/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem, Tooltip } from "@blueprintjs/core";
import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";
import { showConfirmationDialog } from "@teselagen/ui";
import { safeQuery, deleteWithQuery } from "../../../src-shared/apolloMethods";

export function clearLocationConfirmation(recordOrRecords) {
  const records = Array.isArray(recordOrRecords)
    ? recordOrRecords
    : [recordOrRecords];
  return showConfirmationDialog({
    text: `Are you sure you want to clear location information? Doing so will remove the ${modelNameToReadableName(
      records[0].__typename,
      {
        plural: records.length > 1
      }
    )} from ${
      records.length > 1 ? "their" : "its"
    } current container or equipment.`,
    intent: "danger",
    icon: "warning-sign",
    confirmButtonText: "Clear"
  });
}

function ClearLocationMenuItem({ tooltip, records, disabled }) {
  const menuItem = (
    <MenuItem
      icon="cross"
      text="Clear Location"
      disabled={disabled}
      onClick={async () => {
        try {
          const model = records[0].__typename;
          const recordIds = records.map(r => r.id);
          const continueClear = await clearLocationConfirmation(records);
          if (!continueClear) return;
          await deleteWithQuery("assignedPosition", {
            [model + "Id"]: recordIds
          });
          await safeQuery([model + "PathView", "id fullPath"], {
            variables: {
              filter: {
                id: recordIds
              }
            }
          });
        } catch (error) {
          console.error("error:", error);
          window.toastr.error("Error clearing location");
        }
      }}
    />
  );

  if (tooltip) {
    return (
      <Tooltip className="full-width-tooltip" content={tooltip}>
        {menuItem}
      </Tooltip>
    );
  } else {
    return menuItem;
  }
}

export default ClearLocationMenuItem;
