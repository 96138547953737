/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

import customJ5ParameterFragment from "../../../../tg-iso-design/graphql/fragments/customJ5ParameterFragment";
import restrictionEnzymeFragment from "../../../../tg-iso-shared/src/fragments/restrictionEnzymeFragment";

export default gql`
  fragment reactionTemplateFragment on reactionTemplate {
    id
    name
    assemblyMethod {
      id
      name
    }
    customJ5Parameter {
      ...customJ5ParameterFragment
    }
    reactionTemplateJ5OutputNamingTemplates {
      id
      j5OutputNamingTemplate {
        id
        incrementStart
        numDigits
        outputTarget
        template
      }
    }
    restrictionEnzyme {
      ...restrictionEnzymeFragment
    }
  }
  ${customJ5ParameterFragment}
  ${restrictionEnzymeFragment}
`;
