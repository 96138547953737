/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { Switch } from "@blueprintjs/core";
import { get, isArray } from "lodash";
import { DataTable, InputField } from "@teselagen/ui";
import stepFormValues from "../../../stepFormValues";
import {
  getDataGrid,
  parseDataForDataGridTable
} from "../../../utils/experimentData/dataGridUtils";
import HeaderWithHelper from "../../../HeaderWithHelper";
import SelectDataGrid from "../../../components/SelectDataGrid";

// Constant for the number of rows to display (in this tool cells are not editable)
// No need to view them all (they can be a lot).
// TODO: evaluate if cell edition is needed, and if all of the rows need to be accessible here.
//   In case this is enabled. We cant forget to update the data grid in database.
const DATA_GRID_ROW_LIMIT_DISPLAY = 1000;

const AssaySelection = ({
  dataGridId,
  history,
  stepFormProps: { change, destroy },
  selectedDataGrid
}) => {
  const [dataGridEntities, setDataGridEntities] = useState([]);
  const [showDataGridContent, setShowDataGridContent] = useState(false);
  const [dataGridSchema, setDataGridDataSchema] = useState(undefined);
  const [assayName, setAssayName] = useState("");

  useEffect(() => {
    if (assayName)
      change("selectedAssay", {
        userCreated: true,
        value: assayName,
        label: assayName
      });
  }, [assayName]); //eslint-disable-line

  useEffect(() => {
    if (dataGridId) onDataGridSelection(dataGridId);
  }, [dataGridId]); //eslint-disable-line

  useEffect(() => {
    const handleDataGridSelection = async () => {
      const {
        entities: dataGridEntities,
        schema: dataGridSchema
      } = parseDataForDataGridTable(selectedDataGrid.dataCells);
      setDataGridDataSchema(dataGridSchema);
      setDataGridEntities(dataGridEntities);
    };
    if (selectedDataGrid) handleDataGridSelection();
  }, [selectedDataGrid]);

  const onDataGridSelection = async result => {
    const _dataGridId = isArray(result)
      ? get(result, "0.id")
      : get(result, "id") || result;
    const dataGrid = await getDataGrid(_dataGridId);
    if (dataGrid) {
      const headerRow = get(dataGrid, "dataCells").filter(
        cell => cell.rowPosition === 0
      );
      const _headerNames = headerRow.map(headerCell => headerCell.value.trim());
      change("selectedDataGrid", {
        id: dataGrid.id,
        name: dataGrid.name,
        dataCells: dataGrid.dataCells.filter(
          cell => cell.rowPosition <= DATA_GRID_ROW_LIMIT_DISPLAY
        ),
        importFileSetId: dataGrid.importFileSet?.id
      });
      change("headerNames", _headerNames);
    }
    // If the suer switched labs on a selected data grid, clear the URL search params,
    // which may contain the 'dataGridId' search param
    else if (dataGridId) {
      history.replace({ pathname: history.location.pathname, search: "" });
    }
  };

  const renderDataGrid = (
    <>
      <HeaderWithHelper
        header="Selected Data Grid"
        helper={
          <span>
            <b>ID:</b> {`${get(selectedDataGrid, "id")}`} <br /> <b>Name:</b>{" "}
            {`${get(selectedDataGrid, "name")}`}
          </span>
        }
      />
      <Switch
        // id={cypressTags.SHOW_TRAINING_SAMPLES_SWITCH_ID}
        checked={showDataGridContent}
        onChange={() => setShowDataGridContent(!showDataGridContent)}
        label="Show Data Grid"
      />
      {showDataGridContent && (
        <DataTable
          schema={dataGridSchema}
          entities={dataGridEntities}
          withSearch
          formName="dataGridRecordTable"
          pageSize={10} // pageSize is not working for some reason.
          compact
        />
      )}
    </>
  );

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        {!dataGridId ? (
          <SelectDataGrid
            helper={
              <HeaderWithHelper
                header="Select Data Grid"
                helper="Select a data grid for mapping."
              />
            }
            handlers={{
              onSelect: onDataGridSelection,
              onClear: () => {
                setDataGridDataSchema(undefined);
                destroy();
              }
            }}
          />
        ) : null}
        {dataGridSchema && renderDataGrid}
      </div>
      {selectedDataGrid && (
        <div className="tg-step-form-section column">
          <div
            className="tg-flex justify-space-between"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <HeaderWithHelper
              header="Create an Assay"
              helper="Experimental data needs to be assigned to an Assay."
            />
            <div style={{ width: 400 }}>
              <InputField
                name="assayName"
                label="Assay Name"
                isRequired
                onFieldSubmit={setAssayName}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default compose(
  stepFormValues("selectedAssay", "selectedDataGrid", "assayName")
)(AssaySelection);
