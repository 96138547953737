/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import tgUseLocalStorageState from "tg-use-local-storage-state";

export const disabledProjectsLocalStorageKey = "tg-admin-projects-disabled";

const useDisabledProjects = () => {
  return tgUseLocalStorageState(disabledProjectsLocalStorageKey, {
    defaultValue: false
  });
};

export default useDisabledProjects;
