/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
//

import React from "react";
import { reduxForm, FieldArray, change } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import { connect } from "react-redux";
import shortid from "shortid";
import {
  InputField,
  ReactSelectField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Classes, Callout } from "@blueprintjs/core";

import MeasurementUnits from "./MeasurementUnits";
import { getModelTypeOptions } from "../../utils/extendedPropertyUtils";
import { safeUpsert } from "../../apolloMethods";

class EditMeasurementExtendedProperty extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object,
    refetch: PropTypes.func.isRequired
  };

  onSubmit = async values => {
    try {
      const { initialValues = {}, refetch, hideModal } = this.props;

      let baseUnitId = null;
      const baseUnitCid = shortid();

      const [{ id: extendedMeasurementUnitClassId }] = await safeUpsert(
        "measurementUnitClass",
        {
          id: get(initialValues, "extendedMeasurementUnitClass.id"),
          name: values.name.trim(),
          measurementUnits: values.units.map(v => {
            if (v.isBaseUnit) {
              baseUnitId = v.id || "&" + baseUnitCid;
            }
            return {
              id: v.id,
              name: v.name,
              abbreviation: v.abbreviation,
              conversionFactor: v.conversionFactor,
              cid: v.isBaseUnit && !v.id ? baseUnitCid : undefined
            };
          })
        }
      );

      await safeUpsert(
        ["extendedProperty", "id extendedMeasurementUnitClassId"],
        {
          id: initialValues.id,
          name: values.name,
          modelTypeCode: values.modelTypeCode,
          extendedPropertyClassCode: "MEASUREMENT",
          extendedMeasurementUnitClassId
        }
      );

      await safeUpsert("measurementUnitClass", {
        id: extendedMeasurementUnitClassId,
        baseMeasurementUnitId: baseUnitId
      });

      await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error ${
          this.isEditing() ? "editing" : "creating"
        } measurement extended property.`
      );
    }
  };

  /**
   * Are we editing or creating a new extended property?
   */
  isEditing() {
    return !!get(this.props, "initialValues.id");
  }

  render() {
    const {
      hideModal,
      handleSubmit,
      initialValues = {},
      submitting,
      initialItemType,
      change,
      units
    } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {!!initialValues.id && (
            <Callout style={{ marginBottom: 10 }} intent="warning">
              Cannot edit item type when updating extended properties because
              they might be in use.
            </Callout>
          )}
          <ReactSelectField
            isRequired
            name="modelTypeCode"
            label="Item Type"
            disabled={!!initialValues.id}
            options={getModelTypeOptions()}
            defaultValue={initialItemType}
          />
          <InputField isRequired name="name" label="Name" />

          <FieldArray
            name="units"
            component={MeasurementUnits}
            isEditing={this.isEditing()}
            change={change}
            units={units}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  const { units } = values;
  if (!get(units, "length")) {
    errors.units = {
      _error: "At least one option must be supplied."
    };
  } else {
    errors.units = values.units.map(() => ({}));

    const namesCount = {};
    const abbrevsCount = {};

    units.forEach(c => {
      if (!abbrevsCount[c.abbreviation]) abbrevsCount[c.abbreviation] = 0;
      abbrevsCount[c.abbreviation]++;

      if (!namesCount[c.name]) namesCount[c.name] = 0;
      namesCount[c.name]++;
    });

    const baseUnitInds = [];

    units.forEach((c, i) => {
      if (!c.name) errors.units[i].name = "Required.";
      else if (namesCount[c.name] > 1)
        errors.units[i].name = "Duplicated name.";

      if (!c.abbreviation) errors.units[i].abbreviation = "Required.";
      else if (abbrevsCount[c.abbreviation] > 1)
        errors.units[i].abbreviation = "Duplicated abbreviation.";

      if (!(c.conversionFactor > 0))
        errors.units[i].conversionFactor = "Must be greater than 0.";

      if (c.isBaseUnit) {
        if (c.conversionFactor !== 1)
          errors.units[i].conversionFactor = "Must be 1.";
        baseUnitInds.push(i);
      }
    });

    if (!baseUnitInds.length) {
      units.forEach((u, i) => {
        errors.units[i].isBaseUnit = "Exactly one base unit must be chosen.";
      });
    } else if (baseUnitInds.length > 1) {
      baseUnitInds.forEach(i => {
        errors.units[i].isBaseUnit = "Exactly one base unit must be chosen.";
      });
    }
  }

  return errors;
}

const form = "EditMeasurementExtendedProperty";

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Measurement Extended Property"
          : "Add Measurement Extended Property",
        style: {
          width: 650
        }
      };
    }
  }),
  connect(null, {
    change: (...args) => change(form, ...args)
  }),
  reduxForm({
    form,
    enableReinitialize: true,
    validate
  }),
  tgFormValues("units")
)(EditMeasurementExtendedProperty);
