/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
class Logger {
  constructor(logs = []) {
    this.logs = logs;
  }

  add(log) {
    this.logs.push(log);
  }

  log(...args) {
    this.logs.forEach(log => log.log(...args));
  }

  error(...args) {
    this.logs.forEach(log => log.error(...args));
  }

  info(...args) {
    this.logs.forEach(log => log.info(...args));
  }

  warn(...args) {
    this.logs.forEach(log => log.warn(...args));
  }
}

export { Logger };
