/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const extendedPropertyLinkableLimsModels = [
  "sample",
  "aliquot",
  "lot",
  "containerArray",
  "aliquotContainer",
  "sequence",
  "aminoAcidSequence",
  "genome",
  "strain",
  "functionalProteinUnit",
  "material",
  "additiveMaterial",
  "j5Report",
  "worklist",
  "reactionMap",
  "plateMapGroup",
  "plateMap",
  "dataTable"
];
