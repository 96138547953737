/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { map } from "lodash";
import { showConfirmationDialog } from "@teselagen/ui";

export default async function showDuplicateInputSequencesConfirmation(
  duplicatesOfInputSequences,
  sequenceAliases = []
) {
  if (duplicatesOfInputSequences.length) {
    const seqMessages = duplicatesOfInputSequences.map(dup => {
      if (dup.name !== dup.duplicateFound.name && dup.duplicateFound.cid) {
        // aliases will be automatically created for db sequences in checkDuplicateSequencesExtended
        const sequenceId = `&${dup.duplicateFound.cid}`;
        sequenceAliases.push({
          name: dup.name,
          sequenceId
        });
      }
      return `${dup.name} and ${dup.duplicateFound.name} are identical.`;
    });
    const duplicateGroups = {};
    duplicatesOfInputSequences.forEach(dup => {
      const key = dup.duplicateFound.id || dup.duplicateFound.cid;
      duplicateGroups[key] = duplicateGroups[key] || [];
      duplicateGroups[key].name = dup.duplicateFound.name;
      duplicateGroups[key].push(dup.name);
    });
    const linkMessages = map(duplicateGroups, group => {
      return `Duplicate sequence${group.length > 1 ? "s" : ""} ${group.join(
        group.length === 2 ? " and " : ", "
      )} will ${
        group.length > 1 ? (group.length === 2 ? "both " : "all ") : ""
      }be linked to ${group.name}.`;
    });
    const message = (
      <span>
        Duplicate input sequences were detected in this upload:
        <ul style={{ paddingLeft: 20 }}>
          {seqMessages.map((seqMessage, i) => {
            return <li key={i}>{seqMessage}</li>;
          })}
        </ul>
        <br />
        If you proceed,
        <ul style={{ paddingLeft: 20 }}>
          {linkMessages.map((linkMessage, i) => {
            return <li key={i}>{linkMessage}</li>;
          })}
        </ul>
        <br />
        Would you like to continue? Aliases will be created.
      </span>
    );

    return await showConfirmationDialog({
      content: message,
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
  } else {
    return true;
  }
}
