/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isoContext } from "@teselagen/utils";

export default async function handleMutations(mutations, ctx = isoContext) {
  for (const key in mutations) {
    const { create, update, remove } = mutations[key];
    await ctx.safeUpsert(key, create);
    await ctx.safeUpsert(key, update);
    await ctx.safeDelete(key, remove);
  }
}
