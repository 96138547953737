/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const genericElutionReactionMapFragment = gql`
  fragment genericElutionReactionMapFragment on reactionMap {
    id
    name
    reactionTypeCode
    reactions {
      id
      reactionInputs {
        id
        inputMaterial {
          id
          name
        }
        inputAdditiveMaterial {
          id
          name
        }
      }
      reactionOutputs {
        id
        outputMaterial {
          id
          name
        }
        outputAdditiveMaterial {
          id
          name
        }
      }
    }
  }
`;

export const genericElutionCollectionPlateFragment = gql`
  fragment genericElutionCollectionPlateFragment on containerArray {
    id
    name
    containerArrayType {
      id
      name
      containerFormatCode
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      aliquotId
    }
  }
`;

export const genericElutionColumnPlateFragment = gql`
  fragment genericElutionColumnPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      aliquotId
      additives {
        id
        additiveMaterial {
          id
        }
        lot {
          id
          additiveMaterial {
            id
          }
        }
      }
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
        molarity
        molarityUnitCode
        sample {
          id
          name
          sampleTypeCode
          material {
            id
            name
          }
          sampleFormulations {
            id
            materialCompositions {
              id
              materialId
            }
          }
        }
        additives {
          id
          additiveMaterial {
            id
          }
          lot {
            id
            additiveMaterial {
              id
            }
          }
        }
      }
    }
    containerArrayType {
      id
      name
      isColumn
      containerFormatCode
    }
    collectionPlate {
      ...genericElutionCollectionPlateFragment
    }
  }
  ${genericElutionCollectionPlateFragment}
`;
