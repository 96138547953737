/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default function getReactionInputsAndOutputs({
  inputMaterials = [],
  outputMaterials = [],
  inputAdditiveMaterials = [],
  inputConservedAdditiveMaterials = [],
  outputAdditiveMaterials = [],
  outputMaterialId
}) {
  const getId = m => (typeof m === "string" ? m : m.id);
  return {
    reactionInputs: inputMaterials
      .map(m => ({
        inputMaterialId: getId(m),
        ...getExtras(m),
        reactionInputSourceTypeCode: m.sourceType,
        reactionRoleTypeCode: m.reactionRoleType
      }))
      .concat(
        inputAdditiveMaterials.map(am => ({
          inputAdditiveMaterialId: getId(am),
          ...getExtras(am),
          reactionInputSourceTypeCode: am.sourceType
        }))
      )
      .concat(
        inputConservedAdditiveMaterials.map(am => ({
          inputAdditiveMaterialId: getId(am),
          ...getExtras(am),
          reactionInputSourceTypeCode: am.sourceType,
          conserved: true
        }))
      ),
    reactionOutputs: outputMaterialId
      ? [
          {
            outputMaterialId
          }
        ]
      : outputMaterials
          .map(m => ({ outputMaterialId: getId(m) }))
          .concat(
            outputAdditiveMaterials.map(am => ({
              outputAdditiveMaterialId: getId(am)
            }))
          )
  };
}

function getExtras(m) {
  const extras = {};
  if (m.reactionRoleTypeCode) {
    extras.reactionRoleTypeCode = m.reactionRoleTypeCode;
  }
  return extras;
}
