/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import React, { useMemo, useState } from "react";
import { useTgQuery } from "../apolloMethods";
import { find, map, pick, size, startCase } from "lodash";
import integrationTypeSettingsMap from "../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import { externalReferenceKeys } from "../../../tg-iso-shared/constants";
import { useIntegrationQuery } from "./useIntegrationQuery";
import { SendRecordsPage } from "./SendRecordsPage";
import { Classes } from "@blueprintjs/core";
import upsertHandlers from "./upsertHandlers";
import { getImportResultSchema } from "./utils";
import { hideDialog } from "../GlobalDialog";
import concatWarningStrs from "../../../tg-iso-shared/utils/concatWarningStrs";
import mergeUpdateRecord from "../../../tg-iso-shared/src/crudHandlers/upsert/mergeUpdateRecord";
import { cleanExternalRecord } from "../../../tg-iso-shared/src/crudHandlers/utils";
const form = "UpdateExternalDialog";

const {
  UPDATE: {
    endpoints: {
      UPDATE__FORMAT: { method: methodFormat },
      UPDATE__UPDATE: { method: methodUpdate }
    }
  }
} = integrationTypeSettingsMap;

const UpdateResultsPage = ({ results }) => {
  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <h3>Results:</h3>
        <DataTable
          formName="updateResultsTable"
          isSimple
          schema={getImportResultSchema(results)}
          entities={results}
        />
      </div>
      <DialogFooter
        noCancel
        text="OK"
        hideModal={hideDialog}
        onClick={hideDialog}
      />
    </div>
  );
};

const UpdateExternalDialog = ({
  submitting,
  refetch,
  model,
  handleSubmit,
  records,
  integrationId
}) => {
  const [results, setResults] = useState();
  const { integration, ...rest } = useIntegrationQuery({ integrationId });

  return useMemo(() => {
    if (useTgQuery.checkErrAndLoad(rest))
      return useTgQuery.handleErrAndLoad(rest);

    let inner;
    if (results) {
      inner = <UpdateResultsPage results={results} />;
    } else {
      const handleUpdate = async (
        additionalFields,
        { entities, convertDbModelToUserFacing, intermediateUpdate }
      ) => {
        try {
          const endpoint = find(
            integration.integrationEndpoints,
            ({ endpointTypeCode }) => endpointTypeCode === "UPDATE__UPDATE"
          );

          const { integrationEndpointHeaders } = endpoint;
          const headers = {};
          if (integrationEndpointHeaders.length > 0) {
            integrationEndpointHeaders.forEach(h => {
              headers[h?.name] = h?.value;
            });
          }

          const recordsToImport = [];
          const failedRecords = [];
          // const importResults = [];
          for (const record of entities) {
            // Maybe __oldRecord should also be "cleaned"/cast to its schema
            // for consistency.
            const __oldRecord = {
              ...intermediateUpdate(
                convertDbModelToUserFacing(record),
                recordsToImport
              ),
              ...pick(record, externalReferenceKeys),
              id: record.id
            };

            let result;

            try {
              result = await window.triggerIntegrationRequest({
                endpointId: endpoint.id,
                data: {
                  record: __oldRecord,
                  additionalFields: additionalFields
                },
                method: methodUpdate,
                headers
              });
            } catch (error) {
              console.error(`error from integration:`, error);
              failedRecords.push({
                // This should be a cleaned/cast version of __oldRecord
                // instead of the unconverted original record version for consistency.
                ...cleanExternalRecord(__oldRecord, integration.subtype, false),
                // ...record,
                __importFailed: concatWarningStrs(
                  __oldRecord.__importFailed,
                  error.__message || error.message
                )
              });
              continue;
            }
            if (!result.data) {
              // importResults.push({
              //   ...record,
              //   success: false,
              //   error:
              // });
              failedRecords.push({
                // This should be a cleaned/cast version of __oldRecord
                // instead of the unconverted original record version for consistency.
                ...cleanExternalRecord(__oldRecord, integration.subtype, false),
                // ...record,
                __importFailed: "Unknown Error with the integration response"
              });
            } else {
              const cleanedRecord = cleanExternalRecord(
                result.data,
                integration.subtype,
                true
              );

              recordsToImport.push({
                ...cleanedRecord,
                id: record.id,
                __newRecord: mergeUpdateRecord({
                  updateRecord: cleanedRecord,
                  originalRecord: __oldRecord
                }),
                __oldRecord
              });
            }
          }

          try {
            const handler = upsertHandlers[integration.subtype];
            await handler({
              // importResults,
              recordsToImport,
              model
            });

            if (size(recordsToImport)) {
              window.toastr.success(
                `Successfully Updated ${recordsToImport.length} ${startCase(
                  model
                )}(s).`
              );
            }
          } catch (error) {
            console.error(`error:`, error);
            console.error(`failedRecords:`, failedRecords);
            console.error(`recordsToImport:`, recordsToImport);
            recordsToImport.forEach(r => {
              r.__importFailed = concatWarningStrs(
                r.__importFailed,
                "Error processing response."
              );
            });
            window.toastr.error(
              "The external endpoint returned an invalid reponse. Please verify the endpoint is returning data in the expected format. Check the console for more details"
            );
          }
          refetch && (await refetch());

          setResults(
            map(failedRecords.concat(recordsToImport), r => {
              if (!r) return r;
              if (!r.name) {
                if (r.__newRecord && r.__newRecord.name) {
                  r.name = r.__newRecord.name;
                } else if (r.__oldRecord && r.__oldRecord.name) {
                  r.name = r.__oldRecord.name;
                }
              }
              return r;
            })
          );
        } catch (error) {
          window.toastr.error(
            "An error occurred saving the data. Please verify that you've correctly set up the endpoint"
          );
          console.error(error);
          // eslint-disable-next-line no-debugger
        }
      };
      inner = (
        <SendRecordsPage
          model={model}
          endpointTypeCode="UPDATE__FORMAT"
          integration={integration}
          records={records}
          submitting={submitting}
          handleSubmit={handleSubmit}
          methodFormat={methodFormat}
          form={form}
          subtype={integration.subtype}
          onSend={handleUpdate}
        />
      );
    }
    return inner;
  }, [
    rest,
    results,
    model,
    integration,
    records,
    submitting,
    handleSubmit,
    refetch
  ]);
};

export default compose(
  wrapDialog({ style: { width: 700 } }),
  reduxForm({
    form
  })
)(UpdateExternalDialog);
