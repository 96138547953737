/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment plateMapGroupSimpleFragment on plateMapGroup {
    id
    name
    containerFormatCode
    createdAt
    updatedAt
    createdAt
    updatedAt
  }
`;
