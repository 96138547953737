/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import MediaQuery from "react-responsive";
import SequenceScoringSummary from "./SequenceScoringSummary";
import SequenceScoringTable from "./SequenceScoringTable";

function SequenceScoringTableAndSummary(props) {
  return (
    <MediaQuery maxWidth={1300}>
      {smallerScreen => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: smallerScreen ? "column" : undefined
            }}
          >
            <SequenceScoringTable {...props} smallerScreen={smallerScreen} />
            <SequenceScoringSummary {...props} smallerScreen={smallerScreen} />
          </div>
        );
      }}
    </MediaQuery>
  );
}

export default SequenceScoringTableAndSummary;
