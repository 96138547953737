/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import {
  CheckboxField,
  FileUploadField,
  RadioGroupField,
  ReactSelectField,
  SelectField,
  withSelectedEntities
} from "@teselagen/ui";
import pluralize from "pluralize";
import { Callout, Tooltip, Icon, Position, Button } from "@blueprintjs/core";
import {
  keyBy,
  get,
  set,
  isEmpty,
  chunk,
  times,
  sortBy,
  range,
  forEach,
  uniqBy
} from "lodash";
import shortid from "shortid";
import { compose } from "redux";
import { connect } from "react-redux";
import { stopSubmit } from "redux-form";
import QueryBuilder from "tg-client-query-builder";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import {
  plateMapItemTypes,
  plateWellContentTypes,
  j5EntityToFragment,
  itemTypeOptions
} from "../constants";
import { cleanPosition } from "../../../../utils/plateUtils";
import { getSelectedItems, withItemProps } from "../utils";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import {
  dateModifiedColumn,
  j5ReportAssemblyHierarchicalColumns
} from "../../../../../src-shared/utils/libraryColumns";

import {
  plateTo2dAliquotContainerArray,
  blockToAliquotArray,
  getBlockOf2dArray
} from "../../utils";

import { tagModels } from "../../../../../../tg-iso-shared/constants";
import InventoryListSection from "./InventoryListSection";
import {
  arrayToIdOrCodeValuedOptions,
  isValidPositiveNumber
} from "../../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../../src-shared/apolloMethods";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  generateEmptyWells,
  wellInBounds
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";
import SelectJ5MaterialsOrPcrReactions from "../../../SelectJ5MaterialsOrPcrReactions";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { breakdownPatterns } from "../../PlateReformatTool/utils";
import fieldConstants from "../fieldConstants";
import SelectReactionMapEntities, {
  getItemTypeAndFilterForReactionMaps
} from "../../../../../src-shared/SelectReactionMapEntities";

const quadrantFormatMap = {
  "24_WELL": "6_WELL",
  "96_WELL": "24_WELL",
  "384_WELL": "96_WELL",
  "1536_WELL": "384_WELL"
};

const modelNameToSchema = {
  material: [
    {
      path: "name"
    },
    {
      path: "materialType.name",
      displayName: "Type"
    },
    dateModifiedColumn
  ],
  j5Material: [
    {
      path: "name"
    },
    {
      path: "materialType.name",
      displayName: "Type"
    },
    dateModifiedColumn
  ],
  additiveMaterial: [
    {
      path: "name"
    },
    {
      path: "additiveType.name",
      displayName: "Type",
      type: "string"
    }
  ],
  lot: [
    {
      path: "name"
    },
    {
      path: "additiveMaterial.name",
      displayName: "Reagent",
      type: "string"
    }
  ],
  sample: [
    {
      path: "name"
    },
    {
      path: "sampleType.name",
      displayName: "Type"
    },
    dateModifiedColumn
  ],
  j5Report: [
    {
      path: "name"
    },
    ...j5ReportAssemblyHierarchicalColumns,
    dateModifiedColumn
  ],
  plateMap: [
    {
      path: "name"
    },
    {
      path: "plateMapGroup.containerFormat.name",
      displayName: "Format"
    },
    dateModifiedColumn
  ],
  j5PcrReaction: [
    {
      path: "name"
    },
    {
      path: "j5Report.name",
      displayName: "Assembly Report"
    },
    {
      path: "primaryTemplate.name",
      displayName: "Primary Template"
    },
    {
      path: "forwardPrimer.name",
      displayName: "Forward Primer"
    },
    {
      path: "reversePrimer.name",
      displayName: "Reverse Primer"
    },
    {
      path: "pcrProductSequence.name",
      displayName: "Product Sequence"
    }
  ],
  primaryTemplate: [
    {
      path: "name"
    },
    {
      displayName: "Assembly Report",
      render: (v, r) => {
        const j5Reports = [];
        const j5ReportIds = [];
        r.j5PcrReactionsPrimaryTemplates.forEach(primaryTemplate => {
          if (!j5ReportIds.includes(primaryTemplate.j5Report.id)) {
            j5ReportIds.push(primaryTemplate.j5Report.id);
            j5Reports.push(primaryTemplate.j5Report);
          }
        });
        return j5Reports.map(j5Report => j5Report.name).join(", ");
      }
    },
    dateModifiedColumn
  ],
  pcrProductSequence: [
    {
      path: "name"
    },
    {
      displayName: "Assembly Report",
      render: (v, r) => {
        return (
          r.j5PcrReactionsPCRProductSequences.length > 0 &&
          r.j5PcrReactionsPCRProductSequences
            .map(productSequence => get(productSequence, "j5Report.name"))
            .join(", ")
        );
      }
    },
    dateModifiedColumn
  ],
  primer: [
    {
      path: "name"
    },
    {
      displayName: "Assembly Report",
      render: (v, r) => {
        const j5Reports = [];
        r.j5PcrReactionsForwardPrimers.forEach(forwardPrimer =>
          j5Reports.push(forwardPrimer.j5Report.name)
        );
        r.j5PcrReactionsReversePrimers.forEach(reversePrimer =>
          j5Reports.push(reversePrimer.j5Report.name)
        );
        return j5Reports.join(", ");
      }
    },
    dateModifiedColumn
  ],
  j5AssemblyPiece: [
    {
      path: "name"
    },
    {
      displayName: "Assembly Report",
      path: "j5Report.name"
    },
    dateModifiedColumn
  ],
  j5InputSequence: [
    {
      displayName: "Name",
      path: "sequence.name"
    },
    {
      displayName: "Assembly Report",
      path: "j5Report.name"
    },
    dateModifiedColumn
  ],
  j5RunConstruct: [
    {
      path: "name"
    },
    {
      displayName: "Assembly Report",
      path: "j5Report.name"
    },
    dateModifiedColumn
  ],
  reactionMap: [
    "name",
    {
      displayName: "Reaction Type",
      path: "reactionType.name"
    },
    dateModifiedColumn
  ]
};

const taggedItems = `
taggedItems {
  id
  tag {
    id
    name
    color
  }
  tagOption {
    id
    name
    color
  }
}
`;
export const modelNameToFragment = {
  material: ["material", "id name materialTypeCode materialType { code name }"],
  j5Material: [
    "material",
    "id name materialTypeCode materialType { code name }"
  ],
  additiveMaterial: ["additiveMaterial", "id name additiveType { code name }"],
  lot: ["lot", "id name additiveMaterial { id name }"],
  sample: ["sample", "id name sampleType { code name }"],
  j5Report: [
    "j5Report",
    "id name isHierarchical outputCardName assemblyBatchId idFromOriginalAssemblyBatch treePosition"
  ],
  reactionMap: [
    "reactionMap",
    "id name reactionTypeCode reactionType {code name}"
  ],
  containerArray: ["containerArray", `id name barcode { id barcodeString }`],
  plateMap: [
    "plateMap",
    `id
      name
      type
      plateMapGroup {
        id
        name
        containerFormat {
          code
          name
          rowCount
          columnCount
        }
      }`
  ],
  j5PcrReaction: [
    "j5PcrReaction",
    "id name primaryTemplate { id name } forwardPrimer { id name } reversePrimer { id name } pcrProductSequence { id name size } j5Report { id name }"
  ],
  primaryTemplate: [
    "sequence",
    "id name polynucleotideMaterial { id name } j5PcrReactionsPrimaryTemplates { id j5Report { id name } }"
  ],
  pcrProductSequence: [
    "sequence",
    "id name polynucleotideMaterial { id name } j5PcrReactionsPCRProductSequences { id j5Report { id name } }"
  ],
  primer: [
    "j5Oligo",
    "id name sequence { id polynucleotideMaterial { id name } } j5PcrReactionsForwardPrimers { id j5Report { id name } } j5PcrReactionsReversePrimers { id j5Report { id name } }"
  ],
  j5InputSequence: [
    "j5InputSequence",
    "id sequence { id name polynucleotideMaterial { id name } } j5Report { id name }"
  ],
  j5AssemblyPiece: [
    "j5AssemblyPiece",
    "id name sequence { id polynucleotideMaterial { id name } } j5Report { id name }"
  ],
  j5RunConstruct: [
    "j5RunConstruct",
    "id name sequence { id polynucleotideMaterial { id name } } j5Report { id name } "
  ]
};

export const modelNameToAdditionalFragment = {
  material: [
    "material",
    "id name materialTypeCode materialType { code name }  polynucleotideMaterialSequence { id size } updatedAt"
  ],
  sample: [
    "sample",
    "id name sampleType { code name } material { id polynucleotideMaterialSequence { id size } }"
  ],
  containerArray: [
    "containerArray",
    `id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        columnCount
        rowCount
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        id
        sample {
          id
          name
          ${taggedItems}
          material {
            id
            name
            polynucleotideMaterialSequence { id size }
            ${taggedItems}
          }
        }
      }
    }`
  ],
  plateMap: [
    "plateMap",
    `id
      name
      type
      plateMapGroup {
        id
        name
        containerFormat {
          code
          name
          rowCount
          columnCount
        }
      }
      plateMapItems {
        id
        rowPosition
			  columnPosition
        plateMapItemTypeCode
        inventoryItem {
          id
          material {
            id
            name
            ${taggedItems}
          }
          lot {
            id
            name
            ${taggedItems}
          }
          sample {
            id
            name
            ${taggedItems}
          }
          additiveMaterial {
            id
            name
            ${taggedItems}
          }
        }
        j5Item {
          id
          j5PcrReaction {
            id
            name
          }
        }
      }`
  ]
};

forEach(modelNameToFragment, fragmentArray => {
  fragmentArray[1] = fragmentArray[1] + ` updatedAt`;
  if (tagModels.includes(fragmentArray[0])) {
    fragmentArray[1] = fragmentArray[1] + ` ${taggedItems}`;
  }
});

class PlateMapSettingsStep extends React.Component {
  state = {
    activeDestinationPlateMapIndex: 0,
    numDestinationPlateMaps: 1,
    loadingInventoryList: false
  };
  getCsvNameFieldHeader = () => {
    return `${modelNameToReadableName(this.props.itemType, {
      upperCase: true
    })} Name`;
  };
  getCsvHeaders = () => {
    return ["Plate Key", this.getCsvNameFieldHeader(), "Well"];
  };

  beforeUpload = async (fileList, onChange) => {
    const {
      stepFormProps: { change },
      selectedContainerFormat = {},
      stopSubmit,
      toolSchema,
      itemType
    } = this.props;

    if (!selectedContainerFormat.code) {
      return window.toastr.error("Please select a plate format.");
    }

    const file = fileList[0];
    const newFile = {
      ...file,
      loading: false
    };

    const stopLoading = () => {
      onChange([newFile]);
    };

    const makeError = error => {
      stopLoading();
      stopSubmit(toolSchema.code, {
        plateMapCsv: error
      });
    };

    try {
      const readableName = modelNameToReadableName(itemType);
      const { parsedData } = file;

      const itemNames = [];
      const rowKey = this.getCsvNameFieldHeader();

      let uploadingVolumeInfo = false;
      for (const [index, row] of parsedData.entries()) {
        if (!row[rowKey].trim()) {
          return makeError(
            `Row ${
              index + 1
            } is missing a ${readableName} name, please provide one.`
          );
        }
        if (row["Volume (optional)"]) {
          uploadingVolumeInfo = true;
        }

        const materialName = row[rowKey].trim();
        if (materialName && !itemNames.includes(materialName)) {
          itemNames.push(materialName);
        }
      }
      const filterArray = [];
      const qb = new QueryBuilder(itemType);
      itemNames.forEach(name =>
        filterArray.push({ name: qb.lowerCase(name.toLowerCase()) })
      );
      const filter = qb.whereAny(...filterArray).toJSON();
      const items = await safeQuery(
        modelNameToAdditionalFragment[itemType] ||
          modelNameToFragment[itemType],
        {
          variables: {
            filter
          }
        }
      );

      const selectedItems = getSelectedItems(this.props);
      const keyedItems = keyBy(items, m => m.name.toLowerCase().trim());
      let keyedAlreadySelectedItems = {};
      let newSelectedItems = [];
      if (!uploadingVolumeInfo) {
        keyedAlreadySelectedItems = keyBy(selectedItems, "id");
        newSelectedItems = [...selectedItems];
      }
      items.forEach(m => {
        if (!keyedAlreadySelectedItems[m.id]) {
          newSelectedItems.push(m);
          keyedAlreadySelectedItems[m.id] = m;
        }
      });

      const plateMapKeys = [];
      const platesToCreate = [];
      const plateKeyToIndex = {};

      const missingItems = [];
      parsedData.forEach(row => {
        const itemName = row[rowKey];
        const existingItem = keyedItems[itemName.toLowerCase().trim()];

        if (!existingItem && !missingItems.includes(itemName)) {
          missingItems.push(itemName);
        }
      });
      if (missingItems.length) {
        return makeError(
          `These ${readableName} were not found in the ${readableName} library: ${missingItems.join(
            ", "
          )}.`
        );
      }

      // because we only one thing per well we need to make sure there are not two rows of items going into the same well
      const plateLocationTracker = {};
      for (const [index, row] of parsedData.entries()) {
        const {
          "Plate Key": plateKey,
          Well: _location,
          "Volume (optional)": volume,
          "Volumetric Unit (optional)": volumetricUnitCode
        } = row;
        if (uploadingVolumeInfo) {
          if (!volume) {
            return makeError(
              `Row ${
                index + 1
              } does not have a volume. If providing volume info it needs to be on each row.`
            );
          } else if (!isValidPositiveNumber(volume)) {
            return makeError(`Row ${index + 1} does specify a valid volume.`);
          }
          if (!volumetricUnitCode) {
            return makeError(
              `Row ${index + 1} does not specify a valid volumetric unit.`
            );
          }
          if (
            volumetricUnitCode &&
            !unitGlobals.volumetricUnits[volumetricUnitCode]
          ) {
            return makeError(
              `Row ${
                index + 1
              } specifies the volumetric unit ${volumetricUnitCode} which does not exist.`
            );
          }
        }

        const itemName = row[rowKey];
        const location = cleanPosition(_location);
        const existingItem = keyedItems[itemName.toLowerCase().trim()];

        if (!location) {
          return makeError(
            `Row ${index + 1} is missing a well location, please provide one.`
          );
        }
        if (!plateKey) {
          return makeError(
            `Row ${index + 1} is missing a plate key, please provide one.`
          );
        }
        let plateIndex = plateKeyToIndex[plateKey];
        if (plateIndex === undefined) {
          plateIndex = plateMapKeys.push(plateKey) - 1;
          plateKeyToIndex[plateKey] = plateIndex;
        }
        if (!platesToCreate[plateIndex]) {
          platesToCreate[plateIndex] = {};
        }
        if (!wellInBounds(location, selectedContainerFormat)) {
          return makeError(
            `The well ${location} will not fit onto the selected plate format.`
          );
        }
        plateLocationTracker[plateKey] = plateLocationTracker[plateKey] || [];
        if (plateLocationTracker[plateKey].includes(location)) {
          return makeError(
            `Multiple rows pointed to the same location ${location} on plate ${plateKey}. Please only specify one row per well.`
          );
        }
        plateLocationTracker[plateKey].push(location);
        platesToCreate[plateIndex][location] = {
          item: existingItem,
          volume,
          volumetricUnitCode
        };
      }
      change("uploadingVolumeInfo", uploadingVolumeInfo);
      change("platesToCreate", platesToCreate);
      change(pluralize(itemType), newSelectedItems);
      stopLoading();
    } catch (error) {
      console.error("error:", error);
      makeError(error.message || "Unable to upload plate map csv.");
    }
  };

  clearPlates = newContainerFormat => {
    const {
      stepFormProps: { change },
      selectedContainerFormat
    } = this.props;
    if (newContainerFormat !== selectedContainerFormat) {
      change("platesToCreate", []);
      change("plateMaps", []);
    }
  };

  clearPlateMaps = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("plateMaps", []);
  };

  getSchema = itemType => {
    let schema;
    if (itemType === "material") {
      schema = [
        "name",
        { displayName: "Material Type", path: "materialType.name" },
        dateModifiedColumn
      ];
    }
    return schema;
  };

  addDestinationPlateMap = () => {
    const { numDestinationPlateMaps = 1 } = this.state;
    this.setState({
      numDestinationPlateMaps: numDestinationPlateMaps + 1
    });
  };

  removeDestinationPlateMap = () => {
    const { stepFormProps, destinationPlateMapQuadrants = [] } = this.props;
    const { activeDestinationPlateMapIndex, numDestinationPlateMaps = 1 } =
      this.state;
    const newDestinationPlateMapQuadrants = destinationPlateMapQuadrants.filter(
      (d, i) => i !== activeDestinationPlateMapIndex
    );
    this.setState({
      numDestinationPlateMaps: numDestinationPlateMaps - 1,
      activeDestinationPlateMapIndex:
        activeDestinationPlateMapIndex - 1 < 0
          ? 0
          : activeDestinationPlateMapIndex - 1
    });
    stepFormProps.change(
      "destinationPlateMapQuadrants",
      newDestinationPlateMapQuadrants
    );
  };

  onSelectMainEntity = (mainEntities = []) => {
    const {
      stepFormProps: { change },
      breakIntoQuadrants,
      reactionMaps = [],
      itemType
    } = this.props;

    this.setState({
      activeDestinationPlateMapIndex: 0
    });
    const selectedPlateMapOrJ5Reports =
      itemType === "plateMap" || itemType === "j5Report";
    if (selectedPlateMapOrJ5Reports && breakIntoQuadrants) {
      const numDestinationPlateMaps = Math.ceil(mainEntities.length / 4);
      this.setState({
        numDestinationPlateMaps
      });
      const destinationPlateMapQuadrants = [];
      times(numDestinationPlateMaps, i => {
        const startIndex = i * 4;
        destinationPlateMapQuadrants[i] = {};
        times(4, j => {
          const plateMapIndex = startIndex + j;
          if (mainEntities[plateMapIndex]) {
            destinationPlateMapQuadrants[i][`quadrant${j + 1}`] =
              mainEntities[plateMapIndex].id;
          }
        });
      });
      change("destinationPlateMapQuadrants", destinationPlateMapQuadrants);
    }
    if (itemType === "reactionMap") {
      const changed = reactionMaps !== mainEntities;
      if (changed) {
        // clear these so that the table won't be populated
        change("materials", []);
        change("additiveMaterials", []);
      }
    }
  };

  beforeNextStep = async values => {
    const {
      nextStep,
      stepFormProps: { change },
      createPlateMapSampleInventoryListTableSelectedEntities = []
    } = this.props;
    const {
      plateMaps = [],
      containerArrays = [],
      j5Reports = [],
      itemType,
      j5EntityType,
      plateMapType,
      plateWellContentType,
      breakIntoQuadrants,
      breakdownPattern,
      uploadMaterialPlateMap,
      destinationPlateMapQuadrants: _destinationPlateMapQuadrants,
      selectedContainerFormat,
      [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities,
      [fieldConstants.reactionEntityType]: reactionEntityType,
      reactionMaps
    } = values;

    const fixedQuadrants =
      (itemType === "plateMap" || itemType === "j5Report") &&
      breakIntoQuadrants;
    try {
      if (selectAllReactionEntities) {
        const { additionalFilter, itemType } =
          getItemTypeAndFilterForReactionMaps({
            reactionEntityType,
            reactionMaps
          });
        const items = await safeQuery(
          modelNameToAdditionalFragment[itemType] ||
            modelNameToFragment[itemType],
          {
            variables: {
              filter: additionalFilter
            }
          }
        );
        if (!items.length) {
          return window.toastr.error(
            `Could not find any ${reactionEntityType}`
          );
        }
        change(pluralize(itemType), items);
      }

      if (fixedQuadrants) {
        let keyedPlateMaps = {};
        const platesToCreate = [];

        let destinationPlateMapQuadrants = _destinationPlateMapQuadrants;

        if (itemType === "j5Report") {
          const j5ReportsWithEntities = await safeQuery(
            j5EntityToFragment[j5EntityType],
            {
              variables: {
                filter: { id: j5Reports.map(j5Report => j5Report.id) }
              }
            }
          );
          const j5ReportIdToEntities = {};
          j5ReportsWithEntities.forEach(j5Report => {
            j5ReportIdToEntities[j5Report.id] = [];
            if (j5EntityType === "j5PcrReaction") {
              j5ReportIdToEntities[j5Report.id] = j5Report.j5PcrReactions;
            } else if (j5EntityType === "primaryTemplate") {
              j5Report.j5PcrReactions.forEach(pcrReaction => {
                const material = get(
                  pcrReaction,
                  "primaryTemplate.polynucleotideMaterial"
                );
                if (!material)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(material);
              });
            } else if (j5EntityType === "pcrProductSequence") {
              j5Report.j5PcrReactions.forEach(pcrReaction => {
                const material = get(
                  pcrReaction,
                  "pcrProductSequence.polynucleotideMaterial"
                );
                if (!material)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(material);
              });
            } else if (j5EntityType === "primer") {
              j5Report.j5PcrReactions.forEach(pcrReaction => {
                const forwardPrimerMaterial = get(
                  pcrReaction,
                  "forwardPrimer.sequence.polynucleotideMaterial"
                );
                const reversePrimerMaterial = get(
                  pcrReaction,
                  "reversePrimer.sequence.polynucleotideMaterial"
                );
                if (!forwardPrimerMaterial || !reversePrimerMaterial)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(forwardPrimerMaterial);
                j5ReportIdToEntities[j5Report.id].push(reversePrimerMaterial);
              });
            } else if (j5EntityType === "j5AssemblyPiece") {
              j5Report.j5AssemblyPieces.forEach(assemblyPiece => {
                const material = get(
                  assemblyPiece,
                  "sequence.polynucleotideMaterial"
                );
                if (!material)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(material);
              });
            } else if (j5EntityType === "j5InputSequence") {
              j5Report.j5InputSequences.forEach(inputSequence => {
                const material = get(
                  inputSequence,
                  "sequence.polynucleotideMaterial"
                );
                if (!material)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(material);
              });
            } else if (j5EntityType === "j5RunConstruct") {
              j5Report.j5RunConstructs.forEach(construct => {
                const material = get(
                  construct,
                  "sequence.polynucleotideMaterial"
                );
                if (!material)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(material);
              });
            }
          });
          destinationPlateMapQuadrants = [];
          const { quadrant1, quadrant2, quadrant3, quadrant4 } =
            _destinationPlateMapQuadrants[0];
          const allReportEntities = [
            j5ReportIdToEntities[quadrant1],
            j5ReportIdToEntities[quadrant2],
            j5ReportIdToEntities[quadrant3],
            j5ReportIdToEntities[quadrant4]
          ];
          times(4, plateMapIndex => {
            const reportEntitiesForIndex =
              allReportEntities[plateMapIndex] || [];
            chunk(
              reportEntitiesForIndex,
              selectedContainerFormat.quadrantSize / 4
            ).forEach((chunkOfEntities, i) => {
              const plateMapKey = shortid();
              keyedPlateMaps[plateMapKey] = {
                plateMapItems: generateEmptyWells(selectedContainerFormat).map(
                  (well, i) => {
                    const j5Item = chunkOfEntities[i];
                    well.j5Item = j5Item;
                    return well;
                  }
                ),
                plateMapGroup: {
                  containerFormat: selectedContainerFormat
                }
              };
              set(
                destinationPlateMapQuadrants,
                `[${i}].quadrant${plateMapIndex + 1}`,
                plateMapKey
              );
            });
          });
        } else {
          keyedPlateMaps = keyBy(plateMaps, "id");
        }
        destinationPlateMapQuadrants.forEach(quadrants => {
          const plateToCreate = {};
          const { quadrant1, quadrant2, quadrant3, quadrant4 } = quadrants;
          const plateMapFor1 = keyedPlateMaps[quadrant1];
          const plateMapFor2 = keyedPlateMaps[quadrant2];
          const plateMapFor3 = keyedPlateMaps[quadrant3];
          const plateMapFor4 = keyedPlateMaps[quadrant4];

          const sortedSourcePlateAcs = [];
          const sourcePlateMaps = [
            plateMapFor1,
            plateMapFor2,
            plateMapFor3,
            plateMapFor4
          ];

          sourcePlateMaps.forEach(plateMap => {
            if (plateMap) {
              const plateMapItemsFilled = generateContainerArray(
                plateMap.plateMapItems,
                plateMap.plateMapGroup.containerFormat
              );
              sortedSourcePlateAcs.push(
                sortBy(plateMapItemsFilled, ["rowPosition", "columnPosition"])
              );
            } else {
              sortedSourcePlateAcs.push([]);
            }
          });
          const { rowCount: destRowCount, columnCount: destColCount } =
            selectedContainerFormat;
          const srcRowCount = destRowCount / 2;
          const srcColCount = destColCount / 2;
          const destinationPlate = generateContainerArray(
            [],
            selectedContainerFormat
          );
          const aliquotContainer2dArray = plateTo2dAliquotContainerArray({
            aliquotContainers: destinationPlate,
            containerArrayType: {
              containerFormat: selectedContainerFormat
            }
          });
          const blockRowCount = destRowCount / srcRowCount;
          const blockColCount = destColCount / srcColCount;
          range(srcRowCount).forEach(destRowPos => {
            range(srcColCount).forEach(destColPos => {
              const block = getBlockOf2dArray(
                aliquotContainer2dArray,
                blockRowCount,
                blockColCount,
                destColPos,
                destRowPos,
                true,
                true
              );
              const inputAliquotContainers = times(
                4,
                i => sortedSourcePlateAcs[i] && sortedSourcePlateAcs[i].shift()
              );
              blockToAliquotArray(block, breakdownPattern).forEach(
                (aliquotContainer, plateIndex) => {
                  const sourceAc = inputAliquotContainers[plateIndex];
                  const item =
                    (get(sourceAc, "inventoryItem") || {})[plateMapType] ||
                    get(sourceAc, "j5Item");
                  if (item) {
                    plateToCreate[
                      getAliquotContainerLocation(aliquotContainer)
                    ] = {
                      item
                    };
                  }
                }
              );
            });
          });

          platesToCreate.push(plateToCreate);
        });

        change("platesToCreate", platesToCreate);
      }
      if (!uploadMaterialPlateMap && !fixedQuadrants) {
        change("platesToCreate", []);
      }
      if (itemType === "containerArray") {
        const plateEntities = [];
        const addPlateEnt = (plate, ac, path) => {
          const ent = get(ac, path);
          if (!ent) return;
          const plateBarcode = plate.barcode?.barcodeString;
          plateEntities.push({
            ...ent,
            well: getAliquotContainerLocation(ac, {
              containerFormat: plate.containerArrayType.containerFormat
            }),
            plateName: plate.name,
            plateBarcode
          });
        };
        let path, fieldName;
        if (plateWellContentType === "sample") {
          path = "aliquot.sample";
          fieldName = "samples";
        } else {
          path = "aliquot.sample.material";
          fieldName = "materials";
        }
        containerArrays.forEach(p =>
          p.aliquotContainers.forEach(ac => addPlateEnt(p, ac, path))
        );
        change(fieldName, uniqBy(plateEntities, "id"));
      }
      if (itemType === "Inventory List") {
        change("samples", [
          ...createPlateMapSampleInventoryListTableSelectedEntities
        ]);
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error(
        error.validationMsg || "Error generating plate maps"
      );
    }
    change("fixedQuadrants", fixedQuadrants);
    nextStep();
  };

  renderJ5ReportOptions() {
    const {
      breakIntoQuadrants,
      itemType,
      j5Reports = [],
      j5EntityRadio
    } = this.props;

    const selectedItemsNames = [];
    const duplicateItemNames = [];
    const selectedItems = getSelectedItems(this.props);

    if (selectedItems.length > 0) {
      selectedItems.forEach(material => {
        if (selectedItemsNames.includes(material.name)) {
          duplicateItemNames.push(material.name);
        } else {
          selectedItemsNames.push(material.name);
        }
      });
    }

    if (j5Reports.length && itemType === "j5Report") {
      if (breakIntoQuadrants) {
        return (
          <div className="tg-flex column" style={{ maxWidth: 250 }}>
            <ReactSelectField
              name="j5EntityType"
              options={[
                {
                  label: "Input Sequences",
                  value: "j5InputSequence"
                },
                { label: "PCR Reactions", value: "j5PcrReaction" },
                {
                  label: "Primary Templates",
                  value: "primaryTemplate"
                },
                { label: "Primers", value: "primer" },
                {
                  label: "PCR Products",
                  value: "pcrProductSequence"
                },
                {
                  label: "Assembly Pieces",
                  value: "j5AssemblyPiece"
                },
                { label: "Constructs", value: "j5RunConstruct" }
              ]}
              label="Assembly Report Entity"
            />
          </div>
        );
      } else {
        return (
          <div className="tg-flex column">
            <RadioGroupField
              inline
              options={[
                {
                  label: "Report Materials",
                  value: "material"
                },
                {
                  label: "PCR Reactions",
                  value: "j5PcrReaction"
                }
              ]}
              defaultValue="material"
              name="j5EntityRadio"
              label=""
            />
            {j5EntityRadio && (
              <SelectJ5MaterialsOrPcrReactions
                {...{
                  entityType: j5EntityRadio,
                  j5Reports,
                  modelNameToSchema,
                  modelNameToFragment,
                  modelNameToAdditionalFragment
                }}
              />
            )}
          </div>
        );
      }
    }
  }

  renderReactionMapOptions() {
    const {
      reactionMaps = [],
      itemType,
      toolSchema,
      stepFormProps: { change }
    } = this.props;
    if (itemType !== "reactionMap") return;

    return (
      <SelectReactionMapEntities
        toolSchema={toolSchema}
        change={change}
        modelNameToSchema={modelNameToSchema}
        fieldConstants={fieldConstants}
        reactionMaps={reactionMaps}
        modelNameToAdditionalFragment={modelNameToAdditionalFragment}
        modelNameToFragment={modelNameToFragment}
      />
    );
  }

  render() {
    const { activeDestinationPlateMapIndex = 0, numDestinationPlateMaps = 1 } =
      this.state;
    const {
      uploadingVolumeInfo,
      containerArrays = [],
      uploadMaterialPlateMap,
      selectedContainerFormat = {},
      breakIntoQuadrants,
      itemType,
      j5EntityType,
      plateMapType,
      plateWellContentType,
      j5Reports = [],
      plateMaps = [],
      Footer,
      footerProps,
      handleSubmit,
      toolSchema,
      stepFormProps: { change },
      createPlateMapSampleInventoryListTableSelectedEntities = []
    } = this.props;
    let quadrantEntities;
    if (itemType === "j5Report") {
      quadrantEntities = j5Reports;
    } else if (itemType === "plateMap") {
      quadrantEntities = plateMaps;
    }

    const selectedItemsNames = [];
    const duplicateItemNames = [];
    const csvHeaders = this.getCsvHeaders();
    const selectedItems = getSelectedItems(this.props);

    if (selectedItems.length > 0) {
      selectedItems.forEach(item => {
        if (selectedItemsNames.includes(item.name)) {
          duplicateItemNames.push(item.name);
        } else {
          selectedItemsNames.push(item.name);
        }
      });
    }

    let plateMapFilter;

    if (breakIntoQuadrants) {
      plateMapFilter = {
        type: plateMapType,
        "plateMapGroup.containerFormatCode":
          quadrantFormatMap[selectedContainerFormat.code]
      };
    } else {
      plateMapFilter = {
        type: plateMapType,
        "plateMapGroup.containerFormatCode": selectedContainerFormat.code
      };
    }

    const quadrantEntityOptions = arrayToIdOrCodeValuedOptions(
      quadrantEntities || []
    );

    const normalFlow =
      !(
        itemType === "j5Report" ||
        itemType === "plateMap" ||
        itemType === "reactionMap" ||
        itemType === "containerArray"
      ) && !breakIntoQuadrants;

    const firstSection = (
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Select Plate Format"
          helper="Specify a plate format for the output plate map. If you would like to break the plate map into quadrants, check the box and specify a breakdown pattern."
        />
        <div className="tg-flex column" style={{ width: "30%" }}>
          <GenericSelect
            {...{
              generateDefaultValue: {
                ...defaultValueConstants.CREATE_PLATE_MAP_PLATE_FORMAT
              },
              ...defaultValueConstants.CREATE_PLATE_MAP_PLATE_FORMAT,
              label: "Plate Format",
              name: "selectedContainerFormat",
              onFieldSubmit: this.clearPlates
            }}
          />
          <div className="tg-flex column">
            <div className="tg-flex">
              <CheckboxField
                name="breakIntoQuadrants"
                label="Break Into Quadrants"
              />
              <div style={{ marginTop: 7, marginLeft: 10 }}>
                <Tooltip
                  content="Checking this box allows placement of entities onto quadrants of the output plate map."
                  position="auto"
                  boundary="preventOverflow"
                >
                  <Icon icon="help" />
                </Tooltip>
              </div>
            </div>
            {breakIntoQuadrants && (
              <div style={{ width: 150, marginTop: 10 }}>
                <SelectField
                  name="breakdownPattern"
                  label="Breakdown Pattern"
                  defaultValue="Z"
                  options={breakdownPatterns(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

    const errorsForPlates = {};
    const hasEntity = (ac, plateMapType) => {
      if (plateMapType === "sample") {
        return get(ac, "aliquot.sample");
      } else {
        return get(ac, "aliquot.sample.material");
      }
    };
    containerArrays.forEach(containerArray => {
      if (
        !containerArray.aliquotContainers.some(ac =>
          hasEntity(ac, plateWellContentType)
        )
      ) {
        errorsForPlates[containerArray.id] =
          `This plate does not have any ${pluralize(plateWellContentType)}.`;
      }
    });

    let postSelectTableSchema = modelNameToSchema[itemType];

    if (itemType === "containerArray") {
      postSelectTableSchema = [
        {
          type: "action",
          width: 35,
          render: (_, record) => {
            const error = errorsForPlates[record.id];
            if (error) {
              return (
                <Tooltip content={error}>
                  <Icon
                    intent="danger"
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
          }
        },
        platePreviewColumn(),
        {
          path: "name"
        },
        {
          path: "barcode.barcodeString",
          displayName: "Barcode"
        }
      ];
    }
    let readyToSelect;
    if (itemType === "plateMap") {
      readyToSelect = !!plateMapType;
    } else if (itemType === "containerArray") {
      readyToSelect = !!plateWellContentType;
    } else {
      readyToSelect = true;
    }
    return (
      <div>
        {firstSection}
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Choose Item Type"
            helper="Which type of items will be used to populate these plate maps."
          />
          <div style={{ width: "30%" }}>
            <ReactSelectField
              name={fieldConstants.itemType}
              options={itemTypeOptions}
              label="Item Type"
              onFieldSubmit={() => {
                change("materials", []);
                change("additiveMaterials", []);
              }}
            />
            {itemType === "plateMap" && (
              <ReactSelectField
                name="plateMapType"
                options={plateMapItemTypes.map(model => ({
                  label: modelNameToReadableName(model, { upperCase: true }),
                  value: model
                }))}
                onFieldSubmit={this.clearPlateMaps}
                label="Plate Map Type"
              />
            )}
            {itemType === "containerArray" && (
              <ReactSelectField
                name="plateWellContentType"
                options={plateWellContentTypes.map(model => ({
                  label: modelNameToReadableName(model, { upperCase: true }),
                  value: model
                }))}
                onFieldSubmit={this.clearPlateMaps}
                label="Plate Well Contents"
              />
            )}
          </div>
        </div>
        {!!selectedContainerFormat &&
          !!itemType &&
          itemType !== "Inventory List" &&
          readyToSelect && (
            <div>
              <div className="tg-step-form-section column">
                <div className="tg-flex justify-space-between">
                  <HeaderWithHelper
                    width="100%"
                    header={`Select ${modelNameToReadableName(itemType, {
                      plural: true,
                      upperCase: true
                    })}`}
                    helper={`Choose which ${modelNameToReadableName(itemType, {
                      plural: true
                    })} you would like to apply to the output plate map.`}
                  />
                  {normalFlow && (
                    <div
                      className="tg-flex justify-space-between"
                      style={{ width: 250 }}
                    >
                      <CheckboxField
                        name="uploadMaterialPlateMap"
                        label="Populate Plate Map with CSV"
                      />
                      <div style={{ marginTop: 5 }}>
                        <Tooltip
                          content={`Check this box to provide a CSV of ${pluralize(
                            itemType
                          )} instead of selecting manually.`}
                          position={Position.BOTTOM_RIGHT}
                          boundary="preventOverflow"
                        >
                          <Icon icon="help" />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {normalFlow && uploadMaterialPlateMap && (
                    <div style={{ marginBottom: 20, maxWidth: 300 }}>
                      <FileUploadField
                        beforeUpload={this.beforeUpload}
                        accept={getDownloadTemplateFileHelpers({
                          fileName: "create_plate_map.csv",
                          validateAgainstSchema: {
                            fields: csvHeaders
                              .map(h => ({
                                path: h,
                                isRequired: true,
                                description:
                                  h === "Plate Key"
                                    ? "Required. Used to specify items across multiple plates."
                                    : undefined
                              }))
                              .concat(
                                [
                                  "Volume (optional)",
                                  "Volumetric Unit (optional)"
                                ].map(h => ({
                                  path: h
                                }))
                              )
                          }
                        })}
                        fileLimit={1}
                        name="plateMapCsv"
                      />
                    </div>
                  )}
                  {!uploadingVolumeInfo && (
                    <GenericSelect
                      key={itemType}
                      {...{
                        name: pluralize(itemType),
                        isMultiSelect: true,
                        schema: modelNameToSchema[itemType],
                        fragment: modelNameToFragment[itemType],
                        additionalDataFragment:
                          modelNameToAdditionalFragment[itemType],
                        tableParamOptions: {
                          additionalFilter:
                            itemType === "plateMap" ? plateMapFilter : undefined
                        },
                        onSelect: this.onSelectMainEntity,
                        postSelectDTProps: {
                          formName: "plateMapEntitiesTable",
                          schema: postSelectTableSchema,
                          errorsForPlates
                        }
                      }}
                    />
                  )}
                  {this.renderJ5ReportOptions()}
                  {this.renderReactionMapOptions()}

                  {duplicateItemNames.length > 0 && (
                    <Callout intent="warning" icon="error">
                      {`The following selected ${modelNameToReadableName(
                        itemType,
                        {
                          plural: true
                        }
                      )} have duplicated names: ${duplicateItemNames.join(
                        ", "
                      )}.`}
                    </Callout>
                  )}
                </div>
              </div>
              {breakIntoQuadrants &&
                ((j5Reports &&
                  itemType === "j5Report" &&
                  j5Reports.length > 0 &&
                  j5EntityType) ||
                  (plateMaps &&
                    itemType === "plateMap" &&
                    plateMaps.length > 0)) && (
                  <div className="tg-step-form-section">
                    <HeaderWithHelper
                      header="Assign to Quadrant"
                      helper={`Assign each selected ${modelNameToReadableName(
                        itemType
                      )} to a quadrant.`}
                    />

                    <div className="tg-flex column">
                      {itemType === "plateMap" && (
                        <div
                          className="tg-flex align-center"
                          style={{ marginBottom: 10 }}
                        >
                          <Button
                            minimal
                            icon="arrow-left"
                            disabled={activeDestinationPlateMapIndex <= 0}
                            onClick={() => {
                              this.setState({
                                activeDestinationPlateMapIndex:
                                  activeDestinationPlateMapIndex - 1
                              });
                            }}
                          />
                          <div>
                            Destination Plate Map{" "}
                            {activeDestinationPlateMapIndex + 1} of{" "}
                            {numDestinationPlateMaps}
                          </div>
                          <Button
                            minimal
                            icon="arrow-right"
                            disabled={
                              activeDestinationPlateMapIndex >=
                              numDestinationPlateMaps - 1
                            }
                            onClick={() => {
                              this.setState({
                                activeDestinationPlateMapIndex:
                                  activeDestinationPlateMapIndex + 1
                              });
                            }}
                          />
                          <Button
                            icon="trash"
                            intent="danger"
                            minimal
                            style={{ marginLeft: 10 }}
                            disabled={numDestinationPlateMaps <= 1}
                            onClick={this.removeDestinationPlateMap}
                          />
                          <Button
                            icon="add"
                            intent="success"
                            minimal
                            style={{ marginLeft: 10 }}
                            onClick={this.addDestinationPlateMap}
                          />
                        </div>
                      )}
                      {times(4, i => {
                        return (
                          <div key={i}>
                            <ReactSelectField
                              name={`destinationPlateMapQuadrants.${activeDestinationPlateMapIndex}.quadrant${
                                i + 1
                              }`}
                              options={quadrantEntityOptions}
                              label={`Quadrant ${i + 1}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
            </div>
          )}
        {itemType === "Inventory List" && (
          <InventoryListSection
            toolSchema={toolSchema}
            modelNameToFragment={modelNameToAdditionalFragment}
            modelNameToSchema={modelNameToSchema}
            change={change}
          />
        )}
        <Footer
          {...footerProps}
          nextDisabled={
            (itemType === "Inventory List" &&
              !createPlateMapSampleInventoryListTableSelectedEntities.length) ||
            !isEmpty(errorsForPlates)
          }
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    "uploadingVolumeInfo",
    "uploadMaterialPlateMap",
    "selectedContainerFormat",
    "itemType",
    "breakIntoQuadrants",
    "j5EntityType",
    "plateMapType",
    "plateWellContentType",
    "j5Reports",
    "reactionMaps",
    "plateMaps",
    "destinationPlateMapQuadrants",
    "j5EntityRadio",
    "reactionEntityType",
    fieldConstants.selectAllReactionEntities
  ),
  withItemProps,
  connect(null, {
    stopSubmit
  }),
  withSelectedEntities("createPlateMapSampleInventoryListTable")
)(PlateMapSettingsStep);
