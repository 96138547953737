/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import PlatformHeader from "../PlatformHeader";
import NotificationPopover from "../NotificationUI/NotificationPopover";
import getAppColor from "../utils/getAppColor";
import { openInNewTab } from "../utils/generalUtils";
import { Button, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core";
import { showDialog } from "../GlobalDialog";
import BarcodeHelperDialog from "../../src-build/components/Dialogs/BarcodeHelperDialog";
import MoveBarcodedItemDialog from "../../src-build/components/Dialogs/MoveBarcodedItemDialog";
import { socketWrapper } from "../socket/socketWrapper";
import { menuItems } from "../utils/menuItems";

function Header({
  loggedIn,
  appBody,
  logout,
  currentUser,
  appHotkeySets,
  history,
  showLeftSlideOutDrawer,
  setshowLeftSlideOutDrawer
}) {
  useEffect(() => {
    if (currentUser?.id) {
      socketWrapper.init(currentUser);
    }
  }, [currentUser]);

  const userExtra = [
    {
      text: "Help",
      icon: "help",
      onClick: () => {
        window.frontEndConfig.localDocs
          ? openInNewTab("html_files/collections/313799-build-module.html")
          : window.open("https://docs.teselagen.com/", "_blank");
      }
    }
  ];

  if (window.frontEndConfig.showDocumentation) {
    userExtra.push({
      text: "Documentation",
      icon: "book",
      onClick: () => {
        window.location.href = "/user-docs/index.html";
      }
    });
  }

  const leftMenuItems = [];

  return (
    <PlatformHeader
      loggedIn={loggedIn}
      appBody={appBody}
      logout={logout}
      backgroundColor={getAppColor()}
      appName={window.frontEndConfig.appTitle || "TeselaGen"}
      menuItems={menuItems}
      leftMenuItems={leftMenuItems}
      history={history}
      userExtraItems={userExtra}
      onSearch={null}
      showLeftSlideOutDrawer={showLeftSlideOutDrawer}
      setshowLeftSlideOutDrawer={setshowLeftSlideOutDrawer}
      hotkeySets={appHotkeySets}
      extraContent={[
        ...(!window.frontEndConfig.disabledToolkits
          .materialsAndInventoryManagement
          ? [
              <Popover
                key="barcode"
                position="bottom-right"
                minimal
                content={
                  <Menu>
                    <MenuItem
                      text="Open Barcoded Item"
                      onClick={() => {
                        showDialog({
                          ModalComponent: BarcodeHelperDialog
                        });
                      }}
                    />
                    <MenuItem
                      text="Move Barcoded Item"
                      onClick={() => {
                        showDialog({
                          ModalComponent: MoveBarcodedItemDialog
                        });
                      }}
                    />
                  </Menu>
                }
              >
                <Tooltip content="Open or move item by scanning/entering a barcode">
                  <Button
                    icon="barcode"
                    className="tg-barcode-helper-button"
                    minimal
                  />
                </Tooltip>
              </Popover>
            ]
          : []),
        <NotificationPopover
          currentUser={currentUser}
          key="NotificationPopover"
        />
        // <HelperBtn key="helper"></HelperBtn>
      ]}
    />
  );
}

export { Header };
