/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default function (modelName, results, variables) {
  if (window.Cypress && window.Cypress.logGraphql) {
    window.Cypress.graphqlLog = window.Cypress.graphqlLog || [];
    // if we are only capturing specific requests
    if (
      window.Cypress.logGraphqlModel &&
      modelName !== window.Cypress.logGraphqlModel
    ) {
      return;
    }
    window.Cypress.graphqlLog.push({
      model: modelName,
      results,
      variables
    });
  }
}
