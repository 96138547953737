/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

export default {
  // defaultSubtype: "COLONY_PICKING_FEEDBACK",
  // subtypes: ['DNA_SEQUENCE', "COLONY_PICKING_FEEDBACK"],
  // subtypes: ["COLONY_PICKING_FEEDBACK"],
  description:
    "Hit an endpoint and receive a worklist; Used in conjunction with the 'Create Custom Worklist' Tool",
  endpoints: {
    CUSTOM_WORKLIST: {
      name: "Create Custom Worklist",
      method: "POST",
      description: "Get worklist from endpoint",
      exampleRequest: {
        __validate__: () => {
          const nullString = yup.string().nullable();
          const nullNum = yup.number().nullable();
          const plateShape = yup.array(
            yup.object({
              id: yup.string(),
              name: yup.string(),
              barcode: yup.string(),
              format: yup
                .object({
                  name: yup.string(),
                  columnCount: yup.number(),
                  rowCount: yup.number()
                })
                .required(),
              contents: yup.array(
                yup.object({
                  position: yup.string(),
                  volume: nullNum,
                  "volume unit": nullString,
                  mass: nullNum,
                  "mass unit": nullString,
                  concentration: nullNum,
                  "concentration unit": nullString,
                  molarity: nullNum,
                  "molarity unit": nullString,
                  materials: yup.array(yup.string()),
                  additives: yup.array(yup.string())
                })
              )
            })
          );
          return yup.object({
            plateNamePrefix: yup.string(),
            newPlateType: yup
              .object({
                name: yup.string().required(),
                rowCount: yup.number().required(),
                columnCount: yup.number().required()
              })
              .default(undefined),
            sourcePlates: plateShape,
            destinationPlates: plateShape,
            reactionMap: yup.object({
              id: yup.string(),
              name: yup.string(),
              reactions: yup.array(
                yup.object({
                  reactionInputs: yup.array(
                    yup.object({
                      inputMaterial: yup
                        .object({
                          id: yup.string(),
                          name: yup.string()
                        })
                        .nullable(),
                      inputAdditiveMaterial: yup
                        .object({
                          id: yup.string(),
                          name: yup.string()
                        })
                        .nullable()
                    })
                  ),
                  reactionOutputs: yup.array(
                    yup.object({
                      outputMaterial: yup
                        .object({
                          id: yup.string(),
                          name: yup.string()
                        })
                        .nullable(),
                      outputAdditiveMaterial: yup
                        .object({
                          id: yup.string(),
                          name: yup.string()
                        })
                        .nullable()
                    })
                  )
                })
              )
            }),
            encodedFiles: yup.array(
              yup.object({
                type: yup.string(),
                base64Data: yup.string(),
                name: yup.string()
              })
            )
          });
        },
        plateNamePrefix: "my plates ",
        newPlateType: {
          name: "96 Well",
          rowCount: 8,
          columnCount: 12
        },
        reactionMap: {
          id: "12109",
          name: "reaction map",
          reactions: [
            {
              reactionInputs: [
                {
                  inputMaterial: {
                    id: "90128948",
                    name: "material 1"
                  }
                }
              ],
              reactionOutputs: [
                {
                  outputMaterial: {
                    id: "90128948",
                    name: "material 1"
                  }
                }
              ]
            }
          ]
        },
        sourcePlates: [
          {
            id: "22",
            name: "plate 1",
            barcode: "Z100272",
            format: {
              name: "96 Well",
              rowCount: 8,
              columnCount: 12
            },
            contents: [
              {
                position: "A1",
                volume: 23,
                "volume unit": "uL",
                materials: ["pj1005"],
                additives: ["TB+Kan"]
              }
            ]
          }
        ],
        destinationPlates: [
          {
            id: "28",
            name: "plate 2",
            barcode: "Z100279",
            format: {
              name: "48 Well",
              rowCount: 8,
              columnCount: 6
            },
            contents: [
              {
                position: "B3",
                volume: 23,
                "volume unit": "uL",
                materials: ["pj1007"]
              }
            ]
          }
        ],
        encodedFiles: [
          {
            type: "txt/csv",
            base64Data: "Im base 64 data 29382982uf",
            name: "someFile.txt"
          }
        ]
      },
      exampleResponse: {
        __validate__: () => {
          const strReq = yup.string().required();
          const str = yup.string();
          return yup.object({
            name: yup.string(),
            transfers: yup
              .array(
                yup.object({
                  transferVolume: yup.number().required(),
                  transferVolumeUnit: strReq,
                  sourcePlateId: strReq,
                  sourceWell: strReq,
                  destinationPlateId: str,
                  destinationPlateName: str,
                  destinationPlateBarcode: str,
                  destinationPlateTypeId: str,
                  destinationWell: strReq
                })
              )
              .required()
          });
        },
        name: "custom worklist",
        transfers: [
          {
            transferVolume: 2,
            transferVolumeUnit: "uL",
            sourcePlateId: "212",
            sourceWell: "A2",
            destinationPlateId: "389",
            destinationWell: "B5"
          },
          {
            transferVolume: 25,
            transferVolumeUnit: "uL",
            sourcePlateId: "5",
            sourceWell: "A3",
            destinationPlateBarcode: "Z100067",
            destinationWell: "C3"
          },
          {
            transferVolume: 33,
            transferVolumeUnit: "uL",
            sourcePlateId: "5",
            sourceWell: "A3",
            destinationWell: "D3",
            destinationPlateName: "New Plate",
            destinationPlateBarcode: "Z100068",
            destinationPlateTypeId: "10"
          }
        ]
      }
    }
  }
};
