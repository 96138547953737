/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Link } from "react-router-dom";
import React from "react";

export default function getIsLinkedCellRenderer(path) {
  return {
    [path]: value => {
      if (value) {
        return <Link to={"/materials/" + value}>View Material</Link>;
      } else {
        return "Unlinked";
      }
    }
  };
}
