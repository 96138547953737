/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { pcrPlanningJ5PcrReaction } from "../../../graphql/fragments/pcrPlanningj5ReportFragment";

export const pcrPlanningSourcePlateMapGroupFragment = gql`
  fragment pcrPlanningSourcePlateMapGroupFragment on plateMapGroup {
    id
    name
    containerFormat {
      code
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
    }
    plateMaps {
      id
      name
      type
      plateMapItems {
        id
        rowPosition
        columnPosition
        j5Item {
          id
          j5PcrReaction {
            ...pcrPlanningJ5PcrReaction
          }
        }
      }
    }
  }
  ${pcrPlanningJ5PcrReaction}
`;
