/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get, difference, keyBy } from "lodash";
import {
  getPlateAliquotType,
  sampleStatusColumn,
  getAliquotContainerAdditiveString,
  getAliquotMaterialString,
  renderMaterialsField,
  getAliquotMaterialList,
  renderLocation
} from "../../../utils/plateUtils";
import { getAlphnumericWellLocation } from "../../../../src-shared/utils/getAlphnumericWellLocation";

import { containerArrayRecordViewAliquotContainerFragment } from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";
import {
  convertVolume,
  withUnitGeneric
} from "../../../../src-shared/utils/unitUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import {
  safeUpsert,
  safeDelete,
  safeQuery
} from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  sortAliquotContainers
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import {
  labGroupColumn,
  projectColumnNested
} from "../../../../src-shared/utils/libraryColumns";
import { tagColumnWithRenderNested } from "../../../../src-shared/utils/tagColumn";
import { startCase } from "lodash";
import { Tooltip } from "@blueprintjs/core";
import withLibraryExtendedPropertyColumns from "../../../../src-shared/enhancers/withLibraryExtendedPropertyColumns";
import { compose, withProps } from "recompose";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { ExportTableAsCsvDialog } from "../../../../src-shared/ExportTableAsCsvButton";
import { getSequence } from "../../../../../tg-iso-shared/src/utils/getSequence";
import { SelectField } from "@teselagen/ui";
import { camelCase } from "lodash";

const testTubeTableSchema = [
  {
    path: "location",
    type: "string",
    displayName: "Location",
    getValueToFilterOn: getAliquotContainerLocation,
    sortFn: ["rowPosition", "columnPosition"]
  },
  { path: "name", displayName: "Tube Name" },
  {
    path: "barcode.barcodeString",
    type: "string",
    displayName: "Tube Barcode"
  },
  {
    path: "aliquot.sample.name",
    type: "string",
    displayName: "Sample Name"
  }
];

const plateWellsSchemaBasic = [
  {
    path: "location",
    type: "string",
    displayName: "Well Location",
    getValueToFilterOn: getAliquotContainerLocation,
    sortFn: ["rowPosition", "columnPosition"],
    render: renderLocation
  },
  {
    path: "aliquot.sample.name",
    type: "string",
    displayName: "Sample Name",
    render: (v, r) => {
      if (v) {
        return <Link to={modelNameToLink(r.aliquot.sample)}>{v}</Link>;
      }
    }
  }
];

export function getPlateTableSchema({
  containerArray: _containerArray,
  containerArrays,
  hasAliquotExtProps,
  aliquotExtendedPropertyFields,
  simpleSchema,
  withSampleStatus
}) {
  const containerArray = _containerArray || containerArrays[0];
  const isPlate = containerArray.containerArrayType.isPlate;
  const schema = isPlate ? plateWellsSchemaBasic : testTubeTableSchema;
  const plateAliquotType = getPlateAliquotType(
    containerArray.aliquotContainers
  );
  const wetColumns = [
    {
      path: "aliquot.volume",
      type: "number",
      displayName: "Volume",
      render: (v, r) => {
        if (r.aliquot) {
          return withUnitGeneric(
            "aliquot.volume",
            "aliquot.volumetricUnitCode"
          )(v, r);
        } else {
          if (r.additives.length) {
            let unitToUse;
            let volumeSum = 0;
            r.additives.forEach(a => {
              if (a.volume) {
                if (!unitToUse) {
                  unitToUse = a.volumetricUnitCode;
                }
                volumeSum += convertVolume(
                  a.volume,
                  a.volumetricUnitCode,
                  unitToUse
                );
              }
            });
            return withUnitGeneric(
              "v",
              "u"
            )({
              v: volumeSum,
              u: unitToUse
            });
          }
        }
      }
    },
    {
      path: "aliquot.concentration",
      type: "number",
      displayName: "Concentration",
      render: withUnitGeneric(
        "aliquot.concentration",
        "aliquot.concentrationUnitCode"
      )
    }
  ];
  const dryColumns = [
    {
      path: "aliquot.mass",
      type: "number",
      displayName: "Mass",
      render: withUnitGeneric("aliquot.mass", "aliquot.massUnitCode")
    }
  ];
  const schemaToUse = [...schema];

  let hasAdditives = false;
  let hasImportCollection = false;
  let hasSampleStatus = false;
  let hasSampleType = false;
  let hasMolarity = false;

  let hasProjects = false;
  let hasTags = false;
  let hasCellConcentration = false;
  let hasCellCount = false;

  const checkForColumns = containerArray => {
    containerArray.aliquotContainers.forEach(ac => {
      hasImportCollection =
        hasImportCollection || get(ac, "aliquot.importCollection.name");
      hasAdditives =
        hasAdditives ||
        get(ac, "aliquot.additives.length") ||
        get(ac, "additives.length");
      hasMolarity = hasMolarity || get(ac, "aliquot.molarity");
      hasSampleStatus =
        hasSampleStatus || get(ac, "aliquot.sample.sampleStatus");
      hasSampleType = hasSampleType || get(ac, "aliquot.sample.sampleTypeCode");
      hasTags = hasTags || get(ac, "aliquot.taggedItems[0]");
      hasProjects = hasProjects || get(ac, "aliquot.projectItems[0]");
      hasCellConcentration =
        hasCellConcentration || get(ac, "aliquot.cellConcentration");
      hasCellCount = hasCellCount || get(ac, "aliquot.cellCount");
    });
  };

  if (containerArrays?.length) {
    containerArrays.forEach(ca => {
      checkForColumns(ca);
    });
  } else {
    checkForColumns(containerArray);
  }

  wetColumns.push({
    path: "aliquot.molarity",
    type: "number",
    displayName: "Molarity",
    render: withUnitGeneric("aliquot.molarity", "aliquot.molarityUnitCode"),
    isHidden: !hasMolarity
  });
  wetColumns.push({
    path: "aliquot.cellConcentration",
    type: "number",
    displayName: "Cell Concentration",
    render: withUnitGeneric(
      "aliquot.cellConcentration",
      "aliquot.cellConcentrationUnitCode"
    ),
    isHidden: !hasCellConcentration
  });
  schemaToUse.push({
    path: "aliquot.cellCount",
    type: "number",
    displayName: "Cell Count",
    isHidden: !hasCellCount
  });

  if ((!simpleSchema || withSampleStatus) && hasSampleStatus) {
    schemaToUse.push(sampleStatusColumn);
  }
  schemaToUse.push({
    path: "aliquot",
    type: "string",
    displayName: "Material Name",
    getValueToFilterOn: aliquotContainer =>
      getAliquotMaterialString(aliquotContainer.aliquot),
    render: aliquot => {
      return renderMaterialsField(aliquot);
    }
  });

  if (!simpleSchema) {
    schemaToUse.push({
      path: "aliquot.sample.material.polynucleotideMaterialSequence.sequenceType.name",
      type: "string",
      displayName: "DNA Type"
    });
    schemaToUse.push({
      path: "aliquot.sample.material.polynucleotideMaterialSequence.size",
      type: "number",
      displayName: "Sequence Size"
    });
    schemaToUse.push({
      path: "aliquot.id",
      type: "string",
      displayName: "Aliquot ID",
      getClipboardData: aliquotId => aliquotId,
      render: (val, r) => {
        if (val) {
          return <Link to={modelNameToLink(r.aliquot)}>{val}</Link>;
        }
      }
    });
    if (hasSampleType) {
      schemaToUse.push({
        displayName: "Sample Type",
        path: "aliquot.sample.sampleTypeCode",
        render: v => v && startCase(v.toLowerCase())
      });
    }

    const extraColumns = [];
    if (hasTags) {
      extraColumns.push({
        ...tagColumnWithRenderNested("aliquot"),
        displayName: "Aliquot Tags"
      });
    }
    if (hasProjects) {
      extraColumns.push({
        ...projectColumnNested("aliquot"),
        displayName: "Aliquot Projects"
      });
    }
    extraColumns.push({
      ...labGroupColumn,
      isHidden: true
    });
    extraColumns.forEach(col => {
      if (col.path) {
        schemaToUse.push({
          ...col,
          path: "aliquot." + col.path
        });
      } else {
        schemaToUse.push(col);
      }
    });
  }

  // if there are additives then we want to display them in the table
  // we want additives to come before the unit columns
  if (hasAdditives) {
    schemaToUse.push({
      displayName: "Additives",
      render: (_, r) => {
        return getAliquotContainerAdditiveString(r);
      }
    });
  }

  if (plateAliquotType === "wet" || plateAliquotType === "mixed") {
    schemaToUse.push(...wetColumns);
  }
  if (plateAliquotType === "dry" || plateAliquotType === "mixed") {
    schemaToUse.push(...dryColumns);
  }

  // add import collection column
  if (hasImportCollection) {
    schemaToUse.push({
      path: "aliquot.importCollection.name",
      displayName: "Import Collection"
    });
  }
  if (hasAliquotExtProps) {
    schemaToUse.push(...aliquotExtendedPropertyFields);
  }

  if (!simpleSchema) {
    schemaToUse.push({
      path: "aliquot",
      type: "string",
      isHidden: true,
      displayName: "Material ID",
      render: aliquot => {
        return getAliquotMaterialList(aliquot)
          .map(m => m.id)
          .join(", ");
      }
    });
    schemaToUse.push({
      path: "aliquot.sample.id",
      type: "string",
      isHidden: true,
      displayName: "Sample ID"
    });
    schemaToUse.push({
      displayName: "Plate ID",
      render: (v, r) => r.containerArray.id,
      isHidden: true
    });
    schemaToUse.push({
      displayName: "Plate Barcode",
      render: (v, r) => get(r.containerArray, "barcode.barcodeString"),
      isHidden: true
    });
    schemaToUse.push({
      displayName: "Plate Name",
      render: (v, r) => r.containerArray.name,
      isHidden: true
    });
  }

  return schemaToUse;
}

export async function handlePlateReformat({
  containerArray,
  newAliquotContainers: acs,
  onFinish
}) {
  try {
    const originalAcs = generateContainerArray(
      containerArray.aliquotContainers,
      containerArray.containerArrayType.containerFormat
    );

    const locToAc = createLocationToContainerMap(acs);
    const locToOrigAc = createLocationToContainerMap(originalAcs);

    // moving aliquots
    if (containerArray.containerArrayType.isPlate) {
      const valuesToUpsert = [];
      for (const loc of Object.keys(locToAc)) {
        const ac = locToAc[loc];
        const origAc = locToOrigAc[loc];

        const aliquotId = get(ac, "aliquot.id") || null;
        const origAliquotId = get(origAc, "aliquot.id") || null;

        if (aliquotId !== origAliquotId) {
          valuesToUpsert.push({ id: ac.id, aliquotId });
        }
      }
      await safeUpsert(
        containerArrayRecordViewAliquotContainerFragment,
        valuesToUpsert
      );

      const originalAliquotIds = Object.keys(
        keyBy(
          originalAcs.filter(ac => ac.aliquot),
          "aliquot.id"
        )
      );
      const aliquotsIds = Object.keys(
        keyBy(
          acs.filter(ac => ac.aliquot),
          "aliquot.id"
        )
      );
      const deletedAliquotIds = difference(originalAliquotIds, aliquotsIds);
      await safeDelete("aliquot", deletedAliquotIds);
    } else {
      // moving tubes
      const originalAcIds = originalAcs.reduce((acc, ac) => {
        if (ac.id) acc.push(ac.id);
        return acc;
      }, []);
      const newAcIds = acs.reduce((acc, ac) => {
        if (ac.id) acc.push(ac.id);
        return acc;
      }, []);
      // unlink tubes that have been overridden
      const unlinkedAliquotContainerIds = difference(originalAcIds, newAcIds);
      await safeUpsert(
        containerArrayRecordViewAliquotContainerFragment,
        unlinkedAliquotContainerIds.map(acId => ({
          id: acId,
          containerArrayId: null,
          rowPosition: null,
          columnPosition: null
        }))
      );

      // move tubes
      const keyedNewAcs = acs.reduce((acc, ac) => {
        if (ac.id) acc[ac.id] = ac;
        return acc;
      }, {});

      const updatedAcs = [];
      originalAcs.forEach(ac => {
        const newAc = keyedNewAcs[ac.id];
        if (ac.id && newAc) {
          const hasMoved =
            newAc.rowPosition !== ac.rowPosition ||
            newAc.columnPosition !== ac.columnPosition;
          if (hasMoved) {
            updatedAcs.push({
              id: ac.id,
              rowPosition: newAc.rowPosition,
              columnPosition: newAc.columnPosition
            });
          }
        }
      });

      await safeUpsert(
        containerArrayRecordViewAliquotContainerFragment,
        updatedAcs
      );
    }

    onFinish();
  } catch (e) {
    console.error(e);
    window.toastr.error("Error saving plate reformat.");
  }
}

export function createLocationToContainerMap(aliquotContainers) {
  const locations = getAlphnumericWellLocation(aliquotContainers);
  const map = {};
  locations.forEach((loc, i) => {
    map[loc] = aliquotContainers[i];
  });
  return map;
}

export const plateRecordCellRenderer = () => {
  return {
    location: (value, record) => {
      return getAliquotContainerLocation({
        ...record,
        containerArrayType: record.containerArray.containerArrayType
      });
    },
    "aliquot.importCollection.name": (value, r) => {
      const aliquotImportCollectionId = get(r, "aliquot.importCollection.id");
      const plateImportCollectionId = get(
        r.containerArray,
        "importCollection.id"
      );
      if (
        aliquotImportCollectionId &&
        plateImportCollectionId &&
        plateImportCollectionId !== aliquotImportCollectionId
      ) {
        return (
          <Tooltip content="This aliquot is not in the same collection as this plate.">
            <span
              className="aliquot-outside-of-plate-import-collection"
              style={{ fontWeight: "700" }}
            >
              {value}
            </span>
          </Tooltip>
        );
      }
      return value;
    }
  };
};

export function getPlateRecordEntities(
  containerArray,
  columnOrder = "rowFirst"
) {
  let entities = [];
  if (containerArray) {
    entities = containerArray.aliquotContainers;
    if (containerArray.containerArrayType.isPlate) {
      entities = entities.filter(
        entity => entity.aliquot || entity.additives.length
      );
    }
    entities = entities.map(e => {
      return {
        ...e,
        containerArray
      };
    });
    entities = sortAliquotContainers(entities, columnOrder);
  }
  return entities;
}

export const hasAliquotExtendedPropsHelper = () =>
  compose(
    withLibraryExtendedPropertyColumns({
      recordPath: "aliquot",
      model: "aliquot",
      inDialog: true,
      isLocalTable: true
    }),
    withProps(props => ({
      aliquotExtendedPropertyFields: props.extendedPropertyFields,
      hasAliquotExtProps: props.hasExtendedProperties
    }))
  );

/**
 *
 * @param {object} params
 * @param {any[]} params.containerArrays - Array of container arrays
 * @param {boolean=} params.hasAliquotExtProps - Whether or not the aliquots have extended properties
 * @param {any[]=} params.aliquotExtendedPropertyFields - Extended property fields
 * @param {function=} params.onClose - Function to close the dialog
 */
export function showPlateRecordTableExport({
  containerArrays,
  hasAliquotExtProps = false,
  aliquotExtendedPropertyFields,
  onClose
}) {
  const transformEntities = async (records, fieldsToExport, values) => {
    const exportingBasePairs = fieldsToExport.some(
      f => f.path === "sequenceBPs"
    );
    if (exportingBasePairs) {
      const sequenceIds = [];
      const pathToSequenceId =
        "aliquot.sample.material.polynucleotideMaterialSequence.id";
      records.forEach(r => {
        const sequenceId = get(r, pathToSequenceId);
        if (sequenceId) {
          sequenceIds.push(sequenceId);
        }
      });
      if (sequenceIds.length) {
        const sequences = await safeQuery(
          [
            "sequence",
            /* GraphQL */ `
              {
                id
                sequenceFragments {
                  id
                  index
                  fragment
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: sequenceIds
              }
            }
          }
        );
        const sequenceIdToBps = {};
        sequences.forEach(s => {
          sequenceIdToBps[s.id] = getSequence(s);
        });
        records = records.map(r => {
          const sequenceId = get(r, pathToSequenceId);
          return {
            ...r,
            sequenceBPs: sequenceIdToBps[sequenceId]
          };
        });
      }
    }
    if (values.sorting) {
      records = sortAliquotContainers(records, camelCase(values.sorting));
    }
    return records;
  };

  let entities = [];
  containerArrays.forEach(containerArray => {
    entities = entities.concat(getPlateRecordEntities(containerArray));
  });
  const schema = getPlateTableSchema({
    containerArrays,
    hasAliquotExtProps,
    aliquotExtendedPropertyFields
  });
  showDialog({
    ModalComponent: ExportTableAsCsvDialog,
    onClose,
    modalProps: {
      schema: [
        ...schema,
        {
          displayName: "Sequence",
          path: "sequenceBPs"
        }
      ],
      transformEntities,
      entities,
      ExtraFields: () => {
        return (
          <SelectField
            name="sorting"
            label="Sorting"
            defaultValue="Row First"
            options={["Row First", "Column First"]}
          />
        );
      },
      filename:
        containerArrays.length === 1 ? containerArrays[0].name : "plates",
      text: "Export as List CSV",
      cellRenderer: plateRecordCellRenderer()
    }
  });
}
