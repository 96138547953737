/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

/**
 * When used to query all of the aliquots participating in a formulation, the fragment
 * will provide us with all of the information needed to execute the formulation.
 */
export default gql`
  fragment aliquotFormulationFragment on aliquot {
    id

    isDry
    aliquotType

    volume
    volumetricUnitCode

    concentration
    concentrationUnitCode

    mass
    massUnitCode

    molarity
    molarityUnitCode

    cellCount
    cellConcentration
    cellConcentrationUnitCode

    sample {
      id

      name
      sampleTypeCode

      materialId

      material {
        id

        functionalProteinUnit {
          id
          molecularWeight
        }

        polynucleotideMaterialSequence {
          id
          molecularWeight
        }
      }

      sampleFormulations {
        id
        materialCompositions {
          id
          materialId
        }
      }
    }

    additives {
      id

      volume
      volumetricUnitCode

      concentration
      concentrationUnitCode

      mass
      massUnitCode

      molarity
      molarityUnitCode

      materialConcentration
      materialConcentrationUnitCode

      additiveMaterialId
      lotId

      lot {
        id
        additiveMaterialId
      }
    }
  }
`;
