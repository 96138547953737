/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  InputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";

import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";
import { withProps } from "recompose";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class CreateInductionMethodDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop } = this.props;
    const { id: updatedId, name, description } = values;
    try {
      await safeUpsert(["inductionMethod", "id name description"], {
        id: updatedId,
        name,
        description
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating induction method");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit, asyncValidating } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            isRequired
            label="Name"
            rightElement={
              <AsyncValidateFieldSpinner validating={asyncValidating} />
            }
          />
          <TextareaField name="description" label="Description" />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Induction Method"
          : "Create Induction Method"
      };
    }
  }),
  withProps(() => ({ model: "inductionMethod" })),
  reduxForm({
    form: "createInductionMethod",
    ...asyncValidateName
  })
)(CreateInductionMethodDialog);
