/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { bottle } from "../../../../tg-iso-lims/src/dependencyContainer";
import { clientLogger, serverLogger } from "../../Loggers";
import { Logger } from "@teselagen/logger";

export const initLogger = () => {
  bottle.factory("logger", () => {
    return new Logger([clientLogger, serverLogger]);
  });
  // eslint-disable-next-line no-console
  console.log = (...message) => bottle.container.logger.log(...message);
  console.error = (...message) => bottle.container.logger.error(...message);
  console.info = (...message) => bottle.container.logger.info(...message);
  console.warn = (...message) => bottle.container.logger.warn(...message);
};
