/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";

function RecordLink({ record }) {
  if (!record) return "N/A";
  return <Link to={modelNameToLink(record)}>{record.name}</Link>;
}

export default RecordLink;
