/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { inject, observer } from "mobx-react";
import { Alert } from "@blueprintjs/core";
import { get } from "lodash";
import modelNameToReadableName from "../utils/modelNameToReadableName";

export default inject("mainStore")(
  observer(
    ({
      mainStore: {
        alert: { open, props, close }
      }
    }) => {
      const { remove, model, plural } = props;
      const readableName = modelNameToReadableName(model, { plural });
      const defaultMessage = `Are you sure you want to delete \
          ${plural ? "these" : "this"} ${readableName}? You will not \
          be able to restore ${plural ? "them" : "it"} later.`;
      return (
        <Alert isOpen={open} onCancel={close} {...props.get()}>
          {remove ? defaultMessage : get(props.get(), "content")}
        </Alert>
      );
    }
  )
);
