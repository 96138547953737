/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import PropTypes from "prop-types";
import { InputField } from "@teselagen/ui";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import { getToolOutputItems } from "../../utils";

function ReactionMapNameField({ toolSchema, ...rest }) {
  const outputItems = getToolOutputItems(toolSchema);
  const reactionMapOutputItem = outputItems.find(
    item => item.name === "reactionMap"
  );

  return (
    <InputField
      name="reactionMapName"
      label="Reaction Map Name"
      placeholder="Enter reaction map name..."
      isRequired
      {...rest}
      generateDefaultValue={{
        ...defaultValueConstants.REACTION_MAP_NAME,
        ...(reactionMapOutputItem && {
          customParams: {
            outputType: reactionMapOutputItem.defaultValue
          }
        })
      }}
    />
  );
}

ReactionMapNameField.propTypes = {
  toolSchema: PropTypes.object.isRequired
};

export default ReactionMapNameField;
