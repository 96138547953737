/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { branch, compose, withProps } from "recompose";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../withQuery";
import { reduxForm } from "redux-form";

const WithQueryWrapper = InnerComp => {
  return function WithQueryWrapper(props) {
    const Inner = useMemo(() => {
      const addTableParams = withTableParams({
        withSelectedEntities: true,
        noOrderError: true,
        doNotCoercePageSize: true,
        defaults: {
          order: ["-modified"]
        },
        entities: props.entities,
        isLocalCall: props.isLocalCall
      });

      // Only perform query if fragment is present
      if (props.fragment) {
        return compose(
          addTableParams,
          withQuery(props.fragment, {
            isPlural: true,
            options: props.queryOptions,
            useHasura: props.useHasura
          })
        )(InnerComp);
      }

      return addTableParams(InnerComp);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Inner {...props} />;
  };
};

export default compose(
  withProps(props => {
    const { passedName, tableParamOptions } = props;
    return {
      form: passedName + "DataTable",
      formName: passedName + "DataTable",
      ...tableParamOptions
    };
  }),
  branch(props => props.asReactSelect, reduxForm()),
  WithQueryWrapper
);
