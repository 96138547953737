/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { InputField, TextareaField, RadioGroupField } from "@teselagen/ui";

import { safeQuery, safeUpsert } from "../../apolloMethods";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import FormFooter from "../../components/FormFooter";

import "./style.css";
import TagField from "../../TagField";
import { addTaggedItemsBeforeCreate } from "../../../../tg-iso-shared/src/tag-utils";
import CustomHelpDocWidget from "../../CustomHelpDocsManagementPanel/CustomHelpDocWidget";
import customHelpDocConstants from "../../CustomHelpDocsManagementPanel/customHelpDocConstants";
import { Card } from "@blueprintjs/core";

const SequenceAddViewForm = props => {
  const {
    handleSubmit,
    Title,
    submitting,
    isRNA,
    onCreateFromRangeFinished,
    history,
    isOligo,
    noCard,
    noTitle,
    parentSequenceId,
    onCancel
  } = props;

  const inner = (
    <div style={{ width: "100%" }}>
      {!noTitle && (
        <h2 className="edit-view-title">
          Create New {isOligo ? "Oligo" : "Sequence"}
        </h2>
      )}
      <form
        onSubmit={handleSubmit(async ({ tags, ...values }) => {
          values.circular = values.circularSelect === "circular";
          delete values.circularSelect;
          try {
            let sequenceTypeCode;
            if (isRNA) {
              sequenceTypeCode = "RNA";
            } else if (isOligo) {
              sequenceTypeCode = "OLIGO";
            } else if (values.circular) {
              sequenceTypeCode = "CIRCULAR_DNA";
            } else {
              sequenceTypeCode = "LINEAR_DNA";
            }

            const [sequence] = await safeUpsert(
              "sequence",
              addTaggedItemsBeforeCreate(
                {
                  ...values,
                  parentSequenceId,
                  sequenceTypeCode,
                  sequenceFragments: [],
                  size: 0,
                  labId: undefined
                },
                tags
              )
            );
            if (onCreateFromRangeFinished) {
              if (parentSequenceId) {
                //requery for the parentSequenceSequences to update the UI after a new child seq is made
                safeQuery(
                  ["sequence", "id parentSequenceSequences {id name}"],
                  {
                    variables: {
                      id: parentSequenceId
                    }
                  }
                );
              }

              //send the newly created sequence to onCreateFromRangeFinished for additional modifications
              await onCreateFromRangeFinished({ ...values, id: sequence.id });
            } else {
              history.push(
                (isOligo ? "/oligos/" : "/sequences/") + sequence.id
              );
            }
          } catch (error) {
            console.error("error:", error);
            window.toastr.error(
              `Error ${values.id ? "updating" : "creating"} sequence.`
            );
          }
        })}
      >
        {Title}
        <CustomHelpDocWidget
          {...customHelpDocConstants.UPLOAD_DNA_SEQUENCE}
        ></CustomHelpDocWidget>
        <InputField
          generateDefaultValue={
            defaultValueConstants[isOligo ? "OLIGO_NAME" : "SEQUENCE_NAME"]
          }
          autoFocus
          isRequired
          name="name"
          label="Name"
        />
        {isOligo || isRNA ? null : (
          <RadioGroupField
            name="circularSelect"
            defaultValue="linear"
            options={[
              {
                label: "Circular",
                value: "circular"
              },
              {
                label: "Linear",
                value: "linear"
              }
            ]}
          />
        )}
        <TextareaField name="description" label="Description" />
        <TagField />
        <FormFooter
          onCancel={onCancel}
          submitting={submitting}
          history={history}
        />
      </form>
    </div>
  );
  if (noCard) return inner;
  return <Card className="form-layout">{inner}</Card>;
};

const SequenceAddViewFormConnected = reduxForm({
  form: "newSequenceForm",
  enableReinitialize: true
})(SequenceAddViewForm);

const SequenceAddView = props => <SequenceAddViewFormConnected {...props} />;

export { SequenceAddView };

const OligoAddView = props => (
  <SequenceAddViewFormConnected isOligo alias="oligo" {...props} />
);

export { OligoAddView };
