/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { reduxForm } from "redux-form";
import { wrapDialog } from "@teselagen/ui";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { compose } from "recompose";
import shortid from "shortid";

const SelectGenericItemsDialog = ({
  isMultiSelect,
  fragment,
  schema,
  additionalDataFragment,
  tableParamOptions,
  hideModal,
  onSelect,
  noHide
}) => {
  // this key will make sure the nested table form in the generic select gets reset
  const key = useMemo(() => shortid(), []);

  const selectItems = async recordOrRecords => {
    try {
      await onSelect(!isMultiSelect ? recordOrRecords[0] : recordOrRecords);
      if (!noHide) {
        hideModal();
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error saving selection");
    }
  };

  return (
    <GenericSelect
      additionalDataFragment={additionalDataFragment}
      fragment={fragment}
      hideModal={hideModal}
      isMultiSelect={isMultiSelect}
      isRequired
      name={"selectGenericItemsField" + key}
      noDialog
      schema={schema}
      tableParamOptions={tableParamOptions}
      onFieldSubmit={selectItems}
    />
  );
};

export default compose(
  wrapDialog(props => {
    const { fragment, dialogProps, isMultiSelect } = props;
    const model = getModelNameFromFragment(fragment);
    const readableName = modelNameToReadableName(model, {
      plural: isMultiSelect,
      upperCase: true
    });
    return {
      title: "Select " + readableName,
      style: { width: 750 },
      ...dialogProps
    };
  }),
  reduxForm({
    form: "selectGenericItemsForm"
  })
)(SelectGenericItemsDialog);
