/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import {
  exampleRecord,
  searchExample,
  sharedImportResults
} from "../exampleRecords";
import {
  importExportSubtypes,
  importExportSubtypesArray
} from "../importExportSubtypes";

import {
  exampleRecordValidation,
  formatPageValidation
} from "../validationFns";

export const cursorPagingMode = "cursor";
const IMPORT = {
  defaultSubtype: importExportSubtypes.DNA_SEQUENCE,
  subtypes: importExportSubtypesArray,
  description:
    "This adds an option to the 'Import from External DB' button in the Library. For DNA_SEQUENCES, it also adds a 'Right Click > Insert > From External DB' to the Design Editor",
  endpoints: {
    IMPORT__SEARCH_FORMAT: {
      //this is the endpointTypeCode
      isOptional: true,
      name: "Format Search Endpoint",
      method: "GET",
      description:
        "This 'Format' request can optionally be used to configure the UI that the user sees when doing the import. If no endpoint is provided, the default 'Search Term' input will be shown. More info here:  http://docs.teselagen.com/en/articles/4626993-custom-form-inputs-integrations",
      exampleRequest: "",
      exampleResponse: {
        __tgMultipleExamples__: [
          {
            __tgTabTitle__: "Custom Fields",
            title: "Circular Sequence Fields",
            formInputs: [
              {
                isRequired: true,
                fieldType: "SelectField",
                name: "status",
                label: "Status",
                options: [
                  { label: "Complete", value: "complete" },
                  { label: "In Progress", value: "inProgress" },
                  { label: "Abandoned", value: "abandoned" }
                ]
              },
              {
                isRequired: true,
                fieldType: "InputField",
                name: "customSearchTerm",
                label: "Custom Search Term"
              }
            ]
          },
          {
            __tgTabTitle__: "No Search Input",
            noSearchInput: true
          }
        ],
        __validate__: () =>
          yup.lazy(val => {
            return val.skipSearch
              ? yup.object({ skipSearch: yup.boolean() })
              : yup.object(formatPageValidation);
          })
      }
    },
    IMPORT__SEARCH: {
      name: "Search Endpoint",
      description:
        "This endpoint takes in a search term and returns a paged list of results. The results can be paged by numbers or tokens.",
      method: "POST",
      exampleRequest: {
        __tgMultipleExamples__: [
          {
            __tgTabTitle__: "Numbered Paging",
            ...searchExample,
            pageNumber: 1
          },
          {
            __tgTabTitle__: "Token Paging",
            ...searchExample,
            pageToken: "1389hf982h2323"
          }
        ],
        __validate__: () =>
          yup.lazy(val => {
            return yup.object({
              ...(val.pageToken
                ? {
                    pageToken: yup.string().required()
                  }
                : {
                    pageNumber: yup.number().required()
                  }),
              customFields: yup
                .object()
                // eslint-disable-next-line no-template-curly-in-string
                .test(
                  "is-empty",
                  "customFields is an empty object. If using custom formInputs, the integration requires that at least some of those form inputs to be filled out by the user",
                  value =>
                    !val.searchTerm && value
                      ? Object.keys(value).length > 0
                      : true
                ),
              searchTerm: val.customFields
                ? yup.string()
                : yup.string().required()
            });
          })
      },
      exampleResponse: {
        __tgMultipleExamples__: [
          {
            __tgTabTitle__: "Numbered Paging",
            ...sharedImportResults,
            totalResults: 200,
            pageNumber: 1,
            pageSize: 50
          },
          {
            __tgTabTitle__: "Token Paging",
            ...sharedImportResults,
            pagingMode: cursorPagingMode,
            nextToken: "1389hf982h2323"
          }
        ],
        __validate__: () =>
          yup.lazy(val => {
            return yup.object({
              ...(val.pagingMode === cursorPagingMode
                ? {
                    pagingMode: yup.string().required(),
                    nextToken: yup.string()
                  }
                : {
                    totalResults: yup.number(),
                    pageNumber: yup.number(),
                    pageSize: yup.number()
                  }),
              results: yup.array(
                yup.object({
                  id: yup.string().required(),
                  name: yup.string().required(),
                  description: yup.string(),
                  size: yup.number()
                })
              ),
              additionalFieldsToDisplay: yup.array(
                yup.lazy(value => {
                  if (typeof value === "string") {
                    return yup.string().required();
                  }
                  return yup.object({
                    path: yup.string().required(),
                    name: yup.string(),
                    isHidden: yup.boolean()
                  });
                })
              )
            });
          })
      }
    },
    IMPORT__IMPORT: {
      //this is the endpointTypeCode
      name: "Import Endpoint",
      description:
        "This endpoint takes in a single ID for a record and returns the expected JSON for that record.",
      method: "GET", // this must be a get to avoid GCP's 32mb limit
      externalRecordType: importExportSubtypesArray.join("|"),
      exampleRequest: "?id=3558997",
      exampleResponse: {
        __validate__: exampleRecordValidation,
        ...exampleRecord()
      }
    }
  }
};
export default IMPORT;
