/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Button, Classes } from "@blueprintjs/core";
import {
  CheckboxField,
  DialogFooter,
  InputField,
  NumericInputField,
  RadioGroupField
} from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "redux";
import { forEach, get, replace, find } from "lodash";

import { wrapDialog, AdvancedOptions } from "@teselagen/ui";
import { getRangeLength } from "@teselagen/range-utils";
import Promise from "bluebird";

import { hideDialog, showDialog } from "../../GlobalDialog";
import TagField from "../../TagField";
import { waitForSaveToFinish } from "../../utils/handleSequenceSave";
import { safeUpsert } from "../../apolloMethods";
import getSingleCellPcrDesign from "../../utils/getSingleCellPcrDesign";
import shortid from "shortid";
import { Link } from "react-router-dom";
import { socketWrapper } from "../../socket/socketWrapper";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";

const validate = values => {
  const errors = {};
  if (!/^[acgtu]*$/i.test(values.sequence))
    errors.sequence =
      replace(get(values, "sequence"), /[acgtu]/g, "") + " is invalid value";

  return errors;
};

export const CreatePcrPrimersForRegionDialog = compose(
  wrapDialog(() => {
    return {
      title: "Create PCR Primers for Region"
    };
  }),
  reduxForm({
    form: "CreatePcrPrimersForRegionDialog", // a unique name for this form
    enableReinitialize: true,
    validate
  }),
  tgFormValues("useExistingPart")
)(
  ({
    handleSubmit,
    submitting,
    hideModal,
    rangeLength,
    existingPart,
    selectionLayer,
    change,
    useExistingPart
  }) => {
    const labelStyle = { width: 140 };
    const maxPrimerLength = Math.floor(rangeLength / 2); // max primer length to half of the amplicon. For example, if the amplicon is 26 bps then to prevent the primers from annealing to each other the max length would actually be 13
    return (
      <form
        onSubmit={handleSubmit(
          async ({
            useExistingPart,
            name,
            tags,
            strand,
            primerMaxTm,
            primerMinTm,
            primerMaxSize,
            primerMinSize
          }) => {
            const partCid = shortid();
            let newPartId;
            if (useExistingPart) {
              newPartId = existingPart.id;
            } else {
              window.teGlobalStore.dispatch({
                type: "UPSERT_PART",
                payload: {
                  cid: partCid,
                  start: selectionLayer.start,
                  end: selectionLayer.end,
                  type: "misc_feature",
                  forward: strand === "positive",
                  name: name,
                  tags: (tags || []).map(({ id }) => id)
                },
                meta: {
                  editorName: "SequenceEditor"
                }
              });
              await waitForSaveToFinish();
              forEach(window.__lastSavedSequenceData.parts, p => {
                if (p.cid === partCid) {
                  newPartId = p.id;
                }
              });
            }

            if (!newPartId)
              return window.toastr.error(
                "Part Saving Did Not Work Properly! Contact a developer near you."
              );

            const designName = `Simple PCR - ${name}`;
            const defaultParams = getDefaultParamsAsCustomJ5ParamName();
            const paramsToUpsert = {
              ...defaultParams,
              primerMaxTm,
              primerMinTm,
              primerMaxSize,
              primerMinSize,

              ...(rangeLength < defaultParams.minPcrProductBps
                ? { minPcrProductBps: rangeLength }
                : {})
            };

            //add a new custom custom j5 parameter record
            const [{ id: customJ5ParameterId }] = await safeUpsert(
              "customJ5Parameter",
              {
                ...paramsToUpsert,
                name: designName
              }
            );

            const designId = await upsertDesign(
              getSingleCellPcrDesign({
                partId: newPartId,
                partName: name,
                designName,
                customJ5ParameterId
              })
            );

            // The customJ5Parameter record is specific to this design and not a preset
            // thus we update it with 'isLocalToThisDesignId: designId'
            await safeUpsert("customJ5Parameter", {
              id: customJ5ParameterId,
              isLocalToThisDesignId: designId
            });

            hideDialog();
            showDialog({
              ModalComponent: wrapDialog({
                title: "Successfully Created a New Design"
              })(() => {
                return (
                  <div className={Classes.DIALOG_BODY}>
                    Successfully Created a New Design: &nbsp;
                    <Link to={`/designs/${designId}`}>{designName}</Link>
                    <br></br>
                    <br></br>
                    <Button
                      intent="success"
                      onClick={() => {
                        socketWrapper.startJ5({
                          designId,
                          designName
                        });
                        hideDialog();
                      }}
                    >
                      Submit for Assembly
                    </Button>
                  </div>
                );
              })
            });
          }
        )}
      >
        <div className={Classes.DIALOG_BODY}>
          {existingPart && (
            <CheckboxField
              name="useExistingPart"
              label={`Use Existing Part - ${existingPart.name} ?`}
              onChange={(e, val) => {
                if (val) {
                  change("name", existingPart.name);
                  change(
                    "strand",
                    existingPart.forward ? "positive" : "negative"
                  );
                } else {
                  change("name", "");
                  change("strand", "positive");
                }
              }}
            ></CheckboxField>
          )}
          <InputField
            disabled={useExistingPart}
            tooltipError
            autoFocus
            isRequired
            label="Part Name"
            name="name"
          />
          <RadioGroupField
            disabled={useExistingPart}
            label="Strand"
            name="strand"
            options={[
              { label: "Positive", value: "positive" },
              { label: "Negative", value: "negative" }
            ]}
            defaultValue="positive"
          ></RadioGroupField>
          {!useExistingPart && <TagField disabled={useExistingPart}></TagField>}
          <AdvancedOptions
            content={
              <div>
                <NumericInputField
                  {...{
                    defaultValue: 70,
                    tooltipInfo:
                      "Primer3 parameter: the maximum primer Tm (Primer3 default is 70)",
                    labelStyle,
                    label: <span>Primer Max Tm</span>,
                    inlineLabel: true,
                    name: "primerMaxTm"
                  }}
                ></NumericInputField>
                <NumericInputField
                  {...{
                    defaultValue: 60,
                    tooltipInfo:
                      "Primer3 parameter: the minimum primer Tm (Primer3 default is 60)",
                    labelStyle,
                    label: <span>Primer Min Tm</span>,
                    inlineLabel: true,
                    name: "primerMinTm"
                  }}
                ></NumericInputField>
                <NumericInputField
                  {...{
                    defaultValue: Math.min(36, maxPrimerLength),
                    max: maxPrimerLength,
                    tooltipInfo:
                      "Max primer length cannot exceed half the amplicon length. For example, if the amplicon is 26 bps then to prevent the primers from annealing to each other the max primer length would actually be 13. Primer3 parameter: the maximum length of a primer (Primer3 default is 27, maximum is 36)",
                    labelStyle,
                    label: <span>Primer Max Size</span>,
                    inlineLabel: true,
                    name: "primerMaxSize"
                  }}
                ></NumericInputField>
                <NumericInputField
                  {...{
                    defaultValue: Math.min(18, maxPrimerLength),
                    max: maxPrimerLength,
                    tooltipInfo:
                      "Primer3 parameter: the minimum length of a primer (Primer3 default is 18)",
                    labelStyle,
                    label: <span>Primer Min Size</span>,
                    inlineLabel: true,
                    name: "primerMinSize"
                  }}
                ></NumericInputField>
              </div>
            }
          ></AdvancedOptions>
        </div>
        <DialogFooter submitting={submitting} hideModal={hideModal} text="OK" />
      </form>
    );
  }
);

export const getAdditionalCreateOpts = props => {
  if (props.sequenceData && !props.sequenceData.isOligo) {
    const rangeLength = getRangeLength(
      props.selectionLayer,
      props.sequenceData.sequence.length
    );
    return [
      {
        text: "Create PCR primers for this region",
        disabled:
          rangeLength < 36
            ? "Select at least 36 bases to create PCR primers for a region"
            : false,
        onClick: () => {
          showDialog({
            ModalComponent: CreatePcrPrimersForRegionDialog,
            modalProps: {
              existingPart: find(
                props.sequenceData.parts,
                p =>
                  p.start === props.selectionLayer.start &&
                  p.end === props.selectionLayer.end
              ),
              rangeLength,
              selectionLayer: props.selectionLayer
            }
          });
        }
      }
    ];
  } else {
    return [];
  }
};

const upsertDesign = async mutations => {
  let designId = null;
  await Promise.each(mutations, async ({ entity, inputs, forceSequential }) => {
    if (Array.isArray(inputs) && !inputs.length) return;
    else if (forceSequential) {
      return Promise.each(inputs, async input => {
        const response = await safeUpsert(entity, input);
        if (entity === "design") designId = response[0].id;
      });
    } else {
      const response = await safeUpsert(entity, inputs);
      if (entity === "design") designId = response[0].id;
    }
  });
  return designId;
};
