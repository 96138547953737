/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import ReactDOM from "react-dom";

export default function renderOnDoc(fn) {
  const elemDiv = document.createElement("div");
  elemDiv.style.cssText =
    "position:absolute;width:100%;height:100%;top:0px;opacity:0.3;z-index:100;";
  document.body.appendChild(elemDiv);
  const handleClose = () => {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(elemDiv);
      document.body.removeChild(elemDiv);
    });
  };
  return ReactDOM.render(fn(handleClose), elemDiv);
}
