/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const extendedValueFragment = gql`
  fragment extendedValueFragment on extendedValue {
    id
    value
    extendedProperty {
      id
      name
    }
  }
`;
