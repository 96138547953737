/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { map } from "lodash";

export function getDuplicateBarcodeHelper(items) {
  const dupHelper = {};
  items.forEach(item => {
    const barcode = item.barcode.barcodeString;
    if (!dupHelper[barcode]) {
      dupHelper[barcode] = [];
    }
    dupHelper[barcode].push(item);
  });
  Object.keys(dupHelper).forEach(key => {
    if (dupHelper[key].length === 1) delete dupHelper[key];
  });
  return dupHelper;
}

export function getToolOutputItems(_toolSchema) {
  const toolSchema = _toolSchema.toolSchema || _toolSchema;
  const ioItems = toolSchema.output.ioItems;

  return map(ioItems, (v, key) => {
    return {
      name: key,
      label: v.label,
      defaultValue: v.defaultValue || v.label
    };
  });
}
