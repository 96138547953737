/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Tooltip } from "@blueprintjs/core";
import { CollapsibleCard, DataTable } from "@teselagen/ui";
import { get, pick } from "lodash";
import Linkify from "react-linkify";

import AbstractRecord from "../../../../src-shared/AbstractRecord";
import PriorityView from "../../PriorityView";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import jobFragment from "../../../graphql/fragments/jobFragment";
import WorkflowRunJobs from "./WorkflowRunJobs";
import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import CommentCard from "../../../../src-shared/CommentCard";
import WorkQueueJobs from "./WorkQueueJobs";
import { cascadeCustomerRequestTagsToJobsAndWorkflow } from "../CustomerRequestRecordView/utils";
import { formatDate } from "../../../../src-shared/utils/dateUtils";
import MediaQuery from "react-responsive";

class JobRecordView extends Component {
  onCustomerRequestDoubleClick = (record /*, rowIndex, event*/) => {
    const route = "/user-requests/" + record.id;
    this.props.history.push(route);
  };

  updateShowFunction = () => {
    const { job, currentUser, refetchJob } = this.props;

    const initialValues = {
      ...pick(job, [
        "id",
        "name",
        "startDate",
        "priorityTypeCode",
        "dueDate",
        "description"
      ]),
      jobUsers: job.jobUsers.map(ju => ju.user),
      customerRequests: job.customerRequestJobs.map(r => r.customerRequest),
      jobOwner: job.jobOwner,
      initialCustomerRequestJobs: job.customerRequestJobs,
      initialJobUsers: job.jobUsers
    };

    showDialog({
      modalType: "LaunchNewJobDialog",
      modalProps: {
        initialValues,
        currentUser,
        refetch: refetchJob
      }
    });
  };

  afterTag = async ({ selectedTags }) => {
    const { job } = this.props;
    await cascadeCustomerRequestTagsToJobsAndWorkflow({
      jobIds: [job.id],
      selectedTags
    });
  };

  render() {
    const { job, currentUser, readOnly } = this.props;
    const jobUsersData = job.jobUsers.map(jobUser => {
      return {
        id: jobUser.userId,
        username: jobUser.user.username,
        email: jobUser.user.email
      };
    });

    const jobUsersSchema = {
      fields: [
        {
          displayName: "Name",
          path: "username",
          render: (username, record) => {
            return <Tooltip content={record.email}>{username}</Tooltip>;
          }
        },
        {
          displayName: "Email",
          path: "email"
        }
      ]
    };

    const customerRequests = job.customerRequestJobs.map(customerRequestJob => {
      return customerRequestJob.customerRequest;
    });
    const customerRequestData = job.customerRequestJobs.reduce(
      (acc, customerRequestJob) => {
        if (!customerRequestJob.customerRequest) return acc;
        return acc.concat({
          id: customerRequestJob.customerRequestId,
          name: customerRequestJob.customerRequest.name,
          requestType: customerRequestJob.customerRequest.requestType.name,
          dueDate: customerRequestJob.customerRequest.dueDate
        });
      },
      []
    );

    const customerRequestColumns = {
      fields: [
        {
          displayName: "Name",
          path: "name"
        },
        {
          displayName: "Request Type",
          path: "requestType"
        },
        {
          displayName: "Due Date",
          type: "timestamp",
          path: "dueDate"
        }
      ]
    };
    const workflowCard = (
      <WorkflowRunJobs key="workflowRuns" jobId={job.id} readOnly={readOnly} />
    );
    const workQueueCard = <WorkQueueJobs key="workQueues" jobId={job.id} />;

    const extraCards = (
      <MediaQuery maxWidth={600}>
        {onMobile => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: onMobile ? "column" : undefined
              }}
              key="extraCards"
            >
              <div style={{ flex: 1 }}>
                <CollapsibleCard
                  title="Team Members"
                  className="job-team-members-section"
                  icon="people"
                >
                  {job.jobUsers.length ? (
                    <DataTable
                      formName="jobUsersTable"
                      isSimple
                      compact
                      style={{ marginBottom: 20 }}
                      schema={jobUsersSchema}
                      entities={jobUsersData}
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      No assigned team members.
                    </div>
                  )}
                </CollapsibleCard>
                <CollapsibleCard title="User Requests" icon="projects">
                  {customerRequests.length ? (
                    <DataTable
                      formName="customerRequestJobsTable"
                      isSimple
                      compact
                      style={{ marginBottom: 20 }}
                      schema={customerRequestColumns}
                      entities={customerRequestData}
                      onDoubleClick={this.onCustomerRequestDoubleClick}
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      Not assigned to any user requests.
                    </div>
                  )}
                </CollapsibleCard>
              </div>
              {!onMobile && <div style={{ width: 15 }} />}
              <div style={{ flex: 1 }}>
                <CommentCard
                  {...{
                    currentUser,
                    record: job
                  }}
                />
              </div>
            </div>
          );
        }}
      </MediaQuery>
    );

    return (
      <div className="record-container-with-cards">
        <AbstractRecord
          {...this.props}
          afterTag={this.afterTag}
          inCard
          recordName="job"
          updateShowFunction={this.updateShowFunction}
          recordInfo={[
            [
              "Description",
              <Linkify key="description">{job.description}</Linkify>
            ],
            ["Job Owner", get(job, "jobOwner.username")],
            [
              "Priority",
              <PriorityView key="priority" priority={job.priorityType} />
            ],
            ["Start Date", formatDate(job.startDate)],
            ["Due Date", formatDate(job.dueDate)]
          ]}
          additionalCards={[workflowCard, workQueueCard, extraCards]}
        />
      </div>
    );
  }
}

export default recordViewEnhancer(jobFragment)(JobRecordView);
