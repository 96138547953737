/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { Intent, MenuItem } from "@blueprintjs/core";
import {
  genericCommandFactory,
  showConfirmationDialog,
  showDialogOnDocBody
} from "@teselagen/ui";
import { get, uniqBy, cloneDeep, camelCase, pickBy } from "lodash";
import React from "react";
import actions from "../../../src-shared/redux/actions";
import {
  getSelectedCellPaths,
  getSelectedCardId,
  getSelectedBinIds
} from "../../../src-shared/selectors/designViewSelectors";
import createBin from "../../utils/createBin";
import { isDesignLocked } from "../../../src-shared/utils/designUtils/isDesignLocked";
import sIfPlural from "../../../src-shared/utils/sIfPlural";
import {
  getDesign,
  isDesignTemplate,
  getDesignState,
  getItemOfType,
  getAllOfType,
  treeLayout,
  isLayoutList,
  isDisabledSameBinDigestValidation
} from "../../../../tg-iso-design/selectors/designStateSelectors";
import { isClassicDesign } from "../../../src-shared/selectors/classicViewSelectors";
import {
  canZoomIn,
  canZoomOut
} from "../../../src-shared/selectors/miscDesignSelectors";
import createDesignFromTemplate from "../../utils/createDesignFromTemplate";
import { safeDelete } from "../../../src-shared/apolloMethods";
import DesignPrintDialog from "../DesignPrintDialog";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { map } from "lodash";
import NameExampleDesignDialog from "../NameExampleDesignDialog";
import exampleDesignMap from "../../../../tg-iso-design/exampleDesigns";

const selectedCellsWithElements = state =>
  getSelectedCellPaths(state).filter(p => p.elementId);

const didCopy = state => !!state.ui.designEditor.general.copiedCells.length;

const sbolRibbonHidden = state =>
  state.ui.designEditor.general.sbolRibbonHidden;
const inspectorOpen = state => state.ui.designEditor.inspector.open;
const minimapIsShown = state => state.ui.designEditor.tree.showMinimap;
const rowsIndexIsShown = state => state.ui.designEditor.general.showRowsIndex;
const rowNamesIsShown = state => state.ui.designEditor.general.showRowNames;
const rowDisableIsShown = state => state.ui.designEditor.general.showRowDisable;

const loadExample = async name => {
  const exampleDesignJson = cloneDeep(exampleDesignMap[name]);
  showDialog({
    ModalComponent: NameExampleDesignDialog,
    modalProps: {
      exampleDesignJson
    }
  });
};

const exampleDesignMapAvailable = pickBy(
  exampleDesignMap,
  (_, exampleDesignName) => {
    if (
      window.frontEndConfig.disabledToolkits.advancedMolecularBiology &&
      exampleDesignName === "Simple Hierarchical"
    ) {
      return false;
    }
    return true;
  }
);

const exampleDesignCommandDefs = {};
for (const exampleName in exampleDesignMapAvailable) {
  const cmdId = camelCase("load" + exampleName);
  exampleDesignCommandDefs[cmdId] = {
    name: exampleName,
    handler: ({ history }) => {
      loadExample(exampleName, history);
    }
  };
}

export const getCreateExampleDesignMenuItems = ({ history }) =>
  map(exampleDesignMapAvailable, (_, key) => {
    return (
      <MenuItem
        key={key}
        text={key}
        onClick={() => {
          loadExample(key, history);
        }}
      ></MenuItem>
    );
  });

const hiddenWhenLocked = props => isDesignLocked(props.state);

// Design Editor commands
export const commandDefs = {
  duplicate: {
    // isHidden: hiddenWhenLocked,
    handler: ({ state, history }) =>
      showDialog({
        modalType: "DUPLICATE_DESIGN",
        modalProps: {
          designs: [getDesign(state)],
          onSuccess: ([id]) =>
            history.push(
              `/${
                isDesignTemplate(state) ? "design-templates" : "designs"
              }/${id}`
            ),
          refetch: () => {
            return;
          }
        }
      })
  },

  clearDesign: {
    isHidden: hiddenWhenLocked,
    handler: () => actions.design.clearDesign()
  },

  deleteDesign: {
    isHidden: hiddenWhenLocked,
    handler: ({ state, history }) => {
      try {
        showConfirmationDialog({
          text: `Are you sure you want to delete this design? You cannot undo this action.`,
          intent: Intent.DANGER,
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          canEscapeKeyCancel: true
        }).then(async confirm => {
          if (confirm) {
            const design = getDesign(state);
            await safeDelete("design", design.id);
            history.push("/designs");
          }
        });
      } catch (e) {
        console.error(e);
        window.toastr.error("Error deleting design.");
      }
    }
  },

  savePDF: {
    name: "Print",
    handler: ({ state }) => {
      const design = getDesign(state);
      //don't show the print dialog if it is already visible!
      if (document.querySelector(".hdePrintDialog")) return;
      showDialogOnDocBody(DesignPrintDialog, {
        title: "Print",
        addDialogContainer: true,
        designName: design.name
      });
    },
    hotkeyProps: { preventDefault: true },
    hotkey: "mod+p"
  },

  renameDesign: {
    isHidden: hiddenWhenLocked,
    handler: ({ state }) =>
      showDialog({
        modalType: "GENERIC_RENAME",
        modalProps: {
          record: getDesign(state),
          dataType: "design"
        }
      })
  },

  createDesignFromTemplate: {
    isDisabled: ({ state }) => {
      const designId = Object.keys(get(state, "design.design") || {})[0];
      return !designId || !isDesignTemplate(state);
    },
    handler: ({ state, history }) => {
      (async function () {
        try {
          const designState = getDesignState(state);
          const designId = await createDesignFromTemplate(designState);
          history.push(`/designs/${designId}`);
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error creating design from template");
        }
      })();
    }
  },

  exportToJson: {
    handler: () => actions.designIo.exportToJson()
  },

  exportToCsvGenbank: {
    handler: () => actions.designIo.exportToCsvGenbank()
  },

  undo: {
    isHidden: hiddenWhenLocked,
    isDisabled: ({ state }) => !state.ui.designEditor.undo.undoStack.length,
    handler: () => actions.ui.designEditor.undo.undo(),
    hotkey: "mod+z"
  },

  redo: {
    isHidden: hiddenWhenLocked,
    isDisabled: ({ state }) => !state.ui.designEditor.undo.redoStack.length,
    handler: () => actions.ui.designEditor.undo.redo(),
    hotkey: "mod+shift+z"
  },

  setClassicTreeStyle: {
    name: "Standard",
    isDisabled: ({ state }) => {
      const isClassic = isClassicDesign(state);
      if (!isClassic.success) {
        return isClassic.failCase;
      } else {
        return false;
      }
    },
    isActive: ({ state }) => treeLayout(state) === "classic",
    handler: () =>
      actions.ui.designEditor.general.updateViewOptions({
        treeLayout: "classic"
      })
  },

  moveUp: {
    isHidden: hiddenWhenLocked,
    hotkey: "up",
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.ui.designEditor.general.moveActiveCell("up")
  },

  moveDown: {
    isHidden: hiddenWhenLocked,
    hotkey: "down",
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.ui.designEditor.general.moveActiveCell("down")
  },

  moveLeft: {
    isHidden: hiddenWhenLocked,
    hotkey: "left",
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.ui.designEditor.general.moveActiveCell("left")
  },

  moveRight: {
    isHidden: hiddenWhenLocked,
    hotkey: "right",
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.ui.designEditor.general.moveActiveCell("right")
  },

  // REFACTOR TODO: the tree layout code for 'vertical' should have been called 'horizontal'
  setHorizontalTreeStyle: {
    name: "Horizontal (hierarchical)",
    isActive: ({ state }) => treeLayout(state) === "vertical",
    handler: () =>
      actions.ui.designEditor.general.updateViewOptions({
        treeLayout: "vertical"
      })
  },

  // REFACTOR TODO: the tree layout code for 'horizontal' should have been called 'vertical'
  setVerticalTreeStyle: {
    name: "Vertical (hierarchical)",
    isActive: ({ state }) => treeLayout(state) === "horizontal",
    handler: () =>
      actions.ui.designEditor.general.updateViewOptions({
        treeLayout: "horizontal"
      })
  },

  clearParts: {
    isHidden: hiddenWhenLocked,
    name: ({ state }) =>
      `Clear Part${sIfPlural(selectedCellsWithElements(state))}`,
    isDisabled: ({ state }) => !selectedCellsWithElements(state).length,
    handler: ({ state }) =>
      actions.design.removeElements(
        selectedCellsWithElements(state)
          .filter(c => {
            const bin = getItemOfType(state, "bin", c.binId);
            return !bin.isLocked;
          })
          .map(c => c.elementId)
      )
  },

  convertToListLayout: {
    isHidden: hiddenWhenLocked,
    name: `Convert to List Layout`,
    isDisabled: ({ state }) =>
      getDesign(state).layoutType === "list" ||
      Object.keys(state.design.reaction).length !== 1,
    handler: () => actions.design.convertToListLayout()
  },

  toggleDisableSameBinDigestValidation: {
    isActive: ({ state }) => isDisabledSameBinDigestValidation(state),
    isDisabled: ({ state }) => getDesign(state).layoutType === "list",
    handler: () => actions.design.toggleDisableSameBinDigestValidation()
  },

  removeBins: {
    isHidden: hiddenWhenLocked,
    name: ({ state }) =>
      `Remove Bin${sIfPlural(selectedCellsWithElements(state))}`,
    isDisabled: ({ state }) =>
      !getSelectedBinIds(state).length || treeLayout(state) !== "classic", //Not handling deletes for non leaf cards yet
    handler: ({ state }) =>
      actions.design.removeBins({
        cardId: getSelectedCardId(state),
        binIds: getSelectedBinIds(state)
      })
  },

  removeRows: {
    isHidden: hiddenWhenLocked,
    name: ({ state }) =>
      `Remove Row${sIfPlural(uniqBy(getSelectedCellPaths(state), "index"))}`,
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.design.removeRows()
  },

  insertRowAbove: {
    isHidden: hiddenWhenLocked,
    hotkey: "mod+shift+up",
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.design.insertRow({ isAbove: true })
  },

  insertRowBelow: {
    isHidden: hiddenWhenLocked,
    isDisabled: ({ state }) => !getSelectedCellPaths(state).length,
    handler: () => actions.design.insertRow({ isAbove: false }),
    hotkey: "mod+shift+down"
  },

  insertBinLeft: {
    isHidden: hiddenWhenLocked,
    hotkey: "alt+shift+-",
    isDisabled: ({ state }) => !getSelectedBinIds(state).length,
    handler: ({ state, dispatch }) => {
      createBin(
        {
          insertBin: (...args) => dispatch(actions.design.insertBin(...args)),
          selectedCardId: getSelectedCardId(state),
          selectedBinIds: getSelectedBinIds(state)
        },
        true
      );
    }
  },

  insertBinRight: {
    isHidden: hiddenWhenLocked,
    hotkey: "alt+shift+=",
    isDisabled: ({ state }) => !getSelectedBinIds(state).length,
    handler: ({ state, dispatch }) => {
      createBin(
        {
          insertBin: (...args) => dispatch(actions.design.insertBin(...args)),
          selectedCardId: getSelectedCardId(state),
          selectedBinIds: getSelectedBinIds(state)
        },
        false
      );
    }
  },

  insertBinLeftNoPropagation: {
    isHidden: hiddenWhenLocked,
    hotkey: "alt+shift+[",
    isDisabled: ({ state }) => !getSelectedBinIds(state).length,
    handler: ({ state, dispatch }) => {
      createBin(
        {
          insertBin: (...args) => dispatch(actions.design.insertBin(...args)),
          selectedCardId: getSelectedCardId(state),
          selectedBinIds: getSelectedBinIds(state)
        },
        true,
        true
      );
    }
  },

  insertBinRightNoPropagation: {
    isHidden: hiddenWhenLocked,
    hotkey: "alt+shift+]",
    isDisabled: ({ state }) => !getSelectedBinIds(state).length,
    handler: ({ state, dispatch }) => {
      createBin(
        {
          insertBin: (...args) => dispatch(actions.design.insertBin(...args)),
          selectedCardId: getSelectedCardId(state),
          selectedBinIds: getSelectedBinIds(state)
        },
        false,
        true
      );
    }
  },

  zoomIn: {
    hotkey: "alt+=",
    isDisabled: ({ state }) => !canZoomIn(state),
    handler: () => actions.ui.designEditor.tree.zoomIn()
  },

  zoomOut: {
    isDisabled: ({ state }) => !canZoomOut(state),
    hotkey: "alt+-",
    handler: () => actions.ui.designEditor.tree.zoomOut()
  },

  toggleSbolRibbon: {
    name: "Show SBOL Icon Toolbar",
    isActive: ({ state }) => !sbolRibbonHidden(state),
    handler: () => actions.ui.designEditor.general.toggleSbolRibbon()
  },

  toggleInspectorPanel: {
    name: "Show Inspector Panel",
    isActive: ({ state }) => inspectorOpen(state),
    handler: () => actions.ui.designEditor.inspector.toggle()
  },

  showMinimap: {
    isDisabled: ({ state }) => treeLayout(state) === "classic",
    isActive: ({ state }) => minimapIsShown(state),
    handler: () => actions.ui.designEditor.tree.toggleMinimap()
  },

  showRowsIndex: {
    //isDisabled: ({ state }) => layout(state) === "classic",
    isActive: ({ state }) => rowsIndexIsShown(state),
    handler: () => actions.ui.designEditor.general.toggleRowsIndex()
  },
  showRowNames: {
    isHidden: ({ state }) => !isLayoutList(state),
    isActive: ({ state }) => rowNamesIsShown(state),
    handler: () => actions.ui.designEditor.general.toggleRowNames()
  },
  showRowDisable: {
    isHidden: ({ state }) => !isLayoutList(state),
    isActive: ({ state }) => rowDisableIsShown(state),
    handler: () => actions.ui.designEditor.general.toggleShowRowDisable()
  },

  // TODO: check if cut/copy/paste hotkeys should map to generic handlers
  // that trigger specific commands based on some current selection
  copyPart: {
    isHidden: hiddenWhenLocked,
    name: "Copy",
    hotkey: "mod+c",
    isDisabled: ({ state }) => !selectedCellsWithElements(state).length,
    handler: () => actions.ui.designEditor.general.copyCells()
  },

  cutPart: {
    isHidden: hiddenWhenLocked,
    name: "Cut",
    hotkey: "mod+x",
    isDisabled: ({ state }) => !selectedCellsWithElements(state).length,
    handler: () => actions.design.cutCells()
  },

  pastePart: {
    isHidden: hiddenWhenLocked,
    name: "Paste",
    hotkey: "mod+v",
    isDisabled: ({ state }) =>
      !getSelectedCellPaths(state).length || !didCopy(state),
    handler: () => actions.ui.designEditor.general.pasteCells()
  },

  sendDesignToCrickit: {
    isHidden: hiddenWhenLocked,
    handler: ({ state }) => {
      const allIcons = Object.values(getAllOfType(state, "icon"));
      const cdsIconId = allIcons.find(icon => icon.name === "CDS").id;
      const cdsBinIds = Object.values(getAllOfType(state, "bin"))
        .filter(bin => bin.iconId === cdsIconId)
        .map(bin => bin.id);

      return showDialog({
        modalType: "SEND_TO_CRICKIT",
        modalProps: {
          binIds: cdsBinIds
        }
      });
    }
  },

  designRulesets: {
    handler: ({ history }) => history.push("/design-rulesets/")
  },

  ...exampleDesignCommandDefs
};

// TODO move elsewhere
const hdeCommandFactory = (commandDefs, store, history) => {
  return genericCommandFactory({
    commandDefs,
    handleReturn: (cmdId, v) => {
      if (v) store.dispatch(v);
    },
    getArguments: (cmdId, [ctxInfo]) => {
      const state = store.getState();
      return [
        {
          state,
          dispatch: store.dispatch,
          history,
          ctxInfo
        }
      ];
    }
  });
};

export const getCommands = (store, history) => {
  return hdeCommandFactory(commandDefs, store, history);
};
