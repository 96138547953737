/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { DataTable, InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { compose } from "recompose";
import stepFormValues from "../../../../../src-shared/stepFormValues";

const getSchema = j5EntityType => {
  if (j5EntityType === "constructs") {
    return [
      { displayName: "Name", path: "sequence.name" },
      {
        displayName: "Assembly Pieces",
        render: (v, r) =>
          r.j5ConstructAssemblyPieces
            .map(jcap => jcap.assemblyPiece.name)
            .join(", ")
      }
    ];
  } else {
    return [
      { displayName: "Name", path: "name" },
      { displayName: "Primary Template", path: "primaryTemplate.name" },
      { displayName: "Forward Primer", path: "forwardPrimer.name" },
      { displayName: "Reverse Primer", path: "reversePrimer.name" },
      { displayName: "PCR Product", path: "pcrProductSequence.name" }
    ];
  }
};
class ReviewSubsetJ5Report extends Component {
  render() {
    const {
      Footer,
      footerProps,
      pcrReactions = [],
      j5RunConstructs = []
    } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Name Report Subset"
            helper="Provide a name for the new assembly report."
          />
          <div style={{ width: "40%" }}>
            <InputField name="j5ReportName" label="Report Name" isRequired />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <React.Fragment>
            <HeaderWithHelper
              header="Review Report Subset"
              helper="Review the assembly and PCR reactions to be added to the output assembly report subset."
            />
            {pcrReactions.length > 0 && (
              <React.Fragment>
                <h6> PCR Reactions </h6>
                <DataTable
                  formName="pcrReactions"
                  entities={pcrReactions}
                  schema={getSchema("pcrReactions")}
                  isSimple
                />
              </React.Fragment>
            )}
            {j5RunConstructs.length > 0 && (
              <div style={{ marginTop: 20 }}>
                <h6> Assembly Reactions </h6>
                <DataTable
                  formName="constructs"
                  entities={j5RunConstructs}
                  schema={getSchema("constructs")}
                  isSimple
                />
              </div>
            )}
          </React.Fragment>
        </div>
        <Footer {...footerProps} />
      </React.Fragment>
    );
  }
}

export default compose(stepFormValues("pcrReactions", "j5RunConstructs"))(
  ReviewSubsetJ5Report
);
