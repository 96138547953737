/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import gql from "graphql-tag";

export const integrationFragment = gql`
  fragment integration on integration {
    id
    name
    subtype
    integrationTypeCode
  }
`;

const SelectIntegration = ({ toolSchema }) => {
  const settings = toolSchema.toolSchema.settings?.integrationSetting || {};
  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Choose Integration"
          helper="Select the integration to use for import."
        />
        <div style={{ maxWidth: 250 }}>
          <GenericSelect
            name="integration"
            asReactSelect
            isRequired
            label="Integration"
            additionalFilter={{
              integrationTypeCode: settings.integrationTypeCode,
              ...settings.additionalFilter
            }}
            fragment={integrationFragment}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectIntegration;
