/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { basename } from "path";
import React, { Component } from "react";
import { compose } from "recompose";
import { Button, Tooltip, Icon } from "@blueprintjs/core";
import { capitalize, map, keyBy, isEmpty } from "lodash";
import {
  Loading,
  DataTable,
  BlueprintError,
  SwitchField,
  FileUploadField,
  ReactSelectField
} from "@teselagen/ui";
import pluralize from "pluralize";
import shortid from "shortid";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  getDuplicateBarcodeHelper,
  dataTableHeaderMap,
  isGenbankFile,
  getMaterialPlasmidSequence
} from "../../../../utils";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../../src-shared/GenericSelect";

import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import {
  computeSequenceHash,
  parseSequenceFiles,
  sequenceJSONtoGraphQLInput
} from "../../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { cleanPosition } from "../../../../utils/plateUtils";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  allowedCsvFileTypes,
  isCsvOrExcelFile,
  extractZipFiles,
  removeExt
} from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { sequenceAssociationTableFragment } from "../fragments";
import { checkDuplicateSequencesExtended } from "../../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { getMaterialFields } from "../../../../../../tg-iso-shared/src/sequence-import-utils/getMaterialFields";
import { dnaFileTypesAndDescriptions } from "../../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { getSequence } from "../../../../../../tg-iso-shared/src/utils/getSequence";

const tableFilter = (props, qb) => {
  qb.whereAll({
    dataTableTypeCode: "SEQUENCE_ASSOCIATION"
  });
};

const validSequenceTypes = ["CIRCULAR_DNA", "LINEAR_DNA", "OLIGO"];

export const SEQUENCE_PREFIX_HELPER = "TG_SEQUENCE_PREFIX";

class SelectSequenceData extends Component {
  state = {
    loading: false
  };

  parseSchemaFile = async (files, onChange) => {
    const {
      stepFormProps: { change }
    } = this.props;
    try {
      change("partialSequenceData", []);
      const allFiles = await extractZipFiles(files);
      const schemaFile = allFiles.find(isCsvOrExcelFile);
      if (!schemaFile) {
        return window.toastr.error("No csv file found.");
      }
      onChange([
        {
          ...schemaFile,
          loading: false
        }
      ]);

      const cleanedData = [];
      schemaFile.parsedData.forEach(row => {
        Object.keys(row).forEach(key => {
          if (row[key] && key === "sequenceFile") {
            const filename = removeExt(row[key]);
            const sequenceFile = allFiles.find(file => {
              return removeExt(basename(file.name)) === filename;
            });
            row.sequenceFileObject = sequenceFile;
          }
        });
        cleanedData.push({ ...row, id: cleanedData.length + 1 });
      });

      this.loadedFullData = false;
      change("partialSequenceData", cleanedData);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error parsing file");
    }
    // so that file is not uploaded
    return false;
  };

  async componentDidUpdate() {
    const {
      partialSequenceData = [],
      stepFormProps: { change }
    } = this.props;

    if (partialSequenceData.length && !this.loadedFullData) {
      this.loadedFullData = true;
      this.setState({
        loading: true
      });
      try {
        const tubeBarcodes = [];
        const plateBarcodes = [];
        const sequencesToCheckForDuplicates = [];
        const sequenceFileNameToSequenceInputs = {};

        partialSequenceData.forEach(async data => {
          if (data.barcode && !data.position) {
            tubeBarcodes.push(data.barcode.trim());
          } else if (data.barcode) {
            plateBarcodes.push(data.barcode.trim());
          }
          if (data.sequence) {
            const hash = computeSequenceHash(data.sequence, data.sequenceType);
            sequencesToCheckForDuplicates.push({
              circular: data.sequenceType === "CIRCULAR_DNA",
              sequence: data.sequence,
              hash
            });
          }
          if (data.sequenceFileObject) {
            const sequences = await parseSequenceFiles(
              [data.sequenceFileObject],
              {
                isOligo: data.sequenceTypeCode === "OLIGO" // ToDo: check if in this case exists sequenceTypeCode.
              }
            );
            if (sequences && sequences.length) {
              sequenceFileNameToSequenceInputs[data.sequenceFileObject.name] =
                sequences;
              sequences.forEach(sequence => {
                if (!sequence.hash) {
                  const hash = computeSequenceHash(
                    getSequence(sequence),
                    sequence.sequenceTypeCode
                  );
                  sequencesToCheckForDuplicates.push({
                    ...sequence,
                    hash
                  });
                } else {
                  sequencesToCheckForDuplicates.push(sequence);
                }
              });
            }
          }
        });

        const aliquotFragment = `aliquot {
          id
          isDry
          volume
          volumetricUnitCode
          sample {
            id
            materialId
          }
        }`;
        const tubes = tubeBarcodes.length
          ? await safeQuery(
              [
                "aliquotContainer",
                `
              id
              name
              barcode { id barcodeString }
              ${aliquotFragment}
            `
              ],
              {
                variables: {
                  filter: {
                    "barcode.barcodeString": tubeBarcodes
                  }
                }
              }
            )
          : [];
        const plates = plateBarcodes.length
          ? await safeQuery(
              [
                "containerArray",
                `id name
            barcode { id barcodeString }
            aliquotContainers {
              id
              rowPosition
              columnPosition
              ${aliquotFragment}
            }`
              ],
              {
                variables: {
                  filter: {
                    "barcode.barcodeString": plateBarcodes
                  }
                }
              }
            )
          : [];
        const {
          duplicateInputSequences,
          allInputSequencesWithAttachedDuplicates
        } = sequencesToCheckForDuplicates.length
          ? await checkDuplicateSequencesExtended(sequencesToCheckForDuplicates)
          : {};
        change("fetchedPlates", plates);
        change("fetchedTubes", tubes);
        change(
          "sequenceFileNameToSequenceInputs",
          sequenceFileNameToSequenceInputs
        );

        change("duplicateWarnings", keyBy(duplicateInputSequences, "hash"));

        change(
          "keyedExistingSequencesOrDuplicateInput",
          allInputSequencesWithAttachedDuplicates.reduce((acc, seq) => {
            if (seq.duplicateFound) {
              acc[seq.hash] = seq.duplicateFound;
            }
            return acc;
          }, {})
        );

        const duplicatePlates = getDuplicateBarcodeHelper(plates);
        const duplicateTubes = getDuplicateBarcodeHelper(tubes);
        change("duplicatePlates", duplicatePlates);
        change("duplicateTubes", duplicateTubes);
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error validating sequence data.");
      }
      this.setState({
        loading: false
      });
    }
  }

  getSequenceData = ({ plates = [], tubes = [] } = {}, callChange) => {
    const {
      partialSequenceData: _partialSequenceData,
      stepFormProps: { change },
      duplicatePlates = {},
      duplicateWarnings = {},
      resolvedBarcodes = {
        tubes: {},
        plates: {}
      },
      duplicateTubes = {},
      aliquotErrorHelper = {},
      sequenceFileNameToSequenceInputs = {}
    } = this.props;
    const partialSequenceData = _partialSequenceData || [];

    const getKeyedItems = (items, name) => {
      const keyedItems = {};
      items.forEach(item => {
        const barcode = item.barcode.barcodeString;
        if (resolvedBarcodes[name][barcode]) {
          keyedItems[barcode] = resolvedBarcodes[name][barcode];
        } else {
          keyedItems[barcode] = item;
        }
      });
      return keyedItems;
    };
    const keyedTubes = getKeyedItems(tubes, "tubes");
    const keyedPlates = getKeyedItems(plates, "plates");

    const plateBarcodeAcHelper = {};

    Object.values(keyedPlates).forEach(p => {
      plateBarcodeAcHelper[p.barcode.barcodeString] = {};
      p.aliquotContainers.forEach(ac => {
        plateBarcodeAcHelper[p.barcode.barcodeString][
          getAliquotContainerLocation(ac)
        ] = ac;
      });
    });

    const newSequenceData = partialSequenceData.map(record => {
      let error;
      let warning;
      const isTube = !record.position;
      record.barcode = record.barcode && record.barcode.trim();
      const newRecord = {
        ...record,
        position: cleanPosition(record.position),
        sequence: record.sequence && record.sequence.toLowerCase()
      };
      newRecord.isTube = isTube;

      newRecord.sequenceHash =
        newRecord.sequence &&
        computeSequenceHash(newRecord.sequence, newRecord.sequenceType);

      if (
        newRecord.sequenceFileObject &&
        sequenceFileNameToSequenceInputs[newRecord.sequenceFileObject.name]
      ) {
        if (
          sequenceFileNameToSequenceInputs[newRecord.sequenceFileObject.name]
            .length > 1
        ) {
          const seq = sequenceFileNameToSequenceInputs[
            newRecord.sequenceFileObject.name
          ].find(seq => seq.name === newRecord.sequenceName);
          newRecord.sequenceHash = seq && seq.hash;
        } else {
          const seq =
            sequenceFileNameToSequenceInputs[
              newRecord.sequenceFileObject.name
            ][0];
          newRecord.sequenceHash = seq && seq.hash;
        }
      }
      newRecord.sequenceType =
        newRecord.sequenceType &&
        newRecord.sequenceType.toUpperCase().replace(/ /g, "_");

      if (duplicateWarnings[newRecord.sequenceHash]) {
        warning = "This sequence already exists in the sequence library.";
      }

      // if they provide a genbank file we grab the sequence type from it
      let invalidSequenceType;
      if (
        !newRecord.sequenceFileObject ||
        !isGenbankFile(newRecord.sequenceFileObject)
      ) {
        invalidSequenceType = !validSequenceTypes.includes(
          newRecord.sequenceType
        );
      }
      if (
        (!newRecord.sequence || !newRecord.sequence.trim()) &&
        !newRecord.sequenceFile
      ) {
        error = "Please provide a sequence";
      } else if (newRecord.sequenceFile && !newRecord.sequenceFileObject) {
        error =
          "The sequence file referenced in this row was not found in the zip.";
      } else if (
        newRecord.sequenceFileObject &&
        (!sequenceFileNameToSequenceInputs[newRecord.sequenceFileObject.name] ||
          (sequenceFileNameToSequenceInputs[newRecord.sequenceFileObject.name]
            .length > 1 &&
            !sequenceFileNameToSequenceInputs[
              newRecord.sequenceFileObject.name
            ].find(input => input.name === newRecord.sequenceName)))
      ) {
        if (
          !sequenceFileNameToSequenceInputs[newRecord.sequenceFileObject.name]
        ) {
          error =
            "The sequence file provided did not have a proper sequence for this row.";
        } else if (
          sequenceFileNameToSequenceInputs[newRecord.sequenceFileObject.name]
            .length > 1 &&
          !newRecord.sequenceName
        ) {
          error =
            "The provided sequence file contains multiple sequences but the sequence name field in the data table is missing.";
        } else {
          error = `The provided sequence name ${newRecord.sequenceName} does not match any sequence name in the provided sequence file`;
        }
      } else if (invalidSequenceType) {
        error = "This sequence type in this row is not valid";
      } else if (
        isTube &&
        duplicateTubes[record.barcode] &&
        !resolvedBarcodes.tubes[record.barcode]
      ) {
        error =
          "This barcode matches multiple tubes in inventory. Please resolve.";
      } else if (
        !isTube &&
        duplicatePlates[record.barcode] &&
        !resolvedBarcodes.plates[record.barcode]
      ) {
        error =
          "This barcode matches multiple plates in inventory. Please resolve.";
      } else if (!isTube && !keyedPlates[record.barcode]) {
        error = "This plate was not found in inventory";
      } else if (isTube && !keyedPlates[record.barcode]) {
        error = "This tube was not found in inventory";
      } else {
        let aliquotContainer;
        if (isTube) {
          aliquotContainer = keyedTubes[record.barcode];
        } else {
          aliquotContainer =
            plateBarcodeAcHelper[record.barcode][newRecord.position];
        }
        const aliquot = aliquotContainer && aliquotContainer.aliquot;
        newRecord.aliquotContainer = aliquotContainer;
        newRecord.aliquotId = aliquot ? aliquot.id : undefined;
        if (!aliquot) {
          error = `No aliquot found in ${isTube ? "tube" : "plate well"}.`;
        } else if (aliquotErrorHelper[aliquot.id]) {
          error = aliquotErrorHelper[aliquot.id];
        }
      }
      if (error) {
        newRecord.error = error;
      } else if (warning) {
        newRecord.warning = warning;
      }

      return newRecord;
    });
    if (callChange) {
      change("sequenceData", newSequenceData);
      change("keyedTubes", keyedTubes);
      change("keyedPlates", keyedPlates);
      change("plateBarcodeAcHelper", plateBarcodeAcHelper);
    }
    return newSequenceData;
  };

  onSelectTable = dataTable => {
    const {
      stepFormProps: { change }
    } = this.props;
    const newSequenceData = dataTable.dataRows.map((r, i) => ({
      ...r.rowValues,
      id: i + 1
    }));
    change("partialSequenceData", newSequenceData);
  };

  clearSequenceData = () => {
    const {
      stepFormProps: { change }
    } = this.props;

    this.loadedFullData = false;
    change("sequenceValidationFile", []);
    change("resolvedBarcodes", {
      plates: {},
      tubes: {}
    });
    change("duplicatePlates", {});
    change("duplicateTubes", {});
    change("aliquotErrorHelper", {});
    change("sequenceFileNameToSequenceInputs", {});
    change("sequenceData", null);
    change("partialSequenceData", []);
    change("sequenceValidationTable", null);
  };

  startUpdate = () => {
    const { handleSubmit, fetchedPlates = [], fetchedTubes = [] } = this.props;

    this.getSequenceData(
      {
        plates: fetchedPlates,
        tubes: fetchedTubes
      },
      true
    );
    // this timeout is needed so that the redux form changes from getSequenceData
    // will be reflected in the values bound to updateData
    setTimeout(() => {
      handleSubmit(this.updateData)();
    });
  };

  updateData = async values => {
    const {
      handleSubmit,
      onSubmit,
      stepFormProps: { change },
      keyedExistingSequencesOrDuplicateInput = {},
      sequenceFileNameToSequenceInputs = {}
    } = this.props;
    const { sequenceData } = values;

    const aliquotIdToRowInfo = {};

    const aliquotIds = sequenceData.map(r => {
      aliquotIdToRowInfo[r.aliquotId] = {
        sequenceName: r.sequenceName,
        sequenceHash: r.sequenceHash,
        sequencePrefix: r.prefix,
        existingSequenceOrDupInput:
          keyedExistingSequencesOrDuplicateInput[r.sequenceHash],
        sequenceFileObject: r.sequenceFileObject,
        sequenceInputs:
          r.sequenceFileObject &&
          sequenceFileNameToSequenceInputs[r.sequenceFileObject.name],
        sequenceString: r.sequence,
        sequenceType: r.sequenceType
      };
      return r.aliquotId;
    });

    const aliquotFragment = `
      id
      sample {
        id
        sampleTypeCode
        sampleIsolation {
          id
          sourceSample {
            id
            sampleTypeCode
            sampleFormulations {
              id
              materialCompositions {
                id
                material {
                  id
                  name
                  materialTypeCode
                  strain {
                    id
                    name
                    microbialMaterials {
                      id
                      microbialMaterialMicrobialMaterialPlasmids {
                        id
                        polynucleotideMaterial {
                          id
                          polynucleotideMaterialSequence {
                            id
                            hash
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    try {
      const fullAliquots = await safeQuery(["aliquot", aliquotFragment], {
        variables: {
          filter: {
            id: aliquotIds
          }
        }
      });

      const materialCidToPlasmidCid = {};
      const materialsToCreate = [];
      const dnaMaterialsToCreate = [];
      const newSeqHashToDnaMatId = {};
      const sequencesToCreate = [];

      const aliquotErrorHelper = {};

      const sampleUpdates = [];
      const sequenceUpdates = [];
      const sequenceIdToDnaMatId = {};
      const newSequenceCidToDnaMatId = {};

      fullAliquots.forEach(aliquot => {
        const {
          existingSequenceOrDupInput,
          sequenceHash,
          sequenceName,
          sequenceString,
          sequenceType,
          sequencePrefix,
          sequenceInputs,
          sequenceFileObject
        } = aliquotIdToRowInfo[aliquot.id];
        const sample = aliquot.sample;

        const getMaterialId = materialCid => {
          const seqInput = existingSequenceOrDupInput;
          if (seqInput?.id) {
            if (!sequenceIdToDnaMatId[seqInput.id]) {
              if (seqInput.polynucleotideMaterialId) {
                sequenceIdToDnaMatId[seqInput.id] =
                  seqInput.polynucleotideMaterialId;
              } else {
                const cid = shortid();
                dnaMaterialsToCreate.push({
                  ...getMaterialFields(),
                  cid,
                  name: seqInput.name
                });
                sequenceIdToDnaMatId[seqInput.id] = `&${cid}`;
                sequenceUpdates.push({
                  id: seqInput.id,
                  polynucleotideMaterialId: `&${cid}`
                });
              }
            }
            return sequenceIdToDnaMatId[seqInput.id];
          } else {
            if (seqInput && newSequenceCidToDnaMatId[seqInput.cid]) {
              return newSequenceCidToDnaMatId[seqInput.cid];
            } else if (newSeqHashToDnaMatId[sequenceHash]) {
              return newSeqHashToDnaMatId[sequenceHash];
            } else {
              let newSequence;
              if (sequenceInputs && sequenceInputs.length) {
                if (sequenceInputs.length > 1) {
                  newSequence = sequenceInputs.find(
                    seq => seq.name === sequenceName
                  );
                } else {
                  newSequence = sequenceInputs[0];
                }
                if (!newSequence.name) {
                  newSequence.name =
                    sequenceName ||
                    (sequencePrefix
                      ? `${sequencePrefix}-${SEQUENCE_PREFIX_HELPER}`
                      : `Untitled Sequence ${SEQUENCE_PREFIX_HELPER}`);
                }
                if (!isGenbankFile(sequenceFileObject)) {
                  newSequence.sequenceTypeCode = sequenceType;
                }
              } else {
                newSequence = sequenceJSONtoGraphQLInput({
                  name:
                    sequenceName ||
                    (sequencePrefix
                      ? `${sequencePrefix}-${SEQUENCE_PREFIX_HELPER}`
                      : `Untitled Sequence ${SEQUENCE_PREFIX_HELPER}`),
                  sequenceTypeCode: sequenceType,
                  circular: sequenceType === "CIRCULAR_DNA",
                  sequence: sequenceString
                });
              }
              if (!newSequence.polynucleotideMaterial) {
                newSequence.polynucleotideMaterial = {
                  ...getMaterialFields(),
                  cid: shortid(),
                  name: newSequence.name
                };
              }
              newSequence.cid = seqInput?.cid || newSequence.cid || shortid();
              materialCidToPlasmidCid[materialCid] = newSequence.cid;
              const dnaMatId = `&${newSequence.polynucleotideMaterial.cid}`;
              newSequenceCidToDnaMatId[newSequence.cid] = dnaMatId;
              newSeqHashToDnaMatId[newSequence.hash] = dnaMatId;
              sequencesToCreate.push(newSequence);
              return dnaMatId;
            }
          }
        };

        const getNewMaterial = ({ starterName, strain }) => {
          let name = starterName;
          if (existingSequenceOrDupInput && existingSequenceOrDupInput.name) {
            name += ` (${existingSequenceOrDupInput.name})`;
          } else if (sequenceName) {
            name += ` (${sequenceName})`;
          } else if (sequencePrefix) {
            name += ` (${sequencePrefix}-${SEQUENCE_PREFIX_HELPER})`;
          }
          const cid = shortid();
          const newMaterial = {
            cid,
            name,
            materialTypeCode: "MICROBIAL",
            strainId: strain && strain.id,
            microbialMaterialMicrobialMaterialPlasmids: [
              {
                polynucleotideMaterialId: getMaterialId(cid)
              }
            ]
          };
          sampleUpdates.push({
            id: sample.id,
            sampleStatusCode: "VALID",
            materialId: `&${newMaterial.cid}`
          });
          materialsToCreate.push(newMaterial);
        };

        if (!sample) {
          aliquotErrorHelper[aliquot.id] =
            "No sample found on aliquot for this row.";
        } else if (sample.sampleTypeCode !== "ISOLATED_SAMPLE") {
          aliquotErrorHelper[aliquot.id] =
            "Sample not of type isolated sample for this row.";
        } else if (!sample.sampleIsolation) {
          aliquotErrorHelper[aliquot.id] =
            "Sample does not have an isolation event data.";
        } else if (!sample.sampleIsolation.sourceSample) {
          aliquotErrorHelper[aliquot.id] =
            "Isolated sample has no source sample for this row.";
        } else if (
          //  may want to allow for registered pools in the future
          sample.sampleIsolation.sourceSample.sampleTypeCode !==
          "FORMULATED_SAMPLE"
        ) {
          aliquotErrorHelper[aliquot.id] =
            "Source sample not of type registered pool for this row.";
        } else {
          const sourceSample = sample.sampleIsolation.sourceSample;
          const materials = [];
          let oneMicrobialMaterial = false;
          let hasMoreThanOneMicrobialMaterial = false;
          sourceSample.sampleFormulations.forEach(sf => {
            sf.materialCompositions.forEach(mc => {
              materials.push(mc.material);
              if (mc.material.materialTypeCode === "MICROBIAL") {
                if (oneMicrobialMaterial)
                  hasMoreThanOneMicrobialMaterial = true;
                oneMicrobialMaterial = mc.material;
              }
            });
          });

          if (!sourceSample.sampleFormulations.length) {
            aliquotErrorHelper[aliquot.id] =
              "No sample formulations found on source sample for this row.";
          } else if (!oneMicrobialMaterial || hasMoreThanOneMicrobialMaterial) {
            aliquotErrorHelper[aliquot.id] =
              "Source sample must have exactly one microbial material.";
          } else {
            const strain = oneMicrobialMaterial.strain;
            if (strain) {
              const hadMatch = strain.microbialMaterials.some(mm => {
                const isMatch =
                  mm.microbialMaterialMicrobialMaterialPlasmids.length === 1 &&
                  getMaterialPlasmidSequence(
                    mm.microbialMaterialMicrobialMaterialPlasmids[0]
                  ).hash === sequenceHash;

                if (isMatch) {
                  sampleUpdates.push({
                    id: sample.id,
                    sampleStatusCode: "VALID",
                    materialId: mm.id
                  });
                }

                return isMatch;
              });
              if (!hadMatch) {
                getNewMaterial({
                  starterName: strain.name,
                  strain
                });
              }
            } else {
              getNewMaterial({
                starterName: oneMicrobialMaterial.name
              });
            }
          }
        }
      });

      if (!isEmpty(aliquotErrorHelper)) {
        change("aliquotErrorHelper", aliquotErrorHelper);
      } else {
        change("materialsToCreate", materialsToCreate);
        change("dnaMaterialsToCreate", dnaMaterialsToCreate);
        change("sequencesToCreate", sequencesToCreate);
        change("sequenceUpdates", sequenceUpdates);
        change("materialCidToPlasmidCid", materialCidToPlasmidCid);
        change("sampleUpdates", sampleUpdates);

        // set timeout so changes above are reflected in form values
        setTimeout(() => {
          handleSubmit(onSubmit)();
        });
      }
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message || "Error loading aliquot info.");
    }
  };

  renderDuplicateChoices = () => {
    const { duplicatePlates = {}, duplicateTubes = {} } = this.props;

    if (isEmpty(duplicatePlates) && isEmpty(duplicateTubes)) return null;

    const getComp = name => {
      const items = name === "plate" ? duplicatePlates : duplicateTubes;
      if (isEmpty(items)) return;
      return (
        <div
          className="tg-flex justify-space-between"
          style={{ marginTop: 15 }}
        >
          <HeaderWithHelper
            header={`Resolve Duplicates ${capitalize(pluralize(name))}`}
            helper={`The following barcodes matched more than one ${name} in inventory. Please choose the one you would like to use.`}
          />
          <div>
            {map(items, (options, barcode) => {
              return (
                <ReactSelectField
                  key={barcode}
                  options={arrayToItemValuedOptions(options)}
                  name={`resolvedBarcodes.${pluralize(name)}.` + barcode}
                  label={barcode}
                />
              );
            })}
          </div>
        </div>
      );
    };
    return (
      <>
        {getComp("plate")}
        {getComp("tube")}
      </>
    );
  };

  render() {
    const {
      Footer,
      footerProps,
      submitting,
      fetchedPlates = [],
      fetchedTubes = [],
      isUpload,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;

    const sequenceData = this.getSequenceData({
      plates: fetchedPlates,
      tubes: fetchedTubes
    });
    const hasSequenceDataError = sequenceData.some(r => r.error);

    let clearButton, table, uploadOptions;

    if (!!sequenceData.length && !this.state.loading) {
      clearButton = (
        <div
          style={{
            marginBottom: 10,
            marginRight: 20,
            alignSelf: "flex-end"
          }}
        >
          <Button
            onClick={this.clearSequenceData}
            intent="danger"
            text="Clear Table"
          />
        </div>
      );

      table = (
        <>
          <DataTable
            isSimple
            formName="sequenceValidationDisplayTable"
            maxHeight={300}
            noSelect
            schema={[
              {
                type: "action",
                width: 35,
                render: (_, record) => {
                  if (record.error || record.warning) {
                    return (
                      <Tooltip content={record.error || record.warning}>
                        <Icon
                          intent={record.error ? "danger" : "warning"}
                          style={{ marginRight: 10 }}
                          icon="warning-sign"
                        />
                      </Tooltip>
                    );
                  }
                }
              },
              {
                path: "barcode",
                displayName: "Barcode"
              },
              {
                path: "position",
                displayName: "Position",
                render: v => v || "N/A"
              },
              "sequenceType",
              "sequenceName",
              "prefix",
              "sequence",
              "sequenceFile"
            ]}
            entities={sequenceData}
          />
          {hasSequenceDataError && (
            <BlueprintError error="Please fix errors in sequence data." />
          )}
        </>
      );
    }

    if (!sequenceData.length && !this.state.loading) {
      uploadOptions = (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
          }}
        >
          <SwitchField name="isUpload" label="Upload Schema" />
          <div className="tg-flex align-center">
            {isUpload ? (
              <div>
                <FileUploadField
                  accept={[
                    getDownloadTemplateFileHelpers({
                      type: allowedCsvFileTypes.concat([".zip"]),
                      fileName: "sequence_validation_schema",
                      validateAgainstSchema: {
                        fields: dataTableHeaderMap.SEQUENCE_ASSOCIATION
                      }
                    }),
                    ...dnaFileTypesAndDescriptions
                  ]}
                  name="sequenceValidationFile"
                  beforeUpload={this.parseSchemaFile}
                />
              </div>
            ) : (
              <GenericSelect
                name="sequenceAssociationTable"
                tableParamOptions={{
                  additionalFilter: tableFilter
                }}
                buttonProps={{
                  disabled: isDisabledMap.dataTable,
                  loading: isLoadingMap.dataTable
                }}
                onSelect={this.onSelectTable}
                schema={["name", dateModifiedColumn]}
                fragment={["dataTable", "id name updatedAt"]}
                additionalDataFragment={sequenceAssociationTableFragment}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select DNA Sequence Association Schema"
              helper="Select or upload sequence association schema specifying which samples are to be updated with sequence data."
            />
            {clearButton}
            {uploadOptions}
          </div>
          {table}
          {this.renderDuplicateChoices()}
          {this.state.loading && <Loading bounce />}
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent="success"
              disabled={!sequenceData.length || hasSequenceDataError}
              loading={submitting}
              onClick={this.startUpdate}
            >
              Submit
            </Button>
          }
        />
      </>
    );
  }
}

export default compose(
  stepFormValues(
    "sequenceValidationSchema",
    "isUpload",
    "fetchedPlates",
    "fetchedTubes",
    "resolvedBarcodes",
    "sequenceValidationFile",
    "sequenceValidationFile",
    "partialSequenceData",
    "sequenceValidationTable",
    "duplicatePlates",
    "duplicateTubes",
    "keyedExistingSequencesOrDuplicateInput",
    "sequenceFileNameToSequenceInputs",
    "aliquotErrorHelper"
  )
)(SelectSequenceData);
