/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import {
  convertMassBig,
  convertVolumeBig,
  convertConcentrationBig,
  calculateConcentrationFromMolarity
} from "../../utils/unitUtils";
import { isDiluent, getAliquotMolecularWeight } from "../../utils/aliquotUtils";
import { sumBigs } from "../../utils/bigIntUtils";

/**
 * Get the mass of the aliquot's non-diluent content in grams.
 * @param {object} aliquot
 * @returns {number} Mass in grams.
 */
export function getMassInGrams(aliquot) {
  if (aliquot.isDry) {
    return convertMassBig(aliquot.mass, aliquot.massUnitCode, "g");
  } else if (isDiluent(aliquot)) {
    return new Big(0);
  } else {
    return convertVolumeBig(
      aliquot.volume,
      aliquot.volumetricUnitCode,
      "L"
    ).times(
      convertConcentrationBig(
        aliquot.concentration,
        aliquot.concentrationUnitCode,
        "g/L"
      )
    );
  }
}

/**
 * Get the total volume of an aliquot in liters.
 * @param {object} aliquot
 * @returns {number} Volume in liters.
 */
export function getVolumeInLiters(aliquot) {
  if (aliquot.isDry) {
    return new Big(0);
  } else {
    return convertVolumeBig(aliquot.volume, aliquot.volumetricUnitCode, "L");
  }
}

/**
 * See whether or not the aliquot has the required fields to be used to compute
 * the concentration of a mixture.
 * @param {object} aliquot
 * @returns {boolean}
 */
function canBeUsed(aliquot) {
  if (aliquot.isDry) return aliquot.mass !== null;
  else if (isDiluent(aliquot)) return aliquot.volume !== null;
  // Wety aliquots have null concentrations in the case of formulated samples.
  else if (aliquot.molarity && !aliquot.concentration) {
    return !!getAliquotMolecularWeight(aliquot);
  } else return aliquot.concentration !== null && aliquot.volume !== null;
}

/**
 * Given an array of aliquots, return the concentration of their mixture. If the resulting mixture has no volume,
 * then we return `null. If any of the aliquots has no concentration, we return `null`.
 * @param {Array<Object>} aliquots Array of aliquots. Should have all of the fields directly on aliquot in the `aliquotFormulationFragment`.
 * @param {string} concentrationUnitCode The units that the concentration will be returned in.
 * @returns {number}
 */
function getConcentrationOfMixture(_aliquots, concentrationUnitCode) {
  if (!_aliquots.length) return null;

  const canAllBeUsed = _aliquots.every(canBeUsed);
  if (!canAllBeUsed) return null;

  const aliquots = _aliquots.map(aliquot => {
    if (
      aliquot.molarity &&
      !aliquot.concentration &&
      getAliquotMolecularWeight(aliquot)
    ) {
      return {
        ...aliquot,
        concentration: calculateConcentrationFromMolarity(
          aliquot.molarity,
          aliquot.molarityUnitCode,
          getAliquotMolecularWeight(aliquot)
        ),
        concentrationUnitCode: "g/L"
      };
    } else {
      return aliquot;
    }
  });

  const masses = aliquots.map(getMassInGrams);
  const volumes = aliquots.map(getVolumeInLiters);

  const totalVolume = sumBigs(volumes);
  // This would divide by zero in the next step, so return null.
  if (!totalVolume || totalVolume.eq(0)) return null;

  // Concentration = mass / volume
  const concInGramPerLiter = sumBigs(masses).div(totalVolume);
  return Number(
    convertConcentrationBig(concInGramPerLiter, "g/L", concentrationUnitCode)
  );
}

export default getConcentrationOfMixture;
