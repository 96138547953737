/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

const API_INTEGRATION = {
  description: `These are integrations that are called from TeselaGen API`,
  endpoints: {
    API_INTEGRATION: {
      name: "Integration Exposed via TeselaGen API",
      method: "POST",
      description: "This integration flow is called via API",
      exampleRequest: {
        __validate__: () => yup.object()
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            data: yup.object()
          });
        },
        data: {}
      }
    }
  }
};

export default API_INTEGRATION;
