/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import { pluralIfNeeded } from "../../../utils";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import {
  REQUIRED_ERROR,
  isValidPositiveNumber
} from "../../../../src-shared/utils/formUtils";

const validate = ({
  microbialMaterial,
  dnaTransferType,
  containerArrays = [],
  aliquotContainers = [],
  destinationPlateTypes = [],
  destinationPlateNames = [],
  plates = [],
  transferToSourcePlates,
  intermediateContainerType,
  microbialTransferVolume,
  microbialTransferVolumeUnitCode,
  inputType
}) => {
  const preppingPlates = inputType === "PLATE";
  const errors = {};

  if (microbialMaterial) {
    if (!microbialMaterial.strain) {
      errors.microbialMaterial =
        "Please select a microbial material with a strain.";
    }
  }
  if (!isValidPositiveNumber(microbialTransferVolume)) {
    errors.microbialTransferVolume = "Please enter a valid transfer volume.";
  }
  if (
    transferToSourcePlates &&
    intermediateContainerType &&
    microbialTransferVolume &&
    !errors.intermediateContainerType &&
    !errors.microbialTransferVolume
  ) {
    const newDeadVolume =
      get(intermediateContainerType, "aliquotContainerType.deadVolume") || 0;
    const standardizedDeadVolume = standardizeVolume(
      newDeadVolume,
      intermediateContainerType.aliquotContainerType.deadVolumetricUnitCode ||
        "uL",
      true
    );
    const standardizedTransferVolume = standardizeVolume(
      microbialTransferVolume,
      microbialTransferVolumeUnitCode,
      true
    );
    const standardizedMaxWellVolume = standardizeVolume(
      intermediateContainerType.aliquotContainerType.maxVolume,
      intermediateContainerType.aliquotContainerType.volumetricUnitCode,
      true
    );
    const totalMaxVolume = standardizedMaxWellVolume.times(
      intermediateContainerType.containerFormat.quadrantSize
    );
    const totalDeadVolume = standardizedDeadVolume.times(
      intermediateContainerType.containerFormat.quadrantSize
    );
    const aliquotContainersToPrep = preppingPlates
      ? plates.map(plate => plate.aliquotContainers.map(ac => ac.aliquot))
      : aliquotContainers;
    const totalTransferVolume = standardizedTransferVolume.times(
      aliquotContainersToPrep.length
    );

    if (
      standardizedDeadVolume
        .plus(standardizedTransferVolume)
        .gt(standardizedMaxWellVolume)
    ) {
      errors.microbialTransferVolume =
        "Transfer volume exceeds intermediate container well volume.";
    }

    if (totalDeadVolume.plus(totalTransferVolume).gt(totalMaxVolume)) {
      errors.intermediateContainerType =
        "Selected container type cannot support specified transfer volume. Please select a container type with greater volume capacity.";
    }
  }
  if (dnaTransferType === "mass") {
    const noConcentrationPlates = [];
    containerArrays.forEach(plate => {
      const noConcentration = plate.aliquotContainers.some(
        ac => ac.aliquot && !ac.aliquot.concentration
      );
      if (noConcentration) noConcentrationPlates.push(plate.name);
    });
    if (noConcentrationPlates.length) {
      errors.dnaTransferType = `The following ${pluralIfNeeded(
        "plate",
        noConcentrationPlates
      )} ${pluralIfNeeded(
        "has",
        noConcentrationPlates
      )} aliquots with null concentrations: ${noConcentrationPlates.join(
        ", "
      )}.`;
    }
  }

  const destinationPlateNamesErrors = [];
  destinationPlateTypes.forEach((t, i) => {
    if (!destinationPlateNames[i])
      destinationPlateNamesErrors[i] = REQUIRED_ERROR;
  });
  errors.destinationPlateNames = destinationPlateNamesErrors;

  return errors;
};

export { validate };
