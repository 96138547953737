import { uniq } from "lodash";

import {
  getReferencedValue,
  getSequenceStringOfSequence,
  getItemOfType
} from "../../../../selectors/designStateSelectors";

export const getSequenceIdOfElement = (state, element) => {
  if (!element.partId) return null;
  return getItemOfType(state, "part", element.partId).sequenceId;
};

// Returns false if any the sets are empty or combinatorial.
export default function isElementCombinationContiguousOnTheSameSequence(
  state,
  bins,
  elements
) {
  if (bins.length !== elements.length)
    throw new Error("Bins and elements must have the same length");

  // All of the bins must be facing the same direction.
  const binDirections = bins.map(s => s.direction);
  if (uniq(binDirections).length !== 1) return false;

  // Every element must be associated with a part.
  if (elements.some(el => !el.partId)) return false;

  // All of the parts must be on the same sequence.
  const sequenceIds = elements
    .map(el => getSequenceIdOfElement(state, el))
    .filter(id => id);
  if (uniq(sequenceIds).length !== 1) return false;

  // All of the parts must either be on the forward or reverse strand.
  const partDirections = elements.map(
    el => getReferencedValue(state, "element", el.id, "partId").strand === -1
  );
  if (uniq(partDirections).length !== 1) return false;

  // A combination with a single part element will trivially be contiguous
  // on the same source sequence.
  if (elements.length === 1) return true;

  const doElementsOverlap = (el1, el2) => {
    const part = getReferencedValue(state, "element", el1.id, "partId");
    const nextPart = getReferencedValue(state, "element", el2.id, "partId");
    if (part.start <= part.end) {
      if (nextPart.start <= nextPart.end) {
        return (
          (nextPart.start <= part.start && part.start <= nextPart.end) ||
          (nextPart.start <= part.end && part.end <= nextPart.end)
        );
      } else {
        return nextPart.start <= part.end || part.start <= nextPart.end;
      }
    } else {
      if (nextPart.start <= nextPart.end) {
        return doElementsOverlap(el2, el1);
      } else {
        return true;
      }
    }
  };

  let violations = 0;
  for (let i = 0, ii = elements.length; i < ii; i++) {
    const el = elements[i];
    const nextEl = elements[(i + 1) % elements.length];
    const part = getReferencedValue(state, "element", el.id, "partId");
    const nextPart = getReferencedValue(state, "element", nextEl.id, "partId");
    const sequence = getReferencedValue(state, "part", part.id, "sequenceId");
    if (doElementsOverlap(el, nextEl)) return false;
    if (
      !(
        part.end + 1 === nextPart.start ||
        (sequence.circular &&
          part.end ===
            getSequenceStringOfSequence(state, sequence.id).length - 1 &&
          nextPart.start === 0)
      )
    )
      violations++;
  }
  return violations <= 1;
}
