/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createSelector } from "reselect";
import { get, keyBy, uniqBy, flatMapDeep, sortBy } from "lodash";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";
import {
  SIMPLE_REFERENCES,
  FOREIGN_REFERENCES,
  SIMPLE_REFERENCES_TO_TYPE
} from "../constants/designStateConstants";
import { dftAll } from "./selectorUtils";
import getAllSetIdsInCard from "./getAllSetIdsInCard";
import tgCreateCachedSelector from "../utils/tgCreateCachedSelector";

// Anything using these is deprecated and importing designUtils
// was messing with isomorphic code.
const leavesChildSetsAll = null;
const isIconBackbone = null;
const getMaxDepthOfSets = null;

// NOTE: Not a true selector.
const getTypeOfReference = (type, field) => {
  if (SIMPLE_REFERENCES[type] && SIMPLE_REFERENCES[type][field]) {
    return SIMPLE_REFERENCES_TO_TYPE[type][field];
  } else if (FOREIGN_REFERENCES[type] && FOREIGN_REFERENCES[type][field]) {
    return FOREIGN_REFERENCES[type][field].foreignType;
  }
};

// NOTE: Not a true selector.
const getForeignKeyOfReference = (type, field) => {
  if (FOREIGN_REFERENCES[type] && FOREIGN_REFERENCES[type][field]) {
    return FOREIGN_REFERENCES[type][field].foreignKey;
  } else return null;
};

// NOTE: Not a selector.
const resolveReference = (allItemsOfReferenceType, type, item, field) => {
  const foreignKey = getForeignKeyOfReference(type, field);
  if (foreignKey)
    return Object.values(allItemsOfReferenceType).filter(
      ({ [foreignKey]: value }) => value === item.id
    );
  else return allItemsOfReferenceType[item[field]];
};

const getDesignState = state => state.design;

const getAllOfType = (state, type) => state.design[type];
const getCountOfType = (state, type) => {
  if (Array.isArray(state.design[type])) {
    return state.design[type].length;
  } else {
    return Object.keys(state.design[type]).length;
  }
};
const getItemOfType = (state, type, itemId) => {
  return state.design[type][itemId];
};
const getFieldOnItem = (state, type, itemId, field) =>
  state.design[type][itemId][field];

const isDesignVisualReport = state =>
  Object.values(state.design.design)[0].type === "visual-report";

const isDisabledSameBinDigestValidation = state => {
  return Object.values(state.design.design)[0].disableSameBinDigestValidation;
};
const isDesignUniform = state =>
  Object.values(state.design.design)[0].type === "uniform";
const isDesignTemplate = state =>
  Object.values(state.design.design)[0].type === "design-template";

//Checks if assembly method exists in design and returns the assembly method obj
const isAssemblyMethodInDesign = createSelector(
  [
    state => getAllOfType(state, "assemblyMethod"),
    state => getAllOfType(state, "reaction"),
    (state, assemblyMethodToFind) => assemblyMethodToFind
  ],
  (assemblyMethods, reactions, assemblyMethodToFind) => {
    assemblyMethods = Object.values(assemblyMethods);
    reactions = Object.values(reactions);
    let assemblyMethod = null;

    for (let i = 0; i < assemblyMethods.length; i += 1) {
      if (assemblyMethods[i].name === assemblyMethodToFind) {
        assemblyMethod = assemblyMethods[i];
        break;
      }
    }
    if (!assemblyMethod)
      return console.error(
        "Exit code 123: Can't find assembly method provided"
      );
    for (let i = 0; i < reactions.length; i += 1) {
      if (reactions[i].assemblyMethodId === assemblyMethod.id) {
        return assemblyMethod;
      }
    }
    return false;
  }
);

const getAssemblyMethodOfCard = createSelector(
  [
    (state, cardId) => getAllOfType(state, "card")[cardId],
    (state, cardId) => isCardRoot(state, cardId),
    state => getAllOfType(state, "assemblyMethod"),
    state => getAllOfType(state, "reaction")
  ],
  (card, isCardRoot, assemblyMethods, reactions) => {
    // The root card of a design is not assigned to an assembly methods.
    // They are used as the root or reference for the hierarchical tree structure of a design.
    if (isCardRoot) return;
    const cardReactionId = card.inputReactionId;
    const cardReactionAssemblyMethodId =
      reactions[cardReactionId].assemblyMethodId;
    const assemblyMethod = assemblyMethods[cardReactionAssemblyMethodId];

    return assemblyMethod;
  }
);

const getNumOfInvaliditiesInDesign = createSelector(
  state => getAllOfType(state, "element"),
  elements => {
    elements = Object.values(elements);
    let count = 0;

    elements.forEach(el => (el.invalidityMessage ? (count += 1) : null));

    return count;
  }
);

const getNumberOfActivePartsInPartset = tgCreateCachedSelector(
  state => getAllOfType(state, "partsetPart"),
  (state, partsetPartId) => partsetPartId,
  state => getAllOfType(state, "partsetPart"),
  (partsetParts, partsetId) => {
    return Object.values(partsetParts).filter(
      psp => !psp.isDeactivated && psp.partsetId === partsetId
    ).length;
  }
)((state, cardId) => cardId);

const getDesign = createSelector(
  state => getAllOfType(state, "design"),
  designs => {
    designs = Object.values(designs);
    if (designs.length !== 1) throw new Error("We should only see one design.");
    return designs[0];
  }
);

const getDesignNoError = createSelector(
  state => getAllOfType(state, "design"),
  designs => {
    designs = Object.values(designs);
    if (designs.length !== 1) return null;
    return designs[0];
  }
);

const getMultiValuedIndex = tgCreateCachedSelector(
  getAllOfType,
  (state, type, key) => key,
  (allOfType, key) => {
    allOfType = Object.values(allOfType);
    const indexMap = {};
    for (const item of allOfType) {
      if (!item[key]) continue;
      if (!indexMap[item[key]]) indexMap[item[key]] = [];
      indexMap[item[key]].push(item);
    }
    return indexMap;
  }
)((state, type, key) => `${type}:${key}`);

const getItemByCid = tgCreateCachedSelector(
  getAllOfType,
  (state, type, itemCid) => itemCid,
  (allOfType, itemCid) =>
    Object.values(allOfType).find(item => item.cid === itemCid)
)((state, type, itemCid) => `${type}:${itemCid}`);

const getItemIdByCid = tgCreateCachedSelector(getItemByCid, item =>
  item ? item.id : null
)((state, type, itemCid) => `${type}:${itemCid}`);

const getReferencedValue = tgCreateCachedSelector(
  (state, type, itemId) => itemId,
  getFieldOnItem,
  (state, type, itemId, field) =>
    getAllOfType(state, getTypeOfReference(type, field)),
  (state, type, itemId, field) => getForeignKeyOfReference(type, field),
  (itemId, fieldValue, possibleReferenceItems, foreignKey) => {
    if (foreignKey)
      return Object.values(possibleReferenceItems).filter(
        ({ [foreignKey]: value }) => value === itemId
      );
    else return possibleReferenceItems[fieldValue];
  }
)((state, type, itemId, field) => `${type}:${itemId}:${field}`);

const performGettingCardSets = (card, sets, setSets) => {
  setSets = Object.values(setSets);
  const resolve = (set, setSetId) => {
    return {
      ...set,
      setSetId,
      childSets: setSets
        .filter(ss => ss.parentSetId === set.id)
        .sort((a, b) => a.index - b.index)
        .map(ss => resolve(sets[ss.childSetId], ss.id))
    };
  };
  return resolve(card);
};
const getCardSets = tgCreateCachedSelector(
  (state, cardId) => getItemOfType(state, "set", cardId),
  state => getAllOfType(state, "set"),
  state => getAllOfType(state, "setSet"),
  performGettingCardSets
)((state, cardId) => cardId);

const getChildSetIds = tgCreateCachedSelector(
  (state, setId) => setId,
  state => getAllOfType(state, "setSet"),
  (setId, setSets) =>
    Object.values(setSets)
      .filter(ss => ss.parentSetId === setId)
      .sort((a, b) => a.index - b.index)
      .map(ss => ss.childSetId)
)((state, setId) => setId);

const getChildSets = tgCreateCachedSelector(
  getChildSetIds,
  state => getAllOfType(state, "set"),
  (childSetIds, sets) => childSetIds.map(setId => sets[setId])
)((state, setId) => setId);

const getLeafSets = tgCreateCachedSelector(getCardSets, cardSets =>
  leavesChildSetsAll(cardSets)
)((state, cardId) => cardId);

const getDepthOfSetsInCard = tgCreateCachedSelector(getCardSets, card =>
  getMaxDepthOfSets(card)
)((state, cardId) => cardId);

const getRootCardBinIds = state => getBinIdsInCard(state, getRootCardId(state));

const getClassicNumberOfRows = state =>
  getNumberOfRowsForCard(state, getRootCardId(state));

const getBinIdsInCard = tgCreateCachedSelector(
  state => getAllOfType(state, "binCard"),
  (state, cardId) => cardId,
  (binCards, cardId) =>
    Object.values(binCards)
      .filter(bc => bc.cardId === cardId)
      .sort((a, b) => a.index - b.index)
      .map(bc => bc.binId)
)((state, cardId) => cardId);

const getBinIdsSetInCard = tgCreateCachedSelector(getBinIdsInCard, binIds =>
  keyBy(binIds, x => x)
)((state, cardId) => cardId);

const getBinsInCard = tgCreateCachedSelector(
  state => getAllOfType(state, "bin"),
  getBinIdsInCard,
  (bins, binIds) => binIds.map(binId => bins[binId])
)((state, cardId) => cardId);

const getBinCardByCardIdAndBinId = tgCreateCachedSelector(
  state => getAllOfType(state, "binCard"),
  (state, cardId) => cardId,
  (state, cardId, binId) => binId,
  (binCards, cardId, binId) => {
    return Object.values(binCards).find(
      bc => bc.cardId === cardId && bc.binId === binId
    );
  }
)((state, binId) => binId);

const getLeftMostBinIdInCard = (state, cardId) =>
  getBinIdsInCard(state, cardId)[0];

const getLeftMostBinInCard = (state, cardId) => getBinsInCard(state, cardId)[0];

const getRightMostBinIdInCard = (state, cardId) => {
  const binIds = getBinIdsInCard(state, cardId);
  return binIds[binIds.length - 1];
};

const getRightMostBinInCard = (state, cardId) => {
  const bins = getBinsInCard(state, cardId);
  return bins[bins.length - 1];
};

const getSetsInformationToRenderForCard = tgCreateCachedSelector(
  getCardSets,
  state => state.ui.designEditor.tree.cardSetExpansions,
  (card, cardSetExpansions) => {
    const cardId = card.id;
    const setExpansions = cardSetExpansions[cardId] || {};
    const recurse = (set, info = []) => {
      if (!set.childSets.length) {
        info.push({ id: set.id, numberOfLeaves: 1 });
      } else if (setExpansions[set.id]) {
        info.push({
          id: set.id,
          numberOfLeaves: leavesChildSetsAll(set).length
        });
      } else {
        set.childSets.forEach(child => recurse(child, info));
      }
      return info;
    };
    return recurse(card);
  }
)((state, cardId) => cardId);

const getElementsInBin = tgCreateCachedSelector(
  (state, binId) => getReferencedValue(state, "bin", binId, "elements"),
  elements => [...elements].sort((a, b) => a.index - b.index)
)((state, binId) => binId);

const getElementIdsInBin = tgCreateCachedSelector(getElementsInBin, elements =>
  elements.map(el => el.id)
)((state, binId) => binId);

const getRowIndexToElement = tgCreateCachedSelector(
  getElementsInBin,
  elements => keyBy(elements, "index")
)((state, binId) => binId);

const getInjectingOperationOfSet = tgCreateCachedSelector(
  (state, setId) => getReferencedValue(state, "set", setId, "opSetInjecteds"),
  state => getAllOfType(state, "operation"),
  (opSetInjecteds, operations) => {
    const operationSet = opSetInjecteds.find(
      os => operations[os.injectingOpId]
    );
    return operationSet ? operations[operationSet.injectingOpId] : null;
  }
)((state, setId) => setId);

const isSetInjectedByOperation = tgCreateCachedSelector(
  (state, setId) => getReferencedValue(state, "set", setId, "opSetInjecteds"),
  (state, setId, operationId) => operationId,
  (opSetInjecteds, operationId) =>
    opSetInjecteds.some(os => os.injectingOpId === operationId)
)((state, setId, operationId) => `${setId}:${operationId}`);

const getNumberOfRowsForCard = createSelector(
  getDesign,
  design => design.numRows
);

const getBlastResultsOfElement = tgCreateCachedSelector(
  (state, cardId) => cardId,
  (state, cardId, elementId) => elementId,
  state => getAllOfType(state, "operationSet"),
  state => getAllOfType(state, "blastResultOpSetInput"),
  state => getAllOfType(state, "blastResult"),
  (cardId, elementId, operationSets, blastResultOpSetInputs, blastResults) => {
    const opSetInput = Object.values(operationSets).find(
      os => os.inputSetId === cardId
    );
    if (!opSetInput) return [];
    return Object.values(blastResultOpSetInputs).reduce((a, r) => {
      if (
        r.opSetInputId === opSetInput.id &&
        blastResults[r.blastResultId].elementId === elementId
      )
        a.push(blastResults[r.blastResultId]);
      return a;
    }, []);
  }
)((state, cardId, elementId) => `${cardId}:${elementId}`);

const getDsfForCard = (state, cardId) =>
  !!getFieldOnItem(state, "card", cardId, "dsf");

const doesSetHaveBackbone = tgCreateCachedSelector(
  (state, cardId) => cardId,
  (state, cardId) =>
    getReferencedValue(state, "set", cardId, "parentSets").find(
      ss => ss.index === 0
    ),
  state => getAllOfType(state, "set"),
  state => getAllOfType(state, "icon"),
  (cardId, firstSetSet, sets, icons) => {
    const setIdToGetIconOf = firstSetSet ? firstSetSet.childSetId : cardId;
    const icon = icons[sets[setIdToGetIconOf].iconId];
    return isIconBackbone(icon);
  }
)((state, cardId) => cardId);

const isSetBackbone = tgCreateCachedSelector(
  (state, setId) => getItemOfType(state, "set", setId),
  state => getAllOfType(state, "icon"),
  (set, icons) => isIconBackbone(icons[set.iconId])
)((state, setId) => setId);

const calcLevels = node => {
  // This will only be called if this is the root node.
  if (!node.level) {
    node.level = "1";
  }
  node.children.forEach((child, i) => {
    child.level = node.level + "." + (i + 1);
    calcLevels(child);
  });
  return node;
};

const performingCreatingSubtree = (cards, reactions, cardId) => {
  const reaction = reactions[cards[cardId].outputReactionId];
  const reactionId = reaction && reaction.id;
  return {
    id: cardId,
    reactionId,
    children: reactionId
      ? Object.values(cards)
          .filter(c => c.inputReactionId === reactionId)
          .sort((a, b) => a.inputIndex - b.inputIndex)
          .map(c => performingCreatingSubtree(cards, reactions, c.id))
      : []
  };
};
const createCardTree = createSelector(
  state => getAllOfType(state, "card"),
  state => getAllOfType(state, "reaction"),
  (cards, reactions) => {
    const rootCards = Object.values(cards).filter(c => c.isRoot);
    if (rootCards.length !== 1)
      throw new Error(
        `Design has ${rootCards.length} root cards. Must have exactly 1.`
      );
    return calcLevels(
      performingCreatingSubtree(cards, reactions, rootCards[0].id)
    );
  }
);

const getOperationIdsInTree = createSelector(createCardTree, cardTree => {
  const operationIds = [];
  dftAll(cardTree, "children", card => {
    if (card.operationId) operationIds.push(card.operationId);
  });
  return operationIds;
});

const getLevelOfCard = tgCreateCachedSelector(
  (state, cardId) => cardId,
  createCardTree,
  (cardId, cardTree) => {
    let level = "";
    dftAll(cardTree, "children", card => {
      if (card.id === cardId) level = card.level;
    });
    return level;
  }
)((state, cardId) => cardId);

const isCardRoot = (state, cardId) =>
  !!getFieldOnItem(state, "card", cardId, "isRoot");

const getRootCard = createSelector(
  state => getAllOfType(state, "card"),
  cards => Object.values(cards).find(c => c.isRoot)
);

const getRootCardId = state => getRootCard(state).id;

const isElementMapped = tgCreateCachedSelector(
  (state, elementId) => getItemOfType(state, "element", elementId),
  element => !!(element.partId || element.bps || element.partsetId)
)((state, elementId) => elementId);

const getSizeOfPart = tgCreateCachedSelector(
  (state, partId) => getItemOfType(state, "part", partId),
  (state, partId) => getReferencedValue(state, "part", partId, "sequenceId"),
  (part, sequence) => {
    if (part.start <= part.end) {
      return part.end - part.start + 1;
    } else {
      return sequence.size - part.start + part.end + 1;
    }
  }
)((state, partId) => partId);

const getSizeOfElement = tgCreateCachedSelector(
  state => state,
  (state, elementId) => getItemOfType(state, "element", elementId),
  (state, element) => {
    let len = element.partId
      ? getSizeOfPart(state, element.partId)
      : get(element, "bps.length", 0);
    len +=
      get(element, "extraStartSequence.length", 0) +
      get(element, "extraEndSequence.length", 0);
    return len;
  }
)((state, elementId) => elementId);

const getSequenceStringOfSequence = tgCreateCachedSelector(
  (state, sequenceId) =>
    getFieldOnItem(state, "sequence", sequenceId, "sequence"),
  (state, sequenceId) =>
    getReferencedValue(state, "sequence", sequenceId, "sequenceFragments"),
  (sequence, fragments) =>
    sequence ||
    fragments
      .sort((a, b) => a.index - b.index)
      .reduce((s, f) => s + f.fragment, "")
)((state, sequenceId) => sequenceId);

const getSequenceStringOfPart = tgCreateCachedSelector(
  (state, partId) => getItemOfType(state, "part", partId),
  (state, partId) =>
    getSequenceStringOfSequence(
      state,
      getItemOfType(state, "part", partId).sequenceId
    ).toLowerCase(),
  (part, sequenceString) => {
    let partSeq;
    if (part.start <= part.end) {
      partSeq = sequenceString.slice(part.start, part.end + 1);
    } else {
      partSeq =
        sequenceString.slice(part.start) +
        sequenceString.slice(0, part.end + 1);
    }
    if (part.strand === -1) {
      partSeq = getReverseComplementSequenceString(partSeq);
    }
    return partSeq;
  }
)((state, partId) => partId);

const getAminoAcidStringOfAminoAcidPart = tgCreateCachedSelector(
  state => state,
  (state, aminoAcidPartId) =>
    getItemOfType(state, "aminoAcidPart", aminoAcidPartId),
  (state, aminoAcidPart) => {
    const aaSeq = getItemOfType(
      state,
      "aminoAcidSequence",
      aminoAcidPart.aminoAcidSequenceId
    );

    return aaSeq.proteinSequence.slice(
      Math.round(aminoAcidPart.start / 3),
      Math.round(aminoAcidPart.end / 3)
    );
  }
)((state, partId) => partId);

const getSequenceStringOfElement = tgCreateCachedSelector(
  state => state,
  (state, elementId) => getItemOfType(state, "element", elementId),
  (state, elementId, ignoreExtraSequences) => ignoreExtraSequences,
  (state, element, ignoreExtraSequences) => {
    let str;
    if (element.partId) str = getSequenceStringOfPart(state, element.partId);
    else str = element.bps || "";
    if (!ignoreExtraSequences && element.extraStartSequence)
      str = element.extraStartSequence + str;
    if (!ignoreExtraSequences && element.extraEndSequence)
      str = str + element.extraEndSequence;
    return str;
  }
)(
  (state, elementId, ignoreExtraSequences) =>
    `${elementId}:${!!ignoreExtraSequences}`
);

const performGettingOutputtingOperationOfCard = (
  operationSets,
  operations,
  cardId
) => {
  const opSetOutput = Object.values(operationSets).find(
    os => os.outputSetId === cardId
  );
  if (!opSetOutput) return null;
  return operations[opSetOutput.outputtingOpId];
};

const getOutputtingReactionIdOfCard = tgCreateCachedSelector(
  state => getAllOfType(state, "card"),
  (state, cardId) => cardId,
  (cards, cardId) => cards[cardId].outputReactionId || null
)((state, cardId) => cardId);

const getOutputtingReactionOfCard = tgCreateCachedSelector(
  state => getAllOfType(state, "reaction"),
  state => getAllOfType(state, "card"),
  (state, cardId) => cardId,
  (reactions, cards, cardId) =>
    reactions[cards[cardId].outputReactionId] || null
)((state, cardId) => cardId);

const getInputReactionIdOfCard = (state, cardId) =>
  getFieldOnItem(state, "card", cardId, "inputReactionId");

const getInputReactionOfCard = tgCreateCachedSelector(
  state => getAllOfType(state, "reaction"),
  getInputReactionIdOfCard,
  (reactions, reactionId) => reactions[reactionId]
)((state, cardId) => cardId);

const getOutputCardIdOfReaction = tgCreateCachedSelector(
  state => getAllOfType(state, "card"),
  (state, reactionId) => reactionId,
  (cards, reactionId) =>
    Object.values(cards).find(c => c.outputReactionId === reactionId).id
)((state, reactionId) => reactionId);

const getOutputCardIdOfOperation = tgCreateCachedSelector(
  state => getAllOfType(state, "operationSet"),
  (state, operationId) => operationId,
  (operationSets, operationId) =>
    Object.values(operationSets).find(os => os.outputtingOpId === operationId)
      .outputSetId
)((state, operationId) => operationId);

const getInputCardsOfReaction = tgCreateCachedSelector(
  (state, reactionId) =>
    getReferencedValue(state, "reaction", reactionId, "cards"),
  cards => [...cards].sort((a, b) => a.inputIndex - b.inputIndex)
)((state, reactionId) => reactionId);

const getInputCardThatContainsBin = createSelector(
  state => state,
  (state, reactionId) =>
    getReferencedValue(state, "reaction", reactionId, "cards"),
  (state, reactionId, binId) => binId,
  (state, cards, binId) => {
    return cards.find(card => {
      const bins = getBinsInCard(state, card.id);
      return bins.some(bin => bin.id === binId);
    });
  }
);

const getBinCardByReactionIdAndElementId = tgCreateCachedSelector(
  getInputCardsOfReaction,
  state => getAllOfType(state, "binCard"),
  (state, reactionId, elementId) => getItemOfType(state, "element", elementId),
  (inputCards, binCards, element) =>
    Object.values(binCards).find(
      binCard =>
        binCard.binId === element.binId &&
        inputCards.find(inputCard => inputCard.id === binCard.cardId)
    )
)((state, binId) => binId);

const getNeighborBinCardByReactionIdAndElementId = tgCreateCachedSelector(
  state => state,
  getInputCardsOfReaction,
  state => getAllOfType(state, "binCard"),
  (state, _, elementId) => getItemOfType(state, "element", elementId),
  (_, __, ___, threePrimeNeighbor) => threePrimeNeighbor,
  (state, inputCards, binCards, element, threePrimeNeighbor = false) => {
    const mainBinCard = Object.values(binCards).find(
      binCard =>
        binCard.binId === element.binId &&
        inputCards.some(card => card.id === binCard.cardId)
    );

    const mainInputCard = inputCards.find(
      inputCard => inputCard.id === mainBinCard.cardId
    );

    const parentCardId = getIdOfParentCard(state, mainInputCard.id);
    const parentCard = getItemOfType(state, "card", parentCardId);
    const isCircular = parentCard.circular;

    let neighborBinIndex;
    // Gets the right neighbor (3 prime neighbor)
    if (threePrimeNeighbor) {
      // For circular cases, if the main input card is the last one, the right neighbor is the first one.
      // otherwise, it's the one after.
      neighborBinIndex =
        mainInputCard.inputIndex === inputCards.length - 1
          ? isCircular
            ? 0
            : null
          : mainInputCard.inputIndex + 1;
    }
    // Gets the left neighbor (5 prime neighbor)
    else {
      // For circular cases, if the main input card is the first one, the left neighbor is the last one.
      // otherwise, it's the one before.
      neighborBinIndex =
        mainInputCard.inputIndex === 0
          ? isCircular
            ? inputCards.length - 1
            : null
          : mainInputCard.inputIndex - 1;
    }

    const neighborInputCard = inputCards.find(
      inputCard => inputCard.inputIndex === neighborBinIndex
    );

    if (!neighborInputCard) return null;

    return Object.values(binCards).find(
      bc => bc.cardId === neighborInputCard.id
    );
  }
)((state, binId) => binId);

const doesCardContainInvalidity = tgCreateCachedSelector(
  state => state,
  (state, cardId) => getItemOfType(state, "card", cardId),
  getBinsInCard,
  (state, card, bins) => {
    if (card.invalidityMessage) return true;
    return bins.some(
      s =>
        s.invalidityMessage ||
        getElementsInBin(state, s.id).some(el => el.invalidityMessage)
    );
  }
)((state, cardId) => cardId);

const getParentSet = tgCreateCachedSelector(
  (state, cardId) => cardId,
  (state, cardId, setId) => setId,
  state => getAllOfType(state, "setSet"),
  state => getAllOfType(state, "set"),
  (state, cardId) => getAllSetIdsInCard(getDesignState(state), cardId),
  (cardId, setId, setSets, sets, setIdsInCard) => {
    const possibilities = Object.values(setSets).filter(
      ss => ss.childSetId === setId && setIdsInCard.includes(ss.parentSetId)
    );
    if (possibilities.some(ss => ss.parentSetId === cardId))
      return sets[cardId];
    else if (!possibilities.length) return null;
    else if (possibilities.length === 1)
      return sets[possibilities[0].parentSetId];
    else
      throw new Error(
        `There are ${possibilities.length} possibilities for the parent bin. There should only be one.`
      );
  }
)((state, cardId, setId) => `${cardId}:${setId}`);

const getAllParentSets = tgCreateCachedSelector(
  (state, setId) => setId,
  state => getAllOfType(state, "setSet"),
  state => getAllOfType(state, "set"),
  (setId, setSets, sets) =>
    Object.values(setSets).reduce((acc, ss) => {
      if (ss.childSetId === setId) acc.push(sets[ss.parentSetId]);
      return acc;
    }, [])
)((state, setId) => setId);

const getNeighborCardInOperation = tgCreateCachedSelector(
  (state, cardId) => cardId,
  (state, cardId, isPrevious) => isPrevious,
  (state, cardId) => {
    const operationId = getInputReactionIdOfCard(state, cardId);
    return (
      operationId &&
      getFieldOnItem(state, "operation", operationId, "isOutputCircular")
    );
  },
  (state, cardId) => {
    const operationId = getInputReactionIdOfCard(state, cardId);
    return operationId && getInputCardsOfReaction(state, operationId);
  },
  (cardId, isPrevious, isOutputCircular, inputCards) => {
    if (!inputCards) return null;
    const index = inputCards.findIndex(c => c.id === cardId);
    if (isPrevious) {
      if (index !== 0) return inputCards[index - 1];
      else return isOutputCircular ? inputCards[inputCards.length - 1] : null;
    } else {
      if (index !== inputCards.length - 1) return inputCards[index + 1];
      else return isOutputCircular ? inputCards[0] : null;
    }
  }
)((state, cardId, isPrevious) => `${cardId}:${!!isPrevious}`);

const getOpSetOutputOfOperation = tgCreateCachedSelector(
  (state, operationId) => operationId,
  state => getAllOfType(state, "operationSet"),
  (operationId, operationSets) =>
    Object.values(operationSets).find(os => os.outputtingOpId === operationId)
)((state, operationId) => operationId);

const getOpSetOutputOfCard = tgCreateCachedSelector(
  (state, cardId) => cardId,
  state => getAllOfType(state, "operationSet"),
  (cardId, operationSets) =>
    Object.values(operationSets).find(os => os.outputSetId === cardId)
)((state, cardId) => cardId);

const doesCardHaveElementsWithBlastResults = () => false;

const isLeafCard = (state, cardId) =>
  !getFieldOnItem(state, "card", cardId, "outputReactionId");

const getJ5OutputNamingTemplates = tgCreateCachedSelector(
  state => getAllOfType(state, "j5OutputNamingTemplate"),
  (state, reactionId) =>
    getReferencedValue(
      state,
      "reaction",
      reactionId,
      "reactionJ5OutputNamingTemplates"
    ),
  (j5OutputNamingTemplates, reactionJ5OutputNamingTemplates) =>
    reactionJ5OutputNamingTemplates.map(
      item => j5OutputNamingTemplates[item.j5OutputNamingTemplateId]
    )
)((state, reactionId) => reactionId);

const getJ5OutputNamingTemplatesMap = tgCreateCachedSelector(
  getJ5OutputNamingTemplates,
  j5OutputNamingTemplates => keyBy(j5OutputNamingTemplates, "outputTarget")
)((state, reactionId) => reactionId);

const doesBinContainElement = tgCreateCachedSelector(
  (state, setId, elementId) => elementId,
  getElementsInBin,
  (elementId, elements) => elements.some(el => el.id === elementId)
)((state, setId, elementId) => `${setId}:${elementId}`);

const getViewOptionForCard = tgCreateCachedSelector(
  state => state.ui.designEditor.general.viewOptions,
  (state, cardId) => state.ui.designEditor.tree.cardViewOptions[cardId],
  (state, cardId, fieldName) => fieldName,
  (globalViewOptions, localViewOptions, fieldName) => {
    localViewOptions = localViewOptions || {};
    return (localViewOptions[fieldName] ? localViewOptions : globalViewOptions)[
      fieldName
    ];
  }
)((state, cardId, fieldName) => `${cardId}:${fieldName}`);

const doesElementHaveExtraSequence = tgCreateCachedSelector(
  (state, elementId) => getItemOfType(state, "element", elementId),
  element => !!(element.extraStartSequence || element.extraEndSequence)
)((state, elementId) => elementId);

/**
 * When applying a design template to a bin, we normally should be able to
 * apply the design template only to leaf cards. However, we sometimes wish
 * to apply the multiple templates to the same card. The intermediate states
 * will involve having classic-design style child cards that can have design
 * templates applied to them.
 */
const getBinIdsThatCanFillTemplatePlaceholders = tgCreateCachedSelector(
  state => state,
  (state, cardId) => cardId,
  (state, cardId) => {
    const binIds = getBinIdsInCard(state, cardId);
    const outputtingReaction = getOutputtingReactionOfCard(state, cardId);

    if (!outputtingReaction) return binIds;

    const childCards = getInputCardsOfReaction(state, outputtingReaction.id);

    return binIds.filter(binId => {
      return !childCards.some(childCard => {
        const binIds = getBinIdsInCard(state, childCard.id);
        if (!binIds.includes(binId)) return false;
        return binIds.length > 2;
      });
    });
  }
)((state, cardId) => cardId);

const isCardBackboneInputToReaction = tgCreateCachedSelector(
  (state, cardId) => cardId,
  getInputReactionOfCard,
  (state, cardId) => {
    const inputReactionId = getInputReactionIdOfCard(state, cardId) || null;
    return inputReactionId && getInputCardsOfReaction(state, inputReactionId);
  },
  (cardId, parentReaction, inputs) => {
    if (!parentReaction) return false;
    const index = inputs.findIndex(c => c.id === cardId);
    return index === 0 && parentReaction.isOutputCircular;
  }
)((state, cardId) => cardId);

const getSetIdsContainingElement = tgCreateCachedSelector(
  (state, elementId) => elementId,
  state => getAllOfType(state, "setElement"),
  (elementId, setElements) =>
    Object.values(setElements).reduce((acc, se) => {
      if (se.elementId === elementId) acc.push(se.setId);
      return acc;
    }, [])
)((state, elementId) => elementId);

const getRightJunctionBpsForElement = tgCreateCachedSelector(
  state => state,
  (state, reactionId) => reactionId,
  (state, reactionId, elementId) => elementId,
  getInputCardsOfReaction,
  state => getAllOfType(state, "binCard"),
  (state, reactionId, elementId, inputCards, _binCards) => {
    let rightJunctionBps = null;
    // get only the binCards that are inputs of reaction
    const binCards = Object.values(_binCards).filter(
      binCard => !!inputCards.find(inputCard => inputCard.id === binCard.cardId)
    );

    // for each binCard, get elements in bin
    binCards.some(bc => {
      const binEls = getElementsInBin(state, bc.binId);
      if (binEls.find(el => el.id === elementId)) {
        rightJunctionBps = bc.rightJunctionBps;
        return true;
      }
      return false;
    });

    return rightJunctionBps;
  }
)((state, elementId) => elementId);

const getRuleSetsOfBin = tgCreateCachedSelector(
  (state, binId) => getReferencedValue(state, "bin", binId, "binRuleSets"),
  state => getAllOfType(state, "ruleSet"),
  (binRuleSets, ruleSets) => binRuleSets.map(brs => ruleSets[brs.ruleSetId])
)((state, binId) => binId);

const getEugeneRulesOfElement = tgCreateCachedSelector(
  (state, cardId) => cardId,
  (state, cardId, elementId) => elementId,
  getInputReactionIdOfCard,
  state => getAllOfType(state, "eugeneRule"),
  state => getAllOfType(state, "eugeneRuleElement"),
  (cardId, elementId, reactionId, eugeneRules, eugeneRuleElements) => {
    if (!reactionId) return [];
    const eugeneRuleIds = {};
    for (const ere of Object.values(eugeneRuleElements)) {
      if (ere.elementId === elementId) {
        if (ere.eugeneRule1Id)
          eugeneRuleIds[ere.eugeneRule1Id] = eugeneRules[ere.eugeneRule1Id];
        if (ere.eugeneRule2Id)
          eugeneRuleIds[ere.eugeneRule2Id] = eugeneRules[ere.eugeneRule2Id];
      }
    }
    return Object.values(eugeneRuleIds).filter(
      r => r.reactionId === reactionId
    );
  }
)((state, cardId, elementId) => `${cardId}:${elementId}`);

const getAllElementsInInputCards = tgCreateCachedSelector(
  state => state,
  getInputCardsOfReaction,
  (state, inputCards) =>
    uniqBy(
      flatMapDeep(inputCards, c =>
        getBinIdsInCard(state, c.id).map(bId => getElementsInBin(state, bId))
      ),
      "id"
    )
)((state, reactionId) => reactionId);

const getEugeneRulesOfReaction = tgCreateCachedSelector(
  state => state,
  (state, reactionId) =>
    getReferencedValue(state, "reaction", reactionId, "eugeneRules"),
  (state, eugeneRules) =>
    eugeneRules.map(r => ({
      ...r,
      operand1Elements: getOperand1Elements(state, r.id),
      operand2Elements: getOperand2Elements(state, r.id)
    }))
)((state, reactionId) => reactionId);

const isLeafOperation = tgCreateCachedSelector(
  state => state,
  getInputCardsOfReaction,
  (state, inputCards) => inputCards.every(c => isLeafCard(state, c.id))
)((state, operationId) => operationId);

const isDesignHierarchical = state =>
  Object.keys(getAllOfType(state, "reaction")).length > 1;

const getNumberOfBinsInRootCard = createSelector(
  state => getAllOfType(state, "card"),
  state => getAllOfType(state, "binCard"),
  (cards, binCards) => {
    if (Object.keys(cards).length > 0) {
      const rootCardId = Object.values(cards).filter(el => !!el.isRoot)[0].id;
      const rootBinCards = Object.values(binCards).filter(
        el => el.cardId === rootCardId
      );
      return rootBinCards.length;
    }
    return undefined;
  }
);

const isLayoutCombinatorial = state =>
  getDesign(state).layoutType === "combinatorial";

const isLayoutList = state => getDesign(state).layoutType === "list";

const getOperand1Elements = tgCreateCachedSelector(
  state => getAllOfType(state, "element"),
  (state, eugeneRuleId) =>
    getReferencedValue(
      state,
      "eugeneRule",
      eugeneRuleId,
      "operand1EugeneRuleElements"
    ),
  (elements, operand1EugeneRuleElements) =>
    [...operand1EugeneRuleElements]
      .sort((a, b) => a.index - b.index)
      .map(ere => elements[ere.elementId])
)((state, eugeneRuleId) => eugeneRuleId);

const getOperand2Elements = tgCreateCachedSelector(
  state => getAllOfType(state, "element"),
  (state, eugeneRuleId) =>
    getReferencedValue(
      state,
      "eugeneRule",
      eugeneRuleId,
      "operand2EugeneRuleElements"
    ),
  (elements, operand1EugeneRuleElements) =>
    [...operand1EugeneRuleElements]
      .sort((a, b) => a.index - b.index)
      .map(ere => elements[ere.elementId])
)((state, eugeneRuleId) => eugeneRuleId);

const getAllElementCombosOfCard = (state, cardId) =>
  getReferencedValue(state, "card", cardId, "elementCombos");

const getAvailableElementCombosOfCard = tgCreateCachedSelector(
  getAllElementCombosOfCard,
  allCombos => allCombos.filter(ec => ec.availablePartId)
)((state, cardId) => cardId);

const getElementsInCombo = tgCreateCachedSelector(
  state => getAllOfType(state, "element"),
  (state, elementComboId) =>
    getReferencedValue(
      state,
      "elementCombo",
      elementComboId,
      "elementElementCombos"
    ),
  (elements, elementElementCombos) =>
    [...elementElementCombos]
      .sort((a, b) => a.index - b.index)
      .map(eec => elements[eec.elementId])
)((state, elementComboId) => elementComboId);

const getFullAvailableElementCombosOfCard = tgCreateCachedSelector(
  state => state,
  getAvailableElementCombosOfCard,
  (state, elementCombos) =>
    elementCombos.map(ec => ({
      ...ec,
      elements: getElementsInCombo(state, ec.id)
    }))
)((state, cardId) => cardId);

const getAllCombinationsGroupsOfCards = (state, cardId) =>
  getReferencedValue(state, "card", cardId, "elementGroupCombos");

const getEliminatedCombinationGroupsOfCard = tgCreateCachedSelector(
  (state, cardId) =>
    getReferencedValue(state, "card", cardId, "elementGroupCombos"),
  elementGroupCombos => elementGroupCombos.filter(egc => egc.isDeleted)
)((state, cardId) => cardId);

const getFullEliminatedCombinationGroupsOfCard = tgCreateCachedSelector(
  state => state,
  state => getAllOfType(state, "element"),
  getEliminatedCombinationGroupsOfCard,
  (state, elements, comboGroups) =>
    comboGroups.map(comboGroup => {
      const elementGroups = getReferencedValue(
        state,
        "elementGroupCombo",
        comboGroup.id,
        "elementGroupElementGroupCombos"
      ).reduce((acc, eg) => {
        if (!acc[eg.index]) acc[eg.index] = [];
        acc[eg.index].push(elements[eg.elementId]);
        return acc;
      }, []);
      for (let i = 0, ii = elementGroups.length; i < ii; i++) {
        elementGroups[i] = elementGroups[i] || [];
      }
      return { ...comboGroup, elementGroups };
    })
)((state, cardId) => cardId);

const getFasOfElement = tgCreateCachedSelector(
  (state, cardId) => cardId,
  (state, cardId, elementId) => elementId,
  getInputReactionIdOfCard,
  state => getAllOfType(state, "fas"),
  (cardId, elementId, inputReactionId, fases) => {
    if (!inputReactionId) return null;
    return Object.values(fases).find(
      f => f.elementId === elementId && f.reactionId === inputReactionId
    );
  }
)((state, cardId, elementId) => `${cardId}:${elementId}`);

const getPartOfElement = createSelector(
  (state, elementId) => getItemOfType(state, "element", elementId),
  state => getAllOfType(state, "part"),
  (element, parts) => get(parts, `${element?.partId}`)
);

const getPartSetOfElement = createSelector(
  (state, elementId) => getItemOfType(state, "element", elementId),
  state => getAllOfType(state, "partset"),
  (element, partsets) => get(partsets, `${element?.partsetId}`)
);

const getRestrictionEnzymeOfReaction = createSelector(
  (state, reactionId) => getItemOfType(state, "reaction", reactionId),
  state => getAllOfType(state, "restrictionEnzyme"),
  (reaction, restrictionEnzymes) =>
    get(restrictionEnzymes, `${reaction?.restrictionEnzymeId}`)
);

const isCardCircular = (state, cardId) =>
  !!getFieldOnItem(state, "card", cardId, "circular");

const doesCardHaveReaction = (...args) => !isLeafCard(...args);

const performGettingIdOfParentCard = (operationSets, cardId) => {
  operationSets = Object.values(operationSets);
  const opSetInput = operationSets.find(os => os.inputSetId === cardId);
  if (!opSetInput) return null;
  else {
    const operationSet = operationSets.find(
      os => os.outputtingOpId === opSetInput.operationId
    );
    return operationSet ? operationSet.outputSetId : null;
  }
};

const getChildCards = (state, cardId) => {
  const outputtingReactionId = getOutputtingReactionIdOfCard(state, cardId);
  if (!outputtingReactionId) return [];
  return getInputCardsOfReaction(state, outputtingReactionId);
};

const getChildCardIds = tgCreateCachedSelector(getChildCards, cards =>
  cards.map(c => c.id)
)((state, cardId) => cardId);

const getIdOfParentCard = tgCreateCachedSelector(
  state => getAllOfType(state, "card"),
  getInputReactionIdOfCard,
  (cards, reactionId) => {
    if (!reactionId) return null;

    const parentCard = Object.values(cards).find(
      c => c.outputReactionId === reactionId
    );
    return parentCard ? parentCard.id : null;
  }
)((state, cardId) => cardId);

/**
 * Get a map from reaction id to the depth of the reaction in the tree.
 * The outputting reaction of the root card will have a depth of 0.
 * @param {Object} state
 * @returns {Map<string,int>}
 */
const getReactionDepthMap = createSelector(createCardTree, cardTree => {
  const depthMap = {};
  function processCard(card, depth = 0) {
    if (card.reactionId) depthMap[card.reactionId] = depth;
    for (const child of card.children) {
      processCard(child, depth + 1);
    }
  }
  processCard(cardTree);
  return depthMap;
});

/**
 * Given a card, bin, and direction, find the id of the neighbor of the bin. This selector
 * enables wrapping around the origin on circular cards.
 *
 * If the neighbor doesn't exist, then this selector returns null.
 * @param {Object} state
 * @param {string} cardId The card we want to find the neighbor on.
 * @param {string} binId The bin whose neighbor we want to find
 * @param {boolean} threePrime Whether to find the three prime neighbor or five neighbor of the bin.
 * @param {boolean} ignoreCircular Disable wrapping around the origin when finding the neighbor..
 */
const getNeighborBinId = tgCreateCachedSelector(
  (state, cardId, binId) => binId,
  (state, cardId, binId, threePrime) => threePrime,
  (state, cardId, binId, threePrime, ignoreCircular) => ignoreCircular,
  (state, cardId, binId, threePrime, ignoreCircular, ignoreBinNotFoundError) =>
    ignoreBinNotFoundError,
  getBinIdsInCard,
  isCardCircular,
  (
    binId,
    threePrime,
    ignoreCircular,
    ignoreBinNotFoundError,
    binIds,
    isCircular
  ) => {
    const binIndex = binIds.indexOf(binId);
    if (binIndex === -1) {
      if (ignoreBinNotFoundError) return null;
      throw new Error("Bin not found in card.");
    }

    let neighborIndex = threePrime ? binIndex + 1 : binIndex - 1;

    // This enables wrapping of the origin.
    if (!ignoreCircular && isCircular) {
      neighborIndex = (neighborIndex + binIds.length) % binIds.length;
    }

    return binIds[neighborIndex] || null;
  }
)(
  (state, cardId, binId, threePrime, ignoreCircular) =>
    `${cardId}:${binId}:${!!threePrime}:${!!ignoreCircular}`
);

/**
 * Get the ids of all of the parts that belong to a given part set.
 * @param {string} partsetId
 */
const getAllPartIdsInPartset = tgCreateCachedSelector(
  (state, partsetId) =>
    getReferencedValue(state, "partset", partsetId, "partsetParts"),
  partsetParts => partsetParts.map(x => x.partId)
)((state, partsetId) => partsetId);

/**
 * Get the ids of the active parts that belong to a given part set.
 * @param {string} partsetId
 */
const getPartIdsInPartset = tgCreateCachedSelector(
  (state, partsetId) =>
    getReferencedValue(state, "partset", partsetId, "partsetParts"),
  partsetParts => partsetParts.filter(x => !x.isDeactivated).map(x => x.partId)
)((state, partsetId) => partsetId);

/**
 * Get all of the parts that belong to a given part set.
 * @param {string} partsetId
 */
const getAllPartsInPartset = tgCreateCachedSelector(
  state => getAllOfType(state, "part"),
  getPartIdsInPartset,
  (parts, partIds) => partIds.map(pId => parts[pId])
)((state, partsetId) => partsetId);

/**
 * Get parts that belong to a given part set that haven't been deactivated
 * @param {string} partsetId
 */
const getPartsInPartset = tgCreateCachedSelector(
  state => getAllOfType(state, "part"),
  getPartIdsInPartset,
  (parts, partIds) => partIds.map(pId => parts[pId])
)((state, partsetId) => partsetId);

const doesDesignHavePartsets = createSelector(
  state => getAllOfType(state, "partset"),
  partsets => !!Object.keys(partsets).length
);

/**
 * kc_todo rewrite this using the cached style
 * Load part info onto part set parts
 * @param {string} partsetId
 */
const getPartSetPartsWithPartInfo = (state, partsetId) => {
  const allParts = getAllOfType(state, "part");
  const partsetParts = getReferencedValue(
    state,
    "partset",
    partsetId,
    "partsetParts"
  );

  return partsetParts.map(psp => ({ ...allParts[psp.partId], ...psp }));
};

/**
 * Get the sequence string of an element as an array. Required whenver we deal with
 * part set elements.
 */
const getSequenceStringsOfElement = tgCreateCachedSelector(
  state => state,
  (state, elementId) => getItemOfType(state, "element", elementId),
  (state, elementId, ignoreExtraSequences) => ignoreExtraSequences,
  (state, element, ignoreExtraSequences) => {
    let strs = null;
    if (element.partsetId) {
      strs = getPartIdsInPartset(state, element.partsetId).map(pId =>
        getSequenceStringOfPart(state, pId)
      );
    } else if (element.aminoAcidPartId) {
      strs = [""];
    } else if (element.partId) {
      strs = [getSequenceStringOfPart(state, element.partId)];
    } else {
      strs = [element.bps || ""];
    }
    if (!ignoreExtraSequences) {
      strs = strs.map(
        s =>
          (element.extraStartSequence || "") +
          s +
          (element.extraEndSequence || "")
      );
    }
    return strs;
  }
)(
  (state, elementId, ignoreExtraSequences) =>
    `${elementId}:${!!ignoreExtraSequences}`
);

const getCustomParametersOfReaction = createSelector(
  (state, reactionId) => getItemOfType(state, "reaction", reactionId),
  state => getAllOfType(state, "customJ5Parameter"),
  (reaction, parameters) =>
    Object.values(parameters).find(
      param => param.id === reaction.customJ5ParameterId
    )
);

/**
 * Get the total number of items in the bin. For bins without partsets,
 * this will just be the number of elements in the bin. For bins with
 * part sets, each part in the part set will be counted.
 */
const getNumberOfItemsInBin = tgCreateCachedSelector(
  state => state,
  getElementsInBin,
  (state, elements) =>
    elements.reduce((acc, el) => {
      if (el.partsetId) {
        return acc + getNumberOfActivePartsInPartset(state, el.partsetId);
      } else {
        return acc + 1;
      }
    }, 0)
)((state, binId) => binId);

/**
 * Get a map from element id to the number of items that the element represents.
 * For part sets, this will be the number of parts in the part set. For everything
 * else, this will be 1.
 */
const getElementToNumberOfItemsMap = createSelector(
  state => getAllOfType(state, "element"),
  state => getAllOfType(state, "partset"),
  (elements, partsets) => {
    const map = {};
    for (const el of Object.values(elements)) {
      if (el.partsetId) {
        map[el.id] = partsets[el.partsetId].numParts;
      } else {
        map[el.id] = 1;
      }
    }
    return map;
  }
);

const getElementGroupWithCardId = createSelector(
  [
    state => state,
    (state, cardId) => cardId,
    state => getAllOfType(state, "elementGroupCombo")
  ],
  (state, cardId, elementGroupCombos) => {
    elementGroupCombos = Object.values(elementGroupCombos);

    for (let index = 0; index < elementGroupCombos.length; index++) {
      const elementComboInCard = elementGroupCombos[index];

      if (elementComboInCard.cardId === cardId) {
        return elementComboInCard;
      }
    }
    return false;
  }
);

const doesCardHaveConstructAnnotations = tgCreateCachedSelector(
  state => state,
  (state, cardId) => cardId,
  (state, cardId) => {
    return Object.values(getAllOfType(state, "constructAnnotation")).some(
      ca => ca.cardId === cardId
    );
  }
)((state, cardId) => cardId);

const getRowsIndexIsShown = state =>
  state.ui.designEditor.general.showRowsIndex;
const getRowNamesIsShown = state => state.ui.designEditor.general.showRowNames;
const getRowDisableIsShown = state =>
  state.ui.designEditor.general.showRowDisable;

/**
 * Returns a list of the design elements sorted
 * from top-to-bottom and left-to-right.
 */
const getElements = createSelector(
  state => getAllOfType(state, "bin"),
  state => getAllOfType(state, "element"),
  (bins, elements) => {
    const binIds = Object.keys(bins);
    const elementsList = Object.values(elements);
    const sortedElements = sortBy(elementsList, [
      element => binIds.indexOf(element.binId),
      element => element.index
    ]);
    return sortedElements.map(element => ({ id: element.id }));
  }
);

const treeLayout = state =>
  state.ui.designEditor.general.viewOptions.treeLayout;

export {
  createCardTree,
  doesBinContainElement,
  doesCardContainInvalidity,
  doesCardHaveElementsWithBlastResults,
  doesCardHaveReaction,
  doesCardHaveConstructAnnotations,
  doesDesignHavePartsets,
  doesElementHaveExtraSequence,
  doesSetHaveBackbone,
  getAllCombinationsGroupsOfCards,
  getAllElementCombosOfCard,
  getAllElementsInInputCards,
  getElements,
  getAllOfType,
  getAllParentSets,
  getAllPartIdsInPartset,
  getAllPartsInPartset,
  getAminoAcidStringOfAminoAcidPart,
  getAssemblyMethodOfCard,
  getAvailableElementCombosOfCard,
  getBinIdsInCard,
  getBinIdsSetInCard,
  getBinIdsThatCanFillTemplatePlaceholders,
  getBinCardByCardIdAndBinId,
  getBinCardByReactionIdAndElementId,
  getBinsInCard,
  getBlastResultsOfElement,
  getCardSets,
  getChildCardIds,
  getChildCards,
  getChildSetIds,
  getChildSets,
  getClassicNumberOfRows,
  getCountOfType,
  getCustomParametersOfReaction,
  getDepthOfSetsInCard,
  getDesign,
  getDesignNoError,
  getDesignState,
  getDsfForCard,
  getElementGroupWithCardId,
  getElementIdsInBin,
  getElementToNumberOfItemsMap,
  getElementsInBin,
  getElementsInCombo,
  getEliminatedCombinationGroupsOfCard,
  getEugeneRulesOfElement,
  getEugeneRulesOfReaction,
  getFasOfElement,
  getPartOfElement,
  getPartSetOfElement,
  getRestrictionEnzymeOfReaction,
  getFieldOnItem,
  getFullAvailableElementCombosOfCard,
  getFullEliminatedCombinationGroupsOfCard,
  getIdOfParentCard,
  getInjectingOperationOfSet,
  getInputCardsOfReaction,
  getInputCardThatContainsBin,
  getInputReactionIdOfCard,
  getInputReactionOfCard,
  getItemByCid,
  getItemIdByCid,
  getItemOfType,
  getNumberOfActivePartsInPartset,
  getJ5OutputNamingTemplates,
  getJ5OutputNamingTemplatesMap,
  getLeafSets,
  getLeftMostBinIdInCard,
  getLeftMostBinInCard,
  getLevelOfCard,
  getMultiValuedIndex,
  getNeighborBinCardByReactionIdAndElementId,
  getNeighborBinId,
  getNeighborCardInOperation,
  getNumOfInvaliditiesInDesign,
  getNumberOfBinsInRootCard,
  getNumberOfItemsInBin,
  getNumberOfRowsForCard,
  getOpSetOutputOfCard,
  getOpSetOutputOfOperation,
  getOperand1Elements,
  getOperand2Elements,
  getOperationIdsInTree,
  getOutputCardIdOfOperation,
  getOutputCardIdOfReaction,
  getOutputtingReactionIdOfCard,
  getOutputtingReactionOfCard,
  getParentSet,
  getPartIdsInPartset,
  getPartSetPartsWithPartInfo,
  getPartsInPartset,
  getReactionDepthMap,
  getReferencedValue,
  getRightMostBinIdInCard,
  getRightMostBinInCard,
  getRightJunctionBpsForElement,
  getRootCard,
  getRootCardBinIds,
  getRootCardId,
  getRowIndexToElement,
  getRowsIndexIsShown,
  getRowNamesIsShown,
  getRowDisableIsShown,
  getRuleSetsOfBin,
  getSequenceStringOfElement,
  getSequenceStringOfPart,
  getSequenceStringOfSequence,
  getSequenceStringsOfElement,
  getSetIdsContainingElement,
  getSetsInformationToRenderForCard,
  getSizeOfElement,
  getSizeOfPart,
  getViewOptionForCard,
  isAssemblyMethodInDesign,
  isCardBackboneInputToReaction,
  isCardCircular,
  isCardRoot,
  isDesignHierarchical,
  isDisabledSameBinDigestValidation,
  isDesignTemplate,
  isDesignUniform,
  isDesignVisualReport,
  isElementMapped,
  isLayoutCombinatorial,
  isLayoutList,
  isLeafCard,
  isLeafOperation,
  isSetBackbone,
  isSetInjectedByOperation,
  performGettingCardSets,
  performGettingIdOfParentCard,
  performGettingOutputtingOperationOfCard,
  performingCreatingSubtree,
  resolveReference,
  treeLayout
};
