/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, pick, keyBy, isEmpty } from "lodash";
import pluralize from "pluralize";
import {
  TYPES,
  LOCAL_FIELDS,
  SIMPLE_REFERENCES,
  FOREIGN_REFERENCES,
  SIMPLE_REFERENCES_TO_TYPE,
  DB_TO_STATE_TYPES
} from "../constants/designStateConstants";
import recurseOnJson from "./recurseOnJson";
import { getMultiValuedIndex } from "../selectors/designStateSelectors";

const emptyMap = {};

/**
 * Get a object mapping all of the types in the design
 * state constants to an empty array.
 */
export const createArrayValuedTypeMap = () =>
  Object.keys(TYPES).reduce((map, type) => {
    map[type] = [];
    return map;
  }, {});

/**
 * Get a object mapping all of the types in the design
 * state constants to an empty object.
 */
export const createObjectValuedTypeMap = () =>
  Object.keys(TYPES).reduce((map, type) => {
    map[type] = {};
    return map;
  }, {});

/**
 * Given some item, typically returned from a graphql query, convert that
 * item to normalized form. By normalized form, I mean a map from model to
 * id to entity (same as the design state).
 *
 * For this function to work correctly, it is essential that every object in
 * the item tree has `id` and `__typename` fields.
 * @param {Object} item
 * @param {boolean} alsoIncludeSimpleReferences Should simple references (eg `sequenceId`) be added to the entities.
 */
export const flattenTree = (item, alsoIncludeSimpleReferences = true) => {
  const map = createObjectValuedTypeMap();
  recurseOnJson(
    item,
    obj => {
      const { id, __typename: type } = obj;
      //filter out empty objects
      if (isEmpty(obj)) return map;
      // labs shouldn't be in the redux state for a design
      if (type === "lab") return map;

      if (!TYPES[type]) {
        throw new Error(
          `Encountered unrecognized type: ${type} in object ${JSON.stringify(
            obj,
            null,
            2
          )}. You probably need to update the designStateConstants. Full item ${JSON.stringify(
            item,
            null,
            2
          )}`
        );
      }

      if (!map[type][id]) map[type][id] = {};

      const value = map[type][id];

      if (LOCAL_FIELDS[type])
        LOCAL_FIELDS[type].forEach(fieldName => {
          const fieldValue = obj[fieldName];
          if (fieldValue !== undefined) value[fieldName] = fieldValue;
        });

      if (SIMPLE_REFERENCES[type])
        Object.entries(SIMPLE_REFERENCES[type]).forEach(([key, path]) => {
          const fieldValue = get(obj, path);

          if (fieldValue !== undefined) value[key] = fieldValue;
        });

      if (FOREIGN_REFERENCES[type])
        Object.entries(FOREIGN_REFERENCES[type]).forEach(
          ([key, { foreignType, foreignKey }]) => {
            const foreignObjects = obj[key] || [];
            foreignObjects.forEach(foreignObj => {
              if (!map[foreignType][foreignObj.id])
                map[foreignType][foreignObj.id] = {};
              map[foreignType][foreignObj.id][foreignKey] = id;
            });
          }
        );

      if (alsoIncludeSimpleReferences && SIMPLE_REFERENCES_TO_TYPE[type]) {
        Object.keys(SIMPLE_REFERENCES_TO_TYPE[type]).forEach(fieldName => {
          const fieldValue = obj[fieldName];
          if (fieldValue !== undefined) {
            value[fieldName] = fieldValue;
          }
        });
      }
    },
    { callOnObjectsOnly: true }
  );
  return map;
};

export const dbToStateConversion = (data, type) => {
  const stateType = DB_TO_STATE_TYPES[type] || type;
  const stateData = data[pluralize(type)].map(typeRecord => ({
    ...typeRecord,
    __typename: stateType
  }));

  return { stateType: pluralize(stateType), stateData };
};

/**
 * Given a design state and a map from old id to new id,
 * replace all of the references to the old ids to the new ids.
 * This will also replace the id field and the keys in the map
 * from id to entity in the design state.
 *
 * The old and new ids should form disjoint sets. Removed entities
 * should map to null, but I don't think this
 * @param {*} designState
 * @param {*} oldToNewIdMap A map of type to old id to new id.
 */
export const transformWithNewIds = (designState, oldToNewIdMap) => {
  const newState = {};

  Object.entries(designState).forEach(([type, values]) => {
    const simpleRefsToTypeEntries = Object.entries(
      SIMPLE_REFERENCES_TO_TYPE[type] || {}
    );
    const oldToNewId = oldToNewIdMap[type] || emptyMap;

    const newValues = { ...values };

    Object.values(values).forEach(item => {
      const newItem = { ...item };
      simpleRefsToTypeEntries.forEach(([fieldName, referenceType]) => {
        const fieldValue = item[fieldName];
        const possibleNewId = get(oldToNewIdMap, [referenceType, fieldValue]);
        if (possibleNewId) {
          newItem[fieldName] = possibleNewId;
        }
      });

      if (oldToNewId[item.id]) delete newValues[item.id];
      newItem.id = oldToNewId[item.id] || item.id;
      newValues[newItem.id] = newItem;
    });

    newState[type] = newValues;
  });

  return newState;
};

/**
 * Similar to `transformWithNewIds`. I honestly don't know the difference
 * between the two functions or if there even is a difference.
 * @param {*} designState
 * @param {*} oldIdToNewItemIdMap A map of type to old id to new id.
 */
export const replaceReferences = (designState, oldIdToNewItemIdMap) => {
  const newState = {};
  Object.entries(designState).forEach(([type, values]) => {
    const simpleRefsToTypeEntries = Object.entries(
      SIMPLE_REFERENCES_TO_TYPE[type] || {}
    );
    const oldIdToNewItemId = oldIdToNewItemIdMap[type] || {};

    const newValues = { ...values };

    Object.values(values).forEach(item => {
      if (oldIdToNewItemId[item.id]) {
        delete newValues[item.id];
        return;
      }

      const newItem = { ...item };
      simpleRefsToTypeEntries.forEach(([fieldName, referenceType]) => {
        const fieldValue = item[fieldName];
        const possibleNewItemId = get(oldIdToNewItemIdMap, [
          referenceType,
          fieldValue
        ]);
        if (possibleNewItemId) {
          newItem[fieldName] = possibleNewItemId;
        }
      });
      newValues[item.id] = newItem;
    });

    newState[type] = newValues;
  });

  return newState;
};

export const createForeignReferenceIndices = designState => {
  const fakeFullState = { design: designState };
  const simpleReferencesMap = createObjectValuedTypeMap();
  for (const [type, values] of Object.entries(FOREIGN_REFERENCES)) {
    for (const [key, { foreignType, foreignKey }] of Object.entries(values)) {
      simpleReferencesMap[type][key] = getMultiValuedIndex(
        fakeFullState,
        foreignType,
        foreignKey
      );
    }
  }
  return simpleReferencesMap;
};

/**
 * If an item has a foreign key that references another item which isn't in the state
 * this sets the foreign key to be null.
 *
 * @param {Object} designState
 */
export function removeDanglingPointers(designState) {
  const newState = createObjectValuedTypeMap();

  Object.entries(designState).forEach(([type, values]) => {
    const simpleRefsToTypeEntries = Object.entries(
      SIMPLE_REFERENCES_TO_TYPE[type] || {}
    );

    Object.values(values).forEach(item => {
      const newItem = { ...item };

      simpleRefsToTypeEntries.forEach(([fieldName, referenceType]) => {
        const refId = item[fieldName];
        if (refId && !designState[referenceType][refId]) {
          newItem[fieldName] = null;
        }
      });
      newState[type][item.id] = newItem;
    });
  });

  return newState;
}

/**
 * Perform a garbage collection of the design state. Remove entities that aren't accessible
 * via a traversal from the root card. The parameters of the traversal are given by the
 * `SIMPLE_REFERENCES_TO_TYPE` and `FOREIGN_REFERENCES` constants from designStateContants.
 *
 * @param {Object} designState
 * @param {Array<String>} typesToKeep All items of these types won't be garbage collected.
 */
export const removeInaccessibleItems = (designState, typesToKeep = []) => {
  const newState = {
    ...createObjectValuedTypeMap(),
    ...pick(designState, typesToKeep)
  };

  const visited = createObjectValuedTypeMap();
  const foreignReferenceIndices = createForeignReferenceIndices(designState);

  // Uncomment out the commented out code for help with debugging.
  // let depth = 0
  const traverseItem = (type, item) => {
    if (!item) return; // Maybe some better handling here, possible changed by flag.
    if (visited[type][item.id]) return;

    // console.log(require('lodash').times(depth++, () => ' ').join(''), type, item.id)

    if (type === "element") {
      // remove elements if their bin is gone
      if (!designState.bin[item.binId]) {
        return;
      }
    } else if (type === "fas") {
      // remove fas if their element is gone
      if (!designState.element[item.elementId]) {
        return;
      }
    }

    newState[type][item.id] = item;
    visited[type][item.id] = true;

    for (const referenceKey of Object.keys(
      SIMPLE_REFERENCES_TO_TYPE[type] || {}
    )) {
      const foreignType = SIMPLE_REFERENCES_TO_TYPE[type][referenceKey];
      const referenceId = item[referenceKey];
      if (referenceId) {
        traverseItem(foreignType, designState[foreignType][referenceId]);
      }
    }

    for (const referenceKey of Object.keys(foreignReferenceIndices[type])) {
      const { foreignType } = FOREIGN_REFERENCES[type][referenceKey];
      const referencedValues =
        foreignReferenceIndices[type][referenceKey][item.id] || [];
      for (const referencedItem of referencedValues) {
        traverseItem(foreignType, referencedItem);
      }
    }
    // depth--
  };

  const rootCard = Object.values(designState.card).find(c => c.isRoot);
  traverseItem("card", rootCard);
  return newState;
};

export const removeMaterialAvailabilityInformation = designState => {
  const newState = {
    ...designState,
    set: {}
  };

  for (const card of Object.values(designState.card)) {
    newState.card[card.id] = {
      ...card,
      allConstructsAvailable: null,
      lastCheckedAvailability: null,
      hasAvailabilityInfo: null
    };
  }

  return newState;
};

/**
 * Given an old and new state, get the changes needed to go from the old
 * state to the new state.
 *
 * All of the creates and updates will be as they are in the state. This means
 * that the results of this function cannot be used directly as input to mutations.
 *
 * This also means that items with database ids can be in the creates and items
 * with client generated ids can be in the deletes.
 *
 * @param {Object} oldState
 * @param {Object} newState
 * @param {Object | undefined} typesToCheck A set of types to check. If none is provided, then every type will be checked.
 */
export function diffDesignStates(oldState, newState, typesToCheck) {
  const updates = createObjectValuedTypeMap();

  // Note, these might not actually represent creates in the database (for example parts).
  // They reflect things that need to be added to the design state.
  const creates = createObjectValuedTypeMap();
  const deletes = createArrayValuedTypeMap();

  // If no types to check are applied specifically, then check everything.
  if (!typesToCheck) typesToCheck = createArrayValuedTypeMap();

  for (const type of Object.keys(typesToCheck)) {
    // This 'updateOnlyType' means that only if the type is under the 'updates' diff
    // then it will be considered, otherwise it will skip any creates or deletes for that type.
    // NOTE: related to the comment in TYPES_TO_CHECK at 'saveDesignConstants.js'.
    const updateOnlyType = !typesToCheck[type];
    const oldObjects = oldState[type];
    const newObjects = newState[type];

    // Creates and updates.
    for (const [id, newValues] of Object.entries(newObjects)) {
      const oldValues = oldObjects[id];

      // If we can update an existing object.
      if (oldValues) {
        const update = { id };
        for (const fieldName in newValues) {
          if (oldValues[fieldName] === newValues[fieldName]) continue;
          update[fieldName] = newValues[fieldName];
        }
        // If there is anything to update.
        if (Object.keys(update).length > 1) updates[type][id] = update;
      } else if (!updateOnlyType) {
        // Otherwise we have to create a new object.
        creates[type][id] = { ...newValues };
      }
    }

    // Deletes.
    for (const id in oldObjects) {
      if (!newObjects[id] && !updateOnlyType) deletes[type].push(id);
    }
  }

  return { updates, creates, deletes };
}

/**
 * Given a state and a diff, patch the object to reflect the diff.
 * @param {Object} state
 * @param {Object} diff Object with keys create, delete, and update.
 */
export function patchDesignState(state, diff) {
  const { creates, updates, deletes } = diff;

  const newState = {};
  for (const type of Object.keys(state)) {
    const newItems = {};
    for (const create of Object.values(creates[type] || {})) {
      newItems[create.id] = {
        ...create
      };
    }
    const deletesOfType = keyBy(deletes[type]);

    for (const item of Object.values(state[type])) {
      if (deletesOfType[item.id]) continue;

      newItems[item.id] = {
        ...item,
        ...(updates[type][item.id] || {})
      };
    }
    newState[type] = newItems;
  }

  return newState;
}

/**
 * Given the deletes section of a diff and a mapping from old ids to new
 * ids, transform the diffs to reflect the new ids.
 */
function transformDeletesWithNewIds(deletes, oldIdToNewItemId) {
  const newDeletes = {};
  for (const type of Object.keys(deletes)) {
    newDeletes[type] = deletes[type]
      .filter(id => oldIdToNewItemId[type][id])
      .map(id => oldIdToNewItemId[type][id]);
  }
  return newDeletes;
}

/**
 * Given a diff generated from a design and a map from old ids to new ids,
 * transform the objects and their references in the diff to reflect the new
 * ids.
 *
 * @param {Object} diff Object with keys create, delete, and update.
 * @param {Object} oldToNewIdMap Map from type to old id to new id.
 */
export function transformDiffWithNewIds(diff, oldToNewIdMap) {
  return {
    creates: transformWithNewIds(diff.creates, oldToNewIdMap),
    updates: transformWithNewIds(diff.updates, oldToNewIdMap),
    deletes: transformDeletesWithNewIds(diff.deletes, oldToNewIdMap)
  };
}

/**
 * This function creates an 'oldIdsToNewIds' map from the design state 'diff' object.
 * This might be useful when design state mutations are done outside the Design Editor,
 * for instance, via an API or Integration Hook that might update the design state directly
 * via database mutations.
 *
 * NOTE: This was needed for the DESIGN_PART subtype UPDATE hook, which runs mutations on certain
 * design state entities.
 *
 * @param {Object} diff Object with keys creates, deletes, and updates.
 * @returns {Object} oldToNewIdMap Map from type to old id to new id.
 */
export function getOldIdToNewIdMapFromDiff(diff) {
  const {
    // creates,
    deletes
  } = diff;
  const oldIdsToNewIds = createObjectValuedTypeMap();

  Object.entries(deletes).forEach(([model, ids]) => {
    ids.forEach(id => {
      oldIdsToNewIds[model][id] = null;
    });
  });

  // Right now, this is not doing anything since 'create diffs' values come with no 'cid' field,
  // which will need to be queried, not sure about the best way to get
  // NOTE: The 'fas' design state type (entitie) cannot be queried due to pluralization issues with graphql.
  // Object.entries(creates).forEach(([model, values]) => {
  //   Object.values(values).forEach(({ id, cid }) => {
  //     if (!cid) return;
  //     oldIdsToNewIds[model][cid] = id;
  //   });
  // });

  return oldIdsToNewIds;
}
