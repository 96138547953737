/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import withQuery from "../../withQuery";

export const dnaSequenceTypeCodes = ["CIRCULAR_DNA", "LINEAR_DNA", "OLIGO"];

const fragment = gql`
  fragment withDNASequenceTypesFragment on sequenceType {
    code
    name
    description
    isDoubleStranded
    isCircular
  }
`;

export default withQuery(fragment, {
  options: {
    fetchPolicy: "cache-first",
    variables: {
      filter: {
        code: dnaSequenceTypeCodes
      }
    }
  },
  isPlural: true,
  showLoading: true,
  inDialog: true
});
