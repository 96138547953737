/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment reactionMapFragment on reactionMap {
    id
    name
    reactionType {
      code
      name
    }
    reactions {
      id
      name
      reactionInputs {
        id
        conserved
        inputMaterial {
          id
          name
        }
        inputAdditiveMaterial {
          id
          name
        }
      }
      reactionOutputs {
        id
        outputMaterial {
          id
          name
        }
        outputAdditiveMaterial {
          id
          name
        }
      }
    }
  }
`;
