/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types } from "mobx-state-tree";

export const newAssayStore = types
  .model("newAssayStore", {
    name: types.optional(types.string, ""),
    // description: types.optional(types.string, ""),
    assayStatusCode: types.optional(types.string, "0"),
    loading: types.optional(types.boolean, false)
  })
  .views(self => ({
    get assayToBeCreated() {
      return {
        name: self.name
      };
    }
  }))
  .actions(self => {
    return {
      setName(name) {
        self.name = name;
      }
      // setDescription(description) {
      //   self.description = description;
      // }
    };
  });
