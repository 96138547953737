/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const j5ReworkReportFragment = gql`
  fragment j5ReworkReportFragment on j5Report {
    id
    name
    assemblyMethod
    assemblyType
    createdAt
    updatedAt
    j5RunConstructs {
      id
      name
      sequenceId
      sequence {
        id
        name
      }
      j5ConstructAssemblyPieces {
        id
        assemblyPiece {
          id
          name
          type
          sequenceId
          j5AssemblyPieceParts {
            id
            j5InputPart {
              id
              partId
              j5InputSequence {
                id
                sequenceId
              }
            }
          }
        }
      }
    }
    j5PcrReactions {
      name
      id
      primaryTemplateId
      forwardPrimerId
      reversePrimerId
      pcrProductSequenceId
      oligoMeanTm
      oligoDeltaTm
      oligoMeanTm3Prime
      oligoDeltaTm3Prime
      primaryTemplate {
        id
        name
      }
      forwardPrimer {
        id
        name
      }
      reversePrimer {
        id
        name
      }
      pcrProductSequence {
        id
        name
      }
      pcrProductSequenceId
    }
  }
`;

export const j5ReworkDataTableFragment = gql`
  fragment j5ReworkDataTableFragment on dataTable {
    id
    name
    createdAt
    updatedAt
    dataSetId
    dataTableTypeCode
    dataRows {
      id
      rowValues
    }
    dataTableType {
      code
      name
      rowSchema
    }
  }
`;
