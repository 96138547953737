/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { wrapDialog } from "@teselagen/ui";
import J5LogMessagesView from "../J5LogMessagesView";
import "./style.css";

export default wrapDialog(props => {
  const { j5LogMessages } = props;
  let title;
  if (!j5LogMessages.length) title = "No errors or warnings";

  const hasError = j5LogMessages.some(m => m.j5LogMessageTypeCode === "ERROR");
  title = `${hasError ? "Errors" : "Warnings"} (${j5LogMessages.length})`;

  return {
    className: "j5-logs-dialog",
    title,
    style: {
      width: 650
    }
  };
})(J5LogMessagesView);
