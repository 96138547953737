/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isDiluent } from "../aliquotUtils";
import { convertVolumeBig } from "./volumeUtils";
import { convertCellConcentrationBig } from "./concentrationUtils";
import Big from "big.js";

/**
 * Get the number of cells of the aliquot's non-diluent content.
 * @param {object} aliquot
 * @returns {number} number of cells.
 */
export default function getAliquotNumberOfCells(aliquot) {
  if (aliquot.isDry) {
    return new Big(aliquot.cellCount);
  } else if (isDiluent(aliquot)) {
    return new Big(0);
  } else {
    if (!aliquot.cellConcentration) {
      return new Big(aliquot.cellCount || 0);
    }
    return convertVolumeBig(
      aliquot.volume,
      aliquot.volumetricUnitCode,
      "L"
    ).times(
      convertCellConcentrationBig(
        aliquot.cellConcentration,
        aliquot.cellConcentrationUnitCode,
        "cells/L"
      )
    );
  }
}
