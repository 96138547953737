/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { InputField, TextareaField } from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import FormFooter from "../../../../src-shared/components/FormFooter";
import { Card } from "@blueprintjs/core";

class SequenceAddViewForm extends React.Component {
  render() {
    const { handleSubmit, history, submitting } = this.props;

    return (
      <Card className="form-layout">
        <form
          onSubmit={handleSubmit(async values => {
            try {
              const [sequence] = await safeUpsert("aminoAcidSequence", {
                ...values,
                size: 0,
                labId: undefined
              });
              history.push("/amino-acid-sequences/" + sequence.id);
            } catch (error) {
              console.error("error:", error);
              window.toastr.error(
                `Error ${
                  values.id ? "updating" : "creating"
                } amino acid sequence.`
              );
            }
          })}
        >
          <h2 className="edit-view-title">Create New Amino Acid Sequence</h2>
          <InputField autoFocus isRequired name="name" label="Name" />
          <TextareaField name="description" label="Description" />
          <FormFooter submitting={submitting} history={history} />
        </form>
      </Card>
    );
  }
}

export default reduxForm({
  form: "newAASequenceForm",
  enableReinitialize: true
})(SequenceAddViewForm);
