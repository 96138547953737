/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const impersonatedUserIdHeaderKey = "tg-lims-user-id";
const activeLabHeaderKey = "tg-active-lab-id";
const activeProjectHeaderKey = "tg-project-id";
const activeBatchHeaderKey = "tg-active-batch-id";
const importCollectionHeaderKey = "tg-active-import-collection-id";
const authorizationHeaderKey = "authorization";

// Bearer prefix should always be capitalized (according to Hasura).
export const BEARER_PREFIX = "Bearer ";

export const COMMON_LAB_ID = "sys-common-lab";

export const headers = {
  impersonatedUserIdHeaderKey,
  activeLabHeaderKey,
  activeProjectHeaderKey,
  activeBatchHeaderKey,
  importCollectionHeaderKey,
  authorizationHeaderKey
};

export const SYSTEM_ROLE = "SYSTEM";
export const ADMIN_ROLE = "ADMIN";
export const MEMBER_ROLE = "MEMBER";
export const LAB_CREATOR_ROLE = "LAB_CREATOR";
export const LAB_ADMIN = "LAB_ADMIN";
export const PUBLISHER_ROLE = "PUBLISHER";

export const userRoleCodes = {
  SYSTEM_ROLE,
  ADMIN_ROLE,
  MEMBER_ROLE,
  LAB_CREATOR_ROLE,
  LAB_ADMIN,
  PUBLISHER_ROLE
};

export const labRoleCodes = {
  ADMIN_ROLE,
  MEMBER_ROLE
};

export const lastSelectedLabKey = "lastSelectedLabId";
export const labStorageKey = "activeLabId";

export const ALL_PROJECTS_CID = "sys-all-projects";
export const UNASSIGNED_PROJECT_ID = "unassigned";
