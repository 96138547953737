/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  getItemIdByCid,
  getOutputtingReactionOfCard,
  getBinIdsInCard
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import { getBreakpoints } from "../../../utils/addOperationUtils";
import { flattenTree } from "../../../../../tg-iso-design/utils/designStateUtils";
import recurseOnJson from "../../../../../tg-iso-design/utils/recurseOnJson";
import addJunctionsToReaction from "./addJunctionsToReaction";
import removeReaction from "./removeReaction";
import shortid from "shortid";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";

/**
 * Returns an array of arrays of bin ids. The i-th array will be the bins in the i-th
 * input card.
 * @param {Object} fullState
 * @param {string} cardId The id of the card that will be the output card of the reaction.
 * @param {Array<int>} breakPointIndices Array of indices of where to place splits.
 * @param {boolean} isOutputCircular Is the card with id `cardId` circular?
 */
function getInputBinIds(
  fullState,
  cardId,
  breakPointIndices,
  isOutputCircular
) {
  // If the output card is circular, then the breakpoints indexing ends up
  // ignoring the backbone bin. To get the correct breakpoints, we must increment
  // them by one.
  if (isOutputCircular) {
    breakPointIndices = breakPointIndices.map(ind => ind + 1);
  }

  const outputBinIds = getBinIdsInCard(fullState, cardId);

  const inputBinIds = [];

  // Figure out the bins for all of the "interior" input cards.
  for (let i = 0, ii = breakPointIndices.length - 1; i < ii; i++) {
    const startIndex = breakPointIndices[i];
    const endIndex = breakPointIndices[i + 1];
    inputBinIds.push(outputBinIds.slice(startIndex, endIndex));
  }

  // We still need to add the bins for either the first and last input
  // cards or the input card containing the backbone, depending on whether
  // the output is circular.
  const firstBinIds = outputBinIds.slice(0, breakPointIndices[0]);
  const lastBinIds = outputBinIds.slice(
    breakPointIndices[breakPointIndices.length - 1]
  );
  if (isOutputCircular) {
    inputBinIds.unshift(lastBinIds.concat(firstBinIds));
  } else {
    inputBinIds.unshift(firstBinIds);
    inputBinIds.push(lastBinIds);
  }

  return inputBinIds.filter(x => x.length);
}

export default (state, { payload }, fullState) => {
  const backboneIconId = getItemIdByCid(
    fullState,
    "icon",
    "ORIGIN_OF_REPLICATION"
  );

  const designId = Object.keys(fullState.design.design)[0];

  const reaction = getOutputtingReactionOfCard(fullState, payload.cardId);

  if (reaction) {
    reaction.assemblyMethod =
      fullState.design.assemblyMethod[reaction.assemblyMethodId];
    reaction.restrictionEnzyme =
      fullState.design.restrictionEnzyme[reaction.restrictionEnzymeId];
    reaction.customJ5Parameter =
      fullState.design.customJ5Parameter[reaction.customJ5ParameterId];
  }

  let {
    name,
    assemblyMethod: _assemblyMethod,
    outputNamingTemplates = {},
    customJ5Parameter,
    restrictionEnzyme,
    makeAllInputsCircular,
    breakPointIndices,
    isOutputCircular,
    cardId,
    addMockReaction,
    reactionIdToUse
  } = { ...reaction, ...payload }; //use the existing reaction as the default values and override them with anything in the payload

  let assemblyMethod = _assemblyMethod;
  if (addMockReaction) {
    const mockAssemblyId = getItemIdByCid(fullState, "assemblyMethod", "mock");
    assemblyMethod = {
      id: mockAssemblyId,
      cid: "mock"
    };
  }

  const changeSetsHelper = new ChangeSetsHelper(state);

  if (reaction) removeReaction(state, changeSetsHelper, reaction.id);

  breakPointIndices = getBreakpoints(breakPointIndices);

  if (isOutputCircular && breakPointIndices.length < 2) {
    throw new Error("Must have at least 2 breakpoints with circular outputs.");
  }
  if (!breakPointIndices.length) {
    throw new Error("Must have at least 1 breakpoint.");
  }

  const inputBinsInfo = getInputBinIds(
    fullState,
    cardId,
    breakPointIndices,
    isOutputCircular
  );

  // If we should make the inputs circular, then do it.
  if (makeAllInputsCircular) {
    inputBinsInfo.forEach((info, i) => {
      if (i === 0 && isOutputCircular) return;
      info.unshift({
        __typename: "bin",
        direction: true,
        name: "Backbone",
        iconId: backboneIconId
      });
    });
  }
  let customJ5ParameterId;
  if (addMockReaction) {
    customJ5ParameterId = shortid();
    changeSetsHelper.createPure("customJ5Parameter", {
      ...getDefaultParamsAsCustomJ5ParamName(),
      id: customJ5ParameterId,
      name: "default",
      isLocalToThisDesignId: designId
    });
  } else {
    customJ5ParameterId = customJ5Parameter.id;
  }
  const reactionId = reactionIdToUse || shortid();
  const reactionInput = {
    id: reactionId,
    name,
    __typename: "reaction",
    assemblyMethodId: assemblyMethod.id,
    customJ5ParameterId,
    restrictionEnzyme: restrictionEnzyme
      ? { __typename: "restrictionEnzyme", ...restrictionEnzyme }
      : null,
    reactionJ5OutputNamingTemplates: Object.keys(outputNamingTemplates).map(
      outputTarget => ({
        __typename: "reactionJ5OutputNamingTemplate",
        j5OutputNamingTemplate: {
          __typename: "j5OutputNamingTemplate",
          outputTarget,
          ...outputNamingTemplates[outputTarget]
        }
      })
    ),
    cards: inputBinsInfo.map((info, inputIndex) => ({
      inputIndex,
      __typename: "card",
      circular: !!makeAllInputsCircular,
      binCards: info.map((binInfo, index) => {
        if (typeof binInfo === "string") {
          return {
            __typename: "binCard",
            binId: binInfo,
            index
          };
        } else {
          return {
            __typename: "binCard",
            bin: binInfo,
            index
          };
        }
      })
    }))
  };

  // Add the ids.
  recurseOnJson(
    reactionInput,
    obj => {
      if (!obj.id) obj.id = shortid();
    },
    { callOnObjectsOnly: true }
  );

  const flattenedOperation = flattenTree(reactionInput, true);

  changeSetsHelper.updateViaFlatObject(flattenedOperation);
  changeSetsHelper.updatePure("card", {
    id: cardId,
    outputReactionId: reactionInput.id
  });

  return addJunctionsToReaction({
    changeSetsHelper,
    restrictionEnzyme,
    cardId,
    isOutputCircular,
    makeAllInputsCircular,
    assemblyMethod
  });
};
