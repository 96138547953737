/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import SelectInputs, {
  propagateSampleStatusDataTableFragment
} from "./Steps/PropagateSampleStatus";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";
import ReviewParentSamples from "./Steps/ReviewParentSamples";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

function SampleStatusPropagationTool(props) {
  const {
    initialValues,
    toolSchema,
    toolIntegrationProps,
    isToolIntegrated
  } = props;

  async function onSubmit(values) {
    try {
      const { baseLevelSamples, sampleIdToParentList } = values;
      const sampleUpdates = [];
      const alreadyUpdated = [];
      baseLevelSamples.forEach(sample => {
        const parentList = sampleIdToParentList[sample.id];
        if (parentList) {
          parentList.forEach(parent => {
            const status = sample.sampleStatusCode;
            if (
              !alreadyUpdated.includes(parent.id) &&
              ["VALID", "INVALID"].includes(status)
            ) {
              alreadyUpdated.push(parent.id);
              sampleUpdates.push({
                id: parent.id,
                sampleStatusCode: status
              });
            }
          });
        }
      });
      await safeUpsert("sample", sampleUpdates);
      return {};
    } catch (error) {
      console.error(error);
      window.toastr.error("Error propagating sample status");
    }
  }

  const steps = [
    {
      title: "Propagate Sample Status",
      Component: SelectInputs,
      withCustomFooter: true
    },
    {
      title: "Review Parent Samples",
      Component: ReviewParentSamples
    }
  ];

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      enableReinitialize={isToolIntegrated}
      initialValues={initialValues}
    />
  );
}

export default compose(
  withWorkflowInputs(propagateSampleStatusDataTableFragment)
)(SampleStatusPropagationTool);
