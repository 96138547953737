/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { identity, cloneDeep, keyBy, reduce, isEqual, map } from "lodash";
import { Promise } from "bluebird";
import pluralize from "pluralize";
import { tidyUpSequenceData } from "@teselagen/sequence-utils";
import {
  safeDelete,
  safeUpsert,
  safeQuery,
  deleteWithQuery
} from "../apolloMethods";
import { chunkSequenceToFragments } from "../../../tg-iso-shared/src/sequence-import-utils/utils";

import { updateEditor } from "@teselagen/ove";
import shortid from "shortid";
import { getDigestPartFields } from "../../../tg-iso-shared/utils/digestPartUtils";
import { digestPartFragment } from "../../../tg-iso-design/graphql/fragments/partFragment";
import { flatMap } from "lodash";
import { filter } from "lodash";

const updateSequenceEditorStore = newSequenceData =>
  updateEditor(
    window.teGlobalStore,
    "SequenceEditor",
    {
      sequenceData: newSequenceData
    },
    { disregardUndo: true }
  );

const areTagsEqual = (a1 = [], a2 = []) => {
  return isEqual([...a1].sort(), [...a2].sort());
};

let isSaving;
let isSavePending;
let retryTimeout;

export function waitForSaveToFinish() {
  return new Promise(resolve => {
    const clearId = setInterval(() => {
      if (!isSaving) {
        clearInterval(clearId);
        resolve();
      }
    }, 100);
  });
}

export async function handleSequenceSave({
  id,
  sequenceData,
  isCurrentlyBeingEdited,
  doNotShowSeqSaveMsgs
}) {
  try {
    clearTimeout(retryTimeout);
    //we should make sure that the seq isn't still saving..
    if (isSaving) {
      // if another sequence is already saving, we don't want that save to update
      // the ove sequence json with its returned vals
      // because that would mess up the seq data going into pending save
      // so we set the isSavePending variable to block that from happening
      isSavePending = true;
      retryTimeout = setTimeout(() => {
        handleSequenceSave({ id, sequenceData, isCurrentlyBeingEdited });
      }, 1000);
      return "zozozozoo";
    } else {
      isSavePending = false;
      isSaving = true;
      if (!doNotShowSeqSaveMsgs) {
        (window.oveMenuToastrSuccess || window.toastr.success)(
          "Sequence Saving...",
          { key: "saveMsg", loading: true }
        );
      }

      const newSequenceData = await handleSequenceSaveInner({
        sequenceId: id,
        lastSavedSequenceData: isCurrentlyBeingEdited
          ? window.__lastSavedSequenceData
          : { id },
        doNotShowSeqSaveMsgs,
        sequenceData: sequenceData
      });

      isSaving = false;
      if (isCurrentlyBeingEdited) {
        window.__lastSavedSequenceData = newSequenceData;
      }

      if (!isSavePending && isCurrentlyBeingEdited) {
        //if another save is already queued up, we don't want to update the sequenceEditorStore
        updateSequenceEditorStore(newSequenceData);
      }
    }
  } catch (e) {
    console.error(e);
    window.toastr.error("Error saving sequence.");
    isSaving = false;
  }
}

const areAnnotationsEqual = (a1, a2) =>
  a1.name === a2.name &&
  isEqual(a1.locations, a2.locations) &&
  a1.start === a2.start &&
  a1.end === a2.end &&
  isEqual(a1.notes, a2.notes) &&
  areTagsEqual(a1.tags, a2.tags) &&
  a1.strand === a2.strand &&
  a1.arrowheadType === a2.arrowheadType &&
  a1.overlapsSelf === a2.overlapsSelf &&
  a1.type === a2.type &&
  a1.oligoWithBindingSiteId === a2.oligoWithBindingSiteId;

const annotationToUpsertValue = {
  feature: annot => ({
    name: annot.name,
    start: annot.start,
    end: annot.end,
    arrowheadType:
      annot.arrowheadType === "BOTH" || annot.arrowheadType === "NONE"
        ? annot.arrowheadType
        : null,
    notes: annot.notes,
    locations: annot.locations,
    strand: annot.strand,
    type: annot.type,
    oligoWithBindingSiteId: annot.oligoWithBindingSiteId
  }),
  part: annot => ({
    taggedItems: (annot.tags || []).map((id = "") => {
      let tagId, tagOptionId;
      if (id.includes(":")) {
        [tagId, tagOptionId] = id.split(":");
      } else {
        tagId = id;
      }
      return {
        tagId,
        tagOptionId
      };
    }),
    name: annot.name,
    notes: annot.notes,
    start: annot.start,
    end: annot.end,
    overlapsSelf: annot.overlapsSelf,
    strand: annot.strand,
    type: annot.type,
    ...getDigestPartFields(annot)
  }),
  translation: annot => ({
    start: annot.start,
    end: annot.end,
    strand: annot.strand
  })
};
const proteinAnnotationToUpsertValue = {
  feature: annot => ({
    name: annot.name,
    notes: annot.notes,
    start: annot.start,
    end: annot.end,
    locations: annot.locations,
    type: annot.type
  }),
  part: annot => ({
    name: annot.name,
    notes: annot.notes,
    start: annot.start,
    end: annot.end,
    type: annot.type
  })
};

const updateAnnotations = async ({
  isProtein,
  sequenceId,
  lastSavedAnnotations,
  annotations,
  type
}) => {
  const dataType =
    type === "feature"
      ? isProtein
        ? "regionAnnotation"
        : "sequenceFeature"
      : isProtein
        ? "aminoAcidPart"
        : type;

  const toUpsertValue = isProtein
    ? proteinAnnotationToUpsertValue[type]
    : annotationToUpsertValue[type];
  lastSavedAnnotations = cloneDeep(Object.values(lastSavedAnnotations || {}));
  annotations = cloneDeep(Object.values(annotations || {}));

  // Map the ids to real ids.
  const idRegex = new RegExp(`^(?:${pluralize(type)})*(\\d+)$`);
  [...lastSavedAnnotations, ...annotations].forEach(annot => {
    const match = annot.id.match(idRegex);
    if (match) annot.id = match[1];
  });

  const lastSavedIdMap = keyBy(lastSavedAnnotations, "id");
  const idMap = keyBy(annotations, "id");

  const idsToDelete = lastSavedAnnotations
    .map(({ id }) => (idMap[id] ? null : id))
    .filter(identity);

  const cidToOldIdMap = {};
  const tagIds = [];
  const tagOptionIds = [];
  const upserts = map(annotations, annot => {
    const oldAnnot = lastSavedIdMap[annot.id];
    const toSpread = toUpsertValue(annot);
    if (!oldAnnot) {
      const cid = shortid();
      cidToOldIdMap[cid] = annot.id;
      return {
        cid,
        ...(isProtein ? { aminoAcidSequenceId: sequenceId } : { sequenceId }),
        ...toSpread
      };
    } else if (areAnnotationsEqual(oldAnnot, annot)) return null;
    else {
      return {
        id: annot.id,
        ...toSpread
      };
    }
  })
    .filter(identity)
    .map(a => {
      if (a.taggedItems) {
        a.taggedItems.forEach(ti => {
          if (ti.tagId) tagIds.push(ti.tagId);
          if (ti.tagOptionId) tagOptionIds.push(ti.tagOptionId);
        });
      }
      return a;
    });
  const tags = await safeQuery(["tag", "id"], {
    isPlural: true,
    variables: { filter: { id: tagIds } }
  });
  const tagOptions = await safeQuery(["tagOption", "id"], {
    isPlural: true,
    variables: { filter: { id: tagOptionIds } }
  });
  upserts.forEach(a => {
    if (a.taggedItems) {
      a.taggedItems = flatMap(a.taggedItems, ti => {
        if (ti?.tagId) {
          const tag = tags.find(t => t.id === ti.tagId);
          if (!tag) {
            return [];
          }
        } else {
          return [];
        }
        if (ti?.tagOptionId) {
          const tagOption = tagOptions.find(t => t.id === ti.tagOptionId);
          if (!tagOption) {
            return [];
          }
        }
        return ti;
      });
    }
  });

  const creates = upserts.filter(a => !a.id);
  const updates = upserts.filter(a => a.id);
  const oldIdToIdMap = {};
  if (idsToDelete.length) await safeDelete(dataType, idsToDelete);
  if (creates.length) {
    const results = await safeUpsert([dataType, "id cid"], creates);
    for (const { id, cid } of results) {
      oldIdToIdMap[cidToOldIdMap[cid]] = id;
    }
  }

  if (updates.length) {
    let taggedItemsToCreate = [];
    const removeTaggedItemsForIds = [];
    updates.forEach(update => {
      if (update.taggedItems) {
        //delete old part tags
        removeTaggedItemsForIds.push(update.id);
        //update with new part tags
        taggedItemsToCreate = taggedItemsToCreate.concat(
          (update.taggedItems || []).map(pt => ({
            ...pt,
            partId: update.id
          }))
        );
        delete update.taggedItems;
      }
    });
    if (removeTaggedItemsForIds.length) {
      await deleteWithQuery("taggedItem", {
        [dataType + "Id"]: removeTaggedItemsForIds
      });
    }
    try {
      await safeUpsert("taggedItem", taggedItemsToCreate);
    } catch (e) {
      console.error(`error upserting taggedItems:`, e);
    }

    await safeUpsert(dataType, updates);
  }

  // For parts, change the name of their associated elements if we
  // are changing their names.
  if (type === "part") {
    const partsWithChangedNames = annotations.filter(
      annot =>
        lastSavedIdMap[annot.id] && lastSavedIdMap[annot.id].name !== annot.name
    );

    const elementsToChange = await safeQuery(["element", "id partId"], {
      isPlural: true,
      variables: { filter: { partId: partsWithChangedNames.map(p => p.id) } }
    });

    if (elementsToChange.length)
      await safeUpsert(
        "element",
        elementsToChange.map(el => ({ id: el.id, name: idMap[el.partId].name }))
      );
  }
  return oldIdToIdMap;
};

const updateSequenceFragments = async (sequenceId, sequenceString) => {
  const sequenceFragments = chunkSequenceToFragments(sequenceString);

  // NOTE: To update the sequence fragments of the current sequence,
  // First delete any existing ones, and then create the new ones.
  const oldFragments = await safeQuery(["sequenceFragment", "id"], {
    isPlural: true,
    variables: { filter: { sequenceId } }
  });
  if (oldFragments.length)
    await safeDelete(
      "sequenceFragment",
      oldFragments.map(f => f.id)
    );
  if (sequenceFragments.length)
    await safeUpsert(
      "sequenceFragment",
      sequenceFragments.map(f => ({ ...f, sequenceId }))
    );
};

const updateSequenceData = (
  sequenceData,
  oldFeatureIdToNewId,
  oldPartIdToNewId,
  oldTranslationIdToNewId
) => {
  return tidyUpSequenceData(
    {
      ...sequenceData,
      features: map(sequenceData.features, f => ({
        ...f,
        id: oldFeatureIdToNewId[f.id] || f.id
      })),
      assemblyPieces: map(sequenceData.assemblyPieces, f => ({
        ...f,
        id: oldFeatureIdToNewId[f.id] || f.id
      })),
      lineageAnnotations: map(sequenceData.lineageAnnotations, f => ({
        ...f,
        id: oldFeatureIdToNewId[f.id] || f.id
      })),
      warnings: map(sequenceData.warnings, f => ({
        ...f,
        id: oldFeatureIdToNewId[f.id] || f.id
      })),
      parts: map(sequenceData.parts, p => ({
        ...p,
        id: oldPartIdToNewId[p.id] || p.id
      })),
      translations: map(sequenceData.translations, p => ({
        ...p,
        id: oldTranslationIdToNewId[p.id] || p.id
      })),
      primers: map(sequenceData.primers, p => ({
        ...p,
        id: oldFeatureIdToNewId[p.id] || p.id
      }))
    },
    {
      doNotRemoveInvalidChars: true,
      annotationsAsObjects: true
    }
  );
};

const SEQUENCE_TYPE_CODE_CAN_BE_CIRCULAR = {
  CIRCULAR_DNA: true,
  GENOME: true,
  ALIGNMENT_SEQ: true
};

const BASIC_SEQUENCE_TYPE_CODES = ["LINEAR_DNA", "CIRCULAR_DNA"];

// ** handleSequenceSaveInner **
const handleSequenceSaveInner = async ({
  sequenceId,
  lastSavedSequenceData,
  sequenceData,
  doNotShowSeqSaveMsgs
}) => {
  const { isProtein } = sequenceData;
  const prevSeq =
    lastSavedSequenceData[isProtein ? "proteinSequence" : "sequence"];
  const currSeq = sequenceData[isProtein ? "proteinSequence" : "sequence"];
  const isSeqDifferent = prevSeq !== currSeq;
  const isSeqAdd = (prevSeq?.length || 0) <= (currSeq?.length || 0);
  let sequenceTypeCode = isProtein ? "AA" : sequenceData.sequenceTypeCode;

  if (BASIC_SEQUENCE_TYPE_CODES.includes(sequenceTypeCode)) {
    sequenceTypeCode = sequenceData.circular ? "CIRCULAR_DNA" : "LINEAR_DNA";
  } else if (
    sequenceData.circular &&
    !SEQUENCE_TYPE_CODE_CAN_BE_CIRCULAR[sequenceTypeCode]
  ) {
    return window.toastr.error(
      `Sequence of type ${sequenceTypeCode} cannot be set to circular.`
    );
  }

  if (isSeqDifferent && !isProtein && isSeqAdd) {
    await updateSequenceFragments(sequenceId, sequenceData.sequence);
  }

  const oldFeatureIdToNewId = await updateAnnotations({
    isProtein,
    sequenceId,
    lastSavedAnnotations: [
      ...map(lastSavedSequenceData.features),
      //sequence features don't have lastSaved primers, warnings, assemblyPieces, lineageAnnotations
      ...map(lastSavedSequenceData.warnings),
      ...map(lastSavedSequenceData.assemblyPieces),
      ...map(lastSavedSequenceData.lineageAnnotations),
      ...map(lastSavedSequenceData.primers)
    ],
    annotations: {
      ...sequenceData.features,
      ...sequenceData.warnings,
      ...sequenceData.assemblyPieces,
      ...sequenceData.lineageAnnotations,
      ...reduce(
        sequenceData.primers,
        (acc, key) => {
          //this is to change the primer feature from "misc_feature" to primer
          acc[key.id] = {
            ...sequenceData.primers[key.id],
            type: "primer_bind"
          };
          return acc;
        },
        {}
      )
    },
    type: "feature"
  });

  if (window.Cypress?.addArbitrarySeqWait) {
    //add wait of 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));
  }
  const oldPartIdToNewId = await updateAnnotations({
    isProtein,
    sequenceId,
    lastSavedAnnotations: lastSavedSequenceData.parts || [],
    annotations: sequenceData.parts,
    type: "part"
  });
  const oldTranslationIdToNewId = await updateAnnotations({
    isProtein,
    sequenceId,
    lastSavedAnnotations: lastSavedSequenceData.translations || [],
    annotations: filter(
      sequenceData.translations || [],
      t =>
        t.translationType === "User Created" ||
        (!t.isOrf && t.translationType !== "CDS Feature")
    ),
    type: "translation"
  });

  if (isSeqDifferent && !isProtein && !isSeqAdd) {
    await updateSequenceFragments(sequenceId, sequenceData.sequence);
  }

  // NOTE: Something doesn't look completely right with the sequenceData object.
  // It is not completely up-to-date with the actual data in the database.

  // Looks like SQL auto-computed fields, are not getting tracked to the state,
  // which is something that digestParts rely on.
  // So we need to query any updated digestParts and update the sequenceData state.

  // This is a bit of a hack, but it works, Ill leave until we find what's wrong with the state.
  await Promise.map(
    Object.entries(sequenceData.parts).filter(([, part]) => part.isDigestPart),
    async ([partId, part]) => {
      const oldPart = (lastSavedSequenceData.parts || {})[partId];
      const arePartsEqual = !oldPart || areAnnotationsEqual(part, oldPart);
      if (!arePartsEqual) {
        // Running this query will trigger the tgSequenceEditor 'UNSAFE_componentWillReceiveProps' function
        const syncedPart = await safeQuery(digestPartFragment, {
          variables: { id: partId }
        });
        Object.assign(sequenceData.parts[partId], { ...part, ...syncedPart });
      }
    }
  );

  await safeUpsert(isProtein ? "aminoAcidSequence" : "sequence", {
    id: sequenceId,
    name: sequenceData.name,
    chromatogramData: sequenceData.chromatogramData,
    size: isProtein ? sequenceData.proteinSequence.length : sequenceData.size,
    description: sequenceData.description,
    ...(!isProtein && { sequenceTypeCode, circular: sequenceData.circular }),
    ...(isProtein &&
      isSeqDifferent && { proteinSequence: sequenceData.proteinSequence })
  });
  if (!doNotShowSeqSaveMsgs) {
    (window.oveMenuToastrSuccess || window.toastr.success)("Sequence Saved", {
      key: "saveMsg"
    });
  }

  const newSequenceData = updateSequenceData(
    sequenceData,
    oldFeatureIdToNewId,
    oldPartIdToNewId,
    oldTranslationIdToNewId
  );
  return newSequenceData;
};
