/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment plateMapGroupPreviewFragment on plateMapGroup {
    id
    name
    containerFormatCode
    plateMaps {
      id
      type
      name
      plateMapItems {
        id
        name
        rowPosition
        columnPosition
        volume
        volumetricUnitCode
        inventoryItem {
          id
          material {
            id
            name
          }
          sample {
            id
            name
          }
          lot {
            id
            name
          }
          additiveMaterial {
            id
            name
          }
        }
      }
    }
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
`;
