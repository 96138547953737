/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { connect } from "react-redux";
import { branch, compose, renderNothing } from "recompose";

const stopOutOfSyncReduxRender = selector =>
  compose(
    connect((state, props) => ({
      mustExist: selector(state, props)
    })),
    branch(props => !props.mustExist, renderNothing)
  );

export default stopOutOfSyncReduxRender;
