/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";

import { safeUpsert } from "../../../apolloMethods";

class EditImportCollection extends Component {
  onSubmit = async values => {
    try {
      const { hideModal } = this.props;
      await safeUpsert(["importCollection", "id name"], {
        id: values.id,
        name: values.name
      });
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating import collection.");
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField label="Name" name="name" isRequired />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Update Import Collection"
  }),
  reduxForm({
    form: "EditImportCollectionForm"
  })
)(EditImportCollection);
