/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  withSelectedEntities,
  RadioGroupField,
  wrapDialog
} from "@teselagen/ui";

import { withProps } from "recompose";
import TubesTable from "../../Dialogs/InspectAliquotContainerDialog/TubesTable";
import AliquotsTable from "../../Dialogs/InspectAliquotContainerDialog/AliquotsTable";
import { handleAssignment } from "../../Dialogs/InspectAliquotContainerDialog";

function AssignAliquotsOrTubesToPlateDialog(props) {
  const {
    aliquotContainers,
    choosingAliquots,
    containerArrayType,
    containerArrayId,
    hideModal,
    handleSubmit,
    selectedTubes = [],
    selectedAliquots = [],
    submitting
  } = props;

  const onSubmit = async ({ orderToAssign }) => {
    const sharedOptions = {
      aliquotContainers,
      orderToAssign,
      containerArrayId,
      hideModal
    };
    if (choosingAliquots) {
      await handleAssignment({
        ...sharedOptions,
        aliquotsToAssign: selectedAliquots
      });
    } else {
      await handleAssignment({
        ...sharedOptions,
        tubesToAssign: selectedTubes
      });
    }
  };

  let content;
  const multi = aliquotContainers.length > 1;
  if (choosingAliquots) {
    content = <AliquotsTable multi={multi} />;
  } else {
    content = (
      <TubesTable multi={multi} containerArrayType={containerArrayType} />
    );
  }
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {multi && (
          <RadioGroupField
            defaultValue="rowFirst"
            label="Assignment Order"
            inline
            options={[
              {
                label: "Row First",
                value: "rowFirst"
              },
              {
                label: "Column First",
                value: "columnFirst"
              }
            ]}
            name="orderToAssign"
          />
        )}
        {content}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        disabled={
          choosingAliquots ? !selectedAliquots.length : !selectedTubes.length
        }
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  withProps(props => {
    const { aliquotContainers = [] } = props;
    return {
      choosingAliquots: aliquotContainers[0].id
    };
  }),
  wrapDialog({
    getDialogProps: props => {
      let title;
      if (props.choosingAliquots) {
        title = "Assign Aliquots";
      } else {
        title = "Assign Tubes";
      }
      return {
        style: {
          width: 650
        },
        title
      };
    }
  }),
  withSelectedEntities({
    formName: "aliquotsDisplayTable",
    name: "selectedAliquots"
  }),
  withSelectedEntities({
    formName: "tubesDisplayTable",
    name: "selectedTubes"
  }),
  reduxForm({
    form: "assignAliquotsOrTubeDialogForm"
  })
)(AssignAliquotsOrTubesToPlateDialog);
