/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem, MenuDivider, Menu } from "@blueprintjs/core";
import defaultAsyncWrap from "../../../src-shared/utils/defaultAsyncWrap";
import schematicFromDesign from "../../utils/schematicFromDesign";
import designToJson from "../../../src-shared/redux/sagas/exportDesign/designToJson";
import designToCsvGenbank from "../../../src-shared/redux/sagas/exportDesign/designToCsvGenbank";
import { showDialog } from "../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import libraryEnhancer from "../../../src-shared/libraryEnhancer";
import { DesignApprovalStatusIcon } from "../DesignApprovalStatusIcon";
import { InfoHelper } from "@teselagen/ui";
import AbstractLibrary from "../../../src-shared/AbstractLibrary";
import withQuery from "../../../src-shared/withQuery";
import { compose } from "recompose";
import { getCreateExampleDesignMenuItems } from "../HierarchicalDesign/commands";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import UploadDesigns from "../UploadDesigns";
import DesignRecordView from "../../../src-shared/DesignRecordView";
import { designLibraryFilter } from "../../utils/designUtils";
// import { useErrorBoundary } from "react-error-boundary";

const DesignLibrary = props => {
  const { refetchDesigns, history, stages, refetch, tableParams } = props;
  // const { showBoundary } = useErrorBoundary();

  const handleSubmitForReviewClick = selectedRecords => {
    showDialog({
      modalType: "SubmitDesignForReviewDialog",
      modalProps: {
        design: selectedRecords[0],
        refetch: refetchDesigns
      }
    });
  };

  const handleDuplicateClick = selectedRecords => {
    showDialog({
      modalType: "DUPLICATE_DESIGN",
      modalProps: {
        designs: selectedRecords,
        refetch: refetchDesigns
      }
    });
  };

  const additionalContextMenu = selectedRecords => {
    const menuItems = [];
    if (selectedRecords.length === 1) {
      const duplicateMenuItem = (
        <MenuItem
          key="duplicate"
          icon="duplicate"
          text="Duplicate"
          onClick={() => handleDuplicateClick(selectedRecords)}
        />
      );
      menuItems.push(duplicateMenuItem);

      menuItems.push(<MenuDivider key="md1" />);

      menuItems.push(
        <MenuItem
          key="createSchematicFromDesign"
          icon="add"
          text="Create Schematic from Design"
          onClick={defaultAsyncWrap(async () => {
            const designId = selectedRecords[0].id;
            const diagramId = await schematicFromDesign(designId);
            history.push(`/schematics/${diagramId}`);
          }, "Error creating schematic from design.")}
        />
      );

      menuItems.push(
        <MenuItem
          key="submitForReview"
          icon="send-message"
          text="Submit For Review"
          onClick={() => handleSubmitForReviewClick(selectedRecords)}
        />
      );
    }
    menuItems.push(
      <MenuDivider key="md2" />,
      <MenuItem key="export" text="Export" icon="export">
        <MenuItem
          key="exportAsJson"
          text="Export as JSON"
          onClick={() => {
            window.toastr.success("Download is starting");
            designToJson(
              selectedRecords.length > 1 ? selectedRecords : selectedRecords[0]
            );
          }}
        />
        <MenuItem
          key="exportAsJson"
          text="Export as CSV + Genbank"
          onClick={() => {
            window.toastr.success("Download is starting");
            designToCsvGenbank(
              selectedRecords.length > 1 ? selectedRecords : selectedRecords[0]
            );
          }}
        />
      </MenuItem>
    );
    return menuItems;
  };

  return (
    <>
      {/* For Testing async errors */}
      {/* <button
        onClick={() => {
          try {
            throw new Error("BUEN ERROR TE MANDASTE COMPADRE");
          } catch (error) {
            showBoundary(error);
          }
        }}
      >
        ERROR
      </button> */}
      {/*  */}
      <AbstractLibrary
        {...props}
        RecordViewInspector={DesignRecordView}
        generateExtraContextMenuItems={additionalContextMenu}
        // ViewerComponent={ViewerComponent}
        uploadMenuItems={[
          <MenuItem
            key="uploadDesign"
            text="Upload Designs"
            onClick={() => {
              showDialog({
                ModalComponent: UploadDesigns,
                modalProps: {
                  refetch
                }
              });
            }}
          />
        ]}
        newItemMenu={
          <Menu>
            <MenuItem
              text="Blank Design"
              onClick={() => {
                history.push(`${modelNameToLink("design")}/new`);
              }}
            />
            <MenuItem text="Load Example Design">
              {getCreateExampleDesignMenuItems({ history })}
            </MenuItem>
            {!window.frontEndConfig.disabledToolkits
              .advancedMolecularBiology && (
              <MenuItem
                onClick={() => showDialog({ modalType: "PARALLEL_PART" })}
                text={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    New DAPPER Design &nbsp; &nbsp;
                    <InfoHelper
                      content={
                        <p>
                          <strong>
                            Design from an Alignment of Parallel Parts that
                            Expose Regions of diversity:
                          </strong>
                          <br />
                          This tool will create a new list layout design based
                          on a file upload of aligned same-size sequences that
                          are nearly identical with a few regions of diversity.
                        </p>
                      }
                    />
                  </div>
                }
              />
            )}
            <MenuItem
              text={
                <div style={{ display: "flex", alignItems: "center" }}>
                  New Design from Partition &nbsp; &nbsp;
                  <InfoHelper
                    content={
                      <p>
                        <strong>Create a design from Partition</strong>
                        <br />
                        This tool will create a new design based on an existing
                        part sequence. Each sub-part will be created
                        automatically by the parition tool.
                      </p>
                    }
                  />
                </div>
              }
              onClick={() => {
                history.push("/tools/create-design-from-partition-tool");
              }}
            />
          </Menu>
        }
        extraTableParams={{
          stages,
          refetch: tableParams.onRefresh
        }}
        withExport
        isLibraryTable
        model="design"
      />
    </>
  );
};

const schema = {
  model: "design",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name",
      render: (
        name,
        { id, isLocked, lockedMessage, lockMsgDescription, activeSubmission },
        b,
        { stages, refetch }
      ) => {
        return (
          <>
            {stages && stages.length ? (
              <DesignApprovalStatusIcon
                {...{
                  id,
                  activeSubmission,
                  selectedSubmissionId: activeSubmission && activeSubmission.id,
                  showGoToDesign: true,
                  refetch
                }}
              />
            ) : null}
            {!!isLocked && (
              <InfoHelper
                isInline
                color="darkgrey"
                icon="lock"
                content={
                  <div>
                    {lockedMessage}{" "}
                    <div style={{ fontSize: 11, fontStyle: "italic" }}>
                      {lockMsgDescription}
                    </div>
                  </div>
                }
              />
            )}{" "}
            {name}{" "}
          </>
        );
      }
    },
    {
      path: "designReactions.assemblyMethod.name",
      type: "string",
      displayName: "Assembly Method(s)",
      render: (v, r) => {
        const assemblyMethods = [];
        r.designReactions.forEach(d => {
          if (
            d.assemblyMethod &&
            !assemblyMethods.includes(d.assemblyMethod.name)
          ) {
            assemblyMethods.push(d.assemblyMethod.name);
          }
        });
        return assemblyMethods.join(", ");
      }
    }
  ]
};

const fragment = gql`
  fragment designLibraryFragment on design {
    id
    name
    isLocked
    lockedMessage
    lockMsgDescription
    type
    designReactions {
      id
      assemblyMethod {
        id
        name
      }
    }
    activeSubmission {
      id
      submissionStateCode
    }
    description
  }
`;

export default compose(
  withQuery(["stage", `id name`], {
    isPlural: true
  }),
  libraryEnhancer({
    schema,
    fragment,
    withSelectedEntities: true,
    additionalFilter: (props, qb) => {
      designLibraryFilter(qb);
    }
  })
)(DesignLibrary);
