/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import upsertReactionJsons from "../src/utils/upsertReactionJsons";
import { isoContext } from "@teselagen/utils";
import isoReadFileAsText from "./isoReadFileAsText";

export default async function handleReactionMapJson(
  reactionMapFiles,
  { labId, reactionDesign = false } = {},
  ctx = isoContext
) {
  const reactionJsonFiles = reactionMapFiles.filter(({ name, originalname }) =>
    (originalname || name).endsWith(".json")
  );
  if (!reactionJsonFiles.length) {
    throw new Error("No .reaction.json files found.");
  }
  const parsedJsons = [];
  let importCollectionName = "Reaction Map Upload";
  if (reactionJsonFiles.length === 1) {
    importCollectionName = reactionJsonFiles[0].name || importCollectionName;
  }
  for (const file of reactionJsonFiles) {
    try {
      const jsonString = await isoReadFileAsText(
        file.originalFileObj || file,
        "utf8"
      );
      parsedJsons.push(JSON.parse(jsonString));
    } catch (err) {
      console.error("err:", err);
      throw new Error("Invalid JSON");
    }
  }
  if (reactionDesign) {
    for (const json of parsedJsons) {
      if (!json.reactionMaps?.length) {
        throw new Error(
          "All JSON files must have reactionMaps key if uploading reaction designs (see example file)."
        );
      }
    }
  }
  ctx.setActiveLab?.(labId);
  await ctx.startImportCollection?.(importCollectionName);
  return await upsertReactionJsons(parsedJsons, {}, ctx);
}
