/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  wrapDialog,
  FileUploadField,
  BlueprintError
} from "@teselagen/ui";

import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import TagField from "../../../../src-shared/TagField";
import {
  parseWorklistCsvData,
  worklistCsvFields
} from "../../../../../tg-iso-lims/src/utils/worklistUtils";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

function UploadWorklistsDialog(props) {
  const { hideModal, handleSubmit, submitting, error, refetch } = props;

  async function onSubmit(values) {
    try {
      const parsedCsvs = [];
      for (const file of values.worklistFiles) {
        parsedCsvs.push({
          data: file.parsedData,
          name: file.name
        });
      }
      const { error, worklists } = await parseWorklistCsvData(parsedCsvs);
      if (error) {
        throw new Error(error);
      }
      await safeUpsert(
        "worklist",
        addTaggedItemsBeforeCreate(worklists, values.tags)
      );
      await refetch();
      hideModal();
    } catch (error) {
      throwFormError(error.message || "Error uploading worklists");
      console.error(`error:`, error);
      window.toastr.error("Error uploading worklists");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          isRequired
          accept={[
            getDownloadTemplateFileHelpers({
              fileName: "worklistName",
              validateAgainstSchema: {
                fields: worklistCsvFields
              }
            })
          ]}
          label="Upload Worklist Files"
          name="worklistFiles"
        />
        <TagField />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Upload Worklists"
  }),
  reduxForm({
    form: "uploadWorklists"
  })
)(UploadWorklistsDialog);
