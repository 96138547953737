/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import hgql from "graphql-tag";
import { InputField } from "@teselagen/ui";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import {
  annotationSizeStartEndColumns,
  dateModifiedColumn
} from "../../../../../src-shared/utils/libraryColumns";
import { useTgQuery } from "../../../../../src-shared/apolloMethods";

const SelectParametersInner = ({ microbialMaterial }) => {
  const { materials = [] } = useTgQuery(
    hgql`
      query materials {
        materials(
          where: {
            _and: {
              materialTypeCode: { _eq: "MICROBIAL" }
              genome: {
                _and: {
                  primaryReferenceSequenceId: { _is_null: false }
                  genomeGenomicRegions_aggregate: {
                    count: { predicate: { _gt: 0 } }
                  }
                }
              }
            }
          }
        ) {
          nodes {
            id
            name
            updatedAt
            genome {
              id
              name
              genomeGenomicRegions(
                where: {
                  _and: {
                    genomicRegion: {
                      sequenceFeatures: { type: { _eq: "CDS" } }
                      sequenceFeatures_aggregate: {
                        count: { predicate: { _gt: 0 } }
                      }
                    }
                  }
                }
              ) {
                id
                genomicRegion {
                  id
                  name
                  size
                  fullSequence
                  updatedAt
                  sequenceFeatures(where: { type: { _eq: "CDS" } }) {
                    id
                    name
                    start
                    end
                    updatedAt
                  }
                }
              }
              primaryReferenceSequence {
                id
                name
                referenceSequenceFiles {
                  id
                  name
                  fastaFileUrl
                }
              }
            }
          }
        }
      }
    `,
    {
      useHasura: true
    }
  );

  const availableFeatures = useMemo(() => {
    return (
      microbialMaterial?.genome.genomeGenomicRegions
        .map(({ genomicRegion }) =>
          genomicRegion.sequenceFeatures.map(feature => ({
            ...feature,
            genomicRegion
          }))
        )
        .flat() || []
    );
  }, [microbialMaterial?.genome.genomeGenomicRegions]);

  return (
    <>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Task name"
            helper="Provide a task name. Created gRNAs are going to use as a alias."
          />
          <InputField
            label="Name"
            name="name"
            isRequired
            placeholder="Enter the name of the task."
            style={{ minWidth: 450, marginRight: 20 }}
          />
        </div>
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Microbial Material"
          helper={`Microbial Material needs to be related to a genome which is
        linked to a reference sequence and contains at least one genomic region
        with a feature.`}
        />
        <GenericSelect
          name="microbialMaterial"
          schema={{
            model: "microbialMaterial",
            fields: [
              "name",
              {
                displayName: "Genome",
                path: "genome.name"
              },
              {
                displayName: "Reference Sequence",
                path: "genome.primaryReferenceSequence.name"
              },
              dateModifiedColumn
            ]
          }}
          entities={materials}
          isLocalCall
          isRequired
          postSelectDTProps={{
            formName: "selectedMicrobialMaterial",
            schema: [
              "name",
              {
                displayName: "Genome",
                path: "genome.name"
              },
              {
                displayName: "Reference Sequence",
                path: "genome.primaryReferenceSequence.name"
              },
              dateModifiedColumn
            ]
          }}
        />
      </div>
      {microbialMaterial && (
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Sequence Features"
            helper="Select the sequence feature where you want the gRNA to bind."
          />
          <GenericSelect
            name="feature"
            isRequired
            isLocalCall
            schema={{
              model: "feature",
              fields: [
                "name",
                ...annotationSizeStartEndColumns,
                { displayName: "Genomic Region", path: "genomicRegion.name" },
                dateModifiedColumn
              ]
            }}
            buttonProps={{ disabled: !microbialMaterial }}
            entities={availableFeatures}
            postSelectDTProps={{
              formName: "selectedFeature",
              schema: [
                "name",
                ...annotationSizeStartEndColumns,
                { displayName: "Genomic Region", path: "genomicRegion.name" },
                dateModifiedColumn
              ]
            }}
          />
        </div>
      )}
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Cas Enzyme"
          helper="Select Cas Enzyme that is going to be used with the gRNA."
        />
        <GenericSelect
          name="casEnzyme"
          isRequired
          schema={[
            "name",
            {
              path: "scaffoldSequence.sequence",
              displayName: "Scaffold Sequence"
            },
            {
              displayName: "PAM Sequence",
              path: "casEnzymePamSites.pamSite.sequence",
              render: (v, r) => {
                return r.casEnzymePamSites
                  .map(f => {
                    return f.pamSite.sequence;
                  })
                  .join(", ");
              }
            },
            {
              displayName: "PAM Strand",
              path: "casEnzymePamSites.pamSite.strand",
              render: (v, r) => {
                return r.casEnzymePamSites
                  .map(f => {
                    return f.pamSite.strand === 1 ? "Positive" : "Negative";
                  })
                  .join(", ");
              }
            },
            dateModifiedColumn
          ]}
          fragment={[
            "enzyme",
            "id name updatedAt scaffoldSequence { id, sequence } casEnzymePamSites { id pamSite { id sequence strand }}"
          ]}
          tableParamOptions={{
            additionalFilter: {
              reagentEnzymeTypeCode: "CAS"
            }
          }}
          postSelectDTProps={{
            formName: "selectedCasEnzyme",
            schema: [
              "name",
              {
                path: "scaffoldSequence.sequence",
                displayName: "Scaffold Sequence"
              },
              {
                displayName: "PAM Site",
                path: "casEnzymePamSites.pamSite.sequence",
                render: (v, r) => {
                  return r.casEnzymePamSites
                    .map(f => {
                      return f.pamSite.sequence;
                    })
                    .join(", ");
                }
              },
              {
                displayName: "PAM Strand",
                path: "casEnzymePamSites.pamSite.strand",
                render: (v, r) => {
                  return r.casEnzymePamSites
                    .map(f => {
                      return f.pamSite.strand === 1 ? "Positive" : "Negative";
                    })
                    .join(", ");
                }
              },
              dateModifiedColumn
            ]
          }}
        />
      </div>
    </>
  );
};

const SelectParameters = stepFormValues(
  "microbialMaterial",
  "feature",
  "name"
)(SelectParametersInner);

export { SelectParameters };
