/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { getExternalReferenceColumns } from "./getExternalReferenceColumns";

export function getExternalReferenceColumnsWithRender({ prefix }) {
  return getExternalReferenceColumns({ prefix }).map(f => {
    return {
      ...f,
      render: v => {
        if (f.fieldKey === "externalReferenceUrl") {
          let url = v;
          if (url && !url.includes("http")) {
            url = "//" + url;
          }
          return (
            <a
              key="extUrl"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {v}
            </a>
          );
        }
        return v;
      }
    };
  });
}
