/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getDesign } from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import CommentCard from "../../../../../src-shared/CommentCard";
import CurrentUserContext from "../../../../../src-shared/context/CurrentUserContext";

const CommentInspectorPanelContainer = ({ design }) => {
  const { currentUser = {} } = useContext(CurrentUserContext);

  return (
    <div className="bp3-card">
      <h5 className="inspector-panel-header">Comments</h5>
      <CommentCard
        isDisabled={design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"}
        noCard
        currentUser={currentUser}
        // __typename it's needed in CommentCard module
        record={{ ...design, __typename: "design" }}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    design: getDesign(state)
  };
};

export default compose(connect(mapStateToProps))(
  CommentInspectorPanelContainer
);
