/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
module.exports = [
  "additiveMaterial",
  "aliquot",
  "aliquotContainer",
  "plateMapGroup",
  "aminoAcidSequence",
  "containerArray",
  "dataTable",
  "dataSet",
  "worklist",
  "reactionMap",
  "equipmentItem",
  "functionalProteinUnit",
  "j5Report",
  "lot",
  "material",
  "sample",
  "sequence",
  "strain"
];
