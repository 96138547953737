/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get } from "lodash";

export const isIconRecognitionSite = icon =>
  icon.cid === "TYPE-IIS-RECOGNITION-SITE";

// Generic depth-first traversal. This function will traverse ALL nodes,
// i.e. it will traverse operation-injected sets regardless of the view options (which are
// not even a parameter here). The function `fn` will be called on every node.
export const dftAll = (node, childrenKey, fn) => {
  fn(node);
  (get(node, childrenKey) || []).forEach(child =>
    dftAll(child, childrenKey, fn)
  );
};
