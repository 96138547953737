/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import { Dialog, Classes } from "@blueprintjs/core";
import { DialogFooter, NumericInputField } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import actions from "../../../../src-shared/redux/actions";

class EditElementIndexInBin extends React.Component {
  onSubmit = async values => {
    try {
      const {
        hideModal,
        changeElementIndex,
        elementsInBin,
        selectedElement
      } = this.props;

      const oldIndex = selectedElement.index;
      const newIndex = values.index - 1;

      const sortedElements = elementsInBin.sort((a, b) => a.index - b.index);
      let slicedElements;

      if (newIndex < oldIndex) {
        //get newIndex to currentIndex elements into array
        slicedElements = sortedElements.slice(newIndex, oldIndex + 1);
        //change currentIndex element to newIndex number
        slicedElements[slicedElements.length - 1].index =
          slicedElements[0].index;
        //increment rest of element indexs in array by 1
        for (let i = 0; i < slicedElements.length - 1; i += 1) {
          slicedElements[i].index += 1;
        }
      } else {
        slicedElements = sortedElements.slice(oldIndex, newIndex + 1);
        slicedElements[0].index =
          slicedElements[slicedElements.length - 1].index;
        for (let i = 1; i < slicedElements.length; i += 1) {
          slicedElements[i].index -= 1;
        }
      }
      await changeElementIndex({ elementsWithNewIndex: slicedElements });
      hideModal();
    } catch (error) {
      //eslint-disable-next-line
      console.log(error);
    }
  };

  render() {
    const { hideModal, handleSubmit, elementsInBin, submitting } = this.props;

    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title="Edit Part Index In Bin"
        style={{ width: 400 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            <NumericInputField
              name="index"
              min={1}
              max={elementsInBin.length}
              label="Select A New 1-Based Index"
              placeholder={1}
            />
          </div>
          <DialogFooter
            hideModal={hideModal}
            text="Save"
            submitting={submitting}
          />
        </form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {
  changeElementIndex: actions.design.changeElementIndex
};

const validate = values => {
  const errors = {};
  ["index"].forEach(key => {
    if (!values[key]) errors[key] = "Required";
  });
  return errors;
};

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "editElementIndexInBin",
    enableReinitialize: true,
    validate
  })
)(EditElementIndexInBin);
