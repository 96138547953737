/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { Dialog, Classes, Intent, Button } from "@blueprintjs/core";
import { DataTable, DialogFooter, withSelectedEntities } from "@teselagen/ui";
import "./style.css";

function DuplicateSequenceDialog(props) {
  const {
    onSubmit,
    hideModal,
    resolve,
    duplicateSequenceDialogTableSelectedEntities: selectedSeqs,
    allInputSequencesWithAttachedDuplicates
  } = props;

  const [loading, setLoading] = React.useState(false);

  const onContinue = async duplicatesToSwap => {
    setLoading(true);
    await onSubmit(duplicatesToSwap);
    setLoading(false);
    hideModal();
  };

  const handleCancel = () => {
    hideModal();
    if (resolve) resolve();
  };

  const entities = allInputSequencesWithAttachedDuplicates.filter(
    s => s.duplicateFound?.id
  );

  return (
    // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
    <Dialog
      canOutsideClickClose={false}
      isOpen
      onClose={handleCancel}
      title="Duplicate Sequence Found"
      style={{ width: 950 }}
      className="dup-seq-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        Select duplicates for import
        <DataTable
          formName="duplicateSequenceDialogTable"
          schema={[
            { path: "name", type: "string", displayName: "Sequence Name" },
            {
              path: "duplicateFound.name",
              type: "string",
              displayName: "Matching Sequence in Library"
            }
          ]}
          withCheckboxes
          entities={entities}
          doNotShowEmptyRows
        />
      </div>
      <DialogFooter
        hideModal={handleCancel}
        additionalButtons={
          <Button
            intent={Intent.PRIMARY}
            minimal
            loading={loading}
            text="Continue with importing duplicates"
            onClick={() =>
              onContinue(entities.filter(s => !selectedSeqs.includes(s)))
            }
            disabled={!selectedSeqs.length}
          />
        }
        text="Continue without importing duplicates"
        loading={loading}
        onClick={() => onContinue(entities)}
      />
    </Dialog>
  );
}

export default compose(withSelectedEntities("duplicateSequenceDialogTable"))(
  DuplicateSequenceDialog
);
