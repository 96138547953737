/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash-es";
import pluralize from "pluralize";
import generateFragmentWithFields from "./generateFragmentWithFields";
import generateQuery from "./generateQuery";
import generateHasuraQuery from "./generateHasuraQuery";

/**
 *
 * @typedef {import('graphql').DocumentNode} DocumentNode
 *
 * A point on a two dimensional plane.
 * @typedef {Object} FragmentQueryData
 * @property {string | DocumentNode} gqlQuery
 * @property {string} modelName
 * @property {string} nameToUse
 * @property {string} queryNameToUse
 * @property {DocumentNode | string} fragment
 * @property {boolean} isPlural
 *
 *
 *
 * @param {string | [string, string] | DocumentNode} inputFragment
 * @param {*} options
 * @returns {FragmentQueryData}
 */
export default function fragmentToQuery(inputFragment, options) {
  if (options.useHasura) {
    const r = generateHasuraQuery(inputFragment, options);
    return r;
  }
  const { isPlural, nameOverride, queryName } = options;

  let fragment = inputFragment;
  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment);
  }
  const gqlQuery = generateQuery(fragment, options);
  const modelName = get(fragment, "definitions[0].typeCondition.name.value");
  const nameToUse =
    nameOverride || (isPlural ? pluralize(modelName) : modelName);
  const queryNameToUse = queryName || nameToUse + "Query";

  return {
    gqlQuery,
    modelName,
    nameToUse,
    queryNameToUse,
    fragment,
    isPlural
  };
}
