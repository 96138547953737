/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import combinatorialGoldenGate from "./combinatorialGoldenGate";
import combinatorialGibson from "./combinatorialGibson";
import gibson from "./gibson";
import simpleHierarchical from "./simpleHierarchical";
import goldenGate from "./goldenGate";

export {
  combinatorialGoldenGate,
  combinatorialGibson,
  gibson,
  simpleHierarchical,
  goldenGate
};

export default {
  "Golden Gate": goldenGate,
  Gibson: gibson,
  "Combinatorial Golden Gate": combinatorialGoldenGate,
  "Combinatorial Gibson": combinatorialGibson
};
