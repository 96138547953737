/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import NewEquipmentFields from "./NewEquipmentFields";

import { asyncValidateBarcodeHandler } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { uploadAndProcessFiles } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import stripFields from "../../../../src-shared/utils/stripFields";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { isEmpty } from "lodash";

export async function handleEquipmentSubmit(values) {
  values.locationId = values.location.id;
  delete values.location;
  if (!values.generateBarcode && values.userAddedBarcode) {
    values.barcode = {
      barcodeString: values.userAddedBarcode
    };
  }
  delete values.generateBarcode;
  delete values.userAddedBarcode;
  values.equipmentTypeCode = values.equipmentType.code;
  values.equipmentFiles = await uploadAndProcessFiles(values.equipmentFiles);
  if (values.equipmentType.equipmentDefaultPositions) {
    values.equipmentPositions = stripFields(
      values.equipmentType.equipmentDefaultPositions,
      ["id", "__typename"]
    );
  }
  delete values.equipmentType;
}

class AddNewEquipmentDialog extends React.Component {
  onSubmit = async _values => {
    try {
      const { hideModal, history } = this.props;
      const values = { ..._values };
      const generateBarcode = values.generateBarcode;
      await handleEquipmentSubmit(values);
      const [newEquipment] = await safeUpsert("equipmentItem", values);
      if (generateBarcode) {
        await addBarcodesToRecords(newEquipment);
      }
      hideModal();
      history.push(`/equipment/${newEquipment.id}`);
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Error adding new equipment");
    }
  };

  render() {
    const { handleSubmit, hideModal, submitting, asyncValidating } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <NewEquipmentFields asyncValidating={asyncValidating} />
        <DialogFooter hideModal={hideModal} loading={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Add New Equipment", style: { width: 800 } }),
  reduxForm({
    form: "addNewEquipmentDialogForm",
    asyncBlurFields: ["userAddedBarcode", "barcode.barcodeString"],
    asyncValidate: async (values, dis, ownProps) => {
      const errors = {};
      const options = {
        values,
        ownProps,
        model: "equipmentItem",
        errors
      };
      await asyncValidateBarcodeHandler(options);
      if (!isEmpty(errors)) {
        throw errors;
      }
    }
  })
)(AddNewEquipmentDialog);
