/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniq, startsWith } from "lodash";
import {
  getSequenceStringsOfElement,
  getElementsInBin,
  getInputCardsOfReaction,
  getBinsInCard,
  getItemOfType
} from "../../selectors/designStateSelectors";
import { ChangeSetsHelper } from ".";
import {} from "../../selectors/designStateSelectors";
import { getJunctionOnCard } from "../../selectors/junctionSelectors";
import { getBinsThatArePropagatedUpMap } from "../../selectors/submitForAssemblySelectors";
import getFlankingSequenceOfPart from "../../selectors/getFlankingSequenceOfPart";

/**
 * Given the design state, see if a design with USER assembly is valid
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
export default function validateUSER(designState) {
  try {
    const elMsgsMap = {};
    const cardMsgsMap = {};
    const state = { design: designState };
    const changeSetsHelper = new ChangeSetsHelper(designState);
    const userAssemblyMethod = Object.values(designState.assemblyMethod).find(
      x => x.name === "USER"
    );
    const digestFasElMap = Object.values(designState.fas)
      .filter(fas => fas.name === "DIGEST")
      .reduce((acc, digestFas) => {
        acc[digestFas.elementId] = true;
        return acc;
      }, {});

    if (!userAssemblyMethod) {
      // if no USER method in design then short-circuit
      return designState;
    }

    const userReactions = Object.values(designState.reaction).filter(
      rxn => rxn.assemblyMethodId === userAssemblyMethod.id
    );

    userReactions.forEach(reaction => {
      // get inputs of reaction
      const inputCards = getInputCardsOfReaction(state, reaction.id);

      inputCards.forEach(inputCard => {
        // kc_refactor this needs to be sorted
        const bins = getBinsInCard(state, inputCard.id);
        const leftJunction = getJunctionOnCard(state, inputCard.id, true);
        const rightJunction = getJunctionOnCard(state, inputCard.id, false);

        if (!rightJunction.bps) {
          cardMsgsMap[inputCard.id] = cardMsgsMap[inputCard.id] || [];

          return cardMsgsMap[inputCard.id].push(
            "USER: 3' junction of this card does not have specified bps, required for USER assembly"
          );
        } else if (!leftJunction.bps) {
          cardMsgsMap[inputCard.id] = cardMsgsMap[inputCard.id] || [];

          return cardMsgsMap[inputCard.id].push(
            "USER: 5' junction of this card does not have specified bps, required for USER assembly"
          );
        }

        const firstBin = bins[0];
        const lastBin = bins[bins.length - 1];
        const propagatingBinsMap = getBinsThatArePropagatedUpMap(
          state,
          inputCard.id
        );
        const overhangLeft = !leftJunction.fivePrimeCardFormsOverhang;
        const overhangRight = rightJunction.fivePrimeCardFormsOverhang;

        const validationParams = {
          state,
          card: inputCard,
          elMsgsMap,
          cardMsgsMap,
          bins,
          digestFasElMap,
          firstBin,
          lastBin,
          propagatingBinsMap,
          overhangLeft,
          overhangRight,
          leftJunction,
          rightJunction
        };

        if (bins.length === 1) {
          return handleSingleBinValidation(validationParams);
        } else {
          return handleMultiBinValidation(validationParams);
        }
      });
    });

    const allElements = designState.element;
    const allCards = designState.card;
    clearObsoleteInvalidityMsgs({
      allElements,
      elMsgsMap,
      changeSetsHelper
    });

    Object.entries(elMsgsMap).forEach(([elementId, invalidityMessages]) => {
      const element = allElements[elementId];

      // get rid of old USER msgs, replace with new ones
      const existingMsgs = !element.invalidityMessage
        ? []
        : element.invalidityMessage
            .split("\n")
            .filter(msg => !startsWith(msg, "USER"));

      const invalidityMessage = invalidityMessages.length
        ? existingMsgs.concat(uniq(invalidityMessages)).join("\n")
        : null;

      // add USER related msgs to any existing non-USER msgs
      changeSetsHelper.updatePure("element", {
        id: element.id,
        invalidityMessage
      });
    });

    Object.entries(cardMsgsMap).forEach(([cardId, invalidityMessages]) => {
      const card = allCards[cardId];

      // get rid of old USER msgs, replace with new ones
      const existingMsgs = !card.invalidityMessage
        ? []
        : card.invalidityMessage
            .split("\n")
            .filter(msg => !startsWith(msg, "USER"));

      const invalidityMessage = invalidityMessages.length
        ? existingMsgs.concat(uniq(invalidityMessages)).join("\n")
        : existingMsgs.join("\n");

      // add USER related msgs to any existing non-USER msgs
      changeSetsHelper.updatePure("card", {
        id: card.id,
        invalidityMessage
      });
    });

    return changeSetsHelper.execute({
      removeCardInvalidityMessages: false,
      updateNumPlaceholders: false,
      removeInvalidEugeneRules: false,
      removeInvalidElementCombos: false,
      recomputeUSERValidation: false
    });
  } catch (err) {
    console.error("Error with validating digest fas", err);
    return designState;
  }
}

function clearObsoleteInvalidityMsgs({
  allElements,
  elMsgsMap,
  changeSetsHelper,
  designState
}) {
  for (const element of Object.values(allElements)) {
    if (element.invalidityMessage && !elMsgsMap[element.id]) {
      // only clear USER related msgs, not Express Digest msgs
      const msgs = element.invalidityMessage
        .split("\n")
        .filter(msg => !startsWith(msg, "USER"));
      changeSetsHelper.updatePure("element", {
        id: element.id,
        invalidityMessage: msgs.length === 0 ? null : msgs.join("\n")
      });
    }
  }
  return designState;
}

// kc_todo move this to @teselagen/sequence-utils
export const getReverseComplementSequenceRnaString = sequence => {
  const dnaRnaComplementMap = {
    a: "t",
    t: "a",
    c: "g",
    g: "c",
    A: "T",
    T: "A",
    C: "G",
    G: "C",
    r: "y",
    R: "Y",
    y: "r",
    Y: "R",
    d: "h",
    D: "H",
    h: "d",
    H: "D",
    k: "m",
    K: "M",
    m: "k",
    M: "K",
    v: "b",
    V: "B",
    b: "v",
    B: "V",
    U: "A",
    u: "a"
  };

  let reverseComplementSequenceString = "";
  for (let i = sequence.length - 1; i >= 0; i--) {
    let revChar = dnaRnaComplementMap[sequence[i]];
    if (!revChar) {
      revChar = sequence[i];
      throw new Error(`invalid base '${sequence[i]}' detected`);
    }
    reverseComplementSequenceString += revChar;
  }
  return reverseComplementSequenceString;
};

function handleMultiBinValidation({
  state,
  card,
  elMsgsMap,
  cardMsgsMap,
  bins,
  // digestFasElMap,
  firstBin,
  lastBin,
  propagatingBinsMap,
  overhangLeft,
  overhangRight,
  leftJunction,
  rightJunction
}) {
  cardMsgsMap[card.id] = cardMsgsMap[card.id] || [];
  const secondBin = bins[1];
  const penultimateBin = bins[bins.length - 2];

  if (overhangLeft) {
    // if left side of card is an overhang...
    if (!propagatingBinsMap[firstBin.id]) {
      return cardMsgsMap[card.id].push(
        "USER: The USER junction should be contained in the first bin of this card, and since the bin contains the junction's overhang the first bin should propagate through the reaction."
      );
    }
  } else {
    // if left side of card is an underhang...
    if (propagatingBinsMap[firstBin.id]) {
      // the first bin should have only the basepairs of the underhang and should not propagate
      return cardMsgsMap[card.id].push(
        "USER: Underhang bins should not propagate through the USER assembly. Adjust your parts so the underhang is defined in its own bin and does not propagate."
      );
    } else if (!propagatingBinsMap[secondBin.id]) {
      return cardMsgsMap[card.id].push(
        "USER: Please define your underhang entirely in the first non-propagating bin. If you believe you have a good use case for building your design like this, please contact the TeselaGen team."
      );
    }
  }

  if (overhangRight) {
    // if right side of card is an overhang...
    if (!propagatingBinsMap[lastBin.id]) {
      cardMsgsMap[card.id].push(
        "USER: The USER junction should be contained in the last bin of this card, and since the bin contains the junction's overhang the last bin should propagate through the reaction."
      );
      return;
    }
  } else {
    // if right side of card is an underhang...
    if (propagatingBinsMap[lastBin.id]) {
      // the last bin should have only the basepairs of the underhang and should not propagate
      return cardMsgsMap[card.id].push(
        "USER: Underhang bins should not propagate through the USER assembly. Adjust your design so the underhang is defined in its own bin and does not propagate."
      );
    } else if (!propagatingBinsMap[penultimateBin.id]) {
      return cardMsgsMap[card.id].push(
        "USER: Please define your underhang entirely in the last non-propagating bin. If you believe you have a good use case for building your design like this, please contact the TeselaGen team."
      );
    }
  }

  const firstBinEls = getElementsInBin(state, firstBin.id);
  const lastBinEls = getElementsInBin(state, lastBin.id);

  firstBinEls.forEach(el => {
    elMsgsMap[el.id] = elMsgsMap[el.id] || [];

    // kc_todo if its part set then this just acts on the first part
    const seqBps = getBpsOfEl(state, el, firstBin);

    if (seqBps.length < 7) {
      // kc_refactor we should be able to calculate this with a little more work
      return elMsgsMap[el.id].push(
        `USER: The part is too short (<7 bps) for USER validation.`
      );
    }

    if (!seqBps.startsWith(leftJunction.bps)) {
      return elMsgsMap[el.id].push(
        `USER: Starting bps (${seqBps.slice(
          0,
          leftJunction.bps.length
        )}) do not match specified junction bps (${leftJunction.bps})`
      );
    }
  });

  lastBinEls.forEach(el => {
    elMsgsMap[el.id] = elMsgsMap[el.id] || [];

    const seqBps = getBpsOfEl(state, el, lastBin);

    if (seqBps.length < 7) {
      // kc_refactor we should be able to calculate this with a little more work
      return elMsgsMap[el.id].push(
        `USER: The part is too short (<7 bps) for USER validation.`
      );
    }

    if (!seqBps.endsWith(rightJunction.bps)) {
      return elMsgsMap[el.id].push(
        `USER: Ending bps (${seqBps.slice(
          -rightJunction.bps.length
        )}) do not match specified 3' junction bps (${rightJunction.bps}).`
      );
    }
  });
}

function handleSingleBinValidation({
  state,
  card,
  elMsgsMap,
  cardMsgsMap,
  bins,
  digestFasElMap,
  overhangLeft,
  overhangRight,
  leftJunction,
  rightJunction
}) {
  cardMsgsMap[card.id] = cardMsgsMap[card.id] || [];
  const bin = bins[0];

  const binEls = getElementsInBin(state, bin.id);

  binEls.forEach(el => {
    elMsgsMap[el.id] = elMsgsMap[el.id] || [];
    if (!el.partId) {
      return elMsgsMap[el.id].push(
        `USER: Please use standard parts that link to a single part for USER assembly.`
      );
    }

    const leftFlankingBps = getFlankingSequenceOfPart(
      state,
      el.partId,
      leftJunction.bps.length + (digestFasElMap[el.id] ? 0 : 1),
      true
    ).toUpperCase();
    const rightFlankingBps = getFlankingSequenceOfPart(
      state,
      el.partId,
      rightJunction.bps.length + (digestFasElMap[el.id] ? 0 : 1)
    ).toUpperCase();

    const seqBps = getBpsOfEl(state, el, bin);

    if (seqBps.length < 7) {
      // kc_refactor we should be able to calculate this with a little more work
      return elMsgsMap[el.id].push(
        `USER: The part is too short (<7 bps) for USER validation.`
      );
    }

    if (overhangLeft) {
      if (!seqBps.startsWith(leftJunction.bps)) {
        return elMsgsMap[el.id].push(
          `USER: Starting bps (${seqBps.slice(
            0,
            leftJunction.bps.length
          )}) do not match specified junction bps (${leftJunction.bps})`
        );
      }
      if (leftFlankingBps.length > 0 && !digestFasElMap[el.id]) {
        return elMsgsMap[el.id].push(
          `USER: There shouldn't be any flanking sequence upstream of this part (unless it has DIGEST FAS), it should begin with the USER overhang.`
        );
      }
    } else {
      if (
        leftFlankingBps.length !== leftJunction.bps.length &&
        !digestFasElMap[el.id]
      ) {
        return elMsgsMap[el.id].push(
          `USER: There should only be bps of the underhang upstream of this part on its source sequence (unless it has DIGEST FAS).`
        );
      }
      if (leftFlankingBps !== leftJunction.bps) {
        return elMsgsMap[el.id].push(
          `USER: Upstream bps of this part (${leftFlankingBps}) do not match expected junction bps (${leftJunction.bps}).`
        );
      }
    }

    if (overhangRight) {
      if (rightFlankingBps.length > 0 && !digestFasElMap[el.id]) {
        return elMsgsMap[el.id].push(
          `USER: There shouldn't be any flanking sequence downstream of this part (unless it has DIGEST FAS), it should end with the USER overhang.`
        );
      }
      if (!seqBps.endsWith(rightJunction.bps)) {
        return elMsgsMap[el.id].push(
          `USER: Ending bps (${seqBps.slice(
            -rightJunction.bps.length
          )}) do not match specified junction bps (${rightJunction.bps})`
        );
      }
    } else {
      if (
        rightFlankingBps.length !== rightJunction.bps.length &&
        !digestFasElMap[el.id]
      ) {
        return elMsgsMap[el.id].push(
          `USER: There should only be bps of the underhang downstream of this part (unless it has DIGEST FAS).`
        );
      }
      if (rightFlankingBps !== rightJunction.bps) {
        return elMsgsMap[el.id].push(
          `USER: Downstream bps of this part (${rightFlankingBps}) do not match expected junction bps (${rightJunction.bps}).`
        );
      }
    }
  });
}

function getBpsOfEl(state, el, bin) {
  // kc_todo if its part set then this just acts on the first part
  let seqBps = getSequenceStringsOfElement(state, el.id)[0].toUpperCase();

  if (seqBps.length > 50) {
    // if it's a really long sequence then shorten it for perf/debugging clarity
    seqBps = seqBps.slice(0, 25).concat(seqBps.slice(-25));
  }

  const isReversePart =
    el.partId && getItemOfType(state, "part", el.partId).strand === -1;

  if ((bin.direction && isReversePart) || (!bin.direction && !isReversePart)) {
    seqBps = getReverseComplementSequenceRnaString(seqBps).toUpperCase();
  }
  return seqBps;
}
