/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { Intent, Button } from "@blueprintjs/core";
import { NumericInputField, ReactSelectField, InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import toolConstants from "../constants.json";

class SequenceTuplingConfig extends React.Component {
  render() {
    const {
      Footer,
      footerProps,
      excludedAnnotationTags = [],
      exclusiveAnnotationTags = []
    } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div style={{ paddingBottom: 15 }}>
            <h6>Sample Multiplexing Data Table Name</h6>
            <InputField
              id={toolConstants.JOB_NAME_INPUT_FIELD_ID}
              name="jobName"
              placeholder="Please enter a name..."
              style={{ width: 500 }}
              isRequired
            />
          </div>
          <HeaderWithHelper
            header="Configuration Parameters"
            width="100%"
            helper="These parameters define how the sample multiplexing optimization is executed."
          />
          <div style={{ display: "flex", width: 400 }}>
            <div>
              <NumericInputField
                id={toolConstants.MAX_ALIGNMENT_OVERLAP_NUMERIC_INPUT_FIELD_ID}
                name="maxBPOverlap"
                label="Maximum Alignment Overlap"
                placeholder="100"
                tooltipInfo="Maximum base pair overlap allowed between the reference sequence of aliquots in the same tuple."
                style={{ width: 200 }}
                min={1}
              />
            </div>
            <div style={{ marginLeft: "40px" }}>
              <NumericInputField
                id={toolConstants.MAX_TUPLE_SIZE_NUMERIC_INPUT_FIELD_ID}
                name="tupleMaxLength"
                label="Maximum Tuple Size"
                placeholder="4"
                min={1}
                tooltipInfo="Maximum number of aliquots in a tuple."
                style={{ width: 200 }}
              />
            </div>
          </div>

          <div style={{ width: 500 }}>
            <div id={toolConstants.EXCLUSIVE_ANNOTATIONS_REACT_INPUT_FIELD_ID}>
              <ReactSelectField
                name="exclusiveAnnotationTags"
                tooltipInfo="Overlap computation will be performed exclusively for regions with these selected tags."
                label={
                  excludedAnnotationTags.length > 0 ? (
                    <span style={{ color: "red" }}>Exclusive Annotations</span>
                  ) : (
                    <span>Exclusive Annotations</span>
                  )
                }
                options={
                  excludedAnnotationTags.length > 0
                    ? undefined
                    : this.props.featureTags
                }
                multi
                placeholder="Please choose..."
                disabled={excludedAnnotationTags.length > 0}
              />
            </div>
            <div id={toolConstants.EXCLUDED_ANNOTATIONS_REACT_INPUT_FIELD_ID}>
              <ReactSelectField
                name="excludedAnnotationTags"
                tooltipInfo="Overlap computation will exclude regions annotated with these selected tags."
                label={
                  exclusiveAnnotationTags.length > 0 ? (
                    <span style={{ color: "red" }}>Excluded Annotations</span>
                  ) : (
                    <span>Excluded Annotations</span>
                  )
                }
                options={
                  exclusiveAnnotationTags.length > 0
                    ? undefined
                    : this.props.featureTags
                }
                multi
                placeholder="Please choose..."
                disabled={exclusiveAnnotationTags.length > 0}
              />
            </div>
          </div>
        </div>

        <Footer
          {...footerProps}
          nextButton={
            <Button
              id={toolConstants.STEP_2_SUBMIT_BUTTON_ID}
              type="submit"
              intent={Intent.SUCCESS}
              text="Submit"
              loading={footerProps.submitting}
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "tuplingData",
    "featureTags",
    "exclusiveAnnotationTags",
    "excludedAnnotationTags",
    "jobName"
  )
)(SequenceTuplingConfig);
