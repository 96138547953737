/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { get, pick, forEach } from "lodash";
import { InputField, CheckboxField, SelectField } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import shortid from "shortid";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import containerArrayTypeFragment from "../../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import {
  arrayToItemValuedOptions,
  arrayToIdOrCodeValuedOptions
} from "../../../../../src-shared/utils/formUtils";
import { generateContainerArray } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import UnitInputField from "../../../UnitInputField";

class PcrConfiguration extends Component {
  generateWorklist = () => {
    const {
      stepFormProps: { change },
      plateMapGroup,
      generateBarcodes,
      containerArrays = [],
      dataTableAliquots = [],
      outputPlateInfo,
      primerTransferVolume,
      primaryTemplateTransferVolume,
      primerTransferVolumetricUnitCode,
      primaryTemplateTransferVolumetricUnitCode,
      nextStep
    } = this.props;

    const outputPlates = [];
    const transfers = [];
    const sequenceMap = {};

    const updateSequenceMap = (sequence, aliquotContainer) => {
      if (sequence) {
        sequenceMap[sequence.id] = aliquotContainer;
      }
    };

    dataTableAliquots.forEach(aliquot => {
      const sequence = get(
        aliquot,
        "sample.material.polynucleotideMaterialSequence"
      );
      const aliquotContainer = {
        ...aliquot.aliquotContainer,
        aliquot: {
          id: get(aliquot, "id"),
          volume: get(aliquot, "volume"),
          volumetricUnitCode: get(aliquot, "volumetricUnitCode")
        }
      };
      updateSequenceMap(sequence, aliquotContainer);
    });

    containerArrays.forEach(c => {
      c.aliquotContainers.forEach(ac => {
        const sequence = get(
          ac,
          "aliquot.sample.material.polynucleotideMaterialSequence"
        );
        updateSequenceMap(sequence, {
          ...ac,
          containerArray: c
        });
      });
    });

    plateMapGroup.plateMaps.reduce((acc, plateMap) => {
      const { name, barcode, containerArrayType } =
        outputPlateInfo["id" + plateMap.id] || {};

      const outputPlate = {
        name,
        containerArrayTypeId: containerArrayType.id,
        ...(!generateBarcodes && {
          barcode: {
            barcodeString: barcode
          }
        })
      };
      const aliquotContainers = [];
      plateMap.plateMapItems.forEach(plateMapItem => {
        if (!plateMapItem.j5Item) return;
        const sequences = [];
        sequences.push(
          get(plateMapItem, "j5Item.j5PcrReaction.primaryTemplate")
        );
        sequences.push(
          get(plateMapItem, "j5Item.j5PcrReaction.forwardPrimer.sequence")
        );
        sequences.push(
          get(plateMapItem, "j5Item.j5PcrReaction.reversePrimer.sequence")
        );

        const aliquotContainer = {
          cid: shortid(),
          aliquotContainerTypeCode: containerArrayType.isPlate
            ? containerArrayType.aliquotContainerType.code
            : outputPlateInfo["id" + plateMap.id].aliquotContainerType.code,
          ...pick(plateMapItem, ["rowPosition", "columnPosition"])
        };

        aliquotContainers.push(aliquotContainer);
        sequences.forEach((sequence, i) => {
          const isPrimer = i > 0;
          const sourceAliquotContainer = sequenceMap[sequence.id];
          transfers.push({
            volume: isPrimer
              ? primerTransferVolume
              : primaryTemplateTransferVolume,
            volumetricUnitCode: isPrimer
              ? primerTransferVolumetricUnitCode
              : primaryTemplateTransferVolumetricUnitCode,
            sourceAliquotContainerId: sourceAliquotContainer.id,
            sourceAliquotContainer,
            destinationPlateName: outputPlate.name,
            destinationAliquotContainer: aliquotContainer,
            destinationAliquotContainerId: `&${aliquotContainer.cid}`
          });
        });
      });

      outputPlate.aliquotContainers = generateContainerArray(
        aliquotContainers,
        plateMapGroup.containerFormat,
        {
          aliquotContainerTypeCode: containerArrayType.isPlate
            ? containerArrayType.aliquotContainerTypeCode
            : outputPlateInfo["id" + plateMap.id].aliquotContainerType.code
        }
      );
      outputPlates.push(outputPlate);
      return acc;
    }, {});
    change("outputPlates", outputPlates);
    change("worklist", { worklistTransfers: transfers });
    nextStep();
  };

  render() {
    const {
      plateMapGroup,
      outputPlateInfo = {},
      generateBarcodes,
      Footer,
      footerProps,
      containerArrayTypes,
      handleSubmit
    } = this.props;

    const tubeTypeOptions = {};
    forEach(outputPlateInfo, (value, plateMapIdKey) => {
      tubeTypeOptions[
        plateMapIdKey
      ] = value.containerArrayType.nestableTubeTypes.map(
        n => n.aliquotContainerType
      );
    });
    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Transfer Volumes"
            helper="Enter transfer volumes for input primers and primary templates."
          />
          <UnitInputField
            label="Primer"
            isRequired
            name="primerTransferVolume"
            unitName="primerTransferVolumetricUnitCode"
            unitDefault="uL"
            unitType="volumetricUnit"
          />
          <UnitInputField
            label="Primary Templates"
            isRequired
            name="primaryTemplateTransferVolume"
            unitName="primaryTemplateTransferVolumetricUnitCode"
            unitDefault="uL"
            unitType="volumetricUnit"
          />
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Output Plate Info"
            helper="Enter a name, type and barcode for the destination plate."
          />
          <div>
            <CheckboxField
              name="generateBarcodes"
              label="Generate Plate Barcode"
              defaultValue
            />
            {plateMapGroup.plateMaps.map(plateMap => {
              return (
                <React.Fragment key={plateMap.id}>
                  <InputField
                    label={`Output Plate Name for ${plateMap.name}`}
                    name={`outputPlateInfo.id${plateMap.id}.name`}
                    isRequired
                    generateDefaultValue={{
                      ...defaultValueConstants.DESTINATION_CONTAINER_NAME,
                      customParams: {
                        containerType: "Plate"
                      }
                    }}
                  />
                  <SelectField
                    label="Plate Type"
                    name={`outputPlateInfo.id${plateMap.id}.containerArrayType`}
                    isRequired
                    options={arrayToItemValuedOptions(containerArrayTypes)}
                    defaultValue={containerArrayTypes[0]}
                  />
                  {tubeTypeOptions["id" + plateMap.id] &&
                    tubeTypeOptions["id" + plateMap.id].length > 0 && (
                      <SelectField
                        name={`outputPlateInfo.id${plateMap.id}.aliquotContainerType`}
                        label="Tube Type"
                        isRequired
                        defaultValue={tubeTypeOptions["id" + plateMap.id][0]}
                        options={arrayToIdOrCodeValuedOptions(
                          tubeTypeOptions["id" + plateMap.id]
                        )}
                      />
                    )}
                  {!generateBarcodes && (
                    <InputField
                      label="Barcode"
                      name={`outputPlateInfo.id${plateMap.id}.barcode`}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.generateWorklist)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "plateMapGroup",
    "dataTableAliquots",
    "containerArrays",
    "generateBarcodes",
    "outputPlateInfo",
    "primerTransferVolume",
    "primaryTemplateTransferVolume",
    "primerTransferVolumetricUnitCode",
    "primaryTemplateTransferVolumetricUnitCode"
  ),
  withQuery(containerArrayTypeFragment, {
    isPlural: true,
    showLoading: true,
    options: ({ plateMapGroup: { containerFormatCode } }) => {
      return {
        variables: {
          filter: {
            containerFormatCode
          }
        }
      };
    }
  })
)(PcrConfiguration);
