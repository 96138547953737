/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { pick, isEmpty } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  CheckboxField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { compose } from "redux";
import {
  prepareWetAliquot,
  prepareDryAliquot
} from "../../../utils/plateUtils";

import HydrateForm from "../../HydrateForm";
import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";

import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import CreateOrGenerateBarcodeField from "../../CreateOrGenerateBarcodeField";
import { asyncValidateNameHandler } from "../../../../src-shared/utils/formUtils";
import { asyncValidateBarcodeHandler } from "../../../../src-shared/utils/formUtils";
import isValidPositiveNumber from "../../../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import gql from "graphql-tag";
import aliquotHydrationFragment from "../../../graphql/fragments/aliquotHydrationFragment";

const plateUpdateFields = ["id", "name"];
const aliquotUpdateFields = [
  "id",
  "isDry",
  "mass",
  "massUnitCode",
  "volume",
  "volumetricUnitCode",
  "concentration",
  "concentrationUnitCode",
  "molarity",
  "molarityUnitCode"
];

class UpdatePlateDialog extends Component {
  onSubmit = async values => {
    const {
      hideModal,
      initialValues: { barcode: initialBarcode = {} },
      refetch = () => {}
    } = this.props;
    const initialBarcodeString = initialBarcode && initialBarcode.barcodeString;
    try {
      const {
        hydrate,
        volume,
        volumetricUnitCode,
        concentrationUnitCode,
        molarityUnitCode,
        aliquotContainers,
        containerArrayType,
        barcode
      } = values;
      let aliquotsToUpdate = [];
      const additivesToDelete = [];
      let getAliquotUpdate;
      if (hydrate === "Dehydrate") {
        getAliquotUpdate = aliquot => {
          if (aliquot.additives.length > 0) {
            aliquot.additives.forEach(additive => {
              if (additive.additiveMaterial.evaporable) {
                additivesToDelete.push(additive);
              }
            });
          }
          return prepareDryAliquot({ aliquot });
        };
      } else if (hydrate === "Rehydrate") {
        getAliquotUpdate = aliquot =>
          prepareWetAliquot({
            aliquot,
            volume,
            volumetricUnitCode,
            concentrationUnitCode,
            molarityUnitCode
          });
      }
      if (hydrate && hydrate !== "None") {
        if (hydrate === "Dehydrate") {
          const noConcentration = aliquotContainers.some(
            ac => ac.aliquot && !ac.aliquot.concentration
          );
          if (noConcentration)
            return window.toastr.warning(
              "Could not update plate because one or more aliquot did not have concentration."
            );
        }
        if (hydrate === "Rehydrate") {
          const aliquotContainerType = containerArrayType.aliquotContainerType;
          if (!containerArrayType.isPlate) {
            const aliquotContainersWithAliquots = aliquotContainers.filter(
              ac => ac.aliquot
            );
            let smallestTubeVolume = standardizeVolume(
              aliquotContainersWithAliquots[0].aliquotContainerType.maxVolume,
              aliquotContainersWithAliquots[0].aliquotContainerType
                .volumetricUnitCode,
              true
            );
            aliquotContainersWithAliquots.forEach(ac => {
              const currentTubeVolume = standardizeVolume(
                ac.aliquotContainerType.maxVolume,
                ac.aliquotContainerType.volumetricUnitCode,
                true
              );
              if (currentTubeVolume.lt(smallestTubeVolume)) {
                smallestTubeVolume = currentTubeVolume;
              }
            });
            if (
              standardizeVolume(volume, volumetricUnitCode, true).gt(
                smallestTubeVolume
              )
            ) {
              return window.toastr.error(
                `Rehydration volume is greater than max tube volume.`
              );
            }
          } else if (
            standardizeVolume(volume, volumetricUnitCode) >
            standardizeVolume(
              aliquotContainerType.maxVolume,
              aliquotContainerType.volumetricUnitCode
            )
          ) {
            return window.toastr.error(
              `Rehydration volume is greater than max well volume (${aliquotContainerType.maxVolume} ${aliquotContainerType.volumetricUnitCode}).`
            );
          }
        }
        aliquotsToUpdate = aliquotContainers.reduce((acc, { aliquot }) => {
          if (
            !aliquot ||
            (hydrate === "Dehydrate" ? aliquot.isDry : !aliquot.isDry)
          )
            return acc;
          return acc.concat(getAliquotUpdate(aliquot));
        }, []);
      }
      if (barcode && barcode.barcodeString !== initialBarcodeString) {
        await safeUpsert("barcode", {
          ...pick(barcode, ["id", "barcodeString"]),
          containerArrayId: values.id
        });
      }
      await safeDelete(
        "additive",
        additivesToDelete.map(add => add.id)
      );
      await safeUpsert(["aliquot", aliquotUpdateFields], aliquotsToUpdate);
      await safeUpsert(
        [
          "containerArray",
          [...plateUpdateFields, "barcode { id barcodeString }"]
        ],
        pick(values, plateUpdateFields)
      );
      await refetch();
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Error updating plate");
    }
  };

  render() {
    const {
      hideModal,
      submitting,
      handleSubmit,
      initialValues = {},
      asyncValidating
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            rightElement={
              <AsyncValidateFieldSpinner validating={asyncValidating} />
            }
          />
          <CheckboxField name="allowDupName" label="Allow duplicate names" />
          <CreateOrGenerateBarcodeField
            asyncValidating={asyncValidating}
            initialValues={initialValues}
          />
          <HydrateForm
            {...{
              aliquotContainers: initialValues.aliquotContainers
            }}
          />
        </div>
        <DialogFooter hideModal={hideModal} loading={submitting} />
      </form>
    );
  }
}

function validate(values) {
  const { hydrate, volume } = values;
  const errors = {};
  if (hydrate === "Rehydrate" && !isValidPositiveNumber(volume)) {
    errors.volume = "Must be greater than 0.";
  }
  return errors;
}

async function asyncValidate(values, dispatch, ownProps) {
  const { allowDupName } = values;
  const errors = {};
  const options = {
    values,
    ownProps,
    model: "containerArray",
    errors
  };
  if (!allowDupName) {
    await asyncValidateNameHandler(options);
  }
  await asyncValidateBarcodeHandler(options);
  if (!isEmpty(errors)) {
    throw errors;
  }
}

const updatePlateFragment = gql`
  fragment updatePlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      isPlate
      containerFormat {
        code
        rowCount
        columnCount
      }
      aliquotContainerType {
        code
        maxVolume
        volumetricUnitCode
      }
    }
    aliquotContainers {
      id
      name
      additives {
        id
      }
      aliquotContainerType {
        code
        maxVolume
        volumetricUnitCode
      }
      columnPosition
      rowPosition
      aliquot {
        ...aliquotHydrationFragment
      }
    }
  }
  ${aliquotHydrationFragment}
`;

export default compose(
  wrapDialog({ title: "Update Plate" }),
  withQuery(updatePlateFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.plateId
        }
      };
    },
    props: ({ containerArray }) => {
      return {
        initialValues: containerArray
      };
    }
  }),
  reduxForm({
    form: "updatePlateDialog",
    validate,
    asyncValidate,
    asyncBlurFields: ["name", "barcode.barcodeString", "allowDupName"]
  })
)(UpdatePlateDialog);
