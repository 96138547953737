/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes } from "@blueprintjs/core";
import { safeUpsert } from "../apolloMethods";
import { InputField, DialogFooter } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { externalReferenceKeys } from "../../../tg-iso-shared/constants";
import { startCase, pick } from "lodash";
import { compose } from "recompose";
import { wrapDialog } from "@teselagen/ui";

export default compose(
  wrapDialog({
    title: "Update External Identifiers"
  }),
  reduxForm({
    form: "EditExternalIdentifiersDialog"
  })
)(function EditExternalIdentifiersDialog(props) {
  const { handleSubmit, submitting, initialValues = {}, hideModal } = props;
  async function onSubmit(values) {
    try {
      await safeUpsert(
        [initialValues.__typename, "id " + externalReferenceKeys.join(" ")],
        {
          id: values.id,
          ...pick(values, externalReferenceKeys)
        }
      );
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error updating external identifiers");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {externalReferenceKeys.map(key => {
          return (
            <InputField
              label={startCase(key.replace("Reference", ""))}
              key={key}
              name={key}
            />
          );
        })}
      </div>

      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
});
