/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { noop, isEmpty, uniqBy, upperFirst } from "lodash";
import pluralize from "pluralize";

import { addBarcodesToRecords } from "./barcodeUtils";
import {
  getTypeOfContainerArray,
  getUploadAliquotContainers
} from "./plateUtils";
import { isBrowser } from "browser-or-node";
import { isoContext } from "@teselagen/utils";
import createTaggedItems from "../../../tg-iso-shared/src/tag-utils/createTaggedItems";
import upsertUniqueAliases from "../../../tg-iso-shared/src/sequence-import-utils/upsertUniqueAliases";
import processSequences from "../../../tg-iso-shared/src/sequence-import-utils/processSequences";
import { uniq } from "lodash";
import { addTaggedItemsBeforeCreate } from "../../../tg-iso-shared/src/tag-utils";
import { bottle } from "../dependencyContainer";

export default async function finishPlateCreate(vals, ctx = isoContext) {
  const {
    safeUpsert,
    startImportCollection = noop,
    showDialog = noop,
    hideDialog = noop
  } = ctx;

  const {
    filename,
    isTubeUpload = false,
    newAliquots = [],
    newPlates = [],
    newTubes = [],
    newMaterials = [],
    newSequences = [],
    newAminoAcidSequences = [],
    sequenceUpdates = [],
    aminoAcidSequenceUpdates = [],
    existingSequences: _existingSequences = [],
    existingMaterials: _existingMaterials = [],
    existingAminoAcidSequences: _existingAminoAcidSequences = [],
    existingFunctionalProteinUnits: _existingFunctionalProteinUnits = [],
    tags = {},
    sequenceAliases = [],
    generateTubeBarcodes,
    generateBarcode,
    generateBarcodes,
    extraFn = noop,
    createUploadProperties = noop,
    shouldAssignToLocation,
    containerArrayType,
    aliquotContainerType,
    refetch = noop,
    shouldFillRack,
    numTubesToFillRack,
    allPlateTypes
  } = vals;

  const isAddingTags = !isEmpty(tags);
  let maybeTaggedItemFragment = "";

  if (isAddingTags) {
    maybeTaggedItemFragment = `
      taggedItems {
        id
        tagId
        tagOptionId
      }
    `;
  }

  let tubesToCreate = newTubes;

  let containerArrayTypeForMsg = containerArrayType;
  let platesToCreate = newPlates.map(plate => {
    const aliquotContainers = getUploadAliquotContainers({
      newAliquotContainers: plate.aliquotContainers || [],
      containerArrayType: plate.containerArrayType || containerArrayType,
      shouldFillRack,
      numTubesToFillRack,
      aliquotContainerType
    });
    const newPlate = {
      ...plate,
      aliquotContainers
    };
    containerArrayTypeForMsg =
      containerArrayTypeForMsg || newPlate.containerArrayType;
    delete newPlate.containerArrayType;
    return newPlate;
  });

  // cancel early if nothing to create
  let itemsToCreate = isTubeUpload ? tubesToCreate : platesToCreate;

  if (isBrowser) {
    itemsToCreate =
      await bottle.container.checkForDuplicatePlatesOrTubesOnImport(
        itemsToCreate,
        isTubeUpload
      );
  }

  if (!itemsToCreate) return [];
  if (isTubeUpload) {
    tubesToCreate = itemsToCreate;
  } else {
    platesToCreate = itemsToCreate;
  }

  let clearProgressToast;
  // only show this on the browser
  const plateMsg = upperFirst(
    getTypeOfContainerArray(containerArrayTypeForMsg)
  );
  if (platesToCreate.length > 1 && isBrowser) {
    let msg = `Importing ${platesToCreate.length} ${plateMsg}`;
    if (platesToCreate.length > 1) msg += "s";
    clearProgressToast = window.showProgressToast(msg);
  }
  await startImportCollection(
    filename || (isTubeUpload ? "Tube Import" : `${plateMsg} Import`)
  );
  await safeUpsert("material", addTaggedItemsBeforeCreate(newMaterials, tags), {
    excludeResults: true
  });
  const createdSequences = await safeUpsert(
    "sequence",
    addTaggedItemsBeforeCreate(newSequences, tags)
  );
  await safeUpsert(
    "aminoAcidSequence",
    addTaggedItemsBeforeCreate(newAminoAcidSequences, tags),
    {
      excludeResults: true
    }
  );
  const updatedSequences = await safeUpsert(
    ["sequence", `id ${maybeTaggedItemFragment}`],
    sequenceUpdates
  );
  const updatedAminoAcidSequences = await safeUpsert(
    ["aminoAcidSequence", `id ${maybeTaggedItemFragment}`],
    aminoAcidSequenceUpdates
  );

  await upsertUniqueAliases(sequenceAliases, ctx);

  const existingSequences = uniqBy(
    _existingSequences.concat(updatedSequences),
    "id"
  );

  const allSequenceIds = uniq(
    createdSequences.concat(updatedSequences, existingSequences).map(s => s.id)
  );

  const createdAliquots = await safeUpsert(
    ["aliquot", "id sampleId"],
    addTaggedItemsBeforeCreate(newAliquots, tags)
  );
  const sampleUpdates = [];
  createdAliquots.forEach(aliquot =>
    sampleUpdates.push({ id: aliquot.sampleId, sampleAliquotId: aliquot.id })
  );
  await safeUpsert("sample", sampleUpdates);
  // we can modify the nested aliquots with tags and upsert them directly so we don't have to query back as much data
  if (!isEmpty(tags)) {
    const nestedAliquots = [];
    platesToCreate.forEach(plate => {
      plate.aliquotContainers &&
        plate.aliquotContainers.forEach(ac => {
          if (ac.aliquot) {
            nestedAliquots.push(ac.aliquot);
          }
        });
    });
    addTaggedItemsBeforeCreate(nestedAliquots, tags);
  }
  let plateFragment = "id name";
  if (generateTubeBarcodes) {
    plateFragment += `
      aliquotContainers {
        id
      }
    `;
  }
  const createdPlates = await safeUpsert(
    ["containerArray", plateFragment],
    addTaggedItemsBeforeCreate(platesToCreate, tags)
  );

  const createdTubes = await safeUpsert(
    "aliquotContainer",
    addTaggedItemsBeforeCreate(tubesToCreate, tags)
  );
  if (generateBarcode || generateBarcodes) {
    await addBarcodesToRecords(createdPlates, ctx);
    await addBarcodesToRecords(createdTubes, ctx);
  }
  if (generateTubeBarcodes) {
    const createdAliquotContainers = [];
    createdPlates.forEach(p => {
      p.aliquotContainers.forEach(ac => createdAliquotContainers.push(ac));
    });
    await addBarcodesToRecords(createdAliquotContainers, ctx);
  }

  const existingMaterials = uniqBy(_existingMaterials, "id");
  const existingAAs = uniqBy(
    _existingAminoAcidSequences.concat(updatedAminoAcidSequences),
    "id"
  );
  const existingFpus = uniqBy(_existingFunctionalProteinUnits, "id");
  if (isAddingTags) {
    for (const items of [
      existingSequences,
      existingMaterials,
      existingAAs,
      existingFpus
    ]) {
      await createTaggedItems(
        {
          selectedTags: tags,
          records: items
        },
        ctx
      );
    }
  }

  await processSequences(allSequenceIds, ctx);

  await extraFn({
    sequenceIds: allSequenceIds,
    createdPlates,
    createdTubes
  });
  await createUploadProperties();

  if (clearProgressToast) clearProgressToast();

  await refetch();
  if (isTubeUpload && !createdTubes.length) {
    throw new Error("No tubes to create.");
  } else if (!isTubeUpload && !createdPlates.length) {
    throw new Error(`No ${pluralize(plateMsg)} to create.`);
  }
  hideDialog();
  if (shouldAssignToLocation && (createdPlates.length || createdTubes.length)) {
    let modalProps = {};
    if (createdPlates.length) {
      modalProps = {
        plateIds: createdPlates.map(p => p.id),
        containerArrayType,
        containerArrayTypes: allPlateTypes,
        refetch
      };
    } else if (createdTubes.length) {
      modalProps = {
        tubeIds: createdTubes.map(t => t.id),
        aliquotContainerType,
        refetch
      };
    }
    showDialog({
      modalType: "ASSIGN_PLATE_PLACEMENT_STRATEGY",
      modalProps
    });
  }
  if (isTubeUpload) {
    return createdTubes;
  } else {
    return createdPlates;
  }
}
