/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* eslint jsx-a11y/accessible-emoji:0 */
import React from "react";
import {
  ELEMENT_LABEL_EXTRA_WIDTH,
  ELEMENT_LABEL_EXTRA_HEIGHT,
  ELEMENT_LABEL_LINE_HEIGHT,
  ELEMENT_LABEL_HEADER_HEIGHT
} from "../../constants";
import classnames from "classnames";
import ElementName from "./ElementName";
import "./style.css";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import CreateSchematicElement from "../../../Dialogs/CreateSchematicElement";

class ElementLabel extends React.Component {
  handlePlusClick = async () => {
    try {
      const { set, refetchSchematic } = this.props;

      showDialog({
        ModalComponent: CreateSchematicElement,
        modalProps: {
          initialValues: {
            schematicSetId: set.id,
            index: set.schematicElements.length
          },
          refetch: refetchSchematic,
          type: "element"
        }
      });
    } catch (e) {
      console.error(e);
      window.toastr.error("Erroring editing schematic.");
    }
  };

  renderLabelLine = () => {
    const { labelLine, labelsHeight } = this.props;
    if (!labelLine) return null;
    return (
      <path
        className="element-label-line"
        style={{
          stroke: "#4a4b4c",
          strokeWidth: 1,
          fill: "none"
        }}
        d={labelLine
          .map(({ x, y }, i) => `${i ? "L" : "M"} ${x} ${labelsHeight - y}`)
          .join(" ")}
      />
    );
  };

  handleDoubleClick = async () => {
    const { set, readOnly } = this.props;
    if (readOnly) return;

    showDialog({
      modalType: "SCHEMATIC_EDIT_NAME",
      modalProps: {
        initialValues: set,
        type: "set"
      }
    });
  };

  render() {
    const {
      x,
      y,
      height,
      width,
      labelsHeight,
      set,
      refetchSchematic,
      readOnly
    } = this.props;
    if (set.type === "divider") return null;
    const transformY = labelsHeight - y - height;

    return (
      <React.Fragment>
        {this.renderLabelLine()}
        <g
          className={classnames({
            "element-label": true,
            "element-label-no-elements": !set.schematicElements.length
          })}
          transform={`translate(${x},${transformY})`}
        >
          <rect
            className="element-label-rect"
            style={{
              stroke: "#4a4b4c",
              strokeWidth: 1,
              fill: "#e9e9e9"
            }}
            {...{ x: 0, y: 0, height, width }}
          />
          <g
            transform={`translate(${ELEMENT_LABEL_EXTRA_WIDTH /
              2},${ELEMENT_LABEL_EXTRA_HEIGHT / 2})`}
          >
            {set.schematicElements.map((el, i) => {
              return (
                <g
                  key={el.id}
                  transform={`translate(0,${i * ELEMENT_LABEL_LINE_HEIGHT +
                    ELEMENT_LABEL_HEADER_HEIGHT})`}
                >
                  <ElementName
                    readOnly={readOnly}
                    element={el}
                    set={set}
                    refetchSchematic={refetchSchematic}
                    width={width - ELEMENT_LABEL_EXTRA_WIDTH}
                  />
                </g>
              );
            })}
          </g>
          {!readOnly && (
            <text
              className="add-element-plus"
              style={{
                cursor: "pointer",
                fill: "#54b57d",
                fontSize: "120%",
                fontWeight: 900
              }}
              textAnchor="middle"
              alignmentBaseline="baseline"
              transform={`translate(${width / 2}, ${height -
                ELEMENT_LABEL_EXTRA_HEIGHT / 2})`}
              onClick={this.handlePlusClick}
            >
              ➕
            </text>
          )}
          <rect
            className="element-label-rect"
            style={{
              stroke: "#4a4b4c",
              strokeWidth: 1,
              fill: "#555"
            }}
            {...{
              x: 0,
              y: 0,
              height: ELEMENT_LABEL_HEADER_HEIGHT,
              width
            }}
          />
          <text
            className="element-label-element-name element-name-bullet"
            alignmentBaseline="middle"
            textAnchor="middle"
            fill="#fff"
            {...{
              x: width / 2,
              y: ELEMENT_LABEL_HEADER_HEIGHT / 2,
              lengthAdjust: "spacing"
            }}
            onDoubleClick={this.handleDoubleClick}
          >
            {set.name}
          </text>
        </g>
      </React.Fragment>
    );
  }
}

export default ElementLabel;
