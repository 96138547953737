/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { get, cloneDeep, keyBy } from "lodash";
import {
  Button,
  Intent,
  Classes,
  Menu,
  MenuItem,
  ButtonGroup,
  Popover
} from "@blueprintjs/core";
import {
  DataTable,
  showConfirmationDialog,
  flaskIcon,
  withSelectTableRecords,
  getSelectedEntities
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { compose } from "recompose";
import { deleteRecordsHelper, showDeleteAlert } from "../../../utils";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import AbstractRecord from "../../../../src-shared/AbstractRecord";

import {
  getInitialPlateLayerValues,
  beforeRackDelete
} from "../../../utils/plateUtils";
import { getAlphnumericWellLocation } from "../../../../src-shared/utils/getAlphnumericWellLocation";

import PlateMapPlate from "../../PlateMapPlate";
import {
  containerArrayRecordFragment,
  containerArrayRecordViewAliquotContainerFragment
} from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";
import BulkUpdatePlateAliquotsExtendedPropertyDialog from "../../Dialogs/BulkUpdatePlateAliquotsExtendedPropertyDialog";
import handleReprint from "../../../utils/handleReprint";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import LaunchReport from "../../LaunchReport";
import plateLayerFragment from "../../../graphql/fragments/plateLayerFragment";
import TubeLocationDisplay from "../../TubeLocationDisplay";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import {
  getPlateTableSchema,
  handlePlateReformat,
  createLocationToContainerMap,
  plateRecordCellRenderer,
  getPlateRecordEntities,
  hasAliquotExtendedPropsHelper,
  showPlateRecordTableExport
} from "./utils";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import "./style.css";
import { withUnitGeneric } from "../../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import AssignAliquotsOrTubesToPlateDialog from "../../Dialogs/AssignAliquotsOrTubesToPlateDialog";
import MediaQuery from "react-responsive";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { pushHelper } from "../../../../src-shared/utils/pushHelper";
import { popoverOverflowModifiers } from "../../../../src-shared/utils/generalUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { rowIndexToLetter } from "../../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import {
  generateContainerArray,
  getPositionFromAlphanumericLocation
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import store from "../../../../src-shared/redux/store";
import DehydrateRehydrateAliquotMenuItem from "./DehydrateRehydrateAliquotMenuItem";
import PrintLabelButton from "../../PrintLabelButton";
import ExportDialog from "../../Dialogs/ExportPlatesOrTubesDialog";
import ExportAsPlateCsvDialog from "./ExportAsPlateCsvDialog";

class ContainerArrayRecordView extends Component {
  state = {
    sortColumnFirst: null,
    reformattingPlate: false,
    detachingPlate: false,
    aliquotContainers: [],
    selectedLocations: []
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedLocations: oldLocations } = prevState;
    const { selectedLocations } = this.state;
    const { selectTableRecords } = this.props;
    // this handles updating the table selection to reflect the selection on the plate preview
    if (
      selectedLocations.length !== oldLocations.length ||
      selectedLocations.some(loc => !oldLocations.includes(loc))
    ) {
      const toSelect = [];
      this.getEntities().forEach(ac => {
        const location = getAliquotContainerLocation(ac);
        if (selectedLocations.includes(location)) {
          toSelect.push(ac);
        }
      });
      selectTableRecords(toSelect);
    }
  }

  renderLinkOrNotFound({ containerArray, path, route, render }) {
    const routeToUse = route ? route : pluralize(path);
    const item = get(containerArray, path);
    return item ? (
      <Link to={`/${routeToUse}/${item.id}`}>
        {render ? render(item) : item.name}
      </Link>
    ) : (
      "Not Found"
    );
  }

  toggleSortColumnFirst = () => {
    const { sortColumnFirst } = this.state;
    return this.setState({
      sortColumnFirst: sortColumnFirst ? null : "asc"
    });
  };

  renderRecordHeaderInfoSection(containerArray = {}) {
    const { containerArrayLoading, readOnly } = this.props;
    if (containerArrayLoading || !containerArray) return;
    const attached =
      containerArray.collectionPlate || containerArray.containerArray;

    const isColumn = containerArray.containerArrayType.isColumn;
    const isPlate = containerArray.containerArrayType.isPlate;
    const existingTubeTypes = [];
    containerArray.aliquotContainers.forEach(ac => {
      const tubeTypeName = get(ac, "aliquotContainerType.name");
      if (!existingTubeTypes.includes(tubeTypeName)) {
        existingTubeTypes.push(tubeTypeName);
      }
    });
    const sharedRecordInfo = [
      ["Name", containerArray.name],
      [
        isPlate ? "Plate Type" : "Rack Type",
        containerArray.containerArrayType.name
      ],
      ["Barcode", get(containerArray, "barcode.barcodeString")],
      ["Manufacturer", containerArray.containerArrayType.manufacturer],
      ["Catalog Number", containerArray.containerArrayType.catalogNumber],
      [
        "Number of Aliquots",
        containerArray.aliquotContainers.filter(ac => ac.aliquot).length
      ]
    ];

    const plateFields = [
      [
        "Max Well Volume",
        withUnitGeneric(
          "containerArrayType.aliquotContainerType.maxVolume",
          "containerArrayType.aliquotContainerType.volumetricUnitCode"
        )(containerArray)
      ],
      [
        "Well Type",
        get(containerArray, "containerArrayType.aliquotContainerType.name")
      ],
      [
        "Well Bottom",
        get(containerArray, "containerArrayType.aliquotContainerType.bottom")
      ]
    ];
    const rackFields = [["Tube Types", existingTubeTypes.join(", ")]];
    let recordInfo;
    if (isPlate) {
      recordInfo = sharedRecordInfo.concat(plateFields);
    } else {
      recordInfo = sharedRecordInfo.concat(rackFields);
    }
    return (
      <div>
        <div className="record-header-info-section">
          <RecordInfoDisplay
            readOnly={this.props.readOnly}
            recordInfo={recordInfo}
            record={containerArray}
          />
        </div>
        {attached && (
          <div className="tg-flex justify-space-between">
            <h6>{`${isColumn ? "Collection" : "Column"} Plate:`}</h6>
            {this.renderLinkOrNotFound({
              containerArray,
              path: isColumn ? "collectionPlate" : "containerArray",
              route: "plates"
            })}
          </div>
        )}
        <TubeLocationDisplay
          readOnly={readOnly}
          containerArrayId={containerArray.id}
        />
        <LaunchReport
          reportHookTypeCode="ALIQUOT_CONTAINER"
          reportHookSourceCode="PLATE_RECORD"
          itemId={containerArray.id}
        />
      </div>
    );
  }

  openAliquot = aliquotContainer =>
    this.props.history.push(`/aliquots/${aliquotContainer.aliquot.id}`);

  showInspectWell = aliquotContainer => {
    const {
      containerArray: { id, containerArrayType }
    } = this.props;

    showDialog({
      modalType: "INSPECT_ALIQUOT_CONTAINER_DIALOG",
      modalProps: {
        containerArrayId: id,
        aliquotContainer,
        containerArrayType
      }
    });
  };

  showReplenishWell = aliquotContainer => {
    showDialog({
      modalType: "REPLENISH_ALIQUOT",
      modalProps: {
        aliquotId: aliquotContainer.aliquot.id
      }
    });
  };

  aliquotContainerDoubleClick = aliquotContainer => {
    if (aliquotContainer.aliquot) {
      this.openAliquot(aliquotContainer);
    } else {
      this.showInspectWell(aliquotContainer);
    }
  };

  deleteAliquots = async selectedAcs => {
    const { reformattingPlate, aliquotContainers } = this.state;

    if (reformattingPlate) {
      const idToSelectedAcMap = keyBy(selectedAcs, "id");
      this.setState({
        aliquotContainers: aliquotContainers.map(ac => {
          if (idToSelectedAcMap[ac.id]) {
            return {
              ...ac,
              aliquot: null
            };
          } else {
            return ac;
          }
        })
      });
    } else {
      try {
        const aliquotIdsToDelete = [],
          aliquotContainerIdsToRefetch = [];
        selectedAcs.forEach(ac => {
          const aliquotId = get(ac, "aliquot.id");
          if (aliquotId) {
            aliquotIdsToDelete.push(aliquotId);
            aliquotContainerIdsToRefetch.push(ac.id);
          }
        });
        const continueDelete = await showDeleteAlert({
          model: "aliquot",
          plural: aliquotIdsToDelete.length > 1
        });
        if (continueDelete) {
          await safeDelete("aliquot", aliquotIdsToDelete);
          await safeQuery(containerArrayRecordViewAliquotContainerFragment, {
            variables: {
              filter: {
                id: aliquotContainerIdsToRefetch
              }
            }
          });
        }
      } catch (err) {
        console.error("err:", err);
        window.toastr.error("Error deleting aliquots");
      }
    }
  };

  deleteTubes = async tubes => {
    await deleteRecordsHelper({
      records: tubes.filter(t => t.id),
      refetch: this.props.refetchContainerArray
    });
  };

  getSelectedAliquotContainers() {
    const { containerArray } = this.props;
    const { reformattingPlate, aliquotContainers, selectedLocations } =
      this.state;

    const aliquotContainersFilled = generateContainerArray(
      containerArray.aliquotContainers,
      containerArray.containerArrayType.containerFormat
    );
    const locToAcMap = createLocationToContainerMap(
      reformattingPlate ? aliquotContainers : aliquotContainersFilled
    );
    const selectedAcs = selectedLocations.map(l => locToAcMap[l]);
    return selectedAcs;
  }

  aliquotContextMenu = (aliquotContainer, fromTable) => {
    const { containerArray } = this.props;
    const selectedAcs = fromTable
      ? getSelectedEntities(store.getState(), "PlateRecordViewAliquotsTable")
      : this.getSelectedAliquotContainers();
    const isPlate = containerArray.containerArrayType.isPlate;
    const missingTubes = [],
      haveAliquots = [],
      missingAliquots = [];
    selectedAcs.forEach(ac => {
      if (!ac.id) {
        missingTubes.push(ac);
      } else if (ac.id && !ac.aliquot) {
        missingAliquots.push(ac);
      } else if (ac.id && ac.aliquot) {
        haveAliquots.push(ac);
      }
    });

    const allMissingAliquots = missingAliquots.length === selectedAcs.length;
    const allMissingTubes = missingTubes.length === selectedAcs.length;

    const onAssignClick = () => {
      showDialog({
        ModalComponent: AssignAliquotsOrTubesToPlateDialog,
        modalProps: {
          containerArrayId: containerArray.id,
          aliquotContainers: selectedAcs,
          containerArrayType: containerArray.containerArrayType
        }
      });
    };
    const menuItems = [];

    const multipleSelected = selectedAcs.length > 1;
    if (!multipleSelected && aliquotContainer.aliquot) {
      menuItems.push(
        <MenuItem
          key="viewAliquot"
          icon="eye-open"
          text="View Aliquot"
          disabled={!aliquotContainer.aliquot}
          onClick={() => this.openAliquot(aliquotContainer)}
        />
      );
    }

    if (haveAliquots.length) {
      const aliquots = haveAliquots.map(ac => ac.aliquot);
      menuItems.push(<DehydrateRehydrateAliquotMenuItem aliquots={aliquots} />);
    }

    if (allMissingAliquots) {
      menuItems.push(
        <MenuItem
          key="assignAliquots"
          icon="import"
          text={`Assign Aliquot${selectedAcs.length > 1 ? "s" : ""}`}
          onClick={onAssignClick}
        />
      );
    }
    if (allMissingTubes) {
      menuItems.push(
        <MenuItem
          key="assignTubes"
          icon="import"
          text={`Assign Tube${selectedAcs.length > 1 ? "s" : ""}`}
          onClick={onAssignClick}
        />
      );
    }
    if (!isPlate && aliquotContainer.id) {
      if (!multipleSelected) {
        menuItems.push(
          <MenuItem
            key="viewTube"
            icon={flaskIcon}
            text="View Tube"
            onClick={e =>
              pushHelper(
                e,
                modelNameToLink(
                  aliquotContainer.__typename,
                  aliquotContainer.id
                )
              )
            }
          />
        );
      }
      if (!this.state.reformattingPlate) {
        menuItems.push(
          <MenuItem
            key="deleteTube"
            icon="trash"
            text={`Delete Tube${selectedAcs.length > 1 ? "s" : ""}`}
            onClick={() => this.deleteTubes(selectedAcs)}
          />
        );
      }
    }

    if (haveAliquots.length) {
      menuItems.push(
        <MenuItem
          icon="trash"
          key="deleteAliquots"
          text={`Delete Aliquot${selectedAcs.length > 1 ? "s" : ""}`}
          onClick={() => this.deleteAliquots(haveAliquots)}
        />
      );
    }

    if (!multipleSelected) {
      menuItems.push(
        <MenuItem
          key="inspectWell"
          icon="info-sign"
          text={isPlate ? "Inspect Well" : "Inspect Position"}
          onClick={() => this.showInspectWell(aliquotContainer)}
        />
      );
      if (
        get(aliquotContainer, "aliquot.sample.material") &&
        !aliquotContainer.aliquot.isDry
      ) {
        menuItems.push(
          <MenuItem
            key="replenishWell"
            icon="tint"
            text={isPlate ? "Replenish Well" : "Replenish Position"}
            onClick={() => this.showReplenishWell(aliquotContainer)}
          />
        );
      }
    }
    if (fromTable) return menuItems;
    else return <Menu>{menuItems}</Menu>;
  };

  createPositionToContainerMap(aliquotContainers) {
    const map = {};
    for (const ac of aliquotContainers) {
      map[`${ac.rowPosition}:${ac.columnPosition}`] = ac;
    }
    return map;
  }

  handleDrop = ({ sourceLocations, draggedLocation, droppedLocation }) => {
    const { isPlate } = this.props.containerArray.containerArrayType;
    const { rowCount, columnCount: colCount } =
      this.props.containerArray.containerArrayType.containerFormat;
    const aliquotContainers = cloneDeep(this.state.aliquotContainers);
    const locToOldAcMap = createLocationToContainerMap(
      this.state.aliquotContainers
    );
    const locToAcMap = createLocationToContainerMap(aliquotContainers);

    const { columnPosition: dragCol, rowPosition: dragRow } =
      getPositionFromAlphanumericLocation(draggedLocation);
    const { columnPosition: dropCol, rowPosition: dropRow } =
      getPositionFromAlphanumericLocation(droppedLocation);

    const deltaCol = dropCol - dragCol;
    const deltaRow = dropRow - dragRow;

    const dstLocations = sourceLocations.map(srcLoc => {
      if (isPlate) {
        locToAcMap[srcLoc].aliquot = null;
      }

      const { columnPosition: srcCol, rowPosition: srcRow } =
        getPositionFromAlphanumericLocation(srcLoc);

      const dstCol = (srcCol + deltaCol + colCount) % colCount;
      const dstRow = (srcRow + deltaRow + rowCount) % rowCount;

      const dstLoc = rowIndexToLetter(dstRow) + (dstCol + 1);
      return dstLoc;
    });

    sourceLocations.forEach((srcLoc, i) => {
      const dstLoc = dstLocations[i];
      if (isPlate) {
        locToAcMap[dstLoc].aliquot = locToOldAcMap[srcLoc].aliquot;
      } else {
        const srcAc = locToOldAcMap[srcLoc];
        if (srcAc.id) {
          const sourceIndex = aliquotContainers.findIndex(
            ac => ac.id === srcAc.id
          );
          const destIndex = aliquotContainers.indexOf(locToAcMap[dstLoc]);
          if (sourceIndex === destIndex) return;
          aliquotContainers[sourceIndex] = {
            rowPosition: srcAc.rowPosition,
            columnPosition: srcAc.columnPosition
          };
          const { rowPosition, columnPosition } = locToAcMap[dstLoc];
          aliquotContainers[destIndex] = {
            ...srcAc,
            rowPosition,
            columnPosition
          };
        }
      }
    });
    this.handleSelectLocations(dstLocations);
    this.setState({ aliquotContainers });
  };

  handleSavePlateReformat = async () => {
    const { containerArray } = this.props;
    const { aliquotContainers: acs } = this.state;
    const onFinish = () => {
      this.setState({
        reformattingPlate: false,
        aliquotContainers: []
      });
    };
    return handlePlateReformat({
      containerArray,
      newAliquotContainers: acs,
      onFinish
    });
  };

  getAllAliquotIds(aliquotContainers) {
    const acWithAliquots = aliquotContainers.filter(ac =>
      get(ac, "aliquot.aliquot.id")
    );
    return Object.keys(keyBy(acWithAliquots, "aliquot.id"));
  }

  refetchExtendedProperties = async () => {
    const { refetchExtendedProperties, refetchContainerArray } = this.props;
    await refetchContainerArray();
    await refetchExtendedProperties();
  };

  getEntities() {
    return getPlateRecordEntities(this.props.containerArray, this.sortOrder);
  }

  get sortOrder() {
    return this.state.sortColumnFirst ? "columnFirst" : "rowFirst";
  }

  handleSelectLocations = async locations => {
    this.setState({
      selectedLocations: locations
    });
  };

  renderContent(containerArray) {
    if (this.props.containerArrayLoading) return;
    const {
      hasAliquotExtProps,
      aliquotExtendedPropertyFields,
      plateLayers = [],
      aliquotContainersLoading
    } = this.props;

    const readOnly = aliquotContainersLoading;
    const { selectedLocations, sortColumnFirst, reformattingPlate } =
      this.state;

    if (containerArray) {
      const entities = this.getEntities();

      const aliquotContainersFilled = generateContainerArray(
        containerArray.aliquotContainers,
        containerArray.containerArrayType.containerFormat
      );

      const schemaToUse = getPlateTableSchema({
        containerArray,
        hasAliquotExtProps,
        aliquotExtendedPropertyFields
      });

      return (
        <MediaQuery minHeight={1200}>
          {tallScreen => {
            return (
              <div
                style={{
                  marginTop: 20,
                  ...(tallScreen && {
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden"
                  })
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}
                >
                  {this.renderRecordHeaderInfoSection(containerArray)}
                  <div>
                    <PlateMapPlate
                      {...{
                        isEditable: reformattingPlate,
                        withDragSelect: !reformattingPlate,
                        selectedAliquotContainerLocations: selectedLocations,
                        onWellsSelected: this.handleSelectLocations,
                        containerArrayType: containerArray.containerArrayType,
                        aliquotContainers: reformattingPlate
                          ? this.state.aliquotContainers
                          : aliquotContainersFilled,
                        ...(!readOnly && {
                          aliquotContainerDoubleClick:
                            this.aliquotContainerDoubleClick,
                          aliquotContextMenu: ac => this.aliquotContextMenu(ac),
                          onDrop: this.handleDrop
                        }),
                        plateLayers
                      }}
                    />
                    {!!reformattingPlate && (
                      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <ButtonGroup minimal>
                          <Button
                            intent={Intent.DANGER}
                            text="Cancel"
                            onClick={() =>
                              this.setState({
                                reformattingPlate: false,
                                aliquotContainers: []
                              })
                            }
                          />
                          <Button
                            intent={Intent.SUCCESS}
                            text="Save Changes"
                            onClick={this.handleSavePlateReformat}
                          />
                        </ButtonGroup>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="plate-wells-table-view width100"
                  style={
                    tallScreen
                      ? {
                          flex: 1,
                          overflow: "hidden",
                          marginTop: 25
                        }
                      : {
                          margin: "25px 0"
                        }
                  }
                >
                  <DataTable
                    formName="PlateRecordViewAliquotsTable"
                    maxHeight={tallScreen ? null : undefined}
                    entities={entities}
                    schema={schemaToUse}
                    isLoading={aliquotContainersLoading}
                    contextMenu={({ selectedRecords }) => {
                      if (readOnly) return [];
                      else {
                        return this.aliquotContextMenu(
                          selectedRecords[0],
                          true
                        );
                      }
                    }}
                    isInfinite
                    noPadding
                    withDisplayOptions
                    showCount
                    onRowSelect={this.handleDataTableSelect}
                    onDoubleClick={this.aliquotContainerDoubleClick}
                    cellRenderer={plateRecordCellRenderer()}
                    extraClasses="plate-well-table"
                  >
                    <Button
                      text="Sort Column First"
                      active={sortColumnFirst}
                      onClick={this.toggleSortColumnFirst}
                    />
                  </DataTable>
                </div>
              </div>
            );
          }}
        </MediaQuery>
      );
    }
  }

  handleDataTableSelect = containers => {
    if (!containers) containers = [];
    if (!Array.isArray(containers)) containers = [containers];

    const locations = getAlphnumericWellLocation(containers);
    this.handleSelectLocations(locations);
  };

  updateShowFunction = () => {
    const { containerArray } = this.props;
    showDialog({
      modalType: "UPDATE_PLATE",
      modalProps: {
        plateId: containerArray.id
      }
    });
  };

  getAttachmentButton() {
    const { containerArray } = this.props;
    const { detachingPlate } = this.state;
    if (!containerArray) return;
    const buttons = [];
    if (containerArray.collectionPlate || containerArray.containerArray) {
      buttons.push(
        <Button
          key="detach"
          icon="unlock"
          loading={detachingPlate}
          text="Detach Plate"
          onClick={this.handleDetachClick}
        />
      );
    } else if (get(containerArray, "containerArrayType.isColumn")) {
      buttons.push(
        <Button
          key="attach"
          icon="lock"
          text="Attach Plate"
          onClick={this.handleAttachClick}
        />
      );
    }
    return buttons;
  }

  handleDetachClick = async () => {
    const { containerArray } = this.props;
    const isColumn = get(containerArray, "containerArrayType.isColumn");
    const shouldDetach = await showConfirmationDialog({
      text: `Are you sure you want to detach ${get(
        containerArray,
        "name"
      )} from its ${isColumn ? "collection" : "column"} plate?`,
      intent: "warning",
      icon: "warning-sign"
    });
    this.setState({ detachingPlate: true });
    try {
      if (shouldDetach) {
        if (isColumn) {
          await safeUpsert("containerArray", {
            id: containerArray.id,
            collectionPlateId: null
          });
        } else {
          await safeUpsert("containerArray", {
            id: containerArray.containerArray.id,
            collectionPlateId: null
          });
        }
        await safeQuery(
          [
            "containerArray",
            /* GraphQL */ `
              {
                id
                collectionPlateId
                collectionPlate {
                  id
                  name
                }
                containerArray {
                  id
                  name
                }
              }
            `
          ],
          {
            variables: {
              id: containerArray.id
            }
          }
        );
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Failed to detach plate.");
    }
    if (shouldDetach) window.toastr.success("Successfully detached plate.");
    this.setState({ detachingPlate: false });
  };

  handleAttachClick = () => {
    const { containerArray } = this.props;
    if (!containerArray) return;
    showDialog({
      modalType: "ATTACH_PLATE",
      modalProps: {
        containerArray
      }
    });
  };

  getPrintButtons() {
    const { containerArray, refetchContainerArray } = this.props;
    const buttons = [
      <PrintLabelButton
        key="print"
        records={[containerArray]}
        refetch={refetchContainerArray}
      />
    ];
    if (get(containerArray, "labelFormatId")) {
      buttons.push(
        <Button
          key="reprint"
          icon="print"
          text="Reprint Label"
          onClick={this.handleReprint}
        />
      );
    }
    return buttons;
  }

  handlePrintLabelClick = async () => {
    const { containerArray, refetchContainerArray } = this.props;
    if (!containerArray) return;
    const ModalComponent = (await import("../../Dialogs/PrintLabelDialog"))
      .default;
    showDialog({
      ModalComponent,
      modalProps: {
        itemIds: [containerArray.id],
        isContainerArray: true,
        refetch: refetchContainerArray
      }
    });
  };

  handleReprint = () => {
    const { containerArray, show, refetchContainerArray } = this.props;
    if (!containerArray) return;
    handleReprint({
      id: containerArray.id,
      isContainerArray: true,
      show,
      refetch: refetchContainerArray
    });
  };

  render() {
    const {
      containerArray,
      refetchPlateLayers,
      plateLayers = [],
      hasAliquotExtProps,
      aliquotExtendedPropertyFields,
      aliquotContainersLoading
    } = this.props;
    const { reformattingPlate } = this.state;

    const aliquotContainersFilled = generateContainerArray(
      containerArray.aliquotContainers,
      containerArray.containerArrayType.containerFormat
    );

    return (
      <AbstractRecord
        {...this.props}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          height: "100%"
        }}
        readOnly={this.props.readOnly || aliquotContainersLoading}
        beforeDelete={beforeRackDelete}
        updateShowFunction={this.updateShowFunction}
        additionalUpdateMenuItems={[
          <MenuItem
            key="reformatPlate"
            text="Reformat Plate"
            disabled={reformattingPlate}
            onClick={() =>
              this.setState({
                reformattingPlate: true,
                aliquotContainers: aliquotContainersFilled
              })
            }
          />,
          <MenuItem
            key="plateLayers"
            text="Update Plate Layers"
            onClick={() => {
              let dialogProps;
              if (
                containerArray.containerArrayType.containerFormat.rowCount >= 16
              ) {
                dialogProps = {
                  style: {
                    width: 1000
                  }
                };
              }
              showDialog({
                modalType: "CREATE_PLATE_LAYER",
                modalProps: {
                  record: containerArray,
                  refetch: refetchPlateLayers,
                  dialogProps,
                  initialValues: {
                    plateLayers: getInitialPlateLayerValues(
                      plateLayers,
                      aliquotContainersFilled
                    )
                  }
                }
              });
            }}
          />,
          <MenuItem
            key="uploadAliquotExtendedProperties"
            text="Upload Aliquot Extended Properties CSV"
            onClick={() => {
              showDialog({
                ModalComponent: BulkUpdatePlateAliquotsExtendedPropertyDialog,
                modalProps: {
                  refetch: this.refetchExtendedProperties,
                  aliquotContainers: this.getEntities(),
                  containerArray: containerArray
                }
              });
            }}
          />
        ]}
        additionalButtons={[
          ...this.getAttachmentButton(),
          ...this.getPrintButtons(),
          <Popover
            key="export"
            position="bottom-right"
            modifiers={popoverOverflowModifiers}
            content={
              <Menu>
                <MenuItem
                  text="Export Plate"
                  onClick={() => {
                    showDialog({
                      ModalComponent: ExportDialog,
                      modalProps: {
                        plateIds: [containerArray.id]
                      }
                    });
                  }}
                />
                <MenuItem
                  text="Export as List CSV"
                  onClick={() => {
                    showPlateRecordTableExport({
                      containerArrays: [containerArray],
                      hasAliquotExtProps,
                      aliquotExtendedPropertyFields
                    });
                  }}
                />
                {!!containerArray.containerArrayType.isPlate && (
                  <MenuItem
                    text="Export as Plate Layout CSV"
                    onClick={() => {
                      showDialog({
                        ModalComponent: ExportAsPlateCsvDialog,
                        modalProps: {
                          sortOrder: this.sortOrder,
                          containerArrays: [containerArray]
                        }
                      });
                    }}
                  />
                )}
              </Menu>
            }
          >
            <Button
              text="Export"
              icon="export"
              loading={this.state.exporting}
            />
          </Popover>
        ]}
        recordName="containerArray"
      >
        {this.renderContent(containerArray)}
      </AbstractRecord>
    );
  }
}

export default compose(
  recordViewEnhancer(containerArrayRecordFragment),
  withQuery(plateLayerFragment, {
    isPlural: true,
    options: props => {
      const containerArrayId = props.recordIdOverride || props.match.params.id;
      return {
        variables: {
          filter: {
            containerArrayId
          }
        }
      };
    }
  }),
  hasAliquotExtendedPropsHelper(),
  withSelectTableRecords("PlateRecordViewAliquotsTable")
)(ContainerArrayRecordView);
