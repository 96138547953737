/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { wrapDialog } from "@teselagen/ui";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { InputField, DialogFooter } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { withProps } from "recompose";

class SpecifyBinOverhangDialog extends React.Component {
  onSubmit = async values => {
    const { binCardId, updateBinCard, hideModal } = this.props;

    updateBinCard({
      id: binCardId,
      rightJunctionBps: values.overhang.toUpperCase(),
      hasSpecifiedJunction: true
    });

    hideModal();
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="overhang"
            label="If you wish, enter exact bps that will be required at this assembly junction. Otherwise the junction's bps will be auto-detected."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          text="OK"
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const validate = ({ overhang = "" }, { overhangLength }) => {
  const errors = {};

  if (overhang && !overhang.toLowerCase().match(/^[acgt]+$/)) {
    errors.overhang = "Must only contain A, G, C, and T";
  }

  if (overhang && overhang.length !== overhangLength) {
    errors.overhang = `Overhang length must be ${overhangLength}, as prescribed in this reaction's Assembly Method Parameters => "Golden Gate Overhang BPs"`;
  }

  return errors;
};

export default compose(
  wrapDialog({ title: "Specify Overhang" }),
  withProps(props => ({
    initialValues: {
      overhang: props.rightJunctionBps
    }
  })),
  reduxForm({ form: "specifyBinOverhangDialog", validate })
)(SpecifyBinOverhangDialog);
