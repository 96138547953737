/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { DialogFooter, withSelectedEntities, wrapDialog } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import Page1 from "./Page1";

import Page2 from "./Page2";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { REQUIRED_ERROR } from "../../../../src-shared/utils/formUtils";
import aliquotRecordFragment from "../../../../src-shared/graphql/fragments/aliquotRecordFragment";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import PartialFormulation from "../../../../../tg-iso-lims/src/AliquotFormulation/PartialFormulation";
import executeAliquotFormulations from "../../../../../tg-iso-lims/src/AliquotFormulation/executeAliquotFormulations";

function ReplenishAliquotDialog(props) {
  const [page, setPage] = useState(1);
  const {
    hideModal,
    submitting,
    handleSubmit,
    destinationAliquot,
    change,
    aliquotsForReplenishSelectedEntities = [],
    sourceAliquot
  } = props;

  const onSubmit = async values => {
    try {
      const {
        sourceAliquot,
        volume,
        volumetricUnitCode,
        transferType
      } = values;
      const stdTransferVolume = standardizeVolume(
        volume,
        volumetricUnitCode,
        true
      );
      let transferVolume = stdTransferVolume;
      const stdDestAliquotVolume = standardizeVolume(
        destinationAliquot.volume || 0,
        destinationAliquot.volumetricUnitCode || "uL",
        true
      );
      if (transferType === "total") {
        transferVolume = stdTransferVolume.minus(stdDestAliquotVolume);
      }
      const aliquotFormulation = new PartialFormulation({
        aliquotId: destinationAliquot.id
      });

      aliquotFormulation.addTransfer(sourceAliquot.id, transferVolume, "L");

      await executeAliquotFormulations(aliquotFormulation);
      await safeQuery(aliquotRecordFragment, {
        variables: {
          id: destinationAliquot.id
        }
      });

      hideModal();
    } catch (error) {
      window.toastr.error("Error updating aliquot");
      console.error("error:", error);
    }
  };

  let footer;
  if (page === 1) {
    footer = (
      <DialogFooter
        hideModal={hideModal}
        onClick={() => {
          change("sourceAliquot", aliquotsForReplenishSelectedEntities[0]);
          setPage(page + 1);
        }}
        disabled={!aliquotsForReplenishSelectedEntities.length}
        text="Select Aliquot"
      />
    );
  } else if (page === 2) {
    footer = (
      <DialogFooter
        hideModal={hideModal}
        secondaryText="Back"
        secondaryAction={() => setPage(page - 1)}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    );
  }

  const onAliquotDoubleClick = aliquot => {
    change("sourceAliquot", aliquot);
    setPage(page + 1);
  };

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {page === 1 && (
          <Page1
            destinationAliquot={destinationAliquot}
            onDoubleClick={onAliquotDoubleClick}
          />
        )}
        {page === 2 && (
          <Page2
            destinationAliquot={destinationAliquot}
            sourceAliquot={sourceAliquot}
          />
        )}
      </div>
      {footer}
    </React.Fragment>
  );
}

function validate(values, props) {
  const { destinationAliquot } = props;
  const { volume, volumetricUnitCode, sourceAliquot, transferType } = values;
  const errors = {};

  if (!volume || Number(volume) < 0) {
    errors.volume = REQUIRED_ERROR;
  } else if (sourceAliquot) {
    let stdMaxVolume, maxVolume, maxVolumetricUnitCode;
    if (destinationAliquot.aliquotContainer) {
      const type = destinationAliquot.aliquotContainer.aliquotContainerType;
      maxVolume = type.maxVolume;
      maxVolumetricUnitCode = type.volumetricUnitCode;
      stdMaxVolume = standardizeVolume(maxVolume, maxVolumetricUnitCode);
    }

    const stdTransferVolume = standardizeVolume(volume, volumetricUnitCode);
    const stdDestAliquotVolume = standardizeVolume(
      destinationAliquot.volume || 0,
      destinationAliquot.volumetricUnitCode || "uL"
    );
    const stdSourceAliquotVolume = standardizeVolume(
      sourceAliquot.volume,
      sourceAliquot.volumetricUnitCode
    );

    if (transferType === "total") {
      if (stdMaxVolume && stdTransferVolume > stdMaxVolume) {
        errors.volume = `Please enter a value less than this aliquot well's max volume (${maxVolume} ${maxVolumetricUnitCode})`;
      } else if (stdTransferVolume <= stdDestAliquotVolume) {
        errors.volume = `The destination aliquot already has more volume than this (${destinationAliquot.volume} ${destinationAliquot.volumetricUnitCode}). No transfer necessary`;
      } else {
        const volumeToTransferFromSource =
          stdTransferVolume - stdDestAliquotVolume;
        if (volumeToTransferFromSource > stdSourceAliquotVolume) {
          errors.volume = `The source aliquot does not have enough volume for transfer (${sourceAliquot.volume} ${sourceAliquot.volumetricUnitCode})`;
        }
      }
    } else {
      if (
        stdMaxVolume &&
        stdDestAliquotVolume + stdTransferVolume > stdMaxVolume
      ) {
        errors.volume = `The well can only support a max volume of ${maxVolume} ${maxVolumetricUnitCode}.`;
      } else {
        if (stdTransferVolume > stdSourceAliquotVolume) {
          errors.volume = `The source aliquot does not have enough volume for transfer (${sourceAliquot.volume} ${sourceAliquot.volumetricUnitCode})`;
        }
      }
    }
  }
  return errors;
}

export default compose(
  wrapDialog({
    title: "Replenish Aliquot",
    style: {
      width: 750
    }
  }),
  withQuery(
    [
      "aliquot",
      `id
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      sample {
        id
        name
        materialId
      }
      aliquotContainer {
        id
        aliquotContainerType {
          code
          maxVolume
          volumetricUnitCode
        }
      }`
    ],
    {
      showLoading: true,
      inDialog: true,
      options: props => {
        return {
          variables: {
            id: props.aliquotId
          }
        };
      },
      props: props => {
        const {
          data: { aliquot }
        } = props;
        return {
          destinationAliquot: aliquot
        };
      }
    }
  ),
  withSelectedEntities("aliquotsForReplenish"),
  reduxForm({
    form: "replenishAliquotForm",
    validate
  }),
  tgFormValues("sourceAliquot")
)(ReplenishAliquotDialog);
