/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { formValues, reduxForm } from "redux-form";
import { Callout, Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import FillDirectionSelect from "../../../../FillDirectionSelect";
import DistributionAdvancedOptions from "./DistributionAdvancedOptions";

function ChooseSimpleDistributeOptions(props) {
  const {
    hideModal,
    handleSubmit,
    submitting,
    containerFormat,
    handleDistribute,
    distributionPattern
  } = props;

  async function onSubmit(values) {
    try {
      await handleDistribute(values);
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message || "Error Distributing");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="warning" style={{ marginBottom: 15 }}>
          This will clear current placement and replace all items.
        </Callout>
        <div style={{ maxWidth: 250 }}>
          <FillDirectionSelect disabled={distributionPattern} />
        </div>

        <DistributionAdvancedOptions containerFormat={containerFormat} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Distribute Options"
  }),
  reduxForm({
    form: "chooseDistSimpleForm"
  }),
  formValues("distributionPattern")
)(ChooseSimpleDistributeOptions);
