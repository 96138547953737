/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import gql from "graphql-tag";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import {
  DataTable,
  ReactSelectField,
  showConfirmationDialog,
  withSelectedEntities,
  withSelectTableRecords
} from "@teselagen/ui";
import {
  arrayToItemValuedOptions,
  throwFormError
} from "../../../../../src-shared/utils/formUtils";
import { pluralIfNeeded } from "../../../../utils";
import { Icon, Tooltip } from "@blueprintjs/core";
import { compose } from "recompose";
import SelectSequencesFromAssemblyReport from "../../../SelectSequencesFromAssemblyReport";
import bioshopDataTableFragment from "../../../../graphql/fragments/bioshopDataTableFragment";
import SelectAllScoredSequencesCheckbox from "../../../SequenceScoringComponents/SelectAllScoredSequencesCheckbox";

export const sequenceFragment = [
  "sequence",
  "id size name sequenceTypeCode updatedAt"
];

const twistOrderingReactionMapFragment = gql`
  fragment twistOrderingReactionMapFragment on reactionMap {
    id
    name
    reactions {
      id
      reactionInputs {
        id
        inputMaterial {
          id
          name
          materialTypeCode
          polynucleotideMaterialSequence {
            id
          }
        }
      }
    }
  }
`;

function sequenceIsValid(seq) {
  return seq.sequenceTypeCode === "LINEAR_DNA";
}

class SelectDNA extends Component {
  state = {
    loadingSequencesFromTable: false
  };
  componentDidMount() {
    const {
      integrations,
      stepFormProps: { change }
    } = this.props;
    if (integrations.length === 1) {
      change("twistIntegration", integrations[0]);
    }
  }
  beforeNextStep = async values => {
    const { twistIntegration } = values;
    const {
      nextStep,
      stepFormProps: { change },
      reactionMaps = [],
      twistOrderinTableSequencesSelectedEntities: selectedTableSequences = []
    } = this.props;
    const { sequences = [], sequencesFromAssemblyReports = [] } = values;
    const incomingDnaSequenceIds = sequences
      .concat(selectedTableSequences)
      .concat(sequencesFromAssemblyReports)
      .map(s => s.id);

    if (reactionMaps.length) {
      try {
        const fullReactionMaps = await safeQuery(
          twistOrderingReactionMapFragment,
          {
            variables: {
              filter: {
                id: reactionMaps.map(d => d.id)
              }
            }
          }
        );
        fullReactionMaps.forEach(rm => {
          rm.reactions.forEach(r => {
            r.reactionInputs.forEach(ri => {
              const seqId =
                ri.inputMaterial?.polynucleotideMaterialSequence?.id;
              if (seqId) {
                incomingDnaSequenceIds.push(seqId);
              }
            });
          });
        });
      } catch (error) {
        console.error(`error:`, error);
        return window.toastr.error("Error loading reaction maps");
      }
    }
    if (incomingDnaSequenceIds.length) {
      let allSequences = [];
      try {
        allSequences = await safeQuery(
          [
            "sequence",
            /* GraphQL */ `
              {
                id
                name
                size
                sequenceTypeCode
                circular
                sequenceFragments {
                  id
                  fragment
                  index
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: incomingDnaSequenceIds
              }
            }
          }
        );
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.error("Error loading full sequence info.");
        return;
      }
      const invalidSeqs = [];
      const sequencesToScore = allSequences.filter(s => {
        const valid = sequenceIsValid(s);
        if (!valid) {
          invalidSeqs.push(s.name);
        }
        return valid;
      });
      if (invalidSeqs.length) {
        const errorMsg = `These sequences are not linear DNA: ${invalidSeqs.join(
          ", "
        )}`;
        if (sequencesToScore.length) {
          // if only some are invalid offer to filter them out
          const continueTool = await showConfirmationDialog({
            text:
              errorMsg +
              `\n\nWould you like to continue with the remaining ${
                sequencesToScore.length
              } ${pluralIfNeeded("sequence", sequencesToScore)}?`,
            confirmButtonText: "Continue"
          });
          if (!continueTool) {
            return;
          }
        } else {
          throwFormError(errorMsg);
        }
      }
      change("sequencesToScore", sequencesToScore);
    } else {
      return window.toastr.error("No sequences found.");
    }

    try {
      const endpoint = twistIntegration.integrationEndpoints.find(
        e => e.endpointTypeCode === "VENDOR__VECTORS"
      );
      if (!endpoint) throw new Error("No endpoint for vectors");
      const { data: result } = await window.triggerIntegrationRequest({
        endpointId: endpoint.id,
        method: "GET"
      });
      change("vectorOptions", result.options);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error loading vendor vectors.");
      return;
    }
    try {
      const endpoint = twistIntegration.integrationEndpoints.find(
        e => e.endpointTypeCode === "VENDOR__PRICEBOOK"
      );
      if (!endpoint) throw new Error("No endpoint for pricebook");
      const { data: result } = await window.triggerIntegrationRequest({
        endpointId: endpoint.id,
        method: "GET"
      });
      change("pricebook", result.pricebook || []);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error loading price list.");
      return;
    }
    nextStep();
  };

  loadSequencesFromTable = async dataTables => {
    const {
      stepFormProps: { change },
      selectTableRecords
    } = this.props;
    this.setState({
      loadingSequencesFromTable: true
    });

    if (dataTables.length) {
      const fullDataTables = await safeQuery(bioshopDataTableFragment, {
        variables: {
          filter: {
            id: dataTables.map(d => d.id)
          }
        }
      });
      const sequenceIds = [];
      for (const dataTable of fullDataTables) {
        if (dataTable.dataTableTypeCode === "SEQUENCE_ORDER") {
          dataTable.dataRows.forEach(dataRow =>
            sequenceIds.push(dataRow.rowValues.sequenceId)
          );
        } else if (dataTable.dataTableTypeCode === "J5_ASSEMBLY_PIECE_LIST") {
          dataTable.dataRows.forEach(dataRow => {
            const sequenceId =
              dataRow.dataRowJ5Items[0].j5Item.j5AssemblyPiece.sequenceId;
            sequenceIds.push(sequenceId);
          });
        }
      }
      if (sequenceIds.length) {
        const sequences = await safeQuery(
          [
            "sequence",
            "id name size sequenceTypeCode sequenceType { code name }"
          ],
          {
            variables: {
              filter: {
                id: sequenceIds
              }
            }
          }
        );
        change("sequencesFromTable", sequences);
        const validSeqs = sequences.filter(sequenceIsValid);
        selectTableRecords(validSeqs);
      } else {
        window.toastr.warning("No sequences found on tables.");
      }
    }
    this.setState({
      loadingSequencesFromTable: false
    });
  };

  clearTableSequences = () => {
    this.props.stepFormProps.change("sequencesFromTable", []);
  };

  render() {
    const { loadingSequencesFromTable } = this.state;
    const {
      Footer,
      footerProps,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      integrations = [],
      sequencesFromTable = [],
      dataTables = [],
      stepFormProps: { change },
      twistOrderinTableSequencesSelectedEntities: selectedTableSequences = [],
      sequencesFromAssemblyReports = [],
      sequences = [],
      reactionMaps = [],
      handleSubmit,
      toolSchema
    } = this.props;

    return (
      <React.Fragment>
        {integrations.length > 1 && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Select Twist Integration"
              helper="Select Twist integration for ordering DNA"
            />
            <div style={{ maxWidth: 250 }}>
              <ReactSelectField
                name="twistIntegration"
                label="Integration"
                options={arrayToItemValuedOptions(integrations)}
                isRequired
              />
            </div>
          </div>
        )}
        <div className="tg-step-form-section column">
          <SelectAllScoredSequencesCheckbox />
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Data Tables"
            helper="Select tables of primary templates or assembly pieces to help choose sequences."
          />
          <GenericSelect
            {...{
              name: "dataTables",
              schema: ["name", dateModifiedColumn],
              buttonProps: {
                disabled: isDisabledMap.dataTables,
                loading: isLoadingMap.dataTables
              },
              isMultiSelect: true,
              onClear: this.clearTableSequences,
              onSelect: this.loadSequencesFromTable,
              fragment: ["dataTable", "id name updatedAt"],
              tableParamOptions: {
                additionalFilter: {
                  dataTableTypeCode: [
                    "J5_ASSEMBLY_PIECE_LIST",
                    "SEQUENCE_ORDER"
                  ]
                }
              }
            }}
          />
          {!!dataTables.length && (
            <DataTable
              isLoading={loadingSequencesFromTable}
              entities={sequencesFromTable}
              formName="twistOrderinTableSequences"
              withCheckboxes
              isEntityDisabled={r => !sequenceIsValid(r)}
              schema={[
                {
                  type: "action",
                  width: 50,
                  render: (v, r) => {
                    if (!sequenceIsValid(r)) {
                      return (
                        <Tooltip content="Only linear DNA can be ordered.">
                          <Icon intent="warning" icon="warning-sign" />
                        </Tooltip>
                      );
                    }
                  }
                },
                "name",
                {
                  displayName: "Length",
                  type: "number",
                  path: "size"
                },
                {
                  displayName: "Type",
                  path: "sequenceType.name"
                }
              ]}
            />
          )}
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Reaction Maps"
            helper="Select reaction maps of materials to help choose sequences."
          />
          <GenericSelect
            {...{
              name: "reactionMaps",
              schema: ["name", dateModifiedColumn],
              buttonProps: {
                disabled: isDisabledMap.reactionMaps,
                loading: isLoadingMap.reactionMaps
              },
              isMultiSelect: true,
              fragment: ["reactionMap", "id name updatedAt"],
              postSelectDTProps: {
                formName: "selectedReactionMaps",
                schema: ["name", dateModifiedColumn]
              }
            }}
          />
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Sequences"
            helper="Select linear sequences from inventory."
          />
          <GenericSelect
            {...{
              name: "sequences",
              schema: [
                "name",
                { displayName: "Length", path: "size", type: "number" },
                dateModifiedColumn
              ],
              buttonProps: {
                disabled: isDisabledMap.sequences,
                loading: isLoadingMap.sequences
              },
              isMultiSelect: true,
              fragment: sequenceFragment,
              tableParamOptions: {
                additionalFilter: {
                  sequenceTypeCode: "LINEAR_DNA"
                }
              },
              postSelectDTProps: {
                formName: "selectedSequences",
                schema: [
                  "name",
                  { displayName: "Length", path: "size" },
                  dateModifiedColumn
                ]
              }
            }}
          />
        </div>
        <div className="tg-step-form-section column">
          <SelectSequencesFromAssemblyReport
            toolSchema={toolSchema}
            change={change}
          />
        </div>
        <Footer
          {...footerProps}
          nextDisabled={
            !selectedTableSequences.length &&
            !sequences.length &&
            !sequencesFromAssemblyReports.length &&
            !reactionMaps.length
          }
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "twistIntegration",
    "sequencesFromTable",
    "reactionMaps",
    "sequencesFromAssemblyReports",
    "dataTables",
    "sequences"
  ),
  withSelectedEntities("twistOrderinTableSequences"),
  withSelectTableRecords("twistOrderinTableSequences")
)(SelectDNA);
