/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { createTaggedItems } from "../../../../src-shared/utils/tagUtils";
import { safeQuery } from "../../../../src-shared/apolloMethods";

export async function cascadeCustomerRequestTagsToJobsAndWorkflow({
  customerRequestIds: _customerRequestIds = [],
  jobIds: _jobIds = [],
  selectedTags
}) {
  let jobIds = [],
    workflowRunIds = [];
  if (_jobIds.length) {
    jobIds = _jobIds;
    const workflowRuns = await safeQuery(["workflowRun", "id"], {
      variables: {
        filter: {
          jobId: jobIds
        }
      }
    });
    workflowRunIds = workflowRuns.map(w => w.id);
  } else {
    const fragment = gql`
      fragment customerRequestAfterTagFragment on customerRequest {
        id
        customerRequestJobs {
          id
          job {
            id
            workflowRuns {
              id
            }
          }
        }
      }
    `;
    const customerRequestsWithJobs = await safeQuery(fragment, {
      variables: {
        filter: {
          id: _customerRequestIds
        }
      }
    });
    jobIds = [];
    workflowRunIds = [];
    customerRequestsWithJobs.forEach(customerRequestWithJob => {
      customerRequestWithJob.customerRequestJobs.forEach(c => {
        jobIds.push(c.job.id);
        c.job.workflowRuns.forEach(wr => {
          workflowRunIds.push(wr.id);
        });
      });
    });
  }

  await createTaggedItems({
    selectedTags,
    recordIds: jobIds,
    model: "job"
  });
  await createTaggedItems({
    selectedTags,
    recordIds: workflowRunIds,
    model: "workflowRun"
  });
}
