/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import "@blueprintjs/table/lib/css/table.css";

// META DATA
import AddMetaData from "./views/dialogs/addMetaData/index";
import ViewMetaData from "./views/dialogs/viewMetaData/index";

export const mobXModalComponents = {
  //REFORMAT ACTIONS
  ADD_META_DATA: AddMetaData,
  VIEW_META_DATA: ViewMetaData
};
