/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useRef } from "react";
import { DataTable, convertSchema } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import classNames from "classnames";
import { nanoid } from "nanoid";
import GenericSelect from "../GenericSelect";
import { compose } from "recompose";
import { Popover } from "@blueprintjs/core";
import { isEmpty } from "lodash";
import { isFunction } from "lodash";

// export const addEditableTableEntities = (entities, formName) => {
//   const formToUse = formName || "editableCellTable";
//   const reduxFormEntities =
//     formValueSelector(formToUse)(
//       window.teGlobalStore.getState(),
//       "reduxFormEntities"
//     ) || [];
//   window.teGlobalStore.dispatch(
//     change(
//       formToUse,
//       "reduxFormEntities",
//       reduxFormEntities.concat(
//         entities.map(e => ({ ...e, id: e.id || nanoid(), _isClean: false }))
//       )
//     )
//   );
// };

// export const getEditableTableInfoAndThrowFormError = formName => {
//   const { reduxFormEntities, reduxFormCellValidation } = formValueSelector(
//     formName || "editableCellTable"
//   )(
//     window.teGlobalStore.getState(),
//     "reduxFormEntities",
//     "reduxFormCellValidation"
//   );
//   const { entsToUse, validationToUse } = removeCleanRows(
//     reduxFormEntities,
//     reduxFormCellValidation
//   );
//   validateTableWideErrors({ entsToUse, schema, validationToUse })

//   console.log(`validationToUse:`, validationToUse);
//   console.log(`entsToUse:`, entsToUse);
//   if (!entsToUse?.length) {
//     throwFormError(
//       "Please add at least one row to the table before submitting."
//     );
//   }
//   const invalid =
//     isEmpty(validationToUse) || !some(validationToUse, v => v)
//       ? undefined
//       : validationToUse;

//   console.log(`invalid:`, invalid);
//   if (invalid) {
//     throwFormError("Please fix the errors in the table before submitting.");
//   }

//   return entsToUse;
// };
const initialEnts = [{}, {}, {}];

export const TgEditableTable = compose(reduxForm({ form: "TgEditableTable" }))(
  function({ entsToInitialize = initialEnts, schema, tableProps }) {
    // comp did mount hook
    const schemaToUse = useRef(schema);
    const entsToUse = useRef(entsToInitialize);

    // const [initing, setIniting] = useState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {}, []);

    const s = convertSchema(schema);

    entsToUse.current =
      entsToInitialize &&
      entsToInitialize.length &&
      entsToInitialize.map((row, i) => {
        const toRet = {
          _isClean: isEmpty(row) ? true : row._isClean
        };
        s.fields.forEach(({ path, defaultValue }) => {
          if (toRet[path] === undefined || toRet[path] === "") {
            if (defaultValue) {
              if (isFunction(defaultValue)) {
                toRet[path] = defaultValue(i, row);
              } else toRet[path] = defaultValue;
            } else {
              // const exampleToUse = isArray(example) //this means that the row was not added by a user
              //   ? example[i1]
              //   : i1 === 0 && example;
              // toRet[path]
              // if (useExampleData && exampleToUse) {
              //   toRet[path] = exampleToUse;
              //   delete toRet._isClean;
              // } else {
              // }
            }
          }
        });

        if (row.id === undefined) {
          toRet.id = nanoid();
        } else {
          toRet.id = row.id;
        }
        // console.log(`toRet:`, toRet);
        return toRet;
      });

    s.fields.forEach(f => {
      if (f.type === "genericSelect") {
        f.render = toSelect => {
          const toRet = toSelect
            ?.map?.(s =>
              f.schema
                .map(schemaVal => {
                  return s[schemaVal?.path || schemaVal];
                })
                .join(" - ")
            )
            ?.join(", ");
          return toRet;
        };
        f.validate = v => {
          if (f.isRequired && (!v || v.length === 0)) {
            return "Please choose one of the accepted values";
          }
        };
        f.GenericSelectComp = ({
          rowId,
          fullValue,
          finishEdit,
          dataTest,
          cancelEdit
        }) => {
          const containerClassName = classNames(
            "tg-dropdown-cell-edit-container",
            {
              "tg-dropdown-cell-edit-container-multi": f.isMultiSelect
            }
          );
          return (
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <Popover //tnw - maybe we want this popover here or maybe we can do better..?
                minimal
                usePortal
                position="bottom-left"
                children={<div></div>}
                content={
                  <div style={{ maxWidth: 300 }}>
                    <GenericSelect
                      defaultValue={fullValue}
                      dataTest={dataTest}
                      handleOpenChange={isOpen => {
                        if (!isOpen) cancelEdit();
                      }}
                      onFieldSubmit={toSelect => {
                        finishEdit(toSelect, true);
                      }}
                      autoOpen
                      placeholder={" "}
                      noMarginBottom
                      asReactSelect
                      name={f.path + "__" + rowId}
                      {...f}
                      label={undefined}
                      containerClassName={containerClassName}
                    />
                  </div>
                }
                isOpen
              ></Popover>
            </div>
          );
        };
      }
    });
    // setIniting(false);
    schemaToUse.current = s;
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // if (initing) return "initializing...";
    return (
      <DataTable
        formName="editableCellTable"
        isSimple
        doNotValidateUntouchedRows
        isCellEditable
        entities={entsToUse.current}
        schema={schemaToUse.current}
        {...tableProps}
      ></DataTable>
    );
  }
);
