/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import HeaderIcon, { renderHeaderIconInner, prettifyName } from "./HeaderIcon";

import {
  Tooltip,
  Position,
  Icon,
  Classes,
  MenuItem,
  Menu,
  Button
} from "@blueprintjs/core";
import { hotkeysById } from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";

import "./style.css";
import { map } from "lodash";
import { compose } from "recompose";
import actions from "../../../src-shared/redux/actions";
import { connect } from "react-redux";
import { hideDialog } from "../../../src-shared/GlobalDialog";

const getIconHotkey = (icon, hotkeys) => {
  const id = `Icon_${icon.name}`;
  const getHotkeyById = hotkeysById(hotkeys, "raw");
  return !hotkeys[id] ? "" : getHotkeyById(id);
};

class SbolIconHeader extends React.Component {
  state = {
    sbolMode: true
  };

  toggleSbol = () => this.setState({ sbolMode: !this.state.sbolMode });

  render() {
    const { icons = [], hotkeys } = this.props;

    return (
      <div className="simplified-sbol-icons-header">
        <div className="icons-container">
          <div className="bp3-button-group bp3-minimal">
            {icons
              .filter(i => i.isSbol === this.state.sbolMode)
              .map(icon => (
                <HeaderIcon
                  icon={icon}
                  key={icon.id}
                  hotkey={getIconHotkey(icon, hotkeys)}
                  size={40}
                />
              ))}
          </div>
        </div>
        <Tooltip
          position={Position.BOTTOM_RIGHT}
          content={
            this.state.sbolMode === true
              ? "Show custom (i.e. non-SBOL) icons only"
              : "Show SBOL icons only"
          }
        >
          <Button
            minimal
            onClick={this.toggleSbol}
            className="sbol-ribbon-button"
          >
            <Icon icon="chevron-left" />
            <span>
              {this.state.sbolMode === true ? "SHOW CUSTOM" : "SHOW SBOL"}
            </span>
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default SbolIconHeader;

export const SbolIconDialog = compose(
  connect(null, {
    setSelectedBinsIcon: actions.design.setSelectedBinsIcon
  }),
  wrapDialog({ title: "Select SBOL Icon" })
)(({ icons, setSelectedBinsIcon }) => {
  return (
    <div className={Classes.DIALOG_BODY}>
      <Menu>
        {map(
          [...icons].sort((a, b) => (a.name || "").localeCompare(b.name || "")),
          icon => {
            return (
              <MenuItem
                onClick={() => {
                  hideDialog();
                  setSelectedBinsIcon({ icon });
                }}
                className="sbol-icon-row"
                text={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {renderHeaderIconInner(icon)}
                    <div style={{ marginLeft: 10 }} />
                    {prettifyName(icon.name)}
                  </div>
                }
              ></MenuItem>
            );
          }
        )}
      </Menu>
    </div>
  );
});
