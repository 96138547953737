/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import unitGlobals from "../../../tg-iso-lims/src/unitGlobals";
import isValidPositiveNumber from "./isValidPositiveNumber";

const volumeValues = () => Object.keys(unitGlobals.volumetricUnits);
const concentrationValues = () => Object.keys(unitGlobals.concentrationUnits);
const massValues = () => Object.keys(unitGlobals.massUnits);
const molarityValues = () => Object.keys(unitGlobals.molarityUnits);

const validateVolume = v =>
  !isValidPositiveNumber(v) ? "Must be a positive number" : undefined;

export const volumeField = {
  path: "volume",
  type: "number",
  validate: validateVolume,
  example: "50"
};

export const maxVolumeField = {
  ...volumeField,
  path: "maxVolume",
  isRequired: true
};

export const volumetricUnitCodeField = {
  path: "volumetricUnitCode",
  type: "dropdown",
  requireIf: "volume",
  values: volumeValues,
  description: "Unit of the volume. Required if volume is provided",
  example: "mL"
};

export const massUnitCodeField = {
  path: "massUnitCode",
  type: "dropdown",
  requireIf: "mass",
  values: massValues,
  description: "Unit of the mass. Required if mass is provided",
  example: "g"
};

export const molarityUnitCodeField = {
  path: "molarityUnitCode",
  type: "dropdown",
  requireIf: "molarity",
  values: molarityValues,
  description: "Unit of the molarity. Required if molarity is provided",
  example: "M"
};

export const concentrationUnitCodeField = {
  path: "concentrationUnitCode",
  type: "dropdown",
  values: concentrationValues,
  requireIf: "concentration",
  description: "Unit of the volume. Required if volume is provided",
  example: "g/L"
};

export const sequenceTypeField = {
  path: "SEQUENCE_TYPE",
  example: "CIRCULAR_DNA",
  type: "dropdown",
  requireIf: "SEQUENCE",
  format: v => {
    let toRet = v || "";
    toRet = toRet ? toRet.toUpperCase() : toRet;
    toRet = toRet.trim().replace(/ /g, "_");
    if (toRet === "LINEAR") {
      toRet = "LINEAR_DNA";
    }
    if (toRet === "CIRCULAR") {
      toRet = "CIRCULAR_DNA";
    }
    return toRet;
  },
  values: ["CIRCULAR_DNA", "LINEAR_DNA", "OLIGO"],
  description:
    "Required if sequence type is not provided by sequence file. Please select one of the following for sequence types: CIRCULAR_DNA, OLIGO, LINEAR_DNA"
};

export const volumeAndDeadVolumeFields = ({ type }) => [
  { ...maxVolumeField, description: `The maximum volume of the ${type}` },
  {
    ...volumetricUnitCodeField,
    displayName: "Max Volume Unit",
    description:
      "The unit of measurement for the max volume. Required if max volume is provided",
    isRequired: true
  },
  {
    path: "deadVolume",
    type: "number",
    validate: deadVolume =>
      deadVolume && !isValidPositiveNumber(deadVolume)
        ? "Must be a positive number"
        : undefined,
    description: `Amount of volume that cannot be extracted from ${type}.`,
    example: "2"
  },
  {
    path: "deadVolumetricUnitCode",
    type: "dropdown",
    values: volumeValues,
    requireIf: "deadVolume",
    displayName: "Dead Volume Unit",
    description:
      "The unit of measurement for the dead volume. Required if specifying dead volume",
    example: "mL"
  }
];

export const wellLocationField = {
  path: "WELL_LOCATION",
  alternatePathMatch: ["WELL_POSITION", "WELL"],
  description: "The location of the well in the plate.",
  example: "A1",
  isRequired: true
};

export const totalVolumeFields = notRequired => [
  {
    path: "TOTAL_VOLUME",
    description: "The total volume of the sample.",
    example: 1,
    isRequired: !notRequired,
    type: "number"
  },
  {
    ...volumetricUnitCodeField,
    path: "TOTAL_VOLUMETRIC_UNIT",
    description: "The unit of the total volume.",
    isRequired: !notRequired,
    requireIf: "TOTAL_VOLUME"
  }
];
