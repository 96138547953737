/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { get, isEmpty } from "lodash";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { additionalFilterForTubes } from "../../../../utils/plateUtils";

import { plateFragment, tubeFragment } from "../fragments";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import { Tooltip, Icon } from "@blueprintjs/core";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

const aliquotAppropriateMaterialError = aliquot => {
  const material = get(aliquot, "sample.material");
  if (aliquot && !aliquot.sample) {
    return "No sample on aliquot.";
  }
  if (material) {
    if (
      material.materialTypeCode !== "MICROBIAL" &&
      material.materialTypeCode !== "CELL_CULTURE"
    ) {
      return "Must be linked to a microbial material or cell culture.";
    }
    if (material.materialTypeCode === "MICROBIAL") {
      if (material.microbialMaterialMicrobialMaterialPlasmids.length > 1) {
        return "Material contains multiple plasmids. Should be linked to a single plasmid.";
      }
      if (!material.microbialMaterialMicrobialMaterialPlasmids.length) {
        return "This aliquot does not contain microbial material with a plasmid.";
      }
    } else if (material.materialTypeCode === "CELL_CULTURE") {
      if (material.cellCultureCellCulturePlasmids.length > 1) {
        return "Material contains multiple plasmids. Should be linked to a single plasmid.";
      }
      if (!material.cellCultureCellCulturePlasmids.length) {
        return "This aliquot does not contain a cell culture with a plasmid.";
      }
    }
  } else if (aliquot.sample.sampleTypeCode === "FORMULATED_SAMPLE") {
    if (!aliquot.sample.sampleFormulations.length) {
      return "No sample formulations found.";
    }
    for (const sf of aliquot.sample.sampleFormulations) {
      for (const mc of sf.materialCompositions) {
        if (
          mc.material.materialTypeCode !== "MICROBIAL" &&
          mc.material.materialTypeCode !== "CELL_CULTURE"
        ) {
          return "This pooled sample has non-microbial materials";
        } else if (
          mc.material.microbialMaterialMicrobialMaterialPlasmids.length > 1
        ) {
          return `Material ${mc.material.name} contains multiple plasmids. Should be linked to a single plasmid of DNA.`;
        } else if (
          !mc.material.microbialMaterialMicrobialMaterialPlasmids.length
        ) {
          return `Material ${mc.material.name} does not have plasmid DNA.`;
        } else if (mc.material.cellCultureCellCulturePlasmids.length > 1) {
          return `Material ${mc.material.name} contains multiple plasmids. Should be linked to a single plasmid of DNA.`;
        } else if (
          !mc.material.microbialMaterialMicrobialMaterialPlasmids.length
        ) {
          return `Material ${mc.material.name} does not have plasmid DNA.`;
        }
      }
    }
  } else if (!material) {
    return "Not linked to a material.";
  }
};

const SelectDonorCells = ({
  Footer,
  footerProps,
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  containerArrays = [],
  aliquotContainers = []
}) => {
  const aliquotContainerErrorMap = useMemo(() => {
    const aliquotContainerErrorMap = {};
    aliquotContainers.forEach(ac => {
      if (ac.aliquot) {
        const error = aliquotAppropriateMaterialError(ac.aliquot);
        if (error) {
          aliquotContainerErrorMap[ac.id] = error;
        }
      } else {
        aliquotContainerErrorMap[ac.id] = "No aliquot found in tube.";
      }
    });
    return aliquotContainerErrorMap;
  }, [aliquotContainers]);

  const containerArrayErrorMap = useMemo(() => {
    const containerArrayErrorMap = {};

    containerArrays.forEach(containerArray => {
      let noAliquots = true;
      containerArray.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          noAliquots = false;
          const error = aliquotAppropriateMaterialError(ac.aliquot);
          if (error) {
            if (!containerArrayErrorMap[containerArray.id]) {
              containerArrayErrorMap[containerArray.id] = {};
            }
            containerArrayErrorMap[containerArray.id][
              getAliquotContainerLocation(ac, { force2D: true })
            ] = error;
          }
        }
      });

      if (noAliquots) {
        containerArrayErrorMap[containerArray.id] = {
          _error: "No aliquots on plate."
        };
      }
    });
    return containerArrayErrorMap;
  }, [containerArrays]);

  const invalidInputs =
    !isEmpty(aliquotContainerErrorMap) || !isEmpty(containerArrayErrorMap);

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Input Plates/Racks"
          helper="Select plates or racks of tubes of donor cells of microbial materials containing one plasmid DNA material."
        />
        <GenericSelect
          name="containerArrays"
          schema={[
            "name",
            { displayName: "Barcode", path: "barcode.barcodeString" },
            dateModifiedColumn
          ]}
          isMultiSelect
          buttonProps={{
            disabled: isDisabledMap.containerArrays,
            loading: isLoadingMap.containerArrays
          }}
          fragment={[
            "containerArray",
            "id name barcode { id barcodeString } updatedAt"
          ]}
          additionalDataFragment={plateFragment}
          postSelectDTProps={{
            formName: "bacterialConjugationSelectPlates",
            containerArrayErrorMap,
            schema: [
              platePreviewColumn({
                plateErrors: containerArrayErrorMap
              }),
              "name",
              {
                displayName: "Barcode",
                path: "barcode.barcodeString"
              }
            ]
          }}
        />
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Tubes"
          helper="Select tubes of donor cells of microbial materials containing one plasmid DNA material."
        />
        <GenericSelect
          name="aliquotContainers"
          schema={[
            "name",
            { displayName: "Barcode", path: "barcode.barcodeString" },
            dateModifiedColumn
          ]}
          isMultiSelect
          buttonProps={{
            disabled: isDisabledMap.aliquotContainers,
            loading: isLoadingMap.aliquotContainers
          }}
          fragment={[
            "aliquotContainer",
            "id name barcode { id barcodeString } updatedAt"
          ]}
          additionalDataFragment={tubeFragment}
          tableParamOptions={{
            additionalFilter: additionalFilterForTubes
          }}
          postSelectDTProps={{
            formName: "bacterialConjugationSelectTubes",
            aliquotContainerErrorMap,
            schema: [
              {
                type: "action",
                width: 35,
                render: (_, record) => {
                  const error = aliquotContainerErrorMap[record.id];
                  if (error) {
                    return (
                      <Tooltip content={error}>
                        <Icon
                          intent="danger"
                          style={{ marginRight: 10 }}
                          icon="warning-sign"
                        />
                      </Tooltip>
                    );
                  }
                }
              },
              "name",
              {
                displayName: "Barcode",
                path: "barcode.barcodeString"
              }
            ]
          }}
        />
      </div>
      <Footer
        nextDisabled={invalidInputs}
        {...footerProps}
        errorMessage={invalidInputs && "Please review form errors."}
      />
    </>
  );
};

export default compose(stepFormValues("containerArrays", "aliquotContainers"))(
  SelectDonorCells
);
