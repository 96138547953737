/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isPlainObject, snakeCase, keyBy } from "lodash";
import { isBrowser } from "browser-or-node";
import { checkDuplicateSequencesExtended } from "../sequence-import-utils/checkDuplicateSequences";
import { sequenceJSONtoGraphQLInput } from "../sequence-import-utils/utils";
import shortid from "shortid";
import caseInsensitiveFilter from "./caseInsensitiveFilter";
import uploadStrainHelper from "./uploadStrainHelper";
import uploadReagentHelper from "./uploadReagentHelper";
import { uniq } from "lodash";
import { groupBy } from "lodash";
import {
  reactionInputSourceTypeMap,
  reactionInputRoleTypeMap,
  sortReactionMapsByExecutionOrder
} from "../../../tg-iso-lims/src/utils/reactionMapUtils";
import { isoContext } from "@teselagen/utils";
import isValidPositiveInteger from "./isValidPositiveInteger";
import getReactionInputsAndOutputs from "./getReactionInputsAndOutputs";
import { pick } from "lodash";
import { externalReferenceKeys } from "../../constants";
import { getMaterialFields } from "../sequence-import-utils/getMaterialFields";

export default async function upsertReactionJsons(
  jsons,
  options = {},
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;
  const allSequences = [];
  const allStrains = [];
  const allMaterials = [];
  const allReagents = [];
  const reactionTypeNames = [];
  const reactionMapJsons = [];
  const reactionDesigns = [];
  const {
    reactionDesignId,
    pcaReactionMapId,
    pcrReactionMapId,
    skipStrainNameCheck
  } = options;

  jsons.forEach(rm => {
    if (!rm || !isPlainObject(rm)) {
      throw new Error("Improper file structure.");
    }
    const {
      name,
      version,
      sequences = [],
      strains = [],
      materials = [],
      reagents = [],
      reactionType,
      reactionMaps = []
    } = rm;
    if (version === 1 || version === 2) {
      allSequences.push(...sequences);
      allStrains.push(...strains);
      allMaterials.push(...materials);
      allReagents.push(...reagents);
      if (reactionMaps.length) {
        const reactionDesign = {
          cid: shortid(),
          name: name || "Untitled Reaction Plan",
          ...pick(rm, externalReferenceKeys)
        };
        reactionDesigns.push(reactionDesign);
        reactionMapJsons.push(
          ...reactionMaps.map(rm => {
            const reactionMapToReturn = {
              ...rm,
              reactionDesignId: reactionDesignId || `&${reactionDesign.cid}`
            };
            if (pcaReactionMapId && rm.reactionType === "Annealed Oligos") {
              reactionMapToReturn.id = pcaReactionMapId;
            } else if (pcrReactionMapId && rm.reactionType === "PCR Reaction") {
              reactionMapToReturn.id = pcrReactionMapId;
            }
            return reactionMapToReturn;
          })
        );
        reactionMaps.forEach(rm => {
          if (!rm.reactionType) {
            throw new Error(`All reaction maps must pass a reactionType.`);
          }
          reactionTypeNames.push(rm.reactionType);
        });
      } else {
        if (!reactionType) {
          throw new Error(`All reaction maps must pass a reactionType.`);
        }
        reactionMapJsons.push(rm);
        reactionTypeNames.push(reactionType);
      }
    } else {
      throw new Error(`Version is unsupported version: ${version}.`);
    }
  });

  const reactionMapJsonsGroupedByReactionDesignId = groupBy(
    reactionMapJsons,
    "reactionDesignId"
  );
  Object.keys(reactionMapJsonsGroupedByReactionDesignId).forEach(
    reactionDesignId => {
      if (reactionDesignId !== "undefined") {
        let rmsInPlan =
          reactionMapJsonsGroupedByReactionDesignId[reactionDesignId];
        rmsInPlan = sortReactionMapsByExecutionOrder(rmsInPlan);
        rmsInPlan.forEach(rm => {
          const { executionOrder, name, major, minor } = rm;
          if (!executionOrder) {
            throw new Error(
              `Reaction map ${name} did not provide an executionOrder. This is required for reaction plans.`
            );
          }
          if (!isValidPositiveInteger(major)) {
            throw new Error(
              `Reaction map ${name} did not provide a valid executionOrder (should be in format 1.1, 1.2).`
            );
          }
          if (!isValidPositiveInteger(minor)) {
            throw new Error(
              `Reaction map ${name} did not provide a valid executionOrder (should be in format 1.1, 1.2).`
            );
          }
        });
        rmsInPlan.forEach((rm, i) => {
          if (i === 0) {
            if (rm.major !== 1) {
              throw new Error(
                `Reaction map ${rm.name} execution order must start at 1.1 (found ${rm.executionOrder})`
              );
            }
            if (rm.minor !== 1) {
              throw new Error(
                `Reaction map ${rm.name} execution order must start at 1.1 (found ${rm.executionOrder})`
              );
            }
          } else {
            const prev = rmsInPlan[i - 1];
            if (rm.major === prev.major) {
              if (rm.minor !== prev.minor + 1) {
                throw new Error(
                  `Reaction map ${rm.name} must increment execution order by .1 (previous order was ${prev.executionOrder}, current is ${rm.executionOrder})`
                );
              }
            } else {
              if (rm.major !== prev.major + 1) {
                throw new Error(
                  `Reaction map ${rm.name} must increment execution order by 1 (previous order was ${prev.executionOrder}, current is ${rm.executionOrder})`
                );
              }
              if (rm.minor !== 1) {
                throw new Error(
                  `Reaction map ${rm.name} execution order ${rm.major}.1 missing.`
                );
              }
            }
          }
        });
      }
    }
  );

  const seqRefToId = {};
  const strainRefToId = {};
  const seqRefToMatId = {};
  const newSequences = [];
  const newStrains = [];
  const newStrainPlasmids = [];
  const seqRefToName = {};

  if (allSequences.length) {
    const cleanedSequences = [];
    allSequences.forEach(seq => {
      if (!seq.ref) {
        throw new Error(`Sequence ${seq.name} did not provide a ref`);
      }
      seqRefToName[seq.ref] = seq.name;
      const cleanedSeqType = snakeCase(seq.sequenceType || "").toUpperCase();
      if (
        cleanedSeqType &&
        !["LINEAR_DNA", "CIRCULAR_DNA", "OLIGO"].includes(cleanedSeqType)
      ) {
        throw new Error(
          `Sequence ${seq.name} does not provide a valid sequence type. Should be one of: LINEAR_DNA, CIRCULAR_DNA, OLIGO`
        );
      }
      seq.sequenceTypeCode = cleanedSeqType || "LINEAR_DNA";
      delete seq.sequenceType;
      const cleanedSeq = sequenceJSONtoGraphQLInput(seq);
      cleanedSeq.ref = seq.ref;
      cleanedSequences.push(cleanedSeq);
    });
    const { allInputSequencesWithAttachedDuplicates } =
      await checkDuplicateSequencesExtended(cleanedSequences, {}, ctx);
    allInputSequencesWithAttachedDuplicates.forEach(s => {
      if (s.duplicateFound) {
        seqRefToId[s.ref] = s.duplicateFound.id || `&${s.duplicateFound.cid}`;
        seqRefToMatId[s.ref] = s.duplicateFound.polynucleotideMaterialId;
      } else {
        seqRefToId[s.ref] = `&${s.cid}`;
        delete s.ref;
        newSequences.push(s);
      }
    });
  }

  const newMicrobialMaterialPlasmids = [];
  const materialRefToId = {};
  const materialRefToSourceType = {};
  const materialRefToRoleType = {};
  const reagentRefToSourceType = {};
  const reagentRefToRoleType = {};
  const newMaterials = [];
  const sequenceUpdates = [];

  const addSourceType = (item, map) => {
    if (item.sourceType) {
      const cleanedSourceType =
        reactionInputSourceTypeMap[item.sourceType.toUpperCase()];
      if (!cleanedSourceType) {
        throw new Error(
          `${item.name} references source type ${item.sourceType} which is not a valid source type.`
        );
      }
      map[item.ref] = cleanedSourceType;
      delete item.sourceType;
    }
  };

  const addRoleType = (item, map) => {
    if (item.reactionRoleType) {
      const cleanedRoleType =
        reactionInputRoleTypeMap[item.reactionRoleType.toUpperCase()];
      if (!cleanedRoleType) {
        throw new Error(
          `${item.name} references role type ${item.reactionRoleType} which is not a valid source type.`
        );
      }
      map[item.ref] = cleanedRoleType;
      delete item.reactionRoleType;
    }
  };

  const makeDnaMatForSeq = (seqRef, name) => {
    const sequenceId = seqRefToId[seqRef];
    if (!seqRefToMatId[seqRef]) {
      const cid = shortid();
      sequenceUpdates.push({
        id: sequenceId,
        polynucleotideMaterialId: `&${cid}`
      });
      seqRefToMatId[seqRef] = `&${cid}`;

      newMaterials.push({
        ...getMaterialFields(),
        cid,
        name: name || seqRefToName[seqRef]
      });
    }
    return seqRefToMatId[seqRef];
  };

  allMaterials.forEach(m => {
    if (!m.ref) {
      throw new Error(`Material ${m.name} did not provide a ref`);
    }
    addSourceType(m, materialRefToSourceType);
    addRoleType(m, materialRefToRoleType);
    m.materialTypeCode = m.materialType ? m.materialType.toUpperCase() : "DNA";
    if (m.materialTypeCode !== "DNA" && m.materialTypeCode !== "MICROBIAL") {
      throw new Error("Currently only supporting DNA and microbial materials");
    }
    if (m.materialTypeCode === "DNA") {
      if (m.sequences && m.sequences.length > 1) {
        throw new Error(
          `Material ${m.name} has more than 1 sequence. DNA materials must have exactly 1 sequence.`
        );
      }
      if (!m.sequences || !m.sequences.length) {
        throw new Error(`Material ${m.name} did not provide a sequence.`);
      }
      const seqRef = m.sequences[0];
      const sequenceId = seqRefToId[seqRef];
      if (!sequenceId) {
        throw new Error(
          `Material ${m.name} sequence ref (${seqRef}) was was not mapped to a sequence.`
        );
      }

      materialRefToId[m.ref] = makeDnaMatForSeq(seqRef, m.name);
    } else if (m.materialTypeCode === "MICROBIAL") {
      const cid = shortid();
      const microbialMaterialId = `&${cid}`;
      materialRefToId[m.ref] = microbialMaterialId;
      m.sequences?.forEach(seqRef => {
        newMicrobialMaterialPlasmids.push({
          polynucleotideMaterialId: makeDnaMatForSeq(seqRef, m.name),
          microbialMaterialId
        });
      });
      delete m.sequences;
      newMaterials.push({
        cid,
        name: m.name,
        strainRef: m.strain,
        materialTypeCode: "MICROBIAL"
      });
    }
  });

  if (allStrains.length) {
    allStrains.forEach(strain => {
      if (!strain.ref) {
        throw new Error(`Strain ${strain.name} did not provide a ref`);
      }
      strain.cid = shortid();
      const strainId = `&${strain.cid}`;
      strainRefToId[strain.ref] = strainId;
    });
    const strainNames = [];
    const dupStrainNames = [];
    allStrains.forEach(strain => {
      if (strainNames.includes(strain.name)) {
        dupStrainNames.push(strain.name);
      } else {
        strainNames.push(strain.name);
      }
    });
    if (dupStrainNames.length) {
      throw new Error(
        `Multiple strains found with names: ${dupStrainNames.join(",")}`
      );
    }
    const existingStrains = await safeQuery(["strain", "id name"], {
      variables: {
        filter: caseInsensitiveFilter("strain", "name", strainNames)
      }
    });
    const keyedExist = keyBy(existingStrains, s => s.name.toLowerCase());
    const existingNames = [];
    const cleanedStrains = allStrains.filter(s => {
      const existing = keyedExist[s.name.toLowerCase()];
      if (existing) {
        existingNames.push(s.name);
        strainRefToId[s.ref] = existing.id;
      } else {
        strainRefToId[s.ref] = `&${s.cid}`;
        newStrains.push(s);
      }
      return !existing;
    });
    if (isBrowser && !skipStrainNameCheck && existingNames.length) {
      const continueUpload = await window.showConfirmationDialog({
        text: `Strains already exist with these names: ${existingNames.join(
          ", "
        )}. Would you like to use the existing strains?`,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel Import"
      });
      if (!continueUpload) {
        return;
      }
    }

    cleanedStrains.forEach(s => {
      s.sequences?.forEach(seqRef => {
        if (!seqRefToMatId[seqRef]) {
          makeDnaMatForSeq(seqRef);
        }
        newStrainPlasmids.push({
          polynucleotideMaterialId: seqRefToMatId[seqRef],
          strainId: strainRefToId[s.ref]
        });
      });
      delete s.sequences;
      delete s.ref;
      cleanedStrains.push(s);
    });
  }

  newMaterials.forEach(m => {
    // link to strain refs
    if (m.strainRef) {
      m.strainId = strainRefToId[m.strainRef];
      if (!m.strainId) {
        throw new Error(
          `Material ${m.name} strain ref (${m.strain}) was not mapped to a strain.`
        );
      }
      delete m.strainRef;
    }
  });

  const reagentRefToId = {};
  const reagentCidToRef = {};
  allReagents.forEach(reagent => {
    addSourceType(reagent, reagentRefToSourceType);
    addRoleType(reagent, reagentRefToRoleType);
    reagent.cid = shortid();
    const id = `&${reagent.cid}`;
    reagentCidToRef[reagent.cid] = reagent.ref;
    reagentRefToId[reagent.ref] = id;
    delete reagent.ref;
  });

  const reagentsToCreate = await uploadReagentHelper(
    allReagents,
    {
      noErrorOnExisting: true
    },
    ctx
  );

  for (const reagent of allReagents) {
    if (reagent.existingId) {
      const ref = reagentCidToRef[reagent.cid];
      reagentRefToId[ref] = reagent.existingId;
    }
  }

  let keyedReactionTypes = {};
  if (reactionTypeNames.length) {
    const reactionTypes = await safeQuery(["reactionType", "code name"], {
      variables: {
        filter: caseInsensitiveFilter(
          "reactionType",
          "name",
          uniq(reactionTypeNames)
        )
      }
    });
    keyedReactionTypes = keyBy(reactionTypes, t => t.name.toLowerCase());
  }

  const reactionMaps = [];
  const reactionsToUpsert = [];
  reactionMapJsons.forEach(rm => {
    const {
      name,
      reactions,
      reactionType = "",
      reactionDesignId,
      executionOrder
    } = rm;
    const reactionTypeCode =
      keyedReactionTypes[reactionType.toLowerCase()]?.code;
    if (!reactionTypeCode) {
      throw new Error(
        `Reaction map ${name} did not specify a valid reaction type (${reactionType} was not found).`
      );
    }
    const cleanedReactions = reactions.map((reaction, i) => {
      const errPrefix = `Reaction ${i + 1} on ${name}`;
      if (
        !reaction.inputMaterials?.length &&
        !reaction.inputReagents?.length &&
        !reaction.inputReagentsConserved?.length
      ) {
        throw new Error(`${errPrefix} did not provide input materials`);
      }
      if (
        !reaction.outputMaterials?.length &&
        !reaction.outputReagents?.length &&
        !reaction.inputReagentsConserved?.length
      ) {
        throw new Error(`${errPrefix} did not provide output materials`);
      }
      const reactionIds = {
        inputMaterials: [],
        outputMaterials: [],
        inputAdditiveMaterials: [],
        inputConservedAdditiveMaterials: [],
        outputAdditiveMaterials: []
      };

      reaction.inputMaterials?.forEach(ref => {
        const inputMaterialId = materialRefToId[ref];
        if (!inputMaterialId) {
          throw new Error(
            `${errPrefix} specified material ref ${ref} which wasn't found.`
          );
        }
        reactionIds.inputMaterials.push({
          id: inputMaterialId,
          sourceType: materialRefToSourceType[ref],
          reactionRoleType: materialRefToRoleType[ref]
        });
      });
      reaction.inputReagents?.forEach(ref => {
        const inputReagentId = reagentRefToId[ref];
        if (!inputReagentId) {
          throw new Error(
            `${errPrefix} specified reagent ref ${ref} which wasn't found.`
          );
        }
        reactionIds.inputAdditiveMaterials.push({
          id: inputReagentId,
          sourceType: reagentRefToSourceType[ref]
        });
      });
      reaction.inputReagentsConserved?.forEach(ref => {
        const inputReagentId = reagentRefToId[ref];
        if (!inputReagentId) {
          throw new Error(
            `${errPrefix} specified reagent ref ${ref} which wasn't found.`
          );
        }
        reactionIds.inputConservedAdditiveMaterials.push({
          id: inputReagentId,
          sourceType: reagentRefToSourceType[ref]
        });
      });
      reaction.outputMaterials?.forEach(ref => {
        const outputMaterialId = materialRefToId[ref];
        if (!outputMaterialId) {
          throw new Error(
            `${errPrefix} specified material ref ${ref} which wasn't found.`
          );
        }
        reactionIds.outputMaterials.push(outputMaterialId);
      });
      reaction.outputReagents?.forEach(ref => {
        const outputReagentId = reagentRefToId[ref];
        if (!outputReagentId) {
          throw new Error(
            `${errPrefix} specified reagent ref ${ref} which wasn't found.`
          );
        }
        reactionIds.outputAdditiveMaterials.push(outputReagentId);
      });
      return {
        name: reaction.name,
        reactionDetails: reaction.reactionDetails,
        ...getReactionInputsAndOutputs(reactionIds)
      };
    });
    const reactionMapToAdd = {
      name,
      executionOrder,
      reactionDesignId,
      reactionTypeCode,
      ...pick(rm, externalReferenceKeys)
    };
    if (rm.id) {
      reactionMapToAdd.id = rm.id;
      cleanedReactions.forEach(r => {
        reactionsToUpsert.push({ ...r, reactionMapId: rm.id });
      });
    } else {
      reactionMapToAdd.reactions = cleanedReactions;
    }
    reactionMaps.push(reactionMapToAdd);
  });
  const strainsToCreate = await uploadStrainHelper(
    newStrains,
    {
      doNotCreate: true
    },
    ctx
  );
  await safeUpsert("sequence", newSequences);
  await safeUpsert("strain", strainsToCreate);
  await safeUpsert("material", newMaterials);
  await safeUpsert("additiveMaterial", reagentsToCreate);
  await safeUpsert("strainPlasmid", newStrainPlasmids);
  await safeUpsert("microbialMaterialPlasmid", newMicrobialMaterialPlasmids);
  await safeUpsert("sequence", sequenceUpdates);
  let newReactionDesigns;
  if (!reactionDesignId) {
    newReactionDesigns = await safeUpsert("reactionDesign", reactionDesigns);
  }
  if (reactionsToUpsert) {
    await safeUpsert("reaction", reactionsToUpsert);
  }
  const newReactionMaps = await safeUpsert("reactionMap", reactionMaps);
  return {
    reactionDesigns: newReactionDesigns || [],
    reactionMaps: newReactionMaps || []
  };
}
