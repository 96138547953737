/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty } from "lodash";
import React from "react";
import { BlueprintError, DataTable } from "@teselagen/ui";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { volumeColumn } from "../../../../../src-shared/utils/libraryColumns";
import { validateTransfers } from "../../../../utils";
import ReviewWorklistSection from "../../../ReviewWorklistSection";

// add validation to transfer volume field if it would overflow any destination well.

// submit should either
// 1. create worklist
// 2. update plate. make a source reservoir with enough source volume. make a worklist to handle all of it for us. execute worklist. then delete worklist and source reservoir.
// 3. Write tests!

function ReviewWorklist(props) {
  const {
    toolSchema,
    worklist,
    sourceReagent,
    aliquotContainerTypes,
    Footer,
    footerProps,
    mockTransfers = []
  } = props;
  if (!sourceReagent) {
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Review Transfers"
            helper="Review the transfers below. These will be performed on submit."
            width="100%"
          />
          <DataTable
            isSimple
            formName="reviewDirectTransfersTable"
            entities={mockTransfers}
            schema={[
              "preppingReagent",
              { displayName: "Destination Plate Name", path: "plateName" },
              {
                displayName: "Destination Plate Barcode",
                path: "plateBarcode"
              },
              {
                displayName: "Destination Well",
                path: "destinationAliquotContainer",
                render: v => getAliquotContainerLocation(v)
              },
              { ...volumeColumn, displayName: "Transfer Volume" }
            ]}
          />
        </div>
        <Footer {...footerProps} />
      </React.Fragment>
    );
  } else {
    let valid = false;
    let error = validateTransfers(worklist, aliquotContainerTypes);
    if (!error && !worklist.worklistTransfers.length) {
      error = "No transfers on worklist.";
    }
    valid = isEmpty(error);
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <ReviewWorklistSection toolSchema={toolSchema} />
          <BlueprintError error={error} />
        </div>
        <Footer {...footerProps} nextDisabled={!valid} />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "sourceReagent",
  "worklist",
  "mockTransfers"
)(ReviewWorklist);
