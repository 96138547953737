/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import {
  addActiveProjectFilter,
  shouldShowProjects
} from "../utils/projectUtils";
import { addTagFilterToQuery } from "../utils/tagUtils";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import {
  aliasModels,
  expirationDateModels,
  tagModels
} from "../../../tg-iso-shared/constants";
import { aliasColumn, dateModifiedColumn } from "../utils/libraryColumns";
import { tagColumnWithRender } from "../utils/tagColumn";
import { combineGqlFragments } from "../../../tg-iso-shared/utils/gqlUtils";

const taggedItemFrag = `
  taggedItems {
    id
    tag {
      id
      name
      color
    }
    tagOption {
      id
      name
      color
    }
  }
`;

const aliasFrag = `
  aliases {
    id
    name
  }
`;

const GenericSelectWrapper = props => {
  const {
    additionalFilter: _additionalFilter,
    asReactSelect,
    dialogProps = {},
    fragment,
    name,
    schema,
    tableParamOptions = {},
    useHasura
  } = props;

  // Note: All of these include "something" variable will only work when passing a fragment, as it adds extraFragments to the query
  // When passing entities directly this is not done or has to be handled outside

  const includeTags = useMemo(() => {
    const model = getModelNameFromFragment(fragment);
    return !asReactSelect && tagModels.includes(model);
  }, [fragment, asReactSelect]);

  const includeAliases = useMemo(() => {
    const model = getModelNameFromFragment(fragment);
    return !asReactSelect && aliasModels.includes(model);
  }, [fragment, asReactSelect]);

  const includeProjects = useMemo(() => {
    const model = getModelNameFromFragment(fragment);
    return shouldShowProjects(model);
  }, [fragment]);

  const includeExpirationDate = useMemo(() => {
    const model = getModelNameFromFragment(fragment);
    return expirationDateModels.includes(model);
  }, [fragment]);

  const additionalFilter = useMemo(() => {
    const passedAdditionalFilter =
      tableParamOptions.additionalFilter || _additionalFilter;
    const model = getModelNameFromFragment(fragment);
    const tagAndProjectFilter = (_, qb, currentParams) => {
      if (includeTags) {
        addTagFilterToQuery(currentParams.tags, qb);
      }
      if (includeProjects) {
        addActiveProjectFilter(qb, {
          model
        });
      }
    };
    if (passedAdditionalFilter) {
      if (useHasura) {
        return passedAdditionalFilter;
      }
      return (...args) => {
        tagAndProjectFilter(...args);
        const qb = args[1];
        if (typeof passedAdditionalFilter === "function") {
          const filter = passedAdditionalFilter(...args);
          qb.whereAll(filter);
        } else {
          qb.whereAll(passedAdditionalFilter);
        }
      };
    }
    return tagAndProjectFilter;
  }, [
    _additionalFilter,
    fragment,
    includeProjects,
    includeTags,
    tableParamOptions.additionalFilter,
    useHasura
  ]);

  let fieldsToUse;
  if (schema) {
    fieldsToUse = schema.fields || schema;
  } else if (asReactSelect) {
    fieldsToUse = ["name"];
  } else {
    fieldsToUse = ["name", dateModifiedColumn];
  }

  if (!fragment) {
    return {
      key: name,
      ...props,
      schema: {
        model: schema?.model,
        fields: fieldsToUse
      }
    };
  }

  const model = getModelNameFromFragment(fragment);
  if (
    !includeTags &&
    !includeAliases &&
    !includeProjects &&
    !includeExpirationDate
  ) {
    return {
      key: name,
      ...props,
      schema: {
        model,
        fields: fieldsToUse
      }
    };
  }

  let additionalFragment = "\nupdatedAt";

  const extraColumns = [];
  if (includeExpirationDate) {
    additionalFragment += "\nexpirationDate";
  }
  if (includeAliases) {
    additionalFragment += aliasFrag;
    extraColumns.push(aliasColumn);
  }
  if (includeTags) {
    additionalFragment += taggedItemFrag;
    extraColumns.push(tagColumnWithRender);
  }

  if (fieldsToUse.length === 1) {
    fieldsToUse = [fieldsToUse[0], ...extraColumns];
  } else {
    fieldsToUse = [
      ...fieldsToUse.slice(0, fieldsToUse.length - 1),
      ...extraColumns,
      ...fieldsToUse.slice(fieldsToUse.length - 1)
    ];
  }

  const fragmentToUse = combineGqlFragments([fragment, additionalFragment]);

  const propsToPass = {
    ...props,
    fragment: fragmentToUse,
    schema: {
      model,
      fields: fieldsToUse
    },
    dialogProps: {
      ...dialogProps,
      style: {
        ...dialogProps.style,
        width: 750
      }
    },
    tableParamOptions: {
      ...tableParamOptions,
      additionalFilter
    }
  };

  // remove passed additional filter because it has been integrated into the tableParamOptions
  delete propsToPass.additionalFilter;

  return { key: name, ...propsToPass };
};

export default Comp => {
  return props => {
    const propsToPass = GenericSelectWrapper(props);
    return <Comp {...propsToPass} />;
  };
};
