/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { keyBy } from "lodash";
import { Intent, MenuItem, Tooltip } from "@blueprintjs/core";
import { InputField, DataTable, showConfirmationDialog } from "@teselagen/ui";
import defaultAsyncWrap from "../../../../../src-shared/utils/defaultAsyncWrap";
import { updateEditor } from "@teselagen/ove";
import store from "../../../../../src-shared/redux/store";
import { reduxForm } from "redux-form";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { designPartFragment } from "../../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { safeDelete } from "../../../../../src-shared/apolloMethods";
import withQuery from "../../../../../src-shared/withQuery";
import { withProps } from "recompose";
import ExportTableAsCsvButton from "../../../../../src-shared/ExportTableAsCsvButton";
import withLibraryExtendedPropertyColumns from "../../../../../src-shared/enhancers/withLibraryExtendedPropertyColumns";
import gql from "graphql-tag";
import { extendedValueLibraryDisplayFragment } from "../../../../../src-shared/fragments/extendedValueLibraryDisplayFragment.gql";
import { annotationSizeStartEndColumns } from "../../../../../src-shared/utils/libraryColumns";

const schema = {
  model: "part",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "isDeactivated",
      type: "flag",
      displayName: "Status",
      render: val => {
        return (
          <span
            style={{
              fontWeight: val ? "normal" : "bold",
              color: val ? null : "darkgreen"
            }}
          >
            {val ? "deactivated" : "activated"}
          </span>
        );
      }
    },
    ...annotationSizeStartEndColumns,
    { path: "sequence.name", type: "string", displayName: "Source" }
  ]
};

class PartsetSection extends React.Component {
  handlePartsTableDoubleClick = part => {
    const { history } = this.props;
    updateEditor(store, "SequenceEditor", {
      selectionLayer: {
        start: part.start,
        end: part.end
      }
    });
    history.push(`/sequences/${part.sequenceId}`);
  };

  deletePartSetPartMenuItem = item => {
    return defaultAsyncWrap(async () => {
      // const itemIds = item.ids.map(cf => cf.id) for multiple ids
      const confirm = await showConfirmationDialog({
        text: `Are you sure you want to delete part "${item.name}" from this partset?
              You cannot undo this action.`,
        intent: Intent.DANGER,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        canEscapeKeyCancel: true
      });

      if (confirm) {
        await safeDelete("partsetPart", item.id);
        window.toastr.success(`Deleted "${item.name}"`);
      }
    }, "Error deleting partset parts.")();
  };

  swapPartSetPartMenuItem = item => {
    return defaultAsyncWrap(async () => {
      const { createElements, selectedElement } = this.props;

      const confirm = await showConfirmationDialog({
        text: `Are you sure you want to swap out the partset in the design with this part: ${item.name}?`,
        intent: Intent.DANGER,
        confirmButtonText: "Swap",
        cancelButtonText: "Cancel",
        canEscapeKeyCancel: true
      });

      if (confirm) {
        const part = await safeQuery(designPartFragment, {
          variables: { id: item.partId }
        });

        createElements({
          binId: selectedElement.binId,
          values: { part },
          elementIdsToDelete: [selectedElement.id],
          cellIndex: selectedElement.index
        });
      }
    }, "Error swapping out partset.")();
  };

  editPartSetPartMenuItem = item => {
    showDialog({
      modalType: "EDIT_PARTSETPART",
      modalProps: {
        item
      }
    });
  };

  partSetPartContextMenu = ({ selectedRecords }) => {
    if (!selectedRecords || !selectedRecords.length) return;
    return [
      <MenuItem
        key="edit"
        text="Edit"
        onClick={() => this.editPartSetPartMenuItem(selectedRecords[0])}
      />,
      <MenuItem
        key="delete"
        text="Delete"
        onClick={() => this.deletePartSetPartMenuItem(selectedRecords[0])}
      />,
      <MenuItem
        key="swap"
        text="Swap Partset with this Part"
        onClick={() => this.swapPartSetPartMenuItem(selectedRecords[0])}
      />
    ];
  };

  render() {
    const {
      partsetParts,
      schema: enhancedSchema,
      extendedPropertiesLoading,
      partsLoading
    } = this.props;
    return (
      <React.Fragment>
        <h6>Properties</h6>
        <InputField name="name" label="Name" readOnly />
        Parts
        <div className="element-panel-partset-parts">
          <DataTable
            style={{ marginTop: 5, marginBottom: 15 }}
            formName="elementPanelPartsetParts"
            maxHeight={185}
            compact
            isLoading={extendedPropertiesLoading || partsLoading}
            urlConnected={false}
            withPaging
            withSearch={false}
            // isSimple
            noHeader
            contextMenu={this.partSetPartContextMenu}
            doNotShowEmptyRows
            hideSelectedCount
            isSingleSelect
            schema={extendedPropertiesLoading ? schema : enhancedSchema}
            entities={partsetParts}
            entityCount={partsetParts.length}
            onDoubleClick={this.handlePartsTableDoubleClick}
            additionalFooterButtons={
              partsetParts &&
              partsetParts[0] && (
                <Tooltip content="Export as CSV">
                  <ExportTableAsCsvButton
                    noId
                    filename={`Parts in Partset ID ${partsetParts[0].partsetId}`}
                    tableParams={{
                      schema: enhancedSchema,
                      entities: partsetParts
                    }}
                  />
                </Tooltip>
              )
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const partsetSectionPartExtPropFragment = gql`
  fragment partsetSectionPartExtPropFragment on part {
    id
    extendedStringValueViews {
      ...extendedValueLibraryDisplayFragment
    }
  }
  ${extendedValueLibraryDisplayFragment}
`;

export default compose(
  reduxForm({ form: "editPartsetPart", enableReinitialize: true }),
  withLibraryExtendedPropertyColumns({
    model: "part",
    isLocalTable: true,
    showLoading: false,
    schema
  }),
  withQuery(partsetSectionPartExtPropFragment, {
    isPlural: true,
    options: props => {
      const partIds = props.partsetParts.map(p => p.partId);
      return {
        variables: {
          pageSize: partIds.length,
          filter: {
            id: partIds
          }
        }
      };
    },
    props: props => {
      return {
        partsWithExtProps: props.parts
      };
    }
  }),
  withProps(props => {
    const { partsWithExtProps = [], partsetParts } = props;
    if (partsWithExtProps.length) {
      const keyedWithProps = keyBy(partsWithExtProps, "id");
      return {
        partsetParts: partsetParts.map(p => {
          return {
            ...p,
            ...keyedWithProps[p.partId]
          };
        })
      };
    }
  })
)(PartsetSection);
