/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* eslint graphql/template-strings: 0 */
import React from "react";
import { Intent, Button, Dialog } from "@blueprintjs/core";
import { DataTable } from "@teselagen/ui";
import gql from "graphql-tag";
import Promise from "bluebird";
import { uniqBy } from "lodash";
import recurseOnJson from "../../../../../tg-iso-design/utils/recurseOnJson";
import {
  safeQuery,
  safeDelete,
  safeUpsert
} from "../../../../src-shared/apolloMethods";

const partToItemsToDeleteFragment = gql`
  fragment partToItemsToDeleteFragment on part {
    id
    elements {
      id
      fas {
        id
      }
      elementElementCombos {
        id
      }
      eugeneRuleselement1S {
        id
      }
      eugeneRuleselement2S {
        id
      }
      eugeneRuleElements {
        id
        eugeneRule1 {
          id
        }
        eugeneRule2 {
          id
        }
      }
    }
  }
`;

const sequenceToItemsToDeleteFragment = gql`
  fragment sequenceToItemsToDeleteFragment on sequence {
    id
    isJ5Sequence
    isInLibrary
    parts {
      ...partToItemsToDeleteFragment
    }
  }
  ${partToItemsToDeleteFragment}
`;

class PartOrSequenceDeleteDialog extends React.Component {
  state = { submitting: false };

  handleClick = async () => {
    const { hideModal, isPart, items, refetch } = this.props;
    this.setState({ submitting: true });
    try {
      const queryResults = await safeQuery(
        isPart ? partToItemsToDeleteFragment : sequenceToItemsToDeleteFragment,
        {
          isPlural: true,
          variables: { filter: { id: items.map(it => it.id) } }
        }
      );

      const typeToIdsToDelete = {};
      const constructsToDelete = [];
      const cascadeDeleteTypes = { element: true, part: !isPart };
      recurseOnJson(
        queryResults,
        item => {
          if (cascadeDeleteTypes[item.__typename]) return;
          if (!typeToIdsToDelete[item.__typename])
            typeToIdsToDelete[item.__typename] = [];
          if (
            item.__typename === "sequence" &&
            item.isInLibrary === true &&
            item.isJ5Sequence === true
          ) {
            constructsToDelete.push({
              id: item.id,
              isInLibrary: false
            });
          } else {
            typeToIdsToDelete[item.__typename].push(item.id);
          }
        },
        { callOnObjectsOnly: true }
      );

      await Promise.mapSeries(
        Object.entries(typeToIdsToDelete),
        async ([type, ids]) => {
          if (ids.length) await safeDelete(type, ids);
        }
      );

      if (constructsToDelete.length)
        await safeUpsert("sequence", constructsToDelete);

      await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(`Error deleting ${isPart ? "parts" : "sequences"}.`);
    }
  };

  getSchema = () => {
    const { isPart } = this.props;
    return {
      model: isPart ? "part" : "sequence",
      fields: [
        {
          path: "name",
          type: "string",
          displayName: isPart ? "Part" : "Sequence"
        },
        {
          path: "designs",
          type: "string",
          displayName: "Design(s)"
        }
      ]
    };
  };

  cellRenderer = {
    designs: (__, item) =>
      uniqBy(this.props.itemIdToDesign[item.id], "id")
        .map(d => d.name)
        .join(", ")
  };

  render() {
    const { hideModal, isPart, items } = this.props;
    const { submitting } = this.state;
    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={
          items.length > 1
            ? `Are you sure you want to delete these ${items.length} ${
                isPart ? "parts" : "sequences"
              }?`
            : `Are you sure you want to delete this ${
                isPart ? "part" : "sequence"
              }?`
        }
        style={{ width: 550 }}
      >
        <div className="bp3-dialog-body">
          <DataTable
            formName="partOrSequenceLibraryDeleteDialogTable"
            isCompact
            isSimple
            isInfinite
            entities={items}
            entityCount={items.length}
            schema={this.getSchema()}
            cellRenderer={this.cellRenderer}
          >
            {items.length > 1 ? "These" : "This"} {isPart ? "part" : "sequence"}
            {items.length > 1 ? "s" : ""} will be deleted from the following
            designs.
          </DataTable>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text="Cancel" onClick={hideModal} />
            <Button
              intent={Intent.PRIMARY}
              text={`Delete ${isPart ? "Part" : "Sequence"}${
                items.length > 1 ? "s" : ""
              }`}
              loading={submitting}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default PartOrSequenceDeleteDialog;
