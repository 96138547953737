/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";
import SelectPlates from "./Steps/SelectPlates";
import { prepareDryAliquot } from "../../../utils/plateUtils";
import { dehydrationToolPlateFragment } from "./fragments";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";

const aliquotUpdateFields = [
  "id",
  "isDry",
  "mass",
  "massUnitCode",
  "volume",
  "volumetricUnitCode",
  "concentration",
  "concentrationUnitCode",
  "molarity",
  "molarityUnitCode",
  "cellConcentration",
  "cellConcentrationUnitCode",
  "cellCount"
];

class DehydrationTool extends React.Component {
  onSubmit = async values => {
    try {
      const { containerArrays, mass, massUnitCode } = values;
      const aliquotUpdates = [];
      const additivesToDelete = [];
      containerArrays.forEach(plate => {
        plate.aliquotContainers.forEach(ac => {
          if (ac.aliquot && ac.aliquot.volume) {
            if (ac.aliquot.additives.length > 0) {
              ac.aliquot.additives.forEach(additive => {
                if (
                  additive.additiveMaterial?.evaporable ||
                  additive.lot?.additiveMaterial.evaporable
                ) {
                  additivesToDelete.push(additive);
                }
              });
            }
            if (ac.aliquot.concentration) {
              aliquotUpdates.push(
                prepareDryAliquot({
                  aliquot: ac.aliquot
                })
              );
            } else {
              aliquotUpdates.push(
                prepareDryAliquot({
                  aliquot: ac.aliquot,
                  mass,
                  massUnitCode
                })
              );
            }
          }
        });
      });
      await safeDelete(
        "additive",
        additivesToDelete.map(add => add.id)
      );
      await safeUpsert(
        ["aliquot", aliquotUpdateFields.join(" ")],
        aliquotUpdates
      );
      const updatedPlates = await safeUpsert(
        ["containerArray", "id updatedAt"],
        containerArrays.map(p => ({ id: p.id, updatedAt: new Date() }))
      );
      return {
        containerArrays: updatedPlates
      };
    } catch (e) {
      console.error(e);
      window.toastr.error("Error dehydrating plates.");
    }
  };

  render() {
    const {
      toolSchema,
      initialValues,
      isToolIntegrated,
      toolIntegrationProps
    } = this.props;

    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        steps={steps}
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(withWorkflowInputs(dehydrationToolPlateFragment))(
  DehydrationTool
);
