/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { pick, get } from "lodash";
import { compose } from "redux";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

import { Classes } from "@blueprintjs/core";

import UnitFields from "../../UnitFields";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import {
  calculateAliquotMolarityAndConcentration,
  getAliquotMolecularWeight
} from "../../../../../tg-iso-lims/src/utils/aliquotUtils";
import getAliquotNumberOfCells from "../../../../../tg-iso-lims/src/utils/unitUtils/getAliquotNumberOfCells";

class UpdateAliquotDialog extends Component {
  onSubmit = async values => {
    const { hideModal, initialValues } = this.props;
    const updateFields = [
      "id",
      "volume",
      "mass",
      "concentration",
      "molarity",
      "molarityUnitCode",
      "concentrationUnitCode",
      "cellConcentration",
      "cellConcentrationUnitCode",
      "volumetricUnitCode",
      "massUnitCode",
      "cellCount"
    ];
    try {
      const molecularWeight = get(
        initialValues,
        "sample.material.polynucleotideMaterialSequence.molecularWeight"
      );
      const hasConcentrationValue =
        values.concentration || values.concentration === 0;
      const hasMolarityValue = values.molarity || values.molarity === 0;
      // should clear values of no molarity or concentration is provided
      if (
        (values.concentrationType === "concentration" &&
          !hasConcentrationValue) ||
        (values.concentrationType === "molarity" && !hasMolarityValue)
      ) {
        values.molarity = null;
        values.concentration = null;
      } else if (molecularWeight) {
        calculateAliquotMolarityAndConcentration({
          aliquotValues: values,
          molecularWeight,
          concentrationType: values.concentrationType
        });
      }
      if (values.volume && values.cellConcentration) {
        values.cellCount = getAliquotNumberOfCells(values);
      }
      if (values.volume && values.volumetricUnitCode) {
        const aliquotToUpdate = await safeQuery(
          [
            "aliquot",
            `id
            aliquotContainer {
              id
              aliquotContainerType {
                code
                maxVolume
                volumetricUnitCode
              }
            }
            `
          ],
          {
            variables: {
              id: values.id
            }
          }
        );
        const aliquotContainerType = get(
          aliquotToUpdate,
          "aliquotContainer.aliquotContainerType"
        );

        if (aliquotContainerType) {
          if (
            standardizeVolume(values.volume, values.volumetricUnitCode) >
            standardizeVolume(
              aliquotContainerType.maxVolume,
              aliquotContainerType.volumetricUnitCode
            )
          ) {
            return window.toastr
              .error(`The new volume of ${values.volume} ${values.volumetricUnitCode} \
            is greater than the wells max volume (${aliquotContainerType.maxVolume} ${aliquotContainerType.volumetricUnitCode}).`);
          }
        }
      }

      await safeUpsert(["aliquot", updateFields], pick(values, updateFields));
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Error updating aliquot");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit, initialValues } = this.props;
    const molecularWeight = getAliquotMolecularWeight(initialValues);
    const isMicrobial = ["MICROBIAL", "CELL_CULTURE"].includes(
      get(initialValues, "sample.material.materialTypeCode")
    );
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <UnitFields
            initialValues={initialValues}
            isDry={initialValues && initialValues.isDry}
            molecularWeight={molecularWeight}
            concentrationTypes={
              isMicrobial
                ? ["cellConcentration"]
                : ["concentration", "molarity"]
            }
            {...this.props}
          />
        </div>
        <DialogFooter hideModal={hideModal} loading={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Update Aliquot" }),
  reduxForm({
    form: "updateAliquotDialog"
  })
)(UpdateAliquotDialog);
