/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { keyBy } from "lodash";
import {
  getItemOfType,
  getReferencedValue
} from "../selectors/designStateSelectors";

const isRegexRule = rule => !!rule.regex;

const filterTagRulesByExistence = (state, ruleId, existence) =>
  getReferencedValue(state, "rule", ruleId, "tagRules").filter(
    tr => tr.existence === existence
  );
const someZeroLengthOk = (a, fn) => (a.length ? a.some(fn) : true);

export const getTagRules = (state, ruleId) => {
  const required = filterTagRulesByExistence(state, ruleId, "required");
  const restricted = filterTagRulesByExistence(state, ruleId, "restricted");
  const atLeastOneOf = filterTagRulesByExistence(
    state,
    ruleId,
    "at-least-one-of"
  );
  return { required, restricted, atLeastOneOf };
};

const doesSequenceStringPassRule = ({
  state,
  ruleId,
  elementsContained,
  seqStr,
  elementsName
}) => {
  const rule = getItemOfType(state, "rule", ruleId);
  const { regex, type } = rule;

  if (isRegexRule(rule)) {
    const regexp = new RegExp(regex, "i");
    const match = regexp.test(seqStr);
    // Just some mistake prevention here.
    if (!["requiredRegex", "restrictedRegex"].includes(type))
      throw new Error("Unrecognized type: " + type);

    if (type === "requiredRegex" && !match)
      return [
        `Part ${elementsName} has failed the required regex rule ${rule.name}.`
      ];
    else if (type === "restrictedRegex" && match)
      return [
        `Part ${elementsName} has failed the required regex rule ${rule.name}.`
      ];
  } else {
    if (elementsContained.length !== 1) {
      console.warn(
        "What to do with multiple elements? Returning true for now."
      );
      return null;
    }
    const element = elementsContained[0];
    if (!element.partId) {
      console.warn(
        "What to do with base pair literals? Returning true for now."
      );
      return null;
    }

    const tagIds = keyBy(
      getReferencedValue(state, "part", element.partId, "taggedItems"),
      "tagId"
    );

    const { required, restricted, atLeastOneOf } = getTagRules(state, rule.id);

    const hasTagMatch = tr => {
      const tag = tagIds[tr.tagId];
      const tagOptionMatch = tr.tagOptionId
        ? tag && tag.tagOptionId === tr.tagOptionId
        : true;
      return tag && tagOptionMatch;
    };
    const getTagLabel = tr => {
      let label = getItemOfType(state, "tag", tr.tagId).name;
      if (tr.tagOptionId) {
        label += ": " + getItemOfType(state, "tagOption", tr.tagOptionId).name;
      }
      return label;
    };
    return [
      ...required.map(tr =>
        !hasTagMatch(tr)
          ? `Part ${elementsName} does not have required tag with name ${getTagLabel(
              tr
            )}.`
          : null
      ),
      ...restricted.map(tr =>
        hasTagMatch(tr)
          ? `Part ${elementsName} has a restricted tag with name ${getTagLabel(
              tr
            )}.`
          : null
      ),
      !someZeroLengthOk(atLeastOneOf, tr => hasTagMatch(tr))
        ? `Part ${elementsName} does not have at least one of the tags ${atLeastOneOf
            .map(tr => getTagLabel(tr))
            .join(", ")}.`
        : null
    ].filter(x => x);
  }
  return null;
};
export default doesSequenceStringPassRule;
