/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import { Dialog, Checkbox, Tooltip } from "@blueprintjs/core";
import { DataTable, DialogFooter } from "@teselagen/ui";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

const schema = {
  model: "sequenceFeature",
  fields: [
    { path: "name", displayName: "Name" },
    { path: "start", displayName: "Start" },
    { path: "end", displayName: "End" },
    { path: "type", displayName: "Type" }
  ]
};

const cellRenderer = {
  start: i => i + 1,
  end: i => i + 1
};

class GeneratePartsFromFeaturesDialog extends React.Component {
  state = {
    submitting: false,
    cutOutCodon: false
  };

  wrappedHideModal = () => {
    const { hideModal, resolve } = this.props;
    hideModal();
    if (resolve) resolve();
  };

  onSubmit = async () => {
    const {
      onSubmit,
      hideModal,
      shouldNotHideModal,
      refetchSequences,
      selectedEntities
    } = this.props;
    this.setState({ submitting: true });
    try {
      await onSubmit(selectedEntities, this.state.cutOutCodon);
      await refetchSequences(); // kc_todo refactor this to selectTableEntities
    } catch (e) {
      console.error(e);
    }
    this.setState({ submitting: false });
    if (!shouldNotHideModal) hideModal();
  };

  render() {
    const {
      features = [],
      title = "Generate Parts from Features"
    } = this.props;

    const { submitting } = this.state;
    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={this.wrappedHideModal}
        title={title}
        style={{ width: 650 }}
      >
        <div className="bp3-dialog-body">
          <DataTable
            formName="generatePartsFromFeaturesDialog"
            withCheckboxes
            schema={schema}
            cellRenderer={cellRenderer}
            entities={features}
            entityCount={features.totalResults || 0}
          />
          <Tooltip content="Whether or not to excise a codon when the part is generated.">
            <Checkbox
              style={{ marginTop: "10px" }}
              checked={this.state.cutOutCodon}
              label="Cut out codon?"
              onChange={() => {
                this.setState(prevState => {
                  return { cutOutCodon: !prevState.cutOutCodon };
                });
              }}
            />
          </Tooltip>
        </div>
        <DialogFooter
          hideModal={this.wrappedHideModal}
          text="OK"
          loading={submitting}
          onClick={this.onSubmit}
        />
      </Dialog>
    );
  }
}

const selector = formValueSelector("generatePartsFromFeaturesDialog");

const mapStateToProps = state => ({
  selectedEntities: Object.values(
    selector(state, "reduxFormSelectedEntityIdMap") || {}
  ).map(v => v.entity)
});

export default compose(connect(mapStateToProps))(
  GeneratePartsFromFeaturesDialog
);
