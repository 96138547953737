/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import pascalCase from "pascal-case";
import gql from "graphql-tag";
import pluralize from "pluralize";
import generateFragmentWithFields from "./utils/generateFragmentWithFields";

export default function updateWithQuery(nameOrFragment, input, options = {}) {
  const { client, update, idAs, options: upsertOptions } = options;

  let fragment = typeof nameOrFragment === "string" ? null : nameOrFragment;
  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment);
  }

  const name = fragment
    ? fragment.definitions[0].typeCondition.name.value
    : nameOrFragment;

  const fragName = fragment && fragment.definitions[0].name.value;
  const recordType = pascalCase(name);
  const pluralRecordType = pluralize(recordType);
  // /*eslint-disable*/
  const updateWithQueryMutation = gql`
    mutation update${pluralRecordType}WithQuery($input: update${pluralRecordType}WithQueryInput!) {
      update${pluralRecordType}WithQuery(input:$input) {
        updatedItemsCursor {
          totalResults
          results {
            ${fragName ? `...${fragName}` : idAs || "id"}
          }
        }
      }
    }
    ${fragment ? fragment : ``}
  `;
  // /*eslint-enable*/

  if (!client) {
    return console.error(
      "You need to pass the apollo client to updateWithQuery"
    );
  }

  return client
    .mutate({
      mutation: updateWithQueryMutation,
      ...upsertOptions,
      variables: {
        input: {
          updateQueries: input
        }
      },
      update
    })
    .then(({ data }) => {
      const { updatedItemsCursor } = data[`update${pluralRecordType}WithQuery`];
      return updatedItemsCursor.results;
    });
}
