/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { TextareaField } from "@teselagen/ui";
import { safeUpsert } from "../../apolloMethods";

class DescriptionEditForm extends React.Component {
  onSubmit = async values => {
    try {
      const { dataType, exit } = this.props;
      const { id, description } = values;
      await safeUpsert([dataType, "id description"], {
        id,
        description
      });
      exit();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error editing description");
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <TextareaField
        name="description"
        autoFocus
        onFieldSubmit={handleSubmit(this.onSubmit)}
      />
    );
  }
}

export default compose(
  reduxForm({
    form: "libraryInspectorDescriptionEditForm",
    enableReinitialize: true
  })
)(DescriptionEditForm);
