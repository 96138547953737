/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button } from "@blueprintjs/core";
import React from "react";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import UploadJ5Report from "../../Library/J5Reports/UploadJ5Report";
import { getOutputModel } from "./utils";

function SelectStartItems(props) {
  const {
    workflowTool,
    stepFormProps: { change }
  } = props;

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Start Task Items"
          helper="Select items for each of the types specified."
        />
        {workflowTool.workflowToolOutputs.map((output, i) => {
          const model = getOutputModel(output);
          let fragment = [model, "id name updatedAt"];
          let additionalDataFragment;
          let schema = ["name", dateModifiedColumn];
          let additionalFilter;
          if (model === "dataFile") {
            fragment = [model, "id originalname updatedAt"];
            schema = [
              {
                displayName: "Name",
                path: "originalname"
              },
              dateModifiedColumn
            ];
          } else if (model === "aliquot") {
            fragment = [model, "id sample { id name } updatedAt"];
            schema = [
              {
                displayName: "ID",
                type: "number",
                path: "id"
              },
              {
                displayName: "Sample",
                path: "sample.name"
              },
              dateModifiedColumn
            ];
          } else if (model === "aliquotContainer") {
            additionalFilter = {
              "aliquotContainerType.isTube": true
            };
            fragment = [
              "aliquotContainerPathView",
              "id name barcodeString fullPath updatedAt"
            ];
            additionalDataFragment = [model, "id name"];
            schema = [
              "name",
              {
                displayName: "Barcode",
                path: "barcodeString"
              },
              {
                width: 300,
                displayName: "Location",
                path: "fullPath"
              },
              dateModifiedColumn
            ];
          } else if (model === "containerArray") {
            fragment = [
              model,
              "id name barcode { id barcodeString } updatedAt"
            ];
            schema = [
              "name",
              {
                displayName: "Barcode",
                path: "barcode.barcodeString"
              },
              dateModifiedColumn
            ];
          }
          const fieldName = "startItemsFor:" + output.id;
          return (
            <div key={output.id}>
              {model === "j5Report" && (
                <Button
                  text="Upload DNA Assembly Report"
                  onClick={() => {
                    showDialog({
                      ModalComponent: UploadJ5Report,
                      modalProps: {
                        uploadCompleted: async j5ReportIds => {
                          const reports = await safeQuery(
                            additionalDataFragment || fragment,
                            {
                              variables: {
                                filter: {
                                  id: j5ReportIds
                                }
                              }
                            }
                          );
                          change(fieldName, reports);
                        }
                      }
                    });
                  }}
                />
              )}
              <GenericSelect
                {...{
                  name: fieldName,
                  isMultiSelect: true,
                  fragment,
                  additionalDataFragment,
                  nameOverride:
                    output.workflowToolOutputDefinition.label || undefined,
                  schema,
                  tableParamOptions: {
                    additionalFilter
                  },
                  dialogProps: {
                    style: {
                      width: 650
                    }
                  },
                  postSelectDTProps: {
                    formName: "selectStartItems:" + output.id,
                    schema
                  }
                }}
              />
              {i !== workflowTool.workflowToolOutputs.length - 1 && (
                <hr className="tg-section-break" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SelectStartItems;
