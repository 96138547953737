/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  safeQuery,
  safeUpsert,
  safeDelete,
  deleteWithQuery
} from "./apolloMethods";
import { isoContext } from "@teselagen/utils";
import { startImportCollection } from "./utils/importCollection";
import { showDialog, hideDialog } from "./GlobalDialog";
import modelNameToReadableName from "./utils/modelNameToReadableName";
import { setActiveLab } from "./utils/labUtils";

export default () => {
  // isoContext methods will be used by utils in the iso packages. This will make it so we don't have to pass
  // these methods manually.
  isoContext.clear();
  isoContext.safeQuery = safeQuery;
  isoContext.safeUpsert = safeUpsert;
  isoContext.safeDelete = safeDelete;
  isoContext.deleteWithQuery = deleteWithQuery;

  // these are front-end only methods
  isoContext.modelNameToReadableName = modelNameToReadableName;
  isoContext.startImportCollection = startImportCollection;
  isoContext.showDialog = showDialog;
  isoContext.hideDialog = hideDialog;
  isoContext.setActiveLab = setActiveLab;
};
