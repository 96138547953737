/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import {
  exampleRecord,
  exampleSimpleRecord,
  formInputs,
  tagsExampleRecord
} from "../exampleRecords";
import {
  importExportSubtypes,
  importExportSubtypesArray
} from "../importExportSubtypes";
import {
  exampleRecordValidation,
  formatPageValidation,
  tagField,
  yupRequiredIfSuccess
} from "../validationFns";

export const EXPORT__FORMAT = {
  name: "Format Endpoint",
  description:
    "When exporting to an external database, this first 'Format' request is to help configure the UI that the user sees when doing the export. More info here:  http://docs.teselagen.com/en/articles/4626993-custom-form-inputs-integrations",
  method: "POST",
  exampleRequest: {
    __validate__: ({ subtype }) => {
      return yup.object({
        records: yup.array(
          yup.object({
            name: yup.string().required(),
            id: yup.string().required(),
            ...(subtype === "DNA_SEQUENCE" && {
              circular: yup.boolean().required()
            })
          })
        )
      });
    },
    records: [
      {
        ...exampleSimpleRecord,
        id: "151345"
      },
      {
        ...exampleSimpleRecord,
        id: "671321"
      }
    ]
  },
  exampleResponse: {
    __validate__: () =>
      yup.object({
        pages: yup.array(yup.object(formatPageValidation)),
        ...formatPageValidation
      }),
    __tgMultipleExamples__: [
      {
        __tgTabTitle__: "Simple",
        title: "Circular Sequence Fields",
        ...formInputs
      },
      {
        __tgTabTitle__: "Paged",
        pages: [
          {
            title: "Circular Sequence Fields",
            ...formInputs
          },
          {
            title: "Linear Sequence Fields",
            ...formInputs
          }
        ]
      }
    ]
  }
};
export const EXPORT__EXPORT = {
  //this is the endpointTypeCode
  name: "Export Endpoint",
  description:
    "This endpoint receives record data and any additional fields. It needs to return a success response and, optionally, a reason why the export failed.",
  method: "POST",
  exampleRequest: {
    __validate__: ({ subtype }) =>
      yup.object({
        additionalFields: yup.object(),
        record: exampleRecordValidation({
          subtype,
          doNotRequireExternalFields: true
        })
      }),
    additionalFields: {
      bioSafetyLevel: "1",
      selectionMarkers: ["kanamycin"],
      summary: "This is an important sequence I've designed",
      status: "complete"
    },
    record: exampleRecord()
  },
  exampleResponse: {
    __validate__: (/*{ subtype }*/) =>
      yup.object({
        success: yup.boolean().required(),
        name: yup.string().required(),
        failureReason: yup.string(),
        ...tagField(),
        externalReferenceSystem: yupRequiredIfSuccess,
        externalReferenceId: yupRequiredIfSuccess,
        externalReferenceType: yupRequiredIfSuccess,
        externalReferenceUrl: yup.string()
      }),

    success: true,
    externalReferenceSystem: "jbei",
    externalReferenceId: "289hf9h",
    externalReferenceType: "DNA_SEQUENCE",
    externalReferenceUrl: `https://jbei.org/DNA_SEQUENCE/289hf9h`,
    name: "seq 1",
    failureReason: "(Only needed if success=false) Sequence is a duplicate",
    ...tagsExampleRecord
  }
};

export default {
  defaultSubtype: importExportSubtypes.DNA_SEQUENCE,
  subtypes: importExportSubtypesArray,
  description:
    "This adds a 'Right Click > Export to External DB' option to the Library.",
  endpoints: {
    EXPORT__FORMAT,
    EXPORT__EXPORT
  }
};
