/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get } from "lodash";
import queryString from "query-string";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import StepForm from "../../StepForm";
import DataSelection from "./steps/DataSelectionStep";
import DataMapping from "./steps/DataMappingStep";
import "./datamapping.css";
import { Icon, Intent } from "@blueprintjs/core";
import {
  getAssaySubjectIdsFromNames,
  linkSubjectsToAliquots
} from "../../utils/assaySubjectUtils";
import { endpoints } from "../../../src-test/configs/config.json";
import {
  INPROGRESS as IMPORT_JOB_INPROGRESS_CODE,
  FINISHED as IMPORT_JOB_FINISH_CODE,
  ERROR as IMPORT_JOB_ERROR_CODE
} from "../../utils/experimentData/dataGridUtils";
import { getSubjectNames } from "./utils";
import { promiseTimeout } from "../../../../tg-iso-shared/src/utils/promiseUtils";

// NOTE: This allows the tool to auto select the first datagrid coming from the previous tool.
// import { compose } from "redux";
// import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";

const IMPORT_JOB_TIMEOUT = window.Cypress?.IMPORT_JOB_TIMEOUT || 10 * 1000;

/**
 * Add assaySubjectClassId to each assaySubjectName of the selected dataGrid to import them into the DB
 */
const getSubjects = ({ selectedDataGrid, headerClasses, headerSubClasses }) => {
  const { subjectNames, assaySubjectHeaderName } = getSubjectNames({
    selectedDataGrid,
    headerClasses
  });

  const classId = headerSubClasses[assaySubjectHeaderName].id;
  return subjectNames.map(el => ({ name: el, assaySubjectClassId: classId }));
};

/**
 * Build the TEST mapper object from the user selected column maps
 */
const constructMapper = ({ headerNames, headerClasses, headerSubClasses }) => {
  const mapper = [];
  headerNames.forEach(headerName => {
    const subClassUnit = headerSubClasses[headerName].unit;
    mapper.push({
      name: headerName,
      class: headerClasses[headerName].name,
      className: headerClasses[headerName].label,
      subClass: headerSubClasses[headerName].id,
      subClassName: headerSubClasses[headerName].name,
      ...(subClassUnit && {
        unit: { id: subClassUnit.id, name: subClassUnit.name }
      })
    });
  });

  return mapper;
};

/**
 * Upserts a new assay.
 */
const createNewAssay = async ({ assayName }) => {
  const result = await window.serverApi.request({
    method: "POST",
    headers: getRequestHeaderKeys(),
    withCredentials: true,
    url: endpoints.createAssay,
    data: { name: assayName }
  });
  return get(result, "data.0.id");
};

/**
 * Upserts a list of assaySubjects.
 */
const createNewSubjects = async assaySubjects => {
  await window.serverApi.request({
    method: "POST",
    headers: getRequestHeaderKeys(),
    withCredentials: true,
    url: endpoints.createAssaySubjects,
    data: assaySubjects
  });
};

/**
 * Calls TEST API to import the experimental data into the assay.
 */
const importIntoDataLake = async ({ assayId, importFileSetId, mapper }) => {
  await window.serverApi.request({
    method: "PUT",
    headers: getRequestHeaderKeys(),
    withCredentials: true,
    url: endpoints.importAssayResults.replace(":assayId", assayId),
    data: {
      fileId: importFileSetId,
      mapper
    }
  });
};

/**
 * Calls TEST API to add the linking descriptors to assay subjects
 * of the recently imported data.
 *
 * NOTE: When subjects do not exist before importing
 * experiment data into an assay, these are created on-the-fly
 * so we need to run this function only after the data import finishes.
 */
const linkAssaySubjects = async subjectLinks => {
  const subjectNamesToAliquotIdMap = {};
  subjectLinks.forEach(link => {
    subjectNamesToAliquotIdMap[link.name] = link.aliquot.id;
  });
  // Gets a subject name to subject id map.
  const subjectNamesToIds = await getAssaySubjectIdsFromNames(
    Object.keys(subjectNamesToAliquotIdMap)
  );
  // Constructs a subject id to aliquot id map.
  const subjectIdToAliquotId = {};
  Object.entries(subjectNamesToAliquotIdMap).forEach(
    ([subjectName, aliquotId]) => {
      subjectIdToAliquotId[subjectNamesToIds[subjectName]] = aliquotId;
    }
  );
  await linkSubjectsToAliquots(subjectIdToAliquotId);
};

const runImporter = async ({
  assayId,
  mapper,
  subjectLinks,
  selectedDataGrid,
  headerClasses,
  headerSubClasses
}) => {
  let code, description;

  const importFileSetId = selectedDataGrid.importFileSetId;

  try {
    if (subjectLinks) {
      await createNewSubjects(
        getSubjects({ selectedDataGrid, headerClasses, headerSubClasses })
      );
    }
  } catch (error) {
    console.error(error);
  }

  // Try import data into data lake
  try {
    // Imports the data into the assay.
    // If successful set the finish code and description.
    await importIntoDataLake({
      assayId,
      importFileSetId,
      mapper
    });
    code = IMPORT_JOB_FINISH_CODE;
    description = "Importer job finished";
  } catch (error) {
    console.error(error);
    code = IMPORT_JOB_ERROR_CODE;
    // For some type of errors, the TEST API returns more informative user friendly error messages
    // in error.response.data.error, so use it as possible.
    description = get(error, "response.data.error") || "";
  }

  // Try linking subjects to aliquot ids.
  try {
    // Once the data is imported into the assay
    // Adds the linking descriptors to the assay subjects.
    if (subjectLinks) {
      await linkAssaySubjects(subjectLinks);
    }
  } catch (error) {
    console.error(error);
  }
  return {
    id: importFileSetId,
    status: {
      code,
      description
    }
  };
};

/**
 * This function takes the datagrid data plus the column mapper
 * and imports this into TEST Data Lake.
 */
const importDataGridToDataLake = async values => {
  const {
    headerNames,
    headerClasses,
    headerSubClasses,
    // selectedExperiment,
    selectedAssay,
    selectedDataGrid,
    subjectLinks
  } = values;
  const mapper = constructMapper({
    headerNames,
    headerClasses,
    headerSubClasses
  });

  // Checks the selected assay to be created.
  if (selectedAssay.userCreated) {
    const assayId = await createNewAssay({
      assayName: selectedAssay.value
    });
    selectedAssay.id = assayId;
  }

  // 'importJobPromise' is a function that will return the
  // promise of the import run we want to attach a timeout to.
  const importJobPromise = () =>
    runImporter({
      mapper,
      assayId: selectedAssay.id,
      subjectLinks,
      selectedDataGrid,
      headerClasses,
      headerSubClasses
    });

  // 'importJobTimeoutDefault' is a function that returns a setTimeout
  // with a callback that calls a Promise.resolve with a default importJob object in "inprogress" status.
  const importJobTimeoutDefault = resolve =>
    setTimeout(
      () =>
        resolve({
          id: selectedDataGrid.importFileSetId,
          status: {
            code: IMPORT_JOB_INPROGRESS_CODE,
            description: "Importer job is in progress."
          }
        }),
      IMPORT_JOB_TIMEOUT
    );

  const importJob = await promiseTimeout(
    importJobPromise,
    importJobTimeoutDefault
  );

  const error =
    importJob.status.code === IMPORT_JOB_ERROR_CODE
      ? importJob.status.description
      : "";
  if (error) {
    window.toastr.error(`Import Error: ${importJob.status.description}`);
  }

  return {
    assay: selectedAssay,
    importJob,
    error
  };
};

const SuccessPageInnerContent = props => {
  const {
    assay,
    assay: { importJob }
  } = props;
  const importStatusCode = get(importJob, "status.code");
  let headerMessage, subHeaderMessage;

  if (importStatusCode === IMPORT_JOB_FINISH_CODE) {
    headerMessage = "Your data grid has been mapped and imported.";
    subHeaderMessage = (
      <span>
        <a href={`/client/assays/${assay.id}`}>Go to imported Assay</a>{" "}
      </span>
    );
  } else if (importStatusCode === IMPORT_JOB_INPROGRESS_CODE) {
    headerMessage = "Your data grid is being imported.";
    subHeaderMessage = (
      <span>
        Watch the progress of the Import Job at{" "}
        <a href="/client/assay-import-jobs">
          Assay Import Jobs ( ID={importJob.id} )
        </a>{" "}
      </span>
    );
  } else if (importStatusCode === IMPORT_JOB_ERROR_CODE) {
    headerMessage = "Error importing data. Please review inputs.";
    subHeaderMessage = (
      <span>
        <Icon
          icon="warning-sign"
          intent={Intent.WARNING}
          style={{ marginRight: 10 }}
        />
        {get(importJob, "status.description")}
      </span>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h4>{headerMessage}</h4>
      <h6>{subHeaderMessage}</h6>
    </div>
  );
};

class DataGridToDataLakeTool extends Component {
  state = {
    submitting: false
  };

  onSubmit = async values => {
    try {
      this.setState({ submitting: true });
      const results = await importDataGridToDataLake(values);
      if (results.error !== "") {
        throw new Error(results.error);
      }
      return {
        assay: { id: results.assay.id, importJob: results.importJob }
      };
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setState({ submitting: false });
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues,
      location
    } = this.props;

    const { dataGridId, assayName } = queryString.parse(location?.search);

    const steps = [
      {
        title: "Data Selection",
        Component: DataSelection,
        // withCustomFooter: true,
        props: {
          dataGridId,
          assayName
        }
      },
      {
        title: "Data Mapping",
        Component: DataMapping,
        withCustomFooter: true,
        props: {
          submitting: this.state.submitting,
          history: this.props.history
        }
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        successPageInnerContent={SuccessPageInnerContent}
      />
    );
  }
}

export default DataGridToDataLakeTool;
// This allows the tool to auto select the first datagrid coming from the previous tool.
// However we may wanna hold off this until the tool supports handling multiple data grids.
// withWorkflowInputs(genericSelectDataGridFragment, {
//   initialValueName: "selectDataGrids"
// })
