/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import { keyBy } from "lodash";
import shortid from "shortid";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import isIdClientGenerated from "../../../tg-iso-shared/utils/isIdClientGenerated";

/**
 * A class to help improve performance by combining multiple upsert/delete calls in one call.
 *
 * The major challenge is figuring out how we can group the upserts
 * without breaking dependencies between the individual upserts. This is challenging
 * and requires knowledge of the schema in the general case.
 *
 * This class deals with this by executing all upserts of a single model at once. The
 * order of models is provided by the user at runtime. This will not always works, so
 * the user must be careful with what they are upserting. It should also be noted that
 * the sorting is done purely by the top-level model in the upserted values.
 *
 * All deletes will be performed before upserts. Mixing deletes and creates might cause
 * unintended behavior if any of those deletes cascade in the database. Cascading deletes
 * could potentially cause errors.
 *
 * Errors might occur if we do something like set the sample of an aliquot via `sample {...}`
 * in one upsert and then set the `sampleId` in another upsert, especially if they
 * are different samples.
 *
 * In short, be very careful when using this code. If you are not careful, you are going
 * to get some annoying errors.
 */
class SimpleWriteBuffer {
  constructor() {
    /**
     * A map from model to id to mutation object. An mutation object has keys:
     *    - `id`: The id of the object. For created objects, this will be the cid.
     *    - `value`: The values for the object that we wish to create.
     *    - `deleted`: Flag saying whether we are deleting the object or not.
     */
    this.buffer = {};
    this.fragmentMap = {};

    this.bufferAfter = {};

    // Bind these methods so that plain `upsert`s can be passed around.
    this.upsert = this.upsert.bind(this);
    this.updateAfter = this.updateAfter.bind(this);
    this.delete = this.delete.bind(this);
    this.del = this.del.bind(this);
    this.flush = this.flush.bind(this);

    // bind others
    this.safeUpsert = this.upsert;
    this.safeDelete = this.del;
  }

  /**
   * Add the upsert to the buffer.
   *
   * This will return an array of objects with an `id` key. This is to preserve
   * interoperability with `withUpsert`. For creates, the id will be a cid prefixed
   * with a "&".
   * @param {string} model The model that we are upserting.
   * @param {Object | Array<Object>} values The values that we wish to upsert.
   * @returns {Array<Object>}
   */
  upsert(modelOrFragment, values) {
    if (!Array.isArray(values)) values = [values];

    let fragment, model;
    if (typeof modelOrFragment === "string") {
      model = modelOrFragment;
    } else {
      model = getModelNameFromFragment(modelOrFragment);
      fragment = modelOrFragment;
    }

    if (!this.buffer[model]) this.buffer[model] = {};
    if (fragment) {
      this.fragmentMap[model] = fragment;
    }
    // Map from id/cid to mutation object.
    const modelMap = this.buffer[model];

    return values.map(value => {
      const isCreate = !value.id;
      let id = value.id || value.cid || shortid();

      // We need this step in case the the user is upserting using an
      // id returned by this function.
      if (id[0] === "&") id = id.slice(1);

      // Add the cid if we are creating an object.
      if (isCreate) value = { ...value, cid: id };

      // Initialize the mutation object if needed.
      if (!modelMap[id]) modelMap[id] = { id, value: {}, deleted: false };

      if (modelMap[id].deleted)
        console.warn(`Trying to upsert deleted ${model} with id ${id}.`);

      Object.assign(modelMap[id].value, value);

      return { id: isCreate ? "&" + id : id };
    });
  }

  /**
   * Add the upsertAfter to 2nd buffer.
   *
   * Because we sometimes need to create 2 items and then go back and update the first item
   * we need a function to handle that update.
   * For example adding the sampleAliquotId to a sample after the aliquot and sample has been created.
   * @param {string} model The model that we are upserting.
   * @param {Object | Array<Object>} values The values that we wish to upsert.
   * @returns {Array<Object>}
   */
  updateAfter(modelOrFragment, values) {
    if (!Array.isArray(values)) values = [values];

    let model;
    if (typeof modelOrFragment === "string") {
      model = modelOrFragment;
    } else {
      model = getModelNameFromFragment(modelOrFragment);
    }

    if (!this.bufferAfter[model]) this.bufferAfter[model] = {};
    // Map from id/cid to mutation object.
    const modelMap = this.bufferAfter[model];

    return values.map(value => {
      const cid = value.id.slice(1);

      const cleanedValue = { ...value, cid };

      delete cleanedValue.id;

      // Initialize the mutation object if needed.
      if (!modelMap[cid]) modelMap[cid] = { cid, value: {}, deleted: false };

      if (modelMap[cid].deleted) {
        console.warn(`Trying to updateAfter deleted ${model} with cid ${cid}.`);
      }
      Object.assign(modelMap[cid].value, cleanedValue);
      return { id: value.id };
    });
  }

  /**
   * Add the deletes to the buffer.
   * @param {string} model The model that we are upserting.
   * @param {Object | Array<Object>} ids The ids of the objects we wish to delete.
   */
  delete(model, ids) {
    if (!Array.isArray(ids)) ids = [ids];

    if (!this.buffer[model]) this.buffer[model] = {};
    // Map from id/cid to mutation object.
    const modelMap = this.buffer[model];
    for (let id of ids) {
      // We need this step in case the the user is deleting via an
      // id returned by a buffered upsert.
      if (id[0] === "&") id = id.slice(1);

      // Initialize the mutation object if needed.
      if (!modelMap[id]) modelMap[id] = { id, value: {}, deleted: true };

      modelMap[id].deleted = true;
    }
  }

  /**
   * Avoid using JavaScript reserved words.
   */
  del(...args) {
    return this.delete(...args);
  }

  /**
   * Clear the data from the buffer and write it to the database.
   *
   * A model order must be provided telling this method which order
   * to perform the deletes and upserts. They items in the array should be
   * the name of the models that we are upserting and deleting.
   *
   * @param {Object} apolloMethods
   * @param {function} apolloMethods.upsert
   * @param {function} apolloMethods.delete
   * @param {function} apolloMethods.safeUpsert
   * @param {function} apolloMethods.safeDelete
   * @param {Array<string>} modelOrder The order in which to execute the model deletes and upserts.
   */
  async flush(apolloMethods, modelOrder, customHandlers = {}) {
    const { buffer, bufferAfter } = this;
    const {
      delete: maybeDelete,
      upsert: maybeUpsert,
      safeQuery,
      safeDelete,
      safeUpsert
    } = apolloMethods;
    const del = maybeDelete || safeDelete;
    const upsert = maybeUpsert || safeUpsert;

    // Check that all of the models in the buffer are provided in the model order.
    const missingFromOrder = missingFromModelOrder(buffer, modelOrder);
    if (missingFromOrder.length) {
      throw new Error(
        `Not all models in the buffer exist in the supplied model order. Missing ${missingFromOrder.join(
          ","
        )}`
      );
    }

    // Perform the deletes.
    await Promise.mapSeries(modelOrder, async model => {
      const { handleDelete } = customHandlers[model] || {};
      if (!buffer[model]) return;
      const idsToDelete = Object.values(buffer[model])
        // We can't delete items that haven't been created.
        .filter(x => x.deleted && !isIdClientGenerated(x.id))
        .map(x => x.id);
      if (idsToDelete.length) {
        if (handleDelete) {
          await handleDelete(idsToDelete);
        } else {
          await del(model, idsToDelete);
        }
      }
    });
    // Perform the upserts.
    await Promise.mapSeries(modelOrder, async model => {
      if (!buffer[model]) return;
      const valuesToUpsert = Object.values(buffer[model])
        .filter(x => !x.deleted)
        .map(x => x.value);

      // We must upsert the creates and updates seperately.
      const updates = valuesToUpsert.filter(v => v.id);
      const creates = valuesToUpsert.filter(v => !v.id);

      const { handleCreate, handleUpdate } = customHandlers[model] || {};

      const fragmentOrModel = this.fragmentMap[model] || model;
      const excludeResults = typeof fragmentOrModel === "string";
      if (updates.length) {
        if (handleUpdate) {
          await handleUpdate(updates);
        } else {
          await upsert(fragmentOrModel, updates, {
            excludeResults
          });
        }
      }
      if (creates.length) {
        if (handleCreate) {
          await handleCreate(creates);
        } else {
          await upsert(fragmentOrModel, creates, {
            excludeResults
          });
        }
      }
    });
    // perform the update afters now that everything is created
    await Promise.mapSeries(modelOrder, async model => {
      if (!bufferAfter[model]) return;
      const valuesToUpsert = Object.values(bufferAfter[model])
        .filter(x => !x.deleted)
        .map(x => x.value);
      const listOfCids = valuesToUpsert.map(v => v.cid);
      const itemsWithIds = await safeQuery([model, "id cid"], {
        variables: {
          filter: {
            cid: listOfCids
          }
        }
      });
      const cidToId = itemsWithIds.reduce((acc, item) => {
        acc[item.cid] = item.id;
        return acc;
      }, {});
      const updates = [];
      valuesToUpsert.forEach(v => {
        const id = cidToId[v.cid];
        if (id) {
          const update = {
            id,
            ...v
          };
          delete update.cid;
          updates.push(update);
        }
      });
      await upsert(model, updates, {
        excludeResults: true
      });
    });

    // Clear the buffer.
    this.buffer = {};
    // clear fragment map
    this.fragmentMap = {};
  }
}

/**
 * See if all of the models in the buffer are in the model order.
 * @param {Object} buffer
 * @param {Array<string>} modelOrder
 */
export function missingFromModelOrder(buffer, modelOrder) {
  const modelSet = keyBy(modelOrder, x => x);
  const missing = Object.keys(buffer).filter(model => !modelSet[model]);
  return missing;
}

export default SimpleWriteBuffer;
