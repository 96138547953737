/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEqual, get, forEach } from "lodash";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { ReactColorField, ReactSelectField } from "@teselagen/ui";

import PlateMapPlate from "../../PlateMapPlate";
import stepFormValues from "../../../../src-shared/stepFormValues";
import determineBlackOrWhiteTextColor from "../../../../src-shared/utils/determineBlackOrWhiteTextColor";
import { removeDecimal } from "../../../../src-shared/utils/formUtils";
import {
  generateContainerArray,
  getPositionFromAlphanumericLocation
} from "../../../../../tg-iso-lims/src/utils/plateUtils";

class PlateCsvWithTabs extends React.Component {
  state = {
    extraLayers: null,
    activeLayer: null,
    extraLayerMap: {}
  };

  componentDidMount() {
    this.calculateExtraLayers();
  }

  componentDidUpdate() {
    this.calculateExtraLayers();
  }

  computeDefaultColorsAndActivePlate(layers) {
    const { data, change, userLayerColor } = this.props;

    // for paging multiple plates
    const plateNames = [];
    data.forEach(row => {
      const plateName = row["Plate Name"];
      if (!plateNames.includes(plateName)) {
        plateNames.push(plateName);
      }
    });
    change("activePlate", plateNames[0]);
    this.setState({
      plateNames
    });

    // for computing layers and colors
    const extraLayerMap = layers.reduce((acc, layer, i) => {
      acc[layer] = [];
      acc[layer].reverse = i % 2 !== 0;
      return acc;
    }, {});

    data.forEach(row => {
      layers.forEach(layer => {
        const rowZone = row[layer];
        if (rowZone && !extraLayerMap[layer].includes(rowZone)) {
          extraLayerMap[layer].push(rowZone);
        }
      });
    });

    forEach(extraLayerMap, (layerItems, layer) => {
      const colorsToUse = layerItems.reverse
        ? colors.slice().reverse()
        : colors;
      layerItems.forEach((layerItem, i) => {
        const formNameKey = `al${layer}.li${removeDecimal(layerItem)}`;
        const userDefinedColor = get(userLayerColor, formNameKey);
        if (!userDefinedColor) {
          const defaultColor = colorsToUse[i] || otherColor;
          change("userLayerColor." + formNameKey, defaultColor);
        }
      });
    });
    this.setState({
      extraLayerMap
    });
  }

  calculateExtraLayers() {
    const { activeLayer } = this.state;
    const { headers, layers: passedLayers, requiredHeaders } = this.props;

    if (passedLayers) {
      if (!activeLayer || !passedLayers.includes(activeLayer)) {
        this.computeDefaultColorsAndActivePlate(passedLayers);
        this.setState({
          activeLayer: passedLayers[0]
        });
      }
    } else {
      const extraLayers = headers.filter(
        header => !requiredHeaders.includes(header)
      );
      if (!isEqual(extraLayers, this.state.extraLayers)) {
        this.computeDefaultColorsAndActivePlate(extraLayers);
        this.setState({
          extraLayers,
          activeLayer: extraLayers[0]
        });
      }
    }
  }

  render() {
    const {
      extraLayerMap,
      extraLayers: calculatedExtraLayers = [],
      activeLayer,
      plateNames = []
    } = this.state;
    const {
      data,
      userLayerColor,
      withButtons,
      layers: passedLayers,
      activePlate
    } = this.props;
    const extraLayers = passedLayers || calculatedExtraLayers;
    if (!extraLayers) return null;

    const plateMapGroups = data.reduce((acc, row) => {
      const plateName = row["Plate Name"];
      if (!acc[plateName]) acc[plateName] = [];

      const layerItem = row[activeLayer];
      const color = get(
        userLayerColor,
        `al${activeLayer}.li${removeDecimal(layerItem)}`
      );
      acc[plateName].push({
        ...getPositionFromAlphanumericLocation(row.Well),
        tooltip: row["Plasmid ID"],
        color
      });
      return acc;
    }, {});
    const plateMapItems = plateMapGroups[activePlate] || [];
    const containerFormat = {
      rowCount: 8,
      columnCount: 12,
      code: "96_WELL",
      is2DLabeled: true
    };

    return (
      <div className="width100">
        {plateNames.length > 1 && (
          <ReactSelectField
            label="Plate"
            name="activePlate"
            options={plateNames}
          />
        )}
        <div className="tg-flex justify-space-between width100">
          {withButtons && (
            <ButtonGroup vertical>
              {extraLayers.map(layer => {
                return (
                  <Button
                    key={layer}
                    text={layer}
                    active={activeLayer === layer}
                    onClick={() => {
                      this.setState({
                        activeLayer: layer
                      });
                    }}
                  />
                );
              })}
            </ButtonGroup>
          )}
          <div className="tg-flex">
            <div style={{ marginRight: 15, marginTop: 15, minWidth: 150 }}>
              {extraLayerMap[activeLayer] &&
                extraLayerMap[activeLayer].map((layerItem, i) => {
                  const userColor = get(
                    userLayerColor,
                    `al${activeLayer}.li${removeDecimal(layerItem)}`
                  );
                  const background = userColor;
                  return (
                    <SelectLayerColor
                      key={i}
                      name={`userLayerColor.al${activeLayer}.li${removeDecimal(
                        layerItem
                      )}`}
                      layerItem={layerItem}
                      color={background}
                    />
                  );
                })}
            </div>
            <PlateMapPlate
              aliquotContainers={generateContainerArray(
                plateMapItems,
                containerFormat
              )}
              containerFormat={containerFormat}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default stepFormValues("activePlate")(PlateCsvWithTabs);

const colors = [
  "#2965CC",
  "#29A634",
  "#D99E0B",
  "#D13913",
  "#8F398F",
  "#00B3A4",
  "#DB2C6F",
  "#9BBF30",
  "#FF6E4A",
  "#7157D9"
];

const otherColor = "#A854A8";

function SelectLayerColor({ name, layerItem, color }) {
  return (
    <div className="tg-flex width100 align-center tg-no-form-group-margin">
      <ReactColorField name={name} />
      <div
        style={{
          flex: 1,
          padding: 5,
          marginLeft: 5,
          background: color,
          color: determineBlackOrWhiteTextColor(color),
          textAlign: "center"
        }}
      >
        {layerItem}
      </div>
    </div>
  );
}
