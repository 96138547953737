/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment dataRowFragment on dataRow {
    id
    index
    key
    rowValues
    dataRowInventoryItems {
      id
      inventoryItem {
        id
        containerArray {
          id
          name
          barcode {
            id
            barcodeString
          }
        }
        aliquotContainer {
          id
          name
          barcode {
            id
            barcodeString
          }
        }
      }
    }
    dataRowJ5Items {
      id
      name
      j5Item {
        id
        j5RunConstruct {
          id
          name
          j5Report {
            id
            name
          }
          sequence {
            id
            name
            size
          }
        }
        j5AssemblyPiece {
          id
          name
          type
          j5AssemblyPieceParts {
            id
            j5InputPart {
              id
              j5InputSequence {
                id
                sequence {
                  id
                  polynucleotideMaterialId
                  polynucleotideMaterial {
                    id
                    name
                  }
                }
              }
            }
          }
          sequence {
            id
            polynucleotideMaterialId
            polynucleotideMaterial {
              id
              name
            }
          }
          j5Report {
            id
            name
          }
          j5ConstructAssemblyPieces {
            id
            j5RunConstruct {
              id
              name
            }
          }
        }
      }
    }
  }
`;
