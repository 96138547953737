/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import client from "../../../src-shared/apolloClient";
import { getApolloMethods } from "@teselagen/apollo-methods";
import {
  Button,
  Checkbox,
  InputGroup,
  Intent,
  Classes,
  FormGroup
} from "@blueprintjs/core";
import classNames from "classnames";
import { camelCase } from "lodash";
import classTypes from "../../../../server/src/seedData/build/dictionaries/classType.json";
const { query } = getApolloMethods(client);

export const modelMap = {};
classTypes.map(ct => (modelMap[ct.name] = ct.code));

export const classesWithUnit = classTypes.flatMap(type =>
  type.requireUnit ? [type.code, type.name] : []
);

const getChar = value => {
  return (value + 1 + 9).toString(36).toUpperCase();
};

const noop = () => {};

const renderItemWithCol = (index, text, onClick = noop) => (
  <div
    key={index}
    className="dropdown-with-header-container"
    onClick={() => onClick()}
  >
    <div className="dropdown-menu-item">
      <p className="dropdow-header-col">{`Col: ${getChar(index)}`}</p>
      <p className="dropdow-content">{text.split("__")[0]}</p>
    </div>
  </div>
);

const renderPlainItemWithCol = (index, text) =>
  `[Col: ${getChar(index)}]  ${text.split("__")[0]}`;

export { getChar, renderItemWithCol, renderPlainItemWithCol };

export const getExtendedSchema = async inputSchema => {
  const filter = {
    variables: {
      filter: {
        id: inputSchema.map(el => {
          return el.subClass;
        })
      }
    }
  };
  const columns = "id name nid __typename";
  const resultsDescriptors = query(["descriptorType", columns], filter);
  const resultsMeasurements = query(["measurementType", columns], filter);
  const resultsAssaySubjectClasses = query(
    ["assaySubjectClass", columns],
    filter
  );
  const resultsComputedValues = query(["computedValueType", columns], filter);
  const values = await Promise.all([
    resultsDescriptors,
    resultsMeasurements,
    resultsAssaySubjectClasses,
    resultsComputedValues
  ]);
  const complementSchema = [].concat.apply([], values);

  const extendedSchema = [];
  for (let i = 0; i < complementSchema.length; i++) {
    const sameIdInCS = inputSchema.filter(el => {
      return (
        el.subClass === complementSchema[i].id &&
        complementSchema[i].__typename === el.class
      );
    });
    if (sameIdInCS.length > 0) {
      extendedSchema.push({
        officialFieldName: complementSchema[i].name,
        importFileSetName: sameIdInCS[0].name,
        subclassId: complementSchema[i].id,
        className: complementSchema[i].__typename,
        label: complementSchema[i].name,
        value: complementSchema[i].name
      });
    }
  }
  return extendedSchema;
};

export function renderInput(
  { type, label, value, dataset, key, removable, handleValue, removeField },
  disabled
) {
  switch (type) {
    case "inputField":
      return (
        <InputGroup
          name={camelCase(label)}
          data-test={`tg-${type}_${camelCase(label)}`}
          value={value}
          onChange={e => handleValue(e.target.value)}
        />
      );
    case "selectField":
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className={classNames(
              "bp3-select",
              "bp3-fill",
              ".bp3-large",
              ".bp3-minimal"
            )}
          >
            <select
              className={label}
              value={value}
              data-test={`tg-${type}_${camelCase(label)}`}
              onChange={e => {
                e.persist();
                handleValue(e.target.value);
              }}
              disabled={disabled}
            >
              <option value="" hidden>
                {label}
              </option>
              {dataset.loading && <option disabled>Loading.</option>}
              {dataset.data?.map((opt, index) => (
                <option value={opt.value} key={index} disabled={opt.disabled}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
          {removable && key && (
            <Button
              icon="cross"
              data-test={`tg-remove-button_${camelCase(label)}`}
              intent={Intent.DANGER}
              className={Classes.MINIMAL}
              onClick={removeField}
            />
          )}
        </div>
      );
    case "checkboxField":
      return (
        <Checkbox
          data-test={`tg-${type}_${camelCase(label)}`}
          checked={value}
          value={value}
          onChange={() => handleValue()}
        />
      );
    default:
      break;
  }
}

export const renderFormGroup = (element, enabled) => (
  <FormGroup
    key={element.key}
    intent={Intent.PRIMARY}
    label={element.field.label}
    labelInfo={enabled ? "(required)" : ""}
    disabled={!enabled}
  >
    {renderInput(element.field, !enabled)}
  </FormGroup>
);
