/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { fragmentToQuery, logGraphql } from "@teselagen/apollo-methods";
import { camelCase, get, isEmpty, keyBy, upperFirst } from "lodash";
import { branch, compose, withHandlers, withProps } from "recompose";
import {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  useQuery
} from "@apollo/client";
import {
  BaseTProps,
  convertInputToOutput,
  getVariables,
  WithLoadingHoc,
  WithQueryOptions
} from "./utils";

/**
 * T represents the type of the resulting query
 * TProps represents the type of the props that will be passed to the wrapped component
 */
export default function withQuery<
  TEntity = any,
  TProps extends BaseTProps = {}
>(
  inputFragment: string | [string, string] | DocumentNode,
  withQueryOptions: WithQueryOptions = {
    showError: true,
    withLoadingHoc: WithLoadingHoc
  }
) {
  const { withLoadingHoc = WithLoadingHoc } = withQueryOptions;

  if (
    withQueryOptions.useHasura &&
    !withQueryOptions.isSingular &&
    withQueryOptions.isPlural !== false
  )
    withQueryOptions.isPlural = true;
  const queryData = fragmentToQuery(inputFragment, withQueryOptions);

  const tableParamHandlers = {
    selectTableRecords:
      (props: {
        tableParams: {
          entities: TEntity[];
          selectedEntities: TEntity;
          changeFormValue: Function;
        };
      }) =>
      (ids: string[], keepOldEntities?: boolean) => {
        const {
          tableParams: { entities, selectedEntities, changeFormValue }
        } = props;
        setTimeout(function () {
          const key = get(entities, "[0].code") ? "code" : "id";
          const entitiesById: { [idOrCode: string]: TEntity } = keyBy(
            entities,
            key
          );
          const newIdMap: { [idOrCode: string]: TEntity } = {
            ...(keepOldEntities && selectedEntities)
          };
          ids.forEach(id => {
            const entity = entitiesById[id];
            if (!entity) return;
            // @ts-ignore
            newIdMap[id] = {
              entity
            };
          });
          changeFormValue("reduxFormSelectedEntityIdMap", newIdMap);
        });
      }
  };

  return compose(
    withProps((props: TProps) => {
      const useQueryOptions = getUseQueryOptions(
        props,
        withQueryOptions,
        queryData
      );

      /** This line is to match the previous HOC behavior, we could return the data which handles the skipped case */
      if (useQueryOptions.skip) {
        return {};
      }

      const queryResults = useQuery<TEntity>(
        queryData.gqlQuery as DocumentNode,
        useQueryOptions
      );

      const propsToReturn = mapUseQueryToProps(
        props,
        withQueryOptions,
        queryData,
        queryResults
      );

      return propsToReturn;
    }),
    branch(
      (props: { tableParams: any }) => props.tableParams,
      withHandlers(tableParamHandlers)
    ),
    withLoadingHoc({
      showError: withQueryOptions.showError,
      showLoading: withQueryOptions.showLoading,
      queryNameToUse: queryData.queryNameToUse,
      inDialog: withQueryOptions.inDialog
    })
  );
}

const getUseQueryOptions = <TProps extends BaseTProps = {}>(
  props: TProps,
  withQueryOptions: WithQueryOptions,
  queryData: ReturnType<typeof fragmentToQuery>
) => {
  /** Run skip function first, and if its true don't execute anything else */
  let skipQuery = false;
  if (typeof withQueryOptions.skip === "function") {
    skipQuery = withQueryOptions.skip(props);
  } else {
    skipQuery = !!withQueryOptions.skip;
  }

  if (skipQuery) {
    return { skip: true };
  }

  let variables = getVariables(props, {
    queryOptions: withQueryOptions.options,
    queryNameToUse: queryData.queryNameToUse,
    getIdFromParams: withQueryOptions.getIdFromParams,
    variables: withQueryOptions.variables
  });
  const {
    additionalFilter,
    fetchPolicy,
    pollInterval,
    notifyOnNetworkStatusChange
  } = props;
  let extraOptions = withQueryOptions.options || {
    variables: undefined,
    context: undefined
  };
  if (typeof extraOptions === "function") {
    extraOptions = extraOptions(props) || {
      variables: undefined,
      context: undefined
    };
  }

  const { variables: extraOptionVariables, ...otherExtraOptions } =
    extraOptions;

  if (withQueryOptions.useHasura) {
    const { pageSize, pageNumber, sort } = variables;
    const hasuraVariables = {
      where: additionalFilter,
      ...(pageSize && { limit: pageSize }),
      ...(pageNumber && {
        ...(pageSize && { offset: pageSize * Math.max(pageNumber - 1, 0) })
      }),
      ...(sort && { order_by: convertInputToOutput(sort) })
    };
    variables = hasuraVariables;
  } else if (
    get(variables, "filter.entity") &&
    get(variables, "filter.__objectType") === "query" &&
    get(variables, "filter.entity") !== queryData.modelName
  ) {
    console.error("filter model does not match fragment model!");
  }

  const context = otherExtraOptions?.context || {};

  const useQueryOptions: QueryHookOptions = {
    ...(!isEmpty(variables) && { variables }),
    fetchPolicy: fetchPolicy || "network-only",
    nextFetchPolicy: "cache-first",
    ssr: false,
    pollInterval,
    skip: skipQuery,
    notifyOnNetworkStatusChange,
    // This will refetch queries whose data has been messed up by other cache updates. https://github.com/apollographql/react-apollo/pull/2003
    partialRefetch: true,
    ...otherExtraOptions,
    context: {
      ...context,
      hasura: { gqlRewriter: !withQueryOptions.useHasura }
    }
  };
  return useQueryOptions;
};

const mapUseQueryToProps = <TProps extends BaseTProps = {}>(
  props: TProps,
  withQueryOptions: WithQueryOptions,
  queryData: ReturnType<typeof fragmentToQuery>,
  queryResults: QueryResult
) => {
  const { tableParams } = props;
  const format = withQueryOptions.useHasura ? ".nodes" : ".results";
  const { data, ...queryResultsWithoutData } = queryResults;

  const results = get(
    data,
    queryData.nameToUse + (withQueryOptions.isPlural ? format : "")
  );

  // TODO: Hasura queries don't seem to be returning total results
  const totalResults = withQueryOptions.isPlural
    ? get(data, queryData.nameToUse + ".totalResults", 0)
    : results && 1;
  const newData = {
    ...queryResultsWithoutData,
    ...data,
    totalResults,
    //adding these for consistency with withItemsQuery
    entities: results,
    entityCount: totalResults,
    ["error" + upperFirst(queryData.nameToUse)]: queryResults.error,
    ["loading" + upperFirst(queryData.nameToUse)]: queryResults.loading
  };

  const variables = getVariables(props, {
    queryNameToUse: queryData.queryNameToUse,
    queryOptions: withQueryOptions.options,
    getIdFromParams: withQueryOptions.getIdFromParams,
    variables: withQueryOptions.variables
  });

  // apollo does not clear data immediately when variables are changed, stale data is passed down. we are manually
  // clearing it here so that our loading helper will work properly
  if (
    variables.id &&
    get(newData, "entities.id") &&
    get(newData, "entities.id") !== variables.id
  ) {
    newData.loading = true;
    newData.entities = undefined;
    newData.entityCount = undefined;
  }

  if (!newData.loading) {
    logGraphql(queryData.modelName, results, variables);
  }

  let newTableParams;
  if (tableParams && !tableParams.entities && !tableParams.isLoading) {
    const entities = results;

    newTableParams = {
      ...tableParams,
      isLoading: newData.loading,
      entities,
      entityCount: totalResults,
      onRefresh: newData.refetch,
      variables,
      fragment: queryData.fragment
    };
  }

  const propsToReturn = {
    ...(newTableParams && { tableParams: newTableParams }),
    data: newData,
    [queryData.queryNameToUse]: newData,
    [queryData.nameToUse]: results,
    [queryData.nameToUse + "Error"]: newData.error,
    [queryData.nameToUse + "Loading"]: newData.loading,
    [queryData.nameToUse + "Count"]: totalResults,
    [camelCase("refetch_" + queryData.nameToUse)]: newData.refetch,
    fragment: queryData.fragment,
    gqlQuery: queryData.gqlQuery
  };

  return {
    ...propsToReturn,
    ...(withQueryOptions.props &&
      withQueryOptions.props({
        ...queryResultsWithoutData,
        ...propsToReturn,
        ownProps: props
      }))
  };
};
