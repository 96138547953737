/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import store from "../redux/store";
import { handleSequenceSave } from "../utils/handleSequenceSave";

export const onCreateNewFromSubsequence = (oveSeqData, { selectionLayer }) => {
  store.dispatch({
    type: "TG_SHOW_MODAL",
    name: "createNewSequenceFromRangeDialog",
    props: {
      noTitle: true,
      parentSequenceId: oveSeqData.id,
      onCancel: () =>
        store.dispatch({
          type: "TG_HIDE_MODAL",
          name: "createNewSequenceFromRangeDialog" //you'll need to pass a unique dialogName prop to the compoennt
        }),
      onCreateFromRangeFinished: ({ id, ...rest }) => {
        return handleSequenceSave({
          //we re-use our internal handleSequenceSave to save the new sequence data
          id,
          doNotShowSeqSaveMsgs: true, //but we don't want to show the save messages
          sequenceData: { ...oveSeqData, id, ...rest }
        });
      },
      initialValues: {
        name: `${oveSeqData.name} - ${selectionLayer.start +
          1}:${selectionLayer.end + 1}`,
        circular: oveSeqData.circular,
        description: oveSeqData.description
      }
    } // pass props to the wrapped component here :)
  });
};
