/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  dataItemTypeCodeToModel,
  inventoryItemTypeCodeToModel
} from "../../../../../tg-iso-shared/src/utils/inventoryUtils";

export function getOutputModel(output) {
  const {
    inventoryItemTypeCode,
    dataItemTypeCode
  } = output.workflowToolOutputDefinition.taskIoType;
  const model =
    inventoryItemTypeCodeToModel[inventoryItemTypeCode] ||
    dataItemTypeCodeToModel[dataItemTypeCode];
  return model;
}
