/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { MenuItem } from "@blueprintjs/core";
import React from "react";
import useTgQuery from "../../../src-shared/apolloUseTgQuery";
import PrintCustomExternalLabel from "../../../src-shared/ExternalIntegrations/PrintCustomExternalLabelDialog";
import integrationOptionsFragment from "../../../src-shared/fragments/integrationOptionsFragment";
import { showDialog } from "../../../src-shared/GlobalDialog";

function PrintLabelMenuItem({ records, refetch, skipOuter }) {
  const inner = (
    <React.Fragment>
      <MenuItem
        text="Default Label"
        onClick={async () => {
          const { default: ModalComponent } = await import(
            "../Dialogs/PrintLabelDialog"
          );
          showDialog({
            ModalComponent,
            modalProps: {
              refetch,
              itemIds: records.map(r => r.id),
              isContainerArray: records[0]?.__typename === "containerArray"
            }
          });
        }}
      />
      <MenuItem text="Custom Label Integrations">
        <CustomLabelIntegrationsMenuItem records={records} />
      </MenuItem>
    </React.Fragment>
  );
  if (skipOuter) return inner;
  return (
    <MenuItem text="Print Label" icon="print">
      {inner}
    </MenuItem>
  );
}

function CustomLabelIntegrationsMenuItem({ records }) {
  const { integrations, ...rest } = useTgQuery(integrationOptionsFragment, {
    variables: {
      filter: {
        integrationTypeCode: "CUSTOM_LABEL_PRINTING"
      }
    }
  });
  if (rest.loading) {
    return <MenuItem text="Loading..." disabled />;
  }
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  if (!integrations.length) {
    return <MenuItem text="No integrations have been setup." disabled />;
  }
  return integrations.map(integration => {
    return (
      <MenuItem
        text={integration.name}
        key={integration.id}
        onClick={() => {
          showDialog({
            ModalComponent: PrintCustomExternalLabel,
            modalProps: {
              integrationId: integration.id,
              records
            }
          });
        }}
      />
    );
  });
}

export default PrintLabelMenuItem;
