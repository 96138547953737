/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { MenuItem, Menu } from "@blueprintjs/core";
import { compose } from "recompose";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import { deleteEquipmentAndContents } from "../../../utils/deletePositionUtils";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import AddNewEquipmentDialog from "../../Dialogs/AddNewEquipmentDialog";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import { pushHelper } from "../../../../src-shared/utils/pushHelper";
import getExportEquipmentMenuItems from "./getExportEquipmentMenuItems";
import EquipmentRecordView from "../../Record/EquipmentRecordView";

const deleteEquipment = (selectedRecords, refetch) => async () => {
  try {
    const equipmentIds = selectedRecords.map(r => r.id);
    await deleteEquipmentAndContents(equipmentIds);
    await refetch();
  } catch (e) {
    console.error(e);
    window.toastr.error("Error deleting equipment.");
  }
};

const contextMenu = ({
  selectedRecords,
  openMenuItem,
  tagMenuItem,
  projectMenuItem,
  refetch,
  extraContextItems,
  editExtendedPropertiesMenuItem
}) => {
  const deleteMenuItem = (
    <MenuItem
      key="delete"
      icon="trash"
      onClick={deleteEquipment(selectedRecords, refetch)}
      text="Delete"
    />
  );

  const recordIds = selectedRecords.map(r => r.id);

  return [
    ...(selectedRecords.length === 1 ? [openMenuItem] : []),
    deleteMenuItem,
    tagMenuItem,
    editExtendedPropertiesMenuItem,
    projectMenuItem,
    ...getExportEquipmentMenuItems({ recordIds }),
    ...extraContextItems
  ];
};

const EquipmentLibrary = props => {
  const { tableParams } = props;

  const showEquipmentUpload = () => {
    showDialog({
      modalType: "EQUIPMENT_UPLOAD",
      modalProps: {
        refetch: tableParams.onRefresh
      }
    });
  };

  return (
    <AbstractLibrary
      {...props}
      RecordViewInspector={EquipmentRecordView}
      libraryName="equipment"
      model="equipmentItem"
      newItemMenu={
        <Menu>
          <MenuItem
            text="New Equipment"
            onClick={() => {
              showDialog({
                ModalComponent: AddNewEquipmentDialog
              });
            }}
          />
          <MenuItem
            text="New Freezer"
            onClick={e => {
              pushHelper(e, "/new-freezer");
            }}
          />
        </Menu>
      }
      showUploadFunction={showEquipmentUpload}
      isLibraryTable
      withExport
      contextMenu={contextMenu}
    />
  );
};

const schema = {
  model: "equipmentPathView",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "equipmentType.name", type: "string", displayName: "Type" },
    { path: "make", type: "string", displayName: "Make" },
    { path: "model", type: "string", displayName: "Model" },
    { isHidden: true, path: "description", type: "string" },
    { isHidden: true, path: "manufacturer", type: "string" },
    { isHidden: true, path: "serialNumber", type: "string" },
    { isHidden: true, path: "serviceContactInfo", type: "string" },
    { isHidden: true, path: "serviceContractExpiration", type: "timestamp" },
    { isHidden: true, path: "warrantyExpiration", type: "timestamp" },
    {
      path: "fullPath",
      type: "string",
      displayName: "Location"
    }
  ]
};

const fragment = gql`
  fragment equipmentLibraryFragment on equipmentPathView {
    id
    name
    fullPath
    make
    model
    description
    manufacturer
    serialNumber
    serviceContactInfo
    serviceContractExpiration
    warrantyExpiration
    equipmentType {
      code
      name
    }
  }
`;

export default compose(
  libraryEnhancer({
    schema,
    updateableModel: "equipmentItem",
    fragment: fragment
  })
)(EquipmentLibrary);
