/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getRootCardId,
  getCustomParametersOfReaction,
  getNeighborBinId
} from "../../../../../tg-iso-design/selectors/designStateSelectors";

export default (state, binId, inputCard, updateBin) => {
  const rootCardId = getRootCardId(state);
  const reactionId = inputCard.inputReactionId;
  const params = getCustomParametersOfReaction(state, reactionId);
  const overlapLength = params.gibsonOverlapBps;
  const overlapLengthIsOdd = overlapLength % 2 === 1;

  updateBin({
    id: binId,
    fro: Math.floor(overlapLength / 2),
    extra3PrimeBps: overlapLengthIsOdd ? -1 : 0
  });

  const prevBinId = getNeighborBinId(state, rootCardId, binId, false);

  if (prevBinId) {
    updateBin({
      id: prevBinId,
      fro: -Math.floor(overlapLength / 2),
      extra5PrimeBps: overlapLengthIsOdd ? -1 : 0
    });
  }
};
