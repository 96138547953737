/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Redirect } from "react-router-dom";
import recordViewEnhancer from "../../recordViewEnhancer";

const typeToRoute = {
  RNA: "/rna-sequences"
};

const defaultRoute = "/dna-sequences";

function RerouteToProperSequenceRecordView({ sequence, location }) {
  return (
    <Redirect
      to={{
        ...location,
        pathname: `${typeToRoute[sequence.sequenceTypeCode] || defaultRoute}/${
          sequence.id
        }`
      }}
    />
  );
}

export default recordViewEnhancer(["sequence", "id sequenceTypeCode"])(
  RerouteToProperSequenceRecordView
);
