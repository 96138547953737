/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";
import { isEmpty } from "lodash";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";

export type UniversalSearchEntity = {
  id: string;
  __typename: string;
  name?: string;
  updatedAt: string;
  barcode?: { id: string; barcodeString: string };
  aliases?: { id: string; name: string }[];
  projectItems?: {
    id: string;
    project: {
      id: string;
      name: string;
      color: string;
      projectRoles: { id: string; userId: string };
    };
  }[];
};

export type ModelMap = {
  [model: string]: {
    displayName: string;
    route?: string;
    entityCount: number;
    entities: UniversalSearchEntity[];
  };
};

// All entities in the model map are flattened to make it easier to work with
export type FlatModelMapEntity = Omit<ModelMap[string], "entities"> &
  UniversalSearchEntity;

type TagFilterParams = {
  page: number | undefined;
  tags: string[];
};

const parsePartialResult = (partialResult: string) => {
  const parsedResult = {};
  const lines = partialResult.split("\n");
  for (const line of lines) {
    if (line !== "") {
      const parsedObject = JSON.parse(line);
      Object.assign(parsedResult, parsedObject);
    }
  }
  return parsedResult;
};

/**
 * This function fetches the search results from the server and updates the model map with the results.
 * It also updates the model map with partial results while the query is still loading.
 */
const searchWithTemporaryResults = async (
  searchTerm: string,
  tags: string[],
  setModelMap: Function,
  options: {
    signal?: AbortSignal;
  }
): Promise<ModelMap> => {
  const response = await fetch(
    `/tg-api/search/all?searchTerm=${searchTerm}&tags=${tags.join(",")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...getRequestHeaderKeys()
      },
      signal: options.signal
    }
  );

  const reader = response.body?.getReader();
  if (!reader) {
    console.error("No reader");
    return {};
  }
  const decoder = new TextDecoder();
  let result = "";

  while (true) {
    const { value, done } = await reader?.read();
    if (done) {
      const finalResult = parsePartialResult(result);
      setModelMap({});
      return finalResult;
    }
    // Decode the Uint8Array value to a string
    result += decoder.decode(value, { stream: true });
    try {
      const parsedPartialResult = parsePartialResult(result);
      setModelMap(parsedPartialResult);
    } catch (e) {
      // With slow connections, the response might be split in the middle of a JSON object
      // We can ignore the error and keep reading the response
    }
  }
};

export function useGetFilteredRecords() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [tagFilterParams, setTagFilterParams] = useState<TagFilterParams>({
    page: undefined,
    tags: []
  });

  // Used while the query is still loading to show a temporary result
  const [tempModelMap, setTempModelMap] = useState<ModelMap>({});

  const queryData = useQuery({
    queryKey: ["universalSearch", debouncedSearchTerm, tagFilterParams.tags],
    queryFn: ({ signal }) => {
      return searchWithTemporaryResults(
        debouncedSearchTerm,
        tagFilterParams.tags,
        setTempModelMap,
        {
          signal
        }
      );
    },
    enabled: debouncedSearchTerm !== "" || !isEmpty(tagFilterParams.tags),
    refetchOnMount: false,
    retry: false, // For this one retrying behaves weirdly with the streaming
    keepPreviousData: true, // Keeps data from previous query while loading
    staleTime: 1000 * 60 * 1 // Time after which the query with the same key is re-fetched. Otherwise it uses the cache if available
  });

  const hasQuery = searchTerm !== "" || !isEmpty(tagFilterParams.tags);
  const isLoadingIndicator =
    (hasQuery && (queryData.isLoading || queryData.isPreviousData)) ||
    searchTerm !== debouncedSearchTerm; // We also want to show a loading state if the search term has changed but the query has not yet started due to the debounce

  const displayModelMap = queryData.isLoading
    ? tempModelMap
    : queryData.data || {};

  return {
    searchTerm,
    setSearchTerm,
    isLoading: isLoadingIndicator,
    modelMap: displayModelMap,
    hasQuery,
    tagFilterParams,
    setTagFilterParams
  };
}
