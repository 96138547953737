/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, last } from "lodash";

export default function getAvatarIconPath(user) {
  const path = get(user, "avatarFile.path") || "";
  // is http file online
  if (!path.includes("user_uploads")) return path;
  // TODO fix to build server until we get shared image folder
  const trimmedPath = `/user_uploads/` + last(path.split("/"));
  return trimmedPath;
}
