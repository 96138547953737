/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Popover } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";

// import NotificationManager from "../components/NotificationManager";
import NotificationLineItem from "./NotificationLineItem";
import withQuery from "../withQuery";
import actions from "../redux-shared/actions";
import "./style.css";
import { withProps } from "recompose";
import notificationFragment from "./notificationFragment";
import { get } from "lodash";
import { safeUpsert } from "../apolloMethods";
import isMobile from "is-mobile";

const toastdNotes = {};

const toastrByIntent = {
  success: note => {
    let extraOpts = {};

    if (note.link) {
      extraOpts = {
        link: note.link,
        linkText: "Open"
      };
    }

    window.toastr.success(note.message, {
      ...extraOpts
    });
  },
  error: note => {
    let extraOpts = {};

    if (note.link) {
      extraOpts = {
        link: note.link,
        linkText: "Open"
      };
    }

    window.toastr.error(note.message, {
      ...extraOpts
    });
  }
};

const WrappedInner = compose(
  withRouter,
  connect(state => ({
    date: state.platform.ui.notificationManager.date
  }))
)(function NotificationPopoverInner(props) {
  const { data, notifications, close, date, history } = props;
  // tnw: we use fakeTempLoading to show a loading spinner when the user clicks the refresh button to give feedback that the refresh is happening
  const [fakeTempLoading, setFakeTempLoading] = React.useState(false);
  useEffect(() => {
    const { data } = props;
    setFakeTempLoading(true);
    setTimeout(() => {
      setFakeTempLoading(false);
    }, 600);
    data?.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let inner;
  if (!notifications || !notifications.length) {
    return (
      <div className="no-notifications">
        You don't have any notifications yet!
      </div>
    );
  } else {
    inner = notifications.map(notification => {
      return (
        <NotificationLineItem
          onOpen={async () => {
            await safeUpsert("notification", {
              id: notification.id,
              hasBeenRead: true
            });
            data.refetch();
            close();
          }}
          key={notification.id}
          history={history}
          notification={notification}
          time={date[notification.id]}
        />
      );
    });
  }
  return (
    <div className="notification-manager">
      <div className="notification-manager-header">
        <div className="notification-manager-title">
          Notifications
          <Button
            loading={data?.loading || fakeTempLoading}
            style={{ marginLeft: 4, marginTop: -2 }}
            onClick={() => {
              setFakeTempLoading(true);
              setTimeout(() => {
                setFakeTempLoading(false);
              }, 600);
              data?.refetch();
            }}
            color="green"
            small
            minimal
            icon="refresh"
          />
        </div>
        <div>
          <Button
            onClick={async () => {
              try {
                await safeUpsert(
                  "notification",
                  notifications.map(n => ({
                    id: n.id,
                    hasBeenRead: true
                  }))
                );
                data.refetch();
              } catch (error) {
                console.error(`error:`, error);
              }
            }}
            disabled={data && data.loading}
            intent="primary"
            small
            minimal
          >
            Mark all as read
          </Button>
          <Button
            onClick={() => {
              history.push("/settings/notifications");
              close();
            }}
            // intent="primary"
            icon="settings"
            small
            minimal
          />
        </div>
      </div>
      <div className="notification-manager-body">{inner}</div>
      <div className="notification-manager-footer">
        <Button
          data-test="openNotificationLib"
          onClick={() => {
            close();
            history.push("/notifications");
          }}
          style={{ margin: 3 }}
          small
          minimal
          intent="primary"
        >
          See All
        </Button>
      </div>
    </div>
  );
});

export default compose(
  withRouter,
  connect(
    state => ({
      isOpen: state.platform.ui.notificationManager.isNotificationManagerOpen
    }),
    {
      open: actions.openNotifications,
      close: actions.closeNotifications
    }
  ),
  withQuery(notificationFragment, {
    isPlural: true,
    fetchPolicy: "network-only",
    options: props => ({
      variables: {
        filter: {
          userId: get(props, "currentUser.id")
        },
        pageSize: 10,
        sort: ["-createdAt"]
      },
      notifyOnNetworkStatusChange: true,

      pollInterval:
        Number(window.frontEndConfig.notificationInterval) <= 0
          ? 30000
          : Number(window.frontEndConfig.notificationInterval) || 30000
    })
  }),
  withProps(props => ({
    unreadCount: props.notifications
      ? props.notifications.filter(n => !n.hasBeenRead).length
      : 0,
    activeCount: 0
  }))
)(
  class NotificationPopoverOuter extends React.Component {
    state = {
      loading: false
    };

    componentDidUpdate() {
      const { notifications = [], data } = this.props;
      const readByToastr = [];

      if (!data) return;
      if (notifications.length < 1) return;

      const updateReadNotes = async (readNotes, data) => {
        await safeUpsert("notification", readNotes);
        data.refetch();
      };

      notifications.forEach(note => {
        if (!note.hasBeenRead && !toastdNotes[note.id]) {
          toastdNotes[note.id] = true; //prevents duplicate toasts
          if (toastrByIntent[note.notificationIntent]) {
            toastrByIntent[note.notificationIntent](note);
            readByToastr.push({ id: note.id, hasBeenRead: true });
          }
        } else if (note.hasBeenRead) {
          delete toastdNotes[note.id]; // free up memory
        }
      });

      if (readByToastr.length > 0) {
        updateReadNotes(readByToastr, data);
      }
    }

    render() {
      const {
        isOpen,
        open,
        activeCount,
        unreadCount,
        data,
        networkStatus,
        notifications = [],
        notificationsFromProps = [], //just used to test the ui locally
        close,
        history
      } = this.props;
      if (data && data.refetch) {
        window.refetchNotifications = data.refetch;
        if (window.Cypress) window.Cypress.refetchNotifications = data.refetch;
      }

      return (
        <Popover
          disabled={isMobile()}
          content={
            <WrappedInner
              {...{
                data,
                close,
                notifications: [...notifications, ...notificationsFromProps]
              }}
            />
          }
          onInteraction={isOpen => {
            if (isOpen) {
              open();
            } else {
              close();
            }
          }}
          isOpen={isOpen}
        >
          <div
            data-test="notification-popover-button"
            style={{ position: "relative" }}
          >
            <Button
              onClick={() => {
                isMobile() && history.push("/notifications");
              }}
              data-test={`notification-popover-unreadCount=${unreadCount}`}
              loading={
                data.loading ||
                (networkStatus || (data && data.networkStatus)) === 4
              }
              icon="notifications"
              minimal
            />
            {!this.state.loading && [
              <NotificationCircle
                data-test="activeCount"
                key="activeCount"
                style={{
                  position: "absolute",
                  backgroundColor: "blue",
                  left: -1,
                  bottom: -3
                }}
                count={activeCount}
              />,
              <NotificationCircle
                data-test="unreadCount"
                key="unreadCount"
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  right: -1,
                  bottom: -3
                }}
                count={unreadCount}
              />
            ]}
          </div>
        </Popover>
      );
    }
  }
);

const NotificationCircle = ({ count, style = {}, ...rest }) =>
  count ? (
    <div
      style={{
        backgroundColor: "red",
        border: "1px solid white",
        borderRadius:
          "13px /* one half of ( (border * 2) + height + padding ) */",
        color: "white",
        font: "11px/13px Helvetica, Verdana, Tahoma",
        minWidth: "12px",
        padding: "0px 3px 0 3px",
        textAlign: "center",
        height: 14,
        fontWeight: "bolder",
        ...style
      }}
      {...rest}
    >
      {count}
    </div>
  ) : null;

// const noop = () => {};
