/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { Tooltip, Position } from "@blueprintjs/core";
import { CheckboxField, DialogFooter } from "@teselagen/ui";
import { getItemOfType } from "../../../../../../tg-iso-design/selectors/designStateSelectors";

class GeneSelection extends React.Component {
  render() {
    const {
      elements,
      nextPage,
      designState,
      selectedElements,
      hideModal,
      setSelectedElements
    } = this.props;

    return (
      <div className="bp3-dialog-body">
        <h6>The following genes will be sent to Crick-It:</h6>
        <form onSubmit={this.onSubmit}>
          {elements.map(el => {
            const state = { design: designState };
            const bin = getItemOfType(state, "bin", el.binId);
            const { crickitValidationMsg } = el;

            return (
              <Tooltip
                className="crickit-validation"
                position={Position.RIGHT}
                content={<span>{crickitValidationMsg}</span>}
                disabled={!crickitValidationMsg}
                key={`${el.name}_${el.id}_tooltip`}
              >
                <CheckboxField
                  name={`${el.name}_${el.id}`}
                  label={
                    <span>
                      <b>{el.name}</b> in <b>bin "{bin.name}"</b> at{" "}
                      <b>row {el.index + 1}</b> in Card 1
                    </span>
                  }
                  key={`${el.name}_${el.id}`}
                  defaultValue={!crickitValidationMsg}
                  disabled={crickitValidationMsg}
                  onChange={(evt, checked) => {
                    setSelectedElements({
                      ...selectedElements,
                      [el.id]: checked
                    });
                  }}
                />
              </Tooltip>
            );
          })}
          <DialogFooter
            hideModal={hideModal}
            text="Confirm"
            onClick={nextPage}
            disabled={
              Object.values(selectedElements).filter(elVal => elVal === true)
                .length === 0
            }
          />
        </form>
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: "crickitGeneSelectionForm", // a unique name for this form
    enableReinitialize: true
  })
)(GeneSelection);
