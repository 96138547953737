/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { inject, observer } from "mobx-react";
import { Dialog } from "@blueprintjs/core";

export default inject("mainStore")(
  observer(props => {
    const {
      mainStore: {
        modal: { type, icon, open, hideModal, title }
      },
      mobXModalComponents = {}
      // history
    } = props;
    const Body = (type && mobXModalComponents[type]) || "";
    return (
      // Couldnt find anything related to the eslint suggestion about using wrapDialog instead of Dialog.
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        icon={icon || ""}
        isOpen={open}
        onClose={hideModal}
        title={title || ""}
        style={{ width: "fit-content", maxWidth: "70%", minWidth: "30%" }}
      >
        {type && <Body {...props} />}
      </Dialog>
    );
  })
);
