/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { isEmpty, keyBy } from "lodash";
import { unparse } from "papaparse";
import React from "react";
import { compose } from "recompose";
import { withSelectedEntities, withSelectTableRecords } from "@teselagen/ui";
import { getSequence } from "../../../../../../tg-iso-shared/src/utils/getSequence";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { download } from "../../../../../src-shared/utils/downloadTest";
import { formatTwistSequencesForExporting } from "../../../Bioshop/Vendors/VendorFunctions";
import SequenceScoringTableAndSummary from "../../../SequenceScoringComponents/SequenceScoringTableAndSummary";
import { sequenceScoringTableName } from "../../../SequenceScoringComponents/SequenceScoringTable";
import { handleScoreUpdateAutoselect } from "../../../SequenceScoringComponents/utils";
/*

const map = {
  sequenceId: taskId
}

const activeTaskIds = uniq(Object.values(map))

when tasks come back in polling function
make sure task id matches the maps task id for the sequence
otherwise it has been rerun

*/

function formatSequenceForScoring(item, vector) {
  return {
    id: item.id,
    name: item.name,
    circular: item.circular,
    sequence: getSequence(item),
    ...(vector && { vector: vector.value })
  };
}

class ScoreAndPrice extends React.Component {
  state = {
    checkingScoreResults: {}
  };
  componentDidMount() {
    const { sequencesToScore = [] } = this.props;

    this.sequenceScoringMap = this.props.sequenceScoringMap || {};
    this.scoringIdMap = this.props.scoringIdMap || {};
    this.scoredSequenceMap = this.props.scoredSequenceMap || {};
    this.sequenceErrorMap = this.props.sequenceErrorMap || {};
    this.scoringCheckCountMap = this.props.scoringCheckCountMap || {};

    const needsToScore = sequencesToScore.some(s => {
      return (
        !this.scoredSequenceMap[s.id] &&
        !this.sequenceErrorMap[s.id] &&
        !this.sequenceScoringMap[s.id]
      );
    });
    if (needsToScore) {
      this.scoreSequences(this.props.sequencesToScore);
    }

    const scoringPollingTimeMs = 3000;
    this.scoringPollingInterval = setInterval(() => {
      this.pollScoringTasks();
    }, scoringPollingTimeMs);
  }

  componentWillUnmount() {
    clearInterval(this.scoringPollingInterval);
  }

  pollScoringTasks = async () => {
    const {
      twistIntegration,
      sequencesToScore = [],
      stepFormProps: { change },
      pricebook = []
    } = this.props;

    if (isEmpty(this.sequenceScoringMap) || this.checkingScoreResults) return;

    this.checkingScoreResults = true;
    const scoringResultEndpoint = twistIntegration.integrationEndpoints.find(
      e => e.endpointTypeCode === "VENDOR__SCORING__RESULT"
    );
    const quoteEndpoint = twistIntegration.integrationEndpoints.find(
      e => e.endpointTypeCode === "VENDOR__QUOTE"
    );

    const keyedInputSeqs = keyBy(sequencesToScore, "id");
    await Promise.all(
      Object.keys(this.sequenceScoringMap).map(async seqId => {
        const scoringId = this.sequenceScoringMap[seqId];
        // if we have started scoring but have not gotten start scoring response from twist
        if (scoringId === true) return;

        const result = { id: seqId, scoringId };

        if (!this.scoringCheckCountMap[scoringId]) {
          this.scoringCheckCountMap[scoringId] = 1;
        }

        if (this.scoringCheckCountMap[scoringId] >= 20) {
          result.error = "Timed out fetching scoring result.";
        } else {
          this.scoringCheckCountMap[scoringId]++;

          try {
            const { data: scoringResult } =
              await window.triggerIntegrationRequest({
                endpointId: scoringResultEndpoint.id,
                method: "GET",
                params: {
                  scoringId,
                  callCount: this.scoringCheckCountMap[scoringId]
                }
              });

            const inputSeq = keyedInputSeqs[seqId];
            if (scoringResult.scored || scoringResult.error) {
              if (scoringResult.error) {
                result.error = scoringResult.error;
              } else if (!scoringResult.buildable) {
                result.error = "This sequence is not buildable";
              } else {
                try {
                  // sequence is good
                  const { selectedVectors = {} } = this.props;
                  const selectedVector = selectedVectors[inputSeq.id];
                  const formatted = formatSequenceForScoring(
                    inputSeq,
                    selectedVector
                  );
                  const { data } = await window.triggerIntegrationRequest({
                    endpointId: quoteEndpoint.id,
                    method: "POST",
                    data: {
                      pricebook,
                      sequence: formatted
                    }
                  });

                  const price = data.price;

                  result.scored = true;
                  result.scoringInfo = {
                    buildable: scoringResult.buildable,
                    info: scoringResult.info,
                    price
                  };
                } catch (error) {
                  console.error(`error in get pricing:`, error);
                  result.error = "Error getting price for sequence.";
                }
              }
            }
          } catch (err) {
            console.error(`error in get score result:`, err);
            result.error = err.message;
          }
        }

        if (result.scored || result.error) {
          this.finishScoringSequence(result);
          delete this.scoringCheckCountMap[scoringId];
        }
      })
    );

    change("scoringCheckCountMap", { ...this.scoringCheckCountMap });
    this.checkingScoreResults = false;
  };

  finishScoringSequence = seq => {
    const {
      stepFormProps: { change }
    } = this.props;
    if (this.isActiveScoringId(seq.id, seq.scoringId)) {
      if (seq.error) {
        this.sequenceErrorMap[seq.id] = seq.error;
      } else {
        delete this.sequenceErrorMap[seq.id];
        this.scoredSequenceMap[seq.id] = seq.scoringInfo;
      }
    }
    this.changeScoringStatus(seq, false);
    change("sequenceErrorMap", { ...this.sequenceErrorMap });
    change("scoredSequenceMap", { ...this.scoredSequenceMap });
  };

  componentDidUpdate() {
    handleScoreUpdateAutoselect(this);
  }

  isActiveScoringId = (sequenceId, scoringId) => {
    return this.sequenceScoringMap[sequenceId] === scoringId;
  };

  changeScoringStatus = (sequence, scoring) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const sequences = Array.isArray(sequence) ? sequence : [sequence];
    sequences.forEach(s => {
      if (scoring || s.scoring) {
        if (s.scoringId) {
          this.sequenceScoringMap[s.id] = s.scoringId;
        } else {
          this.sequenceScoringMap[s.id] = true;
        }
      } else {
        if (s.scoringId) {
          if (this.sequenceScoringMap[s.id] === s.scoringId) {
            delete this.sequenceScoringMap[s.id];
          }
        } else {
          delete this.sequenceScoringMap[s.id];
        }
      }
    });
    change("sequenceScoringMap", { ...this.sequenceScoringMap });
  };

  trackScoringId = (sequenceId, scoringId) => {
    const {
      stepFormProps: { change }
    } = this.props;
    this.scoringIdMap[sequenceId] = scoringId;
    change("scoringIdMap", { ...this.scoringIdMap });
  };

  scoreSequences = async (sequencesToScore, passedSelectedVectors) => {
    const {
      stepFormProps: { change },
      selectedVectors = {},
      twistIntegration
    } = this.props;

    const selectedVectorsToUse = passedSelectedVectors || selectedVectors;

    const formattedSequences = sequencesToScore.map(sequence => {
      const selectedVector = selectedVectorsToUse[sequence.id];
      const formatted = formatSequenceForScoring(sequence, selectedVector);
      return formatted;
    });
    try {
      const scoringEndpoint = twistIntegration.integrationEndpoints.find(
        e => e.endpointTypeCode === "VENDOR__SCORING"
      );

      this.changeScoringStatus(formattedSequences, true);
      await Promise.all(
        formattedSequences.map(async sequence => {
          let errorMsg;
          try {
            const { data: startScoringResult } =
              await window.triggerIntegrationRequest({
                endpointId: scoringEndpoint.id,
                method: "POST",
                data: {
                  sequence
                },
                requestTimeout: 45000
              });
            const { scoringId, error } = startScoringResult;
            // this will either get back an error or an active scoring id
            // we need to use this id to poll for the sequences to finish
            // for the ones that errored immediately stop scoring loader
            errorMsg = error;
            if (!error && !scoringId) {
              errorMsg = "Did not respond with scoring id";
            }
            if (!errorMsg && scoringId) {
              this.trackScoringId(sequence.id, scoringId);
              // add to map to track polling ids for each sequence
              this.changeScoringStatus({
                id: sequence.id,
                scoringId,
                scoring: true
              });
            }
          } catch (error) {
            errorMsg = error.message;
            console.error(`error in response from start scoring:`, error);
          }
          if (errorMsg) {
            const erroredSeq = { ...sequence, error: errorMsg };
            this.changeScoringStatus(erroredSeq);
            this.sequenceErrorMap[sequence.id] = errorMsg;
            change("sequenceErrorMap", { ...this.sequenceErrorMap });
          }
        })
      );
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message);
    }
  };

  rescoreFailedSequences = () => {
    const { sequencesToScore = [] } = this.props;
    const failedSeqs = sequencesToScore.filter(
      s => this.sequenceErrorMap[s.id]
    );
    if (failedSeqs.length) {
      this.scoreSequences(failedSeqs);
    }
  };

  generateVectorOptionsSelectComponent = sequence => {
    const {
      vectorOptions = [],
      selectedVectors = {},
      stepFormProps: { change }
    } = this.props;

    const handleChangeVector = op => {
      const newSelectedVectors = { ...this.props.selectedVectors };
      newSelectedVectors[sequence.id] = op;
      change("selectedVectors", newSelectedVectors);
      this.scoreSequences([sequence], newSelectedVectors);
    };
    const selectedVector = selectedVectors[sequence.id];
    const displayName =
      selectedVector && selectedVector.label
        ? selectedVector.label
        : "No Vector";

    function getMenu(options) {
      return options.map((op, i) => {
        if (op.options && !op.options.length) {
          return null;
        }
        return (
          <MenuItem
            key={i}
            text={op.label}
            onClick={() => {
              if (!op.options) {
                handleChangeVector(op);
              }
            }}
          >
            {op.options ? getMenu(op.options) : null}
          </MenuItem>
        );
      });
    }
    return (
      <div
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Popover
          content={
            <Menu>
              <MenuItem text="No Vector" onClick={() => handleChangeVector()} />
              {getMenu(vectorOptions)}
            </Menu>
          }
          position="right-bottom"
        >
          <Button text={displayName} rightIcon="caret-right" minimal small />
        </Popover>
      </div>
    );
  };

  exportOrder = () => {
    const {
      orderName,
      [sequenceScoringTableName + "SelectedEntities"]: selectedSequences = []
    } = this.props;
    const formattedSequences = selectedSequences.map(formatSequenceForScoring);
    const sequencesFormattedForExport =
      formatTwistSequencesForExporting(formattedSequences);
    const csv = unparse(sequencesFormattedForExport);
    download(csv, (orderName || "twist_order") + ".csv");
  };

  render() {
    const {
      sequencesToScore = [],
      vectorOptions = [],
      sequenceScoringMap = {},
      sequenceErrorMap = {},
      scoredSequenceMap = {},
      selectedVectors = {},
      Footer,
      footerProps,
      [sequenceScoringTableName + "SelectedEntities"]: selectedSequences = [],
      handleSubmit,
      onSubmit
    } = this.props;

    const nextDisabled =
      !isEmpty(sequenceScoringMap) || !selectedSequences.length;

    let totalPrice = 0;
    selectedSequences.forEach(s => {
      const scoredInfo = scoredSequenceMap[s.id];
      if (scoredInfo && scoredInfo.price) {
        totalPrice += scoredInfo.price;
      }
    });

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <SequenceScoringTableAndSummary
            {...{
              sequenceErrorMap,
              sequenceScoringMap,
              scoredSequenceMap,
              vectorOptions,
              selectedVectors,
              sequencesToScore,
              scoreSequences: this.scoreSequences,
              rescoreFailedSequences: this.rescoreFailedSequences,
              generateVectorOptionsSelectComponent:
                this.generateVectorOptionsSelectComponent,
              selectedSequences,
              totalPrice,
              vendorCode: "TWIST"
            }}
          />
        </div>
        <Footer
          {...footerProps}
          nextDisabled={nextDisabled}
          onNextClick={handleSubmit(values => {
            return onSubmit({
              ...values,
              sequencesToOrder: selectedSequences.map(seq => {
                let formatted = formatSequenceForScoring(seq);
                const scoredInfo = scoredSequenceMap[seq.id];
                if (scoredInfo) {
                  formatted = {
                    ...formatted,
                    ...scoredInfo
                  };
                }
                return formatted;
              })
            });
          })}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "selectAllSequences",
    "sequencesToScore",
    "sequenceScoringMap",
    "sequenceErrorMap",
    "scoredSequenceMap",
    "scoringIdMap",
    "selectedVectors",
    "orderName",
    "vectorOptions",
    "pricebook",
    "twistIntegration"
  ),
  withSelectedEntities(sequenceScoringTableName),
  withSelectTableRecords(sequenceScoringTableName)
)(ScoreAndPrice);
