/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { flatMap, get } from "lodash";
import {
  DataTable,
  withSelectedEntities,
  Loading,
  withSelectTableRecords
} from "@teselagen/ui";
import { compose } from "redux";
import { Callout, Intent } from "@blueprintjs/core";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";

import stepFormValues from "../../../../src-shared/stepFormValues";
import {
  dateModifiedColumn,
  unitColumns
} from "../../../../src-shared/utils/libraryColumns";

import gql from "graphql-tag";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

export const microbialMaterialSelectionPlateFragment = gql`
  fragment microbialMaterialSelectionPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    updatedAt
  }
`;

const microbialMaterialSelectionAliquotFragment = gql`
  fragment microbialMaterialSelectionAliquotFragment on aliquot {
    id
    isDry
    updatedAt
    sample {
      id
      material {
        id
        name
        materialTypeCode
        strain {
          id
          name
        }
      }
    }
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    aliquotContainer {
      id
      columnPosition
      rowPosition
      containerArray {
        id
        name
      }
    }
  }
`;

class SelectMaterials extends Component {
  state = {
    loadingDataTableMaterials: false,
    loadingContainerArrayMaterials: false
  };

  saveAliquotsToForm = () => {
    const {
      stepFormProps: { change },
      mmsSelectedAliquotsFromPlatesSelectedEntities: selectedAliquotsFromPlates = [],
      mmsSelectedAliquotsFromDataTablesSelectedEntities: selectedAliquotsFromDataTables = []
    } = this.props;
    const selectedAliquots = [];
    const addedIds = {};
    selectedAliquotsFromPlates.forEach(aliquot => {
      if (aliquot.aliquotContainer && !addedIds[aliquot.id]) {
        selectedAliquots.push(aliquot);
        addedIds[aliquot.id] = true;
      }
    });
    selectedAliquotsFromDataTables.forEach(aliquot => {
      if (aliquot.aliquotContainer && !addedIds[aliquot.id]) {
        selectedAliquots.push(aliquot);
        addedIds[aliquot.id] = true;
      }
    });
    change("selectedAliquots", selectedAliquots);
  };

  fetchInventoryMaterials = async records => {
    const {
      stepFormProps: { change },
      selectDataTableTableAliquots
    } = this.props;
    this.setState({
      loadingDataTableMaterials: true
    });
    try {
      change("inventoryAliquots", []);
      selectDataTableTableAliquots([]);
      const aliquotIds = flatMap(records, record =>
        record.dataRows.map(row => row.rowValues.aliquotId)
      );
      const inventoryAliquots = await safeQuery(
        microbialMaterialSelectionAliquotFragment,
        {
          variables: { filter: { id: aliquotIds } }
        }
      );
      change("inventoryAliquots", inventoryAliquots);
      selectDataTableTableAliquots(inventoryAliquots);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading aliquots");
    }
    this.setState({
      loadingDataTableMaterials: false
    });
  };

  fetchPlateMaterials = async (plates = []) => {
    const {
      stepFormProps: { change },
      selectPlateTableAliquots
    } = this.props;
    this.setState({
      loadingContainerArrayMaterials: true
    });
    change("plateAliquots", []);
    selectPlateTableAliquots([]);
    if (plates.length) {
      try {
        const plateAliquots = await safeQuery(
          microbialMaterialSelectionAliquotFragment,
          {
            variables: {
              filter: {
                "aliquotContainer.containerArrayId": plates.map(p => p.id)
              }
            }
          }
        );
        change("plateAliquots", plateAliquots);
        selectPlateTableAliquots(plateAliquots);
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error loading aliquots");
      }
    }
    this.setState({
      loadingContainerArrayMaterials: false
    });
  };

  removeInventoryMaterialSelection = () => {
    const { stepFormProps, selectDataTableTableAliquots } = this.props;
    stepFormProps.change("inventoryAliquots", []);
    selectDataTableTableAliquots([]);
  };

  removePlateMaterialSelection = () => {
    const { stepFormProps, selectPlateTableAliquots } = this.props;
    stepFormProps.change("plateAliquots", []);
    selectPlateTableAliquots([]);
  };

  render() {
    const {
      loadingContainerArrayMaterials,
      loadingDataTableMaterials
    } = this.state;
    const {
      containerArrays = [],
      dataTables = [],
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      Footer,
      footerProps,
      inventoryAliquots = [],
      plateAliquots = [],
      mmsSelectedAliquotsFromPlatesSelectedEntities: selectedPlateAliquotEntities,
      mmsSelectedAliquotsFromDataTablesSelectedEntities: selectedDataTableAliquotEntities
    } = this.props;
    const nonMicrobialPlateMaterials = [];
    selectedPlateAliquotEntities.forEach(aliquot => {
      const materialName = get(aliquot, "sample.material.name");
      const materialTypeCode = get(aliquot, "sample.material.materialTypeCode");
      if (materialTypeCode !== "MICROBIAL") {
        nonMicrobialPlateMaterials.push(materialName);
      }
    });
    const nonMicrobialInventoryMaterials = [];
    inventoryAliquots.forEach(aliquot => {
      const materialName = get(aliquot, "sample.material.name");
      const materialTypeCode = get(aliquot, "sample.material.materialTypeCode");
      if (materialTypeCode !== "MICROBIAL") {
        nonMicrobialInventoryMaterials.push(materialName);
      }
    });

    const hasSelectedPlateOrTable = !!(
      containerArrays.length || dataTables.length
    );
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Lists of Microbial Materials"
            helper="Select one or more data tables of inventory materials."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "dataTables",
                schema: ["name", dateModifiedColumn],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.dataTables,
                  loading: isLoadingMap.dataTables
                },
                onSelect: this.fetchInventoryMaterials,
                tableParamOptions: {
                  additionalFilter: {
                    dataTableTypeCode: "MICROBIAL_INVENTORY_MATERIALS"
                  }
                },
                fragment: ["dataTable", "id name updatedAt"],
                additionalDataFragment: [
                  "dataTable",
                  "id dataRows { id rowValues }"
                ],
                onClear: this.removeInventoryMaterialSelection
              }}
            />
            {loadingDataTableMaterials && <Loading bounce inDialog />}
            {inventoryAliquots.length > 0 && (
              <DataTable
                formName="mmsSelectedAliquotsFromDataTables"
                style={{ marginBottom: 15 }}
                isSimple
                withCheckboxes
                destroyOnUnmount={false}
                schema={aliquotSchema}
                entities={inventoryAliquots}
              />
            )}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Microbial Materials from Plates"
            helper="Select one or more plates of microbial materials."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "containerArrays",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.containerArrays,
                  loading: isLoadingMap.containerArrays
                },
                onSelect: this.fetchPlateMaterials,
                fragment: microbialMaterialSelectionPlateFragment,
                onClear: this.removePlateMaterialSelection
              }}
            />
            {loadingContainerArrayMaterials && <Loading bounce inDialog />}
            {plateAliquots.length > 0 && (
              <DataTable
                formName="mmsSelectedAliquotsFromPlates"
                style={{ marginBottom: 15 }}
                isSimple
                withCheckboxes
                destroyOnUnmount={false}
                schema={aliquotSchema}
                entities={plateAliquots}
              />
            )}
          </div>
          {hasSelectedPlateOrTable &&
            !selectedPlateAliquotEntities.length &&
            !selectedDataTableAliquotEntities.length && (
              <Callout intent={Intent.WARNING} style={{ marginTop: 10 }}>
                No aliquots found in selection.
              </Callout>
            )}
          {!!nonMicrobialPlateMaterials.length && (
            <Callout intent={Intent.WARNING} style={{ marginTop: 10 }}>
              {`The following materials from the selected plates above are non-microbial: ${nonMicrobialPlateMaterials.join(
                ", "
              )}.`}
            </Callout>
          )}
          {!!nonMicrobialInventoryMaterials.length && (
            <Callout intent={Intent.WARNING} style={{ marginTop: 10 }}>
              {`The following materials from the selected data tables above are non-microbial: ${nonMicrobialInventoryMaterials.join(
                ", "
              )}.`}
            </Callout>
          )}
        </div>
        <Footer
          {...footerProps}
          nextDisabled={
            (!selectedPlateAliquotEntities.length &&
              !selectedDataTableAliquotEntities.length) ||
            nonMicrobialInventoryMaterials.length ||
            nonMicrobialPlateMaterials.length
          }
          onNextClick={this.saveAliquotsToForm}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "inventoryAliquots",
    "plateAliquots",
    "containerArrays",
    "dataTables"
  ),
  withSelectedEntities(
    "mmsSelectedAliquotsFromDataTables",
    "mmsSelectedAliquotsFromPlates"
  ),
  withSelectTableRecords(
    "mmsSelectedAliquotsFromDataTables",
    "selectDataTableTableAliquots"
  ),
  withSelectTableRecords(
    "mmsSelectedAliquotsFromPlates",
    "selectPlateTableAliquots"
  )
)(SelectMaterials);

const aliquotSchema = {
  model: "aliquot",
  fields: [
    { path: "sample.material.name", type: "string", displayName: "Material" },
    {
      path: "aliquotContainer.containerArray.name",
      type: "string",
      displayName: "Plate"
    },
    {
      displayName: "Well",
      render: (v, r) => getAliquotContainerLocation(r.aliquotContainer)
    },
    ...unitColumns,
    dateModifiedColumn
  ]
};
