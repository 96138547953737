/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const createDataTableFromPlateFragment = gql`
  fragment createDataTableFromPlateFragment on containerArray {
    id
    name
    updatedAt
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquotContainerTypeCode
      aliquot {
        id
        volume
        volumetricUnitCode
        mass
        massUnitCode
        concentration
        concentrationUnitCode
        molarity
        molarityUnitCode
        sample {
          id
          name
          sampleStatusCode
          materialId
          sampleFormulations {
            id
            materialCompositions {
              id
              material {
                id
                name
              }
            }
          }
          material {
            id
            name
          }
        }
      }
    }
  }
`;
