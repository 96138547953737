/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { get } from "lodash";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  ReactSelectField,
  CheckboxField,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import TagField from "../../../../src-shared/TagField";
import PlateUploadFields from "../../PlateUploadFields";
import handleIdtPlateImport, {
  requiredHeaders,
  allHeaders
} from "../../../../../tg-iso-lims/src/plateImports/handleIdtPlateImport";
import {
  arrayToIdOrCodeValuedOptions,
  throwFormError
} from "../../../../src-shared/utils/formUtils";
import withDnaSequenceTypes from "../../../../src-shared/graphql/enhancers/withDnaSequenceTypes";

export { requiredHeaders, allHeaders };

export async function submitIdtPlate(values, options) {
  const { makeError } = options;
  try {
    return await handleIdtPlateImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.errors || error.message || "Error uploading plate.");
  }
}

const UploadIdtPlateUnwrapped = ({
  hideModal,
  refetch,
  show,
  noDialog,
  submitting,
  handleSubmit,
  containerArrayType,
  sequenceTypes,
  fieldPrefix = "",
  error
}) => {
  const onSubmit = async values => {
    const makeError = error => {
      throwFormError({
        _error: error
      });
    };
    await submitIdtPlate(values, {
      makeError,
      hideModal,
      show,
      refetch
    });
  };

  const isRack = get(containerArrayType, "isPlate") === false;

  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <PlateUploadFields
          inTool={noDialog}
          fieldPrefix={fieldPrefix}
          templateFileOptions={{
            fileName: "IDT Plate",
            headers: allHeaders,
            extendedPropTypes: [
              "plate",
              "tube",
              "sequence",
              "aliquot",
              "sample"
            ],
            requiredHeaders: requiredHeaders
          }}
        />
        <ReactSelectField
          name={fieldPrefix + "sequenceTypeCode"}
          isRequired
          label="Sequence Type"
          placeholder="Select Sequence Type..."
          options={arrayToIdOrCodeValuedOptions(sequenceTypes)}
          defaultValue="OLIGO"
        />
        <CheckboxField
          name={fieldPrefix + "dry"}
          label={`Upload as dry ${isRack ? "rack" : "plate"}?`}
        />
        <CheckboxField
          name={fieldPrefix + "generateBarcode"}
          label={`Generate ${isRack ? "Rack" : "Plate"} Barcode`}
        />
        <TagField name={fieldPrefix + "tags"} />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export const UploadIdtPlateDialog = compose(withDnaSequenceTypes)(
  UploadIdtPlateUnwrapped
);

export default compose(
  wrapDialog({
    title: "Upload IDT CSV"
  }),
  reduxForm({
    form: "uploadIDTForm"
  }),
  tgFormValues("containerArrayType")
)(UploadIdtPlateDialog);
