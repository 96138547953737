/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Callout, Intent, Switch } from "@blueprintjs/core";
import { isEmpty, cloneDeep, keys, pickBy } from "lodash";
import { DataTable, Loading } from "@teselagen/ui";
import { EvolveConfig } from "../../configs/config.js";
import cypressTags from "../../configs/cypressTags.json";
import {
  validateAndCapitalizeSequences,
  VALIDATION_ISSUES_TYPES
} from "./GenerationsToolUtils/CsvGenerationInputValidation";
import HeaderWithHelper from "../../components/HeaderWithHelper";
import ValidationIssuesComponent from "./GenerationsToolUtils/ValidationIssuesComponent";
import createGenerationModelFormStyles from "./GenerationsToolUtils/CreateGenerationModelFormStyles.module.css";

const { GENERATIVE_MODEL } = EvolveConfig.constants;
const DESIGN_MODULE = "DESIGN_MODULE";

class SelectAminoAcidSequences extends React.PureComponent {
  constructor(props) {
    super(props);
    const { modelData } = props;
    this.state = {
      modelData: cloneDeep(modelData),
      globalIssues: [],
      sequencesIssues: [],
      validationComplete: false
    };
  }

  componentDidMount() {
    // If records come with taggedItems, it means data (aa seqs) were gotten directly from the DESIGN Module, there's no need for aa column selection,
    // Since we already know the "Sequence" column will contain the AA sequences, so we can go directly to auto select it and validate the sequences.
    if (
      this.props.modelData &&
      this.props.modelData[0] &&
      this.props.modelData[0].taggedItems
    ) {
      this.onAminoAcidSequenceColumnSelected({ headerName: "Sequence" });
    }
    // If they do not come with taggedItems, it means they were gotten from the TEST Module, thus th user will need to specify which column
    // in the TEST Assay contains the sequences.
    else {
      this.props.stepFormProps.change("aminoAcidSequencesColumnName", "");
      this.setState({
        modelData: cloneDeep(this.props.modelData),
        globalIssues: [],
        sequencesIssues: []
      });
    }
  }

  isDesignDataModule() {
    return (
      this.props.modelType === GENERATIVE_MODEL &&
      this.props.dataModule === DESIGN_MODULE &&
      !this.props.dataFromFileUpload
    );
  }

  filterInvalidSequences() {
    if (!this.props.filterInvalidSequences) {
      const filteredSequences = keys(
        pickBy(this.state.modelData, seq => seq.issues)
      );
      this.props.stepFormProps.change("filteredSequences", filteredSequences);
    }
    this.findSequenceIssues({
      modelData:
        !this.props.filterInvalidSequences &&
        this.state.modelData.filter(seq => !seq.issues)
    });
    this.props.stepFormProps.change(
      "filterInvalidSequences",
      !this.props.filterInvalidSequences
    );
  }

  findSequenceIssues = async ({ aminoAcidSequencesColumnName, modelData }) => {
    const _aminoAcidSequencesColumnName =
      aminoAcidSequencesColumnName || this.props.aminoAcidSequencesColumnName;
    this.setState(currentState => ({
      ...currentState,
      validationComplete: false
    }));
    const _modelData = modelData || cloneDeep(this.props.modelData);

    const sequencesList = _modelData.map(
      sequence => sequence[_aminoAcidSequencesColumnName]
    );

    const validationIssues = validateAndCapitalizeSequences(sequencesList);

    const globalIssues = [];
    const sequencesIssues = [];

    validationIssues.issues.forEach(issue => {
      if (issue.position !== undefined) {
        if (!_modelData[issue.position].issues) {
          _modelData[issue.position]["issues"] = [issue];
        } else {
          _modelData[issue.position].issues.push(issue);
        }
        sequencesIssues.push(issue);
      } else {
        globalIssues.push(issue);
      }
    });

    this.props.setIsValid(validationIssues.isValid);

    this.setState({
      modelData: _modelData,
      globalIssues,
      sequencesIssues,
      validationComplete: true
    });
  };

  highlightInvalidCharacterInSequence = (value, record, index) => {
    if (record.issues) {
      const charactersInvalidIssue = record.issues.filter(
        issue =>
          issue.type === VALIDATION_ISSUES_TYPES.sequenceCharactersInvalid.type
      );

      if (!isEmpty(charactersInvalidIssue)) {
        const invalidCharacters =
          charactersInvalidIssue[0].value.invalidCharacters;

        const valueAsArray = value.split("");
        invalidCharacters.forEach(issue => {
          const invalidChar = valueAsArray[issue.position];
          valueAsArray[issue.position] = (
            <span
              key={`${issue.character}_${index.index}_${issue.position}`}
              style={{
                color: "red",
                fontWeight: "bold"
              }}
            >
              {invalidChar}
            </span>
          );
        });

        return (
          <Callout
            className={createGenerationModelFormStyles.tableCallout}
            intent={Intent.DANGER}
            icon={null}
          >
            {valueAsArray}
          </Callout>
        );
      }
    }
    return value;
  };

  highlightOutOfBoundsSequenceLength = (value, record) => {
    if (record.issues) {
      const outOfBoundSequenceLength = record.issues.filter(
        issue =>
          issue.type ===
            VALIDATION_ISSUES_TYPES.minSeqLengthRequirementNotMet.type ||
          issue.type ===
            VALIDATION_ISSUES_TYPES.maxSeqLengthRequirementExceeded.type
      );

      if (!isEmpty(outOfBoundSequenceLength)) {
        return (
          <Callout
            className={createGenerationModelFormStyles.tableCallout}
            intent={Intent.DANGER}
            icon={null}
          >
            <span
              style={{
                color: "red",
                fontWeight: "bold"
              }}
            >
              {record[Object.keys(record)[0]].length}
            </span>
          </Callout>
        );
      }
    }

    return record[Object.keys(record)[0]].length;
  };

  generateExtendedSchema = dataSchema => {
    const lengthField = {
      path: "length",
      render: this.highlightOutOfBoundsSequenceLength
    };

    const enhancedDataSchema = dataSchema;
    if (!isEmpty(enhancedDataSchema[0])) {
      enhancedDataSchema[0].render = this.highlightInvalidCharacterInSequence;
    }

    const extendedSchema = [...enhancedDataSchema, lengthField];

    return extendedSchema;
  };

  renderTableWithSequences = modelData => {
    const entities = modelData.map(row => ({
      aaSequence: row[this.props.aminoAcidSequencesColumnName],
      issues: row.issues
    }));
    const schema = this.generateExtendedSchema([
      {
        path: "aaSequence",
        displayName: "Amino Acid Sequences"
      }
    ]);
    return (
      <div id={cypressTags.STEP_2_TABLE_WITH_DATA_ID}>
        <DataTable
          formName="selectedModelDataDisplayTable"
          maxHeight={450}
          noHeader
          noSelect
          compact
          showCount
          schema={schema}
          entities={entities}
        />
      </div>
    );
  };

  onAminoAcidSequenceColumnSelected = columnName => {
    if (columnName && columnName.headerName) {
      this.findSequenceIssues({
        aminoAcidSequencesColumnName: columnName.headerName
      });
      this.props.stepFormProps.change(
        "aminoAcidSequencesColumnName",
        columnName.headerName
      );
    } else {
      this.setState({
        modelData: this.props.modelData,
        globalIssues: [],
        sequencesIssues: []
      });
      this.props.stepFormProps.change("aminoAcidSequencesColumnName", "");
      this.props.setIsValid(false);
    }
    this.props.stepFormProps.change("filterInvalidSequences", false);
  };

  renderTableWithColumns = () => {
    const entities =
      this.props.modelDataColumnSchema &&
      this.props.modelDataColumnSchema.length
        ? this.props.modelDataColumnSchema.map(header => ({
            headerName: header.name
          }))
        : [];
    return (
      <div id={cypressTags.STEP_2_TABLE_WITH_DATA_ID}>
        <DataTable
          isSimple
          isViewable
          isSingleSelect
          formName="tuplingSchemaDisplayTable"
          maxHeight={300}
          schema={[
            {
              path: "headerName",
              displayName: "Columns"
            }
          ]}
          entities={entities}
          onSingleRowSelect={this.onAminoAcidSequenceColumnSelected}
          onDeselect={this.onAminoAcidSequenceColumnSelected}
        />
      </div>
    );
  };

  render() {
    const { modelData, sequencesIssues = [], globalIssues = [] } = this.state;

    const isDataLoaded = !!modelData;

    const tableWithTheColumns = this.renderTableWithColumns(modelData);

    const tableWithSequences = this.renderTableWithSequences(modelData);
    const withIssues = !isEmpty(sequencesIssues) || !isEmpty(globalIssues);

    return (
      <div className="tg-step-form-section column">
        {!this.isDesignDataModule() && (
          <div style={{ marginBottom: 50 }}>
            <HeaderWithHelper
              header="Amino acid sequences selection"
              helper="Please select the column that contaIns the Amino Acid Sequences."
            />
            {isDataLoaded && tableWithTheColumns}
          </div>
        )}
        {this.props.aminoAcidSequencesColumnName &&
          (this.state.validationComplete ? (
            <div>
              <HeaderWithHelper header="Amino acid sequences selected for modelling." />
              <Switch
                disabled={!withIssues && !this.props.filterInvalidSequences}
                className={
                  createGenerationModelFormStyles.filter_invalid_sequences
                }
                onChange={() => this.filterInvalidSequences()}
                checked={this.props.filterInvalidSequences}
                label="Filter invalid sequences"
              />
              {withIssues && (
                <ValidationIssuesComponent
                  sequencesIssues={sequencesIssues}
                  globalIssues={globalIssues}
                />
              )}
              {this.props.aminoAcidSequencesColumnName && tableWithSequences}
            </div>
          ) : (
            <div>
              <h5>Validating amino acid sequences...</h5>
              <Loading style={{ height: 60 }} />
            </div>
          ))}
      </div>
    );
  }
}

export default SelectAminoAcidSequences;
