/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import shortid from "shortid";
import { compose } from "recompose";
import {
  InputField,
  ReactSelectField,
  TextareaField,
  wrapDialog
} from "@teselagen/ui";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import withQuery from "../../../../src-shared/withQuery";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { partFragment } from "../../../../../tg-iso-design/graphql/fragments/partFragment";
import { recordPreview } from "../../../../src-shared/utils/dataTableUtils";
import SequencePreview from "../../../../src-shared/SequencePreview";
import sequenceFullFragment from "../../../../../tg-iso-shared/src/fragments/sequenceFullFragment";
import { getCutsitesFromSequence } from "@teselagen/sequence-utils";
import { flatMap } from "lodash";
import { Button, Intent } from "@blueprintjs/core";
import { restrictionEnzymeVeFragment } from "../../../../../tg-iso-shared/src/fragments/restrictionEnzymeFragment";

const partSchema = [
  { path: "name", type: "string", displayName: "Name" },
  { path: "size", type: "number", displayName: "Size" },
  { path: "start", type: "number", displayName: "Start" },
  { path: "end", type: "number", displayName: "End" },
  // { path: "strand", type: "string", displayName: "Strand" },
  { displayName: "5' Overhang", path: "re5PrimeOverhang", type: "string" },
  { displayName: "3' Overhang", path: "re3PrimeOverhang", type: "string" }
];

const SequencePreviewDialog = compose(
  wrapDialog({ title: "Sequence Preview Dialog" }),
  withQuery(sequenceFullFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.sequenceId
        }
      };
    },
    props: ({ sequence, ownProps: { selectedRestrictionEnzyme } }) => {
      let cutsites;
      if (sequence) {
        const cutsitesByEnzyme = getCutsitesFromSequence(
          sequence.fullSequence,
          sequence.circular,
          [selectedRestrictionEnzyme]
        );
        cutsites = flatMap(Object.values(cutsitesByEnzyme));
      }
      return {
        sequence: {
          ...sequence,
          cutsites: cutsites || []
        }
      };
    }
  })
)(props => <SequencePreview {...props.sequence} />);

const SelectParts = props => {
  const {
    Footer,
    footerProps,
    stepFormProps: { change },
    restrictionEnzymes = [],
    selectedRestrictionEnzyme,
    selectedParts = [],
    submitting
  } = props;

  const onPreviewClick = part => {
    return showDialog({
      ModalComponent: SequencePreviewDialog,
      modalProps: {
        sequenceId: part.sequenceId,
        part,
        selectedRestrictionEnzyme
      }
    });
  };

  const designNameField = (
    <div style={{ width: 400 }}>
      <InputField
        label="Design Name"
        name="designName"
        defaultValue={`design-${shortid()}`}
      />
    </div>
  );

  const designDescriptionField = (
    <div style={{ width: 400 }}>
      <TextareaField
        label="Design Description"
        name="designDescription"
        placeholder="Enter notes..."
      />
    </div>
  );

  const designLayoutField = (
    <div style={{ width: 400 }}>
      <ReactSelectField
        label="Design Layout"
        name="designLayout"
        defaultValue="combinatorial"
        options={[
          { value: "combinatorial", label: "Combinatorial" },
          { value: "list", label: "List" }
        ]}
      />
    </div>
  );

  const restrictionEnzymeSelector = (
    <div>
      <HeaderWithHelper
        header="Select Restriction Enzyme"
        helper="This will be the enzyme used for the Golden Gate digest design."
      />
      <div style={{ width: 400 }}>
        <ReactSelectField
          isRequired
          name="selectedRestrictionEnzyme"
          multi={false}
          onChange={value => {
            // Selected parts become invalid if the restriction enzyme changes
            if (value.id !== selectedRestrictionEnzyme?.id)
              change("selectedParts", undefined);
          }}
          options={restrictionEnzymes.map(el => ({
            value: el,
            label: el.name
          }))}
        />
      </div>
    </div>
  );

  const dnaPartSelector = (
    <div>
      <HeaderWithHelper
        header="Select Digest Parts Enzyme"
        helper="The final design will be constructed using these parts."
      />
      <GenericSelect
        {...{
          name: "selectedParts",
          schema: partSchema,
          isMultiSelect: true,
          buttonProps: {
            disabled: !selectedRestrictionEnzyme,
            text: "Select Digest Parts"
          },
          fragment: partFragment,
          transformEntities: entities =>
            entities.map(entity => ({
              ...entity,
              strand: entity.strand === 1 ? "forward" : "reverse"
            })),
          selectedRestrictionEnzyme,
          additionalFilter: (props, qb) => {
            const {
              selectedRestrictionEnzyme: { id: restrictionEnzymeId } = {}
            } = props;
            qb.whereAll({
              isDigestPart: true,
              isDigestValid: true,
              re5PrimeId: restrictionEnzymeId,
              re3PrimeId: restrictionEnzymeId
            });
          },
          nameOverride: "Digest Parts",
          postSelectDTProps: {
            schema: [recordPreview({ onClick: onPreviewClick }), ...partSchema]
          }
        }}
      />
    </div>
  );

  return (
    <React.Fragment>
      <div className="tg-step-form-section column form-container">
        {designNameField}
        {designDescriptionField}
        {designLayoutField}
      </div>
      <div className="tg-step-form-section column form-container">
        {restrictionEnzymeSelector}
        {dnaPartSelector}
      </div>
      <Footer
        {...footerProps}
        nextButton={
          <Button
            id="generate-data-grids-button"
            type="submit"
            intent={Intent.SUCCESS}
            text="Create Digest Design"
            disabled={!selectedParts.length}
            loading={submitting}
          />
        }
      />
    </React.Fragment>
  );
};

export default compose(
  withQuery(restrictionEnzymeVeFragment, {
    isPlural: true,
    showLoading: true,
    variables: {
      filter: {
        isRecognitionSiteDegenerate: false,
        enzymeTypeCode: "TYPE_IIS"
      }
    }
  }),
  stepFormValues("selectedRestrictionEnzyme", "selectedParts")
)(SelectParts);
