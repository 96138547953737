/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DragLayer } from "react-dnd";
import { TYPE_SHAPE_MAP } from "../typeShapeMap";
import ColorSquare from "../SchematicInspector/ColorSquare";

import "./style.css";

class CustomDragLayer extends React.Component {
  getTransform = () => {
    const {
      currentOffset: { x, y }
    } = this.props;
    return `translate(${x}px, ${y}px)`;
  };

  renderShape = () => {
    const {
      item: { type, text, fill, textColor },
      currentOffset: { x, y }
    } = this.props;
    const Shape = TYPE_SHAPE_MAP[type];
    const isRight = type.includes("right");
    return (
      <svg>
        <Shape
          {...{
            isRight,
            x,
            y,
            fill,
            textColor,
            text,
            beingDragged: true
          }}
        />
      </svg>
    );
  };

  renderColor = () => {
    const {
      item: { color },
      currentOffset: { x, y }
    } = this.props;
    return <ColorSquare {...{ x, y, fill: color }} />;
  };

  renderItem = () => {
    const { itemType } = this.props;

    if (itemType === "shape") {
      return this.renderShape();
    } else if (itemType === "color") {
      return this.renderColor();
    }
    return null;
  };

  render() {
    const { isDragging, currentOffset } = this.props;

    if (!isDragging || !currentOffset) return null;

    return (
      <svg className="schematic-edit-view-drag-layer">{this.renderItem()}</svg>
    );
  }
}

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
}))(CustomDragLayer);
