/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const SegmentEvents = {
  OpenEntry: "notebook-entry-opened",
  NewEntry: "notebook-entry-created",
  PasswordReset: "password-reset",
  PasswordResetRequested: "password-reset-requested",
  LoggedIn: "logged-in",
  LoggedOut: "logged-out",
  UsedAnOldDesignFileType: "used-an-old-design-file-type"
} as const;

type SegmentEventPropertiesMap = {
  [SegmentEvents.OpenEntry]: { entryId: string };
  [SegmentEvents.NewEntry]: { entryId: string };
  [SegmentEvents.PasswordReset]: { email: string };
  [SegmentEvents.PasswordResetRequested]: { email: string };
  [SegmentEvents.LoggedIn]: { email: string };
  [SegmentEvents.LoggedOut]: { email: string };
  [SegmentEvents.UsedAnOldDesignFileType]: { email: string };
};

export const triggerSegmentEvent = <T extends keyof SegmentEventPropertiesMap>(
  event: T,
  properties?: SegmentEventPropertiesMap[T],
  options?: Object,
  callback?: Function
) => {
  try {
    if (window.frontEndConfig.segmentAnalyticsKey) {
      window.analytics.track(event, properties, options, callback);
    }
  } catch (error) {
    console.error("Error setting user for analytics");
    console.error(error);
  }
};
