/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniqBy, keyBy } from "lodash";

export function getInputsAndOutputsOfReaction(r) {
  const inputMaterials = [];
  const inputReagents = [];
  const outputMaterials = [];
  const outputReagents = [];
  r.reactionOutputs.forEach(o => {
    if (o.outputMaterial) {
      outputMaterials.push(o.outputMaterial);
    } else if (o.outputAdditiveMaterial) {
      outputReagents.push(o.outputAdditiveMaterial);
    }
  });
  r.reactionInputs.forEach(o => {
    if (o.inputMaterial) {
      inputMaterials.push(o.inputMaterial);
    } else if (o.inputAdditiveMaterial) {
      inputReagents.push(o.inputAdditiveMaterial);
    }
  });
  return {
    inputMaterials,
    inputReagents,
    outputMaterials,
    outputReagents
  };
}

export function renderMaterialNames(mats) {
  return mats.map(m => m.name).join(", ");
}

export function sortReactions(reactions) {
  return [...reactions].sort((a, b) => a.name.localeCompare(b.name));
}

export function getReactionEntitiesAndSchema(
  reactionMap,
  addReagentsForReaction = {},
  allReagents = []
) {
  const reactionSchema = ["name"];
  const reactionEntities = [];
  if (reactionMap) {
    let hasInputMats = false;
    let hasInputReagents = false;
    let hasOutputMats = false;
    let hasOutputReagents = false;
    const keyedReagents = keyBy(allReagents, "id");
    sortReactions(reactionMap.reactions).forEach(r => {
      const {
        inputMaterials,
        inputReagents,
        outputMaterials,
        outputReagents
      } = getInputsAndOutputsOfReaction(r);
      const extraInputReagents = (
        addReagentsForReaction["id" + r.id] || []
      ).map(reagentId => keyedReagents[reagentId]);

      let inputReagentsToUse = inputReagents;
      if (extraInputReagents.length) {
        inputReagentsToUse = uniqBy(
          inputReagentsToUse.concat(extraInputReagents),
          "id"
        );
      }
      if (inputMaterials.length) {
        hasInputMats = true;
      }
      if (inputReagentsToUse.length) {
        hasInputReagents = true;
      }
      if (outputReagents.length) {
        hasOutputReagents = true;
      }
      if (outputMaterials.length) {
        hasOutputMats = true;
      }
      reactionEntities.push({
        ...r,
        inputMaterials,
        inputReagents: inputReagentsToUse,
        outputMaterials,
        outputReagents
      });
    });
    if (hasInputMats) {
      reactionSchema.push({
        displayName: "Input Materials",
        render: (v, r) => renderMaterialNames(r.inputMaterials)
      });
    }
    if (hasInputReagents) {
      reactionSchema.push({
        displayName: "Input Reagents",
        render: (v, r) => renderMaterialNames(r.inputReagents)
      });
    }
    if (hasOutputMats) {
      reactionSchema.push({
        displayName: "Output Materials",
        render: (v, r) => renderMaterialNames(r.outputMaterials)
      });
    }
    if (hasOutputReagents) {
      reactionSchema.push({
        displayName: "Output Reagents",
        render: (v, r) => renderMaterialNames(r.outputReagents)
      });
    }
  }
  return {
    schema: reactionSchema,
    entities: reactionEntities
  };
}
