/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { withSelectedEntities, RadioGroupField } from "@teselagen/ui";
import { Tooltip, Icon } from "@blueprintjs/core";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import {
  additionalFilterForTubes,
  validateMaterialPlates
} from "../../../../utils/plateUtils";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  microbialTransformationPlateFragment,
  microbialTransformationTubeFragment
} from "../fragments";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { validateAliquot } from "../utils";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { isEmpty } from "lodash";

// const renderReactionType = v => startCase(v.toLowerCase());
function aliquotAppropriateMaterialError(aliquot) {
  if (aliquot.isDry) {
    return "Tube has dry aliquot.";
  }
  return validateAliquot(aliquot);
}

class SelectPlates extends Component {
  render() {
    const {
      inputType,
      Footer,
      footerProps,
      aliquotContainers = [],
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      containerArrays = []
    } = this.props;
    const aliquotContainerErrorMap = {};

    aliquotContainers.forEach(ac => {
      if (ac.aliquot) {
        const error = aliquotAppropriateMaterialError(ac.aliquot);
        if (error) {
          aliquotContainerErrorMap[ac.id] = error;
        }
      } else {
        aliquotContainerErrorMap[ac.id] = "No aliquot found in tube.";
      }
    });

    const invalidTubeInputs =
      inputType === "TUBE" && !isEmpty(aliquotContainerErrorMap);

    let plateErrors = {};
    if (containerArrays.length) {
      plateErrors = validateMaterialPlates(containerArrays, {
        materialTypeCode: "DNA",
        enforceSequenceLinks:
          !window.frontEndConfig.enableNonSequenceDnaMaterials,
        validateAliquot,
        asObject: true
      });
    }
    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Select Input Type"
            helper="Choose between an input of plates/racks or tubes."
          />
          <RadioGroupField
            name="inputType"
            isRequired
            generateDefaultValue={defaultValueConstants.PLATES_OR_TUBES}
            options={defaultValueConstants.PLATES_OR_TUBES.options}
          />
        </div>
        {inputType === "PLATE" && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Select Input Plates"
              helper="Select one or more plates of existing DNA material from inventory for transformation."
            />
            <div className="width100 column">
              <GenericSelect
                {...{
                  name: "containerArrays",
                  isRequired: true,
                  schema: [
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" },
                    dateModifiedColumn
                  ],
                  isMultiSelect: true,
                  fragment: [
                    "containerArray",
                    "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
                  ],
                  additionalDataFragment: microbialTransformationPlateFragment,
                  postSelectDTProps: {
                    formName: "microbialTransformationPlateSelect",
                    plateErrors,
                    schema: [
                      platePreviewColumn({
                        plateErrors
                      }),
                      "name",
                      { displayName: "Barcode", path: "barcode.barcodeString" }
                    ]
                  },
                  buttonProps: {
                    loading: isLoadingMap.containerArrays,
                    disabled: isDisabledMap.containerArrays
                  }
                }}
              />
            </div>
          </div>
        )}
        {inputType === "TUBE" && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Select Input Tubes"
              helper="Select one or more tubes of existing DNA material from inventory for transformation."
            />
            <div className="width100 column">
              <GenericSelect
                {...{
                  name: "aliquotContainers",
                  schema: [
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" },
                    dateModifiedColumn
                  ],
                  isMultiSelect: true,
                  isRequired: true,
                  fragment: [
                    "aliquotContainer",
                    "id name aliquotContainerType { code name } barcode { id barcodeString } updatedAt"
                  ],
                  additionalDataFragment: microbialTransformationTubeFragment,
                  tableParamOptions: {
                    additionalFilter: additionalFilterForTubes
                  },
                  postSelectDTProps: {
                    formName: "microbialTransformationTubeSelect",
                    isSimple: true,
                    aliquotContainerErrorMap,
                    schema: [
                      {
                        type: "action",
                        width: 35,
                        render: (_, record) => {
                          const error = aliquotContainerErrorMap[record.id];
                          if (error) {
                            return (
                              <Tooltip content={error}>
                                <Icon
                                  intent="danger"
                                  style={{ marginRight: 10 }}
                                  icon="warning-sign"
                                />
                              </Tooltip>
                            );
                          }
                        }
                      },
                      "name",
                      { displayName: "Barcode", path: "barcode.barcodeString" },
                      {
                        displayName: "Tube Type",
                        path: "aliquotContainerType.name"
                      },
                      dateModifiedColumn
                    ]
                  },
                  buttonProps: {
                    loading: isLoadingMap.aliquotContainers,
                    disabled: isDisabledMap.aliquotContainers
                  }
                }}
              />
            </div>
          </div>
        )}
        <Footer
          nextDisabled={invalidTubeInputs || !isEmpty(plateErrors)}
          {...footerProps}
          errorMessage={
            (invalidTubeInputs || !isEmpty(plateErrors)) &&
            "Please review form errors."
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("inputType", "aliquotContainers", "containerArrays"),
  withSelectedEntities("microbialTransformationPlateSelect")
)(SelectPlates);
