/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect, useCallback } from "react";
import { reduxForm } from "redux-form";
import { Button } from "@blueprintjs/core";
import { get, startCase, has } from "lodash";
import papaparse from "papaparse";
import { ScrollToTop } from "@teselagen/ui";
import { Link } from "react-router-dom";
import getDefaultSchemas, {
  getDigestFragmentFields,
  lengthBp
} from "./getDefaultSchemas";
import exportOligosFields from "./exportOligosFields";
import J5TableCard from "./J5TableCard";
import RecordInfoTable from "./RecordInfoTable";
import processDataForTables from "./processDataForTables";
import { safeQuery } from "../apolloMethods";
import { download } from "../utils/downloadTest";
import gql from "graphql-tag";
import modelNameToLink from "../utils/modelNameToLink";
import { formatDateTime } from "../utils/dateUtils";
import { showDialog } from "../GlobalDialog";
import { fullUrl } from "../utils/generalUtils";
import { getConstructAssemblyColumns } from "../../../tg-iso-shared/src/utils/j5ReportUtils";
import { reportLinks, SynthonAdditionalHeaderEl, getExportSeqs } from "./utils";
import { exportSynthonsJ5ReportFragment } from "./fragments/exportSynthonsJ5ReportFragment";
import { exportOligoJ5ReportFragment } from "./fragments/exportOligoJ5ReportFragment";
import "./style.css";
import LinkAllJ5MaterialsButton from "../../src-build/components/LinkAllJ5MaterialsButton";
import j5AssemblyPieceFragment from "../graphql/fragments/j5AssemblyPieceFragment.gql";
import {
  j5AnnealedOligoFragment,
  j5DirectSynthesisFragment,
  j5InputPartFragment,
  j5InputSequenceFragment,
  j5PcrReactionFragment,
  j5RunConstructFragment
} from "../../src-build/components/Record/J5ReportRecordView/fragments";
import { j5OligoSynthesisFragment } from "../fragments/j5OligoSynthesisFragment";
import j5AssemblyPieceFragmentGql from "../graphql/fragments/j5AssemblyPieceFragment.gql";
import saveStreamResponse from "../utils/saveStreamResponse";
import ExportFileNameDialog from "../components/Dialogs/ExportFileNameDialog";
import getIsLinkedCellRenderer from "../../src-build/components/Record/J5ReportRecordView/getIsLinkedCellRenderer";
import { DisabledToolkitPopover } from "../DisabledToolkitPopover";

const sharedTableProps = {
  withSearch: false,
  showCount: true,
  withDisplayOptions: true,
  doNotShowEmptyRows: true,
  isLoading: false,
  urlConnected: false
};

const J5ReportRecordView = props => {
  const {
    additionalHeaderButtons,
    additionalHeaderComponent,
    j5ReportQuery,
    j5Report,
    j5Report: { design, originalDesign, assemblyMethod },
    onExportOligosAsCsvClick,
    onExportSynthonsAsFastaClick,
    pcrReactionsTitleElements,
    saveableSeqsTitleEls = () => [],
    noPrebuiltConstructs = false,
    // customSchemaGeneratorForAssemblies,
    dataTableProps: passedDataTableProps,
    createOrderModels = [],
    history
  } = props;

  const [exportingReport, setExportingReport] = useState(false);
  const [loadingExportOligos, setLoadingExportOligos] = useState(false);
  const [loadingJ5LogMessages, setLoadingJ5LogMessages] = useState(false);
  const [loadingExportSynthons, setLoadingExportSynthons] = useState(false);
  const [j5LogMessages, setJ5LogMessages] = useState([]);
  const [showSynthons, setShowSynthons] = useState(true);

  useEffect(() => {
    const getJ5LogMessages = async () => {
      setLoadingJ5LogMessages(true);
      const tmpJ5LogMessages = await safeQuery(
        [
          "j5LogMessage",
          "id message j5LogMessageTypeCode j5LogMessagePriorityCode j5LogMessageJoins { id specificMsg j5RunConstructId j5InputPartId j5AssemblyPieceId }"
        ],
        {
          variables: {
            filter: {
              j5ReportId: j5ReportQuery.variables.id
            }
          }
        }
      );
      setJ5LogMessages(tmpJ5LogMessages);
      setLoadingJ5LogMessages(false);
    };
    getJ5LogMessages();
  }, [j5ReportQuery?.variables?.id]);

  const handleExportOligosToCsv = useCallback(async () => {
    let oligos;

    setLoadingExportOligos(true);
    try {
      oligos = await safeQuery(exportOligoJ5ReportFragment, {
        variables: {
          filter: {
            j5ReportId: j5Report.id
          }
        }
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error exporting oligos.");
    }
    setLoadingExportOligos(false);

    const j5OligoSyntheses = processDataForTables.j5OligoSynthesis(oligos);
    const csvString = papaparse.unparse([
      ["OligoSynthesis"],
      exportOligosFields.map(field => field.displayName),
      ...j5OligoSyntheses.map(j5Oligo =>
        exportOligosFields.map(field => get(j5Oligo, field.path))
      )
    ]);
    download(csvString, `Oligo_Synthesis_${j5Report.name}.csv`);
  }, [j5Report?.id, j5Report?.name]);

  const handleExportSynthonsAsFasta = useCallback(async () => {
    let synthons;

    setLoadingExportSynthons(true);
    try {
      synthons = await safeQuery(exportSynthonsJ5ReportFragment, {
        variables: {
          filter: {
            j5ReportId: j5Report.id
          }
        }
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error exporting synthons.");
    }
    setLoadingExportSynthons(false);

    const fastaString = synthons
      .map(synthon => {
        return `>${synthon.name}\n${synthon.sequence.sequenceFragments
          .sort((a, b) => a.index - b.index)
          .map(syn => syn.fragment)
          .join("")
          .toUpperCase()}\n`;
      })
      .join("");

    download(fastaString, `Synthon_Sequences_${j5Report.name}.fasta`);
  }, [j5Report?.id, j5Report?.name]);

  const handleExportButton = useCallback(
    async onExportHandler => {
      setExportingReport(true);
      showDialog({
        ModalComponent: ExportFileNameDialog,
        modalProps: {
          defaultValueType: "EXPORT_ASSEMBLY_REPORT_FILE_NAME",
          onSubmit: async fileName => {
            await onExportHandler(fileName);
            setExportingReport(false);
          },
          onCancel: () => {
            setExportingReport(false);
          },
          customParams: {
            designId: design?.id,
            designName: design?.name || originalDesign?.name,
            assemblyMethod: assemblyMethod
          }
        }
      });
    },
    [assemblyMethod, design?.id, design?.name, originalDesign?.name]
  );

  const renderDownloadOligoButton = useCallback(() => {
    return (
      <Button
        key="exportOligos"
        style={{
          marginLeft: 10
        }}
        loading={loadingExportOligos}
        onClick={onExportOligosAsCsvClick || handleExportOligosToCsv}
      >
        Export Oligos as CSV
      </Button>
    );
  }, [handleExportOligosToCsv, loadingExportOligos, onExportOligosAsCsvClick]);

  const renderDownloadSynthonsButton = useCallback(() => {
    return (
      <Button
        key="exportsynthons"
        style={{
          marginLeft: 10
        }}
        loading={loadingExportSynthons}
        onClick={onExportSynthonsAsFastaClick || handleExportSynthonsAsFasta}
      >
        Export Sequences
      </Button>
    );
  }, [
    handleExportSynthonsAsFasta,
    loadingExportSynthons,
    onExportSynthonsAsFastaClick
  ]);

  const renderHeader = () => {
    // JSON.parse(localStorage.getItem('TEMPORARY_j5Run')) || {}
    const {
      name,
      assemblyType,
      createdAt,
      dateRan,
      design,
      designId,
      originalDesign,
      originalDesignId,
      j5ReportSubsetJ5ReportSubsetJoins = [],
      j5ReportSubsetJoins = []
    } = j5Report;

    let designLink = design && design.name ? design.name : name;
    if (design && design.id) {
      designLink = <Link to={modelNameToLink(design)}>{designLink}</Link>;
    } else if (designId) {
      designLink = (
        <a href={fullUrl(modelNameToLink("design", designId))}>{designLink}</a>
      );
    }
    let originalDesignLink =
      originalDesign && originalDesign.name ? originalDesign.name : name;
    if (originalDesign && originalDesign.id) {
      originalDesignLink = (
        <Link to={modelNameToLink(originalDesign)}>{originalDesignLink}</Link>
      );
    } else if (originalDesignId) {
      originalDesignLink = (
        <a href={fullUrl(modelNameToLink("design", originalDesignId))}>
          {originalDesignLink}
        </a>
      );
    } else {
      originalDesignLink = null;
    }

    const subsetReports = j5ReportSubsetJoins.map(j => j.subsetReport);
    const parentReports = j5ReportSubsetJ5ReportSubsetJoins.map(
      j => j.parentReport
    );

    let recordInfo = [["Current Design", designLink]];

    if (originalDesignLink) {
      recordInfo.push(["Design Upon Submission", originalDesignLink]);
    }
    recordInfo = recordInfo.concat([
      ["Assembly Method", startCase(assemblyMethod)],
      ["Assembly Type", assemblyType],
      ["Date Ran", formatDateTime(dateRan || createdAt)] //fallback to createdAt if dateRan isn't provided (dateRan is derived from the imported j5report)
    ]);

    if (parentReports.length) {
      recordInfo.push(["Parent Reports", reportLinks(parentReports)]);
    }
    if (subsetReports.length) {
      recordInfo.push(["Subset Reports", reportLinks(subsetReports)]);
    }
    const infoFields = recordInfo;

    // new hierarchical fields exported from design
    const extraFields = [
      "isHierarchical",
      "outputCardName",
      "assemblyBatchId",
      "treePosition",
      "assemblyReactionEnzymes"
    ];
    extraFields.forEach(_field => {
      let field = _field;
      if (has(j5Report, field)) {
        let value = j5Report[field];
        // Some j5Reports may be imported with a wrong, deleted or external assemblyBatchId
        // when this happens the j5-import function nullifies such field for FK constraint reasons
        // and stores the id value in the "idFromOriginalAssemblyBatch" field.
        if (field === "assemblyBatchId") {
          value = j5Report[field] || j5Report["idFromOriginalAssemblyBatch"];
        }
        if (field === "isHierarchical") {
          value = value ? "Yes" : "No";
        }
        if (field === "assemblyReactionEnzymes") {
          const assemblyReactionEnzymes = j5Report[field];
          // NOTE: Currently, we only handle a single restriction enzyme per assembly
          const restrictionEnzyme =
            assemblyReactionEnzymes?.[0]?.restrictionEnzyme;

          field = "assemblyReactionEnzyme";
          value = restrictionEnzyme?.name;
          if (!value) return;
        }
        infoFields.push([startCase(field), value]);
      }
    });

    const hasBuildableConstructs = j5Report.j5RunConstructs.length;
    const toolkitDisabled =
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement;
    const isDisabled = toolkitDisabled || !hasBuildableConstructs;
    return (
      <div className="j5-report-header tg-card">
        <RecordInfoTable sections={[infoFields]} />
        {additionalHeaderComponent}
        <div style={{ marginTop: 10 }}>
          <DisabledToolkitPopover disabled={toolkitDisabled} forButton>
            <LinkAllJ5MaterialsButton
              disabled={isDisabled}
              intent="success"
              j5Report={j5Report}
            ></LinkAllJ5MaterialsButton>
          </DisabledToolkitPopover>
          <Button
            key="exportAsCsv"
            loading={exportingReport}
            onClick={() =>
              handleExportButton(async fileName => {
                window.toastr.success("Download is starting");
                try {
                  await saveStreamResponse({
                    url: "/getJ5Csv",
                    body: {
                      j5ReportId: j5Report.id
                    },
                    filename: fileName || j5Report.name
                  });
                  window.toastr.success("File downloaded");
                } catch (error) {
                  console.error(error);
                  window.toastr.error("Error retrieving j5 report");
                }
              })
            }
          >
            Export as CSV
          </Button>
          <Button
            key="exportAsJson"
            loading={exportingReport}
            onClick={() =>
              handleExportButton(async fileName => {
                window.toastr.success("Download is starting");
                try {
                  await saveStreamResponse({
                    url: "/getJ5Json",
                    body: {
                      j5ReportId: j5Report.id
                    },
                    filename: fileName || j5Report.name
                  });
                } catch (e) {
                  console.error(e);
                  window.toastr.error("Error retrieving j5 report");
                }
              })
            }
          >
            Export as JSON
          </Button>
          {additionalHeaderButtons}
        </div>
        <SynthonAdditionalHeaderEl
          toggleShowSynthons={() =>
            setShowSynthons(prevShowSynthons => !prevShowSynthons)
          }
          showSynthons={showSynthons}
          j5ReportId={j5Report.id}
        />
      </div>
    );
  };

  const createSchemaForCombinationOfAssemblyPieces = (
    j5RunConstructs,
    tableSchema
  ) => {
    const extraColumns = getConstructAssemblyColumns(j5RunConstructs);
    return {
      fields: [
        {
          path: "id",
          type: "string",
          displayName: "ID",
          isHidden: true
        },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "sequence.size",
          displayName: lengthBp
        },
        {
          type: "string",
          displayName: "Assembly Method",
          render: () => startCase(assemblyMethod)
        },
        ...extraColumns,
        ...tableSchema.fields
      ]
    };
  };
  const isGibson = useCallback(() => {
    if (!j5Report) return false;
    return j5Report.assemblyMethod === "SLIC/Gibson/CPEC";
  }, [j5Report]);

  const isGoldenGate = useCallback(() => {
    if (!j5Report) return false;
    return j5Report.assemblyMethod === "GoldenGate";
  }, [j5Report]);

  /**
   * Given the model (pluralized) get the schema corresponding to the model. This
   * will either be the default schema or the one returned by the prop `getSchema`
   * if that prop is passed. The prop will be called with the model as the first argument and
   * the default schema as its second argument. The prop should not mutate the schema.
   * @param {string} model Should be pluralized.
   */
  const getSchema = useCallback(
    model => {
      if (model === "combinationOfAssemblyPieces") {
        throw new Error(
          "Due to pecularities in the code, we cannot override the schema for combinationOfAssemblyPieces"
        );
      }

      return getDefaultSchemas(isGoldenGate(), isGibson())[model];
    },
    [isGibson, isGoldenGate]
  );

  const onConstructDoubleClick = c =>
    history.push({
      pathname: modelNameToLink("sequences", c.sequence.id),
      // this fn is actually used not only by constructs but also other
      // j5 model records such as APs, Synthons, etc.
      // We'd like the sequence name to be that j5Record's name
      // instead of the actual sequence name that j5Record is linked to,
      // because some j5Records point to the same j5Sequence.
      ...(!c?.sequence?.isInLibrary && {
        state: { seqName: c.name || c.sequence.name }
      })
    });

  if (!j5Report) {
    return <div>No report found!</div>;
  }

  let dataTableProps = sharedTableProps;
  if (passedDataTableProps) {
    dataTableProps = {
      ...sharedTableProps,
      ...passedDataTableProps
    };
  }

  const tableCardProps = {
    history,
    j5ReportId: j5Report.id,
    createOrderModels,
    tableProps: dataTableProps
  };

  // When is a Mock Assembly APs are identical to the DNA Parts
  // in the design so its not very useful to create duplicate dna sequences
  // for them. Also when importing these reports in BUILD, the AP will be correctly
  // linked to the DNA Material.
  // For these reasons, there's no sequence to open here.
  const assemblyPiecesHaveSequences = j5Report.assemblyMethod !== "Mock";

  return (
    <div className="j5-report-container">
      <ScrollToTop scrollContainer={document.getElementById("app-body")} />
      <div style={{ display: "flex-columns" }}>
        {renderHeader()}
        {!noPrebuiltConstructs && (
          <J5TableCard
            {...tableCardProps}
            helperMessage="Prebuilt constructs are the desired sequences that have already
             been built and are available in your library."
            title="Prebuilt Constructs"
            processData={processDataForTables.prebuiltConstruct}
            additionalTableFilter={(props, qb) => {
              qb.whereAll({
                isPrebuilt: true
              });
            }}
            columnToSortBy="constructName"
            fragment={j5RunConstructFragment}
            onDoubleClick={onConstructDoubleClick}
            schema={getSchema("j5RunConstructs")}
          />
        )}
        <J5TableCard
          {...tableCardProps}
          helperMessage="Constructs are the desired sequences to be built in a j5 run."
          title="Assembled Constructs"
          processData={processDataForTables.j5RunConstruct}
          additionalTableFilter={(props, qb) => {
            qb.whereAll({
              isPrebuilt: false
            });
          }}
          pathToJ5Sequence="sequence"
          fragment={j5RunConstructFragment}
          onDoubleClick={onConstructDoubleClick}
          tableProps={{
            ...dataTableProps,
            // pass these so that the table will re-render when these come in
            j5ReportVersion: j5Report.version,
            loadingJ5LogMessages,
            j5LogMessages
          }}
          cellRenderer={getIsLinkedCellRenderer(
            "sequence.polynucleotideMaterialId"
          )}
          columnToSortBy="constructName"
          schema={getSchema("j5RunConstructs")}
          openTitleElements={({ tableParams }) => [
            ...saveableSeqsTitleEls({
              model: "j5RunConstruct",
              refetchSeqTable: tableParams.onRefresh
            }),
            getExportSeqs({
              frag: gql`
                fragment j5RunConstructSequenceIdFragment on j5RunConstruct {
                  id
                  sequence {
                    id
                  }
                }
              `,
              j5ReportId: j5Report.id
            })
          ]}
          // cellRenderer={}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="Input Sequences are the sequences that contain the Input Parts."
          title="Input Sequences"
          pathToJ5Sequence="sequence"
          onDoubleClick={onConstructDoubleClick}
          // processData={processDataForTables.j5InputSequence}
          // columnToSortBy={"name"} // column isn't sortable due to nested query
          fragment={j5InputSequenceFragment}
          cellRenderer={getIsLinkedCellRenderer(
            "sequence.polynucleotideMaterialId"
          )}
          schema={getSchema("j5InputSequences")}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="Input Parts are the segments of sequence that are being used in
          a j5 run."
          title="Input Parts"
          processData={processDataForTables.j5InputPart}
          fragment={j5InputPartFragment}
          onDoubleClick={c => {
            history.push({
              pathname: modelNameToLink("sequences", c.part.sequence.id)
            });
          }}
          additionalTableFilter={(props, qb) => {
            qb.whereAll({
              "j5InputSequence.j5ReportId": j5Report.id
            });
          }}
          withJ5ExtendedProperties={{ model: "part" }}
          noJ5ReportId
          schema={getSchema("j5InputParts")}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="This is the list of oligos that need to be directly synthesized."
          title="Assembly Oligos"
          pathToJ5Sequence="oligo.sequence"
          showSynthons={showSynthons}
          additionalTableFilter={(props, qb) => {
            if (!showSynthons) {
              qb.whereAny({
                "oligo.j5PcrReactionsForwardPrimers.note": qb.contains("PCR"),
                "oligo.j5PcrReactionsReversePrimers.note": qb.contains("PCR")
              });
            }
          }}
          fragment={j5OligoSynthesisFragment}
          onDoubleClick={j5Oli => {
            onConstructDoubleClick(j5Oli.oligo);
          }}
          columnToSortBy="name"
          schema={getSchema("j5OligoSyntheses")}
          openTitleElements={({ tableParams }) => [
            ...saveableSeqsTitleEls({
              isOligos: true,
              model: "j5OligoSynthesis",
              refetchSeqTable: tableParams.onRefresh
            }),
            renderDownloadOligoButton()
          ]}
          cellRenderer={getIsLinkedCellRenderer(
            "oligo.sequence.polynucleotideMaterialId"
          )}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="This is the list of annealed oligos."
          title="Annealed Oligos"
          pathToJ5Sequence="sequence"
          openTitleElements={({ tableParams }) => [
            ...saveableSeqsTitleEls({
              isAnnealedOligos: true,
              model: "j5AnnealedOligo",
              refetchSeqTable: tableParams.onRefresh
            }),
            getExportSeqs({
              frag: gql`
                fragment j5AnnealedOligoSequenceIdFragment on j5AnnealedOligo {
                  id
                  sequence {
                    id
                  }
                }
              `,
              j5ReportId: j5Report.id
            })
          ]}
          onDoubleClick={onConstructDoubleClick}
          processData={processDataForTables.j5AnnealedOligo}
          additionalTableFilter={(props, qb) => {
            qb.whereAll({
              "topOligo.id": qb.notNull(),
              "bottomOligo.id": qb.notNull()
            });
          }}
          fragment={j5AnnealedOligoFragment}
          schema={getSchema("j5AnnealedOligos")}
        />
        <J5TableCard
          {...tableCardProps}
          helperMessage="This is the list of digest linearized fragments that are going to be used in the assembly reaction."
          title="Digest Linearized Fragments"
          pathToJ5Sequence="sequence"
          onDoubleClick={onConstructDoubleClick}
          // processData={processDataForTables.j5DirectSynthesis}
          schema={{
            fields: getDigestFragmentFields(isGoldenGate(), isGibson())
          }}
          fragment={j5AssemblyPieceFragment}
        />

        <J5TableCard
          {...tableCardProps}
          title="Synthon Sequences"
          helperMessage="This is the list of DNA pieces that need to be directly synthesized."
          pathToJ5Sequence="sequence"
          onDoubleClick={onConstructDoubleClick}
          // processData={processDataForTables.j5DirectSynthesis}
          schema={getSchema("j5DirectSyntheses")}
          fragment={j5DirectSynthesisFragment}
          columnToSortBy="id"
          openTitleElements={({ tableParams }) => [
            ...saveableSeqsTitleEls({
              isSynthonSequences: true,
              model: "j5DirectSynthesis",
              refetchSeqTable: tableParams.onRefresh
            }),
            renderDownloadSynthonsButton()
          ]}
          cellRenderer={getIsLinkedCellRenderer(
            "oligo.sequence.polynucleotideMaterialId"
          )}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="These are the PCR reactions that need to be run to generate the
              assembly pieces."
          title="PCRs"
          pathToJ5Sequence="pcrProductSequence"
          columnToSortBy="name"
          showSynthons={showSynthons}
          additionalTableFilter={(props, qb) => {
            if (!showSynthons) {
              qb.whereAll({ note: qb.notContains("Direct Synthesis") });
            }
          }}
          openTitleElements={pcrReactionsTitleElements}
          fragment={j5PcrReactionFragment}
          schema={getSchema("j5PcrReactions")}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="These are the pieces of DNA that will get put together in a
              final assembly reaction (Gibson/CPEC/SLIC/Golden Gate) to give
              the desired Constructs."
          title="Assembly Pieces"
          pathToJ5Sequence="sequence"
          openTitleElements={
            assemblyPiecesHaveSequences &&
            (({ tableParams }) => [
              ...saveableSeqsTitleEls({
                isj5AssemblyPieces: true,
                model: "j5AssemblyPiece",
                refetchSeqTable: tableParams.onRefresh
              }),
              getExportSeqs({
                frag: gql`
                  fragment j5AssemblyPiecesSequenceIdFragment on j5AssemblyPiece {
                    id
                    sequence {
                      id
                    }
                  }
                `,
                j5ReportId: j5Report.id
              })
            ])
          }
          fragment={j5AssemblyPieceFragmentGql}
          onDoubleClick={assemblyPiecesHaveSequences && onConstructDoubleClick}
          columnToSortBy="name"
          schema={getSchema("j5AssemblyPieces")}
          cellRenderer={getIsLinkedCellRenderer(
            "sequence.polynucleotideMaterialId"
          )}
        />

        <J5TableCard
          {...tableCardProps}
          helperMessage="This lists which assembly pieces need to be combined to create
              each construct."
          title="Assemblies"
          processData={processDataForTables.j5RunConstruct}
          fragment={j5RunConstructFragment}
          pathToJ5Sequence="sequence"
          onDoubleClick={onConstructDoubleClick}
          createSchema={createSchemaForCombinationOfAssemblyPieces}
        />
      </div>
    </div>
  );
};

// Decorate the form component
export default reduxForm({
  form: "j5Report" // a unique name for this form
})(J5ReportRecordView);
