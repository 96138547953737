/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Loading } from "@teselagen/ui";
import { NonIdealState } from "@blueprintjs/core";

export default ({ loading, objectName }) => {
  return loading ? (
    <Loading />
  ) : (
    <NonIdealState visual="eye-off" title={objectName + " not found"} />
  );
};
