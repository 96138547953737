/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { DialogFooter, BlueprintError, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import { updateAliquotsWithReagents } from "../../../utils";
import GenericSelect from "../../../../src-shared/GenericSelect";
import "./style.css";
import { volumeRender } from "../../../../src-shared/utils/unitUtils";
import { validateReagentVolumes } from "../../LimsTools/AddReagentsTool/AddReagents";
import ChangeAdditiveCompositionField from "../../ChangeAdditiveCompositionField";
import UnitFields from "../../UnitFields";
import gql from "graphql-tag";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../src-shared/apolloMethods";

const addAdditivesFragment = gql`
  fragment addAdditivesFragment on additive {
    id
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
    lotId
    lot {
      id
      additiveMaterialId
    }
    additiveMaterialId
  }
`;

const addAdditivesAliquotFragment = gql`
  fragment addAdditivesAliquotFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    cellCount
    cellConcentration
    cellConcentrationUnitCode
    additives {
      ...addAdditivesFragment
    }
    aliquotContainer {
      id
      aliquotContainerType {
        code
        maxVolume
        volumetricUnitCode
        isTube
      }
    }
    sample {
      id
      material {
        id
        polynucleotideMaterialSequence {
          id
          molecularWeight
        }
        functionalProteinUnit {
          id
          molecularWeight
        }
      }
    }
  }
  ${addAdditivesFragment}
`;

const addAdditivesTubeFragment = gql`
  fragment addAdditivesTubeFragment on aliquotContainer {
    id
    additives {
      ...addAdditivesFragment
    }
    aliquotContainerType {
      code
      maxVolume
      volumetricUnitCode
      isTube
    }
  }
  ${addAdditivesFragment}
`;

class AddAdditivesDialog extends Component {
  state = {
    page: 1
  };

  nextPage = () => {
    this.setState({
      page: this.state.page + 1
    });
  };

  prevPage = () => {
    this.setState({
      page: this.state.page - 1
    });
  };

  onSubmit = async values => {
    const {
      hideModal,
      refetch,
      aliquot: _aliquot,
      aliquotContainer: _aliquotContainer
    } = this.props;
    const { reagentInfo, reagents, changeAdditiveComposition } = values;
    try {
      let aliquotContainer, aliquot;
      if (_aliquot) {
        aliquot = await safeQuery(addAdditivesAliquotFragment, {
          variables: {
            id: _aliquot.id
          }
        });
      } else {
        aliquotContainer = await safeQuery(addAdditivesTubeFragment, {
          variables: {
            id: _aliquotContainer.id
          }
        });
      }
      const error = await updateAliquotsWithReagents({
        aliquots: aliquot ? [aliquot] : [],
        aliquotContainers: aliquotContainer ? [aliquotContainer] : [],
        changeAdditiveComposition,
        reagents,
        reagentInfo
      });
      if (error) {
        throw new Error(error);
      }
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error adding additives`);
    }
  };

  renderPage1() {
    return (
      <React.Fragment>
        <GenericSelect
          {...{
            name: "reagents",
            isRequired: true,
            asReactSelect: true,
            isMultiSelect: true,
            label: "Select Reagents",
            schema: [
              {
                path: "name"
              },
              {
                displayName: "Type",
                path: "additiveType.name"
              }
            ],
            fragment: [
              "additiveMaterial",
              "id name isDry additiveType { code name } lots { id name additiveMaterialId }"
            ]
          }}
        />
      </React.Fragment>
    );
  }

  renderPage2() {
    const { reagents = [] } = this.props;
    return (
      <React.Fragment>
        <ChangeAdditiveCompositionField name="changeAdditiveComposition" />
        {reagents.length > 0 &&
          reagents.map((reagent, i) => {
            return this.renderAdditiveFields(reagent, i);
          })}
      </React.Fragment>
    );
  }

  renderAdditiveFields(reagent, i) {
    const { reagents = [] } = this.props;
    const tableParamOptions = {
      additionalFilter: () => {
        return {
          additiveMaterialId: reagent.id
        };
      }
    };

    return (
      <div key={reagent.id}>
        <div className="tg-flex column">
          <div className="tg-flex justify-space-between">
            <h6 style={{ width: "100%", marginTop: 20 }}>{reagent.name}</h6>
            {reagent.lots.length > 0 && (
              <GenericSelect
                {...{
                  name: `reagentInfo.id${reagent.id}.lot`,
                  asReactSelect: true,
                  label: "Specify Lot",
                  schema: [
                    {
                      path: "name"
                    },
                    {
                      displayName: "Volume",
                      render: volumeRender
                    }
                  ],
                  fragment: [
                    "lot",
                    /* GraphQL */ `
                      {
                        id
                        name
                        volume
                        volumetricUnitCode
                        mass
                        massUnitCode
                      }
                    `
                  ],
                  tableParamOptions
                }}
              />
            )}
          </div>
          <UnitFields
            {...{
              isDry: reagent.isDry,
              concentrationTypes: ["concentration"],
              namePrefix: `reagentInfo.id${reagent.id}.`
            }}
          />
        </div>
        {i < reagents.length - 1 && <hr className="tg-section-break dashed" />}
      </div>
    );
  }

  render() {
    const { hideModal, handleSubmit, submitting, error } = this.props;
    const { page } = this.state;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {page > 1 ? this.renderPage2() : this.renderPage1()}
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          text={page > 1 ? "Submit" : "Next"}
          onClick={handleSubmit(page === 1 ? this.nextPage : this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

function validate(values) {
  const { reagents = [], reagentInfo } = values;
  const errors = {};
  validateReagentVolumes({
    errors,
    reagents,
    reagentInfo
  });
  return errors;
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: props.isAliquot
          ? "Add Additives to Aliquot"
          : "Add Additives to Tube"
      };
    }
  }),
  reduxForm({
    form: "addAdditives",
    validate
  }),
  tgFormValues("reagents")
)(AddAdditivesDialog);
