/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import InventoryCheckButton from "../../../../InventoryCheckButton";

const assemblyPieceView = {
  model: "assemblyPieceView",
  fields: [
    {
      displayName: "Piece ID",
      path: "id",
      type: "number"
    },
    "name",
    "type",
    "containedParts",
    {
      path: "relatedConstructs"
    },
    {
      path: "containerCount",
      width: 130,
      displayName: "Inventory",
      filterDisabled: true,
      noEllipsis: true,
      immovable: true,
      render: (v, r) => {
        if (v <= 0) return "Not in Inventory";
        return (
          <InventoryCheckButton
            record={{
              id: r.id,
              __typename: "j5AssemblyPiece"
            }}
          />
        );
      }
    }
  ]
};

export default assemblyPieceView;
