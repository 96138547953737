/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty, keyBy, uniq } from "lodash";
import shortid from "shortid";
import _getNameKeyedItems from "../../utils/getNameKeyedItems";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import {
  handleNestedRecords,
  handleNewLinkagesOnUpdateRecords,
  removeJoins,
  upsertAddIds
} from "./utils";
import { getMaterialFields } from "../../sequence-import-utils/getMaterialFields";
import getSequenceMaterialsByHash from "../../sequence-import-utils/getSequenceMaterialsByHash";

const MICROBIAL_STRAIN = async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;
  const allPlasmidNames = [];
  const genomeNames = [];
  const growthMediumNames = [];
  const targetOrganismClassNames = [];
  let selectionMethods = [];
  let inductionMethods = [];
  const genusNames = [];
  const gasCompositions = [];

  const biosafetyLevels = await safeQuery(["biosafetyLevel", "code name"]);
  const lengthUnits = await safeQuery(["lengthUnit", "code"]);
  const keyedLengthUnits = keyBy(lengthUnits, u => u.code.toLowerCase());
  const keyedBiosafetyLevels = keyBy(biosafetyLevels, "name");

  const grabNames = r => {
    if (r.plasmidNames) {
      allPlasmidNames.push(...r.plasmidNames);
    }
    if (r.genomeName) {
      genomeNames.push(r.genomeName);
    }
    if (r.gasComposition) {
      gasCompositions.push(r.gasComposition);
    }
    if (r.growthMedium) {
      growthMediumNames.push(r.growthMedium);
    }
    if (r.genus) {
      genusNames.push(r.genus);
    }
    if (r.targetOrganismGroup) {
      targetOrganismClassNames.push(r.targetOrganismGroup);
    }
    if (r.selectionMethods) {
      selectionMethods = selectionMethods.concat(r.selectionMethods);
    }
    if (r.inductionMethods) {
      inductionMethods = inductionMethods.concat(r.inductionMethods);
    }
  };
  recordsToImport.forEach(r => {
    grabNames(r);
    if (r.__oldRecord) {
      grabNames(r.__oldRecord);
    }
  });

  //recordsToImport = [
  // {name: "new strain", plasmids: [{id: 10, name: 'existing plas'}, {name: 'new plas'}] }, //doesn't matter
  // {name: "existing strain", id: 111, plasmids:
  //   [{id: 10, name: 'existing unlinked plas'}, {id: 4, __remove: true}, {id: 5, name: 'existing linked plas'}, {name: 'new plas'}]
  // }
  // ]

  // __oldRecord: {name: "existing strain", id: 111, plasmids:
  //   [{id: 4, name: 'i got removed'}, {id: 5, name: 'existing linked plas'}]
  // }
  // {id: 10, name: 'existing unlinked plas'}
  // {name: 'new plas'} <- will now have an id {name: 'new plas', id: 99}

  await removeJoins(
    {
      recordsToImport,
      model: "strain",
      nested: "plasmid",
      nestedId: "polynucleotideMaterial.polynucleotideMaterialSequence.id"
    },
    ctx
  );

  const recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "plasmids",
    async sequences => {
      await upsertHandlers.DNA_SEQUENCE(
        {
          ...rest,
          model: "sequence",
          recordsToImport: sequences,
          upsertHandlers
        },
        ctx
      );
    }
  );

  const toUpdate = handleNewLinkagesOnUpdateRecords(
    recordsToContinueUpserting,
    "plasmids"
  );
  const plasmidIdToDnaMaterialId = {};
  const plasmidIds = toUpdate.map(([, plasmidId]) => plasmidId);

  const getNameKeyedItems = async props => _getNameKeyedItems(props, ctx);
  const keyedExistingPlasmids = await getNameKeyedItems({
    names: allPlasmidNames,
    model: "sequence",
    fragment: ["sequence", `id name hash polynucleotideMaterialId`]
  });

  recordsToContinueUpserting.forEach(r => {
    r.plasmids?.forEach(p => {
      plasmidIds.push(p.id);
    });
  });

  const sequencesWithMaterials = await ctx.safeQuery(
    ["sequence", "id name hash polynucleotideMaterialId"],
    { variables: { filter: { id: plasmidIds } } }
  );

  const allPlasmids = sequencesWithMaterials.concat(
    Object.values(keyedExistingPlasmids)
  );
  const newMaterials = [];
  const seqUpdates = [];
  const allHashes = allPlasmids.map(p => p.hash);
  const hashToMaterial = await getSequenceMaterialsByHash(allHashes, ctx);

  allPlasmids.forEach(({ id, name, hash, polynucleotideMaterialId }) => {
    if (polynucleotideMaterialId) {
      plasmidIdToDnaMaterialId[id] = polynucleotideMaterialId;
    } else if (hashToMaterial[hash]) {
      plasmidIdToDnaMaterialId[id] = hashToMaterial[hash].id;
    } else {
      const cid = shortid();
      newMaterials.push({
        ...getMaterialFields(),
        cid,
        name
      });
      plasmidIdToDnaMaterialId[id] = `&${cid}`;
      seqUpdates.push({
        id,
        polynucleotideMaterialId: `&${cid}`
      });
    }
  });

  await safeUpsert(
    "strainPlasmid",
    toUpdate.map(([strainId, plasmidId]) => ({
      strainId,
      polynucleotideMaterialId: plasmidIdToDnaMaterialId[plasmidId]
    }))
  );

  const keyedExistingGenus = await getNameKeyedItems({
    names: genusNames,
    model: "genus",
    fragment: ["genus", `id name species { id name}`]
  });

  const keyedExistingOrganismClasses = await getNameKeyedItems({
    names: targetOrganismClassNames,
    model: "targetOrganismClass"
  });
  const keyedSelectionMethods = await getNameKeyedItems({
    names: selectionMethods,
    model: "selectionMethod"
  });
  const keyedInductionMethods = await getNameKeyedItems({
    names: inductionMethods,
    model: "inductionMethod"
  });
  const keyedGasCompositions = await getNameKeyedItems({
    names: gasCompositions,
    model: "gasComposition"
  });

  const keyedExistingGrowthMediums = await getNameKeyedItems({
    names: growthMediumNames,
    model: "additiveMaterial"
  });

  const keyedExistingGenomes = await getNameKeyedItems({
    names: genomeNames,
    model: "genome"
  });

  const growthConditionUpdates = [];
  const newGrowthConditions = [];
  const strainUpdates = [];
  // update the materials
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      precheckFn: r => {
        if (r.plasmidNames) {
          const missingNames = r.plasmidNames.filter(
            n => !keyedExistingPlasmids[n.toLowerCase()]
          );
          if (missingNames.length)
            return `These plasmids were not found: ${missingNames.join(", ")}`;
        }

        if (
          r.growthMedium &&
          !keyedExistingGrowthMediums[r.growthMedium.toLowerCase()]
        ) {
          return `Could not find growth medium with name ${r.growthMedium}`;
        }
        if (
          r.gasComposition &&
          !keyedGasCompositions[r.gasComposition.toLowerCase()]
        ) {
          return `Could not find gas composition with name ${r.gasComposition}`;
        }
        if (
          r.targetOrganismGroup &&
          !keyedExistingOrganismClasses[r.targetOrganismGroup.toLowerCase()]
        ) {
          return `Could not find organism group with name ${r.organismClass}`;
        }
        if (r.lengthUnit && !keyedLengthUnits[r.lengthUnit.toLowerCase()]) {
          return `Could not find length unit ${r.lengthUnit}`;
        }
        if (r.species && !r.genus) {
          return `Must provide genus if specifying species`;
        }
        const genus = r.genus && keyedExistingGenus[r.genus.toLowerCase()];
        if (r.genus && !genus) {
          return `Could not find genus ${r.genus}`;
        }
        if (
          r.species &&
          !genus.species.find(
            s => s.name.toLowerCase() === r.species.toLowerCase()
          )
        ) {
          return `Could not find specie ${r.species} for genus ${r.genus}`;
        }
        if (r.genomeName && !keyedExistingGenomes[r.genomeName.toLowerCase()]) {
          return `Could not find genome ${r.genomeName}`;
        }
        if (r.selectionMethods) {
          const missing = r.selectionMethods.filter(
            m => !keyedSelectionMethods[m.toLowerCase()]
          );
          if (missing.length) {
            return `Could not find these selection methods: ${missing.join(
              ", "
            )}`;
          }
        }
        if (r.inductionMethods) {
          const missing = r.inductionMethods.filter(
            m => !keyedInductionMethods[m.toLowerCase()]
          );
          if (missing.length) {
            return `Could not find these induction methods: ${missing.join(
              ", "
            )}`;
          }
        }

        if (r.biosafetyLevel && !keyedBiosafetyLevels[r.biosafetyLevel]) {
          return `Invalid biosafetyLevel ${r.biosafetyLevel}`;
        }
      },
      convertUserFacingToDbModel: (r, { isBrandNewRecord, isUpdateRecord }) => {
        if (r.species) {
          const genus = keyedExistingGenus[r.genus.toLowerCase()];
          r.specieId = genus.species.find(
            s => s.name.toLowerCase() === r.species.toLowerCase()
          ).id;
        }
        delete r.species;
        delete r.genus;

        if (r.selectionMethods) {
          r.strainSelectionMethods = r.selectionMethods.map(m => {
            return {
              selectionMethodId: keyedSelectionMethods[m.toLowerCase()].id
            };
          });
        }
        delete r.selectionMethods;
        if (r.inductionMethods) {
          r.inductionMethodStrains = r.inductionMethods.map(m => {
            return {
              inductionMethodId: keyedInductionMethods[m.toLowerCase()].id
            };
          });
        }
        delete r.inductionMethods;

        if (isBrandNewRecord) {
          const materialIds = uniq(
            (r.plasmidNames || [])
              .map(n => {
                const sequence = keyedExistingPlasmids[n.toLowerCase()];
                return plasmidIdToDnaMaterialId[sequence.id];
              })
              .concat(
                (r.plasmids || []).map(p => plasmidIdToDnaMaterialId[p.id])
              )
          );
          //new strain
          r.strainPlasmids = materialIds.map(id => ({
            polynucleotideMaterialId: id
          }));
        }

        delete r.plasmidNames;
        delete r.plasmids;

        const organismClassId =
          r.targetOrganismGroup &&
          keyedExistingOrganismClasses[r.targetOrganismGroup.toLowerCase()].id;
        r.targetOrganismClassId = organismClassId;
        delete r.targetOrganismGroup;

        const genome =
          r.genomeName && keyedExistingGenomes[r.genomeName.toLowerCase()];
        if (genome) {
          r.genomeId = genome.id;
        }
        delete r.genomeName;

        r.strainTypeCode = "MICROBIAL_STRAIN";
        //strip off the sequence
        delete r.plasmidNames;
        delete r.plasmids;
        delete r.existingRecord;

        const growthConditionFields = {};
        const simpleGrowthConditionFields = [
          ["growthConditionName", "name"],
          ["growthConditionDescription", "description"],
          "temperature",
          "humidity",
          "shakerThrow",
          "shakerSpeed"
        ];
        simpleGrowthConditionFields.forEach(field => {
          if (Array.isArray(field)) {
            if (r[field[0]]) {
              growthConditionFields[field[1]] = r[field[0]];
            }
            delete r[field[0]];
          } else {
            if (r[field]) {
              growthConditionFields[field] = r[field];
            }
            delete r[field];
          }
        });
        const growthMediaId =
          r.growthMedium &&
          keyedExistingGrowthMediums[r.growthMedium.toLowerCase()].id;
        if (growthMediaId) {
          growthConditionFields.growthMediaId = growthMediaId;
        }
        delete r.growthMedium;
        const gasCompositionId =
          r.gasComposition &&
          keyedGasCompositions[r.gasComposition.toLowerCase()].id;
        if (gasCompositionId) {
          growthConditionFields.gasCompositionId = gasCompositionId;
        }
        delete r.gasComposition;
        const lengthUnit =
          r.lengthUnit && keyedLengthUnits[r.lengthUnit.toLowerCase()];
        if (lengthUnit) {
          growthConditionFields.lengthUnitCode = lengthUnit.code;
        }
        delete r.lengthUnit;
        if (!isEmpty(growthConditionFields)) {
          if (isUpdateRecord) {
            if (r.growthConditionId) {
              growthConditionUpdates.push({
                id: r.growthConditionId,
                ...growthConditionFields
              });
            } else {
              const cid = shortid();
              strainUpdates.push({
                id: r.id,
                growthConditionId: `&${cid}`
              });
              newGrowthConditions.push({ cid, ...growthConditionFields });
            }
          } else if (r.id) {
            // skip
          } else {
            `hitt growthConditionFields:`, growthConditionFields;
            r.growthCondition = growthConditionFields;
          }
        }
        if (r.biosafetyLevel) {
          r.biosafetyLevelCode = keyedBiosafetyLevels[r.biosafetyLevel].code;
        }
        delete r.biosafetyLevel;
        if (
          isEmpty(r.growthCondition) ||
          isEverythingUndefined(r.growthCondition)
        ) {
          delete r.growthCondition;
        }
        return r;
      },
      model: "strain"
    },
    ctx
  );
  // create new materials
  await safeUpsert("material", newMaterials);
  await safeUpsert("growthCondition", newGrowthConditions);

  await safeUpsert("strain", strainUpdates);

  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport: recordsToContinueUpserting,
      modelOrFragment: "strain"
    },
    ctx
  );
  await safeUpsert("growthCondition", growthConditionUpdates);
};

export default MICROBIAL_STRAIN;

function isEverythingUndefined(obj) {
  for (const key in obj) {
    if (obj[key] !== undefined) {
      return false;
    }
  }
  return true;
}
