/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import makeLazy from "./utils/initHelpers/makeLazy";

export default {
  "/sso-redirect": makeLazy(() => import("./AuthPage/SSORedirect")),
  "/login": makeLazy(() => import("./AuthPage/Login")),
  "/logout": makeLazy(() => import("./AuthPage/Logout")),
  "/password-reset/new-password": makeLazy(() => import("./AuthPage/Reset")),
  "/password-reset": makeLazy(() => import("./AuthPage/ResetRequest")),
  "/register": makeLazy(() => import("./AuthPage/Register")),
  "/api-docs": makeLazy(() => import("./ApiDocs"))
};
