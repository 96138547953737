/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import withQuery from "../../../src-shared/withQuery";
import { Redirect } from "react-router-dom";
import { compose } from "recompose";

class AAPartToAASequenceRedirect extends Component {
  render() {
    const { aminoAcidPart } = this.props;
    if (aminoAcidPart && aminoAcidPart.aminoAcidSequenceId) {
      return (
        <Redirect
          to={`/amino-acid-sequences/${aminoAcidPart.aminoAcidSequenceId}?bpRange=${aminoAcidPart.start}-${aminoAcidPart.end}`}
        />
      );
    } else {
      return null;
    }
  }
}

export default compose(
  withQuery(["aminoAcidPart", "id aminoAcidSequenceId start end"], {
    getIdFromParams: true,
    showLoading: true
  })
)(AAPartToAASequenceRedirect);
