/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const includeExtendedPropertyLimsModels = [
  "functionalProteinUnit",
  "aminoAcidSequence",
  "labProtocol",
  "lot",
  "additiveMaterial",
  "sequence",
  "part",
  "sequenceFeature",
  "containerArray",
  "sample",
  "aliquot",
  "material",
  "aliquotContainer",
  "strain",
  "worklistContainerArray",
  "worklistTransferAliquotContainer",
  "equipmentItem",
  "workflowRun",
  "workflowToolDefinition",
  "project",
  "isolationEvent",
  "customerRequest"
];
