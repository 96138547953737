// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.ModelGroupedHistogramWidgetStyles-module__container-3ihP7 {\n  margin: auto;\n  height: 95%;\n  width: 100%;\n}\n\n.ModelGroupedHistogramWidgetStyles-module__viz-3wN_D .ModelGroupedHistogramWidgetStyles-module__batchRect-ldbF3 {\n  fill: #137cbd;\n}\n\n.ModelGroupedHistogramWidgetStyles-module__viz-3wN_D .ModelGroupedHistogramWidgetStyles-module__expRect-3TXYd {\n  fill: #3dcc91;\n}\n\n.ModelGroupedHistogramWidgetStyles-module__viz-3wN_D .ModelGroupedHistogramWidgetStyles-module__legend-1bEKO {\n  fill: #738694;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ModelGroupedHistogramWidgetStyles-module__container-3ihP7",
	"viz": "ModelGroupedHistogramWidgetStyles-module__viz-3wN_D",
	"batchRect": "ModelGroupedHistogramWidgetStyles-module__batchRect-ldbF3",
	"expRect": "ModelGroupedHistogramWidgetStyles-module__expRect-3TXYd",
	"legend": "ModelGroupedHistogramWidgetStyles-module__legend-1bEKO"
};
module.exports = exports;
