/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button } from "@blueprintjs/core";
import React, { Component } from "react";
import { compose } from "recompose";
import { get } from "lodash";
import { ReactSelectField } from "@teselagen/ui";
import GenericSelect from "../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import withQuery from "../../../../src-shared/withQuery";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { getAliquotMaterialList } from "../../../utils/plateUtils";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { createDataTableFromPlateFragment } from "./fragments";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { isEmpty } from "lodash";

const getParentButtonText = v =>
  v && v.length ? "Change Parent Plates" : "Select Parent Plates";
class SelectPlates extends Component {
  generateDataTable = values => {
    const {
      dataTableType,
      parentPlates = [],
      sampleStatusCode,
      containerArrays = []
    } = values;
    const {
      nextStep,
      stepFormProps: { change }
    } = this.props;
    const dataRows = [];

    const parentPlateSampleIdToPlateInfo = {};

    parentPlates.forEach(p => {
      const plateInfo = {
        plateName: p.name,
        plateBarcode: p.barcode?.barcodeString
      };
      p.aliquotContainers.forEach(ac => {
        const sample = ac.aliquot?.sample;
        if (sample) {
          const wellPosition = getAliquotContainerLocation({
            ...ac,
            containerArray: p
          });
          parentPlateSampleIdToPlateInfo[sample.id] =
            parentPlateSampleIdToPlateInfo[sample.id] || [];
          parentPlateSampleIdToPlateInfo[sample.id].push({
            ...plateInfo,
            wellPosition
          });
        }
      });
    });

    containerArrays.forEach(containerArray =>
      containerArray.aliquotContainers.forEach(ac => {
        const sample = ac.aliquot?.sample;
        if (sample) {
          if (dataTableType.code.includes("SAMPLE_QC")) {
            if (sample.sampleStatusCode === sampleStatusCode) {
              const materials = getAliquotMaterialList(ac.aliquot);
              dataRows.push({
                rowValues: {
                  aliquotId: ac.aliquot.id,
                  sampleId: ac.aliquot.sample.id,
                  materialName: materials.map(material => material.name),
                  plateName: containerArray.name,
                  plateBarcode: get(containerArray, "barcode.barcodeString"),
                  well: getAliquotContainerLocation(ac),
                  volume: get(ac, "aliquot.volume")
                    ? `${get(ac, "aliquot.volume")} ${get(
                        ac,
                        "aliquot.volumetricUnitCode"
                      )}`
                    : "N/A",
                  concentration: get(ac, "aliquot.concentration")
                    ? `${get(ac, "aliquot.concentration")} ${get(
                        ac,
                        "aliquot.concentrationUnitCode"
                      )}`
                    : "N/A",
                  mass: get(ac, "aliquot.mass")
                    ? `${get(ac, "aliquot.mass")} ${get(
                        ac,
                        "aliquot.massUnitCode"
                      )}`
                    : "N/A"
                }
              });
            }
          } else if (dataTableType.code === "UPDATE_PLATES_TOOL") {
            parentPlateSampleIdToPlateInfo[sample.id].forEach(plateInfo => {
              const concentrationInfo = {};
              if (ac.aliquot.concentration) {
                concentrationInfo.concentration = ac.aliquot.concentration;
                concentrationInfo.concentrationUnit =
                  ac.aliquot.concentrationUnitCode;
              }
              if (ac.aliquot.molarity) {
                concentrationInfo.molarity = ac.aliquot.molarity;
                concentrationInfo.molarityUnit = ac.aliquot.molarityUnitCode;
              }
              if (!isEmpty(concentrationInfo)) {
                dataRows.push({
                  rowValues: {
                    ...plateInfo,
                    ...concentrationInfo
                  }
                });
              }
            });
          }
        }
      })
    );
    change("dataRows", dataRows);
    nextStep();
  };

  render() {
    const {
      isLoadingMap = {},
      isDisabledMap = {},
      Footer,
      handleSubmit,
      footerProps,
      sampleStatuses = [],
      dataTableType = {}
    } = this.props;

    const acceptedDataTableTypeCodes = [
      "VALID_SAMPLE_QC_INVENTORY_LIST",
      "INVALID_SAMPLE_QC_INVENTORY_LIST",
      "UPDATE_PLATES_TOOL"
    ];

    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Choose Data Table Type"
            helper="Select a data table type from the dropdown."
          />
          <div className="tg-flex column" style={{ maxWidth: 500 }}>
            <GenericSelect
              {...{
                name: "dataTableType",
                asReactSelect: true,
                isRequired: true,
                idAs: "code",
                schema: ["name"],
                fragment: ["dataTableType", "code name"],
                additionalDataFragment: [
                  "dataTableType",
                  "code name rowSchema"
                ],
                queryOptions: {
                  variables: {
                    sort: ["-createdAt"]
                  }
                },
                tableParamOptions: {
                  additionalFilter: (props, qb) => {
                    return {
                      code: qb.inList(acceptedDataTableTypeCodes)
                    };
                  }
                }
              }}
            />
            {dataTableType &&
              acceptedDataTableTypeCodes.includes(dataTableType.code) &&
              dataTableType.code.includes("SAMPLE_QC") && (
                <ReactSelectField
                  name="sampleStatusCode"
                  isRequired
                  defaultValue="VALID"
                  label="Sample Status"
                  tooltipInfo={`Choose the sample status you would like
                  to filter for. E.g. selecting "Valid" will populate
                  the output data table with all valid samples from selected plates.`}
                  options={arrayToIdOrCodeValuedOptions(sampleStatuses)}
                />
              )}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Replicate Plates"
            helper="Select replicate plates."
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              isRequired: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              isMultiSelect: true,
              fragment: [
                "containerArray",
                "id name barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: createDataTableFromPlateFragment,
              postSelectDTProps: {
                formName: "createDataTableFromPlateReplicatePlates",
                schema: [
                  platePreviewColumn(),
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ]
              },
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              }
            }}
          />
        </div>
        {dataTableType?.code === "UPDATE_PLATES_TOOL" && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Select Parent Plates"
              helper="Select parent plates."
            />
            <GenericSelect
              {...{
                name: "parentPlates",
                isRequired: true,
                getButtonText: getParentButtonText,
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: createDataTableFromPlateFragment,
                postSelectDTProps: {
                  formName: "createDataTableFromPlateParentPlates",
                  schema: [
                    platePreviewColumn(),
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" },
                    dateModifiedColumn
                  ]
                }
              }}
            />
          </div>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent="primary"
              onClick={handleSubmit(this.generateDataTable)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withQuery(["sampleStatus", "code name"], {
    isPlural: true,
    showLoading: true
  }),
  stepFormValues("dataTableType", "sampleStatusCode", "containerArrays")
)(SelectPlates);
