/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { toDecimalPrecision } from "./generalUtils";

export default function roundValueWithUnit(numericValue, unit) {
  if (!isNaN(numericValue)) {
    const roundedValue = toDecimalPrecision(numericValue);
    return roundedValue + ` ${unit}`;
  } else {
    return "N/A";
  }
}
