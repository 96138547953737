/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import ioItemWithLinkedItemFragment from "./ioItemWithLinkedItemFragment.gql";

export default gql`
  fragment microserviceTaskLibraryBuildFragment on microserviceQueue {
    id
    status
    result
    service
    input
    createdAt
    updatedAt
    startedOn
    completedOn
    microserviceIoFiles {
      id
      destinationPath
    }
    ioItem {
      ...ioItemWithLinkedItemFragment
    }
  }
  ${ioItemWithLinkedItemFragment}
`;
