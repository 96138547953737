/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { takeEvery, put, call, select } from "redux-saga/effects";
import actions from "../actions";
import { handleSaveDesign } from "./saveDesign/index";
import { isDesignLocked } from "../../utils/designUtils/isDesignLocked";

let debug = () => {};
if (window.frontEndConfig.logEditDesignActions) {
  debug = console.info;
}
// I believe we cannot use redux-undo as we need to alter
// the undo/redo stacks upon receiving the new ids after
// saving the design.
const handleEditDesignAction = actionKey =>
  function* ({ payload }) {
    try {
      if (payload && payload.overrideLock) {
        delete payload.overrideLock;
      } else {
        // We can't edit locked designs.
        //tnr: we need a way to override this (we should still be able to change a design even if it's locked in certain cases)
        if (yield select(isDesignLocked)) {
          debug(`design is locked`);
          return;
        }
      }

      debug(`adding design to undo redo stack`);
      // we need a way to NOT add certain actions to the undo redo stack (aka locking)
      if (payload && payload.doNotAddToUndoStack) {
        delete payload.doNotAddToUndoStack;
      } else {
        // Add the previous state to the undo stack.
        yield put(actions.ui.designEditor.undo.addToUndoStack());
      }

      // Actually change the design using a designEdit action.
      yield put(actions.designEdit[actionKey](payload));
      debug(`calling the save action`);
      // Autosave
      yield call(handleSaveDesign);
    } catch (e) {
      console.error(e);
      yield call(window.toastr.error, "Error editing design.");
    }
  };

export default function* watchEditDesignActions() {
  //we ONLY want to filter out the actions.design not the actions.designEdit!
  for (const [actionKey, action] of Object.entries(actions.design)) {
    yield takeEvery(action, handleEditDesignAction(actionKey));
  }
}

//tnrnote:
// actions.designEdit (DO NOT USE THESE IN GENERAL CODEBASE)
//  - actually triggers reducer (immediately)

//  vs

// actions.design (USE THESE)
// - does not trigger reducer
// - trigger handleEditDesignAction to be hit
// - "puts" a second action (a designEdit action) that does trigger reducer
// - has options overrideLock and doNotAddToUndoStack
// overrideLock allows an action to still occur despite the design being locked
// doNotAddToUndoStack prevents the action from being added to the undo redo stack
