/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint no-loop-func: 0 */
import React from "react";
import { get, keyBy, map, pick } from "lodash";
import shortid from "shortid";
import StepForm from "../../../../src-shared/StepForm";

import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import dataTableFragment from "../../../graphql/fragments/dataTableFragment";
import AssayPlanningInputs from "./Steps/AssayPlanningInputs";
import ReactionConditions, {
  REACTION_CONDITION_GROUP_CSV_HEADER
} from "./Steps/ReactionConditions";
import {
  throwFormError,
  removeDecimal,
  REQUIRED_ERROR
} from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getPositionFromAlphanumericLocation } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

const onSubmit = async values => {
  try {
    const {
      reactionMapName,
      plateMapGroupName,
      existingPlateLayerDefinitions = [],
      selectedFpuForPlasmid = {},
      plasmidsFromDataTables = [],
      extraPlateLayers = [],
      userLayerColor = {},
      assayPlanningCsv,
      plasmidIdToMaterial = {},
      reactionConditionRows = {}
    } = values;

    const allPlateLayerNames = extraPlateLayers.concat(
      REACTION_CONDITION_GROUP_CSV_HEADER
    );

    const allPlateLayers = allPlateLayerNames.map(plName => {
      return {
        name: plName,
        plateZoneDefinitions: []
      };
    });
    const keyedPlasmids = keyBy(plasmidsFromDataTables, "name");
    const keyedExistingPlateLayerDefinitions = keyBy(
      existingPlateLayerDefinitions,
      "name"
    );

    const csvData = get(assayPlanningCsv, "[0].parsedCsv.data", []);
    const plateMaps = {};
    const plateZoneDefinitionIdMap = {};
    const plateLayerToPlateZonesMap = {};
    const plateLayerDefinitionIdMap = {};
    const plateZoneDefIdToPlateZoneId = {};
    const newPlateLayerDefinitions = [];
    const newPlateZoneDefinitions = [];
    const updatePlateZoneDefinitions = [];
    const newPlateMapItems = [];

    for (const row of csvData) {
      allPlateLayers.forEach(({ name, plateZoneDefinitions }) => {
        const zoneName = row[name];
        if (
          zoneName &&
          !plateZoneDefinitions.some(pzList => pzList.name === zoneName)
        ) {
          plateZoneDefinitions.push({
            name: zoneName,
            color: get(userLayerColor, `al${name}.li${removeDecimal(zoneName)}`)
          });
        }
      });
    }

    allPlateLayers.forEach(plateLayerDef => {
      const { name, plateZoneDefinitions } = plateLayerDef;
      const existingPlateLayerDef = keyedExistingPlateLayerDefinitions[name];
      plateLayerToPlateZonesMap[name] = {};
      if (existingPlateLayerDef) {
        plateLayerDefinitionIdMap[name] = existingPlateLayerDef.id;
        plateZoneDefinitions.forEach(plateZoneDef => {
          const existingZoneDef = existingPlateLayerDef.plateZoneDefinitions.find(
            pzd => pzd.name === plateZoneDef.name
          );
          if (existingZoneDef) {
            plateZoneDefinitionIdMap[plateZoneDef.name] = existingZoneDef.id;
            plateLayerToPlateZonesMap[name][plateZoneDef.name] =
              existingZoneDef.id;
            if (existingZoneDef.color !== plateZoneDef.color) {
              updatePlateZoneDefinitions.push({
                id: existingZoneDef.id,
                color: plateZoneDef.color
              });
            }
          } else {
            plateZoneDef.plateLayerDefinitionId = existingPlateLayerDef.id;
            plateZoneDef.cid = shortid();
            plateZoneDefinitionIdMap[
              plateZoneDef.name
            ] = `&${plateZoneDef.cid}`;
            plateLayerToPlateZonesMap[name][
              plateZoneDef.name
            ] = `&${plateZoneDef.cid}`;
            newPlateZoneDefinitions.push(plateZoneDef);
          }
        });
      } else {
        plateLayerDef.cid = shortid();
        plateLayerDefinitionIdMap[name] = `&${plateLayerDef.cid}`;
        plateZoneDefinitions.forEach(zone => {
          zone.cid = shortid();
          plateZoneDefinitionIdMap[zone.name] = `&${zone.cid}`;
          plateLayerToPlateZonesMap[name][zone.name] = `&${zone.cid}`;
        });
        plateLayerDef.isReactionConditionLayer =
          plateLayerDef.name === REACTION_CONDITION_GROUP_CSV_HEADER;
        // add reaction conditions
        if (plateLayerDef.isReactionConditionLayer) {
          plateLayerDef.plateZoneDefinitions.forEach(zone => {
            const reactionConditionsForZone = reactionConditionRows[zone.name];
            if (reactionConditionsForZone && reactionConditionsForZone.length) {
              zone.reactionConditions = reactionConditionsForZone.map(rcz => {
                return {
                  ...pick(rcz, [
                    "concentration",
                    "concentrationUnitCode",
                    "mass",
                    "massUnitCode",
                    "volume",
                    "volumetricUnitCode"
                  ]),
                  materialId: get(rcz, "material.id"),
                  additiveMaterialId: get(rcz, "additiveMaterial.id")
                };
              });
            }
          });
        }
        newPlateLayerDefinitions.push(plateLayerDef);
      }
    });

    // plasmidsFromDataTables - all plasmids found on selected data tables
    // plasmidIdToMaterial - map from plasmid id to the material they are on
    const reactionMap = {
      name: reactionMapName,
      reactionTypeCode: "PROTEIN_PURIFICATION",
      reactions: []
    };
    const addedReactions = {};
    for (const row of csvData) {
      const plateName = row["Plate Name"];
      const plasmidName = row["Plasmid ID"];
      const well = row["Well"];
      const plasmid = keyedPlasmids[plasmidName];
      const fpu =
        selectedFpuForPlasmid[plasmid.id] ||
        get(plasmid, "sequenceFpus[0].functionalProteinUnit");
      const microbialMaterial = plasmidIdToMaterial[plasmid.id];
      if (!fpu.proteinMaterial) {
        throw new Error(
          `The FPU ${fpu.name} is not linked to a material. Please link all FPUs before retrying tool.`
        );
      }
      const proteinMaterialId = fpu.proteinMaterial.id;
      if (!(addedReactions[microbialMaterial.id] === proteinMaterialId)) {
        addedReactions[microbialMaterial.id] = proteinMaterialId;
        reactionMap.reactions.push({
          name: microbialMaterial.name + " > " + fpu.proteinMaterial.name,
          ...getReactionInputsAndOutputs({
            inputMaterials: [microbialMaterial],
            outputMaterials: [proteinMaterialId]
          })
        });
      }
      const {
        rowPosition,
        columnPosition
      } = getPositionFromAlphanumericLocation(well);
      if (rowPosition > 7 || columnPosition > 11) {
        throw new Error(`Well ${well} is not valid for a 96 well plate.`);
      }
      if (!plateMaps[plateName]) {
        plateMaps[plateName] = {
          cid: shortid(),
          name: plateName,
          plateLayers: allPlateLayerNames.map(plName => {
            return {
              plateLayerDefinitionId: plateLayerDefinitionIdMap[plName],
              plateZones: map(
                plateLayerToPlateZonesMap[plName],
                plateZoneDefinitionId => {
                  const cid = shortid();
                  plateZoneDefIdToPlateZoneId[
                    plateZoneDefinitionId
                  ] = `&${cid}`;
                  return {
                    cid,
                    plateZoneDefinitionId
                  };
                }
              )
            };
          })
        };
      }
      newPlateMapItems.push({
        plateMapId: `&${plateMaps[plateName].cid}`,
        rowPosition,
        columnPosition,
        inventoryItem: {
          inventoryItemTypeCode: "MATERIAL",
          materialId: fpu.proteinMaterial.id
        },
        plateZoneWells: allPlateLayerNames.reduce((acc, plName) => {
          const plateZoneName = row[plName];
          if (plateZoneName) {
            const plateZoneDefinitionId =
              plateLayerToPlateZonesMap[plName][plateZoneName];
            const plateZoneId =
              plateZoneDefIdToPlateZoneId[plateZoneDefinitionId];
            if (plateZoneDefinitionId && plateZoneId) {
              acc.push({
                isPlateMapItem: true,
                plateZoneId
              });
            }
          }
          return acc;
        }, [])
      });
    }

    const newPlateMaps = Object.values(plateMaps);

    await safeUpsert("plateZoneDefinition", updatePlateZoneDefinitions);
    await safeUpsert("plateZoneDefinition", newPlateZoneDefinitions);
    await safeUpsert("plateLayerDefinition", newPlateLayerDefinitions);
    // const createdPlateMaps =
    const plateMapGroup = {
      name: plateMapGroupName,
      containerFormatCode: "96_WELL",
      plateMaps: newPlateMaps
    };
    const [createdPlateMapGroup] = await safeUpsert(
      "plateMapGroup",
      plateMapGroup
    );
    await safeUpsert("plateMapItem", newPlateMapItems);
    const [createdReactionMap] = await safeUpsert("reactionMap", reactionMap);
    return {
      plateMapGroup: createdPlateMapGroup,
      reactionMap: createdReactionMap
    };
  } catch (error) {
    console.error("error:", error);
    throwFormError(error.message || "Error creating new plate maps");
  }
};

const validate = values => {
  const { selectedFpuForPlasmid = {} } = values;
  const errors = {};
  if (values.assayPlanningCsv && !values.assayPlanningCsv.length) {
    errors.assayPlanningCsv = REQUIRED_ERROR;
  }

  errors.selectedFpuForPlasmid = {};
  Object.keys(selectedFpuForPlasmid).forEach(key => {
    if (!selectedFpuForPlasmid[key]) {
      errors.selectedFpuForPlasmid[key] = REQUIRED_ERROR;
    }
  });
  return errors;
};

const AssayPlanningTool = ({
  toolIntegrationProps,
  toolSchema,
  isToolIntegrated,
  loadingDataTables,
  initialValues
}) => {
  const steps = [
    {
      title: "Assay Planning Inputs",
      Component: AssayPlanningInputs,
      props: {
        loadingDataTables
      },
      withCustomFooter: true
    },
    {
      title: "Reaction Conditions",
      Component: ReactionConditions,
      withCustomFooter: true
    }
  ];

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      validate={validate}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default withWorkflowInputs(dataTableFragment)(AssayPlanningTool);
