/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-loop-func */

import React, { Component } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  CheckboxField,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import TagField from "../../../src-shared/TagField";

import PlateUploadFields from "../PlateUploadFields";
import handleEmptyPlateImport from "../../../../tg-iso-lims/src/plateImports/handleEmptyPlateImport";
import { throwFormError } from "../../../src-shared/utils/formUtils";

export async function submitEmptyPlate(values, options) {
  const { makeError } = options;
  try {
    return await handleEmptyPlateImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.errors || error.message || "Error uploading plates.");
  }
}

class UploadEmptyPlatesUnwrapped extends Component {
  onSubmit = async values => {
    const { refetch } = this.props;
    await submitEmptyPlate(values, {
      makeError: throwFormError,
      refetch
    });
  };

  render() {
    const {
      noDialog,
      submitting,
      handleSubmit,
      hideModal,
      fieldPrefix = "",
      error
    } = this.props;

    return (
      <React.Fragment>
        <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
          <PlateUploadFields
            fieldPrefix={fieldPrefix}
            inTool={noDialog}
            templateFileOptions={{
              fileName: "Empty Plates",
              validateAgainstSchema: {
                fields: [
                  {
                    path: "plateName",
                    isRequired: true,
                    description: "The name of the plate.",
                    example: "Plate1"
                  },
                  {
                    path: "plateBarcode",
                    description: "The barcode of the plate.",
                    example: "Barcode123"
                  },
                  {
                    path: "plateType",
                    isRequired: true,
                    description: "The type of the plate.",
                    example: "TypeA"
                  }
                ]
              }
            }}
            emptyPlates
          />
          <CheckboxField
            name={fieldPrefix + "generateBarcode"}
            label="Generate Plate Barcodes"
          />
          <TagField name={fieldPrefix + "tags"} />
          <BlueprintError error={error} />
        </div>
        {!noDialog && (
          <DialogFooter
            submitting={submitting}
            hideModal={hideModal}
            onClick={handleSubmit(this.onSubmit)}
          />
        )}
      </React.Fragment>
    );
  }
}

export const UploadEmptyPlates = UploadEmptyPlatesUnwrapped;

export default compose(
  wrapDialog({
    title: "Upload Empty Plates"
  }),
  reduxForm({
    form: "uploadEmptyPlates"
  })
)(UploadEmptyPlates);
