/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import { getAllReactionIdsInSubtree } from "../../../../../tg-iso-design/utils/designEditUtils";
import { getOutputCardIdOfReaction } from "../../../../../tg-iso-design/selectors/designStateSelectors";

const removeReaction = (state, changeSetsHelper, reactionId) => {
  const fakeFullState = { design: state };
  const reactionIds = getAllReactionIdsInSubtree(state, reactionId);
  changeSetsHelper.deletePure("reaction", reactionIds);
  for (const rId of reactionIds) {
    const outputCardId = getOutputCardIdOfReaction(fakeFullState, rId);
    if (outputCardId) {
      changeSetsHelper.updatePure("card", {
        id: outputCardId,
        outputReactionId: null
      });
    }
  }

  // Remove all of the junction objects related to the deleted reactions.
  const reactionIdSet = keyBy(reactionIds, x => x);

  const junctionIds = Object.values(state.junction)
    .filter(v => reactionIdSet[v.reactionId])
    .map(v => v.id);

  changeSetsHelper.deletePure("junction", junctionIds);

  return changeSetsHelper;
};

export default removeReaction;
