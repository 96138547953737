/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import {
  checkBarcodesAndFormat,
  getMicrobialMaterialName,
  maxWellVolumeError
} from "../utils/plateUtils";
import {
  extendedPropertyUploadFragment,
  sequenceWithTagsAndAliasesAndExtendedProperitesFragment
} from "./helperFragments";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import { cleanUnit } from "../utils/unitUtils";
import { getParsedSequenceMatch } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import getNameKeyedItems from "../../../tg-iso-shared/src/utils/getNameKeyedItems";
import unitGlobals from "../unitGlobals";
import { cleanCommaSeparatedCell } from "../../../tg-iso-shared/src/utils/fileUtils";

export const requiredPlatedHeaders = ["PLATE_NAME", "WELL_LOCATION"];
export const requiredTubeHeaders = ["TUBE_NAME"];

export const materialNameHeaderMsg =
  "Optional. Can be used to link to an existing material. If no material exists with that name a new material will be created.";

export const strainNameHeaderMsg =
  "Required unless linking to an existing microbial material.";

export default async function handleMicrobialPlateOrTubeImport(
  values,
  ctx = isoContext
) {
  const { safeUpsert } = ctx;

  const { generateTubeBarcodes, isTubeUpload, generateBarcodes } = values;

  const {
    finishPlateCreate,
    finishTubeCreate,
    aliquotContainerType,
    containerArrayType,
    filename,
    csvData,
    newMaterials,
    getCsvRowExtProps
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      barcodeHeader: "PLATE_BARCODE",
      nameHeader: "PLATE_NAME",
      hasSequences: true,
      hasReagents: true,
      hasExtendedProperties: true,
      requiredFields: isTubeUpload
        ? requiredTubeHeaders
        : requiredPlatedHeaders,
      sequenceFragment: sequenceWithTagsAndAliasesAndExtendedProperitesFragment,
      getSequenceForRow: (row, index, allParsedSequences = []) => {
        const gbFileName = row.GB_FILE || "";
        if (!gbFileName) return null;
        const sequenceFilenames = cleanCommaSeparatedCell(gbFileName);
        const parsedSequences = [];
        sequenceFilenames.forEach(seqFilename => {
          const sequence = getParsedSequenceMatch(
            allParsedSequences,
            seqFilename
          );
          if (!sequence) {
            throw new Error(
              `Row ${
                index + 1
              } specifies the sequence file "${seqFilename}" but the corresponding file was not found.`
            );
          }
          parsedSequences.push(sequence);
        });
        return parsedSequences;
      }
    },
    ctx
  );

  const isRack = containerArrayType && !containerArrayType.isPlate;
  const mapPlates = {};
  const addedPropsForSequence = {};
  const strainNamesToCheck = [];
  const materialNamesToCheck = [];
  const microbialMaterialPlasmidsToCreate = [];
  const addedPropsForStrain = {};
  const addedPropsForMaterial = {};

  if (!isTubeUpload) {
    const continueUpload = await checkBarcodesAndFormat({
      data: csvData,
      generateTubeBarcodes,
      filename,
      containerArrayType,
      tubeBarcodeKey: "TUBE_BARCODE",
      barcodeKey: "PLATE_BARCODE",
      wellPositionHeader: "WELL_LOCATION"
    });

    if (!continueUpload) return;
  }

  // collect strain names to search for
  for (const [index, row] of csvData.entries()) {
    if (row.STRAIN_NAME) {
      strainNamesToCheck.push(row.STRAIN_NAME);
    }
    if (row.MATERIAL_NAME) {
      materialNamesToCheck.push(row.MATERIAL_NAME);
    }
    if (!row.STRAIN_NAME && !row.MATERIAL_NAME && !row.additives?.length) {
      throw new Error(
        `Row ${index + 1} did not specify a material or strain name.`
      );
    }
  }

  const strainFrag = `id name strainPlasmids { id polynucleotideMaterialId } ${extendedPropertyUploadFragment}`;
  const keyedStrains = await getNameKeyedItems(
    {
      names: strainNamesToCheck,
      fragment: ["strain", strainFrag],
      additionalFilter: {
        strainTypeCode: "MICROBIAL_STRAIN"
      }
    },
    ctx
  );

  const keyedMaterials = await getNameKeyedItems(
    {
      names: materialNamesToCheck,
      fragment: [
        "material",
        `id name strain { ${strainFrag} } ${extendedPropertyUploadFragment}`
      ],
      additionalFilter: {
        materialTypeCode: "MICROBIAL"
      }
    },
    ctx
  );

  const strainIds = [];
  for (const [index, row] of csvData.entries()) {
    const {
      MATERIAL_NAME: matName,
      STRAIN_NAME: strainName,
      sequences = [],
      strainId
    } = row;
    if (
      !strainId &&
      !matName &&
      !strainName &&
      !sequences.length &&
      row.additives?.length
    ) {
      row.onlyAdditives = true;
      continue;
    }

    const strain = keyedStrains[row.STRAIN_NAME.toLowerCase()];
    const material =
      row.MATERIAL_NAME && keyedMaterials[row.MATERIAL_NAME.toLowerCase()];
    if (!row.onlyAdditives) {
      if (material) {
        row.existingMaterial = material;
        const strain = material.strain;
        if (
          !strain ||
          (row.STRAIN_NAME &&
            strain.name.toLowerCase() !== row.STRAIN_NAME.toLowerCase())
        ) {
          throw new Error(
            `Row ${index + 1} material ${
              row.MATERIAL_NAME
            } not linked to a strain with name ${row.STRAIN_NAME}.`
          );
        }
        row.strainId = strain.id;
        row.strain = strain;
        strainIds.push(strain.id);
      } else {
        if (!strain) {
          if (!row.STRAIN_NAME) {
            throw new Error(`Row ${index + 1} didn't specify a strain name.`);
          }
          throw new Error(
            `Row ${index + 1} specifies the strain ${
              row.STRAIN_NAME
            } which doesn't exist.`
          );
        } else {
          row.strainId = strain.id;
          row.strain = strain;
          strainIds.push(strain.id);
        }
      }
    }
  }

  const tubesToCreate = [];
  // will deduplicate materials within this upload if two rows provide the same
  // plasmid and strain
  const keyedMaterialIds = {};
  const sequenceUpdates = [];
  const materialCreates = [];

  for (const row of csvData) {
    const {
      MATERIAL_NAME: matName,
      STRAIN_NAME: strainName,
      sequences = [],
      materialIds: dnaMaterialIds = [],
      existingMaterial,
      strainId,
      strain,
      onlyAdditives
    } = row;
    if (onlyAdditives) continue;

    const materialName =
      matName ||
      getMicrobialMaterialName(
        strainName,
        sequences.map(s => s.name)
      );
    row.materialName = materialName;
    const materialDedupKey = `${dnaMaterialIds
      .sort()
      .join("_")}:${strainId}:${materialName}`;
    let microbialMaterialId = existingMaterial
      ? existingMaterial.id
      : keyedMaterialIds[materialDedupKey];
    if (!microbialMaterialId) {
      const cid = shortid();
      const newMicrobialMaterial = {
        cid,
        name: materialName,
        materialTypeCode: "MICROBIAL",
        strainId
      };

      const strainPlasmids = strain.strainPlasmids;
      if (strainPlasmids.length > 0) {
        strainPlasmids.forEach(strainPlasmid => {
          if (
            !dnaMaterialIds.includes(strainPlasmid.polynucleotideMaterialId)
          ) {
            microbialMaterialPlasmidsToCreate.push({
              microbialMaterialId: `&${cid}`,
              polynucleotideMaterialId: strainPlasmid.polynucleotideMaterialId
            });
          }
        });
      }
      microbialMaterialId = `&${cid}`;
      newMaterials.push(newMicrobialMaterial);
      if (dnaMaterialIds.length) {
        dnaMaterialIds.forEach(dnaMaterialId => {
          microbialMaterialPlasmidsToCreate.push({
            microbialMaterialId: `&${cid}`,
            polynucleotideMaterialId: dnaMaterialId
          });
        });
      }
      keyedMaterialIds[materialDedupKey] = microbialMaterialId;
    }
    row.microbialMaterialId = microbialMaterialId;
  }

  for (const [index, row] of csvData.entries()) {
    const {
      PLATE_NAME: plateName,
      TUBE_NAME: tubeName,
      SAMPLE_NAME: sampleName,
      PLATE_BARCODE: plateBarcode,
      TOTAL_VOLUME: volume,
      TOTAL_VOLUMETRIC_UNIT: _volumetricUnit,
      MASS: mass,
      MASS_UNIT: _massUnit,
      TUBE_BARCODE: tubeBarcode,
      microbialMaterialId,
      sequences = [],
      sequenceIds = [],
      rowPosition,
      columnPosition,
      existingMaterial,
      rowKey,
      strain,
      materialName,
      onlyAdditives
    } = row;

    const tubeCid = shortid();
    let aliquot = null;

    if (!onlyAdditives) {
      if (!microbialMaterialId) {
        throw new Error(
          `Something went wrong and the material could not be found for row ${
            index + 1
          }.`
        );
      }

      if (!addedPropsForMaterial[microbialMaterialId]) {
        addedPropsForMaterial[microbialMaterialId] = true;
        getCsvRowExtProps({
          row,
          modelTypeCode: "MATERIAL",
          recordId: microbialMaterialId,
          record: existingMaterial,
          typeFilter: "material"
        });
      }

      if (!addedPropsForStrain[strain.id]) {
        addedPropsForStrain[strain.id] = true;

        getCsvRowExtProps({
          row,
          modelTypeCode: "STRAIN",
          recordId: strain.id,
          record: strain,
          typeFilter: "strain"
        });
      }

      if (!existingMaterial && sequenceIds) {
        sequenceIds.forEach((sequenceId, i) => {
          addedPropsForSequence[sequenceId] = true;

          getCsvRowExtProps({
            row,
            modelTypeCode: "DNA_SEQUENCE",
            typeFilter: "sequence",
            recordId: sequenceId,
            record: sequences[i]
          });
        });
      }

      if (!volume && !mass) {
        throw new Error(
          `Row ${index + 1} did not provide either a mass or a total volume.`
        );
      }
      if (volume && mass) {
        throw new Error(
          `Row ${
            index + 1
          } provided both mass and total volume. Please only include one.`
        );
      }
      let totalVolumetricUnit, massUnit;
      if (volume) {
        if (!_volumetricUnit) {
          throw new Error(`Row ${index + 1} is missing a volumetric unit.`);
        }
        totalVolumetricUnit =
          unitGlobals.volumetricUnits[cleanUnit(_volumetricUnit)];
        if (!totalVolumetricUnit) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the total volumetric unit ${_volumetricUnit} which doesn't exist.`
          );
        }
        const maxVolumeError = maxWellVolumeError({
          volume,
          unit: totalVolumetricUnit,
          containerArrayType,
          aliquotContainerType,
          index
        });
        if (maxVolumeError) {
          throw new Error(maxVolumeError);
        }
      }

      if (mass) {
        if (!_massUnit) {
          throw new Error(`Row ${index + 1} is missing a mass unit.`);
        }
        massUnit = unitGlobals.massUnits[cleanUnit(_massUnit)];
        if (!massUnit) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the mass unit ${massUnit} which doesn't exist.`
          );
        }
      }

      const aliquotCid = shortid();
      const sampleCid = shortid();
      aliquot = {
        cid: aliquotCid,
        aliquotType: "sample-aliquot",
        isDry: false,
        additives: row.additives,
        sample: {
          cid: sampleCid,
          name: sampleName || materialName,
          materialId: microbialMaterialId,
          sampleTypeCode: "REGISTERED_SAMPLE"
        }
      };

      if (volume) {
        aliquot.volume = volume;
        aliquot.volumetricUnitCode = totalVolumetricUnit.code;
      } else {
        aliquot.isDry = true;
        aliquot.mass = mass;
        aliquot.massUnitCode = massUnit.code;
      }
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT",
        typeFilter: "aliquot",
        recordId: `&${aliquotCid}`
      });
      getCsvRowExtProps({
        row,
        modelTypeCode: "SAMPLE",
        typeFilter: "sample",
        recordId: `&${sampleCid}`
      });

      if (isRack || isTubeUpload) {
        getCsvRowExtProps({
          row,
          modelTypeCode: "ALIQUOT_CONTAINER",
          typeFilter: "tube",
          recordId: `&${tubeCid}`
        });
      }
    }

    if (!isTubeUpload && !mapPlates[rowKey]) {
      const plateCid = shortid();
      getCsvRowExtProps({
        row,
        modelTypeCode: "CONTAINER_ARRAY",
        typeFilter: ["plate", "rack"],
        recordId: `&${plateCid}`
      });

      mapPlates[rowKey] = {
        cid: plateCid,
        name: plateName,
        containerArrayTypeId: containerArrayType.id,
        ...(plateBarcode && {
          barcode: {
            barcodeString: plateBarcode
          }
        }),
        aliquotContainers: []
      };
    }

    const additivesOrAliquot = aliquot
      ? {
          aliquot
        }
      : {
          additives: row.additives
        };

    if (isTubeUpload) {
      const barcodeField = {};
      if (isTubeUpload && !generateBarcodes) {
        barcodeField.barcode = {
          barcodeString: tubeBarcode
        };
      }
      tubesToCreate.push({
        cid: tubeCid,
        name: tubeName,
        ...barcodeField,
        aliquotContainerTypeCode: aliquotContainerType.code,
        ...additivesOrAliquot
      });
    } else {
      const barcodeField = {};
      if (!generateTubeBarcodes) {
        barcodeField.barcode = {
          barcodeString: tubeBarcode
        };
      }
      mapPlates[rowKey].aliquotContainers.push({
        cid: tubeCid,
        aliquotContainerTypeCode: isRack
          ? aliquotContainerType.code
          : containerArrayType.aliquotContainerType.code,
        rowPosition,
        columnPosition,
        ...barcodeField,
        ...additivesOrAliquot
      });
    }
  }
  const platesToCreate = Object.values(mapPlates);

  const extraFn = async () => {
    await safeUpsert("material", materialCreates);
    await safeUpsert("sequence", sequenceUpdates);
    await safeUpsert(
      "microbialMaterialPlasmid",
      microbialMaterialPlasmidsToCreate
    );
  };

  if (isTubeUpload) {
    return await finishTubeCreate({
      newTubes: tubesToCreate,
      extraFn
    });
  } else {
    return await finishPlateCreate({
      newPlates: platesToCreate,
      extraFn
    });
  }
}
