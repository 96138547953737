/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import withQuery from "../../../../../src-shared/withQuery";

import { compose } from "recompose";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { validateTransfers } from "../../../../utils";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { isEmpty } from "lodash";
import ReactionMapNameField from "../../../ReactMapNameField";

const ReviewWorklist = ({
  toolSchema,
  worklist,
  aliquotContainerTypes = [],
  worklistWarnings = [],
  Footer,
  reactionMapMessage,
  footerProps
}) => {
  let inner,
    valid = false;
  if (!worklist) {
    inner = <Callout intent="danger">No worklist found. Tool error.</Callout>;
  } else {
    let error = validateTransfers(worklist, aliquotContainerTypes);
    if (
      !error &&
      !worklist.worklistTransfers?.length &&
      !worklist.tubeTransfers?.length
    ) {
      error = "No transfers on worklist.";
    }
    const belowDeadvolume = error
      ?.trim()
      .split("\n")
      .every(e => e.includes("below dead volume"));
    valid = isEmpty(error) || belowDeadvolume;
    inner = (
      <>
        {reactionMapMessage && (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Name Reaction Map"
              helper={reactionMapMessage}
            />
            <div style={{ width: "40%" }}>
              <ReactionMapNameField toolSchema={toolSchema} />
            </div>
          </div>
        )}
        <ReviewWorklistSection toolSchema={toolSchema} />
        {error && (
          <Callout
            className="preserve-newline"
            intent={belowDeadvolume ? "warning" : "danger"}
          >
            {error.trimStart()}
          </Callout>
        )}
      </>
    );
  }

  return (
    <>
      {inner}
      {!!worklistWarnings.length && (
        <Callout intent={Intent.WARNING} className="preserve-newline">
          {worklistWarnings.join("\n")}
        </Callout>
      )}
      <Footer
        {...footerProps}
        nextButton={
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            disabled={!valid}
            loading={footerProps.submitting}
          >
            Generate Worklist
          </Button>
        }
      />
    </>
  );
};

export default compose(
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  }),
  stepFormValues("worklist", "worklistWarnings")
)(ReviewWorklist);
