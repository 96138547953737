/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { getFeatureTypes } from "@teselagen/sequence-utils";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, ReactSelectField } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { wrapDialog } from "@teselagen/ui";

class ChooseFeatureTypeDialog extends React.Component {
  onSubmit = async ({ type }) => {
    const { onSubmit, hideModal } = this.props;
    try {
      await onSubmit(type);
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error choosing feature type.");
    }
  };

  render() {
    const { hideModal, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <ReactSelectField
            name="type"
            label="Type:"
            isRequired
            defaultValue="misc_feature"
            options={getFeatureTypes()}
          />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" />
      </form>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Choose a Feature Type",
    style: { width: 350 }
  }),
  reduxForm({
    form: "chooseFeatureTypeDialog",
    enableReinitialize: true
  })
)(ChooseFeatureTypeDialog);
