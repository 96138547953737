/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  getDesign,
  getElementsInBin,
  isLayoutList,
  getSequenceStringOfElement,
  getBinsInCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";
import { cartesianProductOf } from "../../../tg-iso-design/utils/combinatorialUtils";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

export const getBinsToTakeCombinationsOf = tgCreateCachedSelector(
  state => state,
  (state, cardId) => cardId,
  (state, cardId) => {
    return getBinsInCard(state, cardId);
  }
)((state, cardId) => cardId);

const getListElementCombinations = (state, bins) => {
  const combinations = [];
  const design = getDesign(state);

  const binIdToIndexToElement = {};
  for (const bin of bins) {
    binIdToIndexToElement[bin.id] = {};
    for (const el of getElementsInBin(state, bin.id)) {
      binIdToIndexToElement[bin.id][el.index] = el;
    }
  }

  outer: for (let i = 0, ii = design.numRows; i < ii; i++) {
    const combination = [];
    for (const bin of bins) {
      const element = binIdToIndexToElement[bin.id][i];
      if (!element) continue outer;
      combination.push(element);
    }
    combinations.push(combination);
  }
  return combinations;
};

export default tgCreateCachedSelector(
  state => state,
  (state, cardId, includeSequences) => includeSequences,
  state => isLayoutList(state),
  getBinsToTakeCombinationsOf,
  (state, includeSequences, isListLayout, bins) => {
    const elementCombinations = isListLayout
      ? getListElementCombinations(state, bins)
      : cartesianProductOf(bins.map(b => getElementsInBin(state, b.id)));

    return elementCombinations.map((comb, i) => ({
      id: comb.map(el => el.id).join(","),
      index: i,
      elements: comb,
      sequence:
        includeSequences &&
        comb
          .map((el, j) => {
            const elStr = getSequenceStringOfElement(state, el.id);
            return bins[j].direction
              ? elStr
              : getReverseComplementSequenceString(elStr);
          })
          .join("")
    }));
  }
)((state, cardId, includeSequences) => `${cardId}:${!!includeSequences}`);
