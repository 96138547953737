/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import GenericSelect from "../../../../src-shared/GenericSelect";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class UpdateOrderDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch } = this.props;
    const { name, orderStatus = {} } = values;
    delete values.orderStatus;
    const orderToUpdate = {
      ...values,
      name: name,
      orderStatusCode: orderStatus.code || null
    };
    try {
      await safeUpsert(
        ["vendorOrder", "id name orderStatusCode"],
        orderToUpdate
      );
      await refetch();
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Error updating order");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Order Name" isRequired />
          <GenericSelect
            {...{
              name: "orderStatus",
              isRequired: true,
              idAs: "code",
              schema: [{ path: "name" }],
              asReactSelect: true,
              fragment: ["orderStatus", "code name"],
              label: "Order Status"
            }}
          />
        </div>
        <DialogFooter hideModal={hideModal} loading={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Update Order" }),
  reduxForm({
    form: "UpdateOrderDialog"
  })
)(UpdateOrderDialog);
