/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Button, Classes, Intent } from "@blueprintjs/core";

/**
 * @param {object} props
 * @param {FieldArrayFieldsProps} props.fields
 * @param {string=} props.label
 * @param {string=} props.text
 * @param {Function=} props.add
 * @param {boolean=} props.noMargin
 * @returns
 */
export default function AddButton({
  fields,
  label,
  text,
  add: _add,
  noMargin,
  ...rest
}) {
  function add() {
    fields.push({});
  }

  return (
    <Button
      style={{ marginTop: noMargin ? 0 : 10 }}
      icon="add"
      className={Classes.MINIMAL}
      intent={Intent.SUCCESS}
      onClick={_add || add}
      text={label || text}
      {...rest}
    />
  );
}
