/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class CreateAminoAcidSequenceDialog extends Component {
  onSubmit = async values => {
    const { refetch = () => {}, hideModal } = this.props;
    try {
      const { id, name } = values;

      const aminoAcidSequence = {
        id,
        name
      };

      await safeUpsert("aminoAcidSequence", aminoAcidSequence);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating amino acid sequence.");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" isRequired />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Create Amino Acid Sequence"
  }),
  reduxForm({
    form: "createAminoAcidSequenceDialog"
  })
)(CreateAminoAcidSequenceDialog);
