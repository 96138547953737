/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { InputField } from "@teselagen/ui";
import SortableListField from "../SortableListField";

function ListItemComp({ field, index }: { field: string; index: number }) {
  return (
    <>
      <div style={{ margin: 10 }}>Position {index + 1}</div>
      <InputField name={`${field}.name`} label="Name" isRequired />
      <div className="tg-flex-separator" />
      <InputField name={`${field}.label`} label="Label" />
      <div className="tg-flex-separator" />
    </>
  );
}

function PositionRows({
  name,
  addButtonText = "Add Default Position"
}: {
  name: string;
  addButtonText?: string;
}) {
  return (
    <SortableListField
      name={name}
      ListItemComp={ListItemComp}
      addButtonText={addButtonText}
    />
  );
}

export default PositionRows;
