/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { DataTable } from "@teselagen/ui";
import { compose } from "recompose";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { getReactionEntitiesAndSchema } from "../utils";

function ReviewReactions(props) {
  const { addReagentsForReaction = {}, reactionMap, allReagents } = props;

  const {
    schema: reactionSchema,
    entities: reactionEntities
  } = getReactionEntitiesAndSchema(
    reactionMap,
    addReagentsForReaction,
    allReagents
  );

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Review Reactions"
          helper="Review updated reaction map, the reactions should include the reagents assigned in the previous step."
          width="100%"
        />
        <DataTable
          formName="reviewReagentReactionMapTable"
          isSimple
          schema={reactionSchema}
          entities={reactionEntities}
        />
      </div>
    </React.Fragment>
  );
}

export default compose(
  stepFormValues("reactionMap", "addReagentsForReaction", "allReagents")
)(ReviewReactions);
