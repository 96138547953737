// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.WidgetCardStyles-module__widgetCard-2NGkY {\n  width: 100%;\n  height: 100%;\n  page-break-inside: avoid;\n  break-inside: avoid;\n  /* overflow-y: scroll; */\n  /* Figure out how to set the color-scheme to dark mode. This\n  enhances the browsers dark mode styles for scroll bars and other native componentes.\n  color-scheme: dark;\n   */\n}\n\n/*\n.shortHeightStandardWidthWidgetCard {\n  grid-area: span 1 / span 1;\n}\n\n.standardHeightStandardWidthWidgetCard {\n  grid-area: span 2 / span 1;\n}\n\n.tallHeightStandardWidthWidgetCard {\n  grid-area: span 3 / span 1;\n}\n\n.standardHeightMediumWidthWidgetCard {\n  grid-area: span 2 / span 2;\n}\n\n.tallHeightMediumWidthWidgetCard {\n  grid-area: span 3 / span 2;\n}\n\n.tallHeightTallWidthWidgetCard {\n  grid-area: span 3 / span 3;\n}\n\n*/\n\n.WidgetCardStyles-module__widgetContainer-3QBC8 {\n  padding-top: 10px;\n  height: 90%;\n  /* overflow: auto; */\n}\n\n.WidgetCardStyles-module__widgetHeader-vTNqJ {\n  margin-bottom: 10px;\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.WidgetCardStyles-module__widgetHeader-vTNqJ h4 {\n  margin-bottom: 0px;\n  font-size: 1.2em;\n  font-weight: bold;\n}\n\n.WidgetCardStyles-module__widgetCard-2NGkY h5 {\n  font-weight: bold;\n}\n\n/* PLOTS & CHARTS STYLES */\n.WidgetCardStyles-module__axis-12XYx path,\n.WidgetCardStyles-module__axis-12XYx line {\n  fill: none;\n  stroke: #5c7080;\n  shape-rendering: crispEdges;\n}\n\n.WidgetCardStyles-module__label-_mGw5 {\n  color: #182026;\n  fill: #738694;\n}\n", ""]);
// Exports
exports.locals = {
	"widgetCard": "WidgetCardStyles-module__widgetCard-2NGkY",
	"widgetContainer": "WidgetCardStyles-module__widgetContainer-3QBC8",
	"widgetHeader": "WidgetCardStyles-module__widgetHeader-vTNqJ",
	"axis": "WidgetCardStyles-module__axis-12XYx",
	"label": "WidgetCardStyles-module__label-_mGw5"
};
module.exports = exports;
