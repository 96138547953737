/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Collapse, Portal, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import ReactMarkDown from "react-markdown";
import remarkGfm from "remark-gfm";

export default observer(
  class FAQ extends Component {
    renderImage() {
      const { data } = this.props;
      if (data.img) {
        if (data.fullScreen) {
          return (
            <Portal className="fullscreen-portal">
              <div className="portal-background" />
              <img
                className="fullscreen-image"
                alt={data.title}
                src={data.img}
              />
              <Icon
                icon="cross"
                className="fullscreen-icon"
                iconSize={50}
                onClick={data.handleFullScreen}
              />
            </Portal>
          );
        } else {
          return (
            <div className="FAQ-img">
              <figure>
                <img
                  alt={data.title}
                  src={data.img}
                  style={{ width: "100%" }}
                />
                <a onClick={data.handleFullScreen}>
                  <Icon icon="fullscreen" />
                </a>
              </figure>
            </div>
          );
        }
      }
    }

    render() {
      const { data } = this.props;
      return (
        <div style={{ width: "100%", height: "100%", margin: 0 }}>
          <h5>
            <a onClick={() => data.handleOpen()}>{data.title}</a>
          </h5>
          <Collapse isOpen={data.isOpen}>
            <div style={{ textAlign: "justify" }}>
              <ReactMarkDown
                remarkPlugins={[remarkGfm]}
                children={data.msg}
                disallowedTypes={["linkReference", "delete", "html"]}
              />
            </div>
            {this.renderImage()}
          </Collapse>
        </div>
      );
    }
  }
);
