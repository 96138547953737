/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { DataTable } from "@teselagen/ui";
import StepFormSection from "../../../../../src-shared/StepFormSection";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import RecordExtendedPropertiesTable from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/RecordExtendedPropertiesTable";

class ExtendedProperties extends Component {
  render() {
    const {
      existingSourceExtendedProperties: {
        containerArray: extendedPropertiesOnPlates,
        aliquot: extendedPropertiesOnAliquots
      },
      aliquotExtPropSelectedEntityIdMap = {},
      containerArrayExtPropSelectedEntityIdMap = {},
      allWorklistExtendedProperties: {
        sourceContainerArrayExtendedPropertiesRemoved = [],
        sourceContainerArrayExtendedPropertiesAdded = [],
        sourceAliquotExtendedPropertiesRemoved = [],
        sourceAliquotExtendedPropertiesAdded = [],
        destinationContainerArrayExtendedPropertiesRemoved = [],
        destinationContainerArrayExtendedPropertiesAdded = [],
        destinationAliquotExtendedPropertiesRemoved = [],
        destinationAliquotExtendedPropertiesAdded = []
      } = {}
    } = this.props;
    const existingPropertiesSharedTableProps = {
      schema: ["name"],
      keepDirtyOnReinitialize: true,
      updateUnregisteredFields: true,
      enableReinitialize: true,
      destroyOnUnmount: false,
      isSimple: true,
      withCheckboxes: true,
      compact: true
    };

    const worklistExtProperties = !!(
      sourceContainerArrayExtendedPropertiesRemoved.length ||
      sourceContainerArrayExtendedPropertiesAdded.length ||
      sourceAliquotExtendedPropertiesRemoved.length ||
      sourceAliquotExtendedPropertiesAdded.length ||
      destinationContainerArrayExtendedPropertiesRemoved.length ||
      destinationContainerArrayExtendedPropertiesAdded.length ||
      destinationAliquotExtendedPropertiesRemoved.length ||
      destinationAliquotExtendedPropertiesAdded.length
    );

    const noPropsToTransfer =
      !extendedPropertiesOnPlates.length &&
      !extendedPropertiesOnAliquots.length;

    return (
      <div>
        <StepFormSection column>
          <HeaderWithHelper
            header="Transfer Extended Properties"
            helper="These extended properties exist on the source plates and aliquots. Choose properties you would like to transfer."
          />
          {noPropsToTransfer && (
            <p>
              There are no extended properties on the source plates and
              aliquots.
            </p>
          )}
          <div
            className="tg-flex justify-space-between"
            style={{ marginBottom: 20 }}
          >
            {!!extendedPropertiesOnPlates.length && (
              <div style={{ width: "45%", height: "100%" }}>
                <h6>Plates</h6>
                <DataTable
                  {...existingPropertiesSharedTableProps}
                  entities={extendedPropertiesOnPlates}
                  initialValues={{
                    reduxFormSelectedEntityIdMap: containerArrayExtPropSelectedEntityIdMap
                  }}
                  formName="existingSourceContainerArrayExtendedPropertiesTable"
                />
              </div>
            )}
            <div className="tg-flex-separator" />
            {!!extendedPropertiesOnAliquots.length && (
              <div style={{ width: "45%", height: "100%" }}>
                <h6>Aliquots</h6>
                <DataTable
                  {...existingPropertiesSharedTableProps}
                  entities={extendedPropertiesOnAliquots}
                  initialValues={{
                    reduxFormSelectedEntityIdMap: aliquotExtPropSelectedEntityIdMap
                  }}
                  formName="existingSourceAliquotExtendedPropertiesTable"
                />
              </div>
            )}
          </div>
        </StepFormSection>
        {worklistExtProperties && (
          <StepFormSection column>
            <HeaderWithHelper
              header="Worklist Extended Property Support"
              helper="The worklist is automatically adding and removing the following extended properties (these will override the properties chosen above)."
            />
            <div
              className="tg-flex justify-space-between"
              style={{ marginBottom: 20 }}
            >
              <SimpleDisplayPropertyTable
                formName="sourcePlatePropsToRemove"
                entities={sourceContainerArrayExtendedPropertiesRemoved}
                title="Source Plate Properties to Remove"
              />
              <div className="tg-flex-separator" />
              <RecordPropertiesTableWrapper
                formName="sourcePlatePropsToAdd"
                title="Source Plate Properties to Add"
                entities={sourceContainerArrayExtendedPropertiesAdded}
              />
            </div>
            <div
              className="tg-flex justify-space-between"
              style={{ marginBottom: 20 }}
            >
              <SimpleDisplayPropertyTable
                entities={sourceAliquotExtendedPropertiesRemoved}
                formName="sourceAliquotPropsToRemove"
                title="Source Aliquot Properties to Remove"
              />
              <div className="tg-flex-separator" />
              <RecordPropertiesTableWrapper
                formName="sourceAliquotPropsToAdd"
                title="Source Aliquot Properties to Add"
                entities={sourceAliquotExtendedPropertiesAdded}
              />
            </div>
            <div
              className="tg-flex justify-space-between"
              style={{ marginBottom: 20 }}
            >
              <SimpleDisplayPropertyTable
                formName="destinationPlatePropsToRemove"
                entities={destinationContainerArrayExtendedPropertiesRemoved}
                title="Destination Plate Properties to Remove"
              />
              <div className="tg-flex-separator" />
              <RecordPropertiesTableWrapper
                formName="destinationPlatePropsToAdd"
                title="Destination Plate Properties to Add"
                entities={destinationContainerArrayExtendedPropertiesAdded}
              />
            </div>
            <div
              className="tg-flex justify-space-between"
              style={{ marginBottom: 20 }}
            >
              <SimpleDisplayPropertyTable
                entities={destinationAliquotExtendedPropertiesRemoved}
                formName="destinationAliquotPropsToRemove"
                title="Destination Aliquot Properties to Remove"
              />
              <div className="tg-flex-separator" />
              <RecordPropertiesTableWrapper
                formName="destinationAliquotPropsToAdd"
                title="Destination Aliquot Properties to Add"
                entities={destinationAliquotExtendedPropertiesAdded}
              />
            </div>
          </StepFormSection>
        )}
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    "existingSourceExtendedProperties",
    "aliquotExtPropSelectedEntityIdMap",
    "containerArrayExtPropSelectedEntityIdMap",
    "allWorklistExtendedProperties"
  )
)(ExtendedProperties);

function SimpleDisplayPropertyTable({ entities, formName, title }) {
  if (!entities.length) return null;
  return (
    <div style={{ width: "45%", height: "100%" }}>
      <h6>{title}</h6>
      <DataTable
        entities={entities}
        schema={["name"]}
        maxHeight={300}
        destroyOnUnmount={false}
        isSimple
        compact
        formName={formName}
      />
    </div>
  );
}

function RecordPropertiesTableWrapper({ entities, formName, title }) {
  if (!entities.length) return null;
  return (
    <div style={{ width: "45%", height: "100%" }}>
      <h6>{title}</h6>
      <RecordExtendedPropertiesTable
        maxHeight={300}
        isSimple
        formName={formName}
        entities={entities}
        noEdit
        noDelete
      />
    </div>
  );
}
