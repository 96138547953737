/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import recordViewEnhancer from "../../../src-shared/recordViewEnhancer";
import gql from "graphql-tag";

class PartToSequenceRedirect extends Component {
  render() {
    const { part } = this.props;
    if (part && part.sequenceId) {
      const { overlapsSelf } = part;
      return (
        <Redirect
          to={`/sequences/${part.sequenceId}?bpRange=${part.start}-${part.end}${
            overlapsSelf ? `&overlapsSelf=true` : ""
          }`}
        />
      );
    } else {
      return null;
    }
  }
}

const partToSequenceFragment = gql`
  fragment partToSequenceFragment on part {
    id
    sequenceId
    start
    end
    overlapsSelf
  }
`;

export default recordViewEnhancer(partToSequenceFragment)(
  PartToSequenceRedirect
);
