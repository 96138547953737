/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import {
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Icon
} from "@blueprintjs/core";
import { compose } from "recompose";
import { get } from "lodash";
import classNames from "classnames";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import { safeUpsert } from "../../../apolloMethods";
import { TEST_METADATA, TEST_LABELS } from "../../../utils/experimentData";

const schema = {
  model: "dataMappingActions",
  fields: [
    { path: "col", displayName: "Column" },
    { path: "class", displayName: TEST_LABELS.class },
    { path: "subClass", displayName: TEST_LABELS.subClass }
  ]
};

function formatMappingPresetHeaders(headerState) {
  const [names, classes, subclasses] = headerState;
  const parsedHeaders = [];
  names.forEach(headerName => {
    parsedHeaders.push({
      name: headerName,
      class: get(classes[headerName], "name"),
      className: get(classes[headerName], "label"),
      subClass: get(subclasses[headerName], "id"),
      subClassName: get(subclasses[headerName], "name")
    });
  });
  return parsedHeaders;
}

const NewMappingPreset = props => {
  const [submitting, setSubmitting] = useState(false);
  const [isValidPreset, setIsValidPreset] = useState(true);
  const [mappingPresetName, setMappingPresetName] = useState("");

  async function handleSubmit() {
    if (!mappingPresetName)
      return window.toastr.error("Mapper must have a name.");
    setSubmitting(true);
    try {
      const parsedHeaders = formatMappingPresetHeaders(props.headerStates);
      await safeUpsert("mappingPreset", {
        name: mappingPresetName,
        header: parsedHeaders
      });
      window.toastr.success(`Mapper '${mappingPresetName}' saved.`);
    } catch (error) {
      console.error(error);
    }
    props.hideModal();
    setSubmitting(false);
  }

  function renderCell(value) {
    if (value) {
      return (
        <p style={{ marginTop: "5px", display: "flex" }}>
          {value}
          <Icon
            icon="small-tick"
            style={{ float: "right", marginLeft: 5 }}
            intent={Intent.SUCCESS}
          />
        </p>
      );
    } else {
      if (isValidPreset) setIsValidPreset(false);
      return (
        <p style={{ marginTop: "5px", display: "flex" }}>
          unselected
          <Icon
            icon="error"
            style={{ float: "right", marginLeft: 5 }}
            intent={Intent.DANGER}
          />
        </p>
      );
    }
  }

  const {
    hideModal,
    headerStates: [names, classes = {}, subclasses = {}]
  } = props;
  return (
    <React.Fragment>
      <div className={classNames(Classes.DIALOG_BODY)}>
        <div>
          <FormGroup
            disabled={false}
            intent={Intent.PRIMARY}
            label="Mapper name"
            labelInfo="(required)"
          >
            <InputGroup
              placeholder="Name your mapper"
              value={mappingPresetName}
              onChange={e => {
                setMappingPresetName(e.target.value);
              }}
            />
          </FormGroup>
          <DataTable
            formName="mappingPreset"
            cellRenderer={{
              class: row => renderCell(row),
              subClass: row => renderCell(row)
            }}
            schema={schema}
            noHeader
            noSelect
            compact
            entities={names.map(headerName => ({
              col: headerName,
              class: get(
                TEST_METADATA[get(classes[headerName], "name")],
                "label"
              ),
              subClass: get(subclasses[headerName], "name")
            }))}
            defaultRowHeight={30}
          />
        </div>
      </div>
      <DialogFooter
        submitting={submitting}
        disabled={!isValidPreset}
        hideModal={hideModal}
        onClick={handleSubmit}
      />
    </React.Fragment>
  );
};

export default compose(wrapDialog({ style: { width: 800 } }))(NewMappingPreset);
