/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { SubmissionError } from "redux-form";
import { compose } from "recompose";
import { get } from "lodash";
import { withSelectedEntities } from "@teselagen/ui";
import StepForm from "../../../../src-shared/StepForm";
import ChooseAssemblyPiecesAndConstructs from "./Steps/ChooseAssemblyPiecesAndConstructs";
import { constructsTableName } from "./constants";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";
import "./style.css";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import constructSelectionJ5ReportFragment from "../../../graphql/fragments/constructSelectionJ5ReportFragment";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getSequence } from "../../../../../tg-iso-shared/src/utils/getSequence";

class ConstructSelectionTool extends React.Component {
  onSubmit = async values => {
    try {
      const { selectedBuildableConstructs = [] } = this.props;
      const { j5Reports = [] } = values;
      const {
        constructsDataSetName,
        constructsListName,
        assemblyPieceListName,
        assemblyPieceOrderListName,
        assemblyReactionMapName
      } = values;
      const constructIds = selectedBuildableConstructs.map(c => c.id);
      const assemblyPieceIds = [];
      const assemblyPiecesToOrder = [];
      const assemblyPiecesToOrderIds = [];
      const reactionMap = {
        name: assemblyReactionMapName,
        reactionTypeCode: "ASSEMBLY_REACTION",
        reactions: []
      };
      j5Reports.forEach(report => {
        report.j5RunConstructs.forEach(construct => {
          if (constructIds.includes(construct.id)) {
            const inputMaterials = [];
            construct.j5ConstructAssemblyPieces.forEach(
              ({ assemblyPieceId, assemblyPiece }) => {
                let material;
                if (assemblyPiece.type === "Digest Linearized") {
                  material = get(
                    assemblyPiece,
                    "j5AssemblyPieceParts[0].j5InputPart.j5InputSequence.sequence.polynucleotideMaterial"
                  );
                } else {
                  material = assemblyPiece.sequence.polynucleotideMaterial;
                }
                inputMaterials.push(material);
                if (
                  get(assemblyPiece, "assemblyPieceView.containerCount") ===
                    0 &&
                  !assemblyPiecesToOrderIds.includes(assemblyPiece.id)
                ) {
                  assemblyPiecesToOrderIds.push(assemblyPiece.id);
                  assemblyPiecesToOrder.push(assemblyPiece);
                }
                if (!assemblyPieceIds.includes(assemblyPieceId)) {
                  assemblyPieceIds.push(assemblyPieceId);
                }
              }
            );
            reactionMap.reactions.push({
              name: `Reaction for ${construct.name}`,
              ...getReactionInputsAndOutputs({
                inputMaterials,
                outputMaterialId: construct.sequence.polynucleotideMaterialId
              })
            });
          }
        });
      });

      const dataSet = {
        name: constructsDataSetName,
        type: "J5_CONSTRUCT_SELECT_FORM",
        status: "IN_PROGRESS",
        j5ReportDataSets: j5Reports.map(report => {
          return {
            j5ReportId: report.id
          };
        })
      };
      const [constructsDataSet] = await safeUpsert("dataSet", dataSet);
      const dataTables = [
        {
          name: constructsListName,
          dataSetId: constructsDataSet.id,
          dataTableTypeCode: "J5_CONSTRUCT_SELECTION",
          dataRows: constructIds.map(constructId => {
            return {
              dataRowJ5Items: [
                {
                  j5Item: {
                    j5RunConstructId: constructId,
                    j5ItemTypeCode: "J5_RUN_CONSTRUCT"
                  }
                }
              ]
            };
          })
        },
        {
          name: assemblyPieceListName,
          dataSetId: constructsDataSet.id,
          dataTableTypeCode: "J5_ASSEMBLY_PIECE_LIST",
          dataRows: assemblyPieceIds.map(assemblyPieceId => {
            return {
              dataRowJ5Items: [
                {
                  j5Item: {
                    j5AssemblyPieceId: assemblyPieceId,
                    j5ItemTypeCode: "J5_ASSEMBLY_PIECE"
                  }
                }
              ]
            };
          })
        }
      ];
      if (assemblyPiecesToOrder.length > 0) {
        dataTables.push({
          name: assemblyPieceOrderListName,
          dataSetId: constructsDataSet.id,
          dataTableTypeCode: "SEQUENCE_ORDER",
          dataRows: assemblyPiecesToOrder.map(assemblyPiece => {
            return {
              rowValues: {
                sequenceId: get(assemblyPiece, "sequence.id"),
                sequenceName: get(assemblyPiece, "sequence.name"),
                type: get(assemblyPiece, "sequence.sequenceTypeCode"),
                sequence: getSequence(get(assemblyPiece, "sequence"))
              }
            };
          })
        });
      }
      const [
        constructsList,
        assemblyPieceList,
        assemblyPieceOrderList
      ] = await safeUpsert("dataTable", dataTables);
      const [assemblyReactionMap] = await safeUpsert(
        "reactionMap",
        reactionMap
      );
      return {
        constructsDataSet,
        constructsList,
        assemblyPieceList,
        assemblyPieceOrderList,
        assemblyReactionMap
      };
    } catch (error) {
      console.error("err:", error);
      throw new SubmissionError({
        _error: "Error creating construct batch."
      });
    }
  };

  render() {
    const { toolIntegrationProps, toolSchema, initialValues } = this.props;
    const steps = [
      {
        title: "Choose Assembly Pieces and Constructs",
        Component: ChooseAssemblyPiecesAndConstructs,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        toolSchema={toolSchema}
        steps={steps}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(constructSelectionJ5ReportFragment),
  withSelectedEntities({
    formName: constructsTableName,
    name: "selectedBuildableConstructs"
  })
)(ConstructSelectionTool);
