/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Button, Classes, Intent } from "@blueprintjs/core";

/**
 *
 * @param {object} props
 * @param {FieldArrayFieldsProps} props.fields
 * @param {number} props.index
 * @param {Function=} props.remove
 * @param {object=} props.style
 * @returns
 */
export default function RemoveButton({
  fields,
  index,
  remove: _remove,
  style
}) {
  function remove() {
    fields.remove(index);
  }
  return (
    <Button
      style={{ maxHeight: 30, ...style }}
      intent={Intent.DANGER}
      className={Classes.MINIMAL}
      onClick={_remove || remove}
      icon="trash"
    />
  );
}
