/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { Dialog, Tooltip, Intent, Button } from "@blueprintjs/core";
import { connect } from "react-redux";
import { max, times, get, sum } from "lodash";

import { getSelectedCardId } from "../../../../src-shared/selectors/designViewSelectors";
import {
  getLevelOfCard,
  getFullEliminatedCombinationGroupsOfCard,
  getElementToNumberOfItemsMap
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import store from "../../../../src-shared/redux/store";

import { elementsToKey } from "../../../../../tg-iso-design/redux/sagas/submitDesignForAssembly/removeAvailableConstructs";
import actions from "../../../../src-shared/redux/actions";

import "./style.css";
import { prod } from "../../../utils";
import { cartesianProductOf } from "../../../../../tg-iso-design/utils/combinatorialUtils";

const mapStateToProps = state => {
  const cardId = getSelectedCardId(state);

  return {
    cardId,
    level: getLevelOfCard(state, cardId),
    elementIdToNumberOfItems: getElementToNumberOfItemsMap(state)
  };
};

const mapDispatchToProps = {
  removeEliminatedCombination: actions.design.removeEliminatedCombination
};

class ViewEliminatedCombinationsDialog extends React.Component {
  state = {
    eliminatedComboGroups: []
  };

  /**
   * Given an array of elements representing a combination,
   * get the number of peices of DNA that the combination represents.
   * @param {Array<Element>} elements
   */
  getSizeOfCombination(elements) {
    const { elementIdToNumberOfItems } = this.props;
    return prod(elements.map(el => elementIdToNumberOfItems[el.id]));
  }

  updateEliminatedComboGroups = () => {
    const state = store.getState();

    const cardId = getSelectedCardId(state);
    const eliminatedComboGroups = getFullEliminatedCombinationGroupsOfCard(
      state,
      cardId
    );

    const eliminatedConstructKeys = {};
    const _eliminatedComboGroups = eliminatedComboGroups.map(ecg => {
      const { elementGroups } = ecg;
      const elementCombinations = cartesianProductOf(elementGroups);
      const potentialEliminations = sum(
        elementCombinations.map(elements => this.getSizeOfCombination(elements))
      );
      let actualEliminations = potentialEliminations;
      for (const elements of elementCombinations) {
        const key = elementsToKey(elements);
        if (eliminatedConstructKeys[key]) {
          actualEliminations -= this.getSizeOfCombination(elements);
        } else {
          eliminatedConstructKeys[key] = true;
        }
      }
      return {
        ...ecg,
        potentialEliminations,
        actualEliminations
      };
    });

    this.setState({
      eliminatedComboGroups: _eliminatedComboGroups
    });
  };

  UNSAFE_componentWillMount() {
    this.updateEliminatedComboGroups();
  }

  handleTrashClick = id => () => {
    this.props.removeEliminatedCombination({ id });
    this.updateEliminatedComboGroups();
  };

  renderEliminatedCombinationGroup = ecg => {
    const { elementGroups, potentialEliminations, actualEliminations } = ecg;

    const numRows = max(elementGroups.map(eg => eg.length));

    return (
      <tr key={ecg.id}>
        <td>
          <Button
            minimal
            icon="trash"
            intent={Intent.DANGER}
            onClick={this.handleTrashClick(ecg.id)}
          />
        </td>
        <td>
          <table>
            <tbody>
              {times(numRows, i => (
                <tr key={i}>
                  {elementGroups.map((eg, j) => (
                    <td key={j}>{get(elementGroups[j][i], "name", "")}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </td>
        <td>{potentialEliminations}</td>
        <td>{actualEliminations}</td>
      </tr>
    );
  };

  render() {
    const { hideModal, level } = this.props;
    const { eliminatedComboGroups } = this.state;

    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={"Eliminated Combinations for Card " + level}
        // style={{ width: 550 }}
        className="view-eliminated-combinations-dialog"
      >
        <div className="bp3-dialog-body">
          <table>
            <thead>
              <tr>
                <th />
                <th>Elimination Group</th>
                <th>Potential Number of Eliminations</th>
                <th>
                  <Tooltip content="This take into account previous eliminations, so the actual number can be less than the potential number.">
                    Actual Eliminations
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {eliminatedComboGroups.map(ecg =>
                this.renderEliminatedCombinationGroup(ecg)
              )}
            </tbody>
          </table>
        </div>
      </Dialog>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ViewEliminatedCombinationsDialog
);
