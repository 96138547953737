/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default function getUnit(units, code) {
  if (!units) {
    // eslint-disable-next-line no-debugger
    debugger;
    throw new Error("Bad missing unit");
  }
  if (Array.isArray(units)) {
    return units.find(unit => unit.code === code);
  } else {
    return units[code];
  }
}
