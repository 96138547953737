/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { observer } from "mobx-react";
import { Icon } from "@blueprintjs/core";

const extraContent = ({ data }) => {
  const newUrl = window.location.href.replace(
    /(\/client\/).*/,
    `$1${data.path}`
  );
  return (
    <div>
      <a
        download={data.download}
        href={newUrl}
        target={data.newTab ? "_blank" : ""}
        rel="noreferrer"
      >
        <Icon
          style={{ marginRight: "10px" }}
          icon={data.icon ? data.icon : "dot"}
        />
        {data.text}
      </a>
    </div>
  );
};

export default observer(extraContent);
