import {
  isJunctionValidated,
  getInputCardIdOfJunction,
  getOverhangTypeOfJunction,
  getBoundaryBinIdOfJunction,
  getRegexStringOfJunction,
  getRecognitionRegexStringOfJunction,
  getCardIdOfJunction
} from "../../selectors/junctionSelectors";
import {
  isLeafCard,
  getNeighborBinId,
  getLevelOfCard
} from "../../selectors/designStateSelectors";
import { uniq } from "lodash";
import validateBinAgainstRegex from "./validateBinAgainstRegex";
import { ChangeSetsHelper } from ".";
import validateFlankingSequenceAgainstRegex from "./validateFlankingSequenceAgainstRegex";
import assignMapsToArrays from "../assignMapsToArrays";

/**
 * Get the invalidity message to display for a given element if it fails
 * the validation from a junction on non-flanking sequence.
 * @param {Object} state
 * @param {Object} junction
 */
function getNonFlankingInvalidityMessage(state, junction) {
  const fivePrimeCardId = getCardIdOfJunction(junction, true);
  const threePrimeCardId = getCardIdOfJunction(junction, false);
  return `Fails validation required by junction between cards ${getLevelOfCard(
    state,
    fivePrimeCardId
  )} and ${getLevelOfCard(state, threePrimeCardId)}.`;
}

/**
 * Get the invalidity message to display for a given element if it fails
 * the validation from a junction on flanking sequence.
 *
 * @param {Object} state
 * @param {Object} junction
 * @param {boolean} fivePrimeCard Whether we are validating the bins on the five prime card.
 */
function getFlankingInvalidityMessage(state, junction, fivePrimeCard) {
  const fivePrimeCardId = getCardIdOfJunction(junction, true);
  const threePrimeCardId = getCardIdOfJunction(junction, false);
  const cardId = fivePrimeCard ? fivePrimeCardId : threePrimeCardId;
  return `Flanking sequence on card ${getLevelOfCard(
    state,
    cardId
  )} fails validation required by junction between cards ${getLevelOfCard(
    state,
    fivePrimeCardId
  )} and ${getLevelOfCard(state, threePrimeCardId)}.`;
}

/**
 * Given a set, create an equivalent object where each key in the set will map to
 * an array containing the message as its only item.
 * @param {Object} set
 * @param {string} msg
 */
function setToArrayMap(set, msg) {
  const map = {};
  for (const key of Object.keys(set)) {
    map[key] = [msg];
  }
  return map;
}

/**
 * Get the set of all of the invalid element ids on one of the cards participating
 * in the junction.
 * @param {Object} state The full state.
 * @param {Object} junction
 * @param {boolean} fivePrimeCard Whether to validate the bins on the five prime card.
 */
function getInvalidElementIdsOfTypeIIsJunction(state, junction, fivePrimeCard) {
  const overhangType = getOverhangTypeOfJunction(junction, fivePrimeCard);
  const cardId = getInputCardIdOfJunction(state, junction.id, fivePrimeCard);
  const isLeaf = isLeafCard(state, cardId);

  const boundaryBinId = getBoundaryBinIdOfJunction(junction, fivePrimeCard);
  const neighborBinId = getNeighborBinId(
    state,
    cardId,
    boundaryBinId,
    fivePrimeCard,
    isLeaf
  );

  const overhangRegex = getRegexStringOfJunction(junction);
  const recognitionRegex = getRecognitionRegexStringOfJunction(
    state,
    junction.id,
    !fivePrimeCard
  );

  // Here are some TODO warnings.
  if (!isLeaf && !neighborBinId) {
    console.error(
      "TODO: No sequence for recognition site. This is very invalid. Need to find some way to convey this in the state and UI."
    );
  }

  const nonFlankingMsg = getNonFlankingInvalidityMessage(state, junction);
  const flankingMsg = getFlankingInvalidityMessage(
    state,
    junction,
    fivePrimeCard
  );

  if (overhangType === "INSERT" || overhangType === "ADAPTER") {
    const invalidOverhangElementIds = validateBinAgainstRegex(
      state,
      boundaryBinId,
      overhangRegex,
      !fivePrimeCard,
      overhangType === "ADAPTER"
    );
    const invalidRecognitionElementIds = neighborBinId
      ? validateBinAgainstRegex(
          state,
          neighborBinId,
          recognitionRegex,
          fivePrimeCard,
          false
        )
      : validateFlankingSequenceAgainstRegex(
          state,
          cardId,
          recognitionRegex,
          !fivePrimeCard
        );
    return assignMapsToArrays(
      {},
      setToArrayMap(invalidOverhangElementIds, nonFlankingMsg),
      setToArrayMap(
        invalidRecognitionElementIds,
        neighborBinId ? nonFlankingMsg : flankingMsg
      )
    );
  } else if (overhangType === "FLANKING_SEQUENCE") {
    const regex = fivePrimeCard
      ? overhangRegex + recognitionRegex
      : recognitionRegex + overhangRegex;

    const invalidElementIds = neighborBinId
      ? validateBinAgainstRegex(
          state,
          neighborBinId,
          regex,
          fivePrimeCard,
          false
        )
      : validateFlankingSequenceAgainstRegex(
          state,
          cardId,
          regex,
          !fivePrimeCard
        );

    return setToArrayMap(
      invalidElementIds,
      neighborBinId ? nonFlankingMsg : flankingMsg
    );
  } else {
    console.error(`Invalid overhang type for type IIs enzyme: ${overhangType}`);
    return {};
  }
}

/**
 * Given the design state, see which elements are valid and invalid based on
 * the validation from the junctions. The `invalidityMessage` fields on elements
 * will be updated on by this method.
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
export default function validateElementsFromJunctions(designState) {
  const state = { design: designState };
  const allElements = designState.element;
  const changeSetsHelper = new ChangeSetsHelper(designState);

  const elementIdToInvalidityMessages = {};

  for (const junction of Object.values(designState.junction)) {
    if (!isJunctionValidated(junction)) continue;

    const { junctionTypeCode } = junction;

    if (junctionTypeCode === "TYPE_IIS_ENZYME") {
      assignMapsToArrays(
        elementIdToInvalidityMessages,
        getInvalidElementIdsOfTypeIIsJunction(state, junction, true),
        getInvalidElementIdsOfTypeIIsJunction(state, junction, false)
      );
    } else {
      console.error(`Not supporting junction type of ${junctionTypeCode}`);
    }
  }

  for (const element of Object.values(allElements)) {
    if (
      element.invalidityMessage &&
      !elementIdToInvalidityMessages[element.id] &&
      !element.invalidityMessage.startsWith("ARFFAS")
    ) {
      changeSetsHelper.updatePure("element", {
        id: element.id,
        invalidityMessage: null
      });
    }
  }

  Object.entries(elementIdToInvalidityMessages).forEach(
    ([elementId, invalidityMessages]) => {
      const element = allElements[elementId];
      const invalidityMessage = invalidityMessages.length
        ? uniq(invalidityMessages).join("\n")
        : null;
      changeSetsHelper.updatePure("element", {
        id: element.id,
        invalidityMessage
      });
    }
  );

  return changeSetsHelper.execute({
    removeCardInvalidityMessages: false
  });
}
