/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// TODO: These should be moved to code entity (ForcedAssemblyStrategyCode) in the data model linked to the FAS table.
const forcedAssemblyStrategies = [
  "None",
  "DIGEST",
  "Direct Synthesis",
  "PCR",
  "Embed_in_primer_forward",
  "Embed_in_primer_reverse",
  "Annealed Oligos",
  "Top Strand Oligo",
  "Bottom Strand Oligo",
  "Assembly Ready Fragment"
];
export const fasColors = {
  "Direct Synthesis": "blue",
  PCR: "#8E8DBE",
  DIGEST: "#A9E4EF",
  Embed_in_primer_reverse: "#7A306C",
  Embed_in_primer_forward: "#81F495",
  "Annealed Oligos": "#62B33A",
  "Top Strand Oligo": "#BADA55",
  "Bottom Strand Oligo": "darkgrey",
  "Assembly Ready Fragment": "salmon"
};

export const prettifyFasName = name => name.replace(/_/g, " ");

export default forcedAssemblyStrategies;
