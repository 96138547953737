// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.ModelScatterPlotWidgetStyles-module__container-10kas {\n  margin: auto;\n  height: 95%;\n  width: 100%;\n}\n\n.ModelScatterPlotWidgetStyles-module__viz-12UQj .ModelScatterPlotWidgetStyles-module__dot-2bcsT {\n  fill: #137cbd;\n}\n\n.ModelScatterPlotWidgetStyles-module__viz-12UQj .ModelScatterPlotWidgetStyles-module__hoveredDot-KCrup {\n  fill: #3dcc91;\n}\n\n.ModelScatterPlotWidgetStyles-module__tooltip-3HR3v {\n  position: absolute;\n  padding-left: 5px;\n  padding-right: 5px;\n  height: 32px;\n  pointer-events: none;\n}\n\n.ModelScatterPlotWidgetStyles-module__tooltip-3HR3v .ModelScatterPlotWidgetStyles-module__label-2nO5c {\n  font-weight: bold;\n}\n\n.ModelScatterPlotWidgetStyles-module__yEqualXLine-2P1Pb {\n  stroke: #3dcc91;\n  stroke-width: 1;\n  stroke-dasharray: 4, 0, 4;\n  opacity: 1;\n  shape-rendering: geometricPrecision;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ModelScatterPlotWidgetStyles-module__container-10kas",
	"viz": "ModelScatterPlotWidgetStyles-module__viz-12UQj",
	"dot": "ModelScatterPlotWidgetStyles-module__dot-2bcsT",
	"hoveredDot": "ModelScatterPlotWidgetStyles-module__hoveredDot-KCrup",
	"tooltip": "ModelScatterPlotWidgetStyles-module__tooltip-3HR3v",
	"label": "ModelScatterPlotWidgetStyles-module__label-2nO5c",
	"yEqualXLine": "ModelScatterPlotWidgetStyles-module__yEqualXLine-2P1Pb"
};
module.exports = exports;
