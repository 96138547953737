/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { wrapDialog, DataTable } from "@teselagen/ui";

import gql from "graphql-tag";
import { compose } from "recompose";
import withQuery from "../../../src-shared/withQuery";

function TubePlacementSuccessPage({ aliquotContainerPathViews }) {
  return (
    <div>
      <DataTable
        isSimple
        formName="tubePlacementInfoTable"
        noPadding={false}
        entities={aliquotContainerPathViews}
        schema={[
          "name",
          {
            displayName: "Barcode",
            path: "barcodeString"
          },
          {
            width: 550,
            displayName: "Location",
            path: "fullPath"
          }
        ]}
      />
    </div>
  );
}

const tubePlacementSuccessPageFragment = gql`
  fragment tubePlacementSuccessPageFragment on aliquotContainerPathView {
    id
    name
    barcodeString
    fullPath
  }
`;

export default compose(
  wrapDialog({
    title: "Tube Placement Information",
    style: {
      width: 800
    }
  }),
  withQuery(tubePlacementSuccessPageFragment, {
    showLoading: true,
    inDialog: true,
    isPlural: true,
    options: props => {
      return {
        variables: {
          filter: {
            id: props.tubeIds
          }
        }
      };
    }
  })
)(TubePlacementSuccessPage);
