/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { ProgressBar, Intent, Button, Classes } from "@blueprintjs/core";
import { DataTable, CollapsibleCard, withTableParams } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";
import { getWorkflowProgress } from "../../NewWorkflowRun/utils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import { deleteRecordsHelper } from "../../../utils";

const schema = {
  model: "workflowRun",
  fields: [
    "name",
    {
      displayName: "Priority",
      path: "priorityType.name"
    },
    {
      path: "dueDate",
      type: "timestamp"
    },
    {
      displayName: "Progress",
      render: (v, record) => {
        const workflowProgress = getWorkflowProgress(record);
        return (
          <ProgressBar
            intent={workflowProgress === 1 ? Intent.SUCCESS : Intent.PRIMARY}
            value={workflowProgress}
            animate={false}
            stripes={false}
          />
        );
      }
    },
    {
      path: "deleteButton",
      filterDisabled: true,
      sortDisabled: true,
      type: "action",
      width: 60
    }
  ]
};

class WorkflowRunJobs extends React.Component {
  addWorkflow = () => {
    const { jobId, refetchWorkflowRuns } = this.props;
    showDialog({
      modalType: "LAUNCH_NEW_WORKFLOW_DIALOG",
      modalProps: { refetch: refetchWorkflowRuns, jobId }
    });
  };

  render() {
    const { tableParams, readOnly } = this.props;
    return (
      <CollapsibleCard
        key="workflowCard"
        title="Workflow Runs"
        icon="list-detail-view"
      >
        <DataTable
          {...tableParams}
          noPadding
          cellRenderer={{
            deleteButton: (v, record) => {
              if (readOnly) return null;
              return (
                <Button
                  icon="trash"
                  // loading={removingSequences.indexOf(record.id) > -1}
                  intent={Intent.DANGER}
                  className={Classes.MINIMAL}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteRecordsHelper({
                      records: [record],
                      refetch: tableParams.onRefresh
                    });
                  }}
                />
              );
            }
          }}
          compact
          onDoubleClick={routeDoubleClick}
        >
          {!readOnly && (
            <Button
              icon="add"
              text="Add new workflow run"
              intent={Intent.SUCCESS}
              className={Classes.MINIMAL}
              onClick={this.addWorkflow}
              style={{ marginTop: "-3px" }}
            />
          )}
        </DataTable>
      </CollapsibleCard>
    );
  }
}

const fragment = gql`
  fragment workflowRunJobRecordViewFragment on workflowRun {
    id
    user {
      id
      username
    }
    updatedAt
    name
    dueDate
    priorityType {
      code
      name
    }
    # needed for progress
    workflowTools {
      id
      workflowToolStatusCode
    }
  }
`;

export default compose(
  withTableParams({
    formName: "workflowRunJobs",
    urlConnected: false,
    schema,
    defaults: {
      order: ["dueDate"]
    },
    additionalFilter: (props, qb) => {
      qb.whereAll({
        jobId: props.jobId
      });
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(WorkflowRunJobs);
