/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  FileUploadField,
  CheckboxField,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import TagField from "../../../src-shared/TagField";
import GenericSelect from "../../../src-shared/GenericSelect";
import handleReagentTubeImport from "../../../../tg-iso-lims/src/plateImports/handleReagentTubeImport";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import { allowedCsvFileTypes } from "../../../../tg-iso-shared/src/utils/fileUtils";
import AssignToLocationCheckbox from "../AssignToLocationCheckbox";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { defaultReagentFields } from "../../../../tg-iso-lims/src/utils/getReagentsFromCsv";
import { reagentHandlingLogic } from "../../utils/reagentHandlingLogic";

const UploadReagentTubesUnwrapped = ({
  refetch,
  noDialog,
  submitting,
  handleSubmit,
  hideModal,
  fieldPrefix = "",
  error
}) => {
  const onSubmit = async values => {
    try {
      await handleReagentTubeImport({ ...values, refetch });
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error uploading tubes.");
    }
  };

  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <GenericSelect
          name="aliquotContainerType"
          asReactSelect
          isRequired
          idAs="code"
          label="Tube Type"
          tableParamOptions={{
            additionalFilter: {
              isTube: true
            }
          }}
          fragment={[
            "aliquotContainerType",
            "code name maxVolume volumetricUnitCode"
          ]}
        />
        <CheckboxField
          name={fieldPrefix + "generateBarcodes"}
          label="Generate Tube Barcodes"
        />
        {!noDialog && <AssignToLocationCheckbox fieldPrefix={fieldPrefix} />}
        <FileUploadField
          accept={getDownloadTemplateFileHelpers({
            type: allowedCsvFileTypes,
            fileName: "Reagent Tubes",
            validateAgainstSchema: {
              ...reagentHandlingLogic,
              fields: [
                {
                  path: "Tube Name",
                  example: "Tube1",
                  description: "Name of the tube",
                  isRequired: true
                },
                {
                  path: "Tube Barcode",
                  example: "1234567890",
                  description: "Barcode of the tube"
                },
                ...defaultReagentFields.map(f => {
                  if (f.path === "REAGENT_NAME") {
                    return {
                      ...f,
                      isRequired: true
                    };
                  }
                  return f;
                })
              ]
            },
            headerMessages: {
              Reagent:
                "To add multiple reagents to a single well duplicate the reagent headers in this csv file."
            },
            extendedPropTypes: ["tube"]
          })}
          name={fieldPrefix + "tubeFiles"}
          isRequired
        />
        <TagField name={fieldPrefix + "tags"} />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export const UploadReagentTubes = UploadReagentTubesUnwrapped;

export default compose(
  wrapDialog({
    title: "Upload Reagent Tubes"
  }),
  reduxForm({
    form: "uploadReagentTubesForm"
  })
)(UploadReagentTubes);
