/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { get } from "lodash";
import { DataTable, CollapsibleCard } from "@teselagen/ui";

export default class CodingDNASequenceCard extends Component {
  onDoubleClick = record => {
    this.props.history.push(`/dna-sequences/${record.codingDnaSequence.id}`);
  };
  render() {
    const { material } = this.props;
    const plasmidCodingDnaSequences = get(
      material,
      "polynucleotideMaterialSequence.sequenceCodingSequences",
      []
    );
    const schema = {
      model: "sequence",
      fields: [
        {
          displayName: "Name",
          path: "codingDnaSequence.name"
        },
        {
          displayName: "Size",
          path: "codingDnaSequence.size"
        },
        {
          displayName: "AA Sequence",
          path: "codingDnaSequence.aminoAcidSequence.name"
        }
      ]
    };

    return (
      <CollapsibleCard title="Protein Coding Sequences">
        <DataTable
          schema={schema}
          formName="codingDnaSequencesTable"
          isSimple
          entities={plasmidCodingDnaSequences}
          onDoubleClick={this.onDoubleClick}
        />
      </CollapsibleCard>
    );
  }
}
