/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { DataTable, wrapDialog } from "@teselagen/ui";
import { MenuItem, Classes } from "@blueprintjs/core";
import { get } from "lodash";
import { compose } from "recompose";

import { openInNewTab } from "../../../../src-shared/utils/generalUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

function nameAndBarcodeRender(record) {
  let toReturn = "N/A";
  if (!record) return toReturn;
  const name = record.name;
  const barcode = get(record, "barcode.barcodeString");
  if (name || barcode) toReturn = "";
  if (name) toReturn += name;
  if (barcode) toReturn += ` (${barcode})`;
  return toReturn;
}

class InventoryCheckDialog extends Component {
  renderContextMenu = ({ selectedRecords }) => {
    const linkToAliquot = (
      <MenuItem
        key="linkToAliquot"
        icon="arrow-right"
        text="View in inventory"
        onClick={() => openInNewTab(`/aliquots/${selectedRecords[0].id}`)}
      />
    );
    return [linkToAliquot];
  };

  linkToRecord = record => {
    openInNewTab(`/aliquots/${record.id}`);
  };

  render() {
    const { entities = [] } = this.props;
    const dataTableSchema = [
      {
        displayName: "Plate",
        width: 150,
        render: (v, r) =>
          nameAndBarcodeRender(get(r, "aliquotContainer.containerArray"))
      },
      {
        displayName: "Well",
        width: 80,
        render: (v, r) => getAliquotContainerLocation(r.aliquotContainer)
      },
      {
        displayName: "Tube",
        width: 150,
        render: (v, r) => nameAndBarcodeRender(r.aliquotContainer)
      },
      {
        displayName: "Location",
        path: "aliquotContainer.aliquotContainerPathView.fullPath"
      }
    ];
    return (
      <div className={Classes.DIALOG_BODY}>
        <DataTable
          formName="inventoryCheckForm"
          isSimple
          schema={dataTableSchema}
          entities={entities}
          onDoubleClick={this.linkToRecord}
          contextMenu={this.renderContextMenu}
        />
      </div>
    );
  }
}

export default compose(wrapDialog({ title: "Inventory List" }))(
  InventoryCheckDialog
);
