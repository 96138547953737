/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { identity } from "lodash";
import handleUpdateMutations from "./handleUpdates";
import { upsertAddIds } from "./utils";

const ORGANISM = async function ({ recordsToImport }, ctx) {
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport,
      precheckWarningsFn: () => {},
      convertUserFacingToDbModel: identity,
      model: "targetOrganismClass"
    },
    ctx
  );

  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport,
      modelOrFragment: "targetOrganismClass"
    },
    ctx
  );
};
export default ORGANISM;
