/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get, forEach, flatten, isEmpty } from "lodash";
import { SubmissionError } from "redux-form";
import StepForm from "../../../../src-shared/StepForm";

import { J5PCRSelection, PlateConfiguration, InventoryCheck } from "./Steps";
import {
  volumeRender,
  concentrationRender,
  massRender
} from "../../../../src-shared/utils/unitUtils";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose, withProps } from "recompose";
import { pcrPlanningSourcePlateMapGroupFragment } from "./fragments";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getSequence } from "../../../../../tg-iso-shared/src/utils/getSequence";

class PCRReactionPlanningTool extends React.Component {
  onSubmit = async values => {
    const {
      inventoryCheckMap,
      plateMapGroup: plateMapGroupFormValue,
      j5Reports = [],
      finalSelectedAliquots,
      pcrPlanningDataSetName,
      existingPrimerMaterialsListName,
      existingTemplateMaterialsListName,
      templateOrderListName,
      primerOrderListName,
      plateMapGroupName,
      sourcePlateMapGroup,
      validationPlateMapView,
      validationPlateMapGroup
    } = values;
    let plateMapGroup;
    if (validationPlateMapGroup) {
      plateMapGroup = {
        ...validationPlateMapView,
        // check where output plate map name is coming from
        name: plateMapGroupName
      };
    } else {
      plateMapGroup = {
        ...plateMapGroupFormValue,
        name: plateMapGroupName
      };
    }
    try {
      const [
        existingTemplateRows,
        existingPrimerRows
      ] = finalSelectedAliquots.reduce(
        (acc, aliquotEntity) => {
          const [templateRows, primerRows] = acc;
          const isTemplate = aliquotEntity.type === "primaryTemplates";
          const aliquot = aliquotEntity.chosenAliquot;
          const row = {
            rowValues: {
              aliquotId: aliquot.id,
              sequenceName: aliquotEntity.name,
              plateName: get(aliquot, "aliquotContainer.containerArray.name"),
              plateBarcode: get(
                aliquot,
                "aliquotContainer.containerArray.barcode.barcodeString"
              ),
              tubeName: get(aliquot, "aliquotContainer.name"),
              tubeBarcode: get(
                aliquot,
                "aliquotContainer.barcode.barcodeString"
              ),
              well: getAliquotContainerLocation(
                get(aliquot, "aliquotContainer")
              ),
              volume: volumeRender(aliquot, undefined, { noJsx: true }),
              concentration: concentrationRender(aliquot, undefined, {
                noJsx: true
              }),
              mass: massRender(aliquot, undefined, { noJsx: true })
            },
            index: isTemplate ? templateRows.length : primerRows.length
          };
          isTemplate ? templateRows.push(row) : primerRows.push(row);
          return acc;
        },
        [[], []]
      );

      const sequencesToOrder = {
        primaryTemplates: [],
        forwardPrimers: [],
        reversePrimers: []
      };
      forEach(inventoryCheckMap, ({ orderMaterials }, key) => {
        orderMaterials.forEach(sequence => {
          sequencesToOrder[key].push({
            rowValues: {
              sequenceId: sequence.id,
              sequenceName: sequence.name,
              sequence: getSequence(sequence),
              type: key.includes("Primers") ? "PRIMER" : "TEMPLATE"
            }
          });
        });
      });

      const primers = sequencesToOrder.forwardPrimers.concat(
        sequencesToOrder.reversePrimers
      );

      const dataTables = [
        {
          name: existingTemplateMaterialsListName,
          dataTableTypeCode: "PCR_INVENTORY_MATERIALS",
          dataRows: existingTemplateRows
        },
        {
          name: existingPrimerMaterialsListName,
          dataTableTypeCode: "PCR_INVENTORY_MATERIALS",
          dataRows: existingPrimerRows
        },
        {
          name: templateOrderListName,
          dataTableTypeCode: "SEQUENCE_ORDER",
          dataRows: sequencesToOrder.primaryTemplates
        },
        {
          name: primerOrderListName,
          dataTableTypeCode: "SEQUENCE_ORDER",
          dataRows: primers
        }
      ].filter(table => table.dataRows.length);

      const dataSet = {
        name: pcrPlanningDataSetName,
        type: "PCR Planning",
        j5ReportDataSets: j5Reports.map(report => {
          return {
            j5ReportId: report.id
          };
        })
      };

      const [createdDataSet] = await safeUpsert("dataSet", dataSet);

      dataTables.forEach(table => {
        table.dataSetId = createdDataSet.id;
      });
      const createdDataTables = await safeUpsert(
        ["dataTable", "id name"],
        dataTables
      );
      let createdPlateMapGroup;
      if (!sourcePlateMapGroup) {
        delete plateMapGroup.containerFormat;
        plateMapGroup.plateMaps = plateMapGroup.plateMaps.map(plateMap => {
          const newPlateMap = {
            ...plateMap,
            metadata: {
              temperatureZones: plateMap.temperatureZones,
              temperatureZoneOrientation: plateMap.temperatureZoneOrientation
            }
          };
          delete newPlateMap.temperatureZones;
          delete newPlateMap.temperatureZoneOrientation;
          newPlateMap.plateMapItems = newPlateMap.plateMapItems.map(
            ({ rowPosition, columnPosition, id, pcrProductSequence }) => {
              return {
                columnPosition,
                rowPosition,
                metadata: {
                  elongationTime: Math.round(
                    (20 * pcrProductSequence.size) / 1000
                  )
                },
                j5Item: {
                  j5ItemTypeCode: "J5_PCR_REACTION",
                  j5PcrReactionId: id
                }
              };
            }
          );
          return newPlateMap;
        });
        plateMapGroup.dataSetId = createdDataSet.id;
        [createdPlateMapGroup] = await safeUpsert(
          "plateMapGroup",
          plateMapGroup
        );
      }

      window.toastr.success("Successfully saved PCR data");

      return {
        pcrPlanningDataSet: createdDataSet,
        existingPrimerMaterialsList: createdDataTables.find(
          ({ name }) => name === existingPrimerMaterialsListName
        ),
        existingTemplateMaterialsList: createdDataTables.find(
          ({ name }) => name === existingTemplateMaterialsListName
        ),
        templateOrderList: createdDataTables.find(
          ({ name }) => name === templateOrderListName
        ),
        primerOrderList: createdDataTables.find(
          ({ name }) => name === primerOrderListName
        ),
        // the wasCreated will make the success page cleaner
        plateMapGroup: sourcePlateMapGroup || {
          ...createdPlateMapGroup,
          wasCreated: true
        }
      };
    } catch (error) {
      console.error("error", error);
      throw new SubmissionError({
        _error: "error saving data."
      });
    }
  };

  validate = values => {
    const errors = {};
    const {
      j5Reports = [],
      validationPlateMapGroup,
      sourcePlateMapGroup,
      sourceType
    } = values;
    const invalidJ5Reports = [];
    j5Reports.forEach(report => {
      if (report.j5PcrReactions && report.j5PcrReactions.length < 1) {
        invalidJ5Reports.push(report.name);
      }
    });
    if (invalidJ5Reports.length) {
      errors.j5Reports = `The following assembly reports are \
      missing PCR reactions: ${invalidJ5Reports.join(", ")}.`;
    }
    const pcrProductMaterialIds = flatten(
      j5Reports.map(j5Report =>
        j5Report.j5PcrReactions.map(
          reaction => reaction.pcrProductSequence.polynucleotideMaterialId
        )
      )
    );
    let extraMaterials = false;
    let missingPcrReactions = false;
    let incompatiblePlateMapGroup = false;
    if (!isEmpty(validationPlateMapGroup)) {
      incompatiblePlateMapGroup = validationPlateMapGroup.plateMaps.some(pm =>
        pm.plateMapItems.some(pmi => !get(pmi, "inventoryItem.material.id"))
      );
      extraMaterials = validationPlateMapGroup.plateMaps.some(pm =>
        pm.plateMapItems.some(
          pmi =>
            !pcrProductMaterialIds.includes(
              get(pmi, "inventoryItem.material.id")
            )
        )
      );
      const plateMapMaterialIds = flatten(
        validationPlateMapGroup.plateMaps.map(pm =>
          pm.plateMapItems.map(pmi => get(pmi, "inventoryItem.material.id"))
        )
      );
      missingPcrReactions = pcrProductMaterialIds.some(
        id => !plateMapMaterialIds.includes(id)
      );
    }
    if (incompatiblePlateMapGroup) {
      errors.validationPlateMapGroup =
        "The selected plate map contains items that are not linked to materials. Please select a compatible plate map or continue without one.";
    } else if (extraMaterials) {
      errors.validationPlateMapGroup =
        "The selected plate map contains materials unlinked to the selected reactions. Please select a new plate map or continue without one.";
    } else if (missingPcrReactions) {
      errors.validationPlateMapGroup =
        "The selected plate map is missing PCR product materials from the selected reactions. Please select a new plate map or continue without one.";
    }

    if (sourceType === "plateMapGroup" && sourcePlateMapGroup) {
      const allLinkedToReactions = sourcePlateMapGroup.plateMaps.every(
        plateMap => {
          return plateMap.plateMapItems.every(pmi => {
            return get(pmi, "j5Item.j5PcrReaction");
          });
        }
      );
      if (!allLinkedToReactions) {
        errors.sourcePlateMapGroup =
          "Source plate map not properly linked to PCR reactions";
      }
    }
    return errors;
  };

  // tgreen: this will be used to prevent requerying
  loadedOptions = {
    lastLoadedReportIds: []
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues,
      pcrProtocols = [],
      pcrPlateParameterSets = [],
      containerFormats = [],
      j5Reports
    } = this.props;
    const steps = [
      {
        title: (
          <span>
            <i>j5</i> PCR Selection
          </span>
        ),
        Component: J5PCRSelection,
        props: {
          toolSchema,
          loadedOptions: this.loadedOptions
        },
        withCustomFooter: true
      },
      {
        title: "Plate Configuration",
        Component: PlateConfiguration,
        withCustomFooter: true,
        props: {
          j5Reports,
          pcrProtocols,
          pcrPlateParameterSets,
          containerFormats
        }
      },
      {
        title: "Inventory Check",
        Component: InventoryCheck,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(
    ["j5Report", "id name assemblyType assemblyMethod updatedAt"],
    {
      initialValueName: "partialJ5Reports"
    }
  ),
  withWorkflowInputs(pcrPlanningSourcePlateMapGroupFragment, {
    singular: true,
    inputName: "sourcePlateMapGroup"
  }),
  withProps(props => {
    const { initialValues = {} } = props;
    if (initialValues.sourcePlateMapGroup) {
      return {
        initialValues: {
          ...initialValues,
          sourceType: "plateMapGroup"
        }
      };
    }
  })
)(PCRReactionPlanningTool);
