/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const customerRequestFragment = gql`
  fragment customerRequestFragment on customerRequest {
    name
    id
    customerRequestStatusTypeCode
    customerRequestStatusType {
      code
      description
      name
    }
    dueDate
    outputDescription
    primaryCustomerId
    requestTypeId
    createdAt
    updatedAt
    customerRequestJobs {
      id
      job {
        id
        name
        priorityTypeCode
        dueDate
      }
    }
    customerRequestInputFiles {
      id
      encoding
      mimetype
      originalname
      path
      size
    }
    customers {
      id
      userId
      user {
        id
        username
        isDeactivated
      }
    }
    primaryCustomer {
      id
      userId
      user {
        id
        username
        isDeactivated
      }
    }
    requestType {
      id
      name
      description
      instructions
    }
    customerRequestStrains {
      id
      strain {
        id
        name
        strainType {
          code
          name
        }
      }
    }
    customerRequestSequences {
      id
      sequence {
        id
        name
        sequenceType {
          code
          name
        }
      }
    }
    lab {
      id
      name
    }
    user {
      id
      username
    }
  }
`;

export default customerRequestFragment;
