/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog, InputField } from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

function UpdatePlateMapGroupDialog(props) {
  const { hideModal, handleSubmit, submitting, initialValues } = props;
  async function onSubmit(values) {
    try {
      await safeUpsert(["plateMapGroup", "id name"], {
        id: initialValues.id,
        name: values.name
      });
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error updating");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <InputField label="Name" name="name" isRequired />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Update Plate Map"
  }),
  reduxForm({
    form: "updatePlateMap"
  })
)(UpdatePlateMapGroupDialog);
