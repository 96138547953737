/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap, get, keyBy } from "lodash";
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, upsertAddIds } from "./utils";

export default async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  const { safeUpsert, safeQuery } = ctx;

  // Get the existing materials
  const existingMaterialIds = flatMap(recordsToImport, r => r.id || []);
  let keyedExisting = {};
  if (existingMaterialIds.length) {
    const existingMatInfo = await safeQuery(
      ["material", "id polynucleotideMaterialSequence { id } materialTypeCode"],
      {
        variables: {
          filter: {
            id: existingMaterialIds
          }
        }
      }
    );
    keyedExisting = keyBy(existingMatInfo, "id");
  }

  // Create DNA sequences related to the DNA materials
  let seqsToUpsert = [];
  const recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "sequence",
    async sequences => {
      seqsToUpsert = sequences;
      await upsertHandlers.DNA_SEQUENCE(
        {
          ...rest,
          model: "sequence",
          recordsToImport: sequences,
          upsertHandlers,
          createMaterials: false // We don't want to create materials here
        },
        ctx
      );
    }
  );

  const seqIdToMatId = {};

  const seenSequenceIds = [];

  //update the materials that already exist
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      precheckFn: r => {
        const existing = keyedExisting[r.id];
        const sequenceId = get(r, "sequence.id");
        if (existing) {
          if (
            sequenceId &&
            sequenceId !== get(existing, "polynucleotideMaterialSequence.id")
          ) {
            return "Sequence ID does not match existing sequence id.";
          } else if (existing.materialTypeCode !== "DNA") {
            return "Material in inventory is not a DNA material.";
          }
        }
        if (sequenceId) {
          if (seenSequenceIds.includes(sequenceId)) {
            return "Sequence already linked to a different DNA material.";
          }
          seenSequenceIds.push(sequenceId);
        }
      },
      convertUserFacingToDbModel: r => {
        if (!r.id) r.cid = shortid();

        if (r.sequence) {
          seqIdToMatId[r.sequence.id] = r.id || `&${r.cid}`;
        }
        r.materialTypeCode = "DNA";
        //strip off the sequence
        delete r.sequence;
        delete r.existingRecord;
        return r;
      },
      model: "material"
    },
    ctx
  );

  // create new materials
  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport,
      modelOrFragment: "material"
    },
    ctx
  );

  //get the list of seqs to update
  const sequenceUpdates = [];
  seqsToUpsert.forEach(({ id, __importFailed }) => {
    if (__importFailed) return;
    const materialId = seqIdToMatId[id];
    if (materialId) {
      sequenceUpdates.push({
        id,
        polynucleotideMaterialId: materialId
      });
    }
  });

  // update the sequences with the material ids
  await safeUpsert("sequence", sequenceUpdates);
}
