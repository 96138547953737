/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import QueryBuilder from "tg-client-query-builder";
import { isoContext } from "@teselagen/utils";
import { keyBy, groupBy } from "lodash";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";

export default function caseInsensitiveFilter(
  model,
  key,
  arrayOfMatches,
  options = {}
) {
  const { additionalFilter, returnQb } = options;
  const filterArray = [];
  const qb = new QueryBuilder(model);
  arrayOfMatches.forEach(name => {
    filterArray.push({
      [key]: qb.lowerCase(name.trim().toLowerCase()),
      ...additionalFilter
    });
  });
  const filter = qb.whereAny(...filterArray);
  if (returnQb) return filter;
  return filter.toJSON();
}

export async function getCaseInsensitiveKeyedItems(
  modelOrFragment,
  key,
  arrayOfMatches,
  options = {},
  ctx = isoContext
) {
  let fragment = [modelOrFragment, `id ${key}`];
  let model = modelOrFragment;
  if (typeof modelOrFragment !== "string") {
    fragment = modelOrFragment;
    model = getModelNameFromFragment(fragment);
  }
  const items = await ctx.safeQuery(fragment, {
    variables: {
      filter: caseInsensitiveFilter(model, key, arrayOfMatches, options)
    }
  });
  const fn = options.group ? groupBy : keyBy;
  return fn(items, item => item[key].toLowerCase());
}
