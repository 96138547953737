/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { drawScatter } from "./d3/scatterPlot";
import modelScatterPlotWidgetStyles from "./ModelScatterPlotWidgetStyles.module.css";
import ReactResizeDetector from "react-resize-detector";

const TAG = "[ModelScatterPlotWidget]"; // eslint-disable-line

class ModelScatterPlotWidget extends Component {
  constructor(props) {
    super(props);

    const chartContainerClass =
      "_" +
      Math.random()
        .toString(36)
        .slice(2, 6);

    this.state = {
      dataPoints: [],
      target: undefined,
      chartContainerClass: chartContainerClass
    };
  }

  componentDidMount() {
    const { evolveModelDatapoints, dataSchema } = this.props;
    const { chartContainerClass } = this.state;
    const targetColumnName = dataSchema.filter(
      column => column.type === "target"
    )[0].name;
    const trainDataPoints = evolveModelDatapoints.reduce(
      (filteredDataPoints, dataPoint) => {
        if (
          dataPoint.datapointType === "output" &&
          dataPoint.datapoint[targetColumnName] !== undefined &&
          dataPoint.datapoint[targetColumnName] !== null
        ) {
          filteredDataPoints.push(dataPoint.datapoint);
        }

        return filteredDataPoints;
      },
      []
    );
    const descriptors = dataSchema.filter(item => {
      return item.type === "descriptor";
    });

    const target = dataSchema.find(item => {
      return item.type === "target";
    });

    this.setState(() => {
      return {
        dataPoints: trainDataPoints,
        target: target,
        descriptors: descriptors
      };
    });

    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;

    drawScatter(
      chartContainerClass,
      height,
      width,
      trainDataPoints,
      descriptors,
      target.name,
      "prediction"
    );
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      width: this.divElement.clientWidth,
      height: this.divElement.clientHeight
    });
  };

  render() {
    const { dataPoints, target, chartContainerClass, descriptors } = this.state;

    if (this.divElement) {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;

      drawScatter(
        chartContainerClass,
        height,
        width,
        dataPoints,
        descriptors,
        target.name,
        "prediction"
      );
    }

    return (
      <div
        className={modelScatterPlotWidgetStyles.container}
        ref={divElement => (this.divElement = divElement)}
      >
        <div
          className={`${modelScatterPlotWidgetStyles.viz} ${chartContainerClass}`}
        />
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.updateDimensions}
          targetDomEl={this.divElement}
        />
      </div>
    );
  }
}

export default ModelScatterPlotWidget;
