/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { InputField, DataTable } from "@teselagen/ui";

import { find, get } from "lodash";
import stepFormValues from "../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import Tag from "../../../../src-shared/Tag";

import { unitColumns } from "../../../../src-shared/utils/libraryColumns";
import TagField from "../../../../src-shared/TagField";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class ReviewDataTable extends Component {
  render() {
    const {
      selectedAliquots = [],
      savedTags = [],
      selectedTagMap = {}
    } = this.props;
    const filteredTags = [];

    savedTags.length &&
      savedTags.forEach(tag => {
        if (!tag.tagOptions.length) {
          filteredTags.push(tag);
        } else {
          const newTag = {
            ...tag,
            tagOptions: []
          };
          newTag.tagOptions.push(
            find(
              tag.tagOptions,
              tagOption => selectedTagMap[tag.id] === tagOption.id
            )
          );
          filteredTags.push(newTag);
        }
      });

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Tags"
              helper="Select one or more tags to apply to the output data table."
            />
            <TagField noFieldToggle noBorder />
          </div>
          <div className="tg-flex" style={{ marginTop: 10 }}>
            {filteredTags.map((tag, i) => {
              if (tag.tagOptions.length) {
                const tagOptionName = get(tag, "tagOptions[0].name");
                const tagOptionColor = get(tag, "tagOptions[0].color");
                return (
                  <Tag
                    key={i}
                    style={{ marginRight: 5 }}
                    name={`${tag.name}: ${tagOptionName}`}
                    color={tagOptionColor}
                  />
                );
              } else {
                return (
                  <Tag
                    key={i}
                    style={{ marginRight: 5 }}
                    name={tag.name}
                    color={tag.color}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Review Selected Materials"
              helper="Name the output data table of microbial materials and review the selection below."
            />
            <InputField
              name="dataTableName"
              label="Data Table Name"
              style={{ minWidth: 300 }}
            />
          </div>
          <DataTable
            formName="selectedAliquots"
            entities={selectedAliquots}
            schema={aliquotSchema}
            isSimple
          />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("selectedAliquots", "selectedTagMap", "savedTags")
)(ReviewDataTable);

const aliquotSchema = {
  model: "aliquot",
  fields: [
    { path: "sample.material.name", type: "string", displayName: "Material" },
    {
      path: "aliquotContainer.containerArray.name",
      type: "string",
      displayName: "Plate"
    },
    {
      displayName: "Well",
      render: (v, r) => getAliquotContainerLocation(r.aliquotContainer)
    },
    ...unitColumns,
    { path: "updatedAt", type: "timestamp", displayName: "Last Updated" }
  ]
};
