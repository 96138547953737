/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { Button, Intent } from "@blueprintjs/core";
import { SubmissionError, change } from "redux-form";
import StepForm from "../../../../src-shared/StepForm/index";
import plateMapGroupSimpleFragment from "../../../graphql/fragments/plateMapGroupSimpleFragment";
import PlateMapSettings, {
  modelNameToAdditionalFragment,
  modelNameToFragment
} from "./Steps/PlateMapSettings";
import PlateMapConfiguration from "./Steps/PlateMapConfiguration";
import { generatePlateMapGroup, getSelectedItems } from "./utils";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import withQuery from "../../../../src-shared/withQuery";
import containerFormatFragment from "../../../../../tg-iso-shared/src/fragments/containerFormatFragment";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { itemTypes } from "./constants";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import pluralize from "pluralize";
import fieldConstants from "./fieldConstants";

class CreateOrEditPlateMapTool extends Component {
  onSubmit = async values => {
    const {
      platesToCreate: _platesToCreate,
      breakIntoQuadrants,
      j5EntityType,
      j5EntityRadio
    } = values;
    if (!_platesToCreate) {
      throw new SubmissionError({
        _error: "Please add at least one material to a plate well"
      });
    }

    let { itemType } = values;
    if (itemType === "Inventory List") {
      itemType = "sample";
    }

    const plateMapGroupToCreate = generatePlateMapGroup(values);

    const indexMissingName = plateMapGroupToCreate.plateMaps.findIndex(
      pm => !pm.name
    );
    if (indexMissingName > -1) {
      window.teGlobalStore.dispatch(
        change(
          this.props.toolSchema.code,
          "currentPlateMapIndex",
          indexMissingName
        )
      );
      throw new SubmissionError({
        _error: "Please add a name for all new plate maps."
      });
    }
    if (!plateMapGroupToCreate.plateMaps.some(p => p.plateMapItems.length)) {
      if (itemType === "j5Report") {
        throw new SubmissionError({
          _error: `Please add at least one ${modelNameToReadableName(
            breakIntoQuadrants ? j5EntityType : j5EntityRadio
          )} to a plate well`
        });
      } else {
        throw new SubmissionError({
          _error: `Please add at least one ${modelNameToReadableName(
            itemType
          )} to a plate well`
        });
      }
    }
    try {
      const [plateMapGroup] = await safeUpsert(
        plateMapGroupSimpleFragment,
        plateMapGroupToCreate
      );
      return { plateMapGroup };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating plate maps"
      });
    }
  };

  render() {
    const {
      toolSchema,
      toolIntegrationProps,
      containerFormats = [],
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        Component: PlateMapSettings,
        title: "Plate Map Settings",
        withCustomFooter: true
      },
      {
        Component: PlateMapConfiguration,
        title: "Plate Map Configuration",
        props: { containerFormats },
        nextButton: (
          <Button
            type="submit"
            text="Create Plate Map(s)"
            intent={Intent.SUCCESS}
          />
        )
      }
    ];

    return (
      <StepForm
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        onSubmit={this.onSubmit}
        validate={validate}
        steps={steps}
      />
    );
  }
}

const validate = values => {
  const {
    itemType,
    breakIntoQuadrants,
    selectedContainerFormat = {},
    j5Reports = [],
    plateMaps = [],
    containerArrays = [],
    plateMapType,
    plateWellContentType,
    j5EntityType,
    [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities,
    j5EntityRadio
  } = values;
  const standardFormats = ["24_WELL", "96_WELL", "384_WELL", "1536_WELL"];
  const errors = {};
  const selectedItems = getSelectedItems(values);
  if (!itemType) errors.itemType = "Please select an item type.";
  if (itemType === "plateMap" && !plateMapType)
    errors.plateMapType = "Please select a plate map type.";
  if (itemType === "plateMap" && plateMapType && !plateMaps.length)
    errors._error = "Please select at least one plate map.";
  if (itemType === "containerArray" && !plateWellContentType)
    errors.plateWellContentType = "Please choose a plate well content type.";
  if (itemType === "j5Report" && !j5Reports.length) {
    errors._error = `Please select at least one j5Report.`;
  }
  if (
    itemType &&
    itemType !== "j5Report" &&
    itemType !== "plateMap" &&
    itemType !== "containerArray" &&
    itemType !== "Inventory List" &&
    (!selectedItems || !selectedItems.length)
  ) {
    if (itemType === "reactionMap" && selectAllReactionEntities) {
      // skip
    } else {
      errors._error = `Please select at least one ${modelNameToReadableName(
        itemType
      )}.`;
    }
  }
  if (itemType === "containerArray" && !containerArrays.length) {
    errors._error = `Please select at least one plate.`;
  }
  if (itemType === "j5Report" && !j5EntityType) {
    errors.j5EntityType = `Please specify an assembly report entity`;
  }
  if (
    !breakIntoQuadrants &&
    itemType === "j5Report" &&
    j5Reports.length > 0 &&
    !selectedItems.length
  ) {
    errors._error = `Please select at least one ${modelNameToReadableName(
      j5EntityRadio
    )}.`;
  }
  if (
    breakIntoQuadrants &&
    !standardFormats.includes(selectedContainerFormat.code)
  ) {
    errors.selectedContainerFormat =
      "Please select a standard format when breaking into quadrants (24 well, 96 well, 394 well, 1536 well).";
  }
  if (
    !breakIntoQuadrants &&
    itemType === "plateMap" &&
    plateMaps.length > 0 &&
    selectedContainerFormat.code !==
      plateMaps[0].plateMapGroup.containerFormat.code
  ) {
    errors.plateMaps =
      "Please reselect plate maps that match the selected container format above.";
  }

  return errors;
};

export default compose(
  ...itemTypes
    .filter(type => type !== "Inventory List") // can't link this one
    .map(type => {
      const fragment =
        modelNameToAdditionalFragment[type] || modelNameToFragment[type];
      return withWorkflowInputs(fragment);
    }),
  withProps(props => {
    if (props.initialValues) {
      const typeToInitializeTo = itemTypes.find(
        type => props.initialValues[pluralize(type)]?.length
      );
      if (typeToInitializeTo) {
        return {
          initialValues: {
            ...props.initialValues,
            itemType: typeToInitializeTo
          }
        };
      }
    }
  }),
  withQuery(containerFormatFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        filter: {
          code: ["6_WELL", "24_WELL", "96_WELL", "384_WELL", "1536_WELL"]
        }
      }
    }
  })
)(CreateOrEditPlateMapTool);
