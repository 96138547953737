/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";

import { Dialog, Classes } from "@blueprintjs/core";
import { DataTable, DialogFooter } from "@teselagen/ui";

class InsertAssemblyPieceDialog extends React.Component {
  state = {
    sequences: [],
    submitting: false
  };

  wrappedHideModal = () => {
    const { hideModal, resolve } = this.props;
    hideModal();
    if (resolve) resolve();
  };

  handleSelect = sequences =>
    this.setState({
      sequences: Array.isArray(sequences) ? sequences : [sequences]
    });

  handleDoubleClick = async sequence => {
    this.setState({ submitting: true });
    await this.props.onSubmit([sequence]);
    this.props.hideModal();
  };

  render() {
    const {
      tableParams,
      hideModal,
      sequencesCount,
      loading,
      onSubmit,
      title,
      isSingleSelect,
      withCheckboxes = false
    } = this.props;
    const { sequences, submitting } = this.state;
    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={this.wrappedHideModal}
        title={title || "Choose Sequence"}
        style={{ width: 950 }}
      >
        <div className={Classes.DIALOG_BODY}>
          <DataTable
            formName="InsertAssemblyPieceDialog"
            {...tableParams}
            isSingleSelect={isSingleSelect}
            entityCount={sequencesCount}
            isLoading={loading}
            withSearch
            withPaging
            canOutsideClickClose={false}
            isInfinite={false}
            onSingleRowSelect={this.handleSelect}
            onMultiRowSelect={this.handleSelect}
            onDoubleClick={this.handleDoubleClick}
            withCheckboxes={withCheckboxes}
          />
        </div>
        <DialogFooter
          hideModal={this.wrappedHideModal}
          text="OK"
          disabled={!sequences.length}
          loading={submitting}
          onClick={async () => {
            this.setState({ submitting: true });
            await onSubmit(sequences);
            hideModal();
          }}
        />
      </Dialog>
    );
  }
}

export default reduxForm({
  form: "insertAssemblyPieceDialog",
  enableReinitialize: true
})(InsertAssemblyPieceDialog);
