/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes, Intent, Button } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  NumericInputField,
  wrapDialog
} from "@teselagen/ui";

const DEFAULT_PRESET_NAME = "Default";

class SequencingQCVariantCallingSettingsDialog extends Component {
  onSubmit = async values => {
    // If this is "new settings mode", the preset name cannot be the same as one of those already existing.
    if (
      this.props.newSettingsMode &&
      this.props.forbiddenPresetNames.some(
        name => name === values.variantCallingSettingsName
      )
    ) {
      window.toastr.error(
        `Variant Calling Settings preset name: '${values.variantCallingSettingsName}' already exists.`
      );
      return;
    }
    //Only names other than the DEFAULT_PRESET_NAME name can be used as a valid coverage settings name.
    if (values.variantCallingSettingsName !== DEFAULT_PRESET_NAME) {
      const { bq_vc, mq_vc, qd_vc } = values;
      let newVariantCallingSettingsRecord;
      // For new setting presets, an userID and name have to be given. (apparently userId is hanlded automatically in LIMS?)
      if (this.props.newSettingsMode) {
        newVariantCallingSettingsRecord = [
          {
            userId: this.props.newSettingsMode
              ? this.props.currentUser.id
              : undefined,
            variantCallingSettings: { bq_vc, mq_vc, qd_vc },
            name: values.variantCallingSettingsName
          }
        ];
      } else {
        newVariantCallingSettingsRecord = [
          {
            id: this.props.variantCallingSettings.id,
            updatedAt: new Date(),
            variantCallingSettings: { bq_vc, mq_vc, qd_vc }
          }
        ];
      }
      try {
        await this.props.safeUpsert(
          "sequencingQcVariantCallingPreset",
          newVariantCallingSettingsRecord
        );
      } catch (error) {
        window.toastr.error(
          this.props.newSettingsMode
            ? `Error creating new variant calling settings preset: ${values.variantCallingSettingsName}.`
            : "Error updating variant calling settings preset."
        );
        console.error(error);
      }
      await this.props.refetch();
      this.props.hideModal();
    } else {
      window.toastr.error(
        "Please select another Variant Calling Settings Name other than 'Default'"
      );
    }
  };

  async deleteVariantCallingPreset() {
    const {
      variantCallingSettings: { id }
    } = this.props;
    try {
      await this.props.safeDelete("sequencingQcVariantCallingPreset", [id]);
    } catch (error) {
      window.toastr.error(`Error deleting variant calling settings preset.`);
      console.error(error);
    }
    this.props.change("variantCallingSettingsName", "default");
    await this.props.refetch();
    this.props.hideModal();
  }

  render() {
    const {
      hideModal,
      handleSubmit,
      submitting,
      variantCallingSettings = {},
      newSettingsMode
    } = this.props;
    const {
      name = "",
      variantCallingSettings: { bq_vc = "", mq_vc = "", qd_vc = "" }
    } = variantCallingSettings;
    const nonEditable = name === DEFAULT_PRESET_NAME && !newSettingsMode;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <span style={{ marginBottom: "5px" }}>Preset Name</span>
          <InputField
            id={`${name}_1`}
            name="variantCallingSettingsName"
            placeholder={newSettingsMode ? "" : name}
            defaultValue={newSettingsMode ? "" : name}
            disabled={!newSettingsMode}
            isRequired={newSettingsMode}
          />
          <hr />
          <br />
          <NumericInputField
            id={`${name}_bq_vc_2`}
            name="bq_vc"
            label="Base Pair Quality"
            defaultValue={bq_vc}
            placeholder={bq_vc}
            isRequired
            disabled={nonEditable}
            tooltipInfo="Phred base pair quality filter for called variants"
          />
          <NumericInputField
            id={`${name}_mq_vc_2`}
            name="mq_vc"
            label="Mapping Quality"
            defaultValue={mq_vc}
            placeholder={mq_vc}
            isRequired
            disabled={nonEditable}
            tooltipInfo="Phred mapping quality filter for called variants"
          />
          <NumericInputField
            id={`${name}_qd_vc_2`}
            name="qd_vc"
            label="Quality by Depth"
            defaultValue={qd_vc}
            placeholder={qd_vc}
            isRequired={false}
            disabled={nonEditable}
            tooltipInfo="Depth of coverage filter for called variants"
          />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
          text={newSettingsMode ? "Save" : "Update"}
          disabled={!(name !== DEFAULT_PRESET_NAME || newSettingsMode)}
          additionalButtons={
            !this.props.newSettingsMode && (
              <Button
                intent={Intent.DANGER}
                onClick={() => {
                  this.deleteVariantCallingPreset();
                }}
                text="Delete"
                disabled={nonEditable}
              />
            )
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Variant Calling Settings"
  }),
  reduxForm({
    form: "sequencingQualityControlReportTool",
    destroyOnUnmount: false
  })
)(SequencingQCVariantCallingSettingsDialog);
