/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createSelector } from "reselect";

import { isErdam } from "../utils/addErdamUtils";
import {
  COLUMN_WIDTH,
  ADD_BUTTON_WIDTH,
  ADD_BUTTON_HEIGHT,
  BIN_HEIGHT,
  BIN_PART_GAP_HEIGHT,
  CELL_HEIGHT,
  CONSTRUCT_ANNOTATION_HEIGHT
} from "../components/HierarchicalDesign/ClassicGrid/constants";
import {
  getAllOfType,
  getItemOfType,
  getDesignState,
  getOutputCardIdOfReaction,
  getInputCardsOfReaction,
  getReferencedValue,
  getRootCardId,
  getOutputtingReactionOfCard,
  getBinIdsInCard,
  treeLayout,
  getRootCardBinIds,
  getClassicNumberOfRows
} from "../../../tg-iso-design/selectors/designStateSelectors";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

const isClassicDesign = createSelector(
  state => state,
  getDesignState,
  (state, designState) => {
    const {
      reaction: reactions,
      bin: bins,
      binCard: binCards,
      element: elements,
      card: cards
    } = designState;

    // Exactly one reaction
    if (Object.values(reactions).length !== 1)
      return {
        success: false,
        failCase: "Only one reaction allowed in classic view"
      };

    // TODO: Update this function to reflect changes.

    // No placeholder or locked bins.
    if (Object.values(bins).some(s => s.isPlaceholder || s.isLocked))
      return {
        success: false,
        failCase: "No placeholders or locked bins allowed in classic view"
      };

    // No invalid cards
    if (Object.values(cards).some(s => s.invalidityMessage))
      return {
        success: false,
        failCase: "No invalid cards allowed in classic view"
      };

    // No multi-bin non-root cards
    let multiBinCardCount = 0;
    Object.values(binCards).reduce((acc, bc) => {
      acc[bc.cardId] = acc[bc.cardId] || 0;
      if (++acc[bc.cardId] === 2) {
        multiBinCardCount++;
      }
      return acc;
    }, {});
    // the root card is the only allowable multi-bin card if in classic
    if (multiBinCardCount > 1)
      return {
        success: false,
        failCase: "No multi-bin non-root cards allowed in classic view"
      };

    // No extra sequences.
    if (
      Object.values(elements).some(
        el => el.isAssemblyPiece || el.aminoAcidPart || el.extraEndSequence
      )
    )
      return {
        success: false,
        failCase: "No extra sequences allowed in classic view"
      };

    const reaction = Object.values(reactions)[0];
    const rootCardId = getOutputCardIdOfReaction(state, reaction.id);

    const rootCardBinIds = getBinIdsInCard(state, rootCardId);
    const inputs = getInputCardsOfReaction(state, reaction.id);

    // No digest reactions.
    if (
      isErdam(
        getReferencedValue(state, "reaction", reaction.id, "assemblyMethodId")
      )
    )
      return {
        success: false,
        failCase: "No digest reactions allowed in classic view"
      };

    if (rootCardBinIds.length !== inputs.length)
      return {
        success: false,
        failCase: "Rootcard bin numbers need to match the number of input bins"
      };
    inputs.some((input, i) => {
      const binIds = getBinIdsInCard(state, input.id);
      if (binIds.length !== 1) return true;
      if (binIds[0] !== rootCardBinIds[i]) return true;
      return {
        success: false,
        failCase: "Rootcard bin numbers need to match the number of input bins"
      };
    });

    return {
      success: true
    };
  }
);

const isViewClassic = state => treeLayout(state) === "classic";

const getClassicWidthNoButtons = state =>
  getRootCardBinIds(state).length * COLUMN_WIDTH;

const getClassicWidth = state =>
  getClassicWidthNoButtons(state) + ADD_BUTTON_WIDTH;

const getClassicHeightNoButtons = state =>
  BIN_HEIGHT +
  BIN_PART_GAP_HEIGHT +
  CONSTRUCT_ANNOTATION_HEIGHT +
  CELL_HEIGHT * getClassicNumberOfRows(state);

const getClassicHeight = state =>
  getClassicHeightNoButtons(state) + ADD_BUTTON_HEIGHT;

const getInputCardByIndex = tgCreateCachedSelector(
  state =>
    getInputCardsOfReaction(
      state,
      getOutputtingReactionOfCard(state, getRootCardId(state)).id
    ),
  (state, index) => index,
  (inputs, index) => inputs[index]
)((state, index) => index);

const getInputCardById = tgCreateCachedSelector(
  state => state,
  state =>
    getInputCardsOfReaction(
      state,
      getOutputtingReactionOfCard(state, getRootCardId(state)).id
    ),
  (state, binId) => binId,
  (state, inputs, binId) =>
    inputs.find(input => getBinIdsInCard(state, input.id)[0] === binId)
)((state, binId) => binId);

const getRowsIndexIsShown = state =>
  state.ui.designEditor.general.showRowsIndex;
const getRowNamesIsShown = state => state.ui.designEditor.general.showRowNames;

const getSelectedAssemblyMethod = state => {
  const reaction = Object.values(getAllOfType(state, "reaction"))[0];
  if (!reaction) return null;
  const assemblyMethodId = reaction.assemblyMethodId;
  return getItemOfType(state, "assemblyMethod", assemblyMethodId);
};

export {
  isClassicDesign,
  isViewClassic,
  getClassicWidthNoButtons,
  getClassicWidth,
  getClassicHeightNoButtons,
  getClassicHeight,
  getInputCardByIndex,
  getInputCardById,
  getRowsIndexIsShown,
  getRowNamesIsShown,
  getSelectedAssemblyMethod
};
