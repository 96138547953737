/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SubmissionError } from "redux-form";
import { get } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import SelectInputs from "./SelectInputs";
import { compose } from "recompose";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import {
  executeReactionMapToolContainerArrayFragment,
  executeReactionMapToolReactionMapFragment
} from "./fragments";
import queryAndExecuteReactionMap from "../../../../../tg-iso-lims/src/ReactionMap/queryAndExecuteReactionMap";

const onSubmit = async values => {
  const { reactionMaps = [], containerArrays = [] } = values;
  try {
    await queryAndExecuteReactionMap({
      containerArrayIds: containerArrays.map(c => c.id),
      reactionMapIds: reactionMaps.map(r => r.id)
    });
    return {
      containerArrays
    };
  } catch (error) {
    console.error(error);
    const message = get(error, "response.data") || "Error updating samples.";
    throw new SubmissionError({
      _error: message
    });
  }
};

const ExecuteReactionMapTool = ({
  toolIntegrationProps,
  toolSchema,
  isToolIntegrated,
  reactionMaps = [],
  containerArrays = []
}) => {
  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={[
        {
          title: "Execute Reaction Map",
          Component: SelectInputs,
          withCustomFooter: true
        }
      ]}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={{ reactionMaps, containerArrays }}
    />
  );
};

export default compose(
  withWorkflowInputs(executeReactionMapToolContainerArrayFragment),
  withWorkflowInputs(executeReactionMapToolReactionMapFragment)
)(ExecuteReactionMapTool);
