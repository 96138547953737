/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { showConfirmationDialog } from "@teselagen/ui";
import { Intent } from "@blueprintjs/core";
import { ELEMENT_LABEL_LINE_HEIGHT } from "../../constants";
import {
  safeUpsert,
  safeDelete
} from "../../../../../src-shared/apolloMethods";
import { showDialog } from "../../../../../src-shared/GlobalDialog";

class ElementName extends React.Component {
  state = {
    hover: false,
    hoverOverDelete: false
  };

  handleMouseEnter = () => {
    if (this.props.readOnly) return;
    this.setState({ hover: true });
  };
  handleMouseLeave = () => {
    if (this.props.readOnly) return;
    this.setState({ hover: false });
  };

  handleDeleteMouseEnter = () => {
    if (this.props.readOnly) return;
    this.setState({ hoverOverDelete: true });
  };
  handleDeleteMouseLeave = () => {
    if (this.props.readOnly) return;
    this.setState({ hoverOverDelete: false });
  };

  handleXClick = async () => {
    if (this.props.readOnly) return;
    try {
      const confirm = await showConfirmationDialog({
        text: `Are you sure you want to delete this part?
               You cannot undo this action.`,
        intent: Intent.DANGER,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        canEscapeKeyCancel: true
      });

      if (confirm) {
        const { set, element, refetchSchematic } = this.props;

        await safeDelete("schematicElement", element.id);

        const elsToUpdate = set.schematicElements.filter(
          el => el.index > element.index
        );
        if (elsToUpdate.length)
          await safeUpsert(
            "schematicElement",
            elsToUpdate.map(el => ({ id: el.id, index: el.index - 1 }))
          );

        await refetchSchematic();
      }
    } catch (e) {
      console.error(e);
      window.toastr.error("Error editing schematic.");
    }
  };

  handleDoubleClick = () => {
    const { element, readOnly } = this.props;
    if (readOnly) return;
    showDialog({
      modalType: "SCHEMATIC_EDIT_NAME",
      modalProps: {
        initialValues: element,
        type: "element"
      }
    });
  };

  render() {
    const { element, width } = this.props;
    const { hover, hoverOverDelete } = this.state;

    return (
      <g
        className="element-label-element-name-group"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <rect
          className="element-label-element-name-rect"
          stroke="none"
          fill="rgba(0,0,0,0)"
          x={0}
          y={0}
          width={width}
          height={ELEMENT_LABEL_LINE_HEIGHT}
          onDoubleClick={this.handleDoubleClick}
        />
        <text
          className="element-label-element-name element-name-bullet"
          alignmentBaseline="hanging"
          fill={hover ? "#f54242" : null}
          onClick={this.handleXClick}
          onMouseEnter={this.handleDeleteMouseEnter}
          onMouseLeave={this.handleDeleteMouseLeave}
        >
          {hover ? "×" : "◦"}
        </text>
        <text
          className="element-label-element-name"
          alignmentBaseline="hanging"
          transform="translate(15,0)"
          onDoubleClick={this.handleDoubleClick}
          fill={hoverOverDelete ? "#f54242" : null}
        >
          {element.name}
        </text>
      </g>
    );
  }
}

export default ElementName;
