/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { useDragLayer } from "react-dnd";
import { renderHeaderIconInner } from "../SbolIconHeader/HeaderIcon";

function collect(monitor) {
  return {
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    offset: monitor.getSourceClientOffset()
  };
}

function SbolDragLayer() {
  const { isDragging, item, offset } = useDragLayer(collect);

  if (!isDragging || !offset) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: offset.y,
        left: offset.x,
        zIndex: 100,
        background: "black",
        opacity: 0.5
      }}
    >
      {renderHeaderIconInner(item.icon)}
    </div>
  );
}

export default SbolDragLayer;
