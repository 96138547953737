/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable graphql/template-strings */
import React, { useState } from "react";
import hgql from "graphql-tag";
import StepForm from "../../StepForm";
import AssociationStep, { plateFragment } from "./steps/AssociationStep";
import { safeQuery, safeUpsert } from "../../apolloMethods";
import { differenceBy, partition } from "lodash";
import withWorkflowInputs from "../../../src-build/graphql/enhancers/withWorkflowInputs";

const dataGridTotalRowsFragment = hgql`
  fragment dataGridLastRowFragment on dataGrid {
    id
    name
    dataCells_aggregate {
      aggregate {
        max {
          totalRows: rowPosition
        }
      }
    }
  }
`;

function DataAssociationTool(props) {
  const [submitting, setSubmitting] = useState(false);

  const {
    toolIntegrationProps,
    toolSchema,
    isToolIntegrated,
    initialValues,
    assayId
  } = props;

  const createNewDataGrid = async (dataGridName, dataCells) => {
    return await safeUpsert("dataGrid", {
      name: dataGridName,
      /**
       * Data Grids created in the Data Association Tool v1 will be considered "editable"
       * because they can be extended with new data cells and will be linked to no importFileSet.
       */
      dataGridStateCode: "EDITABLE",
      dataCells
    });
  };

  const updateDataGridCells = async (dataGrid, dataCells) => {
    // Since we are extending an existing data grid, we need to know the number
    // of rows it has so we add that up to the incoming dataCells' rowPosition
    const {
      dataCells_aggregate: {
        aggregate: {
          max: { totalRows }
        }
      }
    } = await safeQuery(dataGridTotalRowsFragment, {
      variables: { id: dataGrid.id },
      isPlural: false,
      useHasura: true
    });

    // We also need to check if there's any new headers in the new dataCells
    const [incomingHeaderCells, incomingDataCells] = partition(
      dataCells,
      dataCell => dataCell.rowPosition === 0
    );

    const existingDataGridHeaderCells = dataGrid.dataCellHeaders;

    /**
     * New headers will be found by comparing the incoming header cells
     * with the existing ones.
     *
     * TODO: we could implement a smarter logic that validates that the
     * incoming new headers columnPosition do not collide with existing
     * headers column position.
     */
    const newHeaderCells = differenceBy(
      incomingHeaderCells,
      existingDataGridHeaderCells,
      // This separator is just a made up string which will hopefully avoid
      // collision with header names.
      dataCell => `${dataCell.columnPosition} -;;- ${dataCell.value}`
    );

    /**
     * Build the new data cells with the dataGridId
     */
    const newDataCells = newHeaderCells.map(newHeaderCell => ({
      ...newHeaderCell,
      dataGridId: dataGrid.id
    }));
    newDataCells.push(
      ...incomingDataCells.map(dataCell => ({
        ...dataCell,
        rowPosition: dataCell.rowPosition + totalRows,
        dataGridId: dataGrid.id
      }))
    );
    await safeUpsert("dataCell", newDataCells);
    return dataGrid;
  };

  const onSubmit = async values => {
    try {
      setSubmitting(true);
      const { dataGrid, dataCells } = values;
      if (dataGrid.userCreated) {
        const dataGridName = dataGrid.value;
        const newDataGrid = await createNewDataGrid(dataGridName, dataCells);
        return { assay: newDataGrid[0] };
      }
      await updateDataGridCells(dataGrid, dataCells);
      return {
        assay: dataGrid
      };
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setSubmitting(false);
    }
  };

  const SuccessPageInnerContent = ({ assay }) => {
    const headerMessage = "Your data has been associated.";
    const subHeaderMessage = (
      <span>
        <a href={`/client/data-grids/${assay.id}`}>Go to created Data Grid</a>{" "}
      </span>
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h4>{headerMessage}</h4>
        <h6>{subHeaderMessage}</h6>
      </div>
    );
  };

  const steps = [
    {
      title: "Data Association",
      Component: AssociationStep,
      withCustomFooter: true,
      props: {
        submitting,
        assayId
      }
    }
  ];

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      successPageInnerContent={SuccessPageInnerContent}
    />
  );
}

export default withWorkflowInputs(plateFragment)(DataAssociationTool);
