/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

const templateDefaults = {
  assembledConstructs: {
    template: "Construct_{{{increment_from_0001}}}",
    outputTarget: "assembledConstructs"
  },
  oligoSynthesis: {
    template: "Oligo_{{{increment_from_0001}}}",
    outputTarget: "oligoSynthesis"
  },
  dnaSynthesis: {
    template: "Synthon_{{{increment_from_0001}}}",
    outputTarget: "dnaSynthesis"
  },
  pcrReactions: {
    template: "PCR_{{{increment_from_0001}}}",
    outputTarget: "pcrReactions"
  },
  assemblyPieces: {
    template: "AP_{{{increment_from_0001}}}",
    outputTarget: "assemblyPieces"
  }
};
export const multiLevelTemplateDefaults = {
  assembledConstructs: {
    template: "{{{reaction_prefix}}} Construct_{{{increment_from_0001}}}",
    outputTarget: "assembledConstructs"
  },
  oligoSynthesis: {
    template: "{{{reaction_prefix}}} Oligo_{{{increment_from_0001}}}",
    outputTarget: "oligoSynthesis"
  },
  dnaSynthesis: {
    template: "{{{reaction_prefix}}} Synthon_{{{increment_from_0001}}}",
    outputTarget: "dnaSynthesis"
  },
  pcrReactions: {
    template: "{{{reaction_prefix}}} PCR_{{{increment_from_0001}}}",
    outputTarget: "pcrReactions"
  },
  assemblyPieces: {
    template: "AP_{{{increment_from_0001}}}",
    outputTarget: "assemblyPieces"
  }
};

export default templateDefaults;
