/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import InventoryCheckDialog from "../Dialogs/InventoryCheckDialog";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { safeQuery } from "../../../src-shared/apolloMethods";

class InventoryCheckButton extends Component {
  state = {
    loading: false
  };
  renderInventoryCheckDialog = async () => {
    const { record = {} } = this.props;
    try {
      this.setState({ loading: true });
      const aliquotContainers = await safeQuery(
        [
          "aliquotContainer",
          /* GraphQL */ `
            {
              id
              rowPosition
              columnPosition
              aliquotId
              name
              barcode {
                id
                barcodeString
              }
              aliquotContainerPathView {
                id
                fullPath
              }
              containerArray {
                id
                name
                barcode {
                  id
                  barcodeString
                }
              }
            }
          `
        ],
        {
          variables: {
            filter: {
              [`aliquot.sample.material.polynucleotideMaterialSequence.${record.__typename}.id`]: record.id
            }
          }
        }
      );

      const entities = [];
      aliquotContainers.forEach(aliquotContainer => {
        entities.push({
          id: aliquotContainer.aliquotId,
          aliquotContainer
        });
      });
      showDialog({
        ModalComponent: InventoryCheckDialog,
        modalProps: {
          entities,
          dialogProps: {
            style: {
              width: 700
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div>
        <Button
          text="View Inventory"
          minimal
          small
          intent="primary"
          loading={this.state.loading}
          onClick={e => {
            e.stopPropagation();
            this.renderInventoryCheckDialog();
          }}
        />
      </div>
    );
  }
}

export default InventoryCheckButton;
