/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { cloneDeep, get, keyBy, partition } from "lodash";
import processSequences from "../../sequence-import-utils/processSequences";
import {
  oveSeqDataToGraphQL,
  computeSequenceHash
} from "../../sequence-import-utils/utils";
import handleUpdateMutations from "./handleUpdates";
import { upsertAddIds } from "./utils";
import { checkDuplicateSequencesExtended } from "../../sequence-import-utils/checkDuplicateSequences";
import { getMaterialFields } from "../../sequence-import-utils/getMaterialFields";
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";

//this function should throw an error if something goes wrong with the import/update
const DNA_SEQUENCE = async function (
  { recordsToImport, allowBpUpdates, createMaterials = true },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;

  async function getSequencesDedupFields(sequences) {
    const results = await safeQuery(
      ["sequence", "id sequence: fullSequence circular hash sequenceTypeCode"],
      {
        variables: {
          filter: {
            id: sequences.map(seq => seq.id)
          }
        }
      }
    );

    const keyedResults = keyBy(results, "id");

    return sequences.map(seq => ({
      ...seq,
      ...keyedResults[seq.id]
    }));
  }

  async function addDedupInfoToSequences(sequences) {
    const seqsWithOrderIndex = sequences.map((s, i) => ({
      ...s,
      orderIndex: i
    }));
    const [sequencesWithId, sequencesWithoutId] = partition(
      cloneDeep(seqsWithOrderIndex),
      seq => seq.id
    );

    const sequencesWithIdAndDedupFields =
      await getSequencesDedupFields(sequencesWithId);

    const sequencesWithoutIdAndDedupFields = sequencesWithoutId
      .filter(seq => seq.sequence)
      .map(sequence => {
        const hash = computeSequenceHash(
          sequence.sequence,
          sequence.sequenceTypeCode ||
            (sequence.circular ? "CIRCULAR_DNA" : "LINEAR_DNA")
        );
        return {
          ...sequence,
          hash,
          circular: sequence.circular || false
        };
      });

    return [
      ...sequencesWithIdAndDedupFields,
      ...sequencesWithoutIdAndDedupFields
    ]
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(s => {
        delete s.orderIndex;
        return s;
      });
  }

  async function deduplication(dnaSequences) {
    // NOTE: if a sequence doesnt come with an id nor a sequence string,
    // it will be naturally omitted from the dedup logic.
    const dnaSequencesWithDedupInfo =
      await addDedupInfoToSequences(dnaSequences);
    const { allInputSequencesWithAttachedDuplicates } =
      await checkDuplicateSequencesExtended(
        dnaSequencesWithDedupInfo,
        // We are just detecting duplicates here, we don't want to upsert anything.
        // we'll leave that for the handler to decide.
        { doNotCreateParts: true, doNotCreateAliases: true },
        ctx
      );

    const seqsWithDuplicatesByCid = keyBy(
      allInputSequencesWithAttachedDuplicates.filter(seq => seq.duplicateFound),
      "cid"
    );

    // #region
    // We may want to run an upsert on the parts after finishing with the dna sequences to avoid duplication.
    // const sequenceParts = uniqWith(
    //   flatMap(dnaSequences, sequence => {
    //     const existingSequence = get(
    //       seqsWithDuplicatesByCid,
    //       `${sequence.cid}.duplicateFound`
    //     );
    //     const parts = sequence.parts.map(part => ({
    //       ...part,
    //       ...(existingSequence && { sequenceId: existingSequence.id })
    //     }));
    //     delete sequence.parts;
    //     return parts;
    //   }),
    //   checkDuplicatePart
    // );

    // TODO: we may want to allow for the incoming duplicate to update the existing sequence fields.
    // dnaSequences.forEach(sequence => {
    // const existingSequence = get(
    //   seqsWithDuplicatesByCid,
    //   `${sequence.cid}.duplicateFound`
    // );
    // if (existingSequence) {
    //   sequence.id = existingSequence.id;
    //   if (!sequence.__oldRecord) {
    //     sequence.__oldRecord = existingSequence;
    //     sequence.__newRecord = cleanExternalRecord(sequence, "DNA_SEQUENCE", false?);
    //   }
    // }
    // });
    // #endregion

    return {
      seqsWithDuplicatesByCid
      // sequenceParts
    };
  }

  // Key by CIDs the input sequences with attached duplicate sequences if found.
  const {
    seqsWithDuplicatesByCid
    // sequenceParts
  } = await deduplication(recordsToImport);

  const getDup = r => get(seqsWithDuplicatesByCid, `${r.cid}.duplicateFound`);

  const seqsThatNeedToResetBps = [];
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport,
      preMutateFn: async () => {
        await ctx.deleteWithQuery("sequenceFragment", {
          sequenceId: seqsThatNeedToResetBps.map(s => s.id)
        });
      },
      convertUserFacingToDbModel: r => {
        if (r.isOligo) {
          r.sequenceTypeCode = "OLIGO";
        }

        const transformed = oveSeqDataToGraphQL(r, { keepIds: true });
        if (r.id) {
          if (r.circular === undefined) {
            delete transformed.circular;
            delete transformed.sequenceTypeCode;
          } else if (r.circular && r.sequenceTypeCode !== "OLIGO") {
            transformed.sequenceTypeCode = "CIRCULAR_DNA";
          } else if (r.sequenceTypeCode !== "OLIGO") {
            transformed.sequenceTypeCode = "LINEAR_DNA";
          }
        }

        return transformed;
      },
      precheckWarningsFn: sequence => {
        // If sequence does not come with an ID, then its an attempt
        // to create a new sequence, thus we need to check for duplication.
        if (!sequence.id) {
          // Attach a warning to the sequence record if its dna is already found in the database.
          const duplicateSeq = getDup(sequence);
          if (duplicateSeq) {
            const seqTypeName =
              sequence.sequenceTypeCode === "OLIGO" ? "Oligo" : "DNA seq";
            // return `This record has sequence ${sequence.name} with a dna already with sequence ${duplicateSeq.name}`;
            return `Duplicate ${seqTypeName} '${duplicateSeq.name}' detected and updated to match incoming seq '${sequence.name}'.`;
          }
        }
      },
      precheckFn: r => {
        const seq = oveSeqDataToGraphQL(r);
        if (seq.hash && r.__oldRecord && r.__oldRecord.hash !== seq.hash) {
          if (!allowBpUpdates) {
            return "This sequence already exists with different base pairs. You cannot update the dna of an existing sequence.";
          } else {
            // allowBpUpdates is a special flag that can be passed which lets bps be updated with potential issues
            seqsThatNeedToResetBps.push(r.__oldRecord);
          }
        }
      },
      model: "sequence"
    },
    ctx
  );

  let recordsToCreate = newRecords;
  const createdCids = [];

  if (newRecords.length) {
    recordsToCreate = [];
    const { allInputSequencesWithAttachedDuplicates } =
      await checkDuplicateSequencesExtended(newRecords, {}, ctx);
    const sequenceMaterials = [];
    const cidToMaterialId = {};
    allInputSequencesWithAttachedDuplicates.forEach(inputSeq => {
      const sequenceToUse = inputSeq.duplicateFound || inputSeq;
      const record = recordsToImport.find(r => r.cid === inputSeq.cid);
      if (sequenceToUse.id) {
        record.id = sequenceToUse.id;
        record.cid = sequenceToUse.cid;
        record.polynucleotideMaterialId =
          sequenceToUse.polynucleotideMaterialId;
        record.duplicate = true;
      } else if (inputSeq.duplicateFound) {
        //it's a duplicate of an input sequence
        record.cid = sequenceToUse.cid;
        record.polynucleotideMaterialId = cidToMaterialId[record.cid];
      } else {
        if (!createdCids.includes(sequenceToUse.cid)) {
          createdCids.push(sequenceToUse.cid); // we're doing this because we're deduplicating input sequences
          const cid = shortid();
          sequenceMaterials.push({
            cid,
            name: sequenceToUse.name,
            externalAvailability: record?.inInventory,
            ...getMaterialFields()
          });

          const materialId = `&${cid}`;
          record.polynucleotideMaterialId = materialId;
          cidToMaterialId[sequenceToUse.cid] = materialId;
          sequenceToUse.polynucleotideMaterialId = materialId;

          recordsToCreate.push(sequenceToUse);
        }
      }
    });
    // because of the order of deduplication an input seq dup might not have materialId set until after
    // its duplicate so just add this check here to be safe
    recordsToImport.forEach(record => {
      if (
        record.cid &&
        !record.polynucleotideMaterialId &&
        cidToMaterialId[record.cid]
      ) {
        record.polynucleotideMaterialId = cidToMaterialId[record.cid];
      }
    });
    if (recordsToCreate.length) {
      if (createMaterials) {
        await safeUpsert("material", sequenceMaterials);
      }
      const ents = await upsertAddIds(
        {
          recordsToCreate,
          recordsToImport,
          modelOrFragment: "sequence"
        },
        ctx
      );
      // TODO: This creates AA sequences from the CDS features
      // We would eventually want this for DESIGN as well.

      await processSequences(
        ents.map(e => e.id),
        ctx
      );
    }
  }
};

export default DNA_SEQUENCE;
