/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

function TagsRenderingWrapper({ children }) {
  return (
    <div className="tg-flex" style={{ overflow: "auto", padding: "0 3px" }}>
      {children}
    </div>
  );
}

export default TagsRenderingWrapper;
