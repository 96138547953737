/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { unitColumns } from "../../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import { getAliquotMaterialString } from "../../../utils/plateUtils";
import FormulateNewSampleFromAliquotDialog from "../../Dialogs/FormulateNewSampleFromAliquotDialog";
import AliquotRecordView from "../../Record/AliquotRecordView";

const AliquotLibrary = props => {
  const generateExtraContextMenuItems = selectedRecords => {
    const [record] = selectedRecords;
    const { refetch } = props;
    const { volume } = record;
    if (selectedRecords.length === 1) {
      return [
        <MenuItem
          key="createReplicate"
          icon="duplicate"
          disabled={!volume || volume <= 0}
          onClick={() => {
            showDialog({
              modalType: "NewReplicateAliquotDialog",
              modalProps: {
                refetch,
                sourceAliquotId: record.id
              }
            });
          }}
          text="Create Replicate"
        />,
        <MenuItem
          key="formulateNewSample"
          icon="add"
          onClick={() => {
            showDialog({
              ModalComponent: FormulateNewSampleFromAliquotDialog,
              modalProps: {
                originalAliquot: record,
                refetch
              }
            });
          }}
          text="Formulate New Sample"
        />
      ];
    }
  };

  return (
    <AbstractLibrary
      {...props}
      RecordViewInspector={AliquotRecordView}
      model="aliquot"
      noNewItem
      isLibraryTable
      withExport
      generateExtraContextMenuItems={generateExtraContextMenuItems}
    />
  );
};

const schema = {
  model: "aliquotPathView",
  fields: [
    ...unitColumns,
    { path: "aliquotType", type: "string", displayName: "Aliquot Type" },
    { path: "sample.name", type: "string", displayName: "Sample" },
    {
      path: "sample.material.name",
      type: "string",
      displayName: "Material",
      render: (v, aliquot) => {
        if (v) return v;
        else {
          return getAliquotMaterialString(aliquot);
        }
      }
    },
    {
      width: 300,
      displayName: "Location",
      path: "fullPath"
    },
    {
      type: "string",
      displayName: "Asset ID",
      render: (v, aliquot) => {
        return "TAQ" + `${aliquot.nid}`.padStart(3, "0");
      }
    }
  ]
};

const fragment = gql`
  fragment aliquotLibraryFragment on aliquotPathView {
    id
    nid
    concentration
    concentrationUnitCode
    molarity
    molarityUnitCode
    volume
    volumetricUnitCode
    mass
    massUnitCode
    fullPath
    sample {
      id
      name
      sampleTypeCode
      sampleFormulations {
        id
        materialCompositions {
          id
          material {
            id
            name
          }
        }
      }
      material {
        id
        name
      }
    }
    aliquotType
  }
`;

export default libraryEnhancer({
  schema,
  fragment,
  updateableModel: "aliquot",
  showIdColumnByDefault: true,
  additionalFilter: (props, qb, currentParams) => {
    if (currentParams.j5SequenceIdFilter) {
      qb.whereAll({
        "sample.material.polynucleotideMaterialSequence.id":
          currentParams.j5SequenceIdFilter,
        "aliquot.aliquotContainer.id": qb.notNull()
      });
    }
  }
})(AliquotLibrary);
