/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent, Callout, Tooltip, Icon } from "@blueprintjs/core";
import { compose } from "recompose";
import {
  DataTable,
  Loading,
  BlueprintError,
  CheckboxField,
  FileUploadField,
  IntentText
} from "@teselagen/ui";
import { cloneDeep, get, uniqBy } from "lodash";
import dataTableFragment from "../../../../graphql/fragments/dataTableFragment";
import {
  arpDataRowFragment,
  arpJ5ConstructAssemblyPieceFragment,
  arpContainerArrayFragment
} from "../fragments";
import { computeDataTableValues, getMaterialsFromDataTable } from "../utils";
import { validateNoDryPlatesObject } from "../../../../utils/plateUtils";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";

import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { safeQuery } from "../../../../../src-shared/apolloMethods";

import { getCaseInsensitiveKeyedItems } from "../../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";

const fields = [
  {
    path: "Assembly Piece Material",
    description: "Material used for assembly piece",
    example: "PCR-amplified DNA fragments"
  },
  {
    path: "Construct Material",
    description: "Material used for construct",
    example: "Plasmid backbone"
  }
];

class SelectJ5ConstructList extends Component {
  state = {
    loadingFullConstructLists: false
  };
  static defaultProps = {
    selectedDataTables: []
  };

  async componentDidUpdate(oldProps) {
    const {
      partialConstructLists = [],
      constructLists = [],
      stepFormProps: { change },
      lastLoadingConstructListIds = []
    } = this.props;
    const newIds = partialConstructLists.map(w => w.id);
    const wasAlreadyLoaded = newIds.every(id =>
      lastLoadingConstructListIds.includes(id)
    );

    this.handleAssemblyFileCleanup(oldProps);
    // reset
    if (!partialConstructLists.length && constructLists.length) {
      change("constructLists", []);
      change("lastLoadingConstructListIds", []);
    }

    if (!wasAlreadyLoaded && partialConstructLists.length) {
      change("lastLoadingConstructListIds", newIds);
      this.setState({
        loadingFullConstructLists: true
      });

      try {
        const constructLists = [];
        for (const partialConstructList of partialConstructLists) {
          let dataRows = await safeQuery(arpDataRowFragment, {
            variables: {
              filter: {
                dataTableId: partialConstructList.id
              }
            }
          });
          const j5ConstructIds = dataRows.reduce((acc, row) => {
            row.dataRowJ5Items.forEach(drJ5Item => {
              const j5ConstructId = get(drJ5Item, "j5Item.j5RunConstruct.id");
              if (j5ConstructId) {
                acc.push(j5ConstructId);
              }
            });
            return acc;
          }, []);
          if (j5ConstructIds) {
            const constructAPs = await safeQuery(
              arpJ5ConstructAssemblyPieceFragment,
              {
                variables: {
                  filter: {
                    j5RunConstructId: j5ConstructIds
                  }
                }
              }
            );
            if (constructAPs) {
              const groupedConstructAps = constructAPs.reduce((acc, ap) => {
                if (!acc[ap.j5RunConstructId]) acc[ap.j5RunConstructId] = [];
                acc[ap.j5RunConstructId].push(ap);
                return acc;
              }, {});
              dataRows = dataRows.map(dataRow => {
                return {
                  ...dataRow,
                  dataRowJ5Items: dataRow.dataRowJ5Items.map(drJ5Item => {
                    const j5RunConstruct = get(
                      drJ5Item,
                      "j5Item.j5RunConstruct"
                    );
                    const j5ConstructAssemblyPieces =
                      (j5RunConstruct &&
                        groupedConstructAps[j5RunConstruct.id]) ||
                      [];
                    if (j5RunConstruct) {
                      return {
                        ...drJ5Item,
                        j5Item: {
                          ...drJ5Item.j5Item,
                          j5RunConstruct: {
                            ...j5RunConstruct,
                            j5ConstructAssemblyPieces
                          }
                        }
                      };
                    } else {
                      return drJ5Item;
                    }
                  })
                };
              });
            }
          }
          constructLists.push({
            ...partialConstructList,
            dataRows
          });
        }

        const { constructDataTablesName, constructMap, constructReactionMap } =
          computeDataTableValues(constructLists);

        change("constructDataTablesName", constructDataTablesName);
        change("constructMap", constructMap);
        change("constructReactionMap", constructReactionMap);
        const selectedDataSetIds = constructLists.map(
          dataTable => dataTable.dataSet && dataTable.dataSet.id
        );
        const dataTables = await safeQuery(dataTableFragment, {
          variables: {
            filter: {
              dataSetId: selectedDataSetIds,
              dataTableTypeCode: "J5_ASSEMBLY_PIECE_LIST"
            }
          }
        });
        change("selectedDataTables", dataTables);
        const assemblyMaterials = getMaterialsFromDataTable(dataTables);
        change("assemblyMaterials", uniqBy(assemblyMaterials, "id"));
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error loading full construct lists.");
      }

      this.setState({
        loadingFullConstructLists: false
      });
    }
  }

  handleAssemblyFileCleanup(oldProps) {
    const { assemblyPieceCsv: oldCsv = [] } = oldProps;
    const {
      assemblyPieceCsv: newCsv = [],
      assemblyMaterials = [],
      stepFormProps: { change },
      constructReactionMap
    } = this.props;
    if (oldCsv.length && !newCsv.length) {
      // removed upload. need to clear out uploaded pieces
      const newAssemblyMaterials = assemblyMaterials.filter(m => !m.csvUpload);
      change("assemblyMaterials", newAssemblyMaterials);
      // make new object for reactions so that table will re-render
      const newConstructReactionMap = cloneDeep(constructReactionMap);
      newConstructReactionMap.reactions.forEach(reaction => {
        const keepMaterialIds = [];
        const filteredInputs = reaction.reactionInputs.filter(input => {
          const keep = !input.csvUpload;
          if (keep) {
            keepMaterialIds.push(input.inputMaterialId);
          }
          return keep;
        });
        if (filteredInputs.length !== reaction.reactionInputs.length) {
          reaction.reactionInputs = filteredInputs;
          reaction.inputMaterials = reaction.inputMaterials.filter(m =>
            keepMaterialIds.includes(m.id)
          );
          reaction.inputs = {
            names: reaction.inputMaterials.map(m => m.name).join(", ")
          };
        }
      });
      change("constructReactionMap", newConstructReactionMap);
    }
  }

  getMissingMaterialNames = () => {
    const {
      assemblyMaterials = [],
      containerArrays = [],
      aliquotContainers = []
    } = this.props;
    const missingMaterialMap = assemblyMaterials.reduce((acc, material) => {
      acc[material.id] = material;
      return acc;
    }, {});

    containerArrays.forEach(plate => {
      plate.aliquotContainers.forEach(container => {
        const materialId = get(container, "aliquot.sample.material.id");
        if (materialId) {
          delete missingMaterialMap[materialId];
        }
      });
    });
    aliquotContainers.forEach(tube => {
      const materialId = get(tube, "aliquot.sample.material.id");
      if (materialId) {
        delete missingMaterialMap[materialId];
      }
    });
    const missingMaterialNames = [];
    Object.values(missingMaterialMap).forEach(material => {
      if (!missingMaterialNames.includes(material.name)) {
        missingMaterialNames.push(material.name);
      }
    });
    return missingMaterialNames;
  };

  getSourcedMaterialMap = () => {
    const { containerArrays = [], aliquotContainers = [] } = this.props;
    const sourceMaterialMap = {};

    containerArrays.forEach(plate => {
      plate.aliquotContainers.forEach(container => {
        const materialId = get(container, "aliquot.sample.material.id");
        if (materialId) {
          sourceMaterialMap[materialId] = true;
        }
      });
    });
    aliquotContainers.forEach(tube => {
      const materialId = get(tube, "aliquot.sample.material.id");
      if (materialId) {
        sourceMaterialMap[materialId] = true;
      }
    });
    return sourceMaterialMap;
  };

  removeConstructSelection = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("constructReactionMap", null);
    change("lastLoadingConstructListIds", []);
    change("constructDataTablesName", null);
    change("constructMap", null);
    change("selectedDataTables", []);
  };

  beforeNextStep = values => {
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const { containerArrays = [] } = values;
    // add nested containerArray to aliquot containers
    const fullContainerArrays = containerArrays.map(c => {
      return {
        ...c,
        aliquotContainers: c.aliquotContainers.map(ac => {
          return {
            ...ac,
            containerArray: {
              id: c.id,
              name: c.name
            }
          };
        })
      };
    });
    change("containerArrays", fullContainerArrays);
    nextStep();
  };

  validateAssemblyCsv = (fileList = []) => {
    const csvFile = fileList[0];
    if (csvFile && csvFile.error) {
      return csvFile.error;
    }
  };

  handleAssemblyInfoCsv = async (fileList, onChange) => {
    const csvFile = fileList[0];
    const {
      stepFormProps: { change },
      constructReactionMap,
      assemblyMaterials = []
    } = this.props;
    try {
      const { parsedData } = csvFile;
      const materialNames = [];
      const keyedReactions = {};
      // make new object for reactions so that table will re-render
      const newConstructReactionMap = cloneDeep(constructReactionMap);
      newConstructReactionMap.reactions.forEach(reaction => {
        const { output } = reaction;
        keyedReactions[output.name] = reaction;
      });
      for (const [index, row] of parsedData.entries()) {
        const {
          "Assembly Piece Material": assemblyPieceMaterialName,
          "Construct Material": constructMaterialName
        } = row;
        if (!keyedReactions[constructMaterialName]) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the construct material ${constructMaterialName} which was not found on the selected construct list.`
          );
        }
        materialNames.push(assemblyPieceMaterialName.trim());
      }
      if (!materialNames.length) {
        throw new Error("No materials specified in CSV");
      }
      const keyedMaterials = await getCaseInsensitiveKeyedItems(
        "material",
        "name",
        materialNames
      );
      const csvAssemblyMaterials = [];
      const csvReactions = {};
      for (const [index, row] of parsedData.entries()) {
        const {
          "Assembly Piece Material": assemblyPieceMaterialName,
          "Construct Material": constructMaterialName
        } = row;
        const assemblyPieceMaterial =
          keyedMaterials[assemblyPieceMaterialName.toLowerCase()];
        if (!assemblyPieceMaterial) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the assembly piece material ${assemblyPieceMaterialName} which was not found in inventory.`
          );
        }
        csvAssemblyMaterials.push(assemblyPieceMaterial);
        if (!csvReactions[constructMaterialName]) {
          csvReactions[constructMaterialName] = [];
        }
        csvReactions[constructMaterialName].push(assemblyPieceMaterial);
      }
      const newFile = {
        ...csvFile,
        loading: false
      };
      // if they haven't cleared the data table while this was loading
      if (this.props.selectedDataTables) {
        const existingAssemblyMaterialIds = assemblyMaterials.map(m => m.id);
        const newAssemblyMaterials = [...assemblyMaterials];
        csvAssemblyMaterials.forEach(m => {
          if (!existingAssemblyMaterialIds.includes(m.id)) {
            newAssemblyMaterials.push({ ...m, csvUpload: true });
          }
        });
        Object.keys(csvReactions).forEach(constructMaterialName => {
          const assemblyMaterials = csvReactions[constructMaterialName];
          const existingReaction = keyedReactions[constructMaterialName];
          const materialsToAddToReaction = [];
          assemblyMaterials.forEach(m => {
            const alreadyHasMaterial = existingReaction.reactionInputs.some(
              ri => {
                return ri.inputMaterialId === m.id;
              }
            );
            if (!alreadyHasMaterial) {
              materialsToAddToReaction.push(m);
            }
          });
          if (materialsToAddToReaction.length) {
            existingReaction.inputMaterials.push(...materialsToAddToReaction);
            materialsToAddToReaction.forEach(m => {
              existingReaction.reactionInputs.push({
                inputMaterialId: m.id,
                csvUpload: true
              });
            });
            existingReaction.inputs = {
              names: existingReaction.inputMaterials.map(m => m.name).join(", ")
            };
          }
        });
        change("constructReactionMap", newConstructReactionMap);
        change("assemblyMaterials", uniqBy(newAssemblyMaterials, "id"));
        onChange([newFile]);
      } else {
        onChange([]);
      }
    } catch (error) {
      onChange([
        {
          ...csvFile,
          loading: false,
          error: error.message || "Error parsing csv file."
        }
      ]);
      console.error("error:", error);
    }
  };

  render() {
    const { loadingFullConstructLists } = this.state;
    const {
      selectedDataTables,
      constructReactionMap,
      Footer,
      containerArrays = [],
      aliquotContainers = [],
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      footerProps,
      handleSubmit,
      uploadAdditionalAssemblyPieces,
      assemblyMaterials = []
    } = this.props;
    const anyInitialValuesLoading =
      isLoadingMap.containerArrays || isLoadingMap.constructLists;

    const unMappedReactionErrors = [];
    let validConstructSelection = true;
    constructReactionMap &&
      constructReactionMap.reactions.forEach(reaction => {
        if (reaction.inputs.names === ", " || !reaction.output.name) {
          unMappedReactionErrors.push(reaction.name);
          validConstructSelection = false;
        }
      });
    const materialMap = this.getSourcedMaterialMap();
    const reactions = constructReactionMap
      ? constructReactionMap.reactions
      : [];
    let reactionErrors;
    if (unMappedReactionErrors.length > 1)
      reactionErrors =
        "The selected data tables have unlinked assembly pieces or output constructs in the following reactions: " +
        unMappedReactionErrors.join(", ") +
        ".";

    const plateErrors = validateNoDryPlatesObject(containerArrays);

    const missingMaterials = [];
    if (
      constructReactionMap &&
      validConstructSelection &&
      !loadingFullConstructLists &&
      (containerArrays.length || aliquotContainers.length)
    ) {
      assemblyMaterials.forEach(mat => {
        if (!materialMap[mat.id]) {
          missingMaterials.push(mat.name);
        }
      });
    }

    const aReactionIsBroken = reactions.some(r => !r.inputMaterials.length);

    return (
      <div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Construct Lists"
              helper="Select one or more lists
              of constructs."
            />
            <div>
              <GenericSelect
                {...{
                  name: "partialConstructLists",
                  isRequired: true,
                  schema: [
                    "name",
                    {
                      displayName: "Data Set",
                      path: "dataSet.name"
                    },
                    dateModifiedColumn
                  ],
                  onClear: this.removeConstructSelection,
                  isMultiSelect: true,
                  nameOverride: "Construct Lists",
                  buttonProps: {
                    disabled: isDisabledMap.constructLists,
                    loading: isLoadingMap.constructLists
                  },
                  fragment: [
                    "dataTable",
                    "id name dataSet { id name } updatedAt"
                  ],
                  tableParamOptions: {
                    additionalFilter: {
                      dataTableTypeCode: "J5_CONSTRUCT_SELECTION"
                    }
                  }
                }}
              />
            </div>
          </div>
          {loadingFullConstructLists && <Loading inDialog />}
          {constructReactionMap &&
            validConstructSelection &&
            !loadingFullConstructLists && (
              <React.Fragment>
                <DataTable
                  formName="assemblyPlanningReactionMapReviewDataTable"
                  className="assembly-reaction-reactions-table"
                  schema={[
                    {
                      type: "action",
                      width: 30,
                      render: (v, r) => {
                        if (
                          !containerArrays.length &&
                          !aliquotContainers.length
                        ) {
                          return (
                            <Tooltip content="Pending selection of input plates/tubes">
                              <Icon intent="warning" icon="warning-sign" />
                            </Tooltip>
                          );
                        } else if (!r.inputMaterials.length) {
                          return (
                            <Tooltip content="Reaction does not have any inputs">
                              <Icon intent="error" icon="warning-sign" />
                            </Tooltip>
                          );
                        } else {
                          const missingInputMaterials = [];
                          r.inputMaterials.forEach(mat => {
                            if (!materialMap[mat.id]) {
                              missingInputMaterials.push(mat.name);
                            }
                          });
                          if (missingInputMaterials.length) {
                            return (
                              <Tooltip
                                content={`Reaction is missing these input materials: ${missingInputMaterials.join(
                                  ", "
                                )}`}
                              >
                                <Icon intent="danger" icon="error" />
                              </Tooltip>
                            );
                          } else {
                            return (
                              <Tooltip content="All materials for this reaction have been selected">
                                <Icon intent="success" icon="tick-circle" />
                              </Tooltip>
                            );
                          }
                        }
                      }
                    },
                    { path: "name", displayName: "Name" },
                    {
                      path: "inputs.names",
                      displayName: "Inputs",
                      render: (v, r) => {
                        return r.inputMaterials.map((material, i) => {
                          if (
                            !containerArrays.length &&
                            !aliquotContainers.length
                          ) {
                            return (
                              <div key={material.id} style={{ marginTop: 5 }}>
                                {material.name}
                              </div>
                            );
                          }
                          return (
                            <IntentText
                              key={i}
                              intent={
                                materialMap[material.id] ? "success" : "danger"
                              }
                            >
                              {material.name}
                            </IntentText>
                          );
                        });
                      }
                    },
                    { path: "output.name", displayName: "Output" }
                  ]}
                  // to force a refresh
                  containerArrays={containerArrays}
                  aliquotContainers={aliquotContainers}
                  destroyOnUnmount={false}
                  entities={reactions}
                  style={{ marginTop: 15 }}
                  isSimple
                  withPaging
                  defaults={{
                    pageSize: 100
                  }}
                  noSelect
                />
                <CheckboxField
                  label="Upload Additional Assembly Pieces"
                  name="uploadAdditionalAssemblyPieces"
                />
                {uploadAdditionalAssemblyPieces && (
                  <div style={{ maxWidth: 450 }}>
                    <FileUploadField
                      label="Upload a CSV with extra assembly pieces not included in the j5 report."
                      beforeUpload={this.handleAssemblyInfoCsv}
                      validate={this.validateAssemblyCsv}
                      accept={getDownloadTemplateFileHelpers({
                        fileName: "assembly_pieces",
                        validateAgainstSchema: {
                          fields
                        }
                      })}
                      fileLimit={1}
                      name="assemblyPieceCsv"
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          {!validConstructSelection && selectedDataTables && (
            <Callout intent={Intent.DANGER} icon="info-sign">
              {reactionErrors}
            </Callout>
          )}
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            width="100%"
            header="Select Source Plates"
            helper="Select one or more source plates of assembly pieces.
              Make sure that all of the necessary input materials are present."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "containerArrays",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                fragment: [
                  "containerArray",
                  "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: arpContainerArrayFragment,
                postSelectDTProps: {
                  formName: "assemblyReactionPlates",
                  isSingleSelect: true,
                  plateErrors,
                  schema: [
                    platePreviewColumn({
                      plateErrors
                    }),
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" },
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    }
                  ]
                },
                buttonProps: {
                  loading: isLoadingMap.containerArrays,
                  disabled: isDisabledMap.containerArrays
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            width="100%"
            header="Select Source Tubes"
            helper="Select one or more source tubes of assembly pieces.
              Make sure that all of the necessary input materials are present."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "aliquotContainers",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                fragment: [
                  "aliquotContainer",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: [
                  "aliquotContainer",
                  "id name barcode { id barcodeString } aliquotContainerType { code name } aliquot { id isDry volume volumetricUnitCode mass massUnitCode sample { id name material { id name } } }"
                ],
                tableParamOptions: {
                  additionalFilter: additionalFilterForTubes
                },
                postSelectDTProps: {
                  formName: "assemblyReactionTubes",
                  schema: [
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" },
                    {
                      displayName: "Material",
                      path: "aliquot.sample.material.name"
                    },
                    {
                      displayName: "Volume",
                      path: "aliquot",
                      render: volumeRender
                    },
                    {
                      displayName: "Tube Type",
                      path: "aliquotContainerType.name"
                    }
                  ]
                },
                buttonProps: {
                  loading: isLoadingMap.containerArrays,
                  disabled: isDisabledMap.containerArrays
                }
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          errorMessage={
            missingMaterials.length
              ? "Please check construct table for missing reaction materials."
              : ""
          }
          nextButton={
            <Button
              intent={Intent.PRIMARY}
              onClick={handleSubmit(this.beforeNextStep)}
              disabled={
                aReactionIsBroken ||
                missingMaterials.length ||
                !selectedDataTables.length ||
                !validConstructSelection
              }
              loading={anyInitialValuesLoading}
            >
              Next
            </Button>
          }
        />
        {constructReactionMap && !selectedDataTables.length && (
          <div className="tg-flex justify-flex-end">
            <BlueprintError error="No assembly lists found." />
          </div>
        )}
      </div>
    );
  }
}

const additionalFilterForTubes = (props, qb) => {
  qb.whereAll({
    containerArrayId: qb.isNull()
  });
};

export default compose(
  stepFormValues(
    "constructMap",
    "constructReactionMap",
    "constructDataTablesName",
    "selectedDataTables",
    "containerArrays",
    "aliquotContainers",
    "partialConstructLists",
    "constructLists",
    "lastLoadingConstructListIds",
    "uploadAdditionalAssemblyPieces",
    "assemblyMaterials",
    "assemblyPieceCsv"
  )
)(SelectJ5ConstructList);
