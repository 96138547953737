/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import DNAMaterials from "./DNAMaterials";
import AdditiveMaterials from "./AdditiveMaterials";
import Aliquots from "./Aliquots";
import Samples from "./Samples";
import Strains from "./Strains";

import DataSets from "./DataSets";
import DataTables from "./DataTables";
import Equipment from "./Equipment";
import Jobs from "./Jobs";
import UserRequests from "./UserRequests";
import PlateMapGroups from "./PlateMapGroups";
import Genomes from "./Genomes";
import ReactionMaps from "./ReactionMaps";
import WorkflowRuns from "./WorkflowRuns";
import Worklists from "./Worklists";
import ContainerArrays from "./ContainerArrays";
import Proteins from "./Proteins";
import J5Reports from "./J5Reports";
import LotsInventory from "./LotsInventory";
import { initModelToLibrary } from "../../../src-shared/modelToLibrary";

const buildLibraryToModel = {
  dnaMaterial: DNAMaterials,
  additiveMaterial: AdditiveMaterials,
  aliquot: Aliquots,
  sample: Samples,
  strain: Strains,
  dataSet: DataSets,
  dataTable: DataTables,
  equipmentItem: Equipment,
  customerRequest: UserRequests,
  genome: Genomes,
  job: Jobs,
  plateMapGroup: PlateMapGroups,
  reactionMap: ReactionMaps,
  workflowRun: WorkflowRuns,
  worklist: Worklists,
  aliquotContainer: Aliquots,
  containerArray: ContainerArrays,
  functionalProteinUnit: Proteins,
  j5Report: J5Reports,
  // labProtocol: ,
  lot: LotsInventory
};

initModelToLibrary(buildLibraryToModel);
