/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../GlobalDialog";
export default class TagMenuItem extends Component {
  onClick = () => {
    const { records, refetch, afterTag } = this.props;
    showDialog({
      modalType: "TAG_ITEM",
      modalProps: {
        records,
        afterTag,
        refetch
      }
    });
  };

  render() {
    return <MenuItem onClick={this.onClick} icon="tag" text="Tag" />;
  }
}
