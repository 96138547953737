/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
//THIS POPS UP WHEN A CDS FEATURE IS RIGHT CLICKED
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Callout, H5 } from "@blueprintjs/core";
import { SelectField, tgFormValues } from "@teselagen/ui";
import { reduxForm } from "redux-form";

import EmbossTranslator from "./EmbossTranslator";
import { embossAmbigCodonTables, embossCodonTables } from "./embossCodonTables";
import { delay } from "bluebird";
import { compose } from "recompose";

/**
 * This is the newer back translate dialog that uses the emboss api
 */
function RemoteCodonOptimization({
  Footer,
  aaSeqsToRevTrans,
  handleSubmit,
  change,
  algorithm
}) {
  const ambig = algorithm === "EMBOSS Backtranambig";
  return (
    <React.Fragment>
      <div className="bp3-dialog-body">
        <Callout style={{ marginBottom: 10 }} intent="primary">
          Using the algorithm from EBI: &nbsp;
          {ambig ? (
            <a href="https://www.ebi.ac.uk/Tools/st/emboss_backtranambig/">
              EMBOSS Backtranambig
            </a>
          ) : (
            <a href="https://www.ebi.ac.uk/Tools/st/emboss_backtranseq/">
              EMBOSS Backtranseq
            </a>
          )}
        </Callout>
        <div className="vertical-table-entity">
          <H5>Select An Algorithm</H5>
          <SelectField
            name="algorithm"
            defaultValue="EMBOSS Backtranseq"
            onFieldSubmit={() => {
              change("organism", ambig ? "Eecoli.cut" : 0);
            }}
            options={[
              { label: "EMBOSS Backtranseq", value: "EMBOSS Backtranseq" },
              { label: "EMBOSS Backtranambig", value: "EMBOSS Backtranambig" }
            ]}
          />
        </div>
        <div className="vertical-table-entity">
          <H5>Select A Codon Table</H5>
          <SelectField
            name="organism"
            defaultValue={ambig ? 0 : "Eecoli.cut"}
            options={ambig ? embossAmbigCodonTables : embossCodonTables}
          />
        </div>
      </div>
      <Footer
        onSubmit={handleSubmit(async ({ organism, algorithm }) => {
          const toRet = [];
          for (const { proteinSequence, ...rest } of aaSeqsToRevTrans) {
            delay(100); //should be a 100 ms gap between seqs
            const translator = await new EmbossTranslator();
            const response = await translator.sendReq(
              proteinSequence,
              organism,
              algorithm === "EMBOSS Backtranambig"
            );
            const data = await response.data;

            let reverseTranslatedDna = await data.split("\n");
            reverseTranslatedDna.splice(0, 1);
            reverseTranslatedDna = reverseTranslatedDna.join("");
            toRet.push({ ...rest, proteinSequence, reverseTranslatedDna });
          }
          return [
            toRet,
            {
              //translationInfo goes here
              name: "Emboss Translator",
              description: organism
            }
          ];
        })}
      ></Footer>
    </React.Fragment>
  );
}

export default compose(
  reduxForm({ form: "RemoteCodonOptimization" }),
  tgFormValues("algorithm", "organism")
)(RemoteCodonOptimization);
