/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import widgetCardStyles from "./WidgetCardStyles.module.css";

class WidgetCardHeader extends Component {
  constructor(props) {
    super(props);

    const { title } = props;

    this.state = {
      title: title
    };
  }
  render() {
    const { title } = this.state;

    return (
      <div className={widgetCardStyles.widgetHeader}>
        <h4>{title}</h4>
      </div>
    );
  }
}

export default WidgetCardHeader;
