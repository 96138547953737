/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, NumericInputField, wrapDialog } from "@teselagen/ui";
import { notLessThan } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class EditCopyNumberDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch } = this.props;
    const {
      copyNumber,
      microbialMaterialPlasmidId,
      cellCulturePlasmidId
    } = values;
    try {
      if (microbialMaterialPlasmidId) {
        await safeUpsert("microbialMaterialPlasmid", {
          id: microbialMaterialPlasmidId,
          copyNumber
        });
      } else {
        await safeUpsert("cellCulturePlasmid", {
          id: cellCulturePlasmidId,
          copyNumber
        });
      }
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating copy number.");
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <NumericInputField
            name="copyNumber"
            isRequired
            label="Copy Number"
            min={1}
            normalize={notLessThan(1, { integer: true })}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
          submitting={submitting}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog({ title: "Edit Copy Number" }),
  withRouter,
  reduxForm({
    form: "EditCopyNumber"
  })
)(EditCopyNumberDialog);
