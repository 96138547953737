/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "recompose";
import {
  DataTable,
  withTableParams,
  withSelectedEntities
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { getReactionMapSchema } from "../../../utils/reactionMapUtils";
import { reactionMapRecordReactionFragment } from "./reactionMapRecordFragment";
import useShowReactionsSampleStatus from "../../useShowReactionsSampleStatus";
import LibrarySidePanelWrapper from "../../../../src-shared/AbstractLibrary/LibrarySidePanelWrapper";
import { startCase } from "lodash";
import RecordInfoTable from "../../../../src-shared/RecordInfoDisplay/RecordInfoTable";

function ReactionsTable(props) {
  const {
    tableParams,
    reactionsQuery,
    reactionRecordReactionsTableSelectedEntities: selectedReactions,
    isReactionMapRecordView
  } = props;
  const reactions = tableParams.entities;
  const schema = useMemo(() => {
    if (reactions) {
      return getReactionMapSchema(reactions, { isReactionMapRecordView });
    } else {
      return [];
    }
  }, [reactions, isReactionMapRecordView]);

  if (reactions && reactions.length) {
    reactionsQuery.stopPolling();
  }

  const { sampleStatusInfo, switchField } = useShowReactionsSampleStatus({
    reactions
  });

  let sidePanelInfo;
  if (selectedReactions?.[0]) {
    const reaction = selectedReactions[0];
    const inputMaterials = reaction.reactionInputs.filter(
      input => input.inputMaterial
    );
    const inputAdditiveMaterials = reaction.reactionInputs.filter(
      input => input.inputAdditiveMaterial
    );
    const outputMaterials = reaction.reactionOutputs.filter(
      output => output.outputMaterial
    );
    const outputAdditiveMaterials = reaction.reactionOutputs.filter(
      output => output.outputAdditiveMaterial
    );
    const makeReactionDetailSection = () => {
      const detailToReadableName = {
        oligoMeanTm: "Oligo Mean Tm (°C)",
        oligoDeltaTm: "Oligo Delta Tm (°C)",
        oligoMeanTm3Prime: "Oligo Mean Tm 3' (°C)",
        oligoDeltaTm3Prime: "Oligo Delta Tm 3' (°C)"
      };
      if (!reaction.reactionDetails) return null;
      const recordInfoToUse = [];
      Object.keys(reaction.reactionDetails).forEach(key => {
        recordInfoToUse.push([
          detailToReadableName[key],
          reaction.reactionDetails[key]
        ]);
      });
      return (
        <div style={{ marginTop: 15 }}>
          <h6>Reaction Details</h6>
          <RecordInfoTable sections={[recordInfoToUse]} />
        </div>
      );
    };
    const makeSection = (inputs, header, extraColumns = []) => {
      if (!inputs.length) return null;
      return (
        <div style={{ marginTop: 15 }}>
          <h6 style={{ marginBottom: 10 }}>{header}</h6>
          <DataTable
            formName={`reactionInspector${header}`}
            isSimple
            compact
            schema={[
              {
                displayName: "Name",
                render: (v, input) =>
                  input.inputMaterial?.name ||
                  input.inputAdditiveMaterial?.name ||
                  input.outputMaterial?.name ||
                  input.outputAdditiveMaterial?.name
              },
              ...extraColumns
            ]}
            entities={inputs}
          />
        </div>
      );
    };
    const sourceTypeColumn = {
      displayName: "Source",
      render: (v, input) =>
        startCase(input.reactionInputSourceTypeCode?.toLowerCase())
    };

    const roleTypeColumn = {
      displayName: "Role",
      render: (v, input) => {
        return startCase(input.reactionRoleTypeCode?.toLowerCase());
      }
    };

    sidePanelInfo = (
      <div style={{ padding: 10 }}>
        <h4>Reaction Info</h4>
        {makeSection(inputMaterials.concat(inputAdditiveMaterials), "Inputs", [
          {
            displayName: "Conserved",
            render: (v, input) => (input.conserved ? "Yes" : "No")
          },
          sourceTypeColumn,
          roleTypeColumn
        ])}
        {makeSection(
          outputMaterials.concat(outputAdditiveMaterials),
          "Outputs"
        )}
        {makeReactionDetailSection()}
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          // max width full width - sidebar width
          maxWidth: `calc(100vw - 40px)`
        }}
      >
        <DataTable
          {...tableParams}
          schema={schema}
          sampleStatusInfo={sampleStatusInfo}
          compact
        >
          {switchField}
        </DataTable>
        <LibrarySidePanelWrapper alwaysShowInspector>
          {sidePanelInfo}
        </LibrarySidePanelWrapper>
      </div>
      {schema.hasConserved && <div>(*Not consumed during reaction)</div>}
    </div>
  );
}

const formName = "reactionRecordReactionsTable";
export default compose(
  withTableParams({
    formName,
    urlConnected: false,
    schema: getReactionMapSchema(null, { returnFullSchema: true }),
    additionalFilter: (props, qb) => {
      qb.whereAll({
        reactionMapId: props.reactionMapId
      });
    }
  }),
  withQuery(reactionMapRecordReactionFragment, {
    isPlural: true,
    options: props => {
      return {
        pollInterval: 10000,
        variables: {
          filter: {
            reactionMapId: props.reactionMapId
          }
        }
      };
    }
  }),
  withSelectedEntities(formName)
)(ReactionsTable);
