/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  isSetBackbone,
  getLeafSets
} from "../../../tg-iso-design/selectors/designStateSelectors";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

/**
 * TODO: FIX THIS!
 */

export const cannotChangeIcon = tgCreateCachedSelector(
  state => state,
  (state, binId) => binId,
  // getSetsValidatingSet,
  (state, binId) => {
    // return validators.some(
    return [].some(
      validator =>
        validator.isFlankingValidator &&
        isSetBackbone(state, binId) &&
        isSetBackbone(state, validator.id) &&
        !getLeafSets(state, validator.id).filter(
          s => s.id !== binId && !s.isFixed && isSetBackbone(state, s.id)
        ).length
    );
  }
)((state, binId) => binId);
