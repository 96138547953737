/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { CheckboxField, InfoHelper } from "@teselagen/ui";

function ChangeAdditiveCompositionField({ name }) {
  return (
    <CheckboxField
      name={name}
      label={
        <span>
          Change Additive Composition
          <InfoHelper
            isInline
            style={{ marginLeft: 5 }}
            content="Check this option if you would like to add additives without adjusting the total volume"
          />
        </span>
      }
    />
  );
}

export default ChangeAdditiveCompositionField;
