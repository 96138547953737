/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Redirect } from "react-router-dom";
import { inDevelopment } from "./utils/inDevelopment";
import { privateRoutes as evolvePrivateRoutes } from "../src-evolve/routes/appRoutes";
import makeLazy from "./utils/initHelpers/makeLazy";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import ParallelPartSequenceViewer from "../src-design/components/ParallelPartSequenceViewer";
import CodonMapLibraryContainer from "../src-design/containers/CodonMapLibraryContainer";
import PartLibraryContainer from "../src-design/containers/PartLibraryContainer";
import PartSetLibraryContainer from "../src-design/containers/PartSetLibraryContainer";
import DesignEditContainer from "../src-design/containers/DesignEditContainer";
import DesignTemplateLibraryContainer from "../src-design/containers/DesignTemplateLibraryContainer";
import SchematicEditContainer from "../src-design/containers/SchematicEditContainer";
import RuleSetEditContainer from "../src-design/containers/RuleSetEditContainer";
import SchematicLibraryContainer from "../src-design/containers/SchematicLibraryContainer";
import SharedMicroserviceTaskLibrary from "./SharedMicroserviceTaskLibrary";
import SharedMicroserviceTaskRecordView from "./SharedMicroserviceTaskRecordView";
import PartToSequenceRedirect from "../src-design/components/PartToSequenceRedirect";
import AAPartToAASequenceRedirect from "../src-design/components/AAPartToAASequenceRedirect";
import AminoAcidSequenceAddView from "../src-design/components/AASequence/AddView";
import { SequenceAddView, OligoAddView } from "./TgSequenceEditor/AddView";
import DesignLibrary from "../src-design/components/DesignLibrary";
import AminoAcidPartLibraryContainer from "../src-design/containers/AminoAcidPartLibraryContainer";
import DesignRulesetLibrary from "../src-design/components/DesignRulesetLibrary";
import EmptyDesign from "../src-design/components/EmptyDesign";
import EmptyDesignTemplate from "../src-design/components/EmptyDesignTemplate";
import RerouteToProperSequenceRecordView from "./components/RerouteToProperSequenceRecordView";
import { toolSchemasForLibrary } from "../src-build/components/LimsTools/toolSchemas";
import { reduce } from "lodash";

const toolkits = {
  materialsAndInventoryManagement: {
    "/protein-materials": makeLazy(
      () => import("../src-build/components/Library/ProteinMaterials")
    ),
    "/protein-materials/:id": makeLazy(
      () => import("../src-build/components/Record/ProteinMaterialRecordView")
    ),
    "/cell-cultures": makeLazy(
      () => import("../src-build/components/Library/CellCultures")
    ),
    "/cell-cultures/:id": makeLazy(
      () => import("../src-build/components/Record/MaterialRecordView")
    ),
    "/microbial-materials": makeLazy(
      () => import("../src-build/components/Library/MicrobialMaterials")
    ),
    "/microbial-materials/:id": makeLazy(
      () => import("../src-build/components/Record/MaterialRecordView")
    ),
    "/genomic-materials": makeLazy(
      () => import("../src-build/components/Library/GenomicMaterials")
    ),
    "/genomic-materials/:id": makeLazy(
      () => import("../src-build/components/Record/MaterialRecordView")
    ),
    "/materials": ({ location }) => (
      <Redirect
        to={{
          ...location,
          pathname: "/dna-materials"
        }}
      />
    ),
    "/materials/:id": ({ match, location }) => (
      <Redirect
        to={{
          ...location,
          pathname: `/dna-materials/${match.params.id}`
        }}
      />
    ),
    "/samples": makeLazy(
      () => import("../src-build/components/Library/Samples")
    ),
    "/samples/:id": makeLazy(
      () => import("../src-build/components/Record/SampleRecordView")
    ),
    "/tubes": makeLazy(() => import("../src-build/components/Library/Tubes")),
    "/tubes/:id": makeLazy(
      () => import("../src-build/components/Record/TubeRecordView")
    ),
    "/reagents": makeLazy(
      () => import("../src-build/components/Library/AdditiveMaterials")
    ),
    "/reagent-lots": makeLazy(
      () => import("../src-build/components/Library/LotsInventory")
    ),
    "/reagent-lots/:id": makeLazy(
      () => import("../src-build/components/Record/LotRecordView")
    ),
    "/reagents/:id": makeLazy(
      () => import("../src-build/components/Record/AdditiveMaterialRecordView")
    ),
    "/dna-materials": makeLazy(
      () => import("../src-build/components/Library/DNAMaterials")
    ),
    "/plate-maps": makeLazy(
      () => import("../src-build/components/Library/PlateMapGroups")
    ),
    "/plate-maps/:id": makeLazy(
      () => import("../src-build/components/Record/PlateMapGroupRecordView")
    ),
    "/plates": makeLazy(
      () => import("../src-build/components/Library/ContainerArrays")
    ),
    "/plates/:id": makeLazy(
      () => import("../src-build/components/Record/ContainerArrayRecordView")
    ),
    "/rna-materials": makeLazy(
      () => import("../src-build/components/Library/RNAMaterials")
    ),
    "/dna-materials/:id": makeLazy(
      () => import("../src-build/components/Record/MaterialRecordView")
    ),
    "/rna-materials/:id": makeLazy(
      () => import("../src-build/components/Record/MaterialRecordView")
    ),
    "/aliquots": makeLazy(
      () => import("../src-build/components/Library/Aliquots")
    ),
    "/aliquots/:id": makeLazy(
      () => import("../src-build/components/Record/AliquotRecordView")
    ),
    "/data-sets/:id": makeLazy(
      () => import("../src-build/components/Record/DataSetRecordView")
    ),
    "/data-sets": makeLazy(
      () => import("../src-build/components/Library/DataSets")
    ),
    "/data-tables": makeLazy(
      () => import("../src-build/components/Library/DataTables")
    ),
    "/data-tables/:id": makeLazy(
      () => import("../src-build/components/Record/DataTableRecordView")
    ),
    "/microbial-materials/:id": makeLazy(
      () => import("../src-build/components/Record/MaterialRecordView")
    ),
    "/protein-materials/:id": makeLazy(
      () => import("../src-build/components/Record/ProteinMaterialRecordView")
    )
  },
  workflowManagement: {
    "/jobs": makeLazy(() => import("../src-build/components/Library/Jobs")),
    "/jobs/:id": makeLazy(
      () => import("../src-build/components/Record/JobRecordView")
    ),
    "/user-requests": makeLazy(
      () => import("../src-build/components/Library/UserRequests")
    ),
    "/user-requests/:id": makeLazy(
      () => import("../src-build/components/Record/CustomerRequestRecordView")
    ),
    "/workflow-collections": makeLazy(
      () => import("../src-build/components/Library/WorkflowCollections")
    ),
    "/workflow-collections/:id": makeLazy(
      () => import("./TagAndWorkflowCollectionAndImportCollectionRecordView")
    ),
    "/work-queues": makeLazy(
      () => import("../src-build/components/Library/WorkQueues")
    ),
    "/workflow-definitions/new": makeLazy(
      () => import("../src-build/components/CreateNewWorkflowDefinition")
    ),
    "/workflow-definitions/:id": makeLazy(
      () => import("../src-build/components/NewWorkflowDefinition")
    ),
    "/workflow-definitions": makeLazy(
      () => import("../src-build/components/Library/WorkflowDefinitions")
    ),
    "/workflow-runs/:id": makeLazy(
      () => import("../src-build/components/NewWorkflowRun")
    ),
    "/workflow-runs": makeLazy(
      () => import("../src-build/components/Library/WorkflowRuns")
    ),
    "/protocols/:id": makeLazy(() => import("./ProtocolRecordView")),
    "/protocols": makeLazy(() => import("./Library/Protocols")),
    "/reaction-designs": makeLazy(
      () => import("../src-build/components/Library/ReactionDesigns")
    ),
    "/reaction-designs/:id": makeLazy(
      () => import("../src-build/components/Record/ReactionDesignRecordView")
    ),
    "/reaction-maps": makeLazy(
      () => import("../src-build/components/Library/ReactionMaps")
    ),
    "/reaction-maps/:id": makeLazy(
      () => import("../src-build/components/Record/ReactionMapRecordView")
    ),
    "/workflow-collections": makeLazy(
      () => import("../src-build/components/Library/WorkflowCollections")
    ),
    "/workflow-collections/:id": makeLazy(
      () => import("./TagAndWorkflowCollectionAndImportCollectionRecordView")
    ),
    "/worklists": makeLazy(
      () => import("../src-build/components/Library/Worklists")
    ),
    "/worklists/:id": makeLazy(
      () => import("../src-build/components/Record/WorklistRecordView")
    )
  },
  labAutomation: {
    "/dna-sequencing-quality-control-reports": makeLazy(
      () =>
        import(
          "../src-build/components/Library/SequencingQualityControlReports"
        )
    ),
    "/dna-sequencing-quality-control-reports/:id": makeLazy(
      () =>
        import("../src-build/components/Record/SequencingQCReportRecordView")
    ),
    "/reports": makeLazy(
      () => import("../src-build/components/Library/Reports")
    ),
    "/reports/:id/:filterId?": makeLazy(
      () => import("../src-build/components/Record/ReportRecordView")
    ),
    "/placement-queues": makeLazy(
      () => import("../src-build/components/PlacementQueueInventory")
    ),
    "/placement-queues/:id": makeLazy(
      () => import("../src-build/components/PlacementQueueRecordView")
    )
  },
  dataAnalysis: {
    "/subjects": makeLazy(
      () => import("../src-test/views/subjects/SubjectLibrary")
    ),
    "/subjects/:id": makeLazy(
      () => import("../src-test/views/subjects/SubjectRecordView")
    ),
    "/data-grids": makeLazy(() => import("./DataGrids/DataGridLibrary")),
    "/data-grids/:id": makeLazy(() => import("./DataGrids/DataGridRecordView")),
    "/assays/:id": makeLazy(
      () => import("../src-test/views/assays/AssayRecordView")
    ),
    "/assays/:assayId/statistical-analyses/:statisticalId": makeLazy(
      () =>
        import(
          "../src-test/views/dataAnalysis/statisticalAnalysis/StatisticalAnalysisView"
        )
    ),
    "/assays": makeLazy(() => import("../src-test/views/assays/AssayLibrary")),
    "/assay-import-jobs": makeLazy(
      () => import("../src-test/views/importJobs/ImportJobLibrary")
    ),
    "/data-grids": makeLazy(() => import("./DataGrids/DataGridLibrary")),
    "/data-grids/:id": makeLazy(() => import("./DataGrids/DataGridRecordView"))
  },
  freezerEquipmentManagement: {
    "/placement-strategies": makeLazy(
      () => import("../src-build/components/Library/PlacementStrategies")
    ),
    "/placement-strategies/:id": makeLazy(
      () => import("../src-build/components/Record/PlacementStrategyRecordView")
    ),

    "/locations": makeLazy(
      () => import("../src-build/components/Library/Locations")
    ),
    "/equipment": makeLazy(
      () => import("../src-build/components/Library/Equipment")
    ),
    "/equipment/:id": makeLazy(
      () => import("../src-build/components/Record/EquipmentRecordView")
    ),
    "/equipment-items": ({ location }) => {
      return (
        <Redirect
          to={{
            ...location,
            pathname: location.pathname.replace("-items", "")
          }}
        />
      );
    },
    "/equipment-items/:id": ({ location }) => {
      return (
        <Redirect
          to={{
            ...location,
            pathname: location.pathname.replace("-items", "")
          }}
        />
      );
    },
    "/containers/:id": makeLazy(
      () => import("../src-build/components/Record/ContainerRecordView")
    ),
    "/containers": makeLazy(
      () => import("../src-build/components/Library/Containers")
    ),
    "/new-freezer": makeLazy(
      () => import("../src-build/components/FreezerSetup")
    )
  },
  discover: evolvePrivateRoutes,
  integrations: {}
};
export const appRoutes = {
  ...reduce(
    toolkits,
    (acc, val, key) => {
      let toRet = acc;
      if (!window.frontEndConfig.disabledToolkits[key]) {
        toRet = {
          ...acc,
          ...val
        };
      }
      return toRet;
    },
    {}
  ),
  "/eln": () => {
    return <Redirect to={"/eln/welcome"} />;
  },
  "/eln/:notebookId": makeLazy(() => import("../src-build/eln")),
  "/eln/:notebookId/:entryId": makeLazy(() => import("../src-build/eln")),
  "/microbial-strains": makeLazy(
    () => import("../src-build/components/Library/Strains")
  ),
  "/microbial-strains/:id": makeLazy(
    () => import("../src-build/components/Record/StrainRecordView")
  ),
  "/genomes": makeLazy(() => import("../src-build/components/Library/Genomes")),
  "/genomes/:id": makeLazy(
    () => import("../src-build/components/Record/GenomeRecordView")
  ),
  "/genomic-regions": makeLazy(
    () => import("../src-build/components/Library/GenomicRegions")
  ),
  "/cell-lines": makeLazy(
    () => import("../src-build/components/Library/CellLines")
  ),
  "/cell-lines/:id": makeLazy(
    () => import("../src-build/components/Record/CellLineRecordView")
  ),
  "/functional-protein-units": makeLazy(
    () => import("../src-build/components/Library/Proteins")
  ),
  "/functional-protein-units/:id": makeLazy(
    () => import("../src-build/components/Record/ProteinRecordView")
  ),
  "/designs": DesignLibrary,
  "/designs/new": EmptyDesign,
  "/designs/:id": DesignEditContainer,
  "/design-rulesets": DesignRulesetLibrary,
  "/design-rulesets/:id": RuleSetEditContainer,
  "/amino-acid-sequences/new": AminoAcidSequenceAddView,
  "/amino-acid-parts": AminoAcidPartLibraryContainer,
  "/amino-acid-parts/:id": AAPartToAASequenceRedirect,
  "/codon-maps": CodonMapLibraryContainer,
  "/design-templates": DesignTemplateLibraryContainer,
  "/design-templates/new": EmptyDesignTemplate,
  "/design-templates/:id": DesignEditContainer,
  "/parts": PartLibraryContainer,
  "/rna-parts": props => <PartLibraryContainer {...props} isRNA />,
  "/parts/:id": PartToSequenceRedirect,
  "/part-sets/:partSetId?": PartSetLibraryContainer,
  "/dna-sequences/new": SequenceAddView,
  "/rna-sequences/new": props => <SequenceAddView {...props} isRNA />,
  "/oligos/new": OligoAddView,
  "/schematics": SchematicLibraryContainer,
  "/schematics/:id": SchematicEditContainer,
  "/massively-parallel-part-creation/:id": ParallelPartSequenceViewer,
  "/microservice-tasks": SharedMicroserviceTaskLibrary,
  "/microservice-tasks/:id": SharedMicroserviceTaskRecordView,
  "/": makeLazy(() => import("../src-build/components/WelcomePage")),
  "/amino-acid-sequences/:id": makeLazy(() => import("./AminoAcidRecordView")),
  "/region-annotations": makeLazy(
    () => import("../src-build/components/Library/RegionAnnotations")
  ),
  "/region-annotations/:id": makeLazy(
    () => import("../src-build/components/Record/RegionAnnotationRecordView")
  ),
  "/assembly-reports": makeLazy(
    () => import("../src-build/components/Library/J5Reports")
  ),
  "/assembly-reports/:id": makeLazy(
    () => import("../src-build/components/Record/J5ReportRecordView")
  ),
  "/guide-rna-score-prediction-runs": makeLazy(
    () => import("../src-build/components/Library/GuideRNAScorePredictionRuns")
  ),
  "/guide-rna-score-prediction-runs/:id": makeLazy(
    () =>
      import(
        "../src-build/components/Record/GuideRNAScorePredictionRunRecordView"
      )
  ),
  "/crispr-designs": makeLazy(
    () => import("../src-build/components/Library/CRISPRDesigns")
  ),
  "/crispr-designs/:id": makeLazy(
    () => import("../src-build/components/Record/CRISPRDesignRecordView")
  ),
  "/strains": ({ location }) => (
    <Redirect
      to={{
        ...location,
        pathname: "/microbial-strains"
      }}
    />
  ),
  "/strains/:id": ({ match, location }) => (
    <Redirect
      to={{
        ...location,
        pathname: `/microbial-strains/${match.params.id}`
      }}
    />
  ),
  "/dna-sequences/:id": makeLazy(() => import("./DNASequenceRecordView")),
  "/oligos": makeLazy(() => import("../src-build/components/Library/Oligos")),
  "/oligos/:id": makeLazy(() => import("./DNASequenceRecordView")),
  "/coding-dna-sequences": makeLazy(
    () => import("../src-build/components/Library/CodingDNASequences")
  ),
  "/rna-sequences/:id": makeLazy(() => import("./DNASequenceRecordView")),
  "/sequence-features": makeLazy(() => import("./Library/SequenceFeatures")),
  "/sequence-features/:id": makeLazy(
    () => import("./SequenceFeatureRecordView")
  ),

  "/orders": makeLazy(
    () => import("../src-build/components/Library/VendorOrders")
  ),
  "/orders/:id": makeLazy(
    () => import("../src-build/components/Record/VendorOrderRecordView")
  ),

  "/functional-protein-units": makeLazy(
    () => import("../src-build/components/Library/Proteins")
  ),
  "/functional-protein-units/:id": makeLazy(
    () => import("../src-build/components/Record/ProteinRecordView")
  ),

  "/label-formats/new": makeLazy(
    () =>
      import(
        "../src-build/components/AppSettings/LabelSettings/LabelFormatPanel/LabelFormatEdit"
      )
  ),
  "/label-formats/:id": makeLazy(
    () =>
      import(
        "../src-build/components/AppSettings/LabelSettings/LabelFormatPanel/LabelFormatEdit"
      )
  ),

  "/microservice-tasks": makeLazy(
    () => import("./SharedMicroserviceTaskLibrary")
  ),
  "/microservice-tasks/:id": makeLazy(
    () => import("./SharedMicroserviceTaskRecordView")
  ),

  "/bioshop/": makeLazy(() => import("../src-build/components/Bioshop")),
  // add test pages for component tests

  "/cas-enzymes": makeLazy(
    () => import("../src-build/components/Library/CasEnzymes")
  ),
  "/cas-enzymes/:id": makeLazy(
    () => import("../src-build/components/Record/CasEnzymeRecordView")
  ),
  "/guide-rna-sequences": makeLazy(
    () => import("../src-build/components/Library/GuideRNASequences")
  ),
  "/guide-rna-sequences/:id": makeLazy(
    () => import("../src-build/components/Record/GuideRNARecordView")
  ),
  "/genomic-reference-sequences": makeLazy(
    () => import("../src-build/components/Library/GenomicReferenceSequences")
  ),
  "/genomic-reference-sequences/:id": makeLazy(
    () =>
      import(
        "../src-build/components/Record/GenomicReferenceSequenceRecordView"
      )
  ),
  "/notifications": makeLazy(
    () => import("./NotificationUI/NotificationLibrary")
  ),
  "/settings": () => <Redirect to="/settings/profile" />,
  "/settings/:section/:subsection?": makeLazy(
    () => import("./SharedAppSettings")
  ),
  "/restriction-enzymes": () => <Redirect to="/settings/enzyme-management" />,
  "/restriction-enzymes/:id": ({ match }) => (
    <Redirect
      to={`/settings/enzyme-management/enzymes?filters=id__equalTo__${match.params.id}`}
    />
  ),
  "/alignments": makeLazy(() => import("./Library/Alignments")),
  "/alignments/new": makeLazy(() => import("./AlignmentAddContainer")),
  "/alignments/:id": makeLazy(() => import("./AlignmentViewContainer")),
  "/amino-acid-sequences": makeLazy(() => import("./Library/AminoAcids")),
  "/import-collections": makeLazy(() => import("./ImportCollections")),
  "/import-collections/:id": makeLazy(
    () => import("./TagAndWorkflowCollectionAndImportCollectionRecordView")
  ),
  "/tags": makeLazy(() => import("./TagsLibrary")),
  "/tags/:id": makeLazy(
    () => import("./TagAndWorkflowCollectionAndImportCollectionRecordView")
  ),
  "/tools": makeLazy(() => import("./ToolLibrary")),
  ...toolSchemasForLibrary.reduce((acc, schema) => {
    const { Component, tabId } = schema;
    acc[`/tools/${tabId}`] = props => (
      <Component {...props} toolSchema={schema} />
    );
    return acc;
  }, {}),
  "/sequences/:id": RerouteToProperSequenceRecordView,
  "/sequences": makeLazy(() => import("./Library/Sequences/DNASequences")),
  "/dna-sequences": makeLazy(() => import("./Library/Sequences/DNASequences")),
  "/rna-sequences": makeLazy(() => import("./Library/Sequences/RNASequences")),
  ...(window.Cypress && {
    "/test-page-1": makeLazy(
      () => import("../src-build/components/__TestPages/Page1")
    ),
    "/test-page-2": makeLazy(
      () => import("../src-build/components/__TestPages/Page2")
    ),
    "/test-page-3": makeLazy(
      () => import("../src-build/components/__TestPages/Page3")
    ),
    "/test-page-4": makeLazy(
      () => import("../src-build/components/__TestPages/Page4")
    ),
    "/table-scroll": makeLazy(
      () =>
        import("../src-build/components/__TestPages/TableScrollOnPageChange")
    ),
    "/editable-cell-table": makeLazy(
      () => import("../src-build/components/__TestPages/EditableCellTable")
    ),
    "/stacked-dialog": makeLazy(
      () => import("../src-build/components/__TestPages/TestStackedDialogs")
    ),
    "/table-select": makeLazy(
      () => import("../src-build/components/__TestPages/TableSelect")
    ),
    "/table-select-all-records": makeLazy(
      () => import("../src-build/components/__TestPages/TableSelectAllRecords")
    ),
    "/apollo-optimistic": makeLazy(
      () => import("../src-build/components/__TestPages/ApolloOptimistic")
    )
  }),
  ...(inDevelopment && {
    "/workflow-report": makeLazy(
      () =>
        import(
          "../src-build/components/NewWorkflowRun/workflowRunReport/ReportMockViewer"
        )
    ),
    "/test-plate-layout": makeLazy(
      () => import("../src-build/components/__TestPages/TestPlateLayout")
    )
  })
};
