/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InputField, ReactSelectField, CheckboxField } from "@teselagen/ui";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";

function NewColumnPlateInfo({
  sourcePlateKey,
  containerArrayTypes = [],
  newColumnPlateInfo = {},
  destinationFormatCode
}) {
  const newColumnPlateInfoForPlate = newColumnPlateInfo[sourcePlateKey] || {};
  const filteredContainerArrayTypes = containerArrayTypes.filter(
    containerArrayType => {
      const properFormat =
        containerArrayType.containerFormatCode === destinationFormatCode;
      return containerArrayType.isColumn && properFormat;
    }
  );
  return (
    <div style={{ width: "50%" }}>
      <InputField
        name={`newColumnPlateInfo.${sourcePlateKey}.name`}
        isRequired
        label="Column Plate Name"
        placeholder="Enter destination plate name..."
      />
      <CheckboxField
        name={`newColumnPlateInfo.${sourcePlateKey}.generateBarcode`}
        label="Generate Barcodes For Column Plate"
        defaultValue
      />
      {!newColumnPlateInfoForPlate.generateBarcode && (
        <InputField
          name={`newColumnPlateInfo.${sourcePlateKey}.barcode`}
          label="Column Plate Barcode"
        />
      )}
      <ReactSelectField
        name={`newColumnPlateInfo.${sourcePlateKey}.plateType`}
        label="Column Plate Type"
        isRequired
        enableReinitialize
        options={arrayToItemValuedOptions(filteredContainerArrayTypes)}
      />
    </div>
  );
}

export default stepFormValues("newColumnPlateInfo")(NewColumnPlateInfo);
