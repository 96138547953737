/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { assignedPositionFragment } from "./assignedPositionFragment.gql";
import { extendedValueLibraryDisplayFragment } from "../../../src-shared/fragments/extendedValueLibraryDisplayFragment.gql";
import { taggedItemFragment } from "./taggedItemFragment.gql";
import { projectItemFragment } from "../../../src-shared/fragments/projectItemFragment.gql";

const containerArrayRecordViewAdditiveFragment = gql`
  fragment containerArrayRecordViewAdditiveFragment on additive {
    id
    volume
    volumetricUnitCode
    additiveMaterial {
      id
      name
      evaporable
    }
    lot {
      id
      name
      additiveMaterial {
        id
        name
      }
    }
  }
`;

export const containerArrayRecordViewAliquotContainerFragment = gql`
  fragment containerArrayRecordViewAliquotContainerFragment on aliquotContainer {
    id
    name
    aliquotContainerType {
      code
      name
      maxVolume
      volumetricUnitCode
    }
    barcode {
      id
      barcodeString
    }
    additives {
      ...containerArrayRecordViewAdditiveFragment
    }
    columnPosition
    rowPosition
    aliquot {
      id
      isDry
      mass
      massUnitCode
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      molarity
      molarityUnitCode
      cellCount
      cellConcentration
      cellConcentrationUnitCode
      additives {
        ...containerArrayRecordViewAdditiveFragment
      }
      importCollection {
        id
        name
      }
      extendedStringValueViews {
        ...extendedValueLibraryDisplayFragment
      }
      taggedItems {
        ...taggedItemFragment
      }
      projectItems {
        ...projectItemFragment
      }
      lab {
        id
        name
      }
      user {
        id
        username
      }
      updatedAt
      createdAt
      sample {
        id
        name
        sampleTypeCode
        sampleStatusCode
        sampleStatus {
          code
          name
        }
        material {
          id
          name
          functionalProteinUnit {
            id
            molecularWeight
          }
          polynucleotideMaterialSequence {
            id
            molecularWeight
            size
            sequenceType {
              code
              name
            }
          }
        }
        sampleFormulations {
          id
          materialCompositions {
            id
            materialId
            material {
              id
              name
            }
          }
        }
      }
    }
  }
  ${containerArrayRecordViewAdditiveFragment}
  ${extendedValueLibraryDisplayFragment}
  ${taggedItemFragment}
  ${projectItemFragment}
`;

export const containerArrayRecordFragment = gql`
  fragment containerArrayRecordFragment on containerArray {
    id
    name
    collectionPlateId
    collectionPlate {
      id
      name
    }
    labelFormatId
    containerArrayType {
      id
      name
      isPlate
      isColumn
      manufacturer
      catalogNumber
      containerFormat {
        code
        is2DLabeled
        rowCount
        columnCount
      }
      nestableTubeTypes {
        id
        aliquotContainerType {
          code
          name
        }
      }
      aliquotContainerType {
        code
        name
        bottom
        maxVolume
        volumetricUnitCode
      }
    }
    assignedPosition {
      ...assignedPositionFragment
    }
    aliquotContainers {
      ...containerArrayRecordViewAliquotContainerFragment
    }
  }
  ${assignedPositionFragment}
  ${containerArrayRecordViewAliquotContainerFragment}
`;

export const containerArrayRecordViewAssignTubeFragment = gql`
  fragment containerArrayRecordViewAssignTubeFragment on aliquotContainer {
    ...containerArrayRecordViewAliquotContainerFragment
    containerArray {
      ...containerArrayRecordFragment
    }
  }
  ${containerArrayRecordViewAliquotContainerFragment}
  ${containerArrayRecordFragment}
`;
