/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { generateFragmentWithFields } from "@teselagen/apollo-methods";

const aliquotFragment = `
id
isDry
volume
volumetricUnitCode
concentration
concentrationUnitCode
mass
massUnitCode
sample {
  id
  name
  sampleStatusCode
  sampleStatus {
    code
    name
  }
  material {
    id
    name
  }
  sampleFormulations {
    id
    materialCompositions {
      id
      material {
        id
        name
      }
    }
  }
}
`;

export const plateFragment = generateFragmentWithFields(
  "containerArray",
  `
  id
  name
  barcode {
    id
    barcodeString
  }
  containerArrayTypeId
  containerArrayType {
    id
    name
    containerFormatCode
    containerFormat {
      code
      rowCount
      columnCount
      quadrantSize
    }
  }
  aliquotContainers {
    id
    aliquotContainerTypeCode
    rowPosition
    columnPosition
    aliquot {
      ${aliquotFragment}
    }
  }
`
);

export const tableFragment = [
  "dataTable",
  `id
    name
    dataTableTypeCode
    dataRows {
      id
      index
      key
      rowValues
    }`
];
