/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { uniq } from "lodash";
import React from "react";
import { ReactSelectField, throwFormError } from "@teselagen/ui";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import SampleQCDataTableSelect from "../../SampleQCDataTableSelect";
import { keyBy } from "lodash";
import { find } from "lodash";
import { identity } from "lodash";

const samplePropagationParentFragment = gql`
  fragment propagateSampleStatusFragment on sample {
    id
    sampleFormulations {
      id
      aliquot {
        id
        sampleId
      }
    }
  }
`;

async function getLevelOfSampleIds(
  sampleIds,
  parentSampleIds = [],
  parentMap = {},
  level = 1
) {
  const currentLevelParentIds = [];
  const samplesWithFormulations = await safeQuery(
    samplePropagationParentFragment,
    {
      variables: {
        filter: {
          id: sampleIds
        }
      }
    }
  );
  if (level === 1) {
    sampleIds.forEach(id => {
      parentMap[id] = [];
    });
  }
  samplesWithFormulations.forEach(sample => {
    sample.sampleFormulations.forEach(formulation => {
      const parentId = formulation.aliquot?.sampleId;
      if (parentId) {
        if (level === 1) {
          parentMap[sample.id].push(parentId);
          currentLevelParentIds.push(parentId);
        } else {
          const arr = find(parentMap, arr => arr.includes(sample.id));
          if (arr && !arr.includes(parentId)) {
            arr.push(parentId);
            currentLevelParentIds.push(parentId);
          }
        }
      }
    });
  });
  const allParentIds = [...parentSampleIds, ...currentLevelParentIds];
  let didNotReachBaseLevel = false;
  if (currentLevelParentIds.length > 0) {
    if (level <= 10) {
      return getLevelOfSampleIds(
        currentLevelParentIds,
        allParentIds,
        parentMap,
        level + 1
      );
    } else if (level > 10) {
      didNotReachBaseLevel = true;
    }
  }
  return { parentIds: allParentIds, parentMap, didNotReachBaseLevel };
}

export const propagateSampleStatusDataTableFragment = gql`
  fragment propagateSampleStatusDataTableFragment on dataTable {
    id
    name
    createdAt
    updatedAt
    dataSetId
    dataTableTypeCode
    dataRows {
      id
      rowValues
    }
    dataTableType {
      code
      name
      rowSchema
    }
  }
`;

function PropagateSampleStatus(props) {
  const {
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
    toolSchema,
    stepFormProps: { change },
    Footer,
    footerProps,
    handleSubmit,
    nextStep
  } = props;

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Sample Propagation Presets"
          helper="Choose one of the presets below."
        />
        <div style={{ maxWidth: 500 }}>
          <ReactSelectField
            name="samplePropagationPreset"
            // will want an on field submit here
            label="Sample Propagation Preset"
            options={[
              { label: "Parent Plasmids", value: "plasmid" },
              {
                label: "Parent Transformed Microbes",
                value: "microbe"
              }
            ]}
          />
        </div>
      </div>
      <SampleQCDataTableSelect
        toolSchema={toolSchema}
        isDisabledMap={isDisabledMap}
        isLoadingMap={isLoadingMap}
        helperText={`Select one or more data tables of samples (output by the Sample QC Tool).
          Based on the selected preset above, parent samples will be queried and have
          their status updated accordingly.`}
        additionalDataFragment={propagateSampleStatusDataTableFragment}
      />
      <Footer
        {...footerProps}
        onClick={handleSubmit(async values => {
          try {
            const { dataTables, samplePropagationPreset } = values;
            let baseLevelSampleIds = [];
            dataTables.forEach(table => {
              table.dataRows.forEach(row => {
                if (row.rowValues.sampleId) {
                  baseLevelSampleIds.push(row.rowValues.sampleId);
                }
              });
            });
            baseLevelSampleIds = uniq(baseLevelSampleIds);
            const {
              parentIds,
              parentMap,
              didNotReachBaseLevel
            } = await getLevelOfSampleIds(baseLevelSampleIds);
            const baseLevelSamples = await safeQuery(
              ["sample", "id name sampleStatusCode"],
              {
                variables: {
                  filter: {
                    id: baseLevelSampleIds
                  }
                }
              }
            );
            let parentSamples = await safeQuery(
              [
                "sample",
                /* GraphQL */ `
                  {
                    id
                    name
                    material {
                      id
                      name
                      materialTypeCode
                      polynucleotideMaterialSequence {
                        id
                        sequenceTypeCode
                      }
                      microbialMaterialMicrobialMaterialPlasmids {
                        id
                      }
                    }
                  }
                `
              ],
              {
                variables: {
                  filter: {
                    id: parentIds
                  }
                }
              }
            );
            if (samplePropagationPreset === "plasmid") {
              parentSamples = parentSamples.filter(
                sample =>
                  sample.material.polynucleotideMaterialSequence
                    ?.sequenceTypeCode === "CIRCULAR_DNA"
              );
            } else if (samplePropagationPreset === "microbe") {
              parentSamples = parentSamples.filter(
                sample =>
                  sample.material.materialTypeCode === "MICROBIAL" &&
                  sample.material.microbialMaterialMicrobialMaterialPlasmids
                    ?.length > 0
              );
            }
            if (didNotReachBaseLevel) {
              window.toastr.warning(
                "There were too many levels of parent samples. Stopped at 10 generations."
              );
            }
            const keyedParentSamples = keyBy(parentSamples, "id");

            const sampleIdToParentList = {};
            baseLevelSamples.forEach(sample => {
              sampleIdToParentList[sample.id] = (parentMap[sample.id] || [])
                .map(parentId => {
                  return keyedParentSamples[parentId];
                })
                .filter(identity);
            });

            change("baseLevelSamples", baseLevelSamples);
            change("sampleIdToParentList", sampleIdToParentList);
            if (
              Object.keys(sampleIdToParentList).every(
                key => !sampleIdToParentList[key].length
              )
            ) {
              throw new Error(
                "No parent samples found for any of these samples."
              );
            }

            nextStep();
          } catch (error) {
            throwFormError(error.message || "Error during submission");
            console.error(`error:`, error);
          }
        })}
      />
    </React.Fragment>
  );
}

export default PropagateSampleStatus;
