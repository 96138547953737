/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isCsvFile } from "../../../../tg-iso-shared/src/utils/fileUtils";
import { dataValidator } from "../../utils/experimentData/validators";
import { FileUploadField } from "@teselagen/ui";
import TagField from "../../TagField";
import { getDownloadTemplateFileHelpers } from "../DownloadTemplateFileButton";
import HeaderWithHelper from "../../HeaderWithHelper";
import { unparse } from "papaparse";

function ExperimentalDataFileUpload(props) {
  const {
    name = "uploadedFiles",
    isRequired,
    onRemove,
    templateFileName,
    template: { headers, data: templateData } = {},
    acceptTypes = [".csv", ".xlsx"],
    beforeUpload,
    headerTitle = "Select Data",
    headerHelper = "Upload your data file.",
    withTags = false,
    fileLimit = null,
    /**
     * These are yup validators and are optional (a default validator is applied regardless)
     * Pass them in if you want to apply additional validations on either the headers or rows.
     * NOTE: these are only applied to CSV files, and the 'headerValidator' applies to to be the very first row,
     * while 'rowsValidator' to be the subsequent rows.
     *
     * CSV data is parsed using papaparse, and its content is formatted with the header:true format,
     * which puts the content in an array of {columnName: cellValue} objects.
     *
     * Example:
     *
     * CSV:
     *      ColumnA | ColumnB
     *       value1   value2
     *       value3   value4
     *
     * Parsed:
     *
     * [
     *   {ColumnA: value1, ColumnB: value2},
     *   {ColumnA: value3, ColumnB: value4}
     * ]
     *
     * refer to 'client/src-shared/components/ExperimentalDataFileUpload/validators.js' for more information.
     */
    validators: { headerValidator, rowValidator, columnValidator, options } = {}
  } = props;

  const _validateFiles = async files => {
    const validation = [];

    // If no file is found return false
    if (files.length === 0) return false;

    // If there are files, validate them
    try {
      for (const file of files) {
        if (isCsvFile(file)) {
          // The validator currently supports csv files only,
          const validationResult = await dataValidator({
            csvFile: file,
            validators: {
              headerValidator,
              columnValidator,
              rowValidator,
              options
            }
          });
          validation.push(validationResult);
        } else {
          validation.push({ isValid: true, validationErrors: [] });
        }
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error("Error validating CSV file.");
    }
    return validation;
  };

  const _onRemove = async (removed, removed_index, originalFiles) => {
    const files = originalFiles.filter(
      (file, index) => index !== removed_index
    );

    try {
      const validation = await _validateFiles(files);
      if (onRemove) {
        return await onRemove(files, validation);
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error("Error on validation while removing file.");
    }
    return true;
  };

  const _beforeUpload = async files => {
    // If no file is found return false
    if (files.length === 0) return false;
    try {
      const validation = await _validateFiles(files);
      if (beforeUpload) {
        return await beforeUpload(files, validation);
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error("Error parsing CSV file.");
    }
    return true;
  };

  const templateContentString = () => {
    const csvData = {
      fields: headers,
      ...(templateData && { data: templateData })
    };
    const csvString = unparse(csvData);
    return csvString;
  };

  return (
    <div className="tg-flex justify-space-between">
      <HeaderWithHelper header={headerTitle} helper={headerHelper} />
      <div>
        <div className="tg-flex align-center">
          <FileUploadField
            fileLimit={fileLimit}
            isRequired={isRequired}
            accept={getDownloadTemplateFileHelpers({
              type: acceptTypes,
              fileName: templateFileName,
              headers: headers,
              fileContents: templateContentString(),
              helper: ""
            })}
            name={name}
            innerText="Click or drag to upload data files"
            beforeUpload={_beforeUpload}
            onRemove={_onRemove}
          />
        </div>
        {withTags ? <TagField /> : null}
      </div>
    </div>
  );
}

export default ExperimentalDataFileUpload;
