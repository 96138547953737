/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import {
  InputField,
  ReactSelectField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import QueryBuilder from "tg-client-query-builder";
import { Button, Intent, Classes } from "@blueprintjs/core";
import { noop, pick, get } from "lodash";

import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import CreateOrGenerateBarcodeField from "../../CreateOrGenerateBarcodeField";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";

class CreateNewTubeUnwrapped extends Component {
  onSubmit = async values => {
    const {
      upsertFragment,
      afterCreate = noop,
      refetch = noop,
      hideModal = noop,
      initialValues: { barcode: initialBarcode } = {}
    } = this.props;
    const initialBarcodeString = initialBarcode && initialBarcode.barcodeString;
    const { generateBarcode, userAddedBarcode, barcode, labId, ...tubeValues } =
      values;
    const newTube = { ...tubeValues };
    if (!generateBarcode && userAddedBarcode) {
      newTube.barcode = {
        barcodeString: userAddedBarcode
      };
    }
    try {
      const [tube] = await safeUpsert(
        upsertFragment || "aliquotContainer",
        newTube
      );

      if (barcode && barcode.barcodeString !== initialBarcodeString) {
        await safeUpsert("barcode", {
          ...pick(barcode, ["id", "barcodeString"]),
          aliquotContainerId: tube.id
        });
      }
      if (generateBarcode && !values.id) {
        await addBarcodesToRecords(tube);
      }
      await afterCreate(tube);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating tube");
    }
  };

  render() {
    const {
      aliquotContainerTypes,
      hideModal,
      submitting,
      isUpdate,
      previousPage,
      handleSubmit,
      initialValues = {}
    } = this.props;

    return (
      <form>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            label="Name"
            name="name"
            placeholder="Enter tube name"
            isRequired
          />
          <CreateOrGenerateBarcodeField initialValues={initialValues} />
          {!isUpdate && (
            <ReactSelectField
              label="Type"
              isRequired
              name="aliquotContainerTypeCode"
              placeholder="Select a type"
              options={arrayToIdOrCodeValuedOptions(aliquotContainerTypes)}
            />
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          additionalButtons={
            !isUpdate &&
            previousPage && (
              <Button
                intent={Intent.PRIMARY}
                onClick={previousPage}
                text="Back"
              />
            )
          }
          onClick={handleSubmit(this.onSubmit)}
        />
      </form>
    );
  }
}

const qb = new QueryBuilder("aliquotContainerType");
const filter = qb
  .whereAll({
    isTube: true
  })
  .toJSON();

export const withTubeTypes = withQuery(aliquotContainerTypeFragment, {
  isPlural: true,
  options: {
    variables: {
      filter
    }
  }
});

export const CreateNewTube = compose(
  reduxForm({
    form: "CreateNewTube"
  }),
  withTubeTypes
)(CreateNewTubeUnwrapped);

export default wrapDialog({
  getDialogProps: props => {
    return {
      title: get(props, "initialValues.id") ? "Edit Tube" : "Create Tube"
    };
  }
})(CreateNewTube);
