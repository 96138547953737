/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// the getters will help throw errors when these are not properly passed in to functions
const context = {
  get safeQuery() {
    throw new Error("safeQuery was not set up. Should not use isoContext.");
  },
  get safeUpsert() {
    throw new Error("safeUpsert was not set up. Should not use isoContext.");
  },
  get safeDelete() {
    throw new Error("safeDelete was not set up. Should not use isoContext.");
  },
  get deleteWithQuery() {
    throw new Error(
      "deleteWithQuery was not set up. Should not use isoContext."
    );
  },
  clear() {
    delete this.safeQuery;
    delete this.safeUpsert;
    delete this.safeDelete;
    delete this.deleteWithQuery;
  }
};

export default context;
