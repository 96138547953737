/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DataTable } from "@teselagen/ui";

import { Button, Intent } from "@blueprintjs/core";
import { wrapDialog } from "@teselagen/ui";

const schema = {
  model: "none",
  fields: [
    { path: "term", width: 200, type: "string", displayName: "Term" },
    { path: "description", type: "string", displayName: "Description" }
  ]
};

class GlossaryDialog extends React.Component {
  getEntities() {
    let content = [];
    glossaryTerms
      .sort((a, b) => a.term.localeCompare(b.term))
      .forEach(gt => {
        content.push(gt);
        content = content.concat(gt.subterms);
      });
    return content;
  }

  cellRenderer = {
    term: term => <strong>{term}</strong>,
    description: description => (
      <div style={{ whiteSpace: "normal" }}>{description}</div>
    )
  };

  render() {
    const { hideModal } = this.props;
    return (
      <React.Fragment>
        <div className="bp3-dialog-body">
          <DataTable
            formName="hdeGlossary"
            isLoading={false}
            isSimple
            cellRenderer={this.cellRenderer}
            urlConnected={false}
            schema={schema}
            entities={this.getEntities()}
            noSelect
          />
        </div>

        <div
          className="bp3-dialog-footer"
          style={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <Button
            minimal
            text="Close"
            intent={Intent.PRIMARY}
            onClick={hideModal}
            type="submit"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default wrapDialog({
  title: "Glossary of Terms",
  style: {
    width: "80%"
  }
})(GlossaryDialog);

const glossaryTerms = [
  {
    term: "Feature",
    description:
      "An annotation on a sequence (cannot be inserted into a design for assembly)",
    subterms: []
  },
  {
    term: "Part",
    description:
      "A special type of annotation on a sequence that can be inserted into a design for assembly",
    subterms: []
  },
  {
    term: "Unmapped Part",
    description: "A part that hasn't yet been associated with any DNA",
    subterms: []
  },
  {
    term: "Part Set",
    description:
      "A grouping of parts that can be inserted into a design (one cell represents many parts)",
    subterms: []
  },
  {
    term: "Base Pairs",
    description:
      "Typed-in base pairs of DNA that can be inserted into a design as a part without defining it first on a sequence",
    subterms: []
  },
  {
    term: "Assembly Report",
    description:
      "A report generated by running a Design. It contains instructions for combining input parts into assembled constructs",
    subterms: []
  },
  {
    term: "Assembly method",
    description:
      "A strategy to create assembled constructs based on experimental approaches (MoClo, Golden Gate, Gibson) " +
      "automated with j5 technology that optimizes the design process.",
    subterms: []
  },
  {
    term: "Alignments",
    description:
      "A tool that allows you create new alignments from your sequences (Multiple Sequence Alignment, " +
      "Pairwise Alignment and Map to Reference Sequence) and visualizes the alignments that you’ve already done.",
    subterms: []
  },
  {
    term: "DAPPER",
    description:
      "Design from an Alignment of Parallel Parts that Expose Regions of Diversity",
    subterms: []
  },
  {
    term: "Design Rule",
    description:
      "Constraints that can be added to bins in a design editor that will throw warnings when " +
      "their specifications aren't met",
    subterms: [
      {
        term: "Regex Design Rule",
        description:
          "Constraints that validate the bps of a part, e.g. throw a warning if a CDS part does not start with ATG",
        subterms: []
      },
      {
        term: "Tag Design Rule",
        description:
          "Constraints that validate parts in designs based on the presence or non-presence of tags",
        subterms: []
      }
    ]
  },
  {
    term: "Design Rule Set",
    description:
      "A grouping of Design Rules that can be applied to a bin in a design",
    subterms: []
  },
  {
    term: "Eugene Rule",
    description:
      "Constraints for reducing the number of combinations produced by a design by linking " +
      "together two parts with certain logical operators",
    subterms: []
  },
  {
    term: "Bin",
    description:
      "A container for parts in the design editor that typically represent a functional group " +
      "of the target construct. Each assembled construct contains one part from each bin in the design.",
    subterms: []
  },
  {
    term: "Card",
    description:
      "Seen in hierarchical views of the design editor, cards contain one or more bins " +
      "and represent the sequence(s) resulting from the combination of the parts in the bins that they contain.",
    subterms: []
  },
  {
    term: "Lab",
    description:
      "A high-level entity that multiple people can access (and thus a way to share data). " +
      "Different entities can be moved into and organized within them.",
    subterms: []
  },
  {
    term: "SBOL",
    description:
      "Synthetic Biology Open Language which is an open standard for the representation of in silico biological designs. " +
      "You can find it on the header of the design editor clicking “show SBOL”.",
    subterms: []
  },
  {
    term: "Schematic",
    description:
      "An abstract representation of a design that can be used to create a generic snapshot " +
      "of a design or to kick off the design process at a higher level (they can be converted to designs).",
    subterms: []
  },
  {
    term: "Combination Group",
    description:
      "Used at the moment to 'knock out' a subset of combinations in an hierarchical design " +
      "so they won't be submitted for assembly. The combination groups are displayed in the same combinatorial " +
      "fashion as non-list layout designs (a much more compact way of displaying information that listing each " +
      "combination of parts individually).",
    subterms: []
  },
  {
    term: "Design Template",
    description:
      "Can be used to capture portions of designs that are reused from design to design, and can " +
      "be applied within the design editor to quickly build out a design in an automated fashion",
    subterms: []
  },
  {
    term: "Reactant Group",
    description:
      "When adding a reaction the user starts with the assembly product and breaks it " +
      "down into the inputs into the assembly reaction that produces it. The reactant group is what we " +
      "call the input into the reaction which will be a grouping of bins (although a group can be just one bin).",
    subterms: []
  },
  {
    term: "Tag",
    description:
      "Labels that can be added to tags and subsequently used for filtering and design rule validation.",
    subterms: []
  }
];
