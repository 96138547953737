/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { getSelectedValidatedJunctionId } from "../../../../../../src-shared/selectors/designViewSelectors";
import {
  getReferencedValue,
  getItemOfType
} from "../../../../../../../tg-iso-design/selectors/designStateSelectors";

import {
  regexStrToRegularSequence,
  getOverhangSize
} from "../../../../../../../tg-iso-shared/utils/enzymeUtils";
import actions from "../../../../../../src-shared/redux/actions";
import InternalizationPreferencesSection from "./InternalizationPreferencesSection";
import EnzymeVisual from "../../../../../../src-shared/components/Dialogs/CreateRestrictionEnzymeDialog/EnzymeVisual";
import "./style.css";
import { InputField } from "@teselagen/ui";
import {
  getInternalizationPreferencesIntialValues,
  isAdapterAnOptionForOverhang
} from "../../../../../../src-shared/components/HierarchicalDesign/DesignInspector/ValidatedJunctionPanel/TypeIIsJunctionPanel/selectors";
import getInternalizationPreferenceErrors from "../../../../../../src-shared/components/HierarchicalDesign/DesignInspector/ValidatedJunctionPanel/TypeIIsJunctionPanel/getInternalizationPreferenceErrors";
import {
  getLevelOfInputCardOfJunction,
  getBpsForOtherJunctionsOfReaction
} from "../../../../../../../tg-iso-design/selectors/junctionSelectors";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

const mapStateToProps = state => {
  const junctionId = getSelectedValidatedJunctionId(state);

  const junction = getItemOfType(state, "junction", junctionId);
  const restrictionEnzyme = getReferencedValue(
    state,
    "junction",
    junctionId,
    "restrictionEnzymeId"
  );

  return {
    state,
    junctionId,
    junction,
    restrictionEnzyme,
    initialValues: {
      ...junction,
      ...getInternalizationPreferencesIntialValues(state, junctionId)
    },
    overhangSize: getOverhangSize(restrictionEnzyme),
    level5Prime: getLevelOfInputCardOfJunction(state, junctionId, true),
    level3Prime: getLevelOfInputCardOfJunction(state, junctionId, false),
    canHave5PrimeAdapter: isAdapterAnOptionForOverhang(state, junctionId, true),
    canHave3PrimeAdapter: isAdapterAnOptionForOverhang(
      state,
      junctionId,
      false
    ),
    otherOverhangBps: getBpsForOtherJunctionsOfReaction(
      state,
      junction.reactionId,
      junction.id
    )
  };
};

const mapDispatchToProps = {
  updateJunction: actions.design.updateJunction,
  changeInternalizationPreferences:
    actions.design.changeInternalizationPreferences
};

class TypeIIsJunctionPanel extends React.Component {
  /**
   * Render a section giving the user a visuaization of the generic
   * restriction site of the given restriction enzyme.
   */
  renderEnzymeVisualizationSection() {
    const { restrictionEnzyme } = this.props;
    return (
      <div className="validated-junction-enzyme-visualization">
        <EnzymeVisual
          {...restrictionEnzyme}
          label={`Digesting Enzyme (${restrictionEnzyme.name})`}
          recognitionRegex={regexStrToRegularSequence(
            restrictionEnzyme.recognitionRegex
          )}
        />
      </div>
    );
  }

  /**
   * Simple function to ensure changes get propagated to the state
   * whenever we make a valid change.
   */
  handleFieldSubmit = fieldName => newValue => {
    const { updateJunction, junction, valid } = this.props;

    if (!valid) return;

    updateJunction({
      id: junction.id,
      [fieldName]: newValue
    });
  };

  render() {
    return (
      <div className="validation-panel">
        <h5 className="inspector-panel-header">Junction Details</h5>
        {this.renderEnzymeVisualizationSection()}
        <InputField
          readOnly={this.props.readOnly}
          name="bps"
          label="Overhang Base Pairs"
          onFieldSubmit={this.handleFieldSubmit("bps")}
        />
        <InternalizationPreferencesSection {...this.props} />
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "TypeIIsJunctionPanelForm",
    enableReinitialize: true,
    validate,
    touchOnBlur: true,
    touchOnChange: true
  }),
  tgFormValues("rs5", "o5", "o3", "rs3")
)(TypeIIsJunctionPanel);

function validate(values, { overhangSize, otherOverhangBps }) {
  const { bps } = values;
  const errors = {};
  if (!bps || bps.length !== overhangSize)
    errors.bps = `Must be ${overhangSize} bps long`;
  else if (!/^[acgt]*$/i.test(bps)) errors.bps = "Contains invalid character";
  else if (/^n+$/i.test(bps)) return errors;
  else if (bps === getReverseComplementSequenceString(bps))
    errors.bps = "Can't have self-incompatible overhangs";
  else if (
    otherOverhangBps.some(r => (r || "").toLowerCase() === bps.toLowerCase())
  )
    errors.bps = "Can't have duplicate overhangs in the same digest";
  else if (
    otherOverhangBps.some(
      r =>
        getReverseComplementSequenceString((r || "").toLowerCase()) ===
        bps.toLowerCase()
    )
  )
    errors.bps =
      "Can't have reverse complement-duplicated overhangs in the same digest";

  Object.assign(errors, getInternalizationPreferenceErrors(values));

  return errors;
}
