/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment aminoAcidLibraryFragment on aminoAcidSequence {
    id
    name
    commonId
    hisTagLoc
    isoPoint
    uniprotId
    size
    molecularWeight
    extinctionCoefficient
    regionAnnotations {
      id
      name
    }
    proteinSequence
  }
`;
