// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__container-1l40j {\n  margin: auto;\n  height: 95%;\n  width: 100%;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__viz-1WXRN .ModelSigmaAminoAcidHistogramWidgetStyles-module__rect-18KxB:hover {\n  fill: #3dcc91;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__viz-1WXRN .ModelSigmaAminoAcidHistogramWidgetStyles-module__outputrect-1paJX {\n  fill: #137cbd;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__viz-1WXRN .ModelSigmaAminoAcidHistogramWidgetStyles-module__inputrect-2Bz0A {\n  fill: #3dcc91;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__tip-3Boek {\n  line-height: 1;\n  font-weight: bold;\n  padding: 12px;\n  background: rgba(0, 0, 0, 0.8);\n  color: #fff;\n  border-radius: 2px;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__viz-1WXRN .ModelSigmaAminoAcidHistogramWidgetStyles-module__legend-1eisl {\n  fill: #738694;\n}\n\n/* Creates a small triangle extender for the tooltip */\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__tip-3Boek:after {\n  box-sizing: border-box;\n  display: inline;\n  font-size: 10px;\n  width: 100%;\n  line-height: 1;\n  color: rgba(0, 0, 0, 0.8);\n  content: \"\\25BC\";\n  position: absolute;\n  text-align: center;\n  margin: -1px 0 0 0;\n  top: 100%;\n  left: 0;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__headerSlider-ctRb8 {\n  display: flex;\n  width: 350px;\n  margin-right: 30px;\n}\n\n.ModelSigmaAminoAcidHistogramWidgetStyles-module__headerSlider-ctRb8 h5 {\n  font-size: small;\n  width: 250px;\n  margin-top: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ModelSigmaAminoAcidHistogramWidgetStyles-module__container-1l40j",
	"viz": "ModelSigmaAminoAcidHistogramWidgetStyles-module__viz-1WXRN",
	"rect": "ModelSigmaAminoAcidHistogramWidgetStyles-module__rect-18KxB",
	"outputrect": "ModelSigmaAminoAcidHistogramWidgetStyles-module__outputrect-1paJX",
	"inputrect": "ModelSigmaAminoAcidHistogramWidgetStyles-module__inputrect-2Bz0A",
	"tip": "ModelSigmaAminoAcidHistogramWidgetStyles-module__tip-3Boek",
	"legend": "ModelSigmaAminoAcidHistogramWidgetStyles-module__legend-1eisl",
	"headerSlider": "ModelSigmaAminoAcidHistogramWidgetStyles-module__headerSlider-ctRb8"
};
module.exports = exports;
