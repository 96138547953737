/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { FC } from "react";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

const SuccessPage: FC<{
  crisprDesign: {
    id: string;
    __typename: string;
  };
}> = ({ crisprDesign }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h4>
        The CRISPR knock-ins design tool has been completed. Head to the{" "}
        <Link to={modelNameToLink(crisprDesign.__typename, crisprDesign.id)}>
          CRISPR Design page
        </Link>{" "}
        to view
        <br />
      </h4>
    </div>
  );
};

export default SuccessPage;
