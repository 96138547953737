/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";
import { digestPartFragment } from "../../../tg-iso-design/graphql/fragments/partFragment";

export default gql`
  fragment sequenceRecordFragment on sequence {
    id
    name
    circular
    description
    size
    parentSequenceId
    isJ5Sequence
    isInLibrary
    lockId
    childLockId
    polynucleotideMaterialId
    sequenceJ5ItemViews {
      id
      j5Report {
        id
        name
        designId
      }
    }
    parentSequenceSequences {
      id
      name
    }
    translations {
      id
      start
      end
      strand
    }
    molecularWeight
    hash
    createdAt
    updatedAt
    rnaType {
      id
      name
    }
    chromatogramData
    aminoAcidSequence {
      id
      name
    }
    plasmidInductionMethodPlasmids {
      id
      inductionMethod {
        id
        name
      }
    }
    sequenceCodingSequences {
      id
      codingDnaSequence {
        id
        name
        size
        taggedItems {
          ...taggedItemFragment
        }
      }
    }
    codingDnaSequenceSequenceCodingSequences {
      id
      eouDescription
      ribosomeBindingStrength
      sequence {
        id
        name
        size
        taggedItems {
          ...taggedItemFragment
        }
      }
    }
    polynucleotideMaterial {
      id
      name
      polynucleotideMaterialMaterialFpus {
        id
        functionalProteinUnit {
          id
          name
          extinctionCoefficient
          molecularWeight
          taggedItems {
            ...taggedItemFragment
          }
        }
      }
      polynucleotideMaterialMicrobialMaterialPlasmids {
        id
        microbialMaterialId
      }
    }
    parts {
      id
      name
      # size
      start
      end
      strand
      overlapsSelf
      elements {
        id
        design {
          lockTypeCode
          id
          name
        }
      }
      notes
      type
      ...digestPartFragment
      taggedItems {
        id
        tagId
        tagOptionId
      }
    }
    sequenceFeatures {
      id
      start
      end
      type
      name
      strand
      locations
      notes
      arrowheadType
      primerBindsOn
      oligoWithBindingSiteId
      oligoWithBindingSite {
        id
        name
        fullSequenceRaw
      }
    }
    sequenceFragments {
      id
      index
      fragment
    }
    sequenceTypeCode
    sequenceType {
      code
      name
    }
    user {
      id
      username
    }
  }
  ${taggedItemFragment}
  ${digestPartFragment}
`;
