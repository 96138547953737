/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  InputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";

import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";
import { withProps } from "recompose";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

class CreateSelectionMethodDialog extends Component {
  onSubmit = async values => {
    const {
      hideModal,
      selectionMethodTargetOrganismClasses = [],
      refetch = noop
    } = this.props;
    const {
      id: updatedId,
      name,
      cofactor,
      description,
      targetOrganismClasses = []
    } = values;
    try {
      const [{ id }] = await safeUpsert(
        ["selectionMethod", "id name cofactor description"],
        { id: updatedId, name, cofactor, description }
      );
      await safeDelete(
        "selectionMethodTargetOrganismClass",
        selectionMethodTargetOrganismClasses.map(c => c.id)
      );
      await safeUpsert(
        "selectionMethodTargetOrganismClass",
        targetOrganismClasses.map(targetOrganismClass => ({
          selectionMethodId: id,
          targetOrganismClassId: targetOrganismClass.id
        }))
      );
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating selection method");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit, asyncValidating } = this.props;
    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            rightElement={
              <AsyncValidateFieldSpinner validating={asyncValidating} />
            }
          />
          <InputField name="cofactor" label="Cofactor" />
          <TextareaField name="description" label="Description" />
          <GenericSelect
            asReactSelect
            label={modelNameToReadableName("targetOrganismClass", {
              upperCase: true,
              plural: true
            })}
            name="targetOrganismClasses"
            placeholder="Choose target organisms."
            isMultiSelect
            schema={["name"]}
            fragment={["targetOrganismClass", "id name"]}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Selection Method"
          : "Create Selection Method"
      };
    }
  }),
  withProps(() => ({ model: "selectionMethod" })),
  reduxForm({
    form: "createSelectionMethod",
    ...asyncValidateName
  })
)(CreateSelectionMethodDialog);
