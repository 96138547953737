/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@blueprintjs/core";
import { reduxForm, formValueSelector } from "redux-form";
import { uniq, keyBy } from "lodash";
import {
  getElementsInBin,
  getRootCardId,
  getItemOfType
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { socketWrapper } from "../../../../src-shared/socket/socketWrapper";
import actions from "../../../../src-shared/redux/actions";
import GeneSelection from "./GeneSelection";
import CrickitParameterSelection from "./CrickitParameterSelection";
import CrickitParameterPresetDetails from "./CrickitParameterPresetDetails";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import {
  getSequenceOfPart,
  getSequenceSequence
} from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";

class SendToCrickitDialog extends React.Component {
  state = {
    page: 1,
    selectedElements: {},
    elements: []
  };

  nextPage = () => {
    this.setState(prevState => {
      return { page: prevState.page + 1 };
    });
  };

  previousPage = () => {
    this.setState(prevState => {
      return { page: prevState.page - 1 };
    });
  };

  setSelectedElements = selectedElements => {
    this.setState(() => {
      return { selectedElements };
    });
  };

  onSubmit = () => {
    const {
      hideModal,
      updateDesign,
      designId,
      crickitParameterSelection,
      selectedParameterPreset
    } = this.props;
    const { username, selectionHeuristics } = crickitParameterSelection;

    try {
      updateDesign({
        lockedMessage:
          "This design is being optimized. Design is not editable until optimization is finished.",
        lockMsgDescription: null,
        lockTypeCode: "LOCK_ENTIRE_DESIGN",
        isLocked: true
      });

      socketWrapper.sendToCrickit({
        designId,
        selectedElements: Object.keys(this.state.selectedElements).filter(
          elId => !!this.state.selectedElements[elId]
        ),
        selectedParameterPreset,
        selectedSelectionHeuristic: selectionHeuristics,
        username
      });

      hideModal();
    } catch (e) {
      console.error(e);
      updateDesign({
        lockedMessage: null,
        lockMsgDescription: null,
        isLocked: false,
        lockTypeCode: null
      });
      window.toastr.error("Error sending part(s) to crickit.");
    }
  };

  async UNSAFE_componentWillMount() {
    const { elements, selectedElement } = this.props;

    const allDNASeqIds = [];
    const allAASeqIds = [];
    elements.forEach(el => {
      const { part, aminoAcidPart } = el;
      let sequenceId;
      if (aminoAcidPart) {
        sequenceId = aminoAcidPart.aminoAcidSequenceId;
        allAASeqIds.push(sequenceId);
      } else if (part) {
        sequenceId = part.sequenceId;
        allDNASeqIds.push(sequenceId);
      }
    });

    const elementsToSet = [];

    if (allDNASeqIds) {
      const uniqueDNASeqIds = uniq(allDNASeqIds);
      const sequenceInfo = await safeQuery(
        [
          "sequence",
          "id size circular sequenceFragments { id index fragment }"
        ],
        {
          variables: {
            filter: {
              id: uniqueDNASeqIds
            }
          }
        }
      );
      const keyedSeqInfo = keyBy(sequenceInfo, "id");
      elements.forEach(el => {
        if (el.part && !el.name.includes("_crickitized")) {
          const fullSeqInfo = keyedSeqInfo[el.part.sequenceId];
          const fullSeqBps = getSequenceSequence(fullSeqInfo);
          const partBps = getSequenceOfPart(el.part, fullSeqBps);
          elementsToSet.push({ ...el, bps: partBps });
        }
      });
    }

    if (allAASeqIds) {
      const uniqueAASeqIds = uniq(allAASeqIds);
      const aminoAcidSequenceInfo = await safeQuery(
        ["aminoAcidSequence", "id size proteinSequence"],
        {
          variables: {
            filter: {
              id: uniqueAASeqIds
            }
          }
        }
      );
      const keyedAASeqInfo = keyBy(aminoAcidSequenceInfo, "id");
      elements.forEach(el => {
        if (el.aminoAcidPart && !el.name.includes("_crickitized")) {
          const fullSeqInfo =
            keyedAASeqInfo[el.aminoAcidPart.aminoAcidSequenceId];
          const fullSeqBps = fullSeqInfo.proteinSequence;
          const partBps = getSequenceOfPart(el.aminoAcidPart, fullSeqBps);
          elementsToSet.push({ ...el, bps: partBps });
        }
      });
    }

    elementsToSet.forEach((el, index) => {
      const { bps, part, aminoAcidPart } = el;
      let crickitValidationMsg = "";
      let checkForNonNucleotides = false;
      let checkForStopCodon = false;
      const bpsWithoutEnd = bps.slice(0, -3).toUpperCase();
      const stopCodons = ["TAA", "TAG", "TGA"];
      let stopCodonFound, stopCodonIndex;
      const codons = bpsWithoutEnd.match(/.{1,3}/g);

      if (part) {
        checkForNonNucleotides = /[^atgc]/i.test(bps);
        stopCodons.forEach(stopCodon => {
          const possibleStopCodonIndex = codons.indexOf(stopCodon);
          if (possibleStopCodonIndex !== -1) {
            stopCodonFound = stopCodon;
            checkForStopCodon = true;
            stopCodonIndex = possibleStopCodonIndex * 3 + 1;
          }
        });
        if (checkForNonNucleotides) {
          crickitValidationMsg = "Gene must only contain nucleotides ATGC";
        } else if (checkForStopCodon) {
          crickitValidationMsg = `Stop codon ${stopCodonFound} found at base pair position ${stopCodonIndex}`;
        }
      } else if (aminoAcidPart) {
        const aaBps = bps.slice(
          Math.round(aminoAcidPart.start / 3),
          Math.round(aminoAcidPart.end / 3)
        );
        const valid = /^[arndcqeghilkmfpstwyv]*$/i.test(aaBps);
        if (!valid) {
          crickitValidationMsg =
            "Gene must only contain the single letter codes of the 20 amino acids";
        }
      }

      elementsToSet[index] = { ...el, crickitValidationMsg };
    });

    this.setState(() => {
      return { elements: elementsToSet };
    });

    if (selectedElement) {
      return this.setState(() => {
        return {
          selectedElements: {
            [selectedElement.id]: selectedElement
          },
          page: 2
        };
      });
    }

    this.setSelectedElements(
      elementsToSet.reduce((acc, el) => {
        if (el.crickitValidationMsg) {
          acc[el.id] = false;
        } else {
          acc[el.id] = true;
        }
        return acc;
      }, {})
    );
  }

  render() {
    const {
      hideModal,
      designState,
      customCrickitParameters = [],
      crickitParameterSelection,
      selectedParameterPreset
    } = this.props;
    const { page, selectedElements } = this.state;
    const { selectionHeuristics } = crickitParameterSelection;

    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={page === 3 ? "" : "Crick-It Submission"}
      >
        {page === 1 && (
          <GeneSelection
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            setSelectedElements={this.setSelectedElements}
            selectedElements={selectedElements}
            designState={designState}
            elements={this.state.elements}
            hideModal={hideModal}
          />
        )}
        {page === 2 && (
          <CrickitParameterSelection
            customCrickitParameters={customCrickitParameters}
            selectionHeuristics={selectionHeuristics}
            nextPage={this.nextPage}
            previousPage={this.previousPage}
            selectedSelectionHeuristic={selectionHeuristics}
            selectedParameterPreset={selectedParameterPreset}
            onSubmit={this.onSubmit}
            hideModal={hideModal}
          />
        )}
        {page === 3 && (
          <CrickitParameterPresetDetails
            previousPage={this.previousPage}
            hideModal={hideModal}
            selectedParameterPreset={selectedParameterPreset}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (state, { binIds = [] }) => {
  let elements = [];
  const rootCardId = getRootCardId(state);

  binIds.forEach(async binId => {
    let binIndex = null;
    Object.values(state.design.binCard).some(bc => {
      if (bc.cardId === rootCardId) {
        binIndex = bc.index;
        return true;
      }
      return false;
    });

    if (binIndex === null) {
      const errMsg =
        "Crickit submission doesn't support sending in bins that aren't in the root card for now";
      window.toastr.error(errMsg);
      throw new Error(errMsg);
    }

    const binEls = getElementsInBin(state, binId);
    elements = elements.concat(
      binEls.map(el => {
        let part;
        let aminoAcidPart;
        if (el.partId) {
          part = getItemOfType(state, "part", el.partId);
        } else if (el.aminoAcidPartId) {
          aminoAcidPart = getItemOfType(
            state,
            "aminoAcidPart",
            el.aminoAcidPartId
          );
        }
        const elementInfo = {
          id: el.id,
          name: el.name,
          binId: el.binId,
          index: el.index,
          binIndex,
          part,
          aminoAcidPart
        };
        return elementInfo;
      })
    );
  });

  const selector = formValueSelector("crickitParameterSelection");

  return {
    elements,
    designId: Object.keys(state.design.design)[0],
    designState: state.design,
    crickitParameterSelection: selector(
      state,
      "username",
      "selectionHeuristics"
    ),
    selectedParameterPreset: selector(state, "parameterPreset.id")
  };
};

const mapDispatchToProps = {
  updateDesign: actions.design.updateDesign
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "sendToCrickitDialog",
    enableReinitialize: true
  })
)(SendToCrickitDialog);
