/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable graphql/template-strings */
import {
  generateFragmentWithFields,
  getModelNameFromFragment
} from "@teselagen/apollo-methods";
import gql from "graphql-tag";
import {
  isArray,
  isPlainObject,
  forEach,
  get,
  pick,
  find,
  cloneDeep,
  identity,
  flatMap,
  keyBy,
  filter,
  sortBy,
  omit
} from "lodash";
import { getSequenceWithinRange } from "@teselagen/range-utils";
import { sequenceToVeInput } from "../sequence-import-utils/utils";
import { convertMicrobialMaterialGqlJsonToIntegrationJson } from "../utils/convertMicrobialMaterialGqlJsonToIntegrationJson";
import { convertMicrobialStrainGqlJsonToIntegrationJson } from "../utils/convertMicrobialStrainGqlJsonToIntegrationJson";
import aminoAcidRecordFragment from "../fragments/aminoAcidRecordFragment";
import functionalProteinUnitRecordFragment from "../fragments/functionalProteinUnitRecordFragment.js";
import {
  aliasModels,
  extendedPropertyModels,
  externalReferenceKeys,
  tagModels
} from "../../constants";
import includeExternalRecordIdentifierModelsBuild from "../../../tg-iso-lims/src/utils/includeExternalRecordIdentifierModels";
import { taggedItemFragment } from "../fragments/taggedItemFragment";
import sequenceFullFragment from "../fragments/sequenceFullFragment";
import getFragmentNameFromFragment from "../utils/getFragmentNameFromFragment";
import partFullFragment from "../fragments/partFullFragment";
import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getExtendedPropertyValue } from "../utils/extendedPropertiesUtils";
import { uniq } from "lodash";
import { strainSelectionMethodFragment } from "../fragments/strainRecordFragment";
import targetOrganismClassFragment from "../fragments/targetOrganismClassFragment";

const exportDnaMaterialFragment = gql`
  fragment exportDnaMaterialFragment on material {
    id
    name
    materialTypeCode
    internalAvailability
    externalAvailability
    polynucleotideMaterialSequence {
      ...${getFragmentNameFromFragment(sequenceFullFragment)}
    }
  }
  ${sequenceFullFragment}
`;

const strainFragment = gql`
  fragment externalExportStrainSimpleFragment on strain {
    id
    name
    genotype
    biosafetyLevel {
      code
      name
    }
    specie {
      id
      name
      genus {
        id
        name
      }
    }
    genome {
      id
      name
    }
    targetOrganismClass {
      id
      name
    }
    growthCondition {
      id
      name
      description
      shakerSpeed
      shakerThrow
      lengthUnitCode
      temperature
      growthMedia {
        id
        name
      }
    }
    strainSelectionMethods {
      id
      selectionMethod {
        id
        name
      }
    }
    strainPlasmids {
      id
      polynucleotideMaterialId
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          ...${getFragmentNameFromFragment(sequenceFullFragment)}
        }
      }
    }
  }
  ${sequenceFullFragment}
`;

const externalExportMicrobialFragment = gql`
  fragment externalExportMicrobialFragment on material {
    id
    name
    internalAvailability
    externalAvailability
    strain {
      ...${getFragmentNameFromFragment(strainFragment)}
    }
    microbialMaterialMicrobialMaterialPlasmids {
      id
      polynucleotideMaterialId
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          ...${getFragmentNameFromFragment(sequenceFullFragment)}
        }
      }
    }
  }
  ${strainFragment}
  ${sequenceFullFragment}
`;

const externalExportStrainFragment = gql`
  fragment externalExportStrainFragment on strain {
      ...${getFragmentNameFromFragment(strainFragment)}
      sourceMicrobialMaterial {
      ...${getFragmentNameFromFragment(externalExportMicrobialFragment)}
      }
  }
  ${strainFragment}
  ${externalExportMicrobialFragment}
`;

const exportAliquotFragment = gql`
  fragment exportAliquotFragment on aliquot {
    id
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    isDry
    sample {
      id
      name
      material {
        id
        materialTypeCode
        ...exportDnaMaterialFragment
        ...externalExportMicrobialFragment
      }
    }
  }
  ${exportDnaMaterialFragment}
  ${externalExportMicrobialFragment}
`;

const exportTubeFragment = gql`
  fragment exportTubeFragment on aliquotContainer {
    id
    name
    barcode {
      id
      barcodeString
    }
    rowPosition
    columnPosition
    aliquotContainerTypeCode
    aliquotContainerType {
      code
      name
    }
    aliquot {
      ...exportAliquotFragment
    }
  }
  ${exportAliquotFragment}
`;

const exportPlateFragment = gql`
  fragment exportPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      containerFormat {
        code
        is2DLabeled
        columnCount
      }
    }
    aliquotContainers {
      ...exportTubeFragment
    }
  }
  ${exportTubeFragment}
`;

const exportPartFragment = gql`
  fragment exportPartFragment on part {
    ...partFullFragment
  }
  ${partFullFragment}
`;

// NOTE: Design Part (different from DNA Part) seems to be a more user friendly
// name to refer to 'elements'. In fact, when merging Design and BUILD schema,
// I think it might be a good chance to rename the 'element' table to 'designPart' or maybe 'binPart'.
const exportDesignPartFragment = gql`
  fragment exportDesignPartFragment on element {
    id
    createdAt
    binId
    index
    design {
      id
      name
      bins {
        id
        createdAt
        name
        elements {
          id
          index
          name
          fas {
            id
            name
            reactionId
          }
          part {
            ...exportPartFragment
          }
        }
      }
    }
    part {
      ...exportPartFragment
    }
    fas {
      id
      name
      reactionId
    }
    bin {
      id
      binCards {
        id
        card {
          id
          isRoot
          inputReactionId
        }
      }
    }
  }
  ${exportPartFragment}
`;

const designFragment = gql`
  fragment designFragment on design {
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    id
    name
  }
`;

const exportReactionMapFragment = gql`
  fragment exportReactionMapFragment on reactionMap {
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    id
    name
    reactionType {
      code
      name
    }
    reactions {
      id
      name
      reactionInputs {
        id
        conserved
        inputMaterial {
          ...exportReactionMapMaterialFragment
        }
        inputAdditiveMaterial {
          ...exportReactionMapAMFragment
        }
      }
      reactionOutputs {
        id
        outputMaterial {
          ...exportReactionMapMaterialFragment
        }
        outputAdditiveMaterial {
          ...exportReactionMapAMFragment
        }
      }
    }
  }

  fragment exportReactionMapMaterialFragment on material {
    id
    name
    materialType {
      code
      name
    }
    polynucleotideMaterialSequence {
      ...exportReactionMapSequenceFragment
    }
    microbialMaterialMicrobialMaterialPlasmids {
      id
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          ...exportReactionMapSequenceFragment
        }
      }
    }
    strain {
      id
      name
      description
      genotype
      biosafetyLevel {
        code
        name
      }
      strainPlasmids {
        id
        polynucleotideMaterial {
          id
          polynucleotideMaterialSequence {
            ...exportReactionMapSequenceFragment
          }
        }
      }
      specie {
        id
        name
        genus {
          id
          name
        }
      }
      genome {
        id
        name
      }
      targetOrganismClass {
        id
        name
      }
      strainSelectionMethods {
        id
        selectionMethod {
          id
          name
        }
      }
      growthCondition {
        id
        name
        description
        growthMedia {
          id
          name
        }
        gasComposition {
          id
          name
        }
        temperature
        humidity
        shakerSpeed
        shakerThrow
        lengthUnitCode
      }
    }
  }

  fragment exportReactionMapSequenceFragment on sequence {
    id
    name
    sequenceTypeCode
    sequenceFeatures {
      id
      name
      start
      end
      strand
      type
    }
  }

  fragment exportReactionMapAMFragment on additiveMaterial {
    id
    name
    description
    isDry
    evaporable
    additiveType {
      code
      name
    }
    growthMediaSelectionMethods {
      id
      selectionMethod {
        id
        name
      }
    }
    targetOrganismClass {
      id
      name
    }
  }
`;

const exportUserRequestFragment = gql`
  fragment exportUserRequestFragment on customerRequest {
    id
    name
    outputDescription
    dueDate
    requestType {
      id
      name
    }
    customers {
      id
      user {
        id
        username
      }
    }
    primaryCustomer {
      id
      user {
        id
        username
      }
    }
    customerRequestStatusType {
      code
      name
    }
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    customerRequestStrains {
      id
      strain {
        ...${getFragmentNameFromFragment(strainFragment)}
      }
    }
    customerRequestSequences {
      id
      sequence {
        ...${getFragmentNameFromFragment(sequenceFullFragment)}
      }
    }
  }
  ${strainFragment}
`;

const exportReactionDesignFragment = gql`
  fragment exportReactionDesignFragment on reactionDesign {
    id
    name
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    reactionMaps {
      executionOrder
      ...exportReactionMapFragment
    }
  }
  ${exportReactionMapFragment}
`;

const exportExternalEquipmentFragment = gql`
  fragment exportExternalEquipmentFragment on equipmentItem {
    id
    name
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
  }
`;

const proteinMaterialFragment = gql`
  fragment proteinMaterialFragment on material {
    id
    name
    materialTypeCode
    functionalProteinUnit {
      ...functionalProteinUnitRecordFragment
    }
  }
  ${functionalProteinUnitRecordFragment}
`;

const extendedValuesFragment = `
  id
  value
  extendedProperty {
    id
    name
  }
`;

const extendedCategoryValuesFragment = `
  id
  extendedCategoryId
  extendedCategory {
    id
    name
  }
  extendedProperty {
    id
    name
  }
`;

const extendedMeasurementValuesFragment = `
  id
  value
  measurementUnitId
  measurementUnit {
    id
    abbreviation
  }
  extendedProperty {
    id
    name
  }
`;

const allExtendedValuesFragment = `
  extendedValues {
    ${extendedValuesFragment}
  }
  extendedMeasurementValues {
    ${extendedMeasurementValuesFragment}
  }
  extendedCategoryValues {
    ${extendedCategoryValuesFragment}
  }
`;

export const fragMap = {
  DNA_SEQUENCE: {
    convert: seq => {
      const toRet = sequenceToVeInput(seq, {
        annotationsAsObjects: false,
        hideAssemblyAnnotations: true
      });
      return toRet;
    },
    fragment: sequenceFullFragment
  },
  DNA_PART: {
    convert: identity,
    fragment: partFullFragment
  },
  SELECTION_METHOD: {
    convert: identity,
    fragment: strainSelectionMethodFragment
  },
  ORGANISM: {
    convert: m => {
      return m;
    },
    fragment: targetOrganismClassFragment
  },
  OLIGO: {
    convert: seq => sequenceToVeInput(seq, { annotationsAsObjects: false }),
    fragment: generateFragmentWithFields(
      "sequence",
      "id name size fullSequenceRaw"
    ),
    queryFilter: {
      sequenceTypeCode: "OLIGO"
    }
  },
  DNA_MATERIAL: {
    convert: (m, options) => {
      const cleaned = {
        ...m,
        sequence: options.DNA_SEQUENCE.convert(
          m.polynucleotideMaterialSequence,
          options
        )
      };
      delete cleaned.polynucleotideMaterialSequence;
      return cleaned;
    },
    fragment: exportDnaMaterialFragment,
    queryFilter: {
      materialTypeCode: "DNA"
    }
  },
  MICROBIAL_MATERIAL: {
    convert: convertMicrobialMaterialGqlJsonToIntegrationJson,
    fragment: externalExportMicrobialFragment,
    queryFilter: {
      materialTypeCode: "MICROBIAL"
    }
  },
  MICROBIAL_STRAIN: {
    convert: convertMicrobialStrainGqlJsonToIntegrationJson,
    fragment: externalExportStrainFragment
  },
  AMINO_ACID: {
    convert: seq => {
      const res = sequenceToVeInput(seq, {
        annotationsAsObjects: false,
        isProtein: true
      });
      delete res.aminoAcidDataForEachBaseOfDNA;
      return res;
    },
    fragment: aminoAcidRecordFragment
  },
  ALIQUOT: {
    doNotUseAsImportExportSubtype: true,
    convert: (aliquot, options) => {
      const transformed = pick(aliquot, [
        "id",
        "mass",
        "massUnitCode",
        "volume",
        "volumetricUnitCode",
        "concentration",
        "concentrationUnitCode"
      ]);
      const material = get(aliquot, "sample.material");
      if (material && material.materialTypeCode === "DNA") {
        transformed.dnaMaterial = options.DNA_MATERIAL.convert(material);
      } else if (material && material.materialTypeCode === "MICROBIAL") {
        transformed.microbialMaterial =
          options.MICROBIAL_MATERIAL.convert(material);
      }
      return transformed;
    },
    fragment: exportAliquotFragment
  },
  TUBE: {
    fragment: exportTubeFragment,
    convert: (tube, options) => {
      const transformed = {
        name: tube.name,
        tubeType: tube.aliquotContainerType.name,
        aliquotContainerTypeCode: tube.aliquotContainerTypeCode,
        barcode: get(tube, "barcode.barcodeString"),
        ...(tube.aliquot && {
          aliquot: options.ALIQUOT.convert(tube.aliquot)
        })
      };
      return transformed;
    }
  },
  PLATE: {
    fragment: exportPlateFragment,
    convert: (plate, options) => {
      const transformed = {
        name: plate.name,
        barcode: get(plate, "barcode.barcodeString"),
        plateType: plate.containerArrayType.name,
        contents: plate.aliquotContainers.map(ac => {
          return {
            id: ac.id,
            ...options.TUBE.convert(ac),
            location: getAliquotContainerLocation(ac, {
              containerFormat: plate.containerArrayType.containerFormat
            })
          };
        })
      };
      return transformed;
    }
  },
  DESIGN_PART: {
    fragment: exportDesignPartFragment,
    // queryOptions could be passed along with the fragment.
    queryOptions: {},
    convert: _element => {
      const element = cloneDeep(_element);
      function userFriendlyDesignObject(design) {
        return {
          ...design,
          bins: design.bins.map(bin => {
            const elements = sortBy(bin.elements, "index");
            return {
              id: bin.id,
              name: bin.name,
              // Here we remove the "element" level making the design object less nested.
              // by directly placing dna parts within each bin.
              dnaParts: elements.map(element => {
                const dnaPart = element.part;
                const sequence = cloneDeep(dnaPart.sequence);
                dnaPart["sourceSequence"] = sequence;
                dnaPart["partSequence"] = getSequenceWithinRange(
                  {
                    start: dnaPart.start,
                    end: dnaPart.end
                  },
                  sequence.fullSequence
                );
                delete dnaPart["sequence"];
                delete dnaPart.sourceSequence["fullSequence"];
                dnaPart["forcedAssemblyStrategy"] = get(element, "fas.0.name");
                return {
                  ...dnaPart
                };
              })
            };
          })
        };
      }
      const design = cloneDeep(get(element, "design"));
      const formattedDesign = userFriendlyDesignObject(design);
      const elementBinCard = find(
        get(element, "bin.binCards"),
        // We don't care about root cards.
        // Every element record SHOULD be linked to a single non-root card.
        binCard => !get(binCard, "card.isRoot")
      );
      const elementReactionId =
        elementBinCard && get(elementBinCard, "card.inputReactionId");

      const convertedElement = {
        ...element,
        // Some DESIGN_PART update hooks needs contextual information on the other parts of the design
        // to make calls on how to update it. So, a design field with its bins parts should be passed
        // in the request.
        design: formattedDesign,
        dnaPart: {
          ...element.part,
          partSequence: getSequenceWithinRange(
            {
              start: element.part.start,
              end: element.part.end
            },
            get(element, "part.sequence.fullSequence")
          ),
          sourceSequence: { ...get(element, "part.sequence") }
        },
        // FAS is actually an object, but users (from the NodeRedFlow POV)
        // should only worry about the FAS name (or code).
        // BTW: Available FAS names are currently stored in a file in tg-iso-design/constants/,
        // but we should create a code table in the DM linked to the FAS table seeded with the available FAS strategies codes.
        forcedAssemblyStrategy: get(element, "fas.0.name"),
        // reactionId is actually a 'FAS' field, but users (from the NodeRedFlow POV)
        // don't need to worry about it. We just need it in case users return a custom
        // FAS from the nodeRedFlow.
        reactionId: elementReactionId
      };
      delete convertedElement["bin"];
      delete convertedElement["fas"];
      delete convertedElement["part"];
      delete convertedElement["__typename"];
      delete convertedElement.dnaPart["sequence"];
      delete convertedElement.dnaPart.sourceSequence["fullSequence"];
      return convertedElement;
    },
    postQueryFn: elements => {
      // Every element comes with a design object, just take the first one
      // to get the order in which the bins are in the design.
      const binIds = get(elements, "0.design.bins").map(bin => bin.id);
      const sortedElements = sortBy(elements, [
        element => binIds.indexOf(element.binId),
        "index"
      ]);
      return sortedElements;
    },
    intermediateUpdate: (element, designPartsProcessedByHook) => {
      const newContextDnaParts = designPartsProcessedByHook.map(designPart => ({
        ...designPart.dnaPart,
        forcedAssemblyStrategy: designPart.forcedAssemblyStrategy,
        updatedByThisHookCall: true
      }));
      const newContextDnaPartsById = keyBy(newContextDnaParts, "id");
      const contextDnaPartsToUpdate = filter(
        flatMap(element.design.bins.map(bin => bin.dnaParts)),
        dnaPart => dnaPart.id !== element.dnaPart.id
      );

      forEach(contextDnaPartsToUpdate, contextDnaPartToUpdate => {
        const newDnaPartState =
          newContextDnaPartsById[contextDnaPartToUpdate.id];

        if (newDnaPartState) {
          Object.assign(contextDnaPartToUpdate, newDnaPartState);
        }
      });

      return element;
    }
  },
  DESIGN: {
    fragment: designFragment,
    convert: identity
  },
  PROTEIN_MATERIAL: {
    fragment: proteinMaterialFragment,
    convert: identity,
    queryFilter: {
      materialTypeCode: "PROTEIN"
    }
  },
  USER_REQUEST: {
    fragment: exportUserRequestFragment,
    convert: (userRequest, options) => {
      const cleaned = {
        ...userRequest,
        feedback: userRequest.outputDescription,
        status: userRequest.customerRequestStatusType.name,
        requestType: userRequest.requestType.name,
        primaryCustomer: userRequest.primaryCustomer?.user?.username,
        customers: userRequest.customers.map(c => c.user.username),
        strains: userRequest.customerRequestStrains.map(strain =>
          options.MICROBIAL_STRAIN.convert(strain.strain)
        ),
        sequences: userRequest.customerRequestSequences.map(sequence =>
          options.DNA_SEQUENCE.convert(sequence.sequence)
        )
      };
      delete cleaned.customerRequestStatusType;
      delete cleaned.outputDescription;
      delete cleaned.customerRequestStrains;
      delete cleaned.customerRequestSequences;
      return cleaned;
    }
  },
  REACTION_DESIGN: {
    fragment: exportReactionDesignFragment,
    convert: reactionDesign => {
      return {
        ...reactionDesign,
        reactionMaps: reactionDesign.reactionMaps.map(convertReactionMap)
      };
    }
  },
  REACTION_MAP: {
    fragment: exportReactionMapFragment,
    convert: convertReactionMap
  },
  EQUIPMENT: {
    fragment: exportExternalEquipmentFragment,
    // convert:identity
    convert: e => {
      return {
        ...omit(e, "equipmentType"),
        equipementTypeCode: e.equipmentType
      };
    }
  }
};

forEach(fragMap, (value, key) => {
  const model = getModelNameFromFragment(value.fragment);
  const hasTags = tagModels.includes(model);
  const hasExtendedProperties = extendedPropertyModels.includes(model);
  const hasAliases = aliasModels.includes(model);
  const hasExternalReference =
    includeExternalRecordIdentifierModelsBuild.includes(model);
  const _fragment = value.fragment;
  const originalConvert = fragMap[key].convert;
  value.convert = (_record, options = {}) => {
    if (!_record) return _record;
    const extendedProperties = [];
    let tags = [];
    if (hasExtendedProperties) {
      [
        "extendedValues",
        "extendedCategoryValues",
        "extendedMeasurementValues"
      ].forEach(table => {
        (_record[table] || []).forEach(val => {
          const value = getExtendedPropertyValue(val);
          extendedProperties.push({
            id: val.extendedProperty.id,
            name: val.extendedProperty.name,
            value
          });
        });
      });
    }
    if (hasTags) {
      tags = (_record.taggedItems || []).map(ti => {
        return {
          id: ti.tag.id,
          name: ti.tag.name,
          color: ti.tag.color,
          ...(ti.tagOption && {
            tagOption: ti.tagOption.name,
            tagOptionColor: ti.tagOption.color
          })
        };
      });
    }
    const record = originalConvert(_record, { ...options, ...fragMap });
    if (_record.id && !record.id) {
      record.id = _record.id;
    }
    if (hasExtendedProperties) {
      delete record.extendedValues;
      delete record.extendedMeasurementValues;
      delete record.extendedCategoryValues;
      record.extendedProperties = extendedProperties;
    }
    if (hasTags) {
      delete record.taggedItems;
      record.tags = tags;
    }
    if (hasAliases) {
      record.aliases = _record.aliases?.map(({ name }) => name);
    }
    deepRemoveNulls(record);
    return record;
  };

  const fragmentNameFromFragment = getFragmentNameFromFragment(_fragment);
  const fragment = gql`
  fragment externalExportFragmentFor${model}_${fragmentNameFromFragment} on ${model} {
    ...${fragmentNameFromFragment}
    ${hasTags ? `taggedItems {...taggedItemFragment}` : ""}
    ${hasExtendedProperties ? allExtendedValuesFragment : ""}
    ${hasExternalReference ? externalReferenceKeys.join("\n") : ""}
    ${hasAliases ? `aliases {id name}` : ""}
  }
  ${_fragment}
  ${hasTags ? taggedItemFragment : ""}
`;
  value.fragment = fragment;
});

function convertReactionMap(reactionMap) {
  const allReagents = [];
  const allMaterials = [];
  const allStrains = [];
  return {
    version: 1,
    executionOrder: reactionMap.executionOrder,
    name: reactionMap.name,
    reactionType: reactionMap.reactionType.name,
    reactions: reactionMap.reactions.map(reaction => {
      const inputMaterials = [];
      const inputReagents = [];
      const inputReagentsConserved = [];
      const outputMaterials = [];
      const outputReagents = [];
      reaction.reactionInputs.forEach(reactionInput => {
        if (reactionInput.inputMaterial) {
          inputMaterials.push(reactionInput.inputMaterial);
        } else if (reactionInput.inputAdditiveMaterial) {
          if (reactionInput.conserved) {
            inputReagentsConserved.push(reactionInput.inputAdditiveMaterial);
          } else {
            inputReagents.push(reactionInput.inputAdditiveMaterial);
          }
        }
      });
      reaction.reactionOutputs.forEach(reactionOutput => {
        if (reactionOutput.outputMaterial) {
          outputMaterials.push(reactionOutput.outputMaterial);
        } else if (reactionOutput.outputAdditiveMaterial) {
          outputReagents.push(reactionOutput.outputAdditiveMaterial);
        }
      });
      allReagents.push(
        ...inputReagents,
        ...inputReagentsConserved,
        ...outputReagents
      );
      allMaterials.push(...inputMaterials, ...outputMaterials);
      return {
        name: reaction.name,
        inputMaterials: inputMaterials.map(material => material.id),
        inputReagents: inputReagents.map(reagent => reagent.id),
        inputReagentsConserved: inputReagentsConserved.map(
          reagent => reagent.id
        ),
        outputMaterials: outputMaterials.map(material => material.id),
        outputReagents: outputReagents.map(reagent => reagent.id)
      };
    }),
    reagents: uniq(allReagents).map(reagent => {
      return {
        ref: reagent.id,
        name: reagent.name,
        description: reagent.description,
        reagentType: reagent.additiveType?.name,
        isDry: reagent.isDry,
        evaporable: reagent.evaporable,
        targetOrganismGroup: reagent.targetOrganismClass?.name,
        selectionMethods: reagent.growthMediaSelectionMethods.map(gmsm => {
          return gmsm.selectionMethod.name;
        })
      };
    }),
    materials: uniq(allMaterials).map(material => {
      material.strain && allStrains.push(material.strain);
      return {
        ref: material.id,
        name: material.name,
        materialType: material.materialType?.name,
        sequences: material.polynucleotideMaterialSequence
          ? [material.polynucleotideMaterialSequence.id]
          : material.microbialMaterialMicrobialMaterialPlasmids.map(
              mp => mp.polynucleotideMaterial.polynucleotideMaterialSequence.id
            ),
        strain: material.strain?.id
      };
    }),
    strains: allStrains.map(strain => {
      return {
        ref: strain.id,
        name: strain.name,
        description: strain.description,
        genotype: strain.genotype,
        species: strain.specie?.name,
        genus: strain.specie?.genus?.name,
        genome: strain.genome?.name,
        biosafetyLevel: strain.biosafetyLevel?.name,
        targetOrganismGroup: strain.targetOrganismClass?.name,
        growthCondition: {
          name: strain.growthCondition?.name,
          description: strain.growthCondition?.description,
          growthMedia: strain.growthCondition?.growthMedia?.name,
          gasComposition: strain.growthCondition?.gasComposition?.name,
          temperature: strain.growthCondition?.temperature,
          humidity: strain.growthCondition?.humidity,
          shakerSpeed: strain.growthCondition?.shakerSpeed,
          shakerThrow: strain.growthCondition?.shakerThrow,
          lengthUnit: strain.growthCondition?.lengthUnitCode
        }
      };
    })
  };
}

export function modelToExportFragment({ subtype }) {
  const fragMapForType = fragMap[subtype];
  if (!fragMapForType) {
    throw new Error(`No fragment map found for subtype ${subtype}`);
  }
  const {
    convert,
    intermediateUpdate = identity,
    postQueryFn = identity,
    fragment,
    queryFilter,
    queryOptions = {}
  } = fragMapForType;
  return {
    convertDbModelToUserFacing: convert,
    postQueryFn,
    intermediateUpdate,
    fragment,
    queryFilter,
    queryOptions
  };
}

function deepRemoveNulls(r) {
  forEach(r, (val, key) => {
    if (val === null) delete r[key];
    else if (isPlainObject(val)) {
      deepRemoveNulls(val);
    } else if (isArray(val)) {
      val.forEach(deepRemoveNulls);
    }
  });
}
