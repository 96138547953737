/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { H4 } from "@blueprintjs/core";
import { observer } from "mobx-react";
import ReactMarkDown from "react-markdown";
import remarkGfm from "remark-gfm";

export default observer(
  class definition extends Component {
    render() {
      const { data } = this.props;
      return (
        <div style={{ marginBottom: 20 }}>
          <H4>
            <b>{data.keyWord}</b>
          </H4>
          <div style={{ textAlign: "justify" }}>
            <ReactMarkDown
              children={data.description}
              remarkPlugins={[remarkGfm]}
              disallowedTypes={["linkReference", "delete", "html"]}
            />
          </div>
        </div>
      );
    }
  }
);
