/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// These have their own custom logic for processing j5 data that's special when it comes to applying
// naming templates to construct annotations.
// In order to support CAs name templates additional work and refactor
// will have to be done for these methods.
// Also, I don't think construct annotations have ever worked for these methods anyway (Ill create issue for this).
// NOTE: refer to file: `server/src/j5/j5-assembly-methods/index.js` and `processJ5ResponseUSHER.js`
export const NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS = [
  "synthesis-and-custom-cloning",
  "USER"
];
