/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import tgCreateCachedSelector from "../utils/tgCreateCachedSelector";

export default tgCreateCachedSelector(
  designState => designState,
  (designState, cardId) => cardId,
  (designState, cardId) => {
    return Object.values(designState.binCard)
      .filter(bc => bc.cardId === cardId)
      .map(bc => bc.binId);
  }
)((designState, cardId) => cardId);
