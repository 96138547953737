/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import { InfoHelper } from "@teselagen/ui";
import {
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import { startCase } from "lodash";

export const automateSelectionMethods = [
  "lastModified",
  "fewestPlates",
  "oldest",
  "newest"
];

function AutomateSelectionField({
  hasAutomaticSelection,
  clearAutomatedSelection,
  automateSelection,
  onMount
}) {
  useEffect(() => {
    onMount && onMount();
  }, [onMount]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ButtonGroup>
        <Popover
          minimal
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              {automateSelectionMethods.map(method => (
                <MenuItem
                  key={method}
                  text={startCase(method)}
                  onClick={() => {
                    automateSelection(method);
                  }}
                />
              ))}
            </Menu>
          }
        >
          <Button
            intent="primary"
            text="Automate Sample Selection"
            rightIcon="caret-down"
          />
        </Popover>
        {hasAutomaticSelection && (
          <Button
            className="tg-clear-automated-selection"
            intent="danger"
            icon="trash"
            onClick={clearAutomatedSelection}
          />
        )}
      </ButtonGroup>
      <div style={{ marginRight: 12 }} />
      <InfoHelper
        isInline
        content={`This lets you automatically choose samples from your inventory either by the last time the plate was modified (will use the "freshest" plates) or by the strategy that cuts down the most on plate use.`}
      ></InfoHelper>
    </div>
  );
}

export default AutomateSelectionField;
