/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  get,
  keyBy,
  startCase,
  forEach,
  some,
  maxBy,
  flatMap,
  groupBy,
  sortBy
} from "lodash";
import { change, initialize } from "redux-form";
import {
  Loading,
  DataTable,
  getSelectedEntities,
  withSelectedEntities,
  CheckboxField
} from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { Button, Intent, Callout, Tooltip, Icon } from "@blueprintjs/core";
import { connect } from "react-redux";
import { aliquotHasVolumeToTransfer } from "../../../../utils/plateUtils";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import AutomateSelectionField from "../../../AutomateSelectionField";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import NameOutputData from "../NameOutputData";
import { withUnitGeneric } from "../../../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import { isSequenceInInventory } from "../utils";
import { withProps } from "recompose";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import SelectAliquotsForPCRDialog from "../../../Dialogs/SelectAliquotsForPCRDialog";
import AliquotVolumeLessThanDeadWarning from "../../../AliquotVolumeLessThanDeadWarning";
import { minBy } from "lodash";

const materialTabs = ["primaryTemplates", "forwardPrimers", "reversePrimers"];

class InventoryCheck extends Component {
  // state = {
  //   activeTab: "primaryTemplates"
  // };

  clearAliquotForSequence = (sequenceId, tableForm) => {
    const {
      stepFormProps: { change },
      selectedAliquotForSequence = {},
      changeFormValue
    } = this.props;
    const newSelectedAliquotsForSequence = {
      ...selectedAliquotForSequence
    };
    delete newSelectedAliquotsForSequence[sequenceId];
    change("selectedAliquotForSequence", newSelectedAliquotsForSequence);
    const selectedEntities = getSelectedEntities(
      window.teGlobalStore,
      tableForm
    );
    const newSelectedEntityIdMap = selectedEntities.reduce((acc, entity) => {
      if (entity.id === sequenceId) return acc;
      else {
        acc[entity.id] = {
          entity
        };
        return acc;
      }
    }, {});
    changeFormValue(
      tableForm,
      "reduxFormSelectedEntityIdMap",
      newSelectedEntityIdMap
    );
  };

  saveSelectedAliquot = (aliquot, { sequenceId, tableForm }) => {
    const {
      stepFormProps: { change },
      changeFormValue,
      selectedAliquotForSequence = {},
      sequences = []
    } = this.props;
    const keyedSequences = getSequenceMap(sequences);
    const newSelectedAliquotsForSequence = {
      ...selectedAliquotForSequence,
      [sequenceId]: aliquot
    };
    change("selectedAliquotForSequence", newSelectedAliquotsForSequence);
    const selectedEntities = getSelectedEntities(
      window.teGlobalStore,
      tableForm
    );
    const newSelectedEntityIdMap = selectedEntities.reduce((acc, entity) => {
      acc[entity.id] = {
        entity
      };
      return acc;
    }, {});
    const aliquotEntity = getAliquotEntity({
      key: tableForm.replace("InventoryMaterials", ""),
      sequenceId,
      keyedSequences,
      selectedAliquotForSequence: newSelectedAliquotsForSequence
    });
    newSelectedEntityIdMap[aliquotEntity.id] = { entity: aliquotEntity };
    changeFormValue(
      tableForm,
      "reduxFormSelectedEntityIdMap",
      newSelectedEntityIdMap
    );
  };

  selectAliquot = ({ sequenceId, aliquotOptions }, tableForm) => {
    showDialog({
      ModalComponent: SelectAliquotsForPCRDialog,
      modalProps: {
        aliquotOptions,
        selectKey: { sequenceId, tableForm },
        makeSelection: this.saveSelectedAliquot
      }
    });
  };

  automateAliquotSelection = automaticSelectionMethod => {
    const {
      stepFormProps: { change },
      changeFormValue,
      initializeForm
    } = this.props;

    const { inventoryCheckMap } = this.buildInventoryCheckMap();

    const newSelectedAliquotsForSequence = {};
    const plateIdToNumMaterialsMap = {};

    if (automaticSelectionMethod === "fewestPlates") {
      Object.keys(inventoryCheckMap).forEach(key => {
        const inventoryMaterials = inventoryCheckMap[key].inventoryMaterials;

        inventoryMaterials.forEach(invMat => {
          if (invMat.aliquotOptions.length > 1) {
            const seenPlateIds = [];
            invMat.aliquotOptions.forEach(o => {
              const item =
                o.aliquotContainer.containerArray || o.aliquotContainer;
              if (!seenPlateIds.includes(item.id)) {
                seenPlateIds.push(item.id);
                if (!plateIdToNumMaterialsMap[item.id]) {
                  plateIdToNumMaterialsMap[item.id] = 0;
                }
                plateIdToNumMaterialsMap[item.id]++;
              }
            });
          }
        });
      });
    }

    Object.keys(inventoryCheckMap).forEach(key => {
      const tableForm = key + "InventoryMaterials";

      // initialize the form so that it will keep the selection if it has not yet be mounted (different tab)
      initializeForm(
        tableForm,
        {},
        { keepDirty: true, updateUnregisteredFields: true, keepValues: true }
      );

      const selectedEntities = this.props[tableForm + "SelectedEntities"] || [];

      const newSelectedEntitiesForTable = selectedEntities.reduce(
        (acc, entity) => {
          acc[entity.id] = { entity };
          return acc;
        },
        {}
      );

      const inventoryMaterials = inventoryCheckMap[key].inventoryMaterials;

      inventoryMaterials.forEach(invMat => {
        if (invMat.aliquotOptions.length > 1) {
          let matchingAliquotOption;
          if (automaticSelectionMethod === "lastModified") {
            const mostRecent = maxBy(invMat.aliquotOptions, o => {
              const item =
                o.aliquotContainer.containerArray || o.aliquotContainer;
              return new Date(item.updatedAt).getTime();
            });
            matchingAliquotOption = mostRecent;
          } else if (
            automaticSelectionMethod === "oldest" ||
            automaticSelectionMethod === "newest"
          ) {
            const fn = automaticSelectionMethod === "oldest" ? minBy : maxBy;
            const oldestOrNewest = fn(invMat.aliquotOptions, o => {
              const item =
                o.aliquotContainer.containerArray || o.aliquotContainer;
              return new Date(item.createdAt).getTime();
            });
            matchingAliquotOption = oldestOrNewest;
          } else {
            const mostUsed = maxBy(
              // sort first incase multiple options have the same amount of uses
              sortBy(
                invMat.aliquotOptions,
                "aliquotContainer.containerArray.id"
              ),
              o => {
                const item =
                  o.aliquotContainer.containerArray || o.aliquotContainer;
                return plateIdToNumMaterialsMap[item.id];
              }
            );
            matchingAliquotOption = mostUsed;
          }
          if (matchingAliquotOption) {
            newSelectedAliquotsForSequence[invMat.sequenceId] = {
              ...matchingAliquotOption,
              automated: true
            };
            const entity = {
              ...invMat,
              id: matchingAliquotOption.id,
              chosenAliquot: matchingAliquotOption,
              automated: true
            };
            newSelectedEntitiesForTable[invMat.id] = { entity };
          }
        } else if (invMat.aliquotOptions.length === 1) {
          const entity = {
            ...invMat,
            automated: true
          };
          newSelectedEntitiesForTable[invMat.id] = { entity };
        }
      });

      changeFormValue(
        tableForm,
        "reduxFormSelectedEntityIdMap",
        newSelectedEntitiesForTable
      );
    });
    change("selectedAliquotForSequence", newSelectedAliquotsForSequence);
  };

  clearAutomatedSelection = () => {
    const {
      stepFormProps: { change },
      selectedAliquotForSequence = {},
      changeFormValue
    } = this.props;

    const { inventoryCheckMap } = this.buildInventoryCheckMap();

    const newSelectedAliquotsForSequence = { ...selectedAliquotForSequence };

    Object.keys(newSelectedAliquotsForSequence).forEach(key => {
      if (newSelectedAliquotsForSequence[key].automated) {
        delete newSelectedAliquotsForSequence[key];
      }
    });
    change("selectedAliquotForSequence", newSelectedAliquotsForSequence);

    Object.keys(inventoryCheckMap).forEach(key => {
      const tableForm = key + "InventoryMaterials";
      const selectedEntities = this.props[tableForm + "SelectedEntities"] || [];

      const newSelectedEntitiesForTable = selectedEntities.reduce(
        (acc, entity) => {
          if (entity.automated) return acc;
          acc[entity.id] = { entity };
          return acc;
        },
        {}
      );

      changeFormValue(
        tableForm,
        "reduxFormSelectedEntityIdMap",
        newSelectedEntitiesForTable
      );
    });
  };

  buildInventoryCheckMap = () => {
    const {
      plateMapGroup,
      sourcePlateMapGroup,
      selectedAliquotForSequence = {},
      sequences: unfilteredSequences = [],
      hideDryAliquots,
      showInvalidSamples
    } = this.props;

    const inventoryCheckMap = {
      primaryTemplates: {
        orderMaterials: [],
        inventoryMaterials: [],
        allSequenceMap: {}
      },
      forwardPrimers: {
        orderMaterials: [],
        inventoryMaterials: [],
        allSequenceMap: {}
      },
      reversePrimers: {
        orderMaterials: [],
        inventoryMaterials: [],
        allSequenceMap: {}
      }
    };

    const keyedSequences = getSequenceMap(unfilteredSequences);

    const sequenceIdsAlreadySelected = {};
    const finalSelectedAliquots = [];
    Object.keys(inventoryCheckMap).forEach(key => {
      const tableForm = key + "InventoryMaterials";
      const selectedEntities = this.props[tableForm + "SelectedEntities"] || [];
      finalSelectedAliquots.push(
        ...selectedEntities.filter(aliquot => {
          const dryAliquotChosen =
            hideDryAliquots &&
            aliquot.chosenAliquot &&
            aliquot.chosenAliquot.isDry;
          if (dryAliquotChosen) return false;
          return true;
        })
      );
    });
    finalSelectedAliquots.forEach(aliquot => {
      const clearable =
        aliquot.aliquotOptions.length > 1 && !aliquot.chosenAliquot;
      if (clearable && !selectedAliquotForSequence[aliquot.sequenceId]) return;
      sequenceIdsAlreadySelected[aliquot.sequenceId] = true;
    });

    (sourcePlateMapGroup || plateMapGroup).plateMaps.forEach(pm => {
      pm.plateMapItems.forEach(pmi => {
        let pathPrefix = "";
        if (sourcePlateMapGroup) {
          pathPrefix = "j5Item.j5PcrReaction.";
        }
        const ptId = get(pmi, pathPrefix + "primaryTemplate.id");
        const fpId = get(pmi, pathPrefix + "forwardPrimer.sequence.id");
        const rpId = get(pmi, pathPrefix + "reversePrimer.sequence.id");
        [
          { sequenceId: ptId, key: "primaryTemplates" },
          { sequenceId: fpId, key: "forwardPrimers" },
          { sequenceId: rpId, key: "reversePrimers" }
        ].forEach(({ sequenceId, key }) => {
          getAliquotEntity({
            key,
            sequenceId,
            keyedSequences,
            inventoryCheckMap,
            selectedAliquotForSequence,
            addToMap: true,
            hideDryAliquots,
            showInvalidSamples
          });
        });
      });
    });

    return {
      inventoryCheckMap,
      sequenceIdsAlreadySelected,
      keyedSequences,
      finalSelectedAliquots
    };
  };

  handleHideDryAliquots = hideDryAliquots => {
    const {
      stepFormProps: { change },
      selectedAliquotForSequence = {}
    } = this.props;
    if (hideDryAliquots) {
      const newSelectedAliquotsForSequence = { ...selectedAliquotForSequence };
      Object.keys(newSelectedAliquotsForSequence).forEach(key => {
        if (newSelectedAliquotsForSequence[key].isDry) {
          delete newSelectedAliquotsForSequence[key];
        }
      });
      change("selectedAliquotForSequence", newSelectedAliquotsForSequence);
    }
  };

  handleShowInvalidSamples = showInvalidSamples => {
    const {
      stepFormProps: { change },
      selectedAliquotForSequence = {},
      changeFormValue
    } = this.props;
    if (!showInvalidSamples) {
      const newSelectedAliquotsForSequence = { ...selectedAliquotForSequence };
      Object.keys(newSelectedAliquotsForSequence).forEach(key => {
        if (
          newSelectedAliquotsForSequence[key].sample?.sampleStatusCode ===
          "INVALID"
        ) {
          delete newSelectedAliquotsForSequence[key];
        }
      });
      change("selectedAliquotForSequence", newSelectedAliquotsForSequence);
      const { inventoryCheckMap } = this.buildInventoryCheckMap();
      Object.keys(inventoryCheckMap).forEach(key => {
        const tableForm = key + "InventoryMaterials";
        const selectedEntities =
          this.props[tableForm + "SelectedEntities"] || [];
        const newSelectedEntities = selectedEntities.filter(e => {
          if (e.aliquotOptions?.length === 1) {
            return e.aliquotOptions[0].sample.sampleStatusCode !== "INVALID";
          } else {
            return true;
          }
        });
        if (newSelectedEntities.length !== selectedEntities.length) {
          changeFormValue(
            tableForm,
            "reduxFormSelectedEntityIdMap",
            newSelectedEntities.reduce((acc, e) => {
              acc[e.id] = { entity: e };
              return acc;
            }, {})
          );
        }
      });
    }
  };

  render() {
    // const { activeTab } = this.state;
    const {
      Footer,
      footerProps,
      sequencesLoading,
      selectedAliquotForSequence = {},
      handleSubmit,
      onSubmit,
      stepFormProps: { change },
      sourcePlateMapGroup,
      toolSchema
    } = this.props;
    if (sequencesLoading) {
      return (
        <div className="tg-step-form-section" style={{ width: "100%" }}>
          <Loading inDialog bounce />
        </div>
      );
    }

    const {
      inventoryCheckMap,
      sequenceIdsAlreadySelected,
      keyedSequences,
      finalSelectedAliquots
    } = this.buildInventoryCheckMap();

    const hasAutomaticSelection =
      some(selectedAliquotForSequence, "automated") ||
      Object.keys(inventoryCheckMap).some(key => {
        const tableForm = key + "InventoryMaterials";
        const selectedEntities =
          this.props[tableForm + "SelectedEntities"] || [];
        return selectedEntities.some(entity => entity.automated);
      });

    forEach(inventoryCheckMap, ({ allSequenceMap, orderMaterials }) => {
      forEach(allSequenceMap, (sequence, id) => {
        if (!sequenceIdsAlreadySelected[id]) {
          orderMaterials.push(keyedSequences[id]);
        }
      });
    });
    // const activeInventoryMap = inventoryCheckMap[activeTab];
    // const tableForm = activeTab + "InventoryMaterials";
    const orderMaterials = flatMap(materialTabs, tab => {
      return inventoryCheckMap[tab].orderMaterials;
    });
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Inventory Check"
              helper="Select materials from inventory. Anything not selected will be put into an order list."
            />
            <div>
              <CheckboxField
                label="Hide Dry Aliquots"
                name="hideDryAliquots"
                onFieldSubmit={this.handleHideDryAliquots}
              />
              <CheckboxField
                label="Show Invalid Samples"
                name="showInvalidSamples"
                onFieldSubmit={this.handleShowInvalidSamples}
              />
            </div>
          </div>

          <AutomateSelectionField
            {...{
              hasAutomaticSelection,
              clearAutomatedSelection: this.clearAutomatedSelection,
              automateSelection: this.automateAliquotSelection
            }}
          />

          <div style={{ marginTop: 20 }} />
          {materialTabs.map((materialTab, i) => {
            const activeInventoryMap = inventoryCheckMap[materialTab];
            const tableForm = materialTab + "InventoryMaterials";
            return (
              <React.Fragment key={i}>
                {startCase(materialTab)}
                {activeInventoryMap.inventoryMaterials.length ? (
                  <DataTable
                    className="tg-materials-in-inventory-table"
                    isSimple
                    withCheckboxes
                    isEntityDisabled={record =>
                      record.aliquotOptions.length > 1 && !record.chosenAliquot
                    }
                    formName={tableForm}
                    key={tableForm}
                    destroyOnUnmount={false}
                    keepDirtyOnReinitialize
                    updateUnregisteredFields
                    maxHeight={350}
                    style={{ marginTop: 15 }}
                    entities={activeInventoryMap.inventoryMaterials}
                    schema={[
                      {
                        type: "action",
                        width: 35,
                        render: (_, record) => {
                          if (
                            record.chosenAliquot &&
                            record.chosenAliquot.isDry
                          ) {
                            return (
                              <Tooltip content="This aliquot is dry and will need to be rehydrated before running PCR.">
                                <Icon
                                  intent="warning"
                                  style={{ marginRight: 10 }}
                                  icon="warning-sign"
                                />
                              </Tooltip>
                            );
                          }
                        }
                      },
                      "name",
                      {
                        displayName: "Sample Status",
                        render(v, r) {
                          if (r.chosenAliquot)
                            return startCase(
                              r.chosenAliquot.sample?.sampleStatusCode?.toLowerCase()
                            );
                        }
                      },
                      {
                        displayName: "Plate",
                        render: (v, r) => {
                          if (r.chosenAliquot) {
                            return get(
                              r,
                              "chosenAliquot.aliquotContainer.containerArray.name"
                            );
                          }
                        }
                      },
                      {
                        displayName: "Well",
                        render: (v, r) => {
                          if (r.chosenAliquot) {
                            return getAliquotContainerLocation(
                              get(r, "chosenAliquot.aliquotContainer") || {}
                            );
                          }
                        }
                      },
                      {
                        displayName: "Tube Barcode",
                        render: (v, r) => {
                          if (r.chosenAliquot) {
                            return get(
                              r,
                              "chosenAliquot.aliquotContainer.barcode.barcodeString"
                            );
                          }
                        }
                      },
                      {
                        displayName: "Volume",
                        render: (v, r) => {
                          if (r.chosenAliquot) {
                            let toRet = withUnitGeneric(
                              "chosenAliquot.volume",
                              "chosenAliquot.volumetricUnitCode"
                            )(r);

                            if (
                              !aliquotHasVolumeToTransfer(r.chosenAliquot) &&
                              !r.chosenAliquot.isDry
                            ) {
                              toRet = (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                  <AliquotVolumeLessThanDeadWarning />
                                  {toRet}
                                </div>
                              );
                            }

                            return toRet;
                          }
                        }
                      },
                      {
                        displayName: "Concentration",
                        render: (v, r) => {
                          if (r.chosenAliquot) {
                            return withUnitGeneric(
                              "chosenAliquot.concentration",
                              "chosenAliquot.concentrationUnitCode"
                            )(r);
                          }
                        }
                      },
                      {
                        displayName: "Mass",
                        render: (v, r) => {
                          if (r.chosenAliquot) {
                            return withUnitGeneric(
                              "chosenAliquot.mass",
                              "chosenAliquot.massUnitCode"
                            )(v, r);
                          }
                        }
                      },
                      {
                        displayName: "Select Aliquot",
                        render: (v, r) => {
                          if (r.aliquotOptions.length > 1 && r.chosenAliquot) {
                            return (
                              <Button
                                intent={Intent.DANGER}
                                text="Clear Aliquot"
                                onClick={() =>
                                  this.clearAliquotForSequence(
                                    r.sequenceId,
                                    tableForm
                                  )
                                }
                              />
                            );
                          } else if (r.aliquotOptions.length > 1) {
                            return (
                              <Button
                                intent={Intent.PRIMARY}
                                text="Select Aliquot"
                                onClick={() => this.selectAliquot(r, tableForm)}
                              />
                            );
                          }
                        }
                      }
                    ]}
                  ></DataTable>
                ) : (
                  <Callout
                    style={{ marginTop: 5, width: 550 }}
                    intent={Intent.WARNING}
                  >
                    No materials found in inventory. All materials will need to
                    be ordered for PCR.
                  </Callout>
                )}
                <br></br>
              </React.Fragment>
            );
          })}

          <div style={{ marginTop: 20 }}>
            <HeaderWithHelper header="Materials to Order" />
          </div>
          {orderMaterials.length ? (
            <React.Fragment>
              <div style={{ marginBottom: 10 }}>
                These materials will need to be ordered for PCR.
              </div>
              <DataTable
                className="tg-materials-to-order-table"
                isSimple
                style={{ marginTop: 15 }}
                entities={orderMaterials}
                schema={["name"]}
                formName="materialsToOrderForPCR"
              />
            </React.Fragment>
          ) : (
            <Callout style={{ width: 350 }} intent={Intent.SUCCESS}>
              All PCR materials selected from inventory.
            </Callout>
          )}
        </div>
        <NameOutputData
          toolSchema={toolSchema}
          change={change}
          sourcePlateMapGroup={sourcePlateMapGroup}
        />
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              loading={footerProps.submitting}
              intent={Intent.SUCCESS}
              onClick={handleSubmit(values => {
                return onSubmit({
                  ...values,
                  inventoryCheckMap,
                  finalSelectedAliquots
                });
              })}
            >
              Submit
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

const aliquotFragment = gql`
  fragment aliquotFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    sample {
      id
      sampleStatusCode
      material {
        id
        polynucleotideMaterialSequence {
          id
        }
      }
    }
    aliquotContainer {
      id
      name
      barcode {
        id
        barcodeString
      }
      aliquotContainerType {
        code
        deadVolume
        deadVolumetricUnitCode
      }
      rowPosition
      columnPosition
      updatedAt
      createdAt
      containerArray {
        id
        name
        updatedAt
        createdAt
        barcode {
          id
          barcodeString
        }
      }
    }
  }
`;
const sequenceFragment = gql`
  fragment inventoryCheckSequenceFragment on sequence {
    id
    name
    hash
    sequenceFragments {
      id
      index
      fragment
    }
  }
`;

export default compose(
  connect(null, {
    changeFormValue: change,
    initializeForm: initialize
  }),
  stepFormValues(
    "selectedPcrProtocol",
    "plateMapGroup",
    "selectedAliquotForSequence",
    "hideDryAliquots",
    "showInvalidSamples",
    "sourcePlateMapGroup"
  ),
  withSelectedEntities(
    "primaryTemplatesInventoryMaterials",
    "forwardPrimersInventoryMaterials",
    "reversePrimersInventoryMaterials"
  ),
  withProps(props => {
    const { plateMapGroup, sourcePlateMapGroup } = props;
    const sequenceIds = [];
    (sourcePlateMapGroup || plateMapGroup).plateMaps.forEach(pm => {
      pm.plateMapItems.forEach(pmi => {
        let pathPrefix = "";
        if (sourcePlateMapGroup) {
          pathPrefix = "j5Item.j5PcrReaction.";
        }
        const ptId = get(pmi, pathPrefix + "primaryTemplate.id");
        const fpId = get(pmi, pathPrefix + "forwardPrimer.sequence.id");
        const rpId = get(pmi, pathPrefix + "reversePrimer.sequence.id");
        [ptId, fpId, rpId].forEach(id => {
          if (id && !sequenceIds.includes(id)) sequenceIds.push(id);
        });
      });
    });
    return {
      sequenceIds
    };
  }),
  // query for aliquots separately so that they will be lab accessed controlled
  withQuery(aliquotFragment, {
    isPlural: true,
    options: props => {
      const { sequenceIds } = props;
      return {
        variables: {
          filter: {
            "sample.material.polynucleotideMaterialSequence.id": sequenceIds
          },
          pageSize: 1000000
        }
      };
    }
  }),
  withQuery(sequenceFragment, {
    isPlural: true,
    options: props => {
      const { sequenceIds } = props;

      return {
        variables: {
          filter: {
            id: sequenceIds
          },
          pageSize: sequenceIds.length
        }
      };
    }
  }),
  withProps(props => {
    const {
      sequences = [],
      aliquots = [],
      sequencesLoading,
      aliquotsLoading
    } = props;
    let reformattedSequences = sequences;
    if (aliquots.length) {
      const aliquotsBySequenceId = groupBy(
        aliquots,
        "sample.material.polynucleotideMaterialSequence.id"
      );
      reformattedSequences = sequences.map(s => {
        return {
          ...s,
          aliquots: aliquotsBySequenceId[s.id] || []
        };
      });
    }
    return {
      sequencesLoading: sequencesLoading || aliquotsLoading,
      sequences: reformattedSequences
    };
  })
)(InventoryCheck);

function getAliquotEntity({
  keyedSequences,
  key,
  sequenceId,
  inventoryCheckMap,
  selectedAliquotForSequence,
  addToMap,
  hideDryAliquots,
  showInvalidSamples
}) {
  const sequence = keyedSequences[sequenceId];
  let entity;
  if (sequence) {
    if (
      !inventoryCheckMap ||
      !inventoryCheckMap[key].allSequenceMap[sequenceId]
    ) {
      if (inventoryCheckMap) {
        inventoryCheckMap[key].allSequenceMap[sequenceId] = true;
      }
      const isInInventory = isSequenceInInventory(sequence);
      if (isInInventory) {
        const sharedFields = {
          // always use the sequence id
          id: sequence.id,
          sequenceId: sequence.id,
          name: sequence.name,
          type: key
        };
        const aliquotOptions = [];
        sequence.aliquots.forEach(aliquot => {
          if (aliquot.aliquotContainer) {
            if (!aliquot.isDry && aliquot.volume === 0) return;
            if (aliquot.isDry && hideDryAliquots) return;
            if (
              !showInvalidSamples &&
              aliquot.sample?.sampleStatusCode === "INVALID"
            )
              return;
            aliquotOptions.push(aliquot);
          }
        });
        let chosenAliquot = aliquotOptions[0];
        if (aliquotOptions.length > 1) {
          chosenAliquot = selectedAliquotForSequence[sequence.id] || undefined;
        }
        if (aliquotOptions.length) {
          entity = {
            aliquotOptions,
            chosenAliquot,
            ...sharedFields
          };
        }
      }
    }
  }
  if (addToMap && entity) {
    inventoryCheckMap[key].inventoryMaterials.push(entity);
  }
  return entity;
}

function getSequenceMap(sequences) {
  return keyBy(sequences, "id");
}
