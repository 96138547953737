/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Classes } from "@blueprintjs/core";
import { identity } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import {
  DataTable,
  DialogFooter,
  InputField,
  withSelectedEntities,
  withSelectTableRecords,
  wrapDialog
} from "@teselagen/ui";
import { safeQuery, safeUpsert } from "../apolloMethods";
import useTgQuery from "../apolloUseTgQuery";
import { hideDialog } from "../GlobalDialog";
import modelNameToLink from "../utils/modelNameToLink";

export async function createSequenceOrderTable(name, sequenceIds) {
  const fullSeqInfo = await safeQuery(
    [
      "sequence",
      /* GraphQL */ `
        {
          id
          name
          sequenceTypeCode
          fullSequence
        }
      `
    ],
    {
      variables: {
        filter: {
          id: sequenceIds
        }
      }
    }
  );
  const [table] = await safeUpsert("dataTable", {
    name,
    dataTableTypeCode: "SEQUENCE_ORDER",
    dataRows: fullSeqInfo.map(seq => {
      return {
        rowValues: {
          sequenceId: seq.id,
          sequenceName: seq.name,
          type: seq.sequenceTypeCode,
          sequence: seq.fullSequence
        }
      };
    })
  });
  return table;
}

function CreateSequenceOrderDialog({
  fragment,
  getSequenceForRecord,
  j5ReportId,
  selectTableRecords,
  handleSubmit,
  submitting,
  history,
  sequenceOrderDialogTableSelectedEntities: selectedSeqs = []
}) {
  const { entities = [], loading } = useTgQuery(fragment, {
    variables: {
      pageSize: 10000,
      filter: {
        j5ReportId
      }
    }
  });
  const [sequences, setSequences] = useState([]);

  async function onSubmit({ name }) {
    try {
      const table = await createSequenceOrderTable(
        name,
        selectedSeqs.map(s => s.id)
      );
      hideDialog();
      history.push(modelNameToLink(table));
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating table.");
    }
  }

  useEffect(() => {
    if (entities.length) {
      const sequencesForEnts = entities
        .map(getSequenceForRecord)
        .filter(identity)
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
      setSequences(sequencesForEnts);
      selectTableRecords(sequencesForEnts);
    }
  }, [entities, getSequenceForRecord, selectTableRecords]);

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div style={{ marginBottom: 12 }}>
          Choose which sequences you would like to order.
          <br />
          <br />
          Selected sequences will be output in a table to be used in{" "}
          <Link to="/tools/twist-ordering" target="_blank">
            Twist
          </Link>{" "}
          or{" "}
          <Link to="/tools/idt-ordering" target="_blank">
            IDT
          </Link>{" "}
          Ordering tools
        </div>
        <InputField name="name" label="Table Name" isRequired />
        <DataTable
          formName="sequenceOrderDialogTable"
          isLoading={loading}
          schema={[{ displayName: "Sequence Name", path: "name" }]}
          entities={sequences}
          isSimple
          withCheckboxes
        />
      </div>
      <DialogFooter
        hideModal={hideDialog}
        submitting={submitting}
        disabled={!selectedSeqs.length}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

export default compose(
  wrapDialog({
    title: "Create Order Table"
  }),
  reduxForm({
    form: "createSequenceOrderTable"
  }),
  withSelectedEntities("sequenceOrderDialogTable"),
  withSelectTableRecords("sequenceOrderDialogTable")
)(CreateSequenceOrderDialog);
