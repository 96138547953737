/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { useState, useEffect } from "react";

export function useIsSmallScreen() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1025);

  useEffect(() => {
    const onResize = () => {
      setIsSmallScreen(window.innerWidth <= 1025);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isSmallScreen;
}
