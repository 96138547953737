/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment lotRecordFragment on lot {
    id
    name
    description
    additiveMaterialId
    additiveMaterial {
      id
      name
      isDry
      molecularWeight
      additiveType {
        code
        name
      }
    }
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
    createdAt
    updatedAt
    lab {
      id
      name
    }
    user {
      id
      username
    }
  }
`;
