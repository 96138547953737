/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { sum, max, keyBy } from "lodash";
import Graph from "node-dijkstra";
import { getTextLength } from "../utils";
import {
  SPACE_BETWEEN_SHAPES,
  BACKBONE_BORDER_RADIUS,
  /* SHAPE_EXTRA_WIDTH, */
  SHAPE_WIDTH,
  BACKBONE_RING_HEIGHT,
  MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS,
  ELEMENT_LABEL_LINE_HEIGHT,
  ELEMENT_LABEL_EXTRA_WIDTH,
  ELEMENT_LABEL_EXTRA_HEIGHT,
  ELEMENT_LABEL_MIN_WIDTH,
  DIVIDER_WIDTH,
  ELEMENT_LABEL_TRACK_HEIGHT,
  ELEMENT_LABEL_MIN_SPACING,
  ELEMENT_LABEL_LINE_MERGE_NODE_DISTANCE,
  SHAPE_HEIGHT,
  ELEMENT_LABEL_TAILING_WIDTH,
  ELEMENT_LABEL_HEADER_HEIGHT
} from "../constants";
import clip from "liang-barsky";

let _fastUid = 0;
const fastUid = () => _fastUid++ + "";

const maxZeroIfEmpty = a => a.length && max(a);

export const getWidthOfSetShape = set => {
  return set.type === "divider" ? DIVIDER_WIDTH : SHAPE_WIDTH;
  // : getTextLength(set.name) + SHAPE_EXTRA_WIDTH
};

export const getWidthOfSchematicNoLabels = schematic =>
  2 * BACKBONE_BORDER_RADIUS +
  (schematic.schematicSets.length - 1) * SPACE_BETWEEN_SHAPES +
  sum(schematic.schematicSets.map(set => getWidthOfSetShape(set)));

export const getWidthOfSchematic = (schematic, elementLabelPositions) => {
  const noLabelWidth = getWidthOfSchematicNoLabels(schematic);
  const minLabel = Object.values(elementLabelPositions).reduce(
    (acc, p) => Math.min(acc, p.x),
    0
  );
  const maxWidth = Object.values(elementLabelPositions).reduce(
    (acc, p) => Math.max(acc, p.x + p.width),
    0
  );
  return Math.max(noLabelWidth, maxWidth - minLabel);
};

export const getHeightOfSchematic = (schematic, elementLabelPositions) => {
  return (
    BACKBONE_RING_HEIGHT +
    MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS +
    getElementLabelsHeight(elementLabelPositions)
  );
};

export const computeElementLabelHeight = set =>
  (set.schematicElements.length + 1) * ELEMENT_LABEL_LINE_HEIGHT +
  ELEMENT_LABEL_EXTRA_HEIGHT +
  ELEMENT_LABEL_HEADER_HEIGHT;

export const computeElementLabelWidth = set =>
  Math.max(
    maxZeroIfEmpty(set.schematicElements.map(el => getTextLength(el.name))) +
      ELEMENT_LABEL_EXTRA_WIDTH +
      ELEMENT_LABEL_TAILING_WIDTH,
    getTextLength(set.name) + ELEMENT_LABEL_EXTRA_WIDTH,
    ELEMENT_LABEL_MIN_WIDTH
  );

// const rangesDontOverlap = (a1, a2, b1, b2) => b1 > a2 || a1 > b2

const getSetIdToIndexMap = schematic =>
  schematic.schematicSets.reduce((acc, s, i) => {
    acc[s.id] = i;
    return acc;
  }, {});

const getLabelStats = schematic =>
  schematic.schematicSets
    .map(set => ({
      set,
      height: computeElementLabelHeight(set),
      width: computeElementLabelWidth(set)
    }))
    .sort((a, b) => a.height - b.height);

const getSetIdToXOffsetMap = schematic => {
  let offset = BACKBONE_BORDER_RADIUS;
  return schematic.schematicSets.reduce((acc, set) => {
    acc[set.id] = offset;
    offset += getWidthOfSetShape(set) + SPACE_BETWEEN_SHAPES;
    return acc;
  }, {});
};

const getSetIdToMidShapeX = schematic => {
  let offset = BACKBONE_BORDER_RADIUS;
  return schematic.schematicSets.reduce((acc, set) => {
    const width = getWidthOfSetShape(set);
    acc[set.id] = offset + width / 2;
    offset += width + SPACE_BETWEEN_SHAPES;
    return acc;
  }, {});
};

const getClosestValue = (x, a, b) =>
  Math.abs(x - a) < Math.abs(x - b) ? a : b;

const getClosestPositionInTrack = (
  positions,
  trackNumber,
  shapeX,
  width,
  height
) => {
  const numberOfTracksToCheckAbove =
    (height + ELEMENT_LABEL_MIN_SPACING) / ELEMENT_LABEL_TRACK_HEIGHT;

  // If the previously position label is too high or too low, then it doesn't affect
  // the label we are positioning.
  const labelsOccludingTrack = Object.values(positions)
    .filter(
      p =>
        !(
          p.track > trackNumber + numberOfTracksToCheckAbove ||
          ELEMENT_LABEL_TRACK_HEIGHT * p.track +
            p.height +
            ELEMENT_LABEL_MIN_SPACING <
            ELEMENT_LABEL_TRACK_HEIGHT * trackNumber
        )
    )
    .sort((a, b) => a.x - b.x);

  // Merge occluding labels that overlap into a single occluded region. This
  // simplifies finding regions big enough to fit the target label in.
  const occludedRegions = labelsOccludingTrack.reduce((acc, p) => {
    if (!acc.length) {
      acc.push([p.x, p.x + p.width]);
    } else {
      const lastEnd = acc[acc.length - 1][1];
      if (p.x + p.width < lastEnd) return acc;
      else if (lastEnd < p.x) acc.push([p.x, p.x + p.width]);
      else acc[acc.length - 1].splice(1, 1, p.x + p.width);
    }
    return acc;
  }, []);

  let bestX = -Infinity;
  const idealX = shapeX - width / 2;
  const neededSpace = width + 2 * ELEMENT_LABEL_MIN_SPACING;
  if (!occludedRegions.length) return idealX;
  for (let i = 0, ii = occludedRegions.length; i <= ii; i++) {
    const prev = occludedRegions[i - 1] ? occludedRegions[i - 1][1] : -Infinity;
    const next = occludedRegions[i] ? occludedRegions[i][0] : Infinity;
    const size = next - prev;

    if (size < neededSpace) continue;
    const leftmostOption = prev + ELEMENT_LABEL_MIN_SPACING;
    const rightmostOption = next - width - ELEMENT_LABEL_MIN_SPACING;

    if (leftmostOption <= idealX && idealX <= rightmostOption) return idealX;

    const mostIdealInRange = getClosestValue(
      idealX,
      leftmostOption,
      rightmostOption
    );
    bestX = getClosestValue(idealX, bestX, mostIdealInRange);
  }

  return bestX;
};

const getMaxTrackPosition = positions => {
  const maxY = Object.values(positions).reduce((acc, p) => {
    const top = ELEMENT_LABEL_TRACK_HEIGHT * p.track + p.height;
    return top > acc ? top : acc;
  }, 0);
  return Math.ceil(
    (maxY + ELEMENT_LABEL_MIN_SPACING) / ELEMENT_LABEL_TRACK_HEIGHT
  );
};

// Weighted Euclidean distance
const makeHorizontalWeight = 5; // (I think 5 looks good)
const getLabelDistanceFromIdeal = (idealX, x, track) =>
  Math.sqrt(
    (x - idealX) ** 2 +
      makeHorizontalWeight * (track * ELEMENT_LABEL_TRACK_HEIGHT) ** 2
  );

// Simple greedy algorithm.
export const computeElementLabelPositions = schematic => {
  const { schematicSets } = schematic;

  const setIdToIndex = getSetIdToIndexMap(schematic);
  const setIdToXOffset = getSetIdToXOffsetMap(schematic);
  const labelStats = getLabelStats(schematic);

  const distToMiddle = set =>
    Math.abs(schematicSets.length / 2 - setIdToIndex[set.id]);

  // The x value in position refers to the left of the label. The track
  // is the track (similar to y) of the bottom of the label.
  const positions = {};
  const labelsToAdd = labelStats.sort(
    (a, b) => distToMiddle(a.set) - distToMiddle(b.set)
  );
  for (const { set, width, height } of labelsToAdd) {
    const shapeOffset = setIdToXOffset[set.id];
    const shapeWidth = getWidthOfSetShape(set);
    const midShapeX = shapeOffset + shapeWidth / 2;

    const idealX = midShapeX - width / 2;

    let bestTrack,
      bestX,
      bestDistance = Infinity;
    const maxTrackPositionToTry = getMaxTrackPosition(positions);
    for (
      let trialTrack = 0;
      trialTrack <= maxTrackPositionToTry;
      trialTrack++
    ) {
      const x = getClosestPositionInTrack(
        positions,
        trialTrack,
        midShapeX,
        width,
        height
      );

      const dist = getLabelDistanceFromIdeal(idealX, x, trialTrack);
      if (dist < bestDistance) {
        bestDistance = dist;
        bestX = x;
        bestTrack = trialTrack;
      }
    }

    positions[set.id] = {
      id: set.id,
      height,
      width,
      x: bestX, // This is the x
      track: bestTrack,
      y: ELEMENT_LABEL_TRACK_HEIGHT * bestTrack
    };
  }

  return positions;
};

const l2Dist = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

// O(n^2)
const createLabelLineNodes = (schematic, positions) => {
  const nodes = Object.entries(getSetIdToMidShapeX(schematic)).map(
    ([setId, midX]) => ({
      setId,
      x: midX,
      y: -MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS + SHAPE_HEIGHT * 2
      //y: -MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS + SHAPE_HEIGHT / 2
      //y: -MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS + 70 / 2
    })
  );

  const margin = ELEMENT_LABEL_MIN_SPACING / 2;
  for (const { id, x, y, height, width } of Object.values(positions)) {
    nodes.push({
      x: x - margin,
      y: y - margin
    });
    nodes.push({
      x: x - margin,
      y: y + height + margin
    });
    nodes.push({
      x: x + width + margin,
      y: y + height + margin
    });
    nodes.push({
      x: x + width + margin,
      y: y - margin
    });
    nodes.push({
      labelSetId: id,
      x: x + width / 2,
      y: y - 1
    });
  }

  const realNodes = [];
  for (const node of nodes) {
    if (
      !realNodes.some(
        n => l2Dist(n, node) < ELEMENT_LABEL_LINE_MERGE_NODE_DISTANCE
      )
    ) {
      realNodes.push(node);
    }
  }

  return realNodes.map(node => ({ ...node, id: fastUid() }));
};

const isEdgeOccluded = (positions, n1, n2) =>
  Object.values(positions).some(p =>
    clip([n1.x, n1.y], [n2.x, n2.y], [p.x, p.y, p.x + p.width, p.y + p.height])
  );

// O(n^3)
const createLabelLineEdges = (nodes, positions) => {
  const edges = {};
  for (const n1 of nodes) {
    const nodeEdges = {};
    for (const n2 of nodes) {
      if (n1.id === n2.id) continue;
      if (!isEdgeOccluded(positions, n1, n2)) {
        nodeEdges[n2.id] = l2Dist(n1, n2);
      }
    }

    edges[n1.id] = nodeEdges;
  }
  return edges;
};

const getSetIdToTerminalNodesMap = (schematic, nodes) =>
  schematic.schematicSets.reduce((acc, s) => {
    acc[s.id] = {
      start: nodes.find(n => n.labelSetId === s.id).id,
      end: nodes.find(n => n.setId === s.id).id
    };
    return acc;
  }, {});

export const computeElementLabelLines = (schematic, positions) => {
  const nodes = createLabelLineNodes(schematic, positions);
  const edges = createLabelLineEdges(nodes, positions);

  const setIdToTerminalNodes = getSetIdToTerminalNodesMap(schematic, nodes);

  const graph = new Graph(edges);

  const nodeIdToNode = keyBy(nodes, "id");
  const setIdToPath = {};
  for (const [setId, { start, end }] of Object.entries(setIdToTerminalNodes)) {
    const path = graph.path(start, end);
    if (!path) continue;
    setIdToPath[setId] = path.map(nodeId => nodeIdToNode[nodeId]);
  }
  return setIdToPath;
};

export const getElementLabelsHeight = elementLabelPositions =>
  maxZeroIfEmpty(Object.values(elementLabelPositions).map(p => p.y + p.height));
