/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { DialogFooter, wrapDialog, InputField } from "@teselagen/ui";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

const SaveParameterPresetDialog = props => {
  const { hideModal, handleSubmit, parameters, refetch } = props;

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async values => {
    setSubmitting(true);
    const { name } = values;
    try {
      setSubmitting(true);

      await safeUpsert("customJ5Parameter", {
        // Always save j5Parameters with default values
        // TODO: maybe tg-iso-share j5Parameter upserts..
        ...getDefaultParamsAsCustomJ5ParamName(),
        ...parameters,
        name,
        isLocalToThisDesignId: null
      });
      await refetch();
      window.toastr.success(`Parameter preset '${name}' successfully saved.`, {
        ...(!props.isPreset && {
          link: "/settings/assembly-parameter-presets",
          linkText: "Open parameter presets settings",
          target: "_blank"
        })
      });
      hideModal();
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      window.toastr.error("Error saving parameter preset.");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <InputField
        name="name"
        autoFocus
        label="Parameter Preset Name"
        isRequired
      />
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default compose(
  wrapDialog({ title: "Assembly Parameter Preset" }),
  reduxForm({ form: "newParameterPreset" })
)(SaveParameterPresetDialog);
