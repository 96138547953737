/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { getItemIdByCid } from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { flattenTree } from "../../../../../tg-iso-design/utils/designStateUtils";
import defaultJ5OutputNamingTemplateMap from "../../../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import shortid from "shortid";

export default state => {
  const backboneIconId = getItemIdByCid(state, "icon", "ORIGIN_OF_REPLICATION");
  const userDefinedIconId = getItemIdByCid(state, "icon", "USER-DEFINED");

  const reactionId = shortid();
  const card1Id = shortid();
  const card2Id = shortid();
  const bbId = shortid();
  const insertId = shortid();

  return {
    ...flattenTree({
      id: shortid(),
      __typename: "card",
      isRoot: true,
      name: "Target Construct",
      circular: true,
      binCards: [
        {
          id: shortid(),
          __typename: "binCard",
          index: 0,
          bin: {
            id: bbId,
            direction: true,
            name: "Backbone",
            iconId: backboneIconId,
            __typename: "bin"
          }
        },
        {
          id: shortid(),
          __typename: "binCard",
          index: 1,
          bin: {
            id: insertId,
            direction: true,
            name: "Insert 1",
            iconId: userDefinedIconId,
            __typename: "bin"
          }
        }
      ],
      outputReaction: {
        id: reactionId,
        __typename: "reaction",
        assemblyMethodId: getItemIdByCid(state, "assemblyMethod", "mock"),
        name: "Mock Assembly",
        customJ5ParameterId: getItemIdByCid(
          state,
          "customJ5Parameter",
          "default"
        ),
        reactionJ5OutputNamingTemplates: Object.keys(
          defaultJ5OutputNamingTemplateMap
        ).map(outputTarget => ({
          id: shortid(),
          __typename: "reactionJ5OutputNamingTemplate",
          j5OutputNamingTemplate: {
            id: shortid(),
            __typename: "j5OutputNamingTemplate",
            ...defaultJ5OutputNamingTemplateMap[outputTarget]
          }
        })),
        cards: [
          {
            id: card1Id,
            __typename: "card",
            inputIndex: 0,
            circular: true,
            name: "Backbone",
            binCards: [
              { __typename: "binCard", id: shortid(), index: 0, binId: bbId }
            ]
          },
          {
            id: card2Id,
            __typename: "card",
            inputIndex: 1,
            circular: false,
            name: "Insert 1",
            binCards: [
              {
                __typename: "binCard",
                id: shortid(),
                index: 0,
                binId: insertId
              }
            ]
          }
        ]
      }
    }),
    junction: [
      {
        id: shortid(),
        junctionTypeCode: "SCARLESS",
        isPhantom: true,
        reactionId: reactionId,
        fivePrimeCardId: card1Id,
        fivePrimeCardEndBinId: bbId,
        fivePrimeCardInteriorBinId: bbId,
        threePrimeCardId: card2Id,
        threePrimeCardStartBinId: insertId,
        threePrimeCardInteriorBinId: insertId
      },
      {
        id: shortid(),
        junctionTypeCode: "SCARLESS",
        isPhantom: true,
        reactionId: reactionId,
        threePrimeCardId: card1Id,
        threePrimeCardStartBinId: bbId,
        threePrimeCardInteriorBinId: bbId,
        fivePrimeCardId: card2Id,
        fivePrimeCardEndBinId: insertId,
        fivePrimeCardInteriorBinId: insertId
      }
    ].reduce((acc, j) => {
      acc[j.id] = j;
      return acc;
    }, {})
  };
};
