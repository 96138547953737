/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable graphql/template-strings */

import gql from "graphql-tag";

const restrictionEnzymeFragment = gql`
  fragment restrictionEnzymeFragment on restrictionEnzyme {
    id
    name
    enzymeTypeCode
    description
    forwardSnipPosition
    recognitionLength
    recognitionRegex
    recognitionStart
    reverseSnipPosition
    reverseRecognitionRegex
    sequence
    enzymeTypeCode
  }
`;

const restrictionEnzymeVeFragment = gql`
  fragment restrictionEnzymeVeFragment on restrictionEnzyme {
    ...restrictionEnzymeFragment
    topSnipOffset: forwardSnipPosition
    forwardRegex: recognitionRegex
    bottomSnipOffset: reverseSnipPosition
    reverseRegex: reverseRecognitionRegex
    site: sequence
  }
  ${restrictionEnzymeFragment}
`;

export { restrictionEnzymeVeFragment };
export default restrictionEnzymeFragment;
