/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { formValues, reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import ZonesPerPlateField from "../../../PcrPlanningAndInventoryCheck/ZonesPerPlateField";
import TemperatureZoneOrientationField from "../../../PcrPlanningAndInventoryCheck/TemperatureZoneOrientationField";

function ChooseDistributeIntoTemperatureBlocks(props) {
  const {
    hideModal,
    handleSubmit,
    submitting,
    containerFormat,
    temperatureZoneOrientation,
    handleDistribute
  } = props;

  async function onSubmit(values) {
    try {
      await handleDistribute(values);
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message);
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {/* <RadioGroupField
          name="distributeMethod"
          defaultValue="assembly"
          options={[
            {
              label: "Distribute using Assembly Report Tm",
              value: "assembly"
            },
            {
              label:
                "Distribute using NEB Tm calculated from SantaLucia (1998) & Owczarzy (2004)",
              value: "neb"
            }
          ]}
        /> */}
        <TemperatureZoneOrientationField />
        <ZonesPerPlateField
          containerFormat={containerFormat}
          temperatureZoneOrientation={temperatureZoneOrientation}
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Distribute Parameters"
  }),
  reduxForm({
    form: "distributeTempZonesParameters"
  }),
  formValues("temperatureZoneOrientation")
)(ChooseDistributeIntoTemperatureBlocks);
