/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable graphql/template-strings */
import { get, cloneDeep } from "lodash";
import gql from "graphql-tag";
import { safeQuery, safeUpsert } from "../../src-shared/apolloMethods";

const cardSchematicFragment = gql`
  fragment cardSchematicFragment on card {
    id
    name
    design {
      id
      name
      labId
    }
    binCards {
      id
      index
      bin {
        id
        name
        direction
        icon {
          id
          cid
        }
        elements {
          id
          index
          name
        }
      }
    }
  }
`;

const iconCidToBaseType = {
  PROMOTER: "arrow",
  CDS: "cds",
  TERMINATOR: "terminator"
};

const binToType = bin => {
  const iconCid = bin.icon.cid;
  let type = iconCidToBaseType[iconCid] || "rectangle";
  if (type !== "rectangle") {
    type = (bin.direction ? "right-" : "left-") + type;
  }
  return type;
};

const createSchematic = async (card, design, fromDesign) => {
  let bins = [...card.binCards]
    .sort((a, b) => a.index - b.index)
    .map(ss => ss.bin);
  let backbone;
  if (bins[0].icon.cid === "ORIGIN_OF_REPLICATION") {
    backbone = bins[0];
    bins = bins.slice(1);
  }

  const [{ id: diaId }] = await safeUpsert("schematic", {
    name: fromDesign ? design.name : card.name,
    description: fromDesign ? design.description : "",
    labId: design.labId || null,
    backboneSet: {
      color: "#e0e3e6",
      name: get(backbone, "name", "Backbone"),
      type: "rectangle"
    },
    schematicSets: bins.map((bin, i) => {
      return {
        name: bin.name,
        color: "#e0e3e6",
        index: i,
        type: binToType(bin),
        schematicElements: [...bin.elements]
          .sort((a, b) => a.index - b.index)
          .map((el, j) => ({
            name: el.name,
            index: j
          }))
      };
    })
  });

  return diaId;
};

export const schematicFromCard = async (cardId, level) => {
  const card = cloneDeep(
    await safeQuery(cardSchematicFragment, {
      variables: { id: cardId }
    })
  );
  // Set default schematic name when card name is not assigned
  //{design.name} - {level} {card.name}
  const name = `${get(card, "design.name")} - ${level} ${get(card, "name") ||
    ""}`;
  card.name = name.trim(); // trim empty space if card name is not available

  return await createSchematic(card, card.design, false);
};

export default async designId => {
  const card = (
    await safeQuery(cardSchematicFragment, {
      isPlural: true,
      variables: {
        filter: {
          designId,
          isRoot: true
        }
      }
    })
  )[0];

  return await createSchematic(card, card.design, true);
};
