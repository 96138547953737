/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// import { get, uniq, times, flatMap } from 'lodash'
// import { ChangeSetsHelper } from '../../../utils/designEditUtils'
// import {
//   getSetsValidatingSet,
//   getFixedSetsOfValidatorGeneric,
//   getRegexOfValidatorGeneric
// } from '../../../selectors/validationSetSelectors'
// import {
//   getInjectingOperationOfSet,
//   getElementsInBin,
//   getReferencedValue,
//   getSequenceStringOfElement,
//   getItemOfType,
//   getInputCardsOfReaction
// } from '../../../selectors/designStateSelectors'
// import {
//   isSetOperationInjectedOverhang,
//   getOverhangRegexGeneric
// } from '../../../selectors/erdamSelectors'
// import { toJsRegexStr } from '../../../utils/validationSetUtils'
// import { getReverseComplementSequenceString } from '@teselagen/sequence-utils'

export default ({
  changeSetsHelper
  // setId,
  // elementId
}) => {
  // Implement this later.
  if (1) {
    window.console.log("TODO");
    return changeSetsHelper;
  }

  // const state = changeSetsHelper.execute()
  // changeSetsHelper = new ChangeSetsHelper(state)
  // const fakeFullState = { design: state }

  // const set = getItemOfType(fakeFullState, 'set', setId)
  // const validators = getSetsValidatingSet(fakeFullState, setId)

  // if (validators.length === 0) {
  //   return changeSetsHelper
  // } else if (validators.length > 1) {
  //   console.warn(
  //     'More than one set validating this set. Not autofilling validation.'
  //   )
  //   return changeSetsHelper
  // }

  // const validator = validators[0]

  // const allOperationIds = []
  // const allUnassignedOverhangs = []
  // let regexes = []
  // for (const isLeft of [0, 1]) {
  //   const fixedSets = getFixedSetsOfValidatorGeneric(
  //     fakeFullState,
  //     validator.id,
  //     isLeft
  //   )
  //   const operationIds = uniq(
  //     fixedSets
  //       .map(s => get(getInjectingOperationOfSet(fakeFullState, s.id), 'id'))
  //       .filter(x => x)
  //   )
  //   allOperationIds.push(...operationIds)
  //   if (operationIds.length !== 1) continue

  //   const operationId = operationIds[0]

  //   const unassignedOverhangs = fixedSets.filter(s => {
  //     if (!isSetOperationInjectedOverhang(fakeFullState, s.id, operationId))
  //       return false
  //     const elements = getElementsInBin(fakeFullState, s.id)
  //     if (elements.length !== 1) return false
  //     return /^n*$/i.test(elements[0].regex || '')
  //   })
  //   if (unassignedOverhangs.length > 1) {
  //     console.warn(
  //       'More than one unassigned overhang on a side. Not autofilling validation.'
  //     )
  //     return changeSetsHelper
  //   } else if (unassignedOverhangs.length !== 1) {
  //     continue
  //   }

  //   const unassignedOverhang = unassignedOverhangs[0]
  //   allUnassignedOverhangs[isLeft] = unassignedOverhang

  //   const enz = getReferencedValue(
  //     fakeFullState,
  //     'operation',
  //     operationId,
  //     'restrictionEnzymeId'
  //   )
  //   const overhangSize = Math.abs(
  //     enz.forwardSnipPosition - enz.reverseSnipPosition
  //   )

  //   const regex = fixedSets
  //     .map(s => {
  //       if (s.id === unassignedOverhang.id) {
  //         return '(' + times(overhangSize, () => 'n').join('') + ')'
  //       } else {
  //         return getElementsInBin(fakeFullState, s.id)[0].regex || ''
  //       }
  //     })
  //     .join('')

  //   regexes[isLeft] = regex
  // }
  // if (!allUnassignedOverhangs.length) return changeSetsHelper

  // if (uniq(allOperationIds).length !== 1) {
  //   if (uniq(allOperationIds).length !== 0) {
  //     console.warn(
  //       'More than one injecting operation. Not autofilling validation.'
  //     )
  //   }
  //   return changeSetsHelper
  // }

  // regexes = times(2, isLeft => {
  //   const regex =
  //     regexes[isLeft] ||
  //     getRegexOfValidatorGeneric(fakeFullState, validator.id, isLeft)
  //   return isLeft
  //     ? new RegExp('^' + toJsRegexStr(regex), 'i')
  //     : new RegExp(toJsRegexStr(regex) + '$', 'i')
  // })

  // let seqStr = getSequenceStringOfElement(fakeFullState, elementId)
  // if (!set.direction) {
  //   seqStr = getReverseComplementSequenceString(seqStr)
  // }

  // if (!regexes.every(regex => regex.test(seqStr))) return changeSetsHelper

  // const newOverhangStrings = regexes.map((regex, i) => {
  //   const match = seqStr.match(regex)
  //   const overhangStr = match && match[1]
  //   const overhangSet = allUnassignedOverhangs[i]
  //   if (!overhangStr || !overhangSet) return null
  //   else return overhangStr
  // })

  // const allOtherRegexes = flatMap(allOperationIds, operationId => {
  //   const inputCards = getInputCardsOfReaction(fakeFullState, operationId)
  //   const operation = getItemOfType(fakeFullState, 'operation', operationId)
  //   return (operation.isOutputCircular
  //     ? inputCards
  //     : inputCards.slice(0, -1)
  //   ).map(input =>
  //     getOverhangRegexGeneric(fakeFullState, input.id, operationId, false)
  //   )
  // })

  // for (let i = 0, ii = newOverhangStrings.length; i < ii; i++) {
  //   const overhangStr = newOverhangStrings[i]
  //   if (!overhangStr) continue
  //   if (
  //     doesRegexClash(overhangStr, allOtherRegexes) ||
  //     doesRegexClash(
  //       overhangStr,
  //       newOverhangStrings.filter((x, j) => x && j !== i)
  //     )
  //   )
  //     return changeSetsHelper
  // }

  // for (let i = 0, ii = newOverhangStrings.length; i < ii; i++) {
  //   const overhangStr = newOverhangStrings[i]
  //   if (!overhangStr) continue
  //   const overhangSet = allUnassignedOverhangs[i]
  //   const overhangElement = getElementsInBin(fakeFullState, overhangSet.id)[0]
  //   changeSetsHelper.updatePure('element', {
  //     id: overhangElement.id,
  //     name: overhangStr,
  //     regex: overhangStr
  //   })
  // }

  // return changeSetsHelper
};

// function doesRegexClash(regex, otherRegexes) {
//   regex = regex.toLowerCase()
//   if (regex === getReverseComplementSequenceString(regex)) return true
//   else if (otherRegexes.some((r = '') => (r || '').toLowerCase() === regex))
//     return true
//   else if (
//     otherRegexes.some(
//       (r = '') =>
//         getReverseComplementSequenceString((r || '').toLowerCase()) === regex
//     )
//   )
//     return true
//   return false
// }
