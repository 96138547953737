/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import recordViewEnhancer from "../recordViewEnhancer";
import AbstractRecord from "../AbstractRecord";
import TgSequenceEditor from "../TgSequenceEditor";
import modelNameToLink from "../utils/modelNameToLink";
import { getEnclosingFeatures } from "../utils/sequenceUtils";
import gql from "graphql-tag";
import { aaSizeInfo } from "../utils/libraryColumns";
import { associatedDesigns } from "../PartRecordView";
import aminoAcidRecordFragment from "../../../tg-iso-shared/src/fragments/aminoAcidRecordFragment";

class AminoAcidPartRecordView extends Component {
  state = {};

  render() {
    const { aminoAcidPart } = this.props;
    const { name, type, aminoAcidSequence, start, end } = aminoAcidPart;
    const enclosingFeatures = getEnclosingFeatures(
      aminoAcidPart,
      aminoAcidSequence,
      { isRegionAnnotation: true }
    );
    const recordInfo = [
      ["Name", name],
      ["Type", type],
      aaSizeInfo(aminoAcidPart),
      [
        "Enclosing Features",
        enclosingFeatures.map((f, i) => (
          <React.Fragment key={f.id}>
            <Link to={modelNameToLink(f.__typename, f.id)}>{f.name}</Link>
            {i < enclosingFeatures.length - 1 ? ", " : ""}
          </React.Fragment>
        ))
      ],
      [
        "Source Sequence",
        <Link
          key={aminoAcidSequence.id}
          to={modelNameToLink(
            aminoAcidSequence.__typename,
            aminoAcidSequence.id
          )}
        >
          {aminoAcidSequence.name}
        </Link>
      ],
      [
        associatedDesigns().displayName,
        associatedDesigns().render(null, aminoAcidPart)
      ]
    ];
    return (
      <AbstractRecord
        {...this.props}
        recordInfo={recordInfo}
        recordName="aminoAcidPart"
      >
        <TgSequenceEditor
          {...this.props}
          isProtein
          sequence={{ ...aminoAcidSequence, isProtein: true }}
          editorOpts={{ selectionLayer: { start, end } }}
          hoveredId={aminoAcidPart.id}
        />
      </AbstractRecord>
    );
  }
}

const fragment = gql`
  fragment aminoAcidPartRecordFragment on aminoAcidPart {
    id
    name
    type
    start
    end
    elements {
      id
      design {
        id
        name
      }
    }
    aminoAcidSequence {
      ...aminoAcidRecordFragment
    }
  }
  ${aminoAcidRecordFragment}
`;

export default recordViewEnhancer(fragment)(AminoAcidPartRecordView);
