/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default function getInternalizationPreferenceErrors(values) {
  const errors = {};

  const { rs5, o5, o3, rs3 } = values;

  // Right now the restriction sites must be on flanking sequences.
  if (rs5 !== "FLANKING_SEQUENCE") {
    errors.rs5 = "Must be on flanking sequence.";
  }
  if (rs3 !== "FLANKING_SEQUENCE") {
    errors.rs3 = "Must be on flanking sequence.";
  }

  let msg = null;
  if (o5 === "INSERT" && o3 === "INSERT") {
    msg = "Both overhangs cannot be on the insert.";
  }

  if (msg) {
    errors.o5 = msg;
    errors.o3 = msg;
  }

  return errors;
}

export const doesInternalizationPreferenceHaveErrors = values =>
  Object.values(getInternalizationPreferenceErrors(values)).some(x => x);
