/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// import Promise from 'bluebird'
import { times } from "lodash";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

export const bpToRegexStrMap = {
  a: "a",
  c: "c",
  g: "g",
  t: "t",
  u: "u",
  w: "[at]",
  s: "[cg]",
  m: "[ac]",
  k: "[gt]",
  r: "[ag]",
  y: "[ct]",
  b: "[cgt]",
  d: "[agt]",
  h: "[act]",
  v: "[acg]",
  n: "[acgt]"
};

export const toJsRegexStr = regex => {
  regex = (regex || "").toLowerCase();
  let s = "";
  for (const c of regex) {
    s += bpToRegexStrMap[c] || c;
  }
  s = s.replace(/(.)\{(\d+)\}/g, (match, char, num) =>
    times(Number(num), () => char).join("")
  );
  return s;
};
export const toRevCompJsRegexStr = regex => {
  regex = (regex || "").toLowerCase();
  regex = regex.replace(/(.)\{(\d+)\}/g, (match, char, num) =>
    times(Number(num), () => char).join("")
  );
  regex = getReverseComplementSequenceString(regex);
  let s = "";
  for (const c of regex) {
    s += bpToRegexStrMap[c] || c;
  }
  return s;
};
