/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  SHAPE_HEIGHT,
  SHAPE_WIDTH,
  SHAPE_EXTRA_WIDTH,
  SHAPE_BASE_COLOR
} from "../constants";
import {
  Rectangle,
  Arrow,
  Divider,
  Terminator,
  Cds,
  /* AssemblyScar,
  BluntRestrictionSite,
  FivePrimeOverhang,
  FivePrimeUtr,
  FivePrimeRestrictionSite, */
  Insulator,
  Operator,
  PrimerBindingSite,
  ProteaseSite,
  ProteinStabilityElement,
  RestrictionEnzymeRecognitionSite,
  RibonucleaseSite,
  RibosomeEntrySite,
  Signature,
  /* ThreePrimeOverhang,
  ThreePrimeRestrictionSite, */
  RnaStabilityElement
} from "../Shapes";

//Commented icons will be kept for a future discussion
export const IconsArray = [
  /* {
    shapeName: `3' Overhang`,
    shapeType: 'three_prime_overhang',
    shape: ThreePrimeOverhang,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_HEIGHT,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: `3' Restriction Site`,
    shapeType: 'three_prime_restriction_site',
    shape: ThreePrimeRestrictionSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_HEIGHT / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: `5' Overhang`,
    shapeType: 'five_prime_overhang',
    shape: FivePrimeOverhang,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_HEIGHT,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: `5' Utr`,
    shapeType: 'five_prime_utr',
    shape: FivePrimeUtr,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: `5' Restriction Site`,
    shapeType: 'five_prime_restriction_site',
    shape: FivePrimeRestrictionSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_HEIGHT,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: 'Assembly Scar',
    shapeType: 'assembly_junction',
    shape: AssemblyScar,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_HEIGHT,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: 'Blunt Restriction Site',
    shapeType: 'blunt_restriction_site',
    shape: BluntRestrictionSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  }, */
  {
    shapeName: "CDS",
    shapeType: "right-cds",
    shape: Cds,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: true
    }
  },
  {
    shapeName: "CDS",
    shapeType: "left-cds",
    shape: Cds,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_HEIGHT - SHAPE_EXTRA_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Divider",
    shapeType: "divider",
    shape: Divider,
    shapeProps: {
      x: SHAPE_HEIGHT,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Insulator",
    shapeType: "insulator",
    shape: Insulator,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Operator",
    shapeType: "operator",
    shape: Operator,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: (SHAPE_WIDTH + SHAPE_HEIGHT) / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Primer Binding Site",
    shapeType: "primer_binding_site",
    shape: PrimerBindingSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: (SHAPE_WIDTH + SHAPE_HEIGHT) / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Promoter",
    shapeType: "right-arrow",
    shape: Arrow,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: true
    }
  },
  {
    shapeName: "Promoter",
    shapeType: "left-arrow",
    shape: Arrow,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: 0,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Protease Site",
    shapeType: "protease_site",
    shape: ProteaseSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Protein Stability Element",
    shapeType: "protein_stability_element",
    shape: ProteinStabilityElement,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Restriction Enzyme Recognition Site",
    shapeType: "restriction_enzyme_recognition_site",
    shape: RestrictionEnzymeRecognitionSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Ribonuclease Site",
    shapeType: "ribonuclease_site",
    shape: RibonucleaseSite,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Ribosome Entry Site",
    shapeType: "ribosome_entry_site",
    shape: RibosomeEntrySite,
    shapeProps: {
      x: 8,
      y: SHAPE_WIDTH - 5,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "RNA Stability Element",
    shapeType: "rna_stability_element",
    shape: RnaStabilityElement,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Signature",
    shapeType: "signature",
    shape: Signature,
    shapeProps: {
      x: 8,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "Terminator",
    shapeType: "right-terminator",
    shape: Terminator,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: SHAPE_WIDTH,
      fill: SHAPE_BASE_COLOR,
      isRight: true
    }
  },
  {
    shapeName: "Terminator",
    shapeType: "left-terminator",
    shape: Terminator,
    shapeProps: {
      x: SHAPE_EXTRA_WIDTH,
      y: 0,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  },
  {
    shapeName: "User Defined",
    shapeType: "rectangle",
    shape: Rectangle,
    shapeProps: {
      x: 8,
      y: SHAPE_WIDTH / 2,
      fill: SHAPE_BASE_COLOR,
      isRight: false
    }
  }
];
