/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SelectField } from "@teselagen/ui";

function FillDirectionSelect({ disabled }) {
  return (
    <SelectField
      className="tg-no-form-group-margin"
      disabled={disabled}
      name="fillDirection"
      label="Fill Direction"
      options={["Right", "Left", "Up", "Down"]}
    />
  );
}

export default FillDirectionSelect;
