/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { takeEvery, call, select } from "redux-saga/effects";
import actions from "../../actions";
import designToJson from "./designToJson";
import designToCsvGenbank from "./designToCsvGenbank";
import { getDesign } from "../../../../../tg-iso-design/selectors/designStateSelectors";

function* handleExportDesign() {
  try {
    const design = yield select(getDesign);
    const success = yield call(designToJson, design);
    if (!success) return;
    yield call(window.toastr.success, "Successfully exported design.");
  } catch (e) {
    console.error("design export error 1251616:", e);
    yield call(window.toastr.error, "Error exporting design.");
  }
}

function* handleExportDesignCsvGenbank() {
  try {
    const design = yield select(getDesign);
    const success = yield call(designToCsvGenbank, design);
    if (!success) return;
    yield call(
      window.toastr.success,
      "Successfully exported csv/genbank design."
    );
  } catch (e) {
    console.error("design export error 101320:", e);
    yield call(window.toastr.error, "Error exporting design.");
  }
}

export default function* watchExportDesign() {
  yield takeEvery(actions.designIo.exportToJson, handleExportDesign);
  yield takeEvery(
    actions.designIo.exportToCsvGenbank,
    handleExportDesignCsvGenbank
  );
}
