/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Classes } from "@blueprintjs/core";

// function customShouldError({
//   values,
//   nextProps,
//   props, // not used in default implementation
//   initialRender,
//   lastFieldValidatorKeys,
//   fieldValidatorKeys,
//   structure
// }) {
//   if (initialRender) {
//     return true;
//   }
//   if (
//     props &&
//     nextProps &&
//     nextProps.activeStepIndex !== props.activeStepIndex
//   ) {
//     return true;
//   }
//   return (
//     !structure.deepEqual(values, nextProps && nextProps.values) ||
//     !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
//   );
// }

export function jumpToErrorOnFail({ containerClassName = ".bp3-dialog" } = {}) {
  return {
    onSubmitFail: function(errors, dispatch, submitError) {
      console.error("Error hit in stepForms onSubmit:", errors); //this is a little hack so that we can see the stack trace when onsubmit fails
      console.error("submitError:", submitError);
      setTimeout(() => {
        try {
          const selector = `${containerClassName} .${Classes.FORM_GROUP} .${Classes.INTENT_DANGER}`;
          const erroredEl = document.querySelector(selector);

          if (erroredEl) {
            erroredEl.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center"
            });
          }
        } catch (error) {
          console.error("error:", error);
        }
      }, 100);
    }
  };
}
