/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getAllOfType,
  getBinIdsSetInCard,
  getOutputtingReactionIdOfCard,
  getFieldOnItem,
  getReactionDepthMap,
  getReferencedValue,
  getBinIdsInCard,
  getInputCardsOfReaction,
  getLevelOfCard,
  getItemOfType,
  getInputReactionIdOfCard
} from "./designStateSelectors";
import { RESTRICTION_SITE } from "../constants/validatorBinRoles";
import {
  getGapSequence,
  toJsRegexStr
} from "../../tg-iso-shared/utils/enzymeUtils";
import tgCreateCachedSelector from "../utils/tgCreateCachedSelector";

/**
 * Junctions are created for all reactions. However, not all of these
 * junctions require any validation or the rendering of an indicator.
 * @param {Object} junction
 */
export function isJunctionValidated(junction) {
  return !junction.isPhantom && junction.junctionTypeCode !== "SCARLESS";
}

/**
 * The the overhang type code for one of the cards participating in the
 * junction.
 * @param {Object} junction
 * @param {boolean} fivePrimeCard
 */
export function getOverhangTypeOfJunction(junction, fivePrimeCard) {
  return fivePrimeCard
    ? junction.fivePrimeCardOverhangTypeCode
    : junction.threePrimeCardOverhangTypeCode;
}

export function getInteriorBinIdOfJunction(junction, fivePrimeCard) {
  return fivePrimeCard
    ? junction.fivePrimeCardInteriorBinId
    : junction.threePrimeCardInteriorBinId;
}

export function getBoundaryBinIdOfJunction(junction, fivePrimeCard) {
  return fivePrimeCard
    ? junction.fivePrimeCardEndBinId
    : junction.threePrimeCardStartBinId;
}

export function getCardIdOfJunction(junction, fivePrimeCard) {
  return fivePrimeCard ? junction.fivePrimeCardId : junction.threePrimeCardId;
}

export function doesJunctionInjectSequence(junction) {
  const { junctionTypeCode } = junction;
  if (junctionTypeCode === "TYPE_IIS_ENZYME") {
    const ohType5p = getOverhangTypeOfJunction(junction, true);
    const ohType3p = getOverhangTypeOfJunction(junction, false);
    return (
      (ohType5p === "ADAPTER" || ohType5p === "FLANKING_SEQUENCE") &&
      (ohType3p === "ADAPTER" || ohType3p === "FLANKING_SEQUENCE")
    );
  } else {
    return false;
  }
}

/**
 * Get a regexized version of the `bps` string of a junction. This means
 * that characters such as "n" are replaced with "[agtc]".
 * @param {Object} junction
 */
export function getRegexStringOfJunction(junction) {
  const bps = junction.bps || "";
  return toJsRegexStr(bps);
}

/**
 * Get all of the validated junctions in card. This will
 * include both ligated junctions and unligated junctions.
 * @param {Object} state
 * @param {string} cardId
 */
export const getValidatedJunctionIdsInCard = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  getBinIdsSetInCard,
  (junctions, binIds) => {
    return Object.values(junctions)
      .filter(j => {
        if (!isJunctionValidated(j)) return false;
        return (
          binIds[j.fivePrimeCardEndBinId] || binIds[j.threePrimeCardStartBinId]
        );
      })
      .map(j => j.id);
  }
)((state, cardId) => cardId);

/**
 * See if any of the bins on the cards participate in a validated junction.
 * @param {Object} state
 * @param {string} cardId
 */
export const doesCardHaveValidatedJunctionsToRender = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  getReactionDepthMap,
  getOutputtingReactionIdOfCard,
  getInputReactionIdOfCard,
  getValidatedJunctionIdsInCard,
  (
    junctions,
    reactionIdToDepth,
    outputtingReactionId,
    inputReactionId,
    junctionIds
  ) =>
    junctionIds.some(jId => {
      const j = junctions[jId];
      return (
        // Is this an unligated junction to render.
        j.reactionId === inputReactionId ||
        // Or can we render it a ligated junction.
        (outputtingReactionId &&
          reactionIdToDepth[outputtingReactionId] <=
            reactionIdToDepth[j.reactionId])
      );
    })
)((state, cardId) => cardId);

// (state, cardId) =>
//   !!(state, cardId).length

/**
 * Get all of the validated junctions that reference the bin.
 * @param {Object} state
 * @param {string} binId
 * @param {boolean} isInterior Whether to treat the bin as an interior bin or a boundary bin.
 */
export const getValidatedJunctionsOnBin = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  (state, binId) => binId,
  (state, binId, isInterior) => isInterior,
  (junctions, binId, isInterior) =>
    Object.values(junctions).filter(j => {
      if (!isJunctionValidated(j)) return false;
      if (isInterior) {
        return (
          binId === j.fivePrimeCardInteriorBinId ||
          binId === j.threePrimeCardInteriorBinId
        );
      } else {
        return (
          binId === j.fivePrimeCardEndBinId ||
          binId === j.threePrimeCardStartBinId
        );
      }
    })
)((state, binId, isInterior) => `${binId}:${!!isInterior}`);

/**
 * Get all of the validated junctions that reference the bin that have been ligated.
 * @param {Object} state
 * @param {string} cardId
 * @param {string} binId
 */
export const getLigatedValidatedJunctionIdsOnBin = tgCreateCachedSelector(
  getReactionDepthMap,
  getOutputtingReactionIdOfCard,
  (state, cardId, binId) => getValidatedJunctionsOnBin(state, binId, true),
  (reactionIdToDepth, outputtingReactionId, junctions) =>
    junctions
      .filter(
        j =>
          outputtingReactionId &&
          reactionIdToDepth[outputtingReactionId] <=
            reactionIdToDepth[j.reactionId]
      )
      .map(j => j.id)
)((state, cardId, binId) => `${cardId}:${binId}`);

/**
 * Get all of the validated junctions that reference the bin that haven't been ligated.
 * @param {Object} state
 * @param {string} cardId
 * @param {string} binId
 */
export const getUnligatedValidatedJunctionIdsToRenderOnBin = tgCreateCachedSelector(
  getInputReactionIdOfCard,
  (state, cardId, binId) => getValidatedJunctionsOnBin(state, binId, false),
  (inputReactionId, junctions) =>
    junctions.filter(j => j.reactionId === inputReactionId).map(j => j.id)
)((state, cardId, binId) => `${cardId}:${binId}`);

/**
 * Get all of junctions on the card have been ligated.
 * @param {Object} state
 * @param {string} cardId
 */
export const getLigatedJunctionsOnCard = tgCreateCachedSelector(
  getReactionDepthMap,
  state => getAllOfType(state, "junction"),
  getOutputtingReactionIdOfCard,
  getValidatedJunctionIdsInCard,
  (reactionIdToDepth, junctions, outputtingReactionId, junctionIdsOnCard) =>
    junctionIdsOnCard
      .filter(jId => {
        const j = junctions[jId];
        return (
          outputtingReactionId &&
          reactionIdToDepth[outputtingReactionId] <=
            reactionIdToDepth[j.reactionId]
        );
      })
      .map(jId => junctions[jId])
)((state, cardId) => cardId);

/**
 * When rendering ligated junctions on a bin, we only show a subset of the
 * ligated junctions associated with that bin. This prevents rendering of duplicate
 * icons.
 *
 * Some of the junctions might not be directly attached to the bin. This will happen
 * when we have adapter bins for type IIs enzyme.
 * @param {Object} state
 * @param {string} cardId
 * @param {string} binId
 */
export const getLigatedValidatedJunctionIdsToRenderOnBin = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  getLigatedValidatedJunctionIdsOnBin,
  (state, cardId, binId) => binId,
  (junctions, ligatedJunctionIds, binId) =>
    ligatedJunctionIds.filter(jId => {
      const j = junctions[jId];

      if (j.junctionTypeCode === "TYPE_IIS_ENZYME") {
        const injectsSequence = doesJunctionInjectSequence(j);
        const on5PrimeCard = j.fivePrimeCardInteriorBinId === binId;
        const overhangType = on5PrimeCard
          ? j.fivePrimeCardOverhangTypeCode
          : j.threePrimeCardOverhangTypeCode;

        if (on5PrimeCard && injectsSequence) {
          return true;
        } else if (!injectsSequence && overhangType === "INSERT") {
          return true;
        } else {
          return false;
        }
      } else {
        console.error(`Not handling ${j.junctionTypeCode} junctions yet.`);
        return false;
      }
    })
)((state, cardId, binId) => `${cardId}:${binId}`);

/**
 * Are the 5 prime and 3 prime bins joined together in this card, or is
 * this card a precursor to the reaction that operates on the junction.
 * @param {Object} state
 * @param {string} cardId
 * @param {string} junctionId
 */
export const isJunctionLigated = tgCreateCachedSelector(
  getReactionDepthMap,
  getOutputtingReactionIdOfCard,
  (state, cardId, junctionId) =>
    getFieldOnItem(state, "junction", junctionId, "reactionId"),
  (reactionIdToDepth, ligatingReactionId, outputtingReactionId) =>
    outputtingReactionId &&
    reactionIdToDepth[outputtingReactionId] <=
      reactionIdToDepth[ligatingReactionId]
)((state, cardId, junctionId) => `${cardId}:${junctionId}`);

/**
 * Given a validated junction bin id, does it span the entire bin or it
 * is validating just the edge.
 * @param {Object} state
 * @param {string} validatedJunctionBinId
 */
export const doesValidatorSpanBin = (state, validatedJunctionBinId) =>
  !!getFieldOnItem(
    state,
    "validatedJunctionBin",
    validatedJunctionBinId,
    "spansBin"
  );

/**
 * Is the given validated junction bin on the three piece piece that forms the junction.
 * @param {Object} state
 * @param {string} validatedJunctionBinId
 */
export const isOnThreePrimePiece = (state, validatedJunctionBinId) =>
  !!getFieldOnItem(
    state,
    "validatedJunctionBin",
    validatedJunctionBinId,
    "threePrimeValidatedJunctionId"
  );

/**
 * These maps assume that the validator bin is on the five prime assembly piece. To get
 * the values for the three prime piece, simply take the negation of the values.
 */
const roleToIsThreePrimeEdge = {
  [RESTRICTION_SITE]: true
};

/**
 * Does the given validated junction bin validate the 3 prime edge of the bin?
 * Ignore this selector if the validated junction bin spans the entire bin. In
 * that case, this selector might give wrong results.
 * @param {Object} state
 * @param {string} validatedJunctionBinId
 */
export const isThreePrimeEdgeValidated = tgCreateCachedSelector(
  isOnThreePrimePiece,
  (state, id) => getFieldOnItem(state, "validatedJunctionBin", id, "role"),
  (onThreePrimePiece, role) => {
    if (!(role in roleToIsThreePrimeEdge))
      throw new Error(`Unsupported validated junction bin role: ${role}`);

    let isThreePrimeEdge = roleToIsThreePrimeEdge[role];
    if (onThreePrimePiece) isThreePrimeEdge = !isThreePrimeEdge;
    return isThreePrimeEdge;
  }
)((state, validatedJunctionBinId) => validatedJunctionBinId);

/**
 * Get the length of the "bps" portion of a validated junction. For things
 * such as type IIs restriction sites, this won't include the recognition site.
 * @param {Object} state
 * @param {string} validatedJunctionId
 */
export const getLengthOfValidatedJunction = (state, validatedJunctionId) =>
  getFieldOnItem(state, "validatedJunction", validatedJunctionId, "bps").length;

/**
 * Get an enum giving the state of the junction sequence with respect to bin boundaries.
 * Here are the possible values:
 *    - INT_5_PRIME: The entire junction sequence is 5 prime of the bin boundary with at least 1 bp of buffer.
 *    - EDGE_5_PRIME: The three prime edge of the junction sequence touches the bin boundary.
 *    - SPAN: The junction sequence spans the bin boundary.
 *    - EDGE_3_PRIME: The five prime edge of the junction sequence touches the bin boundary.
 *    - INT_3_PRIME: The entire junction sequence is 3 prime of the bin boundary with at least 1 bp of buffer.
 * @param {Object} state
 * @param {string} validatedJunctionId
 */
export const getOverlapPositionEnum = tgCreateCachedSelector(
  getLengthOfValidatedJunction,
  (state, validatedJunctionId) =>
    getFieldOnItem(
      state,
      "validatedJunction",
      validatedJunctionId,
      "relativePosition"
    ),
  (length, relativePosition) => {
    const edge5p = -Math.floor(length / 2);
    const edge3p = Math.ceil(length / 2);
    if (relativePosition < edge5p) return "INT_5_PRIME";
    else if (relativePosition === edge5p) return "EDGE_5_PRIME";
    else if (relativePosition < edge3p) return "SPAN";
    else if (relativePosition === edge3p) return "EDGE_3_PRIME";
    else return "INT_3_PRIME";
  }
)((state, validatedJunctionId) => validatedJunctionId);

/**
 * Given a validated junction get the id of the card that corresponds to
 * one of the pieces making up the junction. Each validated junction is associated
 * with the reaction that ligates the junction. The returned card id will belong
 * to one of the input cards of that reaction.
 * @param {Object} state
 * @param {string} validatedJunctionId
 * @param {boolean} fivePrimePiece Should we get the card corresponding the to piece that forms the five prime end of the junction?
 */
export const getInputCardIdOfJunction = tgCreateCachedSelector(
  state => state,
  (state, id) => getItemOfType(state, "junction", id),
  (state, validatedJunctionId, fivePrimePiece) => fivePrimePiece,
  (state, junction, fivePrimePiece) => {
    const binId = fivePrimePiece
      ? junction.fivePrimeCardEndBinId
      : junction.threePrimeCardStartBinId;

    const inputCards = getInputCardsOfReaction(state, junction.reactionId);
    for (const card of inputCards) {
      const binIds = getBinIdsInCard(state, card.id);
      if (binIds.includes(binId)) {
        return card.id;
      }
    }
  }
)(
  (state, validatedJunctionId, fivePrimePiece) =>
    `${validatedJunctionId}:${!!fivePrimePiece}`
);

/**
 * See `getInputCardIdOfJunction` for more documentation. Get the level of
 * the card that it returns.
 * @param {Object} state
 * @param {string} junctionId
 * @param {boolean} fivePrimePiece Should we get the level of the card corresponding the to piece that forms the five prime end of the junction?
 */
export const getLevelOfInputCardOfJunction = (
  state,
  junctionId,
  fivePrimePiece
) =>
  getLevelOfCard(
    state,
    getInputCardIdOfJunction(state, junctionId, fivePrimePiece)
  );

export const getTerminalBinIdOfJunction = tgCreateCachedSelector(
  (state, id, onFivePrimePiece) => onFivePrimePiece,
  (state, id) => getFieldOnItem(state, "junction", id, "fivePrimeCardEndBinId"),
  (state, id) =>
    getFieldOnItem(state, "junction", id, "threePrimeCardStartBinId"),
  (onFivePrimePiece, bin5p, bin3p) => (onFivePrimePiece ? bin5p : bin3p)
)((state, junction, onFivePrimePiece) => `${junction}:${!!onFivePrimePiece}`);

export const getOverhangTypeCodeOfJunction = tgCreateCachedSelector(
  (state, id, onFivePrimePiece) => onFivePrimePiece,
  (state, id) =>
    getFieldOnItem(state, "junction", id, "fivePrimeCardOverhangTypeCode"),
  (state, id) =>
    getFieldOnItem(state, "junction", id, "threePrimeCardOverhangTypeCode"),
  (onFivePrimePiece, ot5p, ot3p) => (onFivePrimePiece ? ot5p : ot3p)
)((state, junction, onFivePrimePiece) => `${junction}:${!!onFivePrimePiece}`);

export const getRecognitionRegexStringOfJunction = tgCreateCachedSelector(
  (state, junctionId) =>
    getReferencedValue(state, "junction", junctionId, "restrictionEnzymeId"),
  (state, junctionId, direction) => direction,
  (restrictionEnzyme, direction) => {
    const seq = direction
      ? restrictionEnzyme.recognitionRegex + getGapSequence(restrictionEnzyme)
      : getGapSequence(restrictionEnzyme) +
        restrictionEnzyme.reverseRecognitionRegex;
    return toJsRegexStr(seq);
  }
)((state, junctionId, direction) => `${junctionId}:${!!direction}`);

/**
 * Get the junction that forms one of the ends of the card. If no such junction exists,
 * return null.
 * @param {Object} state
 * @param {string} cardId
 * @param {boolean} fivePrimeEnd Whether the get the junction on the five prime or three prime end of the card.
 */
export const getJunctionOnCard = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  (state, cardId) => cardId,
  (state, cardId, fivePrimeEnd) => fivePrimeEnd,
  (junctions, cardId, fivePrimeEnd) => {
    for (const j of Object.values(junctions)) {
      const cardRef = getCardIdOfJunction(j, !fivePrimeEnd);
      if (cardRef === cardId) return j;
    }
    return null;
  }
)((state, cardId, fivePrimeEnd) => `${cardId}:${!!fivePrimeEnd}`);

/**
 * Get the bins in card that are present in both the card and its parent card.
 * For the root card, this will return all of the bins in the card.
 *
 * These bins can be thought of forming the input part in the reaction; however,
 * there are some caveats to this idea when considering digest reactions as the
 * overhangs might not be present on these bins.
 *
 * @param {Object} state
 * @param {string} cardId
 */
export const getNonTerminalBins = tgCreateCachedSelector(
  state => getAllOfType(state, "bin"),
  getBinIdsInCard,
  (state, cardId) => getJunctionOnCard(state, cardId, true),
  (state, cardId) => getJunctionOnCard(state, cardId, false),
  (bins, binIds, fivePrimeJunction, threePrimeJunction) => {
    const startIndex = fivePrimeJunction
      ? binIds.indexOf(getInteriorBinIdOfJunction(fivePrimeJunction, false))
      : 0;
    const endIndex = threePrimeJunction
      ? binIds.indexOf(getInteriorBinIdOfJunction(threePrimeJunction, true))
      : binIds.length - 1;
    return binIds.slice(startIndex, endIndex + 1).map(binId => bins[binId]);
  }
)((state, cardId) => cardId);

/**
 * Get the ids of all of the bins that contain a piece of the insert represented by the card.
 * This will include all of the non-terminal bins along with any bins that contains (at least
 * partially) an overhang.
 * @param {Object} state
 * @param {string} cardId
 */
export const getBinIdsContainingInsert = tgCreateCachedSelector(
  getBinIdsInCard,
  (state, cardId) => getJunctionOnCard(state, cardId, true),
  (state, cardId) => getJunctionOnCard(state, cardId, false),
  (binIds, fivePrimeJunction, threePrimeJunction) => {
    const startIndex = fivePrimeJunction
      ? binIds.indexOf(getBoundaryBinIdOfJunction(fivePrimeJunction, false))
      : 0;
    const endIndex = threePrimeJunction
      ? binIds.indexOf(getBoundaryBinIdOfJunction(threePrimeJunction, true))
      : binIds.length - 1;
    const insertIds = binIds.slice(startIndex, endIndex + 1);

    // For flanking sequence junctions, we will need to add the bins right outside
    // the given insertIds if they exist.
    if (
      fivePrimeJunction &&
      getOverhangTypeOfJunction(fivePrimeJunction, false) ===
        "FLANKING_SEQUENCE" &&
      startIndex !== 0
    ) {
      insertIds.unshift(binIds[startIndex - 1]);
    }
    if (
      threePrimeJunction &&
      getOverhangTypeOfJunction(threePrimeJunction, true) ===
        "FLANKING_SEQUENCE" &&
      endIndex !== binIds.length - 1
    ) {
      insertIds.push(binIds[endIndex + 1]);
    }
    return insertIds;
  }
)((state, cardId) => cardId);

/**
 * Given a reaction, get all of the bps of the junctions in that reaction, ignoring
 * the bps from a single provided junction.
 *
 * @param {Object} state
 * @param {string} reactionId
 * @param {string} junctionId Ignore the bps from this junction.
 */
export const getBpsForOtherJunctionsOfReaction = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  (state, reactionId) => reactionId,
  (state, reactionId, junctionId) => junctionId,
  (junctions, reactionId, junctionId) =>
    Object.values(junctions)
      .filter(j => j.reactionId === reactionId && j.id !== junctionId)
      .map(j => j.bps)
)((state, reactionId, junctionId) => `${reactionId}:${junctionId}`);

/**
 * See if the given bin forms one of the boundary bins of the insert
 * of the given card.
 * @param {string} cardId
 * @param {string} binId
 * @param {boolean} fivePrimeEnd
 */
export const isBinBoundaryOfInsert = tgCreateCachedSelector(
  (state, cardId, binId, fivePrimeEnd) =>
    getJunctionOnCard(state, cardId, fivePrimeEnd),
  (state, cardId, binId) => binId,
  (state, cardId, binId, fivePrimeEnd) => fivePrimeEnd,
  (junction, binId, fivePrimeEnd) => {
    return getBoundaryBinIdOfJunction(junction, !fivePrimeEnd) === binId;
  }
)(
  (state, cardId, binId, fivePrimeEnd) => `${cardId}:${binId}:${!!fivePrimeEnd}`
);
