/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* Copyright (C) 2019 TeselaGen Biotechnology, Inc. */

import { difference } from "lodash";
import { safeQuery } from "../apolloMethods";

export const findSequencesWithDuplicateSequenceParts = async validRecords => {
  const sequencesWithParts = await safeQuery(
    ["sequence", "id parts{id name start end strand}"],
    {
      isPlural: true,
      variables: {
        filter: {
          id: validRecords.map(r => r.id)
        },
        pageSize: validRecords.length
      }
    }
  );

  const sequencesWithDuplicatedParts = sequencesWithParts.filter((s, i) => {
    const { name, size } = validRecords[i];
    return s.parts.find(
      p =>
        p.start === 0 && p.end === size - 1 && p.name === name && p.strand === 1
    );
  });
  const sequencesWithoutDuplicatedParts = difference(
    sequencesWithParts,
    sequencesWithDuplicatedParts
  );

  return {
    sequencesWithoutDuplicatedParts,
    sequencesWithDuplicatedParts
  };
};

export const findAASequencesWithDuplicateAASequenceParts = async validRecords => {
  const aaSequencesWithParts = await safeQuery(
    ["aminoAcidSequence", "id aminoAcidParts{id name start end}"],
    {
      isPlural: true,
      variables: {
        filter: {
          id: validRecords.map(r => r.id)
        },
        pageSize: validRecords.length
      }
    }
  );

  const sequencesWithDuplicatedParts = aaSequencesWithParts.filter((s, i) => {
    const { name, size } = validRecords[i];
    return s.aminoAcidParts.find(
      p => p.start === 0 && p.end === size - 1 && p.name === name
    );
  });
  const sequencesWithoutDuplicatedParts = difference(
    aaSequencesWithParts,
    sequencesWithDuplicatedParts
  );

  return {
    sequencesWithoutDuplicatedParts,
    sequencesWithDuplicatedParts
  };
};
