/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniq, keyBy, last } from "lodash";
import {
  getItemIdByCid,
  getBinIdsInCard,
  getRootCardId,
  getChildCardIds,
  isLeafCard,
  getIdOfParentCard,
  getOutputtingReactionIdOfCard
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import { ChangeSetsHelper } from "../../../../../../tg-iso-design/utils/designEditUtils";
import { isViewClassic } from "../../../../selectors/classicViewSelectors";
import removeAndInsertCards from "../removeAndInsertCards";
import {
  getJunctionOnCard,
  getNonTerminalBins,
  getOverhangTypeOfJunction
} from "../../../../../../tg-iso-design/selectors/junctionSelectors";
import shortid from "shortid";

/**
 * Given a non-empty array of integer indices, return an array of 2-tuples
 * containing the first and last index (inclusive) of contiguous indices.
 * @param {Array<int>} indices
 */
function getContiguousIndices(indices) {
  indices = [...indices].sort((a, b) => a - b);

  const regions = [[indices[0], indices[0]]];
  for (let i = 1, ii = indices.length; i < ii; i++) {
    const ind = indices[i];
    const lastEnd = regions[regions.length - 1][1];
    if (lastEnd + 1 === ind) {
      regions[regions.length - 1][1] = ind;
    } else {
      regions.push([ind, ind]);
    }
  }
  return regions;
}

/**
 * This function assumes that all of the bins have been removed from
 * the subtree starting at the card.
 *
 * Returns true if we could remove the bins.
 *
 * @param {*} changeSetsHelper
 * @param {Object} state
 * @param {string} cardId The card we are removing bins from
 * @param {Set<string>} binIdsSet The set of bin ids we are removing.
 */
function removeBinsFromCard(changeSetsHelper, state, cardId, binIdsSet) {
  // For the root card, this will return all of the bins.
  const nonTerminalBinIds = getNonTerminalBins(state, cardId).map(b => b.id);
  const newNonTerminalBinIds = nonTerminalBinIds.filter(bId => !binIdsSet[bId]);

  // After removing bins, we must have at least one bin in the insert.
  if (!newNonTerminalBinIds.length)
    return {
      errorMessage: "Unable to remove bin, card must have at least one bin."
    };

  const binIdsInCard = getBinIdsInCard(state, cardId);
  const newBinIdsInCard = binIdsInCard.filter(bId => !binIdsSet[bId]);

  // Update the junctions
  for (const fivePrimeEnd of [true, false]) {
    const junc = getJunctionOnCard(state, cardId, fivePrimeEnd);
    if (!junc) continue;

    const overhangType = getOverhangTypeOfJunction(junc);

    const interiorBinKey = fivePrimeEnd
      ? "threePrimeCardInteriorBinId"
      : "fivePrimeCardInteriorBinId";
    const boundaryBinKey = fivePrimeEnd
      ? "threePrimeCardStartBinId"
      : "fivePrimeCardEndBinId";

    const interiorBinId = fivePrimeEnd
      ? newNonTerminalBinIds[0]
      : newNonTerminalBinIds[newNonTerminalBinIds.length - 1];

    if (overhangType === "ADAPTER") {
      const boundaryBinIndexId =
        newBinIdsInCard.indexOf(interiorBinId) + (fivePrimeEnd ? -1 : 1);
      const boundaryBinId = newBinIdsInCard[boundaryBinIndexId];

      // If we are removing from a card with an adapter, we need at least
      // one terminal bin to the outside of the interior bin to act as the
      // overhang.
      if (!boundaryBinId) return false;

      changeSetsHelper.updatePure("junction", {
        id: junc.id,
        [interiorBinKey]: interiorBinId,
        [boundaryBinKey]: boundaryBinId
      });
    } else {
      changeSetsHelper.updatePure("junction", {
        id: junc.id,
        [interiorBinKey]: interiorBinId,
        [boundaryBinKey]: interiorBinId
      });
    }
  }

  // Actually slate the bins for removal.
  const binIdsInCardSet = keyBy(binIdsInCard);
  Object.keys(binIdsSet).forEach(binId => {
    if (binIdsInCardSet[binId]) {
      changeSetsHelper.removeBin(cardId, binId);
    }
  });

  return true;
}

/**
 * Slate the proper mutations when we are in the classic view.
 */
function handleClassicView(changeSetsHelper, state, cardId, binIds) {
  const userDefinedIconId = getItemIdByCid(state, "icon", "USER-DEFINED");

  binIds.forEach(binId => changeSetsHelper.removeBin(cardId, binId));

  let newBinId = null;
  // If we are deleting all the bins in the set.
  if (getBinIdsInCard(state, cardId).length === uniq(binIds).length) {
    newBinId = shortid();
    changeSetsHelper.insertChild(cardId, 0, {
      id: newBinId,
      direction: true,
      name: "New Bin",
      iconId: userDefinedIconId
    });
  }

  const parentCardId = getRootCardId(state);
  const binIdsInCard = getBinIdsInCard(state, parentCardId);
  const deletes = binIds.map(binId => binIdsInCard.indexOf(binId));

  const newCardId = shortid();

  removeAndInsertCards({
    changeSetsHelper,
    fullState: state,
    cardId: parentCardId,
    deletes,
    inserts: newBinId ? [{ index: 0, child: newCardId }] : []
  });

  const cardIds = getChildCardIds(state, parentCardId);

  if (newBinId) {
    changeSetsHelper.createPure("binCard", {
      id: shortid(),
      index: 0,
      cardId: newCardId,
      binId: newBinId
    });

    // Remove all of the existing junctions
    for (const cId of cardIds) {
      changeSetsHelper.deletePure(
        "junction",
        getJunctionOnCard(state, cId, true).id
      );
    }

    // Create the new junction
    changeSetsHelper.createPure("junction", {
      junctionTypeCode: "SCARLESS",
      isPhantom: true,
      reactionId: getOutputtingReactionIdOfCard(state, cardId),
      fivePrimeCardId: newCardId,
      threePrimeCardId: newCardId,
      fivePrimeCardEndBinId: newBinId,
      fivePrimeCardInteriorBinId: newBinId,
      threePrimeCardStartBinId: newBinId,
      threePrimeCardInteriorBinId: newBinId
    });
  } else {
    // Do the junctions
    const contiguousRegions = getContiguousIndices(deletes);

    for (const region of contiguousRegions) {
      // Remove the interior junctions of the deleted region
      for (let i = region[0]; i <= region[1]; i++) {
        const junc = getJunctionOnCard(state, cardIds[i], true);
        changeSetsHelper.deletePure("junction", junc.id);
      }

      const remainingJunc = getJunctionOnCard(state, cardIds[region[1]], false);

      // If we have a deleted region that spans the origin, the remaining junction
      //  will get deleted,
      if (remainingJunc.isPhantom && contiguousRegions[0][0] === 0) {
        continue;
      }

      let isPhantom = false;
      let fivePrimeCardIndex = region[0] - 1;

      // We need some special logic when removing the first bins in the card.
      if (fivePrimeCardIndex === -1) {
        isPhantom = true;

        // Get the rightmost remaining card. We need special logic in case we
        // are removing the last bins.
        if (last(contiguousRegions)[1] === cardIds.length - 1) {
          fivePrimeCardIndex = last(contiguousRegions)[0] - 1;
        } else {
          fivePrimeCardIndex = cardIds.length - 1;
        }
      }

      // Reattach the remaining junction to the remaining five prime card.
      const fivePrimeCardId = cardIds[fivePrimeCardIndex];
      const fivePrimeBinId = getBinIdsInCard(state, fivePrimeCardId)[0];

      changeSetsHelper.updatePure("junction", {
        id: remainingJunc.id,
        isPhantom,
        fivePrimeCardId,
        fivePrimeCardEndBinId: fivePrimeBinId,
        fivePrimeCardInteriorBinId: fivePrimeBinId
      });
    }
  }
}

export default function removeBins(
  designState,
  { payload: { cardId, binIds } },
  state
) {
  const changeSetsHelper = new ChangeSetsHelper(designState);

  if (isViewClassic(state)) {
    handleClassicView(changeSetsHelper, state, cardId, binIds);
  } else {
    if (!isLeafCard(state, cardId)) {
      console.error(
        "Not handling deletes for non leaf cards yet. We will probably want to eventually support this with some restrictions."
      );
      return designState;
    }

    const binIdsSet = keyBy(binIds);

    let cId = cardId;
    while (cId) {
      const canRemove = removeBinsFromCard(
        changeSetsHelper,
        state,
        cId,
        binIdsSet
      );
      if (!canRemove || canRemove.errorMessage) {
        window.toastr.error(canRemove.errorMessage || "Unable to remove bins.");
        return designState;
      }
      cId = getIdOfParentCard(state, cId);
    }
  }

  return changeSetsHelper.execute({
    recomputeElementValidation: true,
    simplifyTrivialValidators: true,
    removeInaccessibleItems: true,
    removeNonsensicalExtraSequences: true,
    recomputeBinValidation: true,
    recomputeDigestFasValidation: true,
    updateInvalidMaterialAvailabilities: true
  });
}
