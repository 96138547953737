/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * Util function that adds a timeout to the promise.
 * @param {Promise} promise
 * @param {number | Function} timeout can either a number with the timeout time
 * or a function that receives a Promise.resolve callback and returns a setTimeout.
 * @returns {Promise}
 */

const DEFAULT_TIMEOUT = 10 * 1000;

export async function promiseTimeout(promise, timeout) {
  const timeoutFn =
    timeout instanceof Function
      ? timeout
      : resolve => setTimeout(resolve, parseInt(timeout | DEFAULT_TIMEOUT));
  return Promise.race([promise(), new Promise(resolve => timeoutFn(resolve))]);
}
