/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { times, isString } from "lodash";
import shortid from "shortid";
import {
  getOutputtingReactionOfCard,
  getInputCardsOfReaction
} from "../../../../../tg-iso-design/selectors/designStateSelectors";

export default ({
  changeSetsHelper,
  fullState,
  cardId,
  inserts = [],
  deletes = []
}) => {
  const reaction = getOutputtingReactionOfCard(fullState, cardId);

  const inputCards = getInputCardsOfReaction(fullState, reaction.id);

  deletes.sort((a, b) => a - b);

  const indexToInserts = inserts.reduce((acc, { index, child }) => {
    if (!acc[index]) acc[index] = [];
    acc[index].push(child);
    return acc;
  }, {});

  const indToDeltaIndex = [0];
  times(inputCards.length + 1, i => {
    if (indexToInserts[i]) indToDeltaIndex[i] += indexToInserts[i].length;
    indToDeltaIndex[i + 1] = indToDeltaIndex[i];
    if (deletes.includes(i)) indToDeltaIndex[i + 1]--;
  });

  times(inputCards.length + 1, i => {
    const deltaIndex = indToDeltaIndex[i];
    const inputCard = inputCards[i];

    // If the card at this index should be removed, remove it.
    // Otherwise, update the index of the card at the current position.
    if (deletes.includes(i)) {
      changeSetsHelper.deletePure("card", inputCard.id);
    } else if (inputCard && deltaIndex !== 0) {
      changeSetsHelper.updatePure("card", {
        id: inputCard.id,
        inputIndex: i + deltaIndex
      });
    }

    // If we are adding any cards at the current index, then add them.
    if (indexToInserts[i]) {
      indexToInserts[i].forEach((input, j) => {
        const inputIndex = i + j + deltaIndex - indexToInserts[i].length;
        // If we are passing the id of the card we want to insert.
        if (isString(input)) {
          changeSetsHelper.updatePure("card", {
            id: input,
            inputReactionId: reaction.id,
            inputIndex
          });
        } else {
          // Otherwise create a new card.
          const newSetId = input.id || shortid();
          changeSetsHelper.createPure("card", {
            ...input,
            inputReactionId: reaction.id,
            inputIndex,
            id: newSetId
          });
        }
      });
    }
  });
};
