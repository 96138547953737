/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const clientLogger = {
  // eslint-disable-next-line no-console
  log: console.log,
  error: console.error,
  info: console.info,
  warn: console.warn
};

export { clientLogger };
