/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { takeEvery, put, select, call } from "redux-saga/effects";
import actions from "../actions";
import {
  getTailOfUndoStack,
  getTailOfRedoStack
} from "../../selectors/miscDesignSelectors";
import { isDesignLocked } from "../../utils/designUtils/isDesignLocked";
import { handleSaveDesign } from "./saveDesign/index";

function* handleUndo() {
  try {
    // We can't edit locked designs.
    if (yield select(isDesignLocked)) {
      return;
    }

    const newDesignState = yield select(getTailOfUndoStack);
    if (!newDesignState) return;

    yield put(actions.ui.designEditor.undo.popUndoStack());
    yield put(
      actions.ui.designEditor.undo.setDesignFromUndoRedoStack(newDesignState)
    );

    // Autosave
    yield call(handleSaveDesign);
  } catch (e) {
    // Nothing async happening here, so this should only
    // be reached due to bugs in the code.
    console.error(e);
  }
}

function* handleRedo() {
  try {
    // We can't edit locked designs.
    if (yield select(isDesignLocked)) {
      return;
    }

    const newDesignState = yield select(getTailOfRedoStack);
    if (!newDesignState) return;

    yield put(actions.ui.designEditor.undo.popRedoStack());
    yield put(
      actions.ui.designEditor.undo.setDesignFromUndoRedoStack(newDesignState)
    );

    // Autosave
    yield call(handleSaveDesign);
  } catch (e) {
    // Nothing async happening here, so this should only
    // be reached due to bugs in the code.
    console.error(e);
  }
}

export default function* watchUndoRedo() {
  yield takeEvery(actions.ui.designEditor.undo.undo, handleUndo);
  yield takeEvery(actions.ui.designEditor.undo.redo, handleRedo);
}
