/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getMaterialIdsFromAliquotContainer } from "../../../utils/plateUtils";

/**
 * When transferring into a fermented batch sample we add sample formulations for the source materials
 *
 * This function only deals with updates to the destination; it does not affect the source
 * aliquots.
 * @param {FullFormulation} fullFormulation Must be a concentration change event.
 * @param {Object} options
 * @param {WriteBuffer} writeBuffer
 * @returns {string} The id of the destination aliquot.
 */
function executeFermentedBatchEvent(fullFormulation, options, writeBuffer) {
  const { upsert } = writeBuffer;

  const destAliquot = fullFormulation.getAliquot();
  const sourceAliquots = fullFormulation.getAllSourceAliquots();

  let sourceMaterialIds = [];
  sourceAliquots.forEach(aliquot => {
    sourceMaterialIds = sourceMaterialIds.concat(
      getMaterialIdsFromAliquotContainer({ aliquot })
    );
  });
  if (sourceMaterialIds.length) {
    if (destAliquot.sample?.sampleFormulations.length > 0) {
      // update the sample formulations
      const sampleFormulationId = destAliquot.sample.sampleFormulations[0].id;
      upsert(
        "materialComposition",
        sourceMaterialIds.map(matId => {
          return {
            materialId: matId,
            sampleFormulationId
          };
        })
      );
    } else {
      upsert("sampleFormulation", {
        formulatedSampleId: destAliquot.sample.id,
        materialCompositions: sourceMaterialIds.map(materialId => ({
          materialId
        }))
      });
    }
  }

  writeBuffer.upsert("aliquot", {
    id: destAliquot.id,
    concentration: fullFormulation.getConcentration(),
    concentrationUnitCode: fullFormulation.getConcentrationUnitCode(),
    ...fullFormulation.getCellConcentrationFields(),
    molarity: fullFormulation.getMolarity(),
    molarityUnitCode: fullFormulation.getMolarityUnitCode(),
    volume: fullFormulation.getVolume(),
    volumetricUnitCode: fullFormulation.getVolumetricUnitCode(),
    isDry: fullFormulation.isDry()
  });

  return destAliquot.id;
}

export default executeFermentedBatchEvent;
