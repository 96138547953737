/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { CheckboxField, InputField } from "@teselagen/ui";
import GenericSelect from "../../../src-shared/GenericSelect";

const MaterialUpdateSubform = ({ materialTypeCode }) => {
  const materialFields = [
    <InputField label="Name" key="name" name="name" isRequired />,
    <CheckboxField
      key="externalAvailability"
      name="externalAvailability"
      label="External Availability"
    />
  ];

  if (materialTypeCode === "MICROBIAL") {
    materialFields.push(
      <InputField
        label="Vendor Catalog Number"
        name="vendorCatalogNumber"
        key="vendorCatalogNumber"
        placeholder="Enter vendor catalog number..."
      />
    );

    materialFields.push(
      <GenericSelect
        name="genome"
        key="genome"
        asReactSelect
        label="Genome"
        schema={["name"]}
        fragment={["genome", "id name"]}
      />
    );
  }

  return materialFields;
};

export default MaterialUpdateSubform;
