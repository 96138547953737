/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import gql from "graphql-tag";
import PlateMapGroupRecordView from "../../Record/PlateMapGroupRecordView";

class PlateMapGroups extends Component {
  render() {
    return (
      <AbstractLibrary
        {...this.props}
        RecordViewInspector={PlateMapGroupRecordView}
        libraryName="plateMap"
        libraryTitle="Plate Maps"
        model="plateMapGroup"
        isLibraryTable
        withExport
        noNewItem
      />
    );
  }
}

const schema = {
  model: "plateMapGroup",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "plateMaps[0].type",
      render: val => val && modelNameToReadableName(val, { upperCase: true }),
      type: "string",
      displayName: "Type",
      filterDisabled: true,
      sortDisabled: true
    },
    {
      path: "containerFormat.name",
      type: "string",
      displayName: "Container Format"
    }
  ]
};

const fragment = gql`
  fragment plateMapGroupInvFragment on plateMapGroup {
    id
    name
    plateMaps {
      id
      name
      type
    }
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(PlateMapGroups);
