/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { ReactSelectField, TextareaField, InputField } from "@teselagen/ui";
import { Card, Elevation } from "@blueprintjs/core";
import customHelpDocConstants from "../CustomHelpDocsManagementPanel/customHelpDocConstants";
import CustomHelpDocWidget from "../CustomHelpDocsManagementPanel/CustomHelpDocWidget";
import SequenceFileUpload from "../SequenceFileUpload";
import { arrayToIdOrCodeValuedOptions } from "../utils/formUtils";
import { filterRnaString } from "@teselagen/sequence-utils";

function UploadOrPasteSequenceFields({
  casEnzymes = [],
  isFileUpload,
  isManualPasteScaffold,
  isRNA,
  isOligo,
  isMaterial,
  isGuideRNA,
  toggleFileUpload,
  toggleManualPasteScaffold,
  rnaTypes = [],
  fileLimit,
  isRequired
}) {
  let rnaTypeSelect;
  const [scaffoldSequence, setScaffoldSequence] = useState("");
  const [spacerSequence, setSpacerSequence] = useState("");
  if (isRNA && !isGuideRNA) {
    rnaTypeSelect = (
      <ReactSelectField
        name="rnaTypeId"
        label="RNA Type"
        options={arrayToIdOrCodeValuedOptions(
          rnaTypes.filter(rnaType => rnaType.name !== "gRNA")
        )}
      />
    );
  }

  return isFileUpload ? (
    <div>
      <CustomHelpDocWidget
        {...customHelpDocConstants.UPLOAD_DNA_SEQUENCE}
      ></CustomHelpDocWidget>
      <div style={{ textAlign: "right" }} className="upload-method">
        <a onClick={toggleFileUpload}>Paste Sequence</a>
      </div>
      {rnaTypeSelect}
      <SequenceFileUpload
        isRequired={isRequired}
        fileLimit={fileLimit}
        label={isMaterial ? "Upload Materials" : "Upload Sequences"}
        name="sequenceUpload"
        isRNA={isRNA}
        isOligo={isOligo}
        rnaTypes={rnaTypes}
        isMaterial={isMaterial}
        isGuideRNA={isGuideRNA}
      />
    </div>
  ) : (
    <div>
      <div style={{ textAlign: "right" }} className="upload-method">
        <a onClick={toggleFileUpload}>Upload File</a>
      </div>
      <InputField
        label="Name"
        name="sequenceName"
        placeholder="Custom sequence name (optional)"
      />
      {rnaTypeSelect}
      {isGuideRNA ? (
        <>
          <TextareaField
            label="Spacer Sequence"
            name="spacerSequence"
            isRequired
            placeholder="Paste your GenBank or FASTA sequence here"
            format={filterRnaString}
            onChange={event => {
              setSpacerSequence(filterRnaString(event.target.value));
            }}
          />
          <div style={{ textAlign: "right" }} className="upload-method">
            <a onClick={toggleManualPasteScaffold}>
              {isManualPasteScaffold
                ? "Select sequence from cas enzyme"
                : "Manually enter sequence"}
            </a>
          </div>
          {isManualPasteScaffold ? (
            <TextareaField
              label="Scaffold Sequence"
              name="scaffoldSequence"
              isRequired
              placeholder="Paste your GenBank or FASTA sequence here"
              format={s => {
                let string = "";
                if (typeof s === "string") string = filterRnaString(s);
                setScaffoldSequence(string);
                return string;
              }}
            />
          ) : (
            <ReactSelectField
              name="scaffoldSequence"
              label="Select Scaffold Sequence From Cas Enzyme"
              isRequired
              onChange={event => {
                if (event?.sequence) {
                  setScaffoldSequence(event.sequence);
                } else {
                  setScaffoldSequence("");
                }
              }}
              options={casEnzymes.map(casEnzyme => ({
                value: casEnzyme.scaffoldSequence,
                label: (
                  <div>
                    <div>{casEnzyme.name}</div>
                    <div
                      style={{
                        fontSize: "10px",
                        opacity: 0.5,
                        marginTop: "-7px",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {casEnzyme.scaffoldSequence.sequence}
                    </div>
                  </div>
                )
              }))}
            />
          )}
          {!!(spacerSequence + scaffoldSequence).length && (
            <Card elevation={Elevation.TWO} style={{ margin: "10px" }}>
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Concatenated Sequence Preview
              </p>
              <p
                style={{
                  wordWrap: "break-word",
                  maxHeight: "80px",
                  overflowY: "scroll"
                }}
              >
                {spacerSequence + scaffoldSequence}
              </p>
            </Card>
          )}
        </>
      ) : (
        <TextareaField
          label="Sequence"
          name="sequenceText"
          isRequired
          placeholder="Paste your GenBank or FASTA sequence here"
        />
      )}
    </div>
  );
}

export default UploadOrPasteSequenceFields;
