/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment dataTableTypeFragment on dataTableType {
    code
    name
    description
    rowSchema
    isSystemType
    updatedAt
  }
`;
