/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { allSubtypesToModel } from "./integrationTypeSettingsMap/importExportSubtypes";

export function getModelFromSubtype(subtype) {
  if (!subtype)
    throw new Error(
      "zoink! You betta pass a subtype to exampleRecordValidation"
    );
  const model = allSubtypesToModel[subtype];

  if (!model) {
    throw new Error(
      `zoink! You betta pass a subtype that maps to a model to exampleRecordValidation - ${subtype}`
    );
  }
  return model;
}
