/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Button } from "@blueprintjs/core";
import "./style.css";

const FormFooter = ({ submitting, history, onCancel }) => (
  <div className="form-footer">
    <div className="form-footer-actions">
      <Button minimal onClick={onCancel ? onCancel : () => history.goBack()}>
        Cancel
      </Button>
      <Button type="submit" intent="primary" loading={submitting}>
        Save
      </Button>
    </div>
  </div>
);

export default FormFooter;
