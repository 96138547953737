/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { sequenceScoringTableName } from "./SequenceScoringTable";

export function handleScoreUpdateAutoselect(classObject) {
  const {
    [sequenceScoringTableName + "SelectedEntities"]: selectedSequences = [],
    selectTableRecords,
    skipScoring,
    selectAllSequences: autoSelect,
    sequencesToScore
  } = classObject.props;
  const validSelected = selectedSequences.filter(
    s => !classObject.sequenceErrorMap[s.id]
  );

  const extraToSelect = [];
  if (autoSelect) {
    sequencesToScore.forEach(s => {
      if (
        !classObject.sequenceScoringMap[s.id] &&
        !classObject.sequenceErrorMap[s.id]
      ) {
        if (
          (classObject.scoredSequenceMap[s.id] || skipScoring) &&
          !selectedSequences.includes(s)
        ) {
          extraToSelect.push(s);
        }
      }
    });
  }
  if (extraToSelect.length) {
    selectTableRecords([...validSelected, ...extraToSelect]);
  } else if (validSelected.length !== selectedSequences.length) {
    selectTableRecords(validSelected);
  }
}
