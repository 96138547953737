/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { Classes, Intent, Icon, Tooltip } from "@blueprintjs/core";
import { compose } from "recompose";
import { get, every, size } from "lodash";
import classNames from "classnames";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import { TEST_METADATA, TEST_LABELS } from "../../../utils/experimentData";

const schema = {
  fields: [
    { path: "name", displayName: "Column" },
    { path: "className", displayName: TEST_LABELS.class },
    { path: "subClassName", displayName: TEST_LABELS.subClass }
  ]
};

const UseMappingPreset = ({ headers, preset, hideModal, submitMappings }) => {
  const [submitting, setSubmitting] = useState(false);
  const [isValidPreset, setIsValidPreset] = useState(false);

  const presetMappings = preset.header.map(mapping => ({
    name: mapping.name,
    className: get(TEST_METADATA[mapping.class], "label"),
    subClassName: mapping.subClassName,
    matchesColumn: headers.includes(mapping.name)
  }));

  const presetHeaders = preset.header.map(mapping => mapping.name);

  // Checks that the preset has a mapping for every column.
  useEffect(() => {
    setIsValidPreset(every(headers, header => presetHeaders.includes(header)));
  }, []); // eslint-disable-line

  async function handleSubmit() {
    setSubmitting(true);
    await submitMappings(preset);
    hideModal();
    setSubmitting(false);
  }

  const renderColumnHeaderCell = header => {
    // NOTE: not sure why the isValidPreset state is not updated.
    // For now Im just recomputing it.
    const _isValidPreset = every(headers, header =>
      presetHeaders.includes(header)
    );
    return (
      <>
        {header.matchesColumn ? (
          <p style={{ marginTop: 5 }}>
            {header.name}
            <Icon
              icon="small-tick"
              style={{ marginLeft: 10 }}
              intent={Intent.SUCCESS}
            />
          </p>
        ) : (
          <p style={{ marginTop: 5 }}>
            <span style={{ marginRight: 5 }}>{header.name}</span>
            <Tooltip
              className={Classes.TOOLTIP_INDICATOR}
              content="No columns match this preset"
            >
              <Icon
                icon={_isValidPreset ? "warning-sign" : "error"}
                style={{ marginLeft: 3 }}
                intent={_isValidPreset ? Intent.WARNING : Intent.DANGER}
              />
            </Tooltip>
          </p>
        )}
      </>
    );
  };

  const renderCell = value => {
    return <p style={{ marginTop: "5px", display: "flex" }}>{value}</p>;
  };

  const cellRenderer = () => {
    return {
      name: (_, row) => renderColumnHeaderCell(row),
      className: (_, row) => renderCell(row.className),
      subClassName: (_, row) => renderCell(row.subClassName)
    };
  };

  return (
    <React.Fragment>
      <div className={classNames(Classes.DIALOG_BODY)}>
        <h5>{preset.name}</h5>
        <DataTable
          formName="mappingPreset"
          cellRenderer={cellRenderer()}
          schema={schema}
          noHeader
          noSelect
          compact
          entities={presetMappings}
          pageSize={size(presetMappings)}
          noFooter
        />
      </div>
      <DialogFooter
        submitting={submitting}
        disabled={!isValidPreset}
        hideModal={hideModal}
        onClick={handleSubmit}
      />
    </React.Fragment>
  );
};

export default compose(wrapDialog({ style: { width: 800 } }))(UseMappingPreset);
