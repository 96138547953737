/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  createObjectValuedTypeMap,
  transformWithNewIds
} from "./designStateUtils";
import {
  PART_AND_SEQ_TYPES,
  TYPES_LINKED_TO_DESIGN
} from "../constants/designStateConstants";
import { v4 as uuid } from "uuid";

const createDuplicatedDesignInput = (
  designState,
  { duplicatePartsAndSequences = false } = {}
) => {
  const valuesToKeep = Object.entries(designState).reduce(
    (acc, [type, values]) => {
      if (
        type === "design" ||
        TYPES_LINKED_TO_DESIGN[type] ||
        (duplicatePartsAndSequences && PART_AND_SEQ_TYPES[type])
      )
        acc[type] = values;
      else acc[type] = {};
      return acc;
    },
    {}
  );

  const oldIdToNewIdMap = createObjectValuedTypeMap();
  for (const [type, values] of Object.entries(valuesToKeep)) {
    for (const item of Object.values(values)) {
      if (
        type === "customJ5Parameter" &&
        !valuesToKeep.customJ5Parameter[item.id].isLocalToThisDesignId
      ) {
        //we don't want to duplicate any customJ5Parameter set that isn't local to a design
        //don't duplicate the default customJ5Parameter set
        delete valuesToKeep.customJ5Parameter[item.id];
      } else {
        oldIdToNewIdMap[type][item.id] = uuid();
      }
    }
  }

  if (duplicatePartsAndSequences) {
    Object.values(valuesToKeep.sequence).forEach(seq => {
      seq.name = seq.name + " copy";
    });
  }

  const valuesToCreate = transformWithNewIds(valuesToKeep, oldIdToNewIdMap);
  const designId = Object.values(valuesToCreate.design)[0].id;
  for (const [type, values] of Object.entries(valuesToCreate)) {
    for (const item of Object.values(values)) {
      valuesToCreate[type][item.id] = item;
      if (type === "customJ5Parameter") {
        // There's a default customJ5Parameter whose cid equal 'default'
        // his default record should NOT be linked to a design, it is considered a generic template.
        if (valuesToCreate.customJ5Parameter[item.id].cid === "default") {
          valuesToCreate[type][item.id].isLocalToThisDesignId = null;
        } else {
          valuesToCreate[type][item.id].isLocalToThisDesignId = designId;
        }
      } else {
        if (TYPES_LINKED_TO_DESIGN[type]) {
          valuesToCreate[type][item.id].designId = designId;
        }
      }
    }
  }
  return valuesToCreate;
};

export default createDuplicatedDesignInput;
