/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { DataTable, InputField } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import dataTableTypeFragment from "../../../../graphql/fragments/dataTableTypeFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import ExportTableAsCsvButton from "../../../../../src-shared/ExportTableAsCsvButton";

class ReviewPlates extends Component {
  render() {
    const {
      inventoryCheckTable,
      dataTableTypes = [],
      dataTableName,
      sampleListName,
      sampleInventoryList,
      targetType,
      inputType
    } = this.props;
    const inventoryCheckOutputDataTableType = dataTableTypes.find(
      dtt => dtt.code === "INVENTORY_CHECK_OUTPUT"
    );
    const sampleListDataTableType = dataTableTypes.find(
      dtt => dtt.code === "SAMPLE_INVENTORY_LIST"
    );

    if (!inventoryCheckOutputDataTableType || !inventoryCheckTable) {
      return <div>Error loading table!</div>;
    }
    const inventoryCheckOutputTableProps = {
      schema: inventoryCheckOutputDataTableType.rowSchema,
      entities: inventoryCheckTable.dataRows
    };

    let sampleListTableProps = {};

    if (inputType !== "containerArray") {
      sampleListTableProps = {
        schema: sampleListDataTableType.rowSchema,
        entities: sampleInventoryList.dataRows
      };
    }
    return (
      <>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Review Plate Data"
            helper="Please review the selected plates and name the table."
            width="100%"
          />
          <InputField label="Table Name" name="dataTableName" isRequired />
          <div>
            <ExportTableAsCsvButton
              {...{
                ...inventoryCheckOutputTableProps,
                noId: true,
                filename: dataTableName || "dataTable",
                text: "Export Table"
              }}
            />
          </div>
          <DataTable
            formName="inventoryCheckReviewData"
            {...inventoryCheckOutputTableProps}
            isSimple
          />
        </div>
        {inputType !== "containerArray" && targetType === "material" && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Review Sample Data"
              helper="Please review the selected samples and name the table."
              width="100%"
              style={{ marginTop: 20 }}
            />
            <InputField
              label="Sample List Name"
              name="sampleListName"
              isRequired
            />
            <div>
              <ExportTableAsCsvButton
                {...{
                  ...sampleListTableProps,
                  noId: true,
                  filename: sampleListName || "sampleList",
                  text: "Export Table"
                }}
              />
            </div>
            <DataTable
              formName="sampleInventoryListData"
              isSimple
              {...sampleListTableProps}
            />
          </div>
        )}
      </>
    );
  }
}

export default compose(
  withQuery(dataTableTypeFragment, {
    showLoading: true,
    isPlural: true,
    idAs: "code",
    options: {
      variables: {
        filter: { code: ["INVENTORY_CHECK_OUTPUT", "SAMPLE_INVENTORY_LIST"] }
      }
    }
  }),
  stepFormValues(
    "inventoryCheckTable",
    "sampleInventoryList",
    "dataTableName",
    "sampleListName",
    "inputType",
    "targetType"
  )
)(ReviewPlates);
