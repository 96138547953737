/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, FileUploadField } from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";
import { DesignImport } from "../../utils/designImportProcess";

function UploadDesigns(props) {
  const { hideModal, handleSubmit, submitting, history, refetch } = props;
  async function onSubmit(values) {
    try {
      const designImport = new DesignImport(history, true, refetch);
      await designImport.processImport(values);
    } catch (err) {
      console.error(err);
      window.toastr.error(err.message || "Error uploading design.");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          accept={[".csv", ".json", ".xlsx", ".zip"]}
          label="Upload Existing Design Template File Here"
          name="inputFiles"
          fileLimit={1}
          isRequired
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Upload Design Template"
  }),
  reduxForm({
    form: "uploadDesignTemplates"
  })
)(UploadDesigns);
