/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import containerFormatFragment from "./fragments/containerFormatFragment";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { startCase } from "lodash";
import { singular } from "pluralize";

const annotationTypes = ["parts", "features", "primers"];

const oveDVCs = {
  ...annotationTypes.reduce((acc, type) => {
    const code = type + "_name";
    const prettyType = startCase(singular(type));
    acc[code] = {
      code,
      fieldType: "input",
      name: `${prettyType} Name`,
      params: {
        isProtein: false,
        sequenceName: "Some Seq",
        start: 10,
        end: 5
      },
      defaultTemplateString: ``
    };
    return acc;
  }, {})
};
//these values are added via the AddWorkflowParamsContextHOC
const defaultToolParams = {
  workflowDefinitionName: "Some_Workflow",
  workflowDefinitionId: "89",
  taskNumber: 4,
  workflowRunName: "Some_Workflow_Run",
  workflowRunId: "103",
  workflowToolTitle: "Custom title",
  toolName: "Some Tool"
};

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const defaultValueConstants = {
  // MICROBIAL_TRANSFORMATION_INPUT_TYPE: {
  //   params: {
  //     workflowName: "Fake Workflow",
  //     workflowDefName: "Fake Workflow Def"

  //   },
  // },

  // ASSEMBLED_CONSTRUCTS: {
  //   code: "ASSEMBLED_CONSTRUCTS",
  //   fieldType: "input",
  //   name: "Assembled Constructs",
  //   staticParams: {

  //     "'{{{incrementing_number}}}'": 4,
  //     "'{{{design_id}}}'": 4,
  //     "'{{{assembly_run_id}}}'": 4,
  //     "'{{{reaction_prefix}}}'": 4,

  //   },
  //   params: {
  //   },
  //   defaultTemplateString: "Construct {{ '{{{incrementing_number}}}' }}"
  // },
  MICROBE_TRANSFER_VOLUME: {
    code: "MICROBE_TRANSFER_VOLUME",
    fieldType: "numericInput",
    label: "Microbe Transfer Volume",
    name: "microbialTransferVolume",
    min: 0,
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  DNA_TRANSFER_VOLUME: {
    label: "DNA Transfer Volume",
    name: "dnaTransferVolume",
    min: 0,
    code: "DNA_TRANSFER_VOLUME",
    fieldType: "numericInput",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  DESTINATION_CONTAINER_NAME: {
    code: "DESTINATION_CONTAINER_NAME",
    fieldType: "input",
    name: "Destination Container Name",
    params: {
      ...defaultToolParams,
      containerType: "Plate"
    },
    defaultTemplateString: "{{toolName}} {{containerType}} {{workflowRunName}}"
  },
  CREATE_PLATE_MAP_PLATE_FORMAT: {
    code: "CREATE_PLATE_MAP_PLATE_FORMAT",
    fieldType: "genericSelect",
    name: "Create Plate Map Plate Format",
    params: {
      ...defaultToolParams
      // containerType: "Plate"
    },
    defaultTemplateString: "96_WELL",
    asReactSelect: true,
    idAs: "code",
    queryOptions: {
      variables: {
        sort: ["quadrantSize", "name", "code"]
      }
    },
    fragment: containerFormatFragment,
    tableParamOptions: {
      additionalFilter: (a, qb) => {
        qb.whereAll({
          code: qb.notInList(["1_RESERVOIR", "2_RESERVOIR", "81_BOX"])
        });
      }
    }
  },
  DESIGN_LAYOUT_TYPE: {
    code: "DESIGN_LAYOUT_TYPE",
    fieldType: "select",
    name: "Design Layout Type",
    params: {
      ...defaultToolParams
    },
    options: [
      {
        label: "combinatorial",
        value: "combinatorial"
      },
      {
        label: "list",
        value: "list"
      }
    ],
    defaultTemplateString: "combinatorial"
  },
  SORT_WORKLIST_ORDER: {
    code: "SORT_WORKLIST_ORDER",
    fieldType: "radio",
    name: "Sort Worklist Order",
    params: {
      ...defaultToolParams
    },
    options: [
      {
        label: "Row First",
        value: "rowFirst"
      },
      {
        label: "Column First",
        value: "columnFirst"
      }
    ],
    defaultTemplateString: "rowFirst"
  },
  WORKLIST_NAME: {
    code: "WORKLIST_NAME",
    fieldType: "input",
    name: "Worklist Name",
    params: {
      ...defaultToolParams,
      outputType: "Output"
    },
    defaultTemplateString: "{{toolName}} Worklist {{workflowRunName}}"
  },
  REACTION_MAP_NAME: {
    code: "REACTION_MAP_NAME",
    fieldType: "input",
    name: "Reaction Map Name",
    params: {
      ...defaultToolParams,
      outputType: "Output"
    },
    defaultTemplateString: "{{toolName}} Reaction Map {{workflowRunName}}"
  },
  INTERMEDIATE_CONTAINER_NAME: {
    code: "INTERMEDIATE_CONTAINER_NAME",
    fieldType: "input",
    name: "Intermediate Container Name",
    params: {
      ...defaultToolParams,
      label: "Intermediate Container"
    },
    defaultTemplateString: "{{toolName}} {{label}} {{workflowRunName}}"
  },
  PLATES_OR_TUBES: {
    code: "PLATES_OR_TUBES",
    fieldType: "radio",
    name: "Plates or Tubes Selection",
    params: {
      ...defaultToolParams
    },
    options: [
      { label: "Plates and Racks", value: "PLATE" },
      { label: "Tubes", value: "TUBE" }
    ],
    defaultTemplateString: "PLATE"
  },
  CONC_OR_MOL_NORMALIZATION: {
    code: "CONC_OR_MOL_NORMALIZATION",
    fieldType: "radio",
    name: "Normalization Tool - Concentration, Cell Concentration, or Molarity",
    params: {
      ...defaultToolParams
    },
    options: [
      { label: "Concentration", value: "concentration" },
      { label: "Cell Concentration", value: "cellConcentration" },
      { label: "Molarity", value: "molarity" }
    ],
    defaultTemplateString: "concentration"
  },
  CONSTRUCT_SELECTION_OUTPUT_NAME: {
    code: "CONSTRUCT_SELECTION_OUTPUT_NAME",
    fieldType: "input",
    name: "Construct Selection Tool - Output Name",
    params: {
      ...defaultToolParams,
      outputType: "Output"
    },
    defaultTemplateString: "{{toolName}} {{outputType}} {{workflowRunName}}"
  },
  ASSEMBLY_REACTION_PLANNING_DESTINATION_WELL_ORDER: {
    code: "ASSEMBLY_REACTION_PLANNING_DESTINATION_WELL_ORDER",
    fieldType: "radio",
    name: "DNA Assembly Reaction Planning Tool - Destination Well Order",
    params: {
      ...defaultToolParams
    },
    options: [
      {
        label: "Row First",
        value: "right"
      },
      {
        label: "Column First",
        value: "down"
      }
    ],
    defaultTemplateString: "right"
  },
  PCR_PLANNING_TEMP_DISTRIBUTION: {
    code: "PCR_PLANNING_TEMP_DISTRIBUTION",
    fieldType: "radio",
    name: "PCR Planning Tool - Plate Map Temperature Distribution",
    params: {
      ...defaultToolParams
    },
    options: [
      {
        label: "Distribute using Assembly Report Tm",
        value: "assembly"
      },
      {
        label:
          "Distribute using NEB Tm calculated from SantaLucia (1998) & Owczarzy (2004)",
        value: "neb"
      },
      {
        label: "Disable zones based on Tm",
        value: "disableZones"
      }
    ],
    defaultTemplateString: "assembly"
  },
  PCR_PLANNING_OUTPUT_NAME: {
    code: "PCR_PLANNING_OUTPUT_NAME",
    fieldType: "input",
    name: "PCR Planning Tool - Output Name",
    params: {
      ...defaultToolParams,
      outputType: "Output"
    },
    defaultTemplateString: "{{toolName}} {{outputType}} {{workflowRunName}}"
  },
  ALIQUOT_REARRAY_DESTINATION_WELL_ORDER: {
    code: "ALIQUOT_REARRAY_DESTINATION_WELL_ORDER",
    fieldType: "radio",
    name: "Aliquot Rearray Tool - Destination Well Order",
    params: {
      ...defaultToolParams
    },
    options: [
      {
        label: "Row First",
        value: "rowFirst"
      },
      {
        label: "Column First",
        value: "columnFirst"
      }
    ],
    defaultTemplateString: "rowFirst"
  },
  ALIQUOT_REARRAY_SOURCE_WELL_ORDER: {
    code: "ALIQUOT_REARRAY_SOURCE_WELL_ORDER",
    fieldType: "radio",
    name: "Aliquot Rearray Tool - Source Well Order",
    params: {
      ...defaultToolParams
    },
    options: [
      {
        label: "Row First",
        value: "rowFirst"
      },
      {
        label: "Column First",
        value: "columnFirst"
      }
    ],
    defaultTemplateString: "rowFirst"
  },
  UPDATE_PLATE_METRIC_TYPE: {
    code: "UPDATE_PLATE_METRIC_TYPE",
    fieldType: "select",
    name: "Update Plates Tool - Metric Type",
    params: {
      ...defaultToolParams
    },
    options: [
      { label: "Volume", value: "Volume" },
      { label: "Concentration", value: "Concentration" },
      { label: "Cell Concentration", value: "Cell Concentration" },
      { label: "Molarity", value: "Molarity" },
      { label: "Mass", value: "Mass" },
      { label: "Tube Barcodes", value: "Tube Barcodes" }
    ],
    defaultTemplateString: "Volume"
  },
  REAGENT_LIST_NAME: {
    code: "REAGENT_LIST_NAME",
    fieldType: "input",
    name: "Reagent List Name",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: "{{toolName}} Reagent List {{workflowRunName}}"
  },
  PLATE_MAP_GROUP_NAME: {
    code: "PLATE_MAP_GROUP_NAME",
    fieldType: "input",
    name: "Plate Map Name",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: "{{toolName}} Plate Map {{workflowRunName}}"
  },
  PLATE_BASE_NAME: {
    code: "PLATE_BASE_NAME",
    fieldType: "input",
    name: "Plate Base Name",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  PLATE_NAME_FROM_PLATE_MAP: {
    code: "PLATE_NAME_FROM_PLATE_MAP",
    fieldType: "input",
    name: "Plate Name from Plate Map",
    params: {
      ...defaultToolParams,
      plateMapName: "Plate Map Name"
    },
    defaultTemplateString:
      "{{toolName}} Plate from {{plateMapName}} {{workflowRunName}}"
  },
  SAMPLE_QC_OUTPUT_NAME: {
    code: "SAMPLE_QC_OUTPUT_NAME",
    fieldType: "input",
    name: "Sample Quality Control Tool - Output Name",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: "{{toolName}} {{workflowRunName}}"
  },
  PLATE_NAME_PREFIX: {
    code: "PLATE_NAME_PREFIX",
    fieldType: "input",
    name: "Plate Name Prefix",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: "{{toolName}} Plate {{workflowRunName}}"
  },
  REQUEST_NAME: {
    code: "REQUEST_NAME",
    fieldType: "input",
    name: "Request Name",
    defaultTemplateString: ""
  },
  REQUEST_DESCRIPTION: {
    code: "REQUEST_DESCRIPTION",
    fieldType: "input",
    name: "Request Description",
    defaultTemplateString: ""
  },
  JOB_NAME: {
    code: "JOB_NAME",
    fieldType: "input",
    name: "Job Name",
    defaultTemplateString: "{{userRequestName}}",
    params: {
      userRequestName: ""
    }
  },
  JOB_DESCRIPTION: {
    code: "JOB_DESCRIPTION",
    fieldType: "input",
    name: "Job Description",
    defaultTemplateString: "{{userRequestDescription}}",
    params: {
      userRequestDescription: ""
    }
  },
  OLIGO_NAME: {
    code: "OLIGO_NAME",
    fieldType: "input",
    name: "Oligo Name",
    params: {},
    defaultTemplateString: ""
  },
  DESIGN_NAME: {
    code: "DESIGN_NAME",
    fieldType: "input",
    name: "Design Name",
    params: {
      // lastDesignId: "1215"
    },
    defaultTemplateString: ""
  },
  SEQUENCE_NAME: {
    code: "SEQUENCE_NAME",
    fieldType: "input",
    name: "Sequence Name",
    params: {
      // lastDesignId: "1215"
    },
    defaultTemplateString: ""
    // defaultTemplateString: "{{lastDesignId}}-{{username}}"
  },
  EXPORT_ASSEMBLY_REPORT_FILE_NAME: {
    code: "EXPORT_ASSEMBLY_REPORT_FILE_NAME",
    fieldType: "input",
    name: "Export Assembly Report File Name",
    params: {
      designId: "Design ID",
      designName: "Design Name",
      assemblyMethod: "Assembly Method"
    },
    defaultTemplateString: "{{designName}}::{{assemblyMethod}}-{{designId}}"
  },
  GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_NAME: {
    code: "GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_NAME",
    fieldType: "input",
    name: "Generate Oligos for Synthons Design Name",
    defaultTemplateString: ""
  },
  GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_TYPE: {
    code: "GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_TYPE",
    fieldType: "radio",
    name: "Generate Oligos for Synthons Design Type",
    params: {
      ...defaultToolParams
    },
    options: [
      { label: "Maximize Recycling", value: "maximizeRecycling" },
      { label: "Minimize Complexity", value: "minimizeComplexity" },
      { label: "Gapped Design", value: "gappedDesign" }
    ],
    defaultTemplateString: "maximizeRecycling"
  },
  ESTIMATED_IDENTITY: {
    code: "ESTIMATED_IDENTITY",
    fieldType: "numericInput",
    label: "Estimated Identity [0-1.0]",
    name: "estimatedIdentity",
    min: 0,
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  MIN_Z_SCORE_CUTOFF: {
    code: "MIN_Z_SCORE_CUTOFF",
    fieldType: "numericInput",
    label: "Min Z-Score Cutoff",
    name: "minZScoreCutoff",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  TEMP_FOR_DELTAG_CALC: {
    code: "TEMP_FOR_DELTAG_CALC",
    fieldType: "numericInput",
    label: "Temp for ΔG Calculations (°C)",
    name: "tempForDeltaGCalc",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  OVERLAP_TARGET_TM_TEMP: {
    code: "OVERLAP_TARGET_TM_TEMP",
    fieldType: "numericInput",
    label: "Overlap Target TM Temp (°C)",
    name: "overlapTargetTmTemp",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  MIN_SIZE: {
    code: "MIN_SIZE",
    fieldType: "numericInput",
    label: "Min Size (bp)",
    name: "minSize",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  TARGET_SIZE: {
    code: "TARGET_SIZE",
    fieldType: "numericInput",
    label: "Target Size (bp)",
    name: "targetSize",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  MAX_SIZE: {
    code: "MAX_SIZE",
    fieldType: "numericInput",
    label: "Max Size (bp)",
    name: "maxSize",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  MIN_OVERLAP: {
    code: "MIN_OVERLAP",
    fieldType: "numericInput",
    label: "Min Overlap (bp)",
    name: "minOverlap",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  MAX_OVERLAP: {
    code: "MAX_OVERLAP",
    fieldType: "numericInput",
    label: "Max Overlap (bp)",
    name: "maxOverlap",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  TARGET_PRIMER_SIZE: {
    code: "TARGET_PRIMER_SIZE",
    fieldType: "numericInput",
    label: "Target Primer Size (bp)",
    name: "targetPrimerSize",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  PRIMER_TARGET_TM_TEMP: {
    code: "PRIMER_TARGET_TM_TEMP",
    fieldType: "numericInput",
    label: "Primer Target TM Temp (°C)",
    name: "primerTargetTmTemp",
    params: {
      ...defaultToolParams
    },
    defaultTemplateString: ""
  },
  // INTERMEDIATE_CONTAINER_NAME: {
  //   code: "INTERMEDIATE_CONTAINER_NAME",
  //   fieldType: "input",
  //   name: "Diluent Container",
  //   params: {
  //     workflowDefinitionName: "Some_Workflow",
  //     workflowDefinitionId: "89",
  //     workflowRunName: "Some_Workflow_Run",
  //     workflowRunId: "103"
  //   },
  // },
  ...oveDVCs
};

if (window) {
  window.__getDefaultValGenerator = ({ code, customParams }) => {
    if (!defaultValueConstants[code]) return {};
    return {
      generateDefaultValue: {
        ...defaultValueConstants[code],
        customParams
      }
    };
  };
}

export default defaultValueConstants;
