/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import {
  extendedPropertyUploadFragment,
  sequenceWithTagsAndAliasesAndExtendedProperitesFragment
} from "./helperFragments";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import {
  getParsedSequenceMatch,
  sequenceJSONtoGraphQLInput
} from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import cleanUnit from "../utils/unitUtils/cleanUnit";
import { keyBy } from "lodash";
import calculateConcentration from "../utils/unitUtils/calculateConcentration";
import { trim } from "lodash";
import {
  maxWellVolumeError,
  checkBarcodesAndFormat
} from "../utils/plateUtils";
import {
  calculateConcentrationFromMolarity,
  calculateMolarityFromConcentration,
  defaultConcentrationUnitCode,
  defaultMolarityUnitCode
} from "../utils/unitUtils";
import {
  convertConcentration,
  convertMolarity
} from "../utils/unitUtils/convertUnits";
import { parseSequenceFiles } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import { getSequenceFileMatchIndex } from "./utils";
import unitGlobals from "../unitGlobals";

export const requiredTubeHeaders = ["TUBE_NAME"];
export const requiredPlateHeaders = ["PLATE_NAME", "WELL_LOCATION"];
const headerMappings = {
  WELL: "WELL_LOCATION"
};

export default async function handleDnaTubeImport(values, ctx = isoContext) {
  const {
    generateBarcodes,
    isDry,
    isTubeUpload,
    generateTubeBarcodes,
    isRNAUpload,
    nonSequence
  } = values;
  const { safeQuery } = ctx;
  const {
    finishPlateCreate,
    finishTubeCreate,
    containerArrayType,
    aliquotContainerType,
    filename,
    csvData,
    getCsvRowExtProps
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      hasSequences: true,
      hasReagents: true,
      isRNAUpload,
      hasExtendedProperties: true,
      requiredFields: isTubeUpload ? requiredTubeHeaders : requiredPlateHeaders,
      sequenceFragment: sequenceWithTagsAndAliasesAndExtendedProperitesFragment,
      headerMappings,
      barcodeHeader: "PLATE_BARCODE",
      nameHeader: "PLATE_NAME",
      getSequenceForRow: async (
        row,
        index,
        allParsedSequences,
        allParsedSequencesFilenames,
        allSequenceFiles
      ) => {
        const {
          SEQUENCE: sequence,
          MATERIAL_NAME: materialName,
          SEQUENCE_NAME: sequenceName,
          SEQUENCE_TYPE: sequenceTypeCode,
          SEQUENCE_FILE: sequenceFile
        } = row;

        if (nonSequence && !materialName) {
          throw new Error(
            `Row ${
              index + 1
            }: every row must specify a material name when uploading materials without sequence data.`
          );
        }
        if (nonSequence) return;

        // if there is no sequence or sequence file then they might just be uploading reagents
        if (!sequence && !sequenceFile) {
          return;
        }
        const viableSequenceTypes = [
          "OLIGO",
          "CIRCULAR_DNA",
          "LINEAR_DNA",
          "CDS"
        ];
        const sequenceNameToUse = sequenceName || "Untitled Sequence";
        let formattedSequenceTypeCode =
          sequenceTypeCode && sequenceTypeCode.replace(" ", "_").toUpperCase();
        if (
          sequenceTypeCode &&
          (sequenceTypeCode.toUpperCase() === "CIRCULAR" ||
            sequenceTypeCode.toUpperCase() === "LINEAR")
        ) {
          formattedSequenceTypeCode = formattedSequenceTypeCode.concat("_DNA");
        }
        if (isRNAUpload) {
          viableSequenceTypes.push("RNA");
          formattedSequenceTypeCode = "RNA";
        }
        if (
          !sequenceFile &&
          !viableSequenceTypes.includes(formattedSequenceTypeCode)
        ) {
          throw new Error(
            `Row ${index + 1} contains an invalid sequence type.`
          );
        }
        if (!sequence && !sequenceFile) {
          throw new Error(
            `Row ${index + 1} does not contain a sequence or sequence file.`
          );
        }
        if (!formattedSequenceTypeCode && !sequenceFile) {
          throw new Error(
            `Row ${
              index + 1
            } does not contain a sequence type or sequence file.`
          );
        }
        if (sequence && sequenceFile) {
          throw new Error(
            `Row ${
              index + 1
            } contains a sequence and sequence file. Please upload one or the other.`
          );
        }
        if (sequenceFile) {
          const sequenceFilenames = sequenceFile.split(",").map(trim);
          const parsedSequences = [];
          if (formattedSequenceTypeCode === "OLIGO") {
            const filesToParse = [];
            sequenceFilenames.forEach(seqFilename => {
              const sequenceIndex = getSequenceFileMatchIndex(
                seqFilename,
                allParsedSequencesFilenames
              );
              const sequenceFile = allSequenceFiles[sequenceIndex];
              if (!sequenceFile) {
                throw new Error(
                  `Row ${
                    index + 1
                  } specifies the sequence file "${seqFilename}" but the corresponding file was not found.`
                );
              }
              filesToParse.push(sequenceFile);
            });
            const parsedOligos = await parseSequenceFiles(filesToParse, {
              isOligo: true
            });
            return parsedOligos;
          } else {
            sequenceFilenames.forEach(seqFilename => {
              const sequence = getParsedSequenceMatch(
                allParsedSequences,
                seqFilename
              );
              if (!sequence) {
                throw new Error(
                  `Row ${
                    index + 1
                  } specifies the sequence file "${seqFilename}" but the corresponding file was not found.`
                );
              }
              parsedSequences.push(sequence);
            });
          }

          if (formattedSequenceTypeCode) {
            parsedSequences.forEach(sequence => {
              if (
                sequence.sequenceTypeCode === "CIRCULAR" &&
                formattedSequenceTypeCode !== "CIRCULAR"
              ) {
                throw new Error(
                  `Row ${
                    index + 1
                  } specifies a sequence type of circular, but the corresponding sequence file specifies linear.`
                );
              }
              if (
                sequence.sequenceTypeCode === "LINEAR" &&
                formattedSequenceTypeCode === "CIRCULAR"
              ) {
                throw new Error(
                  `Row ${
                    index + 1
                  } specifies a sequence type of linear, but the corresponding sequence file specifies circular.`
                );
              }
            });
          }
          return parsedSequences;
        } else if (sequence && formattedSequenceTypeCode && !sequenceFile) {
          return [
            sequenceJSONtoGraphQLInput({
              name: sequenceNameToUse,
              sequence: sequence,
              sequenceTypeCode: formattedSequenceTypeCode
            })
          ];
        } else if (sequence && !formattedSequenceTypeCode && !sequenceFile) {
          return [
            sequenceJSONtoGraphQLInput({
              name: sequenceNameToUse,
              sequence,
              sequenceTypeCode: "LINEAR"
            })
          ];
        }
      }
    },
    ctx
  );

  const tubesToCreate = [];
  const addedPropsForSequence = {};

  let keyedExistingTubeBarcodes = {};
  if (isTubeUpload) {
    const barcodes = [];
    for (const [index, row] of csvData.entries()) {
      const { TUBE_BARCODE: barcode } = row;
      if (barcodes.includes(barcode)) {
        throw new Error(`Row ${index + 1} contains a duplicate barcode.`);
      }
      if (barcode) barcodes.push(barcode);
    }

    const existingBarcodes = await safeQuery(["barcode", "id barcodeString"], {
      variables: { filter: { barcodeString: barcodes } }
    });
    keyedExistingTubeBarcodes = keyBy(existingBarcodes, "barcodeString");
  }
  if (!isTubeUpload) {
    const continueUpload = await checkBarcodesAndFormat({
      data: csvData,
      generateTubeBarcodes,
      filename,
      containerArrayType,
      tubeBarcodeKey: "TUBE_BARCODE",
      barcodeKey: "PLATE_BARCODE",
      wellPositionHeader: "WELL_LOCATION"
    });
    if (!continueUpload) return;
  }

  const isRack = containerArrayType && !containerArrayType.isPlate;
  const mapPlates = {};

  const allMaterialIds = [];
  for (const row of csvData) {
    const { materialIds = [] } = row;
    materialIds.forEach(id => {
      if (!id.startsWith("&")) {
        allMaterialIds.push(id);
      }
    });
  }
  const materialsWithExtPropInfo = keyBy(
    await safeQuery(["material", `id ${extendedPropertyUploadFragment}`], {
      variables: {
        filter: {
          id: allMaterialIds
        }
      }
    }),
    "id"
  );

  for (const [index, row] of csvData.entries()) {
    const {
      PLATE_NAME: plateName,
      PLATE_BARCODE: plateBarcode,
      SAMPLE_NAME: sampleName = "",
      MATERIAL_NAME: materialName = "",
      TUBE_NAME: tubeName,
      TUBE_BARCODE: tubeBarcode,
      MASS: _mass,
      MASS_UNIT: _massUnitCode = "",
      VOLUME: _volume,
      VOLUMETRIC_UNIT: _volumetricUnitCode = "",
      VOLUMETRIC_UNIT_CODE: __volumetricUnitCode = "",
      CONCENTRATION: concentration,
      CONCENTRATION_UNIT: _concentrationUnitCode = "",
      CONCENTRATION_UNIT_CODE: __concentrationUnitCode = "",
      MOLARITY: molarity,
      MOLARITY_UNIT: _molarityUnitCode = "",
      rowPosition,
      columnPosition,
      additives,
      materialIds = [],
      sequenceIds = [],
      sequences = [],
      molecularWeights = [],
      rowKey
    } = row;
    const volumetricUnitCode = _volumetricUnitCode
      ? cleanUnit(_volumetricUnitCode)
      : cleanUnit(__volumetricUnitCode);
    const massUnitCode = cleanUnit(_massUnitCode);
    const concentrationUnitCode = _concentrationUnitCode
      ? cleanUnit(_concentrationUnitCode)
      : cleanUnit(__concentrationUnitCode);
    const molarityUnitCode = cleanUnit(_molarityUnitCode);
    const volume = _volume && Number(_volume);
    const mass = _mass && Number(_mass);
    const noVolume = !volume && volume !== 0;
    const noMass = !mass && mass !== 0;
    const noConcentration = !concentration && concentration !== 0;

    if (noVolume && noMass && !row.additives?.length) {
      throw new Error(`Row ${index + 1} does not specify a volume or mass.`);
    }
    if (
      !noVolume &&
      (!volumetricUnitCode || !unitGlobals.volumetricUnits[volumetricUnitCode])
    ) {
      if (!volumetricUnitCode) {
        throw new Error(`Row ${index + 1} does not specify a volumetric unit.`);
      } else {
        throw new Error(
          `Row ${index + 1} specifies an invalid volumetric unit.`
        );
      }
    }
    if (!noVolume && volumetricUnitCode) {
      const maxVolumeError = maxWellVolumeError({
        volume,
        unit: volumetricUnitCode,
        containerArrayType,
        aliquotContainerType,
        index
      });
      if (maxVolumeError) {
        throw new Error(maxVolumeError);
      }
    }
    if (!noMass && !massUnitCode) {
      throw new Error(`Row ${index + 1} does not specify a mass unit.`);
    }
    if (massUnitCode && !unitGlobals.massUnits[massUnitCode]) {
      throw new Error(`Row ${index + 1} does not specify a valid mass unit.`);
    }
    if (!noConcentration && !concentrationUnitCode) {
      throw new Error(
        `Row ${index + 1} does not specify a concentration unit.`
      );
    }
    if (
      concentrationUnitCode &&
      !unitGlobals.concentrationUnits[concentrationUnitCode]
    ) {
      throw new Error(
        `Row ${index + 1} does not specify a valid concentration unit.`
      );
    }
    if (molarityUnitCode && !unitGlobals.molarityUnits[molarityUnitCode]) {
      throw new Error(
        `Row ${index + 1} does not specify a valid molarity unit.`
      );
    }
    if (tubeBarcode && keyedExistingTubeBarcodes[tubeBarcode]) {
      throw new Error(
        `Row ${
          index + 1
        } contains the barcode ${tubeBarcode}, which already exists in the database.`
      );
    }
    if (generateBarcodes && tubeBarcode) {
      throw new Error(
        `Row ${
          index + 1
        } contains a barcode, but the "Generate Barcodes" option was selected.`
      );
    }
    if (isTubeUpload) {
      if (isDry && noMass) {
        throw new Error(
          `Row ${
            index + 1
          } is missing a mass, but the "Upload Dry Tubes" option was selected.`
        );
      }
      if (isDry && !massUnitCode) {
        throw new Error(
          `Row ${
            index + 1
          } is missing a mass unit, but the "Upload Dry Tubes" option was selected.`
        );
      }
      if (isDry && !noVolume) {
        throw new Error(
          `Row ${
            index + 1
          } specifies a volume, but the "Upload Dry Tubes" option was selected.`
        );
      }

      if (!isDry && noVolume) {
        throw new Error(`Row ${index + 1} is missing a volume.`);
      }
      if (!isDry && !volumetricUnitCode) {
        throw new Error(`Row ${index + 1} is missing a volume unit.`);
      }
    }

    let unitFields;
    if (isTubeUpload && isDry) {
      unitFields = { mass, massUnitCode };
    } else {
      const finalConcentrationAndMolarityFields = {};
      if (volume && mass) {
        const calculatedConcentration = calculateConcentration({
          volume,
          volumetricUnitCode,
          mass,
          massUnitCode,
          concentrationUnitCode: "ng/uL"
        });
        finalConcentrationAndMolarityFields.concentration =
          calculatedConcentration;
        finalConcentrationAndMolarityFields.concentrationUnitCode = "ng/uL";
      } else {
        finalConcentrationAndMolarityFields.concentration =
          concentration === "" ? null : concentration;
        finalConcentrationAndMolarityFields.concentrationUnitCode =
          concentrationUnitCode || null;
        finalConcentrationAndMolarityFields.molarity =
          molarity === "" ? null : molarity;
        finalConcentrationAndMolarityFields.molarityUnitCode =
          molarityUnitCode || null;
      }
      if (molecularWeights.length === 1 && molecularWeights[0] > 0) {
        if (
          finalConcentrationAndMolarityFields.molarity &&
          finalConcentrationAndMolarityFields.molarityUnitCode
        ) {
          finalConcentrationAndMolarityFields.concentration =
            convertConcentration(
              calculateConcentrationFromMolarity(
                finalConcentrationAndMolarityFields.molarity,
                finalConcentrationAndMolarityFields.molarityUnitCode,
                molecularWeights[0]
              ),
              "g/L",
              defaultConcentrationUnitCode
            );
          finalConcentrationAndMolarityFields.concentrationUnitCode =
            defaultConcentrationUnitCode;
        } else if (
          finalConcentrationAndMolarityFields.concentration &&
          finalConcentrationAndMolarityFields.concentrationUnitCode
        ) {
          finalConcentrationAndMolarityFields.molarity = convertMolarity(
            calculateMolarityFromConcentration(
              finalConcentrationAndMolarityFields.concentration,
              finalConcentrationAndMolarityFields.concentrationUnitCode,
              molecularWeights[0]
            ),
            "M",
            defaultMolarityUnitCode
          );
          finalConcentrationAndMolarityFields.molarityUnitCode =
            defaultMolarityUnitCode;
        }
      }
      unitFields = {
        volume,
        volumetricUnitCode: volumetricUnitCode || null,
        mass,
        massUnitCode: massUnitCode || null,
        ...finalConcentrationAndMolarityFields
      };
    }

    const barcodeField = {};
    if (isTubeUpload && !generateBarcodes) {
      barcodeField.barcode = {
        barcodeString: tubeBarcode
      };
    }

    if (sequenceIds.length) {
      sequenceIds.forEach((sequenceId, i) => {
        if (sequenceId && !addedPropsForSequence[sequenceId]) {
          getCsvRowExtProps({
            row,
            modelTypeCode: "DNA_SEQUENCE",
            typeFilter: "sequence",
            recordId: sequenceId,
            record: sequences[i]
          });
        }
      });
    }

    const tubeCid = shortid();
    const aliquotCid = shortid();
    const sampleCid = shortid();

    if (isRack || isTubeUpload) {
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT_CONTAINER",
        recordId: `&${tubeCid}`,
        typeFilter: "tube"
      });
    }
    materialIds.forEach(materialId => {
      getCsvRowExtProps({
        row,
        modelTypeCode: "MATERIAL",
        recordId: materialId,
        typeFilter: "material",
        record: materialsWithExtPropInfo[materialId]
      });
    });
    getCsvRowExtProps({
      row,
      modelTypeCode: "ALIQUOT",
      recordId: `&${aliquotCid}`,
      typeFilter: "aliquot"
    });
    getCsvRowExtProps({
      row,
      modelTypeCode: "SAMPLE",
      recordId: `&${sampleCid}`,
      typeFilter: "sample"
    });

    const sample = {
      cid: sampleCid,
      sampleTypeCode: "REGISTERED_SAMPLE",
      name:
        sampleName.trim() ||
        sequences.map(s => s.name).join(" - ") ||
        materialName.trim()
    };
    if (materialIds.length > 1) {
      sample.sampleTypeCode = "FORMULATED_SAMPLE";
      sample.sampleFormulations = materialIds.map(id => ({
        materialCompositions: [{ materialId: id }]
      }));
    } else {
      if (nonSequence) {
        sample.material = {
          name: materialName.trim(),
          materialTypeCode: "DNA"
        };
      } else {
        sample.materialId = materialIds[0];
      }
    }
    let isDryToUse;
    if (isTubeUpload) {
      isDryToUse = isDry;
    } else {
      isDryToUse = mass && !volume;
    }
    const aliquot = {
      cid: aliquotCid,
      isDry: !!isDryToUse,
      aliquotType: "sample-aliquot",
      sample,
      additives,
      ...unitFields
    };
    // if they don't pass a sample name or sequence info then don't put an aliquot in this well.
    // can still upload additives for the well (reagents).
    const shouldHaveAliquot =
      nonSequence || materialIds.length || sampleName.trim();
    const aliquotOrAdditives = {};
    if (shouldHaveAliquot) {
      aliquotOrAdditives.aliquot = aliquot;
    } else {
      aliquotOrAdditives.additives = additives;
    }
    if (isTubeUpload) {
      tubesToCreate.push({
        cid: tubeCid,
        name: tubeName,
        ...barcodeField,
        aliquotContainerTypeCode: aliquotContainerType.code,
        ...aliquotOrAdditives
      });
    } else {
      if (!mapPlates[rowKey]) {
        const plateCid = shortid();
        getCsvRowExtProps({
          row,
          modelTypeCode: "CONTAINER_ARRAY",
          typeFilter: ["plate", "rack"],
          recordId: `&${plateCid}`
        });

        mapPlates[rowKey] = {
          cid: plateCid,
          name: plateName,
          containerArrayTypeId: containerArrayType.id,
          ...(plateBarcode && {
            barcode: {
              barcodeString: plateBarcode
            }
          }),
          aliquotContainers: []
        };
      }
      const barcodeField = {};
      if (!generateTubeBarcodes) {
        barcodeField.barcode = {
          barcodeString: tubeBarcode
        };
      }
      mapPlates[rowKey].aliquotContainers.push({
        cid: tubeCid,
        aliquotContainerTypeCode: isRack
          ? aliquotContainerType.code
          : containerArrayType.aliquotContainerType.code,
        rowPosition,
        columnPosition,
        ...barcodeField,
        ...aliquotOrAdditives
      });
    }
  }

  const platesToCreate = Object.values(mapPlates);
  if (isTubeUpload) {
    return await finishTubeCreate({
      newTubes: tubesToCreate
    });
  } else {
    return await finishPlateCreate({
      newPlates: platesToCreate
    });
  }
}
