/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";

import {
  addAndRemoveExtendedProperties,
  collectAllExtendedValues
} from "../../../../src-shared/utils/extendedPropertyUtils";

export async function handleExtendedProperties(
  worklists,
  { aliquotPropsToTransfer, containerArrayPropsToTransfer }
) {
  const containerArrayPropsAddedMap = {};
  const containerArrayPropsRemovedMap = {};
  const aliquotPropsAddedMap = {};
  const aliquotPropsRemovedMap = {};

  const extendedValuesToDelete = {
    extendedValue: [],
    extendedCategoryValue: [],
    extendedMeasurementValue: []
  };

  const extendedValuesToCreate = {
    extendedValue: [],
    extendedCategoryValue: [],
    extendedMeasurementValue: []
  };
  const keyedMissingAliquots = {};

  // get missing destination aliquots
  const aliquotContainerIdsWithMissingAliquots = new Set();
  worklists.forEach(worklist => {
    worklist.worklistTransfers.forEach(transfer => {
      const { destinationAliquotContainer } = transfer;
      if (!destinationAliquotContainer.aliquot) {
        aliquotContainerIdsWithMissingAliquots.add(
          destinationAliquotContainer.id
        );
      }
    });
  });

  if (aliquotContainerIdsWithMissingAliquots.size) {
    const aliquotContainers = await safeQuery(
      ["aliquotContainer", "id aliquot { id }"],
      {
        variables: {
          filter: {
            id: Array.from(aliquotContainerIdsWithMissingAliquots)
          }
        }
      }
    );
    aliquotContainers.forEach(ac => {
      keyedMissingAliquots[ac.id] = ac.aliquot;
    });
  }

  // first handle all the worklist extended values
  worklists.forEach(worklist => {
    worklist.worklistContainerArrays.forEach(worklistContainerArray => {
      const {
        worklistExtendedProperties,
        containerArray
      } = worklistContainerArray;
      const platePropIdsToRemove = [];
      const allExtendedValues = collectAllExtendedValues(
        worklistContainerArray
      );

      worklistExtendedProperties.forEach(wExtProp => {
        platePropIdsToRemove.push(wExtProp.extendedPropertyToRemove.id);
      });

      addAndRemoveExtendedProperties(containerArray, {
        extendedValues: allExtendedValues,
        extendedValuesToDelete,
        extendedValuesToCreate,
        propIdsToRemove: platePropIdsToRemove,
        propAddedMap: containerArrayPropsAddedMap,
        propRemovedMap: containerArrayPropsRemovedMap
      });
    });

    worklist.worklistTransfers.forEach(transfer => {
      transfer.worklistTransferAliquotContainers.forEach(wtAC => {
        const { transferExtendedProperties, aliquotContainer } = wtAC;
        const aliquot =
          aliquotContainer.aliquot || keyedMissingAliquots[aliquotContainer.id];
        if (!aliquot) return;
        const aliquotPropIdsToRemove = [];
        const allExtendedValues = collectAllExtendedValues(wtAC);
        transferExtendedProperties.forEach(tExtProp => {
          aliquotPropIdsToRemove.push(tExtProp.extendedPropertyToRemove.id);
        });
        addAndRemoveExtendedProperties(aliquot, {
          extendedValues: allExtendedValues,
          extendedValuesToDelete,
          extendedValuesToCreate,
          propIdsToRemove: aliquotPropIdsToRemove,
          propAddedMap: aliquotPropsAddedMap,
          propRemovedMap: aliquotPropsRemovedMap
        });
      });
    });
  });

  // next handle the transfer extended values
  // NOTE: edge-case two transfers go into same destination well and each
  // source has different values for the same ext prop. For now we will
  // only handle the first one
  if (aliquotPropsToTransfer.length || containerArrayPropsToTransfer.length) {
    const aliquotPropsToTransferIds = aliquotPropsToTransfer.map(p => p.id);
    const containerArrayPropsToTransferIds = containerArrayPropsToTransfer.map(
      p => p.id
    );
    worklists.forEach(worklist => {
      worklist.worklistTransfers.forEach(transfer => {
        const {
          sourceAliquotContainer,
          destinationAliquotContainer
        } = transfer;
        const destinationAliquot =
          destinationAliquotContainer.aliquot ||
          keyedMissingAliquots[destinationAliquotContainer.id];
        if (sourceAliquotContainer.aliquot && destinationAliquot) {
          const sourceExtendedValues = getPropertiesToTransfer(
            sourceAliquotContainer.aliquot,
            destinationAliquot,
            {
              transferIds: aliquotPropsToTransferIds,
              propsAddedMap: aliquotPropsAddedMap,
              propsRemovedMap: aliquotPropsRemovedMap
            }
          );
          if (sourceExtendedValues.length) {
            addAndRemoveExtendedProperties(destinationAliquot, {
              extendedValues: sourceExtendedValues,
              extendedValuesToDelete,
              extendedValuesToCreate,
              propAddedMap: aliquotPropsAddedMap,
              propRemovedMap: aliquotPropsRemovedMap
            });
          }
        }
        if (
          sourceAliquotContainer.containerArray &&
          destinationAliquotContainer.containerArray
        ) {
          const sourceExtendedValues = getPropertiesToTransfer(
            sourceAliquotContainer.containerArray,
            destinationAliquotContainer.containerArray,
            {
              transferIds: containerArrayPropsToTransferIds,
              propsAddedMap: containerArrayPropsAddedMap,
              propsRemovedMap: containerArrayPropsRemovedMap
            }
          );
          if (sourceExtendedValues.length) {
            addAndRemoveExtendedProperties(
              destinationAliquotContainer.containerArray,
              {
                extendedValues: sourceExtendedValues,
                extendedValuesToDelete,
                extendedValuesToCreate,
                propAddedMap: containerArrayPropsAddedMap,
                propRemovedMap: containerArrayPropsRemovedMap
              }
            );
          }
        }
      });
    });
  }

  for (const model of Object.keys(extendedValuesToDelete)) {
    const items = extendedValuesToDelete[model];
    await safeDelete(model, items);
  }
  for (const model of Object.keys(extendedValuesToCreate)) {
    const items = extendedValuesToCreate[model];
    await safeUpsert(model, items, {
      excludeResults: true
    });
  }
}

function getPropertiesToTransfer(
  source,
  destination,
  { transferIds, propsAddedMap, propsRemovedMap }
) {
  const propsAddedToAliquot = propsAddedMap[destination.id] || [];
  const propsRemovedFromAliquot = propsRemovedMap[destination.id] || [];
  return collectAllExtendedValues(source).filter(extValue => {
    const extendedPropertyId = extValue.extendedProperty.id;
    const inTransfer = transferIds.includes(extendedPropertyId);
    const alreadyAddedOrRemoved =
      propsAddedToAliquot.includes(extendedPropertyId) ||
      propsRemovedFromAliquot.includes(extendedPropertyId);
    return inTransfer && !alreadyAddedOrRemoved;
  });
}
