/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import PlateMapView from "../../../components/PlateMapView";
import { wrapDialog } from "@teselagen/ui";

const PlatePreviewDialog = ({
  containerArrayId,
  plateMapGroupId,
  schema,
  ...rest
}) => (
  <PlateMapView
    containerArrayId={containerArrayId}
    plateMapGroupId={plateMapGroupId}
    tableSchema={schema}
    {...rest}
  />
);

export default compose(
  wrapDialog({
    title: "Preview Plate",
    style: {
      display: "table"
    }
  })
)(PlatePreviewDialog);
