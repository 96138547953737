/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  getParentSet,
  getAllOfType,
  getItemOfType,
  getDesignState,
  getReferencedValue,
  getSequenceStringOfElement,
  getElementsInBin,
  getChildSetIds
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { uniqBy, takeWhile, takeRightWhile } from "lodash";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";
import { getAllCardIdsInDesign } from "../../../tg-iso-design/utils/designEditUtils";
import { toJsRegexStr } from "../../../tg-iso-shared/utils/enzymeUtils";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

export const getAncestralValidatorSet = tgCreateCachedSelector(
  state => state,
  (state, cardId) => cardId,
  (state, cardId, setId) => setId,
  (state, cardId, setId) => {
    const sets = getAllOfType(state, "set");
    let parent = sets[setId];
    do {
      if (parent.isValidationSet) return parent;
      const child = parent;
      parent = getParentSet(state, cardId, child.id);
    } while (parent);
    return null;
  }
)((state, cardId, setId) => `${cardId}:${setId}`);

export const getSetsValidatingSet = tgCreateCachedSelector(
  state => state,
  (state, setId) => getItemOfType(state, "set", setId),
  state => getAllCardIdsInDesign(getDesignState(state)),
  (state, set, allCardIds) => {
    if (set.isFixed) return [];
    else
      return uniqBy(
        allCardIds.reduce((validators, cardId) => {
          const validator = getAncestralValidatorSet(state, cardId, set.id);
          if (validator) validators.push(validator);
          return validators;
        }, []),
        "id"
      );
  }
)((state, setId) => `${setId}`);

export const getMainSetOfValidator = tgCreateCachedSelector(
  state => getAllOfType(state, "set"),
  (state, validatorSetId) =>
    getReferencedValue(state, "set", validatorSetId, "parentSets"),
  (sets, childSetSets) => {
    const mainSetSet = childSetSets.find(ss => !sets[ss.childSetId].isFixed);
    return mainSetSet ? sets[mainSetSet.childSetId] : null;
  }
)((state, validatorSetId) => `${validatorSetId}`);

export const getRegexOfValidatorGeneric = tgCreateCachedSelector(
  state => state,
  state => getAllOfType(state, "set"),
  (state, validatorSetId) =>
    getReferencedValue(state, "set", validatorSetId, "parentSets"),
  (state, validatorSetId, isStart) => isStart,
  (state, sets, childSetSets, isStart) =>
    (isStart ? takeWhile : takeRightWhile)(
      [...childSetSets].sort((a, b) => a.index - b.index),
      ss => sets[ss.childSetId].isFixed
    )
      .map(ss => getElementsInBin(state, ss.childSetId)[0].regex || "")
      .join("")
)((state, validatorSetId, isStart) => `${validatorSetId}:${!!isStart}`);

export const getFixedSetsOfValidatorGeneric = tgCreateCachedSelector(
  state => state,
  state => getAllOfType(state, "set"),
  (state, validatorSetId) =>
    getReferencedValue(state, "set", validatorSetId, "parentSets"),
  (state, validatorSetId, isStart) => isStart,
  (state, sets, childSetSets, isStart) =>
    (isStart ? takeWhile : takeRightWhile)(
      [...childSetSets].sort((a, b) => a.index - b.index),
      ss => sets[ss.childSetId].isFixed
    ).map(ss => sets[ss.childSetId])
)((state, validatorSetId, isStart) => `${validatorSetId}:${!!isStart}`);

export const isElementValid = tgCreateCachedSelector(
  (state, validationSetId) =>
    getRegexOfValidatorGeneric(state, validationSetId, true),
  (state, validationSetId) =>
    getRegexOfValidatorGeneric(state, validationSetId, false),
  (state, validationSetId, elementId, setDirection) => {
    const seqStr = getSequenceStringOfElement(state, elementId);
    return setDirection ? seqStr : getReverseComplementSequenceString(seqStr);
  },
  (startRegex, endRegex, elementSequenceString) =>
    new RegExp("^" + toJsRegexStr(startRegex), "i").test(
      elementSequenceString
    ) &&
    new RegExp(toJsRegexStr(endRegex) + "$", "i").test(elementSequenceString)
)(
  (state, validationSetId, elementId, setDirection) =>
    `${validationSetId}:${elementId}:${!!setDirection}`
);

export const isElementEndValid = tgCreateCachedSelector(
  (state, validationSetId, elementId, setDirection, isStart) =>
    getRegexOfValidatorGeneric(state, validationSetId, isStart),
  (state, validationSetId, elementId, setDirection) => {
    const seqStr = getSequenceStringOfElement(state, elementId);
    return setDirection ? seqStr : getReverseComplementSequenceString(seqStr);
  },
  (state, validationSetId, elementId, setDirection, isStart) => isStart,
  (regex, elementSequenceString, isStart) => {
    const regExp = isStart
      ? new RegExp("^" + toJsRegexStr(regex), "i")
      : new RegExp(toJsRegexStr(regex) + "$", "i");
    return regExp.test(elementSequenceString);
  }
)(
  (state, validationSetId, elementId, setDirection, isStart) =>
    `${validationSetId}:${elementId}:${!!setDirection}:${!!isStart}`
);

export const isSetBorderWithinValidatorGeneric = tgCreateCachedSelector(
  state => state,
  (state, cardId, setId) => setId,
  (state, cardId, setId, isLeftBorder) => isLeftBorder,
  getAncestralValidatorSet,
  (state, setId, isLeftBorder, validator) => {
    if (!validator || validator.id === setId) return false;
    const childSetIds = getChildSetIds(state, validator.id);
    const index = childSetIds.indexOf(setId);
    if (index === -1) {
      console.error("Child bin ids should include the bin id.");
      return false;
    }
    if (isLeftBorder) return index !== 0;
    else return index !== childSetIds.length - 1;
  }
)(
  (state, cardId, setId, isLeftBorder) => `${cardId}:${setId}:${!!isLeftBorder}`
);
