/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import fullUserFragment from "../fragments/fullUserFragment.gql";
const userQuery = gql`
  query currentUserQuery {
    currentUser {
      ...fullUserFragment
    }
  }
  ${fullUserFragment}
`;

export default userQuery;
