/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { DataTable, withTableParams, CheckboxField } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import {
  unitColumns,
  dateModifiedColumn
} from "../../../../src-shared/utils/libraryColumns";
import { addTagFilterToQuery } from "../../../../src-shared/utils/tagUtils";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";

import { withProps } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { addActiveProjectFilter } from "../../../../src-shared/utils/projectUtils";

const schema = {
  model: "aliquotUnassignedView",
  fields: [
    ...unitColumns,
    { path: "aliquot.sample.name", type: "string", displayName: "Sample" },
    {
      path: "fullPath",
      displayName: "Location"
    },
    tagColumnWithRender,
    dateModifiedColumn
  ]
};

function AliquotsTable({ tableParams, onDoubleClick, multi }) {
  const showAssignedAliquots = val => {
    tableParams.setNewParams({
      ...tableParams.currentParams,
      showAssigned: val
    });
  };

  return (
    <React.Fragment>
      <CheckboxField
        name="showAssigned"
        label="Show Assigned Aliquots"
        onFieldSubmit={showAssignedAliquots}
      />
      <DataTable
        {...tableParams}
        maxHeight={450}
        onDoubleClick={onDoubleClick}
        noPadding
        withCheckboxes={multi}
        isSingleSelect={!multi}
      ></DataTable>
    </React.Fragment>
  );
}

const fragment = [
  "aliquotUnassignedView",
  `
  id
  isUnassigned
  fullPath
  aliquot {
    id
    mass
    massUnitCode
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    sample {
      id
      name
    }
    taggedItems {
      id
      tag {
        id
        name
        color
      }
      tagOption {
        id
        name
        color
      }
    }
  }
  updatedAt
`
];

export default compose(
  reduxForm({
    form: "aliquotsDisplayTableOptions"
  }),
  tgFormValues("showAssigned"),
  withTableParams({
    urlConnected: false,
    formName: "aliquotsDisplayTable",
    schema,
    defaults: {
      order: ["-modified"]
    },
    additionalFilter: (props, qb, currentParams) => {
      qb.whereAll({
        isUnassigned: !props.showAssigned
      });
      addTagFilterToQuery(currentParams.tags, qb, {
        pathToTaggedItems: "aliquot.taggedItems"
      });
      addActiveProjectFilter(qb, {
        pathToProjectId: "aliquot.projectItems.projectId",
        model: "aliquot",
        isUsingView: true
      });
    }
  }),
  withQuery(fragment, { isPlural: true }),
  withProps(props => {
    if (props.tableParams.entities && props.tableParams.entities.length) {
      return {
        tableParams: {
          ...props.tableParams,
          entities: props.tableParams.entities.map(e => {
            return { ...e, ...e.aliquot };
          })
        }
      };
    }
  })
)(AliquotsTable);
