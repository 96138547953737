/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withState } from "recompose";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import downloadServerFile from "../../utils/downloadServerFile";
import { min } from "lodash";

function PreviewImageFileDialog(props) {
  const { hideModal, file, setImageDims, imageDims } = props;

  const maxHeight = window.innerHeight - 200;

  return (
    <React.Fragment>
      <div
        className={Classes.DIALOG_BODY}
        style={{
          height: min([imageDims.height, maxHeight])
        }}
      >
        <img
          alt="preview"
          style={{
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          onLoad={({ target }) => {
            setImageDims({
              width: target.naturalWidth,
              height: target.naturalHeight
            });
          }}
          src={file.url || file.path}
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        text="Download"
        onClick={() => {
          downloadServerFile(file);
        }}
      />
    </React.Fragment>
  );
}

export default compose(
  withState("imageDims", "setImageDims", {}),
  wrapDialog(() => {
    return {
      title: "Preview",
      style: {
        width: "fit-content"
      }
    };
  })
)(PreviewImageFileDialog);
