/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import lengthUnitFragment from "../lengthUnitFragment.gql";

export default gql`
  fragment labelMediaFragment on labelMedia {
    id
    name
    description
    isDotUnit
    height
    width
    heightUnitCode
    heightUnit {
      ...lengthUnitFragment
    }
    widthUnitCode
    widthUnit {
      ...lengthUnitFragment
    }
  }
  ${lengthUnitFragment}
`;
