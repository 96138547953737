/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Query } from "@apollo/client/react/components";
import { get, upperFirst, camelCase, isEmpty, isFunction, keyBy } from "lodash";
import { Loading } from "@teselagen/ui";
import React, { useEffect } from "react";
import pluralize from "pluralize";
import { generateStaticGqlFragment } from "../../tg-iso-shared/utils/gqlUtils";
import { generateQuery } from "@teselagen/apollo-methods";

/**
 * withQuery
 * @param {gql fragment} fragment supply a fragment as the first argument
 * @param {options} options
 * @typedef {object} options
 * @property {boolean} isPlural Are we searching for 1 thing or many?
 * @property {string} queryName What the props come back on ( by default = modelName + 'Query')
 * @property {boolean} asFunction If true, this gives you back a function you can call directly instead of a HOC
 * @property {string} idAs By default single record queries occur on an id. But, if the record doesn't have an id field, and instead has a 'code', you can set idAs: 'code'
 * @property {boolean} getIdFromParams Grab the id variable off the match.params object being passed in!
 * @property {boolean || string} showLoading Show a loading spinner over the whole component while the data is loading
 * @property {boolean} showError Default=true show an error message toastr if the an error occurs while loading the data
 * @return {props}: {xxxxQuery, data }
 */
export default function withQueryDynamic(options = {}) {
  return Component => props => {
    // runTimeQueryOptions are used to override query options by passing them
    // directly to the component wrapped with withQuery
    const { runTimeQueryOptions, ...componentProps } = props;
    const mergedOpts = getMergedOpts(options, runTimeQueryOptions);
    const {
      isPlural,
      asFunction,
      LoadingComp = Loading,
      nameOverride,
      client,
      variables,
      props: mapQueryProps,
      queryName,
      getIdFromParams,
      showLoading,
      inDialog,
      fragment: _fragment,
      showError = true,
      options: queryOptions,
      skip: skipQueryFn,
      ...rest
    } = mergedOpts;

    const {
      variables: propVariables,
      fetchPolicy,
      pollInterval,
      notifyOnNetworkStatusChange
    } = { ...componentProps, ...options, ...runTimeQueryOptions };

    if (Number(pollInterval) <= 4999) {
      throw new Error(`pollInterval ${pollInterval} can't be less 5 secs`);
    }

    const fragment = Array.isArray(_fragment)
      ? generateStaticGqlFragment(...[].concat(_fragment))
      : _fragment;

    const gqlQuery = generateQuery(fragment, mergedOpts);

    const modelName = get(fragment, "definitions[0].typeCondition.name.value");
    const nameToUse =
      nameOverride || (isPlural ? pluralize(modelName) : modelName);
    const queryNameToUse = queryName || nameToUse + "Query";

    let id;
    if (getIdFromParams) {
      id = get(props, "match.params.id");
      // id = parseInt(get(props, "match.params.id"), 10);
      if (!id) {
        console.error(
          "There needs to be an id passed here to ",
          queryNameToUse,
          "but none was found"
        );
        /* eslint-disable */
        debugger;
        /* eslint-enable */
      }
    }

    let shouldSkipQuery = false;
    if (skipQueryFn) {
      shouldSkipQuery = skipQueryFn(componentProps);
    }

    let extraOptions = queryOptions || {};
    if (typeof queryOptions === "function") {
      extraOptions = queryOptions(props) || {};
    }

    const { variables: extraOptionVariables, ...otherExtraOptions } =
      extraOptions;
    const variablesToUse = getVariables(
      props,
      propVariables,
      extraOptionVariables,
      {
        ...options,
        queryNameToUse
      }
    );
    if (
      get(variablesToUse, "filter.entity") &&
      get(variablesToUse, "filter.entity") !== modelName
    ) {
      console.error("filter model does not match fragment model!");
    }

    return (
      <Query
        query={gqlQuery}
        //default options
        {...(!isEmpty(variablesToUse) && { variables: variablesToUse })}
        fetchPolicy={fetchPolicy || "network-only"}
        nextFetchPolicy="cache-first"
        ssr={false}
        pollInterval={pollInterval}
        notifyOnNetworkStatusChange={notifyOnNetworkStatusChange}
        skip={shouldSkipQuery}
        {...otherExtraOptions}
        {...rest} //overwrite defaults here
      >
        {({ data: _data, ...queryProps }) => {
          let allPropsForComponent = componentProps,
            newData;
          if (!shouldSkipQuery) {
            const data = {
              ..._data,
              ...queryProps
            };

            const results = get(data, nameToUse + (isPlural ? ".results" : ""));
            const { tableParams } = componentProps;
            const totalResults = isPlural
              ? get(data, nameToUse + ".totalResults", 0)
              : results && 1;

            newData = {
              ...data,
              totalResults,
              //adding these for consistency with withItemsQuery
              entities: results,
              entityCount: totalResults,
              ["error" + upperFirst(nameToUse)]: data.error,
              ["loading" + upperFirst(nameToUse)]: data.loading
            };

            data.loading = data.loading || data.networkStatus === 4;

            let newTableParams;
            if (
              tableParams &&
              !tableParams.entities &&
              !tableParams.isLoading
            ) {
              const entities = results;

              newTableParams = {
                ...tableParams,
                isLoading: data.loading,
                entities,
                entityCount: totalResults,
                onRefresh: data.refetch,
                variables: variablesToUse,
                fragment
              };
            }

            const propsToReturn = {
              ...(newTableParams && { tableParams: newTableParams }),
              data: newData,
              [queryNameToUse]: newData,
              [nameToUse]: results,
              [nameToUse + "Error"]: data.error,
              [nameToUse + "Loading"]: data.loading,
              [nameToUse + "Count"]: totalResults,
              [camelCase("refetch_" + nameToUse)]: data.refetch,
              fragment,
              gqlQuery
            };

            if (data.loading && showLoading) {
              const bounce = inDialog || showLoading === "bounce";
              return <LoadingComp inDialog={inDialog} bounce={bounce} />;
            }

            allPropsForComponent = {
              ...componentProps,
              ...propsToReturn
            };

            if (isFunction(mapQueryProps)) {
              allPropsForComponent = {
                ...allPropsForComponent,
                ...mapQueryProps(allPropsForComponent)
              };
            }
          }

          return (
            <ComponentHelper
              Component={Component}
              showError={showError}
              data={newData}
              queryNameToUse={queryNameToUse}
              componentProps={allPropsForComponent}
            />
          );
        }}
      </Query>
    );
  };
}

function getMergedOpts(topLevelOptions, runTimeQueryOptions) {
  return { ...topLevelOptions, ...runTimeQueryOptions };
}

function getVariables(ownProps, propVariables, extraOptionVariables, options) {
  const { getIdFromParams, queryNameToUse, variables } = options;
  let id;
  if (getIdFromParams) {
    id = get(ownProps, "match.params.id");
    // id = parseInt(get(ownProps, "match.params.id"), 10);
    if (!id) {
      console.error(
        "There needs to be an id passed here to ",
        queryNameToUse,
        "but none was found"
      );
      debugger; // eslint-disable-line
      // to prevent crash
      id = -1;
    }
  }

  return {
    ...(getIdFromParams && { id }),
    ...variables,
    ...propVariables,
    ...(extraOptionVariables && extraOptionVariables)
  };
}

const ComponentHelper = ({
  showError,
  data,
  loggedIn,
  queryNameToUse,
  componentProps: {
    tableParams: { entities, selectedEntities, changeFormValue } = {}
  },
  Component,
  componentProps
}) => {
  useEffect(() => {
    if (showError && data && data.error) {
      const error = data.error;
      if (loggedIn) {
        console.error("error:", error);
        window.toastr.error(`Error loading ${queryNameToUse}`);
      } else {
        console.warn("Error supressed, not logged in");
      }
    }
  }, [data, loggedIn, queryNameToUse, showError]);

  const selectTableRecords = (ids, keepOldEntities) => {
    setTimeout(() => {
      const key = entities[0]?.code ? "code" : "id";

      const entitiesById = keyBy(entities, key);
      const newIdMap = {
        ...(keepOldEntities && selectedEntities)
      };
      ids.forEach(id => {
        const entity = entitiesById[id];
        if (!entity) return;
        newIdMap[id] = {
          entity
        };
      });
      changeFormValue("reduxFormSelectedEntityIdMap", newIdMap);
    });
  };

  const extraProps = {};
  if (componentProps.tableParams) {
    extraProps.selectTableRecords = selectTableRecords;
  }
  return <Component {...componentProps} {...extraProps} />;
};
