/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import withQuery from "../../../src-shared/withQuery";

import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import { Callout } from "@blueprintjs/core";
import { noop } from "lodash";
import { dataItemTypeMap } from "../../../../tg-iso-shared/src/utils/inventoryUtils";
import microserviceTaskStatus from "./microserviceTaskStatus";

class MicroserviceRecordStatus extends Component {
  componentDidUpdate() {
    const { ioItemsQuery, ioItemsLoading, refetch = noop } = this.props;
    const microserviceQueue = this.getMicroserviceQueue();
    if (!ioItemsLoading && !microserviceQueue) {
      ioItemsQuery.stopPolling();
    } else if (microserviceQueue) {
      const status = microserviceTaskStatus[microserviceQueue.status];
      if (!status.loading) {
        ioItemsQuery.stopPolling();
        refetch();
      }
    }
  }

  getMicroserviceQueue() {
    const { ioItems = [] } = this.props;
    let microserviceQueue;
    ioItems.forEach(ioItem => {
      if (ioItem.microserviceQueue) {
        microserviceQueue = ioItem.microserviceQueue;
      }
    });
    return microserviceQueue;
  }

  render() {
    const { ioItemsLoading } = this.props;
    if (ioItemsLoading) {
      return null;
    }
    const microserviceQueue = this.getMicroserviceQueue();
    if (microserviceQueue) {
      const status = microserviceTaskStatus[microserviceQueue.status];
      if (status) {
        return (
          <div style={{ marginBottom: 15, marginTop: 15, maxWidth: 350 }}>
            <Callout
              intent={status.intent}
              icon={status.loading ? "refresh" : undefined}
            >
              Task Status: {status.description}
            </Callout>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

const ioItemFragment = generateFragmentWithFields(
  "ioItem",
  `id microserviceQueue { id status }`
);

export default compose(
  withQuery(ioItemFragment, {
    isPlural: true,
    options: props => {
      const { record } = props;
      const filter = {};
      if (dataItemTypeMap[record.__typename]) {
        filter[`dataItem.${record.__typename + "Id"}`] = record.id;
      } else {
        filter[`inventoryItem.${record.__typename + "Id"}`] = record.id;
      }
      return {
        pollInterval: 10000,
        variables: {
          filter
        }
      };
    }
  })
)(MicroserviceRecordStatus);
