/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { forEach, isObject, difference, startCase } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  TextareaField,
  DialogFooter,
  NumericInputField,
  ReactSelectField,
  CheckboxField,
  RadioGroupField
} from "@teselagen/ui";
import shortid from "shortid";
import GenericSelect from "../../../../src-shared/GenericSelect";
import "./style.css";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { aliquotContainerBottoms } from "../../../utils/plateUtils";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import UnitInputField from "../../UnitInputField";

const fieldMap = {
  name: InputField,
  liters: NumericInputField,
  grams: NumericInputField,
  gramsPerLiter: NumericInputField,
  moles: NumericInputField,
  molesPerLiter: NumericInputField,
  description: TextareaField,
  instructions: TextareaField
};

class LaunchNewLookupValueDialog extends Component {
  renderExtraComponentFields = () => {
    const {
      aliquotContainerTypes = [],
      ioItemTypes = [],
      model,
      initialValues = {}
    } = this.props;
    const isUpdate = initialValues.code || initialValues.id;
    const isPlate = initialValues.isPlate;
    if (model === "containerArrayType") {
      return (
        <div>
          <GenericSelect
            disabled={isUpdate}
            asReactSelect
            name="containerFormat"
            secondaryLabel={isUpdate && "(Cannot update container format)"}
            isRequired
            label="Format"
            idAs="code"
            fragment={["containerFormat", "code name"]}
            queryOptions={{
              variables: {
                sort: ["quadrantSize", "name", "code"]
              }
            }}
          />
          <InputField
            name="catalogNumber"
            label="Catalog Number"
            placeholder="Enter a catalog number..."
          />
          <InputField
            name="manufacturer"
            label="Manufacturer"
            placeholder="Enter a manufacturer..."
          />
          {isPlate ? (
            <>
              <CheckboxField
                label="Is Column?"
                name="isColumn"
                defaultValue={false}
              />
              <hr className="tg-section-break" />
              <h5 style={{ marginBottom: 15 }}> Plate Well Info </h5>
              <InputField
                name="aliquotContainerType.name"
                label="Plate Well Type Name"
                isRequired
                defaultValue="Round"
              />
              <TextareaField
                name="aliquotContainerType.description"
                label="Plate Well Type Description"
              />
              <UnitInputField
                name="aliquotContainerType.maxVolume"
                disabled={isUpdate}
                isRequired
                label="Max Volume"
                secondaryLabel={isUpdate && "Cannot update max volume"}
                tooltipInfo="Max volume that plate well can hold."
                unitName="aliquotContainerType.volumetricUnitCode"
                unitDefault="uL"
                unitType="volumetricUnit"
              />
              <UnitInputField
                name="aliquotContainerType.deadVolume"
                tooltipInfo="Amount of volume that cannot be extracted from this plate well"
                label="Dead Volume"
                unitName="aliquotContainerType.deadVolumetricUnitCode"
                unitDefault="uL"
                unitType="volumetricUnit"
              />
              <ReactSelectField
                name="aliquotContainerType.bottom"
                label="Plate Well Bottom"
                options={aliquotContainerBottoms}
              />
            </>
          ) : (
            <ReactSelectField
              multi
              isRequired
              name="nestableTubeTypes"
              label="Tube Types"
              options={arrayToIdOrCodeValuedOptions(aliquotContainerTypes).map(
                item => {
                  if (
                    initialValues.nestableTubeTypes &&
                    initialValues.nestableTubeTypes.includes(item.value)
                  ) {
                    return {
                      ...item,
                      disabled: true
                    };
                  } else {
                    return item;
                  }
                }
              )}
            />
          )}
        </div>
      );
    }
    if (model === "aliquotContainerType") {
      return (
        <>
          <UnitInputField
            name="maxVolume"
            label="Max Volume"
            disabled={isUpdate}
            secondaryLabel={isUpdate && "Cannot update max volume"}
            tooltipInfo="Max volume that tube can hold."
            unitName="volumetricUnitCode"
            unitDefault="uL"
            isRequired
            unitType="volumetricUnit"
          />
          <UnitInputField
            name="deadVolume"
            tooltipInfo="Amount of volume that cannot be extracted from this tube"
            label="Dead Volume"
            unitName="deadVolumetricUnitCode"
            unitDefault="uL"
            unitType="volumetricUnit"
          />
          <ReactSelectField
            name="bottom"
            label="Bottom"
            options={aliquotContainerBottoms}
          />
        </>
      );
    }
    if (model === "taskIoType") {
      return (
        <ReactSelectField
          label="Item Type"
          name="ioItemTypeCode"
          isRequired
          options={arrayToIdOrCodeValuedOptions(ioItemTypes)}
        />
      );
    }
    if (model === "containerFormat") {
      return (
        <>
          <NumericInputField
            label="Rows"
            name="rowCount"
            isRequired
            disabled={isUpdate}
          />
          <NumericInputField
            label="Columns"
            name="columnCount"
            isRequired
            disabled={isUpdate}
          />
          <RadioGroupField
            label="Label Type"
            options={[
              {
                label: "Alphanumeric (e.g. A1, A2)",
                value: "alphanumeric"
              },
              {
                label: "Index (e.g. 1, 2)",
                value: "index"
              }
            ]}
            name="is2DLabeled"
          />
        </>
      );
    }
  };

  onSubmit = async formData => {
    const {
      upsertLookupValue,
      hideModal,
      model,
      label,
      refetch,
      cb,
      initialValues = {},
      isCodeModel
    } = this.props;
    const isUpdate = initialValues.code || initialValues.id;
    const readableModelName = modelNameToReadableName(label || model);
    let toUpsert;
    if (model === "containerArrayType") {
      if (initialValues.isPlate) {
        const {
          aliquotContainerType,
          isPlate,
          containerFormat,
          ...rest
        } = formData;
        toUpsert = {
          isPlate: true,
          aliquotContainerType: {
            ...aliquotContainerType,
            isTube: false
          },
          containerFormatCode: containerFormat.code,
          ...rest
        };
        if (isUpdate) {
          await safeUpsert(
            "aliquotContainerType",
            {
              code: aliquotContainerType.code,
              name: aliquotContainerType.name,
              description: aliquotContainerType.description,
              bottom: aliquotContainerType.bottom,
              maxVolume: aliquotContainerType.maxVolume,
              volumetricUnitCode: aliquotContainerType.volumetricUnitCode,
              deadVolume: aliquotContainerType.deadVolume,
              deadVolumetricUnitCode:
                aliquotContainerType.deadVolumetricUnitCode
            },
            {
              idAs: "code",
              forceUpdate: true
            }
          );
        } else {
          toUpsert.aliquotContainerType.code = shortid();
        }
        delete toUpsert.nestableTubeTypes;
      } else {
        const {
          nestableTubeTypes: tubeCodes,
          aliquotContainerType,
          isPlate,
          containerFormat,
          ...rest
        } = formData;
        const nestableTubeTypes = tubeCodes.map(code => {
          return { aliquotContainerTypeCode: code };
        });
        toUpsert = {
          nestableTubeTypes,
          aliquotContainerType: null,
          isPlate: false,
          containerFormatCode: containerFormat.code,
          ...rest
        };
        if (isUpdate) {
          const newTubeTypeCodes = tubeCodes;
          const initialTubeTypeCodes = initialValues.nestableTubeTypes;
          const addedTubeCodes = difference(
            newTubeTypeCodes,
            initialTubeTypeCodes
          );
          const removedTubeCodes = difference(
            initialTubeTypeCodes,
            newTubeTypeCodes
          );
          await safeDelete(
            "nestableTubeType",
            toUpsert.initialNestableTubeTypes
              .filter(ntt =>
                removedTubeCodes.includes(ntt.aliquotContainerTypeCode)
              )
              .map(ntt => ntt.id)
          );
          await safeUpsert(
            "nestableTubeType",
            addedTubeCodes.map(code => ({
              aliquotContainerTypeCode: code,
              containerArrayTypeId: toUpsert.id
            }))
          );
          delete toUpsert.nestableTubeTypes;
          delete toUpsert.initialNestableTubeTypes;
        }
      }
    } else if (model === "aliquotContainerType") {
      toUpsert = {
        ...formData,
        isTube: true
      };
    } else if (model === "containerFormat") {
      toUpsert = {
        ...formData,
        is2DLabeled: formData.is2DLabeled === "alphanumeric",
        quadrantSize: formData.rowCount * formData.columnCount
      };
    } else {
      toUpsert = {
        ...formData
      };
    }
    delete toUpsert.__typename;
    if (isUpdate) {
      forEach(toUpsert, (val, key) => {
        if (isObject(val)) delete toUpsert[key];
        if (val === undefined) delete toUpsert[key];
      });
    }
    try {
      if (!isUpdate && isCodeModel) {
        toUpsert.code = shortid();
      }
      await upsertLookupValue(toUpsert);
      await refetch();
      if (cb) await cb();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(
        `Error ${isUpdate ? "updating" : "creating"} ${readableModelName}`
      );
    }
  };

  render() {
    const {
      fields,
      hideModal,
      handleSubmit,
      readableModelName,
      submitting
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          {fields.map(field => {
            const FieldComponent =
              typeof field === "string" ? fieldMap[field] : field;
            const props = {
              name: field,
              label: startCase(field)
            };
            if (field === "name") {
              props.placeholder = `New ${readableModelName}`;
              props.isRequired = true;
            }
            return <FieldComponent key={field} {...props} />;
          })}
          {this.renderExtraComponentFields()}
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

export default LaunchNewLookupValueDialog;
