/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default async function incrementWorklistCounterProperties(
  app,
  aliquotContainerIds = []
) {
  if (aliquotContainerIds.length) {
    if (!app.get("config").gqlServerDisabled) {
      await app.get(
        "gqlCustomEndpointHandler"
      )("incrementWorklistCounterProperties", { aliquotContainerIds });
    } else {
      const incrementWorklistCounterExtendedPropertiesSql = `
      update "extendedValue"
      set value = to_json (coalesce(value::text::int, 0) + 1)
      where id in (
        with "epCounter" as (
          select id from "extendedProperty" ep inner join "extendedType" et on et.code = ep."extendedTypeCode" and et.code = 'worklistCounter'
        )
        select ev.id "extendedValueId" from
        "aliquotContainer" ac
          left outer join
          "aliquot" on aliquot.id = ac."aliquotId"
          left outer join
          "additive" on additive."aliquotId" = aliquot.id or additive."aliquotContainerId" = ac.id
          left outer join
          "lot" on additive."lotId" = lot.id
          left outer join
          "additiveMaterial" am on am.id = additive."additiveMaterialId"
          left outer join
          "sample" on aliquot."sampleId" = sample.id
          left outer join
          "material" on sample."materialId" = material.id
          inner join
          "extendedValue" ev on
            ev."aliquotContainerId" = ac."id" or
            ev."aliquotId" = ac."aliquotId" or
            ev."materialId" = material.id or
            ev."sampleId" = sample.id or
            ev."containerArrayId" = ac."containerArrayId"  or
            ev."lotId" = additive."lotId" or
            ev."additiveMaterialId" = additive."additiveMaterialId" or
            ev."additiveMaterialId" = lot."additiveMaterialId"
            inner join "epCounter" on "epCounter".id = ev."extendedPropertyId"
            where ac.id in ( ${aliquotContainerIds
              .map(id => `'${id}'`)
              .toString()} )
      );
      `;
      await app.get("db").raw(incrementWorklistCounterExtendedPropertiesSql);
    }
  }
}
