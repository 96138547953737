/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import widgetCardStyles from "../../WidgetCardStyles.module.css";
import { HTMLSelect } from "@blueprintjs/core";

class ModelGroupedHistogramWidgetHeader extends Component {
  constructor(props) {
    super(props);

    const { updateWidgetData, data } = props;
    const features = Object.keys(data["suggested_batch_hist_info"]);

    updateWidgetData({ featureToPlot: features[0] });

    this.state = {
      features: features,
      updateWidgetData: updateWidgetData
    };
  }

  changeSelectedFeature = event => {
    const { updateWidgetData } = this.state;
    const selectedFeature = event.currentTarget.value;

    updateWidgetData({ featureToPlot: selectedFeature });
  };

  generateSelectFromFeatures = features => {
    return (
      <HTMLSelect onChange={this.changeSelectedFeature}>
        {features.map((feature, index) => {
          return <option key={`${feature}_${index}`}>{feature}</option>;
        })}
      </HTMLSelect>
    );
  };

  render() {
    const { features } = this.state;

    return (
      <div className={widgetCardStyles.widgetHeader}>
        <h4>Variant distribution</h4>
        {this.generateSelectFromFeatures(features)}
      </div>
    );
  }
}

export default ModelGroupedHistogramWidgetHeader;
