/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment containerArrayWorklistFragment on containerArray {
    id
    name
    containerArrayType {
      id
      name
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        is2DLabeled
        quadrantSize
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        code
        deadVolume
        deadVolumetricUnitCode
      }
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
      }
      containerArray {
        id
        name
      }
      columnPosition
      rowPosition
    }
  }
`;
