/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  TextareaField,
  DateInputField,
  DialogFooter,
  SelectField,
  wrapDialog
} from "@teselagen/ui";

import withQuery from "../../../../src-shared/withQuery";

import { isEmpty, noop } from "lodash";
import {
  arrayToIdOrCodeValuedOptions,
  asyncValidateBarcodeHandler
} from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import CreateOrGenerateBarcodeField from "../../CreateOrGenerateBarcodeField";
import HasContentsCheckbox from "../AddNewEquipmentDialog/HasContentsCheckbox";

class EditEquipmentDialog extends Component {
  onSubmit = async values => {
    const {
      editFields,
      hideModal,
      refetch = noop,
      initialValues = {}
    } = this.props;
    try {
      const barcodeWasChanged =
        values.barcode &&
        values.barcode.barcodeString !== initialValues.barcode?.barcodeString;
      if (barcodeWasChanged) {
        await safeUpsert("barcode", {
          id: initialValues.barcode?.id,
          barcodeString: values.barcode.barcodeString,
          equipmentItemId: values.id
        });
      }
      delete values.barcode;
      await safeUpsert(["equipmentItem", editFields], values);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating equipment.");
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      hideModal,
      equipmentTypes = [],
      initialValues,
      asyncValidating
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" isRequired />
          <InputField name="label" label="Label" />
          <HasContentsCheckbox />
          <CreateOrGenerateBarcodeField
            initialValues={initialValues}
            asyncValidating={asyncValidating}
          />
          <TextareaField name="description" label="Description" />
          <SelectField
            name="equipmentTypeCode"
            label="Equipment Type"
            placeholder="Select an equipment type"
            options={arrayToIdOrCodeValuedOptions(equipmentTypes)}
          />
          <InputField name="manufacturer" label="Manufacturer" />
          <InputField name="serialNumber" label="Serial Number" />
          <DateInputField
            name="warrantyExpiration"
            label="Warranty Expiration Date"
            minDate={new Date("1/1/2010")}
            maxDate={new Date("12/31/2100")}
          />
          <InputField name="make" label="Make" />
          <InputField name="model" label="Model" />
          <TextareaField
            name="serviceContactInfo"
            label="Service Contact Info"
          />
          <DateInputField
            name="serviceContractExpiration"
            label="Service Contract Expiration Date"
            minDate={new Date("1/1/2010")}
            maxDate={new Date("12/31/2100")}
          />
        </div>
        <DialogFooter submitting={submitting} hideModal={hideModal} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Update Equipment" }),
  reduxForm({
    form: "editEquipmentForm",
    asyncValidate: async (values, dis, ownProps) => {
      const errors = {};
      const options = {
        values,
        ownProps,
        model: "equipmentItem",
        errors
      };
      await asyncValidateBarcodeHandler(options);
      if (!isEmpty(errors)) {
        throw errors;
      }
    }
  }),
  withQuery(["equipmentType", "code name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(EditEquipmentDialog);
