/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { unparse } from "papaparse";
import { get, reduce } from "lodash";
import {
  calculateMolarityFromConcentration,
  calculateConcentrationFromMolarity,
  molarToNanoMolar,
  defaultConcentrationUnitCode,
  defaultVolumetricUnitCode,
  defaultMassUnitCode,
  defaultMolarityUnitCode
} from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { parseCsvOrExcelFile } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { download } from "../../../../src-shared/utils/downloadTest";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

export function getItemHeader(item) {
  return `${modelNameToReadableName(item.__typename)} name`;
}

export function downloadTemplateFile(
  selectedPlateMaps,
  additionalFields,
  fileName
) {
  const csvRows = [];
  selectedPlateMaps.forEach(pm => {
    pm.plateMapItems.forEach(pmi => {
      const item = getPlateMapItemContent(pm, pmi);
      csvRows.push({
        "plate map name": pm.name,
        "plate map item id": pmi.id,
        ...(item && {
          [getItemHeader(item)]: item.name
        }),
        position: getAliquotContainerLocation({
          rowPosition: pmi.rowPosition,
          columnPosition: pmi.columnPosition
        }),
        ...additionalFields
      });
    });
  });
  const csv = unparse(csvRows);
  download(csv, `${fileName || "template_file"}.csv`);
}

export async function parseCsvAndSave(files, onChange) {
  try {
    const file = files[0];
    const parsedCsv = await parseCsvOrExcelFile(file);
    const newFile = {
      ...file,
      parsedCsv,
      loading: false
    };
    delete newFile.parsedString;
    onChange([newFile]);
  } catch (error) {
    onChange([]);
    console.error("error:", error);
    window.toastr.error(error.message || "Error parsing csv file.");
  }
  return false;
}

export function preparePlatesInput(values) {
  const {
    plateMaps,
    plateMapBarcodes = [],
    wellContentsInfoUpload,
    plateBarcodeTubesUpload,
    volumeAndConcentration: volumeAndConcentrationFieldValue,
    volume,
    volumetricUnitCode,
    concentration,
    concentrationUnitCode,
    mass,
    massUnitCode,
    selectedContainerArrayTypes = {},
    selectedTubeTypes,
    plateMapNames,
    molarity,
    concentrationType,
    molarityUnitCode,
    generateBarcodes,
    overrideVolumeInfo
  } = values;

  const wellContentsCsvData = get(wellContentsInfoUpload, "[0].parsedCsv.data");
  const plateBarcodeTubesCsvData = get(
    plateBarcodeTubesUpload,
    "[0].parsedCsv.data"
  );
  let usedTubeCsvData = false;
  const formattedTubeTypes = reduce(
    selectedTubeTypes,
    (acc, c, key) => {
      acc[key.split(",")[0]] = c;
      return acc;
    },
    {}
  );
  const formattedPlateMapNames = reduce(
    plateMapNames,
    (acc, name, key) => {
      acc[key] = name;
      return acc;
    },
    {}
  );

  const plateInputs = plateMaps.map((plateMap, i) => {
    let aliquotContainerTypeCode;
    if (selectedContainerArrayTypes["id" + plateMap.id].isPlate) {
      aliquotContainerTypeCode =
        selectedContainerArrayTypes["id" + plateMap.id]
          .aliquotContainerTypeCode;
    } else {
      aliquotContainerTypeCode =
        formattedTubeTypes["id" + plateMap.id].aliquotContainerTypeCode;
    }
    let aliquotContainers = plateMap.plateMapItems.map(plateMapItem => {
      const molecularWeight = get(
        plateMapItem,
        "inventoryItem.material.polynucleotideMaterialSequence.molecularWeight"
      );
      let volumeOrMassValues;
      let extraValues;
      if (!overrideVolumeInfo && plateMapItem.volume) {
        volumeOrMassValues = {
          isDry: false,
          volume: plateMapItem.volume,
          volumetricUnitCode: plateMapItem.volumetricUnitCode
        };
      } else if (volumeAndConcentrationFieldValue === "plateWideVolume") {
        if (molecularWeight) {
          if (concentrationType === "concentration" && concentration) {
            extraValues = {
              molarity: calculateMolarityFromConcentration(
                concentration,
                concentrationUnitCode,
                molecularWeight
              ),
              molarityUnitCode: "M",
              concentration,
              concentrationUnitCode
            };
          } else if (molarity) {
            extraValues = {
              concentration: calculateConcentrationFromMolarity(
                molarity,
                molarityUnitCode,
                molecularWeight
              ),
              molarity,
              molarityUnitCode,
              concentrationUnitCode: "g/L"
            };
          }
        } else {
          extraValues = {
            concentration,
            concentrationUnitCode
          };
        }

        volumeOrMassValues = {
          isDry: false,
          volume: volume,
          volumetricUnitCode: volumetricUnitCode
        };
      } else if (volumeAndConcentrationFieldValue === "plateWideMass") {
        volumeOrMassValues = {
          isDry: true,
          mass: mass,
          massUnitCode: massUnitCode
        };
      }
      if (wellContentsCsvData) {
        const plateMapItemRow = wellContentsCsvData.find(row => {
          return row["plate map item id"] === plateMapItem.id;
        });
        if (plateMapItemRow) {
          const volumetricUnitCode = (
            unitGlobals.volumetricUnits[
              get(plateMapItemRow, "volume unit", "")
                .toLowerCase()
                .trim()
            ] || unitGlobals.volumetricUnits[defaultVolumetricUnitCode]
          ).code;

          const concentrationUnitCode = (
            unitGlobals.concentrationUnits[
              get(plateMapItemRow, "concentration unit", "")
                .toLowerCase()
                .trim()
            ] || unitGlobals.concentrationUnits[defaultConcentrationUnitCode]
          ).code;

          const massUnitCode = (
            unitGlobals.massUnits[
              get(plateMapItemRow, "mass unit", "")
                .toLowerCase()
                .trim()
            ] || unitGlobals.massUnits[defaultMassUnitCode]
          ).code;

          const molarityUnitCode = (
            unitGlobals.molarityUnits[
              get(plateMapItemRow, "molarity unit", "")
                .toLowerCase()
                .trim()
            ] || unitGlobals.molarityUnits[defaultMolarityUnitCode]
          ).code;

          const volume = parseInt(plateMapItemRow.volume, 10);
          const concentration = parseInt(plateMapItemRow.concentration, 10);
          const mass = parseInt(plateMapItemRow.mass, 10);
          if (volume) {
            let concentrationFields;
            if (molecularWeight) {
              if (concentration) {
                concentrationFields = {
                  concentration,
                  concentrationUnitCode,
                  molarity:
                    calculateMolarityFromConcentration(
                      concentration,
                      concentrationUnitCode,
                      molecularWeight
                    ) * molarToNanoMolar,
                  molarityUnitCode: "nM"
                };
              } else if (molarity) {
                concentrationFields = {
                  molarity,
                  molarityUnitCode,
                  concentration: calculateConcentrationFromMolarity(
                    molarity,
                    molarityUnitCode,
                    molecularWeight
                  ),
                  concentrationUnitCode: "g/L"
                };
              }
            } else {
              concentrationFields = {
                concentration,
                concentrationUnitCode
              };
            }
            volumeOrMassValues = {
              isDry: false,
              volume,
              volumetricUnitCode,
              ...concentrationFields
            };
          } else if (mass) {
            volumeOrMassValues = {
              isDry: true,
              mass,
              massUnitCode
            };
          }
        }
      }

      let tubeBarcode;
      if (plateBarcodeTubesCsvData) {
        const plateMapItemRow = plateBarcodeTubesCsvData.find(row => {
          return row["plate map item id"] === plateMapItem.id;
        });
        if (plateMapItemRow) {
          usedTubeCsvData = true;
        }
        tubeBarcode = plateMapItemRow?.barcode;
      }
      const aliquotContainer = {
        aliquotContainerTypeCode,
        rowPosition: plateMapItem.rowPosition,
        columnPosition: plateMapItem.columnPosition,
        ...(tubeBarcode && {
          barcode: {
            barcodeString: tubeBarcode
          }
        })
      };
      if (plateMap.type === "additiveMaterial" || plateMap.type === "lot") {
        delete volumeOrMassValues.isDry;
        aliquotContainer.additives = [
          {
            ...volumeOrMassValues,
            ...extraValues,
            lotId: get(plateMapItem, "inventoryItem.lotId"),
            additiveMaterialId: get(
              plateMapItem,
              "inventoryItem.additiveMaterialId"
            )
          }
        ];
      } else if (plateMap.type === "sample") {
        aliquotContainer.aliquot = {
          ...volumeOrMassValues,
          ...extraValues,
          aliquotType: "sample-aliquot",
          sampleId: get(plateMapItem, "inventoryItem.sampleId")
        };
      } else {
        aliquotContainer.aliquot = {
          ...volumeOrMassValues,
          ...extraValues,
          aliquotType: "sample-aliquot",
          sample: {
            name: plateMapItem.name,
            sampleTypeCode: "REGISTERED_SAMPLE",
            materialId: get(plateMapItem, "inventoryItem.materialId")
          }
        };
      }
      return aliquotContainer;
    });
    const containerArrayType = selectedContainerArrayTypes["id" + plateMap.id];
    aliquotContainers = generateContainerArray(
      aliquotContainers,
      containerArrayType.containerFormat,
      {
        aliquotContainerTypeCode
      }
    );
    let barcode;
    if (!generateBarcodes && plateMapBarcodes) {
      barcode = plateMapBarcodes[i];
    }
    if (!!plateBarcodeTubesCsvData && !usedTubeCsvData) {
      // if they uploaded tube barcode csv file and it didn't match any rows
      // then it was a bad file which didn't have matching ids
      throw new Error(
        "Tube barcode CSV data did not match any of the selected plate maps. Make sure ID info is correct. Download template to verify."
      );
    }

    return {
      name: formattedPlateMapNames[plateMap.id] || plateMap.name,
      barcode: barcode && {
        barcodeString: barcode
      },
      containerArrayTypeId: containerArrayType.id,
      aliquotContainers
    };
  });
  return plateInputs;
}

export function getPlateMapEntitiesFromPlateMapGroups(plateMapGroups = []) {
  return plateMapGroups.reduce((acc, plateMapGroup) => {
    return acc.concat(
      plateMapGroup.plateMaps.map(plateMap => ({
        ...plateMap,
        containerFormatCode: plateMapGroup.containerFormatCode,
        plateMapGroupName: plateMapGroup.name
      }))
    );
  }, []);
}

export function getPlateMapItemContent(pm, pmi) {
  return get(pmi, `inventoryItem.${pm.type}`);
}

export function getInvalidPlateMaps(plateMapGroups = []) {
  const missingProperLink = [];
  plateMapGroups.forEach(plateMapGroup => {
    const missingItem = plateMapGroup.plateMaps.some(plateMap => {
      return plateMap.plateMapItems.some(pmi => {
        return !getPlateMapItemContent(plateMap, pmi);
      });
    });
    if (missingItem) missingProperLink.push(plateMapGroup.name);
  });
  return missingProperLink;
}
