/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";
import PositionRows from "../../PositionRows";
import { prepareEditingPositions } from "../../../utils";

class GenericEditPositionsDialog extends Component {
  onSubmit = async values => {
    const { refetch, hideModal, initialValues, model } = this.props;
    try {
      await prepareEditingPositions({
        values: {
          ...values,
          isPositional: true
        },
        initialValues,
        key: model + "Position",
        model
      });
      await refetch();
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error(`Error updating ${model} positions`);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, model } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <PositionRows
            name={model + "Positions"}
            addButtonText="Add Position"
          />
        </div>
        <DialogFooter {...{ hideModal, submitting }} />
      </form>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const key = props.model + "Positions";
  if (!values[key] || !values[key].length) {
    errors[key] = { _error: "Please add at least one position." };
  }
}

export default compose(
  reduxForm({
    form: "genericEditPositionsDialog",
    validate
  })
)(GenericEditPositionsDialog);
