/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { getItemOfType } from "../../../../../../../tg-iso-design/selectors/designStateSelectors";
import {
  getReactionColor,
  getSingleBlockWidth,
  getSelectedValidatedJunctionId
} from "../../../../../../src-shared/selectors/designViewSelectors";
import RestrictionSite from "./RestrictionSite";

import actions from "../../../../../../src-shared/redux/actions";
import { doesJunctionInjectSequence } from "../../../../../../../tg-iso-design/selectors/junctionSelectors";
import { VALIDATOR_HEIGHT } from "../../../../../../src-shared/components/HierarchicalDesign/constants";

const mapStateToProps = (state, props) => {
  const { junctionId, binId, cardId } = props;

  const singleBinWidth = getSingleBlockWidth(state, cardId);

  const junction = getItemOfType(state, "junction", junctionId);
  const on5PrimeCard = junction.fivePrimeCardInteriorBinId === binId;

  return {
    singleBinWidth,
    on5PrimeCard,
    overhangType: on5PrimeCard
      ? junction.fivePrimeCardOverhangTypeCode
      : junction.threePrimeCardOverhangTypeCode,
    junctionType: junction.junctionTypeCode,
    color: getReactionColor(state, junction.reactionId),
    injectsSequence: doesJunctionInjectSequence(junction),
    isSelected: getSelectedValidatedJunctionId(state) === junctionId
  };
};

const mapDispatchToProps = {
  selectValidatedJunction:
    actions.ui.designEditor.general.selectValidatedJunction
};

class Ligated extends React.Component {
  static propTypes = {
    /**
     * The id of the validated junction we are rendering.
     */
    junctionId: PropTypes.string.isRequired,

    /**
     * The id of the bin we are rendering the junction for.
     */
    binId: PropTypes.string.isRequired,

    /**
     * The id of the card we are rendering on.
     */
    cardId: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  /**
   * Render the indicators that will be displayed.
   */
  renderContent() {
    const { junctionType } = this.props;
    if (junctionType === "TYPE_IIS_ENZYME") {
      return <RestrictionSite {...this.props} />;
    } else {
      throw new Error(`Unrecognized validated junction type: ${junctionType}`);
    }
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: -VALIDATOR_HEIGHT,
          left: 0
        }}
        onClick={this.handleClick}
      >
        {this.renderContent()}
      </div>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Ligated);
