/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * See if the source and destination aliquots in a given array of
 * aliquot formulation objects form disjoint sets. In other words, make
 * sure that we are not transferring from any of the destination containers.
 * @param {Array<FullFormulation>} fullFormulations The full formulation objects to check.
 * @returns {boolean}
 */
function areSourcesAndDestinationsDisjoint(fullFormulations) {
  // Sets consisting of source and destination aliquot ids.
  const srcSet = {};
  const dstSet = {};

  // Populate the sets of source and destination aliquot ids.
  for (const f of fullFormulations) {
    const srcContainerId = f.getAliquotContainerId();

    if (srcContainerId) srcSet[srcContainerId] = true;

    const transfers = f.getTransfers();
    for (const t of transfers) {
      const entity = t.aliquotContainer;
      if (entity) {
        dstSet[entity.id] = true;
      }
    }
  }

  // Check that the sources and destinations are disjoint.
  for (const srcContainerId of Object.keys(srcSet)) {
    if (dstSet[srcContainerId]) return false;
  }

  return true;
}

export default areSourcesAndDestinationsDisjoint;
