// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.AllModelsView-module__margins-1qu92 {\n  margin-top: 50px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.AllModelsView-module__width-15XOb {\n  width: 60%;\n}\n\n.AllModelsView-module__headerContainer-3Dvkf {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.AllModelsView-module__headerContainer-3Dvkf h3 {\n  margin-bottom: 0;\n}\n\n.AllModelsView-module__headerContainer-3Dvkf button {\n  margin-right: 15px;\n}\n\n.AllModelsView-module__noticeText-h__Nt {\n  margin-top: 15px;\n  width: 380px;\n}\n", ""]);
// Exports
exports.locals = {
	"margins": "AllModelsView-module__margins-1qu92",
	"width": "AllModelsView-module__width-15XOb",
	"headerContainer": "AllModelsView-module__headerContainer-3Dvkf",
	"noticeText": "AllModelsView-module__noticeText-h__Nt"
};
module.exports = exports;
