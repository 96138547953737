/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";

import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import {
  speciesColumn,
  genusAndSpeciesColumn,
  targetOrganismGroupColumn
} from "../../../../src-shared/utils/libraryColumns";
import { handleStrainsExport } from "./utils";
import StrainRecordView from "../../Record/StrainRecordView";

const strainTypeCode = "MICROBIAL_STRAIN";

const schema = {
  model: "strain",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    speciesColumn,
    genusAndSpeciesColumn,
    { path: "genotype", type: "string", displayName: "Genotype" },
    {
      path: "biosafetyLevel.name",
      type: "string",
      displayName: "BioSafety Level"
    },
    targetOrganismGroupColumn,
    {
      displayName: "Induction Methods",
      render: (v, r) =>
        r.inductionMethodStrains.map(sim => sim.inductionMethod.name).join(", ")
    }
  ]
};

class StrainLibrary extends Component {
  onNewItemClick = ({ history }) => {
    showDialog({
      modalType: "CREATE_NEW_STRAIN",
      modalProps: {
        history,
        strainTypeCode
      }
    });
  };

  onDoubleClick = record => {
    this.props.history.push(`/microbial-strains/${record.id}`);
  };

  showUploadFunction = () => {
    const { refetchStrains } = this.props;
    showDialog({
      modalType: "UPLOAD_STRAIN",
      modalProps: {
        refetch: refetchStrains
      }
    });
  };

  additionalContextMenu = selectedRecords => {
    const strainIds = selectedRecords.map(s => s.id);
    const exportStrainMenuItem = (
      <MenuItem
        key="export"
        icon="export"
        text="Export"
        onClick={() => handleStrainsExport(strainIds)}
      />
    );
    const menuItems = [];
    menuItems.push(exportStrainMenuItem);
    return menuItems;
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        RecordViewInspector={StrainRecordView}
        onDoubleClick={this.onDoubleClick}
        showUploadFunction={this.showUploadFunction}
        isLibraryTable
        libraryTitle="Microbial Strains"
        withExport
        onNewItemClick={this.onNewItemClick}
        generateExtraContextMenuItems={this.additionalContextMenu}
      />
    );
  }
}

const fragment = gql`
  fragment strainLibraryFragment on strain {
    id
    name
    strainTypeCode
    specie {
      id
      abbreviatedName
      name
      genus {
        id
        name
      }
    }
    genotype
    biosafetyLevel {
      code
      name
    }
    inductionMethodStrains {
      id
      inductionMethod {
        id
        name
      }
    }
    targetOrganismClass {
      id
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment,
  additionalFilter: (props, qb) => {
    qb.andWhereAll({
      strainTypeCode
    });
  }
})(StrainLibrary);
