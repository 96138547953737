/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  getReferencedValue,
  getElementsInBin,
  getSequenceStringOfElement
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { flatMap, uniq } from "lodash";
import doesSequenceStringPassRule from "../../../tg-iso-design/utils/doesSequenceStringPassRule";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

export default tgCreateCachedSelector(
  state => state,
  (state, binId) => binId,
  (state, binId) => {
    const binRuleSets = getReferencedValue(state, "bin", binId, "binRuleSets");

    const elements = getElementsInBin(state, binId);

    return binRuleSets.reduce((acc, { ruleSetId }) => {
      const rules = getReferencedValue(state, "ruleSet", ruleSetId, "rules");
      let rsHasViolations = false;
      const rsViolations = rules.reduce((acc2, rule) => {
        const messages = flatMap(elements, element =>
          doesSequenceStringPassRule({
            state,
            ruleId: rule.id,
            elementsContained: [element],
            seqStr: getSequenceStringOfElement(state, element.id),
            elementsName: element.name
          })
        ).filter(x => x);

        if (messages && messages.length) {
          acc2[rule.id] = uniq(messages);
          rsHasViolations = true;
        }
        return acc2;
      }, {});

      if (rsHasViolations) acc[ruleSetId] = rsViolations;
      return acc;
    }, {});
  }
)((state, reactionId) => reactionId);
