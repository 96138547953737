/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";
import defaultJ5OutputNamingTemplateMap from "../../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";

export default () => {
  const designCid = shortid();
  const card1Cid = shortid();
  const card2Cid = shortid();
  const bbCid = shortid();
  const insertCid = shortid();
  const reactionCid = shortid();

  const refDesign = "&" + designCid;
  return [
    {
      entity: "design",
      inputs: {
        cid: designCid,
        type: "grand-design",
        layoutType: "combinatorial",
        numRows: 1
      }
    },
    {
      entity: "card",
      inputs: {
        designId: refDesign,
        isRoot: true,
        name: "Target Construct",
        circular: true,
        binCards: [
          {
            designId: refDesign,
            index: 0,
            bin: {
              designId: refDesign,
              cid: bbCid,
              direction: true,
              name: "Backbone",
              iconId: "&ORIGIN_OF_REPLICATION"
            }
          },
          {
            designId: refDesign,
            index: 1,
            bin: {
              designId: refDesign,
              cid: insertCid,
              direction: true,
              name: "Insert 1",
              iconId: "&USER-DEFINED"
            }
          }
        ],
        outputReaction: {
          designId: refDesign,
          cid: reactionCid,
          assemblyMethodId: "&mock",
          name: "Mock Assembly",
          customJ5Parameter: {
            name: "Default",
            isLocalToThisDesignId: refDesign,
            ...getDefaultParamsAsCustomJ5ParamName()
          },
          reactionJ5OutputNamingTemplates: Object.keys(
            defaultJ5OutputNamingTemplateMap
          ).map(outputTarget => ({
            designId: refDesign,
            j5OutputNamingTemplate: {
              designId: refDesign,
              outputTarget,
              ...defaultJ5OutputNamingTemplateMap[outputTarget]
            }
          })),
          cards: [
            {
              designId: refDesign,
              inputIndex: 0,
              circular: true,
              cid: card1Cid,
              name: "Backbone"
            },
            {
              designId: refDesign,
              inputIndex: 1,
              circular: false,
              cid: card2Cid,
              name: "Insert 1"
            }
          ]
        }
      }
    },
    {
      entity: "binCard",
      inputs: [
        {
          designId: refDesign,
          index: 0,
          binId: "&" + bbCid,
          cardId: "&" + card1Cid
        },
        {
          designId: refDesign,
          index: 0,
          binId: "&" + insertCid,
          cardId: "&" + card2Cid
        }
      ]
    },
    {
      entity: "junction",
      inputs: [
        {
          designId: refDesign,
          junctionTypeCode: "SCARLESS",
          isPhantom: false,
          reactionId: "&" + reactionCid,
          fivePrimeCardId: "&" + card1Cid,
          fivePrimeCardEndBinId: "&" + bbCid,
          fivePrimeCardInteriorBinId: "&" + bbCid,
          threePrimeCardId: "&" + card2Cid,
          threePrimeCardStartBinId: "&" + insertCid,
          threePrimeCardInteriorBinId: "&" + insertCid
        },
        {
          designId: refDesign,
          junctionTypeCode: "SCARLESS",
          isPhantom: false,
          reactionId: "&" + reactionCid,
          threePrimeCardId: "&" + card1Cid,
          threePrimeCardStartBinId: "&" + bbCid,
          threePrimeCardInteriorBinId: "&" + bbCid,
          fivePrimeCardId: "&" + card2Cid,
          fivePrimeCardEndBinId: "&" + insertCid,
          fivePrimeCardInteriorBinId: "&" + insertCid
        }
      ]
    }
  ];
};
