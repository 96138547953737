/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Callout } from "@blueprintjs/core";
import React, { useState } from "react";
import { compose } from "recompose";
import { DataTable, Loading, withSelectTableRecords } from "@teselagen/ui";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { safeQuery } from "../../../../../src-shared/apolloMethods";

function InventoryListSection({
  inventoryLists,
  modelNameToFragment,
  change,
  samplesFromInventoryList = [],
  selectTableRecords
}) {
  const [loadingList, setLoadingList] = useState(false);
  async function loadSamplesFromInventoryList(dataTables) {
    setLoadingList(true);
    try {
      const dataRows = await safeQuery(["dataRow", "id rowValues"], {
        variables: {
          filter: {
            dataTableId: dataTables.map(d => d.id)
          }
        }
      });
      const sampleIds = [];
      const rowValuesKeyed = {};
      dataRows.forEach(row => {
        const sampleId = row.rowValues?.sampleId;
        if (sampleId) {
          sampleIds.push(sampleId);
          rowValuesKeyed[sampleId] = row.rowValues;
        }
      });
      if (sampleIds.length) {
        const samples = await safeQuery(modelNameToFragment.sample, {
          variables: {
            filter: {
              id: sampleIds
            }
          }
        });
        const fullSamples = samples.map(s => {
          return {
            ...s.material, // this will get sequence info from material which allows size sorting
            ...s,
            ...rowValuesKeyed[s.id]
          };
        });
        change("samplesFromInventoryList", fullSamples);
        selectTableRecords(fullSamples);
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading samples from list.");
    }
    setLoadingList(false);
  }

  let sampleContent;
  if (!loadingList) {
    if (!samplesFromInventoryList.length) {
      sampleContent = (
        <Callout intent="warning">No samples found on inventory list</Callout>
      );
    } else {
      sampleContent = (
        <DataTable
          formName="createPlateMapSampleInventoryListTable"
          entities={samplesFromInventoryList}
          schema={[
            "name",
            {
              path: "sampleType.name",
              displayName: "Type"
            },
            {
              displayName: "Plate Name",
              path: "plateName"
            },
            {
              displayName: "Plate Barcode",
              path: "plateBarcode"
            },
            {
              displayName: "Position",
              path: "well"
            }
          ]}
          isSimple
          withCheckboxes
        />
      );
    }
  }
  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        width="100%"
        header="Select Inventory List"
        helper="Select list of samples outputted by the Sample Quality Control tool"
      />
      <GenericSelect
        {...{
          name: "inventoryLists",
          schema: ["name", dateModifiedColumn],
          isMultiSelect: true,
          fragment: ["dataTable", "id name updatedAt"],
          // additionalDataFragment: modelNameToAdditionalFragment[itemType],
          tableParamOptions: {
            additionalFilter: () => ({
              dataTableTypeCode: [
                "VALID_SAMPLE_QC_INVENTORY_LIST",
                "INVALID_SAMPLE_QC_INVENTORY_LIST"
              ]
            })
          },
          onSelect: loadSamplesFromInventoryList,
          nameOverride: "Inventory List"
        }}
      />
      {inventoryLists && (
        <React.Fragment>
          <h6>{inventoryLists.map(l => l.name).join(", ")}</h6>
          {loadingList && <Loading inDialog />}
          {sampleContent}
        </React.Fragment>
      )}
    </div>
  );
}

export default compose(
  stepFormValues("inventoryLists", "samplesFromInventoryList"),
  withSelectTableRecords("createPlateMapSampleInventoryListTable")
)(InventoryListSection);
