/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { wrapDialog } from "@teselagen/ui";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { InputField, DialogFooter } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { withProps } from "recompose";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import actions from "../../../../src-shared/redux/actions";

class GenericRenameDialog extends React.Component {
  onSubmit = async values => {
    const { record, hideModal, dataType, updateDesign } = this.props;
    const splitRecordId = record.id.split("_");
    const recordId = splitRecordId[splitRecordId.length - 1];
    let sequenceId, newName;

    try {
      newName = values.name;

      if (dataType === "design") {
        updateDesign({ name: newName });
      } else if (dataType) {
        /*
          safeUpsert takes 2 arguments. First argument is either a string or array
          string - which DB table we're querying
          array -
          index 0 is table name in DB to query,
          index 1 is variables we want back from the query
          useful to do this way if we don't want to refetch,
          we will get it back from query and update automatically
          then an object as the second argument for what is to be replaced/updated
        */
        await safeUpsert(dataType, {
          id: record.id,
          name: newName
        });
      } else {
        if (record.oligo) {
          sequenceId = record.oligo.sequence.id;
          await safeUpsert(["j5Oligo", "id name"], {
            id: record.oligo.id,
            name: newName
          });
        } else if (record.sequence) {
          sequenceId = record.sequence.id;
        }

        await safeUpsert("sequence", {
          id: sequenceId,
          name: newName,
          sequenceTypeCode: "LINEAR_DNA"
        });
        // top level filter on sequences requires us to nest our query to update fields
        let frag = "id name sequence { id name }";
        if (record.oligo) {
          frag = "id name oligo { id sequence { id name } }";
        }
        await safeUpsert([record.__typename, frag], {
          id: recordId,
          name: newName
        });
      }

      hideModal();
      window.toastr.success(`Changed Name To ${newName}`);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, onSubmit } = this.props;

    let submitHandler = this.onSubmit;

    if (onSubmit) {
      submitHandler = (...args) => {
        onSubmit(...args);
        hideModal();
      };
    }

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Enter a new name:" />
        </div>
        <DialogFooter
          hideModal={hideModal}
          text="OK"
          submitting={submitting}
          onClick={handleSubmit(submitHandler)}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateDesign: actions.design.updateDesign
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is Required";
  }
  return errors;
};

export default compose(
  wrapDialog({ title: "Rename" }),
  withProps(props => ({
    initialValues: {
      name: props.record.name
    }
  })),
  connect(null, mapDispatchToProps),
  reduxForm({ form: "genericRenameDialog", validate })
)(GenericRenameDialog);
