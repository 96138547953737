/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isBrowser } from "browser-or-node";
import { get } from "lodash";
import { bottle } from "../../../../tg-iso-lims/src/dependencyContainer";

export const isDesignLocked = state => {
  const designState = Object.values(get(state, "design.design", {}))[0] || {};

  let commonLabLocked = false;
  if (isBrowser) {
    commonLabLocked = bottle.container.isCommonLabLocked(designState);
  }
  let isManuallyLocked = false;
  if (isBrowser) {
    isManuallyLocked = designState.lockId;
  }
  return !!designState.isLocked || commonLabLocked || isManuallyLocked;
};
