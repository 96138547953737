/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import uploadStrainHelper from "../../tg-iso-shared/src/utils/uploadStrainHelper";
import { parseCsvAndSequenceFiles } from "../../tg-iso-shared/src/sequence-import-utils/utils";
import { cleanCommaSeparatedCell } from "../../tg-iso-shared/src/utils/fileUtils";
import { getBoundExtendedPropertyUploadHelpers } from "../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";

export default async function handleStrainImport(
  { strainFile, isCellLine, tags },
  ctx = isoContext
) {
  // const file = values.strainFile[0];
  const { parsedCsvs, allParsedSequences, allParsedSequencesFilenames } =
    await parseCsvAndSequenceFiles(strainFile, {
      allowMultipleCsvFiles: true
    });
  const strains = [];

  const findMatchingSequences = ({
    row,
    rowIndex,
    header,
    sequenceTypeCode
  }) => {
    const sequences = [];
    if (!row[header]) {
      return [];
    }
    const sequenceFileNames = cleanCommaSeparatedCell(row[header]);
    sequenceFileNames.forEach(filename => {
      const matchedSequenceFileIndices = [];
      allParsedSequencesFilenames.forEach((name, i) => {
        if (name.split(".")[0] === filename.split(".")[0]) {
          matchedSequenceFileIndices.push(i);
        }
      });
      if (!matchedSequenceFileIndices.length) {
        throw new Error(
          `Row ${
            rowIndex + 1
          } specifies the ${header} ${filename} which was not found.`
        );
      } else {
        matchedSequenceFileIndices.forEach(fileIndex => {
          const parsedSequence = allParsedSequences[fileIndex];
          if (sequenceTypeCode) {
            parsedSequence.sequenceTypeCode = sequenceTypeCode;
          }
          sequences.push(parsedSequence);
        });
      }
    });
    return sequences;
  };
  for (const { data, meta, originalFile } of parsedCsvs) {
    const { getCsvRowExtProps, createUploadProperties } =
      await getBoundExtendedPropertyUploadHelpers(meta.fields, ctx);

    for (const [index, row] of data.entries()) {
      const {
        NAME,
        ALIAS = "",
        BIOSAFETY_LEVEL,
        GENUS = "",
        SPECIES = "",
        GENOME_NAME = "",
        GENOTYPE,
        DESCRIPTION,
        SELECTION_METHOD = "",
        INDUCTION_METHOD = "",
        TARGET_ORGANISM_GROUP = "",
        GROWTH_CONDITION_NAME,
        GROWTH_CONDITION_DESCRIPTION,
        GROWTH_MEDIUM = "",
        TEMPERATURE,
        HUMIDITY,
        GAS_COMPOSITION,
        SHAKER_SPEED,
        SHAKER_THROW,
        LENGTH_UNIT_CODE,
        PLASMID_NAME = ""
      } = row;

      const plasmids = findMatchingSequences({
        row,
        rowIndex: index,
        header: "GB_FILE"
      });

      const strainCid = shortid();
      getCsvRowExtProps({
        row,
        modelTypeCode: "STRAIN",
        recordId: `&${strainCid}`
      });

      const newStrain = {
        cid: strainCid,
        name: NAME,
        description: DESCRIPTION,
        aliases: cleanCommaSeparatedCell(ALIAS),
        biosafetyLevel: BIOSAFETY_LEVEL,
        genus: GENUS,
        species: SPECIES,
        genotype: GENOTYPE,
        genomeName: GENOME_NAME,
        targetOrganismGroup: TARGET_ORGANISM_GROUP,
        selectionMethods: cleanCommaSeparatedCell(SELECTION_METHOD),
        inductionMethods: cleanCommaSeparatedCell(INDUCTION_METHOD),
        growthMedium: GROWTH_MEDIUM,
        growthConditionName: GROWTH_CONDITION_NAME,
        growthConditionDescription: GROWTH_CONDITION_DESCRIPTION,
        gasComposition: GAS_COMPOSITION,
        temperature: TEMPERATURE,
        humidity: HUMIDITY,
        shakerSpeed: SHAKER_SPEED,
        shakerThrow: SHAKER_THROW,
        lengthUnit: LENGTH_UNIT_CODE,
        plasmidNames: isCellLine ? [] : cleanCommaSeparatedCell(PLASMID_NAME),
        plasmids: isCellLine ? [] : plasmids
      };
      strains.push(newStrain);
    }

    const newStrains = await uploadStrainHelper(
      strains,
      {
        isCellLine,
        tags,
        importCollectionName:
          originalFile.name ||
          (isCellLine ? "Cell Line Upload" : "Strain Upload")
      },
      ctx
    );

    await createUploadProperties();
    return newStrains;
  }
}
