/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Icon } from "@blueprintjs/core";
import { Loading } from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import { parse } from "qs";
import CommentBubble from "../CommentBubble";
import commentReplyFragment from "../commentReplyFragment";
import { safeQuery } from "../../apolloMethods";
import "./style.css";

class CommentWithReplies extends Component {
  state = {
    showingMore: true,
    replyCount: 0,
    replies: []
  };

  startReply = () => {
    this.props.startReply(this.props.comment.id);
  };

  toggleShowMore = () =>
    this.setState({ showingMore: !this.state.showingMore });

  countReplies = async () => {
    const { comment } = this.props;
    try {
      const res = await safeQuery(["commentReply", "id"], {
        variables: {
          filter: {
            commentId: comment.id
          },
          pageSize: 1
        }
      });
      this.setState({
        replyCount: res.totalResults
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  loadReplies = async () => {
    const { comment } = this.props;
    try {
      this.setState({
        loadingReplies: true
      });
      const res = await safeQuery(commentReplyFragment, {
        variables: {
          sort: ["createdAt"],
          filter: {
            commentId: comment.id
          },
          pageSize: this.state.replyCount
        }
      });
      this.setState({
        replies: res.map(commentReply => commentReply.reply),
        replyCount: res.totalResults
      });
    } catch (error) {
      console.error("error:", error);
    }
    this.setState({
      loadingReplies: false
    });
  };

  afterReply = reply => {
    const { replies } = this.state;
    this.countReplies();
    this.setState({
      replies: replies.concat(reply)
    });
  };

  afterReplyDelete = replyId => {
    this.setState({
      replies: this.state.replies.filter(reply => reply.id !== replyId)
    });
  };

  afterReplyEdit = editedReply => {
    const { replies } = this.state;
    const indexToReplace = replies.findIndex(r => r.id === editedReply.id);
    const newReplies = [...replies];
    newReplies[indexToReplace] = editedReply;
    this.setState({
      replies: newReplies
    });
  };

  componentDidMount() {
    this.countReplies();
    const { location, comment = {} } = this.props;
    const { parentCommentId } = parse(location.hash.replace(/^#/, ""));

    if (parentCommentId === comment.id) {
      this.loadReplies();
    }
  }

  render() {
    const {
      replyCount,
      showingMore,
      loadingReplies,
      replies = []
    } = this.state;
    const {
      comment,
      currentUser,
      startReply,
      cancelReply,
      isReplying,
      afterEdit,
      afterDelete,
      withMentions = true,
      disableDelete,
      handleMentionsSubmit,
      toggleEdit,
      editingCommentId
    } = this.props;

    const sharedPropsWithReplies = {
      currentUser,
      cancelReply,
      afterReply: this.afterReply,
      handleMentionsSubmit,
      toggleEdit,
      editingCommentId
    };

    return (
      <div key={comment.id}>
        <CommentBubble
          disableDelete={disableDelete}
          afterEdit={afterEdit}
          afterDelete={afterDelete}
          withMentions={withMentions}
          userIdToReplyTo={comment.user.id}
          isReplying={comment.id === isReplying}
          startReply={this.startReply}
          comment={comment}
          {...sharedPropsWithReplies}
        />
        {loadingReplies && <Loading bounce withTimeout />}
        {!!replyCount && !replies.length && (
          <div className="show-more-comments-btn" onClick={this.loadReplies}>
            Show Replies
          </div>
        )}
        {!!replies.length && (
          <div className="show-more-comments-btn" onClick={this.toggleShowMore}>
            {showingMore ? "Hide Replies " : "Show Replies "}
            <Icon icon={`chevron-${showingMore ? "up" : "down"}`} />
          </div>
        )}
        {showingMore &&
          replies.map(reply => (
            <CommentBubble
              key={reply.id}
              isReply
              disableDelete={disableDelete}
              afterEdit={this.afterReplyEdit}
              withMentions={withMentions}
              afterDelete={() => this.afterReplyDelete(reply.id)}
              parentId={comment.id}
              userIdToReplyTo={reply.user.id}
              startReply={() => startReply(reply.id)}
              isReplying={reply.id === isReplying}
              comment={reply}
              {...sharedPropsWithReplies}
            />
          ))}
      </div>
    );
  }
}

export default withRouter(CommentWithReplies);
