/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import { Classes } from "@blueprintjs/core";
import {
  DataTable,
  DialogFooter,
  withTableParams,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { uniq, difference, keyBy } from "lodash";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { annotationSizeStartEndColumns } from "../../../../src-shared/utils/libraryColumns";

class CreatePartsFromFeaturesDialog extends React.Component {
  cellRenderer = {
    strand: strand => (strand < 0 ? "-" : "+")
  };

  onSubmit = async () => {
    const { selectedEntities, hideModal, onRefresh } = this.props;
    // Check for duplicated parts.
    const seqIds = uniq(selectedEntities.map(e => e.sequence.id));
    const parts = await safeQuery(
      ["part", "id name start end strand sequenceId"],
      {
        isPlural: true,
        variables: {
          filter: {
            sequenceId: seqIds
          },
          pageSize: 1000000
        }
      }
    );

    const duplicatedPartsOnFeatures = selectedEntities.filter(f =>
      parts.some(
        p =>
          f.sequence.id === p.sequenceId &&
          f.name === p.name &&
          f.start === p.start &&
          f.end === p.end &&
          f.strand === p.strand
      )
    );

    const nonDuplicatedPartsOnFeatures = difference(
      selectedEntities,
      duplicatedPartsOnFeatures
    );

    const featureToPart = (f, tagIds) => {
      return {
        start: f.start,
        end: f.end,
        name: f.name,
        type: f.type,
        strand: f.strand,
        sequenceId: f.sequence.id,
        taggedItems: tagIds.map(id => ({ tagId: id }))
      };
    };

    if (nonDuplicatedPartsOnFeatures.length) {
      await showDialog({
        modalType: "APPLY_TAGS",
        modalProps: {
          onSubmit: async ({ tagIds = [] }) => {
            if (nonDuplicatedPartsOnFeatures.length) {
              await safeUpsert(
                "part",
                nonDuplicatedPartsOnFeatures.map(x => featureToPart(x, tagIds))
              );

              window.toastr.success(
                `${nonDuplicatedPartsOnFeatures.length} part${sIfPlural(
                  nonDuplicatedPartsOnFeatures
                )} created.`
              );
            }

            await onRefresh();
            await hideModal();

            if (duplicatedPartsOnFeatures.length) {
              showDialog({
                modalType: "DUPLICATE_PARTS_FOUND",
                modalProps: {
                  tagIds,
                  valuesToUpsert: duplicatedPartsOnFeatures.map(x =>
                    featureToPart(x, tagIds)
                  ),
                  idToSequence: keyBy(
                    duplicatedPartsOnFeatures.map(f => f.sequence),
                    "id"
                  ),
                  refetch: onRefresh
                }
              });
            }
          }
        }
      });
    } else if (duplicatedPartsOnFeatures.length) {
      await showDialog({
        modalType: "APPLY_TAGS",
        modalProps: {
          onSubmit: async ({ tagIds = [] }) => {
            if (duplicatedPartsOnFeatures.length) {
              showDialog({
                modalType: "DUPLICATE_PARTS_FOUND",
                modalProps: {
                  tagIds,
                  valuesToUpsert: duplicatedPartsOnFeatures.map(x =>
                    featureToPart(x, tagIds)
                  ),
                  idToSequence: keyBy(
                    duplicatedPartsOnFeatures.map(f => f.sequence),
                    "id"
                  ),
                  refetch: onRefresh
                }
              });
            }
          }
        }
      });
    }
  };

  render() {
    const {
      tableParams,
      hideModal,
      loading,
      selectedEntities,
      submitting
    } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          Choose which features you would like to use to create parts.
          <DataTable
            {...tableParams}
            withCheckboxes
            compact
            maxHeight={500}
            doNotShowEmptyRows
            isLoading={loading}
            cellRenderer={this.cellRenderer}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          text="Create Parts"
          onClick={this.onSubmit}
          disabled={!selectedEntities.length}
          loading={submitting}
        />
      </React.Fragment>
    );
  }
}

const selector = formValueSelector("createPartsFromFeaturesDialog");

const mapStateToProps = state => ({
  selectedEntities: Object.values(
    selector(state, "reduxFormSelectedEntityIdMap") || {}
  )
    .filter(v => !!v)
    .map(v => v.entity)
});

export default compose(
  wrapDialog({
    title: "Create Parts from Features"
  }),
  withTableParams({
    schema: {
      model: "sequenceFeature",
      fields: [
        {
          path: "id",
          type: "string",
          isHidden: true,
          searchDisabled: true
        },
        { path: "name", type: "string", displayName: "Name" },
        { path: "type", type: "string", displayName: "Type" },
        ...annotationSizeStartEndColumns,
        {
          path: "strand",
          type: "number",
          displayName: "Strand",
          searchDisabled: true
        },
        {
          path: "sequence.name",
          type: "string",
          displayName: "Sequence of Origin"
        }
      ]
    },
    additionalFilter: (props, qb) => {
      qb.whereAll({ "sequence.id": props.sequenceIds });
    },
    formName: "createPartsFromFeaturesDialog"
  }),
  withQuery(
    [
      "sequenceFeature",
      "id name type start end strand sequence { id name size }"
    ],
    {
      isPlural: true
    }
  ),
  connect(mapStateToProps)
)(CreatePartsFromFeaturesDialog);
