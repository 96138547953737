/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import unitGlobals from "../../unitGlobals";
import { standardizeVolume } from "./standardizeUnits";
import getUnit from "./getUnit";

export const defaultVolumetricUnitCode = "uL";

/**
 * Given a volumetric unit code, return what fraction of a liter one unit represents.
 * @param {string} volumetricUnitCode
 * @return {number}
 */
export function volumetricUnitCodeToLiters(volumetricUnitCode) {
  const unit =
    unitGlobals.volumetricUnits &&
    getUnit(unitGlobals.volumetricUnits, volumetricUnitCode);
  const liters = unit && unit.liters;
  if (!liters)
    throw new Error(`Invalid volumetric unit code: ${volumetricUnitCode}.`);
  return new Big(liters);
}

/**
 * Convert a `volume` given in `oldUnits` to `newUnits`.
 * @param {number} volume The volume we wish to convert
 * @param {string} oldUnits The volumetric unit code that `volume` is provided as.
 * @param {string} newUnits The volumetric unit code that we wish to see `volume` as.
 * @returns {number}
 */
export function convertVolumeBig(volume, oldUnits, newUnits) {
  return new Big(volume)
    .times(volumetricUnitCodeToLiters(oldUnits))
    .div(volumetricUnitCodeToLiters(newUnits));
}

/**
 * Get the total volume represented by an array of volume information objects.
 * @param {Array<Object>} volumesInfo Array of objects with keys `volume` and `volumetricUnitCode`.
 * @param {string} units The desired units of the return value.
 * @returns {number}
 */
export function sumVolumes(volumesInfo, units) {
  let totalVolume = new Big(0);
  for (const { volume, volumetricUnitCode } of volumesInfo) {
    totalVolume = totalVolume.add(
      convertVolumeBig(volume || 0, volumetricUnitCode || "uL", units)
    );
  }
  return totalVolume;
}

// Fix number to 12 for picoliter to avoid bad calculations with repeating numbers
export function toFixedPico(bigInt) {
  return bigInt.toFixed(12);
}

/**
 * checks whether transfers will fit into an aliquot container.
 * transfers must include current aliquot volume
 * @param {*} param0
 */
export function doTransfersFit({
  maxVolume,
  maxVolumetricUnitCode,
  transfers
}) {
  const totalVolume = transfers.reduce((acc, info) => {
    const vol = standardizeVolume(info.volume, info.volumetricUnitCode, true);
    return acc.add(vol);
  }, new Big(0));
  const stdMaxVolume = standardizeVolume(
    maxVolume || 0,
    maxVolumetricUnitCode || "uL",
    true
  );
  return totalVolume.lte(stdMaxVolume);
}
