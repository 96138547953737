/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get, times, flatten, isEmpty } from "lodash";
import { showConfirmationDialog } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { change, formValueSelector, reset, SubmissionError } from "redux-form";
import Big from "big.js";
import StepForm from "../../../../src-shared/StepForm";
import validate, { maxStandardizedDesiredConcentration } from "./validate";
import {
  SelectPlates,
  NormalizationParameters,
  DiluentContainer,
  ReviewWorklists
} from "./Steps";
import {
  getDiluentTransferVolumes,
  calculateMinimumNumberOfContainerArrays,
  calculateVolumePerDiluentAliquotContainer,
  getSourceVolumetricUnit,
  getAliquotContainersToNormalize
} from "./utils";
import {
  generateEmptyWells,
  getUploadAliquotContainers,
  sortAliquotContainers
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";

import "./style.css";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import { compose } from "recompose";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import containerArrayNormalizationFragment from "../../../graphql/fragments/containerArrayNormalizationFragment";
import { connect } from "react-redux";

class NormalizationTool extends React.Component {
  doCalculations = overrideNumContainers => {
    const { formValues, changeFieldValue } = this.props;

    const { diluentTransferVolumes, transferMap } =
      getDiluentTransferVolumes({
        ...formValues
      }) || {};
    if (!diluentTransferVolumes) return;
    const containerFormat = get(
      formValues,
      "intermediateContainerType.containerFormat"
    );
    const quadrantSize = get(
      formValues,
      "intermediateContainerType.containerFormat.quadrantSize"
    );
    const diluentAliquotContainerType = get(
      formValues,
      "intermediateContainerType.aliquotContainerType"
    );
    const {
      uniformDiluentAliquotContainerVolume: originalUniformVolume,
      userSpecifiedNumDiluentContainers,
      calculatedNumDiluentContainers
    } = calculateMinimumNumberOfContainerArrays({
      ...formValues,
      quadrantSize,
      overrideNumContainers,
      diluentTransferVolumes
    });
    const filteredAndSortedDiluentTransferVolumes = [...diluentTransferVolumes]
      .filter(v => !!v && !new Big(v).eq(0))
      .sort((a, b) => new Big(a).minus(new Big(b)));
    const {
      diluentAliquotContainers,
      uniformDiluentAliquotContainerVolume
    } = calculateVolumePerDiluentAliquotContainer({
      uniformDiluentAliquotContainerVolume: originalUniformVolume,
      numDiluentAliquotContainers:
        userSpecifiedNumDiluentContainers || calculatedNumDiluentContainers,
      diluentAliquotContainerType,
      transferMap,
      containerFormat,
      diluentTransferVolumes: filteredAndSortedDiluentTransferVolumes,
      intermediateContainerName: get(formValues, "intermediateContainerName")
    });

    changeFieldValue("diluentAliquotContainers", diluentAliquotContainers);
    changeFieldValue(
      "uniformDiluentAliquotContainerVolume",
      uniformDiluentAliquotContainerVolume
    );
    if (!userSpecifiedNumDiluentContainers) {
      changeFieldValue(
        "numDiluentAliquotContainers",
        calculatedNumDiluentContainers
      );
    }
    changeFieldValue("transferMap", transferMap);
  };

  onSubmit = async values => {
    const {
      containerArrays,
      containerArrayToNormalize: sourceContainerArray,
      transferSourcePlateToNormalizedPlate,
      destinationContainerArrayName,
      destinationContainerArrayBarcode,
      containerArrayTransferWorklistName,
      normalizationWorklistName,
      desiredTransferVolume,
      desiredTransferVolumetricUnitCode,
      intermediateContainerName,
      intermediateContainerType,
      numberOfDiluentContainers,
      diluentAliquotContainers,
      uniformDiluentAliquotContainerVolume,
      generateBarcodes,
      containerArrayType: destinationContainerArrayType,
      aliquotContainerType: destinationAliquotContainerType,
      generateTubeBarcodes,
      shouldFillRack,
      numTubesToFillRack,
      worklistMap = {},
      sourceTransferOrder,
      destinationTransferOrder
    } = values;

    const { changeFieldValue, resetForm } = this.props;
    const acsToUse = getAliquotContainersToNormalize(values);
    let aliquotContainers;
    let sortedSourceAcs = acsToUse;
    const isDifferentFormat =
      destinationContainerArrayType &&
      destinationContainerArrayType.containerFormat.code !==
        sourceContainerArray.containerArrayType.containerFormat.code;
    if (isDifferentFormat) {
      sortedSourceAcs = sortAliquotContainers(acsToUse, sourceTransferOrder);
    }
    if (transferSourcePlateToNormalizedPlate) {
      const newAliquotContainers = [];
      const emptyWells = generateEmptyWells(
        destinationContainerArrayType.containerFormat
      );
      const sortedDestWells = sortAliquotContainers(
        emptyWells,
        destinationTransferOrder
      );
      sortedSourceAcs.forEach(ac => {
        if (ac.aliquot) {
          const destWell = sortedDestWells.shift();
          // if they are not a different format we just want the same positions as the source
          // if they are different then use the sorted well positions
          const positionEnt = isDifferentFormat ? destWell : ac;
          const newAc = {
            rowPosition: positionEnt.rowPosition,
            columnPosition: positionEnt.columnPosition,
            aliquotContainerTypeCode: destinationContainerArrayType.isPlate
              ? destinationContainerArrayType.aliquotContainerType.code
              : destinationAliquotContainerType.code
          };
          newAliquotContainers.push(newAc);
        }
      });
      aliquotContainers = getUploadAliquotContainers({
        newAliquotContainers,
        containerArrayType: destinationContainerArrayType,
        aliquotContainerType: destinationAliquotContainerType,
        shouldFillRack,
        numTubesToFillRack
      });
    }
    const sourcePlateToNormalizedPlate = {};
    const createdWorklists = [];
    try {
      let destinationContainerArray;
      if (transferSourcePlateToNormalizedPlate) {
        const destinationContainerArrays = await safeUpsert(
          [
            "containerArray",
            `id
          aliquotContainers {
            id
            rowPosition
            columnPosition
            aliquotContainerType {
              code
              volumetricUnitCode
            }
          }`
          ],
          {
            containerArrayTypeId: destinationContainerArrayType.id,
            name: destinationContainerArrayName,
            barcode: destinationContainerArrayBarcode
              ? { barcodeString: destinationContainerArrayBarcode }
              : null,
            aliquotContainers
          }
        );
        if (generateBarcodes) {
          await addBarcodesToRecords(destinationContainerArrays);
        }
        if (generateTubeBarcodes) {
          const createdAliquotContainers = [];
          destinationContainerArrays.forEach(plate => {
            plate.aliquotContainers.forEach(ac =>
              createdAliquotContainers.push(ac)
            );
          });
          await addBarcodesToRecords(createdAliquotContainers);
        }
        destinationContainerArray = destinationContainerArrays[0];
      }
      let transferWorklist;
      if (destinationContainerArray) {
        let sortedDestinationWells;
        if (isDifferentFormat) {
          sortedDestinationWells = sortAliquotContainers(
            [...destinationContainerArray.aliquotContainers],
            destinationTransferOrder
          );
        }

        transferWorklist = await safeUpsert("worklist", {
          name: containerArrayTransferWorklistName,
          worklistTransfers: sortedSourceAcs.reduce((acc, aliquotContainer) => {
            if (!get(aliquotContainer, "aliquot")) return acc;
            let destinationAliquotContainer;
            if (isDifferentFormat) {
              destinationAliquotContainer = sortedDestinationWells.shift();
            } else {
              destinationAliquotContainer = destinationContainerArray.aliquotContainers.find(
                ac => {
                  return (
                    ac.rowPosition === aliquotContainer.rowPosition &&
                    ac.columnPosition === aliquotContainer.columnPosition
                  );
                }
              );
            }
            sourcePlateToNormalizedPlate[
              aliquotContainer.id
            ] = destinationAliquotContainer;
            const transfer = {
              volume: desiredTransferVolume,
              volumetricUnitCode: desiredTransferVolumetricUnitCode,
              sourceAliquotContainerId: aliquotContainer.id,
              destinationAliquotContainerId: destinationAliquotContainer.id
            };
            return acc.concat(transfer);
          }, [])
        });
      }
      if (transferWorklist) {
        createdWorklists.push({
          ...transferWorklist[0],
          name: containerArrayTransferWorklistName
        });
      }
      const diluentContainerUnitCode =
        intermediateContainerType.aliquotContainerType.volumetricUnitCode;
      const diluentContainerUnit =
        unitGlobals.volumetricUnits[diluentContainerUnitCode];
      const diluentVolumeInUnit = new Big(uniformDiluentAliquotContainerVolume)
        .div(new Big(diluentContainerUnit.liters))
        .toString();
      const normalizationAliquotContainers = generateEmptyWells(
        get(intermediateContainerType, "containerFormat"),
        {
          aliquotContainerTypeCode:
            intermediateContainerType.aliquotContainerTypeCode,
          aliquot: {
            aliquotType: "additive",
            volume: diluentVolumeInUnit,
            volumetricUnitCode: diluentContainerUnitCode,
            sample: {
              name: "diluent",
              sampleTypeCode: "ADDITIVE_SAMPLE"
            }
          }
        }
      );
      const diluentContainersToCreate = times(numberOfDiluentContainers, i => {
        return {
          name: intermediateContainerName + ` ${i + 1}`,
          containerArrayTypeId: intermediateContainerType.id,
          displayFilter: "DILUENT",
          aliquotContainers: normalizationAliquotContainers
        };
      });
      const createdDiluentContainers = await safeUpsert(
        [
          "containerArray",
          `id
          aliquotContainers {
            id
          }`
        ],
        diluentContainersToCreate
      );
      const createdAliquotContainers = createdDiluentContainers.reduce(
        (acc, diluentContainer) => {
          return acc.concat(diluentContainer.aliquotContainers);
        },
        []
      );

      const { volumetricUnit, volumetricUnitCode } = getSourceVolumetricUnit(
        sourceContainerArray
      );

      const worklistTransfers = [];
      diluentAliquotContainers.forEach((diluentContainer, i) => {
        diluentContainer.transfers.forEach(transfer => {
          const aliquotContainerId = get(transfer, "aliquotContainer.id");
          const worklistTransfer = {
            volume: new Big(transfer.transferVolume)
              .div(new Big(volumetricUnit.liters))
              .toString(),
            volumetricUnitCode,
            sourceAliquotContainerId: get(
              createdAliquotContainers,
              `[${i}].id`
            ),
            destinationAliquotContainerId: isEmpty(sourcePlateToNormalizedPlate)
              ? aliquotContainerId
              : sourcePlateToNormalizedPlate[aliquotContainerId].id
          };
          worklistTransfers.push(worklistTransfer);
        });
      });

      const normalizationWorklist = await safeUpsert("worklist", {
        name: normalizationWorklistName,
        worklistTransfers
      });
      createdWorklists.push({
        ...normalizationWorklist[0],
        name: normalizationWorklistName
      });
      window.toastr.success("Successfully created worklists.");
      const newWorklistMap = {
        ...worklistMap,
        [sourceContainerArray.id]: createdWorklists.map(({ id }) => ({ id }))
      };
      const incomplete = containerArrays.some(({ id }) => !newWorklistMap[id]);
      const notNormalized = [];
      containerArrays.forEach(containerArray => {
        if (!newWorklistMap[containerArray.id])
          notNormalized.push(containerArray.name);
      });
      const allWorklists = flatten(Object.values(newWorklistMap));
      const toolOutput = {
        worklists: allWorklists
      };
      if (incomplete) {
        const continueNormalization = await showConfirmationDialog({
          text: `There are ${notNormalized.length} plate${
            notNormalized.length === 1 ? "" : "s"
          } (${notNormalized.join(
            ", "
          )}) that have not been normalized. Would you like to continue normalization?`,
          cancelButtonText: "Stop Now",
          confirmButtonText: "Continue Normalization"
        });
        if (continueNormalization) {
          resetForm();
          changeFieldValue("worklistMap", newWorklistMap);
          changeFieldValue("containerArrays", containerArrays);
          return false;
        } else {
          return toolOutput;
        }
      } else {
        return toolOutput;
      }
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({ _error: "Error creating worklists." });
    }
  };

  validate = values => validate({ ...values, ...this.state, ...this.props });

  warn = values => {
    const errorForMolarityOrConcentration = maxStandardizedDesiredConcentration(
      { ...values, ...this.props, forWarning: true }
    );
    const warnings = {};
    if (
      errorForMolarityOrConcentration &&
      errorForMolarityOrConcentration.warning
    ) {
      warnings.desiredConcentration = errorForMolarityOrConcentration.warning;
      warnings.desiredMolarity = errorForMolarityOrConcentration.warning;
      warnings.desiredCellConcentration =
        errorForMolarityOrConcentration.warning;
    }
    return warnings;
  };

  render() {
    const {
      formValues,
      setSelectedAliquotContainerLocation,
      toolIntegrationProps,
      initialValues,
      toolSchema
    } = this.props;
    const steps = [
      {
        title: "Select Inputs",
        Component: SelectPlates,
        props: {
          ...formValues,
          setSelectedAliquotContainerLocation
        },
        withCustomFooter: true
      },
      {
        title: "Normalization Parameters",
        Component: NormalizationParameters,
        props: formValues
      },
      {
        title: "Diluent Container",
        Component: DiluentContainer,
        props: {
          ...formValues,
          minNumContainerArrays: formValues.numDiluentAliquotContainers,
          doCalculations: this.doCalculations
        }
      },
      {
        title: "Review Worklists",
        Component: ReviewWorklists,
        props: {
          ...formValues,
          dilutionWorklistTransfers: formValues.diluentAliquotContainers
        },
        nextButton: (
          <Button type="submit" text="Save Worklists" intent={Intent.SUCCESS} />
        )
      }
    ];

    return (
      <StepForm
        steps={steps}
        onSubmit={this.onSubmit}
        validate={this.validate}
        warn={this.warn}
        enableReinitialize
        toolSchema={toolSchema}
        toolIntegrationProps={toolIntegrationProps}
        initialValues={initialValues}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const normalizationSelector = formValueSelector(ownProps.toolSchema.code);
  return {
    formValues: normalizationSelector(
      state,
      "containerArrayToNormalize",
      "selectedWellsForPlates",
      "destinationContainerArrayName",
      "containerArrayType",
      "destinationContainerArrayBarcode",
      "transferSourcePlateToNormalizedPlate",
      "desiredConcentration",
      "desiredConcentrationUnitCode",
      "desiredMolarity",
      "desiredMolarityUnitCode",
      "desiredCellConcentration",
      "desiredCellConcentrationUnitCode",
      "normalizationType",
      "intermediateContainerType",
      "intermediateContainerName",
      "desiredTransferVolume",
      "uniformDiluentAliquotContainerVolume",
      "desiredTransferVolumetricUnitCode",
      "numberOfDiluentContainers",
      "numDiluentAliquotContainers",
      "diluentAliquotContainers",
      "destinationTransferOrder",
      "sourceTransferOrder",
      "transferMap"
    )
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeFieldValue: (fieldName, value) =>
    dispatch(change(ownProps.toolSchema.code, fieldName, value)),
  resetForm: () => dispatch(reset(ownProps.toolSchema.code))
});

export default compose(
  withWorkflowInputs(containerArrayNormalizationFragment),
  connect(mapStateToProps, mapDispatchToProps)
)(NormalizationTool);
