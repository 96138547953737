/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { partition } from "lodash";
import concatWarningStrs from "../../utils/concatWarningStrs";
import { exampleRecordValidation } from "../utils/integrationTypeSettingsMap/validationFns";

export function cleanExternalRecord(record, subtype, isUpdate, options) {
  return exampleRecordValidation({
    subtype: subtype,
    isUpdateCall: isUpdate,
    forceUpdateTransform: isUpdate,
    ...options
  }).cast(record, {
    strict: true,
    stripUnknown: true
  });
}

/**
 * The external reference properties could come in either spread out or within
 * a { externalReference {...} } field in the records. In case of the latter, we
 * spread them out into the record as expected by internal logic.
 */
export function handleExternalReference(records) {
  records.forEach(record => {
    const { externalReference } = record;
    if (externalReference) {
      const externalReferenceProps = {
        externalReferenceSystem: externalReference.system,
        externalReferenceId: externalReference.id,
        externalReferenceType: externalReference.type,
        externalReferenceUrl: externalReference.url
      };
      Object.assign(record, { ...externalReferenceProps });
    }
  });
}

export function validateRecord(
  record,
  { subtype, isUpdate, doNotRequireExternalFields, allowBpUpdates }
) {
  const errors = [];
  try {
    exampleRecordValidation({
      subtype: subtype,
      isUpdateCall: isUpdate,
      forceUpdateTransform: isUpdate,
      doNotRequireExternalFields,
      allowBpUpdates
    }).validateSync(record, { abortEarly: false });
  } catch (error) {
    console.error(`error:`, error);
    errors.push(...error.errors);
  }
  return errors;
}

export function validateRecords(records, opts) {
  records.forEach(record => {
    const errors = validateRecord(record, opts);
    if (errors.length) {
      Object.assign(record, {
        __importFailed: concatWarningStrs(...errors)
      });
    }
  });
  return partition(records, r => r.__importFailed);
}
