/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment containerFormatFragment on containerFormat {
    code
    name
    rowCount
    columnCount
    quadrantSize
    is2DLabeled
  }
`;
