/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  Rectangle,
  Arrow,
  Divider,
  Terminator,
  Cds,
  Origin,
  AssemblyScar,
  BluntRestrictionSite,
  FivePrimeOverhang,
  FivePrimeUtr,
  FivePrimeRestrictionSite,
  Insulator,
  Operator,
  PrimerBindingSite,
  ProteaseSite,
  ProteinStabilityElement,
  RestrictionEnzymeRecognitionSite,
  RibonucleaseSite,
  RibosomeEntrySite,
  RnaStabilityElement,
  Signature,
  ThreePrimeOverhang,
  ThreePrimeRestrictionSite
} from "./Shapes";

export const TYPE_SHAPE_MAP = {
  rectangle: Rectangle,
  "left-arrow": Arrow,
  "right-arrow": Arrow,
  divider: Divider,
  "left-terminator": Terminator,
  "right-terminator": Terminator,
  "left-cds": Cds,
  "right-cds": Cds,
  origin: Origin,
  assembly_junction: AssemblyScar,
  blunt_restriction_site: BluntRestrictionSite,
  five_prime_overhang: FivePrimeOverhang,
  five_prime_utr: FivePrimeUtr,
  five_prime_restriction_site: FivePrimeRestrictionSite,
  insulator: Insulator,
  operator_site: Operator,
  primer_binding_site: PrimerBindingSite,
  protease_site: ProteaseSite,
  protein_stability_element: ProteinStabilityElement,
  restriction_enzyme_recognition_site: RestrictionEnzymeRecognitionSite,
  ribonuclease_site: RibonucleaseSite,
  ribosome_entry_site: RibosomeEntrySite,
  rna_stability_element: RnaStabilityElement,
  signature: Signature,
  three_prime_overhang: ThreePrimeOverhang,
  three_prime_restriction_site: ThreePrimeRestrictionSite
};
