/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  Colors,
  Classes,
  Tooltip,
  Callout,
  Intent,
  Divider
} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import { EvolveConfig, tooltips } from "../../../../../configs/config.js";
import { parseErrorToJson } from "./utils.js";
import { get } from "lodash";
import LabelWithTooltip from "../../../../../../src-shared/LabelWithTooltip";
import styles from "./ModelPerformanceStatsWidgetStyles.module.css";

const UNKNOWN_ERROR_OBJECT = {
  message:
    "Encountered an unkown error while training the model. Please try and train your model again and if it keeps throwing an error, please contact TeselaGen's team. Sorry for any inconvenience that this could cause."
};

const PRECISION = 1000;

const CVStatsItem = ({ label, value, precision }) => (
  <li className={styles.subStatsListItem}>
    {label}
    <Tooltip className={Classes.TOOLTIP_INDICATOR} content={value}>
      <span style={{ color: Colors.GRAY3 }}>
        {Number.isNaN(Math.round(value * precision) / precision)
          ? "NaN"
          : Math.round(value * precision) / precision}
      </span>
    </Tooltip>
  </li>
);

const MetricItems = ({ metric, precision }) => (
  <ul>
    <li className={styles.subStatsListItem}>
      (μ ± σ)
      <div>
        <Tooltip
          className={Classes.TOOLTIP_INDICATOR}
          content={metric.mean || metric}
        >
          <span style={{ color: Colors.GRAY3 }}>
            {Math.round((metric.mean || metric) * precision) / precision}
          </span>
        </Tooltip>
        {metric.std_dev && " ± "}
        {metric.std_dev && (
          <Tooltip
            className={Classes.TOOLTIP_INDICATOR}
            content={metric.std_dev}
          >
            <span style={{ color: Colors.GRAY3 }}>
              {Math.round(metric.std_dev * precision) / precision}
            </span>
          </Tooltip>
        )}
      </div>
    </li>
    <CVStatsItem label="median" value={metric.median} precision={precision} />
    <CVStatsItem label="IQR" value={metric.iqr} precision={precision} />
  </ul>
);

const CVMetrics = ({ metrics, precision = PRECISION }) => (
  <ul className={styles.metricList}>
    {get(metrics, "test.mae") && (
      <li className={styles.statsListItem}>
        <h6>Test MAE</h6>
        <MetricItems metric={get(metrics, "test.mae")} precision={precision} />
      </li>
    )}
    {get(metrics, "test.r2") && (
      <li className={styles.statsListItem}>
        <h6>
          <span>
            Test R<sup>2</sup> score
          </span>
        </h6>
        <MetricItems metric={get(metrics, "test.r2")} precision={precision} />
      </li>
    )}
    {get(metrics, "test.rmse") && (
      <li className={styles.statsListItem}>
        <h6>Test RMSE</h6>
        <MetricItems metric={get(metrics, "test.rmse")} precision={precision} />
      </li>
    )}
    {get(metrics, "train.mae") && (
      <li className={styles.statsListItem}>
        <h6>Train MAE</h6>
        <MetricItems metric={get(metrics, "train.mae")} precision={precision} />
      </li>
    )}
    {get(metrics, "train.r2") && (
      <li className={styles.statsListItem}>
        <h6>
          <span>
            Train R<sup>2</sup> score
          </span>
        </h6>
        <MetricItems metric={get(metrics, "train.r2")} precision={precision} />
      </li>
    )}
    {get(metrics, "train.rmse") && (
      <li className={styles.statsListItem}>
        <h6>Train RMSE</h6>
        <MetricItems
          metric={get(metrics, "train.rmse")}
          precision={precision}
        />
      </li>
    )}
  </ul>
);

class ModelStatsWidget extends Component {
  renderPredictionAndEvolutionStatisticsPane = statisticsPaneProps => {
    const {
      mainStore: { helper }
    } = this.props;

    const { modelStats } = statisticsPaneProps;

    const trainingResults = modelStats.training_results;
    const cvResults = modelStats.crossvalidation;

    return (
      <div className={styles.summaryMetricsPane}>
        <LabelWithTooltip
          label={<h5>Training</h5>}
          tooltip={tooltips.trainingMetrics}
          onClick={() => helper.open("statisticalMetrics")}
        />
        <ul className={styles.metricList}>
          {trainingResults.mae && (
            <li>
              MAE
              <Tooltip
                className={Classes.TOOLTIP_INDICATOR}
                content={trainingResults.mae}
              >
                <span style={{ color: Colors.GRAY3 }}>
                  {Math.round(trainingResults.mae * PRECISION) / PRECISION}
                </span>
              </Tooltip>
            </li>
          )}
          {trainingResults.r2 && (
            <li>
              <span>
                R<sup>2</sup> score
              </span>
              <Tooltip
                className={Classes.TOOLTIP_INDICATOR}
                content={trainingResults.r2}
              >
                <span style={{ color: Colors.GRAY3 }}>
                  {Math.round(trainingResults.r2 * PRECISION) / PRECISION}
                </span>
              </Tooltip>
            </li>
          )}
        </ul>
        <LabelWithTooltip
          label={<h5>Cross-validation</h5>}
          tooltip={tooltips.crossValidationMetrics}
          onClick={() => helper.open("crossvalidation")}
        />
        <CVMetrics metrics={cvResults} />
      </div>
    );
  };

  renderGenerativeModelStatisticsPane = statisticsPaneProps => {
    const { isLoading, modelStats } = statisticsPaneProps;

    if (isLoading) {
      return (
        <div className={styles.summaryMetricsPane}>
          <h5>Statistics</h5>
          <ul>
            <li className={Classes.SKELETON}>
              Number of itereations:{" "}
              <span style={{ color: Colors.GRAY3 }}>...loading</span>
            </li>
            <li className={Classes.SKELETON}>
              Number of sequences generated:{" "}
              <span style={{ color: Colors.GRAY3 }}>...loading</span>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className={styles.summaryMetricsPane}>
          <h5>Statistics</h5>
          <ul>
            <li>
              Number of iterations:{" "}
              <span style={{ color: Colors.GRAY3 }}>
                {modelStats.number_of_iterations}
              </span>
            </li>
            <li>
              Number of sequences generated:{" "}
              <span style={{ color: Colors.GRAY3 }}>
                {modelStats.total_number_of_sequences}
              </span>
            </li>
          </ul>
        </div>
      );
    }
  };

  renderErrorPane = error => {
    let errorJson = error;

    if (error.constructor === "string".constructor) {
      try {
        errorJson = parseErrorToJson(error);
      } catch (error) {
        errorJson = UNKNOWN_ERROR_OBJECT;
      }
    }

    return (
      <div className={styles.errorPane}>
        <Divider className={styles.divider} />
        <Callout intent={Intent.DANGER} icon={null}>
          {errorJson.message}
        </Callout>
      </div>
    );
  };

  render() {
    const { modelStats, modelType } = this.props;

    const renderStatistics =
      modelType === EvolveConfig.constants.GENERATIVE_MODEL
        ? this.renderGenerativeModelStatisticsPane
        : this.renderPredictionAndEvolutionStatisticsPane;

    const hasModelStats = Object.keys(modelStats).length !== 0;

    return (
      <div className={styles.container}>
        {hasModelStats && renderStatistics({ modelStats })}
      </div>
    );
  }
}

export default inject("mainStore")(observer(ModelStatsWidget));
