/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SelectParameters } from "./Steps/SelectParameters";
import { AlgorithmParameters } from "./Steps/AlgorithmParameters";
import StepForm from "../../../../src-shared/StepForm";
import SuccessPage from "./SucessPage";

const GuideRNAScorePredictionTool = ({
  initialValues,
  isToolIntegrated,
  toolIntegrationProps,
  toolSchema
}) => {
  const steps = [
    {
      title: "Select Parameters",
      Component: SelectParameters
    },
    {
      title: "Algorithm Parameters",
      Component: AlgorithmParameters
    }
  ];

  const onSubmit = async ({
    name,
    microbialMaterial,
    feature,
    casEnzyme,
    guideLength,
    minOnTargetScore,
    maxGeneratedGRnas,
    forceTargetAtGenome
  }) => {
    // referenceSequenceFiles is always length 1
    const refSeq =
      microbialMaterial.genome.primaryReferenceSequence
        .referenceSequenceFiles[0];

    const genomicRegion = feature.genomicRegion;

    const targetSequence = genomicRegion.fullSequence.slice(
      feature.start,
      feature.end + 1 // end should be included
    );

    // For now only one pam sequence is allowed, and casEnzymePamSites.length is 1 always
    const pamSite = casEnzyme.casEnzymePamSites
      .map(c => c.pamSite.sequence)
      .join(", ");

    const input = {
      guideRnaRunData: {
        name,
        genomeId: microbialMaterial.genome.id,
        genomicRegionId: genomicRegion.id,
        genomicReferenceSequenceId:
          microbialMaterial.genome.primaryReferenceSequence.id,
        sequenceFeatureId: feature.id,
        scaffoldSequence: {
          id: casEnzyme.scaffoldSequence.id,
          sequence: casEnzyme.scaffoldSequence.sequence
        }
      },
      CRISPRToolData: {
        data: {
          genomeFilename: refSeq.fastaFileUrl,
          targetSequence,
          targetIsForward: feature.strand === 1,
          forceTargetAtGenome
        },
        options: {
          guideLength,
          pamSite,
          minOnTargetScore,
          maxGeneratedGRnas
        }
      }
    };

    const response = await window.tgApi({
      url: "/crispr-grnas",
      method: "POST",
      data: input,
      withCredentials: true
    });

    const { taskId } = response.data;

    return {
      microserviceQueue: {
        id: taskId,
        _typename: "microserviceQueue"
      }
    };
  };

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      successPageInnerContent={SuccessPage}
      steps={steps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export { GuideRNAScorePredictionTool };
