/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import {
  Menu,
  MenuItem,
  MenuDivider,
  Tooltip,
  Position,
  KeyCombo
} from "@blueprintjs/core";
import { uniqBy, keyBy, find, has } from "lodash";
import { createDynamicMenu, commandMenuEnhancer } from "@teselagen/ui";
import forcedAssemblyStrategies from "../../../../../tg-iso-design/constants/forcedAssemblyStrategies";
import {
  getItemOfType,
  getInputReactionIdOfCard,
  getEliminatedCombinationGroupsOfCard,
  getRuleSetsOfBin,
  getBinIdsInCard,
  getElementsInBin
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import _createBin from "../../../utils/createBin";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import { schematicFromCard } from "../../../utils/schematicFromDesign";
import ruleSetFragment from "../../../../../tg-iso-design/graphql/fragments/ruleSetFragment";
import {
  designPartFragment,
  designPartsetFragment
} from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import TooltipMessages from "../../../../src-shared/constants/tooltips";
import _handleAssemblePartsInThisBinAsIs from "../ContextMenuHandlers/handleAssemblePartsInThisBinAsIs";
import handleInsertPart from "../ContextMenuHandlers/handleInsertPart";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { createPartsFromSeqsAndInsertThemIntoDesign } from "../ContextMenuHandlers/handleInsertSequenceFromExternalDbClick";
import handleInsertCompatiblePart from "../ContextMenuHandlers/handleInsertCompatiblePart";
import { get, sortBy } from "lodash";
import AddSplitsDialog from "../../Dialogs/AddSplitsDialog";
import InsertSequenceFromExternalDbMenuItem from "../InsertSequenceFromExternalDbMenuItem";
import { NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS } from "../../../constants/misc";
import InsertPartsFromSequenceDigestDialogContainer from "../../../containers/InsertPartsFromSequenceDigestDialogContainer";
import { showInsertBasePairLiteralDialog } from "../../Dialogs/InsertBasePairLiteralDialog";

const DesignElementCardContextMenu = ({
  selectedCardId,
  selectedBinIds,
  insertBin,
  state,
  removeElements,
  selectedCellsWithElements,
  selectedElement,
  selectedCellPaths,
  createElements,
  selectedInputCards,
  updateBin,
  changeFas,
  previousArfPartId,
  nextArfPartId,
  firstPreviousPartId,
  firstNextPartId,
  selectedBin,
  lockBins,
  removeElementEugeneRules,
  setDsf,
  mapElementToPart,
  eliminateCombinations,
  applyRuleSet,
  isLocked,
  unlockedSelectedBins,
  history,
  level,
  convertBinsToPlaceholders,
  insertRow,
  removeRows,
  alphabetizeParts,
  bins,
  selectedCard,
  isDesignTemplate,
  isSelectedCardRoot,
  selectedCardDsf,
  selectedCardHasReaction,
  isSelectedElementMapped,
  binOfFirstSelectedCell,
  binOfSelectedElement,
  assemblyMethodOfInputReaction,
  selectedElementIsInvalid,
  isSelectedElementInvalidOnlyDueToOverhangs,
  isSelectedCardLeaf,
  selectedBins,
  isFivePrimeBinBoundary,
  isThreePrimeBinBoundary,
  isListLayout,
  editorContext,
  upsertElement,
  assemblyMethodOfCard,
  removeBins,
  multi
}) => {
  const createBin = (onLeft, noPropagation) => () =>
    _createBin(
      { selectedCardId, selectedBinIds, insertBin },
      onLeft,
      noPropagation
    );

  const removeParts = () => {
    removeElements(
      selectedCellsWithElements
        .filter(c => {
          const bin = getItemOfType(state, "bin", c.binId);
          return !bin.isFixed && !bin.isLocked;
        })
        .map(c => c.elementId)
    );
  };

  const handleSendPartsToCrickitClick = async () => {
    return showDialog({
      modalType: "SEND_TO_CRICKIT",
      modalProps: {
        selectedElement
      }
    });
  };

  const handleSendBinToCrickit = () => {
    showDialog({
      modalType: "SEND_TO_CRICKIT",
      modalProps: {
        binIds: selectedBinIds,
        onSubmit: async () => {
          try {
            // let useDefaultParameters = crickitParams.useDefaultParameters;
            // delete crickitParams.useDefaultParameters;
            // socketWrapper.sendToCrickit({
            //   crickitParams,
            //   designId: Object.keys(state.design.design)[0],
            //   useDefaultParameters
            // });
          } catch (e) {
            console.error(e);
            window.toastr.error("Error sending bin(s) to crickit.");
          }
        }
      }
    });
  };

  const handleInsertPartClick = async () => {
    const binIds = selectedBinIds;
    const arfPartData = await safeQuery(
      ["part", "id name bpsUpStream bpsDownStream bps5Prime bps3Prime"],
      {
        isPlural: true,
        variables: {
          filter: {
            id: [
              previousArfPartId,
              nextArfPartId,
              firstPreviousPartId,
              firstNextPartId
            ].filter(id => !!id)
          }
        }
      }
    );

    showDialog({
      modalType: "PART_LIBRARY",
      modalProps: {
        firstPreviousPart: arfPartData.find(
          data => data.id === firstPreviousPartId
        ),
        firstNextPart: arfPartData.find(data => data.id === firstNextPartId),
        previousArfPart: arfPartData.find(
          data => data.id === previousArfPartId
        ),
        nextArfPart: arfPartData.find(data => data.id === nextArfPartId),
        binId: binIds.length === 1 ? binIds[0] : undefined,
        cardId: selectedCardId,
        basePairLiteralOption: selectedCellPaths.length === 1,
        onSubmit: async (
          parts,
          { validateCompatibleParts, adjacentBinHasArf }
        ) => {
          await handleInsertPart({
            parts,
            selectedCellPaths,
            createElements,
            cardId: selectedCardId,
            validateCompatibleParts
          });

          if (validateCompatibleParts && !adjacentBinHasArf) {
            // adjust the FRO and extraCPECBps of this bin and previous bin
            await handleInsertCompatiblePart(
              state,
              selectedBinIds[0],
              selectedInputCards[0],
              updateBin
            );

            // apply FAS of Assembly Ready Fragment to the newly-created element
            const elements = await safeQuery(["element", "id name"], {
              variables: {
                filter: {
                  name: parts[0].name,
                  designId: Object.keys(state.design.design)[0]
                }
              }
            });
            changeFas({
              fas: "Assembly Ready Fragment",
              cardId: selectedInputCards[0].id,
              elementId: sortBy(elements, "id")[elements.length - 1].id
            });
          }
        }
      }
    });
  };

  const handleInsertAssemblyPiece = () => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;
    showDialog({
      modalType: "INSERT_ASSEMBLY_PIECE",
      modalProps: {
        binId,
        onSubmit: async sequences => {
          try {
            const gibsonOverlapBps = Object.entries(
              state.design.customJ5Parameter
            )[0][1].gibsonOverlapBps; // why should I use selectors?
            const partUpserts = sequences.map(seq => ({
              name: seq.name,
              start: 0,
              end: seq.size - 1 - gibsonOverlapBps,
              sequenceId: seq.id,
              strand: 1
            }));
            // kc_todo refactor this into designStateSelectors
            const cardIdCount = {};
            Object.values(state.design.binCard).forEach(bc => {
              cardIdCount[bc.cardId] = cardIdCount[bc.cardId] || 0;
              cardIdCount[bc.cardId]++;
            });
            const singleBinCards = Object.values(state.design.binCard).filter(
              bc => cardIdCount[bc.cardId] === 1
            );

            const inputCardIdWithSelectedBin = find(singleBinCards, {
              binId: selectedBin.id
            }).cardId;
            const inputCardWithSelectedBin = find(
              Object.values(state.design.card),
              { id: inputCardIdWithSelectedBin }
            );
            const reactionId = inputCardWithSelectedBin.inputReactionId;

            let maxInputIndex = 0;
            const inputCards = Object.values(state.design.card).filter(card => {
              if (
                card.inputReactionId === reactionId &&
                parseInt(card.inputIndex, 10) > maxInputIndex
              ) {
                maxInputIndex = parseInt(card.inputIndex, 10);
              }
              return card.inputReactionId === reactionId;
            });
            const isOutputCircular = !!Object.values(state.design.card).find(
              card => card.outputReactionId === reactionId
            ).circular;

            const binUpdates = [
              {
                id: selectedBin.id,
                extra3PrimeBps: 0,
                extra5PrimeBps: 0,
                fro: -Math.floor(gibsonOverlapBps / 2)
              }
            ];
            const cardUpdates = [
              {
                id: inputCardIdWithSelectedBin,
                dsf: true
              }
            ];

            inputCards.forEach(card => {
              if (
                inputCardWithSelectedBin.inputIndex === 0 &&
                isOutputCircular &&
                parseInt(card.inputIndex, 10) === maxInputIndex
              ) {
                binUpdates.push({
                  id: find(Object.values(state.design.binCard), {
                    cardId: card.id
                  }).binId,
                  extra3PrimeBps: 0,
                  extra5PrimeBps: 0,
                  fro: -Math.floor(gibsonOverlapBps / 2)
                });
                cardUpdates.push({
                  id: card.id,
                  dsf: true
                });
              }
              if (
                parseInt(card.inputIndex, 10) ===
                parseInt(inputCardIdWithSelectedBin.inputIndex, 10) - 1
              ) {
                binUpdates.push({
                  id: selectedBin.id,
                  extra3PrimeBps: 0,
                  extra5PrimeBps: 0,
                  fro: -Math.floor(gibsonOverlapBps / 2)
                });
                cardUpdates.push({
                  id: card.id,
                  dsf: true
                });
              }
            });

            safeUpsert("bin", binUpdates);
            safeUpsert("card", cardUpdates);

            const parts = await safeUpsert("part", partUpserts);

            const fullParts = await safeQuery(designPartFragment, {
              isPlural: true,
              variables: { filter: { id: parts.map(p => p.id) } }
            });

            const elementIdsToDelete = selectedCellPaths.reduce((acc, cp) => {
              if (cp.elementId) acc.push(cp.elementId);
              return acc;
            }, []);
            createElements({
              binId,
              isAssemblyPiece: true,
              values: fullParts.map(part => ({ part })),
              elementIdsToDelete,
              cellIndex: selectedCellPath.index || 0
            });
          } catch (e) {
            console.error(e);
            window.toastr.error("Error inserting assembly piece(s).");
          }
        }
      }
    });
  };

  const handleInsertSequence = () => {
    showDialog({
      modalType: "INSERT_SEQUENCE",
      modalProps: {
        onSubmit: async sequences => {
          await createPartsFromSeqsAndInsertThemIntoDesign({
            cardId: selectedInputCards[0].id,
            sequences,
            selectedCellPaths,
            createElements
          });
        }
      }
    });
  };

  const handleInsertPartSetClick = () => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;
    showDialog({
      modalType: "PARTSET_LIBRARY",
      modalProps: {
        onSubmit: defaultAsyncWrap(async partsets => {
          const elementIdsToDelete = selectedCellPaths.reduce((acc, cp) => {
            if (cp.elementId) acc.push(cp.elementId);
            return acc;
          }, []);
          const fullPartsets = await safeQuery(designPartsetFragment, {
            isPlural: true,
            variables: { filter: { id: partsets.map(p => p.id) } }
          });
          createElements({
            binId,
            values: fullPartsets.map(partset => ({ partset })),
            elementIdsToDelete,
            cellIndex: selectedCellPath.index || 0
          });
        }, "Error inserting part set(s).")
      }
    });
  };

  const handleInsertUnmappedElementClick = () => {
    const selectedCellPath = selectedCellPaths[0] || {};
    const binId = selectedCellPath.binId || selectedBin.id;

    showDialog({
      modalType: "ADD_UNMAPPED_ELEMENT",
      modalProps: {
        onSubmit: values => {
          createElements({
            binId,
            values: {
              name: values.name
            },
            elementIdsToDelete: selectedCellPaths.reduce((acc, cp) => {
              if (cp.elementId) acc.push(cp.elementId);
              return acc;
            }, []),
            cellIndex: selectedCellPath.index || 0
          });
        }
      }
    });
  };

  const handleLockBin = () => {
    lockBins({
      binIds: selectedBinIds,
      isLocked: true
    });
  };

  const handleUnlockBin = () => {
    lockBins({
      binIds: selectedBinIds,
      isLocked: false
    });
  };

  const handleAddEugeneRule = () => {
    showDialog({
      modalType: "ADD_EUGENE_RULE"
    });
  };

  const handleRemoveEugeneRule = () => {
    removeElementEugeneRules({
      cardId: selectedCardId,
      elementId: selectedElement.id
    });
  };

  const handleFasClick = fas => () => {
    changeFas({
      fas,
      cardIdToElementIdsMap: {
        [selectedCardId]: selectedCellPaths
          .filter(p => p.elementId)
          .map(p => p.elementId)
      }
    });
  };

  const handleApplyDesignTemplateClick = () => {
    showDialog({
      modalType: "APPLY_DESIGN_TEMPLATE"
    });
  };

  const handleDirectSynthesisFirewallClick = dsf => () => {
    setDsf({ cardId: selectedCardId, dsf });
  };

  const handleMapElementToPart = () => {
    const { binId, elementId } = selectedCellsWithElements[0];
    showDialog({
      modalType: "PART_LIBRARY",
      modalProps: {
        isSingleSelect: true,
        binId,
        cardId: selectedCardId,
        onSubmit: async parts => {
          try {
            if (parts.length !== 1)
              throw new Error("Only one part should be selected.");

            const part = await safeQuery(designPartFragment, {
              variables: { id: parts[0].id }
            });

            mapElementToPart({ binId, elementId, part });
          } catch (e) {
            console.error(e);
            window.toastr.error("Error mapping to part.");
          }
        }
      }
    });
  };

  const handleInjectMissingOverhangs = () => {
    // const {
    //   state,
    //   selectedCardId,
    //   selectedElement,
    //   binOfSelectedElement,
    //   updateElement
    // } = this.props
    // const validator = getAncestralValidatorSet(
    //   state,
    //   selectedCardId,
    //   binOfSelectedElement.id
    // )
    // const startRegex = getRegexOfValidatorGeneric(state, validator.id, true)
    // const endRegex = getRegexOfValidatorGeneric(state, validator.id, false)
    // const values = { id: selectedElement.id }
    // if (
    //   !isElementEndValid(
    //     state,
    //     validator.id,
    //     selectedElement.id,
    //     binOfSelectedElement.id,
    //     true
    //   )
    // ) {
    //   values.extraStartSequence = startRegex || ''
    // }
    // if (
    //   !isElementEndValid(
    //     state,
    //     validator.id,
    //     selectedElement.id,
    //     binOfSelectedElement.id,
    //     false
    //   )
    // ) {
    //   values.extraEndSequence = endRegex || ''
    // }
    // updateElement({ element: values })
  };

  const createFasSubmenu = disabled => (
    <MenuItem
      key="forcedAssemblyStrategy"
      text="Forced Assembly Strategy"
      disabled={disabled}
    >
      {forcedAssemblyStrategies.map(fas => (
        <MenuItem
          key={fas}
          text={fas.replace(/_/g, " ")}
          onClick={handleFasClick(fas)}
        />
      ))}
    </MenuItem>
  );

  const createEugeneRulesSubmenu = disabled => (
    <MenuItem key="eugeneRules" text="Eugene Rules" disabled={disabled}>
      <MenuItem key="addRule" text="Add Rule" onClick={handleAddEugeneRule} />
      <MenuItem
        key="removeRule"
        text="Remove Rule(s)"
        onClick={handleRemoveEugeneRule}
      />
    </MenuItem>
  );

  const createCombinationsSubmenu = () => (
    <MenuItem key="combinations" text="Combinations">
      <MenuItem
        key="viewEliminatedCombinations"
        text="View Eliminated Combinations"
        disabled={
          !getEliminatedCombinationGroupsOfCard(state, selectedCardId).length
        }
        onClick={() =>
          showDialog({ modalType: "VIEW_ELIMINATED_COMBINATIONS" })
        }
      />
      <MenuItem
        key="eliminateTheseCombinations"
        text="Eliminate These Combinations"
        disabled={!selectedCellsWithElements.length}
        onClick={() => eliminateCombinations()}
      />
    </MenuItem>
  );

  const handleApplyRuleSetsClick = () => {
    // Get the rulesets that are common to all the selected sets.
    const ruleSets = selectedBinIds.map(setId =>
      keyBy(getRuleSetsOfBin(state, setId), "id")
    );
    const commonRuleSetIds = Object.keys(ruleSets[0]).filter(id =>
      ruleSets.every(rs => rs[id])
    );

    showDialog({
      modalType: "DESIGN_RULE_SET_LIBRARY",
      modalProps: {
        idsToIgnore: commonRuleSetIds,
        onSubmit: async ruleSets => {
          try {
            const fullRuleSets = await safeQuery(ruleSetFragment, {
              isPlural: true,
              variables: { filter: { id: ruleSets.map(rs => rs.id) } }
            });
            applyRuleSet({
              binIds: selectedBinIds,
              ruleSets: fullRuleSets
            });
          } catch (e) {
            console.error(e);
            window.toastr.error("Error applying rule set.");
          }
        }
      }
    });
  };

  const createDesignRulesSubmenu = () => (
    <MenuItem
      key="designRules"
      disabled={isLocked || !unlockedSelectedBins.length}
      text="Design Rules"
    >
      <MenuItem
        key="applyDesignRuleset"
        text="Apply Design Ruleset"
        onClick={handleApplyRuleSetsClick}
      />
      {selectedBinIds.length === 1 && (
        <MenuItem
          key="viewDesignRulesetValidationResults"
          text="View Design Ruleset Validation Results"
          onClick={() => {
            showDialog({ modalType: "DESIGN_RULE_VIOLATIONS" });
          }}
        />
      )}
    </MenuItem>
  );

  const handleCreateSchematicFromCard = defaultAsyncWrap(async () => {
    const diagramId = await schematicFromCard(selectedCardId, level);
    history.push(`/schematics/${diagramId}`);
  }, "Error creating schematic from card.");

  const convertBinToPlaceholder = () => {
    convertBinsToPlaceholders({ binIds: selectedBinIds, isPlaceholder: true });
  };

  const convertPlaceholderToRegularBin = () => {
    convertBinsToPlaceholders({ binIds: selectedBinIds, isPlaceholder: false });
  };

  const handleAssemblePartsInThisBinAsIs = () => {
    _handleAssemblePartsInThisBinAsIs(
      state,
      selectedBinIds,
      selectedInputCards,
      updateBin
    );
  };

  const insertPartFromSequenceDigest = () => {
    showDialog({
      ModalComponent: InsertPartsFromSequenceDigestDialogContainer,
      modalProps: { selectedCellPaths, createElements, selectedBinIds }
    });
  };

  const handleCreateConstructAnnotation = annotationType => () => {
    showDialog({
      modalType: "CREATE_CONSTRUCT_ANNOTATION",
      modalProps: {
        cardId: selectedCardId,
        leftBoundaryBinId: selectedBinIds[0],
        rightBoundaryBinId: selectedBinIds[selectedBinIds.length - 1],
        bins,
        annotationType
      }
    });
  };

  if (!selectedCardId) return null;

  let shouldRenderFasSubMenu = false;
  const singleBinCard = getBinIdsInCard(state, selectedCard.id).length === 1;

  const menuItems = [];
  const insertMenuItems = [];
  const createMenuItems = [];
  const removeMenuItems = [];
  const replaceMenuItems = [];
  const digestMenuItems = [];
  let clipboardItems = [];

  if (selectedCard) {
    const numberOfBinsInCard = getBinIdsInCard(state, selectedCardId).length;

    if (has(selectedBin, "id")) {
      const elementsInBin = getElementsInBin(state, selectedBin.id);

      if (elementsInBin.length > 1) {
        menuItems.push(
          <MenuItem
            key="editElementIndexInBin"
            text="Edit Part Index"
            onClick={() => {
              showDialog({
                modalType: "EDIT_ELEMENT_INDEX_IN_BIN",
                modalProps: {
                  elementsInBin,
                  state,
                  upsertElement,
                  selectedElement
                }
              });
            }}
          />
        );
      }
    }

    if (!selectedCardHasReaction && numberOfBinsInCard > 1) {
      menuItems.push(
        <MenuItem
          key="addReaction"
          text="Add Assembly Reaction"
          onClick={() => {
            if (numberOfBinsInCard < 2)
              return window.toastr.warning(
                `Cannot add assembly reaction to card with only one bin.`
              );
            showDialog({
              ModalComponent: AddSplitsDialog,
              modalProps: {
                title: "Choose Input Grouping",
                addMockReaction: true,
                cardId: selectedCard.id
              }
            });
          }}
        />
      );
    }
    menuItems.push(
      <MenuItem
        key="applyDesignTemplate"
        text="Apply Design Template"
        onClick={handleApplyDesignTemplateClick}
      />,
      <MenuItem
        key="Create Schematic From Card"
        text="Create Schematic From Card"
        onClick={handleCreateSchematicFromCard}
      />
    );

    if (
      assemblyMethodOfInputReaction &&
      assemblyMethodOfInputReaction.name === "Gibson/SLIC/CPEC"
    ) {
      menuItems.push(
        <MenuItem
          key="assemblePartsInThisBinAsIs"
          text={
            <Tooltip
              content={
                <span>{TooltipMessages.assemblePartsInThisBinAsIs}</span>
              }
              position={Position.RIGHT}
            >
              Assemble Parts in this Bin As-Is
            </Tooltip>
          }
          onClick={handleAssemblePartsInThisBinAsIs}
        />
      );
    }

    if (isDesignTemplate) {
      if (!selectedBins.every(s => s.isPlaceholder)) {
        menuItems.push(
          <MenuItem
            key="convertBinToPlaceholder"
            text={
              "Convert Bin" +
              (selectedBinIds.length > 1 ? "s" : "") +
              " to Placeholder" +
              (selectedBinIds.length > 1 ? "s" : "")
            }
            onClick={convertBinToPlaceholder}
          />
        );
      }
      if (!selectedBins.every(s => !s.isPlaceholder)) {
        const s =
          selectedBins.filter(s => s.isPlaceholder).length > 1 ? "s" : "";
        menuItems.push(
          <MenuItem
            key="convertPlaceholderToRegularBin"
            text={`Convert Placeholder${s} to Regular Bin${s}`}
            onClick={convertPlaceholderToRegularBin}
          />
        );
      }
    }

    if (!isSelectedCardRoot && selectedCardDsf) {
      removeMenuItems.push(
        <MenuItem
          key="removeDirectSynthesisFirewall"
          text="Remove Direct Synthesis Firewall"
          onClick={handleDirectSynthesisFirewallClick(false)}
        />
      );
    }

    if (!isSelectedCardRoot && !selectedCardDsf) {
      insertMenuItems.push(
        <MenuItem
          key="newDirectSynthesisFirewall"
          text="Direct Synthesis Firewall"
          onClick={handleDirectSynthesisFirewallClick(true)}
        />
      );
    }
  }

  if (selectedCard && selectedBinIds.length) {
    const insertSetMenuItems = [
      <MenuItem
        key="insertBinLeft"
        text="Insert Bin Left"
        // labelElement="⌥⇧–"
        labelElement={<KeyCombo minimal combo="option + shift + minus" />}
        onClick={createBin(true, false)}
      />,
      <MenuItem
        key="insertBinRight"
        text="Insert Bin Right"
        labelElement={<KeyCombo minimal combo="option + shift + plus" />}
        onClick={createBin(false, false)}
      />
    ];
    insertMenuItems.push(...insertSetMenuItems);

    if (isSelectedCardLeaf & isFivePrimeBinBoundary) {
      insertMenuItems.push(
        <MenuItem
          key="insertBinLeftNoProp"
          text="Insert Bin Left (No Propagation)"
          labelElement={<KeyCombo minimal combo="option + shift + [" />}
          onClick={createBin(true, true)}
        />
      );
    }
    if (isSelectedCardLeaf & isThreePrimeBinBoundary) {
      insertMenuItems.push(
        <MenuItem
          key="insertBinRightNoProp"
          text="Insert Bin Right (No Propagation)"
          labelElement={<KeyCombo minimal combo="option + shift + ]" />}
          onClick={createBin(false, true)}
        />
      );
    }

    replaceMenuItems.push(
      <MenuItem
        key="replacePart"
        text="Replace with a Part"
        onClick={() => {
          removeParts();
          handleInsertPartClick();
        }}
      />,
      <MenuItem
        key="replacePartset"
        text="Replace with a Partset"
        onClick={() => {
          removeParts();
          handleInsertPartSetClick();
        }}
      />
    );

    removeMenuItems.push(
      <MenuItem
        key="removeBins"
        text={selectedBinIds.length > 1 ? "Remove Bins" : "Remove Bin"}
        disabled={!isSelectedCardLeaf}
        onClick={() => {
          removeBins({
            cardId: selectedCardId,
            binIds: selectedBinIds
          });
        }}
      />
    );

    menuItems.push(
      <MenuItem
        key="alphabetizeParts"
        text={`Alphabetize Parts in Bin${sIfPlural(selectedBinIds)}`}
        onClick={() => alphabetizeParts()}
      />
    );

    if (selectedBinIds.length === 1 && window.frontEndConfig.atLanzatech) {
      menuItems.push(
        <MenuItem
          key="sendBinToCrickit"
          text="Send Bin to Crickit"
          onClick={handleSendBinToCrickit}
        />
      );
    }

    if (selectedBinIds.length === 1 && isDesignTemplate) {
      if (selectedBin.isLocked) {
        menuItems.push(
          <MenuItem
            key="unlockSet"
            text="Unlock Bin"
            onClick={handleUnlockBin}
          />
        );
      } else {
        menuItems.push(
          <MenuItem key="lockSet" text="Lock Bin" onClick={handleLockBin} />
        );
      }
    }

    const insertPartAndUnmappedElementsMenuItems = [
      <MenuItem
        key="insertPart"
        text="Insert Part"
        onClick={handleInsertPartClick}
      />,
      <InsertSequenceFromExternalDbMenuItem
        key="insertSequenceFromExternalDb"
        multi={multi}
        selectedInputCards={selectedInputCards}
        selectedCellPaths={selectedCellPaths}
        createElements={createElements}
      />,
      <MenuItem
        key="insertUnmappedElement"
        text="Insert Unmapped Part"
        onClick={handleInsertUnmappedElementClick}
      />,
      <MenuItem
        key="insertBasePairLiteral"
        text="Insert Base Pairs"
        onClick={showInsertBasePairLiteralDialog}
      />,
      <MenuItem
        key="insertAssemblyPiece"
        text="Insert Assembly Piece"
        onClick={handleInsertAssemblyPiece}
      />,
      <MenuItem
        key="insertSequence"
        text="Insert Sequence"
        onClick={handleInsertSequence}
      />
    ];

    const insertPartFromSequenceDigestMenuItem = (
      <MenuItem
        key="insertPartFromSequenceDigest"
        text="Insert Part(s) from Sequence Digest"
        onClick={insertPartFromSequenceDigest}
      />
    );

    if (selectedBinIds.length === 1 && isActionable(selectedBin)) {
      insertMenuItems.push(...insertPartAndUnmappedElementsMenuItems);
      if (!isListLayout) {
        insertMenuItems.push(
          <MenuItem
            key="insertPartSet"
            text="Insert Part Set"
            onClick={handleInsertPartSetClick}
          />,
          insertPartFromSequenceDigestMenuItem
        );
      }
    }

    if (selectedBinIds.length > 1 && isActionable(selectedBin)) {
      insertMenuItems.push(
        <MenuItem
          key="insertPart"
          text="Insert Parts"
          onClick={handleInsertPartClick}
        />,
        insertPartFromSequenceDigestMenuItem
      );
    }

    if (
      selectedBinIds.length > 1 &&
      !NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS.includes(
        get(assemblyMethodOfCard, "name")
      )
    ) {
      menuItems.push(
        <MenuItem
          key="createConstructAnnotation"
          text="Create Construct Annotation"
        >
          <MenuItem
            key="createConstructPart"
            text="Part"
            onClick={handleCreateConstructAnnotation("part")}
          />
          <MenuItem
            key="createConstructFeature"
            text="Feature"
            onClick={handleCreateConstructAnnotation("feature")}
          />
        </MenuItem>
      );
    }

    if (selectedCellPaths.length) {
      insertMenuItems.push(
        <MenuItem
          key="insertRowAbove"
          text="Insert Row Above"
          onClick={() => insertRow({ isAbove: true })}
        />,
        <MenuItem
          key="insertRowBelow"
          text="Insert Row Below"
          onClick={() => insertRow({ isAbove: false })}
        />
      );
      removeMenuItems.push(
        <MenuItem
          key="removeRows"
          text={`Remove Row${sIfPlural(uniqBy(selectedCellPaths, "index"))}`}
          onClick={() => removeRows()}
        />
      );

      // We have the selectedBins.length !== 1 because if selectedBins.length === 1, we
      // will have already added the menu item.
      if (
        selectedCellPaths.length === 1 &&
        isActionable(binOfFirstSelectedCell) &&
        selectedBinIds.length !== 1
      ) {
        insertMenuItems.push(...insertPartAndUnmappedElementsMenuItems);
      }

      if (selectedCellsWithElements.length) {
        removeMenuItems.push(
          <MenuItem
            key="removeParts"
            text={
              selectedCellsWithElements.length > 1
                ? "Remove Parts"
                : selectedElement.partsetId
                  ? "Remove Partset"
                  : isSelectedElementMapped
                    ? "Remove Part"
                    : "Remove Unmapped Part"
            }
            onClick={removeParts}
          />
        );
      }

      if (
        window.frontEndConfig.atLanzatech &&
        selectedCellsWithElements.length === 1
      ) {
        menuItems.push(
          <MenuItem
            key="sendPartsToCrickIT"
            text="Send Part to Crickit"
            onClick={handleSendPartsToCrickitClick}
          />
        );
      }

      // TODO migrate all items to use commands like this
      clipboardItems = createDynamicMenu(
        ["cutPart", "copyPart", "pastePart"],
        [
          commandMenuEnhancer(editorContext.commands, {
            forceIconAlignment: false
          })
        ]
      );

      // Make sure that the selected card is not the root card.
      if (selectedCellsWithElements.length >= 1 && !isSelectedCardRoot) {
        shouldRenderFasSubMenu = true;
      }
      if (
        selectedCellsWithElements.length === 1 &&
        !isSelectedElementMapped &&
        isActionable(binOfSelectedElement)
      ) {
        createMenuItems.push(
          <MenuItem
            key="mapElementToPart"
            text="Map to Part"
            onClick={handleMapElementToPart}
          />,
          <MenuItem
            key="mapBasePairLiteralToPart"
            text="Map to Base Pairs"
            onClick={showInsertBasePairLiteralDialog}
          />
        );
      }
    }
  }

  // If the set is on a leaf card with only one set
  // and is invalid purely due to overhangs. This (should)
  // imply that the operation is scarless. Overwise we should
  // at least see the insert and the flanking validator.
  if (
    isSelectedCardLeaf &&
    selectedElement &&
    selectedElementIsInvalid &&
    singleBinCard
  ) {
    menuItems.push(
      <MenuItem
        text="Inject Missing Overhangs"
        key="injectMissingOverhangs"
        disabled={!isSelectedElementInvalidOnlyDueToOverhangs}
        onClick={handleInjectMissingOverhangs}
      />
    );
  }

  // FAS should only be applicable to elements in single bin cards.
  selectedElement &&
    singleBinCard &&
    menuItems.push(createFasSubmenu(!shouldRenderFasSubMenu));

  menuItems.unshift(
    createEugeneRulesSubmenu(
      !selectedElement ||
        !getInputReactionIdOfCard(state, selectedCardId) ||
        selectedElement.isEmpty
    ),
    createCombinationsSubmenu(),
    createDesignRulesSubmenu(),
    <MenuDivider key={Math.random()} />
  );
  if (digestMenuItems.length)
    menuItems.unshift(
      <MenuItem key="digest" text="Digest-related actions">
        {digestMenuItems}
      </MenuItem>
    );
  menuItems.unshift(
    <MenuItem key="insert" text="Insert" disabled={!insertMenuItems.length}>
      {insertMenuItems}
    </MenuItem>,
    <MenuItem key="create" text="Create" disabled={!createMenuItems.length}>
      {createMenuItems}
    </MenuItem>,
    <MenuItem key="remove" text="Remove" disabled={!removeMenuItems.length}>
      {removeMenuItems}
    </MenuItem>,
    <MenuItem key="replace" text="Replace" disabled={!selectedElement}>
      {replaceMenuItems}
    </MenuItem>
  );

  menuItems.push(...clipboardItems);

  return <Menu>{menuItems}</Menu>;
};

const isActionable = bin => !bin.isFixed && !bin.isPlaceholder && !bin.isLocked;

export default DesignElementCardContextMenu;
