/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { validateTransfers } from "../../../utils";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { compose } from "recompose";
import withQuery from "../../../../src-shared/withQuery";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import stepFormValues from "../../../../src-shared/stepFormValues";
import ReviewWorklistSection from "../../ReviewWorklistSection";
import { Button, Callout } from "@blueprintjs/core";

function ReviewWorklists({
  aliquotContainerTypes,
  allTransfers,
  Footer,
  footerProps,
  toolSchema
}) {
  // do not block submit if there are validation errors
  // might be prepping a worklist that will not be run yet
  const errors = {};
  const worklistKeys = Object.keys(allTransfers);
  worklistKeys.forEach(key => {
    const transfers = allTransfers[key];
    const error = validateTransfers(
      {
        worklistTransfers: transfers
      },
      aliquotContainerTypes
    );
    if (error) {
      errors[key] = error;
    }
  });

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Review Worklists"
          helper="Enter a worklist name and review the transfers below."
        />
        {worklistKeys.map((key, i) => {
          return (
            <div key={i}>
              <ReviewWorklistSection
                noHeader
                noSort
                noSection
                toolSchema={toolSchema}
                worklistTransfers={allTransfers[key]}
                fieldName={`worklistNames.${key}`}
                tableFormName={`createCustomWorklist${key}`}
              />
              {errors[key] && (
                <Callout style={{ marginTop: 20 }} intent="warning">
                  {errors[key]}
                </Callout>
              )}
              {i !== worklistKeys.length - 1 && (
                <hr className="tg-section-break" />
              )}
            </div>
          );
        })}
      </div>
      <Footer
        {...footerProps}
        nextButton={
          <Button
            type="submit"
            intent="success"
            loading={footerProps.submitting}
          >
            Generate Worklist
          </Button>
        }
      />
    </div>
  );
}

export default compose(
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  }),
  stepFormValues("allTransfers")
)(ReviewWorklists);
