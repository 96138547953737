/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEmpty } from "lodash";
import StepForm from "../../StepForm";
import DataSelectionStep from "./steps/DataSelectionStep";
import { safeUpsert } from "../../apolloMethods";
import { rowIndexToLetter } from "../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import { throwFormError } from "../../utils/formUtils";

const filterTypes = {
  over: ">",
  below: "<",
  equal: "==="
};

const SampleScreeningTool = props => {
  const [errors, setErrors] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  const { initialValues, toolSchema, toolIntegrationProps, isToolIntegrated } =
    props;

  const validate = values => {
    const errors = {};
    const { dataGridEntites } = values;

    if (!isEmpty(dataGridEntites)) {
      if (dataGridEntites.every(ent => ent.aliquot === null)) {
        errors.dataGridEntities = "Data grid does not contain any aliquots.";
      }
      if (dataGridEntites.every(ent => ent.aliquot.sample === null)) {
        errors.dataGridEntities = "Data grid does not contain any sample data.";
      }
      if (dataGridEntites.every(ent => ent.aliquot.sample.material === null)) {
        errors.dataGridEntities =
          "Data grid does not contain any material data.";
      }
      if (dataGridEntites.every(ent => ent.aliquot.volume === null)) {
        errors.dataGridEntities = "Data grid does not contain any volume data.";
      }
      if (dataGridEntites.every(ent => ent.aliquot.concentration === null)) {
        errors.dataGridEntities =
          "Data grid does not contain any concentration data.";
      }
    }

    if (!isEmpty(errors)) setErrors(errors);
  };

  const onSubmit = async values => {
    try {
      const { dataGridEntites, filterType, filterTarget, filterValue } = values;

      setSubmitting(true);
      const dataTableName = `Sample List ${filterType === "equal" ? "with" : filterType} ${filterValue} ${filterTarget.unit} of ${filterTarget.name}`;

      const filteredDataRows = dataGridEntites.filter(row => {
        const value = row[`${filterTarget.name} [${filterTarget.unit}]`];
        if (isNaN(value)) return;
        return eval(`${value} ${filterTypes[filterType]} ${filterValue}`);
      });

      if (isEmpty(filteredDataRows))
        throw new Error("No data found for the selected filter.");

      const dataRows = filteredDataRows.map(row => {
        const aliquot = row.aliquot;
        const sample = aliquot.sample;
        const material = sample.material;
        const well = aliquot.aliquotContainer;
        const plate = well.containerArray;
        const wellPosition =
          rowIndexToLetter(well?.rowPosition) + (well?.columnPosition + 1);
        const volume =
          aliquot?.volume && `${aliquot.volume} ${aliquot.volumetricUnitCode}`;
        const concentration =
          aliquot?.concentration &&
          `${aliquot.concentration} ${aliquot.concentrationUnitCode}`;
        const mass = aliquot?.mass && `${aliquot.mass} ${aliquot.massUnitCode}`;

        return {
          rowValues: {
            aliquotId: aliquot?.id,
            sampleId: sample?.id,
            materialName: material?.name,
            plateName: plate?.name,
            plateBarcode: plate.barcode?.barcodeString,
            well: wellPosition,
            volume: volume || "N/A",
            concentration: concentration || "N/A",
            mass: mass || "N/A"
          }
        };
      });

      const [{ id: dataTableId }] = await safeUpsert("dataTable", {
        name: dataTableName,
        dataTableTypeCode: "SAMPLE_INVENTORY_LIST",
        dataRows
      });

      return { dataTableId, dataTableName };
    } catch (error) {
      throwFormError(error.message || "Error creating Sample List.");
    } finally {
      setSubmitting(false);
    }
  };

  const steps = [
    {
      title: "Data Selection",
      Component: DataSelectionStep,
      withCustomFooter: true,
      props: {
        submitting,
        filterTypes,
        errors
      }
    }
  ];

  const SuccessPageInnerContent = ({ dataTableId, dataTableName }) => {
    const headerMessage = (
      <span>
        <a href={`/client/data-tables/${dataTableId}`}>{dataTableName}</a>
        {" has been created."}
      </span>
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h4>{headerMessage}</h4>
      </div>
    );
  };

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      successPageInnerContent={SuccessPageInnerContent}
    />
  );
};

export default SampleScreeningTool;
