/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";
import SelectMaterials, {
  materialFragment,
  reactionMapFragment
} from "./Steps/SelectMaterials";
import ExtendedProperties from "./Steps/ExtendedProperties";
import { withSelectedEntities } from "@teselagen/ui";
import {
  addAndRemoveExtendedProperties,
  getBoundExtendedPropertyUploadHelpers
} from "../../../../src-shared/utils/extendedPropertyUtils";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";
import { parseCsvOrExcelFile } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { noop } from "lodash";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { removeExtendedPropertiesTableName } from "../../../../src-shared/RemoveExtendedPropertiesTable";
import { groupBy } from "lodash";
import { generateSuccessPageLink } from "../../../../src-shared/StepForm/SuccessPage";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { isEmpty } from "lodash";

function UpdateMaterialExtendedProperties(props) {
  const onSubmit = async values => {
    try {
      const {
        [removeExtendedPropertiesTableName +
        "SelectedEntities"]: propsToRemove = []
      } = props;
      const propIdsToRemove = propsToRemove.map(p => p.id);
      const {
        allMaterials = [],
        materialExtendedValues = [],
        extendedPropertiesFile = []
      } = values;

      const extendedValuesToDelete = {
        extendedValue: [],
        extendedCategoryValue: [],
        extendedMeasurementValue: []
      };

      const extendedValuesToCreate = {
        extendedValue: [],
        extendedCategoryValue: [],
        extendedMeasurementValue: []
      };

      allMaterials.forEach(material => {
        addAndRemoveExtendedProperties(material, {
          extendedValues: materialExtendedValues,
          extendedValuesToDelete,
          extendedValuesToCreate,
          propIdsToRemove
        });
      });

      let _createUploadProperties = noop;
      if (extendedPropertiesFile.length) {
        const {
          data,
          meta: { fields }
        } = await parseCsvOrExcelFile(extendedPropertiesFile[0]);
        const materialsByName = groupBy(allMaterials, p =>
          p.name.toLowerCase()
        );
        const {
          createUploadProperties,
          getCsvRowExtProps,
          properties
        } = await getBoundExtendedPropertyUploadHelpers(fields);
        if (isEmpty(properties)) {
          throw new Error("No extended properties found in file.");
        }
        _createUploadProperties = createUploadProperties;

        for (const [index, row] of data.entries()) {
          const { "material name": materialName } = row;
          if (!materialName) {
            throw new Error(`Row ${index + 1}: material name is required.`);
          }
          const materials = materialsByName[materialName.toLowerCase()];
          if (!materials) {
            throw new Error(`Material ${materialName} not found in selection.`);
          } else if (materials.length > 1) {
            throw new Error(
              `Multiple materials found with name ${materialName}.`
            );
          }
          const material = materials[0];
          getCsvRowExtProps({
            row,
            recordId: material.id,
            record: material,
            modelTypeCode: "MATERIAL"
          });
        }
      }

      for (const model of Object.keys(extendedValuesToDelete)) {
        await safeDelete(model, extendedValuesToDelete[model]);
      }
      for (const model of Object.keys(extendedValuesToCreate)) {
        await safeUpsert(model, extendedValuesToCreate[model]);
      }
      await _createUploadProperties();

      return {
        materials: allMaterials
      };
    } catch (error) {
      throwFormError(error.message || error);
    }
  };

  const {
    toolSchema,
    initialValues,
    isToolIntegrated,
    toolIntegrationProps
  } = props;

  const steps = [
    {
      title: "Select Materials",
      Component: SelectMaterials,
      withCustomFooter: true
    },
    {
      title: "Update Extended Properties",
      Component: ExtendedProperties,
      withCustomFooter: true
    }
  ];

  return (
    <StepForm
      steps={steps}
      successPageInnerContent={
        isToolIntegrated
          ? null
          : data => {
              const groupedMaterials = {};
              data.materials.forEach(material => {
                const matTypeHelper =
                  material.materialType.code.toLowerCase() + "Material";
                if (!groupedMaterials[matTypeHelper]) {
                  groupedMaterials[matTypeHelper] = [];
                }
                groupedMaterials[matTypeHelper].push(material);
              });
              return (
                <div>
                  {Object.keys(groupedMaterials).map((key, index) => {
                    return (
                      <div key={index}>
                        {generateSuccessPageLink(groupedMaterials[key], {
                          isUpdate: true,
                          model: key,
                          label: modelNameToReadableName(key, {
                            upperCase: true,
                            plural: true
                          })
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            }
      }
      toolSchema={toolSchema}
      initialValues={initialValues}
      enableReinitialize={isToolIntegrated}
      toolIntegrationProps={toolIntegrationProps}
      onSubmit={onSubmit}
    />
  );
}

export default compose(
  withWorkflowInputs(reactionMapFragment),
  withWorkflowInputs(materialFragment),
  withSelectedEntities(removeExtendedPropertiesTableName)
)(UpdateMaterialExtendedProperties);
