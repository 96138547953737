/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { designPartFragment } from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery } from "../../../../src-shared/apolloMethods";

export default async ({ selectedCellPaths, cardId, parts, createElements }) => {
  if (selectedCellPaths.length > 1 && selectedCellPaths.length < parts.length) {
    return window.toastr.error(
      `Did not select enough cells to fill total parts selected`
    );
  }
  try {
    const elementIdsToDelete = selectedCellPaths.reduce((acc, cp) => {
      if (cp.elementId) acc.push(cp.elementId);
      return acc;
    }, []);

    const partIds = parts.map(p => p.id);
    const fullParts = await safeQuery(designPartFragment, {
      isPlural: true,
      variables: { filter: { id: partIds } }
    });
    const sortedFullParts = partIds.map(id => {
      return fullParts.find(part => part.id === id);
    });
    /*
        createElements takes an object or array for values.
        objects passed into values has to be object pointing to object with values.
        create elements can only accept 1 binId.
      */
    sortedFullParts.forEach((part, index) => {
      createElements({
        binId:
          selectedCellPaths.length === 1
            ? selectedCellPaths[0].binId
            : selectedCellPaths[index].binId,
        cardId,
        values: {
          part,
          ...(part?.isDigestPart &&
            part.isDigestValid && { fas: { name: "DIGEST" } })
        },
        elementIdsToDelete,
        cellIndex:
          selectedCellPaths.length === 1
            ? selectedCellPaths[0].index + index
            : selectedCellPaths[index].index || 0
      });
    });
  } catch (err) {
    console.error(err);
  }
};
