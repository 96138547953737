/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { getToolOutputItems } from "../../../../utils";

function OutputNames({ toolSchema }) {
  const outputItems = getToolOutputItems(toolSchema);

  return (
    <div className="tg-step-form-section">
      <HeaderWithHelper
        header="Output Data"
        helper="Name the output data set, lists, and reaction map."
      />
      <div
        className="tg-flex column align-flex-end"
        style={{ width: "50%", marginTop: 10 }}
      >
        <div style={{ width: "100%" }}>
          {outputItems.map(({ name, label, defaultValue }) => {
            return (
              <InputField
                label={label}
                key={name}
                name={name + "Name"}
                isRequired
                generateDefaultValue={{
                  ...defaultValueConstants.CONSTRUCT_SELECTION_OUTPUT_NAME,
                  customParams: {
                    outputType: defaultValue
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OutputNames;
