/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InfoHelper } from "@teselagen/ui";
import { statusToIconMap } from "../../../../tg-iso-design/constants/designStateConstants";
import { showDialog } from "../../../src-shared/GlobalDialog";
import DesignReviewStatusDialog from "../Dialogs/DesignReviewStatusDialog";

export const DesignApprovalStatusIcon = ({
  id,
  activeSubmission,
  submissionStateCode,
  withText,
  noNewApprovalsAllowed,
  selectedSubmissionId,
  noClick,
  className = "",
  showGoToDesign,
  refetch = () => {},
  ...rest
}) => {
  const status = activeSubmission
    ? submissionStateCode || activeSubmission.submissionStateCode
    : "NO_SUBMISSION_YET";
  const { content, icon, color } = statusToIconMap[status] || {};
  return (
    <InfoHelper
      className={"designApprovalStatusIcon " + className}
      onClick={() => {
        if (noClick) return;
        if (!activeSubmission) {
          if (noNewApprovalsAllowed) {
            return;
          }
          showDialog({
            modalType: "SubmitDesignForReviewDialog",
            modalProps: {
              design: { id },
              refetch
            }
          });
        } else {
          showDialog({
            ModalComponent: DesignReviewStatusDialog,
            modalProps: {
              id,
              selectedSubmissionId,
              showGoToDesign
            }
          });
        }
      }}
      content={content}
      icon={icon}
      clickable
      color={color}
      isInline
      displayToSide={withText}
      {...rest}
    />
  );
};
