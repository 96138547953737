/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SelectField } from "@teselagen/ui";

function InInventorySelectFilter({ name, tableParams, selectTableRecords }) {
  const inventoryFilterChange = inventoryFilter => {
    tableParams.setNewParams({
      ...tableParams.currentParams,
      inventoryFilter
    });
    selectTableRecords([]);
  };

  return (
    <SelectField
      className="tg-no-form-group-margin"
      name={name}
      defaultValue="Show All"
      options={["Show All", "In Inventory", "Not in Inventory"]}
      onFieldSubmit={inventoryFilterChange}
    />
  );
}

export default InInventorySelectFilter;
