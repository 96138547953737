/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, ButtonGroup } from "@blueprintjs/core";
import { useState } from "react";
import React from "react";

export function useButtonTabs(_tabList, { onChange = () => {}, tabId } = {}) {
  const tabList = _tabList.map(t =>
    typeof t === "string" ? { id: t, text: t } : t
  );
  const firstTab = tabList[0];
  const [selectedTabId, setTab] = useState(tabId || firstTab.id);

  let selectedTab;
  const tabs = (
    <ButtonGroup>
      {tabList.map((tab, i) => {
        const { id, text, buttonProps, ...rest } = tab;
        const active = id === selectedTabId;
        if (active) selectedTab = tab;
        return (
          <Button
            onClick={(...args) => {
              setTab(id);
              if (id !== selectedTabId) {
                onChange(id);
              }
              rest.onClick && rest.onClick(...args);
            }}
            intent={active ? "primary" : undefined}
            text={text}
            key={i}
            {...buttonProps}
          ></Button>
        );
      })}
    </ButtonGroup>
  );
  return {
    selectedTab,
    selectedTabId,
    setTab,
    tabs
  };
}
