/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getInputReactionOfCard,
  getOutputCardIdOfReaction,
  getOutputtingReactionIdOfCard,
  getNeighborBinId,
  getElementsInBin,
  getAllOfType,
  getOutputtingReactionOfCard,
  getItemOfType
} from "../../../tg-iso-design/selectors/designStateSelectors";

let debugARFLog = () => {};
if (window.frontEndConfig.logDesignARFActions) {
  debugARFLog = console.info;
}

export const getFirstNeighborPartId = (state, binId, cardId, isFivePrime) => {
  if (!binId || !cardId) return null;
  let cardIdToUse = cardId;
  let reaction = getInputReactionOfCard(state, cardId);
  if (reaction) {
    cardIdToUse = getOutputCardIdOfReaction(state, reaction.id);
  }
  if (!reaction) reaction = getOutputtingReactionIdOfCard(state, cardId);
  if (!reaction) {
    return null;
  }

  const neighborBinId = getNeighborBinId(
    state,
    cardIdToUse,
    binId,
    !isFivePrime
  );

  if (!neighborBinId) {
    return null;
  }

  const elementsInBin = getElementsInBin(state, neighborBinId) || [];

  const neighborPartElement = elementsInBin.find(el => !!el.partId);
  if (!neighborPartElement) {
    return null;
  }

  return neighborPartElement.partId;
};

export const getNeighborArfPartId = (state, binId, cardId, isFivePrime) => {
  if (!binId || !cardId) return null;
  let cardIdToUse = cardId;
  let reaction = getInputReactionOfCard(state, cardId);
  if (reaction) {
    cardIdToUse = getOutputCardIdOfReaction(state, reaction.id);
  }
  if (!reaction) reaction = getOutputtingReactionIdOfCard(state, cardId);
  if (!reaction) {
    return null;
  }

  const neighborBinId = getNeighborBinId(
    state,
    cardIdToUse,
    binId,
    !isFivePrime
  );

  if (!neighborBinId) {
    return null;
  }

  const allFas = Object.values(getAllOfType(state, "fas"));

  const arfEls = allFas.reduce((acc, fas) => {
    if (fas.name === "Assembly Ready Fragment") {
      acc[fas.elementId] = true;
    }
    return acc;
  }, {});

  const elementsInBin = getElementsInBin(state, neighborBinId) || [];

  const neighborArfElement = elementsInBin.find(el => !!arfEls[el.id]);

  if (!neighborArfElement) {
    return null;
  }

  return neighborArfElement.partId;
};

export const doesAdjacentBinHaveArf = (state, binId, cardId) => {
  if (!state.ui.designEditor.inspector.validateCompatibleParts) {
    return false;
  }
  if (!binId || !cardId) return false;
  let cardIdToUse = cardId;
  let reaction = getInputReactionOfCard(state, cardId);
  if (reaction) {
    cardIdToUse = getOutputCardIdOfReaction(state, reaction.id);
  }
  if (!reaction) reaction = getOutputtingReactionOfCard(state, cardId);
  if (!reaction) {
    return false;
  }

  const allFas = Object.values(getAllOfType(state, "fas"));

  const arfEls = allFas.reduce((acc, fas) => {
    if (fas.name === "Assembly Ready Fragment") {
      acc[fas.elementId] = true;
    }
    return acc;
  }, {});

  const fivePrimeNeighborBinId = getNeighborBinId(
    state,
    cardIdToUse,
    binId,
    false,
    true,
    true
  );

  if (fivePrimeNeighborBinId) {
    const elementsInFivePrimeBin = getElementsInBin(
      state,
      fivePrimeNeighborBinId
    );
    if (elementsInFivePrimeBin.some(el => !!arfEls[el.id])) return true;
  }

  const threePrimeNeighborBinId = getNeighborBinId(
    state,
    cardIdToUse,
    binId,
    true,
    true,
    true
  );

  if (threePrimeNeighborBinId) {
    const elementsInThreePrimeBin = getElementsInBin(
      state,
      threePrimeNeighborBinId
    );
    if (elementsInThreePrimeBin.some(el => !!arfEls[el.id])) return true;
  }

  return false;
};

export const getShowCompatiblePartsFilter = (state, binId, cardId) => {
  if (!binId || !cardId) return false;
  let reaction = getInputReactionOfCard(state, cardId);
  if (!reaction) reaction = getOutputtingReactionOfCard(state, cardId);
  if (!reaction) {
    return false;
  }
  const assemblyMethod = getItemOfType(
    state,
    "assemblyMethod",
    reaction.assemblyMethodId
  );

  return (
    assemblyMethod.cid === "gibson-slic-cpec" || assemblyMethod.cid === "digest"
  );
};

export const getArfJunctionBps = ({
  state,
  binId,
  cardId,
  isFivePrimeJunction,
  neighborPart,
  neighborArfPart
}) => {
  if (!state.ui.designEditor.inspector.validateCompatibleParts) {
    return;
  }
  if (!neighborPart && !neighborArfPart) {
    debugARFLog("didnt find neighborPart");
    return null;
  }
  if (!binId || !cardId) return null;
  let cardIdToUse = cardId;
  let reaction = getInputReactionOfCard(state, cardId);
  if (reaction) {
    cardIdToUse = getOutputCardIdOfReaction(state, reaction.id);
  }
  if (!reaction) reaction = getOutputtingReactionOfCard(state, cardId);
  if (!reaction) {
    debugARFLog("didnt find reaction");
    return null;
  }

  const neighborBinId = getNeighborBinId(
    state,
    cardIdToUse,
    binId,
    !isFivePrimeJunction
  );

  if (!neighborBinId) {
    debugARFLog("didnt find neighborBinId");
    return null;
  }

  const elementsInBin = getElementsInBin(state, neighborBinId);
  if (!elementsInBin) {
    debugARFLog("didnt find elementsInBin");
    return null;
  }

  const assemblyMethod = getItemOfType(
    state,
    "assemblyMethod",
    reaction.assemblyMethodId
  );

  if (!assemblyMethod) {
    debugARFLog("didnt find assemblyMethod");
    return null;
  }

  const customJ5Parameter = getItemOfType(
    state,
    "customJ5Parameter",
    reaction.customJ5ParameterId
  );

  if (!customJ5Parameter) {
    debugARFLog("didnt find customJ5Parameter");
    return null;
  }

  let junctionLength;
  if (assemblyMethod.cid === "gibson-slic-cpec") {
    junctionLength = customJ5Parameter.gibsonOverlapBps;
  } else if (assemblyMethod.cid === "golden-gate") {
    junctionLength = customJ5Parameter.ggateOverhangBps;
  } else {
    debugARFLog(
      "assembly method isn't gibson or golden gate, not supporting other assembly methods right now"
    );
    return null;
  }

  let junctionBps = null;

  if (neighborArfPart) {
    junctionBps = isFivePrimeJunction
      ? neighborArfPart.bpsDownStream.slice(0, junctionLength)
      : neighborArfPart.bpsUpStream.slice(-junctionLength);
  } else {
    junctionBps = isFivePrimeJunction
      ? neighborPart.bps3Prime.slice(-junctionLength)
      : neighborPart.bps5Prime.slice(0, junctionLength);
  }

  return junctionBps;
};

export const getJunctionBpsOfBin = ({ state, binId }) => {
  const junctions = Object.values(getAllOfType(state, "junction"));

  const threePrimeJunction = junctions.find(
    j => j.bps && j.fivePrimeCardEndBinId === binId
  );

  const fivePrimeJunction = junctions.filter(
    j => j.bps && j.threePrimeCardStartBinId === binId
  );

  return {
    fivePrimeBps: fivePrimeJunction ? fivePrimeJunction.bps : null,
    threePrimeBps: threePrimeJunction ? threePrimeJunction.bps : null
  };
};
