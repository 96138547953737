/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  InputField,
  DialogFooter,
  CheckboxField,
  wrapDialog
} from "@teselagen/ui";
import { compose } from "recompose";
import { getAliquotValuesForMaterial } from "../../../utils";
import GenericSelect from "../../../../src-shared/GenericSelect";
import UnitFields from "../../UnitFields";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import ManuallyAssignAliquotDialog from "../ManuallyAssignAliquotDialog";
import { pick } from "lodash";

class NewSampleAliquotDialog extends Component {
  onSubmit = async values => {
    const { history, refetch } = this.props;

    const {
      material = {},
      name,
      sampleStatus = {},
      isFermentedBatch,
      materials = []
    } = values;

    try {
      const valuesToPass = {
        ...values
      };
      if (isFermentedBatch || this.isMicrobialMaterial()) {
        delete valuesToPass.concentration;
      }
      const aliquotValues = await getAliquotValuesForMaterial({
        ...values,
        material: isFermentedBatch ? {} : material
      });
      let sample;
      if (isFermentedBatch) {
        sample = {
          name,
          sampleTypeCode: "FERMENTED_BATCH",
          sampleStatusCode: sampleStatus.code || null,
          sampleFormulations: [
            {
              ...pick(values, [
                "volume",
                "volumetricUnitCode",
                "mass",
                "massUnitCode"
              ]),
              materialCompositions: materials.map(m => {
                return { materialId: m.id };
              })
            }
          ]
        };
      } else {
        sample = {
          name,
          materialId: material.id,
          sampleTypeCode: "REGISTERED_SAMPLE",
          sampleStatusCode: sampleStatus.code || null
        };
      }
      const [aliquot] = await safeUpsert(["aliquot", "id sampleId"], {
        ...aliquotValues,
        sample
      });
      const { id: sampleAliquotId, sampleId } = aliquot;
      const [{ id }] = await safeUpsert("sample", {
        id: sampleId,
        sampleAliquotId
      });
      await refetch();

      showDialog({
        ModalComponent: ManuallyAssignAliquotDialog,
        modalProps: {
          aliquot,
          refetch: () => {
            history.push(`/samples/${id}`);
          }
        }
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating sample");
    }
  };

  isMicrobialMaterial() {
    const { material = {} } = this.props;
    return (
      material.materialTypeCode === "MICROBIAL" ||
      material.materialTypeCode === "CELL_CULTURE"
    );
  }

  render() {
    const {
      hideModal,
      handleSubmit,
      submitting,
      isDry,
      isFermentedBatch
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <h6 className="new-sample-aliquot-section-header">
            Sample Information
          </h6>
          <InputField
            label="Name"
            name="name"
            isRequired
            placeholder="Enter the name of the sample"
          />
          <CheckboxField label="Fermented Batch" name="isFermentedBatch" />
          {isFermentedBatch ? (
            <GenericSelect
              {...{
                name: "materials", //the field name within the redux form Field
                schema: [{ path: "name" }],
                isMultiSelect: true,
                asReactSelect: true,
                fragment: ["material", "id name materialTypeCode"],
                label: "Materials",
                reactSelectProps: {
                  placeholder: "Select materials..."
                }
              }}
            />
          ) : (
            <GenericSelect
              {...{
                name: "material", //the field name within the redux form Field
                schema: [{ path: "name" }],
                asReactSelect: true,
                fragment: ["material", "id name materialTypeCode"],
                label: "Material",
                reactSelectProps: {
                  placeholder: "Select a material..."
                }
              }}
            />
          )}

          <GenericSelect
            {...{
              name: "sampleStatus",
              idAs: "code",
              schema: [{ path: "name" }],
              asReactSelect: true,
              fragment: ["sampleStatus", "code name"],
              label: "Sample Status",
              reactSelectProps: {
                placeholder: "Select a status..."
              }
            }}
          />
          <h6 className="new-sample-aliquot-section-header">
            Aliquot Information
          </h6>
          <CheckboxField name="isDry" label="Is Dry?" />
          <UnitFields
            {...{
              volumeRequired: true,
              massRequired: true,
              concentrationTypes: [
                ...(this.isMicrobialMaterial() || isFermentedBatch
                  ? []
                  : ["concentration", "molarity"])
              ],
              concentrationRequired: true,
              isDry
            }}
          />
        </div>
        <DialogFooter submitting={submitting} hideModal={hideModal} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({
    title: "New Sample Aliquot"
  }),
  reduxForm({
    enableReinitialize: true,
    form: "newSampleAliquotForm"
  }),
  tgFormValues("isDry", "material", "isFermentedBatch")
)(NewSampleAliquotDialog);
