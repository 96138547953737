/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get } from "lodash";
import { compose } from "recompose";
import StepForm from "../../../../src-shared/StepForm";

import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import dataTableFragment from "../../../graphql/fragments/dataTableFragment";
import ReviewDataTable from "./ReviewDataTable";
import SelectMaterials, {
  microbialMaterialSelectionPlateFragment
} from "./SelectMaterials";
import {
  volumeRender,
  concentrationRender,
  massRender
} from "../../../../src-shared/utils/unitUtils";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class MicrobialMaterialSelectionTool extends Component {
  onSubmit = async values => {
    const { selectedAliquots, tags, dataTableName } = values;
    try {
      const dataRows = selectedAliquots.map(aliquot => {
        return {
          rowValues: {
            aliquotId: aliquot.id,
            materialName: get(aliquot, "sample.material.name"),
            strainName: get(aliquot, "sample.material.strain.name"),
            plateName: get(aliquot, "aliquotContainer.containerArray.name"),
            well: getAliquotContainerLocation(get(aliquot, "aliquotContainer")),
            volume: volumeRender(aliquot, undefined, { noJsx: true }),
            concentration: concentrationRender(aliquot, undefined, {
              noJsx: true
            }),
            mass: massRender(aliquot, undefined, { noJsx: true })
          }
        };
      });

      const outputDataTable = {
        name: dataTableName,
        dataTableTypeCode: "MICROBIAL_INVENTORY_MATERIALS",
        dataRows
      };
      const [createdDataTable] = await safeUpsert(
        "dataTable",
        addTaggedItemsBeforeCreate(outputDataTable, tags)
      );

      return {
        inventoryMicrobesList: createdDataTable
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error Creating Data Table.");
    }
  };

  validate = values => {
    const { dataTableName, containerArrays = [], dataTables = [] } = values;
    const errors = {};

    if (!containerArrays.length && !dataTables.length) {
      errors.containerArrays =
        "Must select at least one plate or list of inventory materials.";
      errors.dataTables =
        "Must select at least one plate or list of inventory materials.";
    }
    if (!dataTableName) {
      errors.dataTableName = "Must enter a data table name.";
    }
    return errors;
  };

  render() {
    const { toolIntegrationProps, toolSchema, initialValues } = this.props;

    const steps = [
      {
        title: "Select Materials",
        Component: SelectMaterials,
        withCustomFooter: true
      },
      {
        title: "Review Data Table",
        Component: ReviewDataTable
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        toolSchema={toolSchema}
        initialValues={initialValues}
        steps={steps}
        onSubmit={this.onSubmit}
        validate={this.validate}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(dataTableFragment),
  withWorkflowInputs(microbialMaterialSelectionPlateFragment)
)(MicrobialMaterialSelectionTool);
