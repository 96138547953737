/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty } from "lodash";
import has from "lodash/has";
import React from "react";
import { compose } from "recompose";
import shortid from "shortid";
import { withSelectedEntities, withSelectTableRecords } from "@teselagen/ui";
import { getSequence } from "../../../../../../tg-iso-shared/src/utils/getSequence";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import SequenceScoringTableAndSummary from "../../../SequenceScoringComponents/SequenceScoringTableAndSummary";
import { sequenceScoringTableName } from "../../../SequenceScoringComponents/SequenceScoringTable";
import { handleScoreUpdateAutoselect } from "../../../SequenceScoringComponents/utils";
import fieldConstants from "../fieldConstants";

const PARALLEL_SCORING_PAGE_SIZE = 100;

function formatSequenceForScoring(item) {
  return {
    id: item.id,
    name: item.name,
    circular: item.circular,
    sequence: getSequence(item)
  };
}

class ScoreAndPrice extends React.Component {
  componentDidMount() {
    const {
      sequencesToScore = [],
      scoringOptionsHash,
      customOptions = {}
    } = this.props;

    this.sequenceScoringMap = this.props.sequenceScoringMap || {};
    this.scoredSequenceMap = this.props.scoredSequenceMap || {};
    this.sequenceErrorMap = this.props.sequenceErrorMap || {};

    const needsToScore = sequencesToScore.some(s => {
      return (
        !this.scoredSequenceMap[s.id] &&
        !this.sequenceErrorMap[s.id] &&
        !this.sequenceScoringMap[s.id]
      );
    });

    const hashOfOptions = JSON.stringify(
      Object.keys(customOptions)
        .sort()
        .map(key => [key, customOptions[key]])
    );
    if (!scoringOptionsHash.current) {
      scoringOptionsHash.current = hashOfOptions;
    }
    if (needsToScore) {
      this.scoreSequences(sequencesToScore);
    } else {
      if (scoringOptionsHash.current !== hashOfOptions) {
        // custom options have changed. rescore
        scoringOptionsHash.current = hashOfOptions;
        this.scoreSequences(sequencesToScore);
      }
    }
  }

  componentDidUpdate() {
    handleScoreUpdateAutoselect(this);
  }

  changeScoringStatus = (sequence, scoring, scoringId) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const sequences = Array.isArray(sequence) ? sequence : [sequence];
    sequences.forEach(s => {
      if (scoring) {
        this.sequenceScoringMap[s.id] = scoringId;
      } else {
        delete this.sequenceScoringMap[s.id];
      }
    });
    change("sequenceScoringMap", { ...this.sequenceScoringMap });
  };

  scoringQueue = [];

  scoreSequences = async sequencesToScore => {
    const {
      idtIntegration,
      productType,
      customOptions,
      [fieldConstants.skipScoring]: skipScoring,
      userContext
    } = this.props;
    if (skipScoring) return;

    const formattedSequences = sequencesToScore.map(sequence => {
      const formatted = formatSequenceForScoring(sequence);
      return formatted;
    });
    try {
      const scoringEndpoint = idtIntegration.integrationEndpoints.find(
        e => e.endpointTypeCode === "VENDOR__SCORING"
      );
      const quoteEndpoint = idtIntegration.integrationEndpoints.find(
        e => e.endpointTypeCode === "VENDOR__QUOTE"
      );

      const scoringId = shortid();

      this.changeScoringStatus(formattedSequences, true, "waitingToScore");
      const wasEmpty = !this.scoringQueue.length;
      this.scoringQueue.push(...formattedSequences);
      if (!wasEmpty) {
        // already scoring from other call so that one will handle what we added here (hit rescore button)
        return;
      }
      while (this.scoringQueue.length > 0) {
        const groupToScore = this.scoringQueue.splice(
          0,
          PARALLEL_SCORING_PAGE_SIZE
        );
        this.changeScoringStatus(groupToScore, true, scoringId);
        await Promise.all(
          groupToScore.map(async sequence => {
            const result = {
              id: sequence.id
            };
            try {
              const {
                data: scoreResult
              } = await window.triggerIntegrationRequest({
                endpointId: scoringEndpoint.id,
                method: "POST",
                data: {
                  context: userContext,
                  productType,
                  options: customOptions,
                  sequence
                },
                headers: {
                  "x-int-req-timeout": 45000
                }
              });
              const { buildable, error, warning, alerts } = scoreResult;
              // this will either get back an error or an active scoring id
              // we need to use this id to poll for the sequences to finish
              // for the ones that errored immediately stop scoring loader
              result.error = error;
              if (alerts) {
                result.scoringInfo = {
                  alerts
                };
              }

              if (!error && !has(scoreResult, "buildable")) {
                result.error = "Invalid response. Must specify buildable.";
              }
              if (!result.error) {
                try {
                  const { data } = await window.triggerIntegrationRequest({
                    endpointId: quoteEndpoint.id,
                    method: "POST",
                    data: {
                      context: userContext,
                      productType,
                      options: customOptions,
                      sequence
                    }
                  });
                  if (data.error) {
                    result.error = data.error;
                  } else {
                    const price = data.price;
                    result.scoringInfo = {
                      buildable: buildable,
                      alerts,
                      warning,
                      price
                    };
                  }
                } catch (error) {
                  result.error = error.message;
                  console.error(`error in response from quote:`, error);
                }
              }
            } catch (error) {
              result.error = error.message;
              console.error(`error in response from start scoring:`, error);
            }
            this.finishScoringSequence(result, scoringId);
          })
        );
      }
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message);
    }
  };

  finishScoringSequence = (seq, scoringId) => {
    const {
      stepFormProps: { change }
    } = this.props;
    if (this.sequenceScoringMap[seq.id] !== scoringId) {
      return;
    }
    if (seq.scoringInfo) {
      this.scoredSequenceMap[seq.id] = seq.scoringInfo;
    }
    if (seq.error) {
      this.sequenceErrorMap[seq.id] = seq.error;
    } else {
      delete this.sequenceErrorMap[seq.id];
    }
    this.changeScoringStatus(seq, false, scoringId);
    change("sequenceErrorMap", { ...this.sequenceErrorMap });
    change("scoredSequenceMap", { ...this.scoredSequenceMap });
  };

  rescoreFailedSequences = () => {
    const { sequencesToScore = [] } = this.props;
    const failedSeqs = sequencesToScore.filter(
      s => this.sequenceErrorMap[s.id]
    );
    if (failedSeqs.length) {
      this.scoreSequences(failedSeqs);
    }
  };

  render() {
    const {
      sequencesToScore = [],
      sequenceScoringMap = {},
      sequenceErrorMap = {},
      scoredSequenceMap = {},
      [fieldConstants.skipScoring]: skipScoring,
      Footer,
      footerProps,
      [sequenceScoringTableName + "SelectedEntities"]: selectedSequences = [],
      handleSubmit,
      onSubmit
    } = this.props;

    const nextDisabled =
      !isEmpty(sequenceScoringMap) || !selectedSequences.length;

    let totalPrice = 0;
    selectedSequences.forEach(s => {
      const scoredInfo = scoredSequenceMap[s.id];
      if (scoredInfo && scoredInfo.price) {
        totalPrice += scoredInfo.price;
      }
    });

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <SequenceScoringTableAndSummary
            {...{
              sequenceErrorMap,

              sequenceScoringMap,
              scoredSequenceMap,
              sequencesToScore,
              scoreSequences: this.scoreSequences,
              rescoreFailedSequences: this.rescoreFailedSequences,
              selectedSequences,
              skipScoring,
              totalPrice,
              vendorCode: "IDT"
            }}
          />
        </div>
        <Footer
          {...footerProps}
          nextDisabled={nextDisabled}
          onNextClick={handleSubmit(values => {
            return onSubmit({
              ...values,
              sequencesToOrder: selectedSequences.map(seq => {
                let formatted = formatSequenceForScoring(seq);
                const scoredInfo = scoredSequenceMap[seq.id];
                if (scoredInfo) {
                  formatted = {
                    ...formatted,
                    ...scoredInfo
                  };
                }
                return formatted;
              })
            });
          })}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "selectAllSequences",
    fieldConstants.skipScoring,
    "sequencesToScore",
    "customOptions",
    "sequenceScoringMap",
    "sequenceErrorMap",
    "scoredSequenceMap",
    "orderName",
    "pricebook",
    "idtIntegration",
    "productType",
    "userContext"
  ),
  withSelectedEntities(sequenceScoringTableName),
  withSelectTableRecords(sequenceScoringTableName)
)(ScoreAndPrice);
