/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { noop } from "lodash";
import { deleteWithQuery, safeUpsert } from "../apolloMethods";
import { getExtendedValueModelKey } from "../utils/extendedPropertyUtils";
import { capitalizeModel } from "@teselagen/utils";

export const classCodeToValueModel = {
  VALUE: "extendedValue",
  CATEGORY: "extendedCategoryValue",
  MEASUREMENT: "extendedMeasurementValue",
  LINK: "extendedLinkValue"
};

export const mixedValueConstant = "MIXED_EXT_PROP_VALUE";

export async function submitAddExtendedProperty(
  values,
  {
    refetch = noop,
    model,
    recordId,
    recordIds,
    onSubmitValue,
    fullValueFields = false,
    reset = noop
  }
) {
  try {
    const getKey = partialKey =>
      fullValueFields ? partialKey : `${partialKey}Id`;
    const getValue = (partialKey, values) => values[getKey(partialKey)];

    const { extendedProperty } = values;
    const { extendedPropertyClassCode: classCode, targetModel } =
      extendedProperty;

    const val = values.value;

    const upsertValue = {
      value: val,
      [getKey("extendedProperty")]: fullValueFields
        ? extendedProperty
        : extendedProperty.id
    };

    if (classCode === "CATEGORY") {
      upsertValue[getKey("extendedCategory")] = getValue(
        "extendedCategory",
        values
      );
    } else if (classCode === "MEASUREMENT") {
      upsertValue.value = values.measurementValue;
      upsertValue[getKey("measurementUnit")] = getValue(
        "measurementUnit",
        values
      );
    } else if (classCode === "LINK") {
      upsertValue[targetModel + "Elvs"] = (values.targetEntities || []).map(
        ent => ({
          ["source" + capitalizeModel(targetModel) + "Id"]: ent.id
        })
      );
    }

    const valModel = classCodeToValueModel[classCode];
    const valsToInsert = [];
    if (onSubmitValue) {
      upsertValue.__typename = classCodeToValueModel[classCode];
      reset();
      return onSubmitValue(upsertValue);
    } else {
      const recordIdsToUse = recordIds || [recordId];
      await deleteWithQuery(valModel, {
        [model + "Id"]: recordIds,
        extendedPropertyId: extendedProperty.id
      });
      recordIdsToUse.forEach(id => {
        valsToInsert.push({
          ...upsertValue,
          [getExtendedValueModelKey(model)]: id
        });
      });
    }

    await safeUpsert(valModel, valsToInsert);
    await refetch();
    reset();
  } catch (e) {
    console.error(e);
    window.toastr.error("Error adding extended property.");
  }
}
