/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { pick } from "lodash";
import { reduxForm } from "redux-form";
import { BlueprintError, DateInputField, tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import UnitFields from "../../UnitFields";
import GenericSelect from "../../../../src-shared/GenericSelect";
import lotRecordFragment from "../../../graphql/fragments/lotRecordFragment";

import "./style.css";
import { withProps } from "recompose";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { getRecordViewFragment } from "../../../../src-shared/recordViewEnhancer";

const concentrationTypes = [
  "concentration",
  "molarity",
  "materialConcentration"
];

class CreateNewLotDialog extends Component {
  onSubmit = async values => {
    const { hideModal, history, materialIsLocked, isDry } = this.props;
    const {
      id: updateId,
      name,
      description,
      selectedAdditiveMaterial = {},
      concentrationType,
      concentration,
      molarity,
      materialConcentration,
      concentrationUnitCode,
      molarityUnitCode,
      materialConcentrationUnitCode,
      initialValues = {},
      expirationDate
    } = values;
    try {
      const valuesToUpsert = {
        id: updateId,
        name,
        expirationDate,
        description,
        ...pick(
          values,
          selectedAdditiveMaterial.isDry || isDry
            ? ["mass", "massUnitCode"]
            : ["volume", "volumetricUnitCode"]
        )
      };
      const concentrationChange =
        concentration !== initialValues.concentration ||
        concentrationUnitCode !== initialValues.concentrationUnitCode ||
        molarity !== initialValues.molarity ||
        molarityUnitCode !== initialValues.molarityUnitCode ||
        materialConcentration !== initialValues.materialConcentration ||
        materialConcentrationUnitCode !==
          initialValues.materialConcentrationUnitCode;

      if (
        concentrationType !== initialValues.concentrationType ||
        concentrationChange
      ) {
        const additives = await safeQuery(["additive", "id"], {
          variables: { filter: { lotId: updateId } }
        });
        if (additives.length > 0) {
          return window.toastr.error(
            `Cannot change concentration of a lot with associated additives.`
          );
        }
      }
      concentrationTypes.forEach(type => {
        valuesToUpsert[type] = null;
      });
      if (selectedAdditiveMaterial.isDry || isDry) {
        valuesToUpsert.mass = values.mass;
        valuesToUpsert.massUnitCode = values.massUnitCode;
      } else {
        valuesToUpsert.volume = values.volume;
        valuesToUpsert.volumetricUnitCode = values.volumetricUnitCode;
        if (values[concentrationType] > 0) {
          valuesToUpsert[concentrationType] = values[concentrationType];
          valuesToUpsert[`${concentrationType}UnitCode`] =
            values[`${concentrationType}UnitCode`];
        } else {
          valuesToUpsert.concentration = null;
        }
      }
      if (!materialIsLocked) {
        valuesToUpsert.additiveMaterialId = selectedAdditiveMaterial.id;
      }
      const [{ id }] = await safeUpsert(
        getRecordViewFragment(lotRecordFragment),
        valuesToUpsert
      );
      hideModal();
      !updateId && history.push(`/reagent-lots/${id}`);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(
        `Error ${updateId ? "updating" : "creating"} reagent lot`
      );
    }
  };

  isUpdate = () => {
    const { initialValues = {} } = this.props;
    return !!initialValues.id;
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      submitting,
      concentrationType,
      materialIsLocked,
      selectedAdditiveMaterial = {},
      currentUser,
      isDry,
      initialValues = {}
    } = this.props;

    const { additiveMaterial } = initialValues;
    let error;
    let additiveMaterialToCheck;
    if (this.isUpdate()) {
      additiveMaterialToCheck = additiveMaterial;
    } else {
      additiveMaterialToCheck = selectedAdditiveMaterial;
    }
    if (
      additiveMaterialToCheck &&
      !additiveMaterialToCheck.molecularWeight &&
      concentrationType === "molarity"
    ) {
      error = `Because the selected reagent does not have a molecular weight molarity cannot be calculated. Please update the reagent to include a molecular weight.`;
    }
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {!materialIsLocked && (
            <GenericSelect
              {...{
                name: "selectedAdditiveMaterial",
                asReactSelect: true,
                isRequired: true,
                label: "Reagent",
                schema: [
                  {
                    path: "name"
                  }
                ],
                fragment: [
                  "additiveMaterial",
                  "id name isDry additiveType { code name } molecularWeight"
                ]
              }}
            />
          )}
          <InputField
            label="Name"
            name="name"
            isRequired
            placeholder="Enter the name of the reagent lot."
            enableReinitialize
            defaultValue={
              selectedAdditiveMaterial.id
                ? selectedAdditiveMaterial.name + " Lot"
                : undefined
            }
          />
          <TextareaField
            label="Description"
            name="description"
            placeholder="Enter any additional information."
          />
          <UnitFields
            {...{
              isDry: isDry || selectedAdditiveMaterial.isDry,
              initialValues,
              concentrationTypes,
              volumeRequired: true
            }}
          />
          <DateInputField
            name="expirationDate"
            label="Expiration Date"
            maxDate={new Date("12/31/2100")}
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          disabled={error}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Create New Reagent Lot"
  }),
  reduxForm({
    form: "createNewLot"
  }),
  tgFormValues("selectedAdditiveMaterial", "concentrationType"),
  withProps(props => {
    return {
      selectedAdditiveMaterial: props.selectedAdditiveMaterial || undefined
    };
  }),
  withQuery(["additiveMaterial", "id name isDry additiveType { code name }"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(CreateNewLotDialog);
