/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { max } from "lodash";
import shortid from "shortid";

export default (schematic, pDesignCid) => {
  const designCid = pDesignCid || shortid();

  // NOTE: The order of these is very important.
  return [
    { entity: "design", inputs: createDesignInput(schematic, designCid) },
    { entity: "bin", inputs: createBinInputs(schematic, designCid) },
    { entity: "card", inputs: createCardInputs(schematic, designCid) }
  ];
};

const shapesToIconsMap = {
  rectangle: "USER-DEFINED",
  "left-arrow": "PROMOTER",
  "right-arrow": "PROMOTER",
  "left-cds": "CDS",
  "right-cds": "CDS",
  "left-terminator": "TERMINATOR",
  "right-terminator": "TERMINATOR"
};

const createDesignInput = (schematic, designCid) => ({
  cid: designCid,
  name: schematic.name,
  description: schematic.description || "",
  type: "grand-design",
  boundaryAnalysisType: "j5",
  numRows:
    max(schematic.schematicSets.map(s => s.schematicElements.length)) || 1, // design need to have at least 1 row
  layoutType: "combinatorial"
});

const createBinInputs = (schematic, designCid) => {
  const backboneSetInput = {
    cid: designCid + "-" + schematic.backboneSet.id,
    designId: "&" + designCid,
    name: schematic.backboneSet.name,
    direction: true,
    iconId: "&ORIGIN_OF_REPLICATION"
  };

  const schematicSets = schematic.schematicSets
    .filter(s => s.type !== "divider")
    .map(bin => ({
      cid: designCid + "-" + bin.id,
      designId: "&" + designCid,
      name: bin.name,
      direction: !bin.type.includes("left"),
      iconId: "&" + shapesToIconsMap[bin.type],
      elements: bin.schematicElements.map((el, index) => ({
        // elementId: '&' + designCid + '-element' + id,
        designId: "&" + designCid,
        index,
        name: el.name
      }))
    }));

  const concatenatedSet = [backboneSetInput].concat(schematicSets);
  return concatenatedSet;
};

function createCardInputs(schematic, designCid) {
  const backboneSet = {
    id: schematic.backboneSet.id
  };

  const schematicSets = schematic.schematicSets.filter(
    s => s.type !== "divider"
  );

  const concatenatedSet = [backboneSet].concat(schematicSets);

  return [
    {
      designId: "&" + designCid,
      isRoot: true,
      name: "Target Construct",
      circular: true,
      binCards: concatenatedSet.map((s, index) => ({
        designId: "&" + designCid,
        index,
        binId: "&" + designCid + "-" + s.id
      }))
    }
  ];
}
