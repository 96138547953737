/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import aliquotFormulationFragment from "./aliquotFormulationFragment";

/**
 * This function returns an array with all of the aliquot ids participating in
 * the aliquot formulations. This includes both source and destination aliquots.
 *
 * @param {Array<Object>} aliquotFormulations Serialized aliquot formulation JSONs.
 * @returns {Array<string>} All of the aliquots ids.
 */
function getAllAliquotIds(aliquotFormulations) {
  const idSet = {};
  for (const af of aliquotFormulations) {
    if (af.destination.aliquotId) {
      idSet[af.destination.aliquotId] = true;
    }
    for (const t of af.transfers) {
      if (!t.aliquotContainerId) {
        if (!t.aliquotId) {
          throw new Error("Transfer is missing an aliquot id.");
        }
        idSet[t.aliquotId] = true;
      }
    }
  }
  return Object.keys(idSet);
}

/**
 * Create a map from aliquot id to the full aliquot for all of the aliquots participating
 * in the aliquot formulations. The "full" aliquot is specified in the `aliquotFormulationFragment`.
 *
 * @param {Object} app
 * @param {Array<Object>} aliquotFormulations Serialized aliquot formulation JSONs.
 * @returns {Map<string, Object>} A map from aliquot id to the full aliquot.
 */
async function getIdToAliquotMap(aliquotFormulations, apolloMethods = {}) {
  const { safeQuery } = apolloMethods;
  const aliquotIds = getAllAliquotIds(aliquotFormulations);

  let aliquots = await safeQuery(aliquotFormulationFragment, {
    isPlural: true,
    variables: { pageSize: aliquotIds.length, filter: { id: aliquotIds } }
  });
  aliquots = aliquots.map(aliquot => {
    if (aliquot.cellCount && !aliquot.cellConcentration && aliquot.volume) {
      // we need cell concentration to be able to calculate new cell concentration after transfer
      // and it needs to be based off of the original volume
      return {
        ...aliquot,
        cellConcentration: aliquot.cellCount / aliquot.volume,
        cellConcentrationUnitCode: `cells/${aliquot.volumetricUnitCode}`
      };
    } else {
      return aliquot;
    }
  });

  return keyBy(aliquots, "id");
}

export default getIdToAliquotMap;
