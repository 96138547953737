/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../../../src-shared/MobxModal/Footer";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import { addMetaDataStore } from "./store";
import { FormGroup, Classes, Intent, Button } from "@blueprintjs/core";
import { upsertMiddleData } from "./addMiddleData";
import {
  renderFormGroup,
  renderInput,
  classesWithUnit,
  modelMap
} from "../utils";
import uuid from "uuid";
import QueryBuilder from "tg-client-query-builder";
import { size, isEmpty, camelCase } from "lodash";
import { BlueprintError, Loading } from "@teselagen/ui";

const AddMetaData = ({ mainStore: { modal } }) => {
  const store = useMemo(
    () =>
      addMetaDataStore.create({
        form: modal.props.get().form,
        model: modal.props.get().schema.model,
        existingData: modal.props.get().row ? modal.props.get().row[0] : null
      }),
    // eslint-disable-next-line
    []
  );

  const [classType, setClassType] = useState(store.form.get("class")?.value);
  const [subClassId, setSubClassId] = useState(
    store.form.get("subClass")?.value
  );

  useEffect(() => {
    if (store.model === "columnMap") {
      const classField = store.form.get("class");
      const subClassField = store.form.get("subClass");
      const unitField = store.form.get("unit");

      if (!isEmpty(classField.value) && classField.value !== classType) {
        setClassType(classField.value);
        subClassField.setValue("");
      } else if (
        !isEmpty(subClassField.value) &&
        subClassField.value !== subClassId
      ) {
        setSubClassId(subClassField.value);
        unitField.setValue("");
      }

      if (
        !isEmpty(classField.value) &&
        !isEmpty(subClassField.value) &&
        (isEmpty(unitField.dataset.data) ||
          subClassField.value !== subClassId) &&
        classesWithUnit.includes(classField.value)
      ) {
        const existingSubClass =
          store.existingData?.[modelMap[classField.value]];

        const subClassData = subClassField.dataset.data.find(
          opt => opt.value === subClassField.value
        );

        const dimensionId =
          existingSubClass?.unitDimensionId || subClassData?.unitDimensionId;

        const qb = new QueryBuilder("unit").whereAll({
          "unitScale.unitDimensionId": dimensionId
        });

        unitField?.setDataset({
          model: "unit",
          query: "id name unitScale {id unitDimensionId}",
          queryOptions: {
            variables: {
              filter: qb.toJSON()
            }
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [store.getValues.filter(f => f.key === "class" || f.key === "subClass")]);

  const saveData = async (store, modal) => {
    const isSimpleMode = !(
      window.localStorage.getItem("advancedMetadata") === "true"
    );
    try {
      if (
        isSimpleMode &&
        (store.model === "measurementType" || store.model === "unit")
      ) {
        upsertMiddleData(store).then(results => {
          switch (store.model) {
            case "measurementType":
              store.getValues.forEach(e => {
                if (e.key === "measurementTargetId") {
                  e.field.handleValue(results[0].id);
                }
              });
              break;
            case "unit":
              store.getValues.forEach(e => {
                if (e.key === "unitScaleId") {
                  e.field.handleValue(results[0].id);
                }
                if (e.key === "unitDimensionId") {
                  e.field.handleValue("");
                }
              });
              break;
            default:
              return undefined;
          }
          store.save(modal.props.get().refetch);
          modal.hideModal();
        });
      } else {
        await store.save(modal.props.get().refetch);
        modal.hideModal();
      }
    } catch (error) {
      store.setError(error);
    }
  };

  return (
    <React.Fragment>
      <div
        data-test={`tgDialog_${camelCase(modal.type)}`}
        className={classNames(Classes.DIALOG_BODY, "tg-flex auto")}
      >
        {store.fetching ? (
          <Loading inDialog />
        ) : (
          <div>
            {store.getValues.map((element, index) => {
              if (element.key === "externalSourceSystemId") {
                /**
                 * The "externalSourceSystemId" field depends on the "isExternalReference" type.
                 * That's why we need to a variable to store a reference of the latter, as the next line does.
                 */
                const isExternalReferenceFieldReference =
                  store.getValues.filter(
                    element => element.key === "isExternalReference"
                  )[0].field;
                const isExternalSourceSystem =
                  isExternalReferenceFieldReference.value;

                if (isExternalSourceSystem) {
                  element.field.makeRequired();
                } else {
                  element.field.makeNotRequired();
                }
                return renderFormGroup(element, isExternalSourceSystem);
              } else if (element.key === "subClass") {
                const classField = store.form.get("class");
                const selectedClass = classField.value;

                const isClassSelected = !isEmpty(selectedClass);

                if (
                  isClassSelected &&
                  (isEmpty(element.field.dataset.model) ||
                    element.field.dataset.model !== selectedClass)
                ) {
                  const model = selectedClass;

                  if (classesWithUnit.includes(model)) {
                    element.field.setDataset({
                      model,
                      query: "id name unitDimensionId"
                    });
                  } else {
                    element.field.setDataset({ model });
                  }
                }

                return renderFormGroup(element, isClassSelected);
              } else if (element.key === "unit") {
                const selectedClass = store.form.get("class");
                const selectedSubClass = store.form.get("subClass");

                const withUnit =
                  classesWithUnit.some(val => val === selectedClass.value) &&
                  !isEmpty(selectedSubClass.value);

                if (withUnit) {
                  element.field.makeRequired();
                } else {
                  element.field.makeNotRequired();
                  element.field.setDataset({ noFetch: true });
                }

                return (
                  <FormGroup
                    key={index}
                    intent={Intent.PRIMARY}
                    label={element.field.label}
                    labelInfo={element.field.required ? "(required)" : ""}
                    disabled={!withUnit}
                  >
                    {renderInput(element.field, !withUnit)}
                  </FormGroup>
                );
              } else {
                if (element.key.includes("columnMap")) {
                  const columnMaps = store.getKeys.filter(key =>
                    key.includes("columnMap")
                  );

                  if (size(columnMaps) > 1) {
                    element.field.makeRemovable();
                    element.field.makeNotRequired();
                  } else {
                    element.field.makeNotRemovable();
                    element.field.makeRequired();
                  }
                }
                return (
                  <FormGroup
                    key={index}
                    intent={Intent.PRIMARY}
                    label={element.field.label}
                    labelInfo={element.field.required ? "(required)" : ""}
                    style={{
                      display: element.field.isHidden ? "none" : "inherit"
                    }}
                  >
                    {renderInput(element.field)}
                  </FormGroup>
                );
              }
            })}
            {store.getKeys.some(key => key.includes("columnMap")) && (
              <Button
                minimal
                text="Add Column Mapper"
                intent={Intent.SUCCESS}
                icon="add"
                onClick={() =>
                  store.addField({
                    key: `columnMap${uuid()}`,
                    label: "Column Map",
                    type: "selectField",
                    value: "",
                    removable: true,
                    dataset: {
                      model: "columnMap",
                      query: "id name:columnName"
                    }
                  })
                }
              />
            )}
            <BlueprintError error={store.error} />
          </div>
        )}
      </div>
      <Footer
        onClick={() => saveData(store, modal)}
        disabled={store.disableCreate}
        loading={store.sending}
        text="Save"
      />
    </React.Fragment>
  );
};

export default inject("mainStore")(observer(AddMetaData));
