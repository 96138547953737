/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment jobUserFragment on jobUser {
    id
    userId
    user {
      id
      username
      email
      isDeactivated
    }
  }
`;
