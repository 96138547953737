/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Loading } from "@teselagen/ui";
import React, { ComponentType, Suspense, useRef } from "react";

export default function makeLazy(
  fn: () => Promise<{ default: ComponentType }>
) {
  return (props: any) => {
    const LazyComponent = useRef(React.lazy(fn));
    return (
      <Suspense fallback={<Loading bounce />}>
        <LazyComponent.current {...props} />
      </Suspense>
    );
  };
}
