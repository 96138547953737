/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { DataTable } from "@teselagen/ui";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import AddExtendedProperty from "../../../../src-shared/UpdateRecordExtendedPropertiesDialog/AddExtendedProperty";
import RecordExtendedPropertiesTable from "../../../../src-shared/UpdateRecordExtendedPropertiesDialog/RecordExtendedPropertiesTable";
import { transformExtendedValues } from "../../../../src-shared/utils/extendedPropertyUtils.js";

class UpdateExtendedProperties extends Component {
  saveExtendedPropertyToForm = (val, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(fieldName, [...existingValues, val]);
  };

  removeExtendedProperty = (record, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(
      fieldName,
      existingValues.filter(val => val.extendedProperty.id !== record.id)
    );
  };

  render() {
    const {
      discardSupernatant,
      existingExtendedProperties: {
        aliquot: extendedPropertiesOnAliquots = [],
        containerArray: extendedPropertiesOnPlates = []
      } = {},
      containerArrayExtendedValues = [],
      aliquotExtendedValues = []
    } = this.props;

    const containerArrayExtendedValuesEntities = transformExtendedValues(
      containerArrayExtendedValues
    );
    const aliquotExtendedValuesEntities = transformExtendedValues(
      aliquotExtendedValues
    );

    return (
      <React.Fragment>
        {(!!extendedPropertiesOnAliquots.length ||
          !!extendedPropertiesOnPlates.length) && (
          <div className="tg-step-form-section column">
            <React.Fragment>
              <HeaderWithHelper
                header="Remove Existing Extended Properties"
                helper="The selected plates and/or aliquots already have the
                  extended properties displayed below. Check the corresponding
                  box to delete any extended property upon tool submission."
                width="100%"
              />
              <div
                className="tg-flex justify-space-between"
                style={{ marginBottom: 20 }}
              >
                {!!extendedPropertiesOnPlates.length && (
                  <div style={{ width: "45%" }}>
                    <h6>Plates</h6>
                    <DataTable
                      entities={extendedPropertiesOnPlates}
                      schema={["name"]}
                      maxHeight={300}
                      destroyOnUnmount={false}
                      isSimple
                      withCheckboxes
                      compact
                      formName="plateExtendedPropertiesToRemove"
                    />
                  </div>
                )}
                <div className="tg-flex-separator" />
                {!!extendedPropertiesOnAliquots.length && (
                  <div style={{ width: "45%" }}>
                    <h6>Aliquots</h6>
                    <DataTable
                      entities={extendedPropertiesOnAliquots}
                      schema={["name"]}
                      maxHeight={300}
                      destroyOnUnmount={false}
                      isSimple
                      withCheckboxes
                      compact
                      formName="aliquotExtendedPropertiesToRemove"
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          </div>
        )}
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Add Extended Properties"
            helper={`Add extended properties to selected plates and
              aliquots below. An extended property of "Pellet"
              will be added to each aliquot. If supernatant is discarded,
              an extended property of "Aspirated" will be added to each plate.`}
            width="100%"
          />
          <div
            className="tg-flex justify-space-between"
            style={{ marginBottom: 20 }}
          >
            <div style={{ width: "45%", height: "100%" }}>
              <h6>Plates</h6>
              <AddExtendedProperty
                model="containerArray"
                fullValueFields
                noLinkProperties
                formName="addExtendedPropertiesSpinDownToolContainerArray"
                extendedPropertyIdsToIgnore={containerArrayExtendedValues.map(
                  val => val.extendedProperty.id
                )}
                onSubmitValue={val =>
                  this.saveExtendedPropertyToForm(val, "containerArray")
                }
              />
              {!!containerArrayExtendedValuesEntities.length && (
                <div style={{ minHeight: 200, marginTop: 20 }}>
                  <RecordExtendedPropertiesTable
                    isSimple
                    compact
                    noEdit
                    onDelete={record =>
                      this.removeExtendedProperty(record, "containerArray")
                    }
                    disableDelete={record => {
                      return (
                        discardSupernatant &&
                        record.extendedProperty.name.toLowerCase() ===
                          "aspirated"
                      );
                    }}
                    entities={containerArrayExtendedValuesEntities}
                  />
                </div>
              )}
            </div>
            <div className="tg-flex-separator" />
            <div style={{ width: "45%", height: "100%" }}>
              <h6>Aliquots</h6>
              <AddExtendedProperty
                model="aliquot"
                fullValueFields
                noLinkProperties
                formName="addExtendedPropertiesSpinDownToolAliquot"
                extendedPropertyIdsToIgnore={aliquotExtendedValues.map(
                  val => val.extendedProperty.id
                )}
                onSubmitValue={val =>
                  this.saveExtendedPropertyToForm(val, "aliquot")
                }
              />
              {!!aliquotExtendedValuesEntities.length && (
                <div style={{ minHeight: 200, marginTop: 20 }}>
                  <RecordExtendedPropertiesTable
                    isSimple
                    compact
                    noEdit
                    onDelete={record =>
                      this.removeExtendedProperty(record, "aliquot")
                    }
                    disableDelete={disableDeleteOfPelletProp}
                    entities={aliquotExtendedValuesEntities}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "containerArrays",
    "existingExtendedProperties",
    "containerArrayExtendedValues",
    "aliquotExtendedValues",
    "discardSupernatant"
  )
)(UpdateExtendedProperties);

function disableDeleteOfPelletProp(record) {
  return record.extendedProperty.name.toLowerCase() === "pellet";
}
