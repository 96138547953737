/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  SelectField,
  RadioGroupField,
  FileUploadField,
  DataTable,
  InputField,
  CheckboxField
} from "@teselagen/ui";

import { downloadTemplateFile, parseCsvAndSave } from "../utils";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import UnitFields from "../../../UnitFields";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { get } from "lodash";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";
import { allowedCsvFileTypes } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";

const plateTypeTableSchema = {
  fields: [
    { displayName: "Plate Name", path: "plateName" },
    {
      path: "plateMapGroup.name",
      displayName: "Plate Map"
    },
    {
      path: "plateType"
    },
    {
      path: "tubeType"
    }
  ]
};

class SelectPlateTypeAndContents extends Component {
  downloadTemplateFile = () => {
    const { plateMaps, overrideVolumeInfo } = this.props;
    const allMaterialPlateMaps = plateMaps.every(pm => pm.type === "material");
    const fields = {
      volume: "",
      "volume unit": "",
      concentration: "",
      "concentration unit": "",
      mass: "",
      "mass unit": ""
    };
    const missingMolecularWeight = plateMaps.some(pm => {
      return pm.plateMapItems.some(pmi => {
        const molecularWeight = get(
          pmi,
          "inventoryItem.material.polynucleotideMaterialSequence.molecularWeight"
        );
        return !molecularWeight;
      });
    });
    if (allMaterialPlateMaps && !missingMolecularWeight) {
      fields.molarity = "";
      fields["molarity unit"] = "";
    }

    let plateMapsToTemplate = this.props.plateMaps;
    if (!overrideVolumeInfo) {
      plateMapsToTemplate = plateMapsToTemplate.filter(pm => !pm.hasVolumeInfo);
    }
    downloadTemplateFile(
      plateMapsToTemplate,
      fields,
      "well_content_template_file"
    );
  };

  renderVolumeOrMassFields() {
    const { volumeAndConcentration, toolSchema } = this.props;
    const allMaterialPlateMaps = this.props.plateMaps.every(
      pm => pm.type === "material"
    );
    if (
      volumeAndConcentration === "plateWideVolume" ||
      volumeAndConcentration === "plateWideMass"
    ) {
      return (
        <React.Fragment>
          {volumeAndConcentration !== "upload" && (
            <UnitFields
              concentrationTypes={[
                "concentration",
                ...(allMaterialPlateMaps ? ["molarity"] : [])
              ]}
              isDry={volumeAndConcentration === "plateWideMass"}
              toolSchema={toolSchema}
              {...this.props}
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <FileUploadField
            label="Upload Well Contents Info File"
            fileLimit={1}
            accept={{
              type: allowedCsvFileTypes,
              exampleFile: this.downloadTemplateFile
            }}
            name="wellContentsInfoUpload"
            beforeUpload={parseCsvAndSave}
          />
        </div>
      );
    }
  }

  render() {
    const {
      overrideVolumeInfo,
      containerArrayTypes = [],
      selectedContainerArrayTypes = {},
      plateMaps = []
    } = this.props;

    const allHaveVolumeInfo = plateMaps.every(pm => pm.hasVolumeInfo);
    const someHasVolumeInfo = plateMaps.some(pm => pm.hasVolumeInfo);
    const onlyUseVolumeInfo = allHaveVolumeInfo && !overrideVolumeInfo;
    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Plate Name and Type"
            helper="Input a name and specify a plate type for each plate below."
          />
          <DataTable
            isSimple
            noForm
            selectedContainerArrayTypes={selectedContainerArrayTypes}
            schema={plateTypeTableSchema}
            entities={plateMaps}
            noSelect
            cellRenderer={{
              plateName: (v, record, row) => {
                return (
                  <InputField
                    name={`plateMapNames.${record.id}`}
                    isRequired
                    placeholder="Enter plate name..."
                    generateDefaultValue={{
                      ...defaultValueConstants.PLATE_NAME_FROM_PLATE_MAP,
                      customParams: {
                        plateMapName:
                          record.plateMapGroup?.name + " " + (row.index + 1)
                      }
                    }}
                  />
                );
              },
              plateType: (v, record) => {
                const filteredContainerArrayTypeOptions = arrayToItemValuedOptions(
                  containerArrayTypes.filter(
                    type =>
                      type.containerFormatCode ===
                      record.plateMapGroup.containerFormat.code
                  )
                );
                return (
                  <SelectField
                    name={`selectedContainerArrayTypes.id${record.id}`}
                    enableReinitialize
                    defaultValue={filteredContainerArrayTypeOptions[0].value}
                    options={filteredContainerArrayTypeOptions}
                  />
                );
              },
              tubeType: (v, record) => {
                const selectedContainerType =
                  selectedContainerArrayTypes["id" + record.id];
                if (selectedContainerType && !selectedContainerType.isPlate) {
                  const tubeTypeOptions = arrayToItemValuedOptions(
                    selectedContainerType.nestableTubeTypes,
                    { labelKey: "aliquotContainerType.name" }
                  );
                  return (
                    <SelectField
                      name={`selectedTubeTypes.id${record.id}`}
                      enableReinitialize
                      defaultValue={tubeTypeOptions[0].value}
                      options={tubeTypeOptions}
                    />
                  );
                } else {
                  return "N/A";
                }
              }
            }}
          />
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Plate Contents"
            helper={
              "Specify the contents of the plates you would like to register. Either specify a plate-wide volume and concentration, a plate-wide mass (for dry plates), or upload a CSV to specify volume, concentration and mass for each individual well." +
              (someHasVolumeInfo
                ? " Check the override box if you would like to ignore existing volume information on input plate maps."
                : "")
            }
          />
          <div>
            {someHasVolumeInfo && (
              <CheckboxField
                name="overrideVolumeInfo"
                label="Override Existing Volume Information"
              />
            )}
            {!onlyUseVolumeInfo && (
              <div className="plate-registration-volume-and-concentration">
                <RadioGroupField
                  options={[
                    {
                      label: "Set plate-wide volume and concentration",
                      value: "plateWideVolume"
                    },
                    {
                      label: "Set plate-wide mass",
                      value: "plateWideMass"
                    },
                    {
                      label:
                        "Upload well volume and concentration (or mass) CSV",
                      value: "upload"
                    }
                  ]}
                  name="volumeAndConcentration"
                  defaultValue="plateWideVolume"
                />
                {this.renderVolumeOrMassFields()}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    "overrideVolumeInfo",
    "volumeAndConcentration",
    "plateMaps",
    "selectedContainerArrayTypes",
    "containerArrayTypes"
  )
)(SelectPlateTypeAndContents);
