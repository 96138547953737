/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import {
  NumericInputField,
  RadioGroupField,
  CheckboxField
} from "@teselagen/ui";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";

import {
  annotationSizeStartEndColumns,
  dateModifiedColumn
} from "../../../../../src-shared/utils/libraryColumns";
import { inRange } from "../../../../../src-shared/utils/formUtils";
import {
  sequenceFeatureFragment,
  sequenceFeatureWithSequenceFragment
} from "./fragments.gql";
import type {
  GenomeFragment,
  GenomeGenomicRegionFragment,
  GuideRnaFragment,
  SequenceFeatureFragment,
  SequenceFeatureWithSequenceFragment
} from "./fragments.gql.generated";
import type { RecursiveRequired } from "../../../../../src-shared/typescriptHelpers";
import QueryBuilder from "tg-client-query-builder";
import { Link } from "react-router-dom";

type FullGenomeFragment = RecursiveRequired<GenomeFragment>;
type FullGenomeGenomicRegionFragment =
  RecursiveRequired<GenomeGenomicRegionFragment>;
type FullGuideRnaFragment = RecursiveRequired<GuideRnaFragment>;
type FullSequenceFeatureFragment = RecursiveRequired<SequenceFeatureFragment>;
type FullSequenceFeatureWithSequenceFragment =
  RecursiveRequired<SequenceFeatureWithSequenceFragment>;

export enum HomologyArmsMode {
  FromHA = "fromHA",
  FromTargetRegion = "fromTargetRegion"
}

const fieldsStyle = { minWidth: 450, marginRight: 20 };

const strandColumn = {
  displayName: "Strand",
  path: "strand",
  render: (v: FullSequenceFeatureFragment["strand"]) =>
    v === 1 ? "Positive" : "Negative"
};

const FromHaForm = ({
  genome,
  genomicRegion,
  guideRna
}: {
  genome: FullGenomeFragment;
  genomicRegion: FullGenomeGenomicRegionFragment["genomicRegion"];
  guideRna: FullGuideRnaFragment;
}) => {
  // Take the cutsite for the guideRnaScore associated to the same genome, genomic Region
  const cutSite = guideRna.rnaSequenceGuideRnaScores.find(
    score =>
      score.guideRnaRun.genomeId === genome.id &&
      score.guideRnaRun.genomicRegionId === genomicRegion.id
  )!.cutSite;

  const displayCutSite = cutSite + 1;

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select HA 5' Prime"
          helper={`Select the 5' homology arm. The only constraint is that the end of the homology arm must be to the left of the cut site (position: ${displayCutSite})`}
        />
        <GenericSelect
          nameOverride="Homology Arm 5'"
          name="homologyArm5Prime"
          schema={[
            "name",
            ...annotationSizeStartEndColumns,
            strandColumn,
            dateModifiedColumn
          ]}
          fragment={sequenceFeatureFragment}
          isRequired
          tableParamOptions={{
            additionalFilter: (_props: any, qb: QueryBuilder) => {
              qb.whereAll({
                sequenceId: genomicRegion.id,
                end: qb.lessThan(cutSite)
              });
            }
          }}
          postSelectDTProps={{
            formName: "selectedHA5Prime",
            schema: [
              "name",
              ...annotationSizeStartEndColumns,
              strandColumn,
              dateModifiedColumn
            ]
          }}
          style={fieldsStyle}
        />
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select HA 3' Prime"
          helper={`Select the 3' homology arm. The only constraint is that the start of the homology arm must be to the right of the cut site (position: ${displayCutSite})`}
        />
        <GenericSelect
          nameOverride="Homology Arm 3'"
          name="homologyArm3Prime"
          placeholder="Select plate type..."
          schema={[
            "name",
            ...annotationSizeStartEndColumns,
            strandColumn,
            dateModifiedColumn
          ]}
          fragment={sequenceFeatureFragment}
          tableParamOptions={{
            additionalFilter: (_props: any, qb: QueryBuilder) => {
              qb.whereAll({
                sequenceId: genomicRegion.id,
                start: qb.greaterThan(cutSite)
              });
            }
          }}
          postSelectDTProps={{
            formName: "selectedHA3Prime",
            schema: [
              "name",
              ...annotationSizeStartEndColumns,
              strandColumn,
              dateModifiedColumn
            ]
          }}
          isRequired
          style={fieldsStyle}
        />
      </div>
    </>
  );
};

const FromTargetRegionForm = ({
  genome,
  genomicRegion,
  guideRna,
  targetRegion
}: {
  genome: FullGenomeFragment;
  genomicRegion: FullGenomeGenomicRegionFragment["genomicRegion"];
  guideRna: FullGuideRnaFragment;
  targetRegion: FullSequenceFeatureFragment;
}) => {
  const maxhomologyArmsSize = useMemo(() => {
    // Both arms have to be same lenght, and constrained by the available space left of the target region with respect to the genomic Region sequence
    if (!targetRegion) return undefined;
    const minimumHASize = Math.min(
      targetRegion.start,
      genomicRegion.size - targetRegion.end - 1
    );

    return minimumHASize;
  }, [targetRegion, genomicRegion.size]);

  // Take the cutsite for the guideRnaScore associated to the same genome, genomic Region
  const cutSite = guideRna.rnaSequenceGuideRnaScores.find(
    score =>
      score.guideRnaRun.genomeId === genome.id &&
      score.guideRnaRun.genomicRegionId === genomicRegion.id
  )!.cutSite;

  const displayCutSite = cutSite + 1;
  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Target Region"
          helper={`Select the target region, it must contain the cut site in position: ${displayCutSite}`}
        />
        <GenericSelect
          nameOverride="Target Region"
          name="targetRegion"
          schema={[
            "name",
            ...annotationSizeStartEndColumns,
            strandColumn,
            dateModifiedColumn
          ]}
          fragment={sequenceFeatureFragment}
          isRequired
          tableParamOptions={{
            additionalFilter: (_props: any, qb: QueryBuilder) => {
              qb.whereAll({
                sequenceId: genomicRegion.id,
                start: qb.lessThan(
                  guideRna.rnaSequenceGuideRnaScores[0].cutSite
                ),
                end: qb.greaterThan(
                  guideRna.rnaSequenceGuideRnaScores[0].cutSite
                )
              });
            }
          }}
          postSelectDTProps={{
            formName: "selectedTargetRegion",
            schema: [
              "name",
              ...annotationSizeStartEndColumns,
              strandColumn,
              dateModifiedColumn
            ]
          }}
          style={fieldsStyle}
        />
      </div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Homology Arm Length (bps)"
            helper="Select the homology arms length, they will be constrained to the available space on the genomic region after selecting the target region"
          />
          <NumericInputField
            name="homologyArmsSize"
            label="Homology Arms Length"
            min={maxhomologyArmsSize ? 1 : 0}
            max={maxhomologyArmsSize}
            tooltipInfo={`Max size: ${
              maxhomologyArmsSize ??
              "select a target region to set the max size"
            }`}
            style={fieldsStyle}
            normalize={inRange([1, maxhomologyArmsSize], { integer: true })}
          />
        </div>
      </div>
    </>
  );
};

const SelectContentInner = ({
  homologyArmsMode,
  targetRegion,
  genome,
  genomeGenomicRegion,
  guideRna
}: {
  homologyArmsMode: HomologyArmsMode;
  targetRegion: FullSequenceFeatureFragment;
  genome: FullGenomeFragment;
  genomeGenomicRegion: FullGenomeGenomicRegionFragment;
  guideRna: FullGuideRnaFragment;
}) => {
  const genomicRegion = genomeGenomicRegion.genomicRegion;
  return (
    <>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Homology Arms selection mode"
            helper="Select a mode on how to select the homology arms. The options are to use existing homology arms, which are features on the genomic region, or to create new homology arms inside of the genomic region. The first option will automatically select the target region while the last option will require to select it manually."
          />
          <RadioGroupField
            label="Homology Arms selection mode"
            name="homologyArmsMode"
            options={[
              { label: "Select existing Homology Arms", value: "fromHA" },
              {
                label: "Create Homology Arms from target region",
                value: "fromTargetRegion"
              }
            ]}
            defaultValue="fromHA"
          />
        </div>
      </div>
      {homologyArmsMode === "fromHA" ? (
        <FromHaForm
          genome={genome}
          genomicRegion={genomicRegion}
          guideRna={guideRna}
        />
      ) : (
        <FromTargetRegionForm
          genome={genome}
          targetRegion={targetRegion}
          genomicRegion={genomicRegion}
          guideRna={guideRna}
        />
      )}
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="HDR Donor content"
          helper="Select the HDR donor content. This is, the content that will replace the target region in the genomic region."
        />
        <GenericSelect
          nameOverride="HDR Donor content"
          name="hdrDonorContent"
          schema={[
            "name",
            ...annotationSizeStartEndColumns,
            {
              displayName: "Parent sequence",
              path: "sequence",
              render: (
                v: FullSequenceFeatureWithSequenceFragment["sequence"]
              ) => <Link to={modelNameToLink(v)}>{v.name}</Link>
            },
            strandColumn,
            dateModifiedColumn
          ]}
          fragment={sequenceFeatureWithSequenceFragment}
          isRequired
          postSelectDTProps={{
            formName: "selectedHDRDonorContent",
            schema: [
              "name",
              ...annotationSizeStartEndColumns,
              strandColumn,
              dateModifiedColumn
            ]
          }}
          style={fieldsStyle}
        />
      </div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Create output genomic region"
            helper="If set, the tool will automatically create an edited version of the genomic region, where the target region is replaced by the HDR donor content."
          />
          <CheckboxField
            name="createOutputGenomicRegion"
            label="Create output genomic region"
            style={fieldsStyle}
            defaultValue={false}
          />
        </div>
      </div>
    </>
  );
};

const SelectContent = stepFormValues(
  "genome",
  "genomeGenomicRegion", // selected in previous step
  "guideRna", // selected in previous step
  "homologyArmsMode",
  "homologyArm5Prime",
  "homologyArm3Prime",
  "targetRegion",
  "homologyArmsSize",
  "hdrDonorContent",
  "createOutputGenomicRegion"
)(SelectContentInner);

export { SelectContent };
