/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { convertVolumeBig } from "../../../utils/unitUtils";
import linkToAliquotContainer from "./linkToAliquotContainer";

/**
 * Given an aliquot that is participating in the formulation, create the value to upsert
 * to create the sample formulation entry for the aliquot. The aliquot's volume should only
 * be the volume that is participating in the transfer, not the aliquot's entire volume.
 *
 * The function should only be used to provide the objects in the `sampleFormulations`
 * array when upserting a sample. The sample formulation is missing a `formulatedSampleId`.
 * @param {Object} aliquot Aliquot with fields from `aliquotFormulationFragment`
 * @param {string} volumetricUnitCode The volumetric unit code to express the transfers in.
 * @param {string} userId
 * @return {Object}
 */
function getSampleFormulationUpsertValue(aliquot, volumetricUnitCode, userId) {
  const {
    sample: { sampleTypeCode, sampleFormulations, materialId }
  } = aliquot;

  const materialSet = [];

  if (sampleTypeCode === "REGISTERED_SAMPLE" && materialId) {
    // If this is a registered sample with a material, its
    // only link to materials is through a direct link via `materialId`.
    materialSet[materialId] = true;
  } else if (sampleTypeCode === "ISOLATED_SAMPLE" && materialId) {
    // If this is a isolated sample with a material, its
    // only link to materials is through a direct link via `materialId`.
    materialSet[materialId] = true;
  } else if (
    sampleTypeCode === "FORMULATED_SAMPLE" ||
    sampleTypeCode === "FERMENTED_BATCH"
  ) {
    // If this is a formulated sample, add all of the materials
    // linked via the aliquot's sample formulations.
    for (const sf of sampleFormulations) {
      if (materialId) {
        // if the sample is directly linked to a material, that material
        // will be added to the material composition array alone
        materialSet[materialId] = true;
      } else {
        // if the sample is not directly linked to a material, the
        // materials inside of its material composition array will
        // be added to the output sample's material compositions array
        for (const mc of sf.materialCompositions) {
          if (mc.materialId) {
            materialSet[mc.materialId] = true;
          }
        }
      }
    }
  }

  return {
    userId,
    aliquotId: aliquot.id,
    concentration: aliquot.concentration,
    concentrationUnitCode: aliquot.concentrationUnitCode,
    volume: aliquot.volume
      ? Number(
          convertVolumeBig(
            aliquot.volume,
            aliquot.volumetricUnitCode,
            volumetricUnitCode
          )
        )
      : 0,
    volumetricUnitCode,
    materialCompositions: Object.keys(materialSet).map(id => ({
      materialId: id,
      userId
    }))
  };
}

/**
 * Given a formulation event aliquot formulation to execute, add the required
 * upserts to a write buffer.
 *
 * This function assumes that the `fullFormulation` is a formulation event. It does not
 * check this, so be careful.
 *
 * This function only deals with updates to the destination; it does not affect the source
 * aliquots.
 * @param {FullFormulation} fullFormulation Must be a formulation event.
 * @param {Object} options
 * @param {WriteBuffer} writeBuffer
 * @returns {string} The id of the destination aliquot.
 */
function executeFormulationEvent(fullFormulation, options, writeBuffer) {
  const { userId } = options;
  const volumetricUnitCode = fullFormulation.getVolumetricUnitCode();

  // Remember that the volumes of these aliquots will only be the
  // volume that participates in the transfer.
  const aliquots = fullFormulation.getAllAliquots();
  const pendingSamplePoolId = fullFormulation.getPendingSamplePoolId();
  const originalAliquotId = fullFormulation.getAliquotId() || undefined;

  let sampleUpsert;
  if (pendingSamplePoolId) {
    sampleUpsert = {
      id: pendingSamplePoolId,
      sampleTypeCode: "FORMULATED_SAMPLE",
      sampleAliquotId: originalAliquotId //what if there is no original aliquot at destination?
    };
    writeBuffer.upsert(
      "sampleFormulation",
      aliquots.map(a => {
        // Use the same volumetric unit code for consistency reasons.
        return {
          ...getSampleFormulationUpsertValue(a, volumetricUnitCode, userId),
          formulatedSampleId: pendingSamplePoolId
        };
      })
    );
  } else {
    sampleUpsert = {
      userId,
      sampleTypeCode: "FORMULATED_SAMPLE",
      // does this need sample aliquot?
      name: fullFormulation.getSampleName(),
      sampleFormulations: aliquots.map(a =>
        // Use the same volumetric unit code for consistency reasons.
        getSampleFormulationUpsertValue(a, volumetricUnitCode, userId)
      )
    };
  }
  const [{ id: sampleId }] = writeBuffer.upsert("sample", sampleUpsert);
  const [{ id: aliquotId }] = writeBuffer.upsert("aliquot", {
    id: originalAliquotId,
    // Do not (potentially) change the userId of existing aliquots
    ...(!originalAliquotId && { userId: options.userId }),
    sampleId,
    aliquotType: `sample-aliquot`,
    concentration: fullFormulation.getConcentration(),
    concentrationUnitCode: fullFormulation.getConcentrationUnitCode(),
    ...fullFormulation.getCellConcentrationFields(),
    molarity: fullFormulation.getMolarity(),
    molarityUnitCode: fullFormulation.getMolarityUnitCode(),
    volume: fullFormulation.getVolume(),
    volumetricUnitCode: fullFormulation.getVolumetricUnitCode(),
    isDry: fullFormulation.isDry()
  });
  if (!originalAliquotId && !pendingSamplePoolId) {
    writeBuffer.updateAfter("sample", {
      id: sampleId,
      sampleAliquotId: aliquotId
    });
  }
  // Add the destination aliquot to the aliquot container if one is provided.
  linkToAliquotContainer(fullFormulation, aliquotId, writeBuffer);

  return aliquotId;
}

export default executeFormulationEvent;
