/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { plateLibraryFilter } from "../../../utils/plateUtils";
import { CheckboxField, ReactSelectField } from "@teselagen/ui";
import stepFormValues from "../../../../src-shared/stepFormValues";
import gql from "graphql-tag";

export const plateFragment = [
  "containerArray",
  "id name barcode { id barcodeString } containerArrayType { id name isPlate } updatedAt"
];

export const exportPlatesIntegrationFragment = gql`
  fragment exportPlatesIntegrationFragment on integration {
    id
    name
    integrationTypeCode
    integrationEndpoints {
      id
      endpointTypeCode
    }
  }
`;

const ExportPlates = props => {
  const {
    useIntegration,
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
  } = props;

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Plates"
          helper="Select one or more plates to export."
        />
        <div>
          <GenericSelect
            {...{
              name: "containerArrays",
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              isMultiSelect: true,
              isRequired: true,
              schema: [
                {
                  path: "name"
                },
                {
                  displayName: "Barcode",
                  path: "barcode.barcodeString"
                },
                dateModifiedColumn
              ],
              tableParamOptions: {
                additionalFilter: plateLibraryFilter
              },
              fragment: plateFragment,
              postSelectDTProps: {
                formName: "movePlateToolSelectPlates",
                schema: [
                  platePreviewColumn(),
                  "name",
                  {
                    displayName: "Barcode",
                    path: "barcode.barcodeString"
                  },
                  dateModifiedColumn
                ]
              }
            }}
          />
          <div style={{ maxWidth: 250 }}>
            <CheckboxField name="useIntegration" label="Use Integration" />
            {useIntegration ? (
              <GenericSelect
                {...{
                  name: "integration",
                  isRequired: true,
                  asReactSelect: true,
                  label: "Export Plate Integration",
                  fragment: ["integration", "id name"],
                  additionalDataFragment: exportPlatesIntegrationFragment,
                  additionalFilter: {
                    integrationTypeCode: "EXPORT_CUSTOM_PLATE"
                  }
                }}
              />
            ) : (
              <ReactSelectField
                label="Export Format"
                name="exportFormat"
                options={[
                  { value: "defaultCsv", label: "Default CSV" },
                  { value: "listCsv", label: "List CSV" },
                  { value: "plateLayoutCsv", label: "Plate Layout CSV" }
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default stepFormValues("useIntegration")(ExportPlates);
