/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const rowIndexToLetter = index => {
  let num = index;
  let letterString = "";
  while (num > 25) {
    letterString += ((num % 26) + 10).toString(36).toUpperCase();
    num = num - 26;
  }
  letterString += (num + 10).toString(36).toUpperCase();
  return letterString;
};
