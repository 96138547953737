/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get } from "lodash";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import { Link } from "react-router-dom";
import {
  biosafetyDisplay,
  inductionMethodsDisplay,
  targetOrganismDisplay
} from "../../DisplayGrowthConditions";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

function StrainRecordInfo({ strain, material, readOnly }) {
  if (strain) {
    const { name, strainTypeCode, genotype, description, specie } = strain;

    let parentLink, originatingStrainLink;

    if (strain.sourceMicrobialMaterial) {
      const parent = strain.sourceMicrobialMaterial;
      parentLink = (
        <Link to={`/microbial-materials/${parent.id}`}>{parent.name}</Link>
      );
      if (parent.strain) {
        originatingStrainLink = (
          <Link to={`/microbial-strains/${parent.strain.id}`}>
            {parent.strain.name}
          </Link>
        );
      }
    } else if (strain.sourceCellCulture) {
      const parent = strain.sourceCellCulture;
      parentLink = (
        <Link to={`/cell-cultures/${parent.id}`}>{parent.name}</Link>
      );
      if (parent.strain) {
        originatingStrainLink = (
          <Link to={`/cell-lines/${parent.strain.id}`}>
            {parent.strain.name}
          </Link>
        );
      }
    }

    const recordInfo = [
      ["Name", name],
      ["Genotype", genotype],
      ["Description", description],
      [
        "Species",
        specie && specie.abbreviatedName && (
          <i key="specie">{specie.abbreviatedName}</i>
        )
      ],
      specie
        ? [
            "Genus and Species",
            get(specie, "genus.name", "") + " " + specie.name
          ]
        : null,
      ["Target Organism Group", targetOrganismDisplay({ strain, material })],
      [
        "Induction Methods",
        inductionMethodsDisplay({
          material,
          strain
        })
      ],
      strain.genome
        ? [
            "Genome",
            <Link key="genome" to={modelNameToLink(strain.genome)}>
              {strain.genome.name}
            </Link>
          ]
        : null,
      ["Biosafety Level", biosafetyDisplay({ strain, material })],
      [
        strainTypeCode === "MICROBIAL_STRAIN"
          ? "Parent Microbial Material"
          : "Parent Cell Culture",
        parentLink
      ],
      [
        strainTypeCode === "MICROBIAL_STRAIN"
          ? "Originating Strain"
          : "Originating Cell Line",
        originatingStrainLink
      ]
    ].filter(info => info && info[1]);
    return (
      <div className="record-info-container">
        <RecordInfoDisplay
          recordInfo={recordInfo}
          readOnly={readOnly}
          record={strain}
          noFill
        />
      </div>
    );
  }
}

export function strainLink(strain) {
  return (
    <Link
      style={{ paddingBottom: 9, paddingLeft: 11 }}
      to={
        strain.strainTypeCode === "MICROBIAL_STRAIN"
          ? `/microbial-strains/${strain.id}`
          : `/cell-lines/${strain.id}`
      }
    >
      {strain.strainTypeCode === "MICROBIAL_STRAIN"
        ? "Open Strain"
        : "Open Cell Line"}
    </Link>
  );
}

export default StrainRecordInfo;
