/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { handleActions } from "redux-actions";
import actions from "../../redux-shared/actions";

const initialState = {
  dark: localStorage.getItem("darkMode") === "true"
};

export default handleActions(
  {
    [actions.platform.ui.theme.toggle]: ({ dark }) => {
      // persist to localStorage
      window.localStorage.setItem("darkMode", !dark);
      return { dark: !dark };
    }
  },
  initialState
);
