/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";
import withQuery from "../../../../src-shared/withQuery";

class SampleHistoryCard extends Component {
  render() {
    const { tableParams } = this.props;
    return (
      <div>
        <CollapsibleCard title="Sample History">
          <DataTable
            {...tableParams}
            noPadding
            className="sample-history-card"
            onDoubleClick={routeDoubleClick}
          />
        </CollapsibleCard>
      </div>
    );
  }
}

export const sampleHistorySchema = {
  model: "sample",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Sample Type",
      path: "sampleType.name"
    },
    {
      displayName: "Sample Status",
      path: "sampleStatus.name"
    }
  ]
};

export default compose(
  withTableParams({
    formName: "sampleHistory",
    urlConnected: false,
    additionalFilter: (props, qb) => {
      qb.whereAll({
        sampleAliquotId: props.aliquot.id
      });
    },
    schema: sampleHistorySchema
  }),
  withQuery(
    ["sample", `id name sampleStatus { code name } sampleType { code name }`],
    {
      isPlural: true
    }
  )
)(SampleHistoryCard);
