/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getCommandHotkeys,
  getCommandHotkeyHandlers,
  withHotkeys
} from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";
import { compose } from "redux";
import { DndProvider } from "react-dnd";
import MouseBackEnd from "react-dnd-mouse-backend";
import DesignInspector from "../../containers/DesignInspectorContainer";

import SbolIconHeader from "../SbolIconHeader";
import { socketWrapper } from "../../../src-shared/socket/socketWrapper";
import { query, safeQuery } from "../../../src-shared/apolloMethods";
import { getNumberOfBinsInRootCard } from "../../../../tg-iso-design/selectors/designStateSelectors";
import store from "../../../src-shared/redux/store";
import TopMenu from "./TopMenu";
import editorHotkeys from "./hotkeys";
import { getCommands } from "./commands";
import SbolDragLayer from "./SbolDragLayer";
import iconFragment from "../../../../tg-iso-design/graphql/fragments/iconFragment";
import "./style.css";
import "./gridStyles.css";

import ClassicGrid from "./ClassicGrid";
import TreeView from "./TreeView";
import { DesignEditorProvider } from "./DesignEditorContext";
import {
  HORIZONTAL_MINIMAP_THICKNESS,
  VERTICAL_MINIMAP_THICKNESS
} from "../../../src-shared/components/HierarchicalDesign/constants";
import LoadingOrNotFound from "../../../src-shared/components/LoadingOrNotFound";
import appGlobals from "../../../src-shared/appGlobals";

/**
 * This is the period used for the interval that
 * periodically checks if the opened design got its lock removed
 */
const EDIT_LOCK_REMOVED_CHECK_TIME = 10 * 1000;

const EditDesign = ({
  addItems,
  designId,
  design,
  fetchFromServer,
  history,
  icons,
  inspectorWidth,
  isDesignHierarchical,
  isInspectorOpen,
  isMaterialAvailabilityTrayOpen,
  loading,
  numberOfBinsInRootCard,
  sbolRibbonHidden,
  showMinimap,
  treeLayout
}) => {
  const commands = getCommands(store, history);
  const HotkeyEnabler = withHotkeys(
    getCommandHotkeys(commands),
    getCommandHotkeyHandlers(commands)
  );

  useEffect(() => {
    // We need to make sure that these icons are in
    // the redux store in order to access their ids
    // in some reducers.
    safeQuery(iconFragment, {
      isPlural: true,
      variables: {
        filter: {
          cid: [
            "USER-DEFINED",
            "ORIGIN_OF_REPLICATION",
            "FIVE_PRIME_OVERHANG",
            "THREE_PRIME_OVERHANG",
            "TYPE-IIS-RECOGNITION-SITE",
            "TYPE-IIS-CUTSITE",

            // These are currently needed for icon hotkeys to work
            // TODO maybe change that
            "RBS",
            "FIVE_PRIME_RESTRICTION_SITE",
            "THREE_PRIME_RESTRICTION_SITE",
            "PROMOTER",
            "CDS",
            "TERMINATOR",
            "ASSEMBLY_JUNCTION",
            "INSULATOR",
            "SIGNATURE"
          ]
        }
      }
    }).then(icons => addItems({ icon: icons }));

    safeQuery(["assemblyMethod", "id cid name"], {
      isPlural: true
    }).then(assemblyMethods => addItems({ assemblyMethod: assemblyMethods }));
  }, [addItems]);

  /**
   * Fetches the design from the server,
   * once it does the 'design' prop will be populated.
   *
   * NOTE: fetchFromServer is a saga (i.e., generator)
   * thus it does not behave as an async function (i.,e, no .then() nor awaitable).
   */
  useEffect(() => {
    const handleCloseDesign = () => {
      if (window.Cypress?.tg_doNotCloseDesignSocket) return;
      socketWrapper.closeDesign(designId);
    };
    fetchFromServer(designId);
    window.addEventListener("beforeunload", handleCloseDesign);
    appGlobals.forceCloseDesign = handleCloseDesign;
    return () => {
      handleCloseDesign();
      window.removeEventListener("beforeunload", handleCloseDesign);
    };
  }, [designId, fetchFromServer]);

  /**
   * When for the design prop to be fetched/populated, we proceed to create an interval
   * that periodically checks for the design editLock to be removed,
   * so that we can notify the user that he can now reload the design to start editing it.
   *
   * We also hook up a handleClose logic if the component is unmounted (e.g., by leaving closing the tab).
   */
  useEffect(() => {
    if (design) {
      const intervalId = setInterval(async () => {
        try {
          const { editLockId, lockTypeCode } = await query(
            ["design", "id editLockId lockTypeCode"],
            {
              variables: {
                id: designId
              }
            }
          );
          /**
           * If lockTypeCode is not null, then the design is locked for reasons
           * other than an edit lock, such as design approval or crickit processing,
           * in these scenarios the design can only be unlocked by other means.
           */
          if (!editLockId && !lockTypeCode) {
            clearInterval(intervalId);
            /**
             * There's two reason why this toastr might appear:
             *
             * 1- the design was opened but it was in edit locked mode and got released
             *
             * 2- the design was opened, but the edit lock got released due to inactivity.
             */
            window.toastr.success("Reload to start editing this design", {
              action: {
                text: "Reload?",
                onClick: () => window.location.reload()
              },
              timeout: 999999999
            });
          }
        } catch (error) {
          console.error(`error:`, error);
        }
      }, EDIT_LOCK_REMOVED_CHECK_TIME);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [design, designId]);

  const computeGridStyle = () => {
    const minimapNeeded =
      showMinimap && (isDesignHierarchical || numberOfBinsInRootCard > 8);

    return {
      display: "grid",
      height: "100%",
      gridTemplateColumns: `[tree-start] 1fr [tree-end v-minimap-start] ${
        minimapNeeded && treeLayout === "vertical"
          ? VERTICAL_MINIMAP_THICKNESS
          : 0
      }px [v-minimap-end inspector-content-start] ${
        isInspectorOpen ? inspectorWidth : 0
      }px [inspector-content-end inspector-tab-bar-start] 45px [inspector-tab-bar-end]`,
      gridTemplateRows: `[h-minimap-start] ${
        minimapNeeded && treeLayout === "horizontal"
          ? HORIZONTAL_MINIMAP_THICKNESS
          : 0
      }px [h-minimap-end tree-start] 1fr [tree-end comb-header-start] 0px [comb-header-end comb-body-start] ${
        isMaterialAvailabilityTrayOpen ? 350 : 0
      }px [comb-body-end]`,
      justifyItems: "stretch",
      alignItems: "stretch"
    };
  };

  if (loading || !design) {
    return <LoadingOrNotFound {...{ objectName: "Design", loading }} />;
  }

  return (
    <DndProvider backend={MouseBackEnd}>
      <SbolDragLayer />
      <DesignEditorProvider value={{ commands }}>
        <HotkeyEnabler />
        <TopMenu />
        {!sbolRibbonHidden && (
          <SbolIconHeader icons={icons} hotkeys={editorHotkeys} />
        )}
        <div
          className={"edit-design-container " + treeLayout + "-design"}
          style={{
            ...computeGridStyle()
          }}
        >
          {treeLayout === "classic" ? (
            <ClassicGrid designId={designId} />
          ) : (
            <TreeView designId={designId} />
          )}
          <DesignInspector icons={icons} />
        </div>
      </DesignEditorProvider>
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  numberOfBinsInRootCard: getNumberOfBinsInRootCard(state)
});

export default compose(
  withQuery(iconFragment, {
    isPlural: true
  }),
  connect(mapStateToProps)
)(EditDesign);
