/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import CreateNewMaterialDialog from "./components/Dialogs/CreateNewMaterialDialog";
import CreateNewStrainDialog from "./components/Dialogs/CreateNewStrainDialog";
import UpdatePlateDialog from "./components/Dialogs/UpdatePlateDialog";
import UpdateSampleDialog from "./components/Dialogs/UpdateSampleDialog";
import UploadTwistPlateDialog from "./components/Dialogs/UploadTwistPlateDialog";
import UploadTubesDialog from "./components/Dialogs/UploadTubesDialog";
import UploadProteinDialog from "./components/Dialogs/UploadProteinDialog";
import UploadProteinDnaDialog from "./components/Dialogs/UploadProteinDNADialog";
//import AddAliquotDialog from "./AddAliquotContainer"; //seems to get imported from ./index in most containers?
import MaterialMicrobialTransformationDialog from "./components/Dialogs/MaterialMicrobialTransformationDialog";
import CreateSelectionMethodDialog from "./components/Library/Strains/CreateSelectionMethodDialog";
import CreateNewAdditiveMaterialDialog from "./components/Dialogs/CreateNewAdditiveMaterialDialog";
import AssignPlatePlacementDialog from "./components/Dialogs/AssignPlatePlacementDialog";
import AddToPlacementQueueDialog from "./components/Dialogs/AddToPlacementQueueDialog";
import PlaceAccordingToStrategyDialog from "./components/Dialogs/PlaceAccordingToStrategyDialog";
import PromptedLabelValuesDialog from "./components/Dialogs/PromptedLabelValuesDialog";
import PlatePrepDialog from "./components/LimsTools/PlatePrepTool/PlatePrepDialog";
import UploadStrainsDialog from "./components/Dialogs/UploadStrainsDialog";
import UpdateOrderDialog from "./components/Dialogs/UpdateOrderDialog";

import CreateTubeFromReagentDialog from "./components/Dialogs/CreateTubeFromReagentDialog";
import SelectReagentsOrMaterialsDialog from "./components/Dialogs/SelectReagentsOrMaterialsDialog";
import UploadIdtPlateDialog from "./components/Dialogs/UploadIdtPlateDialog";
import SaveReactionPresetDialog from "./components/Dialogs/SaveReactionPresetDialog";
import SelectGenericItemsDialog from "./components/Dialogs/SelectGenericItemsDialog";
import UploadMicrobialPlateDialog from "./components/Dialogs/UploadMicrobialPlateDialog";
import NewSampleAliquotDialog from "./components/Dialogs/NewSampleAliquotDialog";
import CreatePlateLayerDialog from "./components/Dialogs/CreatePlateLayerDialog";
import EquipmentUploadDialog from "./components/Dialogs/EquipmentUploadDialog";
import AddItemsByBarcodeDialog from "./components/Dialogs/AddItemsByBarcodeDialog";
import PlacementStrategyConfigurationDialog from "./components/Dialogs/PlacementStrategyConfigurationDialog";
import AttachPlateDialog from "./components/Dialogs/AttachPlateDialog";
import UpdateJ5MetadataDialog from "./components/Dialogs/UpdateJ5MetadataDialog";
import ChangePlateTypeDialog from "./components/Dialogs/ChangePlateTypeDialog";
import CreateGenusDialog from "./components/Library/Strains/CreateGenusDialog";
import CreateSpeciesDialog from "./components/Library/Strains/CreateSpeciesDialog";
import UpdateMaterialDialog from "./components/Dialogs/UpdateMaterialDialog";
import UploadAdditiveMaterialDialog from "./components/Dialogs/UploadAdditiveMaterialDialog";
import UploadDataTableDialog from "./components/Dialogs/UploadDataTableDialog";
import TagJ5ReportMaterialsDialog from "./components/Dialogs/TagJ5ReportMaterialsDialog";
import UpdateWorkflowRunDialog from "./components/Dialogs/UpdateWorkflowRunDialog";
import EditEquipmentDialog from "./components/Dialogs/EditEquipmentDialog";
import InspectAliquotContainerDialog from "./containers/InspectAliquotContainerDialogContainer";
import LaunchNewLookupValueDialog from "./containers/LaunchNewLookupValueDialogContainer";
import NewReplicateAliquotDialog from "./components/Dialogs/NewReplicateAliquotDialog";
import EditPositionsDialogContainer from "./containers/EditPositionsDialogContainer";
import ReplenishAliquotDialog from "./components/Dialogs/ReplenishAliquotDialog";
import UploadReagentPlateDialog from "./components/Dialogs/UploadReagentPlateDialog";
import SelectEquipmentPositionDialog from "./components/Dialogs/SelectEquipmentPositionDialog";
import EditContainerInFreezerSetupDialog from "./components/Dialogs/EditContainerInFreezerSetupDialog";
import TubePlacementSuccessPage from "./components/TubePlacementSuccessPage";
import CreateAminoAcidSequenceDialog from "./components/Dialogs/CreateAminoAcidSequenceDialog";
import PlaceTubesChooseBoxDialog from "./components/Dialogs/PlaceTubesChooseBoxDialog";
import AddAdditivesDialog from "./components/Dialogs/AddAdditivesDialog";
import EditCopyNumberDialog from "./components/Dialogs/EditCopyNumberDialog";
import EditAdditiveDialog from "./components/Dialogs/EditAdditiveDialog";
import CreateInductionMethodDialog from "./components/Library/Strains/CreateInductionMethodDialog";
import ExportWorklistDialog from "./components/Dialogs/ExportWorklistDialog";
import UploadProteinPlateDialog from "./components/Dialogs/UploadProteinPlateDialog";
import MoveRecordsToEquipmentDialog from "./components/Dialogs/MoveRecordsToEquipmentDialog";
import ManuallyAssignTubeDialog from "./components/Dialogs/ManuallyAssignTubeDialog";
import LaunchNewJobDialog from "./components/Dialogs/LaunchNewJobDialog";
import LaunchNewWorkflowDialog from "./components/Dialogs/LaunchNewWorkflowDialog";
import UploadReagentTubes from "./components/UploadReagentTubes";
import UploadTwistTubesDialog from "./components/Dialogs/UploadTwistTubesDialog";
import CreateGasCompositionDialog from "./components/Library/Strains/CreateGasCompositionDialog";
import LaunchNewCustomerRequestDialog from "./components/Dialogs/LaunchNewCustomerRequestDialog";
import UploadCellCulturePlateDialog from "./components/Dialogs/UploadCellCulturePlateDialog";
import UploadDnaPlateDialog from "./components/Dialogs/UploadDnaPlateDialog";
import SequencingQCReportRejectionDialog from "./components/Dialogs/SequencingQCReportRejectionDialog";
import SequencingQCCoverageSettingsDialog from "./components/Dialogs/SequencingQCCoverageSettingsDialog";
import SequencingQCVariantCallingSettingsDialog from "./components/Dialogs/SequencingQCVariantCallingSettingsDialog";
import AddContainerDialog from "./components/Dialogs/AddContainerDialog";
import UploadMaterialSequenceDialog from "./components/Dialogs/UploadMaterialSequenceDialog";
import SequencingQCReportMetadataDialog from "./components/Library/Strains/SequencingQCReportMetadataDialog";
import CreateVendorDialog from "./components/Library/VendorOrders/CreateVendorDialog";
import CreateCatalogTypeDialog from "./components/Library/VendorOrders/CreateCatalogTypeDialog";
import ExportSequencesDialog from "../src-shared/ExportSequencesDialog";
import PublishSequencesDialog from "../src-shared/PublishSequencesDialog";
import J5LogsDialog from "../src-shared/J5LogsDialog";
import ExportToExternalDbDialog from "../src-shared/ExternalIntegrations/ExportToExternalDbDialog";
import EditImportCollectionDialog from "../src-shared/components/Dialogs/EditImportCollectionDialog";
import CreatePublicationTargetWithDialog from "../src-shared/PublicationManagementPanel/CreatePublicationTargetDialog";

const MODAL_COMPONENTS = {
  ADD_ADDITIVES: AddAdditivesDialog,
  ADD_CONTAINER: AddContainerDialog,
  ADD_ITEMS_BY_BARCODE: AddItemsByBarcodeDialog,
  ADD_TO_PLACEMENT_QUEUE: AddToPlacementQueueDialog,
  ASSIGN_PLATE_PLACEMENT_STRATEGY: AssignPlatePlacementDialog,
  ATTACH_PLATE: AttachPlateDialog,
  CHANGE_PLATE_TYPE: ChangePlateTypeDialog,
  CHOOSE_BOXES_FOR_TUBE_STRATEGY: PlaceTubesChooseBoxDialog,
  CREATE_AA_SEQUENCE: CreateAminoAcidSequenceDialog,
  CREATE_NEW_INDUCTION_METHOD: CreateInductionMethodDialog,
  CreateGasCompositionDialog,
  CREATE_NEW_ADDITIVE_MATERIAL: CreateNewAdditiveMaterialDialog,
  CREATE_NEW_GENUS: CreateGenusDialog,
  CREATE_NEW_MATERIAL: CreateNewMaterialDialog,
  CREATE_NEW_SELECTION_METHOD: CreateSelectionMethodDialog,
  CREATE_NEW_SPECIES: CreateSpeciesDialog,
  CREATE_NEW_STRAIN: CreateNewStrainDialog,
  CREATE_NEW_VENDOR: CreateVendorDialog,
  CREATE_NEW_CATALOG_TYPE: CreateCatalogTypeDialog,
  CREATE_PLACEMENT_STRATEGY: PlacementStrategyConfigurationDialog,
  CREATE_PLATE_LAYER: CreatePlateLayerDialog,
  CREATE_PLATES_FOR_PREP: PlatePrepDialog,
  CREATE_PUBLICATION_TARGET: CreatePublicationTargetWithDialog,
  CREATE_TUBE_FROM_REAGENT: CreateTubeFromReagentDialog,
  EDIT_ADDITIVE_DIALOG: EditAdditiveDialog,
  EDIT_CONTAINER_FREEZER_SETUP: EditContainerInFreezerSetupDialog,
  EDIT_COPY_NUMBER: EditCopyNumberDialog,
  EDIT_EQUIPMENT: EditEquipmentDialog,
  EDIT_POSITIONS: EditPositionsDialogContainer,
  EQUIPMENT_UPLOAD: EquipmentUploadDialog,
  EXPORT_WORKLIST: ExportWorklistDialog,
  ExportToExternalDbDialog,
  INSPECT_ALIQUOT_CONTAINER_DIALOG: InspectAliquotContainerDialog,
  J5_LOGS: J5LogsDialog,
  LAUNCH_NEW_LOOKUP_VALUE_DIALOG: LaunchNewLookupValueDialog,
  LAUNCH_NEW_WORKFLOW_DIALOG: LaunchNewWorkflowDialog,
  MATERIAL_MICROBIAL_TRANSFORMATION: MaterialMicrobialTransformationDialog,
  NEW_SAMPLE_ALIQUOT: NewSampleAliquotDialog,
  PLACE_ACCORDING_TO_STRATEGY: PlaceAccordingToStrategyDialog,
  PROMPTED_LABEL_VALUES: PromptedLabelValuesDialog,
  REPLENISH_ALIQUOT: ReplenishAliquotDialog,
  SAVE_REACTION_CONDITION_GROUP_PRESET: SaveReactionPresetDialog,
  SELECT_EQUIPMENT_POSITIONS: SelectEquipmentPositionDialog,
  SELECT_GENERIC_ITEMS: SelectGenericItemsDialog,

  SELECT_REAGENTS_OR_MATERIALS: SelectReagentsOrMaterialsDialog,
  TAG_J5_REPORT_MATERIALS: TagJ5ReportMaterialsDialog,
  TUBE_PLACEMENT_SUCCESS_PAGE: TubePlacementSuccessPage,
  UPDATE_J5_METADATA: UpdateJ5MetadataDialog,
  UPDATE_MATERIAL: UpdateMaterialDialog,
  UPDATE_PLATE: UpdatePlateDialog,
  UPDATE_ORDER: UpdateOrderDialog,
  UPDATE_SAMPLE: UpdateSampleDialog,
  UPDATE_WORKFLOW_RUN: UpdateWorkflowRunDialog,
  UPLOAD_ADDITIVE_MATERIAL: UploadAdditiveMaterialDialog,
  UPLOAD_DATA_TABLE: UploadDataTableDialog,
  UPLOAD_IDT_PLATE: UploadIdtPlateDialog,
  UPLOAD_MATERIAL_SEQUENCE: UploadMaterialSequenceDialog,
  UPLOAD_MICROBIAL_MATERIAL_PLATE: UploadMicrobialPlateDialog,
  UPLOAD_PROTEIN_DNA: UploadProteinDnaDialog,
  UPLOAD_PROTEIN_PLATE: UploadProteinPlateDialog,
  UPLOAD_PROTEIN: UploadProteinDialog,
  UPLOAD_REAGENT_PLATE: UploadReagentPlateDialog,
  UPLOAD_STRAIN: UploadStrainsDialog,
  UPLOAD_TUBES: UploadTubesDialog,
  UPLOAD_TWIST_PLATE: UploadTwistPlateDialog,
  NewReplicateAliquotDialog,
  LaunchNewCustomerRequestDialog,
  UploadDnaPlateDialog,
  ExportSequencesDialog,
  PublishSequencesDialog,
  MoveRecordsToEquipmentDialog,
  EditImportCollectionDialog,
  ManuallyAssignTubeDialog,
  LaunchNewJobDialog,
  UploadReagentTubes,
  UploadTwistTubesDialog,
  UploadCellCulturePlateDialog,
  SequencingQCReportRejectionDialog,
  SequencingQCCoverageSettingsDialog,
  SequencingQCVariantCallingSettingsDialog,
  SequencingQCReportMetadataDialog
};

export default MODAL_COMPONENTS;
