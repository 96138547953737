/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent, Callout, Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  ReactSelectField,
  CheckboxField,
  wrapDialog
} from "@teselagen/ui";
import { get } from "lodash";
import classNames from "classnames";
import PlateMapPlate from "../../PlateMapPlate";
import {
  addAliquotToAliquotContainerWithReagents,
  additiveMixingFragment
} from "../../../utils";

import { CreateNewTube, withTubeTypes } from "../../Dialogs/AddTubeDialog";
import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";
import GenericSelect from "../../../../src-shared/GenericSelect";
import "./style.css";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import gql from "graphql-tag";

import { compose } from "recompose";
import { connect } from "react-redux";
import { containerArraySelectedAliquotContainerLocationsSelector } from "../../../../src-shared/redux/selectors";

import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import actions from "../../../../src-shared/redux/actions";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";

const manuallyAssignAliquotContainerFragment = gql`
  fragment manuallyAssignAliquotContainerFragment on aliquotContainer {
    id
    aliquotContainerType {
      code
      maxVolume
      volumetricUnitCode
    }
    additives {
      ...additiveMixingFragment
    }
  }
  ${additiveMixingFragment}
`;
const manuallyAssignAliquotFragment = gql`
  fragment manuallyAssignAliquotFragment on aliquot {
    id
    volume
    volumetricUnitCode
    isDry
    cellConcentration
    cellConcentrationUnitCode
    cellCount
    additives {
      ...additiveMixingFragment
    }
  }
  ${additiveMixingFragment}
`;

class ManuallyAssignAliquotDialog extends Component {
  state = {
    assignToPlate: false,
    assignToTube: false,
    page: 1,
    selectedContainerArray: {},
    selectedAliquotContainer: null,
    loading: false,
    existingTube: false,
    fetchedContainerArray: {}
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  fetchSelectedContainerArray = values => {
    const { containerArray } = values;
    this.setState({ loading: true });
    return safeQuery(containerArrayPlatePreviewFragment, {
      variables: {
        id: containerArray.id
      }
    })
      .then(fetchedContainerArray => {
        this.setState({ fetchedContainerArray, loading: false });
        this.nextPage();
      })
      .catch(e => {
        this.setState({ loading: false });
        console.error(e);
        window.toastr.error("Error fetching plate");
      });
  };

  handleUpdate = (aliquotContainer, values) => {
    if (get(aliquotContainer, "aliquot.id")) {
      this.nextPage();
    } else {
      this.doUpsert(aliquotContainer, values);
    }
  };

  onSubmitLocation = values =>
    this.handleUpdate(this.state.selectedAliquotContainer, values);

  onSubmitTube = values => this.handleUpdate(values.aliquotContainer);

  doUpsert = async (selectedContainer, values = {}) => {
    const aliquotContainer =
      selectedContainer || this.state.selectedAliquotContainer;
    const { fetchedContainerArray } = this.state;
    const { refetch, hideModal, aliquot } = this.props;

    if (
      aliquotContainer.id &&
      aliquotContainer.id === get(aliquot, "aliquotContainer.id")
    ) {
      return window.toastr.warning(
        "This aliquot is already assigned to this location."
      );
    }

    try {
      this.setState({ loading: true });

      let aliquotContainerId = aliquotContainer.id;
      const aliquotContainerUpdates = [];
      if (!aliquotContainerId) {
        const { newTube } = values;
        const [createdTube] = await safeUpsert("aliquotContainer", {
          name: newTube.name,
          rowPosition: aliquotContainer.rowPosition,
          columnPosition: aliquotContainer.columnPosition,
          containerArrayId: fetchedContainerArray.id,
          aliquotContainerTypeCode: newTube.aliquotContainerTypeCode,
          ...(!newTube.generateBarcode && {
            barcode: {
              barcodeString: newTube.barcode
            }
          })
        });
        aliquotContainerId = createdTube.id;
        if (newTube.generateBarcode) {
          await addBarcodesToRecords(createdTube);
        }
        aliquotContainerUpdates.push({
          id: aliquotContainerId,
          aliquotId: aliquot.id
        });
      } else {
        aliquotContainerUpdates.push({
          id: aliquotContainer.id,
          aliquotId: aliquot.id
        });
      }
      if (aliquot.aliquotContainer) {
        aliquotContainerUpdates.push({
          id: aliquot.aliquotContainer.id,
          aliquotId: null
        });
      }
      const newAliquotContainer = await safeQuery(
        manuallyAssignAliquotContainerFragment,
        {
          variables: {
            id: aliquotContainerId
          }
        }
      );
      const aliquotToAssign = await safeQuery(manuallyAssignAliquotFragment, {
        variables: {
          id: aliquot.id
        }
      });
      if (aliquotToAssign.volume) {
        const aliquotContainerType = newAliquotContainer.aliquotContainerType;
        if (
          standardizeVolume(
            aliquotToAssign.volume,
            aliquotToAssign.volumetricUnitCode
          ) >
          standardizeVolume(
            aliquotContainerType.maxVolume,
            aliquotContainerType.volumetricUnitCode
          )
        ) {
          this.setState({
            loading: false
          });
          return window.toastr
            .error(`This aliquot's volume of ${aliquotToAssign.volume} ${aliquotToAssign.volumetricUnitCode} will not fit \
          into this well with max volume ${aliquotContainerType.maxVolume} ${aliquotContainerType.volumetricUnitCode}`);
        }
      }

      if (newAliquotContainer.additives.length) {
        const error = await addAliquotToAliquotContainerWithReagents(
          aliquotToAssign,
          newAliquotContainer
        );
        if (error) {
          return window.toastr.error(error);
        }
      }

      await safeUpsert("aliquotContainer", aliquotContainerUpdates);
      await refetch();
      hideModal();
      window.toastr.success("Successfully assigned aliquot");
    } catch (err) {
      window.toastr.error("Error assigning aliquot.");
      console.error("err:", err);
      this.setState({
        loading: false
      });
    }
  };

  onPlateClick = () => {
    this.nextPage();
    this.setState({ assignToPlate: true, assignToTube: false });
  };

  onTubeClick = () => {
    this.nextPage();
    this.setState({ assignToTube: true, assignToPlate: false });
  };

  selectExistingTubes = () => {
    this.nextPage();
    this.setState({ existingTube: true });
  };

  createNewTube = () => {
    this.nextPage();
    this.setState({ existingTube: false });
  };

  aliquotContainerDoubleClick = record => {
    const { selectedAliquotContainer } = this.state;
    this.setState({ selectedAliquotContainer: record });
    if (get(selectedAliquotContainer, "aliquotId")) {
      return this.nextPage();
    }
    this.doUpsert(record);
  };

  onAliquotContainerSelected = record => {
    this.setState({ selectedAliquotContainer: record });
  };

  onBackClick = () => {
    this.setState({ selectedAliquotContainer: null });
    this.previousPage();
  };

  renderPage1 = () => {
    return (
      <div className="tg-flex column align-center">
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          Assign to Plate or Tube?
        </div>
        <div>
          <Button
            style={{ marginRight: 10 }}
            intent={Intent.PRIMARY}
            text="Plate"
            onClick={this.onPlateClick}
          />
          <Button
            style={{ marginLeft: 10 }}
            intent={Intent.PRIMARY}
            text="Tube"
            onClick={this.onTubeClick}
          />
        </div>
      </div>
    );
  };

  renderPage2 = () => {
    const { assignToPlate, assignToTube, loading } = this.state;
    const { handleSubmit, hideModal } = this.props;

    return (
      <div>
        {assignToPlate && (
          <React.Fragment>
            <div
              className={classNames(
                Classes.DIALOG_BODY,
                "tg-flex column align-center",
                "fill-react-select"
              )}
            >
              <h6 className="header-margin">Select Destination Plate</h6>
              <GenericSelect
                {...{
                  name: "containerArray", //the field name within the redux form Field
                  asReactSelect: true,
                  isRequired: true,
                  schema: [
                    "name",
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    },
                    dateModifiedColumn
                  ],
                  fragment: [
                    "containerArray",
                    `id
                    name
                    barcode {
                      id
                      barcodeString
                    }
                    updatedAt`
                  ]
                }}
              />
            </div>
            <DialogFooter
              hideModal={hideModal}
              loading={loading}
              secondaryAction={this.previousPage}
              secondaryText="Back"
              text="Select Plate"
              onClick={handleSubmit(this.fetchSelectedContainerArray)}
            />
          </React.Fragment>
        )}
        {assignToTube && (
          <div>
            <div className={Classes.DIALOG_BODY}>
              <div className="tg-flex justify-center">
                <Button
                  intent={Intent.PRIMARY}
                  text="Select Existing Tube"
                  onClick={this.selectExistingTubes}
                />
                <div className="tg-flex-separator" />
                <Button
                  intent={Intent.PRIMARY}
                  text="Create New Tube"
                  onClick={this.createNewTube}
                />
              </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button text="Back" minimal onClick={this.previousPage} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderPage3 = () => {
    const {
      assignToPlate,
      assignToTube,
      loading,
      fetchedContainerArray,
      selectedAliquotContainer,
      existingTube
    } = this.state;
    const {
      hideModal,
      selectedAliquotContainerLocations,
      setSelectedAliquotContainerLocation,
      handleSubmit,
      newTube = {},
      aliquotContainerTypes = [],
      currentUser
    } = this.props;

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          {assignToPlate && fetchedContainerArray && (
            <React.Fragment>
              <div className="tg-flex column align-center">
                <h6>Select Desired Aliquot Location</h6>
                <PlateMapPlate
                  {...{
                    selectedAliquotContainerLocations,
                    setSelectedAliquotContainerLocation,
                    containerArrayType:
                      fetchedContainerArray.containerArrayType,
                    aliquotContainers: generateContainerArray(
                      fetchedContainerArray.aliquotContainers,
                      fetchedContainerArray.containerArrayType.containerFormat
                    ),
                    aliquotContainerDoubleClick: this
                      .aliquotContainerDoubleClick,
                    onAliquotContainerSelected: this.onAliquotContainerSelected
                  }}
                />
              </div>
              {!!selectedAliquotContainer && !selectedAliquotContainer.id && (
                <div style={{ marginTop: 10 }}>
                  <InputField
                    label="New Tube Name"
                    name="newTube.name"
                    isRequired
                    placeholder="Enter tube name"
                  />
                  <CheckboxField
                    name="newTube.generateBarcode"
                    defaultValue
                    label="Generate Tube Barcode"
                  />
                  {!newTube.generateBarcode && (
                    <InputField
                      name="newTube.barcode"
                      label="Tube Barcode"
                      placeholder="Enter Barcode..."
                    />
                  )}
                  <ReactSelectField
                    label="Tube Type"
                    name="newTube.aliquotContainerTypeCode"
                    isRequired
                    placeholder="Select a type"
                    options={arrayToIdOrCodeValuedOptions(
                      aliquotContainerTypes
                    )}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {assignToTube && existingTube && (
            <div className="tg-flex column">
              <div className="tg-flex row justify-space-between fill-react-select">
                <GenericSelect
                  {...{
                    name: "aliquotContainer", //the field name within the redux form Field
                    asReactSelect: true,
                    isRequired: true,
                    schema: [
                      "name",
                      {
                        displayName: "Barcode",
                        path: "barcode.barcodeString"
                      },
                      {
                        displayName: "Position",
                        path: "containerArray.name",
                        render: (v, r) => {
                          if (v) {
                            return `${v} (${getAliquotContainerLocation(r)})`;
                          }
                        }
                      },
                      "aliquotContainerType.name",
                      dateModifiedColumn
                    ],
                    tableParamOptions: tubeTableParamOptions,
                    fragment: [
                      "aliquotContainer",
                      `id
                          name
                          aliquotId
                          barcode {
                            id
                            barcodeString
                          }
                          rowPosition
                          columnPosition
                          aliquotContainerType {
                            code
                            name
                          }
                          containerArray {
                            id
                            name
                          }
                          updatedAt`
                    ]
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {assignToTube && !existingTube && (
          <CreateNewTube
            previousPage={this.previousPage}
            afterCreate={this.doUpsert}
            hideModal={hideModal}
            currentUser={currentUser}
          />
        )}
        {assignToPlate && (
          <DialogFooter
            hideModal={hideModal}
            loading={loading}
            disabled={!selectedAliquotContainer}
            secondaryAction={this.onBackClick}
            secondaryText="Back"
            text="Select Location"
            onClick={handleSubmit(this.onSubmitLocation)}
          />
        )}
        {assignToTube && existingTube && (
          <DialogFooter
            hideModal={hideModal}
            loading={loading}
            secondaryAction={this.previousPage}
            secondaryText="Back"
            text="Select Tube"
            onClick={handleSubmit(this.onSubmitTube)}
          />
        )}
      </div>
    );
  };

  renderPage4 = () => {
    const { loading } = this.state;
    const { hideModal } = this.props;

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <div className="tg-flex column align-center">
            <Callout intent={Intent.WARNING} icon="warning-sign">
              Remove existing aliquot?
            </Callout>
          </div>
        </div>

        <DialogFooter
          hideModal={hideModal}
          loading={loading}
          secondaryAction={this.onBackClick}
          secondaryText="Back"
          text="Confirm"
          onClick={() => this.doUpsert()}
        />
      </div>
    );
  };

  render() {
    return this[`renderPage${this.state.page}`]();
  }
}

const mapStateToProps = state => {
  return {
    selectedAliquotContainerLocations: containerArraySelectedAliquotContainerLocationsSelector(
      state
    )
  };
};

const mapDispatchToProps = {
  setSelectedAliquotContainerLocation:
    actions.ui.records.containerArray.setSelectedAliquotContainerLocation
};

export default compose(
  wrapDialog({
    title: "Assign Aliquot",
    enforceFocus: false,
    canOutsideClickClose: false
  }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "ManuallyAssignAliquot"
  }),
  tgFormValues("newTube"),
  withTubeTypes
)(ManuallyAssignAliquotDialog);

const tubeTableParamOptions = {
  additionalFilter: (props, qb) => {
    qb.whereAll({
      "aliquotContainerType.isTube": true,
      aliquotId: qb.isNull()
    });
  }
};
