/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Button, Classes } from "@blueprintjs/core";
import {
  DataTable,
  DialogFooter,
  withSelectedEntities,
  withTableParams
} from "@teselagen/ui";
import "./style.css";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { compose } from "recompose";
import withQuery from "../../../../src-shared/withQuery";
import { wrapDialog } from "@teselagen/ui";

class DesignRuleSetLibraryDialog extends React.Component {
  render() {
    const {
      hideModal,
      onSubmit,
      tableParams,
      history,
      DesignRuleSetLibraryDialogSelectedEntities: selectedRuleSets
    } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <DataTable {...tableParams} withCheckboxes>
            <Button
              minimal
              text="Open Library"
              onClick={() => {
                hideModal();
                history.push(modelNameToLink("ruleSet"));
              }}
            />
          </DataTable>
        </div>
        <DialogFooter
          hideModal={hideModal}
          disabled={!selectedRuleSets.length}
          onClick={() => {
            hideModal();
            onSubmit(selectedRuleSets);
          }}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Design Rulesets",
    style: {
      width: 700
    }
  }),
  withTableParams({
    formName: "DesignRuleSetLibraryDialog",
    schema: {
      model: "ruleSet",
      fields: [
        { path: "id", type: "string", displayName: "Id", isHidden: true },
        { path: "name", type: "string", displayName: "Name" }
      ]
    },
    urlConnected: false,
    additionalFilter: ({ idsToIgnore }, qb) => {
      if (idsToIgnore) {
        qb.whereAll({
          id: qb.notInList(idsToIgnore)
        });
      }
    }
  }),
  withQuery(["ruleSet", "id name"], { isPlural: true }),
  withSelectedEntities("DesignRuleSetLibraryDialog")
)(DesignRuleSetLibraryDialog);
