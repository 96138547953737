/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatten, times } from "lodash";
import Promise from "bluebird";
import { STATE_TO_DB_TYPES } from "../../../tg-iso-design/constants/designStateConstants";

export default async function executeDesignMutation(
  type,
  values,
  upsert,
  careAboutResults = false
) {
  // NOTE: not sure why __typenames are being set to undefined in here...
  values = Object.values(values).map(value => ({
    ...value,
    // NOTE: cid is not functionally used now, but it still needs to comply with cid uniqueness, so we just copy the record's ID value.
    cid: value.id,
    __typename: undefined
  }));

  // NOTE: This remaps the name of the model type used in the design state
  // by the actual name in the database. Originally added for the renaming of the Reaction table to designReaction
  // needed by issues lims#8622
  type = STATE_TO_DB_TYPES[type] || type;
  if (values.length) {
    if (careAboutResults) {
      // We got to do it this way as upsert will only return up to
      // 100 values.
      const pageSize = 100;
      return flatten(
        await Promise.mapSeries(
          times(Math.ceil(values.length / pageSize), i => i),
          i =>
            upsert(
              [type, "id cid"],
              values.slice(i * pageSize, (i + 1) * pageSize)
            )
        )
      );
    } else {
      return await upsert([type, "id cid"], values);
    }
  } else return [];
}
