/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import {
  InputField,
  TextareaField,
  DateInputField,
  FileUploadField
} from "@teselagen/ui";
import GenericSelect from "../../../../src-shared/GenericSelect";
import CreateOrGenerateBarcodeField from "../../CreateOrGenerateBarcodeField";
import HasContentsCheckbox from "./HasContentsCheckbox";

const NewEquipmentFields = ({ asyncValidating, hasContentsDefault }) => (
  <div className="tg-flex">
    <div style={{ margin: 20, flex: 1 }}>
      <InputField name="name" label="Name" isRequired />
      <HasContentsCheckbox defaultValue={hasContentsDefault} />
      <CreateOrGenerateBarcodeField asyncValidating={asyncValidating} />
      <TextareaField name="description" label="Description" />
      <GenericSelect
        asReactSelect
        name="equipmentType"
        isRequired
        idAs="code"
        label="Equipment Type"
        fragment={["equipmentType", "code name"]}
      />
      <InputField name="serialNumber" label="Serial Number" />
      <DateInputField
        name="warrantyExpiration"
        label="Warranty Expiration Date"
        minDate={new Date("1/1/2010")}
        maxDate={new Date("12/31/2100")}
      />
      <TextareaField name="serviceContactInfo" label="Service Contact Info" />
    </div>
    <div style={{ margin: 20, flex: 1 }}>
      <InputField name="label" label="Label" />
      <InputField name="make" label="Make" />
      <InputField name="model" label="Model" />
      <GenericSelect
        asReactSelect
        fragment={["location", "id name"]}
        isRequired
        label="Location"
        name="location"
      />
      <InputField name="manufacturer" label="Manufacturer" />
      <DateInputField
        name="serviceContractExpiration"
        label="Service Contract Expiration Date"
        minDate={new Date("1/1/2010")}
        maxDate={new Date("12/31/2100")}
      />
      <FileUploadField name="equipmentFiles" label="Associated Files" />
    </div>
  </div>
);

export default NewEquipmentFields;
