/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import { overwriteBarcodeCheck } from "../utils/plateUtils";

export default async function handleReagentTubeImport(
  values,
  ctx = isoContext
) {
  const { generateBarcodes } = values;
  const { finishTubeCreate, aliquotContainerType, csvData, getCsvRowExtProps } =
    await parsePlateCsvAndSequenceFiles(
      values,
      {
        isTubeUpload: true,
        hasReagents: true,
        hasExtendedProperties: true,
        requiredFields: ["TUBE_NAME"]
      },
      ctx
    );

  if (generateBarcodes) {
    const continueOverwriteBarcodes = await overwriteBarcodeCheck(
      csvData,
      "TUBE_BARCODE"
    );
    if (!continueOverwriteBarcodes) return;
  }

  const tubesToCreate = [];

  for (const [index, row] of csvData.entries()) {
    const { TUBE_NAME: tubeName, TUBE_BARCODE: tubeBarcode, additives } = row;

    if (!tubeName && !tubeBarcode) {
      throw new Error(`Row ${index + 1} did not provide a name or a barcode.`);
    }
    if (!row.additives || !row.additives.length) {
      throw new Error(`Row ${index + 1} did not specify any reagents.`);
    }

    const tubeCid = shortid();
    tubesToCreate.push({
      cid: tubeCid,
      name: tubeName,
      ...(!generateBarcodes && {
        barcode: {
          barcodeString: tubeBarcode
        }
      }),
      additives,
      aliquotContainerTypeCode: aliquotContainerType.code
    });
    getCsvRowExtProps({
      row,
      modelTypeCode: "ALIQUOT_CONTAINER",
      typeFilter: "tube",
      recordId: `&${tubeCid}`
    });
  }

  return await finishTubeCreate({
    newTubes: tubesToCreate
  });
}
