/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import sequenceFragment from "../../../../tg-iso-shared/src/fragments/sequenceFragment";
import { functionProteinRecordViewSubUnitsFragment } from "../../../../tg-iso-shared/src/fragments/functionalProteinUnitRecordFragment";

export default gql`
  fragment sampleRecordFragment on sample {
    id
    user {
      id
      username
    }
    sampleTypeCode
    sampleType {
      code
      name
    }
    sampleStatus {
      code
      name
    }
    sampleAliquot {
      id
    }
    sampleIsolation {
      id
      sourceSample {
        id
        name
      }
      xCoordinate
      yCoordinate
      diameter
      date
    }
    sampleQualityControlChecks(pageSize: 1) {
      id
    }
    sampleProteinPatterns {
      id
      proteinPattern {
        id
        name
        pattern
      }
    }
    sampleFormulations {
      id
      materialCompositions {
        id
        material {
          id
          name
          materialTypeCode
          strain {
            id
            name
            specie {
              id
              abbreviatedName
            }
          }
          polynucleotideMaterialSequence {
            id
            size
            sequenceType {
              code
              name
            }
          }
        }
      }
    }
    status
    name
    createdAt
    updatedAt
    material {
      id
      name
      materialTypeCode
      strain {
        id
        name
        specie {
          id
          abbreviatedName
        }
      }
      polynucleotideMaterialSequence {
        ...sequenceFragment
      }
      microbialMaterialMicrobialMaterialPlasmids {
        id
        microbialMaterialId
        polynucleotideMaterial {
          id
          polynucleotideMaterialSequence {
            ...sequenceFragment
          }
        }
      }
      cellCultureCellCulturePlasmids {
        id
        cellCultureId
        polynucleotideMaterial {
          id
          polynucleotideMaterialSequence {
            ...sequenceFragment
          }
        }
      }
      functionalProteinUnit {
        ...functionProteinRecordViewSubUnitsFragment
      }
    }
    targetMaterial {
      id
      name
    }
    sampleFormulations {
      id
      materialCompositions {
        id
        materialId
        material {
          id
          name
        }
      }
    }
    sequencingQualityControlReports {
      id
      name
    }
  }
  ${sequenceFragment}
  ${functionProteinRecordViewSubUnitsFragment}
`;
