/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField } from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";
import { DesignImport } from "../../utils/designImportProcess";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import { startImportCollection } from "../../../src-shared/utils/importCollection";

function ChooseNameDialog(props) {
  const {
    history,
    hideModal,
    handleSubmit,
    submitting,
    exampleDesignJson,
    templateName
  } = props;

  async function onSubmit(values) {
    try {
      if (templateName) {
        const designImport = new DesignImport(history, true);
        await designImport.processImport({
          exampleDesignTemplate: templateName,
          name: values.name
        });
      } else {
        await startImportCollection("Design Upload");
        const designImport = new DesignImport(history, false);
        // make file from exampleDesignJson
        const file = new File(
          [
            new Blob(
              [new TextEncoder().encode(JSON.stringify(exampleDesignJson))],
              {
                type: "application/json;charset=utf-8"
              }
            )
          ],
          `${values.name}.json`
        );
        await designImport.processImport({
          name: values.name,
          inputFiles: [file]
        });
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading example design.");
    }
  }
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          autoFocus
          generateDefaultValue={{
            ...defaultValueConstants.DESIGN_NAME,
            customParams: {
              // lastDesignId: 189128
              //every field will always have userName, userId, timestamp, labId passed to it
            }
          }}
          label="Name"
          name="name"
          isRequired
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Name Design"
  }),
  reduxForm({
    form: "NameDesignDialog"
  })
)(ChooseNameDialog);
