/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const sequenceSampleStatusFragment = gql`
  fragment sequenceSampleStatusFragment on sequenceSampleStatusView {
    id
    totalValid
    totalInvalid
    totalUnvalidated
  }
`;
