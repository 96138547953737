/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import classNames from "classnames";

function StepFormSection({ column, className, children, style }) {
  return (
    <div
      style={style}
      className={classNames("tg-step-form-section", className, { column })}
    >
      {children}
    </div>
  );
}

export default StepFormSection;
