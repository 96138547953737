/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { Button, Switch } from "@blueprintjs/core";
import { InfoHelper } from "@teselagen/ui";
import useTgQuery from "../apolloUseTgQuery";
import modelNameToLink from "../utils/modelNameToLink";
import ExportSequencesDialog from "../ExportSequencesDialog";
import { safeQuery } from "../apolloMethods";
import { showDialog } from "../GlobalDialog";

// linkable models (keep order)
const models = [
  "j5InputSequence",
  "j5DirectSynthesis",
  "j5RunConstruct",
  "j5OligoSynthesis",
  "j5AssemblyPiece"
];

const titleMap = {
  j5OligoSynthesis: "Oligos",
  j5AssemblyPiece: "DNA Pieces",
  j5InputSequence: "Input Sequences",
  j5RunConstruct: "Constructs",
  j5DirectSynthesis: "DNA Synthesis Sequences"
};

const propNameMap = {
  j5OligoSynthesis: "oligos",
  j5AssemblyPiece: "dnaPieces",
  j5InputSequence: "inputSequences",
  j5RunConstruct: "constructs",
  j5DirectSynthesis: "dnaSynthesisSequences"
};

const hasOligos = {
  j5OligoSynthesis: true
};

export function reportLinks(reports) {
  return reports.map((r, i) => {
    return (
      <span key={r.id}>
        <Link to={modelNameToLink(r)}>{r.name}</Link>
        {i !== reports.length - 1 && ", "}
      </span>
    );
  });
}

export function getLinkDialogProps(j5Report, fragmentMap) {
  const linkDialogProps = models.reduce((acc, model) => {
    const propName = propNameMap[model];
    acc[propName] = {
      dialogProps: {
        title: `Link ${titleMap[model]} to Materials`
      },
      hasOligos,
      j5ReportId: j5Report.id,
      model
    };
    if (!isEmpty(fragmentMap)) {
      acc[propName].runTimeQueryOptions = {
        fragment: fragmentMap[model]
      };
    } else {
      let items = j5Report[pluralize(model)];
      if (hasOligos[model]) {
        items = items.map(item => item.oligo);
      }
      acc[propName].items = items;
      acc[propName].sequenceHashes = items.map(({ sequence }) => sequence.hash);
      acc[propName].allLinked = !items.some(
        item => item.sequence && !item.sequence.polynucleotideMaterialId
      );
    }
    return acc;
  }, {});
  return linkDialogProps;
}

export const SynthonAdditionalHeaderEl = ({
  j5ReportId,
  toggleShowSynthons,
  showSynthons
}) => {
  const { j5DirectSyntheses, ...rest } = useTgQuery(
    ["j5DirectSynthesis", `id`],
    { variables: { filter: { j5ReportId }, pageSize: 1 } }
  );
  if (useTgQuery.checkErrAndLoad(rest)) return null;
  if (j5DirectSyntheses.length) {
    return (
      <div style={{ marginTop: 20, display: "flex" }} key="zoink">
        <Switch onChange={toggleShowSynthons} checked={showSynthons}>
          PCR Amplify Synthons?
        </Switch>
        &nbsp;&nbsp;
        <InfoHelper content="Check this box if you want to PCR amplify the sequences that you'll be directly synthesizing. If not, you'll just use them as is in your assembly reaction."></InfoHelper>
      </div>
    );
  }
  return null;
};

export const getExportSeqs = ({ frag, j5ReportId }) => {
  return (
    <Button
      key="exportSeqs"
      text="Export Sequences"
      style={{ marginLeft: 10 }}
      onClick={async () => {
        try {
          const constructs = await safeQuery(frag, {
            variables: {
              filter: {
                j5ReportId
              }
            }
          });
          const sequenceIds = constructs.map(
            construct => construct.sequence.id
          );
          showDialog({
            ModalComponent: ExportSequencesDialog,
            modalProps: {
              sequenceIds
            }
          });
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error(error.message);
        }
      }}
    />
  );
};
