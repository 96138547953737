/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { reduxForm } from "redux-form";
import { AddComment } from "../AddComment";

export default compose(
  reduxForm({
    form: "addCommentReply"
  })
)(AddComment);
