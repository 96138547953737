// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.ModelDetailViewStyles-module__modelDetailGridContainer-LAuYn {\n  width: 90%;\n  margin: 0 auto;\n  height: -moz-fit-content;\n  height: fit-content;\n  margin-bottom: 100px;\n}\n\n.ModelDetailViewStyles-module__modelDetailHeader-2fL-s {\n  display: flex;\n  flex-flow: column;\n  margin: 20px 10px 20px 10px;\n}\n\n/* .modelDetailHeader p {\n  width: 600px;\n} */\n\n.ModelDetailViewStyles-module__modelDetailHeader-2fL-s h2 {\n  width: 500px;\n}\n\n.ModelDetailViewStyles-module__tableMarkdown-gXmlf {\n  font-family: \"Trebuchet MS\", Arial, Helvetica, sans-serif;\n  border-collapse: collapse;\n  width: 100%;\n}\n\n.ModelDetailViewStyles-module__tableMarkdown-gXmlf td,\n.ModelDetailViewStyles-module__tableMarkdown-gXmlf th {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.ModelDetailViewStyles-module__tableMarkdown-gXmlf tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.ModelDetailViewStyles-module__tableMarkdown-gXmlf tr:hover {\n  background-color: #ddd;\n}\n\n.ModelDetailViewStyles-module__tableMarkdown-gXmlf th {\n  padding-top: 12px;\n  padding-bottom: 12px;\n  text-align: left;\n  background-color: #4caf50;\n  color: white;\n}\n", ""]);
// Exports
exports.locals = {
	"modelDetailGridContainer": "ModelDetailViewStyles-module__modelDetailGridContainer-LAuYn",
	"modelDetailHeader": "ModelDetailViewStyles-module__modelDetailHeader-2fL-s",
	"tableMarkdown": "ModelDetailViewStyles-module__tableMarkdown-gXmlf"
};
module.exports = exports;
