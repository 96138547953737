/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DataTable, InputField } from "@teselagen/ui";
import stepFormValues from "../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";

function ReviewData({ dataRows, dataTableType = {} }) {
  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Name and Review Data Table"
        helper="Provide a name for the data table and review the information below."
      />
      <div style={{ maxWidth: 250 }}>
        <InputField isRequired label="Data Table Name" name="dataTableName" />
      </div>
      <DataTable
        formName="reviewDataRows"
        schema={dataTableType.rowSchema}
        entities={dataRows}
        isSimple
        noSelect
      />
    </div>
  );
}

export default stepFormValues("dataTableType", "dataRows")(ReviewData);
