/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import { formInputsValidation } from "../validationFns";

const idtOptions = {
  productType: yup.string().required(),
  context: yup.object(),
  options: yup.object({
    containerType: yup.string(),
    containerFormat: yup.object()
  })
};

const idtOptionsExample = {
  context: {
    access_token: "388e57c49c23870e04035d8ff176bca0"
  },
  productType: "OLIGO",
  options: {
    scale: "25nm"
  }
};

const IDT_ORDERING = {
  description: "Setup endpoints for IDT Ordering tool",
  bundles: [
    {
      id: "dna_ordering_idt",
      name: "IDT",
      integrationHookFileUrl: "idtDnaOrderingIntegrationHook.json",
      nodeRedFileUrl: "idtNodeRed.json"
    }
  ],
  endpoints: {
    VENDOR__CONTEXT: {
      isOptional: true,
      name: "Get Context For Requests",
      method: "GET",
      description:
        "Returns any context variables that will be sent with other requests (auth, etc.).",
      exampleRequest: "",
      exampleResponse: {
        __validate__: () => yup.object(),
        access_token: "388e57c49c23870e04035d8ff176bca0"
      }
    },
    VENDOR__OPTIONS: {
      name: "Get Form Options",
      method: "GET",
      description: "Returns form options which will be added to the order CSV.",
      exampleRequest: "",
      exampleResponse: {
        __validate__: () =>
          yup.object({
            formInputs: formInputsValidation
          }),
        formInputs: [
          {
            isRequired: true,
            fieldType: "SelectField",
            name: "scale",
            label: "Scale",
            options: [
              { value: "25nm", label: "25 nmole" },
              { value: "100nm", label: "100 nmole" },
              { value: "250nm", label: "250 nmole" },
              { value: "1um", label: "1 µmole" },
              { value: "2um", label: "2 umole" },
              { value: "5um", label: "5 µmole" },
              { value: "10um", label: "10 µmole" },
              { value: "4nmU", label: "4 nmole Ultramer™" },
              { value: "20nmU", label: "20 nmole Ultramer™" },
              { value: "PU", label: "PAGE Ultramer™" },
              { value: "25nmS", label: "25 nmole Sameday" }
            ]
          },
          {
            isRequired: true,
            fieldType: "SelectField",
            name: "purification",
            label: "Purification",
            options: [
              { value: "STD", label: "Standard Desalting" },
              { value: "PAGE", label: "PAGE $60.00" },
              { value: "HPLC", label: "HPLC $42.00" },
              { value: "IEHPLC", label: "IE HPLC $45.00" },
              { value: "RNASE", label: "RNase Free HPLC $75.00" },
              { value: "DUALHPLC", label: "Dual HPLC $80.00" },
              { value: "PAGEHPLC", label: "Dual PAGE & HPLC $130.00" }
            ]
          }
        ]
      }
    },
    VENDOR__SCORING: {
      name: "Score DNA",
      method: "POST",
      description: "Score DNA",
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            ...idtOptions,
            sequence: yup
              .object({
                id: yup.string().required(),
                name: yup.string().required(),
                sequence: yup.string().required(),
                circular: yup.boolean().required()
              })
              .required()
          });
        },
        ...idtOptionsExample,
        sequence: {
          id: "382",
          name: "pj5001",
          sequence: "AAABBCC",
          circular: false
        }
        // {
        //   id: "383",
        //   name: "pj5002",
        //   sequence: "AAABBCCCCCC",
        //   vector: "N/A"
        // }
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            error: yup.string(),
            warning: yup.string(),
            alerts: yup.array(yup.string()),
            buildable: yup.boolean()
          });
        },
        buildable: true
      }
    },
    VENDOR__QUOTE: {
      name: "Quote",
      description: "Get the price of sequence",
      method: "POST",
      // this will get the price for a single construct
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            ...idtOptions,
            sequence: yup
              .object({
                id: yup.string().required(),
                name: yup.string().required(),
                sequence: yup.string().required(),
                circular: yup.boolean().required()
              })
              .required()
          });
        },
        ...idtOptionsExample,
        sequence: {
          id: "382",
          name: "pj5001",
          sequence: "AAABBCC",
          circular: false
        }
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            id: yup.string().required(),
            price: yup.number(),
            error: yup.string()
          });
        },
        id: "382",
        price: 30.0
      }
    },
    VENDOR__TABLE: {
      name: "Generate Order Table",
      description: "Generate table rows for ordering from IDT",
      method: "POST",
      // this will get the price for a single construct
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            ...idtOptions,
            sequences: yup
              .array(
                yup.object({
                  id: yup.string().required(),
                  name: yup.string().required(),
                  sequence: yup.string().required()
                })
              )
              .required()
          });
        },
        ...idtOptionsExample,
        sequences: [
          {
            id: "382",
            name: "pj5001",
            sequence: "AAABBCC",
            circular: false
          }
        ]
      },
      exampleResponse: {
        __validate__: () => {
          return yup
            .object({
              tables: yup
                .array(
                  yup.object({
                    name: yup.string().required(),
                    rows: yup.array(yup.object()).required()
                  })
                )
                .required()
            })
            .required();
        },
        tables: [
          {
            name: "Order 1",
            rows: [
              {
                name: "Sequence 1",
                sequence: "AAACCCGGG"
              }
            ]
          }
        ]
      }
    }
  }
};
export default IDT_ORDERING;
