/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { showConfirmationDialog } from "@teselagen/ui";
import {
  calculateTm,
  getReverseComplementSequenceString
} from "@teselagen/sequence-utils";
import { safeUpsert, safeQuery, deleteWithQuery } from "../apolloMethods";
import sequenceStringToFragments from "../../../tg-iso-shared/src/sequence-import-utils/sequenceStringToFragments";
import { Callout } from "@blueprintjs/core";
import modelNameToLink from "../utils/modelNameToLink";
import { fullUrl } from "../utils/generalUtils";

export async function beforeAnnotationCreate({
  annotationTypePlural,
  annotation,
  props,
  bases,
  isEdit
}) {
  if (annotationTypePlural === "primers") {
    const { name, useLinkedOligo } = annotation;

    if (isEdit && !useLinkedOligo) {
      //important to set them undefined (and not just delete them) since upsertPrimer() spreads onto the old annotation
      annotation.linkedOligo = undefined;
      annotation.bases = undefined;
      annotation.oligoWithBindingSite = undefined;
      annotation.oligoWithBindingSiteId = undefined;
      annotation.oligoWithBindingSiteName = undefined;
      return;
    } else if (isEdit && useLinkedOligo) {
      if (
        annotation.bases !== (props.initialValues && props.initialValues.bases)
      ) {
        if (annotation.oligoWithBindingSiteId) {
          const confirm = await showConfirmationDialog({
            text: (
              <div>
                It looks like you're editing the underlying Oligo bases. Do you
                want to add a new Oligo to your library or update the existing
                linked Oligo?
                <br></br>
                <br></br>
                <Callout intent="warning">
                  Note updating the existing Oligo will change the bases for all
                  its linked binding sites!
                </Callout>
              </div>
            ),
            confirmButtonText: "Create New",
            thirdButtonText: "Update Existing",
            cancelButtonText: "Cancel",
            canEscapeKeyCancel: true
          });
          if (confirm === "thirdButtonClicked") {
            // update existing case
            await deleteWithQuery("sequenceFragment", {
              sequenceId: annotation.oligoWithBindingSiteId
            });

            await safeUpsert(
              "sequenceFragment",
              sequenceStringToFragments(bases).map(f => ({
                ...f,
                sequenceId: annotation.oligoWithBindingSiteId
              }))
            );

            await safeUpsert("sequence", {
              id: annotation.oligoWithBindingSiteId,
              tm: calculateTm(bases),
              tm3Prime: calculateTm(getReverseComplementSequenceString(bases)),
              size: bases.length
            });
            window.toastr.success("Successfully Updated the Existing Oligo", {
              link: modelNameToLink("oligo", annotation.oligoWithBindingSiteId),
              linkText: "Open Oligo"
            });
            return;
          } else if (confirm) {
            //create new case - do nothing and continue - subsequent logic should handle the create
          } else {
            //cancel the create/edit
            return false;
          }
        } else {
          //continue on since there's no existing oligo linkage yet
        }
      } else if (annotation.oligoWithBindingSiteId) {
        return; //don't need to run any of the subsequent steps because the annotation is already linked to an existing oligo that isn't being changed
      }
    }
    //see if oligo already exists in primer library
    const [existingOligo] = await safeQuery(["sequence", "id name"], {
      variables: {
        filter: {
          sequenceTypeCode: "OLIGO",
          fullSequence: bases,
          isJ5Sequence: false
        },
        pageSize: 1
      }
    });
    let confirm;
    if (existingOligo) {
      confirm = await showConfirmationDialog({
        text: (
          <div>
            We've detected a matching oligo in the Oligo Library -{" "}
            <a
              href={fullUrl(modelNameToLink("oligo", existingOligo.id))}
              target="_blank"
              rel="noopener noreferrer"
            >
              {existingOligo.name}
            </a>{" "}
            - Do you want to link to this oligo? You can also add a new oligo to
            your library
          </div>
        ),
        confirmButtonText: "Link Existing",
        canEscapeKeyCancel: true,
        fourthButtonText: "Do Not Link",
        thirdButtonText: "Add New"
      });
      if (confirm === false) return false;
      if (confirm === true) {
        //link to the existing oligo
        annotation.oligoWithBindingSiteId = existingOligo.id;
        annotation.oligoWithBindingSiteName = existingOligo.name;
        window.toastr.success("Primer linked to oligo library", {
          link: modelNameToLink("oligo", existingOligo.id),
          linkText: "Open Oligo"
        });
      }
    } else {
      if (useLinkedOligo) {
        confirm = true;
      }
    }

    if (existingOligo ? confirm === "thirdButtonClicked" : confirm) {
      //create a new oligo
      const [oligo] = await safeUpsert("sequence", {
        name,
        sequenceTypeCode: "OLIGO",
        tm: calculateTm(bases),
        tm3Prime: calculateTm(getReverseComplementSequenceString(bases)),
        sequenceFragments: sequenceStringToFragments(bases),
        size: bases.length
      });
      if (oligo) {
        annotation.oligoWithBindingSiteId = oligo.id;
        annotation.oligoWithBindingSiteName = name;
        window.toastr.success("Primer saved to oligo library", {
          link: modelNameToLink("oligo", oligo.id),
          linkText: "Open Oligo"
        });
      }
    }
  }
}
