/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import { remove } from "lodash";
import { Icon } from "@blueprintjs/core";
import SingleLabModeSelector from "../SingleLabModeSelector";
import { getUserLabs, labStorageKey } from "../utils/labUtils";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";
import CurrentUserContext from "../context/CurrentUserContext";

export const getLabOptions = (currentUser, asId, withActiveLab) => {
  const activeLabId = sessionStorage.getItem(labStorageKey);
  let labs = getUserLabs(currentUser).map(lab => {
    let label = lab.name;

    if (withActiveLab && lab.id === activeLabId) {
      label = (
        <span>
          <Icon icon="dot" /> {label}
        </span>
      );
    }

    return {
      label,
      value: asId ? lab.id : lab
    };
  });

  if (activeLabId && activeLabId !== COMMON_LAB_ID) {
    const activeItem = remove(labs, labOrLabId => {
      return asId
        ? labOrLabId.value === activeLabId
        : labOrLabId.value.id === activeLabId;
    });
    labs = [...activeItem, ...labs];
  }

  return labs;
};

export default function ActiveLabWidget(props) {
  const { currentUser } = useContext(CurrentUserContext);
  const options = getLabOptions(currentUser);
  const firstLabOption = options[0];

  return (
    <SingleLabModeSelector
      options={options}
      defaultLabId={firstLabOption?.id || ""}
      defaultLab={firstLabOption}
      {...props}
    />
  );
}
