/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import importDesignFromHdeJson from "../../../../tg-iso-design/designImport/importDesignFromHdeJson";
import { isoContext } from "@teselagen/utils";
// TODO:
// Apply logic of handle nested records as others handlers

const DESIGN = async function ({ recordsToImport }, ctx = isoContext) {
  try {
    await Promise.all(
      recordsToImport.map(async record => {
        const overrides = {};
        const checkDuplicates = false;

        await importDesignFromHdeJson(
          record.design,
          overrides,
          checkDuplicates,
          ctx
        );
      })
    );
  } catch (error) {
    console.error(error.message);
  }
};

export default DESIGN;
