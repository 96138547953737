/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  query appSettingsQuery {
    appSettings {
      results {
        code
        value
        type
      }
    }
  }
`;
