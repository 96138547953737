/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Tooltip } from "@blueprintjs/core";
import "./style.css";

export default class AddButton extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <g>
        <Tooltip wrapperTagName="g" targetTagName="g">
          <path
            className="add-button"
            d="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
            {...this.props}
          />
          <div>{className === "add-row-button" ? "Add Row" : "Add Bin"}</div>
        </Tooltip>
      </g>
    );
  }
}
