/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";

import GenericSelect from "../../../../src-shared/GenericSelect";
import { Classes } from "@blueprintjs/core";

const EditContainerInFreezerSetupDialog = ({
  handleSubmit,
  hideModal,
  onSubmit,
  submitting
}) => (
  <>
    <div className={Classes.DIALOG_BODY}>
      <InputField name="name" label="Name" isRequired />
      <InputField name="label" label="Label" isRequired />
      <GenericSelect
        asReactSelect
        name="containerType"
        isRequired
        idAs="code"
        label="Container Type"
        fragment={["containerType", "code name"]}
      />
    </div>
    <DialogFooter
      hideModal={hideModal}
      submitting={submitting}
      onClick={handleSubmit(values => {
        hideModal();
        onSubmit(values);
      })}
    />
  </>
);

export default compose(
  wrapDialog({ title: "Edit Container Settings" }),
  reduxForm({
    form: "editContainerFreezerSetup"
  })
)(EditContainerInFreezerSetupDialog);
