/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* Copyright (C) 2024 TeselaGen Biotechnology, Inc. */
import { isoContext } from "@teselagen/utils";
import { modelToExportFragment } from "../../crudHandlers/modelToExportFragment-conversionFn";

const { fragment: proteinMaterialFragment } = modelToExportFragment({
  subtype: "PROTEIN_MATERIAL"
});

/**
 * Upserts protein material records.
 *
 * @param {Object} options - The options for upserting protein material records.
 * @param {Array} options.recordsToImport - The records to import.
 * @param {Object} options.upsertHandlers - The upsert handlers.
 * @param {Object} options.rest - The rest of the options.
 * @param {Object} [ctx=isoContext] - The context object.
 * @returns {Promise<void>} - A promise that resolves when the upsert is complete.
 */
export default async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  const { safeUpsert } = ctx;
  console.info("Starting PROTEIN_MATERIAL.js upsert");
  console.info("recordsToImport", recordsToImport);

  // Check no ids on functionalProteinUnit.
  // For simplicity, we don't allow fpus objects to have ids (and be updated)
  // We always build a new one on update and delete the old one
  recordsToImport.forEach(r => {
    if (r.functionalProteinUnit?.id) {
      throw new Error("FunctionalProteinUnit should not have id");
    }
  });

  // First upsert aa sequences
  await uploadRelatedAminoAcidSequences(
    { recordsToImport, upsertHandlers, ...rest },
    ctx
  );

  // Process SubUnits:
  await makeSubUnits({ recordsToImport, upsertHandlers, ...rest }, ctx);

  // Get which records are new and which are explicit updates
  const recordsToCreate = recordsToImport.filter(r => !r.id);
  const recordsToUpdate = recordsToImport.filter(r => r.id);

  // Create the records
  if (recordsToCreate.length) {
    console.info("Creating new records");
    const upsertResults = await safeUpsert("material", recordsToCreate);
    // Add ids to the records that were created
    recordsToCreate.forEach((record, i) => {
      record.id = upsertResults[i].id;
    });
    console.info("Records created: ", recordsToCreate);
  }

  // Update the records that should be updated
  if (recordsToUpdate.length) {
    await update({ recordsToUpdate }, ctx);
  }

  console.info("Final imported records", recordsToImport);
}

/**
 * Uploads data when update is required.
 *
 * @param {Object} options - The options for the update operation.
 * @param {Array} options.recordsToUpdate - The records to update.
 * @param {Object} [options.ctx=isoContext] - The context object.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 */
async function update({ recordsToUpdate }, ctx = isoContext) {
  const { safeUpsert, safeQuery, safeDelete } = ctx;
  console.info("Starting update");
  console.info("recordsToUpdate: ", recordsToUpdate);

  // Add ids of fpu if they exist at __oldRecord
  // We collect these ids to remove the objects at the end
  recordsToUpdate.forEach(record => {
    if (
      record.functionalProteinUnit &&
      !record.functionalProteinUnit.id &&
      record.__oldRecord &&
      record.__oldRecord.functionalProteinUnit
    ) {
      record.functionalProteinUnit.id =
        record.__oldRecord.functionalProteinUnit.id;
    }
  });

  // Make a list of existing fpu ids
  const existingFpuIds = [];
  recordsToUpdate.forEach(r => {
    if (r.functionalProteinUnit?.id) {
      existingFpuIds.push(r.functionalProteinUnit.id);
    }
  });

  // Remove fpus ids because we won't make updates on this object, just create from zero
  // We also remove __oldRecord, __newRecord fields because we shouldn't upload them
  // We store those fields in a backup array to restore them later
  const recordsToUpdateBackup = [];
  recordsToUpdate.forEach(record => {
    recordsToUpdateBackup.push(JSON.parse(JSON.stringify(record)));

    if (record.functionalProteinUnit) {
      delete record.functionalProteinUnit.id;
    }
    delete record.__newRecord;
    delete record.__oldRecord;
  });

  // Filter records and get the ones that have functionalProteinUnit
  const recordsWithFpusToUpsert = [];
  recordsToUpdate.forEach(r => {
    if (r.functionalProteinUnit) {
      recordsWithFpusToUpsert.push(r);
    }
  });

  // Upsert the functionalProteinUnits
  const upsertResults = await safeUpsert(
    "functionalProteinUnit",
    recordsWithFpusToUpsert.map(r => r.functionalProteinUnit)
  );

  // Add new ids to functionalProteinUnitId
  recordsWithFpusToUpsert.forEach((record, i) => {
    record.functionalProteinUnitId = upsertResults[i].id;
  });

  // Remove the functionalProteinUnit from the records to update proteinMaterial
  recordsWithFpusToUpsert.forEach(record => {
    delete record.functionalProteinUnit;
  });

  // Delete old fpus in database
  await safeDelete("functionalProteinUnit", existingFpuIds);

  // Update the records
  await safeUpsert("material", recordsToUpdate);

  // Safe query to get the updated records (we want to return exactly what is at db)
  const updatedRecords = await safeQuery(proteinMaterialFragment, {
    variables: {
      filter: {
        id: recordsToUpdate.map(r => r.id)
      }
    }
  });

  // Restore __oldRecord and __newRecord on recordsToUpdate
  // also add refreshed functionalProteinUnit to the recordsToUpdate
  // because this is required by the upsert function
  recordsToUpdate.forEach((record, i) => {
    record.__oldRecord = recordsToUpdateBackup[i].__oldRecord;
    record.__newRecord = recordsToUpdateBackup[i].__newRecord;
    record.functionalProteinUnit = updatedRecords[i].functionalProteinUnit;
  });
}

/**
 * Uploads related amino acid sequences.
 *
 * @param {Object} options - The options for uploading amino acid sequences.
 * @param {Array} options.recordsToImport - The records to import.
 * @param {Object} options.upsertHandlers - The upsert handlers.
 * @param {Object} [options.rest] - Additional parameters.
 * @param {Object} [ctx=isoContext] - The context.
 * @returns {Promise<void>} - A promise that resolves when the upload is complete.
 * @throws {Error} - If there is an error upserting the amino acid sequence.
 */
async function uploadRelatedAminoAcidSequences(
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  console.info("Starting uploadRelatedAminoAcidSequences");

  // Go through all protein subunits and get their aa sequence
  const aaSequencesToUpsert = [];
  recordsToImport.forEach(proteinMaterial => {
    if (proteinMaterial.functionalProteinUnit?.proteinSubUnits) {
      proteinMaterial.functionalProteinUnit.proteinSubUnits.forEach(
        (proteinSubUnit, subUnitIndex) => {
          if (
            !proteinSubUnit.aminoAcidSequence &&
            !proteinSubUnit.aminoAcidSequenceId
          ) {
            console.info(
              "No aminoAcidSequence or aminoAcidSequenceId at proteinSubUnit",
              proteinSubUnit
            );
          }

          // If id is in aminoAcidSequence, we have to add __oldRecord
          if (proteinSubUnit.aminoAcidSequence?.id) {
            if (
              proteinMaterial.__oldRecord?.functionalProteinUnit
                ?.proteinSubUnits[subUnitIndex]?.aminoAcidSequence
            ) {
              proteinSubUnit.aminoAcidSequence.__oldRecord =
                proteinMaterial.__oldRecord.functionalProteinUnit.proteinSubUnits[
                  subUnitIndex
                ].aminoAcidSequence;
            } else {
              throw new Error(
                "aminoAcidSequence Id was set, but can't found it at the dataset"
              );
            }
            proteinSubUnit.aminoAcidSequence.__newRecord =
              JSON.parse(
                JSON.stringify(
                  proteinMaterial.__newRecord.functionalProteinUnit
                    .proteinSubUnits[subUnitIndex].aminoAcidSequence
                )
              ) || JSON.parse(JSON.stringify(proteinSubUnit.aminoAcidSequence));
          }
          if (proteinSubUnit.aminoAcidSequence) {
            aaSequencesToUpsert.push(proteinSubUnit.aminoAcidSequence);
          }
        }
      );
    } else {
      console.info("No proteinSubUnits, at proteinMaterial", proteinMaterial);
    }
  });

  console.info(
    `Found ${aaSequencesToUpsert.length} amino acid sequences to upsert`
  );

  // Upsert the amino acid sequences
  await upsertHandlers.AMINO_ACID({
    recordsToImport: aaSequencesToUpsert,
    ...rest
  });

  console.info("Finished upserting amino acid sequences", aaSequencesToUpsert);

  // Raise errors if found any in aaSequences
  aaSequencesToUpsert.forEach(aaSeq => {
    if (aaSeq.__importFailed) {
      throw new Error(
        `Error upserting amino acid sequence: ${aaSeq.__importFailed}`
      );
    }
  });

  const sequencesToQuery = aaSequencesToUpsert;

  // Add aminoAcidSequence object to the proteinSubUnits that has aminoAcidSequenceId
  // and push these sequences to sequencesToQuery
  recordsToImport.forEach(proteinMaterial => {
    if (proteinMaterial.functionalProteinUnit?.proteinSubUnits) {
      proteinMaterial.functionalProteinUnit.proteinSubUnits.forEach(
        proteinSubUnit => {
          if (proteinSubUnit.aminoAcidSequenceId) {
            proteinSubUnit.aminoAcidSequence = {
              id: proteinSubUnit.aminoAcidSequenceId
            };
            // We add the aa sequence into aaSequences so we can retrieve molecularWeight and extinctionCoefficient
            sequencesToQuery.push(proteinSubUnit.aminoAcidSequence);
          }
        }
      );
    }
  });

  // Stop if there are no sequences to process
  if (sequencesToQuery.length === 0) {
    return;
  }

  // Retrieve molecularWeight extinctionCoefficient
  const queriedAASequences = await ctx.safeQuery(
    ["aminoAcidSequence", "id molecularWeight extinctionCoefficient"],
    {
      variables: {
        filter: {
          id: sequencesToQuery.map(r => r.id)
        }
      }
    }
  );

  // Add the molecularWeight and extinctionCoefficient to the aaSequences
  sequencesToQuery.forEach((aaSeq, i) => {
    aaSeq.molecularWeight = queriedAASequences[i].molecularWeight;
    aaSeq.extinctionCoefficient = queriedAASequences[i].extinctionCoefficient;
  });

  console.info("Finished uploadRelatedAminoAcidSequences");
}

/**
 * Creates protein subunits based on the given records to import. Just creates them without upserting.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.recordsToImport - The array of protein materials to import.
 * @param {Object} options.upsertHandlers - The upsert handlers object.
 * @param {Object} options.rest - The rest object.
 * @param {Object} ctx - The context object.
 * @param {Function} ctx.safeDelete - The safe delete function.
 * @returns {Promise<void>} - A promise that resolves when the protein subunits are upserted.
 * @throws {Error} - If there are any validation errors.
 */
async function makeSubUnits({ recordsToImport }, ctx) {
  console.info("Starting making subunits");
  const { safeDelete } = ctx;

  for (let i = 0; i < recordsToImport.length; i++) {
    const proteinMaterial = recordsToImport[i];
    if (
      !proteinMaterial.functionalProteinUnit ||
      !proteinMaterial.functionalProteinUnit.proteinSubUnits
    ) {
      continue;
    }

    const proteinSubUnits = [];
    let fpuMolecularWeight = 0;
    let fpuExtinctionCoefficient = 0;

    // Review index field at proteinSubUnits.
    const indexes = proteinMaterial.functionalProteinUnit.proteinSubUnits.map(
      s => s.index
    );
    const hasIndexes = indexes.some(i => i !== undefined);
    if (hasIndexes) {
      // If one subunit contains index, all of them should contain it.
      if (!indexes.every(i => i !== undefined)) {
        throw new Error("Some proteinSubUnits have index, but not all of them");
      }
      // Check all indexes are numbers
      if (!indexes.every(i => typeof i === "number")) {
        throw new Error("Some proteinSubUnits indexes are not numbers");
      }
      // Check indexes are unique and consecutive and start at 0
      const sortedIndexes = indexes.sort();
      if (sortedIndexes[0] !== 0) {
        throw new Error(`Indexes start at ${sortedIndexes[0]}`);
      }
      for (let i = 0; i < sortedIndexes.length; i++) {
        if (sortedIndexes[i] !== i) {
          throw new Error("Indexes are not unique and consecutive");
        }
      }
    }

    // Add molecularWeight and extinctionCoefficient to the functionalProteinUnit
    // based on the sum of the molecularWeight and extinctionCoefficient of the proteins subunits
    proteinMaterial.functionalProteinUnit.proteinSubUnits.forEach(
      proteinSubUnit => {
        const { molecularWeight, extinctionCoefficient } =
          proteinSubUnit.aminoAcidSequence;
        fpuMolecularWeight += molecularWeight || 0;
        fpuExtinctionCoefficient += extinctionCoefficient || 0;
        proteinSubUnits.push({
          index:
            typeof proteinSubUnit.index === "number"
              ? proteinSubUnit.index
              : proteinSubUnits.length,
          aminoAcidSequenceId: proteinSubUnit.aminoAcidSequence.id,
          name: proteinSubUnit.name || proteinSubUnit.aminoAcidSequence.name
        });
      }
    );
    proteinMaterial.functionalProteinUnit.molecularWeight = fpuMolecularWeight;
    proteinMaterial.functionalProteinUnit.extinctionCoefficient =
      fpuExtinctionCoefficient;
    proteinMaterial.functionalProteinUnit.proteinSubUnits = proteinSubUnits;

    // Delete the old proteinSubUnits (existent when updating)
    if (
      proteinMaterial.__oldRecord &&
      proteinMaterial.__oldRecord.functionalProteinUnit
    ) {
      const oldProteinSubUnits =
        proteinMaterial.__oldRecord.functionalProteinUnit.proteinSubUnits;
      if (oldProteinSubUnits) {
        const oldProteinSubUnitIds = oldProteinSubUnits.map(s => s.id);
        await safeDelete("proteinSubUnit", oldProteinSubUnitIds);
      }
    }
  }
  console.info("Finished making subunits");
}
