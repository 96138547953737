/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { addReagentsContainerArrayFragment } from "./fragments";

class SelectPlates extends Component {
  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;

    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates from Inventory"
            helper="Select one or more plates to add reagents to."
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              isRequired: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              isMultiSelect: true,
              fragment: [
                "containerArray",
                "id name barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: addReagentsContainerArrayFragment,
              postSelectDTProps: {
                formName: "addReagentsSelectPlatesTable",
                schema: [
                  platePreviewColumn(),
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  {
                    displayName: "Plate Type",
                    path: "containerArrayType.name"
                  },
                  dateModifiedColumn
                ]
              },
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default SelectPlates;
