/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import modelNameToLink from "./modelNameToLink";
import modelNameToReadableName from "./modelNameToReadableName";

export default function createMenuFromModelNamesArray(array) {
  const nameOpts = { upperCase: true, plural: true };
  const menu = array.map(model => {
    if (!model) return undefined;
    if (model === "-") {
      return { divider: "" };
    }
    let modelText = model.text || modelNameToReadableName(model, nameOpts);
    if (model === "codingDNASequence") {
      modelText = "Coding Sequences";
    }
    if (model === "newOrder") {
      return {
        text: "New Order",
        key: model,
        navTo: "/bioshop/"
      };
    } else if (model === "eln") {
      return {
        text: "Electronic Lab Notebook",
        key: model,
        navTo: "/eln/"
      };
    } else {
      return {
        text: modelText,
        key: model,
        navTo: model.navTo || modelNameToLink(model)
      };
    }
  });
  return menu;
}
