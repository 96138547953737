/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
// import EnzymeLibraryViewer from '../components/EnzymeLibraryViewer'
import { hideDialog, showDialog } from "../GlobalDialog";
import AbstractLibrary from "../AbstractLibrary";
import gql from "graphql-tag";
import libraryEnhancer from "../libraryEnhancer";
import { CheckboxField, wrapDialog } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import {
  DataTable,
  DialogFooter,
  InputField,
  TextareaField,
  withSelectedEntities
} from "@teselagen/ui";
import GenericSelect from "../GenericSelect";
import { Classes } from "@blueprintjs/core";
import { deleteWithQuery, safeUpsert } from "../apolloMethods";
import LibraryInspector from "../AbstractLibrary/LibraryInspector";
import EnzymeVisual from "../components/Dialogs/CreateRestrictionEnzymeDialog/EnzymeVisual";
import { regexStrToRegularSequence } from "../../../tg-iso-shared/utils/enzymeUtils";
import { asyncValidateName } from "../utils/formUtils";
import { withProps } from "recompose";

function EnzymeGroupEnzymesView({
  enzymeGroup,
  enzymeGroupEnzymesTableSelectedEntities = []
}) {
  const _selectedEnzyme = enzymeGroupEnzymesTableSelectedEntities[0];
  const enzymes = enzymeGroup.enzymeToGroups.map(etg => etg.restrictionEnzyme);
  // if the enzyme was removed then the table will still have it in redux but we don't want to show it
  const selectedEnzyme =
    _selectedEnzyme && enzymes.find(e => e.id === _selectedEnzyme.id);
  return (
    <div>
      {selectedEnzyme && (
        <>
          <h6>{selectedEnzyme.name}</h6>
          <EnzymeVisual
            {...selectedEnzyme}
            recognitionRegex={regexStrToRegularSequence(
              selectedEnzyme.recognitionRegex
            )}
            label={null}
          />
          <div style={{ marginTop: 15 }} />
        </>
      )}
      <DataTable
        isSimple
        maxHeight={300}
        isSingleSelect
        formName="enzymeGroupEnzymesTable"
        schema={[
          {
            displayName: "Enzymes",
            path: "name"
          }
        ]}
        entities={enzymes}
      />
    </div>
  );
}

const WrappedEnzymeGroupEnzymes = withSelectedEntities(
  "enzymeGroupEnzymesTable"
)(EnzymeGroupEnzymesView);

const ViewerComponent = props => (
  <LibraryInspector
    {...props}
    withDescription
    withSharingInformation={false}
    renderExtraItemsBottom={enzymeGroup => {
      return <WrappedEnzymeGroupEnzymes enzymeGroup={enzymeGroup} />;
    }}
  />
);

const schema = {
  model: "enzymeGroup",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    }
  ]
};

const EnzymeLibrary = props => {
  const { refetch } = props;
  const onNewItemClick = () => {
    showDialog({
      ModalComponent: NewEnzymeGroupDialog,
      modalProps: {
        refetch: refetch
      }
    });
  };

  const onDoubleClick = enzymeGroup => {
    showDialog({
      ModalComponent: NewEnzymeGroupDialog,
      modalProps: {
        refetch: refetch,
        initialValues: {
          ...enzymeGroup,
          enzymes: enzymeGroup.enzymeToGroups.map(etg => etg.restrictionEnzyme)
        }
      }
    });
  };

  return (
    <AbstractLibrary
      {...props}
      noOpen
      noRename
      alwaysShowInspector
      inspectorWidth={350}
      isLibraryTable
      onDoubleClick={onDoubleClick}
      onEdit={onDoubleClick}
      ViewerComponent={ViewerComponent}
      onNewItemClick={onNewItemClick}
    />
  );
};

export const enzymeGroupFragment = gql`
  fragment enzymeGroupLibraryFragment on enzymeGroup {
    id
    name
    description
    enzymeToGroups {
      id
      restrictionEnzyme {
        id
        name
        forwardSnipPosition
        reverseSnipPosition
        recognitionLength
        recognitionRegex
        recognitionStart
        sequence
      }
    }
  }
`;

export default compose(
  libraryEnhancer({
    fragment: enzymeGroupFragment,
    schema,
    withSelectedEntities: true,
    noAddedBy: true
  })
)(EnzymeLibrary);

const NewEnzymeGroupDialog = compose(
  wrapDialog({ title: "New Enzyme Group" }),
  withProps(() => ({ model: "enzymeGroup" })), // needed for async validate
  reduxForm({ form: "newEnzymeGroup", ...asyncValidateName })
)(({ handleSubmit, refetch, hideModal, submitting }) => {
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField label="Name" name="name" isRequired />
        <TextareaField label="Description" name="description" />
        <GenericSelect
          isRequired
          name="enzymes"
          asReactSelect
          isMultiSelect
          label="Select Enzymes"
          fragment={["restrictionEnzyme", "id name"]}
        />
        <CheckboxField label="Use as default cutsite filter?" />
      </div>

      <DialogFooter
        onClick={handleSubmit(async values => {
          try {
            const toUpsert = {
              id: values.id,
              name: values.name,
              description: values.description
            };
            if (!values.id) {
              toUpsert.enzymeToGroups = values.enzymes.map(e => ({
                restrictionEnzymeId: e.id
              }));
            }
            await safeUpsert("enzymeGroup", toUpsert);
            if (values.id) {
              await deleteWithQuery("enzymeToGroup", {
                enzymeGroupId: values.id
              });
              await safeUpsert(
                "enzymeToGroup",
                values.enzymes.map(e => ({
                  restrictionEnzymeId: e.id,
                  enzymeGroupId: values.id
                }))
              );
            }
            await refetch();
            hideModal();
          } catch (error) {
            console.error("error:", error);
            window.toastr.error(
              `Error ${values.id ? "editing" : "creating"} enzyme group.`
            );
          }
        })}
        hideModal={hideDialog}
        submitting={submitting}
      />
    </>
  );
});
