/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";

import {
  InputField,
  TextareaField,
  SelectField,
  CheckboxField,
  Loading,
  IntentText
} from "@teselagen/ui";
import defaultAsyncWrap from "../../../../../src-shared/utils/defaultAsyncWrap";
import schematicFromDesign from "../../../../utils/schematicFromDesign";
import "./style.css";
import { compose } from "recompose";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { Button, Card } from "@blueprintjs/core";
import { isAdmin } from "../../../../../src-shared/utils/generalUtils";

class DesignPanel extends Component {
  shouldSubmitForAssemblyBeDisabled = () => {
    const { canSubmitForAssembly } = this.props;
    return !canSubmitForAssembly;
  };

  handleFieldSubmit = field => newValue => {
    const { valid, updateDesign } = this.props;
    if (valid) {
      updateDesign({ [field]: newValue });
    }
  };

  // This is needed to prevent a unsaved change destroying refresh of the
  // page upon pressing enter.
  onSubmit = () => {};

  handleCreateSchematicFromDesign = defaultAsyncWrap(async () => {
    const { design, history } = this.props;
    const diagramId = await schematicFromDesign(design.id);
    history.push(`/schematics/${diagramId}`);
  }, "Error creating schematic from design.");

  render() {
    const {
      loading,
      design,
      updateDesign,
      exportToJson,
      handleSubmit,
      hasPartsets,
      toggleRemoveInterruptedFeatures
    } = this.props;
    if (loading) return <Loading />;

    const isLocked = !!design.isLocked || !!design.lockId;

    return (
      <Card>
        <form
          className="design-inspector-panel"
          style={{
            padding: 2
          }}
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <h4 className="inspector-panel-header">Design Details</h4>

          <h5 className="de-locked-status" style={{ flexDirection: "column" }}>
            {isLocked && (
              <IntentText intent="warning">
                <div style={{ fontSize: 15 }}>Locked.</div>
              </IntentText>
            )}
            {isLocked && !!design.lockedMessage && (
              <p>{design.lockedMessage}</p>
            )}
            {isLocked && !!design.lockMsgDescription && (
              <p className="de-locked-status-description">
                {design.lockMsgDescription}
              </p>
            )}
          </h5>
          <InputField
            name="name"
            label="Name"
            isRequired
            readOnly={isLocked}
            onFieldSubmit={this.handleFieldSubmit("name")}
          />
          <TextareaField
            name="description"
            label="Description"
            readOnly={isLocked}
            onFieldSubmit={this.handleFieldSubmit("description")}
          />
          {design.type === "design-template" && (
            <InputField
              name="numPlaceholders"
              label="Number of Placeholders"
              readOnly
            />
          )}
          <SelectField
            name="layoutType"
            label="Design Layout Type"
            onFieldSubmit={this.handleFieldSubmit("layoutType")}
            disabled={hasPartsets || isLocked}
            options={defaultValueConstants.DESIGN_LAYOUT_TYPE.options}
          />
          {design.type === "grand-design" && (
            <CheckboxField
              disabled={isLocked}
              name="doNotReuseOligos"
              label="Do NOT reuse oligos from library"
              onChange={e => {
                updateDesign({
                  doNotReuseOligos: !!e.target.checked,
                  doNotAddToUndoStack: true
                });
              }}
            />
          )}
          {design.type === "grand-design" && (
            <CheckboxField
              name="removeInterruptedFeatures"
              disabled={isLocked}
              onChange={toggleRemoveInterruptedFeatures}
              label="Remove interrupted features from constructs"
            />
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              text="Export to JSON"
              style={{ marginTop: "5px", marginBottom: "5px" }}
              className="export-to-json-button"
              onClick={() => {
                exportToJson();
              }}
            />
            {design.type === "design-template" && !isLocked && (
              <Button
                text="Lock Template"
                className="lock-template-button"
                onClick={() =>
                  updateDesign({
                    isLocked: true,
                    lockedMessage: "This design template has been locked",
                    lockMsgDescription: null,
                    lockTypeCode: "LOCK_ENTIRE_DESIGN"
                  })
                }
                style={{ marginBottom: "5px" }}
              />
            )}
            {design.type === "design-template" && isLocked && !!isAdmin() && (
              <Button
                text="Unlock Template"
                className="unlock-template-button"
                onClick={() =>
                  updateDesign({
                    isLocked: false,
                    lockedMessage: null,
                    lockTypeCode: null,
                    // This flag is used by the 'handleEditDesignAction' to allow editing Locked designs.
                    overrideLock: true
                  })
                }
                style={{ marginBottom: "5px" }}
              />
            )}
            {design.type === "grand-design" &&
              !window.frontEndConfig.disabledToolkits
                .advancedMolecularBiology && (
                <Button
                  key="createSchematicFromDesign"
                  text="Create Schematic from Design"
                  onClick={this.handleCreateSchematicFromDesign}
                />
              )}
          </div>
        </form>
      </Card>
    );
  }
}

export default compose(
  reduxForm({
    form: "designInspectorEditDesign",
    enableReinitialize: true
  })
)(DesignPanel);
