/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { externalReferenceKeys } from "../constants";
import { startCase, get } from "lodash";

export function getExternalReferenceColumns({ prefix } = {}) {
  const fields = [];
  externalReferenceKeys.forEach(key => {
    fields.push({
      isHidden: typeof window !== "undefined" && !get(window, "Cypress"),
      path: (prefix ? `${prefix}.` : "") + key,
      type: "string",
      fieldKey: key,
      displayName: startCase(key.replace("Reference", "")).replace("Id", "ID")
    });
  });
  return fields;
}
