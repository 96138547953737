/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import {
  DialogFooter,
  wrapDialog,
  SelectField,
  InputField
} from "@teselagen/ui";
import {
  concentrationRender,
  volumeRender,
  massRender,
  standardizeMass,
  standardizeVolume
} from "../../../../src-shared/utils/unitUtils";
import withQuery from "../../../../src-shared/withQuery";

import "./style.css";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import { withProps } from "recompose";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getAliquotMaterialString } from "../../../utils/plateUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import ManuallyAssignAliquotDialog from "../ManuallyAssignAliquotDialog";
import { copySampleFormulationsFromAliquot } from "../../../../../tg-iso-lims/src/utils/aliquotUtils";
import UnitInputField from "../../UnitInputField";

class NewReplicateAliquotDialog extends Component {
  onSubmit = async values => {
    try {
      const { sourceAliquot, history, refetch } = this.props;
      const { volume, mass, fermentedBatchType } = values;

      const isFermentedBatch =
        sourceAliquot.sample.sampleTypeCode === "FERMENTED_BATCH";
      const {
        id: sourceAliquotId,
        isDry,
        volume: sourceVolume,
        volumetricUnitCode,
        mass: sourceMass,
        massUnitCode,
        sample: { id: sampleId },
        concentration,
        concentrationUnitCode
      } = sourceAliquot;

      let sampleIdToUse = sampleId;

      if (isFermentedBatch) {
        const [newSample] = await safeUpsert("sample", {
          name: values.sampleName,
          sampleTypeCode:
            fermentedBatchType === "Fermented Batch"
              ? "FERMENTED_BATCH"
              : "FORMULATED_SAMPLE",
          sampleFormulations: copySampleFormulationsFromAliquot(sourceAliquot)
        });
        sampleIdToUse = newSample.id;
      }

      const [replicateAliquot] = await safeUpsert("aliquot", {
        aliquotType: "replicate",
        isDry,
        sampleId: sampleIdToUse,
        concentration,
        concentrationUnitCode,
        replicateParentAliquotId: sourceAliquotId,
        ...(isDry
          ? {
              mass,
              massUnitCode
            }
          : {
              volume,
              volumetricUnitCode
            })
      });
      await safeUpsert("aliquot", {
        id: sourceAliquotId,
        ...(isDry
          ? { mass: sourceMass - mass }
          : {
              volume: sourceVolume - volume
            })
      });
      await refetch();
      showDialog({
        ModalComponent: ManuallyAssignAliquotDialog,
        modalProps: {
          aliquot: replicateAliquot,
          refetch: () => {
            history.push(`/aliquots/${replicateAliquot.id}`);
          }
        }
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating replicate aliquot.");
    }
  };

  render() {
    const { hideModal, submitting, sourceAliquot, handleSubmit } = this.props;

    if (!sourceAliquot) {
      return "No source aliquot provided";
    }

    const isFermentedBatch =
      sourceAliquot.sample.sampleTypeCode === "FERMENTED_BATCH";
    const sourceAliquotInfo = [
      ["Sample", sourceAliquot.sample.name],
      ["Material", getAliquotMaterialString(sourceAliquot)]
    ];

    if (sourceAliquot.isDry) {
      sourceAliquotInfo.push(["Mass", massRender(sourceAliquot)]);
    } else {
      sourceAliquotInfo.push(["Volume", volumeRender(sourceAliquot)]);
      sourceAliquotInfo.push([
        "Concentration",
        concentrationRender(sourceAliquot)
      ]);
    }

    let transferFields;
    if (sourceAliquot.isDry) {
      transferFields = (
        <UnitInputField
          label="Mass"
          name="mass"
          unitName="massUnitCode"
          unitDefault={sourceAliquot.massUnitCode}
          unitType="massUnit"
          isRequired
        />
      );
    } else {
      transferFields = (
        <UnitInputField
          label="Volume"
          name="volume"
          unitName="volumetricUnitCode"
          unitDefault={sourceAliquot.volumetricUnitCode}
          unitType="volumetricUnit"
          isRequired
        />
      );
    }

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <div>
            <h6>Source Aliquot Information</h6>
            <RecordInfoDisplay recordInfo={sourceAliquotInfo} />
          </div>
          <hr className="tg-section-break" />
          <div>
            <h6>Replicate Aliquot Information</h6>
            {transferFields}
            {isFermentedBatch && (
              <>
                <InputField name="sampleName" isRequired label="Sample Name" />
                <SelectField
                  options={["Fermented Batch", "Titer"]}
                  isRequired
                  name="fermentedBatchType"
                  label="Select Sample Type"
                  defaultValue="Fermented Batch"
                />
              </>
            )}
          </div>
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const validate = (values, { sourceAliquot }) => {
  const { volume, mass, massUnitCode, volumetricUnitCode } = values;
  const errors = {};

  if (sourceAliquot) {
    if (sourceAliquot.isDry) {
      if (!isValidPositiveNumber(mass)) {
        errors.mass = "Transfer mass must be non-zero.";
      } else {
        const standardSourceMass = standardizeMass(
          sourceAliquot.mass,
          sourceAliquot.massUnitCode,
          true
        );
        const standardTransferMass = standardizeMass(mass, massUnitCode, true);
        if (standardTransferMass.gt(standardSourceMass)) {
          errors.mass = "Transfer mass must be less than source aliquot mass.";
        }
      }
    } else {
      if (!isValidPositiveNumber(volume)) {
        errors.volume = "Transfer volume must be non-zero.";
      } else {
        const standardSourceVolume = standardizeVolume(
          sourceAliquot.volume,
          sourceAliquot.volumetricUnitCode,
          true
        );
        const standardTransferVolume = standardizeVolume(
          volume,
          volumetricUnitCode,
          true
        );
        if (standardTransferVolume.gt(standardSourceVolume)) {
          errors.volume =
            "Transfer volume must be less than source aliquot volume.";
        }
      }
    }
  }

  return errors;
};

const aliquotFields = `
  id
  isDry
  mass
  massUnitCode
  volume
  volumetricUnitCode
  concentration
  concentrationUnitCode
  sample {
    id
    name
    sampleTypeCode
    material {
      id
      name
    }
    sampleFormulations {
      id
      materialCompositions {
        id
        materialId
        material {
          id
          name
        }
      }
    }
  }
`;

export default compose(
  wrapDialog({
    title: "New Replicate Aliquot"
  }),
  withQuery(["aliquot", aliquotFields], {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.sourceAliquotId
        }
      };
    }
  }),
  withProps(props => ({
    sourceAliquot: props.aliquot
  })),
  reduxForm({
    form: "newReplicateAliquotForm",
    validate
  })
)(NewReplicateAliquotDialog);
