/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import api from "../api";
import {
  clearLocalStorageItems,
  getAuthToken,
  setAuthToken
} from "./authTokenMethods";
import Cookies from "js-cookie";
import { startsWith } from "lodash";

// api.defaults.withCredentials = true;

let localStorage;

// If we're testing, use a local storage polyfill
if (global.process && process.env.NODE_ENV === "test") {
  localStorage = require("localStorage");
} else {
  // If not, use the browser one
  localStorage = global.window.localStorage;
}

const auth = {
  getAuthToken,
  setAuthToken,

  resetPassword(data) {
    return api.request({
      method: "POST",
      url: "/reset",
      withCredentials: true,
      data
    });
  },
  /**
   * Logs the current user out
   */
  async logout() {
    const darkMode = localStorage.getItem("darkMode") === "true";
    let ssoResponse;
    try {
      if (window.frontEndConfig.azureADLogin) {
        ssoResponse = await api.post("/adlogout");
      } else if (window.frontEndConfig.samlLogin) {
        ssoResponse = await api.post("/samllogout");
      } else if (window.frontEndConfig.googleLogin) {
        ssoResponse = await api.post("/google-logout");
      } else {
        await api.post("/auth/logout");
      }
    } catch (error) {
      console.error(error);
    }
    const authError = localStorage.getItem("auth-error");
    clearLocalStorageItems();

    Cookies.remove("auth-token");
    console.warn("Stored reset in progress...");

    window.teGlobalStore.dispatch({
      type: "APOLLO_STORE_RESET",
      observableQueryIds: []
    });

    console.warn("APOLLO REFRESH");

    // persist user darkmode setting
    if (darkMode) {
      localStorage.setItem("darkMode", true);
    }

    if (authError) {
      localStorage.setItem("auth-error", authError);
    }

    if (ssoResponse && ssoResponse.data.logoutUrl) {
      window.location.replace(ssoResponse.data.logoutUrl);
    } else {
      if (window.frontEndConfig.logoutRedirect) {
        const logoutRedirect = window.frontEndConfig.logoutRedirect;
        if (startsWith(logoutRedirect.toLowerCase(), "http")) {
          window.location.href = logoutRedirect;
        } else {
          //assume it's relative to server
          window.location.href = logoutRedirect;
        }
      } else {
        window.location.href = "/client/login";
      }
    }
  },

  /**
   * Registers a user and then logs them in
   * @param  {string} username The username of the user
   * @param  {string} password The password of the user
   */
  register(data, isRegistration) {
    let route = "/signup";

    if (isRegistration) {
      route = "/register";
    }

    return api
      .request({
        method: "POST",
        url: route,
        data
      })
      .then(res => {
        return isRegistration ? res : auth.login(data);
      });
  },

  changePassword(data, userId) {
    return api.request({
      method: "POST",
      url: "/change-password",
      data: {
        ...data,
        userId
      }
    });
  }
};

export default auth;
