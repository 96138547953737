/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment j5ItemTypeFragment on j5ItemType {
    code
    name
    description
  }
`;
