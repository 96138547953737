/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import { convertVolumeBig } from "../../../utils/unitUtils";

/**
 * Given an additive that is either dry or wet, get another additive object corresponding
 * to the same object but a certain fraction of its original size.
 * @param {Object} additive The additive that we intend to take the fraction of.
 * @param {number} fraction A number between 0 and 1.
 * @returns {object}
 */
function getFraction(additive, fraction) {
  return {
    ...additive,
    volume: additive.volume && additive.volume * fraction,
    mass: additive.mass && additive.mass * fraction
  };
}

/**
 * Given a transfer (e.g., an object in the `transfers` array of a FullFormulation),
 * return array of the additives of the source aliquot with their volumes adjusted to
 * represent the amount that is actually being transferred.
 * @param {Object} arg
 * @param {Aliquot} arg.aliquot The aliquot we are transferring.
 * @param {number} arg.volume How much we are transferring.
 * @param {string} arg.volumetricUnitCode The units the transfer `volume` is in.volume
 * @returns {Array<Object>}
 */
function getAdditivesFromTransfer({
  aliquot,
  aliquotContainer,
  volume,
  volumetricUnitCode
}) {
  let totalVolume;
  if (aliquot) {
    totalVolume = convertVolumeBig(
      aliquot.volume,
      aliquot.volumetricUnitCode,
      "L"
    );
  } else if (aliquotContainer) {
    totalVolume = aliquotContainer.additives.reduce((acc, additive) => {
      if (additive.volume) {
        acc = acc.plus(
          convertVolumeBig(additive.volume, additive.volumetricUnitCode, "L")
        );
      }
      return acc;
    }, new Big(0));
  }
  const transferVolume = convertVolumeBig(volume, volumetricUnitCode, "L");
  // The fraction of the aliquot that we are transfering.
  const fraction = transferVolume.div(totalVolume);

  return (aliquot || aliquotContainer).additives.map(a => {
    const frac = getFraction(a, fraction);
    return frac;
  });
}

export default getAdditivesFromTransfer;
