/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types, flow, applySnapshot } from "mobx-state-tree";
import dayjs from "../../../../tg-iso-shared/node_modules/dayjs";
import { getApolloMethods } from "@teselagen/apollo-methods";
import client from "../../apolloClient";
import config from "../../../src-test/configs/config.json";
import importFileSetFragment from "./importFileSetFragment";
// import { autorun } from "mobx";
import QueryBuilder from "tg-client-query-builder";
import { getActiveLabId } from "@teselagen/auth-utils";

function getDiscardedCode(code) {
  if (code.includes("-DISCARDED")) return code;
  else return `${code}-DISCARDED`;
}

const { safeQuery: query, safeDelete, safeUpsert } = getApolloMethods(client);
const ONE_HOUR = 60 * 60 * 1000;
const IMPORT_FILE_SET_IGNORE_CODES = [
  "REFORMATTING-DISCARDED",
  "MAPPING-DISCARDED",
  "INPROGRESS-DISCARDED",
  "FINISHED-DISCARDED",
  "ERROR-DISCARDED"
];

// TODO: Make this timeout available through frontEndConfigs.
const IMPORT_PROCESS_TIMEOUT_MINUTES = 10;

const assay = types.model("assay", {
  id: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  assayStatusCode: types.optional(types.string, ""), //
  protocol: types.optional(types.union(types.frozen(), types.null), {})
});

const dataFile = types.model("dataFile", {
  id: types.optional(types.string, ""),
  originalname: types.optional(types.string, ""),
  path: types.optional(types.string, ""),
  mimetype: types.optional(types.string, "")
});

const importFileSetAttachment = types.model("assayResultsAttachment", {
  id: types.optional(types.string, ""),
  isTabularData: types.optional(types.boolean, true),
  dataFile: types.optional(dataFile, {})
});

const importFileSet = types.model("importFileSet", {
  id: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  status: types.optional(types.union(types.string, types.null), ""),
  labId: types.optional(types.string, ""),
  importFileSetStep: types.optional(types.frozen(), {}),
  assay: types.optional(types.union(assay, types.null), {}),
  importFileSetAttachments: types.optional(
    types.array(importFileSetAttachment),
    []
  ),
  importTimedOut: types.optional(types.boolean, false),
  createdAt: types.optional(types.Date, new Date()),
  updatedAt: types.optional(types.Date, new Date())
});

const schema = {
  model: "importFileSet",
  fields: [
    { path: "id", displayName: "Import job id" },
    { path: "assay.name", displayName: "Assay" },
    { path: "importFileSetStep.name", displayName: "Status" },
    {
      path: "importFileSetAttachments.0.dataFile.originalname",
      displayName: "File"
    },
    {
      path: "assay.protocol.name",
      displayName: "Parser"
    },
    { path: "updatedAt", displayName: "Updated at" },
    { path: "discard", displayName: "Discard" }
  ]
};

export const importJobStore = types
  .model("importJobStore", {
    schema: types.optional(types.frozen(), schema),
    removeDialog: types.optional(types.boolean, false),
    isWatching: types.optional(types.boolean, false),
    fetchingData: types.optional(types.boolean, true),
    rowSelected: types.optional(types.frozen(), {}),
    importFileSets: types.optional(types.array(importFileSet), []),
    totalResults: types.optional(types.number, 0),
    isOpen: types.optional(types.boolean, false),
    lastPageNumberFetched: types.optional(types.number, 1),
    lastPageSizeFetched: types.optional(types.number, 10)
  })
  .actions(self => {
    const fetch = flow(function* (
      tableParams = { pageNumber: 1, pageSize: 10 }
    ) {
      try {
        const { pageNumber = 1, pageSize = 10 } = tableParams;
        self.setFetchingData(true);
        const qb = new QueryBuilder("importFileSet");
        const filter = qb
          .whereAll({
            // Filter those without an associated assay.
            assayId: qb.notNull(),
            // Filter those that have been discarded.
            importFileSetStepCode: qb.notInList(IMPORT_FILE_SET_IGNORE_CODES),
            labId: getActiveLabId()
          })
          .toJSON();
        const importFileSets = yield query(importFileSetFragment, {
          isPlural: true,
          variables: {
            filter: filter,
            // Order them based on most recently updated.
            sort: "-updatedAt",
            pageNumber: Number(pageNumber),
            pageSize: Number(pageSize)
          }
        });
        self.lastPageNumberFetched = Number(pageNumber);
        self.lastPageSizeFetched = Number(pageSize);

        // Identify imports that have timed out.
        const curTime = dayjs(new Date());
        const snapshotData = importFileSets.map(importFileSet => {
          let importTimedOut = false;

          // TODO: Currently the API GET endpoint used to query the state of an import process
          // updates the stepCode to TIMEDOUT if necessary, see if we can update it through the front-end as well.
          if (importFileSet.importFileSetStep.code.includes("TIMEDOUT")) {
            importTimedOut = true;
          } else {
            const updatedAt = dayjs(new Date(importFileSet.updatedAt));
            const updatedAtPlusTimeout = updatedAt.add(
              IMPORT_PROCESS_TIMEOUT_MINUTES,
              "minute"
            );
            importTimedOut =
              importFileSet.importFileSetStep.code.includes("INPROGRESS") &&
              curTime.isAfter(updatedAtPlusTimeout);
          }
          return {
            ...importFileSet,
            createdAt: new Date(importFileSet.createdAt),
            updatedAt: new Date(importFileSet.updatedAt),
            importTimedOut
          };
        });
        applySnapshot(self.importFileSets, snapshotData);
        self.totalResults = importFileSets.totalResults;
      } catch (error) {
        console.error(error);
        window.toastr.error("Error fetching pending results");
      }
      self.setFetchingData(false);
    });

    const deleteImportFileSet = flow(function* () {
      self.setFetchingData(true);
      try {
        yield safeDelete("importFileSet", self.rowSelected.id);
        yield fetch();
        window.toastr.success("Deleted", self.rowSelected.name);
        self.removeDialog = false;
      } catch (error) {
        return window.toastr.error(
          "Error deleting importer job ",
          self.rowSelected.name
        );
      }
    });

    const discardJob = flow(function* (importFileSets, refetch) {
      try {
        const updatedRecords = importFileSets.map(ifs => ({
          id: ifs.id,
          importFileSetStepCode: getDiscardedCode(ifs.importFileSetStep.code)
        }));
        yield safeUpsert("importFileSet", updatedRecords);
        yield fetch();
        yield refetch();
        window.toastr.success("Discarded", importFileSet.name);
      } catch (error) {
        console.error(error);
      }
    });

    const retryImport = async ({ importFileSetId }) => {
      try {
        await window.serverApi.post(config.endpoints["retryImport"], {
          importFileSetId
        });
        self.fetch();
      } catch (error) {
        console.error(error);
      }
    };

    return {
      setIsWatching(booleanValue) {
        self._isWatching = booleanValue;
      },
      watch() {
        if (!self._isWatching) {
          self.setIsWatching(true);
          const interval = setInterval(() => {
            if (self.isOpen && !self.isFetchingData) {
              self.fetch();
            }
            if (!self.isOpen) {
              clearInterval(interval);
              self.setIsWatching(false);
            }
          }, 2000);
        }

        // The following code was using mobc autorun function, but it wasnt working all that well.
        // TODO: evaluate it again and put it back if it makes sense. For now and for the same purpose,
        // An interval is being used.
        // autorun(runner => {
        //   const interval = setInterval(() => {
        //     console.log("inner self.isFetchingData", self.isFetchingData);
        //     if (self.isOpen && !self.isFetchingData) {
        //       self.fetch();
        //     }
        //   }, 1000);

        //   if (!self.isOpen) {
        //     console.log("Stopping watch on import jobs.");
        //     clearInterval(interval);
        //     runner.dispose();
        //   }
        // });
      },
      fetch,
      setFetchingData(booleanValue) {
        self.fetchingData = booleanValue;
      },
      deleteImportFileSet,
      discardJob,
      open(state) {
        self.isOpen = state;
      },
      handleRemoveDialog(row) {
        self.removeDialog = !self.removeDialog;
        self.rowSelected = row
          ? { name: row.name, id: row.id }
          : { name: "", id: "" };
      },
      retryImport
    };
  })
  .views(self => ({
    get pageNumberFetched() {
      return self.lastPageNumberFetched;
    },
    get pageSizeFetched() {
      return self.lastPageSizeFetched;
    },
    get isFetchingData() {
      return self.fetchingData;
    },
    get runningJobs() {
      return self.importFileSets.filter(
        importFileSet =>
          new Date() - importFileSet.updatedAt < ONE_HOUR ||
          importFileSet.importFileSetStep.code !== "FINISHED"
      );
    },
    get recentlyUpdatedJobs() {
      // self.importFileSets are already being queried sorted by 'updateAt', so just return them.
      return self.importFileSets;
    }
  }));
