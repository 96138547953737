/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useMemo } from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  AdvancedOptions,
  FileUploadField,
  ReactSelectField
} from "@teselagen/ui";
import { compose } from "recompose";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { arrayToIdOrCodeValuedOptions } from "../../../../../src-shared/utils/formUtils";
import { Button } from "@blueprintjs/core";
import { uniq } from "lodash";
import { getInputsAndOutputsOfReaction, sortReactions } from "../utils";
import { renderMaterialNames } from "../utils";
import { cleanCommaSeparatedCell } from "../../../../../../tg-iso-shared/src/utils/fileUtils";

import { isEmpty } from "lodash";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";

function UpdateReactions(props) {
  const {
    Footer,
    footerProps,
    stepFormProps: { change },
    addReagentsToAll = [],
    addReagentsForReaction = {},
    allReagents,
    reactionMap
  } = props;

  const reagentOptions = useMemo(
    () => arrayToIdOrCodeValuedOptions(allReagents),
    [allReagents]
  );
  const reactionNameToReactionId = useMemo(() => {
    const reactionNameToReactionId = {};
    reactionMap.reactions.forEach(r => {
      reactionNameToReactionId[r.name] = r.id;
    });
    return reactionNameToReactionId;
  }, [reactionMap.reactions]);

  const reagentNameToId = useMemo(() => {
    const reagentNameToId = {};
    allReagents.forEach(r => {
      reagentNameToId[r.name.toLowerCase()] = r.id;
    });
    return reagentNameToId;
  }, [allReagents]);

  const parseCsvUpload = useCallback(
    async (fileList, onChange) => {
      const [csvFile] = fileList;
      try {
        onChange([{ ...csvFile, loading: false }]);
        const newAddReagentsForReaction = { ...addReagentsForReaction };
        for (const row of csvFile.parsedData) {
          const {
            "Reaction Name": reactionName,
            "Input Reagents": inputReagents
          } = row;
          const inputReagentNames = cleanCommaSeparatedCell(
            inputReagents
          ).map(name => name.toLowerCase());
          const newReagentIds = inputReagentNames.map(n => reagentNameToId[n]);
          const reactionId = reactionNameToReactionId[reactionName];
          newAddReagentsForReaction["id" + reactionId] = uniq(
            (newAddReagentsForReaction["id" + reactionId] || []).concat(
              newReagentIds
            )
          );
        }
        change("addReagentsForReaction", newAddReagentsForReaction);
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.error("Error parsing file");
      }
      return false;
    },
    [reagentNameToId, reactionNameToReactionId, addReagentsForReaction, change]
  );

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between column">
          <HeaderWithHelper
            header="Update Reactions"
            helper={`Add selected reagents to reaction inputs. Either add input
              reagents to individual reactions, or use the dropdown at the top
              to add input reagents to all reactions. If you would like to upload
              a file instead, click the "Advanced" toggle below and download the
              file template.`}
            width="100%"
          />
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: 20
            }}
          >
            <div style={{ maxWidth: 250 }}>
              <ReactSelectField
                className="tg-no-form-group-margin"
                name="addReagentsToAll"
                label="Add to All Inputs"
                multi
                options={reagentOptions}
              />
            </div>
            <Button
              style={{ marginLeft: 5 }}
              intent="primary"
              disabled={!addReagentsToAll.length}
              onClick={() => {
                const newAddReagentsForReaction = {};
                reactionMap.reactions.forEach(r => {
                  newAddReagentsForReaction["id" + r.id] = uniq(
                    (addReagentsForReaction["id" + r.id] || []).concat(
                      addReagentsToAll
                    )
                  );
                });
                change("addReagentsForReaction", newAddReagentsForReaction);
                change("addReagentsToAll", []);
              }}
              text="Add Reagents to All Reactions"
            />
          </div>
          <AdvancedOptions>
            <div style={{ maxWidth: 300 }}>
              <FileUploadField
                accept={getDownloadTemplateFileHelpers({
                  validateAgainstSchema: {
                    fields: [
                      {
                        path: "Reaction Name",
                        isRequired: true,
                        validate: reactionName => {
                          const reactionId =
                            reactionNameToReactionId[reactionName];
                          if (!reactionId) {
                            return `No reaction with name ${reactionName}`;
                          }
                        },
                        description: "The name of the reaction",
                        example: "DNA Amplification"
                      },
                      {
                        path: "Input Reagents",
                        validate: inputReagents => {
                          const inputReagentNames = cleanCommaSeparatedCell(
                            inputReagents
                          ).map(name => name.toLowerCase());
                          const invalid = inputReagentNames.filter(
                            name => !reagentNameToId[name]
                          );
                          if (invalid.length) {
                            return `These reagents were not in the selection: ${invalid.join(
                              ", "
                            )}`;
                          }
                        },
                        isRequired: true,
                        description: "The input reagents for the reaction",
                        example: "Polymerase, primers, nucleotides"
                      }
                    ]
                  },
                  fileName: "reactionInputs"
                })}
                fileLimit={1}
                name="reactionUpload"
                label="Upload Input Reagents"
                beforeUpload={parseCsvUpload}
              />
            </div>
          </AdvancedOptions>
          <hr className="tg-section-break" />

          {sortReactions(reactionMap.reactions).map((r, i) => {
            const {
              inputMaterials,
              inputReagents,
              outputMaterials,
              outputReagents
            } = getInputsAndOutputsOfReaction(r);
            return (
              <div key={r.id}>
                <h6>{r.name || `Reaction ${i + 1}`}</h6>
                {!!inputMaterials.length && (
                  <div>
                    Input Materials: {renderMaterialNames(inputMaterials)}
                  </div>
                )}
                {!!inputReagents.length && (
                  <div>
                    Input Reagents: {renderMaterialNames(inputReagents)}
                  </div>
                )}
                {!!outputMaterials.length && (
                  <div>
                    Output Materials: {renderMaterialNames(outputMaterials)}
                  </div>
                )}
                {!!outputReagents.length && (
                  <div>
                    Output Reagents: {renderMaterialNames(outputReagents)}
                  </div>
                )}
                <div style={{ maxWidth: 250, marginTop: 15 }}>
                  <ReactSelectField
                    name={`addReagentsForReaction.id${r.id}`}
                    label="Add Input Reagents"
                    multi
                    options={reagentOptions}
                  />
                </div>
                {i !== reactionMap.reactions.length - 1 && (
                  <hr className="tg-section-break" />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Footer {...footerProps} nextDisabled={isEmpty(addReagentsForReaction)} />
    </React.Fragment>
  );
}

export default compose(
  stepFormValues(
    "reactionMap",
    "allReagents",
    "addReagentsToAll",
    "addReagentsForReaction"
  )
)(UpdateReactions);
