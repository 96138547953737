/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  DialogFooter,
  InputField,
  NumericInputField,
  RadioGroupField,
  showConfirmationDialog,
  tgFormValues,
  wrapDialog
} from "@teselagen/ui";
import { find } from "lodash";
import React, { useState } from "react";
import { forEach } from "lodash";
import immer from "immer";

import { reduxForm } from "redux-form";
import { updateCache } from "../../../src-shared/utils/apolloUtils";
import { inspectorSeqFragment } from "../../utils/sequenceUtils";
import { partLibraryDialogFragment } from "../Dialogs/PartLibraryDialog";
import { SimpleCircularOrLinearView } from "@teselagen/ove";
import { hideDialog } from "../../../src-shared/GlobalDialog";
import { sequenceToVeInput } from "../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { compose } from "recompose";
import useTgQuery from "../../../src-shared/apolloUseTgQuery";
import { partRecordFragment } from "../../../src-shared/PartRecordView";
import { Icon } from "@blueprintjs/core";
import { safeUpsert } from "../../../src-shared/apolloMethods";

export const QuickEditPartDialog = compose(
  wrapDialog({
    title: "Update Part"
  }),
  reduxForm({ form: "QuickEditPartDialog" }),
  tgFormValues("start", "end")
)(function QuickEditPartDialog({
  partId,
  activeDesignId,
  handleSubmit,
  updatePart,
  updateElement,
  elementId,
  insertNewPart
}) {
  const [selectionLayer, setSelectionLayer] = useState({});

  const { part, ...rest } = useTgQuery(partRecordFragment, {
    variables: { id: partId },
    onCompleted: ({ part }) => {
      setSelectionLayer(part);
    }
  });
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  const { sequence } = part;
  const sequenceLength = sequence.size;
  const sequenceData = sequenceToVeInput(part.sequence);

  return (
    <div>
      <div style={{ padding: 20 /* display: "flex", flexDirection: "row" */ }}>
        <SimpleCircularOrLinearView
          sequenceData={sequenceData}
          width={450}
          annotationLabelVisibility={{
            parts: false,
            features: false,
            cutsites: false,
            primers: false
          }}
          selectionLayer={selectionLayer}
          selectionLayerUpdate={setSelectionLayer}
          hoveredId={partId}
          withSelectionEnabled
          withChoosePreviewType
          withVisibilityOptions
          annotationVisibility={{
            parts: true,
            features: true
          }}
        />
        <div
          style={{
            width: "100%",
            marginTop: 10
          }}
        >
          <div style={{ marginRight: 50 }}>
            <InputField
              inlineLabel
              tooltipError
              autoFocus
              placeholder="Untitled Annotation"
              defaultValue={part.name}
              isRequired
              name="name"
              label="Part Name"
            />
            <RadioGroupField
              inlineLabel
              inline
              tooltipError
              options={[
                { label: "Positive", value: true },
                { label: "Negative", value: false }
              ]}
              defaultValue={part.strand === 1}
              name="strand"
              label="Strand"
            />
          </div>
          <div>
            <NumericInputField
              clampValueOnBlur
              noRedux
              inlineLabel
              tooltipError
              defaultValue={part.start + 1}
              min={1}
              max={sequenceLength || 1}
              name="start"
              label="Start"
              value={selectionLayer.start + 1}
              onChange={start => {
                const newVal = Math.max(0, start - 1);
                setSelectionLayer(prev => ({
                  ...prev,
                  start: newVal
                }));
              }}
            />
            <NumericInputField
              clampValueOnBlur
              noRedux
              inlineLabel
              tooltipError
              defaultValue={part.end + 1}
              min={1}
              max={sequenceLength || 1}
              name="end"
              label="End"
              value={selectionLayer.end + 1}
              onChange={end => {
                const newVal = Math.max(0, end - 1);
                setSelectionLayer(prev => ({
                  ...prev,
                  end: newVal
                }));
              }}
            />
          </div>
        </div>
      </div>
      <DialogFooter
        onClick={handleSubmit(async ({ name, strand }) => {
          if (!sequence.circular && selectionLayer.start > selectionLayer.end) {
            return window.toastr.error(
              "Part Start cannot be greater than End for a Linear Sequence"
            );
          }
          const newPart = {
            name,
            strand: strand ? 1 : -1,
            start: selectionLayer.start,
            end: selectionLayer.end
          };

          let hasLinkedDesigns;
          part.elements?.forEach(({ design }) => {
            if (design?.id !== activeDesignId) {
              hasLinkedDesigns = true;
            }
          });

          const confirm =
            !hasLinkedDesigns ||
            (await showConfirmationDialog({
              text: (
                <div>
                  <Icon intent="warning" icon="warning-sign"></Icon> &nbsp; It
                  looks like this part is used in other designs. Are you sure
                  you want to update this part? You can also choose to apply
                  your changes to a new part on the same sequence.
                </div>
              ),
              intent: "warning",
              confirmButtonText: "Update Part",
              thirdButtonText: "Create New Part",
              thirdButtonIntent: "primary",
              cancelButtonText: "Cancel",
              canEscapeKeyCancel: true
            }));
          if (!confirm) return;
          let updater;
          if (confirm === "thirdButtonClicked") {
            const [createdPart] = await safeUpsert(partLibraryDialogFragment, {
              ...newPart,
              sequenceId: part.sequenceId
            });
            insertNewPart(createdPart);
            updater = seq => {
              const toRet = immer(seq, s => {
                s.parts.push(createdPart);
              });
              return toRet;
            };
            window.toastr.success("New Part Created Successfully");
          } else if (confirm) {
            updatePart({
              part: {
                id: part.id,
                ...newPart
              }
            });
            updateElement({
              element: {
                id: elementId,
                name: newPart.name
              }
            });
            updater = seq => {
              const toRet = immer(seq, s => {
                const part = find(s.parts, p => p.id === partId);
                forEach(newPart, (v, k) => {
                  part[k] = v;
                });
              });
              return toRet;
            };

            window.toastr.success("Part Updated Successfully");
          }
          updateCache({
            fragment: inspectorSeqFragment,
            updater,
            variables: {
              id: part.sequence.id
            }
          });

          hideDialog();
        })}
        text="Update Part"
      />
    </div>
  );
});
