/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { min, max } from "lodash";
import {
  SBOL_ICON_HEIGHT,
  SBOL_ICON_WIDTH
} from "../components/HierarchicalDesign/constants";

export const iconWidth = SBOL_ICON_WIDTH;
export const iconHeight = SBOL_ICON_HEIGHT;

// This function assumes that the output is circular.
export const isSetIndexBackboneSourced = (breakPointIndices, setIndex) =>
  !breakPointIndices.length ||
  setIndex < min(breakPointIndices) ||
  setIndex >= max(breakPointIndices);

export const getBreakpoints = breakPointIndices =>
  [...(breakPointIndices ? breakPointIndices.indices : [])].sort(
    (a, b) => a - b
  );
