/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default function getSampleStatusMessage(statusInfo) {
  if (!statusInfo) return "No Samples";
  const { totalValid, totalInvalid, totalUnvalidated } = statusInfo;
  if (totalValid > 0 && !totalInvalid && !totalUnvalidated) {
    return "All Valid";
  } else if (totalValid > 0) {
    return "At Least One Valid";
  } else if (!totalValid && !totalUnvalidated && totalInvalid > 0) {
    return "All Invalid";
  } else if (totalInvalid || totalUnvalidated) {
    return "None Are Valid";
  }
}
