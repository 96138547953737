/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const extendedPropertyUploadFragment = `
  extendedValues {
    id
    value
    extendedPropertyId
  }
  extendedCategoryValues {
    id
    extendedCategoryId
    extendedPropertyId
  }
  extendedMeasurementValues {
    id
    value
    measurementUnitId
    extendedPropertyId
  }
`;

export const taggedItems = `taggedItems {
  id
  tagId
  tagOptionId
}`;

export const sequenceWithTagsAndAliasesFragment = `
  id
  name
  hash
  aliases {
    id
    name
  }
  ${taggedItems}
  polynucleotideMaterialId
  polynucleotideMaterial {
    id
    ${taggedItems}
  }
`;

export const sequenceWithTagsAndAliasesAndExtendedProperitesFragment = `
  ${sequenceWithTagsAndAliasesFragment}
  ${extendedPropertyUploadFragment}
`;

export const aliquotContainerTypeFragment = `
  code
  name
  volumetricUnitCode
  isTube
  maxVolume
`;

export const containerArrayTypeFragment = `
  id
  name
  isPlate
  nestableTubeTypes {
    id
    aliquotContainerTypeCode
    aliquotContainerType {
      ${aliquotContainerTypeFragment}
    }
  }
  aliquotContainerTypeCode
  aliquotContainerType {
    ${aliquotContainerTypeFragment}
  }
  containerFormatCode
  containerFormat {
    code
    rowCount
    columnCount
    quadrantSize
    is2DLabeled
  }
`;
