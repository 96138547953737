/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { compose } from "recompose";
import { Button, Classes, Popover } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";
import { withProps } from "recompose";
import AddTagDialog from "../AddTagDialog";
import withQuery from "../withQuery";
import tagFragment from "../fragments/tagFragment";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { safeQuery } from "../apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import "./style.css";
import { createTaggedItems, getInitiallySelectedTags } from "../utils/tagUtils";
import { noop } from "lodash";
import GenericSelect from "../GenericSelect";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { tagQueryOptions } from "../RecordInfoDisplay/RecordTags";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";

function getTagId(tag) {
  return tag.id.split(":")[0];
}

const TagItemDialog = ({
  hideModal,
  handleSubmit,
  submitting,
  initialValues = {},
  newTags = [],
  change,
  records,
  needsInitialize,
  afterTag = noop
}) => {
  const [creatingNewTag, setCreatingNewTag] = useState(false);

  async function onSubmit(values) {
    const { newTags } = values;
    const typename = records[0].__typename;
    // const recordIds = records.map(r => r.id);
    const readableName = modelNameToReadableName(typename, {
      plural: records.length > 1
    });
    try {
      const initialTagIds = [];
      if (initialValues.newTags) {
        initialValues.newTags.forEach(t => {
          initialTagIds.push(getTagId(t));
        });
      }
      const newPartialTagIds = [];
      const selectedTags = {};
      newTags.forEach(tag => {
        if (tag.__partial) {
          // these need to have the id:tagOptionId format
          newPartialTagIds.push(tag.id);
        } else {
          const [tagId, tagOptionId] = tag.id.split(":");
          selectedTags[tagId] = tagOptionId || true;
        }
      });
      await createTaggedItems({
        selectedTags,
        initiallySelectedTagIds: initialTagIds,
        records
      });
      // this is from a record view which uses the taggedItemFragment
      if (needsInitialize) {
        //do nothing
      } else {
        let modelToRefetch = typename;
        let fragment = `
          id
          taggedItems {
            id
            tag {
              id
              name
              color
            }
            tagOption {
              id
              name
              color
            }
          }
        `;
        // this is from a library view
        // tubes and plates have special views for paths so we have to add some special logic
        if (typename === "aliquotContainer") {
          modelToRefetch = "aliquotContainerPathView";
          fragment = `id aliquotContainer { ${fragment} }`;
        } else if (typename === "containerArray") {
          modelToRefetch = "containerArrayPathView";
          fragment = `id containerArray { ${fragment} }`;
        }
        await safeQuery([modelToRefetch, fragment], {
          variables: {
            filter: {
              id: records.map(r => r.id)
            }
          }
        });
      }
      await safeQuery(
        taggedItemFragment,
        tagQueryOptions({ model: typename, recordId: records[0].id })
      );
      await afterTag({ records, selectedTags });
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error(`Error tagging ${readableName}`);
    }
  }

  const selectNewTag = tag => {
    /**
     * After creating a new tag select it
     */
    const tagInfo = {
      ...tag,
      tagOptions: undefined
    };
    if (tag.tagOptions.length) {
      const op = tag.tagOptions[0];
      tagInfo.id += `:${op.id}`;
      tagInfo.color = op.color;
      tagInfo.name = `${tag.name}: ${op.name}`;
    }
    tagInfo.label = tagInfo.name;
    change("newTags", [...newTags, tagInfo]);
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Popover
          isOpen={creatingNewTag}
          hasBackdrop
          content={
            <div style={{ padding: 15, width: 300 }}>
              <AddTagDialog
                noDialog
                tagFragment={tagFragment}
                afterCreate={selectNewTag}
                hideModal={() => setCreatingNewTag(false)}
                submitText="Add Tag"
              />
            </div>
          }
        >
          <Button
            icon="add"
            minimal
            text="Create New Tag"
            intent="success"
            active={creatingNewTag}
            onClick={() => setCreatingNewTag(true)}
            style={{ marginBottom: 10 }}
          />
        </Popover>
        <GenericSelect
          asReactSelect
          fragment={["tagWithOptionView", "id name color"]}
          isMultiSelect
          name="newTags"
          reactSelectProps={{
            isTagSelect: true
          }}
          tableParamOptions={{
            defaults: {
              order: ["name"]
            }
          }}
        />
      </div>
      <DialogFooter
        disabled={creatingNewTag}
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

const TagItemWrapped = compose(
  wrapDialog({
    title: "Tag",
    icon: "tag"
  }),
  // initialize only supports a single record currently
  withQuery(taggedItemFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    skip: ({ needsInitialize }) => !needsInitialize,
    options: ({ records }) =>
      tagQueryOptions({ recordId: records[0].id, model: records[0].__typename })
  }),
  withProps(({ needsInitialize, records, taggedItems }) => {
    if (needsInitialize) {
      return {
        records: [
          {
            ...records[0],
            taggedItems
          }
        ]
      };
    }
  }),
  withProps(({ records }) => {
    if (records) {
      return {
        initialValues: {
          newTags: getInitiallySelectedTags(records)
        }
      };
    }
  }),
  reduxForm({
    form: "tagItemForm",
    destroyOnUnmount: true
  }),
  tgFormValues("newTags")
)(TagItemDialog);

export default TagItemWrapped;
