/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import { groupBy, uniq, every, isEqual } from "lodash";

const FILE_TO_DATAGRID = {
  // description: `Send a file and return one or many data grids; Used in conjunction with the 'Parse Experiment Data' ${
  //   toolSchemas.filter(tool => tool.code === "convertFileToDataGrid")[0].title
  // } Tool`,
  description: `Send files and return one or many data grids; Used in conjunction with the 'Parse Experiment Data' Tool`,
  bundles: [
    {
      id: "simple_excel_parser",
      name: "Simple Excel Parser",
      integrationHookFileUrl: "simpleExcelParserIntegrationHook.json",
      nodeRedFileUrl: "simpleExcelParserNodeRed.json"
    }
  ],
  endpoints: {
    FILE_TO_DATAGRID: {
      name: "Convert File To Data Grids",
      method: "POST",
      description: "Send a file and get back a list of data grids",
      exampleRequest: {
        __validate__: () =>
          yup.object({
            encodedFiles: yup.array(
              yup.object({
                type: yup.string(),
                base64Data: yup.string(),
                name: yup.string()
              })
            )
          }),
        encodedFiles: [
          {
            type: "txt/csv",
            base64Data: "Im base 64 data 29382982uf",
            name: "someFile.txt"
          }
        ]
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            datagrids: yup.array(
              yup.object({
                name: yup.string().required(),
                dataCells: yup
                  .array()
                  .ensure()
                  .of(
                    yup.object({
                      columnPosition: yup.number().required(),
                      rowPosition: yup.number().required(),
                      value: yup.string()
                    })
                  )
                  .min(0, "No array found.") // Allows empty arrays.
                  .required()
                  // eslint-disable-next-line no-template-curly-in-string
                  .test(
                    "tabular-validation",
                    "${path} error",
                    function (dataCells) {
                      const rows = groupBy(dataCells, "rowPosition");
                      const columns = groupBy(dataCells, "columnPosition");

                      const uniqColumnPositions = uniq(
                        dataCells.map(cell => cell.columnPosition)
                      );
                      const uniqRowPositions = uniq(
                        dataCells.map(cell => cell.rowPosition)
                      );

                      // This checks that every row has even columns.
                      const rowsHaveEvenColumns = every(
                        Object.values(rows),
                        row =>
                          isEqual(
                            row.map(cell => cell.columnPosition),
                            uniqColumnPositions
                          )
                      );

                      // This checks that every column has even rows.
                      const columnsHaveEvenRows = every(
                        Object.values(columns),
                        column =>
                          isEqual(
                            column.map(cell => cell.rowPosition),
                            uniqRowPositions
                          )
                      );

                      if (!rowsHaveEvenColumns && !columnsHaveEvenRows) {
                        return this.createError({
                          path: this.path,
                          message:
                            "Based on the dataCells row and column position indexes, there seems to be an uneven number of rows and/or columns"
                        });
                      }

                      return true;
                    }
                  )
              })
            )
          });
        },
        datagrids: [
          {
            name: "datagrid1",
            dataCells: [
              { value: "I'm cell1", columnPosition: 0, rowPosition: 0 },
              { value: "I'm cell2", columnPosition: 1, rowPosition: 0 }
            ]
          },
          {
            name: "datagrid2",
            dataCells: [
              { value: "I'm cell1", columnPosition: 0, rowPosition: 0 },
              { value: "I'm cell2", columnPosition: 1, rowPosition: 0 }
            ]
          }
        ]
      }
    }
  }
};

export default FILE_TO_DATAGRID;
