/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InfoHelper } from "@teselagen/ui";

function InfoHeader({ header, info }) {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
      <h5 style={{ marginBottom: 0, marginRight: 8 }}>{header}</h5>
      <InfoHelper content={info} />
    </div>
  );
}

export default InfoHeader;
