/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import StepForm from "../../../../src-shared/StepForm";
import SourceMaterials from "./SourceMaterials";
import SelectPlates, {
  worklistPlanningContainerArrayTypeFragment
} from "./SelectInputs";
import ReviewWorklist from "../AliquotRearrayTool/Steps/ReviewWorklist";
import { every, forEach, set, isEmpty } from "lodash";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import {
  worklistPlanningReactionMapFragment,
  worklistPlanningPlateFragment,
  worklistPlanningTubeFragment,
  worklistPlanningDataTableFragment
} from "./fragments";
import withQuery from "../../../../src-shared/withQuery";
import shortid from "shortid";

import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import { isValidNumOfReactions } from "./utils";
import { generateEmptyWells } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";

class WorklistPlanningTool extends React.Component {
  onSubmit = async values => {
    const {
      destinationPlates,
      worklistName,
      worklist,
      usedReactionMapIds = []
    } = values;
    try {
      if (destinationPlates[0].id.includes("__")) {
        const isRack = !destinationPlates[0].containerArrayType.isPlate;
        const newPlates = await safeUpsert(
          "containerArray",
          destinationPlates.map(p => {
            const cleanedPlate = { ...p };
            delete cleanedPlate.id;
            cleanedPlate.aliquotContainers = cleanedPlate.aliquotContainers.map(
              ac => {
                const cleanedAc = { ...ac };
                delete ac.id;
                return cleanedAc;
              }
            );
            cleanedPlate.containerArrayTypeId = p.containerArrayType.id;
            delete cleanedPlate.containerArrayType;
            return cleanedPlate;
          })
        );
        await addBarcodesToRecords(newPlates);
        if (isRack) {
          const newTubes = await safeQuery(["aliquotContainer", "id"], {
            variables: {
              filter: {
                containerArrayId: newPlates.map(p => p.id)
              }
            }
          });
          await addBarcodesToRecords(newTubes);
        }
      }
      const worklistToUpsert = {
        name: worklistName,
        worklistTransfers: worklist.worklistTransfers.map(t => {
          const cleanedTransfer = { ...t };
          delete cleanedTransfer.sourceAliquotContainer;
          delete cleanedTransfer.destinationAliquotContainer;
          delete cleanedTransfer.destinationPlateName;
          return cleanedTransfer;
        })
      };

      // link to reaction map if completed at least one reaction
      if (usedReactionMapIds.length) {
        worklistToUpsert.worklistReactionMaps = usedReactionMapIds.map(id => {
          return {
            reactionMapId: id
          };
        });
      }
      const [newWorklist] = await safeUpsert("worklist", worklistToUpsert);
      return {
        worklist: newWorklist
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: error.message || "Error generating worklist."
      });
    }
  };

  validate = values => {
    const {
      destinationType,
      sourceTubes = [],
      sourcePlates = [],
      sourceDataTables = [],
      destinationPlates = [],
      plateToReactionMap = {},
      reagentTransferSettings = {},
      materialTransferSettings = {},
      numberOfReactionsPerWell,
      enableMultipleReactions
    } = values;
    const errors = {};

    if (
      !sourceTubes.length &&
      !sourcePlates.length &&
      !sourceDataTables.length
    ) {
      errors.sourcePlates =
        errors.sourceTubes =
        errors.sourceDataTables =
          "Please select source plates, tubes, or data tables.";
    }
    const sourcePlateIds = sourcePlates.map(c => c.id);
    if (
      destinationType === "Existing Plates" &&
      destinationPlates.some(p => sourcePlateIds.includes(p.id))
    ) {
      errors.sourcePlates = "Source plate cannot be a destination plate.";
    }

    if (!isValidPositiveNumber(reagentTransferSettings.transferVolume)) {
      set(
        errors,
        "reagentTransferSettings.transferVolume",
        "Please enter a valid transfer volume."
      );
    }
    if (!isValidPositiveNumber(reagentTransferSettings.transferMass)) {
      set(
        errors,
        "reagentTransferSettings.transferMass",
        "Please enter a valid transfer mass."
      );
    }
    if (!isValidPositiveNumber(materialTransferSettings.transferVolume)) {
      set(
        errors,
        "materialTransferSettings.transferVolume",
        "Please enter a valid transfer volume."
      );
    }
    if (!isValidPositiveNumber(materialTransferSettings.transferMass)) {
      set(
        errors,
        "materialTransferSettings.transferMass",
        "Please enter a valid transfer mass."
      );
    }
    if (!materialTransferSettings.applyUniversalTransfer) {
      forEach(materialTransferSettings.transferInfo, (value = {}, key) => {
        if (!isValidPositiveNumber(value.volume)) {
          set(
            errors,
            `materialTransferSettings.transferInfo[${key}].volume`,
            "Please enter a valid transfer volume."
          );
        }
        if (!isValidPositiveNumber(value.mass)) {
          set(
            errors,
            `materialTransferSettings.transferInfo[${key}].mass`,
            "Please enter a valid transfer mass."
          );
        }
      });
    }
    if (!reagentTransferSettings.applyUniversalTransfer) {
      forEach(reagentTransferSettings.transferInfo, (value = {}, key) => {
        if (!isValidPositiveNumber(value.volume)) {
          set(
            errors,
            `reagentTransferSettings.transferInfo[${key}].volume`,
            "Please enter a valid transfer volume."
          );
        }
        if (!isValidPositiveNumber(value.mass)) {
          set(
            errors,
            `reagentTransferSettings.transferInfo[${key}].mass`,
            "Please enter a valid transfer mass."
          );
        }
      });
    }
    if (destinationPlates.length && every(plateToReactionMap, isEmpty)) {
      errors.reactionMaps =
        "Please drag at least one reaction from the table to the plate wells.";
    }
    if (enableMultipleReactions) {
      if (!isValidNumOfReactions(numberOfReactionsPerWell)) {
        errors.numberOfReactionsPerWell =
          "Please enter a positive integer greater than or equal to 2.";
      }
    }
    return errors;
  };

  staticTracker = {};

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues,
      containerArrayTypes = []
    } = this.props;
    const steps = [
      {
        title: "Plan Reactions",
        Component: SelectPlates,
        withCustomFooter: true,
        props: {
          staticTracker: this.staticTracker,
          defaultPlateType: containerArrayTypes[0]
        }
      },
      {
        title: "Select Input Materials",
        Component: SourceMaterials,
        withCustomFooter: true
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(worklistPlanningReactionMapFragment),
  withWorkflowInputs(worklistPlanningPlateFragment, {
    initialValueName: "sourcePlates"
  }),
  withWorkflowInputs(worklistPlanningDataTableFragment, {
    initialValueName: "sourceDataTables"
  }),
  withWorkflowInputs(worklistPlanningTubeFragment, {
    initialValueName: "sourceTubes"
  }),
  withWorkflowInputs(["plateMapGroup", "id name"], {
    singular: true
  }),
  withQuery(worklistPlanningContainerArrayTypeFragment, {
    isPlural: true,
    inDialog: true,
    options: {
      variables: {
        pageSize: 1,
        filter: {
          cid: "sys-generic-96-well-plate"
        }
      }
    },
    props: props => {
      const { containerArrayTypes = [], ownProps } = props;
      const containerArrayType = containerArrayTypes[0];
      if (containerArrayType) {
        return {
          initialValues: {
            ...ownProps.initialValues,
            newPlateType: containerArrayType,
            destinationPlates: [
              {
                id: "__" + shortid(),
                name: "Destination Plate 1",
                containerArrayType,
                aliquotContainers: generateEmptyWells(
                  containerArrayType.containerFormat,
                  {
                    aliquotContainerTypeCode:
                      containerArrayType.aliquotContainerTypeCode
                  }
                )
              }
            ]
          }
        };
      }
    }
  })
)(WorklistPlanningTool);
