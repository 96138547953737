/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { withSelectedEntities } from "@teselagen/ui";
import { compose } from "recompose";
import SelectJ5Report from "./SelectJ5Report";
import Constructs from "./Constructs";
import AssemblyPieces from "./AssemblyPieces";
import OutputNames from "./OutputNames";
import { constructsTableName } from "../constants";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { j5ReportsFullyLinked } from "../utils";

function ChooseAssemblyPiecesAndConstructs(props) {
  const {
    Footer,
    footerProps,
    selectedBuildableConstructs = [],
    j5Reports = [],
    ...rest
  } = props;

  const fullyLinked = j5ReportsFullyLinked(j5Reports);
  return (
    <div>
      <SelectJ5Report {...rest} />
      {j5Reports.length > 0 && fullyLinked && (
        <React.Fragment>
          <AssemblyPieces {...rest} />
          <Constructs {...rest} />
          <OutputNames {...rest} />
        </React.Fragment>
      )}
      <Footer
        {...footerProps}
        errorMessage={
          j5Reports.length > 0 &&
          fullyLinked &&
          !selectedBuildableConstructs.length &&
          "Please select at least one construct to build."
        }
        nextDisabled={!selectedBuildableConstructs.length}
      />
    </div>
  );
}

export default compose(
  withSelectedEntities({
    formName: constructsTableName,
    name: "selectedBuildableConstructs"
  }),
  stepFormValues("j5Reports", "finalizedAPIds")
)(ChooseAssemblyPiecesAndConstructs);
