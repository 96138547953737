/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { generateField } from "@teselagen/ui";
import QueryBuilder from "tg-client-query-builder";
import { safeQuery } from "../apolloMethods";
import MentionsInput from "../Mentions/MentionsInput";
import Mention from "../Mentions/Mention";

let container;

const fetchUsers = async (queryString, callback) => {
  if (!queryString) return;
  const qb = new QueryBuilder("user");
  qb.whereAll({
    username: qb.contains(queryString)
  });
  const users = await safeQuery(["user", "id username"], {
    variables: {
      filter: qb.toJSON(),
      pageSize: 5
    }
  });
  const userObjects = users.map(user => ({
    id: user.id,
    display: user.username
  }));
  callback(userObjects);
};

function RenderMentionInput(props) {
  const { input, autoFocus, disabled } = props;

  const reduxFormStringValue = input.value && input.value.stringValue;

  function onMentionValueChange(e, newValue, newPlainTextValue, mentions) {
    const newStateValue = {
      stringValue: newValue,
      mentions
    };
    // setValue(newStateValue);
    input.onChange(newStateValue);
  }
  return (
    <div ref={el => (container = el)}>
      <MentionsInput
        value={reduxFormStringValue || ""}
        onChange={onMentionValueChange}
        suggestionsPortalHost={container}
        className={classNames("tg-mention-field", {
          "bp3-disabled": disabled
        })}
        inputRef={n => {
          if (n && autoFocus) {
            n.focus();
          }
        }}
        classNames={{
          "tg-mention-field__input": classNames(Classes.INPUT, {
            [Classes.DISABLED]: disabled
          }),
          // "tg-mention-field__suggestions": Classes.MENU
          "tg-mention-field__suggestions__list": Classes.MENU
        }}
        style={{
          "&multiLine": {
            input: {
              padding: 9,
              minHeight: 63,
              width: "100%",
              backgroundColor: null
            }
          },
          suggestions: {
            backgroundColor: null,
            list: {
              boxShadow:
                "0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4)",
              backgroundColor: null
            }
          }
        }}
      >
        <Mention
          appendSpaceOnAdd
          trigger="@"
          // markup='@[__display__](__id__)'
          displayTransform={(id, display) => `@${display}`}
          renderSuggestion={(
            entry,
            search,
            highlightedDisplay,
            index,
            focused
          ) => {
            return (
              <div
                className={classNames(Classes.MENU_ITEM, {
                  [Classes.ACTIVE]: focused,
                  [Classes.INTENT_PRIMARY]: focused
                })}
              >
                {highlightedDisplay}
              </div>
            );
          }}
          data={fetchUsers}
        />
      </MentionsInput>
    </div>
  );
}

const MentionFieldInner = generateField(RenderMentionInput);

export default props => (
  <MentionFieldInner containerStyle={{ width: "100%" }} {...props} />
);
