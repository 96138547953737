/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Callout } from "@blueprintjs/core";

function ReactionJsonUploadMessage() {
  return (
    <Callout style={{ marginBottom: 10 }} intent="primary">
      Use this style of reaction map upload if you want to create new materials
      on the fly. Note the ONLY file type supported here is .reaction.json
    </Callout>
  );
}

export default ReactionJsonUploadMessage;
