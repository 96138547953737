/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { inject, observer } from "mobx-react";
import { Drawer, Classes, Divider, H4, Icon } from "@blueprintjs/core";
import FAQ from "./FAQ";
import Definition from "./definition";
import ExtraContent from "./extraContent";
import { get, size } from "lodash";
import "./style.css";

/**
 * Apparently mobx-state-tree doesnt support model.types of type React.Component,
 * which is useful for more customizable content.
 *
 * Thus, perhaps refactor this component without mobx? For now I'll add support
 * for the content to be in markdown.
 */
export default inject("mainStore")(
  observer(({ mainStore: { helper }, width = 500 }) => {
    const Title = () => (
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {!helper.index && <Icon icon="arrow-left" onClick={helper.back} />}
        <span style={{ lineHeight: 2 }}>
          {get(helper.active, "title") || "User Manual"}
        </span>
      </div>
    );

    return (
      <Drawer
        size={width}
        // icon=""
        onClose={helper.close}
        title={<Title />}
        isOpen={helper.isOpen}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            {get(helper.active, "definition") && (
              <div style={{ marginBottom: "20px" }}>
                {/* <H4>Definition</H4> */}
                {get(helper.active, "definition")
                  .slice()
                  .sort((a, b) => {
                    return b.focus - a.focus;
                  })
                  .map((def, index) => (
                    <Definition key={index} data={def} />
                  ))}
                <Divider />
              </div>
            )}
            {get(helper.active, "FAQ") && (
              <div style={{ marginBottom: "20px" }}>
                <H4>FAQs</H4>
                {get(helper.active, "FAQ").map((faq, index) => (
                  <FAQ data={faq} key={index} />
                ))}
                <Divider />
              </div>
            )}
            {!!size(get(helper.active, "extraContent")) && (
              <div style={{ marginBottom: "20px" }}>
                <H4>Extra content</H4>
                <br />
                {get(helper.active, "extraContent").map((def, index) => (
                  <ExtraContent key={index} data={def} />
                ))}
                <Divider />
              </div>
            )}
            {helper.index && (
              <div style={{ marginBottom: "30px" }}>
                {size(helper.active.list)
                  ? get(helper, "active.list").map((key, index) => (
                      <a
                        key={index}
                        style={{ marginBottom: 20 }}
                        onClick={() => helper.open(key)}
                        className="index-title"
                      >
                        {helper.getTitle(key)}
                      </a>
                    ))
                  : ""}
              </div>
            )}
          </div>

          {/* <div className={Classes.DRAWER_FOOTER}>Footer</div> */}
        </div>
      </Drawer>
    );
  })
);
