/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import QueryBuilder from "tg-client-query-builder";
import { containerEmptyPathFilter } from "./utils";
import { sortContainers } from "../../../utils";
import { safeQuery } from "../../../../src-shared/apolloMethods";

const defaultContainerFragment =
  "id name label barcode { id barcodeString } path equipmentId equipmentPositionId";

export const defaultEquipmentTreeFragments = {
  containerFragment: ["container", defaultContainerFragment],
  equipmentPositionFragment: ["equipmentPosition", "id name label equipmentId"],
  containerPositionFragment: [
    "containerPosition",
    `id name label index container { ${defaultContainerFragment} }`
  ],
  assignedPositionFragment: [
    "assignedPosition",
    `
    id
    containerArray {
      id
      name
      labId
      barcode {
        id
        barcodeString
      }
    }
    aliquotContainer {
      id
      name
      barcode {
        id
        barcodeString
      }
    }
  `
  ]
};

async function loadContainers(fragment, filter) {
  const containers = await safeQuery(fragment, {
    variables: {
      filter
    }
  });
  return sortContainers(containers);
}

export default async function loadChildItemsForTreeNode(node, options = {}) {
  const {
    containerFragment = defaultEquipmentTreeFragments.containerFragment,
    equipmentPositionFragment = defaultEquipmentTreeFragments.equipmentPositionFragment,
    containerPositionFragment = defaultEquipmentTreeFragments.containerPositionFragment,
    assignedPositionFragment = defaultEquipmentTreeFragments.assignedPositionFragment
  } = options;

  let items = [];

  if (node.record.__typename === "equipmentItem") {
    const equipmentPositions = await safeQuery(equipmentPositionFragment, {
      variables: {
        filter: {
          equipmentId: node.record.id
        }
      }
    });

    const filter = containerEmptyPathFilter({ equipmentId: node.record.id });
    const containers = await loadContainers(containerFragment, filter);
    items = equipmentPositions.concat(containers);
  } else if (node.record.__typename === "equipmentPosition") {
    const filter = containerEmptyPathFilter({
      equipmentPositionId: node.record.id
    });
    items = await loadContainers(containerFragment, filter);
  } else if (node.record.__typename === "container") {
    const { id, equipmentItemId, equipmentPositionId, path } = node.record;
    const qb = new QueryBuilder("container");
    const filter = qb
      .whereAll({
        equipmentItemId,
        equipmentPositionId,
        path: (path || "") + `/${id}`
      })
      .toJSON();

    const containers = await loadContainers(containerFragment, filter);
    let containerPositions = await safeQuery(containerPositionFragment, {
      variables: {
        filter: {
          containerId: node.record.id
        }
      }
    });
    containerPositions = [...containerPositions].sort(
      (a, b) => a.index - b.index
    );
    items = containerPositions.concat(containers);
  } else if (node.record.__typename === "containerPosition") {
    const {
      id,
      equipmentItemId,
      equipmentPositionId,
      path
    } = node.record.container;
    const qb = new QueryBuilder("container");
    const filter = qb
      .whereAll({
        equipmentItemId,
        equipmentPositionId,
        path: (path || "") + `/${id}-${node.record.id}`
      })
      .toJSON();
    items = await loadContainers(containerFragment, filter);
  }

  let modelKeyForAssignedPosition = node.record.__typename;
  if (modelKeyForAssignedPosition === "equipmentItem") {
    modelKeyForAssignedPosition = "equipment";
  }

  const assignedPositions = await safeQuery(assignedPositionFragment, {
    variables: {
      filter: {
        [modelKeyForAssignedPosition + "Id"]: node.record.id
      }
    }
  });

  return assignedPositions.concat(items);
}
