/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent, Classes, Callout, Colors } from "@blueprintjs/core";
import { chunk, sortBy, get } from "lodash";
import gql from "graphql-tag";
import {
  RadioGroupField,
  NumericInputField,
  SelectField,
  BlueprintError,
  CheckboxField,
  ReactSelectField
} from "@teselagen/ui";
import { compose } from "recompose";
import Big from "big.js";
import PlateMapView from "../../../../components/PlateMapView";
import {
  getAliquotTransferVolumeFromMass,
  assignAliquotContainerPosition,
  getWellContents
} from "../../../../utils/plateUtils";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import DestinationPlateInfo from "../DestinationPlateInfo";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { ADDITIVE_COLOR } from "../../../PlateMapPlate";
import { standardizeVolume } from "../../../../../src-shared/utils/unitUtils";
import { volumeColumn } from "../../../../../src-shared/utils/libraryColumns";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import {
  getAcItemOfType,
  makePlateMapLocationMap,
  makeWorklistTransfers,
  makeWorklistTransferWithValidationPlateMapGroup
} from "../utils";
import shortid from "shortid";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  generateEmptyWells,
  sortAliquotContainers
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { capitalize } from "lodash";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";

function sortSourceAliquotContainers(aliquotContainers, direction) {
  return sortBy(
    [...aliquotContainers],
    direction === "columnFirst"
      ? ["containerArray.id", "columnPosition", "rowPosition"]
      : ["containerArray.id", "rowPosition", "columnPosition"]
  );
}
class FormatPlate extends Component {
  state = {
    currentPage: 0
  };

  generateWorklist = (values, plates) => {
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const {
      validationPlateMapGroup,
      destinationTransferOrder,
      destinationPlates = [],
      generateBarcodes,
      transferType,
      destinationPlateBarcodes = [],
      destinationPlateNames = [],
      destinationPlateTypes = [],
      transferVolume,
      transferVolumeUnitCode,
      transferMass,
      transferMassUnitCode,
      overrideVolumeInfo,
      isTubeTransfer
    } = values;

    const allAliquotContainers = this.getAllAliquotContainers();
    const rearrayWorklist = {
      worklistTypeCode: isTubeTransfer ? "TUBE_TRANSFER" : undefined
    };

    // this will keep track of how much volume each aliquot container has left.
    // if we are pulling from the same aliquot container multiple times then it needs to have enough
    // volume for each transfer
    const remainingAcVolumeTracker = {};

    allAliquotContainers.forEach(ac => {
      const removeDeadVolume = () => {
        const acType = ac.aliquotContainerType;
        const stdDeadVolume = standardizeVolume(
          acType.deadVolume || 0,
          acType.deadVolumetricUnitCode || "uL",
          true
        );
        remainingAcVolumeTracker[ac.id] = remainingAcVolumeTracker[ac.id].minus(
          stdDeadVolume
        );
      };
      // if there is an aliquot then the available volume is the aliquots volume
      if (ac.aliquot) {
        if (ac.aliquot.volume) {
          remainingAcVolumeTracker[ac.id] = standardizeVolume(
            ac.aliquot.volume,
            ac.aliquot.volumetricUnitCode,
            true
          );
          removeDeadVolume();
        } else {
          remainingAcVolumeTracker[ac.id] = new Big(0);
        }
      } else {
        // if there is not an aliquot and there are additives then the available volume is the sum of the additive's volumes
        remainingAcVolumeTracker[ac.id] = ac.additives.reduce(
          (acc, additive) => {
            if (additive.volume) {
              return acc.plus(
                standardizeVolume(
                  additive.volume,
                  additive.volumetricUnitCode,
                  true
                )
              );
            }
            return acc;
          },
          new Big(0)
        );
        removeDeadVolume();
      }
    });

    const getTransferVolumeFields = (
      aliquotContainer,
      plateMapItemVolumeFields = {},
      dryRun
    ) => {
      const { aliquot } = aliquotContainer;
      let transferVolumeToUse = transferVolume;
      let transferVolumetricUnitCodeToUse = transferVolumeUnitCode;
      if (plateMapItemVolumeFields.volume && !overrideVolumeInfo) {
        transferVolumeToUse = plateMapItemVolumeFields.volume;
        transferVolumetricUnitCodeToUse =
          plateMapItemVolumeFields.volumetricUnitCode;
      }
      if (transferType === "mass" && aliquot) {
        transferVolumeToUse = getAliquotTransferVolumeFromMass(
          aliquot,
          transferMass,
          transferMassUnitCode
        );
        transferVolumetricUnitCodeToUse = aliquot.volumetricUnitCode;
      }

      const standardVolume = standardizeVolume(
        transferVolumeToUse,
        transferVolumetricUnitCodeToUse,
        true
      );
      if (!dryRun) {
        // now remove this volume from the aliquot container tracker
        // this will stop us from transferring a second time from this aliquot container if it is out of volume
        remainingAcVolumeTracker[
          aliquotContainer.id
        ] = remainingAcVolumeTracker[aliquotContainer.id].minus(standardVolume);
      }

      return {
        volume: transferVolumeToUse,
        volumetricUnitCode: transferVolumetricUnitCodeToUse
      };
    };

    const canTransferFrom = (aliquotContainer, volumeOverrides) => {
      const { volume, volumetricUnitCode } = getTransferVolumeFields(
        aliquotContainer,
        volumeOverrides,
        true
      );
      const standardTransferVolume = standardizeVolume(
        volume,
        volumetricUnitCode,
        true
      );
      const currentVolume =
        remainingAcVolumeTracker[aliquotContainer.id] || new Big(0);
      return currentVolume.minus(standardTransferVolume).gte(0);
    };

    const allAliquotContainersCopy = [...allAliquotContainers];

    let transfers;
    if (destinationPlates.length > 0) {
      if (validationPlateMapGroup) {
        const existingPlateLocations = [];
        destinationPlates.forEach((plate, i) => {
          existingPlateLocations[i] = {};
          return plate.aliquotContainers.forEach(ac => {
            existingPlateLocations[i][getAliquotContainerLocation(ac)] = {
              aliquotContainer: ac,
              plateName: plate.name
            };
          });
        });
        transfers = makeWorklistTransferWithValidationPlateMapGroup({
          sourceAliquotContainerList: allAliquotContainersCopy,
          validationPlateMapGroup,
          destinationPlates,
          isTubeTransfer,
          canTransferFrom,
          getTransferVolumeFields,
          existingPlateLocations
        });
      } else {
        // because they are racks they won't have aliquot containers
        // but we need them for positioning
        let destinationPlatesFilled = destinationPlates;
        if (isTubeTransfer) {
          destinationPlatesFilled = destinationPlates.map(p => ({
            ...p,
            aliquotContainers: generateContainerArray(
              p.aliquotContainers,
              p.containerArrayType.containerFormat
            )
          }));
        }
        transfers = makeWorklistTransfers({
          destinationPlates: destinationPlatesFilled,
          isTubeTransfer,
          sourceAliquotContainerList: allAliquotContainersCopy,
          destinationTransferOrder,
          getTransferVolumeFields,
          canTransferFrom
        });
      }
    } else {
      let toBeCreatedDestinationPlates;
      if (validationPlateMapGroup) {
        toBeCreatedDestinationPlates = validationPlateMapGroup.plateMaps.map(
          (plateMap, i) => {
            return {
              cid: shortid(),
              name: destinationPlateNames[i],
              containerArrayType: destinationPlateTypes[i],
              barcode: !generateBarcodes
                ? {
                    barcodeString: destinationPlateBarcodes[i]
                  }
                : undefined,
              aliquotContainers: []
            };
          }
        );
        transfers = makeWorklistTransferWithValidationPlateMapGroup({
          sourceAliquotContainerList: allAliquotContainersCopy,
          validationPlateMapGroup,
          canTransferFrom,
          isTubeTransfer,
          getTransferVolumeFields,
          toBeCreatedDestinationPlates,
          destinationPlateTypes
        });
      } else {
        toBeCreatedDestinationPlates = plates.map((plate, index) => {
          return {
            name: destinationPlateNames[index],
            cid: shortid(),
            barcode: !generateBarcodes
              ? {
                  barcodeString: destinationPlateBarcodes[index]
                }
              : undefined,
            containerArrayType: destinationPlateTypes[index],
            aliquotContainers: generateEmptyWells(
              destinationPlateTypes[index].containerFormat,
              {
                aliquotContainerTypeCode:
                  destinationPlateTypes[index].aliquotContainerTypeCode
              }
            )
          };
        });

        transfers = makeWorklistTransfers({
          destinationPlates: toBeCreatedDestinationPlates,
          sourceAliquotContainerList: allAliquotContainersCopy,
          destinationTransferOrder,
          isTubeTransfer,
          getTransferVolumeFields,
          canTransferFrom
        });
        // remove the aliquotcontainers from destination plates if it was
        // a tube transfer. they were just added for the positioning logic
        if (isTubeTransfer) {
          toBeCreatedDestinationPlates.forEach(p => {
            p.aliquotContainers = [];
          });
        }
      }
      change("toBeCreatedDestinationPlates", toBeCreatedDestinationPlates);
    }
    if (!transfers.length) {
      change("worklistWarnings", transfers.warnings);
      return window.toastr.error("No transfers needed.");
    }
    rearrayWorklist[
      isTubeTransfer ? "tubeTransfers" : "worklistTransfers"
    ] = transfers;
    change("worklist", rearrayWorklist);
    nextStep();
  };

  prevPage = () => {
    this.setState({
      currentPage: this.state.currentPage - 1
    });
  };

  nextPage = () => {
    this.setState({
      currentPage: this.state.currentPage + 1
    });
  };

  getAllAliquotContainers = () => {
    const {
      allAliquotContainers: _allAliquotContainers = [],
      sourceTransferOrder,
      showSampleStatusFilter,
      sampleStatusFilter = []
    } = this.props;

    let allAliquotContainers = sortSourceAliquotContainers(
      _allAliquotContainers,
      sourceTransferOrder
    );

    if (showSampleStatusFilter) {
      allAliquotContainers = allAliquotContainers.filter(ac => {
        const sampleStatusCode = ac.aliquot?.sample?.sampleStatusCode;
        if (sampleStatusCode) {
          if (sampleStatusCode === "VALID") {
            return sampleStatusFilter.includes("Valid");
          } else if (sampleStatusCode === "INVALID") {
            return sampleStatusFilter.includes("Invalid");
          } else {
            return false;
          }
        } else {
          return sampleStatusFilter.includes("No Status");
        }
      });
    }
    return allAliquotContainers;
  };

  render() {
    const { currentPage } = this.state;
    const {
      destinationTransferOrder,
      destinationContainerFormat: _destinationContainerFormat,
      overrideVolumeInfo,
      Footer,
      footerProps,
      validationPlateMapGroup,
      destinationPlates = [],
      worklistWarnings = [],
      showSampleStatusFilter,
      transferType,
      toolSchema,
      handleSubmit,
      isTubeTransfer
    } = this.props;

    const allAliquotContainers = this.getAllAliquotContainers();

    let containerFormat;

    const plateMapHasVolumeInfo = !!get(
      validationPlateMapGroup,
      "plateMaps[0].plateMapItems[0].volume"
    );

    const destinationContainerFormat = _destinationContainerFormat || {};

    if (validationPlateMapGroup) {
      containerFormat = validationPlateMapGroup.containerFormat;
    } else if (destinationContainerFormat) {
      containerFormat = destinationContainerFormat;
    }
    let destinationPlateHasEnoughRoom = true;
    let previewPlates;
    const missingMaterials = [];
    const missingSamples = [];
    const missingAdditiveMaterials = [];
    const missingLots = [];
    const materialsNotInCorrectLocations = [];
    const samplesNotInCorrectLocations = [];

    const plateMapType = get(validationPlateMapGroup, "plateMaps[0].type");
    const sourceAcLocationMap = makePlateMapLocationMap(
      allAliquotContainers,
      validationPlateMapGroup
    );

    const destinationPlateLocationMap = [];
    if (validationPlateMapGroup && plateMapType) {
      if (destinationPlates.length > 0) {
        destinationPlates.forEach((plate, i) => {
          destinationPlateLocationMap[i] = {};
          plate.aliquotContainers.forEach(ac => {
            const item = getAcItemOfType(ac, plateMapType);

            if (item) {
              destinationPlateLocationMap[i][
                getAliquotContainerLocation(ac)
              ] = item;
            }
          });
        });
      }

      validationPlateMapGroup.plateMaps.forEach((plateMap, i) => {
        plateMap.plateMapItems.forEach(
          ({ inventoryItem, rowPosition, columnPosition }) => {
            const item = inventoryItem && inventoryItem[plateMapType];
            let missingArray = [];
            let incorrectLocationArray = [];
            if (plateMapType === "material") {
              missingArray = missingMaterials;
              incorrectLocationArray = materialsNotInCorrectLocations;
            } else if (plateMapType === "sample") {
              missingArray = missingSamples;
              incorrectLocationArray = samplesNotInCorrectLocations;
            } else if (plateMapType === "lot") {
              missingArray = missingLots;
            } else if (plateMapType === "additiveMaterial") {
              missingArray = missingAdditiveMaterials;
            }
            if (item && !sourceAcLocationMap[item.id]) {
              missingArray.push(item.name);
            }
            const location = getAliquotContainerLocation({
              rowPosition,
              columnPosition
            });

            if (
              item &&
              destinationPlateLocationMap[i]?.[location]?.id &&
              destinationPlateLocationMap[i][location].id !== item.id
            ) {
              incorrectLocationArray.push(
                `${location}: ${item.name} (found ${destinationPlateLocationMap[i][location].name})`
              );
            }
          }
        );
      });
    }

    let missingTubeError = "";

    if (destinationPlates.length > 0) {
      const validationLocationMap = [];
      if (validationPlateMapGroup) {
        // if the user has selected a destination plate and selected a plate map, we should
        // A) validate to make sure there are no overlapping wells
        validationPlateMapGroup.plateMaps.forEach((plateMap, i) => {
          validationLocationMap[i] = {};
          plateMap.plateMapItems.forEach(
            ({ inventoryItem, rowPosition, columnPosition }) => {
              const item = inventoryItem && inventoryItem[plateMapType];
              if (item) {
                const sourceAc = sourceAcLocationMap[item.id]?.[0];
                validationLocationMap[i][
                  getAliquotContainerLocation({ rowPosition, columnPosition })
                ] = sourceAc;
              }
            }
          );
        });
      }
      // these are all of the aliquot containers from the source plates (NOT DESTINATION)
      const aliquotContainerList = [...allAliquotContainers];
      const existingDestPlates = [];
      destinationPlates.forEach((plate, i) => {
        existingDestPlates.push({
          ...plate,
          aliquotContainers: sortAliquotContainers(
            generateContainerArray(
              plate.aliquotContainers,
              plate.containerArrayType.containerFormat
            ),
            destinationTransferOrder
          )
            .reduce((acc, aliquotContainer) => {
              if (
                isTubeTransfer ? aliquotContainer.id : aliquotContainer.aliquot
              ) {
                return acc.concat(aliquotContainer);
              }
              if (validationPlateMapGroup) {
                const location = getAliquotContainerLocation(aliquotContainer);
                const sourceAc = validationLocationMap[i]?.[location];

                if (sourceAc) {
                  if (!aliquotContainer.id && !isTubeTransfer) {
                    missingTubeError += `No destination tube at location ${location} on destination rack. Cannot make transfer.\n`;
                    return acc;
                  } else {
                    return acc.concat({
                      ...aliquotContainer,
                      aliquot: {
                        ...sourceAc.aliquot,
                        color: Colors.GREEN3
                      }
                    });
                  }
                } else {
                  return acc;
                }
              } else {
                if (aliquotContainerList.length) {
                  const aliquot = aliquotContainerList.shift().aliquot;
                  return acc.concat({
                    ...aliquotContainer,
                    aliquot: {
                      ...aliquot,
                      color: Colors.GREEN3
                    }
                  });
                } else {
                  return acc;
                }
              }
            }, [])
            .map(ac => {
              if (!ac.id) {
                return {
                  ...ac,
                  id: shortid()
                };
              } else {
                return ac;
              }
            })
        });
      });
      previewPlates = existingDestPlates;
      // only need to drain source aliquot list if not validating with a plate map
      if (!validationPlateMapGroup) {
        destinationPlateHasEnoughRoom = !aliquotContainerList.length;
      }
    } else {
      const plates = [];
      if (validationPlateMapGroup) {
        validationPlateMapGroup.plateMaps.forEach((plateMap, i) => {
          const aliquotContainers = [];
          plateMap.plateMapItems.forEach(
            ({
              volume,
              volumetricUnitCode,
              inventoryItem,
              rowPosition,
              columnPosition
            }) => {
              const item = inventoryItem && inventoryItem[plateMapType];
              const existingAc = get(sourceAcLocationMap, `${item.id}[0]`);
              if (existingAc && item) {
                aliquotContainers.push({
                  id: shortid(),
                  rowPosition,
                  columnPosition,
                  volume,
                  volumetricUnitCode,
                  wellContents: item.name,
                  aliquot: {
                    color: Colors.GREEN3
                  }
                });
              }
            }
          );
          plates.push({ index: i, aliquotContainers });
        });
        previewPlates = plates;
      } else {
        const aliquotContainerGroups = chunk(
          allAliquotContainers,
          containerFormat.quadrantSize
        );

        const plates = aliquotContainerGroups.map(aliquotContainerGroup => {
          return assignAliquotContainerPosition(
            aliquotContainerGroup,
            containerFormat,
            { columnFirst: destinationTransferOrder === "columnFirst" }
          );
        });
        previewPlates = plates.map((aliquotContainers, index) => {
          return {
            index,
            aliquotContainers: aliquotContainers.map(ac => {
              const toRet = {
                ...ac,
                id: ac.id || shortid()
              };
              if (ac.additives && ac.additives.length && !ac.aliquot) {
                return {
                  ...toRet,
                  aliquot: {
                    color: ADDITIVE_COLOR
                  },
                  wellContents: ac.additives
                    .map(additive =>
                      additive.lot
                        ? additive.lot.name
                        : additive.additiveMaterial.name
                    )
                    .join(", ")
                };
              } else {
                return toRet;
              }
            })
          };
        });
      }
    }

    let plateMapGroupValidationFailed = false;
    if (validationPlateMapGroup && !previewPlates[0].aliquotContainers.length) {
      plateMapGroupValidationFailed = true;
    }
    const hasVolumeInfoOnPreview = get(
      previewPlates,
      `[${currentPage}].aliquotContainers[0].volume`
    );
    const missingPlateMapSourceItems =
      missingMaterials.length ||
      missingSamples.length ||
      missingAdditiveMaterials.length ||
      missingLots.length;

    const destinationContainerType = isTubeTransfer ? "rack" : "plate";
    const DestinationContainerType = capitalize(destinationContainerType);
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          {!validationPlateMapGroup && (
            <React.Fragment>
              <div className="tg-flex justify-space-between">
                <HeaderWithHelper
                  header={`Destination ${DestinationContainerType} Format`}
                  helper={`Select a format for your destination ${destinationContainerType}(s). This will filter destination ${destinationContainerType} types, existing destination ${destinationContainerType} and plate maps.`}
                />
                <div style={{ maxWidth: 250 }}>
                  <GenericSelect
                    {...{
                      name: "destinationContainerFormat",
                      asReactSelect: true,
                      label: `Destination ${DestinationContainerType} Format`,
                      schema: [
                        {
                          path: "name"
                        }
                      ],
                      idAs: "code",
                      fragment: aliquotRearrayContainerFormatFragment,
                      queryOptions: {
                        variables: {
                          sort: ["quadrantSize", "code"]
                        }
                      }
                    }}
                  />
                </div>
              </div>
              <hr className="tg-section-break" />
            </React.Fragment>
          )}
          <DestinationPlateInfo
            destinationContainerType={destinationContainerType}
            canSelectExistingPlate
            plates={previewPlates}
            toolSchema={toolSchema}
            destinationContainerFormat={containerFormat}
          />
          {!destinationPlateHasEnoughRoom && (
            <BlueprintError
              error={`The chosen destination ${destinationContainerType} do not have enough space to fit all the ${
                isTubeTransfer ? "tubes" : "aliquots"
              }.`}
            />
          )}
          {!!materialsNotInCorrectLocations.length && (
            <Callout intent="danger">
              These materials were not in the correct location on the
              destination {destinationContainerType}:{" "}
              {materialsNotInCorrectLocations.join(", ")}
            </Callout>
          )}
          {!!samplesNotInCorrectLocations.length && (
            <Callout intent="danger">
              These samples were not in the correct location on the destination
              {destinationContainerType}:{" "}
              {samplesNotInCorrectLocations.join(", ")}
            </Callout>
          )}
          <div style={{ marginBottom: 15 }} />
          {!!missingMaterials.length && (
            <Callout intent="warning">
              These materials were specified by the plate map but not found on
              the materials from inventory: {missingMaterials.join(", ")}
            </Callout>
          )}
          {!!missingSamples.length && (
            <Callout intent="warning">
              These samples were specified by the plate map but not found on the
              samples from inventory: {missingSamples.join(", ")}
            </Callout>
          )}
          {!!missingAdditiveMaterials.length && (
            <Callout intent="warning">
              These reagents were specified by the plate map but not found on
              the reagents from inventory: {missingAdditiveMaterials.join(", ")}
            </Callout>
          )}
          {!!missingLots.length && (
            <Callout intent="warning">
              These lots were specified by the plate map but not found on the
              lots from inventory: {missingLots.join(", ")}
            </Callout>
          )}
          {plateMapGroupValidationFailed && (
            <Callout intent="danger">
              Validation with this plate map failed. The selected destination
              rack has no tubes to transfer to.
            </Callout>
          )}
          {missingTubeError && (
            <Callout intent="danger">{missingTubeError}</Callout>
          )}
        </div>
        {destinationPlateHasEnoughRoom &&
          containerFormat &&
          containerFormat.code && (
            <div className="tg-step-form-section column">
              <div className="tg-flex">
                <HeaderWithHelper
                  header={`Format ${DestinationContainerType}s`}
                  helper="Choose either a row first or column first layout or select a plate map to automate plate layout."
                />
                <div
                  style={{ width: "50%", marginRight: "5%", marginLeft: "5%" }}
                  className="tg-flex justify-space-between"
                >
                  {!validationPlateMapGroup && (
                    <div>
                      <RadioGroupField
                        generateDefaultValue={
                          defaultValueConstants.ALIQUOT_REARRAY_SOURCE_WELL_ORDER
                        }
                        className="remove-field-margin"
                        label="Source Transfer Order"
                        options={
                          defaultValueConstants
                            .ALIQUOT_REARRAY_SOURCE_WELL_ORDER.options
                        }
                        name="sourceTransferOrder"
                      />
                      <RadioGroupField
                        generateDefaultValue={
                          defaultValueConstants.ALIQUOT_REARRAY_DESTINATION_WELL_ORDER
                        }
                        className="remove-field-margin"
                        label="Destination Placement Order"
                        options={
                          defaultValueConstants
                            .ALIQUOT_REARRAY_DESTINATION_WELL_ORDER.options
                        }
                        name="destinationTransferOrder"
                      />
                    </div>
                  )}
                  <div>
                    <CheckboxField
                      label="Filter By Sample Status"
                      name="showSampleStatusFilter"
                    />
                    {showSampleStatusFilter && (
                      <ReactSelectField
                        name="sampleStatusFilter"
                        multi
                        options={["Valid", "Invalid", "No Status"]}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="tg-flex align-center row">
                {previewPlates.length > 1 && (
                  <React.Fragment>
                    <Button
                      disabled={currentPage === 0}
                      onClick={this.prevPage}
                      className={Classes.MINIMAL}
                      icon="arrow-left"
                    />
                    {DestinationContainerType} {currentPage + 1} of{" "}
                    {previewPlates.length}
                    <Button
                      disabled={currentPage === previewPlates.length - 1}
                      onClick={this.nextPage}
                      className={Classes.MINIMAL}
                      icon="arrow-right"
                    />
                  </React.Fragment>
                )}
              </div>
              {destinationPlateHasEnoughRoom && (
                <PlateMapView
                  noPadding
                  containerArray={{
                    aliquotContainers:
                      previewPlates[currentPage]?.aliquotContainers || [],
                    containerArrayType: {
                      containerFormat,
                      isPlate: destinationPlates[currentPage]
                        ? destinationPlates[currentPage].containerArrayType
                            .isPlate
                        : !isTubeTransfer
                    }
                  }}
                  tableSchema={[
                    {
                      displayName: "Well",
                      render: (v, r) => {
                        return getAliquotContainerLocation(
                          {
                            rowPosition: r.rowPosition,
                            columnPosition: r.columnPosition
                          },
                          {
                            containerFormat
                          }
                        );
                      }
                    },
                    {
                      displayName: "Contents",
                      render: (v, r) => r.wellContents || getWellContents(r)
                    },
                    ...(hasVolumeInfoOnPreview ? [volumeColumn] : [])
                  ]}
                />
              )}
            </div>
          )}
        {!isTubeTransfer && (
          <React.Fragment>
            <div className="tg-step-form-section">
              <HeaderWithHelper
                header="Transfer Volume"
                helper={
                  "Enter the desired transfer volume/mass from each of the input materials selected during the previous step." +
                  (plateMapHasVolumeInfo
                    ? " The chosen plate map specifies volume information. This will be used for transfers unless overridden."
                    : "")
                }
              />
              <div>
                {plateMapHasVolumeInfo && (
                  <CheckboxField
                    label="Override Plate Map Volume Info"
                    name="overrideVolumeInfo"
                  />
                )}
                {(!plateMapHasVolumeInfo ||
                  (plateMapHasVolumeInfo && overrideVolumeInfo)) && (
                  <React.Fragment>
                    <RadioGroupField
                      defaultValue="volume"
                      inline
                      options={[
                        {
                          label: "Volume",
                          value: "volume"
                        },
                        {
                          label: "Mass",
                          value: "mass"
                        }
                      ]}
                      label="Transfer Type"
                      name="transferType"
                    />
                    <div className="input-with-unit-select">
                      {transferType === "volume" ? (
                        <React.Fragment>
                          <NumericInputField
                            label="Transfer Volume"
                            name="transferVolume"
                            min={0}
                          />
                          <SelectField
                            name="transferVolumeUnitCode"
                            defaultValue="uL"
                            label="none"
                            className="tg-unit-select"
                            options={unitGlobals.getOptionsForSelect(
                              "volumetricUnit"
                            )}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <NumericInputField
                            label="Mass"
                            name="transferMass"
                            min={0}
                          />
                          <SelectField
                            name="transferMassUnitCode"
                            label="none"
                            defaultValue="ng"
                            className="tg-unit-select"
                            options={unitGlobals.getOptionsForSelect(
                              "massUnit"
                            )}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}

        {!!worklistWarnings.length && (
          <Callout intent={Intent.WARNING} className="preserve-newline">
            {worklistWarnings.join("\n")}
          </Callout>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent={Intent.PRIMARY}
              disabled={
                !destinationPlateHasEnoughRoom ||
                materialsNotInCorrectLocations.length ||
                samplesNotInCorrectLocations.length ||
                plateMapGroupValidationFailed ||
                missingTubeError ||
                missingPlateMapSourceItems.length
              }
              onClick={handleSubmit(values =>
                this.generateWorklist(values, previewPlates)
              )}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

const aliquotRearrayContainerFormatFragment = gql`
  fragment aliquotRearrayContainerFormatFragment on containerFormat {
    code
    name
    rowCount
    columnCount
    quadrantSize
    is2DLabeled
    containerArrayTypes(pageSize: 1) {
      id
    }
  }
`;

export default compose(
  stepFormValues(
    "allAliquotContainers",
    "destinationContainerFormat",
    "destinationTransferOrder",
    "sourceTransferOrder",
    "validationPlateMapGroup",
    "destinationPlates",
    "transferType",
    "overrideVolumeInfo",
    "isTubeTransfer",
    "worklistWarnings",
    "showSampleStatusFilter",
    "sampleStatusFilter"
  )
)(FormatPlate);
