/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function getPlatePrepKey(record) {
  return record.__typename + record.id;
}

export function getIntermediateContainerAdjustedMaxVolume({
  intermediateContainerType,
  standardizedTransferVolume
}) {
  const {
    deadVolume,
    deadVolumetricUnitCode,
    volumetricUnitCode,
    maxVolume
  } = intermediateContainerType.aliquotContainerType;

  const standardMaxVolume = standardizeVolume(
    maxVolume,
    volumetricUnitCode,
    true
  );
  const standardizedDeadVolume = standardizeVolume(
    deadVolume || 0,
    deadVolumetricUnitCode || "uL",
    true
  );
  let maxWellVolumeAdjusted = standardMaxVolume.minus(standardizedDeadVolume);
  if (!maxWellVolumeAdjusted.mod(standardizedTransferVolume).eq(0)) {
    maxWellVolumeAdjusted = maxWellVolumeAdjusted.minus(
      maxWellVolumeAdjusted.mod(standardizedTransferVolume)
    );
  }
  return maxWellVolumeAdjusted;
}
