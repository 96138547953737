/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import MustacheTemplateField from "../../../../src-shared/MustacheTemplateField";

const fieldName = "universalPlateNameTemplate";

class UniversalPlateNameTemplateField extends Component {
  render() {
    const {
      label = "Plate Name Template",
      onFieldSubmit,
      defaultValue,
      templateVariables = [],
      tooltipInfo,
      name = fieldName
    } = this.props;

    return (
      <MustacheTemplateField
        {...{
          name,
          label,
          onFieldSubmit,
          defaultValue,
          templateVariables,
          tooltipInfo
        }}
      />
    );
  }
}

export default UniversalPlateNameTemplateField;
