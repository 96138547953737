/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import axios from "axios";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { Intent, Button, Tooltip } from "@blueprintjs/core";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { SelectField, InputField } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import {
  safeUpsert,
  safeDelete
} from "../../../../../src-shared/apolloMethods";

// The following global constants correspond to the default configurations for
// coverage and variant calling settings.
const DEFAULT_COVERAGE_SETTINGS = {
  name: "Default",
  coverageSettings: {
    min_bp_cov: 10,
    cov_th: 0.995,
    BAQ: 20,
    MAQ: 20
  }
};
const TEMPLATE_COVERAGE_SETTINGS = {
  name: "",
  coverageSettings: {
    min_bp_cov: 10,
    cov_th: 0.995,
    BAQ: 20,
    MAQ: 20
  }
};
const DEFAULT_VARIANT_CALLING_SETTINGS = {
  name: "Default",
  variantCallingSettings: {
    bq_vc: 20,
    mq_vc: 20,
    qd_vc: 20
  }
};
const TEMPLATE_VARIANT_CALLING_SETTINGS = {
  name: "",
  variantCallingSettings: {
    bq_vc: 20,
    mq_vc: 20,
    qd_vc: 20
  }
};

class AssemblyQCToolConfig extends React.Component {
  runAssemblyQC = async () => {
    const {
      jobName,
      min_bp_cov,
      cov_th,
      ploidy,
      filter,
      alignments,
      stepFormProps: { change }
    } = this.props;
    const saqcToolParams = { jobName, min_bp_cov, cov_th, ploidy, filter };
    change("job_running", true);
    return axios
      .request({
        method: "post",
        baseURL: localStorage.getItem("serverURI"),
        url: "/dsTools/runAssemblyQCTool",
        withCredentials: true,
        data: { saqcToolParams, alignmentNameAndIds: alignments }
      })
      .then(response => {
        const job_reports = response.data.output;
        change("job_reports", job_reports);
        const assemblyqcs = job_reports.map(job_report => ({
          sampleId: this.props.alignments.filter(
            alignment => alignment.id === job_report.alignmentId
          )[0].sampleId,
          sampleName: "",
          taskId: job_report.jobId,
          assembly_approved: false,
          coverage_approved: false,
          assembly_notes: ""
        }));
        const assembly_reports_inspected = job_reports.map(job_report => ({
          taskId: job_report.jobId,
          inspected: false
        }));
        change("job_running", false);
        change("assemblyqcs", assemblyqcs);
        change("assembly_reports_inspected", assembly_reports_inspected);
      })
      .catch(err => {
        change("job_running", false);
        console.error(err);
      });
  };

  UNSAFE_componentWillMount() {
    this.updateCoverageSettings();
    this.updateVariantCallingSettings();
  }

  displayCoverageDialog(newSettingsMode) {
    this.updateCoverageSettings();
    const {
      selectedCoverageSettings,
      sequencingQcCoveragePresetsQuery,
      sequencingQcCoveragePresets = []
    } = this.props;
    showDialog({
      modalType: "SequencingQCCoverageSettingsDialog",
      modalProps: {
        newSettingsMode,
        coverageSettings: newSettingsMode
          ? TEMPLATE_COVERAGE_SETTINGS
          : selectedCoverageSettings,
        forbiddenPresetNames: sequencingQcCoveragePresets.map(
          record => record.name
        ),
        safeUpsert,
        safeDelete,
        // Needed, because after closing the modal, we need to trigger the withQuery query again to update
        // The <SelectField/> components with the new coverage setting names.
        refetch: sequencingQcCoveragePresetsQuery.refetch,
        updateSettings: this.updateCoverageSettings.bind(this)
      }
    });
  }

  displayVariantCallingDialog(newSettingsMode) {
    const {
      selectedVariantCallingSettings,
      sequencingQcVariantCallingPresetsQuery,
      sequencingQcVariantCallingPresets = []
    } = this.props;
    showDialog({
      modalType: "SequencingQCVariantCallingSettingsDialog",
      modalProps: {
        newSettingsMode,
        variantCallingSettings: newSettingsMode
          ? TEMPLATE_VARIANT_CALLING_SETTINGS
          : selectedVariantCallingSettings,
        forbiddenPresetNames: sequencingQcVariantCallingPresets.map(
          record => record.name
        ),
        safeUpsert,
        safeDelete,
        // Needed, because after closing the modal, we need to trigger the withQuery query again to update
        // The <SelectField/> components with the new variant calling setting names.
        refetch: sequencingQcVariantCallingPresetsQuery.refetch,
        updateSettings: this.updateVariantCallingSettings.bind(this)
      }
    });
  }

  updateCoverageSettings(value) {
    const {
      stepFormProps: { change },
      sequencingQcCoveragePresets,
      coverageSettingsName
    } = this.props;
    let _selectedCoverageSettings = DEFAULT_COVERAGE_SETTINGS;
    const _newCovName = value ? value : coverageSettingsName;
    if (
      sequencingQcCoveragePresets &&
      sequencingQcCoveragePresets.length !== 0 &&
      _newCovName !== DEFAULT_COVERAGE_SETTINGS.name
    ) {
      _selectedCoverageSettings = sequencingQcCoveragePresets.filter(
        setting => setting.name === _newCovName
      )[0];
    }
    if (_selectedCoverageSettings) {
      change("selectedCoverageSettings", _selectedCoverageSettings);
      change("BAQ", _selectedCoverageSettings.coverageSettings.BAQ);
      change("MAQ", _selectedCoverageSettings.coverageSettings.MAQ);
      change("cov_th", _selectedCoverageSettings.coverageSettings.cov_th);
      change(
        "min_bp_cov",
        _selectedCoverageSettings.coverageSettings.min_bp_cov
      );
    }
  }

  updateVariantCallingSettings(value) {
    const {
      stepFormProps: { change },
      sequencingQcVariantCallingPresets,
      variantCallingSettingsName
    } = this.props;
    let _selectedVariantCallingSettings = DEFAULT_VARIANT_CALLING_SETTINGS;
    const _newVcName = value ? value : variantCallingSettingsName;
    if (
      sequencingQcVariantCallingPresets &&
      sequencingQcVariantCallingPresets.length !== 0 &&
      _newVcName !== DEFAULT_VARIANT_CALLING_SETTINGS.name
    ) {
      _selectedVariantCallingSettings = sequencingQcVariantCallingPresets.filter(
        setting => setting.name === _newVcName
      )[0];
    }
    change("selectedVariantCallingSettings", _selectedVariantCallingSettings);
    change(
      "bq_vc",
      _selectedVariantCallingSettings.variantCallingSettings.bq_vc
    );
    change(
      "mq_vc",
      _selectedVariantCallingSettings.variantCallingSettings.mq_vc
    );
    change(
      "qd_vc",
      _selectedVariantCallingSettings.variantCallingSettings.qd_vc
    );
  }

  render() {
    const {
      Footer,
      footerProps,
      sequencingQcCoveragePresets = [],
      sequencingQcVariantCallingPresets = []
    } = this.props;

    const seqQCGroupNameSection = (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Group Name of Sequencing QC Reports"
          width="100%"
          helper="Enter a name for this group of sequencing QC reports. Each report will be named '{{Aliquot ID}}_{{Reference Sequence Name}}_{{QC Group Name}}'."
        />
        <InputField
          id="secQCGroupName"
          name="secQCGroupName"
          style={{ width: 200 }}
        />
      </div>
    );

    const coverageSettingsSection = (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Coverage Settings"
          width="100%"
          helper="Configure parameters to compute assembly coverage"
        />
        <div style={{ display: "inline-flex" }}>
          <SelectField
            id="coverageSettingsName"
            name="coverageSettingsName"
            label="Parameter Set"
            defaultValue="Default"
            options={sequencingQcCoveragePresets
              .map(record => record.name)
              .filter(option => option !== "Default")
              .concat(["Default"])}
            onFieldSubmit={value => this.updateCoverageSettings(value)}
            style={{ width: 200 }}
          />
          <div style={{ margin: "25px 0 0 10px" }}>
            <Tooltip content="Add Parameter Set">
              <Button
                icon="plus"
                intent={Intent.PRIMARY}
                minimal
                small
                onClick={() => this.displayCoverageDialog(true)}
              />
            </Tooltip>
            <Tooltip content="View Parameter Set">
              <Button
                icon="eye-open"
                intent={Intent.PRIMARY}
                minimal
                small
                onClick={() => this.displayCoverageDialog(false)}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );

    const variantCallingSettingsSection = (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Variant Calling Settings"
          width="100%"
          helper="Configure parameters to identify variations between the sequencing data and reference sequence"
        />
        <div style={{ display: "inline-flex" }}>
          <SelectField
            id="variantCallingSettingsName"
            name="variantCallingSettingsName"
            label="Variant Calling Filter Preset"
            defaultValue="Default"
            options={sequencingQcVariantCallingPresets
              .map(record => record.name)
              .filter(option => option !== "Default")
              .concat(["Default"])}
            onFieldSubmit={value => this.updateVariantCallingSettings(value)}
            style={{ width: 200 }}
          />
          <div style={{ margin: "25px 0 0 10px" }}>
            <Tooltip content="Add Variant Calling Filter Preset">
              <Button
                icon="plus"
                intent={Intent.PRIMARY}
                minimal
                small
                onClick={() => {
                  this.displayVariantCallingDialog(true);
                }}
              />
            </Tooltip>
            <Tooltip content="View Variant Calling Filter Preset">
              <Button
                icon="eye-open"
                intent={Intent.PRIMARY}
                minimal
                small
                onClick={() => {
                  this.displayVariantCallingDialog(false);
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {seqQCGroupNameSection}
        {coverageSettingsSection}
        {variantCallingSettingsSection}
        <Footer
          {...footerProps}
          nextButton={
            <Button intent={Intent.SUCCESS} type="submit" text="Submit" />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  // connect(null, { show: actions.ui.modal.show }),
  withQuery(
    [
      "sequencingQcCoveragePreset",
      "id name description coverageSettings userId"
    ],
    {
      isPlural: true,
      options: props => {
        const userId = props.currentUser.id;
        return {
          variables: {
            filter: { userId }
          }
        };
      }
    }
  ),
  withQuery(
    [
      "sequencingQcVariantCallingPreset",
      "id name description variantCallingSettings userId"
    ],
    {
      isPlural: true,
      options: props => {
        const userId = props.currentUser.id;
        return {
          variables: {
            filter: { userId }
          }
        };
      }
    }
  ),
  stepFormValues(
    "job_running",
    "jobName",
    "BAQ",
    "MAQ",
    "min_bp_cov",
    "cov_th",
    "bq_vc",
    "mq_vc",
    "qd_vc",
    "ploidy",
    "filter",
    "alignments",
    "selectedCoverageSettings",
    "coverageSettingsName",
    "selectedVariantCallingSettings",
    "variantCallingSettingsName",
    "secQCGroupName"
  )
)(AssemblyQCToolConfig);
