/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { SelectField } from "@teselagen/ui";
import stepFormValues from "../../../src-shared/stepFormValues";
import withQuery from "../../../src-shared/withQuery";
import HeaderWithHelper from "../../HeaderWithHelper";
import { Button, Intent } from "@blueprintjs/core";

import { isCsvFile } from "../../../../tg-iso-shared/src/utils/fileUtils";
import { get, size } from "lodash";
import { ValidationMsgComponent } from "../../../src-shared/ValidationComponent";
import ExperimentalDataFileUpload from "../../components/ExperimentalDataFileUpload";

const headers = [
  "Assay Subject Name",
  "Descriptor 1",
  "Descriptor 2",
  "Metabolite Concentration",
  "Metabolite Concentration Unit"
];

const TEMPLATE_FILE_NAME = "Assay Results";

const UploadDataFile = ({
  Footer,
  footerProps,
  submitLoading,
  submitted,
  uploadedFiles,
  dataParserId,
  integrations,
  stepFormProps: { destroy },
  clearSubmissionState,
  parserValidationErrors = []
}) => {
  const [allFilesAreCSV, setAllFilesAreCsv] = useState(false);
  const [needsParser, setNeedsParser] = useState();
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    clearSubmissionState();
  }, [dataParserId]); //eslint-disable-line

  const renderParserValidationComponent = () => {
    // If no errors, then just return a null component.
    if (!size(parserValidationErrors)) return null;

    // return (
    return parserValidationErrors.map((validationError, idx) => {
      const { code, name, validationErrors } = validationError;

      let validationMessage = `[${name}]: ${validationErrors[0].message}.`;
      if (code !== "yup-validation") {
        validationMessage +=
          " Make sure the selected Data Parser is correct and matches you Data File.";
      }
      validationMessage = validationMessage.replace("..", ".");
      return (
        <React.Fragment key={`parserValidationError-${idx}`}>
          <ValidationMsgComponent
            validationMessage={validationMessage}
            intent={Intent.DANGER}
          />
        </React.Fragment>
      );
    });
  };

  const renderCsvValidationComponent = () => {
    const validationError = validationErrors[0];
    const validationMessage = `${validationError.message}. Either fix your CSV file or select an appropriate Data Parser.`;
    return ValidationMsgComponent({
      validationMessage,
      intent: Intent.WARNING
    });
  };

  const validateFiles = (files, validation) => {
    if (files.length === 0) return false;

    let filesAreValid = true;
    let filesAreCSV = true;

    // Loop through each file and check if it is a CSV file
    files.forEach((file, file_index) => {
      // If no file is found return false
      if (!file) return false;

      if (!isCsvFile(file)) {
        filesAreCSV = false;
      }

      // if some file is invalid, set filesAreValid to false
      const { isValid, validationErrors } = validation[file_index];
      if (!isValid) {
        filesAreValid = false;
        setValidationErrors(validationErrors);
      }
    });

    // When some CSV file is invalid, we just set the needsParser to true
    if (filesAreCSV) {
      setNeedsParser(!filesAreValid);
      setAllFilesAreCsv(true);
    } else {
      setNeedsParser(true);
      setAllFilesAreCsv(false);
    }
    return true;
  };

  const renderUploadSection = (
    <ExperimentalDataFileUpload
      onRemove={(files, validation) => {
        if (!size(files)) {
          clearSubmissionState();
          destroy();
          return;
        } else {
          validateFiles(files, validation);
        }
      }}
      withTags={true}
      templateFileName={TEMPLATE_FILE_NAME}
      template={{ headers }}
      beforeUpload={validateFiles}
    />
  );

  const parserNeededButNotSelected = needsParser && !dataParserId;
  const submitDisabled = !size(uploadedFiles) || parserNeededButNotSelected;

  const selectDataFileParserSection = (
    <div className="tg-step-form-section column">
      <div className="tg-flex align-flex-start column">
        <HeaderWithHelper
          header="Select data file parser"
          helper={
            <span>
              If needed, choose a data file parser integration. These are
              configured at TeselaGen Settings {">"} Integrations.
            </span>
          }
        />
        <SelectField
          tooltipInfo={
            <span>
              Only CSVs that are properly tabulated can proceed without a
              parser.
            </span>
          }
          placeholder="Select a parser"
          options={[
            ...integrations.map(integration => ({
              value: get(integration, "integrationEndpoints.0.id"),
              label: integration.name
            }))
          ]}
          name="dataParserId"
          label="Select Data Parser"
        />
        {allFilesAreCSV && parserNeededButNotSelected
          ? renderCsvValidationComponent()
          : null}
        {submitted && size(parserValidationErrors)
          ? renderParserValidationComponent()
          : null}
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div className="tg-step-form-section column">{renderUploadSection}</div>
      {!!size(uploadedFiles) && selectDataFileParserSection}
      {/**
       * TODO: Submitting can take quite a while, Maybe implement a sort of loading view with steps like:
       * "Parsing file" ---- "Generating CSVs" ---- "Uploading CSVs" ---- "Generating DataGrids"
       */}
      <Footer
        {...footerProps}
        nextButton={
          <Button
            id="generate-data-grids-button"
            type="submit"
            intent={Intent.SUCCESS}
            text="Generate Data Grids"
            disabled={submitDisabled}
            loading={submitLoading}
          />
        }
      />
    </React.Fragment>
  );
};

export default compose(
  withQuery(
    [
      "integration",
      "id integrationTypeCode name integrationEndpoints { id endpointTypeCode url integrationEndpointHeaders { id name value } }"
    ],
    {
      showLoading: true,
      isPlural: true,
      options: () => {
        return {
          variables: {
            filter: {
              integrationTypeCode: "FILE_TO_DATAGRID"
            }
          }
        };
      }
    }
  ),
  stepFormValues("uploadedFiles", "dataParserId")
)(UploadDataFile);
