/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment dataTablePartialFragment on dataTable {
    id
    name
    dataSetId
    dataTableTypeCode
    dataSet {
      id
      name
      type
    }
    dataTableType {
      code
      name
    }
  }
`;
