/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  Loading,
  BlueprintError,
  DataTable,
  CheckboxField,
  ReactSelectField,
  FileUploadField
} from "@teselagen/ui";
import {
  capitalize,
  get,
  groupBy,
  maxBy,
  some,
  sortBy,
  startCase
} from "lodash";
import { Button, Callout, Icon, Tooltip } from "@blueprintjs/core";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import {
  additivesHaveVolumeToTransfer,
  aliquotHasVolumeToTransfer,
  getSampleMaterialList
} from "../../../../utils/plateUtils";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import AutomateSelectionField from "../../../AutomateSelectionField";
import {
  volumeRender,
  concentrationRender,
  massRender,
  withUnitGeneric
} from "../../../../../src-shared/utils/unitUtils";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import caseInsensitiveFilter from "../../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { compose } from "recompose";
import SelectAliquotOptionForInventoryCheck from "../../../SelectAliquotOptionForInventoryCheck";
import AliquotVolumeLessThanDeadWarning from "../../../AliquotVolumeLessThanDeadWarning";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { minBy } from "lodash";
import { lowerCase } from "lodash";
import SelectJ5MaterialsOrPcrReactions from "../../../SelectJ5MaterialsOrPcrReactions";
import {
  inputTypeModels,
  inputTypeModelsForReagents,
  multiSelectModels,
  reactionEntityTypeOptionsInventoryCheck
} from "../utils";
import pluralize from "pluralize";
import containerArrayPlatePreviewFragment from "../../../../graphql/fragments/containerArrayPlatePreviewFragment";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import fieldConstants from "../fieldConstants";
import QueryBuilder from "tg-client-query-builder";
import { addActiveProjectFilter } from "../../../../../src-shared/utils/projectUtils";

const getSelectedAliquotForMaterial = (material, values) => {
  const { materialIdToOptions = {}, materialIdToSelectedOption = {} } = values;

  const options = materialIdToOptions[material.id] || [];
  if (options.length === 1 || materialIdToSelectedOption[material.id]) {
    return materialIdToSelectedOption[material.id] || options[0];
  }
};

const plateMapFragment = `
  id
  plateMapItems {
    id
    inventoryItem {
      id
      materialId
      additiveMaterialId
    }
  }
`;

const secondarySelectionModels = ["j5Report"];

const inventoryCheckAliquotContainerFragment = gql`
  fragment inventoryCheckAliquotContainerFragment on aliquotContainer {
    id
    name
    createdAt
    updatedAt
    rowPosition
    columnPosition
    barcode {
      id
      barcodeString
    }
    aliquotContainerType {
      code
      deadVolume
      deadVolumetricUnitCode
    }
    containerArray {
      id
      name
      barcode {
        id
        barcodeString
      }
      updatedAt
      createdAt
    }
  }
`;

const inventoryCheckAliquotContainerWithAdditivesFragment = gql`
  fragment inventoryCheckAliquotContainerWithAdditivesFragment on aliquotContainer {
    ...inventoryCheckAliquotContainerFragment
    additives {
      id
      concentration
      concentrationUnitCode
      mass
      massUnitCode
      volume
      volumetricUnitCode
      additiveMaterialId
    }
  }
  ${inventoryCheckAliquotContainerFragment}
`;

const inventoryCheckAliquotFragment = gql`
  fragment inventoryCheckAliquotFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    sample {
      id
      name
      materialId
      sampleStatusCode
    }
    aliquotContainer {
      ...inventoryCheckAliquotContainerFragment
    }
  }
  ${inventoryCheckAliquotContainerFragment}
`;

function getAliquotOptionsForMaterial(material, hideDry, showInvalidSamples) {
  const options = [];
  material.materialAliquots.forEach(aliquot => {
    const containerArray = get(aliquot, "aliquotContainer.containerArray");
    const tube = get(aliquot, "aliquotContainer");
    if (containerArray || tube) {
      if (!aliquot.isDry && aliquot.volume === 0) {
        return;
      }
      if (hideDry && aliquot.isDry) {
        return;
      }
      if (!showInvalidSamples && aliquot.sample?.sampleStatusCode === "INVALID")
        return;
      options.push(aliquot);
    }
  });
  return options;
}

function getAliquotContainerOptionsReagent(reagent) {
  const options = [];
  reagent.reagentAliquotContainers.forEach(ac => {
    options.push({ aliquotContainer: ac });
  });
  return options;
}

const additionalFilterMap = {
  dataTable: {
    dataTableTypeCode: [
      "VALID_SAMPLE_QC_INVENTORY_LIST",
      "INVALID_SAMPLE_QC_INVENTORY_LIST"
    ]
  }
};

class InventoryCheck extends Component {
  state = {
    loadingInfoFromSelection: false
  };

  componentDidMount() {
    this.loadMaterials();
  }

  componentDidUpdate(oldProps) {
    this.loadMaterials(oldProps);
  }

  formatMaterials = async (materialOrReagentIds, originalInputType) => {
    const {
      stepFormProps: { change },
      targetType
    } = this.props;
    if (targetType === "material") {
      let materials = await safeQuery(["material", "id name"], {
        variables: {
          filter: {
            id: materialOrReagentIds
          }
        }
      });
      if (originalInputType !== this.props.inputType) return;
      // query for aliquots separately to ensure they will be in this lab
      const qb = new QueryBuilder("aliquot");
      qb.whereAll({
        "sample.materialId": materialOrReagentIds
      });
      addActiveProjectFilter(qb, {
        model: "aliquot"
      });
      const aliquots = await safeQuery(inventoryCheckAliquotFragment, {
        variables: {
          filter: qb.toJSON()
        }
      });
      const groupedAliquots = groupBy(aliquots, "sample.materialId");
      materials = materials.map(m => {
        return {
          ...m,
          materialAliquots: groupedAliquots[m.id] || []
        };
      });
      const materialIdToOptions = {};
      materials.forEach(material => {
        materialIdToOptions[material.id] = getAliquotOptionsForMaterial(
          material,
          false,
          false
        );
      });
      if (originalInputType !== this.props.inputType) return;
      change("materialsForInventoryCheck", materials);
      change("materialIdToOptions", materialIdToOptions);
    } else if (targetType === "additiveMaterial") {
      let reagents = await safeQuery(["additiveMaterial", "id name"], {
        variables: {
          filter: {
            id: materialOrReagentIds
          }
        }
      });
      if (originalInputType !== this.props.inputType) return;
      // query for aliquots separately to ensure they will be in this lab
      const qb = new QueryBuilder("aliquotContainer");
      qb.whereAll({
        "additives.additiveMaterialId": materialOrReagentIds
      });
      addActiveProjectFilter(qb, {
        model: "aliquotContainer"
      });
      let aliquotContainers = await safeQuery(
        inventoryCheckAliquotContainerWithAdditivesFragment,
        {
          variables: {
            filter: qb.toJSON()
          }
        }
      );
      aliquotContainers = aliquotContainers.filter(
        a => a.additives.length === 1
      );
      const groupedAliquotContainers = groupBy(
        aliquotContainers,
        "additives[0].additiveMaterialId"
      );
      reagents = reagents.map(m => {
        return {
          ...m,
          reagentAliquotContainers: groupedAliquotContainers[m.id] || []
        };
      });
      const reagentIdToOptions = {};
      reagents.forEach(reagent => {
        reagentIdToOptions[reagent.id] =
          getAliquotContainerOptionsReagent(reagent);
      });
      if (originalInputType !== this.props.inputType) return;
      change("materialsForInventoryCheck", reagents);
      change("materialIdToOptions", reagentIdToOptions);
    }
  };

  getMaterialsToUse() {
    const {
      materialsForInventoryCheck = [],
      [fieldConstants.reactionEntityType]: reactionEntityType,
      materialIdToInputOrOutput
    } = this.props;
    let materialsToUse = materialsForInventoryCheck;
    if (reactionEntityType && materialIdToInputOrOutput) {
      materialsToUse = materialsToUse.filter(mat => {
        const inOrOut = materialIdToInputOrOutput[mat.id];
        return inOrOut === "both" || inOrOut === reactionEntityType;
      });
    }

    return materialsToUse;
  }

  loadMaterials = async (oldProps = {}) => {
    const {
      plateMapGroup,
      targetType,
      reactionMap,
      sample = [],
      material = [],
      stepFormProps: { change },
      j5Materials = [],
      dataTable = [],
      loadedHelpers,
      inputType,
      additiveMaterial
    } = this.props;
    const addMaterialIdsFromSamples = async sampleIds => {
      const fullSamples = await safeQuery(
        [
          "sample",
          /* GraphQL */ `
            {
              id
              material {
                id
                name
              }
              sampleTypeCode
              sampleFormulations {
                id
                materialCompositions {
                  id
                  material {
                    id
                    name
                  }
                }
              }
            }
          `
        ],
        {
          variables: {
            filter: {
              id: sampleIds
            }
          }
        }
      );
      let materialIds = [];
      fullSamples.forEach(s => {
        materialIds = materialIds.concat(
          getSampleMaterialList(s, { returnIds: true })
        );
      });
      if (materialIds.length) {
        await this.formatMaterials(materialIds, inputType);
      }
    };

    const clearMaterials = () => {
      loadedHelpers.clear();
      if (this.props.materialsForInventoryCheck?.length) {
        change("materialsForInventoryCheck", []);
        change("j5Materials", []);
      }
    };
    if (oldProps.inputType && oldProps.inputType !== inputType) {
      clearMaterials();
    }

    if (inputType === "upload") {
      // this will be handled when dropping an upload file
      return;
    }

    const inputValOrVals = this.props[inputType];
    let itemsToCheck = inputValOrVals;
    if (inputType === "j5Report") {
      if (!itemsToCheck?.length) {
        return clearMaterials();
      }
      itemsToCheck = j5Materials;
    }
    const hasVal = Array.isArray(itemsToCheck)
      ? itemsToCheck.length
      : itemsToCheck;
    if (!hasVal) {
      return clearMaterials();
    }

    const wasItemLoaded = () => {
      let idOrIds;
      let wasLoaded = false;
      const key = "loaded" + inputType;
      if (Array.isArray(itemsToCheck)) {
        idOrIds = itemsToCheck.map(i => i.id);
        wasLoaded =
          loadedHelpers[key]?.every(id => idOrIds.includes(id)) &&
          loadedHelpers[key]?.length === idOrIds.length;
      } else {
        idOrIds = itemsToCheck.id;
        wasLoaded = loadedHelpers[key] === idOrIds;
      }
      if (wasLoaded) {
        return true;
      } else {
        loadedHelpers[key] = idOrIds;
        return false;
      }
    };

    if (wasItemLoaded()) return;

    this.setState({
      loadingInfoFromSelection: true
    });
    try {
      if (inputType === "plateMapGroup") {
        const plateMaps = await safeQuery(["plateMap", plateMapFragment], {
          variables: {
            filter: {
              plateMapGroupId: plateMapGroup.id
            }
          }
        });
        const materialIds = [];
        plateMaps.forEach(pm => {
          pm.plateMapItems.forEach(pmi => {
            const materialId =
              targetType === "material"
                ? get(pmi, "inventoryItem.materialId")
                : get(pmi, "inventoryItem.additiveMaterialId");
            if (materialId && !materialIds.includes(materialId)) {
              materialIds.push(materialId);
            }
          });
        });
        if (materialIds.length) {
          await this.formatMaterials(materialIds, inputType);
        }
      } else if (inputType === "reactionMap") {
        const materialIdToInputOrOutput = {};
        const materialIds = [];
        const fullReactions = await safeQuery(
          [
            "reaction",
            /* GraphQL */ `
              {
                id
                name
                reactionInputs {
                  id
                  inputMaterialId
                  inputAdditiveMaterialId
                }
                reactionOutputs {
                  id
                  outputMaterialId
                  outputAdditiveMaterialId
                }
              }
            `
          ],
          {
            variables: {
              filter: { reactionMapId: reactionMap.map(rm => rm.id) }
            }
          }
        );
        fullReactions.forEach(reaction => {
          reaction.reactionInputs.forEach(input => {
            const matId =
              targetType === "material"
                ? input.inputMaterialId
                : input.inputAdditiveMaterialId;
            if (!matId) return;
            if (!materialIdToInputOrOutput[matId]) {
              materialIdToInputOrOutput[matId] = "isInput";
            } else if (materialIdToInputOrOutput[matId] === "isOutput") {
              materialIdToInputOrOutput[matId] = "both";
            }
            !materialIds.includes(matId) && materialIds.push(matId);
          });
          reaction.reactionOutputs.forEach(output => {
            const matId =
              targetType === "material"
                ? output.outputMaterialId
                : output.outputAdditiveMaterialId;
            if (!matId) return;
            if (!materialIdToInputOrOutput[matId]) {
              materialIdToInputOrOutput[matId] = "isOutput";
            } else if (materialIdToInputOrOutput[matId] === "isInput") {
              materialIdToInputOrOutput[matId] = "both";
            }
            !materialIds.includes(matId) && materialIds.push(matId);
          });
        });
        if (materialIds.length) {
          await this.formatMaterials(materialIds, inputType);
        }
        change("materialIdToInputOrOutput", materialIdToInputOrOutput);
      } else if (inputType === "sample") {
        await addMaterialIdsFromSamples(sample.map(s => s.id));
      } else if (inputType === "j5Report" && j5Materials?.length) {
        await this.formatMaterials(
          j5Materials.map(m => m.id),
          inputType
        );
      } else if (inputType === "material") {
        const materialIds = material.map(s => s.id);
        await this.formatMaterials(materialIds, inputType);
      } else if (inputType === "dataTable") {
        const dataTableWithInfo = await safeQuery(
          [
            "dataTable",
            /* GraphQL */ `
              {
                id
                dataRows {
                  id
                  rowValues
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: dataTable.map(s => s.id)
              }
            }
          }
        );
        const sampleIds = [];
        dataTableWithInfo.forEach(dt => {
          dt.dataRows.forEach(dr => {
            if (dr.rowValues?.sampleId) {
              sampleIds.push(dr.rowValues.sampleId);
            }
          });
        });
        await addMaterialIdsFromSamples(sampleIds);
      } else if (inputType === "additiveMaterial") {
        const additiveMaterialIds = additiveMaterial.map(am => am.id);
        await this.formatMaterials(additiveMaterialIds, inputType);
      }
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(
        `Error loading full info from ${lowerCase(inputType)}`
      );
    }
    this.setState({
      loadingInfoFromSelection: false
    });
  };

  automateSampleSelection = (automaticSelectionMethod, maybeOptions) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const { materialIdToOptions = {}, materialIdToSelectedOption = {} } =
      maybeOptions || this.props;
    const newMatToSelected = { ...materialIdToSelectedOption };

    const shouldAutomate = mat => {
      const options = materialIdToOptions[mat.id] || [];
      const selectedAliquot = this.getSelectedAliquotForMaterial(mat);
      const noSelectionOrAutomated =
        !selectedAliquot || selectedAliquot.automated;
      return noSelectionOrAutomated && options.length;
    };

    const materialsForInventoryCheckFiltered = this.getMaterialsToUse();

    if (
      automaticSelectionMethod === "oldest" ||
      automaticSelectionMethod === "newest"
    ) {
      materialsForInventoryCheckFiltered.forEach(mat => {
        const options = materialIdToOptions[mat.id] || [];
        if (shouldAutomate(mat)) {
          const fn = automaticSelectionMethod === "oldest" ? minBy : maxBy;
          const oldestOrNewest = fn(options, o =>
            new Date(
              (
                o.aliquotContainer.containerArray || o.aliquotContainer
              ).createdAt
            ).getTime()
          );
          newMatToSelected[mat.id] = oldestOrNewest && {
            ...oldestOrNewest,
            automated: true
          };
        }
      });
      change("materialIdToSelectedOption", newMatToSelected);
    } else if (automaticSelectionMethod === "lastModified") {
      materialsForInventoryCheckFiltered.forEach(mat => {
        const options = materialIdToOptions[mat.id] || [];
        if (shouldAutomate(mat)) {
          const mostRecent = maxBy(options, o =>
            new Date(
              (
                o.aliquotContainer.containerArray || o.aliquotContainer
              ).updatedAt
            ).getTime()
          );
          newMatToSelected[mat.id] = mostRecent && {
            ...mostRecent,
            automated: true
          };
        }
      });
      change("materialIdToSelectedOption", newMatToSelected);
    } else if (automaticSelectionMethod === "fewestPlates") {
      const plateIdToNumMaterialsMap = {};
      const incrementForPlate = plate => {
        if (!plateIdToNumMaterialsMap[plate.id]) {
          plateIdToNumMaterialsMap[plate.id] = 0;
        }
        plateIdToNumMaterialsMap[plate.id]++;
      };

      materialsForInventoryCheckFiltered.forEach(mat => {
        const options = materialIdToOptions[mat.id] || [];
        if (shouldAutomate(mat)) {
          const seenPlateIds = [];
          options.forEach(o => {
            const plate = o.aliquotContainer.containerArray;
            if (plate && !seenPlateIds.includes(plate.id)) {
              seenPlateIds.push(plate.id);
              incrementForPlate(plate);
            }
          });
        } else {
          // we still want to count the already selected plates so that it will use the fewest plates
          const alreadySelectedAliquot =
            this.getSelectedAliquotForMaterial(mat);
          if (alreadySelectedAliquot) {
            const plate =
              alreadySelectedAliquot.aliquotContainer.containerArray;
            if (plate) {
              incrementForPlate(plate);
            }
          }
        }
      });

      materialsForInventoryCheckFiltered.forEach(mat => {
        const options = materialIdToOptions[mat.id] || [];
        if (shouldAutomate(mat)) {
          const filtered = options.filter(
            op => op.aliquotContainer.containerArray
          );
          const mostUsed = maxBy(
            // sort first incase multiple options have the same amount of uses
            sortBy(filtered, "aliquotContainer.containerArray.id"),
            o => plateIdToNumMaterialsMap[o.aliquotContainer.containerArray?.id]
          );
          newMatToSelected[mat.id] = mostUsed && {
            ...mostUsed,
            automated: true
          };
        }
      });
      change("materialIdToSelectedOption", newMatToSelected);
    }
  };

  clearAutomatedSelection = () => {
    const {
      stepFormProps: { change },
      materialIdToSelectedOption = {}
    } = this.props;
    const newMatToSelected = { ...materialIdToSelectedOption };
    Object.keys(newMatToSelected).forEach(key => {
      if (newMatToSelected[key].automated) {
        delete newMatToSelected[key];
      }
    });
    change("materialIdToSelectedOption", newMatToSelected);
  };

  onChooseAliquotOption = (material, op) => {
    const {
      materialIdToSelectedOption = {},
      stepFormProps: { change }
    } = this.props;

    const newMap = {
      ...materialIdToSelectedOption,
      [material.id]: op
    };

    change("materialIdToSelectedOption", newMap);
  };

  selectAliquotOption = (material, options) => {
    const { targetType } = this.props;
    showDialog({
      ModalComponent: SelectAliquotOptionForInventoryCheck,
      modalProps: {
        options,
        targetType,
        onSelect: op => this.onChooseAliquotOption(material, op)
      }
    });
  };

  removeSelectedOption = material => {
    const {
      materialIdToSelectedOption = {},
      stepFormProps: { change }
    } = this.props;
    const newMap = {
      ...materialIdToSelectedOption
    };
    delete newMap[material.id];
    change("materialIdToSelectedOption", newMap);
  };

  getSelectedAliquotForMaterial = material =>
    getSelectedAliquotForMaterial(material, this.props);

  renderAliquotOptions = (v, material) => {
    const {
      materialIdToOptions = {},
      materialIdToSelectedOption = {},
      targetType
    } = this.props;

    const selectedAliquot = this.getSelectedAliquotForMaterial(material);
    const options = materialIdToOptions[material.id] || [];
    if (!options.length) {
      return "Nothing in inventory.";
    } else if (selectedAliquot) {
      let toReturn;
      if (selectedAliquot.aliquotContainer.containerArray) {
        toReturn = `${
          selectedAliquot.aliquotContainer.containerArray.name
        } (${getAliquotContainerLocation(selectedAliquot.aliquotContainer)})`;
      } else {
        toReturn = `In tube ${
          selectedAliquot.aliquotContainer.barcode?.barcodeString ||
          "Not barcoded tube"
        }`;
      }
      if (materialIdToSelectedOption[material.id]) {
        toReturn = (
          <span>
            <Button
              style={{ marginRight: 5 }}
              minimal
              intent="danger"
              icon="trash"
              onClick={() => this.removeSelectedOption(material)}
            />
            {toReturn}
          </span>
        );
      }
      if (targetType === "material") {
        if (
          !aliquotHasVolumeToTransfer(selectedAliquot) &&
          !selectedAliquot.isDry
        ) {
          toReturn = (
            <div style={{ display: "flex", alignItems: "center" }}>
              <AliquotVolumeLessThanDeadWarning />
              {toReturn}
            </div>
          );
        }
      } else {
        if (!additivesHaveVolumeToTransfer(selectedAliquot.aliquotContainer)) {
          toReturn = (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip content="Additive volume is less than dead volume of container.">
                <Icon
                  style={{ marginRight: 5 }}
                  icon="warning-sign"
                  intent="warning"
                />
              </Tooltip>
              {toReturn}
            </div>
          );
        }
      }
      return toReturn;
    } else {
      return (
        <Button
          intent="primary"
          text={targetType === "material" ? "Choose Aliquot" : "Choose Reagent"}
          onClick={() => this.selectAliquotOption(material, options)}
        />
      );
    }
  };

  handleHideDryAliquots = bool => {
    const {
      stepFormProps: { change },
      materialIdToSelectedOption,
      [fieldConstants.showInvalidSamples]: showInvalidSamples
    } = this.props;
    const newMaterialIdToSelectedOptions = {
      ...materialIdToSelectedOption
    };

    const materialsFiltered = this.getMaterialsToUse();
    const newMaterialIdToOptions = {};
    materialsFiltered.forEach(material => {
      newMaterialIdToOptions[material.id] = getAliquotOptionsForMaterial(
        material,
        bool,
        showInvalidSamples
      );
      if (
        newMaterialIdToSelectedOptions[material.id] &&
        newMaterialIdToSelectedOptions[material.id].isDry
      ) {
        delete newMaterialIdToSelectedOptions[material.id];
      }
    });
    change("materialIdToOptions", newMaterialIdToOptions);
    change("materialIdToSelectedOption", newMaterialIdToSelectedOptions);
    return {
      materialIdToOptions: newMaterialIdToOptions,
      materialIdToSelectedOption: newMaterialIdToSelectedOptions
    };
  };

  showInvalidSamples = bool => {
    const {
      stepFormProps: { change },
      materialIdToSelectedOption,
      [fieldConstants.hideDryAliquots]: hideDryAliquots
    } = this.props;
    const newMaterialIdToSelectedOptions = {
      ...materialIdToSelectedOption
    };
    const newMaterialIdToOptions = {};
    const materialsFiltered = this.getMaterialsToUse();
    materialsFiltered.forEach(material => {
      newMaterialIdToOptions[material.id] = getAliquotOptionsForMaterial(
        material,
        hideDryAliquots,
        bool
      );
      if (
        newMaterialIdToSelectedOptions[material.id] &&
        newMaterialIdToSelectedOptions[material.id].sample?.sampleStatusCode ===
          "INVALID"
      ) {
        delete newMaterialIdToSelectedOptions[material.id];
      }
    });
    change("materialIdToOptions", newMaterialIdToOptions);
    change("materialIdToSelectedOption", materialIdToSelectedOption);
  };

  renderMaterialsTable() {
    const materialsFiltered = this.getMaterialsToUse();
    if (!materialsFiltered.length) return null;
    const aliquotOptionRenderHelper = (path, renderFn) => (v, r) => {
      const aliquotOption = this.getSelectedAliquotForMaterial(r);
      if (aliquotOption) {
        const val = get(aliquotOption, path);
        if (renderFn) {
          return renderFn(val, aliquotOption);
        } else {
          return val;
        }
      }
    };

    const schema = [
      "name",
      {
        displayName: "Sample",
        render: aliquotOptionRenderHelper("sample.name")
      },
      {
        displayName: "Sample Status",
        render: aliquotOptionRenderHelper("sample.sampleStatusCode", v => {
          return v && startCase(v.toLowerCase());
        })
      },
      {
        displayName: "Plate Name",
        path: "plateName",
        render: aliquotOptionRenderHelper(
          "aliquotContainer.containerArray.name"
        )
      },
      {
        displayName: "Plate Barcode",
        path: "plateBarcode",
        render: aliquotOptionRenderHelper(
          "aliquotContainer.containerArray.barcode.barcodeString"
        )
      },
      {
        displayName: "Well Location",
        path: "location",
        width: 100,
        render: aliquotOptionRenderHelper(
          "aliquotContainer",
          getAliquotContainerLocation
        )
      },
      {
        displayName: "Tube Barcode",
        render: aliquotOptionRenderHelper(
          "aliquotContainer.barcode.barcodeString"
        )
      },
      {
        displayName: "Volume",
        width: 80,
        render: aliquotOptionRenderHelper("volume", volumeRender)
      },
      {
        displayName: "Concentration",
        width: 100,
        render: aliquotOptionRenderHelper("concentration", concentrationRender)
      },
      {
        displayName: "Mass",
        width: 80,
        render: aliquotOptionRenderHelper("mass", massRender)
      },
      {
        displayName: "Aliquot",
        width: 120,
        render: this.renderAliquotOptions
      }
    ];

    return (
      <>
        <div>
          <CheckboxField
            label="Hide Dry Aliquots"
            name={fieldConstants.hideDryAliquots}
            onFieldSubmit={this.handleHideDryAliquots}
          />
          <CheckboxField
            label="Show Invalid Samples"
            name={fieldConstants.showInvalidSamples}
            onFieldSubmit={this.showInvalidSamples}
          />
        </div>
        {this.renderSharedTable({
          schema,
          entities: materialsFiltered
        })}
      </>
    );
  }

  renderSharedTable(options) {
    const { toolSchema, materialIdToSelectedOption, materialIdToOptions } =
      this.props;
    return (
      <DataTable
        formName={toolSchema.code}
        showEmptyColumnsByDefault
        materialIdToSelectedOption={materialIdToSelectedOption}
        materialIdToOptions={materialIdToOptions}
        destroyOnUnmount={false}
        noSelect
        withDisplayOptions
        isSimple
        withPaging
        defaults={{ pageSize: 50 }}
        maxHeight={600}
        {...options}
      ></DataTable>
    );
  }

  renderReagentsTable() {
    const reagentsFiltered = this.getMaterialsToUse();
    if (!reagentsFiltered.length) return null;
    const reagentOptionRenderHelper = (path, renderFn) => (v, r) => {
      const aliquotOption = this.getSelectedAliquotForMaterial(r);
      if (aliquotOption) {
        const val = get(aliquotOption, path);
        if (renderFn) {
          return renderFn(val, aliquotOption);
        } else {
          return val;
        }
      }
    };
    const additiveBasePath = "aliquotContainer.additives[0]";

    const schema = [
      "name",
      {
        displayName: "Plate Name",
        path: "plateName",
        render: reagentOptionRenderHelper(
          "aliquotContainer.containerArray.name"
        )
      },
      {
        displayName: "Plate Barcode",
        path: "plateBarcode",
        render: reagentOptionRenderHelper(
          "aliquotContainer.containerArray.barcode.barcodeString"
        )
      },
      {
        displayName: "Well Location",
        path: "location",
        width: 100,
        render: reagentOptionRenderHelper(
          "aliquotContainer",
          getAliquotContainerLocation
        )
      },
      {
        displayName: "Tube Barcode",
        render: reagentOptionRenderHelper(
          "aliquotContainer.barcode.barcodeString"
        )
      },
      {
        displayName: "Volume",
        width: 80,
        render: reagentOptionRenderHelper(
          `${additiveBasePath}.volume`,
          withUnitGeneric(
            `${additiveBasePath}.volume`,
            `${additiveBasePath}.volumetricUnitCode`
          )
        )
      },
      {
        displayName: "Concentration",
        width: 100,
        render: reagentOptionRenderHelper(
          `${additiveBasePath}.concentration`,
          withUnitGeneric(
            `${additiveBasePath}.concentration`,
            `${additiveBasePath}.concentrationUnitCode`
          )
        )
      },
      {
        displayName: "Mass",
        width: 80,
        render: reagentOptionRenderHelper(
          `${additiveBasePath}.mass`,
          withUnitGeneric(
            `${additiveBasePath}.mass`,
            `${additiveBasePath}.massUnitCode`
          )
        )
      },
      {
        displayName: "Reagent",
        width: 150,
        render: this.renderAliquotOptions
      }
    ];

    return this.renderSharedTable({
      schema,
      entities: reagentsFiltered
    });
  }

  getMissingMaterials = () => {
    return this.getMaterialsToUse().filter(
      mat => !this.getSelectedAliquotForMaterial(mat)
    );
  };

  savePlatesAndTubesToForm = async values => {
    const {
      nextStep,
      stepFormProps: { change }
    } = this.props;
    try {
      const updatedPlates = [];
      const updatedTubes = [];
      const plateIds = [];
      const tubeIds = [];
      if (values.inputType === "containerArray") {
        values.containerArray.forEach(c => {
          plateIds.push(c.id);
          updatedPlates.push(c);
        });
      } else {
        const materialsFiltered = this.getMaterialsToUse();
        materialsFiltered.forEach(mat => {
          const option = getSelectedAliquotForMaterial(mat, values);
          const plate = get(option, "aliquotContainer.containerArray");
          const tube = get(option, "aliquotContainer");
          if (plate) {
            if (!plateIds.includes(plate.id)) {
              plateIds.push(plate.id);
              updatedPlates.push(plate);
            }
          } else if (tube && !tubeIds.includes(tube.id)) {
            tubeIds.push(tube.id);
            updatedTubes.push(tube);
          }
        });
      }
      const platePathViews = await safeQuery(
        ["containerArrayPathView", "id fullPath"],
        {
          variables: {
            filter: {
              id: plateIds
            }
          }
        }
      );
      const tubePathViews = await safeQuery(
        ["aliquotContainerPathView", "id fullPath"],
        {
          variables: {
            filter: {
              id: tubeIds
            }
          }
        }
      );
      const idToLocation = {};
      platePathViews.concat(tubePathViews).forEach(p => {
        idToLocation[p.id] = p.fullPath || undefined;
      });

      if (updatedPlates.length || updatedTubes.length) {
        const inventoryCheckTable = {
          dataTableTypeCode: "INVENTORY_CHECK_OUTPUT",
          dataRows: updatedPlates
            .concat(updatedTubes)
            .map((plateOrTube, index) => {
              return {
                rowValues: {
                  name: plateOrTube.name,
                  barcode: get(plateOrTube, "barcode.barcodeString"),
                  location: idToLocation[plateOrTube.id]
                },
                index
              };
            })
        };
        if (values.inputType !== "containerArray") {
          const sampleListDataRows = [];
          const materialsFiltered = this.getMaterialsToUse();
          materialsFiltered.map((mat, index) => {
            const selectedAliquot = this.getSelectedAliquotForMaterial(mat);
            if (selectedAliquot) {
              sampleListDataRows.push({
                index,
                rowValues: {
                  aliquotId: selectedAliquot.id,
                  sampleId: selectedAliquot?.sample?.id,
                  materialName: mat.name,
                  plateName:
                    selectedAliquot.aliquotContainer?.containerArray?.name,
                  plateBarcode:
                    selectedAliquot.aliquotContainer?.containerArray?.barcode
                      ?.barcodeString,
                  well: getAliquotContainerLocation(
                    selectedAliquot.aliquotContainer
                  ),
                  volume: volumeRender(selectedAliquot, undefined, {
                    noJsx: true
                  }),
                  concentration: concentrationRender(
                    selectedAliquot,
                    undefined,
                    { noJsx: true }
                  ),
                  mass: massRender(selectedAliquot, undefined, {
                    noJsx: true
                  })
                }
              });
            }
          });
          const sampleInventoryList = {
            dataTableTypeCode: "SAMPLE_INVENTORY_LIST",
            dataRows: sampleListDataRows
          };
          change("sampleInventoryList", sampleInventoryList);
        }
        change("inventoryCheckTable", inventoryCheckTable);
      }
      change("updatedPlates", updatedPlates);
      change("updatedTubes", updatedTubes);
      nextStep();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading full plate info");
    }
  };

  getTargetTypeReadable = () => {
    const { targetType } = this.props;
    const targetTypeReadableSingle = modelNameToReadableName(targetType);
    const targetTypeReadable = modelNameToReadableName(targetType, {
      plural: true
    });
    const targetTypeReadableCap = modelNameToReadableName(targetType, {
      plural: true,
      upperCase: true
    });
    const targetTypeSingleCap = modelNameToReadableName(targetType, {
      upperCase: true
    });
    return {
      ttSingle: targetTypeReadableSingle,
      ttSingleCap: targetTypeSingleCap,
      ttPlur: targetTypeReadable,
      ttCap: targetTypeReadableCap
    };
  };

  renderHeader() {
    const { inputType } = this.props;
    const { ttCap, ttSingle, ttPlur } = this.getTargetTypeReadable();
    if (inputType === "upload") {
      return (
        <HeaderWithHelper
          header={`Upload ${ttCap}`}
          helper={`Upload a .csv or .zip file of ${ttSingle}
              names. These names will be used to search the
              inventory for matching ${ttPlur}.`}
        />
      );
    } else {
      return (
        <HeaderWithHelper
          header={`Select ${modelNameToReadableName(inputType)
            .split(" ")
            .map(word => capitalize(word))
            .join(" ")}`}
          helper={
            inputType === "material"
              ? `Please select desired ${ttPlur}.`
              : inputType === "additiveMaterial"
                ? `Please select desired ${ttPlur}.`
                : `Please select a ${modelNameToReadableName(
                    inputType
                  )} containing desired ${ttPlur}.`
          }
        />
      );
    }
  }

  renderInputField() {
    const {
      inputType,
      j5Report = [],
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;
    const { ttCap, ttSingle, ttSingleCap } = this.getTargetTypeReadable();
    if (inputType === "upload") {
      return (
        <div style={{ width: "50%" }}>
          <FileUploadField
            accept={getDownloadTemplateFileHelpers({
              fileName: "materialNames",
              validateAgainstSchema: {
                fields: [
                  {
                    path: `${ttSingleCap} Name`,
                    description: `Name of the ${ttSingle}`,
                    example: "Polymerase enzyme"
                  }
                ]
              }
            })}
            beforeUpload={this.beforeUpload}
            fileLimit={1}
            name="materialFile"
            text={`Upload ${ttCap}`}
          />
        </div>
      );
    } else {
      return (
        <div>
          <GenericSelect
            {...{
              name: inputType,
              isMultiSelect: multiSelectModels.includes(inputType),
              schema: ["name", dateModifiedColumn],
              fragment: [inputType, "id name updatedAt"],
              tableParamOptions: {
                additionalFilter: additionalFilterMap[inputType]
              },
              buttonProps: {
                loading:
                  isLoadingMap[inputType] || isLoadingMap[pluralize(inputType)],
                disabled:
                  isDisabledMap[inputType] ||
                  isDisabledMap[pluralize(inputType)]
              }
            }}
          />

          {inputType === "j5Report" && !!j5Report?.length && (
            <div style={{ marginTop: 15 }}>
              <SelectJ5MaterialsOrPcrReactions j5Reports={j5Report} />
            </div>
          )}
        </div>
      );
    }
  }

  beforeUpload = async (fileList, onChange) => {
    const file = fileList[0];
    const { inputType, targetType } = this.props;
    try {
      const { ttPlur, ttSingleCap } = this.getTargetTypeReadable();
      const materialNames = [];

      for (const [, row] of file.parsedData.entries()) {
        const { [`${ttSingleCap} Name`]: materialName } = row;
        if (!materialNames.includes(materialName)) {
          materialNames.push(materialName.trim());
        }
        if (!materialNames.length) {
          throw new Error(`No ${ttPlur} specified in .csv file.`);
        }
      }
      const materials = await safeQuery([targetType, "id name"], {
        variables: {
          filter: caseInsensitiveFilter(targetType, "name", materialNames)
        }
      });

      const materialIds = materials.map(m => m.id);
      if (materialIds.length > 0) {
        await this.formatMaterials(materialIds, inputType);
      } else {
        throw new Error(`No ${ttPlur} found with these names.`);
      }
      const newFile = { ...file, loading: false };
      onChange([newFile]);
    } catch (error) {
      onChange([
        {
          ...file,
          loading: false,
          error: error.message || "Error parsing csv file."
        }
      ]);
      console.error("error:", error);
    }
  };

  handleAutomateSelectionMount = () => {
    // trigger this once on mount
    const {
      [fieldConstants.automateSampleSelectionMethod]: automateMethod,
      [fieldConstants.hideDryAliquots]: hideDryAliquots
    } = this.props;
    let options;
    if (hideDryAliquots) {
      options = this.handleHideDryAliquots(hideDryAliquots);
    }
    if (automateMethod) {
      this.automateSampleSelection(automateMethod, options);
    }
  };

  render() {
    const { loadingInfoFromSelection } = this.state;
    const {
      Footer,
      footerProps,
      materialIdToSelectedOption = {},
      continueWithSelection,
      inputType,
      handleSubmit,
      targetType,
      stepFormProps: { change },
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;

    const { ttPlur } = this.getTargetTypeReadable();
    const materials = this.getMaterialsToUse();

    const hasAutomaticSelection = some(materialIdToSelectedOption, "automated");
    const missingMaterials = this.getMissingMaterials();

    const inputEntity = this.props[inputType];
    const hasInput = Array.isArray(inputEntity)
      ? !!inputEntity?.length
      : !!inputEntity;

    let disableButton = false;

    let inputSelection;
    if (inputType) {
      if (inputType === "containerArray") {
        inputSelection = (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Select Plates"
              helper="These plates will be used to generate the inventory table."
            />
            <GenericSelect
              name={inputType}
              isRequired
              schema={[
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ]}
              isMultiSelect
              fragment={[
                "containerArray",
                "id name barcode { id barcodeString } updatedAt"
              ]}
              additionalDataFragment={containerArrayPlatePreviewFragment}
              postSelectDTProps={{
                formName: "inventoryCheckPlatesTable",
                schema: [
                  platePreviewColumn(),
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ]
              }}
              buttonProps={{
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              }}
            />
          </div>
        );
      } else {
        disableButton =
          !materials.length ||
          (missingMaterials.length && !continueWithSelection);
        inputSelection = (
          <div className="tg-step-form-section column">
            {this.renderHeader()}
            <Loading bounce loading={loadingInfoFromSelection}>
              <div className="tg-flex justify-space-between">
                {this.renderInputField()}
                {!!materials.length && (
                  <AutomateSelectionField
                    {...{
                      onMount: this.handleAutomateSelectionMount,
                      hasAutomaticSelection,
                      clearAutomatedSelection: this.clearAutomatedSelection,
                      automateSelection: this.automateSampleSelection
                    }}
                  />
                )}
              </div>
              {hasInput &&
                !secondarySelectionModels.includes(inputType) &&
                !materials.length && (
                  <BlueprintError
                    error={`No valid ${ttPlur} found on ${modelNameToReadableName(
                      inputType
                    )}.`}
                  />
                )}
              {inputType === "reactionMap" && !!materials.length && (
                <div style={{ maxWidth: 250 }}>
                  <ReactSelectField
                    label="Reaction Entity Type"
                    name={fieldConstants.reactionEntityType}
                    options={reactionEntityTypeOptionsInventoryCheck}
                    onFieldSubmit={() => this.loadMaterials()}
                  />
                </div>
              )}
              {targetType === "material"
                ? this.renderMaterialsTable()
                : this.renderReagentsTable()}
              {!!materials.length &&
              missingMaterials.length === materials.length ? (
                <Callout style={{ marginTop: 15 }} intent="danger">
                  Not all {ttPlur} have inventory selected
                </Callout>
              ) : (
                !!missingMaterials.length && (
                  <div style={{ marginTop: 15 }}>
                    <Callout intent="warning" style={{ marginBottom: 15 }}>
                      These {ttPlur} do not have inventory selected:{" "}
                      {missingMaterials.map(m => m.name).join(", ")}
                    </Callout>
                    <CheckboxField
                      name="continueWithSelection"
                      label="Continue without full selection?"
                    />
                  </div>
                )
              )}
            </Loading>
          </div>
        );
      }
    }
    return (
      <div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Select Input and Target Type"
            helper="Please select an input and target type."
          />
          <div>
            <ReactSelectField
              name="targetType"
              label="Target Type"
              defaultValue="material"
              isRequired
              onFieldSubmit={() => {
                change("inputType", null);
                change(inputType, null);
              }}
              options={[
                ...["material", "additiveMaterial"].map(model => {
                  return {
                    value: model,
                    label: modelNameToReadableName(model, { upperCase: true })
                  };
                })
              ]}
            />
            <ReactSelectField
              name="inputType"
              label="Input Type"
              defaultValue="plateMapGroup"
              options={[
                ...(targetType === "material"
                  ? inputTypeModels
                  : inputTypeModelsForReagents
                ).map(model => {
                  return {
                    value: model,
                    label: modelNameToReadableName(model, { upperCase: true })
                  };
                }),
                ...(targetType
                  ? [
                      {
                        label: `Upload ${modelNameToReadableName(targetType, { upperCase: true, plural: true })}`,
                        value: "upload"
                      }
                    ]
                  : [])
              ]}
            />
          </div>
        </div>
        {inputSelection}
        <Footer
          nextButton={
            <Button
              disabled={disableButton}
              intent="primary"
              onClick={handleSubmit(this.savePlatesAndTubesToForm)}
            >
              Next
            </Button>
          }
          {...footerProps}
        />
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    "inputType",
    "targetType",
    ...inputTypeModels,
    "additiveMaterial",
    "j5Materials",
    "materialFile",
    "materialsForInventoryCheck",
    fieldConstants.reactionEntityType,
    "materialIdToInputOrOutput",
    "materialIdToOptions",
    "materialIdToSelectedOption",
    "continueWithSelection",
    fieldConstants.showInvalidSamples,
    fieldConstants.hideDryAliquots,
    fieldConstants.automateSampleSelectionMethod
  )
)(InventoryCheck);
