/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// TODO: Important! : Start using a src-shared version of StepForm Component!

import React, { Component } from "react";
import { connect } from "react-redux";
import { startCase, get, noop } from "lodash";
import { compose } from "recompose";
import { Prompt } from "react-router-dom";
import { withHandlers, withProps } from "recompose";
import { reduxForm, destroy, getFormValues } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import Helmet from "react-helmet";
import ToolTitle from "../ToolTitle";
import Step from "./Step";
import SuccessPage from "./EvolveSuccessPage";
import StepFormFooter from "./StepFormFooter";
import "./style.css";

class StepForm extends Component {
  state = { onSubmitCalled: false };

  static defaultProps = {
    toolIntegrationProps: {},
    activeStepIndex: 0,
    toolSchema: {},
    afterReset: noop
  };

  warnBeforeLeave = e => {
    const { onSubmitCalled } = this.state;
    const { pristine } = this.props;
    // if we are in a cypress test run then we don't want to block navigation
    if (window.Cypress) {
      return;
    }
    if (pristine || onSubmitCalled) return;
    const confirmationMessage =
      "Are you sure you want to leave? Tool progress will not be saved.";

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.warnBeforeLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.warnBeforeLeave);
    const {
      toolIntegrationProps: { isToolIntegrated }
    } = this.props;
    if (!isToolIntegrated) {
      this.resetStepForm();
    }
  }

  onToolReset = () => {
    this.resetStepForm(true);
    this.props.afterReset();
  };

  resetStepForm = overrideDebuggingMode => {
    // destroy forms when unmounting otherwise the data will persist when
    // the user opens the form again

    const {
      debuggingMode,
      toolSchema: { linkedForms = [] },
      destroy,
      destroyForms
    } = this.props;

    this.setState({
      onSubmitCalled: false
    });
    if (
      process.env.NODE_ENV !== "production" &&
      !overrideDebuggingMode &&
      (debuggingMode || window.location.href.indexOf("?debug") > -1)
    )
      return; //don't destroy anything so that the form state won't reload every time
    destroy();
    linkedForms.length && destroyForms(...linkedForms);
  };

  nextStep = () => {
    this.setState({ onSubmitCalled: false });
    this.props.change("activeStepIndex", this.props.activeStepIndex + 1);
  };

  previousStep = () => {
    this.setState({ onSubmitCalled: false });
    this.props.change(
      "activeStepIndex",
      Math.max(this.props.activeStepIndex - 1, 0)
    );
  };

  /**
   * render the next step button
   * can pass in a custom button element for each step
   */
  nextStepButton = ({ disabled, onClick, submitting, text }) => {
    const { steps, activeStepIndex } = this.props;
    const nextButton = steps[activeStepIndex].nextButton;
    const isLastStep = activeStepIndex === steps.length - 1;
    if (nextButton) {
      if (submitting) {
        return React.cloneElement(nextButton, { loading: true });
      } else {
        return nextButton;
      }
    } else {
      return (
        <Button
          disabled={disabled}
          onClick={onClick}
          type="submit"
          loading={submitting}
          intent={isLastStep ? Intent.SUCCESS : Intent.PRIMARY}
          text={text || (isLastStep ? "Submit" : "Next")}
        />
      );
    }
  };

  previousStepButton = (stepIndex, disabled) => {
    return stepIndex === 0 ? (
      <div />
    ) : (
      <Button
        disabled={disabled}
        onClick={this.previousStep}
        text="Previous"
        minimal
      />
    );
  };

  setActiveStep = i => {
    this.props.change("activeStepIndex", i);
  };

  renderStepNav() {
    const { steps, activeStepIndex, submitting } = this.props;

    return steps.reduce((acc, step, i) => {
      acc.push(
        <Step
          key={i}
          title={step.title}
          complete={activeStepIndex > i}
          active={activeStepIndex === i}
          disabled={activeStepIndex < i || submitting}
          setActive={this.setActiveStep}
          step={i}
        />
      );
      if (i < steps.length - 1)
        acc.push(
          <div key={`step-separator-${i}`} className="step-separator" />
        );
      return acc;
    }, []);
  }

  onSubmit = async data => {
    const dataToUse = { ...data };
    // remove meta values
    delete dataToUse.inProgress;
    delete dataToUse.activeStepIndex;
    const {
      onSubmit,
      toolSchema: { linkedForms = [] },
      toolIntegrationProps: { taskCallback }
    } = this.props;
    const state = window.teGlobalStore.getState();
    const formVals = linkedForms.reduce((acc, name) => {
      acc[name] = getFormValues(name)(state);
      return acc;
    }, {});
    const returnVal = await onSubmit(dataToUse, formVals);
    if (!returnVal) return;
    else if (taskCallback) {
      taskCallback(returnVal);
    } else {
      this.submittedData = returnVal;
      this.setState({ onSubmitCalled: true });
    }
    return returnVal;
  };

  render() {
    const {
      handleSubmit,
      error,
      submitFailed,
      steps,
      getSuccessPageProps,
      submitting,
      form,
      onSubmit,
      toolName,
      toolSchema,
      reset,
      submitSucceeded,
      toolIntegrationProps,
      activeStepIndex: _activeStepIndex,
      finishButton,
      ...rest
    } = this.props;
    const { onSubmitCalled } = this.state;

    const { title: toolTitle } = toolSchema;
    const {
      selectionWarning,
      disabledWarning,
      isToolIntegrated,
      stopTool
    } = toolIntegrationProps;
    let activeStepIndex = _activeStepIndex;
    if (!onSubmit)
      throw new Error(
        "Please pass an onSubmit prop to <StepForm/> like <StepForm onSubmit={onSubmit}/> "
      );

    const isLastStep = activeStepIndex === steps.length - 1;
    if (
      isLastStep &&
      onSubmitCalled &&
      submitSucceeded
      // && getSuccessPageProps
    ) {
      return (
        <SuccessPage
          {...{
            title: toolTitle,
            resetTool: this.onToolReset,
            submittedData: this.submittedData,
            toolSchema: toolSchema.toolSchema,
            finishButton: finishButton
            // ...getSuccessPageProps(this.props)
          }}
        />
      );
    }
    const defaultPreviousButton = this.previousStepButton;
    const defaultNextButton = this.nextStepButton;
    if (_activeStepIndex >= steps.length) {
      console.error("this should not happen");
      console.error(
        "_activeStepIndex, steps.length:",
        _activeStepIndex,
        steps.length
      );
      activeStepIndex = steps.length - 1;
    }
    const step = steps[activeStepIndex];
    /* eslint-disable */
    if (!step) debugger;
    /* eslint-enable */
    const { Component, props = {}, withCustomFooter } = step;

    const onSubmitFunction = isLastStep ? this.onSubmit : this.nextStep;

    const defaultFooterProps = {
      defaultPreviousButton,
      defaultNextButton,
      submitting,
      error,
      submitFailed,
      activeStepIndex
    };

    return (
      <div className="tg-step-form-container">
        <Prompt
          when={!rest.pristine}
          message={location => {
            if (this.routeToNavTo === location.pathname && location.search) {
              return true;
            }
            this.routeToNavTo = location.pathname;
            return "Are you sure you want to leave? Tool progress will not be saved.";
          }}
        />
        {!isToolIntegrated && <Helmet title={startCase(form)} />}
        <div className="tg-step-form-title-and-nav-container">
          <ToolTitle
            title={toolTitle}
            toolSchema={toolSchema}
            embedded={isToolIntegrated}
            stopTool={stopTool}
          />
          <div className="tg-step-form-nav">{this.renderStepNav()}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmitFunction)}>
          <Component
            {...props}
            toolSchema={toolSchema}
            submitting={submitting}
            toolIntegrationProps={toolIntegrationProps}
            isToolIntegrated={toolIntegrationProps.isToolIntegrated}
            nextStep={this.nextStep}
            handleSubmit={handleSubmit}
            onSubmit={onSubmitFunction}
            previousStep={this.previousStep}
            footerProps={defaultFooterProps}
            Footer={StepFormFooter}
            stepFormProps={rest}
          />
          {activeStepIndex === 0 && (
            <React.Fragment>
              {selectionWarning}
              {disabledWarning}
            </React.Fragment>
          )}
          {!withCustomFooter && <StepFormFooter {...defaultFooterProps} />}
        </form>
      </div>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => {
      const toolCode = get(ownProps, "toolSchema.code");
      if (!ownProps.form && toolCode) {
        return {
          form: toolCode
        };
      }
    },
    {
      destroyForms: destroy
    }
  ),
  withHandlers({
    validate: ({ steps, validate: _validate }) => (...args) => {
      let finalErrors = {};
      if (steps) {
        steps.forEach(step => {
          if (step.validate) {
            const errors = step.validate(...args);
            finalErrors = { ...finalErrors, ...errors };
          }
        });
      }
      if (_validate) {
        finalErrors = { ...finalErrors, ..._validate(...args) };
      }
      return finalErrors;
    }
  }),
  // used to check if a tool is partially complete
  withProps(props => ({
    initialValues: { ...props.initialValues, inProgress: true }
  })),
  // withHandlers({
  //   validate: ({ activeStepIndex, steps, validate: _validate }) => {
  //     return (
  //       (steps && steps[activeStepIndex] && steps[activeStepIndex].validate) ||
  //       _validate ||
  //       (() => {})
  //     ); //use the step specific validation if passed
  //   }
  // }),
  reduxForm({
    onSubmitFail: function(errors, dispatch, submitError) {
      console.error("Error hit in stepForms onSubmit:", errors); //this is a little hack so that we can see the stack trace when onsubmit fails
      console.error("submitError:", submitError);
      setTimeout(() => {
        try {
          // const selector = `.tg-step-form-container .${Classes.FORM_GROUP} .${Classes.INTENT_DANGER}`;
          // const erroredEl = document.querySelector(selector);
          // const globalBottomError = document.querySelector(
          //   ".tg-step-form-error"
          // );
          // const stepFormContainer = document.getElementsByClassName(
          //   "tg-step-form-container"
          // )[0];
          // const workflowRunContainer = document.getElementsByClassName(
          //   "workflow-run-body"
          // )[0];
          // const containerEl = workflowRunContainer || stepFormContainer;
          // if (containerEl) {
          //   if (erroredEl) {
          //     scrollIntoView(erroredEl, containerEl, {
          //       alignWithTop: true,
          //       offsetTop: 50 // size of platform header
          //     });
          //   } else if (globalBottomError) {
          //     scrollIntoView(globalBottomError, containerEl, {
          //       alignWithTop: true,
          //       offsetTop: 50 // size of platform header
          //     });
          //   }
          // }
        } catch (error) {
          console.error("error:", error);
        }
      }, 100);
    },
    // shouldError: customShouldError,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    enableReinitialize: true,
    updateUnregisteredFields: true,
    touchOnChange: true,
    touchOnBlur: true
  }),
  tgFormValues("activeStepIndex")
)(StepForm);

// function customShouldError({
//   values,
//   nextProps,
//   props, // not used in default implementation
//   initialRender,
//   lastFieldValidatorKeys,
//   fieldValidatorKeys,
//   structure
// }) {
//   if (initialRender) {
//     return true;
//   }
//   if (
//     props &&
//     nextProps &&
//     nextProps.activeStepIndex !== props.activeStepIndex
//   ) {
//     return true;
//   }
//   return (
//     !structure.deepEqual(values, nextProps && nextProps.values) ||
//     !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
//   );
// }
