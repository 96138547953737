/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import { upperFirst } from "lodash";
import {
  generateFragmentWithFields,
  getModelNameFromFragment
} from "@teselagen/apollo-methods";
import withNotFound from "./withNotFound";
import withQuery from "./withQuery";
import { getGeneralFragmentFields } from "./libraryEnhancer";
import { isCommonLabLocked } from "./utils/labUtils";
import modelNameToReadableName from "./utils/modelNameToReadableName";
import { Helmet } from "react-helmet";
import { combineGqlFragments } from "../../tg-iso-shared/utils/gqlUtils";
import { setActiveRecord } from "./activeRecordStore";

const withRecordHelmet = Component => props => {
  const { record } = props;

  let helmet;
  if (record) {
    const modelType = modelNameToReadableName(record.__typename, {
      upperCase: true
    });
    const name = record.name || `${modelType} ${record.id}`;

    helmet = <Helmet title={`${name} - ${modelType}`} />;
  }

  return (
    <React.Fragment>
      {helmet}
      <Component {...props} />
    </React.Fragment>
  );
};

export function getRecordViewFragment(_fragment, options = {}) {
  let fragment = _fragment;

  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment);
  }
  const model = getModelNameFromFragment(fragment);
  const additionalFragment = getGeneralFragmentFields(model, options);

  return combineGqlFragments([fragment, additionalFragment]);
}

/**
 * recordViewEnhancer
 * @property {gql fragment or array} fragment for query
 * @property {object} withQueryOptions options passed to withQuery
 * @return {enhancer}: enhancer which adds query and withNotFound
 */
const recordViewEnhancer = (_fragment, options = {}) => {
  const { withQueryOptions } = options;
  const fragment = getRecordViewFragment(_fragment, options);
  const model = getModelNameFromFragment(fragment);
  const toCompose = [
    withProps(({ recordIdOverride }) => {
      return {
        isInsideSidePanel: !!recordIdOverride,
        getIdFromParams: !recordIdOverride,
        inDialog: !!recordIdOverride, //make the loader act as if in a dialog
        model
      };
    }),
    withQuery(fragment, {
      showLoading: true,
      ...withQueryOptions
    }),
    withNotFound({ path: model, loadingPath: `loading${upperFirst(model)}` }),
    withProps(props => {
      const record = props[model];
      const readOnly =
        props.readOnly || isCommonLabLocked(record) || record.lockId;
      if (record?.name) {
        setActiveRecord({
          name: record.name
        });
      }
      return {
        record,
        fragment,
        refetchRecord: props.data.refetch,
        readOnly
      };
    }),
    withRecordHelmet
  ];

  return compose(...toCompose);
};

export default recordViewEnhancer;
