/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { CHAR_WIDTH } from "./constants";
import { cartesianProductOf } from "../../../../tg-iso-design/utils/combinatorialUtils";

// Just an estimate for now
export const getTextLength = text => CHAR_WIDTH * text.length;

export const getRealSchematicSets = schematic =>
  schematic.schematicSets.filter(s => s.type !== "divider");

export const generateAllCombinations = schematic => {
  const schematicSets = getRealSchematicSets(schematic);
  return cartesianProductOf(
    schematicSets.map(s =>
      s.schematicElements.length ? s.schematicElements : [s]
    )
  ).map((comb, i) => ({
    index: i,
    backbone: schematic.backboneSet,
    elements: comb.map((item, j) => {
      const set = schematicSets[j];
      const isSet = item.__typename === "schematicSet";
      return {
        name: isSet ? item.name : `${set.name} - ${item.name}`,
        setId: set.id,
        elementId: isSet ? null : item.id
      };
    })
  }));
};
