/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  Loading,
  ReactSelectField,
  CheckboxField,
  DialogFooter,
  wrapDialog,
  NumericInputField,
  InputField
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { map } from "lodash";
import { download } from "../../../../src-shared/utils/downloadTest";
import withQuery from "../../../../src-shared/withQuery";
import { worklistFormats } from "../../../../../tg-iso-lims/src/utils/worklistUtils";
import formatWorklistsForExport from "../../../../../tg-iso-lims/src/utils/formatWorklistsForExport";
import { handleZipFiles } from "../../../../../tg-iso-shared/src/utils/fileUtils";

const formatsWithoutBarcodeAsPlateName = [
  "Echo",
  "FlowBot",
  "QPix",
  "TeselaGen",
  "Mantis"
];

const helperMessages = {
  TeselaGen: "This format can be used for exporting and re-importing worklists."
};

class ExportWorklistDialog extends Component {
  onSubmitWorklist = async values => {
    const { worklistIds = [], hideModal, integrations } = this.props;
    const { format, barcodeAsPlateName, pipette, tipType } = values;

    const sourceIdToSlotPosMap = {};
    const destinationIdToSlotPosMap = {};

    Object.keys(values).forEach(val => {
      if (val.startsWith("sourceSlotPos_")) {
        sourceIdToSlotPosMap[val.replace("sourceSlotPos_", "")] = values[val];
      } else if (val.startsWith("destSlotPos_")) {
        destinationIdToSlotPosMap[val.replace("destSlotPos_", "")] =
          values[val];
      }
    });

    const options = { pipette, tipType };

    try {
      if (!worklistIds.length) return hideModal();
      try {
        let results = [];
        if (worklistFormats.includes(format)) {
          // exporting in a default worklist format
          results = await formatWorklistsForExport({
            worklistIds,
            format,
            barcodeAsPlateName,
            sourceIdToSlotPosMap,
            destinationIdToSlotPosMap,
            options
          });
        } else {
          // exporting in a custom worklist format
          options.customFormat = true;
          const endpoint = integrations.filter(
            integration => integration.name === format
          );
          if (endpoint > 1) {
            const error = `More than one integration endpoint found for the ${format} worklist format. Please check the endpoints at 'Settings > Integrations > Export Custom Worklist'`;
            console.error("error:", error);
            window.toastr.error(error);
          }
          const endpointId = endpoint[0]?.integrationEndpoints[0].id;
          if (!endpointId) {
            const error = `No integration endpoint found for the ${format} worklist format. Please check the endpoints at 'Settings > Integrations > Export Custom Worklist'`;
            console.error("error:", error);
            window.toastr.error(error);
          }
          const integrationData = await formatWorklistsForExport({
            worklistIds,
            format,
            barcodeAsPlateName,
            sourceIdToSlotPosMap,
            destinationIdToSlotPosMap,
            options
          });
          const endpointResults = await window.triggerIntegrationRequest({
            endpointId,
            data: integrationData,
            method: "POST"
          });
          map(endpointResults.data, worklist => {
            results.push(worklist);
          });
        }

        if (results.length === 1) {
          download(results[0].contents, results[0].filename, "text/plain");
        } else {
          const zipFile = await handleZipFiles(
            results.map(res => ({
              name: res.filename,
              data: res.contents
            }))
          );
          download(zipFile, "Worklists.zip");
        }
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error exporting worklists.");
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error exporting worklist");
    }
  };

  // onSubmitGenerator = format => () => this.onSubmitWorklist(format);

  render() {
    const {
      worklist = {},
      worklistIds = [],
      worklists = [],
      hideModal,
      submitting,
      handleSubmit,
      format,
      integrations
    } = this.props;
    let defaultLiquidHandlerBrand;
    if (window.localStorage.getItem("defaultLiquidHandlerBrand")) {
      defaultLiquidHandlerBrand = window.localStorage.getItem(
        "defaultLiquidHandlerBrand"
      );
    }

    const sourceIdToNameMap = {};
    const destinationIdToNameMap = {};
    if (worklists) {
      worklists.forEach(worklist =>
        worklist.worklistTransfers.forEach(transfer => {
          const {
            sourceAliquotContainer,
            destinationAliquotContainer
          } = transfer;
          const sourceName = sourceAliquotContainer?.containerArray?.name;
          const sourceId = sourceAliquotContainer?.containerArray?.id;
          if (!sourceIdToNameMap[sourceId]) {
            sourceIdToNameMap[sourceId] = sourceName;
          }
          const destinationName =
            destinationAliquotContainer?.containerArray?.name;
          const destinationId = destinationAliquotContainer?.containerArray?.id;
          if (!destinationIdToNameMap[destinationId]) {
            destinationIdToNameMap[destinationId] = destinationName;
          }
        })
      );
    }

    const sourceFlowBotInputs = Object.keys(sourceIdToNameMap).map(
      (sourceId, i) => {
        const sourceName = sourceIdToNameMap[sourceId];
        return (
          <div className="flowBotSourceInput" key={`flowBotSourceInput_${i}`}>
            <NumericInputField
              name={`sourceSlotPos_${sourceId}`}
              label={`Source slot position of '${sourceName}'`}
              defaultValue={1}
              min={1}
              key={`sourceSlotPos_${i}`}
            />
          </div>
        );
      }
    );
    const destinationFlowBotInputs = Object.keys(destinationIdToNameMap).map(
      (destinationId, i) => {
        const destinationName = destinationIdToNameMap[destinationId];
        return (
          <div className="flowBotDestInput" key={`flowBotDestInput_${i}`}>
            <NumericInputField
              name={`destSlotPos_${destinationId}`}
              label={`Destination slot position of '${destinationName}'`}
              defaultValue={1}
              min={1}
              key={`destSlotPos_${i}`}
            />
          </div>
        );
      }
    );

    const additionalFlowBotInputs = sourceFlowBotInputs.concat(
      destinationFlowBotInputs
    );

    const pipetteOption = (
      <div className="pipetteInput" key="pipetteInput">
        <InputField
          name="pipette"
          label="Pipette (optional)"
          placeholder=""
          key="pipette"
          tooltipInfo={
            <div>
              Specifies the name of the pipette to be used. Must match the
              pipette tip type (if defined) and the volume of the transfer. Left
              blank by default, in which case the FlowBot will choose
              appropriate pipettes and tips.
            </div>
          }
        />
      </div>
    );
    const tipTypeOption = (
      <div className="tipTypeInput" key="tipTypeInput">
        <InputField
          name="tipType"
          label="Pipette Tip Type (optional)"
          placeholder=""
          key="tipType"
          tooltipInfo={
            <div>
              Specifies the name of the type of tip to be used. Must match the
              pipette (if defined) and the volume of the transfer. Left blank by
              default, in which case the FlowBot will choose appropriate
              pipettes and tips.
            </div>
          }
        />
      </div>
    );

    const otherOptions = [pipetteOption, tipTypeOption];
    additionalFlowBotInputs.push(...otherOptions);

    const customWorklistFormatNames = integrations?.map(i => i.name);

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <Loading loading={submitting} inDialog>
            <h6 style={{ marginBottom: 15 }}>
              Choose a liquid handler format for export of{" "}
              {worklistIds.length
                ? `${worklistIds.length} worklists`
                : `${worklist.name}`}
            </h6>
            <div className="worklist-select">
              <ReactSelectField
                name="format"
                options={
                  integrations
                    ? [...worklistFormats, ...customWorklistFormatNames]
                    : worklistFormats
                }
                defaultValue={defaultLiquidHandlerBrand || worklistFormats[0]}
              />
              {helperMessages[format] && (
                <div style={{ margin: "10px 0" }}>{helperMessages[format]}</div>
              )}
              {!formatsWithoutBarcodeAsPlateName.includes(format) &&
                !customWorklistFormatNames.includes(format) && (
                  <CheckboxField
                    name="barcodeAsPlateName"
                    label="Use Plate Barcode as Plate Name in Worklist"
                  />
                )}
              {format === "FlowBot" && additionalFlowBotInputs}
            </div>
          </Loading>
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmitWorklist)}
        />
      </div>
    );
  }
}

const worklistSourceDestFrag = /* GraphQL */ `
  {
    id
    name
    worklistTransfers {
      id
      volume
      volumetricUnitCode
      sourceAliquotContainer {
        id
        containerArray {
          id
          name
        }
      }
      destinationAliquotContainer {
        id
        containerArray {
          id
          name
        }
      }
    }
  }
`;

export default compose(
  wrapDialog({ title: "Export Worklist" }),
  reduxForm({
    form: "exportWorklist"
  }),
  tgFormValues("format"),
  withQuery(["worklist", worklistSourceDestFrag], {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          filter: {
            id: props.worklistIds || []
          }
        }
      };
    }
  }),
  withQuery(["integration", `id name integrationEndpoints { id }`], {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: () => {
      return {
        variables: {
          filter: {
            integrationTypeCode: "EXPORT_CUSTOM_WORKLIST"
          }
        }
      };
    }
  })
)(ExportWorklistDialog);
