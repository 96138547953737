/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { reduce, flatten, map } from "lodash";

/**
 * @template T
 * Get the cartesian product of a list of lists
 * @param {Array<Array<T>>} items - The list of lists
 * @returns {Array<Array<T>>} The cartesian product of the list of lists
 */
export const cartesianProductOf = items => {
  return reduce(
    items,
    function (a, b) {
      return flatten(
        map(a, function (x) {
          return map(b, function (y) {
            return x.concat([y]);
          });
        }),
        true
      );
    },
    [[]]
  );
};
