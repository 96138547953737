/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const terminalSiteFragment = gql`
  fragment terminalSiteFragment on site {
    id
    name
  }
`;

const locationToSiteFragment = gql`
  fragment locationToSiteFragment on location {
    id
    name
    path
    site {
      ...terminalSiteFragment
    }
  }
  ${terminalSiteFragment}
`;

const equipmentToSiteFragment = gql`
  fragment equipmentToSiteFragment on equipmentItem {
    id
    name
    label
    location {
      ...locationToSiteFragment
    }
  }
  ${locationToSiteFragment}
`;

const equipmentPositionToSiteFragment = gql`
  fragment equipmentPositionToSiteFragment on equipmentPosition {
    id
    name
    label
    equipment {
      ...equipmentToSiteFragment
    }
  }
  ${equipmentToSiteFragment}
`;

const containerToSiteFragment = gql`
  fragment containerToSiteFragment on container {
    id
    name
    label
    path
    location {
      ...locationToSiteFragment
    }
    equipment {
      ...equipmentToSiteFragment
    }
    equipmentPosition {
      ...equipmentPositionToSiteFragment
    }
  }
  ${equipmentToSiteFragment}
  ${locationToSiteFragment}
  ${equipmentPositionToSiteFragment}
`;

const containerPositionToSiteFragment = gql`
  fragment containerPositionToSiteFragment on containerPosition {
    id
    name
    label
    index
    container {
      ...containerToSiteFragment
    }
  }
  ${containerToSiteFragment}
`;

const assignedPositionToSiteFragment = gql`
  fragment assignedPositionToSiteFragment on assignedPosition {
    id
    location {
      ...locationToSiteFragment
    }
    equipment {
      ...equipmentToSiteFragment
    }
    equipmentPosition {
      ...equipmentPositionToSiteFragment
    }
    container {
      ...containerToSiteFragment
    }
    containerPosition {
      ...containerPositionToSiteFragment
    }
  }
  ${locationToSiteFragment}
  ${equipmentToSiteFragment}
  ${equipmentPositionToSiteFragment}
  ${containerToSiteFragment}
  ${containerPositionToSiteFragment}
`;

const placementLocationToSiteFragment = gql`
  fragment placementLocationToSiteFragment on placementLocation {
    id
    location {
      ...locationToSiteFragment
    }
    equipment {
      ...equipmentToSiteFragment
    }
    equipmentPosition {
      ...equipmentPositionToSiteFragment
    }
    container {
      ...containerToSiteFragment
    }
    containerPosition {
      ...containerPositionToSiteFragment
    }
  }
  ${locationToSiteFragment}
  ${equipmentToSiteFragment}
  ${equipmentPositionToSiteFragment}
  ${containerToSiteFragment}
  ${containerPositionToSiteFragment}
`;

export const typeToFragmentMap = {
  location: locationToSiteFragment,
  equipment: equipmentToSiteFragment,
  equipmentItem: equipmentToSiteFragment,
  equipmentPosition: equipmentPositionToSiteFragment,
  container: containerToSiteFragment,
  containerPosition: containerPositionToSiteFragment,
  assignedPosition: assignedPositionToSiteFragment,
  placementLocation: placementLocationToSiteFragment
};
export const typeToPathResolutionFragment = {
  location: ["location", "id name"],
  container: ["container", "id name label"],
  containerPosition: ["containerPosition", "id name label index"]
};
