/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";

function Footer({
  nextButton,
  previousButton,
  disabled,
  nextDisabled,
  onNextClick,
  previousDisabled,
  defaultPreviousButton,
  defaultNextButton,
  additionalButtons,
  submitting,
  error,
  submitFailed,
  activeStepIndex
}) {
  return (
    <div className="width100 tg-step-form-footer">
      <div className="tg-step-form-buttons">
        {previousButton ||
          defaultPreviousButton(activeStepIndex, previousDisabled)}
        <div className="tg-flex">
          {additionalButtons}
          {nextButton ||
            defaultNextButton({
              activeStepIndex,
              disabled: nextDisabled || disabled,
              onClick: onNextClick,
              submitting
            })}
        </div>
      </div>
      {submitFailed && error && (
        <div className={classNames(Classes.FORM_GROUP, Classes.INTENT_DANGER)}>
          <div
            className={classNames(
              Classes.FORM_HELPER_TEXT,
              "tg-step-form-error preserve-newline"
            )}
          >
            {error}
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
