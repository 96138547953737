/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import { Classes, Button } from "@blueprintjs/core";
import dayjs from "dayjs";
import {
  InputField,
  DateInputField,
  TextareaField,
  DialogFooter,
  CheckboxField,
  InfoHelper,
  wrapDialog
} from "@teselagen/ui";
import "./style.css";
import GenericSelect from "../../../../src-shared/GenericSelect";
import withQuery from "../../../../src-shared/withQuery";
import { get } from "lodash";
import UserSelect from "../../../../src-shared/UserSelect";
import { createWorkflowRun } from "../../../../../tg-iso-lims/src/utils/workflowUtils";
import { onSubmitCreate as createNewJob } from "../LaunchNewJobDialog/submit";

/* eslint-disable no-debugger */

const LaunchNewWorkflowDialogInner = ({
  hideModal,
  history,
  jobId: _maybeJobId,
  refetch,
  currentUser,
  job,
  submitting,
  handleSubmit,
  isAdHoc,
  includeInactive,
  createJob,
  jobId
}) => {
  const onSubmit = async (formData, shouldSave) => {
    try {
      let jobId = _maybeJobId;
      const { createJob, jobName, ...workflowValues } = formData;
      if (!jobId && createJob) {
        const newJob = await createNewJob(
          {
            name: jobName,
            priorityTypeCode: "NORMAL",
            jobOwner: currentUser
          },
          { returnJob: true }
        );
        if (!newJob) return;
        jobId = newJob.id;
      }
      const newWorkflowRun = await createWorkflowRun({
        ...workflowValues,
        jobId,
        workflowDefinitionId: get(formData, "workflowDefinition.id")
      });
      if (shouldSave && refetch) {
        await refetch();
      } else {
        history.push("/workflow-runs/" + newWorkflowRun.id);
      }
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating workflow run");
    }
  };

  const onLaunchClick = values => onSubmit(values, false);
  const onSaveClick = values => onSubmit(values, true);

  let additionalButtons;
  // let users save instead of launch workflow if in job record view
  if (job) {
    additionalButtons = [
      <Button
        key="save"
        text="Save"
        loading={submitting}
        intent="success"
        onClick={handleSubmit(onSaveClick)}
      />
    ];
  }

  let newJobFields;
  if (!jobId) {
    newJobFields = (
      <>
        <CheckboxField
          name="createJob"
          defaultValue
          label={
            <span>
              Create Job{" "}
              <InfoHelper
                isInline
                content="A job will be created for this workflow. Team members can be assigned to tasks."
              />
            </span>
          }
        />
        {createJob && <InputField isRequired name="jobName" label="Job Name" />}
      </>
    );
  }
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          label="Name"
          name="name"
          isRequired
          defaultValue="Untitled Workflow Run"
          placeholder="My workflow"
        />
        {newJobFields}
        <CheckboxField
          name="isAdHoc"
          label={
            <span>
              Ad Hoc{" "}
              <InfoHelper
                isInline
                content="Ad hoc workflows are built in place without a workflow definition"
              />
            </span>
          }
        />
        {!isAdHoc && (
          <>
            <CheckboxField
              name="includeInactive"
              label={
                <span>
                  Include Unpublished Workflows{" "}
                  <InfoHelper
                    isInline
                    content="This will allow you to select workflow drafts"
                  />
                </span>
              }
            />
            <GenericSelect
              asReactSelect
              fragment={["workflowDefinition", "id name"]}
              isRequired
              label="Workflow"
              name="workflowDefinition" //the field name within the redux form Field
              schema={[{ path: "name" }]}
              tableParamOptions={{
                additionalFilter: {
                  isAdHoc: false,
                  isDeprecated: false,
                  ...(!includeInactive && { isActive: true })
                }
              }}
            />
          </>
        )}
        <GenericSelect
          asReactSelect
          defaultValue={{
            code: "NORMAL",
            name: "Normal"
          }}
          fragment={["priorityType", "code name"]}
          idAs="code"
          label="Priority"
          name="priorityType"
          schema={["name"]}
        />
        <DateInputField
          defaultValue={new Date()}
          label="Due Date"
          name="dueDate"
          minDate={new Date()}
          maxDate={dayjs().add(3, "years").toDate()}
        />
        <TextareaField name="notes" label="Notes" rows={8} />
        {!!job && (
          <UserSelect
            name="manager"
            label="Workflow Manager"
            defaultValue={job.jobOwner}
            isRequired
          />
        )}
      </div>
      <DialogFooter
        hideModal={hideModal}
        loading={submitting}
        additionalButtons={additionalButtons}
        onClick={handleSubmit(onLaunchClick)}
        text="Launch Workflow"
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "New Workflow Run"
  }),
  reduxForm({
    form: "launchNewWorkflowRunForm"
  }),
  tgFormValues("isAdHoc", "createJob", "includeInactive"),
  withQuery(["job", `id jobOwner { id username }`], {
    skip: ({ jobId }) => !jobId,
    showLoading: true,
    inDialog: true,
    options: ({ jobId }) => ({
      variables: {
        id: jobId
      }
    })
  })
)(LaunchNewWorkflowDialogInner);
