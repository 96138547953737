/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useMemo } from "react";
import { compose } from "redux";
import { DataTable } from "@teselagen/ui";
import { getReactionMapSchema } from "../../../../utils/reactionMapUtils";
import stepFormValues from "../../../../../src-shared/stepFormValues";

function ReviewReactionMap(props) {
  const {
    reactions = [],
    reactionMapName,
    reactionPrefix = "reaction"
  } = props;

  const { schema, cleanedReactions } = useMemo(() => {
    const cleanedReactions = reactions.map((r, i) => {
      const {
        inputMaterials = [],
        consumedAdditiveMaterials = [],
        conservedAdditiveMaterials = [],
        outputMaterials = [],
        outputAdditiveMaterials = []
      } = r;
      return {
        name: r.name || reactionPrefix + " " + (i + 1),
        reactionInputs: inputMaterials
          .map(m => ({ inputMaterial: m }))
          .concat(
            consumedAdditiveMaterials.map(r => ({ inputAdditiveMaterial: r }))
          )
          .concat(
            conservedAdditiveMaterials.map(r => ({
              inputAdditiveMaterial: r,
              conserved: true
            }))
          ),
        reactionOutputs: outputMaterials
          .map(m => ({ outputMaterial: m }))
          .concat(
            outputAdditiveMaterials.map(r => ({
              outputAdditiveMaterial: r
            }))
          )
      };
    });
    const schema = getReactionMapSchema(cleanedReactions);
    return { schema, cleanedReactions };
  }, [reactions, reactionPrefix]);
  return (
    <div>
      <div className="tg-step-form-section column">
        <h6>Review Reaction Map {reactionMapName}</h6>
        <DataTable
          formName="reactionMapReviewDataTable"
          schema={schema}
          destroyOnUnmount={false}
          entities={cleanedReactions}
          isSimple
          noSelect
        />
        {schema.hasConserved && <div>(*Not consumed during reaction)</div>}
      </div>
    </div>
  );
}

export default compose(
  stepFormValues(
    "reactionMapName",
    "reactionMapType",
    "reactions",
    "reactionPrefix"
  )
)(ReviewReactionMap);
