/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { noop, startCase } from "lodash";
import { generateField } from "@teselagen/ui";
import { InputGroup, ButtonGroup, Button, Classes } from "@blueprintjs/core";

class MustacheTemplateField extends Component {
  handleTemplateCursor = e => {
    e.persist && e.persist();
    // Defer this to get the updated (post-event) position
    setTimeout(() => {
      this.cursorPos = e.target.selectionStart;
    }, 0);
  };

  handleTemplateButtonClick = variable => () => {
    const {
      input: { name, value, onChange },
      onFieldSubmit = noop
    } = this.props;

    const oldValue = value || "";
    if (!this.cursorPos) {
      this.cursorPos = oldValue.length;
    }
    const newValue =
      oldValue.substr(0, this.cursorPos) +
      `{{{${variable}}}}` +
      oldValue.substr(this.cursorPos);
    onChange(newValue);
    // Refocus and move cursor on input box
    const newPos = this.cursorPos + variable.length + 6;

    onFieldSubmit(newValue);
    const el = ReactDOM.findDOMNode(this).querySelector(`[name="${name}"]`);
    el.focus();
    setTimeout(() => {
      el.selectionStart = el.selectionEnd = this.cursorPos = newPos;
    }, 10);
  };

  onChange = (e, ...rest) => {
    const { input, submitOnChange, onFieldSubmit = noop } = this.props;
    input.onChange(e, ...rest);
    if (submitOnChange) {
      onFieldSubmit(e.target.value);
    }
  };

  render() {
    const {
      input,
      onFieldSubmit = noop,
      templateVariables = [],
      InputComponent = InputGroup,
      intent,
      inline,
      rows
    } = this.props;

    const style = {};
    if (inline) {
      style.display = "flex";
      style.position = "relative";
    }
    return (
      <div style={style}>
        <div
          style={{
            width: inline ? "70%" : undefined
          }}
        >
          <InputComponent
            {...input}
            onChange={this.onChange}
            rows={rows}
            className={Classes.FILL}
            intent={intent}
            onFocus={this.handleTemplateCursor}
            onClick={this.handleTemplateCursor}
            onKeyDown={this.handleTemplateCursor}
            onBlur={function(e, val) {
              input.onBlur(e, val);
              onFieldSubmit(e.target ? e.target.value : val, { blur: true }, e);
            }}
          />
        </div>
        <div
          style={{
            marginTop: inline ? 0 : 12,
            marginLeft: inline ? 20 : 0,
            width: inline ? "25%" : undefined,
            height: "100%"
          }}
        >
          <div
            style={{
              marginBottom: 5
            }}
          >
            Available Template Variables
          </div>
          <ButtonGroup style={{ marginBottom: 15 }} vertical={inline}>
            {templateVariables.map(templateVariable => {
              return (
                <Button
                  key={templateVariable}
                  text={startCase(templateVariable)}
                  style={{
                    textAlign: "center"
                  }}
                  onClick={this.handleTemplateButtonClick(templateVariable)}
                />
              );
            })}
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default generateField(MustacheTemplateField);
