/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import DesignElementCardContextMenu from "../components/HierarchicalDesign/DesignElementCard/DesignElementCardContextMenu";
import {
  getSelectedCardId,
  getSelectedBinIds,
  getSelectedBins,
  getSelectedCellPaths,
  getSelectedElement,
  getBinOfFirstSelectedCell,
  getSelectedBin,
  getBinOfSelectedElement
} from "../../src-shared/selectors/designViewSelectors";
import {
  isDesignTemplate,
  getItemOfType,
  isCardRoot,
  getDsfForCard,
  getIdOfParentCard,
  doesCardHaveReaction,
  isElementMapped,
  getInputReactionIdOfCard,
  isCardCircular,
  isLeafCard,
  isCardBackboneInputToReaction,
  isLayoutList,
  doesDesignHavePartsets,
  getLevelOfCard,
  getInputReactionOfCard,
  getBinsInCard,
  getAssemblyMethodOfCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../src-shared/redux/actions";
import { isReactionErdam } from "../../src-shared/selectors/erdamSelectors";
import { isBinBoundaryOfInsert } from "../../../tg-iso-design/selectors/junctionSelectors";
import {
  getInputCardById,
  isClassicDesign
} from "../../src-shared/selectors/classicViewSelectors";
import {
  getNeighborArfPartId,
  getFirstNeighborPartId
} from "../utils/stateUtils";

const mapStateToProps = state => {
  const selectedCardId = getSelectedCardId(state);
  if (!selectedCardId) return {};

  const globalViewOptionsRaw = state.ui.designEditor.general.viewOptions;
  const localViewOptionsRaw =
    state.ui.designEditor.tree.cardViewOptions[selectedCardId] || {};

  const trueValue = fieldName =>
    (localViewOptionsRaw[fieldName]
      ? localViewOptionsRaw
      : globalViewOptionsRaw)[fieldName];

  const selectedCellPaths = getSelectedCellPaths(state);
  const selectedCellsWithElements = selectedCellPaths.filter(p => p.elementId);
  const selectedElement = getSelectedElement(state);
  const isSelectedElementMapped =
    selectedElement && isElementMapped(state, selectedElement.id);
  const selectedElementIsInvalid =
    !!selectedElement && !!selectedElement.invalidityMessage;
  // const isSelectedElementInvalidOnlyDueToOverhangs =
  //   selectedElementIsInvalid &&
  //   isElementInvalidOnlyDueToOverhangs(state, selectedElement.id)

  const parentReactionId = getInputReactionIdOfCard(state, selectedCardId);
  const isParentOperationErdam =
    parentReactionId && isReactionErdam(state, parentReactionId);

  const selectedBins = getSelectedBins(state);

  const unlockedSelectedBins = selectedBins.map(s => !s.isLocked);
  const selectedBinIds = getSelectedBinIds(state);
  const selectedInputCards =
    Object.keys(state.design.card).length === 1
      ? null
      : selectedBinIds.map(setId => getInputCardById(state, setId));

  // ARF stuff, so we can query for these parts and their partSeqContextView
  const isClassic = isClassicDesign(state).success; // ARF only works in Classic-compatible designs for now

  let previousArfPartId, nextArfPartId, firstPreviousPartId, firstNextPartId;
  if (isClassic) {
    previousArfPartId = getNeighborArfPartId(
      state,
      selectedBinIds[0],
      selectedCardId,
      true
    );
    nextArfPartId = getNeighborArfPartId(
      state,
      selectedBinIds[0],
      selectedCardId,
      false
    );

    firstPreviousPartId = previousArfPartId
      ? null
      : getFirstNeighborPartId(state, selectedBinIds[0], selectedCardId, true);
    firstNextPartId = nextArfPartId
      ? null
      : getFirstNeighborPartId(state, selectedBinIds[0], selectedCardId, false);
  }
  // end ARF stuff

  const assemblyMethodOfCard = getAssemblyMethodOfCard(state, selectedCardId);

  const injections = {
    state,
    bins: getBinsInCard(state, selectedCardId),
    j5Runs: state.j5.j5Runs,
    setExpansion: state.ui.designEditor.tree.cardSetExpansions[selectedCardId],
    globalViewOptionsRaw,
    localViewOptionsRaw,
    shouldIncludeInjecteds: trueValue("operationInjectedElements") === "view",
    areListElementsExpanded: trueValue("listElements") === "expanded",
    areCompositeSetsCondensed: trueValue("compositeSets") === "condensed",
    //
    previousArfPartId,
    nextArfPartId,
    firstPreviousPartId,
    firstNextPartId,
    //
    selectedCardId,
    selectedBinIds,
    selectedCellPaths,
    isDesignTemplate: isDesignTemplate(state),
    selectedCard: getItemOfType(state, "card", selectedCardId),
    isSelectedCardRoot: isCardRoot(state, selectedCardId),
    isSelectedCardCircular: isCardCircular(state, selectedCardId),
    isSelectedCardLeaf: isLeafCard(state, selectedCardId),
    selectedCardDsf: getDsfForCard(state, selectedCardId),
    selectedCardHasReaction:
      selectedCardId && doesCardHaveReaction(state, selectedCardId),
    assemblyMethodOfInputReaction:
      selectedCardId &&
      getInputReactionOfCard(state, selectedCardId) &&
      getItemOfType(
        state,
        "assemblyMethod",
        getInputReactionOfCard(state, selectedCardId).assemblyMethodId
      ),
    selectedInputCards,
    selectedCardIsBackboneInput:
      selectedCardId && isCardBackboneInputToReaction(state, selectedCardId),
    selectedCellsWithElements,
    selectedElement,
    binOfSelectedElement: getBinOfSelectedElement(state),
    isSelectedElementMapped,
    selectedElementIsInvalid,
    // isSelectedElementInvalidOnlyDueToOverhangs,
    selectedBin: getSelectedBin(state),
    // v This does not necessarily come from the cell of the selected element. v
    binOfFirstSelectedCell: getBinOfFirstSelectedCell(state),
    parentReactionId,
    isParentOperationErdam,
    // areSelectedSetsContiguousInCard: areSelectedSetsContiguous(state),
    selectedBins,
    unlockedSelectedBins,
    level: getLevelOfCard(state, selectedCardId),
    isListLayout: isLayoutList(state),
    hasPartsets: doesDesignHavePartsets(state),
    assemblyMethodOfCard
  };

  const selectedCardParentId = getIdOfParentCard(state, selectedCardId);
  if (selectedCardParentId) {
    Object.assign(injections, {
      selectedCardParentId
    });
  }

  if (
    selectedCardId &&
    selectedBinIds.length &&
    !isCardRoot(state, selectedCardId)
  ) {
    Object.assign(injections, {
      isFivePrimeBinBoundary: isBinBoundaryOfInsert(
        state,
        selectedCardId,
        selectedBinIds[0],
        true
      ),
      isThreePrimeBinBoundary: isBinBoundaryOfInsert(
        state,
        selectedCardId,
        selectedBinIds[selectedBinIds.length - 1],
        false
      )
    });
  }

  return injections;
};

const mapDispatchToProps = {
  setDsf: actions.design.setDsf,
  toggleValidationEnd: actions.design.toggleValidationEnd,
  insertBin: actions.design.insertBin,
  removeBins: actions.design.removeBins,
  updateBin: actions.design.updateBin,
  changeFas: actions.design.changeFas,
  removeElements: actions.design.removeElements,
  createElements: actions.design.createElements,
  mapElementToPart: actions.design.mapElementToPart,
  internalizeOverhangOnPart: actions.design.internalizeOverhangOnPart,
  internalizeOverhangOnFlankingSequence:
    actions.design.internalizeOverhangOnFlankingSequence,
  internalizeRecognitionSiteOnPart:
    actions.design.internalizeRecognitionSiteOnPart,
  internalizeRecognitionSiteOnFlankingSequence:
    actions.design.internalizeRecognitionSiteOnFlankingSequence,
  makeErdamCircular: actions.design.makeErdamCircular,
  makeErdamLinear: actions.design.makeErdamLinear,
  lockBins: actions.design.lockBins,
  convertBinsToPlaceholders: actions.design.convertBinsToPlaceholders,
  removeCardFromDesign: actions.design.removeCardFromDesign,
  makeCardTheRoot: actions.design.makeCardTheRoot,
  updateElement: actions.design.updateElement,
  removeElementEugeneRules: actions.design.removeElementEugeneRules,
  insertRow: actions.design.insertRow,
  removeRows: actions.design.removeRows,
  eliminateCombinations: actions.design.eliminateCombinations,
  applyRuleSet: actions.design.applyRuleSet,
  alphabetizeParts: actions.design.alphabetizeParts
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DesignElementCardContextMenu
);
