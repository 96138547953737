/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const exportSynthonsJ5ReportFragment = gql`
  fragment exportSynthonsJ5ReportFragment on j5DirectSynthesis {
    id
    name
    sequence {
      id
      name
      size
      sequenceFragments {
        id
        index
        fragment
      }
    }
  }
`;
