/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types } from "mobx-state-tree";

export const withPropsStore = types.model("withPropsStore", {}).extend(() => {
  let props;
  return {
    views: {
      // note this one is NOT a getter (NOT a computed value)
      // if this were a getter this value would get stale upon observation
      get() {
        return props;
      }
    },
    actions: {
      set(nextProps) {
        props = nextProps;
      }
    }
  };
});
