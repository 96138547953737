/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { DataTable } from "@teselagen/ui";
import { Intent, Button } from "@blueprintjs/core";
import { get } from "lodash";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import PlateMapView from "../../../../components/PlateMapView";
import { renderLocation } from "../../../../utils/plateUtils";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import { getMaterialPlasmidSequence } from "../../../../utils";

class ReviewDestinationPlates extends Component {
  state = {
    selectedPlate: null
  };

  togglePlatePreview = records => {
    const { selectedPlate: oldPlate } = this.state;
    let selectedPlate = records[0];
    if (oldPlate === selectedPlate) selectedPlate = null;
    this.setState({ selectedPlate });
  };

  renderPlateList(tableEntities) {
    return (
      <DataTable
        schema={[
          {
            displayName: "Name",
            type: "string",
            path: "name"
          },
          {
            displayName: "Plate Type",
            type: "string",
            path: "containerArrayType.name"
          }
        ]}
        isSimple
        entities={tableEntities}
        isViewable
        formName="destinationPlates"
        keepDirtyOnReinitialize
        isSingleSelect
        onRowSelect={this.togglePlatePreview}
      />
    );
  }

  renderTubeList(tableEntities) {
    return (
      <React.Fragment>
        <DataTable
          entities={tableEntities}
          isSimple
          compact
          formName="destinationTubes"
          schema={[
            { displayName: "Name", path: "name" },
            { displayName: "Tube Type", path: "aliquotContainerType.name" },
            {
              displayName: "Plasmids",
              render: (v, r) => {
                return r.microbialMaterialPlasmids
                  .map(mmp => getMaterialPlasmidSequence(mmp).name)
                  .join(", ");
              }
            }
          ]}
        />
      </React.Fragment>
    );
  }
  a;
  renderReviewHeader() {
    const { outputPlates } = this.props;
    let header, helper;
    if (outputPlates === "PLATES") {
      header = `Review Destination Plates`;
      helper = `Review the plates to be created.`;
    } else {
      header = `Review Destination Tubes`;
      helper = `Review the tubes to be created.`;
    }
    return <HeaderWithHelper header={header} helper={helper} />;
  }

  renderPlatePreview(selectedPlate) {
    return (
      <div className="tg-step-form-section column">
        <h6>{selectedPlate.name}</h6>
        <PlateMapView
          containerArrayType={selectedPlate.containerArrayType}
          containerArray={selectedPlate}
          tableSchema={[
            { displayName: "Location", render: renderLocation },
            {
              displayName: "Plasmid",
              render: (v, r) => {
                return get(
                  r,
                  "aliquot.sample.material.microbialMaterialMicrobialMaterialPlasmids"
                )
                  .map(mmp => getMaterialPlasmidSequence(mmp).name)
                  .join(", ");
              }
            },
            { displayName: "Volume", render: volumeRender }
          ]}
          noEntityTransform
          noPadding
        />
      </div>
    );
  }

  render() {
    const {
      tableEntities = [],
      outputPlates,
      Footer,
      footerProps
    } = this.props;
    const { selectedPlate } = this.state;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          {this.renderReviewHeader()}
          {outputPlates === "PLATES"
            ? this.renderPlateList(tableEntities)
            : this.renderTubeList(tableEntities)}
          {outputPlates === "PLATES" &&
            selectedPlate &&
            this.renderPlatePreview(selectedPlate)}
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              loading={footerProps.submitting}
            >
              Submit
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "tableEntities",
  "outputPlates"
)(ReviewDestinationPlates);
