/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import getUnit from "./getUnit";
import unitGlobals from "../../unitGlobals";

export const standardizeGeneric = baseKey => (value, unitOrUnitCode, big) => {
  let unit = unitOrUnitCode;

  if (typeof unitOrUnitCode === "string") {
    const units = unitGlobals.getUnitsForBaseKey(baseKey);
    unit = getUnit(units, unitOrUnitCode);
  }
  /* eslint-disable */
  if (!unit) {
    debugger;
  }
  /* eslint-enable */
  if (big) {
    return new Big(value).times(new Big(unit[baseKey]));
  }
  return value * unit[baseKey];
};

export const standardizeVolume = standardizeGeneric("liters");
export const standardizeConcentration = standardizeGeneric("gramsPerLiter");
export const standardizeCellConcentration = standardizeGeneric("cellsPerLiter");
export const standardizeMolarity = standardizeGeneric("molesPerLiter");
export const standardizeMass = standardizeGeneric("grams");
