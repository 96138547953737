/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isBrowser } from "browser-or-node";
import { extname, basename } from "path";
import { sequenceFileTypes } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import { bottle } from "../dependencyContainer";

export async function showDuplicateInputSequencesConfirmation(
  duplicatesOfInputSequences
) {
  if (isBrowser) {
    const continueUpload = await bottle.container.showDuplicateInputSequencesConfirmation(
      duplicatesOfInputSequences
    );
    return continueUpload;
  } else {
    return true;
  }
}

export function getSequenceFileMatchIndex(sequenceFilename, allFilenames) {
  const sequenceIndex = allFilenames.findIndex(filename => {
    if (
      sequenceFilename.includes(".") &&
      sequenceFileTypes.includes(sequenceFilename.split(".").pop())
    ) {
      return basename(filename) === basename(sequenceFilename);
    } else {
      return (
        basename(filename).replace(extname(filename), "") === sequenceFilename
      );
    }
  });
  return sequenceIndex;
}

export function stripTwistAdapters(sequenceString) {
  const twist5PrimeAdapter = "GAAGTGCCATTCCGCCTGACCT";
  const twist3PrimeAdapter = "AGGCTAGGTGGAGGCTCAGTG";
  let updatedSequenceString = sequenceString;
  if (
    sequenceString.toUpperCase().startsWith(twist5PrimeAdapter) &&
    sequenceString.toUpperCase().endsWith(twist3PrimeAdapter)
  ) {
    updatedSequenceString = sequenceString.substring(
      twist5PrimeAdapter.length,
      sequenceString.length - twist3PrimeAdapter.length
    );
  }
  return updatedSequenceString;
}
