/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import basicHandleActionsWithFullState from "./basicHandleActionsWithFullState";
import actions from "../actions";

const initialState = {};

export default basicHandleActionsWithFullState(
  {
    [actions.massivelyParallelPartCreation.addData]: (
      state,
      { payload: { id, data } }
    ) => ({ ...state, [id]: data })
  },
  initialState
);
