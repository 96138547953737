/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import {
  DialogFooter,
  withTableParams,
  DataTable,
  withSelectedEntities,
  wrapDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import gql from "graphql-tag";
import withQuery from "../../../../src-shared/withQuery";
import { get } from "lodash";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class MoveEquipmentDialog extends React.Component {
  state = {
    loading: false
  };

  onSubmit = async location => {
    try {
      const {
        equipmentItem,
        selectedLocations = [],
        refetch,
        hideModal
      } = this.props;
      this.setState({ loading: true });
      await safeUpsert("equipmentItem", {
        id: equipmentItem.id,
        locationId: location ? location.id : selectedLocations[0].id
      });

      await refetch();
      hideModal();
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
      window.toastr.error("Error moving container.");
    }
  };

  render() {
    const { loading } = this.state;
    const {
      equipmentItem,
      hideModal,
      tableParams,
      selectedLocations = []
    } = this.props;

    const currentLocation = get(equipmentItem, "equipmentPathView.fullPath");

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {currentLocation && (
            <div style={{ marginBottom: 10 }}>
              Current Location: {currentLocation}
            </div>
          )}
          <DataTable
            {...tableParams}
            onDoubleClick={r => this.onSubmit(r)}
            isSingleSelect
            noPadding
          />
        </div>
        <DialogFooter
          {...{
            hideModal,
            loading,
            disabled: !selectedLocations.length,
            onClick: () => this.onSubmit()
          }}
        />
      </React.Fragment>
    );
  }
}

const schema = {
  model: "locationPathView",
  fields: [
    {
      displayName: "Location",
      path: "fullPath",
      width: 400
    },
    {
      displayName: "Site",
      path: "site.name"
    }
  ]
};

const fragment = gql`
  fragment moveEquipmentLocationFragment on locationPathView {
    id
    name
    site {
      id
      name
    }
    fullPath
    updatedAt
  }
`;

export default compose(
  wrapDialog({ title: "Update Location", style: { width: 600 } }),
  reduxForm({
    form: "moveEquipmentDialog"
  }),
  withTableParams({
    urlConnected: false,
    formName: "moveEquipmentLocationsTable",
    schema,
    defaults: {
      order: ["location"]
    }
  }),
  withQuery(fragment, {
    isPlural: true
  }),
  withSelectedEntities({
    formName: "moveEquipmentLocationsTable",
    name: "selectedLocations"
  })
)(MoveEquipmentDialog);
