/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem, Tooltip } from "@blueprintjs/core";
import { showDialog } from "../../../src-shared/GlobalDialog";

function MoveToEquipmentMenuItem({ tooltip, records, refetch, disabled }) {
  const containerArrayTypes = [],
    aliquotContainerTypes = [],
    plateIds = [],
    tubeIds = [];
  const addedTypeIds = {};
  records.forEach(r => {
    if (r.__typename === "containerArray") {
      plateIds.push(r.id);
      if (r.containerArrayType && !addedTypeIds[r.containerArrayType.id]) {
        addedTypeIds[r.containerArrayType.id] = true;
        containerArrayTypes.push(r.containerArrayType);
      }
    } else {
      tubeIds.push(r.id);
      if (r.aliquotContainerType && !addedTypeIds[r.aliquotContainerType.id]) {
        addedTypeIds[r.aliquotContainerType.id] = true;
        aliquotContainerTypes.push(r.aliquotContainerType);
      }
    }
  });

  const menuItem = (
    <MenuItem
      icon="geolocation"
      text="Move"
      disabled={disabled}
      onClick={() => {
        showDialog({
          modalType: "ASSIGN_PLATE_PLACEMENT_STRATEGY",
          modalProps: {
            plateIds,
            tubeIds,
            refetch,
            containerArrayTypes: containerArrayTypes,
            aliquotContainerTypes: aliquotContainerTypes
          }
        });
      }}
    />
  );

  if (tooltip) {
    return (
      <Tooltip className="full-width-tooltip" content={tooltip}>
        {menuItem}
      </Tooltip>
    );
  } else {
    return menuItem;
  }
}

export default MoveToEquipmentMenuItem;
