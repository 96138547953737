/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { get, pick } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import {
  SelectInputs,
  ConfigureDestinationPlates,
  ReviewDestinationPlates
} from "./Steps";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { plasmidPrepPlateFragment, plasmidPrepTubeFragment } from "./fragments";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { getMaterialPlasmid } from "../../../utils";

class PlasmidPrep extends Component {
  onSubmit = async values => {
    const {
      choosingPlates,
      outputPlates,
      containerArrayType,
      aliquotContainerType,
      tableEntities = [],
      containerArrays = [],
      aliquotContainers = [],
      destinationBaseName,
      generateBarcodes
    } = values;
    try {
      const materialLineageUpdates = [];
      const destinationPlates = [];
      let destinationTubes;
      if (outputPlates === "PLATES") {
        tableEntities.forEach((plate, i) => {
          const formattedAliquotContainers = this.formatAliquotContainers({
            aliquotContainers: plate.aliquotContainers,
            materialLineageUpdates,
            values
          });
          destinationPlates.push({
            name: `${destinationBaseName} ${i + 1}`,
            containerArrayTypeId: containerArrayType.id,
            aliquotContainers: generateContainerArray(
              formattedAliquotContainers,
              containerArrayType.containerFormat,
              {
                aliquotContainerTypeCode: containerArrayType.isPlate
                  ? containerArrayType.aliquotContainerTypeCode
                  : aliquotContainerType.code
              }
            )
          });
        });
      } else {
        destinationTubes = this.formatAliquotContainers({
          aliquotContainers: tableEntities,
          materialLineageUpdates,
          values
        });
      }
      // need to update existing plates and tubes with aliquot volumes of 0
      const sourceAliquotsToUpdate = [];
      if (choosingPlates === "PLATES") {
        // input plates
        containerArrays.forEach(containerArray => {
          containerArray.aliquotContainers.forEach(ac => {
            if (ac.aliquot) {
              sourceAliquotsToUpdate.push({
                id: ac.aliquot.id,
                volume: 0
              });
            }
          });
        });
      } else {
        // input tubes
        aliquotContainers.forEach(ac => {
          if (ac.aliquot) {
            sourceAliquotsToUpdate.push({
              id: ac.aliquot.id,
              volume: 0
            });
          }
        });
      }
      await safeUpsert("aliquot", sourceAliquotsToUpdate);
      const createdPlates = await safeUpsert(
        "containerArray",
        destinationPlates
      );
      const createdTubes = await safeUpsert(
        "aliquotContainer",
        destinationTubes
      );
      await safeUpsert("materialLineage", materialLineageUpdates);
      if (generateBarcodes) {
        if (choosingPlates === "PLATES") {
          await addBarcodesToRecords(createdPlates);
        } else {
          await addBarcodesToRecords(createdTubes);
        }
      }
      if (createdPlates.length > 0) {
        window.toastr.success("Successfully created plates");
      } else {
        window.toastr.success("Successfully created tubes");
      }
      return {
        containerArrays: createdPlates,
        aliquotContainers: createdTubes
      };
    } catch (error) {
      window.toastr.error("Error prepping plasmids.");
      console.error(error);
    }
  };

  formatAliquotContainers = ({
    aliquotContainers,
    materialLineageUpdates,
    values
  }) => {
    const {
      containerArrayType,
      aliquotContainerType,
      outputPlates,
      destinationBaseName,
      reagent,
      lot,
      elutionVolume,
      elutionVolumetricUnitCode,
      onlyWithMaterials
    } = values;
    const formattedAliquotContainers = [];
    let multiplePlasmids;
    let sampleFields;

    const additiveFields = {
      additiveMaterialId: reagent.id,
      volume: elutionVolume,
      volumetricUnitCode: elutionVolumetricUnitCode
    };

    if (lot) {
      additiveFields.lotId = lot.id;
    }

    if (onlyWithMaterials) {
      aliquotContainers = aliquotContainers.filter(ac => ac.aliquot);
    }
    // These are modified aliquot containers that have had the aliquot's microbialPlasmid information added
    aliquotContainers.forEach((ac, i) => {
      const parentMaterialId = get(ac, "aliquot.sample.material.id");
      let aliquotFields;
      const aliquotHasNoVolume = get(ac, "aliquot.volume") <= 0;
      multiplePlasmids =
        ac.aliquot && get(ac, "microbialMaterialPlasmids").length > 1;

      const dnaMaterial = getMaterialPlasmid(
        get(ac, "microbialMaterialPlasmids[0]")
      );
      if (ac.aliquot) {
        let materialId;
        const materialIdToMaterialName = {};
        if (multiplePlasmids) {
          // if the microbe has more than one plasmid, iterate through each to grab the material ids / create materials if they do not already exist
          const materialIds = [];
          if (!aliquotHasNoVolume) {
            ac.microbialMaterialPlasmids.forEach(microbialMaterialPlasmid => {
              const mat = microbialMaterialPlasmid.polynucleotideMaterial;
              materialId = mat.id;

              // if the plasmid is linked to a material push into materialId array
              materialIds.push(materialId);
              // also will need to update the map below to access material name when generating formulated sample name
              materialIdToMaterialName[materialId] = mat.name;
              // if the plasmid is not linked to a material...
            });
            sampleFields = {
              name: materialIds
                .map(id => materialIdToMaterialName[id])
                .join(" - "),
              sampleTypeCode: "FORMULATED_SAMPLE",
              sampleStatusCode: "UNVALIDATED",
              sampleFormulations: materialIds.map(id => ({
                aliquotId: ac.aliquot.id,
                materialCompositions: [{ materialId: id }]
              }))
            };
          }
        } else {
          const materialId = get(
            ac,
            "microbialMaterialPlasmids[0].polynucleotideMaterialId"
          );
          // because the plasmid material already exists, we're just linking the new aliquot to the old material, we still need to update that existing material with the lineage info
          if (!aliquotHasNoVolume) {
            // materialId on plasmid
            materialLineageUpdates.push({
              parentMaterialId,
              childMaterialId: materialId
            });

            sampleFields = {
              name: dnaMaterial.name,
              sampleTypeCode: "REGISTERED_SAMPLE",
              sampleStatusCode: "UNVALIDATED",
              materialId,
              sampleFormulations: [parentMaterialId].map(id => ({
                aliquotId: ac.aliquot.id,
                materialCompositions: [{ materialId: id }]
              }))
            };
          }
        }
        aliquotFields = {
          volume: ac.volume,
          volumetricUnitCode: ac.volumetricUnitCode,
          aliquotType: "sample-aliquot",
          sample: sampleFields,
          additives: [additiveFields]
        };
      }
      formattedAliquotContainers.push({
        ...pick(ac, ["rowPosition", "columnPosition"]),
        name:
          outputPlates === "PLATES" ? null : `${destinationBaseName} ${i + 1}`,
        aliquotContainerTypeCode:
          outputPlates === "PLATES"
            ? outputPlates === "PLATES" && containerArrayType.isPlate === false
              ? aliquotContainerType.code
              : containerArrayType.aliquotContainerTypeCode
            : aliquotContainerType.code,
        ...(ac.aliquot && !aliquotHasNoVolume
          ? {
              aliquot: aliquotFields
            }
          : {
              additives: [additiveFields]
            })
      });
    });
    return formattedAliquotContainers;
  };

  validate = values => {
    const {
      containerArrays = [],
      aliquotContainers = [],
      elutionVolume,
      elutionVolumetricUnitCode,
      choosingPlates,
      outputPlates,
      containerArrayType,
      aliquotContainerType
    } = values;

    const errors = {};
    if (containerArrays.length > 0) {
      const formatCodes = containerArrays.map(containerArray =>
        get(containerArray, "containerArrayType.containerFormatCode")
      );
      const uniformFormats = formatCodes.every(
        formatCode => formatCode === formatCodes[0]
      );
      if (!uniformFormats) {
        errors.containerArrays = "Input plates must share the same format.";
      }
      // array of plates that have microbial materials with no plasmids
      const platesWithMissingPlasmids = [];
      containerArrays.forEach(containerArray => {
        const missingPlasmids = containerArray.aliquotContainers.some(ac => {
          if (ac.aliquot) {
            return (
              get(
                ac,
                "aliquot.sample.material.microbialMaterialMicrobialMaterialPlasmids.length"
              ) < 1
            );
          } else {
            return false;
          }
        });
        if (missingPlasmids) {
          platesWithMissingPlasmids.push(containerArray.name);
        }
      });
      if (!errors.containerArrays && platesWithMissingPlasmids.length > 0) {
        errors.containerArrays = `The following plates have microbial materials with no plasmids: ${platesWithMissingPlasmids.join(
          ", "
        )}.`;
      }
    }
    if (aliquotContainers.length > 0) {
      const nonMicrobialTubes = [];
      const noPlasmidMicrobes = [];
      const dryTubes = [];
      aliquotContainers.forEach(aliquotContainer => {
        if (
          get(aliquotContainer, "aliquot.sample.material.materialTypeCode") !==
          "MICROBIAL"
        ) {
          nonMicrobialTubes.push(
            aliquotContainer.name ||
              get(aliquotContainer, "barcode.barcodeString")
          );
        }
        if (
          get(
            aliquotContainer,
            "aliquot.sample.material.microbialMaterialMicrobialMaterialPlasmids.length"
          ) < 1
        ) {
          noPlasmidMicrobes.push(
            aliquotContainer.name ||
              get(aliquotContainer, "barcode.barcodeString")
          );
        }
        if (get(aliquotContainer, "aliquot.isDry") === true) {
          dryTubes.push(aliquotContainer.name);
        }
      });
      if (nonMicrobialTubes.length > 0) {
        errors.aliquotContainers =
          `The following tubes have non microbial materials: ` +
          nonMicrobialTubes.join(", ") +
          `.\n\n`;
      } else if (noPlasmidMicrobes.length > 0) {
        errors.aliquotContainers =
          `The following tubes have microbial materials with no plasmids: ` +
          noPlasmidMicrobes.join(", ") +
          `.\n\n`;
      }
      if (dryTubes.length > 0) {
        errors.aliquotContainers =
          `The following tubes are dry: ` + dryTubes.join(", ") + `.\n\n`;
      }
    }
    if (choosingPlates === "PLATES" && containerArrays.length < 1) {
      errors.containerArrays = "Must select at least one plate or tube rack.";
    }
    if (!choosingPlates === "PLATES" && aliquotContainers.length < 1) {
      errors.aliquotContainers = "Must select at least one tube.";
    }
    // tgreen: typing e in elution volume would crash in the standardizeVolume function
    if (!isValidPositiveNumber(elutionVolume)) {
      errors.elutionVolume = "Please enter a valid number.";
    } else if (elutionVolume && (containerArrayType || aliquotContainerType)) {
      let aliquotContainerTypeToUse = aliquotContainerType;
      if (
        outputPlates === "PLATES" &&
        containerArrayType &&
        containerArrayType.isPlate
      ) {
        aliquotContainerTypeToUse = containerArrayType.aliquotContainerType;
      } else {
        aliquotContainerTypeToUse = aliquotContainerType;
      }

      if (aliquotContainerTypeToUse) {
        if (
          standardizeVolume(elutionVolume, elutionVolumetricUnitCode, true).gt(
            standardizeVolume(
              aliquotContainerTypeToUse.maxVolume,
              aliquotContainerTypeToUse.volumetricUnitCode,
              true
            )
          )
        ) {
          errors.elutionVolume =
            "Elution volume must be lower than max well volume of selected plate or tube type.";
        }
      }
    }
    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select Inputs",
        Component: SelectInputs,
        withCustomFooter: true
      },
      {
        title: "Configure Destination Plates",
        Component: ConfigureDestinationPlates,
        withCustomFooter: true
      },
      {
        title: "Review Destination Plates",
        Component: ReviewDestinationPlates,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plasmidPrepPlateFragment),
  withWorkflowInputs(plasmidPrepTubeFragment)
)(PlasmidPrep);
