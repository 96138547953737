/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import ReactHtmlParser from "react-html-parser";
import React from "react";

import { useTgQuery } from "../apolloMethods";
import ReactLinkify from "react-linkify";

export default function CustomHelpDocWidget({ code }) {
  if (!code) {
    throw new Error("No code provided to CustomHelpDocWidget!");
  }

  const { customHelpDoc, ...rest } = useTgQuery(
    ["customHelpDoc", `code markdown`],
    {
      idAs: "code",
      fetchPolicy: "cache-first",
      variables: { code }
    }
  );
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  return (
    <CustomHelpDocWidgetInner {...customHelpDoc}></CustomHelpDocWidgetInner>
  );
}

export function CustomHelpDocWidgetInner({ markdown, noMargin } = {}) {
  if (!markdown || !markdown.replace(/\s/g, "")) {
    return null;
  }
  return (
    <div
      className="tg-custom-help-text"
      style={{
        marginTop: noMargin ? 0 : 10,
        marginBottom: noMargin ? 0 : 10,
        maxHeight: 250,
        overflowY: "auto"
      }}
    >
      <ReactLinkify>{ReactHtmlParser(markdown)}</ReactLinkify>
    </div>
  );
}
