/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniq, startsWith } from "lodash";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";
import {
  getSequenceStringsOfElement,
  isLayoutList,
  getRightJunctionBpsForElement,
  getNeighborBinCardByReactionIdAndElementId,
  getItemOfType,
  isDisabledSameBinDigestValidation,
  getBinCardByReactionIdAndElementId,
  getPartsInPartset
} from "../../selectors/designStateSelectors";
import { ChangeSetsHelper } from ".";

/**
 * Returns an array of element ids that are in the 3' neighbor reaction input
 *  and have DIGEST FAS applied to them
 *
 */
function getNeighborDigestFasEls(state, reactionId, elementId) {
  const designState = state.design;

  // 1.  get index of element
  const { index: elementIndex } = getItemOfType(state, "element", elementId);

  // 2.  get the element's next binCard in the reaction
  const neighborBinCard = getNeighborBinCardByReactionIdAndElementId(
    state,
    reactionId,
    elementId,
    true // 3Prime (right neighbor)
  );

  if (!neighborBinCard) return [];
  const nextBinId = neighborBinCard.binId;

  // 3.  get the elements in next bin
  // If its a list layout, we only care about the element in the same position ('elementIndex'),
  // otherwise we care about all elements in the bin since its combinatorial.
  const nextBinElIds = [];
  if (isLayoutList(state)) {
    for (const element of Object.values(designState.element)) {
      if (element.binId === nextBinId && element.index === elementIndex) {
        nextBinElIds.push(element.id);
      }
    }
  } else {
    for (const element of Object.values(designState.element)) {
      if (element.binId === nextBinId) {
        nextBinElIds.push(element.id);
      }
    }
  }
  if (!nextBinElIds.length) return [];

  // 4.  filter out any elements that don't have DIGEST FAS
  const nextBinDigestElIds = nextBinElIds.filter(elId => {
    let hasDigestFas = false;
    for (const fas of Object.values(designState.fas)) {
      if (fas.elementId === elId && fas.name === "DIGEST") {
        hasDigestFas = true;
      }
    }
    return hasDigestFas;
  });

  return nextBinDigestElIds;
}

/**
 * Returns an array of element ids that are in the same bin as the passed-in element
 *  and have DIGEST FAS applied to them
 *
 *  Only need to do this validation once, so will only run if element is the first DIGEST FAS element in the bin
 */
function getSameBinDigestElIds(state, reactionId, elementId, fasElementMap) {
  const designState = state.design;

  let binId;
  let elementIndex;
  for (const element of Object.values(designState.element)) {
    if (element.id === elementId) {
      binId = element.binId;
      elementIndex = element.index;
    }
  }

  const sameBinDigestElIds = [];
  for (const element of Object.values(designState.element)) {
    if (
      element.binId === binId &&
      element.id !== elementId &&
      fasElementMap[element.id]
    ) {
      if (element.index < elementIndex) {
        return [];
      }
      sameBinDigestElIds.push(element.id);
    }
  }

  return sameBinDigestElIds;
}

function validateDigestPart({ parts: _parts, fas }) {
  const parts = Array.isArray(_parts) ? _parts : [_parts];

  // Loop through the element's parts (usually just one, but can be more if its a partset)
  // and return the first error message.
  for (const part of parts) {
    if (fas.restrictionEnzymeId) {
      if (part?.re5PrimeId && part.re5PrimeId !== fas.restrictionEnzymeId) {
        return `DIGEST 5' restriction enzyme for part '${part.name}' is incompatible with selected for the assembly`;
      }
      if (part?.re3PrimeId && part.re3PrimeId !== fas.restrictionEnzymeId) {
        return `DIGEST 3' restriction enzyme for part '${part.name}' is incompatible with selected for the assembly`;
      }
    }
    if (!part.isDigestPart) {
      return `DIGEST '${part.name}' is not a digest part. Make sure you created the part using the Create Part From Digest Tool.`;
    }
    if (!part.isDigestValid) {
      const invalidPrimeEnd =
        part.re5PrimeOverhang === null
          ? "5' end"
          : part.re3PrimeOverhang === null
          ? "3' end"
          : "digest ends";
      return `DIGEST part '${part.name}' has invalid ${invalidPrimeEnd}. Make sure any sequence edits have not broken the digest.`;
    }
  }
}

/**
 * Given the design state, see if any back-to-back digest fas parts
 *  are incompatible with one another
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
export default function validateDigestFas(designState) {
  try {
    const allDigestFas = {};
    const fasElementMap = {};
    const allDigestedDigestElementIds = [];
    Object.values(designState.fas).forEach(fas => {
      if (!designState.element[fas.elementId]) return;
      if (fas.name === "DIGEST") {
        const reaction = getItemOfType(
          { design: designState },
          "reaction",
          fas.reactionId
        );
        const reactionRestrictionEnzyme = getItemOfType(
          { design: designState },
          "restrictionEnzyme",
          reaction.restrictionEnzymeId
        );

        allDigestFas[fas.id] = {
          ...fas,
          restrictionEnzymeId: reactionRestrictionEnzyme?.id
        };
        fasElementMap[fas.elementId] = true;
        allDigestedDigestElementIds.push(fas.elementId);
      }
    });
    const allElements = designState.element;
    const elementIdToInvalidityMessages = {};
    const changeSetsHelper = new ChangeSetsHelper(designState);
    const state = { design: designState };

    // NOTE: The only time an element is linked to many bps (sequences),
    // is when the element is a partSet.
    const elementIdToBpsMap = allDigestedDigestElementIds.reduce(
      (acc, elId) => {
        acc[elId] = getSequenceStringsOfElement(state, elId);

        // if the bin direction is backwards, then need complement sequence
        if (!designState.bin[allElements[elId].binId].direction) {
          acc[elId] = acc[elId].map(bps =>
            getReverseComplementSequenceString(bps)
          );
        }

        return acc;
      },
      {}
    );

    for (const fas of Object.values(allDigestFas)) {
      // get junction length from the j5 parameters of the reaction the FAS is involved in
      const customParamId =
        designState.reaction[fas.reactionId].customJ5ParameterId;
      const junctionLength =
        designState.customJ5Parameter[customParamId].ggateOverhangBps;
      const elementOverhangMap = {};

      // overhangs shouldn't be self-incompatible
      Object.entries(elementIdToBpsMap).forEach(([elementId, allBps]) => {
        elementIdToInvalidityMessages[elementId] =
          elementIdToInvalidityMessages[elementId] || [];
        allBps.forEach(bps => {
          const overhang5Prime = bps.slice(0, junctionLength).toUpperCase();
          const rev5PrimeOverhang = getReverseComplementSequenceString(
            overhang5Prime
          ).toUpperCase();

          if (overhang5Prime === rev5PrimeOverhang) {
            elementIdToInvalidityMessages[elementId].push(
              `DIGEST 5' overhang is self-incompatible (rev complement of ${overhang5Prime} is ${rev5PrimeOverhang})`
            );
          }

          const overhang3Prime = bps.slice(-junctionLength).toUpperCase();
          const rev3PrimeOverhang = getReverseComplementSequenceString(
            overhang3Prime
          ).toUpperCase();
          if (overhang3Prime === rev3PrimeOverhang) {
            elementIdToInvalidityMessages[elementId] =
              elementIdToInvalidityMessages[elementId] || [];

            elementIdToInvalidityMessages[elementId].push(
              `DIGEST 3' overhang is self-incompatible (rev complement of ${overhang3Prime} is ${rev3PrimeOverhang})`
            );
          }

          elementOverhangMap[elementId] = {};
          elementOverhangMap[elementId].overhang5Prime = overhang5Prime;
          elementOverhangMap[elementId].overhang3Prime = overhang3Prime;
        });
        const element = getItemOfType(
          { design: designState },
          "element",
          elementId
        );
        const part = getItemOfType(
          { design: designState },
          "part",
          element.partId
        );
        const elementParts = getPartsInPartset(
          { design: designState },
          element.partsetId
        );
        // NOTE: part may be undefined if the element is a partSet and vice-versa
        const invalidityMessage = validateDigestPart({
          parts: part || elementParts,
          fas
        });
        invalidityMessage &&
          elementIdToInvalidityMessages[elementId].push(invalidityMessage);
      });

      const binCard = getBinCardByReactionIdAndElementId(
        state,
        fas.reactionId,
        fas.elementId
      );
      if (!binCard.hasSpecifiedJunction) {
        changeSetsHelper.updatePure("binCard", {
          id: binCard.id,
          rightJunctionBps: elementOverhangMap[fas.elementId].overhang3Prime
        });
      }
      const previousBinCard = getNeighborBinCardByReactionIdAndElementId(
        state,
        fas.reactionId,
        fas.elementId,
        false // threePrimeNeighbor: false, we want the 5prime one
      );
      if (!previousBinCard.hasSpecifiedJunction) {
        changeSetsHelper.updatePure("binCard", {
          id: previousBinCard.id,
          rightJunctionBps: elementOverhangMap[fas.elementId].overhang5Prime
        });
      }

      // rightJunctionBps are user-specified bps stored on binCards
      const rightJunctionBps = binCard.hasSpecifiedJunction
        ? getRightJunctionBpsForElement(state, fas.reactionId, fas.elementId)
        : null;

      elementIdToInvalidityMessages[fas.elementId] =
        elementIdToInvalidityMessages[fas.elementId] || [];
      const currentElementName = designState.element[fas.elementId].name;
      const overhangs3Prime = elementIdToBpsMap[fas.elementId].map(bps =>
        bps.slice(-junctionLength).toUpperCase()
      );
      // if element is a part set then all overhangs inside it should match
      if (overhangs3Prime.length > 1) {
        if (rightJunctionBps && overhangs3Prime[0] !== rightJunctionBps) {
          elementIdToInvalidityMessages[fas.elementId].push(
            `DIGEST overhang ${
              overhangs3Prime[0]
            } doesn't match user specified overhang ${rightJunctionBps.toUpperCase()}`
          );
        }

        if (uniq(overhangs3Prime).length !== 1) {
          // todo refactor this so that the error reports the names of the mismatched parts
          // its not a common use case though, not too worried about it
          elementIdToInvalidityMessages[fas.elementId].push(
            `DIGEST overhang mismatch within this part set: ${uniq(
              overhangs3Prime
            ).join(", ")}`
          );
        }
      }

      // overhang should match next overhang if back-to-back DIGEST FAS
      // If partSet, we are only validating with the first 3prime overhang.
      // NOTE: I guess its fine and sort of makes sense since if one of the parts in the set is invalid,
      // the whole partSet is.
      const firstOverhang3Prime = overhangs3Prime[0];

      if (rightJunctionBps && firstOverhang3Prime !== rightJunctionBps) {
        elementIdToInvalidityMessages[fas.elementId].push(
          `DIGEST 3' overhang ${firstOverhang3Prime} of ${currentElementName} doesn't match user-specified overhang ${rightJunctionBps.toUpperCase()}`
        );
      }

      getNeighborDigestFasEls(state, fas.reactionId, fas.elementId).forEach(
        neighborElId => {
          elementIdToInvalidityMessages[neighborElId] =
            elementIdToInvalidityMessages[neighborElId] || [];

          const neighborOverhangs5Prime = elementIdToBpsMap[
            neighborElId
          ].map(bps => bps.slice(0, junctionLength).toUpperCase());
          const nextElementName = designState.element[neighborElId].name;

          neighborOverhangs5Prime.forEach(overhang => {
            if (rightJunctionBps && overhang !== rightJunctionBps) {
              elementIdToInvalidityMessages[neighborElId].push(
                `DIGEST 5' overhang ${overhang} of ${nextElementName} doesn't match user-specified overhang ${rightJunctionBps.toUpperCase()}`
              );
            }
            if (overhang !== firstOverhang3Prime) {
              elementIdToInvalidityMessages[fas.elementId] =
                elementIdToInvalidityMessages[fas.elementId] || [];

              elementIdToInvalidityMessages[fas.elementId].push(
                `DIGEST 3' overhang mismatch with ${nextElementName} (...${firstOverhang3Prime} and ${overhang}...)`
              );

              elementIdToInvalidityMessages[neighborElId] =
                elementIdToInvalidityMessages[neighborElId] || [];

              elementIdToInvalidityMessages[neighborElId].push(
                `DIGEST 5' overhang mismatch with ${currentElementName} (...${firstOverhang3Prime} and ${overhang}...)`
              );
            }
          });
        }
      );

      const isList = isLayoutList({ design: designState });
      const disableSameBinDigestValidation = isDisabledSameBinDigestValidation({
        design: designState
      });
      if (!isList && !disableSameBinDigestValidation) {
        getSameBinDigestElIds(
          state,
          fas.reactionId,
          fas.elementId,
          fasElementMap
        ).forEach(elId => {
          // we've already checked for overhang mismatch within part sets, so only need to check if first overhang matches
          const fivePrimeOverhang = elementIdToBpsMap[fas.elementId][0]
            .slice(0, junctionLength)
            .toUpperCase();
          const threePrimeOverhang = elementIdToBpsMap[fas.elementId][0]
            .slice(-junctionLength)
            .toUpperCase();

          const fivePrimeOverhang2 = elementIdToBpsMap[elId][0]
            .slice(0, junctionLength)
            .toUpperCase();
          const threePrimeOverhang2 = elementIdToBpsMap[elId][0]
            .slice(-junctionLength)
            .toUpperCase();

          const elementName2 = designState.element[elId].name;

          elementIdToInvalidityMessages[fas.elementId] =
            elementIdToInvalidityMessages[fas.elementId] || [];

          if (fivePrimeOverhang !== fivePrimeOverhang2) {
            elementIdToInvalidityMessages[fas.elementId].push(
              `DIGEST FAS parts in same bin need to have matching overhangs, 5' overhang mismatch with ${elementName2} (${fivePrimeOverhang} and ${fivePrimeOverhang2})`
            );
          }

          if (threePrimeOverhang !== threePrimeOverhang2) {
            elementIdToInvalidityMessages[fas.elementId].push(
              `DIGEST FAS parts in same bin need to have matching overhangs, 3' overhang mismatch with ${elementName2} (${threePrimeOverhang} and ${threePrimeOverhang2})`
            );
          }
        });
      }
    }

    clearObsoleteInvalidityMsgs({
      allElements,
      elementIdToInvalidityMessages,
      changeSetsHelper
    });

    Object.entries(elementIdToInvalidityMessages).forEach(
      ([elementId, warningMessages]) => {
        const element = allElements[elementId];

        // get rid of old DIGEST msgs, replace with new ones
        const existingMsgs = !element.warningMessage
          ? []
          : element.warningMessage
              .split("\n")
              .filter(msg => !startsWith(msg, "DIGEST"));

        const warningMessage = warningMessages.length
          ? existingMsgs.concat(uniq(warningMessages)).join("\n")
          : existingMsgs.join("\n");

        // add DIGEST related msgs to any existing non-DIGEST msgs
        changeSetsHelper.updatePure("element", {
          id: element.id,
          warningMessage
        });
      }
    );

    return changeSetsHelper.execute({
      removeCardInvalidityMessages: false,
      updateNumPlaceholders: false,
      removeInvalidEugeneRules: false,
      removeInvalidElementCombos: false
    });
  } catch (err) {
    console.error("Error with validating digest fas", err);
    return designState;
  }
}

function clearObsoleteInvalidityMsgs({
  allElements,
  elementIdToInvalidityMessages,
  changeSetsHelper,
  designState
}) {
  for (const element of Object.values(allElements)) {
    if (element.warningMessage && !elementIdToInvalidityMessages[element.id]) {
      // only clear DIGEST related msgs, not Express Digest msgs
      const msgs = element.warningMessage
        .split("\n")
        .filter(msg => !startsWith(msg, "DIGEST"));
      changeSetsHelper.updatePure("element", {
        id: element.id,
        warningMessage: msgs.length === 0 ? null : msgs.join("\n")
      });
    }
  }
  return designState;
}
