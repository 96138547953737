/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { isBinSelected } from "../../../../src-shared/selectors/designViewSelectors";
import actions from "../../../../src-shared/redux/actions";
import ClassicGridContextMenuContainer from "../../../containers/ClassicGridContextMenuContainer";
import { withEditorContext } from "../DesignEditorContext";
import "./style.css";
import {
  getItemOfType,
  getRootCardId,
  getOutputtingReactionOfCard,
  getElementsInBin,
  getBinCardByCardIdAndBinId,
  getFasOfElement,
  getAssemblyMethodOfCard,
  getNeighborBinId,
  getInputCardsOfReaction
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { ContextMenu, Tooltip } from "@blueprintjs/core";
import store from "../../../../src-shared/redux/store";
import {
  COLUMN_WIDTH,
  BIN_HEIGHT,
  FLIP_ARROW_PATH,
  BIN_PART_GAP_HEIGHT,
  CONSTRUCT_ANNOTATION_HEIGHT
} from "../../../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";
import BinHeaderRect from "./BinHeaderRect";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { renderHeaderIconInner } from "../../SbolIconHeader/HeaderIcon";
import { isDesignLocked } from "../../../../src-shared/utils/designUtils/isDesignLocked";

const mapStateToProps = (state, { binId }) => {
  const cardId = getRootCardId(state);
  const bin = getItemOfType(state, "bin", binId);
  const reaction = getOutputtingReactionOfCard(state, cardId);
  const j5Params = getItemOfType(
    state,
    "customJ5Parameter",
    reaction.customJ5ParameterId
  );

  const rootBinCard = getBinCardByCardIdAndBinId(state, cardId, binId);
  let hasRightSideDigestJunction = false;
  const inputCards = getInputCardsOfReaction(state, reaction.id);
  const inputCardOfBin = inputCards.find(
    card => card.inputIndex === rootBinCard.index
  );
  const nextInputCardOfBin = inputCards.find(
    card =>
      card.inputIndex === (rootBinCard.index + 1) % (inputCards.length - 1)
  );

  const inputBinCard = getBinCardByCardIdAndBinId(
    state,
    inputCardOfBin.id,
    binId
  );

  if (nextInputCardOfBin) {
    const fasInThisBin = getElementsInBin(state, binId).map(el =>
      getFasOfElement(state, inputCardOfBin.id, el.id)
    );

    const nextBinId = getNeighborBinId(state, cardId, binId, true);
    if (nextBinId) {
      const fasInNextBin = getElementsInBin(state, nextBinId).map(el =>
        getFasOfElement(state, nextInputCardOfBin.id, el.id)
      );

      if (
        fasInThisBin.some(fas => fas && fas.name === "DIGEST") ||
        fasInNextBin.some(fas => fas && fas.name === "DIGEST")
      ) {
        hasRightSideDigestJunction = true;
      }
    }
  }

  return {
    bin,
    cardId,
    assemblyMethod: getAssemblyMethodOfCard(state, inputBinCard.cardId),
    hasRightSideDigestJunction,
    overhangLength: j5Params.ggateOverhangBps,
    binCard: inputBinCard,
    isSelected: isBinSelected(state, cardId, binId),
    icon: getItemOfType(state, "icon", bin.iconId),
    invalidityMessage: bin.invalidityMessage,
    isLocked: isDesignLocked(state)
  };
};

const mapDispatchToProps = {
  selectBin: actions.ui.designEditor.general.selectBin,
  updateBinCard: actions.design.updateBinCard,
  flipBin: actions.design.flipBin,
  removeBins: actions.design.removeBins,
  setActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  openInspector: actions.ui.designEditor.inspector.open
};

/**
 * A bin header (the box containing the sbol icon) in the classic design layout.
 */
class BinHeader extends React.Component {
  static propTypes = {
    /**
     * The id of the bin we are rendering.
     */
    binId: PropTypes.string.isRequired,

    /**
     * The index of bin we are rendering.
     */
    binIndex: PropTypes.number.isRequired,

    /**
     * The database object representing the bin we are rendering.
     */
    bin: PropTypes.object.isRequired,

    /**
     * The id of the root card.
     */
    cardId: PropTypes.string.isRequired,

    /**
     * Is this bin selected?
     */
    isSelected: PropTypes.bool,

    /**
     * The icon.`path` attribute of the icon we are rendering. If this is an svg icon,
     * then this is the `d` attribute on the svg `path` element. If this is an image
     * icon, then it is `src` attribute on the `image` element.
     */
    icon: PropTypes.object.isRequired,

    /**
     * If the bin is invalid, then this gives a message explaining why.
     */
    invalidityMessage: PropTypes.string
  };

  handleBinHeaderClick = e => {
    const {
      cardId,
      binId,
      selectBin,
      setActivePanel,
      openInspector
    } = this.props;
    selectBin({
      cardId,
      binId,
      shift: e.shiftKey,
      ctrl: e.ctrlKey || e.metaKey
    });
    setActivePanel({ panel: "set" });
    openInspector();
  };

  handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      cardId,
      binId,
      selectBin,
      isSelected,
      editorContext,
      isLocked
    } = this.props;

    if (!isSelected) {
      selectBin({
        cardId,
        binId,
        shift: e.shiftKey,
        ctrl: e.ctrlKey || e.metaKey
      });
    }

    const menu = (
      <ClassicGridContextMenuContainer {...{ store, editorContext }} />
    );

    if (!isLocked) ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };

  handleFlipButtonClick = () => {
    const { flipBin, binId, isLocked } = this.props;
    if (!isLocked) flipBin(binId);
  };

  handleDeleteButtonClick = () => {
    const { cardId, binId, removeBins, isLocked } = this.props;
    if (!isLocked) removeBins({ cardId, binIds: [binId] });
  };

  handleSpecifiedOverhangClick = () => {
    const { binCard, updateBinCard, overhangLength } = this.props;

    showDialog({
      modalType: "SPECIFY_BIN_OVERHANG",
      modalProps: {
        binCardId: binCard.id,
        updateBinCard,
        rightJunctionBps: binCard.rightJunctionBps,
        overhangLength
      }
    });
  };

  // Svg appears to lack the ability to elipsize overflowing text.
  getNameText = () => {
    const {
      bin: { name }
    } = this.props;

    // Width of rect/ ~number of pixels in a character
    const numCharsAllowed = COLUMN_WIDTH / 7;

    return name.length > numCharsAllowed
      ? name.substring(0, numCharsAllowed - 3) + "..."
      : name;
  };

  renderFlipButton = () => {
    const { bin, hasConstructAnnotations } = this.props;
    return (
      <g
        className={`flip-button direction-${
          bin.direction ? "forward" : "reverse"
        }`}
      >
        <rect
          {...{
            width: 22,
            height: 22,
            rx: 1,
            ry: 1,
            x: 5,
            y: 5 + +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT,
            onClick: this.handleFlipButtonClick
          }}
        />
        <path
          {...{
            d: FLIP_ARROW_PATH,
            transform: bin.direction
              ? `translate(3, ${9 +
                  +hasConstructAnnotations *
                    CONSTRUCT_ANNOTATION_HEIGHT})scale(0.7)`
              : `translate(3, ${9 +
                  +hasConstructAnnotations *
                    CONSTRUCT_ANNOTATION_HEIGHT})scale(0.7)rotate(180,19,10)`
          }}
        />
      </g>
    );
  };

  renderDeleteButton = () => {
    const { hasConstructAnnotations } = this.props;
    return (
      <g
        transform={`translate(${COLUMN_WIDTH - 15}, ${13 +
          +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT})`}
      >
        <path
          className="delete-button-cross"
          d="M 0 0 L 4 4  M 4 0 L 0 4"
          x={0}
          y={0}
          onClick={this.handleDeleteButtonClick}
        />
      </g>
    );
  };

  renderDesignRuleViolation = () => {
    const { invalidityMessage, hasConstructAnnotations } = this.props;
    if (!invalidityMessage) return null;
    const flagSize = 9;
    return (
      <g
        transform={`translate(4, ${BIN_HEIGHT -
          13 +
          +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT})`}
      >
        <Tooltip wrapperTagName="g" targetTagName="g">
          <circle
            fill="red"
            className="tg-design-ruleset-violation"
            cx={flagSize / 2}
            cy={flagSize / 2}
            r={flagSize / 2}
          />
          <div>
            {invalidityMessage.split("\n").map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>
        </Tooltip>
      </g>
    );
  };

  renderIcon = () => {
    const { icon, bin, hasConstructAnnotations } = this.props;
    return renderHeaderIconInner(icon, {
      noSvgWrapper: true,
      forward: bin.direction,
      translateHeight: hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT
    });
  };

  renderSpecifiedOverhang = () => {
    const {
      binCard = {},
      hasRightSideDigestJunction,
      assemblyMethod,
      hasConstructAnnotations
    } = this.props;

    // if there's no digest FAS parts in this bin
    // don't provide them the option to specify overhang
    if (!hasRightSideDigestJunction) return null;

    // if not a digest-based reaction then don't show
    if (
      assemblyMethod.name !== "Golden Gate" &&
      assemblyMethod.name !== "Auto Digest"
    )
      return null;

    return (
      <text
        className="bin-specified-overhang"
        {...{
          width: COLUMN_WIDTH / 2,
          stroke: binCard.rightJunctionBps ? "gray" : "lightgray",
          onClick: this.handleSpecifiedOverhangClick,
          fontSize: "12",
          x: binCard.rightJunctionBps
            ? COLUMN_WIDTH - 3 * binCard.rightJunctionBps.length
            : COLUMN_WIDTH - 30,
          y:
            BIN_HEIGHT +
            BIN_PART_GAP_HEIGHT * 0.75 +
            +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT
        }}
      >
        {binCard.rightJunctionBps || "specify bps"}
        <title>Base pairs for this junction. Click to customize!</title>
      </text>
    );
  };

  render() {
    const { hasConstructAnnotations } = this.props;
    return (
      <g>
        <BinHeaderRect
          {...this.props}
          handleBinHeaderClick={this.handleBinHeaderClick}
          handleContextMenu={this.handleContextMenu}
        />

        <text
          className="bin-name"
          {...{
            x: COLUMN_WIDTH / 2,
            y: 82 + +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT
          }}
        >
          {this.getNameText()}
        </text>
        {this.renderSpecifiedOverhang()}
        {this.renderIcon()}
        {this.renderFlipButton()}
        {this.renderDeleteButton()}
        {this.renderDesignRuleViolation()}
      </g>
    );
  }
}

export const DumbBinHeader = BinHeader;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEditorContext
)(BinHeader);
