/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { volumeRender } from "../../../../src-shared/utils/unitUtils";

function getBarcodeToDisplay(ac) {
  return (
    ac?.containerArray?.barcode?.barcodeString || ac?.barcode?.barcodeString
  );
}

export const worklistRecordTableSchema = {
  model: "worklistTransfer",
  fields: [
    {
      displayName: "Source",
      path: "sourceAliquotContainer.containerArray.name",
      render: (v, r) => {
        if (r.sourceAliquotContainer?.containerArrayId) {
          return r.sourceAliquotContainer.containerArray.name;
        } else {
          return r.sourceAliquotContainer?.name;
        }
      }
    },
    {
      displayName: "Source Barcode",
      render: (v, r) => getBarcodeToDisplay(r.sourceAliquotContainer)
    },
    {
      displayName: "Source Position",
      filterDisabled: true,
      sortDisabled: true,
      path: "sourceAliquotContainer",
      render: getAliquotContainerLocation
    },
    {
      displayName: "Source Material",
      path: "sourceAliquotContainer.aliquot.sample.name"
    },
    {
      displayName: "Destination",
      path: "destinationAliquotContainer.containerArray.name",
      render: (v, r) => {
        if (r.destinationAliquotContainer?.containerArray)
          return r.destinationAliquotContainer.containerArray.name;
        return r.destinationAliquotContainer?.name;
      }
    },
    {
      displayName: "Destination Barcode",
      render: (v, r) => getBarcodeToDisplay(r.destinationAliquotContainer)
    },
    {
      displayName: "Destination Position",
      filterDisabled: true,
      sortDisabled: true,
      path: "destinationAliquotContainer",
      render: getAliquotContainerLocation
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      type: "timestamp",
      render: volumeRender
    }
  ]
};

export const worklistTableSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source",
      path: "sourceAliquotContainer.containerArray.name",
      render: (v, r) => {
        if (r.sourceAliquotContainer?.containerArrayId) {
          return r.sourceAliquotContainer.containerArray.name;
        } else {
          return r.sourceAliquotContainer?.name;
        }
      }
    },
    {
      displayName: "Source Barcode",
      render: (v, r) => getBarcodeToDisplay(r.sourceAliquotContainer)
    },
    {
      displayName: "Source Position",
      sortFn: [
        r => get(r, "sourceAliquotContainer.rowPosition"),
        r => get(r, "sourceAliquotContainer.columnPosition")
      ],
      path: "sourceAliquotContainer",
      render: getAliquotContainerLocation
    },
    {
      displayName: "Source Material",
      path: "sourceAliquotContainer.aliquot.sample.name"
    },
    {
      displayName: "Destination",
      path: "destinationAliquotContainer.containerArray.name",
      render: (v, r) => {
        if (r.destinationAliquotContainer?.containerArray)
          return r.destinationAliquotContainer.containerArray.name;
        return r.destinationAliquotContainer?.name;
      }
    },
    {
      displayName: "Destination Barcode",
      render: (v, r) => getBarcodeToDisplay(r.destinationAliquotContainer)
    },
    {
      displayName: "Destination Position",
      sortFn: [
        r => r.destinationAliquotContainer?.rowPosition,
        r => r.destinationAliquotContainer?.columnPosition
      ],
      path: "destinationAliquotContainer",
      render: getAliquotContainerLocation
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    }
  ]
};

export const worklistRecordTubeTransferTableSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Tube Name",
      path: "aliquotContainer.name"
    },
    {
      displayName: "Tube Barcode",
      path: "aliquotContainer.barcode.barcodeString"
    },
    {
      displayName: "Source Rack",
      path: "sourceContainerArray.name"
    },
    {
      displayName: "Source Rack Barcode",
      path: "sourceContainerArray.barcode.barcodeString"
    },
    {
      displayName: "Source Position",
      path: "sourceContainerArray",
      sortFn: [r => r.sourceRowPosition, r => r.sourceColumnPosition],
      render: (_, r) =>
        getAliquotContainerLocation({
          rowPosition: r.sourceRowPosition,
          columnPosition: r.sourceColumnPosition,
          containerArray: r.sourceContainerArray
        })
    },
    {
      displayName: "Destination Rack",
      path: "destinationContainerArray.name"
    },
    {
      displayName: "Destination Rack Barcode",
      path: "destinationContainerArray.barcode.barcodeString"
    },
    {
      displayName: "Destination Position",
      path: "destinationContainerArray",
      sortFn: [r => r.destinationRowPosition, r => r.destinationColumnPosition],
      render: (_, r) =>
        getAliquotContainerLocation({
          rowPosition: r.destinationRowPosition,
          columnPosition: r.destinationColumnPosition,
          containerArray: r.destinationContainerArray
        })
    }
  ]
};
