/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Callout, Intent, H4 } from "@blueprintjs/core";
import classNames from "classnames";
import createGenerationModelFormStyles from "./CreateGenerationModelFormStyles.module.css";
import { VALIDATION_ISSUES_TYPES } from "./CsvGenerationInputValidation";
import { isEmpty } from "lodash";

/**
 * Issues come with a "message" and sometimes with a "sequence" object.
 */
function renderIssuesComponent(issues) {
  const issuesComponents = [];

  issues.forEach((issue, index) => {
    let issueComponentValue = issue.message;
    let seqWithIssueDisplayText = "";

    if (issue.sequence) {
      if (issue.sequence.length > 20) {
        seqWithIssueDisplayText = `${issue.sequence.slice(0, 20)}...`;
      } else {
        seqWithIssueDisplayText = issue.sequence;
      }
    }

    switch (issue.type) {
      case VALIDATION_ISSUES_TYPES.minSeqNumRequirementNotMet.type:
        issueComponentValue = (
          <span>
            <b>{issue.message}</b> At least {issue.validationRule} are required.
          </span>
        );
        break;
      case VALIDATION_ISSUES_TYPES.maxSeqNumRequirementExceeded.type:
        issueComponentValue = (
          <span>
            <b>{issue.message}</b> At most {issue.validationRule} should be
            uploaded.
          </span>
        );
        break;
      case VALIDATION_ISSUES_TYPES.minSeqLengthRequirementNotMet.type:
        issueComponentValue = (
          <span>
            <b>{issue.message}</b> Sequence number {issue.position + 1} ("
            {seqWithIssueDisplayText}") should have a length of at least{" "}
            {issue.validationRule} characters.
          </span>
        );
        break;
      case VALIDATION_ISSUES_TYPES.maxSeqLengthRequirementExceeded.type:
        issueComponentValue = (
          <span>
            <b>{issue.message}</b> Sequence number {issue.position + 1} ("
            {seqWithIssueDisplayText}") should have a length of at most{" "}
            {issue.validationRule} characters.
          </span>
        );
        break;
      case VALIDATION_ISSUES_TYPES.sequenceCharactersInvalid.type:
        const invalidCharPositions = issue.value.invalidCharacters.reduce(
          (accumulator, invalidChar) => {
            if (isEmpty(accumulator)) {
              return `${invalidChar.position + 1}`;
            }
            return `${accumulator}, ${invalidChar.position}`;
          },
          ""
        );
        issueComponentValue = (
          <span>
            <b>{issue.message}</b> Sequence number {issue.position + 1} ("
            {seqWithIssueDisplayText}") has{" "}
            {issue.value.invalidCharacters.length} invalid characters at
            position(s) {invalidCharPositions}.
          </span>
        );
        break;
      default:
        break;
    }
    issuesComponents.push(
      <li key={`${issue.type}_${index}`}>{issueComponentValue}</li>
    );
  });

  return issuesComponents;
}

function renderOverflowIssues(numberOfOverflowedIssues) {
  return (
    <div
      className={classNames(createGenerationModelFormStyles.overflowedIssues)}
    >{`( + ${numberOfOverflowedIssues} other issues ... )`}</div>
  );
}

function ValidationIssuesComponent(props) {
  const {
    sequencesIssues = [],
    globalIssues = [],
    maxNumberOfIssuesToDisplay = 10
  } = props;

  const sequencesIssuesToRender = sequencesIssues.slice(
    1,
    maxNumberOfIssuesToDisplay - globalIssues.length
  );

  const overflowedIssues = sequencesIssues.slice(maxNumberOfIssuesToDisplay);

  return (
    <Callout
      className={classNames(
        createGenerationModelFormStyles.validationIssuesComponent
      )}
      intent={Intent.DANGER}
    >
      <H4 className={createGenerationModelFormStyles.validationIssuesTitle}>
        The following issues were encountered, please select other data.
      </H4>
      <ul>{renderIssuesComponent(globalIssues)}</ul>
      <ul>{renderIssuesComponent(sequencesIssuesToRender)}</ul>
      {sequencesIssues.length > maxNumberOfIssuesToDisplay
        ? renderOverflowIssues(overflowedIssues.length)
        : ""}
    </Callout>
  );
}

export default ValidationIssuesComponent;
