/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../src-shared/GenericSelect";
import containerArrayTypeFragment from "../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";

function ContainerArrayTypeSelect({
  additionalDataFragment = containerArrayTypeFragment,
  ...rest
}) {
  return (
    <GenericSelect
      {...{
        asReactSelect: true,
        isRequired: true,
        name: "containerArrayType",
        label: "Plate Type",
        placeholder: "Select plate type...",
        schema: ["name"],
        fragment: ["containerArrayTypeWithQuadrantSizeView", "id name"],
        additionalDataFragment,
        queryOptions: {
          variables: {
            sort: ["-isPlate", "quadrantSize", "name", "id"]
          }
        },
        ...rest
      }}
    />
  );
}

export default ContainerArrayTypeSelect;
