/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const executeReactionsFragment = gql`
  fragment executeReactionsFragment on reaction {
    id
    name
    inputHash
    reactionOutputs {
      id
      outputMaterialId
      outputMaterial {
        id
        name
      }
      outputAdditiveMaterialId
      outputAdditiveMaterial {
        id
        name
      }
    }
    reactionInputs {
      id
      name
      inputMaterialId
      inputMaterial {
        id
        name
      }
      inputAdditiveMaterialId
      inputAdditiveMaterial {
        id
        name
      }
      conserved
    }
  }
`;

export const aliquotContainerReactionMapAdditiveFragment = gql`
  fragment aliquotContainerReactionMapAdditiveFragment on additive {
    id
    lot {
      id
      additiveMaterialId
      additiveMaterial {
        id
        name
      }
    }
    additiveMaterialId
    additiveMaterial {
      id
      name
    }
  }
`;

export const aliquotContainerReactionMapFragmentBasic = gql`
  fragment aliquotContainerReactionMapFragmentBasic on aliquotContainer {
    id
    additives {
      ...aliquotContainerReactionMapAdditiveFragment
    }
    aliquot {
      id
      additives {
        ...aliquotContainerReactionMapAdditiveFragment
      }
      sample {
        id
        materialId
        material {
          id
          name
        }
        sampleFormulations {
          id
          materialCompositions {
            id
            materialId
            material {
              id
              name
            }
          }
        }
      }
    }
  }
  ${aliquotContainerReactionMapAdditiveFragment}
`;

export const aliquotContainerReactionMapFragment = gql`
  fragment aliquotContainerReactionMapFragment on aliquotContainer {
    id
    cid
    name
    rowPosition
    columnPosition
    barcode {
      id
      barcodeString
    }
    containerArrayId
    containerArray {
      id
      name
    }
    ...aliquotContainerReactionMapFragmentBasic
  }
  ${aliquotContainerReactionMapFragmentBasic}
`;
