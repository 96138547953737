/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { DataTable, InputField, IntentText } from "@teselagen/ui";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import PlateUploadFields from "../../../PlateUploadFields";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";

class DestinationContainerConfiguration extends Component {
  render() {
    const { feedbackData = [] } = this.props;

    let allInInventory = true;
    const destinationPlatesInInventory = feedbackData.reduce((acc, row) => {
      if (!row.destinationPlate) allInInventory = false;
      if (row.destinationPlate && !acc.includes(row.destinationPlate)) {
        acc.push(row.destinationPlate);
      }
      return acc;
    }, []);
    return (
      <>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Destination Container"
              helper={`Select a destination container type. The number of destination containers depends on\
              the number of combinations of input materials. Enter a name for the destination\
              container. If there are multiple containers, the name will be appended with incremental\
              number starting from 1.`}
            />
            <div style={{ width: 300 }}>
              {!allInInventory && (
                <>
                  <InputField
                    name="destinationContainerName"
                    isRequired
                    label="Destination Container Name"
                    generateDefaultValue={{
                      ...defaultValueConstants.DESTINATION_CONTAINER_NAME,
                      customParams: {
                        containerType: "Plate"
                      }
                    }}
                  />
                  <PlateUploadFields
                    inTool
                    noFileUpload
                    noNumTubes
                    genericSelectOptions={{
                      isRequired: true
                    }}
                  />
                </>
              )}
              {allInInventory && (
                <IntentText intent="warning">
                  All destination plates exist in inventory.
                </IntentText>
              )}
            </div>
          </div>
          {!!destinationPlatesInInventory.length && (
            <div>
              <h6>Destination plate(s) in inventory</h6>
              <DataTable
                isSimple
                formName="colonyFeedbackDestinationPlates"
                entities={destinationPlatesInInventory}
                schema={[
                  "name",
                  {
                    displayName: "barcode",
                    path: "barcode.barcodeString"
                  },
                  {
                    displayName: "Plate Type",
                    path: "containerArrayType.name"
                  }
                ]}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default compose(stepFormValues("feedbackData"))(
  DestinationContainerConfiguration
);
