/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isInteger } from "lodash";
import isValidPositiveNumber from "../../../../../tg-iso-shared/src/utils/isValidPositiveNumber";

export const isValidNumOfReactions = num => {
  const cleanedNum = num && Number(num);
  const valid =
    isValidPositiveNumber(cleanedNum) &&
    cleanedNum >= 2 &&
    isInteger(cleanedNum);
  return valid;
};
