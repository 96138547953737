/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import gql from "graphql-tag";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";
import SelectPlates from "./Steps/SelectPlates";
import ExtendedProperties from "./Steps/ExtendedProperties";
import { plateFragment, tubeFragment } from "./fragments";
import { withSelectedEntities } from "@teselagen/ui";
import {
  addAndRemoveExtendedProperties,
  getBoundExtendedPropertyUploadHelpers
} from "../../../../src-shared/utils/extendedPropertyUtils";
import {
  safeDelete,
  safeQuery,
  safeUpsert
} from "../../../../src-shared/apolloMethods";
import { parseCsvOrExcelFile } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { keyBy } from "lodash";
import { getPositionFromAlphanumericLocation } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { noop } from "lodash";
import { createPlateLocationMap } from "../../../utils/plateUtils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { removeExtendedPropertiesTableName } from "../../../../src-shared/RemoveExtendedPropertiesTable";
import { isEmpty } from "lodash";

const plateFragmentWithAliquotsForUpdateAlExtProps = gql`
  fragment plateFragmentWithAliquotsForUpdateAlExtProps on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        rowCount
        columnCount
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        id
        extendedValues {
          id
          extendedPropertyId
          value
        }
        extendedCategoryValues {
          id
          extendedCategoryId
          extendedCategory {
            id
            name
          }
          extendedPropertyId
        }
        extendedMeasurementValues {
          id
          value
          measurementUnitId
          measurementUnit {
            id
            abbreviation
          }
          extendedPropertyId
        }
      }
    }
  }
`;

class ExecuteWorklistTool extends React.Component {
  onSubmit = async values => {
    try {
      const {
        [removeExtendedPropertiesTableName +
        "SelectedEntities"]: propsToRemove = []
      } = this.props;
      const aliquotPropIdsToRemove = propsToRemove.map(p => p.id);
      const {
        containerArrays = [],
        aliquotContainers = [],
        aliquots = [],
        aliquotExtendedValues = [],
        extendedPropertiesFile = []
      } = values;

      const extendedValuesToDelete = {
        extendedValue: [],
        extendedCategoryValue: [],
        extendedMeasurementValue: []
      };

      const extendedValuesToCreate = {
        extendedValue: [],
        extendedCategoryValue: [],
        extendedMeasurementValue: []
      };

      aliquots.forEach(aliquot => {
        addAndRemoveExtendedProperties(aliquot, {
          extendedValues: aliquotExtendedValues,
          extendedValuesToDelete,
          extendedValuesToCreate,
          propIdsToRemove: aliquotPropIdsToRemove
        });
      });

      let _createUploadProperties = noop;
      if (extendedPropertiesFile.length) {
        if (!containerArrays.length) {
          window.toastr.error("Can only upload csv if plates are selected.");
          return false;
        }
        const _platesWithInfo = await safeQuery(
          plateFragmentWithAliquotsForUpdateAlExtProps,
          {
            variables: {
              filter: {
                id: containerArrays.map(c => c.id)
              }
            }
          }
        );
        const platesWithInfo = _platesWithInfo.map(p => {
          return {
            ...p,
            locationMap: createPlateLocationMap(p.aliquotContainers)
          };
        });
        const {
          data,
          meta: { fields }
        } = await parseCsvOrExcelFile(extendedPropertiesFile[0]);
        const platesByBarcode = keyBy(platesWithInfo, p =>
          p.barcode?.barcodeString.toLowerCase()
        );
        const platesByName = keyBy(platesWithInfo, p => p.name.toLowerCase());
        const {
          createUploadProperties,
          getCsvRowExtProps,
          properties
        } = await getBoundExtendedPropertyUploadHelpers(fields);
        if (isEmpty(properties)) {
          throw new Error("No extended properties found in file.");
        }
        _createUploadProperties = createUploadProperties;

        for (const row of data) {
          const {
            "plate name": plateName,
            "plate barcode": plateBarcode,
            "well position": wellPosition
          } = row;
          const plate = plateBarcode
            ? platesByBarcode[plateBarcode.toLowerCase()]
            : platesByName[plateName?.toLowerCase()];
          if (!plate) {
            window.toastr.error(
              `Plate ${plateName || plateBarcode} not found in selection.`
            );
            return false;
          }
          const locationCleaned = getAliquotContainerLocation(
            getPositionFromAlphanumericLocation(
              wellPosition,
              plate.containerArrayType.containerFormat
            ),
            {
              force2D: true
            }
          );
          const aliquot = plate.locationMap[locationCleaned]?.aliquot;
          if (!aliquot) {
            window.toastr.error(
              `No aliquot found at position ${wellPosition} on plate ${plateBarcode ||
                plateName}.`
            );
            return false;
          }
          getCsvRowExtProps({
            row,
            recordId: aliquot.id,
            record: aliquot,
            modelTypeCode: "ALIQUOT"
          });
        }
      }

      for (const model of Object.keys(extendedValuesToDelete)) {
        await safeDelete(model, extendedValuesToDelete[model]);
      }
      for (const model of Object.keys(extendedValuesToCreate)) {
        await safeUpsert(model, extendedValuesToCreate[model]);
      }
      await _createUploadProperties();

      return {
        containerArrays,
        aliquotContainers
      };
    } catch (error) {
      throwFormError(error);
    }
  };

  validate = values => {
    const { aliquotContainers = [], containerArrays = [] } = values;
    const errors = {};
    if (!aliquotContainers.length && !containerArrays.length) {
      const msg = "Please select plates or tubes to update";
      errors.aliquotContainers = msg;
      errors.containerArrays = msg;
    }

    return errors;
  };

  render() {
    const {
      toolSchema,
      initialValues,
      isToolIntegrated,
      toolIntegrationProps
    } = this.props;

    const steps = [
      {
        title: "Select Plates/Tubes",
        Component: SelectPlates,
        withCustomFooter: true
      },
      {
        title: "Update Extended Properties",
        Component: ExtendedProperties
      }
    ];

    return (
      <StepForm
        steps={steps}
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        onSubmit={this.onSubmit}
        validate={this.validate}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plateFragment),
  withWorkflowInputs(tubeFragment),
  withSelectedEntities(removeExtendedPropertiesTableName)
)(ExecuteWorklistTool);
