/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const createNotification = async notificationOrNotifications => {
  const newNotifications = Array.isArray(notificationOrNotifications)
    ? notificationOrNotifications
    : [notificationOrNotifications];
  if (!notificationOrNotifications || !newNotifications.length) return [];
  const results = [];
  let numErrors = 0;
  for (const newNotification of newNotifications) {
    const {
      data: { success, err, result }
    } = await window.serverApi.request({
      method: "POST",
      url: "/createNotification",
      data: newNotification
    });
    if (!success || err) {
      numErrors++;
      window.console.error("Error creating notification,", err);
    }
    results.push(result);
  }
  if (numErrors > 0) {
    window.toastr.error(
      `Error creating notification${numErrors > 1 ? "s" : ""}.`
    );
  }
  return results;
};
