/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment ioItemWithLinkedItemFragment on ioItem {
    id
    dataItem {
      id
      dataItemTypeCode
      dataTable {
        id
        name
      }
      reactionDesign {
        id
        name
      }
      reactionMap {
        id
        name
      }
    }
  }
`;
