/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { DataTable, SelectField, InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { get } from "lodash";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";

const reactionInfoSchema = {
  fields: [
    { displayName: "Input Material", path: "inputMaterial.name" },
    { path: "outputMaterial" }
  ]
};
class ReviewMaterials extends Component {
  componentDidMount() {
    const {
      reactionInfo = [],
      stepFormProps: { change }
    } = this.props;
    reactionInfo.forEach(r => {
      if (r.outputMaterialOptions.length <= 1) {
        const material = r.outputMaterialOptions[0];
        if (material) {
          change(`selectedProteinMaterials.id${r.inputMaterial.id}`, material);
        }
      }
    });
  }
  render() {
    const { reactionInfo = [], Footer, footerProps } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Review Purification Reactions"
            helper="Review the purification reaction info below. By default, protein information has been pulled from input materials."
          />
          <DataTable
            isSimple
            noForm
            schema={reactionInfoSchema}
            entities={reactionInfo}
            noSelect
            cellRenderer={{
              outputMaterial: (v, record) => {
                const outputMaterialOptions = arrayToItemValuedOptions(
                  record.outputMaterialOptions
                );
                if (outputMaterialOptions.length > 1) {
                  return (
                    <SelectField
                      name={`selectedProteinMaterials.id${record.inputMaterial.id}`}
                      enableReinitialize
                      defaultValue={outputMaterialOptions[0].value}
                      options={outputMaterialOptions}
                    />
                  );
                } else {
                  return get(outputMaterialOptions, "[0].label");
                }
              }
            }}
          />
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Name Reaction Map"
            helper="Provide a name for the output protein purification reaction map"
          />
          <InputField
            style={{ width: 700 }}
            name="reactionMapName"
            label="Reaction Map Name"
            defaultValue="Protein Purification Reaction Map"
          />
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              loading={footerProps.submitting}
            >
              Generate Reaction Map
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues("reactionInfo")(ReviewMaterials);
