/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import {
  BACKBONE_BORDER_RADIUS,
  WITHIN_SVG_PADDING,
  BACKBONE_RING_HEIGHT,
  SPACE_BETWEEN_SHAPES,
  SHAPE_HEIGHT,
  MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS
} from "../constants";
import { Origin } from "../Shapes";
import {
  getWidthOfSchematic,
  getWidthOfSetShape,
  getHeightOfSchematic,
  computeElementLabelPositions,
  getElementLabelsHeight,
  getWidthOfSchematicNoLabels,
  computeElementLabelLines
} from "./layoutEngine";
import ShapeDropTarget from "./ShapeDropTarget";
import TrashDropTarget from "./TrashDropTarget";
import ElementLabel from "./ElementLabel";

import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { TYPE_SHAPE_MAP } from "../typeShapeMap";
import determineBlackOrWhiteTextColor from "../../../../src-shared/utils/determineBlackOrWhiteTextColor";

class Schematic extends React.Component {
  handleShapeDoubleClick = set => () => {
    if (this.props.readOnly) return;
    showDialog({
      modalType: "SCHEMATIC_EDIT_NAME",
      modalProps: {
        initialValues: set,
        type: "backbone"
      }
    });
  };

  renderBackboneRing = () => (
    <rect
      id="schematic-backbone-ring"
      className="schematic-backbone-ring"
      style={{
        fill: "none",
        stroke: "#4a4b4c",
        strokeWidth: 3
      }}
      x={0}
      y={0}
      width={getWidthOfSchematicNoLabels(this.props.schematic)}
      height={BACKBONE_RING_HEIGHT}
      rx={BACKBONE_BORDER_RADIUS}
      ry={BACKBONE_BORDER_RADIUS}
    />
  );

  renderSet = (set, i, vars) => {
    const { schematic, refetchSchematic, readOnly } = this.props;
    const { xOffset } = vars;
    const Shape = TYPE_SHAPE_MAP[set.type];
    const isRight = set.type.includes("right");
    const shapeWidth = getWidthOfSetShape(set);
    vars.xOffset += shapeWidth + SPACE_BETWEEN_SHAPES;
    return (
      <g key={set.id}>
        <Shape
          {...{
            isRight,
            x: xOffset,
            y: 0,
            fill: set.color,
            textColor: determineBlackOrWhiteTextColor(set.color),
            text: set.name,
            set,
            readOnly,
            onDoubleClick: this.handleShapeDoubleClick(set)
          }}
        />
        <ShapeDropTarget
          {...{
            schematic,
            index: i + 1,
            x: xOffset + shapeWidth,
            y: -SHAPE_HEIGHT,
            width: SPACE_BETWEEN_SHAPES,
            height: 3 * SHAPE_HEIGHT,
            refetchSchematic
          }}
        />
      </g>
    );
  };

  renderSets = () => {
    const { schematic, refetchSchematic } = this.props;
    const vars = { xOffset: BACKBONE_BORDER_RADIUS };
    return (
      <g transform={`translate(0,${-SHAPE_HEIGHT / 2})`}>
        <ShapeDropTarget
          {...{
            schematic,
            index: 0,
            x: 0,
            y: -SHAPE_HEIGHT,
            width: SPACE_BETWEEN_SHAPES,
            height: 3 * SHAPE_HEIGHT,
            refetchSchematic
          }}
        />
        {schematic.schematicSets
          .map((set, i) => this.renderSet(set, i, vars))
          .reverse()}
      </g>
    );
  };

  renderBackboneSet = () => {
    const { schematic } = this.props;
    const { backboneSet } = schematic;
    const bb = { ...backboneSet, type: "origin" };

    const schematicWidth = getWidthOfSchematicNoLabels(schematic);
    const widthOfShape = getWidthOfSetShape(bb);

    return (
      <g
        id="schematic-backbone-origin"
        transform={`translate(${schematicWidth / 2 -
          widthOfShape / 2}, ${BACKBONE_RING_HEIGHT - SHAPE_HEIGHT / 2})`}
      >
        <Origin
          {...{
            strokeWidth: 2,
            fill: bb.color,
            textColor: determineBlackOrWhiteTextColor(bb.color),
            text: bb.name,
            set: backboneSet,
            isBackbone: true,
            onDoubleClick: this.handleShapeDoubleClick(backboneSet)
          }}
        />
      </g>
    );
  };

  renderElementLabels = (elementLabelPositions, labelLines) => {
    const { schematic, refetchSchematic, show, readOnly } = this.props;
    const schematicSets = [...schematic.schematicSets].reverse();
    const labelsHeight = getElementLabelsHeight(elementLabelPositions);
    return schematicSets.map(set => {
      const position = elementLabelPositions[set.id];
      const labelLine = labelLines[set.id];
      return (
        <ElementLabel
          key={set.id}
          {...{
            ...position,
            labelsHeight,
            set,
            refetchSchematic,
            labelLine,
            show,
            readOnly
          }}
        />
      );
    });
  };

  render() {
    const { schematic, readOnly } = this.props;
    const elementLabelPositions = computeElementLabelPositions(schematic);
    const labelLines = computeElementLabelLines(
      schematic,
      elementLabelPositions
    );

    const minLabelPosition = Object.values(elementLabelPositions).reduce(
      (acc, p) => Math.min(acc, p.x),
      0
    );
    return (
      <div className="schematic-edit-upper-container">
        {!readOnly && <TrashDropTarget {...this.props} />}
        <div className="schematic-edit-container">
          <div className="schematic-edit-svg-container">
            <svg
              id="schematic-edit-svg"
              style={{
                fontFamily: '"Lucida Console", Monaco, monospace',
                fontSize: "13px"
              }}
              className="schematic-edit-svg"
              width={
                getWidthOfSchematic(schematic, elementLabelPositions) +
                2 * WITHIN_SVG_PADDING
              }
              height={
                getHeightOfSchematic(schematic, elementLabelPositions) +
                2 * WITHIN_SVG_PADDING
              }
            >
              <g
                transform={`translate(${WITHIN_SVG_PADDING -
                  minLabelPosition},${WITHIN_SVG_PADDING})`}
              >
                {this.renderElementLabels(elementLabelPositions, labelLines)}
                <g
                  transform={`translate(0,${getElementLabelsHeight(
                    elementLabelPositions
                  ) + MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS})`}
                >
                  {this.renderBackboneRing()}
                  {this.renderSets()}
                  {this.renderBackboneSet()}
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default Schematic;
