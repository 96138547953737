/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";

import { cloneDeep } from "lodash";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";

import recurseOnJson from "../../../../../tg-iso-design/utils/recurseOnJson";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";

class DuplicateAASequenceDialog extends React.Component {
  onSubmit = defaultAsyncWrap(async ({ name }) => {
    const { hideModal, refetch, selectedRecords } = this.props;

    try {
      const aaSequences = await safeQuery(
        [
          "aminoAcidSequence",
          `id
        name
        description
        size
        proteinSequence
        createdAt
        updatedAt
        `
        ],
        {
          isPlural: true,
          variables: {
            filter: { id: selectedRecords.map(r => r.id) },
            pageSize: selectedRecords.length
          }
        }
      );
      const valuesToUpsert = cloneDeep(aaSequences);
      recurseOnJson(
        valuesToUpsert,
        value => {
          delete value.id;
          delete value.code;
          delete value.cid;
          delete value.__typename;
        },
        { callOnObjectsOnly: true }
      );

      valuesToUpsert.forEach(seq => {
        seq.name = name;
      });

      await safeUpsert("aminoAcidSequence", valuesToUpsert);
      await refetch();
      hideModal();

      window.toastr.success(
        `Duplicated ${valuesToUpsert.length} AA sequence${
          valuesToUpsert.length > 1 ? "s" : ""
        }.`
      );
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error duplicating AA Sequence${
          selectedRecords.length > 1 ? "s" : ""
        }s.`
      );
    }
  });

  render() {
    const { hideModal, handleSubmit, selectedRecords } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            defaultValue={selectedRecords[0].name + " copy"}
          />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" />
      </form>
    );
  }
}

const validate = (values, { selectedRecords }) => {
  const errors = {};
  if (selectedRecords.length && !values.name) errors.name = "Required.";
  return errors;
};

export default compose(
  wrapDialog(props => ({
    title: "Duplicate AA Sequence" + sIfPlural(props.selectedRecords)
  })),
  reduxForm({
    form: "duplicateAASequenceDialog", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(DuplicateAASequenceDialog);
