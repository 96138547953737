/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Tooltip, Position, Button, Intent, Divider } from "@blueprintjs/core";
import { range, get, sortBy } from "lodash";
import { InputField, NumericInputField, CheckboxField } from "@teselagen/ui";
import {
  goldenGateSpecificParams,
  flankingHomologySpecificParams,
  universalParams
} from "./j5params";
import {
  validatePositiveInteger,
  validatePositiveNumber
} from "../../../../src-shared/utils/formUtils";
import { DIGEST_ASSEMBLY_METHODS } from "../../../../../tg-iso-design/constants/assemblyMethods";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";

function PositiveNumericInputField(props) {
  return (
    <NumericInputField min={0} validate={validatePositiveNumber} {...props} />
  );
}

class EditParameters extends React.Component {
  UNSAFE_componentWillMount() {
    const { customJ5Parameter, updateFormValues } = this.props;
    updateFormValues(customJ5Parameter);
  }

  renderAttSiteParameters(gateway) {
    const { arrayRemove, readOnly } = this.props;
    return gateway.map((__, i) => (
      <div className="gateway-att-site-edit-group" key={i}>
        <Button
          minimal
          style={{ position: "absolute", top: 5, right: 5 }}
          intent={Intent.DANGER}
          icon="trash"
          text="Remove"
          onClick={() => arrayRemove("editParameters.gatewayParams.gateway", i)}
          disabled={readOnly}
        />
        <h6>L{i + 1}</h6>
        <InputField
          name={`editParameters.gatewayParams.gateway[${i}].L.sequence`}
          label="Sequence"
          disabled={readOnly}
        />
        <PositiveNumericInputField
          name={`editParameters.gatewayParams.gateway[${i}].L.overhangStart`}
          label="Overhang Start"
          disabled={readOnly}
        />
        <CheckboxField
          name={`editParameters.gatewayParams.gateway[${i}].L.isBSide5Prime`}
          label={
            <span>
              Does 5' Fragment Form <i>attB</i>
            </span>
          }
          disabled={readOnly}
        />
        <h6>R{i + 1}</h6>
        <InputField
          name={`editParameters.gatewayParams.gateway[${i}].R.sequence`}
          label="Sequence"
          disabled={readOnly}
        />
        <PositiveNumericInputField
          name={`editParameters.gatewayParams.gateway[${i}].R.overhangStart`}
          label="Overhang Start"
          disabled={readOnly}
        />
        <CheckboxField
          name={`editParameters.gatewayParams.gateway[${i}].R.isBSide5Prime`}
          label={
            <span>
              Does 5' Fragment Form <i>attB</i>
            </span>
          }
          disabled={readOnly}
        />
      </div>
    ));
  }

  renderGatewayParameters() {
    const { gatewayParams, arrayPush, readOnly } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <Button
          intent={Intent.SUCCESS}
          style={{ position: "absolute", top: 5, right: 5 }}
          icon="add"
          text={
            <span>
              Add <i>attL</i>/<i>attR</i> Sites
            </span>
          }
          onClick={() =>
            arrayPush("editParameters.gatewayParams.gateway", {
              L: { sequence: "", overhangStart: 1, isBSide5Prime: true },
              R: { sequence: "", overhangStart: 1, isBSide5Prime: false }
            })
          }
          disabled={readOnly}
        />
        <h6>General</h6>
        <PositiveNumericInputField
          name="editParameters.gatewayParams.overhangLength"
          label="Overhang Size"
          disabled={readOnly}
        />
        {gatewayParams?.gateway &&
          this.renderAttSiteParameters(gatewayParams.gateway)}
      </div>
    );
  }

  displayType(columns) {
    const { isPreset } = this.props;
    if (isPreset) {
      return (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around"
            }}
          >
            {columns}
          </div>
          <Divider />
          <div style={{ paddingBottom: 20 }} />
          <HeaderWithHelper header="Gateway Parameters" />
          {this.renderGatewayParameters()}
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around"
          }}
        >
          {columns}
        </div>
      );
    }
  }

  promptUnsavedChanges = () => {
    this.props.onUnsavedChanges(true);
  };

  render() {
    const {
      parameterPreset,
      customJ5Parameter,
      assemblyMethod,
      readOnly,
      isPreset
    } = this.props;

    const assemblyMethodName = assemblyMethod?.name;

    let params = universalParams;
    if (DIGEST_ASSEMBLY_METHODS.includes(assemblyMethodName)) {
      params = params.concat(goldenGateSpecificParams);
    } else if (
      ["Gibson/SLIC/CPEC", "Simple Flanking Homology", "PCR"].includes(
        assemblyMethodName
      )
    ) {
      params = params.concat(flankingHomologySpecificParams);
    } else if (isPreset) {
      params = params
        // If this is a preset (i.e., is being configured from the Assembly Parameter Presets settings panel)
        // We can't really allow the user to configure params that depende on a restriction enzyme for now.
        .concat(goldenGateSpecificParams.filter(param => !param.disabled))
        .concat(flankingHomologySpecificParams);
    } else {
      params = params
        .concat(goldenGateSpecificParams)
        .concat(flankingHomologySpecificParams);
    }
    params = sortBy(params, "name");

    if (customJ5Parameter) {
      params = params.map(param => ({
        ...param,
        defaultValue: customJ5Parameter[param.name] || param.defaultValue
      }));
    }

    const fields = params
      .filter(param => !param.hidden)
      .map((entry, i) => {
        const label = (
          <Tooltip
            content={<span>{entry.description}</span>}
            position={Position.TOP}
          >
            {entry.display}
          </Tooltip>
        );

        const fieldProps = {
          name: "editParameters." + entry.name,
          label,
          defaultValue: get(parameterPreset, entry.name) ?? entry.defaultValue,
          disabled: entry.disabled || readOnly,
          // No point in requiring boolean types.
          isRequired: entry.isRequired && entry.type !== "Boolean",
          ...(entry.format && { format: entry.format }),
          ...(entry.validate && { validate: entry.validate })
        };

        if (entry.type === "int") {
          return (
            <PositiveNumericInputField
              key={i}
              {...fieldProps}
              validate={validatePositiveInteger}
              onChange={this.promptUnsavedChanges}
            />
          );
        } else if (entry.type === "Float") {
          return (
            <PositiveNumericInputField
              key={i}
              {...fieldProps}
              onChange={this.promptUnsavedChanges}
            />
          );
        } else if (entry.type === "Boolean") {
          return (
            <CheckboxField
              key={i}
              {...fieldProps}
              onChange={this.promptUnsavedChanges}
            />
          );
        }
        return (
          <InputField
            key={i}
            {...fieldProps}
            onChange={this.promptUnsavedChanges}
          />
        );
      })
      .map((field, i) => (
        <div className="j5-param-field-wrapper" key={i}>
          {field}
        </div>
      ));

    const nCols = 3;
    const nRows = Math.ceil(fields.length / nCols);

    const columns = range(nCols)
      .map(i => fields.slice(i * nRows, (i + 1) * nRows))
      .map((column, i) => {
        return (
          <div className="j5preset-col" key={i}>
            {column}
          </div>
        );
      });
    return this.displayType(columns);
  }
}

export default EditParameters;
