/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { camelCase, get, findIndex } from "lodash";
import { Link, withRouter } from "react-router-dom";
import { MenuBar, commandMenuEnhancer } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";
import SubmitForAssemblyButton from "../SubmitForAssemblyButton";
import { withEditorContext } from "./DesignEditorContext";
import { Button, MenuItem, Tooltip } from "@blueprintjs/core";
import {
  isAssemblyMethodInDesign,
  getNumOfInvaliditiesInDesign,
  getDesign,
  getElements,
  treeLayout
} from "../../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../../src-shared/redux/actions";
import exampleDesignMap from "../../../../tg-iso-design/exampleDesigns";
import { useTgQuery } from "../../../src-shared/apolloMethods";
import isMobile from "is-mobile";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import store from "../../../src-shared/redux/store";
import { showDialog } from "../../../src-shared/GlobalDialog";
import UpdateExternalDialog from "../../../src-shared/ExternalIntegrations/UpdateExternalDialog";
import { isDesignLocked } from "../../../src-shared/utils/designUtils/isDesignLocked";

const updatePartsMenuItem = {
  text: "Update Parts",
  component: function PartIntegrationsMenu() {
    const { integrations, loading } = useTgQuery(
      [
        "integration",
        "id integrationTypeCode name integrationEndpoints { id endpointTypeCode url integrationEndpointHeaders { id name value } }"
      ],
      {
        variables: {
          filter: {
            integrationTypeCode: "UPDATE",
            subtype: "DESIGN_PART"
          }
        }
      }
    );

    let subMenuItems = [];

    if (loading) {
      subMenuItems.push(
        <MenuItem
          key="integration-menu-loading"
          text="Loading update part hooks..."
          disabled
        />
      );
    } else if (!integrations.length) {
      // TODO: maybe add a link 'Settings > Integrations' to where the user can set one up
      subMenuItems.push(
        <MenuItem
          key="integration-menu-no-part-hooks"
          text="No update part hooks"
          disabled
        />
      );
    } else {
      subMenuItems = integrations.map((integration, idx) => {
        return (
          <MenuItem
            key={`integration-menu-item-${idx}`}
            text={integration.name}
            onClick={() => {
              const state = store.getState();

              const layout = treeLayout(state);

              if (layout !== "classic") {
                window.toastr.warning(
                  "Cannot run Update Parts Tool in hierarchical mode." // yet
                );
                return;
              }

              const designLocked = isDesignLocked(state);
              if (designLocked) {
                window.toastr.warning(
                  "Cannot run Update Part Tool when design is locked."
                );
                return;
              }
              const design = getDesign(state);
              const designElements = getElements(state);

              const refetch = async () => {
                store.dispatch(
                  actions.ui.designEditor.general.syncDesignState(design.id)
                );
              };

              return showDialog({
                modalProps: {
                  records: designElements,
                  integrationId: integration.id,
                  // Throughout the DESIGN_PART subtype UPDATE hook the "element" DM entity
                  // will also be known as "design part" as a more user friendly name.
                  model: "element",
                  refetch
                },
                ModalComponent: UpdateExternalDialog
              });
            }}
          />
        );
      });
    }
    return (
      <MenuItem key="integration-menu" text="Update Parts">
        {subMenuItems}
      </MenuItem>
    );
  },
  shouldDismissPopover: false
};

class DesignEditorMenu extends React.Component {
  constructor(props) {
    super(props);
    const { commands } = props.editorContext;
    this.cmdEnhancer = commandMenuEnhancer(commands, { useTicks: true });

    this.menuDef = [
      {
        text: "File",
        submenu: [
          {
            text: "Save Design",
            disabled: "The design is autosaved after every edit action"
          },
          "duplicate",
          "clearDesign",
          "deleteDesign",
          "renameDesign",
          // { text: 'Set Read Only' },
          "createDesignFromTemplate",
          "--",
          {
            text: "Export",
            submenu: [
              {
                cmd: "exportToJson",
                text: "JSON"
              },
              {
                cmd: "exportToCsvGenbank",
                text: "CSV + Genbank"
              }
              // { text: 'XML file' }
            ]
          },
          {
            cmd: "savePDF"
          }

          // { text: 'Import Design' },
          // {
          //   text: 'Eugene Rules',
          //   submenu: [
          //     { text: 'Import Eugene Rules' },
          //     { text: 'Export Eugene Rules' }
          //   ]
          // }
        ]
      },
      {
        text: "Edit",
        submenu: [
          "undo",
          "redo",
          "--",
          "convertToListLayout",
          "--",
          {
            text: "Validation Settings",
            submenu: [
              {
                text: "Disable Same Bin Digest Validation",
                cmd: "toggleDisableSameBinDigestValidation"
              }
            ]
          },
          "--",
          "clearParts",
          "removeBins",
          "removeRows",
          "cutPart",
          "copyPart",
          "pastePart",
          {
            text: "Insert",
            submenu: [
              {
                text: "Row Above",
                cmd: "insertRowAbove"
              },
              {
                text: "Row Below",
                cmd: "insertRowBelow"
              },
              {
                text: "Bin Left",
                cmd: "insertBinLeft"
              },
              {
                text: "Bin Right",
                cmd: "insertBinRight"
              }
            ]
          }
        ]
      },
      {
        text: "View",
        submenu: [
          "zoomIn",
          "zoomOut",
          "--",
          "toggleSbolRibbon",
          "toggleInspectorPanel",
          { text: "View DNA Assembly Reports for this Design", disabled: true },
          "showMinimap",
          "showRowsIndex",
          "showRowNames",
          { cmd: "showRowDisable", text: "Show Row Enable/Disable" },
          ...(!window.frontEndConfig.disabledToolkits.advancedMolecularBiology
            ? [
                {
                  text: "View Mode",
                  submenu: [
                    "setClassicTreeStyle",
                    "setHorizontalTreeStyle",
                    "setVerticalTreeStyle"
                  ]
                }
              ]
            : []),
          "--",
          ...(!window.frontEndConfig.disabledToolkits.advancedMolecularBiology
            ? [{ cmd: "designRulesets", text: "View Design Rulesets Library" }]
            : [])
        ]
      },
      {
        text: "Tools",
        submenu: [updatePartsMenuItem]
      },
      {
        text: "Help",
        submenu: [
          { isMenuSearch: true },
          {
            text: "Load Example Design",
            submenu: Object.keys(exampleDesignMap).map(name =>
              camelCase("load" + name)
            )
          }
        ]
      }
    ];

    if (window.frontEndConfig.atLanzatech) {
      const toolsDefIndex = findIndex(
        this.menuDef,
        def => def.text === "Tools"
      );

      this.menuDef[toolsDefIndex].submenu.push({
        text: "Codon Optimizer",
        submenu: [
          {
            text: "Send to Crickit",
            cmd: "sendDesignToCrickit"
          }
        ]
      });
    }
  }

  render() {
    const {
      design,
      history,
      match,
      isLocked,
      designName,
      designId,
      setActivePanel,
      lockedMessage: _lockedMessage,
      lockMsgDescription,
      layout
    } = this.props;
    if (
      match.path.indexOf("template") === -1 &&
      this.menuDef[0].submenu.indexOf("createDesignFromTemplate") > -1
    ) {
      this.menuDef[0].submenu.splice(
        this.menuDef[0].submenu.indexOf("createDesignFromTemplate"),
        1
      );
    }

    const extraContent = [];

    let lockedMessage = _lockedMessage;
    if (isLocked && design.lockId && !lockedMessage) {
      lockedMessage = "This design has been locked.";
    }

    if (lockedMessage && isLocked) {
      extraContent.push(<div className="flex1" key="spacer1" />);
      extraContent.push(
        <Tooltip
          key="lockedMessageTooltip"
          target={
            <div
              className="is-locked-msg"
              style={{
                background: "#C23030",
                padding: "0.3em",
                color: "white",
                marginTop: 3,
                borderRadius: 4
              }}
              key="lockedMessage"
            >
              {lockedMessage}
            </div>
          }
          disabled={!lockMsgDescription}
          content={lockMsgDescription}
        ></Tooltip>
      );
    }
    extraContent.push(<div className="flex1" key="spacer3" />);

    if (!design.originalDesignId) {
      extraContent.push(
        <SubmitForAssemblyButton key="submitForAssemblyButton" />
      );
    } else {
      extraContent.push(
        <Button
          small
          text="View Current Version of Design"
          onClick={() => {
            history.push(modelNameToLink("design", design.originalDesignId));
          }}
          style={{
            marginRight: 10,
            marginTop: 3,
            marginBottom: 3,
            paddingLeft: 40,
            paddingRight: 40,
            whiteSpace: "nowrap",
            fontWeight: "normal"
          }}
        />
      );
    }
    extraContent.push(
      <Tooltip key="partSourceInLibrary-tooltip" content="View in Library">
        <Link
          key="partSourceInLibrary"
          to={`/designs?filters=id__equalTo__${design?.id}`}
        >
          <Button
            minimal
            style={{
              marginTop: 2,
              marginRight: 2
            }}
            icon="git-repo"
          ></Button>
        </Link>
      </Tooltip>
    );
    let menuDefToUse = [...this.menuDef];
    if (isLocked) {
      // cannot edit or load examples into a locked design
      menuDefToUse = menuDefToUse.filter(
        item => item.text !== "Edit" && item.text !== "Examples"
      );
    }

    if (layout === "classic") {
      // remove zoom commands in classic mode
      const viewSubMenuIndex = menuDefToUse.findIndex(
        item => item.text === "View"
      );
      const newViewSubMenu = { ...menuDefToUse[viewSubMenuIndex] };
      newViewSubMenu.submenu = newViewSubMenu.submenu.filter(item =>
        item.startsWith ? !item.startsWith("zoom") : true
      );
      menuDefToUse.splice(viewSubMenuIndex, 1, newViewSubMenu);
    }

    return (
      <MenuBar
        style={isMobile() ? { overflowX: "auto", overflowY: "hidden" } : {}}
        menu={menuDefToUse}
        enhancers={[this.cmdEnhancer]}
        extraContent={
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <GetDesignCreator {...{ designName, setActivePanel, designId }} />
            {extraContent}
          </div>
        }
      />
    );
  }
}

export default compose(
  withRouter,
  withQuery(["user", "id username"], {
    skip: () => !localStorage.getItem("userId"),
    options: () => ({
      variables: { id: localStorage.getItem("userId") }
    })
  }),

  connect(
    state => {
      const design = Object.values(get(state, "design.design"))[0];
      const lockedMessage = design.lockedMessage;
      const isLocked = isDesignLocked(state);
      const lockMsgDescription = design.lockMsgDescription;
      const updatedAt = design.updatedAt;
      const hasAutoDigestInDesign = isAssemblyMethodInDesign(
        state,
        "Auto Digest"
      );
      const numErrorsInDesign = getNumOfInvaliditiesInDesign(state);

      return {
        updatedAt,
        lockedMessage,
        isLocked,
        design,
        layout: treeLayout(state),
        designName: design.name,
        designId: design.id,
        lockMsgDescription,
        hasAutoDigestInDesign,
        numErrorsInDesign
      };
    },
    {
      setActivePanel: actions.ui.designEditor.inspector.setActivePanel
    }
  ),
  withEditorContext
)(DesignEditorMenu);

function GetDesignCreator({ designName, setActivePanel, designId }) {
  const { design, ...rest } = useTgQuery(
    [
      "design",
      `id name  user {
      id
      username
    }`
    ],
    { variables: { id: designId } }
  );
  // we will handle loading in the button below
  if (rest.error && useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  const designCreator = get(design, "user.username", "Not Found");

  return (
    <Button
      className={"tg-design-creator-btn"}
      loading={rest.loading}
      onClick={() => {
        setActivePanel({ panel: "info", shouldOpen: "open" });
      }}
      minimal
    >
      {designName} created by {designCreator}
    </Button>
  );
}
