/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, ReactSelectField } from "@teselagen/ui";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import withQuery from "../../../../src-shared/withQuery";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import { wrapDialog } from "@teselagen/ui";

class ApplyTagsDialog extends React.Component {
  render() {
    const { hideModal, handleSubmit, tags, onSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <ReactSelectField
            name="tagIds"
            label="Apply Tags"
            options={arrayToIdOrCodeValuedOptions(tags)}
            multi
          />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" />
      </form>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Apply Tags",
    style: { width: 350 }
  }),
  withQuery(["tag", "id name"], { isPlural: true }),
  reduxForm({
    form: "applyTagsDialog"
  })
)(ApplyTagsDialog);
