/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState, useEffect, useRef } from "react";
import { Classes, Colors } from "@blueprintjs/core";
import { useTgQuery, safeQuery } from "../../apolloMethods";
import {
  InputField,
  DataTable,
  withSelectedEntities,
  mergeSchemas,
  DialogFooter,
  Loading
} from "@teselagen/ui";
import { hideDialog } from "../../GlobalDialog";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import { find, get } from "lodash";
import modelNameToReadableName from "../../../../tg-iso-shared/src/utils/modelNameToReadableName";
import integrationTypeSettingsMap, {
  cursorPagingMode
} from "../../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import { startImportCollection } from "../../utils/importCollection";
import { difference } from "lodash";
import { wrapDialog } from "@teselagen/ui";
import { getFormInputsUi } from "../getFormInputsUi";
import { Link } from "react-router-dom";
import { useIntegrationQuery } from "../useIntegrationQuery";
import upsertHandlers from "../upsertHandlers";
import { allSubtypesToModel } from "../../../../tg-iso-shared/src/utils/integrationTypeSettingsMap/importExportSubtypes";
import { getRecordsForCreate } from "../../../../tg-iso-shared/src/crudHandlers/createUtils";
import { getImportResultSchema } from "../utils";
import { externalRecordIdentifierModelsNoUpdate } from "../../../../tg-iso-shared/constants";
import deleteBeforeReimportHandlers from "../deleteBeforeReimportHandlers";

export const dupMessage = "This duplicate was not reimported.";
const form = "ImportFromExternalDbDialog";
const {
  IMPORT: {
    endpoints: {
      IMPORT__SEARCH_FORMAT: { method: methodSearchFormat },
      IMPORT__SEARCH: { method: methodSearch },
      IMPORT__IMPORT: { method: methodImport }
    }
  }
} = integrationTypeSettingsMap;

const ImportFromExternalDbDialog = ({
  integrationId,
  submitting,
  refetch = () => {},
  afterRecordUpsert = () => {},
  onFinish = () => {},
  allowContinueWithoutImporting,
  importSubtype,
  handleSubmit
}) => {
  if (!importSubtype)
    throw new Error(
      "Must pass an importSubtype to the Import from external dialog!"
    );
  // isFormatLoaded can either be
  // false=not loaded
  // true=loaded but no special formatting
  // [...formInputs]=loaded as an array of formInputs
  const [importCollectionId, setImportCollectionId] = useState(null);
  const [isFormatLoaded, setFormatLoaded] = useState();
  const [isSearchSubmitted, setSearchSubmitted] = useState();
  const [importResults, setImportResults] = useState();
  const { integration, ...queryRest } = useIntegrationQuery({ integrationId });

  useEffect(() => {
    (async () => {
      if (!integration) return;
      setFormatLoaded(false);
      try {
        let hasSpecialFormat = false;

        for (const ep of integration.integrationEndpoints) {
          if (ep.endpointTypeCode === "IMPORT__SEARCH_FORMAT" && ep.url) {
            hasSpecialFormat = true;

            const res = await window.triggerIntegrationRequest({
              method: methodSearchFormat,
              endpointId: ep.id,
              ...oligoHeader
            });
            setFormatLoaded({ ...res.data, endpointId: ep.id });
          }
        }
        if (!hasSpecialFormat) {
          setFormatLoaded(true);
        }
      } catch (error) {
        window.toastr.error(
          "There was an error getting the search format. See console for more details"
        );
        console.error(
          `23r82h Error - There was an error getting the search format. The integration endpoint has not been set up properly. Contact an admin for help. Error details:`,
          error
        );
        setFormatLoaded(true);
      }
    })();
  }, [integration]);
  if (useTgQuery.checkErrAndLoad(queryRest))
    return useTgQuery.handleErrAndLoad({ ...queryRest, inDialog: true });

  const onSubmit = async ({ selectedIntegration, ...rest }) => {
    setSearchSubmitted({ ...rest });
  };

  let inner;
  if (importResults) {
    inner = (
      <ImportResultsPage
        onFinish={onFinish}
        importCollectionId={importCollectionId}
        integration={integration}
        importResults={importResults}
        refetch={refetch}
      />
    );
  } else if (isSearchSubmitted) {
    inner = (
      <SearchResultsPage
        setImportCollectionId={setImportCollectionId}
        importSubtype={importSubtype}
        afterRecordUpsert={afterRecordUpsert}
        formInputs={isSearchSubmitted}
        integration={integration}
        setSearchSubmitted={setSearchSubmitted}
        setImportResults={setImportResults}
        allowContinueWithoutImporting={allowContinueWithoutImporting}
        submitting={submitting}
        refetch={refetch}
        handleSubmit={handleSubmit}
      />
    );
  } else {
    inner = (
      <>
        <div className={Classes.DIALOG_BODY}>
          {isFormatLoaded === true ? (
            //loaded but no special formatting found
            <InputField label="Search Term" isRequired name="searchTerm" />
          ) : isFormatLoaded ? (
            isFormatLoaded.noSearchInput ? (
              onSubmit({ noSearchInput: true })
            ) : (
              //loaded and special formatting found
              getFormInputsUi(isFormatLoaded.formInputs, {
                endpointId: isFormatLoaded.endpointId,
                formName: form
              })
            )
          ) : isFormatLoaded === false ? (
            //not yet loaded
            <Loading loading bounce />
          ) : null}
        </div>
        <DialogFooter
          disabled={!isFormatLoaded}
          secondaryAction={hideDialog}
          text="Search"
          submitting={submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </>
    );
  }
  return inner;
};

export default compose(
  wrapDialog({
    title: "Import From External Database",
    style: { width: 1000 }
  }),
  reduxForm({
    form
  }),
  tgFormValues("selectedIntegration")
)(ImportFromExternalDbDialog);

const schema = [
  {
    path: "name",
    displayName: "name",
    filterDisabled: true,
    sortDisabled: true
  },
  "size",
  {
    path: "id",
    displayName: "External ID",
    filterDisabled: true,
    sortDisabled: true
  },
  {
    path: "description",
    displayName: "description",
    filterDisabled: true,
    sortDisabled: true
  }
];
const oligoHeader = {
  headers: {
    "x-tg-allow-oligos": true
  }
};

let SearchResultsPage = ({
  integration,
  formInputs,
  setSearchSubmitted,
  refetch,
  importSubtype,
  submitting,
  setImportResults,
  handleSubmit,
  afterRecordUpsert,
  allowContinueWithoutImporting,
  setImportCollectionId,
  seqsToImportTableSelectedEntities,
  duplicateRecordsTableSelectedEntities = []
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [responseData, setRes] = useState(true);
  const [duplicateRecords, setDuplicateRecords] = useState(null);
  const [recordsToImport, setRecordsToImport] = useState(null);
  const [errorResults, setErrorResults] = useState(null);

  const model = allSubtypesToModel[importSubtype];
  const checkForDuplicateRecords = async () => {
    const endpoint = find(
      integration.integrationEndpoints,
      ({ endpointTypeCode }) => endpointTypeCode === "IMPORT__IMPORT"
    );

    const errorResults = [];
    const records = [];

    for (const seqToImport of seqsToImportTableSelectedEntities) {
      try {
        const params = Object.assign(
          { id: seqToImport.id },
          seqToImport._additional_import_params
        );
        const res = await window.triggerIntegrationRequest({
          showToastrError: false,
          endpointId: endpoint.id,
          params: params,
          method: methodImport,
          oligoHeader
        });
        const record = res.data;
        delete record.id; //we don't want any id returned from the import (because it could make our subsequent duplication checking logic confusing)
        records.push(record);
      } catch (e) {
        console.error("error 928h3982fw:", e);
        let __importFailed =
          "The external endpoint returned an invalid reponse. Please verify the endpoint is returning data in the expected format";
        if (e.__type === "TG_INTEGRATION_ERROR") {
          __importFailed = e.__message;
        }
        errorResults.push({
          ...seqToImport,
          __importFailed: __importFailed
        });
      }
    }

    if (errorResults.length && !records.length) {
      return setImportResults(errorResults);
    }

    if (records.length) {
      try {
        const {
          duplicatedOnExternalIdRecords,
          nonDuplicateRecords,
          invalidRecords
        } = await getRecordsForCreate(
          { records, subtype: importSubtype },
          { safeQuery }
        );
        errorResults.push(...invalidRecords);

        // if there are duplicates stop early
        if (duplicatedOnExternalIdRecords.length) {
          setDuplicateRecords(duplicatedOnExternalIdRecords);
          setRecordsToImport(nonDuplicateRecords);
          setErrorResults(errorResults);
          return;
        } else {
          return handleImportOnSubmit(nonDuplicateRecords, errorResults);
        }
      } catch (error) {
        const __importFailed = `There was an error checking for duplicate records. ${
          error.message || ""
        }`;
        console.error("error:", error);
        return setImportResults(
          records.map(record => {
            return {
              ...record,
              __importFailed
            };
          })
        );
      }
    }
  };

  const handleDuplicateSubmit = async () => {
    // this concat order matters
    let duplicatesToKeep = duplicateRecordsTableSelectedEntities;

    if (externalRecordIdentifierModelsNoUpdate.includes(model)) {
      const handler = deleteBeforeReimportHandlers[importSubtype];
      // start the import/update
      await handler({
        duplicateRecords: duplicatesToKeep,
        model
      });
      duplicatesToKeep = duplicatesToKeep.map(dup => {
        const cleaned = { ...dup };
        delete cleaned.id;
        delete cleaned.__oldRecord;
        delete cleaned.__newRecord;
        return cleaned;
      });
    }
    const allRecordsToImport = duplicatesToKeep.concat(recordsToImport);
    if (!allRecordsToImport.length && !allowContinueWithoutImporting) {
      return window.toastr.error(
        `There are no ${modelNameToReadableName(model, {
          plural: true
        })} to import.`
      );
    }
    const duplicatesThatWereIgnored = difference(
      duplicateRecords,
      duplicateRecordsTableSelectedEntities
    );
    const newErrorResults = (errorResults || []).concat(
      duplicatesThatWereIgnored.map(dup => {
        return {
          ...dup,
          __importFailed: dupMessage
        };
      })
    );
    return handleImportOnSubmit(allRecordsToImport, newErrorResults);
  };

  const handleImportOnSubmit = async (recordsToImport, errorResults) => {
    const importResults = errorResults || [];
    if (recordsToImport.length) {
      let _markCollectionAsFailed;
      try {
        const importCollectionName = modelNameToReadableName(model, {
          upperCase: true,
          plural: true
        });

        // startImportCollection
        const { markCollectionAsFailed, importCollectionId } =
          await startImportCollection(importCollectionName);
        _markCollectionAsFailed = markCollectionAsFailed;
        //  link to it in the success page of the dialog
        setImportCollectionId(importCollectionId);

        //tnr: we could swap out individual handlers if we wanted to in the future to reuse our existing import logic:
        // if (importSubtype === 'DNA_SEQUENCE') {//add existing custom dna seq import handler here}

        // get the appropriate import/update handler
        const handler = upsertHandlers[importSubtype];
        // start the import/update
        await handler({
          recordsToImport,
          model
        });
        recordsToImport.forEach(r => {
          importResults.push({ ...r.__oldRecord, ...r });
        });
      } catch (error) {
        let __importFailed = `There was an error parsing the records from the external database. ${
          error.message || ""
        }`;
        if (error && error.type === "uploadStrainHelperError") {
          __importFailed = error.message;
        }
        if (error && error.type === "uploadMicrobialMaterialHelperError") {
          __importFailed = error.message;
        }
        // mark the ImportCollection as failed on fail
        _markCollectionAsFailed && _markCollectionAsFailed(__importFailed);
        recordsToImport.forEach(record => {
          importResults.push({
            ...record,
            __importFailed
          });
        });
        console.error("error:", error);
      }
    }
    importResults.sort(({ __importFailed }) => {
      if (__importFailed) return -1;
      else return 1;
    });

    await afterRecordUpsert(importResults);
    setImportResults(importResults);

    await refetch();
  };
  const tokens = useRef();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      // You can await here
      try {
        const endpoint = find(
          integration.integrationEndpoints,
          ({ endpointTypeCode }) => endpointTypeCode === "IMPORT__SEARCH"
        );

        let pageOrToken;
        if (tokens.current && tokens.current[page - 1]) {
          pageOrToken = { pageToken: tokens.current[page - 1] };
        } else {
          pageOrToken = { pageNumber: page };
        }
        const res = await window.triggerIntegrationRequest({
          data: {
            searchTerm: formInputs.searchTerm,
            customFields: formInputs,
            ...pageOrToken
          },
          method: methodSearch,
          endpointId: endpoint.id,
          ...oligoHeader
        });
        if (res.data.pagingMode === "cursor") {
          if (!tokens.current) {
            tokens.current = [undefined];
          }

          tokens.current[page] = res.data.nextToken;
          res.data.pageNumber = page;
        }
        setRes(res.data);
      } catch (e) {
        console.error(`error 716j9k827918:`, e);
      } finally {
        setLoading(false);
      }
      // ...
    }
    fetchData();
  }, [page, integration, formInputs]);

  if (duplicateRecords) {
    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          <div style={{ marginBottom: 15 }}>
            This import had {duplicateRecords.length}{" "}
            {modelNameToReadableName(model, {
              plural: duplicateRecords.length > 1
            })}{" "}
            which {duplicateRecords.length > 1 ? "have" : "has"} already been
            imported.{" "}
            {externalRecordIdentifierModelsNoUpdate.includes(model) ? (
              <span>
                Choose any you would like to{" "}
                <span style={{ color: Colors.RED4 }}>delete</span> and reimport.
                Otherwise continue without reimporting.
              </span>
            ) : (
              `Choose any you would like to reimport/update, or continue
              without reimporting. (Not all fields can be updated. Invalid fields
              will be stripped.)`
            )}
          </div>
          <DataTable
            disabled={submitting}
            withCheckboxes
            maxHeight={400}
            formName="duplicateRecordsTable"
            entities={duplicateRecords}
            schema={[
              {
                path: "name",
                render: (v, r) => v || get(r, "__oldRecord.name")
              }
            ]}
            noPadding
            isSimple
            compact
          />
        </div>
        <DialogFooter
          hideModal={hideDialog}
          text="Continue Import"
          submitting={submitting}
          onClick={handleSubmit(handleDuplicateSubmit)}
        />
      </>
    );
  } else {
    const pageSize =
      responseData.pagingMode === cursorPagingMode
        ? (Array.isArray(responseData.results) ? responseData.results : [])
            .length
        : responseData.pageSize;
    const entities = Array.isArray(responseData.results)
      ? responseData.results
      : [];

    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          <DataTable
            withCheckboxes
            maxHeight={400}
            isLoading={loading}
            formName="seqsToImportTable"
            entities={entities}
            // entities={[]}
            schema={mergeSchemas(
              schema,
              responseData.additionalFieldsToDisplay || []
            )}
            noPadding
            withPaging
            controlledPaging
            hideTotalPages={responseData.pagingMode === cursorPagingMode}
            controlled_hasNextPage={
              responseData.pagingMode === cursorPagingMode
                ? responseData.nextToken
                : undefined
            }
            keepSelectionOnPageChange
            hideSelectedCount={false}
            controlled_total={
              responseData.pagingMode === cursorPagingMode
                ? undefined
                : responseData.totalResults
            }
            pagingDisabled={loading}
            controlled_page={page || 1}
            controlled_pageSize={pageSize}
            controlled_setPage={setPage}
            hideSetPageSize
            isSimple
            compact
          />
        </div>
        <DialogFooter
          onBackClick={
            formInputs.noSearchInput
              ? undefined
              : () => {
                  setSearchSubmitted(false);
                }
          }
          secondaryAction={hideDialog}
          disabled={!seqsToImportTableSelectedEntities.length}
          text="Import"
          submitting={submitting}
          onClick={handleSubmit(checkForDuplicateRecords)}
        />
      </>
    );
  }
};

SearchResultsPage = compose(
  withSelectedEntities("seqsToImportTable"),
  withSelectedEntities("duplicateRecordsTable")
)(SearchResultsPage);

function ImportResultsPage({ importResults, importCollectionId, onFinish }) {
  const ents = Array.isArray(importResults) ? importResults : [];
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <div>
          {importCollectionId ? (
            <Link to={`/import-collections/${importCollectionId}`}>
              View Import Collection
            </Link>
          ) : null}
        </div>
        <DataTable
          maxHeight={400}
          formName="seqsToImportTable"
          entities={ents}
          schema={getImportResultSchema(ents)}
          isSimple
          compact
        />
      </div>
      <DialogFooter
        text="Finish"
        secondaryAction={hideDialog}
        onClick={() => {
          onFinish(importResults);
          hideDialog();
        }}
      />
    </React.Fragment>
  );
}

// function addExternalUrls(seqData, externalRecordType) {
//   try {
//     const ident = {
//       externalRecordTypeId: externalRecordType.id
//     };
//     if (externalRecordType.identifierTypeCode === "FULL_URL") {
//       ident.url = seqData.externalUrl;
//     } else if (externalRecordType.identifierTypeCode === "NUMERIC_VALUE") {
//       ident.numericValue = Number(seqData.id);
//     } else {
//       ident.value = seqData.id + "";
//     }
//     return {
//       ...seqData
//       // externalRecordIdentifiers: [ident]
//     };
//   } catch (error) {
//     console.error(`error adding externalRecordIdentifiers:`, error);
//     return seqData;
//   }
// }
