/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { difference, get } from "lodash";
import {
  safeUpsert,
  safeDelete,
  deleteWithQuery
} from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";
import { createNotification } from "../../../../src-shared/utils/createNotification";

export async function onSubmitCreate(_values, props) {
  const { history, hideModal } = props;
  const values = {
    ..._values
  };
  const customers =
    values.customers &&
    values.customers.map(customer => ({
      userId: customer.id
    }));
  values.requestTypeId = values.requestType.id;
  delete values.requestType;
  values.customerRequestStrains = values.strains?.map(strain => ({
    strainId: strain.id
  }));
  delete values.strains;
  values.customerRequestSequences = values.sequences?.map(sequence => ({
    sequenceId: sequence.id
  }));
  delete values.sequences;

  const upsertCustomerRequestInput = {
    ...values,
    customers
  };
  if (upsertCustomerRequestInput.primaryCustomer) {
    upsertCustomerRequestInput.primaryCustomer = {
      userId: upsertCustomerRequestInput.primaryCustomer.id
    };
  }
  // return;
  try {
    const res = await safeUpsert(
      ["customerRequest", `id name`],
      upsertCustomerRequestInput
    );
    const record = res[0];

    const newNotifications = [];
    if (customers) {
      customers.forEach(customer => {
        newNotifications.push({
          userId: customer.userId,
          message: `You have been added to user request '${record.name}'`,
          notificationIntent: "primary",
          link: `/user-requests/${record.id}`,
          notificationTypeCode: "ADDED_TO_USER_REQUEST"
        });
      });
      await createNotification(newNotifications);
    }

    const route = "/user-requests/" + record.id;
    history.push(route);
    hideModal();
  } catch (error) {
    console.error("err:", error);
    window.toastr.error("Error creating user request");
  }
}

export async function onSubmitEdit(_values, props) {
  let values = { ..._values };
  const {
    initialValues,
    initialCustomers,
    initialPrimaryCustomer,
    refetch,
    hideModal
  } = props;

  const promises = [];

  const removedCustomerUserIds = difference(
    initialValues.customers.map(c => c.id),
    values.customers.map(c => c.id)
  );
  const addedCustomerUserIds = difference(
    values.customers.map(c => c.id),
    initialValues.customers.map(c => c.id)
  );
  if (removedCustomerUserIds.length) {
    const customerIdsToDelete = initialCustomers.reduce(
      (acc, { id, userId }) => {
        if (removedCustomerUserIds.indexOf(userId) > -1) acc.push(id);
        return acc;
      },
      []
    );
    promises.push(safeDelete("customer", customerIdsToDelete));
  }
  promises.push(
    deleteWithQuery("customerRequestStrain", {
      customerRequestId: initialValues.id
    })
  );
  promises.push(
    deleteWithQuery("customerRequestSequence", {
      customerRequestId: initialValues.id
    })
  );
  promises.push(
    safeUpsert(
      "customerRequestStrain",
      values.strains.map(strain => ({
        customerRequestId: initialValues.id,
        strainId: strain.id
      }))
    )
  );
  promises.push(
    safeUpsert(
      "customerRequestSequence",
      values.sequences.map(sequence => ({
        customerRequestId: initialValues.id,
        sequenceId: sequence.id
      }))
    )
  );

  const createCustomerInput = addedCustomerUserIds.map(userId => ({
    customerRequestId: initialValues.id,
    userId
  }));

  const newNotifications = [];
  if (addedCustomerUserIds) {
    addedCustomerUserIds.forEach(customerUserId => {
      newNotifications.push({
        userId: customerUserId,
        message: `You have been added to user request '${initialValues.name}'`,
        notificationIntent: "primary",
        link: `/user-requests/${initialValues.id}`,
        notificationTypeCode: "ADDED_TO_USER_REQUEST"
      });
    });
    await createNotification(newNotifications);
  }

  if (createCustomerInput.length) {
    promises.push(safeUpsert("customer", createCustomerInput));
  }

  values = stripFields(values, ["customers"]);
  delete values.sequences;
  delete values.strains;

  if (
    values.primaryCustomer &&
    values.primaryCustomer !== get(initialPrimaryCustomer, "userId")
  ) {
    values.primaryCustomer = {
      id: initialPrimaryCustomer.id,
      userId: values.primaryCustomer.id
    };
  } else {
    delete values.primaryCustomer;
  }

  promises.push(safeUpsert("customerRequest", values));

  try {
    await Promise.all(promises);
    await refetch();
    hideModal();
  } catch (err) {
    console.error("err:", err);
    window.toastr.error("Error editing user request");
  }
}
