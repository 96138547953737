/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  ChangeSetsHelper,
  removeItemsAndShallowReferences
} from "../../../../../tg-iso-design/utils/designEditUtils";

export default function removeElements(state, elementIds, _changeSetsHelper) {
  if (!Array.isArray(elementIds)) elementIds = [elementIds];

  const changeSetsHelper = _changeSetsHelper || new ChangeSetsHelper(state);
  removeItemsAndShallowReferences(
    state,
    "element",
    elementIds,
    changeSetsHelper,
    [
      "eugeneRuleElement",
      "elementElementCombo",
      "elementGroupElementGroupCombo"
    ]
  );
  if (!_changeSetsHelper)
    return changeSetsHelper.execute({
      removeInaccessibleItems: true,
      recomputeBinValidation: true,
      recomputeDigestFasValidation: true,
      updateInvalidMaterialAvailabilities: true
    });
}
