/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import UploadFiles, { integrationFragment } from "./UploadFiles";
import ReviewWorklists from "./ReviewWorklists";
import { forEach } from "lodash";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import shortid from "shortid";
import { isEmpty } from "lodash";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { withProps } from "recompose";
import fieldConstants from "./fieldConstants";
import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";

// const headers = [
//   "source plate barcode",
//   "source tube barcode",
//   "source well",
//   "destination plate barcode",
//   "destination tube barcode",
//   "destination well",
//   "transfer volume",
//   "transfer volume unit"
// ];

function CreateCustomWorklistTool(props) {
  const {
    initialValues,
    toolSchema,
    toolIntegrationProps,
    isToolIntegrated
  } = props;

  const steps = [
    {
      title: "Upload Files",
      Component: UploadFiles,
      withCustomFooter: true
    },
    {
      title: "Review Worklists",
      Component: ReviewWorklists,
      withCustomFooter: true
    }
  ];

  const onSubmit = async values => {
    try {
      const {
        allTransfers,
        worklistNames,
        useIntegration,
        platesToCreate = [],
        barcodeUpdates = []
      } = values;
      const newWorklists = [];

      let destinationPlates = [];
      const destinationTubes = [];
      if (useIntegration) {
        if (platesToCreate.length) {
          let fragment = "id cid";
          const needsTubeBarcodes = {};
          const cleanedPlatesToCreate = platesToCreate.map(p => {
            const c = {
              ...p
            };
            if (!c.cid) {
              c.cid = shortid();
            }
            if (!c.containerArrayType.isPlate) {
              needsTubeBarcodes[c.cid] = true;
            }
            delete c.containerArrayType;
            return c;
          });
          if (!isEmpty(needsTubeBarcodes)) {
            fragment += " aliquotContainers { id }";
          }
          const plates = await safeUpsert(
            ["containerArray", fragment],
            cleanedPlatesToCreate
          );
          destinationPlates = plates;
          if (needsTubeBarcodes) {
            let allTubes = [];
            plates.forEach(p => {
              if (needsTubeBarcodes[p.cid]) {
                allTubes = allTubes.concat(p.aliquotContainers);
              }
            });
            await addBarcodesToRecords(allTubes);
          }

          await safeUpsert("barcode", barcodeUpdates);
        }
      }
      const destPlateIds = [];
      const destTubeIds = [];
      forEach(allTransfers, (transfers, key) => {
        newWorklists.push({
          name: worklistNames[key],
          worklistTransfers: transfers.map(t => {
            const cleaned = { ...t };
            if (t.destinationAliquotContainer) {
              const plate = t.destinationAliquotContainer.containerArray;
              if (plate) {
                if (plate.id && !destPlateIds.includes(plate.id)) {
                  destPlateIds.push(plate.id);
                  destinationPlates.push({
                    id: plate.id,
                    __typename: "containerArray"
                  });
                }
              } else {
                const tubeId = t.destinationAliquotContainer.id;
                if (!destTubeIds.includes(tubeId)) {
                  destTubeIds.push(tubeId);
                  destinationTubes.push({
                    id: tubeId,
                    __typename: "aliquotContainer"
                  });
                }
              }
            }
            delete cleaned.sourceAliquotContainer;
            delete cleaned.destinationAliquotContainer;
            return cleaned;
          })
        });
      });
      const worklists = await safeUpsert("worklist", newWorklists);
      return {
        worklists,
        containerArrays: destinationPlates,
        aliquotContainers: destinationTubes
      };
    } catch (error) {
      console.error("err:", error);
      throwFormError("Error creating worklist.");
    }
  };

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize={isToolIntegrated}
      initialValues={initialValues}
    />
  );
}

export default compose(
  withWorkflowInputs(integrationFragment, {
    singular: true
  }),
  withWorkflowInputs(containerArrayPlatePreviewFragment, {
    inputName: "sourcePlates"
  }),
  withWorkflowInputs(containerArrayPlatePreviewFragment, {
    inputName: "destinationPlates"
  }),
  withWorkflowInputs(["reactionMap", "id name"], {
    singular: true
  }),
  withProps(({ initialValues: _iv }) => {
    let initialValues = {
      ..._iv
    };
    if (
      initialValues.integration ||
      initialValues.destinationPlates ||
      initialValues.reactionMap ||
      initialValues.sourcePlates
    ) {
      initialValues = {
        ...initialValues,
        useIntegration: true
      };
    }
    if (initialValues[fieldConstants.universalTransferVolume]) {
      initialValues = {
        ...initialValues,
        useUniversalVolume: true
      };
    }
    return {
      initialValues
    };
  })
)(CreateCustomWorklistTool);

function validate(values) {
  const {
    sourcePlates = [],
    sourceTubes = [],
    destinationPlates = [],
    destinationTubes = []
  } = values;
  const errors = {};
  if (!sourcePlates.length && !sourceTubes.length) {
    errors.sourcePlates = errors.sourceTubes =
      "Please select a source plate or tube.";
  }
  if (!destinationPlates.length && !destinationTubes.length) {
    errors.destinationPlates = errors.destinationTubes =
      "Please select a destination plate or tube.";
  }
  return errors;
}
