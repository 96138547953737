/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";
import { safeUpsert } from "../apolloMethods";
import axios from "axios";

class CreatePublicationTargetDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop } = this.props;
    const { id: updatedId, name, baseUrl, authKey, authToken } = values;
    try {
      // check account validity by getting a jwt token if same domain
      if (window.location.href.startsWith(baseUrl)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios({
          method: "PUT",
          url: `${baseUrl}/tg-api/public/auth`, // this will move to /api/public/auth
          data: JSON.stringify({
            username: authKey,
            password: authToken,
            expiresIn: "1m"
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });
      }

      try {
        await safeUpsert(
          ["publicationTarget", "id name, baseUrl authKey authToken"],
          {
            id: updatedId,
            name,
            baseUrl,
            authKey,
            authToken
          }
        );
        await refetch();
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error creating publication target.");
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        window.toastr.error(`Invalid service account or auth token provided`);
      } else {
        window.toastr.error("Invalid URL provided");
      }
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            placeholder="Enter name for target..."
          />
          <InputField
            name="baseUrl"
            label="Base URL"
            isRequired
            placeholder="Enter base URL..."
          />
          <InputField
            name="authKey"
            label="Service Account"
            isRequired
            placeholder="Enter service account..."
          />
          <InputField
            name="authToken"
            label="Auth Token"
            type="password"
            isRequired
            placeholder="Enter auth token..."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreatePublicationTargetWithDialog = compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Publication Target"
          : "Create Publication Target"
      };
    }
  }),
  reduxForm({
    form: "createPublicationTarget"
  })
)(CreatePublicationTargetDialog);

export default CreatePublicationTargetWithDialog;
