/**
 * For the subset of aliquot formulations that provide a destination aliquot container,
 * make sure that each of the aliquot containers is associated with exactly one aliquot
 * formulation. In other words, make sure that no two aliquot formulations have the same
 * non-null aliquot container.
 * @param {Array<FullFormulation>} fullFormulations The full formulation objects to check.
 * @returns {boolean}
 */
function areDestinationAliquotContainersUnique(fullFormulations) {
  // Set of aliquot container ids.
  const acSet = {};

  for (const f of fullFormulations) {
    const acId = f.getAliquotContainerId();
    if (acId) {
      if (acSet[acId]) return false;
      acSet[acId] = true;
    }
  }

  return true;
}

export default areDestinationAliquotContainersUnique;
