/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const multiChannelTooltip =
  "Depending on selected orientation, transfers will be grouped by row or column.";

export const everyOtherTooltip = (operation, rowOrColumn) => {
  let plateType;
  if (operation === "breakdown") {
    plateType = "source";
  } else {
    plateType = "destination";
  }
  return `Transfers will skip every other ${rowOrColumn} on ${plateType} plate.`;
};
