/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getItemOfType,
  getSequenceStringOfElement,
  getSizeOfElement
} from "../../selectors/designStateSelectors";
import { startsWith, uniq } from "lodash";
import { ChangeSetsHelper } from ".";

/**
 * Given the design state, see which elements are valid and invalid based on
 * the validation from the bins.
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
export default function validateElementsFromBins(designState) {
  const state = { design: designState };
  const changeSetsHelper = new ChangeSetsHelper(designState);
  const elementIdToWarningMessages = {};
  const seqStrToElInfoMap = {};
  const elSizeToIdMap = {};
  const isListLayout = Object.values(designState.design).some(
    d => d.layoutType === "list"
  );
  for (const element of Object.values(designState.element)) {
    const elSize = getSizeOfElement(state, element.id);
    elSizeToIdMap[elSize] = elSizeToIdMap[elSize] || [];
    elSizeToIdMap[elSize].push({
      elementId: element.id,
      partId: element.partId,
      binId: element.binId
    });
  }

  for (const elInfo of Object.values(elSizeToIdMap)) {
    if (elInfo.length === 1) continue;

    elInfo.forEach(ei => {
      const elSeqStr = getSequenceStringOfElement(state, ei.elementId);
      seqStrToElInfoMap[elSeqStr] = seqStrToElInfoMap[elSeqStr] || [];
      seqStrToElInfoMap[elSeqStr].push(ei);
    });
  }

  for (const elementInfos of Object.values(seqStrToElInfoMap)) {
    const partElInfos = elementInfos.filter(ei => ei.partId);
    if (partElInfos.length === 1) continue;

    for (const repeatedElInfo of partElInfos) {
      const differentPartInfoWithSameSeq = partElInfos.find(info => {
        if (info.elementId !== repeatedElInfo.elementId) {
          // don't warn about different els in same bin with same part in List Layout
          if (
            isListLayout &&
            info.binId === repeatedElInfo.binId &&
            info.partId === repeatedElInfo.partId
          ) {
            return false;
          }
          return true;
        }
        return false;
      });

      if (!differentPartInfoWithSameSeq) continue;

      const differentElWithSameSeq = getItemOfType(
        state,
        "element",
        differentPartInfoWithSameSeq?.elementId
      );

      elementIdToWarningMessages[repeatedElInfo.elementId] =
        elementIdToWarningMessages[repeatedElInfo.elementId] || [];

      elementIdToWarningMessages[repeatedElInfo.elementId].push(
        `Another part (${
          differentElWithSameSeq
            ? differentElWithSameSeq.name
            : getItemOfType(state, "element", repeatedElInfo.elementId).name
        }) in this design has the same sequence as this part`
      );
    }
  }

  clearObsoleteInvalidityMsgs({
    allElements: Object.values(designState.element),
    elementIdToWarningMessages,
    changeSetsHelper
  });

  Object.entries(elementIdToWarningMessages).forEach(
    ([elementId, warningMessages]) => {
      const element = designState.element[elementId];
      const warningMessage = warningMessages.length
        ? uniq(warningMessages).join("\n")
        : null;
      changeSetsHelper.updatePure("element", {
        id: element.id,
        warningMessage
      });
    }
  );

  return changeSetsHelper.execute({
    removeCardInvalidityMessages: false
  });
}

function clearObsoleteInvalidityMsgs({
  allElements,
  elementIdToWarningMessages,
  changeSetsHelper,
  designState
}) {
  for (const element of Object.values(allElements)) {
    if (element.warningMessage && !elementIdToWarningMessages[element.id]) {
      // only clear "Another part..." related msgs
      const msgs = element.warningMessage
        .split("\n")
        .filter(msg => !startsWith(msg, "Another part"));
      changeSetsHelper.updatePure("element", {
        id: element.id,
        warningMessage: msgs.length === 0 ? null : msgs.join("\n")
      });
    }
  }
  return designState;
}
