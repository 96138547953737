/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { safeUpsert, safeDelete } from "../apolloMethods";
import { isLabAdmin } from "../utils/generalUtils";

export default ({ records, refetch }) => {
  const allLocked = records.every(r => r.lockId);
  if (!isLabAdmin()) return null;
  return (
    <MenuItem
      key="lockRecords"
      text={allLocked ? "Unlock" : "Lock"}
      icon={allLocked ? "unlock" : "lock"}
      onClick={async () => {
        try {
          /**
           * If all selected records are locked, and the user wants to unlock them all
           * then we can simply delete each of the lock records.
           *
           * If on the other hand, we need to lock those records that are not locked, then we create
           * a lock record for each of those.
           *
           * NOTE: this component has never been used but if/when eventually gets used, we still
           * need to handle the child locks as well.
           */
          if (allLocked) {
            await safeDelete(
              "lock",
              records.map(r => r.lockId)
            );
            // refetch needed bc apollo will no refresh
            // on its own given that the "lock" isn't the library model.
            refetch();
          } else {
            const model = records[0].__typename;
            const recordsToLock = records.filter(r => !r.lockId);
            // To lock each record, we need to create a unique lock record for each of them, so that the locks are independent.
            const lockRecords = await safeUpsert(
              "lock",
              recordsToLock.map(() => ({ isPermanent: false }))
            );
            // Once we have all the needed lock IDs, we update the records with them one by one.
            const recordLockIds = lockRecords.map(r => r.id);
            const recordsToLockWithLockId = recordsToLock.map((r, idx) => ({
              id: r.id,
              lockId: recordLockIds[idx]
            }));
            await safeUpsert([model, "id lockId"], recordsToLockWithLockId);
          }
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error changing lock");
        }
      }}
    />
  );
};
