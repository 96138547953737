/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const worklistPlanningReactionMapFragment = gql`
  fragment worklistPlanningReactionMapFragment on reactionMap {
    id
    name
    updatedAt
    reactions {
      id
      reactionOutputs {
        id
        outputMaterial {
          id
          name
        }
        outputAdditiveMaterial {
          id
          name
        }
      }
      reactionInputs {
        id
        inputMaterialId
        inputMaterial {
          id
          name
        }
        inputAdditiveMaterial {
          id
          name
        }
      }
    }
  }
`;

const worklistPlanningAdditiveFragment = gql`
  fragment worklistPlanningAdditiveFragment on additive {
    id
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    additiveMaterial {
      id
    }
    lot {
      id
      additiveMaterial {
        id
      }
    }
  }
`;

export const worklistPlanningTubeFragment = gql`
  fragment worklistPlanningTubeFragment on aliquotContainer {
    id
    name
    updatedAt
    barcode {
      id
      barcodeString
    }
    aliquotContainerTypeCode
    additives {
      ...worklistPlanningAdditiveFragment
    }
    aliquot {
      id
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      additives {
        ...worklistPlanningAdditiveFragment
      }
      sample {
        id
        materialId
        sampleFormulations {
          id
          materialCompositions {
            id
            materialId
          }
        }
      }
    }
  }
  ${worklistPlanningAdditiveFragment}
`;

export const worklistPlanningDataTableFragment = gql`
  fragment worklistPlanningDataTableFragment on dataTable {
    id
    name
    updatedAt
    dataRows {
      id
      rowValues
    }
  }
`;

export const worklistPlanningDataTableAliquotContainerFragment = gql`
  fragment worklistPlanningDataTableAliquotContainerFragment on aliquotContainer {
    ...worklistPlanningTubeFragment
    rowPosition
    columnPosition
    containerArray {
      id
      name
    }
  }
  ${worklistPlanningTubeFragment}
`;

export const worklistPlanningPlateFragment = gql`
  fragment worklistPlanningPlateFragment on containerArray {
    id
    name
    updatedAt
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      ...worklistPlanningTubeFragment
    }
  }
  ${worklistPlanningTubeFragment}
`;

export const worklistPlanningDestinationPlateFragment = gql`
  fragment worklistPlanningDestinationPlateFragment on containerArray {
    id
    name
    updatedAt
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      ...worklistPlanningTubeFragment
    }
  }
  ${worklistPlanningTubeFragment}
`;
