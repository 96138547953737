/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem, Tooltip, Icon } from "@blueprintjs/core";
import { MenuItemWithTooltip } from "@teselagen/ui";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import { deleteRecordsHelper } from "../../../utils";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";

import "./style.css";
import { get } from "lodash";
import isMobile from "is-mobile";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class LookupLibrary extends Component {
  contextMenu = ({ selectedRecords }) => {
    const {
      readOnly,
      show,
      lookupNewItemFunction,
      data: { refetch },
      selectTableRecords
    } = this.props;
    const systemTypeSelected = selectedRecords.some(r => r.isSystemType);
    const items = [];
    const record = selectedRecords[0];
    const model = record.__typename;
    if (model === "additiveType") {
      const culture = "Culture Media";
      const growth = "Growth Media";
      if (
        selectedRecords.length === 1 &&
        record.isSystemType &&
        [growth, culture].includes(record.name)
      ) {
        const newName = record.name === growth ? culture : growth;
        return [
          <MenuItemWithTooltip
            key="renameGrowthMedia"
            tooltip={`Can only be named "${growth}" or "${culture}"`}
            text={`Rename to "${newName}"`}
            onClick={async () => {
              try {
                await safeUpsert([model, "code name"], {
                  code: record.code,
                  name: newName
                });
              } catch (error) {
                console.error(`error:`, error);
                window.toastr.error("Error renaming");
              }
            }}
          />
        ];
      }
    }
    if (readOnly || systemTypeSelected) return;
    if (selectedRecords.length === 1) {
      items.push(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() => {
            lookupNewItemFunction(show, refetch, record, this.reselect(record));
          }}
          text="Edit"
        />
      );
    }
    items.push(
      <MenuItem
        key="delete"
        icon="trash"
        onClick={async () => {
          try {
            const typename = selectedRecords[0].__typename;
            const inUse = selectedRecords.some(record => {
              if (typename === "additiveType") {
                return (
                  record.additiveMaterials && record.additiveMaterials.length
                );
              }
              if (typename === "requestType") {
                return (
                  record.customerRequests && record.customerRequests.length
                );
              }
              if (typename === "reactionType") {
                return record.reactionMaps && record.reactionMaps.length;
              }
              if (typename === "containerFormat") {
                return (
                  (record.containerArrayTypes &&
                    record.containerArrayTypes.length) ||
                  (record.plateMapGroups && record.plateMapGroups.length)
                );
              }
              return (
                record[typename.replace("Type", "") + "s"] &&
                record[typename.replace("Type", "") + "s"].length
              );
            });
            if (inUse) {
              return window.toastr.error(
                `Cannot delete ${modelNameToReadableName(
                  typename
                )} that is in use.`
              );
            }
            await deleteRecordsHelper({
              records: selectedRecords,
              refetch: async () => {
                await refetch();
                selectTableRecords([]);
              }
            });
          } catch (error) {
            console.error("err:", error);
            window.toastr.warning(
              "Something went wrong while trying to delete. Please refresh the page and try again."
            );
          }
        }}
        text="Delete"
      />
    );
    return items;
  };

  onNewItemClick = () => {
    const {
      show,
      lookupNewItemFunction,
      data: { refetch }
    } = this.props;
    lookupNewItemFunction(show, refetch);
  };

  renderDetails() {
    const { selectedEntities = [], lookupEntityInfoFields = [] } = this.props;
    const selectedRecord = selectedEntities[0];

    let content;
    if (!selectedRecord) {
      content = `No ${modelNameToReadableName(
        this.props.tableLibraryName
      )} selected`;
    } else {
      const recordInfo = lookupEntityInfoFields.map(field => {
        let val;
        if (field.render) {
          val = field.render(selectedRecord);
        } else {
          val = get(selectedRecord, field.path);
        }
        return [field.name, val];
      });
      content = <RecordInfoDisplay recordInfo={recordInfo} />;
    }
    return content;
  }

  reselect = record => {
    const { selectTableRecords } = this.props;
    return () => {
      selectTableRecords([record.id || record.code]);
    };
  };

  render() {
    const {
      show,
      tableLibraryName,
      lookupNewItemFunction,
      showUploadFunction,
      data: { refetch },
      readOnly
    } = this.props;
    const cellRenderer = {
      name: (value, record) => {
        return (
          <span>
            {record.isSystemType ? (
              <Tooltip content="Created by system">
                <Icon icon="cog" style={{ marginRight: 5 }} />
              </Tooltip>
            ) : null}
            {value}
          </span>
        );
      }
    };

    const extraTableParams = {
      hideSelectedCount: true,
      onSingleRowSelect: this.selectLookupEntity,
      onDoubleClick: record => {
        if (readOnly || record.isSystemType) return;
        lookupNewItemFunction(show, refetch, record, this.reselect(record));
      },
      contextMenu: this.contextMenu,
      cellRenderer
    };

    return (
      <div className="tg-card">
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <AbstractLibrary
              {...this.props}
              noNewItem={readOnly}
              compact
              onNewItemClick={this.onNewItemClick}
              showUploadFunction={showUploadFunction}
              extraTableParams={extraTableParams}
              libraryName={tableLibraryName}
            />
          </div>
          <div className="tg-flex-separator" />
          {!isMobile() && (
            <div style={{ flex: 1, marginTop: 80 }}>{this.renderDetails()}</div>
          )}
        </div>
      </div>
    );
  }
}

export default LookupLibrary;
