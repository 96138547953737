/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

class PcrReaction {
  constructor({
    id,
    template_name,
    alt_template_name,
    forward_primer,
    reverse_primer,
    note,
    primer_mean_tm_full_length,
    primer_delta_tm_full_length,
    primer_mean_tm,
    primer_delta_tm,
    optimal_annealing_temperature
    // seq_obj
  }) {
    // I'm not sure if all these fields are necessary.
    this.id = id;
    this.template_name = template_name;
    this.alt_template_name = alt_template_name;
    this.forward_primer = forward_primer;
    this.reverse_primer = reverse_primer;
    this.note = note;
    this.primer_mean_tm_full_length = primer_mean_tm_full_length;
    this.primer_delta_tm_full_length = primer_delta_tm_full_length;
    this.primer_mean_tm = primer_mean_tm;
    this.primer_delta_tm = primer_delta_tm;

    this.optimal_annealing_temperature =
      optimal_annealing_temperature ||
      primer_mean_tm - 0.5 * primer_delta_tm + 2.0;
  }
}

/**
 * Sort an array of PCR reactions in ascending order of their optimal annealing temperature.
 */
PcrReaction.sortByOptimumAnnealingTemperature = pcrReactions => {
  // Make a shallow copy so as not to mutate the original array.
  pcrReactions = [...pcrReactions];

  // Sort ascending.
  pcrReactions.sort(
    (pcr1, pcr2) =>
      pcr1.optimal_annealing_temperature - pcr2.optimal_annealing_temperature
  );

  return pcrReactions;
};

export default PcrReaction;
