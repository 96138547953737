/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { get, size, intersection, difference } from "lodash";
import { compose } from "recompose";
import stepFormValues from "../../../stepFormValues";
import { validateNoDryPlatesObject } from "../../../../src-build/utils/plateUtils";
import platePreviewColumn from "../../../../src-build/utils/platePreviewColumn";
import ExperimentalDataFileUpload from "../../../components/ExperimentalDataFileUpload";
import HeaderWithHelper from "../../../HeaderWithHelper";
import GenericSelect from "../../../GenericSelect";
import { dateModifiedColumn } from "../../../utils/libraryColumns";
import { Button, Intent } from "@blueprintjs/core";
import { ValidationMsgComponent } from "../../../ValidationComponent";
import { DataTable, ReactSelectField } from "@teselagen/ui";
import {
  ALIQUOT_WELL_FIELD,
  MATERIAL_NAME_FIELD,
  PLATE_BARCODE_FIELD,
  PLATE_NAME_FIELD,
  SAMPLE_NAME_FIELD,
  dataGridAliquotFragment,
  getDataCellsFromCsvData
} from "../../../utils/experimentData/dataGridUtils";
import withQuery from "../../../withQuery";
import QueryBuilder from "tg-client-query-builder";
import pluralIfNeeded from "../../../utils/pluralIfNeeded";
import {
  getPlateLocationMap,
  sortAliquotContainers
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

export const plateFragment = gql`
  fragment plateToolFragment on containerArray {
    id
    name
    updatedAt
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      isPlate
      containerFormat {
        code
        is2DLabeled
        columnCount
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      barcode {
        id
        barcodeString
      }
      aliquot {
        ...dataGridAliquotFragment
      }
    }
  }
  ${dataGridAliquotFragment}
`;

const EXPLANATION_HEADER = "NOTE: Add any extra data columns from here";

/**
 * Some headers are strictly needed to identify the aliquot IDs,
 * others are only used for a human-friendly context on the aliquots
 */
const TEMPLATE_IDENTIFIER_HEADERS = [PLATE_BARCODE_FIELD, ALIQUOT_WELL_FIELD];
const TEMPLATE_CONTEXT_HEADERS = [
  PLATE_NAME_FIELD,
  MATERIAL_NAME_FIELD,
  SAMPLE_NAME_FIELD,
  EXPLANATION_HEADER
];

const TEMPLATE_HEADERS = [
  ...TEMPLATE_IDENTIFIER_HEADERS,
  ...TEMPLATE_CONTEXT_HEADERS
];

const AssociationStep = props => {
  const {
    stepFormProps: { change },
    Footer,
    footerProps,
    dataGrids: existingDataGrids = [],
    dataGrid,
    containerArrays = [],
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
    submitting
  } = props;

  const [templateHeaders, setTemplateHeaders] = useState([...TEMPLATE_HEADERS]);
  const [templateData, setTemplateData] = useState([]);
  const [acWellToRecordByPlate, setacWellToRecordByPlate] = useState({});

  const [fileData, setFileData] = useState();

  const [aliquotsDataRows, setAliquotsDataRows] = useState([]);
  const [aliquotsDataSchema, setAliquotsDataSchema] = useState([]);

  const [plateErrors, setPlateErrors] = useState([]);
  const [rowValidator, setRowValidator] = useState(null);
  const [columnValidator, setColumnValidator] = useState(null);
  const [headerValidator, setHeadersValidator] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isValid, setIsValid] = useState(false);

  /**
   * Constructs the CSV template when plates are selected.
   */
  useEffect(
    () => {
      if (size(containerArrays) && dataGrid) {
        const plateErrors = validateNoDryPlatesObject(containerArrays);
        if (size(plateErrors)) {
          return setPlateErrors(plateErrors);
        }
        /**
         * Example structure of this mapper
         * {
         *   [barcode_1]: {
         *      [wellLocation1]: aliquotRecord1,
         *      [wellLocation2]: aliquotRecord2,
         *      ...
         *   },
         *   [barcode_2]: {
         *      [wellLocation1]: aliquotRecord3
         *      ...
         *   }
         * }
         */
        const _acWellToRecordByPlate = {};

        const newTemplateData = [];
        containerArrays.forEach(plate => {
          const barcode = plate.barcode.barcodeString;
          const plate2d = getPlateLocationMap(plate, { onlyAliquots: true });
          _acWellToRecordByPlate[barcode] = plate2d;
          sortAliquotContainers(plate.aliquotContainers).forEach(ac => {
            if (!ac.aliquot) return;
            newTemplateData.push({
              [PLATE_BARCODE_FIELD]: barcode,
              [PLATE_NAME_FIELD]: plate.name,
              [ALIQUOT_WELL_FIELD]: getAliquotContainerLocation(ac),
              [MATERIAL_NAME_FIELD]: ac.aliquot.sample?.material?.name,
              [SAMPLE_NAME_FIELD]: ac.aliquot.sample?.name
            });
          });
        });

        /**
         * If an existing data grid has been selected,
         * we can include the same additional headers the user
         * used the previous time.
         */
        if (!dataGrid.userCreated) {
          const dataGridHeaders = dataGrid.dataCellHeaders.map(
            dataCell => dataCell.value
          );
          // EXPLANATION_COLUMN should always be the last,
          // so we place the dataGridHeaders right before it.
          const newHeaders = difference(dataGridHeaders, templateHeaders);
          templateHeaders.splice(
            templateHeaders.length - 1,
            0,
            ...newHeaders // avoid duplicating headers
          );
        }

        // Update the necessary component states
        setTemplateHeaders(templateHeaders);
        setTemplateData(newTemplateData);
        setacWellToRecordByPlate(_acWellToRecordByPlate);
      }
    },
    // eslint-disable-next-line
    [containerArrays, dataGrid]
  );

  /**
   * Applies CSV header validators based on the constructed headers
   */
  useEffect(() => {
    // Validates that the CSV headers have the the provided and required ones
    // in the template (in case the user accidentally changes them),
    // and that it doesn't include the explanation column header

    function getMissingRequiredColumns(value) {
      const requiredHeaders = difference(
        templateHeaders,
        TEMPLATE_CONTEXT_HEADERS
      );
      /**
       * Incoming required headers should be equal to the required headers.
       */
      const incomingRequiredHeaders = intersection(value, requiredHeaders);
      const missingRequiredColumns = difference(
        requiredHeaders,
        incomingRequiredHeaders
      );

      return missingRequiredColumns;
    }

    function getNewHeaders(value) {
      return difference(value, TEMPLATE_HEADERS);
    }

    function getDuplicatedHeaders(value) {
      const newHeaders = getNewHeaders(value);

      const uniqueHeaders = new Set();
      const duplicated = new Set();

      for (const header of newHeaders) {
        if (uniqueHeaders.has(header)) {
          duplicated.add(header);
        } else {
          uniqueHeaders.add(header);
        }
      }

      return Array.from(duplicated);
    }

    setHeadersValidator(
      () => yupSchema =>
        yupSchema
          .test(
            "excludes-explanation-column",
            "Please remove the explanation column from the CSV",
            value => {
              const noExplanationColumn = !size(
                intersection(value, [EXPLANATION_HEADER])
              );
              return noExplanationColumn;
            }
          )
          .test(
            "includes-required-columns",
            ({ value }) => {
              const missingRequiredColumns = getMissingRequiredColumns(value);
              return (
                "Please do not remove these required columns: " +
                missingRequiredColumns.join(", ")
              );
            },
            value => {
              const missingRequiredColumns = getMissingRequiredColumns(value);
              const noMissingRequiredColumns = !size(missingRequiredColumns);
              return noMissingRequiredColumns;
            }
          )
          .test(
            "at-least-one-data-column",
            "Please add at least one data column to associate with selected aliquots.",
            value => {
              return size(getNewHeaders(value)) > 0;
            }
          )
          .test(
            "no-duplicate-columns",
            ({ value }) => {
              const duplicatedHeaders = getDuplicatedHeaders(value);
              return `Please remove duplicate ${pluralIfNeeded(
                "header",
                duplicatedHeaders
              )}: ${duplicatedHeaders.join(", ")}`;
            },
            value => {
              const duplicatedColumns = getDuplicatedHeaders(value);
              return !size(duplicatedColumns);
            }
          )
    );
  }, [templateHeaders]);

  /**
   * Applies CSV data columns and rows validators based on the selected plates
   */
  useEffect(() => {
    function getEmptyColumns(value) {
      const emptyColumns = value.flatMap(col =>
        Object.entries(col)
          .filter(row => row[1].every(cell => !cell))
          .map(row => row[0])
      );

      return emptyColumns;
    }

    if (size(acWellToRecordByPlate)) {
      setColumnValidator(
        () => yupSchema =>
          yupSchema.test(
            "no-empty-columns",
            ({ value }) => {
              const emptyColumns = getEmptyColumns(value);
              return `Empty ${pluralIfNeeded(
                "column",
                emptyColumns
              )}: ${emptyColumns.join(", ")}`;
            },
            value => {
              const emptyColumns = getEmptyColumns(value);
              const noEmptyCols = !size(emptyColumns);
              return noEmptyCols;
            }
          )
      );

      const _rowValidator = {};

      // Make sure that the barcode columns comes with valid barcode values
      _rowValidator[PLATE_BARCODE_FIELD] = yupSchema =>
        yupSchema.oneOf(Object.keys(acWellToRecordByPlate));

      // Make sure that the well location column comes with valid well location values
      // depending on its plate.
      _rowValidator[ALIQUOT_WELL_FIELD] = yupSchema => {
        return yupSchema.when(PLATE_BARCODE_FIELD, (...args) => {
          const [barcode, schema] = args;
          const _aliquotLocationToRecord = acWellToRecordByPlate[barcode];
          if (_aliquotLocationToRecord) {
            const validWells = Object.keys(_aliquotLocationToRecord);
            if (size(validWells)) {
              return schema.oneOf(validWells);
            }
          }
          return schema;
        });
      };

      setRowValidator(_rowValidator);
    }
  }, [acWellToRecordByPlate]);

  /**
   * Transforms CSV data into dataCells for the database and dataRows for a preview data table
   */
  useEffect(() => {
    function parseData(data) {
      /**
       * construct both the dataCells to be created in the database
       * and the dataRows to be shown in the tool's preview data table
       * NOTE: they are not same, the latter includes additional contextual
       * information of the aliquot (s.a., plate name, barcode, etc.), while the
       * former only needs the aliquot's ID
       */
      const dataWithAliquot = data.map(row => {
        const wellLocation = row[ALIQUOT_WELL_FIELD];
        const barcode = row[PLATE_BARCODE_FIELD];
        const ac = acWellToRecordByPlate[barcode][wellLocation];
        return { ...row, aliquot: ac.aliquot };
      });

      const dataCells = getDataCellsFromCsvData(dataWithAliquot, {
        excludeColumns: TEMPLATE_HEADERS
      });

      const headers = Object.keys(data[0]);
      const schema = headers.map(header => ({
        path: header,
        displayName: header,
        render: (_, record) => get(record, header)
      }));

      setAliquotsDataSchema(schema);
      setAliquotsDataRows(dataWithAliquot);
      change("dataCells", dataCells);
    }
    if (size(fileData)) {
      parseData(fileData);
    }
  }, [fileData, acWellToRecordByPlate, change]);

  const onClearFile = () => {
    change("uploadedFile", null);
    setValidationErrors([]);
    setAliquotsDataRows([]);
    setAliquotsDataSchema([]);
    setFileData([]);
    clearPlateErrors();
  };

  const clearPlateErrors = () => {
    setPlateErrors([]);
  };

  const beforeUpload = async (_, validation) => {
    const { isValid: _isValid, validationErrors, data } = validation[0];

    setIsValid(_isValid);
    if (!_isValid) {
      setValidationErrors(validationErrors);
    } else {
      setFileData(data);
    }
    return true;
  };

  const renderDataGridSelection = (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Select a Data Grid"
        helper="Select an existing data grid or begin typing a new one."
      />
      <ReactSelectField
        isRequired
        name="dataGrid"
        label="Data Grid"
        placeholder="Select or create a data grid..."
        tooltipInfo="A Data Grid will be used as the experimental context where data points will be stored"
        options={existingDataGrids.map(option => ({
          value: option,
          label: option.name
        }))}
        creatable
        /**
         * Every time the datagrid changes destroy the form
         * as downstream data depends on it
         */
        onChange={onClearFile}
      />
    </div>
  );

  const renderPlateSelection = (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Select Plate(s)"
        helper="Choose the plate(s) containing the aliquots to be associated with the experimental data."
      />
      <div>
        <GenericSelect
          {...{
            name: "containerArrays",
            isRequired: true,
            onClear: onClearFile,
            onSelect: clearPlateErrors,
            buttonProps: {
              loading: isLoadingMap.containerArrays,
              disabled: isDisabledMap.containerArrays
            },
            /**
             * A single set of data rows to be ingested could reference different aliquots in different plates
             */
            isMultiSelect: true,
            schema: [
              {
                path: "name"
              },
              {
                displayName: "Barcode",
                path: "barcode.barcodeString"
              },
              dateModifiedColumn
            ],
            fragment: plateFragment,
            tableParamOptions: {
              additionalFilter: (_, qb) => {
                qb.whereAll({
                  displayFilter: qb.isNull(),
                  "barcode.barcodeString": qb.notNull()
                });
              }
            },
            postSelectDTProps: {
              formName: "updatePlateToolSelectPlates",
              plateErrors,
              schema: [
                platePreviewColumn({ plateErrors }),
                "name",
                {
                  displayName: "Barcode",
                  path: "barcode.barcodeString"
                },
                dateModifiedColumn
              ]
            }
          }}
        />
      </div>
    </div>
  );

  const renderUploadCsv = (
    <div className="tg-step-form-section column">
      <ExperimentalDataFileUpload
        name="uploadedFile"
        fileLimit={1}
        isRequired={true}
        headerHelper="Please download the csv template from the upload file zone and complete it with the experimental data."
        template={{
          headers: templateHeaders,
          data: templateData
        }}
        templateFileName="dataAssociation.csv"
        acceptTypes={[".csv"]}
        validators={{
          rowValidator,
          columnValidator,
          headerValidator,
          options: {
            // Allows rows to have empty cells.
            row: { allowEmpty: true }
          }
        }}
        onRemove={onClearFile}
        beforeUpload={beforeUpload}
      />
    </div>
  );

  const renderCsvValidationComponent = () => {
    const validationError = validationErrors[0];
    const validationMessage = `${validationError.message}. Fix your CSV file to proceed.`;
    return ValidationMsgComponent({
      validationMessage,
      intent: Intent.WARNING
    });
  };

  const renderAliquotsMapped = (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Aliquots Data"
        helper="These are your aliquots linked to data rows."
      />
      <DataTable
        formName="aliquotsMappedTable"
        isSimple
        entities={aliquotsDataRows}
        schema={aliquotsDataSchema}
      />
    </div>
  );

  return (
    <React.Fragment>
      {renderDataGridSelection}
      {dataGrid ? renderPlateSelection : null}
      {size(containerArrays) && !size(plateErrors) ? renderUploadCsv : null}
      {size(validationErrors) ? renderCsvValidationComponent() : null}
      {size(aliquotsDataRows) &&
      size(aliquotsDataSchema) &&
      !size(validationErrors)
        ? renderAliquotsMapped
        : null}
      <Footer
        {...footerProps}
        errorMessage={plateErrors?._error}
        nextButton={
          <Button
            id="generate-data-grids-button"
            type="submit"
            intent={Intent.SUCCESS}
            text="Submit"
            disabled={!isValid}
            loading={submitting}
          />
        }
      />
    </React.Fragment>
  );
};

export default compose(
  withQuery(
    [
      "dataGrid",
      "id name importFileSetId dataGridStateCode dataCellHeaders: dataCells(filter: {rowPosition: 0}) { id rowPosition columnPosition value }"
    ],
    {
      isPlural: true,
      options: () => {
        const qb = new QueryBuilder("dataGrid");
        const filter = qb
          .whereAll({
            /**
             * Only EDITABLE data grids can be selected because these are not
             * created from a file, and if selected, new data cells will added to it.
             */
            dataGridStateCode: "EDITABLE",
            /**
             * Maybe a redundant filter given that data grids created from files should
             * not have their dataGridStateCode set to "EDITABLE". But just to be safe.
             */
            importFileSetId: qb.isNull()
          })
          .toJSON();
        return {
          variables: { filter }
        };
      }
    }
  ),
  stepFormValues("dataGrid", "containerArrays", "uploadedFile")
)(AssociationStep);
