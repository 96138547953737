/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getSelectedValidatedJunctionId } from "../../../../../src-shared/selectors/designViewSelectors";
import { getFieldOnItem } from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import TypeIIsJunctionPanel from "./TypeIIsJunctionPanel";

import "./style.css";

const mapStateToProps = state => {
  const junctionId = getSelectedValidatedJunctionId(state);
  if (!junctionId) return {};

  return {
    junctionId,
    type: getFieldOnItem(state, "junction", junctionId, "junctionTypeCode")
  };
};

class ValidatedJunctionPanel extends React.Component {
  /**
   * If we have validated junction selected, render the panel corresponding to
   * its type.
   */
  renderPanel() {
    const { type, readOnly } = this.props;
    if (type === "TYPE_IIS_ENZYME") {
      return <TypeIIsJunctionPanel readOnly={readOnly} />;
    } else {
      return (
        <div className="validation-panel">
          <i style={{ color: "red" }}>
            Error: Unrecoginized validated junction type.
          </i>
        </div>
      );
    }
  }

  render() {
    const { junctionId } = this.props;
    if (!junctionId)
      return (
        <div className="validation-panel">
          <i>
            Select a validated junction to see more details:
            <br></br>
            <br></br>
          </i>
          <span style={{ width: "100%", display: "block" }}>
            <svg height={25} width={25} viewBox="-10 20 70 60">
              <path
                fill="none"
                stroke="#0081cb"
                strokeWidth={3}
                d="M 10 46 L 40 46 M 25 54 L 40 54"
              />
            </svg>
            <svg height={25} width={25} viewBox="-10 20 70 60">
              <path
                fill="none"
                stroke="#0081cb"
                strokeWidth={3}
                d="M 15, 40  m -9, 0  a 9,9 0 1,0 18,0  a 9,9 0 1,0 -18,0 M 21.3 46.3 l 20 20 M 27.3 50.3 l 15 15 M 25.3 52.3 l 15 15"
              />
            </svg>
          </span>
        </div>
      );

    return this.renderPanel();
  }
}

export default compose(connect(mapStateToProps))(ValidatedJunctionPanel);
