/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { InputField, CheckboxField, RadioGroupField } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { get, chunk, times, isEmpty } from "lodash";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import RecordInfoDisplay from "../../../../../src-shared/RecordInfoDisplay";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateContainerArray } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import UnitInputField from "../../../UnitInputField";

function getMicrobialPlasmids(ac) {
  return get(
    ac,
    "aliquot.sample.material.microbialMaterialMicrobialMaterialPlasmids"
  );
}

class ConfigureDestinationPlates extends Component {
  generatePreviewEntities = values => {
    const {
      outputPlates,
      choosingPlates,
      containerArrays = [],
      aliquotContainers = [],
      destinationBaseName,
      containerArrayType,
      aliquotContainerType,
      elutionVolume,
      elutionVolumetricUnitCode
    } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;

    const tableEntities = [];
    if (choosingPlates === "PLATES") {
      // input plates
      if (outputPlates === "PLATES") {
        // destination plates
        containerArrays.forEach((containerArray, i) => {
          const plateWells = [];
          containerArray.aliquotContainers.forEach(ac => {
            if (!ac.aliquot) {
              plateWells.push({
                ...ac,
                location: getAliquotContainerLocation(ac)
              });
            } else {
              plateWells.push({
                ...ac,
                location: getAliquotContainerLocation(ac),
                microbialMaterialPlasmids: getMicrobialPlasmids(ac),
                volume: elutionVolume,
                volumetricUnitCode: elutionVolumetricUnitCode
              });
            }
          });
          tableEntities.push({
            name: `${destinationBaseName} ${i + 1}`,
            containerArrayType,
            aliquotContainers: plateWells
          });
        });
      } else {
        // destination tubes
        containerArrays.forEach(containerArray => {
          let counter = 0;
          containerArray.aliquotContainers.forEach(ac => {
            if (ac.aliquot && get(ac, "aliquot.volume") > 0) {
              counter++;
              tableEntities.push({
                name: `${destinationBaseName} ${counter}`,
                aliquotContainerType,
                aliquot: ac.aliquot,
                microbialMaterialPlasmids: getMicrobialPlasmids(ac),
                volume: elutionVolume,
                volumetricUnitCode: elutionVolumetricUnitCode
              });
            }
          });
        });
      }
    } else {
      // input tubes
      if (outputPlates === "PLATES") {
        // destination plates
        const plateWells = [];
        aliquotContainers.forEach(ac => {
          if (ac.aliquot && get(ac, "aliquot.volume") > 0) {
            plateWells.push({
              aliquotContainerType,
              aliquot: ac.aliquot,
              microbialMaterialPlasmids: getMicrobialPlasmids(ac),
              volume: elutionVolume,
              volumetricUnitCode: elutionVolumetricUnitCode
            });
          }
        });
        let aliquotContainerGroups = chunk(
          plateWells,
          get(containerArrayType, "containerFormat.quadrantSize")
        );
        if (choosingPlates === "TUBES") {
          const { columnCount } = containerArrayType.containerFormat;
          // if the inputs are tubes, we need to assign a row and column position to each one
          const reformattedAliquotContainerGroups = [];
          aliquotContainerGroups.forEach(acGroup => {
            const reformattedAliquotContainers = [];
            let column = 0,
              row = -1;
            times(acGroup.length, i => {
              if (column % columnCount === 0) row++;
              const columnPosition = column++ % columnCount;
              reformattedAliquotContainers.push({
                ...acGroup[i],
                aliquotContainerTypeCode: containerArrayType.isPlate
                  ? containerArrayType.aliquotContainerTypeCode
                  : aliquotContainerType.code,
                rowPosition: row,
                columnPosition
              });
            });
            reformattedAliquotContainerGroups.push(
              reformattedAliquotContainers
            );
          });
          aliquotContainerGroups = reformattedAliquotContainerGroups;
        }
        aliquotContainerGroups.forEach((aliquotContainerGroups, i) => {
          tableEntities.push({
            name: `${destinationBaseName} ${i + 1}`,
            containerArrayType,
            aliquotContainers: generateContainerArray(
              aliquotContainerGroups,
              containerArrayType.containerFormat,
              {
                aliquotContainerTypeCode: containerArrayType.isPlate
                  ? containerArrayType.aliquotContainerTypeCode
                  : aliquotContainerType.code
              }
            )
          });
        });
      } else {
        // destination tubes
        aliquotContainers.forEach((ac, i) => {
          if (ac.aliquot && get(ac, "aliquot.volume") > 0) {
            tableEntities.push({
              name: `${destinationBaseName} ${i + 1}`,
              aliquotContainerType,
              aliquot: ac.aliquot,
              microbialMaterialPlasmids: getMicrobialPlasmids(ac),
              volume: elutionVolume,
              volumetricUnitCode: elutionVolumetricUnitCode
            });
          }
        });
      }
    }
    change("tableEntities", tableEntities);
    nextStep();
  };

  render() {
    const {
      containerArrays = [],
      aliquotContainers = [],
      outputPlates,
      Footer,
      footerProps,
      reagent = {},
      lot = {},
      containerArrayType = {},
      handleSubmit
    } = this.props;
    return (
      <React.Fragment>
        {(containerArrays.length > 0 || aliquotContainers.length > 0) && (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Configure Destination Plates and Tubes"
              helper="Choose whether you would like to elute into plates or tubes. Then enter a base name for the destination container and select the plate or tube type."
            />
            <div className="tg-flex column" style={{ width: "50%" }}>
              <div style={{ alignSelf: "flex-end" }}>
                <RadioGroupField
                  name="outputPlates"
                  label="Specify Destination Type"
                  options={[
                    { label: "Plates", value: "PLATES" },
                    { label: "Tubes", value: "TUBES" }
                  ]}
                  defaultValue="PLATES"
                />
              </div>
              <InputField
                name="destinationBaseName"
                isRequired
                label={
                  `Destination ` +
                  (outputPlates === "PLATES" ? `Plate ` : `Tube `) +
                  `Name`
                }
                generateDefaultValue={{
                  ...defaultValueConstants.DESTINATION_CONTAINER_NAME,
                  customParams: {
                    containerType: outputPlates === "TUBES" ? "Tube" : "Plate"
                  }
                }}
              />
              {outputPlates === "PLATES" && (
                <GenericSelect
                  {...{
                    name: "containerArrayType",
                    asReactSelect: true,
                    multiSelect: false,
                    isRequired: true,
                    label: "Destination Plate Type",
                    schema: [
                      {
                        path: "name"
                      }
                    ],
                    fragment: [
                      "containerArrayType",
                      "id name isPlate containerFormat { code rowCount columnCount quadrantSize is2DLabeled } aliquotContainerTypeCode aliquotContainerType { code maxVolume volumetricUnitCode}"
                    ],
                    tableParamOptions: {
                      additionalFilter: (props, qb) => {
                        qb.whereAll({
                          containerFormatCode: get(
                            containerArrays[0],
                            "containerArrayType.containerFormatCode"
                          )
                        });
                      }
                    }
                  }}
                />
              )}
              {(outputPlates === "TUBES" ||
                (containerArrayType &&
                  containerArrayType.isPlate === false)) && (
                <GenericSelect
                  {...{
                    name: "aliquotContainerType",
                    asReactSelect: true,
                    multiSelect: false,
                    isRequired: true,
                    label: "Destination Tube Type",
                    idAs: "code",
                    schema: [
                      {
                        path: "name"
                      }
                    ],
                    fragment: [
                      "aliquotContainerType",
                      "code name isTube maxVolume volumetricUnitCode"
                    ],
                    tableParamOptions: {
                      additionalFilter: {
                        isTube: true
                      }
                    }
                  }}
                />
              )}
              <CheckboxField
                name="generateBarcodes"
                defaultValue
                label={
                  `Generate ` +
                  (outputPlates === "PLATES" ? `Plate ` : `Tube `) +
                  `Barcodes`
                }
              />
              {outputPlates === "PLATES" && (
                <CheckboxField
                  name="onlyWithMaterials"
                  label="Elute only wells with materials"
                />
              )}
            </div>
          </div>
        )}
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Elution Solution"
              helper="Select the elution solution from the available reagent/reagent lots and specify the desired final elution volume."
            />
            <div>
              <GenericSelect
                {...{
                  name: "reagent",
                  isRequired: true,
                  isMultiSelect: false,
                  tableParamOptions: {
                    additionalFilter: additionalFilterForReagent
                  },
                  schema: [
                    "name",
                    {
                      displayName: "Reagent Type",
                      path: "additiveType.name"
                    },
                    dateModifiedColumn
                  ],
                  fragment: [
                    "additiveMaterial",
                    "id name isDry additiveType { code name } updatedAt"
                  ],
                  additionalDataFragment: [
                    "additiveMaterial",
                    "id name description additiveType { code name } lots { id name }"
                  ]
                }}
              />
            </div>
          </div>
          <div className="tg-flex column">
            {!isEmpty(reagent) && (
              <React.Fragment>
                <div className="tg-flex justify-space-between">
                  <div style={{ minWidth: 300, marginTop: 15 }}>
                    <h6> Reagent Information </h6>
                  </div>
                  <GenericSelect
                    {...{
                      name: "lot",
                      isMultiSelect: false,
                      buttonProps: {
                        text: `${isEmpty(reagent) ? "Change" : "Specify"} Lot`
                      },
                      reagent,
                      schema: [
                        "name",
                        { displayName: "Volume", render: volumeRender },
                        dateModifiedColumn
                      ],
                      fragment: [
                        "lot",
                        "id name volume volumetricUnitCode additiveMaterialId updatedAt"
                      ],
                      tableParamOptions: {
                        additionalFilter: additionalFilterForLot
                      }
                    }}
                  />
                </div>
                <RecordInfoDisplay
                  recordInfo={[
                    ["Name", reagent.name],
                    ["Description", reagent.description],
                    ["Type", get(reagent, "additiveType.name")]
                  ]}
                />
              </React.Fragment>
            )}
            {!isEmpty(lot) && (
              <React.Fragment>
                <h6 style={{ marginTop: 20, marginBottom: 5 }}>Lot</h6>
                <RecordInfoDisplay
                  recordInfo={[
                    ["Name", lot.name],
                    ["Volume", lot.volume + " " + lot.volumetricUnitCode]
                  ]}
                />
              </React.Fragment>
            )}
          </div>
          <hr className="tg-section-break width100" />
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Elution Transfer Volume"
              helper="Enter the amount of elution solution to be added to each destination plate well or tube."
            />
            <UnitInputField
              name="elutionVolume"
              isRequired
              placeholder="Enter desired volume..."
              label="Volume"
              unitName="elutionVolumetricUnitCode"
              unitDefault="uL"
              unitType="volumetricUnit"
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent={Intent.PRIMARY}
              onClick={handleSubmit(values =>
                this.generatePreviewEntities(values)
              )}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "containerArrays",
    "containerArrayType",
    "aliquotContainers",
    "aliquotContainerType",
    "containerArrayType",
    "reagent",
    "lot",
    "elutionVolume",
    "elutionVolumetricUnitCode",
    "outputPlates",
    "choosingPlates"
  )
)(ConfigureDestinationPlates);

const additionalFilterForReagent = (props, qb) => {
  qb.whereAll({ isDry: qb.isNull() }).orWhereAll({ isDry: false });
};

const additionalFilterForLot = (props, qb) => {
  const { reagent = {} } = props;
  qb.whereAll({ additiveMaterialId: reagent.id });
};
