/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import shortid from "shortid";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import appGlobals from "../../../../src-shared/appGlobals";
import { validateConditionallyRequiredFields } from "../../../../src-shared/utils/formUtils";
import withQuery from "../../../../src-shared/withQuery";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import withWorkflowInputsFromHash from "../../../graphql/enhancers/withWorkflowInputsFromHash";
import {
  formatTwistSequencesForExporting,
  getVendorParams
} from "../../Bioshop/Vendors/VendorFunctions";
import StepForm from "../../../../src-shared/StepForm";
import ScoreAndPrice from "./Steps/ScoreAndPrice";
import SelectDNA, { sequenceFragment } from "./Steps/SelectDNA";

function validate(values) {
  const errors = {};
  validateConditionallyRequiredFields(
    ["sequences", "dataTables", "sequencesFromAssemblyReports", "reactionMaps"],
    values,
    errors
  );
  return errors;
}

function TwistOrderingTool(props) {
  const {
    toolIntegrationProps,
    toolSchema,
    isToolIntegrated,
    initialValues,
    integrations = []
  } = props;

  async function onSubmit(values) {
    try {
      const { sequencesToOrder, orderName, scoringIdMap } = values;
      const vendorParams = getVendorParams("TWIST");
      const formattedSequences =
        formatTwistSequencesForExporting(sequencesToOrder);
      const isPlasmids = sequencesToOrder[0].type === "CIRCULAR_DNA";
      const dataTable = {
        name: `Order ${orderName}_Twist_DNA Data Table`,
        dataTableTypeCode: isPlasmids
          ? vendorParams.plasmidDataTableTypeCode
          : vendorParams.dataTableTypeCode,
        dataRows: formattedSequences.map(seq => ({ rowValues: seq }))
      };
      const dataSet = {
        cid: shortid(),
        name: orderName
      };
      const [createdOrder] = await safeUpsert("vendorOrder", {
        name: orderName,
        dataSet,
        vendorId: "&twist_cid",
        ownerId: appGlobals.currentUser.id,
        orderStatusCode: "DRAFT_STATUS",
        lineItems: sequencesToOrder.map(seq => {
          const scoringId = scoringIdMap[seq.id];
          const lineItem = {
            isClonal: isPlasmids,
            isScored: true,
            sequenceId: seq.id, //seq.queriedMaterial.id also an option
            totalPrice: seq.price,
            purchaseOrderId: "&testpurchaseorder1", //from seed data dummy order....
            lineItemScore: {
              difficulty: seq.difficulty,
              isBuildable: seq.score === "BUILDABLE",
              score: seq.score
            },
            lineItemStatusCode: "PENDING_STATUS",
            vendorLineItemReferenceId: scoringId
          };
          return lineItem;
        })
      });
      dataTable.dataSetId = `&${dataSet.cid}`;
      await safeUpsert("dataTable", dataTable);
      return {
        vendorOrder: createdOrder
      };
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating order");
    }
  }

  // const [vectorList, loadingVectorList] = useVectorList();

  const steps = [
    {
      title: "Select DNA",
      Component: SelectDNA,
      withCustomFooter: true,
      props: {
        integrations
      }
    },
    {
      title: "Score and Price DNA",
      Component: ScoreAndPrice,
      withCustomFooter: true
    }
  ];

  if (!integrations.length) {
    return (
      <div style={{ margin: 45 }}>
        No integrations have been setup for Twist DNA Ordering. An admin can add
        one in <Link to="/settings/integrations">integration settings</Link> DNA
        Ordering.
      </div>
    );
  }

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      toolSchema={toolSchema}
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
}

// const { data: result } = await window.triggerIntegrationRequest({
//   endpointId,
//   data: {
//     encodedFiles
//   },
//   method: "POST"
// });

// VENDOR__VECTORS

const integrationFragment = gql`
  fragment twistOrderingToolIntegrationFragment on integration {
    id
    name
    integrationTypeCode
    integrationEndpoints {
      id
      endpointTypeCode
    }
  }
`;

export default compose(
  withQuery(integrationFragment, {
    showLoading: true,
    isPlural: true,
    options: () => {
      return {
        variables: {
          filter: {
            ...(window.Cypress?.__twistIntegrationId && {
              id: window.Cypress?.__twistIntegrationId
            }),
            integrationTypeCode: "TWIST_ORDERING"
          }
        }
      };
    }
  }),
  withWorkflowInputsFromHash({
    hashIdToInputName: {
      dataTableIds: "dataTables",
      sequenceIds: "sequences"
    }
  }),
  withWorkflowInputs(["j5Report", "id name updatedAt"]),
  withWorkflowInputs(["dataTable", "id name updatedAt"]),
  withWorkflowInputs(["reactionMap", "id name updatedAt"]),
  withWorkflowInputs(sequenceFragment)
)(TwistOrderingTool);
