/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { ReactSelectField } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { compose } from "redux";
import { get, isEmpty } from "lodash";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import containerFormatFragment from "../../../../../../tg-iso-shared/src/fragments/containerFormatFragment";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { validateNoDryPlatesObject } from "../../../../utils/plateUtils";
import gql from "graphql-tag";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";

export const breakableFormats = ["24_WELL", "96_WELL", "384_WELL", "1536_WELL"];
class SelectOperationAndInputPlates extends Component {
  render() {
    const {
      operationType,
      containerFormats = [],
      sourcePlateFormat,
      toolIntegrationProps: { isIntegratedMap = {}, isLoadingMap = {} },
      Footer,
      footerProps,
      sourcePlates = [],
      handleSubmit,
      nextStep,
      stepFormProps: { change }
    } = this.props;
    const operationOptions = [
      {
        label: "Replication",
        value: "REPLICATION"
      },
      {
        label: "Breakdown",
        value: "BREAKDOWN"
      },
      {
        label: "Combination",
        value: "COMBINATION"
      },
      {
        label: "Consolidation",
        value: "CONSOLIDATION"
      }
    ];

    const sourcePlateFormats = [];
    containerFormats.forEach(format => {
      if (format.code !== "12_WELL" && format.code !== "48_WELL") {
        sourcePlateFormats.push(format);
      }
    });
    const uniformFormats =
      operationType === "CONSOLIDATION" || operationType === "COMBINATION";

    const additionalPlateFilter = {
      additionalFilter: (props, qb) => {
        qb.whereAll({
          "containerArrayType.containerFormatCode": [
            "6_WELL",
            "12_WELL",
            "24_WELL",
            "48_WELL",
            "96_WELL",
            "384_WELL",
            "1536_WELL"
          ]
        });
      }
    };

    const sourcePlateFilter = {
      additionalFilter:
        operationType === "BREAKDOWN"
          ? (props, qb) => {
              qb.whereAll({
                "containerArrayType.containerFormatCode": breakableFormats
              });
            }
          : {
              "containerArrayType.containerFormatCode": get(
                sourcePlateFormat,
                "code"
              )
            }
    };

    const maybeSelectFormatText = isIntegratedMap.containerArrays
      ? ""
      : " (select a format from the dropdown below)";
    const operationDescriptions = {
      REPLICATION: {
        header: "Replication",
        description:
          "Replication (or plate stamping) moves selecte materials from source plates to destination plates of the same format."
      },
      BREAKDOWN: {
        header: "Breakdown",
        description:
          "Breakdown moves selected materials from higher density plates (384 well) to lower density plates (96 well) following an assigned mapping."
      },
      COMBINATION: {
        header: "Combination",
        description: `Combination moves selected material from lower density plates (96 well) to higher density plates (384 well) following an assigned mapping. Source plate formats must be uniform${maybeSelectFormatText}.`
      },
      CONSOLIDATION: {
        header: "Consolidation",
        description: `Consolidation takes any number of plates and combines their constituent aliquots onto one new plate. Source plate formats must be uniform${maybeSelectFormatText}.`
      }
    };

    const schema = [
      "name",
      { displayName: "Barcode", path: "barcode.barcodeString" },
      dateModifiedColumn
    ];
    if (operationType === "BREAKDOWN") {
      schema.splice(2, 0, {
        path: "containerArrayType.name",
        displayName: "Plate Type"
      });
    }

    const plateErrors = validateNoDryPlatesObject(sourcePlates);

    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Select Operation Type"
            helper="Choose which plate reformat operation you would like to execute."
          />
          <div style={{ width: "40%" }} className="tg-flex column">
            <ReactSelectField
              style={{ marginBottom: 10 }}
              isRequired
              name="operationType"
              label="Operation Type"
              options={operationOptions}
              placeholder="Select a reformat operation type..."
            />
            {operationType && (
              <HeaderWithHelper
                header={
                  operationDescriptions[operationType] &&
                  operationDescriptions[operationType].header
                }
                helper={
                  operationDescriptions[operationType] &&
                  operationDescriptions[operationType].description
                }
                width="100%"
              />
            )}
            {uniformFormats && !isIntegratedMap.containerArrays && (
              <ReactSelectField
                style={{ marginBottom: 10 }}
                name="sourcePlateFormat"
                label="Source Plate Format"
                options={arrayToItemValuedOptions(sourcePlateFormats)}
                placeholder="Specify a format for your source plates..."
              />
            )}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates From Inventory"
            helper="Select one or more plates of material that you would like to reformat."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "sourcePlates",
                schema,
                isRequired: true,
                isMultiSelect: true,
                buttonProps: {
                  disabled:
                    isIntegratedMap.containerArrays ||
                    (uniformFormats && !sourcePlateFormat),
                  loading: isLoadingMap.containerArrays
                },
                tableParamOptions:
                  uniformFormats || operationType === "BREAKDOWN"
                    ? sourcePlateFilter
                    : additionalPlateFilter,
                fragment: [
                  "containerArray",
                  "id name barcode {id barcodeString}"
                ],
                additionalDataFragment: plateReformatSourcePlateFragment,
                postSelectDTProps: {
                  formName: "plateReformatSelectPlates",
                  isSingleSelect: true,
                  plateErrors,
                  schema: [
                    platePreviewColumn({
                      plateErrors
                    }),
                    "name",
                    {
                      path: "containerArrayType.name",
                      displayName: "Plate Type"
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          nextDisabled={!isEmpty(plateErrors)}
          onNextClick={handleSubmit(() => {
            change("replicatePlateInfo", {});
            nextStep();
          })}
          errorMessage={
            !isEmpty(plateErrors) &&
            (plateErrors._error || "Please review plate errors.")
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("operationType", "sourcePlateFormat", "sourcePlates"),
  withQuery(containerFormatFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        filter: {
          code: [
            "6_WELL",
            "12_WELL",
            "24_WELL",
            "48_WELL",
            "96_WELL",
            "384_WELL",
            "1536_WELL"
          ]
        }
      }
    }
  })
)(SelectOperationAndInputPlates);

export const plateReformatSourcePlateFragment = gql`
  fragment plateReformatSourcePlateFragment on containerArray {
    id
    name
    updatedAt
    containerArrayType {
      id
      name
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        id
        isDry
      }
    }
  }
`;
