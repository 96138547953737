/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import {
  ContextMenu,
  Popover,
  Button,
  Classes,
  Position,
  Tooltip,
  Icon
} from "@blueprintjs/core";
import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { IntentText } from "@teselagen/ui";
import { Rnd } from "react-rnd";
import store from "../../../../src-shared/redux/store";
import DesignCardContextMenuContainer from "../../../containers/DesignCardContextMenuContainer";
import DesignElementBlockContainer from "../../../containers/DesignElementBlockContainer";
import DesignRowIndexContainer from "../../../containers/DesignRowIndexContainer";

import "./style.css";
import TooltipMessage from "../../../../src-shared/constants/tooltips";
import { withEditorContext } from "../DesignEditorContext";
import CardViewOptions from "./CardViewOptions";
import { getFeatureToColorMap } from "@teselagen/sequence-utils";
import { CONSTRUCT_ANNOTATION_HEIGHT } from "../../../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";
import {
  DESIGN_ELEMENT_CARD_X_PADDING,
  ROW_INDEX_WIDTH,
  SET_HEADER_ITEM_HEIGHT,
  VALIDATOR_HEIGHT
} from "../../../../src-shared/components/HierarchicalDesign/constants";

/**
 * A card in the tree view of the hierarchical design editor. Ignore the word "Element"
 * in its name.
 *
 * When passing props, be careful as `shouldComponentUpdate` performs a deep equality check.
 */
class DesignElementCard extends React.Component {
  static propTypes = {
    /**
     * The id of the card being rendering. Note that a card is a set in the database.
     */
    cardId: PropTypes.string.isRequired,

    /**
     * The level of the card. This takes of the form of `1.3.2.1`. The number of numbers
     * equals the depth of the card in the tree. The i-th number represent the index of
     * the card's ancestor at depth i among its siblings.
     */
    level: PropTypes.string.isRequired,

    /**
     * The depth of the card in the tree. I do not believe it is being used.
     */
    depth: PropTypes.number,

    /**
     * Should we render the cells or just the bin headers.
     */
    areListElementsExpanded: PropTypes.bool,

    /**
     * Array of the bins, in order, in the card.
     */
    binIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    /**
     * Is the design a uniform design.
     *
     * @deprecated We don't have uniform designs anymore.
     */

    isUniformDesign: PropTypes.bool,

    /**
     * Is the design a visual report?
     *
     * @deprecated We don't have visual reports anymore.
     */
    isVisualReport: PropTypes.bool,

    /**
     * Is the design locked?
     */
    isLocked: PropTypes.bool,

    /**
     * Are we rendering a card in the horizontal or vertical tree layouts?
     */
    treeLayout: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,

    /**
     * The height of the card in pixels.
     */
    cardHeight: PropTypes.number.isRequired,

    /**
     * The minimum allowable width of the card in pixels.
     */
    minCardWidth: PropTypes.number.isRequired,

    /**
     * The actual width of the card in pixels.
     */
    cardWidth: PropTypes.number.isRequired,

    /**
     * The database entry representing the card. This will from the `set` table.
     */
    card: PropTypes.object.isRequired,

    /**
     * Does the right side of the card contain a direct synthesis firewall?
     */
    dsf: PropTypes.bool,

    /**
     * Is the card selected?
     */
    isSelected: PropTypes.bool,

    /**
     * The `margin-top` property of the card in pixels.
     */
    topMargin: PropTypes.number.isRequired,

    /**
     * The width of a single column (i.e. bin) in pixels.
     */
    singleBlockWidth: PropTypes.number.isRequired,

    materialAvailabilityInfo: PropTypes.object,

    /**
     * The design editor "context", used to pass reusable,
     * non-state, editor-level objects/functions, such as
     * the editor commands.
     */
    editorContext: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  handleContextMenu = e => {
    const {
      cardId,
      selectCard,
      isSelected,
      isVisualReport,
      isLocked,
      history,
      editorContext
    } = this.props;

    e.preventDefault();

    if (isVisualReport || isLocked) return;

    if (!isSelected) {
      selectCard({
        cardId
      });
    }

    const menu = (
      <DesignCardContextMenuContainer
        clickedOn="card"
        {...{ store, history, editorContext }}
      />
    );

    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };

  renderMaterialAvailabilityInformation = () => {
    const {
      card: { allConstructsAvailable },
      materialAvailabilityInfo: { numberAvailable, numCombinations }
    } = this.props;

    let tooltipContent, icon, intent;

    if (allConstructsAvailable) {
      intent = "success";
      icon = <Icon icon="tick" iconSize={14} />;
      tooltipContent = "All constructs available";
    } else if (numberAvailable === 0) {
      intent = "danger";
      icon = `${numberAvailable}/${numCombinations}`;
      tooltipContent = `You have ${numberAvailable}/${numCombinations} constructs available`;
    } else if (numberAvailable !== undefined) {
      intent = "warning";
      icon = `${numberAvailable}/${numCombinations}`;
      tooltipContent = `You have ${numberAvailable}/${numCombinations} constructs available`;
    }

    return (
      <Tooltip content={tooltipContent} key="ma">
        <IntentText intent={intent}>
          <span
            key="ma"
            style={{
              width: 10,
              height: 10,
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "bolder",
              margin: "5px"
            }}
          >
            {icon}
          </span>
        </IntentText>
      </Tooltip>
    );
  };

  renderCircularityInformation = () => {
    const {
      card: { circular }
    } = this.props;

    const tooltipContent = `This card is ${circular ? "circular" : "linear"}`;

    return (
      <Tooltip content={tooltipContent} key="circular">
        <IntentText intent="primary">
          <span
            key="circular"
            style={{
              width: 10,
              height: 10,
              textAlign: "center",
              fontWeight: "bolder",
              margin: "5px"
            }}
          >
            <Icon icon={circular ? "circle" : "minus"} iconSize={14} />
          </span>
        </IntentText>
      </Tooltip>
    );
  };

  renderTopRightIndicators = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 1,
          right: 1
        }}
      >
        {[
          this.renderMaterialAvailabilityInformation(),
          this.renderCircularityInformation()
        ]}
      </div>
    );
  };

  handleNameClick = () => {
    const {
      cardId,
      updateSelection,
      openInspector,
      setInspectorActivePanel
    } = this.props;

    updateSelection({
      cardId,
      setIds: [cardId],
      cells: [],
      activeSetId: null,
      activeCellPath: null
    });
    setInspectorActivePanel({
      panel: "card"
    });
    openInspector();
  };

  renderRowIndexCells() {
    return <DesignRowIndexContainer {...this.props} />;
  }

  renderBins() {
    const {
      binIds,
      card: { id },
      areListElementsExpanded,
      singleBlockWidth
    } = this.props;
    return binIds.map(binId => {
      return (
        <DesignElementBlockContainer
          key={binId}
          {...{
            binId,
            cardId: id,
            areListElementsExpanded,
            width: singleBlockWidth
          }}
        />
      );
    });
  }

  /**
   * The actual indicators will be rendered off the bins. We need to include
   * space on the card for them, which is what this method accomplishes.
   */
  renderValidatedJunctionsSpacer() {
    const { hasValidatedJunctions } = this.props;
    if (!hasValidatedJunctions) return null;
    return (
      <div
        className="validated-junctions-container"
        style={{ height: VALIDATOR_HEIGHT }}
      />
    );
  }

  renderConstructAnnotations() {
    const {
      constructAnnotations = [],
      cardId,
      singleBlockWidth,
      binIds
    } = this.props;
    if (!constructAnnotations.length) return null;
    return (
      <div
        className="construct-annotations-container"
        onClick={this.handleNameClick}
        style={{ height: 16 }}
      >
        {constructAnnotations.map(ca => {
          return this.renderConstructAnnotation(
            ca,
            cardId,
            singleBlockWidth,
            binIds
          );
        })}
      </div>
    );
  }

  renderConstructAnnotation(ca, cardId, singleBlockWidth, binIds) {
    const leftBinIndex = ca.leftBoundaryBin.index;
    const rightBinIndex = ca.rightBoundaryBin.index;

    if (leftBinIndex > rightBinIndex) {
      return (
        <div key={ca.id + cardId}>
          <div
            style={{
              border:
                ca.annotationType === "part"
                  ? `1px solid purple`
                  : `1px solid white`,
              borderRightStyle: "dotted",
              position: "absolute",
              fontSize: 10,
              left: leftBinIndex * singleBlockWidth,
              opacity: 0.3,
              backgroundColor:
                ca.annotationType === "part"
                  ? "white"
                  : getFeatureToColorMap({ includeHidden: true })[ca.type],
              width: (binIds.length - leftBinIndex) * singleBlockWidth,
              height: CONSTRUCT_ANNOTATION_HEIGHT
            }}
          >
            <Tooltip content={`Construct Annotation: ${ca.name}`}>
              <div>{ca.name}...</div>
            </Tooltip>
          </div>
          <div
            style={{
              border:
                ca.annotationType === "part"
                  ? `1px solid purple`
                  : `1px solid white`,
              borderLeftStyle: "dotted",
              position: "absolute",
              fontSize: 10,
              left: 0,
              opacity: 0.3,
              backgroundColor:
                ca.annotationType === "part"
                  ? "white"
                  : getFeatureToColorMap({ includeHidden: true })[ca.type],
              width: (rightBinIndex + 1) * singleBlockWidth,
              height: 15
            }}
          >
            <Tooltip content={`Construct Annotation: ${ca.name}`}>
              <div>...{ca.name}</div>
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div
        key={ca.id + cardId}
        style={{
          border:
            ca.annotationType === "part"
              ? `1px solid purple`
              : `1px solid white`,
          position: "absolute",
          fontSize: 10,
          left: leftBinIndex * singleBlockWidth,
          opacity: 0.3,
          backgroundColor:
            ca.annotationType === "part"
              ? "white"
              : getFeatureToColorMap({ includeHidden: true })[ca.type],
          width: (rightBinIndex - leftBinIndex + 1) * singleBlockWidth,
          height: 15
        }}
      >
        <Tooltip content={`Construct Annotation: ${ca.name}`}>
          <div>{ca.name}</div>
        </Tooltip>
      </div>
    );
  }

  render() {
    const {
      level,
      card: { id, name, invalidityMessage: cardInvalidityMessage },
      updateCardWidth,
      isUniformDesign,
      cardHeight,
      cardWidth,
      minCardWidth,
      treeLayout,
      dsf,
      topMargin,
      paddingLeft,
      paddingRight,
      showRowsIndex,
      isSelected
    } = this.props;

    const setCardWidth = cardWidth + (showRowsIndex ? ROW_INDEX_WIDTH : 0);

    const cardContent = (
      <div
        style={{
          zIndex: 1
        }}
      >
        <Tooltip
          hoverOpenDelay="1000"
          disabled={!dsf && !cardInvalidityMessage}
          content={
            <div style={{ width: 400 }}>
              {cardInvalidityMessage
                ? cardInvalidityMessage
                : TooltipMessage.dsf}
            </div>
          }
          className="full-width-tooltip"
          position={dsf ? Position.RIGHT : Position.TOP}
        >
          <div
            className={classNames("design-card", {
              selected: isSelected,
              dsf: dsf || isUniformDesign,
              invalid: !!cardInvalidityMessage
            })}
            onContextMenu={this.handleContextMenu}
          >
            <div
              className="design-card-header"
              style={{
                height: cardHeight,
                paddingLeft,
                paddingRight
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: -SET_HEADER_ITEM_HEIGHT,
                  left: DESIGN_ELEMENT_CARD_X_PADDING,
                  display: "flex",
                  flexDirection: "row"
                }}
              />
              {false && (
                <div
                  className="eye-button"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1
                  }}
                >
                  <Popover
                    position={Position.RIGHT_TOP}
                    useSmartArrowPositioning={false}
                    tetherOptions={{
                      constraints: [
                        { attachment: "together", to: "scrollParent" }
                      ]
                    }}
                  >
                    <Button className={Classes.MINIMAL} icon="eye-open" />
                    <CardViewOptions cardId={id} />
                  </Popover>
                </div>
              )}
              <div
                onClick={this.handleNameClick}
                className="element-name"
                style={{ zIndex: 0 }}
              >
                {name ? level + " - " + name : level}
              </div>
              {this.renderTopRightIndicators()}
              {this.renderValidatedJunctionsSpacer()}
              {/*
                TODO: Displaying Construct Annotations in Hierarchical view is not working properly.
                The CAs are not nicely displayed in the DOM so it will require some work to get those looking nice.
              */}
              {/* {this.renderConstructAnnotations()} */}
              <div style={{ display: "flex" }}>
                {showRowsIndex ? this.renderRowIndexCells() : null}
                <div>{this.renderBins()}</div>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
    );

    return (
      <div
        style={{
          height: cardHeight,
          marginTop: topMargin,
          width: setCardWidth,
          marginRight: treeLayout === "vertical" ? 12 : null,
          position: "relative"
        }}
      >
        <Rnd
          default={{ width: setCardWidth }}
          minWidth={minCardWidth}
          size={{ width: setCardWidth }}
          disableDragging
          enableResizing={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          resizeHandleClasses={{
            right: "designCardResizeHandle"
          }}
          onResizeStop={(_event, _direction, _refToElement, delta) => {
            updateCardWidth({ id, cardWidth: setCardWidth + delta.width });
          }}
        >
          {cardContent}
        </Rnd>
      </div>
    );
  }
}

export default withEditorContext(DesignElementCard);
