/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import testConfigs from "../../../src-test/configs/config.json";
import { safeQuery } from "../../apolloMethods";

export const TEST_METADATA = testConfigs.testMetaData;
export const TEST_LABELS = testConfigs.labels;

export function getClassEntity(_class) {
  if (_class === "unit") {
    return "measurementType";
  }
  if (_class === "d-unit") {
    return "referenceDimension";
  } else {
    return _class;
  }
}

export const getSubClasses = async (_class, options = {}) => {
  const { expanded = false } = options;
  const classSubclasses = await window.serverApi.request({
    method: "GET",
    headers: getRequestHeaderKeys(),
    withCredentials: true,
    url: `/test-routes/metadata/${_class}?expanded=${expanded}`
  });
  return classSubclasses;
};

export const getMappingPresets = async () => {
  try {
    const mappingPresets = await safeQuery(
      ["mappingPreset", "id name header"],
      {
        isPlural: true
      }
    );
    return mappingPresets;
  } catch (error) {
    console.error(error);
  }
};
