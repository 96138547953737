/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { getAssemblyPieceIds } from "./AssemblyPieces";
import { withSelectTableRecords } from "@teselagen/ui";
import constructSelectionJ5ReportFragment from "../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { assemblyPieceTableName } from "../constants";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { compose } from "recompose";
import { isEqual } from "lodash";
import LinkAllJ5MaterialsButton from "../../../LinkAllJ5MaterialsButton";
import { j5ReportLinkedToMaterials } from "../utils";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { j5ReportAssemblyHierarchicalColumns } from "../../../../../src-shared/utils/libraryColumns";

const shortSchema = {
  model: "j5Report",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Assembly Method",
      path: "assemblyMethod"
    },
    {
      displayName: "Assembly Type",
      path: "assemblyType"
    },
    ...j5ReportAssemblyHierarchicalColumns
  ]
};
const fullSchema = {
  ...shortSchema,
  fields: [
    ...shortSchema.fields,
    {
      displayName: "Link Materials",
      render: (v, j5Report, i, props) => {
        if (j5ReportLinkedToMaterials(j5Report)) {
          return "Fully Linked";
        } else {
          const { j5Reports, change } = props;
          const afterLinking = async () => {
            const fullReport = await safeQuery(
              constructSelectionJ5ReportFragment,
              {
                variables: {
                  id: j5Report.id
                }
              }
            );
            const indexOfReport = j5Reports.findIndex(
              r => r.id === j5Report.id
            );
            change("j5Reports", [
              ...j5Reports.slice(0, indexOfReport),
              fullReport,
              ...j5Reports.slice(indexOfReport + 1)
            ]);
          };

          return (
            <LinkAllJ5MaterialsButton
              j5Report={j5Report}
              text="Link to Materials"
              afterlinking={afterLinking}
            />
          );
        }
      }
    }
  ]
};

class SelectJ5Report extends React.Component {
  componentDidUpdate(oldProps) {
    const { j5Reports: oldReports = [] } = oldProps;
    const {
      j5Reports = [],
      selectTableRecords,
      stepFormProps: { change }
    } = this.props;
    const newIds = j5Reports.map(r => r.id);
    const oldIds = oldReports.map(r => r.id);
    if (!isEqual([...newIds].sort(), [...oldIds].sort())) {
      const assemblyPieceIds = getAssemblyPieceIds(j5Reports);
      selectTableRecords(assemblyPieceIds.map(id => ({ id })));
      change("finalizedAPIds", []);
    }
  }

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      stepFormProps: { change },
      j5Reports
    } = this.props;

    return (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Select DNA Assembly Reports"
          helper="Select one or more DNA assembly reports to compile a list of assembly pieces and constructs."
        />
        <div style={{ marginBottom: 20 }}>
          <GenericSelect
            additionalDataFragment={constructSelectionJ5ReportFragment}
            name="j5Reports"
            isMultiSelect
            buttonProps={{
              disabled: isDisabledMap.j5Reports,
              loading: isLoadingMap.j5Reports
            }}
            schema={shortSchema}
            fragment={[
              "j5Report",
              `id
                name
                assemblyMethod
                assemblyType
                isHierarchical
                outputCardName
                assemblyBatchId
                treePosition
                updatedAt`
            ]}
            postSelectDTProps={{
              formName: "constructSelectionReportsTable",
              schema: fullSchema,
              j5Reports,
              change
            }}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  stepFormValues("j5Reports"),
  withSelectTableRecords(assemblyPieceTableName)
)(SelectJ5Report);
