/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { uniq } from "lodash";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const shared = ["containerArray", "reactionMap", "plateMapGroup"];

export const inputTypeModelsForReagents = ["additiveMaterial"].concat(shared);

export const inputTypeModels = [
  "material",
  "sample",
  "j5Report",
  "dataTable"
].concat(shared);

export const allInputTypeModels = uniq(
  inputTypeModels.concat(inputTypeModelsForReagents)
);

export const multiSelectModels = [
  "sample",
  "reactionMap",
  "j5Report",
  "material",
  "additiveMaterial",
  "containerArray",
  "dataTable"
];

export const reactionEntityTypeOptionsInventoryCheck = [
  {
    label: "Input Materials",
    value: "isInput"
  },
  {
    label: "Output Materials",
    value: "isOutput"
  }
];
