/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import { handleEquipmentUpload } from "../../../../tg-iso-lims/src/utils/equipmentUtils";
import caseInsensitiveFilter from "../../utils/caseInsensitiveFilter";

const EQUIPMENT = async function ({ recordsToImport }, ctx = isoContext) {
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport,
      model: "equipmentItem"
    },
    ctx
  );
  await Promise.each(newRecords, async record => {
    try {
      if (!record.locationName) {
        throw new Error(`Equipment must have a location.`);
      }
      const [locationToUse] = await ctx.safeQuery(["location", "id"], {
        variables: {
          filter: caseInsensitiveFilter("location", "name", [
            record.locationName
          ])
        }
      });
      if (!locationToUse) {
        throw new Error(`Could not find location ${record.locationName}.`);
      }
      const uploadRes = await handleEquipmentUpload(
        {
          equipmentJsons: [record],
          locationId: locationToUse.id
        },
        ctx
      );
      const { equipmentItems } = uploadRes;
      record.id = equipmentItems[0].id;
      record.__typename = equipmentItems[0].__typename;
    } catch (error) {
      console.error(`error:`, error);
      record.__importFailed = error.message;
    }
  });
};

export default EQUIPMENT;
