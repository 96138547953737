/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Classes } from "@blueprintjs/core";
import React from "react";
import { showDialog } from "../../src-shared/GlobalDialog";
import { displayDateTime } from "../../src-shared/utils/dateUtils";
import downloadServerFile from "../utils/downloadServerFile";
import PreviewImageFileDialog from "./Dialogs/PreviewImageFileDialog";

function FileListSection({ files, onRemove }) {
  return files.map((file, i) => {
    return (
      <div
        key={file.id || i}
        className="tg-file-item-with-info"
        style={{ display: "flex", alignItems: "center" }}
      >
        {onRemove && (
          <Button
            minimal
            small
            onClick={() => onRemove(file)}
            intent="danger"
            icon="trash"
            style={{ marginRight: 5 }}
          />
        )}
        <a
          onClick={() => {
            if (file.mimetype.includes("image/")) {
              showDialog({
                ModalComponent: PreviewImageFileDialog,
                modalProps: {
                  file
                }
              });
            } else {
              downloadServerFile(file);
            }
          }}
        >
          {file.originalname}
        </a>
        {file.user && (
          <div
            className={Classes.TEXT_MUTED}
            style={{ marginLeft: 10, fontSize: 10 }}
          >
            Uploaded by {file.user?.username} ({displayDateTime(file.createdAt)}
            )
          </div>
        )}
      </div>
    );
  });
}

export default FileListSection;
