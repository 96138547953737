/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { getSortedTaggedItems } from "../utils/tagUtils";
import TagsRenderingWrapper from "../TagsRenderingWrapper";
import TagWithSetParams from "../TagWithSetParams";

export default function TagTableColumn({ record, paramProps }) {
  const sortedTaggedItems = getSortedTaggedItems(record);

  return (
    <TagsRenderingWrapper>
      {sortedTaggedItems.map(({ id, tag, tagOption }) => {
        return (
          <TagWithSetParams key={id} {...{ tag, tagOption, ...paramProps }} />
        );
      })}
    </TagsRenderingWrapper>
  );
}
