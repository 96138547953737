/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import { formInputsValidation } from "../validationFns";

const DNA_VENDOR_QUOTE = {
  description: "Setup endpoints for DNA Vendor Quote",
  bundles: [
    {
      id: "dna_quote_twist",
      name: "Twist DNA Quote",
      integrationHookFileUrl: "twistOrderingQuote.json",
      nodeRedFileUrl: "twistQuoteNodeRed.json"
    }
  ],
  endpoints: {
    VENDOR__FULL__QUOTE: {
      name: "Quote",
      description: "Get the full quote id for a vendor order",
      method: "GET",
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            vendorLineItemReferenceIds: yup.array(yup.string())
          });
        },
        vendorLineItemReferenceIds: [
          "35091c07-8ee5-4e2a-ae62-b112e8c585b4",
          "033d0427-686e-499d-a4c9-70a68907fee8"
        ]
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            dataFileId: yup.string().required()
          });
        },
        dataFileId: "35091c07-8ee5-4e2a-ae62-b112e8c585b4"
      }
    },
    VENDOR__FULL__QUOTE__OPTIONS: {
      name: "Quote Options",
      description: "Get options to be sent to quote endpoint",
      method: "GET",
      exampleRequest: "",
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            formInputs: formInputsValidation
          });
        },
        formInputs: [
          {
            fieldType: "SelectField",
            name: "address",
            label: "Address",
            options: [
              {
                value: {
                  id: "a485A00000CKvHTQA1",
                  first_name: "John",
                  last_name: "Doe",
                  address_type: "Shipping",
                  state: "CA",
                  state_name: "California",
                  street_1: "123 California St",
                  street_2: null,
                  residential: null,
                  zip_code: "94118",
                  city: "San Francisco",
                  organization: "TeselaGen",
                  country: "US",
                  country_name: "United States of America",
                  phone_number: null,
                  verification_status: "PENDING_REVIEW",
                  created_date: "2019-05-01T22:13:45Z",
                  billing_email: null,
                  google_place_id: null,
                  jaggaer_id: null,
                  is_default: false
                },
                label: "123 California St, San Francisco, CA 94118"
              },
              {
                value: {
                  id: "a485A00000CKvHdQAL",
                  first_name: "Jane",
                  last_name: "Doe",
                  address_type: "Shipping",
                  state: "CA",
                  state_name: "California",
                  street_1: "1501 Mariposa St",
                  street_2: null,
                  residential: "False",
                  zip_code: "94107",
                  city: "San Francisco",
                  organization: "TeselaGen Biotechnology",
                  country: "US",
                  country_name: "United States of America",
                  phone_number: null,
                  verification_status: "PENDING_REVIEW",
                  created_date: "2019-05-01T22:50:10Z",
                  billing_email: null,
                  google_place_id: null,
                  jaggaer_id: null,
                  is_default: true
                },
                label: "1501 Mariposa St, San Francisco, CA 94107"
              }
            ]
          }
        ]
      }
    }
  }
};

export default DNA_VENDOR_QUOTE;
