/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import { keyBy } from "lodash";
import {
  checkBarcodesAndFormat,
  maxWellVolumeError
} from "../utils/plateUtils";
import { taggedItems, extendedPropertyUploadFragment } from "./helperFragments";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import unitGlobals from "../unitGlobals";

export const requiredHeaders = [
  "PLATE_NAME",
  "WELL_LOCATION",
  "CELL_CULTURE_NAME",
  "CELL_LINE_NAME",
  "TOTAL_VOLUME",
  "TOTAL_VOLUMETRIC_UNIT"
];

export default async function handleCellCulturePlateImport(
  values,
  ctx = isoContext
) {
  const { safeQuery } = ctx;

  const { generateTubeBarcodes } = values;

  const {
    finishPlateCreate,
    newMaterials,
    filename,
    csvData,
    getCsvRowExtProps,
    containerArrayType,
    aliquotContainerType
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      barcodeHeader: "PLATE_BARCODE",
      nameHeader: "PLATE_NAME",
      hasReagents: true,
      hasExtendedProperties: true,
      requiredFields: requiredHeaders
    },
    ctx
  );

  const isRack = !containerArrayType.isPlate;
  const mapPlates = {};
  const cellLineNamesToCheck = [];
  const cellCultureNames = [];
  const addedPropsForStrain = {};

  const continueUpload = await checkBarcodesAndFormat({
    data: csvData,
    generateTubeBarcodes,
    filename,
    containerArrayType,
    tubeBarcodeKey: "TUBE_BARCODE",
    barcodeKey: "PLATE_BARCODE",
    wellPositionHeader: "WELL_LOCATION"
  });

  if (!continueUpload) return;

  // collect strain names to search for
  for (const row of csvData) {
    cellLineNamesToCheck.push(row.CELL_LINE_NAME);
    cellCultureNames.push(row.CELL_CULTURE_NAME);
  }

  const strainsInDb = await safeQuery(
    ["strain", `id name ${extendedPropertyUploadFragment}`],
    {
      variables: {
        filter: caseInsensitiveFilter("strain", "name", cellLineNamesToCheck, {
          additionalFilter: {
            strainTypeCode: "CELL_LINE"
          }
        })
      }
    }
  );

  const keyedStrains = keyBy(strainsInDb, s => s.name.toLowerCase());

  const strainIds = [];
  for (const [index, row] of csvData.entries()) {
    const strain = keyedStrains[row.CELL_LINE_NAME.toLowerCase()];
    if (!strain) {
      throw new Error(
        `Row ${index + 1} specifies the cell line ${
          row.CELL_LINE_NAME
        } which doesn't exist.`
      );
    } else {
      row.strain = strain;
      strainIds.push(strain.id);
    }
  }

  // strain id for microbial material deduplication, need to make sure that code still works
  const materialFragment = [
    "material",
    `
        id
        name
        strainId
        ${taggedItems}
      `
  ];

  // an existing cell culture is one who's name and strain id match what is in the csv
  const existingCellCultures = await safeQuery(materialFragment, {
    variables: {
      filter: {
        strainId: strainIds,
        name: cellCultureNames
      }
    }
  });

  const keyedCellCultures = keyBy(
    existingCellCultures,
    c => `${c.name}:${c.strainId}`
  );

  for (const [index, row] of csvData.entries()) {
    const {
      PLATE_NAME: plateName,
      SAMPLE_NAME: sampleName,
      CELL_CULTURE_NAME: cellCultureName,

      PLATE_BARCODE: plateBarcode,
      TOTAL_VOLUME: volume,
      TOTAL_VOLUMETRIC_UNIT: _volumetricUnit,
      TUBE_BARCODE: tubeBarcode,
      strain,
      rowPosition,
      columnPosition,
      rowKey
    } = row;

    const existingCellCulture =
      keyedCellCultures[`${cellCultureName}:${strain.id}`];

    let materialId;
    if (existingCellCulture) {
      materialId = existingCellCulture.id;
    } else {
      const cid = shortid();
      const newCellCulture = {
        cid,
        name: cellCultureName,
        materialTypeCode: "CELL_CULTURE",
        strainId: strain.id
      };
      materialId = `&${cid}`;
      newMaterials.push(newCellCulture);
    }

    if (!addedPropsForStrain[strain.id]) {
      addedPropsForStrain[strain.id] = true;

      getCsvRowExtProps({
        row,
        modelTypeCode: "STRAIN",
        recordId: strain.id,
        record: strain,
        typeFilter: "cell line"
      });
    }

    if (!mapPlates[rowKey]) {
      const plateCid = shortid();
      getCsvRowExtProps({
        row,
        modelTypeCode: "CONTAINER_ARRAY",
        typeFilter: ["plate", "rack"],
        recordId: `&${plateCid}`
      });

      mapPlates[rowKey] = {
        cid: plateCid,
        name: plateName,
        containerArrayTypeId: containerArrayType.id,
        ...(plateBarcode && {
          barcode: {
            barcodeString: plateBarcode
          }
        }),
        aliquotContainers: []
      };
    }

    const totalVolumetricUnit = unitGlobals.volumetricUnits[_volumetricUnit];
    if (!totalVolumetricUnit) {
      throw new Error(
        `Row ${
          index + 1
        } specifies the total volumetric unit ${_volumetricUnit} which doesn't exist.`
      );
    }

    const maxVolumeError = maxWellVolumeError({
      volume,
      unit: totalVolumetricUnit,
      containerArrayType,
      aliquotContainerType,
      index
    });
    if (maxVolumeError) {
      throw new Error(maxWellVolumeError);
    }
    const barcodeField = {};
    if (!generateTubeBarcodes) {
      barcodeField.barcode = {
        barcodeString: tubeBarcode
      };
    }

    const aliquotCid = shortid();
    const sampleCid = shortid();
    const aliquot = {
      cid: aliquotCid,
      aliquotType: "sample-aliquot",
      isDry: false,
      volume,
      volumetricUnitCode: totalVolumetricUnit.code,
      additives: row.additives,
      sample: {
        cid: sampleCid,
        name: sampleName || cellCultureName,
        materialId,
        sampleTypeCode: "REGISTERED_SAMPLE"
      }
    };
    getCsvRowExtProps({
      row,
      modelTypeCode: "ALIQUOT",
      typeFilter: "aliquot",
      recordId: `&${aliquotCid}`
    });
    getCsvRowExtProps({
      row,
      modelTypeCode: "SAMPLE",
      typeFilter: "sample",
      recordId: `&${sampleCid}`
    });

    const tubeCid = shortid();
    if (isRack) {
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT_CONTAINER",
        typeFilter: "tube",
        recordId: `&${tubeCid}`
      });
    }
    mapPlates[rowKey].aliquotContainers.push({
      cid: tubeCid,
      aliquotContainerTypeCode: isRack
        ? aliquotContainerType.code
        : containerArrayType.aliquotContainerType.code,
      rowPosition,
      columnPosition,
      ...barcodeField,
      aliquot
    });
  }
  const platesToCreate = Object.values(mapPlates);

  const createdPlates = await finishPlateCreate({
    newPlates: platesToCreate
  });

  if (!createdPlates.length) {
    throw new Error("No plates to create.");
  }

  return createdPlates;
}
