/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment assaySubjectRecordFragment on assaySubject {
    id
    name
    createdAt
    updatedAt
    assaySubjectClass {
      id
      name
    }
    descriptorValues {
      id
      descriptorTypeId
      descriptorType {
        id
        urlTemplate
        externalSourceSystem {
          id
          url
        }
      }
      value
    }
  }
`;

// TODO: Change this fragment so that the route to the assay object
// goes via the AssaySubejctGroup table. This route is implemented in the DB
// but it's not used when creating subjects yet!
export const assaySubjectResultsFragment = gql`
  fragment assaySubjectResultsFragment on assaySubjectResult {
    id
    assaySubjectId
    result {
      id
      assay {
        id
        name
      }
    }
  }
`;
