/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { get } from "lodash";
import { NumericInputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import IntermediateContainerTypeFields from "../../../IntermediateContainerTypeFields";
import {
  standardizeVolume,
  toDecimalPrecision
} from "../../../../../src-shared/utils/unitUtils";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";
import { defaultVolumetricUnitCode } from "../../../../../../tg-iso-lims/src/utils/unitUtils";

class DiluentContainer extends Component {
  componentDidMount() {
    const { doCalculations } = this.props;
    doCalculations();
  }

  componentDidUpdate(oldProps) {
    const { intermediateContainerType: oldType } = oldProps;
    const { intermediateContainerType, doCalculations } = this.props;
    if (oldType !== intermediateContainerType) {
      doCalculations(true);
    }
  }

  normalizeNumDiluentContainers = value => {
    const { minNumContainerArrays } = this.props;
    const num = parseInt(value, 10);
    if (isNaN(num) || num < (minNumContainerArrays || 1))
      return minNumContainerArrays || 1;
    return value;
  };

  render() {
    const {
      intermediateContainerType,
      minNumContainerArrays,
      uniformDiluentAliquotContainerVolume,
      toolSchema
    } = this.props;
    const diluentContainerUnitCode = get(
      intermediateContainerType,
      "aliquotContainerType.volumetricUnitCode"
    );
    const diluentContainerUnit =
      unitGlobals.volumetricUnits[diluentContainerUnitCode] ||
      unitGlobals.volumetricUnits[defaultVolumetricUnitCode];

    let error;
    if (intermediateContainerType) {
      const maxIntermediateContainerVolume =
        standardizeVolume(
          intermediateContainerType.aliquotContainerType.maxVolume,
          intermediateContainerType.aliquotContainerType.volumetricUnitCode
        ) -
        (intermediateContainerType.aliquotContainerType.deadVolume
          ? standardizeVolume(
              intermediateContainerType.aliquotContainerType.deadVolume || 0,
              intermediateContainerType.aliquotContainerType
                .deadVolumetricUnitCode
            )
          : 0);

      if (
        uniformDiluentAliquotContainerVolume > maxIntermediateContainerVolume
      ) {
        error =
          "Minimum transfer volume would exceed diluent containers max volume. Please choose a larger diluent container.";
      }
    }
    return (
      <div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Select Diluent Container Type"
            helper="Select the type of diluent container to be used for normalization."
          />
          <div style={{ width: "40%" }}>
            <IntermediateContainerTypeFields
              noBarcodeInfo
              toolSchema={toolSchema}
              label="Diluent Container"
              error={error}
            />
          </div>
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Suggested Number of Diluent Containers"
            helper="Based on the selected diluent container
              type and the volume required to reach target
              concentration, we have calculated the minimum
              number of diluent containers required for
              normalization."
          />
          <div style={{ width: "40%" }}>
            <NumericInputField
              label="Number of Diluent Containers"
              name="numberOfDiluentContainers"
              defaultValue={minNumContainerArrays}
              normalize={this.normalizeNumDiluentContainers}
              enableReinitialize
            />
            {intermediateContainerType && (
              <div className="input-with-unit-select">
                Minimum Diluent Per Container:{" "}
                {toDecimalPrecision(
                  uniformDiluentAliquotContainerVolume /
                    diluentContainerUnit.liters
                ) +
                  " " +
                  diluentContainerUnit.code}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default stepFormValues("intermediateContainerType")(DiluentContainer);
