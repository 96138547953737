/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { wrapDialog, DialogFooter, InputField } from "@teselagen/ui";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";

function ExportFileNameDialog(props) {
  const {
    hideModal,
    handleSubmit,
    onCancel,
    onSubmit,
    customParams,
    defaultValueType
  } = props;

  async function onClickSubmit({ name }) {
    try {
      onSubmit(name);
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
    }
  }

  function onClickCancel() {
    onCancel();
    hideModal();
  }

  return (
    <React.Fragment>
      <div id="export-file-name-dialog" className={Classes.DIALOG_BODY}>
        <InputField
          name="name"
          label="File Name"
          isRequired
          generateDefaultValue={{
            ...defaultValueConstants[defaultValueType],
            customParams
          }}
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        secondaryAction={onClickCancel}
        onClick={handleSubmit(onClickSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog(({ title }) => ({
    title
  })),
  reduxForm({ form: "exportFileName" })
)(ExportFileNameDialog);
