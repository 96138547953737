/**
 * If the aliquot formulation tells us to add the destination aliquot to an
 * aliquot container, then add the required changes to the buffer. Otherwise,
 * do nothing
 *
 * @param {FullFormulation} fullFormulation
 * @param {string} aliquotId The destination aliquot id of the formulation
 * @param {WriteBuffer} writeBuffer
 */
function linkToAliquotContainer(fullFormulation, aliquotId, writeBuffer) {
  const aliquotContainerId = fullFormulation.getAliquotContainerId();
  if (aliquotContainerId) {
    writeBuffer.upsert("aliquotContainer", {
      id: aliquotContainerId,
      aliquotId
    });
  }
}

export default linkToAliquotContainer;
