/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const CARD_Y_SPACING_OUTPUT = 2 * 145;
export const CARD_Y_SPACING_EXPANDED = 2 * 210;
export const CARD_DEFAULT_Y_SPACING = 2 * 120;
// export const CARD_Y_SPACING_OUTPUT = 2*288

// export const DESIGN_ELEMENT_CARD_BASE_WIDTH = 2*200

// Needs to be non-zeros or else the SBOL icons will sometimes take up two lines.
export const DESIGN_ELEMENT_CARD_BASE_WIDTH = 2 * 50; // 5

export const DESIGN_ELEMENT_CARD_MIN_WIDTH = 2 * 100;
export const DEFAULT_NODE_WIDTH = 2 * DESIGN_ELEMENT_CARD_BASE_WIDTH + 80;
// export const DEFAULT_NODE_WIDTH = 2*150 // 200 + 80
export const ATOMIC_ELEMENT_WIDTH = 2 * 40;

// export const EXTRA_SPACE_BETWEEN_BRANCHES = 2*80

// These include any padding around the true svg icon sizes.
export const SBOL_ICON_WIDTH = 2 * 45;
export const SBOL_ICON_HEIGHT = 2 * 44;

// Padding for each side, i.e. both left and right paddings are 25.
export const DESIGN_ELEMENT_CARD_X_PADDING = 2 * 5;

export const SIBLING_DESIGN_ELEMENT_CARD_SEPARATION = 2 * 60;

export const CARD_LEFT_TAB_WIDTH = 2 * 30; // 20
export const CARD_RIGHT_TAB_WIDTH = 2 * 20;

export const DESIGN_STEP_SEPARATION = 2 * 20;

export const SET_HEADER_ITEM_HEIGHT = 2 * 8;

export const OPERATION_CARD_Y_OFFSET = 2 * 60; // 150
export const OPERATION_CARD_WIDTH = 2 * 200;
export const OPERATION_CARD_PADDING = 2 * 8;
//
export const OPERATION_CARD_HEIGHT = 2 * 45;

export const LIST_ELEMENT_ROW_HEIGHT = 2 * 18; // 15
export const ROW_INDEX_WIDTH = 2 * 18;

// export const DESIGN_CARD_BASE_HEIGHT = 2*98
export const DESIGN_CARD_BASE_HEIGHT = 1.5 * 90 - 7;

export const DESIGN_CARD_MIN_WIDTH =
  SBOL_ICON_WIDTH * 2 + 2 * DESIGN_ELEMENT_CARD_X_PADDING;

export const OUTPUT_VIEW_EXTRA_HEIGHT = 2 * 10;

export const X_OFFSET_PER_UNIT_DEPTH = 50; // 2 * 100 || 75

export const DEFAULT_SPACE_BETWEEN_CARDS = 2 * 10;

export const VERTICAL_MINIMAP_THICKNESS = 250;
export const HORIZONTAL_MINIMAP_THICKNESS = 125;

export const VALIDATOR_HEIGHT = 25;
export const VALIDATOR_ICON_WIDTH = 25;

export const REACTION_COLORS = [
  "#0081cb",
  "#32ad70",
  "#9865c1",
  "#ff9600",
  "#c46f89"
];
