/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { XMLParser } from "fast-xml-parser";
import { anyToJson } from "@teselagen/bio-parsers";

import arrayify from "./arrayify";
import { map, omit } from "lodash";

const importDivaXml = async (divaXmlString, designName) => {
  try {
    const result = new XMLParser({
      ignoreAttributes: false
      // isArray: () => true
    }).parse(divaXmlString);
    const {
      "de:design": {
        // "de:eugeneRules": eugeneRules,
        "de:j5Collection": {
          "de:isCircular": isCircular,
          "de:isOnePerRowLayout": isOnePerRowLayout,
          "de:j5Bins": { "de:j5Bin": columns }
        },
        "de:partVOs": { "de:partVO": parts },
        "de:eugeneRules": { "de:eugeneRule": eugeneRules },
        "de:sequenceFiles": { "de:sequenceFile": seqFiles }
      }
    } = result;
    const eugene_rules = [];
    const partsById = {};
    const partNamesToIds = {};
    const emptyPartsById = {};
    const seqIdToParts = {};
    const seqIdToForceCircular = {};
    arrayify(parts).forEach(
      ({
        "de:name": name,
        "de:revComp": revComp,
        "de:sequenceFileID": sequenceFileID,
        "de:startBP": startBP,
        "de:stopBP": stopBP,
        "de:parts": { "de:part": subParts }
      }) => {
        if (!sequenceFileID) {
          arrayify(subParts).forEach(({ "@_id": id }) => {
            emptyPartsById[id] = true;
          });
          return;
        }
        const tgPart = {
          // source_id: sequenceFileID,
          id: arrayify(subParts)[0]["@_id"],
          start: startBP - 1,
          end: stopBP - 1,
          name,
          strand: revComp ? -1 : 1
        };
        partNamesToIds[name] = tgPart.id;
        if (tgPart.start > tgPart.end) {
          // the user has defined a circular part on the sequence so we need to force the sequence to be circular
          seqIdToForceCircular[sequenceFileID] = true;
        }
        if (!seqIdToParts[sequenceFileID]) {
          seqIdToParts[sequenceFileID] = [];
        }
        seqIdToParts[sequenceFileID].push(tgPart);

        arrayify(subParts).forEach(({ "@_id": id, "de:fas": fas }) => {
          partsById[id] = { ...tgPart, fas };
        });
      }
    );

    arrayify(eugeneRules).forEach(er => {
      if (!er) return;
      const {
        "de:name": name,
        "de:negationOperator": negation_operator,
        "de:operand1ID": operand1,
        "de:operand2ID": operand2,
        "de:compositionalOperator": compositional_operator
      } = er;
      eugene_rules.push({
        name,
        negation_operator,
        operand1: partNamesToIds[operand1],
        operand2: partNamesToIds[operand2],
        compositional_operator
      });
    });
    const sequences = [];
    const array = arrayify(seqFiles);
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      const {
        "@_id": id,
        "de:content": content,
        "de:fileName": fileName,
        // "de:format": format,
        "de:icePartId": icePartId,
        // "de:partSource": partSource,
        "de:iceEntryURI": iceEntryURI
      } = element;
      const seq = await anyToJson(content, { fileName });

      if (!seq[0].success) {
        console.error(`Error with seq:`, seq);
        throw new Error(`Error parsing sequence file ${fileName}`);
      }
      sequences.push({
        ...seq[0].parsedSequence,
        id,
        ...(seqIdToForceCircular[id] && { circular: true }),
        parts: map(seqIdToParts[id], p => omit(p, ["fas"])),
        ...(iceEntryURI && {
          externalReferenceId: icePartId,
          externalReferenceUrl: iceEntryURI,
          externalReferenceType: "sequence",
          externalReferenceSystem: "JBEI"
        })
      });
    }

    const toReturn = {
      sequences,
      eugene_rules,
      columns: columns.map(
        ({
          "de:binItems": { "de:partID": partIDs },
          "de:binName": binName,
          "de:direction": direction,
          // "de:dsf": dsf,
          "de:iconID": icon
        }) => {
          return {
            name: binName,
            icon,
            // direct_synthesis_firewall: dsf,
            direction,
            parts: arrayify(partIDs).map(id => {
              const emptyPart = emptyPartsById[id];
              if (emptyPart) {
                return {
                  isEmpty: true
                };
              }
              const part = partsById[id];
              const fas = part.fas;
              return {
                id: part.id,
                ...(fas && { forced_assembly_strategy: fas })
              };
            })
          };
        }
      ),
      name: designName,
      circular: isCircular,
      layout_type: isOnePerRowLayout ? "list" : "combinatorial",
      assembly_method: "Gibson/SLIC/CPEC"
    };
    return toReturn;
  } catch (error) {
    console.error(error);
    throw new Error("Error parsing diva xml", error);
  }
};

export default importDivaXml;
