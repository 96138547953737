/**
 * The target and sources are all objects with arrays as values. This function
 * acts kind of like `Object.assign`, except keys common between sources and targets
 * will have their values merged.
 *
 * @param {Object} target The target object.
 * @param {Objects} sources The source object(s).
 */
export default function assignMapsToArrays(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources[0];
  for (const [key, value] of Object.entries(source)) {
    if (target[key]) {
      target[key].push(...value);
    } else {
      target[key] = [...value];
    }
  }

  return assignMapsToArrays(target, ...sources.slice(1));
}
