/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, times, cloneDeep } from "lodash";
import Big from "big.js";
import {
  sumBigs,
  maxBigs
} from "../../../../../tg-iso-lims/src/utils/bigIntUtils";
import {
  calculateConcentrationFromMolarity,
  standardizeCellConcentration
} from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { getAliquotMolecularWeight } from "../../../../../tg-iso-lims/src/utils/aliquotUtils";
import {
  standardizeVolume,
  standardizeConcentration,
  standardizeMass
} from "../../../../src-shared/utils/unitUtils";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

export function calculateMinimumNumberOfContainerArrays({
  intermediateContainerType,
  diluentTransferVolumes,
  overrideNumContainers,
  quadrantSize,
  numberOfDiluentContainers: userSpecifiedNumDiluentContainers
}) {
  const maxVolume = get(
    intermediateContainerType,
    "aliquotContainerType.maxVolume"
  );
  if (!maxVolume) return;

  const maxVolumeUnitCode = get(
    intermediateContainerType,
    "aliquotContainerType.volumetricUnitCode"
  );
  const deadVolume =
    get(intermediateContainerType, "aliquotContainerType.deadVolume") || 0;
  const standardizedDeadVolume = standardizeVolume(
    deadVolume,
    intermediateContainerType.aliquotContainerType.deadVolumetricUnitCode ||
      "uL",
    true
  );

  let calculatedNumDiluentContainers;
  if (overrideNumContainers || !userSpecifiedNumDiluentContainers) {
    const standardizedMaxVolume = standardizeVolume(
      maxVolume,
      maxVolumeUnitCode,
      true
    );

    const newVolume = () => standardizedMaxVolume.minus(standardizedDeadVolume);
    let containerArrayVolume = newVolume();
    calculatedNumDiluentContainers = 1;
    const calcContainerArrayVolumeDilutionTransferVolumes = [
      ...diluentTransferVolumes
    ];
    while (true) {
      let currentDilution =
        calcContainerArrayVolumeDilutionTransferVolumes[0] || 0;
      calcContainerArrayVolumeDilutionTransferVolumes.forEach(val => {
        if (val && val.gt(currentDilution)) currentDilution = val;
      });
      calcContainerArrayVolumeDilutionTransferVolumes.splice(
        calcContainerArrayVolumeDilutionTransferVolumes.indexOf(
          currentDilution
        ),
        1
      );
      if (containerArrayVolume.minus(currentDilution).lt(0)) {
        calculatedNumDiluentContainers++;
        containerArrayVolume = newVolume();
      }
      containerArrayVolume = containerArrayVolume.minus(currentDilution);
      if (!calcContainerArrayVolumeDilutionTransferVolumes.length) break;
    }
  }

  const numDiluentAliquotContainers = new Big(
    calculatedNumDiluentContainers || userSpecifiedNumDiluentContainers
  );
  const calcVolumePerContainerArrayDilutionTransferVolumes = [
    ...diluentTransferVolumes
  ].map(val => new Big(val || 0));
  const totalDiluentTransferVolume = sumBigs(
    calcVolumePerContainerArrayDilutionTransferVolumes
  );
  let uniformDiluentAliquotContainerVolume = totalDiluentTransferVolume.div(
    numDiluentAliquotContainers.times(quadrantSize)
  );
  if (
    uniformDiluentAliquotContainerVolume.lt(
      maxBigs(calcVolumePerContainerArrayDilutionTransferVolumes)
    )
  ) {
    uniformDiluentAliquotContainerVolume = maxBigs(
      calcVolumePerContainerArrayDilutionTransferVolumes
    );
  }

  return {
    userSpecifiedNumDiluentContainers,
    calculatedNumDiluentContainers,
    uniformDiluentAliquotContainerVolume
  };
}

export function getDiluentTransferVolumes({
  normalizationType,
  containerArrayToNormalize,
  selectedWellsForPlates,
  desiredConcentration,
  desiredConcentrationUnitCode,
  desiredCellConcentration,
  desiredCellConcentrationUnitCode,
  desiredMolarity,
  desiredMolarityUnitCode,
  intermediateContainerType,
  transferSourcePlateToNormalizedPlate,
  desiredTransferVolume,
  desiredTransferVolumetricUnitCode
}) {
  const maxVolume = get(
    intermediateContainerType,
    "aliquotContainerType.maxVolume"
  );
  let hasDesiredValue;
  if (
    normalizationType === "molarity" &&
    isValidPositiveNumber(desiredMolarity)
  ) {
    hasDesiredValue = !new Big(desiredMolarity).eq(0);
  } else if (
    normalizationType === "concentration" &&
    isValidPositiveNumber(desiredConcentration)
  ) {
    hasDesiredValue = !new Big(desiredConcentration).eq(0);
  } else if (
    normalizationType === "cellConcentration" &&
    isValidPositiveNumber(desiredCellConcentration)
  ) {
    hasDesiredValue = !new Big(desiredCellConcentration).eq(0);
  }

  if (!containerArrayToNormalize || !hasDesiredValue || !maxVolume) return;

  let standardizedDesiredConcentration;
  let standardizedDesiredCellConcentration;

  if (normalizationType === "concentration") {
    standardizedDesiredConcentration = standardizeConcentration(
      desiredConcentration,
      desiredConcentrationUnitCode,
      true
    );
  } else if (normalizationType === "cellConcentration") {
    standardizedDesiredCellConcentration = standardizeCellConcentration(
      desiredCellConcentration,
      desiredCellConcentrationUnitCode,
      true
    );
  }

  let standardizedVolume;
  let desiredTransferVolumeToUse;
  if (isNaN(desiredTransferVolume)) {
    desiredTransferVolumeToUse = 0;
  } else {
    desiredTransferVolumeToUse = desiredTransferVolume;
  }
  if (
    transferSourcePlateToNormalizedPlate &&
    !new Big(desiredTransferVolumeToUse).eq(new Big(0))
  ) {
    standardizedVolume = standardizeVolume(
      desiredTransferVolume,
      desiredTransferVolumetricUnitCode,
      true
    );
  }
  const transferMap = {};

  const acsToUse = getAliquotContainersToNormalize({
    containerArrayToNormalize,
    selectedWellsForPlates
  });

  const diluentTransferVolumes = acsToUse.map(container => {
    const { aliquot } = container;
    if (!aliquot) return null;
    let dilutionVolume;
    if (aliquot.isDry) {
      const standardizedAliquotMass = standardizeMass(
        aliquot.mass,
        aliquot.massUnitCode,
        true
      );

      if (normalizationType === "molarity") {
        const desiredConcentrationForAliquot = calculateConcentrationFromMolarity(
          desiredMolarity,
          desiredMolarityUnitCode,
          getAliquotMolecularWeight(aliquot)
        );
        dilutionVolume = standardizedAliquotMass.div(
          desiredConcentrationForAliquot
        );
      } else if (normalizationType === "concentration") {
        dilutionVolume = standardizedAliquotMass.div(
          standardizedDesiredConcentration
        );
      } else if (normalizationType === "cellConcentration") {
        // not handling dry aliquots for cell concentration
      }
    } else {
      let standardizedAliquotConcentration;
      if (normalizationType === "cellConcentration") {
        standardizedAliquotConcentration = standardizeCellConcentration(
          aliquot.cellConcentration,
          aliquot.cellConcentrationUnitCode,
          true
        );
      } else if (aliquot.concentration) {
        standardizedAliquotConcentration = standardizeConcentration(
          aliquot.concentration,
          aliquot.concentrationUnitCode,
          true
        );
      } else {
        standardizedAliquotConcentration = calculateConcentrationFromMolarity(
          aliquot.molarity,
          aliquot.molarityUnitCode,
          getAliquotMolecularWeight(aliquot)
        );
      }

      let desiredConcentration = standardizedDesiredConcentration;
      if (normalizationType === "cellConcentration") {
        desiredConcentration = standardizedDesiredCellConcentration;
      }
      if (normalizationType === "molarity") {
        desiredConcentration = calculateConcentrationFromMolarity(
          desiredMolarity,
          desiredMolarityUnitCode,
          getAliquotMolecularWeight(aliquot)
        );
      }

      const standardizedAliquotVolume = standardizeVolume(
        aliquot.volume,
        aliquot.volumetricUnitCode,
        true
      );
      const volumeToUse = standardizedVolume || standardizedAliquotVolume;

      // if the aliquot is already at or below the target then we don't need to dilute
      if (standardizedAliquotConcentration.lte(desiredConcentration)) {
        return null;
      }
      const v2 = new Big(standardizedAliquotConcentration)
        .times(volumeToUse)
        .div(desiredConcentration);
      dilutionVolume = v2.minus(volumeToUse);
    }
    if (!transferMap[dilutionVolume]) transferMap[dilutionVolume] = [];
    transferMap[dilutionVolume].push(container);
    return dilutionVolume;
  });

  return {
    diluentTransferVolumes,
    transferMap
  };
}

export function calculateVolumePerDiluentAliquotContainer({
  uniformDiluentAliquotContainerVolume,
  numDiluentAliquotContainers,
  diluentTransferVolumes,
  intermediateContainerName,
  diluentAliquotContainerType,
  transferMap,
  containerFormat
}) {
  const transferMapToUse = cloneDeep(transferMap);
  const { quadrantSize, rowCount, columnCount } = containerFormat;
  const diluentTransferVolumesToUse = [...diluentTransferVolumes];
  let column = 0,
    row = -1;

  const diluentAliquotContainers = times(
    numDiluentAliquotContainers * quadrantSize,
    i => {
      const name =
        intermediateContainerName + " " + Math.floor(i / quadrantSize + 1);
      if (column % columnCount === 0) row++;
      if (row === rowCount) row = 0;
      return {
        id: i,
        name,
        columnPosition: column++ % columnCount,
        rowPosition: row,
        transferVolume: new Big(0),
        transfers: [],
        aliquotContainerType: diluentAliquotContainerType,
        aliquotContainerTypeCode: get(diluentAliquotContainerType, "code")
      };
    }
  );

  for (let i = diluentTransferVolumesToUse.length - 1; i >= 0; i--) {
    const containerWithLowestTransferVolume = getContainerWithLowestTransferVolume(
      diluentAliquotContainers
    );
    const volume = new Big(diluentTransferVolumesToUse[i]);
    containerWithLowestTransferVolume.transferVolume = containerWithLowestTransferVolume.transferVolume.add(
      volume
    );
    containerWithLowestTransferVolume.transfers.push({
      aliquotContainer: transferMapToUse[volume].pop(),
      transferVolume: volume
    });
    if (
      containerWithLowestTransferVolume.transferVolume.gt(
        new Big(uniformDiluentAliquotContainerVolume)
      )
    ) {
      return calculateVolumePerDiluentAliquotContainer({
        uniformDiluentAliquotContainerVolume:
          containerWithLowestTransferVolume.transferVolume,
        diluentAliquotContainerType,
        intermediateContainerName,
        numDiluentAliquotContainers,
        diluentTransferVolumes,
        transferMap,
        containerFormat
      });
    }
  }
  return { diluentAliquotContainers, uniformDiluentAliquotContainerVolume };
}

function getContainerWithLowestTransferVolume(containers) {
  let targetContainerIndex = 0;
  let lowestTransferVolume = containers[0].transferVolume;

  containers.forEach((container, i) => {
    if (container.transferVolume.lt(lowestTransferVolume)) {
      targetContainerIndex = i;
      lowestTransferVolume = container.transferVolume;
    }
  });
  return containers[targetContainerIndex];
}

export function getSourceVolumetricUnit(sourceContainerArray) {
  let volumetricUnitCode, volumetricUnit;
  if (!sourceContainerArray.containerArrayType.isPlate) {
    volumetricUnitCode = get(
      sourceContainerArray,
      "aliquotContainers[0].aliquotContainerType.volumetricUnitCode"
    );
    volumetricUnit = unitGlobals.volumetricUnits[volumetricUnitCode];
  } else {
    volumetricUnitCode = get(
      sourceContainerArray,
      "containerArrayType.aliquotContainerType.volumetricUnitCode"
    );
    volumetricUnit = unitGlobals.volumetricUnits[volumetricUnitCode];
  }
  return {
    volumetricUnit,
    volumetricUnitCode
  };
}

export function getAliquotContainersToNormalize({
  containerArrayToNormalize,
  selectedWellsForPlates = {}
}) {
  if (!containerArrayToNormalize) {
    return [];
  }
  let acsToUse = containerArrayToNormalize.aliquotContainers;
  const selectedWells = selectedWellsForPlates[containerArrayToNormalize.id];
  if (selectedWells?.length) {
    acsToUse = acsToUse.filter(ac =>
      selectedWells.includes(getAliquotContainerLocation(ac))
    );
  }
  return acsToUse;
}
