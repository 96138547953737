/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const integrationRequestPlateAdditivesFragment = gql`
  fragment integrationRequestPlateAdditivesFragment on additive {
    id
    lot {
      id
      name
      additiveMaterial {
        id
        name
      }
    }
    additiveMaterial {
      id
      name
    }
  }
`;

export default gql`
  fragment integrationRequestPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        is2DLabeled
        rowCount
        columnCount
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      additives {
        ...integrationRequestPlateAdditivesFragment
      }
      aliquot {
        id
        volume
        volumetricUnitCode
        mass
        massUnitCode
        concentration
        concentrationUnitCode
        molarity
        molarityUnitCode
        additives {
          ...integrationRequestPlateAdditivesFragment
        }
        sample {
          id
          name
          sampleTypeCode
          sampleStatusCode
          material {
            id
            name
            polynucleotideMaterialSequence {
              id
              size
            }
          }
          sampleFormulations {
            id
            materialCompositions {
              id
              material {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${integrationRequestPlateAdditivesFragment}
`;
