/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { groupBy } from "lodash";
import { padStart, times } from "lodash";
import { isoContext } from "@teselagen/utils";

export function generateBarcode(dbId) {
  return "Z" + padStart(Number(dbId).toString(16), 9, "0");
}

export async function generateBarcodeRecords(
  numToGenerate,
  { safeUpsert } = isoContext
) {
  const emptyBarcodesToCreate = times(numToGenerate, () => ({}));
  const barcodes = await safeUpsert(
    ["barcode", "id nid"],
    emptyBarcodesToCreate
  );
  const barcodeUpdates = barcodes.map(barcode => {
    return {
      id: barcode.id,
      barcodeString: generateBarcode(barcode.nid)
    };
  });
  return await safeUpsert(["barcode", "id barcodeString"], barcodeUpdates, {
    pageSize: barcodeUpdates.length
  });
}

export async function addBarcodesToRecords(
  maybeRecords,
  { safeUpsert, deleteWithQuery } = isoContext
) {
  const records = Array.isArray(maybeRecords) ? maybeRecords : [maybeRecords];
  if (!records.length) return;
  const emptyBarcodesToCreate = times(records.length, () => ({}));
  const barcodes = await safeUpsert(
    ["barcode", "id nid"],
    emptyBarcodesToCreate
  );

  // remove old barcodes first if they exist
  const groupedRecords = groupBy(records, "__typename");
  for (const group of Object.values(groupedRecords)) {
    await deleteWithQuery("barcode", {
      [group[0].__typename + "Id"]: group.map(r => r.id)
    });
  }

  const barcodeUpdates = barcodes.map((barcode, i) => {
    return {
      id: barcode.id,
      barcodeString: generateBarcode(barcode.nid),
      [records[i].__typename + "Id"]: records[i].id
    };
  });
  await safeUpsert("barcode", barcodeUpdates, {
    excludeResults: true,
    pageSize: barcodeUpdates.length
  });
}
