/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Classes } from "@blueprintjs/core";
import React from "react";
import { compose } from "recompose";
import { reduxForm, formValues } from "redux-form";
import {
  BlueprintError,
  DialogFooter,
  wrapDialog,
  CheckboxField
} from "@teselagen/ui";
import classNames from "classnames";

import TagField from "../../../../src-shared/TagField";
import handleDnaPlateImport from "../../../../../tg-iso-lims/src/plateImports/handleDnaPlateOrTubeImport";
import PlateUploadFields from "../../PlateUploadFields";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { defaultReagentFields } from "../../../../../tg-iso-lims/src/utils/getReagentsFromCsv";
import {
  concentrationUnitCodeField,
  molarityUnitCodeField,
  massUnitCodeField,
  volumetricUnitCodeField,
  wellLocationField,
  sequenceTypeField
} from "../../../../../tg-iso-shared/src/utils/unitCodeFields";
import { reagentHandlingLogic } from "../../../utils/reagentHandlingLogic";

const csvHeaders = [
  {
    path: "PLATE_NAME",
    description: "The name of the plate.",
    example: "Plate1",
    isRequired: true
  },
  {
    path: "PLATE_BARCODE",
    description: "The barcode of the plate.",
    example: "123456789"
  },
  wellLocationField,
  {
    path: "TUBE_BARCODE",
    description: "The barcode of the tube.",
    example: "987654321"
  },
  {
    path: "SAMPLE_NAME",
    description: "The name of the sample.",
    example: "Sample1"
  },
  {
    path: "SEQUENCE_NAME",
    description: "The name of the sequence.",
    example: "Sequence1"
  },
  {
    path: "SEQUENCE",
    description: "The DNA sequence.",
    example: "ATCG"
  },
  sequenceTypeField,
  {
    path: "SEQUENCE_FILE",
    description: "The file containing the sequence.",
    example: "sequence1.gb"
  },
  {
    path: "MASS",
    description: "The mass of the sample.",
    example: "1",
    type: "number"
  },
  {
    ...massUnitCodeField,
    path: "MASS_UNIT"
  },
  {
    path: "VOLUME",
    description: "The volume of the sample.",
    example: "1",
    type: "number"
  },
  {
    ...volumetricUnitCodeField,
    path: "VOLUMETRIC_UNIT"
  },
  {
    path: "CONCENTRATION",
    description: "The concentration of the sample.",
    example: "1",
    type: "number"
  },
  { ...concentrationUnitCodeField, path: "concentrationUnit" },
  {
    path: "MOLARITY",
    description: "The molarity of the sample.",
    example: "1",
    type: "number"
  },
  {
    ...molarityUnitCodeField,
    path: "molarityUnit"
  }
];

export const submitDnaPlate = async (values, options) => {
  const { makeError } = options;
  try {
    return await handleDnaPlateImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.errors || error.message || "Error uploading plate.");
  }
};

export const UploadDnaPlateDialog = ({
  refetch,
  isRNAUpload,
  submitting,
  handleSubmit,
  hideModal,
  error,
  noDialog,
  nonSequence,
  fieldPrefix = ""
}) => {
  const onSubmit = async values => {
    await submitDnaPlate(values, {
      makeError: throwFormError,
      refetch,
      isRNAUpload
    });
  };

  let headers = csvHeaders;

  if (isRNAUpload) {
    headers = headers.filter(h => h.path !== "SEQUENCE_TYPE");
  }

  if (nonSequence) {
    const index = headers.findIndex(h => h.path === "SEQUENCE_NAME");
    headers[index] = {
      path: "MATERIAL_NAME",
      description: "The name of the material.",
      example: "Material1"
    };
    headers = headers.filter(h => !h.path.includes("SEQUENCE"));
  }
  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <PlateUploadFields
          inTool={noDialog}
          fieldPrefix={fieldPrefix}
          templateFileOptions={{
            fileName: isRNAUpload ? "RNA_Plate" : "DNA_Plate",
            validateAgainstSchema: {
              ...reagentHandlingLogic,
              fields: [...headers, ...defaultReagentFields]
            },
            extendedPropTypes: [
              "plate",
              ...(nonSequence ? [] : ["sequence"]),
              "tube",
              "aliquot",
              "sample",
              "material"
            ],
            helper:
              "Upload one or more plate CSVs and any associated sequence GenBank files."
          }}
        />
        {window.frontEndConfig.enableNonSequenceDnaMaterials && (
          <CheckboxField
            name="nonSequence"
            label="Upload Materials Without Sequence Data"
            tooltipInfo="If checked, the plate's materials will not be linked to DNA sequences."
          />
        )}
        <TagField />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export default compose(
  wrapDialog({
    getDialogProps: props => {
      let title;
      if (props.isRNAUpload) {
        title = "Upload RNA Plate";
      } else {
        title = "Upload DNA Plate";
      }
      return { title };
    }
  }),
  reduxForm({
    form: "uploadDnaPlate"
  }),
  formValues("nonSequence")
)(UploadDnaPlateDialog);
