/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import {
  withSelectedEntities,
  DataTable,
  getSelectedEntities,
  SwitchField
} from "@teselagen/ui";
import { get, keyBy } from "lodash";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Button, MenuItem } from "@blueprintjs/core";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import AddReagents from "../../AddReagentsTool/AddReagents";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import worklistExecuteFragment, {
  worklistExecuteTransferFragment
} from "../../../../graphql/fragments/worklistExecuteFragment";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  collectExistingExtendedPropertyValuesForTable,
  collectExtendedProperties
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import {
  worklistRecordTubeTransferTableSchema,
  worklistTableSchema
} from "../../../Record/WorklistRecordView/worklistRecordTableSchema";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import safeQueryIgnoreBatch from "../../../../utils/safeQueryIgnoreBatch";
import { validateWorklist } from "../../../../../../tg-iso-lims/src/utils/worklistUtils";

class SelectWorklist extends React.Component {
  state = {
    loadingFullWorklists: false
  };

  renderReactionMapName = (val, worklist) => {
    const { reactionMaps = {} } = this.props;
    const reactionMapsForWorklist =
      reactionMaps[`worklistId${worklist.id}`] || [];
    return reactionMapsForWorklist.map(rm => rm.name).join(", ");
  };

  async componentDidUpdate() {
    const {
      partialSelectedWorklists: partialWorklistNoTransfers = [],
      worklists: _worklists = [],
      stepFormProps: { change },
      lastLoadedWorklistIds = [],
      toolIntegrationProps: { isDisabledMap = {} },
      reactionMaps = {}
    } = this.props;

    const newIds = partialWorklistNoTransfers.map(w => w.id);
    const wasAlreadyLoaded = newIds.every(id =>
      lastLoadedWorklistIds.includes(id)
    );

    if (wasAlreadyLoaded && newIds.length !== lastLoadedWorklistIds.length) {
      change("lastLoadedWorklistIds", newIds);
    }

    // reset
    if (!partialWorklistNoTransfers.length && _worklists.length) {
      change("worklists", []);
      change("lastLoadedWorklistIds", []);
    }

    const worklists = [];
    if (!wasAlreadyLoaded && partialWorklistNoTransfers.length) {
      change("lastLoadedWorklistIds", newIds);
      this.setState({
        loadingFullWorklists: true
      });
      try {
        const worklistErrorInfo = {};
        for (const partialNoTransfer of partialWorklistNoTransfers) {
          const worklistTransfers = await safeQueryIgnoreBatch(
            worklistExecuteTransferFragment,
            {
              variables: {
                filter: {
                  worklistId: partialNoTransfer.id
                }
              }
            }
          );
          const fullWorklist = {
            ...partialNoTransfer,
            worklistTransfers
          };

          worklists.push(fullWorklist);
        }
        for (const worklist of worklists) {
          const validationInfo = validateWorklist(worklist, worklists);
          worklistErrorInfo[worklist.id] = {
            errors: validationInfo.errors.length
              ? validationInfo.errors
              : undefined,
            warnings: validationInfo.warnings.length
              ? validationInfo.warnings
              : undefined
          };
        }

        change("worklistErrorInfo", worklistErrorInfo);
        change("worklists", worklists);

        if (!isDisabledMap["reactionMaps"]) {
          const reactionMapIds = [];
          worklists.forEach(worklist => {
            worklist.worklistReactionMaps.forEach(wrm => {
              reactionMapIds.push(wrm.reactionMapId);
            });
          });
          if (reactionMapIds.length) {
            const fetchedReactionMaps = await safeQuery(
              ["reactionMap", "id name updatedAt"],
              {
                variables: {
                  filter: {
                    id: reactionMapIds
                  }
                }
              }
            );
            const newReactionMaps = { ...reactionMaps };
            const keyedReactionMaps = keyBy(fetchedReactionMaps, "id");
            worklists.forEach(worklist => {
              worklist.worklistReactionMaps.forEach(wrm => {
                const key = "worklistId" + worklist.id;
                if (!newReactionMaps[key]) {
                  newReactionMaps[key] = [];
                } else {
                  newReactionMaps[key] = [...newReactionMaps[key]];
                }
                if (
                  !newReactionMaps[key].some(rm => rm.id === wrm.reactionMapId)
                ) {
                  newReactionMaps[key].push(
                    keyedReactionMaps[wrm.reactionMapId]
                  );
                }
              });
            });
            change("reactionMaps", newReactionMaps);
          }
        }
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error loading worklists");
        change("worklists", []);
        change("partialSelectedWorklists", []);
      }
      this.setState({
        loadingFullWorklists: false
      });
    }
  }

  beforeNextStep = () => {
    const {
      nextStep,
      stepFormProps: { change },
      partialSelectedWorklists = [],
      worklists: _worklists = [],
      existingSourceAliquotExtendedPropertiesTableSelectedEntities = [],
      existingSourceContainerArrayExtendedPropertiesTableSelectedEntities = [],
      setHasExtendedProperties
    } = this.props;

    const partialSelectedWorklistsIds = partialSelectedWorklists.map(w => w.id);
    const worklists = _worklists.filter(w =>
      partialSelectedWorklistsIds.includes(w.id)
    );

    if (worklists.length !== _worklists.length) {
      change("worklists", worklists);
    }

    const worklistToDestinationPlates = {};

    worklists.forEach(worklist => {
      worklist.worklistTransfers.forEach(t => {
        const destinationPlate = get(
          t,
          "destinationAliquotContainer.containerArray"
        );
        if (destinationPlate) {
          const location = getAliquotContainerLocation(
            t.destinationAliquotContainer
          );
          if (!worklistToDestinationPlates[worklist.id]) {
            worklistToDestinationPlates[worklist.id] = {
              plates: [],
              locationsToTransfer: {}
            };
          }
          const infoForWorklist = worklistToDestinationPlates[worklist.id];
          if (!infoForWorklist.plates.includes(destinationPlate)) {
            infoForWorklist.plates.push(destinationPlate);
          }
          if (!infoForWorklist.locationsToTransfer[destinationPlate.id]) {
            infoForWorklist.locationsToTransfer[destinationPlate.id] = [];
          }
          if (
            !infoForWorklist.locationsToTransfer[destinationPlate.id].includes(
              location
            )
          ) {
            infoForWorklist.locationsToTransfer[destinationPlate.id].push(
              location
            );
          }
        }
      });
    });

    change("worklistToDestinationPlates", worklistToDestinationPlates);

    const targetHelpers = {
      sourceContainerArrayIds: new Set(),
      sourceAliquotContainerIds: new Set(),
      destinationContainerArrayIds: new Set(),
      destinationAliquotContainerIds: new Set()
    };

    worklists.forEach(worklist => {
      worklist.worklistTransfers.forEach(
        ({
          sourceAliquotContainerId,
          sourceAliquotContainer,
          destinationAliquotContainerId,
          destinationAliquotContainer
        }) => {
          targetHelpers.sourceAliquotContainerIds.add(sourceAliquotContainerId);
          if (sourceAliquotContainer?.containerArrayId) {
            targetHelpers.sourceContainerArrayIds.add(
              sourceAliquotContainer.containerArrayId
            );
          }
          targetHelpers.destinationAliquotContainerIds.add(
            destinationAliquotContainerId
          );
          if (destinationAliquotContainer?.containerArrayId) {
            targetHelpers.destinationContainerArrayIds.add(
              destinationAliquotContainer.containerArrayId
            );
          }
        }
      );
    });

    const sourceExtendedProperties = {
      containerArray: {},
      aliquot: {}
    };

    const allWorklistExtendedProperties = {
      sourceContainerArrayExtendedPropertiesRemoved: {},
      sourceContainerArrayExtendedPropertiesAdded: {},
      sourceAliquotExtendedPropertiesRemoved: {},
      sourceAliquotExtendedPropertiesAdded: {},
      destinationContainerArrayExtendedPropertiesRemoved: {},
      destinationContainerArrayExtendedPropertiesAdded: {},
      destinationAliquotExtendedPropertiesRemoved: {},
      destinationAliquotExtendedPropertiesAdded: {}
    };

    const alreadyCheckedAliquotIds = [];
    const alreadyCheckedContainerArrayIds = [];
    // loop through worklists and get all extended properties

    worklists.forEach(worklist => {
      worklist.worklistContainerArrays.forEach(worklistContainerArray => {
        const isDest = targetHelpers.destinationContainerArrayIds.has(
          worklistContainerArray.containerArrayId
        );
        const isSource = targetHelpers.sourceContainerArrayIds.has(
          worklistContainerArray.containerArrayId
        );
        worklistContainerArray.worklistExtendedProperties.forEach(wExtProp => {
          const extProp = wExtProp.extendedPropertyToRemove;
          if (isDest) {
            allWorklistExtendedProperties.destinationContainerArrayExtendedPropertiesRemoved[
              extProp.id
            ] = extProp;
          }
          if (isSource) {
            allWorklistExtendedProperties.sourceContainerArrayExtendedPropertiesRemoved[
              extProp.id
            ] = extProp;
          }
        });
        if (isDest) {
          collectExistingExtendedPropertyValuesForTable(
            worklistContainerArray,
            allWorklistExtendedProperties.destinationContainerArrayExtendedPropertiesAdded
          );
        }
        if (isSource) {
          collectExistingExtendedPropertyValuesForTable(
            worklistContainerArray,
            allWorklistExtendedProperties.sourceContainerArrayExtendedPropertiesAdded
          );
        }
      });
      worklist.worklistTransfers.forEach(transfer => {
        transfer.worklistTransferAliquotContainers.forEach(wtAC => {
          const isDest = targetHelpers.destinationAliquotContainerIds.has(
            wtAC.aliquotContainerId
          );
          const isSource = targetHelpers.sourceAliquotContainerIds.has(
            wtAC.aliquotContainerId
          );
          if (!wtAC.isTargetAliquot) return;
          wtAC.transferExtendedProperties.forEach(wExtProp => {
            const extProp = wExtProp.extendedPropertyToRemove;
            if (isDest) {
              allWorklistExtendedProperties.destinationAliquotExtendedPropertiesRemoved[
                extProp.id
              ] = extProp;
            }
            if (isSource) {
              allWorklistExtendedProperties.sourceAliquotExtendedPropertiesRemoved[
                extProp.id
              ] = extProp;
            }
          });
          if (isDest) {
            collectExistingExtendedPropertyValuesForTable(
              wtAC,
              allWorklistExtendedProperties.destinationAliquotExtendedPropertiesAdded
            );
          }
          if (isSource) {
            collectExistingExtendedPropertyValuesForTable(
              wtAC,
              allWorklistExtendedProperties.sourceAliquotExtendedPropertiesAdded
            );
          }
        });
        const sourceAliquotContainer = transfer.sourceAliquotContainer;
        if (sourceAliquotContainer) {
          const aliquot = sourceAliquotContainer.aliquot;
          const containerArray = sourceAliquotContainer.containerArray;
          if (aliquot && !alreadyCheckedAliquotIds.includes(aliquot.id)) {
            alreadyCheckedAliquotIds.push(aliquot.id);
            collectExtendedProperties(
              aliquot,
              sourceExtendedProperties.aliquot
            );
          }
          if (
            containerArray &&
            !alreadyCheckedContainerArrayIds.includes(containerArray.id)
          ) {
            alreadyCheckedContainerArrayIds.push(containerArray.id);
            collectExtendedProperties(
              containerArray,
              sourceExtendedProperties.containerArray
            );
          }
        }
      });
    });

    // get selected entities and automatically select these values
    // in the table by using a change on the reduxFormSelectedEntityIdMap

    // existingSourceAliquotExtendedPropertiesTableSelectedEntities
    // existingSourceContainerArrayExtendedPropertiesTableSelectedEntities

    // acc[entity.id] = {
    //   entity
    // }

    const newAliquotSelectedEntityMap = {};
    const newContainerArraySelectedEntityMap = {};
    existingSourceAliquotExtendedPropertiesTableSelectedEntities.forEach(
      entity => {
        newAliquotSelectedEntityMap[entity.id] = { entity };
      }
    );
    existingSourceContainerArrayExtendedPropertiesTableSelectedEntities.forEach(
      entity => {
        newContainerArraySelectedEntityMap[entity.id] = { entity };
      }
    );

    const sourceExtPropContainerArrayEntities = Object.values(
      sourceExtendedProperties.containerArray
    );
    const sourceExtAliquotEntities = Object.values(
      sourceExtendedProperties.aliquot
    );

    change("aliquotExtPropSelectedEntityIdMap", newAliquotSelectedEntityMap);

    change(
      "containerArrayExtPropSelectedEntityIdMap",
      newContainerArraySelectedEntityMap
    );

    change("existingSourceExtendedProperties", {
      containerArray: sourceExtPropContainerArrayEntities,
      aliquot: sourceExtAliquotEntities
    });
    let hasExtendedProperties = false;
    const allWorklistExtendedPropertiesFormValue = Object.keys(
      allWorklistExtendedProperties
    ).reduce((acc, key) => {
      acc[key] = Object.values(allWorklistExtendedProperties[key]);
      if (acc[key].length) {
        hasExtendedProperties = true;
      }
      return acc;
    }, {});
    if (
      sourceExtPropContainerArrayEntities.length ||
      sourceExtAliquotEntities.length
    ) {
      hasExtendedProperties = true;
    }
    setHasExtendedProperties(hasExtendedProperties, () => {
      change(
        "allWorklistExtendedProperties",
        allWorklistExtendedPropertiesFormValue
      );
      nextStep();
    });
  };

  getWorklistFilter = (props, qb, currentParams) => {
    if (currentParams.showAllWorklists) return;
    else {
      return {
        worklistStatusCode: "PENDING"
      };
    }
  };

  render() {
    const { loadingFullWorklists } = this.state;
    const {
      validatingWorklists,
      executeWorklistSelectedEntities = [],
      stepFormProps: { change },
      stepFormProps,
      worklists = [],
      initialValues,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      reactionMaps = {},
      addReagents,
      Footer,
      footerProps,
      handleSubmit,
      toolSchema,
      submitting
    } = this.props;

    let selectedWorklist;
    if (executeWorklistSelectedEntities.length && !loadingFullWorklists) {
      selectedWorklist = worklists.find(w => {
        return w.id === executeWorklistSelectedEntities[0].id;
      });
    }
    const postSelectSchema = [
      "name",
      dateModifiedColumn,
      {
        displayName: "Linked Reaction Map",
        render: this.renderReactionMapName
      },
      {
        path: "Link Reaction Map",
        sortDisabled: true,
        resizable: false,
        width: 200,
        render: renderLinkReactionMap
      }
    ];
    if (loadingFullWorklists) {
      postSelectSchema.unshift({
        type: "action",
        width: 80,
        render: () => <Button loading minimal small />
      });
    }

    const EnhancedGenericSelectChildren = ({ tableParams }) => {
      const { currentParams, setNewParams } = tableParams;
      return (
        <SwitchField
          label="Only Show Pending Worklists"
          name="onlyShowPending"
          defaultValue={!currentParams.showAllWorklists}
          onFieldSubmit={val => {
            setNewParams({
              ...currentParams,
              showAllWorklists: !val
            });
          }}
        />
      );
    };

    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Worklist"
            helper="Select a worklist to execute."
            width="100%"
            menuItems={[
              <MenuItem
                key="addReagents"
                icon={addReagents ? "tick" : ""}
                shouldDismissPopover={false}
                text="Add Reagents to Destination Wells"
                onClick={() => {
                  change("addReagents", !addReagents);
                }}
              />
            ]}
          />
          <GenericSelect
            {...{
              name: "partialSelectedWorklists", //the field name within the redux form Field
              schema: [
                "name",
                { displayName: "Status", path: "worklistStatus.name" },
                dateModifiedColumn
              ],
              buttonProps: {
                loading: isLoadingMap.worklists,
                disabled: isDisabledMap.worklists
              },
              isRequired: true,
              postSelectDTProps: {
                formName: toolSchema.code,
                isViewable: !loadingFullWorklists,
                isSingleSelect: true,
                isDisabledMap,
                initialValues,
                reactionMaps,
                schema: postSelectSchema
              },
              tableParamOptions: {
                additionalFilter: this.getWorklistFilter
              },
              isMultiSelect: true,
              fragment: [
                "worklist",
                "id name worklistStatus { code name } updatedAt"
              ],
              additionalDataFragment: worklistExecuteFragment,
              additionalTableProps: {
                enhancedChildren: EnhancedGenericSelectChildren
              }
            }}
          />
          {selectedWorklist && (
            <DataTable
              schema={
                selectedWorklist.tubeTransfers.length
                  ? worklistRecordTubeTransferTableSchema
                  : worklistTableSchema
              }
              maxHeight={400}
              formName="worklistSelectionTable"
              entities={
                selectedWorklist.tubeTransfers.length
                  ? selectedWorklist.tubeTransfers
                  : selectedWorklist.worklistTransfers
              }
              isSimple
              withPaging
              defaults={{
                pageSize: 100
              }}
              destroyOnUnmount={false}
            />
          )}
        </div>
        {addReagents && (
          <AddReagents
            toolSchema={toolSchema}
            stepFormProps={stepFormProps}
            noEmptyWellOption
            forWorklist
          />
        )}
        {validatingWorklists && "Validating worklists..."}
        <Footer
          {...footerProps}
          loading={
            loadingFullWorklists ||
            submitting ||
            isLoadingMap.worklists ||
            validatingWorklists
          }
          onClick={handleSubmit(this.beforeNextStep)}
        />
      </div>
    );
  }
}

export default compose(
  connect((state, { toolSchema }) => {
    const executeWorklistSelectedEntities = getSelectedEntities(
      state,
      toolSchema.code
    );
    return {
      executeWorklistSelectedEntities
    };
  }),
  withSelectedEntities(
    "existingSourceAliquotExtendedPropertiesTable",
    "existingSourceContainerArrayExtendedPropertiesTable"
  ),
  stepFormValues(
    "addReagents",
    "reactionMaps",
    "validatingWorklists",
    "partialSelectedWorklists",
    "worklists",
    "targetHelpers",
    "lastLoadedWorklistIds"
  )
)(SelectWorklist);

const renderLinkReactionMap = (a, worklist, _, props) => {
  if (worklist.worklistTypeCode === "TUBE_TRANSFER") return null;
  return (
    <div className="width100 tg-flex justify-center">
      <GenericSelect
        {...{
          name: `reactionMaps[worklistId${worklist.id}]`, //the field name within the redux form Field
          schema: ["name", dateModifiedColumn],
          isMultiSelect: true,
          getButtonText,
          buttonProps: {
            disabled: props.isDisabledMap["reactionMaps"]
          },
          dialogProps: {
            noButtonClickPropagate: true // stop the button click from row selection
          },
          fragment: ["reactionMap", "id name updatedAt"]
        }}
      />
    </div>
  );
};

const getButtonText = val => (val ? "Change Link" : "Link");
