/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, RadioGroupField, wrapDialog } from "@teselagen/ui";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class SequencingQCReportRejectionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  onSubmit = async () => {
    const { hideModal, refetch, record, rejectionReason } = this.props;
    this.setState({ submitting: true });
    try {
      await safeUpsert("sequencingQualityControlReport", {
        id: record.id,
        sequencingQualityControlReportStatusCode: rejectionReason
      });
      await safeUpsert("sample", {
        id: record.sampleId,
        sampleStatusCode: "INVALID"
      });
      await refetch();
      hideModal();
      window.toastr.success(
        `Successfully rejected DNA sequencing quality control report`
      );
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error updating DNA sequencing quality control report status`
      );
      this.setState({ submitting: false });
    }
  };

  render() {
    const { hideModal } = this.props;
    const { submitting } = this.state;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <RadioGroupField
            name="rejectionReason"
            inline
            defaultValue="Rejected"
            options={[
              {
                label: "General rejection of assembly",
                value: "Rejected"
              },
              {
                label: "Reject assembly based on insufficient coverage",
                value: "Rejected (Coverage)"
              },
              {
                label: "Reject assembly based on unwanted variants",
                value: "Rejected (Variants)"
              }
            ]}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={this.onSubmit}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog({ title: "Reject Assembly" }),
  reduxForm({
    form: "sequencingQCReportRejectionDialog"
  }),
  tgFormValues("rejectionReason")
)(SequencingQCReportRejectionDialog);
