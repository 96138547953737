/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

export const mentionRegex = /@\[([^\]]+?)\]\(([^\]]+?)\)/g;

export function transformCommentMessageWithMentions(message = "") {
  const messagePieces = [];
  let lastMatchEnd = 0;
  message.replace(mentionRegex, (match, username, userId, startIndex) => {
    const piece = message
      .slice(lastMatchEnd, startIndex + match.length)
      .replace(match, "");
    lastMatchEnd = startIndex + match.length;
    messagePieces.push(piece);
    messagePieces.push(
      <span
        key={startIndex}
        style={{
          fontWeight: "bold"
        }}
      >
        @{username}
      </span>
    );
  });
  messagePieces.push(message.slice(lastMatchEnd));
  return <span>{messagePieces}</span>;
}

export function transformCommentMessageWithMentionsToString(message = "") {
  const messagePieces = [];
  let lastMatchEnd = 0;
  message.replace(mentionRegex, (match, username, userId, startIndex) => {
    const piece = message
      .slice(lastMatchEnd, startIndex + match.length)
      .replace(match, "");
    lastMatchEnd = startIndex + match.length;
    messagePieces.push(piece);
    messagePieces.push(`@${username}`);
  });
  messagePieces.push(message.slice(lastMatchEnd));
  return messagePieces.join("");
}

export function getInitialMentions(message) {
  const mentions = [];
  message.replace(mentionRegex, (match, username, userId) => {
    mentions.push({
      id: userId,
      display: `@${username}`
    });
  });
  return mentions;
}

export function getLocationHashPathName() {
  const location = window.location.origin + window.location.pathname;
  const pathName = window.location.pathname;
  const hash = window.location.hash;
  return {
    location,
    pathName,
    hash
  };
}
