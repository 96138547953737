/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect } from "react";
import { ReactSelectField } from "@teselagen/ui";
import GenericSelect from "../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../src-shared/stepFormValues";
import {
  dateModifiedColumn,
  j5ReportAssemblyHierarchicalColumns
} from "../../../src-shared/utils/libraryColumns";
import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";
import { assemblyReportEntityFilter } from "../../utils";

const reportSchema = [
  {
    path: "name"
  },
  ...j5ReportAssemblyHierarchicalColumns,
  dateModifiedColumn
];

const sequenceSchema = [
  "name",
  {
    displayName: "Length",
    path: "size"
  },
  dateModifiedColumn
];

function SelectSequencesFromAssemblyReport({ j5Reports = [], change }) {
  const additionalFilter = useCallback(
    (props, qb, currentParams) => {
      assemblyReportEntityFilter({
        j5Reports,
        entityFilter: currentParams.entityFilter,
        qb
      });
    },
    [j5Reports]
  );

  useEffect(() => {
    // do not want hanging sequences
    if (!j5Reports.length) {
      change("sequencesFromAssemblyReports", []);
    }
  }, [j5Reports, change]);

  return (
    <div>
      <HeaderWithHelper
        header={`Select Sequences from ${modelNameToReadableName("j5Report", {
          upperCase: true,
          plural: true
        })}`}
        helper={`Select a ${modelNameToReadableName(
          "j5Report"
        )} and then use the filter to specify which report sequences you would like to include.`}
      />
      <GenericSelect
        {...{
          name: "j5Reports",
          isMultiSelect: true,
          schema: reportSchema,
          fragment: [
            "j5Report",
            "id name isHierarchical outputCardName assemblyBatchId idFromOriginalAssemblyBatch treePosition"
          ],
          postSelectDTProps: {
            formName: "SelectSequencesFromAssemblyReportJ5ReportTable",
            schema: reportSchema
          }
        }}
      />
      {!!j5Reports.length && (
        <GenericSelect
          {...{
            name: "sequencesFromAssemblyReports",
            isMultiSelect: true,
            schema: sequenceSchema,
            fragment: ["sequence", "id name size"],
            tableParamOptions: {
              additionalFilter
            },
            nameOverride: "Sequences From Report",
            postSelectDTProps: {
              formName: "selectedSequencesFromAssemblyReport",
              schema: sequenceSchema
            },
            additionalTableProps: {
              enhancedChildren: ({ tableParams }) => {
                const { currentParams, setNewParams } = tableParams;
                return (
                  <ReactSelectField
                    name="entityFilter"
                    label="Entity Type"
                    onFieldSubmit={val => {
                      setNewParams({
                        ...currentParams,
                        // clear page so we don't get into a weird non-existing page state
                        page: undefined,
                        entityFilter: val
                      });
                    }}
                    options={[
                      {
                        label: "Input Sequences",
                        value: "j5InputSequence"
                      },
                      {
                        label: "Primary Templates",
                        value: "primaryTemplate"
                      },
                      { label: "Primers", value: "primer" },
                      {
                        label: "PCR Products",
                        value: "pcrProductSequence"
                      },
                      {
                        label: "Assembly Pieces",
                        value: "j5AssemblyPiece"
                      },
                      { label: "Constructs", value: "j5RunConstruct" }
                    ]}
                  />
                );
              }
            }
          }}
        />
      )}
    </div>
  );
}

export default stepFormValues("j5Reports")(SelectSequencesFromAssemblyReport);
