/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { dehydrationToolPlateFragment } from "../fragments";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { Callout } from "@blueprintjs/core";
import UnitInputField from "../../../UnitInputField";
import { defaultMassUnitCode } from "../../../../../../tg-iso-lims/src/utils/unitUtils";
import { compose } from "recompose";
import { map, isEmpty } from "lodash";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

const additionalFilter = (props, qb) => {
  qb.whereAll({
    displayFilter: qb.isNull()
  });
};

class SelectPlates extends React.Component {
  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      containerArrays = [],
      Footer,
      footerProps
    } = this.props;
    const missingConcentrationMap = {};

    const plateErrors = {};
    containerArrays.forEach(c => {
      let allDry = true;
      let noAliquots = true;
      c.aliquotContainers.forEach(ac => {
        const location = getAliquotContainerLocation({
          ...ac,
          containerArray: c
        });
        if (ac.aliquot) {
          noAliquots = false;
        }
        if (ac.aliquot && ac.aliquot.volume && !ac.aliquot.concentration) {
          missingConcentrationMap[c.name] =
            missingConcentrationMap[c.name] || [];
          missingConcentrationMap[c.name].push(location);
        }
        if (ac.aliquot && !ac.aliquot.isDry) {
          allDry = false;
        }
      });
      if (allDry || noAliquots) {
        plateErrors[c.id] = {
          _error: noAliquots
            ? "No aliquots on plate."
            : "All aliquots are already dry"
        };
      }
    });
    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates"
            helper="Select plates you would like to dehydrate"
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              isRequired: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              tableParamOptions: {
                additionalFilter: additionalFilter
              },
              postSelectDTProps: {
                formName: "addReagentsSelectPlatesTable",
                plateErrors,
                schema: [
                  platePreviewColumn({
                    plateErrors
                  }),
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" }
                ]
              },
              isMultiSelect: true,
              fragment: [
                "containerArray",
                "id name barcode { id barcodeString }"
              ],
              additionalDataFragment: dehydrationToolPlateFragment
            }}
          />
          {!isEmpty(missingConcentrationMap) && (
            <div style={{ marginTop: 20 }}>
              <Callout intent="warning" style={{ marginBottom: 12 }}>
                The following locations do not have concentration to compute
                mass. Please enter desired mass for them.
                {map(missingConcentrationMap, (locations, plateName) => {
                  return (
                    <div key={plateName}>
                      <span className="bold">{plateName}</span>:{" "}
                      {locations.join(", ")}
                    </div>
                  );
                })}
              </Callout>
              <UnitInputField
                {...{
                  isRequired: true,
                  name: "mass",
                  label: "Mass",
                  unitType: "massUnit",
                  unitName: "massUnitCode",
                  unitDefault: defaultMassUnitCode
                }}
              />
            </div>
          )}
        </div>
        <Footer
          {...footerProps}
          nextDisabled={!isEmpty(plateErrors)}
          errorMessage={!isEmpty(plateErrors) && "Fix errors on plates."}
        />
      </div>
    );
  }
}

export default compose(stepFormValues("containerArrays"))(SelectPlates);
