/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { DataTable, CollapsibleCard } from "@teselagen/ui";

export default class LineageCard extends Component {
  onDoubleClick = record => {
    const materialTypeCodeToRoute = {
      MICROBIAL: "microbial-materials",
      DNA: "dna-materials",
      PROTEIN: "protein-materials",
      CELL_CULTURE: "cell-cultures"
    };
    this.props.history.push(
      `/${materialTypeCodeToRoute[record.materialTypeCode]}/${record.id}`
    );
  };

  getParentMaterials = material => {
    return material.materialLineages.map(lineage => {
      return {
        id: lineage.parentMaterial.id,
        name: lineage.parentMaterial.name,
        materialTypeCode: lineage.parentMaterial.materialTypeCode
      };
    });
  };

  getChildMaterials = material => {
    return material.childrenMaterials.map(lineage => {
      return {
        id: lineage.childMaterial.id,
        name: lineage.childMaterial.name,
        materialTypeCode: lineage.childMaterial.materialTypeCode
      };
    });
  };

  render() {
    const { material } = this.props;

    const childMaterialSchema = {
      model: "material",
      fields: [
        {
          displayName: "Child Materials",
          path: "name"
        },
        {
          displayName: "Type",
          path: "materialTypeCode"
        }
      ]
    };

    const parentMaterialSchema = {
      model: "material",
      fields: [
        {
          displayName: "Parent Materials",
          path: "name"
        },
        {
          displayName: "Type",
          path: "materialTypeCode"
        }
      ]
    };

    return (
      <React.Fragment>
        <CollapsibleCard title="Material Lineage">
          <div className="tg-flex">
            <DataTable
              key="parentMaterials"
              schema={parentMaterialSchema}
              formName="parentMaterials"
              isSimple
              compact
              entities={this.getParentMaterials(material)}
              onDoubleClick={this.onDoubleClick}
            />
            <DataTable
              key="childMaterials"
              schema={childMaterialSchema}
              formName="childMaterials"
              isSimple
              compact
              entities={this.getChildMaterials(material)}
              onDoubleClick={this.onDoubleClick}
            />
          </div>
        </CollapsibleCard>
      </React.Fragment>
    );
  }
}
