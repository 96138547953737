/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { Loading } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";
// import MoveTubeDialogContainer from "../../containers/MoveTubeDialogContainer";
import aliquotContainerLocationDisplayFragment from "../../graphql/fragments/aliquotContainerLocationDisplayFragment";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { clearLocationConfirmation } from "../ClearLocationMenuItem";
import withLocationLink from "../../graphql/enhancers/withLocationLink";
import { safeQuery, deleteWithQuery } from "../../../src-shared/apolloMethods";

class TubeLocationDisplay extends Component {
  state = {
    clearingLocation: false
  };

  showPlaceByStrategyDialog = async () => {
    const {
      aliquotContainerId,
      containerArrayId,
      refetchLocationLink,
      refetchTube,
      refetchRack
    } = this.props;

    const plateIds = [];
    const tubeIds = [];
    let aliquotContainerType, containerArrayType;

    let aliquotContainer;
    if (aliquotContainerId) {
      aliquotContainer = await safeQuery(
        [
          "aliquotContainer",
          "id aliquotContainerType { code name } containerArrayId"
        ],
        {
          variables: {
            id: aliquotContainerId
          }
        }
      );
      aliquotContainerType = aliquotContainer.aliquotContainerType;
      tubeIds.push(aliquotContainerId);
    } else {
      const containerArray = await safeQuery(
        ["containerArray", "id containerArrayType { id name }"],
        {
          variables: {
            id: containerArrayId
          }
        }
      );
      containerArrayType = containerArray.containerArrayType;
      plateIds.push(containerArrayId);
    }

    const refetch = async () => {
      // if moving tube and it was placed into a rack we need to do a full refetch to update the record view
      if (aliquotContainerId) {
        const tubeUpdated = await safeQuery(
          ["aliquotContainer", "id containerArrayId"],
          {
            variables: {
              id: aliquotContainerId
            }
          }
        );
        const placedIntoRack =
          !aliquotContainer.containerArrayId && tubeUpdated.containerArrayId;
        const removedFromRack =
          aliquotContainer.containerArrayId && !tubeUpdated.containerArrayId;
        // moved to a different spot in rack
        if (
          aliquotContainer.containerArrayId &&
          aliquotContainer.containerArrayId === tubeUpdated.containerArrayId
        ) {
          await refetchRack();
        } else if (placedIntoRack || removedFromRack) {
          await refetchTube();
        }
      }
      await refetchLocationLink();
    };
    showDialog({
      modalType: "ASSIGN_PLATE_PLACEMENT_STRATEGY",
      modalProps: {
        plateIds,
        tubeIds,
        containerArrayType,
        aliquotContainerType,
        refetch
      }
    });
  };

  clearLocation = async () => {
    const {
      containerArrayId,
      aliquotContainerId,
      refetchLocationLink,
      refetchTube
    } = this.props;
    this.setState({
      clearingLocation: true
    });
    try {
      const continueClear = await clearLocationConfirmation({
        id: containerArrayId || aliquotContainerId,
        __typename: containerArrayId ? "containerArray" : "aliquotContainer"
      });
      if (continueClear) {
        await deleteWithQuery("assignedPosition", {
          containerArrayId,
          aliquotContainerId
        });
        await refetchLocationLink();
        if (refetchTube) {
          // to clear plate view
          await refetchTube();
        }
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error clearing location");
    }
    this.setState({
      clearingLocation: false
    });
  };

  render() {
    const {
      loadingLocationLink,
      aliquotContainerLoading,
      aliquotContainer,
      title,
      aliquotContainerId,
      containerArrayId,
      locationLink,
      assignedPositionId,
      location,
      history,
      readOnly
    } = this.props;
    const { clearingLocation } = this.state;

    if (loadingLocationLink || aliquotContainerLoading)
      return <Loading bounce />;
    if (aliquotContainerId && !aliquotContainer) {
      return "No Tube Found";
    }
    let titleToUse = title;
    if (containerArrayId && !aliquotContainerId) {
      titleToUse = "Plate Location";
    } else if (!titleToUse) {
      titleToUse = "Tube Information";
    }
    // const currentLocationName = getCurrentLocationName(positionPath);
    return (
      <div key="site-info">
        <div className="tg-flex align-center justify-space-between">
          <h6 style={{ marginRight: 10, marginTop: -2.5 }}>{titleToUse}</h6>
          <ButtonGroup minimal style={{ marginBottom: 10 }}>
            {!readOnly && (
              <Button
                intent="primary"
                small
                text={`Move ${aliquotContainerId ? "Tube" : "Plate"}`}
                icon="geolocation"
                onClick={this.showPlaceByStrategyDialog}
              />
            )}
            {!readOnly && !!assignedPositionId && (
              <Button
                intent="danger"
                small
                loading={clearingLocation}
                text="Clear Location"
                icon="trash"
                onClick={this.clearLocation}
              />
            )}
            {!location.pathname.includes("tubes") && !containerArrayId && (
              <Button
                intent="primary"
                small
                text="View Tube"
                icon="eye-open"
                onClick={() => history.push(modelNameToLink(aliquotContainer))}
              />
            )}
          </ButtonGroup>
        </div>
        <h6>Current Location: {locationLink}</h6>
      </div>
    );
  }
}

export default compose(
  withQuery(aliquotContainerLocationDisplayFragment, {
    skip: props => {
      return !props.aliquotContainerId;
    },
    options: props => {
      return {
        variables: {
          id: props.aliquotContainerId
        }
      };
    }
  }),
  withLocationLink,
  withRouter
)(TubeLocationDisplay);
