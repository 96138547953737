/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  CheckboxField,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import TagField from "../../../src-shared/TagField";

import PlateUploadFields from "../PlateUploadFields";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import handleEmptyTubeRackImport, {
  requiredHeaders,
  allHeaders
} from "../../../../tg-iso-lims/src/plateImports/handleEmptyTubeRackImport";

export async function submitEmptyTubeRack(values, options) {
  const { makeError } = options;
  try {
    return await handleEmptyTubeRackImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.errors || error.message || "Error uploading plates.");
  }
}

const UploadEmptyTubeRack = ({
  refetch,
  noDialog,
  submitting,
  handleSubmit,
  hideModal,
  fieldPrefix = "",
  error
}) => {
  const onSubmit = async values => {
    await submitEmptyTubeRack(values, {
      makeError: throwFormError,
      refetch
    });
  };

  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <PlateUploadFields
          fieldPrefix={fieldPrefix}
          inTool={noDialog}
          noNumTubes
          noFillOption
          noTubeBarcodeOption
          templateFileOptions={{
            fileName: "Empty Racks",
            headers: allHeaders,
            requiredHeaders
          }}
          genericSelectOptions={{
            label: "Rack Type",
            placeholder: "Select rack type...",
            additionalFilter: {
              isPlate: false
            }
          }}
        />
        <CheckboxField
          name={fieldPrefix + "generateBarcodes"}
          label="Generate Rack Barcodes"
        />
        <CheckboxField
          name={fieldPrefix + "generateTubeBarcodes"}
          label="Generate Tube Barcodes"
        />
        <TagField name={fieldPrefix + "tags"} />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Upload Empty Tube Racks"
  }),
  reduxForm({
    form: "uploadEmptyTubeRacks"
  })
)(UploadEmptyTubeRack);
