/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { withProps } from "recompose";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { SelectMaterials, FormatPlate, ReviewWorklist } from "./Steps";
import { getInvalidPlateMaps } from "../PlateRegistrationTool/utils";

import {
  plateMapGroupFragment,
  plateFragment,
  aliquotRearrayTableFragment
} from "./fragments";
import { REQUIRED_ERROR } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";

class AliquotRearrayTool extends React.Component {
  validate = values => {
    const {
      sourcePlates = [],
      dataTables = [],
      destinationPlateNames = [],
      destinationPlateTypes = [],
      transferType,
      transferVolume,
      transferMass,
      destinationPlates = [],
      destinationContainerFormat,
      validationPlateMapGroup,
      allAliquotContainers = [],
      isTubeTransfer
    } = values;
    const errors = {};
    if (!destinationContainerFormat) {
      errors.destinationContainerFormat =
        "Please select a container format for the destination plate(s).";
    }
    if (
      isTubeTransfer &&
      sourcePlates.some(p => p.containerArrayType?.isPlate)
    ) {
      errors.sourcePlates = "Can only select racks for tube transfers.";
    }
    if (
      destinationContainerFormat &&
      destinationContainerFormat.containerArrayTypes.length < 1
    ) {
      errors.destinationContainerFormat =
        "There are no plate types for the selected destination plate format. Please choose another format or create a new plate type in App Settings.";
    }
    if (!transferVolume) {
      errors.transferVolume = "Please enter desired transfer volume.";
    } else if (Number(transferVolume) <= 0) {
      errors.transferVolume = "Transfer volume must be greater than 0.";
    }

    if (validationPlateMapGroup) {
      const invalidPlateMaps = getInvalidPlateMaps([validationPlateMapGroup]);
      if (invalidPlateMaps.length) {
        errors.validationPlateMapGroup =
          "This plate map is not linked to materials properly. This tool only accepts plate maps created using the Create Plate Map tool.";
      }
    }

    if (transferType === "mass") {
      const missingConcentration = allAliquotContainers.some(ac => {
        return ac.aliquot && !ac.aliquot.concentration;
      });
      const missingAliquot = allAliquotContainers.some(ac => !ac.aliquot);
      if (missingAliquot) {
        errors.transferMass =
          "Not all source locations have aliquots. Transfer by mass is not allowed.";
      } else if (missingConcentration) {
        errors.transferMass =
          "Source plates have aliquots that are missing concentration. Concentration is needed for mass transfers.";
      } else if (!transferMass) {
        errors.transferMass = "Please enter desired transfer mass.";
      } else if (Number(transferMass) <= 0) {
        errors.transferMass = "Transfer mass must be greater than 0.";
      }
    }

    const destinationPlateNamesErrors = [];
    destinationPlateTypes.forEach((t, i) => {
      if (!destinationPlateNames[i])
        destinationPlateNamesErrors[i] = REQUIRED_ERROR;
    });
    errors.destinationPlateNames = destinationPlateNamesErrors;

    if (!sourcePlates.length && !dataTables.length) {
      errors.sourcePlates =
        "Must select at least one plate or list of inventory materials.";
      errors.dataTables =
        "Must select at least one plate or list of inventory materials.";
    }
    if (
      destinationPlates.length > 0 &&
      !validationPlateMapGroup &&
      destinationContainerFormat &&
      destinationPlates[0].containerArrayType.containerFormatCode !==
        destinationContainerFormat.code
    ) {
      errors.destinationPlates =
        "Destination container format must match selected destination plates.";
    }

    return errors;
  };

  onSubmit = async values => {
    const {
      toBeCreatedDestinationPlates = [],
      worklist,
      worklistName,
      generateBarcodes,
      isTubeTransfer
    } = values;
    let createdContainerArrays;
    try {
      if (toBeCreatedDestinationPlates.length) {
        const filledPlates = toBeCreatedDestinationPlates.map(plate => {
          return {
            ...plate,
            aliquotContainers: isTubeTransfer
              ? []
              : generateContainerArray(
                  plate.aliquotContainers,
                  plate.containerArrayType.containerFormat,
                  {
                    aliquotContainerTypeCode:
                      plate.containerArrayType.aliquotContainerTypeCode
                  }
                )
          };
        });
        const finalizedPlates = filledPlates.map(plate => {
          plate.containerArrayTypeId = plate.containerArrayType.id;
          delete plate.containerArrayType;
          return {
            ...plate,
            aliquotContainers: plate.aliquotContainers.map(aliquotContainer => {
              delete aliquotContainer.containerArray;
              return {
                ...aliquotContainer
              };
            })
          };
        });
        createdContainerArrays = await safeUpsert(
          "containerArray",
          finalizedPlates
        );
        if (generateBarcodes) {
          await addBarcodesToRecords(createdContainerArrays);
        }
      }
      const worklistToUpsert = stripFields(worklist, ["__typename"]);
      worklistToUpsert.name = worklistName;
      if (worklistToUpsert.tubeTransfers) {
        worklistToUpsert.tubeTransfers = worklistToUpsert.tubeTransfers.map(
          transfer => {
            delete transfer.sourceAliquotContainer;
            delete transfer.destinationRackName;
            delete transfer.destinationContainerArray;
            return transfer;
          }
        );
      } else {
        worklistToUpsert.worklistTransfers = worklistToUpsert.worklistTransfers.map(
          transfer => {
            delete transfer.sourceAliquotContainer;
            delete transfer.destinationAliquotContainer;
            delete transfer.destinationPlateName;
            return transfer;
          }
        );
      }
      const [createdWorklist] = await safeUpsert("worklist", worklistToUpsert);
      window.toastr.success("Successfully created worklist.");
      return {
        worklist: createdWorklist,
        containerArrays: createdContainerArrays
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating worklist."
      });
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Materials From Inventory",
        Component: SelectMaterials,
        withCustomFooter: true
      },
      {
        title: "Format Destination Plate",
        Component: FormatPlate,
        withCustomFooter: true
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plateFragment),
  withWorkflowInputs(aliquotRearrayTableFragment),
  withWorkflowInputs(plateMapGroupFragment, {
    singular: true
  }),
  withProps(props => {
    const { dataTables, containerArrays, plateMapGroup } = props;
    return {
      initialValues: {
        dataTables: dataTables,
        sourcePlates: containerArrays,
        validationPlateMapGroup: plateMapGroup
      }
    };
  })
)(AliquotRearrayTool);
