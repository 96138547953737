/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * Corresponds to `Downstream_Automation_Parameter_Set` in the perl code.
 *
 * A structure that contains a collection of information relevant to a set of downstream of j5 automation parameters.
 */
class DownstreamAutomationParameters {
  constructor(initialValues = {}) {
    const {
      max_delta_temperature_adjacent_zones = 5.0,
      max_delta_temperature_reaction_optimum_zone_acceptable = 5.0,
      max_mc_steps_per_zone = 1000,
      max_well_volume_multi_well_plate = 100.0,
      mc_temperature_final = 0.0001,
      mc_temperature_initial = 0.1,
      min_pipetting_volume = 5.0,
      n_columns_multi_well_plate = 12,
      n_rows_multi_well_plate = 8,
      PRPR_constraints_ok = null, // Should be boolean, I think it is computed later.
      trial_delta_temperature = 0.1,
      wells_per_thermocycler_zone = 16,
      zones_per_thermocycler_block = 6
    } = initialValues;

    // Initialize a set of downstream of j5 automation parameters to defaults.
    this.max_delta_temperature_adjacent_zones = max_delta_temperature_adjacent_zones;
    this.max_delta_temperature_reaction_optimum_zone_acceptable = max_delta_temperature_reaction_optimum_zone_acceptable;
    this.max_mc_steps_per_zone = max_mc_steps_per_zone;
    this.max_well_volume_multi_well_plate = max_well_volume_multi_well_plate;
    this.mc_temperature_final = mc_temperature_final;
    this.mc_temperature_initial = mc_temperature_initial;
    this.min_pipetting_volume = min_pipetting_volume;
    this.n_columns_multi_well_plate = n_columns_multi_well_plate;
    this.n_rows_multi_well_plate = n_rows_multi_well_plate;
    this.PRPR_constraints_ok = PRPR_constraints_ok;
    this.trial_delta_temperature = trial_delta_temperature;
    this.wells_per_thermocycler_zone = wells_per_thermocycler_zone;
    this.zones_per_thermocycler_block = zones_per_thermocycler_block;

    this.wells_per_thermocycler_block =
      this.zones_per_thermocycler_block * this.wells_per_thermocycler_zone;
  }
}

export default DownstreamAutomationParameters;
