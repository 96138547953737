/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { get, pick } from "lodash";
import { CollapsibleCard, DataTable } from "@teselagen/ui";
import Linkify from "react-linkify";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";

// import CustomerRequestOutputContainer from "../../../containers/CustomerRequestOutputContainer";
import AssociatedFilesSection from "../AssociatedFilesSection";
import customerRequestFragment from "../../../graphql/fragments/customerRequestFragment";
import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import CommentCard from "../../../../src-shared/CommentCard";
import { cascadeCustomerRequestTagsToJobsAndWorkflow } from "./utils";
import { formatDate } from "../../../../src-shared/utils/dateUtils";
import { Link } from "react-router-dom";

class CustomerRequestRecordView extends Component {
  newJobButton = () => {
    const { customerRequest, refetchCustomerRequest } = this.props;

    return (
      <Button
        intent={Intent.SUCCESS}
        minimal
        icon="add"
        text="New Job"
        style={{ marginTop: -3 }}
        onClick={() => {
          showDialog({
            modalType: "LaunchNewJobDialog",
            modalProps: {
              customerRequest,
              refetchCustomerRequest,
              initialValues: {
                customerRequests: [
                  {
                    id: customerRequest.id,
                    name: customerRequest.name,
                    __typename: "customerRequest"
                  }
                ]
              }
            }
          });
        }}
      />
    );
  };

  onRowDoubleClick = (datum /*, rowIndex, event*/) => {
    const { history } = this.props;
    // This is the corresponding id.
    const recordId = datum["key"];
    const route = "/jobs/" + recordId;
    history
      ? history.push(route)
      : console.warn("react router history not passed to this component");
  };

  getInitialValuesForEdit = () => {
    const { customerRequest } = this.props;
    return {
      ...pick(customerRequest, [
        "id",
        "customerRequestStatusTypeCode",
        "name",
        "outputDescription",
        "dueDate"
      ]),
      strains: customerRequest.customerRequestStrains.map(s => s.strain),
      sequences: customerRequest.customerRequestSequences.map(s => s.sequence),
      primaryCustomer: get(customerRequest, "primaryCustomer.user"),
      customers: customerRequest.customers.map(c => c.user)
    };
  };

  updateShowFunction = () => {
    const { customerRequest, refetchCustomerRequest, currentUser } = this.props;
    showDialog({
      modalType: "LaunchNewCustomerRequestDialog",
      modalProps: {
        initialValues: this.getInitialValuesForEdit(),
        requestType: customerRequest.requestType,
        initialCustomers: customerRequest.customers,
        initialPrimaryCustomer: customerRequest.primaryCustomer,
        currentUser: currentUser,
        refetch: refetchCustomerRequest
      }
    });
  };

  afterTag = async ({ selectedTags }) => {
    const { customerRequest } = this.props;
    await cascadeCustomerRequestTagsToJobsAndWorkflow({
      customerRequestIds: [customerRequest.id],
      selectedTags
    });
  };

  render() {
    const { customerRequest, refetchCustomerRequest, currentUser, readOnly } =
      this.props;
    const customers = customerRequest.customers || [];
    const customerRequestJobs = customerRequest.customerRequestJobs || [];

    const jobs = customerRequestJobs.map(customerRequestJob => {
      return customerRequestJob.job;
    });

    const customerColumns = [
      {
        displayName: "Name",
        path: "name"
      },
      {
        displayName: "Email",
        path: "email"
      }
    ];
    const customersTableData = customers.map(customer => {
      return {
        key: customer.user.id,
        name: customer.user.username,
        email: customer.user.email
      };
    });

    const jobColumns = [
      {
        displayName: "Name",
        path: "name"
      },
      {
        displayName: "Priority",
        path: "priorityTypeCode"
      },
      {
        displayName: "Due Date",
        path: "dueDate"
      }
    ];

    const jobsTableData = jobs.map(job => {
      return {
        key: job.id,
        name: job.name,
        priorityTypeCode: job.priorityTypeCode,
        dueDate: formatDate(job.dueDate)
      };
    });

    const additionalCards = (
      <div
        key="customer-request-cards"
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <div style={{ flex: 1, padding: 10 }}>
          <CollapsibleCard title="Users" icon="people">
            {customers.length ? (
              <DataTable
                compact
                isSimple
                formName="jobsCustomersTable"
                schema={customerColumns}
                entities={customersTableData}
              />
            ) : (
              <div style={{ textAlign: "center" }}>No assigned customers.</div>
            )}
          </CollapsibleCard>
          <CollapsibleCard
            title="Jobs"
            className="customer-request-jobs-section"
            icon="build"
            openTitleElements={!readOnly && this.newJobButton()}
          >
            {jobs.length ? (
              <DataTable
                compact
                isSimple
                formName="jobsCustomerRequest"
                schema={jobColumns}
                entities={jobsTableData}
                onDoubleClick={this.onRowDoubleClick}
              />
            ) : (
              <div style={{ textAlign: "center" }}>No assigned jobs.</div>
            )}
          </CollapsibleCard>
          <CollapsibleCard
            title="Request Type Details"
            className="request-type-info-section"
            icon="info-sign"
          >
            <RecordInfoDisplay
              recordInfo={[
                ["Request Type", customerRequest.requestType.name],
                ["Description", customerRequest.requestType.description],
                ["Instructions", customerRequest.requestType.instructions]
              ]}
            />
          </CollapsibleCard>
          {!!customerRequest.customerRequestStrains.length && (
            <CollapsibleCard title="Relevant Strains">
              <DataTable
                isSimple
                formName="relevantStrains"
                schema={[
                  {
                    displayName: "Name",
                    path: "name",
                    render: (name, r) => {
                      return <Link to={`/strains/${r.id}`}>{name}</Link>;
                    }
                  },
                  {
                    displayName: "Type",
                    path: "strainType.name"
                  }
                ]}
                entities={customerRequest.customerRequestStrains.map(
                  s => s.strain
                )}
              />
            </CollapsibleCard>
          )}
          {!!customerRequest.customerRequestSequences.length && (
            <CollapsibleCard title="Relevant Sequences">
              <DataTable
                isSimple
                formName="relevantSequences"
                schema={[
                  {
                    displayName: "Name",
                    path: "name",
                    render: (name, r) => {
                      return <Link to={`/sequences/${r.id}`}>{name}</Link>;
                    }
                  },
                  {
                    displayName: "Type",
                    path: "sequenceType.name"
                  }
                ]}
                entities={customerRequest.customerRequestSequences.map(
                  s => s.sequence
                )}
              />
            </CollapsibleCard>
          )}
        </div>
        <div style={{ flex: 1, padding: 10 }}>
          <CollapsibleCard
            title="Associated Files"
            className="customer-request-inputs-section"
            icon="document"
          >
            <AssociatedFilesSection
              noHeader
              files={customerRequest.customerRequestInputFiles}
              refetch={refetchCustomerRequest}
              fileTableName="customerRequestInputFile"
              record={customerRequest}
            />
          </CollapsibleCard>

          <CommentCard
            {...{
              currentUser,
              record: customerRequest
            }}
          />
        </div>
      </div>
    );
    return (
      <div className="record-container-with-cards">
        <AbstractRecord
          {...this.props}
          inCard
          recordInfo={[
            [
              "Feedback",
              <Linkify key="linked">
                {customerRequest.outputDescription}
              </Linkify>
            ],
            [
              "Primary User",
              get(customerRequest, "primaryCustomer.user.username")
            ],
            ["Status", customerRequest.customerRequestStatusType.name],
            [
              "Due Date",
              customerRequest.dueDate && formatDate(customerRequest.dueDate)
            ]
          ]}
          recordName="customerRequest"
          afterTag={this.afterTag}
          updateShowFunction={this.updateShowFunction}
          additionalCards={additionalCards}
        />
      </div>
    );
  }
}

export default recordViewEnhancer(
  customerRequestFragment,
  {}
)(CustomerRequestRecordView);
