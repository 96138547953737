/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get } from "lodash";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import SampleRecordView from "../../Record/SampleRecordView";

class Library extends React.Component {
  onNewItemClick = ({ history }) => {
    showDialog({
      modalType: "NEW_SAMPLE_ALIQUOT",
      modalProps: { history, refetch: this.props.refetch }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        withExport
        RecordViewInspector={SampleRecordView}
        isLibraryTable
        model="sample"
        onNewItemClick={this.onNewItemClick}
      />
    );
  }
}

const schema = {
  model: "sample",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "sampleStatus.name", type: "string", displayName: "Status" },
    { path: "sampleType.name", type: "string", displayName: "Sample Type" },
    {
      type: "string",
      displayName: "Materials",
      render: (v, r) => {
        if (r.sampleTypeCode === "FORMULATED_SAMPLE") {
          const materialNames = [];
          const materialIds = [];
          r.sampleFormulations.forEach(sf => {
            sf.materialCompositions &&
              sf.materialCompositions.forEach(mc => {
                if (!materialIds.includes(get(mc, "material.id"))) {
                  materialIds.push(get(mc, "material.id"));
                  materialNames.push(get(mc, "material.name"));
                }
              });
          });
          return materialNames.join(", ");
        } else {
          return get(r, "material.name");
        }
      }
    }
  ]
};

const fragment = gql`
  fragment sampleLibraryFragment on sample {
    id
    name
    sampleStatus {
      code
      name
    }
    sampleTypeCode
    sampleType {
      code
      name
    }
    sampleFormulations {
      id
      materialCompositions {
        id
        material {
          id
          name
        }
      }
    }
    material {
      id
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment,
  additionalFilter: (props, qb, currentParams) => {
    if (currentParams.j5SequenceIdFilter) {
      qb.whereAll({
        "material.polynucleotideMaterialSequence.id":
          currentParams.j5SequenceIdFilter
      });
    }
  }
})(Library);
