/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { Button, Intent } from "@blueprintjs/core";
import { ReactSelectField } from "@teselagen/ui";

import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import { arrayToIdOrCodeValuedOptions } from "../../../src-shared/utils/formUtils";
import { pushHelper } from "../../../src-shared/utils/pushHelper";
import useTgQuery from "../../../src-shared/apolloUseTgQuery";

const LaunchReport = props => {
  const { reportIdToLaunch, reportHookTypeCode, reportHookSourceCode } = props;

  const { reportHooks = [], ...rest } = useTgQuery(
    [
      "reportHook",
      `id
      report {
        id
        name
      }`
    ],
    {
      isPlural: true,
      variables: {
        pageSize: 999,
        filter: {
          reportHookTypeCode,
          reportHookSourceCode
        }
      }
    }[("design", `id name`)]
  );

  const options = arrayToIdOrCodeValuedOptions(reportHooks.map(r => r.report));

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  const launchReport = e => {
    const { reportIdToLaunch, itemId } = props;
    pushHelper(e, `/reports/${reportIdToLaunch}/${itemId}`);
  };

  if (!reportHooks.length) return null;

  return (
    <div
      className="tg-flex width100"
      style={{
        maxHeight: 35,
        margin: "15px 0"
      }}
    >
      <div
        style={{
          flex: 1
        }}
      >
        <ReactSelectField options={options} name="reportIdToLaunch" />
      </div>
      <Button
        style={{
          marginLeft: 15
        }}
        intent={Intent.PRIMARY}
        text="Launch Report"
        disabled={!reportIdToLaunch}
        onClick={launchReport}
      />
    </div>
  );
};

export default compose(
  withRouter,
  reduxForm({
    form: "launchReportForm"
  }),
  tgFormValues("reportIdToLaunch")
)(LaunchReport);
