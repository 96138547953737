/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment sequenceDuplicateFragment on sequence {
    id
    name
    circular
    sequence
    description
    createdAt
    updatedAt
    size
    libraryId
    sequenceTypeCode
    sequenceFragments {
      id
      index
      fragment
    }
    sequenceFeatures {
      id
      start
      end
      type
      name
      strand
      locations
    }
    translations {
      id
      start
      end
      strand
    }
    parts {
      id
      name
      start
      end
      strand
    }
    labId
    lab {
      id
      name
    }
  }
`;
