/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import withQuery from "../../../../src-shared/withQuery";
import { compose } from "recompose";
import gql from "graphql-tag";
import { unitColumns } from "../../../../src-shared/utils/libraryColumns";
import { get } from "lodash";
import { withProps } from "recompose";

const schema = {
  model: "sampleFormulation",
  fields: [
    {
      displayName: "Aliquot",
      path: "aliquotId",
      render: (v, r) => {
        if (!v) return "N/A";
        let toRet = `Aliquot ${v}`;
        if (get(r, "sampleFormulationExistingAliquotView.aliquotId")) {
          toRet += "*";
        }
        return toRet;
      }
    },
    {
      displayName: "Materials",
      render: (v, r) => {
        return r.materialCompositions
          .map(mc => {
            return mc.material.name;
          })
          .join(", ");
      }
    },
    ...unitColumns
  ]
};

function MicrobialMaterialCard({ tableParams, history, hadExistingAliquot }) {
  return (
    <CollapsibleCard
      title="Sample Formulations"
      className="sample-formulations-table-card"
    >
      <DataTable
        {...tableParams}
        noPadding
        maxHeight={300}
        onDoubleClick={r => history.push(`/aliquots/${r.aliquotId}`)}
      >
        {hadExistingAliquot && (
          <div style={{ float: "right", marginTop: 5, fontSize: 11 }}>
            *Existing aliquot before sample formulation.
          </div>
        )}
      </DataTable>
    </CollapsibleCard>
  );
}

const fragment = gql`
  fragment sampleFormulationsSampleRecordFragment on sampleFormulation {
    id
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    aliquotId
    sampleFormulationExistingAliquotView {
      id
      aliquotId
    }
    materialCompositions {
      id
      material {
        id
        name
      }
    }
  }
`;

const sfHadExistingAliquotFragment = gql`
  fragment sfHadExistingAliquotFragment on sampleFormulationExistingAliquotView {
    id
    aliquotId
  }
`;

export default compose(
  withRouter,
  withQuery(sfHadExistingAliquotFragment, {
    isPlural: true,
    options: props => {
      return {
        variables: {
          pageSize: 1,
          filter: {
            formulatedSampleId: props.sampleId
          }
        }
      };
    }
  }),
  withProps(props => {
    return {
      hadExistingAliquot:
        props.sampleFormulationExistingAliquotViews &&
        props.sampleFormulationExistingAliquotViews.length > 0
    };
  }),
  withTableParams({
    urlConnected: false,
    formName: "sampleFormulationsCard",
    schema,
    additionalFilter: (props, qb) => {
      qb.whereAll({
        formulatedSampleId: props.sampleId
      });
    }
  }),
  withQuery(fragment, { isPlural: true })
)(MicrobialMaterialCard);
