/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { forEach } from "lodash";
import { isoContext } from "@teselagen/utils";
import { checkBarcodesAndFormat } from "../utils/plateUtils";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";

export default async function handleReagentPlateImport(
  values,
  ctx = isoContext
) {
  const { generateTubeBarcodes, generateBarcode } = values;

  const {
    finishPlateCreate,
    containerArrayType,
    aliquotContainerType,
    filename,
    csvData,
    getCsvRowExtProps
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      nameHeader: "PLATE_NAME",
      barcodeHeader: "PLATE_BARCODE",
      hasReagents: true,
      allowDryReagents: true,
      hasExtendedProperties: true,
      requiredFields: ["PLATE_NAME", "WELL_LOCATION"]
    },
    ctx
  );

  const isRack = !containerArrayType.isPlate;
  const mapPlates = {};

  const continueUpload = await checkBarcodesAndFormat({
    data: csvData,
    generateTubeBarcodes,
    filename,
    containerArrayType,
    tubeBarcodeKey: "TUBE_BARCODE",
    barcodeKey: "PLATE_BARCODE",
    wellPositionHeader: "WELL_LOCATION"
  });

  if (!continueUpload) return;

  for (const [index, row] of csvData.entries()) {
    const {
      PLATE_NAME: plateName,
      PLATE_BARCODE: plateBarcode,
      TUBE_BARCODE: tubeBarcode,
      rowPosition,
      columnPosition,
      rowKey
    } = row;

    if (!row.additives || !row.additives.length) {
      throw new Error(`Row ${index + 1} did not specify any reagents.`);
    }

    if (!mapPlates[rowKey]) {
      const plateCid = shortid();
      mapPlates[rowKey] = {
        cid: plateCid,
        name: plateName,
        barcode: plateBarcode,
        aliquotContainers: []
      };
      getCsvRowExtProps({
        row,
        modelTypeCode: "CONTAINER_ARRAY",
        typeFilter: ["plate", "rack"],
        recordId: `&${plateCid}`
      });
    }

    const tubeCid = shortid();
    if (isRack) {
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT_CONTAINER",
        typeFilter: "tube",
        recordId: `&${tubeCid}`
      });
    }
    mapPlates[rowKey].aliquotContainers.push({
      cid: tubeCid,
      rowPosition,
      columnPosition,
      tubeBarcode,
      additives: row.additives
    });
  }

  const platesToCreate = [];

  forEach(
    mapPlates,
    ({ cid, aliquotContainers, inventoryItems, name, barcode }) => {
      const newAliquotContainers = aliquotContainers.map(
        ({ tubeBarcode, ...rest }) => {
          const barcodeField = {};
          if (!generateTubeBarcodes) {
            barcodeField.barcode = {
              barcodeString: tubeBarcode
            };
          }
          return {
            aliquotContainerTypeCode: isRack
              ? aliquotContainerType.code
              : containerArrayType.aliquotContainerType.code,
            ...barcodeField,
            ...rest
          };
        }
      );

      platesToCreate.push({
        cid,
        name,
        ...(name !== barcode &&
          !generateBarcode && {
            barcode: {
              barcodeString: barcode
            }
          }),
        inventoryItems,
        containerArrayTypeId: containerArrayType.id,
        aliquotContainers: newAliquotContainers
      });
    }
  );

  const createdPlates = await finishPlateCreate({
    newPlates: platesToCreate
  });

  return createdPlates;
}
