/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import shortid from "shortid";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import appGlobals from "../../../../src-shared/appGlobals";
import { validateConditionallyRequiredFields } from "../../../../src-shared/utils/formUtils";
import withQuery from "../../../../src-shared/withQuery";
import StepForm from "../../../../src-shared/StepForm";
import ScoreAndPrice from "./Steps/ScoreAndPrice";
import SelectDNA, { sequenceFragment } from "./Steps/SelectDNA";
import CustomOptions from "./Steps/CustomOptions";
import { pick } from "lodash";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import withWorkflowInputsFromHash from "../../../graphql/enhancers/withWorkflowInputsFromHash";

function validate(values) {
  const errors = {};
  validateConditionallyRequiredFields(
    ["sequences", "dataTables", "sequencesFromAssemblyReports", "reactionMaps"],
    values,
    errors
  );
  return errors;
}

class IDTOrderingTool extends React.Component {
  onSubmit = async values => {
    try {
      const {
        idtIntegration,
        sequencesToOrder,
        orderName,
        userContext,
        productType,
        customOptions
      } = values;
      const dataTablesToCreate = [];
      try {
        const tableEndpoint = idtIntegration.integrationEndpoints.find(
          e => e.endpointTypeCode === "VENDOR__TABLE"
        );
        const { data } = await window.triggerIntegrationRequest({
          endpointId: tableEndpoint.id,
          method: "POST",
          data: {
            context: userContext,
            productType,
            options: customOptions,
            sequences: sequencesToOrder.map(s =>
              pick(s, ["id", "name", "price", "sequence", "circular"])
            )
          }
        });
        if (!data.tables || !data.tables.length) {
          return window.toastr.error(
            "Integration must generate tables for ordering."
          );
        }
        for (const table of data.tables) {
          const { rows, name } = table;
          let containerType, orderType;
          if (rows[0].wellPosition) {
            // is plate
            containerType = "PLATE";
          } else {
            // is tube
            containerType = "TUBE";
          }
          if (rows[0].scale) {
            // is oligo
            orderType = "OLIGO";
          } else {
            // is linear fragment
            orderType = "LINEAR_FRAGMENT";
          }
          if (!rows[0].name || !rows[0].sequence) {
            return window.toastr.error(
              "Order tables must have name and sequence."
            );
          }
          const dataTableTypeCode = `IDT_ORDER_${containerType}_${orderType}`;
          dataTablesToCreate.push({
            name: name,
            dataTableTypeCode,
            dataRows: rows.map(r => {
              return {
                rowValues: r
              };
            })
          });
        }
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.error("Error generating order tables from endpoint");
        return false;
      }

      const dataSet = {
        cid: shortid(),
        name: orderName
      };
      const [createdOrder] = await safeUpsert("vendorOrder", {
        name: orderName,
        dataSet,
        vendorId: "&idt_cid",
        ownerId: appGlobals.currentUser.id,
        orderStatusCode: "DRAFT_STATUS",
        lineItems: sequencesToOrder.map(seq => {
          const lineItem = {
            isClonal: false,
            isScored: true,
            sequenceId: seq.id, //seq.queriedMaterial.id also an option
            totalPrice: seq.price,
            purchaseOrderId: "&testpurchaseorder1", //from seed data dummy order....
            lineItemScore: {
              isBuildable: true
            },
            lineItemStatusCode: "PENDING_STATUS"
          };
          return lineItem;
        })
      });
      // link tables to dataset
      await safeUpsert(
        "dataTable",
        dataTablesToCreate.map(t => {
          t.dataSetId = `&${dataSet.cid}`;
          return t;
        })
      );

      return {
        vendorOrder: createdOrder
      };
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating order");
    }
  };

  lastLoadedOptions = { current: "" };

  scoringOptionsHash = { current: "" };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues,
      integrations = [],
      vendors = []
    } = this.props;
    const idtVendor = vendors[0];

    const steps = [
      {
        title: "Select DNA",
        Component: SelectDNA,
        withCustomFooter: true,
        props: {
          idtVendor,
          integrations
        }
      },
      {
        title: "Custom Options",
        Component: CustomOptions,
        withCustomFooter: true,
        props: {
          lastLoadedOptions: this.lastLoadedOptions
        }
      },
      {
        title: "Score and Price DNA",
        Component: ScoreAndPrice,
        withCustomFooter: true,
        props: {
          scoringOptionsHash: this.scoringOptionsHash
        }
      }
    ];

    if (!integrations.length) {
      return (
        <div style={{ margin: 45 }}>
          No integrations have been setup for IDT DNA Ordering. An admin can add
          one in <Link to="/settings/integrations">integration settings</Link>{" "}
          DNA Ordering.
        </div>
      );
    }

    if (!idtVendor) {
      return <div style={{ margin: 45 }}>IDT Vendor info not found.</div>;
    }

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        validate={validate}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

const integrationFragment = gql`
  fragment idtOrderingToolIntegrationFragment on integration {
    id
    name
    integrationTypeCode
    integrationEndpoints {
      id
      endpointTypeCode
    }
  }
`;

export const vendorFragment = gql`
  fragment idtOrderingVendorFragment on vendor {
    id
    name
    catalogItemTypes {
      id
      name
      orderableItemTypeCode
    }
  }
`;

export default compose(
  withWorkflowInputsFromHash({
    hashIdToInputName: {
      dataTableIds: "dataTables",
      sequenceIds: "sequences"
    }
  }),
  withQuery(integrationFragment, {
    showLoading: true,
    isPlural: true,
    options: () => {
      return {
        variables: {
          filter: {
            ...(window.Cypress?.__idtIntegrationId && {
              id: window.Cypress?.__idtIntegrationId
            }),
            integrationTypeCode: "IDT_ORDERING"
          }
        }
      };
    }
  }),
  withQuery(vendorFragment, {
    showLoading: true,
    isPlural: true,
    options: () => {
      return {
        variables: {
          pageSize: 1,
          filter: {
            cid: "idt_cid"
          }
        }
      };
    }
  }),
  withWorkflowInputs(["j5Report", "id name updatedAt"]),
  withWorkflowInputs(["dataTable", "id name updatedAt"]),
  withWorkflowInputs(["reactionMap", "id name updatedAt"]),
  withWorkflowInputs(sequenceFragment)
)(IDTOrderingTool);
