/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, forEach, keyBy, isEmpty } from "lodash";
import {
  getKeyMaterialsFromAliquotContainer,
  getKeyReagentsFromAliquotContainer
} from "../utils/plateUtils";
import { getAliquotContainerLocation } from "../utils/getAliquotContainerLocation";
import {
  aliquotContainerReactionMapFragment,
  executeReactionsFragment
} from "./fragments";
import { isoContext } from "@teselagen/utils";
import executeReactions from "./executeReactions";

export { executeReactions };

export function collectMatchingReactionInfo(
  reaction,
  keyedMaterials,
  keyedReagents,
  destinationWellLocationTable,
  wellId,
  destinationWellName
) {
  if (destinationWellLocationTable) {
    if (!destinationWellLocationTable.extraMaterialsForDestinationWells) {
      destinationWellLocationTable.extraMaterialsForDestinationWells = {};
    }
    if (
      !destinationWellLocationTable.extraMaterialsForDestinationWells[wellId]
    ) {
      destinationWellLocationTable.extraMaterialsForDestinationWells[wellId] = {
        ...keyedMaterials
      };
    }
  }
  const isMatch = reaction.reactionInputs.every(input => {
    let matches = false;
    if (input.inputMaterialId) {
      matches = keyedMaterials[input.inputMaterialId];
      if (destinationWellLocationTable && matches) {
        delete destinationWellLocationTable.extraMaterialsForDestinationWells[
          wellId
        ][input.inputMaterialId];
      }
    }
    if (input.inputAdditiveMaterialId) {
      matches = keyedReagents[input.inputAdditiveMaterialId];
    }
    return matches;
  });
  if (isMatch) {
    return {
      id: reaction.id,
      reactionOutputs: reaction.reactionOutputs
        .map(o => (o.outputMaterial || o.outputAdditiveMaterial).name)
        .join(", "),
      reactionInputs: reaction.reactionInputs
        .map(input => (input.inputMaterial || input.inputAdditiveMaterial).name)
        .join(", "),
      reactionName: reaction.name,
      reactionFullInputs: reaction.reactionInputs,
      reactionFullOutputs: reaction.reactionOutputs,
      destinationWell: destinationWellName,
      destinationWellId: wellId
    };
  }
}

export function reactionIsMatch(
  reaction,
  keyedMaterials = {},
  keyedReagents = {}
) {
  return reaction.reactionInputs.every(input =>
    input.inputMaterial || input.inputMaterialId
      ? keyedMaterials[
          input.inputMaterial ? input.inputMaterial.id : input.inputMaterialId
        ]
      : keyedReagents[
          input.inputAdditiveMaterial
            ? input.inputAdditiveMaterial.id
            : input.inputAdditiveMaterialId
        ]
  );
}

export default class ReactionMap {
  constructor(worklist, reactionMaps, ctx = isoContext) {
    this.worklist = worklist;
    this.reactionMaps = reactionMaps;
    this.verified = false;
    this.ctx = ctx;
    this.reactionDestinationWellLocationsTable = null;
  }

  async executeWorklistReactions() {
    const { reactionDestinationWellLocationsTable, verified } = this;
    if (!verified) {
      console.warn("Reaction Map not yet verified!");
      return "Reaction Map not yet verified!";
    }

    await executeReactions(reactionDestinationWellLocationsTable, this.ctx);
  }

  async verifyWorklistReactions() {
    const { worklist, reactionMaps } = this;
    const worklistTransfers = worklist.worklistTransfers;
    const { safeQuery } = this.ctx;

    const reactionMapIds = Array.isArray(reactionMaps)
      ? reactionMaps.map(rm => rm.id)
      : reactionMaps.id;
    const allReactions = await safeQuery(executeReactionsFragment, {
      variables: {
        filter: {
          reactionMapId: reactionMapIds
        }
      }
    });

    let reactionsNotRun = keyBy(allReactions, "id");

    const aliquotContainersToFetch = {};
    worklistTransfers.forEach(function (transfer) {
      const destinationAliquotContainerId =
        getDestinationAliquotContainerIdFromTransfer(transfer);
      const sourceAliquotContainerId =
        getSourceAliquotContainerIdFromTransfer(transfer);
      aliquotContainersToFetch[sourceAliquotContainerId] = true;
      aliquotContainersToFetch[destinationAliquotContainerId] = true;
    });
    const aliquotContainersToFetchIds = Object.keys(aliquotContainersToFetch);
    const aliquotContainers = await safeQuery(
      aliquotContainerReactionMapFragment,
      {
        variables: {
          filter: { id: aliquotContainersToFetchIds },
          pageSize: aliquotContainersToFetchIds.length
        }
      }
    );

    const aliquotContainerInfoLookup = {}; //given a containerId returns the materialId held in it
    aliquotContainers.forEach(aliquotContainer => {
      aliquotContainerInfoLookup[aliquotContainer.id] = {
        keyedMaterials: getKeyMaterialsFromAliquotContainer(aliquotContainer),
        keyedReagents: getKeyReagentsFromAliquotContainer(aliquotContainer),
        containerArrayName: aliquotContainer.containerArrayId
          ? get(aliquotContainer, "containerArray.name") +
            " " +
            getAliquotContainerLocation(aliquotContainer)
          : aliquotContainer.name +
            " " +
            get(aliquotContainer, "barcode.barcodeString", "")
      };
    });
    const destinationWells = {}; // a map with keys of destinationWellId amd value of an array of all material ids that will end up in this well
    worklistTransfers.forEach(function (transfer) {
      const sourceAliquotContainerId =
        getSourceAliquotContainerIdFromTransfer(transfer);
      if (!aliquotContainerInfoLookup[sourceAliquotContainerId]) return;
      const { keyedMaterials: sourceMaterials, keyedReagents: sourceReagents } =
        aliquotContainerInfoLookup[sourceAliquotContainerId];
      const destinationAliquotContainerId =
        getDestinationAliquotContainerIdFromTransfer(transfer);

      if (!aliquotContainerInfoLookup[destinationAliquotContainerId]) return;
      if (!destinationWells[destinationAliquotContainerId]) {
        const destInfo =
          aliquotContainerInfoLookup[destinationAliquotContainerId];
        destinationWells[destinationAliquotContainerId] = {
          keyedReagents: destInfo.keyedReagents,
          keyedMaterials: destInfo.keyedMaterials,
          destinationWellName: destInfo.containerArrayName
        };
      }

      const { keyedMaterials, keyedReagents } =
        destinationWells[destinationAliquotContainerId];

      if (sourceMaterials && !isEmpty(sourceMaterials)) {
        Object.keys(sourceMaterials).forEach(sourceMaterialId => {
          if (!keyedMaterials[sourceMaterialId]) {
            keyedMaterials[sourceMaterialId] =
              sourceMaterials[sourceMaterialId];
          }
        });
      }
      if (sourceReagents && !isEmpty(sourceReagents)) {
        Object.keys(sourceReagents).forEach(sourceReagentId => {
          if (!keyedReagents[sourceReagentId]) {
            keyedReagents[sourceReagentId] = sourceReagents[sourceReagentId];
          }
        });
      }
    });

    let numberOfWellsWithRunningReaction = 0;
    const totalNumberOfReactions = allReactions.length;
    // let reactionsNotRun = { ...reactionMapHashLookup };

    const reactionDestinationWellLocationsTable = [];

    const reactionInfoTable = [];
    const reactionIdToWells = {};
    allReactions.forEach(reaction => {
      const wellsWithReaction = [];
      reactionInfoTable.push({
        ...reaction,
        wellsWithReaction
      });
      reactionIdToWells[reaction.id] = wellsWithReaction;
    });
    forEach(
      destinationWells,
      (
        { keyedMaterials, keyedReagents, destinationWellName },
        destinationWellId
      ) => {
        let wellHadReaction = false;
        allReactions.forEach(reaction => {
          const reactionInfo = collectMatchingReactionInfo(
            reaction,
            keyedMaterials,
            keyedReagents,
            reactionDestinationWellLocationsTable,
            destinationWellId,
            destinationWellName
          );
          wellHadReaction = wellHadReaction || !!reactionInfo;
          if (reactionInfo) {
            delete reactionsNotRun[reaction.id];
            reactionDestinationWellLocationsTable.push(reactionInfo);
            reactionIdToWells[reaction.id].push(destinationWellName);
          }
        });
        if (wellHadReaction) {
          numberOfWellsWithRunningReaction++;
        }
      }
    );
    const totalDestinationWells = Object.keys(destinationWells).length;
    reactionsNotRun = Object.values(reactionsNotRun);
    const numberOfReactionsRan =
      totalNumberOfReactions - reactionsNotRun.length;
    this.verified = true;
    this.reactionDestinationWellLocationsTable =
      reactionDestinationWellLocationsTable;
    return {
      reactionVerificationTable: reactionInfoTable,
      reactionSummaryStats: {
        totalDestinationWells,
        totalNumberOfReactions,
        numberOfWellsWithRunningReaction,
        reactionsNotRun,
        numberOfReactionsRan
      }
    };
  }
}

function getDestinationAliquotContainerIdFromTransfer(transfer) {
  return (
    transfer.destinationAliquotContainerId ||
    get(transfer, "destinationAliquotContainer.id") ||
    false
  );
}
function getSourceAliquotContainerIdFromTransfer(transfer) {
  return (
    transfer.sourceAliquotContainerId ||
    get(transfer, "sourceAliquotContainer.id") ||
    false
  );
}
