/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Button, Classes } from "@blueprintjs/core";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { wrapDialog } from "@teselagen/ui";

class AddDesignRuleDialog extends React.Component {
  handleAddRegexRuleButtonClick = () => {
    const { ruleSet, refetchRuleSet } = this.props;
    showDialog({
      modalType: "ADD_REGEX_DESIGN_RULE",
      modalProps: {
        ruleSet,
        refetchRuleSet
      }
    });
  };

  handleAddTagRuleButtonClick = () => {
    const { ruleSet, refetchRuleSet } = this.props;
    showDialog({
      modalType: "ADD_TAG_DESIGN_RULE",
      modalProps: {
        ruleSet,
        refetchRuleSet
      }
    });
  };

  render() {
    return (
      <div className={Classes.DIALOG_BODY}>
        <div className="bp3-button-group">
          <Button
            minimal
            text="Add Tag Rule"
            onClick={this.handleAddTagRuleButtonClick}
          />
          <Button
            minimal
            text="Add Regex Rule"
            onClick={this.handleAddRegexRuleButtonClick}
          />
        </div>
      </div>
    );
  }
}

export default wrapDialog({
  title: "Add Design Rule",
  style: { width: 300 }
})(AddDesignRuleDialog);
