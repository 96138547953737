/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import ExportPlates, {
  exportPlatesIntegrationFragment,
  plateFragment
} from "./ExportPlates";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import ExportPlatesOrTubesDialog from "../../Dialogs/ExportPlatesOrTubesDialog";
import ExportAsPlateCsvDialog from "../../Record/ContainerArrayRecordView/ExportAsPlateCsvDialog";
import { containerArrayRecordFragment } from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import {
  hasAliquotExtendedPropsHelper,
  showPlateRecordTableExport
} from "../../Record/ContainerArrayRecordView/utils";
import { getRecordViewFragment } from "../../../../src-shared/recordViewEnhancer";
import { withProps } from "recompose";
import { get } from "lodash";
import { cleanPlateForIntegrationRequest } from "../../../utils/plateUtils";
import integrationRequestPlateFragment from "../../../graphql/fragments/integrationRequestPlateFragment";
import { download } from "../../../../src-shared/utils/downloadTest";
import { handleZipFiles } from "../../../../../tg-iso-shared/src/utils/fileUtils";

class ExportPlatesTool extends React.Component {
  onSubmit = async values => {
    const { hasAliquotExtProps, aliquotExtendedPropertyFields } = this.props;
    const { containerArrays, exportFormat, useIntegration, integration } =
      values;
    try {
      if (useIntegration) {
        const endpointId = get(integration, "integrationEndpoints[0].id");
        if (!endpointId) {
          return window.toastr.error("No endpoint setup for integration");
        }
        const plates = await safeQuery(integrationRequestPlateFragment, {
          variables: {
            filter: {
              id: containerArrays.map(c => c.id)
            }
          }
        });
        const { data } = await window.triggerIntegrationRequest({
          endpointId,
          data: {
            plates: plates.map(cleanPlateForIntegrationRequest)
          },
          method: "POST"
        });
        download(
          await handleZipFiles(
            data.data.map(d => ({ name: d.filename, data: d.contents }))
          ),
          "plates.zip",
          "application/zip"
        );
      } else {
        const fragment = getRecordViewFragment(containerArrayRecordFragment);
        const { completed } =
          (await new Promise(async resolve => {
            if (exportFormat === "defaultCsv") {
              showDialog({
                ModalComponent: ExportPlatesOrTubesDialog,
                onClose: resolve,
                modalProps: {
                  plateIds: containerArrays.map(p => p.id)
                }
              });
            } else if (exportFormat === "plateLayoutCsv") {
              const fullPlates = await safeQuery(fragment, {
                variables: {
                  filter: {
                    id: containerArrays.map(c => c.id)
                  }
                }
              });
              showDialog({
                ModalComponent: ExportAsPlateCsvDialog,
                onClose: resolve,
                modalProps: {
                  containerArrays: fullPlates
                }
              });
            } else if (exportFormat === "listCsv") {
              const fullPlates = await safeQuery(fragment, {
                variables: {
                  filter: {
                    id: containerArrays.map(c => c.id)
                  }
                }
              });
              showPlateRecordTableExport({
                containerArrays: fullPlates,
                onClose: resolve,
                hasAliquotExtProps,
                aliquotExtendedPropertyFields
              });
            }
          })) || {};
        if (!completed || window.Cypress?.doNotFinishTool) return false;
      }
      return {
        containerArrays
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error exporting plates");
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Export Plates",
        Component: ExportPlates
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        validate={values => {
          const errors = {};
          if (
            values.containerArrays?.some(p => !p.containerArrayType.isPlate)
          ) {
            if (values.exportFormat === "plateLayoutCsv") {
              errors.exportFormat = "Plate Layout CSV only supports plates";
            }
          }
          if (
            values.integration &&
            values.integration.integrationTypeCode !== "EXPORT_CUSTOM_PLATE"
          ) {
            errors.integration =
              "Integration must be of type 'EXPORT_CUSTOM_PLATE'";
          }
          return errors;
        }}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plateFragment),
  withWorkflowInputs(exportPlatesIntegrationFragment, {
    singular: true
  }),
  withProps(({ initialValues: _iv }) => {
    let initialValues = {
      ..._iv
    };
    if (initialValues.integration) {
      initialValues = {
        ...initialValues,
        useIntegration: true
      };
    }
    return {
      initialValues
    };
  }),
  hasAliquotExtendedPropsHelper()
)(ExportPlatesTool);
