/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { taggedItemFragment } from "../../../../tg-iso-shared/src/fragments/taggedItemFragment";

export default gql`
  fragment selectLotsForPlatePrepFragment on lot {
    id
    name
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    molarity
    molarityUnitCode
    updatedAt
    additiveMaterial {
      id
      name
      taggedItems {
        ...taggedItemFragment
      }
    }
  }
  ${taggedItemFragment}
`;
