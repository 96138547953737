/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DragSource } from "react-dnd";
import { Icon } from "@blueprintjs/core";

function DraggableMaterialHandle({ name, connectDragSource }) {
  return connectDragSource(
    <span>
      <Icon
        icon="drag-handle-vertical"
        style={{ marginRight: 5, marginLeft: -5 }}
      />
      {name}
    </span>
  );
}

export default DragSource(
  "source-material",
  {
    beginDrag(props) {
      const { selectedSourceMaterials, material } = props;

      // Either use the selected materials if the dragged handle is part
      // of the selection or use only the dragged material otherwise.
      return {
        selectedSourceMaterials: selectedSourceMaterials.some(
          m => m.id === material.id
        )
          ? selectedSourceMaterials
          : [material]
      };
    }
  },
  (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    };
  }
)(DraggableMaterialHandle);
