/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { ReactElement } from "react";
import {
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { FieldArrayFieldsProps } from "redux-form";

const Sortable = (props: {
  index: number;
  innerClassName: string;
  innerElement: string;
  isFullyDraggable: boolean;
  children: ReactElement;
}) => {
  const Element = props.innerElement as any;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef
  } = useSortable({
    id: `${props.index}`
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Element
      className={props.innerClassName}
      style={style}
      ref={setNodeRef}
      {...(props.isFullyDraggable ? listeners : {})}
      {...attributes}
    >
      {React.cloneElement(props.children, {
        setActivatorNodeRef,
        listeners: listeners
      })}
    </Element>
  );
};

interface SortableListProps<Item> {
  /** Array of items or field array that will be displayed */
  items: Item[] | FieldArrayFieldsProps<Item>;
  /** If true, the sorting will be disabled */
  sortDisabled?: boolean;
  /** Method that will be called when the user finishes dragging an element */
  onDragEnd: ({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
  /** Used to generate a unique key for the elements of the array, defaults to getting the id of the item */
  keyMethod?: (item: Item | string, index: number) => string;
  /** Method that receives and item and returns true if it has to be disabled */
  disableMethod?: (item: Item | string) => boolean;
  /** Component that will be used to display the items */
  DisplayComponent:
    | React.FC<{
        item: Item;
        index: number;
        items: Item[];
        removeFromList?: Function;
        listeners?: any;
        setActivatorNodeRef?: any;
      }>
    | React.FC<{
        item: string;
        index: number;
        items: FieldArrayFieldsProps<Item>;
        removeFromList?: Function;
        listeners?: any;
        setActivatorNodeRef?: any;
      }>;

  /** If true, the elements will be fully draggable, if false, Display component has to implement draggable listeners */
  isFullyDraggable?: boolean;
  /** Class name that will be added to the wrapper */
  wrapperClassName?: string;
  /** Element that will be used as the wrapper */
  wrapperElement?: "div" | "ul" | "ol";
  /** Class name that will be added to the inner element wrapper */
  innerClassName?: string;
  /** Element that will be used as the inner element wrapper */
  innerElement?: "div" | "li";
}

export default function SortableList<Item>({
  items,
  keyMethod = (item: Item | string, index: number) => {
    return typeof item === "string"
      ? (((items as FieldArrayFieldsProps<Item>).get(index) as any)[
          "id"
        ] as string)
      : ((item as any)["id"] as string);
  },
  disableMethod = (_item: string | Item) => false,
  onDragEnd,
  DisplayComponent,
  sortDisabled = false,
  isFullyDraggable = true,
  wrapperClassName = "",
  wrapperElement = "div",
  innerElement = "div",
  innerClassName = "",
  ...rest // All the other props are passed automatically to the DisplayComponent
}: SortableListProps<Item>) {
  const Wrapper = wrapperElement as keyof React.JSX.IntrinsicElements;

  // Required for cypress to be able to click on buttons inside of the components
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 1
    }
  });

  const sensors = useSensors(mouseSensor);
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over || !active) {
      return;
    }

    if (active.id == over.id) {
      return;
    }
    onDragEnd({
      oldIndex: parseInt(active.id as string),
      newIndex: parseInt(over.id as string)
    });
  }

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
    >
      <SortableContext
        items={items.map((_item, index) => `${index}`)}
        strategy={verticalListSortingStrategy}
        disabled={sortDisabled}
      >
        <Wrapper className={wrapperClassName}>
          {items.map((item, index) => {
            const key = keyMethod(item, index);
            const isDisabled = disableMethod(item);

            if (isDisabled) {
              return (
                <DisplayComponent
                  key={key ?? index}
                  item={item as any}
                  items={items as any}
                  index={index}
                  listeners={{}} // Empty listeners to avoid errors
                  setActivatorNodeRef={() => {}} // Empty setActivatorNodeRef to avoid errors
                  {...rest}
                />
              );
            }
            return (
              <Sortable
                innerElement={innerElement}
                innerClassName={innerClassName}
                key={key ?? index}
                index={index}
                isFullyDraggable={isFullyDraggable}
              >
                <DisplayComponent
                  item={item as any}
                  items={items as any}
                  index={index}
                  {...rest}
                />
              </Sortable>
            );
          })}
        </Wrapper>
      </SortableContext>
    </DndContext>
  );
}
