/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import buildAliasFragment from "../fragments/buildAliasFragment";
import withQuery from "../withQuery";

/**
 * For a given componenet with `model` and `recordId` in the props, query for
 * the aliases on that record.
 */

export default withQuery(buildAliasFragment, {
  isPlural: true,
  options: ({ record }) => {
    return {
      variables: {
        filter: {
          [record.__typename + "Id"]: record.id
        }
      }
    };
  }
});
