/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Tree, Classes, Button, Colors } from "@blueprintjs/core";
import { compose } from "recompose";
import { noop, snakeCase, get, sortBy, keyBy, isEmpty } from "lodash";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import shortid from "shortid";
import Color from "color";
import {
  wrapDialog,
  DialogFooter,
  ReactSelectField,
  Loading,
  IntentText
} from "@teselagen/ui";
import {
  buildTreeNodes,
  showAlreadyPlacedWarning,
  resetAssignedPositionFields,
  pluralIfNeeded,
  getPathOfAssignedPosition
} from "../../../utils";
import { getPlateTubeLabel } from "../../../utils/plateUtils";

import GenericSelect from "../../../../src-shared/GenericSelect";
import loadChildItemsForTreeNode from "../../Record/EquipmentRecordView/loadChildItemsForTreeNode";
import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";
import PlateMapPlate from "../../PlateMapPlate";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import withQuery from "../../../../src-shared/withQuery";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  getPositionFromAlphanumericLocation
} from "../../../../../tg-iso-lims/src/utils/plateUtils";

const getRecordLabel = (r, index) => {
  let label = getPlateTubeLabel(r);
  if (!label) {
    label = modelNameToReadableName(r.__typename) + ` ${index}`;
  }
  return label;
};

class MoveRecordsToEquipmentDialog extends React.Component {
  state = {
    loading: false,
    treeNodes: [],
    selectionMap: {},
    tubeRackLocationIndex: -1,
    tubePositions: {}
  };

  get isMovingTubes() {
    const { recordsToMove = [] } = this.props;
    return !!(
      recordsToMove.length && recordsToMove[0].__typename === "aliquotContainer"
    );
  }

  get isMovingPlates() {
    const { recordsToMove = [] } = this.props;
    return !!(
      recordsToMove.length && recordsToMove[0].__typename === "containerArray"
    );
  }

  get isMovingContainers() {
    const { recordsToMove = [] } = this.props;
    return !!(
      recordsToMove.length && recordsToMove[0].__typename === "container"
    );
  }

  handleNodeExpand = async node => {
    if (node.loaded === true) {
      node.isExpanded = true;
      node.icon = "folder-open";
      return this.setState({
        treeNodes: this.state.treeNodes
      });
    }
    if (node.icon === "refresh") return;
    node.icon = "refresh";
    this.setState({
      treeNodes: this.state.treeNodes
    });
    let plateFragment = `
      id
      name
      barcode {
        id
        barcodeString
      }
    `;

    if (this.isMovingTubes) {
      plateFragment += `
        aliquotContainers {
          id
          rowPosition
          columnPosition
        }
        containerArrayType {
          id
          isPlate
          nestableTubeTypes {
            id
            aliquotContainerTypeCode
            aliquotContainerType {
              code
              name
            }
          }
          containerFormat {
            code
            rowCount
            columnCount
            quadrantSize
          }
        }
      `;
    }

    const assignedPositionFragment = [
      "assignedPosition",
      `
          id
          containerArray {
            ${plateFragment}
          }
          aliquotContainer {
            id
            name
            barcode {
              id
              barcodeString
            }
          }
        `
    ];
    const items = await loadChildItemsForTreeNode(node, {
      assignedPositionFragment
    });

    node.icon = "folder-open";

    node.isExpanded = true;
    node.loaded = true;
    const itemNodes = buildTreeNodes({
      records: items,
      displayPath: node.record.displayPath || "",
      selectionMap: this.state.selectionMap,
      countEmptyRackPositions: this.isMovingTubes
    });
    node.childNodes = itemNodes;

    this.setState({
      treeNodes: this.state.treeNodes
    });
  };

  handleNodeCollapse = nodeData => {
    nodeData.icon = "folder-close";
    nodeData.isExpanded = false;
    this.setState({
      treeNodes: this.state.treeNodes
    });
  };

  handleNodeClick = nodeData => {
    const { recordsToMove = [], itemsToPlaceIn = {}, change } = this.props;
    if (nodeData.record.__typename === "assignedPosition") {
      if (this.isMovingTubes) {
        const containerArray = get(nodeData, "record.containerArray");
        const isRack =
          containerArray && !containerArray.containerArrayType.isPlate;
        // if we are moving tubes then we can let users pick a rack that isn't full
        // if that rack can accept that tube type
        if (!isRack) return;
        const tubeTypesUserIsMoving = recordsToMove.map(
          r => r.aliquotContainerTypeCode
        );
        const nestableTubeTypeCodes = containerArray.containerArrayType.nestableTubeTypes.map(
          ntt => ntt.aliquotContainerTypeCode
        );
        const canMoveATubeHere = tubeTypesUserIsMoving.some(type =>
          nestableTubeTypeCodes.includes(type)
        );
        if (nodeData.numberOfEmptyPositions <= 0) {
          return window.toastr.warning("That rack is full.");
        }
        if (!canMoveATubeHere) {
          return window.toastr.warning(
            `This rack does not accept ${pluralIfNeeded(
              "this",
              recordsToMove
            )} tube ${pluralIfNeeded("type", recordsToMove)}.`
          );
        }
      } else {
        return;
      }
    }
    if (
      nodeData.record.__typename === "containerPosition" &&
      this.isMovingContainers
    ) {
      // can't put containers inside a container position
      return window.toastr.warning("Cannot put containers inside of positions");
    }
    const originallySelected = nodeData.isSelected;
    let selectionMap = {
      ...this.state.selectionMap
    };
    if (recordsToMove.length === 1) {
      Object.values(selectionMap).forEach(node => {
        node.isSelected = false;
      });
      selectionMap = {};
    }

    nodeData.isSelected = !originallySelected;

    if (originallySelected) {
      delete selectionMap[nodeData.id];
      const newItemsToPlaceIn = { ...itemsToPlaceIn };
      delete newItemsToPlaceIn[nodeData.id];
      change("itemsToPlaceIn", newItemsToPlaceIn);
    } else {
      selectionMap[nodeData.id] = nodeData;
    }

    this.setState({
      treeNodes: this.state.treeNodes,
      selectionMap
    });
  };

  onEquipmentChosen = async equipment => {
    const treeNodes = buildTreeNodes({
      records: [equipment],
      selectionMap: this.state.selectionMap
    });
    this.setState(
      {
        treeNodes
      },
      () => {
        this.handleNodeExpand(this.state.treeNodes[0]);
      }
    );
  };

  skipContainerPlacement = async () => {
    try {
      const { finishPlacement, hideModal } = this.props;
      if (finishPlacement) {
        await finishPlacement();
        hideModal();
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error placing items.");
    }
  };

  onSubmit = async values => {
    try {
      const { selectionMap, tubePositions } = this.state;
      const {
        recordsToMove,
        hideModal,
        placementCb,
        finishPlacement,
        refetch = noop
      } = this.props;
      const { itemsToPlaceIn } = values;
      let placementInfo = itemsToPlaceIn;
      if (recordsToMove.length === 1) {
        const locations = Object.values(selectionMap);
        placementInfo = {
          [locations[0].id]: recordsToMove
        };
      } else {
        const unplacedRecords = this.getUnplacedRecords();
        if (unplacedRecords.length) {
          return window.toastr.error("Please choose locations for all items.");
        }
      }

      if (finishPlacement) {
        const placementInfoFromRecordToLocation = {};
        Object.keys(placementInfo).forEach(key => {
          const [positionModel, positionId] = key.split(":");
          const recordsToMoveToPosition = placementInfo[key];
          recordsToMoveToPosition.forEach(record => {
            placementInfoFromRecordToLocation[record.id] = {
              id: positionId,
              __typename: positionModel
            };
          });
        });
        await finishPlacement(placementInfoFromRecordToLocation);
        hideModal();
        return;
      }

      const movingModelName = recordsToMove[0].__typename;
      const items = await safeQuery(
        [movingModelName, "id name assignedPosition { id }"],
        {
          variables: {
            filter: {
              id: recordsToMove.map(r => r.id)
            }
          }
        }
      );

      await showAlreadyPlacedWarning(items);
      if (!items.length) return hideModal();
      const itemIdsToContinueWith = items.map(item => item.id);
      let dataTableId;
      const assignedPositionsToDelete = [];
      const newAssignedPositions = [];
      const plateUpdates = [];
      const tubeUpdates = [];
      const assignedPositionCidToItemId = {};
      Object.keys(placementInfo).forEach(key => {
        const targetRack = selectionMap[key].record.containerArray;

        const [positionModel, positionId] = key.split(":");
        const recordsToMoveToPosition = placementInfo[key].filter(r =>
          itemIdsToContinueWith.includes(r.id)
        );
        const modelName =
          positionModel === "equipmentItem" ? "equipment" : positionModel;
        const positionTypeCode = snakeCase(modelName).toUpperCase();
        recordsToMoveToPosition.forEach(record => {
          if (record.assignedPosition) {
            assignedPositionsToDelete.push(record.assignedPosition.id);
          }

          // if we are moving a tube to a rack then we will handle logic below
          if (!targetRack) {
            const cid = shortid();
            newAssignedPositions.push({
              cid,
              ...resetAssignedPositionFields,
              positionTypeCode,
              [modelName + "Id"]: positionId,
              [record.__typename + "Id"]: record.id
            });
            assignedPositionCidToItemId[cid] = record.id;
          }
          const update = {
            id: record.id,
            placementQueueId: null
          };
          if (record.__typename === "containerArray") {
            plateUpdates.push(update);
          } else {
            // handle moving tube to rack
            if (targetRack) {
              const location = tubePositions[key][record.id];
              if (!location) {
                return window.toastr.error(
                  `Please choose a location for ${getRecordLabel(record)}`
                );
              }
              const {
                rowPosition,
                columnPosition
              } = getPositionFromAlphanumericLocation(location);
              update.containerArrayId = targetRack.id;
              update.rowPosition = rowPosition;
              update.columnPosition = columnPosition;
            } else {
              // remove tube from a rack if it is in one
              update.containerArrayId = null;
              update.rowPosition = null;
              update.columnPosition = null;
            }
            tubeUpdates.push(update);
          }
        });
      });
      let fragment;
      if (placementCb) {
        fragment = [
          "assignedPosition",
          "id cid containerArray { id } aliquotContainer { id }"
        ];
      } else {
        fragment = "assignedPosition";
      }
      await safeDelete("assignedPosition", assignedPositionsToDelete);
      const apCreates = await safeUpsert(fragment, newAssignedPositions);
      await safeUpsert("containerArray", plateUpdates);
      await safeUpsert("aliquotContainer", tubeUpdates);

      if (placementCb) {
        // we want to make a placement dataTable
        const assignedPositionsWithFullPaths = await safeQuery(
          [
            "assignedPosition",
            /* GraphQL */ `
              {
                id
                containerArray {
                  id
                  containerArrayPathView {
                    id
                    fullPath
                  }
                }
                aliquotContainer {
                  id
                  aliquotContainerPathView {
                    id
                    fullPath
                  }
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: apCreates.map(ap => ap.id)
              }
            }
          }
        );
        const keyedApWithPaths = keyBy(assignedPositionsWithFullPaths, "id");
        const inventoryItemTypeCode = snakeCase(movingModelName).toUpperCase();
        const dataRows = apCreates.map((ap, index) => {
          return {
            index,
            dataRowInventoryItems: [
              {
                inventoryItem: {
                  inventoryItemTypeCode,
                  [movingModelName + "Id"]:
                    get(ap, "containerArray.id") ||
                    get(ap, "aliquotContainer.id") ||
                    assignedPositionCidToItemId[ap.cid]
                }
              }
            ],
            rowValues: {
              path: getPathOfAssignedPosition(keyedApWithPaths[ap.id])
            }
          };
        });
        const [{ id: _dataTableId }] = await safeUpsert("dataTable", {
          name:
            modelNameToReadableName(movingModelName, { upperCase: true }) +
            " Move List",
          dataTableTypeCode: inventoryItemTypeCode + "_PLACEMENT_LIST",
          dataRows
        });
        dataTableId = _dataTableId;
      }

      await refetch();
      if (recordsToMove.length > 1) {
        window.toastr.success(
          `${modelNameToReadableName(movingModelName, {
            plural: true,
            upperCase: true
          })} Moved`
        );
      }
      if (placementCb) {
        placementCb({ success: true, dataTableId });
      }
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error placing items.");
    }
  };

  getUnplacedRecords = () => {
    const { recordsToMove, itemsToPlaceIn = {} } = this.props;
    if (recordsToMove) {
      const recordsPlacedMap = {};

      Object.keys(itemsToPlaceIn).forEach(locationId => {
        const recordsInLocation = itemsToPlaceIn[locationId];
        if (recordsInLocation) {
          recordsInLocation.forEach(record => {
            recordsPlacedMap[record.id] = locationId;
          });
        }
      });

      return recordsToMove.filter(r => !recordsPlacedMap[r.id]);
    }
    return [];
  };

  renderRecordLocationSelection() {
    const { tubePositions } = this.state;
    const { recordsToMove, itemsToPlaceIn = {}, change } = this.props;

    if (recordsToMove.length === 1) return null;

    const locations = Object.values(this.state.selectionMap);
    const recordsPlacedMap = {};

    Object.keys(itemsToPlaceIn).forEach(locationId => {
      const recordsInLocation = itemsToPlaceIn[locationId];
      if (recordsInLocation) {
        recordsInLocation.forEach(record => {
          recordsPlacedMap[record.id] = locationId;
        });
      }
    });

    const unplacedRecords = this.getUnplacedRecords();
    let message;
    if (unplacedRecords.length) {
      message = `Choose location${
        unplacedRecords.length > 1 ? "s" : ""
      } for ${unplacedRecords.map(getRecordLabel).join(", ")}.`;
    } else {
      message = "Locations chosen for all items.";
    }

    return (
      <div style={{ padding: 15 }}>
        {message}
        <div style={{ marginTop: 15, maxHeight: 600, overflow: "auto" }}>
          {locations.map((location, i) => {
            const rack = get(location, "record.containerArray");
            const nestableTubeTypes = get(
              rack,
              "containerArrayType.nestableTubeTypes",
              []
            );
            const nestableTubeTypeCodes = nestableTubeTypes.map(
              ntt => ntt.aliquotContainerTypeCode
            );
            let numOfItemsPlacedHere = 0;
            let recordChoices = recordsToMove.filter(record => {
              if (
                nestableTubeTypeCodes.length &&
                !nestableTubeTypeCodes.includes(record.aliquotContainerTypeCode)
              ) {
                return false;
              }
              const itemPlacedHere =
                recordsPlacedMap[record.id] === location.id;
              if (itemPlacedHere) numOfItemsPlacedHere++;
              return !recordsPlacedMap[record.id] || itemPlacedHere;
            });
            const rackIsFull = !!(
              rack && location.numberOfEmptyPositions === numOfItemsPlacedHere
            );
            const numberOfFreePositionsInRack =
              rack && location.numberOfEmptyPositions - numOfItemsPlacedHere;
            // if we have already filled out the rack then don't let the user place any more tubes here
            if (rackIsFull) {
              recordChoices = recordChoices.filter(record => {
                const itemPlacedHere =
                  recordsPlacedMap[record.id] === location.id;
                return itemPlacedHere;
              });
            }

            const selectFieldName = `itemsToPlaceIn.${location.id}`;

            return (
              <div
                key={location.id}
                className="tg-choose-items-for-location"
                style={{
                  padding: 10
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    minimal
                    intent="danger"
                    icon="trash"
                    inten="danger"
                    small
                    onClick={() => {
                      this.handleNodeClick(location);
                      const newItemsToPlaceIn = { ...itemsToPlaceIn };
                      delete newItemsToPlaceIn[location.id];
                      change("itemsToPlaceIn", newItemsToPlaceIn);
                      const newTubePositions = { ...tubePositions };
                      delete newTubePositions[location.id];
                      this.setState({
                        tubePositions: newTubePositions
                      });
                    }}
                  />
                  <div>{location.record.displayPath}</div>
                </div>
                {rack && (
                  <div style={{ margin: 10 }}>
                    Accepted tube types:{" "}
                    {nestableTubeTypes
                      .map(tubeType => tubeType.aliquotContainerType.name)
                      .join(", ")}
                    <br />
                    {rackIsFull && (
                      <div style={{ margin: 10 }}>
                        <IntentText intent="warning">
                          Tubes chosen for all available spots in rack.
                        </IntentText>
                      </div>
                    )}
                  </div>
                )}
                <div style={{ marginLeft: 10 }}>
                  <ReactSelectField
                    className="tg-no-form-group-margin"
                    name={selectFieldName}
                    label={`Select items to put in ${
                      rack ? "rack" : "location"
                    }:`}
                    multi
                    popoverProps={{
                      usePortal: true
                    }}
                    options={recordChoices.map((record, i) => ({
                      label: getRecordLabel(record, i),
                      value: record
                    }))}
                  />
                  {!!unplacedRecords.length && !rackIsFull && (
                    <Button
                      small
                      intent="primary"
                      style={{ marginTop: 4 }}
                      onClick={() => {
                        const currentSelection =
                          itemsToPlaceIn[location.id] || [];
                        let recordsToPlace = unplacedRecords;
                        if (rack) {
                          recordsToPlace = recordsToPlace.slice(
                            0,
                            numberOfFreePositionsInRack
                          );
                        }
                        change("itemsToPlaceIn", {
                          ...itemsToPlaceIn,
                          [location.id]: [
                            ...currentSelection,
                            ...recordsToPlace
                          ]
                        });
                      }}
                      text={
                        unplacedRecords.length === recordsToMove.length
                          ? "Add all"
                          : "Add remaining"
                      }
                    />
                  )}
                </div>
                {i !== locations.length - 1 && (
                  <hr className="tg-section-break" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  get rackLocationList() {
    const locations = Object.values(this.state.selectionMap);
    return locations.filter(l => get(l, "record.containerArray"));
  }

  autoPlaceTubesIntoRack = (fullRack, rackLocation, columnFirst = false) => {
    const { tubePositions } = this.state;
    const { itemsToPlaceIn = {}, recordsToMove } = this.props;
    const fullTubeList = generateContainerArray(
      fullRack.aliquotContainers,
      fullRack.containerArrayType.containerFormat
    );
    const newTubePositionsForRack = {};
    const tubesToPlaceHere =
      recordsToMove.length > 1
        ? itemsToPlaceIn[rackLocation.id]
        : recordsToMove;
    const sortOrder = columnFirst
      ? ["columnPosition", "rowPosition"]
      : ["rowPosition", "columnPosition"];
    const sortedEmptyPositions = sortBy(fullTubeList, sortOrder).filter(
      ac => !ac.id
    );
    tubesToPlaceHere.forEach(tube => {
      const ac = sortedEmptyPositions.shift();
      if (!ac) return;
      const location = getAliquotContainerLocation(ac);
      newTubePositionsForRack[tube.id] = location;
    });
    this.setState({
      tubePositions: {
        ...tubePositions,
        [rackLocation.id]: newTubePositionsForRack
      }
    });
  };

  loadingActiveRackPreview = async newRackIndex => {
    const { tubePositions } = this.state;
    const rackLocation = this.rackLocationList[newRackIndex];
    const rack = rackLocation.record.containerArray;
    this.setState({
      loading: true
    });
    try {
      const fullRack = await safeQuery(containerArrayPlatePreviewFragment, {
        variables: {
          id: rack.id
        }
      });
      if (!fullRack) {
        throw new Error("User missing access.");
      }
      const tubePositionsForRack = tubePositions[rackLocation.id] || {};
      // if the user has not manually selected positions for these tubes yet then auto assign them
      if (isEmpty(tubePositionsForRack)) {
        this.autoPlaceTubesIntoRack(fullRack, rackLocation);
      }
      this.setState({
        tubeRackLocationIndex: newRackIndex,
        activeRackPreview: fullRack
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading rack view.");
    }

    this.setState({
      loading: false
    });
  };

  showPlaceTubesPage = async () => {
    const { tubeRackLocationIndex } = this.state;
    const newRackIndex = tubeRackLocationIndex + 1;
    this.loadingActiveRackPreview(newRackIndex);
  };

  showPreviousPage = () => {
    const { tubeRackLocationIndex } = this.state;
    const newRackIndex = tubeRackLocationIndex - 1;
    if (newRackIndex >= 0) {
      this.loadingActiveRackPreview(newRackIndex);
    } else {
      this.setState({
        tubeRackLocationIndex: tubeRackLocationIndex - 1
      });
    }
  };

  renderRackPositions() {
    const {
      tubeRackLocationIndex,
      activeRackPreview,
      tubePositions
    } = this.state;
    const { itemsToPlaceIn = {}, recordsToMove = [] } = this.props;

    const rackLocation = this.rackLocationList[tubeRackLocationIndex];
    const tubesToPlaceHere =
      recordsToMove.length > 1
        ? itemsToPlaceIn[rackLocation.id]
        : recordsToMove;
    const tubePositionsForRack = tubePositions[rackLocation.id] || {};
    const unplacedTubesForRack = tubesToPlaceHere.filter(
      t => !tubePositionsForRack[t.id]
    );
    const placedTubesForRack = tubesToPlaceHere.filter(
      t => tubePositionsForRack[t.id]
    );

    const selectedLocations = Object.values(tubePositionsForRack);

    const onAliquotContainerSelected = (aliquotContainer, location) => {
      if (selectedLocations.includes(location)) return;
      if (aliquotContainer.id) {
        return window.toastr.warning("There is already a tube here.");
      }
      // let them click around if only placing a single tube
      if (!unplacedTubesForRack.length && recordsToMove.length > 1) return;
      const tubeForLocation =
        recordsToMove.length > 1 ? unplacedTubesForRack[0] : recordsToMove[0];
      const newTubePositions = {
        ...tubePositions,
        [rackLocation.id]: {
          ...tubePositionsForRack,
          [tubeForLocation.id]: location
        }
      };
      this.setState({
        tubePositions: newTubePositions
      });
    };

    const clearAllTubePositions = () => {
      const newTubePositions = {
        ...tubePositions
      };
      delete newTubePositions[rackLocation.id];
      this.setState({
        tubePositions: newTubePositions
      });
    };

    const placeRowFirst = () =>
      this.autoPlaceTubesIntoRack(activeRackPreview, rackLocation);

    const placeColumnFirst = () =>
      this.autoPlaceTubesIntoRack(activeRackPreview, rackLocation, true);

    const valid = !unplacedTubesForRack.length;
    const content = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <div>
          {unplacedTubesForRack.length ? (
            <div>
              Choose positions for tubes:
              {recordsToMove.length !== 1 && (
                <div className="tg-flex" style={{ margin: 10 }}>
                  <Button text="Place Row First" onClick={placeRowFirst} />
                  <Button
                    text="Place Column First"
                    onClick={placeColumnFirst}
                    style={{ marginLeft: 5 }}
                  />
                </div>
              )}
              {unplacedTubesForRack.map((tube, i) => {
                const label = getRecordLabel(tube, i);
                return (
                  <div key={i}>
                    {i + 1}. {label}
                  </div>
                );
              })}
            </div>
          ) : (
            "Locations chosen for all tubes."
          )}
          {!!placedTubesForRack.length && (
            <div>
              <hr className="tg-section-break" />
              Tube positions:
              <div style={{ margin: 10 }}>
                <Button
                  minimal
                  intent="danger"
                  text="Clear"
                  onClick={clearAllTubePositions}
                />
              </div>
              {placedTubesForRack.map((tube, i) => {
                const label = getRecordLabel(tube, i);
                return (
                  <div key={i}>
                    <Button
                      icon="trash"
                      intent="danger"
                      minimal
                      onClick={() => {
                        const newTubePositionsForRack = {
                          ...tubePositions[rackLocation.id]
                        };
                        delete newTubePositionsForRack[tube.id];
                        const newTubePositions = {
                          ...tubePositions,
                          [rackLocation.id]: newTubePositionsForRack
                        };
                        this.setState({
                          tubePositions: newTubePositions
                        });
                      }}
                    />
                    {i + 1}. {label} - {tubePositionsForRack[tube.id]}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="tg-flex-separator" />
        <div className="tg-flex column align-center" style={{ flex: 1 }}>
          <h4>{activeRackPreview.name}</h4>
          <h6>Select Desired Tube Locations</h6>
          <PlateMapPlate
            {...{
              containerArrayType: activeRackPreview.containerArrayType,
              aliquotContainers: generateContainerArray(
                activeRackPreview.aliquotContainers,
                activeRackPreview.containerArrayType.containerFormat
              ).map(ac => {
                if (ac.id) {
                  return {
                    ...ac,
                    color: Color(Colors.RED4).fade(0.8)
                  };
                } else {
                  return ac;
                }
              }),
              onAliquotContainerSelected,
              selectedAliquotContainerLocations: selectedLocations
            }}
          />
        </div>
      </div>
    );

    return {
      content,
      valid
    };
  }

  render() {
    const {
      loading,
      treeNodes,
      selectionMap,
      tubeRackLocationIndex
    } = this.state;

    const {
      handleSubmit,
      submitting,
      hideModal,
      recordsToMove,
      aliquotContainerPathViews,
      containerArrayPathViews
    } = this.props;
    const unplacedRecords = this.getUnplacedRecords();
    const disableSubmitPlaceRecords =
      recordsToMove.length > 1 && unplacedRecords.length;

    const equipmentSelect = (
      <div style={{ minWidth: 300 }}>
        <GenericSelect
          asReactSelect
          label="Choose Equipment"
          name="equipment" //the field name within the redux form Field
          onSelect={this.onEquipmentChosen}
          additionalFilter={{
            hasContents: true
          }}
          fragment={["equipmentItem", "id name"]}
        />
      </div>
    );

    const movingSingleRecord = recordsToMove && recordsToMove.length === 1;

    let content;
    let valid = true;

    if (loading) {
      content = <Loading inDialog />;
    } else if (tubeRackLocationIndex >= 0) {
      const rackPositionInfo = this.renderRackPositions();
      content = rackPositionInfo.content;
      valid = rackPositionInfo.valid;
    } else {
      content = (
        <>
          {recordsToMove.length === 1 &&
            (this.isMovingPlates || this.isMovingTubes) && (
              <div style={{ marginBottom: 15 }}>
                <h6>Current Location:</h6>
                {get(aliquotContainerPathViews, "[0].fullPath") ||
                  get(containerArrayPathViews, "[0].fullPath") ||
                  "Not Placed"}
              </div>
            )}
          <div style={{ display: "flex" }}>
            <div
              style={{
                borderRight: movingSingleRecord
                  ? undefined
                  : "1px solid #a7b6c2",
                paddingRight: movingSingleRecord ? undefined : 10,
                minWidth: recordsToMove ? 300 : undefined
              }}
            >
              {equipmentSelect}
              <Tree
                contents={treeNodes}
                onNodeClick={this.handleNodeClick}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
              />
            </div>
            {recordsToMove
              ? this.renderRecordLocationSelection()
              : this.renderPositionLocationSelection()}
          </div>
        </>
      );
    }

    let footerProps;
    const additionalButtons = [];
    // if we have not placed all tubes into racks manually yet
    if (
      this.rackLocationList.length &&
      tubeRackLocationIndex < this.rackLocationList.length - 1
    ) {
      footerProps = {
        text: "Next",
        onClick: handleSubmit(this.showPlaceTubesPage)
      };
    } else {
      footerProps = {
        text: "Submit",
        onClick: handleSubmit(this.onSubmit)
      };
    }

    if (tubeRackLocationIndex >= 0) {
      additionalButtons.push(
        <Button
          key="prev"
          text="Previous"
          loading={submitting || loading}
          onClick={this.showPreviousPage}
        />
      );
    }

    if (this.isMovingContainers) {
      additionalButtons.push(
        <Button
          key="skip"
          text="Skip Location"
          loading={submitting || loading}
          onClick={handleSubmit(this.skipContainerPlacement)}
        />
      );
    }

    return (
      <>
        <div className={Classes.DIALOG_BODY}>{content}</div>
        <DialogFooter
          {...footerProps}
          hideModal={hideModal}
          disabled={
            !valid || isEmpty(selectionMap) || disableSubmitPlaceRecords
          }
          additionalButtons={additionalButtons}
          submitting={submitting || loading}
        />
      </>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      const plural = props.recordsToMove.length > 1;
      return {
        title: `Select Location${
          plural ? "s" : ""
        } for ${modelNameToReadableName(props.recordsToMove[0].__typename, {
          upperCase: true,
          plural
        })}`,
        style: {
          width: 950
        }
      };
    }
  }),
  withQuery(["containerArrayPathView", "id fullPath"], {
    isPlural: true,
    skip: props => {
      return (
        props.recordsToMove.length > 1 ||
        !(props.recordsToMove[0].__typename === "containerArray")
      );
    },
    options: props => {
      return {
        variables: {
          filter: {
            id: props.recordsToMove[0].id
          }
        }
      };
    },
    inDialog: true,
    showLoading: true
  }),
  withQuery(["aliquotContainerPathView", "id fullPath"], {
    isPlural: true,
    skip: props => {
      return (
        props.recordsToMove.length > 1 ||
        !(props.recordsToMove[0].__typename === "aliquotContainer")
      );
    },
    options: props => {
      return {
        variables: {
          filter: {
            id: props.recordsToMove[0].id
          }
        }
      };
    },
    inDialog: true,
    showLoading: true
  }),
  reduxForm({
    form: "selectPositionsForMovingRecords"
  }),
  tgFormValues("itemsToPlaceIn")
)(MoveRecordsToEquipmentDialog);
