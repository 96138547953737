/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { NumericInputField, CheckboxField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { inRange } from "../../../../src-shared/utils/formUtils";

const InventoryParameters = props => {
  const { usingInventoryParts, min_match_length } = props;

  return (
    <div>
      <HeaderWithHelper
        header="Inventory Parameters"
        helper="Run the partitioning tool leveraging your inventory"
      />
      <CheckboxField
        name="usingInventoryParts"
        style={{ display: "flex", alignItems: "center" }}
        label={
          <div>
            <b>Use inventory parts </b>
            <span style={{ fontSize: 11 }}>
              Search for relevant sequences at BUILD's aliquots
            </span>
          </div>
        }
      />
      <NumericInputField
        key="min_match_length"
        name="min_match_length"
        label="Minimum match length"
        defaultValue={min_match_length}
        disabled={!usingInventoryParts}
        min={0}
        normalize={inRange([0, 1000], { integer: true })}
        placeholder={100}
        isRequired={false}
      />
    </div>
  );
};

export default InventoryParameters;
