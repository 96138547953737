/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment aliquotAdditiveFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    mass
    massUnitCode
    additives {
      id
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      mass
      massUnitCode
      additiveMaterial {
        id
        name
        additiveType {
          code
          name
        }
      }
    }
  }
`;
