/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import axios from "axios";
import { get, endsWith } from "lodash";
import React from "react";

import defaultValueConstants from "../../tg-iso-shared/src/defaultValueConstants";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import {
  addRequestInterceptorsToAxios,
  addResponseInterceptorsToAxios
} from "./axiosInterceptors";
import { getDefaultValueSharedParams } from "./defaultValueSharedParams";

let api;

try {
  // if window is undefined will throw error
  // log error is omitted if is called from server
  if (window.serverApi) {
    api = window.serverApi;
  } else {
    api = axios.create({
      baseURL: "/"
    });
    addRequestInterceptorsToAxios(api);
    addResponseInterceptorsToAxios(api);
    window.serverApi = api;
  }
} catch (error) {
  if (!global) console.error(error);
}
//set up the integration api
window.triggerIntegrationRequest = async ({
  showToastrError = true,
  endpointId,
  isPagingRequest,
  isFormInputRequest,
  ...rest
}) => {
  try {
    return await window.serverApi.request(
      `/integration-proxy/${endpointId}${
        isPagingRequest ? "?isPagingRequest=true" : ""
      }${isFormInputRequest ? "?isFormInputRequest=true" : ""}`,
      rest
    );
  } catch (e) {
    console.error(e);
    console.error(e.response); // tnw - important to log this since just logging the e doesn't show the response...
    const message = get(e, "response.data.message");
    const source = get(e, "response.data.source");
    if (message) {
      let errorMsg = (message || "Unknown Error").trim();
      // stop double .
      if (endsWith(errorMsg, ".")) {
        errorMsg = errorMsg.substring(0, errorMsg.length - 1);
      }

      // When the error comes with 'source', we know what the error is
      // and the message itself is constructed with information on which
      // node in the flow the error came from (see the "Catch" node for more information).
      const toastrMsg = source ? (
        <div>
          {errorMsg}
          <br />
          <br />
          <div style={{ fontSize: "0.8em" }}>
            <b>Node-Red node:</b> {JSON.stringify(source, null, 2)}
          </div>
        </div>
      ) : (
        `There was an error in the data returned from the integration: ${errorMsg}. See console for more details.`
      );
      showToastrError &&
        window.toastr.error(toastrMsg, {
          timeout: 60 * 1000
        });
      e.__type = "TG_INTEGRATION_ERROR";
      e.__message = message;
    } else {
      window.toastr.error(
        "Error fetching data from the integration. Additional configuration may be required. Please check the console for more details."
      );
    }
    throw e;
  }
};
window.__triggerGetDefaultValueRequest = async (
  code,
  customParams = {},
  { showToastrError, isForExample } = {}
) => {
  const __defaultValConsts = defaultValueConstants[code];
  if (!__defaultValConsts) {
    return window.toastr.warning(
      `You need to define a defaultValueConstants entry for ${code}`
    );
  }
  try {
    const res = await window.serverApi.request(`/get-default-value/${code}`, {
      method: "POST",
      data: {
        __defaultValConsts,
        ...isForExample,

        params: {
          ...getDefaultValueSharedParams(),
          ...(__defaultValConsts.staticParams || {}),
          ...customParams
        }
      }
    });

    const toReturn = {
      defaultValue: get(res, "data.defaultValue"),
      warningMessage: get(res, "data.warningMessage"),
      allowUserOverride: get(res, "data.allowUserOverride", true)
    };
    return toReturn;
  } catch (e) {
    console.error(e);
    const message = get(e, "response.data.message");
    if (message) {
      showToastrError &&
        window.toastr.error(
          `There was an error in the data returned from the get-default-value request: ${
            message || "Unknown Error"
          }. See console for more details.`
        );
      e.__type = "TG_GET_DEFAULT_VALUE_ERROR";
      e.__message = message;
    } else {
      window.toastr.error(
        "An error occurred fetching the data. Please verify that you've correctly set up the get default value template. See console for more details."
      );
    }
    return { defaultValue: "Template Error", allowUserOverride: true };
  }
};

window.tgApi = async ({ ...options }) => {
  const apiHeaders = getRequestHeaderKeys();
  return window.serverApi.request({
    baseURL: "/tg-api",
    ...options,
    headers: {
      ...apiHeaders,
      ...options.headers
    }
  });
};

export default api;
