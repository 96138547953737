/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Button } from "@blueprintjs/core";
import {
  DataTable,
  InfoHelper,
  NumericInputField,
  ReactSelectField,
  SelectField
} from "@teselagen/ui";
import {
  ValidationAlgorithms,
  GIBSON_ASSEMBLY,
  GOLDEN_GATE_ASSEMBLY
} from "./ValidationAlgorithms";
import { INTENT_PRIMARY } from "@blueprintjs/core/lib/esm/common/classes";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import AlgorithmInputDialog from "./AlgorithmInputDialog";
import { inRange } from "../../../../src-shared/utils/formUtils";
import { identity } from "lodash";

// const RESTRICTION_ENZYMES = [ "AarI","BsaI", "BsmBI"]

const InfoHelperWrapper = ({ content }) => {
  return <InfoHelper isInline content={content} />;
};

const schema = {
  fields: [
    { path: "label", type: "string", displayName: "Constraint" },
    {
      displayName: " View and Change Default Parameters",
      path: "edit"
    }
  ]
};

const BasicParameterInput = ({ name, label, isPercentage, required }) => {
  return (
    <div style={{ marginRight: 40 }}>
      <NumericInputField
        isRequired={required}
        name={name}
        label={label}
        normalize={
          isPercentage ? inRange([0, 100], { integer: true }) : identity
        }
        max={isPercentage ? 100 : undefined}
        min={isPercentage ? 0 : undefined}
      />
    </div>
  );
};
const MinMaxForm = ({
  includeOptimal,
  mainPrefix,
  required,
  isPercentage = false
}) => {
  const names = {
    min: `${mainPrefix}Min`,
    optimal: `${mainPrefix}Optimal`,
    max: `${mainPrefix}Max`
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
      }}
    >
      <BasicParameterInput
        name={names.min}
        label="Minimum"
        isPercentage={isPercentage}
        required={required}
      />
      {includeOptimal ? (
        <BasicParameterInput
          name={names.optimal}
          label="Optimal"
          isPercentage={isPercentage}
          required={required}
        />
      ) : null}
      <BasicParameterInput
        name={names.max}
        label="Maximum"
        isPercentage={isPercentage}
        required={required}
      />
    </div>
  );
};
const BasicGibsonParametersForm = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginBottom: 60
      }}
    >
      {/* <h5>
        Parts Size (bp){" "}
        <InfoHelperWrapper content="Length range (min and max) permitted for synthesis." />
      </h5>
      <MinMaxForm
        includeOptimal={false}
        mainPrefix="partitionSize"
        name="partitionSize"
        required={false}
      /> */}
      <h5 style={{ marginTop: 20 }}>
        Overlap Size (bp){" "}
        <InfoHelperWrapper content="Length range (min, max and optimal) allowed for overlaps." />
      </h5>
      <MinMaxForm
        includeOptimal={true}
        mainPrefix="overlapSize"
        name="OverlapSize"
        required={false}
      />
      <h5 style={{ marginTop: 20 }}>
        Overlap GC content (bp) (%){" "}
        <InfoHelperWrapper content="GC content range (min, max and optimal) used for evaluating scoring overlaps." />
      </h5>
      <MinMaxForm
        includeOptimal={true}
        mainPrefix="overlapGcContent"
        isPercentage={true}
        name="OverlapGCContent"
        required={false}
      />
    </div>
  );
};

const GoldenGateParametersForm = ({ restrictionEnzymes }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginBottom: 60
      }}
    >
      <h5 style={{ marginTop: 20 }}>
        Restriction enzymes{" "}
        <InfoHelperWrapper content="Choose digest enzymes for partitioner. This tool considers a single enzyme in the final design. When inventory search is enabled, the enzyme that maximizes inventory usage is chosen by the tool." />
      </h5>
      <ReactSelectField
        // className="tg-no-form-group-margin"

        name="selectedRestrictionEnzymes"
        multi={true}
        //value={restrictionEnzymes}
        // label="Choose a value"
        options={restrictionEnzymes.map(el => ({ value: el, label: el.name }))}
      />
    </div>
  );
};

const Parameters = props => {
  const {
    validationAlgorithmsParamsReducer,
    validationAlgorithms,
    assemblyStrategy,
    restrictionEnzymes
  } = props;

  const onAlgorithmCheckboxChange = value => {
    if (value && value.reduxFormSelectedEntityIdMap) {
      const selectedAlgorithms = Object.values(
        value.reduxFormSelectedEntityIdMap
      )
        .filter(el => el !== false)
        .map(el => el.entity.name);
      validationAlgorithmsParamsReducer({
        type: "SET-VALIDATION-ALGORITHMS",
        selectedAlgorithms
      });
    }
  };

  return (
    <React.Fragment>
      <h5>
        Parts Size (bp){" "}
        <InfoHelperWrapper content="Length range (min and max) permitted for synthesis." />
      </h5>
      <MinMaxForm
        includeOptimal={false}
        mainPrefix="partitionSize"
        name="partitionSize"
        required={false}
      />
      <h5>
        Assembly strategy{" "}
        <InfoHelperWrapper content="Assembly strategy to guide how to make partitions" />
      </h5>
      <SelectField
        className="tg-no-form-group-margin"
        name="assemblyStrategy"
        // label="Choose a value"
        options={[GIBSON_ASSEMBLY, GOLDEN_GATE_ASSEMBLY]}
      />
      {assemblyStrategy === GOLDEN_GATE_ASSEMBLY && (
        <GoldenGateParametersForm restrictionEnzymes={restrictionEnzymes} />
      )}
      {assemblyStrategy === GIBSON_ASSEMBLY && (
        <span>
          <BasicGibsonParametersForm />
          <h4 style={{ width: "100%" }}>
            Partitioning Constraints{" "}
            <InfoHelperWrapper content="Additional constraints for partition" />
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <DataTable
              withCheckboxes
              isSimple={true}
              selectAllByDefault
              mustClickCheckboxToSelect={true}
              destroyOnUnmount={false}
              formName="chooseReviewAlgorithms"
              entities={Object.values(ValidationAlgorithms)}
              isSingleSelect={false}
              onChange={onAlgorithmCheckboxChange}
              schema={schema}
              validationAlgorithms={validationAlgorithms}
              cellRenderer={{
                edit: (_, r) => {
                  const { label, inputs, name } = r;
                  return (
                    <Button
                      intent={INTENT_PRIMARY}
                      icon="edit"
                      disabled={
                        !validationAlgorithms.map(el => el.name).includes(name)
                      }
                      onClick={() => {
                        showDialog({
                          ModalComponent: AlgorithmInputDialog,
                          modalProps: {
                            inputs,
                            label,
                            handleAlgorithmParams: (paramName, paramValue) => {
                              validationAlgorithmsParamsReducer({
                                type: "CHANGE-VALIDATION-ALGORITHM-PARAMETER",
                                algorithmName: name,
                                validationParameterName: paramName,
                                validationParameterValue: paramValue
                              });
                            }
                          }
                        });
                      }}
                    ></Button>
                  );
                }
              }}
              isLoading={false}
            />
          </div>
        </span>
      )}
    </React.Fragment>
  );
};

export default Parameters;
