/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const plateFragment = gql`
  fragment updateAliquotExtPropsPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      isPlate
      containerFormat {
        code
        rowCount
        columnCount
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        id
      }
    }
  }
`;

export const tubeFragment = [
  "aliquotContainer",
  "id name barcode { id barcodeString }"
];
