/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * A class to represent an arbitrary directed acyclic graph.
 */
class DAG {
  constructor() {
    /**
     * A map from a key to an object with keys `value`, `out`, `in`, `outCount`, and `inCount`. The
     * `value` is what is is referenced by the key. The `out` key is the set
     * of keys of the destination nodes of the outgoing edges. The `in` key is
     * the set of keys of the source nodes of the ingoing edges.
     */
    this.graph = {};
  }

  /**
   * Add a node with the given key and value to the graph.
   * @param {string} key The key used to reference the value. Must be unique across nodes.
   * @param {*} value
   */
  addNode(key, value) {
    initializeNode(this.graph, key).value = value;
  }

  /**
   * Add an edge to the graph. If the nodes don't exist, then dummy nodes will
   * be created in their place. This method does not check if the edge introduces
   * a cycle.
   * @param {string} srcKey The key of the source node of the edge.
   * @param {string} dstKey The key of the destination node of the edge.
   */
  addEdge(srcKey, dstKey) {
    const srcNode = initializeNode(this.graph, srcKey);
    const dstNode = initializeNode(this.graph, dstKey);

    if (!srcNode.out[dstKey]) srcNode.outCount++;
    if (!dstNode.in[srcKey]) dstNode.inCount++;

    srcNode.out[dstKey] = true;
    dstNode.in[srcKey] = true;
  }

  /**
   * Remove an edge from the graph. An error will be thrown if the edge does
   * not exist.
   * @param {string} srcKey The key of the source vertex of the edge.
   * @param {string} dstKey The key of destination vertex of the edge.
   */
  removeEdge(srcKey, dstKey) {
    const srcNode = this.graph[srcKey];
    const dstNode = this.graph[dstKey];

    if (!srcNode) throw new Error(`Vertex with key ${srcKey} not found.`);
    if (!dstNode) throw new Error(`Vertex with key ${dstKey} not found.`);

    if (!srcNode.out[dstKey] || !dstNode.in[srcKey])
      throw new Error(`Edge from ${srcKey} to ${dstKey} not found.`);

    delete srcNode.out[dstKey];
    srcNode.outCount--;

    delete dstNode.in[srcKey];
    dstNode.inCount--;
  }

  /**
   * Get the keys of all of the vertices that `vertexKey` has
   * edges to.
   * @param {string} vertexKey
   * @returns {Array<string>}
   */
  getOutVertexKeys(vertexKey) {
    return Object.keys(this.graph[vertexKey].out);
  }

  /**
   * Get the in-degree of a given vertex.
   * @param {string} vertexKey
   */
  getInDegree(vertexKey) {
    return this.graph[vertexKey].inCount;
  }

  /**
   * Get the keys of the source nodes in the DAG.
   * @returns {Array<string>}
   */
  getSourceKeys() {
    return Object.values(this.graph)
      .filter(n => n.inCount === 0)
      .map(n => n.key);
  }

  /**
   * Get the keys of all nodes in the DAG.
   * @returns {Array<string>}
   */
  getAllKeys() {
    return Object.values(this.graph).map(n => n.key);
  }

  /**
   * Get the number of nodes in the graph.
   */
  getNumberOfNodes() {
    return Object.keys(this.graph).length;
  }

  /**
   * Get the value associated with a key.
   * @param {string} key
   */
  getValue(key) {
    return this.graph[key].value;
  }

  /**
   * See if this graph contains any cycles.
   */
  isCyclic() {
    const sourceKeys = this.getSourceKeys();
    // const srcKeyToIndex = sourceKeys.reduce((acc, key, i) => {
    //   acc[key] = i;
    //   return acc;
    // }, {});

    const allKeys = this.getAllKeys();
    const allKeysToIndex = allKeys.reduce((acc, key, i) => {
      acc[key] = i;
      return acc;
    }, {});

    // console.log(`isCyclic:Source Keys and Index`, { sourceKeys, srcKeyToIndex });

    // Mark all the vertices as not visited and
    // not part of recursion stack
    const visited = [];
    const recStack = [];

    // Call the recursive helper function to
    // detect cycle in different DFS trees
    for (const v of sourceKeys) {
      if (this._isCyclicUtil(allKeysToIndex, v, visited, recStack)) {
        console.info("\n\ncycle detected!");
        console.info({ allKeysToIndex, v, visited, recStack });
        console.info("\n");
        return true;
      }
    }

    return false;
  }

  /**
   * Helper function for `isCyclic`
   * @private
   */
  _isCyclicUtil(allKeysToIndex, v, visited, recStack) {
    // console.log(`_isCyclicUtil(allKeysToIndex, v, visited, recStack)`, { allKeysToIndex, v, visited, recStack });
    const i = allKeysToIndex[v];

    if (i === undefined) {
      console.info("cycle detection found undefined, this shouldn't happen!");
      return false;
    }

    // Mark the current node as visited and
    // part of recursion stack
    if (recStack[i]) {
      // console.warn(`Source Key ${i}: ${v} already exists in recursion stack`);
      return true;
    }
    if (visited[i]) {
      return false;
    }
    visited[i] = true;
    recStack[i] = true;
    // console.log(`Adding source Key ${i}: ${v} to recursion stack`);

    const children = this.getOutVertexKeys(v);
    for (const c of children) {
      if (this._isCyclicUtil(allKeysToIndex, c, visited, recStack)) return true;
    }

    recStack[i] = false;

    return false;
  }
}

/**
 * Add a dummy node to the graph with the given key if the node doesn't already exist.
 * Otherwise do nothing.
 * Returns the node.
 */
function initializeNode(graph, key) {
  if (!graph[key]) {
    graph[key] = {
      key: key,
      value: null,
      out: {},
      outCount: 0,
      in: {},
      inCount: 0
    };
  }

  return graph[key];
}

export default DAG;
