/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const microbialTransformationAliquotFragment = gql`
  fragment microbialTransformationAliquotFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    sample {
      id
      sampleTypeCode
      sampleFormulations {
        id
        materialCompositions {
          id
          material {
            id
            name
            materialTypeCode
            polynucleotideMaterialSequence {
              id
            }
          }
        }
      }
      material {
        id
        name
        materialTypeCode
        polynucleotideMaterialSequence {
          id
        }
      }
    }
  }
`;
export const microbialTransformationPlateFragment = gql`
  fragment microbialTransformationPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormatCode
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        code
        name
        isTube
        maxVolume
        deadVolume
        volumetricUnitCode
        deadVolumetricUnitCode
      }
      rowPosition
      columnPosition
      aliquot {
        ...microbialTransformationAliquotFragment
      }
    }
  }
  ${microbialTransformationAliquotFragment}
`;

export const microbialTransformationTubeFragment = gql`
  fragment microbialTransformationTubeFragment on aliquotContainer {
    id
    aliquotContainerTypeCode
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainerType {
      code
      name
      isTube
      maxVolume
      deadVolume
      volumetricUnitCode
      deadVolumetricUnitCode
    }
    aliquot {
      ...microbialTransformationAliquotFragment
    }
  }
  ${microbialTransformationAliquotFragment}
`;
