/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

import { exampleRecord } from "../exampleRecords";
import { updateSubtypes, updateSubtypesArray } from "../importExportSubtypes";
import { exampleRecordValidation } from "../validationFns";
import { EXPORT__EXPORT, EXPORT__FORMAT } from "./EXPORT";

const UPDATE = {
  defaultSubtype: updateSubtypes.DNA_SEQUENCE,
  subtypes: updateSubtypesArray,
  description: `Users can select 1 or more records Right Click > Update > "Your Custom Update Text Here" (Or from a record view)`,
  endpoints: {
    UPDATE__FORMAT: {
      ...EXPORT__FORMAT,
      isOptional: true,
      exampleRequest: {
        __validate__: ({ subtype }) => {
          return yup.object({
            records: yup.array(
              exampleRecordValidation({
                subtype,
                doNotRequireExternalFields: true
              })
            )
          });
        },
        //it receives an array of records
        records: [
          exampleRecord({ alwaysIds: true }),
          exampleRecord({ alwaysIds: true })
        ]
      },
      // exampleResponse defined on the ...EXPORT__FORMAT spread
      description:
        "The 'Format' request is to help configure the UI that the user sees when doing the update. It receives a list of the  More info here: http://docs.teselagen.com/en/articles/4626993-custom-form-inputs-integrations"
    },
    UPDATE__UPDATE: {
      ...EXPORT__EXPORT,
      name: "Update Endpoint",
      description:
        "This endpoint receives record data and can return a subset of fields to update. It can optionally pass a reason why the update failed.",
      exampleRequest: {
        __validate__: ({ subtype }) =>
          yup.object({
            additionalFields: yup.object(),
            record: exampleRecordValidation({
              subtype,
              doNotRequireExternalFields: true
            })
          }),
        additionalFields: {
          jiraStatus: "complete"
        },
        record: exampleRecord({ alwaysIds: true })
      },
      exampleResponse: {
        __validate__: ({ subtype }) =>
          exampleRecordValidation({
            subtype,
            isUpdateCall: true,
            doNotRequireExternalFields: true
          }),
        ...exampleRecord({ isUpdateCall: true })
      }
    }
  }
};

export default UPDATE;
