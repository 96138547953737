/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  SelectField,
  Loading,
  RadioGroupField,
  InfoHelper
} from "@teselagen/ui";
import { keyBy, get } from "lodash";
import { Button, Intent } from "@blueprintjs/core";
// import pcrPlateParameterSetFragment from "../../../../graphql/fragments/pcrPlateParameterSetFragment";
import containerFormatFragment from "../../../../../../tg-iso-shared/src/fragments/containerFormatFragment";
import PlateMapView from "../../../../components/PlateMapView";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import withQuery from "../../../../../src-shared/withQuery";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import plateMapGroupPreviewFragment from "../../../../graphql/fragments/plateMapGroupPreviewFragment";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { getToolOutputItems } from "../../../../utils";
import ZonesPerPlateField from "../ZonesPerPlateField";
import TemperatureZoneOrientationField from "../TemperatureZoneOrientationField";
import { distributeIntoTemperatureBlocks } from "../utils";

const pcrProductSchema = {
  model: "plateMapEntity",
  fields: [
    {
      path: "location",
      type: "string",
      displayName: "Location"
    },
    {
      path: "pcrProductSequence.name",
      type: "string",
      displayName: "PCR Product Sequence"
    },
    {
      path: "pcrProductSequence.size",
      type: "string",
      displayName: "Product Size (bp)"
    },
    {
      path: "primaryTemplate.name",
      type: "string",
      displayName: "Primary Template"
    },
    {
      path: "forwardPrimer.sequence.name",
      type: "string",
      displayName: "Forward Primer"
    },
    {
      path: "reversePrimer.sequence.name",
      type: "string",
      displayName: "Reverse Primer"
    }
  ]
};

const pcrProductSchemaForSourcePlateMapGroup = {
  model: "plateMapEntity",
  fields: pcrProductSchema.fields.map(field => {
    if (field.path === "location") {
      return field;
    } else {
      return {
        ...field,
        path: "j5Item.j5PcrReaction." + field.path
      };
    }
  })
};

class PlateConfiguration extends Component {
  state = {
    loadingNebCalc: false,
    plateMapPreview: null
  };

  componentDidMount() {
    const { validationPlateMapGroup, sourcePlateMapGroup } = this.props;
    let plateMapPreview;
    if (sourcePlateMapGroup) {
      plateMapPreview = sourcePlateMapGroup;
    } else if (validationPlateMapGroup) {
      plateMapPreview = validationPlateMapGroup;
    } else {
      plateMapPreview = this.runJ5Distribution();
    }
    this.setState({
      plateMapPreview
    });
  }

  changeDistributionMethod = async distributeMethod => {
    if (distributeMethod === "neb") {
      const {
        j5Reports = [],
        stepFormProps: { change }
      } = this.props;
      this.setState({
        loadingNebCalc: true
      });

      let sequenceIds = [];
      j5Reports.forEach(report => {
        report.j5PcrReactions.forEach(j5PcrReaction => {
          sequenceIds.push(
            j5PcrReaction.forwardPrimer &&
              j5PcrReaction.forwardPrimer.sequenceId
          );
          sequenceIds.push(
            j5PcrReaction.reversePrimer &&
              j5PcrReaction.reversePrimer.sequenceId
          );
        });
      });

      sequenceIds = sequenceIds.filter(id => !!id);

      const seqs = await safeQuery(
        [
          "sequence",
          `
          id
          sequenceFragments {
            id
            index
            fragment
          }
          `
        ],
        {
          options: {
            fetchPolicy: "cache-first"
          },
          variables: {
            filter: {
              id: sequenceIds
            }
          }
        }
      );

      const sequencesWithBps = keyBy(seqs, "id");
      change("sequencesWithBps", sequencesWithBps);
      this.setState({
        loadingNebCalc: false
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const propsToCompare = [
      "sequencesWithBps",
      "distributeMethod",
      "selectedContainerFormat",
      "selectedPcrPlateParameterSet",
      "temperatureZoneOrientation",
      "zonesPerPlate"
    ];
    const stateToCompare = ["loadingNebCalc"];
    const needsUpdate =
      propsToCompare.some(prop => {
        return prevProps[prop] !== this.props[prop];
      }) ||
      stateToCompare.some(state => {
        return prevState[state] !== this.state[state];
      });
    if (needsUpdate) {
      this.setState({
        plateMapPreview: this.runJ5Distribution()
      });
    }
  }

  runJ5Distribution = () => {
    const { loadingNebCalc } = this.state;
    const {
      selectedContainerFormat,
      selectedPcrReactions = [],
      distributeMethod,
      sequencesWithBps = {},
      temperatureZoneOrientation,
      toolSchema,
      zonesPerPlate
    } = this.props;

    if (!selectedContainerFormat || loadingNebCalc) return;
    const plateMaps = distributeIntoTemperatureBlocks({
      selectedPcrReactions,
      sequencesWithBps,
      containerFormat: selectedContainerFormat,
      distributeMethod,
      zonesPerPlate,
      temperatureZoneOrientation
    });

    const outputItems = getToolOutputItems(toolSchema);

    const plateMapPreview = {
      plateMaps,
      name: outputItems.find(item => item.name === "plateMapGroup")
        .defaultValue,
      containerFormatCode: selectedContainerFormat.code,
      containerFormat: selectedContainerFormat
    };
    return plateMapPreview;
  };

  savePreviewToForm = () => {
    const {
      stepFormProps: { change },
      sourcePlateMapGroup
    } = this.props;
    let plateMapGroup;
    if (!sourcePlateMapGroup) {
      plateMapGroup = this.runJ5Distribution();
    }
    change("plateMapGroup", plateMapGroup);
  };

  updatePlateMapView() {
    const {
      validationPlateMapGroup,
      selectedPcrReactions = [],
      stepFormProps: { change }
    } = this.props;
    const productMaterialIdToReactionMap = {};
    selectedPcrReactions.forEach(reaction => {
      const pcrProductMaterialId = get(
        reaction,
        "pcrProductSequence.polynucleotideMaterialId"
      );
      if (!productMaterialIdToReactionMap[pcrProductMaterialId]) {
        productMaterialIdToReactionMap[pcrProductMaterialId] = reaction;
      }
    });

    const plateMaps = validationPlateMapGroup.plateMaps.map(plateMap => {
      const plateMapItems = [];
      plateMap.plateMapItems.forEach(pmi => {
        const productMaterialId = get(pmi, "inventoryItem.material.id");
        if (productMaterialIdToReactionMap[productMaterialId]) {
          plateMapItems.push({
            rowPosition: pmi.rowPosition,
            columnPosition: pmi.columnPosition,
            ...productMaterialIdToReactionMap[productMaterialId]
          });
        }
      });
      return {
        name: plateMap.name,
        type: "j5PcrReaction",
        plateMapItems
      };
    });

    const updatedPlateMapView = {
      containerFormatCode: validationPlateMapGroup.containerFormatCode,
      containerFormat: validationPlateMapGroup.containerFormat,
      plateMaps
    };
    change("validationPlateMapView", updatedPlateMapView);
    return updatedPlateMapView;
  }

  renderPlateMapPreview() {
    const { loadingNebCalc, plateMapPreview } = this.state;
    const {
      // selectedPcrProtocol,
      // pcrPlateParameterSets = [],
      distributeMethod,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      validationPlateMapGroup,
      sourcePlateMapGroup,
      containerFormats = [],
      selectedContainerFormat,
      temperatureZoneOrientation
    } = this.props;

    if (sourcePlateMapGroup) {
      return (
        <div className="tg-step-form-section column">
          <PlateMapView
            plateMapGroup={sourcePlateMapGroup}
            tableSchema={pcrProductSchemaForSourcePlateMapGroup}
            noPadding
          />
        </div>
      );
    } else {
      const containerFormatOptions = arrayToItemValuedOptions(containerFormats);
      const containerFormatDefault = containerFormatOptions.find(
        containerFormat => containerFormat.value.code === "96_WELL"
      );
      let updatedPlateMapView;
      if (validationPlateMapGroup) {
        updatedPlateMapView = this.updatePlateMapView();
      }

      return (
        <React.Fragment>
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Plate Configuration"
              helper="Select a plate format. A preview of the PCR plate will be rendered below."
              width="100%"
            />
            <SelectField
              name="selectedContainerFormat"
              label="Plate Format"
              placeholder="Select a Plate Format"
              options={containerFormatOptions}
              defaultValue={containerFormatDefault.value}
            />
          </div>
          {(plateMapPreview || loadingNebCalc) && (
            <div className="tg-step-form-section column">
              <h6>
                {loadingNebCalc ? "Loading Preview..." : plateMapPreview.name}
              </h6>
              <div style={{ display: "flex" }}>
                <div>
                  <RadioGroupField
                    name="distributeMethod"
                    disabled={loadingNebCalc}
                    onFieldSubmit={this.changeDistributionMethod}
                    generateDefaultValue={
                      defaultValueConstants.PCR_PLANNING_TEMP_DISTRIBUTION
                    }
                    options={
                      defaultValueConstants.PCR_PLANNING_TEMP_DISTRIBUTION
                        .options
                    }
                  />
                  {distributeMethod === "disableZones" && (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="tg-no-form-group-margin"
                    >
                      <GenericSelect
                        {...{
                          noFill: true,
                          name: "validationPlateMapGroup",
                          schema: [
                            "name",
                            {
                              displayName: "Plate Format",
                              path: "containerFormat.name"
                            },
                            dateModifiedColumn
                          ],
                          fragment: [
                            "plateMapGroup",
                            "id name containerFormatCode containerFormat { code name } updatedAt"
                          ],
                          buttonProps: {
                            loading: isLoadingMap.plateMapGroup,
                            disabled: isDisabledMap.plateMapGroup
                          },
                          additionalDataFragment: plateMapGroupPreviewFragment
                        }}
                      />
                      <InfoHelper
                        style={{ marginLeft: 5 }}
                        content="The plate map you select here will determine where materials will be placed on the destination plate"
                      ></InfoHelper>
                    </div>
                  )}
                </div>
                {distributeMethod !== "disableZones" && (
                  <div style={{ marginLeft: 20 }}>
                    <TemperatureZoneOrientationField />
                    <ZonesPerPlateField
                      containerFormat={selectedContainerFormat}
                      temperatureZoneOrientation={temperatureZoneOrientation}
                    />
                  </div>
                )}
              </div>

              <Loading
                loading={loadingNebCalc}
                bounce
                style={{ minHeight: 400 }}
              >
                <PlateMapView
                  plateMapGroup={updatedPlateMapView || plateMapPreview}
                  tableSchema={pcrProductSchema}
                  noPadding
                />
              </Loading>
            </div>
          )}
        </React.Fragment>
      );
    }
  }

  render() {
    const { plateMapPreview } = this.state;
    const {
      // selectedPcrProtocol,
      // pcrPlateParameterSets = [],
      containerFormatsLoading,
      pcrProtocolsLoading,
      pcrPlateParameterSetsLoading,
      Footer,
      footerProps
    } = this.props;

    // const pcrPlateParameterSetOptions = arrayToItemValuedOptions(
    //   pcrPlateParameterSets
    // );
    // const pcrPlateParameterSetDefault = pcrPlateParameterSetOptions.find(
    //   parameterSet =>
    //     parameterSet.label === "Golden Gate Standard Parameter Set"
    // );

    const isAnyLoading =
      containerFormatsLoading ||
      pcrProtocolsLoading ||
      pcrPlateParameterSetsLoading;
    if (isAnyLoading) {
      return (
        <div className="tg-step-form-section" style={{ width: "100%" }}>
          <Loading inDialog bounce />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.renderPlateMapPreview()}

        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.PRIMARY}
              disabled={!plateMapPreview}
              onClick={this.savePreviewToForm}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  // withQuery(pcrPlateParameterSetFragment, { isPlural: true }),
  withQuery(containerFormatFragment, {
    isPlural: true,
    options: { variables: { filter: { code: ["96_WELL", "384_WELL"] } } }
  }),
  stepFormValues(
    "selectedPcrPlateParameterSet",
    "selectedContainerFormat",
    "j5Reports",
    "selectedPcrReactions",
    "distributeMethod",
    "sequencesWithBps",
    "validationPlateMapGroup",
    "sourcePlateMapGroup",
    "temperatureZoneOrientation",
    "zonesPerPlate"
  )
)(PlateConfiguration);
