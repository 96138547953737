/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { Callout, Classes } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { formValueSelector } from "redux-form";
import {
  InputField,
  TextareaField,
  ReactSelectField,
  CheckboxField,
  EditableTextField,
  SuggestField,
  FileUploadField,
  NumericInputField,
  RadioGroupField,
  DateInputField,
  DateRangeInputField,
  ReactColorField,
  SwitchField
} from "@teselagen/ui";
import { useDebounce } from "use-debounce";

const FieldComponents = {
  InputField,
  TextareaField,
  ReactSelectField,
  CheckboxField,
  EditableTextField,
  SuggestField,
  FileUploadField,
  NumericInputField,
  RadioGroupField,
  DateInputField,
  DateRangeInputField,
  ReactColorField,
  ColorField: ReactColorField,
  SwitchField,
  SelectField: ReactSelectField,
  PagedSelectField: ({
    onFieldSubmit,
    url,
    hiddenUntil,
    endpointId,
    ...rest
  }) => {
    const [pageNumberOrCursor, setPageNumberOrCursor] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [query, setQuery] = useState("");
    const [debouncedQuery] = useDebounce(query, 1000, { leading: true });
    const [options, setOptions] = useState([]);
    const [isLoadingOptions, setIsLoadingOptions] = useState();

    useEffect(() => {
      (async () => {
        setIsLoadingOptions(true);
        const res = await window.triggerIntegrationRequest({
          isPagingRequest: true,
          endpointId,
          data: {
            url,
            pageNumberOrCursor,
            query: debouncedQuery
          },
          method: "POST"
        });

        let { options: opts, nextPageOrCursor } = res.data;

        const getLoadMoreOption = backToPage1 => ({
          value: "loadMore",
          label: (
            <span
              className={Classes.TEXT_MUTED}
              style={{ fontStyle: "italic" }}
            >
              {backToPage1 ? `Back To Page 1` : `Next Page`}
            </span>
          ),
          onClick: e => {
            e.stopPropagation();
            e.preventDefault();
            setPageNumberOrCursor(backToPage1 ? 0 : nextPageOrCursor);
          }
        });

        if (pageNumberOrCursor !== 0) {
          opts = [getLoadMoreOption(true), ...opts];
        }
        if (nextPageOrCursor) {
          opts = [...opts, getLoadMoreOption()];
        }
        setIsLoadingOptions(false);
        setOptions(opts);
      })();
    }, [endpointId, pageNumberOrCursor, debouncedQuery, url]);

    const optsToUse = selectedOptions.concat(options);

    const isLoading = rest.isLoading || isLoadingOptions;
    return (
      <ReactSelectField
        {...rest}
        isLoading={isLoading}
        itemListPredicate={(q, items) => {
          return items.filter(
            i => !selectedOptions.find(opt => opt.value === i.value)
          );
        }}
        onInputChange={q => {
          setIsLoadingOptions(true);
          setPageNumberOrCursor(0);
          setQuery(q);
        }}
        onFieldSubmit={(...args) => {
          onFieldSubmit(...args);
          const val = args[0];
          const valArray = [].concat(val || []);
          const selectedOptions = valArray.map(v =>
            options.find(opt => opt.value === v)
          );
          setSelectedOptions(selectedOptions);
        }}
        options={optsToUse}
      ></ReactSelectField>
    );
  }
};

export function getFormInputsUi(
  formInputs,
  { formName, endpointId, prefix = "", extraParams }
) {
  return formInputs.map(({ fieldType, ...rest }, i) => {
    const ComponentToUse = FieldComponents[fieldType];
    if (ComponentToUse) {
      return (
        <AddFormValuesHOC
          formName={formName}
          {...((fieldType === "PagedSelectField" ||
            fieldType === "SelectField") && { endpointId })}
          ComponentToUse={ComponentToUse}
          key={rest.name || i}
          {...rest}
          prefix={prefix}
          extraParams={extraParams}
          name={prefix + rest.name}
        ></AddFormValuesHOC>
      );
    } else {
      return (
        <Callout key={i} intent="danger">
          {" "}
          {fieldType
            ? `No Component was found for ${fieldType}`
            : "No fieldType field was passed as a response"}{" "}
        </Callout>
      );
    }
  });
}

const AddFormValuesHOC = connect(
  (state, { formName, name, hiddenUntil = [] }) => {
    const extraProps = {};
    const selector = formValueSelector(formName);
    hiddenUntil.forEach(fieldName => {
      const prop = selector(state, fieldName);
      extraProps[fieldName] = prop;
    });
    extraProps.currentValue = selector(state, name);
    return extraProps;
  }
)(({
  ComponentToUse,
  cascadeEndpoint,
  extraParams,
  currentValue,
  endpointId,
  formName,
  prefix,
  ...rest
}) => {
  const [parentValue, setParentVal] = useState(currentValue);
  const [loadingCascadedInputs, setLoadingCascadedInputs] = useState();
  const [formInputs, setFormInputs] = useState([]);

  let inner = (
    <ComponentToUse
      {...{
        ...rest,
        endpointId,
        ...(loadingCascadedInputs && {
          isLoading: true,
          validate: stopSubmit
        }),
        onFieldSubmit: setParentVal
      }}
    ></ComponentToUse>
  );

  useEffect(() => {
    (async () => {
      if (!cascadeEndpoint) return;
      setLoadingCascadedInputs(true);
      try {
        const res = await window.triggerIntegrationRequest({
          isFormInputRequest: true,
          endpointId: endpointId,
          data: {
            url: cascadeEndpoint,
            parentName: rest.name,
            parentLabel: rest.label,
            parentValue,
            ...extraParams
          },
          method: "POST"
        });
        const { formInputs } = res.data;
        setLoadingCascadedInputs(false);
        setFormInputs(formInputs);
      } catch (error) {
        window.toastr.error(
          "Error loading Cascading Dropdown - additional configuration required."
        );
        console.error("Cascading Dropdown Error Details:", error);
        setLoadingCascadedInputs(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cascadeEndpoint, parentValue, endpointId, rest.name, rest.label]);
  if (cascadeEndpoint) {
    //recursively get formInputsUi
    const formInputsUi = getFormInputsUi(formInputs, {
      formName,
      endpointId,
      prefix
    });
    inner = (
      <React.Fragment>
        {inner}
        {formInputsUi}
      </React.Fragment>
    );
  }

  return inner;
});

function stopSubmit() {
  return "Can't Submit While Loading";
}
