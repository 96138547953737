/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

export default {
  description:
    "Hit an endpoint to export a worklist in a custom liquid handler format",
  endpoints: {
    EXPORT_CUSTOM_WORKLIST: {
      name: "Export Custom Liquid Handler Worklist",
      method: "POST",
      description: "Export a worklist in a custom liquid handler format",
      exampleRequest: {
        __validate__: () =>
          yup.object({
            data: yup.array(
              yup.object({
                filename: yup.string(),
                contents: yup.object({
                  id: yup.string(),
                  name: yup.string(),
                  worklistTransfers: yup.array(
                    yup.object({
                      id: yup.string(),
                      volume: yup.number(),
                      volumetricUnitCode: yup.string(),
                      sourceAliquotContainer: yup.object(),
                      destinationAliquotContainer: yup.object()
                    })
                  )
                })
              })
            )
          }),
        data: [
          {
            filename: "Worklist.csv",
            contents: {
              id: "98765",
              name: "Worklist",
              worklistTransfers: [
                {
                  id: "678",
                  volume: "100",
                  volumetricUnitCode: "uL",
                  sourceAliquotContainer: {
                    id: "135",
                    rowPosition: 0,
                    columnPosition: 0,
                    aliquot: {
                      id: "222",
                      sample: {
                        id: "333",
                        name: "pCir"
                      }
                    },
                    containerArray: {
                      id: "434",
                      name: "Plate S",
                      barcode: {
                        id: "408",
                        barcodeString: "Z00123"
                      },
                      containerArrayType: {
                        id: "444",
                        name: "Generic 96 Well Plate"
                      }
                    }
                  },
                  destinationAliquotContainer: {
                    id: "246",
                    rowPosition: 0,
                    columnPosition: 0,
                    aliquot: null,
                    containerArray: {
                      id: "865",
                      name: "Plate D",
                      barcode: {
                        id: "510",
                        barcodeString: "Z00456"
                      },
                      containerArrayType: {
                        id: "555",
                        name: "Generic 96 Well Plate"
                      }
                    }
                  }
                }
              ]
            }
          }
        ]
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            data: yup.array(
              yup.object({
                filename: yup.string(),
                contents: yup.string()
              })
            )
          });
        },
        data: [
          {
            filename: "Worklist_CustomFormat.csv",
            contents:
              "Source Plate Name, Source Plate Barcode, Source Well, Destination Plate Name, Destination Plate Barcode, Destination Well, Transfer Volume, Plate S, Z00123, A1, Plate D, Z00456, A1, 100"
          }
        ]
      }
    }
  }
};
