/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import BinPanel from "../components/HierarchicalDesign/DesignInspector/BinPanel";
import {
  getSelectedBin,
  getSelectedBinNumber,
  getSelectedCard,
  getSelectedCardLevel
} from "../../src-shared/selectors/designViewSelectors";
import {
  getReferencedValue,
  getSizeOfElement,
  getDsfForCard,
  getRuleSetsOfBin,
  getElementsInBin,
  getAssemblyMethodOfCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";
import actions from "../../src-shared/redux/actions";
import {
  isViewClassic,
  getInputCardById
} from "../../src-shared/selectors/classicViewSelectors";
import { getJunctionOnCard } from "../../../tg-iso-design/selectors/junctionSelectors";
import { cannotChangeIcon } from "../../src-shared/utils/cannotChangeIcon";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

const elementEntitiesSelector = tgCreateCachedSelector(
  state => state,
  getElementsInBin,
  (state, elements) => {
    return elements.map(el => ({
      ...el,
      size: getSizeOfElement(state, el.id)
    }));
  }
)((state, binId) => binId);

const mapStateToProps = state => {
  const selectedCard = getSelectedCard(state);
  const selectedCardLevel = getSelectedCardLevel(state);
  const selectedBin = getSelectedBin(state);
  const selectedBinNumber = getSelectedBinNumber(state);
  const classic = isViewClassic(state);
  const selectedInputCard =
    selectedBin && classic && getInputCardById(state, selectedBin.id);

  const leftJunction = selectedInputCard
    ? getJunctionOnCard(state, selectedInputCard.id, true) || {}
    : {};
  const rightJunction = selectedInputCard
    ? getJunctionOnCard(state, selectedInputCard.id, false) || {}
    : {};

  return {
    selectedBin: selectedBin,
    selectedBinCard: selectedCard,
    selectedBinNumber: selectedBinNumber,
    selectedBinCardLevel: selectedCardLevel,
    initialValues: selectedBin && {
      ...selectedBin,
      icon: getReferencedValue(state, "bin", selectedBin.id, "iconId"),
      direction: +selectedBin.direction + "",
      dsf: classic
        ? selectedInputCard && !!getDsfForCard(state, selectedInputCard.id)
        : selectedCard && !!getDsfForCard(state, selectedCard.id),
      leftJunctionBps: leftJunction.bps,
      rightJunctionBps: rightJunction.bps,
      leftJunctionIsUnderhang: !leftJunction.fivePrimeCardFormsOverhang,
      rightJunctionIsOverhang: rightJunction.fivePrimeCardFormsOverhang
    },
    elements: selectedBin && elementEntitiesSelector(state, selectedBin.id),
    cannotChangeIcon: selectedBin && cannotChangeIcon(state, selectedBin.id),
    isClassicView: classic,
    selectedInputCard,
    isLocked: !!(
      isDesignLocked(state) ||
      (selectedBin && selectedBin.isLocked)
    ),
    ruleSets: selectedBin && getRuleSetsOfBin(state, selectedBin.id),
    leftJunction,
    rightJunction,
    isUSERInput:
      selectedInputCard &&
      getAssemblyMethodOfCard(state, selectedInputCard.id).name === "USER"
  };
};

const mapDispatchToProps = {
  updateBin: actions.design.updateBin,
  updateJunction: actions.design.updateJunction,
  setDsf: actions.design.setDsf,
  applyRuleSet: actions.design.applyRuleSet,
  removeRuleSet: actions.design.removeRuleSet
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(BinPanel);
