/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import { Dialog, Classes } from "@blueprintjs/core";
import { DataTable, DialogFooter } from "@teselagen/ui";

import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { get } from "lodash";

import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { annotationSizeStartEndColumns } from "../../../../src-shared/utils/libraryColumns";

class DuplicatePartsFoundDialog extends React.Component {
  cellRenderer = {
    start: index => index + 1,
    end: index => index + 1,
    sequenceId: seqId => get(this.props.idToSequence, [seqId, "name"])
  };

  onSubmit = defaultAsyncWrap(async () => {
    const { selectedEntities, hideModal, refetch } = this.props;
    await safeUpsert("part", selectedEntities);
    await refetch();
    await hideModal();
    window.toastr.success(
      `${selectedEntities.length} part${sIfPlural(selectedEntities)} created.`
    );
  }, "Error creating duplicate parts.");

  render() {
    const {
      hideModal,
      loading,
      valuesToUpsert,
      selectedEntities,
      submitting
    } = this.props;

    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title="Duplicate Parts Found"
        style={{ width: 550 }}
      >
        <div className={Classes.DIALOG_BODY}>
          Duplicate parts not created. Choose which parts you would like to
          duplicate.
          <DataTable
            schema={{
              model: "part",
              fields: [
                { path: "name", type: "string", displayName: "Name" },
                ...annotationSizeStartEndColumns,
                {
                  path: "sequenceId",
                  type: "string",
                  displayName: "Sequence of Origin"
                }
              ]
            }}
            formName="duplicatePartsFoundDialog"
            doNotShowEmptyRows
            entities={valuesToUpsert}
            entityCount={valuesToUpsert.length}
            isLoading={loading}
            cellRenderer={this.cellRenderer}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          text="Create Duplicate Parts"
          onClick={this.onSubmit}
          disabled={!selectedEntities.length}
          loading={submitting}
        />
      </Dialog>
    );
  }
}

const selector = formValueSelector("duplicatePartsFoundDialog");

const mapStateToProps = state => ({
  selectedEntities: Object.values(
    selector(state, "reduxFormSelectedEntityIdMap") || {}
  ).map(v => v.entity)
});

export default compose(connect(mapStateToProps))(DuplicatePartsFoundDialog);
