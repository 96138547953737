/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { showConfirmationDialog } from "@teselagen/ui";

export default async function showStrippedAminoAcidsWarningConfirmation(
  aminoAcidsWithStrippedCharacters = []
) {
  if (aminoAcidsWithStrippedCharacters.length) {
    const message = (
      <span>
        The following amino acid sequences have invalid characters. These
        characters will be removed on upload.
        <br />
        <br />
        {aminoAcidsWithStrippedCharacters.map((a, i) => (
          <div key={i}>{a.name}</div>
        ))}
        <br />
        <br />
        Would you like to continue?
      </span>
    );

    return await showConfirmationDialog({
      content: message,
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
  } else {
    return true;
  }
}
