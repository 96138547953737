/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getIdOrCodeOrIndex } from "@teselagen/ui";

import { standardizeVolume } from "../../../tg-iso-lims/src/utils/unitUtils";

export const validateDeadVolume = {
  requireAllOrNone: ["deadVolume", "deadVolumetricUnitCode"],
  tableWideValidation: ({ entities }) => {
    const toRet = {};
    entities.forEach(entity => {
      if (entity.deadVolume) {
        const standardizedMaxVolume = standardizeVolume(
          entity.maxVolume,
          entity.volumetricUnitCode
        );
        const standardizedDeadVolume = standardizeVolume(
          entity.deadVolume,
          entity.deadVolumetricUnitCode
        );
        if (standardizedDeadVolume >= standardizedMaxVolume) {
          toRet[`${getIdOrCodeOrIndex(entity)}:deadVolume`] =
            `Dead volume must be less than max volume`;
        }
      }
      if (entity.deadVolume && !entity.deadVolumetricUnitCode) {
        toRet[`${getIdOrCodeOrIndex(entity)}:deadVolumetricUnitCode`] =
          "Must provide a dead volume unit";
      }
    });
    return toRet;
  }
};
