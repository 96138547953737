/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Ref, useEffect, useRef, useState } from "react";
import { times, has, get, keyBy, union, pull, round } from "lodash";
import classNames from "classnames";
import {
  ContextMenu,
  ButtonGroup,
  Button,
  Switch,
  Tooltip,
  Colors,
  Menu
} from "@blueprintjs/core";

import CustomDragLayer from "./CustomDragLayer";
import "./style.css";
import Well from "./Well";
import SelectionLayer from "./SelectionLayer";
import determineBlackOrWhiteTextColor from "../../../src-shared/utils/determineBlackOrWhiteTextColor";
import { getAliquotContainerLocation } from "../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { calculateSelectedLocations } from "../../../src-shared/utils/calculateSelectedLocations";
import { rowIndexToLetter } from "../../../../tg-iso-lims/src/utils/rowIndexToLetter";

const SELECTION_DRAG_DISTANCE = 5;
export const DRY_COLOR = "#FFB366";
export const WET_COLOR = "#1585C5";
export const ADDITIVE_COLOR = "#9179F2";
const EMPTY_WET_COLOR = "#de6666";

const TEMPERATURE_ZONE_GAP = 15;
type ContainerArray = {
  id: string;
  name: string;
  __typename: "containerArray";
  containerArrayType?: ContainerArrayType;
  aliquotContainers: AliquotContainer[];
};

type AliquotContainer = {
  __typename: "aliquotContainer";
  rowPosition: number;
  columnPosition: number;
  tooltip?: string;
  id?: string;
  color?: string;
  additives?: any[];
  containerArray?: ContainerArray;
  volume?: number;
  volumetricUnitCode?: string;
  barcode: {
    barcodeString: string;
  };
  aliquot?: {
    __typename: "aliquot";
    id: string;
    [key: string]: any; // Other aliquot props
  };
};

type ContainerFormat = {
  code: string;
  rowCount: number;
  columnCount: number;
  is2DLabeled: boolean;
  name: string;
  __typename: "containerFormat";
};

type ContainerArrayType = {
  __typename: "containerArrayType";
  name: string;
  id: string;
  isPlate?: boolean;
  isColumn?: boolean;
  containerFormat: ContainerFormat;
};

type ContainerArraySpecs = {
  containerWidth: number;
  containerFormatSpecs: ContainerFormat;
  columnsPerZone: number | undefined;
  rowsPerZone: number | undefined;
  innerContainerHeight: number;
  innerContainerWidth: number;
  containerHeight: number;
  maxWidthOfWell: number;
  shrinkWellFactor: number;
  fontSize: number;
  columnCount: number;
  containerBottomPadding: number;
  containerRightPadding: number;
  rowCount: number;
};

type PlateLayer = {
  __typename: "plateLayer";
  id: string;
  plateLayerDefinition: {
    __typename: "plateLayerDefinition";
    id: string;
    name: string;
  };
  containerArrayId: string | null;
  plateZones: {
    __typename: "plateZone";
    id: string;
    plateZoneDefinition: {
      __typename: "plateZoneDefinition";
      id: string;
      name: string;
      color: string;
    };
    plateZoneWells: {
      __typename: "plateZoneWell";
      aliquotContainerId: string | null;
      plateMapItemId: string;
      id: string;
    }[];
  }[];
};

type PlateMapProps = {
  plateLayers?: PlateLayer[];
  temperatureZones?: number[];
  containerFormat?: ContainerFormat;
  containerFormatSpecs?: ContainerFormat;
  aliquotContainers: AliquotContainer[];
  /** Required if aliquotConatainers are set */
  containerArrayType?: ContainerArrayType;
  selectedAliquotContainerLocations?: string[];

  withDragSelect?: boolean;
  onWellsSelected?: (locations: string[], e: React.MouseEvent) => void;
  size?: number;
  temperatureZoneOrientation?: string;
  previewMode?: boolean;
  onAliquotContainerSelected?: (
    record: AliquotContainer,
    location: string
  ) => void;
  setSelectedAliquotContainerLocation?: (options: {
    location: string | null;
    shift?: boolean;
    ctrl?: boolean;
  }) => void;
  aliquotContainerDoubleClick?: (aliquotContainer: AliquotContainer) => void;
  aliquotContextMenu?: (
    aliquotContainer: AliquotContainer
  ) => React.JSX.Element;
  getContextMenuItems?: (
    options: { selectedRecords: AliquotContainer[] },
    location: string
  ) => React.JSX.Element[];
  activeFilledWells?: { [location: string]: string };
  measureRef?: Ref<HTMLDivElement>;
  noSelect?: boolean;
  isEditable?: boolean;
  onDrop?: (options: {
    sourceLocations: string[];
    draggedLocation: string;
    droppedLocation: string;
  }) => void;
  onSourceMaterialDrop?: (options: {
    selectedSourceMaterials: any;
    droppedLocation: string;
  }) => void;
  isWellDisabled?: (
    aliquotContainer: AliquotContainer,
    location: string
  ) => boolean;
  canDrag?: (props: any) => boolean;
  aliquotTooltip?: (
    aliquotContainer: AliquotContainer,
    location: string
  ) => string;
};

const PlateMapPlate = (props: PlateMapProps) => {
  const temperatureZoneOrientation =
    props.temperatureZoneOrientation || "vertical";

  const wellSVG = useRef<SVGSVGElement>(null);
  const [startingSelection, setStartingSelection] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStartCoordinates, setSelectionStartCoordinates] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const [selectionCurrentCoordinates, setSelectionCurrentCoordinates] =
    useState<{ x: number | undefined; y: number | undefined }>({
      x: undefined,
      y: undefined
    });
  const [locationsInSelection, setLocationsInSelection] = useState<string[]>(
    []
  );
  const [showLayers, setShowLayers] = useState(false);
  const [activePlateLayer, setActivePlateLayer] = useState(
    props.plateLayers && props.plateLayers[0]
  );

  useEffect(() => {
    if (activePlateLayer && !props.plateLayers?.includes(activePlateLayer)) {
      setActivePlateLayer(props.plateLayers![0]);
    }
  }, [activePlateLayer, props.plateLayers]);

  useEffect(() => {
    return () => {
      props.setSelectedAliquotContainerLocation &&
        props.setSelectedAliquotContainerLocation({ location: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setSelectedAliquotContainerLocation]);

  const getIs2DLabeled = () => {
    return (
      get(props.containerArrayType, "containerFormat.is2DLabeled") ||
      get(props.containerFormat, "is2DLabeled") ||
      false
    );
  };

  /**
   * Get information about the rendering of the container array.
   * This will contain things such as dimensions.
   */
  const getContainerArraySpecs = (): ContainerArraySpecs => {
    let { containerFormatSpecs } = props;

    if (!containerFormatSpecs) {
      if (!props.containerArrayType) {
        containerFormatSpecs = props.containerFormat!;
      } else {
        containerFormatSpecs = props.containerArrayType.containerFormat;
      }
    }

    let fontSize = 9;

    let shrinkWellFactor = 0.8;
    const { rowCount, columnCount } = containerFormatSpecs as any;
    const quadrantSize = rowCount * columnCount;
    let size = props.size || 400;

    if (quadrantSize === 1) {
      // reservoir special case
      fontSize = 16;
      size = 300;
    } else {
      if (columnCount <= 1) {
        size = 100;
      } else if (columnCount <= 2) {
        size = 120;
      } else if (columnCount <= 4) {
        size = 200;
      } else if (columnCount <= 8) {
        size = 300;
      } else if (columnCount <= 12) {
        size = 500;
      } else {
        size = 800;
        shrinkWellFactor = 0.7;
      }
    }

    const columnsPerZone =
      props.temperatureZones &&
      Math.ceil(columnCount / props.temperatureZones.length);
    const rowsPerZone =
      props.temperatureZones &&
      Math.ceil(rowCount / props.temperatureZones.length);

    let containerWidth = size;

    // const fullWellSize = containerWidth / columnCount;
    const is2DLabeled = getIs2DLabeled();
    let innerContainerWidth = containerWidth;
    if (is2DLabeled) {
      // add a little extra space for outer labels
      innerContainerWidth -= 45;
    }

    const maxWidthOfWell = innerContainerWidth / columnCount;
    let innerContainerHeight = maxWidthOfWell * rowCount;
    let containerHeight = innerContainerHeight;
    if (is2DLabeled) {
      // add a little extra space for outer labels
      containerHeight += 30;
    }

    let containerBottomPadding = 0;
    let containerRightPadding = 0;
    // must come after previous logic
    if (props.temperatureZones) {
      // because we are going to have dividing lines for each temperature zone
      // we need to provide space for that
      const temperatureZoneExtraSpace =
        (props.temperatureZones.length - 1) * TEMPERATURE_ZONE_GAP;
      if (temperatureZoneOrientation === "vertical") {
        containerWidth += temperatureZoneExtraSpace;
        innerContainerWidth += temperatureZoneExtraSpace;
        // add some height for zone labels
        if (is2DLabeled) {
          containerBottomPadding += 15;
        } else {
          containerBottomPadding += 25;
        }
      } else {
        containerHeight += temperatureZoneExtraSpace;
        innerContainerHeight += temperatureZoneExtraSpace;
        // add some height for zone labels
        if (is2DLabeled) {
          containerRightPadding += 20;
        } else {
          containerRightPadding += 30;
        }
      }
    }

    // inner container will be used for padding around wells
    return {
      containerWidth,
      containerFormatSpecs: containerFormatSpecs as any,
      columnsPerZone,
      rowsPerZone,
      innerContainerHeight,
      innerContainerWidth,
      containerHeight,
      maxWidthOfWell,
      shrinkWellFactor,
      fontSize,
      columnCount,
      containerBottomPadding,
      containerRightPadding,
      rowCount
    };
  };

  const getLocationsInSelection = (
    corner1: { x: number; y: number },
    corner2: { x: number; y: number }
  ) => {
    const { rowCount, columnCount } =
      props.containerFormat || props.containerArrayType!.containerFormat; // Should pass one or the other

    const containerArraySpecs = getContainerArraySpecs();

    const locations: string[] = [];
    for (let row = 0; row < rowCount; row++) {
      for (let column = 0; column < columnCount; column++) {
        if (
          isWellWithinSelection(
            row,
            column,
            corner1,
            corner2,
            containerArraySpecs,
            wellSVG.current!,
            getIs2DLabeled()
          )
        ) {
          locations.push(
            getAliquotContainerLocation(
              {
                rowPosition: row,
                columnPosition: column
              },
              {
                force2D: true
              }
            )
          );
        }
      }
    }
    return locations;
  };

  const handleShowPlateLayersChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowLayers(e.target.checked);
  };

  const getDistanceDragged = (currentCoordinates: { x: number; y: number }) => {
    const { x: x1, y: y1 } = selectionStartCoordinates;
    const { x: x2, y: y2 } = currentCoordinates;
    if ([x1, x2, y1, y2].includes(undefined)) {
      return 0;
    }

    return Math.sqrt((x2 - x1!) ** 2 + (y2 - y1!) ** 2);
  };

  /**
   * Given a click event, get the relative offset of
   * the event from the current target.
   */
  const getOffset = (e: React.MouseEvent) => {
    const { clientX, clientY } = e;
    return { x: clientX, y: clientY };
  };

  const handleBackgroundMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.persist();
    if (!props.withDragSelect) return;
    const { x, y } = getOffset(e);
    setStartingSelection(true);
    setIsSelecting(false);
    setSelectionStartCoordinates({ x, y });
    setSelectionCurrentCoordinates({ x, y });
  };

  const handleBackgoundMouseMove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.persist();
    if (!props.withDragSelect) return;

    if (!startingSelection) return;
    const locationsInSelection = getLocationsInSelection(
      selectionStartCoordinates as { x: number; y: number },
      getOffset(e)
    );
    const { x, y } = getOffset(e);
    setSelectionCurrentCoordinates({ x, y });
    setLocationsInSelection(locationsInSelection);

    if (
      !isSelecting &&
      getDistanceDragged({ x, y }) >= SELECTION_DRAG_DISTANCE
    ) {
      setIsSelecting(true);
    }
  };

  const cleanupDragSelect = () => {
    setIsSelecting(false);
    setStartingSelection(false);
    setSelectionStartCoordinates({ x: undefined, y: undefined });
    setSelectionCurrentCoordinates({ x: undefined, y: undefined });
    setLocationsInSelection([]);
  };
  const handleBackgroundMouseUp = (e: any) => {
    e.preventDefault();
    if (props.withDragSelect) {
      cleanupDragSelect();
    }
  };
  const handleBackgroundMouseLeave = () => {
    if (startingSelection && !isSelecting) {
      cleanupDragSelect();
    }
  };
  const handleSelectionLayerMouseUp = (e: React.MouseEvent) => {
    const { selectedAliquotContainerLocations = [] } = props;

    e.preventDefault();
    e.persist();

    if (!props.withDragSelect) return;

    cleanupDragSelect();

    if (!isSelecting) return;

    let locationsToSelect = getLocationsInSelection(
      selectionStartCoordinates as { x: number; y: number },
      getOffset(e)
    );

    if ((e.shiftKey || e.metaKey) && selectedAliquotContainerLocations.length) {
      const allLocationsAlreadySelected = locationsToSelect.every(location =>
        selectedAliquotContainerLocations.includes(location)
      );
      if (allLocationsAlreadySelected) {
        locationsToSelect = pull(
          selectedAliquotContainerLocations,
          ...locationsToSelect
        );
      } else {
        locationsToSelect = union(
          selectedAliquotContainerLocations,
          locationsToSelect
        );
      }
    }
    props.onWellsSelected && props.onWellsSelected(locationsToSelect, e);
  };

  const highlightSingleAliquotContainer = (
    record: AliquotContainer,
    e: React.MouseEvent = {} as React.MouseEvent
  ) => {
    const location = getAliquotContainerLocation(record);
    props.onAliquotContainerSelected &&
      props.onAliquotContainerSelected(record, location);
    const options = {
      location,
      shift: e.shiftKey,
      ctrl: e.ctrlKey || e.metaKey
    };
    if (props.onWellsSelected) {
      const { selectedAliquotContainerLocations: newLocations } =
        calculateSelectedLocations(
          {
            selectedAliquotContainerLocations:
              props.selectedAliquotContainerLocations || [],
            activeAliquotContainerLocation:
              props.selectedAliquotContainerLocations![
                props.selectedAliquotContainerLocations!.length - 1
              ] // last selected
          },
          options
        );

      props.onWellsSelected(newLocations, e);
    }
    if (props.setSelectedAliquotContainerLocation) {
      props.setSelectedAliquotContainerLocation(options);
    }
  };

  const handleWellClick =
    (aliquotContainer: AliquotContainer) => (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      highlightSingleAliquotContainer(aliquotContainer, e);
    };

  const handleWellDoubleClick =
    (aliquotContainer: AliquotContainer) => (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      props.aliquotContainerDoubleClick &&
        props.aliquotContainerDoubleClick(aliquotContainer);
    };

  const handleWellContextMenu =
    (
      aliquotContainer: AliquotContainer,
      selectedLocationsMap: { [location: string]: string }
    ) =>
    (e: React.MouseEvent) => {
      e.persist();
      e.preventDefault();
      e.stopPropagation();

      const location = getAliquotContainerLocation(aliquotContainer);
      const isSelected = !!selectedLocationsMap[location];
      // select first so that context menu can get proper selection
      if (!isSelected) {
        highlightSingleAliquotContainer(aliquotContainer);
      }
      setTimeout(() => {
        let menu;
        if (props.getContextMenuItems) {
          const menuItems = props.getContextMenuItems(
            { selectedRecords: [aliquotContainer] },
            location
          );
          if (menuItems && menuItems.length) {
            menu = <Menu>{menuItems}</Menu>;
          }
        } else if (props.aliquotContextMenu) {
          menu = props.aliquotContextMenu(aliquotContainer);
        }
        if (menu) {
          ContextMenu.show(menu, {
            left: e.clientX,
            top: e.clientY
          });
        }
      }, 0);
    };

  const getWellClickHandlers = ({
    aliquotContainer,
    isDisabled,
    selectedLocationsMap
  }: {
    aliquotContainer: AliquotContainer;
    isDisabled: boolean;
    selectedLocationsMap: { [location: string]: string };
  }) => {
    let onClick: ReturnType<typeof handleWellClick> | undefined,
      onDoubleClick: ReturnType<typeof handleWellDoubleClick> | undefined,
      onContextMenu: ReturnType<typeof handleWellContextMenu> | undefined;
    if (!props.previewMode && !isDisabled) {
      onClick = handleWellClick(aliquotContainer);
      onDoubleClick = handleWellDoubleClick(aliquotContainer);
      onContextMenu = handleWellContextMenu(
        aliquotContainer,
        selectedLocationsMap
      );
    }
    return {
      onClick,
      onDoubleClick,
      onContextMenu
    };
  };

  const getInnerContainerOffset = ({
    containerArraySpecs,
    is2DLabeled
  }: {
    containerArraySpecs: ContainerArraySpecs;
    is2DLabeled: boolean;
  }) => {
    // for 2D labeled view we shift the wells to the right and down slightly to make space for the labels on left and right (A, B, C. 1, 2, 3 etc.)
    const {
      containerWidth,
      containerHeight,
      innerContainerHeight,
      innerContainerWidth
    } = containerArraySpecs;
    let x = (containerWidth - innerContainerWidth) / 2;
    let y = (containerHeight - innerContainerHeight) / 2;
    const unevenShiftForLabels = { x: 3, y: 8 };

    if (is2DLabeled) {
      // because the labels show up inside the grey box on plates, shift the wells so that the labels will have space
      x += unevenShiftForLabels.x;
      y += unevenShiftForLabels.y;
    }
    return { x, y };
  };

  /** ---- RENDER --- */

  const containerArraySpecs = getContainerArraySpecs();
  if (!containerArraySpecs) {
    return <div>This plate format cannot be previewed.</div>;
  }

  const {
    containerWidth,
    containerHeight,
    columnsPerZone,
    rowsPerZone,
    maxWidthOfWell,
    columnCount,
    rowCount,
    shrinkWellFactor,
    fontSize,
    containerBottomPadding,
    containerRightPadding
  } = containerArraySpecs;

  const is2DLabeled = getIs2DLabeled();
  let aliquotContainers = props.aliquotContainers;

  const isRack =
    (props.containerArrayType?.id && !props.containerArrayType?.isPlate) ||
    (props.containerArrayType?.isPlate !== undefined &&
      !props.containerArrayType?.isPlate);

  const EMPTY_WELL_COLOR = "#E1E8ED";

  // Make a map to enable constant time checking of whether a location is selected.
  const selectedLocationsMap = keyBy(
    props.selectedAliquotContainerLocations,
    x => x
  );

  const selectedItemsProps: { [key: string]: any } = {};

  let layerComponent;
  if (props.plateLayers?.length) {
    layerComponent = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {showLayers && (
          <ButtonGroup vertical>
            {props.plateLayers?.map(layer => {
              return (
                <Button
                  key={layer.id}
                  text={layer.plateLayerDefinition.name}
                  active={activePlateLayer === layer}
                  onClick={() => {
                    setActivePlateLayer(layer);
                  }}
                />
              );
            })}
          </ButtonGroup>
        )}
        {showLayers && activePlateLayer && (
          <div style={{ marginTop: 15, width: 200 }}>
            {activePlateLayer.plateZones.map(plateZone => {
              const def = plateZone.plateZoneDefinition;
              return (
                <Tooltip key={plateZone.id} content={def.name}>
                  <div
                    style={{
                      padding: 5,
                      marginLeft: 5,
                      width: 200,
                      background: def.color,
                      color: determineBlackOrWhiteTextColor(def.color),
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      overflow: "hidden"
                    }}
                  >
                    {def.name}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    );
    if (showLayers && activePlateLayer) {
      const idToColorMap = activePlateLayer.plateZones.reduce(
        (acc, zone) => {
          if (zone.plateZoneWells) {
            zone.plateZoneWells.forEach(well => {
              acc[(well.aliquotContainerId || well.plateMapItemId) as string] =
                zone.plateZoneDefinition.color;
            });
          }
          return acc;
        },
        {} as { [id: string]: string }
      );
      aliquotContainers = aliquotContainers.map(ac => {
        if (ac.id && idToColorMap[ac.id]) {
          return {
            ...ac,
            color: idToColorMap[ac.id]
          };
        } else {
          return {
            ...ac,
            color: EMPTY_WELL_COLOR
          };
        }
      });
    }
  }

  let dragSelectBackgroundSvgProps;
  let dragSelectOuterContainerProps;
  if (props.withDragSelect) {
    dragSelectBackgroundSvgProps = {
      ref: wellSVG
    };
    dragSelectOuterContainerProps = {
      onMouseDown: handleBackgroundMouseDown,
      onMouseMove: handleBackgoundMouseMove,
      onMouseUp: handleBackgroundMouseUp,
      onMouseLeave: handleBackgroundMouseLeave
    };
  }

  // this would completely fill all available space
  // and wells would be touching
  const fullRadius = maxWidthOfWell / 2;
  // shrink each well so that there is space in between
  const radius = fullRadius * shrinkWellFactor;

  // because we have two containers (one for the grey box, one for the plate layout)
  // this will be used to center the inner container inside the large one
  const { x: offsetInnerContainerX, y: offsetInnerContainerY } =
    getInnerContainerOffset({
      containerArraySpecs,
      is2DLabeled
    });

  const labelGap = 10;

  const getXZoneOffset = (columnPosition: number) => {
    // when using temperature zones each set of wells and zone must be offset by the extra space needed for the zones that precede it
    let zoneOffset = 0;
    if (temperatureZoneOrientation === "vertical" && columnsPerZone) {
      const activeZoneIndex = Math.floor(columnPosition / columnsPerZone);
      // offset each x by the previous zones to make space for the zone borders
      zoneOffset = activeZoneIndex * TEMPERATURE_ZONE_GAP;
    }
    return zoneOffset;
  };

  const getYZoneOffset = (rowPosition: number) => {
    // when using temperature zones each set of wells and zone must be offset by the extra space needed for the zones that precede it
    let zoneOffset = 0;
    if (temperatureZoneOrientation === "horizontal" && rowsPerZone) {
      const activeZoneIndex = Math.floor(rowPosition / rowsPerZone);
      // offset each y by the previous zones to make space for the zone borders
      zoneOffset = activeZoneIndex * TEMPERATURE_ZONE_GAP;
    }

    return zoneOffset;
  };

  const getXAndYofWell = (columnPosition: number, rowPosition: number) => {
    const xZoneOffset = getXZoneOffset(columnPosition);
    const yZoneOffset = getYZoneOffset(rowPosition);

    const x = columnPosition * maxWidthOfWell + xZoneOffset;
    const y = rowPosition * maxWidthOfWell + yZoneOffset;
    return { x, y };
  };

  // padding will be added to the bottom to make space for zone labels
  const containerHeightWithPadding = containerHeight + containerBottomPadding;
  const containerWidthWithPadding = containerWidth + containerRightPadding;

  return (
    <div
      className="tg-plate-view"
      style={{
        display: "flex",
        flexDirection: "column"
        // alignItems: "flex-end" //tnr: commenting this out because it looks bad on small screens when the plate gets flex wrapped to the next row
      }}
    >
      {!!props.plateLayers?.length && (
        <Switch
          checked={showLayers}
          label="Show Plate Layers"
          onChange={handleShowPlateLayersChange}
        />
      )}
      <div style={{ display: "flex" }}>
        {containerWidthWithPadding && layerComponent}
        <div style={{ width: 20 }} />
        <div ref={props.measureRef} style={{ flex: 1, maxWidth: "100%" }}>
          {containerWidthWithPadding && (
            <svg
              width={containerWidthWithPadding}
              height={containerHeightWithPadding}
              {...dragSelectOuterContainerProps}
            >
              <rect
                width={containerWidthWithPadding}
                height={containerHeightWithPadding}
                style={{
                  fill: is2DLabeled ? Colors.GRAY2 : Colors.GRAY3
                }}
                rx={!is2DLabeled ? 0 : 15}
                ry={!is2DLabeled ? 0 : 15}
              />
              <g
                width={containerWidthWithPadding}
                height={containerHeight}
                transform={`translate(${offsetInnerContainerX}, ${offsetInnerContainerY})`}
                {...dragSelectBackgroundSvgProps}
              >
                <g data-help="wells">
                  {aliquotContainers.map(aliquotContainer => {
                    const { rowPosition, columnPosition, tooltip } =
                      aliquotContainer;
                    const columnIndex = columnPosition + 1;
                    const rowIndex = rowPosition + 1;

                    const location =
                      getAliquotContainerLocation(aliquotContainer);
                    const isSelected = !!selectedLocationsMap[location];
                    // const isSecondarySelected = secondarySelections[location];
                    const isActiveFilled =
                      props.activeFilledWells &&
                      props.activeFilledWells[location];
                    const isDisabled = props.isWellDisabled
                      ? props.isWellDisabled(aliquotContainer, location)
                      : false;

                    // gets x and y of well including offset for temperature zones
                    const { x, y } = getXAndYofWell(
                      columnPosition,
                      rowPosition
                    );
                    const key = columnIndex + "-" + rowIndex;
                    // cx is the x of the center of the circle
                    // so shift x by radius so that the well is centered in its "space"
                    const cx = x + fullRadius;

                    // get the color of the well based on if it is dry, wet, empty, or overridden
                    const wellFill = getWellFill(
                      aliquotContainer,
                      EMPTY_WELL_COLOR
                    );

                    const sharedProps = {
                      id: aliquotContainer.id,
                      className: classNames(
                        "plate-well",
                        "well-location-" + location,
                        {
                          "is-selected-well": isSelected,
                          "is-disabled": isDisabled,
                          // "is-secondary-selected-well": isSecondarySelected,
                          "is-active-filled-well": !!isActiveFilled,
                          "no-select": props.noSelect || props.previewMode,
                          "is-dry": wellFill === DRY_COLOR,
                          "is-wet": wellFill === WET_COLOR,
                          "is-empty-tube": isRack && !!aliquotContainer.id,
                          "is-empty": wellFill === EMPTY_WELL_COLOR,
                          "is-in-selection":
                            locationsInSelection.includes(location)
                        }
                      ),
                      cy: y + fullRadius,
                      cx,
                      r: radius,
                      width: maxWidthOfWell,
                      height: maxWidthOfWell,
                      x: x,
                      y: y,
                      fill: wellFill
                    };
                    const clickHandlers = getWellClickHandlers({
                      aliquotContainer,
                      isDisabled,
                      selectedLocationsMap
                    });

                    if (isSelected) {
                      selectedItemsProps[location] = sharedProps;
                    }
                    return (
                      <Well
                        key={key}
                        {...{
                          sharedProps,
                          isDisabled,
                          canDrag: props.canDrag,
                          previewMode: props.previewMode,
                          clickHandlers,
                          isSelected,
                          location,
                          rectWidth: radius * 2,
                          rectHeight: radius * 2,
                          isEditable: props.isEditable,
                          fontSize,
                          selectedItemsProps,
                          r: radius,
                          tooltip:
                            tooltip ||
                            (props.aliquotTooltip &&
                              props.aliquotTooltip(aliquotContainer, location)),
                          onDrop: props.onDrop,
                          onSourceMaterialDrop: props.onSourceMaterialDrop,
                          is2DLabeled,
                          aliquotContainer,
                          rowCount,
                          columnCount
                        }}
                      />
                    );
                  })}
                </g>
                <g data-help="2D-labels">
                  {is2DLabeled &&
                    times(columnCount, function (xindex) {
                      const { x } = getXAndYofWell(xindex, 0);
                      const columnIndex = xindex + 1;
                      return (
                        <text
                          className="no-select"
                          key={"columnHeader" + xindex}
                          x={x + fullRadius}
                          y={-labelGap}
                          fill="white"
                          textAnchor="middle"
                          alignmentBaseline="central"
                          style={{
                            fontSize
                          }}
                        >
                          {columnIndex}
                        </text>
                      );
                    })}
                  {is2DLabeled &&
                    times(rowCount, function (yindex) {
                      let { y } = getXAndYofWell(0, yindex);
                      // this is starting from top so need to move down one
                      y += maxWidthOfWell;
                      return (
                        <text
                          className="no-select"
                          key={"rowHeader" + yindex}
                          y={y - fullRadius}
                          x={-labelGap}
                          fill="white"
                          textAnchor="middle"
                          alignmentBaseline="central"
                          style={{
                            fontSize
                          }}
                        >
                          {rowIndexToLetter(yindex)}
                        </text>
                      );
                    })}
                </g>
                <g data-help="temp-zones">
                  {props.temperatureZones &&
                    props.temperatureZones.map((zone, i) => {
                      const safeColumnsPerZone = columnsPerZone as number; // if temperature zones are used, this will be defined
                      const safeRowsPerZone = rowsPerZone as number; // if temperature zones are used, this will be defined
                      // ex 96 well has 12 columns. With 6 temp zones we have
                      // columnsPerZone 2 (12 / 6)
                      // columnPosition when i = 1 is 4
                      // (first zone line comes after columns)
                      const columnPosition =
                        safeColumnsPerZone * i + safeColumnsPerZone;
                      const rowPosition = safeRowsPerZone * i + safeRowsPerZone;
                      const widthOfWellsInZone =
                        maxWidthOfWell * safeColumnsPerZone;
                      const heightOfWellsInZone =
                        maxWidthOfWell * safeRowsPerZone;
                      // first x is the direct position if the wells were right next to each other
                      let x =
                        widthOfWellsInZone *
                        (columnPosition / safeColumnsPerZone);
                      let y =
                        heightOfWellsInZone * (rowPosition / safeRowsPerZone);
                      // now account for the zone gaps that are added in between wells
                      const xZoneOffset = getXZoneOffset(columnPosition);
                      const yZoneOffset = getYZoneOffset(rowPosition);
                      x += xZoneOffset;
                      y += yZoneOffset;
                      // recenter to middle of zone
                      x -= TEMPERATURE_ZONE_GAP / 2;
                      y -= TEMPERATURE_ZONE_GAP / 2;
                      const fullZoneWidth =
                        widthOfWellsInZone + TEMPERATURE_ZONE_GAP;
                      const fullZoneHeight =
                        heightOfWellsInZone + TEMPERATURE_ZONE_GAP;
                      // the text must be centered in the whole zone width
                      let textY, textX, y1, y2, x1, x2;
                      if (temperatureZoneOrientation === "vertical") {
                        textX = x - fullZoneWidth / 2;
                        const yToBottomOfWells = maxWidthOfWell * rowCount;
                        textY = yToBottomOfWells + containerBottomPadding / 2;
                        if (is2DLabeled) {
                          // because the wells on 2dLabeled plates do not fill there "square"
                          // need to adjust slightly to center the text in the bottom padding
                          textY += 3;
                        }
                        x1 = x;
                        x2 = x;
                        y1 = 0 - offsetInnerContainerY;
                        y2 = containerHeightWithPadding - offsetInnerContainerY;
                      } else {
                        textY = y - fullZoneHeight / 2;
                        const xToRightOfWells = maxWidthOfWell * columnCount;
                        const textXOffset = is2DLabeled
                          ? containerRightPadding / 1.5
                          : containerRightPadding / 2;
                        textX = xToRightOfWells + textXOffset;
                        if (is2DLabeled) {
                          textX += 3;
                        }
                        y1 = y;
                        y2 = y;
                        x1 = 0 - offsetInnerContainerX;
                        x2 = containerWidthWithPadding - offsetInnerContainerX;
                      }

                      return (
                        <React.Fragment key={i}>
                          {i !== props.temperatureZones!.length - 1 && (
                            <line
                              className="temperature-zone-divider"
                              x1={x1}
                              y1={y1}
                              x2={x2}
                              y2={y2}
                              stroke="#F5F8FA"
                              strokeWidth={2}
                            />
                          )}
                          <text
                            className="no-select"
                            x={textX}
                            y={textY}
                            fill="white"
                            textAnchor="middle"
                            alignmentBaseline="central"
                            style={{
                              fontSize
                            }}
                          >
                            {round(zone, 1)}°
                          </text>
                        </React.Fragment>
                      );
                    })}
                </g>
              </g>
              {!!isSelecting && (
                <SelectionLayer
                  corner1={selectionCurrentCoordinates}
                  corner2={selectionStartCoordinates}
                  onMouseMove={handleBackgoundMouseMove}
                  onMouseUp={handleSelectionLayerMouseUp}
                />
              )}
            </svg>
          )}
        </div>
        {!!props.isEditable && (
          <CustomDragLayer
            fullRadius={fullRadius}
            is2DLabeled={is2DLabeled}
            getXAndYofWell={getXAndYofWell}
          />
        )}
      </div>
    </div>
  );
};

export const PlateMapPlateNoContext = PlateMapPlate;

export default PlateMapPlate;

/**
 * See if the well at the given position lies within the selection.
 *
 * @param {int} rowIndex The row index of the well.
 * @param {int} columnIndex The column index of the well.
 * @param {Object} corner1 One of the corners of the selection. Has x and y coordinates relative to plate background.
 * @param {Object} corner2 One of the corners of the selection. Has x and y coordinates relative to plate background.
 * @param {*} containerArraySpecs
 */
function isWellWithinSelection(
  rowIndex: number,
  columnIndex: number,
  corner1: { x: number; y: number },
  corner2: { x: number; y: number },
  containerArraySpecs: ContainerArraySpecs,
  wellSVG: SVGSVGElement,
  is2DLabeled: boolean
) {
  const bb = wellSVG.getBoundingClientRect();

  const c1 = corner1;
  const c2 = corner2;
  const { maxWidthOfWell } = containerArraySpecs;
  // these offsets are needed to account for the adjust for labels

  let x = bb.left + maxWidthOfWell * (columnIndex + 1);
  let y = bb.top + maxWidthOfWell * (rowIndex + 1);

  // select wells halfway for boxes
  const halfWell = maxWidthOfWell / 2;
  if (!is2DLabeled) {
    x -= halfWell;
    y -= halfWell;
  }
  return (
    Math.min(c1.x, c2.x) <= x &&
    x <= Math.max(c1.x, c2.x) &&
    Math.min(c1.y, c2.y) <= y &&
    y <= Math.max(c1.y, c2.y)
  );
}

function getWellFill(
  aliquotContainer: AliquotContainer,
  EMPTY_WELL_COLOR: string
): string {
  const { additives = [] } = aliquotContainer;
  const isDry = get(aliquotContainer, "aliquot.isDry", false);
  const aliquotColorOverride =
    get(aliquotContainer, "aliquot.color") || aliquotContainer.color;

  // isEmpty is true if there is no aliquot or if the container doesn't have an id
  const isEmpty = has(aliquotContainer, "aliquot")
    ? !aliquotContainer.aliquot
    : // @ts-ignore
      !aliquotContainer.id && aliquotContainer.id !== 0; // TODO: CHeck this
  const isDrained =
    aliquotContainer.aliquot &&
    !aliquotContainer.aliquot.isDry &&
    aliquotContainer.aliquot.volume <= 0;
  let wellFill;
  if (aliquotColorOverride) {
    wellFill = aliquotColorOverride;
  } else if (isDrained) {
    wellFill = EMPTY_WET_COLOR;
  } else if (isEmpty) {
    wellFill = additives.length ? ADDITIVE_COLOR : EMPTY_WELL_COLOR;
  } else if (isDry) {
    wellFill = DRY_COLOR;
  } else {
    if (
      !aliquotContainer.aliquot &&
      (get(aliquotContainer, "inventoryItem.additiveMaterial") ||
        get(aliquotContainer, "inventoryItem.lot"))
    ) {
      wellFill = ADDITIVE_COLOR;
    } else {
      wellFill = isDry ? DRY_COLOR : WET_COLOR;
    }
  }
  return wellFill;
}
