/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
import gql from "graphql-tag";
import { get, groupBy, keyBy } from "lodash";
import React from "react";
import shortid from "shortid";
import { CheckboxField, FileUploadField, InputField } from "@teselagen/ui";
import { generateBarcodeRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { encodeFilesForRequest } from "../../../../../tg-iso-shared/src/utils/fileUtils";

import isValidPositiveNumber from "../../../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import { safeQuery } from "../../../../src-shared/apolloMethods";

import GenericSelect from "../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { cleanPlateForIntegrationRequest } from "../../../utils/plateUtils";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import PlateUploadFields from "../../PlateUploadFields";
import UnitInputField from "../../UnitInputField";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateEmptyWells,
  getAliquotContainerSource,
  getPositionFromAlphanumericLocation,
  wellInBounds
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import { uniq } from "lodash";
import reactionMapFragment from "../../../graphql/fragments/reactionMapFragment";
import fieldConstants from "./fieldConstants";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import containerArrayTypeFragment from "../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";

// const headers = fields.map(field => field.path);

export const integrationFragment = [
  "integration",
  /* GraphQL */ `
    {
      id
      name
    }
  `
];

const createCustomWorklistAliquotContainerFragment = gql`
  fragment createCustomWorklistAliquotContainerFragment on aliquotContainer {
    id
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainerTypeCode
    columnPosition
    rowPosition
    additives {
      id
      volume
      volumetricUnitCode
    }
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      mass
      massUnitCode
    }
  }
`;

const createCustomWorklistPlateFragment = gql`
  fragment createCustomWorklistPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
      }
    }
    aliquotContainers {
      ...createCustomWorklistAliquotContainerFragment
    }
  }
  ${createCustomWorklistAliquotContainerFragment}
`;

const createWorklists = async ({ values, change }) => {
  try {
    const { worklistCsvFiles } = values;

    const allRows = [];
    for (const csvFile of worklistCsvFiles) {
      const worklistKey = shortid();
      csvFile.parsedData.forEach((row, i) => {
        row.filename = csvFile.name;
        row.worklistKey = worklistKey;
        row.index = i + 1;
        allRows.push(row);
      });
    }

    const errors = [];
    const maybeHandleError = () => {
      if (errors.length) {
        throw {
          validationError: errors.join("\n")
        };
      }
    };

    const tubeBarcodes = [];
    const plateBarcodes = [];

    allRows.forEach(row => {
      const {
        "source plate barcode": sourcePlateBarcode,
        "source tube barcode": sourceTubeBarcode,
        "destination plate barcode": destPlateBarcode,
        "destination tube barcode": destTubeBarcode
      } = row;
      sourceTubeBarcode && tubeBarcodes.push(sourceTubeBarcode);
      sourcePlateBarcode && plateBarcodes.push(sourcePlateBarcode);
      destTubeBarcode && tubeBarcodes.push(destTubeBarcode);
      destPlateBarcode && plateBarcodes.push(destPlateBarcode);
    });

    maybeHandleError();

    let keyedTubes = {};
    let keyedPlates = {};
    if (tubeBarcodes.length) {
      const tubes = await safeQuery(
        createCustomWorklistAliquotContainerFragment,
        {
          variables: {
            filter: {
              "barcode.barcodeString": tubeBarcodes,
              "aliquotContainerType.isTube": true
            }
          }
        }
      );
      keyedTubes = keyBy(tubes, "barcode.barcodeString");
    }

    const plateToAliquotContainer = {};
    if (plateBarcodes.length) {
      const plates = await safeQuery(createCustomWorklistPlateFragment, {
        variables: {
          filter: {
            "barcode.barcodeString": plateBarcodes
          }
        }
      });
      const groupedPlates = groupBy(plates, "barcode.barcodeString");
      const dupBarcodes = [];
      Object.keys(groupedPlates).forEach(barcode => {
        if (groupedPlates[barcode].length > 1) {
          dupBarcodes.push(barcode);
        }
      });
      if (dupBarcodes.length) {
        throw {
          validationError: `Multiple plates found with these barcodes: ${dupBarcodes.join(
            ", "
          )}`
        };
      }
      keyedPlates = keyBy(plates, "barcode.barcodeString");
      plates.forEach(p => {
        plateToAliquotContainer[p.id] = {};
        p.aliquotContainers.forEach(ac => {
          const location = getAliquotContainerLocation(ac, {
            force2D: true
          });
          plateToAliquotContainer[p.id][location] = ac;
        });
      });
    }

    const allTransfers = {};

    allRows.forEach(row => {
      const {
        "source plate barcode": sourcePlateBarcode,
        "source tube barcode": sourceTubeBarcode,
        "source well": sourceWell,
        "destination plate barcode": destPlateBarcode,
        "destination tube barcode": destTubeBarcode,
        "destination well": destWell,
        "transfer volume": transferVolume,
        "transfer volume unit": transferUnit,
        worklistKey
      } = row;
      const addError = msg => {
        errors.push(`${row.filename} row ${row.index} ${msg}`);
      };
      const sourcePlate = keyedPlates[sourcePlateBarcode];
      const sourceTube = keyedTubes[sourceTubeBarcode];
      const destPlate = keyedPlates[destPlateBarcode];
      const destTube = keyedTubes[destTubeBarcode];
      if (sourcePlateBarcode && !sourcePlate) {
        return addError(`no plate found with barcode ${sourcePlateBarcode}`);
      }
      if (sourceTubeBarcode && !sourceTube) {
        return addError(`no tube found with barcode ${sourceTubeBarcode}`);
      }
      if (destPlateBarcode && !destPlate) {
        return addError(`no plate found with barcode ${destPlateBarcode}`);
      }
      if (destTubeBarcode && !destTube) {
        return addError(`no tube found with barcode ${destTubeBarcode}`);
      }
      if (!isValidPositiveNumber(transferVolume)) {
        return addError(`has invalid transfer volume`);
      }
      if (!transferUnit || !unitGlobals.volumetricUnits[transferUnit]) {
        return addError("has invalid volume unit");
      }

      // getAliquotContainerLocation
      const transfer = {
        volume: transferVolume,
        volumetricUnitCode: transferUnit
      };

      const getSourceOrDestinationAliquotContainerId = ({
        tube,
        plate,
        well,
        msg,
        key
      }) => {
        if (tube) {
          transfer[key] = tube.id;
          transfer[key.replace("Id", "")] = tube;
        } else {
          const containerFormat = plate.containerArrayType.containerFormat;
          const sourcePosition = getPositionFromAlphanumericLocation(
            well,
            containerFormat
          );
          if (!wellInBounds(sourcePosition, containerFormat)) {
            return addError(`${msg} well does not fit in plate`);
          }
          const location = getAliquotContainerLocation(sourcePosition, {
            force2D: true
          });
          const aliquotContainer = plateToAliquotContainer[plate.id][location];
          if (!aliquotContainer) {
            return addError(`error finding aliquot container on ${msg} plate`);
          }
          if (
            key === "sourceAliquotContainerId" &&
            !getAliquotContainerSource(aliquotContainer)
          ) {
            return addError(`no aliquot or additives at source location`);
          }
          transfer[key.replace("Id", "")] = {
            ...aliquotContainer,
            containerArray: plate && {
              id: plate.id,
              name: plate.name,
              __typename: plate.__typename
            }
          };
          transfer[key] = aliquotContainer.id;
        }
      };

      getSourceOrDestinationAliquotContainerId({
        tube: sourceTube,
        plate: sourcePlate,
        well: sourceWell,
        msg: "source",
        key: "sourceAliquotContainerId"
      });

      getSourceOrDestinationAliquotContainerId({
        tube: destTube,
        plate: destPlate,
        well: destWell,
        msg: "destination",
        key: "destinationAliquotContainerId"
      });

      if (!allTransfers[worklistKey]) {
        allTransfers[worklistKey] = [];
      }
      allTransfers[worklistKey].push(transfer);
    });

    maybeHandleError();
    change("allTransfers", allTransfers);
  } catch (error) {
    console.error("err:", error);
    throwFormError(error.validationError || "Error creating worklist.");
  }
};

function getKeyedPlate(plate) {
  const keyed = {};
  plate.aliquotContainers.forEach(ac => {
    keyed[getAliquotContainerLocation({ ...ac, containerArray: plate })] = ac;
  });
  keyed.plateInfo = {
    id: plate.id,
    name: plate.name,
    barcode: plate.barcode,
    __typename: plate.__typename
  };
  return keyed;
}

async function handleIntegration({ values, change }) {
  try {
    const {
      newDestination,
      containerArrayType,
      platePrefix,
      integration: _integration,
      sourcePlates = [],
      destinationPlates = [],
      integrationFiles = [],
      useUniversalVolume,
      universalTransferVolume,
      universalTransferVolumeUnitCode,
      aliquotContainerType,
      useIntegrationSourcePlates,
      useIntegrationDestinationPlates,
      reactionMap
    } = values;
    const integrationData = {};

    integrationData.sourcePlates = sourcePlates.map(
      cleanPlateForIntegrationRequest
    );
    if (reactionMap) {
      integrationData.reactionMap = await safeQuery(reactionMapFragment, {
        variables: {
          id: reactionMap.id
        }
      });
    }
    if (!newDestination) {
      integrationData.destinationPlates = destinationPlates.map(
        cleanPlateForIntegrationRequest
      );
    } else {
      integrationData.plateNamePrefix = platePrefix;
      integrationData.newPlateType = {
        name: containerArrayType.name,
        rowCount: containerArrayType.containerFormat.rowCount,
        columnCount: containerArrayType.containerFormat.columnCount
      };
    }
    const integration = await safeQuery(
      ["integration", "id subtype integrationEndpoints {id}"],
      {
        variables: {
          id: _integration.id
        }
      }
    );
    const endpointId = get(integration, "integrationEndpoints[0].id");
    if (!endpointId) {
      throw {
        validationError:
          "No integrationEndpoint ID found for integration. Something must have gotten corrupted. Please check the integration settings with an admin"
      };
    }
    const encodedFiles = await encodeFilesForRequest(integrationFiles);

    integrationData.encodedFiles = encodedFiles;
    const { data: result } = await window.triggerIntegrationRequest({
      endpointId,
      data: integrationData,
      method: "POST"
    });

    const keyedPlates = {};
    const keyPlate = p => {
      keyedPlates[p.id] = getKeyedPlate(p);
    };

    if (!useIntegrationSourcePlates) {
      sourcePlates.forEach(keyPlate);
    }
    if (!useIntegrationDestinationPlates) {
      destinationPlates.forEach(keyPlate);
    }

    const plateIdsFromIntegration = [];
    result.transfers.forEach(t => {
      if (useIntegrationSourcePlates) {
        if (t.sourcePlateId) {
          plateIdsFromIntegration.push(t.sourcePlateId);
        }
      }
      if (useIntegrationDestinationPlates) {
        if (t.destinationPlateId) {
          plateIdsFromIntegration.push(t.destinationPlateId);
        }
      }
    });
    if (plateIdsFromIntegration.length) {
      const plates = await safeQuery(containerArrayPlatePreviewFragment, {
        variables: {
          filter: {
            id: uniq(plateIdsFromIntegration)
          }
        }
      });
      plates.forEach(keyPlate);
    }

    const plateTypeIds = [];
    result.transfers.forEach(t => {
      if (t.destinationPlateTypeId) {
        plateTypeIds.push(t.destinationPlateTypeId);
      }
    });

    const keyedPlateTypes = keyBy(
      plateTypeIds.length
        ? await safeQuery(containerArrayTypeFragment, {
            variables: {
              filter: {
                id: plateTypeIds
              }
            }
          })
        : [],
      "id"
    );

    const newPlateMap = {};
    const worklistKey = shortid();
    const allTransfers = {
      [worklistKey]: result.transfers.map((t, i) => {
        const {
          transferVolume = 0,
          transferVolumeUnit: unit,
          sourcePlateId,
          sourceWell = "",
          destinationPlateId: dstPlateId,
          destinationPlateName: dstPlateName,
          destinationPlateBarcode: dstPlateBarcode,
          destinationPlateTypeId: dstPlateTypeId,
          destinationWell: dstWell
        } = t;

        const transfer = {};
        const keyedPlate = keyedPlates[sourcePlateId];
        const sourceAliquotContainer = keyedPlate && {
          ...keyedPlate[sourceWell.toUpperCase()],
          containerArray: keyedPlate.plateInfo
        };

        if (!sourceAliquotContainer) {
          throw {
            validationError: `No source found for transfer ${i + 1}`
          };
        }

        if (dstPlateId) {
          const keyedPlate = keyedPlates[dstPlateId];
          transfer.destinationAliquotContainer = keyedPlate && {
            ...keyedPlate[dstWell.toUpperCase()],
            containerArray: keyedPlate.plateInfo
          };
          if (!transfer.destinationAliquotContainer) {
            throw {
              validationError: `No destination found for transfer ${i + 1}.`
            };
          } else {
            transfer.destinationAliquotContainerId =
              transfer.destinationAliquotContainer.id;
          }
        } else {
          // new destination plates
          if (!dstPlateName) {
            throw {
              validationError: `Must specify a destination plate name when creating new plates.`
            };
          }
          const plateTypeToUse = dstPlateTypeId
            ? keyedPlateTypes[dstPlateTypeId]
            : containerArrayType;

          const tubeTypeToUse = aliquotContainerType
            ? aliquotContainerType
            : plateTypeToUse.nestableTubeTypes?.[0]?.aliquotContainerType;

          if (dstPlateTypeId && !plateTypeToUse) {
            throw {
              validationError: `Destination on transfer ${i +
                1} passed invalid destinationPlateTypeId ${dstPlateTypeId}.`
            };
          }
          if (!wellInBounds(dstWell, plateTypeToUse.containerFormat)) {
            throw {
              validationError: `Destination well on transfer ${i +
                1} does not fit on the selected plate format.`
            };
          } else {
            // start building up new plates
            // create empty new plate
            // assign cids to all aliquot containers
            // use that for destination aliquot container id
            const position = getPositionFromAlphanumericLocation(
              dstWell,
              plateTypeToUse.containerFormat
            );
            const location2d = getAliquotContainerLocation(position, {
              force2D: true
            });
            if (!newPlateMap[dstPlateName]) {
              let aliquotContainers = [];
              if (plateTypeToUse.isPlate) {
                aliquotContainers = generateEmptyWells(
                  plateTypeToUse.containerFormat,
                  {
                    aliquotContainerTypeCode:
                      plateTypeToUse.aliquotContainerTypeCode
                  }
                ).map(ac => {
                  ac.cid = shortid();
                  return ac;
                });
              }
              const keyedAliquotContainers = aliquotContainers.reduce(
                (acc, ac) => {
                  acc[getAliquotContainerLocation(ac, { force2D: true })] = {
                    ...ac,
                    containerArray: {
                      name: dstPlateName
                    }
                  };
                  return acc;
                },
                {}
              );
              newPlateMap[dstPlateName] = {
                cid: shortid(),
                name: dstPlateName,
                ...(dstPlateBarcode && {
                  barcode: {
                    barcodeString: dstPlateBarcode
                  }
                }),
                containerArrayType: plateTypeToUse,
                containerArrayTypeId: plateTypeToUse.id,
                aliquotContainers,
                keyedAliquotContainers
              };
            }

            const keyedAcs = newPlateMap[dstPlateName].keyedAliquotContainers;
            let destinationAc;
            if (keyedAcs[location2d]) {
              destinationAc = keyedAcs[location2d];
            } else {
              if (plateTypeToUse.isPlate) {
                throw new Error(
                  "Shouldn't be here. Should have found aliquot container."
                );
              }
              const newAc = {
                cid: shortid(),
                aliquotContainerTypeCode: tubeTypeToUse.code,
                ...position
              };
              newPlateMap[dstPlateName].aliquotContainers.push(newAc);
              destinationAc = {
                ...newAc,
                containerArray: {
                  cid: newPlateMap[dstPlateName].cid,
                  name: dstPlateName
                }
              };
              keyedAcs[location2d] = destinationAc;
            }
            transfer.destinationAliquotContainer = destinationAc;
            transfer.destinationAliquotContainerId = `&${destinationAc.cid}`;
          }
        }

        transfer.sourceAliquotContainerId = sourceAliquotContainer.id;
        transfer.sourceAliquotContainer = sourceAliquotContainer;
        if (useUniversalVolume) {
          transfer.volume = universalTransferVolume;
          transfer.volumetricUnitCode = universalTransferVolumeUnitCode;
        } else {
          transfer.volume = transferVolume;
          transfer.volumetricUnitCode = unit;
        }

        return transfer;
      })
    };
    const worklistNames = {
      [worklistKey]: result.name
    };
    let numBarcodesToGenerate = 0;
    const platesToCreate = Object.values(newPlateMap).map(p => {
      delete p.keyedAliquotContainers;
      if (!p.barcode) numBarcodesToGenerate++;
      return p;
    });
    const barcodeUpdates = [];
    const plateCidToBarcode = {};
    if (numBarcodesToGenerate > 0) {
      const newBarcodes = await generateBarcodeRecords(numBarcodesToGenerate);
      const barcodeList = [...newBarcodes];
      platesToCreate.forEach(p => {
        if (!p.barcode) {
          const barcodeRecord = barcodeList.shift();
          plateCidToBarcode[p.cid] = barcodeRecord.barcodeString;
          barcodeUpdates.push({
            id: barcodeRecord.id,
            containerArrayId: `&${p.cid}`
          });
        }
      });
      allTransfers[worklistKey].forEach(transfer => {
        const barcodeString =
          plateCidToBarcode[
            transfer.destinationAliquotContainer.containerArray.cid
          ];
        if (barcodeString) {
          transfer.destinationAliquotContainer.containerArray.barcode = {
            barcodeString
          };
        }
      });
    }
    change("barcodeUpdates", barcodeUpdates);
    change("platesToCreate", platesToCreate);
    change("worklistNames", worklistNames);
    change("allTransfers", allTransfers);
  } catch (error) {
    console.error("err:", error);
    throwFormError(error.validationError || "Error handling integration.");
  }
}

function UploadFiles({
  Footer,
  footerProps,
  stepFormProps: { change },
  nextStep,
  handleSubmit,
  useIntegration,
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  newDestination,
  useUniversalVolume,
  useIntegrationDestinationPlates,
  useIntegrationSourcePlates
}) {
  async function beforeNextStep(values) {
    const fn = values.useIntegration ? handleIntegration : createWorklists;

    await fn({
      values,
      change
    });
    nextStep();
  }

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Upload Worklist CSV or Choose Integration"
          helper="Upload a CSV of worklist transfers or specify a custom integration that has been setup in settings. This integration should return data which can be used to create a worklist."
        />
        <div>
          <CheckboxField name="useIntegration" label="Use Integration" />
          {useIntegration ? (
            <React.Fragment>
              <div style={{ maxWidth: 250 }}>
                <GenericSelect
                  {...{
                    name: "integration",
                    asReactSelect: true,
                    isRequired: true,
                    label: "Choose Integration",
                    additionalFilter: {
                      integrationTypeCode: "CUSTOM_WORKLIST"
                    },
                    schema: [
                      {
                        path: "name"
                      }
                    ],
                    fragment: integrationFragment
                  }}
                />
                <FileUploadField
                  name="integrationFiles"
                  secondaryLabel="(optional)"
                  label="Upload files to be sent to integration endpoint"
                />
                <CheckboxField
                  name="useUniversalVolume"
                  label="Specify Universal Transfer Volume"
                />
                {useUniversalVolume && (
                  <UnitInputField
                    name={fieldConstants.universalTransferVolume}
                    isRequired
                    label="Universal Transfer Volume"
                    unitName={fieldConstants.universalTransferVolumeUnitCode}
                    unitDefault="uL"
                    unitType="volumetricUnit"
                  />
                )}
              </div>
              <div>
                <div>
                  <h7>Source Plates</h7>
                  <CheckboxField
                    containerStyle={{ marginTop: 10 }}
                    label="Integration will return source plate information"
                    name="useIntegrationSourcePlates"
                  />
                  {!useIntegrationSourcePlates && (
                    <GenericSelect
                      {...{
                        name: "sourcePlates",
                        isMultiSelect: true,
                        buttonProps: {
                          disabled: isDisabledMap.sourcePlates,
                          loading: isLoadingMap.sourcePlates
                        },
                        nameOverride: "Source Plates",
                        schema: ["name"],
                        fragment: ["containerArray", "id name"],
                        additionalDataFragment: containerArrayPlatePreviewFragment,
                        postSelectDTProps: {
                          formName: "sourcePlatesTable",
                          schema: [platePreviewColumn(), "name"]
                        }
                      }}
                    />
                  )}
                </div>
                <h7>Destination Plates</h7>
                <CheckboxField
                  containerStyle={{ marginTop: 10 }}
                  label="Create New Destination Plates"
                  name={fieldConstants.newDestination}
                />
                {newDestination ? (
                  <div
                    style={{
                      maxWidth: 350
                    }}
                  >
                    <InputField
                      name={fieldConstants.platePrefix}
                      label="Plate Name Prefix"
                    />
                    <PlateUploadFields
                      inTool
                      noFileUpload
                      noFillOption
                      noTubeBarcodeOption
                    />
                  </div>
                ) : (
                  <div>
                    <CheckboxField
                      label="Integration will return destination plate information"
                      name="useIntegrationDestinationPlates"
                    />
                    {!useIntegrationDestinationPlates && (
                      <GenericSelect
                        {...{
                          name: "destinationPlates",
                          buttonProps: {
                            disabled: isDisabledMap.destinationPlates,
                            loading: isLoadingMap.destinationPlates
                          },
                          isMultiSelect: true,
                          nameOverride: "Destination Plates",
                          schema: ["name"],
                          fragment: ["containerArray", "id name"],
                          additionalDataFragment: containerArrayPlatePreviewFragment,
                          postSelectDTProps: {
                            formName: "destinationPlatesTable",
                            schema: [platePreviewColumn(), "name"]
                          }
                        }}
                      />
                    )}
                  </div>
                )}
                <div style={{ maxWidth: 250 }}>
                  <GenericSelect
                    {...{
                      asReactSelect: true,
                      name: "reactionMap",
                      label: "Reaction Map",
                      secondaryLabel: "(optional)",
                      tooltipInfo:
                        "This will be sent to integration endpoint to be used for worklist creation.",
                      schema: ["name"],
                      fragment: ["reactionMap", "id name"]
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div
              style={{
                width: 250
              }}
            >
              <FileUploadField
                isRequired
                fileLimit={1}
                accept={getDownloadTemplateFileHelpers({
                  fileName: "worklistCsv.csv",
                  validateAgainstSchema: {
                    requireExactlyOneOf: [
                      ["source plate barcode", "source tube barcode"],
                      ["destination plate barcode", "destination tube barcode"]
                    ],
                    requireAllOrNone: [
                      ["source plate barcode", "source well"],
                      ["destination plate barcode", "destination well"]
                    ],
                    fields: [
                      {
                        path: "source plate barcode",
                        description: "Barcode of the source plate",
                        example: "PLT123456"
                      },
                      {
                        path: "source tube barcode",
                        description:
                          "Barcode of the source tube. Optional if not a loose tube.",
                        example: "TUB789012"
                      },
                      {
                        path: "source well",
                        description: "Well identifier of the source",
                        example: "A1"
                      },
                      {
                        path: "destination plate barcode",
                        description: "Barcode of the destination plate",
                        example: "PLT345678"
                      },
                      {
                        path: "destination tube barcode",
                        description: "Barcode of the destination tube",
                        example: "TUB901234"
                      },
                      {
                        path: "destination well",
                        description: "Well identifier of the destination",
                        example: "B3"
                      },
                      {
                        path: "transfer volume",
                        description: "Volume to be transferred",
                        type: "number",
                        example: "50.2",
                        isRequired: true,
                        validate: value =>
                          isValidPositiveNumber(value)
                            ? undefined
                            : "Must be a positive number"
                      },
                      {
                        path: "transfer volume unit",
                        description: "Unit of the transfer volume",
                        example: "uL",
                        type: "dropdown",
                        values: Object.keys(unitGlobals.volumetricUnits),
                        isRequired: true
                      }
                    ]
                  }
                })}
                name="worklistCsvFiles"
              />
            </div>
          )}
        </div>
      </div>
      <Footer {...footerProps} onNextClick={handleSubmit(beforeNextStep)} />
    </div>
  );
}

export default stepFormValues(
  "useIntegration",
  "useIntegrationSourcePlates",
  "useIntegrationDestinationPlates",
  "newDestination",
  "useUniversalVolume"
)(UploadFiles);
