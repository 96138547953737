/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { DataTable, NumericInputField, SelectField } from "@teselagen/ui";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import {
  genericElutionColumnPlateFragment,
  genericElutionReactionMapFragment,
  genericElutionCollectionPlateFragment
} from "./fragments";
import { get, isEmpty } from "lodash";
import { compose } from "recompose";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { Button, Intent } from "@blueprintjs/core";
import { getReactionsForAliquotContainer } from "../../../utils/reactionMapUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

class SelectPlates extends Component {
  generateWorklist = values => {
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const {
      columnPlates = [],
      collectionPlates = {},
      elutionVolume,
      elutionVolumetricUnitCode
    } = values;

    const transfers = [];
    const collectionPlateIdToAliquotContainers = {};

    columnPlates.forEach(columnPlate => {
      const collectionPlate = collectionPlates["id" + columnPlate.id];
      collectionPlateIdToAliquotContainers[collectionPlate.id] = {};
      collectionPlate.aliquotContainers.forEach(ac => {
        collectionPlateIdToAliquotContainers[collectionPlate.id][
          getAliquotContainerLocation(ac)
        ] = { ...ac, plateName: collectionPlate.name };
      });
      columnPlate.aliquotContainers.forEach(ac => {
        if (
          get(ac, "aliquot.sample.material") ||
          get(ac, "aliquot.sample.sampleFormulations.length")
        ) {
          const destinationAliquotContainer =
            collectionPlateIdToAliquotContainers[collectionPlate.id][
              getAliquotContainerLocation(ac)
            ];
          transfers.push({
            sourceAliquotContainer: {
              ...ac,
              containerArray: {
                id: columnPlate.id,
                name: columnPlate.name,
                containerArrayType: columnPlate.containerArrayType,
                __typename: columnPlate.__typename
              }
            },
            sourceAliquotContainerId: ac.id,
            volume: elutionVolume,
            volumetricUnitCode: elutionVolumetricUnitCode,
            destinationAliquotContainerId: destinationAliquotContainer.id,
            destinationPlateName: destinationAliquotContainer.plateName,
            destinationAliquotContainer: destinationAliquotContainer
          });
        }
      });
    });
    change("worklist", { worklistTransfers: transfers });
    nextStep();
  };

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      collectionPlates = {},
      columnPlates = [],
      Footer,
      footerProps,
      reactionMap,
      handleSubmit
    } = this.props;

    const plateErrors = {};
    const plateWarnings = {};
    if (reactionMap && columnPlates.length) {
      const reactionInputMaterialIds = [];
      const reactionInputReagentIds = [];
      reactionMap.reactions.forEach(reaction =>
        reaction.reactionInputs.forEach(input => {
          if (input.inputMaterial) {
            reactionInputMaterialIds.push(
              input.inputMaterial && input.inputMaterial.id
            );
          } else {
            reactionInputReagentIds.push(input.inputAdditiveMaterial.id);
          }
        })
      );

      columnPlates.forEach(plate => {
        let noMaterials = true;
        let hasDryAliquot = false;
        plate.aliquotContainers.forEach(ac => {
          const sample = get(ac, "aliquot.sample");
          const material = get(ac, "aliquot.sample.material");
          const addError = (msg, obj = plateErrors) => {
            if (!obj[plate.id]) {
              obj[plate.id] = {};
            }
            obj[plate.id][
              getAliquotContainerLocation(ac, { force2D: true })
            ] = msg;
          };
          const addWarning = msg => addError(msg, plateWarnings);
          const hasFormulatedSampleMaterial =
            sample &&
            sample.sampleTypeCode === "FORMULATED_SAMPLE" &&
            sample.sampleFormulations.length;
          const hasMaterial = !!material || !!hasFormulatedSampleMaterial;
          if (hasMaterial) {
            noMaterials = false;
          }
          if (
            hasMaterial ||
            get(ac, "additives.length") ||
            get(ac, "aliquot.additives.length")
          ) {
            if (
              !getReactionsForAliquotContainer({
                aliquotContainer: ac,
                reactionMaps: [reactionMap]
              }).length
            ) {
              addWarning(
                "This well is missing reaction inputs and will be ignored."
              );
            }
          }
          // if a single material it needs to have a reaction
          // if multiple materials at least one needs to have a reaction
          if (ac.aliquot && ac.aliquot.isDry) {
            addError("This aliquot is dry");
            hasDryAliquot = true;
          } else if (material) {
            if (!reactionInputMaterialIds.includes(material.id)) {
              addError(
                `Material ${material.name} at ${getAliquotContainerLocation(
                  ac,
                  { force2D: true }
                )} not found on reaction map.`
              );
            }
          } else if (sample && sample.sampleTypeCode === "FORMULATED_SAMPLE") {
            if (sample.sampleFormulations.length) {
              noMaterials = false;
            }
            const hasMatchingMaterial = sample.sampleFormulations.some(sf =>
              sf.materialCompositions.some(mc =>
                reactionInputMaterialIds.includes(mc.materialId)
              )
            );
            if (!hasMatchingMaterial) {
              addError(
                `Pooled sample ${sample.name} does not have any materials which are on the reaction map.\n`
              );
            }
          }
        });
        if (noMaterials || hasDryAliquot) {
          if (!plateErrors[plate.id]) {
            plateErrors[plate.id] = {};
          }
          plateErrors[plate.id]._error = noMaterials
            ? `No materials found on plate`
            : "Plate has dry aliquots.";
        }
      });
    }

    const errorMessage = "Please review errors on plates.";
    const hasErrors = !isEmpty(plateErrors);

    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Reaction Map"
            helper="Select a reaction map for the elution worklist."
          />
          <GenericSelect
            {...{
              name: "reactionMap",
              isRequired: true,
              schema: [
                "name",
                {
                  displayName: "Reaction Type",
                  path: "reactionType.name"
                },
                dateModifiedColumn
              ],
              fragment: [
                "reactionMap",
                "id name reactionTypeCode reactionType { code name } updatedAt"
              ],
              tableParamOptions: {
                additionalFilter: (props, qb) => {
                  qb.whereAll({
                    reactionTypeCode: qb.inList([
                      "PROTEIN_PURIFICATION",
                      "PLASMID_PURIFICATION"
                    ])
                  });
                }
              },
              additionalDataFragment: genericElutionReactionMapFragment,
              buttonProps: {
                loading: isLoadingMap.reactionMap,
                disabled: isDisabledMap.reactionMap
              }
            }}
          />
          {reactionMap && (
            <React.Fragment>
              <h6>{reactionMap.name}</h6>
              <DataTable
                formName="reactions"
                entities={reactionMap.reactions}
                schema={[
                  {
                    displayName: "Reaction Inputs",
                    render: (v, r) =>
                      r.reactionInputs
                        .map(input => {
                          if (input.inputMaterial) {
                            return input.inputMaterial.name;
                          } else {
                            return input.inputAdditiveMaterial.name;
                          }
                        })
                        .join(", ")
                  },
                  {
                    displayName: "Reaction Outputs",
                    render: (v, r) =>
                      r.reactionOutputs.map(output => {
                        if (
                          output.outputMaterial &&
                          output.outputMaterial.name
                        ) {
                          return output.outputMaterial.name;
                        } else {
                          return output.outputAdditiveMaterial.name;
                        }
                      })
                  }
                ]}
              />
            </React.Fragment>
          )}
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Column Plates from Inventory"
            helper="Select one or more column plates for elution."
          />
          <GenericSelect
            {...{
              name: "columnPlates",
              isRequired: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              isMultiSelect: true,
              fragment: [
                "containerArray",
                "id name barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: genericElutionColumnPlateFragment,
              postSelectDTProps: {
                noForm: true,
                collectionPlates,
                plateErrors,
                plateWarnings,
                schema: [
                  platePreviewColumn({ plateErrors, plateWarnings }),
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  {
                    displayName: "Plate Type",
                    path: "containerArrayType.name"
                  },
                  {
                    displayName: "Collection Plate",
                    width: 180,
                    render: (v, r) => {
                      return (
                        <div>
                          {get(collectionPlates, `id${r.id}.name`)}
                          <GenericSelect
                            {...{
                              name: `collectionPlates.id` + r.id,
                              isRequired: true,
                              noRemoveButton: true,
                              schema: [
                                "name",
                                {
                                  displayName: "Barcode",
                                  path: "barcode.barcodeString"
                                },
                                {
                                  displayName: "Plate Type",
                                  path: "containerArrayType.name"
                                },
                                dateModifiedColumn
                              ],
                              tableParamOptions: {
                                additionalFilter: (props, qb) => {
                                  qb.whereAll({
                                    "containerArrayType.isColumn": false,
                                    "containerArrayType.containerFormatCode":
                                      r.containerArrayType.containerFormatCode,
                                    "containerArrayType.isPlate": true
                                  });
                                  qb.orWhereAll({
                                    "containerArrayType.isColumn": qb.isNull(),
                                    "containerArrayType.containerFormatCode":
                                      r.containerArrayType.containerFormatCode,
                                    "containerArrayType.isPlate": true
                                  });
                                }
                              },
                              defaultValue: r.collectionPlate,
                              fragment: [
                                "containerArray",
                                "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
                              ],
                              additionalDataFragment: genericElutionCollectionPlateFragment
                            }}
                          />
                        </div>
                      );
                    }
                  }
                ]
              },
              tableParamOptions: plateFilter,
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              }
            }}
          />
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Elution Transfer Volume"
            helper="Enter the amount of elution solution to be added to each destination plate well."
          />
          <div className="input-with-unit-select">
            <NumericInputField
              isRequired
              name="elutionVolume"
              label="Volume"
              placeholder="Enter desired volume..."
            />
            <SelectField
              className="tg-unit-select"
              label="none"
              name="elutionVolumetricUnitCode"
              defaultValue="uL"
              options={unitGlobals.getOptionsForSelect("volumetricUnit")}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          errorMessage={hasErrors && errorMessage}
          nextButton={
            <Button
              intent={Intent.PRIMARY}
              disabled={!isEmpty(plateErrors)}
              onClick={handleSubmit(this.generateWorklist)}
            >
              Next
            </Button>
          }
        />
      </div>
    );
  }
}

const plateFilter = {
  additionalFilter: (props, qb) => {
    qb.whereAll({
      "containerArrayType.isColumn": true
    });
  }
};

export default compose(
  stepFormValues("columnPlates", "collectionPlates", "reactionMap")
)(SelectPlates);
