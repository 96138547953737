/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import SelectReagents, { selectReagentsFragment } from "./Steps/SelectReagents";

function CreateReagentList(props) {
  const {
    initialValues,
    toolSchema,
    toolIntegrationProps,
    isToolIntegrated
  } = props;

  async function onSubmit(values) {
    const { tableName, additiveMaterials = [] } = values;

    try {
      const [table] = await safeUpsert("dataTable", {
        name: tableName,
        dataTableTypeCode: "REAGENT_LIST",
        dataRows: additiveMaterials.map(am => {
          return {
            rowValues: {
              reagentId: am.id,
              reagentName: am.name
            }
          };
        })
      });
      return {
        dataTable: table
      };
    } catch (error) {
      console.error("err:", error);
      throwFormError("Error creating table.");
    }
  }

  const steps = [
    {
      title: "Select Reagents",
      Component: SelectReagents
    }
  ];

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      enableReinitialize={isToolIntegrated}
      initialValues={initialValues}
    />
  );
}

export default compose(
  withWorkflowInputs(selectReagentsFragment, {
    inputName: "additiveMaterials",
    initialValueName: "additiveMaterials"
  })
)(CreateReagentList);
