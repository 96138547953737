/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import "./style.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  arrayPush,
  arrayRemove,
  change,
  formValueSelector,
  reduxForm
} from "redux-form";
import { get, identity } from "lodash";
import {
  Button,
  ButtonGroup,
  Callout,
  Divider,
  Intent,
  Menu,
  MenuItem
} from "@blueprintjs/core";
import queryBuilder from "tg-client-query-builder";
import {
  wrapDialog,
  PromptUnsavedChanges,
  DialogFooter,
  DropdownButton,
  InputField,
  showConfirmationDialog
} from "@teselagen/ui";
import EditParameters from "./EditParameters";
import { jumpToErrorOnFail } from "../../../../src-shared/utils/jumpToErrorOnFail";
import {
  getDefaultParameters,
  getDefaultParamsAsCustomJ5ParamName
} from "../../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";
import { showStackedDialog } from "../../../../src-shared/StackedDialog";
import saveParameterPresetDialog from "./saveParameterPresetDialog";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import customJ5ParameterFragment from "../../../../../tg-iso-design/graphql/fragments/customJ5ParameterFragment";
import { getGapLength } from "../../../../../tg-iso-shared/utils/enzymeUtils";
import { DIGEST_ASSEMBLY_METHODS } from "../../../../../tg-iso-design/constants/assemblyMethods";
import useTgQuery from "../../../../src-shared/apolloUseTgQuery";

const handleGatewayParams = gatewayParams =>
  gatewayParams?.gateway
    ? JSON.stringify(
        gatewayParams.gateway.map(({ L, R }, i) => ({
          L: {
            sequence: L.sequence,
            name: `L${i + 1}`,
            overhangStart: L.overhangStart - 1,
            overhangLength: gatewayParams.overhangLength,
            isBSide5Prime: +L.isBSide5Prime
          },
          R: {
            sequence: R.sequence,
            name: `R${i + 1}`,
            overhangStart: R.overhangStart - 1,
            overhangLength: gatewayParams.overhangLength,
            isBSide5Prime: +R.isBSide5Prime
          }
        }))
      )
    : getDefaultParameters.GATEWAY_ATT_SITES;

function EditJ5Parameters(props) {
  const {
    hideModal,
    handleSubmit,
    onSubmit,
    customJ5ParameterFormValues,
    isPreset,
    customJ5Parameter,
    refetch = identity,
    updateFormValues,
    unsavedChanges,
    change
  } = props;

  const [submitting, setSubmitting] = useState(false);
  // const [unsavedChanges, setUnsavedChanges] = useState(false);
  const setUnsavedChanges = () => change("unsavedChanges", true);

  const _onSubmit = async values => {
    try {
      setSubmitting(true);
      const { name, editParameters } = values;
      const { gatewayParams, isLocalToThisDesignId, ...params } =
        editParameters;

      const newParams = {
        ...params,
        gatewayAttSites: handleGatewayParams(gatewayParams)
      };

      const newParamSet = {
        ...getDefaultParamsAsCustomJ5ParamName(),
        ...newParams,
        ...(props.isPreset && { name })
      };

      await onSubmit(newParamSet);
      await refetch();

      window.toastr.success("Parameters successfully saved");
      hideModal();
      setSubmitting(false);
    } catch (e) {
      console.error(e);
      window.toastr.error(
        "Error saving parameters. Please try again later and/or contact the TeselaGen Team."
      );
    }
  };

  const onApplyPreset = async paramPreset => {
    const continueCreate = await showConfirmationDialog({
      text: "This will overwrite the current parameters. Continue?",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    });
    if (continueCreate) {
      const {
        // Only keep the relevant information, thus the parameters
        // and not the rest of the records props.
        id,
        cid,
        __typename,
        name,
        isLocalToThisDesignId,
        // digest-based assembly preset params cannot be taken into account
        // given that they depend on the selected restriction enzyme
        ggateRecognitionSeq,
        ggateOverhangBps,
        ...restParams
      } = paramPreset;
      updateFormValues({ name: props.name, ...restParams });
      window.toastr.success(`Parameter preset '${paramPreset.name}' applied.`);
    }
  };

  const parameterPresetSection = (
    <div>
      <ButtonGroup>
        <DropdownButton
          minimal
          noRightIcon
          intent={Intent.PRIMARY}
          menu={
            <ParamterPresetMenu
              onApplyPreset={onApplyPreset}
              isPreset={isPreset}
              customJ5Parameter={customJ5Parameter}
            ></ParamterPresetMenu>
          }
          data-tip="This overrides the current parameters with those of the preset"
          text="Apply Preset"
          disabled={props.readOnly}
        />
        <div>
          <Button
            data-tip="Go to assembly parameter templates settings"
            icon="cog"
            style={{ width: "100%" }}
            minimal
            intent={Intent.PRIMARY}
            onClick={() =>
              window.open(
                "/client/settings/assembly-parameter-presets",
                "_blank"
              )
            }
          />
        </div>
        <div>
          <Button
            minimal
            icon="floppy-disk"
            text="Save As New Preset"
            intent={Intent.SUCCESS}
            onClick={handleSubmit(() =>
              showStackedDialog({
                ModalComponent: saveParameterPresetDialog,
                modalProps: {
                  parameters: customJ5ParameterFormValues,
                  isPreset: props.isPreset,
                  refetch: () => {
                    refetch();
                  }
                }
              })
            )}
            disabled={props.readOnly || props.invalid}
          />
        </div>
      </ButtonGroup>
    </div>
  );

  const headerSection = (
    <React.Fragment>
      {props.isPreset && props.readOnly && (
        <Callout intent="warning" style={{ marginBottom: 20 }}>
          Only admin users can edit parameter presets
        </Callout>
      )}
      {props.isPreset && (
        <InputField
          name="name"
          isRequired
          label="Preset Name"
          disabled={props.readOnly}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <HeaderWithHelper
          header="Assembly Parameters"
          helper="These are the parameter that will be applied for your assembly."
        />
        {parameterPresetSection}
      </div>
    </React.Fragment>
  );

  const onClose = async () => {
    if (
      !unsavedChanges ||
      (await showConfirmationDialog({
        text: "Are you sure you want to close? any changes will be lost",
        canEscapeKeyCancel: true,
        intent: Intent.DANGER,
        icon: "warning-sign"
      }))
    ) {
      hideModal();
    }
  };

  return (
    <React.Fragment>
      <PromptUnsavedChanges when={unsavedChanges} />
      <div className="bp3-dialog-body">
        {headerSection}
        <Divider />
        <EditParameters
          {...props}
          formName="editJ5ParametersDialogForm"
          onUnsavedChanges={setUnsavedChanges}
        />
      </div>

      <DialogFooter
        hideModal={hideModal}
        onClick={handleSubmit(_onSubmit)}
        text="Save Parameters"
        disabled={props.readOnly}
        secondaryAction={onClose}
        submitting={submitting}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog(props => ({
    title: "Assembly Method Parameters",
    style: { width: "80%" },
    onClose: async () => {
      const state = window.teGlobalStore.getState();
      const unsavedChanges = formValueSelector("editJ5ParametersDialogForm")(
        state,
        "unsavedChanges"
      );
      if (
        // TODO: figure out how to handle dialog onClose based on unsaved changes.
        !unsavedChanges ||
        (await showConfirmationDialog({
          text: "Are you sure you want to close? any changes will be lost",
          canEscapeKeyCancel: true,
          intent: Intent.DANGER,
          icon: "warning-sign"
        }))
      )
        props.hideModal();
    }
  })),
  reduxForm({
    form: "editJ5ParametersDialogForm", // a unique name for this form
    enableReinitialize: true,
    ...jumpToErrorOnFail(),
    asyncBlurFields: ["editParameters.name"],
    validate: parametersValidate
  }),
  connect(
    state => {
      const editParameters = formValueSelector("editJ5ParametersDialogForm")(
        state,
        "editParameters"
      );
      // the 'editJ5ParametersDialogForm; state form doesnt exit on first renders.
      if (!editParameters) return {};

      const { gatewayParams, ...params } = editParameters;
      const customJ5ParameterFormValues = {
        ...params,
        gatewayAttSites: handleGatewayParams(gatewayParams)
      };
      return {
        name: formValueSelector("editJ5ParametersDialogForm")(state, "name"),
        unsavedChanges: formValueSelector("editJ5ParametersDialogForm")(
          state,
          "unsavedChanges"
        ),
        gatewayParams,
        // We'll need access to these in case the user wants to save the current parameters as a preset.
        customJ5ParameterFormValues
      };
    },

    dispatch => ({
      changeForm: (...args) =>
        dispatch(change("editJ5ParametersDialogForm", ...args)),
      arrayRemove: (...args) =>
        dispatch(arrayRemove("editJ5ParametersDialogForm", ...args)),
      arrayPush: (...args) =>
        dispatch(arrayPush("editJ5ParametersDialogForm", ...args)),
      updateFormValues: customJ5Parameter => {
        const changeForm = (formField, formValue) =>
          dispatch(change("editJ5ParametersDialogForm", formField, formValue));

        const {
          // take the db information out of the 'customJ5Parameter' object
          // and keep only the properties relevant to the form. Infact adding them to the form
          // will cause unwanted behavior.
          id,
          cid,
          name,
          __typename,
          isLocalToThisDesignId,
          // relevant form params:
          gatewayAttSites: gatewayAttSitesString,
          ...restParams
        } = customJ5Parameter;

        changeForm("name", name);

        Object.entries(restParams).forEach(([formParam, formValue]) =>
          changeForm(`editParameters.${formParam}`, formValue)
        );

        const gatewayAttSites = gatewayAttSitesString
          ? JSON.parse(gatewayAttSitesString)
          : [];

        changeForm(
          "editParameters.gatewayParams.overhangLength",
          get(gatewayAttSites, [0, "overhangLength"], 7)
        );

        gatewayAttSites.length &&
          gatewayAttSites.forEach(({ L, R }, i) => {
            changeForm(
              `editParameters.gatewayParams.gateway[${i}].L.sequence`,
              L.sequence
            );
            changeForm(
              `editParameters.gatewayParams.gateway[${i}].L.overhangStart`,
              L.overhangStart + 1
            );
            changeForm(
              `editParameters.gatewayParams.gateway[${i}].L.isBSide5Prime`,
              !!L.isBSide5Prime
            );
            changeForm(
              `editParameters.gatewayParams.gateway[${i}].R.sequence`,
              R.sequence
            );
            changeForm(
              `editParameters.gatewayParams.gateway[${i}].R.overhangStart`,
              R.overhangStart + 1
            );
            changeForm(
              `editParameters.gatewayParams.gateway[${i}].R.isBSide5Prime`,
              !!R.isBSide5Prime
            );
          });
      }
    })
  )
)(EditJ5Parameters);

export function parametersValidate({ editParameters }, props) {
  const { restrictionEnzyme, assemblyMethod, isPreset } = props;
  const assemblyMethodName = assemblyMethod?.name;
  const errors = { editParameters: { gatewayParams: {} } };

  if (!editParameters) return {};

  function digestValidation() {
    const spacerLength = restrictionEnzyme && getGapLength(restrictionEnzyme);
    const {
      ggateTerminiExtraSeq,
      ggateTerminiExtraSeqAlt,
      ggateRecognitionSeq
    } = editParameters;

    // No point in validating these if they havent been set yet.
    if (!ggateTerminiExtraSeq || !ggateTerminiExtraSeqAlt) return false;

    const terminiChecks = [
      {
        invalid: value => !value.includes(ggateRecognitionSeq),
        errorMessage: `Termini sequence must include the recognition sequence '${ggateRecognitionSeq}'.`
      },
      ...(Number.isInteger(spacerLength)
        ? [
            {
              invalid: value => {
                const { index: recognitionStart } =
                  value.match(ggateRecognitionSeq) || [];

                if (recognitionStart === undefined) return true;
                const spacerStart =
                  recognitionStart + ggateRecognitionSeq.length;
                const actualSpacerLength = value.slice(spacerStart).length;

                return actualSpacerLength !== spacerLength;
              },
              errorMessage: `The Termini sequence must have exactly ${spacerLength} base pair${
                spacerLength > 1 ? "s" : ""
              } after the recognition site.`
            }
          ]
        : [])
    ];

    terminiChecks.forEach(check => {
      if (
        check.invalid(ggateTerminiExtraSeq) &&
        !errors.editParameters.ggateTerminiExtraSeq
      ) {
        errors.editParameters.ggateTerminiExtraSeq = check.errorMessage;
      }
      if (
        check.invalid(ggateTerminiExtraSeqAlt) &&
        !errors.editParameters.ggateTerminiExtraSeqAlt
      ) {
        errors.editParameters.ggateTerminiExtraSeqAlt = check.errorMessage;
      }
    });
  }
  function gatewayValidation() {
    const {
      gatewayParams: { gateway: _gateway, overhangLength }
    } = editParameters;

    const gateway = _gateway || [];

    if (!(overhangLength > 0 && Number.isInteger(overhangLength)))
      errors.editParameters.gatewayParams.overhangLength =
        "Must be a positive integer.";

    errors.editParameters.gatewayParams.gateway = gateway.map(() => ({
      L: {},
      R: {}
    }));

    const sequences = {};
    gateway.forEach(({ L, R }, i) => {
      [L, R].forEach(({ sequence, overhangStart }, j) => {
        overhangStart--;
        const key = j ? "R" : "L";
        const attErr = errors.editParameters.gatewayParams.gateway[i][key];

        sequences[sequence.toUpperCase()] = true;

        if (!sequence) attErr.sequence = "Required";
        else if (!/^[acgtuwsmkrybdhvn]*$/i.test(sequence))
          attErr.sequence = "Contains invalid character";
        else if (overhangStart + overhangLength > sequence.length)
          attErr.sequence =
            attErr.overhangStart = `The sum of the overhang start and length must be less than or equal to the length of the sequence ${sequence.length}.`;
        if (!(overhangStart > 0 && Number.isInteger(overhangStart)))
          attErr.overhangStart = "Must be a positive integer.";
      });

      if (L.isBSide5Prime === R.isBSide5Prime)
        errors.editParameters.gatewayParams.gateway[i].L.isBSide5Prime =
          errors.editParameters.gatewayParams.gateway[i].R.isBSide5Prime =
            "The 5' fragments of both the L and R site either do or do not form the attB site. Exactly one of the 5' fragments of the L and R sites must form the attB site.";
    });

    if (Object.keys(sequences).length !== 2 * gateway.length)
      errors.editParameters.gatewayParams.gateway.forEach(({ L, R }) => {
        L.sequence = R.sequence = "Duplicate sequence detected.";
      });
  }

  if (
    DIGEST_ASSEMBLY_METHODS.includes(assemblyMethodName) &&
    restrictionEnzyme
  ) {
    digestValidation();
  } else if (isPreset) {
    gatewayValidation();
  }

  return errors;
}

export const ParamterPresetMenu = ({
  onApplyPreset,
  isPreset,
  customJ5Parameter = { id: 0 }
}) => {
  const qb = new queryBuilder("customJ5Parameter");
  const filter = qb
    .whereAll({
      isLocalToThisDesignId: qb.isNull(),
      ...(isPreset && {
        id: qb.notEquals(customJ5Parameter.id)
      })
    })
    .andWhereAny({ cid: qb.isNull() }, { cid: qb.notEquals("default") });

  const { customJ5Parameters, ...rest } = useTgQuery(
    customJ5ParameterFragment,
    {
      variables: {
        filter,
        pageSize: 999
      }
    }
  );
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  return (
    <Menu style={{ maxHeight: 200, overflowY: "scroll" }}>
      {!customJ5Parameters.length && (
        <MenuItem text="No presets found." disabled={true} />
      )}
      {customJ5Parameters.map((paramPreset, idx) => (
        <MenuItem
          key={`paramPreset-${idx}`}
          onClick={() => onApplyPreset(paramPreset)}
          text={paramPreset.name}
        />
      ))}
    </Menu>
  );
};
