/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const plasmidPrepPlateFragment = gql`
  fragment plasmidPrepPlateFragment on containerArray {
    id
    name
    containerArrayType {
      id
      name
      containerFormatCode
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        id
        volume
        isDry
        sample {
          id
          name
          material {
            id
            materialTypeCode
            name
            microbialMaterialMicrobialMaterialPlasmids {
              id
              polynucleotideMaterialId
              polynucleotideMaterial {
                id
                name
                polynucleotideMaterialSequence {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    barcode {
      id
      barcodeString
    }
    createdAt
    updatedAt
  }
`;

export const plasmidPrepTubeFragment = gql`
  fragment plasmidPrepTubeFragment on aliquotContainer {
    id
    name
    aliquot {
      id
      volume
      sample {
        id
        material {
          id
          name
          materialTypeCode
          microbialMaterialMicrobialMaterialPlasmids {
            id
            polynucleotideMaterialId
            polynucleotideMaterial {
              id
              name
              polynucleotideMaterialSequence {
                id
                name
              }
            }
          }
        }
      }
    }
    aliquotContainerType {
      code
      name
    }
    updatedAt
  }
`;
