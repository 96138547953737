/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { v4 as uuid } from "uuid";
import {
  createObjectValuedTypeMap,
  transformWithNewIds
} from "../utils/designStateUtils";
import { isHdeDesignJson } from "../utils/isHdeDesignJson";
import removeLockInfoFromDesignObject from "../utils/removeLockInfoFromDesignObject";
import {
  TYPES_LINKED_TO_DESIGN,
  SIMPLE_REFERENCES_TO_TYPE
} from "../constants/designStateConstants";
import { removeDuplicates } from "../utils/removeDuplicates";
import { isoContext } from "@teselagen/utils";
import { uploadDesign } from "../../tg-iso-shared/src/utils/uploadDesign";
import sequenceStringToFragments from "../../tg-iso-shared/src/sequence-import-utils/sequenceStringToFragments";

function replaceBpLiteralsWithParts(designState) {
  const elements = designState.element;
  elements &&
    Object.keys(elements).forEach(elementId => {
      const element = elements[elementId];
      if (!element.partId && element.bps) {
        const bps = element.bps;
        delete element.bps;
        const sequenceId = uuid();
        element.partId = uuid();
        designState.part[element.partId] = {
          id: element.partId,
          name: element.name,
          sequenceId,
          strand: 1,

          start: 0,
          end: bps.length - 1
        };
        designState.sequence[sequenceId] = {
          id: sequenceId,
          name: element.name,
          size: bps.length
        };
        sequenceStringToFragments(bps).forEach(({ fragment, index }) => {
          const id = uuid();
          designState.sequenceFragment[id] = {
            id,
            index,
            sequenceId,
            fragment: fragment
          };
        });
      }
    });
}
/**
 * This function will mutate the input json.
 * @param {*} json Will get mutated.
 * @param {*} designOverrideValues
 * @param {*} checkForDuplicates boolean, if true the server checks for duplicated sequences
 */
export default async function importDesignFromHdeJson(
  json,
  designOverrideValues = {},
  checkForDuplicates,
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  if (!isHdeDesignJson(json)) {
    throw new Error("This is not HDE design export JSON.");
  }

  let designState = { ...json.design };
  const design = Object.values(designState.design)[0];
  replaceBpLiteralsWithParts(designState);
  delete design.id;
  delete design.originalDesignId;
  removeLockInfoFromDesignObject(design);
  const designId = uuid();
  design.id = designId;
  designState.design = {};

  // Replace the existing ids with randomized ids.
  const oldIdToNewIdMap = createObjectValuedTypeMap();
  for (const [type, items] of Object.entries(designState)) {
    for (const id of Object.keys(items)) {
      oldIdToNewIdMap[type][id] = uuid();
      if (type === "customJ5Parameter") {
        if (items[id].isLocalToThisDesignId) {
          items[id].isLocalToThisDesignId = designId;
        }
      } else if (TYPES_LINKED_TO_DESIGN[type]) {
        items[id].designId = designId;
      }
    }
  }
  designState = transformWithNewIds(designState, oldIdToNewIdMap);
  // console.log(`designState:`, JSON.stringify(designState, null, 2))

  // Check for duplicates and then set the ids and remove
  // the duplicated values from the upserts.
  designState = await removeDuplicates(designState, { safeQuery });
  // Add the overide values.
  Object.assign(design, designOverrideValues);

  designState.design = { [design.id]: design };
  // let data;
  const dataForCreate = {
    designJson: designState,
    SIMPLE_REFERENCES_TO_TYPE,
    checkForDuplicates
  };
  const data = await uploadDesign(dataForCreate, ctx);

  const newDesignId = data.designIds
    ? Array.isArray(data.designIds)
      ? data.designIds[0]
      : data.designIds
    : null;
  const results = data.results || null;
  const topologicalDesign = data.topologicalDesign || null;
  const response = {
    allInputSequencesWithAttachedDuplicates:
      data.allInputSequencesWithAttachedDuplicates,
    designId: newDesignId,
    results,
    topologicalDesign
  };

  return response;
}
