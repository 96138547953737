/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  FileUploadField,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";

import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import GenericSelect from "../../../../src-shared/GenericSelect";
import handleTwistPlateImport from "../../../../../tg-iso-lims/src/plateImports/handleTwistPlateImport";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { allowedCsvFileTypes } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import AssignToLocationCheckbox from "../../AssignToLocationCheckbox";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

export const twistUploadHelperText =
  "Upload a CSV of one or more plates received from Twist and genbank files for any associated sequences. This prevents duplication of sequence data.";

export async function submitTwistTubes(values, options) {
  const { makeError } = options;
  try {
    return await handleTwistPlateImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.message || error.errors || "Error uploading plate.");
  }
}

const UploadTwistTubesDialog = ({
  refetch,
  noDialog,
  submitting,
  handleSubmit,
  hideModal,
  fieldPrefix = "",
  error
}) => {
  const onSubmit = async values => {
    const makeError = error => {
      throwFormError({
        _error: error
      });
    };
    await submitTwistTubes(values, {
      makeError,
      refetch,
      isTubeUpload: true
    });
  };

  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <GenericSelect
          name={fieldPrefix + "aliquotContainerType"}
          asReactSelect
          isRequired
          idAs="code"
          label="Tube Type"
          placeholder="Select tube type..."
          fragment={["aliquotContainerType", "code name"]}
          additionalDataFragment={aliquotContainerTypeFragment}
          tableParamOptions={{
            additionalFilter: {
              isTube: true
            }
          }}
        />
        <AssignToLocationCheckbox fieldPrefix={fieldPrefix} />
        {!noDialog && (
          <div style={{ fontStyle: "italic", marginBottom: 10 }}>
            {twistUploadHelperText}
          </div>
        )}
        <FileUploadField
          isRequired
          accept={getDownloadTemplateFileHelpers({
            type: allowedCsvFileTypes,
            fileName: "Twist_Tubes",
            validateAgainstSchema: {
              fields: [
                {
                  path: "Name",
                  isRequired: true,
                  description: "The name of the entity.",
                  example: "Entity1"
                },
                {
                  path: "Insertion point name",
                  description: "The name of the insertion point.",
                  example: "InsertionPoint1"
                },
                {
                  path: "Vector name",
                  description: "The name of the vector.",
                  example: "Vector1"
                },
                {
                  path: "Insert length",
                  description: "The length of the insert.",
                  example: "100",
                  type: "number"
                },
                {
                  path: "Construct length",
                  description: "The length of the construct.",
                  example: "200",
                  type: "number"
                },
                {
                  path: "Insert sequence",
                  description: "The sequence of the insert.",
                  example: "ATCG"
                },
                {
                  path: "Construct sequence",
                  description: "The sequence of the construct.",
                  example: "GCTA"
                },
                {
                  path: "NGS",
                  description: "The NGS value.",
                  example: "NGS123"
                },
                {
                  path: "Yield (ng)",
                  isRequired: true,
                  description: "The yield in nanograms.",
                  example: "150",
                  type: "number"
                },
                {
                  path: "Product type",
                  description: "The type of the product.",
                  example: "ProductType1"
                },
                {
                  path: "Tube barcode",
                  isRequired: true,
                  description: "The barcode of the tube.",
                  example: "Tube123"
                }
              ]
            },
            additionalFileTypes: [".gb"],
            extendedPropTypes: ["sequence", "aliquot", "sample", "tube"],
            helper:
              "To attach sequences upload genbank files along with the csv."
          })}
          name={fieldPrefix + "tubeFiles"}
        />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Upload Twist CSV"
  }),
  reduxForm({
    form: "uploadTwistTubesForm",
    onSubmitFail: (...args) => {
      console.error("Error hit in upload twist tubes onSubmit:", ...args);
    }
  })
)(UploadTwistTubesDialog);
