/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get } from "lodash";
import DescriptionEditForm from "./DescriptionEditForm";
import { showDialog } from "../../../src-shared/GlobalDialog";
import {
  extendedPropertyModels,
  tagModels
} from "../../../../tg-iso-shared/constants";
import { getSortedTaggedItems } from "../../../src-shared/utils/tagUtils";
import { Button, Tooltip } from "@blueprintjs/core";
import Tag from "../../../src-shared/Tag";

import "./style.css";
import RecordExtendedProperties from "../../RecordInfoDisplay/RecordExtendedProperties";

const renderDate = date => new Date(date).toLocaleDateString("en-US");
const renderDateWithTime = date => new Date(date).toLocaleString("en-US");

class LibraryInspector extends React.Component {
  static defaultProps = {
    renderExtraItemsTop: null,
    renderExtraItemsBottom: null,
    withDescription: false,
    withSharingInformation: false,
    withSequence: false,
    additionalFields: []
  };

  state = {
    isDescriptionEditing: false,
    isSequenceEditing: false
  };

  renderDescription = () => {
    const { record, dataType } = this.props;
    const { isDescriptionEditing } = this.state;
    return (
      <div className="library-inspector-group">
        {isDescriptionEditing ? (
          <DescriptionEditForm
            record={record}
            dataType={dataType}
            initialValues={record}
            exit={() => this.setState({ isDescriptionEditing: false })}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{record.description || <i>Add a description</i>}</span>{" "}
            <div>
              <Button
                minimal
                icon="edit"
                onClick={() => this.setState({ isDescriptionEditing: true })}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderSequence = () => {
    const { record: _record, isAASeq } = this.props;
    const record = { ..._record };
    if (isAASeq) {
      record.sequence = record.proteinSequence;
    }
    // tnr: this was kind of a mess. I made it so we can no longer edit just the AA sequence of an amino acid
    // (instead users will need to jump into the editor to do so which is good because AA seqs might have parts/features)
    // I also made it so if editing an oligo, it will just open the oligo edit dialog to not duplicate logic
    return (
      <div className="library-inspector-group-text-above">
        <div>Sequence: </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="sequence-text">
            {record.fullSequence || record.sequence || <i>Add a sequence..</i>}
          </span>
        </div>
      </div>
    );
  };

  renderSharingInformation = () => {
    const { record } = this.props;
    const username = get(record, "user.username", "");
    return (
      <div className="library-inspector-group">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Owner: {username}</div>
        </div>
      </div>
    );
  };

  renderFields = () => {
    const { record, additionalFields } = this.props;
    const fields = [
      ...additionalFields,
      { path: "createdAt", displayName: "Created", render: renderDate },
      { path: "updatedAt", displayName: "Modified", render: renderDateWithTime }
    ];
    return (
      <div className="library-inspector-group">
        <table style={{ width: "100%" }}>
          <tbody>
            {fields.map(({ path, displayName, render }, i) => (
              <tr key={i}>
                <td className="library-inspector-data-name">{displayName}:</td>
                <td className="library-inspector-data-value">
                  {render
                    ? render(get(record, path), record)
                    : get(record, path, "")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  renderExtraItems = extraItems => {
    if (!Array.isArray(extraItems)) extraItems = [extraItems];
    return (
      <React.Fragment>
        {extraItems.map((item, i) => (
          <div className="library-inspector-group" key={i}>
            {item}
          </div>
        ))}
      </React.Fragment>
    );
  };
  renderExtraItemsTop = () => {
    const { record, renderExtraItemsTop } = this.props;
    return this.renderExtraItems(renderExtraItemsTop(record, this.props));
  };

  renderExtraItemsBottom = () => {
    const { record, renderExtraItemsBottom } = this.props;
    return this.renderExtraItems(renderExtraItemsBottom(record, this.props));
  };

  showUpdateTags = () => {
    const { record } = this.props;
    showDialog({
      modalType: "TAG_ITEM",
      modalProps: {
        records: [record]
      }
    });
  };

  renderTags = () => {
    const { record } = this.props;
    if (record && tagModels.includes(record.__typename)) {
      const sortedTaggedItems = getSortedTaggedItems(record);

      return (
        <div>
          <Tooltip content="Update tags">
            <Button
              icon="tag"
              className="tg-tag-record-button"
              minimal
              intent="warning"
              onClick={this.showUpdateTags}
            />
          </Tooltip>
          <div style={{ marginTop: 8, marginBottom: 10 }}>
            {sortedTaggedItems.map(({ id, tag, tagOption }) => {
              return (
                <div key={id} style={{ marginBottom: 8 }}>
                  <Tag
                    {...(tagOption || tag)}
                    name={`${tag.name +
                      (tagOption ? `: ${tagOption.name}` : "")}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  renderExtendedProperties() {
    const { record, libraryFragment } = this.props;
    const model = record.__typename;
    if (extendedPropertyModels.includes(model)) {
      return (
        <div style={{ marginBottom: 15 }}>
          <RecordExtendedProperties
            libraryFragment={libraryFragment}
            recordId={record.id}
            model={model}
            withEdit
          />
        </div>
      );
    }
  }

  renderBody = () => {
    const {
      record,
      withDescription,
      withSequence,
      withSharingInformation,
      renderExtraItemsTop,
      renderExtraItemsBottom
    } = this.props;

    if (!record) return "Select a record to view its details.";

    return (
      <React.Fragment>
        {!!renderExtraItemsTop && this.renderExtraItemsTop()}
        {!!withDescription && this.renderDescription()}
        {!!withSharingInformation && this.renderSharingInformation()}
        {this.renderFields()}
        {this.renderTags()}
        {this.renderExtendedProperties()}
        {!!renderExtraItemsBottom && this.renderExtraItemsBottom()}
        {!!withSequence && this.renderSequence()}
      </React.Fragment>
    );
  };

  render() {
    const { record } = this.props;
    return (
      <div className="library-inspector">
        <h2 style={{ marginLeft: 10 }} className="record-title-name">
          {get(record, "name")}
        </h2>
        <div className="library-inspector-body">{this.renderBody()}</div>
      </div>
    );
  }
}

export default LibraryInspector;
