/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { ReactSelectField } from "@teselagen/ui";
import GenericSelect from "../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../src-shared/utils/libraryColumns";
import { assemblyReportEntityFilter } from "../utils";

const materialFragment = [
  "material",
  "id name materialTypeCode materialType { code name }"
];

const materialSchema = [
  {
    path: "name"
  },
  {
    path: "materialType.name",
    displayName: "Type"
  },
  dateModifiedColumn
];
// entityType
function SelectJ5MaterialsOrPcrReactions({
  j5Reports = [],
  entityType = "material",
  modelNameToSchema = {},
  modelNameToFragment = {},
  modelNameToAdditionalFragment = {}
}) {
  const j5MaterialFilter = useCallback(
    (props, qb, currentParams) => {
      assemblyReportEntityFilter({
        j5Reports,
        entityFilter: currentParams.entityFilter,
        onMaterial: true,
        qb
      });
    },
    [j5Reports]
  );
  const j5PcrReactionFilter = useCallback(
    (props, qb) => {
      qb.whereAll({
        j5ReportId: j5Reports.map(j5Report => j5Report.id)
      });
    },
    [j5Reports]
  );

  const modelNameToFilter = {
    // separate filter
    j5PcrReaction: j5PcrReactionFilter,
    material: j5MaterialFilter
  };

  const tableSchema = modelNameToSchema[entityType];
  return (
    <GenericSelect
      key={entityType}
      isRequired
      name={entityType === "material" ? "j5Materials" : "j5PcrReactions"}
      isMultiSelect
      nameOverride={entityType === "material" ? "Materials" : "Reactions"}
      schema={modelNameToSchema[entityType] || materialSchema}
      fragment={modelNameToFragment[entityType] || materialFragment}
      additionalDataFragment={modelNameToAdditionalFragment[entityType]}
      tableParamOptions={{ additionalFilter: modelNameToFilter[entityType] }}
      getButtonText={val => {
        let actionPrefix = "Select";
        if (val?.length) {
          actionPrefix = "Change";
        }
        if (entityType === "material") {
          return `${actionPrefix} DNA Assembly Materials`;
        } else {
          return `${actionPrefix} DNA Assembly PCR Reactions`;
        }
      }}
      postSelectDTProps={
        tableSchema && {
          formName: "j5EntitiesTable",
          schema: tableSchema
        }
      }
      additionalTableProps={
        entityType === "material" && {
          enhancedChildren: ({ tableParams }) => {
            const { currentParams, setNewParams } = tableParams;
            return (
              <ReactSelectField
                name="entityFilter"
                label="Entity Type"
                onFieldSubmit={val => {
                  setNewParams({
                    ...currentParams,
                    // clear page so we don't get into a weird non-existing page state
                    page: undefined,
                    entityFilter: val
                  });
                }}
                options={[
                  {
                    label: "Input Sequences",
                    value: "j5InputSequence"
                  },
                  {
                    label: "Primary Templates",
                    value: "primaryTemplate"
                  },
                  { label: "Primers", value: "primer" },
                  {
                    label: "PCR Products",
                    value: "pcrProductSequence"
                  },
                  {
                    label: "Assembly Pieces",
                    value: "j5AssemblyPiece"
                  },
                  { label: "Constructs", value: "j5RunConstruct" }
                ]}
              />
            );
          }
        }
      }
    />
  );
}

export default SelectJ5MaterialsOrPcrReactions;
