/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types, applySnapshot } from "mobx-state-tree";
import { withPropsStore } from "./withProps";

export const modalStore = types
  .model("modalStore", {
    open: types.optional(types.boolean, false),
    mobxProps: types.optional(types.frozen(), {}),
    props: types.optional(withPropsStore, {}),
    type: types.optional(types.string, ""),
    stack: types.optional(types.frozen(), []),
    noCancel: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    intent: types.optional(types.string, "PRIMARY"),
    secondaryIntent: types.optional(types.string, "DANGER"),
    secondaryClassName: types.optional(types.string, ""),
    secondaryText: types.optional(types.string, "Cancel"),
    secondaryAction: types.optional(types.frozen(), {}),
    additionalButtons: types.optional(types.frozen(), {}),
    text: types.optional(types.string, "Confirm"),
    className: types.optional(types.string, ""),
    disabled: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
    submitting: types.optional(types.boolean, false),
    onClick: types.optional(types.frozen(), {}),
    withOutActions: types.optional(types.boolean, false)
  })
  .actions(self => ({
    hideModal(
      // extraProps are used to pass props from the current modal to the previous one
      extraProps
    ) {
      // removes the current modal from stack.
      self.stack = self.stack.slice(0, -1);

      // If there are modals left in the stack, apply them
      if (self.stack.length > 0) {
        // gets the last modal from the stack (self.stack has a FILO arrangement for modals).
        const previousModal = self.stack.slice(-1)[0];

        // apply previous modal with updated stack
        applySnapshot(self, {
          ...previousModal.config,
          stack: self.stack
        });

        // if previous modal came with props, apply them too.
        if (previousModal.props || extraProps) {
          self.props.set({ ...previousModal.props, ...extraProps });
        }
      } else {
        applySnapshot(self, {});
      }
    },
    openModal(config, props) {
      applySnapshot(self, {
        ...config,
        // This supports opening modals from other modals and then going back to the previous modal.
        stack: [...self.stack, { config, props }]
      });
      self.props.set(props);
    }
  }));
