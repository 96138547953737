/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { digestPartFragment } from "../partFragment";
import { isNode } from "browser-or-node";

// We need these fragments in order to be able to load different things
// depending on whether we are on the server or not.

export const designSequenceFragment = isNode
  ? gql`
      fragment designSequenceFragment on sequence {
        id
        name
        description
        circular
        sequence
        size
        externalReferenceSystem
        externalReferenceId
        externalReferenceType
        externalReferenceUrl
        designMaterialAvailabilityView {
          id
          isAvailable
        }
        sequenceFragments {
          id
          index
          fragment
        }
        sequenceFeatures {
          id
          name
          start
          end
          strand
          type
          locations
        }
      }
    `
  : gql`
      fragment designSequenceFragment on sequence {
        id
        name
        circular
        size
        externalReferenceSystem
        externalReferenceId
        externalReferenceType
        externalReferenceUrl
      }
    `;

export const designPartFragment = gql`
  fragment designPartFragment on part {
    id
    name
    start
    strand
    preferred3PrimeOverhangs
    preferred5PrimeOverhangs
    end
    sequence {
      ...designSequenceFragment
    }
    ...digestPartFragment
    taggedItems {
      id
      tag {
        id
        name
        color
      }
      tagOption {
        id
        name
        tagId
        color
      }
    }
  }
  ${designSequenceFragment}
  ${digestPartFragment}
`;

export const designPartsetFragment = gql`
  fragment designPartsetFragment on partset {
    id
    name
    numParts
    partsetParts {
      id
      isDeactivated
      part {
        ...designPartFragment
      }
    }
  }
  ${designPartFragment}
`;

export const constructAnnotationFragment = gql`
  fragment constructAnnotationFragment on constructAnnotation {
    id
    cardId
    name
    notes
    annotationType
    annotationTemplate
    type
    leftBoundaryBinId
    rightBoundaryBinId
  }
`;
