/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { sortBy } from "lodash";
import shortid from "shortid";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import reactionMapFragment from "../../../graphql/fragments/reactionMapFragment";
import SelectInputs from "./SelectInputs";
import { proteinPurificationPlateFragment } from "./fragments";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class ProteinPurification extends Component {
  onSubmit = async values => {
    const {
      reactionMap,
      containerArrays = [],
      volume,
      volumetricUnitCode
    } = values;
    try {
      const collectionNewAliquots = [];
      const columnAliquotsToDelete = [];
      const collectionUpdatedAliquotContainers = [];
      const updatedCollectionContainerArrays = [];
      const updatedColumnContainerArrays = [];
      const extendedValueUpdates = [];
      const columnContainerArrayUpdates = [];

      const keyedReactions = {};
      reactionMap.reactions.forEach(reaction => {
        keyedReactions[reaction.reactionInputs[0].inputMaterial.id] = reaction;
      });
      containerArrays.forEach(containerArray => {
        const cleanValue = containerArray.extendedValues.find(
          ev => ev.extendedProperty.name === "Clean"
        );
        const usageValue = containerArray.extendedValues.find(
          ev => ev.extendedProperty.name === "Number of Uses"
        );

        extendedValueUpdates.push({
          id: cleanValue.id,
          value: false
        });
        extendedValueUpdates.push({
          id: usageValue.id,
          value: usageValue.value + 1
        });
        updatedCollectionContainerArrays.push({
          id: containerArray.collectionPlateId,
          __typename: "containerArray"
        });
        updatedColumnContainerArrays.push({
          id: containerArray.id,
          __typename: "containerArray"
        });
        columnContainerArrayUpdates.push({
          id: containerArray.id,
          collectionPlateId: null
        });
        const sortedColumnAliquotContainers = sortBy(
          containerArray.aliquotContainers,
          ["rowPosition", "columnPosition"]
        );
        const sortedCollectionAliquotContainers = sortBy(
          containerArray.collectionPlate.aliquotContainers,
          ["rowPosition", "columnPosition"]
        );

        sortedColumnAliquotContainers.forEach((ac, i) => {
          if (ac.aliquot) {
            columnAliquotsToDelete.push(ac.aliquot.id);

            if (ac.aliquot.sample.materialId) {
              const reaction = keyedReactions[ac.aliquot.sample.materialId];

              const cid = shortid();
              collectionNewAliquots.push({
                cid,
                volume,
                volumetricUnitCode,
                aliquotType: "sample-aliquot",
                sample: {
                  name: reaction.reactionOutputs[0].outputMaterial.name,
                  sampleTypeCode: "REGISTERED_SAMPLE",
                  materialId: reaction.reactionOutputs[0].outputMaterial.id
                }
              });
              collectionUpdatedAliquotContainers.push({
                id: sortedCollectionAliquotContainers[i].id,
                aliquotId: `&${cid}`
              });
            }
          }
        });
      });

      await safeUpsert("extendedValue", extendedValueUpdates, {
        excludeResults: true
      });
      const createdAliquots = await safeUpsert(
        ["aliquot", "id sampleId"],
        collectionNewAliquots
      );
      const sampleUpdates = [];
      createdAliquots.forEach(aliquot =>
        sampleUpdates.push({
          id: aliquot.sampleId,
          sampleAliquotId: aliquot.id
        })
      );
      await safeUpsert("sample", sampleUpdates);
      await safeUpsert("aliquotContainer", collectionUpdatedAliquotContainers, {
        excludeResults: true
      });
      await safeUpsert("containerArray", columnContainerArrayUpdates, {
        excludeResults: true
      });
      await safeDelete("aliquot", columnAliquotsToDelete);

      return {
        collectionContainerArrays: updatedCollectionContainerArrays,
        columnContainerArrays: updatedColumnContainerArrays
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating collection plate.");
    }
  };

  validate = values => {
    const { transferVolume, containerArrays = [], reactionMap } = values;
    const errors = {};

    if (!reactionMap) {
      errors.reactionMap = "Must select a protein purification reaction map.";
    } else {
      const badReaction = reactionMap.reactions.some(reaction => {
        return (
          reaction.reactionInputs.length > 1 ||
          reaction.reactionInputs.some(input => !input.inputMaterial)
        );
      });
      if (badReaction) {
        errors.reactionMap =
          "This tool only accepts reactions a with single input.";
      } else {
        const badOutput = reactionMap.reactions.some(reaction => {
          return (
            reaction.reactionOutputs.length > 1 ||
            reaction.reactionOutputs.some(output => !output.outputMaterial)
          );
        });
        if (badOutput) {
          errors.reactionMap =
            "This tool only accepts reactions with a single output.";
        }
      }
    }
    if (!transferVolume) {
      errors.transferVolume =
        "Please specify the amount of protein material to be added to the collection plate.";
    }
    if (containerArrays.length < 1) {
      errors.containerArrays = "Must select at least one nickel column plate.";
    }

    if (containerArrays.length) {
      const invalidPlates = [];
      const dirtyPlates = [];
      containerArrays.forEach(containerArray => {
        const hasCleanProp = containerArray.extendedValues.some(
          ev => ev.extendedProperty.name === "Clean"
        );
        const hasUsageProp = containerArray.extendedValues.some(
          ev => ev.extendedProperty.name === "Number of Uses"
        );
        const cleanProp = containerArray.extendedValues.find(
          ev => ev.extendedProperty.name === "Clean"
        );
        const isClean = cleanProp && cleanProp.value === true;
        if (!hasCleanProp || !hasUsageProp) {
          invalidPlates.push(containerArray.name);
        }
        if (!isClean) {
          dirtyPlates.push(containerArray.name);
        }
      });

      if (reactionMap && containerArrays.length > 0 && !errors.reactionMap) {
        const extraMaterials = [];
        const missingMaterialMap = {};
        reactionMap.reactions.forEach(reaction => {
          reaction.reactionInputs.forEach(input => {
            missingMaterialMap[input.inputMaterial.id] =
              input.inputMaterial.name;
          });
        });
        const materialIds = Object.keys(missingMaterialMap);
        const formulatedSamplePlates = {};
        containerArrays.forEach(containerArray => {
          containerArray.aliquotContainers.forEach(ac => {
            const addMaterial = material => {
              if (materialIds.includes(material.id)) {
                delete missingMaterialMap[material.id];
              } else {
                extraMaterials.push({
                  name: material.name,
                  plate: containerArray.name,
                  well: getAliquotContainerLocation(ac)
                });
              }
            };

            if (ac.aliquot && ac.aliquot.sample) {
              const material = ac.aliquot.sample.material;
              if (material) {
                addMaterial(material);
              } else if (
                ac.aliquot.sample.sampleTypeCode === "FORMULATED_SAMPLE"
              ) {
                formulatedSamplePlates[containerArray.name] = true;
              }
            }
          });
        });

        const missingMaterials = Object.values(missingMaterialMap);
        const platesWithFormulatedSamples = Object.keys(formulatedSamplePlates);
        if (platesWithFormulatedSamples.length > 0) {
          errors.containerArrays = `Thes plates have formulated samples and cannot be used: ${platesWithFormulatedSamples.join(
            ", "
          )}.`;
        } else if (missingMaterials.length > 0) {
          errors.containerArrays = `The selected plates are missing the following\
           materials: ${missingMaterials.join(", ")}.`;
        } else if (extraMaterials.length > 0) {
          errors.containerArrays = `The following materials were not found in the selected \
          reaction map: ${extraMaterials.map(
            (material, i) =>
              `${material.plate} (${material.well}): ${material.name}${
                i === extraMaterials.length - 1 ? "" : ", "
              }`
          )}.`;
        }
      }

      if (invalidPlates.length > 0) {
        errors.containerArrays = `The following plates are missing necessary extended\
         properties: ${invalidPlates.join(", ")}.`;
      }
      if (dirtyPlates.length > 0) {
        errors.containerArrays = `The following nickel column plates must be cleaned before \
        selection: ${dirtyPlates.join(", ")}.`;
      }
    }
    return errors;
  };

  render() {
    const {
      toolSchema,
      isToolIntegrated,
      toolIntegrationProps,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Inputs",
        Component: SelectInputs,
        withCustomFooter: true
      }
    ];
    return (
      <StepForm
        steps={steps}
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        validate={this.validate}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(proteinPurificationPlateFragment),
  withWorkflowInputs(reactionMapFragment, { singular: true })
)(ProteinPurification);
