/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import containerArrayTypeFragment from "../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import strainRecordFragment, {
  growthConditionFragment
} from "../../../../tg-iso-shared/src/fragments/strainRecordFragment";
import sequenceFragment from "../../../../tg-iso-shared/src/fragments/sequenceFragment";
import { functionProteinRecordViewSubUnitsFragment } from "../../../../tg-iso-shared/src/fragments/functionalProteinUnitRecordFragment";

const aliquotRecordAdditiveMaterialFragment = gql`
  fragment aliquotRecordAdditiveMaterialFragment on additiveMaterial {
    id
    name
    isDry
    additiveType {
      code
      name
    }
  }
`;

export const aliquotRecordAdditiveFragment = gql`
  fragment aliquotRecordAdditiveFragment on additive {
    id
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
    lot {
      id
      name
      additiveMaterial {
        ...aliquotRecordAdditiveMaterialFragment
      }
    }
    additiveMaterial {
      ...aliquotRecordAdditiveMaterialFragment
    }
  }
  ${aliquotRecordAdditiveMaterialFragment}
`;

export const aliquotAndLocationFragment = gql`
  fragment aliquotAndLocationFragment on aliquot {
    id
    sample {
      id
      name
    }
    aliquotContainer {
      id
      rowPosition
      columnPosition
      aliquotContainerType {
        code
        name
      }
      barcode {
        id
        barcodeString
      }
      containerArray {
        id
        barcode {
          id
          barcodeString
        }
        name
      }
    }
  }
`;

export default gql`
  fragment aliquotRecordFragment on aliquot {
    id
    user {
      id
      username
    }
    aliquotType
    aliquotContainer {
      id
      name
      aliquotId
      columnPosition
      rowPosition
      barcode {
        id
        barcodeString
      }
      aliquotContainerType {
        code
        name
        isTube
        maxVolume
        volumetricUnitCode
      }
      containerArrayId
      containerArray {
        id
        name
        containerArrayType {
          ...containerArrayTypeFragment
        }
        aliquotContainers {
          id
          rowPosition
          columnPosition
          additives {
            id
          }
          aliquot {
            id
            isDry
            volume
            mass
          }
        }
      }
    }
    isDry
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    molarity
    molarityUnitCode
    cellCount
    cellConcentration
    cellConcentrationUnitCode
    replicateParentAliquot {
      id
      aliquotType
      aliquotContainer {
        id
        columnPosition
        rowPosition
        containerArray {
          id
          name
        }
      }
    }
    sampleAliquotSamples {
      id
      name
    }
    sample {
      id
      sampleTypeCode
      sampleType {
        code
        name
      }
      status
      name
      sampleAliquotId
      createdAt
      updatedAt
      aliquots {
        id
        aliquotType
        aliquotContainer {
          id
          rowPosition
          columnPosition
          containerArray {
            id
            name
          }
        }
      }
      material {
        id
        name
        materialTypeCode
        strain {
          ...strainRecordFragment
        }
        biosafetyLevelOverwrite {
          code
          name
        }
        targetOrganismClassOverwrite {
          id
          name
        }
        growthConditionOverwrite {
          ...growthConditionFragment
        }
        microbialMaterialMicrobialMaterialPlasmids {
          id
          microbialMaterialId
          polynucleotideMaterial {
            id
            name
            polynucleotideMaterialSequence {
              ...sequenceFragment
            }
          }
        }
        cellCultureCellCulturePlasmids {
          id
          cellCultureId
          polynucleotideMaterial {
            id
            polynucleotideMaterialSequence {
              ...sequenceFragment
            }
          }
        }
        microbialMaterialMicrobialMaterialInductionMethods {
          id
          inductionMethod {
            id
            name
          }
        }
        cellCultureCellCultureInductionMethods {
          id
          inductionMethod {
            id
            name
          }
        }
        polynucleotideMaterialSequence {
          ...sequenceFragment
        }
        functionalProteinUnit {
          ...functionProteinRecordViewSubUnitsFragment
        }
      }
      sampleFormulations {
        id
        materialCompositions {
          id
          materialId
          material {
            id
            name
          }
        }
      }
    }
  }
  ${sequenceFragment}
  ${containerArrayTypeFragment}
  ${growthConditionFragment}
  ${strainRecordFragment}
  ${functionProteinRecordViewSubUnitsFragment}
`;
