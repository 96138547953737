/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { get, keyBy } from "lodash";
import { SubmissionError } from "redux-form";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import SelectRecipientCells from "./Steps/SelectRecipientCells";
import SelectDonorCells from "./Steps/SelectDonorCells";
import { plateFragment, tubeFragment } from "./fragments";
import {
  checkForDuplicateMicrobialMaterials,
  getTransformationMicrobialMaterial
} from "../../../utils";
import { getAliquotMaterialList } from "../../../utils/plateUtils";

import ReviewWorklist from "../AliquotRearrayTool/Steps/ReviewWorklist";
import {
  standardizeVolume,
  volumeRender
} from "../../../../src-shared/utils/unitUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

const previewWorklistSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source Plate",
      path: "sourceAliquotContainer.containerArray.name"
    },
    {
      displayName: "Source Position",
      path: "sourceAliquotContainer",
      render: value => getAliquotContainerLocation(value)
    },
    {
      displayName: "Destination Plate / Tube",
      render: (_, r) => {
        if (r.destinationPlate) {
          return r.destinationPlate.name;
        } else {
          const barcode = get(
            r,
            "destinationAliquotContainer.barcode.barcodeString"
          );
          const name = r.destinationAliquotContainer.name;
          if (name && barcode) {
            return `${name} (${barcode})`;
          } else {
            return name || barcode;
          }
        }
      }
    },
    {
      displayName: "Destination Position",
      path: "destinationAliquotContainer",
      render: value => getAliquotContainerLocation(value)
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    }
  ]
};

class BacterialConjugationTool extends React.Component {
  onSubmit = async values => {
    const {
      worklistName,
      reactionMapName,
      worklist,
      microbialMaterial: recipientMicrobialMaterial,
      destinationPlates = [],
      intermediateContainer,
      containerArrays = [],
      generateBarcodes,
      destinationPlateTypes,
      aliquotContainers = []
    } = values;

    try {
      let materialsToUpsert = [];
      const microbialMaterialPlasmidsToCreate = [];
      const reformattedIntermediateContainer = {
        ...intermediateContainer,
        aliquotContainers: intermediateContainer.aliquotContainers.map(ac => {
          const cleanedAc = { ...ac };
          delete cleanedAc.containerArray;
          return cleanedAc;
        })
      };

      const platesToCreate = destinationPlates.map((plate, index) => {
        return {
          ...plate,
          aliquotContainers: generateContainerArray(
            plate.aliquotContainers,
            destinationPlateTypes[index].containerFormat,
            {
              aliquotContainerTypeCode:
                destinationPlateTypes[index].aliquotContainerTypeCode
            }
          )
        };
      });

      const reactionsWithInputsAndOutputs = [];
      const sourceAliquotContainers = [...aliquotContainers];
      containerArrays.forEach(plate => {
        plate.aliquotContainers.forEach(aliquotContainer => {
          if (aliquotContainer.aliquot) {
            sourceAliquotContainers.push(aliquotContainer);
          }
        });
      });

      const createdReactionForMaterialMap = {};

      sourceAliquotContainers.forEach(aliquotContainer => {
        const donorMicrobialMaterials = getAliquotMaterialList(
          aliquotContainer.aliquot
        );

        donorMicrobialMaterials.forEach(donorMicrobialMaterial => {
          if (!createdReactionForMaterialMap[donorMicrobialMaterial.id]) {
            createdReactionForMaterialMap[donorMicrobialMaterial.id] = true;

            const outputMaterial = getTransformationMicrobialMaterial(
              donorMicrobialMaterial,
              recipientMicrobialMaterial
            );
            materialsToUpsert.push(outputMaterial);
            reactionsWithInputsAndOutputs.push({
              name: "reaction " + (reactionsWithInputsAndOutputs.length + 1),
              ...getReactionInputsAndOutputs({
                outputMaterialId: `&${outputMaterial.cid}`,
                inputMaterials: [
                  recipientMicrobialMaterial,
                  donorMicrobialMaterial
                ]
              })
            });
          }
        });
      });

      // check for duplicate materials
      if (materialsToUpsert.length) {
        const cidKeyedMaterialMap = keyBy(materialsToUpsert, m => `&${m.cid}`);
        const duplicateMap = await checkForDuplicateMicrobialMaterials(
          materialsToUpsert
        );
        materialsToUpsert = [];
        reactionsWithInputsAndOutputs.forEach(reaction => {
          const outputMaterial =
            cidKeyedMaterialMap[reaction.reactionOutputs[0].outputMaterialId];

          const existingMaterial = duplicateMap.get(outputMaterial);
          if (existingMaterial) {
            reaction.reactionOutputs[0] = {
              outputMaterialId: existingMaterial.id
            };
          } else {
            materialsToUpsert.push(outputMaterial);
          }
        });
      }

      const reactionMapToCreate = {
        name: reactionMapName,
        reactionTypeCode: "CONJUGATION",
        reactions: reactionsWithInputsAndOutputs
      };

      const worklistToUpsert = { ...worklist };
      worklistToUpsert.name = worklistName;
      worklistToUpsert.worklistTransfers = worklistToUpsert.worklistTransfers.map(
        _transfer => {
          const transfer = { ..._transfer };
          delete transfer.sourceAliquotContainer;
          delete transfer.destinationAliquotContainer;
          delete transfer.destinationPlate;
          return transfer;
        }
      );
      await safeUpsert(
        "microbialMaterialPlasmid",
        microbialMaterialPlasmidsToCreate
      );
      await safeUpsert("material", materialsToUpsert);

      const createdContainerArrays = await safeUpsert(
        "containerArray",
        platesToCreate
      );

      if (generateBarcodes) {
        await addBarcodesToRecords(createdContainerArrays);
      }
      await safeUpsert("containerArray", reformattedIntermediateContainer);
      const [createdReactionMap] = await safeUpsert(
        "reactionMap",
        reactionMapToCreate
      );
      worklistToUpsert.worklistReactionMaps = [
        {
          reactionMapId: createdReactionMap.id
        }
      ];
      const [createdWorklist] = await safeUpsert("worklist", worklistToUpsert);
      window.toastr.success("Successfully created worklist");
      return {
        reactionMap: createdReactionMap,
        worklist: createdWorklist
      };
    } catch (error) {
      console.error("error:", error);
      const message = error.message || "Error creating worklist.";
      throw new SubmissionError({
        _error: message
      });
    }
  };

  validate = values => {
    const {
      microbialMaterial,
      containerArrays = [],
      aliquotContainers = [],
      intermediateContainerType,
      microbialTransferVolume,
      microbialTransferVolumeUnitCode
    } = values;
    const errors = {};
    const selectedPlate = containerArrays && containerArrays.length;
    const selectedTube = aliquotContainers && aliquotContainers.length;

    if (!selectedPlate && !selectedTube) {
      errors.containerArrays = "Please select a plate or a tube.";
      errors.aliquotContainers = "Please select a plate or a tube.";
    }

    if (microbialMaterial && !microbialMaterial.strain) {
      errors.microbialMaterial =
        "Please choose a microbial material that is linked to a strain.";
    }

    if (
      intermediateContainerType &&
      microbialTransferVolume &&
      !errors.intermediateContainerType &&
      !errors.microbialTransferVolume
    ) {
      const newDeadVolume =
        get(intermediateContainerType, "aliquotContainerType.deadVolume") || 0;
      const standardizedDeadVolume = standardizeVolume(
        newDeadVolume,
        intermediateContainerType.aliquotContainerType.deadVolumetricUnitCode ||
          "uL",
        true
      );
      const standardizedTransferVolume = standardizeVolume(
        microbialTransferVolume,
        microbialTransferVolumeUnitCode,
        true
      );
      const standardizedMaxWellVolume = standardizeVolume(
        intermediateContainerType.aliquotContainerType.maxVolume,
        intermediateContainerType.aliquotContainerType.volumetricUnitCode,
        true
      );
      const totalMaxVolume = standardizedMaxWellVolume.times(
        intermediateContainerType.containerFormat.quadrantSize
      );
      const totalDeadVolume = standardizedDeadVolume.times(
        intermediateContainerType.containerFormat.quadrantSize
      );
      let numAliquotContainersToPrep = aliquotContainers.length;
      containerArrays.forEach(plate => {
        plate.aliquotContainers.forEach(ac => {
          if (ac.aliquot) numAliquotContainersToPrep++;
        });
      });

      const totalTransferVolume = standardizedTransferVolume.times(
        numAliquotContainersToPrep
      );

      if (
        standardizedDeadVolume
          .plus(standardizedTransferVolume)
          .gt(standardizedMaxWellVolume)
      ) {
        errors.microbialTransferVolume =
          "Transfer volume exceeds intermediate container well volume.";
      }

      if (totalDeadVolume.plus(totalTransferVolume).gt(totalMaxVolume)) {
        errors.intermediateContainerType =
          "Selected container type cannot support specified transfer volume. Please select a container type with greater volume capacity.";
      }
    }

    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select Donor Cells",
        Component: SelectDonorCells,
        withCustomFooter: true
      },
      {
        title: "Select Recipient Cells",
        Component: SelectRecipientCells,
        withCustomFooter: true
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true,
        props: {
          toolSchema,
          schema: previewWorklistSchema,
          reactionMapMessage:
            "Give a name for the reaction map. The reaction map describes the reactions that transforms the input materials to output materials."
        }
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plateFragment),
  withWorkflowInputs(tubeFragment)
)(BacterialConjugationTool);
