/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Classes } from "@blueprintjs/core";
import {
  DataTable,
  DialogFooter,
  InjectedWrapDialogProps,
  wrapDialog
} from "@teselagen/ui";
import { openInNewTab } from "../../../../src-shared/utils/generalUtils";
import modelNameToLink from "./../../../../src-shared/utils/modelNameToLink";

export type UploadDnaSequencesOutput = {
  allSeqIds: string[];
  duplicateSequences: {
    name: string;
    duplicateFound: {
      __typename: string;
      id: string;
      name: string;
    };
  }[];
};

type Props = {
  repairTemplateSequenceDuplicates: UploadDnaSequencesOutput["duplicateSequences"];
  outputGenomicRegionDuplicates?: UploadDnaSequencesOutput["duplicateSequences"];
};

const DuplicatesDialog = ({
  repairTemplateSequenceDuplicates,
  outputGenomicRegionDuplicates,
  hideModal
}: InjectedWrapDialogProps<Props>) => {
  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        {!!repairTemplateSequenceDuplicates.length && (
          <>
            <h6 className="header-margin">
              Duplicated sequence for repair template detected and will not be
              imported (aliases will be created where needed).
            </h6>
            <DataTable
              isSimple
              maxHeight={300}
              schema={[
                {
                  displayName: "Sequence Being created",
                  path: "name"
                },
                {
                  displayName: "Sequence in Inventory",
                  path: "duplicateFound.name"
                }
              ]}
              formName="repairTemplateSequenceForm"
              entities={repairTemplateSequenceDuplicates}
              onDoubleClick={(
                record: UploadDnaSequencesOutput["duplicateSequences"][number]
              ) => {
                const itemUrl = modelNameToLink(
                  record.duplicateFound.__typename,
                  record.duplicateFound.id
                );
                openInNewTab(itemUrl);
              }}
            />
            <br />
          </>
        )}
        {!!outputGenomicRegionDuplicates?.length && (
          <>
            <h6 className="header-margin">
              Duplicated genomic region detected and will not be imported{" "}
              (aliases will be created where needed).
            </h6>
            <DataTable
              isSimple
              maxHeight={300}
              schema={[
                {
                  displayName: "Sequence in Upload",
                  path: "name"
                },
                {
                  displayName: "Sequence in Inventory",
                  path: "duplicateFound.name"
                }
              ]}
              formName="outputGenomicRegionForm"
              entities={outputGenomicRegionDuplicates}
              onDoubleClick={(
                record: UploadDnaSequencesOutput["duplicateSequences"][number]
              ) => {
                const itemUrl = modelNameToLink(
                  record.duplicateFound.__typename,
                  record.duplicateFound.id
                );
                openInNewTab(itemUrl);
              }}
            />
            <br />
          </>
        )}
      </div>
      <DialogFooter noCancel onClick={hideModal} text="OK" />
    </div>
  );
};

export default wrapDialog<Props>()(DuplicatesDialog);
