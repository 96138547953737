/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { AppInner } from "../../components/AppInner";
import { Provider as MobxProvider, inject, observer } from "mobx-react";
import { getActiveLabId } from "@teselagen/auth-utils";

const InitMobx = ({
  Alert,
  appHotkeySets,
  Helper,
  mobXStore,
  mobXModalComponents,
  modalComponents
}) => {
  let App = ({ mainStore }) => {
    //we wrap AppInner in this simple wrapper because otherwise the hooks in AppInner don't play well together with the mobx wrappers around App
    return (
      <AppInner
        Alert={Alert}
        appHotkeySets={appHotkeySets}
        Helper={Helper}
        mainStore={mainStore}
        modalComponents={modalComponents}
        mobXModalComponents={mobXModalComponents}
      />
    );
  };

  if (mobXStore) {
    App = inject("mainStore")(observer(App));
  }
  let app = <App />;

  const mainStore = useMemo(() => {
    if (mobXStore) {
      return mobXStore.create({
        lab: {
          activeLabIdValue: getActiveLabId()
        }
      });
    }
  }, [mobXStore]);

  if (mobXStore) {
    app = <MobxProvider mainStore={mainStore}>{app}</MobxProvider>;
  }

  return app;
};

export { InitMobx };
