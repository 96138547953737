/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { isEmpty } from "lodash";
import { InputField, Loading, DialogFooter, wrapDialog } from "@teselagen/ui";
import shortid from "shortid";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { compose, withProps } from "recompose";

import gql from "graphql-tag";
import withQuery from "../../../../src-shared/withQuery";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import stripFields from "../../../../src-shared/utils/stripFields";
import CreateOrGenerateBarcodeField from "../../CreateOrGenerateBarcodeField";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { asyncValidateBarcodeHandler } from "../../../../src-shared/utils/formUtils";

class AddContainerDialog extends Component {
  state = {
    loadingInitialValues: false
  };

  onSubmit = async values => {
    const {
      initialValues = {},
      hideModal,
      history,
      refetch,
      partialParentRecord,
      forcedPosition
    } = this.props;
    const editing = !!initialValues.id;

    const finishUpsert = async placementInfo => {
      const valuesToUpsert = {
        id: values.id,
        name: values.name,
        label: values.label || null
      };

      if (values.id) {
        const barcodeWasChanged =
          values.barcode &&
          values.barcode.barcodeString !== initialValues.barcode?.barcodeString;
        if (barcodeWasChanged) {
          await safeUpsert("barcode", {
            id: initialValues.barcode?.id,
            barcodeString: values.barcode.barcodeString,
            containerId: values.id
          });
        }
      } else {
        if (!values.generateBarcode && values.userAddedBarcode) {
          valuesToUpsert.barcode = {
            barcodeString: values.userAddedBarcode
          };
        }
      }

      if (placementInfo || partialParentRecord) {
        // reset these fields first
        valuesToUpsert.path = null;
        valuesToUpsert.locationId = null;
        valuesToUpsert.equipmentId = null;
        valuesToUpsert.equipmentPositionId = null;

        const destinationLocation =
          partialParentRecord || Object.values(placementInfo)[0];
        if (destinationLocation) {
          if (destinationLocation.__typename === "container") {
            const container = await safeQuery(
              [
                "container",
                "id path equipmentId equipmentPositionId locationId"
              ],
              {
                variables: {
                  id: destinationLocation.id
                }
              }
            );
            valuesToUpsert.path = (container.path || "") + "/" + container.id;
            valuesToUpsert.equipmentId = container.equipmentId;
            valuesToUpsert.equipmentPositionId = container.equipmentPositionId;
            valuesToUpsert.locationId = container.locationId;
          } else if (destinationLocation.__typename === "equipmentItem") {
            valuesToUpsert.equipmentId = destinationLocation.id;
          } else if (destinationLocation.__typename === "equipmentPosition") {
            valuesToUpsert.equipmentPositionId = destinationLocation.id;
          }
        }
      }

      // Add the container default positions.
      if (!editing) {
        valuesToUpsert.containerTypeCode = values.containerType.code;
        valuesToUpsert.containerPositions = stripFields(
          values.containerType.containerDefaultPositions,
          ["id", "__typename"]
        );
      }

      const [container] = await safeUpsert(
        ["container", "id name label barcode { id barcodeString }"],
        valuesToUpsert
      );
      if (values.generateBarcode && !values.id) {
        await addBarcodesToRecords(container);
      }
      if (refetch) {
        await refetch();
      } else {
        history.push(`/containers/${container.id}`);
      }
    };

    if (!forcedPosition) {
      showDialog({
        modalType: "MoveRecordsToEquipmentDialog",
        modalProps: {
          finishPlacement: finishUpsert,
          recordsToMove: [
            {
              id: shortid(),
              name: values.name,
              __typename: "container"
            }
          ]
        }
      });
    } else {
      try {
        await finishUpsert();
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error(
          `Error ${editing ? "editing" : "updating"} container`
        );
      }
    }
  };

  renderForm() {
    const {
      handleSubmit,
      submitting,
      hideModal,
      initialValues = {},
      forcedPosition,
      asyncValidating
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" isRequired />
          <InputField name="label" label="Label" />
          <CreateOrGenerateBarcodeField
            initialValues={initialValues}
            asyncValidating={asyncValidating}
          />
          {!initialValues.id && (
            <GenericSelect
              {...{
                name: "containerType",
                idAs: "code",
                isRequired: true,
                asReactSelect: true,
                label: "Container Type",
                fragment: ["containerType", "code name"],
                additionalDataFragment: [
                  "containerType",
                  /* GraphQL */ `
                    {
                      code
                      name
                      containerDefaultPositions {
                        id
                        name
                        label
                        index
                      }
                    }
                  `
                ]
              }}
            />
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          loading={submitting}
          text={forcedPosition ? "Submit" : "Next"}
        />
      </form>
    );
  }

  render() {
    const { containerTypesLoading, locationsLoading } = this.props;

    return containerTypesLoading ||
      locationsLoading ||
      this.state.loadingInitialValues ? (
      <Loading inDialog />
    ) : (
      this.renderForm()
    );
  }
}

const editContainerFragment = gql`
  fragment editContainerFragment on container {
    id
    name
    label
    barcode {
      id
      barcodeString
    }
  }
`;

export default compose(
  wrapDialog({
    getDialogProps: ({ initialValues = {}, containerId }) => {
      const title =
        (initialValues.id || containerId ? "Edit" : "Create New") +
        " Container";
      return {
        title
      };
    }
  }),
  withQuery(editContainerFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.containerId
        }
      };
    },
    skip: props => !props.containerId,
    props: ({ container }) => {
      return {
        initialValues: container
      };
    }
  }),
  withProps(({ initialValues = {}, forcedPosition }) => {
    // can't move if forced position or updating
    return {
      forcedPosition: forcedPosition || initialValues.id
    };
  }),
  reduxForm({
    form: "addContainer",
    asyncBlurFields: ["userAddedBarcode", "barcode.barcodeString"],
    asyncValidate: async (values, dis, ownProps) => {
      const errors = {};
      const options = {
        values,
        ownProps,
        model: "container",
        errors
      };
      await asyncValidateBarcodeHandler(options);
      if (!isEmpty(errors)) {
        throw errors;
      }
    }
  })
)(AddContainerDialog);
