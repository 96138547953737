/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Callout, Icon, Tooltip } from "@blueprintjs/core";
import gql from "graphql-tag";
import { uniq } from "lodash";
import { flatMap } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "@teselagen/ui";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { getReactionMapSchema } from "../../../../utils/reactionMapUtils";
import SampleQCDataTableSelect from "../../SampleQCDataTableSelect";
import pluralize from "pluralize";

export const reactionMapReworkReactionMapFragment = gql`
  fragment reactionMapReworkReactionMapFragment on reactionMap {
    id
    name
    reactionType {
      code
      name
    }
    reactions {
      id
      name
      reactionMapId
      reactionInputs {
        id
        conserved
        inputMaterialId
        inputMaterial {
          id
          name
        }
        inputAdditiveMaterialId
        inputAdditiveMaterial {
          id
          name
        }
      }
      reactionOutputs {
        id
        outputMaterialId
        outputMaterial {
          id
          name
        }
        outputAdditiveMaterialId
        outputAdditiveMaterial {
          id
          name
        }
      }
    }
    createdAt
    updatedAt
  }
`;

export const reactionMapReworkSampleDataTableFragment = gql`
  fragment reactionMapReworkSampleDataTableFragment on dataTable {
    id
    name
    createdAt
    updatedAt
    dataSetId
    dataTableTypeCode
    dataRows {
      id
      rowValues
    }
    dataTableType {
      code
      name
      rowSchema
    }
  }
`;

function SelectInputs(props) {
  const {
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
    toolSchema,
    reactionMaps = [],
    dataTables = [],
    stepFormProps: { change },
    fetchedSampleIds = [],
    Footer,
    footerProps,
    handleSubmit,
    sampleIdToMaterialId = {},
    tableIdToSampleIds = {},
    nextStep
  } = props;

  const [loadingMatInfo, setLoadingMatInfo] = useState(false);
  const materialQueryRef = useRef(0);

  useEffect(() => {
    async function getTableMaterials() {
      if (dataTables.length) {
        materialQueryRef.current++;
        try {
          setLoadingMatInfo(true);
          const sampleIds = [];
          const newTableIdsToSampleIds = {
            ...tableIdToSampleIds
          };
          dataTables.forEach(dt => {
            newTableIdsToSampleIds[dt.id] = [];
            dt.dataRows.forEach(dr => {
              const sampleId = dr.rowValues?.sampleId;
              if (sampleId) {
                sampleIds.push(sampleId);
                newTableIdsToSampleIds[dt.id].push(sampleId);
              }
            });
          });
          change("tableIdToSampleIds", newTableIdsToSampleIds);
          const sampleIdsToGet = sampleIds.filter(
            id => !fetchedSampleIds.includes(id)
          );
          change("fetchedSampleIds", uniq(sampleIds.concat(fetchedSampleIds)));
          const samplesWithMaterialId = await safeQuery(
            ["sample", "id materialId"],
            {
              variables: {
                filter: {
                  id: sampleIdsToGet
                }
              }
            }
          );
          const newSampleIdToMaterialId = {
            ...sampleIdToMaterialId
          };
          samplesWithMaterialId.forEach(sample => {
            const matId = sample.materialId;
            if (matId) {
              newSampleIdToMaterialId[sample.id] = matId;
            }
          });
          change("sampleIdToMaterialId", newSampleIdToMaterialId);
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error loading sample info from tables.");
        }
        materialQueryRef.current--;
        if (materialQueryRef.current === 0) setLoadingMatInfo(false);
      }
    }
    getTableMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTables]);

  const allReactions = useMemo(() => {
    return flatMap(reactionMaps, rm => rm.reactions);
  }, [reactionMaps]);

  const schema = useMemo(() => {
    if (allReactions.length) {
      return getReactionMapSchema(allReactions);
    }
  }, [allReactions]);

  const availableMaterialIds = useMemo(() => {
    const arr = [];
    dataTables.forEach(table => {
      const sampleIds = tableIdToSampleIds[table.id];
      if (sampleIds) {
        sampleIds.forEach(sampleId => {
          const matId = sampleIdToMaterialId[sampleId];
          if (matId) {
            arr.push(matId);
          }
        });
      }
    });
    return arr;
  }, [dataTables, tableIdToSampleIds, sampleIdToMaterialId]);

  const reactionsThatWillRun = useMemo(() => {
    if (availableMaterialIds.length && allReactions.length) {
      const reactions = [];
      allReactions.forEach(r => {
        if (r.reactionOutputs.length === 1) {
          const materialIsAvailable = availableMaterialIds.includes(
            r.reactionOutputs[0].outputMaterialId
          );
          if (materialIsAvailable) {
            reactions.push(r);
          }
        }
      });
      return reactions;
    } else {
      return [];
    }
  }, [availableMaterialIds, allReactions]);

  const noMatsOnTable =
    !!dataTables.length && !loadingMatInfo && !availableMaterialIds.length;
  const noReactions = !!reactionMaps.length && !allReactions.length;
  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Reaction Maps"
          helper="Select one or more parent reaction maps."
        />
        <GenericSelect
          {...{
            name: "reactionMaps",
            schema: [
              "name",
              { displayName: "Reaction Type", path: "reactionType.name" },
              dateModifiedColumn
            ],
            isMultiSelect: true,
            buttonProps: {
              disabled: isDisabledMap.reactionMaps,
              loading: isLoadingMap.reactionMaps
            },
            fragment: [
              "reactionMap",
              "id name reactionType { code name } updatedAt"
            ],
            additionalDataFragment: reactionMapReworkReactionMapFragment
          }}
        />
        {!!allReactions.length && (
          <DataTable
            formName="reactions"
            entities={allReactions}
            reactionsThatWillRun={reactionsThatWillRun}
            schema={{
              ...schema,
              fields: [
                ...schema.fields,
                {
                  type: "action",
                  width: 50,
                  render: (v, r) => {
                    if (reactionsThatWillRun.includes(r)) {
                      return (
                        <Tooltip content="Output material selected by sample QC data table.">
                          <Icon icon="tick" intent="success" />
                        </Tooltip>
                      );
                    }
                  }
                }
              ]
            }}
            maxHeight={300}
            destroyOnUnmount={false}
            isSimple
            compact
          />
        )}
        {noReactions && (
          <Callout intent="warning" style={{ marginTop: 15 }}>
            No reactions found.
          </Callout>
        )}
        {noMatsOnTable && (
          <Callout intent="warning" style={{ marginTop: 15 }}>
            No materials found from table.
          </Callout>
        )}
        {!!reactionsThatWillRun.length && (
          <Callout intent="success" style={{ marginTop: 15 }}>
            {pluralize("reaction", reactionsThatWillRun.length, true)}{" "}
            {pluralize("was", reactionsThatWillRun.length)} selected for rework.
          </Callout>
        )}
      </div>
      <SampleQCDataTableSelect
        toolSchema={toolSchema}
        isDisabledMap={isDisabledMap}
        isLoadingMap={isLoadingMap}
        helperText="Choose data tables of samples (output by Sample QC Tool). These samples will be used to reconstruct a subset reaction map."
        additionalDataFragment={reactionMapReworkSampleDataTableFragment}
      />
      <Footer
        {...footerProps}
        loading={loadingMatInfo}
        disabled={noReactions || noMatsOnTable || !reactionsThatWillRun.length}
        onClick={handleSubmit(() => {
          change("reactionsThatWillRun", reactionsThatWillRun);
          nextStep();
        })}
      />
    </React.Fragment>
  );
}

export default stepFormValues(
  "reactionMaps",
  "dataTables",
  "fetchedSampleIds",
  "sampleIdToMaterialId",
  "tableIdToSampleIds"
)(SelectInputs);
