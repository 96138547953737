/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { fork, all } from "redux-saga/effects";
// import j5Sagas from './j5'
import saveDesign from "./saveDesign";
import syncDesignState from "./syncDesignState";
import fetchDesignFromServer from "./fetchDesignFromServer";
import editDesign from "./editDesign";
import undoRedo from "./undoRedo";
import submitDesignForAssembly from "./submitDesignForAssembly";
import exportDesign from "./exportDesign";
import pasteCells from "./pasteCells";

export default function* rootSaga() {
  yield all([
    // fork(j5Sagas),
    fork(saveDesign),
    fork(syncDesignState),
    fork(fetchDesignFromServer),
    fork(editDesign),
    fork(undoRedo),
    fork(submitDesignForAssembly),
    fork(exportDesign),
    fork(pasteCells)
  ]);
}
