/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { get } from "lodash";

import { Dialog, Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, tgFormValues } from "@teselagen/ui";
import { InputField, RadioGroupField, TextareaField } from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

// The prop `rule` will be passed if and only if we are editing an
// existing rule set.
class AddRegexDesignRuleDialog extends React.Component {
  onSubmit = async values => {
    try {
      const { hideModal, refetchRuleSet, ruleSet, rule } = this.props;
      await safeUpsert("rule", {
        type: values.type,
        name: values.name,
        regex: values.regex,
        ...(rule ? { id: rule.id } : { ruleSetId: ruleSet.id })
      });
      await refetchRuleSet();
      hideModal();
    } catch (err) {
      console.error(err);
      window.toastr.error("Error adding rule.");
    }
  };

  computeMatchFieldLabel = () => {
    const { regex, matchField = "" } = this.props;

    if (!regex) return "Regex Test Field";
    const regexp = new RegExp(regex, "i");

    const matches = matchField.match(regexp);
    if (matches === null) {
      return "Regex Test Field: status=no match";
    }

    return 'Regex Test Field: status=match on "' + matches[0] + '"';
  };

  render() {
    const { hideModal, handleSubmit, rule } = this.props;
    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={rule ? "Edit Regex Rule" : "Add Regex Rule"}
        style={{ width: 550 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            <div> Regex rules will be tested against a part's sequence. </div>
            <div>&nbsp;</div>
            <div>
              {" "}
              For example, a regex of 'taa$' will match on a part with a
              sequence of 'cgatgctaa'.
            </div>
            <div>&nbsp;</div>
            <InputField
              name="name"
              defaultValue={get(rule, "name")}
              label="Rule Name"
            />
            <InputField
              name="regex"
              defaultValue={get(rule, "regex")}
              label="Regex"
            />
            <RadioGroupField
              name="type"
              label="Throws error when regex..."
              defaultValue={get(rule, "type") || "requiredRegex"}
              options={[
                {
                  label: "Matches",
                  value: "restrictedRegex"
                },
                {
                  label: "Doesn't Match",
                  value: "requiredRegex"
                }
              ]}
            />
            <div style={{ width: "100%", borderTop: "1px solid #8898A8" }} />
            <TextareaField
              name="matchField"
              label={this.computeMatchFieldLabel()}
              placeholder="Enter some text here to test your regex (design rule regexes are case insensitive)."
            />
          </div>
          <DialogFooter hideModal={hideModal} text="OK" />
        </form>
      </Dialog>
    );
  }
}

const validate = values => {
  const errors = {};
  ["name", "regex"].forEach(key => {
    if (!values[key]) errors[key] = "Required";
  });
  return errors;
};

export default compose(
  reduxForm({
    form: "addRegexDesignRuleDialogForm",
    validate,
    enableReinitialize: true
  }),
  tgFormValues("regex", "matchField")
)(AddRegexDesignRuleDialog);
