/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isoContext } from "@teselagen/utils";
import { extractPlateCsvFiles } from "./parsePlateCsvAndSequenceFiles";
import { handlePlateCsv } from "./parsePlateCsvAndSequenceFiles";
import finishPlateOrTubeCreate from "../utils/finishPlateOrTubeCreate";
import {
  checkBarcodesAndFormat,
  getPositionFromAlphanumericLocation,
  overwriteBarcodeCheck
} from "../utils/plateUtils";
import { getAliquotContainerLocation } from "../utils/getAliquotContainerLocation";
import { handlePlateTypeInfo } from "./parsePlateCsvAndSequenceFiles";

export const requiredHeaders = ["Rack Name"];
export const allHeaders = [
  "Rack Name",
  "Rack Barcode",
  "Location",
  "Tube Barcode"
];

export default async function handleEmptyTubeRackImport(
  values,
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const { containerArrayType, aliquotContainerType } =
    await handlePlateTypeInfo(values, undefined, ctx);
  const filename = values.plateFiles[0].name;

  const { csvFiles } = await extractPlateCsvFiles(values.plateFiles);
  const { csvData } = await handlePlateCsv(
    csvFiles,
    {},
    {
      requiredFields: requiredHeaders,
      nameHeader: "RACK_NAME",
      barcodeHeader: "RACK_BARCODE"
    },
    ctx
  );

  for (const [index, row] of csvData.entries()) {
    const { RACK_NAME: rackName } = row;
    if (!rackName) {
      throw new Error(`Row ${index + 1} did not specify a rack name`);
    }
  }

  let continuedFromRackBarcodes;
  if (values.generateBarcodes) {
    continuedFromRackBarcodes = await overwriteBarcodeCheck(
      csvData,
      "Rack Barcode"
    );
    if (!continuedFromRackBarcodes) return;
  }
  if (values.generateTubeBarcodes && !continuedFromRackBarcodes) {
    const continueUpload = await overwriteBarcodeCheck(csvData, "Tube Barcode");
    if (!continueUpload) return;
  }

  const nameToRack = {};

  const continueUpload = await checkBarcodesAndFormat({
    data: csvData,
    filename,
    containerArrayType,
    barcodeKey: "RACK_BARCODE",
    tubeBarcodeKey: "TUBE_BARCODE",
    wellPositionHeader: "LOCATION"
  });
  if (!continueUpload) return;

  const seenTubeBarcodes = [];

  for (const [index, row] of csvData.entries()) {
    const {
      RACK_NAME: rackName,
      TUBE_BARCODE: tubeBarcode,
      RACK_BARCODE: rackBarcode,
      LOCATION: location
    } = row;

    if (!nameToRack[rackName]) {
      nameToRack[rackName] = {
        name: rackName,
        seenLocations: [],
        containerArrayType,
        containerArrayTypeId: containerArrayType.id,
        aliquotContainers: [],
        ...(!values.generateBarcodes &&
          rackBarcode && {
            barcode: {
              barcodeString: rackBarcode
            }
          })
      };
    }
    const position = getPositionFromAlphanumericLocation(
      location,
      containerArrayType.containerFormat
    );
    const location2d = getAliquotContainerLocation(position, {
      force2D: true
    });
    if (nameToRack[rackName].seenLocations.includes(location2d)) {
      throw new Error(
        `Row ${
          index + 1
        } specifies a location (${location}) that has already been filled in this rack ${rackName}. Check for duplicate rows.`
      );
    } else {
      nameToRack[rackName].seenLocations.push(location2d);
    }

    const tubeBarcodeToUse = !values.generateTubeBarcodes && tubeBarcode;
    if (tubeBarcodeToUse && seenTubeBarcodes.includes(tubeBarcodeToUse)) {
      throw new Error(
        `Row ${
          index + 1
        } specifies a tube barcode (${tubeBarcodeToUse}) that has already been used in this import. Check for duplicate rows.`
      );
    }
    if (tubeBarcodeToUse) {
      seenTubeBarcodes.push(tubeBarcodeToUse);
    }
    nameToRack[rackName].aliquotContainers.push({
      ...position,
      ...(tubeBarcodeToUse && {
        barcode: {
          barcodeString: tubeBarcodeToUse
        }
      }),
      aliquotContainerTypeCode: aliquotContainerType.code
    });
  }

  const dupTubeBarcodes = await safeQuery(
    ["aliquotContainer", "id barcode { id barcodeString }"],
    {
      variables: {
        filter: {
          "barcode.barcodeString": seenTubeBarcodes
        }
      }
    }
  );

  if (dupTubeBarcodes.length) {
    throw new Error(
      `The following tube barcodes are already in the system: ${dupTubeBarcodes
        .map(d => d.barcode.barcodeString)
        .join(", ")}`
    );
  }

  const createdPlates = await finishPlateOrTubeCreate(
    {
      newPlates: Object.values(nameToRack).map(rack => {
        delete rack.seenLocations;
        return rack;
      }),
      filename,
      containerArrayType,
      aliquotContainerType,
      ...values
    },
    ctx
  );

  return createdPlates;
}
