/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect } from "react";
import { compose, withProps } from "recompose";
import { get, isEmpty } from "lodash";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Button, Intent, Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  CheckboxField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import MaterialUpdateSubform from "../../MaterialUpdateSubform";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { withStrainUnits } from "../../Dialogs/CreateNewStrainDialog";
import StrainOverwrites from "./StrainOverwrites";
import "./style.css";
import {
  filterSequenceUploads,
  parseSequenceText
} from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import {
  asyncValidateName,
  throwFormError
} from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import withQuery from "../../../../src-shared/withQuery";
import { pushHelper } from "../../../../src-shared/utils/pushHelper";
import { checkDuplicateSequencesExtended } from "../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import showDuplicateInputSequencesConfirmation from "../../../../src-shared/showDuplicateInputSequencesConfirmation";
import UploadOrPasteSequenceFields from "../../../../src-shared/UploadDNASequenceDialog/UploadOrPasteSequenceFields";
import SequenceFileUpload from "../../../../src-shared/SequenceFileUpload";
import { getMicrobialPlasmidsToCreate } from "../../../utils";

const strainFragmentFields = `
  id
  name
  biosafetyLevelCode
  targetOrganismClassId
  growthCondition {
    id
    name
    description
    lengthUnitCode
    temperature
    humidity
    shakerSpeed
    shakerThrow
    growthMedia {
      id
      name
    }
    gasComposition {
      id
      name
    }
  }
  strainPlasmids {
    id
    polynucleotideMaterialId
  }
  strainSelectionMethods {
    id
    selectionMethodId
  }
  inductionMethodStrains {
    id
    inductionMethodId
  }
`;

const CreateNewMaterialDialog = ({
  existingStrain,
  change,
  hideModal,
  history,
  cb,
  valid,
  submitting,
  handleSubmit,
  materialTypeCode,
  overwriteStrainFields,
  rnaTypes = [],
  error,
  nonSequence,
  isRNA,
  targetOrganismClasses,
  biosafetyLevels,
  selectionMethods,
  inductionMethods,
  lengthUnits
}) => {
  const [page, setPage] = useState(1);
  const [fileUpload, setFileUpload] = useState(true);

  useEffect(() => {
    if (existingStrain) {
      change("growthCondition", existingStrain.growthCondition);
      change("targetOrganismClassId", existingStrain.targetOrganismClassId);
      change("biosafetyLevelCode", existingStrain.biosafetyLevelCode);
      change(
        "selectionMethodIds",
        existingStrain.strainSelectionMethods.map(sm => sm.selectionMethodId)
      );
      change(
        "inductionMethodIds",
        existingStrain.inductionMethodStrains.map(sm => sm.inductionMethodId)
      );
    }
  }, [existingStrain, change]);

  const onSubmit = async originalValues => {
    const values = {
      ...originalValues
    };
    const matType = values.materialTypeCode;
    const sequenceUploadKey =
      matType === "DNA" || matType === "RNA"
        ? "sequenceUpload"
        : "microbialSequenceUpload";
    const sequenceUpload = values[sequenceUploadKey] || [];
    const sequenceAliases = [];
    const sequenceText = values.sequenceText || "";
    const sequenceName = values.sequenceName;

    const nonSequence = values.nonSequence;
    delete values.nonSequence;
    values.genomeId = values.genome && values.genome.id;
    delete values.genome;

    delete values.sequenceName;
    delete values.sequenceUpload;
    delete values.microbialSequenceUpload;
    delete values.sequenceText;
    delete values.labId;
    const selectedSequences = values.selectedSequences || [];
    delete values.selectedSequences;

    let seqToUpdate;
    try {
      let sequences = [];
      let route;
      if (matType === "DNA" || matType === "RNA") {
        route = matType === "RNA" ? "dna-materials" : "rna-materials";
        if (nonSequence) {
          values.polynucleotideMaterialSequence = null;
        } else {
          if (fileUpload) {
            sequences = await filterSequenceUploads({
              allSequenceFiles: [...sequenceUpload],
              sequenceTypeCode: matType === "RNA" ? "RNA" : null
            });
          } else {
            sequences = await parseSequenceText(sequenceText, sequenceName, {
              sequenceTypeCode: matType === "RNA" ? "RNA" : null
            });
            if (matType === "RNA") {
              sequences.forEach(sequence => {
                sequence.sequenceTypeCode = "RNA";
              });
            }
          }
          if (sequences.length > 1) {
            throw new Error(
              `Can only add a single sequence to a ${matType} material. This upload contained ${sequences.length} sequences.`
            );
          }
          //sequences coming in must have properties: .hash .circular .sequence
          const {
            duplicateSequencesFound: [duplicateSequence]
          } = await checkDuplicateSequencesExtended(sequences, {
            fragment: "id polynucleotideMaterialId "
          });
          if (duplicateSequence && duplicateSequence.id) {
            if (duplicateSequence.polynucleotideMaterialId) {
              return window.toastr.warning(
                "A material is already linked to this sequence.",
                {
                  action: {
                    icon: "eye-open",
                    onClick: e => {
                      hideModal();
                      pushHelper(
                        e,
                        `/${route}/${duplicateSequence.polynucleotideMaterialId}`
                      );
                    }
                  }
                }
              );
            } else {
              seqToUpdate = {
                id: duplicateSequence.id
              };
            }
          } else {
            const seq = sequences[0];
            seq && delete seq.duplicateFound;
            const newSequence = {
              ...seq,
              sequenceTypeCode:
                matType === "RNA"
                  ? "RNA"
                  : seq.circular
                    ? "CIRCULAR_DNA"
                    : "LINEAR_DNA",
              rnaTypeId: matType === "RNA" ? values.rnaTypeId : null
            };
            values.polynucleotideMaterialSequence = newSequence;
            delete values.rnaTypeId;
          }
        }
      } else if (matType === "MICROBIAL" || matType === "CELL_CULTURE") {
        sequences = await filterSequenceUploads({
          allSequenceFiles: [...sequenceUpload],
          sequenceTypeCode: "CIRCULAR_DNA"
        });

        // if the strain already has one of the uploaded plasmids, they will already exist and will be picked up here anyways
        const {
          duplicatesOfInputSequences,
          allInputSequencesWithAttachedDuplicates
        } = await checkDuplicateSequencesExtended(sequences);
        if (sequences.length > 0) {
          const continueUpload = await showDuplicateInputSequencesConfirmation(
            duplicatesOfInputSequences,
            sequenceAliases
          );
          if (!continueUpload) return;
        }
        const {
          overwriteStrainFields,
          growthCondition,
          targetOrganismClassId,
          biosafetyLevelCode,
          selectionMethodIds,
          inductionMethodIds,
          existingStrain = {}
        } = values;

        const strainSelectionMethodIds = get(
          existingStrain,
          "strainSelectionMethods",
          []
        ).map(ssm => ssm.selectionMethodId);
        const strainInductionMethodIds = get(
          existingStrain,
          "inductionMethodStrains",
          []
        ).map(ssm => ssm.inductionMethodId);
        // delete override fields from values
        delete values.overwriteStrainFields;
        delete values.growthCondition;
        delete values.targetOrganismClassId;
        delete values.biosafetyLevelCode;
        delete values.selectionMethodIds;
        delete values.inductionMethodIds;

        if (overwriteStrainFields) {
          if (biosafetyLevelCode !== existingStrain.biosafetyLevelCode) {
            values.biosafetyLevelOverwriteCode = biosafetyLevelCode;
          }
          if (
            targetOrganismClassId !== existingStrain.targetOrganismClassId &&
            targetOrganismClassId
          ) {
            values.targetOrganismClassOverwriteId = targetOrganismClassId;
          }
          if (
            selectionMethodIds.length !== strainSelectionMethodIds.length ||
            !selectionMethodIds.every(id =>
              strainSelectionMethodIds.includes(id)
            )
          ) {
            const selectionMethodsJoin = selectionMethodIds.map(id => ({
              selectionMethodId: id
            }));
            if (matType === "MICROBIAL") {
              values.microbialMaterialMicrobialMaterialSelectionMethods =
                selectionMethodsJoin;
            } else if (matType === "CELL_CULTURE") {
              values.cellCultureCellCultureSelectionMethods =
                selectionMethodsJoin;
            }
          }
          if (
            inductionMethodIds.length !== strainInductionMethodIds.length ||
            !inductionMethodIds.every(id =>
              strainInductionMethodIds.includes(id)
            )
          ) {
            const inductionMethodsJoin = inductionMethodIds.map(id => ({
              inductionMethodId: id
            }));
            if (matType === "MICROBIAL") {
              values.microbialMaterialMicrobialMaterialInductionMethods =
                inductionMethodsJoin;
            } else if (matType === "CELL_CULTURE") {
              values.cellCultureCellCultureInductionMethods =
                inductionMethodsJoin;
            }
          }

          const growthConditionOverwrite = {};
          const toOverwriteGrowthFields = [
            "name",
            "description",
            "lengthUnitCode",
            "temperature",
            "humidity",
            "shakerSpeed",
            "shakerThrow",
            "growthMedia.id",
            "gasComposition.id"
          ];
          toOverwriteGrowthFields.forEach(field => {
            const existingStrainValue = get(
              existingStrain,
              `growthCondition.${field}`
            );
            const newValue = get(growthCondition, field);
            if (newValue && existingStrainValue !== newValue) {
              if (field === "growthMedia.id") {
                growthConditionOverwrite.growthMediaId = newValue;
              } else if (field === "gasComposition.id") {
                growthConditionOverwrite.gasCompositionId = newValue;
              } else {
                growthConditionOverwrite[field] = newValue;
              }
            }
          });
          if (!isEmpty(growthConditionOverwrite)) {
            values.growthConditionOverwrite = growthConditionOverwrite;
          }
        }

        if (values.existingStrain) {
          values.strainId = values.existingStrain.id;
        }
        delete values.existingStrain;

        const { microbialPlasmids, extraCreates } =
          await getMicrobialPlasmidsToCreate({
            selectedSequences,
            allInputSequencesWithAttachedDuplicates,
            existingStrain
          });
        if (matType === "MICROBIAL") {
          values.microbialMaterialMicrobialMaterialPlasmids = microbialPlasmids;
        } else {
          values.cellCultureCellCulturePlasmids = microbialPlasmids;
        }
        route =
          matType === "MICROBIAL" ? "microbial-materials" : "cell-cultures";
        await extraCreates();
      } else if (matType === "GENOMIC") {
        route = "genomic-materials";
      }
      const [material] = await safeUpsert("material", values, {
        forceCreate: true
      });
      if (seqToUpdate) {
        seqToUpdate.polynucleotideMaterialId = material.id;
        await safeUpsert("sequence", seqToUpdate);
      }
      hideModal();
      cb ? cb(material) : history.push(`/${route}/${material.id}`);
    } catch (error) {
      console.error(error);
      throwFormError(error.message || "Error registering material");
    }
  };

  const validateNextPage = () => {
    if (valid) setPage(prev => prev + 1);
  };

  let seqField;
  if (materialTypeCode === "DNA" || materialTypeCode === "RNA") {
    const uploadSeq = (
      <UploadOrPasteSequenceFields
        isRequired
        isFileUpload={fileUpload}
        fileLimit={1}
        toggleFileUpload={() => setFileUpload(prev => !prev)}
        isRNA={isRNA}
        rnaTypes={rnaTypes}
      />
    );
    if (window.frontEndConfig.enableNonSequenceDnaMaterials) {
      seqField = (
        <>
          <CheckboxField
            name="nonSequence"
            label="Create Without Sequence Data"
            tooltipInfo="If checked, the material does not require sequence information."
          />
          {!nonSequence && uploadSeq}
        </>
      );
    } else {
      seqField = uploadSeq;
    }
  }
  return (
    <form>
      {page === 1 && (
        <div className={Classes.DIALOG_BODY}>
          <MaterialUpdateSubform materialTypeCode={materialTypeCode} />
        </div>
      )}
      {page === 2 && (
        <div className={Classes.DIALOG_BODY}>
          {seqField}
          {(materialTypeCode === "MICROBIAL" ||
            materialTypeCode === "CELL_CULTURE") && (
            <>
              <GenericSelect
                name="existingStrain" //the field name within the redux form Field
                label={
                  materialTypeCode === "MICROBIAL"
                    ? "Choose Existing Strain"
                    : "Choose Existing Cell Line"
                }
                asReactSelect
                fragment={["strain", strainFragmentFields]}
                additionalFilter={(props, qb) => {
                  qb.whereAll({
                    strainTypeCode:
                      materialTypeCode === "MICROBIAL"
                        ? "MICROBIAL_STRAIN"
                        : "CELL_LINE"
                  });
                }}
              />
              <CheckboxField
                name="overwriteStrainFields"
                label={
                  materialTypeCode === "MICROBIAL"
                    ? "Overwrite Strain Fields"
                    : "Overwrite Cell Line Fields"
                }
              />
              {overwriteStrainFields && (
                <StrainOverwrites
                  targetOrganismClasses={targetOrganismClasses}
                  biosafetyLevels={biosafetyLevels}
                  selectionMethods={selectionMethods}
                  inductionMethods={inductionMethods}
                  lengthUnits={lengthUnits}
                />
              )}
              <GenericSelect
                name="selectedSequences"
                asReactSelect
                isMultiSelect
                label="Select Existing Plasmids"
                schema={[
                  {
                    path: "name"
                  },
                  {
                    path: "sequenceTypeCode"
                  }
                ]}
                fragment={["sequence", "id name hash polynucleotideMaterialId"]}
                tableParamOptions={{
                  additionalFilter: {
                    sequenceTypeCode: "CIRCULAR_DNA"
                  }
                }}
              />
              <SequenceFileUpload
                // will need to update with Cell Line Specific Verbage
                label="Upload Plasmids (optional)"
                name="microbialSequenceUpload"
              />
            </>
          )}
          {materialTypeCode === "GENOMIC" && (
            <div>
              <GenericSelect
                name="genome"
                asReactSelect
                label="Genome"
                fragment={["genome", "id name"]}
              />
            </div>
          )}
          <BlueprintError error={error} />
        </div>
      )}
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        additionalButtons={
          <Button
            intent={Intent.PRIMARY}
            disabled={page === 1}
            onClick={() => setPage(prev => prev - 1)}
            text="Back"
          />
        }
        text={page === 1 ? "Next" : "Submit"}
        onClick={
          page === 1 ? handleSubmit(validateNextPage) : handleSubmit(onSubmit)
        }
        intent={page === 1 ? Intent.PRIMARY : undefined}
      />
    </form>
  );
};

export default compose(
  withProps(() => ({
    model: "material"
  })),
  wrapDialog(({ initialValues }) => ({
    title:
      initialValues?.materialTypeCode === "CELL_CULTURE"
        ? "Register Cell Culture"
        : "Register Material"
  })),
  reduxForm({
    enableReinitialize: true,
    form: "createNewMaterialForm",
    ...asyncValidateName
  }),
  withStrainUnits,
  withQuery(["rnaType", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  tgFormValues(
    "materialTypeCode",
    "existingStrain",
    "overwriteStrainFields",
    "nonSequence"
  )
)(CreateNewMaterialDialog);
