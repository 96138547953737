/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import uniqueConstraint from "./unique-constraint";
import notNullConstraint from "./null-constraint";
import { identity } from "lodash";

const ERROR_FORMATTERS = {
  [uniqueConstraint.TYPE]: uniqueConstraint,
  [notNullConstraint.TYPE]: notNullConstraint
};

const getErrorTypeHandler = errorMessage => {
  const formatters = Object.values(ERROR_FORMATTERS);
  for (const formatter of formatters) {
    if (formatter.check(errorMessage)) return formatter.handler;
  }
  return identity;
};

/**
 * Generic GQL Error Handler for a single gql error.
 *
 * Each GQL error can be different so there are specific error handlers for them.
 *
 * The idea is to make the formatted error objects user-friendly, specifically the message prop
 * of each error. Note that these errors get propagated down to the API and the Client, so keeping them
 * user-friendly improves the error handling DX relieving the developer from having to format them every time.
 */
const gqlErrorHandler = ({ error, operation }) => {
  const errorTypeHandler = getErrorTypeHandler(error?.message);
  return errorTypeHandler({ error, operation });
};

/**
 * Loops over the graphql errors and formats them at will.
 *
 * It receives a list of gql errors and the original gql operation that caused them.
 */
const gqlErrorFormatter = (gqlErrors, operation) => {
  gqlErrors.forEach(error => gqlErrorHandler({ error, operation }));
};

const USER_ERROR_TYPES = {};

Object.keys(ERROR_FORMATTERS).forEach(errorType => {
  USER_ERROR_TYPES[errorType] = errorType;
});

export { USER_ERROR_TYPES };
export default gqlErrorFormatter;
