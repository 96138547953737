/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import routeDoubleClick from "../utils/routeDoubleClick";
import gql from "graphql-tag";
import { tagColumnWithRender } from "../utils/tagColumn";
import { addTagFilterToQuery } from "../utils/tagUtils";
import withQuery from "../withQuery";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";

function FpusCard({ tableParams }) {
  return (
    <CollapsibleCard title="Functional Protein Units">
      <DataTable {...tableParams} noPadding onDoubleClick={routeDoubleClick} />
    </CollapsibleCard>
  );
}

const schema = {
  model: "functionalProteinUnit",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    tagColumnWithRender
  ]
};

const fragment = gql`
  fragment aminoAcidRecordFpus on functionalProteinUnit {
    id
    name
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export default compose(
  withTableParams({
    formName: "aminoAcidFpuCard",
    urlConnected: false,
    schema,
    additionalFilter: (props, qb, currentParams) => {
      addTagFilterToQuery(currentParams.tags, qb);
      qb.whereAll({
        "proteinSubUnits.aminoAcidSequenceId": props.aminoAcidSequenceId
      });
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(FpusCard);
