/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import gql from "graphql-tag";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import { ReactSelectField, SwitchField } from "@teselagen/ui";
import { getMaterialInfo } from "./utils";
import { isEmpty } from "lodash";

const microbialMaterialSchema = [
  "name",
  { displayName: "Strain", path: "strain.name" },
  dateModifiedColumn
];

const microbialMaterialSelectMaterialFragment = gql`
  fragment microbialMaterialSelectMaterialFragment on material {
    id
    name
    materialTypeCode
    vendorCatalogNumber
    strain {
      id
      name
      description
      genotype
      biosafetyLevelCode
      biosafetyLevel {
        code
        name
      }
      specie {
        id
        abbreviatedName
      }
    }
    cellCultureCellCulturePlasmids {
      id
      copyNumber
      cellCultureId
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          name
        }
      }
    }
    microbialMaterialMicrobialMaterialPlasmids {
      id
      copyNumber
      microbialMaterialId
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          name
        }
      }
    }
    updatedAt
  }
`;

function MicrobialMaterialSelectSection({ header, helper, microbialMaterial }) {
  const additionalFilter = (props, qb, currentParams) => {
    const entityFilter = currentParams.entityFilter;
    if (entityFilter === "microbialMaterial") {
      qb.whereAll({
        materialTypeCode: "MICROBIAL"
      });
    } else {
      qb.whereAll({
        materialTypeCode: "CELL_CULTURE"
      });
    }
    if (currentParams.materialsWithPlasmid) {
      qb.whereAll({
        "microbialMaterialPlasmidCountView.plasmidCount": qb.greaterThan(0)
      });
    } else {
      qb.whereAll({
        "microbialMaterialPlasmidCountView.plasmidCount": 0
      });
    }
  };
  return (
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper header={header} helper={helper} />
        <div>
          <GenericSelect
            {...{
              name: "microbialMaterial",
              isRequired: true,
              schema: microbialMaterialSchema,
              isMultiSelect: false,
              fragment: microbialMaterialSelectMaterialFragment,
              tableParamOptions: {
                additionalFilter: additionalFilter
              },
              additionalTableProps: {
                enhancedChildren: ({ tableParams }) => {
                  const { currentParams, setNewParams } = tableParams;
                  return (
                    <div>
                      <ReactSelectField
                        name="materialTypeFilter"
                        label="Material Type"
                        defaultValue={
                          microbialMaterial?.materialTypeCode === "CELL_CULTURE"
                            ? "cellCulture"
                            : "microbialMaterial"
                        }
                        onFieldSubmit={val => {
                          setNewParams({
                            ...currentParams,
                            page: undefined,
                            entityFilter: val
                          });
                        }}
                        options={[
                          {
                            label: "Cell Culture",
                            value: "cellCulture"
                          },
                          {
                            label: "Microbial Material",
                            value: "microbialMaterial"
                          }
                        ]}
                      />
                      <SwitchField
                        label="Materials With Plasmid"
                        name="materialsWithPlasmid"
                        onFieldSubmit={val => {
                          setNewParams({
                            ...currentParams,
                            page: undefined,
                            materialsWithPlasmid: !!val
                          });
                        }}
                      />
                    </div>
                  );
                }
              }
            }}
          />
        </div>
      </div>
      {!isEmpty(microbialMaterial) && (
        <RecordInfoDisplay recordInfo={getMaterialInfo(microbialMaterial)} />
      )}
    </div>
  );
}

export default MicrobialMaterialSelectSection;
