/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { last } from "lodash";
import {
  isLeafCard,
  isLayoutList,
  getBinIdsInCard,
  getElementsInBin,
  getNumberOfRowsForCard,
  getRowIndexToElement,
  getElementIdsInBin,
  getBinsInCard,
  getItemOfType
} from "../../selectors/designStateSelectors";
import isElementCombinationContiguousOnTheSameSequence from "../../redux/sagas/submitDesignForAssembly/createCardSequenceMap/isElementCombinationContiguousOnTheSameSequence";
import getFlankingSequenceOfPart from "../../selectors/getFlankingSequenceOfPart";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

/**
 * Get a set containing all of the element ids in the card.
 */
function getAllElementIdsSetInCard(state, cardId) {
  const elementIds = {};
  for (const binId of getBinIdsInCard(state, cardId)) {
    for (const elId of getElementIdsInBin(state, binId)) {
      elementIds[elId] = true;
    }
  }
  return elementIds;
}

/**
 * Given a card, get all of the combinations that could potentially have flanking sequence.
 * This function will return null if every element in the card must be invalid.
 *
 * @param {Object} state
 * @param {string} cardId
 */
function getCombinationsOfElementsToCheck(state, cardId) {
  // Non-leaf cards have no flanking sequence.
  if (!isLeafCard(state, cardId)) {
    return null;
  }

  const binIds = getBinIdsInCard(state, cardId);

  // Get all of the combinations that could potentially have flanking sequence.
  if (isLayoutList(state)) {
    const combinations = [];

    const numRows = getNumberOfRowsForCard(state, cardId);
    const rowIndexToElementInBins = binIds.map(binId =>
      getRowIndexToElement(state, binId)
    );

    // Get a list of the elements in the non-empty rows of the card. Empty
    // cells in rows will be missing from the combination, so the combination
    // might not be the same length as the number of bins.
    for (let i = 0; i < numRows; i++) {
      const combination = [];

      for (const rowIndexToElement of rowIndexToElementInBins) {
        if (rowIndexToElement[i]) {
          combination.push(rowIndexToElement[i]);
        }
      }

      if (combination.length) {
        combinations.push(combination);
      }
    }

    return combinations;
  } else {
    const elementsInBins = binIds.map(binId => getElementsInBin(state, binId));

    if (getBinIdsInCard(state, cardId).length === 1) {
      // If we have a single bin the card, then we return every element in the card.
      return elementsInBins[0].map(el => [el]);
    } else {
      // There must be exactly one element in every bin.
      if (elementsInBins.some(els => els.length !== 1)) {
        return null;
      }

      return [elementsInBins.map(els => els[0])];
    }
  }
}

/**
 * Given an element that forms the boundary of a card, get the flanking sequence
 * to test.
 *
 * TODO: This should be tested with reverse direction bins and reverse complement parts.
 */
function getFlankingSequenceStringOfElement(
  state,
  element,
  regexStr,
  isFivePrime
) {
  // This will actually be an upper bound of the length of flanking sequence we need.
  // All of the regexes should look something like "gtg[ac]g[tgac]aca". This is
  // cool with the rest of the function.
  const length = regexStr.length;

  const part = getItemOfType(state, "part", element.partId);
  const bin = getItemOfType(state, "bin", element.binId);

  // Depending on certain factors, we need to see whether to get the sequence
  // from the forward strand five prime or threee prime of the part.
  let getToFivePrimeOfPart = !!bin.direction === (part.strand === 1);
  if (!isFivePrime) getToFivePrimeOfPart = !getToFivePrimeOfPart;

  let flankingSeqStr = getFlankingSequenceOfPart(
    state,
    part.id,
    length,
    getToFivePrimeOfPart
  );

  if (!bin.direction !== (part.strand === -1)) {
    flankingSeqStr = getReverseComplementSequenceString(flankingSeqStr);
  }

  return flankingSeqStr;
}

/**
 * See whether the given combination of elements is actually flanked by the given
 * regex string.
 *
 * This function assumes that the given combination of elements actually has flanking sequence.
 *
 * @param {Object} state
 * @param {Array<Element>} combination
 * @param {regexStr} regex The string version of the regex we want to use for validation.
 * @param {boolean} isFivePrime Whether we are validating the sequence to the five prime end of the card.
 */
function testFlankingSequenceAgainstRegex(
  state,
  combination,
  regexStr,
  isFivePrime
) {
  const regex = isFivePrime
    ? new RegExp(regexStr + "$", "i")
    : new RegExp("^" + regexStr, "i");

  const boundaryElement = isFivePrime ? combination[0] : last(combination);

  const flankingSeqStr = getFlankingSequenceStringOfElement(
    state,
    boundaryElement,
    regexStr,
    isFivePrime
  );

  return regex.test(flankingSeqStr);
}

/**
 * See if the sequence flanking the bins on a card matches a given regex.
 *
 * For cards with no flanking sequence (non-leaf cards and cards with elements that are not contiguous on the same
 * source sequence), we say that the flanking sequence doesn't match the given regex.
 *
 * This function will return a set of invalid element ids.
 *
 * @param {*} state
 * @param {string} cardId The id of the card we wish to validate
 * @param {regexStr} regex The string version of the regex we want to use for validation.
 * @param {boolean} isFivePrime Whether we are validating the sequence to the five prime end of the card.
 */
export default function validateFlankingSequenceAgainstRegex(
  state,
  cardId,
  regexStr,
  isFivePrime
) {
  // In this perhaps degenerate case, we will always say that the flanking
  // sequences matches an empty regex.
  if (!regexStr) return {};

  const combinationsToCheck = getCombinationsOfElementsToCheck(state, cardId);

  // Combinations to check will be null if every element in the card is invalid.
  if (!combinationsToCheck) {
    return getAllElementIdsSetInCard(state, cardId);
  }

  const bins = getBinsInCard(state, cardId);

  const invalidElementIds = {};

  for (const combination of combinationsToCheck) {
    // Having a combination with a different number of elements than
    // the number of bins indicates a missing element in a list layout design.
    if (
      combination.length !== bins.length ||
      !isElementCombinationContiguousOnTheSameSequence(
        state,
        bins,
        combination
      ) ||
      !testFlankingSequenceAgainstRegex(
        state,
        combination,
        regexStr,
        isFivePrime
      )
    ) {
      markCombinationAsInvalid(combination);
    }
  }

  return invalidElementIds;

  /**
   * Helper function.
   * @param {Array<Element>} combination
   */
  function markCombinationAsInvalid(combination) {
    for (const el of combination) {
      invalidElementIds[el.id] = true;
    }
  }
}
