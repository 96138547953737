/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const customHelpDocConstants = {
  UPLOAD_DNA_SEQUENCE: {
    code: "UPLOAD_DNA_SEQUENCE",
    where: "DESIGN & BUILD:\nDNA Sequences > Upload DNA Sequences"
  },
  NEW_DESIGN: {
    code: "NEW_DESIGN",
    where: "DESIGN:\nDesigns > New Design > Blank Design"
  }
};
export default customHelpDocConstants;
