/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import { pick } from "lodash";
import { LOCAL_FIELDS } from "../constants/designStateConstants";
import {
  createObjectValuedTypeMap,
  transformWithNewIds
} from "./designStateUtils";

const typeToDuplicateFieldToCheck = {
  icon: LOCAL_FIELDS.icon,
  restrictionEnzyme: LOCAL_FIELDS.restrictionEnzyme,
  customJ5Parameter: LOCAL_FIELDS.customJ5Parameter,
  tag: ["id", "name"],
  assemblyMethod: ["id", "name"]
};

export const removeDuplicates = async (designState, { safeQuery }) => {
  const idToPreexistingIdMap = createObjectValuedTypeMap();

  await Promise.map(
    Object.entries(typeToDuplicateFieldToCheck),
    async ([type, fields]) => {
      const items = Object.values(designState[type] || []);

      const arrayOfPossibleDuplicates = await Promise.map(items, item => {
        if (
          type === "customJ5Parameter" &&
          item &&
          item.isLocalToThisDesignId
        ) {
          return []; //we don't want to search for a duplicate customJ5Parameter if the customJ5Parameter.isLocalToThisDesignId is truthy (because it is already exclusive to another design)
        }
        const filter = pick(item, fields);
        delete filter.id;
        delete filter.cid;
        return safeQuery([type, fields], {
          isPlural: true,
          variables: { filter }
        });
      });

      items.forEach((item, i) => {
        const possibleDuplicates = arrayOfPossibleDuplicates[i];
        if (possibleDuplicates[0]) {
          idToPreexistingIdMap[type][item.id] = possibleDuplicates[0].id;
        }
      });
    }
  );

  const newDesignState = transformWithNewIds(designState, idToPreexistingIdMap);

  for (const type of Object.keys(idToPreexistingIdMap)) {
    for (const id of Object.values(idToPreexistingIdMap[type])) {
      delete newDesignState[type][id];
    }
  }

  return newDesignState;
};
