/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { filterSequenceString } from "@teselagen/sequence-utils";

// Some fields are commented out because they are not in the customJ5Parameter
export const universalParams = [
  // database structure.
  // {
  //     "defaultValue": 5,
  //     "description": "The default number of digits used to number an oligo, e.g. j5_00001_primer_description uses 5 digits",
  //     "display": "Master Oligo Number Of Digits",
  //     "name": "masterOligoNumberOfDigits",
  //     "nameUppercase": "MASTEROLIGONUMBEROFDIGITS",
  //     "parameter": "MONOD",
  //     "type": "int"
  // },
  // {
  //     "defaultValue": 5,
  //     "description": "The default number of digits used to number a plasmid, e.g. pj5_00001 uses 5 digits",
  //     "display": "Master Plasmid Number Of Digits",
  //     "name": "masterPlasmidNumberOfDigits",
  //     "nameUppercase": "MASTERPLASMIDNUMBEROFDIGITS",
  //     "parameter": "MPNOD",
  //     "type": "int"
  // },

  {
    defaultValue: 110,
    description: "The maximum oligo length to be ordered",
    display: "Max Oligo Length BPs",
    name: "maxOligoLengthBps",
    nameUppercase: "MAXIMUMOLIGOLENGTHBPS",
    parameter: "MOLB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 0.1,
    description: "The oligo synthesis cost per bp ($US)",
    display: "Oligo Synthesis Cost Per BP USD",
    name: "oligoSynthesisCostPerBp",
    nameUppercase: "OLIGOSYNTHESISCOSTPERBPUSD",
    parameter: "OSCPB",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 40,
    description: "The PAGE-purification cost per oligo ($US)",
    display: "Oligo Page Purification Cost Per Piece USD",
    name: "oligoPagePurCostPerPiece",
    nameUppercase: "OLIGOPAGEPURIFICATIONCOSTPERPIECEUSD",
    parameter: "OPPCPP",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 60,
    description:
      "The maximum oligo length that does not require PAGE-purification",
    display: "Oligo Max Length No Page Purification Required BPs",
    name: "oligoMaxLenNoPagePurBps",
    nameUppercase: "OLIGOMAXLENGTHNOPAGEPURIFICATIONREQUIREDBPS",
    parameter: "OMLPPRB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 100,
    description: "The minimum PCR product size",
    display: "Min PCR Product BPs",
    name: "minPcrProductBps",
    nameUppercase: "MINIMUMPCRPRODUCTBPS",
    parameter: "MPPB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 5000,
    description: "The maximum DNA synthesis size",
    display: "Max DNA Synthesis BPs",
    name: "maxDnaSynthLengthBps",
    nameUppercase: "MAXIMUMDNASYNTHESISLENGTHBPS",
    parameter: "MXDSLB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 300,
    description: "The minimum DNA synthesis size",
    display: "Min DNA Synthesis BPs",
    name: "minDnaSynthLengthBps",
    nameUppercase: "MINIMUMDNASYNTHESISLENGTHBPS",
    parameter: "MDSLB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 0.39,
    description: "The cost per bp to do direct synthesis ($US)",
    display: "Direct Synthesis Cost Per BP USD",
    name: "dirSynthCostPerBp",
    nameUppercase: "DIRECTSYNTHESISCOSTPERBPUSD",
    parameter: "DSCPB",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 159,
    description: "The minimum cost of synthesis per piece ($US)",
    display: "Direct Synthesis Min Cost Per Piece USD",
    name: "dirSynthMinCostPerPiece",
    nameUppercase: "DIRECTSYNTHESISMINIUMUMCOSTPERPIECEUSD",
    parameter: "DSMCPP",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 2,
    description:
      "Primer3 parameter: length of the desired GC clamp (Primer3 default is 0)",
    display: "Primer GC Clamp",
    name: "primerGcClamp",
    nameUppercase: "PRIMER_GC_CLAMP",
    parameter: "PGC",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 18,
    description:
      "Primer3 parameter: the minimum length of a primer (Primer3 default is 18)",
    display: "Primer Min Size",
    name: "primerMinSize",
    nameUppercase: "PRIMER_MIN_SIZE",
    parameter: "PMS",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 36,
    description:
      "Primer3 parameter: the maximum length of a primer (Primer3 default is 27, maximum is 36)",
    display: "Primer Max Size",
    name: "primerMaxSize",
    nameUppercase: "PRIMER_MAX_SIZE",
    parameter: "PMAXS",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 60,
    description:
      "Primer3 parameter: the minimum primer Tm (Primer3 default is 57)",
    display: "Primer Min Tm",
    name: "primerMinTm",
    nameUppercase: "PRIMER_MIN_TM",
    parameter: "PMT",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 70,
    description:
      "Primer3 parameter: the maximum primer Tm (Primer3 default is 63)",
    display: "Primer Max Tm",
    name: "primerMaxTm",
    nameUppercase: "PRIMER_MAX_TM",
    parameter: "PMAXT",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 5,
    description:
      "Primer3 parameter: the maximum primer pair difference in Tm (Primer3 default is 100)",
    display: "Primer Max Diff Tm",
    name: "primerMaxDiffTm",
    nameUppercase: "PRIMER_MAX_DIFF_TM",
    parameter: "PMDT",
    type: "Float",
    isRequired: true
  },
  {
    defaultValue: 47,
    description:
      "Primer3 parameter: the maximum primer self complementarity (Primer3 default is 47)",
    display: "Primer Max Self Any Th",
    name: "primerMaxSelfAnyTh",
    nameUppercase: "PRIMER_MAX_SELF_ANY_TH",
    parameter: "PMSAT",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: 47,
    description:
      "Primer3 parameter: the maximum primer self end complementarity (Primer3 default is 47)",
    display: "Primer Max Self End Th",
    name: "primerMaxSelfEndTh",
    nameUppercase: "PRIMER_MAX_SELF_END_TH",
    parameter: "PMSET",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: 47,
    description:
      "Primer3 parameter: the maximum primer pair complementarity (Primer3 default is 47)",
    display: "Primer Pair Max Compl Any Th",
    name: "primerPairMaxComplAnyTh",
    nameUppercase: "PRIMER_PAIR_MAX_COMPL_ANY_TH",
    parameter: "PPMCAT",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: 47,
    description:
      "Primer3 parameter: the maximum primer pair end complementarity (Primer3 default is 47)",
    display: "Primer Pair Max Compl End Th",
    name: "primerPairMaxComplEndTh",
    nameUppercase: "PRIMER_PAIR_MAX_COMPL_END_TH",
    parameter: "PPMCET",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: false,
    description:
      "Primer3 parameter: use the Santalucia formula for calculating Tms (1 = TRUE, 0 = FALSE) (Primer3 default is 0 (FALSE))",
    display: "Primer Tm Santalucia",
    name: "primerTmSantaLucia",
    nameUppercase: "PRIMER_TM_SANTALUCIA",
    parameter: "PTS",
    type: "Boolean"
  },
  {
    defaultValue: false,
    description:
      "Primer3 parameter: use the salt correction formula for calculating Tms (1 = TRUE, 0 = FALSE) (Primer3 default is 0 (FALSE))",
    display: "Primer Salt Corrections",
    name: "primerSaltCorrections",
    nameUppercase: "PRIMER_SALT_CORRECTIONS",
    parameter: "PSC",
    type: "Boolean"
  },
  {
    defaultValue: 250,
    description:
      "Primer3 parameter: DNA concentration to use when calculating Tms in micromolar (IDT uses 250, Primer3 default is 50)",
    display: "Primer Dna Conc",
    name: "primerDnaConc",
    nameUppercase: "PRIMER_DNA_CONC",
    parameter: "PDC",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: 4,
    description:
      "Only warn of mispriming if the BLAST hit between the primer and the template contains the 3' end of the primer (within this number of bp)",
    display: "Mispriming 3 Prime Boundary BP To Warn If Hit",
    name: "mispriming3PBpToWarnIfHit",
    nameUppercase: "MISPRIMING_3PRIME_BOUNDARY_BP_TO_WARN_IF_HIT",
    parameter: "M3BBTWIH",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: 45,
    description:
      "The minimum approximate Tm to consider a significant mispriming event",
    display: "Mispriming Min Tm",
    name: "misprimingMinTm",
    nameUppercase: "MISPRIMING_MIN_TM",
    parameter: "MMT",
    type: "Float",
    isRequired: true
  },
  {
    defaultValue: 0.05,
    description:
      "The salt concentration used when estimating the mispriming Tm in Molar",
    display: "Mispriming Salt Conc",
    name: "misprimingSaltConc",
    nameUppercase: "MISPRIMING_SALT_CONC",
    parameter: "MSC",
    type: "Float",
    isRequired: true
  },
  {
    defaultValue: 2.5e-7,
    description:
      "The oligo concentration used when estimating the mispriming Tm in Molar",
    display: "Mispriming Oligo Conc",
    name: "misprimingOligoConc",
    nameUppercase: "MISPRIMING_OLIGO_CONC",
    parameter: "MOC",
    type: "Float",
    isRequired: true
  },
  // {
  //     "defaultValue": "Genbank",
  //     "description": "\"The output sequence file format. Options are: \"Genbank\", \"FASTA\", \"jbei-seq\", or \"SBOLXML\"\"",
  //     "display": "Output Sequence Format",
  //     "name": "outputSequenceFormat",
  //     "nameUppercase": "OUTPUT_SEQUENCE_FORMAT",
  //     "parameter": "OSF",
  //     "type": "String"
  // },
  // {
  //     "defaultValue": "circular",
  //     "description": "\"Determines whether the assembled DNA product will be circular or linear. Options are: \"circular\" or \"linear\"\"",
  //     "display": "Assembly Product Type",
  //     "name": "assemblyProductType",
  //     "nameUppercase": "ASSEMBLY_PRODUCT_TYPE",
  //     "parameter": "APT",
  //     "type": "String"
  // },
  {
    defaultValue: false,
    description:
      '"Suppress the output of pure primers. Options are: "TRUE" or "FALSE""',
    display: "Suppress Pure Primers",
    name: "suppressPurePrimers",
    nameUppercase: "SUPPRESS_PURE_PRIMERS",
    parameter: "SPP",
    type: "Boolean"
  },
  {
    defaultValue: false,
    description:
      '"Suppress primer annotations. Options are: "TRUE" or "FALSE""',
    display: "Suppress Primer Annotations",
    name: "suppressPrimerAnnotations",
    nameUppercase: "SUPPRESS_PRIMER_ANNOTATIONS",
    parameter: "SPA",
    type: "Boolean"
  },
  // {
  //     "defaultValue": "combinatorial",
  //     "description": "\"Determines whether or not to treat the design as combinatorial or to treat each row as its own construct. Options are: \"combinatorial\" or \"list\"",
  //     "display": "Design Layout Type",
  //     "name": "combinatorialDesignLayoutType",
  //     "nameUppercase": "COMBINATORIAL_DESIGN_LAYOUT_TYPE",
  //     "parameter": "DLT",
  //     "type": "String"
  // },
  {
    defaultValue: 26,
    display: "Homology Min Length BPs",
    description:
      "The minimum length in bps for a homologous sequence repeat to be considered significant",
    name: "hmlgyMinLengthBps",
    nameUppercase: "HOMOLOGY_MIN_LENGTH_BPS",
    parameter: "HMLB",
    type: "int",
    isRequired: true
  },
  {
    defaultValue: 0.05,
    display: "Homology Max Fraction MisMatches",
    description:
      "The maximum fraction of mismatches for a homologous sequence repeat to be considered significant",
    name: "hmlgyMaxFractionMismatches",
    nameUppercase: "HOMOLOGY_MAX_FRACTION_MISMATCHES",
    parameter: "HMFM",
    type: "Float",
    isRequired: true
  }
  // {
  //   defaultValue: false,
  //   display: 'Include Lineage Features',
  //   name: 'includeLineageFeatures',
  //   nameUppercase: 'TRACK_LINEAGE',
  //   parameter: 'ILF', // this is a guess but I don't think this is being used
  //   type: 'Boolean'
  // }
  // { // this is superceded by output naming templates
  //   defaultValue: false,
  //   display: 'Append UUID to Plasmid Oligo and Synthesis name',
  //   name: 'appendUuidToName',
  //   nameUppercase: 'APPEND_UUID_TO_PLASMID_OLIGO_AND_SYNTHESIS_NAME',
  //   parameter: 'UUID',
  //   type: 'Boolean'
  // }
  // {
  //     "defaultValue": "Default Group",
  //     "display": "Restriction Digest Group",
  //     "name": "userRestrictionDigestGroup",
  //     "nameUppercase": "USER_RESTRICTION_DIGEST_GROUP",
  //     "parameter": "URSDG",
  //     "type": "String"
  // }
];

const format = v => {
  return v ? filterSequenceString(v)[0] : v;
};

export const goldenGateSpecificParams = [
  {
    defaultValue: 4,
    description: (
      <span>
        <b>Enforced by the selected type IIs enzyme</b>
        <br />
        <br />
        The number of bps of the overhang resulting from the Golden Gate type
        IIs endonuclease digestion
      </span>
    ),
    display: "Golden Gate Overhang BPs",
    name: "ggateOverhangBps",
    nameUppercase: "GOLDENGATEOVERHANGBPS",
    parameter: "GGOHB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: true
  },
  {
    defaultValue: "GGTCTC",
    description: (
      <span>
        <b>Enforced by the selected type IIs enzyme.</b>
        <br />
        <br />
        The Golden Gate type IIs endonuclease recognition site sequence
      </span>
    ),
    display: "Golden Gate Recognition Seq",
    name: "ggateRecognitionSeq",
    nameUppercase: "GOLDENGATERECOGNITIONSEQ",
    parameter: "GGRS",
    type: "String",
    isRequired: true,
    hidden: false,
    disabled: true,
    format
  },
  {
    defaultValue: "CACACCAGGTCTCA",
    description:
      "The extra 5' sequence required at each end of a Golden Gate assembly piece, e.g. NNNNNNNGGCTCTN for BsaI (Eco31I)",
    display: "Golden Gate Termini Extra Seq",
    name: "ggateTerminiExtraSeq",
    nameUppercase: "GOLDENGATETERMINIEXTRASEQ",
    parameter: "GGTES",
    type: "String",
    isRequired: true,
    hidden: false,
    disabled: false,
    format
  },
  {
    defaultValue: "CACACCAGGTCTCA",
    description: "Golden Gate Term Extra Seq DNA Synth Alt",
    display: "Golden Gate Term Extra Seq DNA Synth Alt",
    name: "ggateTerminiExtraSeqAlt",
    nameUppercase: "GOLDENGATETERMINIEXTRASEQDNASYNTHESISALT",
    parameter: "GGTESALT",
    type: "String",
    isRequired: true,
    hidden: false,
    disabled: false,
    format
  },
  {
    defaultValue: 2,
    description:
      "The maximum number of tolerable non-gapped aligned identities between compatible overhang sequences for Goldenate assembly",
    display: "Max Identities Golden Gate Overhangs Compatible",
    name: "maxIdsGgateOverhangsCmptbl",
    nameUppercase: "MAXIMUM_IDENTITIES_GOLDEN_GATE_OVERHANGS_COMPATIBLE",
    parameter: "MIGGOC",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  }
];

export const flankingHomologySpecificParams = [
  {
    defaultValue: 26,
    description:
      "The minimum number of bps for SLIC/Gibson/CPEC overlaps (should be an even number), this is also the starting design length for the annealing portion of primers",
    display: "Gibson Overlap BPs",
    name: "gibsonOverlapBps",
    nameUppercase: "GIBSONOVERLAPBPS",
    parameter: "GOB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 60,
    description: "The minimum desired Tm for SLIC/Gibson/CPEC overlaps",
    display: "Gibson Overlap Min Tm",
    name: "gibsonOverlapMinTm",
    nameUppercase: "GIBSONOVERLAPMINTM",
    parameter: "GOMT",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 70,
    description: "The maximum desired Tm for SLIC/Gibson/CPEC overlaps",
    display: "Gibson Overlap Max Tm",
    name: "gibsonOverlapMaxTm",
    nameUppercase: "GIBSONOVERLAPMAXTM",
    parameter: "GOMAXT",
    type: "Float",
    isRequired: true,
    hidden: false,
    disabled: false
  },
  {
    defaultValue: 250,
    description: "The minimum fragment size for SLIC/Gibson assembly",
    display: "Min Fragment Size Gibson BPs",
    name: "minFragmentSizeGibsonBps",
    nameUppercase: "MINIMUMFRAGMENTSIZEGIBSONBPS",
    parameter: "MFSGB",
    type: "int",
    isRequired: true,
    hidden: false,
    disabled: false
  }
];
