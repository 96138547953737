/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { withProps } from "recompose";
import StepForm from "../../../../src-shared/StepForm";
import ReviewWorklists from "../AliquotRearrayTool/Steps/ReviewWorklist";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { SelectPlates, TransformationPrep } from "./Steps";
import {
  microbialTransformationPlateFragment,
  microbialTransformationTubeFragment
} from "./fragments";
import { validate } from "./validate";
import { onSubmit } from "./onSubmit";

const MicrobialTransformationTool = ({
  toolIntegrationProps,
  toolSchema,
  isToolIntegrated,
  initialValues
}) => {
  const steps = [
    {
      title: "Select Plates",
      Component: SelectPlates,
      withCustomFooter: true
    },
    {
      title: "Transformation Prep",
      Component: TransformationPrep,
      withCustomFooter: true
    },
    {
      title: "Review Worklist",
      Component: ReviewWorklists,
      withCustomFooter: true,
      props: {
        toolSchema,
        reactionMapMessage:
          "This tool will output a reaction map, which will connect input and output materials for each reaction planned by this tool. Enter a name for this microbial transformation reaction map."
      }
    }
  ];

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      validate={validate}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default compose(
  withWorkflowInputs(microbialTransformationPlateFragment),
  withWorkflowInputs(microbialTransformationTubeFragment),
  withProps(props => {
    const { containerArrays = [], aliquotContainers = [] } = props;
    let inputType;
    if (aliquotContainers.length || containerArrays.length) {
      inputType =
        aliquotContainers.length && !containerArrays.length ? "TUBE" : "PLATE";
    }
    return {
      initialValues: {
        containerArrays,
        aliquotContainers,
        inputType
      }
    };
  })
)(MicrobialTransformationTool);
