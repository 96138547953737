/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import InventorySearchDialog from "./InventorySearchDialog";

const InventorySearchMenuItem = ({
  selectionHasAtLeastOneElement,
  selectedElement,
  selectedElementFas,
  selectedBinOverhangs,
  selectedCellPaths,
  selectedInputCards,
  createElements,
  changeFas,
  reactionRestrictionEnzyme
}) => (
  <MenuItem
    key="inventorySearch"
    text="Search in Inventory"
    disabled={!selectionHasAtLeastOneElement}
    onClick={() =>
      showDialog({
        ModalComponent: InventorySearchDialog,
        modalProps: {
          element: selectedElement,
          fas: selectedElementFas,
          overhangs: selectedBinOverhangs,
          // NOTE: we don't yet support inventory search for multiple elements.
          // but if/when we do, we'll have to make elementDesignInfo an array.
          elementDesignInfo: {
            binId: selectedCellPaths[0].binId,
            cardId: selectedInputCards[0].id,
            index: selectedCellPaths[0].index
          },
          createElements: createElements,
          changeFas: changeFas,
          restrictionEnzyme: reactionRestrictionEnzyme
        }
      })
    }
  />
);

export default InventorySearchMenuItem;
