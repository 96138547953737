/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* Copyright (C) 2019 TeselaGen Biotechnology, Inc. */

import { safeQuery } from "../../../src-shared/apolloMethods";
import Promise from "bluebird";
import designTypeToFragmentMap from "../../../../tg-iso-design/graphql/fragments/designLoadFragment/designTypeToFragmentMap";
import { dbToStateConversion } from "../../../../tg-iso-design/utils/designStateUtils";

const piecemealDesignQuery = async designId => {
  let design = {};

  await Promise.mapSeries(
    Object.entries(designTypeToFragmentMap),
    async ([type, fragment]) => {
      const data = await safeQuery(fragment, {
        variables: { id: designId }
      });
      if (type === "design") {
        design = { ...data, ...design };
      } else {
        const { stateType, stateData } = dbToStateConversion(data, type);
        design[stateType] = stateData;
      }
    }
  );

  return design;
};

export default piecemealDesignQuery;
