/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
module.exports = [
  "sequence",
  "strain",
  "material",
  "aminoAcidSequence",
  "customerRequest",
  "aliquotContainer",
  "reactionMap",
  "reactionDesign",
  "containerArray",
  "equipmentItem",
  "oligo",
  "design"
];
