/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import ThreeDotMenuButton from "../components/ThreeDotMenuButton";

interface Props {
  header: string;
  helper: string;
  width?: string;
  menuItems?: any;
}

const HeaderWithHelper = function ({
  header,
  helper,
  width,
  menuItems
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: width ? width : menuItems ? "100%" : "50%"
      }}
    >
      <div
        style={{
          marginRight: 30,
          marginBottom: 20
        }}
      >
        <h6 style={{ marginBottom: 5 }}>{header}</h6>
        <i style={{ marginBottom: 20 }}>{helper}</i>
      </div>
      {(Array.isArray(menuItems) ? !!menuItems.length : menuItems) && (
        <ThreeDotMenuButton menuItems={menuItems}></ThreeDotMenuButton>
      )}
    </div>
  );
};

export default HeaderWithHelper;
