/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const tuplingToolPlateFragment = gql`
  fragment tuplingToolPlateFragment on containerArray {
    id
    name
    displayFilter
    createdAt
    updatedAt
    containerArrayType {
      id
      name
      isPlate
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      additives {
        id
      }
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
        sample {
          id
          name
          material {
            id
            name
            materialTypeCode
            polynucleotideMaterialSequence {
              id
              name
              sequenceTypeCode
              circular
              sequenceFragments(pageNumber: 1, pageSize: 1) {
                id
                fragment
              }
              sequenceFeatures {
                id
                name
                start
                end
                strand
                type
                notes
              }
              parts {
                id
                name
                start
                end
                strand
              }
            }
          }
        }
      }
    }
  }
`;
