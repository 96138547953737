/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default {
  destinationType: "destinationType",
  plateBaseName: "plateBaseName",
  enableMultipleReactions: "enableMultipleReactions",
  numberOfReactionsPerWell: "numberOfReactionsPerWell",
  applyUniversalTransfersMaterial:
    "materialTransferSettings.applyUniversalTransfer",
  applyUniversalTransfersReagent:
    "reagentTransferSettings.applyUniversalTransfer",
  universalTransferVolumeMaterial: "materialTransferSettings.transferVolume",
  universalTransferVolumetricUnitMaterial:
    "materialTransferSettings.transferVolumetricUnitCode",
  universalTransferMassMaterial: "materialTransferSettings.transferMass",
  transferTypeMaterial: "materialTransferSettings.transferType",
  transferTypeReagent: "reagentTransferSettings.transferType",
  universalTransferMassUnitMaterial:
    "materialTransferSettings.transferMassUnitCode",
  universalTransferVolumeReagent: "reagentTransferSettings.transferVolume",
  universalTransferVolumetricUnitReagent:
    "reagentTransferSettings.transferVolumetricUnitCode",
  universalTransferMassReagent: "reagentTransferSettings.transferMass",
  universalTransferMassUnitReagent:
    "reagentTransferSettings.transferMassUnitCode"
};
