/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment locationPartialFragment on location {
    id
    name
    path
    siteId
  }
`;
