/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment plateMapGroupRecordFragment on plateMapGroup {
    id
    user {
      id
      username
    }
    name
    containerFormatCode
    createdAt
    updatedAt
    plateMaps {
      id
      type
      metadata
      name
      plateMapItems {
        id
        name
        rowPosition
        columnPosition
        metadata
        volume
        volumetricUnitCode
        inventoryItem {
          id
          inventoryItemTypeCode
          aliquot {
            id
          }
          aliquotContainer {
            id
          }
          material {
            id
            name
          }
          sample {
            id
            name
          }
          additiveMaterial {
            id
            name
          }
          lot {
            id
            name
          }
        }
        j5Item {
          id
          j5ItemTypeCode
          j5InputSequence {
            id
            isStock
            sequence {
              id
              name
              size
            }
          }
          j5DirectSynthesis {
            id
            sequence {
              id
              name
              size
            }
          }
          j5Oligo {
            id
            name
            sequence {
              id
              name
              size
            }
          }
          j5PcrReaction {
            id
            name
            oligoDeltaTm
            oligoMeanTm
            primaryTemplate {
              id
              name
              size
            }
            forwardPrimer {
              id
              sequence {
                id
                name
              }
            }
            reversePrimer {
              id
              sequence {
                id
                name
              }
            }
            pcrProductSequence {
              id
              name
              size
            }
          }
        }
      }
    }
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
`;
