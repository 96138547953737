/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import isIdClientGenerated from "../../../../../tg-iso-shared/utils/isIdClientGenerated";

async function getSourceAliquotContainerIds(
  aliquotFormulations,
  apolloMethods
) {
  const { safeQuery } = apolloMethods;

  let aliquotIds = [];
  const sourceAliquotContainerIds = aliquotFormulations
    .reduce((acc, f) => {
      f.transfers.forEach(t => {
        if (t.aliquotContainerId) {
          if (!acc.includes(t.aliquotContainerId)) {
            acc.push(t.aliquotContainerId);
          }
        } else if (t.aliquotId) {
          aliquotIds.push(t.aliquotId);
        }
      });
      return acc;
    }, [])
    .filter(id => !isIdClientGenerated(id));
  aliquotIds = aliquotIds.filter(id => !isIdClientGenerated(id));
  if (aliquotIds.length) {
    const moreAliquotContainers = await safeQuery(["aliquotContainer", "id"], {
      variables: {
        filter: {
          aliquotId: aliquotIds
        }
      }
    });
    moreAliquotContainers.forEach(ac => {
      if (!sourceAliquotContainerIds.includes(ac.id)) {
        sourceAliquotContainerIds.push(ac.id);
      }
    });
  }
  return sourceAliquotContainerIds;
}

export default getSourceAliquotContainerIds;
