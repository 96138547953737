/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getRangeLength } from "@teselagen/range-utils";
import { get, flatMap } from "lodash";
import { compose } from "recompose";
import { getSequence } from "../../../tg-iso-shared/src/utils/getSequence";

const processInputParts = inputParts =>
  inputParts.map(inputPart => {
    if (inputPart.metadata) {
      return inputPart;
    }
    const part = get(inputPart, "part") || {};
    return {
      ...inputPart,
      part,
      size: getRangeLength(
        {
          start: part.start,
          end: part.end
        },
        get(part, "sequence.size")
      )
    };
  });

const processJ5RunConstructs = j5RunConstructs =>
  j5RunConstructs.map(j5RunConstruct => {
    let partsContainedNames =
      get(
        j5RunConstruct,
        "j5ConstructAssemblyPieces[0].assemblyPiece.j5AssemblyPieceParts[0].j5InputPart.part.name"
      ) &&
      flatMap(
        j5RunConstruct.j5ConstructAssemblyPieces,
        j5ConstructAssemblyPiece =>
          j5ConstructAssemblyPiece.assemblyPiece.j5AssemblyPieceParts.map(
            j5InputPart => j5InputPart.j5InputPart.part?.name
          )
      ).join(", ");

    if (!get(j5RunConstruct, "j5ConstructAssemblyPieces[0].assemblyPiece")) {
      partsContainedNames = j5RunConstruct.partNames;
    }

    return {
      ...j5RunConstruct,
      id: j5RunConstruct.id,
      nextLevelParts: (get(j5RunConstruct, "sequence.parts") || [])
        .map(part => part.name)
        .join(", "),
      partsContainedNames
    };
  });

const processPrebuiltConstructs = j5RunConstructs =>
  j5RunConstructs.map(j5RunConstruct => ({
    ...j5RunConstruct,
    id: j5RunConstruct.id,
    nextLevelParts: (get(j5RunConstruct, "sequence.parts") || [])
      .map(part => part.name)
      .join(", "),
    partsContainedNames: j5RunConstruct.partNames
  }));

const getInputPartOrMetadata = j5InputParts =>
  j5InputParts.map(inputPart => {
    if (inputPart.metadata) {
      return {
        metadata: inputPart.metadata
      };
    }
    return inputPart;
  });

// const processInputSequences = j5InputSequences =>
//   j5InputSequences.map(s => {
//     if (s.sequence) {
//       return {
//         ...s,
//         sequence: { ...s.sequence, id: s.sequence.id }
//       };
//     } else {
//       return s;
//     }
//   });

const processJ5OligoSynthesis = j5Oligos =>
  j5Oligos.map(j5Oligo => {
    // todo this shouldn't rely on the oligo name to parse the target parts
    // if someone uses output naming templates for oligos then this will not work
    const partNames = getWrappedInParensMatches(j5Oligo.name);

    let bps;
    if (get(j5Oligo, "oligo.sequence")) {
      bps = getSequence(j5Oligo.oligo.sequence);
    }
    return {
      ...j5Oligo,
      firstTargetPart: partNames[0],
      lastTargetPart: partNames[1],
      bps
    };
  });

const processJ5AnnealedOligo = j5Oligos => {
  return j5Oligos.map(j5Oligo => {
    let targetPart = "";
    try {
      const topOligo = j5Oligo.oligo.j5AnnealedOligosTopOligos[0];
      const bottomOligo = j5Oligo.oligo.j5AnnealedOligosBottomOligos[0];
      const oligo = topOligo || bottomOligo;

      if (oligo.sequence.j5AssemblyPiece.j5AssemblyPieceParts) {
        targetPart = oligo.sequence.j5AssemblyPiece.j5AssemblyPieceParts
          .map(j5tp => j5tp.j5InputPart.part.name)
          .join(", ");
      } else {
        targetPart = oligo.j5InputPart.part.name;
      }
    } catch (e) {
      targetPart = "not found";
    }

    return {
      ...j5Oligo,
      targetPart
    };
  });
};

function getWrappedInParensMatches(s) {
  const matches = [];
  s.replace(/\((.*?)\)/g, function (g0, g1) {
    matches.push(g1);
  });
  return matches;
}

export default {
  // j5InputSequence: processInputSequences,
  j5InputPart: compose(processInputParts, getInputPartOrMetadata),
  j5OligoSynthesis: processJ5OligoSynthesis,
  j5AnnealedOligo: processJ5AnnealedOligo,
  // j5DirectSynthesis: processJ5DirectSyntheses,
  j5RunConstruct: processJ5RunConstructs,
  prebuiltConstruct: processPrebuiltConstructs
};
