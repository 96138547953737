/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { times } from "lodash";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function getInsertRowIndexHelper({ isAbove, selectedIndices, numRows }) {
  let lastDelta = 0;
  // compute the change
  const rowIndexToDelta = times(numRows, i => {
    let deltaForRow = lastDelta;
    if (selectedIndices.includes(i)) {
      lastDelta++;
      if (isAbove) deltaForRow++;
    }
    return deltaForRow;
  });
  const getNewIndex = rowIndex => {
    return rowIndex + rowIndexToDelta[rowIndex];
    // tgreen: this is the old logic. very confused by what is supposed to be happening...
    // was not passing unit tests
    // if (rowIndex >= firstIndexSelected && rowIndex <= lastIndexSelected) {
    //   if (firstIndexSelected === 0) {
    //     return isAbove
    //       ? rowIndex + rowIndex + 1
    //       : rowIndex +
    //           Math.abs(numSelectedRows - (lastIndexSelected - rowIndex + 1));
    //   } else if (firstIndexSelected % 2 === 0 && firstIndexSelected !== 0) {
    //     console.log(`here`);
    //     return isAbove
    //       ? rowIndex + rowIndex - 1
    //       : rowIndex +
    //           Math.abs(numSelectedRows - (lastIndexSelected - rowIndex + 1));
    //   } else {
    //     return isAbove ? rowIndex + rowIndex : rowIndex + rowIndex - 1;
    //   }
    // } else if (rowIndex > lastIndexSelected) {
    //   return rowIndex + numSelectedRows;
    // }
  };
  return getNewIndex;
}

export function getDeleteRowsIndexHelper({ numRows, rowsIndicesToDelete }) {
  let lastDelta = 0;
  const rowIndexToDelta = times(numRows, i => {
    if (rowsIndicesToDelete.includes(i)) lastDelta--;
    return lastDelta;
  });

  const getNewIndex = rowIndex => {
    return rowIndex + rowIndexToDelta[rowIndex];
  };

  return getNewIndex;
}
