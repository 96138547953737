/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, FileUploadField } from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";
import { DesignImport } from "../../utils/designImportProcess";
import { startImportCollection } from "../../../src-shared/utils/importCollection";

function UploadDesigns(props) {
  const { hideModal, handleSubmit, submitting, history, refetch } = props;
  async function onSubmit(values) {
    try {
      await startImportCollection("Design Upload");
      const designImport = new DesignImport(history, false, refetch);
      await designImport.processImport(values);
    } catch (err) {
      console.error(err);
      window.toastr.error(err.message || "Error uploading design.");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          accept={[
            { type: ".csv", exampleFile: "design_example.csv" },
            { type: ".xlsx", exampleFile: "design_example.xlsx" },
            {
              type: ".xml",
              description: "DIVA Design File",
              exampleFile: "Combinatorial Golden Gate.xml"
            },
            {
              type: ".json",
              exampleFiles: [
                {
                  description: "Download Simple Format Example",
                  exampleFile: "exampleDesignSimpleFormat.json"
                },
                {
                  icon: "link",
                  description: "View Simple Format",
                  exampleFile:
                    "https://teselagen.github.io/json-schema-viewer/#/view/%23?url=.%2Fschemas%2Fdesign.json"
                },
                {
                  description: "Download Complex Format Example",
                  exampleFile: "Golden Gate.json"
                }
              ]
            },
            {
              type: ".zip",
              exampleFile: "Golden Gate.zip"
            }
          ]}
          label="Upload Design File"
          name="inputFiles"
          isRequired
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Upload Designs"
  }),
  reduxForm({
    form: "uploadDesign"
  })
)(UploadDesigns);
