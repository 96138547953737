/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { withState, withHandlers, compose } from "recompose";

export default compose(
  withState("page", "setPage", 1),
  withHandlers({
    previousPage: ({ setPage, page }) => () => setPage(page - 1),
    nextPage: ({ setPage, page }) => () => setPage(page + 1)
  })
);
