/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const includeTagModels = [
  "additiveMaterial",
  "alignment",
  "aliquot",
  "aliquotContainer",
  "aminoAcidSequence",
  "containerArray",
  "customerRequest",
  "dataGrid",
  "dataSet",
  "dataTable",
  "enzyme",
  "equipmentItem",
  "functionalProteinUnit",
  "genome",
  "j5Report",
  "job",
  "lot",
  "part",
  "labProtocol",
  "material",
  "plateMapGroup",
  "reactionMap",
  "sample",
  "sequence",
  "sequenceFeature",
  "strain",
  "workflowRun",
  "worklist",
  "design"
];

module.exports = includeTagModels;
