/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import caseInsensitiveFilter from "../utils/caseInsensitiveFilter";

const preventDuplicationOfSequenceCsvTags = async (query, valuesToUpsert) => {
  const tagNamesMap = {};
  valuesToUpsert.forEach(sequence => {
    const { taggedItems = [], parts = [] } = sequence;

    taggedItems.forEach(ti => {
      tagNamesMap[ti.tag.name] = true;
    });

    parts.forEach(part => {
      part.taggedItems.forEach(pt => {
        tagNamesMap[pt.tag.name] = true;
      });
    });
  });

  // console.log(`tagNamesMap`, tagNamesMap);

  const existingTags = await query(["tag", "id name"], {
    isPlural: true,
    variables: {
      filter: caseInsensitiveFilter("tag", "name", Object.keys(tagNamesMap))
    }
  });

  const existTagsNamesMap = keyBy(existingTags, t => t.name.toLowerCase());

  // console.log(`existTagsNamesMap`, existTagsNamesMap);

  const tagsNotFoundInDbMap = {};
  valuesToUpsert.forEach(sequence => {
    const { taggedItems = [], parts = [] } = sequence;

    // console.log(`sequence with all tags:`, sequence);
    taggedItems.forEach(ti => {
      const {
        tag: { name }
      } = ti;
      delete ti.tag;
      const existingTag = existTagsNamesMap[name.toLowerCase()];
      if (existingTag) {
        ti.tagId = existingTag.id;
      } else {
        tagsNotFoundInDbMap[name] = true;
      }
    });

    sequence.taggedItems = taggedItems.filter(ti => !!ti.tagId);

    parts.forEach(part => {
      part.taggedItems.forEach(ti => {
        const {
          tag: { name }
        } = ti;
        delete ti.tag;
        const existingTag = existTagsNamesMap[name.toLowerCase()];
        if (existingTag) {
          ti.tagId = existingTag.id;
        } else {
          tagsNotFoundInDbMap[name] = true;
        }
      });
      part.taggedItems = part.taggedItems.filter(ti => !!ti.tagId);
    });

    // console.log(`sequence with valid tags:`, sequence);
  });

  const tagsNotFoundInDb = Object.keys(tagsNotFoundInDbMap);

  // console.log("tagsNotFoundInDb", tagsNotFoundInDb);

  return { tagsNotFoundInDb };
};

export default preventDuplicationOfSequenceCsvTags;
