/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import GqlErrorFormatter from "./formatter";

class NullConstraintFormatter extends GqlErrorFormatter {
  static TYPE = "NULL";

  static check(errorMessage) {
    return errorMessage.includes("violates not-null constraint");
  }

  static handler({ error, operation }) {
    const { operationName } = operation;
    const modelNameRegex = new RegExp(/insert into "(.*?)"/);
    const missingColumnRegex = new RegExp(
      /null value in column "(.*?)" of relation/
    );
    const modelName = get(error.message.match(modelNameRegex), "1");
    const missingColumnName = get(error.message.match(missingColumnRegex), "1");
    const readableName = modelNameToReadableName(modelName) || "model";
    error.message = `[${NullConstraintFormatter.TYPE}]: ${operationName} attempt on ${readableName}. Missing value for ${missingColumnName}`;
    error.type = NullConstraintFormatter.TYPE;
  }
}

export default NullConstraintFormatter;
