/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent, MenuItem } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { get, isEqual } from "lodash";

import AbstractRecord from "../../../../src-shared/AbstractRecord";
import { getSequencesToUse } from "../../../utils";
import { renderMaterialsField } from "../../../utils/plateUtils";

import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import RehydrateAliquotDialog from "../../Dialogs/RehydrateAliquotDialog";
import DehydrateAliquotDialog from "../../Dialogs/DehydrateAliquotDialog";
import PlateMapPlate from "../../PlateMapPlate";
import VeCard from "../MaterialRecordView/VeCard";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import DisplayGrowthConditions from "../../DisplayGrowthConditions";
import AdditivesTableCard from "./AdditivesTableCard";
import {
  massRender,
  volumeRender,
  concentrationRender,
  molarityRender,
  cellConcentrationRender
} from "../../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import StrainRecordInfo, {
  strainLink
} from "../StrainRecordView/StrainRecordInfo";
import AliquotsTableCard from "../../AliquotsTableCard";
import AminoAcidSequenceCard from "../ProteinRecordView/AminoAcidSequenceCard";
import ManuallyAssignAliquotDialog from "../../Dialogs/ManuallyAssignAliquotDialog";
import SampleHistoryCard from "./SampleHistoryCard";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import { branch, compose, withProps } from "recompose";
import withLocationLink from "../../../graphql/enhancers/withLocationLink";
import UpdateAliquotDialog from "../../Dialogs/UpdateAliquotDialog";
import SequenceJ5ItemTableCard from "../../SequenceJ5ItemTableCard";
import { withExperimentalDataRecords } from "../../../../src-shared/utils/assaySubjectUtils";
import { updateSequenceFromProps } from "../../../../src-shared/utils/sequenceUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import shortid from "shortid";
import aliquotRecordFragment from "../../../../src-shared/graphql/fragments/aliquotRecordFragment";

export function aliquotUnitFields(aliquot) {
  const info = [];
  if (aliquot.isDry) {
    info.push(["Mass", massRender(aliquot)]);
  } else {
    info.push(["Volume", volumeRender(aliquot)]);
    info.push(["Concentration", concentrationRender(aliquot)]);
    info.push(["Molarity", molarityRender(aliquot)]);
    if (aliquot.cellConcentration) {
      info.push(["Cell Concentration", cellConcentrationRender(aliquot)]);
    }
  }
  if (aliquot.cellCount) {
    info.push(["Cell Count", aliquot.cellCount]);
  }
  return info;
}

class AliquotRecordView extends Component {
  state = { additiveTableKey: shortid() };

  renderLinkOrNotFound({ aliquot, path, render }) {
    const item = get(aliquot, path);
    return item ? (
      <Link to={modelNameToLink(item.__typename, item.id)}>
        {render ? render(item) : item.name}
      </Link>
    ) : (
      "Not Found"
    );
  }

  handleAliquotContainerDblClick = aliquotContainer => {
    if (aliquotContainer.aliquot) {
      this.props.history.push(modelNameToLink(aliquotContainer.aliquot));
    }
  };

  renderContent() {
    const { aliquot, aliquotLoading, locationLink } = this.props;
    const material = get(aliquot, "sample.material") || {};
    if (aliquotLoading) return;
    const { aliquotContainer } = aliquot;

    if (aliquot) {
      const recordInfo = [];
      if (get(aliquotContainer, "aliquotContainerType.isTube")) {
        const tubeLink = this.renderLinkOrNotFound({
          aliquot,
          path: "aliquotContainer"
        });
        recordInfo.push(["Tube", tubeLink]);
      }
      if (aliquot.aliquotContainer && aliquot.aliquotContainer.containerArray) {
        const isRack =
          !aliquotContainer.containerArray.containerArrayType.isPlate;

        const plateLink = (
          <Link
            to={modelNameToLink(
              aliquotContainer.containerArray.__typename,
              aliquotContainer.containerArray.id
            )}
          >
            {aliquotContainer.containerArray.name}
            {` - `}
            {getAliquotContainerLocation(aliquotContainer)}
          </Link>
        );
        recordInfo.push([isRack ? "Rack Position" : "Plate Well", plateLink]);
      }

      if (locationLink) {
        recordInfo.push(["Location", locationLink]);
      }

      recordInfo.push(
        ...[
          ["Materials", renderMaterialsField(aliquot)],
          ["Sample", this.renderLinkOrNotFound({ aliquot, path: "sample" })],
          [
            "Parent Aliquot",
            this.renderLinkOrNotFound({
              aliquot,
              path: "replicateParentAliquot",
              render: item => "Aliquot " + item.id
            })
          ]
        ]
      );
      recordInfo.push(...aliquotUnitFields(aliquot));
      recordInfo.push(["Aliquot Type", aliquot.aliquotType]);

      const containerArray = get(aliquot, "aliquotContainer.containerArray");
      const { strain } = material;

      return (
        <div>
          <div
            className="tg-double-record-info-container-wrapper"
            // style={{
            //   flex: 1,
            //   flexDirection: "row",
            //   justifyContent: "space-between"
            // }}
          >
            <RecordInfoDisplay
              readOnly={this.props.readOnly}
              recordInfo={recordInfo}
              record={aliquot}
            />
            {containerArray && (
              <PlateMapPlate
                selectedAliquotContainerLocations={[
                  getAliquotContainerLocation(aliquot.aliquotContainer)
                ].filter(x => x)}
                aliquotContainers={generateContainerArray(
                  containerArray.aliquotContainers,
                  containerArray.containerArrayType.containerFormat
                )}
                aliquotContainerDoubleClick={
                  this.handleAliquotContainerDblClick
                }
                containerArrayType={containerArray.containerArrayType}
              />
            )}
          </div>
          {strain && (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 17 }}
            >
              <div>
                <div className="tg-flex align-center">
                  <h6>
                    {strain.strainTypeCode === "MICROBIAL_STRAIN"
                      ? "Strain "
                      : "Cell Line "}
                    Information
                  </h6>
                  <div className="tg-flex-separator" />
                  {strainLink(strain)}
                </div>
                <StrainRecordInfo
                  readOnly={this.props.readOnly}
                  strain={strain}
                  material={material}
                />
              </div>
              <div className="tg-flex-separator" />
              <DisplayGrowthConditions
                showStrainOverwrites
                material={material}
              />
            </div>
          )}
        </div>
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newSequences = getSequencesToUse(nextProps);
    const { oldSequences } = prevState;
    if (!isEqual(newSequences, oldSequences)) {
      updateSequenceFromProps(newSequences);
      return {
        oldSequences: newSequences
      };
    } else {
      return null;
    }
  }

  renderTubeInfo = aliquotContainer => {
    return (
      <React.Fragment key="tube-info">
        <div
          style={{
            display: "flex"
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h6>Tube Information</h6>
              <Link to={modelNameToLink(aliquotContainer)}>
                <Button
                  style={{ marginLeft: 15, marginBottom: 8 }}
                  intent="primary"
                  small
                  minimal
                  text="View Tube"
                  icon="eye-open"
                />
              </Link>
            </div>
            <RecordInfoDisplay
              recordInfo={[
                aliquotContainer.name && ["Name", aliquotContainer.name],
                ["Tube Type", aliquotContainer.aliquotContainerType.name],
                ["Barcode", get(aliquotContainer, "barcode.barcodeString")]
              ]}
            />
          </div>
        </div>
        <hr className="tg-section-break" />
      </React.Fragment>
    );
  };
  getHydrateButton = () => {
    const { aliquot } = this.props;
    if (!aliquot) return null;
    let Dialog, buttonText, icon;
    if (aliquot.isDry) {
      Dialog = RehydrateAliquotDialog;
      buttonText = "Rehydrate Aliquot";
      icon = "tint";
    } else {
      Dialog = DehydrateAliquotDialog;
      buttonText = "Dehydrate Aliquot";
      icon = "flash";
    }
    return (
      <Button
        key="hydrateAliquot"
        text={buttonText}
        minimal
        small
        // intent={Intent.PRIMARY}
        icon={icon}
        onClick={() => {
          showDialog({
            ModalComponent: Dialog,
            modalProps: {
              aliquotId: aliquot.id,
              refetch: () => {
                return this.setState({ additiveTableKey: shortid() });
              }
            }
          });
        }}
      />
    );
  };

  showReplenishDialog = () => {
    const { aliquot } = this.props;
    showDialog({
      modalType: "REPLENISH_ALIQUOT",
      modalProps: {
        aliquotId: aliquot.id
      }
    });
  };

  updateShowFunction = () => {
    const { aliquot } = this.props;
    showDialog({
      ModalComponent: UpdateAliquotDialog,
      modalProps: {
        initialValues: aliquot
      }
    });
  };

  showCreateReplicateDialog = () => {
    const { aliquot, refetchAliquot } = this.props;
    showDialog({
      modalType: "NewReplicateAliquotDialog",
      modalProps: {
        sourceAliquotId: aliquot.id,
        refetch: refetchAliquot
      }
    });
  };

  render() {
    const { aliquot, refetchAliquot, readOnly, showLeftSlideOutDrawer } =
      this.props;
    const material = get(aliquot, "sample.material");
    const materialTypeCode = get(material, "materialTypeCode");
    const sequencesToUse = material ? getSequencesToUse(this.props) : [];
    const materialHasSequences =
      materialTypeCode === "MICROBIAL" || materialTypeCode === "DNA";
    const veCard = materialHasSequences && (
      <VeCard
        key="veCard"
        {...{
          showLeftSlideOutDrawer,
          isMicrobialMaterial: materialTypeCode === "MICROBIAL",
          hasSequences: sequencesToUse.length,
          sequences: sequencesToUse,
          material,
          readOnly: true
        }}
      />
    );
    const aminoAcidCard = materialTypeCode === "PROTEIN" &&
      material.functionalProteinUnit && (
        <AminoAcidSequenceCard
          key="aas"
          functionalProteinUnit={material.functionalProteinUnit}
        />
      );
    const additionalCards = [
      veCard,
      aminoAcidCard,
      <AliquotsTableCard
        key="ReplicateAliquotsTableCard"
        title="Replicate Aliquots"
        additionalFilter={(props, qb) => {
          qb.whereAll({
            replicateParentAliquotId: aliquot.id
          });
        }}
      />,
      <SampleHistoryCard key="sampleHistoryCard" aliquot={aliquot} />,
      <AdditivesTableCard
        key={"AdditivesTableCard" + this.state.additiveTableKey}
        aliquot={aliquot}
        isAliquot
        refetch={refetchAliquot}
        readOnly={readOnly}
      />,
      <SequenceJ5ItemTableCard
        key="sequenceJ5Items"
        sequenceIds={sequencesToUse.map(s => s.id)}
      />,
      this.props.assaySubjectCard,
      this.props.dataGridRowCard
    ];

    const tubeType = get(
      aliquot,
      "aliquotContainer.aliquotContainerType.code",
      ""
    );
    if (tubeType.indexOf("TUBE") > -1) {
      additionalCards.unshift(this.renderTubeInfo(aliquot.aliquotContainer));
    }
    const additionalUpdateMenuItems = [];
    if (!aliquot.isDry && aliquot.sample?.material) {
      additionalUpdateMenuItems.push(
        <MenuItem
          key="replenish"
          disabled={aliquot.isDry}
          text="Replenish"
          onClick={this.showReplenishDialog}
        />
      );
    }
    return (
      <AbstractRecord
        {...this.props}
        recordName="aliquot"
        title={"Aliquot of  " + aliquot.sample.name}
        additionalButtons={[
          <Button
            key="createReplicate"
            icon="duplicate"
            text="Create Replicate"
            onClick={this.showCreateReplicateDialog}
          ></Button>,
          this.getHydrateButton(),
          <Button
            key="assign"
            intent={Intent.PRIMARY}
            small
            text={
              get(aliquot, "aliquotContainer.id")
                ? "Reassign Aliquot"
                : "Assign Aliquot"
            }
            icon="exchange"
            onClick={() => {
              showDialog({
                ModalComponent: ManuallyAssignAliquotDialog,
                modalProps: {
                  aliquot,
                  refetch: refetchAliquot
                }
              });
            }}
          />
        ]}
        updateShowFunction={this.updateShowFunction}
        additionalUpdateMenuItems={additionalUpdateMenuItems}
        additionalCards={additionalCards}
        withExtendedProperties
      >
        {this.renderContent()}
      </AbstractRecord>
    );
  }
}

export default compose(
  recordViewEnhancer(aliquotRecordFragment),
  withProps(props => {
    // aliquot container id is needed for location link
    if (props.aliquot && props.aliquot.aliquotContainer) {
      return {
        aliquotContainerId: props.aliquot.aliquotContainer.id
      };
    }
  }),
  branch(props => props.aliquotContainerId, withLocationLink),
  ...withExperimentalDataRecords()
)(AliquotRecordView);
