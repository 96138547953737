/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import { exampleCustomInfoRecord } from "../exampleRecords";
import {
  customInfoSubtypes,
  customInfoSubtypesArray
} from "../importExportSubtypes";
import { yupExternalReferenceFields } from "../validationFns";

const CUSTOM_INFO = {
  defaultSubtype: customInfoSubtypes.DNA_SEQUENCE,
  subtypes: customInfoSubtypesArray,
  description: `This adds a 'Right Click > View Info' option to records to display custom information from an external source. This integration supports markdown.`,
  endpoints: {
    CUSTOM_INFO: {
      name: "Custom Info Endpoint",
      method: "POST",
      description: `Display custom info for the chosen resource type. Return valid markdown to be rendered (using https://remarkjs.github.io/react-markdown/ ).`,
      exampleRequest: {
        __validate__: () =>
          yup.object({
            record: yup.object({
              id: yup.string().required(),
              internalType: yup.string().required(),
              ...yupExternalReferenceFields({
                doNotRequireExternalFields: true
              })
            })
          }),
        record: exampleCustomInfoRecord
      },
      exampleResponse: {
        __validate__: () =>
          yup.object({
            markdown: yup.string().required()
          }),
        markdown: "I'm some markdown here <br> Cool!"
      }
    }
  }
};

export default CUSTOM_INFO;
