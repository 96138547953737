/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getAuthToken } from "../auth/authTokenMethods";

export const handleIntegrationClick = () => {
  const url =
    window.frontEndConfig.nodeRedEditorUrl + `/?access_token=${getAuthToken()}`;
  if (window.Cypress) {
    window.location.href = url;
  } else {
    //unless we're in a cypress test, open in a new tab
    const win = window.open(url, "_blank");
    win.focus();
  }
};
