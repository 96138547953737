/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { deleteWithQuery, safeDelete } from "../apolloMethods";

const REACTION_MAP = async function({ duplicateRecords }) {
  await safeDelete(
    "reactionMap",
    duplicateRecords.map(({ __oldRecord }) => __oldRecord.id)
  );
};

const REACTION_DESIGN = async function({ duplicateRecords }) {
  const designIds = duplicateRecords.map(({ __oldRecord }) => __oldRecord.id);
  await deleteWithQuery("reactionMap", {
    reactionDesignId: designIds
  });
  await safeDelete("reactionDesign", designIds);
};

const deleteBeforeReimportHandlers = {
  REACTION_MAP,
  REACTION_DESIGN
};

export default deleteBeforeReimportHandlers;
