/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import tgCreateCachedSelector from "../utils/tgCreateCachedSelector";
import {
  getItemOfType,
  getSequenceStringOfSequence
} from "./designStateSelectors";

/**
 * Get sequence from the forward strand that is flanking a given a part.
 * Since the sequence is on the forward strand, the results of this selector
 * will be independent of whether the part is reverse complemented.
 *
 * If the sequence is circular, then the flanking sequence can wrap the origin.
 *
 * @param {Object} state
 * @param {string} partId
 * @param {int} length
 * @param {boolean} fivePrime Whether to get sequence that is five prime or three prime of the given part.
 */
export default tgCreateCachedSelector(
  state => state,
  (state, partId) => partId,
  (state, partId, length) => length,
  (state, partId, length, fivePrime) => !!fivePrime,
  (state, partId, length, fivePrime) => {
    const part = getItemOfType(state, "part", partId);
    const sequence = getItemOfType(state, "sequence", part.sequenceId);
    const sequenceStr = getSequenceStringOfSequence(state, part.sequenceId);

    let flankingSequenceStr = fivePrime
      ? sequenceStr.slice(Math.max(0, part.start - length), part.start)
      : sequenceStr.slice(part.end + 1, part.end + 1 + length);

    // This logic is needed to handle wrapping around the origin
    // for circular sequences.
    if (flankingSequenceStr.length !== length && sequence.circular) {
      const neededLength = length - flankingSequenceStr.length;
      if (fivePrime) {
        flankingSequenceStr =
          sequenceStr.slice(
            Math.max(part.start, sequenceStr.length - neededLength)
          ) + flankingSequenceStr;
      } else {
        flankingSequenceStr += sequenceStr.slice(
          0,
          Math.min(neededLength, part.end)
        );
      }
    }

    return flankingSequenceStr;
  }
)((state, partId, length, fivePrime) => `${partId}:${length}:${!!fivePrime}`);
