/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";

export default () => {
  const designCid = shortid();
  const refDesign = "&" + designCid;
  return [
    {
      entity: "design",
      inputs: {
        cid: designCid,
        type: "design-template",
        layoutType: "combinatorial",
        numRows: 1,
        numPlaceholders: 1
      }
    },
    {
      entity: "card",
      inputs: {
        designId: refDesign,
        isRoot: true,
        name: "Template Head",
        circular: false,
        binCards: [
          {
            designId: refDesign,
            index: 0,
            bin: {
              designId: refDesign,
              direction: true,
              name: "placeholder 1",
              iconId: "&USER-DEFINED",
              isPlaceholder: true
            }
          }
        ]
      }
    }
  ];
};
