/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import pluralize from "pluralize";
import { get } from "lodash-es";
import generateFragmentWithFields from "./generateFragmentWithFields";

export default function generateHasuraQuery(fragOrQuery, options) {
  const { isPlural, queryName, nameOverride, argsOverride } = options;

  if (Array.isArray(fragOrQuery)) {
    const modelName = pluralize.singular(fragOrQuery[0]);
    const fields = fragOrQuery[1];
    fragOrQuery = generateFragmentWithFields(modelName, fields);
  }
  if (typeof fragOrQuery === "string" || typeof fragOrQuery !== "object") {
    throw new Error(
      "Please provide a valid fragment when using withQuery! im on cypress"
    );
  }
  const type = get(fragOrQuery, "definitions[0].operation");
  if (type === "query") {
    // we've got a full query being passed
    // const name = get(fragOrQuery, "definitions[0].name.value");
    const name = get(
      fragOrQuery,
      "definitions[0].selectionSet.selections[0].name.value"
    );

    return {
      gqlQuery: fragOrQuery,
      nameToUse: name,
      isPlural: getIsPluralFromQuery(fragOrQuery, options)
    };
  } else {
    // we've got a fragment being passed
    const name = get(fragOrQuery, "definitions[0].typeCondition.name.value");
    if (!name) {
      console.error("Bad fragment passed to withQuery!!");
      console.error(fragOrQuery, options);
      throw new Error(
        "No fragment name found in withQuery() call. This is due to passing in a string or something other than a gql fragment to withQuery"
      );
    }

    const fragName =
      fragOrQuery && get(fragOrQuery, "definitions[0].name.value");
    const singularName = pluralize.singular(name);
    const pluralName = pluralize(name);
    const nameToUse = nameOverride || (isPlural ? pluralName : singularName);
    const queryNameToUse = queryName || nameToUse + "Query";

    let queryInner = `${fragName ? `...${fragName}` : "id"}`;
    if (isPlural) {
      queryInner = `nodes {
        ${fragName ? `...${fragName}` : "id"}
      }`;
    }

    let gqlQuery;
    if (argsOverride) {
      gqlQuery = gql`
      query ${queryNameToUse} (${argsOverride[0] || ""}) {
        ${nameToUse} (${argsOverride[1] || ""}) {
          ${queryInner}
        }
      }
      ${fragOrQuery ? fragOrQuery : ``}
    `;
    } else if (isPlural) {
      gqlQuery = gql`
      query ${queryNameToUse} ($limit: Int $offset: Int $order_by: [${singularName}_order_by!] $where: ${singularName}_bool_exp) {
        ${nameToUse}(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
          ${queryInner}
        }
      }
      ${fragOrQuery ? fragOrQuery : ``}
      `;
    } else {
      gqlQuery = gql`
        query ${queryNameToUse} ($id: String!) {
          ${nameToUse}(id: $id) {
            ${queryInner}
          }
        }
        ${fragOrQuery ? fragOrQuery : ``}
      `;
    }

    return {
      gqlQuery,
      nameToUse,
      queryNameToUse: queryName,
      isPlural: getIsPluralFromQuery(gqlQuery, options)
    };
  }
}

function getIsPluralFromQuery(fragOrQuery, options) {
  if (options.isPlural) {
    return true;
  }
  const hasNodes =
    get(
      fragOrQuery,
      "definitions[0].selectionSet.selections[0].selectionSet.selections[0].name.value"
    ) === "nodes";
  if (hasNodes) {
    return true;
  }
  return false;
}
