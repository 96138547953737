/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  DialogFooter,
  InputField,
  CheckboxField,
  SelectField,
  wrapDialog
} from "@teselagen/ui";
import PropTypes from "prop-types";

import UnitFields from "../../UnitFields";
import { getAliquotValuesForMaterial } from "../../../utils";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getMaterialMolecularWeight } from "../../../../../tg-iso-lims/src/utils/aliquotUtils";

class CreateAliquotFromMaterialDialog extends Component {
  static propTypes = {
    material: PropTypes.shape({ id: PropTypes.string.isRequired })
  };

  onSubmit = async values => {
    try {
      const { material, history, hideModal } = this.props;
      const { sampleName } = values;
      const aliquotValues = await getAliquotValuesForMaterial({
        ...values,
        material
      });
      const newAliquot = {
        ...aliquotValues,
        sample: {
          name: sampleName,
          materialId: material.id,
          sampleTypeCode: "REGISTERED_SAMPLE"
        }
      };
      const [createdAliquot] = await safeUpsert("aliquot", newAliquot);
      hideModal();
      history.push(
        modelNameToLink(createdAliquot.__typename, createdAliquot.id)
      );
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating aliquot.");
    }
  };

  isMicrobial() {
    const { material = {} } = this.props;
    return (
      material.materialTypeCode === "MICROBIAL" ||
      material.materialTypeCode === "CELL_CULTURE"
    );
  }

  render() {
    const { material, isDry, hideModal, handleSubmit, submitting } = this.props;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="sampleName"
            label="Sample Name"
            isRequired
            defaultValue={material.name}
          />
          <SelectField
            label="Aliquot Type"
            name="aliquotType"
            isRequired
            defaultValue="sample-aliquot"
            options={["sample-aliquot", "replicate"]}
          />
          <CheckboxField name="isDry" label="Dry Aliquot" />
          <UnitFields
            concentrationTypes={[
              ...(this.isMicrobial()
                ? ["cellConcentration"]
                : ["concentration", "molarity"])
            ]}
            molecularWeight={getMaterialMolecularWeight(material)}
            isDry={isDry}
            volumeRequired
            massRequired
            {...this.props}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
          submitting={submitting}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog({ title: "Create Aliquot" }),
  withRouter,
  reduxForm({
    form: "createAliquotFromMaterial"
  }),
  tgFormValues("isDry")
)(CreateAliquotFromMaterialDialog);
