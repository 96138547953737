/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { invert, times } from "lodash";
import {
  getFullAvailableElementCombosOfCard,
  isLayoutList,
  getReferencedValue,
  getFullEliminatedCombinationGroupsOfCard
} from "../../../selectors/designStateSelectors";
import { cartesianProductOf } from "../../../utils/combinatorialUtils";

const compIds = (a, b) => (a || "").localeCompare(b || "");

export const elementsToKey = elements =>
  elements
    .map(el => el.id)
    .sort(compIds)
    .join(",");

const idsToKey = ids => ids.sort(compIds).join(",");

/**
 * Given a key with the ids possibly in the wrong order,
 * create a new key with the ids in the right order.
 * @param {string} key
 */
function recomposeKey(key) {
  return idsToKey(key.split(","));
}

// /**
//  * Given a map from a key to array of keys with ids possibly
//  * in the right order, create the equivalent map with the standardized keys.
//  * @param {Map<String,Array<String>>} map
//  */
// function recomposeKeyToArrayOfKeys(map) {
//   const newMap = {}
//   for (const [key, array] of Object.entries(map)) {
//     const newKey = recomposeKey(key)
//     const newArray = array.map(recomposeKey)
//     newMap[newKey] = newArray
//   }
//   return newMap
// }

/**
 * Create a map from the standardized key of the database element ids
 * to the generated ids that make up all of the possible cobinations resulting
 * from that combination of elements.
 * @param {Array<Sequence>} constructs
 */
function getElementIdsToGeneratedIds(constructs) {
  const map = {};
  for (const { elements } of constructs) {
    const dbKey = idsToKey(elements.map(el => el.elementId));
    const generatedKey = idsToKey(elements.map(el => el.id));

    if (!map[dbKey]) map[dbKey] = [];
    map[dbKey].push(generatedKey);
  }
  return map;
}

/**
 * Create a map from keys representing combinations of elements to
 * the sequence representing the construct. Note that these keys will
 * reflect ids generated during `createCardSequenceMap` and not the
 * actual element ids as in the database. This is required to accomodate
 * part sets.
 * @param {Array<Sequence>} constructs
 */
const createConstructsMap = constructs =>
  constructs.reduce((acc, construct) => {
    const { elements } = construct;
    acc[elementsToKey(elements)] = construct;
    return acc;
  }, {});

export default ({
  state,
  j5Json,
  sequenceMap,
  cardId,
  propagatedSetElementIdsToPartId
}) => {
  const availableCombos = getFullAvailableElementCombosOfCard(state, cardId);
  const eliminatedCombos = getFullEliminatedCombinationGroupsOfCard(
    state,
    cardId
  );

  if (!availableCombos.length && !eliminatedCombos.length) return {};

  const { bins } = j5Json;
  const isListLayout = isLayoutList(state);

  const partIdsToElementKey = invert(propagatedSetElementIdsToPartId);
  const constructsMap = createConstructsMap(sequenceMap[cardId]);
  const elementIdsToGeneratedIds = getElementIdsToGeneratedIds(
    sequenceMap[cardId]
  );

  const originalConstructsMap = { ...constructsMap };

  const eliminatedConstructsMap = {};
  for (const eliminatedCombo of eliminatedCombos) {
    const elementCombinationsInGroup = cartesianProductOf(
      eliminatedCombo.elementGroups
    );
    for (const elements of elementCombinationsInGroup) {
      const key = elementsToKey(elements);

      // Because the key we get from elements will be in terms of database ids
      // and the constructs map contains generated ids, we need to get all of
      // the generated ids created from the given combination of elements.
      const generatedKeys = elementIdsToGeneratedIds[key];
      for (const generatedKey of generatedKeys) {
        if (!originalConstructsMap[generatedKey]) {
          console.warn(
            "Available part combination not found in the constructs map. We should not be here."
          );
        }
        delete constructsMap[generatedKey];
        eliminatedConstructsMap[generatedKey] = true;
      }
    }
  }

  const availableConstructCombos = [];
  for (const combo of availableCombos) {
    const { elements } = combo;
    const key = elementsToKey(elements);
    if (originalConstructsMap[key] && !eliminatedConstructsMap[key]) {
      availableConstructCombos.push(combo);
    } else {
      console.warn(
        "Available part combination not found in the constructs map. We should not be here."
      );
    }

    delete constructsMap[key];
  }

  const possibleCellCombinations = isListLayout
    ? times(bins[0].cells.length, i => bins.map(b => b.cells[i]))
    : cartesianProductOf(bins.map(b => b.cells));

  const actualCellCombinations = possibleCellCombinations.filter(cellComb => {
    const key = recomposeKey(
      cellComb.map(c => partIdsToElementKey[c.part_id]).join(",")
    );

    return !!constructsMap[key];
  });

  bins.forEach((bin, i) => {
    bin.cells = actualCellCombinations.map(cellComb => cellComb[i]);
  });

  j5Json.parameters.COMBINATORIAL_DESIGN_LAYOUT_TYPE = "list";

  const prebuiltConstructInfo = [];
  for (const combo of availableConstructCombos) {
    const sequence = getReferencedValue(
      state,
      "part",
      combo.availablePartId,
      "sequenceId"
    );

    prebuiltConstructInfo.push({
      sequenceId: sequence.id,
      size: sequence.size,
      partNames: combo.elements.map(el => el.name).join(", ")
    });
  }
  return { prebuiltConstructInfo, dontRunJ5: !actualCellCombinations.length };
};
