/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, keyBy, pick } from "lodash";
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import { copyRecordsTaggedItems } from "../../../tg-iso-shared/src/tag-utils";
import {
  dataItemTypeCodeToModel,
  dataItemTypeMap,
  inventoryItemTypeCodeToModel,
  inventoryItemTypeMap
} from "../../../tg-iso-shared/src/utils/inventoryUtils";
import modelNameToReadableName from "../../../tg-iso-shared/src/utils/modelNameToReadableName";
import { workflowToolDefinitionFragment } from "../fragments/newWorkflowDefinitionFragment";
import { identity } from "lodash";
import { getCaseInsensitiveKeyedItems } from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";

export const workflowToolStatuses = {
  ready: "READY",
  blocked: "BLOCKED",
  skipped: "SKIPPED",
  completed: "COMPLETED"
};

export function getIOBackupLabel(ioSchema) {
  return (
    ioSchema.label ||
    modelNameToReadableName(
      inventoryItemTypeCodeToModel[ioSchema.inventoryItemTypeCode] ||
        dataItemTypeCodeToModel[ioSchema.dataItemTypeCode],
      {
        upperCase: true,
        plural: true
      }
    )
  );
}

export async function importWorkflowDefinition(
  workflowDefObject,
  options = {},
  apolloMethods = isoContext
) {
  const { safeUpsert, safeQuery } = apolloMethods;
  const { newName } = options;
  delete workflowDefObject.id;
  if (newName) {
    workflowDefObject.name = newName;
  }
  let wtInputDefToOutputDefs = [];
  const inputIdMap = {};
  const outputIdMap = {};
  const allWtdExtendedProperties = [];
  let wtdExtendedProperties = [];

  const integrations = [];

  workflowDefObject.workflowToolDefinitions.forEach(td => {
    (td.workflowToolSettingDefinitions || []).forEach(sd => {
      if (sd.integration) {
        integrations.push(sd.integration);
      }
    });
  });

  let integrationsById = {};
  let integrationsByName = {};
  if (integrations.length) {
    const integrationFragment = ["integration", "id name integrationTypeCode"];
    const integrationNames = integrations.map(i => i.name).filter(identity);
    integrationsById = keyBy(
      await safeQuery(integrationFragment, {
        variables: {
          filter: {
            id: integrations.map(i => i.id)
          }
        }
      }),
      "id"
    );
    integrationsByName = await getCaseInsensitiveKeyedItems(
      integrationFragment,
      "name",
      integrationNames,
      {
        group: true
      }
    );
  }

  const invalidIntegrations = [];
  workflowDefObject.workflowToolDefinitions.forEach(td => {
    delete td.id;
    td.cid = shortid();
    (td.workflowToolInputDefinitions || []).forEach(inD => {
      if (inD.wtInputDefToOutputDefs?.length) {
        inD.cid = shortid();
        inputIdMap[inD.id] = inD.cid;
        wtInputDefToOutputDefs = wtInputDefToOutputDefs.concat(
          inD.wtInputDefToOutputDefs
        );
      }
      delete inD.id;
      delete inD.wtInputDefToOutputDefs;
    });
    (td.workflowToolOutputDefinitions || []).forEach(outD => {
      outD.cid = shortid();
      outputIdMap[outD.id] = outD.cid;
      delete outD.id;
    });
    (td.workflowToolSettingDefinitions || []).forEach(sd => {
      delete sd.id;
      if (sd.integration) {
        let integration = integrationsById[sd.integration.id];
        if (!integration) {
          integration = (integrationsByName[sd.integration.name] || []).find(
            integration => {
              return (
                integration.integrationTypeCode ===
                sd.integration?.integrationTypeCode
              );
            }
          );
        }
        if (
          integration &&
          integration.integrationTypeCode ===
            sd.integration?.integrationTypeCode
        ) {
          sd.integrationId = integration.id;
        } else {
          invalidIntegrations.push(sd.integration?.name || "Unknown");
        }
        delete sd.integration;
      }
    });
    (td.wtdExtendedProperties || []).forEach(wtdExtendedProperty => {
      allWtdExtendedProperties.push({
        extendedPropertyId: wtdExtendedProperty.extendedPropertyId,
        workflowToolDefinitionId: `&${td.cid}`
      });
    });
    delete td.wtdExtendedProperties;
  });

  const wtInputDefToOutputDefsToCreate = [];
  wtInputDefToOutputDefs.forEach(wtInOut => {
    wtInputDefToOutputDefsToCreate.push({
      workflowToolInputDefinitionId:
        "&" + inputIdMap[wtInOut.workflowToolInputDefinitionId],
      workflowToolOutputDefinitionId:
        "&" + outputIdMap[wtInOut.workflowToolOutputDefinitionId]
    });
  });
  const [createdWorkflowDefinition] = await safeUpsert(
    "workflowDefinition",
    workflowDefObject
  );
  await safeUpsert("wtInputDefToOutputDef", wtInputDefToOutputDefsToCreate);

  if (allWtdExtendedProperties.length) {
    const existingProps = await safeQuery(
      ["extendedProperty", "id modelTypeCode"],
      {
        variables: {
          filter: {
            id: allWtdExtendedProperties.map(
              wtdExtProp => wtdExtProp.extendedPropertyId
            )
          }
        }
      }
    );
    const keyedProps = keyBy(existingProps, "id");
    wtdExtendedProperties = allWtdExtendedProperties.filter(wtdExtProp => {
      const existingProp = keyedProps[wtdExtProp.extendedPropertyId];
      return (
        existingProp &&
        ["CONTAINER_ARRAY", "ALIQUOT"].includes(existingProp.modelTypeCode)
      );
    });
  }

  await safeUpsert("wtdExtendedProperty", wtdExtendedProperties);
  if (
    allWtdExtendedProperties.length &&
    allWtdExtendedProperties.length !== wtdExtendedProperties.length
  ) {
    window.toastr.warning(
      "Some extended properties could not be added to the workflow definition because they no longer exist."
    );
  }
  if (invalidIntegrations.length) {
    window.toastr.warning(
      `Some integrations could not be added to the workflow definition because they no longer exist: ${invalidIntegrations.join(
        ", "
      )}`
    );
  }

  return createdWorkflowDefinition;
}

export async function getJobUserForUser(
  user,
  jobId,
  apolloMethods = isoContext
) {
  const { safeQuery, safeUpsert } = apolloMethods;
  // make sure the user is a jobUser
  const [existingJobUser] = await safeQuery(["jobUser", "id jobId userId"], {
    variables: {
      filter: {
        jobId,
        userId: user.id
      }
    }
  });
  let jobUser = existingJobUser;
  if (!existingJobUser) {
    // make a new jobUser for this selected user
    [jobUser] = await safeUpsert("jobUser", {
      jobId: jobId,
      userId: user.id
    });
  }
  return jobUser;
}

export async function createWorkflowRun(options, apolloMethods = isoContext) {
  const {
    isAdHoc,
    workflowDefinitionId: _workflowDefinitionId,
    workflowDefinition,
    jobId,
    manager,
    ignoreLab,
    ...formValues
  } = options;

  const { safeUpsert } = apolloMethods;

  let workflowDefinitionId, workflowTools, ioItems;
  if (!isAdHoc) {
    workflowDefinitionId = _workflowDefinitionId || workflowDefinition.id;
    const res = await convertWorkflowToolDefinitionsToWorkflowTools(
      {
        workflowDefinitionId
      },
      apolloMethods
    );
    ioItems = res.ioItems;
    workflowTools = res.workflowTools;
  } else {
    const [newAdHocWorkflowDefinition] = await safeUpsert(
      "workflowDefinition",
      {
        isAdHoc: true
      }
    );
    workflowDefinitionId = newAdHocWorkflowDefinition.id;
  }

  let taggedItems;
  if (jobId) {
    taggedItems = await copyRecordsTaggedItems(
      {
        id: jobId,
        __typename: "job"
      },
      apolloMethods
    );
  }

  if (jobId && manager) {
    const jobUser = await getJobUserForUser(manager, jobId, apolloMethods);

    (workflowTools || []).forEach(tool => {
      tool.workQueueItems = [
        {
          jobUserId: jobUser.id
        }
      ];
    });
  }

  const createWorkflowRunInput = {
    ...pick(formValues, ["name", "notes", "dueDate"]),
    priorityTypeCode: get(formValues, "priorityType.code"),
    workflowDefinitionId,
    jobId,
    taggedItems,
    workflowRunStatusTypeCode: "NOT_STARTED",
    workflowTools: workflowTools
  };

  await safeUpsert("ioItem", ioItems);
  const [newWorkflow] = await safeUpsert("workflowRun", createWorkflowRunInput);
  return newWorkflow;
}

export async function convertWorkflowToolDefinitionsToWorkflowTools(
  { workflowToolDefinitionIds, workflowDefinitionId },
  apolloMethods = isoContext
) {
  const { safeQuery } = apolloMethods;
  const filter = {};
  if (workflowToolDefinitionIds) {
    filter.id = workflowToolDefinitionIds;
  } else {
    filter.workflowDefinitionId = workflowDefinitionId;
  }
  const workflowToolDefinitions = await safeQuery(
    workflowToolDefinitionFragment,
    {
      variables: {
        filter
      }
    }
  );
  //ideas:
  // make an ioItem for every input and output, always make isList true if the
  // input can handle a list
  // then loop through the linkages and make an ioListItems entry in the inputs' ioItems
  // linking the outputs to the inputs

  // will this handle the case of non-list ioItems?
  // should non-list ioItems be linked directly to inputs, or through the ioItem list

  // 1 output can be linked to multiple different inputs

  // 1 input can be linked to multiple different outputs

  // DA PLAN:
  // 1. make an ioItem for all the outputs (isList if necessary (the ioItems contained in the list won't yet be made))
  // 2. then, go through the inputs
  // (a) if the input is not linked to any outputs, link the input to a new ioItem of the correct type (either list or not)
  // (b) if the input is linked to a single output, just link the output's ioItem
  // (c) if the input is linked to multiple outputs, join the input to the outputs via the join table (workflowToolInputIoItems)
  // if the input is marked as a list, and linked to a single output, do we do 1 or 2? I say 1
  const unlinkedInputIoItems = {};
  const inputLinkages = {};
  const outputIoItemsToCreate = {};

  // 1. make an ioItem for all the outputs (isList if necessary (the ioItems contained in the list won't yet be made))
  workflowToolDefinitions.forEach(
    ({
      // workflowToolInputDefinitions = [],
      workflowToolOutputDefinitions = []
    }) => {
      workflowToolOutputDefinitions.forEach(({ id, isList, taskIoType }) => {
        const newIoItem = {
          cid: shortid(),
          isList,
          ...getIoItemFromTaskIoType(taskIoType)
        };
        outputIoItemsToCreate[id] = newIoItem;
      });
    }
  );
  workflowToolDefinitions.forEach(
    ({
      workflowToolInputDefinitions = []
      // workflowToolOutputDefinitions = []
    }) => {
      // 2. then, go through the inputs
      workflowToolInputDefinitions.forEach(
        ({ id, taskIoType, isList, wtInputDefToOutputDefs = [] }) => {
          // (a) if the input is not linked to any outputs, link the input to a new ioItem of the correct type (either list or not)
          if (!wtInputDefToOutputDefs.length) {
            //no linkages
            const newIoItem = {
              isList,
              ...getIoItemFromTaskIoType(taskIoType)
            };
            unlinkedInputIoItems[id] = newIoItem; //this will get made together with the workflow run below
          } else {
            // (b) if the input is linked to outputs, join the input to the outputs via the join table (workflowToolInputIoItems)
            const linkages = [];
            wtInputDefToOutputDefs.forEach(wtInputDefToOutputDef => {
              const ioItem =
                outputIoItemsToCreate[
                  wtInputDefToOutputDef.workflowToolOutputDefinitionId
                ];
              const { cid } = ioItem;
              // eslint-disable-next-line no-debugger
              if (!cid) debugger;
              linkages.push(ioItem);
            });
            inputLinkages[id] = linkages; // these linkages will be made together with the workflow run below
          }
        }
      );
    }
  );
  const workflowTools = workflowToolDefinitions.map(
    ({
      id,
      workflowToolInputDefinitions = [],
      workflowToolOutputDefinitions = []
    }) => {
      let workflowToolStatusCode = workflowToolStatuses.ready;
      let dependsOnOtherTool = false;
      const workflowToolInputs = workflowToolInputDefinitions.map(({ id }) => {
        let ioItem;
        let workflowToolInputIoItems;
        if (unlinkedInputIoItems[id]) {
          //the input is not linked to any outputs
          ioItem = unlinkedInputIoItems[id];
          workflowToolInputIoItems = [
            {
              ioItem
            }
          ];
        } else {
          //link the input to multiple outputs
          workflowToolInputIoItems = inputLinkages[id].map(ioItem => ({
            ioItemId: "&" + ioItem.cid
          }));
          dependsOnOtherTool = true;
        }
        return {
          workflowToolInputDefinitionId: id,
          workflowToolInputIoItems
        };
      });
      if (dependsOnOtherTool) {
        workflowToolStatusCode = workflowToolStatuses.blocked;
      }
      return {
        workflowToolDefinitionId: id,
        workflowToolStatusCode,
        workflowToolOutputs: workflowToolOutputDefinitions.map(({ id }) => {
          const ioItem = outputIoItemsToCreate[id];
          // eslint-disable-next-line no-debugger
          if (!ioItem) debugger;
          const { cid } = ioItem;
          // eslint-disable-next-line no-debugger
          if (!cid) debugger;
          return {
            workflowToolOutputDefinitionId: id,
            ioItemId: "&" + cid
          };
        }),
        workflowToolInputs
      };
    }
  );

  return { workflowTools, ioItems: Object.values(outputIoItemsToCreate) };
}

export function getIoItemFromTaskIoType(taskIoType, taskIoTypeCode) {
  // eslint-disable-next-line no-debugger
  if (!taskIoType && !taskIoTypeCode) debugger;
  let inventoryItemTypeCode, ioItemTypeCode, dataItemTypeCode;
  if (taskIoType) {
    inventoryItemTypeCode = taskIoType.inventoryItemTypeCode;
    ioItemTypeCode = taskIoType.ioItemTypeCode;
    dataItemTypeCode = taskIoType.dataItemTypeCode;
  } else {
    // tgreen: taskIoTypes map directly to models for now. I will assume this stays consistent so we don't need to overfetch
    inventoryItemTypeCode = inventoryItemTypeMap[taskIoTypeCode];
    dataItemTypeCode = dataItemTypeMap[taskIoTypeCode];
    ioItemTypeCode = inventoryItemTypeCode ? "INVENTORY" : "DATA";
  }
  return {
    ioItemTypeCode,
    ioItemAvailabilityStatusCode: "AVAILABLE",
    ...(inventoryItemTypeCode
      ? {
          inventoryItem: {
            inventoryItemTypeCode
          }
        }
      : {
          dataItem: {
            dataItemTypeCode
          }
        })
  };
}

export function getMatchingSettingDef(toolSchema, name) {
  const ts = toolSchema.toolSchema || toolSchema;
  return ts.settings[name];
}

export const settingsTypes = {
  numeric: "numeric",
  select: "select",
  checkbox: "checkbox",
  text: "text",
  radio: "radio"
};
