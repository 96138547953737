/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import {
  ReactSelectField,
  RadioGroupField,
  NumericInputField,
  SelectField,
  InputField,
  CheckboxField
} from "@teselagen/ui";
import { get, times } from "lodash";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { is2x2Mapping } from "../utils";
import stepFormValues from "../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../src-shared/GenericSelect";
import PlateMapView from "../../../components/PlateMapView";
import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import ColumnPlateSelection from "./ColumnPlateSelection";
import {
  breakdownPatterns,
  getNumberOfCombinationDestinationPlates
} from "./utils";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";
import { everyOtherTooltip, multiChannelTooltip } from "./constants";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

class Combination extends Component {
  is2x2 = () => {
    const { destinationPlateFormat, sourcePlateFormat } = this.props;
    return is2x2Mapping(sourcePlateFormat, destinationPlateFormat);
  };

  getBreakdownPatternOptions = () => breakdownPatterns(this.is2x2());

  render() {
    const {
      toolSchema,
      containerArrayTypes = [],
      containerFormats = [],
      sourcePlateFormat,
      combinationGenerateBarcodes,
      destinationPlateFormat,
      newOrExistingDestinationPlates,
      sourcePlates = [],
      combinationDestinationPlates = [],
      combinationMultiChannel
    } = this.props;
    const filteredContainerArrayTypes = containerArrayTypes.filter(
      containerArrayType =>
        containerArrayType.containerFormatCode ===
        get(destinationPlateFormat, "code")
    );
    const filteredContainerFormats = containerFormats.filter(
      containerFormat =>
        containerFormat.quadrantSize === sourcePlateFormat.quadrantSize * 4
    );
    const numDestinationPlates = getNumberOfCombinationDestinationPlates({
      sourcePlateFormat,
      sourcePlates,
      destinationPlateFormat
    });
    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Destination Plate Format"
            helper="Specify a plate format for one or more destination
            plates below. The format must be larger than the selected
            source plates format."
          />
          <div style={{ width: "40%" }}>
            <ReactSelectField
              name="destinationPlateFormat"
              label="Destination Plate Format"
              options={arrayToItemValuedOptions(filteredContainerFormats)}
              placeholder="Specify a destination plate format..."
            />
          </div>
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Combination Pattern"
            helper="Specify a combination pattern for to determine
                the order in which materials will be transferred to
                destination plates."
          />
          <div>
            <ReactSelectField
              name="combinationPattern"
              label="Combination Pattern"
              options={this.getBreakdownPatternOptions()}
              defaultValue="Z"
            />
            <CheckboxField
              name="combinationEveryOtherRow"
              label="Every other row"
              tooltipInfo={everyOtherTooltip("combination", "row")}
              defaultValue
            />
            <CheckboxField
              name="combinationEveryOtherColumn"
              label="Every other column"
              tooltipInfo={everyOtherTooltip("combination", "column")}
              defaultValue
            />
            <CheckboxField
              name="combinationMultiChannel"
              label="Multi-Channel"
              tooltipInfo={multiChannelTooltip}
            />
            {combinationMultiChannel && (
              <RadioGroupField
                name="combinationChannelOrientation"
                inline
                label="Channel Orientation"
                defaultValue="Vertical"
                options={["Vertical", "Horizontal"]}
              />
            )}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Transfer Volume"
              helper="Specify the volume for each of the transfers from the selected source plates."
            />
            <div className="input-with-unit-select" style={{ width: "50%" }}>
              <NumericInputField
                label="Transfer Volume"
                name="transferVolume"
                min={0}
              />
              <SelectField
                name="transferVolumetricUnitCode"
                defaultValue="uL"
                label="none"
                className="tg-unit-select"
                options={unitGlobals.getOptionsForSelect("volumetricUnit")}
              />
            </div>
          </div>
        </div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Combination Plate Mapping"
              helper="Once you have specified a destination plate format and
                combination pattern, either select existing destination plates
                or enter information for new destination plates to be created."
              width="50%"
            />
            <RadioGroupField
              name="newOrExistingDestinationPlates"
              options={[
                { label: "Create new Destination Plates", value: "NEW" },
                {
                  label: "Transfer to Existing Plates",
                  value: "EXISTING"
                },
                {
                  label: "Transfer to New Column Plates",
                  value: "NEW_COLUMN"
                },
                {
                  label: "Transfer to Existing Column Plates",
                  value: "EXISTING_COLUMN"
                }
              ]}
              defaultValue="EXISTING"
            />
          </div>
          {newOrExistingDestinationPlates === "NEW" && (
            <CheckboxField
              name="combinationGenerateBarcodes"
              label="Generate Barcodes For Destination Plate"
              defaultValue
            />
          )}
          {times(numDestinationPlates, i => {
            return (
              <div key={i} className="tg-flex column">
                <hr className="tg-section-break" />
                <div className="tg-flex column">
                  <h6>
                    {combinationDestinationPlates[i]
                      ? combinationDestinationPlates[i].name
                      : `Destination Plate ${i + 1}`}
                  </h6>
                  {newOrExistingDestinationPlates === "EXISTING" && (
                    <div className="tg-flex column">
                      <GenericSelect
                        {...{
                          name: `combinationDestinationPlates.${i}`,
                          schema: ["name", dateModifiedColumn],
                          fragment: ["containerArray", "id name updatedAt"],
                          additionalDataFragment: containerArrayPlatePreviewFragment,
                          tableParamOptions: {
                            additionalFilter: (_, qb) => {
                              qb.whereAll({
                                "containerArrayType.containerFormatCode":
                                  destinationPlateFormat.code
                              });
                            }
                          }
                        }}
                      />
                      {combinationDestinationPlates[i] && (
                        <PlateMapView
                          containerArrayType={
                            combinationDestinationPlates[i].containerArrayType
                          }
                          containerArray={combinationDestinationPlates[i]}
                          noEntityTransform
                          noPadding
                        />
                      )}
                    </div>
                  )}
                  <ColumnPlateSelection
                    {...{
                      index: i,
                      toolSchema,
                      containerArrayTypes,
                      destinationPlateFormat
                    }}
                  />
                  {newOrExistingDestinationPlates === "NEW" && (
                    <div className="tg-flex column" style={{ width: "50%" }}>
                      <InputField
                        name={`destinationPlateInfo.${i}.name`}
                        label="Destination Plate Name"
                        placeholder="Enter destination plate name..."
                        isRequired
                      />
                      <ReactSelectField
                        name={`destinationPlateInfo.${i}.type`}
                        label="Destination Plate Type"
                        isRequired
                        options={arrayToItemValuedOptions(
                          filteredContainerArrayTypes
                        )}
                      />
                      {!combinationGenerateBarcodes && (
                        <InputField
                          name={`destinationPlateInfo.${i}.barcode`}
                          isRequired
                          label="Destination Plate Barcode"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "sourcePlateFormat",
    "combinationGenerateBarcodes",
    "destinationPlateFormat",
    "combinationDestinationPlates",
    "sourcePlates",
    "newOrExistingDestinationPlates",
    "combinationMultiChannel"
  )
)(Combination);
