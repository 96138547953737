/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { get, identity, uniq, difference, pick, isEmpty } from "lodash";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes, Intent, Tooltip, Icon, Position } from "@blueprintjs/core";
import shortid from "shortid";
import {
  InputField,
  DialogFooter,
  CheckboxField,
  wrapDialog
} from "@teselagen/ui";

import GenericSelect from "../../../../src-shared/GenericSelect";

import duplicateDialogAsPromise from "../../../../src-shared/components/Dialogs/DuplicatesDialog";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";

import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

class MaterialMicrobialTransformationDialog extends Component {
  onSubmit = async values => {
    const {
      hideModal,
      sourceMaterials = [],
      typeCode,
      history,
      refetchMaterials
    } = this.props;
    const {
      selectedMaterial = {},
      outputMaterialNames,
      defaultNaming
    } = values;
    try {
      let dnaMaterials, microbialMaterials;
      if (typeCode === "DNA") {
        dnaMaterials = sourceMaterials;
        microbialMaterials = [selectedMaterial];
      } else {
        dnaMaterials = [selectedMaterial];
        microbialMaterials = sourceMaterials;
      }
      const duplicateMaterials = [];
      const outputMaterials = [];
      const outputMaterialsToCreate = [];
      const outputMaterialCidToParentIdsMap = {};
      const outputMaterialCidToDnaIdsMap = {};
      const outputMaterialCidToMaterialMap = {};
      const dnaMaterialIds = dnaMaterials.map(dnaMaterial => dnaMaterial.id);
      const strainIds = microbialMaterials
        .map(microbialMaterial => microbialMaterial.strainId)
        .filter(identity); // removes null strainIds.
      let materialNames;
      if (defaultNaming) {
        materialNames = this.getOutputMaterialNames();
      } else {
        materialNames = outputMaterialNames;
      }
      if (typeCode === "DNA") {
        dnaMaterials.forEach(dnaMaterial => {
          microbialMaterials.forEach(microbialMaterial => {
            const outputMaterialCid = shortid();
            outputMaterialCidToParentIdsMap[outputMaterialCid] = [
              dnaMaterial.id,
              microbialMaterial.id
            ];
            const outputMaterial = {
              id: outputMaterialCid,
              name: materialNames[(defaultNaming ? "" : "id") + dnaMaterial.id],
              materialTypeCode: "MICROBIAL",
              strainId: microbialMaterial.strainId,
              dnaMaterial,
              microbialMaterial
            };
            outputMaterials.push(outputMaterial);
            outputMaterialCidToMaterialMap[outputMaterialCid] = outputMaterial;
          });
        });
      } else {
        microbialMaterials.forEach(microbialMaterial => {
          dnaMaterials.forEach(dnaMaterial => {
            const outputMaterialCid = shortid();
            outputMaterialCidToParentIdsMap[outputMaterialCid] = [
              dnaMaterial.id,
              microbialMaterial.id
            ];
            const outputMaterial = {
              cid: outputMaterialCid,
              name: materialNames[
                (defaultNaming ? "" : "id") + microbialMaterial.id
              ],
              materialTypeCode: "MICROBIAL",
              strainId: microbialMaterial.strainId,
              microbialMaterial,
              dnaMaterial
            };
            outputMaterials.push(outputMaterial);
            outputMaterialCidToMaterialMap[outputMaterialCid] = outputMaterial;
          });
        });
      }
      const maybeDuplicateMaterials = await safeQuery(
        [
          "material",
          "id strainId microbialMaterialMicrobialMaterialPlasmids { id polynucleotideMaterialId }"
        ],
        {
          variables: {
            filter: {
              strainId: strainIds,
              "microbialMaterialMicrobialMaterialPlasmids.polynucleotideMaterialId":
                dnaMaterialIds
            }
          }
        }
      );
      outputMaterials.forEach(outputMaterial => {
        const dnaMaterialIds = [];
        dnaMaterialIds.push(outputMaterial.dnaMaterial.id);
        outputMaterial.microbialMaterial
          .microbialMaterialMicrobialMaterialPlasmids.length > 0 &&
          outputMaterial.microbialMaterial.microbialMaterialMicrobialMaterialPlasmids.forEach(
            mmp => dnaMaterialIds.push(mmp.polynucleotideMaterialId)
          );
        const deduplicatedDnaMatIds = uniq(dnaMaterialIds);
        outputMaterialCidToDnaIdsMap[outputMaterial.id || outputMaterial.cid] =
          deduplicatedDnaMatIds;
        let wasDuplicate = false;
        maybeDuplicateMaterials.forEach(mdm => {
          const maybeDuplicateDnaMatIds =
            mdm.microbialMaterialMicrobialMaterialPlasmids.map(
              mmp => mmp.polynucleotideMaterialId
            );
          const dnaMatMatch =
            difference(maybeDuplicateDnaMatIds, outputMaterialCidToDnaIdsMap)
              .length === 0;
          const strainMatch =
            mdm.strainId === outputMaterial.microbialMaterial.strainId;
          if (dnaMatMatch && strainMatch) {
            duplicateMaterials.push(outputMaterial);
            wasDuplicate = true;
          }
        });
        if (!wasDuplicate) {
          outputMaterialsToCreate.push(outputMaterial);
        }
      });
      let duplicateMaterialsToCreate = [];
      if (duplicateMaterials.length > 0) {
        duplicateMaterialsToCreate = await duplicateDialogAsPromise({
          dialogProps: {
            title: "Select Duplicate Materials To Create"
          },
          schema: [
            {
              path: "name",
              width: 120
            }
          ],
          entities: duplicateMaterials
        });
        if (duplicateMaterialsToCreate.userCancelled) {
          return;
        }
      }
      const materialsToCreate = outputMaterialsToCreate.concat(
        duplicateMaterialsToCreate
      );

      const reformattedMaterials = [];
      materialsToCreate.forEach(material => {
        const hasIdOrCid =
          outputMaterialCidToParentIdsMap[material.id || material.cid];
        const microbialMaterialMicrobialMaterialPlasmids = [];
        outputMaterialCidToDnaIdsMap[material.id || material.cid].forEach(
          dnaMatId =>
            microbialMaterialMicrobialMaterialPlasmids.push({
              polynucleotideMaterialId: dnaMatId,
              microbialMaterialId: `&${material.microbialMaterial.id}`
            })
        );
        reformattedMaterials.push({
          microbialMaterialMicrobialMaterialPlasmids,
          materialLineages: [
            {
              parentMaterialId:
                hasIdOrCid &&
                outputMaterialCidToParentIdsMap[material.id || material.cid][0]
            },
            {
              parentMaterialId:
                hasIdOrCid &&
                outputMaterialCidToParentIdsMap[material.id || material.cid][1]
            }
          ],
          ...pick(material, ["name", "materialTypeCode", "strainId"])
        });
      });
      const createdMaterials = await safeUpsert(
        "material",
        reformattedMaterials
      );

      if (reformattedMaterials.length > 1) {
        await refetchMaterials();
        hideModal();
        history.push(`/microbial-materials`);
      } else {
        hideModal();
        history.push(`/microbial-materials/${createdMaterials[0].id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  getOutputMaterialNames() {
    const {
      sourceMaterials = [],
      selectedMaterial = {},
      typeCode
    } = this.props;
    const multi = sourceMaterials.length > 1;
    const outputMaterialNames = {};

    const getOutputName = (microbialMaterial, plasmidNames) => {
      return `${
        get(microbialMaterial, "strain.name") || microbialMaterial.name
      } (${plasmidNames
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .join(", ")})`;
    };
    if (multi) {
      sourceMaterials.forEach(sourceMaterial => {
        const plasmidNames = [];
        let dnaMaterial, microbialMaterial;
        if (typeCode === "DNA") {
          dnaMaterial = sourceMaterial;
          microbialMaterial = selectedMaterial;
        } else {
          dnaMaterial = selectedMaterial;
          microbialMaterial = sourceMaterial;
        }
        if (
          dnaMaterial.polynucleotideMaterialSequence &&
          !plasmidNames.includes(
            dnaMaterial.polynucleotideMaterialSequence.name
          )
        ) {
          plasmidNames.push(dnaMaterial.polynucleotideMaterialSequence.name);
        }
        if (
          microbialMaterial.microbialMaterialMicrobialMaterialPlasmids &&
          microbialMaterial.microbialMaterialMicrobialMaterialPlasmids.length >
            0
        ) {
          microbialMaterial.microbialMaterialMicrobialMaterialPlasmids.forEach(
            mmp => plasmidNames.push(mmp.plasmid.name)
          );
        }
        outputMaterialNames[sourceMaterial.id] = getOutputName(
          microbialMaterial,
          plasmidNames
        );
      });
    } else {
      const plasmidNames = [];
      let dnaMaterial, microbialMaterial;
      if (typeCode === "DNA") {
        dnaMaterial = sourceMaterials[0];
        microbialMaterial = selectedMaterial;
      } else {
        dnaMaterial = selectedMaterial;
        microbialMaterial = sourceMaterials[0];
      }
      if (!isEmpty(dnaMaterial)) {
        if (
          !plasmidNames.includes(
            dnaMaterial.polynucleotideMaterialSequence.name
          )
        ) {
          plasmidNames.push(dnaMaterial.polynucleotideMaterialSequence.name);
        }
      }
      if (
        microbialMaterial.microbialMaterialMicrobialMaterialPlasmids &&
        microbialMaterial.microbialMaterialMicrobialMaterialPlasmids.length > 0
      ) {
        microbialMaterial.microbialMaterialMicrobialMaterialPlasmids.forEach(
          mmp => plasmidNames.push(mmp.polynucleotideMaterial.name)
        );
      }
      outputMaterialNames[sourceMaterials[0].id] = getOutputName(
        microbialMaterial,
        plasmidNames
      );
    }
    return outputMaterialNames;
  }

  render() {
    const {
      sourceMaterials = [],
      selectedMaterial = {},
      defaultNaming,
      typeCode,
      hideModal,
      submitting,
      handleSubmit
    } = this.props;
    const outputMaterialNameMap = this.getOutputMaterialNames();
    const selectMaterialFragment =
      typeCode === "DNA"
        ? `id
        name
        strainId
        strain {
          id
          name
        }
        microbialMaterialMicrobialMaterialPlasmids {
          id
          microbialMaterialId
          polynucleotideMaterialId
          polynucleotideMaterial {
            id
            name
          }
        }
        updatedAt
        `
        : `id name polynucleotideMaterialSequence { id name } updatedAt`;

    let additionalFilterFields = {};
    let schema;

    if (typeCode === "DNA") {
      additionalFilterFields = {
        materialTypeCode: "MICROBIAL"
      };
      schema = ["name", "strain.name", dateModifiedColumn];
    } else {
      additionalFilterFields = {
        materialTypeCode: "DNA",
        "polynucleotideMaterialSequence.sequenceTypeCode": "CIRCULAR_DNA"
      };
      schema = ["name", dateModifiedColumn];
    }
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <GenericSelect
            {...{
              label: "Select Material",
              name: "selectedMaterial",
              style: { marginTop: 0 },
              schema,
              isRequired: true,
              asReactSelect: true,
              isMultiSelect: false,
              fragment: ["material", selectMaterialFragment],
              tableParamOptions: {
                additionalFilter: {
                  ...additionalFilterFields
                }
              }
            }}
          />
          <div className="tg-flex justify-space-between">
            <h6 style={{ marginTop: 8 }}>Output Material Names</h6>
            <div className="tg-flex">
              <CheckboxField
                name="defaultNaming"
                label="Default Naming"
                defaultValue={true}
              />
              <Tooltip
                content={Object.values(outputMaterialNameMap).map((name, i) => (
                  <div key={i}>{name}</div>
                ))}
                position={Position.RIGHT}
              >
                <Icon icon="help" style={{ marginLeft: 8, marginTop: 8 }} />
              </Tooltip>
            </div>
          </div>
          {!isEmpty(selectedMaterial) && !defaultNaming && (
            <div style={{ overflow: "auto", maxHeight: 300 }}>
              {sourceMaterials.map(mat => {
                return (
                  <InputField
                    name={`outputMaterialNames.id${mat.id}`}
                    key={`outputMaterialNames.id${mat.id}`}
                    enableReinitialize
                    label={mat.name}
                    defaultValue={outputMaterialNameMap[mat.id]}
                  />
                );
              })}
            </div>
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
          intent={Intent.SUCCESS}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog(),
  reduxForm({
    enableReinitialize: true,
    form: "materialClonalTransformation"
  }),
  tgFormValues("selectedMaterial", "defaultNaming")
)(MaterialMicrobialTransformationDialog);
