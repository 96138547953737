/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { find } from "lodash";

import { Button, Classes } from "@blueprintjs/core";
import { ReactSelectField, InputField, DialogFooter } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import appGlobals from "../../../../../src-shared/appGlobals";

const selectionHeuristics = [
  {
    name: "Default (best average ∆G)",
    description: "Activate part with highest ∆G value"
  }
];

class CrickitParameterSelection extends React.Component {
  onSubmit = async crickitParameters => {
    const { onSubmit, hideModal } = this.props;
    try {
      await onSubmit(crickitParameters);
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error choosing crickit parameters.");
    }
  };

  render() {
    const {
      nextPage,
      hideModal,
      handleSubmit,
      change,
      selectedParameterPreset,
      microserviceExtensionInputs = []
    } = this.props;
    const { firstName, lastName } = appGlobals.currentUser;
    const fullName = `${firstName} ${lastName}`;

    const parameterPresets = microserviceExtensionInputs.filter(
      mei =>
        !mei.isHidden && mei.microserviceExtensionCode === "CRICKIT_PARAMETERS"
    );

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            label="Username"
            isRequired
            name="username"
            defaultValue={fullName}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <ReactSelectField
                name="parameterPreset"
                isRequired
                label="Parameter Set"
                defaultValue={find(
                  parameterPresets,
                  pp => pp.id === selectedParameterPreset
                )}
                options={parameterPresets.map(microserviceExtensionInput => ({
                  label: microserviceExtensionInput.name,
                  value: microserviceExtensionInput
                }))}
              />
            </div>
            <Button
              minimal
              icon="plus"
              style={{
                marginTop: 23,
                width: 20,
                height: 36
              }}
              onClick={() => {
                change("parameterPreset", null);
                nextPage();
              }}
            />
            <Button
              minimal
              icon="eye-open"
              style={{
                marginTop: 23,
                width: 20,
                height: 36
              }}
              onClick={nextPage}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <ReactSelectField
                style={{ flex: 1 }}
                name="selectionHeuristics"
                isRequired
                label="Selection Heuristics"
                options={selectionHeuristics.map(mei => ({
                  label: mei.name,
                  value: mei
                }))}
                defaultValue={selectionHeuristics.find(
                  ({ name }) => name.indexOf("efault") > -1
                )}
              />
            </div>
          </div>
        </div>
        <DialogFooter hideModal={hideModal} />
      </form>
    );
  }
}

export default compose(
  withQuery(
    [
      "microserviceExtensionInput",
      "id name isHidden microserviceExtensionCode"
    ],
    {
      isPlural: true
    }
  ),
  reduxForm({
    form: "crickitParameterSelection",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    destroyOnUnmount: false
  }),
  tgFormValues("parameterPreset")
)(CrickitParameterSelection);
