/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { RadioGroupField } from "@teselagen/ui";
import { Callout } from "@blueprintjs/core";
import UnitInputField from "../../UnitInputField";

function Page2({ destinationAliquot }) {
  let warningMessage;
  if (destinationAliquot.volume && !destinationAliquot.concentration) {
    warningMessage =
      "The destination aliquot does not have a concentration. Replenishing will result in a concentration of N/A";
  }
  return (
    <div>
      <h6 style={{ marginBottom: 15 }}>
        Specify transfer volume or desired total volume:
      </h6>
      <RadioGroupField
        name="transferType"
        defaultValue="transfer"
        inline
        options={[
          { label: "Transfer Volume", value: "transfer" },
          { label: "Total Volume", value: "total" }
        ]}
      />
      <div style={{ marginBottom: 10 }}>
        Current aliquot volume: {destinationAliquot.volume}{" "}
        {destinationAliquot.volumetricUnitCode}
      </div>
      <UnitInputField
        name="volume"
        label="Volume"
        unitName="volumetricUnitCode"
        unitDefault="uL"
        unitType="volumetricUnit"
      />
      {warningMessage && (
        <Callout style={{ marginTop: 10 }} intent="warning">
          {warningMessage}
        </Callout>
      )}
    </div>
  );
}

export default Page2;
