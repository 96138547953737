/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { forEach, get, isEmpty, isEqual, keyBy } from "lodash";
import pluralize from "pluralize";

export default function getMutations({ updated, original, mutations, model }) {
  // go thru each key in the updated object and compare its value (deeply)
  // with the original value
  initializeMutations(model, mutations);
  const updateForRecord = {};
  forEach(updated, (updatedVal, key) => {
    if (!original) {
      console.error(
        `cannot getMutations without original record. Updated:`,
        updated
      );
    }
    // for simple values just compare
    const originalVal = original[key];
    if (Array.isArray(updatedVal)) {
      if (isEqual(updatedVal, originalVal)) return;
      let subModel = pluralize.singular(key);
      const __typename =
        get(updatedVal, "[0].__typename") || get(originalVal, "[0].__typename");
      subModel = __typename || subModel;
      initializeMutations(subModel, mutations);

      const updatedValById = keyBy(updatedVal, "id");
      const originalValById = keyBy(originalVal || [], "id");
      //we'll need to compare these looking for creates/updates/removes
      if (Array.isArray(originalVal)) {
        // if the value can't be found in the original array (by id) it's a remove!
        const removes = originalVal
          .filter(({ id }) => !updatedValById[id])
          .map(({ id }) => id);
        mutations[subModel].remove = mutations[subModel].remove.concat(removes); //does this work?
      }
      updatedVal.forEach(newVal => {
        //if it doesn't have an id it's a create!
        if (!newVal.id) {
          // { //tnr: we might need to do:
          //    model1  - model2
          //   "sequence-nucleotide": "polyNucleotideId"
          // }
          if (!original.id) {
            throw new Error(
              "Yikes! We should have an id for the original parent"
            );
          }
          newVal[`${model}Id`] = original.id; //tnr: this might not always be correct.. we really need a way to pass nested creates to graphql
          mutations[subModel].create.push(newVal); //does this work?
        } else {
          const original = originalValById[newVal.id];
          getMutations({
            model: subModel,
            updated: newVal,
            original: original,
            mutations //what do we push here?
          });
        }
      });
    } else if (updatedVal && updatedVal.__typename) {
      //nested object with __typename
      getMutations({
        model: updatedVal.__typename,
        updated: updatedVal,
        original: originalVal,
        mutations
      });
    } else {
      //if the values are different, then push it onto the updateForRecord
      if (!isEqual(updatedVal, originalVal)) {
        updateForRecord[key] = updatedVal;
      }
    }
    // if there are complex nested values
  });
  if (!isEmpty(updateForRecord)) {
    updateForRecord.id = updated.id;
    mutations[model].update.push(updateForRecord);
  }
}

function initializeMutations(model, tracker) {
  if (!model) throw new Error("Must have a model! e192847129874");
  if (!tracker[model]) {
    tracker[model] = {
      update: [],
      remove: [],
      create: []
    };
  }
}

// mutations
// {
// [model]: { update, remove, create }
// aminoAcidSequenceFragment: { update, remove, create }}
