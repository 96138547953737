/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash-es";
import Promise from "bluebird";
import {
  typeToFragmentMap,
  typeToPathResolutionFragment
} from "./getPathsFromFragments.js";
import {
  createPatchyPath,
  getIdsToFetchForPath,
  fillPatchyPaths,
  postprocessPaths
} from "./getPathsFromUtils.js";

export const getPathsFrom = () => {
  /**
   * Given the location ids to get, construct the patchy paths for the items.
   *
   * A "patchy path" represents that maximum information that we can get about an
   * item's path in a single database call. We do this by following the direct links
   * to ancestors in the database.
   *
   * Since equipment, equipment positions, and container positions
   * are directly linked to their immediate ancestor, an item whose path consists solely of these types
   * of items will have its patchy path be equivalent to its actual path.
   *
   * Patchy paths get their name from when we consider locations and containers. These can be nested within
   * items of the same type. This relationship is stored in the string `path` variable. Unfortunately, these
   * references cannot be resolved in the original query. The patchy paths will skip these relationships and go
   * straight from a container to e.g. the equipment item containing it.
   *
   * @returns {Promise<Array<Array<Object>>>} Array of patchy paths
   */
  async function getPatchyPaths(locationType, locationIds, query) {
    const toSiteFragment = typeToFragmentMap[locationType];
    const items = await query(toSiteFragment, {
      isPlural: true,
      variables: { filter: { id: locationIds }, pageSize: 1000000 }
    });

    const idToItem = keyBy(items, "id");
    return locationIds.map(id => createPatchyPath(idToItem[id]));
  }

  /**
   * Given an array of patchy paths, query the database to construct a map of type to id to item
   * of the items needed to fill in the patchy paths.
   *
   * @returns {Promise<Map<Type, Map<Id, Object>>>}
   */
  async function getItemsToFillPath(patchyPaths, query) {
    const idsToFetch = getIdsToFetchForPath(patchyPaths);

    const mapEntries = await Promise.map(
      Object.entries(idsToFetch),
      async ([type, ids]) => [
        type,
        ids.length
          ? await query(typeToPathResolutionFragment[type], {
              isPlural: true,
              variables: { filter: { id: ids }, pageSize: 1000000 }
            })
          : []
      ]
    );

    const fillerItems = {};
    for (const [type, items] of mapEntries) {
      fillerItems[type] = keyBy(items, "id");
    }
    return fillerItems;
  }

  /**
   * Given the ids of a particular type of location, returns an array of full paths to those locations.
   * A full path is an array of either the items or their names/labels.
   *
   * @param {String} locationType The name of location we are getting the paths from (e.g., "container")
   * @param {Array<String>} locationIds Array of ids of locations to get the paths from.
   * @param {Object} options Optional options
   * @param {Boolean} options.useFullName If `true`, this will always use `name`s instead of `labels`s.
   * @param {Boolean} options.objectsAsPath If `true`, this will mean a path will be an array of objects making up the items in the path instead of their names/labels.
   * @param {Boolean} options.includeSelfInPath If `true`, the path will end with the location in the `locationIds` array. Otherwise, it will end at the location immediately above it.
   * @returns {Array<Array<String | Object>>}
   */
  return async function getPathsFrom(
    locationType,
    locationIds,
    query,
    options = {}
  ) {
    if (locationType === "equipment") locationType = "equipmentItem";

    if (!locationIds.length) return [];
    // The first step to construct the paths is to get the "patchy paths" for each item. See
    // the documentation for `getPatchyPaths` for more information on patchy paths.
    const patchyPaths = await getPatchyPaths(locationType, locationIds, query);

    // Get the items from the database that we need to fill the "gaps" of the patchy paths.
    const fillerItems = await getItemsToFillPath(patchyPaths, query);

    // Fill in the "gaps" in the patchy paths.

    const fullPaths = fillPatchyPaths(patchyPaths, fillerItems);

    // Convert the raw full paths to what the caller requested.
    return postprocessPaths(fullPaths, options);
  };
};
