/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import {
  Loading,
  CheckboxField,
  DataTable,
  SelectField,
  InputField,
  fieldRequired
} from "@teselagen/ui";
import { tgFormValues } from "@teselagen/ui";

import withQuery from "../../../../src-shared/withQuery";

import { Callout } from "@blueprintjs/core";

import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { aliquotRearrayDestinationPlateFragment } from "./fragments";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";
import { isEmpty } from "lodash";
import queryBuilder from "tg-client-query-builder";
import containerArrayTypeFragment from "../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import { capitalize } from "lodash";
import platePreviewColumn from "../../../utils/platePreviewColumn";

class DestinationPlateInfo extends Component {
  destinationPlateFilter = (_, qb) => {
    const {
      sourcePlates = [],
      destinationContainerFormat,
      isTubeTransfer
    } = this.props;
    let containerFormatFilter;
    if (destinationContainerFormat) {
      containerFormatFilter = {
        "containerArrayType.containerFormatCode":
          destinationContainerFormat.code
      };
    }
    if (isTubeTransfer) {
      qb.whereAny(
        {
          "containerArrayType.isPlate": false
        },
        {
          "containerArrayType.isPlate": qb.isNull()
        }
      );
    }
    qb.whereAll({
      id: qb.notInList(sourcePlates.map(p => p.id)),
      ...containerFormatFilter
    });
  };

  render() {
    const {
      containerArrayTypes = [],
      containerArrayTypesLoading,
      plates = [],
      isMicrobialTransformation,
      generateBarcodes,
      destinationContainerFormat,
      destinationPlates = [],
      canSelectExistingPlate,
      destinationContainerType = "plate",
      isTubeTransfer
    } = this.props;
    let mustSelectProp;
    if (canSelectExistingPlate && plates.length > 0) {
      mustSelectProp = {
        mustSelect: plates.length
      };
    }

    const DestinationContainerType = capitalize(destinationContainerType);
    const isContainerFormat = !isEmpty(destinationContainerFormat);
    const destinationPlateSchema = [
      "name",
      {
        displayName: `Destination ${DestinationContainerType} Type`,
        path: "containerArrayType"
      }
    ];
    if (!generateBarcodes) {
      destinationPlateSchema.push("barcode");
    }

    return (
      <React.Fragment>
        <HeaderWithHelper
          header={`Destination ${DestinationContainerType} Information`}
          width="100%"
          helper={`${
            canSelectExistingPlate
              ? `Choose an existing ${destinationContainerType}, or create a new one.`
              : `Enter a ${destinationContainerType} name and select a ${destinationContainerType} type.`
          } If you would like to
            have a barcode generated for the new ${destinationContainerType}, check the box below.`}
        />
        {canSelectExistingPlate && isContainerFormat && (
          <GenericSelect
            additionalDataFragment={aliquotRearrayDestinationPlateFragment}
            fragment={["containerArray", "id name updatedAt"]}
            isMultiSelect
            isTubeTransfer={isTubeTransfer}
            name="destinationPlates"
            nameOverride={DestinationContainerType}
            {...mustSelectProp}
            postSelectDTProps={{
              formName: "aliquotRearrayDestinationPlatePreview",
              schema: [
                platePreviewColumn(),
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                {
                  displayName: `${DestinationContainerType} Type`,
                  path: "containerArrayType.name"
                }
              ]
            }}
            schema={["name", dateModifiedColumn]}
            tableParamOptions={{
              additionalFilter: this.destinationPlateFilter
            }}
          />
        )}
        {canSelectExistingPlate &&
          !isContainerFormat &&
          !isMicrobialTransformation && (
            <Callout style={{ marginBottom: 10, width: "25%" }} intent="danger">
              Please choose a destination {destinationContainerType} format.
            </Callout>
          )}
        <br />
        {(destinationPlates.length < 1 || isMicrobialTransformation) && (
          <Loading loading={containerArrayTypesLoading} inDialog>
            <CheckboxField
              name="generateBarcodes"
              label="Generate Barcodes"
              defaultValue
            />
            {(isMicrobialTransformation || isContainerFormat) && (
              <DataTable
                isSimple
                enableReinitialize
                keepDirtyOnReinitialize
                noForm
                schema={destinationPlateSchema}
                entities={plates}
                destroyOnUnmount={false}
                noSelect
                destinationContainerType={destinationContainerType}
                cellRenderer={{
                  name: renderPlateName,
                  containerArrayType: (v, record) => {
                    let filteredContainerArrayTypes;
                    if (isMicrobialTransformation) {
                      filteredContainerArrayTypes = containerArrayTypes.filter(
                        type =>
                          type.containerFormatCode ===
                          record.containerArrayType.containerFormatCode
                      );
                    } else {
                      filteredContainerArrayTypes = containerArrayTypes;
                    }
                    return (
                      <div style={{ padding: 3 }}>
                        <SelectField
                          name={`destinationPlateTypes.${record.index}`}
                          options={arrayToItemValuedOptions(
                            filteredContainerArrayTypes
                          )}
                          defaultValue={filteredContainerArrayTypes[0] || null}
                          validate={fieldRequired}
                          enableReinitialize
                        />
                      </div>
                    );
                  },
                  barcode: renderPlateBarcode
                }}
              />
            )}
          </Loading>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  tgFormValues(
    "generateBarcodes",
    "destinationPlates",
    "sourcePlates",
    "destinationPlateTypes",
    "destinationPlateNames",
    "isTubeTransfer"
  ),
  withQuery(containerArrayTypeFragment, {
    isPlural: true,
    options: ({ destinationContainerFormat, isTubeTransfer }) => {
      const qb = new queryBuilder("containerArrayType");
      if (destinationContainerFormat) {
        qb.whereAll({
          containerFormatCode: destinationContainerFormat.code
        });
      }
      if (isTubeTransfer) {
        qb.whereAny(
          {
            isPlate: false
          },
          {
            isPlate: qb.isNull()
          }
        );
      } else {
        qb.whereAll({
          isPlate: true
        });
      }
      return {
        variables: {
          filter: qb.toJSON()
        }
      };
    }
  })
)(DestinationPlateInfo);

const renderPlateName = (v, record, _, { destinationContainerType }) => {
  return (
    <InputField
      name={`destinationPlateNames.${record.index}`}
      placeholder={`Enter destination ${destinationContainerType} name...`}
      generateDefaultValue={{
        ...defaultValueConstants.DESTINATION_CONTAINER_NAME,
        customParams: {
          containerType: "Plate"
        }
      }}
    />
  );
};

const renderPlateBarcode = (v, record) => {
  return <InputField name={`destinationPlateBarcodes[${record.index}]`} />;
};
