/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import {
  BlueprintError,
  InputField,
  ReactSelectField,
  CheckboxField,
  NumericInputField
} from "@teselagen/ui";
import { times } from "lodash";
import { Button } from "@blueprintjs/core";
import {
  AddButton,
  RemoveButton
} from "../../../../src-shared/FieldArrayButtons";
import stepFormValues from "../../../../src-shared/stepFormValues";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";

class ReplicatePlateFields extends Component {
  addPlates = () => {
    const { numPlates, plateType, platePrefix, fields } = this.props;

    times(numPlates, index =>
      fields.push({
        name: `${platePrefix} ${index + 1}`,
        plateType: plateType
      })
    );
  };
  render() {
    const {
      fields,
      meta: { submitFailed, error },
      containerArrayTypes = [],
      generateBarcodes = {},
      sourcePlate = {},
      numPlates
    } = this.props;

    const filteredContainerArrayTypes = containerArrayTypes.filter(
      containerArrayType => {
        const properFormat =
          containerArrayType.containerFormatCode ===
          sourcePlate.containerArrayType.containerFormatCode;
        return properFormat;
      }
    );

    return (
      <React.Fragment>
        <CheckboxField
          name={`generateBarcodes.id${sourcePlate.id}`}
          label="Generate Barcodes For Replicate Plates"
          defaultValue
        />
        {fields.map((field, index) => (
          <React.Fragment key={`replicate.${index}`}>
            <div style={{ width: "50%" }}>
              <div className="tg-flex">
                <div style={{ flex: 1 }}>
                  <InputField
                    name={`${field}.name`}
                    label="Replicate Plate Name"
                    placeholder="Enter destination plate name..."
                  />
                  {!generateBarcodes["id" + sourcePlate.id] && (
                    <InputField
                      name={`${field}.barcode`}
                      label="Replicate Plate Barcode"
                    />
                  )}
                  <ReactSelectField
                    name={`${field}.plateType`}
                    label="Replicate Plate Type"
                    enableReinitialize
                    options={arrayToItemValuedOptions(
                      filteredContainerArrayTypes
                    )}
                  />
                </div>
                <div className="tg-flex-separator" />
                <RemoveButton fields={fields} index={index} />
              </div>
              {index !== fields.length - 1 && (
                <hr className="tg-section-break" />
              )}
            </div>
          </React.Fragment>
        ))}
        {fields.length > 0 ? (
          <div className="tg-flex justify-flex-start">
            <AddButton fields={fields} label="Add Replicate" />
          </div>
        ) : (
          <div style={{ width: "50%" }}>
            <div className="tg-flex">
              <div style={{ flex: 1 }}>
                <InputField
                  name="platePrefix"
                  label="Plate Name Prefix"
                  generateDefaultValue={{
                    ...defaultValueConstants.PLATE_NAME_PREFIX
                  }}
                />
                <NumericInputField
                  name="numPlates"
                  label="Number of plates to add"
                  defaultValue={1}
                  min={1}
                />
                <ReactSelectField
                  name="plateType"
                  label="Plate Type"
                  options={arrayToItemValuedOptions(
                    filteredContainerArrayTypes
                  )}
                  defaultValue={filteredContainerArrayTypes[0]}
                />
                <Button
                  icon="add"
                  intent="primary"
                  text={numPlates > 1 ? "Add Plates" : "Add Plate"}
                  onClick={this.addPlates}
                />
              </div>
            </div>
          </div>
        )}
        {submitFailed && <BlueprintError error={error} />}
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("generateBarcodes", "numPlates", "platePrefix", "plateType")
)(ReplicatePlateFields);
