/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DataTable } from "@teselagen/ui";
import HeaderWithHelper from "./HeaderWithHelper";
import modelNameToReadableName from "./utils/modelNameToReadableName";

export const removeExtendedPropertiesTableName = "extendedPropertiesToRemove";

function RemoveExtendedPropertiesTable({ model, existingProps }) {
  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Remove Existing Extended Properties"
        helper={`The selected ${modelNameToReadableName(model, {
          plural: true
        })} already have the extended properties displayed below. Check any you would like removed upon submission.`}
        width="100%"
      />
      <div style={{ maxWidth: 500 }}>
        <DataTable
          entities={existingProps}
          schema={["name"]}
          maxHeight={300}
          destroyOnUnmount={false}
          isSimple
          withCheckboxes
          compact
          formName={removeExtendedPropertiesTableName}
        />
      </div>
    </div>
  );
}

export default RemoveExtendedPropertiesTable;
