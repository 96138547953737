/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Dialog } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, InputField } from "@teselagen/ui";
import { set, times, isEmpty } from "lodash";
import PropTypes from "prop-types";

import actions from "../../../../src-shared/redux/actions";
import {
  getInputCardsOfReaction,
  getItemOfType,
  getReferencedValue,
  getOutputCardIdOfReaction,
  isCardCircular
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";
import { getJunctionOnCard } from "../../../../../tg-iso-design/selectors/junctionSelectors";

const mapStateToProps = (state, props) => {
  const { reactionId } = props;
  const outputCardId = getOutputCardIdOfReaction(state, reactionId);
  const inputCards = getInputCardsOfReaction(state, reactionId);
  const reaction = getItemOfType(state, "reaction", reactionId);
  return {
    reaction,
    isCircular: isCardCircular(state, outputCardId),
    inputCards,
    restrictionEnzyme: getReferencedValue(
      state,
      "reaction",
      reactionId,
      "restrictionEnzymeId"
    ),
    initialValues: {
      overhangBps: inputCards
        .map(c => {
          const junction = getJunctionOnCard(state, c.id, false);
          if (junction) {
            return junction.bps || "";
          } else {
            return null;
          }
        })
        .filter(bps => bps !== null)
    }
  };
};

const mapDispatchToProps = {
  assignOverhangs: actions.design.assignOverhangs
};

class AssignReactionOverhangsDialog extends React.Component {
  static propTypes = {
    /**
     * The reaction whose overhangs we are assigning. Must be a digest reaction.
     */
    reactionId: PropTypes.string.isRequired
  };

  onSubmit = ({ overhangBps }) => {
    const {
      hideModal,
      reactionId,
      assignOverhangs,
      isCircular,
      restrictionEnzyme: { forwardSnipPosition, reverseSnipPosition },
      inputCards
    } = this.props;

    const regexLength = Math.abs(forwardSnipPosition - reverseSnipPosition);

    assignOverhangs({
      inputIndexToThreePrimeOverhang: times(
        inputCards.length - +!isCircular,
        i => overhangBps[i] || times(regexLength, () => "N").join("")
      ),
      reactionId
    });

    hideModal();
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      submitting,
      isCircular,
      inputCards,
      restrictionEnzyme: { forwardSnipPosition, reverseSnipPosition }
    } = this.props;
    const length = Math.abs(forwardSnipPosition - reverseSnipPosition);
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title="Assign Overhangs"
        style={{ minWidth: 550 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="bp3-dialog-body">
            {inputCards.map((input, i) => {
              if (!isCircular && i === inputCards.length - 1) return null;
              const nextInputIndex = (i + 1) % inputCards.length;
              const nextInput = inputCards[nextInputIndex];
              return (
                <InputField
                  placeholder={"N".repeat(length)}
                  key={i}
                  name={`overhangBps[${i}]`}
                  label={`Input ${i + 1} ${
                    input.name ? "(" + input.name + ") " : ""
                  }– Input ${nextInputIndex + 1} ${
                    nextInput.name ? "(" + nextInput.name + ")" : ""
                  }`}
                />
              );
            })}
          </div>
          <DialogFooter
            hideModal={hideModal}
            text="OK"
            submitting={submitting}
          />
        </form>
      </Dialog>
    );
  }
}

const validate = (values, props) => {
  const {
    isCircular,
    restrictionEnzyme: { forwardSnipPosition, reverseSnipPosition },
    inputCards
  } = props;
  const { overhangBps = [] } = values;

  const regexLength = Math.abs(forwardSnipPosition - reverseSnipPosition);
  const errors = {};
  times(inputCards.length - 1 * !isCircular, i => {
    overhangBps[i] = overhangBps[i] || "";
  });
  overhangBps.forEach((overhangRegex = "", i) => {
    if (!overhangRegex) return;
    else if (overhangRegex && !/^[acgtn]*$/i.test(overhangRegex))
      set(errors, `overhangBps[${i}]`, "Contains invalid character");
    else if (overhangRegex.length !== regexLength)
      set(
        errors,
        `overhangBps[${i}]`,
        `Invalid length (must be ${regexLength} bps long)`
      );
  });

  if (isEmpty(errors)) {
    overhangBps.forEach((overhangRegex, i) => {
      if (!overhangRegex || /^n+$/i.test(overhangRegex)) return;
      overhangRegex = (overhangRegex || "").toLowerCase();
      const rc = getReverseComplementSequenceString(overhangRegex);
      if (overhangRegex === rc) {
        set(
          errors,
          `overhangBps[${i}]`,
          "Can't have self-incompatible overhangs"
        );
      } else if (
        overhangBps.some(
          (r, j) => i !== j && (r || "").toLowerCase() === overhangRegex
        )
      ) {
        set(
          errors,
          `overhangBps[${i}]`,
          "Can't have duplicate overhangs in the same digest"
        );
      } else if (
        overhangBps.some((r, j) => i !== j && (r || "").toLowerCase() === rc)
      ) {
        set(
          errors,
          `overhangBps[${i}]`,
          "Can't have reverse complement-duplicated overhangs in the same digest"
        );
      }
    });
  }
  return errors;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "assignReactiomOverhangsForm",
    enableReinitialize: true,
    validate
  })
)(AssignReactionOverhangsDialog);
