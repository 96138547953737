/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, map, flatMap, forEach } from "lodash";

import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

export function computeDataTableValues(constructDataTables) {
  const constructMap = {};
  const constructDataTablesName = constructDataTables
    .map(function(constructDataTable) {
      return constructDataTable.name;
    })
    .join(", ");
  constructDataTables.forEach(constructDataTable => {
    constructDataTable.dataRows &&
      constructDataTable.dataRows.forEach(dataRow => {
        dataRow.dataRowJ5Items &&
          dataRow.dataRowJ5Items.forEach(dataRowJ5Item => {
            const construct = get(dataRowJ5Item, "j5Item.j5RunConstruct");
            if (construct) {
              constructMap[construct.id] = construct;
            }
          });
      });
  });

  const reactions = map(constructMap, construct => {
    const inputMaterials = [];
    construct.j5ConstructAssemblyPieces.forEach(j5ConstructAssemblyPiece => {
      let material;
      if (j5ConstructAssemblyPiece.assemblyPiece.type === "Digest Linearized") {
        material = get(
          j5ConstructAssemblyPiece,
          "assemblyPiece.j5AssemblyPieceParts[0].j5InputPart.j5InputSequence.sequence.polynucleotideMaterial"
        );
      } else {
        material = get(
          j5ConstructAssemblyPiece,
          "assemblyPiece.sequence.polynucleotideMaterial"
        );
      }
      if (material) {
        inputMaterials.push(material);
      }
    });
    const reaction = {
      name: `${construct.name} reaction`,
      inputs: {
        names: inputMaterials.map(mat => mat.name).join(", ")
      },
      output: {
        name: get(construct, "sequence.polynucleotideMaterial.name")
      },
      inputMaterials,
      ...getReactionInputsAndOutputs({
        inputMaterials,
        outputMaterials: [get(construct, "sequence.polynucleotideMaterial")]
      })
    };
    return reaction;
  }).sort((a, b) => {
    const aConstructNumMatch = a.name.match(/(\d+)/g);
    const bConstructNumMatch = b.name.match(/(\d+)/g);
    if (aConstructNumMatch && bConstructNumMatch) {
      return Number(aConstructNumMatch[0]) - Number(bConstructNumMatch[0]);
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  const constructReactionMap = {
    reactionTypeCode: "ASSEMBLY_REACTION",
    reactions: reactions
  };

  return {
    constructDataTablesName,
    constructMap,
    constructReactionMap
  };
}

export function getMaterialsFromDataTable(selectedDataTables) {
  const selectedAssemblyPieceLists = selectedDataTables;
  return selectedAssemblyPieceLists.length
    ? flatMap(
        selectedAssemblyPieceLists.map(list => {
          return list.dataRows.map(dataRow => {
            const assemblyPiece = get(
              dataRow,
              "dataRowJ5Items[0].j5Item.j5AssemblyPiece"
            );
            if (assemblyPiece.type === "Digest Linearized") {
              return {
                id: get(
                  assemblyPiece,
                  "j5AssemblyPieceParts[0].j5InputPart.j5InputSequence.sequence.polynucleotideMaterialId"
                ),
                name: get(
                  assemblyPiece,
                  "j5AssemblyPieceParts[0].j5InputPart.j5InputSequence.sequence.polynucleotideMaterial.name"
                )
              };
            } else {
              return {
                id: get(
                  dataRow,
                  "dataRowJ5Items[0].j5Item.j5AssemblyPiece.sequence.polynucleotideMaterialId"
                ),
                name: get(
                  dataRow,
                  "dataRowJ5Items[0].j5Item.j5AssemblyPiece.sequence.polynucleotideMaterial.name"
                )
              };
            }
          });
        })
      )
    : [];
}

export function makeMaterialToAliquotContainerMap({
  containerArrays = [],
  aliquotContainers = [],
  assemblyMaterials = []
}) {
  const assemblyPieceMaterialIds = assemblyMaterials.map(
    material => material.id
  );
  const assemblyPieceMaterialIdToSourceAliquotContainerMap = {};
  const mapAliquotContainer = ac => {
    const aliquotContainerMaterialId = get(ac, "aliquot.sample.material.id");
    if (aliquotContainerMaterialId) {
      if (assemblyPieceMaterialIds.includes(aliquotContainerMaterialId)) {
        assemblyPieceMaterialIdToSourceAliquotContainerMap[
          aliquotContainerMaterialId
        ] =
          assemblyPieceMaterialIdToSourceAliquotContainerMap[
            aliquotContainerMaterialId
          ] || [];
        assemblyPieceMaterialIdToSourceAliquotContainerMap[
          aliquotContainerMaterialId
        ].push(ac);
      }
    }
  };

  containerArrays.forEach(plate => {
    plate.aliquotContainers.forEach(mapAliquotContainer);
  });

  aliquotContainers.forEach(mapAliquotContainer);
  forEach(
    assemblyPieceMaterialIdToSourceAliquotContainerMap,
    (arrayOfAcs, key) => {
      assemblyPieceMaterialIdToSourceAliquotContainerMap[key] = arrayOfAcs.sort(
        (a, b) => {
          return (
            standardizeVolume(
              a.aliquot.volume || 0,
              a.aliquot.volumetricUnitCode
            ) -
            standardizeVolume(
              b.aliquot.volume || 0,
              b.aliquot.volumetricUnitCode
            )
          );
        }
      );
    }
  );
  return assemblyPieceMaterialIdToSourceAliquotContainerMap;
}
