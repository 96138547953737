/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { FullUserFragment } from "../../graphql/fragments/fullUserFragment.gql.generated";

export const identifySegmentUser = (currentUser: FullUserFragment) => {
  // set user for segment analytics
  try {
    if (
      currentUser &&
      currentUser.id &&
      window.frontEndConfig.segmentAnalyticsKey
    ) {
      const { id, ...rest } = currentUser;
      window.analytics.identify(id, rest);
    }
  } catch (error) {
    console.error("Error setting user for analytics");
    console.error(error);
  }
};
