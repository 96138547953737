/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import dataRowFragment from "./dataRowFragment";
import dataTableTypeFragment from "./dataTableTypeFragment";
import dataSetPartialFragment from "./dataSetPartialFragment";

export default gql`
  fragment dataTableFragment on dataTable {
    id
    name
    createdAt
    updatedAt
    dataSetId
    dataTableTypeCode
    dataItems {
      id
      ioItem {
        id
        microserviceQueueId
      }
    }
    vendorOrder {
      id
      name
    }
    dataSet {
      ...dataSetPartialFragment
    }
    dataRows {
      ...dataRowFragment
    }
    dataTableType {
      ...dataTableTypeFragment
    }
  }
  ${dataSetPartialFragment}
  ${dataTableTypeFragment}
  ${dataRowFragment}
`;
