/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Switch } from "@blueprintjs/core";
import ReactToPrint from "react-to-print";
import React from "react";

const PRINT_WIDTH = 400;

class ComponentToPrint extends React.Component {
  componentDidMount() {
    this.addPrintEl();
  }
  addPrintEl = () => {
    const printEl = document.querySelector(".hdeDesignPrintBody");
    if (!printEl) return;
    // clone the design dom node and put it into the print window
    const docToGrab =
      document.getElementById("classicGridContainer") ||
      document.getElementById("tg-tree-container");
    const cloned = docToGrab.cloneNode(true);

    //strip the cloned node of the add buttons because we don't want them in the pdf version
    cloned.querySelectorAll(".add-button").forEach(n => n.remove());
    //unhighlight all cells and bins
    cloned
      .querySelectorAll(".selected-cell-outline")
      .forEach(n => n && n.classList.remove("selected-cell-outline"));
    cloned
      .querySelectorAll(".delete-button-cross")
      .forEach(n => n && n.classList.remove("delete-button-cross"));
    cloned
      .querySelectorAll(".selected-bin-header-outline")
      .forEach(n => n && n.classList.remove("selected-bin-header-outline"));
    //some little style hacks
    cloned.style.overflow = "visible";
    if (cloned.querySelector(".simplified-grid")) {
      cloned.querySelector(".simplified-grid").style.overflow = "visible";
    }
    cloned.style.background = "none";
    let scrollDistance = document.querySelector("#tg-tree-container div") ||
      document.querySelector(".simplified-grid-svg") || {
        scrollWidth: PRINT_WIDTH,
        scrollHeight: PRINT_WIDTH
      };
    scrollDistance = Math.max(
      scrollDistance.scrollWidth,
      scrollDistance.scrollHeight
    );

    //scale it down so it will fit on the page
    const scale = this.props.scaleToFitSinglePage
      ? PRINT_WIDTH / scrollDistance
      : 1;

    cloned.style.transform = `scale(${Math.min(scale, 1)})`;
    cloned.style.transformOrigin = "top left";

    while (printEl.firstChild) {
      printEl.removeChild(printEl.firstChild);
    }

    printEl.appendChild(cloned);
  };
  render() {
    this.addPrintEl();

    return (
      <div
        style={{
          pointerEvents: "none",
          width: "fit-content",
          height: "fit-content"
        }}
        className="hdeDesignPrintBody"
      >
        <h4>Design: {this.props.designName}</h4>
      </div>
    );
  }
}

export default class DesignPrintDialog extends React.Component {
  state = {
    scaleToFitSinglePage: true
  };
  render() {
    const { hideModal } = this.props;
    return (
      <div className="hdePrintDialog">
        <Switch
          style={{ margin: "10px 0 10px 10px" }}
          onChange={e => {
            this.setState({
              scaleToFitSinglePage: e.target.checked
            });
          }}
          checked={this.state.scaleToFitSinglePage}
        >
          Scale To Fit Single Page
        </Switch>
        <div
          style={
            this.props.scaleToFitSinglePage
              ? { display: "flex", justifyContent: "center" }
              : {}
          }
        >
          <div
            style={{
              ...(1 || this.props.scaleToFitSinglePage
                ? {
                    maxWidth: PRINT_WIDTH,
                    maxHeight: PRINT_WIDTH,
                    width: PRINT_WIDTH,
                    height: PRINT_WIDTH
                  }
                : {}),

              overflow: this.props.scaleToFitSinglePage ? "visible" : "auto"
            }}
            className="componentToPrintContainer"
          >
            <ComponentToPrint
              {...this.props}
              scaleToFitSinglePage={this.state.scaleToFitSinglePage}
              ref={el => (this.componentRef = el)}
            />
          </div>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ReactToPrint
              trigger={() => (
                <Button style={{ margin: 10 }} intent="primary">
                  Print
                </Button>
              )}
              content={() => this.componentRef}
              bodyClass="ve-print"
              //       pageStyle={`@page { size: auto;  margin: 0mm; } @media print { //@jon this could be where we maybe force landscape
              // body {
              //    -webkit-print-color-adjust: exact; page-break-after: always;
              // } }`}
              // ignoreLinks //needed because some css is linked to but is not loading..
              onAfterPrint={hideModal}
            />
          </div>
        </div>
      </div>
    );
  }
}
