/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import {
  SBOL_ICON_HEIGHT,
  SBOL_ICON_WIDTH
} from "../../../../../src-shared/components/HierarchicalDesign/constants";

class Breakpoint extends React.Component {
  getDefaultValue = () => {
    return {
      indices: []
    };
  };

  handleClick = () => {
    const {
      input: { onChange },
      index
    } = this.props;
    const value = this.props.input.value
      ? this.props.input.value
      : this.getDefaultValue();

    const breakPointIndices = value.indices;

    if (breakPointIndices.includes(index))
      onChange({
        ...value,
        indices: [...breakPointIndices].filter(idx => idx !== index)
      });
    else
      onChange({
        ...value,
        indices: [...breakPointIndices, index]
      });
  };

  render() {
    const {
      input: { value },
      index
    } = this.props;

    const breakPointIndices = value ? value.indices : [];
    const isSelected = breakPointIndices.includes(index);

    const breakPointWidth = isSelected ? 10 : 2;

    return (
      <div
        className="operation-breakpoint-wrapper"
        style={{
          position: "absolute",
          // right: -0.8 * SBOL_ICON_WIDTH / 2,
          right: index !== 0 && (-0.8 * SBOL_ICON_WIDTH) / 2,
          left: index === 0 && (-0.8 * SBOL_ICON_WIDTH) / 2,
          height: SBOL_ICON_HEIGHT,
          width: 0.8 * SBOL_ICON_WIDTH,
          bottom: 0,
          textAlign: "center"
        }}
        onClick={this.handleClick}
      >
        <span
          className={
            "operation-breakpoint" +
            (isSelected ? " operation-breakpoint-selected" : "")
          }
          style={{
            height: SBOL_ICON_HEIGHT,
            width: breakPointWidth
          }}
        />
      </div>
    );
  }
}

export default Breakpoint;
