/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "redux";
import {
  InputField,
  NumericInputField,
  CheckboxField,
  DialogFooter
} from "@teselagen/ui";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";
import TagField from "../../../src-shared/TagField";
import { wrapDialog } from "@teselagen/ui";
import { noop } from "lodash";
import { createTaggedItems } from "../../../src-shared/utils/tagUtils";
import { handleDuplicateSequences } from "./DuplicateSequenceContextMenuDialog";

const SaveJ5SeqsToSequenceDialog = ({
  hideModal,
  j5ReportIds,
  isAnnealedOligos,
  isj5AssemblyPieces,
  isOligos,
  isSynthonSequences,
  afterSave = noop,
  submitting,
  handleSubmit,
  keepConstructName
}) => {
  const onSubmit = async values => {
    const {
      keepConstructName,
      namePrefix,
      incrementStart,
      appendUniqueIdToName,
      saveAsEditableDuplicate,
      tags
    } = values;
    try {
      const getNewName = (c, index) => {
        return (
          keepConstructName
            ? c.name
            : namePrefix + (parseInt(incrementStart, 10) + index)
        ).concat(
          appendUniqueIdToName
            ? "_" + Math.random().toString(36).substring(7)
            : ""
        );
      };
      const model = isj5AssemblyPieces
        ? "j5AssemblyPiece"
        : isOligos
          ? "j5OligoSynthesis"
          : isAnnealedOligos
            ? "j5AnnealedOligo"
            : isSynthonSequences
              ? "j5DirectSynthesis"
              : "j5RunConstruct";

      const inner = `id sequenceId sequence {id isInLibrary}`;
      const itemsWithSequence = await safeQuery(
        [model, `name id ${isOligos ? `oligo {${inner}}` : inner}`],
        {
          isPlural: true,
          variables: {
            filter: { j5ReportId: j5ReportIds }
          }
        }
      );

      if (saveAsEditableDuplicate) {
        const nameMap = {};
        const sequenceIds = itemsWithSequence.map((c, i) => {
          const id = isOligos ? c?.oligo?.sequenceId : c?.sequenceId;
          nameMap[id] = getNewName(c, i);
          return id;
        });
        await handleDuplicateSequences({
          sequenceIds,
          nameMap,
          tags
        });
      } else {
        const updatedSeqs = await safeUpsert(
          "sequence",
          itemsWithSequence.map((c, i) => {
            const id = isOligos ? c?.oligo?.sequenceId : c?.sequenceId;
            return {
              id,
              isInLibrary: true,
              name: getNewName(c, i)
            };
          })
        );
        await createTaggedItems({
          selectedTags: tags,
          recordIds: updatedSeqs.map(s => s.id),
          model: "sequence"
        });
      }

      await afterSave();
      hideModal();
      window.toastr.success(`${isOligos ? "Oligos" : "Sequences"} saved.`);
    } catch (err) {
      hideModal();
      console.error("Error saving sequences!", err.message);
      window.toastr.error("Error saving sequences.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          name="namePrefix"
          label="Name Prefix"
          placeholder="Sequences will be saved as 'prefix1, prefix2, etc..."
          disabled={keepConstructName}
        />
        <NumericInputField
          name="incrementStart"
          label="Increment Start"
          defaultValue={1}
        />
        <CheckboxField
          name="saveAsEditableDuplicate"
          inlineLabel={true}
          tooltipInfo="This will save the sequence as an editable copy in the sequence library."
          label="Save as an Editable Duplicate"
        />
        <CheckboxField
          name="keepConstructName"
          inlineLabel={true}
          label="Keep name"
        />
        <CheckboxField
          name="appendUniqueIdToName"
          inlineLabel={true}
          label="Append unique id to name"
        />
        <TagField />
      </div>
      <DialogFooter submitting={submitting} hideModal={hideModal} />
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.namePrefix && !values.keepConstructName) {
    errors.namePrefix = "Required";
  }
  return errors;
};

export default compose(
  wrapDialog(
    ({
      title,
      isResave,
      isj5AssemblyPieces,
      isAnnealedOligos,
      isOligos,
      isSynthonSequences
    }) => {
      //Save Oligos to Oligo Library
      if (title) return { title };
      const _title = `${isResave ? "Re-" : ""}Save ${
        isj5AssemblyPieces
          ? "Assembly Pieces"
          : isAnnealedOligos
            ? "Annealed Oligos"
            : isOligos
              ? "Oligos"
              : isSynthonSequences
                ? "Synthon Sequences"
                : "Assembled Constructs"
      } to ${isOligos ? "Oligo" : "Sequence"} Library`;
      return {
        title: _title
      };
    }
  ),
  reduxForm({
    form: "SaveJ5SeqsToSequenceLibrary", // a unique name for this form
    enableReinitialize: true,
    validate
  }),
  tgFormValues("keepConstructName")
)(SaveJ5SeqsToSequenceDialog);
