/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import download from "downloadjs";
import { EXPORT_VERSION } from "../../../../../tg-iso-design/redux/sagas/exportDesign/constants";

export { EXPORT_VERSION };

export default async function designToCsvGenbank(design) {
  const singleDesign = Array.isArray(design) ? design[0] : design;
  try {
    const res = await window.serverApi.request({
      method: "POST",
      url: "/exportDesigns",
      responseType: "blob",
      data: {
        type: "csv/genbank",
        designId: singleDesign.id
      }
    });

    const type = "application/zip, application/octet-stream";
    const name = `${singleDesign.name}.zip`;

    if (!window.Cypress) {
      download(res.data, name, type);
    }
    window.toastr.success("File downloaded.");
    return true;
  } catch (error) {
    console.error(`error:`, error);
    let message = error.message;
    if (error.response?.data) {
      message = await error.response.data.text();
    }
    window.toastr.error(`Error exporting design: ${message}`);
    return false;
  }
}
