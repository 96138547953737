/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { withProps } from "recompose";
import { forEach } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { SelectMaterials, ReviewMaterials } from "./Steps";

import {
  purificationPlanningPlateMapGroupFragment,
  purificationPlanningPlateFragment,
  purificationPlanningTubeFragment
} from "./fragments";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

class ProteinPurificationPlanning extends React.Component {
  validate = values => {
    const {
      reactionMapName,
      sourcePlates = [],
      sourceTubes = [],
      plateMapGroups = []
    } = values;
    const errors = {};
    if (!reactionMapName) {
      errors.reactionMapName = "Please provide a name for the reaction map.";
    }
    if (!sourcePlates.length && !sourceTubes.length && !plateMapGroups.length) {
      errors.sourcePlates =
        "Must select at least one plate, tube or plate map.";
      errors.sourceTubes = "Must select at least one plate, tube or plate map.";
      errors.plateMapGroups =
        "Must select at least one plate, tube or plate map.";
    }
    return errors;
  };
  onSubmit = async values => {
    const { reactionInfo, selectedProteinMaterials, reactionMapName } = values;
    try {
      const inputMaterialIdToProteinMap = {};
      const reactions = [];

      forEach(selectedProteinMaterials, (value, key) => {
        inputMaterialIdToProteinMap[key.replace("id", "")] = value;
      });
      reactionInfo.forEach(reaction => {
        const inputMaterialId = reaction.inputMaterial.id;
        reactions.push({
          name: `${reaction.inputMaterial.name} > ${inputMaterialIdToProteinMap[inputMaterialId].name}`,
          ...getReactionInputsAndOutputs({
            inputMaterials: [reaction.inputMaterial],
            outputMaterialId: inputMaterialIdToProteinMap[inputMaterialId].id
          })
        });
      });
      const reactionMapToCreate = {
        name: reactionMapName,
        reactionTypeCode: "PROTEIN_PURIFICATION",
        reactions
      };
      const [createdReactionMap] = await safeUpsert(
        "reactionMap",
        reactionMapToCreate
      );
      window.toastr.success("Successfully created reaction map");
      return {
        reactionMap: createdReactionMap
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating reaction map."
      });
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Materials From Inventory",
        Component: SelectMaterials,
        withCustomFooter: true
      },
      {
        title: "Review Materials",
        Component: ReviewMaterials,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(purificationPlanningPlateFragment),
  withWorkflowInputs(purificationPlanningTubeFragment),
  withWorkflowInputs(purificationPlanningPlateMapGroupFragment),
  withProps(props => {
    const { aliquotContainers, containerArrays, plateMapGroups } = props;
    return {
      initialValues: {
        sourceTubes: aliquotContainers,
        sourcePlates: containerArrays,
        plateMapGroups
      }
    };
  })
)(ProteinPurificationPlanning);
