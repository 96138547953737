/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// import shortid from "shortid";
import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, upsertAddIds } from "./utils";
import { identity } from "lodash";

const SELECTION_METHOD = async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx
) {
  // First process the organisms
  // Update them accordingly or even create new ones.
  const recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "organismGroups",
    async r => {
      await upsertHandlers.ORGANISM(
        {
          ...rest,
          model: "organismClass",
          recordsToImport: r,
          upsertHandlers
        },
        ctx
      );
    }
  );

  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      precheckWarningsFn: () => {},
      convertUserFacingToDbModel: identity,
      model: "selectionMethod"
    },
    ctx
  );

  // create new parts
  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport: recordsToContinueUpserting,
      modelOrFragment: "selectionMethod"
    },
    ctx
  );
};
export default SELECTION_METHOD;
