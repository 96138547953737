/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { diffDesignStates } from "../../../../../tg-iso-design/utils/designStateUtils";
import { socketWrapper } from "../../../../src-shared/socket/socketWrapper";

const executeMutations = async (designId, diff) => {
  return await socketWrapper.updateDesign({ designId, ...diff });
};

/**
 * Both arguments are design states, not the full state.
 */
export default async (lastSavedState, currentState) => {
  const designId = Object.keys(currentState.design)[0];
  const diff = diffDesignStates(lastSavedState, currentState);
  return await executeMutations(designId, diff);
};
