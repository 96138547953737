/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { bottle } from "../../../../tg-iso-lims/src/dependencyContainer";
import checkForDuplicatePlatesOrTubesOnImport from "../checkForDuplicatePlatesOrTubesOnImport";
import updateVendorOrderDialogAsPromise from "../../components/Dialogs/UpdateVendorOrderDialog";
import showDuplicateInputSequencesConfirmation from "../../showDuplicateInputSequencesConfirmation";
import showStrippedAminoAcidsWarningConfirmation from "../../showStrippedAminoAcidsWarningConfirmation";
import { isCommonLabLocked } from "../labUtils";

export const initDependencyContainer = () => {
  bottle.factory(
    "checkForDuplicatePlatesOrTubesOnImport",
    () => checkForDuplicatePlatesOrTubesOnImport
  );

  bottle.factory(
    "updateVendorOrderDialogAsPromise",
    () => updateVendorOrderDialogAsPromise
  );

  bottle.factory(
    "showDuplicateInputSequencesConfirmation",
    () => showDuplicateInputSequencesConfirmation
  );

  bottle.factory(
    "showStrippedAminoAcidsWarningConfirmation",
    () => showStrippedAminoAcidsWarningConfirmation
  );

  bottle.factory("isCommonLabLocked", () => isCommonLabLocked);
};
