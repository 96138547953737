/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "redux";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import gql from "graphql-tag";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import { showDialog } from "../../src-shared/GlobalDialog";
import { safeQuery } from "../../src-shared/apolloMethods";

const schema = {
  model: "codonMap",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "description", type: "string", displayName: "Description" }
  ]
};

class CodonMapLibrary extends Component {
  onNewItemClick = () => {
    const { refetch } = this.props;
    showDialog({
      modalType: "CODON_MAP",
      modalProps: {
        refetch
      }
    });
  };

  onDoubleClick = async (codonMap = {}) => {
    const { refetch } = this.props;

    if (codonMap.id) {
      codonMap = await safeQuery(singleItemFragment, {
        variables: {
          filter: {
            id: codonMap.id
          }
        }
      });
    }

    showDialog({
      modalType: "CODON_MAP",
      modalProps: {
        refetch: refetch,
        codonMapId: codonMap[0].id,
        initialValues: {
          ...codonMap[0]
        }
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        isLibraryTable
        onDoubleClick={this.onDoubleClick}
        // ViewerComponent={ViewerComponent}
        onNewItemClick={this.onNewItemClick}
      />
    );
  }
}

const libraryFragment = gql`
  fragment codonMapLibraryFragment on codonMap {
    id
    name
    description
  }
`;

const singleItemFragment = gql`
  fragment codonMapSingleItemFragment on codonMap {
    id
    name
    description
    codonMapEntries {
      id
      probability
      aminoAcidResidueCode
      dnaCodonCode
    }
  }
`;

const CodonMapLibraryContainer = compose(
  libraryEnhancer({
    fragment: libraryFragment,
    schema,
    noAddedBy: true
  })
)(CodonMapLibrary);

export default CodonMapLibraryContainer;
