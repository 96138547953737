/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { CheckboxField, DialogFooter, InputField } from "@teselagen/ui";

import { reduxForm } from "redux-form";
import { compose } from "redux";
import actions from "../../../../../src-shared/redux/actions";
import { connect } from "react-redux";
import { Dialog, Classes } from "@blueprintjs/core";
import { safeUpsert } from "../../../../../src-shared/apolloMethods";
import "./style.css";

class editPartsetDialog extends React.Component {
  onSubmit = async values => {
    const { hideModal, item, changePartsetPart } = this.props;
    try {
      if (item.name !== values.partName) {
        await safeUpsert("part", { id: item.partId, name: values.partName });
        await changePartsetPart({
          partId: item.partId,
          newName: values.partName
        });
      }
      if (item.isDeactivated !== values.isDeactivated) {
        await safeUpsert("partsetPart", {
          id: item.id,
          isDeactivated: values.isDeactivated
        });
        await changePartsetPart({
          id: item.id,
          isDeactivated: values.isDeactivated
        });
      }
      if (
        item.name === values.partName &&
        item.isDeactivated !== values.isDeactivated
      ) {
        if (values.isDeactivated === true) {
          window.toastr.success("Deactivated Part");
        } else {
          window.toastr.success("Activated Part");
        }
      } else if (
        item.name !== values.partName &&
        item.isDeactivated === values.isDeactivated
      ) {
        window.toastr.success(
          `Changed Part Name From ${item.name} to ${values.partName}`
        );
      } else {
        if (values.isDeactivated === true) {
          window.toastr.success(
            `Changed Part Name From ${item.name} to ${values.partName} and Deactivated Part`
          );
        } else {
          window.toastr.success(
            `Changed Part Name From ${item.name} to ${values.partName} and Activated Part`
          );
        }
      }
    } catch (error) {
      window.toastr.error(`error: ${error}`);
    }
    hideModal();
  };

  render() {
    const { hideModal, handleSubmit, item } = this.props;

    return (
      <div>
        <Dialog
          canOutsideClickClose={false}
          isOpen
          onClose={() => hideModal()}
          title="Edit Partset"
          style={{ width: 900 }}
        >
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className={Classes.DIALOG_BODY}>
              <InputField
                name="partName"
                label="Name"
                defaultValue={item.name}
              />
              <CheckboxField
                name="isDeactivated"
                label="Deactivated"
                defaultValue={item.isDeactivated}
              />
            </div>
            <DialogFooter hideModal={hideModal} text="OK" />
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = {
  changePartsetPart: actions.designEdit.changePartsetPart
};

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({ form: "editPartsetPartDialog", enableReinitialize: true })
)(editPartsetDialog);
