/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import { keyBy, uniq } from "lodash";

import { throwFormError } from "../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";

import caseInsensitiveFilter from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { validateNameCsvTableUniqueAsync } from "../../components/AppSettings/validateNameCsvTableUniqueAsync";

class UploadRackTypes extends Component {
  onSubmit = async values => {
    try {
      const { refetch, hideModal } = this.props;

      const namesToCheck = [];
      const formatsToCheck = [];
      const tubeTypeNamesToCheck = [];
      const getRowTubeTypes = row => {
        return row["tube types"]
          .split(",")
          .map(t => t.trim().toLowerCase())
          .filter(t => t);
      };
      for (const row of values.rackTypesFile[0].parsedData) {
        namesToCheck.push(row.name);
        formatsToCheck.push(row.format);
        tubeTypeNamesToCheck.push(...getRowTubeTypes(row));
      }
      if (!namesToCheck.length) return window.toastr.error("No rows in csv.");
      if (!tubeTypeNamesToCheck)
        return window.toastr.error("No valid tube types specified in csv.");
      const existingRackTypes = await safeQuery(
        ["containerArrayType", "id name"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "containerArrayType",
              "name",
              namesToCheck
            )
          }
        }
      );
      const existingFormats = await safeQuery(
        ["containerFormat", "code name"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "containerFormat",
              "name",
              formatsToCheck
            )
          }
        }
      );
      const existingTubeTypes = await safeQuery(
        ["aliquotContainerType", "code name"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "aliquotContainerType",
              "name",
              tubeTypeNamesToCheck,
              {
                additionalFilter: {
                  isTube: true
                }
              }
            )
          }
        }
      );
      const newRackTypes = [];
      const keyedExisting = keyBy(existingRackTypes, t => t.name.toLowerCase());
      const keyedFormats = keyBy(existingFormats, f => f.name.toLowerCase());
      const keyedTubeTypes = keyBy(existingTubeTypes, f =>
        f.name.toLowerCase()
      );
      for (const [index, row] of values.rackTypesFile[0].parsedData.entries()) {
        const {
          name = "",
          description,
          "catalog number": catalogNumber,
          manufacturer,
          format = ""
        } = row;
        if (keyedExisting[name.toLowerCase()]) {
          throw new Error(
            `Row ${index + 1} specifies the name ${name} which already exists`
          );
        }
        const containerFormat = keyedFormats[format.toLowerCase()];
        if (!containerFormat) {
          throw new Error(
            `Row ${index + 1} specifies the format ${format} which is not valid`
          );
        }
        const tubeTypes = getRowTubeTypes(row);
        if (!tubeTypes.length) {
          throw new Error(`Row ${index + 1} did not specify any tube types.`);
        }
        const aliquotContainerTypeCodes = uniq(
          tubeTypes.map(type => {
            const matchedType = keyedTubeTypes[type];
            if (!matchedType) {
              throw new Error(
                `Row ${index +
                  1} specified the tube type "${type}" which was not valid`
              );
            }
            return matchedType.code;
          })
        );

        newRackTypes.push({
          name,
          description,
          catalogNumber,
          manufacturer,
          containerFormatCode: containerFormat.code,
          isPlate: false,
          nestableTubeTypes: aliquotContainerTypeCodes.map(
            aliquotContainerTypeCode => {
              return {
                aliquotContainerTypeCode
              };
            }
          )
        });
      }
      await safeUpsert("containerArrayType", newRackTypes);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading plate types.`);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            isRequired
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              fileName: "rackTypes",

              validateAgainstSchema: {
                ...validateNameCsvTableUniqueAsync({
                  model: "containerArrayType"
                }),
                fields: [
                  {
                    path: "name",
                    description: "Required. Name of the rack",
                    example: "Rack_ABC",
                    isUnique: true,
                    isRequired: true
                  },
                  {
                    path: "description",
                    description: "Description of the rack",
                    example: "This rack is used for storing tubes"
                  },
                  {
                    path: "format",
                    description: "Required. Plate format: 6 Well, 24 Well, etc",
                    isRequired: true,
                    example: "6 Well"
                  },
                  {
                    path: "catalog number",
                    description: "Catalog number of the rack",
                    example: "12345"
                  },
                  {
                    path: "manufacturer",
                    description: "Manufacturer of the rack",
                    example: "Company XYZ"
                  },
                  {
                    path: "tube types",
                    isRequired: true,
                    description:
                      "Required. A list of comma-separated tube names that can fit in this rack. Must match pre-existing tube types.",
                    example: "Tube A, Tube B, Tube C"
                  }
                ]
              }
            })}
            name="rackTypesFile"
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Upload Rack Types"
  }),
  reduxForm({
    form: "UploadRackTypes"
  })
)(UploadRackTypes);
