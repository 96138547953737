/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import actions from "../../../actions";
import { getDesignState } from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import basicHandleActionsWithFullState from "../../basicHandleActionsWithFullState";
import { transformWithNewIds } from "../../../../../../tg-iso-design/utils/designStateUtils";

const initialState = {
  undoStack: [],
  redoStack: []
};

export default basicHandleActionsWithFullState(
  {
    [actions.designIo.setDesign]: state => {
      return {
        ...state,
        undoStack: [],
        redoStack: []
      };
    },
    [actions.ui.designEditor.undo.addToUndoStack]: (
      state,
      actions,
      fullState
    ) => {
      // If the action was dispatched with a payload.
      // Use it as the designState to stack on top the undoStack instead of the current full state.
      const payloadState = actions.payload;
      const undoStack = [
        ...state.undoStack,
        payloadState ? payloadState : getDesignState(fullState)
      ];
      return {
        ...state,
        undoStack,
        redoStack: []
      };
    },
    [actions.ui.designEditor.undo.popUndoStack]: (state, action, fullState) => {
      return {
        ...state,
        undoStack: state.undoStack.slice(0, -1),
        redoStack: [...state.redoStack, getDesignState(fullState)]
      };
    },
    [actions.ui.designEditor.undo.popRedoStack]: (state, action, fullState) => {
      return {
        ...state,
        undoStack: [...state.undoStack, getDesignState(fullState)],
        redoStack: state.redoStack.slice(0, -1)
      };
    },
    [actions.designIo.saveCompleted]: (
      state,
      { payload: { oldIdToNewIdMap } }
    ) => {
      return {
        ...state,
        undoStack: state.undoStack.map(item =>
          transformWithNewIds(item, oldIdToNewIdMap)
        ),
        redoStack: state.redoStack.map(item =>
          transformWithNewIds(item, oldIdToNewIdMap)
        )
      };
    }
  },
  initialState
);
