/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { get, forEach, keyBy, isEmpty } from "lodash";

import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import UploadPoolingSchema, {
  dataTableFragment
} from "./Steps/UploadPoolingSchema";
import DestinationContainerConfiguration from "./Steps/DestinationContainerConfiguration";
import ReviewWorklist from "./Steps/ReviewWorklist";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";

import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";

class Pooling extends Component {
  onSubmit = async values => {
    const {
      destinationContainersToCreate,
      worklist,
      worklistName,
      samplesToCreate,
      generateBarcodes,
      proteinPatternHashToSampleIds,
      proteinPatternsToCreate: _proteinPatternsToCreate,
      destinationTubesToCreate
    } = values;

    try {
      const cleanedWorklist = {
        name: worklistName,
        worklistTransfers: worklist.worklistTransfers.map(t => {
          const cleanedTransfer = { ...t };
          delete cleanedTransfer.source;
          delete cleanedTransfer.sourceBarcode;
          delete cleanedTransfer.sourcePosition;
          delete cleanedTransfer.sourceAliquotContainer;
          delete cleanedTransfer.destinationAliquotContainer;
          delete cleanedTransfer.destinationPlateName;
          delete cleanedTransfer.destinationPosition;
          return cleanedTransfer;
        })
      };
      // these need sampleAliquotId
      await safeUpsert("sample", samplesToCreate);

      const existingProteinPatterns = await safeQuery(
        ["proteinPattern", "id hash"],
        {
          variables: {
            filter: {
              hash: Object.keys(proteinPatternHashToSampleIds)
            }
          }
        }
      );
      const proteinPatternHashToId = {};
      const keyedExistingProteinPatterns = keyBy(
        existingProteinPatterns,
        "hash"
      );
      const proteinPatternsToCreate = _proteinPatternsToCreate.filter(p => {
        const existing = keyedExistingProteinPatterns[p.hash];
        if (existing) {
          proteinPatternHashToId[p.hash] = existing.id;
        } else {
          proteinPatternHashToId[p.hash] = `&${p.cid}`;
        }
        return !existing;
      });
      await safeUpsert("proteinPattern", proteinPatternsToCreate);
      const sampleProteinPatternsToCreate = [];
      forEach(proteinPatternHashToSampleIds, (sampleIds, hash) => {
        const proteinPatternId = proteinPatternHashToId[hash];
        sampleIds.forEach(sampleId => {
          sampleProteinPatternsToCreate.push({
            proteinPatternId,
            sampleId
          });
        });
      });
      await safeUpsert("sampleProteinPattern", sampleProteinPatternsToCreate);
      const createdPlates = await safeUpsert(
        [
          "containerArray",
          "id aliquotContainers { id aliquot { id sampleId } }"
        ],
        destinationContainersToCreate
      );

      const createdTubes = await safeUpsert(
        "aliquotContainer",
        destinationTubesToCreate
      );

      const samplesToUpdate = [];

      createdPlates.forEach(plate =>
        plate.aliquotContainers.forEach(
          ac =>
            ac.aliquot &&
            samplesToUpdate.push({
              id: ac.aliquot.sampleId,
              sampleAliquotId: ac.aliquot.id
            })
        )
      );
      createdTubes.forEach(
        ac =>
          ac.aliquot &&
          samplesToUpdate.push({
            id: ac.aliquot.sampleId,
            sampleAliquotId: ac.aliquot.id
          })
      );

      await safeUpsert("sample", samplesToUpdate);

      const [createdWorklist] = await safeUpsert("worklist", cleanedWorklist);
      if (generateBarcodes) {
        await addBarcodesToRecords(createdPlates);
        await addBarcodesToRecords(createdTubes);
      }
      return {
        worklist: createdWorklist,
        containerArrays: createdPlates,
        aliquotContainers: createdTubes
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating worklist");
    }
  };

  validate = values => {
    const errors = {};

    const {
      poolingData: _poolingData,
      universalTransferVolume,
      universalTransferVolumetricUnitCode,
      applyUniversalTransfer
    } = values;
    const poolingData = _poolingData || [];
    if (applyUniversalTransfer) {
      if (
        Number(universalTransferVolume) <= 0 ||
        isNaN(Number(universalTransferVolume))
      ) {
        errors.universalTransferVolume = "Please enter a positive value.";
      } else {
        const universalTransferVolumeStandardized = standardizeVolume(
          universalTransferVolume,
          universalTransferVolumetricUnitCode
        );
        const tubesWithErrors = [];
        const platesWithErrors = {};
        poolingData.forEach(record => {
          const aliquot = get(record, "aliquotContainer.aliquot");
          const canTransfer =
            aliquot &&
            standardizeVolume(aliquot.volume, aliquot.volumetricUnitCode) >
              universalTransferVolumeStandardized;
          if (!canTransfer) {
            if (record.isTube) {
              tubesWithErrors.push(record.barcode);
            } else {
              if (!platesWithErrors[record.barcode]) {
                platesWithErrors[record.barcode] = [];
              }
              platesWithErrors[record.barcode].push(record.position);
            }
          }
        });
        let error = "";
        if (tubesWithErrors.length) {
          error += `These tubes do not have enough volume for transfers: ${tubesWithErrors.join(
            ", "
          )}.`;
        }
        if (!isEmpty(platesWithErrors)) {
          if (error)
            error +=
              "\n\nThese plate wells do not have enough volume for transfers:";
          forEach(platesWithErrors, (positions, barcode) => {
            error += `\n\u25CF ${barcode} at ${positions.join(", ")}`;
          });
          error += ".";
        }
        if (error) {
          errors.universalTransferVolume = error;
        }
      }
    } else {
      const missingVolInSchema = poolingData.some(r => !r.volume);
      if (missingVolInSchema) {
        errors.applyUniversalTransfer =
          "Must apply universal transfer because a row in the selected pooling schema does not specify a volume.";
      }
    }

    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Upload Pooling Schema",
        Component: UploadPoolingSchema,
        withCustomFooter: true
      },
      {
        title: "Destination Container Configuration",
        Component: DestinationContainerConfiguration,
        withCustomFooter: true
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(dataTableFragment, { singular: true })
)(Pooling);
