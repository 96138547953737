/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import {
  Button,
  Intent,
  Callout,
  Tooltip,
  AnchorButton
} from "@blueprintjs/core";
import { DataTable, Loading } from "@teselagen/ui";
import { get } from "lodash";
import { compose } from "recompose";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import { generateLocationList, generateSourcePlates } from "../../../utils";
import worklistRecordFragment from "../../../graphql/fragments/worklistRecordFragment";
import "./style.css";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";

import { showDialog } from "../../../../src-shared/GlobalDialog";

import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import { stringify } from "qs";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { worklistRecordTubeTransferTableSchema } from "./worklistRecordTableSchema";
import UpdateWorklistDialog from "./UpdateWorklistDialog";
import WorklistTransfersTable from "./WorklistTransfersTable";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import queryBuilder from "tg-client-query-builder";

const typeToText = {
  DEFAULT: "Standard Worklist",
  TUBE_TRANSFER: "Tube Transfer Worklist",
  FEEDBACK: "Feedback Worklist"
};

class WorklistRecordView extends Component {
  state = {
    generatingLocationList: false,
    generatingSourcePlates: false,
    checkingError: false,
    error: null
  };

  async componentDidMount() {
    this.setState({
      checkingError: true
    });
    try {
      const { worklist } = this.props;
      const qb = new queryBuilder("worklistTransfer");
      qb.whereAll({
        sourceAliquotContainerId: qb.isNull(),
        worklistId: worklist.id
      }).orWhereAll({
        destinationAliquotContainerId: qb.isNull(),
        worklistId: worklist.id
      });
      const [badTransfer] = await safeQuery(["worklistTransfer", "id"], {
        variables: {
          pageSize: 1,
          filter: qb.toJSON()
        }
      });
      if (badTransfer) {
        this.setState({
          error:
            "This worklist references plate wells that have been deleted from inventory."
        });
      }
    } catch (error) {
      console.error(`error:`, error);
    }
    this.setState({
      checkingError: false
    });
  }

  generateSourcePlatesCsv = async () => {
    const { worklist } = this.props;
    this.setState({
      generatingSourcePlates: true
    });
    await generateSourcePlates(worklist);
    this.setState({
      generatingSourcePlates: false
    });
  };

  generateLocationList = async () => {
    const { worklist } = this.props;
    this.setState({
      generatingLocationList: true
    });
    await generateLocationList(worklist.id);
    this.setState({
      generatingLocationList: false
    });
  };

  renderContent = () => {
    const { checkingError, error } = this.state;
    const { worklist } = this.props;
    if (!worklist) return null;
    if (checkingError) return <Loading inDialog />;
    if (error) {
      return <Callout intent="danger">{error}</Callout>;
    }
    return (
      <React.Fragment>
        <RecordInfoDisplay
          recordInfo={[
            ["Status", get(worklist, "worklistStatus.name")],
            ["Type", typeToText[worklist.worklistTypeCode]],
            worklist.worklistReactionMaps?.length > 0 && [
              "Reaction Maps",
              worklist.worklistReactionMaps.map((wrm, i) => {
                return (
                  <React.Fragment key={wrm.id}>
                    <Link to={modelNameToLink(wrm.reactionMap)}>
                      {wrm.reactionMap.name}
                    </Link>
                    {i !== worklist.worklistReactionMaps.length - 1 && ", "}
                  </React.Fragment>
                );
              })
            ]
          ]}
          record={worklist}
        />
        {worklist.tubeTransfers.length ? (
          <DataTable
            schema={worklistRecordTubeTransferTableSchema}
            maxHeight={600}
            entities={worklist.tubeTransfers}
            formName="worklistTubeTransfersRecordTable"
            withDisplayOptions
            isSimple
            compact
            // tgreen: because we are letting users choose column first or row first when generating
            // worklists i am removing this sort from hear
            // defaults={{
            //   order: ["destination", "destinationPosition"]
            // }}
            withPaging
          />
        ) : (
          <WorklistTransfersTable worklistId={worklist.id} />
        )}
      </React.Fragment>
    );
  };

  showExportDialog = () => {
    const { worklist = {} } = this.props;
    showDialog({
      modalType: "EXPORT_WORKLIST",
      modalProps: {
        worklistIds: [worklist.id]
      }
    });
  };

  render() {
    const {
      generatingLocationList,
      generatingSourcePlates,
      error
    } = this.state;
    const { worklistLoading, worklist, history, readOnly } = this.props;

    if (worklistLoading) {
      return <Loading />;
    }

    const worklistRecordViewAdditionalButtons = [
      <Button
        key="pull-list"
        loading={generatingLocationList}
        text="Generate Location List"
        onClick={this.generateLocationList}
      />,
      <Button
        key="export"
        text="Export Worklist"
        onClick={this.showExportDialog}
        disabled={error}
        small
        minimal
        intent={Intent.PRIMARY}
      />,
      <Tooltip
        key="execute"
        disabled={worklist.worklistStatus.code === "PENDING"}
        content="Worklist already executed"
      >
        <AnchorButton
          text="Execute Worklist"
          disabled={readOnly || worklist.worklistStatus.code !== "PENDING"}
          minimal
          intent="success"
          onClick={() => {
            const params = {
              worklistIds: [worklist.id]
            };

            history.push({
              pathname: "/tools/execute-worklist",
              hash: stringify(params)
            });
          }}
        />
      </Tooltip>
    ];
    if (worklist.worklistStatus.code === "EXECUTED") {
      worklistRecordViewAdditionalButtons.push(
        <Button
          key="source-plates"
          loading={generatingSourcePlates}
          text="Generate Source Plates"
          onClick={this.generateSourcePlatesCsv}
        />
      );
    }
    return (
      <AbstractRecord
        {...this.props}
        updateShowFunction={() => {
          showDialog({
            ModalComponent: UpdateWorklistDialog,
            modalProps: {
              initialValues: worklist
            }
          });
        }}
        additionalButtons={worklistRecordViewAdditionalButtons}
        recordName="worklist"
      >
        {this.renderContent()}
      </AbstractRecord>
    );
  }
}

export default compose(recordViewEnhancer(worklistRecordFragment))(
  WorklistRecordView
);
