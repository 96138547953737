/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { isoContext } from "@teselagen/utils";
import { identity, keyBy } from "lodash";

export default async function getSequenceMaterialsByHash(
  sequenceHashes,
  ctx = isoContext
) {
  const { safeQuery } = ctx;

  const materials = await safeQuery(
    ["material", "id polynucleotideMaterialSequence { id hash }"],
    {
      variables: {
        filter: {
          "polynucleotideMaterialSequence.hash": sequenceHashes.filter(identity)
        }
      }
    }
  );
  return keyBy(materials, "polynucleotideMaterialSequence.hash");
}
