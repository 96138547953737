/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { withProps } from "recompose";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { SelectPlates, SelectMicrobes, ReviewReactionMap } from "./Steps";
import { microbialReactionPlanningPlateFragment } from "./fragments";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { get } from "lodash";
import { filterDuplicateMicrobialMaterials } from "../../../utils";

class MicrobialReactionPlanning extends Component {
  onSubmit = async values => {
    const {
      outputMaterials = [],
      reactions = [],
      reactionMapName,
      reactionType
    } = values;
    try {
      const materialsToUpsert = await filterDuplicateMicrobialMaterials(
        outputMaterials,
        reactions
      );
      if (materialsToUpsert.length > 0) {
        await safeUpsert("material", materialsToUpsert);
      }
      const [createdReactionMap] = await safeUpsert("reactionMap", {
        name: reactionMapName,
        reactionTypeCode: reactionType,
        reactions: reactions.map(r => {
          const cleaned = { ...r };
          delete cleaned.inputs;
          delete cleaned.outputs;
          return cleaned;
        })
      });
      return {
        reactionMap: createdReactionMap
      };
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating reactions");
    }
  };

  validate = values => {
    const {
      containerArrays = [],
      microbialPlates = [],
      destinationPlateMap = {},
      inputType
    } = values;
    const errors = {};

    const uniformFormats =
      containerArrays.length &&
      containerArrays.every(
        containerArray =>
          containerArray.containerArrayType.containerFormatCode ===
          containerArrays[0].containerArrayType.containerFormatCode
      );
    if (!uniformFormats) {
      errors.containerArrays = "Selected plates must share the same format.";
    }

    const sourcePlateIds = containerArrays.map(c => c.id);
    const duplicatePlates = microbialPlates.some(microbialPlate =>
      sourcePlateIds.includes(microbialPlate.id)
    );
    if (duplicatePlates) {
      errors.microbialPlates =
        "Cannot select a plate already selected in the previous step.";
    }
    if (microbialPlates.length !== containerArrays.length) {
      errors.microbialPlates = `Must select ${containerArrays.length} plates to match source plates.`;
    } else {
      const seenList = [];
      const mappedOneToOne = microbialPlates.every(p => {
        const mapId = destinationPlateMap["id" + p.id];
        const good =
          mapId && sourcePlateIds.includes(mapId) && !seenList.includes(mapId);
        seenList.push(mapId);
        return good;
      });
      if (!mappedOneToOne) {
        errors.microbialPlates = `Please map every microbial plate to a unique source plate.`;
      }
    }

    if (inputType === "MICROBIAL_MATERIAL") {
      if (values.microbialMaterial && !values.microbialMaterial.strain) {
        errors.microbialMaterial =
          "Please select a microbial material that is linked to a strain.";
      }
    }

    if (inputType === "CSV") {
      const barcodeList = [];
      const dupSourceBarcodes =
        containerArrays &&
        containerArrays.some(c => {
          const barcode = get(c, "barcode.barcodeString");
          if (barcodeList.includes(barcode)) {
            return true;
          } else {
            barcodeList.push(barcode);
            return false;
          }
        });
      const missingBarcodes =
        containerArrays &&
        containerArrays.some(c => {
          return !get(c, "barcode.barcodeString");
        });
      if (dupSourceBarcodes) {
        errors.microbialMaterialCSV = "Source plates have duplicate barcodes";
      }
      if (missingBarcodes) {
        errors.microbialMaterialCSV =
          "Not all source plates have a barcode. Barcodes are necessary for CSV mapping.";
      }
    }
    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        withCustomFooter: true
      },
      {
        title: "Select Microbial Materials",
        Component: SelectMicrobes,
        withCustomFooter: true
      },
      {
        title: "Review Reaction Map",
        Component: ReviewReactionMap
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(microbialReactionPlanningPlateFragment),
  withProps(props => {
    const { containerArrays = [], aliquotContainers = [] } = props;
    let inputType;
    if (aliquotContainers.length || containerArrays.length) {
      inputType =
        aliquotContainers.length && !containerArrays.length ? "TUBE" : "PLATE";
    }
    return {
      initialValues: {
        containerArrays,
        aliquotContainers,
        inputType
      }
    };
  })
)(MicrobialReactionPlanning);
