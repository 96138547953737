/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { groupBy, has, isObject, keyBy } from "lodash";

//this is a custom merge where any .__remove flags will delete the
export default function mergeUpdateRecord(
  { updateRecord, originalRecord },
  isNested = false
) {
  if (originalRecord.__typename === "sequence") {
    const hasPartOrFeatureMatch = (item, potentialMatches, matchKeys) => {
      if (!potentialMatches) return false;
      return potentialMatches.some(potentialMatch => {
        return matchKeys.every(key => {
          if (item[key] === undefined) return true;
          return item[key] === potentialMatch[key];
        });
      });
    };
    // if we are updating a sequence we do not want to duplicate features and parts
    if (updateRecord.features) {
      // remove duplicates
      const existingFeatures = groupBy(originalRecord.features, "name");
      updateRecord.features = updateRecord.features.filter(feat => {
        return !hasPartOrFeatureMatch(feat, existingFeatures[feat.name], [
          "start",
          "end",
          "type"
        ]);
      });
    }
    if (updateRecord.parts) {
      // remove duplicates
      const existingParts = groupBy(originalRecord.parts, "name");
      updateRecord.parts = updateRecord.parts.filter(part => {
        return !hasPartOrFeatureMatch(part, existingParts[part.name], [
          "start",
          "end"
        ]);
      });
    }
  }

  const outputObj = {};
  const tracker = {
    ...updateRecord
  };

  Object.keys(originalRecord).forEach(key => {
    delete tracker[key];
    const originalVal = originalRecord[key];
    const updateVal = updateRecord[key];
    if (key === "extendedProperties" || key === "tags") {
      return;
    }

    if (!has(updateRecord, key)) {
      // If this is a nested record, we should not pass IDs from the original record to the update one.
      // Because nested records without a specified ID can be intended for creation, otherwise they will be treated as "updates".
      if (
        [
          "id"
          // "cid"
        ].includes(key) &&
        isNested
      ) {
        return;
      }
      outputObj[key] = originalVal; //just use the original obj's value
      return;
    } else {
      if (Array.isArray(updateVal)) {
        if (!Array.isArray(originalVal)) {
          throw new Error(
            `We've got a problem.. originalVal is not an array while updateVal is for ${key}`
          );
        }
        const originalValById = keyBy(originalVal || [], "id");
        const toAdd = [];
        updateVal.forEach(arrayVal => {
          //__remove means delete it!
          if (arrayVal.__remove) {
            if (!arrayVal.id)
              throw new Error(`This needs to have an id! key: ${key}`);
            delete originalValById[arrayVal.id];
          } else if (!arrayVal.id) {
            toAdd.push(arrayVal);
          } else {
            const originalArrayVal = originalValById[arrayVal.id];
            if (!originalArrayVal)
              throw new Error(
                `originalArrayVal needs to exist for id ${arrayVal.id}, key: ${key}`
              );
            toAdd.push(
              mergeUpdateRecord(
                {
                  updateRecord: arrayVal,
                  originalRecord: originalArrayVal
                },
                true
              )
            );
            delete originalValById[arrayVal.id];
          }
        });
        // push on any stragglers
        toAdd.push(...Object.values(originalValById));
        outputObj[key] = toAdd;
      } else if (isObject(originalVal)) {
        //nested object with __typename
        outputObj[key] = mergeUpdateRecord(
          {
            updateRecord: updateVal,
            originalRecord: originalVal
          },
          true
        );
      } else {
        outputObj[key] = updateVal;
      }
    }
  });
  Object.keys(tracker).forEach(key => {
    outputObj[key] = updateRecord[key];
  });
  return outputObj;
}
