/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { sortBy } from "lodash";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import {
  DataTable,
  CollapsibleCard,
  withTableParams,
  CheckboxField
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { stringify } from "qs";
import { MenuItem } from "@blueprintjs/core";
import { workQueueStatusColumn } from "../../Library/WorkQueues";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import ReassignTasksDialog from "../../NewWorkflowRun/WorkflowTaskInfo/ReassignTasksDialog";
import appGlobals from "../../../../src-shared/appGlobals";

function WorkQueueJobs(props) {
  const { tableParams, history, jobId } = props;
  let entities = tableParams.entities;
  if (tableParams.entities) {
    entities = sortBy(tableParams.entities, [
      "workflowTool.workflowRun.id",
      "workflowTool.workflowToolDefinition.index"
    ]);
  }
  return (
    <CollapsibleCard
      key="workQueues"
      title="Work Queue"
      className="job-workqueue-section"
      icon="flows"
      // openTitleElements={
      //   <Button
      //     icon="add"
      //     text="Add new workflow run"
      //     intent={Intent.SUCCESS}
      //     className={Classes.MINIMAL}
      //     onClick={this.addWorkflow}
      //     style={{ marginTop: "-3px" }}
      //   />
      // }
    >
      <DataTable
        {...tableParams}
        entities={entities}
        onDoubleClick={record => {
          if (record.workflowTool && record.workflowTool.workflowRun) {
            const hash = stringify({
              task: record.workflowTool.workflowToolDefinition.index + 1
            });
            history.push({
              pathname: modelNameToLink(record.workflowTool.workflowRun),
              hash
            });
          }
        }}
        contextMenu={({ selectedRecords = [] }) => {
          return [
            <MenuItem
              key="reassign"
              text="Reassign Tasks"
              icon="move"
              onClick={() => {
                showDialog({
                  ModalComponent: ReassignTasksDialog,
                  modalProps: {
                    tasks: selectedRecords.map(r => ({
                      workflowTool: r.workflowTool,
                      jobId
                    })),
                    refetch: tableParams.onRefresh
                  }
                });
              }}
            />
          ];
        }}
        noPadding
        compact
      >
        <CheckboxField
          label="Only show my work queue items"
          defaultValue={true}
          name="filterMyWorkQueueItems"
          className="tg-no-form-group-margin"
          onFieldSubmit={val => {
            tableParams.setNewParams({
              ...tableParams.currentParams,
              showAll: !val
            });
          }}
        />
      </DataTable>
    </CollapsibleCard>
  );
}

const fragment = gql`
  fragment workQueueItemJobRecordViewFragment on workQueueItem {
    id
    reassigned
    updatedAt
    workflowTool {
      id
      workflowToolStatus {
        code
        name
        description
      }
      workflowRun {
        id
        name
      }
      workflowToolDefinition {
        id
        name
        index
      }
    }
    jobUser {
      id
      user {
        id
        username
      }
    }
  }
`;

const schema = {
  model: "workQueueItem",
  fields: [
    {
      displayName: "Task Name",
      path: "workflowTool.workflowToolDefinition.name",
      render: (v, r) =>
        `${r.workflowTool.workflowToolDefinition.index + 1}. ${v}`
    },
    workQueueStatusColumn,
    {
      displayName: "Workflow Run",
      path: "workflowTool.workflowRun.name"
    },
    {
      displayName: "Assignee",
      path: "jobUser.user.username"
    },
    dateModifiedColumn
  ]
};

export default compose(
  withTableParams({
    formName: "workQueueJobs",
    urlConnected: false,
    schema,
    defaults: {
      order: ["-modified"]
    },
    additionalFilter: (props, qb, currentParams) => {
      const filter = {
        "jobUser.jobId": props.jobId,
        reassigned: false
      };
      if (!currentParams.showAll) {
        filter["jobUser.userId"] = appGlobals.currentUser.id;
      }
      qb.whereAll(filter);
    }
  }),
  withQuery(fragment, {
    isPlural: true
  }),
  withRouter
)(WorkQueueJobs);
