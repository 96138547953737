/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isObject, last, times } from "lodash";
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import {
  getOutputtingReactionOfCard,
  getInputCardsOfReaction,
  getBinIdsInCard
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { isErdam } from "../../../utils/addErdamUtils";
import { getOverhangSize } from "../../../../../tg-iso-shared/utils/enzymeUtils";

/**
 * For some assembly methods, we will need to add a phantom junction to the first and
 * last bin even though that junction doesn't actually exist when the output is linear.
 * @param {Object} assemblyMethod
 */
function canHavePhantomJunction(assemblyMethodOrCid) {
  if (isObject(assemblyMethodOrCid)) {
    const name = get(assemblyMethodOrCid, "name");
    return name !== "Contiguous Express Digest";
  } else {
    return assemblyMethodOrCid !== "&digest";
  }
}

/**
 * Get the fields that will be common to all of the junctions.
 * @param {Object} state The full state.
 * @param {string} reactionId
 * @param {bool} isOutputCircular Is this a circular assembly?
 * @param {bool} makeAllInputsCircular Are we prepending a backbone bin to all but the first bin.
 * @param {Object} assemblyMethod
 * @returns {Array<Object>} Array of 2-tuples of bin ids.
 */
function getCommonJunctionFields(
  state,
  reactionId,
  isOutputCircular,
  makeAllInputsCircular,
  assemblyMethod
) {
  const inputs = getInputCardsOfReaction(state, reactionId);

  const junctions = [];

  const needsPhantom =
    !isOutputCircular && canHavePhantomJunction(assemblyMethod);

  const numJunctions =
    isOutputCircular || needsPhantom ? inputs.length : inputs.length - 1;

  for (let i = 0; i < numJunctions; i++) {
    const nextInd = (i + 1) % inputs.length;

    const card5p = inputs[i];
    const card3p = inputs[nextInd];

    // We need this logic to handle the injected backbone bins.
    let threePrimeBinIndex = 0;
    if (makeAllInputsCircular && (!isOutputCircular || nextInd !== 0)) {
      threePrimeBinIndex = 1;
    }

    const fivePrimeCardBinId = last(getBinIdsInCard(state, card5p.id));
    const threePrimeCardBinId = getBinIdsInCard(state, card3p.id)[
      threePrimeBinIndex
    ];

    junctions.push({
      isPhantom: nextInd === 0 && needsPhantom,
      reactionId,
      fivePrimeCardId: card5p.id,
      threePrimeCardId: card3p.id,
      fivePrimeCardEndBinId: fivePrimeCardBinId,
      fivePrimeCardInteriorBinId: fivePrimeCardBinId,
      threePrimeCardStartBinId: threePrimeCardBinId,
      threePrimeCardInteriorBinId: threePrimeCardBinId
    });
  }
  return junctions;
}

/**
 * Given a junction and assembly method, add the fields required by the assembly method to the junction.
 * @param {Pair<string,string>} junction
 * @param {string} reactionId
 * @param {Object} assemblyMethod
 * @param {Object | null} restrictionEnzyme
 */
function addAssemblyMethodSpecificFieldsToJunction(
  junction,
  reactionId,
  assemblyMethodId,
  restrictionEnzyme
) {
  if (isErdam(assemblyMethodId)) {
    const overhangLength = getOverhangSize(restrictionEnzyme);
    Object.assign(junction, {
      bps: times(overhangLength, () => "N").join(""),
      junctionTypeCode: "TYPE_IIS_ENZYME",
      restrictionEnzymeId: restrictionEnzyme.id,
      fivePrimeCardOverhangTypeCode: "INSERT",
      threePrimeCardOverhangTypeCode: "FLANKING_SEQUENCE"
    });
  } else {
    junction.junctionTypeCode = "SCARLESS";
  }
}

export default function addJunctionsToReaction({
  changeSetsHelper,
  restrictionEnzyme,
  cardId,
  isOutputCircular,
  makeAllInputsCircular,
  assemblyMethodId,
  executeOptions
}) {
  const state = changeSetsHelper.execute();
  const fakeFullState = { design: state };
  changeSetsHelper = new ChangeSetsHelper(state);

  const { id: reactionId } = getOutputtingReactionOfCard(fakeFullState, cardId);

  const junctions = getCommonJunctionFields(
    fakeFullState,
    reactionId,
    isOutputCircular,
    makeAllInputsCircular,
    reactionId,
    assemblyMethodId
  );

  for (const junction of junctions) {
    addAssemblyMethodSpecificFieldsToJunction(
      junction,
      reactionId,
      assemblyMethodId,
      restrictionEnzyme
    );
  }

  changeSetsHelper.createPure("junction", junctions);

  return changeSetsHelper.execute(
    executeOptions || {
      removeInaccessibleItems: true,
      recomputeElementValidation: true,
      removeNonsensicalExtraSequences: true,
      recomputeBinValidation: true,
      recomputeDigestFasValidation: true,
      updateInvalidMaterialAvailabilities: true
    }
  );
}
