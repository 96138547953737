/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment buildAliasFragment on alias {
    id
    name
    targetInventoryItem {
      id
      inventoryItemTypeCode
      sequenceId
      aminoAcidSequenceId
      strainId
    }
  }
`;
