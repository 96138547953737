/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const plasmidPurificationSampleFragment = gql`
  fragment plasmidPurificationSampleFragment on sample {
    id
    name
    sampleTypeCode
    material {
      id
      name
      materialTypeCode
      microbialMaterialMicrobialMaterialPlasmids {
        id
        polynucleotideMaterial {
          id
          name
          polynucleotideMaterialSequence {
            id
            name
          }
        }
      }
    }
    sampleFormulations {
      id
      materialCompositions {
        id
        material {
          id
          name
          materialTypeCode
          microbialMaterialMicrobialMaterialPlasmids {
            id
            polynucleotideMaterial {
              id
              name
              polynucleotideMaterialSequence {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const plasmidPurificationPlateMapGroupFragment = gql`
  fragment plasmidPurificationPlateMapGroupFragment on plateMapGroup {
    id
    name
    plateMaps {
      id
      type
      name
      plateMapItems {
        id
        name
        rowPosition
        columnPosition
        volume
        volumetricUnitCode
        inventoryItem {
          id
          materialId
          sampleId
          sample {
            ...plasmidPurificationSampleFragment
          }
          material {
            id
            name
            materialTypeCode
            microbialMaterialMicrobialMaterialPlasmids {
              id
              polynucleotideMaterial {
                id
                name
                polynucleotideMaterialSequence {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
  ${plasmidPurificationSampleFragment}
`;

export const plasmidPurificationTubeFragment = gql`
  fragment plasmidPurificationTubeFragment on aliquotContainer {
    id
    name
    columnPosition
    rowPosition
    barcode {
      id
      barcodeString
    }
    aliquotContainerType {
      code
      name
      isTube
    }
    aliquot {
      id
      isDry
      sample {
        ...plasmidPurificationSampleFragment
      }
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      mass
      massUnitCode
    }
  }
  ${plasmidPurificationSampleFragment}
`;

export const plasmidPurificationAliquotContainerFragment = gql`
  fragment plasmidPurificationAliquotContainerFragment on aliquotContainer {
    id
    name
    columnPosition
    rowPosition
    aliquot {
      id
      isDry
      sample {
        ...plasmidPurificationSampleFragment
      }
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      mass
      massUnitCode
    }
  }
  ${plasmidPurificationSampleFragment}
`;

export const plasmidPurificationPlateFragment = gql`
  fragment plasmidPurificationPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      isPlate
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      ...plasmidPurificationAliquotContainerFragment
    }
  }
  ${plasmidPurificationAliquotContainerFragment}
`;
