/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, InputField } from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { wrapDialog } from "@teselagen/ui";

class CreateSchematicElement extends React.Component {
  onSubmit = async values => {
    try {
      const { hideModal, refetch } = this.props;
      await safeUpsert("schematicElement", values);
      await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error changing element name.");
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Create Element"
  }),
  reduxForm({
    form: "createSchematicElement"
  })
)(CreateSchematicElement);
