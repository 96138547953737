/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
import { keyBy, lowerCase, noop, startCase } from "lodash";
import { addToUniqArray } from "./generalUtils";
import { checkDuplicateSequencesExtended } from "../sequence-import-utils/checkDuplicateSequences";
import { isoContext } from "@teselagen/utils";
import { addTaggedItemsBeforeCreate } from "../tag-utils";
import processSequences from "../sequence-import-utils/processSequences";
import updateJoinsAndRecreateRelated from "./updateJoinsAndRecreateRelated";
import _getNameKeyedItems from "./getNameKeyedItems";
import shortid from "shortid";
import { getMaterialFields } from "../sequence-import-utils/getMaterialFields";

export default async function uploadStrainHelper(
  strains,
  { importCollectionName, tags, isCellLine, doNotCreate } = {},
  ctx = isoContext
) {
  const getNameKeyedItems = ops => _getNameKeyedItems(ops, ctx);
  const readableName = isCellLine ? "Cell Line" : "Strain";
  const { safeQuery, safeUpsert, startImportCollection = noop } = ctx;
  const addedSequenceHashes = [];
  const sequencesToCheck = [];
  const genusNames = [];
  const targetOrganismClassNames = [];
  const selectionMethodNames = [];
  const inductionMethodNames = [];
  const additiveMaterialNames = [];
  const allPlasmidNames = [];
  const genomeNames = [];
  const gasCompositionNames = [];
  strains.forEach(strain => {
    const {
      plasmids = [],
      plasmidNames = [],
      selectionMethods = [],
      inductionMethods = []
    } = strain;
    plasmids.forEach(plasmid => {
      if (!addedSequenceHashes.includes(plasmid.hash)) {
        addedSequenceHashes.push(plasmid.hash);
        sequencesToCheck.push(plasmid);
      }
    });
    addToUniqArray(gasCompositionNames, strain.gasComposition);
    addToUniqArray(gasCompositionNames, strain.growthCondition?.gasComposition);
    addToUniqArray(genomeNames, strain.genomeName);
    addToUniqArray(genusNames, strain.genus);
    strain.organismClass = strain.organismClass || strain.targetOrganismGroup;
    delete strain.targetOrganismGroup;
    addToUniqArray(targetOrganismClassNames, strain.organismClass);
    addToUniqArray(additiveMaterialNames, strain.growthMedium);
    addToUniqArray(additiveMaterialNames, strain.growthCondition?.growthMedium);
    selectionMethods.forEach(sm => {
      addToUniqArray(selectionMethodNames, sm);
    });
    inductionMethods.forEach(im => {
      addToUniqArray(inductionMethodNames, im);
    });
    plasmidNames.forEach(sm => {
      addToUniqArray(allPlasmidNames, sm);
    });
  });

  const { allInputSequencesWithAttachedDuplicates } =
    await checkDuplicateSequencesExtended(sequencesToCheck, {}, ctx);

  const createdSequenceCids = [];
  const sequencesToCreate = [];

  const newMaterials = [];
  const sequenceIdToMaterialId = {};
  const sequenceHashToMaterialId = {};
  const sequenceUpdates = [];

  const makeNewDnaMaterial = sequence => {
    const cid = shortid();
    newMaterials.push({
      cid,
      name: sequence.name,
      ...getMaterialFields(false)
    });
    sequenceUpdates.push({
      id: sequence.id || `&${sequence.cid}`,
      polynucleotideMaterialId: `&${cid}`
    });
    return `&${cid}`;
  };

  allInputSequencesWithAttachedDuplicates.forEach(inputSequence => {
    const sequenceToUse = inputSequence.duplicateFound || inputSequence;
    if (!sequenceToUse.id) {
      if (!createdSequenceCids.includes(sequenceToUse.cid)) {
        sequencesToCreate.push(sequenceToUse);
      }
    }
    if (sequenceToUse.polynucleotideMaterialId) {
      sequenceHashToMaterialId[inputSequence.hash] =
        sequenceToUse.polynucleotideMaterialId;
    }
    if (!sequenceHashToMaterialId[inputSequence.hash]) {
      sequenceHashToMaterialId[inputSequence.hash] =
        makeNewDnaMaterial(sequenceToUse);
    }
  });

  const biosafetyLevels = await safeQuery(["biosafetyLevel", "code name"]);
  const lengthUnits = await safeQuery(["lengthUnit", "code"]);

  const keyedSelectionMethods = await getNameKeyedItems({
    model: "selectionMethod",
    names: selectionMethodNames
  });
  const keyedInductionMethods = await getNameKeyedItems({
    model: "inductionMethod",
    names: inductionMethodNames
  });
  const keyedTargetOrganismClasses = await getNameKeyedItems({
    names: targetOrganismClassNames,
    model: "targetOrganismClass"
  });
  const keyedAdditiveMaterials = await getNameKeyedItems({
    model: "additiveMaterial",
    names: additiveMaterialNames
  });
  const keyedGenus = await getNameKeyedItems({
    model: "genus",
    names: genusNames,
    fragment: ["genus", "id name species { id name }"]
  });
  const keyedGenomes = await getNameKeyedItems({
    model: "genome",
    names: genomeNames
  });
  const keyedPlasmidsByName = await getNameKeyedItems({
    names: allPlasmidNames,
    model: "sequence",
    fragment: ["sequence", "id name polynucleotideMaterialId"]
  });
  const keyedGasCompositions = await getNameKeyedItems({
    names: gasCompositionNames,
    model: "gasComposition"
  });
  const keyedLengthUnits = keyBy(lengthUnits, "code");
  const keyedBiosafetyLevels = keyBy(biosafetyLevels, "name");

  strains.forEach(strain => {
    const throwErrorForStrain = msg => {
      throw {
        type: "uploadStrainHelperError",
        message: `${readableName} ${strain.name} ${msg}`
      };
    };
    function getMatchingRecord(itemKey, allKeyedItems) {
      const name = strain[itemKey] || strain.growthCondition?.[itemKey];
      const item = allKeyedItems[name.toLowerCase()];
      if (!item) {
        throwErrorForStrain(
          `specifies the ${lowerCase(itemKey)} ${name} which does not exist.`
        );
      } else {
        return item;
      }
    }

    if (strain.genus || strain.species) {
      if (strain.genus && !strain.species) {
        throwErrorForStrain(
          `specifies the genus "${strain.genus}" but does not specify a species.`
        );
      }
      if (strain.species && !strain.genus) {
        throwErrorForStrain(
          `specifies the species "${strain.species}" but does not specify a genus.`
        );
      }
      const existingGenus = getMatchingRecord("genus", keyedGenus);
      const specie = existingGenus.species.find(
        s => s.name.toLowerCase() === strain.species.toLowerCase()
      );
      if (!specie) {
        throwErrorForStrain(
          `specifies the species "${strain.species}" which does not exist.`
        );
      }
      strain.specieId = specie.id;
    }
    delete strain.genus;
    delete strain.species;

    if (keyedBiosafetyLevels[strain.biosafetyLevel]) {
      strain.biosafetyLevelCode =
        keyedBiosafetyLevels[strain.biosafetyLevel].code;
    } else if (strain.biosafetyLevel) {
      throwErrorForStrain(
        `specified an invalid biosafety level ${strain.biosafetyLevel}.`
      );
    } else {
      strain.biosafetyLevelCode = "N/A";
    }
    delete strain.biosafetyLevel;

    const missingSelectionMethods = [];
    const strainSelectionMethods = (strain.selectionMethods || []).reduce(
      (acc, smName) => {
        const selectionMethod = keyedSelectionMethods[smName.toLowerCase()];
        if (selectionMethod) {
          acc.push({
            selectionMethodId: selectionMethod.id
          });
        } else {
          missingSelectionMethods.push(smName);
        }
        return acc;
      },
      []
    );
    strain.strainSelectionMethods = strainSelectionMethods;
    delete strain.selectionMethods;
    if (missingSelectionMethods.length) {
      throwErrorForStrain(
        `specified these selection methods which do not exist: ${missingSelectionMethods.join(
          ", "
        )}`
      );
    }
    const missingInductionMethods = [];
    const strainInductionMethods = (strain.inductionMethods || []).reduce(
      (acc, imName) => {
        const inductionMethod = keyedInductionMethods[imName.toLowerCase()];
        if (inductionMethod) {
          acc.push({
            inductionMethodId: inductionMethod.id
          });
        } else {
          missingInductionMethods.push(imName);
        }
        return acc;
      },
      []
    );
    strain.inductionMethodStrains = strainInductionMethods;
    delete strain.inductionMethods;
    if (missingInductionMethods.length) {
      throwErrorForStrain(
        `specified these induction methods which do not exist: ${missingInductionMethods.join(
          ", "
        )}`
      );
    }

    if (strain.organismClass) {
      const targetOrganismClass = getMatchingRecord(
        "organismClass",
        keyedTargetOrganismClasses
      );
      strain.targetOrganismClassId = targetOrganismClass.id;
    }
    delete strain.organismClass;

    if (strain.genomeName) {
      const genome = getMatchingRecord("genomeName", keyedGenomes);
      strain.genomeId = genome.id;
    }
    delete strain.genomeName;

    // handle growth condition values
    if (strain.growthCondition) {
      // already nested
      if (strain.growthCondition.lengthUnit) {
        strain.growthCondition.lengthUnitCode =
          strain.growthCondition.lengthUnit;
      }
    } else {
      strain.growthCondition = {
        name: strain.growthConditionName,
        description: strain.growthConditionDescription,
        shakerSpeed: strain.shakerSpeed,
        shakerThrow: strain.shakerThrow,
        growthMedium: strain.growthMedium,
        gasComposition: strain.gasComposition,
        lengthUnitCode: strain.lengthUnit || null,
        temperature: strain.temperature,
        humidity: strain.humidity
      };
    }
    if (
      strain.growthCondition.lengthUnitCode &&
      !keyedLengthUnits[strain.growthCondition.lengthUnitCode]
    ) {
      throwErrorForStrain(
        `specifies the length unit ${strain.growthCondition.lengthUnitCode} which does not exist.`
      );
    }
    delete strain.growthCondition.lengthUnit;
    delete strain.growthConditionName;
    delete strain.growthConditionDescription;
    delete strain.shakerSpeed;
    delete strain.growthMedium;
    delete strain.gasComposition;
    delete strain.shakerThrow;
    delete strain.lengthUnit;
    delete strain.temperature;
    delete strain.humidity;
    const shouldBeNumbers = [
      "shakerSpeed",
      "shakerThrow",
      "temperature",
      "humidity"
    ];
    shouldBeNumbers.forEach(key => {
      const val = strain.growthCondition[key];
      if (val && isNaN(Number(val))) {
        throwErrorForStrain(
          `specifies a ${startCase(
            key
          )} but did not provide a valid number (${val}).`
        );
      }
    });
    if (strain.growthCondition.growthMedium) {
      const additiveMaterial = getMatchingRecord(
        "growthMedium",
        keyedAdditiveMaterials
      );
      strain.growthCondition.growthMediaId = additiveMaterial.id;
    }
    delete strain.growthCondition.growthMedium;
    if (strain.growthCondition.gasComposition) {
      const gasComposition = getMatchingRecord(
        "gasComposition",
        keyedGasCompositions
      );
      strain.growthCondition.gasCompositionId = gasComposition.id;
    }
    delete strain.growthCondition.gasComposition;

    // handle plasmids
    strain.strainPlasmids = [];
    const plasmidNames = strain.plasmidNames || [];
    const addedPlasmidMaterialIds = [];
    plasmidNames.forEach(plasmidName => {
      const existingPlasmid = keyedPlasmidsByName[plasmidName.toLowerCase()];
      if (!existingPlasmid) {
        throwErrorForStrain(
          `specifies the plasmid name ${plasmidName} but no sequence was found in inventory with that name`
        );
      }
      if (existingPlasmid.polynucleotideMaterialId) {
        sequenceIdToMaterialId[existingPlasmid.id] =
          existingPlasmid.polynucleotideMaterialId;
      }
      if (!sequenceIdToMaterialId[existingPlasmid.id]) {
        sequenceIdToMaterialId[existingPlasmid.id] =
          makeNewDnaMaterial(existingPlasmid);
      }
      if (
        addedPlasmidMaterialIds.includes(
          sequenceIdToMaterialId[existingPlasmid.id]
        )
      ) {
        return;
      }
      addedPlasmidMaterialIds.push(sequenceIdToMaterialId[existingPlasmid.id]);
      strain.strainPlasmids.push({
        polynucleotideMaterialId: sequenceIdToMaterialId[existingPlasmid.id]
      });
    });
    delete strain.plasmidNames;

    const plasmids = strain.plasmids || [];
    plasmids.forEach(plasmid => {
      const materialId = sequenceHashToMaterialId[plasmid.hash];
      if (!materialId) {
        throwErrorForStrain(`had an error parsing the plasmid ${plasmid.name}`);
      }
      if (addedPlasmidMaterialIds.includes(materialId)) return;
      addedPlasmidMaterialIds.push(materialId);
      strain.strainPlasmids.push({
        polynucleotideMaterialId: materialId
      });
    });
    delete strain.plasmids;

    strain.aliases = (strain.aliases || []).map(alias => ({ name: alias }));

    strain.strainTypeCode = isCellLine ? "CELL_LINE" : "MICROBIAL_STRAIN";
  });

  if (importCollectionName) await startImportCollection(importCollectionName);

  const newSequences = await safeUpsert("sequence", sequencesToCreate);
  await safeUpsert("material", newMaterials);
  await safeUpsert("sequence", sequenceUpdates);
  await processSequences(
    newSequences.map(s => s.id),
    ctx
  );

  await updateJoinsAndRecreateRelated(
    {
      records: strains,
      joinModels: [
        {
          jm: "strainPlasmid",
          fk: "strainId"
        },
        {
          jm: "strainSelectionMethod",
          fk: "strainId"
        },
        {
          jm: "inductionMethodStrain",
          fk: "strainId"
        }
      ],
      relatedModels: [
        {
          rm: "growthCondition",
          fk: "growthConditionId",
          filterKey: "strain.id"
        }
      ]
    },
    ctx
  );
  const newStrains = strains.filter(s => !s.id);
  let newDbStrains;
  if (strains.length) {
    const updateStrains = strains.filter(s => s.id);
    if (!doNotCreate) {
      newDbStrains = await safeUpsert(
        ["strain", "id name"],
        addTaggedItemsBeforeCreate(newStrains, tags)
      );
      newStrains.forEach((s, i) => {
        s.id = newDbStrains[i].id;
      });
    }
    await safeUpsert("strain", updateStrains, {
      excludeResults: true
    });
  }
  if (doNotCreate) {
    return newStrains;
  } else {
    return newDbStrains;
  }
}
