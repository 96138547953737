/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { pick } from "lodash";
import { Button, Intent, Classes } from "@blueprintjs/core";
import PlateMapPlate from "../../PlateMapPlate";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

class SelectPlateWellsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: this.props.locations || []
    };
  }

  updateLocations = locations => {
    this.setState({
      locations
    });
  };

  onSelect = () => {
    const { locations } = this.state;
    const { onSelect, containerArray, hideModal } = this.props;
    onSelect(locations, containerArray);
    hideModal();
  };

  clearSelection = () => {
    this.setState({
      locations: []
    });
  };

  render() {
    const { locations } = this.state;
    const { containerArray, hideModal } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <Button
            text="Clear Selection"
            disabled={!locations.length}
            onClick={this.clearSelection}
            intent={Intent.DANGER}
          />
          <div
            className="tg-flex align-center justify-center"
            style={{ padding: 20 }}
          >
            <PlateMapPlate
              {...pick(containerArray, [
                "aliquotContainers",
                "containerArrayType"
              ])}
              withDragSelect
              onWellsSelected={this.updateLocations}
              selectedAliquotContainerLocations={locations}
            />
          </div>
        </div>
        <DialogFooter hideModal={hideModal} onClick={this.onSelect} />
      </React.Fragment>
    );
  }
}

export default wrapDialog({
  title: "Select Plate Wells",
  style: {
    display: "table"
  }
})(SelectPlateWellsDialog);
