/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";
import defaultJ5OutputNamingTemplateMap from "../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";

export default ({ partId, designName, partName, customJ5ParameterId }) => {
  const designCid = shortid();
  const card1Cid = shortid();
  const bbCid = shortid();

  const reactionCid = shortid();

  const refDesign = "&" + designCid;
  return [
    {
      entity: "design",
      inputs: {
        cid: designCid,
        name: designName,
        type: "grand-design",
        layoutType: "combinatorial",
        numRows: 1,
        description: "Simple PCR Design"
      }
    },

    {
      entity: "card",
      inputs: {
        designId: refDesign,
        isRoot: true,
        name: "Target Construct",
        circular: false,
        binCards: [
          {
            designId: refDesign,
            index: 0,
            bin: {
              designId: refDesign,
              cid: bbCid,
              direction: true,
              name: "PCR Product",
              iconId: "&ORIGIN_OF_REPLICATION"
            }
          }
        ],
        outputReaction: {
          designId: refDesign,
          cid: reactionCid,
          assemblyMethodId: "&pcr",
          name: "PCR",
          customJ5ParameterId,
          reactionJ5OutputNamingTemplates: Object.keys(
            defaultJ5OutputNamingTemplateMap
          ).map(outputTarget => ({
            designId: refDesign,
            j5OutputNamingTemplate: {
              designId: refDesign,
              outputTarget,
              ...defaultJ5OutputNamingTemplateMap[outputTarget]
            }
          })),
          cards: [
            {
              designId: refDesign,
              inputIndex: 0,
              circular: false,
              cid: card1Cid,
              name: "PCR Product"
            }
          ]
        }
      }
    },
    {
      entity: "binCard",
      inputs: [
        {
          designId: refDesign,
          index: 0,
          binId: "&" + bbCid,
          cardId: "&" + card1Cid
        }
      ]
    },
    {
      entity: "element",
      inputs: {
        designId: refDesign,
        name: partName,
        index: 0,
        bps: null,
        preferred3PrimeOverhangs: "",
        preferred5PrimeOverhangs: "",
        invalidityMessage: null,
        extraStartSequence: null,
        extraEndSequence: null,
        partId,
        binId: "&" + bbCid
      }
    }
  ];
};
