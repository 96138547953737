/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { times, get } from "lodash";
import { Tooltip } from "@blueprintjs/core";

import { withEditorContext } from "../../DesignEditorContext";

import "./style.css";

class RowIndexCell extends React.PureComponent {
  handleRowClick = (e, index) => {
    e.stopPropagation();
    const { cardId, selectRow } = this.props;
    selectRow({
      cardId,
      index,
      shift: e.shiftKey,
      ctrl: e.ctrlKey || e.metaKey
    });
  };

  handleSelectAllRowsClick = e => {
    e.stopPropagation();
    const { cardId, numberOfRows, selectAllRows } = this.props;
    selectAllRows({
      cardId,
      numberOfRows
    });
  };

  renderRows = () => {
    const {
      numberOfRows,
      cellWidth,
      cellHeight,
      selectedIndexes,
      selectedCard,
      cardId,
      level
    } = this.props;
    return times(numberOfRows, i => {
      return (
        <g
          key={`card-${level}-row-${i}`}
          data-test="row-index"
          onClick={e => this.handleRowClick(e, i)}
        >
          <rect
            className={`row-index-cell-outline ${
              get(selectedIndexes, i, null) && selectedCard === cardId
                ? "row-index-cell-outline-active"
                : ""
            }`}
            {...{
              x: 0,
              y: cellHeight * i,
              width: cellWidth,
              height: cellHeight,
              shapeRendering: "crispEdges"
            }}
          />
          <text
            className="row-index-cell-label"
            {...{
              x: cellWidth / 2,
              y: cellHeight * (i + 1) - cellHeight / 3
            }}
          >
            {i + 1}
          </text>
        </g>
      );
    });
  };

  render() {
    const {
      numberOfRows,
      cellWidth,
      cellHeight,
      isClassicLayout,
      level
    } = this.props;

    let style = {};

    if (isClassicLayout) {
      style = { top: "-45px" };
    } else {
      style = { top: "2px" };
    }

    return (
      <div
        key="row-index-container"
        className="row-index-container"
        style={style}
      >
        <Tooltip content="Select all rows" position="bottom">
          <span className="row-index-header">
            <div style={{ alignSelf: "flex-end" }}>
              <svg
                width={cellWidth}
                height={cellHeight / 2}
                transform={`translate(0,${isClassicLayout ? "-8" : "3"})`}
                className="row-index-top"
              >
                <g
                  key={`card-${level}-row-top`}
                  data-test="row-index-top"
                  onClick={e => this.handleSelectAllRowsClick(e)}
                >
                  <rect
                    className="row-index-top-cell-outline"
                    {...{
                      x: -1,
                      y: -1,
                      width: cellWidth,
                      height: cellHeight / 2,
                      shapeRendering: "crispEdges"
                    }}
                  />
                </g>
              </svg>
            </div>
          </span>
        </Tooltip>
        <div>
          <svg
            width={cellWidth}
            height={numberOfRows * cellHeight}
            key="rows-container"
            className="rows-container"
          >
            {this.renderRows()}
          </svg>
        </div>
      </div>
    );
  }
}

export default withEditorContext(RowIndexCell);
