/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import {
  DataTable,
  withSelectedEntities,
  SelectField,
  InputField,
  withSelectTableRecords,
  DropdownButton
} from "@teselagen/ui";
import { compose } from "redux";
import {
  Button,
  Intent,
  Menu,
  MenuItem,
  Classes,
  Tooltip,
  Icon,
  Colors
} from "@blueprintjs/core";
import { each, intersection, times } from "lodash";
import { connect } from "react-redux";

import { PlateMapPlateNoContext } from "../../../../PlateMapPlate";
import { containerArraySelectedAliquotContainerLocationsSelector } from "../../../../../../src-shared/redux/selectors";
import HeaderWithHelper from "../../../../../../src-shared/HeaderWithHelper";
import DraggableMaterialHandle from "./DraggableMaterialHandle";
import CustomMaterialDragLayer from "./CustomMaterialDragLayer";
import stepFormValues from "../../../../../../src-shared/stepFormValues";
import {
  getLocationHashMapGivenWellCountAndDirection,
  getActiveLocationsForQuadrant
} from "../../../../../utils/plateUtils";
import {
  getSelectedItems,
  generatePlateMapGroup,
  withItemProps,
  canDistributeTemperatureBlocks
} from "../../utils";
import PlateMapView from "../../../../../components/PlateMapView";
import { volumeColumn } from "../../../../../../src-shared/utils/libraryColumns";
import modelNameToReadableName from "../../../../../../src-shared/utils/modelNameToReadableName";
import { tagModels } from "../../../../../../../tg-iso-shared/constants";
import { tagColumnWithRender } from "../../../../../../src-shared/utils/tagColumn";
import { isMac } from "../../../../../../src-shared/utils/generalUtils";
import defaultValueConstants from "../../../../../../../tg-iso-shared/src/defaultValueConstants";
import actions from "../../../../../../src-shared/redux/actions";
import { getAliquotContainerLocation } from "../../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { rowIndexToLetter } from "../../../../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import {
  generateEmptyWells,
  getPositionFromAlphanumericLocation,
  sortAliquotContainers
} from "../../../../../../../tg-iso-lims/src/utils/plateUtils";
import { chunk } from "lodash";
import { reverse } from "lodash";
import { flatten } from "lodash";
import { showDialog } from "../../../../../../src-shared/GlobalDialog";
import ChooseDistributeMaterialsByLengthOptions from "./ChooseDistributeMaterialsByLengthOptions";
import ChooseDistributeByExtendedPropertyOptions from "./ChooseDistributeByExtendedPropertyOptions";
import { safeQuery } from "../../../../../../src-shared/apolloMethods";
import { keyBy } from "lodash";
import ChooseSimpleDistributeOptions from "./ChooseSimpleDistributeOptions";
import {
  blockToAliquotArray,
  getBlockOf2dArray,
  plateTo2dAliquotContainerArray
} from "../../../utils";
import { range } from "lodash";
import { flattenDeep } from "lodash";
import DistributeByAssemblyReportDialog from "./DistributeByAssemblyReportDialog";
import { min } from "lodash";
import ChooseDistributeIntoTemperatureBlocksOptions from "./ChooseDistributeIntoTemperatureBlocksOptions";
import { distributeIntoTemperatureBlocks } from "../../../PcrPlanningAndInventoryCheck/utils";
import gql from "graphql-tag";

const plateMapItemTypeCodeToName = {
  INVENTORY_ITEM: "inventoryItem",
  J5_ITEM: "j5Item"
};

const extPropTypes = ["lot", "material", "additiveMaterial", "sample"];

const getSortedWellsForPlate = (containerFormat, fillDirection) => {
  const aliquotContainers = generateEmptyWells(containerFormat);
  let sortedWells;
  if (fillDirection === "Right" || !fillDirection || fillDirection === "Left") {
    sortedWells = sortAliquotContainers(aliquotContainers, "rowFirst");
    if (fillDirection === "Left") {
      sortedWells = flatten(
        chunk(sortedWells, containerFormat.columnCount).map(reverse)
      );
    }
  } else if (fillDirection === "Down" || fillDirection === "Up") {
    sortedWells = sortAliquotContainers(aliquotContainers, "columnFirst");
    if (fillDirection === "Up") {
      sortedWells = flatten(
        chunk(sortedWells, containerFormat.rowCount).map(reverse)
      );
    }
  }
  return sortedWells;
};

const isPlateObjectFull = (p, containerFormat, quadrantPositionMap) => {
  if (quadrantPositionMap) {
    const quadrantPositions = Object.keys(quadrantPositionMap);
    return quadrantPositions.every(pos => p[pos]);
  } else {
    return (
      Object.keys(p).length >=
      containerFormat.rowCount * containerFormat.columnCount
    );
  }
};

class PlateMapConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.plateMapSelectedItems = [];
    this.itemsWithWellPositions = [];

    const {
      itemType,
      plateMapType,
      plateMaps,
      stepFormProps: { change },
      uploadingVolumeInfo,
      fixedQuadrants
    } = this.props;
    if (itemType === "plateMap" && !uploadingVolumeInfo && !fixedQuadrants) {
      const newPlates = [];
      plateMaps.forEach((plateMap, i) => {
        newPlates.push({});
        plateMap.plateMapItems.forEach(plateMapItem => {
          const item =
            plateMapItem[
              plateMapItemTypeCodeToName[plateMapItem.plateMapItemTypeCode]
            ][plateMapType];
          newPlates[i][getAliquotContainerLocation(plateMapItem)] = { item };
          if (!this.plateMapSelectedItems.includes(item)) {
            this.plateMapSelectedItems.push(item);
          }
        });
      });
      change("platesToCreate", newPlates);
    }
  }

  async updateSelection(prevProps) {
    const {
      selectedAliquotContainerLocations: oldLocations,
      selectedSourceMaterialsTableSelectedEntities: oldRows
    } = prevProps;
    const {
      selectTableRecords,
      selectedAliquotContainerLocations: selectedLocations,
      selectedSourceMaterialsTableSelectedEntities: selectedRows,
      setSelectedAliquotContainerLocations
    } = this.props;
    if (this.stopNextUpdate) {
      this.stopNextUpdate = false;
      return;
    }

    const cleanUpdate = fn => {
      // this prevents the selection from getting hit multiple times from a single action
      // because selected a row selects a location which will trigger selection of a row
      // we do not need to do that twice
      this.stopNextUpdate = true;
      fn();
    };

    if (
      selectedLocations.length !== oldLocations.length ||
      selectedLocations.some(loc => !oldLocations.includes(loc))
    ) {
      // wait for dropped materials to have new location in the table
      await new Promise(resolve => setTimeout(resolve, 50));
      const toSelect = this.itemsWithWellPositions.filter(
        item => intersection(item.wellPositions, selectedLocations).length > 0
      );

      if (toSelect.length) {
        cleanUpdate(() => selectTableRecords(toSelect));
      }
    } else if (
      selectedRows.length !== oldRows.length ||
      selectedRows.some(row => !oldRows.includes(row))
    ) {
      const toSelect = flattenDeep(
        selectedRows
          .filter(item => item.wellPositions?.length > 0)
          .map(item => item.wellPositions)
      );

      if (
        toSelect.length &&
        toSelect.some(row => !selectedLocations.includes(row))
      ) {
        cleanUpdate(() =>
          setSelectedAliquotContainerLocations({ locations: toSelect })
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.updateSelection(prevProps);
  }

  handleSourceMaterialDrop = ({ selectedSourceMaterials, droppedLocation }) => {
    const {
      selectedContainerFormat,
      fillDirection: _fillDirection = "right",
      platesToCreate = [],
      setSelectedAliquotContainerLocations,
      currentPlateMapIndex,
      quadrant,
      breakIntoQuadrants,
      breakdownPattern,
      stepFormProps: { change },
      selectedAliquotContainerLocations = []
    } = this.props;

    const newPlates = [...platesToCreate];

    const fillDirection = _fillDirection.toLowerCase();
    if (
      selectedAliquotContainerLocations.length >= 2 &&
      selectedAliquotContainerLocations.includes(droppedLocation)
    ) {
      if (
        selectedSourceMaterials.length >
        selectedAliquotContainerLocations.length
      ) {
        return window.toastr.warning(
          `Cannot fit ${
            selectedSourceMaterials.length
          } ${this.readableItemType({ plural: true })} into ${
            selectedAliquotContainerLocations.length
          } locations.`
        );
      }
      const dstWellsMap = getLocationHashMapGivenWellCountAndDirection({
        containerFormat: selectedContainerFormat,
        numWells: selectedContainerFormat.quadrantSize,
        startingPosition: droppedLocation,
        direction: fillDirection
      });
      const dstWells = Object.keys(dstWellsMap);
      const sortedSelection = [...selectedAliquotContainerLocations].sort(
        (a, b) => dstWells.indexOf(a) - dstWells.indexOf(b)
      );
      const newPlate = { ...this.getPlateOnInput(currentPlateMapIndex) };
      sortedSelection.forEach((loc, i) => {
        const material =
          selectedSourceMaterials[i % selectedSourceMaterials.length];
        if (material) {
          newPlate[loc] = { ...newPlate[loc], item: material };
        }
      });
      newPlates[currentPlateMapIndex] = newPlate;
    } else {
      const dstWellsMaps = getLocationHashMapGivenWellCountAndDirection({
        containerFormat: selectedContainerFormat,
        numWells: selectedSourceMaterials.length,
        startingPosition: droppedLocation,
        direction: breakIntoQuadrants ? "right" : fillDirection, // left, right, up, down
        multiplate: true,
        quadrant: breakIntoQuadrants ? quadrant : undefined,
        breakdownPattern: breakIntoQuadrants ? breakdownPattern : undefined
      });
      let capacity = selectedContainerFormat.quadrantSize;
      if (breakIntoQuadrants) {
        capacity = capacity / 4;
      }

      dstWellsMaps.forEach((dstWellsMap, i) => {
        const materialsOnPlates = selectedSourceMaterials.slice(
          i * capacity,
          (i + 1) * capacity
        );

        // Although the keys returned technically have no order guarantee, they
        // tend to be the same order as they were put in.
        const dstWells = Object.keys(dstWellsMap);

        const newPlate = { ...this.getPlateOnInput(currentPlateMapIndex + i) };

        dstWells.forEach((loc, j) => {
          newPlate[loc] = { ...newPlate[loc], item: materialsOnPlates[j] };
        });

        newPlates[currentPlateMapIndex + i] = newPlate;
      });

      change(
        "currentPlateMapIndex",
        currentPlateMapIndex + dstWellsMaps.length - 1
      );

      setSelectedAliquotContainerLocations({
        locations: Object.keys(dstWellsMaps[dstWellsMaps.length - 1])
      });
    }
    change("platesToCreate", newPlates);
  };

  getPlateOnInput(index) {
    return (this.props.platesToCreate || [])[index] || {};
  }

  getCurrentPlate() {
    const { currentPlateMapIndex } = this.props;
    return this.getPlateOnInput(currentPlateMapIndex);
  }

  async getTableItemsInSortedOrder(items) {
    const { selectTableRecords } = this.props;

    selectTableRecords(items);

    // wait for records to be selected
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 50);
    });

    // these will now be sorted by table sort filters
    const {
      selectedSourceMaterialsTableSelectedEntities: selectedSourceMaterials
    } = this.props;
    return selectedSourceMaterials;
  }

  distributeAllItems = async (vals = {}) => {
    const { fillDirection, distributionPattern } = vals;
    const {
      selectedContainerFormat,
      stepFormProps: { change },
      breakIntoQuadrants,
      breakdownPattern,
      quadrant
    } = this.props;

    const _allItemsInOrder = await this.getTableItemsInSortedOrder(
      this.getItemsToPlace()
    );
    const allItemsInOrder = [..._allItemsInOrder];

    if (distributionPattern) {
      const aliquotContainers = generateEmptyWells(selectedContainerFormat);
      const aliquotContainer2dArray = plateTo2dAliquotContainerArray({
        aliquotContainers: aliquotContainers,
        containerArrayType: {
          containerFormat: selectedContainerFormat
        }
      });
      const newPlatesToCreate = [];
      let plateIndex = 0;
      const distributeItemGroup = () => {
        range(selectedContainerFormat.rowCount / 2).forEach(repRowPos => {
          range(selectedContainerFormat.columnCount / 2).forEach(repColPos => {
            const block = getBlockOf2dArray(
              aliquotContainer2dArray,
              2,
              2,
              repColPos,
              repRowPos,
              true,
              true
            );
            blockToAliquotArray(block, distributionPattern).forEach(ac => {
              newPlatesToCreate[plateIndex] =
                newPlatesToCreate[plateIndex] || {};
              const plate = newPlatesToCreate[plateIndex];
              const location = getAliquotContainerLocation(ac);

              const item = allItemsInOrder.shift();
              if (ac && item) {
                plate[location] = { item };
              }
            });
          });
        });
      };
      while (allItemsInOrder.length > 0) {
        distributeItemGroup();
        plateIndex++;
      }
      change("platesToCreate", newPlatesToCreate);
    } else {
      let sortedWellList;

      let quadrantPositionMap;
      if (breakIntoQuadrants) {
        quadrantPositionMap = getLocationHashMapGivenWellCountAndDirection({
          containerFormat: selectedContainerFormat,
          numWells:
            selectedContainerFormat.rowCount *
            selectedContainerFormat.columnCount,
          startingPosition: "A1",
          direction: "right",
          quadrant,
          breakdownPattern
        });
        sortedWellList = Object.keys(quadrantPositionMap);
      } else {
        sortedWellList = getSortedWellsForPlate(
          selectedContainerFormat,
          fillDirection
        );
      }

      const newPlatesToCreate = [];
      while (allItemsInOrder.length) {
        let unfilledPlate = newPlatesToCreate.find(
          p =>
            !isPlateObjectFull(p, selectedContainerFormat, quadrantPositionMap)
        );
        if (!unfilledPlate) {
          unfilledPlate = {};
          newPlatesToCreate.push(unfilledPlate);
        }
        for (const well of sortedWellList) {
          const location =
            typeof well === "string" ? well : getAliquotContainerLocation(well);
          if (!unfilledPlate[location]) {
            const itemToPlace = allItemsInOrder.shift();
            if (!itemToPlace) break;
            unfilledPlate[location] = { item: itemToPlace };
          }
        }
      }
      change("platesToCreate", newPlatesToCreate);
    }
  };

  handleWellOnWellDrop = ({
    sourceLocations,
    draggedLocation,
    droppedLocation
  }) => {
    const {
      platesToCreate = [],
      selectedContainerFormat: { rowCount, columnCount: colCount },
      setSelectedAliquotContainerLocations,
      currentPlateMapIndex,
      stepFormProps: { change }
    } = this.props;

    const { columnPosition: dragCol, rowPosition: dragRow } =
      getPositionFromAlphanumericLocation(draggedLocation);
    const { columnPosition: dropCol, rowPosition: dropRow } =
      getPositionFromAlphanumericLocation(droppedLocation);

    const deltaCol = dropCol - dragCol;
    const deltaRow = dropRow - dragRow;

    const plate = this.getPlateOnInput(currentPlateMapIndex);
    const newPlate = { ...plate };

    const dstLocations = sourceLocations.map(srcLoc => {
      delete newPlate[srcLoc];

      const { columnPosition: srcCol, rowPosition: srcRow } =
        getPositionFromAlphanumericLocation(srcLoc);

      const dstCol = (srcCol + deltaCol + colCount) % colCount;
      const dstRow = (srcRow + deltaRow + rowCount) % rowCount;

      const dstLoc = rowIndexToLetter(dstRow) + (dstCol + 1);
      return dstLoc;
    });

    const equal = sourceLocations.every(
      (srcLoc, i) => dstLocations[i] === srcLoc
    );
    // if they dropped into the same spot then there are no changes to do
    if (equal) {
      return;
    }
    sourceLocations.forEach((srcLoc, i) => {
      const dstLoc = dstLocations[i];
      if (plate[srcLoc]) newPlate[dstLoc] = plate[srcLoc];
      else delete newPlate[dstLoc];
    });

    setSelectedAliquotContainerLocations({ locations: dstLocations });
    const newPlates = [...platesToCreate];
    newPlates[currentPlateMapIndex] = newPlate;
    change("platesToCreate", newPlates);
  };

  clearAllPlates = () => {
    const {
      platesToCreate = [],
      stepFormProps: { change },
      selectTableRecords,
      setSelectedAliquotContainerLocation
    } = this.props;
    const platesToUpdate = [...platesToCreate].map(() => ({}));
    change("platesToCreate", platesToUpdate);
    selectTableRecords([]);
    setSelectedAliquotContainerLocation([]);
  };

  clearPlate = () => {
    const {
      platesToCreate = [],
      currentPlateMapIndex,
      stepFormProps: { change },
      selectTableRecords,
      setSelectedAliquotContainerLocation
    } = this.props;
    const platesToUpdate = [...platesToCreate];
    platesToUpdate[currentPlateMapIndex] = {};
    change("platesToCreate", platesToUpdate);
    selectTableRecords([]);
    setSelectedAliquotContainerLocation([]);
  };

  getAlreadyFilledWells = () => {
    const { platesToCreate, currentPlateMapIndex } = this.props;
    const alreadyFilledWells = (platesToCreate || [{}])[currentPlateMapIndex];
    return alreadyFilledWells;
  };

  addPlateMapGroup = () => {
    const {
      platesToCreate,
      stepFormProps: { change },
      setSelectedAliquotContainerLocations
    } = this.props;
    const newPlates = [...(platesToCreate || []), {}];
    setSelectedAliquotContainerLocations({
      locations: []
    });
    change("platesToCreate", newPlates);
    change("currentPlateMapIndex", newPlates.length - 1);
  };

  deletePlateMapGroup = () => {
    const {
      platesToCreate,
      currentPlateMapIndex,
      stepFormProps: { change }
    } = this.props;
    const newPlates = [...(platesToCreate || [])].filter(
      (__, i) => i !== currentPlateMapIndex
    );
    change("platesToCreate", newPlates);
    if (currentPlateMapIndex >= newPlates.length) {
      change("currentPlateMapIndex", newPlates.length - 1);
    }
  };

  aliquotContextMenu = () => {
    const {
      platesToCreate = [],
      currentPlateMapIndex,
      stepFormProps: { change }
    } = this.props;
    const plate = this.getCurrentPlate();

    const selectedLocations =
      containerArraySelectedAliquotContainerLocationsSelector(
        window.teGlobalStore.getState()
      );

    const hasAliquotsSelected = selectedLocations.some(l => plate[l]);

    return (
      <Menu>
        <MenuItem
          icon="trash"
          text="Delete"
          disabled={!hasAliquotsSelected}
          onClick={() => {
            const newPlate = { ...plate };
            for (const l of selectedLocations) delete newPlate[l];
            const newPlates = [...platesToCreate];
            newPlates[currentPlateMapIndex] = newPlate;
            change("platesToCreate", newPlates);
          }}
        />
      </Menu>
    );
  };

  anItemHasDNASequence = () => {
    const items = this.getItemsToPlace();
    return items.some(item => item.polynucleotideMaterialSequence);
  };

  anItemHasSequenceSize = () => {
    const items = this.getItemsToPlace();
    return items.some(item => {
      return item.polynucleotideMaterialSequence || item.pcrProductSequence;
    });
  };

  getSchema = () => {
    const {
      itemType,
      j5EntityType,
      j5EntityRadio,
      extendedPropertyUsedForDist,
      keyedExtendedValuesUsedForDist
    } = this.props;
    const basicSchema = [{ path: "name" }];
    if (
      (j5EntityType || itemType === "j5Report") &&
      j5EntityRadio !== "material"
    ) {
      basicSchema.push({
        path: "j5Report.name",
        displayName: "DNA Assembly Report"
      });
    }
    const items = this.getItemsToPlace();
    if (this.anItemHasDNASequence()) {
      basicSchema.push({
        displayName: "Sequence Size",
        path: "polynucleotideMaterialSequence.size"
      });
    }
    const hasBarcodeAndWell =
      items.some(item => item.plateBarcode) && items.some(item => item.well);
    if (hasBarcodeAndWell) {
      basicSchema.push({
        path: "plateBarcode",
        sortFn: ["plateBarcode", "well"],
        displayName: "Current Location",
        render: (_, r) => `${r.plateBarcode || r.plateName} (${r.well})`
      });
    }
    basicSchema.push({
      path: "wellPositions",
      width: 250,
      displayName: "Well Positions for Current Plate",
      render: val => {
        return val ? val.join(", ") : "";
      }
    });
    if (itemType === "j5Report" && j5EntityRadio === "j5PcrReaction") {
      basicSchema.push({
        path: "pcrProductSequence.size",
        displayName: "Amplicon Size",
        type: "number"
      });
    }
    if (extendedPropertyUsedForDist) {
      basicSchema.push({
        displayName: extendedPropertyUsedForDist.name,
        render: (v, r) => {
          return keyedExtendedValuesUsedForDist[r.id]?.value;
        }
      });
    }
    basicSchema.push({
      path: "hasBeenPlaced",
      type: "action",
      width: 40,
      render: (v, r, i, props) => {
        if (props.itemPlacedMap?.[r.id]) {
          return (
            <Tooltip
              content={`${this.readableItemType({
                upperCase: true
              })} has been placed.`}
            >
              <Icon intent="success" icon="tick" />
            </Tooltip>
          );
        }
      }
    });
    return basicSchema;
  };

  get usableItemType() {
    const { plateMapType, j5EntityType, j5EntityRadio, reactionEntityType } =
      this.props;
    let itemType = this.props.itemType;
    if (itemType === "Inventory List") {
      itemType = "sample";
    } else if (itemType === "containerArray") {
      itemType = this.props.plateWellContentType;
    } else if (itemType === "plateMap") {
      itemType = plateMapType;
    } else if (itemType === "j5Report") {
      if (j5EntityRadio === "j5PcrReaction") {
        itemType = "reaction";
      } else {
        itemType = j5EntityType || j5EntityRadio;
      }
    } else if (itemType === "reactionMap") {
      itemType = reactionEntityType.includes("Material")
        ? "material"
        : "additiveMaterial";
    }
    return itemType;
  }
  readableItemType({ upperCase, plural }) {
    return modelNameToReadableName(this.usableItemType, {
      plural,
      upperCase
    });
  }

  getItemsToPlace = () => {
    let items;
    if (this.usableItemType === "plateMap") {
      items = this.plateMapSelectedItems;
    } else {
      items = getSelectedItems(this.props);
    }
    return items;
  };

  makeItemToHasBeenPlacedMap = () => {
    const { platesToCreate = [] } = this.props;
    const itemPlacedMap = {};
    platesToCreate.forEach(plateMap => {
      each(plateMap, ({ item }, well) => {
        if (well.startsWith("__")) return;
        itemPlacedMap[item.id] = true;
      });
    });
    return itemPlacedMap;
  };

  makeItemToWellMapForPlateMap = plateMap => {
    const itemToWellMap = {};

    each(plateMap, ({ item }, well) => {
      if (well.startsWith("__")) return;
      const id = item.id;
      if (!itemToWellMap[id]) itemToWellMap[id] = [];
      itemToWellMap[id].push(well);
    });

    return itemToWellMap;
  };

  renderPlateConfiguration = () => {
    const {
      selectedContainerFormat,
      platesToCreate = [],
      selectedAliquotContainerLocations,
      setSelectedAliquotContainerLocation,
      selectedSourceMaterialsTableSelectedEntities: selectedSourceMaterials,
      currentPlateMapIndex,
      breakIntoQuadrants,
      breakdownPattern = "Z",
      itemType,
      j5Reports,
      quadrant = 0,
      stepFormProps: { change }
    } = this.props;
    let isWellDisabled;
    const aliquotContainers = generateEmptyWells(selectedContainerFormat);

    const currentPlateMap = platesToCreate[currentPlateMapIndex];
    if (breakIntoQuadrants) {
      const activeLocationsArray = getActiveLocationsForQuadrant({
        containerFormat: selectedContainerFormat,
        aliquotContainers,
        breakdownPattern,
        quadrant
      });
      isWellDisabled = (aliquotContainer, location) => {
        return !activeLocationsArray.includes(location);
      };
    }
    const itemToWellMap = this.makeItemToWellMapForPlateMap(currentPlateMap);

    const items = this.getItemsToPlace();
    this.itemsWithWellPositions = items.map(item => {
      return {
        ...item,
        wellPositions: itemToWellMap[item.id] || []
      };
    });
    const activeFilledWells = this.getAlreadyFilledWells();
    const itemName = this.readableItemType({ plural: true });
    const itemPlacedMap = this.makeItemToHasBeenPlacedMap();

    const numberOfItemsPlaced = Object.keys(itemPlacedMap).length;
    const uppercaseItemName = this.readableItemType({
      upperCase: true,
      plural: true
    });

    const itemModel = items[0]?.__typename;
    const menuItems = [
      <MenuItem
        key="distributeByTableOrder"
        onClick={() => {
          if (breakIntoQuadrants) {
            this.distributeAllItems();
          } else {
            showDialog({
              ModalComponent: ChooseSimpleDistributeOptions,
              modalProps: {
                containerFormat: selectedContainerFormat,
                handleDistribute: values => {
                  this.distributeAllItems(values);
                }
              }
            });
          }
        }}
        text="Table Order"
      />,
      this.anItemHasSequenceSize() && (
        <MenuItem
          key="distributeBySequenceLength"
          onClick={() =>
            showDialog({
              ModalComponent: ChooseDistributeMaterialsByLengthOptions,
              modalProps: {
                handleDistribute: ({ ranges, fillDirection }) => {
                  window.localStorage.setItem(
                    "distributeCreatePlateMapRanges",
                    JSON.stringify(ranges)
                  );

                  const sortedWells = getSortedWellsForPlate(
                    selectedContainerFormat,
                    fillDirection
                  );
                  const newPlatesToCreate = [];
                  const items = this.getItemsToPlace();
                  const itemGroups = ranges.map(() => []);
                  const getItemSize = item =>
                    item.polynucleotideMaterialSequence?.size ||
                    item.pcrProductSequence?.size;
                  items.forEach(item => {
                    const size = getItemSize(item);
                    if (size) {
                      const groupIndex = ranges.findIndex(r => {
                        return size >= r.min && size <= r.max;
                      });
                      if (groupIndex > -1) {
                        itemGroups[groupIndex].push(item);
                      }
                    }
                  });
                  if (itemGroups.every(g => !g.length)) {
                    return window.toastr.error("No items fit into ranges.");
                  }
                  itemGroups.forEach(group => {
                    // because there could be too many for a single plate we need to split
                    const groupsOfGroup = chunk(
                      group.sort((a, b) => getItemSize(a) - getItemSize(b)),
                      selectedContainerFormat.rowCount *
                        selectedContainerFormat.columnCount
                    );
                    groupsOfGroup.forEach(group => {
                      const newPlate = {};
                      newPlatesToCreate.push(newPlate);
                      group.forEach((item, i) => {
                        const location = getAliquotContainerLocation(
                          sortedWells[i]
                        );
                        newPlate[location] = { item };
                      });
                    });
                  });
                  change("platesToCreate", newPlatesToCreate);
                }
              }
            })
          }
          text="Sequence Length"
        />
      ),
      itemModel && extPropTypes.includes(itemModel) && (
        <MenuItem
          key="distributeByExtendedProperty"
          onClick={() =>
            showDialog({
              ModalComponent: ChooseDistributeByExtendedPropertyOptions,
              modalProps: {
                model: itemModel,
                handleDistribute: async ({
                  extendedProperty,
                  fillDirection
                }) => {
                  const sortedWells = getSortedWellsForPlate(
                    selectedContainerFormat,
                    fillDirection
                  );
                  const newPlatesToCreate = [];
                  const items = await this.getTableItemsInSortedOrder(
                    this.getItemsToPlace()
                  );
                  const extStringVals = await safeQuery(
                    ["extendedStringValueView", `id value ${itemModel}Id`],
                    {
                      variables: {
                        filter: {
                          extendedPropertyId: extendedProperty.id,
                          [`${itemModel}Id`]: items.map(i => i.id)
                        }
                      }
                    }
                  );

                  const keyedVals = keyBy(extStringVals, `${itemModel}Id`);

                  // keyed by value
                  const itemGroups = {};
                  items.forEach(item => {
                    const val = keyedVals[item.id]?.value;
                    if (val) {
                      itemGroups[val] = itemGroups[val] || [];
                      itemGroups[val].push(item);
                    } else {
                      itemGroups["no val"] = itemGroups["no val"] || [];
                      itemGroups["no val"].push(item);
                    }
                  });
                  Object.keys(itemGroups)
                    .filter(key => key !== "no val")
                    .concat("no val") // no val should be last
                    .forEach(key => {
                      const groupsOfGroup = chunk(
                        itemGroups[key],
                        selectedContainerFormat.rowCount *
                          selectedContainerFormat.columnCount
                      );
                      groupsOfGroup.forEach(group => {
                        const newPlate = {};
                        newPlatesToCreate.push(newPlate);
                        group.forEach((item, i) => {
                          const location = getAliquotContainerLocation(
                            sortedWells[i]
                          );
                          newPlate[location] = { item };
                        });
                      });
                    });
                  change("platesToCreate", newPlatesToCreate);
                  change("extendedPropertyUsedForDist", extendedProperty);
                  change("keyedExtendedValuesUsedForDist", keyedVals);
                }
              }
            })
          }
          text="Extended Property"
        />
      ),
      (itemModel === "sample" || itemModel === "material") && (
        <MenuItem
          key="distributeByAssemblyReport"
          onClick={() =>
            showDialog({
              ModalComponent: DistributeByAssemblyReportDialog,
              modalProps: {
                items,
                selectedJ5Reports: itemType === "j5Report" && j5Reports,
                handleDistribute: async ({
                  fillDirection,
                  reportOrder,
                  itemIdToReports
                }) => {
                  const sortedWells = getSortedWellsForPlate(
                    selectedContainerFormat,
                    fillDirection
                  );
                  const newPlatesToCreate = [];
                  const itemGroups = [];
                  const items = await this.getTableItemsInSortedOrder(
                    this.getItemsToPlace()
                  );
                  const reportToIndex = {};
                  reportOrder.forEach((id, i) => {
                    reportToIndex[id] = i;
                  });
                  items.forEach(item => {
                    const reports = itemIdToReports[item.id];
                    if (reports) {
                      const indexToUse = min(
                        reports.map(r => reportToIndex[r.id])
                      );
                      itemGroups[indexToUse] = itemGroups[indexToUse] || [];
                      itemGroups[indexToUse].push(item);
                    }
                  });
                  itemGroups.forEach(group => {
                    // because there could be too many for a single plate we need to split
                    const groupsOfGroup = chunk(
                      group,
                      selectedContainerFormat.rowCount *
                        selectedContainerFormat.columnCount
                    );
                    groupsOfGroup.forEach(group => {
                      const newPlate = {};
                      newPlatesToCreate.push(newPlate);
                      group.forEach((item, i) => {
                        const location = getAliquotContainerLocation(
                          sortedWells[i]
                        );
                        newPlate[location] = { item };
                      });
                    });
                  });
                  change("platesToCreate", newPlatesToCreate);
                }
              }
            })
          }
          text="Assembly Report"
        />
      )
    ];

    if (canDistributeTemperatureBlocks(this.props)) {
      menuItems.push(
        <MenuItem
          key="distributeIntoTemperatureBlocks"
          onClick={() =>
            showDialog({
              ModalComponent: ChooseDistributeIntoTemperatureBlocksOptions,
              modalProps: {
                containerFormat: selectedContainerFormat,
                handleDistribute: async ({
                  // distributeMethod,
                  temperatureZoneOrientation,
                  zonesPerPlate
                }) => {
                  const { reactionMaps, materials } = this.props;
                  const reactionsWithInfo = await safeQuery(
                    reactionsWithTempInfoFragment,
                    {
                      variables: {
                        filter: {
                          reactionMapId: reactionMaps.map(r => r.id),
                          "reactionOutputs.outputMaterialId": materials.map(
                            m => m.id
                          )
                        }
                      }
                    }
                  );
                  const reactionIdToMaterialId = {};
                  const mockPcrReactions = [];
                  const keyedMaterials = keyBy(materials, "id");
                  reactionsWithInfo.forEach(r => {
                    reactionIdToMaterialId[r.id] =
                      r.reactionOutputs[0].outputMaterialId;
                    if (
                      !r.reactionDetails?.oligoMeanTm ||
                      !r.reactionDetails?.oligoDeltaTm
                    ) {
                      throw new Error(
                        "All reactions must have reaction detail info."
                      );
                    }
                    mockPcrReactions.push({
                      id: r.id,
                      ...r.reactionDetails
                    });
                  });
                  const plateMaps = distributeIntoTemperatureBlocks({
                    selectedPcrReactions: mockPcrReactions,
                    containerFormat: selectedContainerFormat,
                    zonesPerPlate,
                    temperatureZoneOrientation
                  });
                  const platesToCreate = [];
                  let itemFound = false;
                  plateMaps.forEach(pm => {
                    const newPlate = {};
                    pm.plateMapItems.forEach(pmi => {
                      const location = getAliquotContainerLocation(pmi);
                      const item =
                        keyedMaterials[reactionIdToMaterialId[pmi.id]];
                      if (item) {
                        itemFound = true;
                        newPlate[location] = { item };
                      }
                    });
                    newPlate.__tempInfo = {
                      temperatureZoneOrientation: pm.temperatureZoneOrientation,
                      temperatureZones: pm.temperatureZones
                    };
                    platesToCreate.push(newPlate);
                  });
                  if (!itemFound) {
                    throw new Error(
                      "No valid materials found for distribution."
                    );
                  }
                  change("platesToCreate", platesToCreate);
                  change("currentPlateMapIndex", 0);
                }
              }
            })
          }
          text="Distribute Into Temperature Blocks"
        />
      );
    }

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header={`Distribute ${uppercaseItemName}`}
            helper={
              <span>
                Below is a list of {itemName} selected in the previous step.
                Drag the {itemName} onto the plate to the right and organize
                them as you see fit. You can shift click to select multiple{" "}
                {itemName} to drag at once.
                <br />
                <br />
                You can select multiple wells using {isMac ? "cmd" : "ctrl"} /
                shift click and drop {itemName} into them to create a custom
                format.
              </span>
            }
            width="100%"
          />
          <br />
          <div className="tg-flex justify-space-between">
            <div style={{ width: "50%", display: "table" }}>
              <DropdownButton
                intent="primary"
                text="Distribute By"
                menu={<Menu>{menuItems}</Menu>}
              />
              <DataTable
                style={{ maxHeight: 500 }}
                formName="selectedSourceMaterialsTable"
                entities={this.itemsWithWellPositions}
                schema={this.getSchema()}
                withDisplayOptions
                selectedSourceMaterials={selectedSourceMaterials}
                itemPlacedMap={itemPlacedMap}
                cellRenderer={{
                  name: (name, material) => (
                    <DraggableMaterialHandle
                      name={name}
                      selectedSourceMaterials={selectedSourceMaterials}
                      material={material}
                    />
                  )
                }}
              >
                {numberOfItemsPlaced > 0 && (
                  <div
                    style={{
                      color:
                        numberOfItemsPlaced === items.length
                          ? Colors.GREEN3
                          : Colors.GOLD3
                    }}
                  >
                    {`${numberOfItemsPlaced} of ${items.length} ${itemName} have
                    been placed.`}
                  </div>
                )}
              </DataTable>
            </div>
            <div>
              <div
                className="tg-flex align-flex-end justify-flex-end"
                style={{ marginRight: 20, marginBottom: 15 }}
              >
                {breakIntoQuadrants && (
                  <div style={{ marginRight: 10 }}>
                    <SelectField
                      className="tg-no-form-group-margin"
                      name="quadrant"
                      label="Quadrant"
                      defaultValue={0}
                      options={[
                        { label: "One", value: 0 },
                        { label: "Two", value: 1 },
                        { label: "Three", value: 2 },
                        { label: "Four", value: 3 }
                      ]}
                    />
                  </div>
                )}
                <div style={{ display: "flex", marginRight: 5 }}>
                  <Button onClick={this.clearPlate} intent={Intent.WARNING}>
                    Clear Plate
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    onClick={this.clearAllPlates}
                    intent={Intent.DANGER}
                  >
                    Clear All Plates
                  </Button>
                </div>
                <SelectField
                  className="tg-no-form-group-margin tg-no-form-label"
                  name="currentPlateMapIndex"
                  options={times(platesToCreate.length || 1, i => ({
                    label: "Plate Map " + (i + 1),
                    value: i
                  }))}
                  style={{ minWidth: 200 }}
                  defaultValue={0}
                />
                <div
                  className={Classes.BUTTON_GROUP}
                  style={{ marginLeft: 15 }}
                >
                  <Tooltip content="Add plate map">
                    <Button
                      icon="add"
                      intent={Intent.SUCCESS}
                      onClick={this.addPlateMapGroup}
                    />
                  </Tooltip>
                  <Tooltip
                    content="Remove active plate map"
                    disabled={platesToCreate.length <= 1}
                  >
                    <Button
                      icon="trash"
                      intent={Intent.DANGER}
                      disabled={platesToCreate.length <= 1}
                      onClick={this.deletePlateMapGroup}
                    />
                  </Tooltip>
                </div>
              </div>
              <PlateMapPlateNoContext
                isEditable
                {...currentPlateMap?.__tempInfo}
                isWellDisabled={isWellDisabled}
                activeFilledWells={activeFilledWells}
                selectedAliquotContainerLocations={
                  selectedAliquotContainerLocations
                }
                setSelectedAliquotContainerLocation={
                  setSelectedAliquotContainerLocation
                }
                aliquotContainers={aliquotContainers}
                containerFormat={selectedContainerFormat}
                onDrop={this.handleWellOnWellDrop}
                onSourceMaterialDrop={this.handleSourceMaterialDrop}
                aliquotContextMenu={this.aliquotContextMenu}
              />
            </div>
          </div>
        </div>
        <CustomMaterialDragLayer />
      </React.Fragment>
    );
  };

  renderPlatePreview = () => {
    const {
      platesToCreate,
      plateMapGroupName,
      selectedContainerFormat,
      uploadingVolumeInfo,
      itemType,
      plateMapType,
      j5EntityType,
      breakdownPattern
    } = this.props;
    const plateMapGroup = generatePlateMapGroup({
      plateMapGroupName,
      platesToCreate,
      selectedContainerFormat,
      uploadingVolumeInfo,
      addFakeIds: true
    });
    plateMapGroup.containerFormat = selectedContainerFormat;

    let model = itemType;
    if (itemType === "plateMap") {
      model = plateMapType;
    } else if (itemType === "j5Report") {
      model = j5EntityType;
    }

    const schema = [
      { path: "location", sortFn: ["rowPosition", "columnPosition"] },
      {
        displayName: this.readableItemType({ upperCase: true }) + " Name",
        path: "name"
      }
    ];

    if (uploadingVolumeInfo) {
      schema.push(volumeColumn);
    }

    if (tagModels.includes(model)) {
      schema.push({
        ...tagColumnWithRender,
        filterDisabled: true,
        sortDisabled: true
      });
    }
    return (
      <div className="tg-step-form-section column">
        <PlateMapView
          plateMapGroup={plateMapGroup}
          tableSchema={schema}
          withQuadrantFilter
          breakdownPattern={breakdownPattern}
        />
      </div>
    );
  };

  render() {
    const { uploadingVolumeInfo, fixedQuadrants } = this.props;

    return (
      <div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Name Plate Map"
              helper="Enter a name for the output plate map."
            />
            <div style={{ width: "30%" }}>
              <InputField
                isRequired
                placeholder="Enter output plate map name..."
                name="plateMapGroupName"
                generateDefaultValue={{
                  ...defaultValueConstants.PLATE_MAP_GROUP_NAME
                }}
              />
            </div>
          </div>
        </div>
        {uploadingVolumeInfo || fixedQuadrants
          ? this.renderPlatePreview()
          : this.renderPlateConfiguration()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedAliquotContainerLocations:
      containerArraySelectedAliquotContainerLocationsSelector(state)
  };
};
const mapDispatchToProps = {
  setSelectedAliquotContainerLocation:
    actions.ui.records.containerArray.setSelectedAliquotContainerLocation,
  setSelectedAliquotContainerLocations:
    actions.ui.records.containerArray.setSelectedAliquotContainerLocations
};

const reactionsWithTempInfoFragment = gql`
  fragment reactionsWithTempInfoFragment on reaction {
    id
    reactionOutputs {
      id
      outputMaterialId
    }
    reactionDetails
  }
`;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSelectedEntities("selectedSourceMaterialsTable"),
  withSelectTableRecords("selectedSourceMaterialsTable"),
  stepFormValues(
    "uploadingVolumeInfo",
    "breakIntoQuadrants",
    "breakdownPattern",
    "platesToCreate",
    "itemType",
    "j5EntityType",
    "j5EntityRadio",
    "j5PcrReactions",
    "j5Materials",
    "selectedContainerFormat",
    "currentPlateMapIndex",
    "j5Reports",
    "plateMaps",
    "plateMapType",
    "plateWellContentType",
    "plateMapGroupName",
    "quadrant",
    "fixedQuadrants",
    "reactionEntityType",
    "extendedPropertyUsedForDist",
    "keyedExtendedValuesUsedForDist"
  ),
  withItemProps
)(PlateMapConfiguration);
