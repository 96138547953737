/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import React from "react";
import { DataTable } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { startCase } from "lodash";

export const propagateSampleStatusDataTableFragment = gql`
  fragment propagateSampleStatusDataTableFragment on dataTable {
    id
    name
    createdAt
    updatedAt
    dataSetId
    dataTableTypeCode
    dataRows {
      id
      rowValues
    }
    dataTableType {
      code
      name
      rowSchema
    }
  }
`;

function PropagateSampleStatus(props) {
  const { baseLevelSamples, sampleIdToParentList } = props;

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Review Parent Samples"
          helper="Review the parent sample information we collected below."
        />
        <DataTable
          isSimple
          entities={baseLevelSamples}
          formName="reviewParentSampleStatus"
          schema={[
            {
              displayName: "Child Sample",
              path: "name"
            },
            {
              displayName: "Parent Samples",
              render: (_, sample) => {
                return (sampleIdToParentList[sample.id] || [])
                  .map(parent => parent.name)
                  .join(", ");
              }
            },
            {
              displayName: "Sample Status",
              path: "sampleStatusCode",
              render: sampleStatusCode => startCase(sampleStatusCode)
            }
          ]}
        />
      </div>
    </React.Fragment>
  );
}

export default stepFormValues(
  "baseLevelSamples",
  "sampleIdToParentList"
)(PropagateSampleStatus);
