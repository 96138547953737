/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { getAliquotContainerTableSchema } from "../../../../utils/plateUtils";
import PlateMapView from "../../../PlateMapView";

function PlatePreview({ containerArray, ...rest }) {
  return (
    <div style={{ flex: 1, overflow: "hidden" }}>
      <PlateMapView
        {...{
          containerArray,
          title: containerArray.name,
          noPadding: true,
          tableSchema: getAliquotContainerTableSchema(
            containerArray.aliquotContainers
          ),
          noEntityTransform: true,
          ...rest
        }}
      />
    </div>
  );
}

export default PlatePreview;
