/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { SHAPE_WIDTH, SHAPE_HEIGHT } from "../../constants";
import SBOLIcons from "../../../SBOLIcons/SBOLIcons.js";

const commonDefaultProps = {
  text: "",
  textColor: "black",
  stroke: "black",
  strokeWidth: 1,
  fill: "none",
  x: 0,
  y: 0,
  onDoubleClick: () => {}
};

class DividerClass extends React.Component {
  static defaultProps = {
    ...commonDefaultProps
  };
  render() {
    let { fill } = this.props;
    const {
      x,
      y,
      stroke,
      connectDragSource,
      connectDropTarget,
      isOver,
      canDrop,
      isDragging,
      item,
      beingDragged
    } = this.props;
    if (isOver && canDrop && item) {
      fill = item.color;
    }
    return compose(
      connectDropTarget,
      connectDragSource
    )(
      <g
        transform={`translate(${x},${y})`}
        className={
          "schematic-promoter-shape schematic-shape " +
          (isDragging ? "schematic-shape-dragging" : "")
        }
        style={{ cursor: "pointer" }}
      >
        {!beingDragged && !isDragging && (
          <rect
            {...{
              width: SHAPE_WIDTH,
              height: SHAPE_WIDTH * 1.5,
              fill: "none",
              stroke: "none",
              transform: `translate(${-SHAPE_WIDTH / 2},${-SHAPE_HEIGHT})`
            }}
          />
        )}
        <path
          className={
            "schematic-promoter-shape schematic-shape " +
            (isDragging ? "schematic-shape-dragging" : "")
          }
          fill={fill}
          stroke={stroke}
          strokeWidth={2}
          strokeLinejoin="round"
          d={SBOLIcons.DIVIDER.path}
        />
      </g>
    );
  }
}

export default DividerClass;
