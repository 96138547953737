/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { isEmpty } from "lodash";
import {
  CheckboxField,
  DataTable,
  FileUploadField,
  InfoHelper,
  InputField,
  ReactSelectField,
  SwitchField
} from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { plateFragment, tableFragment } from "../fragments";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";
import { allowedCsvFileTypes } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { Icon, Tooltip } from "@blueprintjs/core";
import { getPlateMatch } from "../utils";
import {
  getAliquotContainerByLocation,
  sortAliquotContainers,
  wellInBounds
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { download } from "../../../../../src-shared/utils/downloadTest";
import { unparse } from "papaparse";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { getAliquotMaterialList } from "../../../../utils/plateUtils";

const sampleFilter = (props, qb) => {
  qb.whereAll({
    dataTableTypeCode: "SAMPLE_QC_INPUT_STATUS"
  });
};

const qcFilter = (props, qb) => {
  qb.whereAll({
    dataTableTypeCode: "SAMPLE_QC_INPUT_QC_CHECK"
  });
};

function InputPlatesAndQCFile(props) {
  const {
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
    Footer,
    footerProps,
    sampleOrCheck,
    isUpload,
    containerArrays = [],
    qualityControlChecks = [],
    selectedQualityControlChecks = [],
    sampleQCInputDataTables = [],
    saveCsvAsTable,
    onSubmit,
    handleSubmit
  } = props;

  const selectedDataRows = [];
  const rowErrors = {};

  const supportedQCCheckNames = qualityControlChecks.map(check =>
    check.name.toLowerCase()
  );
  sampleQCInputDataTables.forEach(table =>
    table.dataRows.forEach(row => selectedDataRows.push(row))
  );

  if (containerArrays.length) {
    selectedDataRows.forEach(row => {
      const plateName = row.rowValues.plateName;
      const plateBarcode = row.rowValues.plateBarcode;
      const wellLocation = row.rowValues.wellLocation;
      const sampleStatus = row.rowValues.sampleStatus;
      const qcCheckName = row.rowValues.qcCheckName;
      const qcCheckStatus = row.rowValues.qcCheckStatus;

      const acceptedSampleTypeCodes = ["valid", "invalid"];
      const rowError = [];
      if (!plateName && !plateBarcode) {
        rowError.push(`Must provide plate name or barcode.`);
      } else {
        const plate = getPlateMatch(containerArrays, plateName, plateBarcode);
        if (!plate) {
          rowError.push(
            `No plate found with name ${plateName} and barcode ${plateBarcode} in selection.`
          );
        } else {
          const aliquotContainer = getAliquotContainerByLocation(
            plate,
            wellLocation
          );
          if (!wellLocation) {
            rowError.push(`Must provide a well location.`);
          }
          if (
            !wellInBounds(
              wellLocation,
              plate.containerArrayType.containerFormat
            )
          ) {
            rowError.push(`Well location does not fit on plate format.`);
          }
          if (!aliquotContainer) {
            rowError.push(`Well not found at that location.`);
          } else if (!aliquotContainer.aliquot) {
            rowError.push(`Well does not have an aliquot.`);
          }
          if (sampleOrCheck === "SAMPLE") {
            if (!sampleStatus) {
              rowError.push(`Must provide a sample status.`);
            }
            if (!acceptedSampleTypeCodes.includes(sampleStatus.toLowerCase())) {
              rowError.push(
                `Unsupported sample status, please use "valid" or "invalid".`
              );
            }
          } else {
            if (!qcCheckName) {
              rowError.push(`Please provide QC Check name.`);
            } else if (
              !supportedQCCheckNames.includes(qcCheckName.toLowerCase())
            ) {
              rowError.push(
                `Unsupported QC Check name, please check Settings.`
              );
            }
            if (!qcCheckStatus) {
              rowError.push(`Please provide QC Check status.`);
            } else if (
              !acceptedSampleTypeCodes.includes(qcCheckStatus.toLowerCase())
            ) {
              rowError.push(
                `unsupported status, please use "valid" or "invalid".`
              );
            }
          }
        }
      }

      if (rowError.length) {
        rowErrors[row.id] = rowError.join(`\n`);
      }
    });
  }

  const sharedFields = [
    {
      width: 50,
      type: "action",
      render: (_, r) => {
        if (rowErrors[r.id]) {
          return (
            <Tooltip
              popoverClassName="preserve-newline"
              content={rowErrors[r.id]}
            >
              <Icon intent="danger" icon="warning-sign" />
            </Tooltip>
          );
        }
      }
    },
    {
      displayName: "Plate Name",
      path: "rowValues.plateName"
    },
    {
      displayName: "Plate Barcode",
      path: "rowValues.plateBarcode"
    },
    {
      displayName: "Well Location",
      path: "rowValues.wellLocation"
    }
  ];

  let schema;
  if (sampleOrCheck === "SAMPLE") {
    schema = {
      model: "dataRow",
      fields: [
        ...sharedFields,
        {
          displayName: "Sample Status",
          path: "rowValues.sampleStatus"
        }
      ]
    };
  } else {
    schema = {
      model: "dataRow",
      fields: [
        ...sharedFields,
        { displayName: "QC Check Name", path: "rowValues.qcCheckName" },
        { displayName: "QC Check Status", path: "rowValues.qcCheckStatus" }
      ]
    };
  }

  const nextDisabled = !isEmpty(rowErrors);

  const sampleOrCheckOptions = [
    { label: "Sample Status", value: "SAMPLE" },
    { label: "QC Check", value: "QC" }
  ];

  const qcCheckOptions = arrayToItemValuedOptions(qualityControlChecks);

  const requiredHeaders = ["Plate Name", "Plate Barcode", "Well Location"];
  const sharedHeaders = [...requiredHeaders, "Sample Name", "Material Name"];
  let templateHeaders;

  const selectedQcCheckNames = selectedQualityControlChecks.map(
    check => check.name
  );
  if (sampleOrCheck === "QC") {
    templateHeaders = [...sharedHeaders, ...selectedQcCheckNames];
  } else {
    templateHeaders = [...sharedHeaders, "Sample Status"];
  }

  const downloadPrefilledTemplateLink = (
    <a
      onClick={() => {
        if (!containerArrays.length) {
          return window.toastr.warning("Select plates first.");
        }
        const csv = [templateHeaders];
        // "Plate Name", "Plate Barcode", "Well Location"
        containerArrays.forEach(p => {
          sortAliquotContainers([...p.aliquotContainers], "rowFirst").forEach(
            ac => {
              if (ac.aliquot) {
                const row = [
                  p.name,
                  p.barcode?.barcodeString,
                  getAliquotContainerLocation(ac),
                  ac.aliquot.sample?.name,
                  getAliquotMaterialList(ac.aliquot)
                    .map(m => m.name)
                    .join(", ")
                ];
                // pad array with empty so csv has all columns
                row.push(...new Array(templateHeaders.length - row.length));
                csv.push(row);
              }
            }
          );
        });
        download(unparse(csv), "sampleQc.csv");
      }}
    >
      Download Prefilled Template
    </a>
  );

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Input Plates"
          helper="Select one or more input plates of samples. The QC file uploaded below should match the name or barcode of the selected plates."
        />
        <div className="width100 column">
          <GenericSelect
            {...{
              name: "containerArrays",
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              isMultiSelect: true,
              fragment: [
                "containerArray",
                "id name barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: plateFragment,
              postSelectDTProps: {
                formName: "sampleQualityControlPlateSelect",
                schema: [
                  platePreviewColumn({ withSampleStatus: true }),
                  "name",
                  "containerArrayType.name",
                  { displayName: "Barcode", path: "barcode.barcodeString" }
                ]
              },
              isRequired: true,
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              }
            }}
          />
        </div>
      </div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Select Sample QC Data"
            helper={`Click the slider below to either select input data tables, or upload a file directly.
              For updating the status of the entire sample, select Sample Status, for running any number
              of specific QC Checks, select QC Check and then choose which checks you would like to update.
              Upload a sample QC file that assigns a sample status to each sample in the selected plates
              above. Accepted sample statuses are "Valid" and "Invalid".`}
          />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end"
            }}
          >
            <SwitchField
              name="isUpload"
              style={{ minWidth: 200 }}
              label={
                <span>
                  Upload Data
                  <InfoHelper
                    isInline
                    style={{ marginLeft: 10 }}
                    content="Either upload a CSV (download prefilled template) or choose a table of existing data."
                  />
                </span>
              }
            />
            <div style={{ minWidth: 200 }}>
              <ReactSelectField
                name="sampleOrCheck"
                label="Upload Type"
                defaultValue="SAMPLE"
                isRequired
                options={sampleOrCheckOptions}
              />
              {isUpload && sampleOrCheck === "QC" && (
                <ReactSelectField
                  name="selectedQualityControlChecks"
                  multi
                  label="QC Checks"
                  isRequired
                  options={qcCheckOptions}
                />
              )}
            </div>
          </div>
        </div>
        {isUpload ? (
          <React.Fragment>
            <div style={{ maxWidth: 500 }}>
              <FileUploadField
                accept={getDownloadTemplateFileHelpers({
                  type: allowedCsvFileTypes,
                  fileName: "sampleQCCSV",
                  headers: templateHeaders,
                  requiredHeaders
                })}
                fileLimit={1}
                isRequired
                name="sampleQCFile"
              />
            </div>
            <div
              style={{
                marginBottom: 15,
                marginTop: 15,
                display: "flex",
                alignItems: "center"
              }}
            >
              {downloadPrefilledTemplateLink}
              <InfoHelper
                style={{ marginLeft: 5 }}
                content="Select Plates and Upload Type first, then use this link to download a template file with info filled in."
              />
            </div>
          </React.Fragment>
        ) : (
          <GenericSelect
            {...{
              name: "sampleQCInputDataTables", //the field name within the redux form Field
              tableParamOptions: {
                additionalFilter:
                  sampleOrCheck === "SAMPLE" ? sampleFilter : qcFilter
              },
              buttonProps: {
                disabled: isDisabledMap.dataTables,
                loading: isLoadingMap.dataTables
              },
              isMultiSelect: true,
              schema: ["name", dateModifiedColumn],
              fragment: ["dataTable", "id name updatedAt"],
              additionalDataFragment: tableFragment
            }}
          />
        )}
        {!isUpload && selectedDataRows.length > 0 && (
          <DataTable
            formName="dataRows"
            isSimple
            compact
            rowErrors={rowErrors}
            schema={schema}
            entities={selectedDataRows}
          />
        )}
        {isUpload && (
          <div style={{ maxWidth: 250 }}>
            <CheckboxField
              label="Save CSV to Data Table Library"
              name="saveCsvAsTable"
            />
            {saveCsvAsTable && (
              <InputField name="csvTableName" label="Table Name" isRequired />
            )}
          </div>
        )}
      </div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Name Output Data"
          helper={`This tool will also output two inventory lists,
            one for valid samples and one for invalid samples.
            These lists can be used for hit-picking operations.
            Please provide a name for the output data.`}
        />
        <div>
          <InputField
            name="outputDataName"
            label="Name Output Data"
            isRequired
            generateDefaultValue={{
              ...defaultValueConstants.SAMPLE_QC_OUTPUT_NAME
            }}
          />
        </div>
      </div>
      <Footer
        {...footerProps}
        nextDisabled={nextDisabled}
        onNextClick={handleSubmit(values => {
          return onSubmit({
            ...values
          });
        })}
      />
    </React.Fragment>
  );
}

export default compose(
  stepFormValues(
    "sampleOrCheck",
    "containerArrays",
    "selectedQualityControlChecks",
    "isUpload",
    "sampleQCInputDataTables",
    "saveCsvAsTable"
  )
)(InputPlatesAndQCFile);
