/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import recordViewEnhancer from "../recordViewEnhancer";
import AbstractRecord from "../AbstractRecord";
import TgSequenceEditor, { sequenceRecordFragment } from "../TgSequenceEditor";
import modelNameToLink from "../utils/modelNameToLink";
import { getEnclosingFeatures } from "../utils/sequenceUtils";
import gql from "graphql-tag";
import { sizeInfo } from "../utils/libraryColumns";
import { flatMap, pick } from "lodash";

class PartRecordView extends Component {
  state = {};

  render() {
    const { part } = this.props;
    const { name, type, sequence } = part;
    const enclosingFeatures = getEnclosingFeatures(part, sequence);
    const recordInfo = [
      ["Name", name],
      ["Type", type],
      sizeInfo(part),
      [
        "Enclosing Features",
        enclosingFeatures.map((f, i) => (
          <React.Fragment key={f.id}>
            <Link to={modelNameToLink(f.__typename, f.id)}>{f.name}</Link>
            {i < enclosingFeatures.length - 1 ? ", " : ""}
          </React.Fragment>
        ))
      ],
      [
        "Source Sequence",
        <Link
          key={sequence.id}
          to={modelNameToLink(sequence.__typename, sequence.id)}
        >
          {sequence.name}
        </Link>
      ],
      [associatedDesigns().displayName, associatedDesigns().render(null, part)]
    ];
    return (
      <AbstractRecord {...this.props} recordInfo={recordInfo} recordName="part">
        <TgSequenceEditor
          {...this.props}
          sequence={part.sequence}
          onPreviewModeFullscreenClose={() => {
            this.props.refetchRecord();
          }}
          hoveredId={part.id}
          editorOpts={{ selectionLayer: pick(part, ["start", "end"]) }}
        />
      </AbstractRecord>
    );
  }
}

export const partRecordFragment = gql`
  fragment partRecordFragment on part {
    id
    name
    type
    strand
    start
    end
    elements {
      id
      design {
        id
        lockTypeCode
        name
      }
    }
    sequenceId
    sequence {
      ...sequenceRecordFragment
    }
  }
  ${sequenceRecordFragment}
`;

export default recordViewEnhancer(partRecordFragment)(PartRecordView);

export const associatedDesigns = (prefix = "") => ({
  path: `${prefix}elements.design.name`,
  type: "string",
  displayName: "Linked Designs",
  render: (_, r) => {
    const toFilter = {};
    return (
      <>
        {flatMap(prefix ? r.parts : [r], ({ elements }) => {
          return flatMap(elements, element => {
            if (!element?.design?.id) return [];
            if (toFilter[element.design.id]) return [];
            toFilter[element.design.id] = true;
            if (element.design.lockTypeCode === "LOCKED_FOR_ASSEMBLY")
              return [];
            return [
              ", ",
              <span key={element.design.id}>
                <Link to={`/designs/${element.design.id}`}>
                  {element.design.name}
                </Link>
              </span>
            ];
          });
        }).slice(1)}
      </>
    );
  }
});
