/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { withSelectedEntities } from "@teselagen/ui";
import Big from "big.js";
import { uniq, set } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import {
  SelectPrepMaterials,
  MapToDestinationPlates,
  ReviewWorklists
} from "./Steps";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import {
  getIntermediateContainerAdjustedMaxVolume,
  getPlatePrepKey
} from "./utils";
import {
  platePrepPlateFragment,
  platePrepLotFragment,
  platePrepInputPlateFragment,
  platePrepAliquotContainerFragment
} from "./fragments";
import { createWorklistExtPropEntities } from "../../../../src-shared/utils/extendedPropertyUtils";
import stripFields from "../../../../src-shared/utils/stripFields";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import {
  REQUIRED_ERROR,
  throwFormError
} from "../../../../src-shared/utils/formUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateEmptyWells } from "../../../../../tg-iso-lims/src/utils/plateUtils";

class PlatePrepTool extends React.Component {
  onSubmit = async values => {
    try {
      const {
        worklistNames,
        worklists,
        destinationContainerArrayExtendedValues = [],
        destinationAliquotExtendedValues = [],
        intermediateContainersToUpsert,
        allDestinationPlates = {},
        lotVolumeToUpsert
      } = values;
      const {
        destinationContainerArrayExtendedPropertiesToRemoveSelectedEntities: containerArrayPropsToRemove = [],
        destinationAliquotExtendedPropertiesToRemoveSelectedEntities: aliquotPropsToRemove = []
      } = this.props;

      const worklistsToUpsert = worklists.map((worklist, i) => {
        const destinationPlateIdsForWorklist = [];
        const destinationAliquotContainerIdsForWorklist = [];

        worklist.worklistTransfers.forEach(t => {
          destinationAliquotContainerIdsForWorklist.push(
            t.destinationAliquotContainerId
          );
          const destPlate = allDestinationPlates[t.destinationPlateId];
          if (destPlate) destinationPlateIdsForWorklist.push(destPlate.id);
        });
        const deduplicatedPlateIds = uniq(destinationPlateIdsForWorklist);
        let worklistContainerArrays = [];
        if (
          containerArrayPropsToRemove.length ||
          destinationContainerArrayExtendedValues.length
        ) {
          worklistContainerArrays = createWorklistExtPropEntities({
            model: "containerArray",
            propsToRemove: containerArrayPropsToRemove,
            destIds: deduplicatedPlateIds,
            extendedValues: destinationContainerArrayExtendedValues
          });
        }
        return {
          name: worklistNames[i],
          worklistTransfers: stripFields(worklist.worklistTransfers, [
            "destinationAliquotContainer",
            "sourceAliquotContainer",
            "destinationPlateName",
            "sourcePlateName",
            "destinationPlateId"
          ]).map(transfer => {
            if (
              destinationAliquotExtendedValues.length ||
              aliquotPropsToRemove.length
            ) {
              transfer.worklistTransferAliquotContainers = createWorklistExtPropEntities(
                {
                  model: "aliquotContainer",
                  propsToRemove: aliquotPropsToRemove,
                  destIds: [transfer.destinationAliquotContainerId],
                  extendedValues: destinationAliquotExtendedValues,
                  joinTableName: "transferExtendedProperty",
                  dataItemTypeCode: "WORKLIST_TRANSFER_ALIQUOT_CONTAINER",
                  additionalFields: {
                    isTargetAliquot: true
                  }
                }
              );
            }
            return transfer;
          }),
          worklistContainerArrays
        };
      });
      await safeUpsert("containerArray", intermediateContainersToUpsert);
      await safeUpsert("lot", lotVolumeToUpsert);
      const createdWorklists = await safeUpsert("worklist", worklistsToUpsert);
      return {
        worklists: createdWorklists
      };
    } catch (error) {
      console.error("error:", error);
      throwFormError("Error creating worklists.");
    }
  };

  validate = values => {
    const {
      prepMaterialPlates = [],
      prepTubes = [],
      prepLots = [],
      prepAliquotContainers = [],
      transferVolume = {},
      transferVolumetricUnitCode = {},
      universalTransfers,
      universalTransferVolume,
      universalTransferVolumeUnitCode,
      intermediateContainers = {},
      worklistNames = [],
      platesToPrep = {},
      selectedWellsForPlate = {},
      prepEmptyWells = {}
    } = values;
    const errors = {
      transferVolume: {},
      worklistNames: []
    };
    if (!prepMaterialPlates.length && !prepTubes.length && !prepLots.length) {
      errors.prepMaterialPlates = errors.prepTubes = errors.prepLots =
        "Please choose some plates, tubes, or lots.";
    }

    prepAliquotContainers.forEach(ac => {
      const key = `id${ac.id}`;
      if (!transferVolume[key]) {
        errors.transferVolume[key] = REQUIRED_ERROR;
      }
    });

    if (worklistNames.length) {
      worklistNames.forEach((name, i) => {
        if (!name) worklistNames[i] = REQUIRED_ERROR;
      });
    }

    errors.platesToPrep = {};
    errors.prepEmptyWells = {};
    errors.transferVolume = {};
    errors.intermediateContainers = {};
    if (universalTransfers && !universalTransferVolume) {
      errors.universalTransferVolume = REQUIRED_ERROR;
    }
    const setVolumeError = (key, errorMessage) => {
      if (universalTransfers) {
        errors.universalTransfers = errorMessage;
      } else {
        errors.transferVolume[key] = errorMessage;
      }
    };
    const getVolumeError = key => {
      if (universalTransfers) {
        return errors.universalTransfers;
      } else {
        return errors.transferVolume[key];
      }
    };
    prepAliquotContainers.concat(prepTubes, prepLots).forEach(item => {
      const key = getPlatePrepKey(item);
      if (!platesToPrep[key] || !platesToPrep[key].length) {
        errors.platesToPrep[key] = REQUIRED_ERROR;
      }
      if (!universalTransfers && !transferVolume[key]) {
        errors.transferVolume[key] = REQUIRED_ERROR;
      }
      if (intermediateContainers[key] && !intermediateContainers[key].name) {
        set(errors, `intermediateContainers.${key}.name`, REQUIRED_ERROR);
      }
      const volumeToTest = universalTransfers
        ? universalTransferVolume
        : transferVolume[key];
      const volumeUnitToTest = universalTransfers
        ? universalTransferVolumeUnitCode
        : transferVolumetricUnitCode[key];

      if (
        volumeToTest &&
        (platesToPrep[key] ||
          intermediateContainers[key] ||
          item.__typename === "lot")
      ) {
        let numDestinationAliquotContainers = 0;
        let totalNumberOfSelectedAliquotContainers = 0;
        const shouldPrepEmptyWells = prepEmptyWells[key];
        platesToPrep[key] &&
          platesToPrep[key].forEach(plate => {
            const plateWellsKey = key + `-${plate.id}`;
            const wells = selectedWellsForPlate[plateWellsKey];
            if (wells && wells.length) {
              totalNumberOfSelectedAliquotContainers += wells.length;
            } else {
              totalNumberOfSelectedAliquotContainers +=
                plate.aliquotContainers.length;
            }
            if (shouldPrepEmptyWells) {
              if (wells && wells.length) {
                numDestinationAliquotContainers += wells.length;
              } else {
                numDestinationAliquotContainers +=
                  plate.aliquotContainers.length;
              }
            } else {
              plate.aliquotContainers.forEach(ac => {
                if (ac.aliquot) {
                  if (wells && wells.length) {
                    if (wells.includes(getAliquotContainerLocation(ac))) {
                      numDestinationAliquotContainers++;
                    }
                  } else {
                    numDestinationAliquotContainers++;
                  }
                }
              });
            }
          });

        if (platesToPrep[key] && platesToPrep[key].length) {
          if (
            totalNumberOfSelectedAliquotContainers > 0 &&
            numDestinationAliquotContainers === 0
          ) {
            errors.prepEmptyWells[key] =
              "All destination wells/tubes are empty. Check prep empty wells if you would like to continue.";
          } else if (totalNumberOfSelectedAliquotContainers === 0) {
            errors.prepEmptyWells[key] =
              "Destination racks do not have any tubes to prep.";
          }
        }

        const totalStandardizedDestinationPlateVolume = standardizeVolume(
          volumeToTest * numDestinationAliquotContainers,
          volumeUnitToTest
        );
        if (isNaN(Number(volumeToTest)) || Number(volumeToTest) <= 0) {
          setVolumeError(key, "Please enter a valid transfer volume.");
        } else if (
          intermediateContainers[key] &&
          intermediateContainers[key].type
        ) {
          const intermediateContainerType = intermediateContainers[key].type;
          // because we can't do partial transfers we need to make sure that the max volume is divisible by the
          // transfer volume otherwise we need to trim some off
          const { containerFormat } = intermediateContainerType;

          const standardizedTransferVolume = standardizeVolume(
            volumeToTest,
            volumeUnitToTest,
            true
          );

          const maxWellVolumeAdjusted = getIntermediateContainerAdjustedMaxVolume(
            {
              intermediateContainerType,
              standardizedTransferVolume
            }
          );

          const {
            deadVolume,
            deadVolumetricUnitCode
          } = intermediateContainerType.aliquotContainerType;

          const standardizedDeadVolume = standardizeVolume(
            deadVolume || 0,
            deadVolumetricUnitCode || "uL",
            true
          );

          const totalStandardizedIntermediateContainerVolume = Number(
            maxWellVolumeAdjusted.times(containerFormat.quadrantSize).toString()
          );
          if (
            totalStandardizedDestinationPlateVolume >
            totalStandardizedIntermediateContainerVolume
          ) {
            const errorMessage =
              "Selected intermediate container type will not have enough additive volume to prep selected plates.";
            setVolumeError(key, errorMessage);
          }
          if (item.__typename === "lot" && !getVolumeError(key)) {
            if (item.volume && item.volumetricUnitCode) {
              const standardizedLotVolume = standardizeVolume(
                item.volume,
                item.volumetricUnitCode
              );

              if (
                totalStandardizedDestinationPlateVolume > standardizedLotVolume
              ) {
                set(
                  errors,
                  `intermediateContainers.${key}.type`,
                  "Selected lot does not have enough additive for all transfers."
                );
              } else {
                let volumeNeeded = standardizeVolume(
                  volumeToTest * numDestinationAliquotContainers,
                  volumeUnitToTest,
                  true
                );
                let totalLotVolumeNeeded = new Big(0);

                // calculate the total lot volume needed for all transfers including dead volume
                generateEmptyWells(containerFormat).some(() => {
                  if (volumeNeeded.lt(0)) {
                    return true;
                  } else if (volumeNeeded.minus(maxWellVolumeAdjusted).lt(0)) {
                    totalLotVolumeNeeded = totalLotVolumeNeeded
                      .plus(volumeNeeded)
                      .plus(standardizedDeadVolume);
                    return true;
                  } else {
                    totalLotVolumeNeeded = totalLotVolumeNeeded
                      .plus(maxWellVolumeAdjusted)
                      .plus(standardizedDeadVolume);
                    volumeNeeded = volumeNeeded.minus(maxWellVolumeAdjusted);
                    return false;
                  }
                });
                if (totalLotVolumeNeeded.gt(standardizedLotVolume)) {
                  set(
                    errors,
                    `intermediateContainers.${key}.type`,
                    `Accounting for the intermediate container's dead volume of ${deadVolume} ${deadVolumetricUnitCode} the lot does not have enough additive for all transfers.`
                  );
                }
              }
            }
          }
        }
        if (item.__typename === "lot" && !getVolumeError(key)) {
          // make sure lot has enough volume for all transfers
          if (item.volume && item.volumetricUnitCode) {
            const standardizedLotVolume = standardizeVolume(
              item.volume,
              item.volumetricUnitCode
            );
            if (
              totalStandardizedDestinationPlateVolume > standardizedLotVolume
            ) {
              set(
                errors,
                `intermediateContainers.${key}.type`,
                "Selected lot does not have enough additive for all transfers."
              );
            }
          } else {
            errors.platesToPrep[key] = "Lot does not have any volume.";
          }
        }
      }
    });
    if (!prepMaterialPlates.length && !prepTubes.length && !prepLots.length) {
      errors.prepMaterialPlates =
        "Must select at least one plate, tube or reagent lot.";
      errors.prepTubes = "Must select at least one plate, tube or reagent lot.";
      errors.prepLots = "Must select at least one plate, tube or reagent lot.";
    }
    if (prepMaterialPlates.length) {
      const platesWithAliquots = [];
      prepMaterialPlates.forEach(plate => {
        if (
          plate.aliquotContainers &&
          plate.aliquotContainers.some(ac => ac.aliquot)
        ) {
          platesWithAliquots.push(plate);
        }
      });
      if (platesWithAliquots.length < 1) {
        errors.prepMaterialPlates = "Selected plates must have aliquots.";
      }
    }
    const aliquotContainerIsInvalidForPrep = ac => {
      const hasWetAliquot = ac.aliquot && !ac.aliquot.isDry;
      const hasAdditives = !!ac.additives.length;
      return !hasWetAliquot && !hasAdditives;
    };
    if (prepMaterialPlates.length) {
      const invalidPlates = [];
      prepMaterialPlates.forEach(plate => {
        if (
          plate.aliquotContainers &&
          plate.aliquotContainers.every(aliquotContainerIsInvalidForPrep)
        ) {
          invalidPlates.push(plate);
        }
      });
      if (invalidPlates.length > 0) {
        errors.prepMaterialPlates =
          "Selected plates must have wet aliquots or additives.";
      }
    }
    if (prepTubes.length) {
      const invalidTubes = prepTubes.filter(aliquotContainerIsInvalidForPrep);
      if (invalidTubes.length > 0) {
        errors.prepTubes = "All tubes must have wet aliquots or additives.";
      }
    }
    if (prepLots.length) {
      const lotsWithoutMaterials = [];
      prepLots.forEach(lot => {
        if (!lot.additiveMaterial) {
          lotsWithoutMaterials.push(lot);
        }
      });
      if (lotsWithoutMaterials.length > 0) {
        errors.prepLots = "All lots must have materials.";
      }
    }
    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select Materials",
        Component: SelectPrepMaterials,
        withCustomFooter: true
      },
      {
        title: "Map To Destination Plates",
        Component: MapToDestinationPlates,
        withCustomFooter: true
      },
      {
        title: "Review Worklists",
        Component: ReviewWorklists,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(platePrepPlateFragment, {
    initialValueName: "destinationContainerArrays"
  }),
  withWorkflowInputs(platePrepLotFragment, {
    inputName: "prepLots"
  }),
  withWorkflowInputs(platePrepInputPlateFragment, {
    inputName: "prepMaterialPlates"
  }),
  withWorkflowInputs(platePrepAliquotContainerFragment, {
    inputName: "prepTubes"
  }),
  withSelectedEntities(
    "destinationContainerArrayExtendedPropertiesToRemove",
    "destinationAliquotExtendedPropertiesToRemove"
  )
)(PlatePrepTool);
