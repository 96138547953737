/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import { every, identity } from "lodash";
import { allSubtypesToName } from "./importExportSubtypes";
import forcedAssemblyStrategies from "../../../../tg-iso-design/constants/forcedAssemblyStrategies";
import {
  aliasModels,
  extendedPropertyModels,
  externalRecordIdentifierModels,
  tagModels
} from "../../../constants";

export const yupRequiredIfSuccess = yup
  .string()
  .when("success", (success, schema) => {
    return success ? schema.required() : schema;
  });

export function getIsUpdateFields({ isUpdateCall }) {
  return {
    id: yup.string().nullable(),
    ...(isUpdateCall ? { __remove: yup.bool(), __create: yup.bool() } : {})
  };
}

export const tagField = ({ isUpdateCall } = {}) => ({
  tags: yup.array(
    yup.lazy(value => {
      return yup.object({
        ...getIsUpdateFields({ isUpdateCall }),
        name: value.__remove ? yup.string() : yup.string().required(),
        color: yup.string(),
        tagOption: yup.string(),
        tagOptionColor: yup.string()
      });
    })
  )
});

export const formInputsValidation = yup.array(
  yup.object({
    fieldType: yup
      .mixed()
      .oneOf([
        "InputField",
        "TextareaField",
        "ReactSelectField",
        "CheckboxField",
        "EditableTextField",
        "SuggestField",
        "FileUploadField",
        "NumericInputField",
        "RadioGroupField",
        "DateInputField",
        "DateRangeInputField",
        "PagedSelectField",
        "ReactColorField",
        "SwitchField",
        "SelectField"
      ])
      .required(),
    name: yup.string().required(),
    label: yup.string(),
    url: yup.string().when("fieldType", {
      is: "PagedSelectField",
      then: yup
        .string()
        .required(
          "PagedSelectField requires a url to be defined to be used as a custom formInput"
        ),
      otherwise: yup.string()
    }),
    cascadeEndpoint: yup.mixed().when("fieldType", {
      is: val => {
        if (
          val === "PagedSelectField" ||
          val === "SelectField" ||
          val === "ReactSelectField"
        ) {
          return true;
        }
      },
      then: yup.string(),
      otherwise: yup
        .mixed()
        .test(
          "hasVal",
          "cascadeEndpoint is only supported for SelectField and PagedSelectField",
          val => {
            if (val) return false;
            else return true;
          }
        )
    })
  })
);

export const formatPageValidation = {
  title: yup.string(),
  formInputs: formInputsValidation
};

export const yupExternalReferenceFields = ({ doNotRequireExternalFields }) => {
  const validator = doNotRequireExternalFields
    ? yup.string().nullable()
    : yup.string().required();
  return {
    externalReferenceId: doNotRequireExternalFields
      ? yup.string().nullable()
      : yup.string().required(),
    externalReferenceSystem: validator,
    externalReferenceType: validator,
    externalReferenceUrl: yup.string().nullable()
  };
};

export const exampleRecordValidation = ({
  subtype,
  doNotRequireExternalFields,
  isUpdateCall,
  forceUpdateTransform,
  allowBpUpdates
}) => {
  const disallowOnUpdate = (validate = yup.string().required()) => {
    if (forceUpdateTransform) {
      /**
       * NOTE: there are occasions where an UPDATE may want to create a new nested record
       * so instead of having to: create that record, obtaining its ID, and then reference it in the parent object
       * we can more directly create the nested records on an update call.
       *
       * For more information: refer to the 'exampleMicrobialMaterialUpdateFlow' example.
       */
      return yup.mixed().when("__create", {
        is: true,
        then: validate,
        otherwise: yup.mixed().strip()
      });
    }
    if (!isUpdateCall) {
      return validate;
    }
    return yup.mixed().when("id", {
      is: v => v,
      then: yup.mixed().test(
        "id-passed",
        // eslint-disable-next-line no-template-curly-in-string
        "Updating ${path} is not allowed",
        val => !val
      ),
      otherwise: validate
    });
  };

  const extendedPropertyFields = {
    extendedProperties: yup.array(
      yup.lazy(value => {
        return yup.object({
          ...getIsUpdateFields({ isUpdateCall }),
          name: value.__remove ? yup.string() : yup.string().required(),
          value: value.__remove ? yup.string() : yup.string().required()
        });
      })
    )
  };

  const optionalOnUpdate = x => {
    if (isUpdateCall) {
      return yup.lazy(v => {
        if (v === undefined || v === null) {
          return yup.mixed().test({
            message: "Ignore this field",
            name: "ignore",
            test() {
              return true;
            }
          });
        } else return x;
      });
    } else {
      return x.required();
    }
  };

  // If it's an update call, make required if the record does NOT
  // come with any of the fields in fieldNames.
  const optionalOnUpdateWithAnyField = (fieldNames, x) => {
    if (isUpdateCall) {
      return yup.mixed().when(fieldNames, {
        is: (...fields) => every(fields, field => !field),
        then: x.required(),
        otherwise: x.default(undefined)
      });
    } else {
      return x.default(undefined);
    }
  };

  // If it's an update call, make required if the record does NOT
  // come with an field called "fieldName".
  const optionalOnUpdateWithField = (fieldName, x) =>
    optionalOnUpdateWithAnyField([fieldName], x);

  // If it's an update call, make required if
  // the record does NOT come with an id.
  const optionalOnUpdateWithId = x => optionalOnUpdateWithField("id", x);
  // aliquots do not have external reference fields because they cannot be imported by themselves
  const commonFields = model => ({
    ...getIsUpdateFields({ isUpdateCall }),
    ...(tagModels.includes(model) && tagField({ isUpdateCall })),
    ...(aliasModels.includes(model) && {
      aliases: yup.array(yup.string())
    }),
    ...(extendedPropertyModels.includes(model) && extendedPropertyFields),
    ...(externalRecordIdentifierModels.includes(model) &&
      yupExternalReferenceFields({
        doNotRequireExternalFields: doNotRequireExternalFields || isUpdateCall
      }))
  });

  const notesFields = yup.lazy(v => {
    if (v) {
      const objShape = {};
      Object.keys(v).forEach(key => {
        objShape[key] = yup.array(yup.string());
      });
      return yup.object().shape(objShape);
    } else {
      return yup.mixed();
    }
  });

  // NOTE: these fields, from DM perspective, should not be nested into sequences
  // but the other way around instead. Parts and Features should nest a sequence object.

  // TODO: Since it also makes sense to nest annotation within sequence objects, we may want
  // for the UPDATE handlers to support both scenarios.
  const annotationFields = {
    features: yup
      .array(
        yup.object({
          ...tagField({ isUpdateCall }),
          ...getIsUpdateFields({ isUpdateCall }),
          name: optionalOnUpdate(yup.string()),
          type: yup.string(),
          strand: yup.number(),
          start: optionalOnUpdate(yup.number()),
          end: optionalOnUpdate(yup.number()),
          notes: notesFields
        })
      )
      .nullable(),
    parts: yup
      .array(
        yup.object({
          ...tagField({ isUpdateCall }),
          ...getIsUpdateFields({ isUpdateCall }),
          name: optionalOnUpdate(yup.string()),
          strand: yup.number(),
          start: optionalOnUpdate(yup.number()),
          end: optionalOnUpdate(yup.number()),
          notes: notesFields
        })
      )
      .nullable()
  };

  const dnaSequenceFields = yup.object({
    ...commonFields("sequence"),
    ...getIsUpdateFields({ isUpdateCall }),
    name: optionalOnUpdate(yup.string()),
    sequence: (allowBpUpdates ? identity : disallowOnUpdate)(
      yup.string().typeError("sequence must be a string").strict(true)
    ),
    circular: yup.boolean(),
    description: yup.string(),
    inInventory: yup.boolean(),
    ...annotationFields
  });
  const simpleMicrobialStrainFields = {
    ...commonFields("strain"),
    name: optionalOnUpdate(yup.string()),
    genotype: yup.string(),
    description: yup.string(),
    biosafetyLevel: optionalOnUpdate(
      yup.mixed().oneOf(["1", "2", "3", "4", "N/A"])
    ),
    selectionMethods: yup.array(yup.string().required()),
    inductionMethods: yup.array(yup.string().required()),
    genus: yup.string(),
    species: yup.string(),
    plasmidNames: yup.array(yup.string().required()),
    plasmids: yup.array(dnaSequenceFields),
    genomeName: yup.string(),
    gasComposition: yup.string(),
    targetOrganismGroup: yup.string(),
    growthConditionName: yup.string(),
    growthConditionDescription: yup.string(),
    growthMedium: yup.string(),
    temperature: yup.number(),
    humidity: yup.number(),
    shakerSpeed: yup.number(),
    shakerThrow: yup.number(),
    lengthUnit: yup.mixed().oneOf(["cm", "in", "mm"]),
    growthCondition: yup.object({
      name: yup.string(),
      description: yup.string(),
      growthMedium: yup.string(),
      gasComposition: yup.string(),
      temperature: yup.number(),
      humidity: yup.number(),
      shakerSpeed: yup.number(),
      shakerThrow: yup.number(),
      lengthUnit: yup.string()
    })
  };

  const microbialStrainFields = yup.object(simpleMicrobialStrainFields);
  const microbialMaterialFields = yup.object({
    ...commonFields("material"),
    name: optionalOnUpdate(yup.string().required()),
    strainName: yup.string(),
    strain: microbialStrainFields.default(undefined),
    plasmidNames: disallowOnUpdate(yup.array(yup.string().required())),
    plasmids: yup.array(dnaSequenceFields),
    internalAvailability: yup.boolean().default(undefined),
    externalAvailability: yup.boolean().default(undefined)
  });
  const fullMicrobialStrainFields = yup.object({
    ...simpleMicrobialStrainFields,
    sourceMicrobialMaterial: microbialMaterialFields.default(undefined)
  });

  const dnaMaterialFields = yup.object({
    ...commonFields("material"),
    name: optionalOnUpdate(yup.string()),
    sequence: optionalOnUpdate(dnaSequenceFields),
    internalAvailability: yup.boolean().default(undefined),
    externalAvailability: yup.boolean().default(undefined)
  });

  const codingDnaSequencesFields = yup.object({
    sequenceTypeCode: yup.string().default("CDS"),
    isCds: yup.boolean().default(true),
    sequence: yup.string().default(""),
    name: yup.string().default("")
  });

  const commonAminoAcidFields = {
    ...commonFields("aminoAcidSequence"),
    name: optionalOnUpdate(yup.string()),
    proteinSequence: disallowOnUpdate(
      yup.string().typeError("proteinSequence must be a string").strict(true)
    ),
    codingDnaSequences: yup.array(codingDnaSequencesFields),
    ...annotationFields
  };

  const aminoAcidSequenceFields = yup.object(commonAminoAcidFields);

  const aminoAcidSequenceFieldsForProteinSubUnit = yup.object({
    ...commonAminoAcidFields,
    proteinSequence: optionalOnUpdate(
      yup.string().typeError("proteinSequence must be a string").strict(true)
    )
  });

  const proteinSubUnit = yup.object({
    index: yup.number(),
    id: yup.string(),
    aminoAcidSequence:
      aminoAcidSequenceFieldsForProteinSubUnit.default(undefined),
    aminoAcidSequenceId: yup.string().default(undefined)
  });

  const functionalProteinUnitFields = yup.object({
    name: optionalOnUpdate(yup.string()),
    id: yup.string(),
    proteinSubUnits: yup.array(proteinSubUnit)
  });

  const proteinMaterialFields = yup.object({
    ...commonFields("material"),
    name: optionalOnUpdate(yup.string()),
    materialTypeCode: yup.string().default("PROTEIN"),
    functionalProteinUnit: functionalProteinUnitFields.default(undefined),
    internalAvailability: yup.boolean().default(undefined),
    externalAvailability: yup.boolean().default(undefined)
  });

  const aliquotFields = yup.object({
    ...commonFields("aliquot"),
    volume: yup.number(),
    mass: yup.number(),
    concentration: yup.number(),
    volumetricUnitCode: yup.string(),
    massUnitCode: yup.string(),
    concentrationUnitCode: yup.string(),
    microbialMaterial: microbialMaterialFields.default(undefined),
    dnaMaterial: dnaMaterialFields.default(undefined)
  });
  const organismFields = yup.object({
    ...commonFields("targetOrganismClass"),
    name: optionalOnUpdate(yup.string()),
    description: optionalOnUpdate(yup.string())
  });
  const selectionMethodFields = yup.object({
    ...commonFields("selectionMethod"),
    name: optionalOnUpdate(yup.string()),
    description: optionalOnUpdate(yup.string()),
    targetOrganismGroups: yup.array(yup.string())
  });

  const dnaPartFields = yup.object({
    ...commonFields("part"),
    name: optionalOnUpdate(yup.string()),
    strand: optionalOnUpdate(yup.mixed().oneOf([1, -1])),
    start: optionalOnUpdate(yup.number().integer().min(0)),
    end: optionalOnUpdate(yup.number().integer().min(0)),
    sequenceId: optionalOnUpdate(
      yup
        .string()
        .typeError("sequenceId must be a valid existing sequence ID.")
        .strict(true)
    )
  });

  const designBinFields = yup.object({
    ...commonFields("bin"),
    id: yup.string(),
    name: yup.string(),
    dnaParts: yup.array(
      yup.object({
        // id: yup.string(),
        ...getIsUpdateFields({ isUpdateCall }),
        name: yup.string(),
        isUpdated: yup.string(),
        partSequenceHash: yup.string(),
        sourceSequence: yup.object({
          id: yup.string(),
          name: yup.string(),
          circular: yup.boolean(),
          hash: yup.string()
        }),
        forcedAssemblyStrategy: yup
          .mixed()
          .oneOf(forcedAssemblyStrategies)
          .default(undefined)
      })
    )
  });

  const designFields = yup.object({
    id: yup.string(),
    name: yup.string(),
    bins: yup.array(designBinFields)
  });

  const designPartFields = yup.object({
    ...getIsUpdateFields({ isUpdateCall }),
    // This 'name' field is a copy of the dnaPart.name
    // Maybe remove it and try to get it from the dnaPart itself.
    name: yup.mixed().when("dnaPart", dnaPart =>
      yup
        .string()
        .required()
        .default(dnaPart.name || "not-found")
    ),
    // The 'design' information is only needed in the request
    // the DESIGN_PART subtype of the UPDATE hook uses it as context information.
    design: optionalOnUpdate(designFields),
    // TODO: It seems that there is more that one way to represent the linkage between
    // sequences and its annotations (features and parts). However when working the parts as the root object
    // its best to follow the DM path which is Part -> Sequence.
    dnaPart: yup.object({
      ...getIsUpdateFields({ isUpdateCall }),
      name: optionalOnUpdateWithId(yup.string()),
      strand: optionalOnUpdateWithId(yup.mixed().oneOf([1, -1])),
      start: optionalOnUpdateWithId(yup.number().integer().min(0)),
      end: optionalOnUpdateWithId(yup.number().integer().min(0)),
      partSequence: optionalOnUpdate(
        yup.string().typeError("sequence must be a string").strict(true)
      ),
      partSequenceHash: yup.string(),
      sourceSequenceId: optionalOnUpdateWithField(
        "sourceSequence",
        yup.string()
      ),
      // If a valid dnaSequenceId is provided,
      // then dnaSequence object can be null and not required.
      sourceSequence: yup.object({
        // id: optionalOnUpdate(yup.string())
        ...getIsUpdateFields({ isUpdateCall }),
        name: optionalOnUpdateWithId(yup.string()),
        sequence: optionalOnUpdateWithId(
          yup
            .string()
            .min(4)
            .typeError("sequence must be a string")
            .strict(true)
        ),
        circular: optionalOnUpdateWithId(yup.boolean()),
        hash: optionalOnUpdateWithAnyField(["id", "sequence"], yup.string()),
        description: yup.string(),
        size: yup.number()
      })
    }),
    forcedAssemblyStrategy: yup
      .mixed()
      .nullable()
      .oneOf([null, ...forcedAssemblyStrategies]),
    // Position in the bin (top-to-bottom)
    index: optionalOnUpdate(yup.number()),
    reactionId: yup.string()
  });

  const reactionMapAndDesignFlattenedFields = {
    reagents: yup.array(
      yup.object({
        ref: yup.string(),
        name: yup.string(),
        description: yup.string(),
        isDry: yup.boolean(),
        selectionMethods: yup.array(yup.string()),
        evaporable: yup.boolean(),
        reagentType: yup.string(),
        targetOrganismGroup: yup.string()
      })
    ),
    materials: yup.array(
      yup.object({
        ref: yup.string(),
        name: yup.string(),
        materialType: yup.string(),
        sequences: yup.array(yup.string())
      })
    ),
    strains: yup.array(
      yup.object({
        ref: yup.string(),
        ...simpleMicrobialStrainFields,
        sequences: yup.array(yup.string())
      })
    ),
    sequences: yup.array(
      yup.object({
        ref: yup.string(),
        sequenceType: yup.string(),
        sequence: yup.string(),
        name: yup.string(),
        features: yup.array(
          yup.object({
            name: yup.string(),
            start: yup.number(),
            end: yup.number(),
            strand: yup.number(),
            type: yup.string()
          })
        )
      })
    )
  };

  const reactionMapFields = {
    ...commonFields("reactionMap"),
    name: yup.string(),
    version: yup.number(),
    reactionType: yup.string(),
    reactions: yup.array(
      yup.object({
        name: yup.string(),
        inputMaterials: yup.array(yup.string()),
        inputReagents: yup.array(yup.string()),
        inputReagentsConserved: yup.array(yup.string()),
        outputMaterials: yup.array(yup.string()),
        outputReagents: yup.array(yup.string())
      })
    )
  };

  const validationMap = {
    [allSubtypesToName.PLATE]: yup.object({
      ...commonFields("containerArray"),
      name: optionalOnUpdate(yup.string()),
      barcode: yup.string(),
      plateType: disallowOnUpdate(yup.string().required()),
      tubeType: yup.string(),
      contents: yup.array(
        yup.object({
          location: yup.string().required(),
          barcode: yup.string(),
          aliquot: aliquotFields
        })
      )
    }),
    [allSubtypesToName.TUBE]: yup.object({
      ...commonFields("aliquotContainer"),
      name: yup.string(),
      barcode: yup.string(),
      tubeType: disallowOnUpdate(yup.string().required()),
      aliquot: aliquotFields
    }),
    [allSubtypesToName.ALIQUOT]: aliquotFields,
    [allSubtypesToName.DNA_SEQUENCE]: dnaSequenceFields,
    [allSubtypesToName.DNA_PART]: dnaPartFields,
    [allSubtypesToName.SELECTION_METHOD]: selectionMethodFields,
    [allSubtypesToName.ORGANISM]: organismFields,
    [allSubtypesToName.DNA_MATERIAL]: dnaMaterialFields,
    [allSubtypesToName.PROTEIN_MATERIAL]: proteinMaterialFields,
    [allSubtypesToName.OLIGO]: yup.object({
      ...commonFields("sequence"),
      name: optionalOnUpdate(yup.string()),
      inInventory: yup.boolean(),
      sequence: disallowOnUpdate(
        yup.string().typeError("sequence must be a string").strict(true)
      )
    }),
    [allSubtypesToName.AMINO_ACID]: aminoAcidSequenceFields,
    [allSubtypesToName.MICROBIAL_MATERIAL]: microbialMaterialFields,
    [allSubtypesToName.MICROBIAL_STRAIN]: fullMicrobialStrainFields,
    [allSubtypesToName.DESIGN_PART]: designPartFields,
    [allSubtypesToName.DESIGN]: yup.object({
      ...commonFields("design"),
      name: yup.string(),
      design: yup.mixed().required()
    }),
    [allSubtypesToName.EQUIPMENT]: yup.object({
      ...commonFields("equipmentItem"),
      name: yup.string(),
      type: yup.string(),
      locationName: yup.string(),
      equipmentTypeCode: yup.string(),
      shownInPlacementDialogs: yup.boolean(),
      containers: yup.array().of(
        yup.object({
          name: yup.string().required(),
          label: yup.string().required(),
          type: yup.string().required(),
          containers: yup.array().of(
            yup.object({
              name: yup.string().required(),
              label: yup.string().required(),
              type: yup.string().required(),
              positions: yup.array().of(
                yup.object({
                  name: yup.string().required(),
                  label: yup.string().required()
                })
              )
            })
          ),
          positions: yup.array().of(
            yup.object().shape({
              name: yup.string().required(),
              label: yup.string().required()
            })
          )
        })
      )
    }),
    [allSubtypesToName.REACTION_DESIGN]: yup.object({
      ...commonFields("reactionDesign"),
      name: yup.string(),
      version: yup.number(),
      reactionMaps: yup.array(
        yup.object({
          ...reactionMapFields,
          executionOrder: yup.string()
        })
      ),
      ...reactionMapAndDesignFlattenedFields
    }),
    [allSubtypesToName.REACTION_MAP]: yup.object({
      ...commonFields("reactionMap"),
      ...reactionMapFields,
      ...reactionMapAndDesignFlattenedFields
    }),
    [allSubtypesToName.USER_REQUEST]: yup.object({
      ...commonFields("customerRequest"),
      name: yup.string().required(),
      feedback: yup.string(),
      status: yup.string().required(),
      requestType: yup.string().required(),
      primaryCustomer: yup.string(),
      customers: yup.array(yup.string()),
      dueDate: yup.string(),
      strains: yup.array(microbialStrainFields),
      sequences: yup.array(dnaSequenceFields)
    })
  };

  const toRet = validationMap[subtype];
  if (!toRet) {
    throw new Error(
      `You passed an invalid subtype to exampleRecordValidation. subtype: ${subtype}. isUpdateCall: ${isUpdateCall}.`
    );
  }
  return toRet;
};
