/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getParent, types } from "mobx-state-tree";
const FAQStore = types
  .model("FAQStore", {
    isOpen: types.optional(types.boolean, false),
    title: types.optional(types.string, ""),
    msg: types.optional(types.string, ""),
    focus: types.optional(types.boolean, false),
    img: types.optional(types.union(types.string, types.boolean), false),
    fullScreen: types.optional(types.boolean, false)
  })
  .actions(self => ({
    handleOpen() {
      self.isOpen = !self.isOpen;
    },
    handleFullScreen() {
      self.fullScreen = !self.fullScreen;
    }
  }));

const definitionStore = types
  .model("definitionStore", {
    keyWord: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    focus: types.optional(types.boolean, false)
  })
  .actions(self => ({
    handleFocus() {
      self.focus = !self.focus;
    },
    reset() {
      self.focus = false;
    }
  }));

const extraContentStore = types.model("extraContentStore", {
  download: types.optional(types.boolean, false),
  path: types.optional(types.string, ""),
  text: types.optional(types.string, ""),
  icon: types.optional(types.union(types.string, types.boolean), false),
  newTab: types.optional(types.union(types.string, types.boolean), false)
});

const categoryStore = types.model("categoryStore", {
  title: types.optional(types.string, ""),
  code: types.optional(types.string, ""),
  FAQ: types.optional(types.array(FAQStore), []),
  definition: types.optional(types.array(definitionStore), []),
  extraContent: types.optional(types.array(extraContentStore), []),
  index: types.optional(types.boolean, true)
});

export const helperStore = types
  .model("helperStore", {
    isOpen: types.optional(types.boolean, false),
    categories: types.optional(types.map(categoryStore), {}),
    selectedCategory: types.optional(types.string, "")
  })
  .views(self => ({
    getTitle(selectedCategory) {
      return self.categories.get(selectedCategory).title;
    },
    get active() {
      if (self.selectedCategory) {
        return self.categories.get(self.selectedCategory);
      } else {
        return { list: Array.from(self.categories.keys()) };
      }
    }
  }))
  .actions(self => ({
    focus(selectedCategory, keyWord) {
      self.selectedCategory = selectedCategory;
      self.categories.get(selectedCategory).definition.forEach(def => {
        if (def.keyWord === keyWord) {
          def.handleFocus();
          // setTimeout(() => {
          //   def.handleFocus();
          // }, 10000);
        }
      });
      self.index = false;
      self.isOpen = true;
    },
    // Now that this store has been mvoed to shared, refactor this action
    // that is very specific to the TEST Module.
    open(selectedCategory) {
      if (selectedCategory) {
        self.isOpen = true;
        self.index = false;
        self.selectedCategory = selectedCategory;
      } else {
        if (window.location.pathname.includes("importData")) {
          switch (getParent(self).importData.wizard.page) {
            case 0:
              self.isOpen = true;
              self.selectedCategory = "importInitialization";
              break;
            case 1:
              self.isOpen = true;
              self.selectedCategory = "importReformat";
              break;
            case 2:
              self.isOpen = true;
              self.selectedCategory = "importMapping";
              break;
            case 3:
              self.isOpen = true;
              self.selectedCategory = "importReview";
              break;
            default:
              break;
          }
        } else if (window.location.pathname.includes("meta-data")) {
          self.isOpen = true;
          self.index = false;
          self.selectedCategory = "metaData";
        } else {
          self.index = true;
          self.isOpen = true;
        }
      }
    },
    back() {
      if (self.selectedCategory) {
        self.selectedCategory = "";
        self.index = true;
      }
    },
    close() {
      self.isOpen = false;
      self.index = true;
      if (self.selectedCategory) {
        self.categories
          .get(self.selectedCategory)
          .definition.map(def => def.reset());
        self.selectedCategory = "";
      }
      // applySnapshot(self, {});
    }
  }));
