/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { pull } from "lodash";
import { getAllLocationsBetweenCells } from "./getAllLocationsBetweenCells";

export const calculateSelectedLocations = (
  state: {
    activeAliquotContainerLocation: string;
    selectedAliquotContainerLocations: string[];
  },
  { location, shift, ctrl }: { location: string; shift: boolean; ctrl: boolean }
) => {
  const {
    activeAliquotContainerLocation: active,
    selectedAliquotContainerLocations: selection
  } = state;

  let newActive = active || location;
  let newSelection: string[] = [];

  const normalClick = () => {
    newSelection = [location];
    newActive = location;
  };

  if (shift) {
    if (active) {
      newSelection = getAllLocationsBetweenCells(active, location);
      // keep active as the last
      pull(newSelection, active);
      newSelection.push(active);
    } else {
      normalClick();
    }
  } else if (ctrl) {
    if (selection.some(l => l === location)) {
      newSelection = selection.filter(l => l !== location);
    } else {
      newSelection = [...selection, location];
      newActive = location;
    }
  } else {
    if (selection.length === 1 && selection[0] === location) {
      newSelection = [];
    } else {
      normalClick();
    }
  }

  return {
    activeAliquotContainerLocation: newSelection.length ? newActive : null,
    selectedAliquotContainerLocations: newSelection
  };
};
