/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { showConfirmationDialog, showProgressToast } from "@teselagen/ui";
import { Intent } from "@blueprintjs/core";
import modelNameToLink from "./modelNameToLink";
import modelNameToReadableName from "./modelNameToReadableName";
import { safeDelete, safeQuery, safeUpsert } from "../apolloMethods";
import { deleteRecordsHelper as _deleteRecordsHelper } from "../../../tg-iso-shared/src/crudHandlers/deleteUtils";

export function showDeleteAlert({ model, plural, records, ...rest }) {
  let modelName;
  if (model === "strain" && records[0].strainTypeCode === "CELL_LINE") {
    modelName = "cellLine";
  } else if (
    model === "material" &&
    records[0].materialTypeCode === "CELL_CULTURE"
  ) {
    modelName = "cellCulture";
  } else {
    modelName = model;
  }
  const readableName = modelNameToReadableName(modelName, { plural });
  let text = "Are you sure you want to delete ";
  text += plural ? "these" : "this";
  text += ` ${readableName}? You will not be able to restore`;
  text += ` ${plural ? "them" : "it"} later.`;
  return showConfirmationDialog({
    text,
    confirmButtonText: "Delete",
    intent: Intent.DANGER,
    icon: "warning-sign",
    canEscapeKeyCancel: true,
    ...rest
  });
}

export async function deleteRecordsHelper(props) {
  const onWarning = message => {
    return window.toastr.error(message);
  };

  const confirmDeleteAlert = async (message, options) => {
    const { canEscapeKeyCancel, cancelButtonText, confirmButtonText } = options;
    return await showConfirmationDialog({
      text: message,
      canEscapeKeyCancel,
      intent: Intent.DANGER,
      icon: "warning-sign",
      cancelButtonText,
      confirmButtonText
    });
  };

  const onFinish = _props => {
    if (props.history) {
      const { model, records } = _props;
      let route = modelNameToLink(model);
      if (model === "material" && records[0].materialTypeCode === "MICROBIAL") {
        route = "/microbial-materials";
      } else if (
        model === "material" &&
        records[0].materialTypeCode === "PROTEIN"
      ) {
        route = "/protein-materials";
      } else if (
        model === "material" &&
        records[0].materialTypeCode === "CELL_CULTURE"
      ) {
        route = "/cell-cultures";
      } else if (
        model === "strain" &&
        records[0].strainTypeCode === "MICROBIAL_STRAIN"
      ) {
        route = "/microbial-strains";
      } else if (
        model === "strain" &&
        records[0].strainTypeCode === "CELL_LINE"
      ) {
        route = "/cell-lines";
      } else if (
        model === "sequence" &&
        records[0].sequenceTypeCode === "RNA"
      ) {
        route = "/rna-sequences";
      } else if (
        model === "sequence" &&
        records[0].sequenceTypeCode === "GENOMIC_REGION"
      ) {
        route = "/genomic-regions";
      }
      props.history.push(route);
    }
  };

  await _deleteRecordsHelper(
    {
      ...props,
      onWarning,
      confirmDeleteAlert,
      showDeleteAlert,
      showProgressToast,
      onFinish
    },
    { safeDelete, safeQuery, safeUpsert }
  );
}
