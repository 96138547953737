/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { plateLibraryFilter } from "../../../utils/plateUtils";

export const plateFragment = [
  "containerArray",
  "id name barcode { id barcodeString } containerArrayType { id name } updatedAt"
];

const MoveItems = ({
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
}) => (
  <div>
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Select Plates"
        helper="Select one or more plates to move."
      />
      <div>
        <GenericSelect
          name="containerArrays"
          buttonProps={{
            loading: isLoadingMap.containerArrays,
            disabled: isDisabledMap.containerArrays
          }}
          isMultiSelect
          isRequired
          schema={[
            {
              path: "name"
            },
            {
              displayName: "Barcode",
              path: "barcode.barcodeString"
            },
            dateModifiedColumn
          ]}
          tableParamOptions={{
            additionalFilter: plateLibraryFilter
          }}
          fragment={plateFragment}
          postSelectDTProps={{
            formName: "movePlateToolSelectPlates",
            schema: [
              platePreviewColumn(),
              "name",
              {
                displayName: "Barcode",
                path: "barcode.barcodeString"
              },
              dateModifiedColumn
            ]
          }}
        />
      </div>
    </div>
  </div>
);

export default MoveItems;
