/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";
import { endpoints } from "../../../src-test/configs/config.json";
import { Classes, FormGroup, Intent } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { renderInput } from "../../../src-test/views/dialogs/utils";
import schema from "../../../src-test/views/MetaData/schema";
import classNames from "classnames";
import { safeQuery } from "../../apolloMethods";
import { capitalize } from "lodash";

const reducer = (state, { type, payload } = {}) => {
  switch (type) {
    case "ADD_DATA":
      Object.assign(state[payload.key].dataset, { data: payload.data });
      return { ...state };
    case "UPDATE_INPUT_VALUE":
      Object.assign(state[payload.key], {
        ...state[payload.key],
        value: payload.newValue
      });
      return { ...state };
    default:
      return state;
  }
};

const AddMetadataDialog = props => {
  const { modelForm: form, modelName, label: classLabel, hideModal } = props;

  const [inputs, dispatch] = useReducer(reducer, form);
  const [isLoading, setIsLoading] = useState(false);
  const [disableCreate, setDisableCreate] = useState(true);

  useEffect(() => {
    setDisableCreate(
      Object.values(inputs).some(input => input.required && input.value === "")
    );
    // eslint-disable-next-line
  }, [inputs]);

  const saveData = async () => {
    setIsLoading(true);
    await upsertMiddleData().catch(console.error);

    const modelData = {};
    const metaData = {};

    for (const [key, input] of Object.entries(inputs)) {
      if (input.value !== "") modelData[key] = input.value;
    }
    if (modelName === "unit") {
      const { unitDimensionId, ...rest } = modelData;
      metaData[modelName] = rest;
    } else {
      metaData[modelName] = modelData;
    }

    try {
      const response = await window.serverApi.request({
        method: "POST",
        url: endpoints["create-metadata"],
        data: { metaData }
      });
      const res = response.data[0];
      setIsLoading(false);
      if (res.status === "failed") {
        window.toastr.error(`Error adding new ${classLabel}`);
      } else {
        window.toastr.success(
          `${capitalize(res.status)} ${classLabel} '${res.name}'`
        );
        const subClass = { id: res.id, name: res.name };
        await props.onCreate(subClass);
        hideModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const upsertMiddleData = useCallback(async () => {
    switch (modelName) {
      case "measurementType":
        // add measurementTarget and get the ID
        const measurement = await window.serverApi.request({
          method: "POST",
          url: endpoints["create-metadata"],
          data: {
            metaData: { measurementTarget: { name: inputs["name"].value } }
          }
        });
        dispatch({
          type: "UPDATE_INPUT_VALUE",
          payload: {
            key: "measurementTargetId",
            newValue: measurement.data[0].id
          }
        });
        break;
      case "unit":
        // add unitScale and get the ID
        const unit = await window.serverApi.request({
          method: "POST",
          url: endpoints["create-metadata"],
          data: {
            metaData: {
              unitScale: {
                name: inputs["name"].value,
                unitDimensionId: inputs["unitDimensionId"].value
              }
            }
          }
        });
        dispatch({
          type: "UPDATE_INPUT_VALUE",
          payload: { key: "unitScaleId", newValue: unit.data[0].id }
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = useCallback(async inputs => {
    for (const [key, input] of Object.entries(inputs)) {
      if (input?.dataset) {
        const { model, query } = input.dataset;
        const data = await safeQuery([model, query]);

        dispatch({
          type: "ADD_DATA",
          payload: {
            key,
            data: data.map(val => ({ value: val.id, label: val.name }))
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    fetchData(inputs).catch(console.error);
    // eslint-disable-next-line
  }, []);

  const makeRequired = field => {
    field.required = true;
  };
  const makeNotRequired = field => {
    field.required = false;
  };

  return (
    <React.Fragment>
      <div className={classNames(Classes.DIALOG_BODY, "tg-flex auto")}>
        <div>
          {Object.entries(inputs).map(([key, _input], index) => {
            const handleValue = newValue => {
              if (_input.type === "checkboxField") {
                newValue = !_input.value;
              }
              dispatch({
                type: "UPDATE_INPUT_VALUE",
                payload: { key, newValue }
              });
            };

            const input = { ..._input, handleValue };

            if (key === "externalSourceSystemId") {
              /**
               * The "externalSourceSystemId" field depends on the "isExternalReference" type.
               * That's why we need a variable to store a reference of the latter, as the next line does.
               */

              const isExternalReference = Object.entries(inputs).find(
                input => input[0] === "isExternalReference"
              )[1].value;

              if (isExternalReference) {
                makeRequired(input);
              } else {
                makeNotRequired(input);
              }
              return (
                <FormGroup
                  key={index}
                  intent={Intent.PRIMARY}
                  label={input.label}
                  labelInfo={input.required ? "(required)" : ""}
                  disabled={!isExternalReference}
                >
                  {renderInput({ ...input, handleValue }, !isExternalReference)}
                </FormGroup>
              );
            } else {
              return (
                <FormGroup
                  key={index}
                  intent={Intent.PRIMARY}
                  label={input.label}
                  labelInfo={input.required ? "(required)" : ""}
                  style={{
                    display: input.isHidden ? "none" : "inherit"
                  }}
                >
                  {renderInput(input)}
                </FormGroup>
              );
            }
          })}
        </div>
      </div>
      <DialogFooter
        onClick={saveData}
        hideModal={props.hideModal}
        disabled={disableCreate}
        loading={isLoading}
        text="Save"
      />
    </React.Fragment>
  );
};

export default compose(
  withProps(props => {
    const modelName = props.modelName === "d-unit" ? "unit" : props.modelName;
    const {
      [modelName]: { form, label }
    } = schema();
    return { ...props, modelName, modelForm: form, label };
  }),
  wrapDialog(props => ({ title: `Add ${props.label}` })),
  reduxForm({
    form: "createMetaDataFromImportTool"
  })
)(AddMetadataDialog);
