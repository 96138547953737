/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { set, invert, size, pick, isNil } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { isoContext } from "@teselagen/utils";

export const INVALID_DIGEST_PART_MSG =
  "Invalid Digest Part. Make sure any edits to the sequence have not broken the digest ends.";

const DIGEST_PART_FIELD_NAMES = [
  "isDigestPart",
  "isDigestValid",
  "re5PrimeId",
  "re5PrimeOverhang",
  "re5PrimeOverhangStrand",
  "re5PrimeRecognitionTypeCode",
  "re3PrimeId",
  "re3PrimeOverhang",
  "re3PrimeOverhangStrand",
  "re3PrimeRecognitionTypeCode"
];

const DIGEST_PART_EXPORT_FIELD_MAP = {
  isDigestPart: "isDigestPart",
  isDigestValid: "isDigestValid",
  "re5Prime.name": "re5PrimeName",
  "re5Prime.recognitionRegex": "re5PrimePattern",
  re5PrimeOverhang: "re5PrimeOverhang",
  re5PrimeOverhangStrand: "re5PrimeOverhangStrand",
  re5PrimeRecognitionTypeCode: "re5PrimeRecognitionTypeCode",
  "re3Prime.name": "re3PrimeName",
  "re3Prime.recognitionRegex": "re3PrimePattern",
  re3PrimeOverhang: "re3PrimeOverhang",
  re3PrimeOverhangStrand: "re3PrimeOverhangStrand",
  re3PrimeRecognitionTypeCode: "re3PrimeRecognitionTypeCode"
};

const DIGEST_PART_NOTES_TO_FIELDS_PARSER = {
  isDigestPart: noteValue => Boolean(noteValue),
  isDigestValid: noteValue => Boolean(noteValue),
  re5PrimeOverhangStrand: noteValue => Boolean(noteValue),
  re3PrimeOverhangStrand: noteValue => Boolean(noteValue)
};

const getDigestPartRecognitionType = ({ cut }) => {
  const { forward, is5Prime, restrictionEnzyme } = cut;

  const isType2s = restrictionEnzyme.enzymeTypeCode === "TYPE_IIS";

  /**
   * NOTE: we are only working with type2s enzymes at the moment.
   *
   * This means they cannot be 'SPANNING'.
   */
  if (isType2s) {
    /**
     * For Type-IIs enzymes, where their cut sites is always located downstream its recognition site the following is true:
     *
     * - If its 5prime and the recognition is NOT in the forward strand, its INTERNAL
     * - If its 3prime and the recognition is in the forward strand, its INTERNAL
     */
    if (is5Prime ^ forward) return "INTERNAL";

    // Given that Type-IIs cannot be SPANNING, they have to be EXTERNAL at this point.
    return "EXTERNAL";
  }
  throw new Error("Non Type-IIS enzymes not yet supported.");
};

export const getDigestPartRecord = ({ digestInfo }) => {
  const { cut1, cut2, start, end, name, strand, forward, overlapsSelf } =
    digestInfo;
  let fivePrimeProps, threePrimeProps;
  if (cut1 && cut1.restrictionEnzyme.id) {
    const topSnipOffset =
      cut1.restrictionEnzyme.topSnipOffset ||
      cut1.restrictionEnzyme.forwardSnipPosition;
    const bottomSnipOffset =
      cut1.restrictionEnzyme.bottomSnipOffset ||
      cut1.restrictionEnzyme.reverseSnipPosition;

    fivePrimeProps = {
      re5PrimeId: cut1.restrictionEnzyme.id,
      re5PrimeOverhang: cut1.overhangBps,
      re5PrimeOverhangStrand: topSnipOffset < bottomSnipOffset,
      re5PrimeRecognitionStrand: cut1.forward,
      re5PrimeRecognitionTypeCode: getDigestPartRecognitionType({
        cut: { ...cut1, is5Prime: true }
      })
    };
  }

  if (cut2 && cut2.restrictionEnzyme.id) {
    const topSnipOffset =
      cut2.restrictionEnzyme.topSnipOffset ||
      cut2.restrictionEnzyme.forwardSnipPosition;
    const bottomSnipOffset =
      cut2.restrictionEnzyme.bottomSnipOffset ||
      cut2.restrictionEnzyme.reverseSnipPosition;

    threePrimeProps = {
      re3PrimeId: cut2.restrictionEnzyme.id,
      re3PrimeOverhang: cut2.overhangBps,
      re3PrimeRecognitionStrand: cut2.forward,
      re3PrimeOverhangStrand: topSnipOffset > bottomSnipOffset,
      re3PrimeRecognitionTypeCode: getDigestPartRecognitionType({
        cut: { ...cut2, is5Prime: false }
      })
    };
  }

  return {
    overlapsSelf,
    start,
    end,
    name,
    strand: forward || strand ? 1 : -1,
    isDigestPart: true,
    // upon creation of a digest part, it will be valid.
    // But this flag can change to false if the source sequence is changed
    //in a way that affects the initial digest properties.
    isDigestValid: true,
    // right now, we only support single enzyme digestion.
    reSingle: true,
    ...fivePrimeProps,
    ...threePrimeProps
  };
};

export const getDigestPartFields = (part, options = {}) => {
  const { withRestrictionEnzymes } = options;
  if (isNil(part.isDigestPart)) return {};
  const digestPartFields = [
    ...DIGEST_PART_FIELD_NAMES,
    ...(withRestrictionEnzymes ? ["re5Prime", "re3Prime"] : [])
  ];
  return { ...pick(part, digestPartFields) };
};

export const getVeDigestInfo = part => {
  if (isNil(part.isDigestPart)) return {};

  const {
    re5PrimeOverhang,
    re5PrimeOverhangStrand,
    re3PrimeOverhang,
    re3PrimeOverhangStrand
  } = part;

  const oveProps = {
    [`fivePrime${re5PrimeOverhangStrand ? "Over" : "Under"}hang`]:
      re5PrimeOverhang,
    [`threePrime${re3PrimeOverhangStrand ? "Over" : "Under"}hang`]:
      re3PrimeOverhang,
    customName:
      part.name + (part.isDigestValid ? " (digest)" : " (invalid digest)")
  };
  return oveProps;
};

/**
 * This function receives a part annotation an tidies it up
 * by mutating the annotation object to have the proper digest part fields.
 *
 * The digest part fields may come in the part annotation notes (as is the case
 * for data coming from a GenBank file).
 *
 * 5' and 3' Restriction Enzyme info will come in the form of the enzyme's name
 * and/or forward recognition regex, so we also need to find the TG ID of the enzymes.
 */
export const tidyUpDigestPartFields = async (
  annotation,
  options,
  ctx = isoContext
) => {
  const { digestInfoInNotes } = options;

  async function includeDigestPartReIds(part, ctx = isoContext) {
    const { safeQuery } = ctx;
    const { re5Prime, re3Prime } = part;
    let re5PrimeId, re3PrimeId;

    const re5PrimeFilter = [
      ...(re5Prime.name ? [{ name: re5Prime.name }] : []),
      ...(re5Prime.recognitionRegex
        ? [{ recognitionRegex: re5Prime.recognitionRegex }]
        : [])
    ];

    const re3PrimeFilter = [
      ...(re3Prime.name ? [{ name: re3Prime.name }] : []),
      ...(re3Prime.recognitionRegex
        ? [{ recognitionRegex: re3Prime.recognitionRegex }]
        : [])
    ];

    if (re5PrimeFilter.length) {
      const qb = new QueryBuilder("restrictionEnzyme");
      const filter = qb
        .whereAll({ enzymeTypeCode: "TYPE_IIS" })
        .andWhereAny(...re5PrimeFilter);

      const restrictionEnzyme = await safeQuery(
        ["restrictionEnzyme", "id name"],
        { variables: { filter } }
      );

      re5PrimeId = restrictionEnzyme[0]?.id;
    }
    if (re3PrimeFilter.length) {
      const qb = new QueryBuilder("restrictionEnzyme");
      const filter = qb
        .whereAll({ enzymeTypeCode: "TYPE_IIS" })
        .andWhereAny(...re3PrimeFilter);
      const restrictionEnzyme = await safeQuery(
        ["restrictionEnzyme", "id name"],
        { variables: { filter } }
      );

      re3PrimeId = restrictionEnzyme[0]?.id;
    }

    if (!re5PrimeId)
      throw new Error("Invalid Digest Part: 5' restriction enzyme not found");

    if (!re3PrimeId)
      throw new Error("Invalid Digest Part: 3' restriction enzyme not found");

    Object.assign(part, { re5PrimeId, re3PrimeId });
  }

  if (digestInfoInNotes) {
    if (!size(annotation.notes)) return;

    const { notes } = annotation;

    if (!notes.isDigestPart) return;

    const digestPartInvertedFieldMap = invert(DIGEST_PART_EXPORT_FIELD_MAP);
    const digestNoteKeys = Object.keys(digestPartInvertedFieldMap);
    Object.keys(notes)
      .filter(noteKey => digestNoteKeys.includes(noteKey))
      .forEach(digestNoteKey => {
        const path = digestPartInvertedFieldMap[digestNoteKey];
        const fieldParserFn = DIGEST_PART_NOTES_TO_FIELDS_PARSER[digestNoteKey];
        if (fieldParserFn) {
          set(annotation, path, fieldParserFn(notes[digestNoteKey][0]));
        } else {
          set(annotation, path, notes[digestNoteKey][0]);
        }
        // We no longer need the digest info note
        delete notes[digestNoteKey];
      });
  } else {
    if (!annotation?.isDigestPart) return;
    Object.assign(annotation, { ...getDigestPartFields(annotation) });
  }

  await includeDigestPartReIds(annotation, ctx);

  return;
};
