/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import {
  TextareaField,
  InputField,
  DataTable,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import { openInNewTab } from "../utils/generalUtils";
import TagField from "../TagField";
import { throwFormError } from "../utils/formUtils";
import uploadAminoAcidSequences from "../../../tg-iso-shared/src/sequence-import-utils/uploadAminoAcidSequences";
import { setActiveLab } from "../utils/labUtils";
import SequenceFileUpload from "../SequenceFileUpload";
import pluralIfNeeded from "../utils/pluralIfNeeded";

class UploadAminoAcidSequences extends Component {
  state = {
    submittedData: null,
    fileUpload: true
  };

  handleClick = () => {
    this.setState(prevState => ({
      fileUpload: !prevState.fileUpload
    }));
  };

  onSubmit = async values => {
    const { refetch, hideModal } = this.props;
    const { sequenceUpload, sequenceText, sequenceName } = values;

    try {
      const {
        createdAminoAcidSequences,
        existingAminoAcidSequences,
        aminoAcidSequencesAliases
      } = await uploadAminoAcidSequences({
        isFileUpload: this.state.fileUpload,
        sequenceUpload,
        sequenceTexts: [sequenceText],
        sequenceNames: [sequenceName],
        setActiveLab,
        labId: values.labId,
        tags: values.tags
      });
      await refetch();
      if (
        createdAminoAcidSequences.length ||
        existingAminoAcidSequences.length
      ) {
        this.setState({
          submittedData: {
            createdSequences: createdAminoAcidSequences,
            existingSequences: existingAminoAcidSequences,
            sequenceAliases: aminoAcidSequencesAliases
          }
        });
      } else {
        hideModal();
      }
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error uploading sequences");
    }
  };

  renderForm() {
    const { hideModal, handleSubmit, valid, submitting, error } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          {this.state.fileUpload ? (
            <div>
              <div style={{ textAlign: "right" }} className="upload-method">
                <a onClick={this.handleClick}>Paste Sequence</a>
              </div>
              <SequenceFileUpload
                name="sequenceUpload"
                isProtein
                label="Upload Amino Acid Sequences"
              />
            </div>
          ) : (
            <div>
              <div style={{ textAlign: "right" }} className="upload-method">
                <a onClick={this.handleClick}> Upload File</a>
              </div>
              <InputField
                label="Name"
                name="sequenceName"
                placeholder="Custom sequence name (optional)"
              />
              <TextareaField
                label="Sequence"
                name="sequenceText"
                isRequired
                placeholder="Paste your GenBank or FASTA sequence here"
              />
            </div>
          )}
          <TagField />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          disabled={!valid}
          hideModal={hideModal}
          submitting={submitting}
        />
      </form>
    );
  }

  renderTable() {
    const {
      submittedData: { createdSequences, existingSequences, sequenceAliases }
    } = this.state;
    const { hideModal } = this.props;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          {!!existingSequences.length && (
            <React.Fragment>
              <h6 className="header-margin">
                Duplicated {pluralIfNeeded("sequence", existingSequences)}{" "}
                detected and will not be imported.
              </h6>
              <DataTable
                isSimple
                maxHeight={300}
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="aminoAcidExistingSequenceUpload"
                entities={sequenceAliases}
                onDoubleClick={record => {
                  const itemUrl = `/amino-acid-sequences/${record.id}`;
                  openInNewTab(itemUrl);
                }}
              />
            </React.Fragment>
          )}
          <br />
          {!!createdSequences.length && (
            <React.Fragment>
              <h6 className="header-margin">
                {`Created ${
                  createdSequences.length
                } New Amino Acid ${pluralIfNeeded(
                  "Sequence",
                  createdSequences
                )}`}
              </h6>
              <DataTable
                isSimple
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="aminoAcidSequenceUpload"
                entities={createdSequences}
                onDoubleClick={record => {
                  const itemUrl = `/amino-acid-sequences/${record.id}`;
                  openInNewTab(itemUrl);
                }}
              />
            </React.Fragment>
          )}
        </div>
        <DialogFooter noCancel onClick={hideModal} text="OK" />
      </div>
    );
  }

  render() {
    const { submittedData } = this.state;
    return submittedData ? this.renderTable() : this.renderForm();
  }
}

const validate = values => {
  const errors = {};
  if (!values.sequenceUpload)
    errors.sequenceUpload = "At least one sequence file is required";
  return errors;
};

export default compose(
  wrapDialog({
    title: "Upload Amino Acid Sequences"
  }),
  reduxForm({
    form: "UploadAminoAcidSequences",
    validate
  })
)(UploadAminoAcidSequences);
