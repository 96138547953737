/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { doesInternalizationPreferenceHaveErrors } from "../../../components/HierarchicalDesign/DesignInspector/ValidatedJunctionPanel/TypeIIsJunctionPanel/getInternalizationPreferenceErrors";
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import {
  getOverhangAdapterBinId,
  getOverhangNonAdapterBin
} from "../../../components/HierarchicalDesign/DesignInspector/ValidatedJunctionPanel/TypeIIsJunctionPanel/selectors";

export default (state, { payload: { junctionId, values } }, fullState) => {
  if (doesInternalizationPreferenceHaveErrors(values)) return state;

  const { o5, o3 } = values;

  const changeSetsHelper = new ChangeSetsHelper(state);

  for (const fivePrimeCard of [true, false]) {
    const o = fivePrimeCard ? o5 : o3;

    const overhangTypeKey = fivePrimeCard
      ? "fivePrimeCardOverhangTypeCode"
      : "threePrimeCardOverhangTypeCode";
    const boundaryBinKey = fivePrimeCard
      ? "fivePrimeCardEndBinId"
      : "threePrimeCardStartBinId";

    const boundaryBinId = (o === "ADAPTER"
      ? getOverhangAdapterBinId
      : getOverhangNonAdapterBin)(fullState, junctionId, fivePrimeCard);

    if (!boundaryBinId) {
      console.error("Unable to change internalization preferences.");
      return state;
    }

    changeSetsHelper.updatePure("junction", {
      id: junctionId,
      [overhangTypeKey]: o,
      [boundaryBinKey]: boundaryBinId
    });
  }

  // Idk if we need all of the options, but we need a lot of them.
  return changeSetsHelper.execute({
    recomputeElementValidation: true,
    simplifyTrivialValidators: true,
    removeInaccessibleItems: true,
    removeNonsensicalExtraSequences: true,
    updateNumPlaceholders: true,
    recomputeBinValidation: true,
    removeInvalidEugeneRules: true,
    updateInvalidMaterialAvailabilities: state
  });
};
