/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { size, indexOf } from "lodash";
import * as yup from "yup";
import {
  parseCsvString,
  parseCsvFile
} from "../../../../tg-iso-shared/src/utils/fileUtils";

// TODO: These validators could also be used by TEST importer.
// If so, move this validator to tg-iso-shared and import them from there.

/**
 * Main function exported to be used
 * to validate TEST normalized data.
 */
export const dataValidator = async ({
  csvFile,
  csvString,
  validators = {}
}) => {
  let keyedData;
  const parseOptions = {
    header: true,
    /** Not skipping empty lines would be nice to warn users about it.
     * However when the empty line is the last line in the file, this is annoying,
     * because it prevents the user from continuing. Strictly tacking though, CSV standard
     * should NOT have last empty lines, though this is usually ignored.
     * */
    skipEmptyLines: true
  };
  if (csvFile) {
    keyedData = await parseCsvFile(csvFile, parseOptions);
  } else if (csvString) {
    keyedData = parseCsvString(csvString, parseOptions);
  }
  const {
    data,
    meta: { fields: headers }
  } = keyedData;
  const {
    headerValidator,
    columnValidator,
    rowValidator,
    options = {}
  } = validators;
  const headerValidation = validateHeaders(headers, headerValidator, options);
  const columnValidation = validateColumns(headers, data, columnValidator);
  const dataValidation = validateDataRows(headers, data, rowValidator, options);
  const validationErrors = [
    headerValidation.error,
    dataValidation.error,
    columnValidation.error
  ].filter(error => error);
  return {
    isValid: !size(validationErrors),
    validationErrors,
    data
  };
};

/**
 * Validates that the data comes with non-empty headers.
 *
 * TODO: Additional header validations could be implemented here.
 * For instance, a call to the smart mapper could be triggered, and if a header is not
 * recognized, show a warning or something.
 */
const validateHeaders = (headers, customValidator) => {
  let validator = yup.array(yup.string().required());
  if (customValidator) {
    validator = customValidator(validator);
  }
  let validationResult = {};
  try {
    validationResult = validator.validateSync(headers);
  } catch (error) {
    validationResult["error"] = formatHeaderValidationError(error);
    console.error(error.message);
  }
  return validationResult;
};

/**
 * Validates that the data comes with non-empty columns.
 *
 * TODO: Additional column validations could be implemented here.
 */
const validateColumns = (headers, data, customValidator) => {
  const schemaFields = {};
  headers.forEach(header => {
    schemaFields[header] = yup.array(yup.string());
  });

  let validator = yup.array(yup.object().shape(schemaFields));
  let validationResult = {};

  if (customValidator) {
    validator = customValidator(validator);
  }

  try {
    const columns = headers.map(header => ({
      [header]: data.map(row => row[header])
    }));
    validationResult = validator.validateSync(columns);
  } catch (error) {
    validationResult["error"] = formatColumnValidationError({
      ...error,
      headerValues: headers
    });
    console.error(error.message);
  }

  return validationResult;
};

/**
 * Validates that the data comes with data rows
 * and that these have no missing values
 *
 * TODO: Eventually we may allow for empty values and
 * maybe auto-run an imputation process.
 */
const validateDataRows = (headers, data, customValidator, options) => {
  const { row: { allowEmpty = false } = {} } = options;
  const yupHeaders = {};
  headers.forEach(header => {
    yupHeaders[header] = yup.string();
    if (!allowEmpty) {
      yupHeaders[header] = yupHeaders[header].required();
    }
    if (customValidator && customValidator[header]) {
      yupHeaders[header] = customValidator[header](yupHeaders[header]);
    }
  });
  const validator = yup.array(yup.object(yupHeaders)).min(1);
  let validationResult = {};
  try {
    validationResult = validator.validateSync(data);
  } catch (error) {
    validationResult["error"] = formatDataRowsValidationError({
      ...error,
      headerValues: headers
    });
    console.error(error.message);
  }
  return validationResult;
};

const formatHeaderValidationError = validationError => {
  const {
    params: { path },
    message
  } = validationError;
  const columnPosition = Number(path.replace("[", "").replace("]", "")) + 1;
  const columnInfo = !Number.isNaN(columnPosition)
    ? `column:${columnPosition}`
    : "";
  validationError.message = columnInfo + message.replace(path, "");
  return {
    ...validationError,
    name: "HeaderError"
  };
};

const formatDataRowsValidationError = validationError => {
  const {
    params: { path },
    headerValues,
    message
  } = validationError;

  const rowPosition =
    Number(path.split(".")[0].replace("[", "").replace("]", "")) + 2;

  const columnPosition = Number(indexOf(headerValues, path.split(".")[1])) + 1;
  const rowInfo = !Number.isNaN(rowPosition) ? `row:${rowPosition} ` : "";
  const columnInfo = !Number.isNaN(columnPosition)
    ? `column:${columnPosition} `
    : "";
  validationError.message = rowInfo + columnInfo + message.replace(path, "");
  return {
    ...validationError,
    name: "DataRowError"
  };
};

const formatColumnValidationError = validationError => {
  const {
    params: { path },
    message
  } = validationError;
  const columnPosition = Number(path.replace("[", "").replace("]", "")) + 1;
  const columnInfo = !Number.isNaN(columnPosition)
    ? `column:${columnPosition}`
    : "";
  validationError.message = columnInfo + message.replace(path, "");
  return {
    ...validationError,
    name: "ColumnError"
  };
};
