/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import {
  getClassicWidth,
  getClassicHeight,
  getClassicWidthNoButtons,
  getClassicHeightNoButtons
} from "../../../../src-shared/selectors/classicViewSelectors";

import actions from "../../../../src-shared/redux/actions";
import DesignRowIndexContainer from "../../../containers/DesignRowIndexContainer";
import DesignRowName from "../../../containers/DesignRowName";
import DesignRowDisable from "../../../containers/DesignRowDisable";
import "./style.css";
import {
  getClassicNumberOfRows,
  getRootCardBinIds,
  getRootCardId,
  getRowDisableIsShown,
  getRowNamesIsShown,
  getRowsIndexIsShown,
  isLayoutList
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import Bin from "./Bin";
import AddButton from "./AddButton";
import {
  ADD_BUTTON_WIDTH,
  BIN_HEIGHT,
  ADD_BUTTON_HEIGHT,
  COLUMN_WIDTH,
  CONSTRUCT_ANNOTATION_HEIGHT
} from "../../../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";
import { getFeatureToColorMap } from "@teselagen/sequence-utils";
import { getConstructAnnotationsOfCard } from "../../../../src-shared/selectors/designViewSelectors";
import classNames from "classnames";
import { isDesignLocked } from "../../../../src-shared/utils/designUtils/isDesignLocked";

const mapStateToProps = state => {
  const cardId = getRootCardId(state);
  if (!cardId) return { isLoaded: false };
  return {
    cardId,
    constructAnnotations: getConstructAnnotationsOfCard(state, cardId),
    isLoaded: true,
    isLocked: isDesignLocked(state),
    isList: isLayoutList(state),
    svgWidth: getClassicWidth(state),
    svgHeight: getClassicHeight(state),
    svgWidthNoButtons: getClassicWidthNoButtons(state),
    svgHeightNoButtons: getClassicHeightNoButtons(state),
    binIds: getRootCardBinIds(state),
    numberOfRows: getClassicNumberOfRows(state),
    showRowsIndex: getRowsIndexIsShown(state),
    showRowNames: getRowNamesIsShown(state),
    showRowDisable: isLayoutList(state) && getRowDisableIsShown(state)
  };
};

const mapDispatchToProps = {
  openInspector: actions.ui.designEditor.inspector.open,
  setActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  changeFas: actions.design.changeFas,
  insertBin: actions.design.insertBin,
  insertRow: actions.design.insertRow
};

/**
 * The classic view of a design in grid form.
 */
class ClassicGrid extends React.Component {
  static propTypes = {
    /**
     * The id of the root card.
     */
    cardId: PropTypes.string.isRequired,

    /**
     * Has the design been loaded from the database?
     */
    isLoaded: PropTypes.bool.isRequired,

    /**
     * The width of the entire SVG in pixels.
     */
    svgWidth: PropTypes.number.isRequired,

    /**
     * The height of the entire SVG in pixels.
     */
    svgHeight: PropTypes.number.isRequired,

    /**
     * The width of the SVG ignoring the plus-shaped add column button.
     */
    svgWidthNoButtons: PropTypes.number.isRequired,

    /**
     * The height of the SVG ignoring the plus-shaped add row button.
     */
    svgHeightNoButtons: PropTypes.number.isRequired,

    /**
     * An array of the ids of the bins this design contains.
     */
    binIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    /**
     * The number of rows of cells to render.
     */
    numberOfRows: PropTypes.number.isRequired
  };

  handleAddColumnButtonClick = () => {
    const { insertBin, binIds, cardId, isLocked } = this.props;
    if (!isLocked) {
      insertBin({
        cardId,
        neighborBinId: binIds[binIds.length - 1],
        onLeft: false
      });
    }
  };

  handleAddRowButtonClick = () => {
    const { insertRow, isLocked } = this.props;
    if (!isLocked) insertRow({ atEnd: true });
  };

  renderAddColumnButton = () => {
    const { svgWidthNoButtons, isLocked } = this.props;
    if (isLocked) return null;
    return (
      <AddButton
        className="add-column-button"
        transform={`
          translate(
            ${svgWidthNoButtons},
            ${BIN_HEIGHT / 2 - ADD_BUTTON_HEIGHT / 2})
          scale(5,5)
        `}
        onClick={this.handleAddColumnButtonClick}
      />
    );
  };

  renderAddRowButton = () => {
    const {
      svgWidthNoButtons,
      svgHeightNoButtons,
      constructAnnotations,
      isLocked
    } = this.props;
    if (isLocked) return null;
    return (
      <AddButton
        className="add-row-button"
        transform={`
          translate(
            ${(svgWidthNoButtons - ADD_BUTTON_WIDTH) / 2},
            ${svgHeightNoButtons +
              +!!constructAnnotations.length * CONSTRUCT_ANNOTATION_HEIGHT -
              CONSTRUCT_ANNOTATION_HEIGHT})
          scale(5,5)
        `}
        onClick={this.handleAddRowButtonClick}
      />
    );
  };

  renderBins = () => {
    const {
      binIds,
      constructAnnotations,
      state,
      inputCard,
      updateBin,
      designId,
      changeFas
    } = this.props;
    return binIds.map((binId, binIndex) => (
      <Bin
        key={binIndex}
        binId={binId}
        designId={designId}
        binIndex={binIndex}
        hasConstructAnnotations={!!constructAnnotations.length}
        state={state}
        inputCard={inputCard}
        updateBin={updateBin}
        changeFas={changeFas}
      />
    ));
  };

  renderConstructAnnotations() {
    const {
      constructAnnotations = [],
      cardId,
      binIds,
      setActivePanel,
      openInspector
    } = this.props;

    if (!constructAnnotations.length) return null;
    return (
      <g
        className="construct-annotations-classic-container"
        onClick={() => {
          setActivePanel({
            panel: "card"
          });
          openInspector();
        }}
      >
        {constructAnnotations.map(ca => {
          return this.renderConstructAnnotation(ca, cardId, binIds);
        })}
      </g>
    );
  }

  renderConstructAnnotation(ca, cardId, binIds) {
    // Based on the text, this function returns its pixel width.
    const getWidthPx = text => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      context.font = getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    // TODO: although this is quite fast it may not be the best way of computing the best fit for the desired width.
    // Here, the width depends on the type of characters the text contains, so it simply loops through
    // different slices of the text to find the best fit for the desired width,
    const getCharsForWidth = (text, desiredPxWidth) => {
      const textChars = text.length;
      let pxDiff = 0;
      let bestPxDiff = 100000;
      let bestNumChars;
      for (const numChars of Array(textChars).keys()) {
        pxDiff = Math.abs(
          desiredPxWidth - getWidthPx(text.slice(0, numChars + 1))
        );
        if (pxDiff < bestPxDiff) {
          bestPxDiff = pxDiff;
          bestNumChars = numChars + 1;
        }
      }
      return bestNumChars;
    };

    // Logic to compute the display name and position of Construct Annotations
    const displayNameProps = () => {
      // If the CA goes around the design (i.e., its start bin is after its end bin)
      // The CA gets split into to svg rectangles so  a special logic is going to
      // be needed to properly adjust the way the CA template name
      // is displayed (specially for lengthy template names that dont fully fit the available space)
      if (leftBinIndex > rightBinIndex) {
        const rightStartPos = leftBinIndex * COLUMN_WIDTH;
        const spaceAvailableLeft = (rightBinIndex + 1) * COLUMN_WIDTH;
        const spaceAvailableRight =
          (binIds.length - leftBinIndex) * COLUMN_WIDTH;

        // This is just a margin for the text inside the rect.
        const spaceMargin = Math.floor(spaceAvailableRight / 20);

        // The CA text needs to be distributed between the right rect and the left rect
        // so depending on their width we compute this ratio to know where to position the text.
        const rightLeftSpaceRatio =
          spaceAvailableRight / (spaceAvailableRight + spaceAvailableLeft);

        const spaceAvailableRightRated =
          spaceAvailableRight * rightLeftSpaceRatio;
        const spaceAvailableLeftRated =
          spaceAvailableLeft * (1 - rightLeftSpaceRatio);

        // This computes the full CA template name in both char count and pixel width.
        const caFullNameChars = caFullName.length;
        const caFullNameWidth = getWidthPx(caFullName);

        // Left name chars and pixel width
        const caLeftNameWidth = caFullNameWidth * (1 - rightLeftSpaceRatio);
        const caLeftNameChars =
          caLeftNameWidth <= spaceAvailableLeftRated
            ? Math.min(
                getCharsForWidth(
                  caFullName,
                  spaceAvailableLeftRated - ELLIPSIS_WIDTH - spaceMargin
                ),
                Math.floor(caFullNameChars * (1 - rightLeftSpaceRatio))
              )
            : getCharsForWidth(
                caFullName,
                spaceAvailableLeft - ELLIPSIS_WIDTH - spaceMargin
              );

        // Right name chars and pixel width
        const caRightNameChars = caFullNameChars - caLeftNameChars;
        const caRightNameWidth = getWidthPx(
          caFullName.slice(0, caRightNameChars)
        );

        const caRightNameMaxChars = getCharsForWidth(
          caFullName,
          spaceAvailableLeft - ELLIPSIS_WIDTH - 2 * spaceMargin
        );

        const rightNameFitsRatedSpace =
          caRightNameWidth <= spaceAvailableRightRated;

        const rightNameFitsFullSpace = caRightNameWidth <= spaceAvailableRight;

        const rightText = rightNameFitsFullSpace
          ? `${caFullName.slice(0, caRightNameChars)}...`
          : `${caFullName.slice(0, caRightNameMaxChars)}...`;

        const leftText = `...${caFullName.slice(
          caFullNameChars - caLeftNameChars,
          -1
        )}`;

        return [
          {
            pos: {
              x:
                rightNameFitsRatedSpace || rightNameFitsFullSpace
                  ? rightStartPos +
                    spaceAvailableRight -
                    caRightNameWidth -
                    ELLIPSIS_WIDTH -
                    spaceMargin
                  : rightStartPos + spaceMargin,
              y: CONSTRUCT_ANNOTATION_HEIGHT / 2 + 2
            },
            text: rightText
          },
          {
            pos: {
              x: spaceMargin,
              y: CONSTRUCT_ANNOTATION_HEIGHT / 2 + 2
            },
            text: leftText
          }
        ];
      } else {
        const spaceAvailable =
          (rightBinIndex - leftBinIndex + 1) * COLUMN_WIDTH;
        const startPos = leftBinIndex * COLUMN_WIDTH;
        const spaceMargin = Math.floor(spaceAvailable / 20);
        const nameFitsSpace =
          getWidthPx(caFullName) <= spaceAvailable - spaceMargin;
        const caNameChars = getCharsForWidth(
          caFullName,
          spaceAvailable - ELLIPSIS_WIDTH - 2 * spaceMargin
        );
        return {
          pos: {
            x: nameFitsSpace
              ? startPos + spaceAvailable / 2 - getWidthPx(caFullName) / 2
              : leftBinIndex * COLUMN_WIDTH + spaceMargin,
            y: CONSTRUCT_ANNOTATION_HEIGHT / 2 + 2
          },
          text: nameFitsSpace
            ? caFullName
            : `${caFullName.slice(0, caNameChars)}...`
        };
      }
    };

    const ELLIPSIS_WIDTH = getWidthPx("...");
    const leftBinIndex = ca.leftBoundaryBin.index;
    const rightBinIndex = ca.rightBoundaryBin.index;
    const caFullName = ca.annotationTemplate || ca.name;

    const caDisplayName = caFullName; //getCaDisplayName();

    if (leftBinIndex > rightBinIndex) {
      const [leftTextProps, rightTextProps] = displayNameProps();
      return (
        <g key={ca.id + cardId}>
          <rect
            {...{
              x: leftBinIndex * COLUMN_WIDTH,
              y: 0,
              opacity: 0.5,
              stroke: ca.annotationType === "part" ? "purple" : "white",
              strokeWidth: 3,
              width: (binIds.length - leftBinIndex) * COLUMN_WIDTH,
              height: CONSTRUCT_ANNOTATION_HEIGHT - 2,
              fill:
                ca.annotationType === "part"
                  ? "white"
                  : getFeatureToColorMap({ includeHidden: true })[ca.type]
            }}
          />
          <rect
            {...{
              x: 0,
              y: 0,
              opacity: 0.5,
              stroke: ca.annotationType === "part" ? "purple" : "white",
              strokeWidth: 3,
              width: (rightBinIndex + 1) * COLUMN_WIDTH,
              height: CONSTRUCT_ANNOTATION_HEIGHT - 2,
              fill:
                ca.annotationType === "part"
                  ? "white"
                  : getFeatureToColorMap({ includeHidden: true })[ca.type]
            }}
          />
          <text className="construct-annotation-name" {...rightTextProps.pos}>
            {rightTextProps.text}
            <title>Construct Annotation: {caDisplayName}</title>
          </text>
          <text className="construct-annotation-name" {...leftTextProps.pos}>
            {leftTextProps.text}
            <title>Construct Annotation: {caDisplayName}</title>
          </text>
        </g>
      );
    }

    const caWidth = (rightBinIndex - leftBinIndex + 1) * COLUMN_WIDTH;
    const caX = leftBinIndex * COLUMN_WIDTH;
    const textProps = displayNameProps();
    return (
      <g className="classic-construct-annotation" key={ca.id + cardId}>
        <rect
          {...{
            x: caX,
            y: 0,
            opacity: 0.7,
            stroke: ca.annotationType === "part" ? "purple" : "white",
            strokeWidth: 3,
            width: caWidth,
            height: CONSTRUCT_ANNOTATION_HEIGHT - 2,
            fill:
              ca.annotationType === "part"
                ? "white"
                : getFeatureToColorMap({ includeHidden: true })[ca.type]
          }}
        />
        <text className="construct-annotation-name" {...textProps.pos}>
          {textProps.text}
          <title>Construct Annotation: {caDisplayName}</title>
        </text>
      </g>
    );
  }

  render() {
    const {
      svgHeight,
      svgWidth,
      isLoaded,
      showRowsIndex,
      showRowNames,
      showRowDisable,
      isList
    } = this.props;
    if (!isLoaded) return null;
    return (
      <div
        id="classicGridContainer"
        className={classNames("simplified-grid-container", {
          "tg-is-list-design": isList
        })}
      >
        <div className="simplified-grid">
          <div style={{ display: "flex" }}>
            <div style={{ alignSelf: "flex-end" }}>
              {showRowDisable ? <DesignRowDisable {...this.props} /> : null}
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              {showRowsIndex ? (
                <DesignRowIndexContainer {...this.props} />
              ) : null}
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              {showRowNames ? <DesignRowName {...this.props} /> : null}
            </div>

            <div>
              <svg
                className="simplified-grid-svg"
                height={svgHeight}
                width={svgWidth}
              >
                <g className="simplified-grid-parent">
                  {this.renderConstructAnnotations()}
                  {this.renderBins()}
                  {this.renderAddColumnButton()}
                  {this.renderAddRowButton()}
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const DumbClassicGrid = ClassicGrid;

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ClassicGrid
);
