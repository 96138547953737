/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import HierarchicalDesign from "../components/HierarchicalDesign";
import actions from "../../src-shared/redux/actions";
import {
  getSelectedCardId,
  getSelectedBinIds
} from "../../src-shared/selectors/designViewSelectors";
import {
  getDesignNoError,
  isDesignHierarchical,
  treeLayout
} from "../../../tg-iso-design/selectors/designStateSelectors";
import recordViewEnhancer from "../../src-shared/recordViewEnhancer";
import { get } from "lodash";

const mapStateToProps = state => ({
  loading: state.ui.designEditor.general.loading,
  treeLayout: treeLayout(state),
  isInspectorOpen: state.ui.designEditor.inspector.open,
  inspectorWidth: state.ui.designEditor.inspector.width,
  isMaterialAvailabilityTrayOpen:
    state.ui.designEditor.general.isMaterialAvailabilityTrayOpen,
  sbolRibbonHidden: state.ui.designEditor.general.sbolRibbonHidden,
  selectedCardId: getSelectedCardId(state),
  selectedSetIds: getSelectedBinIds(state),
  isDesignHierarchical: isDesignHierarchical(state),
  design: getDesignNoError(state),
  showMinimap: state.ui.designEditor.tree.showMinimap,
  showRowsIndex: state.ui.designEditor.general.showRowsIndex,
  showRowNames: state.ui.designEditor.general.showRowNames,
  showRowDisable: state.ui.designEditor.general.showRowDisable
});

const mapDispatchToProps = {
  setDesign: actions.designIo.setDesign,
  addItems: actions.designIo.addItems,
  fetchFromServer: actions.designIo.fetchFromServer,
  insertBin: actions.design.insertBin
};

const DesignEditContainer = compose(
  recordViewEnhancer(["design", "id name"]), // name is needed for helmet
  connect(mapStateToProps, mapDispatchToProps)
)(props => {
  const designId = get(props, "match.params.id");
  return <HierarchicalDesign {...props} key={designId} designId={designId} />;
});

export default DesignEditContainer;
