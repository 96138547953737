/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useMemo, useState } from "react";
import { compose } from "redux";
import {
  Button,
  Icon,
  Intent,
  Tag,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Tooltip
} from "@blueprintjs/core";
import { EnzymeViewer } from "@teselagen/ove";
import {
  DialogFooter,
  DropdownButton,
  InputField,
  ReactSelectField,
  tgFormValues
} from "@teselagen/ui";
import {
  getCutsitesFromSequence,
  computeDigestFragments
} from "@teselagen/sequence-utils";
import hgql from "graphql-tag";
import withQuery from "../../../../src-shared/withQuery";
import { flatMap, some, cloneDeep } from "lodash";
import { sequenceHasRecognitionSite } from "../../../../../tg-iso-shared/utils/enzymeUtils";
import { reduxForm } from "redux-form";
import { useDebounce } from "use-debounce";
import shortid from "shortid";
import { render } from "mustache";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import { getDigestPartRecord } from "../../../../../tg-iso-shared/utils/digestPartUtils";
import {
  deleteWithQuery,
  safeUpsert,
  useTgQuery
} from "../../../../src-shared/apolloMethods";
import SequencePreview from "../../../../src-shared/SequencePreview";
import appGlobals from "../../../../src-shared/appGlobals";

const GeneratePartsFromDigest = props => {
  const {
    digestingEnzyme: _digestingEnzyme,
    partNamingTemplate: _partNamingTemplate,
    sequences: seqs = [],
    handleSubmit,
    onSubmit: _onSubmit
  } = props;
  const { restrictionEnzymes = [], ...rest } = useTgQuery(
    hgql`
    query restrictionEnzymesQuery($userId: String) {
      restrictionEnzymes(where: {enzymeTypeCode: {_eq: "TYPE_IIS"}, isRecognitionSiteDegenerate: {_eq: false}}, order_by: {favoriteItems_aggregate: {count: desc}}) {
        nodes {
          id
          name
          enzymeTypeCode
          description
          favoriteItems(where: {userId: {_eq: $userId}}) {
            id
            userId
            restrictionEnzymeId
          }
          forwardSnipPosition
          recognitionLength
          recognitionRegex
          recognitionStart
          reverseSnipPosition
          reverseRecognitionRegex
          sequence
          enzymeTypeCode
          topSnipOffset: forwardSnipPosition
          forwardRegex: recognitionRegex
          bottomSnipOffset: reverseSnipPosition
          reverseRegex: reverseRecognitionRegex
          site: sequence
        }
      }
    }    
    `,
    {
      useHasura: true,
      variables: {
        userId: appGlobals.currentUser?.id
      }
    }
  );

  const digestingEnzyme = restrictionEnzymes.find(
    ({ id }) => id === _digestingEnzyme
  );
  const [partNamingTemplate] = useDebounce(_partNamingTemplate, 1000);

  const [
    seqsWD, //seqs with digest info
    setSeqsWD
  ] = useState();

  /**
   * Valid restriction enzymes are those that can actual generate digested parts
   * out of any of the selected sequences.
   *
   * NOTE: if we find this to be innefficient for many selected sequences,
   * we could see if concatenating all of the sequence strings together (or by chunks)
   * reduces the computation time.
   */
  const validRestrictionEnzymes = useMemo(() => {
    // We first narrow down the number of restriction enzymes by filtering
    // out those that do not cleave to any of the sequences.
    const enzymesWithRecognitionSitesInSequences = restrictionEnzymes.filter(
      enzyme =>
        some(seqs, seq =>
          sequenceHasRecognitionSite(
            enzyme,
            seq.fullSequence?.repeat(seq.circular ? 2 : 1)
          )
        )
    );

    // Now we further down-select to the restriction enzymes that can cut any of the sequences.
    const enzymeNamesWithCutsitesInSequences = Array.from(
      seqs.reduce((enzymesAcc, seq) => {
        const results = getCutsitesFromSequence(
          seq.fullSequence,
          seq.circular,
          enzymesWithRecognitionSitesInSequences
        );
        Object.keys(results).forEach(enzymeName => enzymesAcc.add(enzymeName));
        return enzymesAcc;
      }, new Set())
    );

    const validEnzymes = enzymesWithRecognitionSitesInSequences.filter(enzyme =>
      enzymeNamesWithCutsitesInSequences.includes(enzyme.name)
    );

    return cloneDeep(validEnzymes);
  }, [restrictionEnzymes, seqs]);

  useEffect(() => {
    (async () => {
      if (!seqs || !digestingEnzyme) return;
      const sds = seqs.map(s => {
        const { fullSequence, circular } = s;
        const cutsitesByName = getCutsitesFromSequence(fullSequence, circular, [
          digestingEnzyme
        ]);
        const allCutsites = flatMap(cutsitesByName).map(c => ({
          ...c,
          id: shortid()
        }));
        const { fragments } = computeDigestFragments({
          circular,
          cutsites: allCutsites,
          sequenceLength: fullSequence.length,
          includeOverAndUnderHangs: true
        });

        return {
          ...s,
          cutsites: allCutsites,
          digestFrags: fragments.map((d, i) => {
            let isLargestNonSeqTermInclusive = !d.isFormedFromLinearEnd;
            fragments.forEach((d2, i2) => {
              if (i === i2) return;
              if (d2.size > d.size && !d2.isFormedFromLinearEnd)
                isLargestNonSeqTermInclusive = false;
            });

            d.forward = true;
            if (isLargestNonSeqTermInclusive) {
              return {
                ...d,
                isSelected: true
              };
            }
            return d;
          })
        };
      });
      setNamingTemplate({
        seqsWD: {
          withFrags: sds.filter(({ digestFrags }) => digestFrags.length),
          noFrags: sds.filter(({ digestFrags }) => !digestFrags.length)
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [digestingEnzyme]);

  useEffect(
    () => setNamingTemplate({ seqsWD }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partNamingTemplate]
  );

  const setNamingTemplate = ({ seqsWD }) => {
    if (!seqsWD) return;

    setSeqsWD({
      ...seqsWD,
      withFrags: seqsWD.withFrags.map(s => ({
        ...s,
        digestFrags: s.digestFrags.map(d => {
          let name = "Template Error";
          try {
            name = render(partNamingTemplate || "", {
              sequence_id: s.id,
              sequence_name: s.name,
              size: d.size,
              start: d.start,
              end: d.end,
              enzyme_name: digestingEnzyme.name
            });
          } catch (error) {
            console.error(`error setting naming template:`, error);
          }
          return {
            ...d,
            name
          };
        })
      }))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const selectDigestFrags = fn =>
    setSeqsWD({
      ...seqsWD,
      withFrags: seqsWD.withFrags.map(f => {
        return {
          ...f,
          digestFrags: fn(f.digestFrags)
        };
      })
    });

  if (!validRestrictionEnzymes) {
    return <div />;
  }

  const onSubmit = defaultAsyncWrap(async () => {
    const digestPartsToCreate = [];
    seqsWD.withFrags.forEach(({ id, digestFrags }) => {
      digestFrags.forEach(digestFrag => {
        if (digestFrag.isSelected) {
          const digestPart = getDigestPartRecord({
            digestInfo: digestFrag
          });
          digestPartsToCreate.push({
            ...digestPart,
            sequenceId: id
          });
        }
      });
    });

    const digestParts = await safeUpsert(
      ["part", "id name sequenceId"],
      digestPartsToCreate
    );

    window.toastr.success(
      `${numNewParts} Part${numNewParts === 1 ? "" : "s"} Generated`
    );

    await _onSubmit(digestParts);
  }, "Error generating parts from enzyme digest.");

  // Maybe move to a state
  const numNewParts = seqsWD
    ? seqsWD.withFrags.reduce((acc, { digestFrags }) => {
        return acc + digestFrags.filter(d => d.isSelected).length;
      }, 0)
    : 0;

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  return (
    <React.Fragment>
      <form name="generatePartsFromDigest">
        <h3 style={{ marginLeft: 0 }}>
          1. Choose Your Enzyme and Naming Template
        </h3>
        <ReactSelectField
          inlineLabel
          id="digestingEnzyme"
          name="digestingEnzyme"
          withFavorites
          label={<div style={{ marginRight: 25 }}>Digesting Enzyme</div>}
          placeholder="Choose digesting enzyme..."
          popoverProps={{
            onClose: () => {
              // This re-triggers the enzyme query, so
              // favorite enzyme changes are immediately reflected.
              rest.refetch();
            }
          }}
          options={validRestrictionEnzymes.map(r => ({
            label: (
              <div>
                <div style={{ display: "flex" }}>
                  <FavoriteComp r={r} />
                  {r.name}{" "}
                  {r.enzymeTypeCode === "TYPE_IIS" ? (
                    <Tag
                      style={{ marginLeft: 5, marginRight: 5 }}
                      intent="primary"
                    >
                      Type 2S
                    </Tag>
                  ) : null}{" "}
                  {r.forwardSnipPosition === r.reverseSnipPosition ? (
                    <Tag
                      style={{ marginLeft: 5, marginRight: 5 }}
                      intent="primary"
                    >
                      Blunt
                    </Tag>
                  ) : null}
                </div>
                <EnzymeViewer
                  {...{
                    ...r,
                    noCopy: true,
                    paddingEnd: "----------",
                    paddingStart: "----------"
                  }}
                ></EnzymeViewer>
              </div>
            ),
            value: r.id
          }))}
        />
        <InputField
          inlineLabel
          name="partNamingTemplate"
          label={
            <div style={{ display: "inline-flex" }}>
              Naming template
              <div style={{ marginTop: "-.5em" }}>
                <Tooltip
                  content={
                    <div style={{ maxWidth: 500 }}>
                      Available template variables are sequence_name,
                      sequence_id, enzyme_name, size, start and end.
                      {` Type them in surrounded by double braces to use them, e.g. {{sequence_name}}`}
                    </div>
                  }
                  position={Position.TOP_RIGHT}
                >
                  <Icon
                    icon="help"
                    intent={Intent.PRIMARY}
                    style={{ marginLeft: "1em" }}
                  />
                </Tooltip>
              </div>
            </div>
          }
          defaultValue="{{sequence_name}}>{{enzyme_name}}"
        />
        <br></br>
        <br></br>
        <h3 style={{ marginLeft: 0 }}>2. Select Parts to Create</h3>
        {digestingEnzyme ? (
          seqsWD ? (
            <>
              <div style={{ display: "flex", marginBottom: 10 }}>
                <div style={{ marginRight: 45 }}>
                  <div style={{ fontSize: 16 }}>
                    No Digest Fragments Detected
                  </div>
                  <div style={{ fontSize: 11 }}>
                    (These sequences will be ignored)
                  </div>

                  <div
                    style={{ marginTop: 10, maxHeight: 500, overflowY: "auto" }}
                  >
                    {seqsWD.noFrags.map(({ name, id, digestFrags }) => {
                      return (
                        <div
                          style={{
                            padding: 5,
                            border: "1px solid lightgray",
                            borderRadius: 5,
                            margin: 5
                          }}
                          key={id}
                        >
                          {name} - {digestFrags.length} Fragments
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <div style={{ fontSize: 16 }}>
                        Digest Fragments Detected
                      </div>
                      <div style={{ fontSize: 11 }}>
                        (Select which fragments you'd like to convert to parts)
                      </div>
                    </div>
                    <DropdownButton
                      style={{ marginLeft: 10 }}
                      text="Select..."
                      disabled={!seqsWD.withFrags?.length}
                      intent="primary"
                      menu={
                        <Menu>
                          <MenuItem
                            text="Select Smallest Fragments"
                            onClick={() => {
                              selectDigestFrags(frags => {
                                return frags.map((f, i) => {
                                  let isSmallest = true;
                                  frags.forEach((d2, i2) => {
                                    if (i === i2) return;
                                    if (d2.size < f.size) isSmallest = false;
                                  });
                                  return {
                                    ...f,
                                    isSelected: isSmallest
                                  };
                                });
                              });
                            }}
                          />
                          <MenuItem
                            text="Select Largest Fragments"
                            onClick={() => {
                              selectDigestFrags(frags => {
                                return frags.map((f, i) => {
                                  let isLargest = true;
                                  frags.forEach((d2, i2) => {
                                    if (i === i2) return;
                                    if (d2.size > f.size) isLargest = false;
                                  });
                                  return {
                                    ...f,
                                    isSelected: isLargest
                                  };
                                });
                              });
                            }}
                          />
                          <MenuDivider></MenuDivider>
                          <MenuItem
                            text={
                              <div>
                                Select Only Single Fragment Digests{" "}
                                <div style={{ fontSize: 10 }}>
                                  (Ignores Seq Term Fragments)
                                </div>
                              </div>
                            }
                            onClick={() => {
                              selectDigestFrags(frags => {
                                return frags.map(f => ({
                                  ...f,
                                  isSelected:
                                    frags.filter(f => !f.isFormedFromLinearEnd)
                                      .length === 1 && !f.isFormedFromLinearEnd
                                }));
                              });
                            }}
                          />
                          <MenuDivider></MenuDivider>
                          {/* <MenuItem
                            text="Revert To Initial Selection"
                            onClick={() => {}}
                          /> */}
                          <MenuDivider></MenuDivider>
                          <MenuItem
                            text="Select All"
                            onClick={() => {
                              selectDigestFrags(frags => {
                                return frags.map(f => ({
                                  ...f,
                                  isSelected: true
                                }));
                              });
                            }}
                          />
                          <MenuItem
                            text="Deselect All"
                            onClick={() => {
                              selectDigestFrags(frags => {
                                return frags.map(f => ({
                                  ...f,
                                  isSelected: false
                                }));
                              });
                            }}
                          />
                        </Menu>
                      }
                    />
                  </div>
                  <div
                    style={{ marginTop: 10, maxHeight: 500, overflowY: "auto" }}
                  >
                    {seqsWD.withFrags.map(
                      (
                        {
                          name,
                          id,
                          isExpanded,
                          digestFrags,
                          fullSequence,
                          cutsites,
                          circular
                        },
                        i0
                      ) => {
                        return (
                          <div
                            className="seqWithDigest"
                            style={{
                              padding: 5,
                              border: "1px solid lightgray",
                              borderRadius: 5,
                              margin: 5
                            }}
                            key={id}
                          >
                            <Button
                              style={{ marginRight: 5 }}
                              minimal
                              small
                              onClick={() => {
                                setSeqsWD({
                                  ...seqsWD,
                                  withFrags: seqsWD.withFrags.map((f, f0) => {
                                    if (i0 !== f0) return f;
                                    return {
                                      ...f,
                                      isExpanded: !f.isExpanded
                                    };
                                  })
                                });
                              }}
                              icon={isExpanded ? "caret-down" : "caret-right"}
                            ></Button>
                            {name} - {digestFrags.length} Fragment
                            {digestFrags.length === 1 ? "" : "s"} -{" "}
                            {digestFrags.map((d, i1) => {
                              return (
                                <Tag
                                  intent={d.isSelected ? "primary" : undefined}
                                  style={{ margin: 2, cursor: "pointer" }}
                                  key={i1}
                                  onClick={() => {
                                    setSeqsWD({
                                      ...seqsWD,
                                      withFrags: seqsWD.withFrags.map(
                                        (f, f0) => {
                                          if (i0 !== f0) return f;
                                          return {
                                            ...f,
                                            digestFrags: digestFrags.map(
                                              (df, f1) => {
                                                if (i1 !== f1) return df;
                                                return {
                                                  ...df,
                                                  isSelected: !df.isSelected
                                                };
                                              }
                                            )
                                          };
                                        }
                                      )
                                    });
                                  }}
                                >
                                  {d.size}
                                  {d.isFormedFromLinearEnd && (
                                    <Tooltip
                                      content={
                                        <div style={{ maxWidth: 200 }}>
                                          This fragment includes a non-digested
                                          end (blunt end sequence terminus)
                                        </div>
                                      }
                                    >
                                      <Icon
                                        style={{ marginLeft: 5 }}
                                        icon="warning-sign"
                                      ></Icon>
                                    </Tooltip>
                                  )}
                                  <Icon
                                    style={{ marginLeft: 5 }}
                                    icon={!d.isSelected ? "blank" : "tick"}
                                  ></Icon>
                                </Tag>
                              );
                            })}
                            {isExpanded && (
                              <SequencePreview
                                {...{
                                  circular,
                                  fullSequence,
                                  parts: digestFrags.filter(d => d.isSelected),
                                  cutsites: cutsites.filter(
                                    c => c.name !== "Sequence_Terminus"
                                  ),
                                  id
                                }}
                              ></SequencePreview>
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={{ margin: 10 }}> Loading Sequence Data...</div>
          )
        ) : (
          "Please Choose an Enzyme to View the Generated Parts"
        )}
      </form>
      <DialogFooter
        secondaryText="Cancel"
        disabled={
          !numNewParts || _partNamingTemplate !== partNamingTemplate //wait for the debounce to run
        }
        text={`Create ${numNewParts} New Part${numNewParts === 1 ? "" : "s"}`}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
};

export default compose(
  withQuery(["sequence", "id name fullSequence circular"], {
    isPlural: true,
    options: props => {
      return {
        variables: {
          filter: {
            id: props.sequenceIds
          }
        }
      };
    }
  }),
  reduxForm({
    form: "generatePartsFromDigest",
    validate: values => {
      const errors = {};
      if (!values.digestingEnzyme) {
        errors.digestingEnzyme = "Required";
      }
      if (!values.partNamingTemplate) {
        errors.partNamingTemplate = "Required";
      }
      return errors;
    }
  }),
  tgFormValues("digestingEnzyme", "partNamingTemplate")
)(GeneratePartsFromDigest);

//tnw - maybe this component can be genericized in the future for selecting other favorites..
const FavoriteComp = ({ r }) => {
  const [favoriteItem, setFavoriteItem] = useState(!!r.favoriteItems?.[0]);
  return (
    <Icon
      className={`tg-favorite-icon ${
        favoriteItem ? "tg-favorite-icon-active" : "tg-favorite-icon-inactive"
      }`}
      style={{ marginRight: 5 }}
      onClick={async e => {
        e.stopPropagation();
        e.preventDefault();
        setFavoriteItem(!favoriteItem);
        favoriteItem
          ? await deleteWithQuery("favoriteItem", {
              restrictionEnzymeId: r.id,
              userId: appGlobals.currentUser?.id
            })
          : await safeUpsert("favoriteItem", { restrictionEnzymeId: r.id });
        r.favoriteItems = favoriteItem
          ? undefined
          : [
              {
                restrictionEnzymeId: r.id,
                userId: appGlobals.currentUser?.id
              }
            ];
        // r.needsRequery = true;
      }}
      data-tip={favoriteItem ? "Remove Favorite" : "Make Favorite"}
      icon={favoriteItem ? "star" : "star-empty"}
    ></Icon>
  );
};
