/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { identity } from "lodash";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function convertMicrobialMaterialGqlJsonToIntegrationJson(r, options) {
  return {
    name: r.name,
    ...(r.strain && { strain: options.MICROBIAL_STRAIN.convert(r.strain) }),
    plasmids: r.microbialMaterialMicrobialMaterialPlasmids
      .map(({ polynucleotideMaterial }) => {
        if (!polynucleotideMaterial.polynucleotideMaterialSequence) return null;
        return options.DNA_SEQUENCE.convert(
          polynucleotideMaterial.polynucleotideMaterialSequence,
          options
        );
      })
      .filter(identity)
  };
}
