/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { withSelectedEntities } from "@teselagen/ui";
import { withProps } from "recompose";

import { times, get, set } from "lodash";
import shortid from "shortid";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import {
  SelectOperationAndInputPlates,
  PlateMappings,
  ReviewWorklist
} from "./Steps";
import {
  breakableFormats,
  plateReformatSourcePlateFragment
} from "./Steps/SelectOperationAndInputPlates";

import { getNumberOfCombinationDestinationPlates } from "./utils";
import { REQUIRED_ERROR } from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";
import { createWorklistExtPropEntities } from "../../../../src-shared/utils/extendedPropertyUtils";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { generateEmptyWells } from "../../../../../tg-iso-lims/src/utils/plateUtils";

class PlateReformatTool extends Component {
  onSubmit = async values => {
    try {
      const {
        sourcePlates,
        sourcePlateFormat,
        destinationPlateFormat,
        operationType,
        worklist,
        worklistName,
        toBeCreatedDestinationPlates,
        toBeCreatedReplicatePlates,
        sourcePlateIds = [],
        newOrExistingReplicates = {},
        replicationColumnPlates = {},
        replicationCollectionPlates = {},
        destinationPlateIds = [],
        destinationPlateCids = [],
        newOrExistingDestinationPlates,
        destinationContainerArrayExtendedValues = [],
        destinationAliquotExtendedValues = [],
        sourceContainerArrayExtendedValues = [],
        sourceAliquotExtendedValues = [],
        newColumnPlateInfo = {},
        sourceToDestinationPlateMap = {},
        newOrExistingCollectionPlates = {},
        newCollectionPlateInfo = {}
      } = values;
      const {
        destinationContainerArrayExtendedPropertiesToRemoveSelectedEntities: destinationContainerArrayPropsToRemove = [],
        destinationAliquotExtendedPropertiesToRemoveSelectedEntities: destinationAliquotPropsToRemove = [],
        sourceContainerArrayExtendedPropertiesToRemoveSelectedEntities: sourceContainerArrayPropsToRemove = [],
        sourceAliquotExtendedPropertiesToRemoveSelectedEntities: sourceAliquotPropsToRemove = []
      } = this.props;
      const allDestinationPlateIds = destinationPlateIds.concat(
        destinationPlateCids.map(cid => `&${cid}`)
      );

      const newCollectionPlates = [];
      const columnPlateUpdates = [];

      const makeCollectionPlateForColumnPlate = ({
        columnPlate,
        index,
        newOrExistingCollectionPlatesForPlate,
        replicationCollectionPlatesForPlate,
        newCollectionPlateInfoForPlate
      }) => {
        const formKey = columnPlate.id
          ? "id" + columnPlate.id
          : "index" + index;
        const newOrExistingCollectionPlate =
          newOrExistingCollectionPlatesForPlate[formKey];
        const columnPlateId = columnPlate.id || `&${columnPlate.cid}`;
        if (newOrExistingCollectionPlate === "EXISTING") {
          columnPlateUpdates.push({
            id: columnPlateId,
            collectionPlateId: replicationCollectionPlatesForPlate[formKey].id
          });
        } else {
          const newCollectionPlateInfoForKey =
            newCollectionPlateInfoForPlate[formKey];
          const collectionPlateCid = shortid();
          newCollectionPlates.push({
            cid: collectionPlateCid,
            name: newCollectionPlateInfoForKey.name,
            barcode: newCollectionPlateInfoForKey.generateBarcode
              ? null
              : {
                  barcodeString: newCollectionPlateInfoForKey.barcode
                },
            containerArrayTypeId: newCollectionPlateInfoForKey.plateType.id,
            aliquotContainers: generateEmptyWells(
              newCollectionPlateInfoForKey.plateType.containerFormat,
              {
                aliquotContainerTypeCode:
                  newCollectionPlateInfoForKey.plateType
                    .aliquotContainerTypeCode
              }
            )
          });
          columnPlateUpdates.push({
            id: columnPlateId,
            collectionPlateId: `&${collectionPlateCid}`
          });
        }
      };

      const handleColumnAndCollectionPlates = ({
        newOrExisting,
        sourcePlateKey,
        newColumnPlates = []
      }) => {
        let columnPlates = [];
        if (
          newOrExisting === "EXISTING_COLUMN" &&
          replicationColumnPlates[sourcePlateKey]
        ) {
          columnPlates = replicationColumnPlates[sourcePlateKey];
        } else if (
          newOrExisting === "NEW_COLUMN" &&
          newColumnPlateInfo[sourcePlateKey]
        ) {
          columnPlates = newColumnPlates;
        }
        const newOrExistingCollectionPlatesForPlate =
          newOrExistingCollectionPlates[sourcePlateKey] || {};
        const newCollectionPlateInfoForPlate =
          newCollectionPlateInfo[sourcePlateKey];
        const replicationCollectionPlatesForPlate =
          replicationCollectionPlates[sourcePlateKey];
        columnPlates.forEach((columnPlate, index) => {
          makeCollectionPlateForColumnPlate({
            columnPlate,
            index,
            newOrExistingCollectionPlatesForPlate,
            newCollectionPlateInfoForPlate,
            replicationCollectionPlatesForPlate
          });
        });
      };

      sourcePlateIds.forEach(sourcePlateId => {
        const sourcePlateKey = "id" + sourcePlateId;
        handleColumnAndCollectionPlates({
          newOrExisting: newOrExistingReplicates[sourcePlateKey],
          sourcePlateKey,
          newColumnPlates: sourceToDestinationPlateMap[sourcePlateId] || []
        });
      });

      if (operationType === "COMBINATION") {
        const numDestinationPlates = getNumberOfCombinationDestinationPlates({
          sourcePlateFormat,
          sourcePlates,
          destinationPlateFormat
        });
        times(numDestinationPlates, i => {
          const sourcePlateKey = "index" + i;
          handleColumnAndCollectionPlates({
            newOrExisting: newOrExistingDestinationPlates,
            sourcePlateKey,
            newColumnPlates: toBeCreatedDestinationPlates || []
          });
        });
      }

      let createdOrUpdatedContainerArrays;
      let updatedColumnPlates;
      let finalizedPlates;
      if (
        toBeCreatedDestinationPlates &&
        toBeCreatedDestinationPlates.length > 0
      ) {
        finalizedPlates = stripFields(toBeCreatedDestinationPlates, [
          "containerArrayType"
        ]);
        createdOrUpdatedContainerArrays = await safeUpsert(
          "containerArray",
          finalizedPlates
        );
        const platesToBarcode = [];
        finalizedPlates.forEach((plate, i) => {
          if (!plate.barcode) {
            platesToBarcode.push(createdOrUpdatedContainerArrays[i]);
          }
        });
        await addBarcodesToRecords(platesToBarcode);
      }
      if (toBeCreatedReplicatePlates && toBeCreatedReplicatePlates.length > 0) {
        finalizedPlates = stripFields(toBeCreatedReplicatePlates, [
          "containerArrayType"
        ]);
        createdOrUpdatedContainerArrays = await safeUpsert(
          "containerArray",
          finalizedPlates
        );
        const platesToBarcode = [];
        toBeCreatedReplicatePlates.forEach((plate, i) => {
          if (!plate.barcode) {
            platesToBarcode.push(createdOrUpdatedContainerArrays[i]);
          }
        });
        await addBarcodesToRecords(platesToBarcode);
      }
      if (newCollectionPlates.length) {
        const createdCollectionPlates = await safeUpsert(
          "containerArray",
          newCollectionPlates
        );
        const platesToBarcode = [];
        newCollectionPlates.forEach((plate, i) => {
          if (!plate.barcode) {
            platesToBarcode.push(createdCollectionPlates[i]);
          }
        });
        await addBarcodesToRecords(platesToBarcode);
      }
      if (columnPlateUpdates.length) {
        updatedColumnPlates = await safeUpsert(
          "containerArray",
          columnPlateUpdates
        );
      }
      const worklistToUpsert = {};
      const worklistTransfers = [];
      let worklistContainerArrays = [];
      if (
        sourceContainerArrayPropsToRemove.length ||
        sourceContainerArrayExtendedValues.length
      ) {
        worklistContainerArrays = worklistContainerArrays.concat(
          createWorklistExtPropEntities({
            model: "containerArray",
            propsToRemove: sourceContainerArrayPropsToRemove,
            destIds: sourcePlateIds,
            extendedValues: sourceContainerArrayExtendedValues
          })
        );
      }
      if (
        destinationContainerArrayPropsToRemove.length ||
        destinationContainerArrayExtendedValues.length
      ) {
        worklistContainerArrays = worklistContainerArrays.concat(
          createWorklistExtPropEntities({
            model: "containerArray",
            propsToRemove: destinationContainerArrayPropsToRemove,
            destIds: allDestinationPlateIds,
            extendedValues: destinationContainerArrayExtendedValues
          })
        );
      }

      worklist.worklistTransfers.forEach(
        ({
          volume,
          volumetricUnitCode,
          destinationAliquotContainerId,
          sourceAliquotContainerId
        }) => {
          let worklistTransferAliquotContainers = [];
          if (
            sourceAliquotPropsToRemove.length ||
            sourceAliquotExtendedValues.length
          ) {
            worklistTransferAliquotContainers = worklistTransferAliquotContainers.concat(
              createWorklistExtPropEntities({
                model: "aliquotContainer",
                propsToRemove: sourceAliquotPropsToRemove,
                destIds: [sourceAliquotContainerId],
                extendedValues: sourceAliquotExtendedValues,
                joinTableName: "transferExtendedProperty",
                dataItemTypeCode: "WORKLIST_TRANSFER_ALIQUOT_CONTAINER",
                additionalFields: {
                  isTargetAliquot: true
                }
              })
            );
          }
          if (
            destinationAliquotPropsToRemove.length ||
            destinationAliquotExtendedValues.length
          ) {
            worklistTransferAliquotContainers = worklistTransferAliquotContainers.concat(
              createWorklistExtPropEntities({
                model: "aliquotContainer",
                propsToRemove: destinationAliquotPropsToRemove,
                destIds: [destinationAliquotContainerId],
                extendedValues: destinationAliquotExtendedValues,
                joinTableName: "transferExtendedProperty",
                dataItemTypeCode: "WORKLIST_TRANSFER_ALIQUOT_CONTAINER",
                additionalFields: {
                  isTargetAliquot: true
                }
              })
            );
          }
          worklistTransfers.push({
            volume,
            volumetricUnitCode,
            destinationAliquotContainerId,
            sourceAliquotContainerId,
            worklistTransferAliquotContainers
          });
        }
      );
      worklistToUpsert.name = worklistName;
      worklistToUpsert.worklistContainerArrays = worklistContainerArrays;
      worklistToUpsert.worklistTransfers = worklistTransfers;
      const [createdWorklist] = await safeUpsert("worklist", worklistToUpsert);
      window.toastr.success("Successfully created worklist.");
      return {
        worklist: createdWorklist,
        containerArrays: createdOrUpdatedContainerArrays,
        columnPlates: updatedColumnPlates
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating worklist."
      });
    }
  };

  validate = values => {
    const {
      sourcePlates,
      operationType,
      universalTransfers,
      universalTransferVolume,
      transferVolumes,
      newOrExistingReplicates = {},
      breakdownPatterns = {},
      destinationPlates,
      combinationDestinationPlates = [],
      consolidationDestinationPlate,
      replicationColumnPlates = {},
      replicationCollectionPlates = {},
      sourcePlateFormat,
      destinationPlateFormat,
      transferVolume,
      combinationPattern,
      replicatePlateInfo = {},
      newOrExistingDestinationPlates,
      replicateFormats = {},
      everyOtherColumn = {},
      everyOtherRow = {},
      applyUniversalBreakdownPattern,
      universalNewOrExistingDestinationPlates,
      universalDestinationFormat,
      universalEveryOtherColumn,
      universalEveryOtherRow
    } = values;
    const errors = {};
    if (
      (operationType === "COMBINATION" || operationType === "CONSOLIDATION") &&
      !sourcePlateFormat
    ) {
      errors.sourcePlateFormat =
        "Must specify a format for your source plates.";
    }
    if (operationType === "COMBINATION" && !destinationPlateFormat) {
      errors.destinationPlateFormat =
        "Must specify a destination plate format.";
    }
    if (
      (operationType === "COMBINATION" || operationType === "CONSOLIDATION") &&
      !transferVolume
    ) {
      errors.transferVolume = "Must enter desired transfer volume.";
    }
    if (operationType === "COMBINATION" && !combinationPattern) {
      errors.combinationPattern = "Must specify a combination pattern.";
    }
    if (sourcePlates && sourcePlates.length) {
      if (
        operationType === "CONSOLIDATION" ||
        operationType === "COMBINATION"
      ) {
        const firstFormat =
          sourcePlates[0].containerArrayType.containerFormatCode;
        const allSameFormat = sourcePlates.every(plate => {
          return plate.containerArrayType.containerFormatCode === firstFormat;
        });
        if (!allSameFormat) {
          errors.sourcePlates =
            "Cannot combine or consolidate input plates of different formats";
        }
      }

      if (universalTransfers && !universalTransferVolume) {
        errors.universalTransferVolume =
          "Please enter a universal transfer volume or specify transfer volumes for each input plate below.";
      }
      if (!universalTransfers) {
        sourcePlates.forEach(plate => {
          if (!transferVolumes || !transferVolumes["id" + plate.id]) {
            if (!errors.transferVolumes) errors.transferVolumes = {};
            errors.transferVolumes["id" + plate.id] =
              "Please enter a transfer volume for this input plate.";
          }
        });
      }
      if (operationType === "BREAKDOWN") {
        let unbreakablePlateFormatError = "";
        sourcePlates.forEach(plate => {
          if (!breakdownPatterns || !breakdownPatterns["id" + plate.id]) {
            if (!errors.breakdownPatterns) errors.breakdownPatterns = {};
            errors.breakdownPatterns["id" + plate.id] =
              "Please select a breakdown pattern for this input plate.";
          }

          // validate that the source plate format can be broken down
          if (
            !breakableFormats.includes(
              plate.containerArrayType.containerFormatCode
            )
          ) {
            unbreakablePlateFormatError += `\n${plate.name}: plate type ${plate.containerArrayType.name} can not be broken down.`;
          }
        });
        errors.sourcePlates = unbreakablePlateFormatError;
      }

      const makeCollectionAndColumnPlateError = plateKey => {
        const replicationCollectionPlatesForPlate =
          replicationCollectionPlates[plateKey] || {};
        if (
          !replicationColumnPlates[plateKey] ||
          replicationColumnPlates[plateKey].length < 1
        ) {
          set(
            errors,
            `replicationColumnPlates[${plateKey}]`,
            "Please select at least one destination column plate."
          );
        } else {
          const notEmptyColumnPlates = [];
          const attachedColumnPlates = [];
          replicationColumnPlates[plateKey].forEach(columnPlate => {
            const columnPlateKey = "id" + columnPlate.id;
            const collectionPlate =
              replicationCollectionPlatesForPlate[columnPlateKey];
            let error;
            if (!collectionPlate) {
              error = "Please select a collection plate.";
            } else if (collectionPlate.containerArray) {
              error =
                "The selected collection plate is already attached to a column plate.";
            } else if (
              collectionPlate.aliquotContainers.some(ac => ac.aliquot)
            ) {
              error = "Collection plate must be empty.";
            }
            if (error) {
              set(
                errors,
                `[replicationCollectionPlates[${plateKey}][${columnPlateKey}]`,
                error
              );
            }

            const notEmpty = columnPlate.aliquotContainers.some(
              ac => ac.aliquot
            );
            const attached = !!columnPlate.collectionPlateId;
            if (attached) attachedColumnPlates.push(columnPlate.name);
            if (notEmpty) notEmptyColumnPlates.push(columnPlate.name);
          });

          let error;
          if (notEmptyColumnPlates.length > 0) {
            error = `The following column plates are not empty: ${notEmptyColumnPlates.join(
              ", "
            )}.`;
          } else if (attachedColumnPlates.length > 0) {
            error = `The following column plates are already attached to collection plates: ${attachedColumnPlates.join(
              ", "
            )}.`;
          }
          if (error) {
            set(errors, `replicationColumnPlates[${plateKey}]`);
          }
        }
      };
      sourcePlates.forEach(plate => {
        const plateKey = "id" + plate.id;
        let format = replicateFormats[plateKey];
        let newOrExistingValue = newOrExistingReplicates[plateKey];

        if (operationType === "BREAKDOWN" && applyUniversalBreakdownPattern) {
          newOrExistingValue = universalNewOrExistingDestinationPlates;
          format = universalDestinationFormat;
        }
        if (newOrExistingValue === "EXISTING") {
          const destinationPlatesForKey = get(destinationPlates, plateKey, []);
          if (destinationPlatesForKey.length < 1) {
            set(
              errors,
              `newOrExistingReplicates.${plateKey}`,
              "Please select an existing plate to replicate onto."
            );
          } else {
            const platesWithProperFormat = destinationPlatesForKey.every(
              p =>
                get(p, "containerArrayType.containerFormat.code") ===
                get(format, "code")
            );
            if (!platesWithProperFormat) {
              set(
                errors,
                `destinationPlates.${plateKey}`,
                "The selected plates do not have the correct format."
              );
            }
          }
        }
        if (
          newOrExistingValue === "NEW" &&
          operationType === "REPLICATION" &&
          !replicatePlateInfo[plateKey]
        ) {
          if (!errors.newOrExistingReplicates)
            errors.newOrExistingReplicates = {};
          errors.newOrExistingReplicates[plateKey] =
            "Please add at least one replicate plate.";
        }
        if (
          newOrExistingValue === "EXISTING_COLUMN" &&
          operationType === "REPLICATION"
        ) {
          makeCollectionAndColumnPlateError(plateKey);
        }
        const everyOtherRowForPlate = everyOtherRow[plateKey];
        const everyOtherColumnForPlate = everyOtherColumn[plateKey];
        const sourceFormat = plate.containerArrayType.containerFormat;
        const brokenInto4 =
          format && format.quadrantSize * 4 === sourceFormat.quadrantSize;
        let formatError;
        if (!format) {
          formatError = REQUIRED_ERROR;
        } else if (
          everyOtherColumnForPlate !== everyOtherRowForPlate &&
          !brokenInto4
        ) {
          formatError =
            "The plate must be broken down into 4 plates when using every other row or every other column.";
        } else if (
          !everyOtherColumnForPlate &&
          !everyOtherRowForPlate &&
          !brokenInto4
        ) {
          formatError =
            "The plate must be broken down into 4 plates when not using every other row and every other column.";
        }
        if (formatError) {
          set(errors, `replicateFormats.${plateKey}`, formatError);
        }
      });

      // do validation for broken into 4 for universal breakdown
      if (applyUniversalBreakdownPattern && universalDestinationFormat) {
        let error;
        if (sourcePlates) {
          sourcePlates.some(sourcePlate => {
            const sourceFormat = sourcePlate.containerArrayType.containerFormat;
            const brokenInto4 =
              universalDestinationFormat &&
              universalDestinationFormat.quadrantSize * 4 ===
                sourceFormat.quadrantSize;
            if (
              universalEveryOtherColumn !== universalEveryOtherRow &&
              !brokenInto4
            ) {
              error =
                "Plates must be broken down into 4 plates when using every other row or every other column.";
              return true;
            } else if (
              !universalEveryOtherColumn &&
              !universalEveryOtherRow &&
              !brokenInto4
            ) {
              error =
                "Plates must be broken down into 4 plates when not using every other row and every other column.";
              return true;
            }
            return false;
          });
          if (error) {
            errors.universalDestinationFormat = error;
          }
        }
      }

      // all source plates must have the same format
      if (
        operationType === "CONSOLIDATION" ||
        operationType === "COMBINATION"
      ) {
        if (sourcePlateFormat) {
          const wrongFormat = sourcePlates.some(
            p =>
              p.containerArrayType.containerFormatCode !==
              sourcePlateFormat.code
          );
          if (wrongFormat) {
            errors.sourcePlates = `All source plates must be in the selected format (${sourcePlateFormat.name}).`;
          }
        }
      }

      let numDestinationPlates = 0;
      if (sourcePlates && sourcePlates.length) {
        numDestinationPlates = Math.ceil(
          sourcePlates.length /
            (get(destinationPlateFormat, "quadrantSize") /
              get(sourcePlateFormat, "quadrantSize"))
        );
      }
      if (
        operationType === "COMBINATION" &&
        newOrExistingDestinationPlates === "EXISTING" &&
        (!combinationDestinationPlates ||
          combinationDestinationPlates.length < 1)
      ) {
        times(numDestinationPlates, i => {
          errors.combinationDestinationPlates = [];
          errors.combinationDestinationPlates[i] =
            "Please select an existing destination plate.";
        });
      }

      if (
        newOrExistingDestinationPlates === "EXISTING_COLUMN" &&
        operationType === "COMBINATION"
      ) {
        times(numDestinationPlates, index => {
          const plateKey = "index" + index;
          makeCollectionAndColumnPlateError(plateKey);
        });
      }
      if (
        operationType === "CONSOLIDATION" &&
        newOrExistingDestinationPlates === "EXISTING" &&
        !consolidationDestinationPlate
      ) {
        errors.consolidationDestinationPlate =
          "Please select an existing destination plate.";
      }
    }
    return errors;
  };
  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Choose Operation and Select Plates",
        Component: SelectOperationAndInputPlates,
        withCustomFooter: true
      },
      {
        title: "Plate Mappings",
        Component: PlateMappings,
        withCustomFooter: true,
        props: {
          toolSchema
        }
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plateReformatSourcePlateFragment),
  withProps(props => {
    const { containerArrays } = props;
    return {
      initialValues: {
        sourcePlates: containerArrays,
        sourcePlateFormat: get(
          containerArrays,
          "[0].containerArrayType.containerFormat"
        )
      }
    };
  }),
  withSelectedEntities(
    "sourceContainerArrayExtendedPropertiesToRemove",
    "sourceAliquotExtendedPropertiesToRemove",
    "destinationContainerArrayExtendedPropertiesToRemove",
    "destinationAliquotExtendedPropertiesToRemove"
  )
)(PlateReformatTool);
