/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { DataTable } from "@teselagen/ui";
import { popoverOverflowModifiers } from "../../../src-shared/utils/generalUtils";
import { forEach } from "lodash";

export const sequenceScoringTableName = "sequenceScoringTable";

function SequenceScoringTable(props) {
  const {
    sequenceErrorMap,
    sequenceScoringMap,
    scoredSequenceMap,
    vectorOptions,
    selectedVectors,
    sequencesToScore,
    scoreSequences,
    skipScoring,
    rescoreFailedSequences,
    generateVectorOptionsSelectComponent
  } = props;

  const schema = useMemo(() => {
    return [
      {
        type: "action",
        width: 50,
        render: (v, r, _, { sequenceErrorMap }) => {
          if (sequenceErrorMap[r.id]) {
            return (
              <Tooltip content="Rescore" disabled={!!window.Cypress}>
                <Button
                  icon="refresh"
                  minimal
                  small
                  onClick={() => {
                    scoreSequences([r]);
                  }}
                />
              </Tooltip>
            );
          }
        }
      },
      "name",
      {
        displayName: "Length (bps)",
        path: "size"
      },
      ...(vectorOptions
        ? [
            {
              displayName: "Vector",
              render: (v, r) => {
                if (!vectorOptions.length) {
                  return "No vectors available for this vendor";
                } else {
                  return generateVectorOptionsSelectComponent(r);
                }
              }
            }
          ]
        : []),

      {
        displayName: "Score",
        path: "score",
        sortDisabled: true,
        width: 85,
        render: (
          v,
          r,
          _,
          { sequenceErrorMap, scoredSequenceMap, sequenceScoringMap }
        ) => {
          if (skipScoring) return "-";
          const errorMsg = sequenceErrorMap[r.id];
          const scoredInfo = scoredSequenceMap[r.id];
          let inner;
          if (sequenceScoringMap[r.id]) {
            if (sequenceScoringMap[r.id] === "waitingToScore") {
              inner = (
                <Tooltip content="Waiting to Score" position="bottom-left">
                  <Icon icon="time" intent="warning" />
                </Tooltip>
              );
            } else {
              inner = <Spinner size={Spinner.SIZE_SMALL} />;
            }
          } else if (errorMsg || scoredInfo?.warning) {
            let tooltipMsg = errorMsg || scoredInfo.warning;
            const icon = errorMsg ? "error" : "warning-sign";
            const intent = errorMsg ? "danger" : "warning";
            if (scoredInfo?.alerts?.length) {
              tooltipMsg += "\n\n";
              scoredInfo.alerts.forEach(alert => {
                tooltipMsg += ` - ${alert}\n`;
              });
            }
            inner = (
              <Tooltip
                content={<div style={{ maxWidth: 400 }}>{tooltipMsg}</div>}
                position="bottom-left"
                modifiers={popoverOverflowModifiers}
                popoverClassName="preserve-newline"
              >
                <Icon icon={icon} intent={intent} />
              </Tooltip>
            );
          } else if (scoredInfo) {
            inner = <Icon icon="tick-circle" intent="success" />;
          }
          if (inner) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {inner}
              </div>
            );
          }
        }
      },
      {
        displayName: "Estimated Price",
        width: 120,
        render: (v, r, _, { scoredSequenceMap }) => {
          if (skipScoring) return "-";
          const scoredInfo = scoredSequenceMap[r.id];
          if (scoredInfo && scoredInfo.price) {
            return (
              <span style={{ fontStyle: "bold" }}>
                ${scoredInfo.price.toFixed(2)}
              </span>
            );
          }
        }
      }
    ];
  }, [
    generateVectorOptionsSelectComponent,
    vectorOptions,
    scoreSequences,
    skipScoring
  ]);

  let waiting = 0;
  let scoring = 0;
  const scored = Object.keys(scoredSequenceMap).length;
  // const errored = Object.keys(sequenceErrorMap).length;
  forEach(sequenceScoringMap, value => {
    if (value === "waitingToScore") {
      waiting++;
    } else {
      scoring++;
    }
  });
  return (
    <DataTable
      withCheckboxes={true}
      isLoading={false}
      isSimple
      defaults={{
        pageSize: 100
      }}
      withPaging
      isEntityDisabled={r => sequenceErrorMap[r.id]}
      formName={sequenceScoringTableName}
      {...{
        sequenceScoringMap,
        scoredSequenceMap,
        sequenceErrorMap,
        vectorOptions,
        selectedVectors
      }}
      schema={schema}
      entities={sequencesToScore}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          text="Rescore Failed Sequences"
          onClick={rescoreFailedSequences}
          disabled={isEmpty(sequenceErrorMap)}
        />
        {(waiting > 0 || scoring > 0) && (
          <>
            <div style={{ marginLeft: 10 }} />
            {waiting + scoring} sequences queued for scoring.
          </>
        )}
        {scored > 0 && (
          <>
            <div style={{ marginLeft: 10 }} />
            Scored {scored} sequences.
          </>
        )}
      </div>
    </DataTable>
  );
}

export default SequenceScoringTable;
