/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { keyBy, difference } from "lodash";
import routeDoubleClick from "../../utils/routeDoubleClick";

import { MenuDivider, MenuItem, Tooltip, Position } from "@blueprintjs/core";
import AbstractLibrary from "../../AbstractLibrary";
import TooltipMessages from "../../constants/tooltips.js";
import sIfPlural from "../../utils/sIfPlural";
import defaultAsyncWrap from "../../utils/defaultAsyncWrap";
import { findAASequencesWithDuplicateAASequenceParts } from "../../utils/findSequencesWithDuplicateSequenceParts";
import aminoAcidLibraryFragment from "../../graphql/fragments/aminoAcidLibraryFragment.gql";
import libraryEnhancer from "../../libraryEnhancer";
import { showDialog } from "../../GlobalDialog";
import { molecularWeightColumn } from "../../utils/libraryColumns";
import AminoAcidRecordView from "../../AminoAcidRecordView";
import UploadAminoAcidSequencesDialog from "../../UploadAminoAcidSequencesDialog";
import ExportSequencesDialog from "../../ExportSequencesDialog";
import { safeUpsert } from "../../apolloMethods";
import { getReverseTranslationMenuItem } from "../../../src-design/containers/AminoAcidPartLibraryContainer";

const schema = {
  model: "aminoAcidSequence",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "size",
      type: "number",
      displayName: "Size"
    },
    {
      path: "extinctionCoefficient",
      type: "number",
      displayName: "Extinction Coefficient"
    },
    { path: "hisTagLoc", type: "string", displayName: "Histidine Tag" },
    {
      path: "isoPoint",
      type: "string",
      displayName: "Isoelectric Point"
    },
    molecularWeightColumn,
    { path: "uniprotId", type: "string", displayName: "Uniprot ID" },
    {
      path: "regionAnnotations.name",
      type: "string",
      displayName: "Region Annotations",
      render: (v, r) =>
        r.regionAnnotations
          .map(regionAnnotation => regionAnnotation.name)
          .join(", ")
    },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    },
    {
      path: "proteinSequence",
      type: "string",
      displayName: "Sequence"
    }
  ]
};

class AminoAcidLibrary extends Component {
  showSequenceUpload = () => {
    const { refetchAminoAcidSequences } = this.props;
    showDialog({
      ModalComponent: UploadAminoAcidSequencesDialog,
      modalProps: {
        refetch: refetchAminoAcidSequences
      }
    });
  };

  additionalContextMenu = selectedRecords => {
    const { refetch, refetchAminoAcidSequences } = this.props;
    const menuItems = [];

    menuItems.push(
      <MenuDivider key="md2" />,
      <MenuItem
        key="createPartsFromSequences"
        text={
          <Tooltip
            content={<span>{TooltipMessages.CreatePartFromSequence}</span>}
            position={Position.TOP}
          >{`Create Part${sIfPlural(selectedRecords)} from Sequence${sIfPlural(
            selectedRecords
          )}`}</Tooltip>
        }
        onClick={defaultAsyncWrap(
          async () => {
            const validRecords = selectedRecords.filter(r => r.size > 0);
            const invalidRecords = difference(selectedRecords, validRecords);
            invalidRecords.forEach(seq =>
              window.toastr.warning(
                `Cannot create part on sequence ${
                  seq.name
                } because its length is ${seq.size === 0 ? 0 : "not defined"}.`
              )
            );

            const {
              sequencesWithoutDuplicatedParts,
              sequencesWithDuplicatedParts
            } = await findAASequencesWithDuplicateAASequenceParts(validRecords);

            const idToValidRecord = keyBy(validRecords, "id");

            const seqToPart = seq => {
              const { name, size } = idToValidRecord[seq.id];
              return {
                start: 0,
                end: size * 3 - 1,
                name,
                aminoAcidSequenceId: seq.id
              };
            };

            if (sequencesWithoutDuplicatedParts.length) {
              await safeUpsert(
                "aminoAcidPart",
                sequencesWithoutDuplicatedParts.map(seqToPart)
              );
              await refetchAminoAcidSequences();
              window.toastr.success(
                `${sequencesWithoutDuplicatedParts.length} part${sIfPlural(
                  sequencesWithoutDuplicatedParts
                )} created.`
              );
            }

            if (sequencesWithDuplicatedParts.length) {
              showDialog({
                modalType: "DUPLICATE_PARTS_FOUND",
                modalProps: {
                  valuesToUpsert: sequencesWithDuplicatedParts.map(seqToPart),
                  refetch: refetchAminoAcidSequences,
                  idToSequence: idToValidRecord
                }
              });
            }
          },
          `Error creating part${sIfPlural(selectedRecords)} from sequence${sIfPlural(selectedRecords)}`
        )}
      />
    );

    menuItems.push(
      <MenuDivider key="md3" />,
      <MenuItem
        key="export"
        icon="export"
        text="Export Sequences"
        onClick={() => {
          showDialog({
            ModalComponent: ExportSequencesDialog,
            modalProps: {
              isProtein: true,
              sequenceIds: selectedRecords.map(({ id }) => id)
            }
          });
        }}
      />,
      getReverseTranslationMenuItem({ selectedRecords }),
      <MenuItem
        key="duplicateAASequence"
        icon="duplicate"
        text="Duplicate"
        onClick={() => {
          showDialog({
            modalType: "DUPLICATE_AA_SEQUENCE",
            modalProps: {
              selectedRecords,
              refetch
            }
          });
        }}
      />
    );
    return menuItems;
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        showUploadFunction={this.showSequenceUpload}
        RecordViewInspector={AminoAcidRecordView}
        libraryTitle="Amino Acid Sequences"
        generateExtraContextMenuItems={this.additionalContextMenu}
        withExport
        onDoubleClick={routeDoubleClick}
      />
    );
  }
}

export default libraryEnhancer({
  fragment: aminoAcidLibraryFragment,
  schema
})(AminoAcidLibrary);
