/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get } from "lodash";
import { addTagToUrl } from "../utils/tagUtils";
import Tag from "../Tag";

export default function TagWithSetParams({
  currentParams,
  setNewParams,
  tag,
  tagOption
}) {
  return (
    <Tag
      {...(tagOption || tag)}
      style={{ marginRight: 5 }}
      onClick={e => {
        e.stopPropagation();
        const args = {
          tagId: tag.id,
          tagOptionId: get(tagOption, "id"),
          currentParams,
          setNewParams
        };
        addTagToUrl(args);
      }}
      name={`${tag.name + (tagOption ? `: ${tagOption.name}` : "")}`}
    />
  );
}
