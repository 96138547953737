/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import SelectIntegration, { integrationFragment } from "./SelectIntegration";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import ImportFromExternalDbDialog, {
  dupMessage
} from "../../../../src-shared/ExternalIntegrations/ImportFromExternalDbDialog";
import {
  dataItemTypeCodeToModel,
  inventoryItemTypeCodeToModel
} from "../../../../../tg-iso-shared/src/utils/inventoryUtils";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

function ExternalImportTool(props) {
  const {
    initialValues,
    toolSchema,
    toolIntegrationProps,
    isToolIntegrated
  } = props;

  const outputIoItems = toolSchema.toolSchema.output.ioItems;
  const output = Object.values(outputIoItems)[0];
  const outputName = Object.keys(outputIoItems)[0];
  const model =
    inventoryItemTypeCodeToModel[output.inventoryItemTypeCode] ||
    dataItemTypeCodeToModel[output.dataItemTypeCode];

  async function onSubmit(vals) {
    const { integration } = vals;
    try {
      let importedItems = await new Promise(resolve => {
        showDialog({
          ModalComponent: ImportFromExternalDbDialog,
          onClose: () => resolve(),
          modalProps: {
            allowContinueWithoutImporting: true,
            importSubtype: integration.subtype,
            integrationId: integration.id,
            onFinish: records => resolve(records)
          }
        });
      });
      importedItems =
        importedItems &&
        importedItems.filter(
          item => item.__importFailed === dupMessage || !item.__importFailed
        );
      if (!importedItems?.length) {
        window.toastr.warning(
          `No ${modelNameToReadableName(model, {
            plural: true
          })} were imported.`
        );
        return false;
      }
      return {
        [outputName]: importedItems
      };
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error importing from external database.");
    }
  }

  const steps = [
    {
      title: "Select Integration",
      Component: SelectIntegration
    }
  ];

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      enableReinitialize={isToolIntegrated}
      validate={values => {
        const { integration } = values;
        const errors = {};
        if (
          integration &&
          props.subtype &&
          integration.subtype !== props.subtype
        ) {
          errors.integration = `Invalid integration. Not proper type.`;
        }
        return errors;
      }}
      initialValues={initialValues}
    />
  );
}

export default compose(
  withWorkflowInputs(integrationFragment, {
    singular: true
  })
)(ExternalImportTool);
