/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  difference,
  flatMap,
  times,
  get,
  uniq,
  differenceWith,
  min,
  max,
  reject,
  filter
} from "lodash";
import {
  getActiveBinId,
  getSelectedCardId,
  getSelectedBinIds,
  getSelectedCellPaths,
  getActiveCellPath,
  getFirstRowSelectedIndex
} from "../selectors/designViewSelectors";
import {
  getNumberOfRowsForCard,
  getElementsInBin,
  getBinIdsInCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { cartesianProductOf } from "../../../tg-iso-design/utils/combinatorialUtils";
import { getAllIndicesBetweenTwoLocations } from "./getAllIndicesBetweenTwoLocations";

const getAllCellIndicesBetweenTwoLocations = (
  activeCellIndex,
  clickedCellIndex
) =>
  cartesianProductOf([
    getAllIndicesBetweenTwoLocations(
      activeCellIndex.binIndex,
      clickedCellIndex.binIndex
    ),
    getAllIndicesBetweenTwoLocations(
      activeCellIndex.index,
      clickedCellIndex.index
    )
  ]).map(([binIndex, index]) => ({ binIndex, index }));

const isCellIndexInSelection = (selectedCellIndices, clickedCellIndex) =>
  selectedCellIndices.some(
    ({ binIndex, index }) =>
      binIndex === clickedCellIndex.binIndex && index === clickedCellIndex.index
  );

const removeCellIndexFromSelection = (selectedCellIndices, clickedCellIndex) =>
  differenceWith(
    selectedCellIndices,
    [clickedCellIndex],
    (a, b) => a.binIndex === b.binIndex && a.index === b.index
  );

const binIdToIndex = (state, cardId, binId) => {
  const binIds = getBinIdsInCard(state, cardId);
  const binIndex = binIds.indexOf(binId);
  if (binIndex === -1) throw new Error("TODO");
  return binIndex;
};

const indexToBinId = (state, cardId, index) =>
  getBinIdsInCard(state, cardId)[index];

const cellPathToCellIndex = (state, cardId, cellIndex) => ({
  index: cellIndex.index,
  elementId: cellIndex.elementId,
  binIndex: binIdToIndex(state, cardId, cellIndex.binId)
});
const cellIndexToCellPath = (state, cardId, cellIndex) => ({
  index: cellIndex.index,
  elementId: cellIndex.elementId,
  binId: indexToBinId(state, cardId, cellIndex.binIndex)
});

const addElementsToSelectedCellPaths = (state, cellPaths) =>
  cellPaths.map(cp => {
    const elements = getElementsInBin(state, cp.binId);
    return {
      ...cp,
      elementId: get(
        elements.find(el => el.index === cp.index),
        "id"
      )
    };
  });
const calculateSelectedBins = ({ state, cardId, binId, shift, ctrl }) => {
  let newSelectedBinIndices, newActiveBinIndex;

  const selectedCardId = getSelectedCardId(state);
  const selectedBinIds = getSelectedBinIds(state);

  const clickedBinIndex = binIdToIndex(state, cardId, binId);
  const onNormalLeftClick = () => {
    newSelectedBinIndices = [clickedBinIndex];
    newActiveBinIndex = clickedBinIndex;
  };

  if (selectedCardId !== cardId || (!shift && !ctrl)) {
    onNormalLeftClick();
  } else {
    newActiveBinIndex = getActiveBinId(state)
      ? binIdToIndex(state, selectedCardId, getActiveBinId(state))
      : clickedBinIndex;

    const selectedBinIndices = selectedBinIds.map(binId =>
      binIdToIndex(state, selectedCardId, binId)
    );

    if (shift) {
      if (getActiveBinId(state)) {
        newSelectedBinIndices = getAllIndicesBetweenTwoLocations(
          binIdToIndex(state, selectedCardId, getActiveBinId(state)),
          clickedBinIndex
        );
      } else {
        onNormalLeftClick();
      }
    } else if (ctrl) {
      if (selectedBinIndices.length) {
        if (selectedBinIndices.includes(clickedBinIndex)) {
          newSelectedBinIndices = difference(selectedBinIndices, [
            clickedBinIndex
          ]);
        } else {
          newSelectedBinIndices = [...selectedBinIndices, clickedBinIndex];
        }
      } else {
        onNormalLeftClick();
      }
    }
  }

  const newSelectedBinIds = newSelectedBinIndices
    .sort((a, b) => a - b)
    .map(ind => indexToBinId(state, cardId, ind));
  const numRows = getNumberOfRowsForCard(state, cardId);
  return {
    selectedCardId: cardId,
    selectedBinIds: newSelectedBinIds,
    activeBinId: newSelectedBinIndices.length
      ? indexToBinId(state, cardId, newActiveBinIndex)
      : null,
    selectedCellPaths: addElementsToSelectedCellPaths(
      state,
      flatMap(newSelectedBinIds, binId => {
        const elements = getElementsInBin(state, binId);
        return times(numRows, index => ({
          binId,
          index,
          elementId: get(
            elements.find(el => el.index === index),
            "id"
          )
        }));
      })
    )
  };
};

const calculateSelectedCells = ({
  state,
  cardId,
  binId,
  index,
  elementId,
  shift,
  ctrl
}) => {
  let newSelectedCellIndices, newActiveCellIndex;

  const clickedCellIndex = {
    binIndex: binIdToIndex(state, cardId, binId),
    index,
    elementId
  };
  const selectedCardId = getSelectedCardId(state);
  const selectedCellPaths = getSelectedCellPaths(state);

  const onNormalLeftClick = () => {
    newSelectedCellIndices = [clickedCellIndex];
    newActiveCellIndex = clickedCellIndex;
  };
  if (selectedCardId !== cardId || (!shift && !ctrl)) {
    onNormalLeftClick();
  } else {
    const activeCellPath = getActiveCellPath(state);
    const selectedCellIndices = selectedCellPaths.map(cp =>
      cellPathToCellIndex(state, selectedCardId, cp)
    );

    newActiveCellIndex = activeCellPath
      ? cellPathToCellIndex(state, selectedCardId, activeCellPath)
      : clickedCellIndex;

    if (shift) {
      if (activeCellPath) {
        newSelectedCellIndices = getAllCellIndicesBetweenTwoLocations(
          cellPathToCellIndex(state, selectedCardId, activeCellPath),
          clickedCellIndex
        );
      } else {
        onNormalLeftClick();
      }
    } else if (ctrl) {
      if (selectedCellIndices.length) {
        if (isCellIndexInSelection(selectedCellIndices, clickedCellIndex)) {
          newSelectedCellIndices = removeCellIndexFromSelection(
            selectedCellIndices,
            clickedCellIndex
          );
        } else {
          newSelectedCellIndices = [...selectedCellIndices, clickedCellIndex];
        }
      } else {
        onNormalLeftClick();
      }
    }
  }

  const newSelectedCellPaths = newSelectedCellIndices.map(ci =>
    cellIndexToCellPath(state, cardId, ci)
  );
  const newSelectedBinIds = uniq(
    newSelectedCellIndices
      .map(ci => ci.binIndex)
      .sort((a, b) => a - b)
      .map(binIndex => indexToBinId(state, cardId, binIndex))
  );

  return {
    selectedCardId: cardId,
    activeBinId: binId,
    selectedCellPaths: addElementsToSelectedCellPaths(
      state,
      newSelectedCellPaths
    ),
    activeCellPath: newSelectedCellIndices.length
      ? cellIndexToCellPath(state, cardId, newActiveCellIndex)
      : null,
    selectedBinIds: newSelectedBinIds
  };
};

const getBinIndexes = (binIds, state, cardId, index) =>
  binIds.map(binId => ({
    binIndex: binIdToIndex(state, cardId, binId),
    index
  }));

const selectedCellPathsByIndex = (state, cardId, binIds, index) => {
  const newSelectedCellIndices = getBinIndexes(binIds, state, cardId, index);

  return newSelectedCellIndices.map(ci =>
    cellIndexToCellPath(state, cardId, ci)
  );
};

const selectedRow = ({ state, cardId, index, shift, ctrl }) => {
  const binIds = getBinIdsInCard(state, cardId);

  let prevSelectedCellPaths = [],
    newSelectedCellPaths = [],
    prevSelectedCardId,
    prevActiveCellPath,
    firstRowIndexSelected = getFirstRowSelectedIndex(state) || 0;

  if (shift || ctrl) {
    prevSelectedCardId = getSelectedCardId(state);
    if (cardId === prevSelectedCardId) {
      if (shift) {
        prevActiveCellPath = getActiveCellPath(state);
        if (prevActiveCellPath) {
          const minIndex = min([firstRowIndexSelected, index]);
          const maxIndex = max([firstRowIndexSelected, index]);
          for (let i = minIndex; i <= maxIndex; i++) {
            newSelectedCellPaths = [
              ...newSelectedCellPaths,
              ...selectedCellPathsByIndex(state, cardId, binIds, i)
            ];
          }
        } else {
          newSelectedCellPaths = [
            ...newSelectedCellPaths,
            ...selectedCellPathsByIndex(state, cardId, binIds, index)
          ];
        }
      } else if (ctrl) {
        const newSelectedCells = selectedCellPathsByIndex(
          state,
          cardId,
          binIds,
          index
        );
        const prevSelected = getSelectedCellPaths(state);
        const filteredSelection = !!filter(prevSelected, { index }).length;

        if (filteredSelection) {
          prevSelectedCellPaths = reject(
            [...newSelectedCells, ...prevSelected],
            {
              index
            }
          );
        } else {
          newSelectedCellPaths = newSelectedCells;
          prevSelectedCellPaths = prevSelected;
        }
      }
    } else {
      newSelectedCellPaths = selectedCellPathsByIndex(
        state,
        cardId,
        binIds,
        index
      );
      firstRowIndexSelected = index;
    }
    newSelectedCellPaths = [...newSelectedCellPaths, ...prevSelectedCellPaths];
  } else {
    newSelectedCellPaths = selectedCellPathsByIndex(
      state,
      cardId,
      binIds,
      index
    );
    firstRowIndexSelected = index;
  }

  const selectedCellPaths = addElementsToSelectedCellPaths(
    state,
    newSelectedCellPaths
  );

  const activeCellPath = selectedCellPaths[0];

  return {
    activeBinId: selectedCellPaths.length ? binIds[0] : null,
    selectedBinIds: selectedCellPaths.length ? binIds : [],
    selectedCardId: cardId,
    activeCellPath,
    selectedCellPaths,
    firstRowIndexSelected
  };
};

const selectAllRows = ({ state, cardId, numberOfRows }) => {
  const binIds = getBinIdsInCard(state, cardId);
  let newSelectedCellPaths = [];
  for (let i = 0; i < numberOfRows; i++) {
    newSelectedCellPaths = [
      ...newSelectedCellPaths,
      ...selectedCellPathsByIndex(state, cardId, binIds, i)
    ];
  }
  const selectedCellPaths = addElementsToSelectedCellPaths(
    state,
    newSelectedCellPaths
  );

  const activeCellPath = selectedCellPaths[0];
  return {
    activeBinId: binIds[0],
    selectedBinIds: binIds,
    selectedCardId: cardId,
    activeCellPath,
    selectedCellPaths
  };
};

// having one export source make easier to find these methods
export {
  getAllIndicesBetweenTwoLocations,
  calculateSelectedBins,
  calculateSelectedCells,
  selectedRow,
  selectAllRows
};
