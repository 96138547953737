/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Link } from "react-router-dom";
import { inventoryItemTypeCodeToModel } from "../../../tg-iso-shared/src/utils/inventoryUtils";
import withRecordAliases from "../enhancers/withRecordAliases";
import modelNameToLink from "../utils/modelNameToLink";

function RecordAliases({ aliases = [], aliasesLoading }) {
  if (aliasesLoading) {
    return "Loading aliases...";
  } else {
    return aliases.map((alias, i) => {
      const separator = aliases.length === i + 1 ? "" : ", ";
      let inner = alias.name;
      if (alias.targetInventoryItem) {
        const targetModel =
          inventoryItemTypeCodeToModel[
            alias.targetInventoryItem.inventoryItemTypeCode
          ];
        const targetId = alias.targetInventoryItem[targetModel + "Id"];
        if (targetId) {
          inner = (
            <Link to={modelNameToLink(targetModel, targetId)}>{inner}</Link>
          );
        }
      }
      return (
        <React.Fragment key={alias.id}>
          {inner}
          {separator}
        </React.Fragment>
      );
    });
  }
}

export default withRecordAliases(RecordAliases);
