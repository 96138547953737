/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isObject } from "lodash";
import Big from "big.js";
import React from "react";
import { Tooltip } from "@blueprintjs/core";
import getUnit from "../../../tg-iso-lims/src/utils/unitUtils/getUnit";
import cleanUnit from "../../../tg-iso-lims/src/utils/unitUtils/cleanUnit";
import {
  standardizeConcentration,
  standardizeVolume,
  standardizeMass,
  standardizeMolarity
} from "../../../tg-iso-lims/src/utils/unitUtils/standardizeUnits";
import {
  convertVolume,
  convertConcentration,
  convertMass,
  convertMolarity
} from "../../../tg-iso-lims/src/utils/unitUtils/convertUnits";
import calculateConcentration from "../../../tg-iso-lims/src/utils/unitUtils/calculateConcentration";
import { toDecimalPrecision } from "../../../tg-iso-shared/src/utils/generalUtils";
import unitGlobals from "../../../tg-iso-lims/src/unitGlobals";

export {
  standardizeConcentration,
  standardizeVolume,
  standardizeMass,
  standardizeMolarity,
  calculateConcentration,
  convertVolume,
  convertConcentration,
  convertMass,
  convertMolarity,
  toDecimalPrecision,
  cleanUnit
};

export const getMassFromVolumeAndConcentration = ({
  volume,
  volumetricUnitCode,
  concentration,
  concentrationUnitCode,
  big
}) => {
  const desiredUnits = concentrationUnitCode.split("/");
  const desiredMassUnit = getUnit(unitGlobals.massUnits, desiredUnits[0]);
  const desiredVolumetricUnit = getUnit(
    unitGlobals.volumetricUnits,
    desiredUnits[1]
  );
  const standardizedVolume = standardizeVolume(volume, volumetricUnitCode, big);
  let newVolume, mass;
  if (big) {
    newVolume = standardizedVolume.div(desiredVolumetricUnit.liters);
    mass = newVolume.times(concentration);
  } else {
    newVolume = standardizedVolume / desiredVolumetricUnit.liters;
    mass = newVolume * concentration;
  }
  return {
    mass,
    massUnitCode: desiredMassUnit.code
  };
};

//noJsx is used when we're exporting data and want to export a string, not a JSX object
export const withUnitGeneric = (path, unitPath) => (
  value,
  record,
  { noJsx } = {}
) => {
  let valueToUse = value;
  let recordToUse = record;
  if (isObject(value)) {
    // then value is the record
    recordToUse = value;
    valueToUse = get(recordToUse, path);
  }
  if (!valueToUse) valueToUse = get(recordToUse, path);
  const unit = get(recordToUse, unitPath) || "";
  const numericValue = parseFloat(valueToUse, 10);

  if (!isNaN(numericValue)) {
    const roundedValue = toDecimalPrecision(numericValue);
    const unroundedValue = numericValue;

    if (
      roundedValue === unroundedValue ||
      Math.abs(unroundedValue - roundedValue) < 1e-6 ||
      noJsx
    ) {
      return roundedValue + ` ${unit}`;
    } else {
      return (
        <Tooltip content={unroundedValue + ` ${unit}`}>
          {roundedValue + ` ${unit}`}
        </Tooltip>
      );
    }
  } else {
    return "N/A";
  }
};

export const volumeRender = withUnitGeneric("volume", "volumetricUnitCode");
export const lengthRender = withUnitGeneric("length", "lengthUnitCode");
export const concentrationRender = withUnitGeneric(
  "concentration",
  "concentrationUnitCode"
);
export const massRender = withUnitGeneric("mass", "massUnitCode");
export const molarityRender = withUnitGeneric("molarity", "molarityUnitCode");
export const cellConcentrationRender = withUnitGeneric(
  "cellConcentration",
  "cellConcentrationUnitCode"
);
export const materialConcentrationRender = withUnitGeneric(
  "materialConcentration",
  "materialConcentrationUnitCode"
);

export const molecularWeightRender = (molecularWeight, unused, opts) =>
  withUnitGeneric("molecularWeight", "molecularWeightUnitCode")(
    { molecularWeight, molecularWeightUnitCode: "g/mol" },
    opts
  );

/**
 * Used to calculate a new concentration when combining volumes of different concentrations.
 * Accepts an array of objects of the form
 * {
 *   volume,
 *   volumetricUnitCode,
 *   concentration,
 *   concentrationUnitCode
 * }
 * The first argument should be the desired concentration code if combining different types.
 *
 * @param  {...any} args
 */
export const combineConcentrations = (...args) => {
  const volumeAndConcentrations = args;

  let volumeTracker = new Big(0);
  let massTracker = new Big(0);
  volumeAndConcentrations.forEach(obj => {
    const {
      volume,
      volumetricUnitCode,
      concentration,
      concentrationUnitCode
    } = obj;
    const stdVolume = standardizeVolume(volume, volumetricUnitCode, true);
    const stdConcentration = standardizeConcentration(
      concentration,
      concentrationUnitCode,
      true
    );
    volumeTracker = volumeTracker.add(stdVolume);
    const mass = stdVolume.times(stdConcentration);
    massTracker = massTracker.add(mass);
  });
  const newStdConcentration = massTracker.div(volumeTracker);
  const targetConcentrationCode =
    volumeAndConcentrations[0].concentrationUnitCode;
  const newConcentration = Number(
    convertConcentration(
      newStdConcentration,
      "g/L",
      targetConcentrationCode,
      true
    ).toString()
  );
  return {
    concentration: newConcentration,
    concentrationUnitCode: targetConcentrationCode
  };
};
