/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get } from "lodash";
import { withSelectedEntities } from "@teselagen/ui";
import StepForm from "../../../../src-shared/StepForm";
import { executeWorklist } from "../utils";
import { updateAliquotsWithReagents } from "../../../utils";
import { validateReagentVolumes } from "../AddReagentsTool/AddReagents";
import { SelectWorklist, ValidateWorklist, ExtendedProperties } from "./Steps";
import client from "../../../../src-shared/apolloClient";
import "./style.css";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { mapProps, compose } from "recompose";
import worklistExecuteFragment from "../../../graphql/fragments/worklistExecuteFragment";
import { handleExtendedProperties } from "./utils";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import withWorkflowInputsFromHash from "../../../graphql/enhancers/withWorkflowInputsFromHash";
import { allConcentrationTypeFields } from "../../../../../tg-iso-lims/src/utils/unitUtils";
import {
  getTransferIntoFromAliquotContainer,
  isUnreadyNormalizationTransfer
} from "../../../../../tg-iso-lims/src/utils/worklistUtils";

const isUnreadyNormalizationWorklist = worklist => {
  const {
    transfersIntoAliquotContainer
  } = getTransferIntoFromAliquotContainer([worklist]);

  return worklist.worklistTransfers.some(t => {
    const transfersToDestinationWell =
      transfersIntoAliquotContainer[t.destinationAliquotContainer.id];
    return isUnreadyNormalizationTransfer(transfersToDestinationWell);
  });
};

class ExecuteWorklistTool extends React.Component {
  state = {
    hasExtendedProperties: false
  };

  setHasExtendedProperties = (bool, cb) => {
    this.setState(
      {
        hasExtendedProperties: bool
      },
      cb
    );
  };

  onSubmit = async values => {
    try {
      const {
        existingSourceContainerArrayExtendedPropertiesTableSelectedEntities: containerArrayPropsToTransfer = [],
        existingSourceAliquotExtendedPropertiesTableSelectedEntities: aliquotPropsToTransfer = []
      } = this.props;
      const {
        worklists,
        reactionMapClasses,
        addReagents,
        reagents = [],
        reagentInfo = {}
      } = values;
      let updatedPlateIds = [];
      let updatedTubeIds = [];

      const orderedWorklists = [];
      for (const worklist of worklists) {
        if (isUnreadyNormalizationWorklist(worklist)) {
          orderedWorklists.push(worklist);
        } else {
          orderedWorklists.unshift(worklist);
        }
      }

      for (const worklist of orderedWorklists) {
        await executeWorklist(worklist);
        const toBeReturnedPlateIds = {};
        const toBeReturnedTubeIds = {};
        worklist.worklistTransfers.forEach(transfer => {
          const containerArrayId = get(
            transfer,
            "destinationAliquotContainer.containerArrayId"
          );
          const tubeId = get(transfer, "destinationAliquotContainer.id");
          if (!containerArrayId) {
            toBeReturnedTubeIds[tubeId] = true;
          }
          if (containerArrayId) {
            toBeReturnedPlateIds[containerArrayId] = true;
          }
        });
        worklist.tubeTransfers.forEach(t => {
          toBeReturnedTubeIds[t.aliquotContainer.id] = true;
          toBeReturnedPlateIds[t.destinationContainerArray.id] = true;
        });
        updatedPlateIds = updatedPlateIds.concat(
          Object.keys(toBeReturnedPlateIds)
        );
        updatedTubeIds = updatedTubeIds.concat(
          Object.keys(toBeReturnedTubeIds)
        );
      }

      await safeQuery(["worklist", "id worklistStatus { code name }"], {
        variables: {
          filter: {
            id: worklists.map(w => w.id)
          }
        }
      });

      if (reactionMapClasses && reactionMapClasses.length) {
        try {
          for (const reactionMapClass of reactionMapClasses) {
            await reactionMapClass.executeWorklistReactions();
          }
        } catch (e) {
          console.error(e);
          window.toastr.error("Error executing worklist reactions");
        }
      }
      try {
        await handleExtendedProperties(worklists, {
          aliquotPropsToTransfer,
          containerArrayPropsToTransfer
        });
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.warning("Unable to apply extended properties");
      }
      const sourceAliquotIds = [];
      worklists.forEach(worklist => {
        worklist.worklistTransfers.forEach(transfer => {
          const sourceAliquot = get(transfer, "sourceAliquotContainer.aliquot");
          if (sourceAliquot) sourceAliquotIds.push(sourceAliquot.id);
        });
      });

      if (addReagents && reagents.length) {
        try {
          const worklistsWithDestinationsAliquots = await safeQuery(
            [
              "worklist",
              `
              id
              worklistTransfers {
                id
                destinationAliquotContainer {
                  id
                  aliquotContainerType {
                    code
                    maxVolume
                    volumetricUnitCode
                  }
                  additives {
                    id
                    volume
                    volumetricUnitCode
                    mass
                    massUnitCode
                    ${allConcentrationTypeFields.join(" ")}
                    additiveMaterialId
                    lotId
                  }
                  aliquot {
                    id
                    volume
                    volumetricUnitCode
                    additives {
                      id
                      volume
                      volumetricUnitCode
                      mass
                      massUnitCode
                      ${allConcentrationTypeFields.join(" ")}
                      additiveMaterialId
                      lotId
                    }
                    isDry
                  }
                }
              }
            `
            ],
            {
              variables: {
                filter: {
                  id: worklists
                    .filter(w => w.worklistTransfers.length)
                    .map(({ id }) => id)
                }
              }
            }
          );

          const aliquotsWithVolume = [];
          const aliquotContainers = [];
          worklistsWithDestinationsAliquots.forEach(worklist => {
            worklist.worklistTransfers.forEach(transfer => {
              const aliquot = get(
                transfer,
                "destinationAliquotContainer.aliquot"
              );
              if (aliquot) {
                aliquotsWithVolume.push({
                  ...aliquot,
                  aliquotContainer: transfer.destinationAliquotContainer
                });
              } else if (transfer.destinationAliquotContainer) {
                aliquotContainers.push(transfer.destinationAliquotContainer);
              }
            });
          });
          const error = await updateAliquotsWithReagents({
            aliquots: aliquotsWithVolume,
            aliquotContainers,
            reagents,
            reagentInfo
          });
          if (error) {
            window.toastr.error(error);
          }
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Unable to add reagents.");
        }
      }

      // re-query aliquots to get updated volumes
      try {
        if (updatedPlateIds.length || updatedTubeIds.length) {
          const updateAliquotFilter = {};
          if (updatedPlateIds.length) {
            updateAliquotFilter[
              "aliquotContainer.containerArrayId"
            ] = updatedPlateIds;
          }
          if (updatedTubeIds.length) {
            updateAliquotFilter["aliquotContainer.id"] = updatedTubeIds;
          }
          await safeQuery(
            [
              "aliquot",
              `id
              volume volumetricUnitCode
              mass massUnitCode
              concentration concentrationUnitCode
              molarity molarityUnitCode`
            ],
            {
              variables: {
                filter: updateAliquotFilter
              }
            }
          );
          // remove the plates and tubes from the cache so that they will have to be refetched
          client.removeFromCache(
            updatedPlateIds.map(id => ({ id, __typename: "containerArray" }))
          );
          client.removeFromCache(
            updatedTubeIds.map(id => ({ id, __typename: "aliquotContainer" }))
          );
        }
      } catch (error) {
        console.error(`error:`, error);
      }

      return {
        containerArrays: updatedPlateIds.map(id => ({ id })),
        aliquotContainers: updatedTubeIds.map(id => ({ id }))
      };
    } catch (e) {
      console.error(e);
      window.toastr.error("Error executing worklist");
    }
  };

  validate = values => {
    const errors = {};
    const { reagents = [], reagentInfo = {}, worklists = [] } = values;
    const hasEmptyWorklist = worklists.some(
      worklist =>
        worklist.worklistTransfers.length < 1 &&
        worklist.tubeTransfers.length < 1
    );
    if (hasEmptyWorklist) {
      errors.partialSelectedWorklists =
        "Some of the selected worklists are missing transfers. Please check and update your selection.";
    }
    const destWellIds = [];
    worklists.forEach(worklist => {
      worklist.worklistTransfers.forEach(transfer => {
        const destAcId = get(transfer, "destinationAliquotContainer.id");
        if (!destWellIds.includes(destAcId)) destWellIds.push(destAcId);
      });
    });
    validateReagentVolumes({
      errors,
      reagents,
      reagentInfo,
      numberOfTransfers: destWellIds.length
    });

    return errors;
  };

  render() {
    const {
      toolSchema,
      initialValues,
      isToolIntegrated,
      toolIntegrationProps
    } = this.props;

    const steps = [
      {
        title: "Select Worklist(s)",
        Component: SelectWorklist,
        props: {
          initialValues,
          setHasExtendedProperties: this.setHasExtendedProperties
        },
        withCustomFooter: true
      },
      ...(this.state.hasExtendedProperties
        ? [
            {
              title: "Extended Properties",
              Component: ExtendedProperties
            }
          ]
        : []),
      {
        title: "Validate Worklist(s)",
        Component: ValidateWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        steps={steps}
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        onSubmit={this.onSubmit}
        validate={this.validate}
      />
    );
  }
}

export default compose(
  withWorkflowInputsFromHash({
    hashIdToInputName: {
      worklistIds: "worklists",
      reactionMapIds: "reactionMaps"
    }
  }),
  withWorkflowInputs(worklistExecuteFragment),
  withWorkflowInputs(["reactionMap", "id name updatedAt"]),
  mapProps(props => {
    const {
      worklists = [],
      reactionMaps: passedReactionMaps = [],
      ...rest
    } = props;
    const reactionMaps = {};
    if (worklists.length && passedReactionMaps.length) {
      // if only a single worklist link to all reaction maps
      if (worklists.length === 1) {
        reactionMaps[`worklistId${worklists[0].id}`] = passedReactionMaps;
      } else {
        // else link in order
        worklists.forEach((worklist, i) => {
          if (passedReactionMaps[i]) {
            reactionMaps[`worklistId${worklist.id}`] = [passedReactionMaps[i]];
          }
        });
      }
    }
    return {
      ...rest,
      initialValues: {
        partialSelectedWorklists: worklists.length ? worklists : undefined,
        reactionMaps
      }
    };
  }),
  withSelectedEntities(
    "existingSourceContainerArrayExtendedPropertiesTable",
    "existingSourceAliquotExtendedPropertiesTable"
  )
)(ExecuteWorklistTool);
