/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { SimpleCircularOrLinearView } from "@teselagen/ove";

import React from "react";
import { compose } from "redux";
import { sequenceToVeInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import withQuery from "../../src-shared/withQuery";

export const inspectorSeqFragment = gql`
  fragment inspectorSeqFragment on sequence {
    id
    name
    size
    circular
    parts {
      id
      name
      start
      end
      overlapsSelf
      strand
    }
    sequenceTypeCode
    sequenceFeatures {
      id
      name
      type
      strand
      start
      end
    }
    isJ5Sequence
    isInLibrary
  }
`;

const renderInspectorSequence = (sequence, extraProps) => {
  const sequenceData = sequenceToVeInput({
    ...sequence,
    noSequence: true //pass this here so that we can just provide a .size property and not the full sequence info which can be costly to grab
  });
  return (
    <SimpleCircularOrLinearView
      {...{
        sequenceData,
        width: 270,
        height: "unset",
        hideName: true,
        annotationLabelVisibility: {
          parts: false,
          features: false,
          cutsites: false,
          primers: false
        },
        annotationVisibility: {
          parts: true,
          axisNumbers: sequenceData.circular
        },
        ...extraProps
      }}
    />
  );
};
class InspectorSequence extends React.Component {
  render() {
    const { fullSeq } = this.state || {};
    const { sequence, extraProps } = this.props;
    if (!sequence) return "Loading..";
    return renderInspectorSequence(fullSeq || sequence, extraProps);
  }
}

const FullSeqWithQuery = compose(
  withQuery(inspectorSeqFragment, {
    options: props => {
      return {
        variables: {
          id: props.sequence.id
        }
      };
    }
  })
)(InspectorSequence);

const renderInspectorSequenceWithQuery = (sequence, extraProps) => {
  return <FullSeqWithQuery {...{ sequence, extraProps }} />;
};

export { renderInspectorSequence, renderInspectorSequenceWithQuery };
