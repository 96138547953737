/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import workflowRunBasicInfoWorkflowRunFragment from "../../graphql/fragments/workflowRunBasicInfoWorkflowRunFragment";

export const workQueueItemsOnWorkflowToolsFragment = gql`
  fragment workQueueItemsOnWorkflowToolsFragment on workflowTool {
    id
    workQueueItems(filter: { reassigned: false }) {
      id
      reassigned
      jobUser {
        id
        user {
          id
          firstName
          lastName
          username
          avatarFile {
            id
            path
          }
        }
      }
    }
  }
`;

export const workflowRunJobFragment = gql`
  fragment workflowRunJobFragment on workflowRun {
    id
    job {
      id
      name
      description
      jobOwnerId
    }
  }
`;

const workflowRunInventoryItemFragment = gql`
  fragment workflowRunInventoryItemFragment on inventoryItem {
    id
    inventoryItemTypeCode
    containerArrayId
    aliquotId
    aliquotContainerId
    materialId
    sequenceId
    sequenceFeatureId
    additiveMaterialId
    functionalProteinUnitId
    aminoAcidSequenceId
    sampleId
    lotId
    strainId
  }
`;
const workflowRunDataItemFragment = gql`
  fragment workflowRunDataItemFragment on dataItem {
    id
    dataItemTypeCode
    plateMapGroupId
    j5ReportId
    dataSetId
    dataMapId
    dataGridId
    dataTableId
    reactionMapId
    reactionDesignId
    vendorOrderId
    worklistId
    alignmentId
    sequencingQualityControlReportId
  }
`;

const workflowRunIoItemFragmentNonNested = gql`
  fragment workflowRunIoItemFragmentNonNested on ioItem {
    id
    isList
    ioItem {
      id
      ioListItems {
        id
      }
    }
    microserviceQueueId
    displayName
    ioItemTypeCode
    ioItemType {
      code
      name
      description
    }
    statusCode
    statusMessage
    ioItemAvailabilityStatusCode
    dataItemId
    dataItem {
      ...workflowRunDataItemFragment
    }
    inventoryItemId
    inventoryItem {
      ...workflowRunInventoryItemFragment
    }
    ioItemTypeCode
  }
  ${workflowRunInventoryItemFragment}
  ${workflowRunDataItemFragment}
`;

const workflowRunIoItemFragment = gql`
  fragment workflowRunIoItemFragment on ioItem {
    ...workflowRunIoItemFragmentNonNested
    ioListItems {
      ...workflowRunIoItemFragmentNonNested
    }
  }
  ${workflowRunIoItemFragmentNonNested}
`;

export const workflowToolInputFragment = gql`
  fragment workflowToolInputFragment on workflowToolInput {
    id
    workflowToolInputDefinition {
      id
      itemCode
      label
      isList
      isDisabled
      taskIoTypeCode
      wtInputDefToOutputDefs {
        id
        workflowToolOutputDefinition {
          id
          label
          workflowToolDefinition {
            id
            index
            name
          }
        }
      }
    }
    workflowToolInputIoItems {
      id
      ioItemId
      ioItem {
        ...workflowRunIoItemFragment
      }
    }
  }
  ${workflowRunIoItemFragment}
`;

export const workflowToolFragment = gql`
  fragment workflowToolFragment on workflowTool {
    id
    ...workQueueItemsOnWorkflowToolsFragment
    workflowToolStatusCode
    updatedAt
    startDate
    endDate
    user {
      id
      firstName
      lastName
    }
    workflowToolInputs {
      ...workflowToolInputFragment
    }
    workflowToolOutputs {
      id
      workflowToolOutputDefinition {
        id
        isList
        itemCode
        label
        taskIoTypeCode
      }
      ioItemId
      ioItem {
        ...workflowRunIoItemFragment
      }
    }
    workflowToolDefinition {
      id
      name
      toolCode
      validateBarcodes
      index
      description
      workflowToolSettingDefinitions {
        id
        name
        value
        workflowToolSettingDefinitionTypeCode
        integrationId
        integration {
          id
          name
        }
      }
    }
  }
  ${workQueueItemsOnWorkflowToolsFragment}
  ${workflowToolInputFragment}
  ${workflowRunIoItemFragment}
`;

const workflowRunFragment = gql`
  fragment workflowRunFragment on workflowRun {
    id
    name
    notes
    ...workflowRunBasicInfoWorkflowRunFragment
    workflowCollection {
      id
    }
    ...workflowRunJobFragment
    workflowDefinition {
      id
      name
      description
      isAdHoc
    }
    workflowTools {
      ...workflowToolFragment
    }
  }
  ${workflowRunBasicInfoWorkflowRunFragment}
  ${workflowRunJobFragment}
  ${workflowToolFragment}
`;

export default workflowRunFragment;
