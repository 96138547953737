/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Dialog, Intent } from "@blueprintjs/core";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import classNames from "classnames";
import renderOnDoc from "./renderOnDoc";
import { reduxForm } from "redux-form";
import { AlertWrapper } from "@teselagen/ui";

export default function asPromiseDialog(InnerComponent) {
  return ({ dialogProps = {}, ...otherProps }) =>
    new Promise((resolve, reject) => {
      renderOnDoc(handleClose => {
        const resolveToUse = (...args) => {
          handleClose();
          resolve(...args);
        };
        const rejectToUse = (...args) => {
          handleClose();
          reject(...args);
        };
        return (
          <Provider store={window.teGlobalStore}>
            <Router>
              <Dialog
                isOpen
                isCloseButtonShown={false}
                {...dialogProps}
                className={classNames(
                  dialogProps.className,
                  "as-promise-dialog"
                )}
              >
                <InnerComponent
                  {...{
                    handleClose,
                    hideModal: handleClose,
                    ...otherProps,
                    resolve: resolveToUse,
                    reject: rejectToUse
                  }}
                />
              </Dialog>
            </Router>
          </Provider>
        );
      });
    });
}

const AlertWrapperRedux = reduxForm({
  form: "confirmationDialogReduxForm"
})(AlertWrapper);

export function showReduxConfirmationDialog(
  opts = {
    text: "",
    noCancelButton: false,
    content: "",
    className: "",
    thirdButtonText: "",
    thirdButtonIntent: "",
    fourthButtonText: "",
    fourthButtonIntent: "",
    canEscapeKeyCancel: false,
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    intent: Intent.PRIMARY
  }
) {
  return new Promise(resolve => {
    renderOnDoc(handleClose => {
      return (
        <Provider store={window.teGlobalStore}>
          <Router>
            <AlertWrapperRedux {...{ ...opts, handleClose, resolve }} />
          </Router>
        </Provider>
      );
    });
  });
}
