/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";

export const getPlateMatch = (inputPlates = [], plateName, plateBarcode) => {
  return inputPlates.find(p => {
    const nameMatch =
      plateName && p.name.toLowerCase() === plateName.toLowerCase();
    const barcodeMatch =
      plateBarcode && get(p, "barcode.barcodeString") === plateBarcode;
    if (plateName && plateBarcode) {
      return nameMatch && barcodeMatch;
    } else if (plateName) {
      return nameMatch;
    } else {
      return barcodeMatch;
    }
  });
};
