/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";
import { createAssemblyReportReactionMaps } from "../../../utils/reactionMapUtils";
import { getHelpArticleLink } from "../../../../src-shared/utils/generalUtils";

const GenerateReactionMaps = ({
  hideModal,
  handleSubmit,
  submitting,
  j5ReportId
}) => {
  async function onSubmit(values) {
    try {
      await createAssemblyReportReactionMaps({
        j5ReportId,
        pcrName: values.pcrName,
        assemblyName: values.assemblyName,
        userTriggered: true
      });
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error generating reaction maps");
    }
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div style={{ marginBottom: 20 }}>
          <i>
            Create{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={getHelpArticleLink("3764239-glossary")}
            >
              reaction maps
            </a>{" "}
            from this report's PCR and Assembly data
          </i>
        </div>
        <InputField label="PCR Reaction Map Name" name="pcrName" isRequired />
        <InputField
          label="Assembly Reaction Map Name"
          name="assemblyName"
          isRequired
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Generate Reaction Maps"
  }),
  reduxForm({
    form: "generateReactionMaps"
  })
)(GenerateReactionMaps);
