/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import CustomerRequestRecordView from "../../Record/CustomerRequestRecordView";

import "./style.css";
import gql from "graphql-tag";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { cascadeCustomerRequestTagsToJobsAndWorkflow } from "../../Record/CustomerRequestRecordView/utils";
import UploadUserRequests from "./UploadUserRequests";

const afterTag = async ({ records, selectedTags }) => {
  await cascadeCustomerRequestTagsToJobsAndWorkflow({
    customerRequestIds: records.map(r => r.id),
    selectedTags
  });
};

function UserRequests(props) {
  return (
    <AbstractLibrary
      {...props}
      RecordViewInspector={CustomerRequestRecordView}
      afterTag={afterTag}
      onNewItemClick={() => {
        showDialog({
          modalType: "LaunchNewCustomerRequestDialog"
        });
      }}
      showUploadFunction={() => {
        showDialog({
          ModalComponent: UploadUserRequests,
          modalProps: {
            refetch: props.tableParams.onRefresh
          }
        });
      }}
      libraryName="user-request"
      withExport
    />
  );
}

const schema = {
  model: "customerRequest",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "requestType.name",
      type: "string",
      displayName: "Request Type"
    },
    {
      path: "dueDate",
      type: "timestamp",
      displayName: "Due Date"
    },
    {
      path: "customerRequestStatusType.name",
      type: "string",
      displayName: "Status"
    }
  ]
};

const fragment = gql`
  fragment customerRequestLibraryFragment on customerRequest {
    id
    name
    dueDate
    requestType {
      id
      name
    }
    customerRequestStatusType {
      code
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(UserRequests);
