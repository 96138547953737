/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent } from "@blueprintjs/core";
import StepForm from "../../../../src-shared/StepForm";
import { ReviewReactionMap, DefineReactionMap } from "./Steps";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";
import "./style.css";
import { SubmissionError } from "redux-form";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

export default class CreateReactionMap extends Component {
  onSubmit = async values => {
    const {
      reactions = [],
      reactionMapName,
      reactionTypeCode,
      reactionPrefix = "reaction"
    } = values;
    try {
      const newReactions = reactions.map((reaction, i) => {
        const {
          inputMaterials,
          consumedAdditiveMaterials,
          conservedAdditiveMaterials,
          outputMaterials,
          outputAdditiveMaterials
        } = reaction;
        const reactionWithInputsAndOutputs = {
          name: reaction.name || reactionPrefix + " " + (i + 1),
          ...getReactionInputsAndOutputs({
            inputMaterials,
            inputAdditiveMaterials: consumedAdditiveMaterials,
            inputConservedAdditiveMaterials: conservedAdditiveMaterials,
            outputMaterials,
            outputAdditiveMaterials
          })
        };
        return reactionWithInputsAndOutputs;
      });

      const reactionMap = {
        name: reactionMapName,
        reactionTypeCode,
        reactions: newReactions
      };

      const [createdReactionMap] = await safeUpsert("reactionMap", reactionMap);
      return {
        reactionMap: createdReactionMap
      };
    } catch (error) {
      console.error("error:", error);
      const message = error.message || "Error creating worklist.";
      throw new SubmissionError({
        _error: message
      });
    }
  };

  render() {
    const { initialValues, toolSchema, toolIntegrationProps } = this.props;
    const steps = [
      {
        title: "Define Reaction Map",
        Component: DefineReactionMap,
        withCustomFooter: true,
        props: {}
      },
      {
        title: "Review Reaction Map",
        nextButton: (
          <Button
            type="submit"
            text="Register Reaction Map"
            intent={Intent.SUCCESS}
          />
        ),
        Component: ReviewReactionMap
      }
    ];

    return (
      <StepForm
        steps={steps}
        validate={this.validate}
        toolIntegrationProps={toolIntegrationProps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        enableReinitialize
        initialValues={initialValues}
      />
    );
  }
}
