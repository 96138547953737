/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export default (fn, msg) => async (...args) => {
  try {
    return await fn(...args);
  } catch (e) {
    console.error(e);
    if (typeof msg === "function") {
      msg(e);
    } else if (typeof msg === "string") {
      window.toastr.error(msg);
    }
  }
};
