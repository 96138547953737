/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import { NonIdealState } from "@blueprintjs/core";
import { get } from "lodash";

export default function NoResourceFound({ location = {}, returnTo }) {
  const pathname = get(location, "state.invalidPathname", location.pathname);

  return (
    <div style={{ alignSelf: "center", height: "100%" }}>
      <Helmet title="Not Found" />
      <NonIdealState title="Not Found" icon="zoom-out">
        {pathname && (
          <div>
            No match for <code>{pathname}</code>
          </div>
        )}
        <br />
        <div className="flex-column-center">{returnTo || ""}</div>
      </NonIdealState>
    </div>
  );
}
