/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent, Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { showDeleteAlert } from "../../../utils";

import withPagingHandlers from "../../enhancers/withPagingHandlers";
import withLoadingHandlers from "../../enhancers/withLoadingHandlers";
import { containerArrayRecordViewAliquotContainerFragment } from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";
import { addAliquotsToAliquotContainers } from "../../../utils/updateAliquotsWithReagents";
import AliquotsTable from "./AliquotsTable";
import TubesTable from "./TubesTable";

import "./style.css";
import AdditivesTable from "./AdditivesTable";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import { aliquotUnitFields } from "../../Record/AliquotRecordView";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { sortAliquotContainers } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { renderMaterialsField } from "../../../utils/plateUtils";
import { containerArrayRecordFragment } from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";

export async function handleAssignment(options) {
  const {
    aliquotContainers = [],
    orderToAssign,
    aliquotsToAssign,
    tubesToAssign,
    containerArrayId,
    hideModal
  } = options;
  let sortedAliquotContainers = aliquotContainers;
  if (orderToAssign) {
    sortedAliquotContainers = sortAliquotContainers(
      aliquotContainers,
      orderToAssign
    );
  }

  try {
    if (aliquotsToAssign) {
      await addAliquotsToAliquotContainers(
        sortedAliquotContainers,
        aliquotsToAssign
      );
    } else {
      const tubeUpdates = [];
      for (const [
        index,
        aliquotContainer
      ] of sortedAliquotContainers.entries()) {
        const tubeToAssignHere = tubesToAssign[index];
        if (!tubeToAssignHere) break;
        tubeUpdates.push({
          id: tubeToAssignHere.id,
          rowPosition: aliquotContainer.rowPosition,
          columnPosition: aliquotContainer.columnPosition,
          containerArrayId
        });
      }
      await safeUpsert("aliquotContainer", tubeUpdates);
      await safeQuery(containerArrayRecordFragment, {
        variables: {
          id: containerArrayId
        }
      });
    }
    hideModal();
    return true;
  } catch (error) {
    console.error("error:", error);
    window.toastr.error(error.message || "Error updating aliquot location");
    return false;
  }
}

class InspectAliquotContainerDialog extends Component {
  state = {
    selectedAliquot: {}
  };

  onSubmit = async () => {
    const { aliquotContainer, hideModal, startLoading, stopLoading } =
      this.props;
    startLoading();
    const selectedAliquot = this.state.selectedAliquot;
    const finished = await handleAssignment({
      aliquotContainers: [aliquotContainer],
      aliquotsToAssign: [selectedAliquot],
      hideModal
    });
    if (!finished) {
      stopLoading();
    }
  };

  makeSelection = maybeRecord => {
    const { selectedAliquots, loading } = this.props;
    if (loading) return;
    const record = maybeRecord || selectedAliquots[0];
    this.setState({ selectedAliquot: record }, () => {
      this.onSubmit();
    });
  };

  makeTubeSelection = async maybeTube => {
    const {
      containerArrayId,
      aliquotContainer,
      selectedTubes,
      stopLoading,
      startLoading,
      hideModal,
      loading
    } = this.props;
    if (loading) return;
    const tube = maybeTube || selectedTubes[0];

    startLoading();
    const finished = await handleAssignment({
      containerArrayId,
      aliquotContainers: [aliquotContainer],
      tubesToAssign: [tube],
      hideModal
    });
    if (!finished) {
      stopLoading();
    }
  };

  deleteAliquot = async () => {
    const { hideModal, aliquotContainer } = this.props;
    try {
      hideModal();
      const continueDelete = await showDeleteAlert({ model: "aliquot" });
      if (continueDelete) {
        await safeDelete("aliquot", aliquotContainer.aliquot.id);
        await safeQuery(containerArrayRecordViewAliquotContainerFragment, {
          variables: {
            filter: {
              id: aliquotContainer.id
            }
          }
        });
      }
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Aliquot deleted at " + aliquotContainer.location);
    }
  };

  render() {
    const {
      hideModal,
      aliquotContainer,
      containerArrayType,
      history,
      selectedAliquots,
      page,
      nextPage,
      previousPage,
      loading,
      selectedTubes
    } = this.props;

    const { aliquot, aliquotContainerType } = aliquotContainer;
    let content;

    if (page === 1) {
      if (aliquot) {
        const recordInfo = aliquotUnitFields(aliquot);
        recordInfo.push([
          "Sample",
          aliquot.sample ? (
            <Link to={modelNameToLink(aliquot.sample)}>
              {aliquot.sample.name}
            </Link>
          ) : (
            "Not found"
          )
        ]);
        recordInfo.push(["Material", renderMaterialsField(aliquot)]);
        content = (
          <React.Fragment>
            <h6>Aliquot Information</h6>
            <RecordInfoDisplay recordInfo={recordInfo} />
            <hr className="tg-section-break" />
            <AdditivesTable aliquotId={aliquot.id} />
          </React.Fragment>
        );
      } else if (!aliquotContainerType) {
        content = <div>There is no tube assigned to this rack.</div>;
      } else {
        const msg = `This ${
          containerArrayType.isPlate ? "plate well" : "tube"
        } does not have an aliquot.`;
        content = (
          <div>
            {msg}
            <AdditivesTable aliquotContainerId={aliquotContainer.id} />
          </div>
        );
      }
    } else if (page === 2) {
      if (aliquotContainerType) {
        content = <AliquotsTable onDoubleClick={this.makeSelection} />;
      } else {
        content = (
          <TubesTable
            onDoubleClick={this.makeTubeSelection}
            containerArrayType={containerArrayType}
          />
        );
      }
    }
    let footer;
    if (aliquot) {
      footer = (
        <DialogFooter
          hideModal={hideModal}
          additionalButtons={
            <Button
              intent={Intent.DANGER}
              onClick={this.deleteAliquot}
              text="Delete Aliquot"
            />
          }
          submitting={loading}
          intent="primary"
          onClick={() => {
            history.push("/aliquots/" + aliquot.id);
            hideModal();
          }}
          text="View Aliquot"
        />
      );
    } else if (page === 1) {
      footer = (
        <DialogFooter
          hideModal={hideModal}
          submitting={loading}
          intent={Intent.PRIMARY}
          onClick={nextPage}
          text={
            !aliquotContainerType
              ? "Assign Tube to Rack"
              : containerArrayType.isPlate
                ? "Assign Aliquot to Plate"
                : "Assign Aliquot to Tube"
          }
        />
      );
    } else {
      footer = (
        <DialogFooter
          submitting={loading}
          secondaryAction={previousPage}
          secondaryText="Back"
          disabled={
            aliquotContainerType
              ? !selectedAliquots.length
              : !selectedTubes.length
          }
          onClick={() =>
            aliquotContainerType
              ? this.makeSelection()
              : this.makeTubeSelection()
          }
        />
      );
    }
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>{content}</div>
        {footer}
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      const { containerArrayType, aliquotContainer = {} } = props;
      let title;
      const location = getAliquotContainerLocation({
        ...aliquotContainer,
        containerArrayType
      });
      if (containerArrayType.isPlate) {
        title = `Inspect Well ${location}`;
      } else {
        title = `Inspect Position ${location}`;
      }
      return {
        title,
        style: {
          width: 800
        }
      };
    }
  }),
  withLoadingHandlers,
  withPagingHandlers
)(InspectAliquotContainerDialog);
