/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

const propTypes = {
  title: "",
  children: {}
};

class IconItem extends React.PureComponent {
  static defaultProps = {
    ...propTypes
  };

  render() {
    const { title, children } = this.props;
    return (
      <div className="schematic-icon">
        <div className="schematic-icon-button">
          <svg
            preserveAspectRatio="xMinYMin meet"
            width="50px"
            height="50px"
            style={{
              backgroundColor: "#efefef",
              borderRadius: "5px"
            }}
          >
            {children}
          </svg>
        </div>
        <span>{title}</span>
      </div>
    );
  }
}

export default IconItem;
