/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Button, Intent, Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  CheckboxField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import PlateMapPlate from "../../PlateMapPlate";

import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";

import { compose } from "recompose";
import { connect } from "react-redux";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";

import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import actions from "../../../../src-shared/redux/actions";
import {
  generateContainerArray,
  generateEmptyWells
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import containerArrayTypeFragment from "../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";

class ManuallyAssignTubeDialog extends Component {
  state = {
    page: 1,
    selectedAliquotContainer: null,
    loading: false,
    fetchedContainerArray: {},
    assignToExistingRack: false,
    createRack: false
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  fetchSelectedContainerArray = values => {
    const { containerArray } = values;
    this.setState({ loading: true });
    return safeQuery(containerArrayPlatePreviewFragment, {
      variables: {
        id: containerArray.id
      }
    })
      .then(fetchedContainerArray => {
        const numberOfWells =
          fetchedContainerArray.containerArrayType.containerFormat.columnCount *
          fetchedContainerArray.containerArrayType.containerFormat.rowCount;
        if (numberOfWells === fetchedContainerArray.aliquotContainers.length) {
          this.setState({ loading: false });
          return window.toastr.warning(
            "Selected rack is full, please select a different rack"
          );
        }
        this.setState({ fetchedContainerArray, loading: false });
        this.nextPage();
      })
      .catch(e => {
        this.setState({ loading: false });
        console.error(e);
        window.toastr.error("Error fetching rack");
      });
  };

  doUpsert = async (values = {}) => {
    const { createRack } = this.state;

    const aliquotContainer = this.state.selectedAliquotContainer;
    const {
      refetch,
      hideModal,
      aliquotContainer: aliquotContainerFromProps = {}
    } = this.props;
    try {
      let containerArrayId;
      if (createRack) {
        const { newRack } = values;
        const [createdRack] = await safeUpsert("containerArray", {
          name: newRack.name,
          containerArrayTypeId: newRack.containerArrayType.id,
          ...(!newRack.generateBarcode && {
            barcode: {
              barcodeString: values.newRack.barcode
            }
          })
        });
        if (values.newRack.generateBarcode) {
          await addBarcodesToRecords(createdRack);
        }
        containerArrayId = createdRack.id;
      } else {
        containerArrayId = values.containerArray.id;
      }

      this.setState({ loading: true });
      if (aliquotContainerFromProps.assignedPosition) {
        safeDelete(
          "assignedPosition",
          aliquotContainerFromProps.assignedPosition.id
        );
      }

      await safeUpsert("aliquotContainer", {
        id: aliquotContainerFromProps.id,
        containerArrayId,
        rowPosition: aliquotContainer.rowPosition,
        columnPosition: aliquotContainer.columnPosition
      });
      await refetch();
      hideModal();
      window.toastr.success("Successfully assigned tube");
    } catch (err) {
      console.error("err:", err);
      this.setState({
        loading: false
      });
    }
  };

  onExisitingRackClick = () => {
    this.nextPage();
    this.setState({ assignToExistingRack: true, createRack: false });
  };

  createNewRackClick = () => {
    this.nextPage();
    this.setState({ createRack: true, assignToExistingRack: false });
  };

  onAliquotContainerSelected = record => {
    this.setState({ selectedAliquotContainer: record });
  };

  onBackClick = () => {
    this.setState({ selectedAliquotContainer: null });
    this.previousPage();
  };

  renderPage1 = () => {
    return (
      <div>
        <div className="tg-flex column align-center">
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Button
              style={{ marginRight: 10 }}
              intent={Intent.PRIMARY}
              text="Select Existing Rack"
              onClick={this.onExisitingRackClick}
            />
            <Button
              style={{ marginLeft: 10 }}
              intent={Intent.PRIMARY}
              text="Create New Rack"
              onClick={this.createNewRackClick}
            />
          </div>
        </div>
      </div>
    );
  };

  renderPage2 = () => {
    const { assignToExistingRack, createRack, loading } = this.state;
    const {
      handleSubmit,
      hideModal,
      aliquotContainer = {},
      newRack = {},
      submitting,
      selectedAliquotContainerLocations = [],
      setSelectedAliquotContainerLocation
    } = this.props;

    return (
      <div>
        {assignToExistingRack && (
          <div>
            <div
              className={classNames(
                Classes.DIALOG_BODY,
                "tg-flex column align-center",
                "fill-react-select"
              )}
            >
              <h6>Select Destination Rack</h6>
              <GenericSelect
                name="containerArray"
                isRequired
                asReactSelect
                schema={[
                  "name",
                  {
                    displayName: "Barcode",
                    path: "barcode.barcodeString"
                  },
                  dateModifiedColumn
                ]}
                fragment={[
                  "containerArray",
                  `id
                      name
                      barcode {
                        id
                        barcodeString
                      }
                      updatedAt
                      `
                ]}
                tableParamOptions={{
                  additionalFilter: (props, qb) => {
                    qb.whereAll({
                      "containerArrayType.isPlate": false,
                      "containerArrayType.nestableTubeTypes.aliquotContainerTypeCode":
                        aliquotContainer.aliquotContainerType.code
                    });
                  }
                }}
              />
            </div>
            <DialogFooter
              hideModal={hideModal}
              loading={loading}
              secondaryAction={this.previousPage}
              secondaryText="Back"
              text="Select Rack"
              onClick={handleSubmit(this.fetchSelectedContainerArray)}
            />
          </div>
        )}
        {createRack && (
          <div>
            <div className={classNames(Classes.DIALOG_BODY)}>
              <div style={{ marginTop: 10 }}>
                <InputField
                  label="Name"
                  name="newRack.name"
                  placeholder="Enter Rack Name"
                />
                <CheckboxField
                  name="newRack.generateBarcode"
                  defaultValue
                  label="Generate Barcode"
                />
                {!newRack.generateBarcode && (
                  <InputField
                    name="newRack.barcode"
                    label="Rack Barcode"
                    placeholder="Enter Barcode..."
                  />
                )}
                <GenericSelect
                  asReactSelect
                  name="newRack.containerArrayType" //the field name within the redux form Field
                  label="Rack Type"
                  isRequired
                  schema={[
                    {
                      path: "name"
                    }
                  ]}
                  fragment={["containerArrayType", "id name"]}
                  additionalDataFragment={containerArrayTypeFragment}
                  tableParamOptions={{
                    additionalFilter: {
                      "nestableTubeTypes.aliquotContainerTypeCode":
                        aliquotContainer.aliquotContainerType.code
                    }
                  }}
                />
                {newRack.containerArrayType && (
                  <div className="tg-flex align-center">
                    <PlateMapPlate
                      {...{
                        selectedAliquotContainerLocations,
                        setSelectedAliquotContainerLocation,
                        containerArrayType: newRack.containerArrayType,
                        aliquotContainers: generateEmptyWells(
                          newRack.containerArrayType.containerFormat
                        ),
                        onAliquotContainerSelected: this
                          .onAliquotContainerSelected
                      }}
                    />
                  </div>
                )}
                {newRack.containerArrayType &&
                  !selectedAliquotContainerLocations.length && (
                    <BlueprintError error="Please select a location." />
                  )}
              </div>
            </div>
            <DialogFooter
              hideModal={hideModal}
              submitting={submitting}
              disabled={!selectedAliquotContainerLocations.length}
              additionalButtons={
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.previousPage}
                  text="Back"
                />
              }
              onClick={handleSubmit(this.doUpsert)}
            />
          </div>
        )}
      </div>
    );
  };

  renderPage3 = () => {
    const {
      loading,
      fetchedContainerArray,
      selectedAliquotContainer
    } = this.state;
    const {
      hideModal,
      aliquotContainer = {},
      selectedAliquotContainerLocations = [],
      setSelectedAliquotContainerLocation,
      handleSubmit
    } = this.props;

    let error;
    if (selectedAliquotContainer) {
      if (selectedAliquotContainer.id === aliquotContainer.id) {
        error = "This tube is already in this position";
      } else if (selectedAliquotContainer.id) {
        error =
          "A tube is already in this position. Please choose an empty position.";
      }
    }
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          {fetchedContainerArray && (
            <React.Fragment>
              <div className="tg-flex column align-center">
                <h6>Select Desired Tube Location</h6>
                <PlateMapPlate
                  {...{
                    selectedAliquotContainerLocations,
                    setSelectedAliquotContainerLocation,
                    containerArrayType:
                      fetchedContainerArray.containerArrayType,
                    aliquotContainers: generateContainerArray(
                      fetchedContainerArray.aliquotContainers,
                      fetchedContainerArray.containerArrayType.containerFormat
                    ),
                    onAliquotContainerSelected: this.onAliquotContainerSelected
                  }}
                />
              </div>
              <BlueprintError error={error} />
              <DialogFooter
                hideModal={hideModal}
                loading={loading}
                disabled={error || !selectedAliquotContainer}
                secondaryAction={this.onBackClick}
                secondaryText="Back"
                text="Select Location"
                onClick={handleSubmit(this.doUpsert)}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  render() {
    return this[`renderPage${this.state.page}`]();
  }
}

const mapStateToProps = state => {
  return {
    selectedAliquotContainerLocations:
      state.ui.records.containerArray.selectedAliquotContainerLocations
  };
};

const mapDispatchToProps = {
  setSelectedAliquotContainerLocation:
    actions.ui.records.containerArray.setSelectedAliquotContainerLocation
};

export default compose(
  wrapDialog({
    title: "Assign Tube to Rack"
  }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "manuallyAssignTube"
  }),
  tgFormValues("newRack")
)(ManuallyAssignTubeDialog);
