/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { SubmissionError } from "redux-form";
import { set, get, forEach, maxBy, isEmpty } from "lodash";
import { getAliquotTransferVolumeFromMass } from "../../../utils/plateUtils";

import StepForm from "../../../../src-shared/StepForm";
import {
  SelectJ5ConstructList,
  DestinationPlateConfiguration,
  ReviewReactionPlans
} from "./Steps";
import { makeMaterialToAliquotContainerMap } from "./utils";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { compose } from "recompose";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import {
  arpPlateMapGroupFragment,
  arpContainerArrayFragment
} from "./fragments";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import withQuery from "../../../../src-shared/withQuery";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

class dnaAssemblyReactionPlanningTool extends Component {
  onSubmit = async values => {
    const {
      worklist,
      destinationPlates,
      constructReactionMap: _constructReactionMap,
      worklistName,
      generateBarcodes,
      destinationPlateBarcodes = [],
      reactionMapName,
      shouldFillRack,
      generateTubeBarcodes,
      aliquotContainerType,
      containerArrayType
    } = values;

    try {
      const constructReactionMap = {
        ..._constructReactionMap,
        name: reactionMapName,
        reactions: _constructReactionMap.reactions.map(reaction => {
          const newReaction = {
            ...reaction
          };
          delete newReaction.inputs;
          delete newReaction.output;
          delete newReaction.inputMaterials;
          newReaction.reactionInputs = newReaction.reactionInputs.map(input => {
            if (input.csvUpload) {
              const cleanedInput = { ...input };
              delete cleanedInput.csvUpload;
              return cleanedInput;
            } else {
              return input;
            }
          });
          return newReaction;
        })
      };
      const platesToCreate = destinationPlates.map((plate, i) => {
        // if destination plate is not a rack OR is one and we want empty tubes generated
        if (plate.containerArrayType.isPlate || shouldFillRack) {
          plate.aliquotContainers = generateContainerArray(
            plate.aliquotContainers.map(ac => {
              const newAc = { ...ac };
              delete newAc.aliquotContainerType;
              return newAc;
            }),
            containerArrayType.containerFormat,
            {
              aliquotContainerTypeCode: plate.containerArrayType.isPlate
                ? containerArrayType.aliquotContainerTypeCode
                : aliquotContainerType.code
            }
          );
          // if destination plate is a rack and we don't want empty tubes generated
        } else {
          plate.aliquotContainers.map(ac => {
            const newAc = { ...ac };
            delete newAc.aliquotContainerType;
            return newAc;
          });
        }
        const newPlate = {
          ...plate
        };
        if (!generateBarcodes)
          newPlate.barcode = { barcodeString: destinationPlateBarcodes[i] };
        delete newPlate.containerArrayType;
        return newPlate;
      });
      const createdPlates = await safeUpsert(
        [
          "containerArray",
          `id
        aliquotContainers {
          id
          aliquot {
            id
          }
        }
        `
        ],
        platesToCreate
      );
      if (generateBarcodes) {
        await addBarcodesToRecords(createdPlates);
      }
      if (generateTubeBarcodes) {
        const createdAliquotContainers = [];
        createdPlates.forEach(p => {
          p.aliquotContainers.forEach(ac => createdAliquotContainers.push(ac));
        });
        await addBarcodesToRecords(createdAliquotContainers);
      }
      const worklistToUpsert = stripFields(worklist, ["__typename"]);
      worklistToUpsert.name = worklistName;
      worklistToUpsert.worklistTransfers = worklistToUpsert.worklistTransfers.map(
        transfer => {
          delete transfer.assemblyPieceName;
          delete transfer.constructName;
          delete transfer.sourcePlateName;
          delete transfer.sourceAliquotContainer;
          delete transfer.sourceAliquotPosition;
          delete transfer.destinationAliquotContainer;
          delete transfer.destinationPlateName;
          delete transfer.destinationAliquotContainerLocation;
          return transfer;
        }
      );

      const [newReactionMap] = await safeUpsert(
        "reactionMap",
        constructReactionMap
      );
      worklistToUpsert.worklistReactionMaps = [
        {
          reactionMapId: newReactionMap.id
        }
      ];
      const [newWorklist] = await safeUpsert("worklist", worklistToUpsert);
      window.toastr.success("Successfully created worklist.");
      return {
        worklist: newWorklist,
        reactionMap: newReactionMap,
        containerArrays: createdPlates
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating worklist."
      });
    }
  };

  validate = values => {
    const {
      assemblyMaterials = [],
      containerArrays = [],
      aliquotContainers = [],
      universalTransferValue,
      applyUniversalTransfer,
      universalTransferUnitCode,
      universalTransferType,
      validationPlateMapGroup,
      constructMap,
      materialTransferInfo = {}
    } = values;

    const errors = {};

    // so the test below only checks if there are incorrect materials on the plate map, need to make sure that all of the constructs from the map are present on the  plate map

    const constructMaterialIds = [];

    !isEmpty(constructMap) &&
      forEach(constructMap, v => {
        constructMaterialIds.push(v.sequence.polynucleotideMaterial.id);
      });

    if (!isEmpty(validationPlateMapGroup)) {
      if (
        validationPlateMapGroup.plateMaps.some(plateMap =>
          plateMap.plateMapItems.some(
            pmi => !get(pmi, "inventoryItem.material.id")
          )
        )
      ) {
        errors.validationPlateMapGroup =
          "The selected plate map is not linked to materials. Please select a plate map of materials to continue.";
      } else {
        const plateMapMaterialIds = [];
        validationPlateMapGroup.plateMaps.forEach(plateMap => {
          plateMap.plateMapItems.forEach(pmi => {
            plateMapMaterialIds.push(pmi.inventoryItem.material.id);
          });
        });
        if (
          !isEmpty(constructMap) &&
          !constructMaterialIds.every(id => plateMapMaterialIds.includes(id))
        ) {
          errors.validationPlateMapGroup = `The selected plate map is missing necessary constructs for this reaction. Please select a different plate map or continue without one.`;
        }
        if (
          validationPlateMapGroup.plateMaps.some(plateMap =>
            plateMap.plateMapItems.some(
              pmi =>
                !constructMaterialIds.includes(
                  get(pmi, "inventoryItem.material.id")
                )
            )
          )
        ) {
          errors.validationPlateMapGroup =
            "The selected plate map contains extraneous materials. Please select a different plate map or continue without one.";
        }
      }
    }
    if (!containerArrays.length && !aliquotContainers.length) {
      errors.containerArrays = "Please select one or more source plates.";
      errors.aliquotContainers = "Please select one or more source tubes.";
    } else {
      const dryAliquot = ac => ac.aliquot && ac.aliquot.isDry;
      const dryTube = aliquotContainers.some(dryAliquot);
      if (dryTube) {
        errors.aliquotContainers = "Tubes have dry aliquots.";
      }
    }

    const remainingVolumeForAliquotContainers = {};

    const canHandleTransfer = (acList, standardTransferVolume) => {
      acList.forEach(ac => {
        if (!remainingVolumeForAliquotContainers[ac.id]) {
          const standardAliquotVolume = standardizeVolume(
            ac.aliquot.volume,
            ac.aliquot.volumetricUnitCode
          );
          remainingVolumeForAliquotContainers[ac.id] = standardAliquotVolume;
        }
      });
      const containerWithMostVolumeLeft = maxBy(
        acList,
        ac => remainingVolumeForAliquotContainers[ac.id]
      );
      const remainingVolume =
        remainingVolumeForAliquotContainers[containerWithMostVolumeLeft.id];
      const hasEnoughVolume = standardTransferVolume <= remainingVolume;

      // take transfer from tracker
      remainingVolumeForAliquotContainers[containerWithMostVolumeLeft.id] =
        remainingVolume - standardTransferVolume;
      return hasEnoughVolume;
    };

    const getAliquotContainerWithMostVolume = acList => {
      acList.forEach(ac => {
        if (!remainingVolumeForAliquotContainers[ac.id]) {
          const standardAliquotVolume = standardizeVolume(
            ac.aliquot.volume,
            ac.aliquot.volumetricUnitCode
          );
          remainingVolumeForAliquotContainers[ac.id] = standardAliquotVolume;
        }
      });
      const containerWithMostVolume = maxBy(
        acList,
        ac => remainingVolumeForAliquotContainers[ac.id]
      );

      return containerWithMostVolume;
    };

    const materialToAcMap = makeMaterialToAliquotContainerMap({
      containerArrays,
      aliquotContainers,
      assemblyMaterials
    });
    if (!applyUniversalTransfer) {
      forEach(
        materialTransferInfo,
        ({ transferValue, quantityType, transferUnit } = {}, key) => {
          const errorKey = `materialTransferInfo[${key}].transferValue`;
          if (!isValidPositiveNumber(transferValue)) {
            set(errors, errorKey, "Please enter a valid positive number.");
          } else {
            const acList = materialToAcMap[key.replace("id", "")];
            const aliquotContainerWithMostVolume = getAliquotContainerWithMostVolume(
              acList
            );
            if (quantityType === "volume") {
              const standardTransferVolume = standardizeVolume(
                transferValue,
                unitGlobals.volumetricUnits[transferUnit] ? transferUnit : "uL"
              );

              if (!canHandleTransfer(acList, standardTransferVolume)) {
                set(
                  errors,
                  errorKey,
                  `Aliquots with this material do not have enough volume for all transfers.`
                );
              }
            } else {
              if (!aliquotContainerWithMostVolume.aliquot.concentration) {
                set(errors, errorKey, `Aliquot does not have a concentration.`);
              } else {
                const standardTransferVolume = standardizeVolume(
                  getAliquotTransferVolumeFromMass(
                    aliquotContainerWithMostVolume.aliquot,
                    transferValue,
                    unitGlobals.massUnits[transferUnit] ? transferUnit : "ng"
                  ),
                  aliquotContainerWithMostVolume.aliquot.volumetricUnitCode
                );
                if (!canHandleTransfer(acList, standardTransferVolume)) {
                  set(
                    errors,
                    errorKey,
                    `Aliquot cannot reach desired transfer quantity.`
                  );
                }
              }
            }
          }
        }
      );
    } else {
      if (!isValidPositiveNumber(universalTransferValue)) {
        errors.universalTransferValue = "Please enter a valid transfer value.";
      } else {
        let error;
        for (const aliquotContainers of Object.values(materialToAcMap)) {
          const aliquotContainer = getAliquotContainerWithMostVolume(
            aliquotContainers
          );
          const materialName = aliquotContainer.aliquot.sample.material.name;
          if (
            universalTransferType === "mass" &&
            !aliquotContainer.aliquot.concentration
          ) {
            error = `The aliquot with material ${materialName} does not have a concentration value to compute volume from mass.`;
            break;
          }
          let standardTransferVolume;
          if (universalTransferType === "volume") {
            const transferCode = unitGlobals.volumetricUnits[
              universalTransferUnitCode
            ]
              ? universalTransferUnitCode
              : "uL";
            standardTransferVolume = standardizeVolume(
              universalTransferValue,
              transferCode
            );
          } else {
            standardTransferVolume = standardizeVolume(
              getAliquotTransferVolumeFromMass(
                aliquotContainer.aliquot,
                universalTransferValue,
                unitGlobals.massUnits[universalTransferUnitCode]
                  ? universalTransferUnitCode
                  : "ng"
              ),
              aliquotContainer.aliquot.volumetricUnitCode
            );
          }
          if (!canHandleTransfer(aliquotContainers, standardTransferVolume)) {
            error = `The aliquot with material ${materialName} does not have enough volume for transfer.`;
          }
        }
        if (error) {
          errors.universalTransferValue = error;
        }
      }
    }
    return errors;
  };

  render() {
    const {
      containerArrayTypes,
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues,
      aliquotContainerTypes
    } = this.props;

    const steps = [
      {
        title: "Select j5 Construct List",
        Component: SelectJ5ConstructList,
        withCustomFooter: true
      },
      {
        title: "Destination Plate Configuration",
        Component: DestinationPlateConfiguration,
        withCustomFooter: true,
        props: {
          containerArrayTypes,
          aliquotContainerTypes
        }
      },
      {
        title: "Review Reaction Plans",
        Component: ReviewReactionPlans,
        withCustomFooter: true,
        props: { aliquotContainerTypes }
      }
    ];

    return (
      <StepForm
        validate={this.validate}
        steps={steps}
        toolIntegrationProps={toolIntegrationProps}
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(arpContainerArrayFragment),
  withWorkflowInputs(["dataTable", "id name dataSet { id name } updatedAt"], {
    inputName: "constructLists",
    initialValueName: "partialConstructLists"
  }),
  withWorkflowInputs(arpPlateMapGroupFragment, {
    singular: true,
    initialValueName: "validationPlateMapGroup"
  }),
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  })
)(dnaAssemblyReactionPlanningTool);
