/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { uniqBy, has } from "lodash";
import { Classes } from "@blueprintjs/core";
import { DataTable, DialogFooter } from "@teselagen/ui";
import { compose } from "recompose";
import { wrapDialog } from "@teselagen/ui";

class AminoAcidPartLibraryDialog extends React.Component {
  state = {
    aaParts: [],
    filteringTags: [],
    submitting: false
  };

  async componentDidUpdate(nextProps) {
    const tp = nextProps.tableParams;
    // TODO props.tableParams.pageSize vs. props.pageSize  (!?)
    // Seems there are duplicate and conflicting props
    const maxPage = Math.max(1, Math.ceil(tp.entityCount / tp.pageSize));
    if (tp.page > maxPage) {
      tp.setPage(maxPage);
    }

    const savedOrder = has(nextProps.tableParams, "currentParams.order")
      ? nextProps.tableParams.currentParams.order[0]
      : undefined;

    const order = savedOrder !== undefined ? savedOrder : "-modified";

    if ((!tp.order || !tp.order.length) && tp.setOrder && order !== undefined) {
      await tp.setOrder(order, undefined, false);
    }
  }

  wrappedHideModal = () => {
    const { hideModal, resolve } = this.props;
    hideModal();
    if (resolve) resolve();
  };

  handleSelect = aaParts => {
    const newAAParts = Array.isArray(aaParts) ? aaParts : [aaParts];
    this.setState({
      aaParts: uniqBy(this.state.aaParts.concat(newAAParts), "id")
    });
  };

  handleDoubleClick = async aaPart => {
    this.setState({ submitting: true });
    await this.props.onSubmit([aaPart]);
    this.props.hideModal();
  };

  render() {
    const {
      tableParams,
      hideModal,
      onSubmit,
      isSingleSelect,
      withCheckboxes = false,
      selectedEntities
    } = this.props;
    const { aaParts, submitting } = this.state;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <DataTable
            formName="AminoAcidPartLibraryDialog"
            {...tableParams}
            // entities={tableParams.entities}
            isSingleSelect={isSingleSelect}
            // entityCount={aminoAcidPartsCount}
            withSearch
            withPaging
            withDisplayOptions
            canOutsideClickClose={false}
            isInfinite={false}
            onSingleRowSelect={this.handleSelect}
            onMultiRowSelect={this.handleSelect}
            onDoubleClick={this.handleDoubleClick}
            withCheckboxes={withCheckboxes}
          />
        </div>
        <DialogFooter
          hideModal={this.wrappedHideModal}
          text="OK"
          disabled={!aaParts.length}
          loading={submitting}
          onClick={async () => {
            this.setState({ submitting: true });
            const stillSelectedPartIds = selectedEntities.map(ent => ent.id);

            await onSubmit(
              aaParts.filter(part => stillSelectedPartIds.indexOf(part.id) > -1)
            );

            hideModal();
          }}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: ({ title }) => {
      return {
        title: title || "Choose Amino Acid Part",
        style: {
          width: 950
        }
      };
    }
  }),
  reduxForm({
    form: "aminoAcidPartLibraryDialog",
    enableReinitialize: true
  })
)(AminoAcidPartLibraryDialog);
