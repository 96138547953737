/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { InputField } from "@teselagen/ui";

export const selectReagentsFragment = ["additiveMaterial", "id name updatedAt"];

function SelectReagents(props) {
  const {
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
  } = props;

  return (
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between column">
        <HeaderWithHelper
          header="Name Table"
          helper="Choose a name for the new table."
        />
        <div style={{ maxWidth: 250 }}>
          <InputField label="Table Name" name="tableName" isRequired />
        </div>
        <HeaderWithHelper
          header="Select Reagents"
          helper="Select reagents you would like to populate the table."
        />
        <div>
          <GenericSelect
            {...{
              name: "additiveMaterials",
              schema: ["name", dateModifiedColumn],
              isRequired: true,
              isMultiSelect: true,
              buttonProps: {
                disabled: isDisabledMap.additiveMaterials,
                loading: isLoadingMap.additiveMaterials
              },
              fragment: selectReagentsFragment,
              postSelectDTProps: {
                formName: "selectReagentsToolTable",
                schema: ["name"]
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectReagents;
