/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { withState, withHandlers, compose } from "recompose";

export default compose(
  withState("loading", "setLoading", false),
  withHandlers({
    startLoading: ({ setLoading }) => () => setLoading(true),
    stopLoading: ({ setLoading }) => () => setLoading(false)
  })
);
