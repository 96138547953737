/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose, withHandlers, withProps } from "recompose";
import { extendedPropertyLinkableModels } from "../../../tg-iso-shared/constants";
import { capitalizeModel } from "@teselagen/utils";
import withQueryDynamic from "../withQueryDynamic";
import pluralize from "pluralize";

/**
 * For a given componenet with `model` and `recordId` in the props, query for
 * the extended property values for that record
 */

const extendedValuesFragment = `
  id
  value
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedTypeCode
    extendedPropertyClassCode
  }
`;

const extendedCategoryValuesFragment = `
  id
  extendedCategoryId
  extendedCategory {
    id
    name
  }
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedTypeCode
    extendedPropertyClassCode
    extendedCategoryClass {
      id
      name
      extendedCategories {
        id
        name
      }
    }
  }
`;

const extendedMeasurementValuesFragment = `
  id
  value
  measurementUnitId
  measurementUnit {
    id
    abbreviation
  }
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedTypeCode
    extendedPropertyClassCode
    extendedMeasurementUnitClass {
      id
      name
      baseMeasurementUnitId
      baseMeasurementUnit {
        id
        name
        abbreviation
        conversionFactor
      }
      measurementUnits {
        id
        name
        abbreviation
        conversionFactor
      }
    }
  }
`;

export let extendedLinkValueString = "";
extendedPropertyLinkableModels.forEach(model => {
  const capM = capitalizeModel(model);
  extendedLinkValueString += `\n${model}Elvs {
    id
    source${capM} {
      id
      ${model !== "aliquot" ? "name" : ""}
    }
  }\n`;
});

export const extendedValuesLibraryFragment = `
extendedValues {
  id
  extendedPropertyId
  value
}
extendedCategoryValues {
  id
  extendedCategoryId
  extendedPropertyId
}
extendedMeasurementValues {
  id
  value
  measurementUnitId
  extendedPropertyId
}
extendedLinkValues {
  id
  ${extendedLinkValueString}
  extendedPropertyId
}
`;

export const extendedLinkValuesFragment = `
  id
  ${extendedLinkValueString}
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedPropertyClassCode
    targetModel
  }
`;

export const getWithRecordExtendedPropertyFragment = model => {
  return [
    model,
    `
      id
      extendedValues {
        ${extendedValuesFragment}
      }
      extendedCategoryValues {
        ${extendedCategoryValuesFragment}
      }
      extendedMeasurementValues {
        ${extendedMeasurementValuesFragment}
      }
      extendedLinkValues {
        ${extendedLinkValuesFragment}
      }
    `
  ];
};

export default compose(
  withProps(props => {
    const { model, recordId, recordIds } = props;
    return {
      runTimeQueryOptions: {
        isPlural: !!recordIds?.length,
        fragment: getWithRecordExtendedPropertyFragment(model),
        options: {
          variables: {
            ...(recordIds
              ? {
                  filter: {
                    id: recordIds
                  }
                }
              : {
                  id: recordId
                })
          }
        }
      }
    };
  }),
  withQueryDynamic(),
  withProps(props => {
    const { model, recordIds } = props;
    if (recordIds?.length) {
      return {
        recordsWithExtendedProperties: props[pluralize(model)]
      };
    } else {
      const recordWithExtPropValues = props[model];
      if (recordWithExtPropValues) {
        const {
          extendedValues,
          extendedCategoryValues,
          extendedMeasurementValues,
          extendedLinkValues
        } = recordWithExtPropValues;
        return {
          extendedValues,
          extendedCategoryValues,
          extendedMeasurementValues,
          extendedLinkValues
        };
      }
    }
  }),
  withHandlers({
    refetchExtendedProperties: props => async () => {
      await props.data.refetch();
    }
  })
);
