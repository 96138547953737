/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, uniq, forEach } from "lodash";
import shortid from "shortid";
import { unparse } from "papaparse";
import { isBrowser } from "browser-or-node";
import { sortAliquotContainers } from "./plateUtils";
import { getAliquotContainerLocation } from "./getAliquotContainerLocation";
import { convertVolume } from "./unitUtils/convertUnits";
import { isoContext } from "@teselagen/utils";
import roundValueWithUnit from "../../../tg-iso-shared/src/utils/roundValueWIthUnit";
import gql from "graphql-tag";
import { worklistFormats } from "./worklistUtils";

const exportWorklistContainerArrayFragment = gql`
  fragment exportWorklistContainerArrayFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      containerFormat {
        code
        is2DLabeled
        rowCount
        columnCount
      }
    }
  }
`;

const exportWorklistAliquotContainerFragment = gql`
  fragment exportWorklistAliquotContainerFragment on aliquotContainer {
    id
    name
    rowPosition
    columnPosition
    containerArrayId
    containerArray {
      ...exportWorklistContainerArrayFragment
    }
    barcode {
      id
      barcodeString
    }
    aliquot {
      id
      sample {
        id
        name
      }
    }
  }
  ${exportWorklistContainerArrayFragment}
`;

const exportWorklistFragment = gql`
  fragment exportWorklistFragment on worklist {
    id
    name
    worklistTransfers {
      id
      volume
      volumetricUnitCode
      volumetricUnit {
        code
        name
        liters
      }
      sourceAliquotContainerId
      sourceAliquotContainer {
        ...exportWorklistAliquotContainerFragment
        additives {
          id
          lot {
            id
            name
            additiveMaterial {
              id
              name
            }
          }
          additiveMaterial {
            id
            name
          }
        }
      }
      destinationAliquotContainerId
      destinationAliquotContainer {
        ...exportWorklistAliquotContainerFragment
      }
    }
  }
  ${exportWorklistAliquotContainerFragment}
`;

function getWellNumberOnPlate(rowCount, columnPosition, rowPosition) {
  const wellNumber =
    columnPosition === 0
      ? rowPosition + 1
      : rowPosition + 1 + columnPosition * rowCount;
  return wellNumber;
}

function getVolumeToTwoDecimalPlaces(transferVolume) {
  // checking if the volume is an integer
  if (transferVolume - Math.floor(transferVolume) === 0) {
    return transferVolume;
  } else {
    // the volume is a decimal
    const transferVolumeTwoDecimals = transferVolume.toFixed(2);
    // getting rid of unnecessary ending zeros
    // if .00 (e.g. if original value was .000001)
    if (
      transferVolumeTwoDecimals.slice(-1) === "0" &&
      transferVolumeTwoDecimals.slice(-2, -1) === "0"
    ) {
      // also take away decimal point
      return transferVolumeTwoDecimals.slice(0, -3);
      // if e.g. .50
    } else if (
      transferVolumeTwoDecimals.slice(-1) === "0" &&
      transferVolumeTwoDecimals.slice(-2, -1) !== "0"
    ) {
      return transferVolumeTwoDecimals.slice(0, -1);
      // if e.g. .55
    } else {
      return transferVolumeTwoDecimals;
    }
  }
}

const getSourceAndDestPlateNames = transfer => {
  const destinationPlateName = transfer.destinationAliquotContainer
    .containerArrayId
    ? transfer.destinationAliquotContainer.containerArray.name
    : transfer.destinationAliquotContainer.name;
  const sourcePlateName = transfer.sourceAliquotContainer.containerArrayId
    ? transfer.sourceAliquotContainer.containerArray.name
    : transfer.sourceAliquotContainer.name;
  return {
    sourcePlateName: sourcePlateName || "",
    destinationPlateName: destinationPlateName || ""
  };
};

const getSourceAndDestPlateBarcodes = transfer => {
  let destinationPlateBarcode;
  if (
    transfer.destinationAliquotContainer.containerArray?.barcode?.barcodeString
  ) {
    destinationPlateBarcode =
      transfer.destinationAliquotContainer.containerArray.barcode.barcodeString;
  } else if (transfer.destinationAliquotContainer.barcode?.barcodeString) {
    destinationPlateBarcode =
      transfer.destinationAliquotContainer.barcode.barcodeString;
  } else {
    destinationPlateBarcode = "";
  }
  let sourcePlateBarcode;
  if (transfer.sourceAliquotContainer.containerArray?.barcode?.barcodeString) {
    sourcePlateBarcode =
      transfer.sourceAliquotContainer.containerArray.barcode.barcodeString;
  } else if (transfer.sourceAliquotContainer.barcode?.barcodeString) {
    sourcePlateBarcode = transfer.sourceAliquotContainer.barcode.barcodeString;
  } else {
    sourcePlateBarcode = "";
  }
  return {
    sourcePlateBarcode,
    destinationPlateBarcode
  };
};

const getTransferVolumeInUnit = (transfer, desiredUnit, units) => {
  if (transfer.volumetricUnitCode === desiredUnit) {
    return transfer.volume;
  } else {
    return convertVolume(
      transfer.volume,
      transfer.volumetricUnitCode,
      desiredUnit,
      units
    );
  }
};

const noUnparse = {
  Tecan: true
};

const noHeader = {
  QPix: true
};

function getFileContent(
  worklist,
  format,
  barcodeAsPlateName,
  sourceIdToSlotPosMap,
  destinationIdToSlotPosMap,
  options
) {
  if (worklistRowMap[format]) {
    // exporting in a default worklist format
    let fileText = worklistRowMap[format](
      worklist,
      barcodeAsPlateName,
      sourceIdToSlotPosMap,
      destinationIdToSlotPosMap,
      options
    );
    if (!noUnparse[format]) {
      fileText = unparse(fileText, { header: !noHeader[format] });
    }
    return fileText;
  } else {
    // exporting in a custom worklist format, so return worklist info to pass to the endpoint
    return worklist;
  }
}

function getFileName(worklist, format, seenFileNames = {}) {
  let fileName = `${worklist.name}_${format}`;
  if (seenFileNames[fileName]) {
    fileName = fileName + " " + seenFileNames[fileName];
    seenFileNames[fileName]++;
  } else {
    seenFileNames[fileName] = 1;
  }
  return `${fileName}.${noUnparse[format] ? "txt" : "csv"}`;
}

const worklistRowMap = {
  Echo: worklist => {
    return worklist.worklistTransfers.map(function (transfer) {
      const transferVolumeInNanoLiters = getTransferVolumeInUnit(
        transfer,
        "nL"
      );
      const { destinationPlateBarcode, sourcePlateBarcode } =
        getSourceAndDestPlateBarcodes(transfer);
      const { destinationPlateName, sourcePlateName } =
        getSourceAndDestPlateNames(transfer);

      const echoWorklistFormat = {
        "Sample Name": get(
          transfer,
          "sourceAliquotContainer.aliquot.sample.name"
        ),
        "Source Plate Name": sourcePlateName.replace(/,| /g, "-"),
        "Source Plate Barcode": sourcePlateBarcode.replace(/,| /g, "-"),
        "Source Plate Type": get(
          transfer,
          "sourceAliquotContainer.containerArray.containerArrayType.name",
          ""
        ),
        "Source Well": getAliquotContainerLocation(
          transfer.sourceAliquotContainer
        ),
        "Destination Plate Name": destinationPlateName.replace(/,| /g, "-"),
        "Destination Plate Barcode": destinationPlateBarcode.replace(
          /,| /g,
          "-"
        ),
        "Destination Well": getAliquotContainerLocation(
          transfer.destinationAliquotContainer
        ),
        "Transfer Volume": getVolumeToTwoDecimalPlaces(
          transferVolumeInNanoLiters
        ),
        "Sample Comment": get(
          transfer,
          "destinationAliquotContainer.aliquot.sample.name",
          ""
        )
      };
      return echoWorklistFormat;
    });
  },
  Tecan: (worklist, barcodeAsPlateName) => {
    return worklist.worklistTransfers.map(function (transfer) {
      let sourcePlateTitle, destinationPlateTitle;
      if (barcodeAsPlateName) {
        const { destinationPlateBarcode, sourcePlateBarcode } =
          getSourceAndDestPlateBarcodes(transfer);
        destinationPlateTitle = destinationPlateBarcode.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateBarcode.replace(/,| /g, "-");
      } else {
        const { destinationPlateName, sourcePlateName } =
          getSourceAndDestPlateNames(transfer);
        destinationPlateTitle = destinationPlateName.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateName.replace(/,| /g, "-");
      }
      const transferVolumeInMicroLiters = getTransferVolumeInUnit(
        transfer,
        "uL"
      );
      const rowCountSourcePlate = get(
        worklist,
        "worklistTransfers[0].sourceAliquotContainer.containerArray.containerArrayType.containerFormat.rowCount",
        0
      );

      const rowCountDestPlate = get(
        worklist,
        "worklistTransfers[0].destinationAliquotContainer.containerArray.containerArrayType.containerFormat.rowCount",
        0
      );

      const aspirateDispense =
        "\rA;" +
        sourcePlateTitle +
        ";;;" +
        getWellNumberOnPlate(
          rowCountSourcePlate,
          transfer.sourceAliquotContainer.columnPosition,
          transfer.sourceAliquotContainer.rowPosition
        ) +
        ";;" +
        getVolumeToTwoDecimalPlaces(transferVolumeInMicroLiters) +
        "\rD;" +
        destinationPlateTitle +
        ";;;" +
        getWellNumberOnPlate(
          rowCountDestPlate,
          transfer.destinationAliquotContainer.columnPosition,
          transfer.destinationAliquotContainer.rowPosition
        ) +
        ";;" +
        Math.round(transferVolumeInMicroLiters);

      return aspirateDispense;
      // Aspirate format (i.e. source plate): A;PlateName;;;Position;;Volume
      // Dispense format (i.e. destination plate): D;PlateName;;;Position;;Volume
    });
  },
  Hamilton: (worklist, barcodeAsPlateName) => {
    return worklist.worklistTransfers.map(function (transfer) {
      let sourcePlateTitle, destinationPlateTitle;
      if (barcodeAsPlateName) {
        const { destinationPlateBarcode, sourcePlateBarcode } =
          getSourceAndDestPlateBarcodes(transfer);
        destinationPlateTitle = destinationPlateBarcode.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateBarcode.replace(/,| /g, "-");
      } else {
        const { destinationPlateName, sourcePlateName } =
          getSourceAndDestPlateNames(transfer);
        destinationPlateTitle = destinationPlateName.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateName.replace(/,| /g, "-");
      }
      const transferVolumeInMicroLiters = getTransferVolumeInUnit(
        transfer,
        "uL"
      );
      return {
        "Source Plate Name": sourcePlateTitle,
        "Source Well": getAliquotContainerLocation(
          transfer.sourceAliquotContainer
        ),
        "Destination Plate Name": destinationPlateTitle,
        "Destination Well": getAliquotContainerLocation(
          transfer.destinationAliquotContainer
        ),
        "Transfer Volume": getVolumeToTwoDecimalPlaces(
          transferVolumeInMicroLiters
        )
      };
    });
  },
  "F.A.S.T.": (worklist, barcodeAsPlateName) => {
    return worklist.worklistTransfers.map(function (transfer) {
      let sourcePlateTitle, destinationPlateTitle;
      if (barcodeAsPlateName) {
        const { destinationPlateBarcode, sourcePlateBarcode } =
          getSourceAndDestPlateBarcodes(transfer);
        destinationPlateTitle = destinationPlateBarcode.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateBarcode.replace(/,| /g, "-");
      } else {
        const { destinationPlateName, sourcePlateName } =
          getSourceAndDestPlateNames(transfer);
        destinationPlateTitle = destinationPlateName.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateName.replace(/,| /g, "-");
      }
      const transferVolumeInNanoLiters = getTransferVolumeInUnit(
        transfer,
        "nL"
      );
      return {
        "Source Plate Name": sourcePlateTitle,
        "Source Well": getAliquotContainerLocation(
          transfer.sourceAliquotContainer
        ),
        "Destination Plate Name": destinationPlateTitle,
        "Destination Well": getAliquotContainerLocation(
          transfer.destinationAliquotContainer
        ),
        "Transfer Volume (nL)": getVolumeToTwoDecimalPlaces(
          transferVolumeInNanoLiters
        )
      };
    });
  },
  Mantis: worklist => {
    return worklist.worklistTransfers.map(function (transfer) {
      const transferVolumeInMicroLiters = getTransferVolumeInUnit(
        transfer,
        "uL"
      );
      const sourceAliquotSampleName = get(
        transfer,
        "sourceAliquotContainer.aliquot.sample.name"
      );
      const sourceAliquotAdditiveLotName = get(
        transfer,
        "sourceAliquotContainer.additives[0].lot.name"
      );
      const sourceAliquotAdditiveMaterialName = get(
        transfer,
        "sourceAliquotContainer.additives[0].additiveMaterial.name"
      );

      return {
        // destination well
        Well: getAliquotContainerLocation(transfer.destinationAliquotContainer),
        Volume: getVolumeToTwoDecimalPlaces(transferVolumeInMicroLiters),
        Reagent:
          sourceAliquotSampleName ||
          sourceAliquotAdditiveLotName ||
          sourceAliquotAdditiveMaterialName
      };
    });
  },
  PipetMax: (worklist, barcodeAsPlateName) => {
    const identifier = shortid();
    const indexesOfMultiplePlateTransfers = [];
    const worklistTransfers = worklist.worklistTransfers;
    worklistTransfers.forEach((transfer, i) => {
      if (i !== 0) {
        const { destinationPlateName, sourcePlateName } =
          getSourceAndDestPlateNames(transfer);
        const {
          destinationPlateName: previousDestinationPlateName,
          sourcePlateName: previousSourcePlateName
        } = getSourceAndDestPlateNames(worklistTransfers[i - 1]);
        // if there are multiple plate transfers
        if (
          sourcePlateName !== previousSourcePlateName ||
          destinationPlateName !== previousDestinationPlateName
        ) {
          // noting where in worklistTransfers we move on to a different plate transfer
          indexesOfMultiplePlateTransfers.push(i);
        }
      }
    });
    let arrayOfPlateTransfers = [];
    if (indexesOfMultiplePlateTransfers.length !== 0) {
      // e.g. [5, 8, 11] becomes [ [worklistTransfers[0-4]], [worklistTransfers[5-7]], [worklistTransfers[8-10]], [worklistTransfers[11-end]] ]
      indexesOfMultiplePlateTransfers.forEach((value, i) => {
        // first value in array
        if (i === 0) {
          // if there is only one value in the array (i.e. first value = last value)
          if (indexesOfMultiplePlateTransfers.length === 1) {
            arrayOfPlateTransfers.push(
              worklistTransfers.slice(0, value),
              worklistTransfers.slice(value)
            );
          } else {
            arrayOfPlateTransfers.push(
              worklistTransfers.slice(0, value),
              worklistTransfers.slice(
                value,
                indexesOfMultiplePlateTransfers[i + 1]
              )
            );
          }
          // last value in array
        } else if (i === indexesOfMultiplePlateTransfers.length - 1) {
          arrayOfPlateTransfers.push(worklistTransfers.slice(value));
        } else {
          // arrayOfPlateTransfers.push(worklistTransfers.slice(indexesOfMultiplePlateTransfers[i-1], value))
          arrayOfPlateTransfers.push(
            worklistTransfers.slice(
              value,
              indexesOfMultiplePlateTransfers[i + 1]
            )
          );
        }
      });
    } else {
      arrayOfPlateTransfers = [worklistTransfers];
    }
    const parameterNameColumnContents = [];
    const parameterValueColumnContents = [];
    const protocolName = [];
    arrayOfPlateTransfers.forEach(plateTransfer => {
      const sourceWells = [];
      const destinationWells = [];
      const transferVolumes = [];
      const firstTransfer = plateTransfer[0];
      let sourcePlateTitle, destinationPlateTitle;
      if (barcodeAsPlateName) {
        const { destinationPlateBarcode, sourcePlateBarcode } =
          getSourceAndDestPlateBarcodes(firstTransfer);
        destinationPlateTitle = destinationPlateBarcode.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateBarcode.replace(/,| /g, "-");
      } else {
        const { destinationPlateName, sourcePlateName } =
          getSourceAndDestPlateNames(firstTransfer);
        destinationPlateTitle = destinationPlateName.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateName.replace(/,| /g, "-");
      }
      plateTransfer.forEach(transfer => {
        sourceWells.push(
          getAliquotContainerLocation(transfer.sourceAliquotContainer)
        );
        destinationWells.push(
          getAliquotContainerLocation(transfer.destinationAliquotContainer)
        );
        const transferVolumeInMicroLiters = getTransferVolumeInUnit(
          transfer,
          "uL"
        );
        transferVolumes.push(
          getVolumeToTwoDecimalPlaces(transferVolumeInMicroLiters)
        );
      });
      // limit array to 32 elements due to character limit of pipetmax software
      if (
        transferVolumes.length < 33 &&
        indexesOfMultiplePlateTransfers.length === 0
      ) {
        protocolName.push(
          `${sourcePlateTitle} to ${destinationPlateTitle}`,
          `${sourcePlateTitle} to ${destinationPlateTitle}`,
          `${sourcePlateTitle} to ${destinationPlateTitle}`
        );
        for (let i = 0; i < 12; i++) {
          parameterNameColumnContents.push(
            `Destination_${i + 1}`,
            `Source_${i + 1}`,
            `Volume_${i + 1}`
          );
        }
        parameterValueColumnContents.push(
          destinationWells,
          sourceWells,
          transferVolumes
        );
      } else {
        const transferVolumeChunks = [];
        const destinationWellChunks = [];
        const sourceWellChunks = [];
        let index = 0;
        while (index < transferVolumes.length) {
          const volumeChunk = transferVolumes.slice(index, index + 32);
          transferVolumeChunks.push(volumeChunk);
          const destinationChunk = destinationWells.slice(index, index + 32);
          destinationWellChunks.push(destinationChunk);
          const sourceChunk = sourceWells.slice(index, index + 32);
          sourceWellChunks.push(sourceChunk);
          index += 32;
        }
        const numChunks = transferVolumeChunks.length;
        for (let i = 0; i < numChunks; i++) {
          protocolName.push(
            `${sourcePlateTitle} to ${destinationPlateTitle}`,
            `${sourcePlateTitle} to ${destinationPlateTitle}`,
            `${sourcePlateTitle} to ${destinationPlateTitle}`
          );
          parameterValueColumnContents.push(
            destinationWellChunks[i],
            sourceWellChunks[i],
            transferVolumeChunks[i]
          );
        }
        for (let i = 0; i < 12; i++) {
          parameterNameColumnContents.push(
            `Destination_${i + 1}`,
            `Source_${i + 1}`,
            `Volume_${i + 1}`
          );
        }
      }
    });
    const pipetMaxWorklistFormat = parameterNameColumnContents.map(
      (row, index) => {
        return {
          "Protocol.Identifier": identifier,
          "Protocol.Name": protocolName[index],
          "Parameter.Name": row,
          "Parameter.Value": parameterValueColumnContents[index]
        };
      }
    );
    return pipetMaxWorklistFormat;
  },
  FlowBot: (
    worklist,
    barcodeAsPlateName,
    sourceIdToSlotPosMap = {},
    destinationIdToSlotPosMap = {},
    options
  ) => {
    const { pipette, tipType } = options;
    return worklist.worklistTransfers.map(function (transfer) {
      const { sourceAliquotContainer, destinationAliquotContainer } = transfer;
      const transferVolumeInMicroLiters = getTransferVolumeInUnit(
        transfer,
        "uL"
      );
      const sourceId = sourceAliquotContainer?.containerArray?.id;
      const destinationId = destinationAliquotContainer?.containerArray?.id;
      const sourcePlateSlotPos = sourceIdToSlotPosMap[sourceId] || 1;
      const destPlateSlotPos = destinationIdToSlotPosMap[destinationId] || 1;

      const flowBotWorklistFormat = {
        source: `${sourcePlateSlotPos}:${getAliquotContainerLocation(
          transfer.sourceAliquotContainer
        )}`,
        target: `${destPlateSlotPos}:${getAliquotContainerLocation(
          transfer.destinationAliquotContainer
        )}`,
        volume: getVolumeToTwoDecimalPlaces(transferVolumeInMicroLiters),
        pipette,
        tipType
      };

      return flowBotWorklistFormat;
    });
  },
  Biomek: (worklist, barcodeAsPlateName) => {
    return worklist.worklistTransfers.map(function (transfer) {
      let sourcePlateTitle, destinationPlateTitle;
      if (barcodeAsPlateName) {
        const { destinationPlateBarcode, sourcePlateBarcode } =
          getSourceAndDestPlateBarcodes(transfer);
        destinationPlateTitle = destinationPlateBarcode.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateBarcode.replace(/,| /g, "-");
      } else {
        const { destinationPlateName, sourcePlateName } =
          getSourceAndDestPlateNames(transfer);
        destinationPlateTitle = destinationPlateName.replace(/,| /g, "-");
        sourcePlateTitle = sourcePlateName.replace(/,| /g, "-");
      }
      const transferVolumeInMicroLiters = getTransferVolumeInUnit(
        transfer,
        "uL"
      );
      return {
        Volume: getVolumeToTwoDecimalPlaces(transferVolumeInMicroLiters),
        "Source ID": sourcePlateTitle,
        "Source Position ID": getAliquotContainerLocation(
          transfer.sourceAliquotContainer
        ),
        "Destination ID": destinationPlateTitle,
        "Destination Position ID": getAliquotContainerLocation(
          transfer.destinationAliquotContainer
        )
      };
    });
  },
  epMotion: worklist => {
    return worklist.worklistTransfers.map(function (transfer) {
      const transferVolumeInMicroLiters = getTransferVolumeInUnit(
        transfer,
        "uL"
      );
      return {
        "Source Rack": 1,
        "Source Well": getAliquotContainerLocation(
          transfer.sourceAliquotContainer
        ),
        "Destination Rack": 1,
        "Destination Well": getAliquotContainerLocation(
          transfer.destinationAliquotContainer
        ),
        Volume: getVolumeToTwoDecimalPlaces(transferVolumeInMicroLiters),
        Tool: 1
      };
    });
  },
  Mosquito: worklist => {
    const worklistValues = worklist.worklistTransfers.map(function (transfer) {
      const transferVolumeInNanoLiters = getTransferVolumeInUnit(
        transfer,
        "nL"
      );
      return {
        // First word must say 'Worklist'..."All numeric data in the file will then be interpreted as pipetting commands; all other data will be ignored"
        // Source Position, Column, Row, Destination Position, Column, Row, Transfer Volume
        Worklist: "",
        "Source Position": 1,
        "Source Column": transfer.sourceAliquotContainer.columnPosition + 1,
        "Source Row": transfer.sourceAliquotContainer.rowPosition + 1,
        "Destination Position": 2,
        "Destination Column":
          transfer.destinationAliquotContainer.columnPosition + 1,
        "Destination Row": transfer.destinationAliquotContainer.rowPosition + 1,
        "Transfer Volume": getVolumeToTwoDecimalPlaces(
          transferVolumeInNanoLiters
        )
      };
    });
    return worklistValues;
  },
  TeselaGen: worklist => {
    return worklist.worklistTransfers.map(transfer => {
      const sourcePlateBarcode =
        transfer.sourceAliquotContainer?.containerArray?.barcode?.barcodeString;
      const destinationPlateBarcode =
        transfer.destinationAliquotContainer?.containerArray?.barcode
          ?.barcodeString;
      return {
        "Source Plate Barcode": sourcePlateBarcode,
        "Source Tube Barcode":
          transfer.sourceAliquotContainer?.barcode?.barcodeString,
        "Source Location": !sourcePlateBarcode
          ? ""
          : getAliquotContainerLocation(transfer.sourceAliquotContainer),
        "Destination Plate Barcode": destinationPlateBarcode,
        "Destination Tube Barcode":
          transfer.destinationAliquotContainer?.barcode?.barcodeString,
        "Destination Location": !destinationPlateBarcode
          ? ""
          : getAliquotContainerLocation(transfer.destinationAliquotContainer),
        "Transfer Volume": transfer.volume,
        "Transfer Volume Unit": transfer.volumetricUnitCode
      };
    });
  },
  QPix: worklist => {
    let plateIndexer = 0;
    const sourcePlatesToSourceWells = {};

    worklist.worklistTransfers.forEach((transfer, i) => {
      const sourcePlate = transfer.sourceAliquotContainer?.containerArray;
      const sourcePlateBarcode = sourcePlate?.barcode?.barcodeString;
      const sourcePlateName = sourcePlate?.name;
      if (!sourcePlate) {
        throw new Error(`Transfer ${i + 1} did not have a source plate.`);
      }
      const plateKey = sourcePlateBarcode || sourcePlateName;

      if (!sourcePlatesToSourceWells[plateKey]) {
        sourcePlatesToSourceWells[plateKey] = {
          plateIndex: plateIndexer++,
          acs: []
        };
      }
      sourcePlatesToSourceWells[plateKey].acs.push(
        transfer.sourceAliquotContainer
      );
    });
    const finalRows = [];
    forEach(sourcePlatesToSourceWells, (vals, plateKey) => {
      const { plateIndex, acs } = vals;
      const sortedAcs = uniq(
        sortAliquotContainers(acs, "rowFirst").map(getAliquotContainerLocation)
      );
      const csvRow = [plateIndex + 1, plateKey, ...sortedAcs];
      finalRows[plateIndex] = csvRow;
    });

    return finalRows;
  }
};

export default async function formatWorklistsForExport(
  {
    worklistIds,
    format: _format = "Echo",
    barcodeAsPlateName,
    sourceIdToSlotPosMap,
    destinationIdToSlotPosMap,
    options = {},
    shouldReturnObject
  },
  ctx = isoContext
) {
  const { safeQuery } = ctx;

  let format = options.customFormat
    ? _format
    : worklistFormats.find(f => f.toLowerCase() === _format.toLowerCase());
  // readable format is a custom format for worklist reports
  if (!format && _format === "readableFormat") {
    format = "readableFormat";
  }
  if (!format && !options.customFormat) {
    throw new Error(`Invalid export format: ${_format}`);
  }
  if (format && isBrowser) {
    window.localStorage.setItem("defaultLiquidHandlerBrand", format);
  }
  const worklists = await safeQuery(exportWorklistFragment, {
    variables: {
      filter: {
        id: worklistIds
      }
    }
  });
  if (shouldReturnObject) {
    const buildWorklistObject = {};
    worklists.forEach(worklist => {
      let tableData;
      if (format === "readableFormat") {
        tableData = worklist.worklistTransfers.map(transfer => {
          const { destinationPlateBarcode, sourcePlateBarcode } =
            getSourceAndDestPlateBarcodes(transfer);
          const { destinationPlateName, sourcePlateName } =
            getSourceAndDestPlateNames(transfer);

          return [
            sourcePlateName,
            sourcePlateBarcode,
            getAliquotContainerLocation(transfer.sourceAliquotContainer),
            destinationPlateName,
            destinationPlateBarcode,
            getAliquotContainerLocation(transfer.destinationAliquotContainer),
            roundValueWithUnit(transfer.volume, transfer.volumetricUnitCode)
          ];
        });
      } else {
        const worklistData = worklistRowMap[format](
          worklist,
          barcodeAsPlateName,
          sourceIdToSlotPosMap,
          destinationIdToSlotPosMap,
          options
        );
        // change rows to arrays instead of objects
        tableData = worklistData.map(row => Object.values(row));
        // add headers
        tableData.unshift(Object.keys(worklistData[0]));
      }
      buildWorklistObject[worklist.id] = {
        name: worklist.name,
        format,
        tableData
      };
    });
    return buildWorklistObject;
  } else {
    const seenFileNames = {};
    return worklists.map(worklist => {
      return {
        filename: getFileName(worklist, format, seenFileNames),
        contents: getFileContent(
          worklist,
          format,
          barcodeAsPlateName,
          sourceIdToSlotPosMap,
          destinationIdToSlotPosMap,
          options
        )
      };
    });
  }
}
