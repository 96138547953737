/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { forEach, pick, uniqBy } from "lodash";
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import { getMaterialIdsFromAliquotContainer } from "../utils/plateUtils";
import { defaultVolumetricUnitCode } from "../utils/unitUtils";
import { convertVolume } from "../utils/unitUtils/convertUnits";

const executeReactionMapAdditivesFragment = gql`
  fragment executeReactionMapAdditivesFragment on additive {
    id
    volume
    volumetricUnitCode
    lot {
      id
      additiveMaterialId
    }
    additiveMaterialId
  }
`;

const executeReactionMapAliquotContainerFragment = gql`
  fragment executeReactionMapAliquotContainerFragment on aliquotContainer {
    id
    cid
    additives {
      ...executeReactionMapAdditivesFragment
    }
    aliquot {
      id
      volume
      volumetricUnitCode
      mass
      massUnitCode
      concentration
      concentrationUnitCode
      replicateParentAliquotId
      additives {
        ...executeReactionMapAdditivesFragment
      }
      sample {
        id
        sampleAliquotId
        materialId
        aliquots(pageSize: 2) {
          id
        }
        sampleFormulations {
          id
          volume
          volumetricUnitCode
          mass
          massUnitCode
          concentration
          concentrationUnitCode
          materialCompositions {
            id
            materialId
          }
        }
      }
    }
  }
  ${executeReactionMapAdditivesFragment}
`;

function createReactionOutput({
  infoForWell,
  aliquot,
  aliquotContainer,
  mutationTrackers
}) {
  const {
    outputMaterials,
    outputReagents,
    consumedMaterials,
    consumedReagents
  } = infoForWell;
  // if we are making a new aliquot here we want to pull the additives from the aliquot container

  // if all materials on the aliquot are consumed do we delete the aliquot?

  const {
    newSamples,
    newAliquots,
    aliquotUpdates,
    aliquotIdsToDelete,
    sampleUpdates,
    additiveIdsToDelete,
    additiveUpdates,
    newAdditives,
    sampleFormulationUpdates,
    aliquotContainerUpdates,
    sampleAliquotUpdates
  } = mutationTrackers;

  const consumedReagentIds = consumedReagents.map(r => r.id);
  const consumedMaterialIds = consumedMaterials.map(m => m.id);
  const existingAdditives =
    (aliquot ? aliquot.additives : aliquotContainer.additives) || [];

  const filteredAdditiveReagentIds = [];
  const filteredAdditives = existingAdditives.filter(a => {
    const reagentId = a.lot ? a.lot.additiveMaterialId : a.additiveMaterialId;
    if (consumedReagentIds.includes(reagentId)) {
      additiveIdsToDelete.push(a.id);
    }
    const keep = !consumedReagentIds.includes(reagentId);
    if (keep) {
      filteredAdditiveReagentIds.push(reagentId);
    }
    return keep;
  });
  // if a reagent is conserved, keep the original instead of the new one
  const addedAdditives = outputReagents
    .map(r => ({
      additiveMaterialId: r.id
    }))
    .filter(r => !filteredAdditiveReagentIds.includes(r.additiveMaterialId));

  // different situations
  // 1. making output materials
  // 2. making output materials with additives
  // 3. making output additives on an existing aliquot
  // 4. making additives in a well without an aliquot
  // 5. removing material (remove aliquot), and put additives in well (should aliquots additives go into well?)
  // 6. removing a single material from an aliquot which is formulated (will still have sample), attach additives to aliquot
  // 7. make output materials in a well with no aliquot
  // 8. make a single output material on an aliquot and also output additives

  const materialIds = getMaterialIdsFromAliquotContainer(aliquotContainer);

  const allMaterialsConsumed = materialIds.every(id =>
    consumedMaterialIds.includes(id)
  );

  // 8. make a single output material on an aliquot and also output additives
  if (outputMaterials.length === 1 && aliquot) {
    const { id: materialId, name: materialName } = outputMaterials[0];
    // if it is a pooled sample without replicates, we are updating the sample
    if (
      aliquot.sample.aliquots.length === 1 &&
      (!aliquot.sample.sampleAliquotId ||
        aliquot.sample.sampleAliquotId === aliquot.sample.aliquots[0].id) &&
      !aliquot.sample.materialId &&
      // check for formulated sample type here, we have registered pool logic from genencore
      materialIds.length > 1
    ) {
      sampleUpdates.push({
        id: aliquot.sample.id,
        materialId
      });
    }
    // this is a special case where we want to track the material history
    else if (aliquot.replicateParentAliquotId && aliquot.sample.materialId) {
      const sampleCid = shortid();
      newSamples.push({
        cid: sampleCid,
        name: materialName,
        sampleAliquotId: aliquot.id,
        sampleTypeCode: "FORMULATED_SAMPLE",
        materialId,
        sampleFormulations: [
          {
            materialCompositions: [{ materialId: aliquot.sample.materialId }],
            volume: aliquot.volume,
            volumetricUnitCode: aliquot.volumetricUnitCode,
            mass: aliquot.mass,
            massUnitCode: aliquot.massUnitCode,
            concentration: null,
            concentrationUnitCode: aliquot.concentrationUnitCode,
            aliquotId: aliquot.replicateParentAliquotId
          }
        ]
      });
      aliquotUpdates.push({
        id: aliquot.id,
        sampleId: `&${sampleCid}`,
        replicateParentAliquotId: null
      });
    } else {
      const sampleCid = shortid();
      newSamples.push({
        cid: sampleCid,
        name: materialName,
        sampleAliquotId: aliquot.id,
        sampleTypeCode: "FORMULATED_SAMPLE",
        materialId,
        sampleFormulations: [
          {
            materialCompositions: materialIds.map(materialId => ({
              materialId
            })),
            volume: aliquot.volume,
            volumetricUnitCode: aliquot.volumetricUnitCode,
            mass: aliquot.mass,
            massUnitCode: aliquot.massUnitCode,
            concentration: null,
            concentrationUnitCode: aliquot.concentrationUnitCode,
            aliquotId: aliquot.replicateParentAliquotId || aliquot.id
          }
        ]
      });
      aliquotUpdates.push({
        id: aliquot.id,
        sampleId: `&${sampleCid}`
      });
    }

    newAdditives.push(
      ...addedAdditives.map(a => ({
        ...a,
        aliquotId: aliquot.id
      }))
    );
  } else if (outputMaterials.length || outputReagents.length) {
    // 5. removing material (remove aliquot), and put additives in well
    // if there are no output materials
    // then we are removing the aliquot in this well.
    // the aliquots additives should go into the well
    if (!outputMaterials.length && outputReagents.length) {
      if (aliquot) {
        if (allMaterialsConsumed) {
          aliquotIdsToDelete.push(aliquot.id);
          // move all aliquot additives to aliquotContainer
          additiveUpdates.push(
            ...filteredAdditives.map(a => ({
              id: a.id,
              aliquotId: null,
              aliquotContainerId: aliquotContainer.id
            }))
          );
          newAdditives.push(
            ...addedAdditives.map(a => ({
              ...a,
              aliquotContainerId: aliquotContainer.id
            }))
          );
        } else if (!allMaterialsConsumed) {
          // 3. making output additives on an existing aliquot
          // 6. removing a single material from an aliquot which is formulated (will still have sample), attach additives to aliquot
          // we need to handle the removal of some materials from this aliquot
          // do we want to make a new sample?
          newAdditives.push(
            ...addedAdditives.map(a => ({
              ...a,
              aliquotId: aliquot.id
            }))
          );
          if (consumedMaterialIds.length) {
            const newMaterialInfo = [];
            aliquot.sample.sampleFormulations.forEach(sf => {
              sf.materialCompositions.forEach(mc => {
                if (!consumedMaterialIds.includes(mc.materialId)) {
                  newMaterialInfo.push({ ...sf, materialId: mc.materialId });
                }
              });
            });
            const sampleCid = shortid();
            const newSample = {
              cid: sampleCid,
              sampleTypeCode: "FORMULATED_SAMPLE",
              materialId:
                newMaterialInfo.length === 1 ? newMaterialInfo[0] : undefined,
              sampleFormulations: newMaterialInfo.map(info => ({
                aliquotId: aliquot.id,
                ...pick(info, [
                  "volume",
                  "volumetricUnitCode",
                  "mass",
                  "massUnitCode",
                  "concentration",
                  "concentrationUnitCode"
                ]),
                materialCompositions: [
                  {
                    materialId: info.materialId
                  }
                ]
              }))
            };
            newSamples.push(newSample);
            aliquotUpdates.push({
              id: aliquot.id,
              sampleId: `&${sampleCid}`
            });
          }
        }
      } else {
        newAdditives.push(
          ...addedAdditives.map(a => ({
            ...a,
            aliquotContainerId: aliquotContainer.id
          }))
        );
      }
    }

    let newAliquot;
    // 7. make output materials in a well with no aliquot
    if (outputMaterials.length && !aliquot) {
      const newAliquotCid = shortid();
      newAliquot = {
        cid: newAliquotCid,
        aliquotType: "sample-aliquot",
        // volume will be volume of aliquot containers additives
        volume: filteredAdditives.reduce((acc, additive) => {
          const vol = convertVolume(
            additive.volume || 0,
            additive.volumetricUnitCode || "uL",
            defaultVolumetricUnitCode
          );
          acc += vol;
          return acc;
        }, 0),
        volumetricUnitCode: defaultVolumetricUnitCode,
        additives: addedAdditives
      };
      aliquotContainerUpdates.push({
        id: aliquotContainer.id,
        aliquotId: `&${newAliquotCid}`
      });
      newAliquots.push(newAliquot);
      // move all the additives from aliquotContainer to aliquot
      additiveUpdates.push(
        ...filteredAdditives.map(a => ({
          id: a.id,
          aliquotId: `&${newAliquotCid}`,
          aliquotContainerId: null
        }))
      );
    }

    if (outputMaterials.length > 0) {
      const sampleCid = shortid();
      let newSample;
      if (outputMaterials.length === 1) {
        newSample = {
          cid: sampleCid,
          name: outputMaterials[0].name,
          sampleTypeCode: "REGISTERED_SAMPLE",
          materialId: outputMaterials[0].id
        };
      } else if (outputMaterials.length > 1) {
        newSample = {
          cid: sampleCid,
          name: outputMaterials.map(m => m.name).join(" - "),
          sampleTypeCode: "FORMULATED_SAMPLE",
          sampleFormulations: outputMaterials.map(m => {
            let volume,
              mass,
              volumetricUnitCode,
              massUnitCode,
              concentrationUnitCode,
              aliquotId;
            const cid = shortid();
            if (aliquot) {
              aliquotId = aliquot.id;
              if (aliquot.volume) {
                volume = aliquot.volume / outputMaterials.length;
              }
              if (aliquot.mass) {
                mass = aliquot.mass / outputMaterials.length;
              }
              volumetricUnitCode = aliquot.volumetricUnitCode;
              massUnitCode = aliquot.massUnitCode;
              concentrationUnitCode = aliquot.concentrationUnitCode;
            } else {
              volume = newAliquot.volume / outputMaterials.length;
              volumetricUnitCode = newAliquot.volumetricUnitCode;
              sampleFormulationUpdates.push({
                cid,
                aliquotId: `&${newAliquot.cid}`
              });
            }
            return {
              cid,
              materialCompositions: [{ materialId: m.id }],
              volume,
              volumetricUnitCode,
              mass,
              massUnitCode,
              concentration: null,
              concentrationUnitCode,
              aliquotId
            };
          })
        };
      }
      if (aliquot) {
        aliquotUpdates.push({
          id: aliquot.id,
          sampleId: `&${sampleCid}`,
          replicateParentAliquotId: null
        });
        newAdditives.push(
          ...addedAdditives.map(a => ({
            ...a,
            aliquotId: aliquot.id
          }))
        );
        newSample.sampleAliquotId = aliquot.id;
        newSamples.push(newSample);
      } else {
        // TODO revisit and attach aliquot to sample after somehow
        // just nest it with the new aliquot
        newAliquot.sample = newSample;
        sampleAliquotUpdates.push({
          cid: newSample.cid,
          sampleAliquotId: `&${newAliquot.cid}`
        });
      }
    }
  }
}

export default async function executeReactions(
  reactionDestinationWellLocationsTable,
  ctx = isoContext
) {
  const { safeUpsert, safeDelete, safeQuery } = ctx;
  const wellIdToInfo = {};
  const aliquotContainersToFetchIds = [];
  const reactionsToExecute = [];
  const executionDate = new Date();
  const initLookups = wellId => {
    if (!wellIdToInfo[wellId]) {
      wellIdToInfo[wellId] = {
        outputMaterials: [],
        outputReagents: [],
        consumedReagents: [],
        consumedMaterials: []
      };
    }
  };
  if (!reactionDestinationWellLocationsTable.length) return;

  reactionDestinationWellLocationsTable.forEach(reactionInfo => {
    const wellId = reactionInfo.destinationWellId;
    reactionsToExecute.push({ id: reactionInfo.id, executedAt: executionDate });
    initLookups(wellId);

    const infoForWell = wellIdToInfo[wellId];
    reactionInfo.reactionFullInputs.forEach(input => {
      if (input.inputMaterial && !input.conserved) {
        infoForWell.consumedMaterials.push(input.inputMaterial);
      }
      if (input.inputAdditiveMaterial && !input.conserved) {
        infoForWell.consumedReagents.push(input.inputAdditiveMaterial);
      }
    });
    reactionInfo.reactionFullOutputs.forEach(output => {
      if (output.outputMaterial) {
        infoForWell.outputMaterials.push(output.outputMaterial);
      } else if (output.outputAdditiveMaterial) {
        infoForWell.outputReagents.push(output.outputAdditiveMaterial);
      }
    });
    aliquotContainersToFetchIds.push(wellId);
  });

  forEach(
    reactionDestinationWellLocationsTable.extraMaterialsForDestinationWells,
    (extraMaterials, wellId) => {
      if (wellIdToInfo[wellId]) {
        // can have a destination well without a running reaction
        wellIdToInfo[wellId].outputMaterials.push(
          ...Object.values(extraMaterials)
        );
      }
    }
  );

  // deduplicate output materials and reagents
  Object.keys(wellIdToInfo).forEach(wellId => {
    const { outputMaterials, outputReagents } = wellIdToInfo[wellId];
    wellIdToInfo[wellId].outputMaterials = uniqBy(outputMaterials, "id");
    wellIdToInfo[wellId].outputReagents = uniqBy(outputReagents, "id");
  });

  const aliquotContainers = await safeQuery(
    executeReactionMapAliquotContainerFragment,
    {
      variables: {
        filter: {
          id: aliquotContainersToFetchIds
        }
      }
    }
  );

  const mutationTrackers = {
    newSamples: [],
    newAliquots: [],
    aliquotUpdates: [],
    aliquotIdsToDelete: [],
    sampleUpdates: [],
    additiveIdsToDelete: [],
    additiveUpdates: [],
    newAdditives: [],
    sampleFormulationUpdates: [],
    aliquotContainerUpdates: [],
    sampleAliquotUpdates: []
  };
  aliquotContainers.forEach(aliquotContainer => {
    const { aliquot, id: wellId } = aliquotContainer;
    createReactionOutput({
      infoForWell: wellIdToInfo[wellId],
      aliquot,
      aliquotContainer,
      mutationTrackers
    });
  });

  await safeUpsert("sample", mutationTrackers.sampleUpdates, {
    excludeResults: true
  });
  await safeUpsert("sample", mutationTrackers.newSamples, {
    excludeResults: true
  });

  await safeUpsert("aliquot", mutationTrackers.aliquotUpdates, {
    excludeResults: true
  });
  await safeUpsert("aliquot", mutationTrackers.newAliquots, {
    excludeResults: true
  });

  await safeUpsert("additive", mutationTrackers.additiveUpdates, {
    excludeResults: true
  });
  await safeUpsert("additive", mutationTrackers.newAdditives, {
    excludeResults: true
  });

  await safeDelete("additive", mutationTrackers.additiveIdsToDelete);
  await safeDelete("aliquot", mutationTrackers.aliquotIdsToDelete);

  await safeUpsert(
    "aliquotContainer",
    mutationTrackers.aliquotContainerUpdates
  );

  await safeUpsert("reaction", reactionsToExecute);

  if (mutationTrackers.sampleFormulationUpdates.length) {
    await updateByCid(
      "sampleFormulation",
      mutationTrackers.sampleFormulationUpdates,
      ctx
    );
  }
  if (mutationTrackers.sampleAliquotUpdates.length) {
    await updateByCid("sample", mutationTrackers.sampleAliquotUpdates, ctx);
  }
}

async function updateByCid(model, updates, ctx) {
  const { safeQuery, safeUpsert } = ctx;
  const recordsWithId = await safeQuery([model, "id cid"], {
    variables: {
      filter: {
        cid: updates.map(u => u.cid)
      }
    }
  });
  const cidToId = {};
  recordsWithId.forEach(sf => {
    cidToId[sf.cid] = sf.id;
  });
  await safeUpsert(
    model,
    updates.map(update => {
      const cleanedUpdate = {
        id: cidToId[update.cid],
        ...update
      };
      delete cleanedUpdate.cid;
      return cleanedUpdate;
    }),
    {
      excludeResults: true
    }
  );
}
