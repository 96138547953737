/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { InputGroup } from "@blueprintjs/core";
import { flatMap } from "lodash";
import { includes } from "lodash";
import { get, identity } from "lodash";
import React, { useState } from "react";
import { InfoHelper } from "@teselagen/ui";
import RecordInfoDisplay from "../../RecordInfoDisplay";
import "./style.css";

const VerticalTable = ({
  entities,
  withSearch,
  schema = [],
  cellRenderer = {},
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const fields = schema.fields || schema;
  return (
    <React.Fragment>
      {withSearch && (
        <InputGroup
          round
          className="tg-vert-table-search"
          placeholder="Search"
          style={{ marginBottom: 15, maxWidth: 250 }}
          leftIcon="search"
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
        />
      )}

      {flatMap(entities, (e, i) => {
        let include = false;
        if (searchTerm && withSearch) {
          fields.forEach(f => {
            const val = e[(f && f.path) || f];
            if (
              includes(
                val && val.toLowerCase ? val.toLowerCase() : val,
                searchTerm.toLowerCase()
              )
            ) {
              include = true;
            }
          });
        } else {
          include = true;
        }
        if (!include) return [];
        const {
          onEdit,
          onDelete,
          editIcon,
          deleteIcon,
          editMessage,
          deleteMessage
        } = {
          ...rest,
          ...e
        };

        return (
          <div className="vertical-table-entity" key={e.id || i}>
            <RecordInfoDisplay
              recordInfo={fields.map(field => {
                const value = get(e, field.path);
                const renderFn =
                  field.render || cellRenderer[field.path] || identity;
                if (field.hideIfEmpty && !renderFn(value, e, i)) return null;
                return [field.displayName, renderFn(value, e, i)];
              })}
            />
            <div style={{ display: "flex" }}>
              {onEdit && (
                <InfoHelper
                  isButton
                  content={editMessage || "Edit"}
                  className="tg-vert-table-edit"
                  minimal
                  small
                  intent="warning"
                  icon={editIcon || "edit"}
                  onClick={() => {
                    onEdit(e);
                  }}
                />
              )}
              {onDelete && (
                <InfoHelper
                  isButton
                  content={deleteMessage || "Delete"}
                  className="tg-vert-table-delete"
                  minimal
                  small
                  intent="danger"
                  icon={deleteIcon || "trash"}
                  onClick={() => {
                    onDelete(e);
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default VerticalTable;
