/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import aliquotContainerTypeFragment from "./aliquotContainerTypeFragment";

export default gql`
  fragment containerArrayTypeFragment on containerArrayType {
    id
    name
    description
    isSystemType
    isPlate
    isColumn
    manufacturer
    catalogNumber
    nestableTubeTypes {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        ...aliquotContainerTypeFragment
      }
    }
    aliquotContainerTypeCode
    aliquotContainerType {
      ...aliquotContainerTypeFragment
    }
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
      isSystemType
    }
  }
  ${aliquotContainerTypeFragment}
`;
