/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import {
  NumericInputField,
  SelectField,
  RadioGroupField,
  CheckboxField,
  InputField,
  ReactSelectField
} from "@teselagen/ui";
import { get } from "lodash";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../src-shared/GenericSelect";
import containerArrayPlatePreviewFragment from "../../../graphql/fragments/containerArrayPlatePreviewFragment";
import PlateMapView from "../../../components/PlateMapView";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

class Consolidation extends Component {
  render() {
    const {
      containerArrayTypes = [],
      newOrExistingDestinationPlates,
      destinationPlateInfo,
      sourcePlates = [],
      sourcePlateFormat,
      consolidationDestinationPlate
    } = this.props;

    const selectedPlateIds = sourcePlates.map(plate => plate.id);
    const filteredContainerArrayTypes = containerArrayTypes.filter(
      containerArrayType =>
        containerArrayType.containerFormatCode ===
        get(sourcePlateFormat, "code")
    );
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Transfer Volume"
              helper="Specify the volume for each of the transfers from the selected source plates."
            />
            <div className="input-with-unit-select" style={{ width: "50%" }}>
              <NumericInputField
                label="Transfer Volume"
                name="transferVolume"
                placeholder="Enter desired transfer volume..."
                min={0}
              />
              <SelectField
                name="transferVolumetricUnitCode"
                defaultValue="uL"
                label="none"
                className="tg-unit-select"
                options={unitGlobals.getOptionsForSelect("volumetricUnit")}
              />
            </div>
          </div>
        </div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Consolidation Plate Mapping"
              helper="Select an existing destination plate or enter information for a new destination plate to be created."
              width="50%"
            />
            <RadioGroupField
              name="newOrExistingDestinationPlates"
              options={[
                { label: "Create new Destination Plate", value: "NEW" },
                {
                  label: "Transfer onto Existing Plate",
                  value: "EXISTING"
                }
              ]}
              defaultValue="EXISTING"
            />
          </div>
          <div className="tg-flex column">
            {newOrExistingDestinationPlates === "EXISTING" && (
              <div className="tg-flex column">
                <GenericSelect
                  {...{
                    name: `consolidationDestinationPlate`,
                    schema: ["name", dateModifiedColumn],
                    fragment: ["containerArray", "id name updatedAt"],
                    additionalDataFragment: containerArrayPlatePreviewFragment,
                    buttonProps: {
                      text: "Select Plate"
                    },
                    tableParamOptions: {
                      additionalFilter: (_, qb) => {
                        qb.whereAll({
                          id: qb.notInList(selectedPlateIds),
                          "containerArrayType.containerFormatCode":
                            sourcePlateFormat.code
                        });
                      }
                    }
                  }}
                />
                {consolidationDestinationPlate && (
                  <PlateMapView
                    containerArrayType={
                      consolidationDestinationPlate.containerArrayType
                    }
                    containerArray={consolidationDestinationPlate}
                    noEntityTransform
                    noPadding
                  />
                )}
              </div>
            )}
            {newOrExistingDestinationPlates === "NEW" && (
              <div className="tg-flex column" style={{ width: "50%" }}>
                <CheckboxField
                  name="destinationPlateInfo.generateBarcode"
                  label="Generate Barcodes For Destination Plate"
                  defaultValue
                />
                <InputField
                  name="destinationPlateInfo.name"
                  label="Destination Plate Name"
                  placeholder="Enter destination plate name..."
                  isRequired
                />
                <ReactSelectField
                  name="destinationPlateInfo.type"
                  label="Destination Plate Type"
                  isRequired
                  options={arrayToItemValuedOptions(
                    filteredContainerArrayTypes
                  )}
                />
                {!get(destinationPlateInfo, "generateBarcode") && (
                  <InputField
                    name="destinationPlateInfo.barcode"
                    label="Destination Plate Barcode"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "newOrExistingDestinationPlates",
    "sourcePlateFormat",
    "sourcePlates",
    "consolidationDestinationPlate",
    "destinationPlateInfo"
  )
)(Consolidation);
