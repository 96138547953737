/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import appGlobals from "../appGlobals";
import { openInNewTab } from "./generalUtils";

export function pushHelper(event, newPathString) {
  if (event && (event.metaKey || event.altKey)) {
    const win = openInNewTab(newPathString);
    if (win) win.opener = null;
  } else {
    appGlobals.history.push(newPathString);
  }
}
