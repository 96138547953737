/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import { toDecimalPrecision } from "../../../../src-shared/utils/unitUtils";

class SequencingQCReportMetadataDialog extends Component {
  render() {
    const { hideModal, metadata } = this.props;
    const {
      alignment_description,
      coveragePass,
      parameters,
      variants
    } = metadata;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <div className="coverage" style={{ paddingBottom: 15 }}>
            <h6>Coverage</h6>
            <b>Coverage:</b>{" "}
            {toDecimalPrecision(
              alignment_description["Percentual Coverage"],
              4
            )}
            <br />
            <b>Coverage Passed:</b> {coveragePass === "1" ? "Yes" : "No"}
            <br />
            <b>Highest Base Pair Coverage:</b>{" "}
            {alignment_description["Highest bp coverage"]}
            <br />
            <b>Lowest Base Pair Coverage:</b>{" "}
            {alignment_description["Lowest bp coverage"]}
            <br />
            <b>Mean Base Pair Coverage:</b>{" "}
            {toDecimalPrecision(
              alignment_description["Mean Base pair coverage"],
              4
            )}
            <br />
            <b>Base Pair Coverage above BPC:</b>{" "}
            {alignment_description["base pair coverage above BPC"]}
          </div>
          <div className="variants" style={{ paddingBottom: 15 }}>
            <h6>Variants</h6>
            <b>INDELs:</b> {variants.INDELs.length}
            <br />
            <b>SNPs:</b> {variants.SNPs.length}
          </div>
          <div className="parameters">
            <h6>Alignment Parameters</h6>
            <b>BAQ:</b> {parameters.BAQ}
            <br />
            <b>MAQ:</b> {parameters.MAQ}
            <br />
            <b>Coverage Threshold:</b>{" "}
            {parameters["Percentual Coverage Threshold"]}
            <br />
            <b>Minimum Base Pair Coverage (BPC):</b>{" "}
            {parameters["minimum base pair coverage (BPC)"]}
          </div>
        </div>
        <DialogFooter noCancel text="Close" onClick={hideModal} />
      </React.Fragment>
    );
  }
}
export default compose(
  wrapDialog({
    title: "View Alignment Metadata"
  })
)(SequencingQCReportMetadataDialog);
