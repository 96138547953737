/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Link } from "react-router-dom";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import { linkedWorkflowsColumn } from "../../../../src-shared/utils/libraryColumns";
import "./style.css";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import reactionMapRecordFragment from "./reactionMapRecordFragment";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import RenameDialog from "../../../../src-shared/RenameDialog";
import { reportLinks } from "../../../../src-shared/SharedJ5ReportRecordView/utils";
import ReactionsTable from "./ReactionsTable";
import MicroserviceRecordStatus from "../../MicroserviceRecordStatus";
import { exportReactionMaps } from "../../../utils/reactionMapUtils";

function RecordLinkHelper({ record }) {
  return <Link to={modelNameToLink(record)}>{record.name}</Link>;
}

function ReactionMapRecordView(props) {
  const { reactionMap } = props;

  const {
    reactionMapSubsetJoins = [],
    reactionMapSubsetReactionMapSubsetJoins = [],
    reactionMapWorkflowView
  } = reactionMap;

  const childReactionMaps = reactionMapSubsetJoins.map(j => j.childReactionMap);
  const parentReactionMaps = reactionMapSubsetReactionMapSubsetJoins.map(
    j => j.parentReactionMap
  );

  const recordInfo = [
    ["Reaction Type", reactionMap.reactionType.name],
    reactionMap.reactionDesign && [
      "Reaction Design",
      <RecordLinkHelper key="link" record={reactionMap.reactionDesign} />
    ],
    reactionMap.dataSet && [
      "Data Set",
      <RecordLinkHelper key="link" record={reactionMap.dataSet} />
    ]
  ];

  if (parentReactionMaps.length) {
    recordInfo.push(["Parent Reaction Maps", reportLinks(parentReactionMaps)]);
  }
  if (childReactionMaps.length) {
    recordInfo.push(["Child Reaction Maps", reportLinks(childReactionMaps)]);
  }
  if (reactionMapWorkflowView?.workflowIds.length) {
    const colInfo = linkedWorkflowsColumn("reactionMapWorkflowView");
    recordInfo.push([colInfo.displayName, colInfo.render(null, reactionMap)]);
  }

  return (
    <AbstractRecord
      {...props}
      recordName="reactionMap"
      onExport={record => exportReactionMaps([record.id])}
      updateShowFunction={() => {
        showDialog({
          ModalComponent: RenameDialog,
          modalProps: {
            record: reactionMap
          }
        });
      }}
    >
      <div>
        <RecordInfoDisplay recordInfo={recordInfo} record={reactionMap} />
        <MicroserviceRecordStatus record={reactionMap} />
        <ReactionsTable
          isReactionMapRecordView={true}
          reactionMapId={reactionMap.id}
        />
      </div>
    </AbstractRecord>
  );
}

export default recordViewEnhancer(reactionMapRecordFragment)(
  ReactionMapRecordView
);
