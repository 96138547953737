/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useState } from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { DataTable } from "@teselagen/ui";
import { compose } from "recompose";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import gql from "graphql-tag";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { uniqBy } from "lodash";
import { getReactionEntitiesAndSchema } from "../utils";

export const updateReactionMapReactionMapFragment = gql`
  fragment updateReactionMapReactionMapFragment on reactionMap {
    id
    name
    reactionTypeCode
    reactions {
      id
      name
      reactionOutputs {
        id
        outputMaterialId
        outputMaterial {
          id
          name
        }
        outputAdditiveMaterialId
        outputAdditiveMaterial {
          id
          name
        }
      }
      reactionInputs {
        id
        conserved
        inputMaterialId
        inputMaterial {
          id
          name
        }
        inputAdditiveMaterialId
        inputAdditiveMaterial {
          id
          name
        }
      }
    }
  }
`;
export const updateReactionMapDataTableFragment = gql`
  fragment updateReactionMapDataTableFragment on dataTable {
    id
    name
    dataRows {
      id
      rowValues
    }
  }
`;

export const updateReactionMapReagentFragment = [
  "additiveMaterial",
  "id name additiveType { code name }"
];

function SelectReagents(props) {
  const {
    handleSubmit,
    nextStep,
    Footer,
    footerProps,
    stepFormProps: { change },
    additiveMaterials = [],
    reagentsFromTable = [],
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
    reactionMap
  } = props;

  const [loadingFromTable, setLoadingFromTable] = useState(false);

  const loadReagentsFromTable = useCallback(
    async dataTables => {
      setLoadingFromTable(true);
      try {
        const reagentIds = [];
        dataTables.forEach(table => {
          table.dataRows.forEach(row => {
            if (row.rowValues?.reagentId) {
              reagentIds.push(row.rowValues.reagentId);
            }
          });
        });
        const reagents = await safeQuery(updateReactionMapReagentFragment, {
          variables: {
            filter: {
              id: reagentIds
            }
          }
        });
        change("reagentsFromTable", reagents);
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.error("Error loading tables");
      }
      setLoadingFromTable(false);
    },
    [change, setLoadingFromTable]
  );

  const clearReagentsFromTable = useCallback(() => {
    change("reagentsFromTable", []);
  }, [change]);

  const allReagents = uniqBy(additiveMaterials.concat(reagentsFromTable), "id");

  const {
    schema: reactionSchema,
    entities: reactionEntities
  } = getReactionEntitiesAndSchema(reactionMap);

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between column">
          <HeaderWithHelper
            header="Reaction Map"
            helper="Select a reaction map to update with reagent inputs."
          />
          <GenericSelect
            {...{
              name: "reactionMap",
              schema: [
                "name",
                { displayName: "Reaction Type", path: "reactionType.name" },
                dateModifiedColumn
              ],
              isRequired: true,
              buttonProps: {
                disabled: isDisabledMap.reactionMap,
                loading: isLoadingMap.reactionMap
              },
              fragment: [
                "reactionMap",
                "id name reactionType { code name } updatedAt"
              ],
              additionalDataFragment: updateReactionMapReactionMapFragment
            }}
          />
          {reactionMap && <h6>{reactionMap.name}</h6>}
          {!!reactionEntities.length && (
            <DataTable
              isSimple
              formName="updateReagentReactionMapReactionsTable"
              entities={reactionEntities}
              schema={reactionSchema}
            />
          )}
        </div>
      </div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between column">
          <HeaderWithHelper
            header="Select Reagents"
            helper="Select reagents from a reagent list (data table) to add them as inputs to the reactions above. If you would like to select additional reagents or do not have a list, select them manually."
            width="100%"
          />
          <div className="tg-flex">
            <div style={{ width: "25%" }}>
              <GenericSelect
                {...{
                  name: "dataTables",
                  schema: ["name", dateModifiedColumn],
                  isMultiSelect: true,
                  buttonProps: {
                    disabled: isDisabledMap.dataTables,
                    loading: isLoadingMap.dataTables
                  },
                  fragment: ["dataTable", "id name"],
                  additionalDataFragment: updateReactionMapDataTableFragment,
                  additionalFilter: {
                    dataTableTypeCode: "REAGENT_LIST"
                  },
                  onSelect: loadReagentsFromTable,
                  onClear: clearReagentsFromTable
                }}
              />
            </div>
            <GenericSelect
              {...{
                name: "additiveMaterials",
                schema: ["name", dateModifiedColumn],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.additiveMaterials,
                  loading: isLoadingMap.additiveMaterials
                },
                fragment: updateReactionMapReagentFragment
              }}
            />
          </div>
          {(allReagents.length || loadingFromTable) && (
            <DataTable
              isSimple
              formName="updateReagentReactionMapReagentTable"
              isLoading={loadingFromTable}
              entities={allReagents}
              schema={[
                "name",
                { displayName: "Reagent Type", path: "additiveType.name" }
              ]}
            />
          )}
        </div>
      </div>
      <Footer
        {...footerProps}
        nextDisabled={!allReagents.length}
        onNextClick={handleSubmit(() => {
          change("allReagents", allReagents);
          nextStep();
        })}
      />
    </React.Fragment>
  );
}

export default compose(
  stepFormValues(
    "reactionMap",
    "additiveMaterials",
    "reagentsFromTable",
    "dataTables"
  )
)(SelectReagents);
