/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, camelCase, groupBy } from "lodash";
import { sumBigs } from "./bigIntUtils";
import { standardizeVolume } from "./unitUtils";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import isValidPositiveNumber from "../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import { isEmpty } from "lodash";
import isValidNonNegativeNumber from "../../../tg-iso-shared/src/utils/isValidNonNegativeNumber";
import unitGlobals from "../unitGlobals";
import {
  volumetricUnitCodeField,
  concentrationUnitCodeField,
  massUnitCodeField
} from "../../../tg-iso-shared/src/utils/unitCodeFields";

export const defaultReagentHeaders = [
  "REAGENT_NAME",
  "REAGENT_LOT_NAME",
  "REAGENT_VOLUME",
  "REAGENT_VOLUMETRIC_UNIT",
  "REAGENT_CONCENTRATION",
  "REAGENT_CONCENTRATION_UNIT",
  "REAGENT_MASS",
  "REAGENT_MASS_UNIT"
];

export const defaultReagentFields = [
  {
    path: "REAGENT_NAME",
    description:
      "To add multiple reagents to a single well duplicate the reagent headers in this csv file.",
    example: "ReagentXYZ"
  },
  {
    path: "REAGENT_LOT_NAME",
    description: "The lot number of the reagent.",
    example: "Lot789"
  },
  {
    path: "REAGENT_VOLUME",
    type: "number",
    description: "The volume of the reagent used.",
    example: "100"
  },
  {
    ...volumetricUnitCodeField,
    path: "REAGENT_VOLUMETRIC_UNIT",
    requireIf: "REAGENT_VOLUME"
  },
  {
    path: "REAGENT_CONCENTRATION",
    type: "number",
    description: "The concentration of the reagent used.",
    example: "1.5"
  },
  {
    ...concentrationUnitCodeField,
    path: "REAGENT_CONCENTRATION_UNIT",
    requireIf: "REAGENT_CONCENTRATION"
  },
  {
    path: "REAGENT_MASS",
    description: "The mass of the reagent used.",
    example: "1.5",
    type: "number"
  },
  { ...massUnitCodeField, path: "REAGENT_MASS_UNIT", requireIf: "REAGENT_MASS" }
];

export default async function getReagentsFromCsv(
  passedDataArray,
  _headers,
  options = {},
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const headers = _headers.map(h => {
    //trim out all numbers from headers and trim off trailing underscore
    return h.trim().replace(/\d+$/g, "").replace(/_$/g, "").trim();
  });
  const {
    allowDry = false,
    aliquotContainerType,
    containerArrayType
  } = options;

  const reagentUnits = defaultReagentHeaders.slice(1);
  const cleanedData = [];
  const additiveMaterialNamesArray = [];
  const lotNamesArray = [];
  for (const row of passedDataArray) {
    const newDataRow = {
      additiveMaterials: []
    };
    for (const [i, header] of headers.entries()) {
      if (header === defaultReagentHeaders[0]) {
        const reagentName = (row[i] || "").trim();
        if (!reagentName) continue;
        additiveMaterialNamesArray.push(reagentName);
        const additiveMaterial = {
          name: reagentName
        };

        let j = i + 1;
        if (headers[j] === defaultReagentHeaders[1]) {
          const reagentLotName = (row[j] || "").trim();
          if (reagentLotName) {
            lotNamesArray.push(reagentLotName);
            additiveMaterial.lotName = reagentLotName;
          }
          j++;
        }
        while (reagentUnits.includes(headers[j])) {
          additiveMaterial[camelCase(headers[j].replace("REAGENT_", ""))] =
            row[j];
          j++;
        }

        // normalize field options
        additiveMaterial.volumetricUnit =
          additiveMaterial.volumetricUnit ||
          additiveMaterial.volumeUnit ||
          additiveMaterial.volumetricUnitCode;
        delete additiveMaterial.volumetricUnitCode;
        delete additiveMaterial.volumeUnit;
        additiveMaterial.concentrationUnit =
          additiveMaterial.concentrationUnit ||
          additiveMaterial.concentrationUnitCode;
        delete additiveMaterial.concentrationUnitCode;
        additiveMaterial.massUnit =
          additiveMaterial.massUnit || additiveMaterial.massUnitCode;
        delete additiveMaterial.volumetricUnitCode;

        newDataRow.additiveMaterials.push(additiveMaterial);
      } else if (defaultReagentHeaders.includes(header)) {
        continue;
      } else {
        newDataRow[header] = row[i];
      }
    }
    cleanedData.push(newDataRow);
  }

  let keyedReagents = {};
  let keyedLots = {};
  if (additiveMaterialNamesArray.length) {
    const reagents = await safeQuery(["additiveMaterial", "id name isDry"], {
      variables: {
        filter: caseInsensitiveFilter(
          "additiveMaterial",
          "name",
          additiveMaterialNamesArray
        )
      }
    });
    keyedReagents = keyBy(reagents, r => r.name.trim().toLowerCase());
  }

  if (!isEmpty(keyedReagents) && lotNamesArray.length) {
    const reagentLots = await safeQuery(
      ["lot", "id name concentration concentrationUnitCode additiveMaterialId"],
      {
        variables: {
          filter: caseInsensitiveFilter("lot", "name", lotNamesArray, {
            additionalFilter: {
              additiveMaterialId: Object.values(keyedReagents).map(r => r.id)
            }
          })
        }
      }
    );
    keyedLots = groupBy(reagentLots, r => r.name.trim().toLowerCase());
  }
  // now validate
  for (const [index, row] of cleanedData.entries()) {
    const additives = [];
    for (const additiveMaterial of row.additiveMaterials) {
      const reagent = keyedReagents[additiveMaterial.name.toLowerCase()];
      if (!reagent) {
        throw new Error(
          `Row ${index + 1} specifies the reagent ${
            additiveMaterial.name
          } which doesn't exist. Add ${
            additiveMaterial.name
          } in the Reagents library to continue.`
        );
      } else if (!allowDry && reagent.isDry) {
        throw new Error(
          `Row ${index + 1} specifies the reagent ${
            additiveMaterial.name
          } which is a dry reagent. This upload doesn't support dry reagents.`
        );
      }
      const reagentLots =
        keyedLots[
          additiveMaterial.lotName && additiveMaterial.lotName.toLowerCase()
        ] || [];
      let reagentLot = reagentLots.find(
        lot => lot.additiveMaterialId === reagent.id
      );
      if (additiveMaterial.lotName && !reagentLot) {
        throw new Error(
          `Row ${index + 1}  specifies the reagent lot ${
            additiveMaterial.lotName
          } which was not found.`
        );
      }
      if (
        additiveMaterial.volume &&
        !isValidPositiveNumber(additiveMaterial.volume)
      ) {
        throw new Error(
          `Row ${index + 1} specifies a reagent with name ${
            additiveMaterial.name
          } but did not have a valid volume.`
        );
      }
      if (
        additiveMaterial.concentration &&
        !isValidNonNegativeNumber(additiveMaterial.concentration)
      ) {
        throw new Error(
          `Row ${index + 1} specifies a reagent with name ${
            additiveMaterial.name
          } but did not have a valid concentration.`
        );
      }
      if (
        additiveMaterial.mass &&
        !isValidNonNegativeNumber(additiveMaterial.mass)
      ) {
        console.error(`additiveMaterial.mass:`, additiveMaterial.mass);
        throw new Error(
          `Row ${index + 1} specifies a reagent with name ${
            additiveMaterial.name
          } but did not have a valid mass.`
        );
      }
      if (!allowDry) {
        if (!additiveMaterial.volume) {
          throw new Error(
            `Row ${index + 1} specifies a reagent with name ${
              additiveMaterial.name
            } but did not give a volume.`
          );
        } else if (!additiveMaterial.volumetricUnit) {
          throw new Error(
            `Row ${index + 1} specifies a reagent with name ${
              additiveMaterial.name
            } but did not give a volumetric unit.`
          );
        } else if (
          !unitGlobals.volumetricUnits[additiveMaterial.volumetricUnit]
        ) {
          throw new Error(
            `Row ${index + 1} with reagent ${
              additiveMaterial.name
            } specifies the volumetric unit ${
              additiveMaterial.volumetricUnit
            } which does not exist.`
          );
        }

        if (
          additiveMaterial.concentrationUnit &&
          !unitGlobals.concentrationUnits[additiveMaterial.concentrationUnit]
        ) {
          throw new Error(
            `Row ${index + 1} with reagent ${
              additiveMaterial.name
            } specifies the concentration unit ${
              additiveMaterial.concentrationUnit
            } which does not exist.`
          );
        }
        if (
          additiveMaterial.massUnit &&
          !unitGlobals.massUnits[additiveMaterial.massUnit]
        ) {
          throw new Error(
            `Row ${index + 1} with reagent ${
              additiveMaterial.name
            } specifies the mass unit ${
              additiveMaterial.massUnit
            } which does not exist.`
          );
        }
      }

      reagentLot = reagentLot || {};
      additives.push({
        additiveMaterialId: reagent.id,
        lotId: reagentLot.id,
        volume: additiveMaterial.volume,
        // if not providing concentration inherit from lot
        concentration:
          additiveMaterial.concentration ||
          (!additiveMaterial.mass && reagentLot.concentration) ||
          undefined,
        volumetricUnitCode: additiveMaterial.volumetricUnit || undefined,
        concentrationUnitCode:
          additiveMaterial.concentrationUnit ||
          reagentLot.concentrationUnitCode ||
          undefined,
        mass: additiveMaterial.mass,
        massUnitCode: additiveMaterial.massUnit || undefined
      });
    }
    delete row.additiveMaterials;
    if (containerArrayType || aliquotContainerType) {
      let aliquotContainerTypeToUse = aliquotContainerType;
      if (containerArrayType && containerArrayType.isPlate) {
        aliquotContainerTypeToUse = containerArrayType.aliquotContainerType;
      }
      if (
        aliquotContainerTypeToUse &&
        aliquotContainerTypeToUse.maxVolume &&
        aliquotContainerTypeToUse.volumetricUnitCode
      ) {
        const additiveWellVolumes = [];
        // eslint-disable-next-line no-loop-func
        additives.forEach(additive => {
          if (additive.volume) {
            additiveWellVolumes.push(
              standardizeVolume(
                additive.volume,
                additive.volumetricUnitCode,
                true
              )
            );
          }
        });
        if (additiveWellVolumes.length) {
          const { maxVolume, volumetricUnitCode } = aliquotContainerTypeToUse;
          const maxWellVolume = standardizeVolume(
            maxVolume,
            volumetricUnitCode,
            true
          );
          const volumeForWell = sumBigs(additiveWellVolumes);
          if (volumeForWell.gt(maxWellVolume)) {
            throw new Error(
              `The volume specified in Row ${
                index + 1
              } exceeds the max volume of the selected well type (${maxVolume} ${volumetricUnitCode}).`
            );
          }
        }
      }
    }
    row.additives = additives;
  }
  return cleanedData;
}
