/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon, Intent } from "@blueprintjs/core";

const ValidationMessages = ({
  issues
  // warrnings,
  // options
}) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {issues.map((issue, idx) => {
      return (
        <ValidationMsgComponent
          key={`issue-${idx}`}
          validationMessage={issue.msg}
          intent={Intent.DANGER}
        />
      );
    })}
  </div>
);

const ValidationMsgComponent = ({ validationMessage, intent }) => (
  <span
    id="validation-msg-component"
    style={{
      fontSize: 14,
      padding: "10px 0 10px 0",
      color: intent === Intent.DANGER ? "#db3737" : "#ffb366"
    }} //
  >
    {" "}
    <Icon intent={intent} iconSize={Icon.SIZE_STANDARD} icon="info-sign" />{" "}
    {validationMessage}
  </span>
);

export default ValidationMessages;

export { ValidationMsgComponent };
