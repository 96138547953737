/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types, applySnapshot } from "mobx-state-tree";
import { withPropsStore } from "./withProps";

export const alertStore = types
  .model("alertStore", {
    open: types.optional(types.boolean, false),
    props: types.optional(withPropsStore, {})
  })
  .actions(self => ({
    close() {
      applySnapshot(self, {});
    },
    newAlert(props) {
      self.open = true;
      self.props.set(props);
    }
  }));
