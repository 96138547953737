/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

import { formInputsValidation } from "../validationFns";

const CUSTOM_LABEL_PRINTING = {
  description: "Setup endpoints for custom label printing",
  endpoints: {
    CUSTOM_LABEL__PRINT_SETTINGS_FORM: {
      name: "Print Settings Form (Optional)",
      isOptional: true,
      description: "Return a form",
      method: "GET",
      exampleRequest:
        "?items[]=%7B%22id%22:%2262d610a7-54d3-4584-a7ce-8c14851ab1b5%22,%22name%22:%22dilution+test%22,%22barcode%22:%22BC0000010%22%7D&itemType=plate",
      exampleResponse: {
        __validate__: () =>
          yup.object({
            formInputs: formInputsValidation
          }),
        formInputs: [
          {
            fieldType: "SelectField",
            name: "printer",
            label: "Printer",
            options: [
              {
                value: {
                  id: "a485A00000CKvHTQA1",
                  name: "printer 1"
                },
                label: "Printer 1"
              },
              {
                value: {
                  id: "a485A00000CKvHTQA1",
                  name: "printer 2"
                },
                label: "Printer 2"
              }
            ]
          }
        ]
      }
    },
    CUSTOM_LABEL__LABEL_SETTINGS_PRESET: {
      method: "GET",
      isOptional: true,
      name: "Label Settings (Optional)",
      description: "Select label media and format from custom form",
      exampleRequest:
        "?items[]=%7B%22id%22:%2262d610a7-54d3-4584-a7ce-8c14851ab1b5%22,%22name%22:%22dilution+test%22,%22barcode%22:%22BC0000010%22%7D&itemType=plate",
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            labelMedia: yup.array(
              yup.object({
                id: yup.string(),
                name: yup.string(),
                isDefault: yup.boolean()
              })
            ),
            labelFormats: yup.array(
              yup.object({
                id: yup.string(),
                name: yup.string(),
                isDefault: yup.boolean()
              })
            )
          });
        },
        labelMedia: [
          {
            id: "id1",
            isDefault: true,
            name: "media 1"
          },
          {
            id: "id2",
            name: "media 2"
          }
        ],
        labelFormats: [
          {
            id: "id1",
            isDefault: true,
            name: "format 1"
          },
          {
            id: "id2",
            name: "format 2"
          }
        ]
      }
    },
    CUSTOM_LABEL__PRINT_LABEL: {
      method: "POST",
      name: "Send Print Info to Printer",
      description: "Send selected settings to printer",
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            items: yup.array(yup.object())
          });
        },
        labelMedia: {
          id: "4f177118-77b8-4322-86f1-75703779562c",
          name: "test media",
          height: 200,
          width: 200,
          heightUnitCode: "mm",
          heightUnit: {
            __typename: "lengthUnit",
            code: "mm",
            name: "millimeter",
            description: "millimeter",
            isMetricUnit: null,
            inches: 0.0393701,
            millimeters: 1
          },
          widthUnitCode: "mm",
          widthUnit: {
            __typename: "lengthUnit",
            code: "mm",
            name: "millimeter",
            description: "millimeter",
            isMetricUnit: null,
            inches: 0.0393701,
            millimeters: 1
          }
        },
        printerId: "a485A00000CKvHTQA3",
        items: [
          { id: "id1", name: "plate 1", barcode: "12810912" },
          { id: "id2", name: "plate 2", barcode: "1208040898" }
        ],
        itemType: "plate",
        labelContents: "my plate Z00000000d"
      },
      exampleResponse: {
        __validate__: () => {
          return yup
            .object({
              success: yup.boolean().required()
            })
            .required();
        },
        success: true
      }
    }
  }
};

export default CUSTOM_LABEL_PRINTING;
