/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment defaultValueGeneratorFragment on defaultValueGenerator {
    code
    templateString
    endpointUrlString
    isUrl
    allowUserOverride
    user {
      id
      username
    }
  }
`;
