/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

import { get, noop, groupBy, uniq } from "lodash";
import { assignUserToTaskWorkflowToolFragment } from "./AssignUserToTaskDialog";
import {
  isToolReady,
  workQueueItemReadyNotification,
  workQueueItemAssignedNotification
} from "../utils";
import { createNotification } from "../../../../src-shared/utils/createNotification";
import UserSelect from "../../../../src-shared/UserSelect";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

/**
 * This dialog can be used to reassign multiple workflow tools to the same user.
 */
class ReassignTasksDialog extends Component {
  onSubmit = async values => {
    try {
      const { tasks, hideModal, refetch = noop } = this.props;
      const { taskAssignee } = values;

      const jobIds = uniq(tasks.map(t => t.jobId));
      const workflowToolIds = uniq(tasks.map(t => t.workflowTool.id));
      const workflowToolIdToJobId = {};
      tasks.forEach(t => {
        workflowToolIdToJobId[t.workflowTool.id] = t.jobId;
      });

      const jobIdToJobUserId = {};
      const jobUsersToCreate = [];
      const existingJobUsers = await safeQuery(["jobUser", "id jobId"], {
        variables: {
          filter: {
            jobId: jobIds,
            userId: taskAssignee.id
          }
        }
      });
      existingJobUsers.forEach(jobUser => {
        jobIdToJobUserId[jobUser.jobId] = jobUser.id;
      });
      jobIds.forEach(jobId => {
        if (!jobIdToJobUserId[jobId]) {
          jobUsersToCreate.push({
            jobId,
            userId: taskAssignee.id
          });
        }
      });
      // make a new jobUser for this selected user
      const newJobUsers = await safeUpsert(
        ["jobUser", "id jobId"],
        jobUsersToCreate
      );
      newJobUsers.forEach(jobUser => {
        jobIdToJobUserId[jobUser.jobId] = jobUser.id;
      });

      const currentWorkQueueItems = await safeQuery(
        [
          "workQueueItem",
          /* GraphQL */ `
            {
              id
              reassigned
              workflowToolId
              jobUser {
                id
                userId
              }
            }
          `
        ],
        {
          variables: {
            filter: {
              workflowToolId: workflowToolIds
            }
          }
        }
      );

      const groupedCurrentWorkQueueItems = groupBy(
        currentWorkQueueItems,
        "workflowToolId"
      );

      const updates = [];
      const creates = [];

      for (const workflowToolId of Object.keys(groupedCurrentWorkQueueItems)) {
        const currentWorkQueueItemsForTool =
          groupedCurrentWorkQueueItems[workflowToolId] || [];
        const workQueueItemForUser = currentWorkQueueItemsForTool.find(
          item => get(item, "jobUser.userId") === taskAssignee.id
        );
        const previouslyAssignedWorkQueueItems = currentWorkQueueItemsForTool.filter(
          item => item.reassigned === false && item !== workQueueItemForUser
        );
        if (previouslyAssignedWorkQueueItems.length) {
          previouslyAssignedWorkQueueItems.forEach(
            previouslyAssignedWorkQueueItem => {
              updates.push({
                id: previouslyAssignedWorkQueueItem.id,
                reassigned: true
              });
            }
          );
        }

        if (workQueueItemForUser) {
          if (!workQueueItemForUser.reassigned) {
            // the chosen assignee is already assigned to this task
          } else {
            updates.push({ id: workQueueItemForUser.id, reassigned: false });
          }
        } else {
          const jobId = workflowToolIdToJobId[workflowToolId];
          const jobUserId = jobIdToJobUserId[jobId];
          if (!jobId || !jobUserId) {
            throw new Error("Something went wrong. No user found for job.");
          }
          creates.push({
            reassigned: false,
            workflowToolId,
            jobUserId
          });
        }
      }

      await safeUpsert("workQueueItem", updates);
      await safeUpsert("workQueueItem", creates);

      // update cache after update
      const fullWorkflowTools = await safeQuery(
        assignUserToTaskWorkflowToolFragment,
        {
          variables: {
            filter: {
              id: workflowToolIds
            }
          }
        }
      );

      const assignedNotifications = fullWorkflowTools.map(tool =>
        workQueueItemAssignedNotification(taskAssignee.id, tool)
      );

      const readyWorklfowNotifications = fullWorkflowTools
        .filter(t => isToolReady(t))
        .map(tool => workQueueItemReadyNotification(taskAssignee.id, tool));

      await createNotification(
        assignedNotifications.concat(readyWorklfowNotifications)
      );
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating tasks assignments.");
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <UserSelect
            {...{
              name: "taskAssignee",
              label: "Task Assignee",
              isRequired: true
            }}
          />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Reassign Tasks"
  }),
  reduxForm({
    form: "reassignTasksForm"
  })
)(ReassignTasksDialog);
