/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes, Button, Intent, Card } from "@blueprintjs/core";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { BlueprintError, DataTable } from "@teselagen/ui";
import InfoHeader from "../../InfoHeader";

import "./style.css";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import GenericSelect from "../../../../src-shared/GenericSelect";
import gql from "graphql-tag";
import UnitInputField from "../../UnitInputField";
import {
  concentrationRender,
  convertVolume,
  volumeRender,
  withUnitGeneric
} from "../../../../src-shared/utils/unitUtils";
import { doTransfersFit } from "../../../../../tg-iso-lims/src/utils/unitUtils";

const addTransferAliquotFragment = gql`
  fragment addTransferAliquotFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    volumetricUnit {
      code
      liters
    }
    concentration
    concentrationUnit {
      code
      gramsPerLiter
    }
    concentrationUnitCode
    mass
    massUnitCode
    sample {
      id
      name
      material {
        id
        name
      }
    }
  }
`;

const AddTransferForm = props => {
  const {
    aliquotToTransfer,
    transferVolumetricUnitCode,
    originalAliquot,
    handleSubmit,
    input,
    reset,
    change,
    meta: { error, touched }
  } = props;

  const onSubmit = ({
    aliquotToTransfer,
    transferAliquotVolume,
    transferVolumetricUnitCode
  }) => {
    const transfers = input.value || [];
    input.onChange([
      ...transfers,
      {
        aliquotToTransfer,
        transferAliquotVolume,
        transferVolumetricUnitCode
      }
    ]);
    reset();
    change("transferVolumetricUnitCode", transferVolumetricUnitCode);
  };

  // This should probably have some information
  // about the aliquot in it.
  const transfersColumns = [
    {
      displayName: "Sample Name",
      path: "aliquotToTransfer.sample.name"
    },
    {
      displayName: "Volume",
      path: "_volume"
    },
    {
      displayName: "Concentration",
      path: "_concentration"
    },
    {
      width: 45,
      type: "action",
      render: (v, record) => {
        return (
          <Button
            icon="trash"
            intent={Intent.DANGER}
            className={Classes.MINIMAL}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              const transfers = input.value || [];
              const {
                aliquotToTransfer: { id }
              } = record;
              input.onChange(
                transfers.filter(
                  transfer => transfer.aliquotToTransfer.id !== id
                )
              );
            }}
          />
        );
      }
    }
  ];

  const transfers = input.value || [];
  const currentTransferAliquotIds = transfers.map(t => t.aliquotToTransfer.id);

  return (
    <Card style={{ margin: "15px 0" }}>
      <div>
        <InfoHeader
          header="Transfer Operations"
          info="Selected aliquots will be transferred to source aliquot."
        />
        <GenericSelect
          {...{
            asReactSelect: true,
            name: "aliquotToTransfer",
            isRequired: true,
            label: "Aliquot to Transfer",
            reactSelectProps: {
              placeholder: "Select the aliquot to transfer"
            },
            schema: [
              {
                path: "id",
                type: "number",
                render: (v, aliquot) => {
                  const {
                    id,
                    sample: { name: sampleName }
                  } = aliquot;
                  const vol = volumeRender(aliquot, { noJsx: true });
                  let conc = concentrationRender(aliquot, { noJsx: true });
                  conc = conc && conc !== "N/A" ? ` at ${conc}` : "";
                  return `Aliquot ID ${id} (${vol} of "${sampleName}"${conc})`;
                }
              },
              {
                path: "sample.name",
                isHidden: true
              }
            ],
            fragment: addTransferAliquotFragment,
            additionalFilter: (props, qb) => {
              qb.whereAll({
                volume: qb.greaterThan(0),
                id: qb.notInList(
                  currentTransferAliquotIds.concat(originalAliquot.id)
                )
              });
            }
          }}
        />
        <UnitInputField
          name="transferAliquotVolume"
          isRequired
          label="Volume to Transfer"
          placeholder="Select the volume to transfer"
          unitName="transferVolumetricUnitCode"
          unitType="volumetricUnit"
          disabled={!aliquotToTransfer}
          unitDefault={aliquotToTransfer?.volumetricUnitCode}
          enableReinitialize
          max={
            aliquotToTransfer && transferVolumetricUnitCode
              ? convertVolume(
                  aliquotToTransfer.volume,
                  aliquotToTransfer.volumetricUnitCode,
                  transferVolumetricUnitCode
                )
              : null
          }
        />
        <Button
          icon="add"
          text="Add Transfer"
          intent={Intent.SUCCESS}
          className={Classes.MINIMAL}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      {transfers.length ? (
        <DataTable
          formName="AliquotTransferOperationsTable"
          isSimple
          style={{ marginBottom: 15, marginTop: 15 }}
          maxHeight={400}
          compact
          schema={transfersColumns}
          entities={transfers.map((transfer, i) => ({
            ...transfer,
            _volume: withUnitGeneric(
              "transferAliquotVolume",
              "transferVolumetricUnitCode"
            )(transfer),
            _concentration: withUnitGeneric(
              "aliquotToTransfer.concentration",
              "aliquotToTransfer.concentrationUnitCode"
            )(transfer),
            key: i
          }))}
        />
      ) : null}
      {touched && <BlueprintError error={error} />}
    </Card>
  );
};

const addTransferFormValidate = (values, props) => {
  const { originalAliquot, transfers = [] } = props;
  const {
    aliquotToTransfer,
    transferAliquotVolume,
    transferVolumetricUnitCode
  } = values;

  const errors = {};

  if (aliquotToTransfer && transferVolumetricUnitCode) {
    const volumeOfTransferAliquot = convertVolume(
      aliquotToTransfer.volume,
      aliquotToTransfer.volumetricUnitCode,
      transferVolumetricUnitCode
    );
    if (!isValidPositiveNumber(transferAliquotVolume)) {
      errors.transferAliquotVolume =
        "Please enter a valid volume greater than 0.";
    } else {
      if (
        transferAliquotVolume < 0 ||
        transferAliquotVolume > volumeOfTransferAliquot
      ) {
        errors.transferAliquotVolume = `The transfer volume must be between 0 ${transferVolumetricUnitCode} and ${volumeOfTransferAliquot} ${transferVolumetricUnitCode}`;
      } else if (originalAliquot.aliquotContainer) {
        const aliquotContainerType =
          originalAliquot.aliquotContainer.aliquotContainerType;
        const currentTransfer = {
          volume: transferAliquotVolume,
          volumetricUnitCode: transferVolumetricUnitCode
        };
        const volumeInfo = [originalAliquot, currentTransfer].concat(
          transfers.map(t => ({
            volume: t.transferAliquotVolume,
            volumetricUnitCode: t.transferVolumetricUnitCode
          }))
        );
        const transfersFit = doTransfersFit({
          maxVolume: aliquotContainerType.maxVolume,
          maxVolumetricUnitCode: aliquotContainerType.volumetricUnitCode,
          transfers: volumeInfo
        });
        if (!transfersFit) {
          errors.transferAliquotVolume = `Transfers would exceed max volume of ${aliquotContainerType.maxVolume} ${aliquotContainerType.volumetricUnitCode}.`;
        }
      }
    }
  }

  return errors;
};

export default compose(
  reduxForm({
    // enableReinitialize: true,
    form: "addTranferForm",
    validate: addTransferFormValidate
  }),
  tgFormValues("aliquotToTransfer", "transferVolumetricUnitCode")
)(AddTransferForm);
