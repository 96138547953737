/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { extractZipFiles } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { noop } from "lodash";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { hideDialog } from "../../../../src-shared/GlobalDialog";
import handleReactionMapJson from "../../../../../tg-iso-shared/utils/handleReactionMapJson";

export default async function uploadReactionMapJson(
  reactionMapFiles,
  { refetch = noop, labId, reactionDesign = false } = {}
) {
  try {
    const allFiles = await extractZipFiles(reactionMapFiles);
    await handleReactionMapJson(allFiles, {
      labId,
      reactionDesign
    });
    await refetch();
    hideDialog();
  } catch (error) {
    console.error(`error:`, error);
    window.toastr.error("Error uploading reaction maps");
    throwFormError(error.message || "Error uploading reaction maps");
  }
}
