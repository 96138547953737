/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { set, get, forEach, sortBy } from "lodash";
import shortid from "shortid";
import { withSelectedEntities } from "@teselagen/ui";
import StepForm from "../../../../src-shared/StepForm";
import { validateMaterialPlates } from "../../../utils/plateUtils";

import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import transferColumnContainerArrayFragment from "../../../graphql/fragments/transferColumnContainerArrayFragment";
import stepFormValues from "../../../../src-shared/stepFormValues";
import SelectPlates from "./SelectPlates";
import UpdateExtendedProperties from "./UpdateExtendedProperties";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";

import { addAndRemoveExtendedProperties } from "../../../../src-shared/utils/extendedPropertyUtils";

class DesaltTool extends Component {
  onSubmit = async values => {
    const {
      aliquotExtendedPropertiesToRemoveSelectedEntities = [],
      plateExtendedPropertiesToRemoveSelectedEntities = [],
      collectionPlatesWithProps = []
    } = this.props;
    const {
      proteinVolume: volume,
      proteinVolumetricUnitCode: volumetricUnitCode,
      containerArrayExtendedValues = [],
      aliquotExtendedValues = [],
      columnContainerArrays = []
    } = values;
    const platePropIdsToRemove = plateExtendedPropertiesToRemoveSelectedEntities.map(
      p => p.id
    );
    const aliquotPropIdsToRemove = aliquotExtendedPropertiesToRemoveSelectedEntities.map(
      p => p.id
    );

    const extendedValuesToDelete = {
      extendedValue: [],
      extendedCategoryValue: [],
      extendedMeasurementValue: []
    };

    const extendedValuesToCreate = {
      extendedValue: [],
      extendedCategoryValue: [],
      extendedMeasurementValue: []
    };
    // we want this to be the collection container array
    collectionPlatesWithProps.forEach(containerArray => {
      addAndRemoveExtendedProperties(containerArray, {
        extendedValues: containerArrayExtendedValues,
        propIdsToRemove: platePropIdsToRemove,
        extendedValuesToCreate
      });
    });
    try {
      const extendedValueUpdates = [];
      const updatedCollectionContainerArrays = [];
      const updatedColumnContainerArrays = [];
      const columnAliquotsToDelete = [];
      const collectionNewAliquots = [];
      const collectionUpdatedAliquotContainers = [];
      const columnContainerArrayUpdates = [];

      columnContainerArrays.forEach(containerArray => {
        const cleanValue = containerArray.extendedValues.find(
          ev => ev.extendedProperty.name === "Clean"
        );
        const usageValue = containerArray.extendedValues.find(
          ev => ev.extendedProperty.name === "Number of Uses"
        );
        extendedValueUpdates.push({
          id: cleanValue.id,
          value: false
        });
        extendedValueUpdates.push({
          id: usageValue.id,
          value: usageValue.value + 1
        });

        updatedColumnContainerArrays.push({
          id: containerArray.id
        });
        columnContainerArrayUpdates.push({
          id: containerArray.id,
          collectionPlateId: null
        });
        updatedCollectionContainerArrays.push({
          id: containerArray.collectionPlateId
        });

        const sortedColumnAliquotContainers = sortBy(
          containerArray.aliquotContainers,
          ["rowPosition", "columnPosition"]
        );
        const sortedCollectionAliquotContainers = sortBy(
          get(containerArray, "collectionPlate.aliquotContainers"),
          ["rowPosition", "columnPosition"]
        );
        sortedColumnAliquotContainers.forEach((ac, i) => {
          if (ac.aliquot) {
            columnAliquotsToDelete.push(ac.aliquot.id);
            const cid = shortid();
            const aliquotToCreate = {
              cid,
              volume,
              volumetricUnitCode,
              aliquotType: "replicate-aliquot",
              replicateParentAliquotId: ac.aliquot.id,
              sampleId: ac.aliquot.sample.id
            };
            collectionNewAliquots.push(aliquotToCreate);
            addAndRemoveExtendedProperties(
              { ...aliquotToCreate, __typename: "aliquot" },
              {
                extendedValues: aliquotExtendedValues,
                propIdsToRemove: aliquotPropIdsToRemove,
                extendedValuesToCreate
              }
            );
            collectionUpdatedAliquotContainers.push({
              id: sortedCollectionAliquotContainers[i].id,
              aliquotId: `&${cid}`
            });
          }
        });
      });
      await safeUpsert("aliquot", collectionNewAliquots);
      forEach(extendedValuesToDelete, async (items, model) => {
        await safeDelete(model, items);
      });
      forEach(extendedValuesToCreate, async (items, model) => {
        await safeUpsert(model, items);
      });
      await safeUpsert("extendedValue", extendedValueUpdates);
      await safeUpsert("aliquotContainer", collectionUpdatedAliquotContainers);
      await safeUpsert("containerArray", columnContainerArrayUpdates);
      await safeDelete("aliquot", columnAliquotsToDelete);

      return {
        collectionContainerArrays: updatedCollectionContainerArrays,
        columnContainerArrays: updatedColumnContainerArrays
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating plates.");
    }
    return values;
  };
  validate = values => {
    const {
      columnContainerArrays = [],
      collectionContainerArrays = {}
    } = values;
    const errors = {};
    errors.columnContainerArrays = "";
    if (columnContainerArrays.length < 1) {
      errors.columnContainerArrays +=
        "Must select one or more nickel column plates.";
    } else {
      errors.columnContainerArrays = validateMaterialPlates(
        columnContainerArrays,
        {
          materialTypeCode: "PROTEIN"
        }
      );
    }

    if (columnContainerArrays.length) {
      const invalidPlates = [];
      const dirtyPlates = [];
      const missingCollectionPlates = [];
      columnContainerArrays.forEach(containerArray => {
        const plateKey = "id" + containerArray.id;
        if (!collectionContainerArrays[plateKey]) {
          set(
            errors,
            `collectionContainerArrays.id${containerArray.id}`,
            "Must select a collection plate for each nickel column plate."
          );
        } else {
          const isEmpty = collectionContainerArrays[
            plateKey
          ].aliquotContainers.every(ac => !ac.aliquot);
          if (!isEmpty) {
            set(
              errors,
              `collectionContainerArrays.id${containerArray.id}`,
              "Selected collection plate must be empty."
            );
          }
          if (collectionContainerArrays[plateKey].containerArray) {
            set(
              errors,
              `collectionContainerArrays.id${containerArray.id}`,
              "Selected collection plate is already attached to a filter plate."
            );
          }
        }

        const hasCleanProp = containerArray.extendedValues.some(
          ev => ev.extendedProperty.name === "Clean"
        );
        const hasUsageProp = containerArray.extendedValues.some(
          ev => ev.extendedProperty.name === "Number of Uses"
        );
        const cleanProp = containerArray.extendedValues.find(
          ev => ev.extendedProperty.name === "Clean"
        );
        const isClean = cleanProp && cleanProp.value === true;
        if (!hasCleanProp || !hasUsageProp) {
          invalidPlates.push(containerArray.name);
        }
        if (!isClean) {
          dirtyPlates.push(containerArray.name);
        }
        if (!containerArray.collectionPlate) {
          missingCollectionPlates.push(containerArray.name);
        }
      });
      if (invalidPlates.length > 0) {
        errors.columnContainerArrays += `\nThe following plates are missing necessary extended properties: ${invalidPlates.join(
          ", "
        )}. \n`;
      }
      if (dirtyPlates.length > 0) {
        errors.columnContainerArrays += `\nThe following nickel column plates must be cleaned before selection: ${dirtyPlates.join(
          ", "
        )}. \n`;
      }
      if (missingCollectionPlates.length > 0) {
        errors.columnContainerArrays += `\nThe following nickel column plates are not linked to collection plates: ${missingCollectionPlates.join(
          ", "
        )}. \n`;
      }
    }
    return errors;
  };

  render() {
    const {
      toolSchema,
      toolIntegrationProps,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        withCustomFooter: true
      },
      {
        title: "Update Extended Properties",
        Component: UpdateExtendedProperties,
        withCustomFooter: true
      }
    ];
    return (
      <StepForm
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        steps={steps}
        validate={this.validate}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(
  withSelectedEntities(
    "aliquotExtendedPropertiesToRemove",
    "plateExtendedPropertiesToRemove"
  ),
  stepFormValues("collectionPlatesWithProps"),
  withWorkflowInputs(transferColumnContainerArrayFragment, {
    initialValueName: "columnContainerArrays"
  })
)(DesaltTool);
