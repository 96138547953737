/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import InventoryCheck from "./Steps/InventoryCheck";
import ReviewPlates from "./Steps/ReviewPlates";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { compose, withProps } from "recompose";
import { allInputTypeModels, multiSelectModels } from "./utils";
import { findKey, isEmpty } from "lodash";

class InventoryCheckTool extends React.Component {
  loadedHelpers = {
    clear: function () {
      Object.keys(this).forEach(key => {
        if (key !== "clear") {
          this[key] = undefined;
        }
      });
    }
  };

  onSubmit = async values => {
    const {
      updatedPlates = [],
      updatedTubes = [],
      inventoryCheckTable,
      sampleInventoryList,
      sampleListName,
      dataTableName
    } = values;
    let createdPlateList;
    let createdSampleList;
    if (inventoryCheckTable && dataTableName) {
      const [table] = await safeUpsert("dataTable", {
        ...inventoryCheckTable,
        name: dataTableName
      });
      createdPlateList = table;
    }
    if (sampleInventoryList && sampleListName) {
      const [table] = await safeUpsert("dataTable", {
        ...sampleInventoryList,
        name: sampleListName
      });
      createdSampleList = table;
    }
    return {
      containerArrays: updatedPlates,
      aliquotContainers: updatedTubes,
      plateList: createdPlateList,
      sampleList: createdSampleList
    };
  };

  render() {
    const {
      toolSchema,
      initialValues,
      isToolIntegrated,
      toolIntegrationProps
    } = this.props;

    const steps = [
      {
        title: "Inventory Check",
        Component: InventoryCheck,
        withCustomFooter: true,
        props: {
          loadedHelpers: this.loadedHelpers
        }
      },
      {
        title: "Review",
        Component: ReviewPlates
      }
    ];

    return (
      <StepForm
        steps={steps}
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(
  ...allInputTypeModels.map(model => {
    return withWorkflowInputs([model, "id name updatedAt"], {
      singular: !multiSelectModels.includes(model),
      initialValueName: model
    });
  }),
  withProps(props => {
    if (!isEmpty(props.initialValues)) {
      const inputTypeToUse = findKey(props.initialValues, (v, k) => {
        return v && allInputTypeModels.includes(k);
      });
      if (inputTypeToUse) {
        return {
          initialValues: {
            ...props.initialValues,
            inputType: inputTypeToUse
          }
        };
      }
    }
  })
)(InventoryCheckTool);
