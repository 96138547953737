/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import targetAndFeaturesWidgetStyles from "./TargetAndFeaturesWidgetStyles.modules.css";
import { Colors, Classes } from "@blueprintjs/core";

// const TAG = "[TargetAndFeaturesWidget]";

class TargetAndFeaturesWidget extends Component {
  renderFeaturesList(isLoading, dataSchema) {
    if (isLoading) {
      return (
        <ul className={Classes.SKELETON}>
          <li>
            Features loading...
            <span style={{ color: Colors.GRAY3 }}>type loading...</span>
          </li>
          <li>
            Features loading...
            <span style={{ color: Colors.GRAY3 }}>type loading...</span>
          </li>
          <li>
            Features loading...
            <span style={{ color: Colors.GRAY3 }}>type loading...</span>
          </li>
        </ul>
      );
    }

    const features = dataSchema.filter(item => item.type === "descriptor");

    return (
      <ul>
        {features.map((feature, index) => {
          return (
            <li key={index}>
              {feature.name}{" "}
              <span style={{ color: Colors.GRAY3 }}>{feature.value_type}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { dataSchema } = this.props;
    const isLoading = !dataSchema;

    let target = undefined;

    if (!isLoading) {
      target = dataSchema.filter(item => item.type === "target")[0];
    }
    return (
      <div className={targetAndFeaturesWidgetStyles.container}>
        <h5>Target</h5>
        <ul>
          <li className={isLoading ? Classes.SKELETON : ""}>
            {isLoading ? "Target loading..." : target.name}
            <span style={{ color: Colors.GRAY3 }}>
              {isLoading ? "target type loading..." : target.value_type}
            </span>
          </li>
        </ul>
        <h5>Features</h5>
        {this.renderFeaturesList(isLoading, dataSchema)}
      </div>
    );
  }
}

export default TargetAndFeaturesWidget;
