/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
export default gql`
  fragment importFileSetFullFragment on importFileSet {
    id
    name
    importFileSetStep {
      code
      name
    }
    assay {
      id
      name
      assayStatusCode
      protocol {
        id
        name
      }
    }
    importFileSetAttachments {
      id
      isTabularData
      dataFile {
        id
        originalname
        path
      }
    }
    createdAt
    updatedAt
  }
`;
