/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { range } from "lodash";
import tgCreateCachedSelector from "../../../../../../tg-iso-design/utils/tgCreateCachedSelector";
import { getBinsFilteredForRender } from "../../../../../src-shared/selectors/addReactionSelectors";

export default tgCreateCachedSelector(
  (state, cardId, isOutputCircular) => +isOutputCircular,
  getBinsFilteredForRender,
  (isOutputCircular, bins) => {
    return isOutputCircular ? range(bins.length + 1) : range(1, bins.length);
  }
)(
  (state, cardId, isOutputCircular, isErdam) =>
    `${cardId}:${+isOutputCircular}:${!!isErdam}`
);
