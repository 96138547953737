/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap } from "lodash";
import React from "react";
import { DataTable } from "@teselagen/ui";
import { Icon, Tooltip } from "@blueprintjs/core";

import GenericSelect from "../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../src-shared/stepFormValues";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";

export function getDataTableRows(dataTables = []) {
  return flatMap(dataTables, dataTable => {
    return dataTable.dataRows.map(dataRow => dataRow.rowValues);
  });
}

function SampleQCDataTableSelect({
  isDisabledMap,
  isLoadingMap,
  additionalDataFragment,
  sampleErrors = {},
  helperText,
  dataTables = []
}) {
  const dataTableRows = getDataTableRows(dataTables);
  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper header="Select Samples" helper={helperText} />
      <GenericSelect
        {...{
          name: "dataTables",
          schema: ["name", dateModifiedColumn],
          isMultiSelect: true,
          isRequired: true,
          buttonProps: {
            disabled: isDisabledMap.dataTables,
            loading: isLoadingMap.dataTables
          },
          fragment: ["dataTable", "id name updatedAt dataTableTypeCode"],
          additionalDataFragment: additionalDataFragment,
          tableParamOptions: {
            additionalFilter: () => ({
              dataTableTypeCode: [
                "VALID_SAMPLE_QC_INVENTORY_LIST",
                "INVALID_SAMPLE_QC_INVENTORY_LIST"
              ]
            })
          }
        }}
      />
      {dataTableRows.length > 0 && (
        <DataTable
          formName="samplesFromDataTables"
          entities={dataTableRows}
          sampleErrors={sampleErrors}
          schema={[
            {
              width: 50,
              type: "action",
              render: (_, r) => {
                if (sampleErrors[r.sampleId]) {
                  return (
                    <Tooltip content={sampleErrors[r.sampleId]}>
                      <Icon intent="danger" icon="cross" />
                    </Tooltip>
                  );
                }
              }
            },
            { displayName: "Material Name", path: "materialName" },
            { displayName: "Sample ID", path: "sampleId" }
          ]}
          maxHeight={300}
          destroyOnUnmount={false}
          isSimple
          compact
        />
      )}
    </div>
  );
}

export default stepFormValues("dataTables")(SampleQCDataTableSelect);
