/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { stopImportCollection } from "./utils/importCollection";
import TagItemDialog from "./TagItemDialog";
import { withRouter } from "react-router-dom";
import UpdateRecordExtendedPropertiesDialog from "./UpdateRecordExtendedPropertiesDialog";
import EditValueExtendedProperty from "./AddExtendedPropertyDialog/EditValueExtendedProperty";
import EditCategoryExtendedProperty from "./AddExtendedPropertyDialog/EditCategoryExtendedProperty";
import EditMeasurementExtendedProperty from "./AddExtendedPropertyDialog/EditMeasurementExtendedProperty";
import EditLinkExtendedProperty from "./AddExtendedPropertyDialog/EditLinkExtendedProperty";

const dialogStore = observable({
  modalType: null
});

// tgreen: keep modal props outside of mobx (which wraps all objects in observables).
// this will keep them as pure js
let dialogModalProps = {};
let CustomModalComponent = null;
let dialogOnClose = null;
let pathnameOnOpen = "";

export function showDialog({ modalType, ModalComponent, modalProps, onClose }) {
  dialogModalProps = modalProps;
  dialogOnClose = onClose;
  pathnameOnOpen = window.location.pathname;
  if (!modalType && ModalComponent) {
    modalType = "TGCustomModal";
  }
  CustomModalComponent = ModalComponent;

  // this needs to happen last
  dialogStore.modalType = modalType;
}

export function getOpenDialogType() {
  return dialogStore.modalType;
}

export function hideDialog(ops) {
  // this will clear the importCollectionId from session storage
  stopImportCollection();
  // this needs to happen first
  dialogStore.modalType = null;

  CustomModalComponent = null;
  dialogModalProps = {};
  if (dialogOnClose) {
    dialogOnClose(ops);
  }
}

const sharedModalComponents = {
  TAG_ITEM: TagItemDialog,
  UPDATE_RECORD_EXTENDED_PROPERTIES: UpdateRecordExtendedPropertiesDialog,
  EDIT_VALUE_EXTENDED_PROPERTY: EditValueExtendedProperty,
  EDIT_CATEGORY_EXTENDED_PROPERTY: EditCategoryExtendedProperty,
  EDIT_MEASUREMENT_EXTENDED_PROPERTY: EditMeasurementExtendedProperty,
  EDIT_LINK_EXTENDED_PROPERTY: EditLinkExtendedProperty
};

class GlobalDialog extends React.Component {
  componentDidUpdate() {
    if (pathnameOnOpen !== window.location.pathname && dialogStore.modalType) {
      hideDialog();
    }
  }

  render() {
    const {
      modalComponents = {},
      currentUser,
      history,
      location,
      ...rest
    } = this.props;

    if (!dialogStore.modalType) {
      return null;
    }

    const hideModal = ops => {
      hideDialog(ops);
    };

    const SpecificModal =
      CustomModalComponent ||
      modalComponents[dialogStore.modalType] ||
      sharedModalComponents[dialogStore.modalType];

    if (!SpecificModal) {
      throw new Error(`No dialog component found for ${dialogStore.modalType}`);
    }

    return (
      <SpecificModal
        {...{
          hideModal,
          currentUser,
          history,
          ...dialogModalProps,
          ...rest
        }}
      />
    );
  }
}

export default withRouter(observer(GlobalDialog));
