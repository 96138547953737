/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { Dialog, Classes } from "@blueprintjs/core";
import { DialogFooter, InputField } from "@teselagen/ui";

import { cloneDeep } from "lodash";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";

import recurseOnJson from "../../../../../tg-iso-design/utils/recurseOnJson";
import sequenceDuplicateFragment from "../../../graphql/fragments/sequenceDuplicateFragment";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";

export async function handleDuplicateSequences({
  sequenceIds,
  nameMap,
  singleName,
  tags
}) {
  const sequences = await safeQuery(sequenceDuplicateFragment, {
    isPlural: true,
    variables: {
      filter: { id: sequenceIds },
      pageSize: sequenceIds.length
    }
  });

  const valuesToUpsert = cloneDeep(sequences);
  recurseOnJson(
    valuesToUpsert,
    value => {
      if (nameMap?.[value.id]) {
        value.name = nameMap[value.id];
      }
      delete value.id;
      delete value.code;
      delete value.cid;
      delete value.lab;
      delete value.__typename;
    },
    { callOnObjectsOnly: true }
  );
  if (singleName) {
    valuesToUpsert.forEach(seq => {
      seq.name = singleName;
    });
  }

  return await safeUpsert(
    "sequence",
    addTaggedItemsBeforeCreate(valuesToUpsert, tags)
  );
}

class DuplicateSequenceContextMenuDialog extends React.Component {
  onSubmit = defaultAsyncWrap(async ({ name }) => {
    const { refetch, selectedRecords, hideModal } = this.props;
    try {
      await handleDuplicateSequences({
        sequenceIds: selectedRecords.map(r => r.id),
        singleName: name
      });
      await refetch();
      hideModal();

      window.toastr.success(
        `Duplicated ${selectedRecords.length} sequence${
          selectedRecords.length > 1 ? "s" : ""
        }.`
      );
    } catch (e) {
      hideModal();
      console.error(e);
      window.toastr.error(
        `Error duplicating sequence${selectedRecords.length > 1 ? "s" : ""}s.`
      );
    }
  });

  render() {
    const { hideModal, submitting, handleSubmit, selectedRecords } = this.props;
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={"Duplicate Sequence" + sIfPlural(selectedRecords)}
        style={{ width: 350 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            <InputField
              name="name"
              label="Name"
              defaultValue={selectedRecords[0].name + " copy"}
            />
          </div>
          <DialogFooter hideModal={hideModal} text="OK" loading={submitting} />
        </form>
      </Dialog>
    );
  }
}

const validate = (values, { selectedRecords }) => {
  const errors = {};
  if (selectedRecords.length && !values.name) errors.name = "Required.";
  return errors;
};

export default compose(
  reduxForm({
    form: "duplicateSequenceContextMenuDialog", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(DuplicateSequenceContextMenuDialog);
