/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import {
  convertVolumeBig,
  convertMassBig,
  convertConcentrationBig,
  defaultConcentrationUnitCode,
  calculateMolarityFromConcentration,
  convertMolarityBig,
  defaultMolarityUnitCode,
  calculateConcentrationFromMolarity,
  defaultCellConcentrationUnitCode,
  convertCellConcentrationBig
} from "../unitUtils";
import { get } from "lodash";
import {
  convertConcentration,
  convertMolarity
} from "../unitUtils/convertUnits";

export function getMaterialMolecularWeight(material) {
  return (
    get(material, "polynucleotideMaterialSequence.molecularWeight") ||
    get(material, "functionalProteinUnit.molecularWeight")
  );
}

export function getAliquotMolecularWeight(aliquot) {
  return getMaterialMolecularWeight(get(aliquot, "sample.material"));
}

export function calculateAliquotMolarityAndConcentration({
  aliquotValues,
  molecularWeight,
  concentrationType
}) {
  if (concentrationType === "concentration" && aliquotValues.concentration) {
    aliquotValues.molarity = calculateMolarityFromConcentration(
      aliquotValues.concentration,
      aliquotValues.concentrationUnitCode,
      molecularWeight
    );
    aliquotValues.molarityUnitCode =
      aliquotValues.molarityUnitCode || defaultMolarityUnitCode;
    aliquotValues.molarity = convertMolarity(
      aliquotValues.molarity,
      "M",
      aliquotValues.molarityUnitCode
    );
  } else if (concentrationType === "molarity" && aliquotValues.molarity) {
    aliquotValues.concentration = calculateConcentrationFromMolarity(
      aliquotValues.molarity,
      aliquotValues.molarityUnitCode,
      molecularWeight
    );
    aliquotValues.concentrationUnitCode =
      aliquotValues.concentrationUnitCode || defaultConcentrationUnitCode;
    aliquotValues.concentration = convertConcentration(
      aliquotValues.concentration,
      "g/L",
      aliquotValues.concentrationUnitCode
    );
  }
}

/**
 * A diluent aliquot is just water.
 * @param {Object} aliquot Must have `aliquotType` as a key.
 * @returns {boolean}
 */
export function isDiluent(aliquot) {
  return aliquot.aliquotType === "additive";
}

export function getNewConcentrationForHydratedAliquot(
  aliquot,
  newVolume,
  volumetricUnitCode
) {
  const volumeInLiters = convertVolumeBig(newVolume, volumetricUnitCode, "L");
  const massInGrams = convertMassBig(
    aliquot.mass || new Big(0),
    aliquot.massUnitCode,
    "g"
  );
  const newConcentration = Number(
    convertConcentrationBig(
      massInGrams.div(volumeInLiters),
      "g/L",
      defaultConcentrationUnitCode
    )
  );

  const aliquotMolecularWeight = getAliquotMolecularWeight(aliquot);

  let molarityFields;
  if (aliquotMolecularWeight) {
    const molarity = Number(
      convertMolarityBig(
        calculateMolarityFromConcentration(
          newConcentration,
          defaultConcentrationUnitCode,
          aliquotMolecularWeight
        ),
        "M",
        "nM"
      )
    );
    molarityFields = {
      molarity,
      molarityUnitCode: "nM"
    };
  }
  return {
    concentration: newConcentration,
    concentrationUnitCode: defaultConcentrationUnitCode,
    ...molarityFields
  };
}

export function getNewCellConcentrationForHydratedAliquot(
  aliquot,
  newVolume,
  volumetricUnitCode
) {
  const volumeInLiters = convertVolumeBig(newVolume, volumetricUnitCode, "L");
  let newCellConcentration = null;
  if (aliquot.cellCount) {
    newCellConcentration = Number(
      convertCellConcentrationBig(
        new Big(aliquot.cellCount).div(volumeInLiters),
        "cells/L",
        defaultCellConcentrationUnitCode
      )
    );
  }

  return {
    cellConcentration: newCellConcentration,
    cellConcentrationUnitCode: defaultCellConcentrationUnitCode
  };
}

export function copySampleFormulationsFromAliquot(aliquot) {
  return aliquot.sample.sampleFormulations.map(sf => {
    return {
      aliquotId: aliquot.id,
      materialCompositions: sf.materialCompositions.map(mc => {
        return {
          materialId: mc.materialId
        };
      })
    };
  });
}
