/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { parseCsvAndSequenceFiles } from "../../tg-iso-shared/src/sequence-import-utils/utils";
import { cleanCommaSeparatedCell } from "../../tg-iso-shared/src/utils/fileUtils";
import { addTaggedItemsBeforeCreate } from "../../tg-iso-shared/src/tag-utils";
import { isoContext } from "@teselagen/utils";
import getNameKeyedItems from "../../tg-iso-shared/src/utils/getNameKeyedItems";

export default async function handleUserRequestImport(
  { userRequestFile, tags },
  ctx = isoContext
) {
  const customerRequestStatusTypes = await ctx.safeQuery([
    "customerRequestStatusType",
    "code name"
  ]);
  const requestTypes = await ctx.safeQuery(["requestType", "id name"]);
  // const file = values.strainFile[0];
  const { parsedCsvs } = await parseCsvAndSequenceFiles(userRequestFile, {
    allowMultipleCsvFiles: true
  });

  const userRequests = [];
  const allUserNames = [];
  const allStrainNames = [];
  const allSequenceNames = [];
  for (const { data } of parsedCsvs) {
    for (const row of data) {
      const {
        NAME,
        REQUEST_TYPE = "",
        DESCRIPTION,
        DUE_DATE,
        STATUS = "",
        PRIMARY_USER,
        TEAM_MEMBERS,
        STRAINS = "",
        SEQUENCES = ""
      } = row;
      const status = customerRequestStatusTypes.find(
        ({ name }) => name.toLowerCase() === STATUS.toLowerCase()
      );
      const requestType = requestTypes.find(
        ({ name }) => name.toLowerCase() === REQUEST_TYPE.toLowerCase()
      );
      if (!status) {
        throw new Error(`Status not found: ${STATUS}`);
      }
      if (!requestType) {
        throw new Error(`Request type not found: ${REQUEST_TYPE}`);
      }
      allUserNames.push(PRIMARY_USER);
      const teamMembers = cleanCommaSeparatedCell(TEAM_MEMBERS);
      const strains = cleanCommaSeparatedCell(STRAINS);
      const sequences = cleanCommaSeparatedCell(SEQUENCES);

      allStrainNames.push(...strains);
      allSequenceNames.push(...sequences);
      allUserNames.push(...teamMembers);
      const date = new Date(DUE_DATE);
      if (isNaN(date.getTime())) {
        throw new Error(`Invalid date: ${DUE_DATE}`);
      }
      userRequests.push({
        name: NAME,
        requestTypeId: requestType.id,
        outputDescription: DESCRIPTION,
        dueDate: date,
        customerRequestStatusTypeCode: status.code,
        primaryUser: PRIMARY_USER,
        customers: teamMembers,
        strains,
        sequences
      });
    }
  }
  const usersByUsername = await getNameKeyedItems(
    {
      names: allUserNames,
      model: "user",
      key: "username"
    },
    ctx
  );
  const strainsByName = await getNameKeyedItems(
    {
      names: allStrainNames,
      model: "strain",
      key: "name"
    },
    ctx
  );
  const sequencesByName = await getNameKeyedItems(
    {
      names: allSequenceNames,
      model: "sequence",
      key: "name"
    },
    ctx
  );
  for (const userRequest of userRequests) {
    const primaryUser = usersByUsername[userRequest.primaryUser.toLowerCase()];
    if (!primaryUser) {
      throw new Error(`Primary user not found: ${userRequest.primaryUser}`);
    }
    userRequest.primaryCustomer = { userId: primaryUser.id };
    delete userRequest.primaryUser;
    userRequest.customers = userRequest.customers.map(customerName => {
      const customer = usersByUsername[customerName.toLowerCase()];
      if (!customer) {
        throw new Error(`Team member not found: ${customerName}`);
      }
      return { userId: customer.id };
    });
    userRequest.customerRequestStrains = userRequest.strains.map(strainName => {
      const strain = strainsByName[strainName.toLowerCase()];
      if (!strain) {
        throw new Error(`Strain not found: ${strainName}`);
      }
      return { strainId: strain.id };
    });
    delete userRequest.strains;
    userRequest.customerRequestSequences = userRequest.sequences.map(
      sequenceName => {
        const sequence = sequencesByName[sequenceName.toLowerCase()];
        if (!sequence) {
          throw new Error(`Sequence not found: ${sequenceName}`);
        }
        return { sequenceId: sequence.id };
      }
    );
    delete userRequest.sequences;
  }

  await ctx.startImportCollection?.("User Request Upload");
  return await ctx.safeUpsert(
    ["customerRequest", "id name"],
    addTaggedItemsBeforeCreate(userRequests, tags)
  );
}
