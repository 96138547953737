/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import QueryBuilder from "tg-client-query-builder";
import { keyBy } from "lodash";
import aliquotContainerFormulationFragment from "./aliquotContainerFormulationFragment";
import isIdClientGenerated from "../../../../../tg-iso-shared/utils/isIdClientGenerated";

/**
 * Aliquot container cids must start with '&' in the serialized aliquot formulation
 * data. In order to query properly by cid, we must remove that prefix.
 * @param {string} cid
 * @returns {string}
 */
function processCid(cid) {
  return cid.slice(1);
}

/**
 * Create a map from aliquot container id to the full aliquot containerfor for all of the
 * destination aliquot containers that are specified. The information contained in a full aliquot
 * container can be found in the `aliquotContainerFormulationFragment` fragment.
 * @param {Array<Object>} aliquotFormulations Serialized aliquot formulation JSONs.
 * @param {Object} apolloMethods
 * @returns {Map<string, Object>} A map from aliquot id to the full aliquot.
 */
async function getIdToAliquotContainerMap(aliquotFormulations, apolloMethods) {
  const { safeQuery } = apolloMethods;

  const containerIds = aliquotFormulations.reduce((acc, f) => {
    if (f.destination.aliquotContainerId) {
      if (!acc.includes(f.destination.aliquotContainerId)) {
        acc.push(f.destination.aliquotContainerId);
      }
    }
    f.transfers.forEach(t => {
      if (t.aliquotContainerId) {
        if (!acc.includes(t.aliquotContainerId)) {
          acc.push(t.aliquotContainerId);
        }
      }
    });
    return acc;
  }, []);

  // If there are no aliquot containers to find, then no need to perform the query.
  if (!containerIds.length) return {};

  const ids = containerIds.filter(id => !isIdClientGenerated(id));
  const cids = containerIds.filter(isIdClientGenerated).map(processCid);

  const qb = new QueryBuilder("aliquotContainer");
  const containers = await safeQuery(aliquotContainerFormulationFragment, {
    isPlural: true,
    variables: {
      pageSize: containerIds.length,
      filter: qb.whereAny({ id: ids }, { cid: cids }).toJSON()
    }
  });

  if (containers.length !== containerIds.length) {
    throw new Error("Not all aliquot containers were found in the database.");
  }

  return keyBy(containers, "id");
}

export default getIdToAliquotContainerMap;
