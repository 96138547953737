/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, Classes, Intent } from "@blueprintjs/core";
import {
  ReactSelectField,
  NumericInputField,
  InputField,
  CheckboxField
} from "@teselagen/ui";

import withQuery from "../../../../../src-shared/withQuery";

import { reduxForm, formValueSelector, change } from "redux-form";
import { safeUpsert } from "../../../../../src-shared/apolloMethods";

const enzymes = [
  "AarI",
  "AscI",
  "BamHI",
  "BbsI",
  "BsaI",
  "EcoRI",
  "FseI",
  "HindIII",
  "KpnI",
  "NcoI",
  "NdeI",
  "NheI",
  "NotI",
  "PmeI",
  "SacI",
  "SalI",
  "SbfI",
  "XbaI",
  "XhoI"
];

class CrickitParameterPresetDetails extends React.Component {
  onSubmit = async values => {
    const { previousPage, changeFormValue } = this.props;
    const name = values.parameterPresetName;
    delete values.parameterPresetName;

    let [mei] = await safeUpsert("microserviceExtensionInput", {
      microserviceExtensionCode: "CRICKIT_PARAMETERS",
      name,
      valueJson: values
    });
    mei = {
      ...mei,
      name,
      isHidden: null,
      microserviceExtensionCode: "CRICKIT_PARAMETERS"
    };
    changeFormValue("crickitParameterSelection", "parameterPreset", mei);
    return previousPage();
  };

  render() {
    const {
      previousPage,
      handleSubmit,
      selectedParameterPreset,
      microserviceExtensionInputs = []
    } = this.props;

    const preset = microserviceExtensionInputs[0] || { valueJson: {} };
    if (!preset.name && selectedParameterPreset) return <div>Loading...</div>;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            label="Parameter Set Name"
            name="parameterPresetName"
            isRequired
            defaultValue={preset.name}
            readOnly={!!selectedParameterPreset}
          />
          <hr />
          <br />
          <InputField
            label="Species"
            name="species"
            isRequired
            readOnly={true}
            defaultValue="Clostridium_autoethanogenum"
          />
          <NumericInputField
            name="sequenceCount"
            label="Sequence Count"
            isRequired
            readOnly={!!selectedParameterPreset}
            defaultValue={preset.valueJson.sequenceCount || 100000}
            min={0}
          />
          <NumericInputField
            name="deltagUpstreamBases"
            label="∆G Upstream Bases"
            isRequired
            readOnly={!!selectedParameterPreset}
            defaultValue={preset.valueJson.deltagUpstreamBases || 50}
            min={1}
          />
          <NumericInputField
            name="markovRatio"
            label="Markov Ratio"
            isRequired
            readOnly={!!selectedParameterPreset}
            defaultValue={preset.valueJson.markovRatio || 50}
            min={0}
            max={100}
          />
          <ReactSelectField
            name="restrictionEnzyme"
            isRequired
            label="Restriction Enzyme Filters:"
            disabled={!!selectedParameterPreset}
            multi
            defaultValue={preset.valueJson.restrictionEnzyme || ["AarI"]}
            options={enzymes}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <CheckboxField
              label="Gene end adapted"
              name="geneEndAdapted"
              disabled={!!selectedParameterPreset}
              defaultValue={!!preset.valueJson.geneEndAdapted}
            />
            <CheckboxField
              label="Filter RBS"
              name="filterRBS"
              disabled={!!selectedParameterPreset}
              defaultValue={!!preset.valueJson.filterRBS}
            />
            <CheckboxField
              label="Apply extra effort"
              name="applyExtraEffort"
              disabled={!!selectedParameterPreset}
              defaultValue={!!preset.valueJson.applyExtraEffort}
            />
          </div>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            {preset.name ? (
              <Button
                minimal
                intent={Intent.DANGER}
                icon="trash"
                text="Delete Preset"
                onClick={async () => {
                  await safeUpsert("microserviceExtensionInput", {
                    id: selectedParameterPreset,
                    isHidden: true
                  });
                  return previousPage();
                }}
              />
            ) : null}
            <div style={{ flex: 1 }} />
            <Button minimal text="Back" onClick={previousPage} />
            {preset.name ? null : (
              <Button
                intent={Intent.PRIMARY}
                text="Save Parameters"
                type="submit"
              />
            )}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector("crickitParameterSelection");
  return {
    selectedParameterPreset: selector(state, "parameterPreset.id")
  };
};

export default compose(
  withQuery(
    [
      "microserviceExtensionInput",
      "id name valueJson isHidden microserviceExtensionCode"
    ],
    {
      isPlural: true,
      options: props => {
        const id = props.selectedParameterPreset || -1;
        return {
          variables: {
            filter: {
              id
            }
          }
        };
      }
    }
  ),
  connect(mapStateToProps, {
    changeFormValue: change
  }),
  reduxForm({
    form: "crickitParameterPresetDetails",
    enableReinitialize: true
  })
)(CrickitParameterPresetDetails);
