/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import gql from "graphql-tag";
import { DialogFooter } from "@teselagen/ui";

import libraryEnhancer from "../../src-shared/libraryEnhancer";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import { Link } from "react-router-dom";
import AminoAcidPartRecordView from "../../src-shared/AminoAcidPartRecordView";
import { aaAnnotationSizeStartEndColumns } from "../../src-shared/utils/libraryColumns";
import { MenuItem, MenuDivider } from "@blueprintjs/core";
import { hideDialog, showDialog } from "../../src-shared/GlobalDialog";
import { safeQuery, safeUpsert } from "../../src-shared/apolloMethods";
import { ReverseTranslationDialog } from "../../src-shared/ReverseTranslation";
import shortid from "shortid";
import { mapSeries } from "bluebird";
import sequenceStringToFragments from "../../../tg-iso-shared/src/sequence-import-utils/sequenceStringToFragments";

const schema = {
  model: "aminoAcidPart",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    ...aaAnnotationSizeStartEndColumns,
    {
      path: "aminoAcidSequence.name",
      type: "string",
      displayName: "Source",
      render: (name, val) => {
        return (
          <Link to={`/amino-acid-sequences/${val.aminoAcidSequence.id}`}>
            {name}
          </Link>
        );
      }
    }
  ]
};

const fragment = gql`
  fragment aminoAcidPartLibraryFragment on aminoAcidPart {
    id
    start
    end
    name
    aminoAcidSequence {
      id
      name
    }
  }
`;

const cellRenderer = {
  start: index => {
    return <div title={(index + 3) / 3}>{(index + 3) / 3}</div>;
  },
  end: index => {
    return <div title={(index + 1) / 3}>{(index + 1) / 3}</div>;
  }
};

class AminoAcidPartLibrary extends React.Component {
  additionalContextMenu = selectedRecords => {
    const menuItems = [];

    menuItems.push(
      <MenuDivider key="md2" />,
      getReverseTranslationMenuItem({ selectedRecords, isAAPart: true })
    );
    return menuItems;
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        generateExtraContextMenuItems={this.additionalContextMenu}
        RecordViewInspector={AminoAcidPartRecordView}
        noNewItem
        isLibraryTable
        model="aminoAcidPart"
        extraTableParams={{ cellRenderer }}
      />
    );
  }
}

export default compose(
  libraryEnhancer({
    schema,
    fragment,
    noAddedBy: true,
    withSelectedEntities: true
  })
)(AminoAcidPartLibrary);

export function getReverseTranslationMenuItem({ selectedRecords, isAAPart }) {
  return (
    <MenuItem
      key="reverseTranslate"
      text="Reverse Translate"
      icon="translate"
      onClick={async () => {
        let aaSeqsToRevTrans;
        if (isAAPart) {
          const fullAAParts = await safeQuery(
            [
              "aminoAcidPart",
              "id name start end aminoAcidSequence { id proteinSequence }"
            ],
            {
              isPlural: true,
              variables: {
                filter: {
                  id: selectedRecords.map(el => el.id)
                }
              }
            }
          );

          aaSeqsToRevTrans = fullAAParts.map(aaPart => {
            return {
              aminoAcidSequence: aaPart.aminoAcidSequence,
              aaPart,
              proteinSequence: aaPart.aminoAcidSequence.proteinSequence.slice(
                aaPart.start / 3,
                (aaPart.end + 1) / 3
              )
            };
          });
        } else {
          aaSeqsToRevTrans = await safeQuery(
            ["aminoAcidSequence", "id name proteinSequence"],
            {
              isPlural: true,
              variables: {
                filter: {
                  id: selectedRecords.map(el => el.id)
                }
              }
            }
          );
        }

        showDialog({
          ModalComponent: ReverseTranslationDialog,
          modalProps: {
            dialogProps: {
              title: `Reverse Translate AA ${isAAPart ? "Parts" : "Sequences"}`
            },
            onRevTransFinished: async (translatedSeqs, { translationInfo }) => {
              const seqs = [];
              await mapSeries(
                translatedSeqs,
                async ({ reverseTranslatedDna, name, aaPart }) => {
                  const seqCid = shortid();
                  const newName =
                    (isAAPart ? aaPart.name : name) + " Reverse Translation";
                  const des = `Derived from ${
                    isAAPart
                      ? `amino acid part ${aaPart.name}`
                      : `amino acid ${name}`
                  } using reverse-translator ${translationInfo.name ||
                    ""} ${translationInfo.description || ""}`;
                  const [s] = await safeUpsert(["sequence", "id name"], {
                    cid: seqCid,
                    name: newName,
                    description: des,
                    sequenceTypeCode: "LINEAR_DNA",
                    sequenceFragments: sequenceStringToFragments(
                      reverseTranslatedDna
                    ),
                    sequenceFeatures: [
                      {
                        name: newName,
                        start: 0,
                        end: reverseTranslatedDna.length - 1,
                        strand: 1,
                        type: "CDS"
                      }
                    ]
                  });

                  seqs.push(s);
                  if (isAAPart) {
                    await safeUpsert("part", {
                      sequenceId: "&" + seqCid,
                      name: newName,
                      notes: { Origin: [des] },
                      strand: 1,
                      start: 0,
                      end: reverseTranslatedDna.length - 1
                    });
                  }
                }
              );

              return {
                nextPage: (
                  <>
                    <div className="bp3-dialog-body">
                      Created {seqs.length} new sequence(s):
                      {seqs.map(seq => (
                        <div key={seq.id}>
                          <Link to={`/sequences/${seq.id}`}>{seq.name}</Link>
                        </div>
                      ))}
                    </div>
                    <DialogFooter
                      noCancel
                      text="Finish"
                      onClick={() => {
                        hideDialog();
                      }}
                    ></DialogFooter>
                  </>
                )
              };
            },
            aaSeqsToRevTrans
          }
        });
      }}
    />
  );
}
