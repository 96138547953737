/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import allModelsViewSchema from "./allModelsViewSchema";
import { Button, Icon, Intent, MenuItem } from "@blueprintjs/core";
import { DataTable } from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import allModelsViewStyles from "./AllModelsView.module.css";
import { EvolveConfig } from "../../configs/config.js";
import cypressTags from "../../configs/cypressTags.json";
import { getActiveLabId } from "@teselagen/auth-utils";
import classNames from "classnames";

const TAG = "[AllModelsView]";

const {
  VIEW_ALL_PREDICTIVE_MODELS_ROUTE,
  VIEW_ALL_EVOLUTIVE_MODELS_ROUTE,
  VIEW_ALL_GENERATIVE_MODEL_ROUTE,
  PREDICTIVE_MODEL,
  EVOLUTIVE_MODEL,
  GENERATIVE_MODEL,
  MODEL_DETAIL_VIEW_ROUTE,
  PREDICTIVE_MODEL_DETAIL_VIEW_ROUTE,
  EVOLUTIVE_MODEL_DETAIL_VIEW_ROUTE,
  GENERATIVE_MODEL_DETAIL_VIEW_ROUTE
} = EvolveConfig.constants;

const REFRESH_FOR_UPDATE_TEXT = (
  <p className={classNames(allModelsViewStyles.noticeText)}>
    <b>Notice:</b> The models status is not updated in real time. To check if
    the status has changed, please click on the refresh icon on the table's
    footer buttons.
  </p>
);

class AllModelsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entities: [],
      modelType: this.modelType(),
      isLoading: false
    };
  }

  async componentDidMount() {
    await this.getModels(this.state.modelType);
  }

  onRefresh = async () => {
    await this.getModels(this.state.modelType);
  };

  getModels = async modelType => {
    this.setState({
      isLoading: true
    });
    try {
      // const queryParams = { variables: { filter: { modelType: modelType } } };
      // get all evolve models of type=modelType. (ex: "predictive"/"evolutive"/"generative").
      const response = await window.serverApi.post(
        EvolveConfig.constants.GET_MODELS_ENDPOINT,
        { modelType }
      );

      let entities = [];

      if (response.data.data) {
        // This filter fixes DISCOVER models with a null labId from showing up in any lab.
        // NOTE: the query done by the backend still returns records with labId=null
        // from within any lab.
        // TODO: evaluate if this is something we want or if we should also filter out labId=null records.
        // It makes sense to keep including records with labId=null in the API response since it is a lab every use has access to.
        entities = response.data.data.filter(
          model => model.labId === getActiveLabId()
        );
      }

      this.setState({
        isLoading: false,
        entities
      });
      return entities;
    } catch (error) {
      console.error(TAG, error.message);
    }
  };

  modelType = () => {
    if (
      this.props.history.location.pathname.includes(
        VIEW_ALL_PREDICTIVE_MODELS_ROUTE
      )
    ) {
      return PREDICTIVE_MODEL;
    } else if (
      this.props.history.location.pathname.includes(
        VIEW_ALL_EVOLUTIVE_MODELS_ROUTE
      )
    ) {
      return EVOLUTIVE_MODEL;
    } else if (
      this.props.history.location.pathname.includes(
        VIEW_ALL_GENERATIVE_MODEL_ROUTE
      )
    ) {
      return GENERATIVE_MODEL;
    }
  };

  handleAddNewClick = () => {
    const modelType = this.state.modelType;

    let target = undefined;

    if (modelType === PREDICTIVE_MODEL) {
      target = EvolveConfig.constants.TRAIN_PREDICTIVE_MODEL_ROUTE;
    } else if (modelType === EVOLUTIVE_MODEL) {
      target = EvolveConfig.constants.TRAIN_EVOLUTIVE_MODEL_ROUTE;
    } else if (modelType === GENERATIVE_MODEL) {
      target = EvolveConfig.constants.TRAIN_GENERATIVE_MODEL_ROUTE;
    } else {
      const errorMessage = `${modelType} not supported.`;
      console.error(TAG, errorMessage);
      throw new Error(errorMessage);
    }
    this.props.history.push(target);
  };

  // TODO: Add a "loading" component for async functions operating on the data table records.
  deleteModel = async modelId => {
    try {
      this.setState({ isLoading: true });
      const response = await window.serverApi.post(
        EvolveConfig.constants.DELETE_MODEL_ENDPOINT,
        { id: modelId }
      );
      if (response.data.error) window.toastr.error(response.data.error);
      else window.toastr.success("Model successfully deleted.");
      const entities = await this.getModels(this.state.modelType);
      this.setState(() => {
        return { entities };
      });
    } catch (error) {
      console.error(TAG, error.message);
    }
    this.setState({ isLoading: false });
  };

  cancelModel = async modelId => {
    try {
      this.setState({ isLoading: true });
      const response = await window.serverApi.post(
        EvolveConfig.constants.CANCEL_MODEL_ENDPOINT,
        { id: modelId }
      );

      if (response.data.error) window.toastr.error(response.data.error);
      else window.toastr.success("Model successfully cancelled.");
      const entities = await this.getModels(this.state.modelType);
      this.setState(() => {
        return { entities };
      });
    } catch (error) {
      window.toastr.error(error);
      console.error(TAG, error.message);
    }
    this.setState({ isLoading: false });
  };

  openModel = pathname => {
    this.props.history.push({ pathname });
  };

  renderContextMenu(tableContext) {
    let pathname;
    if (this.state.modelType === PREDICTIVE_MODEL) {
      pathname = `${PREDICTIVE_MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}${
        tableContext.selectedRecords[0].id
      }`;
    } else if (this.state.modelType === EVOLUTIVE_MODEL) {
      pathname = `${EVOLUTIVE_MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}${
        tableContext.selectedRecords[0].id
      }`;
    } else if (this.state.modelType === GENERATIVE_MODEL) {
      pathname = `${GENERATIVE_MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}${
        tableContext.selectedRecords[0].id
      }`;
    } else {
      pathname = `${MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}${
        tableContext.selectedRecords[0].id
      }`;
    }
    const isOpenable =
      tableContext.selectedRecords[0].status === "completed-successfully" ||
      tableContext.selectedRecords[0].status === "completed-failed";
    const isCancellable =
      tableContext.selectedRecords[0].status === "creating" ||
      tableContext.selectedRecords[0].status === "created" ||
      tableContext.selectedRecords[0].status === "pending" ||
      tableContext.selectedRecords[0].status === "submitting" ||
      tableContext.selectedRecords[0].status === "in-progress";
    return (
      <div>
        {isOpenable && (
          <span style={{ display: "flex" }}>
            <Icon
              icon="log-in"
              intent={Intent.SUCCESS}
              style={{ margin: "6px 0 0 7px" }}
            />
            <MenuItem
              text="Open"
              onClick={() => {
                this.openModel(pathname);
              }}
            />
          </span>
        )}
        {isCancellable && (
          <span style={{ display: "flex" }}>
            <Icon
              icon="disable"
              intent={Intent.WARNING}
              style={{ margin: "6px 0 0 7px" }}
            />
            <MenuItem
              text="Cancel"
              onClick={() => {
                this.cancelModel(tableContext.selectedRecords[0].id);
              }}
            />
          </span>
        )}
        <span style={{ display: "flex" }}>
          <Icon
            icon="trash"
            intent={Intent.DANGER}
            style={{ margin: "6px 0 0 7px" }}
          />
          <MenuItem
            text="Delete"
            onClick={() => {
              this.deleteModel(tableContext.selectedRecords[0].id);
            }}
          />
        </span>
      </div>
    );
  }

  render() {
    const { entities, modelType } = this.state;
    const { tableParams } = this.props;

    let pathname_suffix;
    if (this.state.modelType === PREDICTIVE_MODEL) {
      pathname_suffix = `${PREDICTIVE_MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}`;
    } else if (this.state.modelType === EVOLUTIVE_MODEL) {
      pathname_suffix = `${EVOLUTIVE_MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}`;
    } else if (this.state.modelType === GENERATIVE_MODEL) {
      pathname_suffix = `${GENERATIVE_MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}`;
    } else {
      pathname_suffix = `${MODEL_DETAIL_VIEW_ROUTE.split(":id")[0]}`;
    }
    return (
      <div className="view-container">
        <div
          className={`${allModelsViewStyles.margins} ${allModelsViewStyles.width}`}
        >
          <div className={allModelsViewStyles.headerContainer}>
            <h3>Your {modelType} models</h3>
            <Button
              id={cypressTags.ADD_NEW_MODEL_FROM_OVERVIEW_ID}
              onClick={this.handleAddNewClick}
              minimal
              intent={Intent.PRIMARY}
            >
              Add New
            </Button>
          </div>
          {REFRESH_FOR_UPDATE_TEXT}
          <div id={cypressTags.ALL_MODELS_DATATABLE_ID}>
            <DataTable
              formName="allModelsViewTable"
              entities={entities}
              schema={allModelsViewSchema}
              noHeader
              onRefresh={this.onRefresh}
              onDoubleClick={record => {
                const isOpenable =
                  record.status === "completed-successfully" ||
                  record.status === "completed-failed";
                if (isOpenable)
                  this.props.history.push({
                    pathname: `${pathname_suffix}${record.id}`
                  });
              }}
              isSingleSelect
              {...tableParams}
              contextMenu={this.renderContextMenu.bind(this)}
              isLoading={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AllModelsView);
