/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

const GIBSON_ASSEMBLY = "Flanking homology (eg: Gibson/SLIC/CPEC)";
const GOLDEN_GATE_ASSEMBLY = "Type II Endonuclease (eg: Golden Gate, MoClo)";

const inputsToApiDefault = inputs => {
  return inputs.reduce((prev, current) => {
    const { name, value, default: defaultValue } = current;
    prev[name] = value != null ? value : defaultValue;
    return prev;
  }, {});
};

const ValidationAlgorithms = {
  ValidateKmerTandemRepeats: {
    name: "ValidateKmerTandemRepeats",
    label: "Avoid non - tandem repeats",
    info: "Overlap validator that looks for k-mer repetitions",
    inputs: [
      {
        id: 1,
        name: "k",
        label: "Size of kmers",
        type: "numeric",
        default: 6,
        min: 1,
        value: null
      },
      {
        id: 2,
        name: "max_coverage",
        label:
          "Max coverage percentage allowed for a series of kmers repetitions",
        type: "numeric",
        default: 50.0,
        min: 0,
        max: 100,
        value: null
      }
    ],
    inputsToApi: function () {
      return inputsToApiDefault(this.inputs);
    }
  },
  ValidateOverlapDuplicationInSequence: {
    name: "ValidateOverlapDuplicationInSequence",
    label: "Avoid duplicated overlap sequences",
    info: "Evaluator that checks if overlap is repeated in the full sequence",
    inputs: [
      {
        id: 1,
        name: "max_edit_distance",
        label: "Max edit distance",
        type: "numeric",
        min: 0,
        default: 5,
        value: null
      }
    ],
    inputsToApi: function () {
      return inputsToApiDefault(this.inputs);
    }
  },
  ValidateHomopolymerStretches: {
    name: "ValidateHomopolymerStretches",
    label: "Avoid Homopolymer Stretches",
    info: "Looks for homopolymer stretches",
    inputs: [
      {
        id: 1,
        name: "max_size",
        label: "Stretch size",
        type: "numeric",
        min: 0,
        default: 5,
        value: null
      }
    ],
    inputsToApi: function () {
      return inputsToApiDefault(this.inputs);
    }
  },
  ValidateKMerRepeats: {
    name: "ValidateKMerRepeats",
    label: "Remove K-mer Repeats",
    info: "Look for repetitions of a kmer in the overlap sequence",
    inputs: [
      {
        id: 1,
        name: "k",
        label: "K-mers length",
        type: "numeric",
        min: 0,
        default: 6,
        value: null
      }
    ],
    inputsToApi: function () {
      return inputsToApiDefault(this.inputs);
    }
  }
};

Object.values(ValidationAlgorithms).forEach(val => {
  val.id = val.name;
});

const PartitionParameters = {
  default: {
    partitionSizeMin: 300,
    partitionSizeMax: 5000,
    overlapSizeMin: 20,
    overlapSizeOptimal: 35,
    overlapSizeMax: 50,
    overlapGcContentMin: 42,
    overlapGcContentOptimal: 50,
    overlapGcContentMax: 60,
    assemblyStrategy: GIBSON_ASSEMBLY,
    selectedRestrictionEnzymes: [],
    overlap_validator_config: {}
  },
  inputsToApi: function (values) {
    return {
      isSingleDesign: values.isSingleDesign,
      dna_synthesis_length: {
        min: values.partitionSizeMin,
        max: values.partitionSizeMax
      },
      assembly_strategy:
        values.assemblyStrategy === GIBSON_ASSEMBLY
          ? "PCR_GIBSON"
          : "DIGEST_GOLDEN_GATE",
      pcr_gibson_configuration: {
        overlap_length: {
          min: values.overlapSizeMin,
          opt: values.overlapSizeOptimal,
          max: values.overlapSizeMax
        },
        gc_content_range: {
          min: values.overlapGcContentMin,
          opt: values.overlapGcContentOptimal,
          max: values.overlapGcContentMax
        },
        overlap_validator_config: values.overlap_validator_config
      },

      digest_configuration: {
        enzymes_configurations: values.selectedRestrictionEnzymes.map(el => ({
          name: el.name,
          forward_sequence: el.sequence,
          forward_cut_position: el.forwardSnipPosition,
          reverse_cut_position: el.reverseSnipPosition
        }))
      }
    };
  }
};

const InventoryParameters = {
  default: {
    min_match_length: 100,
    terminate_on_full_sequence_inventory_match: false
  },
  inputsToApi: function (values) {
    return {
      min_match_length: values.min_match_length,
      terminate_on_full_sequence_inventory_match:
        values.terminate_on_full_sequence_inventory_match
    };
  }
};

export {
  ValidationAlgorithms,
  PartitionParameters,
  InventoryParameters,
  GIBSON_ASSEMBLY,
  GOLDEN_GATE_ASSEMBLY
};
