/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get } from "lodash";
import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";
const locationField = [
  {
    path: "location",
    type: "string",
    displayName: "Location"
  }
];

export const j5PcrReaction = {
  model: "plateMapEntity",
  fields: [
    ...locationField,
    {
      path: "j5Item.j5PcrReaction.primaryTemplate.name",
      type: "string",
      displayName: "Primary Template"
    },
    {
      path: "j5Item.j5PcrReaction.forwardPrimer.sequence.name",
      type: "string",
      displayName: "Forward Primer"
    },
    {
      path: "j5Item.j5PcrReaction.reversePrimer.sequence.name",
      type: "string",
      displayName: "Reverse Primer"
    },
    {
      path: "j5Item.j5PcrReaction.oligoMeanTm",
      type: "string",
      displayName: "Mean Tm"
    },
    {
      path: "j5Item.j5PcrReaction.oligoDeltaTm",
      type: "string",
      displayName: "Delta Tm"
    },
    {
      path: "j5Item.j5PcrReaction.pcrProductSequence.name",
      type: "string",
      displayName: "Product Sequence"
    },
    {
      path: "j5Item.j5PcrReaction.pcrProductSequence.size",
      type: "string",
      displayName: "Product Size (bp)"
    },
    {
      path: "metadata.elongationTime",
      type: "string",
      displayName: "Elongation Time (s)"
    }
  ]
};

export const sequence = {
  model: "plateMapEntity",
  fields: [
    ...locationField,
    {
      path: "wellContents",
      type: "string",
      displayName: "Well Contents (Primary Template Sequence)"
    }
  ]
};

const simpleSchemas = ["additiveMaterial", "sample", "lot", "material"].reduce(
  (acc, model) => {
    acc[model] = [
      ...locationField,
      {
        path: `inventoryItem.${model}.name`,
        type: "string",
        displayName: modelNameToReadableName(model, { upperCase: true })
      }
    ];
    return acc;
  },
  {}
);

export const material = simpleSchemas["material"];
export const additiveMaterial = simpleSchemas["additiveMaterial"];
export const sample = simpleSchemas["sample"];
export const lot = simpleSchemas["lot"];

export const j5InputSequence = {
  model: "plateMapEntity",
  fields: [
    ...locationField,
    {
      path: "j5Item.j5InputSequence.sequence.name",
      type: "string",
      displayName: "Sequence Name"
    },
    {
      path: "j5Item.j5InputSequence.sequence.size",
      type: "string",
      displayName: "Amplicon Size",
      render: v => v + " bp"
    },
    {
      path: "j5Item.j5InputSequence.isStock",
      type: "boolean",
      displayName: "In Stock"
    }
  ]
};

export const j5DirectSynthesis = {
  model: "plateMapEntity",
  fields: [
    ...locationField,
    {
      path: "j5Item.j5DirectSynthesis.sequence.name",
      type: "string",
      displayName: "Sequence Name"
    },
    {
      path: "j5Item.j5DirectSynthesis.sequence.size",
      type: "string",
      displayName: "Amplicon Size",
      render: v => v + " bp"
    }
  ]
};

const tempRender = value => {
  return Math.ceil(value * 100) / 100 + " °C";
};

export const pcrProductSequence = [
  { path: "pcrProductSequence.name", displayName: "Contents" },
  {
    path: "oligoMeanTm",
    displayName: "Mean Temp",
    render: v => tempRender(v)
  },
  {
    path: "oligoDeltaTm",
    displayName: "Δ Temp",
    render: v => tempRender(v)
  },
  {
    path: "oligoMeanTm3Prime",
    displayName: "Mean Temp 3'",
    render: v => tempRender(v)
  },
  {
    path: "oligoDeltaTm3Prime",
    displayName: "Δ Temp 3'",
    render: v => tempRender(v)
  },
  {
    path: "pcrProductSequence.size",
    displayName: "Size",
    render: v => v + " bp"
  }
];

export const pcrPlanningInputSequence = {
  model: "plateMapEntity",
  fields: [
    {
      path: "location",
      type: "string",
      displayName: "Location"
    },
    {
      path: "name",
      type: "string",
      displayName: "Material Name"
    },
    {
      path: "j5InputSequence.sequence.size",
      type: "string",
      displayName: "Amplicon Size",
      render: v => (v ? v + " bp" : "")
    },
    {
      path: "j5InputSequence.isStock",
      type: "boolean",
      displayName: "In Stock",
      render: (v, r) =>
        get(r, "j5InputSequence.sequence.polynucleotideMaterial.samples.length")
          ? "True"
          : "False"
    }
  ]
};
export const j5Oligo = {
  model: "plateMapEntity",
  fields: [
    ...locationField,
    {
      path: "name",
      type: "string",
      displayName: "Oligo Name"
    },
    {
      path: "j5Item.j5Oligo.sequence.size",
      type: "string",
      displayName: "Amplicon Size",
      render: v => (v ? v + " bp" : "")
    }
  ]
};
