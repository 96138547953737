/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Callout, Classes, Tab, Tabs } from "@blueprintjs/core";
import {
  DialogFooter,
  wrapDialog,
  FileUploadField,
  BlueprintError,
  tgFormValues
} from "@teselagen/ui";
import {
  allowedCsvFileTypes,
  extractZipFiles,
  isCsvOrExcelFile,
  parseCsvOrExcelFile
} from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { reactionMapCsvFields } from "../../../utils/reactionMapUtils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { parseReactionMapCsvData } from "../../../../../tg-iso-lims/src/utils/reactionMapUtils";
import { useState } from "react";
import example from "../../../../../tg-iso-shared/example.reaction.json";
import ReactionJsonUploadMessage from "./ReactionJsonUploadMessage";
import uploadReactionMapJson from "./uploadReactionMapJson";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

function UploadReactionMapsDialog(props) {
  const [tabId, changeTabId] = useState("existingMaterials");
  const {
    hideModal,
    handleSubmit,
    submitting,
    error,
    refetch,
    reactionType = {}
  } = props;
  async function onSubmitNewMaterials(values) {
    await uploadReactionMapJson(values.reactionMapFiles, {
      refetch,
      labId: values.labId
    });
  }
  async function onSubmitExistingMaterials(values) {
    try {
      const allFiles = await extractZipFiles(values.reactionMapFiles);
      const csvFiles = allFiles.filter(isCsvOrExcelFile);
      if (!csvFiles.length) {
        return window.toastr.error("No csv files found.");
      }
      const parsedCsvs = [];
      for (const file of csvFiles) {
        parsedCsvs.push({
          ...(await parseCsvOrExcelFile(file)),
          name: file.name
        });
      }
      const { error, reactionMaps } = await parseReactionMapCsvData(
        parsedCsvs,
        { forUpsert: true }
      );
      if (error) {
        throw new Error(error);
      }
      await safeUpsert(
        "reactionMap",
        reactionMaps.map(rm => {
          return {
            ...rm,
            reactionTypeCode: values.reactionType.code
          };
        })
      );
      await refetch();
      hideModal();
    } catch (error) {
      throwFormError(error.message || "Error uploading reaction maps");
      console.error(`error:`, error);
      window.toastr.error("Error uploading reaction maps");
    }
  }

  const innerShared = <BlueprintError error={error} />;
  const reactionMapSelect = (
    <GenericSelect
      name="reactionType"
      isRequired
      asReactSelect
      label="Reaction Type"
      idAs="code"
      schema={["name"]}
      fragment={["reactionType", "code name"]}
    />
  );
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Tabs
          selectedTabId={tabId}
          onChange={id => changeTabId(id)}
          renderActiveTabPanelOnly
        >
          <Tab
            title="Existing Materials"
            panel={
              <>
                <Callout style={{ marginBottom: 10 }} intent="primary">
                  Use this style of reaction map upload if you want to link your
                  reaction map to existing materials.
                </Callout>

                {reactionMapSelect}
                <FileUploadField
                  isRequired
                  accept={getDownloadTemplateFileHelpers({
                    type: allowedCsvFileTypes.concat(".zip"),
                    fileName: `${
                      (reactionType && reactionType.name) || "Reaction"
                    } Map`,
                    validateAgainstSchema: {
                      fields: reactionMapCsvFields
                    }
                  })}
                  label="Upload Reaction Map Files"
                  name="reactionMapFiles"
                />
                {innerShared}
              </>
            }
            id="existingMaterials"
          ></Tab>
          <Tab
            title="New Materials"
            panel={
              <>
                <ReactionJsonUploadMessage />
                <FileUploadField
                  isRequired
                  accept={getDownloadTemplateFileHelpers({
                    type: ".json",
                    fileName: "Reaction_Map.reaction.json",
                    fileContents: JSON.stringify(example, null, 2)
                  })}
                  label="Upload .reaction.json Reaction Map Files"
                  name="reactionMapFiles"
                />
                {innerShared}
              </>
            }
            id="newMaterials"
          ></Tab>
        </Tabs>
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(
          tabId === "existingMaterials"
            ? onSubmitExistingMaterials
            : onSubmitNewMaterials
        )}
      />
    </>
  );
}

export default compose(
  wrapDialog({
    title: "Upload Reaction Maps"
  }),
  reduxForm({
    form: "uploadReactionMaps"
  }),
  tgFormValues("reactionType")
)(UploadReactionMapsDialog);
