/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import {
  worklistPlanningDestinationPlateFragment,
  worklistPlanningReactionMapFragment
} from "./fragments";
import {
  CheckboxField,
  DataTable,
  InputField,
  NumericInputField,
  RadioGroupField,
  ReactSelectField,
  withSelectedEntities,
  withSelectTableRecords
} from "@teselagen/ui";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { chunk, get, keyBy, isEmpty, forEach, flatten, isEqual } from "lodash";
import { Button, ButtonGroup, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { compose } from "recompose";
import { PlateMapPlateNoContext } from "../../PlateMapPlate";
import { connect } from "react-redux";

import { containerArraySelectedAliquotContainerLocationsSelector } from "../../../../src-shared/redux/selectors";
import {
  getLocationHashMapGivenWellCountAndDirection,
  plateLibraryFilter
} from "../../../utils/plateUtils";
import DraggableMaterialHandle from "../CreateOrEditPlateMapTool/Steps/PlateMapConfiguration/DraggableMaterialHandle";
import CustomMaterialDragLayer from "../CreateOrEditPlateMapTool/Steps/PlateMapConfiguration/CustomMaterialDragLayer";
import shortid from "shortid";
import gql from "graphql-tag";
import { isValidNumOfReactions } from "./utils";
import actions from "../../../../src-shared/redux/actions";
import { rowIndexToLetter } from "../../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import {
  generateEmptyWells,
  getPositionFromAlphanumericLocation
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import PlateUploadFields from "../../PlateUploadFields";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import FillDirectionSelect from "../../FillDirectionSelect";
import { hideDialog, showDialog } from "../../../../src-shared/GlobalDialog";
import DistributeWithPlateMapDialog from "./DistributeWithPlateMapDialog";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import fieldConstants from "./fieldConstants";
import containerArrayTypeFragment from "../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";

// store a map for each plate for location to reaction
// when you drop a reaction add it to the map
// change preview so that it shows which wells have reactions
// allow dragging reactions around on plate

const newPlateTypeFilter = (p, qb) => {
  qb.whereAll({
    quadrantSize: qb.greaterThan(2)
  });
};

export const worklistPlanningContainerArrayTypeFragment = gql`
  fragment worklistPlanningContainerArrayTypeFragment on containerArrayType {
    id
    name
    aliquotContainerTypeCode
    isPlate
    isColumn
    containerFormatCode
    nestableTubeTypes {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        code
        name
      }
    }
    containerFormat {
      code
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
    }
  }
`;

const reactionInputOutputColumns = [
  {
    path: "reactionInputs.inputMaterial.name",
    displayName: "Inputs",
    getValueToFilterOn: getReactionInputs,
    render: (v, reaction) => getReactionInputs(reaction)
  },
  {
    path: "reactionOutputs.outputMaterial.name",
    displayName: "Outputs",
    getValueToFilterOn: getReactionOutputs,
    render: (v, reaction) => getReactionOutputs(reaction)
  }
];

class SelectPlates extends Component {
  state = {
    distributingWithPlateMap: false
  };
  componentDidUpdate(oldProps) {
    const {
      reactionMaps: oldReactionMaps = [],
      destinationPlates: oldDest
    } = oldProps;
    const {
      reactionMaps = [],
      stepFormProps: { change },
      plateToReactionMap = {},
      destinationPlates = [],
      destinationType,
      newPlateType
    } = this.props;
    const newReactionMapIds = reactionMaps.map(rm => rm.id);
    const oldReactionMapIds = oldReactionMaps.map(rm => rm.id);
    if (
      !isEqual([...newReactionMapIds].sort(), [...oldReactionMapIds].sort()) &&
      !isEmpty(plateToReactionMap)
    ) {
      change("plateToReactionMap", {});
    } else {
      if (destinationPlates !== oldDest) {
        const newIds = destinationPlates.map(p => p.id);
        const oldKeys = Object.keys(plateToReactionMap);
        oldKeys.forEach(key => {
          if (!newIds.includes(key)) {
            delete plateToReactionMap[key];
          }
        });
        if (Object.keys(plateToReactionMap).length !== oldKeys.length) {
          change("plateToReactionMap", plateToReactionMap);
        }
      }
    }

    if (
      newPlateType &&
      destinationType === "New Plates" &&
      !destinationPlates.length
    ) {
      const newPlate = this.generateNewPlate(newPlateType);
      change("destinationPlates", [newPlate]);
      change("activePlate", newPlate);
    }

    this.distributeWithIntegratedPlateMapGroup();
  }

  distributeWithIntegratedPlateMapGroup = () => {
    const {
      toolIntegrationProps: { isIntegratedMap = {} },
      reactionMaps = [],
      plateMapGroup,
      staticTracker
    } = this.props;
    // if the reaction maps are pre-selected and they have a plate map integrated then try to
    // distribute using it.
    // but only do this once!
    if (isIntegratedMap.plateMapGroup && !isIntegratedMap.reactionMaps) {
      staticTracker.showedIntegrationWarning = true;
      window.toastr.warning(
        "Can not use pre-selected plate map if reaction maps are not pre-selected."
      );
    } else if (
      isIntegratedMap.plateMapGroup &&
      isIntegratedMap.reactionMaps &&
      reactionMaps.length &&
      plateMapGroup &&
      !staticTracker.usedPreselectedPlateMapGroup
    ) {
      staticTracker.usedPreselectedPlateMapGroup = true;
      window.toastr.info("Distributing using integrated plate map...");
      this.distributeUsingPlateMap(plateMapGroup);
    }
  };

  onDistributeReactionsClick = async () => {
    const { reactionMaps = [], selectTableRecords } = this.props;
    // always starts from a1, skip wells which already have a reaction
    // count up all of the already filled wells and add that to the number of reactions
    // first do dry run and see where they will be placed. count up how many of those
    // destinations have reactions and then do a run with that added to the number of source
    //
    // dont make extra plates automatically, but jump to it if there is one
    const allReactions = flatten(reactionMaps.map(rm => rm.reactions));
    selectTableRecords(allReactions);

    // wait for records to be selected
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 50);
    });

    // these will now be sorted in same order as table
    const {
      worklistPlanningReactionMapTableSelectedEntities: selectedReactions = []
    } = this.props;

    this.handleSourceMaterialDrop({
      droppedLocation: "A1",
      selectedSourceMaterials: selectedReactions,
      skipFilledWells: true,
      multiplate: true
    });
  };

  handleSourceMaterialDrop = ({
    selectedSourceMaterials,
    droppedLocation,
    skipFilledWells,
    multiplate
  }) => {
    const {
      fillDirection,
      setSelectedAliquotContainerLocations,
      stepFormProps: { change },
      plateToReactionMap = {},
      activePlate,
      [fieldConstants.enableMultipleReactions]: enableMultipleReactions,
      [fieldConstants.numberOfReactionsPerWell]: numberOfReactionsPerWell,
      destinationPlates,
      newPlateType,
      destinationType
    } = this.props;
    if (!activePlate) return;
    const newPlateToReactionMap = { ...plateToReactionMap };
    if (newPlateToReactionMap[activePlate.id]) {
      newPlateToReactionMap[activePlate.id] = {
        ...newPlateToReactionMap[activePlate.id]
      };
    } else {
      newPlateToReactionMap[activePlate.id] = {};
    }

    let reactionGroups = selectedSourceMaterials;
    if (
      enableMultipleReactions &&
      isValidNumOfReactions(numberOfReactionsPerWell)
    ) {
      reactionGroups = chunk(
        reactionGroups,
        Math.floor(numberOfReactionsPerWell)
      );
    }

    const containerFormat = activePlate.containerArrayType.containerFormat;

    let numberOfWellsToDropInto = reactionGroups.length;

    let numberOfAlreadyFilledWells = 0;
    forEach(newPlateToReactionMap, locationMap => {
      numberOfAlreadyFilledWells += Object.keys(locationMap).length;
    });

    if (skipFilledWells) {
      // add the number of already filled wells so the getLocationHashMapGivenWellCountAndDirection
      // will get enough destination locations to be able to skip all filled wells
      numberOfWellsToDropInto =
        numberOfWellsToDropInto + numberOfAlreadyFilledWells;
    }

    const reactionList = [...reactionGroups];

    const dstWellsMaps = getLocationHashMapGivenWellCountAndDirection({
      containerFormat: containerFormat,
      numWells: numberOfWellsToDropInto,
      startingPosition: droppedLocation,
      direction: fillDirection || "right", // left, right, up, down
      multiplate: true // allow multiplate if they drag extra but ignore the extra reactions
    });

    // we want to place overflow of reactions onto plates which come after active plate
    const plateList = destinationPlates.slice(
      destinationPlates.indexOf(activePlate)
    );

    const newDestinationPlates = [...destinationPlates];

    dstWellsMaps.forEach((dstWellsMap, i) => {
      if (i > 0 && !multiplate) return;
      let plate = plateList[i];
      if (!plate) {
        if (destinationType === "New Plates") {
          plate = this.generateNewPlate(
            newPlateType,
            newDestinationPlates.length + 1
          );
          newDestinationPlates.push(plate);
        } else {
          return;
        }
      }
      // Although the keys returned technically have no order guarantee, they
      // tend to be the same order as they were put in.
      const dstWells = Object.keys(dstWellsMap);
      if (!newPlateToReactionMap[plate.id]) {
        newPlateToReactionMap[plate.id] = {};
      }
      dstWells.forEach(loc => {
        if (skipFilledWells && newPlateToReactionMap[plate.id][loc]) return;
        const reactionsToPutHere = reactionList.shift();
        if (reactionsToPutHere) {
          let reactionsForLocation;
          if (Array.isArray(reactionsToPutHere)) {
            reactionsForLocation = reactionsToPutHere.map(r => r.id);
          } else {
            reactionsForLocation = [reactionsToPutHere.id];
          }
          newPlateToReactionMap[plate.id][loc] = reactionsForLocation;
        }
      });
    });

    const dstWells = Object.keys(dstWellsMaps[0]);
    setSelectedAliquotContainerLocations({
      locations: Object.keys(dstWells)
    });
    change("destinationPlates", newDestinationPlates);
    change("plateToReactionMap", newPlateToReactionMap);
  };

  handleWellOnWellDrop = ({
    sourceLocations,
    draggedLocation,
    droppedLocation
  }) => {
    const {
      setSelectedAliquotContainerLocations,
      activePlate,
      plateToReactionMap,
      stepFormProps: { change }
    } = this.props;

    const {
      rowCount,
      columnCount: colCount
    } = activePlate.containerArrayType.containerFormat;
    // move all dropped reactions
    const {
      columnPosition: dragCol,
      rowPosition: dragRow
    } = getPositionFromAlphanumericLocation(draggedLocation);
    const {
      columnPosition: dropCol,
      rowPosition: dropRow
    } = getPositionFromAlphanumericLocation(droppedLocation);
    const activePlateLocations = this.getActiveReactionLocations();
    const newPlateActives = { ...activePlateLocations };

    const deltaCol = dropCol - dragCol;
    const deltaRow = dropRow - dragRow;
    const dstLocations = sourceLocations.map(srcLoc => {
      const {
        columnPosition: srcCol,
        rowPosition: srcRow
      } = getPositionFromAlphanumericLocation(srcLoc);

      const dstCol = (srcCol + deltaCol + colCount) % colCount;
      const dstRow = (srcRow + deltaRow + rowCount) % rowCount;

      const dstLoc = rowIndexToLetter(dstRow) + (dstCol + 1);
      return dstLoc;
    });

    sourceLocations.forEach((srcLoc, i) => {
      const dstLoc = dstLocations[i];
      if (srcLoc === dstLoc) return;
      if (newPlateActives[srcLoc])
        newPlateActives[dstLoc] = newPlateActives[srcLoc];
      else delete newPlateActives[dstLoc];

      // remove from source
      delete newPlateActives[srcLoc];
    });

    const newPlateToReactionMap = {
      ...plateToReactionMap,
      [activePlate.id]: newPlateActives
    };
    setSelectedAliquotContainerLocations({ locations: dstLocations });
    change("plateToReactionMap", newPlateToReactionMap);
  };

  canDrag = wellProps => {
    const activeFilledWells = this.getActiveReactionLocations();
    // only allow dragging if the well has a reaction
    return !!activeFilledWells[wellProps.location];
  };

  clearPlate = () => {
    const {
      stepFormProps: { change },
      plateToReactionMap,
      activePlate
    } = this.props;
    change("plateToReactionMap", {
      ...plateToReactionMap,
      [activePlate.id]: {}
    });
  };

  clearAllPlate = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("plateToReactionMap", {});
  };

  aliquotContextMenu = () => {
    const {
      stepFormProps: { change },
      plateToReactionMap,
      activePlate
    } = this.props;
    const activeReactionLocations = this.getActiveReactionLocations();

    const selectedLocations = containerArraySelectedAliquotContainerLocationsSelector(
      window.teGlobalStore.getState()
    );

    const hasReactionSelected = selectedLocations.some(
      l => activeReactionLocations[l]
    );

    return (
      <Menu>
        <MenuItem
          icon="trash"
          text="Remove Reaction"
          disabled={!hasReactionSelected}
          onClick={() => {
            const newActiveForPlate = {
              ...activeReactionLocations
            };
            selectedLocations.forEach(l => {
              delete newActiveForPlate[l];
            });
            change("plateToReactionMap", {
              ...plateToReactionMap,
              [activePlate.id]: newActiveForPlate
            });
          }}
        />
      </Menu>
    );
  };

  getActiveReactionLocations = () => {
    const { activePlate, plateToReactionMap = {} } = this.props;
    let activeFilledWells = {};
    if (activePlate) {
      activeFilledWells =
        (activePlate && plateToReactionMap[activePlate.id]) || {};
    }
    return activeFilledWells;
  };

  beforeNextStep = async ({
    destinationPlates,
    plateToReactionMap,
    shouldFillRack,
    aliquotContainerType
  }) => {
    const {
      nextStep,
      stepFormProps: { change }
    } = this.props;
    try {
      if (destinationPlates[0].id.includes("__")) {
        const fullDest = destinationPlates.map(destP => {
          destP.aliquotContainers = destP.aliquotContainers.filter(ac => {
            if (!ac.id) {
              ac.cid = shortid();
            }
            if (!destP.containerArrayType.isPlate) {
              const loc = getAliquotContainerLocation(ac, { force2D: true });
              const isMatch = plateToReactionMap[destP.id]?.[loc];
              if (aliquotContainerType) {
                ac.aliquotContainerTypeCode = aliquotContainerType.code;
              }
              // if not filling the rack then we only want to keep the destinations that we are using
              if (shouldFillRack) return true;
              return isMatch;
            }
            return true;
          });
          return destP;
        });
        change("destinationPlates", fullDest);
      }
      nextStep();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading info.");
    }
  };

  getAllPlacedReactionIds = () => {
    const { plateToReactionMap = {} } = this.props;
    const allPlacedReactionIds = [];
    Object.keys(plateToReactionMap).forEach(plateId => {
      const reactionIds = Object.values(plateToReactionMap[plateId]);
      reactionIds.forEach(arrayOfReactionIds => {
        arrayOfReactionIds.forEach(rId => {
          if (!allPlacedReactionIds.includes(rId)) {
            allPlacedReactionIds.push(rId);
          }
        });
      });
    });
    return allPlacedReactionIds;
  };

  generateNewPlate = (containerArrayType, index = 1) => {
    const { plateBaseName } = this.props;
    const acs = generateEmptyWells(containerArrayType.containerFormat, {
      aliquotContainerTypeCode:
        containerArrayType.aliquotContainerTypeCode ||
        containerArrayType.nestableTubeTypes[0]?.aliquotContainerTypeCode
    });
    if (!containerArrayType.isPlate) {
      acs.forEach(ac => {
        // this will force the plate to render "empty tubes" instead of completely empty
        ac.id = shortid();
        ac.aliquot = null;
      });
    }
    return {
      id: "__" + shortid(),
      name: (plateBaseName || "Destination Plate") + ` ${index}`,
      containerArrayType,
      aliquotContainers: acs
    };
  };

  distributeUsingPlateMap = async plateMapGroup => {
    const {
      stepFormProps: { change },
      reactionMaps
    } = this.props;

    const handler = async () => {
      try {
        const fullPlateMapGroup = await safeQuery(
          [
            "plateMapGroup",
            /* GraphQL */ `
              {
                id
                containerFormat {
                  code
                  rowCount
                  columnCount
                }
                plateMaps {
                  id
                  plateMapItems {
                    id
                    rowPosition
                    columnPosition
                    inventoryItem {
                      id
                      materialId
                    }
                  }
                }
              }
            `
          ],
          {
            variables: {
              id: plateMapGroup.id
            }
          }
        );
        const [containerArrayTypeWithSameFormat] = await safeQuery(
          containerArrayTypeFragment,
          {
            variables: {
              pageSize: 1,
              filter: {
                "containerFormat.rowCount":
                  fullPlateMapGroup.containerFormat.rowCount,
                "containerFormat.columnCount":
                  fullPlateMapGroup.containerFormat.columnCount
              }
            }
          }
        );
        if (!containerArrayTypeWithSameFormat) {
          return window.toastr.error(
            "No plate type found to match plate map type"
          );
        }

        const outputMaterialIdToReactionId = {};
        reactionMaps.forEach(rm => {
          rm.reactions.forEach(reaction => {
            if (reaction.reactionOutputs.length === 1) {
              reaction.reactionOutputs.forEach(o => {
                const matId = o.outputMaterial.id;
                if (matId) {
                  outputMaterialIdToReactionId[matId] = reaction.id;
                }
              });
            }
          });
        });
        if (isEmpty(outputMaterialIdToReactionId)) {
          return window.toastr.error(
            "No reactions selected that output a single material."
          );
        }

        const plateToReactionMap = {};
        const destinationPlates = [];
        fullPlateMapGroup.plateMaps.forEach((pm, i) => {
          const newPlate = this.generateNewPlate(
            containerArrayTypeWithSameFormat,
            i + 1
          );
          destinationPlates.push(newPlate);
          pm.plateMapItems.forEach(pmi => {
            const matId = pmi.inventoryItem?.materialId;
            const reactionId = matId && outputMaterialIdToReactionId[matId];
            if (matId && reactionId) {
              const loc = getAliquotContainerLocation(pmi, { force2D: true });
              if (!plateToReactionMap[newPlate.id]) {
                plateToReactionMap[newPlate.id] = {};
              }
              plateToReactionMap[newPlate.id][loc] = [reactionId];
            }
          });
        });
        if (isEmpty(plateToReactionMap)) {
          return window.toastr.warning(
            "No reactions with output materials in plate map."
          );
        }

        change("newPlateType", containerArrayTypeWithSameFormat);
        change("destinationPlates", destinationPlates);
        change("plateToReactionMap", plateToReactionMap);
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.error("Error placing reactions according to plate map.");
      }
    };

    this.setState({
      distributingWithPlateMap: true
    });
    await handler();
    this.setState({
      distributingWithPlateMap: false
    });
  };

  render() {
    const {
      destinationType,
      stepFormProps: { change },
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      reactionMaps = [],
      destinationPlates = [],
      activePlate,
      worklistPlanningReactionMapTableSelectedEntities: selectedReactions = [],
      selectedAliquotContainerLocations = [],
      setSelectedAliquotContainerLocation,
      plateToReactionMap = {},
      [fieldConstants.enableMultipleReactions]: enableMultipleReactions,
      [fieldConstants.numberOfReactionsPerWell]: numberOfReactionsPerWell,
      plateBaseName,
      newPlateType,
      Footer,
      footerProps,
      handleSubmit
    } = this.props;

    let keyedReactions = {};
    reactionMaps.forEach(
      rm =>
        (keyedReactions = {
          ...keyedReactions,
          ...keyBy(get(rm, "reactions"), "id")
        })
    );
    const allReactions = flatten(reactionMaps.map(rm => rm.reactions));
    const activeFilledWells = this.getActiveReactionLocations();
    const allPlacedReactionIds = this.getAllPlacedReactionIds();

    const selectedLocation = selectedAliquotContainerLocations[0];
    let activeReactionsForWell = [];
    if (selectedLocation && activeFilledWells[selectedLocation]) {
      activeReactionsForWell = activeFilledWells[selectedLocation];
    }

    return (
      <div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <div
              style={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <HeaderWithHelper
                header="Select Reaction"
                helper="Select a reaction map to plan worklist."
              />
              <GenericSelect
                {...{
                  name: "reactionMaps",
                  isRequired: true,
                  isMultiSelect: true,
                  schema: [
                    "name",
                    { displayName: "Type", path: "reactionType.name" },
                    dateModifiedColumn
                  ],
                  fragment: [
                    "reactionMap",
                    "id name reactionType { code name } updatedAt"
                  ],
                  additionalDataFragment: worklistPlanningReactionMapFragment,
                  buttonProps: {
                    loading: isLoadingMap.reactionMaps,
                    disabled: isDisabledMap.reactionMaps
                  }
                }}
              />
              {!!reactionMaps?.length && (
                <React.Fragment>
                  <h6>{reactionMaps.map(rm => rm.name).join(", ")}</h6>
                  <CheckboxField
                    name={fieldConstants.enableMultipleReactions}
                    label="Enable Multiple Reactions per Well"
                    tooltipInfo="If checked you can drag multiple reactions into a single plate well on the right."
                  />
                  {enableMultipleReactions && (
                    <NumericInputField
                      name={fieldConstants.numberOfReactionsPerWell}
                      label="Number of Reactions per Well"
                      defaultValue={2}
                    />
                  )}
                  <div style={{ display: "flex", alignItems: "end" }}>
                    <Button
                      intent="primary"
                      text="Distribute Reactions"
                      disabled={
                        enableMultipleReactions
                          ? !isValidNumOfReactions(numberOfReactionsPerWell)
                          : false
                      }
                      onClick={this.onDistributeReactionsClick}
                    />
                    <div style={{ width: 150, marginLeft: 5 }}>
                      <FillDirectionSelect />
                    </div>
                    {destinationType === "New Plates" && (
                      <Button
                        intent="primary"
                        disabled={isDisabledMap.plateMapGroup}
                        style={{ marginLeft: 5 }}
                        text="Distribute with Plate Map"
                        loading={this.state.distributingWithPlateMap}
                        onClick={() => {
                          showDialog({
                            ModalComponent: DistributeWithPlateMapDialog,
                            modalProps: {
                              onSelect: async plateMapGroup => {
                                this.distributeUsingPlateMap(plateMapGroup);
                                hideDialog();
                              }
                            }
                          });
                        }}
                      />
                    )}
                  </div>
                  <DataTable
                    isSimple
                    doNotShowEmptyRows
                    entities={allReactions}
                    formName="worklistPlanningReactionMapTable"
                    selectedSourceMaterials={selectedReactions}
                    allPlacedReactionIds={allPlacedReactionIds}
                    withSearch
                    schema={[
                      {
                        type: "action",
                        width: 40,
                        resizable: false,
                        render: (_, reaction) => {
                          return (
                            <DraggableMaterialHandle
                              selectedSourceMaterials={selectedReactions}
                              material={reaction}
                            />
                          );
                        }
                      },
                      ...reactionInputOutputColumns,
                      {
                        type: "action",
                        resizable: false,
                        width: 40,
                        render: (_, reaction) => {
                          if (allPlacedReactionIds.includes(reaction.id)) {
                            return <Icon icon="tick-circle" intent="success" />;
                          }
                        }
                      }
                    ]}
                  ></DataTable>
                </React.Fragment>
              )}
            </div>
            <div style={{ width: 50 }} />
            <div style={{ width: "50%" }}>
              <HeaderWithHelper
                width="100%"
                header="Destination Plates"
                helper="Create new destination plates or choose from inventory"
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <RadioGroupField
                  name={fieldConstants.destinationType}
                  options={["New Plates", "Existing Plates"]}
                  defaultValue="New Plates"
                  onFieldSubmit={() => {
                    change("destinationPlates", []);
                    change("activePlate", null);
                  }}
                />
                {destinationType === "Existing Plates" && (
                  <React.Fragment>
                    <GenericSelect
                      {...{
                        isRequired: true,
                        noFill: true,
                        name: "destinationPlates",
                        schema: [
                          "name",
                          {
                            displayName: "Barcode",
                            path: "barcode.barcodeString"
                          },
                          dateModifiedColumn
                        ],
                        isMultiSelect: true,
                        fragment: [
                          "containerArray",
                          "id name barcode { id barcodeString } updatedAt"
                        ],
                        tableParamOptions: {
                          additionalFilter: plateLibraryFilter
                        },
                        additionalDataFragment: worklistPlanningDestinationPlateFragment,
                        buttonProps: {
                          loading: isLoadingMap.containerArrays,
                          disabled: isDisabledMap.containerArrays
                        },
                        onClear: () => {
                          change("activePlate", null);
                          change("plateToReactionMap", {});
                        },
                        onSelect: (newPlates = []) => {
                          const newPlateIds = newPlates.map(p => p.id);
                          if (
                            activePlate &&
                            !newPlateIds.includes(activePlate.id)
                          ) {
                            change("activePlate", newPlates[0]);
                          }
                          const newPlateToReactionMap = {
                            ...plateToReactionMap
                          };
                          Object.keys(newPlateToReactionMap).forEach(
                            plateId => {
                              if (!newPlateIds.includes(plateId)) {
                                delete newPlateToReactionMap[plateId];
                              }
                            }
                          );
                          change("plateToReactionMap", newPlateToReactionMap);
                        }
                      }}
                    />
                  </React.Fragment>
                )}
                {destinationType === "New Plates" && (
                  <React.Fragment>
                    <div style={{ maxWidth: 350, flex: 1 }}>
                      <PlateUploadFields
                        inTool
                        noFileUpload
                        noNumTubes
                        noTubeBarcodeOption
                        genericSelectOptions={{
                          name: "newPlateType",
                          label: "New Plate Type",
                          additionalDataFragment: worklistPlanningContainerArrayTypeFragment,
                          tableParamOptions: {
                            additionalFilter: newPlateTypeFilter
                          },
                          onFieldSubmit: containerArrayType => {
                            const newPlate = this.generateNewPlate(
                              containerArrayType
                            );
                            change("destinationPlates", [newPlate]);
                            change("activePlate", newPlate);
                            this.clearAllPlate();
                          }
                        }}
                      />
                      <InputField
                        name={fieldConstants.plateBaseName}
                        label="Plate Base Name"
                        generateDefaultValue={{
                          ...defaultValueConstants.PLATE_BASE_NAME
                        }}
                        onFieldSubmit={baseName => {
                          if (baseName) {
                            change(
                              "destinationPlates",
                              destinationPlates.map((destP, i) => {
                                if (destP.id.startsWith("__")) {
                                  return {
                                    ...destP,
                                    name: baseName + ` ${i + 1}`
                                  };
                                }
                                return destP;
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
              {!!destinationPlates.length && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 15,
                    justifyContent: "flex-end"
                  }}
                >
                  <div style={{ maxWidth: 250 }}>
                    <ReactSelectField
                      className="tg-no-form-group-margin"
                      name="activePlate"
                      defaultValue={destinationPlates[0]}
                      enableReinitialize
                      options={destinationPlates.map(p => ({
                        label: p.name,
                        value: p
                      }))}
                    />
                  </div>
                  {destinationType === "New Plates" && (
                    <ButtonGroup style={{ marginLeft: 8 }}>
                      <Button
                        icon="add"
                        intent="success"
                        disabled={!newPlateType}
                        onClick={() => {
                          const newPlate = {
                            id: "__" + shortid(),
                            name:
                              (plateBaseName || "Destination Plate") +
                              ` ${destinationPlates.length + 1}`,
                            containerArrayType: newPlateType,
                            aliquotContainers: generateEmptyWells(
                              newPlateType.containerFormat,
                              {
                                aliquotContainerTypeCode:
                                  newPlateType.aliquotContainerTypeCode
                              }
                            )
                          };
                          change("destinationPlates", [
                            ...destinationPlates,
                            newPlate
                          ]);
                          change("activePlate", newPlate);
                        }}
                      />
                      <Button
                        icon="trash"
                        intent="danger"
                        disabled={!destinationPlates.length || !activePlate}
                        onClick={() => {
                          let newDest = destinationPlates.filter(
                            p => p !== activePlate
                          );
                          newDest = newDest.map((p, i) => {
                            return {
                              ...p,
                              name:
                                (plateBaseName || "Destination Plate") +
                                ` ${i + 1}`
                            };
                          });
                          change("destinationPlates", newDest);
                          change("activePlate", newDest[0]);
                        }}
                      />
                    </ButtonGroup>
                  )}
                </div>
              )}
              {activePlate && !!destinationPlates.length && (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: 10,
                      marginLeft: 15
                    }}
                  >
                    <Button onClick={this.clearPlate} intent="warning">
                      Clear Plate
                    </Button>
                    <Button
                      style={{ marginLeft: 5 }}
                      onClick={this.clearAllPlate}
                      intent="danger"
                    >
                      Clear All Plates
                    </Button>
                  </div>
                  <PlateMapPlateNoContext
                    isEditable
                    activeFilledWells={activeFilledWells}
                    selectedAliquotContainerLocations={
                      selectedAliquotContainerLocations
                    }
                    setSelectedAliquotContainerLocation={
                      setSelectedAliquotContainerLocation
                    }
                    aliquotContainers={activePlate.aliquotContainers}
                    containerArrayType={activePlate.containerArrayType}
                    onDrop={this.handleWellOnWellDrop}
                    onSourceMaterialDrop={this.handleSourceMaterialDrop}
                    aliquotContextMenu={this.aliquotContextMenu}
                    aliquotTooltip={(ac, location) => {
                      const reactionIds = activeFilledWells[location];
                      if (reactionIds) {
                        if (
                          reactionIds.length === 1 &&
                          keyedReactions[reactionIds[0]]
                        ) {
                          const reaction = keyedReactions[reactionIds[0]];
                          return `Input: ${getReactionInputs(
                            reaction
                          )}\nOutput: ${getReactionOutputs(reaction)}`;
                        } else {
                          return `${reactionIds.length} reactions. Select to view.`;
                        }
                      }
                    }}
                    canDrag={this.canDrag}
                  />

                  {!!activeReactionsForWell.length && (
                    <div style={{ marginTop: 15 }}>
                      <DataTable
                        isSimple
                        className="tg-active-reactions-table"
                        formName="activeReactionsForSelectedWell"
                        entities={activeReactionsForWell.map(
                          reactionId => keyedReactions[reactionId]
                        )}
                        schema={reactionInputOutputColumns}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
        <CustomMaterialDragLayer />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setSelectedAliquotContainerLocation:
    actions.ui.records.containerArray.setSelectedAliquotContainerLocation,
  setSelectedAliquotContainerLocations:
    actions.ui.records.containerArray.setSelectedAliquotContainerLocations
};

const mapStateToProps = state => {
  return {
    selectedAliquotContainerLocations: containerArraySelectedAliquotContainerLocationsSelector(
      state
    )
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  stepFormValues(
    "destinationType",
    "destinationPlates",
    "reactionMaps",
    "activePlate",
    "newPlateType",
    "plateToReactionMap",
    "fillDirection",
    fieldConstants.plateBaseName,
    fieldConstants.enableMultipleReactions,
    fieldConstants.numberOfReactionsPerWell,
    "plateMapGroup"
  ),
  withSelectedEntities("worklistPlanningReactionMapTable"),
  withSelectTableRecords("worklistPlanningReactionMapTable")
)(SelectPlates);

function getReactionInputs(reaction) {
  const inputs = reaction.reactionInputs
    .map(input => {
      const name =
        get(input, "inputMaterial.name") ||
        get(input, "inputAdditiveMaterial.name");
      return name;
    })
    .join(", ");
  return inputs;
}

function getReactionOutputs(reaction) {
  const outputs = reaction.reactionOutputs
    .map(output => {
      const name =
        get(output, "outputMaterial.name") ||
        get(output, "outputAdditiveMaterial.name");
      return name;
    })
    .join(", ");
  return outputs;
}
