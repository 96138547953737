/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function getBuildableConstructIds({ assemblyPieceIds, j5Reports }) {
  const buildableConstructIds = [];
  j5Reports.forEach(report => {
    report.j5RunConstructs.forEach(construct => {
      const isBuildable = construct.j5ConstructAssemblyPieces.every(
        ({ assemblyPieceId }) => assemblyPieceIds.includes(assemblyPieceId)
      );
      if (isBuildable) {
        buildableConstructIds.push(construct.id);
      }
    });
  });
  return buildableConstructIds;
}

export function j5ReportLinkedToMaterials(report) {
  return report.j5RunConstructs.every(c => {
    return (
      get(c, "sequence.polynucleotideMaterialId") &&
      c.j5ConstructAssemblyPieces.every(j5a =>
        get(j5a, "assemblyPiece.sequence.polynucleotideMaterialId")
      )
    );
  });
}

export function j5ReportsFullyLinked(reports) {
  return reports.every(j5ReportLinkedToMaterials);
}

export function addInInventoryFilter(qb, currentParams) {
  if (currentParams.inventoryFilter === "In Inventory") {
    qb.whereAll({
      containerCount: qb.greaterThan(0)
    });
  } else if (currentParams.inventoryFilter === "Not in Inventory") {
    qb.whereAll({
      containerCount: qb.lessThan(1)
    });
  }
}
