/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment assemblyBatchLibraryFragment on assemblyBatch {
    id
    design {
      id
      name
    }
    j5Reports {
      id
      name
      originalDesignId
      j5LogMessages {
        id
        message
        j5LogMessageTypeCode
        j5LogMessagePriorityCode
      }
    }
  }
`;
