/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, groupBy } from "lodash";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "./caseInsensitiveFilter";

export default async function getNameKeyedItems(
  {
    names = [],
    model: _model,
    fragment,
    additionalFilter,
    group,
    key = "name"
  },
  ctx = isoContext
) {
  if (!names.length) return {};
  const model = _model || getModelNameFromFragment(fragment);
  const items = await ctx.safeQuery(fragment || [model, `id ${key}`], {
    variables: {
      filter: caseInsensitiveFilter(model, key, names, {
        additionalFilter
      })
    }
  });
  const fn = group ? groupBy : keyBy;
  return fn(items, item => item[key].toLowerCase());
}
