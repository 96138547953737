/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";
import {
  NumericInputField,
  RadioGroupField,
  tgFormValues,
  showConfirmationDialog,
  InputField,
  TextareaField,
  ReactSelectField,
  CheckboxField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { noop, get } from "lodash";
import withQuery from "../../../../src-shared/withQuery";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

import "./style.css";
import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";
import GenericSelect from "../../../../src-shared/GenericSelect";

import {
  arrayToIdOrCodeValuedOptions,
  notLessThan
} from "../../../../src-shared/utils/formUtils";

import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";

const CreateNewAdditiveMaterialDialog = ({
  hideModal,
  history,
  initialGrowthMediaSelectionMethods = [],
  refetch = noop,
  initialValues = {},
  handleSubmit,
  submitting,
  additiveTypes = [],
  additiveTypeCode,
  isDryLocked,
  asyncValidating,
  enzymes = []
}) => {
  const onSubmit = async values => {
    const {
      id: updateId,
      name,
      description,
      targetOrganismClass,
      additiveTypeCode,
      isDry,
      evaporable,
      molecularWeight,
      selectionMethods = []
    } = values;
    let { enzymeId } = values;
    try {
      if (additiveTypeCode !== "ENZYME") {
        enzymeId = null;
      }
      if (!initialValues.enzymeId) {
        initialValues.enzymeId = null;
      }
      if (enzymeId !== initialValues.enzymeId) {
        const [newEnzyme] = enzymes.filter(e => e.id === enzymeId);
        const [oldEnzyme] = enzymes.filter(
          e => e.id === initialValues.enzymeId
        );
        let text = [];
        if (enzymeId) {
          text.push(`link enzyme ${newEnzyme.name}`);
        }
        if (oldEnzyme) {
          text.push(`unlink enzyme ${oldEnzyme.name}`);
        }
        text = "Are you sure you want to " + text.join(" and ") + "?";
        const continueCreate = await showConfirmationDialog({
          text,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        });
        if (!continueCreate) {
          return;
        }
      }
      if (updateId) {
        if (molecularWeight !== initialValues.molecularWeight) {
          const lots = await safeQuery(["lot", "id"], {
            variables: { filter: { additiveMaterialId: updateId }, pageSize: 1 }
          });
          if (lots.length > 0) {
            return window.toastr.error(
              `Cannot change molecular weight of reagent with associated lots.`
            );
          }
        }
      }
      const [{ id }] = await safeUpsert("additiveMaterial", {
        id: updateId,
        name,
        description,
        isDry,
        evaporable: evaporable === "evaporable",
        molecularWeight,
        targetOrganismClassId: get(targetOrganismClass, "id"),
        additiveTypeCode,
        enzymeId: enzymeId || null
      });
      await safeDelete(
        "growthMediaSelectionMethod",
        initialGrowthMediaSelectionMethods.map(g => g.id)
      );
      if (additiveTypeCode === "GROWTH_MEDIA" && selectionMethods.length) {
        await safeUpsert(
          "growthMediaSelectionMethod",
          selectionMethods.map(sm => ({
            selectionMethodId: sm.id,
            growthMediaId: id
          }))
        );
      }
      await refetch();
      hideModal();
      !updateId && history.push(`/reagents/${id}`);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(
        `Error ${updateId ? "updating" : "creating"} reagent`
      );
    }
  };

  const additiveTypeOptions = arrayToIdOrCodeValuedOptions(additiveTypes);
  const casEnzymeOptions = arrayToIdOrCodeValuedOptions(enzymes);

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          label="Name"
          name="name"
          isRequired
          placeholder="Enter the name of the reagent."
          rightElement={
            <AsyncValidateFieldSpinner validating={asyncValidating} />
          }
        />
        <TextareaField
          label="Description"
          name="description"
          placeholder="Enter any additional information."
        />
        <NumericInputField
          name="molecularWeight"
          label="Molecular Weight (g/mol)"
          min={1}
          normalize={notLessThan(0, { integer: false })}
          tooltipInfo="Optional: used for calculating molarity of reagent lots"
        />
        <ReactSelectField
          label="Reagent Type"
          name="additiveTypeCode"
          isRequired
          placeholder="Choose a reagent type."
          options={additiveTypeOptions}
        />
        {additiveTypeCode === "ENZYME" && (
          <ReactSelectField
            label="Cas Enzyme"
            name="enzymeId"
            placeholder="Choose a cas enzyme (optional)."
            options={casEnzymeOptions}
          />
        )}
        {!isDryLocked && <CheckboxField name="isDry" label="Dry Reagent" />}
        <RadioGroupField
          options={[
            {
              label:
                "This reagent is evaporable, and will be removed from the well or tube after dehydration and rehydration.",
              value: "evaporable"
            },
            {
              label:
                "This reagent is not evaporable, and will remain in the well or tube after dehydration and rehydration.",
              value: "notEvaporable"
            }
          ]}
          isRequired
          name="evaporable"
          label="Evaporable"
        />
        {additiveTypeCode === "GROWTH_MEDIA" && (
          <React.Fragment>
            <GenericSelect
              {...{
                name: "selectionMethods",
                asReactSelect: true,
                isMultiSelect: true,
                label: "Selection Method",
                fragment: ["selectionMethod", "id name"]
              }}
            />
            <GenericSelect
              {...{
                name: "targetOrganismClass",
                asReactSelect: true,
                label: "Target Organism Group",
                fragment: ["targetOrganismClass", "id name"]
              }}
            />
          </React.Fragment>
        )}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
};

export default compose(
  wrapDialog({
    title: "Create New Reagent"
  }),
  withProps(() => ({ model: "additiveMaterial" })),
  reduxForm({
    form: "createNewAdditiveMaterial",
    ...asyncValidateName
  }),
  tgFormValues("additiveTypeCode"),
  withQuery(["additiveType", "code name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["enzyme", "id name"], {
    isPlural: true,
    options: {
      variables: {
        filter: {
          reagentEnzymeTypeCode: "CAS"
        }
      }
    },
    showLoading: true,
    inDialog: true
  })
)(CreateNewAdditiveMaterialDialog);
