/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get, map, uniq, isEmpty } from "lodash";
import {
  FileUploadField,
  Loading,
  DataTable,
  BlueprintError,
  SwitchField,
  ReactSelectField,
  IntentText
} from "@teselagen/ui";
import { compose } from "recompose";
import { Button, Tooltip, Icon } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  getDuplicateBarcodeHelper,
  dataTableHeaderMap
} from "../../../../utils";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { allowedCsvFileTypes } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { formatDateTime } from "../../../../../src-shared/utils/dateUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getPlateLocationMap,
  getPositionFromAlphanumericLocation,
  wellInBounds
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";

const fields = dataTableHeaderMap["COLONY_PICKING_FEEDBACK"];

const headerToColumnKey = {};
fields.forEach(field => {
  headerToColumnKey[field.path] = field.path;
});

const tableHeaders = Object.keys(headerToColumnKey);

const tableFilter = (props, qb) => {
  qb.whereAll({
    dataTableTypeCode: "COLONY_PICKING_FEEDBACK"
  });
};

class UploadFeedbackData extends Component {
  state = {
    loading: false
  };

  parseSchemaFile = async (files, onChange) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const schemaFiles = files.map(file => ({ ...file, loading: false }));
    try {
      this.loadedFullData = false;
      const cleanedData = [];
      let hasExtProp = false;
      const allFields = [];
      for (const file of schemaFiles) {
        const {
          parsedData,
          meta: { fields }
        } = file;
        fields.forEach(f => {
          if (!allFields.includes(f)) {
            allFields.push(f);
          }
        });

        for (const row of parsedData) {
          const cleanedRow = {};
          for (const key of Object.keys(row)) {
            const newKey = headerToColumnKey[key];
            if (newKey) {
              cleanedRow[newKey] = row[key];
            } else if (key.startsWith("ext-")) {
              cleanedRow[key] = row[key];
              hasExtProp = true;
            }
          }
          if (!isEmpty(cleanedRow)) {
            cleanedData.push({ ...cleanedRow, id: cleanedData.length + 1 });
          }
        }
      }

      change("partialFeedbackData", cleanedData);
      change("hasExtProp", hasExtProp);
      change("allCsvFields", allFields);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(error.message || "Error parsing file");
    }
    onChange(schemaFiles);
    // so that file is not uploaded
    return false;
  };

  async componentDidUpdate() {
    const {
      partialFeedbackData,
      feedbackDataFile,
      stepFormProps: { change }
    } = this.props;
    const hasFileError =
      feedbackDataFile && feedbackDataFile.some(f => f.error);

    if (partialFeedbackData && !this.loadedFullData && !hasFileError) {
      this.loadedFullData = true;
      this.setState({
        loading: true
      });
      try {
        let plateBarcodes = [];
        partialFeedbackData.forEach(data => {
          if (data.sourcePlateBarcode) {
            plateBarcodes.push(data.sourcePlateBarcode);
          }
          if (data.destinationPlateBarcode) {
            plateBarcodes.push(data.destinationPlateBarcode);
          }
        });
        plateBarcodes = uniq(plateBarcodes);
        const plates = await safeQuery(
          [
            "containerArray",
            `id
            name
            containerArrayType {
              id
              name
              isPlate
              containerFormat {
                code
                rowCount
                columnCount
              }
            }
            barcode { id barcodeString }
            aliquotContainers {
              id
              rowPosition
              columnPosition
              aliquot {
                id
                sample {
                  id
                  name
                  sampleTypeCode
                  materialId
                  sampleFormulations {
                    id
                    materialCompositions {
                      id
                      material {
                        id
                        materialTypeCode
                      }
                    }
                  }
                  sampleProteinPatterns {
                    id
                    proteinPatternId
                  }
                }
                isDry
              }
            }`
          ],
          {
            variables: {
              filter: {
                "barcode.barcodeString": plateBarcodes
              }
            }
          }
        );
        change("fetchedPlates", plates);
        const duplicatePlates = getDuplicateBarcodeHelper(plates);
        change("duplicatePlates", duplicatePlates);
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error validating feedback data");
      }
      this.setState({
        loading: false
      });
    }
  }

  getFullFeedbackData = (plates, callChange) => {
    const {
      partialFeedbackData: _partialFeedbackData,
      feedbackDataFile,
      stepFormProps: { change },
      duplicatePlates = {},
      resolvedPlates = {}
    } = this.props;

    const hasFileError =
      feedbackDataFile && feedbackDataFile.some(f => f.error);
    if (hasFileError) return [];

    const partialFeedbackData = _partialFeedbackData || [];

    const keyedPlates = {};
    plates.forEach(plate => {
      const barcode = plate.barcode.barcodeString;
      if (resolvedPlates[barcode]) {
        keyedPlates[barcode] = resolvedPlates[barcode];
      } else {
        keyedPlates[barcode] = plate;
      }
    });

    const plateBarcodeAcHelper = {};
    Object.values(keyedPlates).forEach(p => {
      plateBarcodeAcHelper[p.barcode.barcodeString] = getPlateLocationMap(p);
    });

    const sampleNameToProteinPattern = {};
    const mappedDestinationsForExistingPlates = {};
    const newFeedbackData = partialFeedbackData.map(record => {
      let error;
      let warning;
      const newRecord = { ...record };

      if (newRecord.date) {
        let dateToUse = newRecord.date;
        if (newRecord.time) {
          dateToUse += ` ${newRecord.time}`;
        }
        dateToUse = new Date(dateToUse);
        if (isNaN(dateToUse)) {
          error = "Error parsing date.";
        } else {
          newRecord.cleanedDate = dateToUse;
        }
      }

      if (!sampleNameToProteinPattern[record.pooledSampleName]) {
        sampleNameToProteinPattern[record.pooledSampleName] =
          record.proteinPattern;
      }

      if (!record.destinationPlateBarcode) {
        error = "Please add a destination plate barcode.";
      } else if (!record.destinationWell) {
        error = "Please add a destination well.";
      } else if (
        duplicatePlates[record.destinationPlateBarcode] &&
        !resolvedPlates[record.destinationPlateBarcode]
      ) {
        error =
          "This destination barcode in this row matches multiple plates in inventory. Please resolve.";
      } else if (
        duplicatePlates[record.sourcePlateBarcode] &&
        !resolvedPlates[record.sourcePlateBarcode]
      ) {
        error =
          "This source barcode in this row matches multiple plates in inventory. Please resolve.";
      } else if (!keyedPlates[record.sourcePlateBarcode]) {
        error = "The source plate in this row was not found in inventory.";
      } else {
        const sourcePlate = keyedPlates[record.sourcePlateBarcode];

        const destinationPlate = keyedPlates[record.destinationPlateBarcode];
        let destinationAliquotContainer;
        if (destinationPlate) {
          const cleanedDestinationLocation =
            getPositionFromAlphanumericLocation(
              record.destinationWell,
              destinationPlate.containerArrayType.containerFormat
            );
          const cleanedDestinationLocationString = getAliquotContainerLocation(
            cleanedDestinationLocation,
            {
              force2D: true
            }
          );

          if (
            !mappedDestinationsForExistingPlates[record.destinationPlateBarcode]
          ) {
            mappedDestinationsForExistingPlates[
              record.destinationPlateBarcode
            ] = {};
          }

          if (
            mappedDestinationsForExistingPlates[record.destinationPlateBarcode][
              cleanedDestinationLocationString
            ]
          ) {
            error =
              "This rows destination well location is already used in a different row. Duplicate destination wells are not allowed.";
          }
          mappedDestinationsForExistingPlates[record.destinationPlateBarcode][
            cleanedDestinationLocationString
          ] = true;

          destinationAliquotContainer = get(
            plateBarcodeAcHelper,
            `${record.destinationPlateBarcode}.${cleanedDestinationLocationString}`
          );
          newRecord.destinationAliquotContainer = destinationAliquotContainer;
        }

        const cleanedLocation =
          record.sourceLocation &&
          getPositionFromAlphanumericLocation(
            record.sourceLocation,
            sourcePlate.containerArrayType.containerFormat
          );

        const aliquotContainer =
          plateBarcodeAcHelper[record.sourcePlateBarcode][
            getAliquotContainerLocation(cleanedLocation)
          ];
        const aliquot = aliquotContainer && aliquotContainer.aliquot;
        newRecord.sourceAliquotContainer = aliquotContainer;
        if (!record.sourceLocation || !cleanedLocation) {
          error = "Please provide a valid source location.";
        } else if (
          !wellInBounds(
            cleanedLocation,
            sourcePlate.containerArrayType.containerFormat
          )
        ) {
          error = "This well does not fit the source plate format.";
        } else if (
          destinationPlate &&
          !wellInBounds(
            record.destinationWell,
            destinationPlate.containerArrayType.containerFormat
          )
        ) {
          error = "This well does not fit the destination plate format.";
        } else if (!aliquot) {
          error =
            "The existing source plate well of this row does not have an aliquot.";
        } else if (aliquot.isDry) {
          error =
            "The existing source plate well of this row has a dry aliquot.";
        } else if (!aliquot.sample) {
          error =
            "The existing source plate well of this row does not have an sample.";
        }
        // tgreen: no longer requring formulated
        // else if (aliquot.sample.sampleTypeCode !== "FORMULATED_SAMPLE") {
        //   error =
        //     "The existing sample of this row does not have a formulated sample.";
        // }
        else if (
          destinationAliquotContainer &&
          destinationAliquotContainer.aliquot
        ) {
          error =
            "The destination plate well of this row already has an aliquot.";
        } else if (destinationPlate && !destinationAliquotContainer) {
          error =
            "The destination location of this row is invalid. Please make sure it is formatted correctly and fits on the destination plate.";
        } else if (!record.destinationPlateBarcode) {
          error = "No destination plate barcode provided for this row.";
        }
      }

      if (keyedPlates[record.destinationPlateBarcode]) {
        newRecord.destinationPlateInInventory = true;
        newRecord.destinationPlate =
          keyedPlates[record.destinationPlateBarcode];
      }

      if (error) {
        newRecord.error = error;
      } else if (warning) {
        newRecord.warning = warning;
      }

      return newRecord;
    });

    if (callChange) {
      change("feedbackData", newFeedbackData);
      change("keyedPlates", keyedPlates);
      change("plateBarcodeAcHelper", plateBarcodeAcHelper);
    }
    return newFeedbackData;
  };

  onSelectTables = dataTables => {
    const {
      stepFormProps: { change }
    } = this.props;
    const newFeedbackData = [];
    dataTables.forEach((dataTable, i) => {
      dataTable.dataRows.forEach((r, j) => {
        newFeedbackData.push({
          ...r.rowValues,
          id: `${i}${j}`
        });
      });
    });
    change("partialFeedbackData", newFeedbackData);
  };

  clearFeedbackData = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    this.loadedFullData = false;
    change("feedbackDataFile", []);
    change("resolvedPlates", {});
    change("duplicatePlates", {});
    change("feedbackData", null);
    change("partialFeedbackData", null);
    change("feedbackDataTable", null);
  };

  updateData = () => {
    const { nextStep, fetchedPlates = [] } = this.props;
    this.getFullFeedbackData(fetchedPlates, true);
    nextStep();
  };

  renderDuplicateChoices = () => {
    const { duplicatePlates = {} } = this.props;

    if (isEmpty(duplicatePlates)) return null;

    return (
      <div className="tg-flex justify-space-between" style={{ marginTop: 15 }}>
        <HeaderWithHelper
          header="Resolve Duplicates Plates"
          helper="The following barcodes matched more than one plates in inventory. Please choose the one you would like to use."
        />
        <div>
          {map(duplicatePlates, (options, barcode) => {
            return (
              <ReactSelectField
                key={barcode}
                options={arrayToItemValuedOptions(options)}
                name={"resolvedPlates." + barcode}
                label={barcode}
              />
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const {
      Footer,
      isUpload,
      footerProps,
      fetchedPlates = [],
      handleSubmit,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      hasExtProp,
      allCsvFields
    } = this.props;
    const feedbackData = this.getFullFeedbackData(fetchedPlates);
    const hasFeedbackError = feedbackData.some(r => r.error);
    const hasFeedbackErrorWarning =
      !hasFeedbackError && feedbackData.some(r => r.warning);

    const hasExistingPlate = feedbackData.some(
      r => r.destinationPlateInInventory
    );
    let clearButton, table, uploadOptions;
    if (!!feedbackData.length && !this.state.loading) {
      clearButton = (
        <div
          style={{
            marginBottom: 10,
            marginRight: 20,
            alignSelf: "flex-end"
          }}
        >
          <Button
            onClick={this.clearFeedbackData}
            intent="danger"
            text="Clear Table"
          />
        </div>
      );

      const schema = [
        {
          type: "action",
          width: 35,
          render: (_, record) => {
            if (record.error || record.warning) {
              return (
                <Tooltip content={record.error || record.warning}>
                  <Icon
                    intent={record.error ? "danger" : "warning"}
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
          }
        },
        {
          path: "destinationPlateBarcode",
          render: (v, r) => {
            if (r.destinationPlateInInventory) {
              return `${v} (*)`;
            } else {
              return v;
            }
          }
        },
        {
          path: "destinationWell",
          displayName: "Destination Well",
          render: v => v || "N/A"
        },
        "sourcePlateBarcode",
        {
          path: "sourceLocation",
          displayName: "Source Location",
          render: v => v || "N/A"
        },
        {
          displayName: "X-Coordinate",
          path: "xCoordinate"
        },
        {
          displayName: "Y-Coordinate",
          path: "yCoordinate"
        },
        "diameter",
        {
          displayName: "Date",
          render: (v, r) =>
            r.cleanedDate ? formatDateTime(r.cleanedDate) : "N/A"
        }
      ];
      if (hasExtProp) {
        allCsvFields.forEach(f => {
          if (f.startsWith("ext-")) {
            schema.push({
              displayName: f,
              path: f
            });
          }
        });
      }
      table = (
        <>
          <DataTable
            isSimple
            formName="feedbackDataDisplayTable"
            maxHeight={300}
            noSelect
            schema={schema}
            entities={feedbackData}
          />
          {hasExistingPlate && (
            <div style={{ margin: 5 }}>(*) Existing plate(s) in inventory</div>
          )}
          {hasFeedbackError && (
            <BlueprintError error="Please fix errors in feedback data." />
          )}
          {hasFeedbackErrorWarning && (
            <IntentText intent="warning">
              Please review warnings before continuing with tool.
            </IntentText>
          )}
        </>
      );
    }

    if (!feedbackData.length && !this.state.loading) {
      uploadOptions = (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
          }}
        >
          <SwitchField name="isUpload" label="Upload Feedback Data" />
          <div className="tg-flex align-center">
            {isUpload ? (
              <div>
                <FileUploadField
                  accept={getDownloadTemplateFileHelpers({
                    type: allowedCsvFileTypes,
                    fileName: "feedback_data",
                    headers: tableHeaders,
                    extendedPropTypes: ["isolation event"],
                    headerMessages:
                      dataTableHeaderMap["COLONY_PICKING_FEEDBACK"],
                    validateAgainstSchema: {
                      fields
                    }
                  })}
                  name="feedbackDataFile"
                  beforeUpload={this.parseSchemaFile}
                />
              </div>
            ) : (
              <GenericSelect
                {...{
                  name: "feedbackDataTable", //the field name within the redux form Field
                  tableParamOptions: {
                    additionalFilter: tableFilter
                  },
                  buttonProps: {
                    disabled: isDisabledMap.dataTables,
                    loading: isLoadingMap.dataTables
                  },
                  isMultiSelect: true,
                  onSelect: this.onSelectTables,
                  schema: ["name", dateModifiedColumn],
                  fragment: ["dataTable", "id name updatedAt"],
                  additionalDataFragment: [
                    "dataTable",
                    `id
                    name
                    dataRows {
                      id
                      index
                      key
                      rowValues
                    }`
                  ]
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Feedback Data"
              helper="Select or upload colony feedback data specifying how colonies are picked from source to destination plates."
            />
            {clearButton}
            {uploadOptions}
          </div>
          {table}
          {this.renderDuplicateChoices()}
          {this.state.loading && <Loading bounce />}
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent="primary"
              disabled={!feedbackData.length || hasFeedbackError}
              onClick={handleSubmit(this.updateData)}
            >
              Next
            </Button>
          }
        />
      </>
    );
  }
}

export default compose(
  stepFormValues(
    "feedbackDataFile",
    "feedbackDataTable",
    "isUpload",
    "fetchedPlates",
    "partialFeedbackData",
    "duplicatePlates",
    "resolvedPlates",
    "hasExtProp",
    "allCsvFields"
  )
)(UploadFeedbackData);
