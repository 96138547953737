/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  InputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class CreateSpeciesDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop, selectedGenus = {} } = this.props;
    const { id: updatedId, name, description, abbreviatedName } = values;
    const genusId = selectedGenus.id;
    try {
      await safeUpsert(
        ["specie", "id name description abbreviatedName genusId"],
        {
          id: updatedId,
          name,
          description,
          abbreviatedName,
          genusId
        }
      );
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating specie.");
    }
  };

  render() {
    const {
      hideModal,
      submitting,
      handleSubmit,
      selectedGenus = {}
    } = this.props;
    let genusAbbreviation;
    if (selectedGenus.abbreviatedName) {
      genusAbbreviation = selectedGenus.abbreviatedName;
    } else {
      genusAbbreviation = selectedGenus.name.charAt(0).toUpperCase() + ".";
    }
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            placeholder="Enter species name..."
          />
          <InputField
            name="abbreviatedName"
            label="Abbreviated Name"
            isRequired
            defaultValue={genusAbbreviation}
          />
          <TextareaField
            name="description"
            label="Description"
            placeholder="Enter species description..."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreateSpeciesWithDialog = compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Species"
          : "Create Species"
      };
    }
  }),
  reduxForm({
    form: "createSpecies"
  }),
  withQuery(["specie", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(CreateSpeciesDialog);

export default CreateSpeciesWithDialog;
