/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { connect } from "react-redux";
import { compose } from "redux";
import { keyBy } from "lodash";
import { change } from "redux-form";
import AddEugeneRuleDialog from "../components/Dialogs/AddEugeneRuleDialog";
import {
  getSelectedElement,
  getSelectedCardId
} from "../../src-shared/selectors/designViewSelectors";
import compositionalOperators from "../../../tg-iso-design/constants/compositionalOperators";
import {
  getInputReactionIdOfCard,
  getAllElementsInInputCards,
  getEugeneRulesOfReaction,
  getInputCardsOfReaction,
  getElementsInBin,
  getLevelOfCard,
  getBinIdsInCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../src-shared/redux/actions";
import { isViewClassic } from "../../src-shared/selectors/classicViewSelectors";

const mapStateToProps = (state, { initialValues = {}, cardId }) => {
  const selectedCardId = cardId || getSelectedCardId(state);
  const selectedElement = getSelectedElement(state);
  const reactionId = getInputReactionIdOfCard(state, selectedCardId);
  const possibleOperands = getAllElementsInInputCards(state, reactionId) || [];
  const existingNamesMap = keyBy(
    getEugeneRulesOfReaction(state, reactionId),
    "name"
  );
  const inputs = getInputCardsOfReaction(state, reactionId);
  const operand1Inputs = getBinIdsInCard(state, selectedCardId).map(setId =>
    getElementsInBin(state, setId)
  );
  const allOperand2Inputs = inputs
    .filter(c => c.id !== selectedCardId)
    .map(c => ({
      id: c.id,
      name: c.name
        ? `${getLevelOfCard(state, c.id)} - ${c.name}`
        : getLevelOfCard(state, c.id),
      values: getBinIdsInCard(state, c.id).map(setId =>
        getElementsInBin(state, setId)
      )
    }));

  return {
    operand1Inputs,
    selectedCardId,
    selectedElement,
    possibleOperands,
    operand1: initialValues.operand1 || selectedElement,
    reactionId,
    existingNamesMap,
    initialValues: {
      operand1: initialValues.operand1 || selectedElement,
      compositionalOperator: compositionalOperators[0],
      ...initialValues
    },
    isClassicView: !!isViewClassic(state),
    allOperand2Inputs
  };
};

const mapDispatchToProps = {
  upsertEugeneRule: actions.design.upsertEugeneRule,
  changeFormValue: (...args) => change("addEugeneRuleDialogForm", ...args)
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddEugeneRuleDialog
);
