/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getApolloMethods } from "@teselagen/apollo-methods";
import { findIndex, get, size, uniqWith, isEmpty } from "lodash";
import { flow, getParent, types } from "mobx-state-tree";
import client from "../../../../src-shared/apolloClient";
import { modelMap } from "../utils";
import { runInAction } from "mobx";
import dataMapperFragment from "../../../utils/dataMapperUtils/dataMapperFragment";
import { difference } from "lodash";

const { safeQuery: query, safeUpsert: upsert, safeDelete } = getApolloMethods(
  client
);

const datasetStore = types
  .model("datasetStore", {
    model: types.optional(types.string, ""),
    query: types.optional(types.string, ""),
    queryOptions: types.optional(types.frozen(), {}),
    data: types.optional(types.array(types.frozen()), []),
    noFetch: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false)
  })
  .actions(self => {
    const fetch = flow(function*() {
      try {
        if (self.model === "choose an option") return;
        if (self.model && self.query && !self.noFetch) {
          self.loading = true;
          const modelToUse = modelMap[self.model] || self.model;
          const data = yield query([modelToUse, self.query], self.queryOptions);

          data.length > 0
            ? (self.data = data.map(row => {
                if (
                  modelToUse === "referenceDimension" ||
                  modelToUse === "measurementType"
                ) {
                  return {
                    value: row.id,
                    unitDimensionId: row.unitDimensionId,
                    label: row.name
                  };
                }
                if (self.model === "unit") {
                  return {
                    value: row.id,
                    unitDimensionId: row.unitScale.unitDimensionId,
                    label: row.name
                  };
                }
                return { value: row.id, label: row.name };
              }))
            : (self.data = [
                { value: undefined, label: "No Results...", disabled: true }
              ]);
          self.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
    });
    return {
      afterCreate() {
        fetch();
      }
    };
  });

const fieldStore = types
  .model("fieldStore", {
    key: types.optional(types.string, ""),
    path: types.optional(types.string, ""),
    label: types.optional(types.string, ""),
    value: types.union(types.string, types.boolean),
    type: types.optional(types.string, ""),
    dataset: types.optional(datasetStore, {}),
    required: types.optional(types.boolean, false),
    isHidden: types.optional(types.boolean, false),
    removable: types.optional(types.boolean, false)
  })
  .views(self => ({
    get getLabel() {
      const index = findIndex(
        self.dataset.data,
        opt => opt.value === self.value
      );
      if (index >= 0) {
        return self.dataset.data[index].label;
      } else {
        return "choose an option";
      }
    }
  }))
  .actions(self => ({
    handleValue(newValue) {
      if (self.type === "selectField") {
        return (self.value = newValue);
      }
      if (typeof self.value === "boolean") {
        self.value = !self.value;
      } else {
        self.value = newValue ? newValue : "";
      }
    },
    makeRequired() {
      self.required = true;
    },
    makeNotRequired() {
      self.required = false;
    },
    makeRemovable() {
      self.removable = true;
    },
    makeNotRemovable() {
      self.removable = false;
    },
    setDataset({ model, query, queryOptions, data, noFetch }) {
      self.dataset.model = model;
      self.dataset.query = query || "id name";
      self.dataset.queryOptions = queryOptions;
      self.dataset.data = data || [];
      self.dataset.noFetch = noFetch || false;
      self.dataset.afterCreate();
    },
    setValue(newValue) {
      self.value = newValue;
    },
    removeField() {
      getParent(self, 2).remove(self.key);
    }
  }));

export const addMetaDataStore = types
  .model("addMetaDataStore", {
    form: types.optional(types.map(fieldStore), {}),
    model: types.optional(types.string, ""),
    existingData: types.optional(types.union(types.frozen(), types.null)),
    sending: types.optional(types.boolean, false),
    fetching: types.optional(types.boolean, false),
    error: types.optional(types.string, "")
  })
  .actions(self => {
    const save = flow(function*(refetch) {
      try {
        if (self.model === "columnMap") {
          /** Column Map Schema
           *
           * columnName: "Test column"
           * classTypeId: "classId*"
           *  Just one subClass can be selected at a time
           * assaySubjectId?
           * descriptorType?
           * measurementType?
           * referenceDimension?
           *  if subClass require unit it must be specified
           * unitId?
           */
          self.sending = true;

          const columnName = self.form.get("columnName").value;
          const classTypeCode =
            modelMap[self.form.get("class").value] || undefined;
          const subClass = self.form.get("subClass").value;
          const unit = self.form.get("unit").value || undefined;

          const data = {
            ...(size(self.existingData) && { id: self.existingData.id }),
            ...(columnName !== self.existingData?.columnName && { columnName }),
            ...(classTypeCode !== self.existingData?.classType?.code && {
              assaySubjectClassId: null,
              descriptorTypeId: null,
              measurementTypeId: null,
              referenceDimensionId: null,
              unitId: null,
              classTypeCode: classTypeCode,
              [classTypeCode + "Id"]: subClass
            }),
            ...(subClass !== self.existingData?.[classTypeCode]?.id && {
              [classTypeCode + "Id"]: subClass
            }),
            ...(unit !== self.existingData?.unit?.id && {
              unitId: unit
            })
          };

          try {
            yield upsert(self.model, data);
            yield refetch();
          } catch (error) {
            if (error.graphQLErrors) {
              error.graphQLErrors.forEach(error => {
                const constraintName = error.extensions.exception.constraint;
                if (constraintName === "unique_column_map") {
                  throw new Error(
                    `Duplicated column mapper detected, it will not be saved.`
                  );
                }
                if (constraintName === "unique_name") {
                  throw new Error(
                    `Column mapper '${columnName}' already exists.`
                  );
                }
              });
            }
          }
          self.sending = false;
        } else if (self.model === "mappingPreset") {
          self.sending = true;
          const columnMapIds = [];
          const dataMapper = {
            ...(size(self.existingData) && { id: self.existingData.id })
          };

          for (const key of self.getKeys) {
            if (!key.includes("columnMap")) {
              dataMapper[key] = self.form.get(key).value;
            } else {
              columnMapIds.push(self.form.get(key).value);
            }
          }

          const [{ id: mappingPresetId }] = yield upsert(
            self.model,
            dataMapper
          );

          const existingCols = yield query(dataMapperFragment, {
            variables: { filter: { mappingPresetId } }
          });

          const existingColIds = existingCols.map(res => res.columnMap.id);

          if (size(columnMapIds) > size(existingColIds)) {
            // add new columns
            yield upsert(
              "mappingPresetColumnMaps",
              difference(columnMapIds, existingColIds).map(id => ({
                mappingPresetId,
                columnMapId: id
              }))
            );
          }

          if (size(columnMapIds) < size(existingColIds)) {
            // remove columns
            yield safeDelete(
              "mappingPresetColumnMaps",
              existingCols
                .filter(res =>
                  difference(existingColIds, columnMapIds).includes(
                    res.columnMap.id
                  )
                )
                .map(col => col.id)
            );
          }

          yield refetch();
          self.sending = false;
        } else if (size(self.existingData)) {
          self.sending = true;
          const data = {};
          self.getKeys
            .filter(key => self.form.get(key).value !== "")
            .map(key => (data[key] = self.form.get(key).value));
          data.id = self.existingData.id;
          yield upsert(self.model, data);
          yield refetch();
          self.sending = false;
        } else {
          self.sending = true;
          const data = {};
          self.getKeys
            .filter(key => self.form.get(key).value !== "")
            .map(key => (data[key] = self.form.get(key).value));
          yield upsert(self.model, data);
          yield refetch();
          self.sending = false;
        }
      } catch (error) {
        window.toastr.error(error.message);
      }
    });

    const afterCreate = flow(function*() {
      if (self.existingData) {
        for (const key of self.getKeys) {
          const keyType = `${key}Type`;
          const maybePath = self.form.get(key)?.path;
          const existingDataValue = get(self.existingData, maybePath || key);

          if (existingDataValue && typeof existingDataValue !== "object") {
            runInAction(() => {
              self.form.get(key).handleValue(existingDataValue);
            });
          } else if (self.model === "mappingPreset") {
            let columnMaps = [];

            if (self.existingData.header) {
              runInAction(() => {
                self.remove("columnMap");
              });
              return;
            }

            try {
              self.fetching = true;
              const mappingPresetColumnMaps = yield query(dataMapperFragment, {
                variables: { filter: { mappingPresetId: self.existingData.id } }
              });

              runInAction(() => {
                columnMaps = mappingPresetColumnMaps.map(col => col.columnMap);
              });

              if (size(columnMaps) > 0) {
                for (let idx = 0; idx < columnMaps.length; idx++) {
                  const colId = columnMaps[idx].id;
                  runInAction(() => {
                    if (idx === 0 && key === "columnMap") {
                      self.form.get(key).handleValue(colId);
                    } else if (key.includes("columnMap")) {
                      self.addField({
                        key: `columnMap${colId}`,
                        label: "Column Map",
                        type: "selectField",
                        value: colId,
                        dataset: {
                          model: "columnMap",
                          query: "id name:columnName"
                        }
                      });
                    }
                  });
                }
              }
            } catch (error) {
              console.error(error);
            } finally {
              self.fetching = false;
            }
          } else if (self.existingData[key.substring(0, key.length - 2)]) {
            runInAction(() => {
              self.form
                .get(key)
                .handleValue(
                  self.existingData[key.substring(0, key.length - 2)].id
                );
            });
          } else if (self.existingData[key.substring(0, key.length - 4)]) {
            runInAction(() => {
              self.form
                .get(key)
                .handleValue(
                  self.existingData[key.substring(0, key.length - 4)].code
                );
            });
          } else if (self.existingData[keyType]) {
            runInAction(() => {
              self.form.get(key).handleValue(self.existingData[keyType].name);
            });
          } else if (key === "subClass") {
            const classValue = self.existingData["classType"].code;
            runInAction(() => {
              self.form.get(key).handleValue(self.existingData[classValue].id);
            });
          } else if (key === "unit") {
            runInAction(() => {
              self.form.get(key).handleValue(self.existingData[key]?.id || "");
            });
          }
        }
      }
    });

    const addField = field => {
      const newField = fieldStore.create(field);
      self.form.set(field.key, newField);
    };

    const remove = key => self.form.delete(key);

    const setError = error => (self.error = error);
    return { save, afterCreate, addField, remove, setError };
  })
  .views(self => ({
    missingRequired() {
      return Array.from(self.form.keys())
        .map(key => self.form.get(key))
        .filter(field => !field.value && field.required);
    },
    get disableCreate() {
      if (self.getKeys.some(key => key.includes("columnMap"))) {
        const dupCols = [];
        const columnValues = Array.from(self.form.keys())
          .filter(
            key =>
              key.includes("columnMap") && !isEmpty(self.form.get(key).value)
          )
          .map(key => self.form.get(key));

        const uniqValues = uniqWith(
          columnValues,
          (a, b) => a.value === b.value
        );

        columnValues.forEach(el => {
          if (!uniqValues.includes(el) && !dupCols.includes(el.getLabel)) {
            dupCols.push(el.getLabel);
          }
        });

        const hasDuplicates = size(dupCols);

        hasDuplicates
          ? self.setError(`Duplicated columns: ${dupCols.join(", ")}`)
          : self.setError("");

        // at least one columnMap should be selected, no dups are alowed
        return (
          size(this.missingRequired()) || !size(columnValues) || hasDuplicates
        );
      } else {
        return size(this.missingRequired()) > 0;
      }
    },
    get getKeys() {
      return Array.from(self.form.keys());
    },
    get getValues() {
      return Array.from(self.form.keys()).map(key => ({
        key: key,
        field: self.form.get(key)
      }));
    }
  }));
