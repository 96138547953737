/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isBrowser } from "browser-or-node";

export default function isoReadFileAsText(file) {
  if (!isBrowser) {
    return Buffer.isBuffer(file)
      ? file.toString("utf-8")
      : Buffer.isBuffer(file.buffer)
      ? file.buffer.toString("utf-8")
      : file;
  }
  const reader = new window.FileReader();
  reader.readAsText(file, "UTF-8");
  return new Promise((resolve, reject) => {
    reader.onload = evt => {
      resolve(evt.target.result);
    };
    reader.onerror = err => {
      console.error("err:", err);
      reject(err);
    };
  });
}
