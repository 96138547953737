import { isString } from "lodash";

/**
 * Helper class to represent a destination aliquot as we transfer
 * other aliquots into it.
 *
 * The "Partial" in the name of the class refers to the fact that we are just
 * using aliquot ids instead of full aliquot objects. The precludes us from
 * computing many of the properties of the formulation. To compute those properties,
 *
 * This class exists solely to provide information about what and how much
 * goes into a single destination.
 */
class PartialFormulation {
  /**
   * Set up some information about the destination aliquot. You need to specify the `aliquotId`
   * or `aliquotContainerId` because otherwise we would not know where we are transfering to.
   *
   * @param {Object} arg
   * @param {string} arg.aliquotId The id of the destination aliquot. Must be a database id.
   * @param {string} arg.aliquotContainerId The id of the destination aliquot container. Can either be a database id or of the form `&${cid}`.
   * @param {string} arg.sampleName The name of formulated sample, if one gets created. Will be ignored for concentration change.
   * @param {string} arg.concentration The concentration for the new formulated aliquot. Will be ignored for concentration change.
   * @param {string} arg.concentrationUnitCode The concentration unit code for the new aliquot. If not provided, will default to the concentration unit code of the destination aliquot or ng/uL.
   */
  constructor(arg) {
    if (!arg) throw new Error("An object must be passed as the argument.");

    const {
      aliquotId = null,
      aliquotContainerId = null,
      sampleName,
      pendingSamplePoolId = null
    } = arg;

    if (!aliquotId && !aliquotContainerId)
      throw new Error(
        'Either "aliquotId" or "aliquotContainerId" must be defined.'
      );

    /**
     * Information about the destination aliquot.
     */
    this.destination = {
      aliquotId,
      aliquotContainerId,
      pendingSamplePoolId,
      sampleName:
        sampleName ||
        (aliquotId
          ? "formulated-sample-for-aliquot-" + aliquotId
          : "formulated-sample-in-container-" + aliquotContainerId)
    };

    /**
     * Array of objects with keys:
     *   - `aliquotId`: The id of the source aliquot from which we are transfering.
     *   - `volume`: How much of the source aliquot we plan to transfer.
     *   - `volumetricUnitCode`: The units of volume.
     */
    this.transfers = [];
  }

  /**
   * Use this method to add some of a source aliquot to the destination aliquot.
   *
   * @public
   * @param {string} aliquotId The id of the source aliquot of the transfer.
   * @param {number} volume How much we are transfering.
   * @param {string} volumetricUnitCode The units of `volume`.
   */
  addTransfer(aliquotId, volume, volumetricUnitCode) {
    if (!isString(aliquotId)) {
      throw new Error("The argument `aliquotId` must be a string.");
    }
    this.transfers.push({
      aliquotId,
      volume,
      volumetricUnitCode
    });
  }

  /**
   * Use this method to add some of a source additives from the aliquot container to the destination aliquot.
   *
   * @public
   * @param {string} aliquotContainerId The id of the source aliquotContainer of the transfer.
   * @param {number} volume How much we are transfering.
   * @param {string} volumetricUnitCode The units of `volume`.
   */
  addAliquotContainerTransfer(aliquotContainerId, volume, volumetricUnitCode) {
    if (!isString(aliquotContainerId)) {
      throw new Error("The argument `aliquotContainerId` must be a string.");
    }
    this.transfers.push({
      aliquotContainerId,
      volume,
      volumetricUnitCode
    });
  }

  /**
   * Serialize an aliquot formulation instance of JSON.
   *
   * @public
   * @returns {Object} The aliquot formulation serialized as JSON.
   */
  toJson() {
    return {
      destination: this.destination,
      transfers: this.transfers
    };
  }
}

export default PartialFormulation;
