/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import Schematic from "./Schematic";
import SchematicInspector from "./SchematicInspector";
import CustomDragLayer from "./CustomDragLayer";
// import SchematicCombinations from './SchematicCombinations'
import "./style.css";

class SchematicEditView extends React.Component {
  render() {
    const { schematic, readOnly } = this.props;
    if (!schematic) return null;
    return (
      <div className="schematic-edit-view-container">
        <Helmet title="Schematic" />
        <div className="schematic-edit-left-container">
          <Schematic readOnly={readOnly} {...this.props} />
          {/* <SchematicCombinations {...this.props} /> */}
        </div>
        <SchematicInspector readOnly={readOnly} {...this.props} />
        <CustomDragLayer />
      </div>
    );
  }
}

export default SchematicEditView;
