/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import InputPlatesAndQCFile from "./Steps/InputPlatesAndQCFile";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { plateFragment, tableFragment } from "./fragments";
import {
  volumeRender,
  concentrationRender,
  massRender
} from "../../../../src-shared/utils/unitUtils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import {
  isCsvOrExcelFile,
  extractZipFiles,
  validateCSVRequiredHeaders,
  parseCsvOrExcelFile
} from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { getPlateMatch } from "./utils";
import withQuery from "../../../../src-shared/withQuery";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getPositionFromAlphanumericLocation,
  wellInBounds
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { getAliquotMaterialList } from "../../../utils/plateUtils";
import { useCallback } from "react";

const SampleQualityControl = ({
  qualityControlChecks = [],
  toolSchema,
  initialValues,
  toolIntegrationProps
}) => {
  const onSubmit = useCallback(
    async values => {
      try {
        const {
          containerArrays = [],
          sampleQCFile = [],
          outputDataName,
          sampleOrCheck,
          isUpload,
          saveCsvAsTable,
          csvTableName,
          sampleQCInputDataTables = [],
          selectedQualityControlChecks = []
        } = values;
        const dataRows = [];
        sampleQCInputDataTables.forEach(table =>
          table.dataRows.forEach(row => dataRows.push(row))
        );
        const allFiles = await extractZipFiles(sampleQCFile);
        const unparsedCsvFiles = allFiles.filter(file =>
          isCsvOrExcelFile(file)
        );
        if (isUpload && unparsedCsvFiles.length > 1) {
          throw new Error("Multiple files found");
        } else if (isUpload && unparsedCsvFiles.length < 1) {
          throw new Error("No file found");
        }

        const inputPlates = await safeQuery(plateFragment, {
          variables: {
            filter: {
              id: containerArrays.map(containerArray => containerArray.id)
            }
          }
        });

        const aliquotContainerTypeCodes = [];
        const containerArrayTypeIds = [];
        const plateToLocationMap = {};

        inputPlates.forEach(plate => {
          if (!containerArrayTypeIds.includes(plate.containerArrayTypeId)) {
            containerArrayTypeIds.push(plate.containerArrayTypeId);
          }
          plateToLocationMap[plate.id] = {};
          plate.aliquotContainers.forEach(ac => {
            plateToLocationMap[plate.id][getAliquotContainerLocation(ac)] = ac;
            if (
              !aliquotContainerTypeCodes.includes(ac.aliquotContainerTypeCode)
            ) {
              aliquotContainerTypeCodes.push(ac.aliquotContainerTypeCode);
            }
          });
        });

        const sampleUpdates = [];
        const sampleQCChecksToCreate = [];
        const validSampleRows = [];
        const invalidSampleRows = [];

        const errors = [];
        const qcCheckNameToIdMap = {};

        if (isUpload) {
          selectedQualityControlChecks.forEach(
            check => (qcCheckNameToIdMap[check.name] = check.id)
          );
        } else if (sampleOrCheck === "QC") {
          qualityControlChecks.forEach(
            check => (qcCheckNameToIdMap[check.name.toLowerCase()] = check.id)
          );
        }

        const sharedHeaders = ["Plate Name", "Plate Barcode", "Well Location"];
        let requiredHeaders;

        const selectedQcCheckNames = selectedQualityControlChecks.map(
          check => check.name
        );

        if (isUpload) {
          const parsedCsv = await parseCsvOrExcelFile(unparsedCsvFiles[0]);
          const {
            meta: { fields }
          } = parsedCsv;
          if (sampleOrCheck === "QC") {
            requiredHeaders = [...sharedHeaders, ...selectedQcCheckNames];
          } else {
            requiredHeaders = [...sharedHeaders, "Sample Status"];
          }
          const headerError = validateCSVRequiredHeaders(
            fields,
            requiredHeaders
          );
          if (headerError) {
            throw new Error(headerError);
          }

          const csvTableRows = [];

          for (const [index, row] of parsedCsv.data.entries()) {
            const {
              "Plate Name": plateName,
              "Plate Barcode": plateBarcode,
              "Well Location": location,
              "Sample Name": _sampleName,
              "Material Name": _materialName,
              "Sample Status": _sampleStatus = ""
            } = row;

            const sampleStatus = _sampleStatus.trim();
            const qcCheckHeaders = parsedCsv.meta.fields.slice(5);
            const plateToUpdate = getPlateMatch(
              inputPlates,
              plateName,
              plateBarcode
            );
            if (!plateName.trim() && !plateBarcode.trim()) {
              errors.push(
                `Row ${index + 1} did not specify a plate name or barcode`
              );
              continue;
            }

            if (!plateToUpdate) {
              if (plateName && plateBarcode) {
                errors.push(
                  `Row ${
                    index + 1
                  }: No plate was selected with the name ${plateName} and barcode ${plateBarcode}`
                );
              } else if (plateName) {
                errors.push(
                  `Row ${
                    index + 1
                  }: No plate was selected with the name ${plateName}`
                );
              } else {
                errors.push(
                  errors.push(
                    `Row ${
                      index + 1
                    }: No plate was selected with the barcode ${plateBarcode}`
                  )
                );
              }
              continue;
            }

            if (sampleOrCheck === "SAMPLE" && !sampleStatus) {
              errors.push(`Row ${index + 1}: did not specify a sample status`);
              continue;
            }

            if (sampleOrCheck === "QC" && qcCheckHeaders.length < 1) {
              errors.push(
                `File must include columns for ${selectedQualityControlChecks
                  .map(check => check.name)
                  .join(", ")}.`
              );
              continue;
            }

            if (
              sampleOrCheck === "SAMPLE" &&
              sampleStatus.toUpperCase() !== "VALID" &&
              sampleStatus.toUpperCase() !== "INVALID"
            ) {
              errors.push(
                `Row ${
                  index + 1
                }: specifies a sample status of ${sampleStatus}. Please use either "Valid" or "Invalid".`
              );
              continue;
            }

            const csvTableRow = {
              plateName,
              plateBarcode,
              wellLocation: location
            };
            if (sampleOrCheck === "SAMPLE") {
              csvTableRow.sampleStatus = sampleStatus;
            } else {
              csvTableRow.qcCheckName = qcCheckHeaders[0];
              csvTableRow.qcCheckStatus = row[qcCheckHeaders[0]];
            }

            if (sampleOrCheck === "QC") {
              let incorrectSampleStatus = false;
              qcCheckHeaders.forEach(check => {
                const qcCheckValue = row[check];
                if (
                  qcCheckValue &&
                  qcCheckValue.toUpperCase() !== "VALID" &&
                  qcCheckValue &&
                  qcCheckValue.toUpperCase() !== "INVALID"
                ) {
                  incorrectSampleStatus = true;
                }
                csvTableRows.push({
                  rowValues: {
                    ...csvTableRow,
                    qcCheckName: check,
                    qcCheckStatus: qcCheckValue
                  }
                });
              });
              if (incorrectSampleStatus === true) {
                errors.push(
                  `Row ${
                    index + 1
                  }: specifies an unsupported status. Please use either "Valid" or "Invalid".`
                );
                continue;
              }
            } else {
              csvTableRow.sampleStatus = sampleStatus;
              csvTableRows.push({
                rowValues: csvTableRow
              });
            }

            const containerArrayType = plateToUpdate.containerArrayType;
            const wellPosition = getPositionFromAlphanumericLocation(
              location,
              containerArrayType.containerFormat
            );

            if (isNaN(wellPosition.rowPosition)) {
              errors.push(
                `Row ${
                  index + 1
                } specified the location ${location} which was not valid.`
              );
              continue;
            }

            if (!wellInBounds(location, containerArrayType.containerFormat)) {
              errors.push(
                `Row ${
                  index + 1
                }: specified the location ${location} which was not in the bounds of the plate.`
              );
              continue;
            }

            const aliquotContainer =
              plateToLocationMap[plateToUpdate.id][
                getAliquotContainerLocation(wellPosition)
              ];
            if (!aliquotContainer || !aliquotContainer.aliquot) {
              errors.push(
                `Row ${
                  index + 1
                }: No aliquot was found at this location ${location}.`
              );
              continue;
            } else if (!aliquotContainer.aliquot.sample) {
              errors.push(
                `Row ${
                  index + 1
                }: No sample was found on the aliquot at this location ${location}.`
              );
              continue;
            }

            const sample = aliquotContainer.aliquot.sample;
            const aliquot = aliquotContainer.aliquot;
            const materialName = getAliquotMaterialList(aliquot)
              .map(m => m.name)
              .join(", ");
            if (_sampleName && sample.name !== _sampleName) {
              errors.push(
                `Row ${
                  index + 1
                }: Sample name at ${location} did not match name from csv. ${_sampleName} in CSV, ${
                  sample.name
                } found.`
              );
              continue;
            }
            if (_materialName && materialName !== _materialName) {
              errors.push(
                `Row ${
                  index + 1
                }: Material name at ${location} did not match name from csv. ${_materialName} in CSV, ${materialName} found.`
              );
              continue;
            }
            let validSample = true;
            let invalidQCChecks = false;
            if (sampleOrCheck === "QC") {
              invalidQCChecks = qcCheckHeaders.some(check => {
                const qcStatus = row[check];
                return qcStatus.toUpperCase() === "INVALID";
              });
            }
            if (sampleStatus && sampleStatus.toUpperCase() === "INVALID") {
              validSample = false;
            } else if (sampleOrCheck === "QC" && invalidQCChecks) {
              validSample = false;
            }
            if (validSample === true) {
              validSampleRows.push({
                rowValues: {
                  aliquotId: aliquot.id,
                  sampleId: sample.id,
                  materialName,
                  plateName,
                  plateBarcode,
                  well: getAliquotContainerLocation(aliquotContainer),
                  volume: volumeRender(aliquot, undefined, { noJsx: true }),
                  concentration: concentrationRender(aliquot, undefined, {
                    noJsx: true
                  }),
                  mass: massRender(aliquot, undefined, { noJsx: true })
                }
              });
            } else if (validSample === false) {
              invalidSampleRows.push({
                rowValues: {
                  aliquotId: aliquot.id,
                  sampleId: sample.id,
                  materialName,
                  plateName,
                  plateBarcode,
                  well: getAliquotContainerLocation(aliquotContainer),
                  volume: volumeRender(aliquot, undefined, { noJsx: true }),
                  concentration: concentrationRender(aliquot, undefined, {
                    noJsx: true
                  }),
                  mass: massRender(aliquot, undefined, { noJsx: true })
                }
              });
            }
            // only updating the sample's status if they check either of the boxes (to be added)
            if (sampleOrCheck === "SAMPLE") {
              sampleUpdates.push({
                id: sample.id,
                sampleStatusCode: sampleStatus.toUpperCase()
              });
            } else if (sampleOrCheck === "QC") {
              qcCheckHeaders.forEach(qcCheckHeader => {
                if (row[qcCheckHeader]) {
                  sampleQCChecksToCreate.push({
                    qualityControlCheckId: qcCheckNameToIdMap[qcCheckHeader],
                    sampleStatusCode: row[qcCheckHeader].toUpperCase(),
                    sampleId: sample.id,
                    aliquotId: aliquot.id
                  });
                }
              });
            }
          }
          if (errors.length) {
            throw new Error(errors.join("\n"));
          }
          if (saveCsvAsTable) {
            await safeUpsert("dataTable", {
              name: csvTableName,
              dataTableTypeCode:
                sampleOrCheck === "QC"
                  ? "SAMPLE_QC_INPUT_QC_CHECK"
                  : "SAMPLE_QC_INPUT_STATUS",
              dataRows: csvTableRows
            });
          }
        } else {
          const sampleIdToStatusMap = {};
          const deduplicatedRows = dataRows
            .map(_row => {
              const row = Object.assign({}, _row);
              const plateToUpdate = getPlateMatch(
                inputPlates,
                row.rowValues.plateName,
                row.rowValues.plateBarcode
              );
              const wellPosition = getPositionFromAlphanumericLocation(
                row.rowValues.wellLocation,
                plateToUpdate.containerArrayType.containerFormat
              );
              const aliquotContainer =
                plateToLocationMap[plateToUpdate.id][
                  getAliquotContainerLocation(wellPosition)
                ];
              row.aliquotContainer = aliquotContainer;
              return row;
            })
            .filter(row => {
              const qcCheckName =
                row.rowValues.qcCheckName &&
                row.rowValues.qcCheckName.toLowerCase();
              const qcCheckStatus = (
                row.rowValues.qcCheckStatus || ""
              ).toUpperCase();
              const plateToUpdate = getPlateMatch(
                inputPlates,
                row.rowValues.plateName,
                row.rowValues.plateBarcode
              );
              const wellPosition = getPositionFromAlphanumericLocation(
                row.rowValues.wellLocation,
                plateToUpdate.containerArrayType.containerFormat
              );
              const aliquotContainer =
                plateToLocationMap[plateToUpdate.id][
                  getAliquotContainerLocation(wellPosition)
                ];
              const aliquot = aliquotContainer.aliquot;
              if (sampleOrCheck === "QC") {
                sampleQCChecksToCreate.push({
                  qualityControlCheckId: qcCheckNameToIdMap[qcCheckName],
                  sampleStatusCode: qcCheckStatus,
                  sampleId: aliquot.sample.id,
                  aliquotId: aliquot.id
                });
                const seenSample = !!sampleIdToStatusMap[aliquot.sample.id];
                if (!sampleIdToStatusMap[aliquot.sample.id]) {
                  sampleIdToStatusMap[aliquot.sample.id] = qcCheckStatus;
                } else if (qcCheckStatus === "INVALID") {
                  sampleIdToStatusMap[aliquot.sample.id] = "INVALID";
                }
                return !seenSample;
              }
              return true;
            });
          deduplicatedRows.forEach(row => {
            const aliquotContainer = row.aliquotContainer;
            const aliquot = aliquotContainer.aliquot;
            const sampleId = aliquot.sample.id;
            const rowStatus =
              row.rowValues.sampleStatus &&
              row.rowValues.sampleStatus.toUpperCase();
            const sampleStatusCode = sampleIdToStatusMap[sampleId] || rowStatus;
            if (sampleOrCheck === "SAMPLE") {
              sampleUpdates.push({
                id: sampleId,
                sampleStatusCode
              });
            }
            if (sampleStatusCode === "VALID") {
              validSampleRows.push({
                rowValues: {
                  aliquotId: aliquot.id,
                  sampleId: sampleId,
                  materialName: aliquot.sample.material.name,
                  plateName: row.rowValues.plateName,
                  plateBarcode: row.rowValues.plateBarcode,
                  well: getAliquotContainerLocation(aliquotContainer),
                  volume: volumeRender(aliquot, undefined, { noJsx: true }),
                  concentration: concentrationRender(aliquot, undefined, {
                    noJsx: true
                  }),
                  mass: massRender(aliquot, undefined, { noJsx: true })
                }
              });
            } else {
              invalidSampleRows.push({
                rowValues: {
                  aliquotId: aliquot.id,
                  sampleId: sampleId,
                  materialName: aliquot.sample.material.name,
                  plateName: row.rowValues.plateName,
                  plateBarcode: row.rowValues.plateBarcode,
                  well: getAliquotContainerLocation(aliquotContainer),
                  volume: volumeRender(aliquot, undefined, { noJsx: true }),
                  concentration: concentrationRender(aliquot, undefined, {
                    noJsx: true
                  }),
                  mass: massRender(aliquot, undefined, { noJsx: true })
                }
              });
            }
          });
        }

        const dataTables = [
          {
            name: outputDataName + " (Valid Samples)",
            dataTableTypeCode: "VALID_SAMPLE_QC_INVENTORY_LIST",
            dataRows: validSampleRows
          },
          {
            name: outputDataName + " (Invalid Samples)",
            dataTableTypeCode: "INVALID_SAMPLE_QC_INVENTORY_LIST",
            dataRows: invalidSampleRows
          }
        ].filter(table => table.dataRows.length);
        const dataSet = {
          name: outputDataName + " Data Set",
          type: "Sample QC"
        };
        const [createdDataSet] = await safeUpsert("dataSet", dataSet);
        dataTables.forEach(table => {
          table.dataSetId = createdDataSet.id;
        });
        const createdDataTables = await safeUpsert(
          ["dataTable", "id name"],
          dataTables
        );
        await safeUpsert("sampleQualityControlCheck", sampleQCChecksToCreate);
        await safeUpsert("sample", sampleUpdates);
        await safeUpsert(
          ["containerArray", "id updatedAt"],
          containerArrays.map(p => ({ id: p.id, updatedAt: new Date() }))
        );
        return {
          containerArrays,
          validSampleList: createdDataTables.find(
            ({ name }) => name === outputDataName + " (Valid Samples)"
          ),
          invalidSampleList: createdDataTables.find(
            ({ name }) => name === outputDataName + " (Invalid Samples)"
          )
        };
      } catch (error) {
        console.error("error:", error);
        const message = error.message || "Error updating plates.";
        throwFormError(message);
      }
    },
    [qualityControlChecks]
  );

  const validate = values => {
    const { sampleQCInputDataTables = [], isUpload } = values;
    const errors = {};
    if (!isUpload && sampleQCInputDataTables.length) {
      const type = sampleQCInputDataTables[0].dataTableTypeCode;
      const notSameType = sampleQCInputDataTables.some(
        t => t.dataTableTypeCode !== type
      );
      if (notSameType) {
        errors.sampleQCInputDataTables = "All tables must be the same type.";
      }
    }
    return errors;
  };

  return (
    <StepForm
      toolSchema={toolSchema}
      toolIntegrationProps={toolIntegrationProps}
      initialValues={initialValues}
      steps={[
        {
          title: "Select Plates and QC Data",
          Component: InputPlatesAndQCFile,
          withCustomFooter: true,
          props: {
            qualityControlChecks
          }
        }
      ]}
      validate={validate}
      onSubmit={onSubmit}
    />
  );
};

export default compose(
  withQuery(["qualityControlCheck", "id name"], {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        pageSize: 1000
      }
    }
  }),
  withWorkflowInputs(tableFragment, {
    initialValueName: "sampleQCInputDataTables"
  }),
  withWorkflowInputs(plateFragment)
)(SampleQualityControl);
