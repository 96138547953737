/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { camelCase } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { safeUpsert, safeQuery } from "../../src-shared/apolloMethods";

export default async function(container, newParent, newParentTypeCode) {
  if (newParentTypeCode === "EQUIPMENT_ITEM") newParentTypeCode = "EQUIPMENT";

  const refsToUpsert = {
    equipmentId: null,
    equipmentPositionId: null,
    locationId: null
  };
  const valuesToUpsert = {
    id: container.id,
    path: ""
  };

  if (
    newParentTypeCode === "CONTAINER" ||
    newParentTypeCode === "CONTAINER_POSITION"
  ) {
    const container =
      newParentTypeCode === "CONTAINER" ? newParent : newParent.container;
    valuesToUpsert.path = (container.path || "") + "/" + container.id;
    refsToUpsert.equipmentId = container.equipmentId || null;
    refsToUpsert.equipmentPositionId = container.equipmentPositionId || null;
    refsToUpsert.locationId = container.locationId || null;
    if (newParentTypeCode === "CONTAINER_POSITION") {
      valuesToUpsert.path = valuesToUpsert.path + "-" + newParent.id;
    }
  } else {
    refsToUpsert[camelCase(newParentTypeCode) + "Id"] = newParent.id;
  }

  Object.assign(valuesToUpsert, refsToUpsert);

  await safeUpsert("container", valuesToUpsert);

  if (
    valuesToUpsert.path !== container.path ||
    valuesToUpsert.equipmentPositionId !== container.equipmentPositionId
  ) {
    // now update the paths of all the nested containers
    const queryStartsWithString = (container.path || "") + "/" + container.id;
    const replacementString = valuesToUpsert.path
      ? valuesToUpsert.path + "/" + container.id
      : "/" + container.id;

    const qb = new QueryBuilder("container");
    const filter = qb
      .whereAll({
        path: qb.startsWith(queryStartsWithString)
      })
      .toJSON();

    const containersToUpdate = await safeQuery(["container", "id path"], {
      variables: { filter }
    });

    if (containersToUpdate.length) {
      await safeUpsert(
        "container",
        containersToUpdate.map(({ id, path }) => ({
          id,
          path: replacementString + path.slice(queryStartsWithString.length),
          ...refsToUpsert
        }))
      );
    }
  }
}
