/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Button, Tooltip, Position, KeyCombo, Icon } from "@blueprintjs/core";
import { startCase } from "lodash";
import { useDrag } from "react-dnd";
import { connect } from "react-redux";
import actions from "../../../src-shared/redux/actions";

import "./style.css";
import { isDesignLocked } from "../../../src-shared/utils/designUtils/isDesignLocked";

export const prettifyName = name => startCase(name.toLowerCase());

class HeaderIcon extends React.Component {
  handleIconClick = () => {
    const { icon, setSelectedBinsIcon, isLocked } = this.props;
    if (!isLocked) setSelectedBinsIcon({ icon });
  };

  render() {
    const { icon, hotkey, size } = this.props;
    return (
      <Tooltip
        content={
          <span className="sbol-tooltip">
            {prettifyName(icon.name)}
            {hotkey && (
              <KeyCombo className="icon-hotkey" minimala combo={hotkey} />
            )}
          </span>
        }
        position={Position.BOTTOM}
        disabled={!!window.Cypress}
      >
        <Button onClick={this.handleIconClick}>
          <RenderIcon icon={icon} size={size} />
        </Button>
      </Tooltip>
    );
  }
}

function RenderIcon(props) {
  const { icon, size } = props;
  const [{ opacity }, drag] = useDrag({
    item: { icon, type: "SBOL_ICON" },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} style={{ opacity }}>
      {renderHeaderIconInner(icon, { size })}
    </div>
  );
}

export function renderHeaderIconInner(
  icon,
  {
    noSvgWrapper,
    forward = true,
    size,
    height = 50,
    width = 50,
    translateHeight = 0
  } = {}
) {
  let inner;
  if (!icon) {
    inner = <Icon icon="square"></Icon>;
  } else if (
    icon?.path.startsWith("upload") ||
    icon?.path.startsWith("images")
  ) {
    inner = (
      <image
        href={icon.path}
        width={size || width}
        height={size || height}
        {...{
          transform: forward
            ? `translate(36, ${15 + translateHeight})`
            : `translate(36, ${15 + translateHeight})) rotate(180,25,25)`
        }}
      />
    );
  } else {
    inner = (
      <path
        className={`icon-image icon-${icon.name}`}
        {...{
          d: icon.path,
          transform: forward
            ? `translate(36, ${-10 + translateHeight})`
            : `translate(36, ${-10 + translateHeight})rotate(180,25,50)`
        }}
      />
    );
  }

  if (!noSvgWrapper) {
    inner = (
      <svg
        // stroke="white" strokeWidth="3" fill="none" d={icon.path}
        // viewBox={`-10 ${icon.center ? 10 : 18} 70 60`}
        viewBox="25 5 70 70"
        height={size || width}
        width={size || height}
      >
        {inner}
      </svg>
    );
  }
  return inner;
}

export default connect(
  state => ({
    isLocked: !!isDesignLocked(state)
  }),
  {
    setSelectedBinsIcon: actions.design.setSelectedBinsIcon
  }
)(HeaderIcon);
