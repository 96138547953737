/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import PLACEHOLDERS from "./placeholders";

const makeMentionsMarkup = (markup, id, display) => {
  return markup
    .replace(PLACEHOLDERS.id, id)
    .replace(PLACEHOLDERS.display, display);
};

export default makeMentionsMarkup;
