/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isObject } from "lodash";

export const isErdam = assemblyMethodOrAssemblyMethodCid => {
  if (isObject(assemblyMethodOrAssemblyMethodCid)) {
    return (
      get(assemblyMethodOrAssemblyMethodCid, "name") ===
      "Contiguous Express Digest"
    );
  } else {
    return (
      assemblyMethodOrAssemblyMethodCid === "&digest" ||
      assemblyMethodOrAssemblyMethodCid === "digest"
    );
  }
};
