/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, groupBy } from "lodash";
import { doTransfersFit, standardizeVolume, toFixedPico } from "./unitUtils";
import Big from "big.js";
import {
  getAliquotContainerSource,
  getNormalizedAliquotContainerPosition,
  getPlateLocationMap,
  getVolumeOfAliquotContainer
} from "./plateUtils";
import { getAliquotContainerLocation } from "./getAliquotContainerLocation";
import isValidPositiveNumber from "../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import { isoContext } from "@teselagen/utils";
import { removeExt } from "../../../tg-iso-shared/src/utils/fileUtils";
import unitGlobals from "../unitGlobals";

const getTubeMsg = tube => {
  const barcode = get(tube, "barcode.barcodeString");
  const name = get(tube, "name");
  if (!name && !barcode) {
    return "Tube";
  } else if (name && barcode) {
    return `${name} (${barcode})`;
  } else {
    return name || barcode;
  }
};

export const worklistFormats = [
  "Echo",
  "TeselaGen",
  "Tecan",
  "Hamilton",
  "PipetMax",
  "FlowBot",
  "epMotion",
  "Biomek",
  "Mosquito",
  "QPix",
  "Mantis",
  "F.A.S.T."
];

//tnw - is this a duplicate of the one in client/src-build/components/LimsTools/CreateCustomWorklist/UploadFiles.js ??
export const worklistCsvFields = [
  {
    path: "Source Plate Barcode",
    description: "Barcode of the source plate",
    example: "PLT123456"
  },
  {
    path: "Source Tube Barcode",
    description: "Barcode of the source tube. Optional if not a loose tube.",
    example: "TUB789012"
  },
  {
    path: "Source Location",
    description: "Location of the source",
    example: "A1"
  },
  {
    path: "Destination Plate Barcode",
    description: "Barcode of the destination plate",
    example: "PLT345678"
  },
  {
    path: "Destination Tube Barcode",
    description: "Barcode of the destination tube",
    example: "TUB901234"
  },
  {
    path: "Destination Location",
    description: "Location of the destination",
    example: "B3"
  },
  {
    path: "Transfer Volume",
    description: "Volume to be transferred",
    type: "number",
    example: "50.2"
  },
  {
    path: "Transfer Volume Unit",
    description: "Unit of the transfer volume",
    example: "µL"
  }
];
export const worklistCsvHeaders = worklistCsvFields.map(({ path }) => path);

export function getTransferIntoFromAliquotContainer(worklists) {
  const transfersFromAliquotContainer = {};
  const transfersIntoAliquotContainer = {};

  worklists.forEach(selectedWorklist => {
    selectedWorklist.worklistTransfers?.forEach(transfer => {
      if (transfer.destinationAliquotContainer) {
        const transfersInto =
          transfersIntoAliquotContainer[
            transfer.destinationAliquotContainer.id
          ] || [];
        transfersIntoAliquotContainer[transfer.destinationAliquotContainer.id] =
          transfersInto;
        transfersInto.push(transfer);
      }
      if (transfer.sourceAliquotContainer) {
        const transfersFrom =
          transfersFromAliquotContainer[transfer.sourceAliquotContainer.id] ||
          [];
        transfersFromAliquotContainer[transfer.sourceAliquotContainer.id] =
          transfersFrom;
        transfersFrom.push(transfer);
      }
    });
  });

  return {
    transfersFromAliquotContainer,
    transfersIntoAliquotContainer
  };
}

export function isUnreadyNormalizationTransfer(transfersToDestinationWell) {
  return transfersToDestinationWell.every(t => {
    return (
      t.sourceAliquotContainer?.aliquot?.aliquotType === "additive" &&
      !t.destinationAliquotContainer?.aliquot
    );
  });
}

export function validateWorklist(worklist, allWorklists = []) {
  const warnings = [];
  const { transfersFromAliquotContainer, transfersIntoAliquotContainer } =
    getTransferIntoFromAliquotContainer([worklist]);
  let allTransfersIntoAliquotContainer = {};
  if (allWorklists.length) {
    allTransfersIntoAliquotContainer =
      getTransferIntoFromAliquotContainer(
        allWorklists
      ).transfersIntoAliquotContainer;
  } else {
    allTransfersIntoAliquotContainer = transfersIntoAliquotContainer;
  }

  const alreadyMovedToDestinationPositions = {};

  const rackToAcceptableTubeTypes = {};
  const rackToPositionedTubes = {};

  if (worklist.worklistStatusCode === "EXECUTED") {
    warnings.push(
      "This worklist has already been executed, please check source and destination plate contents before proceeding"
    );
  }
  let hasPrenormalizationError = false;
  let errors = [];
  if (worklist.tubeTransfers?.length) {
    errors = worklist.tubeTransfers.reduce((acc, transfer, index) => {
      const {
        aliquotContainer,
        sourceContainerArray,
        destinationContainerArray,
        sourceRowPosition,
        sourceColumnPosition,
        destinationRowPosition,
        destinationColumnPosition
      } = transfer;
      const sourcePos = {
        rowPosition: sourceRowPosition,
        columnPosition: sourceColumnPosition
      };
      const sourcePos2d = getAliquotContainerLocation(sourcePos, {
        force2D: true
      });
      const destPos = {
        rowPosition: destinationRowPosition,
        columnPosition: destinationColumnPosition
      };
      const destPos2d = getAliquotContainerLocation(destPos, {
        force2D: true
      });
      if (
        !aliquotContainer ||
        !sourceContainerArray ||
        !destinationContainerArray
      ) {
        const error = `Worklist Transfer ${
          index + 1
        }: Transfer Information has been corrupted.`;
        acc.push(error);
        return acc;
      }
      if (!rackToPositionedTubes[sourceContainerArray.id]) {
        rackToPositionedTubes[sourceContainerArray.id] =
          getPlateLocationMap(sourceContainerArray);
      }
      if (!rackToPositionedTubes[destinationContainerArray.id]) {
        rackToPositionedTubes[destinationContainerArray.id] =
          getPlateLocationMap(destinationContainerArray);
      }
      const shouldBeSourceTube =
        rackToPositionedTubes[sourceContainerArray.id][sourcePos2d];
      if (
        !shouldBeSourceTube ||
        shouldBeSourceTube.id !== aliquotContainer.id
      ) {
        const error = `Worklist Transfer ${
          index + 1
        }: Tube is no longer at specified position.`;
        acc.push(error);
        return acc;
      }
      const destinationLocationItem =
        rackToPositionedTubes[destinationContainerArray.id][destPos2d];
      if (destinationLocationItem) {
        const error = `Worklist Transfer ${
          index + 1
        }: There is already a tube at the destination position.`;
        acc.push(error);
        return acc;
      }
      const destKey = `${destinationContainerArray.id}:${destPos2d}`;
      if (alreadyMovedToDestinationPositions[destKey]) {
        const error = `Worklist Transfer ${
          index + 1
        }: A transfer has already happened at this destination.`;
        acc.push(error);
        return acc;
      } else {
        alreadyMovedToDestinationPositions[destKey] = true;
      }

      if (!rackToAcceptableTubeTypes[destinationContainerArray.id]) {
        rackToAcceptableTubeTypes[destinationContainerArray.id] =
          destinationContainerArray.containerArrayType.nestableTubeTypes.map(
            t => t.aliquotContainerTypeCode
          );
      }

      if (
        !rackToAcceptableTubeTypes[destinationContainerArray.id].includes(
          aliquotContainer.aliquotContainerTypeCode
        )
      ) {
        const error = `Worklist Transfer ${
          index + 1
        }: Destination rack does not accept this tube type.`;
        acc.push(error);
        return acc;
      }

      return acc;
    }, []);
  } else {
    const srcAcIdToStdTransferredFromVol = {};
    const dstWellCanFitMap = {};

    errors = worklist.worklistTransfers.reduce((acc, transfer, index) => {
      const { sourceAliquotContainer, destinationAliquotContainer } = transfer;
      const sourceEntity = getAliquotContainerSource(sourceAliquotContainer);
      if (
        !sourceAliquotContainer ||
        !sourceEntity ||
        !destinationAliquotContainer
      ) {
        const error = `Worklist Transfer ${
          index + 1
        }: Transfer Information has been corrupted.`;
        acc.push(error);
        return acc;
      }

      let standardVolumeTransferredFromAc = new Big(0);
      if (srcAcIdToStdTransferredFromVol[sourceAliquotContainer.id]) {
        standardVolumeTransferredFromAc =
          srcAcIdToStdTransferredFromVol[sourceAliquotContainer.id];
      } else {
        const transfersFrom =
          transfersFromAliquotContainer[sourceAliquotContainer.id];

        const addTransferFrom = transfer => {
          const standardTransferVolume = standardizeVolume(
            transfer.volume,
            transfer.volumetricUnitCode,
            true
          );
          standardVolumeTransferredFromAc = standardVolumeTransferredFromAc.add(
            standardTransferVolume
          );
        };
        transfersFrom.forEach(addTransferFrom);
        srcAcIdToStdTransferredFromVol[sourceAliquotContainer.id] =
          standardVolumeTransferredFromAc;
      }

      const standardSourceEntityVolume = getVolumeOfAliquotContainer(
        transfer.sourceAliquotContainer,
        true
      );

      const {
        maxVolume: maxWellVolume,
        volumetricUnitCode: maxWellVolumetricUnitCode
      } = get(transfer, "destinationAliquotContainer.aliquotContainerType");

      const sourcePlate = get(sourceAliquotContainer, "containerArray.name");
      const destinationPlate = get(
        destinationAliquotContainer,
        "containerArray.name"
      );
      const sourceAliquotContainerLocation = getAliquotContainerLocation(
        sourceAliquotContainer
      );
      const destinationAliquotContainerLocation = getAliquotContainerLocation(
        destinationAliquotContainer
      );

      const remainingVolumeInContainer = Number(
        toFixedPico(
          standardSourceEntityVolume.minus(standardVolumeTransferredFromAc)
        )
      );

      let sourceObjectMsg;
      if (sourcePlate) {
        sourceObjectMsg = `${sourcePlate} well ${sourceAliquotContainerLocation}`;
      } else {
        const msg = getTubeMsg(sourceAliquotContainer);
        sourceObjectMsg = `(source) ${msg}`;
      }

      let destinationObjectMsg;
      if (destinationPlate) {
        destinationObjectMsg = `(destination) ${destinationPlate} well ${destinationAliquotContainerLocation} `;
      } else {
        const msg = getTubeMsg(destinationAliquotContainer);
        destinationObjectMsg = `(destination) ${msg} `;
      }

      if (remainingVolumeInContainer < 0) {
        const error = `Worklist Transfer ${
          index + 1
        }: ${sourceObjectMsg} has less volume than the transfer volume.`;
        acc.push(error);
      } else {
        // handle dead volume logic
        const { isTube, deadVolume, deadVolumetricUnitCode } =
          sourceAliquotContainer.aliquotContainerType;
        const sourceAliquotContainerDeadVolume = standardizeVolume(
          deadVolume || 0,
          deadVolumetricUnitCode || "uL"
        );
        if (
          sourceAliquotContainerDeadVolume > 0 &&
          remainingVolumeInContainer < sourceAliquotContainerDeadVolume
        ) {
          const warning = `Worklist Transfer ${
            index + 1
          }: ${sourceObjectMsg} would go below ${
            isTube ? "tube's" : "well's"
          } dead volume (${deadVolume} ${deadVolumetricUnitCode})`;
          warnings.push(warning);
        }
      }

      const transfersToDestinationWell =
        transfersIntoAliquotContainer[destinationAliquotContainer.id];
      let transfersFit;
      if (dstWellCanFitMap[destinationAliquotContainer.id] !== undefined) {
        transfersFit = dstWellCanFitMap[destinationAliquotContainer.id];
      } else {
        const destinationAliquotVol = {
          volume: getVolumeOfAliquotContainer(destinationAliquotContainer),
          volumetricUnitCode: "L"
        };
        transfersFit = doTransfersFit({
          maxVolume: maxWellVolume,
          maxVolumetricUnitCode: maxWellVolumetricUnitCode,
          transfers: transfersToDestinationWell.concat(destinationAliquotVol)
        });
      }
      if (!transfersFit) {
        const error = `Worklist Transfer ${
          index + 1
        }: ${destinationObjectMsg} capacity is less than its current volume and transfer volume combined.`;

        acc.push(error);
      }

      if (transfer.volume < 0) {
        const error = `Worklist Transfer ${
          index + 1
        }: ${destinationObjectMsg} has a transfer volume less than 0.`;

        acc.push(error);
      }
      if (!hasPrenormalizationError) {
        if (
          isUnreadyNormalizationTransfer(
            allTransfersIntoAliquotContainer[destinationAliquotContainer.id]
          )
        ) {
          hasPrenormalizationError = true;
          const error = `Cannot execute normalization worklist on empty wells. Please execute pre-normalization worklist first.`;
          acc.push(error);
        }
      }
      return acc;
    }, []);
  }

  return { errors, warnings };
}

export async function parseWorklistCsvData(csvs, ctx = isoContext) {
  const { safeQuery } = ctx;
  const errors = [];
  const plateBarcodes = [];
  const tubeBarcodes = [];
  csvs.forEach(file => {
    const { data, name: filename } = file;
    data.forEach((row, index) => {
      const {
        "Source Plate Barcode": sourcePlateBarcode,
        "Source Tube Barcode": sourceTubeBarcode,
        "Destination Plate Barcode": destinationPlateBarcode,
        "Destination Tube Barcode": destinationTubeBarcode
      } = row;
      if (!sourcePlateBarcode && !sourceTubeBarcode) {
        return errors.push(
          `File ${filename}: Row ${index + 1} did not specify a source.`
        );
      }
      if (!destinationPlateBarcode && !destinationTubeBarcode) {
        return errors.push(
          `File ${filename}: Row ${index + 1} did not specify a destination.`
        );
      }
      if (sourcePlateBarcode) {
        plateBarcodes.push(sourcePlateBarcode);
      } else {
        tubeBarcodes.push(sourceTubeBarcode);
      }
      if (destinationPlateBarcode) {
        plateBarcodes.push(destinationPlateBarcode);
      } else {
        tubeBarcodes.push(destinationTubeBarcode);
      }
    });
  });

  if (errors.length) {
    return {
      error: errors.join("\n")
    };
  }

  let groupedPlates = {};
  let groupedTubes = {};
  if (plateBarcodes.length) {
    const plates = await safeQuery(
      [
        "containerArray",
        /* GraphQL */ `
          {
            id
            barcode {
              id
              barcodeString
            }
            aliquotContainers {
              id
              rowPosition
              columnPosition
            }
            containerArrayType {
              id
              isPlate
              containerFormat {
                code
                rowCount
                columnCount
              }
            }
          }
        `
      ],
      {
        variables: {
          filter: {
            "barcode.barcodeString": plateBarcodes
          }
        }
      }
    );
    groupedPlates = groupBy(plates, "barcode.barcodeString");
  }
  if (tubeBarcodes.length) {
    const tubes = await safeQuery(
      ["aliquotContainer", "id barcode { id barcodeString }"],
      {
        variables: {
          filter: {
            "barcode.barcodeString": tubeBarcodes
          }
        }
      }
    );
    groupedTubes = groupBy(tubes, "barcode.barcodeString");
  }

  const worklists = [];
  const getPlateOrTubeForRow = (plateBarcode, tubeBarcode, index, filename) => {
    if (plateBarcode) {
      const sourcePlates = groupedPlates[plateBarcode] || [];
      if (!sourcePlates.length) {
        return errors.push(
          `File ${filename}: Row ${
            index + 1
          } no plate was found with barcode ${plateBarcode}.`
        );
      } else if (sourcePlates.length > 1) {
        return errors.push(
          `File ${filename}: Row ${
            index + 1
          } multiple plates were found with barcode ${plateBarcode}. Barcodes must be unique.`
        );
      }
      return { plate: sourcePlates[0] };
    } else {
      const sourceTubes = groupedTubes[tubeBarcode] || [];
      if (!sourceTubes.length) {
        return errors.push(
          `File ${filename}: Row ${
            index + 1
          } no tube was found with barcode ${tubeBarcode}.`
        );
      } else if (sourceTubes.length > 1) {
        return errors.push(
          `File ${filename}: Row ${
            index + 1
          } multiple tubes were found with barcode ${tubeBarcode}. Barcodes must be unique.`
        );
      }
      return { tube: sourceTubes[0] };
    }
  };
  const plateBarcodeToAliquotContainerMap = {};

  const getPlateMap = plate => {
    const barcode = plate.barcode.barcodeString;
    if (!plateBarcodeToAliquotContainerMap[barcode]) {
      plateBarcodeToAliquotContainerMap[barcode] = getPlateLocationMap(plate);
    }
    return plateBarcodeToAliquotContainerMap[barcode];
  };

  csvs.forEach(file => {
    const { data, name: filename } = file;
    const transfers = [];
    data.forEach((row, index) => {
      const {
        "Source Plate Barcode": sourcePlateBarcode,
        "Source Tube Barcode": sourceTubeBarcode,
        "Destination Plate Barcode": destinationPlateBarcode,
        "Destination Tube Barcode": destinationTubeBarcode,
        "Source Location": sourceLocation,
        "Destination Location": destLocation,
        "Transfer Volume": transferVolume,
        "Transfer Volume Unit": transferVolumeUnit
      } = row;
      const { tube: sourceTube, plate: sourcePlate } = getPlateOrTubeForRow(
        sourcePlateBarcode,
        sourceTubeBarcode,
        index,
        filename
      );
      if (!sourceTube && !sourcePlate) return;
      if (!unitGlobals.volumetricUnits[transferVolumeUnit]) {
        return errors.push(
          `File ${filename}: Row ${
            index + 1
          } volume unit ${transferVolumeUnit} is invalid.`
        );
      }
      if (!isValidPositiveNumber(transferVolume)) {
        return errors.push(
          `File ${filename}: Row ${
            index + 1
          } did not provide a valid transfer volume.`
        );
      }
      const { tube: destinationTube, plate: destinationPlate } =
        getPlateOrTubeForRow(
          destinationPlateBarcode,
          destinationTubeBarcode,
          index,
          filename
        );
      if (!destinationTube && !destinationPlate) return;

      let sourceAc = sourceTube;
      let destAc = destinationTube;
      if (sourcePlate) {
        const plateMap = getPlateMap(sourcePlate);
        const location = getNormalizedAliquotContainerPosition(sourceLocation);
        if (!location || !plateMap[location]) {
          return errors.push(
            `File ${filename}: Row ${
              index + 1
            } location ${sourceLocation} did not map to a valid position.`
          );
        }
        sourceAc = plateMap[location];
      }
      if (destinationPlate) {
        const plateMap = getPlateMap(destinationPlate);
        const location = getNormalizedAliquotContainerPosition(destLocation);
        if (!location || !plateMap[location]) {
          return errors.push(
            `File ${filename}: Row ${
              index + 1
            } location ${destLocation} did not map to a valid position.`
          );
        }
        destAc = plateMap[location];
      }
      transfers.push({
        volume: transferVolume,
        volumetricUnitCode: transferVolumeUnit,
        sourceAliquotContainerId: sourceAc.id,
        destinationAliquotContainerId: destAc.id
      });
    });

    worklists.push({
      name: removeExt(filename),
      worklistTransfers: transfers
    });
  });
  if (errors.length) {
    return {
      error: errors.join("\n")
    };
  }
  return { worklists };
}
