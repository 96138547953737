/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const plateMapGroupFragment = gql`
  fragment aliquotRearrayPlateMapGroupFragment on plateMapGroup {
    id
    name
    plateMaps {
      id
      type
      name
      plateMapItems {
        id
        name
        rowPosition
        columnPosition
        volume
        volumetricUnitCode
        inventoryItem {
          id
          material {
            id
            name
          }
          sample {
            id
            name
          }
          additiveMaterial {
            id
            name
          }
          lot {
            id
            name
          }
        }
      }
    }
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
`;

export const additiveFragment = `
  additives {
    id
    volume
    volumetricUnitCode
    additiveMaterialId
    lotId
    additiveMaterial {
      id
      name
    }
    lot {
      id
      name
      additiveMaterialId
      additiveMaterial {
        id
        name
      }
    }
  }
`;

export const aliquotContainerFragment = `
  id
  name
  columnPosition
  rowPosition
  barcode {
    id
    barcodeString
  }
  aliquotContainerType {
    code
    deadVolume
    deadVolumetricUnitCode
  }
  ${additiveFragment}
  aliquot {
    id
    isDry
    ${additiveFragment}
    sample {
      id
      name
      sampleStatusCode
      material {
        id
        name
      }
    }
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
  }
`;

export const plateFragment = [
  "containerArray",
  `
  id
  name
  barcode {
    id
    barcodeString
  }
  containerArrayType {
    id
    name
    isPlate
    containerFormatCode
    containerFormat {
      code
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
    }
  }
  aliquotContainers {
    ${aliquotContainerFragment}
  }
`
];

export const aliquotRearrayDestinationPlateFragment = gql`
  fragment aliquotRearrayDestinationPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      containerFormatCode
      isPlate
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        sample {
          id
          name
          material {
            id
            name
          }
        }
      }
    }
  }
`;

export const aliquotRearrayTableFragment = gql`
  fragment aliquotRearrayTableFragment on dataTable {
    id
    name
    dataTableTypeCode
    dataRows {
      id
      rowValues
    }
  }
`;
