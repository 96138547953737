/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isFunction } from "lodash";
import inBrowser from "../../utils/inBrowser";
import executeBrowserAliquotFormulation from "./executeBrowserAliquotFormulation";
import executeServerAliquotFormulation from "./executeServerAliquotFormulation";

/**
 * Given an array of aliquot formulations to execute, this function saves
 * the information contained in the array to the database.
 *
 * Source and destination aliquots should form disjoint sets. Dry aliquots cannot
 * be source aliquots.
 *
 * Return an object providing information about the changes with the following keys:
 *    - `success`: {boolean} Was all of the information successfully saved into the database?
 *    - `error`: {string} If not successful, a message providing information about the error.
 *    - `rehydratedAliquotIds`: {Array<string>} If wet aliquots were added to dry aliquots, these
 *      aliquots are automatically rehydrated. The user may want to overide this behavior afterwards,
 *      this provides convenient access to the rehydrated aliquots.
 *
 * @param {Array<AliquotFormulation> | AliquotFormulation} aliquotFormulations The aliquot formulations we wish to execute. On the server, these could also be serialized json.
 * @param {Object} options Any options for the execution.
 * @param {string} options.userId The id of the user performing the execution. Only needed on the server.
 * @param {Object} app The `app` object on the server. Only needs to be supplied on the server.
 * @returns {Object}
 */
async function executeAliquotFormulations(
  aliquotFormulations,
  options = {},
  app,
  apolloMethods
) {
  // Enable the argument to be eiter a single AliquotFormulation object or an array of AliquotFormulation objects.
  if (!Array.isArray(aliquotFormulations)) {
    aliquotFormulations = [aliquotFormulations];
  }

  if (inBrowser()) {
    // If we are in the browser, we must send a network request to execute the aliquot
    // formulations on the backend.
    return await executeBrowserAliquotFormulation(aliquotFormulations, options);
  } else {
    // For sake of consistency, if we are on the server and the aliquots formulations are objects,
    // then convert them to serialized JSON.
    const jsons = aliquotFormulations.map(af =>
      isFunction(af.toJson) ? af.toJson() : af
    );
    return await executeServerAliquotFormulation(
      jsons,
      options,
      app,
      apolloMethods
    );
  }
}
export default executeAliquotFormulations;
