/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const exportOligoJ5ReportFragment = gql`
  fragment exportOligoJ5ReportFragment on j5OligoSynthesis {
    id
    name
    tm
    tm3Prime
    cost
    oligo {
      id
      sequence {
        id
        size
        sequenceFragments {
          id
          index
          fragment
        }
      }
    }
  }
`;
