/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import PcrReaction from "../structs/PcrReaction";

const data = [
  [
    0,
    "pGFPuv_cterm_signal",
    "",
    "j5_00001_(gfpuv_sigpep_5prime)_pure_forward",
    "j5_00003_(gfpuv_sigpep_5prime)_pure_reverse",
    "PCR",
    60.5775,
    0.739000000000004,
    60.5775,
    0.739000000000004
  ],
  [
    1,
    "pGFPuv_cterm_signal",
    "",
    "j5_00005_(gfpuv_sigpep_middle)_pure_forward",
    "j5_00007_(gfpuv_sigpep_middle)_pure_reverse",
    "SOE",
    58.342,
    3.484,
    58.342,
    3.484
  ],
  [
    2,
    "pGFPuv_cterm_signal",
    "",
    "j5_00009_(gfpuv_sigpep_3prime)_pure_forward",
    "j5_00011_(gfpuv_sigpep_3prime)_pure_reverse",
    "PCR",
    60.7225,
    0.476999999999997,
    60.7225,
    0.476999999999997
  ],
  [
    3,
    "PCR/SOE Reaction 0",
    "pGFPuv_cterm_signal",
    "j5_00002_(RBS)_(gfpuv_sigpep_5prime)_forward",
    "j5_00004_(gfpuv_sigpep_5prime)_(gfpuv_sigpep_XhoI_silent)_reverse",
    "PCR",
    68.872,
    3.446,
    60.5775,
    0.739000000000004
  ],
  [
    4,
    "PCR/SOE Reaction 1",
    "pGFPuv_cterm_signal",
    "j5_00006_(gfpuv_sigpep_middle)_forward",
    "j5_00008_(gfpuv_sigpep_middle)_(gfpuv_sigpep_BamHI_silent)_reverse",
    "SOE",
    68.501,
    0.881999999999991,
    58.342,
    3.484
  ],
  [
    5,
    "PCR/SOE Reaction 2",
    "pGFPuv_cterm_signal",
    "j5_00010_(gfpuv_sigpep_3prime)_forward",
    "j5_00012_(gfpuv_sigpep_3prime)_reverse",
    "PCR",
    69.636,
    4.044,
    60.7225,
    0.476999999999997
  ],
  [
    6,
    "pj5_00001",
    "",
    "j5_00013_(pS8c-gfpuv_sig.pep_vector_backbone)_pure_forward",
    "j5_00015_(pS8c-gfpuv_sig.pep_vector_backbone)_pure_reverse",
    "PCR",
    59.341,
    4.192,
    59.341,
    4.192
  ],
  [
    7,
    "umuD_tag_GFPuv",
    "",
    "j5_00017_(umuD_tag)_pure_forward",
    "j5_00019_(umuD_tag)_pure_reverse",
    "PCR",
    64.114,
    4.67399999999999,
    64.114,
    4.67399999999999
  ],
  [
    8,
    "PCR Reaction 6",
    "pj5_00001",
    "j5_00014_(pS8c-gfpuv_sig.pep_vector_backbone)_forward",
    "j5_00016_(pS8c-gfpuv_sig.pep_vector_backbone)_reverse",
    "PCR",
    65.9015,
    5.483,
    59.341,
    4.192
  ],
  [
    9,
    "PCR Reaction 7",
    "umuD_tag_GFPuv",
    "j5_00018_(umuD_tag)_forward",
    "j5_00020_(umuD_tag)_reverse",
    "PCR",
    69.7285,
    1.801,
    64.114,
    4.67399999999999
  ],
  [
    10,
    "pj5_00001",
    "",
    "j5_00021_(pS8c-vector_backbone)_pure_forward",
    "j5_00023_(pS8c-vector_backbone)_pure_reverse",
    "PCR",
    56.4505,
    4.851,
    56.4505,
    4.851
  ],
  [
    11,
    "pj5_00001",
    "",
    "j5_00025_(GFPuv)_pure_forward",
    "j5_00027_(GFPuv)_pure_reverse",
    "PCR",
    62.919,
    4.76000000000001,
    62.919,
    4.76000000000001
  ],
  [
    12,
    "PCR Reaction 10",
    "pj5_00001",
    "j5_00022_(ssrA_tag_3prime)_(pS8c-vector_backbone)_forward",
    "j5_00024_(pS8c-vector_backbone)_(BMC_nterm_sig_pep)_reverse",
    "PCR",
    71.646,
    5.858,
    56.4505,
    4.851
  ],
  [
    13,
    "PCR Reaction 11",
    "pj5_00001",
    "j5_00026_(long_gly_ser_linker)_(GFPuv)_forward",
    "j5_00028_(GFPuv)_(ssrA_tag_5prime)_reverse",
    "PCR",
    78.793,
    2.80200000000001,
    62.919,
    4.76000000000001
  ],
  [
    14,
    "PCR Reaction 11",
    "pj5_00001",
    "j5_00026_(long_gly_ser_linker)_(GFPuv)_forward",
    "j5_00029_(GFPuv)_(ssrA_tag_enhanced_5prime)_reverse",
    "PCR",
    78.2585,
    3.87100000000001,
    62.919,
    4.76000000000001
  ],
  [
    15,
    "PCR Reaction 11",
    "pj5_00001",
    "j5_00030_(short_gly_ser_linker)_(GFPuv)_forward",
    "j5_00028_(GFPuv)_(ssrA_tag_5prime)_reverse",
    "PCR",
    76.873,
    1.038,
    62.919,
    4.76000000000001
  ],
  [
    16,
    "PCR Reaction 11",
    "pj5_00001",
    "j5_00030_(short_gly_ser_linker)_(GFPuv)_forward",
    "j5_00029_(GFPuv)_(ssrA_tag_enhanced_5prime)_reverse",
    "PCR",
    76.3385,
    0.0310000000000059,
    62.919,
    4.76000000000001
  ],
  [
    17,
    "PCR Reaction 10",
    "pj5_00001",
    "j5_00022_(ssrA_tag_3prime)_(pS8c-vector_backbone)_forward",
    "j5_00031_(pS8c-vector_backbone)_(ccmN_nterm_sig_pep)_reverse",
    "PCR",
    76.3095,
    3.46899999999999,
    56.4505,
    4.851
  ]
];

const pcrReactions = data.map(d => {
  return new PcrReaction({
    id: d[0],
    template_name: d[1],
    alt_template_name: d[2],
    forward_primer: d[3],
    reverse_primer: d[4],
    note: d[5],
    primer_mean_tm_full_length: d[6],
    primer_delta_tm_full_length: d[7],
    primer_mean_tm: d[8],
    primer_delta_tm: d[9]
  });
});

export default pcrReactions;
