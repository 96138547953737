/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment assayRecordFragment on assay {
    id
    name
    createdAt
    updatedAt
  }
`;
