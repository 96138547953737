/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";

import { get } from "lodash";
import { withRouter } from "react-router-dom";
import ElementPanel from "../components/HierarchicalDesign/DesignInspector/ElementPanel";
import actions from "../../src-shared/redux/actions";
import {
  getSelectedElement,
  getSelectedCardId,
  getBinOfSelectedElement
} from "../../src-shared/selectors/designViewSelectors";
import {
  isDesignVisualReport,
  isCardRoot,
  getEugeneRulesOfElement,
  getAllOfType,
  getItemOfType,
  getFasOfElement,
  getPartSetPartsWithPartInfo,
  getOperand1Elements,
  getOperand2Elements,
  getRestrictionEnzymeOfReaction
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";

import {
  isViewClassic,
  getInputCardById
} from "../../src-shared/selectors/classicViewSelectors";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

const getEugeneRulesToRender = tgCreateCachedSelector(
  state => state,
  state => getAllOfType(state, "element"),
  getEugeneRulesOfElement,
  (state, elements, rules) =>
    rules.map(rule => ({
      ...rule,
      operand1: getOperand1Elements(state, rule.id),
      operand2: getOperand2Elements(state, rule.id)
    }))
)((state, cardId, elementId) => `${cardId}:${elementId}`);

const mapStateToProps = state => {
  const selectedCardId = getSelectedCardId(state);
  const selectedElement = getSelectedElement(state);
  const setOfSelectedElement = getBinOfSelectedElement(state);
  if (!selectedElement) return {};

  const aminoAcidPart =
    selectedElement.aminoAcidPartId &&
    getItemOfType(state, "aminoAcidPart", selectedElement.aminoAcidPartId);

  const aminoAcidSequence =
    aminoAcidPart?.aminoAcidSequenceId &&
    getItemOfType(
      state,
      "aminoAcidSequence",
      aminoAcidPart.aminoAcidSequenceId
    );

  const part =
    selectedElement.partId &&
    getItemOfType(state, "part", selectedElement.partId);
  let sequence, partRestrictionEnzyme;
  if (part) {
    sequence = part && getItemOfType(state, "sequence", part.sequenceId);

    partRestrictionEnzyme = get(
      state.design.restrictionEnzyme,
      `${part.re5PrimeId}`
    );
  }

  const classicView = isViewClassic(state);
  const inputCardId =
    classicView && getInputCardById(state, setOfSelectedElement.id).id;

  const fasObj = getFasOfElement(
    state,
    classicView ? inputCardId : selectedCardId,
    selectedElement.id
  );

  const fas = get(fasObj, "name", "None");

  const reactionRestrictionEnzyme = getRestrictionEnzymeOfReaction(
    state,
    fasObj?.reactionId
  );
  const sequences = getAllOfType(state, "sequence");

  const fullPart = part && {
    ...part,
    start: part && part.start,
    end: part && part.end,
    sequence
  };
  return {
    selectedCardId,
    isSelectedCardRoot: isCardRoot(state, selectedCardId),
    selectedElement,
    isVisualReport: isDesignVisualReport(state),
    isLocked: isDesignLocked(state),
    part: fullPart,
    partRestrictionEnzyme,
    reactionRestrictionEnzyme,
    aminoAcidPart,
    inspectorWidth: state.ui.designEditor.inspector.width,
    eugeneRules: getEugeneRulesToRender(
      state,
      classicView ? inputCardId : selectedCardId,
      selectedElement.id
    ),
    initialValues: {
      ...selectedElement,
      part: fullPart,
      aminoAcidPart: aminoAcidPart && {
        ...aminoAcidPart,
        start: aminoAcidPart && aminoAcidPart.start,
        end: aminoAcidPart && aminoAcidPart.end,
        aminoAcidSequence
      },
      fas
    },
    //
    isViewClassic: classicView,
    inputCardId,
    partsetParts: selectedElement.partsetId
      ? getPartSetPartsWithPartInfo(state, selectedElement.partsetId).map(
          p => ({
            ...p,
            sequence: sequences[p.sequenceId],
            start: p.start,
            end: p.end,
            partsetId: selectedElement.partsetId,
            isDeactivated: p.isDeactivated
          })
        )
      : []
  };
};

const mapDispatchToProps = {
  updateElement: actions.design.updateElement,
  createElements: actions.design.createElements,
  changeFas: actions.design.changeFas,
  removeEugeneRule: actions.design.removeEugeneRule
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ElementPanel);
