/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import RehydrateAliquotDialog from "../../Dialogs/RehydrateAliquotDialog";
import DehydrateAliquotDialog from "../../Dialogs/DehydrateAliquotDialog";
import pluralIfNeeded from "../../../../../tg-iso-shared/src/utils/pluralIfNeeded";

function DehydrateRehydrateAliquotMenuItem({ aliquot, aliquots: _aliquots }) {
  const aliquots = _aliquots || [aliquot];
  let allDry = true;
  let allWet = true;
  aliquots.some(a => {
    if (!a.isDry) allDry = false;
    if (a.isDry) allWet = false;
    return !allWet && !allDry;
  });
  const hydrate = (
    <MenuItem
      key="hydrate"
      icon="tint"
      text={pluralIfNeeded("Rehydrate Aliquot", aliquots)}
      onClick={() => {
        const aliquotIds = aliquots.filter(a => a.isDry).map(a => a.id);
        showDialog({
          ModalComponent: RehydrateAliquotDialog,
          modalProps: {
            aliquotIds
          }
        });
      }}
    />
  );
  const dehydrate = (
    <MenuItem
      key="dehydrate"
      icon="flash"
      text={pluralIfNeeded("Dehydrate Aliquot", aliquots)}
      onClick={() => {
        const aliquotIds = aliquots.filter(a => !a.isDry).map(a => a.id);
        showDialog({
          ModalComponent: DehydrateAliquotDialog,
          modalProps: {
            aliquotIds
          }
        });
      }}
    />
  );
  if (allDry) {
    return hydrate;
  } else if (allWet) {
    return dehydrate;
  } else {
    return (
      <MenuItem key="hydrateOptions" text="Hydrate">
        {hydrate}
        {dehydrate}
      </MenuItem>
    );
  }
}

export default DehydrateRehydrateAliquotMenuItem;
