/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { tgFormValues } from "@teselagen/ui";
import { BlueprintError } from "@teselagen/ui";
import { Button, Intent, Tooltip } from "@blueprintjs/core";
import { validateTransfers } from "../../../../utils";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import ReactionMapNameField from "../../../ReactMapNameField";

const ReviewReactionPlans = ({
  toolSchema,
  worklist,
  Footer,
  footerProps,
  aliquotContainerTypes = []
}) => {
  const error = validateTransfers(worklist, aliquotContainerTypes);
  return (
    <div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Name Reaction Map"
            helper="This tool will output a reaction map,
              which will connect input and output materials
              for each reaction planned by this tool. Enter
              a name for this assembly reaction map."
          />
          <div style={{ width: 420 }}>
            <ReactionMapNameField toolSchema={toolSchema} />
          </div>
        </div>
      </div>
      <ReviewWorklistSection
        toolSchema={toolSchema}
        schema={worklistTransferSchema}
      />
      <BlueprintError error={error} />
      <Footer
        {...footerProps}
        nextButton={
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            disabled={error}
            loading={footerProps.submitting}
          >
            Generate Worklist
          </Button>
        }
      />
    </div>
  );
};

const worklistTransferSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source Plate",
      path: "sourcePlateName",
      render: v =>
        v || (
          <Tooltip content="This tube is not assigned to a rack">
            Unassigned
          </Tooltip>
        )
    },
    {
      displayName: "Source Position",
      path: "sourceAliquotPosition"
    },
    {
      displayName: "Destination Plate",
      path: "destinationPlateName"
    },
    {
      displayName: "Destination Position",
      path: "destinationAliquotContainerLocation"
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    },
    {
      displayName: "Assembly Piece",
      path: "assemblyPieceName"
    },
    {
      displayName: "Construct",
      path: "constructName"
    }
  ]
};

export default compose(tgFormValues("worklist", "constructDataTablesName"))(
  ReviewReactionPlans
);
