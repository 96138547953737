/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// import shortid from "shortid";
import { cloneDeep, find, get } from "lodash";
import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, upsertAddIds } from "./utils";

const PART_SIZE_ERROR =
  "Part's start and end values are not compatible with the part's source sequence";

function partSizeValidation(newPart, sourceSequence) {
  const { start, end } = newPart;
  const partSequenceSize = sourceSequence?.size;
  const partSequenceCircular = sourceSequence.circular;
  const isInvalid = !partSequenceCircular && end - start > partSequenceSize;
  return isInvalid && PART_SIZE_ERROR;
}

export function checkDuplicatePart(partA, partB) {
  return (
    partA.name === partB.name &&
    partA.start === partB.start &&
    partA.end === partB.end &&
    partA.strand === partB.strand
  );
}

const DNA_PART = async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx
) {
  const { safeQuery } = ctx;
  async function deduplication(dnaParts) {
    const dnaPartRecordIndexToExistingPartId = {};
    for (let index = 0; index < dnaParts.length; index++) {
      const dnaPart = dnaParts[index];

      const sequenceId =
        get(dnaPart, "sourceSequence.id") || get(dnaPart, "sourceSequenceId");

      const existingSequenceParts = cloneDeep(
        await safeQuery(["part", "cid id name start end strand sequenceId"], {
          variables: {
            filter: { sequenceId }
          }
        })
      );

      const matchingPart = find(existingSequenceParts, part =>
        checkDuplicatePart(part, dnaPart)
      );

      if (matchingPart) {
        dnaPartRecordIndexToExistingPartId[dnaPart.id] = matchingPart.id;
        delete matchingPart["__typename"];

        // If there's a matching part already existent in the database,
        // Convert the dnaPart record from being a "create" to an "update"
        // by simpling adding "dummy" __oldRecord and __newRecord keys to equal the matching part.
        Object.assign(dnaParts[index], {
          ...matchingPart,
          __oldRecord: matchingPart,
          __newRecord: matchingPart
        });
      }
    }
    return dnaParts;
  }

  // First process the parts' sequences.
  // Update them accordingly or even create new ones.
  const recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "sourceSequence",
    async sequences => {
      await upsertHandlers.DNA_SEQUENCE(
        {
          ...rest,
          model: "sequence",
          recordsToImport: sequences,
          upsertHandlers
        },
        ctx
      );
    }
  );

  const recordsDedupToContinueUpserting = await deduplication(
    recordsToContinueUpserting
  );

  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsDedupToContinueUpserting,
      precheckFn: _dnaPart => {
        const newPart = _dnaPart.__newRecord;
        const sourceSequence = newPart?.sourceSequence || newPart?.sequence;
        if (newPart && sourceSequence) {
          return partSizeValidation(newPart, sourceSequence);
        }
      },
      precheckWarningsFn: () => {
        // console.log("dnaPart:", dnaPart);
      },
      convertUserFacingToDbModel: _dnaPart => {
        const dnaPart = cloneDeep(_dnaPart);
        const sequenceId =
          get(dnaPart, "sourceSequence.id") ||
          get(dnaPart, "sourceSequenceId") ||
          get(dnaPart, "sequenceId");

        dnaPart["sequenceId"] = sequenceId;

        //strip off the sequence
        delete dnaPart.sourceSequence;
        delete dnaPart.sourceSequenceId;
        delete dnaPart.existingRecord;
        return dnaPart;
      },
      model: "part"
    },
    ctx
  );

  // create new parts
  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport: recordsDedupToContinueUpserting,
      modelOrFragment: "part"
    },
    ctx
  );
};
export default DNA_PART;
