/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { ignoreBatchHeaders } from "./generalUtils";
import { safeQuery } from "../../src-shared/apolloMethods";

export default function safeQueryIgnoreBatch(fragment, options) {
  return safeQuery(fragment, {
    ...options,
    options: ignoreBatchHeaders
  });
}
