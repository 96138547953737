/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DataTable, CollapsibleCard } from "@teselagen/ui";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";

export function SelectionMethodsCard(props) {
  const { strain, selectionMethods, openTitleElements } = props;
  const schema = {
    model: "selectionMethod",
    fields: [
      {
        displayName: "Name",
        path: "name"
      },
      {
        displayName: "Cofactor",
        path: "cofactor"
      },
      dateModifiedColumn
    ]
  };
  return (
    <CollapsibleCard
      title="Selection Methods"
      openTitleElements={openTitleElements}
    >
      <DataTable
        schema={schema}
        formName="selectionMethodsTable"
        isSimple
        entities={
          selectionMethods ||
          strain.strainSelectionMethods.map(
            strainSelectionMethod => strainSelectionMethod.selectionMethod
          )
        }
      />
    </CollapsibleCard>
  );
}
