/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types, flow, applySnapshot } from "mobx-state-tree";
import { newAssayStore } from "./newAssay";
import { formatDateTime } from "../../utils/dateUtils";
import { size, cloneDeep, some, get, every } from "lodash";
import { getActiveLabId } from "@teselagen/auth-utils";
import { getApolloMethods } from "@teselagen/apollo-methods";
import client from "../../apolloClient";

const { safeQuery: query, safeUpsert: upsert } = getApolloMethods(client);
const IMPORT_FILE_SET_IGNORE_CODES = [
  "REFORMATTING-DISCARDED",
  "MAPPING-DISCARDED",
  "INPROGRESS-DISCARDED",
  "ERROR-DISCARDED"
];
const schema = {
  model: "assay",
  fields: [
    { path: "id", displayName: "Assay ID" },
    { path: "name", displayName: "Assay" },
    {
      path: "dataFile",
      displayName: "File",
      columnWidth: 250
    },
    { path: "protocol.name", displayName: "Parser" },
    { path: "updatedAt", displayName: "Updated at" },
    { path: "options", displayName: "Options" }
    // { path: "option", displayName: "Actions", columnWidth: 110 }
  ]
};

const assayStore = types
  .model("assayStore", {
    entities: types.optional(types.array(types.frozen()), []),
    loading: types.optional(types.boolean, false),
    schema: types.optional(types.frozen(), schema),
    newAssay: types.optional(newAssayStore, {}),
    rowSelected: types.optional(types.frozen(), {}),
    totalResults: types.optional(types.number, 1),
    isRemoveEnabled: types.optional(types.boolean, true),
    downloading: types.optional(types.boolean, false)
  })
  .views(self => ({
    get isSubTable() {
      if (size(self.rowSelected)) {
        return true;
      } else {
        return false;
      }
    }
  }))
  .actions(self => {
    const fetchAssay = flow(function* (tableParams) {
      self.loading = true;
      try {
        self.isRemoveEnabled = true;
        self.schema = schema;
        const assays = yield query(
          [
            "assay",
            "id name protocol { id name } labId assayStatusCode assayResultsAttachments { id dataFile { id originalname } importFileSet { id name status } } importFileSets { id name status importFileSetAttachments { id isTabularData dataFile { id originalname } } }"
          ],
          {
            fetchPolicy: "network-only",
            isPlural: true,
            variables: {
              filter: { labId: sessionStorage.getItem("activeLabId") },
              ...tableParams
            }
          }
        );
        // assays = getCompatibleAssays(assays);
        // assays = backwardsCompatibleAssays(assays);
        self.entities = assays;
        self.totalResults = assays.totalResults;
        self.loading = false;
      } catch (error) {
        console.error(error);
      }
    });

    const fetch = flow(function* (tableParams) {
      self.loading = true;
      try {
        self.isRemoveEnabled = true;
        self.schema = schema;
        const assays = yield query(
          [
            "assay",
            "id labId createdAt updatedAt name protocol { id name } labId assayStatusCode importFileSets { id name importFileSetStepCode importFileSetAttachments { id isTabularData dataFile { id originalname } } }"
          ],
          {
            fetchPolicy: "network-only",
            isPlural: true,
            variables: {
              filter: {
                labId: getActiveLabId()
              },
              ...tableParams,
              sort: "-updatedAt"
            }
          }
        );

        self.entities = assays
          .filter(
            // Filter out assays that do not have at least one importFileSet in FINISHED state stepCode
            assay =>
              size(get(assay, "importFileSets")) &&
              some(
                get(assay, "importFileSets"),
                _importFilseSet =>
                  _importFilseSet.importFileSetStepCode === "FINISHED"
              )
          )
          .map(assay => ({
            ...assay,
            // Remove discarded importFileSets from each assay
            importFileSets: get(assay, "importFileSets").filter(
              _importFileSet =>
                every(
                  IMPORT_FILE_SET_IGNORE_CODES,
                  codeToIgnore =>
                    !_importFileSet.importFileSetStepCode.includes(codeToIgnore)
                )
            ),
            createdAt: formatDateTime(assay.createdAt),
            updatedAt: formatDateTime(assay.updatedAt)
          }));
        self.totalResults = assays.totalResults;
        self.loading = false;
      } catch (error) {
        console.error(error);
      }
    });

    const addAssay = flow(function* () {
      try {
        yield upsert("assay", {
          labId: getActiveLabId(),
          name: self.newAssay.name,
          description: self.newAssay.description
        });
        fetch();
        self.newAssay.handleModal();
        return window.toastr.success(self.newAssay.name + " added");
      } catch (error) {
        console.error(error);
      }
    });

    const selectRow = flow(function* (row, tableStore) {
      try {
        self.loading = true;
        self.rowSelected = row;
        yield fetch();
        tableStore && tableStore.init(self.totalResults);
      } catch (error) {
        console.error(error);
      }
    });

    const selectRowById = flow(function* (id) {
      try {
        yield fetch();
        const row = self.entities.filter(entity => entity.id === id);
        self.loading = true;
        self.rowSelected = cloneDeep(row[0]);
        yield fetch();
      } catch (error) {
        console.error(error);
      }
    });

    return {
      addAssay,
      fetch,
      fetchAssay,
      selectRow,
      selectRowById,
      init() {
        applySnapshot(self, {});
        fetch();
      }
    };
  });

export default assayStore;
