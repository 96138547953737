/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isObject, isPlainObject } from "lodash";
import { forEach, transform, isUndefined } from "lodash";

// for usage see https://stackoverflow.com/a/63801243/2178112
const deepObjectMapTransform = (obj, iterator, parentKey) =>
  transform(obj, (acc, val, key) => {
    const retVal = iterator(key, val, { parentKey }); // use the iterator and get a pair of key and value

    if (isPlainObject(retVal)) {
      forEach(retVal, (value, key) => {
        acc[key] = value;
      });
      return;
    }
    if (!retVal) return;

    const [k, v] = retVal;

    if (isUndefined(k)) return; // skip if no updated key
    const newParentKey = k;
    // set the updated key and value, and if the value is an object iterate it as well
    acc[k] = isObject(v)
      ? deepObjectMapTransform(v, iterator, newParentKey)
      : v;
  });

export { deepObjectMapTransform };
