/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment aliquotContainerLocationDisplayFragment on aliquotContainer {
    id
    name
    rowPosition
    columnPosition
    barcode {
      id
      barcodeString
    }
    aliquotContainerType {
      code
      name
    }
  }
`;
