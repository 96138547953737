/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  BlueprintError,
  CheckboxField,
  InputField,
  NumericInputField
} from "@teselagen/ui";
import { compose } from "redux";
import { tgFormValues } from "@teselagen/ui";
import { times } from "lodash";

import { Button } from "@blueprintjs/core";
import {
  AddButton,
  RemoveButton
} from "../../../../src-shared/FieldArrayButtons";
import PlateUploadFields from "../../PlateUploadFields";
import "./style.css";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";

class AddPlateFields extends Component {
  addPlates = values => {
    const { fields } = this.props;
    const {
      numPlates,
      containerArrayType,
      aliquotContainerType,
      platePrefix,
      shouldFillRack,
      numTubesToFillRack,
      generateTubeBarcodes
    } = values;

    times(numPlates, index =>
      fields.push({
        name: `${platePrefix} ${index + 1}`,
        containerArrayType,
        shouldFillRack,
        numTubesToFillRack,
        generateTubeBarcodes,
        ...(!containerArrayType.isPlate && {
          aliquotContainerType
        })
      })
    );
  };

  render() {
    const {
      fields,
      meta: { submitFailed, error },
      isEditing,
      generateBarcodes,
      numPlates,
      handleSubmit
    } = this.props;
    return (
      <React.Fragment>
        <CheckboxField
          name="generateBarcodes"
          label="Generate Barcodes For Destination Plates"
          defaultValue
        />
        {fields.map((field, index) => (
          <div key={`plate.${index}`} className="tg-flex column">
            <hr className="tg-section-break" />
            <InputField
              name={`${field}.name`}
              label="Plate Name"
              placeholder="Enter destination plate name..."
              isRequired
            />
            {!generateBarcodes && (
              <InputField name={`${field}.barcode`} label="Plate Barcode" />
            )}
            <PlateUploadFields inTool noFileUpload fieldPrefix={field + "."} />
            {!isEditing && <RemoveButton fields={fields} index={index} />}
          </div>
        ))}
        {!isEditing &&
          (fields.length > 0 ? (
            <AddButton fields={fields} label="Add Plate" />
          ) : (
            <div>
              <InputField
                name="platePrefix"
                label="Plate Name Prefix"
                isRequired
                generateDefaultValue={{
                  ...defaultValueConstants.PLATE_NAME_PREFIX
                }}
              />
              <NumericInputField
                name="numPlates"
                label="Number of plates to add"
                defaultValue={1}
                min={1}
              />
              <PlateUploadFields inTool noFileUpload />
              <Button
                icon="add"
                intent="primary"
                text={numPlates > 1 ? "Add Plates" : "Add Plate"}
                onClick={handleSubmit(this.addPlates)}
              />
            </div>
          ))}
        {submitFailed && <BlueprintError error={error} />}
      </React.Fragment>
    );
  }
}

export default compose(tgFormValues("generateBarcodes", "numPlates"))(
  AddPlateFields
);
