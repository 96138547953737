/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { invert } from "lodash";

export const modelTypeMap = {
  containerArray: "CONTAINER_ARRAY",
  aliquot: "ALIQUOT",
  aliquotContainer: "ALIQUOT_CONTAINER",
  material: "MATERIAL",
  sequence: "DNA_SEQUENCE",
  part: "DNA_PART",
  selectionMethod: "SELECTION_METHOD",
  sequenceFeature: "SEQUENCE_FEATURE",
  additiveMaterial: "ADDITIVE_MATERIAL",
  reagent: "ADDITIVE_MATERIAL",
  functionalProteinUnit: "FUNCTIONAL_PROTEIN_UNIT",
  aminoAcidSequence: "AMINO_ACID_SEQUENCE",
  sample: "SAMPLE",
  lot: "LOT",
  labProtocol: "LAB_PROTOCOL",
  strain: "STRAIN",
  plateMapGroup: "PLATE_MAP_GROUP",
  plateMap: "PLATE_MAP",
  worklist: "WORKLIST",
  reactionMap: "REACTION_MAP",
  reactionDesign: "REACTION_DESIGN",
  workflowRun: "WORKFLOW_RUN",
  workflowToolDefinition: "WORKFLOW_TOOL_DEFINITION",
  project: "PROJECT",
  dataTable: "DATA_TABLE",
  dataSet: "DATA_SET",
  dataMap: "DATA_MAP",
  vendorOrder: "VENDOR_ORDER",
  j5Report: "J5_REPORT",
  alignment: "ALIGNMENT",
  sequencingQualityControlReport: "SEQUENCING_QUALITY_CONTROL_REPORT",
  worklistContainerArray: "WORKLIST_CONTAINER_ARRAY",
  worklistTransferAliquotContainer: "WORKLIST_TRANSFER_ALIQUOT_CONTAINER",
  dataFile: "DATA_FILE",
  equipmentItem: "EQUIPMENT_ITEM",
  isolationEvent: "ISOLATION_EVENT",
  customerRequest: "CUSTOMER_REQUEST"
};

export const modelTypeToModelMap = {
  ...invert(modelTypeMap),
  ADDITIVE_MATERIAL: "additiveMaterial"
};
