/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { reduxForm, formValueSelector } from "redux-form";
import { DialogFooter, tgFormValues } from "@teselagen/ui";
import {
  InputField,
  CheckboxField,
  NumericInputField,
  ReactSelectField,
  SelectField
} from "@teselagen/ui";
import { isNumber, get, set } from "lodash";

import compositionalOperators, {
  numericOperators
} from "../../../../../tg-iso-design/constants/compositionalOperators";
import { wrapDialog } from "@teselagen/ui";

import "./style.css";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";

class AddEugeneRuleDialog extends React.Component {
  getOperand2Options = () => {
    const { possibleOperands, operand1 } = this.props;
    return possibleOperands
      .filter(({ id }) => id !== operand1.id)
      .filter(element => !element.isEmpty)
      .map(element => ({ label: element.name, value: element.id }));
  };

  onSubmit = values => {
    const {
      initialValues,
      operand1,
      reactionId,
      upsertEugeneRule,
      hideModal,
      isClassicView
    } = this.props;

    const isOperand2Number = numericOperators.includes(
      values.compositionalOperator
    );

    const upsertValues = {
      id: initialValues.id,
      name: values.name,
      operand2Number: isOperand2Number ? values.operand2Number : null,
      negationOperator: values.negationOperator,
      compositionalOperator: values.compositionalOperator,
      reactionId
    };

    Object.assign(
      upsertValues,
      isClassicView
        ? {
            operand1ElementIds: [operand1.id],
            operand2ElementIds: isOperand2Number ? [] : [values.operand2]
          }
        : {
            operand1ElementIds: values.operand1Elements,
            operand2ElementIds: isOperand2Number ? [] : values.operand2Elements
          }
    );
    upsertEugeneRule(upsertValues);

    hideModal();
  };

  renderOperand1InputField = () => {
    const { operand1Inputs } = this.props;
    return (
      <div className="rule-operand-inputs rule-operand1-inputs">
        <div style={{ marginBottom: 5 }}>Operand 1</div>
        <div style={{ display: "flex" }}>
          {operand1Inputs.map((elements, i) => (
            <ReactSelectField
              key={i}
              name={`operand1Elements[${i}]`}
              label=""
              options={arrayToIdOrCodeValuedOptions(elements)}
            />
          ))}
        </div>
      </div>
    );
  };

  renderOperand2InputField = () => {
    const { allOperand2Inputs, operand2CardId, changeFormValue } = this.props;
    return (
      <div className="rule-operand2-inputs">
        <ReactSelectField
          name="operand2CardId"
          label="Operand 2 Card"
          onChange={(__, newValue, oldValue) => {
            if (get(newValue, "id") !== get(oldValue, "id")) {
              changeFormValue("operand2Elements", []);
            }
          }}
          options={arrayToIdOrCodeValuedOptions(allOperand2Inputs)}
        />
        {!!operand2CardId && (
          <div className="rule-operand-inputs rule-operand2-inputs">
            <div style={{ marginBottom: 5 }}>Operand 2</div>
            <div style={{ display: "flex" }}>
              {allOperand2Inputs
                .find(x => x.id === operand2CardId)
                .values.map((elements, i) => (
                  <ReactSelectField
                    key={i}
                    name={`operand2Elements[${i}]`}
                    label=""
                    options={arrayToIdOrCodeValuedOptions(
                      elements.filter(el => !el.isEmpty)
                    )}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      compositionalOperator,
      isClassicView
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="bp3-dialog-body">
          <InputField name="name" label="Name" />

          {isClassicView ? (
            <InputField name="operand1.name" label="Operand 1" readOnly />
          ) : (
            this.renderOperand1InputField()
          )}
          <CheckboxField
            defaultValue={false}
            name="negationOperator"
            label="NOT?"
          />
          <SelectField
            name="compositionalOperator"
            label="Operator"
            options={compositionalOperators}
          />
          {numericOperators.includes(compositionalOperator) ? (
            <NumericInputField
              name="operand2Number"
              label="Operand 2"
              min={0}
            />
          ) : isClassicView ? (
            <ReactSelectField
              name="operand2"
              label="Operand 2"
              options={this.getOperand2Options()}
            />
          ) : (
            this.renderOperand2InputField()
          )}
        </div>
        <DialogFooter text="OK" hideModal={hideModal} />
      </form>
    );
  }
}

const selector = formValueSelector("addEugeneRuleDialogForm");

const validate = (
  values,
  {
    existingNamesMap,
    initialValues,
    isClassicView,
    operand1Inputs,
    allOperand2Inputs
  }
) => {
  const errors = {};
  const requiredProperties = ["name", "compositionalOperator"];
  if (isClassicView) requiredProperties.push("operand1");
  else {
    for (let i = 0, ii = operand1Inputs.length; i < ii; i++) {
      if (!get(values, `operand1Elements[${i}]`))
        set(errors, `operand1Elements[${i}]`, "Required");
    }
  }
  if (numericOperators.includes(values.compositionalOperator)) {
    if (!isNumber(values.operand2Number) || values.operand2Number < 0)
      errors.operand2Number = "Must be non-negative number.";
  } else {
    if (isClassicView) requiredProperties.push("operand2");
    else {
      const { operand2CardId } = values;
      if (!operand2CardId) errors.operand2CardId = "Required";
      else {
        for (
          let i = 0,
            ii = allOperand2Inputs.find(x => x.id === operand2CardId).values
              .length;
          i < ii;
          i++
        ) {
          if (!get(values, `operand2Elements[${i}]`))
            set(errors, `operand2Elements[${i}]`, "Required");
        }
      }
    }
  }

  if (values.name !== initialValues.name && existingNamesMap[values.name])
    errors.name = "A rule with that name already exists in this operation.";
  if (values.name && values.name.indexOf(" ") >= 0) {
    errors.name = "A rule name can't contain whitespaces, place remove them.";
  }

  requiredProperties.forEach(property => {
    if (!values[property]) errors[property] = "Required";
  });

  return errors;
};

export default compose(
  wrapDialog(props => ({
    title: props.isEdit ? "Edit Eugene Rule" : "Add Eugene Rule",
    style: { minWidth: 550 }
  })),
  connect(state => ({
    compositionalOperator: selector(state, "compositionalOperator")
  })),
  reduxForm({
    form: "addEugeneRuleDialogForm", // a unique name for this form
    enableReinitialize: true,
    validate
  }),
  tgFormValues("operand2CardId")
)(AddEugeneRuleDialog);
