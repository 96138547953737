/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isArray } from "lodash";
import gql from "graphql-tag";
import { isoContext } from "@teselagen/utils";

export const featureTypeOverrideFragment = gql`
  fragment featureTypeOverrideFragment on featureTypeOverride {
    id
    name
    description
    color
    genbankEquivalentType
    updatedAt
    isHidden
  }
`;

export async function updateFeatureTypeOverrides(ctx = isoContext) {
  const { safeQuery } = ctx;
  try {
    const ftos = await safeQuery(featureTypeOverrideFragment, {
      variables: {
        pageSize: 999999
      }
    });
    if (ftos && isArray(ftos)) {
      if (global) {
        global.tg_featureTypeOverrides = ftos;
      } else {
        window.tg_featureTypeOverrides = ftos;
      }
    }
    return true;
  } catch (error) {
    console.error(`error updating feature type overrides`, error);
  }
}
