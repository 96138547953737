/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get, map, capitalize, isEmpty } from "lodash";
import {
  FileUploadField,
  Loading,
  DataTable,
  BlueprintError,
  SwitchField,
  ReactSelectField
} from "@teselagen/ui";

import { compose } from "recompose";
import { Button, Tooltip, Icon } from "@blueprintjs/core";
import pluralize from "pluralize";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  dataTableHeaderMap,
  getDuplicateBarcodeHelper
} from "../../../../utils";
import { cleanPosition } from "../../../../utils/plateUtils";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import {
  standardizeVolume,
  volumeRender
} from "../../../../../src-shared/utils/unitUtils";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { allowedCsvFileTypes } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";

export const dataTableFragment = [
  "dataTable",
  `id
name
dataRows {
  id
  index
  key
  rowValues
}`
];

const tableFilter = (props, qb) => {
  qb.whereAll({
    dataTableTypeCode: "POOLING_LIST"
  });
};

class UploadPoolingSchema extends Component {
  state = {
    loading: false
  };

  parseSchemaFile = async (files, onChange) => {
    const {
      stepFormProps: { change }
    } = this.props;
    try {
      this.loadedFullData = false;
      const schemaFile = files[0];
      onChange([
        {
          ...schemaFile,
          loading: false
        }
      ]);

      const { parsedData } = schemaFile;

      const cleanedData = [];
      parsedData.forEach(row => {
        cleanedData.push({ ...row, id: cleanedData.length + 1 });
      });

      change("partialPoolingData", cleanedData);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(error.message || "Error parsing file");
    }
    // so that file is not uploaded
    return false;
  };

  componentDidMount() {
    this.componentDidMountOrUpdate();
  }

  componentDidUpdate() {
    this.componentDidMountOrUpdate();
  }

  async componentDidMountOrUpdate() {
    const {
      partialPoolingData,
      stepFormProps: { change }
    } = this.props;

    if (partialPoolingData && !this.loadedFullData) {
      this.loadedFullData = true;
      this.setState({
        loading: true
      });
      try {
        const tubeBarcodes = [];
        const plateBarcodes = [];
        partialPoolingData.forEach(data => {
          if (data.barcode && !data.position) {
            tubeBarcodes.push(data.barcode);
            plateBarcodes.push(data.barcode);
          } else if (data.barcode) {
            plateBarcodes.push(data.barcode);
          }
        });
        const aliquotFragment = `aliquot {
          id
          isDry
          volume
          volumetricUnitCode
          sample {
            id
            materialId
          }
        }`;
        const tubes = await safeQuery(
          [
            "aliquotContainer",
            `id
          name
          barcode { id barcodeString }
          ${aliquotFragment}`
          ],
          {
            variables: {
              filter: {
                "barcode.barcodeString": tubeBarcodes
              }
            }
          }
        );
        const plates = await safeQuery(
          [
            "containerArray",
            `id name
            barcode { id barcodeString }
            aliquotContainers {
              id
              rowPosition
              columnPosition
              ${aliquotFragment}
            }`
          ],
          {
            variables: {
              filter: {
                "barcode.barcodeString": plateBarcodes
              }
            }
          }
        );
        change("fetchedPlates", plates);
        change("fetchedTubes", tubes);
        const duplicatePlates = getDuplicateBarcodeHelper(plates);
        const duplicateTubes = getDuplicateBarcodeHelper(tubes);
        change("duplicatePlates", duplicatePlates);
        change("duplicateTubes", duplicateTubes);
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error validating pooling schema");
      }
      this.setState({
        loading: false
      });
    }
  }

  getFullPoolingData = ({ plates = [], tubes = [] } = {}, callChange) => {
    const {
      partialPoolingData: _partialPoolingData,
      stepFormProps: { change },
      duplicatePlates = {},
      resolvedBarcodes = {
        tubes: {},
        plates: {}
      },
      duplicateTubes = {}
    } = this.props;
    const partialPoolingData = _partialPoolingData || [];

    const getKeyedItems = (items, name) => {
      const keyedItems = {};
      items.forEach(item => {
        const barcode = item.barcode.barcodeString;
        if (resolvedBarcodes[name][barcode]) {
          keyedItems[barcode] = resolvedBarcodes[name][barcode];
        } else {
          keyedItems[barcode] = item;
        }
      });
      return keyedItems;
    };
    const keyedTubes = getKeyedItems(tubes, "tubes");
    const keyedPlates = getKeyedItems(plates, "plates");

    const plateBarcodeAcHelper = {};
    Object.values(keyedPlates).forEach(p => {
      plateBarcodeAcHelper[p.barcode.barcodeString] = {};
      p.aliquotContainers.forEach(ac => {
        plateBarcodeAcHelper[p.barcode.barcodeString][
          getAliquotContainerLocation(ac)
        ] = ac;
      });
    });

    const sampleNameToProteinPattern = {};
    const newPoolingData = partialPoolingData.map(record => {
      let error;
      let warning;
      const isTube = !record.position;
      const newRecord = { ...record };
      newRecord.position = cleanPosition(record.position);
      newRecord.isTube = isTube;

      if (!sampleNameToProteinPattern[record.pooledSampleName]) {
        sampleNameToProteinPattern[record.pooledSampleName] =
          record.proteinPattern;
      }

      if (
        isTube &&
        duplicateTubes[record.barcode] &&
        !resolvedBarcodes.tubes[record.barcode]
      ) {
        error =
          "This barcode matches multiple tubes in inventory. Please resolve.";
      } else if (
        !isTube &&
        duplicatePlates[record.barcode] &&
        !resolvedBarcodes.plates[record.barcode]
      ) {
        error =
          "This barcode matches multiple plates in inventory. Please resolve.";
      } else if (!isTube && !keyedPlates[record.barcode]) {
        error = "This plate was not found in inventory.";
      } else if (isTube && !keyedTubes[record.barcode]) {
        if (keyedPlates[record.barcode]) {
          error =
            "A plate with this barcode was found in inventory but no position was provided. Please provide a position for this row.";
        } else {
          error = "This tube was not found in inventory.";
        }
      } else if (
        newRecord.volumetricUnitCode &&
        !unitGlobals.volumetricUnits[newRecord.volumetricUnitCode]
      ) {
        error = `This volume unit ${newRecord.volumetricUnitCode} does not match an existing unit.`;
      } else {
        let aliquotContainer;
        if (isTube) {
          aliquotContainer = keyedTubes[newRecord.barcode];
        } else {
          aliquotContainer =
            plateBarcodeAcHelper[newRecord.barcode][newRecord.position];
        }
        const aliquot = aliquotContainer && aliquotContainer.aliquot;
        newRecord.aliquotContainer = aliquotContainer;

        if (!aliquot) {
          error = `No aliquot found in ${isTube ? "tube" : "plate well"}.`;
        } else if (aliquot.isDry) {
          error = `Aliquot found in ${isTube ? "tube" : "plate well"} is dry.`;
        } else if (
          record.volume &&
          standardizeVolume(record.volume, record.volumetricUnitCode) >
            standardizeVolume(aliquot.volume, aliquot.volumetricUnitCode)
        ) {
          error = "Aliquot does not have enough volume for transfer.";
        } else if (!get(aliquot, "sample.materialId")) {
          warning = "Aliquot is not linked to a material";
        }
      }

      if (
        !error &&
        sampleNameToProteinPattern[record.pooledSampleName] !==
          record.proteinPattern
      ) {
        error = "This sample name is mapped to multiple protein patterns.";
      }

      if (error) {
        newRecord.error = error;
      } else if (warning) {
        newRecord.warning = warning;
      }

      return newRecord;
    });

    if (callChange) {
      change("poolingData", newPoolingData);
      change("keyedTubes", keyedTubes);
      change("keyedPlates", keyedPlates);
      change("plateBarcodeAcHelper", plateBarcodeAcHelper);
    }
    return newPoolingData;
  };

  onSelectTable = dataTable => {
    const {
      stepFormProps: { change }
    } = this.props;
    const newPoolingData = dataTable.dataRows.map((r, i) => ({
      ...r.rowValues,
      id: i + 1
    }));
    change("partialPoolingData", newPoolingData);
  };

  clearPoolingData = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    this.loadedFullData = false;
    change("poolingSchemaFile", []);
    change("resolvedBarcodes", {
      plates: {},
      tubes: {}
    });
    change("duplicatePlates", {});
    change("duplicateTubes", {});
    change("poolingData", null);
    change("partialPoolingData", null);
    change("poolingSchemaTable", null);
  };

  updateData = () => {
    const { nextStep, fetchedPlates = [], fetchedTubes = [] } = this.props;
    this.getFullPoolingData(
      {
        plates: fetchedPlates,
        tubes: fetchedTubes
      },
      true
    );
    nextStep();
  };

  renderDuplicateChoices = () => {
    const { duplicatePlates = {}, duplicateTubes = {} } = this.props;

    if (isEmpty(duplicatePlates) && isEmpty(duplicateTubes)) return null;

    const getComp = name => {
      const items = name === "plate" ? duplicatePlates : duplicateTubes;
      if (isEmpty(items)) return;
      return (
        <div
          className="tg-flex justify-space-between"
          style={{ marginTop: 15 }}
        >
          <HeaderWithHelper
            header={`Resolve Duplicates ${capitalize(pluralize(name))}`}
            helper={`The following barcodes matched more than one ${name} in inventory. Please choose the one you would like to use.`}
          />
          <div>
            {map(items, (options, barcode) => {
              return (
                <ReactSelectField
                  key={barcode}
                  options={arrayToItemValuedOptions(options)}
                  name={`resolvedBarcodes.${pluralize(name)}.` + barcode}
                  label={barcode}
                />
              );
            })}
          </div>
        </div>
      );
    };
    return (
      <React.Fragment>
        {getComp("plate")}
        {getComp("tube")}
      </React.Fragment>
    );
  };

  render() {
    const {
      Footer,
      isUpload,
      footerProps,
      fetchedPlates = [],
      fetchedTubes = [],
      handleSubmit,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;
    const poolingData = this.getFullPoolingData({
      plates: fetchedPlates,
      tubes: fetchedTubes
    });
    const hasPoolingDataError = poolingData.some(r => r.error);
    let clearButton, table, uploadOptions;
    if (!!poolingData.length && !this.state.loading) {
      clearButton = (
        <div
          style={{
            marginBottom: 10,
            marginRight: 20,
            alignSelf: "flex-end"
          }}
        >
          <Button
            onClick={this.clearPoolingData}
            intent="danger"
            text="Clear Table"
          />
        </div>
      );

      table = (
        <React.Fragment>
          <DataTable
            isSimple
            formName="poolingSchemaDisplayTable"
            maxHeight={300}
            noSelect
            schema={[
              {
                type: "action",
                width: 35,
                render: (_, record) => {
                  if (record.error || record.warning) {
                    return (
                      <Tooltip content={record.error || record.warning}>
                        <Icon
                          intent={record.error ? "danger" : "warning"}
                          style={{ marginRight: 10 }}
                          icon="warning-sign"
                        />
                      </Tooltip>
                    );
                  }
                }
              },
              {
                path: "barcode",
                displayName: "Barcode"
              },
              {
                path: "position",
                displayName: "Position",
                render: v => v || "N/A"
              },
              "pooledSampleName",
              "proteinPattern",
              {
                displayName: "Volume",
                path: "volume",
                render: (...args) => {
                  const v = volumeRender(...args);
                  if (v !== "N/A") return v;
                }
              }
            ]}
            entities={poolingData}
          />
          {hasPoolingDataError && (
            <BlueprintError error="Please fix errors in pooling data." />
          )}
        </React.Fragment>
      );
    }

    if (!poolingData.length && !this.state.loading) {
      uploadOptions = (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
          }}
        >
          <SwitchField name="isUpload" label="Upload Pool Schema" />

          <div className="tg-flex align-center">
            {isUpload ? (
              <div>
                <FileUploadField
                  fileLimit={1}
                  accept={getDownloadTemplateFileHelpers({
                    type: allowedCsvFileTypes,
                    fileName: "pooling_schema",
                    validateAgainstSchema: {
                      fields: dataTableHeaderMap["POOLING_LIST"]
                    }
                  })}
                  name="poolingSchemaFile"
                  beforeUpload={this.parseSchemaFile}
                />
              </div>
            ) : (
              <GenericSelect
                additionalDataFragment={dataTableFragment}
                buttonProps={{
                  disabled: isDisabledMap.dataTable,
                  loading: isLoadingMap.dataTable
                }}
                fragment={["dataTable", "id name updatedAt"]}
                name="poolingSchemaTable" //the field name within the redux form Field
                onSelect={this.onSelectTable}
                tableParamOptions={{
                  additionalFilter: tableFilter
                }}
                schema={["name", dateModifiedColumn]}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Pooling Schema"
              helper="Select or upload a pooling schema specifying which samples will be pooled together."
            />
            {clearButton}
            {uploadOptions}
          </div>
          {table}
          {this.renderDuplicateChoices()}
          {this.state.loading && <Loading bounce />}
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent="primary"
              disabled={!poolingData.length || hasPoolingDataError}
              onClick={handleSubmit(this.updateData)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "poolingSchemaFile",
    "poolingSchemaTable",
    "isUpload",
    "fetchedPlates",
    "fetchedTubes",
    "partialPoolingData",
    "duplicatePlates",
    "duplicateTubes",
    "resolvedBarcodes"
  )
)(UploadPoolingSchema);
