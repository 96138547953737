/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import "./style.css";

const WorkflowRuns = props => {
  const { refetchWorkflowRuns } = props;

  return (
    <AbstractLibrary
      {...props}
      withExport
      onNewItemClick={() => {
        showDialog({
          modalType: "LAUNCH_NEW_WORKFLOW_DIALOG",
          modalProps: { refetch: refetchWorkflowRuns }
        });
      }}
    />
  );
};

const schema = {
  model: "workflowRun",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "priorityType.name", type: "string", displayName: "Priority" },
    { path: "dueDate", type: "timestamp", displayName: "Due Date" },
    {
      path: "workflowRunStatusType.name",
      type: "string",
      displayName: "Status"
    },
    {
      path: "workflowDefinition.name",
      type: "string",
      displayName: "Workflow Definition"
    },
    {
      path: "job.name",
      type: "string",
      displayName: "Job"
    },
    {
      type: "string",
      displayName: "Asset ID",
      render: (v, workflowRun) => {
        return "TWR" + `${workflowRun.nid}`.padStart(3, "0");
      }
    }
  ]
};

const fragment = gql`
  fragment workflowRunLibraryFragment on workflowRun {
    id
    nid
    dueDate
    name
    notes
    workflowDefinition {
      id
      name
    }
    job {
      id
      name
    }
    workflowRunStatusType {
      code
      name
      description
    }
    priorityType {
      code
      color
      level
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(WorkflowRuns);
