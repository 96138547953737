/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// Copyright (C) 2018 TeselaGen Biotechnology, Inc.

import { compose } from "redux";
import React from "react";

import StepForm from "../../../../src-shared/StepForm";
import SelectData from "./Steps/SelectData";
import ToolConfiguration from "./Steps/ToolConfiguration";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { isInteger } from "lodash";
import { tuplingToolPlateFragment } from "./fragments";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class SequenceTuplingTool extends React.Component {
  // getStandAloneTuplingToolParams = formValues => {
  //   return {
  //     mode: formValues.mode,
  //     jobName: formValues.jobName,
  //     // jobDescription: formValues.jobDescription,
  //     records: formValues.tuplingData.map((record, idx) => ({
  //       id: idx,
  //       name: record.sampleName,
  //       sequence: record.referenceSequence,
  //       annotations: record.sequenceAnnotations,
  //       circular: record.circular,
  //       ignored: record.ignored
  //     })),
  //     max_bps_overlap: formValues.max_bps_overlap,
  //     tuple_max_length: formValues.tuple_max_length,
  //     excludedAnnotationTags: formValues.excludedAnnotationTags,
  //     exclusiveAnnotationTags: formValues.exclusiveAnnotationTags
  //   };
  // };

  getWorkflowTuplingToolParams = values => {
    return {
      mode: values.mode,
      jobName: values.jobName,
      records: values.tuplingData.map(record => ({
        id: record.aliquotId,
        name: record.sampleName,
        ignored: record.ignored
      })),
      max_bps_overlap: values.maxBPOverlap,
      tuple_max_length: values.tupleMaxLength,
      excluded_annotation_tags: values.excludedAnnotationTags,
      exclusive_annotation_tags: values.exclusiveAnnotationTags
    };
  };

  onSubmit = async values => {
    const tuplingToolParams = this.getWorkflowTuplingToolParams(values);
    const [tuplingWorkflowDataTable] = await safeUpsert("dataTable", {
      dataTableTypeCode: "POOLING_LIST",
      name: tuplingToolParams.jobName
    });

    tuplingToolParams.mode = "workflow";
    tuplingToolParams.tuplingWorkflowDataTableId = tuplingWorkflowDataTable.id;

    return window.serverApi
      .request({
        method: "post",
        url: "/evolve-routes/tupling-tool",
        withCredentials: true,
        data: { tuplingToolParams }
      })
      .then(output => {
        const microserviceQueueId = output.data.data.id;
        return {
          // add the microservice queue id to the ioItem on the datatable (on dataItem)? So do another upsert here...
          dataTable: {
            id: tuplingWorkflowDataTable.id,
            microserviceQueueId,
            __typename: "dataTable"
          }
        };
      })
      .catch(error => {
        console.error("error", error);
        return {};
      });
  };

  validate = values => {
    const { maxBPOverlap, tupleMaxLength } = values;
    const errors = {};
    if (
      maxBPOverlap &&
      (isNaN(Number(maxBPOverlap)) ||
        Number(maxBPOverlap) < 1 ||
        !isInteger(maxBPOverlap))
    ) {
      errors.maxBPOverlap = "Please enter a positive integer.";
    }
    if (
      tupleMaxLength &&
      (isNaN(Number(tupleMaxLength)) ||
        Number(tupleMaxLength) < 1 ||
        !isInteger(tupleMaxLength))
    ) {
      errors.tupleMaxLength = "Please enter a positive integer.";
    }
    return errors;
  };

  render() {
    const {
      toolSchema,
      initialValues,
      toolIntegrationProps = {},
      isToolIntegrated
    } = this.props;
    const steps = [
      {
        title: "Select Samples",
        Component: SelectData,
        props: {
          isToolIntegrated,
          toolSchema
        },
        withCustomFooter: true
      },
      {
        title: "Configuration & Submission",
        Component: ToolConfiguration,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        steps={steps}
        validate={this.validate}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(withWorkflowInputs(tuplingToolPlateFragment))(
  SequenceTuplingTool
);
