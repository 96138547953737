/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import { sumBigs } from "../bigIntUtils";
import { convertMassBig } from "./massUtils";
import { convertVolumeBig } from "./volumeUtils";
import {
  convertConcentrationBig,
  defaultMolarityUnitCode
} from "./concentrationUtils";
import { has } from "lodash";

/**
 * Check whether `x` is null or undefined. This is the best short
 * name I could think of for this function.
 * @param {*} x
 * @returns {boolean}
 */
export function isNullish(x) {
  return x === null || x === undefined;
}

/**
 * See if the given array contains at least one value of null.
 * @param {Array<*>} a Array to check,
 */
export function containsNull(a) {
  return a.some(x => x === null);
}

/**
 * Check whether we consider the item to be dry or not.
 * @param {object} item
 * @param {boolean} explicitDryness Do we check for `isDry` field or infer the items's dryness.
 * @returns {boolean}
 */
export function isDry(item, explicitDryness) {
  if (explicitDryness) {
    return !!item.isDry;
  } else {
    return !item.volume;
  }
}

/**
 * Get the mass of the items in grams. Return null if we cannot compute the mass.
 * @param {object} item
 * @param {boolean} explicitDryness Do we check for `isDry` field or infer the items's dryness.
 * @returns {number} Mass in grams.
 */
export function getMassInGrams(item, explicitDryness) {
  if (isDry(item, explicitDryness)) {
    if (isNullish(item.mass)) return null;
    else return convertMassBig(item.mass, item.massUnitCode, "g");
  } else {
    // If any of these conditions are true, we cannot compute the mass.
    if (isNullish(item.volume)) return null;
    else if (item.volume === 0 && isNullish(item.concentration)) return null;
    else if (isNullish(item.concentration) && isNullish(item.mass)) return null;

    if (isNullish(item.concentration)) {
      return convertMassBig(item.mass, item.massUnitCode, "g");
    } else {
      return convertVolumeBig(item.volume, item.volumetricUnitCode, "L").times(
        convertConcentrationBig(
          item.concentration,
          item.concentrationUnitCode,
          "g/L"
        )
      );
    }
  }
}

/**
 * Get the total volume of an item in liters. Returns null if we cannot compute the volume.
 * @param {object} item
 * @returns {number} Volume in liters.
 */
export function getVolumeInLiters(item, explicitDryness) {
  if (isDry(item, explicitDryness)) {
    return new Big(0);
  } else {
    if (isNullish(item.volume)) return null;
    return convertVolumeBig(item.volume, item.volumetricUnitCode, "L");
  }
}

/**
 * Given an array of objects representing the dimensions of the constituents of a mixture, return an object
 * giving the dimensions of the mixture.
 *
 * The objects in the `mixture` input and the return value will have the shape:
 *    - `volume`
 *    - `volumetricUnitCode`
 *    - `concentration`
 *    - `concentrationUnitCode`
 *    - `mass`
 *    - `massUnitCode`
 *    - `isDry` (optional, will not be in the returned value)
 *
 * If `explicitDryness` is set to true, then items in the mixture are considered dry if and only if they have an
 * `isDry field set to true. Otherwise we have to infer whether the item is dry or not. If volume is zero or null,
 * then we will assume that the item is dry.
 *
 * We assume dry items have a volume of 0 and ignore the concentration. For wet aliquots, we will first look at the
 * concentration. If one is not explicitly set, we will attempt to calculate it based on the mass on volume.
 *
 * If any dry item has a null or undefined mass or if any wet item has either null or undefined concentration and mass,
 * zero volume and null or undefined concentration, or null or undefined volume then we return null for all of the dimensions.
 *
 * To handle diluents, please provide a wet item with zero concentration.
 *
 * @param {Object} arg
 * @param {Array<Object>} arg.mixture Array of objects representing elements in the mixture.
 * @param {string} arg.concentrationUnitCode The concentration unit code the output will be expressed in.
 * @param {string} arg.volumetricUnitCode The volumetric unit code the output will be expressed in.
 * @param {string} arg.massUnitCode The mass unit code the output will be expressed in.
 * @param {boolean} arg.explicitDryness If try, items in the `mixture` array will be considered dry if and only if they have an explicit `isDry` field set to true.
 * @returns {Object}
 */
function getDimensionsOfMixture({
  mixture,
  concentrationUnitCode,
  volumetricUnitCode,
  massUnitCode,
  explicitDryness = false
}) {
  // If there are no items in the mixture, then we cannot compute its dimensions.
  if (!mixture.length) return getFailedReturnValue();
  const masses = mixture.map(item => getMassInGrams(item, explicitDryness));
  const volumes = mixture.map(item => getVolumeInLiters(item, explicitDryness));

  // If any mass or item is null, that means that at least one item in the input
  // did not contain a permissable combination of fields.
  const nullMasses = containsNull(masses);
  const nullVolumes = containsNull(volumes);
  const successVolume =
    !nullVolumes &&
    volumetricUnitCode &&
    convertVolumeBig(sumBigs(volumes), "L", volumetricUnitCode);
  const successMass =
    !nullMasses &&
    massUnitCode &&
    convertMassBig(sumBigs(masses), "g", massUnitCode);
  let failedDims;
  if (nullMasses || nullVolumes) {
    const dims = getFailedReturnValue();
    if (!nullVolumes) dims.volume = successVolume;
    if (!nullMasses) dims.mass = successMass;
    failedDims = dims;
  }

  if (
    mixture[0].__typename === "additive" &&
    mixture.every(m => m.__typename === "additive")
  ) {
    const getFirstMixtureField = type => {
      // we block changing lot molarity so all molarities of mixture should be identical
      if (has(mixture[0], type)) {
        return {
          [type]: mixture[0][type] || null,
          [type + "UnitCode"]: mixture[0][type + "UnitCode"]
        };
      }
    };
    return {
      volume: failedDims ? failedDims.volume : successVolume,
      volumetricUnitCode,
      mass: failedDims ? failedDims.mass : successMass,
      massUnitCode,
      ...getFirstMixtureField("concentration"),
      ...getFirstMixtureField("molarity"),
      ...getFirstMixtureField("materialConcentration")
    };
  }

  if (failedDims) return failedDims;

  const mass = sumBigs(masses);
  const volume = sumBigs(volumes);
  const concentration = volume.gt(0) ? mass.div(volume) : null;

  return {
    volume: successVolume,
    volumetricUnitCode,
    concentration:
      concentration &&
      concentrationUnitCode &&
      Number(
        convertConcentrationBig(concentration, "g/L", concentrationUnitCode)
      ),
    concentrationUnitCode,
    mass: successMass,
    massUnitCode
  };

  /**
   * Value to return when we cannot compute the dimension.
   */
  function getFailedReturnValue() {
    return {
      volume: null,
      volumetricUnitCode,
      concentration: null,
      concentrationUnitCode,
      mass: null,
      massUnitCode,
      ...(has(mixture[0], "molarity") && {
        molarity: null,
        molarityUnitCode: defaultMolarityUnitCode
      })
    };
  }
}

export default getDimensionsOfMixture;
