/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { invert } from "lodash";
import { externalReferenceKeys } from "../../tg-iso-shared/constants";

export const TYPES = {
  aminoAcidPart: true,
  aminoAcidSequence: true,
  assemblyMethod: true,
  bin: true,
  binCard: true,
  binRuleSet: true,
  card: true,
  disabledDesignRow: true,
  constructAnnotation: true,
  customJ5Parameter: true,
  design: true,
  element: true,
  elementCombo: true,
  elementElementCombo: true,
  elementGroupCombo: true,
  elementGroupElementGroupCombo: true,
  eugeneRule: true,
  eugeneRuleElement: true,
  fas: true,
  icon: true,
  j5OutputNamingTemplate: true,
  junction: true,
  part: true,
  taggedItem: true,
  partset: true,
  partsetPart: true,
  reaction: true,
  reactionJ5OutputNamingTemplate: true,
  restrictionEnzyme: true,
  rule: true,
  ruleSet: true,
  sequence: true,
  designMaterialAvailabilityView: true,
  sequenceFeature: true,
  sequenceFragment: true,
  tag: true,
  tagOption: true,
  tagRule: true
};

// NOTE: Local fields mean fields that don't reference
// another object. For example, `name` should be a local
// field. Something like `childSets` would not be a local
// field.
export const LOCAL_FIELDS = {
  design: [
    "id",
    "userId",
    "labId",
    "name",
    "description",
    "type",
    "originalDesignId",
    "isLocked",
    "disableSameBinDigestValidation",
    "lockedMessage",
    "lockMsgDescription",
    "lockTypeCode",
    "boundaryAnalysisType",
    "numPlaceholders",
    "layoutType",
    "numRows",
    "doNotReuseOligos"
  ],
  disabledDesignRow: ["id", "rowIndex"],
  card: [
    "id",
    "isRoot",
    "circular",
    "name",
    "inputIndex",
    "dsf",
    "hasAvailabilityInfo",
    "allConstructsAvailable",
    "lastCheckedAvailability"
  ],
  bin: [
    "id",
    "direction",
    "extra3PrimeBps",
    "extra5PrimeBps",
    "fro",
    "invalidityMessage",
    "isLocked",
    "isPlaceholder",
    "name"
  ],
  reaction: ["id", "name"],
  element: [
    "id",
    "cid",
    "name",
    "index",
    "isEmpty",
    "isAssemblyPiece",
    "apLeftDominant",
    "apRightDominant",
    "bps",
    "preferred3PrimeOverhangs",
    "preferred5PrimeOverhangs",
    "invalidityMessage",
    "warningMessage",
    "extraStartSequence",
    "extraEndSequence",
    "notes"
  ],
  fas: ["id", "name"],
  eugeneRule: [
    "id",
    "compositionalOperator",
    "name",
    "negationOperator",
    "operand2Number"
  ],
  j5OutputNamingTemplate: ["id", "outputTarget", "template"],
  icon: ["id", "isSbol", "name", "path", "cid"],
  part: [
    "id",
    "name",
    "start",
    "strand",
    // Looks like these preferred overhang columns aren't being used.
    "preferred3PrimeOverhangs",
    "preferred5PrimeOverhangs",
    "end",
    // The following are digestPart props.
    "isDigestPart",
    "isDigestValid",
    "re5PrimeOverhang",
    "re5PrimeOverhangStrand",
    "re5PrimeRecognitionTypeCode",
    "re3PrimeOverhang",
    "re3PrimeOverhangStrand",
    "re3PrimeRecognitionTypeCode"
  ],
  sequence: [
    "id",
    "name",
    "description",
    "circular",
    "sequence",
    "size",
    "sequenceTypeCode",
    ...externalReferenceKeys
  ],
  designMaterialAvailabilityView: ["id", "isAvailable"],
  sequenceFeature: ["id", "name", "start", "end", "strand", "type"],
  sequenceFragment: ["id", "index", "fragment"],
  assemblyMethod: ["id", "cid", "name"],
  customJ5Parameter: [
    "id",
    "cid",
    "name",
    "isLocalToThisDesignId",
    "dirSynthCostPerBp",
    "dirSynthMinCostPerPiece",
    "ggateOverhangBps",
    "ggateRecognitionSeq",
    "ggateTerminiExtraSeq",
    "ggateTerminiExtraSeqAlt",
    "gibsonOverlapBps",
    "gibsonOverlapMaxTm",
    "gibsonOverlapMinTm",
    "hmlgyMaxFractionMismatches",
    "hmlgyMinLengthBps",
    "includeLineageFeatures",
    "masterOligoNumOfDigits",
    "masterPlasmidNumOfDigits",
    "maxIdsGgateOverhangsCmptbl",
    "maxOligoLengthBps",
    "minDnaSynthLengthBps",
    "maxDnaSynthLengthBps",
    "minFragmentSizeGibsonBps",
    "minPcrProductBps",
    "mispriming3PBpToWarnIfHit",
    "misprimingMinTm",
    "misprimingOligoConc",
    "misprimingSaltConc",
    "oligoMaxLenNoPagePurBps",
    "oligoPagePurCostPerPiece",
    "oligoSynthesisCostPerBp",
    "outputSeqFormat",
    "primerDnaConc",
    "primerGcClamp",
    "primerMaxDiffTm",
    "primerMaxSelfAnyTh",
    "primerMaxSelfEndTh",
    "primerMaxSize",
    "primerMaxTm",
    "primerMinSize",
    "primerMinTm",
    "primerPairMaxComplAnyTh",
    "primerPairMaxComplEndTh",
    "primerSaltCorrections",
    "primerTmSantaLucia",
    "suppressPrimerAnnotations",
    "suppressPurePrimers",
    "gatewayAttSites"
  ],
  restrictionEnzyme: [
    "id",
    "name",
    "sequence",
    "forwardSnipPosition",
    "reverseSnipPosition",
    "recognitionRegex",
    "enzymeTypeCode",
    "reverseRecognitionRegex"
  ],
  binRuleSet: ["id"],
  reactionJ5OutputNamingTemplate: ["id"],
  taggedItem: ["id"],
  tag: ["id", "name", "description", "color"],
  tagOption: ["id", "name", "description", "color"],
  ruleSet: ["id", "name", "description"],
  rule: ["id", "name", "regex", "type"],
  tagRule: ["id", "existence"],
  eugeneRuleElement: ["id", "index"],
  elementCombo: ["id"],
  elementElementCombo: ["id", "index"],
  elementGroupCombo: ["id", "isDeleted", "cardId"],
  elementGroupElementGroupCombo: ["id", "index"],
  binCard: ["id", "index", "rightJunctionBps", "hasSpecifiedJunction"],
  junction: [
    "id",
    "bps",
    "relativePosition",
    "isPhantom",
    "junctionTypeCode",
    "threePrimeCardOverhangTypeCode",
    "fivePrimeCardOverhangTypeCode",
    "fivePrimeCardFormsOverhang"
  ],
  partset: ["id", "name", "numParts"],
  partsetPart: ["id", "isDeactivated"],
  constructAnnotation: [
    "id",
    "name",
    "type",
    "start",
    "end",
    "annotationType",
    "annotationTemplate",
    "notes"
  ],
  aminoAcidPart: ["id", "name", "start", "end"],
  aminoAcidSequence: ["id", "name", "description", "proteinSequence"]
};

// Simple references are references to a single object
// that don't require a join table.
export const SIMPLE_REFERENCES = {
  bin: {
    iconId: "icon.id"
  },
  card: {
    outputReactionId: "outputReaction.id"
  },
  binCard: {
    binId: "bin.id"
  },
  reaction: {
    assemblyMethodId: "assemblyMethod.id",
    restrictionEnzymeId: "restrictionEnzyme.id",
    customJ5ParameterId: "customJ5Parameter.id"
  },
  element: {
    partId: "part.id",
    aminoAcidPartId: "aminoAcidPart.id",
    partsetId: "partset.id"
  },
  fas: {
    elementId: "element.id"
  },
  eugeneRule: {
    element1Id: "element1.id",
    element2Id: "element2.id"
  },
  reactionJ5OutputNamingTemplate: {
    j5OutputNamingTemplateId: "j5OutputNamingTemplate.id"
  },
  part: {
    sequenceId: "sequence.id",
    re5PrimeId: "re5Prime.id",
    re3PrimeId: "re3Prime.id"
  },
  designMaterialAvailabilityView: {
    id: "sequence.id"
  },
  taggedItem: {
    tagId: "tag.id",
    tagOptionId: "tagOption.id"
  },
  tagOption: {
    tagId: "tag.id"
  },
  binRuleSet: {
    ruleSetId: "ruleSet.id"
  },
  tagRule: {
    tagId: "tag.id",
    tagOptionId: "tagOption.id"
  },
  eugeneRuleElement: {
    eugeneRule1Id: "eugeneRule1.id",
    eugeneRule2Id: "eugeneRule2.id",
    elementId: "element.id"
  },
  elementCombo: {
    availablePartId: "availablePart.id",
    card: "card.id"
  },
  elementElementCombo: {
    elementComboId: "elementCombo.id",
    elementId: "element.id"
  },
  elementGroupCombo: {
    cardId: "card.id"
  },
  elementGroupElementGroupCombo: {
    elementGroupComboId: "elementGroupCombo.id",
    elementId: "element.id"
  },
  junction: {
    fivePrimeCardEndBinId: "bin.id",
    threePrimeCardEndBinId: "bin.id",
    fivePrimeCardInteriorBinId: "bin.id",
    restrictionEnzymeId: "restrictionEnzyme.id"
  },
  partsetPart: {
    partId: "part.id"
  },
  constructAnnotation: {
    cardId: "card.id",
    leftBoundaryBinId: "bin.id",
    rightBoundaryBinId: "bin.id"
  },
  aminoAcidPart: {
    aminoAcidSequenceId: "aminoAcidSequence.id"
  }
};

export const FOREIGN_REFERENCES = {
  card: {
    binCards: {
      foreignType: "binCard",
      foreignKey: "cardId"
    },
    constructAnnotations: {
      foreignType: "constructAnnotation",
      foreignKey: "cardId"
    },
    elementCombos: {
      foreignType: "elementCombo",
      foreignKey: "cardId"
    },
    elementGroupCombos: {
      foreignType: "elementGroupCombo",
      foreignKey: "cardId"
    }
  },
  bin: {
    elements: {
      foreignType: "element",
      foreignKey: "binId"
    },
    binRuleSets: {
      foreignType: "binRuleSet",
      foreignKey: "binId"
    },
    fivePrimeCardEndBinJunction: {
      foreignType: "junction",
      foreignKey: "fivePrimeCardEndBinId"
    },
    threePrimeCardEndBinJunction: {
      foreignType: "junction",
      foreignKey: "threePrimeCardEndBinId"
    }
  },
  reaction: {
    cards: {
      foreignType: "card",
      foreignKey: "inputReactionId"
    },
    reactionJ5OutputNamingTemplates: {
      foreignType: "reactionJ5OutputNamingTemplate",
      foreignKey: "reactionId"
    },
    eugeneRules: {
      foreignType: "eugeneRule",
      foreignKey: "reactionId"
    },
    fas: {
      foreignType: "fas",
      foreignKey: "reactionId"
    },
    junctions: {
      foreignType: "junction",
      foreignKey: "reactionId"
    }
  },
  element: {
    elementGroupElementGroupCombos: {
      foreignType: "elementGroupElementGroupCombo",
      foreignKey: "elementId"
    }
  },
  design: {},
  sequence: {
    sequenceFeatures: {
      foreignType: "sequenceFeature",
      foreignKey: "sequenceId"
    },
    sequenceFragments: {
      foreignType: "sequenceFragment",
      foreignKey: "sequenceId"
    }
  },
  part: {
    taggedItems: {
      foreignType: "taggedItem",
      foreignKey: "partId"
    }
  },
  ruleSet: {
    rules: {
      foreignType: "rule",
      foreignKey: "ruleSetId"
    }
  },
  rule: {
    tagRules: {
      foreignType: "tagRule",
      foreignKey: "ruleId"
    }
  },
  eugeneRule: {
    operand1EugeneRuleElements: {
      foreignType: "eugeneRuleElement",
      foreignKey: "eugeneRule1Id"
    },
    operand2EugeneRuleElements: {
      foreignType: "eugeneRuleElement",
      foreignKey: "eugeneRule2Id"
    }
  },
  elementCombo: {
    elementElementCombos: {
      foreignType: "elementElementCombo",
      foreignKey: "elementComboId"
    }
  },
  elementGroupCombo: {
    elementGroupElementGroupCombos: {
      foreignType: "elementGroupElementGroupCombo",
      foreignKey: "elementGroupComboId"
    }
  },
  partset: {
    partsetParts: {
      foreignType: "partsetPart",
      foreignKey: "partsetId"
    }
  }
};

export const SIMPLE_REFERENCES_TO_TYPE = {
  bin: {
    iconId: "icon"
  },
  card: {
    inputReactionId: "reaction",
    outputReactionId: "reaction"
  },
  reaction: {
    assemblyMethodId: "assemblyMethod",
    restrictionEnzymeId: "restrictionEnzyme",
    customJ5ParameterId: "customJ5Parameter"
  },
  binCard: {
    cardId: "card",
    binId: "bin"
  },
  element: {
    partId: "part",
    aminoAcidPartId: "aminoAcidPart",
    binId: "bin",
    partsetId: "partset"
  },
  binRuleSet: {
    binId: "bin",
    ruleSetId: "ruleSet"
  },
  fas: {
    elementId: "element",
    reactionId: "reaction"
  },
  eugeneRule: {
    reactionId: "reaction",
    element1Id: "element",
    element2Id: "element"
  },
  reactionJ5OutputNamingTemplate: {
    j5OutputNamingTemplateId: "j5OutputNamingTemplate",
    reactionId: "reaction"
  },
  part: {
    sequenceId: "sequence",
    re5PrimeId: "restrictionEnzyme",
    re3PrimeId: "restrictionEnzyme"
  },
  designMaterialAvailabilityView: {
    id: "sequence"
  },
  aminoAcidPart: {
    aminoAcidSequenceId: "aminoAcidSequence"
  },
  sequenceFeature: {
    sequenceId: "sequence"
  },
  sequenceFragment: {
    sequenceId: "sequence"
  },
  tagOption: {
    tagId: "tag"
  },
  taggedItem: {
    partId: "part",
    tagId: "tag",
    tagOptionId: "tagOption"
  },
  rule: {
    ruleSetId: "ruleSet"
  },
  tagRule: {
    ruleId: "rule",
    tagId: "tag",
    tagOptionId: "tagOption"
  },
  eugeneRuleElement: {
    eugeneRule1Id: "eugeneRule",
    eugeneRule2Id: "eugeneRule",
    elementId: "element"
  },
  elementCombo: {
    availablePartId: "part",
    cardId: "card"
  },
  elementElementCombo: {
    elementId: "element",
    elementComboId: "elementCombo"
  },
  elementGroupCombo: {
    cardId: "card"
  },
  elementGroupElementGroupCombo: {
    elementGroupComboId: "elementGroupCombo",
    elementId: "element"
  },
  junction: {
    restrictionEnzymeId: "restrictionEnzyme",
    reactionId: "reaction",
    fivePrimeCardId: "card",
    threePrimeCardId: "card",
    fivePrimeCardEndBinId: "bin",
    threePrimeCardStartBinId: "bin",
    fivePrimeCardInteriorBinId: "bin",
    threePrimeCardInteriorBinId: "bin"
  },
  partsetPart: {
    partId: "part",
    partsetId: "partset"
  },
  constructAnnotation: {
    cardId: "card",
    leftBoundaryBinId: "bin",
    rightBoundaryBinId: "bin"
  }
};

export const JSON_DATATYPE_FIELDS = {
  metadata: {},
  locations: {}
};

/**
 * These types are data intensive and will only be loaded on the server.
 */
export const SERVER_ONLY_TYPES = {
  sequenceFeature: true,
  sequenceFragment: true
};

export const PRESERVED_TYPES = ["icon", "assemblyMethod"];

export const SUPPORTED_BIN_ICONS = {
  "USER-DEFINED": true,
  ASSEMBLY_JUNCTION: true,
  BLUNT_RESTRICTION_SITE: true,
  CDS: true,
  FIVE_PRIME_OVERHANG: true,
  FIVE_PRIME_UTR: true,
  FIVE_PRIME_RESTRICTION_SITE: true,
  INSULATOR: true,
  OPERATOR_SITE: true,
  ORIGIN_OF_REPLICATION: true,
  PRIMER_BINDING_SITE: true,
  PROMOTER: true,
  PROTEASE_SITE: true,
  PROTEIN_STABILITY_ELEMENT: true,
  RESTRICTION_ENZYME_RECOGNITION_SITE: true,
  RIBONUCLEASE_SITE: true,
  RBS: true,
  RNA_STABILITY_ELEMENT: true,
  SIGNATURE: true,
  TERMINATOR: true,
  THREE_PRIME_OVERHANG: true,
  THREE_PRIME_RESTRICTION_SITE: true,
  "Assembled Construct": true,
  "Type IIs recognition site": true,
  "Type IIs cutsite": true
};

export const TYPES_LINKED_TO_DESIGN = {
  reaction: true,
  element: true,
  binRuleSet: true,
  fas: true,
  eugeneRule: true,
  disabledDesignRow: true,
  reactionJ5OutputNamingTemplate: true,
  j5OutputNamingTemplate: true,
  eugeneRuleElement: true,
  elementCombo: true,
  customJ5Parameter: true,
  elementElementCombo: true,
  elementGroupCombo: true,
  elementGroupElementGroupCombo: true,
  bin: true,
  card: true,
  constructAnnotation: true,
  binCard: true,
  junction: true
};

export const statusToIconMap = {
  NO_SUBMISSION_YET: {
    content: "No Submission Yet",
    icon: "symbol-circle",
    color: "lightgreen"
  },
  CHANGES_REQUESTED: {
    content: "Changes Requested",
    icon: "build",
    color: "orange"
  },
  CANCELLED: {
    content: "Cancelled",
    icon: "ban-circle",
    color: "grey"
  },
  ACCEPTED: {
    content: "Accepted",
    icon: "tick",
    color: "green"
  },

  REJECTED: {
    content: "Rejected",
    icon: "cross",
    color: "red"
  },
  READY: {
    content: "Ready",
    icon: "symbol-circle",
    color: "lightblue"
  },
  PARTIALLY_ACCEPTED: {
    content: "Partially Accepted",
    icon: "full-circle",
    color: "lightblue"
  }
};

export const PART_AND_SEQ_TYPES = {
  part: true,
  taggedItem: true,
  sequence: true,
  sequenceFeature: true,
  sequenceFragment: true
};

export const STATE_TO_DB_TYPES = {
  reaction: "designReaction"
};

export const DB_TO_STATE_TYPES = invert(STATE_TO_DB_TYPES);
