/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { Callout } from "@blueprintjs/core";
import { omit, map } from "lodash";
import { getUserGroupLabel, specialCutsiteFilterOptions } from "@teselagen/ove";
import { enzymeGroupFragment } from "./EnzymeGroupPanel";
import { TgSelect } from "@teselagen/ui";
import useTgQuery from "../apolloUseTgQuery";
import gql from "graphql-tag";
import { safeUpsert } from "../apolloMethods";

export const GlobalFiltersPanel = () => {
  const [isSaving, setSaving] = useState();
  const { user, ...userRest } = useTgQuery(userEnzymeFilterPrefsFrag, {
    variables: {
      id: localStorage.getItem("userId")
    }
  });

  const { enzymeGroups, ...rest1 } = useTgQuery(enzymeGroupFragment, {
    variables: { pageSize: 99999 }
  });
  const { restrictionEnzymes, ...rest2 } = useTgQuery(enzymeFragment, {
    variables: { pageSize: 99999 }
  });
  if (useTgQuery.checkErrAndLoad(userRest))
    return useTgQuery.handleErrAndLoad(userRest);
  if (useTgQuery.checkErrAndLoad(rest1))
    return useTgQuery.handleErrAndLoad(rest1);
  if (useTgQuery.checkErrAndLoad(rest2))
    return useTgQuery.handleErrAndLoad(rest2);
  const options = [
    ...map(specialCutsiteFilterOptions),
    ...enzymeGroups.map(g => ({
      value: `__userCreatedGroup${g.name}`,
      label: getUserGroupLabel({ name: g.name, nameArray: [] })
    })),
    ...restrictionEnzymes.map(e => ({
      value: e.name.toLowerCase(),
      label: e.name
    }))
  ];
  const value = user?.preferredEnzymeFilter
    ? map(
        user.preferredEnzymeFilter,
        e => options.find(e2 => e2.value === e.value) || e
      )
    : undefined;
  return (
    <div style={{ maxWidth: 500 }}>
      <Callout intent="primary">
        These changes will only affect your account. If you have already chosen
        an enzyme selection on an existing sequence, that selection will not be
        overridden.
      </Callout>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginBottom: 5, fontWeight: "bold" }}>
          Set default Cutsite Filter for sequences:
        </div>
        <div>
          {isSaving === undefined ? null : isSaving ? (
            "Saving..."
          ) : (
            <span style={{ color: "#43BF4D" }}>Saved</span>
          )}
        </div>
      </div>
      <TgSelect
        isLoading={isSaving}
        className="tg-global-cutsite-filter"
        onChange={async vals => {
          const value = vals.map(v => omit(v, "label"));
          setSaving(true);
          await safeUpsert(userEnzymeFilterPrefsFrag, {
            id: localStorage.getItem("userId"),
            preferredEnzymeFilter: value
          });
          await userRest.refetch();
          setSaving(false);
        }}
        multi
        value={value}
        options={options}
      />

      <br />
      <br />
    </div>
  );
};

export const enzymeFragment = gql`
  fragment enzymeLibraryFragment on restrictionEnzyme {
    id
    name
    description
    forwardSnipPosition
    reverseSnipPosition
    recognitionLength
    recognitionRegex
    recognitionStart
    sequence
    enzymeTypeCode
  }
`;

export const userEnzymeFilterPrefsFrag = gql`
  fragment userEnzymeFilterPrefsFrag on user {
    id
    preferredEnzymeFilter
  }
`;
