/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get, flatten, set, isEmpty } from "lodash";
import { Button, Tooltip, Icon } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import {
  purificationPlanningTubeFragment,
  purificationPlanningPlateMapGroupFragment,
  purificationPlanningPlateFragment
} from "../fragments";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import {
  additionalFilterForTubes,
  validateDNAOrMicrobialMaterial
} from "../../../../utils/plateUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class SelectMaterials extends Component {
  prepReactionMap = values => {
    const { sourceTubes = [], sourcePlates = [], plateMapGroups = [] } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const reactionInfo = [];
    const inputMaterialIds = [];

    const getOutputMaterialOptions = inputMaterial => {
      let outputMaterialOptions;
      if (inputMaterial.materialTypeCode === "DNA") {
        outputMaterialOptions =
          inputMaterial.polynucleotideMaterialMaterialFpus.map(
            // below line will break if fpu is not linked to a protein material
            materialFpu => materialFpu.functionalProteinUnit.proteinMaterial
          );
      } else {
        outputMaterialOptions =
          inputMaterial.microbialMaterialMicrobialMaterialPlasmids.map(mmp =>
            mmp.polynucleotideMaterial.polynucleotideMaterialMaterialFpus.map(
              materialFpu => materialFpu.functionalProteinUnit.proteinMaterial
            )
          );
      }
      outputMaterialOptions = flatten(outputMaterialOptions);
      if (!inputMaterialIds.includes(inputMaterial.id)) {
        reactionInfo.push({ inputMaterial, outputMaterialOptions });
        inputMaterialIds.push(inputMaterial.id);
      }
    };

    const handleSample = sample => {
      if (sample) {
        if (sample.material) {
          getOutputMaterialOptions(sample.material);
        } else if (sample.sampleTypeCode === "FORMULATED_SAMPLE") {
          sample.sampleFormulations.forEach(sf => {
            sf.materialCompositions.forEach(mc => {
              if (mc.material) {
                getOutputMaterialOptions(mc.material);
              }
            });
          });
        }
      }
    };

    const handleAliquotContainer = ac => {
      handleSample(get(ac, "aliquot.sample"));
    };

    sourcePlates.forEach(plate =>
      plate.aliquotContainers.forEach(handleAliquotContainer)
    );
    sourceTubes.forEach(handleAliquotContainer);
    plateMapGroups.forEach(pmg =>
      pmg.plateMaps.forEach(pm =>
        pm.plateMapItems.forEach(pmi => {
          if (pm.type === "material") {
            getOutputMaterialOptions(get(pmi, "inventoryItem.material"));
          } else {
            handleSample(get(pmi, "inventoryItem.sample"));
          }
        })
      )
    );
    change("reactionInfo", reactionInfo);
    nextStep();
  };

  render() {
    const {
      footerProps,
      Footer,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      handleSubmit,
      sourcePlates = [],
      sourceTubes = [],
      plateMapGroups = []
    } = this.props;

    const errorsForPlates = {};
    const warningsForPlates = {};
    const errorsForTubes = {};
    const errorsForPlateMapGroups = {};
    const warningsForPlateMapGroups = {};
    const acceptedMaterialTypes = ["MICROBIAL", "DNA"];

    sourcePlates.forEach(plate => {
      let noMaterials = true;
      let someMaterials = false;
      let invalidMaterialTypes = true;
      let someInvalidMaterialTypes = false;
      let unlinkedSequences = false;
      let missingFPUs = false;
      let unlinkedProteins = false;
      plate.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          let isUnlinked = false;
          let isMissingFpu = false;
          let isNotLinkedToProtein = false;
          const validateMaterial = material => {
            if (!material) {
              someMaterials = true;
            } else {
              noMaterials = false;
              const materialType = material.materialTypeCode;
              if (!acceptedMaterialTypes.includes(materialType)) {
                someInvalidMaterialTypes = true;
              }
              if (acceptedMaterialTypes.includes(materialType)) {
                invalidMaterialTypes = false;
              }
              const {
                noSequence,
                noFpus,
                noProteinMaterials,
                plasmidMissingFpus,
                missingPlasmid,
                fpusMissingMaterials
              } = validateDNAOrMicrobialMaterial(material);
              isUnlinked = noSequence || missingPlasmid;
              isMissingFpu = noFpus || plasmidMissingFpus;
              isNotLinkedToProtein = noProteinMaterials || fpusMissingMaterials;
            }
          };
          const material = get(ac, "aliquot.sample.material");
          if (material) {
            validateMaterial(material);
          } else if (
            get(ac, "aliquot.sample.sampleTypeCode") === "FORMULATED_SAMPLE"
          ) {
            // handle pooled sample material validation
            ac.aliquot.sample.sampleFormulations.forEach(sf => {
              sf.materialCompositions.forEach(mc => {
                if (mc.material) {
                  validateMaterial(mc.material);
                }
              });
            });
          }
          const errorsForWell = [];
          if (isUnlinked) {
            errorsForWell.push("Aliquot is missing sequence data");
            unlinkedSequences = true;
          }
          if (isMissingFpu) {
            errorsForWell.push(
              "Material not linked to functional protein units"
            );
            missingFPUs = true;
          }
          if (isNotLinkedToProtein) {
            errorsForWell.push(
              "Aliquot is linked to functional protein units with no materials"
            );
            unlinkedProteins = true;
          }
          if (errorsForWell.length) {
            set(
              errorsForPlates,
              `${plate.id}.${getAliquotContainerLocation(ac, {
                force2D: true
              })}`,
              errorsForWell
            );
          }
        }
      });

      let plateError;
      if (noMaterials) {
        plateError = `No aliquots on ${plate.name} are linked to materials.`;
      } else if (invalidMaterialTypes) {
        plateError = `No aliquots on ${plate.name} are linked to DNA or microbial materials.`;
      } else if (unlinkedSequences) {
        plateError = `Some materials on ${plate.name} are missing sequence data.`;
      } else if (missingFPUs) {
        plateError = `Some materials on ${plate.name} are not linked to functional protein units.`;
      } else if (unlinkedProteins) {
        plateError = `Some materials on ${plate.name} are linked to functional protein units with no materials.`;
      }
      if (plateError) {
        errorsForPlates[plate.id] = errorsForPlates[plate.id] || {};
        errorsForPlates[plate.id]._error = plateError;
      }
      let plateWarning;
      if (someMaterials) {
        plateWarning = `Some aliquots on ${plate.name} are not linked to materials. These will be ignored.`;
      } else if (someInvalidMaterialTypes) {
        plateWarning = `Some aliquots on ${plate.name} are not linked to DNA or microbial materials. These will be ignored.`;
      }
      if (plateWarning) {
        warningsForPlates[plate.id] = warningsForPlates[plate.id] || {};
        warningsForPlates[plate.id]._warning = plateWarning;
      }
    });

    sourceTubes.forEach(tube => {
      const addError = msg => {
        errorsForTubes[tube.id] = `${tube.name} ${
          tube.barcode.barcodeString ? `(${tube.barcode.barcodeString})` : ""
        } ${msg}`;
      };

      const validateMaterial = material => {
        if (!acceptedMaterialTypes.includes(material.materialTypeCode)) {
          addError("is not linked to a DNA or microbial material.");
        } else {
          const {
            noSequence,
            noFpus,
            noProteinMaterials,
            plasmidMissingFpus,
            missingPlasmid,
            fpusMissingMaterials
          } = validateDNAOrMicrobialMaterial(material);
          if (noSequence) {
            addError("has a DNA material with no sequence data.");
          } else if (noFpus) {
            addError(
              "has a sequence that is not linked to functional protein units."
            );
          } else if (noProteinMaterials) {
            addError(
              "is linked to functional protein units with no protein materials."
            );
          }
          if (missingPlasmid) {
            addError("has a microbial material with no attached plasmids.");
          }
          if (plasmidMissingFpus) {
            addError(
              "has plasmids that are not linked to functional protein units."
            );
          }
          if (fpusMissingMaterials) {
            addError(
              "has plasmids with functional protein units that are not linked to protein materials."
            );
          }
        }
      };

      if (get(tube, "aliquot.sample.material")) {
        validateMaterial(tube.aliquot.sample.material);
      } else if (
        get(tube, "aliquot.sample.sampleTypeCode") === "FORMULATED_SAMPLE"
      ) {
        tube.aliquot.sample.sampleFormulations.forEach(sf => {
          sf.materialCompositions.forEach(mc => {
            if (mc.material) {
              validateMaterial(mc.material);
            }
          });
        });
      } else {
        addError("does not have a material.");
      }
    });

    plateMapGroups.forEach(pmg => {
      let noMaterials = true;
      let someInvalidMaterialTypes = false;
      let invalidMaterialTypes = true;
      let missingSequenceInfo = false;
      let missingProteinMaterials = false;
      let missingFpus = false;
      const validateMaterial = material => {
        if (material) {
          noMaterials = false;
          if (!acceptedMaterialTypes.includes(material.materialTypeCode)) {
            someInvalidMaterialTypes = true;
          } else {
            invalidMaterialTypes = false;
            const {
              noSequence,
              noFpus,
              noProteinMaterials,
              plasmidMissingFpus,
              missingPlasmid,
              fpusMissingMaterials
            } = validateDNAOrMicrobialMaterial(material);
            if (noSequence) {
              missingSequenceInfo = true;
            } else if (noFpus) {
              missingFpus = true;
            } else if (noProteinMaterials) {
              missingProteinMaterials = true;
            }

            if (plasmidMissingFpus) {
              missingFpus = true;
            } else if (missingPlasmid) {
              missingSequenceInfo = true;
            } else if (fpusMissingMaterials) {
              missingProteinMaterials = true;
            }
          }
        }
      };
      pmg.plateMaps.forEach(pm => {
        pm.plateMapItems.forEach(pmi => {
          if (pm.type === "material") {
            validateMaterial(get(pmi, "inventoryItem.material"));
          } else if (pm.type === "sample") {
            const sample = get(pmi, "inventoryItem.sample");
            if (sample) {
              if (sample.material) {
                validateMaterial(sample.material);
              } else if (sample.sampleTypeCode === "FORMULATED_SAMPLE") {
                sample.sampleFormulations.forEach(sf => {
                  sf.materialCompositions.forEach(mc => {
                    validateMaterial(mc.material);
                  });
                });
              }
            }
          }
        });
      });

      if (noMaterials) {
        errorsForPlateMapGroups[pmg.id] =
          `No items on ${pmg.name} are linked to materials.`;
      } else if (invalidMaterialTypes) {
        errorsForPlateMapGroups[pmg.id] =
          `No items on ${pmg.name} are linked to DNA or microbial materials.`;
      } else if (missingSequenceInfo) {
        errorsForPlateMapGroups[pmg.id] =
          `Some materials on ${pmg.name} are missing sequence information.`;
      } else if (missingFpus) {
        errorsForPlateMapGroups[pmg.id] =
          `Some materials on ${pmg.name} are not linked to functional protein units.`;
      } else if (missingProteinMaterials) {
        errorsForPlateMapGroups[pmg.id] =
          `Some materials on ${pmg.name} are linked to functional protein units with no protein materials.`;
      }
      if (someInvalidMaterialTypes) {
        warningsForPlateMapGroups[pmg.id] =
          `Some aliquots on ${pmg.name} are not linked to DNA or microbial materials. These will be ignored.`;
      }
    });

    const hasInputErrors =
      !isEmpty(errorsForPlates) ||
      !isEmpty(errorsForTubes) ||
      !isEmpty(errorsForPlateMapGroups);

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates From Inventory"
            helper="Select one or more plates of DNA or Microbial material."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "sourcePlates",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.containerArrays,
                  loading: isLoadingMap.containerArrays
                },
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: purificationPlanningPlateFragment,
                postSelectDTProps: {
                  formName: "selectedPlates",
                  isSingleSelect: true,
                  errorsForPlates,
                  warningsForPlates,
                  schema: [
                    platePreviewColumn({
                      plateErrors: errorsForPlates,
                      plateWarnings: warningsForPlates
                    }),
                    "name",
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Tubes From Inventory"
            helper="Select one or more tubes of DNA or Microbial material."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "sourceTubes",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                dialogProps: {
                  style: {
                    width: 800
                  }
                },
                fragment: [
                  "aliquotContainer",
                  "id name barcode { id barcodeString } aliquot { id sample { id material { id name } } } updatedAt"
                ],
                additionalDataFragment: purificationPlanningTubeFragment,
                tableParamOptions: {
                  additionalFilter: additionalFilterForTubes
                },
                postSelectDTProps: {
                  formName: "selectedTubes",
                  errorsForTubes,
                  schema: [
                    {
                      type: "action",
                      width: 35,
                      render: (_, record) => {
                        if (errorsForTubes[record.id]) {
                          return (
                            <Tooltip content={errorsForTubes[record.id]}>
                              <Icon
                                intent="danger"
                                style={{ marginRight: 10 }}
                                icon="warning-sign"
                              />
                            </Tooltip>
                          );
                        }
                      }
                    },
                    "name",
                    {
                      displayName: "Tube Type",
                      path: "aliquotContainerType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    },
                    {
                      displayName: "Material",
                      path: "aliquot.sample.material.name"
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plate Maps"
            helper="Select one or more plate maps of DNA or microbial material."
          />
          <div>
            <GenericSelect
              {...{
                name: "plateMapGroups",
                isMultiSelect: true,
                schema: [
                  "name",
                  { displayName: "Plate Format", path: "containerFormat.name" },
                  dateModifiedColumn
                ],
                fragment: [
                  "plateMapGroup",
                  "id name containerFormatCode containerFormat { code name } updatedAt"
                ],
                buttonProps: {
                  loading: isLoadingMap.plateMapGroup,
                  disabled: isDisabledMap.plateMapGroup
                },
                additionalDataFragment:
                  purificationPlanningPlateMapGroupFragment,
                tableParamOptions: {
                  additionalFilter: {
                    "plateMaps.type": ["material", "sample"]
                  }
                },
                postSelectDTProps: {
                  formName: "selectedPlateMapGroups",
                  errorsForPlateMapGroups,
                  warningsForPlateMapGroups,
                  schema: [
                    {
                      type: "action",
                      width: 35,
                      render: (_, record) => {
                        const errorOrWarning =
                          errorsForPlateMapGroups[record.id] ||
                          warningsForPlateMapGroups[record.id];
                        if (errorOrWarning) {
                          let isError;
                          if (errorsForPlateMapGroups[record.id]) {
                            isError = true;
                          } else {
                            isError = false;
                          }
                          return (
                            <Tooltip
                              content={
                                errorsForPlateMapGroups[record.id] ||
                                warningsForPlateMapGroups[record.id]
                              }
                            >
                              <Icon
                                intent={isError ? "danger" : "warning"}
                                style={{ marginRight: 10 }}
                                icon="warning-sign"
                              />
                            </Tooltip>
                          );
                        }
                      }
                    },
                    platePreviewColumn(),
                    "name",
                    "containerFormat.name"
                  ]
                }
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          errorMessage={hasInputErrors && "Please fix errors."}
          nextButton={
            <Button
              intent="primary"
              disabled={hasInputErrors}
              onClick={handleSubmit(this.prepReactionMap)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "sourcePlates",
  "sourceTubes",
  "plateMapGroups"
)(SelectMaterials);
