/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get, times, noop } from "lodash";
import { Colors } from "@blueprintjs/core";
import classNames from "classnames";
import { SizeMe } from "react-sizeme";
import "./style.css";

const SHELF_HEIGHT = 150;
const SHELF_PADDING = 5;

const shelfColor = Colors.BLUE3;
const rackColor = Colors.TURQUOISE3;
const drawerColor = Colors.GOLD3;
const positionColor = Colors.GRAY3;

function getSelectionClassNames(className, selection = {}, item = {}) {
  const type = className.replace("freezer-view-", "");
  const positionIndex = selection[type + "PositionIndex"];
  return classNames(className, {
    selected: positionIndex === undefined && inSelection(selection, type, item)
  });
}

function inSelection(selection, type, item) {
  return get(selection, type, []).includes(item.id);
}

function PositionSlots({
  container,
  containerWidth,
  selectedIndex,
  onPositionClick
}) {
  const positionRecordArray = get(container, "positions", []);
  const numPositions = container.numPositions || positionRecordArray.length;
  if (numPositions > 0) {
    const style = {
      height: "100%"
    };
    if (containerWidth) {
      style.width = containerWidth / numPositions;
    }
    return times(numPositions, i => {
      const positionRecord = positionRecordArray[i];
      const itemCount = (positionRecord && positionRecord.numItems) || 0;
      return (
        <div
          key={i}
          className={classNames("freezer-view-position", {
            selected: selectedIndex === i,
            "has-items": itemCount > 0
          })}
          onClick={e => {
            if (onPositionClick) {
              e.stopPropagation();
              onPositionClick(positionRecord || i);
            }
          }}
          style={{
            ...style,
            boxSizing: "border-box",
            borderRight: i !== numPositions - 1 && `1px solid ${positionColor}`
          }}
        >
          {itemCount > 0 ? itemCount : null}
        </div>
      );
    });
  } else {
    return null;
  }
}

const rackBorderWidth = 2;

function FreezerView({
  shelves,
  selectedShelfIndex,
  onShelfClick = noop,
  onDrawerClick,
  onPositionClick,
  selection = {}
}) {
  let maxRacks = 0;
  shelves.forEach(shelf => {
    const numRacks = shelf.numRacks || get(shelf, "racks.length");
    if (numRacks > maxRacks) maxRacks = numRacks;
  });

  return (
    <div style={{ marginLeft: "auto", flex: 1, width: "100%" }}>
      <SizeMe>
        {({ size: { width: _containerWidth } }) => {
          let containerWidth = _containerWidth;
          if (containerWidth > 1000) {
            containerWidth = 1000;
          } else if (containerWidth < 450) {
            containerWidth = 450;
          }
          const fullShelfPadding = SHELF_PADDING * 2;
          const FREEZER_WIDTH = containerWidth - 8 - fullShelfPadding; // border offset
          const rackContainerWidth = FREEZER_WIDTH / maxRacks;
          const rackWidth = rackContainerWidth - fullShelfPadding;
          return (
            <div className="size-me-container">
              <div
                style={{
                  border: "4px solid #a7b6c2",
                  width: containerWidth,
                  borderRadius: 3,
                  userSelect: "none"
                }}
              >
                {shelves.map((shelf, shelfIndex) => {
                  const numRacks = shelf.numRacks || get(shelf, "racks.length");
                  let className = getSelectionClassNames(
                    "freezer-view-shelf",
                    selection,
                    shelf
                  );
                  const hasSelection = inSelection(selection, "shelf", shelf);
                  if (
                    !className.includes("selected") &&
                    selectedShelfIndex === shelfIndex
                  ) {
                    className += " selected";
                  }
                  const shelfWidth = numRacks
                    ? undefined
                    : FREEZER_WIDTH + SHELF_PADDING * 2;

                  return (
                    <div
                      key={shelfIndex}
                      onClick={() => onShelfClick(shelf.id || shelfIndex)}
                      className={className}
                      style={{
                        height: SHELF_HEIGHT,
                        width: shelfWidth,
                        paddingLeft: SHELF_PADDING,
                        paddingRight: SHELF_PADDING,
                        borderBottom: `3px solid ${shelfColor}`,
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center",
                        justifyContent:
                          !numRacks && shelf.numPositions ? "center" : undefined
                      }}
                    >
                      {!numRacks && (
                        <PositionSlots
                          selectedIndex={
                            hasSelection && selection.shelfPositionIndex
                          }
                          onPositionClick={onPositionClick}
                          container={shelf}
                          containerWidth={shelfWidth}
                        />
                      )}
                      {times(numRacks, rackIndex => {
                        const rack = get(shelf, `racks[${rackIndex}]`, {});
                        const numDrawers =
                          get(rack, `numDrawers`) ||
                          get(rack, `drawers.length`);

                        const drawerWidth = rackWidth - rackBorderWidth * 2;
                        const rackContainerHeight =
                          SHELF_HEIGHT - SHELF_PADDING * 2;
                        const rackHeight =
                          rackContainerHeight - SHELF_PADDING * 2;

                        const hasSelection = inSelection(
                          selection,
                          "rack",
                          rack
                        );

                        return (
                          <div
                            key={rackIndex}
                            className="freezer-view-rack-container"
                            style={{
                              display: "inline-block",
                              height: rackContainerHeight,
                              padding: SHELF_PADDING,
                              width: rackContainerWidth
                            }}
                          >
                            <div
                              className={getSelectionClassNames(
                                "freezer-view-rack",
                                selection,
                                rack
                              )}
                              style={{
                                border: `${rackBorderWidth}px solid ${rackColor}`,
                                display: !numDrawers ? "flex" : undefined,
                                boxSizing: "border-box",
                                height: rackHeight
                              }}
                            >
                              {!numDrawers && (
                                <PositionSlots
                                  selectedIndex={
                                    hasSelection && selection.rackPositionIndex
                                  }
                                  onPositionClick={onPositionClick}
                                  container={rack}
                                  containerWidth={rackWidth}
                                />
                              )}
                              {times(numDrawers, drawerIndex => {
                                const drawer = get(
                                  rack,
                                  `drawers[${drawerIndex}]`,
                                  {}
                                );
                                const lastDrawer =
                                  drawerIndex === numDrawers - 1;
                                const drawerHeight =
                                  (rackHeight - rackBorderWidth * 2) /
                                  numDrawers;

                                const hasSelection = inSelection(
                                  selection,
                                  "drawer",
                                  drawer
                                );

                                return (
                                  <div
                                    key={drawerIndex}
                                    className={getSelectionClassNames(
                                      "freezer-view-drawer",
                                      selection,
                                      drawer
                                    )}
                                    onClick={e => {
                                      if (onDrawerClick) {
                                        e.stopPropagation();
                                        onDrawerClick(drawer || drawerIndex);
                                      }
                                    }}
                                    style={{
                                      height: drawerHeight,
                                      width: drawerWidth,
                                      boxSizing: "border-box",
                                      borderBottom: !lastDrawer
                                        ? `1px solid ${drawerColor}`
                                        : undefined,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <PositionSlots
                                      selectedIndex={
                                        hasSelection &&
                                        selection.drawerPositionIndex
                                      }
                                      onPositionClick={onPositionClick}
                                      container={drawer}
                                      containerWidth={drawerWidth}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      </SizeMe>
    </div>
  );
}

export default FreezerView;
