/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { DataTable, withTableParams } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { worklistRecordTransfersFragment } from "../../../graphql/fragments/worklistRecordFragment";
import { worklistRecordTableSchema } from "./worklistRecordTableSchema";

function WorklistTransfersTable({ tableParams }) {
  return <DataTable {...tableParams} compact />;
}

export default compose(
  withTableParams({
    formName: "worklistRecordTransfersTable",
    urlConnected: false,
    schema: worklistRecordTableSchema,
    additionalFilter: (props, qb) => {
      qb.whereAll({
        worklistId: props.worklistId
      });
    }
  }),
  withQuery(worklistRecordTransfersFragment, {
    isPlural: true
  })
)(WorklistTransfersTable);
