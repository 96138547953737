/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { AdvancedOptions, DataTable } from "@teselagen/ui";
import { Button, Callout, Intent } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import AddExtendedProperty from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/AddExtendedProperty";
import RecordExtendedPropertiesTable from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/RecordExtendedPropertiesTable";
import ReviewWorklistSection, {
  destinationPositionColumn,
  sourcePositionColumn,
  worklistTransferSchema
} from "../../../ReviewWorklistSection";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import { transformExtendedValues } from "../../../../../src-shared/utils/extendedPropertyUtils.js";

class ReviewWorklist extends Component {
  saveExtendedPropertyToForm = (val, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(fieldName, [...existingValues, val]);
  };

  removeExtendedProperty = (record, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(
      fieldName,
      existingValues.filter(
        val => val.extendedProperty.id !== record.extendedProperty.id
      )
    );
  };

  render() {
    const {
      toolSchema,
      worklist,
      warning,
      Footer,
      footerProps,
      operationType,
      destinationAliquotExtendedProperties = [],
      destinationContainerArrayExtendedProperties = [],
      destinationContainerArrayExtendedValues = [],
      destinationAliquotExtendedValues = [],
      sourceAliquotExtendedProperties = [],
      sourceContainerArrayExtendedProperties = [],
      sourceContainerArrayExtendedValues = [],
      sourceAliquotExtendedValues = []
    } = this.props;
    if (!worklist) return null;

    const destinationContainerArrayExtendedValuesEntities = transformExtendedValues(
      destinationContainerArrayExtendedValues
    );
    const destinationAliquotExtendedValuesEntities = transformExtendedValues(
      destinationAliquotExtendedValues
    );
    const sourceContainerArrayExtendedValuesEntities = transformExtendedValues(
      sourceContainerArrayExtendedValues
    );
    const sourceAliquotExtendedValuesEntities = transformExtendedValues(
      sourceAliquotExtendedValues
    );
    const schema =
      operationType === "BREAKDOWN"
        ? breakdownTransferSchema
        : worklistTransferSchema;

    const extendedPropertiesSection = (
      <AdvancedOptions>
        {(!!sourceAliquotExtendedProperties.length ||
          !!sourceContainerArrayExtendedProperties.length) && (
          <div className="tg-step-form-section column">
            <>
              <HeaderWithHelper
                header="Remove Existing Extended Properties from Source Aliquots and Plates"
                helper="The selected source plates and/or aliquots already have the
                  extended properties displayed below. Check the corresponding
                  box to delete any extended property upon executing the worklist."
                width="100%"
              />
              <div
                className="tg-flex justify-space-between"
                style={{ marginBottom: 20 }}
              >
                {!!sourceContainerArrayExtendedProperties.length && (
                  <div style={{ width: "45%" }}>
                    <h6>Plates</h6>
                    <DataTable
                      entities={sourceContainerArrayExtendedProperties}
                      schema={["name"]}
                      maxHeight={300}
                      destroyOnUnmount={false}
                      isSimple
                      withCheckboxes
                      compact
                      formName="sourceContainerArrayExtendedPropertiesToRemove"
                    />
                  </div>
                )}
                <div className="tg-flex-separator" />
                {!!sourceAliquotExtendedProperties.length && (
                  <div style={{ width: "45%" }}>
                    <h6>Aliquots</h6>
                    <DataTable
                      entities={sourceAliquotExtendedProperties}
                      schema={["name"]}
                      maxHeight={300}
                      destroyOnUnmount={false}
                      isSimple
                      withCheckboxes
                      compact
                      formName="sourceAliquotExtendedPropertiesToRemove"
                    />
                  </div>
                )}
              </div>
            </>
          </div>
        )}
        {(!!destinationAliquotExtendedProperties.length ||
          !!destinationContainerArrayExtendedProperties.length) && (
          <div className="tg-step-form-section column">
            <>
              <HeaderWithHelper
                header="Remove Existing Extended Properties from Destination Aliquots and Plates"
                helper="The selected destination plates and/or aliquots already have the
                  extended properties displayed below. Check the corresponding
                  box to delete any extended property upon executing the worklist."
                width="100%"
              />
              <div
                className="tg-flex justify-space-between"
                style={{ marginBottom: 20 }}
              >
                {!!destinationContainerArrayExtendedProperties.length && (
                  <div style={{ width: "45%" }}>
                    <h6>Plates</h6>
                    <DataTable
                      entities={destinationContainerArrayExtendedProperties}
                      schema={["name"]}
                      maxHeight={300}
                      destroyOnUnmount={false}
                      isSimple
                      withCheckboxes
                      compact
                      formName="destinationContainerArrayExtendedPropertiesToRemove"
                    />
                  </div>
                )}
                <div className="tg-flex-separator" />
                {!!destinationAliquotExtendedProperties.length && (
                  <div style={{ width: "45%" }}>
                    <h6>Aliquots</h6>
                    <DataTable
                      entities={destinationAliquotExtendedProperties}
                      schema={["name"]}
                      maxHeight={300}
                      destroyOnUnmount={false}
                      isSimple
                      withCheckboxes
                      compact
                      formName="destinationAliquotExtendedPropertiesToRemove"
                    />
                  </div>
                )}
              </div>
            </>
          </div>
        )}

        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Add Extended Properties"
            helper="Add extended properties to selected plates and
              aliquots below."
            width="100%"
          />
          <div
            className="tg-flex justify-space-between"
            style={{ marginBottom: 20 }}
          >
            <div style={{ width: "45%", height: "100%" }}>
              <h6>Source Plates</h6>
              <AddExtendedProperty
                model="containerArray"
                fullValueFields
                noLinkProperties
                formName="addExtendedPropertiesPlateReformatSourceContainerArray"
                extendedPropertyIdsToIgnore={sourceContainerArrayExtendedValues.map(
                  val => val.extendedProperty.id
                )}
                onSubmitValue={val =>
                  this.saveExtendedPropertyToForm(val, "sourceContainerArray")
                }
              />
              {!!sourceContainerArrayExtendedValuesEntities.length && (
                <div style={{ minHeight: 200, marginTop: 20 }}>
                  <RecordExtendedPropertiesTable
                    isSimple
                    compact
                    noEdit
                    formName="addExtendedPropertiesPlateReformatSourceContainerArrayTable"
                    onDelete={record =>
                      this.removeExtendedProperty(
                        record,
                        "sourceContainerArray"
                      )
                    }
                    entities={sourceContainerArrayExtendedValuesEntities}
                  />
                </div>
              )}
            </div>
            <div className="tg-flex-separator" />
            <div style={{ width: "45%", height: "100%" }}>
              <h6>Source Aliquots</h6>
              <AddExtendedProperty
                model="aliquot"
                fullValueFields
                noLinkProperties
                formName="addExtendedPropertiesPlateReformatSourceAliquot"
                extendedPropertyIdsToIgnore={sourceAliquotExtendedValues.map(
                  val => val.extendedProperty.id
                )}
                onSubmitValue={val =>
                  this.saveExtendedPropertyToForm(val, "sourceAliquot")
                }
              />
              {!!sourceAliquotExtendedValuesEntities.length && (
                <div style={{ minHeight: 200, marginTop: 20 }}>
                  <RecordExtendedPropertiesTable
                    isSimple
                    formName="addExtendedPropertiesPlateReformatSourceAliquotTable"
                    compact
                    noEdit
                    onDelete={record =>
                      this.removeExtendedProperty(record, "sourceAliquot")
                    }
                    entities={sourceAliquotExtendedValuesEntities}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="tg-flex justify-space-between"
            style={{ marginBottom: 20 }}
          >
            <div style={{ width: "45%", height: "100%" }}>
              <h6>Destination Plates</h6>
              <AddExtendedProperty
                model="containerArray"
                fullValueFields
                noLinkProperties
                formName="addExtendedPropertiesPlateReformatDestinationContainerArray"
                extendedPropertyIdsToIgnore={destinationContainerArrayExtendedValues.map(
                  val => val.extendedProperty.id
                )}
                onSubmitValue={val =>
                  this.saveExtendedPropertyToForm(
                    val,
                    "destinationContainerArray"
                  )
                }
              />
              {!!destinationContainerArrayExtendedValuesEntities.length && (
                <div style={{ minHeight: 200, marginTop: 20 }}>
                  <RecordExtendedPropertiesTable
                    isSimple
                    compact
                    noEdit
                    formName="addExtendedPropertiesPlateReformatDestinationContainerArrayTable"
                    onDelete={record =>
                      this.removeExtendedProperty(
                        record,
                        "destinationContainerArray"
                      )
                    }
                    entities={destinationContainerArrayExtendedValuesEntities}
                  />
                </div>
              )}
            </div>
            <div className="tg-flex-separator" />
            <div style={{ width: "45%", height: "100%" }}>
              <h6>Destination Aliquots</h6>
              <AddExtendedProperty
                model="aliquot"
                fullValueFields
                noLinkProperties
                formName="addExtendedPropertiesPlateReformatDestinationAliquot"
                extendedPropertyIdsToIgnore={destinationAliquotExtendedValues.map(
                  val => val.extendedProperty.id
                )}
                onSubmitValue={val =>
                  this.saveExtendedPropertyToForm(val, "destinationAliquot")
                }
              />
              {!!destinationAliquotExtendedValuesEntities.length && (
                <div style={{ minHeight: 200, marginTop: 20 }}>
                  <RecordExtendedPropertiesTable
                    formName="addExtendedPropertiesPlateReformatDestinationAliquotTable"
                    isSimple
                    compact
                    noEdit
                    onDelete={record =>
                      this.removeExtendedProperty(record, "destinationAliquot")
                    }
                    entities={destinationAliquotExtendedValuesEntities}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </AdvancedOptions>
    );

    return (
      <>
        <ReviewWorklistSection
          toolSchema={toolSchema}
          schema={schema}
          sectionFooter={extendedPropertiesSection}
        />
        {warning && (
          <Callout className="preserve-newline" intent="warning">
            {warning}
          </Callout>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              loading={footerProps.submitting}
            >
              Generate Worklist
            </Button>
          }
        />
      </>
    );
  }
}

export default compose(
  stepFormValues(
    "warning",
    "worklist",
    "operationType",
    "destinationAliquotExtendedProperties",
    "destinationContainerArrayExtendedProperties",
    "destinationContainerArrayExtendedValues",
    "destinationAliquotExtendedValues",
    "sourceAliquotExtendedProperties",
    "sourceContainerArrayExtendedProperties",
    "sourceContainerArrayExtendedValues",
    "sourceAliquotExtendedValues"
  )
)(ReviewWorklist);

export const breakdownTransferSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source Plate",
      path: "sourceAliquotContainer.containerArray.name"
    },
    sourcePositionColumn,
    {
      displayName: "Destination Plate",
      path: "destinationPlateName"
    },
    destinationPositionColumn,
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    }
  ]
};
