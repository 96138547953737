/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getSelectedBinIds } from "../../../selectors/designViewSelectors";
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import { getElementsInBin } from "../../../../../tg-iso-design/selectors/designStateSelectors";

export default function alphabetizeParts(designState, __, state) {
  const changeSetsHelper = new ChangeSetsHelper(designState);

  const binIds = getSelectedBinIds(state);
  for (const binId of binIds) {
    const elements = getElementsInBin(state, binId);
    const sortedElements = [...elements].sort((a, b) =>
      ("" + a.name).localeCompare(b.name)
    );
    changeSetsHelper.updatePure(
      "element",
      sortedElements.map((el, index) => ({ id: el.id, index }))
    );
  }

  return changeSetsHelper.execute();
}
