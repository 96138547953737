/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { drawSigmaSeqLengthHistogram } from "./d3/sigmaSeqLengthHistogram";
import modelSigmaSeqLengthHistogramWidgetStyles from "./ModelSigmaSeqLengthHistogramWidgetStyles.module.css";
import ReactResizeDetector from "react-resize-detector";

const TAG = "[ModelGroupedHistogramWidget]"; // eslint-disable-line

class ModelSigmaSeqLengthHistogramWidget extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    const formattedData = this.formatData(data);
    const maxYValue =
      Math.round(
        Math.max(
          ...Object.values(formattedData).map(sigmaGroup =>
            Math.max(
              ...sigmaGroup.map(lengthGroup =>
                Math.max(
                  ...lengthGroup.values.map(
                    lengthElement => lengthElement.value
                  )
                )
              )
            )
          )
        )
      ) + 1;
    const chartContainerClass =
      "_" +
      Math.random()
        .toString(36)
        .slice(2, 6);
    this.state = {
      chartContainerClass: chartContainerClass,
      formattedData: formattedData,
      defaultSigmaToPlot: formattedData[data.output_data[0].sigma],
      maxYValue
    };
  }

  formatData = unformattedData => {
    const { input_data, output_data } = unformattedData;

    const formattedData = {};
    const densityHistogram = Object.keys(
      output_data[0]["lengths_histogram"][0]
    ).includes("density");

    const inputDataByLength = {};
    input_data[0]["lengths_histogram"].forEach(datapoint => {
      inputDataByLength[`${datapoint.lower_edge}-${datapoint.upper_edge}`] = {
        type: "input",
        value: densityHistogram ? datapoint.density * 100 : datapoint.frequency
      };
    });
    output_data.forEach(outputPoint => {
      formattedData[outputPoint.sigma] = [];
      const outputDataByLengthBySigma = {};
      outputPoint["lengths_histogram"].forEach(datapoint => {
        outputDataByLengthBySigma[
          `${datapoint.lower_edge}-${datapoint.upper_edge}`
        ] = {
          type: "output",
          value: densityHistogram
            ? datapoint.density * 100
            : datapoint.frequency
        };
      });
      Object.keys(inputDataByLength).forEach(lengthRange => {
        formattedData[outputPoint.sigma].push({
          lower_edge: lengthRange.split("-")[0],
          upper_edge: lengthRange.split("-")[1],
          values: [
            inputDataByLength[lengthRange],
            outputDataByLengthBySigma[lengthRange]
          ]
        });
      });
    });

    return formattedData;
  };

  componentDidMount() {
    const {
      chartContainerClass,
      formattedData,
      defaultSigmaToPlot
    } = this.state;
    const { sigmaToPlot } = this.props;

    let dataToPlot = defaultSigmaToPlot;

    if (sigmaToPlot) {
      dataToPlot = formattedData[sigmaToPlot];
    }

    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;

    drawSigmaSeqLengthHistogram(
      chartContainerClass,
      height,
      width,
      dataToPlot,
      this.state.maxYValue
    );
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    if (this.divElement) {
      if (
        this.state.width === this.divElement.clientWidth &&
        this.state.height === this.divElement.clientHeight
      ) {
        return false;
      } else {
        this.setState(() => {
          return {
            width: this.divElement.clientWidth,
            height: this.divElement.clientHeight
          };
        });
      }
    }
  };

  render() {
    const {
      chartContainerClass,
      formattedData,
      defaultSigmaToPlot
    } = this.state;
    const { sigmaToPlot } = this.props;

    let dataToPlot = defaultSigmaToPlot;

    if (sigmaToPlot) {
      dataToPlot = formattedData[sigmaToPlot];
    }

    if (this.divElement) {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;

      drawSigmaSeqLengthHistogram(
        chartContainerClass,
        height,
        width,
        dataToPlot,
        this.state.maxYValue
      );
    }
    return (
      <div
        className={modelSigmaSeqLengthHistogramWidgetStyles.container}
        ref={divElement => (this.divElement = divElement)}
      >
        <div
          className={`${modelSigmaSeqLengthHistogramWidgetStyles.viz} ${chartContainerClass}`}
        />
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.updateDimensions}
          targetDomEl={this.divElement}
        />
      </div>
    );
  }
}

export default ModelSigmaSeqLengthHistogramWidget;
