/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Color from "color";
import React, { Component } from "react";
import { Popover } from "@blueprintjs/core";
import classNames from "classnames";
import "./style.css";
import getAvatarIconPath from "../utils/getAvatarIconPath";
import { toLength } from "lodash";

class AvatarIcon extends Component {
  render() {
    const { user, size = 40, style = {}, noHover, menu, ...rest } = this.props;
    const { avatarFile, id, firstName = "", lastName = "" } = user || {};
    const { path } = avatarFile || {};
    const trimmedPath = getAvatarIconPath(user);
    const styleToPass = {
      width: size,
      height: size,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      ...style
    };

    const avatarIcon = (
      <div
        {...rest}
        className={classNames("tg-avatar-button", {
          "has-hover": !!menu
        })}
      >
        {path ? (
          <img
            crossOrigin="anonymous"
            style={{ ...styleToPass }}
            alt="avatar"
            src={trimmedPath}
          />
        ) : (
          <div
            style={{
              ...styleToPass,
              fontSize: size / 2.7,
              backgroundColor: genColor(id),
              color: "white"
            }}
          >
            {firstName && lastName && firstName[0] + lastName[0]}
          </div>
        )}
      </div>
    );
    if (menu) {
      return (
        <Popover position="bottom-right" content={menu}>
          {avatarIcon}
        </Popover>
      );
    } else {
      return avatarIcon;
    }
  }
}

export default AvatarIcon;

function genColor(seed = 1) {
  // const colors = ["#2965CC", "#29A634", "#D99E0B", "#D13913", "#8F398F", "#00B3A4", "#DB2C6F", "#9BBF30", "#96622D", "#7157D9"];
  // return colors[seed % colors.length];

  if (!Number.isInteger(seed)) {
    seed = toLength(seed);
  }

  let color = Math.floor(Math.abs(Math.sin(seed) * 16777215) % 16777215);
  color = color.toString(16);
  // pad any colors shorter than 6 characters with leading 0s
  while (color.length < 6) {
    color = "0" + color;
  }
  //NOTE: SD use these "Qualitative colors" instead (from blueprint)??
  // ["#2965CC", "#29A634", "#D99E0B", "#D13913", "#8F398F", "#00B3A4", "#DB2C6F", "#9BBF30", "#96622D", "#7157D9"]
  color = "#" + color;
  color = Color(color);
  if (color.isLight()) {
    color = color.negate();
  }

  return color.hsl().string();
}
