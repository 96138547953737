/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as d3 from "d3";
import modelScatterPlotWidgetStyles from "../ModelScatterPlotWidgetStyles.module.css";
import widgetCardStyles from "../../../WidgetCardStyles.module.css";
import { isNil } from "lodash";

const TAG = "[D3Scatter]"; // eslint-disable-line
const MAX_ANIMATION_TIME_MS = 2000;
const MIN_ANIMATION_TIME_MS = 1000;

const COMPUTED_LABELS_CACHE = {};

function drawScatter(
  htmlComponentClass,
  height,
  width,
  dataPoints,
  descriptors,
  xAxisValueName,
  yAxisValueName
) {
  d3.selectAll(`.${htmlComponentClass} *`).remove();

  const w = width - 20;
  const h = height - 20;

  const svg = d3
    .select(`.${htmlComponentClass}`)
    .append("svg")
    .attr("height", h + 20)
    .attr("width", w + 20);

  const tooltip = d3
    .select(`.${htmlComponentClass}`)
    .append("div")
    .attr("class", modelScatterPlotWidgetStyles.tooltip)
    .style("opacity", 0);

  const xValue = val => {
    return val[xAxisValueName];
  };
  const xScale = d3.scaleLinear().range([45, w - 20]);
  const xMap = val => {
    return xScale(xValue(val));
  };

  const xAxis = d3.axisBottom(xScale);

  const yValue = d => {
    return d[yAxisValueName];
  };
  const yScale = d3.scaleLinear().range([h - 20, 20]);
  const yMap = d => {
    return yScale(yValue(d));
  };
  const yAxis = d3.axisLeft(yScale);

  const xDomainMarginRatio = 0.05;
  const xDomainMargin =
    (d3.max(dataPoints, xValue) - d3.min(dataPoints, xValue)) *
    xDomainMarginRatio;

  xScale.domain([
    d3.min(dataPoints, xValue) - xDomainMargin,
    d3.max(dataPoints, xValue) + xDomainMargin
  ]);

  const yDomainMarginRatio = 0.1;
  const yDomainMargin =
    (d3.max(dataPoints, yValue) - d3.min(dataPoints, yValue)) *
    yDomainMarginRatio;

  yScale.domain([
    d3.min(dataPoints, yValue) - yDomainMargin,
    d3.max(dataPoints, yValue) + yDomainMargin
  ]);

  // x-axis
  svg
    .append("g")
    .attr("class", widgetCardStyles.axis)
    .attr("transform", "translate(0," + (h - 20) + ")")
    .call(xAxis);

  svg
    .append("text")
    .attr("class", widgetCardStyles.label)
    .attr("x", w / 2)
    .attr("y", h + 15)
    .style("text-anchor", "middle")
    .text(xAxisValueName);

  // y-axis
  svg
    .append("g")
    .attr("class", widgetCardStyles.axis)
    .attr("transform", "translate(" + 45 + ", 0)")
    .call(yAxis);

  svg
    .append("text")
    .attr("class", widgetCardStyles.label)
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(h / 2))
    .attr("dy", "3.4em")
    .style("text-anchor", "middle")
    .text(yAxisValueName);

  const maxX = Math.min(d3.max(dataPoints, xValue), d3.max(dataPoints, yValue));
  const minX = Math.max(
    d3.min(dataPoints, xValue) - xDomainMargin,
    d3.min(dataPoints, yValue) - yDomainMargin
  );

  svg
    .append("line")
    .attr("class", modelScatterPlotWidgetStyles.yEqualXLine)
    .attr("x1", xScale(minX))
    .attr("x2", xScale(maxX))
    .attr("y1", yScale(minX))
    .attr("y2", yScale(maxX));

  const animationScale = d3
    .scaleLinear()
    .domain([0, dataPoints.length])
    .range([MIN_ANIMATION_TIME_MS, MAX_ANIMATION_TIME_MS]);

  svg
    .selectAll(".dot")
    .data(dataPoints)
    .enter()
    .append("circle")
    .attr("class", modelScatterPlotWidgetStyles.dot)
    .attr("r", 4)
    .attr("opacity", 0)
    .attr("cx", xMap)
    .attr("cy", yScale(d3.min(dataPoints, yValue) - yDomainMargin))
    .on("mouseover", (event, d) => {
      const index = JSON.stringify(d);

      let label = COMPUTED_LABELS_CACHE[index];

      if (isNil(label)) {
        label = "";
        for (let i = 0; i < descriptors.length; i++) {
          label += `${d[descriptors[i].name]} `;
          COMPUTED_LABELS_CACHE[index] = label;
        }
      }

      d3.select(event.target)
        .attr("class", modelScatterPlotWidgetStyles.hoveredDot)
        .transition()
        .duration(100)
        .attr("r", 6);

      tooltip.transition().duration(100).style("opacity", 0.95);
      tooltip
        .html(
          `<span class=${
            modelScatterPlotWidgetStyles.label
          }>${label}</span><br />(${xValue(d)}, ${yValue(d)})`
        )
        .style("left", event.pageX + 5 + "px")
        .style("top", event.pageY - 32 + "px");
    })
    .on("mouseout", event => {
      d3.select(event.target)
        .attr("class", modelScatterPlotWidgetStyles.dot)
        .transition()
        .duration(100)
        .attr("r", 4);

      tooltip.transition().duration(500).style("opacity", 0);
    })
    .attr("pointer-events", "none")
    .transition()
    .duration((d, i) => {
      return animationScale(i);
    })
    .ease(d3.easeSin)
    .call(selection =>
      selection
        .attr("opacity", 1)
        .attr("cx", d => xMap(d))
        .attr("cy", d => yMap(d))
        .transition()
        .attr("pointer-events", "auto")
    );
}

export { drawScatter };
