/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isBrowser } from "browser-or-node";
import { camelCase, snakeCase } from "lodash";

export default function getEnvIso(envVarName) {
  if (isBrowser) {
    if (!window.frontEndConfig) {
      return;
    }
    const toRet = window.frontEndConfig[camelCase(envVarName)];
    return toRet;
  } else {
    const toUse = snakeCase(envVarName).toUpperCase();
    return process.env[toUse];
  }
}
