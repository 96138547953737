/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button } from "@blueprintjs/core";
import { uniq } from "lodash";
import React from "react";
import { compose } from "recompose";
import { withSelectedEntities, withSelectTableRecords } from "@teselagen/ui";
import stepFormValues from "../../../../../../src-shared/stepFormValues";

import { assemblyPieceTableName } from "../../constants";

function SelectAssemblyPiecesButton({
  change,
  [assemblyPieceTableName + "SelectedEntities"]: selectedAssemblyPieces = [],
  finalizedAPIds = [],
  selectTableRecords
}) {
  return (
    <Button
      style={{ width: "100%" }}
      text={`Select ${selectedAssemblyPieces.length} Pieces`}
      disabled={!selectedAssemblyPieces.length}
      intent="primary"
      rightIcon="double-chevron-right"
      onClick={() => {
        const newIds = selectedAssemblyPieces.map(p => p.id);
        change("finalizedAPIds", uniq(newIds.concat(finalizedAPIds)));
        selectTableRecords([]);
      }}
    />
  );
}

export default compose(
  withSelectedEntities(assemblyPieceTableName),
  withSelectTableRecords(assemblyPieceTableName),
  stepFormValues("finalizedAPIds")
)(SelectAssemblyPiecesButton);
