/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { compose } from "redux";
import { pick, get } from "lodash";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";
import { withProps } from "recompose";

const SaveReactionPresetDialog = ({
  fieldRowInfo,
  hideModal,
  reactionConditionGroupName,
  handleSubmit,
  submitting
}) => {
  const onSubmit = async ({ name }) => {
    try {
      await safeUpsert("reactionConditionGroupPreset", {
        name,
        reactionConditionPresets: fieldRowInfo.map(preset => {
          return {
            ...pick(preset, [
              "mass",
              "massUnitCode",
              "volume",
              "volumetricUnitCode",
              "concentration",
              "concentrationUnitCode"
            ]),
            materialId: get(preset, "material.id"),
            additiveMaterialId: get(preset, "additiveMaterial.id")
          };
        })
      });
      hideModal();
    } catch (error) {
      window.toastr.error("Error saving preset.");
      console.error("error:", error);
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          label="Name"
          isRequired
          defaultValue={reactionConditionGroupName}
          name="name"
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        loading={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({ title: "Save Reaction Condition Group Preset" }),
  withProps(() => ({
    model: "reactionConditionGroupPreset"
  })),
  reduxForm({
    form: "saveReactionPreset",
    ...asyncValidateName
  })
)(SaveReactionPresetDialog);
