/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { get } from "lodash";
import { RadioGroupField, withSelectedEntities } from "@teselagen/ui";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { microbialReactionPlanningPlateFragment } from "../fragments";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { isEmpty } from "lodash";
import { validateMaterialPlates } from "../../../../utils/plateUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

function aliquotAppropriateMaterialError(aliquot) {
  const material = get(aliquot, "sample.material");
  if (material && material.materialTypeCode === "MICROBIAL") {
    if (!material.strain) {
      return "This aliquot contains a microbial material with no strain.";
    }
    if (!material.microbialMaterialMicrobialMaterialPlasmids.length) {
      return "This aliquot contains a microbial material with no plasmid.";
    }
    if (material.microbialMaterialMicrobialMaterialPlasmids.length > 1) {
      return "This aliquot contains a microbial material with multiple plasmids. It should be linked to a single plasmid.";
    }
  }
}

class SelectPlates extends Component {
  render() {
    const {
      Footer,
      footerProps,
      reactionType,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      containerArrays = []
    } = this.props;

    let plateErrors = {};

    if (containerArrays.length) {
      plateErrors = validateMaterialPlates(containerArrays, {
        asObject: true,
        allowDry: true,
        enforceSequenceLinks:
          !window.frontEndConfig.enableNonSequenceDnaMaterials &&
          reactionType === "CLONAL_TRANSFORMATION",
        materialTypeCode:
          reactionType === "CLONAL_TRANSFORMATION" ? "DNA" : "MICROBIAL"
      });
      if (reactionType === "CONJUGATION") {
        containerArrays.forEach(containerArray => {
          containerArray.aliquotContainers.forEach(ac => {
            if (ac.aliquot) {
              const error = aliquotAppropriateMaterialError(ac.aliquot);
              if (error) {
                if (!plateErrors[containerArray.id]) {
                  plateErrors[containerArray.id] = {};
                }
                const acLocation = getAliquotContainerLocation(ac, {
                  force2D: true
                });
                if (!plateErrors[containerArray.id][acLocation]) {
                  plateErrors[containerArray.id][acLocation] = [];
                }
                plateErrors[containerArray.id][acLocation] &&
                  plateErrors[containerArray.id][acLocation].push(error);
              }
            }
          });
        });
      }
    }
    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Choose Reaction Type"
            helper={`Choose either Microbial Transformation or Bacterial
            Conjugation. Microbial Transformation will require you to select
            plates of DNA plates below, while Bacterial Conjugation will require you
            to select plates of microbial materials with plasmids.`}
          />
          <RadioGroupField
            name="reactionType"
            defaultValue="CLONAL_TRANSFORMATION"
            options={[
              {
                label: "Microbial Transformation",
                value: "CLONAL_TRANSFORMATION"
              },
              { label: "Bacterial Conjugation", value: "CONJUGATION" }
            ]}
            label="Reaction Type"
          />
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Input Plates"
            helper={`Select one or more plates of ${
              reactionType === "CLONAL_TRANSFORMATION"
                ? "DNA"
                : "microbes with plasmids"
            } from inventory.`}
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "containerArrays",
                isRequired: true,
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                fragment: [
                  "containerArray",
                  "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: microbialReactionPlanningPlateFragment,
                postSelectDTProps: {
                  formName: "microbialTransformationPlateSelect",
                  plateErrors,
                  schema: [
                    platePreviewColumn({
                      plateErrors
                    }),
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" }
                  ]
                },
                buttonProps: {
                  loading: isLoadingMap.containerArrays,
                  disabled: isDisabledMap.containerArrays
                }
              }}
            />
          </div>
        </div>
        <Footer
          nextDisabled={!isEmpty(plateErrors)}
          {...footerProps}
          errorMessage={!isEmpty(plateErrors) && "Please review form errors."}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("aliquotContainers", "containerArrays", "reactionType"),
  withSelectedEntities("microbialTransformationPlateSelect")
)(SelectPlates);
