/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { wrapDialog } from "@teselagen/ui";
import { DataTable, DialogFooter, withSelectedEntities } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { getUserLabs } from "../utils/labUtils";

class ChooseLabDialog extends Component {
  onSelect = lab => {
    const { hideModal, onSelect } = this.props;
    onSelect(lab);
    hideModal();
  };

  render() {
    const {
      hideModal,
      currentUser,
      chooseSingleLabTableSelectedEntities = []
    } = this.props;

    const options = getUserLabs(currentUser);

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <DataTable
            noPadding
            schema={["name"]}
            entities={options}
            maxHeight={350}
            isSingleSelect
            formName="chooseSingleLabTable"
            onDoubleClick={this.onSelect}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          disabled={!chooseSingleLabTableSelectedEntities.length}
          onClick={() => this.onSelect(chooseSingleLabTableSelectedEntities[0])}
          text="Select Lab"
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Lab"
  }),
  withSelectedEntities("chooseSingleLabTable")
)(ChooseLabDialog);
