/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { cloneDeep, get, isObject, map } from "lodash";
import { tidyUpSequenceData } from "@teselagen/sequence-utils";
import { getDigestPartFields } from "../../utils/digestPartUtils";
import { getSequence } from "../utils/getSequence";
import { omit } from "lodash";

const j5WarningPragmas = ["j5_warning", "j5_likely_fail"];

const j5Pragmas = [
  "j5_propagated_part",
  "j5_assembly_piece",
  ...j5WarningPragmas
];

const formatNotes = (notes, pragma) => {
  const formattedNotes = { pragma };
  if (isObject(notes)) {
    Object.assign(formattedNotes, notes);
  } else if (typeof notes === "string") {
    if (j5WarningPragmas.includes(pragma)) {
      Object.assign(formattedNotes, { warnings: [notes] });
    } else {
      Object.assign(formattedNotes, { extras: [notes] });
    }
  }
  return formattedNotes;
};

// This function could actually be shared for other logics that need a compatibility check.
const compatibleFeatureJ5Notes = feature => {
  if (j5Pragmas.includes(feature.type)) {
    feature.notes = formatNotes(feature.notes, feature.type);
    feature.type = "misc_feature";
  }
};

export const sequenceToVeInput = (
  sequence,
  {
    isProtein: _isProtein = false,
    annotationsAsObjects = true,
    noParts = false,
    isExport,
    upperCase,
    hideAssemblyAnnotations
  } = {}
) => {
  sequence = cloneDeep(sequence);
  let isMixedRnaAndDna = false;
  if (!sequence) return sequence;
  const isProtein = _isProtein || sequence.isProtein;
  const sequenceStr = getSequence(sequence, { upperCase });
  let features = [];
  const primers = [];
  const lineageAnnotations = [];
  const assemblyPieces = [];
  const warnings = [];
  let parts = [];
  if (isProtein) {
    features = sequence.regionAnnotations.map(ann => ({
      notes: [],
      ...ann,
      strand: 1
    }));
    parts = map(sequence.aminoAcidParts, ann => ({
      notes: [],
      ...ann,
      strand: 1
    }));
  } else {
    parts = map(
      sequence.parts || [],
      ({
        id,
        name,
        type,
        start,
        end,
        strand,
        taggedItems,
        overlapsSelf,
        notes,
        ...rest
      }) => ({
        id,
        name,
        type,
        start,
        end,
        strand,
        overlapsSelf,
        ...(taggedItems && {
          tags: (taggedItems || []).map(pt => {
            if (pt.tagOptionId) {
              return `${pt.tagId}:${pt.tagOptionId}`;
            } else {
              return pt.tagId;
            }
          })
        }),
        taggedItems,
        notes: {
          ...notes,
          ...(isExport && { pragma: ["Teselagen_Part"] }),
          // tag: partTags ? partTags.map(pt => pt.tag.name) : [],
          tag: isExport && taggedItems ? taggedItems.map(pt => pt.tag.name) : []
        },
        ...(rest.isDigestPart && {
          ...getDigestPartFields(rest, { withRestrictionEnzymes: true })
        })
      })
    );
    // if (sequence.chromatogramData) {
    //   sequence.chromatogramData = convertBasePosTraceToPerBpTrace(
    //     sequence.chromatogramData
    //   );
    // }
    if (sequence.sequenceFeatures) {
      sequence.sequenceFeatures.forEach(feature => {
        compatibleFeatureJ5Notes(feature);
        if (feature.type === "primer" || feature.type === "primer_bind") {
          primers.push({
            ...feature,
            useLinkedOligo: !!get(feature, "oligoWithBindingSite.name"),
            oligoWithBindingSiteId: get(feature, "oligoWithBindingSiteId"),
            linkedOligo: get(feature, "oligoWithBindingSite.name"),
            bases: get(feature, "oligoWithBindingSite.fullSequenceRaw")
          });
        } else if (
          get(feature, "notes.pragma", []).includes("j5_propagated_part")
        ) {
          !hideAssemblyAnnotations &&
            lineageAnnotations.push({
              ...feature,
              labelColor: "#8F398F",
              color: "#8F398F"
            });
        } else if (
          get(feature, "notes.pragma", []).includes("j5_assembly_piece")
        ) {
          let colorToUse = "#2965CC";
          let message;
          for (const [type, color] of [
            ["Digest Linearized", "#2D93AD"],
            ["Direct Synthesis/PCR", "#43aa8b"],
            ["PCR", "#A7BBEC"]
          ]) {
            if (get(feature, "notes.creationMethod", []).includes(type)) {
              colorToUse = color;
              message = `Creation Method: ${type}`;
            }
          }
          !hideAssemblyAnnotations &&
            assemblyPieces.push({
              ...feature,
              message,
              labelColor: colorToUse,
              color: colorToUse
            });
        } else if (get(feature, "notes.pragma", []).includes("j5_warning")) {
          !hideAssemblyAnnotations &&
            warnings.push({
              ...feature,
              labelColor: "#D99E0B",
              color: "gold",
              message: get(feature, "notes.warnings[0]", "")
            });
        } else if (
          get(feature, "notes.pragma", []).includes("j5_likely_fail")
        ) {
          !hideAssemblyAnnotations &&
            warnings.push({
              ...feature,
              labelColor: "red",
              color: "red",
              message: get(feature, "notes.warnings[0]", "")
            });
        } else if (
          feature.type === "primer" ||
          feature.type === "primer_bind"
        ) {
          primers.push(feature);
        } else if (
          isExport &&
          feature.strand === 1 &&
          feature.name === "tg_uracil"
        ) {
          isMixedRnaAndDna = true;
          sequence = sequence
            .slice(0, feature.start)
            .concat(upperCase ? "U" : sequence[0].match(/[A-Z]/) ? "U" : "u")
            .concat(sequence.slice(feature.end + 1));
        } else {
          features.push({
            notes: {},
            ...feature
          });
        }
      });
    }
  }
  // tnw: do not pass thru sequenceFragments, fullSequence, fullSequenceRaw, as they are not needed by VE
  const {
    fullSequenceRaw,
    fullSequence,
    sequenceFragments,
    ...toSpread
  } = sequence;
  const inputSequence = tidyUpSequenceData(
    {
      ...toSpread,
      type: isProtein ? "PROTEIN" : "DNA",
      isProtein,
      noSequence: sequence.noSequence,
      description: sequence.description,
      isOligo: sequence.sequenceTypeCode === "OLIGO",
      isRna: sequence.sequenceTypeCode === "RNA",
      proteinSequence: isProtein ? sequenceStr : undefined,
      sequence: !isProtein ? sequenceStr : undefined,
      circular: sequence.circular,
      name: sequence.name,
      proteinSize: isProtein ? sequence.size : undefined,
      size: !isProtein ? sequence.size : undefined,
      primers,
      features,
      lineageAnnotations,
      assemblyPieces,
      warnings,
      parts
    },
    {
      doNotRemoveInvalidChars: true,
      isMixedRnaAndDna,
      annotationsAsObjects
    }
  );
  if (noParts) return omit(inputSequence, "parts");
  return inputSequence;
};
