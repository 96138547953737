/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Route } from "react-router-dom";
import auth from "../auth";

export const EnhancedRoute = ({
  component: Component,
  loggedIn,
  privateRoute,
  path,
  currentUser,
  exact,
  ...rest
}) => {
  const renderEnhancedComponent = ({
    history,
    location,
    match,
    staticContext
  }) => {
    if (loggedIn || !privateRoute) {
      return (
        <Component
          loggedIn={loggedIn}
          currentUser={currentUser}
          history={history}
          location={location}
          match={match}
          staticContext={staticContext}
          {...rest}
        />
      );
    } else {
      auth.logout();
      return null;
    }
  };

  return <Route path={path} exact={exact} render={renderEnhancedComponent} />;
};

export default EnhancedRoute;
