/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getActiveLabId } from "./getActiveLabId.js";
import { getActiveProjectId } from "./getActiveProjectId.js";

/// The following constants are duplicate in auth-utils/src/ext-utils/constants.js
/// they need to be refactored to a common DRY source
export const impersonatedUserIdHeaderKey = "tg-lims-user-id"; // this is only used by super user requests (from backend)
export const activeLabHeaderKey = "tg-active-lab-id";
export const activeProjectHeaderKey = "tg-project-id";
export const activeBatchHeaderKey = "tg-active-batch-id";
export const importCollectionHeaderKey = "tg-active-import-collection-id";

export function getRequestHeaderKeys() {
  // get the authentication activeBatchToken from local storage if it exists
  // const activeBatchId = localStorage.getItem("activeBatchId");
  const token = localStorage.getItem("authToken-teselagen");
  const importCollectionId = sessionStorage.getItem("importCollectionId");

  const activeProjectId = getActiveProjectId();
  const activeLabId = getActiveLabId();

  const headers = {
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  };

  headers[activeLabHeaderKey] = activeLabId;

  if (activeProjectId) {
    headers[activeProjectHeaderKey] = activeProjectId;
  }
  if (importCollectionId) {
    headers[importCollectionHeaderKey] = importCollectionId;
  }

  return headers;
}
