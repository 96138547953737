/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InputField } from "@teselagen/ui";
import RecordInfoDisplay from "../../../src-shared/RecordInfoDisplay";
import { getVendorParams } from "../Bioshop/Vendors/VendorFunctions";

function SequenceScoringSummary({
  smallerScreen,
  selectedSequences,
  sequencesToScore,
  totalPrice,
  vendorCode
}) {
  return (
    <div
      style={{
        marginLeft: 40,
        minWidth: 380,
        marginTop: smallerScreen ? 25 : undefined
      }}
    >
      <h6>Summary</h6>
      <RecordInfoDisplay
        recordInfo={[
          [
            "Total Selected Sequences",
            `${selectedSequences.length} out of ${sequencesToScore.length}`
          ],
          ["Synthesis Vendor", getVendorParams(vendorCode).vendorTitle],
          ["Estimated Total", `$${totalPrice.toFixed(2)}`]
        ]}
      />
      <div style={{ marginTop: 15, maxWidth: 250 }}>
        <InputField name="orderName" label="Order Name" isRequired />
      </div>
    </div>
  );
}

export default SequenceScoringSummary;
