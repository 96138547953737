/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { observable } from "mobx";

export const activeRecordStore = observable({
  name: ""
});

export const setActiveRecord = ({ name }) => {
  activeRecordStore.name = name;
};
