/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default {
  model: "partset",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "numParts", type: "number", displayName: "Size" },
    { path: "createdAt", type: "timestamp", displayName: "Created" },
    { path: "updatedAt", type: "timestamp", displayName: "Modified" }
  ]
};
