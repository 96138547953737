/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Icon, Tooltip } from "@blueprintjs/core";
import React from "react";

export const InventoryCheckIcon = ({ inInventory }) => (
  <Tooltip content={inInventory ? "In inventory" : "Not in inventory"}>
    <Icon
      intent={inInventory ? "success" : "warning"}
      icon={inInventory ? "tick" : "warning-sign"}
      style={{ marginRight: 5 }}
    />
  </Tooltip>
);
