/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as d3 from "d3";
import modelGroupedHistogramWidgetStyles from "../ModelGroupedHistogramWidgetStyles.module.css";
import widgetCardStyles from "../../../WidgetCardStyles.module.css";

function svgSelection(htmlComponentClass, width, height) {
  return d3
    .select(`.${htmlComponentClass}`)
    .append("svg")
    .attr("height", height)
    .attr("width", width);
}

function drawGroupedHistogram(htmlComponentClass, height, width, data) {
  const categories = [
    {
      graph: "variant",
      name: "batch",
      label: "suggested batch",
      cssColorClass: modelGroupedHistogramWidgetStyles.batchRect,
      clicked: false,
      hidden: false
    },
    {
      graph: "variant",
      name: "exp",
      label: "experimental batch",
      cssColorClass: modelGroupedHistogramWidgetStyles.expRect,
      clicked: false,
      hidden: false
    }
  ];

  d3.selectAll(`.${htmlComponentClass} *`).remove();

  const margin = 20;

  const x0 = d3
    .scaleBand()
    .domain(data.xLabels)
    .rangeRound([2.5 * margin, width - 2 * margin])
    .paddingInner(0.1);

  const xAxis = g =>
    g
      .attr("transform", `translate(${0},${height - 2 * margin})`)
      .attr("class", widgetCardStyles.axis)
      .call(d3.axisBottom(x0).tickSizeOuter(0));

  const maxY = d3.max(data.dataPoints, d =>
    d3.max(categories, category => d[category.name])
  );

  const y = d3
    .scaleLinear()
    .domain([0, maxY * 1.1])
    .nice()
    .rangeRound([height - 2 * margin, margin]);

  const yAxis = g =>
    g
      .attr("transform", `translate(${2.5 * margin},${0})`)
      .call(d3.axisLeft(y).ticks(null, "s"))
      .attr("class", widgetCardStyles.axis)
      .call(g =>
        g
          .select(".tick:last-of-type text")
          .clone()
          .attr("x", 3)
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
      );

  const x1 = d3
    .scaleBand()
    .domain(categories.map(category => category.name))
    .rangeRound([0, x0.bandwidth()])
    .padding(0.05);

  const svg = svgSelection(htmlComponentClass, width, height);

  const legend = svg => {
    const g = svg
      .attr("transform", `translate(${width - 2 * margin},0)`)
      .attr("text-anchor", "end")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("class", modelGroupedHistogramWidgetStyles.legend)
      .selectAll("g")
      .data(categories)
      .join("g")
      .attr("transform", (d, i) => `translate(0,${margin + i * 20})`);

    g.append("rect")
      .attr("x", -19)
      .attr("width", 19)
      .attr("height", 19)
      .attr("class", d => d.cssColorClass)
      .on("mouseover", function (d) {
        if (!d.hidden) d3.select(this).style("cursor", "pointer");
      })
      .on("mouseout", function () {
        d3.select(this).style("cursor", "default");
      })
      .on("click", d => {
        filterBars(d);
      });

    g.append("text")
      .attr("x", -24)
      .attr("y", 9.5)
      .attr("dy", "0.35em")
      .text(d => {
        return d.name === "batch"
          ? "Suggested samples"
          : "Experimental samples";
      });
  };

  svg.append("g").call(legend);

  const barsAreaSelection = svg.append("g");

  const featuresSelection = barsAreaSelection
    .selectAll("g")
    .data(Object.entries(data)[2][1])
    .join("g")
    .attr("transform", d => `translate(${x0(d.feature)},0)`);

  const t = svg.transition().duration(1500);

  featuresSelection
    .selectAll("rect")
    .data(d =>
      categories.map(key => {
        return { key: key.name, value: d[key.name] };
      })
    )
    .join(enter =>
      enter
        .append("rect")
        .attr("x", d => x1(d.key))
        .attr("y", y(0))
        .attr("width", x1.bandwidth())
        .attr("height", 0)
        .attr("class", d => {
          return categories.find(category => category.name === d.key)
            .cssColorClass;
        })
        .call(enter =>
          enter
            .transition(t)
            .attr("height", d => y(0) - y(d.value))
            .attr("y", d => y(d.value))
        )
    );

  svg.append("g").call(yAxis);
  svg
    .append("text")
    .attr("class", widgetCardStyles.label)
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height / 2))
    .attr("dy", "3.4em")
    .style("text-anchor", "middle")
    .text("Sample Percentage [%]");

  svg.append("g").call(xAxis);
  svg
    .append("text")
    .attr("class", widgetCardStyles.label)
    .attr("x", width / 2)
    .attr("y", height - 0.3 * margin)
    .style("text-anchor", "middle")
    .text("Variant Name");

  function filterBars(category) {
    if (!category.clicked) {
      if (!category.hidden) {
        category.clicked = true;
        categories.filter(
          _category => _category.name !== category.name
        )[0].hidden = true;
        d3.selectAll("rect")
          .filter(
            d =>
              Object.keys(d).includes("graph") &&
              d.graph === "variant" &&
              d.cssColorClass !== category.cssColorClass
          )
          .transition(2 * t)
          .style("opacity", 0.1);

        d3.selectAll("rect")
          .filter(d => Object.keys(d).includes("key"))
          .filter(d => d.key !== category.name)
          .transition(10 * t)
          .style("opacity", 0.1);
      }
    } else {
      category.clicked = false;
      categories.filter(
        _category => _category.name !== category.name
      )[0].hidden = false;

      d3.selectAll("rect")
        .filter(
          d =>
            Object.keys(d).includes("graph") &&
            d.graph === "variant" &&
            d.cssColorClass !== category.cssColorClass
        )
        .transition(2 * t)
        .style("opacity", 1);

      d3.selectAll("rect")
        .filter(d => Object.keys(d).includes("key"))
        .filter(d => d.key !== category.name)
        .transition(10 * t)
        .style("opacity", 1);
    }
  }
  return svg;
}

export { drawGroupedHistogram };
