/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import {
  ContextMenu,
  Icon,
  Tooltip,
  Position,
  Colors
} from "@blueprintjs/core";
import { isEqual, sortBy } from "lodash";
import { updateEditor } from "@teselagen/ove";
import PropTypes from "prop-types";

import {
  fasColors,
  prettifyFasName
} from "../../../../../../tg-iso-design/constants/forcedAssemblyStrategies";
import DesignCardContextMenuContainer from "../../../../containers/DesignCardContextMenuContainer";
import store from "../../../../../src-shared/redux/store";
import { withEditorContext } from "../../DesignEditorContext";
import { designPartFragment } from "../../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import handleInsertPart from "../../ContextMenuHandlers/handleInsertPart";
import "../style.css";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import handleInsertCompatiblePart from "../../ContextMenuHandlers/handleInsertCompatiblePart";
import { connect } from "react-redux";
import classNames from "classnames";
import { popoverOverflowModifiers } from "../../../../../src-shared/utils/generalUtils";
import { LIST_ELEMENT_ROW_HEIGHT } from "../../../../../src-shared/components/HierarchicalDesign/constants";

const eugeneRuleFlagSize = 2 * 5;
const fasFlagSize = 2 * 5;

/**
 * Tooltips appears to treat new lines like spaces. This function
 * enables us to render new lines as actual new lines.
 * @param {string} error
 */
function processInvalidityMessage(msg) {
  return (
    <div>
      {msg.split("\n").map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </div>
  );
}

/**
 * A cell in the tree view of the hierarchical design editor. The cell can either
 * be empty or contain an element.
 *
 * When passing props, be careful as `shouldComponentUpdate` performs a deep equality check.
 *
 * @example ./ListElementCell.md
 */
class ListElementCell extends React.Component {
  static propTypes = {
    /**
     * The id of the card that this cell is being rendering on.
     */
    cardId: PropTypes.string.isRequired,

    /**
     * The id of the bin that this cell belongs to.
     */
    binId: PropTypes.string.isRequired,

    /**
     * If this cell contains an element, this is its id.
     */
    elementId: PropTypes.string,

    /**
     * The index of the cell in its bin.
     */
    index: PropTypes.number.isRequired,

    /**
     * Is the bin containing this cell a fixed bin?
     */
    isBinFixed: PropTypes.bool,

    /**
     * Is the bin containing this cell a placeholder bin?
     */
    isBinPlaceholder: PropTypes.bool,

    /**
     * Is the bin containing this cell a locked bin?
     */
    isBinLocked: PropTypes.bool,

    /**
     * A bit confusing, but is the bin containing this cell an assembly junction?
     */
    isBinCutsite: PropTypes.bool,

    /**
     * Is this cell in the final row of the card.
     */
    inLastRow: PropTypes.bool,

    /**
     * Is the design a visual report.
     *
     * @deprecated We don't have visual reports anymore.
     */
    isDesignVisualReport: PropTypes.bool,

    /**
     * Is this cell in the final row of the card.
     */
    isDesignLocked: PropTypes.bool,

    /**
     * Is the cell selected?
     */
    isSelected: PropTypes.bool,

    /**
     * Is the left border of this cell within a validation group? If so,
     * it affects rendering of the cell's border.
     */
    isLeftBorderWithinValidator: PropTypes.bool,

    /**
     * Is the left border of this cell within a validation group? If so,
     * it affects rendering of the cell's border.
     */
    isRightBorderWithinValidator: PropTypes.bool,

    /**
     * If this cell contains an element, this is it. Only the fields directly
     * on the element are needed (i.e., the `element` object should have no nested subobjects or arrays.)
     */
    element: PropTypes.object,

    /**
     * If the element of the cell has a fas, then this is the fas object. If present, only the `name` key
     * is required and should be a valid fas name.
     */
    fas: PropTypes.object,

    /**
     * If the element of the cell has any eugene rules associated with it on input operation of the card, then
     * setting this flag to `true` will render an indicator.
     */
    hasEugeneRules: PropTypes.bool,

    /**
     * If the element of the cell has any blast results associated with it on the card, then setting this flag to
     * `true` will render an indicator.
     *
     * @deprecated We don't use blast results anymore.
     */
    hasBlastResults: PropTypes.bool,

    /**
     * Does the element of the cell have non-empty `extraStartSequence` or `extraEndSequence`? This means that some extra
     * 5' and/or 3' sequence will added to the sequence of the element in the assembly reaction. Setting this flag
     * to `true` will render an indicator.
     */
    hasExtraSequence: PropTypes.bool,

    /**
     * If this cell contains an element that corresponds to a part, then this is the id of its sequence.
     */
    partSequenceId: PropTypes.string,

    /**
     * If this cell contains an element that corresponds to a part, then this is its start (0-based and inclusive).
     */
    partStart: PropTypes.number,

    /**
     * If this cell contains an element that corresponds to a part, then this is its end basepair index (0-based and inclusive).
     */
    partEnd: PropTypes.number
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  handleContextMenu = e => {
    const {
      selectCell,
      cardId,
      binId,
      elementId,
      index,
      isDesignVisualReport,
      isDesignLocked,
      isSelected,
      history,
      editorContext
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (isDesignVisualReport || isDesignLocked) return;

    if (!isSelected) {
      selectCell({
        cardId,
        binId,
        index,
        elementId,
        shift: e.shiftKey,
        ctrl: e.ctrlKey || e.metaKey
      });
    }

    const menu = (
      <DesignCardContextMenuContainer
        clickedOn="card"
        {...{ store, history, editorContext }}
      />
    );

    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };

  handleClick = e => {
    e.stopPropagation();
    const {
      selectCell,
      cardId,
      binId,
      elementId,
      element,
      index,
      setActivePanel,
      openInspector
    } = this.props;
    selectCell({
      cardId,
      binId,
      index,
      elementId,
      shift: e.shiftKey,
      ctrl: e.ctrlKey || e.metaKey
    });
    if (element) {
      setActivePanel({ panel: "element" });
      openInspector();
    }
  };

  handleDoubleClick = async e => {
    const {
      cardId,
      binId,
      elementId,
      index,
      isDesignVisualReport,
      isDesignLocked,
      isSelected,
      element,
      history,
      aminoAcidSequenceId,
      partSequenceId,
      partStart,
      partEnd,
      createElements,
      mapElementToPart,
      selectCell,
      isBinFixed,
      isBinPlaceholder,
      isBinLocked,
      isBinCutsite,
      setActivePanel,
      openInspector,
      updateBin,
      state,
      inputCard,
      changeFas,
      previousArfPartId,
      nextArfPartId,
      firstPreviousPartId,
      firstNextPartId
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (isDesignVisualReport || isDesignLocked) return;

    if (!isSelected) {
      selectCell({
        cardId,
        binId,
        index,
        elementId,
        shift: e.shiftKey,
        ctrl: e.ctrlKey || e.metaKey
      });
    }

    if (!element) {
      const selectedCellPaths = [{ index, binId }];
      if (isBinFixed || isBinPlaceholder || isBinLocked || isBinCutsite) return;
      const arfPartData = await safeQuery(
        ["part", "id name bpsUpStream bpsDownStream bps5Prime bps3Prime"],
        {
          isPlural: true,
          variables: {
            filter: {
              id: [
                previousArfPartId,
                nextArfPartId,
                firstPreviousPartId,
                firstNextPartId
              ].filter(id => !!id)
            }
          }
        }
      );

      showDialog({
        modalType: "PART_LIBRARY",
        modalProps: {
          basePairLiteralOption: true,
          firstPreviousPart: arfPartData.find(
            data => data.id === firstPreviousPartId
          ),
          firstNextPart: arfPartData.find(data => data.id === firstNextPartId),
          previousArfPart: arfPartData.find(
            data => data.id === previousArfPartId
          ),
          nextArfPart: arfPartData.find(data => data.id === nextArfPartId),
          binId,
          cardId,
          onSubmit: async (
            parts,
            { validateCompatibleParts, adjacentBinHasArf }
          ) => {
            await handleInsertPart({
              parts,
              selectedCellPaths,
              createElements,
              cardId,
              validateCompatibleParts
            });

            if (validateCompatibleParts && !adjacentBinHasArf) {
              // adjust the FRO and extraCPECBps of this bin and previous bin
              await handleInsertCompatiblePart(
                state,
                binId,
                inputCard,
                updateBin
              );

              // apply FAS of Assembly Ready Fragment to the newly-created element
              const elements = await safeQuery(["element", "id name"], {
                variables: {
                  filter: {
                    name: parts[0].name,
                    designId: Object.keys(state.design.design)[0]
                  }
                }
              });

              changeFas({
                fas: "Assembly Ready Fragment",
                cardId: inputCard.id,
                elementId: sortBy(elements, "id")[elements.length - 1].id
              });
            }
          }
        }
      });
    } else if (element.partId) {
      updateEditor(store, "SequenceEditor", {
        selectionLayer: {
          start: partStart,
          end: partEnd
        }
      });
      history.push(`/sequences/${partSequenceId}`);
    } else if (element.partsetId) {
      setActivePanel({ panel: "element" });
      openInspector();
    } else if (element.aminoAcidPartId) {
      updateEditor(store, "SequenceEditor", {
        selectionLayer: {
          start: partStart,
          end: partEnd
        }
      });
      history.push(`/amino-acid-sequences/${aminoAcidSequenceId}`);
    } else {
      if (isBinFixed || isBinPlaceholder || isBinLocked || isBinCutsite) return;
      // for mapping an unmapped part to a part
      showDialog({
        modalType: "PART_LIBRARY",
        modalProps: {
          binId,
          cardId,
          isSingleSelect: true,
          onSubmit: async parts => {
            try {
              if (parts.length !== 1)
                throw new Error("Only one part should be selected.");

              const part = await safeQuery(designPartFragment, {
                variables: { id: parts[0].id }
              });

              mapElementToPart({ binId, elementId, part });
            } catch (e) {
              console.error(e);
              window.toastr.error("Error mapping element to part.");
            }
          }
        }
      });
    }
  };

  hasEugeneRules = () => this.props.hasEugeneRules;

  hasFas = () => !!this.props.fas;

  hasBlastResults = () => this.props.hasBlastResults;

  renderFasIndicatorIfShould = () => {
    if (this.hasFas()) {
      const { fas } = this.props;
      return (
        <svg
          className="fas-flag-svg"
          style={{
            height: fasFlagSize,
            width: 2 * fasFlagSize
          }}
        >
          <Tooltip
            disabled={!!window.Cypress}
            wrapperTagName="g"
            targetTagName="g"
            content={
              <div>
                <div>Forced Assembly Strategy:</div>
                <div>{prettifyFasName(fas.name)}</div>
              </div>
            }
          >
            <rect
              height={fasFlagSize}
              className={`fas-${fas.name}`}
              width={2 * fasFlagSize}
              fill={fasColors[fas.name]}
            />
          </Tooltip>
        </svg>
      );
    }
  };

  renderEugeneRulesIndicatorIfShould = () =>
    this.hasEugeneRules() && (
      <svg
        className="eugene-rule-flag-svg"
        style={{
          height: eugeneRuleFlagSize,
          width: eugeneRuleFlagSize
        }}
      >
        <circle
          cx={eugeneRuleFlagSize / 2}
          cy={eugeneRuleFlagSize / 2}
          r={eugeneRuleFlagSize / 2}
        />
      </svg>
    );

  renderBlastResultsIndicatorIfShould = () =>
    this.hasBlastResults() && <div className="blast-results-indicator">*</div>;

  renderUnmappedIfShould() {
    const { element = {} } = this.props;
    if (
      element.id &&
      !(element.part || element.partId || element.partsetId || element.isEmpty)
    )
      return (
        <Icon
          icon="path-search"
          style={{
            color: "red",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "unset"
          }}
        />
      );
  }

  renderEmptyIconIfShould() {
    const { element = {} } = this.props;
    if (element.isEmpty)
      return (
        <Icon
          tagName="g"
          icon="cube-remove"
          style={{
            color: "black",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "unset"
          }}
        />
      );
  }
  renderPartSetIconIfShould() {
    const { element = {} } = this.props;
    if (element.partsetId) {
      return (
        <Icon
          icon="menu"
          style={{
            color: element.invalidityMessage ? "firebrick" : "#4a4b4c",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "text-top"
          }}
        />
      );
    }
  }

  renderAssemblyPieceIconIfShould() {
    const { element = {} } = this.props;
    if (element.isAssemblyPiece) {
      return (
        <Icon
          icon="build"
          style={{
            color: element.invalidityMessage ? "firebrick" : "#4a4b4c",
            fontSize: 8,
            marginRight: 5,
            verticalAlign: "text-top"
          }}
        />
      );
    }
  }

  renderPartAvailabilityIndicator = () => {
    const { elementId, partSourceIsAvailable, fas } = this.props;
    if (!elementId) return null;

    const partIsUnmapped = partSourceIsAvailable === undefined;

    let color, tooltipContent, icon;
    if (partIsUnmapped) {
      color = "#949496";
      icon = "~";
      tooltipContent =
        "This part has not been defined on a sequence, no availability info";
    } else if (partSourceIsAvailable) {
      color = "rgb(49, 171, 46)";
      icon = "✓";
      tooltipContent = "This part's source sequence is available";
    }

    if (
      elementId &&
      !partIsUnmapped &&
      fas &&
      fas.name === "Direct Synthesis"
    ) {
      color = "#949496";
      icon = "-";
      tooltipContent = `This part is sourced on an ${
        partSourceIsAvailable ? "available" : "unavailable"
      } sequence but has been marked for Direct Synthesis`;
    }

    return (
      <Tooltip
        modifiers={popoverOverflowModifiers}
        content={tooltipContent}
        position="bottom"
        disabled={!!window.Cypress}
      >
        <span
          key="partAvailability"
          style={{
            color,
            fontSize: 9,
            width: "100%",
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "bolder",
            position: "absolute",
            bottom: 0,
            left: 0
          }}
        >
          {icon}
        </span>
      </Tooltip>
    );
  };

  render() {
    const {
      element = {},
      isBinFixed,
      isBinPlaceholder,
      isSelected,
      hasExtraSequence,
      isLeftBorderWithinValidator,
      isRightBorderWithinValidator,
      isBinLocked,
      inLastRow,
      isDarkTheme
    } = this.props;

    const lockedFixedBin = isBinFixed || isBinPlaceholder || isBinLocked;

    const lockedBackgroundColor = isDarkTheme ? Colors.GRAY1 : "#e9e9e9";

    const cell = (
      <div
        className={classNames("list-elements-item", {
          "list-elements-item-selected": isSelected,
          "list-elements-item-invalid": element.invalidityMessage,
          "list-elements-item-warning": element.warningMessage,
          "list-elements-item-extra-seq-valid":
            !element.invalidityMessage && hasExtraSequence,
          "list-elements-item-placeholder": isBinPlaceholder
        })}
        style={{
          height: LIST_ELEMENT_ROW_HEIGHT,
          backgroundColor:
            lockedFixedBin && !this.props.element
              ? lockedBackgroundColor
              : null,
          borderLeft:
            isLeftBorderWithinValidator &&
            (element.invalidityMessage
              ? "1.5px dashed firebrick"
              : hasExtraSequence
                ? "1.5px dashed goldenrod"
                : "none"),
          borderRight:
            isRightBorderWithinValidator &&
            `1.5px dashed ${
              element.invalidityMessage
                ? "firebrick"
                : hasExtraSequence
                  ? "goldenrod"
                  : "#dadada"
            }`,
          borderBottom: !element.invalidityMessage && !inLastRow && "none"
        }}
        onContextMenu={this.handleContextMenu}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
      >
        <div
          className="list-elements-item-name"
          style={{
            lineHeight: LIST_ELEMENT_ROW_HEIGHT + "px",
            color: lockedFixedBin ? "#777777" : null,
            textDecoration: "underline"
          }}
        >
          {this.renderUnmappedIfShould()}
          {this.renderEmptyIconIfShould()}
          {this.renderPartSetIconIfShould()}
          {this.renderAssemblyPieceIconIfShould()}
          {element.name}
        </div>
        {this.renderEugeneRulesIndicatorIfShould()}
        {this.renderFasIndicatorIfShould()}
        {this.renderBlastResultsIndicatorIfShould()}
        {this.renderPartAvailabilityIndicator()}
      </div>
    );

    if (element.invalidityMessage) {
      return (
        <Tooltip
          disabled={!!window.Cypress}
          position={Position.BOTTOM_LEFT}
          content={processInvalidityMessage(element.invalidityMessage)}
        >
          {cell}
        </Tooltip>
      );
    } else if (element.warningMessage) {
      return (
        <Tooltip
          disabled={!!window.Cypress}
          position={Position.BOTTOM_LEFT}
          content={processInvalidityMessage(element.warningMessage)}
        >
          {cell}
        </Tooltip>
      );
    } else if (element.name) {
      return (
        <Tooltip
          disabled={!!window.Cypress}
          position={Position.TOP}
          content={element.name}
        >
          {cell}
        </Tooltip>
      );
    } else {
      return cell;
    }
  }
}

export default connect(state => ({
  isDarkTheme: state.platform.ui.theme.dark
}))(withEditorContext(ListElementCell));
