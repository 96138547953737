/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  DataTable,
  CollapsibleCard,
  withTableParams,
  showConfirmationDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { compose } from "recompose";
import { isEqual, noop, get } from "lodash";
import { MenuItem, ButtonGroup, Button, Intent } from "@blueprintjs/core";
import {
  standardizeVolume,
  standardizeConcentration,
  convertConcentration,
  convertVolume
} from "../../../../src-shared/utils/unitUtils";
import {
  materialConcentrationColumn,
  unitColumns
} from "../../../../src-shared/utils/libraryColumns";

import { showDialog } from "../../../../src-shared/GlobalDialog";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import { aliquotRecordAdditiveFragment } from "../../../../src-shared/graphql/fragments/aliquotRecordFragment";

class AdditivesTableCard extends Component {
  renderContextMenu = ({ selectedRecords, history }) => {
    const {
      refetch = noop,
      refetchAdditives,
      aliquot,
      aliquotContainer,
      selectTableRecords,
      additives
    } = this.props;

    const handleEdit = async () => {
      showDialog({
        modalType: "EDIT_ADDITIVE_DIALOG",
        modalProps: {
          initialValues: selectedRecords[0],
          history,
          aliquot,
          aliquotContainer,
          additives,
          refetch: () => {
            selectTableRecords([selectedRecords[0].id]);
          }
        }
      });
    };

    const deleteRecords = async () => {
      const selectedAdditiveVolume = standardizeVolume(
        selectedRecords[0].volume,
        selectedRecords[0].volumetricUnitCode,
        true
      );
      if (aliquot) {
        const initialAliquotVolume = standardizeVolume(
          aliquot.volume,
          aliquot.volumetricUnitCode,
          true
        );
        if (isEqual(initialAliquotVolume, selectedAdditiveVolume)) {
          window.toastr.error(
            `Cannot delete ${get(
              selectedRecords[0],
              "additiveMaterial.name"
            )} as it is the only additive hydrating the plate.`
          );
        } else {
          let initialAliquotConcentration;
          let updatedConcentration;
          if (aliquot.concentration) {
            initialAliquotConcentration = standardizeConcentration(
              aliquot.concentration,
              aliquot.concentrationUnitCode,
              true
            );
            updatedConcentration = convertConcentration(
              initialAliquotConcentration
                .times(initialAliquotVolume)
                .div(initialAliquotVolume.minus(selectedAdditiveVolume)),
              "g/L",
              aliquot.concentrationUnitCode,
              true
            ).toString();
          }
          const updatedVolume = convertVolume(
            initialAliquotVolume.minus(selectedAdditiveVolume),
            "L",
            aliquot.volumetricUnitCode,
            true
          );
          const removeVolume = await showConfirmationDialog({
            text:
              "Would you like to subtract this additive's volume from the aliquot?",
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          });
          if (removeVolume) {
            if (updatedVolume.lt(0)) {
              return window.toastr.error(
                "Aliquot does not have enough volume."
              );
            }
            const aliquotUpdates = [
              {
                id: aliquot.id,
                concentration: updatedConcentration,
                volume: updatedVolume.toString()
              }
            ];
            await safeUpsert("aliquot", aliquotUpdates);
          }
        }
      }
      await safeDelete("additive", selectedRecords[0].id);
      await refetch();
      await refetchAdditives();
    };

    const menuItems = [];
    if (selectedRecords.length === 1) {
      menuItems.push(
        <MenuItem key="edit" icon="edit" onClick={handleEdit} text="Edit" />,
        <MenuItem
          key="delete"
          icon="trash"
          onClick={deleteRecords}
          text="Delete"
        />
      );
    }
    return menuItems;
  };

  renderAddAdditivesDialog = () => {
    const {
      refetchAdditives,
      isAliquot,
      refetch: _refetch,
      aliquot,
      aliquotContainer
    } = this.props;

    const refetch = async () => {
      await refetchAdditives();
      await _refetch();
    };
    showDialog({
      modalType: "ADD_ADDITIVES",
      modalProps: {
        refetch,
        isAliquot,
        aliquot,
        aliquotContainer
      }
    });
  };

  render() {
    const { tableParams, readOnly } = this.props;

    return (
      <CollapsibleCard
        title="Additives"
        openTitleElements={
          !readOnly && (
            <ButtonGroup minimal>
              <Button
                text="Add Additives"
                icon="add"
                intent={Intent.SUCCESS}
                onClick={this.renderAddAdditivesDialog}
              />
            </ButtonGroup>
          )
        }
      >
        <DataTable
          {...tableParams}
          noPadding
          className="additives-card"
          contextMenu={this.renderContextMenu}
        />
      </CollapsibleCard>
    );
  }
}

export const additivesTableSchema = {
  model: "additive",
  fields: [
    {
      displayName: "Reagent",
      path: "lot.additiveMaterial.name",
      render: (v, r) => {
        if (r.lot) {
          return get(r, "lot.additiveMaterial.name");
        } else {
          return get(r, "additiveMaterial.name");
        }
      }
    },
    {
      path: "additiveMaterial.name",
      isHidden: true,
      isForcedHidden: true
    },
    {
      displayName: "Lot",
      path: "lot.name"
    },
    {
      displayName: "Reagent Type",
      path: "lot.additiveMaterial.additiveType.name",
      render: (v, r) => {
        if (r.lot) {
          return get(r, "lot.additiveMaterial.additiveType.name");
        } else {
          return get(r, "additiveMaterial.additiveType.name");
        }
      }
    },
    {
      path: "additiveMaterial.additiveType.name",
      isHidden: true,
      isForcedHidden: true
    },
    ...unitColumns,
    materialConcentrationColumn
  ]
};

export default compose(
  withTableParams({
    formName: "additivesForm",
    urlConnected: false,
    additionalFilter: (props, qb) => {
      if (props.aliquot) {
        qb.whereAll({
          aliquotId: props.aliquot.id
        });
      } else if (props.aliquotContainer) {
        qb.whereAll({
          aliquotContainerId: props.aliquotContainer.id
        });
      }
    },
    schema: additivesTableSchema
  }),
  withQuery(aliquotRecordAdditiveFragment, {
    isPlural: true
  })
)(AdditivesTableCard);
