/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { aliquotContainerReactionMapFragmentBasic } from "../../../../../tg-iso-lims/src/ReactionMap/fragments";

export const executeReactionMapToolReactionMapFragment = gql`
  fragment executeReactionMapToolReactionMapFragment on reactionMap {
    id
    name
    reactionTypeCode
    reactionType {
      code
      name
    }
    updatedAt
    reactions {
      id
      reactionOutputs {
        id
        outputMaterialId
        outputAdditiveMaterialId
      }
      reactionInputs {
        id
        inputMaterialId
        inputAdditiveMaterialId
        conserved
      }
    }
  }
`;

export const executeReactionMapToolContainerArrayFragment = gql`
  fragment executeReactionMapToolContainerArrayFragment on containerArray {
    id
    name
    updatedAt
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      isPlate
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      rowPosition
      columnPosition
      ...aliquotContainerReactionMapFragmentBasic
    }
  }
  ${aliquotContainerReactionMapFragmentBasic}
`;
