/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../src-shared/GlobalDialog";
import CreateAliquotFromMaterialDialog from "../components/Dialogs/CreateAliquotFromMaterialDialog";
import ExternalAvailabilityMenuItem from "../components/ExternalAvailabilityMenuItem";

export default (selectedRecords, props, { history, refetchMaterials }) => {
  const toReturn = [];
  const typeCode = selectedRecords[0].materialTypeCode;
  if (selectedRecords.length === 1) {
    const material = selectedRecords[0];
    toReturn.push(
      <MenuItem
        key="createAliquot"
        icon="add"
        text="Create Aliquot"
        onClick={() => {
          showDialog({
            ModalComponent: CreateAliquotFromMaterialDialog,
            modalProps: {
              material,
              dialogProps: {
                title: "Create Aliquot from " + material.name
              }
            }
          });
        }}
      />
    );
  }
  if (
    ["DNA", "MICROBIAL"].includes(typeCode) &&
    window.frontEndConfig.reactions
  ) {
    toReturn.push(
      <MenuItem
        key="transformation-reaction"
        text="Transform"
        icon="resolve"
        onClick={() => {
          showDialog({
            modalType: "MATERIAL_MICROBIAL_TRANSFORMATION",
            modalProps: {
              sourceMaterials: selectedRecords,
              typeCode,
              history,
              refetchMaterials,
              multi: selectedRecords.length > 1,
              dialogProps: {
                title: `Transform ${selectedRecords.length} ${
                  typeCode === "DNA" ? "DNA" : "Microbial"
                } Material${selectedRecords.length > 1 ? "s" : ""}`
              }
            }
          });
        }}
      />
    );
  }
  toReturn.push(
    <ExternalAvailabilityMenuItem
      key="externalAvailability"
      records={selectedRecords}
    />
  );
  return toReturn;
};
