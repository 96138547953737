/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { flatMapDeep, uniqBy, flatMap, max } from "lodash";
import sha1 from "crypto-js/sha1";
import { queryForDuplicateTags } from "./importUtils";
import defaultJ5OutputNamingTemplateMap from "../constants/defaultJ5OutputNamingTemplateMap";
import {
  j5ParameterNameToCustomJ5ParameterName,
  getDefaultParameters
} from "../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";
import { SUPPORTED_BIN_ICONS } from "../constants/designStateConstants";
import { chunkSequenceToFragments } from "../../tg-iso-shared/src/sequence-import-utils/utils";
import shortid from "shortid";

export default async (json, pDesignCid) => {
  const designCid = pDesignCid || shortid();

  if (json.eugene_rules.length)
    window.toastr.warning("Eugene rules cannot currently be imported.");

  console.warn(
    "Due to deficiencies in our current data model, eugene rules cannot be imported."
  );
  console.warn(
    'There might be differences in the meaning of "type" between hde and peony. Also we do not have an analog of "columnName".'
  );

  const duplicatedTagMap = await queryForDuplicateTags(
    getAllTags(json),
    "name"
  );

  // NOTE: The order of these is very important.
  return [
    { entity: "design", inputs: createDesignInput(json, designCid) },
    {
      entity: "tag",
      inputs: createTagInputs(json, designCid, duplicatedTagMap)
    },
    {
      entity: "sequence",
      inputs: createSequenceInputs(json, designCid, duplicatedTagMap)
    },
    { entity: "bin", inputs: createBinInputs(json, designCid) },
    { entity: "card", inputs: createCardInput(json, designCid) },
    { entity: "junction", inputs: createJunctionInput(json, designCid) }
  ];
};

const createTagInputs = (json, designCid, duplicatedTagMap) =>
  getAllTags(json)
    .filter(t => !duplicatedTagMap[t.name])
    .map(t => ({
      cid: sha1(designCid + "-" + t.name),
      name: t.name,
      color: t.color
    }));

const createBinInputs = (json, designCid) =>
  json.bins.map((bin, binIndex) => ({
    cid: designCid + "-" + bin.id,
    designId: "&" + designCid,
    name: bin.bin_name,
    direction: bin.direction === "forward",
    iconId: SUPPORTED_BIN_ICONS[processIconId(bin.iconID)]
      ? "&" + processIconId(bin.iconID)
      : "&USER-DEFINED",
    elements: flatMap(bin.cells, ({ part_id }, index) => {
      if (part_id && part_id !== "null") {
        let part = null;
        for (const sequence of Object.values(json.sequences)) {
          const p = sequence.parts.find(p => p.id === part_id);
          if (p) {
            part = p;
            break;
          }
        }
        return [
          {
            designId: "&" + designCid,
            name: part.name,
            index,
            partId: "&" + designCid + "-" + part_id,
            cid: designCid + "-" + part_id + "-row" + index + "-bin" + binIndex,
            preferred3PrimeOverhangs: part.three_prime_preferred_overhangs,
            preferred5PrimeOverhangs: part.five_prime_preferred_overhangs
          }
        ];
      } else {
        return [];
      }
    })
  }));

const createJunctionInput = (json, designCid) => {
  return json.bins.map((bin, i) => {
    const nextInd = (i + 1) % json.bins.length;
    const nextBin = json.bins[nextInd];
    return {
      designId: "&" + designCid,
      junctionTypeCode: "SCARLESS",
      isPhantom: !json.circular && nextInd === 0,
      reactionId: "&" + designCid,
      fivePrimeCardId: "&" + designCid + "-" + i,
      threePrimeCardId: "&" + designCid + "-" + nextInd,
      fivePrimeCardEndBinId: "&" + designCid + "-" + bin.id,
      fivePrimeCardInteriorBinId: "&" + designCid + "-" + bin.id,
      threePrimeCardStartBinId: "&" + designCid + "-" + nextBin.id,
      threePrimeCardInteriorBinId: "&" + designCid + "-" + nextBin.id
    };
  });
};

const createDesignInput = (json, designCid) => ({
  cid: designCid,
  name: json.design_name,
  description: "",
  type: "grand-design",
  boundaryAnalysisType: "j5",
  numRows: max(json.bins.map(b => b.cells.length)) || 1,
  layoutType: "combinatorial"
});

const createCardInput = (json, designCid) => {
  return {
    designId: "&" + designCid,
    name: json.design_name,
    isRoot: true,
    circular: json.circular,
    binCards: json.bins.map((bin, index) => ({
      binId: "&" + designCid + "-" + bin.id,
      index,
      designId: "&" + designCid
    })),
    outputReaction: {
      designId: "&" + designCid,
      name: json.assembly_method || "Mock Assembly",
      assemblyMethodId: "&" + processAssemblyMethod(json.assembly_method),
      customJ5Parameter: processCustomJ5Parameter(json, designCid),
      reactionJ5OutputNamingTemplates: Object.keys(
        defaultJ5OutputNamingTemplateMap
      ).map(outputTarget => ({
        designId: "&" + designCid,
        j5OutputNamingTemplate: {
          designId: "&" + designCid,
          ...defaultJ5OutputNamingTemplateMap[outputTarget]
        }
      })),
      fas: flatMap(json.bins, (bin, binIndex) =>
        bin.cells
          .filter((c, idx) => {
            if (
              c.part_id &&
              c.part_id !== "null" &&
              c.forced_assembly_strategy &&
              c.forced_assembly_strategy !== "None"
            ) {
              c.temp_index = idx;
              return true;
            } else {
              return false;
            }
          })
          .map(c => {
            const result = {
              designId: "&" + designCid,
              name: c.forced_assembly_strategy || "None",
              elementId:
                "&" +
                designCid +
                "-" +
                c.part_id +
                "-row" +
                c.temp_index +
                "-bin" +
                binIndex
            };
            delete c.temp_index;
            return result;
          })
      ),
      cards: json.bins.map((bin, inputIndex) => ({
        cid: designCid + "-" + inputIndex,
        designId: "&" + designCid,
        name: `Bin ${inputIndex + 1}`,
        binCards: [
          {
            designId: "&" + designCid,
            index: 0,
            binId: "&" + designCid + "-" + bin.id
          }
        ],
        dsf: bin.direct_synthesis_firewall || false,
        inputIndex
      }))
    }
  };
};

const processCustomJ5Parameter = json => ({
  name: json.parameter_preset || "Custom Parameters",
  ...processParameters(json.parameters)
});

const processParameters = parameters => {
  parameters = {
    ...getDefaultParameters(),
    ...parameters
  };
  const newParams = {};
  Object.keys(j5ParameterNameToCustomJ5ParameterName).forEach(j5Name => {
    const customName = j5ParameterNameToCustomJ5ParameterName[j5Name];
    if (!customName) return;
    if (parameters[j5Name] !== undefined)
      newParams[customName] = parameters[j5Name];
  });

  return newParams;
};

const getAllTags = json =>
  uniqBy(
    flatMapDeep(Object.values(json.sequences), s => s.parts.map(p => p.tags)),
    "name"
  );

const createSequenceInputs = (json, designCid, duplicatedTagMap) =>
  Object.values(json.sequences).map(sequence => ({
    cid: designCid + "-" + sequence.id,
    name: sequence.name,
    description: sequence.description,
    circular: sequence.circular,
    sequenceTypeCode: sequence.circular ? "CIRCULAR_DNA" : "LINEAR_DNA",
    size: sequence.sequence.length,
    sequenceFragments: chunkSequenceToFragments(sequence.sequence),
    sequenceFeatures: !sequence.features
      ? []
      : sequence.features.map(feature => ({
          start: feature.start,
          end: feature.end - 1,
          name: feature.name,
          type: feature.type,
          strand: feature.forward ? 1 : -1
        })),
    parts: sequence.parts.map(part => ({
      cid: designCid + "-" + part.id,
      name: part.name,
      start: part.start - 1,
      end: part.end - 1,
      strand: part.reverse_complement ? -1 : 1,
      preferred3PrimeOverhangs: part.three_prime_preferred_overhangs,
      preferred5PrimeOverhangs: part.five_prime_preferred_overhangs,
      taggedItems: part.tags.map(t => ({
        tagId: duplicatedTagMap[t.name] || "&" + sha1(designCid + "-" + t.name)
      }))
    }))
  }));

const processIconId = iconId => {
  if (iconId === "USER-DEFINED") return iconId;
  return iconId.replace(/-/g, "_");
};

// This function might need more work in the future to accomodate
// variations in the assembly method names.
const processAssemblyMethod = method => {
  const assemblyMethodNamingMap = {
    Mock: "mock",
    "Mock Assembly": "mock",
    "SLIC/Gibson/CPEC": "gibson-slic-cpec",
    CPEC: "gibson-slic-cpec",
    SLIC: "gibson-slic-cpec",
    Gibson: "gibson-slic-cpec",
    GoldenGate: "golden-gate",
    "Golden Gate": "golden-gate",
    CombinatorialMock: "mock",
    CombinatorialGoldenGate: "golden-gate",
    CombinatorialSLICGibsonCPEC: "gibson-slic-cpec",
    ERDAM: "digest",
    "Contiguous Express Digest": "digest",
    "Synthesis and Custom Cloning": "synthesis-and-custom-cloning"
  };
  return assemblyMethodNamingMap[method] || "mock";
};
