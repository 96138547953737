/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

export const itemTypes = [
  "material",
  "additiveMaterial",
  "lot",
  "sample",
  "j5Report",
  "containerArray",
  "plateMap",
  "Inventory List",
  "reactionMap"
];

export const itemTypeOptions = itemTypes.map(model => ({
  label: modelNameToReadableName(model, { upperCase: true }),
  value: model
}));

export const plateWellContentTypes = ["material", "sample"];
export const plateMapItemTypes = [
  "material",
  "additiveMaterial",
  "lot",
  "sample"
];

export const j5EntityToFragment = {
  j5PcrReaction: gql`
    fragment createOrEditPlateMapJ5ReportJ5PcrReactionsFragment on j5Report {
      id
      name
      j5PcrReactions {
        id
        name
      }
    }
  `,
  j5AssemblyPiece: gql`
    fragment createOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment on j5Report {
      id
      name
      j5AssemblyPieces {
        id
        name
        sequence {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
    }
  `,
  j5RunConstruct: gql`
    fragment createOrEditPlateMapJ5Reportj5RunConstructFragment on j5Report {
      id
      name
      j5RunConstructs {
        id
        name
        sequence {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
    }
  `,
  primaryTemplate: gql`
    fragment createOrEditPlateMapJ5ReportprimaryTemplateFragment on j5Report {
      id
      name
      j5PcrReactions {
        id
        primaryTemplate {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
    }
  `,
  primer: gql`
    fragment createOrEditPlateMapJ5ReportprimerFragment on j5Report {
      id
      name
      j5PcrReactions {
        id
        forwardPrimer {
          id
          sequence {
            id
            polynucleotideMaterial {
              id
              name
            }
          }
        }
        reversePrimer {
          id
          sequence {
            id
            polynucleotideMaterial {
              id
              name
            }
          }
        }
      }
    }
  `,
  pcrProductSequence: gql`
    fragment createOrEditPlateMapJ5ReportpcrProductSequenceFragment on j5Report {
      id
      name
      j5PcrReactions {
        id
        pcrProductSequence {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
    }
  `,
  j5InputSequence: gql`
    fragment createOrEditPlateMapJ5Reportj5InputSequenceFragment on j5Report {
      id
      name
      j5InputSequences {
        id
        sequence {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
    }
  `
};
