/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isFunction } from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

export default function stepFormValues(..._fieldNames) {
  return connect((state, props) => {
    const { toolSchema } = props;
    if (!toolSchema)
      return console.error(
        "No toolSchema found in props. stepFormValues will not work"
      );

    const selector = formValueSelector(toolSchema.code);
    let fieldNames = _fieldNames;
    if (isFunction(fieldNames[0])) {
      fieldNames = fieldNames[0](props);
    }
    // if only selecting a single field you have to wrap in object
    if (fieldNames.length === 1) {
      return {
        [fieldNames[0]]: selector(state, fieldNames[0])
      };
    }
    return selector(state, ...fieldNames);
  });
}
