// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.TargetAndFeaturesWidgetStyles-modules__container-1oeLJ {\n  display: flex;\n  flex-flow: column;\n  height: 100%;\n  max-height: 200;\n  overflow-y: auto;\n}\n\n.TargetAndFeaturesWidgetStyles-modules__container-1oeLJ ul:first-of-type {\n  overflow: visible;\n}\n\n.TargetAndFeaturesWidgetStyles-modules__container-1oeLJ ul {\n  list-style-type: none;\n  margin-left: 20px;\n  margin-bottom: 10px;\n  /* overflow-y: scroll; */\n}\n\n.TargetAndFeaturesWidgetStyles-modules__container-1oeLJ ul li {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px;\n  margin-right: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "TargetAndFeaturesWidgetStyles-modules__container-1oeLJ"
};
module.exports = exports;
