/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export default (
  { selectedCardId, selectedBinIds, insertBin },
  onLeft,
  noPropagation
) => {
  if (!selectedCardId || !selectedBinIds.length) return;

  const neighborBinId = onLeft
    ? selectedBinIds[0]
    : selectedBinIds[selectedBinIds.length - 1];

  insertBin({
    cardId: selectedCardId,
    neighborBinId,
    onLeft,
    noPropagation
  });
};
