/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import {
  materialConcentrationColumn,
  unitColumns
} from "../../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import LotRecordView from "../../Record/LotRecordView";
import UploadLotDialog from "../../Dialogs/UploadLotDialog";
import CreateNewLotDialog from "../../Dialogs/CreateNewLotDialog";

const schema = {
  model: "lot",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "additiveMaterial.name",
      type: "string",
      displayName: "Reagent"
    },
    {
      path: "additiveMaterial.additiveType.name",
      type: "string",
      displayName: "Reagent Type"
    },
    ...unitColumns,
    materialConcentrationColumn
  ]
};

class LotInventory extends Component {
  onNewItemClick = ({ history }) => {
    showDialog({
      ModalComponent: CreateNewLotDialog,
      modalProps: {
        history
      }
    });
  };

  showUploadFunction = () => {
    const { refetchLots } = this.props;
    showDialog({
      ModalComponent: UploadLotDialog,
      modalProps: {
        refetch: refetchLots
      }
    });
  };

  onDoubleClick = record => {
    this.props.history.push(`/reagent-lots/${record.id}`);
  };

  contextMenuItems = selectedRecords => {
    if (selectedRecords.length === 1) {
      const lot = selectedRecords[0];
      return [
        <MenuItem
          key="createTube"
          icon="add"
          text="Create Tube"
          onClick={() => {
            showDialog({
              modalType: "CREATE_TUBE_FROM_REAGENT",
              modalProps: {
                lotId: lot.id,
                dialogProps: {
                  title: "Create Tube from " + lot.name
                }
              }
            });
          }}
        />
      ];
    }
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        libraryName="reagentLot"
        libraryTitle="Reagent Lots"
        model="lot"
        RecordViewInspector={LotRecordView}
        showUploadFunction={this.showUploadFunction}
        generateExtraContextMenuItems={this.contextMenuItems}
        isLibraryTable
        withExport
        onDoubleClick={this.onDoubleClick}
        onNewItemClick={this.onNewItemClick}
      />
    );
  }
}

const fragment = gql`
  fragment lotLibraryFragment on lot {
    id
    name
    additiveMaterialId
    additiveMaterial {
      id
      name
      additiveType {
        code
        name
      }
    }
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(LotInventory);
