/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import {
  standardizeVolume,
  standardizeConcentration,
  standardizeMass,
  standardizeMolarity
} from "./standardizeUnits";
import getUnit from "./getUnit";
import unitGlobals from "../../unitGlobals";

const convertUnitTypeGeneric = (standardizer, baseKey) => (
  value,
  originalUnitOrUnitCode,
  newUnitOrUnitCode,
  big
) => {
  const units = unitGlobals.getUnitsForBaseKey(baseKey);
  let newUnit = newUnitOrUnitCode;
  if (typeof newUnitOrUnitCode === "string") {
    newUnit = getUnit(units, newUnit);
  }
  const standardized = standardizer(value, originalUnitOrUnitCode, units, big);
  if (big) {
    return standardized.div(new Big(newUnit[baseKey]));
  }
  return standardized / newUnit[baseKey];
};

export const convertVolume = convertUnitTypeGeneric(
  standardizeVolume,
  "liters"
);

export const convertConcentration = convertUnitTypeGeneric(
  standardizeConcentration,
  "gramsPerLiter"
);
export const convertMass = convertUnitTypeGeneric(standardizeMass, "grams");

export const convertMolarity = convertUnitTypeGeneric(
  standardizeMolarity,
  "molesPerLiter"
);
