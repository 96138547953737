/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import unitGlobals from "../../unitGlobals";
import getUnit from "./getUnit";

export const defaultMassUnitCode = "ng";

/**
 * Given a mass unit code, return what fraction of a gram a single unit represents.
 * @param {string} massUnitCode
 * @returns {number}
 */
export function massUnitCodeToGrams(massUnitCode) {
  const unit =
    unitGlobals.massUnits && getUnit(unitGlobals.massUnits, massUnitCode);
  const grams = unit && unit.grams;
  if (!grams) throw new Error(`Invalid mass unit code: ${massUnitCode}.`);
  return new Big(grams);
}

/**
 * Convert a `mass` given in `oldUnits` to `newUnits`.
 * @param {number} mass The mass we wish to convert
 * @param {string} oldUnits The mass unit code that `mass` is provided as.
 * @param {string} newUnits The mass unit code that we wish to see `mass` as.
 * @returns {number}
 */
export function convertMassBig(mass, oldUnits, newUnits) {
  return new Big(mass)
    .times(massUnitCodeToGrams(oldUnits))
    .div(massUnitCodeToGrams(newUnits));
}
