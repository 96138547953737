/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import AsyncValidateFieldSpinner from "../../../src-shared/AsyncValidateFieldSpinner";

import "./style.css";
import { showDialog } from "../../../src-shared/GlobalDialog";
import PlateUploadFields from "../PlateUploadFields";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";

import CreateOrGenerateBarcodeField from "../CreateOrGenerateBarcodeField";
import { addBarcodesToRecords } from "../../../../tg-iso-lims/src/utils/barcodeUtils";
import { isEmpty } from "lodash";
import { asyncValidateNameHandler } from "../../../src-shared/utils/formUtils";
import { asyncValidateBarcodeHandler } from "../../../src-shared/utils/formUtils";
import { getUploadAliquotContainers } from "../../../../tg-iso-lims/src/utils/plateUtils";

class PlateRecordCreate extends Component {
  onSubmit = async data => {
    const { refetch, hideModal } = this.props;
    const {
      containerArrayType,
      generateBarcode,
      generateTubes,
      generateTubeBarcodes,
      shouldFillRack,
      numTubesToFillRack,
      aliquotContainerType,
      userAddedBarcode,
      shouldAssignToLocation,
      labId,
      ...containerArrayData
    } = data;

    // make sure to add validation for available tube type select field
    try {
      const aliquotContainers = getUploadAliquotContainers({
        newAliquotContainers: [],
        containerArrayType,
        shouldFillRack,
        aliquotContainerType,
        numTubesToFillRack
      });
      const shouldAddTubeBarcodes =
        containerArrayType.isPlate === false &&
        shouldFillRack &&
        generateTubeBarcodes;
      const containerArray = {
        ...containerArrayData,
        containerArrayTypeId: containerArrayType.id,
        aliquotContainers
      };
      if (!generateBarcode && userAddedBarcode) {
        containerArray.barcode = {
          barcodeString: userAddedBarcode
        };
      }
      const [createdContainerArray] = await safeUpsert(
        "containerArray",
        containerArray
      );
      if (generateBarcode) {
        await addBarcodesToRecords(createdContainerArray);
      }
      if (shouldAddTubeBarcodes) {
        const newTubes = await safeQuery(["aliquotContainer", "id"], {
          variables: {
            filter: {
              containerArrayId: createdContainerArray.id
            }
          }
        });
        await addBarcodesToRecords(newTubes);
      }
      await refetch();
      hideModal();
      if (shouldAssignToLocation) {
        showDialog({
          modalType: "ASSIGN_PLATE_PLACEMENT_STRATEGY",
          modalProps: {
            plateIds: [createdContainerArray.id],
            containerArrayType: containerArrayType,
            refetch
          }
        });
      }
    } catch (error) {
      console.error("err:", error);
      window.toastr.error("Error creating plate.");
    }
  };

  render() {
    const { handleSubmit, hideModal, submitting, asyncValidating } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            label="Name"
            name="name"
            isRequired
            placeholder="Enter Plate Name..."
            rightElement={
              <AsyncValidateFieldSpinner validating={asyncValidating} />
            }
          />
          <CreateOrGenerateBarcodeField asyncValidating={asyncValidating} />
          <PlateUploadFields noFileUpload />
        </div>
        <DialogFooter
          loading={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

async function asyncValidate(values, ownProps) {
  const errors = {};
  const options = {
    values,
    ownProps,
    model: "containerArray",
    errors
  };
  await asyncValidateNameHandler(options);
  await asyncValidateBarcodeHandler(options);
  if (!isEmpty(errors)) {
    throw errors;
  }
}

export default compose(
  wrapDialog({ title: "Create New Plate" }),
  reduxForm({
    form: "createNewContainerArray",
    asyncBlurFields: ["name", "userAddedBarcode"],
    asyncValidate
  })
)(PlateRecordCreate);
