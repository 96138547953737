/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { get, set } from "lodash";
import { compose, withProps } from "recompose";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, CheckboxField, wrapDialog } from "@teselagen/ui";
import MaterialUpdateSubform from "../../MaterialUpdateSubform";

import { withStrainUnits } from "../CreateNewStrainDialog";
import StrainOverwrites from "../CreateNewMaterialDialog/StrainOverwrites";
import GenericSelect from "../../../../src-shared/GenericSelect";
import {
  safeUpsert,
  safeDelete,
  deleteWithQuery
} from "../../../../src-shared/apolloMethods";

import "./style.css";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

class UpdateMaterialDialog extends Component {
  onSubmit = async _values => {
    const { hideModal, refetch, material: originalMaterial } = this.props;
    try {
      const values = {
        ..._values
      };
      if (!values.strain) {
        values.strainId = null;
      } else {
        values.strainId = values.strain.id;
      }
      if (!values.genome) {
        values.genomeId = null;
      } else {
        values.genomeId = values.genome.id;
      }
      const {
        overwriteStrainFields,
        selectionMethodIds = [],
        inductionMethodIds = [],
        targetOrganismClassId,
        biosafetyLevelCode,
        growthCondition = {}
      } = values;
      if (overwriteStrainFields) {
        const strainSelectionMethodIds = get(
          originalMaterial,
          "strain.strainSelectionMethods",
          []
        ).map(ssm => ssm.selectionMethod.id);
        const strainInductionMethodIds = get(
          originalMaterial,
          "strain.inductionMethodStrains",
          []
        ).map(ssm => ssm.inductionMethod.id);

        // if the selection methods are the same as the strains we don't need to overwrite
        const selectionMethodsSameAsStrains =
          selectionMethodIds.every(sId =>
            strainSelectionMethodIds.includes(sId)
          ) && selectionMethodIds.length === strainSelectionMethodIds.length;
        const inductionMethodsSameAsStrains =
          inductionMethodIds.every(sId =>
            strainInductionMethodIds.includes(sId)
          ) && inductionMethodIds.length === strainInductionMethodIds.length;
        await deleteWithQuery("microbialMaterialSelectionMethod", {
          microbialMaterialId: values.id
        });
        await deleteWithQuery("microbialMaterialInductionMethod", {
          microbialMaterialId: values.id
        });
        if (!selectionMethodsSameAsStrains) {
          if (originalMaterial.materialTypeCode === "MICROBIAL") {
            await safeUpsert(
              "microbialMaterialSelectionMethod",
              selectionMethodIds.map(smId => {
                return {
                  microbialMaterialId: originalMaterial.id,
                  selectionMethodId: smId
                };
              })
            );
          } else {
            await safeUpsert(
              "cellCultureSelectionMethod",
              selectionMethodIds.map(smId => {
                return {
                  cellCultureId: originalMaterial.id,
                  selectionMethodId: smId
                };
              })
            );
          }
        }
        if (!inductionMethodsSameAsStrains) {
          if (originalMaterial.materialTypeCode === "MICROBIAL") {
            await safeUpsert(
              "microbialMaterialInductionMethod",
              inductionMethodIds.map(smId => {
                return {
                  microbialMaterialId: originalMaterial.id,
                  inductionMethodId: smId
                };
              })
            );
          } else {
            await safeUpsert(
              "cellCultureInductionMethod",
              inductionMethodIds.map(smId => {
                return {
                  cellCultureId: originalMaterial.id,
                  inductionMethodId: smId
                };
              })
            );
          }
        }

        values.targetOrganismClassOverwriteId =
          targetOrganismClassId ===
            get(originalMaterial.strain, "targetOrganismClass.id") ||
          !targetOrganismClassId
            ? null
            : targetOrganismClassId;

        values.biosafetyLevelOverwriteCode =
          biosafetyLevelCode ===
            get(originalMaterial.strain, "biosafetyLevel.code") ||
          !biosafetyLevelCode
            ? null
            : biosafetyLevelCode;

        // handle growth condition
        const growthConditionToUpsert = {
          id: get(originalMaterial, "growthConditionOverwrite.id")
        };

        const addFieldToGrowthCondition = field => {
          const strainValueForField = get(
            originalMaterial.strain,
            `growthCondition.${field}`
          );
          let valForField = get(growthCondition, field);
          if (!valForField || valForField === strainValueForField) {
            // dont overwrite
            valForField = null;
          }
          set(growthConditionToUpsert, field, valForField);
        };
        const growthConditionFields = [
          "name",
          "description",
          "shakerSpeed",
          "shakerThrow",
          "lengthUnitCode",
          "temperature",
          "humidity"
        ];
        growthConditionFields.forEach(addFieldToGrowthCondition);
        if (
          growthCondition.growthMedia &&
          growthCondition.growthMedia.id !==
            get(originalMaterial.strain, "growthCondition.growthMedia.id")
        ) {
          growthConditionToUpsert.growthMediaId =
            growthCondition.growthMedia.id;
        } else {
          growthConditionToUpsert.growthMediaId = null;
        }
        if (
          growthCondition.gasComposition &&
          growthCondition.gasComposition.id !==
            get(originalMaterial.strain, "growthCondition.gasComposition.id")
        ) {
          growthConditionToUpsert.gasCompositionId =
            growthCondition.gasComposition.id;
        } else {
          growthConditionToUpsert.gasCompositionId = null;
        }
        const [{ id }] = await safeUpsert(
          "growthCondition",
          growthConditionToUpsert
        );
        values.growthConditionOverwriteId = id;
      } else {
        // do cleanup
        if (originalMaterial.growthCondition) {
          await safeDelete(
            "growthCondition",
            originalMaterial.growthCondition.id
          );
        }

        if (
          originalMaterial.microbialMaterialMicrobialMaterialSelectionMethods
            .length
        ) {
          await safeDelete(
            "microbialMaterialSelectionMethod",
            originalMaterial.microbialMaterialMicrobialMaterialSelectionMethods.map(
              r => r.id
            )
          );
        }

        if (originalMaterial.cellCultureCellCultureSelectionMethods.length) {
          await safeDelete(
            "cellCultureSelectionMethod",
            originalMaterial.cellCultureCellCultureSelectionMethods.map(
              r => r.id
            )
          );
        }

        values.targetOrganismClassOverwriteId = null;
        values.biosafetyLevelOverwriteCode = null;
      }
      delete values.strain;
      delete values.genome;
      delete values.growthCondition;
      delete values.overwriteStrainFields;
      delete values.biosafetyLevelCode;
      delete values.targetOrganismClassId;
      delete values.selectionMethodIds;
      delete values.inductionMethodIds;
      await safeUpsert("material", values);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating material.");
    }
  };
  render() {
    const {
      submitting,
      hideModal,
      handleSubmit,
      initialValues = {},
      overwriteStrainFields
    } = this.props;
    const { materialTypeCode } = initialValues;

    return (
      <form>
        <div className={Classes.DIALOG_BODY}>
          <MaterialUpdateSubform materialTypeCode={materialTypeCode} />
          {materialTypeCode === "GENOMIC" && (
            <GenericSelect
              {...{
                name: "genome",
                label: "Genome",
                asReactSelect: true,
                fragment: ["genome", "id name"]
              }}
            />
          )}
          {(materialTypeCode === "MICROBIAL" ||
            materialTypeCode === "CELL_CULTURE") && (
            <React.Fragment>
              <hr className="tg-section-break" />
              <GenericSelect
                {...{
                  key: "strain",
                  name: "strain", //the field name within the redux form Field
                  label:
                    materialTypeCode === "MICROBIAL"
                      ? "Choose Strain"
                      : "Choose Cell Line",
                  schema: [
                    {
                      path: "name"
                    }
                  ],
                  tableParamOptions: {
                    additionalFilter: {
                      strainTypeCode:
                        materialTypeCode === "MICROBIAL"
                          ? "MICROBIAL_STRAIN"
                          : "CELL_LINE"
                    }
                  },
                  asReactSelect: true,
                  fragment: ["strain", "id name"]
                }}
              />
              <CheckboxField
                name="overwriteStrainFields"
                label={`Overwrite ${
                  materialTypeCode === "MICROBIAL" ? "Strain" : "Cell Line"
                } Fields`}
              />
              {overwriteStrainFields && <StrainOverwrites {...this.props} />}
            </React.Fragment>
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </form>
    );
  }
}

export default compose(
  withProps(() => ({
    model: "material"
  })),
  wrapDialog({ title: "Update Material" }),
  withStrainUnits,
  reduxForm({
    enableReinitialize: true,
    form: "updateMaterialForm",
    ...asyncValidateName
  }),
  tgFormValues("overwriteStrainFields")
)(UpdateMaterialDialog);
