/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get, isNil, startCase } from "lodash";
import RecordInfoDisplay from "../../../src-shared/RecordInfoDisplay";
import { Tooltip } from "@blueprintjs/core";

const strainGrowthConditionOverwriteFields = [
  "name",
  "description",
  "shakerSpeed",
  "shakerThrow",
  "temperature",
  "humidity",
  "growthMedia.name",
  "gasComposition.name"
];

const concatIfNotNil = (val, extra) => {
  if (!isNil(val)) {
    val += extra;
  }
  return val;
};

function DisplayGrowthConditions({
  strain,
  material,
  showStrainOverwrites = true
}) {
  const baseStrain = strain || material.strain;
  if (
    baseStrain.growthCondition ||
    (material && material.growthConditionOverwrite)
  ) {
    const displayReplacer = {
      "growthMedia.name": "Growth Medium",
      "gasComposition.name": "Gas Composition"
    };
    const recordInfo = [];
    const numFieldsWithUnit = {
      shakerSpeed: " rpm",
      temperature: " °C",
      humidity: "%"
    };
    const matVal = get(material, "growthConditionOverwrite.lengthUnitCode");
    const strainVal = get(baseStrain.growthCondition, "lengthUnitCode", "");
    let lengthUnit = matVal ?? strainVal;
    if (!showStrainOverwrites) {
      lengthUnit = strainVal;
    }
    numFieldsWithUnit["shakerThrow"] = " " + lengthUnit;
    strainGrowthConditionOverwriteFields.forEach(field => {
      let overwritten = false;
      let val = get(material, `growthConditionOverwrite.${field}`);
      if (!isNil(val) && showStrainOverwrites) {
        overwritten = true;
      } else {
        val = get(baseStrain.growthCondition, field);
      }
      const displayName = displayReplacer[field] || startCase(field);
      if (numFieldsWithUnit[field]) {
        val = concatIfNotNil(val, numFieldsWithUnit[field]);
      }
      if (overwritten) {
        val = (
          <OverwrittenMessage material={material}>{val}</OverwrittenMessage>
        );
      }
      recordInfo.push([displayName, val]);
    });

    return (
      <div>
        <h6>Growth Conditions</h6>
        <RecordInfoDisplay recordInfo={recordInfo} />
      </div>
    );
  } else {
    return null;
  }
}

const OverwrittenMessage = ({ material, children }) => {
  let strainType;
  let materialType;
  if (material.strain?.strainTypeCode === "MICROBIAL_STRAIN") {
    strainType = "Strain";
    materialType = "material";
  } else {
    strainType = "Cell Line";
    materialType = "cell culture";
  }
  return (
    <Tooltip content={`${strainType} value overwritten by ${materialType}.`}>
      <span style={{ fontWeight: 700 }}>{children} *</span>
    </Tooltip>
  );
};

export function targetOrganismDisplay({
  material,
  strain,
  showStrainOverwrites = true
}) {
  let overwritten = true;
  const baseStrain = strain || material.strain;
  const strainVal = get(baseStrain, "targetOrganismClass.name");
  if (!showStrainOverwrites) return strainVal;
  let targetOrganismClass = get(material, "targetOrganismClassOverwrite.name");
  if (!targetOrganismClass) {
    overwritten = false;
    targetOrganismClass = strainVal;
  }
  if (overwritten) {
    targetOrganismClass = (
      <OverwrittenMessage material={material}>
        {targetOrganismClass}
      </OverwrittenMessage>
    );
  }
  return targetOrganismClass || null;
}

export function biosafetyDisplay({
  material,
  strain,
  showStrainOverwrites = true
}) {
  const baseStrain = strain || material.strain;
  const strainVal = get(baseStrain, "biosafetyLevel.name");
  if (!showStrainOverwrites) return strainVal;
  let overwritten = true;
  let biosafety = get(material, "biosafetyLevelOverwrite.name");
  if (!biosafety) {
    overwritten = false;
    biosafety = strainVal;
  }
  if (overwritten) {
    biosafety = (
      <OverwrittenMessage material={material}>{biosafety}</OverwrittenMessage>
    );
  }
  return biosafety || null;
}

export function inductionMethodsDisplay({
  material,
  strain,
  showStrainOverwrites = true
}) {
  const baseStrain = strain || material.strain;
  const strainVal = baseStrain.inductionMethodStrains
    .map(im => im.inductionMethod.name)
    .join(", ");
  if (!showStrainOverwrites) return strainVal;
  let overwritten = true;
  let materialInductionMethods = [];
  if (material) {
    materialInductionMethods = material
      .microbialMaterialMicrobialMaterialInductionMethods.length
      ? material.microbialMaterialMicrobialMaterialInductionMethods
      : material.cellCultureCellCultureInductionMethods;
  }
  let finalVal;
  if (!materialInductionMethods.length) {
    overwritten = false;
    finalVal = strainVal;
  } else {
    finalVal = materialInductionMethods
      .map(im => im.inductionMethod.name)
      .join(", ");
  }
  if (overwritten) {
    finalVal = (
      <OverwrittenMessage material={material}>{finalVal}</OverwrittenMessage>
    );
  }
  return finalVal || null;
}

export default DisplayGrowthConditions;
