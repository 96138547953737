/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import {
  getElementsInBin,
  getFullEliminatedCombinationGroupsOfCard,
  getRootCardId
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { cartesianProductOf } from "../../../../../tg-iso-design/utils/combinatorialUtils";
import { v4 as uuid } from "uuid";
import { map } from "lodash";

export default function convertToListLayout(designState, _, state) {
  const changeSetsHelper = new ChangeSetsHelper(designState);
  const bins = Object.values(designState.bin);
  const fasByElIdMap = Object.values(designState.fas).reduce((acc, fas) => {
    acc[fas.elementId] = fas;
    return acc;
  }, {});
  const elsOfBins = map(bins, bin => getElementsInBin(state, bin.id));
  const elCombos = cartesianProductOf(elsOfBins.filter(a => a.length));

  const eliminatedCombos = getFullEliminatedCombinationGroupsOfCard(
    state,
    getRootCardId(state)
  );

  const eliminatedCombosMap = {};
  for (const eliminatedCombo of eliminatedCombos) {
    const elementCombinationsInGroup = cartesianProductOf(
      eliminatedCombo.elementGroups
    );
    for (const elementCombo of elementCombinationsInGroup) {
      eliminatedCombosMap[elementCombo.map(el => el.id).join("_")] = true;
    }
  }

  const nonEliminatedCombos = elCombos.filter(
    elCombo => !eliminatedCombosMap[elCombo.map(el => el.id).join("_")]
  );

  if (nonEliminatedCombos.length) {
    changeSetsHelper.deletePure("element", Object.keys(designState.element));
    if (Object.keys(designState.fas).length) {
      changeSetsHelper.deletePure("fas", Object.keys(designState.fas));
    }
  }

  nonEliminatedCombos.forEach((nonEliminatedCombo, comboIndex) => {
    nonEliminatedCombo.forEach(elementInCombo => {
      const newElCid = uuid();
      changeSetsHelper.createPure("element", {
        ...elementInCombo,
        index: comboIndex,
        id: newElCid
      });

      if (fasByElIdMap[elementInCombo.id]) {
        changeSetsHelper.createPure("fas", {
          ...fasByElIdMap[elementInCombo.id],
          elementId: newElCid
        });
      }
    });
  });

  changeSetsHelper.updatePure("design", {
    id: Object.keys(designState.design)[0],
    layoutType: "list",
    numRows: nonEliminatedCombos.length
  });

  return changeSetsHelper.execute();
}
