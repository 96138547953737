/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { takeEvery, select, call, put } from "redux-saga/effects";
import actions from "../../actions";
import { handleSaveDesign } from "../saveDesign/index";
import {
  getAllOfType,
  getDesign
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { getRemoveInterruptedFeatures } from "../../../../../tg-iso-design/selectors/submitForAssemblySelectors";
import { socketWrapper } from "../../../../src-shared/socket/socketWrapper";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { formatDateTime } from "../../../../src-shared/utils/dateUtils";
import { keyBy } from "lodash";
import { disabledAssemblyMethods } from "../../../utils/disabledAssemblyMethods";

function* handleSubmitDesignForAssembly() {
  let design;
  try {
    if (disabledAssemblyMethods?.length) {
      const reactions = Object.values(
        yield select(state => getAllOfType(state, "reaction"))
      );
      const assemblyMethods = keyBy(
        Object.values(
          yield select(state => getAllOfType(state, "assemblyMethod"))
        ),
        "id"
      );
      let disabledReactionType;
      const hasDisabledReactionType = reactions.find(r => {
        const assemblyMethod = assemblyMethods[r.assemblyMethodId];
        const disabled = disabledAssemblyMethods.includes(assemblyMethod?.name);
        if (disabled) {
          disabledReactionType = assemblyMethod.name;
        }
        return disabled;
      });
      if (hasDisabledReactionType) {
        return window.toastr.error(
          `Cannot submit design because the assembly method ${disabledReactionType} has been disabled.`
        );
      }
    }

    design = yield select(getDesign);

    yield put(actions.j5.submittingToJ5Start({ designId: design.id }));
    const removeInterruptedFeatures = yield select(
      getRemoveInterruptedFeatures
    );
    yield put(
      actions.j5.submitDesignForAssemblyStarted({ designId: design.id })
    );

    if (!design.isLocked) {
      // Save the design first.
      yield call(handleSaveDesign);
    }

    const {
      data: { ids }
    } = yield window.serverApi.request({
      method: "POST",
      headers: getRequestHeaderKeys(),
      url: "/duplicateDesigns",
      data: {
        designIds: [design.id],
        name: `${design.name} - ${formatDateTime(new Date())}`,
        trackOriginalDesign: true,
        duplicateParameterSetAsLocal: true,
        duplicatePartsAndSequences: false,
        includeCommentsAndReviews: true
      }
    });

    const dupDesignId = ids[0];
    if (window.Cypress) {
      window.Cypress.__tgDupDesignId = dupDesignId;
    }
    yield safeUpsert("design", [
      {
        id: dupDesignId,
        originalDesignId: design.id,
        lockedMessage:
          "This design copy has been locked as read-only upon submission for assembly",
        lockMsgDescription: null,
        lockTypeCode: "LOCKED_FOR_ASSEMBLY",
        isLocked: true
      }
    ]);

    yield call(socketWrapper.startJ5, {
      labId: design.labId,
      designId: design.id,
      lockedDesignId: dupDesignId,
      removeInterruptedFeatures
    });
  } catch (e) {
    // TODO: Better handling.
    console.error(e);
    yield call(window.toastr.error, "Error submitting for assembly.");
    yield put(
      actions.j5.submitDesignForAssemblyFailed({ designId: design.id })
    );
  }
  yield put(actions.j5.submittingToJ5Finish({ designId: design.id }));
}

export default function* watchSubmitDesignForAssembly() {
  yield takeEvery(
    actions.j5.submitDesignForAssembly,
    handleSubmitDesignForAssembly
  );
}
