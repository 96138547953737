/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment aliquotContainerTypeFragment on aliquotContainerType {
    code
    name
    description
    isSystemType
    volumetricUnitCode
    isTube
    maxVolume
    deadVolume
    deadVolumetricUnitCode
    bottom
  }
`;
