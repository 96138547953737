/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Dialog, Classes, Intent, Button } from "@blueprintjs/core";
import { DataTable, DialogFooter } from "@teselagen/ui";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import SelectBinsForTemplate from "./SelectBinsForTemplate";

import piecemealDesignQuery from "../../../graphql/queries/piecemealDesignQuery";
import { SBOL_ICON_WIDTH } from "../../../../src-shared/components/HierarchicalDesign/constants";

class ApplyDesignTemplateDialog extends React.Component {
  state = {
    designTemplate: null,
    submitting: false,
    page: 0,
    selectedBinIndices: []
  };
  nextPage = () => {
    this.setState(prevState => {
      return { page: prevState.page + 1 };
    });
  };

  previousPage = () => {
    this.setState(prevState => {
      return { page: prevState.page - 1 };
    });
  };

  handleSelect = designTemplate => this.setState({ designTemplate });
  handleDoubleClick = designTemplate => {
    this.handleSelect(designTemplate);
    this.nextPage();
  };

  onSubmit = defaultAsyncWrap(async () => {
    this.setState({ submitting: true });
    const { applyDesignTemplate, selectedCardId, hideModal, bins } = this.props;
    const {
      designTemplate: { id },
      selectedBinIndices
    } = this.state;

    const fullTemplate = await piecemealDesignQuery(id);

    applyDesignTemplate({
      cardId: selectedCardId,
      binIds: selectedBinIndices.map(ind => bins[ind].id),
      fullTemplate
    });
    hideModal();
    this.setState({ submitting: false });
  }, "Error applying design template.");

  renderFirstPage = () => {
    const { tableParams } = this.props;
    return (
      <DataTable
        {...tableParams}
        withSearch
        withPaging
        isSingleSelect
        onDoubleClick={this.handleDoubleClick}
        isInfinite={false}
        onSingleRowSelect={this.handleSelect}
      />
    );
  };

  renderSecondPage = () => {
    const { designTemplate, selectedBinIndices } = this.state;
    const { numPlaceholders } = designTemplate;
    const s = numPlaceholders > 1 ? "s" : "";
    return (
      <React.Fragment>
        Choose the bins that will be swapped out with the placeholders from the
        template "{designTemplate.name}
        ". There {numPlaceholders > 1 ? "are" : "is"} {numPlaceholders}{" "}
        placeholder
        {s} in the template, so you'll need to select {numPlaceholders}{" "}
        contiguous bin
        {s}.
        <SelectBinsForTemplate
          setParentState={(...args) => this.setState(...args)}
          selectedBinIndices={selectedBinIndices}
          scale={this.getScaleFactor()}
        />
      </React.Fragment>
    );
  };

  isSelectionValid = () => {
    const { page, selectedBinIndices, designTemplate } = this.state;
    if (page !== 1) return false;
    if (selectedBinIndices.length !== designTemplate.numPlaceholders)
      return false;
    return [...selectedBinIndices]
      .sort((a, b) => a - b)
      .reduce((isContig, ind, i, array) => {
        if (i === 0) return isContig;
        else if (ind - 1 !== array[i - 1]) return false;
        else return isContig;
      }, true);
  };

  getMaxWidth = () => window.innerWidth - 100;

  getScaleFactor = () => {
    const { numBins } = this.props;
    const maxWidth = this.getMaxWidth();
    const rawWidth = numBins * SBOL_ICON_WIDTH + 50;
    return rawWidth > maxWidth ? maxWidth / rawWidth : 1;
  };

  render() {
    const { hideModal, numBins } = this.props;

    const { designTemplate, submitting, page } = this.state;
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={() => hideModal()}
        title="Choose Design Template"
        style={{
          width:
            page === 1
              ? Math.min(
                  Math.max(numBins * SBOL_ICON_WIDTH + 50, 700),
                  this.getMaxWidth()
                )
              : 700
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          {page === 0 && this.renderFirstPage()}
          {page === 1 && this.renderSecondPage()}
        </div>
        <DialogFooter
          hideModal={hideModal}
          additionalButtons={
            <React.Fragment>
              <Button
                intent={Intent.PRIMARY}
                disabled={page === 0}
                onClick={this.previousPage}
                text="Back"
              />
              <Button
                intent={Intent.PRIMARY}
                disabled={page === 1 || !designTemplate}
                onClick={this.nextPage}
                text="Next"
              />
            </React.Fragment>
          }
          text="Apply Template"
          loading={submitting}
          disabled={!this.isSelectionValid()}
          onClick={this.onSubmit}
        />
      </Dialog>
    );
  }
}

export default ApplyDesignTemplateDialog;
