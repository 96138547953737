// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n.ModelSigmaHistogramWidgetStyles-module__container-2wW6u {\n  margin: auto;\n  height: 95%;\n  width: 100%;\n}\n\n.ModelSigmaHistogramWidgetStyles-module__viz-1pBil .ModelSigmaHistogramWidgetStyles-module__rect-ohbvY:hover {\n  fill: #3dcc91;\n}\n\n.ModelSigmaHistogramWidgetStyles-module__tip-2fGHw {\n  line-height: 1;\n  font-weight: bold;\n  padding: 12px;\n  background: rgba(0, 0, 0, 0.8);\n  color: #fff;\n  border-radius: 2px;\n}\n\n/* Creates a small triangle extender for the tooltip */\n.ModelSigmaHistogramWidgetStyles-module__tip-2fGHw:after {\n  box-sizing: border-box;\n  display: inline;\n  font-size: 10px;\n  width: 100%;\n  line-height: 1;\n  color: rgba(0, 0, 0, 0.8);\n  content: \"\\25BC\";\n  position: absolute;\n  text-align: center;\n  margin: -1px 0 0 0;\n  top: 100%;\n  left: 0;\n}\n\n.ModelSigmaHistogramWidgetStyles-module__headerSlider-6pMPh {\n  display: flex;\n  width: 350px;\n  margin-right: 30px;\n}\n\n.ModelSigmaHistogramWidgetStyles-module__headerSlider-6pMPh h5 {\n  font-size: small;\n  width: 250px;\n  margin-top: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ModelSigmaHistogramWidgetStyles-module__container-2wW6u",
	"viz": "ModelSigmaHistogramWidgetStyles-module__viz-1pBil",
	"rect": "ModelSigmaHistogramWidgetStyles-module__rect-ohbvY",
	"tip": "ModelSigmaHistogramWidgetStyles-module__tip-2fGHw",
	"headerSlider": "ModelSigmaHistogramWidgetStyles-module__headerSlider-6pMPh"
};
module.exports = exports;
