/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "recompose";
import { cloneDeep } from "lodash";
import SchematicEditView from "../components/SchematicEditView";
import schematicFragment from "../graphql/fragments/schematicFragment";
import recordViewEnhancer from "../../src-shared/recordViewEnhancer";

export default compose(
  recordViewEnhancer(schematicFragment, {
    withQueryOptions: {
      props: ({ data: { schematic } }) => {
        if (!schematic) return {};
        schematic = cloneDeep(schematic);
        schematic.schematicSets.sort((a, b) => a.index - b.index);
        schematic.schematicSets.forEach(s =>
          s.schematicElements.sort((a, b) => a.index - b.index)
        );
        return { schematic };
      }
    }
  })
)(SchematicEditView);
