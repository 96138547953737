/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment containerArrayNormalizationFragment on containerArray {
    id
    name
    aliquotContainers {
      id
      columnPosition
      rowPosition
      aliquotContainerType {
        code
        maxVolume
        volumetricUnitCode
        deadVolume
        deadVolumetricUnitCode
      }
      aliquot {
        id
        isDry
        concentration
        concentrationUnitCode
        molarity
        molarityUnitCode
        volume
        volumetricUnitCode
        mass
        massUnitCode
        cellConcentration
        cellConcentrationUnitCode
        sample {
          id
          name
          material {
            id
            functionalProteinUnit {
              id
              molecularWeight
            }
            polynucleotideMaterialSequence {
              id
              molecularWeight
            }
          }
        }
      }
    }
    containerArrayType {
      id
      name
      isPlate
      aliquotContainerType {
        code
        name
        description
        isSystemType
        volumetricUnitCode
        maxVolume
      }
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
  }
`;
