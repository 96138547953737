/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { FieldArray, Field } from "redux-form";
import { SelectField, CheckboxField, BlueprintError } from "@teselagen/ui";
import { UploadTwistPlateDialog } from "../../Dialogs/UploadTwistPlateDialog";
import { UploadIdtPlateDialog } from "../../Dialogs/UploadIdtPlateDialog";
import {
  RemoveButton,
  AddButton
} from "../../../../src-shared/FieldArrayButtons";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { UploadReagentPlateDialog } from "../../Dialogs/UploadReagentPlateDialog";
import { UploadProteinPlateDialog } from "../../Dialogs/UploadProteinPlateDialog";
import { UploadDnaPlateDialog } from "../../Dialogs/UploadDnaPlateDialog";
import { UploadMicrobialPlateDialog } from "../../Dialogs/UploadMicrobialPlateDialog";
import { UploadCellCulturePlate } from "../../Dialogs/UploadCellCulturePlateDialog";

class UploadPlate extends Component {
  render() {
    const { uploadForms = [], Footer, footerProps } = this.props;
    return (
      <React.Fragment>
        <FieldArray
          name="uploadForms"
          fieldValues={uploadForms}
          component={renderUploadForm}
        />
        <Footer {...footerProps} nextDisabled={!uploadForms.length} />
      </React.Fragment>
    );
  }
}

function renderUploadForm({ fields, fieldValues }) {
  return (
    <div>
      <CheckboxField
        tooltipInfo="If selected, after the plate is uploaded you will be prompted to place the new plates into a freezer"
        name="shouldAssignToLocation"
        label="Assign to Location"
      />
      <div style={{ marginBottom: 15 }} />
      {fields.map((field, index) => {
        let UploadComp;
        if (
          fieldValues[index].uploadType === "Twist" ||
          !fieldValues[index].uploadType
        ) {
          UploadComp = UploadTwistPlateDialog;
        } else if (fieldValues[index].uploadType === "IDT") {
          UploadComp = UploadIdtPlateDialog;
        } else if (fieldValues[index].uploadType === "Reagent") {
          UploadComp = UploadReagentPlateDialog;
        } else if (fieldValues[index].uploadType === "Protein") {
          UploadComp = UploadProteinPlateDialog;
        } else if (fieldValues[index].uploadType === "DNA") {
          UploadComp = UploadDnaPlateDialog;
        } else if (fieldValues[index].uploadType === "Microbial") {
          UploadComp = UploadMicrobialPlateDialog;
        } else if (fieldValues[index].uploadType === "Cell Culture") {
          UploadComp = UploadCellCulturePlate;
        }

        return (
          <div className="tg-step-form-section" key={index}>
            <div style={{ flex: 1 }}>
              <div className="tg-flex" style={{ maxWidth: 400 }}>
                <RemoveButton fields={fields} index={index} />
                <div className="tg-flex-separator" />
                <div style={{ flex: 1 }}>
                  <SelectField
                    name={`${field}.uploadType`}
                    label="Vendor or Type"
                    options={[
                      "DNA",
                      "Twist",
                      "IDT",
                      "Microbial",
                      "Cell Culture",
                      "Reagent",
                      "Protein"
                    ]}
                    defaultValue="Twist"
                  />
                </div>
              </div>
              <Field name={`${field}.generalError`} component={renderError} />
            </div>
            <div className="tg-flex-separator" />
            <div style={{ width: "50%" }}>
              <UploadComp noDialog fieldPrefix={field} />
            </div>
          </div>
        );
      })}
      <div style={{ marginTop: 15 }} />
      <AddButton fields={fields} label="Upload Another Plate" noMargin />
    </div>
  );
}

export default stepFormValues("uploadForms")(UploadPlate);

function renderError({ meta: { error } }) {
  if (error) {
    return <BlueprintError error={error} />;
  } else {
    return null;
  }
}
