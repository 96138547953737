/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { RadioGroupField } from "@teselagen/ui";

function SequenceExportFormatsRadio({
  isProtein,
  name = "format",
  label = "Export as"
}) {
  return (
    <RadioGroupField
      name={name}
      label={label}
      options={[
        { label: isProtein ? "GenPept" : "Genbank", value: "genbank" },
        { label: "Fasta", value: "fasta" },
        { label: "Teselagen JSON", value: "json" },
        ...(isProtein ? [] : [{ label: "CSV", value: "csv" }])
      ]}
      defaultValue="genbank"
    />
  );
}

export default SequenceExportFormatsRadio;
