/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  InputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class CreateGenusDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop, initialValues = {} } = this.props;
    const {
      id: updatedId,
      name,
      description,
      abbreviation,
      species = []
    } = values;
    try {
      await safeUpsert(["genus", "id name description abbreviation"], {
        id: updatedId,
        name,
        description,
        abbreviation
      });
      if (
        initialValues.abbreviation &&
        initialValues.abbreviation !== abbreviation &&
        species.length
      ) {
        const specieUpdates = [];
        species.forEach(specie => {
          if (
            specie.abbreviatedName &&
            specie.abbreviatedName.startsWith(initialValues.abbreviation)
          ) {
            specieUpdates.push({
              id: specie.id,
              abbreviatedName: specie.abbreviatedName.replace(
                initialValues.abbreviation,
                abbreviation
              )
            });
          }
        });
        await safeUpsert("specie", specieUpdates);
      }
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating genus.");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            placeholder="Enter genus name..."
          />
          <InputField
            name="abbreviation"
            label="Abbreviation"
            isRequired
            placeholder="Enter abbreviation..."
          />
          <TextareaField
            name="description"
            label="Description"
            placeholder="Enter genus description..."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreateGenusWithDialog = compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id") ? "Edit Genus" : "Create Genus"
      };
    }
  }),
  reduxForm({
    form: "createGenus"
  }),
  withQuery(["genus", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(CreateGenusDialog);

export default CreateGenusWithDialog;
