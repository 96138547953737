/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import GqlErrorFormatter from "./formatter";

class DuplicationFormatter extends GqlErrorFormatter {
  static TYPE = "DUPLICATION";

  static check(errorMessage) {
    return errorMessage.includes("violates unique constraint");
  }

  static handler({ error, operation }) {
    const { operationName } = operation;
    const modelNameRegex = new RegExp(/insert into "(.*?)"/);
    const modelName = get(error.message.match(modelNameRegex), "1");
    const readableName = modelNameToReadableName(modelName) || "model";
    error.message = `[${DuplicationFormatter.TYPE}]: ${operationName} attempt on ${readableName}`;
    error.type = DuplicationFormatter.TYPE;
  }
}

export default DuplicationFormatter;
