/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import pluralize from "pluralize";
import { generateQuery } from "@teselagen/apollo-methods";
import client from "../apolloClient";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { cloneDeep, get } from "lodash";

export function readFragment(fragment, options = {}) {
  const isPlural = !get(options, "variables.id");

  const model = getModelNameFromFragment(fragment);
  const modelOrPlural = isPlural ? pluralize(model) : model;
  const query = generateQuery(fragment, {
    isPlural
  });

  const { [modelOrPlural]: itemOrItems } = client.readQuery({
    query,
    ...options
  });

  return itemOrItems;
}

export function writeFragment(fragment, data, options = {}) {
  const { updater, ...rest } = options;
  const isPlural = !get(rest, "variables.id");
  const model = getModelNameFromFragment(fragment);
  const modelOrPlural = isPlural ? pluralize(model) : model;
  const query = generateQuery(fragment, {
    isPlural
  });

  client.writeQuery({
    query,
    ...rest,
    data: { [modelOrPlural]: data }
  });
}

export function updateCache(options) {
  const { fragment, updater, ...rest } = options;

  const itemOrItems = readFragment(fragment, rest);
  const updatedItems = updater(cloneDeep(itemOrItems));
  writeFragment(fragment, updatedItems, rest);
}
