/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { get, flatMap, isEmpty } from "lodash";
import { DataTable, BlueprintError } from "@teselagen/ui";
import QueryBuilder from "tg-client-query-builder";
import { validateNoDryPlatesObject } from "../../../../utils/plateUtils";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import plateMapGroupRunPcrFragment from "../../../../graphql/fragments/plateMapGroupRunPcrFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import PlateMapView from "../../../../components/PlateMapView";
import runPcrDataTableFragment from "../../../../graphql/fragments/runPcrDataTableFragment";
import {
  dateModifiedColumn,
  volumeColumn,
  concentrationColumn
} from "../../../../../src-shared/utils/libraryColumns";
import { addTagFilterToQuery } from "../../../../../src-shared/utils/tagUtils";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { InventoryCheckIcon } from "../../../../../src-shared/components/InventoryCheckIcon";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

export const containerArrayFragment = [
  "containerArray",
  `id
  name
  containerArrayType {
    id
    name
  }
  aliquotContainers {
    id
    rowPosition
    columnPosition
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      sample {
        id
        material {
          id
          polynucleotideMaterialSequence {
            id
          }
        }
      }
    }
  }`
];
class SelectPCRMaterials extends Component {
  state = {
    fetchingDataTableAliquots: false
  };

  fetchDataTableAliquots = async records => {
    const {
      stepFormProps: { change }
    } = this.props;
    this.alreadyFetchedMaterials = true;
    this.setState({
      fetchingDataTableAliquots: true
    });
    try {
      const aliquotIds = flatMap(records, record =>
        record.dataRows.map(row => row.rowValues.aliquotId)
      );
      const qb = new QueryBuilder("aliquot");
      const filter = qb
        .whereAll({
          id: qb.inList(aliquotIds),
          "aliquotContainer.id": qb.notNull()
        })
        .toJSON();
      const dataTableAliquots = await safeQuery(
        [
          "aliquot",
          `id
          isDry
          volume
          volumetricUnitCode
          concentration
          concentrationUnitCode
          sample {
            id
            material {
              id
              name
              polynucleotideMaterialSequence {
                id
                name
              }
            }
          }
          aliquotContainer {
            id
            name
            rowPosition
            columnPosition
            containerArray {
              id
              name
            }
          }
          updatedAt
          createdAt
          `
        ],
        {
          variables: { filter }
        }
      );
      change("dataTableAliquots", dataTableAliquots);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading table aliquots.");
    }
    this.setState({
      fetchingDataTableAliquots: false
    });
  };

  removeDataTableAliquots = () => {
    this.props.stepFormProps.change("dataTableAliquots", []);
  };

  render() {
    const { fetchDataTableAliquots } = this.state;
    const {
      plateMapGroup,
      containerArrays = [],
      dataTableAliquots = [],
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      Footer,
      footerProps
    } = this.props;
    const missingSequencesMap = plateMapGroup
      ? plateMapGroup.plateMaps.reduce((acc, plateMap) => {
          plateMap.plateMapItems.forEach(plateMapItem => {
            const sequences = [];
            sequences.push(
              get(plateMapItem, "j5Item.j5PcrReaction.primaryTemplate")
            );
            sequences.push(
              get(plateMapItem, "j5Item.j5PcrReaction.forwardPrimer.sequence")
            );
            sequences.push(
              get(plateMapItem, "j5Item.j5PcrReaction.reversePrimer.sequence")
            );
            sequences.forEach(sequence => {
              if (sequence)
                acc[sequence.id] = {
                  name: sequence.name,
                  missing: true
                };
            });
          });
          return acc;
        }, {})
      : {};

    const updateMissingMap = sequence => {
      if (sequence && missingSequencesMap[sequence.id]) {
        missingSequencesMap[sequence.id].missing = false;
      }
    };

    const plateMapHasProductMaterials =
      plateMapGroup &&
      plateMapGroup.plateMaps.length > 0 &&
      plateMapGroup.plateMaps.every(pm =>
        pm.plateMapItems.every(pmi =>
          get(
            pmi,
            "j5Item.j5PcrReaction.pcrProductSequence.polynucleotideMaterial.id"
          )
        )
      );

    if (!isEmpty(missingSequencesMap)) {
      containerArrays.forEach(c => {
        c.aliquotContainers.forEach(ac => {
          const sequence = get(
            ac,
            "aliquot.sample.material.polynucleotideMaterialSequence"
          );
          updateMissingMap(sequence);
        });
      });
      dataTableAliquots.forEach(aliquot => {
        const sequence = get(
          aliquot,
          "sample.material.polynucleotideMaterialSequence"
        );
        updateMissingMap(sequence);
      });
    }
    const materialEntities = Object.values(missingSequencesMap);
    const anyMaterialsMissing = materialEntities.some(mat => mat.missing);
    const anyMaterials = materialEntities.length;
    const dryAliquotsFromInventory = [];

    dataTableAliquots.forEach(aliquot => {
      if (aliquot.isDry) dryAliquotsFromInventory.push(aliquot);
    });

    const plateErrors = validateNoDryPlatesObject(containerArrays);

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plate Map"
            width="100%"
            helper="Please select a PCR plate map. This will allow us
            to validate that all required materials are present and dictate
            worklist transfers based on well position."
          />
          <GenericSelect
            {...{
              name: "plateMapGroup",
              buttonProps: {
                text: plateMapGroup ? "Change Plate Map" : "Select Plate Map",
                loading: isLoadingMap.plateMapGroup,
                disabled: isDisabledMap.plateMapGroup
              },
              nameOverride: "Plate Map",
              schema: ["name", dateModifiedColumn],
              fragment: ["plateMapGroup", "id name updatedAt"],
              additionalDataFragment: plateMapGroupRunPcrFragment
            }}
          />
          {plateMapGroup && (
            <PlateMapView
              plateMapGroup={plateMapGroup}
              tableSchema={pcrProductSchema}
            />
          )}
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Input Plates"
            width="100%"
            helper="Please select one or more input plates
            of PCR materials. These  will be transferred
            to the destination plate according to the selected
            plate map above."
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              nameOverride: "Input Plates",
              isMultiSelect: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              fragment: [
                "containerArray",
                "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: containerArrayFragment,
              postSelectDTProps: {
                formName: "runPCRSelectedPlates",
                plateErrors,
                schema: [
                  platePreviewColumn({
                    plateErrors
                  }),
                  "name",
                  {
                    path: "containerArrayType.name",
                    displayName: "Plate Type"
                  }
                ]
              }
            }}
          />
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select PCR Inventory Lists"
            width="100%"
            helper="It is also possible to select from inventory lists of PCR materials. These will be transferred to the destination plate according to the selected plate map. You can use materials sourced both from plates and from inventory lists"
          />
          <GenericSelect
            {...{
              name: "dataTables",
              schema: ["name", dateModifiedColumn],
              isMultiSelect: true,
              buttonProps: {
                loading: isLoadingMap.dataTables || fetchDataTableAliquots,
                disabled: isDisabledMap.dataTables
              },
              nameOverride: "PCR Inventory Lists",
              onSelect: this.fetchDataTableAliquots,
              fragment: ["dataTable", "id name updatedAt"],
              additionalDataFragment: runPcrDataTableFragment,
              onClear: this.removeDataTableAliquots,
              tableParamOptions: { additionalFilter: additionalFilterForTables }
            }}
          />
          {!!dataTableAliquots.length && (
            <DataTable
              formName="pcrMaterialsFromInventory"
              style={{ marginBottom: 15 }}
              isSimple
              destroyOnUnmount={false}
              schema={aliquotSchema}
              entities={dataTableAliquots}
            />
          )}
        </div>
        {anyMaterials ? (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Validate PCR Materials"
              helper="The following materials are required for PCR.
              You will not be able to proceed until all relevant plates
              and inventory lists have been selected."
              width="100%"
            />
            <DataTable
              formName="runPcrV2MissingMaterialsTable"
              entities={materialEntities}
              maxHeight={250}
              schema={[
                {
                  width: 80,
                  path: "missing",
                  noTitle: true,
                  render: renderMissingMaterialIcon
                },
                { displayName: "Material", path: "name" }
              ]}
              isSimple
            />
          </div>
        ) : (
          plateMapGroup && (
            <BlueprintError error="No PCR input materials found in plate map." />
          )
        )}
        {!isEmpty(plateErrors) && (
          <BlueprintError error="Review plate errors." />
        )}
        {!!dryAliquotsFromInventory.length && (
          <BlueprintError error="The selected inventory lists contain dry aliquots and cannot be used." />
        )}
        {plateMapGroup && !plateMapHasProductMaterials && (
          <BlueprintError error="The selected plate map has PCR product sequences that are not linked to materials." />
        )}
        <Footer
          {...footerProps}
          nextDisabled={
            fetchDataTableAliquots ||
            anyMaterialsMissing ||
            !plateMapHasProductMaterials ||
            !anyMaterials ||
            !isEmpty(plateErrors) ||
            dryAliquotsFromInventory.length
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("plateMapGroup", "dataTableAliquots", "containerArrays")
)(SelectPCRMaterials);

const additionalFilterForTables = (props, qb, currentParams) => {
  qb.andWhereAll({
    dataTableTypeCode: "PCR_INVENTORY_MATERIALS"
  });
  addTagFilterToQuery(currentParams.tags, qb);
};

function renderMissingMaterialIcon(missing) {
  return <InventoryCheckIcon inInventory={!missing}></InventoryCheckIcon>;
}

const pcrProductSchema = {
  model: "plateMapEntity",
  fields: [
    {
      path: "location",
      type: "string",
      displayName: "Location"
    },
    {
      path: "j5Item.j5PcrReaction.pcrProductSequence.name",
      type: "string",
      displayName: "PCR Product Sequence"
    },
    {
      path: "j5Item.j5PcrReaction.primaryTemplate.name",
      type: "string",
      displayName: "Primary Template"
    },
    {
      path: "j5Item.j5PcrReaction.forwardPrimer.sequence.name",
      type: "string",
      displayName: "Forward Primer"
    },
    {
      path: "j5Item.j5PcrReaction.reversePrimer.sequence.name",
      type: "string",
      displayName: "Reverse Primer"
    }
  ]
};

const aliquotSchema = {
  model: "aliquot",
  fields: [
    { path: "sample.material.name", type: "string", displayName: "Material" },
    {
      path: "aliquotContainer.containerArray.name",
      type: "string",
      displayName: "Plate"
    },
    {
      displayName: "Well",
      render: (v, r) => getAliquotContainerLocation(r.aliquotContainer)
    },
    volumeColumn,
    concentrationColumn,
    { path: "updatedAt", type: "timestamp", displayName: "Last Updated" }
  ]
};
