/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { getReferencedValue } from "../../../tg-iso-design/selectors/designStateSelectors";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";
import { isErdam } from "../utils/addErdamUtils";

export const isReactionErdam = tgCreateCachedSelector(
  (state, reactionId) =>
    getReferencedValue(state, "reaction", reactionId, "assemblyMethodId"),
  assemblyMethod => isErdam(assemblyMethod)
)((state, reactionId) => reactionId);
