/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment integrationOptionsFragment on integration {
    id
    name
    integrationTypeCode
    subtype
    integrationEndpoints {
      id
    }
  }
`;
