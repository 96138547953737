/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

export default function({ header, helper, width }) {
  return (
    <div
      style={{
        marginRight: 30,
        marginBottom: 20,
        width: width ? width : "50%"
      }}
    >
      <h5 style={{ marginBottom: 5 }}>{header}</h5>
      <i style={{ marginBottom: 20 }}>{helper}</i>
    </div>
  );
}
