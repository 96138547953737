/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { pick, find, set, get } from "lodash";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import Big from "big.js";
import { Classes } from "@blueprintjs/core";
import UnitFields from "../../UnitFields";
import { minTransferVolume } from "../../../utils/plateUtils";
import {
  standardizeVolume,
  standardizeConcentration,
  convertConcentration,
  convertVolume
} from "../../../../src-shared/utils/unitUtils";
import { REQUIRED_ERROR } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class EditAdditiveDialog extends Component {
  onSubmit = async values => {
    const { hideModal, aliquot, refetch, initialValues } = this.props;
    const updateFields = [
      "id",
      "volume",
      "mass",
      "concentration",
      "concentrationUnitCode",
      "volumetricUnitCode",
      "massUnitCode"
    ];
    let aliquotUpdates;
    if (aliquot) {
      const standardizedAliquotVolume = standardizeVolume(
        aliquot.volume,
        aliquot.volumetricUnitCode,
        true
      );
      const standardizedInitialAdditiveVolume = standardizeVolume(
        initialValues.volume,
        initialValues.volumetricUnitCode,
        true
      );
      const standardizedUpdateVolume = standardizeVolume(
        values.volume,
        values.volumetricUnitCode,
        true
      );
      const standardizedFinalVolume = standardizedAliquotVolume
        .minus(standardizedInitialAdditiveVolume)
        .plus(standardizedUpdateVolume);
      let standardizedInitialConcentration;
      let updatedConcentration;
      if (aliquot.concentration) {
        standardizedInitialConcentration = standardizeConcentration(
          aliquot.concentration,
          aliquot.concentrationUnitCode,
          true
        );
        updatedConcentration = convertConcentration(
          standardizedInitialConcentration
            .times(standardizedAliquotVolume)
            .div(standardizedFinalVolume),
          "g/L",
          aliquot.concentrationUnitCode,
          true
        );
      }
      aliquotUpdates = [
        {
          id: aliquot.id,
          volume: convertVolume(
            standardizedFinalVolume,
            "L",
            aliquot.volumetricUnitCode,
            true
          ).toString(),
          concentration:
            aliquot.concentration && updatedConcentration.toString()
        }
      ];
    }
    try {
      const updateValues = values;
      if (!values.concentration) {
        updateValues.concentration = null;
        updateValues.concentrationUnitCode = null;
      }
      await safeUpsert(
        ["additive", updateFields],
        pick(updateValues, updateFields)
      );
      if (aliquot) {
        await safeUpsert(
          ["aliquot", "id volume concentration"],
          aliquotUpdates
        );
      }
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error", error);
      window.toastr.error("Error updating aliquot.");
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, initialValues } = this.props;
    const isDry =
      get(initialValues, "additiveMaterial.isDry") ||
      get(initialValues, "lot.additiveMaterial.isDry");

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <UnitFields
            initialValues={initialValues}
            isDry={isDry}
            concentrationTypes={["concentration"]}
            {...this.props}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
          submitting={submitting}
        />
      </div>
    );
  }
}

const validate = (values, props) => {
  const { volume, volumetricUnitCode, id } = values;
  const { aliquot, aliquotContainer, additives } = props;
  const additiveToBeUpdated = find(additives, { id: id });
  const otherAdditives = additives.filter(additive => additive.id !== id);
  if (!additiveToBeUpdated) return;
  // check to see if update is necessary
  const hasInitVolume = volume === additiveToBeUpdated.volume;
  if (hasInitVolume) return;

  let standardizedAliquotContainerMaxVolume;
  let entity;

  if (!aliquot) {
    entity = aliquotContainer;
  } else {
    entity = aliquot.aliquotContainer;
  }

  if (!aliquot || aliquot.aliquotContainer) {
    standardizedAliquotContainerMaxVolume = standardizeVolume(
      entity.aliquotContainerType.maxVolume,
      entity.aliquotContainerType.volumetricUnitCode,
      true
    );
  }

  let standardizedContentsVolume;
  if (!aliquot) {
    standardizedContentsVolume = otherAdditives.reduce(
      (acc, b) =>
        (acc = acc.plus(
          standardizeVolume(b.volume, b.volumetricUnitCode, true)
        )),
      new Big(0)
    );
  } else {
    standardizedContentsVolume = standardizeVolume(
      aliquot.volume,
      aliquot.volumetricUnitCode,
      true
    );
  }
  const standardizedInitialVolume = standardizeVolume(
    additiveToBeUpdated.volume,
    additiveToBeUpdated.volumetricUnitCode,
    true
  );
  let maxTransferVolume;
  if (!aliquot || aliquot.aliquotContainer) {
    maxTransferVolume = convertVolume(
      new Big(standardizedAliquotContainerMaxVolume)
        .minus(standardizedContentsVolume)
        .plus(standardizedInitialVolume),
      "L",
      entity.aliquotContainerType.volumetricUnitCode,
      true
    );
  }

  const errors = {};
  if (!aliquot || aliquot.aliquotContainer) {
    errors.volume = minTransferVolume({
      aliquotContainer: entity,
      maxTransferVolume,
      desiredTransferVolume: volume,
      desiredTransferVolumetricUnitCode: volumetricUnitCode,
      additive: true
    });
  }
  if (!values.volume && values.volume !== 0) {
    set(errors, "volume", REQUIRED_ERROR);
  }
  return errors;
};

export default compose(
  wrapDialog({
    getDialogProps: props => {
      const additiveMaterialName =
        get(props, "initialValues.additiveMaterial.name") ||
        get(props, "initialValues.lot.additiveMaterial.name");
      return {
        title: get(props, "initialValues.id")
          ? `Edit Additive: ${additiveMaterialName}`
          : "Add Additive"
      };
    }
  }),
  reduxForm({
    form: "createAdditiveMaterial",
    validate
  })
)(EditAdditiveDialog);
