/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniq, get, min } from "lodash";
import actions from "../../../actions";
import basicHandleActionsWithFullState from "../../basicHandleActionsWithFullState";
import {
  getRootCardId,
  getOutputtingReactionOfCard,
  getNumberOfRowsForCard,
  getDesignState,
  getItemOfType,
  getNeighborBinId,
  getElementsInBin,
  getBinIdsInCard
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import { isViewClassic } from "../../../../selectors/classicViewSelectors";
import { createObjectValuedTypeMap } from "../../../../../../tg-iso-design/utils/designStateUtils";
import { getAllSetIdsInCard } from "../../../../../../tg-iso-design/utils/designEditUtils";
import {
  getSelectedCardId,
  getSelectedCellPaths
} from "../../../../selectors/designViewSelectors";
import {
  calculateSelectedBins,
  calculateSelectedCells,
  selectedRow,
  selectAllRows
} from "../../../../utils/calculateSelection";

const emptySelection = {
  selectedCardId: null,
  selectedBinIds: [],
  /**
   * Array of objects of the form: {binId:..., index: ..., elementId}
   */
  selectedCellPaths: [],
  selectedReactionId: null,
  activeBinId: null,
  activeCellPath: null,
  selectedValidatedJunctionId: null
};

const initialState = {
  ...emptySelection,
  /**
   * Array of objects of the form: { columnIndex: ..., rowIndex: ..., element: {...}}
   */
  copiedCells: [],
  viewOptions: {
    compositeSets: "expanded",
    general: "expanded",
    listElements: "expanded",
    operation: "detailed",
    operationInjectedElements: "view",
    typeIIsRestrictionEnzymeSets: "detailed",
    treeLayout: "horizontal"
  },
  loading: false,
  lastSavedState: null,
  isSaving: false,
  submittingToJ5: false,
  isMaterialAvailabilityTrayOpen: false,
  sbolRibbonHidden: true,
  deletedItems: createObjectValuedTypeMap(),
  showRowsIndex: false,
  showRowNames: false,
  showRowDisable: true
};

const transformIdByMap = (map, id) => {
  if (!id) return id;
  return map[id] || id;
};

const basicActions = {
  [actions.designIo.saveStarted]: state => ({
    ...state,
    isSaving: true
  }),
  [actions.designIo.saveCompleted]: (
    state,
    { payload: { oldIdToNewIdMap } }
  ) => {
    const binIdMap = oldIdToNewIdMap.bin;
    const elementIdMap = oldIdToNewIdMap.element;
    const reactionIdMap = oldIdToNewIdMap.reaction;
    const junctionMap = oldIdToNewIdMap.junction;

    const deletedItems = { ...state.deletedItems };
    for (const [type, oldIdToNewId] of Object.entries(oldIdToNewIdMap)) {
      const deletedItemsOfType = Object.entries(oldIdToNewId).reduce(
        (acc, [oldId, newId]) => {
          if (!newId) acc[oldId] = true;
          return acc;
        },
        {}
      );
      deletedItems[type] = { ...deletedItems[type], ...deletedItemsOfType };
    }

    return {
      ...state,
      selectedCardId: transformIdByMap(binIdMap, state.selectedCardId),
      selectedBinIds: state.selectedBinIds.map(binId =>
        transformIdByMap(binIdMap, binId)
      ),
      selectedCellPaths: state.selectedCellPaths.map(path => ({
        ...path,
        binId: transformIdByMap(binIdMap, path.binId),
        elementId: transformIdByMap(elementIdMap, path.elementId)
      })),
      selectedReactionId: transformIdByMap(
        reactionIdMap,
        state.selectedReactionId
      ),
      activeBinId: transformIdByMap(binIdMap, state.activeBinId),
      activeCellPath: state.activeCellPath && {
        ...state.activeCellPath,
        binId: transformIdByMap(binIdMap, state.activeCellPath.binId),
        elementId: transformIdByMap(
          elementIdMap,
          state.activeCellPath.elementId
        )
      },
      isSaving: false,
      selectedValidatedJunctionId: transformIdByMap(
        junctionMap,
        state.selectedValidatedJunctionId
      ),
      deletedItems
    };
  },
  [actions.designIo.saveFailed]: state => ({
    ...state,
    ...emptySelection,
    isSaving: false
  }),

  [actions.designIo.setLastSavedState]: (state, { payload: designState }) => ({
    ...state,
    lastSavedState: designState
  }),

  [actions.designIo.fetchStarted]: state => ({
    ...state,
    loading: true
  }),

  [actions.designIo.setDesign]: state => {
    return {
      ...state,
      ...emptySelection,
      cardViewOptions: {},
      loading: false
    };
  },

  [actions.ui.designEditor.general.toggleMaterialAvailabilityTray]: state => ({
    ...state,
    isMaterialAvailabilityTrayOpen: !state.isMaterialAvailabilityTrayOpen
  }),

  [actions.ui.designEditor.general.updateSelection]: (
    state,
    {
      payload: {
        cardId,
        binIds,
        additionalBinIds,
        cells,
        activeBinId,
        activeCellPath
      }
    }
  ) => {
    const newState = { ...state };
    if (cardId !== undefined) newState.selectedCardId = cardId;
    if (binIds !== undefined) newState.selectedBinIds = binIds;
    if (cells !== undefined) newState.selectedCellPaths = cells;
    if (additionalBinIds !== undefined)
      newState.selectedBinIds = uniq([
        ...(newState.selectedCardId === state.selectedCardId
          ? newState.selectedBinIds
          : []),
        ...additionalBinIds
      ]);

    if (newState.selectedCardId !== state.selectedCardId) {
      newState.activeBinId = null;
      newState.activeCellPath = null;
    }
    if (activeBinId !== undefined) newState.activeBinId = activeBinId;
    if (activeCellPath !== undefined) newState.activeCellPath = activeCellPath;

    return newState;
  },

  [actions.ui.designEditor.general.selectCard]: (
    state,
    { payload: { cardId } }
  ) => {
    if (state.selectedCardId === cardId) return state;
    else return { ...state, ...emptySelection, selectedCardId: cardId };
  },

  [actions.ui.designEditor.general.selectBin]: (
    state,
    { payload: { cardId, binId, shift, ctrl } },
    fullState
  ) => {
    return {
      ...state,
      ...calculateSelectedBins({ state: fullState, cardId, binId, shift, ctrl })
    };
  },

  [actions.ui.designEditor.general.selectCell]: (
    state,
    { payload: { cardId, binId, index, elementId, shift, ctrl } },
    fullState
  ) => {
    return {
      ...state,
      ...calculateSelectedCells({
        state: fullState,
        cardId,
        binId,
        index,
        elementId,
        shift,
        ctrl
      })
    };
  },
  [actions.ui.designEditor.general.selectReaction]: (
    state,
    { payload: reactionId }
  ) => {
    return {
      ...state,
      selectedReactionId: reactionId
    };
  },

  [actions.ui.designEditor.general.toggleRowsIndex]: state => ({
    ...state,
    showRowsIndex: !state.showRowsIndex
  }),
  [actions.ui.designEditor.general.toggleRowNames]: state => ({
    ...state,
    showRowNames: !state.showRowNames
  }),
  [actions.ui.designEditor.general.toggleShowRowDisable]: state => ({
    ...state,
    showRowDisable: !state.showRowDisable
  }),
  [actions.ui.designEditor.general.selectRow]: (
    state,
    { payload: { cardId, index, shift, ctrl } },
    fullState
  ) => {
    return {
      ...state,
      ...selectedRow({
        state: fullState,
        cardId,
        index,
        shift,
        ctrl
      })
    };
  },

  [actions.ui.designEditor.general.selectAllRows]: (
    state,
    { payload: { cardId, numberOfRows } },
    fullState
  ) => {
    return {
      ...state,
      ...selectAllRows({
        state: fullState,
        cardId,
        numberOfRows
      })
    };
  },

  [actions.ui.designEditor.general.selectValidatedJunction]: (
    state,
    { payload: { cardId, junctionId } }
  ) => {
    const { selectedCardId } = state;
    if (cardId === selectedCardId) {
      return { ...state, selectedValidatedJunctionId: junctionId };
    } else {
      return {
        ...state,
        ...emptySelection,
        selectedCardId: cardId,
        selectedValidatedJunctionId: junctionId
      };
    }
  },

  [actions.ui.designEditor.general.updateViewOptions]: (
    state,
    { payload: changedViewOptions },
    fullState
  ) => {
    const { treeLayout } = changedViewOptions;
    let additionalChanges = {};
    if (treeLayout === "classic") {
      const selectedCardId = getRootCardId(fullState);
      const reaction = getOutputtingReactionOfCard(fullState, selectedCardId);

      additionalChanges = {
        selectedCardId,
        selectedReactionId: reaction.id
      };
    }
    return {
      ...state,
      viewOptions: {
        ...state.viewOptions,
        ...changedViewOptions
      },
      ...additionalChanges
    };
  },

  [actions.ui.designEditor.undo
    .setDesignFromUndoRedoStack]: clearSelectionReducer,

  [actions.designEdit.insertBin]: (state, { payload: { newBinId } }) => ({
    ...state,
    selectedCellPaths: [],
    selectedBinIds: [newBinId]
  }),
  [actions.ui.designEditor.general.toggleSbolRibbon]: state => ({
    ...state,
    sbolRibbonHidden: !state.sbolRibbonHidden
  }),
  [actions.ui.designEditor.general.moveActiveCell]: (
    state,
    { payload: direction },
    fullState
  ) => {
    if (!state.activeCellPath) return state;
    const newActiveCellPath = state.activeCellPath;
    let newActiveBinId = state.activeCellPath.binId;
    const newElementId = null;

    const numRows = getNumberOfRowsForCard(fullState, state.selectedCardId);
    if (direction === "up") {
      newActiveCellPath.index =
        newActiveCellPath.index === 0 ? 0 : newActiveCellPath.index - 1;
    } else if (direction === "down") {
      newActiveCellPath.index =
        newActiveCellPath.index === numRows - 1
          ? numRows - 1
          : newActiveCellPath.index + 1;
    } else if (direction === "left") {
      newActiveBinId = getNeighborBinId(
        fullState,
        state.selectedCardId,
        state.activeBinId || state.selectedBinIds[0],
        false,
        false
      );
    } else if (direction === "right") {
      newActiveBinId = getNeighborBinId(
        fullState,
        state.selectedCardId,
        state.activeBinId || state.selectedBinIds[0],
        true,
        false
      );
    }
    if (!newActiveBinId) return state;

    newActiveCellPath.binId = newActiveBinId;
    newActiveCellPath.elementId = newElementId;

    const elementsInBin = getElementsInBin(fullState, newActiveBinId);
    newActiveCellPath.elementId = get(
      elementsInBin.find(el => el.index === newActiveCellPath.index),
      "id"
    );

    return {
      ...state,
      activeCellPath: newActiveCellPath,
      activeBinId: newActiveBinId,
      selectedCellPaths: [newActiveCellPath],
      selectedBinIds: [newActiveBinId]
    };
  },
  [actions.ui.designEditor.general.updateSelectionAfterDesignEdit]: (
    state,
    { payload: newState }
  ) => {
    const newDesignState = getDesignState(newState);

    const transformItemId = (type, itemId) => {
      if (!itemId) return itemId;
      if (!newDesignState[type][itemId]) return null;
      return itemId;
    };

    const selectedCardId = transformItemId("card", state.selectedCardId);
    const selectedBinIds = selectedCardId
      ? state.selectedBinIds
          .map(binId => transformItemId("bin", binId))
          .filter(binId =>
            getAllSetIdsInCard(newDesignState, selectedCardId).includes(binId)
          )
          .filter(x => x)
      : [];

    const transformCell = cell => {
      if (!selectedCardId) return null;
      if (!cell) return cell;
      const binId = transformItemId("bin", cell.binId);
      if (!selectedBinIds.includes(binId)) return null;
      const numRows = getNumberOfRowsForCard(newState, selectedCardId);
      if (cell.index >= numRows) return null;
      const elements = getElementsInBin(newState, binId);
      return {
        binId,
        index: cell.index,
        elementId: get(
          elements.find(el => el.index === cell.index),
          "id",
          null
        )
      };
    };

    return {
      ...state,
      selectedCardId,
      selectedBinIds,
      selectedCellPaths: state.selectedCellPaths
        .map(p => transformCell(p))
        .filter(x => x),
      selectedReactionId: transformItemId("reaction", state.selectedReactionId),
      activeBinId: selectedBinIds.includes(
        transformItemId("bin", state.activeBinId)
      )
        ? transformItemId("bin", state.activeBinId)
        : null,
      activeCellPath: transformCell(state.activeCellPath),
      selectedValidatedJunctionId: transformItemId(
        "junction",
        state.selectedValidatedJunctionId
      )
    };
  },
  [actions.ui.designEditor.general.copyCells]: handleCopyCells,
  [actions.designEdit.cutCells]: handleCopyCells
};

function translateCopiedCells(copiedCells) {
  const minRow = min(copiedCells.map(c => c.rowIndex));
  const minCol = min(copiedCells.map(c => c.columnIndex));

  return copiedCells.map(c => ({
    ...c,
    rowIndex: c.rowIndex - minRow,
    columnIndex: c.columnIndex - minCol
  }));
}

function handleCopyCells(state, __, fullState) {
  const selectedCardId = getSelectedCardId(fullState);
  const selectedCells = getSelectedCellPaths(fullState);

  const binIds = getBinIdsInCard(fullState, selectedCardId);

  const copiedCells = selectedCells.map(c => ({
    rowIndex: c.index,
    columnIndex: binIds.findIndex(binId => binId === c.binId),
    element: c.elementId
      ? getItemOfType(fullState, "element", c.elementId)
      : null
  }));

  return {
    ...state,
    copiedCells: translateCopiedCells(copiedCells)
  };
}

function clearSelectionReducer(state, __, fullState) {
  const newState = { ...state, ...emptySelection };
  if (isViewClassic(fullState)) {
    Object.assign(newState, {
      selectedCardId: state.selectedCardId,
      selectedReactionId: state.selectedReactionId
    });
  }
  return newState;
}

const createClearSelectionReducers = () =>
  [actions.designIo.fetchFromServer].reduce((acc, action) => {
    acc[action] = clearSelectionReducer;
    return acc;
  }, {});

export default basicHandleActionsWithFullState(
  { ...createClearSelectionReducers(), ...basicActions },
  initialState
);
