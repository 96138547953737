/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment ruleSetFragment on ruleSet {
    id
    name
    description
    rules {
      id
      name
      regex
      type
      tagRules {
        id
        existence
        tagOption {
          id
          name
          color
        }
        tag {
          id
          name
          color
        }
      }
    }
  }
`;
