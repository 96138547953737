/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext, useEffect } from "react";
import { isEqual } from "lodash";
import { Button } from "@blueprintjs/core";
import { branch } from "recompose";
import { DataTable, TableFormTrackerContext } from "@teselagen/ui";
import usePrevious from "../usePrevious";
import { useTgQuery } from "../apolloMethods";

const PostSelectTableInner = ({
  buttonProps = {},
  changeGenericSelectValue,
  clearTempValue,
  entities,
  genericSelectValue,
  isMultiSelect,
  initialEntities,
  loading,
  noRemoveButton,
  readableName,
  postSelectFormName,
  postSelectDTProps,
  removeSelection,
  removeEntityFromSelection,
  withSelectedTitle
}) => {
  const prevEntities = usePrevious(entities);

  useEffect(() => {
    if (!entities) return;
    const hasValue = isMultiSelect
      ? genericSelectValue && genericSelectValue.length
      : genericSelectValue;

    const prevEntitiesEqual = isEqual(prevEntities, entities);
    if (
      (!prevEntitiesEqual || !hasValue) &&
      entities.length &&
      initialEntities.length
    ) {
      setTimeout(() => {
        // we need to clear the tempValue only after the generic select redux form value has been set, otherwise there is a race condition between tempValue and input.value
        clearTempValue();
      }, 0);
      const toSelect = isMultiSelect ? entities : entities[0];
      changeGenericSelectValue(toSelect);
    }
  }, [
    entities,
    isMultiSelect,
    genericSelectValue,
    initialEntities.length,
    changeGenericSelectValue,
    clearTempValue,
    prevEntities
  ]);

  const removeRecord = record => {
    if (isMultiSelect) {
      removeEntityFromSelection(record);
    } else {
      removeSelection();
    }
  };

  const removeColumn = {
    width: 50,
    noEllipsis: true,
    immovable: true,
    type: "action",
    render: (v, record) => {
      return (
        <Button
          small
          minimal
          onClick={e => {
            e.stopPropagation();
            removeRecord(record);
          }}
          icon="trash"
          intent="danger"
        />
      );
    }
  };

  let schemaToUse = postSelectDTProps.schema || [];
  if (!noRemoveButton && !buttonProps.disabled) {
    if (Array.isArray(schemaToUse)) {
      schemaToUse = [...schemaToUse, removeColumn];
    } else {
      schemaToUse = {
        ...schemaToUse,
        fields: [...schemaToUse.fields, removeColumn]
      };
    }
  }
  const formTracker = useContext(TableFormTrackerContext);

  return (
    <div className="postSelectDataTable" style={{ paddingTop: 10 }}>
      {withSelectedTitle && <h6>Selected {readableName}:</h6>}
      <DataTable
        formName={postSelectFormName}
        genericSelectValue={genericSelectValue}
        doNotShowEmptyRows
        maxHeight={400}
        destroyOnUnmount={!formTracker.isActive}
        keepDirtyOnReinitialize
        enableReinitialize
        updateUnregisteredFields
        isSimple
        noSelect={!postSelectDTProps.isSingleSelect}
        {...postSelectDTProps}
        schema={schemaToUse}
        isLoading={loading}
        entities={entities || initialEntities}
      />
    </div>
  );
};

const PostSelectTable = branch(
  ({ additionalDataFragment }) => !!additionalDataFragment,
  WrappedComponent =>
    ({
      additionalDataFragment,
      buttonProps = {},
      changeGenericSelectValue,
      clearTempValue,
      genericSelectValue,
      idAs,
      isMultiSelect,
      initialEntities,
      noRemoveButton,
      readableName,
      postSelectFormName,
      postSelectDTProps,
      removeSelection,
      removeEntityFromSelection,
      withSelectedTitle
    }) => {
      const filter = {
        [idAs]: isMultiSelect
          ? initialEntities.map(e => e[idAs])
          : initialEntities[0][idAs]
      };
      const { entities = [], loading } = useTgQuery(additionalDataFragment, {
        isPlural: true,
        variables: {
          pageSize: initialEntities.length,
          filter
        }
      });
      return (
        <WrappedComponent
          buttonProps={buttonProps}
          changeGenericSelectValue={changeGenericSelectValue}
          clearTempValue={clearTempValue}
          entities={entities}
          genericSelectValue={genericSelectValue}
          isMultiSelect={isMultiSelect}
          initialEntities={initialEntities}
          loading={loading}
          noRemoveButton={noRemoveButton}
          readableName={readableName}
          postSelectFormName={postSelectFormName}
          postSelectDTProps={postSelectDTProps}
          removeSelection={removeSelection}
          removeEntityFromSelection={removeEntityFromSelection}
          withSelectedTitle={withSelectedTitle}
        />
      );
    }
)(PostSelectTableInner);

export { PostSelectTable };
