/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import gql from "graphql-tag";
import { MenuItem } from "@blueprintjs/core";
import { exportReactionMaps } from "../../../utils/reactionMapUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import UploadReactionMapsDialog from "./UploadReactionMapsDialog";
import ReactionMapRecordView from "../../Record/ReactionMapRecordView";
import { reportLinks } from "../../../../src-shared/SharedJ5ReportRecordView/utils";
import { linkedWorkflowsColumn } from "../../../../src-shared/utils/libraryColumns";

class ReactionMapsLibrary extends Component {
  render() {
    return (
      <AbstractLibrary
        {...this.props}
        RecordViewInspector={ReactionMapRecordView}
        generateExtraContextMenuItems={records => {
          return [
            <MenuItem
              key="exportRM"
              text="Export"
              icon="export"
              onClick={() => {
                exportReactionMaps(records.map(r => r.id));
              }}
            />
          ];
        }}
        withExport
        isLibraryTable
        noNewItem
        showUploadFunction={() => {
          showDialog({
            ModalComponent: UploadReactionMapsDialog,
            modalProps: {
              refetch: this.props.refetch
            }
          });
        }}
        model="reactionMap"
        libraryName="reaction-map"
      />
    );
  }
}

const schema = {
  model: "reactionMap",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "reactionType.name",
      type: "string",
      displayName: "Reaction Type"
    },
    {
      displayName: "Parent Reaction Maps",
      render: (v, r) => {
        const parentReactionMaps = r.reactionMapSubsetReactionMapSubsetJoins.map(
          j => j.parentReactionMap
        );
        return reportLinks(parentReactionMaps);
      }
    },
    linkedWorkflowsColumn("reactionMapWorkflowView"),
    {
      path: "dataSet.name",
      type: "string",
      displayName: "Data Set"
    }
  ]
};

const fragment = gql`
  fragment reactionMapLibraryFragment on reactionMap {
    id
    name
    reactionType {
      code
      name
    }
    reactionMapSubsetReactionMapSubsetJoins {
      id
      parentReactionMap {
        id
        name
      }
    }
    dataSet {
      id
      name
    }
    reactionMapWorkflowView {
      id
      workflowIds
      workflowNames
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(ReactionMapsLibrary);
