/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";

import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";
import { compose } from "recompose";

class AddUnmappedElementDialog extends Component {
  onSubmit = async values => {
    const { onSubmit, hideModal } = this.props;
    await onSubmit(values);
    hideModal();
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField label="Name" name="name" />
        </div>
        <DialogFooter submitting={submitting} hideModal={hideModal} />
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = "Required";
  return errors;
};

export default compose(
  wrapDialog({
    title: "Add Unmapped Part"
  }),
  reduxForm({
    form: "addUnmappedElement",
    validate
  })
)(AddUnmappedElementDialog);
