/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { DataTable, InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";

const reactionInfoSchema = {
  fields: [
    { displayName: "Reaction", path: "name" },
    { displayName: "Input Material", path: "inputMaterial.name" },
    { displayName: "Output Material", path: "outputMaterial.name" }
  ]
};
class ReviewMaterials extends Component {
  render() {
    const { reactions = [], Footer, footerProps } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Review Plasmid Purification Reactions"
            helper="Review the purification reaction info below."
          />
          <DataTable
            isSimple
            noForm
            schema={reactionInfoSchema}
            entities={reactions}
            noSelect
          />
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Name Reaction Map"
            helper="Provide a name for the output protein purification reaction map"
          />
          <InputField
            style={{ width: 700 }}
            name="reactionMapName"
            label="Reaction Map Name"
            defaultValue="Plasmid Purification Reaction Map"
          />
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              loading={footerProps.submitting}
            >
              Generate Reaction Map
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues("reactions")(ReviewMaterials);
