/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { takeEvery, select, call, put, fork } from "redux-saga/effects";
import actions from "../../actions";
import { getDesignState } from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { getLastSavedState } from "../../../selectors/miscDesignSelectors";
import performChange from "./performChange";
import { transformWithNewIds } from "../../../../../tg-iso-design/utils/designStateUtils";

const getIsSaving = state => state.ui.designEditor.general.isSaving;

let needsToSaveAgain = false;

export function* handleSaveDesign() {
  try {
    const isSaving = yield select(getIsSaving);
    if (isSaving) {
      needsToSaveAgain = true;
      return;
    }

    yield put(actions.designIo.saveStarted());

    const designState = yield select(getDesignState);
    const lastSavedState = yield select(getLastSavedState);

    const { oldIdToNewIdMap, validationDiff } = yield call(
      performChange,
      lastSavedState,
      designState
    );

    yield put(
      actions.designIo.saveCompleted({ oldIdToNewIdMap, validationDiff })
    );

    const newDesignState = transformWithNewIds(designState, oldIdToNewIdMap);
    yield put(actions.designIo.setLastSavedState(newDesignState));
  } catch (e) {
    console.error("error 0910980198", e);
    window.toastr.error(
      "Error Saving." +
        (e.type === "INVALID_EDIT_LOCK" || e.type === "MISSING_EDIT_LOCK"
          ? " Your edit lock expired due to inactivity"
          : ""),
      {
        action: {
          text: "Reload?",
          onClick: () => window.location.reload()
        },
        timeout: 999999999
      }
    );
    yield put(actions.designIo.saveFailed());
  }
  if (needsToSaveAgain) {
    yield fork(handleSaveDesign);
    needsToSaveAgain = false;
  }
}

export default function* watchSaveDesign() {
  yield takeEvery(actions.designIo.save, handleSaveDesign);
}
