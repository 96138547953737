/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment plateMapGroupRegistrationFragment on plateMapGroup {
    id
    name
    plateMaps {
      id
      name
      type
      plateMapItems {
        id
        name
        volume
        volumetricUnitCode
        rowPosition
        columnPosition
        inventoryItem {
          id
          additiveMaterialId
          additiveMaterial {
            id
            name
            isDry
          }
          lotId
          lot {
            id
            name
          }
          sampleId
          sample {
            id
            name
          }
          materialId
          material {
            id
            name
            polynucleotideMaterialSequence {
              id
              molecularWeight
            }
          }
        }
      }
    }
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
`;
