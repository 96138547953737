/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { RadioGroupField } from "@teselagen/ui";
import { get, isEmpty } from "lodash";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { validateMaterialPlates } from "../../../../utils/plateUtils";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import { Callout } from "@blueprintjs/core";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import {
  plasmidPrepPlateFragment,
  plasmidPrepTubeFragment
} from "../fragments";

class SelectInputs extends Component {
  renderAliquotVolumeWarning() {
    const {
      aliquotContainers = [],
      containerArrays = [],
      choosingPlates
    } = this.props;
    const warningMessage = `Some input ${
      choosingPlates === "PLATES" ? "plates" : "tubes"
    } contain aliquots with no volume. These aliquots will not be present on destination plates or tubes.`;
    const errorMessage = `All input ${
      choosingPlates === "PLATES" ? "plates" : "tubes"
    } contain aliquots with no volume. Please select at least one tube with volume.`;
    let hasNoVolumeAliquots = false;
    let allNoVolumeAliquots = false;
    if (choosingPlates === "PLATES") {
      // input plates
      hasNoVolumeAliquots = containerArrays.some(containerArray => {
        return containerArray.aliquotContainers.some(ac => {
          return !!ac.aliquot && get(ac, "aliquot.volume") <= 0;
        });
      });
    } else {
      // input tubes
      allNoVolumeAliquots =
        aliquotContainers.length > 0 &&
        aliquotContainers.every(ac => {
          return ac.aliquot && get(ac, "aliquot.volume") <= 0;
        });
      hasNoVolumeAliquots = aliquotContainers.some(ac => {
        return ac.aliquot && get(ac, "aliquot.volume") <= 0;
      });
    }
    if (allNoVolumeAliquots) {
      return (
        <Callout style={{ marginBottom: 10 }} intent="danger">
          {errorMessage}
        </Callout>
      );
    } else if (hasNoVolumeAliquots) {
      return (
        <Callout style={{ marginBottom: 10 }} intent="warning">
          {warningMessage}
        </Callout>
      );
    }
  }

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      Footer,
      footerProps,
      containerArrays = [],
      aliquotContainers = [],
      choosingPlates
    } = this.props;

    const allNoVolumeAliquots =
      aliquotContainers.length > 0 &&
      aliquotContainers.every(ac => {
        return ac.aliquot && get(ac, "aliquot.volume") <= 0;
      });

    let plateErrors;
    if (choosingPlates === "PLATES" && containerArrays.length) {
      plateErrors = validateMaterialPlates(containerArrays, {
        materialTypeCode: "MICROBIAL",
        enforceSequenceLinks: false,
        asObject: true
      });
    }

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Plates or Tubes"
              helper="Specify the input type, then choose corresponding containers of microbial materials with DNA plasmids."
            />
            <RadioGroupField
              name="choosingPlates"
              label="Specify Input Type"
              options={[
                { label: "Plates", value: "PLATES" },
                { label: "Tubes", value: "TUBES" }
              ]}
              defaultValue="PLATES"
            />
          </div>
          <div className="width100 column">
            {choosingPlates === "PLATES" ? (
              <GenericSelect
                {...{
                  name: "containerArrays",
                  isMultiSelect: true,
                  isRequired: true,
                  buttonProps: {
                    disabled: isDisabledMap.containerArrays,
                    loading: isLoadingMap.containerArrays
                  },
                  schema: [
                    "name",
                    { displayName: "Barcode", path: "barcode.barcodeString" },
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    },
                    dateModifiedColumn
                  ],
                  fragment: [
                    "containerArray",
                    "id name containerArrayType { id name } barcode { id barcodeString } createdAt updatedAt"
                  ],
                  additionalDataFragment: plasmidPrepPlateFragment,
                  postSelectDTProps: {
                    formName: "selectedPlates",
                    plateErrors,
                    schema: [
                      platePreviewColumn({
                        plateErrors
                      }),
                      "name",
                      { displayName: "barcode", path: "barcode.barcodeString" },
                      {
                        path: "containerArrayType.name",
                        displayName: "Plate Type"
                      },
                      dateModifiedColumn
                    ]
                  }
                }}
              />
            ) : (
              <GenericSelect
                {...{
                  name: "aliquotContainers",
                  isMultiSelect: true,
                  buttonProps: {
                    loading: isLoadingMap.aliquotContainers,
                    disabled: isDisabledMap.aliquotContainers
                  },
                  tableParamOptions: {
                    additionalFilter: additionalFilterForTubes
                  },
                  schema: [
                    "name",
                    {
                      displayName: "Tube Type",
                      path: "aliquotContainerType.name"
                    },
                    dateModifiedColumn
                  ],
                  fragment: [
                    "aliquotContainer",
                    "id name aliquotContainerType { code name } updatedAt"
                  ],
                  additionalDataFragment: plasmidPrepTubeFragment,
                  postSelectDTProps: {
                    formName: "selectedAliquotContainers",
                    isSimple: true,
                    isSingleSelect: true,
                    destroyOnUnmount: false,
                    keepDirtyOnReinitialize: true,
                    enableReinitialize: true,
                    updateUnregisteredFields: true,
                    schema: [
                      { displayName: "Name", path: "name" },
                      {
                        displayName: "Material",
                        path: "aliquot.sample.material.name"
                      },
                      {
                        displayName: "Tube Type",
                        path: "aliquotContainerType.name"
                      },
                      dateModifiedColumn
                    ]
                  }
                }}
              />
            )}
          </div>
          {this.renderAliquotVolumeWarning()}
        </div>
        <Footer
          {...footerProps}
          nextDisabled={allNoVolumeAliquots || !isEmpty(plateErrors)}
          errorMessage={!isEmpty(plateErrors) && "Please review plate errors"}
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "containerArrays",
  "aliquotContainers",
  "choosingPlates"
)(SelectInputs);

const additionalFilterForTubes = (props, qb) => {
  qb.whereAll({ containerArrayId: qb.isNull() });
};
