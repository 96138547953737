/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import { keyBy } from "lodash";
import shortid from "shortid";
import { bottomField } from "../../utils/plateUtils";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";
import caseInsensitiveFilter from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { volumeAndDeadVolumeFields } from "../../../../tg-iso-shared/src/utils/unitCodeFields";
import { validateNameCsvTableUniqueAsync } from "../../components/AppSettings/validateNameCsvTableUniqueAsync";
import { validateDeadVolume } from "../../../src-shared/utils/fieldUtils";

class UploadTubeTypes extends Component {
  onSubmit = async values => {
    try {
      const { refetch, hideModal } = this.props;
      const { parsedData } = values.tubeTypesFile[0];

      const existingTubeTypes = await safeQuery(
        ["aliquotContainerType", "code name"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "aliquotContainerType",
              "name",
              parsedData.map(row => row.name)
            )
          }
        }
      );
      const newTubeTypes = [];
      const keyedExisting = keyBy(existingTubeTypes, t => t.name.toLowerCase());
      for (const [index, row] of parsedData.entries()) {
        const {
          name,
          description,
          maxVolume,
          volumetricUnitCode,
          deadVolume,
          deadVolumetricUnitCode,
          bottom
        } = row;
        if (keyedExisting[name.toLowerCase()]) {
          throw new Error(
            `Row ${index + 1} specifies the name ${name} which already exists`
          );
        }

        newTubeTypes.push({
          code: shortid(),
          name,
          description,
          maxVolume,
          volumetricUnitCode,
          bottom,
          isTube: true,
          deadVolume: deadVolume || null,
          deadVolumetricUnitCode: deadVolumetricUnitCode || null
        });
      }
      await safeUpsert("aliquotContainerType", newTubeTypes, {
        idAs: "code",
        forceCreate: true
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading tube types.`);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            isRequired
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              fileName: "tubeTypes",
              validateAgainstSchema: {
                ...validateNameCsvTableUniqueAsync({
                  model: "aliquotContainerType",
                  isCodeModel: true
                }),
                ...validateDeadVolume,
                fields: [
                  {
                    path: "name",
                    description: "The name of the tube type",
                    example: "Tube Type A",
                    isUnique: true,
                    isRequired: true
                  },
                  {
                    path: "description",
                    description: "The description of the tube type",
                    example: "Plastic tube with red cap"
                  },
                  ...volumeAndDeadVolumeFields({ type: "tube" }),
                  bottomField
                ]
              }
            })}
            name="tubeTypesFile"
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Upload Tube Types"
  }),
  reduxForm({
    form: "uploadTubeTypes"
  })
)(UploadTubeTypes);
