/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// import { combineReducers } from 'redux'
import inspector from "./inspector";
import general from "./general";
import tree from "./tree";
import undo from "./undo";
import combineReducersWithFullState from "../../combineReducersWithFullState";

export default (...args) => {
  return combineReducersWithFullState({
    general,
    inspector,
    tree,
    undo
  })(...args);
};
