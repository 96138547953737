/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const assemblyPieceViewFragment = gql`
  fragment assemblyPieceViewFragment on assemblyPieceView {
    id
    name
    type
    relatedConstructs
    containedParts
    containerCount
  }
`;

export const constructSelectionConstructFragment = gql`
  fragment constructSelectionConstructFragment on j5RunConstructView {
    id
    name
    size
    containerCount
  }
`;

export default gql`
  fragment constructSelectionJ5ReportFragment on j5Report {
    id
    name
    assemblyMethod
    isHierarchical
    assemblyBatchId
    outputCardName
    treePosition
    assemblyType
    updatedAt
    j5RunConstructs {
      id
      name
      sequence {
        id
        polynucleotideMaterialId
      }
      j5ConstructAssemblyPieces {
        id
        assemblyPieceId
        assemblyPiece {
          id
          type
          j5AssemblyPieceParts {
            id
            j5InputPart {
              id
              j5InputSequence {
                id
                sequence {
                  id
                  polynucleotideMaterial {
                    id
                    name
                  }
                }
              }
            }
          }
          assemblyPieceView {
            id
            containerCount
          }
          sequence {
            id
            name
            sequenceTypeCode
            sequenceFragments {
              id
              fragment
            }
            polynucleotideMaterialId
            polynucleotideMaterial {
              id
              name
            }
          }
        }
      }
    }
  }
`;
