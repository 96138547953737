/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { download } from "../../src-shared/utils/downloadTest";

export default async function downloadServerFile(file) {
  try {
    const { originalname, mimetype, url, path } = file;
    const { data } = await window.serverApi.request({
      method: "GET",
      url: "/" + (url || path),
      withCredentials: true,
      timeout: 60000,
      responseType: "arraybuffer"
    });
    const blob = new Blob([new Uint8Array(data)]);
    download(blob, originalname, mimetype);
  } catch (e) {
    console.error(e);
    window.toastr.error("Error downloading file.");
  }
}
