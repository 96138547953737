/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { collectMatchingReactionInfo } from ".";

import { isoContext } from "@teselagen/utils";
import {
  getKeyMaterialsFromAliquotContainer,
  getKeyReagentsFromAliquotContainer
} from "../utils/plateUtils";
import executeReactions from "./executeReactions";
import {
  aliquotContainerReactionMapFragmentBasic,
  executeReactionsFragment
} from "./fragments";

export default async function queryAndExecuteReactionMap(
  input,
  ctx = isoContext
) {
  const {
    reactionMapIds = [],
    containerArrayIds = [],
    aliquotContainerIds = []
  } = input;
  const { safeQuery } = ctx;

  if (!reactionMapIds.length) {
    throw new Error("No reactionMapId passed!");
  }
  if (!containerArrayIds.length && !aliquotContainerIds.length) {
    throw new Error("No plates or tubes passed!");
  }
  const qb = new QueryBuilder("aliquotContainer");
  if (containerArrayIds.length) {
    qb.whereAll({
      containerArrayId: containerArrayIds
    });
  }
  if (aliquotContainerIds.length) {
    qb[containerArrayIds.length ? "orWhereAll" : "whereAll"]({
      id: aliquotContainerIds
    });
  }
  const filter = qb.toJSON();
  const aliquotContainers = await safeQuery(
    aliquotContainerReactionMapFragmentBasic,
    {
      variables: {
        filter
      }
    }
  );
  const reactions = await safeQuery(executeReactionsFragment, {
    variables: {
      filter: {
        reactionMapId: reactionMapIds
      }
    }
  });
  const reactionDestinationWellLocationsTable = [];
  aliquotContainers.forEach(ac => {
    const keyedMaterials = getKeyMaterialsFromAliquotContainer(ac);
    const keyedReagents = getKeyReagentsFromAliquotContainer(ac);
    if (!isEmpty(keyedMaterials) || !isEmpty(keyedReagents)) {
      reactions.forEach(reaction => {
        const reactionInfo = collectMatchingReactionInfo(
          reaction,
          keyedMaterials,
          keyedReagents,
          reactionDestinationWellLocationsTable,
          ac.id
        );
        if (reactionInfo) {
          reactionDestinationWellLocationsTable.push(reactionInfo);
        }
      });
    }
  });
  if (!reactionDestinationWellLocationsTable.length) {
    throw new Error("No reactions to execute.");
  }
  await executeReactions(reactionDestinationWellLocationsTable, ctx);
}
