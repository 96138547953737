/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Card, Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { reduxForm, Field } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import AddTransferForm from "./AddTransferForm";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import withQuery from "../../../../src-shared/withQuery";
import PartialFormulation from "../../../../../tg-iso-lims/src/AliquotFormulation/PartialFormulation";
import executeAliquotFormulations from "../../../../../tg-iso-lims/src/AliquotFormulation/executeAliquotFormulations";

import "./style.css";
import UnitInputField from "../../UnitInputField";
import { defaultConcentrationUnitCode } from "../../../../../tg-iso-lims/src/utils/unitUtils";
import {
  concentrationRender,
  volumeRender
} from "../../../../src-shared/utils/unitUtils";
import { renderMaterialsField } from "../../../utils/plateUtils";
import RecordLink from "../../RecordLink";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import gql from "graphql-tag";

class FormulateNewSampleFromAliquotDialog extends Component {
  onSubmit = async values => {
    try {
      const { originalAliquot, hideModal, transfers, history } = this.props;

      const {
        newSampleName,
        newConcentration,
        newConcentrationUnitCode
      } = values;

      const aliquotFormulation = new PartialFormulation({
        aliquotId: originalAliquot.id,
        sampleName: newSampleName,
        concentration: newConcentration,
        concentrationUnitCode: newConcentrationUnitCode
      });

      transfers.forEach(transfer => {
        aliquotFormulation.addTransfer(
          transfer.aliquotToTransfer.id,
          transfer.transferAliquotVolume,
          transfer.transferVolumetricUnitCode
        );
      });

      await executeAliquotFormulations(aliquotFormulation);

      const updatedAliquot = await safeQuery(
        ["aliquot", "id sample { id name }"],
        {
          variables: {
            id: originalAliquot.id
          }
        }
      );
      history.push(modelNameToLink(updatedAliquot.sample));
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error formulating sample.");
    }
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      originalAliquot,
      submitting,
      transfers = []
    } = this.props;
    if (!originalAliquot) {
      return "No aliquot";
    }
    const recordInfo = [
      ["Material", renderMaterialsField(originalAliquot)],
      ["Sample", <RecordLink key="sample" record={originalAliquot.sample} />],
      ["Aliquot Type", originalAliquot.aliquotType],
      ["Volume", volumeRender(originalAliquot)],
      ["Concentration", concentrationRender(originalAliquot)]
    ];

    return (
      <div className="formulate-new-sample-from-aliquot-dialog">
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            <Card>
              <h5>Source Aliquot</h5>
              <RecordInfoDisplay recordInfo={recordInfo} />
            </Card>
            <Field
              {...{
                originalAliquot,
                transfers
              }}
              name="transfers"
              component={AddTransferForm}
            />
            <Card>
              <h5>New Sample</h5>
              <InputField
                name="newSampleName"
                label="Name"
                placeholder="Enter a name for the new sample..."
              />
              <UnitInputField
                name="newConcentration"
                label="New Concentration"
                unitName="newConcentrationUnitCode"
                unitType="concentrationUnit"
                unitDefault={defaultConcentrationUnitCode}
              />
            </Card>
            <div style={{ marginBottom: 15 }} />
            <DialogFooter hideModal={hideModal} submitting={submitting} />
          </div>
        </form>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  const requiredGeneralProperties = ["newSampleName", "transfers"];
  requiredGeneralProperties.forEach(property => {
    if (!values[property]) errors[property] = "Required";
  });

  if (!values.transfers || !values.transfers.length)
    errors.transfers = "You need at least one transfer.";
  return errors;
};

const formulateNewSampleFromAliquotFragment = gql`
  fragment formulateNewSampleFromAliquotFragment on aliquot {
    id
    isDry
    aliquotType
    sample {
      id
      name
      material {
        id
        name
      }
      sampleFormulations {
        id
        materialCompositions {
          id
          material {
            id
            name
          }
        }
      }
    }
    concentration
    concentrationUnitCode
    volume
    volumetricUnitCode
    mass
    massUnitCode
    aliquotContainer {
      id
      aliquotContainerType {
        code
        maxVolume
        volumetricUnitCode
      }
    }
  }
`;

export default compose(
  wrapDialog({
    title: "Formulate New Sample",
    style: { width: 660 }
  }),
  withQuery(formulateNewSampleFromAliquotFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.originalAliquot.id
        }
      };
    },
    props: props => {
      return {
        originalAliquot: props.aliquot
      };
    }
  }),
  reduxForm({
    enableReinitialize: true,
    form: "formulateNewSampleFromAliquotDialogForm",
    validate
  }),
  tgFormValues("transfers")
)(FormulateNewSampleFromAliquotDialog);
