/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import recordViewEnhancer from "../recordViewEnhancer";
import AbstractRecord from "../AbstractRecord";
import TgSequenceEditor, { sequenceRecordFragment } from "../TgSequenceEditor";
import modelNameToLink from "../utils/modelNameToLink";
import { getEnclosingFeatures } from "../utils/sequenceUtils";
import gql from "graphql-tag";
import { sizeInfo } from "../utils/libraryColumns";
import { pick } from "lodash";

class SequenceFeatureRecordView extends Component {
  state = {};

  render() {
    const { sequenceFeature } = this.props;
    const { name, type, sequence } = sequenceFeature;
    const enclosingFeatures = getEnclosingFeatures(sequenceFeature, sequence);
    const recordInfo = [
      ["Name", name],
      ["Type", type],
      sizeInfo(sequenceFeature),
      [
        "Enclosing Features",
        enclosingFeatures.map((f, i) => (
          <React.Fragment key={f.id}>
            <Link to={modelNameToLink(f.__typename, f.id)}>{f.name}</Link>
            {i < enclosingFeatures.length - 1 ? ", " : ""}
          </React.Fragment>
        ))
      ],
      [
        "Source Sequence",
        <Link
          key={sequence.id}
          to={modelNameToLink(sequence.__typename, sequence.id)}
        >
          {sequence.name}
        </Link>
      ]
    ];

    return (
      <AbstractRecord
        {...this.props}
        recordInfo={recordInfo}
        recordName="sequenceFeature"
      >
        <TgSequenceEditor
          {...this.props}
          sequence={sequenceFeature.sequence}
          onPreviewModeFullscreenClose={() => {
            this.props.refetchRecord();
          }}
          hoveredId={sequenceFeature.id}
          editorOpts={{
            selectionLayer: pick(sequenceFeature, ["start", "end"])
          }}
        />
      </AbstractRecord>
    );
  }
}

const fragment = gql`
  fragment sequenceFeatureRecordFragment on sequenceFeature {
    id
    name
    type
    strand
    start
    end
    sequence {
      ...sequenceRecordFragment
    }
  }
  ${sequenceRecordFragment}
`;

export default recordViewEnhancer(fragment)(SequenceFeatureRecordView);
