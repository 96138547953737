/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import pluralize from "pluralize";
import { map } from "lodash";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { itemTypes } from "./constants";
import { inventoryItemTypeMap } from "../../../../../tg-iso-shared/src/utils/inventoryUtils";
import { getPositionFromAlphanumericLocation } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { identity } from "lodash";

export function canDistributeTemperatureBlocks(values) {
  const { reactionEntityType, itemType, reactionMaps = [] } = values;
  return (
    itemType === "reactionMap" &&
    reactionEntityType === "Output Materials" &&
    reactionMaps.every(rm => rm.reactionTypeCode === "PCR_REACTION")
  );
}

export function getSelectedItems(values) {
  if (!values.itemType) return [];
  let selectedItems;
  if (values.itemType === "j5Report") {
    if (!values.breakIntoQuadrants) {
      if (values.j5EntityRadio === "j5PcrReaction") {
        selectedItems = values.j5PcrReactions;
      } else {
        selectedItems = values.j5Materials;
      }
    }
  } else if (values.itemType === "Inventory List") {
    selectedItems = values.samples;
  } else if (values.itemType === "reactionMap") {
    if (values.reactionEntityType) {
      if (values.reactionEntityType.includes("Material")) {
        selectedItems = values.materials;
      } else {
        selectedItems = values.additiveMaterials;
      }
    }
  } else if (
    values.itemType === "containerArray" &&
    values.plateWellContentType
  ) {
    selectedItems = values[pluralize(values.plateWellContentType)];
  } else {
    selectedItems = values[pluralize(values.itemType)] || [];
  }
  return selectedItems || [];
}

export function generatePlateMapGroup(values) {
  const {
    itemType,
    plateWellContentType,
    plateMapType,
    j5EntityRadio,
    plateMapGroupName = "Plate Map",
    platesToCreate: _platesToCreate,
    selectedContainerFormat,
    addFakeIds,
    uploadingVolumeInfo,
    reactionEntityType
  } = values;
  let id = 1;

  const platesToCreate = _platesToCreate.map((plate, i) => {
    //this plate comes in looking like: {"A4": someMaterial, B5: someOtherMaterial}
    let type;
    if (itemType === "j5Report") {
      if (j5EntityRadio === "j5PcrReaction") {
        type = "j5PcrReaction";
      } else {
        type = "material";
      }
    } else if (itemType === "plateMap") {
      type = plateMapType;
    } else if (itemType === "reactionMap") {
      if (reactionEntityType.includes("Material")) {
        type = "material";
      } else {
        type = "additiveMaterial";
      }
    } else if (itemType === "Inventory List") {
      type = "sample";
    } else if (itemType === "containerArray") {
      type = plateWellContentType;
    } else {
      type = itemType;
    }
    return {
      name: plateMapGroupName + ` ${i + 1}`,
      type,
      ...(plate.__tempInfo && {
        metadata: plate.__tempInfo
      }),
      plateMapItems: map(plate, (plateMapItemInfo, wellString) => {
        if (wellString.startsWith("__")) return;
        const { item, volume, volumetricUnitCode } = plateMapItemInfo;
        const { rowPosition, columnPosition } =
          getPositionFromAlphanumericLocation(wellString);

        const itemTypeField = {};

        if (item.__typename === "j5PcrReaction") {
          itemTypeField.j5Item = {
            j5ItemTypeCode: "J5_PCR_REACTION",
            [item.__typename + "Id"]: item.id
          };
        } else {
          itemTypeField.inventoryItem = {
            inventoryItemTypeCode: inventoryItemTypeMap[item.__typename],
            [item.__typename + "Id"]: item.id
          };
        }

        return {
          // the ids and tags are needed for the preview
          ...(addFakeIds && { id: id++, taggedItems: item.taggedItems }),
          ...(uploadingVolumeInfo && {
            volume,
            volumetricUnitCode
          }),
          rowPosition,
          name: item.name,
          columnPosition,
          plateMapItemTypeCode: "INVENTORY_ITEM",
          ...itemTypeField
        };
      }).filter(identity)
    };
  });

  return {
    name: plateMapGroupName,
    containerFormatCode: selectedContainerFormat.code,
    plateMaps: platesToCreate
  };
}

export const withItemProps = stepFormValues(
  ...itemTypes.map(model => pluralize(model))
);
