/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, forEach } from "lodash";
import Big from "big.js";
import {
  defaultMassUnitCode,
  getDimensionsOfMixture
} from "../../../utils/unitUtils";
import getAdditivesFromTransfer from "./getAdditivesFromTransfer";

/**
 * Given a dimension object, such as return value of `getDimensionsOfMixture`, process
 * it to fit the schema for dry and wet aliquots. This means that wet aliquots will
 * have a null `mass` field and dry aliquots will have a null `concentration` field.
 * @param {Object} dimensions A dimensions object. Has fields for mass, volume, concentration, and their unit codes.
 */
export function processDimensions(dimensions) {
  if (Number(new Big(dimensions.volume || 0))) {
    // If the aliquot is wet, then nullify the mass field.
    return {
      ...dimensions,
      volume: Number(dimensions.volume),
      concentration: dimensions.concentration
        ? Number(dimensions.concentration)
        : null,
      molarity: dimensions.molarity ? Number(dimensions.molarity) : null,
      mass: null,
      massUnitCode: null
    };
  } else {
    // For dry aliquots, ensure that the concentration field is null.
    return {
      ...dimensions,
      mass: Number(dimensions.mass) || 0,
      volume: Number(dimensions.volume),
      molarity: null,
      molarityUnitCode: null,
      concentration: null,
      concentrationUnitCode: null
    };
  }
}

export function getAllTransferAdditives(fullFormulation) {
  const additives = [];

  for (const t of fullFormulation.getTransfers()) {
    additives.push(...getAdditivesFromTransfer(t));
  }

  return additives;
}

/**
 * Return an array of all of the additives participating the formulation.
 * The volumes/masses of the additives will be adjusted to reflect the amount
 * that actually ends up in the destination.
 * @param {FullFormulation} fullFormulation
 * @returns {Array<Object>}
 */
export function getAllAdditives(fullFormulation) {
  const aliquot = fullFormulation.getAliquot();
  const container = fullFormulation.getAliquotContainer();

  const additives = [
    ...get(aliquot, "additives", []),
    ...get(container, "additives", []),
    ...getAllTransferAdditives(fullFormulation)
  ];

  return additives;
}

/**
 * Two additive materials with the same key can be combined. This
 * means that the have the same additive material and lot.
 * @param {Object} additive
 * @returns {string} additiveMaterialId:lotId
 */
export function getCombinabilityKey(additive) {
  return `${get(additive, "lot.additiveMaterialId") ||
    additive.additiveMaterialId}:${additive.lotId || ""}`;
}

/**
 * Attach the additives to the destination aliquot. The source aliquots will not be affected.
 *
 * If the destination aliquot container had any additives attached to it, these will get deleted. They will
 * reflected in the additives attached to the destination aliquot.
 * @param {FullFormulation} fullFormulation
 * @param {string} aliquotId The id of the destination aliquot.
 * @param {Object} options
 * @param {WriteBuffer} writeBuffer
 */
function addDestinationAdditives(
  fullFormulation,
  aliquotId,
  options,
  writeBuffer
) {
  const { userId } = options;
  const { upsert, del } = writeBuffer;
  // A map from the additive material id and lot id to an array of the additives of with the
  // samer additive material and lot that end up in the destination. The volumes/masses of the
  // additives are adjusted to represent the actual amount that ends up in the destination
  const idToAdditives = {};
  for (const a of getAllAdditives(fullFormulation)) {
    const key = getCombinabilityKey(a);
    if (!idToAdditives[key]) idToAdditives[key] = [];
    idToAdditives[key].push(a);
  }
  const aliquot = fullFormulation.getAliquot();
  const container = fullFormulation.getAliquotContainer();

  const additiveIdsToKeep = [];
  forEach(idToAdditives, (additives, combinalityKey) => {
    // The fields that we extract from this additive should be the same
    // for all of the other additives in the array
    // (because we are keying them by the additveMaterial and lot. see getCombinabilityKey)
    const additive = additives[0];

    // Figure out the volume, mass, and concentration of the additive.
    const dimensions = getDimensionsOfMixture({
      mixture: additives,
      concentrationUnitCode:
        // we want to use the destination additive concentration code if it exists
        additive.concentrationUnitCode ||
        fullFormulation.getConcentrationUnitCode(),
      volumetricUnitCode:
        additive.volumetricUnitCode || fullFormulation.getVolumetricUnitCode(),
      massUnitCode: defaultMassUnitCode
    });

    let existingAliquotAdditive, existingContainerAdditive;
    if (aliquot) {
      existingAliquotAdditive = aliquot.additives.find(
        a => getCombinabilityKey(a) === combinalityKey
      );
    } else {
      existingContainerAdditive = container.additives.find(
        a => getCombinabilityKey(a) === combinalityKey
      );
      if (existingContainerAdditive)
        additiveIdsToKeep.push(existingContainerAdditive.id);
    }

    let additiveMaterialId;
    if (!additive.lotId) {
      additiveMaterialId =
        additive.additiveMaterialId || get(additive, "lot.additiveMaterialId");
    }
    const additiveToUpdate = {
      ...processDimensions(dimensions),
      additiveMaterialId,
      lotId: additive.lotId,
      userId
    };

    if (aliquotId) {
      if (existingAliquotAdditive) {
        additiveToUpdate.id = existingAliquotAdditive.id;
      }
      additiveToUpdate.aliquotId = aliquotId;
    } else {
      if (existingContainerAdditive) {
        additiveToUpdate.id = existingContainerAdditive.id;
      }
      additiveToUpdate.aliquotContainerId = container.id;
    }
    upsert("additive", additiveToUpdate);
  });
  // Delete all of the additives attached to the destination aliquot container. They
  // are reflected in the previously upserted aliquots attached to the destination aliquot.
  if (container && fullFormulation.getAllSourceAliquots().length) {
    del(
      "additive",
      container.additives.reduce((acc, additive) => {
        if (!additiveIdsToKeep.includes(additive.id)) acc.push(additive.id);
        return acc;
      }, [])
    );
  }
}

export default addDestinationAdditives;
