/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Fragment } from "react";
import { Button, Intent, UL } from "@blueprintjs/core";

class J5LogMessagesView extends React.Component {
  constructor(props) {
    super(props);

    const { j5LogMessages } = this.props;

    const j5LogMsgState = j5LogMessages.reduce((acc, j5LogMessage) => {
      acc[j5LogMessage.message] = false;
      return acc;
    }, {});
    this.state = {
      highPriorityIsOpen: true,
      lowPriorityIsOpen: true,
      mediumPriorityIsOpen: true,
      ...j5LogMsgState
    };
  }
  /**
   * Get the additional class to apply to the j5 log message to
   * differentiate between types of messages in css.
   */
  getAdditionalMessageClass(logMessage) {
    if (logMessage.j5LogMessageTypeCode) {
      return "j5-log-" + logMessage.j5LogMessageTypeCode.toLowerCase();
    }
    return "j5-log-join";
  }

  /**
   * Render a log message.
   */
  renderMessage = ({ logMessage, msgIndex, isOnlyMsgInGroup }) => {
    return (
      <li
        className={
          "j5-log-message " + this.getAdditionalMessageClass(logMessage)
        }
        key={msgIndex}
      >
        {isOnlyMsgInGroup
          ? `${logMessage.message}${
              logMessage.j5LogMessageJoins &&
              logMessage.j5LogMessageJoins[0] &&
              logMessage.j5LogMessageJoins[0].specificMsg
                ? ": " + logMessage.j5LogMessageJoins[0].specificMsg
                : ""
            }`
          : logMessage.message || logMessage.specificMsg}
      </li>
    );
  };

  renderMessageGroup = messages => {
    const toReturn = [];
    messages.forEach((msg, msgIndex) => {
      if (msg.j5LogMessageJoins.filter(j => !!j.specificMsg).length <= 1) {
        toReturn.push(
          this.renderMessage({
            logMessage: msg,
            msgIndex,
            isOnlyMsgInGroup: true
          })
        );
      } else {
        toReturn.push(
          <Fragment key={msgIndex}>
            <Button
              onClick={() => this.handleGroupedButtonClick(msg.message)}
              intent={Intent.NONE}
              icon={this.state[msg.message] ? "caret-up" : "caret-down"}
            >
              {msg.message} ({msg.j5LogMessageJoins.length})
            </Button>
            {this.state[msg.message] && (
              <UL className="j5-logs-list" key={msg.message + msgIndex}>
                {msg.j5LogMessageJoins.map((logMessage, msgIndex) =>
                  this.renderMessage({ logMessage, msgIndex })
                )}
              </UL>
            )}
          </Fragment>
        );
      }
    });

    return toReturn;
  };

  toggleState = stateVar => {
    this.setState({
      [stateVar]: !this.state[stateVar]
    });
  };
  handleGroupedButtonClick = stateName => this.toggleState(stateName);
  handleHighPriorityClick = () => this.toggleState("highPriorityIsOpen");
  handleMediumPriorityClick = () => this.toggleState("mediumPriorityIsOpen");
  handleLowPriorityClick = () => this.toggleState("lowPriorityIsOpen");

  renderSection({ messages, title, intent, open, toggle }) {
    return (
      <div style={{ marginBottom: 20 }}>
        {messages.length ? (
          <Button
            onClick={toggle}
            intent={intent}
            icon={open ? "caret-up" : "caret-down"}
          >
            {title} ({messages.length})
          </Button>
        ) : (
          <div>No {title}</div>
        )}
        {open && (
          <UL className="j5-logs-list">{this.renderMessageGroup(messages)}</UL>
        )}
      </div>
    );
  }

  render() {
    const { j5LogMessages } = this.props;

    const lowPriorityMessages = [];
    const mediumPriorityMessages = [];
    const highPriorityMessages = [];

    j5LogMessages.forEach(msg => {
      if (msg.j5LogMessagePriorityCode === "LOW") {
        lowPriorityMessages.push(msg);
      } else if (msg.j5LogMessagePriorityCode === "HIGH") {
        highPriorityMessages.push(msg);
      } else {
        mediumPriorityMessages.push(msg);
      }
    });

    return (
      <div className="bp3-dialog-body j5-logs-dialog-body">
        {this.renderSection({
          messages: highPriorityMessages,
          toggle: this.handleHighPriorityClick,
          intent: "danger",
          title: "High Priority Warnings",
          open: this.state.highPriorityIsOpen
        })}
        {this.renderSection({
          messages: mediumPriorityMessages,
          toggle: this.handleMediumPriorityClick,
          intent: "warning",
          title: "Medium Priority Warnings",
          open: this.state.mediumPriorityIsOpen
        })}
        {this.renderSection({
          messages: lowPriorityMessages,
          toggle: this.handleLowPriorityClick,
          title: "Low Priority Warnings",
          intent: "success",
          open: this.state.lowPriorityIsOpen
        })}
      </div>
    );
  }
}

export default J5LogMessagesView;
