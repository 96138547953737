/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { pick, get } from "lodash";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import lotRecordFragment from "../../../graphql/fragments/lotRecordFragment";
import {
  volumeRender,
  concentrationRender,
  massRender,
  molarityRender,
  materialConcentrationRender
} from "../../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import CreateNewLotDialog from "../../Dialogs/CreateNewLotDialog";

class LotRecordView extends Component {
  renderContent(lot) {
    if (this.props.data.loading) return;
    if (lot) {
      const {
        name,
        description,
        additiveMaterial,
        volume,
        concentration,
        molarity,
        materialConcentration,
        mass
      } = lot;
      const additiveType = get(additiveMaterial, "additiveType.name");
      const recordInfo = [
        ["Name", name],
        description && ["Description", description],
        additiveMaterial && ["Reagent", additiveMaterial.name],
        additiveType && ["Reagent Type", additiveType],
        volume && ["Volume", volumeRender(lot)],
        concentration && ["Concentration", concentrationRender(lot)],
        molarity && ["Molarity", molarityRender(lot)],
        materialConcentration && [
          "Material Concentration",
          materialConcentrationRender(lot)
        ],
        mass && ["Mass", massRender(lot)]
      ];
      return (
        <div className="record-info-container">
          <RecordInfoDisplay
            recordInfo={recordInfo}
            readOnly={this.props.readOnly}
            record={lot}
          />
        </div>
      );
    }
  }

  showUpdateDialog = () => {
    const { lot = {} } = this.props;
    showDialog({
      ModalComponent: CreateNewLotDialog,
      modalProps: {
        dialogProps: {
          title: "Update Reagent Lot"
        },
        materialIsLocked: true,
        isDry: lot.additiveMaterial.isDry,
        initialValues: {
          ...pick(lot, [
            "id",
            "name",
            "description",
            "additiveMaterialId",
            "additiveMaterial",
            "volume",
            "volumetricUnitCode",
            "concentration",
            "concentrationUnitCode",
            "mass",
            "expirationDate",
            "massUnitCode",
            "molarity",
            "molarityUnitCode",
            "materialConcentration",
            "materialConcentrationUnitCode"
          ])
        }
      }
    });
  };

  render() {
    const { lot = {} } = this.props;

    return (
      <AbstractRecord
        {...this.props}
        recordName="lot"
        updateShowFunction={this.showUpdateDialog}
      >
        {this.renderContent(lot)}
      </AbstractRecord>
    );
  }
}

export default recordViewEnhancer(lotRecordFragment)(LotRecordView);
