/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { FC, useMemo } from "react";
import { FieldArray, FieldArrayFieldsProps } from "redux-form";

import { Icon } from "@blueprintjs/core";
import { RemoveButton, AddButton } from "../../../src-shared/FieldArrayButtons";
import "./style.css";
import SortableList from "../../../src-shared/SortableList";
import { v4 as uuid } from "uuid";

interface InnerSortableListFieldProps {
  fields: FieldArrayFieldsProps<any>;
  addButtonText: string;
  ListItemComp: FC<any>;
  noRemove?: boolean;
  tempKey: string;
}

function InnerSortableListField({
  fields,
  addButtonText,
  ListItemComp,
  noRemove,
  tempKey
}: InnerSortableListFieldProps) {
  const SortableItem = useMemo(
    () =>
      ({ item: field, index }: { item: string; index: number }) => {
        return (
          <>
            <Icon icon="drag-handle-vertical" />
            <ListItemComp field={field} fields={fields} index={index} />
            {!noRemove && <RemoveButton fields={fields} index={index} />}
          </>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ListItemComp, noRemove]
  );

  function onDragEnd({
    oldIndex,
    newIndex
  }: {
    oldIndex: number;
    newIndex: number;
  }) {
    fields.move(oldIndex, newIndex);
  }

  const sortableList = (
    <SortableList
      items={fields}
      keyMethod={
        (_item, index) => fields.get(index)[tempKey] || fields.get(index)["id"] // new items will contain the tempKey but old existing items will have the id
      }
      onDragEnd={onDragEnd}
      DisplayComponent={SortableItem}
      wrapperClassName="tg-flex column align-center sortable-field-list"
      wrapperElement="ul"
      innerClassName="tg-flex align-center sortable-list-field-item"
      innerElement="li"
    />
  );
  if (addButtonText) {
    return (
      <>
        {sortableList}
        {addButtonText && (
          <AddButton
            // In case of additions we need to add a unique key to the field before its created
            add={() => fields.push({ [tempKey]: uuid() })}
            fields={fields}
            label={addButtonText}
          />
        )}
      </>
    );
  }
  return sortableList;
}

interface SortableListFieldProps {
  name: string;
  addButtonText: string;
  ListItemComp: FC<{ field: string; index: number }>;
  noRemove?: boolean;
  tempKey?: string;
}

export default function ({
  name,
  addButtonText,
  ListItemComp,
  noRemove,
  tempKey = "tempKey"
}: SortableListFieldProps) {
  return (
    <FieldArray
      name={name}
      noRemove={noRemove}
      component={InnerSortableListField}
      ListItemComp={ListItemComp}
      addButtonText={addButtonText}
      tempKey={tempKey}
    />
  );
}
