/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { DialogFooter } from "@teselagen/ui";
import EditExtendedPropertyValue from "./EditExtendedPropertyValue";
import { validateValue } from "./AddExtendedProperty";
import { deleteWithQuery } from "../apolloMethods";
import "./style.css";
import { classCodeToValueModel, submitAddExtendedProperty } from "./utils";

const EditExtendedProperty = ({
  fullValueFields,
  handleSubmit,
  initialValues,
  model,
  onSubmitValue,
  recordId,
  recordIds,
  refetch,
  reset = () => {},
  submitting,
  stopEdit
}) => {
  const onSubmit = async values => {
    try {
      const {
        __typename,
        extendedProperty: {
          id: extPropId,
          targetModel,
          extendedPropertyClassCode: classCode
        }
      } = values;
      const id = values.id.replace(`${__typename}:`, "");

      const valModel = classCodeToValueModel[classCode];
      await deleteWithQuery(valModel, {
        [model + "Id"]: recordIds,
        extendedPropertyId: extPropId
      });
      let reformattedValues = values;
      if (classCode === "CATEGORY") {
        delete values.value;
        reformattedValues = {
          ...values
        };
      }

      if (classCode === "LINK") {
        delete values.value;
        const elvTable = targetModel + "Elv";
        await deleteWithQuery(elvTable, {
          extendedLinkValueId: (values.otherExtValIds || []).concat(id)
        });
      }

      await submitAddExtendedProperty(reformattedValues, {
        refetch,
        model,
        recordId,
        recordIds,
        onSubmitValue,
        fullValueFields,
        reset: undefined
      });
      reset();
      stopEdit();
    } catch (error) {
      console.error(error);
      window.toastr.error("Error updating extended property.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="record-add-extended-property">
        <h6>Update {initialValues.extendedProperty.name}</h6>
        <EditExtendedPropertyValue
          extendedProperty={initialValues.extendedProperty}
        />
        <DialogFooter submitting={submitting} secondaryAction={stopEdit} />
      </div>
    </form>
  );
};

EditExtendedProperty.propTypes = {
  /**
   * The property to edit
   */
  initialValues: PropTypes.object.isRequired,

  /**
   * Cancel edit
   */
  stopEdit: PropTypes.func.isRequired
};

export default compose(
  reduxForm({
    form: "editExtendedProperty",
    enableReinitialize: true,
    validate
  })
)(EditExtendedProperty);

function validate(values, { initialValues: { extendedProperty } }) {
  const errors = {};
  validateValue({
    extendedProperty,
    values,
    errors
  });
  return errors;
}
