import { getSequenceStringOfSequence } from "../../selectors/designStateSelectors";
import { uniq, find } from "lodash";
import { ChangeSetsHelper } from ".";

/**
 * Given the design state, see which assembly piece elements are valid and invalid
 * The `invalidityMessage` fields on elements will be updated on by this method.
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
export default function validateAssemblyPieceElements(designState) {
  try {
    // if no reaction then no need to perform validation
    if (!Object.values(designState.customJ5Parameter)[0]) return designState;

    const state = { design: designState };
    const assemblyPieceElements = Object.keys(designState.element).reduce(
      function(acc, key) {
        if (designState.element[key].isAssemblyPiece) {
          acc[key] = designState.element[key];
        }
        return acc;
      },
      {}
    );

    const changeSetsHelper = new ChangeSetsHelper(designState);

    const elementIdToInvalidityMessages = {};
    const gibsonOverlapBps = Object.values(designState.customJ5Parameter)[0]
      .gibsonOverlapBps;
    const minFragmentSizeGibsonBps = Object.values(
      designState.customJ5Parameter
    )[0].minFragmentSizeGibsonBps;

    const apToSeqMap = Object.values(assemblyPieceElements).reduce(
      (acc, apEl) => {
        const sequenceId = designState.part[apEl.partId].sequenceId;
        acc[apEl.id] = getSequenceStringOfSequence(state, sequenceId);
        return acc;
      },
      {}
    );

    /**
     * Build a map of assembly piece elements with their neighboring assembly pieces to assist with validation
     *
     * {
     *   [id]: {
     *     isValid: true,
     *     leftNeighborAPs: [],
     *     sameBinAPs: [],
     *     rightNeighborAPs: []
     *   }
     * }
     */
    const apElValidationMap = buildAssemblyPieceElementValidationMap(
      assemblyPieceElements,
      designState
    );
    // kc_todo_refactor the same-bin validation needs only to occur once per bin. Neighboring bin validation after that can just check once.

    // perform assembly piece element validation
    Object.entries(apElValidationMap).forEach(([apElId, data]) => {
      let fivePrimeBps, threePrimeBps, firstSameBpName;

      // APs in the same bin should have matching overlaps on both sides
      data.sameBinAPs.forEach((sameBinAP, index) => {
        if (index === 0) {
          firstSameBpName = sameBinAP.name;
          fivePrimeBps = apToSeqMap[sameBinAP.id].slice(
            0,
            gibsonOverlapBps * 2
          );
          threePrimeBps = apToSeqMap[sameBinAP.id].slice(
            apToSeqMap[sameBinAP.id].length - gibsonOverlapBps * 2
          );
        } else {
          if (
            apToSeqMap[sameBinAP.id].slice(0, gibsonOverlapBps * 2) !==
            fivePrimeBps
          ) {
            elementIdToInvalidityMessages[apElId] =
              elementIdToInvalidityMessages[apElId] || [];
            elementIdToInvalidityMessages[apElId].push(
              firstSameBpName + " has 5' conflict with " + sameBinAP.name
            );
          }

          if (
            apToSeqMap[sameBinAP.id].slice(
              apToSeqMap[sameBinAP.id].length - gibsonOverlapBps * 2
            ) !== threePrimeBps
          ) {
            elementIdToInvalidityMessages[apElId] =
              elementIdToInvalidityMessages[apElId] || [];
            elementIdToInvalidityMessages[apElId].push(
              firstSameBpName + " has 3' conflict with " + sameBinAP.name
            );
          }
        }
      });

      // APs in neighboring bins should have matching overlaps
      data.rightNeighborAPs.forEach(rightBinAP => {
        if (
          apToSeqMap[apElId].slice(
            apToSeqMap[apElId].length - gibsonOverlapBps
          ) !== apToSeqMap[rightBinAP.id].slice(0, gibsonOverlapBps)
        ) {
          elementIdToInvalidityMessages[apElId] =
            elementIdToInvalidityMessages[apElId] || [];
          elementIdToInvalidityMessages[apElId].push(
            firstSameBpName + " has conflict with " + rightBinAP.name
          );
        }
      });

      // APs in neighboring bins should have matching overlaps
      data.leftNeighborAPs.forEach(leftBinAP => {
        if (
          apToSeqMap[apElId].slice(0, gibsonOverlapBps) !==
          apToSeqMap[leftBinAP.id].slice(
            apToSeqMap[leftBinAP.id].length - gibsonOverlapBps
          )
        ) {
          elementIdToInvalidityMessages[apElId] =
            elementIdToInvalidityMessages[apElId] || [];
          elementIdToInvalidityMessages[apElId].push(
            firstSameBpName + " has conflict with " + leftBinAP.name
          );
        }
      });

      // if the piece is less than minFragmentSizeGibsonBps, then mark it as invalid
      Object.values(assemblyPieceElements).forEach(ape => {
        if (apToSeqMap[ape.id].length < minFragmentSizeGibsonBps) {
          elementIdToInvalidityMessages[ape.id] =
            elementIdToInvalidityMessages[ape.id] || [];

          elementIdToInvalidityMessages[ape.id].push(
            ape.name +
              " (" +
              apToSeqMap[ape.id].length +
              " bps) is below min fragment size (" +
              minFragmentSizeGibsonBps +
              ")"
          );
        }
      });
    });

    for (const element of Object.values(assemblyPieceElements)) {
      if (
        element.invalidityMessage &&
        !elementIdToInvalidityMessages[element.id]
      ) {
        changeSetsHelper.updatePure("element", {
          id: element.id,
          invalidityMessage: null
        });
      }
    }

    Object.entries(elementIdToInvalidityMessages).forEach(
      ([elementId, invalidityMessages]) => {
        const element = assemblyPieceElements[elementId];
        const invalidityMessage = invalidityMessages.length
          ? uniq(invalidityMessages).join("\n")
          : null;
        changeSetsHelper.updatePure("element", {
          id: element.id,
          invalidityMessage
        });
      }
    );

    return changeSetsHelper.execute({
      removeCardInvalidityMessages: false
    });
  } catch (e) {
    console.error("Error with validateAssemblyPieceElements", e);
    return designState;
  }
}

/**
 * Given the design state, see which assembly piece elements are valid and invalid
 * The `invalidityMessage` fields on elements will be updated on by this method.
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
function buildAssemblyPieceElementValidationMap(apEls, designState) {
  return Object.keys(apEls).reduce((acc, apElId) => {
    acc[apElId] = {
      isValid: true,
      name: apEls[apElId].name,
      leftNeighborAPs: getNeighboringAPs(apElId, apEls, designState).left || [],
      rightNeighborAPs:
        getNeighboringAPs(apElId, apEls, designState).right || [],
      sameBinAPs: getNeighboringAPs(apElId, apEls, designState).same || []
    };
    return acc;
  }, {});
}

/**
 * Return the assembly piece elements neighboring a given assembly piece element
 *
 * This function does not mutate its arguments.
 *
 * @param {string} apElId
 * @param {Object} apEls
 * @param {Object} designState
 * @returns {Object}
 */
function getNeighboringAPs(apElId, apEls, designState) {
  const cardIdCount = {};
  Object.values(designState.binCard).forEach(bc => {
    cardIdCount[bc.cardId] = cardIdCount[bc.cardId] || 0;
    cardIdCount[bc.cardId]++;
  });
  const singleBinCards = Object.values(designState.binCard).filter(
    bc => cardIdCount[bc.cardId] === 1
  );
  const cardIdThatContainsAP = find(singleBinCards, {
    binId: apEls[apElId].binId
  }).cardId;

  const reactionId = designState.card[cardIdThatContainsAP].inputReactionId;
  const inputIndex = designState.card[cardIdThatContainsAP].inputIndex;

  let maxInputIndex = 0;
  const inputCards = Object.values(designState.card).filter(card => {
    if (
      card.inputReactionId === reactionId &&
      card.inputIndex > maxInputIndex
    ) {
      maxInputIndex = card.inputIndex;
    }
    return card.inputReactionId === reactionId;
  });

  let leftBinAPs,
    sameBinAPs,
    rightBinAPs = [];
  const isOutputCircular = !!Object.values(designState.card).find(
    card => card.outputReactionId === reactionId
  ).circular;

  if (isOutputCircular) {
    inputCards.forEach(card => {
      if (
        inputIndex === 0
          ? card.inputIndex === maxInputIndex
          : card.inputIndex === inputIndex - 1
      ) {
        leftBinAPs = Object.values(apEls).filter(
          apEl => apEl.binId === find(singleBinCards, { cardId: card.id }).binId
        );
      }
      if (card.inputIndex === inputIndex) {
        sameBinAPs = Object.values(apEls).filter(
          apEl => apEl.binId === find(singleBinCards, { cardId: card.id }).binId
        ); // && apEl.id !== apElId  // kc_refactor
      }
      if (
        inputIndex === maxInputIndex
          ? card.inputIndex === 0
          : card.inputIndex === inputIndex + 1
      ) {
        rightBinAPs = Object.values(apEls).filter(
          apEl => apEl.binId === find(singleBinCards, { cardId: card.id }).binId
        );
      }
    });
  } else {
    inputCards.forEach(card => {
      if (card.inputIndex === inputIndex - 1) {
        leftBinAPs = Object.values(apEls).filter(
          apEl => apEl.binId === find(singleBinCards, { cardId: card.id }).binId
        );
      } else if (card.inputIndex === inputIndex) {
        sameBinAPs = Object.values(apEls).filter(
          apEl => apEl.binId === find(singleBinCards, { cardId: card.id }).binId
        );
      } else if (card.inputIndex === inputIndex + 1) {
        rightBinAPs = Object.values(apEls).filter(
          apEl => apEl.binId === find(singleBinCards, { cardId: card.id }).binId
        );
      }
    });
  }

  return {
    left: leftBinAPs,
    right: rightBinAPs,
    same: sameBinAPs
  };
}
