/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import areSourcesAndDestinationsDisjoint from "./areSourcesAndDestinationsDisjoint";
import areDestinationAliquotContainersUnique from "./areDestinationAliquotContainersUnique";

/**
 * See of the given array of aliqout formulations are valid. This includes
 * making sure that the source and destination aliquots of the formulations are disjoint and
 * that the destination aliquot containers, if provided, are unique for each formulation.
 *
 * Returns `true` if the formulations are valid and `false` otherwise.
 * @param {Array<FullFormulation>} fullFormulations Aliquot formulations to check.
 * @returns {boolean} Are the formulations valid?
 */
function validateFormulations(fullFormulations) {
  if (!areSourcesAndDestinationsDisjoint(fullFormulations)) return false;
  if (!areDestinationAliquotContainersUnique(fullFormulations)) return false;
  return true;
}

export default validateFormulations;
