/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { flatMap, get, isEqual, keyBy, pick } from "lodash";
import { compose } from "recompose";
import { DataTable, FileUploadField, ReactSelectField } from "@teselagen/ui";
import { Link } from "react-router-dom";
import { Button, Intent, Callout } from "@blueprintjs/core";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import dataTableFragment from "../../../../graphql/fragments/dataTableFragment";
import stepFormValues from "../../../../../src-shared/stepFormValues";

import PlateCsvWithTabs from "../PlateCsvWithTabs";
import assayPlanningReactionConditionGroupPresetFragment from "../../../../graphql/fragments/assayPlanningReactionConditionGroupPresetFragment";
import { REACTION_CONDITION_GROUP_CSV_HEADER } from "./ReactionConditions";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";
import {
  arrayToItemValuedOptions,
  removeDecimal
} from "../../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  allowedCsvFileTypes,
  parseCsvOrExcelFile
} from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { getMaterialPlasmidSequence } from "../../../../utils";

export const requiredHeaders = [
  "Plate Name",
  "Well",
  "Plasmid ID",
  "Reaction Condition Group"
];

class AssayPlanningInputs extends Component {
  state = {
    loadingAliquotInfo: false,
    loading: false
  };

  async componentDidUpdate(prevProps) {
    const { dataTables: prevDataTables = [] } = prevProps;
    const {
      dataTables = [],
      stepFormProps: { change }
    } = this.props;
    if (
      !isEqual(
        prevDataTables.map(dt => dt.id),
        dataTables.map(dt => dt.id)
      )
    ) {
      this.setState({
        loadingAliquotInfo: true
      });
      change("plasmidsFromDataTables", []);
      const aliquotIds = dataTables.reduce((acc, dataTable) => {
        dataTable.dataRows.forEach(row => {
          const aliquotId = row.rowValues.aliquotId;
          if (aliquotId) acc.push(aliquotId);
        });
        return acc;
      }, []);

      try {
        const aliquots = await safeQuery(
          [
            "aliquot",
            `
            id
            sample {
              id
              material {
                id
                name
                microbialMaterialMicrobialMaterialPlasmids {
                  id
                  polynucleotideMaterial {
                    id
                     polynucleotideMaterialMaterialFpus {
                        id
                        functionalProteinUnit {
                          id
                          name
                          proteinMaterial {
                            id
                            name
                          }
                        }
                      }
                    polynucleotideMaterialSequence {
                      id
                      name

                    }
                  }
                }
              }
            }
            `
          ],
          {
            variables: {
              filter: {
                id: aliquotIds
              }
            }
          }
        );
        const plasmids = [];
        const plasmidIdToMaterial = {};
        aliquots.forEach(aliquot => {
          const material = get(aliquot, "sample.material");
          get(
            material,
            "microbialMaterialMicrobialMaterialPlasmids",
            []
          ).forEach(mmPlasm => {
            const plasmid = getMaterialPlasmidSequence(mmPlasm);
            if (!plasmidIdToMaterial[plasmid.id]) {
              plasmidIdToMaterial[plasmid.id] = material;
              plasmids.push(plasmid);
            }
          });
        });
        change("plasmidsFromDataTables", plasmids);
        change("plasmidIdToMaterial", plasmidIdToMaterial);
      } catch (error) {
        console.error("error:", error);
      }

      this.setState({
        loadingAliquotInfo: false
      });
    }
  }

  beforeUpload = async (fileList, onChange) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const file = fileList[0];
    try {
      const parsedCsv = await parseCsvOrExcelFile(file);
      const missingHeaders = requiredHeaders.reduce((acc, header) => {
        if (!parsedCsv.meta.fields.includes(header)) acc.push(header);
        return acc;
      }, []);
      if (missingHeaders.length > 0) {
        file.error = "Invalid headers in csv.";
        window.toastr.error(
          `File missing the following headers: ${missingHeaders.join(", ")}`
        );
      } else {
        file.parsedCsv = parsedCsv;
        const extraLayers = file.parsedCsv.meta.fields.filter(
          header => !requiredHeaders.includes(header)
        );
        change("extraPlateLayers", extraLayers);
        if (extraLayers.length) {
          const existingPlateLayerDefinitions = await safeQuery(
            [
              "plateLayerDefinition",
              `id
              name
              plateZoneDefinitions {
                id
                name
                color
              }`
            ],
            {
              variables: {
                filter: {
                  name: extraLayers
                }
              }
            }
          );
          change(
            "existingPlateLayerDefinitions",
            existingPlateLayerDefinitions
          );
          existingPlateLayerDefinitions.forEach(plDef => {
            plDef.plateZoneDefinitions.forEach(pzDef => {
              const formNameKey = `al${plDef.name}.li${removeDecimal(
                pzDef.name
              )}`;
              change("userLayerColor." + formNameKey, pzDef.color);
            });
          });
        }
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error uploading file");
    }
    const parsedFile = {
      ...file,
      loading: false
    };

    onChange([parsedFile]);
  };

  fetchReactionConditionPresetGroups = async () => {
    const {
      assayPlanningCsv,
      stepFormProps: { change },
      nextStep
    } = this.props;
    const csvData = get(assayPlanningCsv, "[0].parsedCsv.data", []);
    const reactionConditionNames = [];

    csvData.forEach(row => {
      const rowZone = row[REACTION_CONDITION_GROUP_CSV_HEADER];
      if (rowZone && !reactionConditionNames.includes(rowZone)) {
        reactionConditionNames.push(rowZone);
      }
    });

    this.setState({
      loading: true
    });
    try {
      const existingReactionConditionGroupPresets = await safeQuery(
        assayPlanningReactionConditionGroupPresetFragment,
        {
          variables: {
            filter: {
              name: reactionConditionNames
            }
          }
        }
      );
      const keyedExisting = keyBy(
        existingReactionConditionGroupPresets,
        "name"
      );

      const reactionConditions = reactionConditionNames.map(rcName => {
        const existingReactionConditionPresets = get(
          keyedExisting[rcName],
          "reactionConditionPresets",
          []
        );
        const rows = existingReactionConditionPresets.map(row => {
          return pick(row, [
            "material",
            "additiveMaterial",
            "concentration",
            "concentrationUnitCode",
            "volume",
            "volumetricUnitCode",
            "mass",
            "massUnitCode"
          ]);
        });
        change(`reactionConditionRows.${rcName}`, rows);
        return rcName;
      });
      change("reactionConditions", reactionConditions);
      nextStep();
    } catch (error) {
      this.setState({
        loading: false
      });
      console.error("error:", error);
      window.toastr.error("Error loading reaction condition group info.");
    }
  };

  render() {
    const { loading, loadingAliquotInfo } = this.state;
    const {
      toolSchema,
      dataTables = [],
      assayPlanningCsv,
      plasmidsFromDataTables = [],
      userLayerColor = {},
      stepFormProps: { change },
      Footer,
      footerProps,
      loadingDataTables,
      handleSubmit,
      toolIntegrationProps: { isDisabledMap = {} }
    } = this.props;
    const anyLoading = loading || loadingAliquotInfo;
    const dataTableRows = flatMap(dataTables, dt => dt.dataRows);
    const rowSchema = get(dataTables, "[0].dataTableType.rowSchema");
    const csvData = get(assayPlanningCsv, "[0].parsedCsv.data", []);
    const csvHeaders = get(assayPlanningCsv, "[0].parsedCsv.meta.fields", []);
    const plasmidNames = [],
      unlinkedPlasmids = [],
      plasmidsWithOptions = [],
      missingPlasmidNames = [];
    plasmidsFromDataTables.forEach(plasmid => {
      plasmidNames.push(plasmid.name);
      if (!plasmid.sequenceFpus.length) unlinkedPlasmids.push(plasmid);
      else if (plasmid.sequenceFpus.length > 1)
        plasmidsWithOptions.push(plasmid);
    });
    csvData.forEach(row => {
      const plasmidName = row["Plasmid ID"];
      if (!plasmidNames.includes(plasmidName))
        missingPlasmidNames.push(plasmidName);
    });
    const hasExtraLayers = !!csvHeaders.filter(
      header => !requiredHeaders.includes(header)
    ).length;
    return (
      <div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Upload Assay Planning CSV"
            helper="Upload the Assay Planning .csv file
              containing plasmid info, plate layers and
              reaction condition groups. This information will
              be used to create a layered assay plate map and
              a reaction map for protein purification."
          />
          <div>
            <FileUploadField
              beforeUpload={this.beforeUpload}
              accept={getDownloadTemplateFileHelpers({
                type: allowedCsvFileTypes,
                fileName: "assay_planning",
                headers: requiredHeaders,
                requiredHeaders
              })}
              fileLimit={1}
              isRequired
              name="assayPlanningCsv"
            />
          </div>
        </div>
        {!!csvData.length && hasExtraLayers && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Plate Layers"
              width="100%"
              helper="Below is a list of plate layers found
                on the Assay Planning .csv file. Click on a
                layer to preview its zones. Adjust zone color
                by clicking on the color selector in the zone
                legend. "
            />
            <PlateCsvWithTabs
              toolSchema={toolSchema}
              change={change}
              withButtons
              data={csvData}
              headers={csvHeaders}
              userLayerColor={userLayerColor}
              requiredHeaders={requiredHeaders}
            />
          </div>
        )}
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Microbial Materials from Inventory"
            width="100%"
            helper="Select one or more data tables of microbial
              materials. Validation will ensure that the selected
              microbes contain the plasmids uploaded above."
          />
          <GenericSelect
            {...{
              name: "dataTables",
              isRequired: true,
              isMultiSelect: true,
              schema: ["name", dateModifiedColumn],
              fragment: ["dataTable", "id name updatedAt"],
              additionalDataFragment: dataTableFragment,
              tableParamOptions: {
                additionalFilter: additionalFilterForTables
              },
              buttonProps: {
                disabled: isDisabledMap.dataTables,
                loading: loadingDataTables
              }
            }}
          />
          {rowSchema && (
            <DataTable
              schema={rowSchema}
              entities={dataTableRows}
              formName="assayPlanningDataTableInfoDisplay"
              isSimple
              compact
            />
          )}
          {!anyLoading && !!unlinkedPlasmids.length && (
            <Callout intent="danger">
              {unlinkedPlasmids.map((plasmid, i) => {
                return (
                  <div key={i}>
                    Plasmid{" "}
                    <Link to={modelNameToLink(plasmid)}>{plasmid.name}</Link> is
                    not linked to a protein.
                  </div>
                );
              })}
            </Callout>
          )}
          {!anyLoading && !!missingPlasmidNames.length && (
            <Callout intent="danger">
              {missingPlasmidNames.map((plasmidName, i) => {
                return (
                  <div key={i}>
                    Plasmid {plasmidName} was not found on the microbial
                    material data table.
                  </div>
                );
              })}
            </Callout>
          )}
        </div>
        {plasmidsWithOptions.length > 0 && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Choose FPUs for Plasmids"
              helper="These plasmids were linked to multiple FPUs in inventory. Please specify which one you would like to use."
            />
            {plasmidsWithOptions.map(plasmid => {
              return (
                <div key={plasmid.id}>
                  <ReactSelectField
                    name={`selectedFpuForPlasmid.id${plasmid.id}`}
                    label={`Choose FPU for ${plasmid.name}`}
                    options={arrayToItemValuedOptions(
                      plasmid.sequenceFpus.map(
                        sfpu => sfpu.functionalProteinUnit
                      )
                    )}
                  />
                </div>
              );
            })}
          </div>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              disabled={unlinkedPlasmids.length || missingPlasmidNames.length}
              loading={anyLoading}
              intent={Intent.PRIMARY}
              onClick={handleSubmit(this.fetchReactionConditionPresetGroups)}
            >
              Next
            </Button>
          }
        />
      </div>
    );
  }
}

const additionalFilterForTables = (props, qb) => {
  qb.andWhereAll({
    dataTableTypeCode: "MICROBIAL_INVENTORY_MATERIALS"
  });
};

export default compose(
  stepFormValues(
    "dataTables",
    "assayPlanningCsv",
    "plasmidsFromDataTables",
    "selectedFpuForPlasmid",
    "userLayerColor"
  )
)(AssayPlanningInputs);
