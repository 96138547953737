/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types, flow } from "mobx-state-tree";
import { getApolloMethods } from "@teselagen/apollo-methods";
import client from "../../apolloClient";
import params from "../../../src-test/views/dataAnalysis/statisticalAnalysis/Analyses";
import configs from "../../../src-test/configs/config.json";
import {
  downloader,
  delayApiCall
} from "../../../src-test/utils/statisticalUtils";
const { safeQuery: query, safeDelete } = getApolloMethods(client);

export const statisticalAnalysisStore = types
  .model("statisticalAnalysisStore", {
    entities: types.optional(types.array(types.frozen()), [{}]),
    loading: types.optional(types.boolean, false),
    apiCall: types.frozen()
  })
  .views(self => ({
    get getApiCall() {
      return self.apiCall;
    },
    get getLoading() {
      return self.loading;
    }
  }))
  .actions(self => {
    const fetchData = flow(function*(assayId) {
      self.loading = true;
      try {
        const results = yield query(
          [
            "statisticalAnalysisResult",
            "id name createdAt status user{ id, username } assay{id, name} importFileSet{ name, id}"
          ],
          {
            variables: {
              sort: "-updatedAt",
              filter: {
                assayId
              }
            }
          }
        );
        if (results.length > 0) {
          self.entities = results.map(sar => {
            const sarCopy = Object.assign({}, sar);
            sarCopy.label = params[sar.name].label;
            return sarCopy;
          });
        }
      } catch (error) {
        console.error(error);
      }
      self.loading = false;
    });
    const setInstance = flow(function*(instanceId, assayId) {
      self.loading = true;
      try {
        self.apiCall = yield delayApiCall(
          configs.endpoints["getStatisticalAnalyses"]
            .replace(":assayId", assayId)
            .replace(":instanceId", instanceId),
          300
        );
      } catch (error) {
        console.error(error);
      }
      self.loading = false;
    });
    const deleteData = flow(function*(idsList) {
      self.loading = true;
      try {
        yield safeDelete("statisticalAnalysisResult", idsList);
      } catch (error) {
        console.error(error);
      }
      self.loading = false;
    });
    const downloadResult = flow(function*() {
      try {
        yield downloader(
          JSON.stringify(self.getApiCall.data.test_summary),
          "application/json",
          `statistical-analysis-${self.getApiCall.data.id}.json`
        );
      } catch (error) {
        window.toastr.error("we can't download the file");
        self.downloading = false;
        console.error(error);
      }
    });
    return {
      fetchData,
      deleteData,
      setInstance,
      downloadResult
    };
  });
