/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import jobUserFragment from "./jobUserFragment";

export default gql`
  fragment jobFragment on job {
    id
    user {
      id
      username
    }
    lab {
      id
      name
    }
    name
    description
    priorityType {
      code
      name
      color
    }
    startDate
    dueDate
    createdAt
    updatedAt
    customerRequestJobs {
      id
      customerRequest {
        id
        name
        requestType {
          id
          name
        }
        dueDate
      }
      customerRequestId
      jobId
    }
    jobOwner {
      id
      username
      isDeactivated
    }
    jobUsers {
      ...jobUserFragment
    }
  }
  ${jobUserFragment}
`;
