/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import SelectStartItems from "./SelectStartItems";
import gql from "graphql-tag";
import withQuery from "../../../../src-shared/withQuery";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

class StartTaskTool extends React.Component {
  onSubmit = async values => {
    try {
      const { workflowTool } = this.props;
      const toReturn = {};
      workflowTool.workflowToolOutputs.forEach(output => {
        toReturn[output.workflowToolOutputDefinition.itemCode] =
          values["startItemsFor:" + output.id];
      });
      return toReturn;
    } catch (error) {
      console.error("error:", error);
      const message = error.message || "Error updating plates.";
      throwFormError(message);
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues,
      workflowTool
    } = this.props;

    const steps = [
      {
        title: "Select Start Task Items",
        Component: SelectStartItems,
        props: {
          workflowTool
        }
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

const workflowToolFragment = gql`
  fragment startTaskWorkflowToolFragment on workflowTool {
    id
    workflowToolOutputs {
      id
      workflowToolOutputDefinition {
        id
        label
        itemCode
        taskIoType {
          code
          inventoryItemTypeCode
          dataItemTypeCode
        }
      }
    }
  }
`;

export default compose(
  withQuery(workflowToolFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.toolIntegrationProps.taskId
        }
      };
    }
  })
)(StartTaskTool);
