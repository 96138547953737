/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { handleActions } from "redux-actions";
import actions from "../../actions";

const initialState = {
  designsRunningJ5: {},
  submittingToJ5: {}
};

export default handleActions(
  {
    [actions.j5.submitDesignForAssemblyStarted]: (
      state,
      { payload: { designId } }
    ) => ({
      ...state,
      designsRunningJ5: {
        ...state.designsRunningJ5,
        [designId]: true
      }
    }),

    [actions.j5.submitDesignForAssemblyCompleted]: (
      state,
      { payload: { designId } }
    ) => ({
      ...state,
      designsRunningJ5: {
        ...state.designsRunningJ5,
        [designId]: false
      }
    }),

    [actions.j5.submitDesignForAssemblyFailed]: (
      state,
      { payload: { designId } }
    ) => ({
      ...state,
      designsRunningJ5: {
        ...state.designsRunningJ5,
        [designId]: false
      }
    }),
    [actions.j5.submittingToJ5Start]: (state, { payload: { designId } }) => ({
      ...state,
      submittingToJ5: {
        ...state.submittingToJ5,
        [designId]: true
      }
    }),
    [actions.j5.submittingToJ5Finish]: (state, { payload: { designId } }) => ({
      ...state,
      submittingToJ5: {
        ...state.submittingToJ5,
        [designId]: false
      }
    })
  },
  initialState
);
