/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Menu, Popover } from "@blueprintjs/core";
import React from "react";
import { popoverOverflowModifiers } from "../../../src-shared/utils/generalUtils";
import PrintLabelMenuItem from "../PrintLabelMenuItem";

function PrintLabelButton({ records, refetch }) {
  return (
    <Popover
      key="print"
      modifiers={popoverOverflowModifiers}
      minimal
      position="bottom-left"
      content={
        <Menu>
          <PrintLabelMenuItem skipOuter records={records} refetch={refetch} />
        </Menu>
      }
    >
      <Button icon="print" text="Print Label" />
    </Popover>
  );
}

export default PrintLabelButton;
