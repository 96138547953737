/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Intent } from "@blueprintjs/core";
import React from "react";
import { INVALID_DIGEST_PART_MSG } from "../../../tg-iso-shared/utils/digestPartUtils";
import LabelWithTooltip from "../LabelWithTooltip";

export const digestPartColumn = {
  path: "isDigestPart",
  type: "boolean",
  displayName: "Is Digest",
  render: (_, record) => {
    const { isDigestPart, isDigestValid } = record;

    return (
      <LabelWithTooltip
        label={isDigestPart ? "Yes" : "No"}
        {...(isDigestPart &&
          !isDigestValid && {
            tooltip: INVALID_DIGEST_PART_MSG,
            intent: Intent.WARNING,
            icon: "warning-sign"
          })}
      />
    );
  }
};
