/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get } from "lodash";

const getPlasmidInsertAndBackboneFeatures = plasmidMaterial => {
  const VECTOR_FEATURE_NAME = "backbone plasmid"; //backbone plasmid
  const INSERT_FEATURE_NAME = "insert synthon"; //insert synthon

  const potentialFeatures =
    plasmidMaterial.polynucleotideMaterialSequence.sequenceFeatures;
  const features = potentialFeatures === undefined ? [] : potentialFeatures;
  const insertAsUnitArr = features.filter(f => f.name === INSERT_FEATURE_NAME); // should be array of length 1
  const backboneAsUnitArr = features.filter(
    f => f.name === VECTOR_FEATURE_NAME
  ); // same as above
  if (insertAsUnitArr.length < 1 || backboneAsUnitArr.length < 1) {
    // if (features.length < 1) {
    //   console.error(
    //     "Error: material sequence features list is empty. Plasmids must have insert/backbone annotations as sequence features"
    //   );
    // } else {
    //   console.error(
    //     "Error identifying plasmid insert feature and/or backbone feature from feature list"
    //   );
    // }
    // console.error(
    //   "Plasmid: " + plasmidMaterial.name,
    //   "looking for VECTOR_FEATURE_NAME",
    //   VECTOR_FEATURE_NAME,
    //   "looking for INSERT_FEATURE_NAME",
    //   INSERT_FEATURE_NAME,
    //   "in features list:",
    //   features
    // );
    return {
      insertFeature: "NO_INSERT",
      backboneFeature: "NO_BACKBONE"
    };
  } else {
    return {
      insertFeature: insertAsUnitArr[0],
      backboneFeature: backboneAsUnitArr[0]
    };
  }
};

// const getReverseComplementSequence = (sequenceString) => {
//   const allCaps = sequenceString.toUpperCase()
//   const sequenceArr = allCaps.split("")
//   const reverseArr = sequenceArr.reverse()
//   let revCompString = ""
//   reverseArr.forEach((base, index) => {
//     switch(base) {
//       case "A":
//         revCompString += "T"
//         break;
//       case "T":
//         revCompString += "A"
//         break;
//       case "G":
//         revCompString += "C"
//         break;
//       case "C":
//         revCompString += "G"
//         break;
//       default:
//       throw new SubmissionError({
//         _error:
//           `Error creating reverse complement, string contains non-ATGC character
//             ${base} at index ${index}`
//       });
//     }
//     return revCompString
//   })

// }

const getFullSequence = dnaMaterial => {
  // Full sequence is assembled in the order of the fragments in the array
  const fragments =
    dnaMaterial.polynucleotideMaterialSequence.sequenceFragments;
  let fullSequence = "";
  fragments.forEach(currentFragment => {
    fullSequence += currentFragment.fragment;
  });
  return fullSequence;
};

const cutAsCircular = (firstCutIndex, secondCutIndex, seq) => {
  const unbrokenSeq = seq.slice(firstCutIndex, secondCutIndex);
  const joinAcrossOriginSeq =
    seq.slice(secondCutIndex) + seq.slice(0, firstCutIndex);
  return [unbrokenSeq, joinAcrossOriginSeq];
};

export const splitPlasmidInsertAndBackbone = plasmidMaterial => {
  const {
    insertFeature,
    backboneFeature
  } = getPlasmidInsertAndBackboneFeatures(plasmidMaterial);
  const sequence = getFullSequence(plasmidMaterial);

  // Fail-safe to check if insert identification did not work
  if (insertFeature === "NO_INSERT") {
    console.error("Error: could not identify insert and backbone features");
    return {
      insertSize: "NO_INSERT",
      insertSequence: "",
      backboneSequence: "",
      insertionIndexApproximate: -1
    };
  }

  // NOTE: these annotations are ALWAYS on strand 1 (+ strand)! Says Kyle.
  //console.log("insert and backbone features", insertFeature, backboneFeature)
  //console.log("total sequence length:", sequence.length)
  let insertSeq, backboneSeq;
  if (insertFeature.start < insertFeature.end) {
    //then insert is unbroken, doesn't span origin
    [insertSeq, backboneSeq] = cutAsCircular(
      insertFeature.start,
      insertFeature.end,
      sequence
    );
  } else {
    //this is the case where the insert spans index 0 in the plasmid, is broken
    [backboneSeq, insertSeq] = cutAsCircular(
      backboneFeature.start,
      backboneFeature.end,
      sequence
    );
  }
  //console.log('insertSeq', insertSeq.length, insertSeq)
  //console.log('backboneSeq', backboneSeq.length, backboneSeq)
  //console.log('insert + backbone = total?', insertSeq.length + backboneSeq.length === sequence.length)
  return {
    insertSequence: insertSeq.toUpperCase(),
    backboneSequence: backboneSeq.toUpperCase(),
    insertSize: insertSeq.length,
    insertionIndexApproximate: insertFeature.start
  };
};

export const getPlasmidInsertLength = plasmidMaterial => {
  const { insertFeature } = getPlasmidInsertAndBackboneFeatures(
    plasmidMaterial
  );
  if (insertFeature === "NO_INSERT") {
    return insertFeature;
  } else {
    return Math.abs(insertFeature.end - insertFeature.start);
  }
};

export const getPlasmidVectorSequence = plasmidMaterial => {
  const extraFields = splitPlasmidInsertAndBackbone(plasmidMaterial);
  return extraFields.backboneSequence;
};

export const makePlasmidInsertLinearizedAuxMaterials = plasmidMaterials => {
  const plasmidInsertAuxMaterials = plasmidMaterials.map(m => {
    const extraFields = splitPlasmidInsertAndBackbone(m);
    return {
      id: m.id,
      name: m.name + "_INSERT",
      polynucleotideMaterialSequence: {
        sequenceTypeCode: get(
          m,
          "polynucleotideMaterialSequence.sequenceTypeCode"
        ),
        sequenceFragments: [{ fragment: extraFields.insertSequence }]
      }
    };
  });
  return plasmidInsertAuxMaterials;
};
