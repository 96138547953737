/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";

import {
  getNewConcentrationForHydratedAliquot,
  getAliquotMolecularWeight,
  getNewCellConcentrationForHydratedAliquot
} from "../../../utils/aliquotUtils";
import {
  convertMolarityBig,
  calculateMolarityFromConcentration
} from "../../../utils/unitUtils";

function updateAliquot(fullFormulation, writeBuffer) {
  const { upsert } = writeBuffer;

  const aliquot = fullFormulation.getAliquot();
  let concentrationUpdates = {};
  let cellConcentrationUpdates = {};
  const newVolume = fullFormulation.getVolume();
  const volumetricUnitCode = fullFormulation.getVolumetricUnitCode();
  const isDry = fullFormulation.isDry();
  if (newVolume !== aliquot.volume && aliquot.volume && aliquot.concentration) {
    const newConcentration = Number(
      new Big(aliquot.volume)
        .div(new Big(newVolume))
        .times(new Big(aliquot.concentration))
    );
    let molarityFields;
    if (getAliquotMolecularWeight(aliquot)) {
      molarityFields = {
        molarity: Number(
          convertMolarityBig(
            calculateMolarityFromConcentration(
              newConcentration,
              aliquot.concentrationUnitCode,
              getAliquotMolecularWeight(aliquot)
            ),
            "M",
            "nM"
          )
        ),
        molarityUnitCode: "nM"
      };
    }
    concentrationUpdates = {
      concentration: newConcentration,
      ...molarityFields
    };
  } else if (
    (aliquot.isDry || !aliquot.volume) &&
    !isDry &&
    aliquot.massUnitCode
  ) {
    concentrationUpdates = getNewConcentrationForHydratedAliquot(
      aliquot,
      newVolume,
      volumetricUnitCode
    );
  }

  if (
    newVolume !== aliquot.volume &&
    aliquot.volume &&
    (aliquot.cellConcentration || aliquot.cellCount)
  ) {
    let oldCellConcentration = aliquot.cellConcentration;
    if (!oldCellConcentration) {
      oldCellConcentration = new Big(aliquot.cellCount).div(aliquot.volume);
    }
    const newCellConcentration = Number(
      new Big(aliquot.volume)
        .div(new Big(newVolume))
        .times(new Big(oldCellConcentration))
    );
    cellConcentrationUpdates = {
      cellConcentration: newCellConcentration,
      cellConcentrationUnitCode:
        aliquot.cellConcentrationUnitCode ||
        `cells/${aliquot.volumetricUnitCode}`
    };
  } else if ((aliquot.isDry || !aliquot.volume) && !isDry) {
    cellConcentrationUpdates = getNewCellConcentrationForHydratedAliquot(
      aliquot,
      newVolume,
      volumetricUnitCode
    );
  }

  upsert("aliquot", {
    id: aliquot.id,
    volume: newVolume,
    volumetricUnitCode,
    isDry,
    ...concentrationUpdates,
    ...cellConcentrationUpdates
  });
}

export default updateAliquot;
