/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import QueryBuilder from "tg-client-query-builder";
import { groupBy } from "lodash";
import Promise from "bluebird";
import { sortContainers } from "../../../utils";
import { safeQuery } from "../../../../src-shared/apolloMethods";

export function containerEmptyPathFilter({ equipmentId, equipmentPositionId }) {
  const qb = new QueryBuilder("container");

  return qb
    .whereAll({
      equipmentId,
      equipmentPositionId,
      path: ""
    })
    .orWhereAll({
      equipmentId,
      equipmentPositionId,
      path: null
    })
    .toJSON();
}

async function loadPositionsOnContainer(container, options = {}) {
  const qb = new QueryBuilder("containerPosition");
  let filter = qb.whereAll({
    containerId: container.id
  });
  if (options && options.extraFilterFn) {
    options.extraFilterFn(qb);
  }
  filter = filter.toJSON();

  let positions = await safeQuery(["containerPosition", "id index"], {
    variables: {
      filter
    }
  });
  if (positions.length) {
    positions = [...positions]
      .sort((a, b) => a.index - b.index)
      .map(p => ({ ...p }));

    await Promise.map(positions, async position => {
      const assignedPositions = await safeQuery(
        ["assignedPosition", "id containerPositionId"],
        {
          variables: {
            filter: {
              containerPositionId: position.id
            },
            pageSize: 1
          }
        }
      );
      position.numItems = assignedPositions.totalResults;
    });
  }
  container.positions = positions;
}

function makeExtensible(arr) {
  return arr.map(item => ({ ...item }));
}

export async function loadFreezerView(equipmentId) {
  let shelves = makeExtensible(
    await safeQuery(["container", "id name label"], {
      variables: {
        filter: containerEmptyPathFilter({ equipmentId })
      }
    })
  );

  if (shelves.length) {
    shelves = sortContainers(shelves);
    const rackFilters = shelves.map(shelf => ({
      path: `/${shelf.id}`
    }));
    const qb = new QueryBuilder("container");

    const racks = makeExtensible(
      await safeQuery(["container", "id name label path"], {
        variables: {
          filter: qb.whereAny(...rackFilters).toJSON()
        }
      })
    );
    if (racks.length) {
      const groupedRacks = groupBy(racks, "path");
      shelves.forEach(shelf => {
        shelf.racks = sortContainers(groupedRacks[`/${shelf.id}`] || []);
      });
      await Promise.map(shelves, async shelf => {
        if (!shelf.racks.length) {
          await loadPositionsOnContainer(shelf);
        }
      });
      const drawerFilters = racks.map(rack => ({
        path: `${rack.path}/${rack.id}`
      }));
      const qb = new QueryBuilder("container");

      const drawers = makeExtensible(
        await safeQuery(["container", "id name label path"], {
          variables: {
            filter: qb.whereAny(...drawerFilters).toJSON()
          }
        })
      );

      if (drawers.length) {
        const groupedDrawers = groupBy(drawers, "path");
        racks.forEach(rack => {
          rack.drawers = sortContainers(
            groupedDrawers[`${rack.path}/${rack.id}`] || []
          );
        });
        await Promise.map(racks, async rack => {
          if (!rack.drawers.length) {
            await loadPositionsOnContainer(rack);
          }
        });
        await Promise.map(drawers, async drawer => {
          const extraFilterFn = qb => {
            qb.orWhereAll({
              "container.path": qb.startsWith(`${drawer.path}/${drawer.id}`)
            });
          };
          await loadPositionsOnContainer(drawer, { extraFilterFn });
        });
      } else {
        await Promise.map(racks, loadPositionsOnContainer);
      }
    } else {
      await Promise.map(shelves, loadPositionsOnContainer);
    }
  }

  return shelves;
}
