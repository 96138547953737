/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import { getReferencedValue } from "../../../../../tg-iso-design/selectors/designStateSelectors";

export default (state, { payload: { id } }, fullState) => {
  const changeSetsHelper = new ChangeSetsHelper(state);
  changeSetsHelper.deletePure("elementGroupCombo", id);
  changeSetsHelper.deletePure(
    "elementGroupElementGroupCombos",
    getReferencedValue(
      fullState,
      "elementGroupCombo",
      id,
      "elementGroupElementGroupCombos"
    ).map(g => g.id)
  );
  return changeSetsHelper.execute();
};
