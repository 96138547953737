/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  DateInputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import workflowRunBasicInfoWorkflowRunFragment from "../../../graphql/fragments/workflowRunBasicInfoWorkflowRunFragment";

import GenericSelect from "../../../../src-shared/GenericSelect";
import { get } from "lodash";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class UpdateWorkflowRunDialog extends Component {
  onSubmit = async _values => {
    const { hideModal } = this.props;
    try {
      const values = { ..._values };
      values.priorityTypeCode = get(values, "priorityType.code");
      delete values.priorityType;
      await safeUpsert(workflowRunBasicInfoWorkflowRunFragment, values);
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating workflow run.");
    }
  };

  render() {
    const { handleSubmit, submitting, hideModal } = this.props;

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" />
          <GenericSelect
            {...{
              name: "priorityType",
              asReactSelect: true,
              idAs: "code",
              label: "Priority",
              schema: ["name"],
              defaultValue: {
                code: "NORMAL",
                name: "Normal"
              },
              fragment: ["priorityType", "code name"]
            }}
          />
          <DateInputField label="Due Date" name="dueDate" />
          <TextareaField name="notes" label="Notes" />
        </div>
        <DialogFooter
          onClick={handleSubmit(this.onSubmit)}
          submitting={submitting}
          hideModal={hideModal}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Update Workflow"
  }),
  reduxForm({
    form: "updateWorkflowRun"
  })
)(UpdateWorkflowRunDialog);
