/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../src-shared/withQuery";
import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import { compose } from "redux";
import AminoAcidPartLibraryDialog from "../components/Dialogs/AminoAcidPartLibraryDialog";
import { Link } from "react-router-dom";
import {
  aaAnnotationSizeStartEndColumns,
  dateColumns
} from "../../src-shared/utils/libraryColumns";

const schema = {
  model: "aminoAcidPart",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    ...aaAnnotationSizeStartEndColumns,
    {
      path: "aminoAcidSequence.name",
      type: "string",
      displayName: "Source",
      render: (name, val) => {
        return (
          <Link to={`/amino-acid-sequences/${val.aminoAcidSequence.id}`}>
            {name}
          </Link>
        );
      }
    },
    ...dateColumns
  ]
};

const AminoAcidPartLibraryDialogContainer = compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "aminoAcidPartLibraryDialog",
    withSelectedEntities: true,
    cellRenderer: {
      start: index => {
        return <div title={(index + 3) / 3}>{(index + 3) / 3}</div>;
      },
      end: index => {
        return <div title={(index + 1) / 3}>{(index + 1) / 3}</div>;
      }
    }
  }),
  withQuery(
    generateFragmentWithFields(
      "aminoAcidPart",
      `
      id
      start
      end
      name
      createdAt
      updatedAt
      aminoAcidSequence {
        id
        name
        proteinSequence
      }
    `
    ),
    {
      isPlural: true,
      options: props => ({
        variables: {
          ...props.variables,
          pageSize: props.tableParams.pageSize
        }
      })
    }
  )
)(AminoAcidPartLibraryDialog);

export default AminoAcidPartLibraryDialogContainer;
