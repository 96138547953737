/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const aliquotContainerTypeSettingsBasicFragment = gql`
  fragment aliquotContainerTypeSettingsBasicFragment on aliquotContainerType {
    code
    name
    description
    isTube
    maxVolume
    volumetricUnitCode
    deadVolume
    deadVolumetricUnitCode
    bottom
  }
`;

export const aliquotContainerTypeSettingsFragment = gql`
  fragment aliquotContainerTypeSettingsFragment on aliquotContainerType {
    ...aliquotContainerTypeSettingsBasicFragment
    isSystemType
    updatedAt
    aliquotContainers(pageSize: 1) {
      id
    }
  }
  ${aliquotContainerTypeSettingsBasicFragment}
`;

export const containerFormatTypeSettingsFragment = gql`
  fragment containerFormatTypeSettingsFragment on containerFormat {
    code
    name
    rowCount
    columnCount
    quadrantSize
    is2DLabeled
    updatedAt
    containerArrayTypes(pageSize: 1) {
      id
    }
    plateMapGroups(pageSize: 1) {
      id
    }
  }
`;

export const containerArrayTypeSettingsFragment = gql`
  fragment containerArrayTypeSettingsFragment on containerArrayType {
    id
    name
    description
    isSystemType
    isPlate
    isColumn
    manufacturer
    catalogNumber
    nestableTubeTypes {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        ...aliquotContainerTypeSettingsBasicFragment
      }
    }
    aliquotContainerTypeCode
    aliquotContainerType {
      ...aliquotContainerTypeSettingsBasicFragment
    }
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
      isSystemType
    }
    updatedAt
    containerArrays(pageSize: 1) {
      id
    }
  }
  ${aliquotContainerTypeSettingsBasicFragment}
`;

export const dataFileTypeSettingsFragment = gql`
  fragment dataFileTypeSettingsFragment on dataFileType {
    code
    name
    description
    isSystemType
    updatedAt
    dataFiles(pageSize: 1) {
      id
    }
  }
`;

export const reactionTypeSettingsFragment = gql`
  fragment reactionTypeSettingsFragment on reactionType {
    code
    name
    description
    updatedAt
    isSystemType
    reactionMaps(pageSize: 1) {
      id
    }
  }
`;

export const requestTypeSettingsFragment = gql`
  fragment requestTypeSettingsFragment on requestType {
    id
    name
    description
    instructions
    updatedAt
    customerRequests(pageSize: 1) {
      id
    }
  }
`;

export const additiveTypeSettingsFragment = gql`
  fragment additiveTypeSettingsFragment on additiveType {
    code
    isSystemType
    name
    description
    updatedAt
    additiveMaterials(pageSize: 1) {
      id
    }
  }
`;
