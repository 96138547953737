/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { statusConstants } from "../../configs/config.js";
import { Icon } from "@blueprintjs/core";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(calendar);

const allModelsViewSchema = {
  model: "model",
  fields: [
    {
      path: "id",
      displayName: "Model ID"
    },
    {
      path: "name"
    },
    {
      path: "status",
      render: value => {
        let status = statusConstants[value];
        status = status ? status : statusConstants["completed-failed"];

        return (
          <span>
            <Icon intent={status.intent} icon={status.icon} />
            {" " + status.shortDescription}
          </span>
        );
      }
    },
    {
      displayName: "Last Status Update",
      path: "lastCheckIn",
      render: (value, record) => {
        const lastUpdateDate = value ? value : record.createdAt;
        const localDate = dayjs(lastUpdateDate).calendar();
        return localDate;
      }
    }
  ]
};

export default allModelsViewSchema;
