/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm, FieldArray } from "redux-form";
import { compose } from "recompose";
import {
  InputField,
  ReactSelectField,
  wrapDialog,
  DialogFooter
} from "@teselagen/ui";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Classes, Callout } from "@blueprintjs/core";
import CategoricalItems from "./CategoricalItems";
import { getModelTypeOptions } from "../../utils/extendedPropertyUtils";
import { safeUpsert } from "../../apolloMethods";

class EditCategoryExtendedProperty extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object,
    refetch: PropTypes.func.isRequired
  };

  onSubmit = async values => {
    try {
      const { initialValues = {}, refetch, hideModal } = this.props;

      await safeUpsert("extendedProperty", {
        id: initialValues.id,
        name: values.name.trim(),
        modelTypeCode: values.modelTypeCode,
        extendedPropertyClassCode: "CATEGORY",
        extendedCategoryClass: {
          id: get(initialValues, "extendedCategoryClass.id"),
          name: values.name,
          extendedCategories: values.options.map(v => ({
            id: v.id,
            name: v.name
          }))
        }
      });

      await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error ${
          this.isEditing() ? "editing" : "creating"
        } categorical extended property.`
      );
    }
  };

  /**
   * Are we editing or creating a new extended property?
   */
  isEditing() {
    return !!get(this.props, "initialValues.id");
  }

  render() {
    const {
      hideModal,
      handleSubmit,
      initialValues = {},
      submitting,
      initialItemType
    } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {!!initialValues.id && (
            <Callout style={{ marginBottom: 10 }} intent="warning">
              Cannot edit item type when updating extended properties because
              they might be in use.
            </Callout>
          )}
          <ReactSelectField
            name="modelTypeCode"
            isRequired
            label="Item Type"
            disabled={!!initialValues.id}
            options={getModelTypeOptions()}
            defaultValue={initialItemType}
          />
          <InputField name="name" label="Name" isRequired />

          <FieldArray
            name="options"
            component={CategoricalItems}
            isEditing={this.isEditing()}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Categorical Extended Property"
          : "Add Categorical Extended Property"
      };
    }
  }),
  reduxForm({
    form: "EditCategoryExtendedProperty",
    enableReinitialize: true,
    validate
  })
)(EditCategoryExtendedProperty);

function validate(values) {
  const errors = {};

  const { options } = values;
  if (!get(options, "length")) {
    errors.options = {
      _error: "At least one option must be supplied."
    };
  } else {
    errors.options = [];

    const optionsNamesCount = {};
    options.forEach(c => {
      if (!optionsNamesCount[c.name]) optionsNamesCount[c.name] = 0;
      optionsNamesCount[c.name]++;
    });

    options.forEach((c, i) => {
      if (!c.name) {
        errors.options[i] = { name: "Required." };
      } else if (optionsNamesCount[c.name] > 1) {
        errors.options[i] = { name: "Duplicated option." };
      }
    });
  }

  return errors;
}
