/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
if (window.Cypress?.disabledToolkits) {
  window.frontEndConfig.disabledToolkits = {
    ...window.frontEndConfig.disabledToolkits,
    ...window.Cypress.disabledToolkits
  };
}

// console.log(
//   `window.frontEndConfig.disabledToolkits:`,
//   window.frontEndConfig.disabledToolkits
// );
// window.frontEndConfig.disabledToolkits.workflowManagement = undefined;

if (
  window.frontEndConfig.notificationInterval &&
  Number(window.frontEndConfig.notificationInterval) > 0
) {
  window.frontEndConfig.notificationInterval = Number(
    window.frontEndConfig.notificationInterval
  );
} else {
  window.frontEndConfig.notificationInterval = 30000;
}
