/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
//this function should throw an error if something goes wrong with the import/update

import { cloneDeep, uniq } from "lodash";
import shortid from "shortid";
import {
  generateContainerArray,
  getPositionFromAlphanumericLocation,
  validatePlateUploadFormat
} from "../../../../tg-iso-lims/src/utils/plateUtils";
import { standardizeVolume } from "../../../../tg-iso-lims/src/utils/unitUtils";
import getNameKeyedItems from "../../utils/getNameKeyedItems";
import concatWarningStrs from "../../../utils/concatWarningStrs";
import aliquotContainerTypeFragment from "../../fragments/aliquotContainerTypeFragment";
import containerArrayTypeFragment from "../../fragments/containerArrayTypeFragment";
import handleUpdateMutations from "./handleUpdates";
import { upsertAddIds, validateUnits } from "./utils";

export default async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx
) {
  const plateTypes = uniq(
    recordsToImport
      .map(p => {
        p.plateType = p.plateType || p.__oldRecord.plateType;
        return p.plateType;
      })
      .filter(p => p)
  );
  const tubeTypes = uniq(recordsToImport.map(p => p.tubeType).filter(t => t));
  const containerArrayTypes = await getNameKeyedItems(
    {
      names: plateTypes,
      model: "containerArrayType",
      fragment: containerArrayTypeFragment
    },
    ctx
  );

  const aliquotContainerTypes = await getNameKeyedItems(
    {
      names: tubeTypes,
      model: "aliquotContainerType",
      fragment: aliquotContainerTypeFragment
    },
    ctx
  );

  let recordsToContinueUpserting = recordsToImport.filter(r => {
    const containerArrayType = containerArrayTypes[r.plateType.toLowerCase()];
    if (!containerArrayType) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        `No plate type found with name ${r.plateType}`
      );
      return false;
    }
    const aliquotContainerType =
      r.tubeType && aliquotContainerTypes[r.tubeType.toLowerCase()];
    r.containerArrayType = containerArrayType;
    r.aliquotContainerType =
      aliquotContainerType || containerArrayType.aliquotContainerType;
    if (r.tubeType && !aliquotContainerType) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        `No tube type found with name ${r.tubeType}`
      );
      return false;
    }
    if (!r.id && !containerArrayType.isPlate && !r.tubeType) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Must specify tube type."
      );
      return false;
    }
    try {
      const contentsWithLocations = r.contents.filter(c => c.location);
      validatePlateUploadFormat(
        contentsWithLocations,
        containerArrayType,
        "location",
        { alwaysThrow: true }
      );
    } catch (error) {
      console.error(`error with plate upload format:`, error);
      r.__importFailed = concatWarningStrs(r.__importFailed, error.message);
      return false;
    }

    return true;
  });

  const aliquotsToCreate = [];
  recordsToContinueUpserting = recordsToContinueUpserting.filter(p => {
    const keyedOldContents = {};
    const keyedNewContents = {};
    if (p.__oldRecord) {
      p.__oldRecord.contents.forEach(well => {
        keyedOldContents[well.location] = well;
      });
      // TODO p.__newRecord.contents is a concatenation of old and new which is bad
      // need to change mergeUpdateRecord or just use contents here. Kinda confusing though
      p.contents.forEach(well => {
        keyedNewContents[well.location] = well;
      });
    }

    const { maxVolume, volumetricUnitCode } = p.aliquotContainerType;
    const maxWellVolume = standardizeVolume(maxVolume, volumetricUnitCode);
    for (const well of p.contents) {
      if (well.aliquot) {
        if (!well.aliquot.cid) {
          well.aliquot.cid = shortid();
        }
        // clone to prevent mutations messing everything up
        // however, this will make getting the aliquot.id more difficult later
        const newAliquot = cloneDeep(well.aliquot);
        const oldR = keyedOldContents[well.location];
        const newR = keyedNewContents[well.location];
        if (oldR && newAliquot.id && newAliquot.id !== oldR.aliquot.id) {
          p.__importFailed = concatWarningStrs(
            p.__importFailed,
            `Aliquot at location ${well.location} cannot be updated because the old contents does not match.`
          );
          return false;
        }
        if (oldR && newAliquot.id) {
          newAliquot.__oldRecord = oldR.aliquot;
          newAliquot.__newRecord = newR.aliquot;
        }

        if (newAliquot && newAliquot.volume) {
          const unitError = validateUnits(newAliquot);
          if (unitError) {
            p.__importFailed = concatWarningStrs(
              p.__importFailed,
              `Location ${well.location}: ${unitError}`
            );
            return false;
          }
          const volumetricUnitCode =
            newAliquot.volumetricUnitCode ||
            (newAliquot.__oldRecord &&
              newAliquot.__oldRecord.volumetricUnitCode);
          // allows users to update volume without specifying a new volumetric code
          if (!volumetricUnitCode) {
            p.__importFailed = concatWarningStrs(
              p.__importFailed,
              `Location ${well.location}: need volumetric unit code for volume changes.`
            );
            return false;
          }
          const wellVolume = standardizeVolume(
            newAliquot.volume,
            volumetricUnitCode
          );
          if (wellVolume > maxWellVolume) {
            p.__importFailed = concatWarningStrs(
              p.__importFailed,
              `Location ${well.location} volume is greater than the max volume of ${maxVolume} ${volumetricUnitCode}`
            );
            return false;
          }
        }
        aliquotsToCreate.push(newAliquot);
      }
    }
    return true;
  });

  const aliquotCidToId = {};

  if (aliquotsToCreate.length) {
    await upsertHandlers.ALIQUOT(
      {
        ...rest,
        model: "aliquot",
        recordsToImport: aliquotsToCreate,
        upsertHandlers
      },
      ctx
    );
    aliquotsToCreate.forEach(a => {
      if (a.id && a.cid) {
        aliquotCidToId[a.cid] = a.id;
      }
    });
  }

  recordsToContinueUpserting = recordsToContinueUpserting.filter(p => {
    const failed = p.contents.some(c => {
      const failure = c.aliquot && c.aliquot.__importFailed;
      if (failure) {
        p.__importFailed = concatWarningStrs(
          p.__importFailed,
          `Location ${c.location}: ` + failure
        );
        return true;
      } else {
        return false;
      }
    });
    return !failed;
  });
  if (!recordsToContinueUpserting.length) {
    return;
  }

  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      convertUserFacingToDbModel: r => {
        const { containerArrayType, aliquotContainerType } = r;
        if (!r.id) {
          // only allow nested wells on create
          r.aliquotContainers = [];
          r.contents.forEach(well => {
            const { location, barcode, aliquot } = well;
            const { rowPosition, columnPosition } =
              getPositionFromAlphanumericLocation(
                location,
                containerArrayType.containerFormat
              );
            let aliquotId;
            if (aliquot) {
              if (aliquot.id) {
                aliquotId = aliquot.id;
              } else if (aliquot.cid) {
                const maybeId = aliquotCidToId[aliquot.cid];
                if (maybeId) aliquotId = maybeId;
              }
            }
            r.aliquotContainers.push({
              rowPosition,
              columnPosition,
              aliquotId,
              aliquotContainerTypeCode: aliquotContainerType.code,
              ...(barcode && {
                barcode: {
                  barcodeString: barcode
                }
              })
            });
          });
          if (containerArrayType.isPlate) {
            r.aliquotContainers = generateContainerArray(
              r.aliquotContainers,
              containerArrayType.containerFormat,
              {
                aliquotContainerTypeCode: aliquotContainerType.code
              }
            );
          }
          r.containerArrayTypeId = r.containerArrayType.id;
        }
        delete r.plateType;
        delete r.tubeType;
        delete r.contents;
        delete r.containerArrayType;
        delete r.aliquotContainerType;

        if (r.barcode) {
          r.barcode = {
            barcodeString: r.barcode
          };
        }
        return r;
      },
      model: "containerArray"
    },
    ctx
  );
  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport,
      modelOrFragment: "containerArray"
    },
    ctx
  );
}
