/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { startCase, lowerCase } from "lodash";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import "./style.css";
import { cascadeCustomerRequestTagsToJobsAndWorkflow } from "../../Record/CustomerRequestRecordView/utils";
import gql from "graphql-tag";
import JobRecordView from "../../Record/JobRecordView";

const schema = {
  model: "job",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "priorityTypeCode",
      type: "string",
      displayName: "Priority",
      render: v => startCase(lowerCase(v))
    },
    { path: "dueDate", type: "timestamp", displayName: "Due Date" },
    {
      path: "startDate",
      type: "timestamp",
      displayName: "Start Date",
      isHidden: true
    },
    {
      path: "user.username",
      field: "username",
      displayName: "Owner",
      isHidden: true
    }
  ]
};

const afterTag = async ({ records, selectedTags }) => {
  await cascadeCustomerRequestTagsToJobsAndWorkflow({
    jobIds: records.map(r => r.id),
    selectedTags
  });
};

const JobLibrary = props => {
  return (
    <AbstractLibrary
      {...props}
      RecordViewInspector={JobRecordView}
      afterTag={afterTag}
      onNewItemClick={() => {
        showDialog({
          modalType: "LaunchNewJobDialog"
        });
      }}
      withExport
      libraryName="job"
    />
  );
};

const fragment = gql`
  fragment jobLibraryFragment on job {
    id
    name
    startDate
    dueDate
    priorityTypeCode
  }
`;

export default libraryEnhancer({
  fragment,
  schema
})(JobLibrary);
