/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  collectExtendedProperties,
  recordExtendedValuesString
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import SelectReactionMapEntities, {
  getItemTypeAndFilterForReactionMaps
} from "../../../../../src-shared/SelectReactionMapEntities";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import fieldConstants from "../fieldConstants";
import { throwFormError } from "../../../../../src-shared/utils/formUtils";
import { libraryExtendedStringValues } from "../../../../../src-shared/libraryEnhancer";

export const materialFragment = [
  "material",
  "id name materialType { code name }"
];
export const reactionMapFragment = ["reactionMap", "id name"];
class SelectMaterials extends Component {
  state = {
    loading: false
  };

  beforeNextStep = async values => {
    const {
      reactionMaps = [],
      materials = [],
      materialsFromReactionMaps = [],
      [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities,
      [fieldConstants.reactionEntityType]: reactionEntityType
    } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    if (!reactionMaps.length && !materials.length) {
      throwFormError("Please select materials or reaction maps");
    }
    try {
      const materialIds = materials.map(m => m.id);

      if (selectAllReactionEntities) {
        const { additionalFilter } = getItemTypeAndFilterForReactionMaps({
          reactionEntityType,
          reactionMaps
        });
        const items = await safeQuery(["material", "id"], {
          variables: {
            filter: additionalFilter
          }
        });
        if (!items.length) {
          return window.toastr.error(
            `Could not find any ${reactionEntityType} on reaction maps.`
          );
        }
        materialIds.push(...items.map(i => i.id));
      } else {
        materialIds.push(...materialsFromReactionMaps.map(i => i.id));
      }

      const materialsWithExtProps = await safeQuery(
        [
          "material",
          `id name materialType { code name } ${recordExtendedValuesString} ${libraryExtendedStringValues}`
        ],
        {
          variables: {
            filter: {
              id: materialIds
            }
          }
        }
      );
      const matProps = {};
      materialsWithExtProps.forEach(material => {
        collectExtendedProperties(material, matProps);
      });
      change("allMaterials", materialsWithExtProps);
      change("existingProps", Object.values(matProps));
      nextStep();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error fetching materials");
    }
  };

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      stepFormProps: { change } = {},
      Footer,
      reactionMaps,
      toolSchema,
      footerProps,
      handleSubmit
    } = this.props;

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Materials"
            helper="Select materials for updating"
            width="100%"
          />
          <GenericSelect
            {...{
              name: "materials",
              buttonProps: {
                loading: isLoadingMap.materials,
                disabled: isDisabledMap.materials
              },
              isMultiSelect: true,
              schema: [
                "name",
                {
                  displayName: "Type",
                  path: "materialType.name"
                },
                dateModifiedColumn
              ],
              fragment: materialFragment,
              postSelectDTProps: {
                formName: "materialsToChangeProps",
                schema: [
                  "name",
                  {
                    displayName: "Type",
                    path: "materialType.name"
                  }
                ]
              }
            }}
          />
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Reaction Maps"
            helper="Select reaction maps containing materials you would like to update"
            width="100%"
          />
          <div>
            <GenericSelect
              {...{
                name: "reactionMaps",
                buttonProps: {
                  loading: isLoadingMap.reactionMaps,
                  disabled: isDisabledMap.reactionMaps
                },
                isMultiSelect: true,
                schema: ["name", dateModifiedColumn],
                fragment: reactionMapFragment,
                postSelectDTProps: {
                  formName: "reactionMapsForMaterials",
                  schema: ["name"]
                }
              }}
            />
            <SelectReactionMapEntities
              onlyMaterials
              getFieldName={() => "materialsFromReactionMaps"}
              change={change}
              toolSchema={toolSchema}
              fieldConstants={fieldConstants}
              reactionMaps={reactionMaps}
              modelNameToSchema={{
                material: ["name"]
              }}
              modelNameToFragment={{
                material: ["material", "id name materialType { code name }"]
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues("reactionMaps")(SelectMaterials);
