/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import withQuery from "../../withQuery";

import Tag from "../../Tag";
import { tagModels } from "../../../../tg-iso-shared/constants";
import { getSortedTaggedItems } from "../../utils/tagUtils";
import { Tooltip, Button } from "@blueprintjs/core";
import { showDialog } from "../../GlobalDialog";
import { taggedItemFragment } from "../../../../tg-iso-shared/src/fragments/taggedItemFragment";

class RecordTags extends React.Component {
  showUpdateTags = () => {
    const { model, recordId, refetchTaggedItems, afterTag } = this.props;
    showDialog({
      modalType: "TAG_ITEM",
      modalProps: {
        records: [{ id: recordId, __typename: model }],
        needsInitialize: true,
        refetch: refetchTaggedItems,
        afterTag
      }
    });
  };

  render() {
    const { model, taggedItems, taggedItemsLoading, readOnly } = this.props;
    if (!tagModels.includes(model)) {
      // doesn't have tags
      return null;
    }
    const fakeRecord = {
      taggedItems,
      __typename: model
    };
    const sortedTaggedItems = getSortedTaggedItems(fakeRecord);
    // placeholder during loader
    if (taggedItemsLoading) return <div style={{ height: 40 }} />;
    return (
      <div className="tg-flex align-center">
        {!readOnly && (
          <Tooltip content="Update tags" disabled={window.Cypress}>
            <Button
              icon="tag"
              className="tg-tag-record-button"
              minimal
              intent="warning"
              onClick={this.showUpdateTags}
            />
          </Tooltip>
        )}
        <div style={{ marginTop: 8, marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {sortedTaggedItems.map(({ id, tag, tagOption }) => {
              return (
                <Tag
                  key={id}
                  style={{ marginRight: 3, marginTop: 5 }}
                  {...(tagOption || tag)}
                  name={`${
                    tag.name + (tagOption ? `: ${tagOption.name}` : "")
                  }`}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export const tagQueryOptions = ({ recordId, model }) => {
  return {
    variables: {
      pageSize: 400,
      filter: {
        [model + "Id"]: recordId
      }
    }
  };
};

export default withQuery(taggedItemFragment, {
  isPlural: true,
  options: tagQueryOptions,
  skip: props => !tagModels.includes(props.model)
})(RecordTags);
