/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { Classes } from "@blueprintjs/core";
import { InputField, DialogFooter } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { wrapDialog } from "@teselagen/ui";
import { safeUpsert } from "../apolloMethods";
import { withProps } from "recompose";

class RenameLibraryItemDialog extends React.PureComponent {
  onSubmit = async values => {
    const { hideModal, record, libraryName } = this.props;

    try {
      await safeUpsert([record.__typename, "id name"], {
        id: record.id,
        name: values.name
      });
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error renaming ${modelNameToReadableName(
          libraryName || record.__typename
        )}.`
      );
    }
  };

  render() {
    const { submitting, hideModal, handleSubmit } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="New Name" isRequired />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: `Rename ${modelNameToReadableName(
          props.libraryName || props.record.__typename,
          {
            upperCase: true
          }
        )}`
      };
    }
  }),
  withProps(props => {
    return {
      initialValues: {
        name: props.record.name
      }
    };
  }),
  reduxForm({
    form: "renameLibraryItemDialog"
  })
)(RenameLibraryItemDialog);
