/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import pluralize from "pluralize";
import stepFormValues from "../../../../src-shared/stepFormValues";

export const itemTypes = ["material", "reactionMap"];

export const reactionEntityOptions = ["Input Materials", "Output Materials"];

export const withItemProps = stepFormValues(
  ...itemTypes.map(model => pluralize(model))
);
