/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  InputField,
  DialogFooter,
  TextareaField,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class CreateVendorDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop } = this.props;
    const { id: updatedId, name, notes, webAddress } = values;
    try {
      await safeUpsert(["vendor", "id name notes webAddress"], {
        id: updatedId,
        name,
        notes,
        webAddress
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating vendor.");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            placeholder="Enter vendor name..."
          />
          <InputField
            name="webAddress"
            label="Web Address"
            placeholder="Enter web address..."
          />
          <TextareaField
            name="notes"
            label="Notes"
            placeholder="Enter notes..."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreateVendorWithDialog = compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id") ? "Edit Vendor" : "Create Vendor"
      };
    }
  }),
  reduxForm({
    form: "createVendor"
  }),
  withQuery(["vendor", "id name notes webAddress"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(CreateVendorDialog);

export default CreateVendorWithDialog;
