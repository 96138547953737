/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import { reduxForm } from "redux-form";
import { DialogFooter, InputField } from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { wrapDialog } from "@teselagen/ui";

class SchematicElementNameDialog extends React.Component {
  onSubmit = async values => {
    try {
      const { initialValues, hideModal, type } = this.props;

      const trimmedName = (values.name || "").trim();
      if (type === "element") {
        await safeUpsert(["schematicElement", "id name"], {
          id: initialValues.id,
          name: trimmedName || "Part"
        });
      } else if (type === "set") {
        await safeUpsert(["schematicSet", "id name"], {
          id: initialValues.id,
          name: trimmedName || "Bin"
        });
      } else if (type === "backbone") {
        await safeUpsert(["schematicSet", "id name"], {
          id: initialValues.id,
          name: trimmedName || "Backbone"
        });
      }

      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error changing element name.");
    }
  };

  render() {
    const { hideModal, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="bp3-dialog-body">
          <InputField name="name" label="Name" />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" />
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  const requiredProperties = ["name"];

  requiredProperties.forEach(property => {
    if (!values[property]) errors[property] = "Required";
  });

  return errors;
};

export default compose(
  wrapDialog(props => {
    const { initialValues = {}, type } = props;
    const typeNameMap = {
      element: "Element",
      set: "Bin",
      backbone: "Backbone"
    };
    const typeName = typeNameMap[type] || "";

    return {
      title: `${
        initialValues.name.length === 0 ? "Add" : "Edit"
      } ${typeName} Name`,
      style: { width: 550 }
    };
  }),
  reduxForm({
    form: "schematicEditNameDialogForm", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(SchematicElementNameDialog);
