/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import commentFragment from "./commentFragment";

export default gql`
  fragment commentReplyFragment on commentReply {
    id
    commentId
    reply {
      ...commentFragment
    }
  }
  ${commentFragment}
`;
