/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import shortid from "shortid";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { sortAliquotContainers } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

export function makeWorklistTransfers({
  destinationPlates,
  sourceAliquotContainerList,
  destinationTransferOrder,
  getTransferVolumeFields,
  canTransferFrom,
  isTubeTransfer
}) {
  const transfers = [];

  destinationPlates.forEach(plate => {
    const sortedAliquotContainers = sortAliquotContainers(
      plate.aliquotContainers,
      destinationTransferOrder
    );
    for (const ac of sortedAliquotContainers) {
      if (isTubeTransfer) {
        if (ac.id) continue;
        if (!sourceAliquotContainerList.length) break;
        const sourceAc = sourceAliquotContainerList.shift();
        transfers.push({
          aliquotContainerId: sourceAc.id,
          sourceAliquotContainer: sourceAc,
          sourceContainerArrayId: sourceAc.containerArray.id,
          sourceColumnPosition: sourceAc.columnPosition,
          sourceRowPosition: sourceAc.rowPosition,
          destinationContainerArrayId: plate.id || `&${plate.cid}`,
          destinationContainerArray: plate,
          destinationRackName: plate.name,
          destinationColumnPosition: ac.columnPosition,
          destinationRowPosition: ac.rowPosition
        });
      } else {
        if (ac.aliquot) continue;
        if (!sourceAliquotContainerList.length) break;
        const sourceAc = sourceAliquotContainerList.shift();
        // add cids to the newly created plates wells
        if (!ac.id && !ac.cid) {
          ac.cid = shortid();
        }
        if (!canTransferFrom(sourceAc)) {
          const plateName = get(sourceAc, "containerArray.name");
          if (!plateName) {
            throwFormError(
              `There is not enough volume in the source well with position ${getAliquotContainerLocation(
                sourceAc
              )}.`
            );
          }
        }
        transfers.push({
          ...getTransferVolumeFields(sourceAc),
          sourceAliquotContainerId: sourceAc.id,
          sourceAliquotContainer: sourceAc,
          destinationPlateName: plate.name,
          destinationAliquotContainer: ac,
          destinationAliquotContainerId: ac.id || `&${ac.cid}`
        });
      }
    }
  });

  return transfers;
}

export function makeWorklistTransferWithValidationPlateMapGroup({
  sourceAliquotContainerList,
  validationPlateMapGroup,
  canTransferFrom,
  getTransferVolumeFields,
  existingPlateLocations,
  toBeCreatedDestinationPlates,
  destinationPlates = [],
  destinationPlateTypes,
  isTubeTransfer
}) {
  const isUsingExistingDestinations = !!existingPlateLocations;
  const plateMapLocationMapForGeneration = makePlateMapLocationMap(
    sourceAliquotContainerList,
    validationPlateMapGroup
  );

  const transfers = [];
  validationPlateMapGroup.plateMaps.forEach((plateMap, i) => {
    const plateMapType = plateMap.type;
    plateMap.plateMapItems.forEach(plateMapItem => {
      const {
        inventoryItem,
        rowPosition,
        columnPosition,
        volume,
        volumetricUnitCode
      } = plateMapItem;
      const item = inventoryItem && inventoryItem[plateMapType];
      if (item) {
        const volumeOverrides = {
          volume,
          volumetricUnitCode
        };
        const sourceAcList = plateMapLocationMapForGeneration[item.id] || [];
        // we don't care about missing materials. We only care that there is enough volume for the materials
        // specified in the source list.
        if (!sourceAcList.length) return;
        let acToTransferFrom;
        for (const sourceAc of sourceAcList) {
          if (
            isTubeTransfer
              ? sourceAc.id
              : canTransferFrom(sourceAc, volumeOverrides)
          ) {
            acToTransferFrom = sourceAc;
            break;
          }
        }
        if (!acToTransferFrom) {
          throwFormError(
            `There is not enough volume in the source containers containing the ${modelNameToReadableName(
              plateMapType
            )} ${item.name}.`
          );
        }

        const destinationPlateToUse = destinationPlates.length
          ? destinationPlates[i]
          : toBeCreatedDestinationPlates[i];
        if (isTubeTransfer) {
          let shouldTransfer = true;
          if (destinationPlates.length) {
            const acLocation =
              existingPlateLocations[i][
                getAliquotContainerLocation({ rowPosition, columnPosition })
              ];
            const ac = acLocation?.aliquotContainer;
            if (ac?.id) {
              // there is already a tube at location
              shouldTransfer = false;
              const itemId = getAcItemIdOfType(ac, plateMapType);
              if (itemId !== item.id) {
                return throwFormError(
                  `There is already a tube at destination location ${getAliquotContainerLocation(
                    {
                      rowPosition,
                      columnPosition,
                      containerArray: destinationPlateToUse
                    }
                  )}.`
                );
              } else if (itemId === item.id) {
                if (!transfers.warnings) {
                  transfers.warnings = [];
                }
                transfers.warnings.push(
                  `There is already a tube with the same ${modelNameToReadableName(
                    plateMapType
                  )} ${
                    item.name
                  } at destination location ${getAliquotContainerLocation({
                    rowPosition,
                    columnPosition,
                    containerArray: destinationPlateToUse
                  })}.`
                );
              }
            }
          }
          if (shouldTransfer) {
            transfers.push({
              aliquotContainerId: acToTransferFrom.id,
              sourceAliquotContainer: acToTransferFrom,
              sourceContainerArrayId: acToTransferFrom.containerArray.id,
              sourceColumnPosition: acToTransferFrom.columnPosition,
              sourceRowPosition: acToTransferFrom.rowPosition,
              destinationRackName: destinationPlateToUse.name,
              destinationContainerArrayId:
                destinationPlateToUse.id || `&${destinationPlateToUse.cid}`,
              destinationColumnPosition: columnPosition,
              destinationRowPosition: rowPosition
            });
          }
        } else {
          let destAc;
          let destinationPlateName;

          if (isUsingExistingDestinations) {
            const acLocation =
              existingPlateLocations[i][
                getAliquotContainerLocation({ rowPosition, columnPosition })
              ];
            destAc = acLocation.aliquotContainer;
            destinationPlateName = acLocation.plateName;
          } else {
            const cid = shortid();
            const simpleDestAc = {
              cid,
              rowPosition,
              columnPosition,
              aliquotContainerTypeCode:
                destinationPlateTypes[i].aliquotContainerTypeCode
            };
            toBeCreatedDestinationPlates[i].aliquotContainers.push(
              simpleDestAc
            );
            destinationPlateName = toBeCreatedDestinationPlates[i].name;
            destAc = {
              ...simpleDestAc,
              containerArrayType:
                toBeCreatedDestinationPlates[i].containerArrayType
            };
          }

          transfers.push({
            ...getTransferVolumeFields(acToTransferFrom, volumeOverrides),
            sourceAliquotContainerId: acToTransferFrom.id,
            sourceAliquotContainer: acToTransferFrom,
            destinationPlateName,
            destinationAliquotContainer: destAc,
            destinationAliquotContainerId: destAc.id || `&${destAc.cid}`
          });
        }
      }
    });
  });

  return transfers;
}

export function getAcItemOfType(ac, plateMapType) {
  if (plateMapType === "material") {
    return get(ac, "aliquot.sample.material");
  } else if (plateMapType === "sample") {
    return get(ac, "aliquot.sample");
  } else if (plateMapType === "additiveMaterial" || plateMapType === "lot") {
    const additives = ac.aliquot ? ac.aliquot.additives : ac.additives;
    if (additives.length === 1) {
      const additive = additives[0];
      if (plateMapType === "additiveMaterial") {
        const additiveMaterial =
          additive.additiveMaterial || get(additive, "lot.additiveMaterial");
        return additiveMaterial;
      } else {
        return additive.lot;
      }
    }
  }
}

function getAcItemIdOfType(ac, plateMapType) {
  const item = getAcItemOfType(ac, plateMapType);
  return item && item.id;
}

/**
 * This will create a map from the source item specified by the plate map (material, sample, etc.)
 * to an array of aliquot containers where that item appears.
 * @param {*} aliquotContainers
 * @param {*} validationPlateMapGroup
 */
export function makePlateMapLocationMap(
  aliquotContainers,
  validationPlateMapGroup
) {
  const plateMapType = get(validationPlateMapGroup, "plateMaps[0].type");
  if (!validationPlateMapGroup || !plateMapType) return {};
  const map = {};
  aliquotContainers.forEach(ac => {
    const itemId = getAcItemIdOfType(ac, plateMapType);
    if (itemId) {
      if (!map[itemId]) map[itemId] = [];
      map[itemId].push(ac);
    }
  });
  return map;
}
