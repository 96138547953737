/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState } from "react";
import { Callout, Classes, Tab, Tabs } from "@blueprintjs/core";
import {
  CheckboxField,
  DialogFooter,
  InfoHelper,
  InputField,
  ReactSelectField,
  showConfirmationDialog,
  TextareaField
} from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { flatMap, forEach, keyBy, pick, startCase } from "lodash";
import { compose } from "recompose";
import { wrapDialog } from "@teselagen/ui";
import pluralize from "pluralize";
import TableSelect from "../../TableSelect";
import { hideDialog } from "../../GlobalDialog";
import { getFeatureTypes } from "@teselagen/sequence-utils";
import { computeSequenceHash } from "../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { safeDelete, safeQuery, safeUpsert } from "../../apolloMethods";
import { getSequenceWithinRange } from "@teselagen/range-utils";
import { useButtonTabs } from "../../useButtonTabs";
import { useImportPanel, onSubmitImport } from "./Import";
import GenericSelect from "../../GenericSelect";
import { AutoAnnotateBpMatchingDialog } from "./AutoAnnotateBpMatchingDialog";
import { showStackedDialog } from "../../StackedDialog";
import sIfPlural from "../../utils/sIfPlural";
import {
  getReverseComplementSequenceString,
  bioData
} from "@teselagen/sequence-utils";
import { getSequence } from "../../../../tg-iso-shared/src/utils/getSequence";
import { annotationSizeStartEndColumns } from "../../utils/libraryColumns";

const { ambiguous_dna_values } = bioData;
const minRegisteredAnnotationLength = 5;

const fragment = ["registeredAnnotationGroup", "id name updatedAt"];

export const EditRegisteredAnnotationDialog = compose(
  wrapDialog(p => ({
    title: `Edit ${startCase(p.annotationType)}`
  })),
  reduxForm({
    form: "EditRegisteredAnnotationDialog"
  })
)(({
  handleSubmit,
  submitting,
  refetch,
  hideModal,
  annotationType,
  initialValues
}) => {
  const registeredAnnotationTypeCode = annotationType.toUpperCase();

  async function onSubmit({
    selectedAnnotationGroups,
    name,
    description,
    sequence,
    type,
    isRegex
  }) {
    try {
      await safeDelete("registeredAnnotation", initialValues.id);
      const numEdited = await createRegisteredAnnotations({
        refetch,
        hideModal,
        annotationType,
        selectedAnnotationGroups,
        registeredAnnotationTypeCode,
        annotationsToRegister: [
          {
            name,
            description,
            sequence,
            isRegex,
            type
          }
        ]
      });
      numEdited && window.toastr.success("Edit Successful");
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating " + annotationType);
    }
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <CreateOrEditAnnotation annotationType={annotationType} />
        <GroupSelect annotationType={annotationType} />
      </div>
      <DialogFooter
        hideModal={hideDialog}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
});

const CreateOrEditAnnotation = tgFormValues("isRegex")(({
  annotationType,
  isRegex
}) => {
  return (
    <div>
      <Callout intent="primary" style={{ margin: "10px 0" }}>
        Manually Add a "Canonical" {startCase(annotationType)}
      </Callout>
      <InputField isRequired label="Name" name="name"></InputField>
      <TextareaField label="Description" name="description"></TextareaField>
      {annotationType === "feature" && (
        <ReactSelectField
          label="Type"
          name="type"
          options={getFeatureTypes()}
          isRequired
        ></ReactSelectField>
      )}
      <TextareaField
        isRequired
        label={
          <div
            style={{
              maxHeight: 25,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <span> {isRegex ? "Regex" : "Base Pairs"}</span> &nbsp;{" "}
            <CheckboxField
              name="isRegex"
              label={
                <div style={{ display: "flex" }}>
                  Use Regex Instead of IUPAC &nbsp;
                  <InfoHelper
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      showStackedDialog({
                        ModalComponent: AutoAnnotateBpMatchingDialog
                      });
                    }}
                    content={
                      isRegex ? (
                        <span>
                          Any valid regexes allowed. Click for more info about
                          regex matching
                        </span>
                      ) : (
                        `All valid IUPAC bases allowed as well as a couple special characters. Click for more info`
                      )
                    }
                  ></InfoHelper>
                </div>
              }
            />
          </div>
        }
        validate={isRegex ? validateRegex : validateIUPACBasePairs}
        normalize={parseBasePairs}
        name="sequence"
      ></TextareaField>
    </div>
  );
});

export default compose(
  wrapDialog(p => ({
    title: `Register ${pluralize(startCase(p.annotationType))}`
  })),
  reduxForm({
    form: "RegisterAnnotationDialog"
  })
)(({
  handleSubmit,
  submitting,
  refetch,
  hideModal,
  annotationTableProps,
  sequenceTableProps,
  tabId,
  buttonId,
  annotationType,
  showLinkInToast
}) => {
  const registeredAnnotationTypeCode = annotationType.toUpperCase();
  const [selectedTabId, setSelectedTabId] = useState(tabId || "register-new");
  const { tabs, selectedTabId: activeButton } = useButtonTabs(
    ["Annotations", "Sequences"],
    { tabId: buttonId }
  );
  const { panel: importPanel, importType } = useImportPanel({
    annotationType
  });

  const toastrOpts = showLinkInToast
    ? {
        link: `/settings/${annotationType}-management`,
        linkText: `View Registered ${startCase(annotationType)}s`
      }
    : undefined;
  async function onSubmitExisting({
    selectedAnnotationGroups,
    selectedAnnotations,
    selectedSequences
  }) {
    try {
      //we need to query for the underlying sequence bps
      let annsToUse;
      let ignoreInputDups = false;
      if (selectedAnnotations) {
        //selecting features
        const sequenceIds = selectedAnnotations.map(s => s.sequenceId);
        const sequences = await safeQuery(
          [
            "sequence",
            /* GraphQL */ `
              {
                id
                sequenceFragments {
                  id
                  fragment
                  index
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: sequenceIds
              }
            }
          }
        );
        const keyed = {};
        sequences.forEach(s => {
          keyed[s.id] = getSequence(s);
        });
        annsToUse = selectedAnnotations.map(annot => {
          const sequenceBps = keyed[annot.sequenceId];
          return {
            ...annot,
            sequence: getSeq(annot, sequenceBps)
          };
        });
      } else if (selectedSequences) {
        ignoreInputDups = true;
        const sequenceNestKey =
          annotationType === "feature" ? "sequenceFeatures" : "parts";
        const sequencesWithAnnots = await safeQuery(
          [
            "sequence",
            /* GraphQL */ `
              {
                id
                sequenceFragments {
                  id
                  fragment
                  index
                }
                ${sequenceNestKey} {
                  id
                  name
                  start
                  end
                  ${annotationType === "feature" ? "type" : ""}
                  strand
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: selectedSequences.map(s => s.id)
              }
            }
          }
        );
        annsToUse = [];
        sequencesWithAnnots.forEach(s => {
          const sequenceBps = getSequence(s);
          annsToUse = annsToUse.concat(
            s[sequenceNestKey].map(annot => {
              return {
                ...annot,
                sequence: getSeq(annot, sequenceBps)
              };
            })
          );
        });
        if (!annsToUse) {
          return window.toastr.warning(
            "No annotations found on the selected sequence(s)"
          );
        }
      }

      //if we don't have the sequences for the selected features get it
      const numCreated = await createRegisteredAnnotations({
        refetch,
        selectedAnnotationGroups,
        ignoreInputDups,
        annotationType,
        registeredAnnotationTypeCode,
        annotationsToRegister: annsToUse,
        hideModal
      });
      numCreated &&
        window.toastr.success(
          `Registration Successful. Created ${numCreated} New ${startCase(
            annotationType
          )}${numCreated > 1 ? "s" : ""}`,
          toastrOpts
        );
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error Registering Existing Annotations");
    }
  }
  async function onSubmitNew({
    selectedAnnotationGroups,
    name,
    description,
    sequence,
    type,
    isRegex
  }) {
    try {
      const numCreated = await createRegisteredAnnotations({
        refetch,
        hideModal,
        annotationType,
        selectedAnnotationGroups,
        registeredAnnotationTypeCode,
        annotationsToRegister: [
          {
            isRegex,
            name,
            description,
            sequence,
            type
          }
        ]
      });
      numCreated &&
        window.toastr.success(
          `Registration Successful. Created ${numCreated} New ${startCase(
            annotationType
          )}${numCreated > 1 ? "s" : ""}`,
          toastrOpts
        );
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating " + annotationType);
    }
  }

  const onSubmitMap = {
    "register-existing": onSubmitExisting,
    "register-import": onSubmitImport({
      annotationType,
      createRegisteredAnnotations,
      refetch,
      importType,
      hideModal,
      registeredAnnotationTypeCode
    }),
    "register-new": onSubmitNew
  };
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Tabs
          selectedTabId={selectedTabId}
          onChange={t => setSelectedTabId(t)}
          renderActiveTabPanelOnly
        >
          <Tab
            title="Register New"
            id="register-new"
            panel={<CreateOrEditAnnotation annotationType={annotationType} />}
          />
          <Tab
            title="Register Existing"
            id="register-existing"
            panel={
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: 10 }}>Select From:</div> {tabs}
                </div>
                {activeButton === "Annotations" ? (
                  <div>
                    <Callout intent="primary" style={{ margin: "10px 0" }}>
                      Select individual {pluralize(annotationType)} from
                      sequences to register them
                    </Callout>
                    <TableSelect
                      isRequired
                      {...{
                        ...annotationTableProps,
                        name: "selectedAnnotations",
                        isMultiSelect: true,
                        schema: [
                          "name",
                          "updatedAt",
                          ...annotationSizeStartEndColumns,
                          { path: "id", isHidden: true },
                          { path: "sequenceId", isHidden: true }
                        ],
                        fragment: [
                          annotationType === "feature"
                            ? "sequenceFeature"
                            : "part",
                          `id start end strand name updatedAt sequence {
                            id size
                          } sequenceId ${
                            annotationType === "feature" ? "type" : ""
                          }`
                        ]
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div style={{ margin: "10px 0" }}>
                      Select any sequences to register all{" "}
                      {pluralize(annotationType)} on the sequence
                    </div>

                    <TableSelect
                      isRequired
                      {...{
                        ...sequenceTableProps,
                        name: "selectedSequences",
                        isMultiSelect: true,
                        schema: [
                          "name",
                          "updatedAt",
                          { path: "id", isHidden: true }
                        ],
                        // schema: ["name", "data", "weather1", "weather2", "weather3", "weather4"],
                        fragment: ["sequence", `id name`]
                      }}
                    />
                  </div>
                )}
              </div>
            }
          />
          <Tab title="Import" id="register-import" panel={importPanel} />
        </Tabs>
        <GroupSelect annotationType={annotationType} />
      </div>
      <DialogFooter
        hideModal={hideDialog}
        submitting={submitting}
        onClick={handleSubmit((...args) => {
          return onSubmitMap[selectedTabId](...args);
        })}
      />
    </>
  );
});

function parseBasePairs(bpText) {
  let parsedBp = "";
  if (typeof bpText === "string") {
    parsedBp = bpText.replace(/\s/g, "");
  }
  return parsedBp;
}

function validateRegex(bpText) {
  try {
    new RegExp(bpText);
  } catch (error) {
    return "Invalid Regex Statement Detected";
  }
}

const acceptedApeLikeBps = {
  ...ambiguous_dna_values,
  "#": true,
  ">": true,
  "<": true,
  ")": true,
  "(": true
};
function validateIUPACBasePairs(bpText = "") {
  let index = 0;
  for (const char of bpText) {
    if (!acceptedApeLikeBps[char.toUpperCase()]) {
      return `Invalid character ${char} detected at index: ${index}`;
    }
    index++;
  }
  if (bpText.length < minRegisteredAnnotationLength) {
    return `Must have a length of at least ${minRegisteredAnnotationLength} bps`;
  }
}

function GroupSelect({ annotationType }) {
  return (
    <GenericSelect
      {...{
        reactSelectProps: {
          creatable: true,
          noResultsText: "Type to Create A Group..."
        },
        isRequired: true,
        name: "selectedAnnotationGroups",
        isMultiSelect: true,

        asReactSelect: true,
        fragment,
        label: <span>Add to Group(s):</span>,
        // additionalDataFragment,
        additionalFilter: (props, qb) => {
          qb.whereAll({
            registeredAnnotationTypeCode: annotationType.toUpperCase()
          });
        }
      }}
    />
  );
}

async function createRegisteredAnnotations({
  annotationsToRegister,
  registeredAnnotationTypeCode,
  refetch,
  selectedAnnotationGroups,
  annotationType,
  // ignoreInputDups,
  hideModal
}) {
  const hashList = {};
  const dupList = [];
  annotationsToRegister.forEach(a => {
    a.registeredAnnotationTypeCode = registeredAnnotationTypeCode;
    const hash = computeSequenceHash(
      a.name + a.type + registeredAnnotationTypeCode + a.sequence,
      "REGISTERED_ANN" // registered annotation models are not part of the sequence model.
    );

    if (hashList[hash]) {
      dupList.push([a, hashList[hash]]);
    } else {
      hashList[hash] = a;
    }
  });

  const existingAnns = await safeQuery(
    [
      "registeredAnnotation",
      `id name hash registeredAnnotationToGroups { id registeredAnnotationGroupId } `
    ],
    {
      variables: {
        filter: {
          hash: Object.keys(hashList)
        }
      }
    }
  );

  existingAnns.forEach(e => {
    delete hashList[e.hash];
  });

  if (existingAnns.length) {
    window.toastr.warning(
      `${existingAnns.map(a => a.name).join(", ")} ${
        existingAnns.length > 1 ? "are" : "is"
      } already registered (with the same type and base pairs).`
    );
  }

  let annsToCreate = Object.values(hashList);

  const registeredAnnotationToGroups = [];
  if (selectedAnnotationGroups) {
    const groupsToCreate = [];
    let allGroups = [];
    forEach(selectedAnnotationGroups, g => {
      if (!g.id) {
        groupsToCreate.push({
          name: g.label || g.name,
          registeredAnnotationTypeCode
        });
      } else {
        allGroups.push(g);
      }
    });
    if (groupsToCreate.length) {
      //create any new groups
      const newGroups = await safeUpsert(
        "registeredAnnotationGroup",
        groupsToCreate
      );
      allGroups = allGroups.concat(newGroups);
    }
    forEach(allGroups, g => {
      registeredAnnotationToGroups.push({
        registeredAnnotationGroupId: g.id
      });
    });

    const newJoins = [];
    existingAnns.forEach(ann => {
      const annGroupsById = keyBy(
        ann.registeredAnnotationToGroups,
        "registeredAnnotationGroupId"
      );
      allGroups.forEach(g => {
        if (!annGroupsById[g.id]) {
          newJoins.push({
            registeredAnnotationId: ann.id,
            registeredAnnotationGroupId: g.id
          });
        }
      });
    });
    if (newJoins.length) {
      await safeUpsert('"registeredAnnotationToGroup', newJoins);
    }
  }
  const lengthWarnings = [];
  annsToCreate = flatMap(annsToCreate, ann => {
    if (ann.sequence.length < minRegisteredAnnotationLength) {
      lengthWarnings.push(ann.name);
      return [];
    }
    return {
      ...pick(ann, [
        "name",
        "sequence",
        "type",
        "isRegex",
        "description",
        "registeredAnnotationTypeCode"
      ]),
      registeredAnnotationToGroups
    };
  });

  if (lengthWarnings.length) {
    const shouldContinue = await showConfirmationDialog({
      cancelButtonText: "Stop Registration",
      text: (
        <div>
          Detected {lengthWarnings.length} annotation{sIfPlural(lengthWarnings)}{" "}
          shorter than {minRegisteredAnnotationLength} bases:
          <br></br>
          <br></br>
          {lengthWarnings.map((w, i) => (
            <div key={i}>{w}</div>
          ))}
          <br></br>
          <br></br>
          These will need to be removed from the registration to continue.
        </div>
      )
    });
    if (!shouldContinue) {
      return false;
    }
  }
  if (!annsToCreate.length) {
    window.toastr.warning(
      `No valid ${annotationType}s were able to be created`
    );
    return false;
  } else {
    await safeUpsert("registeredAnnotation", annsToCreate);
    await refetch();
    hideModal();
    return annsToCreate.length;
  }
}

function getSeq(annot, sequenceBps) {
  let seq = getSequenceWithinRange(annot, sequenceBps);
  if (annot.strand === -1) {
    seq = getReverseComplementSequenceString(seq);
  }
  return seq;
}
