/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "@teselagen/ui";
import { Button, Intent, Classes, Icon, Tooltip } from "@blueprintjs/core";
import { safeDelete } from "../../apolloMethods";
import { getValueExtendedPropertyValue } from "../../utils/extendedPropertyUtils";
import { mixedValueConstant } from "../utils";
import modelNameToReadableName from "../../utils/modelNameToReadableName";
import { showDeleteAlert } from "../../utils/deleteUtils";

const editColumn = {
  path: "edit",
  type: "action"
};
const deleteColumn = {
  path: "delete",
  type: "action"
};
const schema = [
  { path: "property", displayName: "Property" },
  { path: "value", displayName: "Value" }
];

const RecordExtendedPropertiesTable = props => {
  const {
    entities,
    loading,
    noEdit,
    noDelete,
    disableDelete,
    startEdit,
    onDelete,
    refetch,
    stopEdit,
    propertyToEdit = {},
    ...rest
  } = props;

  const cellRenderer = {
    edit: (__, record) => {
      return (
        <Button
          style={{ maxHeight: 30 }}
          className={Classes.MINIMAL}
          onClick={editProperty(record)}
          icon="edit"
        />
      );
    },
    delete: (__, record) => {
      return (
        <Button
          style={{ maxHeight: 30 }}
          disabled={disableDelete && disableDelete(record)}
          intent={Intent.DANGER}
          className={Classes.MINIMAL}
          onClick={deletePropertyValue(record)}
          icon="trash"
        />
      );
    },
    value: (__, record) => {
      const display = getValueExtendedPropertyValue(record);
      if (display === mixedValueConstant) {
        return (
          <Tooltip
            content={`Multiple ${modelNameToReadableName(record.__typename, {
              plural: true
            })} have this extended property but values differ.`}
          >
            <Icon intent="warning" icon="warning-sign" />
          </Tooltip>
        );
      } else {
        return display;
      }
    }
  };

  const editProperty = record => () => {
    startEdit(record);
  };

  const deletePropertyValue = record => async () => {
    // this means that we are embedded in a tool where properties are just getting added
    // temporarily so we don't want to show the alert
    if (onDelete) return onDelete(record);

    const proceed = await showDeleteAlert({
      model: record.__typename
    });
    if (!proceed) return;
    try {
      if (propertyToEdit.id === record.id) stopEdit();
      const idsToDelete = (record.otherExtValIds || []).concat(record._id);
      await safeDelete(record.__typename, idsToDelete);
      await refetch();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error deleting extended property value.");
    }
  };

  const schemaToUse = [...schema];
  if (!noEdit) schemaToUse.unshift(editColumn);
  if (!noDelete) schemaToUse.push(deleteColumn);

  return (
    <DataTable
      cellRenderer={cellRenderer}
      isLoading={loading}
      schema={schemaToUse}
      noSelect
      formName="RecordExtendedPropertiesTable"
      entities={entities}
      doNotShowEmptyRows
      withSearch={false}
      {...rest}
    />
  );
};

RecordExtendedPropertiesTable.propTypes = {
  /**
   * The prop injected by `withRecordExtendedProperties`. This where we extract
   * the extended properties from.
   */
  inventoryItems: PropTypes.array,

  refetch: PropTypes.func
};

export default RecordExtendedPropertiesTable;
