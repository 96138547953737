/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component, useState } from "react";
import { compose } from "redux";
import { FileUploadField, InfoHelper } from "@teselagen/ui";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import AddExtendedProperty from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/AddExtendedProperty";
import RecordExtendedPropertiesTable from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/RecordExtendedPropertiesTable";
import {
  getExistingPropTemplateRows,
  transformExtendedValues
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import { allowedCsvFileTypes } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { download } from "../../../../../src-shared/utils/downloadTest";
import { unparse } from "papaparse";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import RemoveExtendedPropertiesTable from "../../../../../src-shared/RemoveExtendedPropertiesTable";

const csvHeaders = ["plate name", "plate barcode", "well position"];
class ExtendedProperties extends Component {
  saveExtendedPropertyToForm = (val, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(fieldName, [...existingValues, val]);
  };

  removeExtendedProperty = (record, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(
      fieldName,
      existingValues.filter(
        val => val.extendedProperty.id !== record.extendedProperty.id
      )
    );
  };

  render() {
    const {
      existingProps = [],
      aliquotExtendedValues = [],
      containerArrays = [],
      propsForTemplate = []
    } = this.props;

    const aliquotExtendedValuesEntities = transformExtendedValues(
      aliquotExtendedValues
    );

    return (
      <React.Fragment>
        {!!existingProps.length && (
          <RemoveExtendedPropertiesTable
            existingProps={existingProps}
            model="aliquot"
          />
        )}
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Add Extended Properties to Aliquots"
            helper="Add extended properties to all aliquots selected in first step."
            width="100%"
          />
          <div style={{ maxWidth: 500 }}>
            <AddExtendedProperty
              model="aliquot"
              fullValueFields
              noLinkProperties
              formName="addExtendedPropertiesChangeAliquotPropsTool"
              extendedPropertyIdsToIgnore={aliquotExtendedValues.map(
                val => val.extendedProperty.id
              )}
              onSubmitValue={val =>
                this.saveExtendedPropertyToForm(val, "aliquot")
              }
            />
            {!!aliquotExtendedValuesEntities.length && (
              <div style={{ minHeight: 200, marginTop: 20 }}>
                <RecordExtendedPropertiesTable
                  isSimple
                  compact
                  noEdit
                  onDelete={record =>
                    this.removeExtendedProperty(record, "aliquot")
                  }
                  entities={aliquotExtendedValuesEntities}
                />
              </div>
            )}
          </div>
          {!!containerArrays.length && (
            <div style={{ marginTop: 25 }}>
              <h6>
                Or upload a CSV of properties to add/update specific aliquots.
              </h6>
              <div style={{ maxWidth: 250 }}>
                <GenericSelect
                  {...{
                    asReactSelect: true,
                    isMultiSelect: true,
                    name: "propsForTemplate",
                    label: "Select Properties for Template",
                    fragment: ["extendedProperty", "id name "],
                    additionalFilter: {
                      modelTypeCode: "ALIQUOT",
                      extendedPropertyClassCode: [
                        "VALUE",
                        "MEASUREMENT",
                        "CATEGORY"
                      ]
                    }
                  }}
                />
              </div>

              <div
                style={{
                  marginBottom: 15,
                  marginTop: 15,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <DownloadPrefilledLink
                  {...{
                    containerArrays,
                    propsForTemplate
                  }}
                />
                <InfoHelper
                  style={{ marginLeft: 5 }}
                  content="Select properties for the template, then use this link to download a template file with info filled in."
                />
              </div>
              <div style={{ maxWidth: 250 }}>
                <FileUploadField
                  fileLimit={1}
                  accept={getDownloadTemplateFileHelpers({
                    type: allowedCsvFileTypes,
                    fileName: "aliquot_ext_props",
                    headers: csvHeaders,
                    extendedPropTypes: ["aliquot"],
                    fileContents: (headers, headerMessages) => {
                      let contents = headers.join(",") + "\n";
                      containerArrays.forEach((container, i) => {
                        contents += `${container.name},${container.barcode
                          ?.barcodeString || ""},,${
                          i === 0 ? headerMessages["ext-aliquot-"] : ""
                        }\n`;
                      });
                      return contents;
                    }
                  })}
                  name="extendedPropertiesFile"
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function DownloadPrefilledLink({ containerArrays, propsForTemplate }) {
  const [loading, setLoading] = useState(false);
  return (
    <a
      onClick={async () => {
        if (loading) return;
        try {
          if (!containerArrays.length) {
            return window.toastr.warning("Select plates first.");
          }
          if (!propsForTemplate.length) {
            return window.toastr.warning(
              "Select extended properties for template first."
            );
          }
          setLoading(true);
          const headerRow = [...csvHeaders];
          propsForTemplate.forEach(p => {
            headerRow.push(`ext-aliquot-${p.name}`);
          });
          const csv = [headerRow];
          // ["plate name", "plate barcode", "well position"]
          const aliquotIds = [];
          containerArrays.forEach(p => {
            p.aliquotContainers.forEach(ac => {
              if (ac.aliquot) {
                aliquotIds.push(ac.aliquot.id);
              }
            });
          });
          const itemIdToRow = await getExistingPropTemplateRows({
            model: "aliquot",
            itemIds: aliquotIds,
            propsForTemplate
          });
          containerArrays.forEach(p => {
            p.aliquotContainers.forEach(ac => {
              if (ac.aliquot) {
                const row = [
                  p.name,
                  p.barcode?.barcodeString,
                  getAliquotContainerLocation({
                    ...ac,
                    containerArray: p
                  }),
                  ...(itemIdToRow[ac.aliquot.id] || [])
                ];
                csv.push(row);
              }
            });
          });
          download(unparse(csv), "plateWithAliquotProperties.csv");
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error loading template");
        }
        setLoading(false);
      }}
    >
      {loading ? "Loading template..." : "Download Prefilled Template"}
    </a>
  );
}

export default compose(
  stepFormValues(
    "existingProps",
    "aliquotExtendedValues",
    "containerArrays",
    "propsForTemplate"
  )
)(ExtendedProperties);
