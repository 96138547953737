/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component, useState } from "react";
import { compose } from "redux";
import { DataTable, FileUploadField, InfoHelper } from "@teselagen/ui";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import AddExtendedProperty from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/AddExtendedProperty";
import RecordExtendedPropertiesTable from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/RecordExtendedPropertiesTable";
import {
  getExistingPropTemplateRows,
  transformExtendedValues
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import { allowedCsvFileTypes } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { download } from "../../../../../src-shared/utils/downloadTest";
import { unparse } from "papaparse";
import RemoveExtendedPropertiesTable from "../../../../../src-shared/RemoveExtendedPropertiesTable";
import { Button } from "@blueprintjs/core";
import withLibraryExtendedPropertyColumns from "../../../../../src-shared/enhancers/withLibraryExtendedPropertyColumns";

const csvHeaders = ["material name"];
class ExtendedProperties extends Component {
  saveExtendedPropertyToForm = (val, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(fieldName, [...existingValues, val]);
  };

  removeExtendedProperty = (record, model) => {
    const {
      stepFormProps: { change }
    } = this.props;
    const fieldName = `${model}ExtendedValues`;
    const existingValues = this.props[fieldName] || [];
    change(
      fieldName,
      existingValues.filter(
        val => val.extendedProperty.id !== record.extendedProperty.id
      )
    );
  };

  render() {
    const {
      existingProps = [],
      stepFormProps: { change },
      materialExtendedValues = [],
      allMaterials = [],
      extendedPropertyFields,
      propsForTemplate = [],
      Footer,
      footerProps
    } = this.props;

    const materialExtendedValuesEntities = transformExtendedValues(
      materialExtendedValues
    );

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Material Selection"
            helper="Review and fine-tune selection from previous step."
            width="100%"
          />
          <DataTable
            formName="materialSelection"
            entities={allMaterials}
            schema={[
              {
                type: "action",
                width: 50,
                render: (_, r) => {
                  return (
                    <Button
                      icon="trash"
                      minimal
                      intent="danger"
                      onClick={() => {
                        change(
                          "allMaterials",
                          allMaterials.filter(m => m.id !== r.id)
                        );
                      }}
                    />
                  );
                }
              },
              "name",
              {
                displayName: "Type",
                path: "materialType.name"
              },
              ...extendedPropertyFields
            ]}
          />
        </div>
        {!!existingProps.length && (
          <RemoveExtendedPropertiesTable
            existingProps={existingProps}
            model="material"
          />
        )}
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Add Extended Properties to Materials"
            helper="Add extended properties to all materials selected in first step."
            width="100%"
          />
          <div style={{ maxWidth: 500 }}>
            <AddExtendedProperty
              model="material"
              fullValueFields
              noLinkProperties
              formName="addExtendedPropertiesChangeMaterialPropsTool"
              extendedPropertyIdsToIgnore={materialExtendedValues.map(
                val => val.extendedProperty.id
              )}
              onSubmitValue={val =>
                this.saveExtendedPropertyToForm(val, "material")
              }
            />
            {!!materialExtendedValuesEntities.length && (
              <div style={{ minHeight: 200, marginTop: 20 }}>
                <RecordExtendedPropertiesTable
                  isSimple
                  compact
                  noEdit
                  onDelete={record =>
                    this.removeExtendedProperty(record, "material")
                  }
                  entities={materialExtendedValuesEntities}
                />
              </div>
            )}
          </div>
          <div style={{ marginTop: 25 }}>
            <h6>
              Or upload a CSV of properties to add/update specific materials.
            </h6>
            <div style={{ maxWidth: 250 }}>
              <GenericSelect
                {...{
                  asReactSelect: true,
                  isMultiSelect: true,
                  name: "propsForTemplate",
                  label: "Select Properties for Template",
                  fragment: ["extendedProperty", "id name "],
                  additionalFilter: {
                    modelTypeCode: "MATERIAL",
                    extendedPropertyClassCode: [
                      "VALUE",
                      "MEASUREMENT",
                      "CATEGORY"
                    ]
                  }
                }}
              />
            </div>

            <div
              style={{
                marginBottom: 15,
                marginTop: 15,
                display: "flex",
                alignItems: "center"
              }}
            >
              <DownloadPrefilledLink
                {...{
                  allMaterials,
                  propsForTemplate
                }}
              />
              <InfoHelper
                style={{ marginLeft: 5 }}
                content="Select properties for the template, then use this link to download a template file with info filled in."
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <FileUploadField
                fileLimit={1}
                accept={getDownloadTemplateFileHelpers({
                  type: allowedCsvFileTypes,
                  fileName: "material_ext_props",
                  headers: csvHeaders,
                  extendedPropTypes: ["material"],
                  fileContents: (headers, headerMessages) => {
                    let contents = headers.join(",") + "\n";
                    allMaterials.forEach((material, i) => {
                      contents += `${material.name},${
                        i === 0 ? headerMessages["ext-material-"] : ""
                      }\n`;
                    });
                    return contents;
                  }
                })}
                name="extendedPropertiesFile"
              />
            </div>
          </div>
        </div>
        <Footer {...footerProps} nextDisabled={!allMaterials.length} />
      </React.Fragment>
    );
  }
}

function DownloadPrefilledLink({ propsForTemplate, allMaterials }) {
  const [loading, setLoading] = useState(false);
  return (
    <a
      onClick={async () => {
        if (loading) return;
        try {
          if (!propsForTemplate.length) {
            return window.toastr.warning(
              "Select extended properties for template first."
            );
          }
          setLoading(true);
          const headerRow = [...csvHeaders];
          propsForTemplate.forEach(p => {
            headerRow.push(`ext-material-${p.name}`);
          });
          const csv = [headerRow];
          const itemIdToRow = await getExistingPropTemplateRows({
            model: "material",
            itemIds: allMaterials.map(m => m.id),
            propsForTemplate
          });
          allMaterials.forEach(material => {
            csv.push([material.name, ...itemIdToRow[material.id]]);
          });
          download(unparse(csv), "materialWithExtProps.csv");
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error loading template");
        }
        setLoading(false);
      }}
    >
      {loading ? "Loading template..." : "Download Prefilled Template"}
    </a>
  );
}

export default compose(
  withLibraryExtendedPropertyColumns({
    model: "material",
    modelTypeCode: "MATERIAL",
    isLocalTable: true
  }),
  stepFormValues(
    "existingProps",
    "materialExtendedValues",
    "allMaterials",
    "propsForTemplate"
  )
)(ExtendedProperties);
