/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import "./style.css";
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { reduxForm } from "redux-form";
import {
  getDsfForCard,
  isCardRoot,
  doesDesignHavePartsets,
  getBinsInCard,
  getAssemblyMethodOfCard
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../../../../src-shared/utils/designUtils/isDesignLocked";
import actions from "../../../../../src-shared/redux/actions";
import "./style.css";
import {
  getSelectedCard,
  getConstructAnnotationsOfCard
} from "../../../../../src-shared/selectors/designViewSelectors";

import sIfPlural from "../../../../../src-shared/utils/sIfPlural";
import { InputField, CheckboxField } from "@teselagen/ui";
import generateAllCardCombinations, {
  getBinsToTakeCombinationsOf
} from "../../../../../src-shared/utils/generateAllCardCombinations";
import { Intent, Tooltip, Position, Icon, Button } from "@blueprintjs/core";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import VerticalTable from "../../../../../src-shared/components/VerticalTable";
import { isViewClassic } from "../../../../../src-shared/selectors/classicViewSelectors";
import { getJunctionOnCard } from "../../../../../../tg-iso-design/selectors/junctionSelectors";
import { formatDateTime } from "../../../../../src-shared/utils/dateUtils";
import { NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS } from "../../../../constants/misc";

const mapStateToProps = state => {
  const card = getSelectedCard(state);

  if (!card) return {};

  let assemblyMethodOfCard = null;

  if (card.inputReactionId) {
    assemblyMethodOfCard = getAssemblyMethodOfCard(state, card.id);
  }

  const leftJunction = getJunctionOnCard(state, card.id, true) || {};
  const rightJunction = getJunctionOnCard(state, card.id, false) || {};

  const constructAnnotations = card
    ? getConstructAnnotationsOfCard(state, card.id)
    : [];

  return {
    state,
    card,
    assemblyMethodOfCard,
    isUSERInput: assemblyMethodOfCard && assemblyMethodOfCard.name === "USER",
    bins: getBinsInCard(state, card.id),
    isRootCard: isCardRoot(state, card.id),
    isViewClassic: isViewClassic(state),
    constructAnnotations,
    allCombinations: card ? generateAllCardCombinations(state, card.id) : [],
    setsToTakeCombinationsOf: card
      ? getBinsToTakeCombinationsOf(state, card.id)
      : [],
    initialValues: {
      ...card,
      dsf: getDsfForCard(state, card.id),
      circular: !!card.circular,
      leftJunctionBps: leftJunction.bps,
      rightJunctionBps: rightJunction.bps,
      leftJunctionIsUnderhang: !leftJunction.fivePrimeCardFormsOverhang,
      rightJunctionIsOverhang: rightJunction.fivePrimeCardFormsOverhang
    },
    materialAvailabilityInfo:
      state.ui.designEditor.tree.cardMaterialAvailabilityInformation[card.id] ||
      {},
    isLocked: !!isDesignLocked(state),
    hasPartsets: doesDesignHavePartsets(state),
    leftJunction: getJunctionOnCard(state, card.id, true),
    rightJunction: getJunctionOnCard(state, card.id, false)
  };
};

const mapDispatchToProps = {
  updateCard: actions.design.updateCard,
  updateJunction: actions.design.updateJunction,
  setDsf: actions.design.setDsf,
  removeConstructAnnotation: actions.design.removeConstructAnnotation
};

class CardPanel extends Component {
  state = {
    selectedConstructAnnotation: null
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(this.props, "card.id") !== get(nextProps, "card.id")) {
      this.setState({
        isCheckingMaterialAvailabilities: false,
        lastCheckedMaterialAvailability: null,
        numberAvailable: null
      });
    }
  }

  handleFieldSubmit = fieldName => newValue => {
    const {
      updateCard,
      card,
      updateJunction,
      valid,
      leftJunction,
      rightJunction
    } = this.props;

    if (!valid) return;

    if (fieldName === "leftJunctionBps") {
      updateJunction({
        id: leftJunction.id,
        bps: newValue.toUpperCase()
      });
    } else if (fieldName === "rightJunctionBps") {
      updateJunction({
        id: rightJunction.id,
        bps: newValue.toUpperCase()
      });
    } else {
      updateCard({
        id: card.id,
        [fieldName]: newValue
      });
    }
  };

  handleCircularChange = (e, newValue, oldValue) => {
    const { updateCard, card } = this.props;

    updateCard({
      id: card.id,
      doNotAddToUndoStack: true,
      // We can't simply set circular to newValue because of a bug.
      circular: !oldValue
    });
  };

  handleLeftJunctionIsOverhangChange = (e, newValue) => {
    const { updateJunction, leftJunction } = this.props;

    updateJunction({
      id: leftJunction.id,
      fivePrimeCardFormsOverhang: !newValue
    });
  };

  handleRightJunctionIsOverhangChange = (e, newValue) => {
    const { updateJunction, rightJunction } = this.props;

    updateJunction({
      id: rightJunction.id,
      fivePrimeCardFormsOverhang: newValue
    });
  };

  handleDsfChange = (e, newValue, oldValue) => {
    const { setDsf, card } = this.props;
    setDsf({
      cardId: card.id,
      // We can't simply set dsf to newValue because of a bug.
      dsf: +!oldValue
    });
  };

  handleAddConstructAnnotationClick = () => {
    const { card, bins } = this.props;

    showDialog({
      modalType: "CREATE_CONSTRUCT_ANNOTATION",
      modalProps: {
        cardId: card.id,
        bins,
        annotationType: "part"
      }
    });
  };

  render() {
    const {
      card,
      constructAnnotations = [],
      isRootCard,
      allCombinations,
      materialAvailabilityInfo,
      isLocked,
      isUSERInput,
      leftJunction,
      rightJunction,
      assemblyMethodOfCard
      // hasPartsets
    } = this.props;

    const assemblyMethodName = get(assemblyMethodOfCard, "name");

    const { selectedConstructAnnotation } = this.state;

    if (!card)
      return (
        <div className="card-panel">
          <i>Select a card to see more details.</i>
        </div>
      );

    const { numberAvailable, numCombinations } = materialAvailabilityInfo;
    return (
      <div className="card-panel">
        <h5 className="inspector-panel-header">Card Details</h5>
        <InputField
          name="name"
          label="Name"
          onFieldSubmit={this.handleFieldSubmit("name")}
          disabled={isLocked}
        />
        {card.invalidityMessage && (
          <InputField
            leftIcon="error"
            intent={Intent.DANGER}
            name="invalidityMessage"
            label="Invalidity Message"
            readOnly={true}
          />
        )}
        <CheckboxField
          name="circular"
          label="Circular"
          onChange={this.handleCircularChange}
          disabled={isLocked}
        />
        {!isRootCard && (
          <CheckboxField
            name="dsf"
            label="Direct Synthesis Firewall"
            onChange={this.handleDsfChange}
            disabled={isLocked}
          />
        )}
        {!!card.lastCheckedAvailability && (
          <div>
            <i style={{ marginLeft: 5 }}>
              Last checked at {formatDateTime(card.lastCheckedAvailability)}
            </i>
          </div>
        )}
        {!!isEmpty(materialAvailabilityInfo) &&
          card.hasAvailabilityInfo &&
          (card.allConstructsAvailable
            ? "All constructs are available. Click on the button above to get more information."
            : "Not all constructs are available. Click on the button above to get more information.")}
        {!isEmpty(materialAvailabilityInfo) && (
          <div>
            {numberAvailable}/{numCombinations} Construct
            {sIfPlural(allCombinations) + " "}
            Available
          </div>
        )}
        {!NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_CONSTRUCT_ANNOTATIONS.includes(
          assemblyMethodName
        ) ? (
          <div>
            <h6
              style={{ marginTop: 15, display: "flex", alignItems: "center" }}
            >
              Construct Annotations &nbsp;
              <Tooltip
                content={
                  <div style={{ maxWidth: 400 }}>
                    Construct Annotations will create parts or features on the
                    assembled constructs resulting from this design that span
                    the region covered by combinations of parts from the
                    selected bins
                  </div>
                }
                position={Position.BOTTOM}
              >
                <Icon icon="help" style={{ color: "rgb(0, 108, 171)" }} />
              </Tooltip>
              <Button
                minimal
                style={{ marginLeft: 6 }}
                intent="success"
                icon="add"
                onClick={this.handleAddConstructAnnotationClick}
                disabled={isLocked}
              />
            </h6>

            <VerticalTable
              formName="cardPanelConstructAnnotations"
              compact
              schema={constructAnnotationSchema}
              entities={constructAnnotations}
              onDeselect={() =>
                this.setState({ selectedConstructAnnotation: null })
              }
              onSingleRowSelect={selectedConstructAnnotation =>
                this.setState({ selectedConstructAnnotation })
              }
              cellRenderer={cellRenderer}
              onEdit={
                isLocked
                  ? undefined
                  : item => {
                      const { card, bins } = this.props;

                      let constructAnnotation = item;
                      if (!constructAnnotation || !constructAnnotation.name)
                        constructAnnotation = selectedConstructAnnotation;

                      showDialog({
                        modalType: "CREATE_CONSTRUCT_ANNOTATION",
                        modalProps: {
                          ...constructAnnotation,
                          cardId: card.id,
                          bins
                        }
                      });
                    }
              }
              onDelete={
                isLocked
                  ? undefined
                  : e => {
                      this.props.removeConstructAnnotation(e.id);
                    }
              }
            />
          </div>
        ) : null}
        {isUSERInput ? (
          <div className="user-junction-container">
            <div style={{ display: "inline-flex" }}>
              <h6>USER Junctions</h6>
              <div style={{ marginTop: "-.5em" }}>
                <Tooltip
                  position={Position.TOP}
                  content={
                    <div style={{ width: 220 }}>
                      Here you can specify the exact bps that will be at the end
                      of USER parts that result from this card.
                      <br />
                      <b>Note:</b> This section will appear in the Bin Details
                      inspector if in standard View Mode
                    </div>
                  }
                >
                  <Icon
                    icon="help"
                    intent={Intent.PRIMARY}
                    style={{ marginLeft: "1em", marginTop: "0.5em" }}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="card-visualization">
              <div className="strand-visualization top">
                <div
                  className={
                    leftJunction.fivePrimeCardFormsOverhang ? "blank" : ""
                  }
                >
                  {leftJunction.fivePrimeCardFormsOverhang ? "USER tail" : ""}
                </div>
                <div className="middle" />
                <div
                  className={
                    rightJunction.fivePrimeCardFormsOverhang ? "" : "blank"
                  }
                >
                  {rightJunction.fivePrimeCardFormsOverhang ? "" : "USER tail"}
                </div>
              </div>
              <div className="strand-visualization bottom">
                <div
                  className={
                    leftJunction.fivePrimeCardFormsOverhang ? "" : "blank"
                  }
                >
                  {leftJunction.fivePrimeCardFormsOverhang ? "" : "USER tail"}
                </div>
                <div className="middle" />
                <div
                  className={
                    rightJunction.fivePrimeCardFormsOverhang ? "blank" : ""
                  }
                >
                  {rightJunction.fivePrimeCardFormsOverhang ? "USER tail" : ""}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CheckboxField
                name="leftJunctionIsUnderhang"
                label="Overhang Left"
                defaultValue={!leftJunction.fivePrimeCardFormsOverhang}
                onChange={this.handleLeftJunctionIsOverhangChange}
                disabled={isLocked}
              />
              <div style={{ width: "1.5em" }}></div>
              <CheckboxField
                name="rightJunctionIsOverhang"
                label="Overhang Right"
                defaultValue={rightJunction.fivePrimeCardFormsOverhang}
                onChange={this.handleRightJunctionIsOverhangChange}
                disabled={isLocked}
              />
            </div>
            <InputField
              name="leftJunctionBps"
              label="Left Junction Bps (forward top strand)"
              readOnly={isLocked}
              onFieldSubmit={this.handleFieldSubmit("leftJunctionBps")}
            />
            <InputField
              name="rightJunctionBps"
              label="Right Junction Bps (forward top strand)"
              readOnly={isLocked}
              onFieldSubmit={this.handleFieldSubmit("rightJunctionBps")}
            />
            {/* <SelectField
              label={
                <div style={{ display: "inline-flex" }}>
                  <h6>Adding Missing Specified Ends</h6>
                  <div style={{ marginTop: "-.5em" }}>
                    <Tooltip
                      position={Position.TOP}
                      content={
                        <div style={{ width: 220 }}>
                          If end bps are specified and not found, you can add a
                          sourcing reaction to add the end bps onto your parts
                          via the selected method that'll be generated when you
                          submit the design for assembly.
                        </div>
                      }
                    >
                      <Icon
                        icon="help"
                        intent={Intent.PRIMARY}
                        style={{ marginLeft: "1em", marginTop: "0.5em" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              name="howToSourceJunctionBps"
              options={[
                {
                  label: "None",
                  value: "none"
                },
                {
                  label: "PCR",
                  value: "pcr"
                },
                {
                  label: "Direct Synthesis",
                  value: "directSynthesis"
                },
                {
                  label: "Unspecified",
                  value: "unspecified"
                }
              ]}
              disabled={isLocked}
              onFieldSubmit={() => "kc_todo"}
            /> */}
          </div>
        ) : null}
      </div>
    );
  }
}

const constructAnnotationSchema = {
  model: "constructAnnotation",
  fields: [
    // {
    //   path: "name",
    //   type: "string",
    //   displayName: "Alternative Display Name"
    // },
    {
      path: "annotationTemplate",
      type: "string",
      displayName: "Template Name"
    },
    { path: "annotationType", type: "string", displayName: "Part/Feature" },
    { path: "type", type: "string", displayName: "Type" },
    { path: "leftBoundaryBin", type: "string", displayName: "Left Bin" },
    { path: "rightBoundaryBin", type: "string", displayName: "Right Bin" }
  ]
};

const cellRenderer = {
  leftBoundaryBin: bin => bin.name,
  rightBoundaryBin: bin => bin.name
};

const validate = values => {
  const errors = {};
  if (!areJunctionBpsValid(values.leftJunctionBps))
    errors.leftJunctionBps = "Must be valid DNA characters (ATGC).";
  if (!areJunctionBpsValid(values.rightJunctionBps))
    errors.rightJunctionBps = "Must be valid DNA characters (ATGC).";
  return errors;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "cardPanelForm", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(CardPanel);

function areJunctionBpsValid(bps) {
  return !bps || !/[^ATGC]/.test(bps.toUpperCase());
}
