/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button } from "@blueprintjs/core";
import React from "react";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import CreateAliquotFromMaterialDialog from "../../Dialogs/CreateAliquotFromMaterialDialog";

function CreateAliquotFromMaterialButton({ material }) {
  return (
    <Button
      icon="add"
      intent="success"
      text="Create Aliquot"
      onClick={() => {
        showDialog({
          ModalComponent: CreateAliquotFromMaterialDialog,
          modalProps: {
            material,
            dialogProps: {
              title: "Create Aliquot from " + material.name
            }
          }
        });
      }}
    />
  );
}

export default CreateAliquotFromMaterialButton;
