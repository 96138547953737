/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { get } from "lodash";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Callout, Classes } from "@blueprintjs/core";
import {
  InputField,
  SelectField,
  TextareaField,
  DateInputField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { onSubmitCreate, onSubmitEdit } from "./submit";
import "./style.css";

import withQuery from "../../../../src-shared/withQuery";
import customerRequestStatusTypeFragment from "../../../graphql/fragments/customerRequestStatusTypeFragment";
import UserSelect from "../../../../src-shared/UserSelect";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";

class LaunchNewCustomerRequestDialog extends Component {
  get isEdit() {
    const { initialValues = {} } = this.props;
    return !!initialValues.id;
  }

  onSubmit = values => {
    const onSubmitFunction = this.isEdit ? onSubmitEdit : onSubmitCreate;
    return onSubmitFunction(values, this.props);
  };

  render() {
    const {
      initialValues,
      hideModal,
      currentUser,
      customerRequestStatusTypes = [],
      handleSubmit,
      submitting,
      requestType
    } = this.props;

    const customerRequestStatusTypeOptions = arrayToIdOrCodeValuedOptions(
      customerRequestStatusTypes
    );

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          {!this.isEdit && (
            <>
              <GenericSelect
                name="requestType" //the field name within the redux form Field
                label="Type"
                schema={[
                  {
                    path: "name"
                  }
                ]}
                isRequired
                asReactSelect
                fragment={["requestType", "id name description instructions"]}
              />
              {requestType?.instructions && (
                <div style={{ marginBottom: 15, marginTop: 15 }}>
                  <Callout
                    intent="primary"
                    style={{ maxHeight: 300, overflow: "auto" }}
                  >
                    {requestType.instructions}
                  </Callout>
                </div>
              )}
            </>
          )}
          <div className="form-item-container">
            <div className="form-item">
              <InputField
                label="Name"
                name="name"
                isRequired
                placeholder="User Request"
                generateDefaultValue={{
                  ...defaultValueConstants.REQUEST_NAME
                }}
              />
              <UserSelect
                name="primaryCustomer"
                label="Primary User"
                defaultValue={currentUser}
              />
              <UserSelect name="customers" label="Team Members" isMultiSelect />
              <SelectField
                name="customerRequestStatusTypeCode"
                label="Status"
                options={customerRequestStatusTypeOptions}
                defaultValue={get(
                  customerRequestStatusTypeOptions,
                  "[0].value"
                )}
              />
            </div>

            <div className="form-item">
              <DateInputField
                defaultValue={new Date()}
                label="Due Date"
                name="dueDate"
                minDate={initialValues ? undefined : new Date()}
              />

              <GenericSelect
                asReactSelect
                isMultiSelect
                name="strains"
                label="Relevant Strains"
                fragment={["strain", "id name"]}
              />
              <GenericSelect
                asReactSelect
                isMultiSelect
                name="sequences"
                label="Relevant Sequences"
                fragment={["sequence", "id name"]}
              />
            </div>
          </div>
          <TextareaField
            name="outputDescription"
            label="Feedback"
            generateDefaultValue={{
              ...defaultValueConstants.REQUEST_DESCRIPTION
            }}
          />
        </div>
        <DialogFooter
          submitting={submitting}
          secondaryText="Cancel"
          secondaryAction={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog(props => {
    return {
      title: `${props.initialValues ? "Update" : "New"} User Request`
    };
  }),
  withQuery(customerRequestStatusTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  reduxForm({
    form: "launchNewCustomerRequestForm"
  }),
  tgFormValues("requestType")
)(LaunchNewCustomerRequestDialog);
