/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import PropTypes from "prop-types";
import { FileUploadField } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import downloadServerFile from "../../../utils/downloadServerFile";
import FileListSection from "../../FileListSection";

class AssociatedFiles extends React.Component {
  static propTypes = {
    files: PropTypes.arrayOf(PropTypes.object).isRequired,
    refetch: PropTypes.func.isRequired,
    fileTableName: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired
  };

  handleFileRemove = async file => {
    try {
      const { refetch, fileTableName } = this.props;
      await safeDelete(fileTableName, file.id);
      await refetch();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error deleting file(s).");
    }
  };

  handleFileFieldSubmit = async files => {
    try {
      const { fileTableName, refetch, record } = this.props;
      const recordKey =
        record.__typename === "equipmentItem" ? "equipment" : record.__typename;

      await safeUpsert(
        fileTableName,
        files.map(d => ({
          encoding: d.encoding,
          mimetype: d.mimetype,
          originalname: d.originalname,
          path: d.path,
          size: d.size,
          [recordKey + "Id"]: record.id
        }))
      );

      await refetch();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error uploading file(s).");
    }
  };

  render() {
    const { files, noHeader } = this.props;
    return (
      <div>
        {!noHeader && <h5 style={{ marginBottom: 10 }}>Associated Files</h5>}
        <FileUploadField
          action="/user_uploads/"
          name="equipmentFiles"
          fileList={[]}
          onFieldSubmit={this.handleFileFieldSubmit}
          onFileClick={downloadServerFile}
        />
        <FileListSection files={files} onRemove={this.handleFileRemove} />
      </div>
    );
  }
}

export default reduxForm({
  form: "associatedFilesForm"
})(AssociatedFiles);
