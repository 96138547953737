/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function addDynamicScript({ url, appendBody, sync }) {
  if (!url) {
    return;
  }

  const element = document.createElement("script");

  element.src = url;
  element.type = "text/javascript";
  element.async = !sync;

  element.onload = () => {
    // console.log(`Dynamic Script Loaded: ${url}`);
  };

  element.onerror = () => {
    console.error(`Dynamic Script Error: ${url}`);
  };

  if (appendBody) {
    document.body.appendChild(element);
  } else {
    document.head.appendChild(element);
  }
}
