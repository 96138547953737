/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isObject, omit } from "lodash";
import { get, uniqBy } from "lodash";
import {
  defaultVolumetricUnitCode,
  defaultConcentrationUnitCode,
  defaultMolarityUnitCode,
  sumVolumes,
  calculateMolarityFromConcentration,
  convertMolarityBig,
  defaultCellConcentrationUnitCode,
  getCellCount
} from "../../utils/unitUtils";
import { isDiluent, getAliquotMolecularWeight } from "../../utils/aliquotUtils";
import getConcentrationOfMixture from "./getConcentrationOfMixture";
import getCellConcentrationOfMixture from "./getCellConcentrationOfMixture";

/**
 * Helper class to represent a destination aliquot as we transfer
 * other aliquots into it.
 *
 * The "Full" in the name of the class refers to the fact that we have
 * the full aliquot information available to us. The enables us to compute
 * many properties of the aliquot formulation.
 *
 * When serialized to JSON, however, this class produces exactly the same
 * JSON as a PartialFormulation.
 */
class FullFormulation {
  /**
   * Set up some information about the destination aliquot. You need to specify the `aliquot`
   * or `aliquotContainerId` because otherwise we would not know where we are transfering to.
   * @param {Object} arg
   * @param {object} arg.aliquot The destination aliquot.
   * @param {string} arg.aliquotContainer The destination aliquot container.
   * @param {string} arg.sampleName The name of formulated sample, if one gets created. Will be ignored for concentration change.
   * @param {string} arg.concentration The concentration for the new formulated aliquot. Will be ignored for concentration change.
   * @param {string} arg.concentrationUnitCode The concentration unit code for the new aliquot. If not provided, will default to the concentration unit code of the destination aliquot or ng/uL.
   */
  constructor(arg) {
    if (!arg) throw new Error("An object must be passed as the argument.");

    const {
      aliquot = null,
      aliquotContainer = null,
      pendingSamplePoolId = null,
      sampleName
    } = arg;

    if (!aliquot && !aliquotContainer)
      throw new Error(
        'Either "aliquot" or "aliquotContainer" must be defined.'
      );

    /**
     * Information about the destination aliquot.
     */
    this.destination = {
      aliquot,
      aliquotContainer,
      pendingSamplePoolId,
      sampleName:
        sampleName ||
        (aliquot
          ? "formulated-sample-for-aliquot-" + aliquot.id
          : "formulated-sample-in-container-" + aliquotContainer.id)
    };

    /**
     * Array of objects with keys:
     *   - `aliquot`: The the source aliquot from which we are transfering.
     *   - `volume`: How much of the source aliquot we plan to transfer.
     *   - `volumetricUnitCode`: The units of volume.
     */
    this.transfers = [];
  }

  /**
   * Use this method to add some of a source aliquot to the destination aliquot.
   * @param {string} aliquot The source aliquot of the transfer. Cannot be dry.
   * @param {number} volume How much we are transfering.
   * @param {string} volumetricUnitCode The units of `volume`.
   */
  addTransfer(aliquot, volume, volumetricUnitCode) {
    if (!isObject(aliquot)) {
      throw new Error("The argument `aliquot` must be a object.");
    }
    if (aliquot.isDry) {
      throw new Error(`Trying to transfer dry aliquot with id ${aliquot.id}.`);
    }
    this.transfers.push({
      aliquot,
      volume,
      volumetricUnitCode
    });
  }

  /**
   * Use this method to add some of a source aliquot container additives to the destination aliquot.
   *
   * @public
   * @param {string} additiveId The id of the source aliquot container additives of the transfer.
   * @param {number} volume How much we are transfering.
   * @param {string} volumetricUnitCode The units of `volume`.
   */
  addAliquotContainerTransfer(aliquotContainer, volume, volumetricUnitCode) {
    if (!isObject(aliquotContainer)) {
      throw new Error("The argument `aliquotContainer` must be a object.");
    }
    this.transfers.push({
      aliquotContainer,
      volume,
      volumetricUnitCode
    });
  }

  /**
   * Serialize an aliquot formulation instance of JSON.
   * @returns {Object} The aliquot formulation serialized as JSON.
   */
  toJson() {
    return {
      destination: serializeObject(this.destination),
      transfers: this.transfers.map(serializeObject)
    };
  }

  /**
   * Note that this will return that sample name provided by the user. For concentration change
   * events, it will not return the same of the sample of the non-diluent aliquots.
   * @returns {string}
   */
  getSampleName() {
    return this.destination.sampleName;
  }

  /**
   * This will return the existing sample pool id that was attached to a worklist transfer.
   * @returns {string}
   */
  getPendingSamplePoolId() {
    return this.destination.pendingSamplePoolId;
  }

  /**
   * @returns {Array<Object>}
   */
  getTransfers() {
    return this.transfers;
  }

  /**
   * Get the id of the destination aliquot container or return null if it is not provided..
   * @returns {string | null}
   */
  getAliquotContainerId() {
    return get(this.destination.aliquotContainer, "id") || null;
  }

  /**
   * Get the destination aliquot container or null if one does not exist.
   * @returns {AliquotContainer | null}
   */
  getAliquotContainer() {
    return this.destination.aliquotContainer || null;
  }

  /**
   * Get the id of the destination aliquot or return null if it doesn't exist.
   * @returns {string | null}
   */
  getAliquotId() {
    return get(this.destination.aliquot, "id") || null;
  }

  /**
   * Get the destination aliquot or null if one does not exist.
   * @returns {Aliquot | null}
   */
  getAliquot() {
    return this.destination.aliquot || null;
  }

  /**
   * Returns all of the aliquots participating in this formulation. This includes all of
   * the transferred aliquots and the destination aliquot if it is present. The destination
   * aliquot will be included even if it is dry.
   *
   * The volumes of the transferred aliquots will be only the volume that is being tranferred.
   * @private
   * @returns {Array<Object>}
   */
  getAllAliquots() {
    const { aliquot } = this.destination;
    const aliquots = this.transfers
      .filter(t => t.aliquot)
      .map(t => ({
        ...t.aliquot,
        volume: t.volume,
        volumetricUnitCode: t.volumetricUnitCode
      }));
    if (aliquot) aliquots.push(aliquot);
    return aliquots;
  }

  /**
   * Returns all of the source aliquots participating in this formulation.
   *
   * The volumes of the transferred aliquots will be only the volume that is being tranferred.
   * @private
   * @returns {Array<Object>}
   */
  getAllSourceAliquots() {
    const aliquots = this.transfers
      .filter(t => t.aliquot)
      .map(t => ({
        ...t.aliquot,
        volume: t.volume,
        volumetricUnitCode: t.volumetricUnitCode
      }));
    return aliquots;
  }

  /**
   * Return an array of all of the wet additives in the destination aliquot container. If there
   * is not destination aliquot container, return an empty array.
   * @private
   * @returns {Array<Object>}
   */
  getWetAliquotContainerAdditives() {
    const container = this.getAliquotContainer();
    if (!container) return [];
    return container.additives.filter(a => a.volume);
  }

  /**
   * A concentration change event is whenever the end mixture ends up consisting
   * of at most one sample and diluents.
   *
   * Perhaps a bit un-intuitively, this includes the case where there is no destination
   * aliquot. Thus even simple transfers to empty wells will be considered a concentration
   * change event. This is true even if we are only tranfering diluent to an empty well.
   * @returns {boolean}
   */
  isConcentrationChangeEvent() {
    const aliquots = this.getAllAliquots();
    return (
      uniqBy(
        aliquots.filter(a => !isDiluent(a)),
        "sample.id"
      ).length <= 1
    );
  }

  /**
   * A rehydration event occurs when we transfer one or more wet aliquots to a dry aliquot.
   * @returns {boolean}
   */
  isRehydrationEvent() {
    // Since we can only transfer wet aliquots, we only need to check that we have at least one transfer.
    return !!(this.transfers.length && get(this.destination.aliquot, "isDry"));
  }

  /**
   * See if the destination will be dry after all of the transfers have taken place.
   * @returns {boolean}
   */
  isDry() {
    // Since we can only transfer wet aliquots, we only need to check that there are no transfers.
    return (
      !this.getWetAliquotContainerAdditives().length &&
      !this.transfers.length &&
      get(this.destination.aliquot, "isDry")
    );
  }

  /**
   * Returns the destination aliquot's concentration unit code. If there is no destination
   * aliquot, then returns the default concentration unit code.
   * @returns {string} A concentration unit code.
   */
  getConcentrationUnitCode() {
    return (
      get(this.destination.aliquot, "concentrationUnitCode") ||
      defaultConcentrationUnitCode
    );
  }

  /**
   * Returns the destination aliquot's molarity unit code. If there is no destination
   * aliquot, then returns the default molarity unit code.
   * @returns {string} A molarity unit code.
   */
  getMolarityUnitCode() {
    return (
      get(this.destination.aliquot, "molarityUnitCode") ||
      defaultMolarityUnitCode
    );
  }

  /**
   * Returns the destination aliquot's cell concentration unit code. If there is no destination
   * aliquot, then returns the default cell concentration unit code.
   * @returns {string} A cell concentration unit code.
   */
  getCellConcentrationUnitCode() {
    return (
      get(this.destination.aliquot, "cellConcentrationUnitCode") ||
      defaultCellConcentrationUnitCode
    );
  }

  /**
   * Returns the volumetric unit code that will be used for the destination aliquot.
   * This is either the destination aliquot's volumetric unit code or the default unit
   * code if there is no destination aliquot.
   * @returns {string} A volumetric unit code.
   */
  getVolumetricUnitCode() {
    return (
      get(this.destination.aliquot, "volumetricUnitCode") ||
      defaultVolumetricUnitCode
    );
  }

  /**
   * Get the volume of the destination aliquot after all of the transfers have taken place. The
   * value will be given in the units returned by `getVolumetricUnitCode`.
   * @returns {number}
   */
  getVolume() {
    const {
      destination: { aliquot },
      transfers
    } = this;
    const unitCode = this.getVolumetricUnitCode();

    // Everything in this array must have `volume` and `volumetricUnitCode` keys.
    let volumesInfo =
      aliquot && !aliquot.isDry ? transfers.concat(aliquot) : transfers;

    // We must take into account any additives in the destination aliquot container.
    volumesInfo = volumesInfo.concat(this.getWetAliquotContainerAdditives());

    return Number(sumVolumes(volumesInfo, unitCode));
  }

  getConcentration() {
    return this.getMolarityOrConcentration(false);
  }

  getMolarity() {
    return this.getMolarityOrConcentration(true);
  }

  /**
   * Get the volume of the source aliquot after all of the transfers have taken place. The
   * value will be given in the units returned by `getConcentrationUnitCode`.
   *
   * If the volume of the aliquot is zero after all transfers have taken place, then this
   * returns `null`.
   *
   * If this is a concentration change event, then we compute the new concentration (or molarity). Otherwise,
   * we return either the concentration value (or molarity) supplied in the constructor or `null`.
   * @param {number || null}
   */
  getMolarityOrConcentration(returnMolarity) {
    if (this.isConcentrationChangeEvent()) {
      const concentrationUnitCode = this.getConcentrationUnitCode();
      const molarityUnitCode = this.getMolarityUnitCode();

      // Represent the wet additives as diluents by giving them zero concentration.
      const wetContainerAdditives = this.getWetAliquotContainerAdditives().map(
        a => ({
          ...a,
          concentrationUnitCode,
          concentration: 0
        })
      );

      const aliquots = this.getAllAliquots();

      // If we have a destination aliquot container, then we must take into
      // account the volumes of the additives in the destination aliquot container.
      const aliquotsAndAdditives = aliquots.concat(wetContainerAdditives);

      const concentration = getConcentrationOfMixture(
        aliquotsAndAdditives,
        concentrationUnitCode
      );

      if (concentration) {
        if (returnMolarity) {
          const nonDiluentAliquots = aliquots.filter(a => !isDiluent(a));
          const molecularWeight = getAliquotMolecularWeight(
            nonDiluentAliquots[0]
          );
          if (molecularWeight) {
            const stdMolarity = calculateMolarityFromConcentration(
              concentration,
              concentrationUnitCode,
              molecularWeight
            );

            return Number(
              convertMolarityBig(stdMolarity, "M", molarityUnitCode)
            );
          } else {
            return null;
          }
        } else {
          return concentration;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getCellConcentration() {
    if (this.isConcentrationChangeEvent()) {
      const cellConcentrationUnitCode = this.getCellConcentrationUnitCode();
      // const { aliquot } = this.destination;
      // if (!aliquot) return null;
      // const { cellConcentration, cellCount } = aliquot;
      // if (!cellConcentration && !cellCount) return null;

      // Represent the wet additives as diluents by giving them zero concentration.
      const wetContainerAdditives = this.getWetAliquotContainerAdditives().map(
        a => ({
          ...a,
          cellConcentrationUnitCode,
          cellConcentration: 0
        })
      );

      const aliquots = this.getAllAliquots();

      // If we have a destination aliquot container, then we must take into
      // account the volumes of the additives in the destination aliquot container.
      const aliquotsAndAdditives = aliquots.concat(wetContainerAdditives);
      return getCellConcentrationOfMixture(
        aliquotsAndAdditives,
        cellConcentrationUnitCode
      );
    } else {
      return null;
    }
  }

  getCellConcentrationFields() {
    const cellConcentration = this.getCellConcentration();
    const cellConcentrationUnitCode = this.getCellConcentrationUnitCode();

    const cellCount = getCellCount({
      cellConcentration,
      cellConcentrationUnitCode,
      volume: this.getVolume(),
      volumetricUnitCode: this.getVolumetricUnitCode()
    });
    return {
      cellConcentration,
      cellConcentrationUnitCode,
      cellCount
    };
  }
}

/**
 * Given a serialized aliquot formulation object and a map from aliquot id to the full aliquot,
 * return a FullFormation instance corresponding to the serialized data.
 *
 * @param {Object} json A serialized aliquot formulation object.
 * @param {Map<string, Object>} idToAliquot A map from aliquot id to the full aliquot object.
 * @param {Map<string, Object>} idToAliquotContainer A map from aliquot container id to the full aliquot container object.
 * @returns {FullFormulation}
 */
FullFormulation.deserialize = function(
  json,
  idToAliquot,
  idToAliquotContainer
) {
  const { destination, transfers } = json;

  const dstAliquot = idToAliquot[destination.aliquotId];
  if (destination.aliquotId && !dstAliquot) {
    throw new Error(`Aliquot with id ${destination.aliquotId} not found.`);
  }

  const dstAliquotContainer =
    idToAliquotContainer[destination.aliquotContainerId];
  if (destination.aliquotContainerId && !dstAliquotContainer) {
    throw new Error(
      `Aliquot container with id ${destination.aliquotContainerId} not found.`
    );
  }

  const fullFormulation = new FullFormulation({
    ...destination,
    aliquot: dstAliquot,
    aliquotContainer: dstAliquotContainer
  });

  for (const t of transfers) {
    if (t.aliquotId) {
      const aliquot = idToAliquot[t.aliquotId];
      if (!aliquot) {
        throw new Error(`Aliquot with id ${t.aliquotId} not found.`);
      }
      fullFormulation.addTransfer(aliquot, t.volume, t.volumetricUnitCode);
    } else if (t.aliquotContainerId) {
      const aliquotContainer = idToAliquotContainer[t.aliquotContainerId];
      if (!aliquotContainer) {
        throw new Error(
          `Aliquot container with id ${t.aliquotContainerId} not found.`
        );
      }
      fullFormulation.addAliquotContainerTransfer(
        aliquotContainer,
        t.volume,
        t.volumetricUnitCode
      );
    }
  }

  return fullFormulation;
};

/**
 * Helper function to help with serializing objects. Basically, it
 * replaces the `aliquot` field on the object with an `aliquotId` field.
 */
export function serializeObject(obj) {
  return {
    ...omit(obj, "aliquot", "aliquotContainer"),
    aliquotId: obj.aliquot ? obj.aliquot.id : null,
    aliquotContainerId: obj.aliquotContainer ? obj.aliquotContainer.id : null
  };
}

export default FullFormulation;
