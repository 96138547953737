/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { get } from "lodash";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import StepForm from "../../../../src-shared/StepForm";
import { plateFragment } from "../AliquotRearrayTool/fragments";
import ReviewData from "./ReviewData";
import SelectPlates from "./SelectPlates";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class CreateDataTableFromPlate extends Component {
  onSubmit = async values => {
    try {
      const { dataRows, dataTableName, dataTableType } = values;
      const dataTableToCreate = {
        name: dataTableName,
        dataTableTypeCode: dataTableType.code,
        dataRows
      };
      const createdDataTables = await safeUpsert(
        ["dataTable", "id name"],
        dataTableToCreate
      );
      return {
        dataTables: createdDataTables
      };
    } catch (error) {
      console.error(error);
    }
  };

  validate = values => {
    const {
      containerArrays = [],
      parentPlates = [],
      sampleStatusCode,
      dataTableType
    } = values;
    const errors = {};

    const hasSamples = containerArrays.some(containerArray =>
      containerArray.aliquotContainers.some(ac => get(ac, "aliquot.sample"))
    );
    const hasSampleStatus = containerArrays.some(containerArray =>
      containerArray.aliquotContainers.some(
        ac => get(ac, "aliquot.sample.sampleStatusCode") === sampleStatusCode
      )
    );
    if (!hasSamples) {
      errors.containerArrays = "No samples were found on selected plates.";
    } else if (!hasSampleStatus) {
      // TO DO: sometimes the sampleStatusCode is not being passed in the values
      errors.containerArrays = `No samples with status "${sampleStatusCode?.toLowerCase()}" were found on selected plates.`;
    }
    if (
      dataTableType?.code === "UPDATE_PLATES_TOOL" &&
      containerArrays.length &&
      parentPlates.length
    ) {
      const childIds = containerArrays.map(c => c.id);
      if (parentPlates.some(p => childIds.includes(p.id))) {
        errors.parentPlates = "Cannot have overlap with child plates.";
      } else {
        const parentPlateErrors = [];
        const sampleIdToWells = {};
        containerArrays.forEach(c => {
          c.aliquotContainers.forEach(ac => {
            const sample = ac.aliquot?.sample;
            if (sample) {
              sampleIdToWells[sample.id] = sampleIdToWells[sample.id] || [];
              sampleIdToWells[sample.id].push(ac);
            }
          });
        });
        parentPlates.forEach(c => {
          c.aliquotContainers.forEach(ac => {
            const sample = ac.aliquot?.sample;
            if (sample) {
              if (!sampleIdToWells[sample.id]) {
                parentPlateErrors.push(
                  `No replicate well found with sample ${sample.name}`
                );
              } else if (sampleIdToWells[sample.id].length > 1) {
                parentPlateErrors.push(
                  `Multiple replicate wells found with sample ${sample.name}. Only a single sample replicate should be found.`
                );
              }
            }
          });
        });
        if (parentPlateErrors.length) {
          errors.parentPlates = parentPlateErrors.join("\n");
        }
      }
    }
    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        withCustomFooter: true
      },
      {
        title: "Review Data",
        Component: ReviewData
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        validate={this.validate}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(withWorkflowInputs(plateFragment))(
  CreateDataTableFromPlate
);
