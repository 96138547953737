/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isNumber, get } from "lodash";
import { rowIndexToLetter } from "./rowIndexToLetter";

/**
 * Get the location of an aliquot in a container
 * @param {{rowPosition: number, columnPosition: number; containerArray?: object; containerArrayType?: object}|null} options
 * @param {object=} otherOptions
 * @param {boolean=} otherOptions.force2D - Whether to force 2D labeling
 * @param {object=} otherOptions.containerFormat - The container format object
 * @returns {string} The location of the aliquot in the container
 */
export function getAliquotContainerLocation(
  options,
  otherOptions = { force2D: false, containerFormat: {} }
) {
  const {
    rowPosition,
    columnPosition,
    containerArray = {},
    containerArrayType: _containerArrayType
  } = options || {}; // allows null to still be passed in
  const { force2D, containerFormat = {} } = otherOptions;

  if (isNumber(rowPosition) && isNumber(columnPosition)) {
    const label2D = rowIndexToLetter(rowPosition) + (columnPosition + 1);
    if (force2D) {
      return label2D;
    }
    const containerArrayType =
      _containerArrayType || containerArray.containerArrayType;
    const is2DLabeled =
      get(containerArrayType, "containerFormat.is2DLabeled") ||
      containerFormat.is2DLabeled;
    const columnCount =
      get(containerArrayType, "containerFormat.columnCount") ||
      containerFormat.columnCount;
    if (!force2D && !is2DLabeled && columnCount) {
      return rowPosition * columnCount + columnPosition + 1;
    } else {
      return label2D;
    }
  } else {
    return "N/A";
  }
}
