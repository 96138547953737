/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  ReactSelectField,
  SwitchField,
  CheckboxField,
  InputField,
  NumericInputField,
  SelectField
} from "@teselagen/ui";
import { groupBy, forEach, dropRight } from "lodash";
import { compose } from "recompose";

import withQuery from "../../../../../src-shared/withQuery";

import shortid from "shortid";
import { Button } from "@blueprintjs/core";
import containerArrayTypeFragment from "../../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { arrayToItemValuedOptions } from "../../../../../src-shared/utils/formUtils";
import { computeSequenceHash } from "../../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateEmptyWells } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";

class DestinationContainerConfiguration extends Component {
  generateWorklist = values => {
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const {
      destinationContainerName,
      destinationContainerType,
      poolingData,
      destinationTubeType,
      plateBarcodeAcHelper,
      keyedPlates,
      keyedTubes,
      universalTransferVolume,
      universalTransferVolumetricUnitCode,
      applyUniversalTransfer,
      useFloatingTubes,
      destinationFloatingTubeType
    } = values;

    const proteinPatternHashToSampleIds = {};
    const samplesToCreate = [];
    let aliquotContainerQueue = [];
    const proteinPatternsToCreate = [];
    const destinationContainersToCreate = [];
    const destinationTubesToCreate = [];

    const makeNewDestinationContainer = () => {
      let destinationContainer;
      if (useFloatingTubes) {
        destinationContainer = {
          name:
            destinationContainerName +
            ` ${destinationTubesToCreate.length + 1}`,
          aliquotContainerTypeCode: destinationFloatingTubeType.code
        };
        aliquotContainerQueue.push(destinationContainer);
        destinationTubesToCreate.push(destinationContainer);
      } else {
        let aliquotContainerTypeCode;
        if (
          destinationContainerType.nestableTubeTypes.length > 1 &&
          destinationTubeType
        ) {
          aliquotContainerTypeCode = destinationTubeType.code;
        } else if (destinationContainerType.nestableTubeTypes.length) {
          aliquotContainerTypeCode =
            destinationContainerType.nestableTubeTypes[0]
              .aliquotContainerTypeCode;
        } else {
          aliquotContainerTypeCode =
            destinationContainerType.aliquotContainerType.code;
        }
        destinationContainer = {
          name:
            destinationContainerName +
            ` ${destinationContainersToCreate.length + 1}`,
          containerArrayTypeId: destinationContainerType.id,
          aliquotContainers: generateEmptyWells(
            destinationContainerType.containerFormat,
            {
              aliquotContainerTypeCode
            }
          )
        };
        aliquotContainerQueue = [...destinationContainer.aliquotContainers];
        destinationContainersToCreate.push(destinationContainer);
      }
      return destinationContainer;
    };

    let volumeFields;
    if (applyUniversalTransfer) {
      volumeFields = {
        volume: universalTransferVolume,
        volumetricUnitCode: universalTransferVolumetricUnitCode
      };
    }
    const groupedPools = groupBy(poolingData, "pooledSampleName");
    const transfers = [];
    let activeDestPlate;
    // these are grouped by the transfer destination
    forEach(groupedPools, (sources, sampleName) => {
      const proteinPattern = sources[0].proteinPattern;

      const newSample = {
        cid: shortid(),
        name: sampleName,
        sampleTypeCode: "PENDING_POOL"
      };
      if (proteinPattern) {
        const hash = computeSequenceHash(proteinPattern, "AA");
        if (!proteinPatternHashToSampleIds[hash]) {
          proteinPatternsToCreate.push({
            cid: shortid(),
            hash,
            name: proteinPattern,
            pattern: proteinPattern
          });
          proteinPatternHashToSampleIds[hash] = [];
        }
        proteinPatternHashToSampleIds[
          computeSequenceHash(proteinPattern, "AA")
        ].push(`&${newSample.cid}`);
      }
      samplesToCreate.push(newSample);

      if (!aliquotContainerQueue.length) {
        activeDestPlate = makeNewDestinationContainer();
      }
      const destAc = aliquotContainerQueue.shift();
      destAc.cid = shortid();

      sources.forEach(source => {
        if (!applyUniversalTransfer) {
          volumeFields = {
            volume: Number(source.volume),
            volumetricUnitCode: source.volumetricUnitCode
          };
        }
        const sourceAliquotContainer = source.position
          ? plateBarcodeAcHelper[source.barcode][source.position]
          : keyedTubes[source.barcode];
        const sourcePlateOrTube = source.position
          ? keyedPlates[source.barcode]
          : keyedTubes[source.barcode];
        transfers.push({
          ...volumeFields,
          samplePoolId: `&${newSample.cid}`,
          source: sourcePlateOrTube.name,
          sourceBarcode: sourcePlateOrTube.barcode.barcodeString,
          sourcePosition: source.position || "N/A",
          sourceAliquotContainer,
          destinationAliquotContainer: {
            ...destAc,
            containerArrayType: destinationContainerType
          },
          destinationPlateName: activeDestPlate.name,
          destinationPosition: getAliquotContainerLocation(destAc),
          sourceAliquotContainerId: sourceAliquotContainer.id,
          destinationAliquotContainerId: `&${destAc.cid}`
        });
      });
    });

    // we don't want to make empty tubes in a rack so remove the tubes that won't be used
    if (aliquotContainerQueue.length && !destinationContainerType.isPlate) {
      activeDestPlate.aliquotContainers = dropRight(
        activeDestPlate.aliquotContainers,
        aliquotContainerQueue.length
      );
    }

    change("destinationContainersToCreate", destinationContainersToCreate);
    change("destinationTubesToCreate", destinationTubesToCreate);
    change("worklist", {
      worklistTransfers: transfers
    });
    change("samplesToCreate", samplesToCreate);
    change("proteinPatternsToCreate", proteinPatternsToCreate);
    change("proteinPatternHashToSampleIds", proteinPatternHashToSampleIds);

    nextStep();
  };

  render() {
    const {
      useFloatingTubes,
      containerArrayTypes = [],
      aliquotContainerTypes = [],
      handleSubmit,
      applyUniversalTransfer,
      destinationContainerType,
      Footer,
      footerProps
    } = this.props;

    let tubeTypeOptions = [];
    if (
      destinationContainerType &&
      destinationContainerType.nestableTubeTypes
    ) {
      tubeTypeOptions = destinationContainerType.nestableTubeTypes.map(
        n => n.aliquotContainerType
      );
    }

    return (
      <React.Fragment>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Destination Container"
            helper={`Select a destination container type. The number of destination containers depends on\
              the number of combinations of input materials. Enter a name for the destination\
              container. If there are multiple containers, the name will be appended with incremental\
              number starting from 1. If barcodes are needed, check the Generate Barcodes box.`}
          />
          <div style={{ width: 300 }}>
            <InputField
              name="destinationContainerName"
              isRequired
              label="Destination Container Name"
            />
            <SwitchField
              name="useFloatingTubes"
              label="Transfer to floating tubes"
            />
            {useFloatingTubes ? (
              <ReactSelectField
                name="destinationFloatingTubeType"
                isRequired
                label="Destination Tube Type"
                options={arrayToItemValuedOptions(aliquotContainerTypes)}
              />
            ) : (
              <React.Fragment>
                <ReactSelectField
                  name="destinationContainerType"
                  isRequired
                  label="Destination Container Type"
                  options={arrayToItemValuedOptions(containerArrayTypes)}
                />
                {tubeTypeOptions.length > 1 && (
                  <ReactSelectField
                    name="destinationTubeType"
                    isRequired
                    label="Tube Type"
                    options={arrayToItemValuedOptions(tubeTypeOptions)}
                  />
                )}
              </React.Fragment>
            )}
            <CheckboxField
              name="generateBarcodes"
              label="Generate Barcodes?"
              defaultValue
            />
          </div>
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Transfer Volume"
            helper={`If the checkbox "Apply universal transfer" is selected, the volume values\
            specified in the pooling schema file will be ignored. Instead, a set volume\
            specified by users will be applied to all materials to be pooled together. `}
          />
          <div>
            <CheckboxField
              name="applyUniversalTransfer"
              label="Apply Universal Transfer?"
            />
            <div className="input-with-unit-select">
              <NumericInputField
                disabled={!applyUniversalTransfer}
                label="Volume"
                name="universalTransferVolume"
                min={0}
              />
              <SelectField
                disabled={!applyUniversalTransfer}
                className="tg-unit-select"
                label="none"
                name="universalTransferVolumetricUnitCode"
                defaultValue="uL"
                options={unitGlobals.getOptionsForSelect("volumetricUnit")}
              />
            </div>
          </div>
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent="primary"
              onClick={handleSubmit(this.generateWorklist)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withQuery(containerArrayTypeFragment, {
    showLoading: true,
    isPlural: true
  }),
  withQuery(aliquotContainerTypeFragment, {
    showLoading: true,
    isPlural: true,
    options: {
      variables: {
        filter: {
          isTube: true
        }
      }
    }
  }),
  stepFormValues(
    "applyUniversalTransfer",
    "destinationContainerType",
    "useFloatingTubes"
  )
)(DestinationContainerConfiguration);
