/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { trim, isEmpty, keyBy, forEach, noop, get, has } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { getTaggedItemModelKey } from "./utils";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "../utils/caseInsensitiveFilter";
import { nonDNASeqTypes } from "../sequence-import-utils/utils";

const convertObjectKeysToLowerCase = obj => {
  Object.keys(obj).forEach(key => {
    const temp = obj[key];
    delete obj[key];
    obj[key.toLowerCase()] = temp;
  });
  return obj;
};

export default async function uploadTagsCSV(
  { csvData, reset = noop, model, sequenceType, rnaType },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert, modelNameToReadableName = noop } = ctx;
  const tagItemMap = {};
  const tagNames = [];
  const readableName = modelNameToReadableName(model);
  const pluralReadableName = modelNameToReadableName(model, { plural: true });
  const taggedItemModelKey = getTaggedItemModelKey(model);

  if (get(csvData, "[0].ID")) {
    csvData = csvData.map(_row => {
      if (!_row.id && _row.ID) {
        const row = { ..._row };
        row.id = row.ID;
        delete row.ID;
        return row;
      } else {
        return _row;
      }
    });
  }
  let keyByName = !get(csvData, "[0].id");
  const nameKeyString = get(csvData, "[0].name") ? "name" : "Name";
  const hasName = get(csvData, "[0].name") || get(csvData, "[0].Name");
  const barcodeKeyString = has(csvData, "[0].barcode") ? "barcode" : "Barcode";
  const keyField = keyByName ? nameKeyString : "id";

  const makeError = error => {
    throw new Error(error);
  };
  const showTagRemainingToast = () => {
    window.toastr.info(`Tagging remaining ${pluralReadableName}...`);
  };
  if (keyByName && model === "aliquot") {
    return makeError("Aliquots do not have names. Please fix CSV.");
  }
  let hasBarcodes = false;
  csvData.forEach(dataRow => {
    convertObjectKeysToLowerCase(dataRow);
    const byBarcode = dataRow[barcodeKeyString];
    if (byBarcode) hasBarcodes = true;
    let key = dataRow[barcodeKeyString] || dataRow[keyField];
    let name;
    if (keyByName) {
      name = dataRow[keyField];
    }
    if (!key || !dataRow.tags) return;
    if (!byBarcode) {
      key = key.toLowerCase();
    }
    if (keyField === "id" && model === "aliquot" && key.includes("aliquot ")) {
      key = key.replace("aliquot ", "");
    }
    const tags = dataRow.tags.split(",");
    tags.forEach(tagString => {
      let [tag, tagOption] = tagString.split(":").map(trim);
      if (!tag) return;
      tag = tag.toLowerCase();
      tagOption = tagOption && tagOption.toLowerCase();
      if (!tagNames.includes(tag)) tagNames.push(tag);
      if (!tagItemMap[key])
        tagItemMap[key] = { tags: [], byBarcode: !!byBarcode, name };
      if (tagItemMap[key].tags.find(tagObj => tagObj.tag === tag)) return;
      tagItemMap[key].tags.push({
        tag,
        tagOption
      });
    });
  });

  if (isEmpty(tagItemMap)) {
    return makeError(`No ${pluralReadableName} found to tag.`);
  }
  if (!tagNames.length) {
    return makeError("No tags found in CSV.");
  }
  const initialRecordIdsOrNames = [];
  const initialRecordBarcodes = [];

  const originalKeyByName = keyByName;
  keyByName = false;
  // if all keyed by barcode unset key by name to avoid confusion later
  Object.keys(tagItemMap).forEach(key => {
    if (tagItemMap[key].byBarcode) initialRecordBarcodes.push(key);
    else {
      keyByName = originalKeyByName;
      initialRecordIdsOrNames.push(key);
    }
  });

  // create query to fetch existing tagged items for the records
  const taggedItemsString = `taggedItems {
      id
      tagId
      tagOptionId
    }`;
  const fragmentString = `
      id
      ${hasName ? "name" : ""}
      ${hasBarcodes ? `barcode { id barcodeString }` : ""}
      ${taggedItemsString}
    `;
  // need to filter out records which don't exist
  let records = [];
  if (initialRecordIdsOrNames.length) {
    let filter;
    if (keyByName) {
      filter = caseInsensitiveFilter(model, "name", initialRecordIdsOrNames, {
        returnQb: true
      });
      if (model === "sequence") {
        filter = filter.whereAll({ isInLibrary: true });
        if (sequenceType) {
          filter = filter.whereAll({ sequenceTypeCode: sequenceType });
          if (rnaType) {
            filter = filter.whereAll({ "rnaType.name": rnaType });
          }
        } else {
          filter = filter.whereAll({
            sequenceTypeCode: filter.notInList(nonDNASeqTypes)
          });
        }
      }
      filter = filter.toJSON();
    } else {
      filter = {
        id: initialRecordIdsOrNames
      };
    }
    const namedRecords = await safeQuery([model, fragmentString], {
      variables: {
        filter
      }
    });
    records.push(...namedRecords);
  }
  if (initialRecordBarcodes.length) {
    let barcodeRecords = await safeQuery([model, fragmentString], {
      variables: {
        filter: {
          "barcode.barcodeString": initialRecordBarcodes
        }
      }
    });

    if (barcodeRecords.length) {
      const dupBarcodeCheck = {};
      const duplicateBarcodes = [];
      barcodeRecords.forEach(record => {
        const barcode = record.barcode.barcodeString;
        if (dupBarcodeCheck[barcode] && !duplicateBarcodes.includes(barcode)) {
          delete tagItemMap[barcode];
          duplicateBarcodes.push(barcode);
        } else {
          dupBarcodeCheck[barcode] = true;
        }
      });
      if (duplicateBarcodes.length) {
        barcodeRecords = barcodeRecords.filter(record => {
          const barcode = record.barcode.barcodeString;
          return !duplicateBarcodes.includes(barcode);
        });
        const message = `Multiple ${pluralReadableName} were found with these barcodes: ${duplicateBarcodes.join(
          ", "
        )}. Could not determine which items to tag.`;
        if (records.length || barcodeRecords.length) {
          showTagRemainingToast();
          window.toastr.warning(message);
        } else {
          return makeError(message);
        }
      }
    }
    records.push(...barcodeRecords);
  }

  if (!records.length) {
    return makeError(
      `None of these ${pluralReadableName} were found in the database.`
    );
  }

  if (keyByName) {
    const duplicateNames = [];
    const allNamesCount = records.reduce((acc, record) => {
      if (!acc[record.name]) acc[record.name] = 1;
      else {
        delete tagItemMap[record.name.toLowerCase()];
        duplicateNames.push(record.name);
        acc[record.name]++;
      }
      return acc;
    }, {});

    records = records.filter(record => {
      return allNamesCount[record.name] === 1;
    });
    if (duplicateNames.length) {
      const message = `Multiple ${pluralReadableName} were found with these names: ${duplicateNames.join(
        ", "
      )}. Could not determine which items to tag.`;
      if (records.length) {
        showTagRemainingToast();
        window.toastr.warning(message);
      } else {
        return makeError(message);
      }
    }
  }

  const recordMap = keyBy(
    records,
    keyByName ? r => r.name.toLowerCase() : "id"
  );
  records.forEach(record => {
    const barcode = get(record, "barcode.barcodeString");
    if (barcode) {
      recordMap[barcode] = record;
    }
  });

  const noBarcodeRecord = [];
  const noIdRecord = [];
  const noNameRecord = [];
  const nameMismatch = [];
  for (const idOrNameOrBarcode of Object.keys(tagItemMap)) {
    const record = recordMap[idOrNameOrBarcode];
    const byBarcode = tagItemMap[idOrNameOrBarcode].byBarcode;
    if (byBarcode && record) {
      const name = tagItemMap[idOrNameOrBarcode].name;
      if (
        name &&
        record.name &&
        name.toLowerCase() !== record.name.toLowerCase()
      ) {
        nameMismatch.push(idOrNameOrBarcode);
      }
    }
    if (!record) {
      if (byBarcode) {
        noBarcodeRecord.push(idOrNameOrBarcode);
      } else if (keyByName) {
        noNameRecord.push(idOrNameOrBarcode);
      } else {
        noIdRecord.push(idOrNameOrBarcode);
      }
      delete tagItemMap[idOrNameOrBarcode];
    } else if (record.id !== idOrNameOrBarcode) {
      recordMap[record.id] = record;
      tagItemMap[record.id] = tagItemMap[idOrNameOrBarcode];
      delete tagItemMap[idOrNameOrBarcode];
    }
  }

  if (nameMismatch.length) {
    return makeError(`The ${pluralReadableName} with the following barcodes specified names in the \
      csv which did not match what was in inventory: ${nameMismatch.join(
        ", "
      )}.`);
  }

  if (noBarcodeRecord.length || noIdRecord.length || noNameRecord.length) {
    const toaster = isEmpty(tagItemMap)
      ? window.toastr.error
      : window.toastr.warning;
    if (isEmpty(tagItemMap)) {
      return reset();
    } else {
      showTagRemainingToast();
    }
    if (noBarcodeRecord.length) {
      toaster(
        `No ${readableName} was found with the following barcodes: ${noBarcodeRecord.join(
          ", "
        )}`
      );
    }
    if (noIdRecord.length) {
      toaster(
        `No ${readableName} was found with the following ids: ${noIdRecord.join(
          ", "
        )}`
      );
    }
    if (noNameRecord.length) {
      toaster(
        `No ${readableName} was found with the following names: ${noNameRecord.join(
          ", "
        )}`
      );
    }
  }

  const qb = new QueryBuilder("tag");
  const innerQuery = tagNames.map(tagName => ({
    name: qb.lowerCase(tagName)
  }));
  const filter = qb.whereAny(...innerQuery).toJSON();
  const tagFragment = `
      id
      name
      color
      description
      tagOptions {
        id
        name
        color
        description
      }
    `;
  const tags = await safeQuery(["tag", tagFragment], {
    variables: {
      filter
    }
  });

  if (!tags.length) {
    return makeError("No tags with these names were found in the database.");
  }

  const tagMap = keyBy(tags, ({ name }) => name.toLowerCase());

  const missingTags = tagNames.filter(tagName => !tagMap[tagName]);
  if (missingTags.length) {
    return makeError(
      `These tags did not exist: ${missingTags.join(
        ", "
      )}. Please create them and try again.`
    );
  }
  const taggedItems = [];
  const taggedItemsToUpdate = [];
  forEach(
    tagItemMap,
    ({ tags, [taggedItemModelKey]: modelKeyId }, recordId) => {
      tags.forEach(({ tag, tagOption }) => {
        const dataTag = tagMap[tag.toLowerCase()];
        if (dataTag) {
          const existingTaggedItems = recordMap[recordId]
            ? recordMap[recordId].taggedItems
            : [];
          const tagId = dataTag.id;
          let tagOptionId;
          if (tagOption) {
            const dataTagOption = dataTag.tagOptions.find(
              dataTagOption =>
                dataTagOption.name.toLowerCase() === tagOption.toLowerCase()
            );
            if (dataTagOption) tagOptionId = dataTagOption.id;
          }
          if (existingTaggedItems.length) {
            // if a taggedItem already exists for this tag on the record then
            // it should not be recreated
            // if a taggedItem already exists with a different tagOption then
            // it should be updated
            const shouldSkip = existingTaggedItems.some(taggedItem => {
              if (taggedItem.tagId === tagId) {
                if (tagOptionId && taggedItem.tagOptionId !== tagOptionId) {
                  taggedItemsToUpdate.push({
                    id: taggedItem.id,
                    tagOptionId
                  });
                }
                return true;
              }
              return false;
            });
            if (shouldSkip) return;
          }
          taggedItems.push({
            tagId,
            [taggedItemModelKey]: modelKeyId || recordId,
            tagOptionId
          });
        }
      });
    }
  );
  if (taggedItemsToUpdate.length)
    await safeUpsert("taggedItem", taggedItemsToUpdate);
  if (taggedItems.length) await safeUpsert("taggedItem", taggedItems);
}
