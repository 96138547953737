/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment taskIoTypeFragment on taskIoType {
    code
    name
    isList
    description
    dataItemTypeCode
    updatedAt
    dataItemType {
      code
      name
      description
    }
    inventoryItemTypeCode
    inventoryItemType {
      code
      name
      description
    }
    ioItemTypeCode
    ioItemType {
      code
      name
      description
    }
  }
`;
