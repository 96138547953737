/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { remove, difference, noop } from "lodash";
import { reduxForm, FieldArray } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  InputField,
  TextareaField,
  SwitchField,
  DialogFooter,
  ReactColorField,
  AsyncValidateFieldSpinner
} from "@teselagen/ui";
import { Callout, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { AddButton, RemoveButton } from "../FieldArrayButtons";
import { safeUpsert, safeDelete } from "../apolloMethods";
import stripFields from "../utils/stripFields";
import { wrapDialog } from "@teselagen/ui";
import { branch } from "recompose";
import { asyncValidateName } from "../utils/formUtils";

const normalizeTagName = value => value.replace(/:/g, "");

function renderOptionRows({ fields, isSystemType, noDialog }) {
  return (
    <div
      style={{
        marginTop: 12,
        maxHeight: noDialog ? "50vh" : undefined,
        overflow: "auto"
      }}
    >
      {fields.map((field, index) => (
        <div key={index}>
          <div className="tg-flex">
            <RemoveButton fields={fields} index={index} />
            <div className="tg-flex-separator" />
            <div style={{ flex: 1 }}>
              <InputField
                name={`${field}.name`}
                isRequired
                disabled={isSystemType}
                label="Option Name"
                normalize={normalizeTagName}
              />
            </div>
            &nbsp;
            <ReactColorField
              disabled={isSystemType}
              isRequired
              name={`${field}.color`}
              label="Color"
            />
          </div>
          <TextareaField
            disabled={isSystemType}
            name={`${field}.description`}
            label="Option Description"
          />
          <hr className="tg-section-break" />
        </div>
      ))}
      <AddButton fields={fields} label="Add Option" />
    </div>
  );
}

class AddTagDialog extends Component {
  onSubmit = async values => {
    const {
      afterCreate = noop,
      refetch = noop,
      hideModal = noop,
      initialValues = {},
      tagFragment
    } = this.props;

    try {
      const tagHasOptions = values.tagHasOptions;
      const valuesToUpsert = stripFields(values, [
        "tagHasOptions",
        "__typename"
      ]);
      if (initialValues.id && valuesToUpsert.tagOptions) {
        const newOptions = remove(
          valuesToUpsert.tagOptions,
          option => !option.id
        );
        const oldOptionIds = initialValues.tagOptions.map(({ id }) => id);
        const keptOptionIds = (valuesToUpsert.tagOptions || []).map(
          ({ id }) => id
        );
        const deletedOptionIds = tagHasOptions
          ? difference(oldOptionIds, keptOptionIds)
          : oldOptionIds;
        if (tagHasOptions && newOptions.length) {
          await safeUpsert(
            "tagOption",
            newOptions.map(option => ({
              ...option,
              tagId: initialValues.id
            }))
          );
        }
        if (deletedOptionIds.length) {
          await safeDelete("tagOption", deletedOptionIds);
        }
      }

      if (!tagHasOptions && valuesToUpsert.tagOptions) {
        delete valuesToUpsert.tagOptions;
      }

      const [newTag] = await safeUpsert(tagFragment || "tag", valuesToUpsert);
      await afterCreate(newTag);
      await refetch();
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Error creating tag.");
    }
  };

  render() {
    const {
      tagHasOptions,
      handleSubmit,
      submitting,
      hideModal,
      asyncValidating,
      submitText = "Submit",
      noDialog,
      initialValues = {}
    } = this.props;
    const isSystemType = initialValues.isSystemType;
    return (
      <div className="tg-add-tag-field">
        <div
          className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}
          style={{
            marginBottom: noDialog ? 10 : 0
          }}
        >
          {isSystemType && (
            <Callout intent="primary">
              This is a system tag and cannot be edited.
            </Callout>
          )}
          <div className="tg-flex">
            <div style={{ flex: 1 }}>
              <InputField
                name="name"
                label="Name"
                isRequired
                disabled={isSystemType}
                normalize={normalizeTagName}
                rightElement={
                  <AsyncValidateFieldSpinner validating={asyncValidating} />
                }
              />
            </div>
            {!tagHasOptions && (
              <React.Fragment>
                &nbsp;
                <ReactColorField
                  name="color"
                  label="Color"
                  disabled={isSystemType}
                  isRequired
                  data-test="color-field"
                />
              </React.Fragment>
            )}
          </div>
          <TextareaField
            disabled={isSystemType}
            name="description"
            label="Description"
          />
          <SwitchField
            disabled={isSystemType}
            name="tagHasOptions"
            label="Tag has Options"
            className="tg-no-form-group-margin"
          />
          {tagHasOptions && (
            <FieldArray
              name="tagOptions"
              noDialog={noDialog}
              isSystemType={isSystemType}
              component={renderOptionRows}
            />
          )}
        </div>
        <DialogFooter
          disabled={isSystemType}
          onClick={handleSubmit(this.onSubmit)}
          text={submitText}
          submitting={submitting}
          hideModal={hideModal}
        />
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  if (values.tagHasOptions && values.tagOptions && values.tagOptions.length) {
    // don't validate required color if tag has options
    delete errors.color;
  }
  if (values.tagOptions) {
    const optionsErrors = [];
    const optionsNamesMap = {};
    values.tagOptions.forEach((option, optionIndex) => {
      const tagOptionErrors = {};
      if (option && option.name) {
        if (optionsNamesMap[option.name]) {
          tagOptionErrors.name = "A different option already has that name.";
          optionsErrors[optionIndex] = tagOptionErrors;
        } else {
          optionsNamesMap[option.name] = true;
        }
      }
    });
    if (optionsErrors.length) {
      errors.tagOptions = optionsErrors;
    }
  }
  return errors;
}

export default compose(
  branch(
    props => !props.noDialog,
    wrapDialog(({ initialValues = {} }) => {
      return {
        title: initialValues.id ? "Edit Tag" : "Create Tag"
      };
    })
  ),
  withProps(() => ({
    model: "tag"
  })),
  reduxForm({
    form: "createTag",
    ...asyncValidateName,
    validate
  }),
  tgFormValues("tagHasOptions")
)(AddTagDialog);
