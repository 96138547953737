/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Icon, Tooltip } from "@blueprintjs/core";
import React, { useMemo } from "react";
import appGlobals from "../appGlobals";
import GenericSelect from "../GenericSelect";
import { popoverOverflowModifiers } from "../utils/generalUtils";

function UserSelect(props) {
  const { showEmail } = props;
  const schema = useMemo(() => {
    const toReturn = [
      {
        path: "username",
        render: (v, rec) => {
          let inner = v;
          if (rec.isDeactivated) {
            inner = (
              <span style={{ display: "flex" }}>
                <Tooltip
                  content="User is deactivated"
                  modifiers={popoverOverflowModifiers}
                  position="left"
                >
                  <Icon
                    size={12}
                    style={{ marginRight: 5 }}
                    icon="warning-sign"
                    intent="danger"
                  />
                </Tooltip>
                {inner}
              </span>
            );
          }
          if (appGlobals.currentUser && appGlobals.currentUser.id === rec.id) {
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                {inner} <Icon style={{ marginLeft: 5 }} size={12} icon="user" />
              </span>
            );
          }
          return inner;
        }
      }
    ];
    if (showEmail) {
      toReturn.push({
        path: "email"
      });
    }
    return toReturn;
  }, [showEmail]);
  return (
    <GenericSelect
      schema={schema}
      reactSelectProps={{
        resetOnSelect: true
      }}
      firstItemsToShow={[appGlobals.currentUser]}
      asReactSelect
      queryOptions={{
        variables: {
          sort: ["isDeactivated", "username", "-updatedAt"]
        }
      }}
      fragment={[
        "user",
        `id username isDeactivated ${showEmail ? "email" : ""}`
      ]}
      {...props}
    />
  );
}

export default UserSelect;
