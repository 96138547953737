/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Classes } from "@blueprintjs/core";
import { DataTable, withSelectedEntities, DialogFooter } from "@teselagen/ui";
import asPromiseDialog from "../../../utils/asPromiseDialog";

class DuplicatesDialog extends Component {
  state = { isOpen: true };

  onCancel = () => {
    const { resolve } = this.props;
    resolve({
      userCancelled: true
    });
  };

  onConfirm = () => {
    const { resolve, chooseDuplicatesDialogSelectedEntities = [] } = this.props;
    resolve(chooseDuplicatesDialogSelectedEntities);
  };

  render() {
    const { entities, schema = ["id", "name"], message } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <div style={{ marginBottom: 15 }}>{message}</div>
          <DataTable
            isSimple
            formName="chooseDuplicatesDialog"
            withCheckboxes
            entities={entities}
            schema={schema}
          />
        </div>
        <DialogFooter
          secondaryAction={this.onCancel}
          onClick={this.onConfirm}
        />
      </React.Fragment>
    );
  }
}

const DuplicatesDialogWrapped = withSelectedEntities("chooseDuplicatesDialog")(
  DuplicatesDialog
);

export default asPromiseDialog(DuplicatesDialogWrapped);
