/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createSelector } from "reselect";
import { get, flatMap, findIndex, find, omitBy, size } from "lodash";
import {
  DEFAULT_SPACE_BETWEEN_CARDS,
  DESIGN_ELEMENT_CARD_X_PADDING,
  REACTION_COLORS,
  DESIGN_CARD_BASE_HEIGHT,
  LIST_ELEMENT_ROW_HEIGHT,
  VALIDATOR_HEIGHT,
  VALIDATOR_ICON_WIDTH,
  SBOL_ICON_WIDTH,
  DESIGN_CARD_MIN_WIDTH
} from "../components/HierarchicalDesign/constants";
import { TYPE_IIS_ENZYME } from "../constants/validatorTypes";
import {
  getAllOfType,
  getLeafSets,
  createCardTree,
  doesCardHaveConstructAnnotations,
  getChildSets,
  getBinIdsInCard,
  getViewOptionForCard,
  getNumberOfRowsForCard,
  getInputReactionIdOfCard,
  getItemOfType,
  getBinCardByCardIdAndBinId,
  getBinsInCard,
  getLevelOfCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { doesCardHaveValidatedJunctionsToRender } from "../../../tg-iso-design/selectors/junctionSelectors";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

const getSelectedCardId = state => state.ui.designEditor.general.selectedCardId;
const getSelectedBinIds = state => state.ui.designEditor.general.selectedBinIds;
const getSelectedCellPaths = state =>
  state.ui.designEditor.general.selectedCellPaths;
const getActiveBinId = state => state.ui.designEditor.general.activeBinId;
const getActiveCellPath = state => state.ui.designEditor.general.activeCellPath;
const getSelectedReactionId = state =>
  state.ui.designEditor.general.selectedReactionId ||
  //default the selectedReactionId to the first reaction if no reaction has been selected yet (this prevents the inspector panel from being empty if no reaction has been selected)
  Object.keys(state.design.reaction || {})[0];
const getSelectedValidationSetId = state =>
  state.ui.designEditor.general.selectedValidationSetId;
const getSelectedValidatedJunctionId = state =>
  state.ui.designEditor.general.selectedValidatedJunctionId;
const getFirstRowSelectedIndex = state =>
  state.ui.designEditor.general.firstRowIndexSelected;

const getSelectedBins = createSelector(
  state => getAllOfType(state, "bin"),
  getSelectedBinIds,
  (bins, selectedBinIds) => selectedBinIds.map(binId => bins[binId])
);

const getCopiedCells = state => state.ui.designEditor.general.copiedCells;

const isCardSelected = (state, cardId) => getSelectedCardId(state) === cardId;

const isBinSelected = tgCreateCachedSelector(
  (_state, cardId) => cardId,
  (_state, _cardId, binId) => binId,
  getSelectedCardId,
  getSelectedBinIds,
  (cardId, binId, selectedCardId, selectedBinIds) =>
    cardId === selectedCardId && selectedBinIds.includes(binId)
)((_state, cardId, binId) => `${cardId}:${binId}`);

const isCellSelected = tgCreateCachedSelector(
  (_state, cardId) => cardId,
  (_state, _cardId, binId) => binId,
  (_state, _cardId, _binId, index) => index,
  getSelectedCardId,
  getSelectedCellPaths,
  (cardId, binId, index, selectedCardId, selectedCellPaths) =>
    cardId === selectedCardId &&
    selectedCellPaths.some(p => p.binId === binId && p.index === index)
)((_state, cardId, binId, index) => `${cardId}:${binId}:${index}`);

const isValidationSetSelected = (state, validatorId) =>
  getSelectedValidationSetId(state) === validatorId;

const getSelectedBin = createSelector(
  state => getAllOfType(state, "bin"),
  getSelectedBinIds,
  (bins, selectedBinIds) => {
    if (!selectedBinIds.length) return null;
    return bins[selectedBinIds[0]];
  }
);

const getNonRootBinCards = createSelector(
  state => getAllOfType(state, "card"),
  state => getAllOfType(state, "binCard"),
  (cards, binCards) => {
    return omitBy(binCards, binCard => cards[binCard.cardId].isRoot);
  }
);

const getSelectedBinNumber = createSelector(
  state => getAllOfType(state, "bin"),
  getSelectedBinIds,
  state => {
    const selectedCardId = getSelectedCardId(state);
    return getBinsInCard(state, selectedCardId);
  },
  (bins, selectedBinIds, binsInCard) => {
    if (!selectedBinIds.length) return null;
    const selectedBin = bins[selectedBinIds[0]];
    const binNumber =
      findIndex(binsInCard, bin => bin.id === get(selectedBin, "id")) + 1;
    return binNumber;
  }
);

const getSelectedCard = createSelector(
  state => getAllOfType(state, "card"),
  getSelectedCardId,
  (cards, selectedCardId) => cards[selectedCardId] || null
);

const getSelectedCardLevel = createSelector(
  state => state,
  getSelectedCardId,
  (state, selectedCardId) => {
    return getLevelOfCard(state, selectedCardId);
  }
);

const getSelectedElement = createSelector(
  state => getAllOfType(state, "element"),
  getSelectedCellPaths,
  (elements, selectedCellPaths) => {
    const elementId = get(
      selectedCellPaths.filter(p => p.elementId)[0],
      "elementId"
    );
    if (!elementId) return null;
    return elements[elementId];
  }
);

const getSelectedElementFas = createSelector(
  state => getAllOfType(state, "fas"),
  getSelectedCellPaths,
  (faStrategies, selectedCellPaths) => {
    const elementId = get(
      selectedCellPaths.filter(p => p.elementId)[0],
      "elementId"
    );
    if (!elementId) return null;
    return find(faStrategies, fas => fas.elementId === elementId);
  }
);

const getSelectedBinOverhangs = createSelector(
  getNonRootBinCards,
  state => getAllOfType(state, "junction"),
  getSelectedCellPaths,
  (binCards, junctions, selectedCellPaths) => {
    // NOTE: not supported for multiple selections yet.
    if (size(selectedCellPaths) !== 1) return null;
    const binId = get(selectedCellPaths.filter(p => p.elementId)[0], "binId");
    if (!binId) return null;
    const binCard = find(binCards, binCard => binCard.binId === binId);
    const leftJunction = find(junctions, junction => {
      return junction.threePrimeCardId === binCard.cardId;
    });
    if (!leftJunction) return null;
    const leftBinCard = find(
      binCards,
      binCard => binCard.cardId === leftJunction.fivePrimeCardId
    );
    const leftJunctionBps = leftBinCard?.rightJunctionBps?.toLowerCase();
    const rightJunctionBps = binCard?.rightJunctionBps?.toLowerCase();
    return { leftOverhang: leftJunctionBps, rightOverhang: rightJunctionBps };
  }
);

const getBinOfSelectedElement = createSelector(
  state => getAllOfType(state, "bin"),
  getSelectedCellPaths,
  (bins, selectedCellPaths) => {
    const binId = get(selectedCellPaths.filter(p => p.elementId)[0], "binId");
    if (!binId) return null;
    return bins[binId];
  }
);

const getConstructAnnotationsOfCard = createSelector(
  state => state,
  state => getAllOfType(state, "constructAnnotation"),
  (state, cardId) => cardId,
  (state, constructAnnotations, cardId) => {
    return Object.values(constructAnnotations)
      .filter(ca => ca.cardId === cardId)
      .map(ca => {
        const leftBoundaryBin = getItemOfType(
          state,
          "bin",
          ca.leftBoundaryBinId
        );
        const rightBoundaryBin = getItemOfType(
          state,
          "bin",
          ca.rightBoundaryBinId
        );

        const leftBoundaryBinIndex = getBinCardByCardIdAndBinId(
          state,
          cardId,
          ca.leftBoundaryBinId
        ).index;

        const rightBoundaryBinIndex = getBinCardByCardIdAndBinId(
          state,
          cardId,
          ca.rightBoundaryBinId
        ).index;

        return {
          ...ca,
          leftBoundaryBin: {
            ...leftBoundaryBin,
            index: leftBoundaryBinIndex
          },
          rightBoundaryBin: {
            ...rightBoundaryBin,
            index: rightBoundaryBinIndex
          }
        };
      });
  }
);

const getBinOfFirstSelectedCell = createSelector(
  state => getAllOfType(state, "bin"),
  getSelectedCellPaths,
  (bins, selectedCellPaths) => {
    const binId = get(selectedCellPaths[0], "binId");
    if (!binId) return null;
    return bins[binId];
  }
);

const getTopMarginForCard = () => DEFAULT_SPACE_BETWEEN_CARDS;

const areSelectedSetsContiguous = createSelector(
  state => state,
  getSelectedBinIds,
  state => getLeafSets(state, getSelectedCardId(state)),
  (state, selectedSetIds, selectedCardLeaves) => {
    const setLeafIndices = flatMap(selectedSetIds, setId => {
      const setLeaves = getLeafSets(state, setId);
      return setLeaves.map(s =>
        selectedCardLeaves.findIndex(l => l.id === s.id)
      );
    }).sort((a, b) => a - b);

    return setLeafIndices
      .slice(1)
      .every((nextInd, i) => setLeafIndices[i] + 1 === nextInd);
  }
);

const getReactionColor = tgCreateCachedSelector(
  (_state, reactionId) => reactionId,
  createCardTree,
  (reactionId, cardSets) => {
    let depth = 0;
    let reactionDepth = null;
    const recurse = set => {
      if (set.reactionId === reactionId) reactionDepth = depth;
      depth++;
      set.children.forEach(recurse);
      depth--;
    };
    recurse(cardSets);
    if (reactionDepth === null) return "#949496";
    else return REACTION_COLORS[reactionDepth % REACTION_COLORS.length];
  }
)((_state, reactionId) => reactionId);

const getSetHeaderInformation = tgCreateCachedSelector(
  state => state,
  getChildSets,
  (state, childSets) => {
    let index = 0;
    return childSets.reduce((acc, set) => {
      if (set.isValidationSet) {
        acc.push({ index, setId: set.id });
      }
      index += getLeafSets(state, set.id).length;
      return acc;
    }, []);
  }
)((_state, cardId) => cardId);

const getCardHeight = tgCreateCachedSelector(
  getNumberOfRowsForCard,
  (state, cardId) =>
    getViewOptionForCard(state, cardId, "listElements") === "expanded",
  doesCardHaveValidatedJunctionsToRender,
  doesCardHaveConstructAnnotations,
  (
    numRows,
    areListElementsExpanded,
    hasValidatedJunction,
    hasConstructAnnotations
  ) =>
    DESIGN_CARD_BASE_HEIGHT +
    areListElementsExpanded * numRows * LIST_ELEMENT_ROW_HEIGHT +
    +hasValidatedJunction * VALIDATOR_HEIGHT +
    +hasConstructAnnotations * 20
)((_state, cardId) => cardId);

const getCardPadding = tgCreateCachedSelector(
  state => getAllOfType(state, "junction"),
  getInputReactionIdOfCard,
  (state, cardId, isLeft) => {
    const binIds = getBinIdsInCard(state, cardId);
    return isLeft ? binIds[0] : binIds[binIds.length - 1];
  },
  (_state, _cardId, isLeft) => isLeft,
  (junctions, inputReactionId, binId, isLeft) => {
    let spaceNeeded = 0;
    for (const j of Object.values(junctions)) {
      if (j.junctionTypeCode === TYPE_IIS_ENZYME) {
        // Right now, we don't care about ligated junctions.

        if (j.reactionId !== inputReactionId) continue;

        const junctionBinId = isLeft
          ? j.threePrimeCardStartBinId
          : j.fivePrimeCardEndBinId;

        if (junctionBinId !== binId) continue;
        const overhangType = isLeft
          ? j.threePrimeCardOverhangTypeCode
          : j.fivePrimeCardOverhangTypeCode;

        spaceNeeded = Math.max(
          spaceNeeded,
          overhangType === "FLANKING_SEQUENCE"
            ? 2 * VALIDATOR_ICON_WIDTH
            : VALIDATOR_ICON_WIDTH
        );
      }
    }
    return spaceNeeded + DESIGN_ELEMENT_CARD_X_PADDING / 2;
  }
)((_state, cardId, isLeft) => `${cardId}:${!!isLeft}`);

const getCardTotalPadding = (state, cardId) =>
  getCardPadding(state, cardId, true) + getCardPadding(state, cardId, false);

const getAllCardWidths = state => state.ui.designEditor.tree.cardWidths;

const getMinCardWidth = tgCreateCachedSelector(
  getBinIdsInCard,
  getCardTotalPadding,
  (binIds, padding) =>
    Math.max(SBOL_ICON_WIDTH * binIds.length + padding, DESIGN_CARD_MIN_WIDTH)
)((_state, cardId) => cardId);

const getCardWidth = tgCreateCachedSelector(
  (_state, cardId) => cardId,
  getAllCardWidths,
  getMinCardWidth,
  (cardId, cardWidths, minCardWidth) => {
    const cardWidth = cardWidths[cardId] || 0;
    return Math.max(cardWidth, minCardWidth);
  }
)((_state, cardId) => cardId);

const getSingleBlockWidth = tgCreateCachedSelector(
  getBinIdsInCard,
  getCardWidth,
  getCardTotalPadding,
  (binIds, cardWidth, padding) => (cardWidth - padding) / binIds.length
)((_state, cardId) => cardId);

// having one export source make easier to find these methods
export {
  getSelectedCardId,
  getSelectedBinIds,
  getSelectedCellPaths,
  getActiveBinId,
  getActiveCellPath,
  getSelectedReactionId,
  getSelectedValidationSetId,
  getSelectedValidatedJunctionId,
  getFirstRowSelectedIndex,
  getSelectedBins,
  getCopiedCells,
  isCardSelected,
  isBinSelected,
  isCellSelected,
  isValidationSetSelected,
  getSelectedBin,
  getSelectedBinNumber,
  getSelectedCard,
  getSelectedCardLevel,
  getSelectedElement,
  getSelectedElementFas,
  getSelectedBinOverhangs,
  getBinOfSelectedElement,
  getBinOfFirstSelectedCell,
  getTopMarginForCard,
  areSelectedSetsContiguous,
  getReactionColor,
  getSetHeaderInformation,
  getCardHeight,
  getCardPadding,
  getCardTotalPadding,
  getAllCardWidths,
  getMinCardWidth,
  getCardWidth,
  getSingleBlockWidth,
  getConstructAnnotationsOfCard
};
