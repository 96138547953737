/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const sequenceAssociationTableFragment = gql`
  fragment sequenceAssociationTableFragment on dataTable {
    id
    name
    dataRows {
      id
      index
      key
      rowValues
    }
  }
`;
