/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get, forEach, isEmpty } from "lodash";
import { Button, Tooltip, Icon } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import {
  plasmidPurificationPlateFragment,
  plasmidPurificationTubeFragment,
  plasmidPurificationPlateMapGroupFragment
} from "../fragments";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import {
  additionalFilterForTubes,
  validateMicrobialMaterial
} from "../../../../utils/plateUtils";
import getReactionInputsAndOutputs from "../../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

class SelectMaterials extends Component {
  prepReactionMap = values => {
    const { sourceTubes = [], sourcePlates = [], plateMapGroups = [] } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const reactions = [];
    const inputMaterials = [];
    const inputToOutputMaterialMap = {};
    const plasmidIdToPlasmidMap = {};
    const plateWells = [];
    const mapInputs = inputMaterial => {
      inputMaterial.microbialMaterialMicrobialMaterialPlasmids.length > 0 &&
        inputMaterial.microbialMaterialMicrobialMaterialPlasmids.forEach(
          mmp => {
            const plasmidMaterial = get(mmp, "polynucleotideMaterial");
            if (
              inputToOutputMaterialMap[inputMaterial.id] &&
              !inputToOutputMaterialMap[inputMaterial.id].includes(
                plasmidMaterial.id
              )
            ) {
              inputToOutputMaterialMap[inputMaterial.id].push(
                plasmidMaterial.id
              );
              plasmidIdToPlasmidMap[plasmidMaterial.id] = plasmidMaterial;
            } else {
              inputToOutputMaterialMap[inputMaterial.id] = [plasmidMaterial.id];
              plasmidIdToPlasmidMap[plasmidMaterial.id] = plasmidMaterial;
              inputMaterials.push(inputMaterial);
            }
          }
        );
    };

    sourcePlates.forEach(plate =>
      plate.aliquotContainers.forEach(ac => plateWells.push(ac))
    );
    const allAliquotContainers = plateWells.concat(sourceTubes);
    allAliquotContainers.forEach(ac => {
      if (ac.aliquot) {
        const inputMaterial = get(ac, "aliquot.sample.material");
        const inputSample = get(ac, "aliquot.sample");
        if (inputMaterial) {
          mapInputs(inputMaterial);
        } else if (
          inputSample &&
          inputSample.sampleTypeCode === "FORMULATED_SAMPLE"
        ) {
          inputSample.sampleFormulations.forEach(sf => {
            sf.materialCompositions.forEach(mc => {
              mapInputs(mc.material);
            });
          });
        }
      }
    });
    plateMapGroups.forEach(pmg =>
      pmg.plateMaps.forEach(pm =>
        pm.plateMapItems.forEach(pmi => {
          const inputMaterial = get(pmi, "inventoryItem.material");
          const inputSample = get(pmi, "inventoryItem.sample");
          if (inputMaterial) {
            mapInputs(inputMaterial);
          } else if (inputSample && inputSample.material) {
            mapInputs(inputSample.material);
          } else if (
            inputSample &&
            inputSample.sampleTypeCode === "FORMULATED_SAMPLE"
          ) {
            inputSample.sampleFormulations.forEach(sf => {
              sf.materialCompositions.forEach(mc => {
                mapInputs(mc.material);
              });
            });
          }
        })
      )
    );
    forEach(inputToOutputMaterialMap, (outputMaterialIds, inputMaterialId) => {
      const inputMaterial = inputMaterials.find(
        material => material.id === inputMaterialId
      );
      outputMaterialIds.forEach(outputMaterialId => {
        const outputMaterial = plasmidIdToPlasmidMap[outputMaterialId];
        reactions.push({
          name: `${inputMaterial.name} > ${outputMaterial.name}`,
          inputMaterial,
          outputMaterial,
          ...getReactionInputsAndOutputs({
            outputMaterials: [outputMaterial],
            inputMaterials: [inputMaterial]
          })
        });
      });
    });

    change("reactions", reactions);
    nextStep();
  };

  render() {
    const {
      footerProps,
      Footer,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      handleSubmit,
      sourcePlates = [],
      sourceTubes = [],
      plateMapGroups = []
    } = this.props;

    const errorsForPlates = {};
    const warningsForPlates = {};
    const errorsForTubes = {};
    const errorsForPlateMapGroups = {};
    const warningsForPlateMapGroups = {};

    sourcePlates.forEach(plate => {
      let noMaterials = true;
      let invalidMaterialTypes = true;
      let unlinkedSequences = false;
      let unlinkedSequenceMaterials = false;

      plate.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          let isUnlinked = false;
          let plasmidUnlinked = false;
          const validateMaterial = material => {
            if (material) {
              noMaterials = false;
              const materialType = material.materialTypeCode;
              if (materialType === "MICROBIAL") {
                invalidMaterialTypes = false;
              }
              const {
                missingPlasmid,
                plasmidMissingMaterial
              } = validateMicrobialMaterial(material);
              isUnlinked = missingPlasmid;
              plasmidUnlinked = plasmidMissingMaterial;
            }
          };
          if (get(ac, "aliquot.sample.material")) {
            noMaterials = false;
            validateMaterial(get(ac, "aliquot.sample.material"));
          } else if (
            get(ac, "aliquot.sample.sampleTypeCode") === "FORMULATED_SAMPLE"
          ) {
            ac.aliquot.sample.sampleFormulations.forEach(sf => {
              sf.materialCompositions.forEach(mc => {
                if (mc.material) {
                  validateMaterial(mc.material);
                }
              });
            });
          }
          if (isUnlinked) {
            unlinkedSequences = true;
          }
          if (plasmidUnlinked) {
            unlinkedSequenceMaterials = true;
          }
        }
      });

      if (noMaterials) {
        errorsForPlates[
          plate.id
        ] = `No aliquots on ${plate.name} are linked to materials or samples.`;
      } else if (invalidMaterialTypes) {
        errorsForPlates[
          plate.id
        ] = `No aliquots on ${plate.name} are linked to microbial materials.`;
      } else if (unlinkedSequences) {
        errorsForPlates[
          plate.id
        ] = `Some materials on ${plate.name} are missing sequence data.`;
      } else if (unlinkedSequenceMaterials) {
        errorsForPlates[
          plate.id
        ] = `Some plasmids on ${plate.name} are not linked to materials.`;
      } else if (invalidMaterialTypes) {
        errorsForPlates[
          plate.id
        ] = `Some aliquots on ${plate.name} are not linked to microbial materials. These will be ignored.`;
      }
    });

    sourceTubes.forEach(tube => {
      const addError = msg => {
        errorsForTubes[tube.id] = `${tube.name} ${
          tube.barcode && tube.barcode.barcodeString
            ? `(${tube.barcode.barcodeString})`
            : ""
        } ${msg}`;
      };
      const validateMaterial = material => {
        if (material.materialTypeCode !== "MICROBIAL") {
          addError("does not contain a microbial material");
        } else {
          const {
            missingPlasmid,
            plasmidMissingMaterial
          } = validateMicrobialMaterial(material);
          if (missingPlasmid) {
            addError("has a microbial material with no attached plasmids.");
          }
          if (plasmidMissingMaterial) {
            addError("has a plasmid that is not linked to a material.");
          }
        }
      };
      if (get(tube, "aliquot.sample.material")) {
        validateMaterial(tube.aliquot.sample.material);
      } else if (
        get(tube, "aliquot.sample.sampleTypeCode") === "FORMULATED_SAMPLE"
      ) {
        tube.aliquot.sample.sampleFormulations.forEach(sf => {
          sf.materialCompositions.forEach(mc => {
            if (mc.material) {
              validateMaterial(mc.material);
            }
          });
        });
      } else {
        addError("does not have a material.");
      }
    });

    plateMapGroups.forEach(pmg => {
      let noMaterials = true;
      let invalidMaterialTypes = false;
      let missingPlasmids = false;
      let missingPlasmidMaterials = false;

      const validateMaterial = material => {
        if (material) {
          noMaterials = false;
          if (material.materialTypeCode !== "MICROBIAL") {
            invalidMaterialTypes = true;
          } else {
            const {
              missingPlasmid,
              missingPlasmidMaterial
            } = validateMicrobialMaterial(material);
            if (missingPlasmid) {
              missingPlasmids = true;
            } else if (missingPlasmidMaterial) {
              missingPlasmidMaterials = true;
            }
          }
        }
      };

      pmg.plateMaps.forEach(pm => {
        pm.plateMapItems.forEach(pmi => {
          if (pm.type === "material") {
            validateMaterial(get(pmi, "inventoryItem.material"));
          } else if (pm.type === "sample") {
            const sample = get(pmi, "inventoryItem.sample");
            if (sample.material) {
              validateMaterial(sample.material);
            } else if (sample.sampleTypeCode === "FORMULATED_SAMPLE") {
              sample.sampleFormulations.forEach(sf => {
                sf.materialCompositions.forEach(mc => {
                  validateMaterial(mc.material);
                });
              });
            }
          }
        });
      });

      if (noMaterials) {
        errorsForPlateMapGroups[
          pmg.id
        ] = `No items on ${pmg.name} are linked to materials.`;
      } else if (invalidMaterialTypes) {
        errorsForPlateMapGroups[
          pmg.id
        ] = `No items on ${pmg.name} are linked to microbial materials.`;
      } else if (missingPlasmids) {
        errorsForPlateMapGroups[
          pmg.id
        ] = `Microbial materials on ${pmg.name} are missing plasmids.`;
      } else if (missingPlasmidMaterials) {
        errorsForPlateMapGroups[
          pmg.id
        ] = `Microbial materials on ${pmg.name} have plasmids that are not linked to materials.`;
      }
    });

    const hasInputErrors =
      !isEmpty(errorsForPlates) ||
      !isEmpty(errorsForTubes) ||
      !isEmpty(errorsForPlateMapGroups);

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates From Inventory"
            helper="Select one or more plates of Microbial material."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "sourcePlates",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.containerArrays,
                  loading: isLoadingMap.containerArrays
                },
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: plasmidPurificationPlateFragment,
                postSelectDTProps: {
                  formName: "selectedPlates",
                  destroyOnUnmount: false,
                  keepDirtyOnReinitialize: true,
                  enableReinitialize: true,
                  updateUnregisteredFields: true,
                  isSingleSelect: true,
                  isSimple: true,
                  errorsForPlates,
                  warningsForPlates,
                  schema: [
                    {
                      type: "action",
                      width: 35,
                      render: (_, record) => {
                        const errorOrWarning =
                          errorsForPlates[record.id] ||
                          warningsForPlates[record.id];
                        if (errorOrWarning) {
                          let isError;
                          if (errorsForPlates[record.id]) {
                            isError = true;
                          } else {
                            isError = false;
                          }
                          return (
                            <Tooltip
                              content={
                                errorsForPlates[record.id] ||
                                warningsForPlates[record.id]
                              }
                            >
                              <Icon
                                intent={isError ? "danger" : "warning"}
                                style={{ marginRight: 10 }}
                                icon="warning-sign"
                              />
                            </Tooltip>
                          );
                        }
                      }
                    },
                    platePreviewColumn(),
                    "name",
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Tubes From Inventory"
            helper="Select one or more tubes of microbial material."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "sourceTubes",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                dialogProps: {
                  style: {
                    width: 800
                  }
                },
                fragment: [
                  "aliquotContainer",
                  "id name barcode { id barcodeString } aliquot { id sample { id material { id name } } } updatedAt"
                ],
                additionalDataFragment: plasmidPurificationTubeFragment,
                tableParamOptions: {
                  additionalFilter: additionalFilterForTubes
                },
                postSelectDTProps: {
                  formName: "selectedTubes",
                  destroyOnUnmount: false,
                  keepDirtyOnReinitialize: true,
                  enableReinitialize: true,
                  updateUnregisteredFields: true,
                  isSimple: true,
                  errorsForTubes,
                  schema: [
                    {
                      type: "action",
                      width: 35,
                      render: (_, record) => {
                        if (errorsForTubes[record.id]) {
                          return (
                            <Tooltip content={errorsForTubes[record.id]}>
                              <Icon
                                intent="danger"
                                style={{ marginRight: 10 }}
                                icon="warning-sign"
                              />
                            </Tooltip>
                          );
                        }
                      }
                    },
                    "name",
                    {
                      displayName: "Tube Type",
                      path: "aliquotContainerType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    },
                    {
                      displayName: "Material",
                      path: "aliquot.sample.material.name"
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plate Maps"
            helper="Select one or more plate maps of microbial material."
          />
          <div>
            <GenericSelect
              {...{
                name: "plateMapGroups",
                isMultiSelect: true,
                schema: [
                  "name",
                  { displayName: "Plate Format", path: "containerFormat.name" },
                  dateModifiedColumn
                ],
                fragment: [
                  "plateMapGroup",
                  "id name containerFormatCode containerFormat { code name } updatedAt"
                ],
                buttonProps: {
                  loading: isLoadingMap.plateMapGroup,
                  disabled: isDisabledMap.plateMapGroup
                },
                additionalDataFragment: plasmidPurificationPlateMapGroupFragment,
                tableParamOptions: additionalFilterForPlateMapGroups,
                postSelectDTProps: {
                  formName: "selectedPlateMapGroups",
                  destroyOnUnmount: false,
                  keepDirtyOnReinitialize: true,
                  enableReinitialize: true,
                  updateUnregisteredFields: true,
                  isSimple: true,
                  errorsForPlateMapGroups,
                  warningsForPlateMapGroups,
                  schema: [
                    {
                      type: "action",
                      width: 35,
                      render: (_, record) => {
                        const errorOrWarning =
                          errorsForPlateMapGroups[record.id] ||
                          warningsForPlateMapGroups[record.id];
                        if (errorOrWarning) {
                          let isError;
                          if (errorsForPlateMapGroups[record.id]) {
                            isError = true;
                          } else {
                            isError = false;
                          }
                          return (
                            <Tooltip
                              content={
                                errorsForPlateMapGroups[record.id] ||
                                warningsForPlateMapGroups[record.id]
                              }
                            >
                              <Icon
                                intent={isError ? "danger" : "warning"}
                                style={{ marginRight: 10 }}
                                icon="warning-sign"
                              />
                            </Tooltip>
                          );
                        }
                      }
                    },
                    platePreviewColumn(),
                    "name",
                    "containerFormat.name"
                  ]
                }
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent="primary"
              disabled={hasInputErrors}
              onClick={handleSubmit(this.prepReactionMap)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "sourcePlates",
  "sourceTubes",
  "plateMapGroups"
)(SelectMaterials);

const additionalFilterForPlateMapGroups = (props, qb) => {
  qb.whereAll({
    "plateMap.type": ["sample", "material"]
  });
};
