/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * Execute the given aliquot formulations when we are in the browser.
 *
 * This function works by sending a request to the backend that processes
 *
 * @param {Array<AliquotFormulation>} aliquotFormulations
 * @param {Object} options
 * @returns {Object} See documentation for `executeAliquotFormulations` for information.
 */
async function executeBrowserAliquotFormulations(aliquotFormulations, options) {
  if (window.localStorage.getItem("authToken-teselagen")) {
    const { data } = await window.serverApi.request({
      method: "POST",
      url: "/executeAliquotFormulations",
      withCredentials: true,
      data: {
        aliquotFormulations: aliquotFormulations.map(af => af.toJson()),
        options
      }
    });
    if (!data.success) throw new Error(data.error);
    return data;
  } else {
    throw new Error(
      "No auth token found. It means that something went wrong or that there's not user logged."
    );
  }
}

export default executeBrowserAliquotFormulations;
