/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import {
  worklistPlanningDataTableAliquotContainerFragment,
  worklistPlanningDataTableFragment,
  worklistPlanningPlateFragment,
  worklistPlanningTubeFragment
} from "./fragments";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { forEach, get, keyBy, isEmpty } from "lodash";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { Callout } from "@blueprintjs/core";
import { CheckboxField, DataTable, RadioGroupField } from "@teselagen/ui";
import { validateTransfers } from "../../../utils";
import withQuery from "../../../../src-shared/withQuery";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getKeyAdditivesByReagentIdFromAliquotContainer,
  getMaterialIdsFromAliquotContainer,
  getReagentIdsFromAliquotContainer,
  getUsableVolumeOfAliquotContainer
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { capitalize } from "lodash";
import UnitInputField from "../../UnitInputField";
import {
  defaultMassUnitCode,
  defaultVolumetricUnitCode
} from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { getAliquotTransferVolumeFromMass } from "../../../utils/plateUtils";
import pluralize from "pluralize";
import fieldConstants from "./fieldConstants";

class SourceMaterials extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const {
      reactionMaps = [],
      plateToReactionMap = {},
      destinationPlates
    } = this.props;
    this.inputMaterialIds = [];
    this.inputMaterials = {};
    this.inputReagentIds = [];
    this.inputReagents = {};
    let keyedReactions = {};
    reactionMaps.forEach(
      rm =>
        (keyedReactions = {
          ...keyedReactions,
          ...keyBy(get(rm, "reactions"), "id")
        })
    );
    reactionMaps.forEach(rm => {
      rm.reactions.forEach(r => {
        r.reactionInputs.forEach(input => {
          const materialId = get(input, "inputMaterial.id");
          const reagentId = get(input, "inputAdditiveMaterial.id");
          if (materialId && !this.inputMaterialIds.includes(materialId)) {
            this.inputMaterialIds.push(materialId);
            this.inputMaterials[materialId] = input.inputMaterial;
          }
          if (reagentId && !this.inputReagentIds.includes(reagentId)) {
            this.inputReagentIds.push(reagentId);
            this.inputReagents[reagentId] = input.inputAdditiveMaterial;
          }
        });
      });
    });
    // keep track of how many transfers we need for each source material
    // this will store an array of destination aliquot containers for each
    // source material id
    this.materialIdToDestACs = {};
    this.reagentIdToDestACs = {};
    const platesAcMap = {};
    const plateIdToName = {};
    destinationPlates.forEach(p => {
      plateIdToName[p.id] = p.name;
      platesAcMap[p.id] = {};
      p.aliquotContainers.forEach(ac => {
        platesAcMap[p.id][getAliquotContainerLocation(ac)] = ac;
      });
    });
    this.neededInputMaterialIds = [];
    this.neededInputMaterials = [];
    this.neededInputReagentIds = [];
    this.neededInputReagents = [];
    this.matIdsForDestAcTracker = {};
    this.reagentIdsForDestAcTracker = {};

    this.reactionToNeededInputs = {};

    forEach(plateToReactionMap, (locationMap, plateId) => {
      forEach(locationMap, (reactionIds, location) => {
        const materialIdsForLocation = [];
        const reagentIdsForLocation = [];
        reactionIds.forEach(reactionId => {
          const reactionInfoHelper = {
            materialIds: [],
            reagentIds: [],
            destinationAcIds: []
          };
          this.reactionToNeededInputs[reactionId] = reactionInfoHelper;
          keyedReactions[reactionId].reactionInputs.forEach(input => {
            const material = get(input, "inputMaterial");
            const reagent = get(input, "inputAdditiveMaterial");
            const ac = platesAcMap[plateId][location];

            if (!reactionInfoHelper.destinationAcIds.includes(ac.id)) {
              reactionInfoHelper.destinationAcIds.push(ac.id);
            }
            if (material) {
              const materialId = material.id;
              this.reactionToNeededInputs[reactionId].materialIds.push(
                materialId
              );
              if (!this.materialIdToDestACs[materialId]) {
                this.materialIdToDestACs[materialId] = [];
              }
              if (!this.matIdsForDestAcTracker[ac.id]) {
                this.matIdsForDestAcTracker[ac.id] =
                  getMaterialIdsFromAliquotContainer(ac);
              }
              const materialIdArray = this.matIdsForDestAcTracker[ac.id];
              // the needed material might already be at the destination
              if (!materialIdArray.includes(materialId)) {
                materialIdsForLocation.push(materialId);
                if (!this.neededInputMaterialIds.includes(materialId)) {
                  this.neededInputMaterials.push(material);
                  this.neededInputMaterialIds.push(materialId);
                }
                this.materialIdToDestACs[materialId].push({
                  ...platesAcMap[plateId][location],
                  plateName: plateIdToName[plateId]
                });
              }
            }
            if (reagent) {
              const reagentId = reagent.id;
              this.reactionToNeededInputs[reactionId].reagentIds.push(
                reagentId
              );
              if (!this.reagentIdToDestACs[reagentId]) {
                this.reagentIdToDestACs[reagentId] = [];
              }
              if (!this.reagentIdsForDestAcTracker[ac.id]) {
                this.reagentIdsForDestAcTracker[ac.id] =
                  getReagentIdsFromAliquotContainer(ac);
              }
              const reagentIdArray = this.reagentIdsForDestAcTracker[ac.id];
              // the needed material might already be at the destination
              if (!reagentIdArray.includes(reagentId)) {
                reagentIdsForLocation.push(reagentId);
                if (!this.neededInputReagentIds.includes(reagentId)) {
                  this.neededInputReagents.push(reagent);
                  this.neededInputReagentIds.push(reagentId);
                }
                this.reagentIdToDestACs[reagentId].push({
                  ...platesAcMap[plateId][location],
                  plateName: plateIdToName[plateId]
                });
              }
            }
          });
        });
      });
    });
  }

  fetchMaterialsForTables = async tables => {
    try {
      this.setState({
        loadingTableAliquots: true
      });
      const {
        stepFormProps: { change }
      } = this.props;
      change("tableAliquotContainers", []);
      const aliquotIds = [];
      tables.forEach(table => {
        table.dataRows.forEach(row => {
          const aliquotId = row.rowValues?.aliquotId;
          if (aliquotId) {
            aliquotIds.push(aliquotId);
          }
        });
      });

      if (!aliquotIds.length) {
        return window.toastr.warning("No aliquots found on tables.");
      }

      const aliquotContainers = await safeQuery(
        worklistPlanningDataTableAliquotContainerFragment,
        {
          variables: {
            filter: {
              aliquotId: aliquotIds
            }
          }
        }
      );
      if (!aliquotContainers.length) {
        return window.toastr.error(
          "Table aliquots were not linked to plates or tubes."
        );
      }
      change("tableAliquotContainers", aliquotContainers);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error fetching table aliquots");
    }
    this.setState({
      loadingTableAliquots: false
    });
  };

  render() {
    const { loadingTableAliquots } = this.state;
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      sourceTubes = [],
      sourcePlates = [],
      tableAliquotContainers = [],
      destinationPlates = [],
      Footer,
      footerProps,
      aliquotContainerTypes = [],
      handleSubmit,
      nextStep,
      stepFormProps: { change },
      reagentTransferSettings = {},
      materialTransferSettings = {},
      toolSchema
    } = this.props;

    const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");

    const destinationPlateIds = [];
    destinationPlates.forEach(destP => {
      if (!destP.id.includes("__")) {
        destinationPlateIds.push(destP.id);
      }
    });

    let allSourceAliquotContainers = sourceTubes.concat(tableAliquotContainers);
    sourcePlates.forEach(p => {
      allSourceAliquotContainers = allSourceAliquotContainers.concat(
        p.aliquotContainers.map(ac => ({
          ...ac,
          containerArray: {
            id: p.id,
            name: p.name
          }
        }))
      );
    });

    const materialToSourceACTracker = {};
    const reagentToSourceACTracker = {};

    allSourceAliquotContainers.forEach(sourceAc => {
      const materialId = get(sourceAc, "aliquot.sample.materialId");
      const reagentIds = getReagentIdsFromAliquotContainer(sourceAc);
      if (materialId && this.inputMaterialIds.includes(materialId)) {
        materialToSourceACTracker[materialId] =
          materialToSourceACTracker[materialId] || [];
        materialToSourceACTracker[materialId].push(sourceAc);
      }
      reagentIds.forEach(reagentId => {
        if (this.inputReagentIds.includes(reagentId)) {
          reagentToSourceACTracker[reagentId] =
            reagentToSourceACTracker[reagentId] || [];
          reagentToSourceACTracker[reagentId].push(sourceAc);
        }
      });
    });

    // go through all reactions they want to perform (could be multiple of the same reaction)
    // make sure there is enough of each source material for all reactions, including dead volume of source containers

    // sort the source materials by largest volume first
    forEach(materialToSourceACTracker, (sourceAcs, key) => {
      materialToSourceACTracker[key] = sourceAcs.sort(
        (a, b) =>
          getUsableVolumeOfAliquotContainer(b, keyedAliquotContainerTypes) -
          getUsableVolumeOfAliquotContainer(a, keyedAliquotContainerTypes)
      );
    });
    forEach(reagentToSourceACTracker, (sourceAcs, key) => {
      reagentToSourceACTracker[key] = sourceAcs.sort(
        (a, b) =>
          getUsableVolumeOfAliquotContainer(b, keyedAliquotContainerTypes) -
          getUsableVolumeOfAliquotContainer(a, keyedAliquotContainerTypes)
      );
    });

    // first make sure there is an aliquot for all needed input materials
    // needed input materials are ones that are not already in the destination wells
    let missingMaterialMessage = "";
    const missingMaterialIds = [];
    this.neededInputMaterialIds.forEach(id => {
      if (!materialToSourceACTracker[id]) {
        if (!missingMaterialMessage)
          missingMaterialMessage +=
            "The following input materials are missing from selected inventory:\n";
        missingMaterialIds.push(id);
        missingMaterialMessage += `${this.inputMaterials[id].name}\n`;
      }
    });
    let missingReagentMessage = "";
    const missingReagentIds = [];

    // if all reagent ids are missing then hide the reagent transfer volume field
    this.neededInputReagentIds.forEach(id => {
      if (!reagentToSourceACTracker[id]) {
        if (!missingReagentMessage)
          missingReagentMessage +=
            "The following input reagents are missing from selected inventory:\n";
        missingReagentIds.push(id);
        missingReagentMessage += `${this.inputReagents[id].name}\n`;
      }
    });

    const worklistTransfers = [];

    const allMaterialsMissing =
      this.neededInputMaterialIds.length &&
      missingMaterialIds.length === this.neededInputMaterialIds.length;
    const allReagentsMissing =
      this.neededInputReagentIds.length &&
      missingReagentIds.length === this.neededInputReagentIds.length;

    let errorMessage = "";
    // next make sure that there is enough volume for all input reactions
    if (!allMaterialsMissing || !allReagentsMissing) {
      const acToAvailableVolume = {};

      const tryToAddTransfer = ({ reagentId, materialId, destACs }) => {
        const transferSettings = reagentId
          ? reagentTransferSettings
          : materialTransferSettings;
        const {
          transferType,
          transferVolume,
          transferVolumetricUnitCode,
          transferInfo = {},
          applyUniversalTransfer,
          transferMass,
          transferMassUnitCode
        } = transferSettings;
        const standardizedTransferVolume = standardizeVolume(
          isValidPositiveNumber(transferVolume) ? transferVolume : 0,
          transferVolumetricUnitCode || "uL",
          true
        );

        let standardizedTransferVolumeToUse,
          transferVolumeToUse,
          transferVolumetricUnitCodeToUse;
        const itemId = materialId || reagentId;
        const transferInfoForItem = transferInfo[itemId] || {};

        if (transferType === "mass") {
          // this will be handled individually per aliquot below
        } else {
          if (applyUniversalTransfer) {
            standardizedTransferVolumeToUse = standardizedTransferVolume;
            transferVolumeToUse = isValidPositiveNumber(transferVolume)
              ? transferVolume
              : 0;
            transferVolumetricUnitCodeToUse = transferVolumetricUnitCode;
          } else {
            transferVolumeToUse = isValidPositiveNumber(
              transferInfoForItem.volume
            )
              ? transferInfoForItem.volume
              : 0;
            standardizedTransferVolumeToUse = standardizeVolume(
              transferVolumeToUse,
              transferInfoForItem.volumetricUnitCode ||
                defaultVolumetricUnitCode,
              true
            );
            transferVolumetricUnitCodeToUse =
              transferInfoForItem.volumetricUnitCode ||
              defaultVolumetricUnitCode;
          }
        }
        let sourceAcTracker, inputItems;
        if (materialId) {
          sourceAcTracker = materialToSourceACTracker;
          inputItems = this.inputMaterials;
        } else {
          sourceAcTracker = reagentToSourceACTracker;
          inputItems = this.inputReagents;
        }
        const acIdToKeyReagents = {};
        // a small cache helper
        const sourceItemIdToTransferVolume = {};
        if (sourceAcTracker[itemId]) {
          const matErr = `Not enough source volume for ${
            materialId ? "material" : "reagent"
          } ${inputItems[itemId].name}.\n`;
          const isAliquotContainerValidForTransfer = ac => {
            if (!materialId) {
              if (!acIdToKeyReagents[ac.id]) {
                acIdToKeyReagents[ac.id] =
                  getKeyAdditivesByReagentIdFromAliquotContainer(ac);
              }
            }
            if (acToAvailableVolume[ac.id] === undefined) {
              acToAvailableVolume[ac.id] = getUsableVolumeOfAliquotContainer(
                ac,
                keyedAliquotContainerTypes
              );
            }
            if (transferType === "mass") {
              // if transfer type is mass then we calculate transfer volume
              // from concentration. This means it needs to be calculated
              // for each item individually
              const sourceItem = materialId
                ? ac.aliquot
                : acIdToKeyReagents[ac.id][itemId];
              if (
                !sourceItem ||
                !sourceItem.concentration ||
                !sourceItem.volume
              )
                return false;
              transferVolumetricUnitCodeToUse = sourceItem.volumetricUnitCode;
              transferVolumeToUse = sourceItemIdToTransferVolume[sourceItem.id]
                ? sourceItemIdToTransferVolume[sourceItem.id]
                : getAliquotTransferVolumeFromMass(
                    sourceItem,
                    (applyUniversalTransfer
                      ? transferMass
                      : transferInfoForItem.mass) || 0,
                    (applyUniversalTransfer
                      ? transferMassUnitCode
                      : transferInfoForItem.massUnitCode) || defaultMassUnitCode
                  );
              sourceItemIdToTransferVolume[sourceItem.id] = transferVolumeToUse;
              standardizedTransferVolumeToUse = standardizeVolume(
                transferVolumeToUse,
                sourceItem.volumetricUnitCode || "uL",
                true
              );
            }
            return acToAvailableVolume[ac.id].gte(
              standardizedTransferVolumeToUse
            );
          };
          for (const destAliquotContainer of destACs) {
            const acToUse = sourceAcTracker[itemId].find(
              isAliquotContainerValidForTransfer
            );
            // no source aliquot containers have enough volume for transfer
            if (!acToUse) {
              errorMessage += matErr;
              break;
            } else {
              acToAvailableVolume[acToUse.id] = acToAvailableVolume[
                acToUse.id
              ].minus(standardizedTransferVolumeToUse);
              worklistTransfers.push({
                sourceAliquotContainerId: acToUse.id,
                sourceAliquotContainer: acToUse,
                destinationAliquotContainer: destAliquotContainer,
                destinationAliquotContainerId:
                  destAliquotContainer.id || `&${destAliquotContainer.cid}`,
                destinationPlateName: destAliquotContainer.plateName,
                volume: transferVolumeToUse,
                volumetricUnitCode: transferVolumetricUnitCodeToUse
              });
            }
          }
        }
      };

      forEach(this.materialIdToDestACs, (destACs, materialId) => {
        tryToAddTransfer({
          materialId,
          destACs
        });
      });
      forEach(this.reagentIdToDestACs, (destACs, reagentId) => {
        tryToAddTransfer({
          reagentId,
          destACs
        });
      });
    }

    const materialTransferVolumeWasEntered = this.neededInputMaterialIds.length
      ? this.props.materialTransferSettings?.transferType === "mass"
        ? this.props.materialTransferSettings?.transferMass
        : this.props.materialTransferSettings?.transferVolume
      : true;
    const reagentTransferVolumeWasEntered = this.neededInputReagentIds.length
      ? this.props.reagentTransferSettings?.transferType === "mass"
        ? this.props.reagentTransferSettings?.transferMass
        : this.props.reagentTransferSettings?.transferVolume
      : true;
    const transferVolumesEntered =
      materialTransferVolumeWasEntered && reagentTransferVolumeWasEntered;
    let worklistError;
    if (worklistTransfers.length && transferVolumesEntered) {
      worklistError = validateTransfers(
        { worklistTransfers },
        aliquotContainerTypes
      );
    } else if (
      transferVolumesEntered &&
      !allMaterialsMissing &&
      !allReagentsMissing &&
      !errorMessage &&
      !isEmpty(materialToSourceACTracker) &&
      !isEmpty(reagentToSourceACTracker)
    ) {
      worklistError = "Error setting up transfers.";
    }

    const allSourcesMissing = allReagentsMissing && allMaterialsMissing;

    const makeTransferInfoSection = type => {
      const capitalType = capitalize(type);
      const neededInputs = this[`neededInput${capitalType}s`];
      if (!neededInputs?.length) return null;
      const prefix = type + "TransferSettings";
      const transferSettings = this.props[prefix] || {};
      if (type === "reagent" && allReagentsMissing) return;
      return (
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header={`${capitalType} Transfer Volume`}
            helper={`Specify a transfer volume for source ${pluralize(type)}.`}
          />
          <CheckboxField
            defaultValue
            name={
              type === "material"
                ? fieldConstants.applyUniversalTransfersMaterial
                : fieldConstants.applyUniversalTransfersReagent
            }
            label="Apply Universal Transfer"
          />
          <RadioGroupField
            defaultValue="volume"
            inline
            options={[
              {
                label: "Volume",
                value: "volume"
              },
              {
                label: "Mass",
                value: "mass"
              }
            ]}
            label="Transfer Type"
            name={
              type === "material"
                ? fieldConstants.transferTypeMaterial
                : fieldConstants.transferTypeReagent
            }
          />
          {transferSettings.applyUniversalTransfer ? (
            <div style={{ maxWidth: 350 }}>
              {transferSettings.transferType === "volume" ? (
                <UnitInputField
                  label="Transfer Volume"
                  name={
                    type === "material"
                      ? fieldConstants.universalTransferVolumeMaterial
                      : fieldConstants.universalTransferVolumeReagent
                  }
                  unitName={
                    type === "material"
                      ? fieldConstants.universalTransferVolumetricUnitMaterial
                      : fieldConstants.universalTransferVolumetricUnitReagent
                  }
                  unitType="volumetricUnit"
                  unitDefault={defaultVolumetricUnitCode}
                />
              ) : (
                <UnitInputField
                  label="Transfer Mass"
                  name={
                    type === "material"
                      ? fieldConstants.universalTransferMassMaterial
                      : fieldConstants.universalTransferMassReagent
                  }
                  unitName={
                    type === "material"
                      ? fieldConstants.universalTransferMassUnitMaterial
                      : fieldConstants.universalTransferMassUnitReagent
                  }
                  unitType="massUnit"
                  unitDefault={defaultMassUnitCode}
                />
              )}
            </div>
          ) : (
            <React.Fragment>
              {!!this.neededInputMaterials.length && (
                <div style={{ marginBottom: 15 }}>
                  <h6>Needed Input {capitalType}s</h6>
                  <DataTable
                    isSimple
                    entities={neededInputs}
                    noSelect
                    keepDirtyOnReinitialize
                    destroyOnUnmount={false}
                    formName={toolSchema.code}
                    schema={[
                      "name",
                      getTransferColumn(transferSettings.transferType, prefix)
                    ]}
                    transferInfo={transferSettings.transferInfo}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Source Plates"
            helper="Select plates with input materials or reagents for reactions."
          />
          <GenericSelect
            name="sourcePlates"
            schema={[
              "name",
              { displayName: "Barcode", path: "barcode.barcodeString" },
              dateModifiedColumn
            ]}
            isMultiSelect
            fragment={[
              "containerArray",
              "id name barcode { id barcodeString } updatedAt"
            ]}
            additionalDataFragment={worklistPlanningPlateFragment}
            tableParamOptions={{
              additionalFilter: (props, qb) => {
                qb.whereAll({
                  id: qb.notInList(destinationPlateIds)
                });
              }
            }}
            postSelectDTProps={{
              formName: "worklistPlanningSelectPlatesTable",
              schema: [
                platePreviewColumn(),
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ]
            }}
            buttonProps={{
              loading: isLoadingMap.containerArrays,
              disabled: isDisabledMap.containerArrays
            }}
          />
          <HeaderWithHelper
            header="Select Source Tubes"
            helper="Select tubes with input materials or reagents for reactions."
          />
          <GenericSelect
            name="sourceTubes"
            schema={[
              "name",
              { displayName: "Barcode", path: "barcode.barcodeString" },
              dateModifiedColumn
            ]}
            isMultiSelect
            fragment={[
              "aliquotContainer",
              "id name barcode { id barcodeString } updatedAt"
            ]}
            tableParamOptions={{
              additionalFilter: {
                "aliquotContainerType.isTube": true
              }
            }}
            additionalDataFragment={worklistPlanningTubeFragment}
            postSelectDTProps={{
              formName: "worklistPlanningSelectTubesTable",
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ]
            }}
            buttonProps={{
              loading: isLoadingMap.aliquotContainers,
              disabled: isDisabledMap.aliquotContainers
            }}
          />
          <HeaderWithHelper
            header="Select Data Tables"
            helper="Select data tables containing input materials for reactions."
          />
          <GenericSelect
            name="sourceDataTables"
            schema={["name", dateModifiedColumn]}
            isMultiSelect
            fragment={["dataTable", "id name updatedAt"]}
            tableParamOptions={{
              additionalFilter: {
                dataTableTypeCode: [
                  "PCR_INVENTORY_MATERIALS",
                  "VALID_SAMPLE_QC_INVENTORY_LIST",
                  "INVALID_SAMPLE_QC_INVENTORY_LIST"
                ]
              }
            }}
            additionalDataFragment={worklistPlanningDataTableFragment}
            onSelect={this.fetchMaterialsForTables}
            postSelectDTProps={{
              formName: "worklistPlanningSelectDataTablesTable",
              schema: ["name", dateModifiedColumn]
            }}
            buttonProps={{
              loading: isLoadingMap.dataTables,
              disabled: isDisabledMap.dataTables
            }}
          />
          {loadingTableAliquots && (
            <div style={{ margin: "10px 0" }}>
              Loading source materials from selected data tables...
            </div>
          )}
          {!!allSourceAliquotContainers.length && missingMaterialMessage && (
            <Callout
              intent={allMaterialsMissing ? "danger" : "warning"}
              className="preserve-newline"
              style={{ marginBottom: 5 }}
            >
              {missingMaterialMessage}
            </Callout>
          )}
          {!!allSourceAliquotContainers.length && missingReagentMessage && (
            <Callout
              intent={allReagentsMissing ? "danger" : "warning"}
              className="preserve-newline"
              style={{ marginBottom: 5 }}
            >
              {missingReagentMessage}
            </Callout>
          )}
          {errorMessage && (
            <Callout
              intent="warning"
              className="preserve-newline"
              style={{ marginBottom: 5 }}
            >
              {errorMessage}
            </Callout>
          )}
        </div>
        {makeTransferInfoSection("material")}
        {makeTransferInfoSection("reagent")}
        <Footer
          {...footerProps}
          nextDisabled={
            allSourcesMissing ||
            errorMessage ||
            worklistError ||
            loadingTableAliquots
          }
          errorMessage={worklistError}
          onNextClick={handleSubmit(values => {
            const { reactionMaps = [] } = values;
            change("worklist", { worklistTransfers });
            change("missingMaterialIds", missingMaterialIds);
            change("missingReagentIds", missingReagentIds);
            const completedReactionIds = [];
            Object.keys(this.reactionToNeededInputs || {}).forEach(
              reactionId => {
                const {
                  materialIds,
                  reagentIds,
                  destinationAcIds = []
                } = this.reactionToNeededInputs[reactionId];
                const missingSourceMaterialIdsForReaction = materialIds.filter(
                  id => !materialToSourceACTracker[id]
                );
                const missingSourceReagentIdsReaction = reagentIds.filter(
                  id => !reagentToSourceACTracker[id]
                );
                // if all of the materials and reagents for this reaction are in the chosen sources
                // then the reaction is going to run
                // the reaction will also run if a destination for the reaction already
                // has the missing inputs needed
                if (
                  !missingSourceMaterialIdsForReaction.length &&
                  !missingSourceReagentIdsReaction.length
                ) {
                  completedReactionIds.push(reactionId);
                } else {
                  const someDestinationAlreadyHasMissingInputs =
                    destinationAcIds.some(acId => {
                      const hasMissingMaterials =
                        missingSourceMaterialIdsForReaction.length
                          ? missingSourceMaterialIdsForReaction.every(id =>
                              this.matIdsForDestAcTracker[acId].includes(id)
                            )
                          : true;
                      const hasMissingReagents =
                        missingSourceReagentIdsReaction.length
                          ? missingSourceReagentIdsReaction.every(id =>
                              this.reagentIdsForDestAcTracker[acId].includes(id)
                            )
                          : true;
                      return hasMissingMaterials && hasMissingReagents;
                    });
                  if (someDestinationAlreadyHasMissingInputs) {
                    completedReactionIds.push(reactionId);
                  }
                }
              }
            );
            const usedReactionMapIds = [];
            if (completedReactionIds.length) {
              reactionMaps.forEach(rm => {
                const ranAReaction = rm.reactions.some(r =>
                  completedReactionIds.includes(r.id)
                );
                if (ranAReaction) {
                  usedReactionMapIds.push(rm.id);
                }
              });
            }
            change("usedReactionMapIds", usedReactionMapIds);
            nextStep();
          })}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "destinationPlates",
    "plateToReactionMap",
    "reactionMaps",
    "sourcePlates",
    "sourceTubes",
    "tableAliquotContainers",
    "reagentTransferSettings",
    "materialTransferSettings"
  ),
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  })
)(SourceMaterials);

function getTransferColumn(type = "volume", prefix) {
  let unitType, unitDefault;
  if (type === "volume") {
    unitType = "volumetricUnit";
    unitDefault = defaultVolumetricUnitCode;
  } else {
    unitType = "massUnit";
    unitDefault = defaultMassUnitCode;
  }
  return {
    displayName: `Transfer ${capitalize(type)}`,
    filterDisabled: true,
    sortDisabled: true,
    render: (v, r) => {
      return (
        <UnitInputField
          style={{ display: "flex", alignItems: "center" }}
          name={`${prefix}.transferInfo.${r.id}.${type}`}
          unitName={`${prefix}.transferInfo.${r.id}.${unitType}Code`}
          unitType={unitType}
          unitDefault={unitDefault}
        />
      );
    }
  };
}
