/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get } from "lodash";
import classNames from "classnames";
import { observer, inject } from "mobx-react";
import determineBlackOrWhiteTextColor from "../utils/determineBlackOrWhiteTextColor";
import appGlobals from "../appGlobals";
import { showDialog } from "../GlobalDialog";
import ChooseLabDialog from "../SingleLabModeSelector/ChooseLabDialog";
import {
  changeActiveLab,
  initActiveLabId,
  labStorageKey,
  lastSelectedLabKey
} from "../utils/labUtils";
import { safeQuery } from "../apolloMethods";
import { isAdmin } from "../utils/generalUtils";
import {
  UNASSIGNED_PROJECT_ID,
  getActiveLabId,
  getActiveProjectId
} from "@teselagen/auth-utils";
import "./style.css";

class SingleLabModeSelector extends React.Component {
  constructor(props) {
    super(props);
    appGlobals.setActiveLabId = this.setActiveLabId;

    initActiveLabId(appGlobals.currentUser);

    this.state = {
      activeLabId: getActiveLabId()
    };
  }

  componentDidMount() {
    this.setFullLab();
  }

  componentDidUpdate() {
    const { activeLabId, activeLab } = this.state;
    if (activeLabId && activeLab && activeLabId !== activeLab.id) {
      this.setFullLab();
    }
  }

  setFullLab = async () => {
    const { defaultLabId, defaultLab } = this.props;
    const { activeLabId } = this.state;
    const resetLab = () => {
      this.setState({
        activeLab: defaultLab
      });
    };
    if (activeLabId && activeLabId !== defaultLabId) {
      const fullLab = await safeQuery(["lab", "id name color"], {
        variables: {
          id: activeLabId
        }
      });
      if (fullLab) {
        this.setState({
          activeLab: fullLab
        });
      } else {
        resetLab();
      }
    } else {
      resetLab();
    }
  };

  /**
   * This can be called from other places in the app (dialogs on submit)
   * @param {*} activeLabId
   */
  setActiveLabId = activeLabId => {
    const { mainStore, onActiveLabChange } = this.props;
    localStorage.setItem(lastSelectedLabKey, activeLabId);
    sessionStorage.setItem(labStorageKey, activeLabId);
    if (getActiveProjectId()) {
      // we want to clear the active project if the lab changes, otherwise we will be in an invalid state
      appGlobals.setActiveProjectId(UNASSIGNED_PROJECT_ID);
    }
    // tgreen: this needs to happen after localstorage gets set
    if (mainStore && mainStore.lab) {
      const cleanedLabId = getActiveLabId();
      mainStore.lab.selectLab(cleanedLabId);
    }
    onActiveLabChange && onActiveLabChange(activeLabId);
    this.setState({ activeLabId });
  };

  onChange = activeLab => {
    changeActiveLab(activeLab.id);
  };

  showChooseLabDialog = () => {
    showDialog({
      ModalComponent: ChooseLabDialog,
      modalProps: {
        onSelect: labId => {
          this.onChange(labId);
        }
      }
    });
  };

  render() {
    const { activeLab } = this.state;
    const { label = "Lab" } = this.props;

    const color = get(activeLab, "color");
    const labName = get(activeLab, "name");

    // do not show selector in single lab mode (we still want this component to mount so that the active lab is properly set in storage)
    if (!isAdmin()) {
      return null;
    }
    return (
      <div
        style={{
          marginLeft: 20,
          borderRadius: 5,
          flexShrink: 1,
          maxWidth: "18em"
          // width: "18em", //tnr: I don't think we should have a fixed width here -- better to let it expand and shrink as needed ?
        }}
        className={classNames(
          "tg-single-lab-mode-select",
          "lab-project-selector",
          "tg-flex align-center"
        )}
      >
        <span
          className={"lab-project-left-label"}
          style={{
            paddingRight: 8,
            paddingLeft: 8
          }}
        >
          {label}
        </span>
        <div
          className={"edit-active-lab-button"}
          onClick={this.showChooseLabDialog}
          style={{
            cursor: "pointer",
            backgroundColor: color,
            padding: 6.7,
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: color ? determineBlackOrWhiteTextColor(color) : undefined
          }}
        >
          {labName || "No lab selected"}
        </div>
      </div>
    );
  }
}

let SingleLabSelector = SingleLabModeSelector;

SingleLabSelector = inject("mainStore")(observer(SingleLabSelector));

export default SingleLabSelector;
