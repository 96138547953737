/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Card, Elevation, Divider } from "@blueprintjs/core";
import WidgetCardHeaderDefault from "./WidgetCardHeaderDefault";
import widgetCardStyles from "./WidgetCardStyles.module.css";

class WidgetCard extends Component {
  constructor(props) {
    super(props);

    const { title, Widget, WidgetCardHeader, id, widgetHeaderProps } = props;
    const widgetCardHeader = WidgetCardHeader ? (
      <WidgetCardHeader
        updateWidgetData={this.updateWidgetStore}
        {...widgetHeaderProps}
      />
    ) : (
      <WidgetCardHeaderDefault title={title} />
    );

    this.state = {
      widgetCardHeader: widgetCardHeader,
      Widget: Widget,
      widgetStore: {},
      id: id
    };
  }

  updateWidgetStore = updatedWidgetStore => {
    this.setState(prevState => {
      return {
        widgetStore: { ...prevState.widgetStore, ...updatedWidgetStore }
      };
    });
  };

  render() {
    const { Widget, widgetCardHeader, id, widgetStore } = this.state;
    const { widgetContentProps, className } = this.props;
    const cardClasses = `${widgetCardStyles.widgetCard} ${className}`;
    return (
      <Card id={id} className={cardClasses} elevation={Elevation.TWO}>
        {widgetCardHeader}
        <Divider />
        <div className={widgetCardStyles.widgetContainer}>
          <Widget {...widgetContentProps} {...widgetStore} />
        </div>
      </Card>
    );
  }
}

export default WidgetCard;
