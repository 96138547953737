/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { BlueprintError } from "@teselagen/ui";

import { AddButton } from "../../FieldArrayButtons";
import CategorialItem from "./CategorialItem";

import "./style.css";

function CategoricalItems(props) {
  const {
    fields,
    meta: { submitFailed, error },
    isEditing
  } = props;
  return (
    <div>
      <div style={{ marginBottom: 10 }}>Options</div>
      <div className="categorical-items">
        {fields.map((field, index) => (
          <CategorialItem key={index} {...{ ...props, field, index }} />
        ))}
        {!isEditing && <AddButton fields={fields} label="Add Option" />}
        {submitFailed && <BlueprintError error={error} />}
      </div>
    </div>
  );
}

export default CategoricalItems;
