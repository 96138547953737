/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { plateFragment, tubeFragment } from "../fragments";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  collectExtendedProperties,
  recordExtendedValuesString
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import queryBuilder from "tg-client-query-builder";

class SelectPlates extends Component {
  state = {
    loading: false
  };

  beforeNextStep = async values => {
    const { containerArrays = [], aliquotContainers = [] } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    try {
      const qb = new queryBuilder("aliquot");
      qb.whereAny(
        {
          "aliquotContainer.id": aliquotContainers.map(c => c.id)
        },
        {
          "aliquotContainer.containerArrayId": containerArrays.map(c => c.id)
        }
      );
      const aliquots = await safeQuery(
        ["aliquot", `id ${recordExtendedValuesString}`],
        {
          variables: {
            filter: qb.toJSON()
          }
        }
      );
      if (!aliquots.length) {
        return window.toastr.error("No aliquots found on plates or tubes.");
      }
      const aliquotProps = {};
      aliquots.forEach(aliquot => {
        collectExtendedProperties(aliquot, aliquotProps);
      });
      change("aliquots", aliquots);
      change("existingProps", Object.values(aliquotProps));
      nextStep();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error fetching aliquots");
    }
  };

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      Footer,
      footerProps,
      handleSubmit
    } = this.props;

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates/Tubes"
            helper="Select plates/tubes for updating"
            width="100%"
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              isMultiSelect: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              fragment: plateFragment,
              postSelectDTProps: {
                formName: "changeExtPropsPlates",
                schema: [
                  platePreviewColumn(),
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" }
                ]
              }
            }}
          />
          <GenericSelect
            {...{
              name: "aliquotContainers",
              buttonProps: {
                loading: isLoadingMap.aliquotContainers,
                disabled: isDisabledMap.aliquotContainers
              },
              isMultiSelect: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              additionalFilter: {
                "aliquotContainerType.isTube": true
              },
              fragment: tubeFragment,
              postSelectDTProps: {
                formName: "changeExtPropsTubes",
                schema: ["name"]
              }
            }}
          />
        </div>
        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </React.Fragment>
    );
  }
}

export default SelectPlates;
