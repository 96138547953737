/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { InputField } from "@teselagen/ui";
import { get, isNil } from "lodash";

import RecordInfoDisplay from "../../../../../src-shared/RecordInfoDisplay";
import { Link } from "react-router-dom";
import {
  aaSizeInfo,
  sizeInfo
} from "../../../../../src-shared/utils/libraryColumns";
import { getSequenceWithinRange } from "@teselagen/range-utils";
import { convertDnaCaretPositionOrRangeToAA } from "@teselagen/sequence-utils";
import LabelWithTooltip from "../../../../../src-shared/LabelWithTooltip";
import { INVALID_DIGEST_PART_MSG } from "../../../../../../tg-iso-shared/utils/digestPartUtils";

const NON_MATCHING_RE_MSG = assemblyReName =>
  `Assembly reaction restriction enzyme '${assemblyReName}' does not match with the one for this Digest Part`;

/**
 * The simple element section renders information that is only relevant
 * for simple elements. Simple elements correspond to a single piece of
 * DNA. They are pretty much anything except for part sets.
 */
const SimpleElementSection = props => {
  const {
    selectedElement,
    part,
    isVisualReport,
    isLocked,
    initialValues,
    handleNotesChange,
    handleOverhangsChange,
    partRestrictionEnzyme,
    reactionRestrictionEnzyme
  } = props;

  const renderDigestPartSection = () => {
    const nonMatchingRe =
      reactionRestrictionEnzyme?.id &&
      partRestrictionEnzyme?.id !== reactionRestrictionEnzyme?.id;

    const { isDigestValid, re5PrimeOverhang, re3PrimeOverhang } = part;

    const _5primeOverhang = isNil(re5PrimeOverhang)
      ? "Invalid"
      : re5PrimeOverhang.toUpperCase();

    const _3primeOverhang = isNil(re3PrimeOverhang)
      ? "Invalid"
      : re3PrimeOverhang.toUpperCase();

    const recordInfo = [
      [
        "Restriction Enzyme",
        <LabelWithTooltip
          key="re-name"
          label={get(partRestrictionEnzyme, "name")}
          {...(nonMatchingRe && {
            tooltip: NON_MATCHING_RE_MSG(
              get(reactionRestrictionEnzyme, "name")
            ),
            intent: "WARNING",
            icon: "warning-sign"
          })}
        />
      ],
      ["5' Overhang", _5primeOverhang],
      ["3' Overhang", _3primeOverhang]
    ];
    return (
      <div style={{ marginTop: 20 }}>
        <LabelWithTooltip
          label={<h6>Digest Part</h6>}
          {...(!isDigestValid && {
            intent: "WARNING",
            icon: "warning-sign",
            tooltip: INVALID_DIGEST_PART_MSG
          })}
        />
        <RecordInfoDisplay recordInfo={recordInfo} />
      </div>
    );
  };

  let recordInfo = [["Name", get(initialValues, "name")]];
  const isAAPart = selectedElement.aminoAcidPartId;
  if (selectedElement.partId) {
    const seqName = get(initialValues, "part.sequence.name");
    const seqId = get(initialValues, "part.sequenceId");
    recordInfo = recordInfo.concat([
      [
        "Part Source",
        <Link
          key="partSourceInLibrary"
          to={`/sequences?filters=id__equalTo__${seqId}`}
        >
          {seqName}
        </Link>
      ],
      [
        "Reverse Complement? (on source)",
        get(initialValues, "part.strand") === 1 ? "False" : "True"
      ],
      sizeInfo(initialValues?.part || {})
    ]);
  } else if (isAAPart) {
    const seqName = get(initialValues, "aminoAcidPart.aminoAcidSequence.name");
    const seqId = get(initialValues, "aminoAcidPart.aminoAcidSequenceId");
    const aaSeq = getSequenceWithinRange(
      convertDnaCaretPositionOrRangeToAA(initialValues?.aminoAcidPart),
      get(initialValues, "aminoAcidPart.aminoAcidSequence.proteinSequence", "")
    );
    recordInfo = recordInfo.concat([
      [
        "Part Source",
        <Link
          key="partSourceInLibrary"
          to={`/amino-acid-sequences?filters=id__equalTo__${seqId}`}
        >
          {seqName}
        </Link>
      ],
      ["Amino Acids", aaSeq.toUpperCase()],
      aaSizeInfo(initialValues?.aminoAcidPart || {})
    ]);
  } else {
    recordInfo = recordInfo.concat([["Base Pairs", get(initialValues, "bps")]]);
  }

  return (
    <React.Fragment>
      <h6>Properties</h6>

      <RecordInfoDisplay recordInfo={recordInfo} />
      {part?.sequence && (
        <>
          <h6 style={{ marginTop: 15 }}>Sequence Properties</h6>
          <RecordInfoDisplay
            record={part.sequence}
            recordInfo={[
              ["Name", part.sequence.name],
              ["Description", part.sequence.description],
              ["Size", part.sequence.size]
            ]}
          />
        </>
      )}
      {part?.isDigestPart && renderDigestPartSection()}
      <div style={{ marginTop: 15 }} />
      {(!!selectedElement.extraStartSequence ||
        !!selectedElement.extraEndSequence) && (
        <div style={{ marginBottom: 10 }}>
          This part does not have the required start and/or stop overhangs, but
          the missing overhangs will be added in the PCR or Synthesis step.
        </div>
      )}
      {!!selectedElement.extraStartSequence && (
        <InputField
          name="extraStartSequence"
          label="Extra 5' Sequence"
          readOnly
        />
      )}
      {!!selectedElement.extraEndSequence && (
        <InputField
          name="extraEndSequence"
          label="Extra 3' Sequence"
          readOnly
        />
      )}
      {!isAAPart && (
        <>
          <InputField
            name="notes"
            label="Notes for the Part in this Design"
            onFieldSubmit={handleNotesChange}
            readOnly={isVisualReport || isLocked}
          />
          <InputField
            name="preferred5PrimeOverhangs"
            label="Preferred 5' Overhangs"
            onFieldSubmit={handleOverhangsChange("preferred5PrimeOverhangs")}
            readOnly={isVisualReport || isLocked}
          />
          <InputField
            name="preferred3PrimeOverhangs"
            label="Preferred 3' Overhangs"
            onFieldSubmit={handleOverhangsChange("preferred3PrimeOverhangs")}
            readOnly={isVisualReport || isLocked}
          />
        </>
      )}
    </React.Fragment>
  );
};
export default compose()(SimpleElementSection);
