/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { vectorEditorMiddleware } from "@teselagen/ove";
import sagas from "./sagas";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionsBlacklist: []
    })) ||
  compose;

const store = createStore(
  rootReducer,
  undefined, // we don't have an initial state, so this is undefined
  composeEnhancers(
    applyMiddleware(sagaMiddleware, thunk, vectorEditorMiddleware)
  )
);

sagaMiddleware.run(sagas);
window.teGlobalStore = store;

export default store;
