/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { get } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import SelectInputs from "./SelectInputs";
import {
  genericElutionColumnPlateFragment,
  genericElutionReactionMapFragment
} from "./fragments";
import ReviewWorklist from "../AliquotRearrayTool/Steps/ReviewWorklist";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class GenericElutionTool extends React.Component {
  onSubmit = async values => {
    const {
      reactionMap,
      worklist,
      worklistName,
      columnPlates = [],
      collectionPlates = []
    } = values;
    try {
      const [createdWorklist] = await safeUpsert("worklist", {
        name: worklistName,
        worklistReactionMaps: [{ reactionMapId: reactionMap.id }],
        worklistTransfers: worklist.worklistTransfers.map(t => {
          const cleanedTransfer = { ...t };
          delete cleanedTransfer.sourceAliquotContainer;
          delete cleanedTransfer.destinationAliquotContainer;
          delete cleanedTransfer.destinationPlateName;
          return cleanedTransfer;
        })
      });
      const plateUpdates = [];
      columnPlates.forEach(columnPlate => {
        const collectionPlate = collectionPlates["id" + columnPlate.id];
        if (collectionPlate.id !== get(columnPlate, "collectionPlate.id")) {
          plateUpdates.push({
            id: columnPlate.id,
            collectionPlateId: collectionPlate.id
          });
        }
      });
      await safeUpsert("containerArray", plateUpdates);
      return {
        worklist: createdWorklist
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: error.message || "Error creating worklist."
      });
    }
  };

  validate = values => {
    const { columnPlates = [], collectionPlates = {} } = values;
    const errors = {};
    let errorMessage = "";
    const seenCollectionPlateIds = {};
    columnPlates.forEach(cp => {
      const collectionPlate = collectionPlates[`id` + cp.id];
      if (collectionPlate) {
        if (seenCollectionPlateIds[collectionPlate.id]) {
          errorMessage += `Collection plate ${collectionPlate.name} can only be used once.\n`;
        }
        seenCollectionPlateIds[collectionPlate.id] = true;
        const notEmpty = collectionPlate.aliquotContainers.some(
          ac => ac.aliquotId
        );
        if (notEmpty) {
          errorMessage += `The collection plate '${collectionPlate.name}' chosen for '${cp.name}' is not empty.\n`;
        }
      }
    });
    if (!errors.columnPlates) errors.columnPlates = errorMessage;
    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Inputs",
        Component: SelectInputs,
        withCustomFooter: true
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(genericElutionColumnPlateFragment, {
    initialValueName: "columnPlates"
  }),
  withWorkflowInputs(genericElutionReactionMapFragment, {
    singular: true
  })
)(GenericElutionTool);
