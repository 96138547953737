/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export const microserviceTaskStatus = {
  "completed-successfully": {
    loading: false,
    error: false,
    icon: IconNames.TICK_CIRCLE,
    intent: Intent.SUCCESS,
    description: "Completed Task",
    shortDescription: "Task Completed"
  },
  created: {
    loading: true,
    error: false,
    icon: IconNames.REFRESH,
    intent: Intent.WARNING,
    description: "Task is pending",
    shortDescription: "Task Pending"
  },
  "in-progress": {
    loading: true,
    error: false,
    icon: IconNames.REFRESH,
    intent: Intent.WARNING,
    description: "Task is running",
    shortDescription: "Task Running"
  },
  completing: {
    loading: true,
    error: false,
    icon: IconNames.REFRESH,
    intent: Intent.WARNING,
    description: "Task is running",
    shortDescription: "Task Running"
  },
  creating: {
    loading: true,
    error: false,
    icon: IconNames.REFRESH,
    intent: Intent.WARNING,
    description: "Task is pending",
    shortDescription: "Task Pending"
  },
  pending: {
    loading: true,
    icon: IconNames.REFRESH,
    error: false,
    intent: Intent.WARNING,
    description: "Task is pending",
    shortDescription: "Task Pending"
  },
  submitting: {
    loading: true,
    error: false,
    icon: IconNames.REFRESH,
    intent: Intent.WARNING,
    description: "Task is pending",
    shortDescription: "Task Pending"
  },
  "completed-failed": {
    loading: false,
    error: true,
    icon: IconNames.ERROR,
    intent: Intent.DANGER,
    description: "There was an error executing the task",
    shortDescription:
      "Task Failed. Click on the Microservice Task above for more information."
  }
};

export default microserviceTaskStatus;
