/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import { isoContext } from "@teselagen/utils";
import upsertReactionJsons from "../../utils/upsertReactionJsons";
import handleUpdateMutations from "./handleUpdates";
// TODO:
// Apply logic of handle nested records as others handlers

const REACTION_MAP = async function ({ recordsToImport }, ctx = isoContext) {
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport,
      model: "reactionMap"
    },
    ctx
  );
  await Promise.each(newRecords, async record => {
    try {
      const { reactionMaps } = await upsertReactionJsons(
        [record],
        {
          skipStrainNameCheck: true
        },
        ctx
      );
      record.id = reactionMaps[0].id;
      record.__typename = reactionMaps[0].__typename;
    } catch (error) {
      console.error(`error:`, error);
      record.__importFailed = error.message;
    }
  });
};

export default REACTION_MAP;
