/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

/**
 * Throughout the aliquot formulation code, this fragment will provide us with
 * all of the information we need on aliquot containers.
 */
export default gql`
  fragment aliquotContainerFormulationFragment on aliquotContainer {
    id

    additives {
      id

      volume
      volumetricUnitCode

      concentration
      concentrationUnitCode

      mass
      massUnitCode

      molarity
      molarityUnitCode

      materialConcentration
      materialConcentrationUnitCode

      additiveMaterialId
      lotId

      lot {
        id
        additiveMaterialId
      }
    }
  }
`;
