/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { pick, get } from "lodash";
import shortid from "shortid";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import { deleteEquipmentAndContents } from "../../../utils";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import AssociatedFilesSection from "../AssociatedFilesSection";
import EquipmentTree from "./EquipmentTree";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import { compose } from "recompose";
import gql from "graphql-tag";
import "./style.css";

import MoveEquipmentDialog from "../../Dialogs/MoveEquipmentDialog";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import { formatDate } from "../../../../src-shared/utils/dateUtils";
import getExportEquipmentMenuItems from "../../Library/Equipment/getExportEquipmentMenuItems";
import { InfoHelper } from "@teselagen/ui";
import { hasContentsInfo } from "../../Dialogs/AddNewEquipmentDialog/HasContentsCheckbox";

class EquipmentRecordView extends Component {
  treeKey = shortid();

  renderEquipmentContents() {
    const { equipmentItem } = this.props;

    return <EquipmentTree equipmentId={equipmentItem.id} key={this.treeKey} />;
  }

  renderContent() {
    const { equipmentItem, refetchEquipmentItem } = this.props;
    if (!equipmentItem) return null;
    const recordInfo = [
      ["Location", get(equipmentItem, "equipmentPathView.fullPath")],
      ["Barcode", get(equipmentItem, "barcode.barcodeString")],
      ["Equipment Type", equipmentItem.equipmentType.name],
      [
        <div key="label" style={{ display: "flex" }}>
          Shown in Placement Dialogs{" "}
          <InfoHelper style={{ marginLeft: 5 }} content={hasContentsInfo} />
        </div>,
        equipmentItem.hasContents ? "Yes" : "No"
      ],
      ["Make", equipmentItem.make],
      ["Model", equipmentItem.model],
      ["Manufacturer", equipmentItem.manufacturer],
      ["Description", equipmentItem.description]
    ];
    const serviceInfo = [
      ["Serial Number", equipmentItem.serialNumber],
      ["Warranty Expiration", formatDate(equipmentItem.warrantyExpiration)],
      [
        "Service Contract Expiration",
        formatDate(equipmentItem.serviceContractExpiration)
      ],
      ["Service Contact Info", equipmentItem.serviceContactInfo]
    ];
    return (
      <div>
        <div className="tg-double-record-info-container-wrapper">
          <RecordInfoDisplay
            recordInfo={recordInfo}
            record={equipmentItem}
            readOnly={this.props.readOnly}
          />
          {/* <div className="tg-flex-separator large" /> */}
          <RecordInfoDisplay recordInfo={serviceInfo} />
        </div>
        <hr className="tg-section-break" />
        {this.renderEquipmentContents()}
        <hr className="tg-section-break" />
        <AssociatedFilesSection
          files={equipmentItem.equipmentFiles}
          refetch={refetchEquipmentItem}
          fileTableName="equipmentFile"
          record={equipmentItem}
        />
      </div>
    );
  }

  getTitle = () => {
    const { equipmentItem } = this.props;
    if (!equipmentItem) return null;
    const { name, label } = equipmentItem;
    return label ? `${name} (${label})` : name;
  };

  handleDelete = async records => {
    await deleteEquipmentAndContents([records[0].id]);
  };

  showUpdateLocation = () => {
    const { equipmentItem } = this.props;

    const refetch = async () => {
      await safeQuery([
        "equipmentItem",
        /* GraphQL */ `
          {
            id
            equipmentPathView {
              id
              fullPath
            }
          }
        `
      ]);
    };

    showDialog({
      ModalComponent: MoveEquipmentDialog,
      modalProps: {
        refetch,
        equipmentItem
      }
    });
  };

  updateShowFunction = () => {
    const { equipmentItem } = this.props;
    const editFields = [
      "id",
      "name",
      "hasContents",
      "description",
      "manufacturer",
      "serialNumber",
      "warrantyExpiration",
      "equipmentTypeCode",
      "label",
      "make",
      "model",
      "serviceContactInfo",
      "serviceContractExpiration"
    ];
    showDialog({
      modalType: "EDIT_EQUIPMENT",
      modalProps: {
        initialValues: pick(equipmentItem, editFields.concat("barcode")),
        editFields:
          editFields.join(" ") +
          " equipmentType { code name } barcode { id barcodeString }",
        refetch: () => {
          this.treeKey = shortid();
        }
      }
    });
  };

  render() {
    const { equipmentItem } = this.props;
    return (
      <AbstractRecord
        {...this.props}
        additionalButtons={[
          <Popover
            key="exportContents"
            content={
              <Menu>
                {getExportEquipmentMenuItems({ recordIds: [equipmentItem.id] })}
              </Menu>
            }
            position="bottom-right"
            minimal
          >
            <Button text="Export" rightIcon="caret-down" />
          </Popover>
        ]}
        handleDelete={this.handleDelete}
        updateShowFunction={this.updateShowFunction}
        additionalUpdateMenuItems={[
          <MenuItem
            key="updateLocation"
            text="Update Location"
            onClick={this.showUpdateLocation}
          />
        ]}
        title={this.getTitle()}
        recordName="equipmentItem"
      >
        {this.renderContent()}
      </AbstractRecord>
    );
  }
}

const fragment = gql`
  fragment equipmentRecordFragment on equipmentItem {
    id
    name
    label
    description
    equipmentTypeCode
    hasContents
    equipmentType {
      code
      name
    }
    user {
      id
      username
    }
    make
    model
    manufacturer
    locationId
    location {
      id
      name
    }
    equipmentPathView {
      id
      fullPath
    }
    createdAt
    serialNumber
    serviceContactInfo
    serviceContractExpiration
    warrantyExpiration
    equipmentFiles {
      id
      encoding
      mimetype
      originalname
      path
      size
    }
    equipmentPositions {
      id
      index
      label
      name
    }
  }
`;

export default compose(recordViewEnhancer(fragment))(EquipmentRecordView);
