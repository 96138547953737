/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { NumericInputField, CheckboxField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";

const fieldsStyle = { minWidth: 450, marginRight: 20 };

const AlgorithmParametersInner = () => (
  <>
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper
          header="Guide Length"
          helper="Provide a Guide length for the gRNAs to have."
        />
        <NumericInputField
          name="guideLength"
          label="Guide length [bps]"
          min={1}
          isRequired
          defaultValue={20}
          style={fieldsStyle}
        />
      </div>
    </div>
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper
          header="Minimum on target score"
          helper={`Only gRNAs with the minimum amount of target score set
            are going to appear in the results.`}
        />
        <NumericInputField
          name="minOnTargetScore"
          label="Minimum on target score"
          min={0}
          defaultValue={40}
          style={fieldsStyle}
        />
      </div>
    </div>
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper
          header="Max generated gRNAs"
          helper="The algorithm will only returns the top N gRNAs based on the edit score obtained by it. The edit score is a combination of the on-target score, which measures the complementarity between the guide sequence and a corresponding genomic DNA sequence, and the off-target score, which measures the inverse probability of off-target cutting"
        />
        <NumericInputField
          name="maxGeneratedGRnas"
          label="Max generated gRNAs"
          min={0}
          style={fieldsStyle}
        />
      </div>
    </div>
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper
          header="Force target sequence in genome"
          helper="The algorithm will fail to run if the target sequence is not present in the genome."
        />
        <CheckboxField
          name="forceTargetAtGenome"
          label="Force target at genome"
          style={fieldsStyle}
          defaultValue={false}
        />
      </div>
    </div>
  </>
);

const AlgorithmParameters = stepFormValues(
  "guideLength",
  "minOnTargetScore",
  "maxGeneratedGRnas",
  "forceTargetAtGenome"
)(AlgorithmParametersInner);

export { AlgorithmParameters };
