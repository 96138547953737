/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { molecularWeightColumn } from "../../../../src-shared/utils/libraryColumns";
import gql from "graphql-tag";
import CreateProteinMaterialDialog from "../../Dialogs/CreateProteinMaterialDialog";

const schema = {
  model: "functionalProteinUnit",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "cofactor", type: "string", displayName: "Cofactor" },
    {
      path: "extinctionCoefficient",
      type: "number",
      displayName: "Extinction Coefficient"
    },
    molecularWeightColumn,
    { path: "ger", type: "string", displayName: "GER" },
    { path: "ner", type: "string", displayName: "NER" },
    { path: "designId", type: "string", displayName: "Design ID" },
    { path: "pdbId", type: "string", displayName: "PDB ID" }
  ]
};

class ProteinLibrary extends Component {
  showProteinUpload = () => {
    const { refetchFunctionalProteinUnits } = this.props;
    showDialog({
      modalType: "UPLOAD_PROTEIN",
      modalProps: {
        refetch: refetchFunctionalProteinUnits
      }
    });
  };

  onNewItemClick = () => {
    const { refetchFunctionalProteinUnits } = this.props;

    showDialog({
      ModalComponent: CreateProteinMaterialDialog,
      modalProps: {
        refetch: refetchFunctionalProteinUnits
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        isLibraryTable
        withExport
        onNewItemClick={this.onNewItemClick}
        showUploadFunction={this.showProteinUpload}
      />
    );
  }
}

const fragment = gql`
  fragment functionalProteinUnitLibraryFragment on functionalProteinUnit {
    id
    name
    cofactor
    molecularWeight
    extinctionCoefficient
    ger
    ner
    designId
    pdbId
  }
`;

export default libraryEnhancer({
  fragment,
  schema
})(ProteinLibrary);
