/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog, InputField } from "@teselagen/ui";
import modelNameToReadableName from "./utils/modelNameToReadableName";
import { safeUpsert } from "./apolloMethods";

function RenameDialog(props) {
  const { record, hideModal, handleSubmit, submitting } = props;
  async function onSubmit(values) {
    try {
      await safeUpsert([record.__typename, "id name"], {
        id: record.id,
        name: values.name
      });
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error renaming");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog(props => {
    const { record } = props;
    return {
      title: `Rename ${modelNameToReadableName(record.__typename, {
        upperCase: true
      })}`
    };
  }),
  withProps(props => {
    return {
      initialValues: {
        name: props.record.name
      }
    };
  }),
  reduxForm({
    form: "renameDialog"
  })
)(RenameDialog);
