/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
//

import React from "react";
import { reduxForm, change } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  InputField,
  ReactSelectField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Classes, Callout } from "@blueprintjs/core";

import {
  getModelTypeOptions,
  getTargetModelTypeOptions
} from "../../utils/extendedPropertyUtils";
import { safeUpsert } from "../../apolloMethods";

class EditLinkExtendedProperty extends React.Component {
  static propTypes = {
    initialValues: PropTypes.object,
    refetch: PropTypes.func.isRequired
  };

  onSubmit = async values => {
    try {
      const { initialValues = {}, refetch, hideModal } = this.props;

      await safeUpsert(["extendedProperty", "id"], {
        id: initialValues.id,
        name: values.name,
        modelTypeCode: values.modelTypeCode,
        extendedPropertyClassCode: "LINK",
        targetModel: values.targetModelType
      });

      await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error ${
          this.isEditing() ? "editing" : "creating"
        } link extended property.`
      );
    }
  };

  /**
   * Are we editing or creating a new extended property?
   */
  isEditing() {
    return !!get(this.props, "initialValues.id");
  }

  render() {
    const {
      hideModal,
      handleSubmit,
      initialValues = {},
      submitting,
      initialItemType,
      initialTargetItemType
    } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {!!initialValues.id && (
            <Callout style={{ marginBottom: 10 }} intent="warning">
              Cannot edit item type when updating extended properties because
              they might be in use.
            </Callout>
          )}
          <ReactSelectField
            isRequired
            name="modelTypeCode"
            label="Item Type"
            disabled={!!initialValues.id}
            options={getModelTypeOptions()}
            defaultValue={initialItemType}
          />
          <InputField isRequired name="name" label="Name" />
          <ReactSelectField
            name="targetModelType"
            label="Target Item Type"
            disabled={!!initialValues.id}
            options={getTargetModelTypeOptions()}
            defaultValue={initialTargetItemType}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  const { units } = values;
  if (!get(units, "length")) {
    errors.units = {
      _error: "At least one option must be supplied."
    };
  } else {
    errors.units = values.units.map(() => ({}));

    const namesCount = {};
    const abbrevsCount = {};

    units.forEach(c => {
      if (!abbrevsCount[c.abbreviation]) abbrevsCount[c.abbreviation] = 0;
      abbrevsCount[c.abbreviation]++;

      if (!namesCount[c.name]) namesCount[c.name] = 0;
      namesCount[c.name]++;
    });

    const baseUnitInds = [];

    units.forEach((c, i) => {
      if (!c.name) errors.units[i].name = "Required.";
      else if (namesCount[c.name] > 1)
        errors.units[i].name = "Duplicated name.";

      if (!c.abbreviation) errors.units[i].abbreviation = "Required.";
      else if (abbrevsCount[c.abbreviation] > 1)
        errors.units[i].abbreviation = "Duplicated abbreviation.";

      if (!(c.conversionFactor > 0))
        errors.units[i].conversionFactor = "Must be greater than 0.";

      if (c.isBaseUnit) {
        if (c.conversionFactor !== 1)
          errors.units[i].conversionFactor = "Must be 1.";
        baseUnitInds.push(i);
      }
    });

    if (!baseUnitInds.length) {
      units.forEach((u, i) => {
        errors.units[i].isBaseUnit = "Exactly one base unit must be chosen.";
      });
    } else if (baseUnitInds.length > 1) {
      baseUnitInds.forEach(i => {
        errors.units[i].isBaseUnit = "Exactly one base unit must be chosen.";
      });
    }
  }

  return errors;
}

const form = "EditLinkExtendedProperty";

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Link Extended Property"
          : "Add Link Extended Property",
        style: {
          width: 650
        }
      };
    }
  }),
  connect(null, {
    change: (...args) => change(form, ...args)
  }),
  reduxForm({
    form,
    enableReinitialize: true,
    validate
  }),
  tgFormValues("units")
)(EditLinkExtendedProperty);
