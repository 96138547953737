/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { withProps } from "recompose";
import { get, intersection, keyBy, size } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { DataTable, CollapsibleCard } from "@teselagen/ui";
import {
  dateCreatedColumn,
  dateModifiedColumn
} from "../../src-shared/utils/libraryColumns";
import assaySubjectRecordFragment, {
  assaySubjectResultsFragment
} from "../fragments/assaySubjectRecordFragment";
import assayRecordFragment from "../fragments/assayRecordFragment";
import withQuery from "../withQuery";
import { safeQuery } from "../apolloMethods";
import config from "../../src-test/configs/config.json";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import {
  dataGridRowsCard,
  withDataCells
} from "./experimentData/dataGridUtils";

export const getSubjectsSchema = (options = {}) => {
  const { withDates = true } = options;
  return [
    "name",
    { path: "assaySubjectClass.name", displayName: "Class" },
    ...(withDates ? [dateModifiedColumn, dateCreatedColumn] : [])
  ];
};

const getSubjectsAssaySchema = () => {
  return [
    {
      path: "name",
      displayName: "Name",
      render: (_, record) => getAssayLink(record)
    },
    dateModifiedColumn,
    dateCreatedColumn
  ];
};

export const getAssayLink = assay => {
  return (
    <a
      href={`/client/assays/${get(assay, "id")}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {get(assay, "name")}
    </a>
  );
};

/**
 * This function is originally design for inventory record views,
 * to include assay subjects filtered by the inventory record ID.
 *
 * The ID is obtained from the URL params, but can also be passed as prop.
 *
 * The filter is made on the assay subject's "descriptorValue.value" field,
 * but eventually we could create a direct DM relationship between Assay Subjects
 * and Inventory Entities (s.a., samples, aliquots, tubes, etc.).
 *
 * @returns Assay Subject Records
 */
const withAssaySubjects = (inventoryRecordId = null) => {
  return withQuery(assaySubjectRecordFragment, {
    isPlural: true,
    showLoading: true,
    options: props => {
      const id = get(props, "match.params.id");
      const qb = new QueryBuilder("assaySubject");
      const filter = qb.whereAll(
        ...[
          { "descriptorValues.value": id || inventoryRecordId }
          // This second filter is kindda nested and may not be needed,
          // chances are quite low for a different descriptor type
          // to have a value equal to the sampleId.
          // { "descriptorValues.descriptorType.name": "Sample ID" }
        ]
      );
      return {
        variables: { filter }
      };
    }
  });
};

/**
 * This function is originally design for inventory record views,
 * to include assay subjects assays filtered by the inventory record ID.
 *
 * The ID is obtained from the URL params, but can also be passed as prop.
 *
 * The filter is made on the assay subject's "descriptorValue.value" field,
 * but eventually we could create a direct DM relationship between Assay Subjects
 * and Inventory Entities (s.a., samples, aliquots, tubes, etc.).
 *
 * @returns Assay Subject Records
 */
const withAssaySubjectAssays = () => {
  return withQuery(assayRecordFragment, {
    isPlural: true,
    showLoading: true,
    options: props => {
      const { assaySubjects } = props;
      const subjectIds = assaySubjects.map(subject => subject.id);
      const qb = new QueryBuilder("assay");
      const filter = qb.whereAll({
        "results.assaySubjectResults.assaySubjectId": qb.inList(subjectIds)
      });
      return {
        variables: { filter }
      };
    }
  });
};

const assaySubjectCard = (
  assaySubjects,
  assaySubjectAssays,
  // Only aliquots can be testSubjects for now...
  testSubject = "aliquot"
) => {
  // NOTE: It maybe be better in terms of UX to hide the assay subject concept.
  // At the end of the day, assay subjects is a generic concept, that materializes
  // with actual physical objects such as aliquots. In fact, TEST could also hide this concept,
  // and "materialize" it by displaying an "Assay Subjects" Library where records are actual
  // inventory items such as aliquots, samples or plates.
  const showSubjects = false && size(assaySubjects);
  const showSubjectAssays = size(assaySubjectAssays);
  const showCard = showSubjects || showSubjectAssays;
  return showCard ? (
    <CollapsibleCard
      key="experimentalAnalysesCard"
      title="Experimental Analyses"
    >
      <i style={{ marginBottom: 20 }}>
        {`These are the experimental analyses carried out on this ${testSubject}.`}
      </i>
      {showSubjects ? (
        <div style={{ paddingTop: 30 }}>
          <h6>Assay Subjects</h6>
          <DataTable
            schema={getSubjectsSchema()}
            style={{ marginBottom: 20 }}
            formName="assaySubjectsTable"
            entities={assaySubjects}
            isSimple
          />
        </div>
      ) : null}
      {showSubjectAssays ? (
        <div style={{ paddingTop: 30 }}>
          <h6>Assays</h6>
          <DataTable
            schema={getSubjectsAssaySchema()}
            style={{ marginBottom: 20 }}
            formName="assaySubjectsTable"
            entities={assaySubjectAssays}
            isSimple
          />
        </div>
      ) : null}
    </CollapsibleCard>
  ) : null;
};

/**
 * Returns a mapper of subject names to subject ids.
 * @param {*} subjectNames
 * @returns
 */
export const getAssaySubjectIdsFromNames = async subjectNames => {
  const subjectNameToId = {};
  try {
    const qb = new QueryBuilder("assaySubject");
    const filter = qb.whereAll({ name: qb.inList(subjectNames) });
    const existingSubjects = await safeQuery(["assaySubject", "id cid name"], {
      variables: { filter }
    });
    existingSubjects.forEach(subject => {
      subjectNameToId[subject.name] = subject.id;
    });
    return subjectNameToId;
  } catch (error) {
    console.error(error);
  }
};

export const getSubjectLinkDescriptorTypes = async () => {
  try {
    const qb = new QueryBuilder("descriptorType");
    const filter = qb.whereAll({
      urlTemplate: qb.isNotNull()
    });
    const subjectLinkDescriptorTypes = await safeQuery(
      [
        "descriptorType",
        "id name urlTemplate isExternalReference externalSourceSystem { id url }"
      ],
      {
        variables: { filter }
      }
    );
    return subjectLinkDescriptorTypes;
  } catch (error) {
    console.error(error);
  }
};

export const linkSubjectsToAliquots = async subjectIdToAliquotId => {
  try {
    const descriptorTypes = await safeQuery(["descriptorType", "id name"], {
      variables: {
        filter: { name: "Aliquot ID" }
      }
    });
    const aliquotDescriptorTypeId = get(descriptorTypes, "0.id");
    const reqData = Object.entries(subjectIdToAliquotId).map(
      ([id, aliquotId]) => {
        return {
          id,
          descriptors: [
            {
              descriptorTypeId: aliquotDescriptorTypeId,
              value: aliquotId
            }
          ]
        };
      }
    );

    const targetModelUrl = config.endpoints["createDescriptor"].replace(
      ":targetModel",
      "assay-subjects"
    );

    await window.serverApi.request({
      method: "POST",
      headers: getRequestHeaderKeys(),
      withCredentials: true,
      url: targetModelUrl,
      data: { records: reqData }
    });
  } catch (error) {
    console.error("error linking subjects", error);
  }
};

/**
 * This returns an array of HOC's intended for Inventory Record views.
 */
export const withExperimentalDataRecords = () => {
  return [
    withDataCells(),
    withAssaySubjects(),
    withAssaySubjectAssays(),
    withProps(props => {
      return {
        assaySubjectCard: assaySubjectCard(props.assaySubjects, props.assays),
        dataGridRowCard: dataGridRowsCard(props.dataCells)
      };
    })
  ];
};

/**
 * Returns a mapping between the subjectId and its assaySubjectResults
 * @param {*} subjects
 * @returns
 */
export const getAssaySubjectResults = async subjects => {
  try {
    const result = await safeQuery(assaySubjectResultsFragment, {
      variables: {
        filter: {
          assaySubjectId: subjects.map(subject => subject.id)
        }
      }
    });

    const subjectResultsBySubjectKey = keyBy(
      result,
      subjectResults => subjectResults.assaySubjectId
    );

    return subjectResultsBySubjectKey;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Returns an array of assayIds to which assaySubjects are linked
 * @param {*} subjects
 * @returns
 */
const getAssayIdsFromSubjectRecords = async subjects => {
  try {
    const result = await safeQuery(assaySubjectResultsFragment, {
      variables: {
        filter: {
          assaySubjectId: subjects.map(subject => subject.id)
        }
      }
    });

    const assayIds = result.map(res => res.result.assay.id);

    return assayIds;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Returns an array of projectIds to which assays are linked
 * @param {*} assayIds
 * @returns
 */
async function getProjectIdsFromAssayIds(assayIds) {
  const assays = await safeQuery(
    ["assay", "id projectItems { id projectId }"],
    {
      variables: {
        filter: {
          id: assayIds
        }
      }
    }
  );
  const projectIds = assays.map(assay => assay.projectItems[0]?.projectId);

  return projectIds;
}

export async function validateProjectUpdateForSubjects({
  records: subjects,
  projects: { removed }
}) {
  const res = {
    isValid: true,
    validationMessage: null
  };
  const assayIds = await getAssayIdsFromSubjectRecords(subjects);
  const projectIds = await getProjectIdsFromAssayIds(assayIds);

  // if removed projects exists on assay linked projects show error
  if (intersection(projectIds, removed).length) {
    res.isValid = false;
    res.validationMessage =
      "Remove project attempt: subject belongs to assay in removed project.";
  }

  return res;
}
