/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* eslint no-loop-func: 0 */
import {
  get,
  times,
  isObject,
  isString,
  uniq,
  omit,
  isEmpty,
  keyBy
} from "lodash";
import { createSelector } from "reselect";
import {
  resolveReference,
  getElementsInBin,
  getChildSetIds,
  getAllParentSets,
  getRootCard,
  getSetIdsContainingElement,
  isLeafCard,
  getReferencedValue,
  getAllOfType,
  getItemOfType,
  getInputReactionIdOfCard,
  getAllElementCombosOfCard,
  getAllCombinationsGroupsOfCards,
  getBinsInCard,
  getBinIdsInCard,
  getSequenceStringsOfElement
} from "../../selectors/designStateSelectors";
import {
  SIMPLE_REFERENCES_TO_TYPE,
  PRESERVED_TYPES
} from "../../constants/designStateConstants";
import { removeInaccessibleItems } from "../designStateUtils";
import doesSequenceStringPassRule from "../doesSequenceStringPassRule";
import getAllSetIdsInCard from "../../selectors/getAllSetIdsInCard";
import validateElementsFromJunctions from "./validateElementsFromJunctions";
import validateElementsFromBins from "./validateElementsFromBins";
import validateAssemblyPieceElements from "./validateAssemblyPieceElements";
import validateDigestFas from "./validateDigestFas";
import validateArfFas from "./validateArfFas";
import validateUSER from "./validateUSER";
import findByKey from "../findByKey";
import { v4 as uuid } from "uuid";
import { isNode } from "browser-or-node";

const resolveObjectOrId = objOrId => (isObject(objOrId) ? objOrId.id : objOrId);

// this should get renamed and moved to its own file
export class ChangeSetsHelper {
  constructor(designState) {
    this.state = designState;
    this.map = {};
    this.pureMap = {};
    this.createPureMap = {};
  }

  // this is a map for cards
  _initializeMapValue(cardId) {
    if (!this.map[cardId])
      this.map[cardId] = {
        cardId: cardId,
        deletes: [],
        inserts: [],
        update: {}
      };
  }

  // this is a map for other stuff
  _initializePureMapValue(type, id) {
    if (!this.pureMap[type]) this.pureMap[type] = {};
    if (!this.pureMap[type][id])
      this.pureMap[type][id] = {
        id,
        type,
        update: {},
        del: false
      };
  }

  _initializeCreatePureMapValue(type) {
    if (!this.createPureMap[type]) this.createPureMap[type] = [];
  }

  removeChild(cardOrId, index) {
    const parentId = resolveObjectOrId(cardOrId);
    this._initializeMapValue(parentId);
    this.map[parentId].deletes.push(index);
    return this;
  }

  removeBin(cardId, binOrId) {
    const { state } = this;
    const binId = resolveObjectOrId(binOrId);

    const index = getIndexOfBinInCard(state, cardId, binId);

    this.deletePure("bin", binId);

    return this.removeChild(cardId, index);
  }

  insertChild(parentCardOrId, index, child) {
    const parentId = resolveObjectOrId(parentCardOrId);
    this._initializeMapValue(parentId);
    this.map[parentId].inserts.push({ index, child });
    return this;
  }

  insertNextTo(cardId, binOrId, onLeft, child) {
    const { state } = this;
    const binId = resolveObjectOrId(binOrId);

    const index = getIndexOfBinInCard(state, cardId, binId) + 1 * !onLeft;

    return this.insertChild(cardId, index, child);
  }

  replaceBin(cardId, oldBinOrId, newBinId) {
    const { state } = this;
    const oldBinId = resolveObjectOrId(oldBinOrId);

    const index = getIndexOfBinInCard(state, cardId, oldBinId);

    this.removeChild(cardId, index);
    this.insertChild(cardId, index, newBinId);

    return this;
  }

  updatePure(type, objOrObjs) {
    if (!Array.isArray(objOrObjs)) objOrObjs = [objOrObjs];
    objOrObjs.forEach(obj => {
      if (!obj.id)
        throw new Error("Each object passed to updatePure must have an id.");
      this._initializePureMapValue(type, obj.id);
      this.pureMap[type][obj.id].update = {
        ...this.pureMap[type][obj.id].update,
        ...omit(obj, "id")
      };
    });
  }

  deletePure(type, objIdOrIds) {
    if (!Array.isArray(objIdOrIds)) objIdOrIds = [objIdOrIds];
    objIdOrIds.forEach(id => {
      this._initializePureMapValue(type, id);
      this.pureMap[type][id].del = true;
    });
  }

  createPure(type, objOrObjs) {
    if (!Array.isArray(objOrObjs)) objOrObjs = [objOrObjs];
    this._initializeCreatePureMapValue(type);
    this.createPureMap[type].push(...objOrObjs);
  }

  updateViaFlatObject(flatObj) {
    Object.entries(flatObj).forEach(([type, typeValues]) => {
      if (isEmpty(typeValues)) return;
      this.updatePure(type, Object.values(typeValues));
    });
  }

  execute(
    {
      removeCardInvalidityMessages,
      recomputeElementValidation,
      recomputeAssemblyPieceValidation,
      recomputeDigestFasValidation,
      recomputeArfFasValidation,
      recomputeUSERValidation = true,
      removeInaccessibleItems,
      removeNonsensicalExtraSequences,
      updateNumPlaceholders,
      recomputeBinValidation,
      removeInvalidEugeneRules,
      removeInvalidElementCombos,
      updateInvalidMaterialAvailabilities
    } = {
      removeCardInvalidityMessages: true,
      recomputeElementValidation: false,
      recomputeAssemblyPieceValidation: false,
      recomputeArfFasValidation: false,
      recomputeUSERValidation: false,
      removeInaccessibleItems: false,
      removeNonsensicalExtraSequences: false,
      updateNumPlaceholders: true,
      recomputeBinValidation: false,
      removeInvalidEugeneRules: true,
      removeInvalidElementCombos: true,
      updateInvalidMaterialAvailabilities: false
    }
  ) {
    const { state, map, pureMap, createPureMap } = this;
    let newState = { ...state };

    const typesToChange = uniq([
      "card",
      "bin",
      "binCard",
      ...Object.keys(pureMap),
      ...Object.keys(createPureMap)
    ]);
    typesToChange.forEach(type => {
      newState[type] = { ...newState[type] };
    });

    Object.values(map).forEach(({ cardId, deletes, inserts }) => {
      if (!deletes.length && !inserts.length) return;

      const binCards = resolveReference(
        newState.binCard,
        "card",
        { id: cardId },
        "binCards"
      )
        .slice(0)
        .sort((a, b) => a.index - b.index);

      deletes.sort((a, b) => a - b);

      const indexToInserts = inserts.reduce((acc, { index, child }) => {
        if (!acc[index]) acc[index] = [];
        acc[index].push(child);
        return acc;
      }, {});

      const indToDeltaIndex = [0];
      times(binCards.length + 1, i => {
        if (indexToInserts[i]) indToDeltaIndex[i] += indexToInserts[i].length;
        indToDeltaIndex[i + 1] = indToDeltaIndex[i];
        if (deletes.includes(i)) indToDeltaIndex[i + 1]--;
      });

      times(binCards.length + 1, i => {
        const deltaIndex = indToDeltaIndex[i];
        const binCard = binCards[i];

        // If the bin at this index should be removed, remove it.
        // Otherwise, update the index of the bin at the current position.
        if (deletes.includes(i)) {
          delete newState.binCard[binCard.id];
        } else if (binCard && deltaIndex !== 0) {
          newState.binCard[binCard.id] = {
            ...binCard,
            index: i + deltaIndex
          };
        }

        // If we are adding any bins at the current index, then add them.
        if (indexToInserts[i]) {
          indexToInserts[i].forEach((input, j) => {
            const index = i + j + deltaIndex - indexToInserts[i].length;
            // If we are passing the id of the bin we want to insert.
            if (isString(input)) {
              const binCardId = uuid();
              newState.binCard[binCardId] = {
                id: binCardId,
                cardId,
                binId: input,
                index
              };
            } else {
              // Otherwise create a new bin.
              const newBinId = input.id || uuid();
              const binCardId = uuid();
              newState.binCard[binCardId] = {
                id: binCardId,
                cardId,
                binId: newBinId,
                index
              };
              newState.bin[newBinId] = {
                ...input,
                id: newBinId
              };
            }
          });
        }
      });
    });

    Object.values(pureMap).forEach(values => {
      Object.values(values).forEach(({ id, type, update, del }) => {
        // Delete takes precedence.
        if (del) {
          delete newState[type][id];
        } else {
          newState[type][id] = {
            ...(newState[type][id] || { id }),
            ...update
          };
        }
      });
    });

    Object.entries(createPureMap).forEach(([type, values]) => {
      values.forEach(item => {
        const id = item.id || uuid();
        newState[type][id] = {
          ...item,
          id
        };
      });
    });

    const TODO = 0;

    if (removeInaccessibleItems) {
      newState = this._removeInaccessibleItems(newState);
    }
    if (removeCardInvalidityMessages) {
      newState = this._removeCardInvalidityMessages(newState);
    }
    if (TODO && removeNonsensicalExtraSequences) {
      newState = this._removeNonsensicalExtraSequences(newState);
    }
    if (recomputeElementValidation) {
      newState = this._recomputeElementValidation(newState);
    }
    if (recomputeDigestFasValidation) {
      newState = this._recomputeDigestFasValidation(newState);
    }
    if (recomputeUSERValidation) {
      newState = this._recomputeUSERValidation(newState);
    }
    if (recomputeArfFasValidation) {
      newState = this._recomputeArfFasValidation(newState);
    }
    if (recomputeAssemblyPieceValidation) {
      newState = this._recomputeAssemblyPieceValidation(newState);
    }
    if (recomputeBinValidation) {
      newState = this._recomputeBinValidation(newState);
    }
    if (removeInvalidEugeneRules) {
      newState = this._removeInvalidEugeneRules(newState);
    }
    if (removeInvalidElementCombos) {
      newState = this._removeInvalidElementCombos(newState);
    }
    if (updateInvalidMaterialAvailabilities) {
      newState = this._updateInvalidMaterialAvailabilities(
        newState,
        isObject(updateInvalidMaterialAvailabilities)
          ? updateInvalidMaterialAvailabilities
          : state
      );
    }

    if (
      get(Object.values(newState.design)[0], "type") === "design-template" &&
      updateNumPlaceholders
    ) {
      newState = this._updateNumPlaceHolders(newState);
    }
    return newState;
  }

  _recomputeDigestFasValidation(newState) {
    if (isNode) {
      return validateDigestFas(newState);
    } else {
      return newState;
    }
  }

  _recomputeUSERValidation(newState) {
    if (isNode) {
      return validateUSER(newState);
    } else {
      return newState;
    }
  }

  _recomputeArfFasValidation(newState) {
    if (isNode) {
      return validateArfFas(newState);
    } else {
      return newState;
    }
  }

  _recomputeElementValidation(newState) {
    if (isNode) {
      const newestState = validateElementsFromJunctions(newState);
      return validateElementsFromBins(newestState);
    } else {
      return newState;
    }
  }

  _recomputeAssemblyPieceValidation(newState) {
    if (isNode) {
      return validateAssemblyPieceElements(newState);
    } else {
      return newState;
    }
  }

  _removeInaccessibleItems(newState) {
    return removeInaccessibleItems(newState, [
      ...PRESERVED_TYPES,
      "disabledDesignRow", // these are top level
      "design"
    ]);
  }

  _removeCardInvalidityMessages(state) {
    const newState = { ...state };

    const changeSetsHelper = new ChangeSetsHelper(newState);

    for (const card of Object.values(newState.card)) {
      if (card.invalidityMessage) {
        changeSetsHelper.updatePure("card", {
          id: card.id,
          invalidityMessage: null
        });
      }
    }

    return changeSetsHelper.execute({
      removeCardInvalidityMessages: false
    });
  }

  _removeNonsensicalExtraSequences(newState) {
    const fakeFullNewState = { design: newState };
    const changeSetsHelper = new ChangeSetsHelper(newState);

    for (const element of Object.values(newState.element)) {
      if (!element.extraStartSequence && !element.extraEndSequence) continue;
      const containingSetIds = getSetIdsContainingElement(
        fakeFullNewState,
        element.id
      );
      if (
        !containingSetIds.some(setId => {
          const parentSets = getAllParentSets(fakeFullNewState, setId);
          return parentSets.some(
            parentSet =>
              isLeafCard(fakeFullNewState, parentSet.id) &&
              getChildSetIds(fakeFullNewState, parentSet.id).length === 1
          );
        })
      ) {
        changeSetsHelper.updatePure("element", {
          id: element.id,
          extraStartSequence: "",
          extraEndSequence: ""
        });
      }
    }

    return changeSetsHelper.execute();
  }

  _updateNumPlaceHolders(state) {
    const fakeFullNewState = { design: state };
    const rootCard = getRootCard(fakeFullNewState);

    const newState = { ...state };
    const design = { ...Object.values(newState.design)[0] };
    design.numPlaceholders = getBinsInCard(
      fakeFullNewState,
      rootCard.id
    ).reduce((acc, bin) => (bin.isPlaceholder ? acc + 1 : acc), 0);
    newState.design = {
      ...newState.design,
      [design.id]: design
    };
    return newState;
  }

  _recomputeBinValidation(state) {
    if (!isNode) {
      return state;
    }

    const fakeFullState = { design: state };
    const changeSetsHelper = new ChangeSetsHelper(state);

    const checkedBins = {};
    const binIdToErrors = {};

    for (const cardId of getAllCardIdsInDesign(state)) {
      const binIds = getBinIdsInCard(fakeFullState, cardId);
      for (const binId of binIds) {
        if (checkedBins[binId]) continue;
        checkedBins[binId] = true;

        const binRuleSets = getReferencedValue(
          fakeFullState,
          "bin",
          binId,
          "binRuleSets"
        );

        const elements = getElementsInBin(fakeFullState, binId);
        for (const element of elements) {
          const sequenceStrings = getSequenceStringsOfElement(
            fakeFullState,
            element.id
          );

          for (const sequenceString of sequenceStrings) {
            for (const { ruleSetId } of binRuleSets) {
              const rules = getReferencedValue(
                fakeFullState,
                "ruleSet",
                ruleSetId,
                "rules"
              );
              for (const rule of rules) {
                const message = doesSequenceStringPassRule({
                  state: fakeFullState,
                  ruleId: rule.id,
                  elementsContained: [element],
                  seqStr: sequenceString,
                  elementsName: element.name
                });
                if (message && message.length) {
                  if (!binIdToErrors[binId]) binIdToErrors[binId] = [];
                  binIdToErrors[binId].push(...message);
                }
              }
            }
          }
        }
      }
    }

    for (const fas of Object.values(getAllOfType(fakeFullState, "fas"))) {
      if (
        fas.name === "Top Strand Oligo" ||
        fas.name === "Bottom Strand Oligo"
      ) {
        const reaction = getItemOfType(
          fakeFullState,
          "reaction",
          fas.reactionId
        );
        const assemblyMethod = getItemOfType(
          fakeFullState,
          "assemblyMethod",
          reaction.assemblyMethodId
        );
        if (
          assemblyMethod.name !== "Gibson/SLIC/CPEC" &&
          assemblyMethod.name !== "Mock Assembly"
        ) {
          changeSetsHelper.updatePure("element", {
            id: fas.elementId,
            invalidityMessage:
              "'Top/Bottom Strand Oligo' FAS is only supported for Mock or Gibson/SLIC/CPEC reactions"
          });
        }
      }
      if (fas.name === "Assembly Ready Fragment") {
        const reaction = getItemOfType(
          fakeFullState,
          "reaction",
          fas.reactionId
        );
        const assemblyMethod = getItemOfType(
          fakeFullState,
          "assemblyMethod",
          reaction.assemblyMethodId
        );
        if (assemblyMethod.name !== "Gibson/SLIC/CPEC") {
          changeSetsHelper.updatePure("element", {
            id: fas.elementId,
            invalidityMessage:
              "'Assembly Ready Fragment' FAS is only supported for Gibson/SLIC/CPEC reactions"
          });
        }
      }
    }

    for (const binId of Object.keys(state.bin)) {
      const errors = binIdToErrors[binId] || [];
      changeSetsHelper.updatePure("bin", {
        id: binId,
        invalidityMessage: errors.length ? uniq(errors).join("\n") : ""
      });
    }

    return changeSetsHelper.execute({
      removeCardInvalidityMessages: false
    });
  }

  _removeInvalidEugeneRules(state) {
    const {
      reaction: reactions,
      element: elements,
      eugeneRule: eugeneRules
    } = state;
    const removedEugeneRulesFromElements = {};

    const newEugeneRuleElements = Object.values(state.eugeneRuleElement).reduce(
      (acc, ere) => {
        if (
          !elements[ere.elementId] ||
          (!eugeneRules[ere.eugeneRule1Id] && !eugeneRules[ere.eugeneRule2Id])
        ) {
          if (ere.eugeneRule1Id)
            removedEugeneRulesFromElements[ere.eugeneRule1Id] = true;
          if (ere.eugeneRule2Id)
            removedEugeneRulesFromElements[ere.eugeneRule2Id] = true;
          return acc;
        }
        acc[ere.id] = ere;
        return acc;
      },
      {}
    );

    const newEugeneRules = Object.values(state.eugeneRule).reduce(
      (acc, rule) => {
        if (
          removedEugeneRulesFromElements[rule.id] ||
          !reactions[rule.reactionId]
        )
          return acc;
        acc[rule.id] = rule;
        return acc;
      },
      {}
    );

    return {
      ...state,
      eugeneRule: newEugeneRules,
      eugeneRuleElement: Object.values(newEugeneRuleElements).reduce(
        (acc, ere) => {
          if (
            !newEugeneRules[ere.eugeneRule1Id] &&
            !newEugeneRules[ere.eugeneRule2Id]
          )
            return acc;
          acc[ere.id] = ere;
          return acc;
        },
        {}
      )
    };
  }

  _removeInvalidElementCombos(state) {
    state = this._removeInvalidElementGroupCombos(state);
    const { card: cards, element: elements } = state;

    const removedCombosFromElements = {};
    for (const eec of Object.values(state.elementElementCombo)) {
      if (!elements[eec.elementId])
        removedCombosFromElements[eec.elementComboId] = true;
    }

    const newElementCombos = {};
    for (const ec of Object.values(state.elementCombo)) {
      if (cards[ec.cardId] && !removedCombosFromElements[ec.id])
        newElementCombos[ec.id] = ec;
    }

    const newElementElementCombos = {};
    for (const eec of Object.values(state.elementElementCombo)) {
      if (newElementCombos[eec.elementComboId])
        newElementElementCombos[eec.id] = eec;
    }

    return {
      ...state,
      elementCombo: newElementCombos,
      elementElementCombo: newElementElementCombos
    };
  }

  _removeInvalidElementGroupCombos(state) {
    const { card: cards, element: elements } = state;

    const removedCombosFromElements = {};
    for (const eec of Object.values(state.elementGroupElementGroupCombo)) {
      if (!elements[eec.elementId])
        removedCombosFromElements[eec.elementGroupComboId] = true;
    }

    const newElementCombos = {};
    for (const ec of Object.values(state.elementGroupCombo)) {
      if (cards[ec.cardId] && !removedCombosFromElements[ec.id])
        newElementCombos[ec.id] = ec;
    }

    const newElementGroupElementGroupCombos = {};
    for (const eec of Object.values(state.elementGroupElementGroupCombo)) {
      if (newElementCombos[eec.elementGroupComboId])
        newElementGroupElementGroupCombos[eec.id] = eec;
    }

    return {
      ...state,
      elementGroupCombo: newElementCombos,
      elementGroupElementGroupCombo: newElementGroupElementGroupCombos
    };
  }

  /**
   * This function also removes some invalid eugene rules.
   * @param {*} newState
   * @param {*} oldState
   */
  _updateInvalidMaterialAvailabilities(newState, oldState) {
    const fakeFullNewState = { design: newState };
    const fakeFullOldState = { design: oldState };
    const changeSetsHelper = new ChangeSetsHelper(newState);

    const cardsToRemoveEugeneRulesFrom = {};
    const cardsToRemoveElementCombosFrom = {};
    const cardsToRemoveElementGroupCombosFrom = {};
    const newCardIds = getAllCardIdsInDesign(newState);
    outer: for (const cardId of newCardIds) {
      const newCard = getItemOfType(fakeFullNewState, "card", cardId);

      const newBins = getBinsInCard(fakeFullNewState, cardId);
      const oldBins = getBinsInCard(fakeFullOldState, cardId);
      if (newBins.length !== oldBins.length) {
        if (newCard.hasAvailabilityInfo)
          _invalidateMaterialAvailability(changeSetsHelper, cardId);
        cardsToRemoveEugeneRulesFrom[cardId] = true;
        cardsToRemoveElementCombosFrom[cardId] = true;
        cardsToRemoveElementGroupCombosFrom[cardId] = true;
        continue;
      }

      for (let i = 0, ii = newBins.length; i < ii; i++) {
        const newBin = newBins[i];
        const oldBin = oldBins[i];
        if (newBin.id !== oldBin.id || newBin.direction !== oldBin.direction) {
          _invalidateMaterialAvailability(changeSetsHelper, cardId);
          cardsToRemoveElementCombosFrom[cardId] = true;
          if (newBin.id !== oldBin.id) {
            cardsToRemoveEugeneRulesFrom[cardId] = true;
            cardsToRemoveElementGroupCombosFrom[cardId] = true;
          }
          continue outer;
        }

        if (!newCard.hasAvailabilityInfo) continue;

        const newElements = getElementsInBin(fakeFullNewState, newBin.id);
        const oldElements = getElementsInBin(fakeFullOldState, newBin.id);

        if (newElements.length !== oldElements.length) {
          _invalidateMaterialAvailability(changeSetsHelper, cardId);
          continue outer;
        }

        for (let j = 0, jj = newElements.length; j < jj; j++) {
          const newElement = newElements[j];
          const oldElement = oldElements[j];
          if (
            newElement.id !== oldElement.id ||
            newElement.bps !== oldElement.bps ||
            newElement.partId !== oldElement.partId ||
            newElement.extraStartSequence !== oldElement.extraStartSequence ||
            newElement.extraEndSequence !== oldElement.extraEndSequence
          ) {
            _invalidateMaterialAvailability(changeSetsHelper, cardId);
            continue outer;
          }
        }
      }
    }

    const reactionIdToElementIds = {};
    for (const cardId of Object.keys(cardsToRemoveEugeneRulesFrom)) {
      const reactionId = getInputReactionIdOfCard(fakeFullNewState, cardId);
      if (!reactionIdToElementIds[reactionId])
        reactionIdToElementIds[reactionId] = {};
      const elementIdsMap = reactionIdToElementIds[reactionId];
      const bins = getBinsInCard(fakeFullNewState, cardId);
      for (const bin of bins) {
        const elements = getElementsInBin(fakeFullNewState, bin.id);
        for (const element of elements) {
          elementIdsMap[element.id] = true;
        }
      }
    }

    for (const ere of Object.values(newState.eugeneRuleElement)) {
      const rule =
        newState.eugeneRule[ere.eugeneRule1Id] ||
        newState.eugeneRule[ere.eugeneRule2Id];
      if (!rule) continue;
      const elementIds = reactionIdToElementIds[rule.reactionId];
      if (!elementIds) continue;
      if (elementIds[ere.elementId]) {
        changeSetsHelper.deletePure("eugeneRule", rule.id);
        changeSetsHelper.deletePure("eugeneRuleElement", ere.id);
      }
    }

    for (const cardId of Object.keys(cardsToRemoveElementCombosFrom)) {
      const elementCombos = getAllElementCombosOfCard(fakeFullNewState, cardId);
      changeSetsHelper.deletePure(
        "elementCombo",
        elementCombos.map(ec => ec.id)
      );

      for (const elementCombo of elementCombos) {
        const elementElementCombos = getReferencedValue(
          fakeFullNewState,
          "elementCombo",
          elementCombo.id,
          "elementElementCombos"
        );
        changeSetsHelper.deletePure(
          "elementElementCombo",
          elementElementCombos.map(ec => ec.id)
        );
      }
    }

    for (const cardId of Object.keys(cardsToRemoveElementGroupCombosFrom)) {
      const comboGroups = getAllCombinationsGroupsOfCards(
        fakeFullNewState,
        cardId
      );
      changeSetsHelper.deletePure(
        "elementGroupCombo",
        comboGroups.map(egc => egc.id)
      );

      for (const comboGroup of comboGroups) {
        const elementGroupElementGroupCombos = getReferencedValue(
          fakeFullNewState,
          "elementGroupCombo",
          comboGroup.id,
          "elementGroupElementGroupCombos"
        );
        changeSetsHelper.deletePure(
          "elementGroupElementGroupCombo",
          elementGroupElementGroupCombos.map(ec => ec.id)
        );
      }
    }

    // If the design has any part sets, then we are currently not
    // allowing material availbity. I am putting this logic after
    // everything else so that the other logic in this function
    // won't get affected.
    if (Object.keys(newState.partset).length) {
      for (const cardId of Object.keys(newState.card)) {
        _invalidateMaterialAvailability(changeSetsHelper, cardId);
      }
    }

    return changeSetsHelper.execute();
  }
}

function _invalidateMaterialAvailability(changeSetsHelper, cardId) {
  changeSetsHelper.updatePure("card", {
    id: cardId,
    allConstructsAvailable: false,
    lastCheckedAvailability: null,
    hasAvailabilityInfo: false
  });
}

export { getAllSetIdsInCard };

export const getAllCardIdsInDesign = createSelector(
  designState => designState,
  designState => Object.keys(designState.card)
);

const getIndexOfBinInCard = (designState, cardId, binId) => {
  const binCards = resolveReference(
    designState.binCard,
    "card",
    { id: cardId },
    "binCards"
  );

  return findByKey(binCards, binId, "binId").index;
};

export const getAllReactionIdsInSubtree = (designState, reactionId) => {
  const reactionIds = [];

  // Do bfs
  const queue = [reactionId];
  while (queue.length) {
    const rId = queue.shift();
    reactionIds.push(rId);
    const inputCards = resolveReference(
      designState.card,
      "reaction",
      { id: rId },
      "cards"
    );
    for (const c of inputCards) {
      if (c.outputReactionId) {
        queue.push(c.outputReactionId);
      }
    }
  }

  return reactionIds;
};

export const getAllOperationSetIdsInSubtree = (designState, operationId) => {
  const operationIds = getAllReactionIdsInSubtree(designState, operationId);
  return Object.values(designState.operationSet)
    .filter(
      os =>
        operationIds.includes(os.outputtingOpId) ||
        (os.inputSetId && operationIds.includes(os.operationId))
    )
    .map(os => os.id);
};

export const getOpSetInputForCard = (designState, cardId) => {
  if (!cardId) throw new Error("Must provide cardId.");
  return Object.values(designState.operationSet).find(
    os => os.inputSetId === cardId
  );
};

export const removeItemsAndShallowReferences = (
  designState,
  type,
  itemIds,
  changeSetsHelper,
  ignoreTypes = []
) => {
  if (!Array.isArray(itemIds)) itemIds = [itemIds];
  ignoreTypes = keyBy(ignoreTypes, x => x);

  const deletes = { [type]: itemIds };

  const shallowReferencesToCheck = Object.entries(
    SIMPLE_REFERENCES_TO_TYPE
  ).reduce((acc, [otherType, refs]) => {
    if (ignoreTypes[otherType]) return acc;
    const fields = [];
    Object.entries(refs).forEach(([key, refType]) => {
      if (refType === type) {
        fields.push(key);
      }
    });
    if (fields.length) acc[otherType] = fields;
    return acc;
  }, {});

  changeSetsHelper.deletePure(type, itemIds);

  Object.entries(shallowReferencesToCheck).forEach(([otherType, fields]) => {
    const allOfOtherType = Object.values(designState[otherType]);
    const idsToDelete = [];
    for (const item of allOfOtherType) {
      for (const field of fields) {
        const value = item[field];
        if (itemIds.includes(value)) idsToDelete.push(item.id);
      }
    }
    changeSetsHelper.deletePure(otherType, idsToDelete);
    deletes[otherType] = idsToDelete;
  });

  return deletes;
};
