/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { isEqual, pick } from "lodash";
import {
  withTableParams,
  DataTable,
  withSelectTableRecords
} from "@teselagen/ui";
import withQuery from "../../../../../../src-shared/withQuery";
import { assemblyPieceTableName } from "../../constants";
import { assemblyPieceViewFragment } from "../../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import schema from "./schema";
import stepFormValues from "../../../../../../src-shared/stepFormValues";
import { addInInventoryFilter } from "../../utils";
import InInventorySelectFilter from "../../InInventorySelectFilter";
import { safeQuery } from "../../../../../../src-shared/apolloMethods";

class AssemblyPieceInitialTable extends Component {
  componentDidUpdate(oldProps) {
    const {
      tableParams: { currentParams },
      selectTableRecords
    } = this.props;
    const {
      tableParams: { currentParams: oldParams }
    } = oldProps;
    // tgreen: because we are defaulting to everything selected we should clear the selection when searching
    // to avoid confusion
    const filterParams = ["searchTerm", "filters"];
    if (
      !isEqual(pick(currentParams, filterParams), pick(oldParams, filterParams))
    ) {
      selectTableRecords([]);
    }
  }

  render() {
    const { tableParams, selectTableRecords } = this.props;

    return (
      <DataTable
        {...tableParams}
        className="tg-assembly-pieces-initial"
        maxHeight={400}
        style={{
          height: 400
        }}
        compact
        keepDirtyOnReinitialize
        withSelectAll
        safeQuery={safeQuery}
        withCheckboxes
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <InInventorySelectFilter
            name="assemblyPieceInventoryFilter"
            tableParams={tableParams}
            selectTableRecords={selectTableRecords}
          />
        </div>
      </DataTable>
    );
  }
}

export default compose(
  stepFormValues("j5Reports", "finalizedAPIds"),
  withTableParams({
    formName: assemblyPieceTableName,
    urlConnected: false,
    schema,
    additionalFilter: (
      { j5Reports = [], finalizedAPIds = [] },
      qb,
      currentParams
    ) => {
      const j5ReportIds = j5Reports.map(j => j.id);
      const filter = {
        j5ReportId: j5ReportIds,
        id: qb.notInList(finalizedAPIds)
      };
      addInInventoryFilter(qb, currentParams);
      qb.whereAll(filter);
    }
  }),
  withQuery(assemblyPieceViewFragment, {
    isPlural: true
  }),
  withSelectTableRecords(assemblyPieceTableName)
)(AssemblyPieceInitialTable);
