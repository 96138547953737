/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { headers } from "./constants.js";

const {
  activeLabHeaderKey,
  activeProjectHeaderKey,
  importCollectionHeaderKey,
  authorizationHeaderKey
} = headers;

export function parseHeaders(req) {
  /**
   * Request headers sent as 'null' tend to get converted to a "null" string,
   * Since we are using null value headers in TG, we need a way to convert them back.
   */
  if (req.headers[activeLabHeaderKey] === "null")
    req.headers[activeLabHeaderKey] = null;
  if (req.headers[activeProjectHeaderKey] === "null")
    req.headers[activeProjectHeaderKey] = null;
  if (req.headers[importCollectionHeaderKey] === "null")
    req.headers[importCollectionHeaderKey] = null;
  if (req.headers[authorizationHeaderKey] === "null")
    req.headers[authorizationHeaderKey] = null;
  else if (req.headers[authorizationHeaderKey]) {
    req.headers[authorizationHeaderKey] =
      req.headers[authorizationHeaderKey].trim();
  }
}
