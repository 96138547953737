/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useState } from "react";
import { Classes } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";
import GenericSelect from "../GenericSelect";
import {
  generateTagMapFromQueryParams,
  getTagsUrlParamsForTagSelect
} from "../../../tg-iso-shared/src/tag-utils";
import "./style.css";
import { safeQuery } from "../apolloMethods";
import gql from "graphql-tag";
import { isEqual, keyBy } from "lodash";

const tagFilterMenuFragment = gql`
  fragment tagFilterMenuFragment on tagWithOptionView {
    id
    name
    color
  }
`;
const TagFilterMenu = props => {
  const [loadingDefault, setLoadingDefault] = useState(true);
  const [defaultVal, setDefaultVal] = useState([]);

  const handleFieldSubmit = (tags = []) => {
    const { currentParams, setNewParams } = props;
    const tagParams = getTagsUrlParamsForTagSelect(tags);
    if (
      !isEqual(tagParams.sort(), (currentParams.tags || []).sort()) &&
      !loadingDefault
    ) {
      setNewParams({
        ...currentParams,
        page: undefined,
        tags: tagParams
      });
    }
  };

  useEffect(() => {
    async function fetchDefaultValue() {
      try {
        const tagMap = generateTagMapFromQueryParams(props.currentParams);
        const tagIds = Object.keys(tagMap);
        if (!tagIds.length) return setLoadingDefault(false);
        const tags = await safeQuery(tagFilterMenuFragment, {
          variables: {
            filter: {
              id: tagIds
            }
          }
        });
        const keyedTags = keyBy(tags, "id");
        let defaultValue = [];
        Object.keys(tagMap).forEach(tagId => {
          const tag = keyedTags[tagId];
          if (tag) {
            defaultValue.push({
              ...tag,
              label: tag.name
            });
          }
        });
        defaultValue = defaultValue.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setDefaultVal(defaultValue);
      } catch (error) {
        console.error(`error:`, error);
      }
      setLoadingDefault(false);
    }
    fetchDefaultValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <div style={{ maxWidth: 250, padding: 4 }}>
        <GenericSelect
          name="tagFilter"
          asReactSelect
          isMultiSelect
          reactSelectProps={{
            isTagSelect: true,
            ...(loadingDefault && { isLoading: true })
          }}
          label="Tags"
          onFieldSubmit={handleFieldSubmit}
          defaultValue={defaultVal}
          enableReinitialize
          tableParamOptions={{
            defaults: {
              order: ["name"]
            }
          }}
          fragment={tagFilterMenuFragment}
        />
      </div>
      <DialogFooter text="OK" className={Classes.POPOVER_DISMISS} noCancel />
    </div>
  );
};

export default TagFilterMenu;
