/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { CollapsibleCard, DataTable } from "@teselagen/ui";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";
import { molecularWeightColumn } from "../../../../src-shared/utils/libraryColumns";

const aminoAcidSchema = {
  model: "aminoAcidSequence",
  fields: [
    {
      displayName: "Index",
      path: "index"
    },
    {
      displayName: "Name",
      path: "name"
    },
    molecularWeightColumn,
    {
      displayName: "Size",
      path: "size"
    },
    tagColumnWithRender
  ]
};

class AminoAcidSequenceCard extends React.Component {
  render() {
    const { functionalProteinUnit = {} } = this.props;
    const aminoAcidSequences = [];
    [...functionalProteinUnit.proteinSubUnits]
      .sort((a, b) => a.index - b.index)
      .forEach(({ index, aminoAcidSequence }) => {
        if (aminoAcidSequence) {
          aminoAcidSequences.push({ ...aminoAcidSequence, index: index + 1 });
        }
      });

    return (
      <CollapsibleCard title="Amino Acid Sequences">
        <DataTable
          entities={aminoAcidSequences}
          onDoubleClick={routeDoubleClick}
          isSimple
          schema={aminoAcidSchema}
          formName="aminoAcidForm"
        />
      </CollapsibleCard>
    );
  }
}

export default AminoAcidSequenceCard;
