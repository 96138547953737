/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DataTable } from "@teselagen/ui";
import { flatten, get } from "lodash";
import { Icon } from "@blueprintjs/core";
import { driveIcon } from "@teselagen/ui";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";

const schema = {
  model: "noModel",
  fields: [
    { path: "id", type: "string", displayName: "Id", isHidden: true },
    { path: "name", type: "string", displayName: "Name" }
  ]
};

class FolderExplorer extends React.Component {
  state = {
    expandedFolders: {}
  };

  getEntitiesToRender = (entities, depth = 0) => {
    entities = entities || this.props.entities;
    const { expandedFolders } = this.state;
    return flatten(
      entities.map(item =>
        expandedFolders[item._feId]
          ? [
              {
                depth,
                isFolder: true,
                isExpanded: true,
                entity: item
              },
              // ...item.children.map(entity => ({
              //   depth: 1,
              //   isFolder: this.isItemFolder(entity),
              //   entity
              // }))
              ...this.getEntitiesToRender(item.children || [], depth + 1)
            ]
          : [
              {
                depth,
                isFolder: this.isItemFolder(item),
                isExpanded: false,
                entity: item
              }
            ]
      )
    );
  };

  isItemFolder = item => !!get(item, "children");

  getIndentPerDepth = () => 12 + 5;

  getSubfolderIcon = item => {
    const { isFolder, isExpanded, entity } = item;

    if (entity.icon) return item.entity.icon;
    else if (isFolder && isExpanded) return "folder-open";
    else if (isFolder && !isExpanded) return "folder-close";
    return null;
  };

  onCaretClick = entity => () => {
    const { expandedFolders } = this.state;
    this.setState({
      expandedFolders: {
        ...expandedFolders,
        [entity._feId]: !expandedFolders[entity._feId]
      }
    });
  };

  render() {
    const { withLinks } = this.props;
    return (
      <DataTable
        formName={this.props.formName || "FolderExplorer"}
        {...this.props}
        compact
        isLoading={false}
        urlConnected={false}
        withPaging={false}
        withSearch={false}
        isInfinite
        isSimple
        noHeader
        noFooter
        hideSelectedCount
        isSingleSelect
        schema={schema}
        entities={this.getEntitiesToRender()}
        cellRenderer={{
          name: (name, item) => {
            const { depth, isFolder, isExpanded, entity } = item;
            const indentPerDepth = this.getIndentPerDepth();
            const icon = this.getSubfolderIcon(item);
            let inner = entity.name;

            if (withLinks && entity.__typename) {
              inner = <Link to={modelNameToLink(entity)}>{inner}</Link>;
            }

            return (
              <span
                style={{
                  marginLeft: depth * indentPerDepth
                }}
              >
                {isFolder && (
                  <Icon
                    style={{ marginRight: 5 }}
                    icon={isExpanded ? "caret-down" : "caret-right"}
                    onClick={this.onCaretClick(entity)}
                  />
                )}
                {icon &&
                  (entity.__typename === "folder" ? (
                    <Icon icon={driveIcon} />
                  ) : (
                    <Icon style={{ marginRight: 5 }} icon={icon} />
                  ))}
                {inner}
              </span>
            );
          }
        }}
      />
    );
  }
}

export default FolderExplorer;
