/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const worklistExecuteExtendedValuesFragment = gql`
  fragment worklistExecuteExtendedValuesFragment on extendedValue {
    id
    value
    extendedProperty {
      id
      name
      extendedTypeCode
      extendedPropertyClassCode
    }
  }
`;
const worklistExecuteExtendedCategoryValuesFragment = gql`
  fragment worklistExecuteExtendedCategoryValuesFragment on extendedCategoryValue {
    id
    extendedCategoryId
    extendedCategory {
      id
      name
    }
    extendedProperty {
      id
      name
      extendedTypeCode
      extendedPropertyClassCode
      extendedCategoryClass {
        id
        name
        extendedCategories {
          id
          name
        }
      }
    }
  }
`;

const worklistExecuteExtendedMeasurementValuesFragment = gql`
  fragment worklistExecuteExtendedMeasurementValuesFragment on extendedMeasurementValue {
    id
    value
    measurementUnitId
    measurementUnit {
      id
      abbreviation
    }
    extendedProperty {
      id
      name
      extendedTypeCode
      extendedPropertyClassCode
      extendedMeasurementUnitClass {
        id
        name
        baseMeasurementUnitId
        baseMeasurementUnit {
          id
          name
          abbreviation
          conversionFactor
        }
        measurementUnits {
          id
          name
          abbreviation
          conversionFactor
        }
      }
    }
  }
`;

const worklistExecuteContainerArrayFragment = gql`
  fragment worklistExecuteContainerArrayFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    extendedValues {
      ...worklistExecuteExtendedValuesFragment
    }
    extendedCategoryValues {
      ...worklistExecuteExtendedCategoryValuesFragment
    }
    extendedMeasurementValues {
      ...worklistExecuteExtendedMeasurementValuesFragment
    }
  }
  ${worklistExecuteExtendedValuesFragment}
  ${worklistExecuteExtendedCategoryValuesFragment}
  ${worklistExecuteExtendedMeasurementValuesFragment}
`;

const worklistExecuteAliquotFragment = gql`
  fragment worklistExecuteAliquotFragment on aliquot {
    id
    extendedValues {
      ...worklistExecuteExtendedValuesFragment
    }
    extendedCategoryValues {
      ...worklistExecuteExtendedCategoryValuesFragment
    }
    extendedMeasurementValues {
      ...worklistExecuteExtendedMeasurementValuesFragment
    }
    aliquotType
    isDry
    volume
    volumetricUnitCode
    volumetricUnit {
      code
      liters
    }
    sampleId
    sample {
      id
      name
    }
  }
  ${worklistExecuteExtendedValuesFragment}
  ${worklistExecuteExtendedCategoryValuesFragment}
  ${worklistExecuteExtendedMeasurementValuesFragment}
`;

const worklistExecuteAliquotContainerFragment = gql`
  fragment worklistExecuteAliquotContainerFragment on aliquotContainer {
    id
    containerArrayId
    containerArray {
      ...worklistExecuteContainerArrayFragment
    }
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainerTypeCode
    aliquotContainerType {
      code
      isTube
      maxVolume
      volumetricUnitCode
      deadVolume
      deadVolumetricUnitCode
    }
    columnPosition
    rowPosition
    aliquotId
    aliquot {
      ...worklistExecuteAliquotFragment
    }
    additives {
      id
      volume
      volumetricUnitCode
    }
  }
  ${worklistExecuteAliquotFragment}
  ${worklistExecuteContainerArrayFragment}
`;

const worklistExecuteTubeFragment = gql`
  fragment worklistExecuteTubeFragment on aliquotContainer {
    id
    containerArrayId
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainerTypeCode
    columnPosition
    rowPosition
  }
`;

const worklistExecuteRackFragment = gql`
  fragment worklistExecuteRackFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      ...worklistExecuteTubeFragment
    }
    containerArrayType {
      id
      nestableTubeTypes {
        id
        aliquotContainerTypeCode
      }
    }
  }
  ${worklistExecuteTubeFragment}
`;

export const worklistExecuteTransferFragment = gql`
  fragment worklistExecuteTransferFragment on worklistTransfer {
    id
    volume
    volumetricUnitCode
    sourceAliquotContainerId
    sourceAliquotContainer {
      ...worklistExecuteAliquotContainerFragment
    }
    destinationAliquotContainerId
    destinationAliquotContainer {
      ...worklistExecuteAliquotContainerFragment
    }
    samplePoolId
    worklistTransferAliquotContainers {
      id
      extendedValues {
        ...worklistExecuteExtendedValuesFragment
      }
      extendedCategoryValues {
        ...worklistExecuteExtendedCategoryValuesFragment
      }
      extendedMeasurementValues {
        ...worklistExecuteExtendedMeasurementValuesFragment
      }
      aliquotContainerId
      aliquotContainer {
        ...worklistExecuteAliquotContainerFragment
      }
      isTargetAliquot
      transferExtendedProperties {
        id
        extendedPropertyToRemove {
          id
          name
        }
      }
    }
  }
  ${worklistExecuteExtendedValuesFragment}
  ${worklistExecuteExtendedCategoryValuesFragment}
  ${worklistExecuteExtendedMeasurementValuesFragment}
  ${worklistExecuteAliquotContainerFragment}
`;

const worklistExecuteTubeTransferFragment = gql`
  fragment worklistExecuteTubeTransferFragment on tubeTransfer {
    id
    aliquotContainerId
    aliquotContainer {
      ...worklistExecuteTubeFragment
    }
    sourceContainerArrayId
    sourceContainerArray {
      ...worklistExecuteRackFragment
    }
    destinationContainerArrayId
    destinationContainerArray {
      ...worklistExecuteRackFragment
    }
    sourceRowPosition
    sourceColumnPosition
    destinationRowPosition
    destinationColumnPosition
  }
  ${worklistExecuteTubeFragment}
  ${worklistExecuteRackFragment}
`;

export default gql`
  fragment worklistExecuteFragment on worklist {
    id
    name
    worklistReactionMaps {
      id
      reactionMapId
    }
    tubeTransfers {
      ...worklistExecuteTubeTransferFragment
    }
    worklistStatusCode
    worklistTypeCode
    worklistContainerArrays {
      id
      containerArrayId
      containerArray {
        ...worklistExecuteContainerArrayFragment
      }
      worklistExtendedProperties {
        id
        extendedPropertyToRemove {
          id
          name
        }
      }
      extendedValues {
        ...worklistExecuteExtendedValuesFragment
      }
      extendedCategoryValues {
        ...worklistExecuteExtendedCategoryValuesFragment
      }
      extendedMeasurementValues {
        ...worklistExecuteExtendedMeasurementValuesFragment
      }
    }
    updatedAt
  }
  ${worklistExecuteExtendedValuesFragment}
  ${worklistExecuteExtendedCategoryValuesFragment}
  ${worklistExecuteExtendedMeasurementValuesFragment}
  ${worklistExecuteContainerArrayFragment}
  ${worklistExecuteTubeTransferFragment}
`;
