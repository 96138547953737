/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment fullSequenceFragment on sequence {
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    id
    name
    circular
    sequence
    fullSequence
    description
    createdAt
    updatedAt
    size
    hash
    lab {
      id
      name
    }
    sequenceTypeCode
    sequenceFragments {
      id
      cid
      index
      fragment
    }
    sequenceFeatures {
      id
      start
      end
      type
      name
      strand
      locations
      notes
    }
    parts {
      id
      createdAt
      updatedAt
      name
      start
      end
      strand
      overlapsSelf
    }
    plasmidInductionMethodPlasmids {
      id
      inductionMethod {
        id
        name
      }
    }
    referenceCdsFragmentId
    molecularWeight
  }
`;
