/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const designAminoExtendedPropertyFragment = gql`
  fragment designAminoExtendedPropertyFragment on extendedProperty {
    id
    name
    extendedTypeCode
  }
`;

const designTags = gql`
  fragment designTaggedAminoAcidFragment on tag {
    id
    labId
    name
    color
    tagOptions {
      id
      name
      color
    }
    taggedItems {
      id
      aminoAcidSequence {
        id
        labId
        name
        description
        size
        proteinSequence
        extendedValues {
          id
          extendedProperty {
            ...designAminoExtendedPropertyFragment
          }
          value
        }
        extendedCategoryValues {
          id
          extendedCategory {
            id
            name
          }
          extendedProperty {
            ...designAminoExtendedPropertyFragment
          }
        }
        extendedMeasurementValues {
          id
          value
          measurementUnitId
          measurementUnit {
            id
            abbreviation
          }
          extendedProperty {
            ...designAminoExtendedPropertyFragment
          }
        }
        taggedItems {
          id
          tag {
            id
            name
            color
          }
          tagOption {
            id
            name
            color
          }
        }
      }
    }
  }
  ${designAminoExtendedPropertyFragment}
`;

const designTagOptions = gql`
  fragment designTagOptionsFragment on tagOption {
    id
    name
    color
    tag {
      id
      name
      color
      taggedItems {
        id
        aminoAcidSequence {
          id
          name
          description
          size
          proteinSequence
          taggedItems {
            id
            tagOption {
              id
              name
              color
              tag {
                id
                name
                color
              }
            }
          }
        }
      }
    }
  }
`;

const testAssayFragment = gql`
  fragment testAssay on assay {
    id
    name
    importFileSets {
      id
    }
  }
`;

export { designTags, designTagOptions, testAssayFragment };
