/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Intent, Menu, Position } from "@blueprintjs/core";
import isMobile from "is-mobile";
import { DropdownButton } from "@teselagen/ui";
import ImportFromExternalDbMenuOptions from "./ImportFromExternalDbMenuOptions";

export const ImportFromExternalDbDialogButton = ({ refetch, subtype }) => (
  <DropdownButton
    minimal
    key="external"
    popoverProps={{
      position: isMobile() ? Position.BOTTOM_RIGHT : undefined
    }}
    menu={
      <Menu>
        <ImportFromExternalDbMenuOptions refetch={refetch} subtype={subtype} />
      </Menu>
    }
    text={isMobile() ? "" : "Import From External DB"}
    intent={Intent.SUCCESS}
    icon="download"
  />
);
