/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { DataTable, NumericInputField, SelectField } from "@teselagen/ui";
import { Intent, Button, Callout } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { attachedColumnPlateFilter } from "../../../utils/plateUtils";
import reactionMapFragment from "../../../graphql/fragments/reactionMapFragment";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { proteinPurificationPlateFragment } from "./fragments";
import { getPropValue } from "../../../../src-shared/utils/extendedPropertyUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

class SelectInputs extends Component {
  render() {
    const {
      reactionMap,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      Footer,
      missingMaterials = [],
      footerProps
    } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Reaction Map"
            helper="Select a protein purification reaction map.
              This will be used to transform input microbial
              materials to protein materials and to ensure
              all necessary materials are present."
          />
          <GenericSelect
            {...{
              name: "reactionMap",
              fragment: ["reactionMap", "id name updatedAt"],
              tableParamOptions: { additionalFilter: reactionMapFilter },
              schema: ["name", dateModifiedColumn],
              additionalDataFragment: reactionMapFragment,
              buttonProps: {
                loading: isLoadingMap.reactionMap,
                disabled: isDisabledMap.reactionMap
              }
            }}
          />
          {reactionMap && (
            <React.Fragment>
              <h6>{reactionMap.name}</h6>
              <DataTable
                formName="proteinPurificationReactionTable"
                schema={schema}
                entities={reactionMap.reactions}
                destroyOnUnmount={false}
                isSimple
                compact
                hideSelectedCount
              />
            </React.Fragment>
          )}
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates"
            width="100%"
            helper="Select one or more nickel column plates of
            microbial materials. The nickel column plate should be
            clean and attached to a collection plate to be used."
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              schema: [
                "name",
                {
                  displayName: "Barcode",
                  path: "barcode.barcodeString"
                },
                {
                  displayName: "Plate Type",
                  path: "containerArrayType.name",
                  dateModifiedColumn
                }
              ],
              tableParamOptions: {
                additionalFilter: attachedColumnPlateFilter
              },
              fragment: [
                "containerArray",
                "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: proteinPurificationPlateFragment,
              isMultiSelect: true,
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              postSelectDTProps: {
                formName: "proteinPurificationSelectedPlates",
                isSingleSelect: true,
                schema: platePreviewSchema
              }
            }}
          />
          {missingMaterials.length > 0 && (
            <div className="tg-flex">
              <Callout intent={Intent.DANGER} icon="cross">
                {`The following required materials are missing from the plate: ${missingMaterials.join(
                  ", "
                )}.`}
              </Callout>
            </div>
          )}
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Collection Plate Volume"
            helper="Enter the volume of protein material
                that will be passed to the collection plate."
          />
          <div className="input-with-unit-select">
            <NumericInputField label="Protein Volume" name="volume" min={0} />
            <SelectField
              className="tg-unit-select"
              defaultValue="uL"
              name="volumetricUnitCode"
              label="none"
              options={unitGlobals.getOptionsForSelect("volumetricUnit")}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              text="Submit"
              disabled={missingMaterials.length > 0}
              loading={footerProps.submitting}
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("reactionMap", "containerArrays", "missingMaterials")
)(SelectInputs);

const platePreviewSchema = [
  platePreviewColumn(),
  "name",
  {
    path: "collectionPlate.name",
    displayName: "Collection Plate"
  },
  {
    displayName: "Times Used",
    render: (v, r) => getPropValue(r, "Number of Uses")
  },

  dateModifiedColumn
];

const schema = {
  model: "reaction",
  fields: [
    {
      displayName: "Reaction Name",
      path: "name"
    },
    {
      displayName: "Microbial Material",
      path: "reactionInputs[0].inputMaterial.name"
    },
    {
      displayName: "Output Material",
      path: "reactionOutputs[0].outputMaterial.name"
    }
  ]
};

const reactionMapFilter = (props, qb) => {
  qb.whereAll({
    reactionTypeCode: "PROTEIN_PURIFICATION"
  });
};
