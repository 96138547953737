/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { flatMap, get, keyBy } from "lodash";
import { ReactSelectField, DialogFooter, wrapDialog } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { Classes } from "@blueprintjs/core";

import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";

import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

const containerArrayFragment = `
  id
  name
  aliquotContainers {
    id
    aliquot {
      id
      volume
      volumetricUnitCode
    }
  }
`;

class ChangePlateTypeDialog extends Component {
  onSubmit = async values => {
    const { containerArrays, hideModal } = this.props;
    const { newPlateType } = values;
    try {
      const newMaxVolume = standardizeVolume(
        newPlateType.aliquotContainerType.maxVolume,
        newPlateType.aliquotContainerType.volumetricUnitCode
      );
      const platesWithAcs = await safeQuery(
        ["containerArray", containerArrayFragment],
        {
          variables: {
            filter: {
              id: containerArrays.map(c => c.id)
            }
          }
        }
      );
      const keyedPlatesWithAcs = keyBy(platesWithAcs, "id");
      // if the new plate type has a smaller max well volume we need to make sure the aliquots will fit
      const containerArraysToCheck = containerArrays.filter(
        c =>
          standardizeVolume(
            c.containerArrayType.aliquotContainerType.maxWellVolume,
            c.containerArrayType.aliquotContainerType.volumetricUnitCode
          ) > newMaxVolume
      );
      if (containerArraysToCheck.length) {
        const containerArraysThatDontFit = [];
        containerArraysToCheck.forEach(c => {
          const withAcs = keyedPlatesWithAcs[c.id];
          withAcs.aliquotContainers.some(ac => {
            const aliquotVolume = get(ac, "aliquot.volume");
            // if aliquot exceeds new max well volume
            if (
              aliquotVolume &&
              standardizeVolume(aliquotVolume, ac.aliquot.volumetricUnitCode) >
                newMaxVolume
            ) {
              containerArraysThatDontFit.push(c.name);
              return true;
            }
            return false;
          });
        });
        if (containerArraysThatDontFit.length) {
          return window.toastr.error(
            `These plates have aliquots that exceed the new max well volume of ${
              newPlateType.maxWellVolume
            } ${
              newPlateType.volumetricUnitCode
            }: ${containerArraysThatDontFit.join(", ")}`
          );
        }
      }
      await safeUpsert(
        "aliquotContainer",
        flatMap(platesWithAcs, p =>
          p.aliquotContainers.map(ac => ({
            id: ac.id,
            aliquotContainerTypeCode: newPlateType.aliquotContainerType.code
          }))
        )
      );
      await safeUpsert(
        "containerArray",
        containerArrays.map(c => ({
          id: c.id,
          containerArrayTypeId: newPlateType.id
        }))
      );
      // because we have pathViews
      const fragment = `
        id
        containerArrayType {
          id
          name
          isPlate
          containerFormatCode
          aliquotContainerType {
            code
            maxVolume
            volumetricUnitCode
          }
        }
      `;
      await safeQuery(["containerArrayPathView", fragment], {
        variables: {
          filter: {
            id: containerArrays.map(r => r.id)
          }
        }
      });
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error changing plate type.");
    }
  };

  render() {
    const {
      containerArrayTypes = [],
      hideModal,
      handleSubmit,
      submitting
    } = this.props;

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <ReactSelectField
            label="New Plate Type"
            name="newPlateType"
            isRequired
            options={arrayToItemValuedOptions(containerArrayTypes)}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </div>
    );
  }
}

const containerArrayTypeFragment = `
  id
  name
  aliquotContainerType {
    code
    maxVolume
    volumetricUnitCode
  }
`;

export default compose(
  wrapDialog({
    title: "Change Plate Type"
  }),
  withQuery(["containerArrayType", containerArrayTypeFragment], {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: ({ containerFormatCode }) => {
      return {
        variables: {
          filter: {
            isPlate: true,
            containerFormatCode
          }
        }
      };
    }
  }),
  reduxForm({
    form: "ChangePlateType"
  })
)(ChangePlateTypeDialog);
