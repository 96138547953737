/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment inventoryItemTypeFragment on inventoryItemType {
    code
    name
    description
    isSystemType
  }
`;
