/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

const SBOLIcons = {
  "USER-DEFINED": {
    key: "USER-DEFINED",
    name: "generic",
    path: "M 0 -2 L 35 -2 L 35 20 L 0 20Z",
    isSbol: true
  },
  "ASSEMBLY-SCAR": {
    key: "ASSEMBLY_JUNCTION",
    name: "assembly_junction",
    path: "M 0 5 L 30 5 M 0 15 L 30 15"
  },
  "BLUNT-RESTRICTION-SITE": {
    key: "BLUNT_RESTRICTION_SITE",
    name: "blunt_restriction_site",
    path: "M0 -3 3 -3 3 23 0 23 M13 -3 10 -3 10 23 13 23"
  },
  CDS: {
    key: "CDS",
    name: "cds",
    path: "M 0 23 24 23 35 11.5 24 0 0 0Z"
  },
  DIVIDER: {
    key: "DIVIDER",
    name: "divider",
    path: "M 0 -10 L 0 30 A1,1 0 0,0 7,30 L 7 -10 A1,1 0 0,0 0,-10"
  },
  "FIVE-PRIME-OVERHANG": {
    key: "FIVE_PRIME_OVERHANG",
    name: "five_prime_overhang",
    path: "M 0 5 L 30 5 M 15 15 L 30 15"
  },
  "FIVE-PRIME-UTR": {
    //As "ribosome entry site" in SBOL 1.0.0
    key: "FIVE_PRIME_UTR",
    center: true,
    name: "five_prime_utr",
    path: "M 35 5 L 35 11 L 0 11 L 0 5 C0,-20 35,-20 35,5Z"
  },
  "FIVE-PRIME-STICKY-RESTRICTION-SITE": {
    //New in SBOL 1.0.0
    key: "FIVE_PRIME_RESTRICTION_SITE",
    name: "five_prime_restriction_site",
    path: "M 0 -5 L 0 10 L 30 10 L 30 25"
  },
  INSULATOR: {
    key: "INSULATOR",
    name: "insulator",
    path: "M 0 -5 L 30 -5 L 30 25 L 0 25 Z M 7 2 L 23 2 L 23 18 L 7 18 Z"
  },
  OPERATOR: {
    key: "OPERATOR_SITE",
    name: "operator_site",
    path: "M 5 -10 L 5 10 L 25 10 L 25 -10"
  },
  "ORIGIN-OF-REPLICATION": {
    key: "ORIGIN_OF_REPLICATION",
    name: "origin_of_replication",
    path: "M 0, 0  a 12,12 0 1,0 24,0  a 12,12 0 1,0 -24,0"
  },
  "PRIMER-BINDING-SITE": {
    key: "PRIMER_BINDING_SITE",
    name: "primer_binding_site",
    path: "M 0 5 L 30 5 L 20 -5"
  },
  PROMOTER: {
    key: "PROMOTER",
    name: "promoter",
    path: "M 0 10 L 0 -15 L 30 -15 L 20 -20 M 30 -15 L 20 -10"
  },
  "PROTEASE-SITE": {
    key: "PROTEASE_SITE",
    name: "protease_site",
    path: "M 15 10 L 15 -15 M 8 -22 L 22 -8 M 22 -22 L 8 -8"
  },
  "PROTEIN-STABILITY-ELEMENT": {
    key: "PROTEIN_STABILITY_ELEMENT",
    name: "protein_stability_element",
    path: "M 7 -25 L 23 -25 L 23 -13 L 15 -7 L 7 -13 Z",
    path_complementary:
      "M0.683565,0.375099 C9.547971,-1.547578 9.78649,7.133499 0.528185,5.661234 C9.66863,3.14578 9.328839,12.595645 0.440425,10.876628 C9.503975,8.404751 9.429315,17.864787 0.365765,16.291774"
  },
  "RESTRICTION-ENZYME-RECOGNITION-SITE": {
    key: "RESTRICTION_ENZYME_RECOGNITION_SITE",
    name: "restriction_enzyme_recognition_site",
    path: "M 15 -10 L 15 30"
  },
  "RIBONUCLEASE-SITE": {
    key: "RIBONUCLEASE_SITE",
    name: "ribonuclease_site",
    path: "M 8 -22 L 22 -8 M 22 -22 L 8 -8 Z",
    path_complementary:
      "M1.433046,18.321771 C5.760161,13.519729 -2.735761,13.623156 1.433046,9.45435 M1.485815,27.13431 C5.81293,22.332268 -2.682991,22.435696 1.485815,18.26689 M1.591747,9.443634 C5.918862,4.641592 -2.577059,4.74502 1.591747,0.576214"
  },
  RBS: {
    center: true,
    key: "RBS",
    name: "ribosome_entry_site",
    path: "M 35 5 L 35 11 L 0 11 L 0 5 C0,-20 35,-20 35,5Z"
  },
  "RNA-STABILITY-ELEMENT": {
    key: "RNA_STABILITY_ELEMENT",
    name: "rna_stability_element",
    path: "M 7 -25 L 23 -25 L 23 -13 L 15 -7 L 7 -13 Z",
    path_complementary:
      "M1.433046,18.321771 C5.760161,13.519729 -2.735761,13.623156 1.433046,9.45435 M1.485815,27.13431 C5.81293,22.332268 -2.682991,22.435696 1.485815,18.26689"
  },
  SIGNATURE: {
    key: "SIGNATURE",
    name: "signature",
    path:
      "M 0 -2 L 35 -2 L 35 20 L 0 20Z M 4 16 L 14 6 M 4 6 L 14 16 M 18 16 L 32 16"
  },
  TERMINATOR: {
    key: "TERMINATOR",
    name: "terminator",
    path: "M 0 -15 L 30 -15 Z M 15 -15 L 15 10 Z"
  },
  "THREE-PRIME-OVERHANG": {
    key: "THREE_PRIME_OVERHANG",
    name: "three_prime_overhang",
    path: "M 0 5 L 30 5 M 15 15 L 0 15"
  },
  "THREE-PRIME-RESTRICTION-SITE": {
    key: "THREE_PRIME_RESTRICTION_SITE",
    name: "three_prime_restriction_site",
    path: "M 0 25 L 0 10 L 30 10 L 30 -5"
  }
};

export default SBOLIcons;
