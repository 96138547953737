/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const bacterialConjugationAliquotFragment = gql`
  fragment bacterialConjugationAliquotFragment on aliquot {
    id
    sample {
      id
      sampleTypeCode
      sampleFormulations {
        id
        materialCompositions {
          id
          material {
            id
            name
            materialTypeCode
            microbialMaterialMicrobialMaterialPlasmids {
              id
              polynucleotideMaterialId
              copyNumber
              polynucleotideMaterial {
                id
                polynucleotideMaterialSequence {
                  id
                  name
                }
              }
            }
            cellCultureCellCulturePlasmids {
              id
              polynucleotideMaterialId
              copyNumber
              polynucleotideMaterial {
                id
                polynucleotideMaterialSequence {
                  id
                  name
                }
              }
            }
          }
        }
      }
      material {
        id
        name
        materialTypeCode
        microbialMaterialMicrobialMaterialPlasmids {
          id
          polynucleotideMaterialId
          copyNumber
          polynucleotideMaterial {
            id
            polynucleotideMaterialSequence {
              id
              name
            }
          }
        }
        cellCultureCellCulturePlasmids {
          id
          polynucleotideMaterialId
          copyNumber
          polynucleotideMaterial {
            id
            polynucleotideMaterialSequence {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const plateFragment = gql`
  fragment bacterialConjugationPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        rowCount
        columnCount
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      rowPosition
      columnPosition
      aliquot {
        ...bacterialConjugationAliquotFragment
      }
    }
  }
  ${bacterialConjugationAliquotFragment}
`;
export const tubeFragment = gql`
  fragment bacterConjugationTubeFragment on aliquotContainer {
    id
    aliquotContainerTypeCode
    name
    barcode {
      id
      barcodeString
    }
    aliquot {
      ...bacterialConjugationAliquotFragment
    }
  }
  ${bacterialConjugationAliquotFragment}
`;
