/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  ReactSelectField,
  TextareaField
} from "@teselagen/ui";
import { reduxForm } from "redux-form";
import withQuery from "../../../../src-shared/withQuery";
import { get } from "lodash";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";
import { wrapDialog } from "@teselagen/ui";

class CodonMapDialog extends React.Component {
  onSubmit = async codonMap => {
    const { hideModal, codonMapId, initialValues, refetch } = this.props;
    try {
      const codonMapEntries = [];
      const toUpsert = {
        id: get(initialValues, "id"),
        name: codonMap.name,
        description: codonMap.description
      };

      if (initialValues) {
        await safeDelete(
          "codonMapEntry",
          initialValues.codonMapEntries.map(x => x.id)
        );
      }

      const upsertedCodonMap = await safeUpsert("codonMap", toUpsert);

      Object.entries(codonMap).forEach(([key, value]) => {
        if (key.length === 1) {
          codonMapEntries.push({
            codonMapId: upsertedCodonMap[0].id,
            probability: 1,
            aminoAcidResidueCode: key,
            dnaCodonCode: value
          });
        }
      });

      await safeUpsert("codonMapEntry", codonMapEntries);

      await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error ${codonMapId ? "editing" : "creating"} codon map.`
      );
    }
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      aminoAcidResidues,
      initialValues = {},
      submitting
    } = this.props;

    let codonEntryMap = {};
    if (initialValues.codonMapEntries) {
      codonEntryMap = initialValues.codonMapEntries.reduce((acc, val) => {
        if (val.probability === 1) {
          acc[val.aminoAcidResidueCode] = val.dnaCodonCode;
        }
        return acc;
      }, {});
    }

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="leftSide" style={{ marginRight: 15 }}>
              <InputField
                name="name"
                label="Name"
                defaultValue={initialValues.name}
              />
              <TextareaField
                name="description"
                label="Description"
                defaultValue={initialValues.description}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%"
                }}
              >
                <div
                  key="aminoAcidResidueHeaderLeft"
                  style={{
                    display: "flex",
                    fontWeight: "bold",
                    flexDirection: "row",
                    marginBottom: 5
                  }}
                >
                  <div style={{ width: 110 }}>Amino Acid</div>
                  <div style={{ width: 55 }}>Code</div>
                  <div style={{ width: 60, marginRight: 10 }}>3-Letter</div>
                  <div>Resolving DNA Codon</div>
                </div>
                {aminoAcidResidues
                  .slice(0, 9)
                  .map(aar => renderCodonSelectors(aar, codonEntryMap))}
              </div>
            </div>

            <div
              className="rightSide"
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div
                key="aminoAcidResidueHeaderRight"
                style={{
                  display: "flex",
                  fontWeight: "bold",
                  flexDirection: "row",
                  marginBottom: 10
                }}
              >
                <div style={{ width: 110 }}>Amino Acid</div>
                <div style={{ width: 55 }}>Code</div>
                <div style={{ width: 60, marginRight: 10 }}>3-Letter</div>
                <div>Resolving DNA Codon</div>
              </div>
              {aminoAcidResidues
                .slice(9)
                .map(aar => renderCodonSelectors(aar, codonEntryMap))}
            </div>
          </div>
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

function renderCodonSelectors(aminoAcidResidue, codonEntryMap) {
  return (
    <div
      key={aminoAcidResidue.code}
      style={{
        display: "flex",
        flexDirection: "row"
      }}
    >
      <div
        key={`${aminoAcidResidue.name}-name`}
        style={{ width: 110, marginRight: 5 }}
      >
        {aminoAcidResidue.name}
      </div>
      <div
        key={`${aminoAcidResidue.code}-code`}
        style={{ width: 55, marginRight: 5 }}
      >
        {aminoAcidResidue.code}
      </div>
      <div
        key={`${aminoAcidResidue.threeLetterCode}-threeLetterCode`}
        style={{ width: 60 }}
      >
        {aminoAcidResidue.threeLetterCode}
      </div>
      <ReactSelectField
        name={aminoAcidResidue.code}
        isRequired={true}
        defaultValue={
          codonEntryMap[aminoAcidResidue.code] ||
          aminoAcidResidue.dnaCodons[0].code
        }
        options={aminoAcidResidue.dnaCodons.map(codon => ({
          label: codon.code,
          value: codon.code
        }))}
      />
    </div>
  );
}

export default compose(
  reduxForm({
    form: "codonMapDialog",
    enableReinitialize: true
  }),
  wrapDialog({
    getDialogProps: ({ codonMapId }) => {
      const title = (codonMapId ? "Edit" : "Create") + " Codon Map";
      return {
        title
      };
    },
    style: {
      width: 860
    }
  }),
  withQuery(
    ["aminoAcidResidue", "code name threeLetterCode dnaCodons { code }"],
    {
      showLoading: true,
      isPlural: true,
      inDialog: true
    }
  )
)(CodonMapDialog);
