/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { forEach } from "lodash";
import React, { ReactElement } from "react";

export const disableOligosInSeqLibHelper = {
  checkIfIntegrationsDisabled: ({
    selectedRecords
  }: {
    selectedRecords: { sequenceTypeCode: string }[];
  }): string | ReactElement | null => {
    let hasOligos;
    let hasSeqs;
    forEach(selectedRecords, s => {
      if (s.sequenceTypeCode === "OLIGO") {
        hasOligos = true;
      }
      if (s.sequenceTypeCode !== "OLIGO") {
        hasSeqs = true;
      }
    });

    if (hasOligos && !hasSeqs) {
      return "You've selected oligo(s). Please go to the Oligo library to complete this action";
    }
    if (hasOligos && hasSeqs) {
      return (
        <div style={{ maxWidth: 250 }}>
          You've selected a mix of oligos and DNA sequences. Select just the DNA
          sequences to continue. Go to the Oligo library to complete this action
          for the oligos
        </div>
      );
    }
    return null;
  }
};
