/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { ReactSelectField } from "@teselagen/ui";
import { breakdownPatterns } from "../../../PlateReformatTool/utils";
import { Callout } from "@blueprintjs/core";
import { formValues } from "redux-form";

function DistributionAdvancedOptions({ containerFormat, distributionPattern }) {
  const isRect =
    (containerFormat.rowCount * containerFormat.columnCount) % 4 === 0;
  if (!isRect) return null;

  return (
    <div>
      {distributionPattern && (
        <Callout intent="warning" style={{ marginTop: 15 }}>
          Using a distribution pattern will override the fill direction and
          replace all items.
        </Callout>
      )}
      <div style={{ minWidth: 250, marginTop: 15 }}>
        <ReactSelectField
          name="distributionPattern"
          label="Distribution Pattern"
          secondaryLabel="(Optional)"
          options={breakdownPatterns(true)}
        />
      </div>
    </div>
  );
}

export default formValues("distributionPattern")(DistributionAdvancedOptions);
