/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
import { Callout } from "@blueprintjs/core";
import { startCase } from "lodash";
// import { unparse } from "papaparse";
import React from "react";
import { FileUploadField, showConfirmationDialog } from "@teselagen/ui";
import { getFeatureTypes } from "@teselagen/sequence-utils";
import { anyToJson } from "@teselagen/bio-parsers";

import {
  allowedCsvFileTypes,
  parseCsvOrExcelFile,
  validateCSVRequiredHeaders,
  validateCSVRow
} from "../../../../tg-iso-shared/src/utils/fileUtils";
import { useButtonTabs } from "../../useButtonTabs";
import { download } from "../../utils/downloadTest";
import { throwFormError } from "../../utils/formUtils";
import { getSequenceWithinRange } from "@teselagen/range-utils";
import { showStackedDialog } from "../../StackedDialog";
import { AutoAnnotateBpMatchingDialog } from "./AutoAnnotateBpMatchingDialog";

const getTabEl = ({ fileLimit, accept }) => ({
  selectedTabId,
  annotationType
}) => {
  return (
    <FileUploadField
      key={selectedTabId}
      name={selectedTabId}
      isRequired
      fileLimit={fileLimit}
      accept={accept({ annotationType })}
    />
  );
};
const tabTypes = [
  {
    id: "csvFile",
    TabEl: getTabEl({
      accept: ({ annotationType }) => ({
        type: allowedCsvFileTypes,
        validateAgainstSchema: {
          name: "Example CSV Annotation Upload File",
          fields: [
            {
              isRequired: true,
              path: "name",
              example: `Example ${startCase(annotationType)} 2`
            },
            { path: "description", example: "I'm a description" },
            {
              isRequired: true,
              path: "sequence",
              example: "gatNNtacaggttt",
              validate: value => {
                if (!value) {
                  return "Sequence is required";
                }
                if (value.length < 5) {
                  return "Sequence must be at least 5 bps long";
                }
              }
            },
            ...(annotationType === "feature"
              ? [
                  {
                    path: `type`,
                    type: `dropdown`,
                    values: getFeatureTypes(),
                    defaultValue: getFeatureTypes()[0]
                  }
                ]
              : []),
            {
              path: "isRegex",
              type: "boolean",
              defaultValue: false
            }
          ]
        }
      }),
      fileLimit: 1
    }),

    text: "CSV"
  },
  {
    id: "apeFile",

    TabEl: getTabEl({
      accept: () => {
        return {
          type: ".txt",
          exampleFile: () => {
            download(
              `T3	ATTAACCCTCACTAAAGGGA	primer_bind	cyan	green	0	0
M13-fwd	TGTAAAACGACGGCCAGT	primer_bind	cyan	green	0	0
FRT	GAAGTTCCTATTCTCTAGAAAGTATAGGAACTTC	misc_recomb	orchid	pink	0	0`,
              `Example APE Feature List Upload File.txt`,
              "text/plain"
            );
          }
        };
      },
      fileLimit: 1
    }),

    text: "ApE File"
  },
  {
    id: "genbankFiles",
    TabEl: getTabEl({
      accept: () => {
        return {
          type: [".gb", ".genbank"],
          exampleFile: () => {
            download(
              `LOCUS       pExample_02              5299 bp    DNA     circular  13-APR-2021
FEATURES             Location/Qualifiers
      protein_bind       14..24
                      /label="operator O2"
      misc_marker        complement(12..77)
                      /label="CmR"
ORIGIN
        1 gacgtcttat gacaacttga cggctacatc attcactttt tcttcacaac cggcacggaa
        61 ctcgctcggg ctggccccgg tgcatttttt aaatacccgc gagaaataga gttgatcgtc
//`,
              `pExample_02.gb`,
              "text/plain"
            );
          }
        };
      }
    }),

    text: "Genbanks"
  }
];

export function useImportPanel({ annotationType }) {
  const {
    tabs,
    selectedTabId,
    selectedTab: { TabEl }
  } = useButtonTabs(tabTypes);

  return {
    importType: selectedTabId,
    panel: (
      <div>
        <Callout intent="primary">
          {/* {
            {
              csvFile: `Import a formatted CSV with the following fields:  name
              name
              description
              sequence
              annotationType
              isRegex
              (download examples from the three button menu on the right)`
            }[selectedTabId]
          } */}
          Import a formatted CSV, an APE style feature list, or features
          directly from genbank files (download examples from the three button
          menu on the right)
        </Callout>
        <div style={{ margin: "10px 0" }}>
          <a
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showStackedDialog({
                ModalComponent: AutoAnnotateBpMatchingDialog
              });
            }}
          >
            Click here
          </a>{" "}
          for more info about Regexes (supported in CSV import only)
        </div>

        <br></br>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 10 }}>Upload Type:</div> {tabs}
        </div>
        <TabEl {...{ selectedTabId, annotationType }}></TabEl>
      </div>
    )
  };
}

export const onSubmitImport = ({
  annotationType,
  createRegisteredAnnotations,
  refetch,
  importType,
  hideModal,
  registeredAnnotationTypeCode
}) => async ({ selectedAnnotationGroups, csvFile, apeFile, genbankFiles }) => {
  let convertNonStandardTypes = false;
  try {
    const newAnnotations = [];
    const validateRow = async (row, rowName) => {
      const { type = "", sequence } = row;
      let regexConvertedSeq;

      if (annotationType === "feature") {
        const cleanedType = getFeatureTypes().find(
          t => t.toLowerCase() === type.toLowerCase()
        );
        if (!cleanedType) {
          if (!convertNonStandardTypes) {
            convertNonStandardTypes = await showConfirmationDialog({
              cancelButtonText: "Stop Registration",
              text: `Detected that ${rowName} has a non-standard type of ${type}. We will assign it and all subsequent non-standard types to use the misc_feature type instead`
            });
            if (!convertNonStandardTypes) {
              throw {
                validationError: `${rowName} specifies the feature type ${type} which is not valid`
              };
            }
          }
          row.type = "misc_feature";
        } else {
          row.type = cleanedType;
        }
      }
      if (!sequence) {
        throw {
          validationError: `${rowName} did not have a sequence`
        };
      }
      if (row.isRegex && row.isRegex.toUpperCase() === "TRUE") {
        try {
          new RegExp(regexConvertedSeq); //just trying out whether the regexConvertedSeq will work as a valid regex
        } catch (error) {
          throw {
            validationError: `${rowName} has an invalid sequence/regex. Please fix it manually.`
          };
        }
        row.isRegex = true;
      } else {
        row.isRegex = undefined;
      }
      newAnnotations.push(row);
    };
    if (importType === "csvFile") {
      const csvHeaders = ["name", "description", "sequence"];
      if (annotationType === "feature") {
        csvHeaders.push("type");
      }

      const {
        data,
        meta: { fields }
      } = await parseCsvOrExcelFile(csvFile[0]);
      const error = validateCSVRequiredHeaders(fields, csvHeaders);
      if (error) {
        throw {
          validationError: error
        };
      }
      for (const [index, row] of data.entries()) {
        const error = validateCSVRow(row, csvHeaders, index);
        if (error) {
          throw {
            validationError: error
          };
        }
        await validateRow(row, `Row ${index + 1}`);
      }
    } else if (importType === "apeFile") {
      const { data } = await parseCsvOrExcelFile(apeFile[0], {
        csvParserOptions: { header: false }
      });
      for (const [i, [name, sequence, type]] of data.entries()) {
        await validateRow({ name, sequence, type }, `Row ${i + 1}`);
      }
    } else if (importType === "genbankFiles") {
      for (const file of genbankFiles) {
        const results = await anyToJson(file.originalFileObj, {
          fileName: file.name
        });

        if (results) {
          for (const { parsedSequence } of results) {
            if (!parsedSequence) {
              return;
            }

            const { features, sequence } = parsedSequence;
            for (const feat of features) {
              const { name, type } = feat;
              await validateRow(
                {
                  name,
                  type,
                  sequence: getSequenceWithinRange(feat, sequence)
                },
                `File: ${file.name} with feature: ${name}`
              );
            }
          }
        }
      }
    } else {
      console.info(`we shouldn't be here!`);
      console.info(`importType:`, importType);
    }

    if (!newAnnotations.length) {
      return window.toastr.warning(
        "No Annotations Detected on File. Please check that your file is in the correct format."
      );
    }
    const numCreated = await createRegisteredAnnotations({
      refetch,
      annotationType,
      selectedAnnotationGroups,
      hideModal,
      registeredAnnotationTypeCode,
      annotationsToRegister: newAnnotations
    });
    numCreated &&
      window.toastr.success(
        `Import Successful. Created ${numCreated} New ${startCase(
          annotationType
        )}${numCreated > 1 ? "s" : ""}`
      );
  } catch (error) {
    if (error.validationError) {
      throwFormError({
        [importType]: error.validationError
      });
    } else {
      console.error(`error:`, error);
      window.toastr.error(
        `Error uploading ${annotationType}(s). Double check your file to make sure it is valid!`
      );
    }
  }
};
