/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

import { prepareWetAliquot } from "../../../utils/plateUtils";

import { HydrateFormCore } from "../../HydrateForm";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import aliquotHydrationFragment from "../../../graphql/fragments/aliquotHydrationFragment";
import withQuery from "../../../../src-shared/withQuery";

class RehydrateAliquotDialog extends Component {
  onSubmit = async values => {
    const { hideModal, aliquots } = this.props;
    const {
      volume,
      volumetricUnitCode,
      concentrationUnitCode,
      molarityUnitCode
    } = values;

    try {
      const aliquotUpdates = [];
      aliquots.forEach(aliquot => {
        const rehydratedAliquot = prepareWetAliquot({
          aliquot,
          volume,
          volumetricUnitCode,
          concentrationUnitCode,
          molarityUnitCode
        });
        aliquotUpdates.push(rehydratedAliquot);
      });

      await safeUpsert(aliquotHydrationFragment, aliquotUpdates);
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating aliquots.");
    }
  };

  render() {
    const { handleSubmit, hideModal, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <HydrateFormCore />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Rehydrate Aliquot" }),
  withQuery(aliquotHydrationFragment, {
    showLoading: true,
    inDialog: true,
    isPlural: true,
    options: props => {
      return {
        variables: {
          filter: {
            id: props.aliquotId || props.aliquotIds
          }
        }
      };
    }
  }),
  reduxForm({
    form: "rehydrateAliquot"
  })
)(RehydrateAliquotDialog);
