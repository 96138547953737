/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  getAllOfType,
  isCardCircular,
  getBinsInCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import tgCreateCachedSelector from "../../../tg-iso-design/utils/tgCreateCachedSelector";

export const getBinsFilteredForRender = tgCreateCachedSelector(
  getBinsInCard,
  isCardCircular,
  (bins, isCircular) => (isCircular ? bins.slice(1) : bins)
)((start, cardId) => cardId);

export const getIconsForRender = tgCreateCachedSelector(
  state => getAllOfType(state, "icon"),
  getBinsFilteredForRender,
  (icons, bins) => {
    return bins.map(bin => ({
      ...icons[bin.iconId],
      direction: bin.direction
    }));
  }
)((start, cardId) => cardId);
