/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const schema = () => {
  const advancedMode = localStorage.getItem("advancedMetadata") === "true";
  const assaySubjectClass = {
    label: "Assay Subject Class",
    query: "id name description isSystemType",
    schema: {
      model: "assaySubjectClass",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "description",
          displayName: "Description"
        },
        {
          path: "isSystemType",
          type: "boolean",
          displayName: "Is System Type",
          isHidden: true
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      }
    },
    restricted: true
  };
  const externalSourceSystem = {
    type: "externalSourceSystem",
    label: "External Source System",
    tab: "externalsourcesystem",
    query: "id name description url isSystemType",
    schema: {
      model: "externalSourceSystem",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        { path: "url", type: "string", displayName: "URL" }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      url: {
        label: "URL",
        type: "inputField",
        value: ""
      }
    },
    restricted: true
  };
  const descriptorType = {
    type: "descriptorType",
    label: "Descriptor Type",
    tab: "descriptortype",
    query:
      "id name description isExternalReference isSystemType externalSourceSystemId urlTemplate externalSourceSystem { id url }",
    datasets: {
      externalSourceSystem: []
    },
    schema: {
      model: "descriptorType",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "isExternalReference",
          type: "string",
          displayName: "Is external reference"
        },
        {
          path: "urlTemplate",
          type: "string",
          displayName: "Url template"
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      urlTemplate: {
        label: "URL Template",
        type: "inputField",
        value: ""
      },
      isExternalReference: {
        label: "Is External reference",
        type: "checkboxField",
        value: false
      },
      externalSourceSystemId: {
        label: "External source system",
        type: "selectField",
        dataset: {
          model: "externalSourceSystem",
          query: "id name"
        },
        value: "",
        required: false
      }
    },
    restricted: true
  };
  const measurementTarget = {
    label: "Measurement Target",
    tab: "measurementtarget",
    query: "id name description isSystemType",
    schema: {
      model: "measurementTarget",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "isSystemType",
          type: "boolean",
          displayName: "Is System Type",
          isHidden: true
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      }
    },
    restricted: true,
    isHidden: !advancedMode
  };
  const measurementType = {
    type: "measurementType",
    label: advancedMode ? "Measurement Type" : "Measurements",
    tab: "measurementtype",
    datasets: {
      measurementTarget: [],
      unitDimension: []
    },
    schema: {
      model: "measurementType",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "measurementTarget.name",
          type: "string",
          displayName: "Measurement Target",
          isHidden: !advancedMode
        },
        {
          path: "unitDimension.name",
          type: "string",
          displayName: "Unit Dimension"
        },
        { path: "isNumeric", type: "boolean", displayName: "Is Numeric" },
        {
          path: "isSystemType",
          type: "boolean",
          displayName: "Is System Type",
          isHidden: true
        }
      ]
    },
    query:
      "id measurementTarget { id name } unitDimension { id name } name description isSystemType isNumeric",
    form: {
      measurementTargetId: {
        label: "Measurement Target",
        type: "selectField",
        dataset: {
          model: "measurementTarget",
          query: "id name"
        },
        value: "",
        required: advancedMode,
        isHidden: !advancedMode
      },
      unitDimensionId: {
        label: "Unit Dimension",
        type: "selectField",
        dataset: {
          model: "unitDimension",
          query: "id name"
        },
        value: "",
        required: true
      },
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      isNumeric: {
        label: "Is Numeric",
        type: "checkboxField",
        value: false
      }
    },
    restricted: true
  };
  const computationalMethod = {
    type: "computationalMethod",
    label: "Computational method",
    query: "id name description",
    schema: {
      model: "computationalMethod",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: "",
        required: false
      }
    },
    restricted: true
  };
  const computedValueType = {
    type: "computedValueType",
    label: "Computed value type",
    query:
      "id name description measurementType { id name } isMeasurement isNumeric computationalMethod { id name }",
    datasets: {
      measurementType: [],
      computationalMethod: []
    },
    schema: {
      model: "computedValueType",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "measurementType.name",
          type: "string",
          displayName: "Measurement type"
        },
        {
          path: "computationalMethod.name",
          type: "string",
          displayName: "Computational method"
        },
        { path: "isNumeric", type: "boolean", displayName: "Is numeric" },
        {
          path: "isMeasurement",
          type: "boolean",
          displayName: "Is measurement"
        }
      ]
    },
    form: {
      computationalMethodId: {
        label: "Select computational method",
        type: "selectField",
        dataset: {
          model: "computationalMethod",
          query: "id name"
        },
        value: "",
        required: true
      },
      measurementTypeId: {
        label: "Select measurement type",
        type: "selectField",
        dataset: {
          model: "measurementType",
          query: "id name"
        },
        value: "",
        required: true
      },
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      isNumeric: {
        label: "Is Numeric",
        type: "checkboxField",
        value: false
      },
      isMeasurement: {
        label: "Is Measurement",
        type: "checkboxField",
        value: false
      }
    },
    restricted: true
  };
  const unit = {
    type: "unit",
    label: "Unit",
    tab: "unit",
    query:
      "id name description isSystemType unitScale {id name unitDimension {id name}} isBaseUnit",
    datasets: {
      unitScale: []
    },
    schema: {
      model: "unit",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "unitScale.unitDimension.name",
          type: "string",
          displayName: "Unit Dimension",
          isHidden: advancedMode
        },
        {
          path: "unitScale.name",
          type: "string",
          displayName: "Unit Scale",
          isHidden: !advancedMode
        },
        {
          path: "isBaseUnit",
          type: "boolean",
          displayName: "Is base unit",
          isHidden: !advancedMode
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      unitScaleId: {
        label: "Unit Scale",
        type: "selectField",
        dataset: {
          model: "unitScale",
          query: "id name"
        },
        value: "",
        required: advancedMode,
        isHidden: !advancedMode
      },
      unitDimensionId: {
        path: "unitScale.unitDimension.id",
        label: "Unit Dimension",
        type: "selectField",
        dataset: {
          model: "unitDimension",
          query: "id name"
        },
        value: "",
        required: !advancedMode,
        isHidden: advancedMode
      },
      isBaseUnit: {
        label: "Is Base Unit",
        type: "checkboxField",
        value: false,
        isHidden: !advancedMode
      }
    },
    restricted: true
  };
  const unitScale = {
    type: "unitScale",
    label: "Unit Scale",
    query: "id name description isSystemType unitDimension {id name}",
    datasets: {
      unitDimension: []
    },
    schema: {
      model: "unitScale",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "unitDimension.name",
          type: "string",
          displayName: "Unit Dimension"
        },
        {
          path: "isSystemType",
          type: "boolean",
          displayName: "Is System Type",
          isHidden: true
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      unitDimensionId: {
        label: "Unit Dimension",
        type: "selectField",
        dataset: {
          model: "unitDimension",
          query: "id name"
        },
        value: "",
        required: true
      }
    },
    restricted: true,
    isHidden: !advancedMode
  };
  const unitDimension = {
    label: "Unit Dimension",
    type: "unitDimension",
    query: "id name description isSystemType",
    schema: {
      model: "unitDimension",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "description",
          type: "string",
          displayName: "Description"
        },
        {
          path: "isSystemType",
          type: "boolean",
          displayName: "Is System Type",
          isHidden: true
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      }
    },
    restricted: true
  };
  const columnMap = {
    label: "Column Mapper",
    query:
      "id columnName classType {code name} descriptorType {id name} assaySubjectClass {id name} measurementType {id name unitDimensionId} referenceDimension {id name unitDimensionId} unit {id name} mappingPresetColumnMaps {id mappingPresetId}",
    schema: {
      model: "columnMap",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "columnName", type: "string", displayName: "Column Name" },
        { path: "classType.name", type: "string", displayName: "Class" },
        {
          render: (v, r) => {
            const subClassName = r[r.classType?.code]?.name;
            return r.unit ? `${subClassName} [${r.unit.name}]` : subClassName;
          },
          path: "subClass",
          type: "string",
          displayName: "SubClass"
        }
      ]
    },
    form: {
      columnName: {
        label: "Column Name",
        type: "inputField",
        value: "",
        required: true
      },
      class: {
        label: "Class",
        type: "selectField",
        path: "classType",
        dataset: {
          model: "classType",
          query: "code name"
        },
        value: "",
        required: true
      },
      subClass: {
        path: "subClass",
        label: "Sub Class",
        type: "selectField",
        value: "",
        required: true
      },
      unit: {
        path: "unit",
        label: "Unit",
        type: "selectField",
        value: ""
      }
    },
    restricted: true
  };
  const mappingPreset = {
    label: "Data Mapper",
    query: "id name description header",
    schema: {
      model: "mappingPreset",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        { path: "description", type: "string", displayName: "Description" }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      columnMap: {
        key: "columnMap",
        label: "Column Map",
        type: "selectField",
        value: "",
        dataset: {
          model: "columnMap",
          query: "id name:columnName"
        }
      }
    },
    restricted: true
  };
  const referenceDimension = {
    label: "Reference Dimension",
    query:
      "id name description isSystemType isNumeric unitDimension {id name} hasUnits hasSpecificValues",
    datasets: {
      unitDimension: []
    },
    schema: {
      model: "referenceDimension",
      fields: [
        { path: "id", type: "string", displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        { path: "description", type: "string", displayName: "Description" },
        {
          path: "unitDimension.name",
          type: "string",
          displayName: "Unit Dimension"
        },
        { path: "isNumeric", type: "boolean", displayName: "Is numeric" },
        {
          path: "isSystemType",
          type: "boolean",
          displayName: "Is system type",
          isHidden: true
        },
        { path: "hasUnits", type: "boolean", displayName: "has units" },
        {
          path: "hasSpecificValues",
          type: "boolean",
          displayName: "hasSpecificValues"
        }
      ]
    },
    form: {
      name: {
        label: "Name",
        type: "inputField",
        value: "",
        required: true
      },
      description: {
        label: "Description",
        type: "inputField",
        value: ""
      },
      unitDimensionId: {
        label: "Unit Dimension",
        type: "selectField",
        dataset: {
          model: "unitDimension",
          query: "id name"
        },
        value: "",
        required: true
      },
      isNumeric: {
        label: "Is Numeric",
        type: "checkboxField",
        value: false
      },
      isSystemType: {
        label: "Is System Type",
        type: "checkboxField",
        value: false
      }
    },
    restricted: true
  };
  const main = {
    assaySubjectClass: {
      label: "Assay Subject Class",
      content: assaySubjectClass
    },
    unit: {
      label: "Units",
      content: [unitDimension, unitScale, unit]
    },
    descriptor: {
      label: "Descriptors",
      content: [externalSourceSystem, descriptorType]
    },
    measurement: {
      label: "Measurements",
      content: [measurementTarget, measurementType]
    },
    computedValue: {
      label: "Computed values",
      content: [computationalMethod, computedValueType],
      restricted: true
    },
    columnMapper: {
      label: "Column Mappers",
      content: columnMap
    },
    dataMapper: {
      label: "Data Mappers",
      content: mappingPreset
    },
    referenceDimension: {
      label: "Reference Dimension",
      content: referenceDimension
    }
  };
  return {
    main,
    assaySubjectClass,
    externalSourceSystem,
    descriptorType,
    measurementTarget,
    measurementType,
    computationalMethod,
    computedValueType,
    unit,
    unitScale,
    unitDimension,
    referenceDimension,
    mappingPreset
  };
};

export default schema;
