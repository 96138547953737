/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const BACKBONE_BORDER_RADIUS = 25;

export const WITHIN_SVG_PADDING = 30;

export const BACKBONE_RING_HEIGHT = 75;
export const SHAPE_HEIGHT = 20;
export const SHAPE_WIDTH = 30;
export const SHAPE_EXTRA_WIDTH = 10;
export const SPACE_BETWEEN_SHAPES = 20;
export const ARROWHEAD_SIZE = 15;

export const MIN_SPACE_BETWEEN_SETS_AND_ELEMENTS = 60; // 40

export const ELEMENT_LABEL_EXTRA_WIDTH = 10;
export const ELEMENT_LABEL_EXTRA_HEIGHT = 10;
export const ELEMENT_LABEL_LINE_HEIGHT = 20;
export const ELEMENT_LABEL_MIN_WIDTH = 30;
export const ELEMENT_LABEL_MIN_SPACING = 12;
export const ELEMENT_LABEL_TRACK_HEIGHT = 15;
export const ELEMENT_LABEL_LINE_MERGE_NODE_DISTANCE = 2;
export const ELEMENT_LABEL_TAILING_WIDTH = 15;
export const ELEMENT_LABEL_HEADER_HEIGHT = 20;

export const COLOR_SQUARE_SIZE = 15;

export const DIVIDER_WIDTH = 6;

export const BACKGROUND_BOX_COLOR = "#fff";
export const BACKGROUND_HELPER_BOX_COLOR = "transparent"; //this helps dragging thin shapes
export const SHAPE_BASE_COLOR = "#a7a7a7";

export const COLORS = [
  "#e0e3e6",
  "#00FFFF",
  "#008000",
  "#6e3667",
  "#675682",
  "#5f0f4e",
  "#94618e",
  "#e4c8b7",
  "#560000",
  "#afc2b4",
  "#f3e1db",
  "#ffeaec",
  "#fe7686",
  "#9a819d",
  "#432e46",
  "#2e4643",
  "#32171a",
  "#04e561",
  "#ff0000",
  "#000000",
  "#ffffff",
  "#b5bebf",
  "#dae0e9",
  "#e2e2e2",
  "#fbfbfb",
  "#a7a7a7",
  "#333232",
  "#ebc42e",
  "#f8ed53",
  "#ff005f",
  "#9f3fff",
  "#2deadb",
  "#6f452c",
  "#e2de6a",
  "#ff7fbf",
  "#21204e",
  "#945aca",
  "#f69d3a",
  "#08db72",
  "#ee3239",
  "#f75a56",
  "#7fbfff",
  "#2d8ceb"
];

export const CHAR_WIDTH = 9;
