/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment duplicatedSequenceInDesignFragment on sequence {
    id
    name
    circular
    sequence
    description
    size
    hash
    lab {
      id
      name
    }
    sequenceFeatures {
      id
      start
      end
      type
      name
      strand
      locations
    }
    parts {
      id
      name
      start
      end
      strand
    }
  }
`;
