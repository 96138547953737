/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isPlainObject } from "lodash";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { isBrowser } from "browser-or-node";
import { pick } from "lodash";
import { getBoundExtendedPropertyUploadHelpers } from "../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { addBarcodesToRecords } from "./barcodeUtils";
import { noop } from "lodash";

export async function handleEquipmentUpload(
  {
    equipmentJsons = [],
    files = [],
    locationId,
    generateBarcode = true,
    promptBarcodeOverwrite,
    refetch = noop
  },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert, safeDelete } = ctx;
  const equipmentIds = [];
  const containerIds = [];
  const containerPositionIds = [];
  const containersToCreate = [];
  const containerPositionsToCreate = [];
  // const equipmentPositionsToCreate = [];
  const containerTypeCodes = [];
  const equipmentTypeCodes = [];
  let clearToast;
  const keyedEquipmentTypes = {};
  const keyedContainerTypes = {};

  const collectContainerTypes = containers => {
    containers.forEach(container => {
      const type = container.type || container.containerTypeCode;
      if (type && !containerTypeCodes.includes(type)) {
        containerTypeCodes.push(type);
      }

      if (container.containers) collectContainerTypes(container.containers);
      if (container.positions) {
        container.positions.forEach(position => {
          if (position.containers) collectContainerTypes(position.containers);
        });
      }
    });
  };
  for (const parsedEquipmentObj of equipmentJsons) {
    if (!parsedEquipmentObj || !isPlainObject(parsedEquipmentObj)) {
      throw new Error(
        "Improper file structure. Make sure there is a root equipment object."
      );
    }
    equipmentTypeCodes.push(
      parsedEquipmentObj.type || parsedEquipmentObj.equipmentTypeCode
    );
    if (parsedEquipmentObj.containers)
      collectContainerTypes(parsedEquipmentObj.containers);
    if (parsedEquipmentObj.positions) {
      throw new Error(
        "Positions can not be nested directly inside equipment. Please add a container first."
      );
    }
  }

  const existingEquipmentTypes = await safeQuery(
    ["equipmentType", "code name"],
    {
      variables: {
        filter: caseInsensitiveFilter(
          "equipmentType",
          "name",
          equipmentTypeCodes,
          {
            returnQb: true
          }
        )
          .orWhereAll({
            code: equipmentTypeCodes
          })
          .toJSON()
      }
    }
  );
  const existingContainerTypes = await safeQuery(
    ["containerType", "code name"],
    {
      variables: {
        filter: caseInsensitiveFilter(
          "containerType",
          "name",
          containerTypeCodes,
          {
            returnQb: true
          }
        )
          .orWhereAny({
            code: containerTypeCodes
          })
          .toJSON()
      }
    }
  );
  let error = "";
  existingEquipmentTypes.forEach(t => {
    keyedEquipmentTypes[t.code.toLowerCase()] = t;
    keyedEquipmentTypes[t.name.toLowerCase()] = t;
  });
  existingContainerTypes.forEach(t => {
    keyedContainerTypes[t.code.toLowerCase()] = t;
    keyedContainerTypes[t.name.toLowerCase()] = t;
  });
  const missingEquipmentTypes = equipmentTypeCodes.filter(
    c => !keyedEquipmentTypes[c.toLowerCase()]
  );
  if (missingEquipmentTypes.length) {
    error += ` \nThese equipment types were not found: ${missingEquipmentTypes.join(
      ", "
    )}. Please add them in settings.`;
  }
  const missingContainerTypes = containerTypeCodes.filter(
    c => !keyedContainerTypes[c.toLowerCase()]
  );
  if (missingContainerTypes.length) {
    error += ` \nThese container types were not found: ${missingContainerTypes.join(
      ", "
    )}. Please add them in settings.`;
  }
  if (error) {
    throw new Error(error);
  }

  let hasChosenToOverwiteBarcodes = false;
  try {
    const createItemFromObject = async (item = {}, isEquipment, otherKeys) => {
      const type =
        item.type ||
        (isEquipment ? item.equipmentTypeCode : item.containerTypeCode);
      if (!type) {
        throw new Error(`No type specified for item: ${item.name}`);
      }
      if (generateBarcode && item.barcode) {
        if (promptBarcodeOverwrite && isBrowser) {
          if (!hasChosenToOverwiteBarcodes) {
            const continueUpload = await window.showConfirmationDialog({
              text: `You have checked generate barcodes and there are barcodes\
               specified in the JSON file. Do you want to continue with upload and overwrite these barcodes?`,
              confirmButtonText: "Yes",
              cancelButtonText: "No"
            });
            if (continueUpload) {
              hasChosenToOverwiteBarcodes = true;
            } else {
              throw new Error("Cancelled upload due to barcode overwrite.");
            }
          }
        } else {
          throw new Error(
            `Barcodes can not be specified in the JSON file when generating barcodes.`
          );
        }
      }
      const typeCode = isEquipment
        ? keyedEquipmentTypes[type.toLowerCase()].code
        : keyedContainerTypes[type.toLowerCase()].code;
      const cleanedItem = {
        name: item.name,
        label: item.label,
        ...(!generateBarcode &&
          item.barcode && {
            barcode: {
              barcodeString: item.barcode
            }
          }),
        [isEquipment ? "equipmentTypeCode" : "containerTypeCode"]: typeCode,
        ...otherKeys
      };

      return cleanedItem;
    };
    let importCollectionName = "Equipment Upload";
    if (files && files.length === 1) {
      importCollectionName = files[0].name;
    }
    await ctx.startImportCollection?.(importCollectionName);
    if (isBrowser) {
      clearToast = window.showProgressToast("Importing equipment...");
    }

    for (const parsedEquipmentObj of equipmentJsons) {
      const newEquipment = await createItemFromObject(
        parsedEquipmentObj,
        true,
        pick(parsedEquipmentObj, [
          "description",
          "make",
          "model",
          "manufacturer",
          "serialNumber",
          "serviceContactInfo",
          "serviceContractExpiration",
          "warrantyExpiration"
        ])
      );
      newEquipment.hasContents = parsedEquipmentObj.shownInPlacementDialogs;
      newEquipment.locationId = locationId;

      const [{ id: equipmentId }] = await safeUpsert(
        "equipmentItem",
        newEquipment
      );
      if (parsedEquipmentObj.extendedProperties) {
        const fields = [];
        // easier to just mock how we do csv's so that we can reuse all that logic
        const row = {};
        parsedEquipmentObj.extendedProperties.forEach(prop => {
          const fieldName = `ext-equipmentItem-${prop.name}`;
          fields.push(fieldName);
          row[fieldName] = prop.value;
        });
        try {
          const { getCsvRowExtProps, createUploadProperties } =
            await getBoundExtendedPropertyUploadHelpers(fields, ctx);
          getCsvRowExtProps({
            row,
            recordId: equipmentId,
            modelTypeCode: "EQUIPMENT_ITEM"
          });
          await createUploadProperties();
        } catch (error) {
          console.error(`error:`, error);
          // bail early
          try {
            await safeDelete("equipmentItem", equipmentId);
            await refetch();
          } catch (error) {
            console.error(`error:`, error);
          }
          throw error;
        }
      }
      equipmentIds.push(equipmentId);

      const createContainersForEquipment = async (
        containers,
        path = "",
        equipmentId,
        equipmentPositionId
      ) => {
        for (const container of containers) {
          const newContainer = await createItemFromObject(container, false, {
            path,
            equipmentId,
            equipmentPositionId
          });
          let containerId;
          if (container.positions || container.containers) {
            const [createdContainer] = await safeUpsert(
              "container",
              newContainer
            );
            containerId = createdContainer.id;
            containerIds.push(containerId);
          } else {
            containersToCreate.push(newContainer);
          }
          const positions = container.positions || container.containerPositions;
          if (positions) {
            let index = 0;
            for (const containerPosition of positions) {
              const newContainerPosition = {
                name: containerPosition.name,
                label: containerPosition.label,
                index: index++,
                containerId
              };
              if (containerPosition.containers) {
                throw new Error(
                  "Containers can not be nested inside container positions."
                );
              }
              containerPositionsToCreate.push(newContainerPosition);
            }
          }
          if (container.containers) {
            const pathForContainer = path + `/${containerId}`;
            await createContainersForEquipment(
              container.containers,
              pathForContainer,
              equipmentId,
              equipmentPositionId
            );
          }
        }
      };

      if (parsedEquipmentObj.containers) {
        await createContainersForEquipment(
          parsedEquipmentObj.containers,
          undefined,
          equipmentId
        );
      }
    }
    // await safeUpsert("equipmentPosition", equipmentPositionsToCreate);
    await safeUpsert("containerPosition", containerPositionsToCreate);
    const createdContainers = await safeUpsert("container", containersToCreate);
    const equipmentRecords = equipmentIds.map(id => ({
      id,
      __typename: "equipmentItem"
    }));
    if (generateBarcode) {
      const containerRecords = containerIds
        .map(id => ({ id, __typename: "container" }))
        .concat(createdContainers);

      await addBarcodesToRecords(
        containerRecords.concat(equipmentRecords),
        ctx
      );
    }
    return {
      equipmentItems: equipmentRecords
    };
  } catch (error) {
    try {
      await safeDelete("equipmentItem", equipmentIds);
      await safeDelete("container", containerIds);
      await safeDelete("containerPosition", containerPositionIds);
    } catch (error) {
      console.error("error:", error);
      window?.toastr?.error("Error resetting partially uploaded data.");
    }
    throw error;
  } finally {
    clearToast && clearToast();
  }
}
