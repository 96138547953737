/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import MoveItems, { plateFragment } from "./MoveItems";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class MoveTool extends React.Component {
  onSubmit = async values => {
    const { containerArrays } = values;
    try {
      const { dataTableId } = await new Promise((resolve, reject) => {
        const placementCb = (options = {}) => {
          const { success, dataTableId } = options;
          if (!success) {
            reject("user cancelled");
          } else {
            resolve({ dataTableId });
          }
        };

        showDialog({
          modalType: "ASSIGN_PLATE_PLACEMENT_STRATEGY",
          onClose: () => placementCb({ success: false }),
          modalProps: {
            placementCb,
            plateIds: containerArrays.map(p => p.id),
            containerArrayTypes: containerArrays.map(p => p.containerArrayType)
          }
        });
      });
      await safeUpsert(
        ["containerArray", "id updatedAt"],
        containerArrays.map(p => ({ id: p.id, updatedAt: new Date() }))
      );
      return {
        containerArrays,
        dataTable: dataTableId && { id: dataTableId, __typename: "dataTable" }
      };
    } catch (error) {
      console.error("error:", error);
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Move Items",
        Component: MoveItems
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(withWorkflowInputs(plateFragment))(MoveTool);
