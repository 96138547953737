/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { compose } from "recompose";

import containerArrayNormalizationFragment from "../../../../graphql/fragments/containerArrayNormalizationFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { withSelectedEntities, withSelectTableRecords } from "@teselagen/ui";

import PlatePreview from "./PlatePreview";

export const selectPlatesTableFormName = "normalizationSelectPlatesTable";

class SelectPlates extends Component {
  componentDidUpdate() {
    const {
      containerArrays: newPlates = [],
      selectTableRecords,
      stepFormProps: { change }
    } = this.props;
    const containerArray = this.getSelectedContainerArray();
    if (
      (newPlates.length && !containerArray) ||
      (newPlates.length &&
        containerArray &&
        !newPlates.some(p => p.id === containerArray.id))
    ) {
      const plate = newPlates[0];
      selectTableRecords(plate);
      change("containerArrayToNormalize", plate);
    } else if (!newPlates.length && containerArray) {
      selectTableRecords([]);
      change("containerArrayToNormalize", null);
    }
  }
  getSelectedContainerArray = () => {
    return (
      this.props.containerArrayToNormalize ||
      this.props.selectedContainerArrays[0]
    );
  };

  renderContainerArray() {
    const containerArray = this.getSelectedContainerArray();

    return <PlatePreview containerArray={containerArray} />;
  }

  renderContainerArrayName = (v, record) => {
    const { worklistMap = {} } = this.props;
    return v + (worklistMap[record.id] ? " (Normalized)" : "");
  };

  previewContainerArraySchema = [
    {
      path: "name",
      render: this.renderContainerArrayName
    }
  ];

  saveContainerArrayToNormalize = () => {
    const {
      nextStep,
      stepFormProps: { change }
    } = this.props;
    const containerArray = this.getSelectedContainerArray();
    change("containerArrayToNormalize", containerArray);
    nextStep();
  };

  onSingleRowSelect = plate => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("containerArrayToNormalize", plate || null);
  };

  render() {
    const containerArray = this.getSelectedContainerArray();

    const {
      containerArrays,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      worklistMap = {},
      Footer,
      footerProps,
      handleSubmit
    } = this.props;
    const disabled = !containerArray || worklistMap[containerArray.id];
    return (
      <React.Fragment>
        <div className="tg-step-form-section tg-flex column">
          <HeaderWithHelper
            header="Plates or Racks to Normalize"
            width="100%"
            helper="Select a plate or rack that you would like to normalize. This tool will rehydrate dry plates and racks."
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              isMultiSelect: true,
              fragment: [
                "containerArray",
                "id name barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: containerArrayNormalizationFragment,
              onClear: this.onSingleRowSelect,
              postSelectDTProps: {
                formName: selectPlatesTableFormName,
                isViewable: !containerArrays
                  ? true
                  : !(containerArrays.length <= 1),
                isSingleSelect: true,
                onDeselect: this.onSingleRowSelect,
                onSingleRowSelect: this.onSingleRowSelect,
                schema: this.previewContainerArraySchema
              },
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              }
            }}
          />
          {containerArrays && !containerArray && (
            <h6 style={{ margin: 15 }}>
              Select a plate to begin normalization
            </h6>
          )}
          {containerArray && this.renderContainerArray()}
        </div>
        {!disabled && (
          <h4 style={{ textAlign: "right" }}>
            {" "}
            Ready to Normalize: {containerArray ? containerArray.name : ""}{" "}
          </h4>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              className="normalization-button"
              intent={Intent.PRIMARY}
              disabled={disabled}
              onClick={handleSubmit(this.saveContainerArrayToNormalize)}
            >
              Next
            </Button>
          }
        ></Footer>
      </React.Fragment>
    );
  }
}

export default compose(
  withSelectedEntities({
    formName: selectPlatesTableFormName,
    name: "selectedContainerArrays"
  }),
  withSelectTableRecords(selectPlatesTableFormName),
  stepFormValues("containerArrays", "worklistMap", "containerArrayToNormalize")
)(SelectPlates);
