/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { Loading } from "@teselagen/ui";
import { getFormInputsUi } from "../../../../../src-shared/ExternalIntegrations/getFormInputsUi";

class CustomOptions extends Component {
  state = {};

  componentDidMount() {
    this.loadOptionsEndpoint();
  }

  getOptionsEndpoint() {
    const { idtIntegration } = this.props;
    const optionsEndpoint = idtIntegration.integrationEndpoints.find(
      e => e.endpointTypeCode === "VENDOR__OPTIONS"
    );
    return optionsEndpoint;
  }

  async loadOptionsEndpoint() {
    const {
      idtIntegration,
      productType,
      stepFormProps: { change },
      lastLoadedOptions
    } = this.props;

    if (idtIntegration && productType) {
      const loadOptionKey = idtIntegration.id + productType;
      const alreadyLoaded = lastLoadedOptions.current === loadOptionKey;
      if (!alreadyLoaded) {
        lastLoadedOptions.current = loadOptionKey;
        this.setState({
          loadingOptions: true
        });

        try {
          change("optionFields", []);
          const optionsEndpoint = this.getOptionsEndpoint();
          if (optionsEndpoint) {
            const { data } = await window.triggerIntegrationRequest({
              endpointId: optionsEndpoint.id,
              method: "GET",
              params: {
                productType
              }
            });

            if (lastLoadedOptions.current === loadOptionKey) {
              if (data.formInputs) {
                // set form inputs
                change("optionFields", data.formInputs);
              }
            }
          }
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error loading vendor options");
        }

        this.setState({
          loadingOptions: false
        });
      }
    }
  }

  renderIDTOptions() {
    const { productType, toolSchema, optionFields = [] } = this.props;
    const { loadingOptions } = this.state;
    if (productType && (loadingOptions || optionFields)) {
      const optionsEndpoint = this.getOptionsEndpoint();
      return (
        <React.Fragment>
          {loadingOptions ? (
            <Loading bounce />
          ) : (
            <React.Fragment>
              <HeaderWithHelper
                header="Custom Options"
                helper="Fill out custom IDT options from endpoint. These will be sent along with the requests to IDT."
              />
              {optionFields.length ? (
                <div style={{ maxWidth: 250 }}>
                  {getFormInputsUi(optionFields, {
                    endpointId: optionsEndpoint.id,
                    formName: toolSchema.code,
                    prefix: "customOptions.",
                    extraParams: {
                      productType
                    }
                  })}
                </div>
              ) : (
                "No custom options."
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  }

  render() {
    const { loadingOptions } = this.state;
    const { Footer, footerProps } = this.props;

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          {this.renderIDTOptions()}
        </div>
        <Footer {...footerProps} nextDisabled={loadingOptions} />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "idtIntegration",
  "productType",
  "optionFields"
)(CustomOptions);
