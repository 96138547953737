/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { sortBy } from "lodash";
import { forEach, keyBy } from "lodash";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import {
  cleanCommaSeparatedCell,
  removeExt
} from "../../../tg-iso-shared/src/utils/fileUtils";

export const createReactionMapMaterialFragment = gql`
  fragment createReactionMapMaterialFragment on material {
    id
    name
    materialTypeCode
  }
`;
export const createReactionMapReagentFragment = gql`
  fragment createReactionMapReagentFragment on additiveMaterial {
    id
    name
    additiveTypeCode
  }
`;

export async function parseReactionMapCsvData(
  csvs,
  { forUpsert } = {},
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const materialNames = [];
  const reagentNames = [];
  const materialNameToRowIndexMap = {};
  const reagentNameToRowIndexMap = {};
  const reactionMapsObject = {};
  const errors = [];
  csvs.forEach(file => {
    const { data, name: filename } = file;
    if (data.length < 1) {
      throw new Error(
        `Uploaded files are empty. Please select another file, or download the file template and populate with data.`
      );
    }
    data.forEach((row, index) => {
      const {
        REACTION_NAME: reactionName,
        INPUT_MATERIALS: inputMaterials = "",
        INPUT_REAGENTS: inputReagents = "",
        INPUT_REAGENTS_CONSERVED: inputConservedReagents = "",
        OUTPUT_MATERIALS: outputMaterials = "",
        OUTPUT_REAGENTS: outputReagents = ""
      } = row;

      if (!inputMaterials && !inputReagents && !inputConservedReagents) {
        return errors.push(
          `File ${filename}: Row ${index + 1} did not specify any inputs.`
        );
      }
      if (!outputMaterials && !outputReagents && !inputConservedReagents) {
        return errors.push(
          `File ${filename}: Row ${index + 1} did not specify any outputs.`
        );
      }
      const rowInputMaterialNames = cleanCommaSeparatedCell(inputMaterials);
      const rowInputReagentNames = cleanCommaSeparatedCell(inputReagents);
      const rowInputReagentConservedNames = cleanCommaSeparatedCell(
        inputConservedReagents
      );
      const rowOutputMaterialNames = cleanCommaSeparatedCell(outputMaterials);
      const rowOutputReagentNames = cleanCommaSeparatedCell(outputReagents);
      const allInputReagentNames = rowInputReagentNames.concat(
        rowInputReagentConservedNames
      );

      rowInputMaterialNames
        .concat(rowOutputMaterialNames)
        .forEach(materialName => {
          if (!materialNames.includes(materialName)) {
            materialNameToRowIndexMap[materialName] = { index, filename };
            materialNames.push(materialName);
          }
        });
      allInputReagentNames
        .concat(rowOutputReagentNames)
        .forEach(reagentName => {
          if (!reagentNames.includes(reagentName)) {
            reagentNameToRowIndexMap[reagentName] = { filename };
            reagentNames.push(reagentName);
          }
        });

      if (!reactionMapsObject[filename]) {
        reactionMapsObject[filename] = {};
      }
      reactionMapsObject[filename][reactionName] = {
        inputMaterials: rowInputMaterialNames,
        inputReagents: rowInputReagentNames,
        inputConservedReagents: rowInputReagentConservedNames,
        outputMaterials: rowOutputMaterialNames,
        outputReagents: rowOutputReagentNames
      };
    });
  });

  if (errors.length) {
    return {
      error: errors.join("\n")
    };
  }

  const existingMaterials = await safeQuery(
    ["material", "id name materialTypeCode"],
    {
      variables: {
        filter: { name: materialNames }
      }
    }
  );
  const existingReagents = await safeQuery(createReactionMapReagentFragment, {
    variables: {
      filter: caseInsensitiveFilter("additiveMaterial", "name", reagentNames)
    }
  });
  const keyedExistingMaterials = keyBy(existingMaterials, material => {
    return material.name.toLowerCase();
  });
  const keyedExistingReagents = keyBy(existingReagents, reagent => {
    return reagent.name.toLowerCase();
  });
  materialNames.forEach(name => {
    if (!keyedExistingMaterials[name.toLowerCase()]) {
      const { index, filename } = materialNameToRowIndexMap[name];
      errors.push(
        `File ${filename}: Row ${
          index + 1
        } references material ${name} which was not found in our system.\n`
      );
    }
  });
  reagentNames.forEach(name => {
    if (!keyedExistingReagents[name.toLowerCase()]) {
      const { index, filename } = reagentNameToRowIndexMap[name];
      errors.push(
        `File ${filename}: Row ${
          index + 1
        } references reagent ${name} which was not found in our system.\n`
      );
    }
  });

  if (errors.length) {
    return {
      error: errors.join("\n")
    };
  }

  const getKeyedMat = name => keyedExistingMaterials[name.toLowerCase()];
  const getKeyedReagent = name => keyedExistingReagents[name.toLowerCase()];

  const reactionMaps = [];
  forEach(reactionMapsObject, (reactionMaterialMap, filename) => {
    const reactions = [];
    forEach(reactionMaterialMap, (reactionInfo, reactionName) => {
      if (forUpsert) {
        reactions.push({
          name: reactionName,
          reactionInputs: reactionInfo.inputMaterials
            .map(im => {
              return {
                inputMaterialId: getKeyedMat(im).id
              };
            })
            .concat(
              reactionInfo.inputReagents.map(ir => {
                return {
                  inputAdditiveMaterialId: getKeyedReagent(ir).id
                };
              })
            )
            .concat(
              reactionInfo.inputConservedReagents.map(icr => {
                return {
                  inputAdditiveMaterialId: getKeyedReagent(icr).id,
                  conserved: true
                };
              })
            ),
          reactionOutputs: reactionInfo.outputMaterials
            .map(om => {
              return {
                outputMaterialId: getKeyedMat(om).id
              };
            })
            .concat(
              reactionInfo.outputReagents.map(or => {
                return {
                  outputAdditiveMaterialId: getKeyedReagent(or).id
                };
              })
            )
        });
      } else {
        reactions.push({
          name: reactionName,
          inputMaterials: reactionInfo.inputMaterials.map(getKeyedMat),
          outputMaterials: reactionInfo.outputMaterials.map(getKeyedMat),
          consumedAdditiveMaterials:
            reactionInfo.inputReagents.map(getKeyedReagent),
          conservedAdditiveMaterials:
            reactionInfo.inputConservedReagents.map(getKeyedReagent),
          outputAdditiveMaterials:
            reactionInfo.outputReagents.map(getKeyedReagent)
        });
      }
    });
    reactionMaps.push({
      name: removeExt(filename),
      reactions
    });
  });
  return { reactionMaps };
}

export function sortReactionMapsByExecutionOrder(reactionMaps) {
  const copied = reactionMaps.map(rm => {
    const { executionOrder } = rm;
    const [major, minor] = executionOrder.split(".").map(Number);

    return {
      ...rm,
      major,
      minor
    };
  });
  return sortBy(copied, ["major", "minor"]);
}

export const reactionInputRoleTypeMap = {
  FORWARD_PRIMER: "FORWARD_PRIMER",
  REVERSE_PRIMER: "REVERSE_PRIMER",
  PRIMARY_TEMPLATE: "PRIMARY_TEMPLATE"
};

export const reactionInputSourceTypeMap = {
  UNSPECIFIED: "UNSPECIFIED",
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL"
};
