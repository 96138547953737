/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { splitPlasmidInsertAndBackbone } from "./plasmidUtils";
import { get, pick } from "lodash";

export const getVendorParams = name => {
  if (name === "TWIST") {
    return {
      vendorTitle: "Twist Bioscience",
      vendorCode: "TWIST",
      dataTableTypeCode: "TWIST_PLATE_ORDER_FORM",
      plasmidDataTableTypeCode: "TWIST_PLASMID_ORDER_FORM"
    };
  } else if (name === "IDT") {
    return {
      vendorTitle: "Integrated DNA Technologies",
      vendorCode: "IDT"
    };
  }
};

export const formatTwistSequencesForExporting = sequences => {
  return sequences.map(sequence => {
    const auxSequence = pick(sequence, "name", "sequence");
    if (get(sequence, "sequenceTypeCode") === "PLASMID") {
      return Object.assign(
        auxSequence,
        { constructId: sequence.id },
        ...splitPlasmidInsertAndBackbone(sequence.queriedMaterial)
      );
    }
    return auxSequence;
  });
};
