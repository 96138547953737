/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import pluralize from "pluralize";
import { isoContext } from "@teselagen/utils";

/**
 * Given a list of records which may contain existing records this function will create join table entries
 * separately
 * @param {*} param0
 */
export default async function updateJoinsAndRecreateRelated(
  { records, joinModels = [], relatedModels = [] },
  ctx = isoContext
) {
  const { deleteWithQuery, safeUpsert } = ctx;
  const alreadyExistingRecordIds = [];
  const newJoins = {};
  const newRelated = {};
  records.forEach(r => {
    if (r.id) {
      // if the record already exists then we need to create the join tables separately
      alreadyExistingRecordIds.push(r.id);
      // go through all the join tables and pull them off of the record
      // put them into a new array so that they can all be created in a single upsert
      joinModels.forEach(({ jm, fk, key }) => {
        newJoins[jm] = newJoins[jm] || [];
        const recordKey = key || pluralize(jm);
        const joinsForRecord = r[recordKey] || [];
        newJoins[jm] = newJoins[jm].concat(
          joinsForRecord.map(join => {
            join[fk] = r.id;
            return join;
          })
        );
        delete r[recordKey];
      });

      relatedModels.forEach(({ rm, fk, key }) => {
        const recordKey = key || rm;
        if (r[recordKey]) {
          newRelated[rm] = newRelated[rm] || [];
          // add a cid
          r[recordKey].cid = r[recordKey].cid || shortid();
          newRelated[rm].push(r[recordKey]);
          // link record to the new related model that will be created
          r[fk] = `&${r[recordKey].cid}`;
          delete r[recordKey];
        }
      });
    }
  });
  if (alreadyExistingRecordIds.length) {
    for (const { jm, fk } of joinModels) {
      await deleteWithQuery(jm, {
        [fk]: alreadyExistingRecordIds
      });
    }
    for (const { rm, filterKey } of relatedModels) {
      await deleteWithQuery(rm, {
        [filterKey]: alreadyExistingRecordIds
      });
    }
  }
  for (const joinModel of Object.keys(newJoins)) {
    await safeUpsert(joinModel, newJoins[joinModel] || [], {
      excludeResults: true
    });
  }
  for (const relatedModel of Object.keys(newRelated)) {
    await safeUpsert(relatedModel, newRelated[relatedModel] || [], {
      excludeResults: true
    });
  }
}
