/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const schematicSetFragment = gql`
  fragment schematicSetFragment on schematicSet {
    id
    name
    type
    color
    index
    schematicElements {
      id
      name
    }
  }
`;

export default gql`
  fragment schematicFragment on schematic {
    id
    name
    lab {
      id
      name
    }
    description
    backboneSet {
      ...schematicSetFragment
    }
    schematicSets {
      ...schematicSetFragment
    }
  }
  ${schematicSetFragment}
`;
