/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable graphql/template-strings */

import gql from "graphql-tag";

const extendedValuesFragment = `
  extendedValues {
    id
    value
    extendedPropertyId
  }
  extendedCategoryValues {
    id
    extendedCategoryId
    extendedPropertyId
  }
  extendedMeasurementValues {
    id
    value
    measurementUnitId
    extendedPropertyId
  }
`;

export const concatenateWorklistFragment = gql`
  fragment concatenateWorklistFragment on worklist {
    id
    name
    worklistReactionMaps {
      id
      reactionMapId
    }
    worklistContainerArrays {
      id
      containerArrayId
      worklistExtendedProperties {
        id
        extendedPropertyToRemoveId
      }
      ${extendedValuesFragment}
    }
    worklistTransfers {
      id
      volume
      volumetricUnitCode
      sourceAliquotContainerId
      destinationAliquotContainerId
      samplePoolId
      worklistTransferAliquotContainers {
        id
        ${extendedValuesFragment}
        aliquotContainerId
        isTargetAliquot
        transferExtendedProperties {
          id
          extendedPropertyToRemoveId
        }
      }
    }
  }
`;
