/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";

import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import dataSetPartialFragment from "../../../graphql/fragments/dataSetPartialFragment";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";

const schema = {
  model: "dataSet",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "type",
      type: "string",
      displayName: "Type"
    },
    {
      path: "status",
      type: "string",
      displayName: "Status"
    }
  ]
};

class DataTablesInventory extends Component {
  render() {
    return (
      <AbstractLibrary
        {...this.props}
        isLibraryTable
        withExport
        noNewItem
        model="dataSet"
        libraryName="data-set"
        onDoubleClick={routeDoubleClick}
      />
    );
  }
}

export default libraryEnhancer({
  fragment: dataSetPartialFragment,
  schema
})(DataTablesInventory);
