/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash-es";

export default fragment => {
  if (Array.isArray(fragment)) {
    console.error("No fragment name for passed autogenerated fragment");
  } else {
    return get(fragment, "definitions[0].name.value");
  }
};
