/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const reactionMapRecordReactionFragment = gql`
  fragment reactionMapRecordReactionFragment on reaction {
    id
    name
    executedAt
    reactionDetails
    reactionOutputs {
      id
      outputMaterial {
        id
        name
        materialTypeCode
      }
      outputAdditiveMaterial {
        id
        name
      }
    }
    reactionInputs {
      id
      reactionInputSourceTypeCode
      reactionRoleTypeCode
      conserved
      inputMaterial {
        id
        name
        materialTypeCode
      }
      inputAdditiveMaterial {
        id
        name
      }
    }
  }
`;
export default gql`
  fragment reactionMapRecordFragment on reactionMap {
    id
    name
    reactionDesign {
      id
      name
    }
    reactionType {
      code
      name
      description
    }
    dataSet {
      id
      name
    }
    reactionMapSubsetJoins {
      id
      childReactionMap {
        id
        name
      }
    }
    reactionMapWorkflowView {
      id
      workflowIds
      workflowNames
    }
    reactionMapSubsetReactionMapSubsetJoins {
      id
      parentReactionMap {
        id
        name
      }
    }
  }
`;
