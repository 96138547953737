/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export default async ({ mutations, designValuesOverride = {} }) => {
  const { data } = await window.serverApi.request({
    method: "POST",
    url: "/createDesignFromOldStyleMutation",
    data: {
      mutations,
      designValuesOverride
    }
  });

  const duplicateInfo = data.duplicateInfo || null;
  const designId = data.designIds
    ? Array.isArray(data.designIds)
      ? data.designIds[0]
      : data.designIds
    : null;
  const results = data.results || null;
  const topologicalDesign = data.topologicalDesign || null;
  const response = {
    duplicateInfo,
    designId,
    results,
    topologicalDesign
  };

  return response;
};
