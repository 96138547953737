/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import _getNameKeyedItems from "../../utils/getNameKeyedItems";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import {
  handleNestedRecords,
  handleNewLinkagesOnUpdateRecords,
  removeJoins,
  upsertAddIds
} from "./utils";
import { uniq } from "lodash";

const USER_REQUEST = async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;
  const usernames = [];

  const requestTypes = [];

  const keyedStatuses = keyBy(
    await safeQuery(["customerRequestStatusType", "code name"]),
    status => status.name.toLowerCase()
  );

  const grabNames = r => {
    if (r.primaryCustomer) {
      usernames.push(r.primaryCustomer);
    }
    if (r.customers?.length) {
      usernames.push(...r.customers);
    }
    if (r.requestType) {
      requestTypes.push(r.requestType);
    }
  };

  recordsToImport.forEach(r => {
    grabNames(r);
    if (r.__oldRecord) {
      grabNames(r.__oldRecord);
    }
  });

  await removeJoins(
    {
      recordsToImport,
      model: "customerRequest",
      nested: "sequence",
      joinName: "customerRequestSequence"
    },
    ctx
  );

  await removeJoins(
    {
      recordsToImport,
      model: "customerRequest",
      nested: "strain",
      joinName: "customerRequestStrain"
    },
    ctx
  );

  let recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "sequences",
    async sequences => {
      await upsertHandlers.DNA_SEQUENCE(
        {
          ...rest,
          model: "sequence",
          recordsToImport: sequences,
          upsertHandlers
        },
        ctx
      );
    }
  );
  recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "strains",
    async strains => {
      await upsertHandlers.MICROBIAL_STRAIN(
        {
          ...rest,
          model: "strain",
          recordsToImport: strains,
          upsertHandlers
        },
        ctx
      );
    }
  );

  const toUpdateStrains = handleNewLinkagesOnUpdateRecords(
    recordsToContinueUpserting,
    "strains"
  );
  const toUpdateSequences = handleNewLinkagesOnUpdateRecords(
    recordsToContinueUpserting,
    "sequences"
  );
  await safeUpsert(
    "customerRequestStrain",
    toUpdateStrains.map(([customerRequestId, strainId]) => ({
      strainId,
      customerRequestId
    }))
  );
  await safeUpsert(
    "customerRequestSequence",
    toUpdateSequences.map(([customerRequestId, sequenceId]) => ({
      sequenceId,
      customerRequestId
    }))
  );

  const getNameKeyedItems = async props => _getNameKeyedItems(props, ctx);

  const keyedUsers = await _getNameKeyedItems(
    {
      names: uniq(usernames),
      model: "user",
      fragment: ["user", "id username"],
      key: "username"
    },
    ctx
  );
  const keyedRequestTypes = await getNameKeyedItems({
    names: uniq(requestTypes),
    model: "requestType",
    fragment: ["requestType", "id name"],
    key: "name"
  });

  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      precheckFn: r => {
        if (r.customers?.length) {
          const missing = r.customers.filter(c => !keyedUsers[c.toLowerCase()]);
          if (missing.length) {
            return `Could not find these users: ${missing.join(", ")}`;
          }
        }
        if (r.primaryCustomer && !keyedUsers[r.primaryCustomer.toLowerCase()]) {
          return `Could not find primary customer ${r.primaryCustomer}`;
        }
        if (r.requestType && !keyedRequestTypes[r.requestType.toLowerCase()]) {
          return `Could not find request type ${r.requestType}`;
        }
        if (r.status && !keyedStatuses[r.status.toLowerCase()]) {
          return `Could not find status ${r.status}`;
        }
      },
      convertUserFacingToDbModel: r => {
        if (r.customers) {
          r.customers = r.customers.map(c => ({
            userId: keyedUsers[c.toLowerCase()].id
          }));
        }
        if (r.primaryCustomer) {
          r.primaryCustomer = {
            userId: keyedUsers[r.primaryCustomer.toLowerCase()].id
          };
        }
        if (r.requestType) {
          r.requestTypeId = keyedRequestTypes[r.requestType.toLowerCase()].id;
        }
        delete r.requestType;
        if (r.feedback) {
          r.outputDescription = r.feedback;
        }
        delete r.feedback;
        if (r.status) {
          r.customerRequestStatusTypeCode =
            keyedStatuses[r.status.toLowerCase()].code;
        }
        delete r.status;
        if (r.dueDate) {
          const date = new Date(r.dueDate);
          if (isNaN(date.getTime())) {
            throw new Error(`Invalid date: ${r.dueDate}`);
          }
          r.dueDate = date;
        }

        r.customerRequestSequences = r.sequences?.map(({ id }) => ({
          sequenceId: id
        }));
        r.customerRequestStrains = r.strains?.map(({ id }) => ({
          strainId: id
        }));
        delete r.sequences;
        delete r.strains;
        return r;
      },
      model: "customerRequest"
    },
    ctx
  );

  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport: recordsToContinueUpserting,
      modelOrFragment: "customerRequest"
    },
    ctx
  );
};

export default USER_REQUEST;
