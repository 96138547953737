/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const JS_WORKER_ERROR_PREFIX = "[Error] - ";
function parseErrorToJson(errorString) {
  const errorJsonAsString = errorString.split(JS_WORKER_ERROR_PREFIX)[1];

  try {
    const errorJson = JSON.parse(errorJsonAsString);

    if (errorJson && errorJson.error_code && errorJson.message)
      return errorJson;
    else
      throw new Error(
        "Error json is missing or lacks 'error_code' or 'message' fields."
      );
  } catch (error) {
    console.error(
      "[ParseErrorFunction]",
      `There was an error parsing the error message: ${errorString}.\n The error was: ${error}`
      // `There was an error parsing the error string to JSON. The string was ${errorString}.\n The error was: ${error}` // showing the errorobject in the console can be too revealing.
    );
    throw error;
  }
}

export { parseErrorToJson };
