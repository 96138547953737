/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { forEach } from "lodash";
import { compose } from "recompose";
import { connect } from "react-redux";
import { initialize } from "redux-form";
import { withSelectedEntities } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import StepForm from "../../../../src-shared/StepForm";
import extendedPropertyFragment from "../../../../src-shared/fragments/extendedPropertyFragment";
import { validateMaterialPlates } from "../../../utils/plateUtils";

import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import SelectPlates, {
  containerArrayFragmentWithExtendedProperties
} from "./SelectPlates";
import UpdateExtendedProperties from "./UpdateExtendedProperties";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";

import { addAndRemoveExtendedProperties } from "../../../../src-shared/utils/extendedPropertyUtils";

class LysisTool extends Component {
  onSubmit = async values => {
    const {
      aliquotExtendedPropertiesToRemoveSelectedEntities = [],
      plateExtendedPropertiesToRemoveSelectedEntities = []
    } = this.props;
    const platePropIdsToRemove = plateExtendedPropertiesToRemoveSelectedEntities.map(
      p => p.id
    );
    const aliquotPropIdsToRemove = aliquotExtendedPropertiesToRemoveSelectedEntities.map(
      p => p.id
    );
    const {
      containerArrays = [],
      aliquotExtendedValues = [],
      containerArrayExtendedValues = []
    } = values;

    const extendedValuesToDelete = {
      extendedValue: [],
      extendedCategoryValue: [],
      extendedMeasurementValue: []
    };

    const extendedValuesToCreate = {
      extendedValue: [],
      extendedCategoryValue: [],
      extendedMeasurementValue: []
    };

    containerArrays.forEach(containerArray => {
      addAndRemoveExtendedProperties(containerArray, {
        extendedValues: containerArrayExtendedValues,
        extendedValuesToDelete,
        extendedValuesToCreate,
        propIdsToRemove: platePropIdsToRemove
      });
      containerArray.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          addAndRemoveExtendedProperties(ac.aliquot, {
            extendedValues: aliquotExtendedValues,
            extendedValuesToDelete,
            extendedValuesToCreate,
            propIdsToRemove: aliquotPropIdsToRemove
          });
        }
      });
    });

    try {
      forEach(extendedValuesToDelete, async (items, model) => {
        await safeDelete(model, items);
      });
      forEach(extendedValuesToCreate, async (items, model) => {
        await safeUpsert(model, items);
      });
      // to update cache
      await safeQuery(containerArrayFragmentWithExtendedProperties, {
        variables: {
          filter: {
            id: containerArrays.map(c => c.id)
          }
        }
      });
      return {
        containerArrays
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating new properties");
    }
  };

  validate = values => {
    const errors = {};
    if (values.containerArrays && values.containerArrays.length) {
      errors.containerArrays = validateMaterialPlates(values.containerArrays, {
        materialTypeCode: "MICROBIAL"
      });
    }

    return errors;
  };

  reinitialize = () => {
    const { reinitialize, initialValues, toolSchema } = this.props;
    reinitialize(toolSchema.code, initialValues);
  };

  render() {
    const { toolIntegrationProps, toolSchema, initialValues } = this.props;

    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        withCustomFooter: true
      },
      {
        title: "Update Extended Properties",
        Component: UpdateExtendedProperties
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={true}
        afterReset={this.reinitialize}
        toolSchema={toolSchema}
        initialValues={initialValues}
        steps={steps}
        onSubmit={this.onSubmit}
        validate={this.validate}
      />
    );
  }
}

export default compose(
  connect(null, {
    reinitialize: initialize
  }),
  withWorkflowInputs(containerArrayFragmentWithExtendedProperties),
  withQuery(extendedPropertyFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        filter: {
          name: "Lysate (Raw)"
        }
      }
    },
    props: props => {
      const containerArrayExtendedValues = [];
      const aliquotExtendedValues = [];
      if (props.extendedProperties) {
        const containerArrayProperty = props.extendedProperties.find(
          prop => prop.modelTypeCode === "CONTAINER_ARRAY"
        );
        const aliquotProperty = props.extendedProperties.find(
          prop => prop.modelTypeCode === "ALIQUOT"
        );

        if (containerArrayProperty) {
          containerArrayExtendedValues.push({
            value: true,
            extendedProperty: containerArrayProperty,
            __typename: "extendedValue"
          });
        }
        if (aliquotProperty) {
          aliquotExtendedValues.push({
            value: true,
            extendedProperty: aliquotProperty,
            __typename: "extendedValue"
          });
        }
      }
      const containerArrays = props.ownProps.containerArrays;
      return {
        initialValues: {
          containerArrays,
          containerArrayExtendedValues,
          aliquotExtendedValues
        }
      };
    }
  }),
  withSelectedEntities(
    "aliquotExtendedPropertiesToRemove",
    "plateExtendedPropertiesToRemove"
  )
)(LysisTool);
