/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import gql from "graphql-tag";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import {
  CheckboxField,
  ReactSelectField,
  showConfirmationDialog
} from "@teselagen/ui";
import {
  arrayToItemValuedOptions,
  throwFormError
} from "../../../../../src-shared/utils/formUtils";
import { pluralIfNeeded } from "../../../../utils";
import SelectSequencesFromAssemblyReport from "../../../SelectSequencesFromAssemblyReport";
import { getIdtProductTypeOptions } from "../utils";
import fieldConstants from "../fieldConstants";
import SelectAllScoredSequencesCheckbox from "../../../SequenceScoringComponents/SelectAllScoredSequencesCheckbox";

const idtOrderingDataTableFragment = gql`
  fragment idtOrderingDataTableFragment on dataTable {
    id
    name
    dataTableTypeCode
    dataRows {
      id
      rowValues
      dataRowJ5Items {
        id
        j5Item {
          id
          j5AssemblyPiece {
            id
            sequenceId
          }
        }
      }
    }
  }
`;

const idtOrderingReactionMapFragment = gql`
  fragment idtOrderingReactionMapFragment on reactionMap {
    id
    name
    reactions {
      id
      reactionInputs {
        id
        inputMaterial {
          id
          name
          materialTypeCode
          polynucleotideMaterialSequence {
            id
          }
        }
      }
    }
  }
`;

export const sequenceFragment = [
  "sequence",
  "id size name sequenceTypeCode updatedAt"
];

const productTypeToSequenceType = {
  OLIGO: "OLIGO",
  LINEAR_FRAGMENT: "LINEAR_DNA"
};

class SelectDNA extends Component {
  state = {};

  componentDidMount() {
    const {
      integrations,
      stepFormProps: { change }
    } = this.props;
    if (integrations.length === 1) {
      change("idtIntegration", integrations[0]);
    }
  }

  beforeNextStep = async values => {
    const { productType, idtIntegration } = values;
    const {
      nextStep,
      stepFormProps: { change }
    } = this.props;

    try {
      const contextEndpoint = idtIntegration.integrationEndpoints.find(
        e => e.endpointTypeCode === "VENDOR__CONTEXT"
      );
      if (contextEndpoint) {
        const { data } = await window.triggerIntegrationRequest({
          endpointId: contextEndpoint.id,
          method: "GET"
        });
        change("userContext", data);
      }
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error loading context");
      return;
    }

    let sequencesToScore = [];
    try {
      const {
        dataTables = [],
        sequences = [],
        reactionMaps = [],
        sequencesFromAssemblyReports = []
      } = values;
      const incomingDnaSequenceIds = sequences
        .concat(sequencesFromAssemblyReports)
        .map(s => s.id);

      if (dataTables.length) {
        const fullDataTables = await safeQuery(idtOrderingDataTableFragment, {
          variables: {
            filter: {
              id: dataTables.map(d => d.id)
            }
          }
        });
        for (const dataTable of fullDataTables) {
          if (dataTable.dataTableTypeCode === "SEQUENCE_ORDER") {
            dataTable.dataRows.forEach(dataRow =>
              incomingDnaSequenceIds.push(dataRow.rowValues.sequenceId)
            );
          } else if (dataTable.dataTableTypeCode === "J5_ASSEMBLY_PIECE_LIST") {
            dataTable.dataRows.forEach(dataRow => {
              const sequenceId =
                dataRow.dataRowJ5Items[0].j5Item.j5AssemblyPiece.sequenceId;
              incomingDnaSequenceIds.push(sequenceId);
            });
          }
        }
      }

      if (reactionMaps.length) {
        const fullReactionMaps = await safeQuery(
          idtOrderingReactionMapFragment,
          {
            variables: {
              filter: {
                id: reactionMaps.map(d => d.id)
              }
            }
          }
        );
        fullReactionMaps.forEach(rm => {
          rm.reactions.forEach(r => {
            r.reactionInputs.forEach(ri => {
              const seqId =
                ri.inputMaterial?.polynucleotideMaterialSequence?.id;
              if (seqId) {
                incomingDnaSequenceIds.push(seqId);
              }
            });
          });
        });
      }

      if (incomingDnaSequenceIds.length) {
        sequencesToScore = await safeQuery(
          [
            "sequence",
            /* GraphQL */ `
              {
                id
                name
                size
                sequenceTypeCode
                circular
                sequenceFragments {
                  id
                  fragment
                  index
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                id: incomingDnaSequenceIds
              }
            }
          }
        );
      } else {
        return window.toastr.error("No sequences found.");
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading sequences");
      return;
    }

    const invalidSeqs = [];
    sequencesToScore = sequencesToScore.filter(s => {
      const valid =
        s.sequenceTypeCode === productTypeToSequenceType[productType];
      if (!valid) {
        invalidSeqs.push(s.name);
      }
      return valid;
    });
    if (invalidSeqs.length) {
      const errorMsg = `These sequences do not match selected product type: ${invalidSeqs.join(
        ", "
      )}`;
      if (sequencesToScore.length) {
        // if only some are invalid offer to filter them out
        const continueTool = await showConfirmationDialog({
          text:
            errorMsg +
            `\n\nWould you like to continue with the remaining ${
              sequencesToScore.length
            } ${pluralIfNeeded("sequence", sequencesToScore)}?`,
          confirmButtonText: "Continue"
        });
        if (!continueTool) {
          return;
        }
      } else {
        throwFormError(errorMsg);
      }
    }

    change("sequencesToScore", sequencesToScore);
    nextStep();
  };

  render() {
    const {
      Footer,
      footerProps,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      integrations = [],
      stepFormProps: { change },
      toolSchema,
      handleSubmit,
      idtVendor,
      productType
    } = this.props;
    return (
      <React.Fragment>
        {integrations.length > 1 && (
          <div className="tg-step-form-section column">
            <HeaderWithHelper
              header="Select IDT Integration"
              helper="Select IDT integration for ordering DNA"
            />
            <div style={{ maxWidth: 250 }}>
              <ReactSelectField
                name="idtIntegration"
                label="Integration"
                options={arrayToItemValuedOptions(integrations)}
                isRequired
              />
            </div>
          </div>
        )}
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Product"
            helper="Choose one of IDT's available product types"
          />
          <div style={{ maxWidth: 250 }}>
            <ReactSelectField
              name={fieldConstants.productType}
              label="Product Type"
              options={getIdtProductTypeOptions(idtVendor)}
            />
          </div>
          <SelectAllScoredSequencesCheckbox />
          <CheckboxField
            name={fieldConstants.skipScoring}
            label="Skip Scoring"
            tooltipInfo="Check this if you would to use this tool to generate an order without actually sending sequences to IDT for scoring. This is useful if you have already scored sequences and just want to order them."
          />
        </div>
        {productType && (
          <React.Fragment>
            <div className="tg-step-form-section column">
              <HeaderWithHelper
                header="Select Data Tables"
                helper="Select tables of primary templates or assembly pieces to help choose sequences."
              />
              <GenericSelect
                {...{
                  name: "dataTables",
                  schema: ["name", dateModifiedColumn],
                  buttonProps: {
                    disabled: isDisabledMap.dataTables,
                    loading: isLoadingMap.dataTables
                  },
                  isMultiSelect: true,
                  fragment: ["dataTable", "id name updatedAt"],
                  tableParamOptions: {
                    additionalFilter: {
                      dataTableTypeCode: [
                        "J5_ASSEMBLY_PIECE_LIST",
                        "SEQUENCE_ORDER"
                      ]
                    }
                  },
                  postSelectDTProps: {
                    formName: "selectedDataTables",
                    schema: ["name", dateModifiedColumn]
                  }
                }}
              />
            </div>
            <div className="tg-step-form-section column">
              <HeaderWithHelper
                header="Select Reaction Maps"
                helper="Select reaction maps of materials to help choose sequences."
              />
              <GenericSelect
                {...{
                  name: "reactionMaps",
                  schema: ["name", dateModifiedColumn],
                  buttonProps: {
                    disabled: isDisabledMap.reactionMaps,
                    loading: isLoadingMap.reactionMaps
                  },
                  isMultiSelect: true,
                  fragment: ["reactionMap", "id name updatedAt"],
                  postSelectDTProps: {
                    formName: "selectedReactionMaps",
                    schema: ["name", dateModifiedColumn]
                  }
                }}
              />
            </div>
            <div className="tg-step-form-section column">
              <HeaderWithHelper
                header="Select Sequences"
                helper="Select sequences from inventory."
              />

              <GenericSelect
                {...{
                  name: "sequences",
                  schema: [
                    "name",
                    { displayName: "Length", path: "size", type: "number" },
                    dateModifiedColumn
                  ],
                  buttonProps: {
                    disabled: isDisabledMap.sequences,
                    loading: isLoadingMap.sequences
                  },
                  isMultiSelect: true,
                  fragment: sequenceFragment,
                  tableParamOptions: {
                    additionalFilter: {
                      sequenceTypeCode: productTypeToSequenceType[productType]
                    }
                  },
                  postSelectDTProps: {
                    formName: "selectedSequences",
                    schema: [
                      "name",
                      { displayName: "Length", path: "size" },
                      dateModifiedColumn
                    ]
                  }
                }}
              />
            </div>
            <div className="tg-step-form-section column">
              <SelectSequencesFromAssemblyReport
                toolSchema={toolSchema}
                change={change}
              />
            </div>
          </React.Fragment>
        )}

        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </React.Fragment>
    );
  }
}

export default stepFormValues(
  "idtIntegration",
  "productType",
  "containerType"
)(SelectDNA);
