/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import cn from "classnames";

import {
  getCardHeight,
  getTopMarginForCard,
  getCardWidth
} from "../../../../src-shared/selectors/designViewSelectors";
import {
  doesCardContainInvalidity,
  treeLayout
} from "../../../../../tg-iso-design/selectors/designStateSelectors";

class TreeMinimapCard extends React.PureComponent {
  render() {
    const {
      cardHeight,
      treeLayout,
      cardWidth,
      topMargin,
      invalid
    } = this.props;
    return (
      <div
        className={cn("design-minimap-card", { invalid })}
        style={{
          height: cardHeight,
          width: cardWidth,
          marginRight: treeLayout === "vertical" ? 12 : null,
          marginTop: topMargin,
          background: invalid ? "#f29c9c" : "#fff"
        }}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { cardId } = props;
  return {
    cardHeight: getCardHeight(state, cardId),
    cardWidth: getCardWidth(state, cardId),
    treeLayout: treeLayout(state),
    topMargin: getTopMarginForCard(state, cardId),
    invalid: doesCardContainInvalidity(state, cardId)
  };
};
export default connect(mapStateToProps)(TreeMinimapCard);
