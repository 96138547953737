/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { CheckboxField, NumericInputField, SelectField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";

class UniversalTransfer extends Component {
  render() {
    const { universalTransfers } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column" style={{ minHeight: 200 }}>
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Universal Transfer Volume"
              helper="If you would like to input a universal
              transfer volume for all replication transfers,
              check the box below. Otherwise, specify a
              transfer volume for each input plate."
            />
            <div className="tg-flex column align-flex-end">
              <CheckboxField
                name="universalTransfers"
                label="Apply Universal Transfer Volume"
              />
              <div className="input-with-unit-select">
                <NumericInputField
                  label="Transfer Volume"
                  name="universalTransferVolume"
                  min={0}
                  disabled={!universalTransfers}
                />
                <SelectField
                  name="universalTransferVolumeUnitCode"
                  defaultValue="uL"
                  label="none"
                  className="tg-unit-select"
                  disabled={!universalTransfers}
                  options={unitGlobals.getOptionsForSelect("volumetricUnit")}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(stepFormValues("universalTransfers"))(UniversalTransfer);
