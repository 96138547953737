/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const { addDynamicScript } = require("./addDynamicScript");

const defaultCookieConfig = {
  gdprAppliesGlobally: true,
  whitelabel: true,
  lang: "en",
  siteId: 2258069,
  countryDetection: false,
  consentOnContinuedBrowsing: true,
  perPurposeConsent: true,
  cookiePolicyId: 85768495,
  floatingPreferencesButtonDisplay: "bottom-right",
  banner: {
    brandBackgroundColor: "none",
    brandTextColor: "black",
    acceptButtonDisplay: true,
    customizeButtonDisplay: true,
    acceptButtonColor: "#0f9960",
    acceptButtonCaptionColor: "white",
    customizeButtonColor: "#DADADA",
    customizeButtonCaptionColor: "#4D4D4D",
    rejectButtonDisplay: true,
    rejectButtonColor: "#003554",
    rejectButtonCaptionColor: "white",
    position: "float-bottom-left",
    textColor: "black",
    backgroundColor: "white"
  }
};

if (window.frontEndConfig.iubenda) {
  // console.log("Enabling iubenda..");

  const { cookieConfig = {}, apiKey } = window.frontEndConfig.iubenda;
  const config = {
    ...defaultCookieConfig,
    ...cookieConfig
  };
  config.banner = {
    ...defaultCookieConfig.banner,
    ...cookieConfig.banner
  };
  window._iub = window._iub || {};
  const _iub = window._iub;
  _iub.csConfiguration = config;

  addDynamicScript({ url: "https://cdn.iubenda.com/cs/iubenda_cs.js" });

  _iub.cons_instructions = _iub.cons_instructions || [];
  _iub.cons_instructions.push(["init", { api_key: apiKey }]);

  addDynamicScript({ url: "https://cdn.iubenda.com/cons/iubenda_cons.js" });
}
