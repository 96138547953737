/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment selectMaterialsFromSelectReagentsOrMaterialsDialogFragment on material {
    id
    name
    materialTypeCode
    materialType {
      code
      description
      name
    }
    createdAt
    updatedAt
  }
`;
