/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
// import { DataTable } from "@teselagen/ui";
import { Button, Intent, Icon } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { map, camelCase } from "lodash";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import classNames from "classnames";
import { openInNewTab } from "../../../../src-shared/utils/generalUtils";

const generateLink = (
  itemOrItems,
  { label, inventoryItemTypeCode, dataItemTypeCode, isUpdate },
  key
) => {
  const modelName = camelCase(dataItemTypeCode || inventoryItemTypeCode);
  const plural = Array.isArray(itemOrItems);
  const itemIds = plural ? itemOrItems.map(({ id }) => id) : [itemOrItems.id];
  const labelToUse = `${isUpdate ? "Updated" : "Created"} ${
    itemIds.length
  } ${label}`;

  let linkToCheckifLinkable = modelNameToLink(modelName);
  if (!plural) linkToCheckifLinkable += "/:id";
  // TODO: cant do this because then a circular dependency problem occures
  // if (!privateRoutes[linkToCheckifLinkable]) {
  // return label;
  // }
  let link;
  if (plural) {
    link =
      linkToCheckifLinkable +
      (itemIds.length === 1
        ? `/${itemIds[0]}`
        : "?filters=id__inList__" + itemIds.join(";"));
  } else {
    link = linkToCheckifLinkable.replace(":id", itemOrItems.id);
  }

  return (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    <a
      key={key}
      onClick={() => openInNewTab(link)}
      style={{ fontSize: 20, marginBottom: 15 }}
    >
      {labelToUse}
    </a>
    /* eslint-enable jsx-a11y/anchor-is-valid */
  );
};

class SuccessPage extends Component {
  resetTool = () => {
    this.props.resetTool && this.props.resetTool();
  };

  returnToLibrary = () => {
    const { history } = this.props;
    history.push(history.location.pathname.replace("/tools/", "/tools#"));
  };

  renderContent() {
    const {
      toolSchema: { output },
      submittedData
    } = this.props;
    return map(output.ioItems, (item, key) => {
      const itemOrArrayOfItems = submittedData[key];
      if (itemOrArrayOfItems) {
        if (Array.isArray(itemOrArrayOfItems) && !itemOrArrayOfItems.length)
          return;
        return generateLink(itemOrArrayOfItems, item, key);
      }
    });
  }

  render() {
    const {
      toolSchema: { name },
      innerContentOverride,
      finishButton
    } = this.props;
    return (
      <div
        className={classNames(
          "tg-flex",
          "column",
          "align-center",
          `evolve-stepform-successContent`
        )}
      >
        <Icon
          className="evolve-stepform-successIcon"
          icon="updated"
          iconSize={85}
          intent={Intent.PRIMARY}
        />
        <h2>
          {name.charAt(0).toUpperCase() + name.slice(1)} successfully submitted
          for training.
        </h2>
        {!innerContentOverride && this.renderContent()}
        <div className="tg-flex" style={{ marginTop: 20 }}>
          {finishButton}
          <div className="tg-flex-separator" />
          <Button
            intent={Intent.PRIMARY}
            minimal
            text="Submit new model"
            onClick={this.resetTool}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(SuccessPage);
