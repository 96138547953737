/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, get, forEach, sum, isEmpty } from "lodash";
import {
  convertVolume,
  standardizeVolume
} from "../../src-shared/utils/unitUtils";
import { getPathOfAssignedPosition } from "./positions";
import { safeQuery } from "../../src-shared/apolloMethods";
import { download } from "../../src-shared/utils/downloadTest";

import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getVolumeOfAliquotContainer } from "../../../tg-iso-lims/src/utils/plateUtils";

export const validateTransfers = (
  { worklistTransfers, tubeTransfers = [] },
  aliquotContainerTypes
) => {
  if (tubeTransfers.length || !worklistTransfers) return;
  const sourceTransferVolumeMap = {};
  const destinationTransferVolumeMap = {};
  const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");
  aliquotContainerTypes &&
    worklistTransfers.forEach(transfer => {
      const standardizedSourceVolume = getVolumeOfAliquotContainer(
        transfer.sourceAliquotContainer,
        true
      );
      if (!sourceTransferVolumeMap[transfer.sourceAliquotContainerId]) {
        const deadVolume = get(
          transfer,
          "sourceAliquotContainer.aliquotContainerType.deadVolume"
        );
        let standardizedDeadVolume;
        if (deadVolume && deadVolume > 0) {
          standardizedDeadVolume = standardizeVolume(
            deadVolume,
            get(
              transfer,
              "sourceAliquotContainer.aliquotContainerType.deadVolumetricUnitCode"
            ),
            true
          );
        }
        sourceTransferVolumeMap[transfer.sourceAliquotContainerId] = {
          transfers: [Number(transfer.volume)],
          volumetricUnitCode: transfer.volumetricUnitCode,
          sourceAliquotContainer: transfer.sourceAliquotContainer,
          standardizedSourceVolume,
          standardizedDeadVolume
        };
      } else {
        sourceTransferVolumeMap[
          transfer.sourceAliquotContainerId
        ].transfers.push(Number(transfer.volume));
      }
      if (
        transfer.destinationAliquotContainerId &&
        transfer.destinationAliquotContainer
      ) {
        if (
          !destinationTransferVolumeMap[transfer.destinationAliquotContainerId]
        ) {
          const standardizedDestinationVolume = getVolumeOfAliquotContainer(
            transfer.destinationAliquotContainer,
            true
          );

          destinationTransferVolumeMap[
            transfer.destinationAliquotContainerId
          ] = {
            volume: get(
              transfer.destinationAliquotContainer,
              "aliquot.volume",
              0
            ),
            aliquotContainerTypeCode:
              transfer.destinationAliquotContainer.aliquotContainerTypeCode,
            transfers: [Number(transfer.volume)],
            volumetricUnitCode: transfer.volumetricUnitCode,
            destinationPlateName: transfer.destinationPlateName,
            destinationAliquotContainer: transfer.destinationAliquotContainer,
            destinationAliquotContainerLocation: getAliquotContainerLocation(
              transfer.destinationAliquotContainer
            ),
            standardizedDestinationVolume
          };
        } else {
          destinationTransferVolumeMap[
            transfer.destinationAliquotContainerId
          ].transfers.push(Number(transfer.volume));
        }
      }
    });
  const invalidSourceLocations = {};
  const invalidDeadVolumeLocations = {};
  const invalidTubes = [];
  const invalidDeadVolumeTubes = [];
  const invalidDestinationLocations = [];
  const invalidDestinationLocationsMap = {};
  const invalidDestinationTubes = [];
  forEach(sourceTransferVolumeMap, transfer => {
    const standardizedTransferSum = standardizeVolume(
      sum(transfer.transfers),
      transfer.volumetricUnitCode,
      true
    );

    const sourcePlateName =
      transfer.sourcePlateName ||
      get(transfer, "sourceAliquotContainer.containerArray.name");

    if (
      !transfer.standardizedSourceVolume ||
      transfer.standardizedSourceVolume.lt(standardizedTransferSum)
    ) {
      if (sourcePlateName) {
        if (!invalidSourceLocations[sourcePlateName])
          invalidSourceLocations[sourcePlateName] = [];
        invalidSourceLocations[sourcePlateName].push(
          getAliquotContainerLocation(transfer.sourceAliquotContainer)
        );
      } else {
        invalidTubes.push(transfer.sourceAliquotContainer.name);
      }
    } else if (transfer.standardizedDeadVolume) {
      const remainingVolumeAfterTransfer = transfer.standardizedSourceVolume.minus(
        standardizedTransferSum
      );
      if (remainingVolumeAfterTransfer.lt(transfer.standardizedDeadVolume)) {
        if (sourcePlateName) {
          if (!invalidDeadVolumeLocations[sourcePlateName])
            invalidDeadVolumeLocations[sourcePlateName] = [];
          invalidDeadVolumeLocations[sourcePlateName].push(
            getAliquotContainerLocation(transfer.sourceAliquotContainer)
          );
        } else {
          invalidDeadVolumeTubes.push(transfer.sourceAliquotContainer.name);
        }
      }
    }
  });
  if (aliquotContainerTypes && aliquotContainerTypes.length) {
    // validation breaking here
    forEach(destinationTransferVolumeMap, transfer => {
      const destinationPlateName =
        transfer.destinationPlateName ||
        get(transfer, "destinationAliquotContainer.containerArray.name");

      const standardizedTransferSum = standardizeVolume(
        sum(transfer.transfers),
        transfer.volumetricUnitCode,
        true
      );
      const { maxVolume, volumetricUnitCode } = keyedAliquotContainerTypes[
        transfer.aliquotContainerTypeCode
      ];
      if (
        transfer.standardizedDestinationVolume
          .plus(standardizedTransferSum)
          .gt(standardizeVolume(maxVolume, volumetricUnitCode, true))
      ) {
        if (transfer.destinationAliquotContainerLocation === "N/A") {
          const { name, barcode } = transfer.destinationAliquotContainer;
          let msg;
          if (name && barcode) {
            msg = `${name} (${barcode})`;
          } else if (name) {
            msg = name;
          } else {
            msg = barcode;
          }
          invalidDestinationTubes.push(msg);
        } else {
          if (destinationPlateName) {
            invalidDestinationLocationsMap[destinationPlateName] =
              invalidDestinationLocationsMap[destinationPlateName] || [];
            invalidDestinationLocationsMap[destinationPlateName].push(
              transfer.destinationAliquotContainerLocation
            );
          } else {
            invalidDestinationLocations.push(
              transfer.destinationAliquotContainerLocation
            );
          }
        }
      }
    });
  }
  let error = "";
  if (!isEmpty(invalidSourceLocations)) {
    forEach(invalidSourceLocations, (locations, plateName) => {
      error += "\n";
      error += `Plate ${plateName} has insufficient volume at locations: ${locations.join(
        ", "
      )}`;
    });
  }
  if (invalidTubes.length) {
    error += `\nThese tubes have insufficient volume: ${invalidTubes.join(
      ","
    )}`;
  }

  if (!isEmpty(invalidDeadVolumeLocations)) {
    forEach(invalidDeadVolumeLocations, (locations, plateName) => {
      error += "\n";
      error += `Plate ${plateName} would go below dead volume at locations: ${locations.join(
        ", "
      )}`;
    });
  }
  if (invalidDeadVolumeTubes.length) {
    error += `\nThese tubes would go below dead volume: ${invalidDeadVolumeTubes.join(
      ","
    )}`;
  }

  if (!isEmpty(invalidDestinationLocationsMap)) {
    forEach(invalidDestinationLocationsMap, (locations, plateName) => {
      error += "\n";
      error += `Destination plate ${plateName} has insufficient room for transfers at locations: ${locations.join(
        ", "
      )}`;
    });
  }

  if (invalidDestinationLocations.length > 0) {
    error += "\n";
    error += `Destination plate has insufficient room for transfers at locations ${invalidDestinationLocations.join(
      ", "
    )}`;
  }
  if (invalidDestinationTubes.length > 0) {
    error += "\n";
    error += `These tubes have insufficient room for transfers: ${invalidDestinationTubes.join(
      ", "
    )}`;
  }
  return error;
};

export async function generateSourcePlates(worklist) {
  try {
    const worklistTransfers = await safeQuery(
      [
        "worklistTransfer",
        `id
        worklist {
          id
          name
        }
        volume
        volumetricUnitCode
        sourceAliquotContainer {
          id
          rowPosition
          columnPosition
          containerArrayId
          containerArray {
            id
            name
            barcode {
              id
              barcodeString
            }
          }
          aliquot {
            id
            volume
            volumetricUnitCode
          }
        }`
      ],
      {
        variables: {
          filter: {
            worklistId: worklist.id
          }
        }
      }
    );

    const sourceAliquotIdToPlateInfoMap = {};
    let noPlates = true;
    worklistTransfers.forEach(transfer => {
      const currentSourceWellVolume =
        get(transfer, "sourceAliquotContainer.aliquot.volume") || 0;
      const currentSourceWellVolumeUnitCode =
        get(transfer, "sourceAliquotContainer.aliquot.volumetricUnitCode") ||
        "uL";
      if (
        !sourceAliquotIdToPlateInfoMap[transfer.sourceAliquotContainer.id] &&
        transfer.sourceAliquotContainer.containerArrayId
      ) {
        sourceAliquotIdToPlateInfoMap[transfer.sourceAliquotContainer.id] = {
          plateName:
            get(transfer, "sourceAliquotContainer.containerArray.name") || "",
          plateBarcode:
            get(
              transfer,
              "sourceAliquotContainer.containerArray.barcode.barcodeString"
            ) || "",
          wellLocation: getAliquotContainerLocation(
            transfer.sourceAliquotContainer
          ),
          volume:
            currentSourceWellVolume +
            convertVolume(
              transfer.volume,
              transfer.volumetricUnitCode,
              currentSourceWellVolumeUnitCode
            ),
          volumetricUnitCode: currentSourceWellVolumeUnitCode
        };
        noPlates = false;
      } else if (transfer.sourceAliquotContainer.containerArrayId) {
        sourceAliquotIdToPlateInfoMap[transfer.sourceAliquotContainer.id][
          "volume"
        ] += convertVolume(
          transfer.volume,
          transfer.volumetricUnitCode,
          currentSourceWellVolumeUnitCode
        );
      }
    });
    if (noPlates)
      return window.toastr.warning(`This worklist has no source plates.`);
    const csvRows = [
      "Plate Name,Plate Barcode,Well Location,Volume,Volumetric Unit"
    ];
    forEach(
      sourceAliquotIdToPlateInfoMap,
      ({
        plateName,
        plateBarcode,
        wellLocation,
        volume,
        volumetricUnitCode
      }) => {
        const row = `${plateName},${plateBarcode},${wellLocation},${volume},${volumetricUnitCode}`;
        csvRows.push(row);
      }
    );

    download(
      csvRows.join("\n"),
      `${worklist.name} Original Source Plates.csv`,
      "text/csv"
    );
    window.toastr.success("Successfully generated source plates file.");
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error generating source plates file.");
  }
}

export async function generateLocationList(worklistId) {
  const addToList = (list, item) => {
    if (item && !list.includes(item)) list.push(item);
  };
  try {
    const worklistTransfers = await safeQuery(
      [
        "worklistTransfer",
        `
      id
      sourceAliquotContainerId
      destinationAliquotContainerId
    `
      ],
      {
        variables: {
          filter: {
            worklistId
          }
        }
      }
    );
    const tubeTransfers = await safeQuery(
      [
        "tubeTransfer",
        `
      id
      aliquotContainerId
    `
      ],
      {
        variables: {
          filter: {
            worklistId
          }
        }
      }
    );
    if (!worklistTransfers.length && !tubeTransfers.length) {
      return window.toastr.error("No transfers on worklist.");
    }

    let csvRows;
    if (tubeTransfers.length) {
      const tubeIds = [];
      tubeTransfers.forEach(t => {
        addToList(tubeIds, t.aliquotContainerId);
      });
      if (!tubeIds.length) {
        return window.toastr.error("No tubes found for transfer.");
      }
      const tubesWithLocation = await safeQuery(
        ["aliquotContainerPathView", "id name barcodeString fullPath"],
        {
          variables: {
            filter: {
              id: tubeIds
            }
          }
        }
      );
      csvRows = ["Tube Barcode,Tube Name,Location"];
      tubesWithLocation.forEach(t => {
        csvRows.push(`${t.barcodeString || ""},${t.name || ""},${t.fullPath}`);
      });
    } else {
      const allAliquotContainerIds = [];
      const sourceAliquotContainerIds = [];
      const destinationAliquotContainerIds = [];
      worklistTransfers.forEach(t => {
        addToList(allAliquotContainerIds, t.sourceAliquotContainerId);
        addToList(allAliquotContainerIds, t.destinationAliquotContainerId);
        addToList(sourceAliquotContainerIds, t.sourceAliquotContainerId);
        addToList(
          destinationAliquotContainerIds,
          t.destinationAliquotContainerId
        );
      });

      let keyedAliquotContainers = {};
      let keyedContainerArrays = {};
      let aliquotContainers = [];
      let containerArrays = [];
      if (allAliquotContainerIds.length) {
        aliquotContainers = await safeQuery(
          [
            "aliquotContainer",
            `id name barcode { id barcodeString } containerArrayId assignedPosition { id }`
          ],
          {
            variables: {
              filter: {
                id: allAliquotContainerIds
              }
            }
          }
        );
        keyedAliquotContainers = keyBy(aliquotContainers, "id");
        const allContainerArrayIds = [];
        aliquotContainers.forEach(ac => {
          addToList(allContainerArrayIds, ac.containerArrayId);
        });
        if (allContainerArrayIds.length) {
          containerArrays = await safeQuery(
            [
              "containerArray",
              `id name barcode { id barcodeString } assignedPosition { id }`
            ],
            {
              variables: {
                filter: {
                  id: allContainerArrayIds
                }
              }
            }
          );
          keyedContainerArrays = keyBy(containerArrays, "id");
        }
      }
      const assignedPositionIds = [];
      aliquotContainers.concat(containerArrays).forEach(item => {
        addToList(assignedPositionIds, get(item, "assignedPosition.id"));
      });
      const assignedPositionIdToPath = {};
      if (assignedPositionIds.length) {
        const assignedPositions = await safeQuery([
          "assignedPosition",
          /* GraphQL */ `
            {
              id
              containerArray {
                id
                containerArrayPathView {
                  id
                  fullPath
                }
              }
              aliquotContainer {
                id
                aliquotContainerPathView {
                  id
                  fullPath
                }
              }
            }
          `
        ]);
        assignedPositions.forEach(ap => {
          assignedPositionIdToPath[ap.id] = getPathOfAssignedPosition(ap);
        });
      }
      const sources = [];
      const destinations = [];
      const handleGroupings = (acId, arrayToGroupTo) => {
        const aliquotContainer = keyedAliquotContainers[acId];
        if (aliquotContainer) {
          const containerArray =
            keyedContainerArrays[aliquotContainer.containerArrayId];
          if (containerArray) {
            addToList(arrayToGroupTo, containerArray);
          } else {
            addToList(arrayToGroupTo, aliquotContainer);
          }
        }
      };
      sourceAliquotContainerIds.forEach(acId => handleGroupings(acId, sources));
      destinationAliquotContainerIds.forEach(acId =>
        handleGroupings(acId, destinations)
      );
      csvRows = [];

      const addRowToCsv = source => {
        const barcode = get(source, "barcode.barcodeString", "");
        const name = get(source, "name", "");
        const location =
          assignedPositionIdToPath[get(source, "assignedPosition.id")] || "N/A";

        const row = `${barcode},${name},${location}`;
        csvRows.push(row);
      };

      if (sources.length) {
        csvRows.push("Source Plates/Tubes", "Barcode,Name,Location");
        sources.forEach(addRowToCsv);
      }
      if (destinations.length) {
        csvRows.push("Destination Plates/Tubes", "Barcode,Name,Location");
        destinations.forEach(addRowToCsv);
      }
    }

    const worklist = await safeQuery(["worklist", "id name"], {
      variables: {
        id: worklistId
      }
    });
    download(
      csvRows.join("\n"),
      `${worklist.name} Location List.csv`,
      "text/csv"
    );
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error generating list");
  }
}
