/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const platePrepPlateFragment = gql`
  fragment platePrepPlateFragment on containerArray {
    id
    name
    updatedAt
    containerArrayType {
      id
      name
      isPlate
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      additives {
        id
      }
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
      }
    }
  }
`;

export const platePrepLotFragment = gql`
  fragment platePrepLotFragment on lot {
    id
    name
    updatedAt
    volume
    volumetricUnitCode
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
    additiveMaterial {
      id
      name
      isDry
    }
  }
`;

export const platePrepInputPlateFragment = gql`
  fragment platePrepInputPlateFragment on containerArray {
    id
    name
    updatedAt
    containerArrayType {
      id
      containerFormat {
        code
        name
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
        isSystemType
      }
    }
    aliquotContainers {
      id
      additives {
        id
      }
      aliquot {
        id
        isDry
        sample {
          id
          material {
            id
          }
        }
      }
    }
  }
`;

export const platePrepAdditivesFragment = gql`
  fragment platePrepAdditivesFragment on additive {
    id
    volume
    volumetricUnitCode
  }
`;

export const platePrepAliquotContainerFragment = gql`
  fragment platePrepAliquotContainerFragment on aliquotContainer {
    id
    name
    barcode {
      id
      barcodeString
    }
    rowPosition
    columnPosition
    containerArrayId
    additives {
      ...platePrepAdditivesFragment
    }
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      mass
      massUnitCode
      sample {
        id
        sampleTypeCode
        sampleFormulations {
          id
          materialCompositions {
            id
            materialId
            material {
              id
              name
            }
          }
        }
        material {
          id
          name
        }
      }
    }
  }
  ${platePrepAdditivesFragment}
`;
