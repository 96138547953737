/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment assayPlanningReactionConditionGroupPresetFragment on reactionConditionGroupPreset {
    id
    reactionConditionPresets {
      id
      additiveMaterial {
        id
        name
        isDry
      }
      material {
        id
        name
      }
      concentration
      concentrationUnitCode
      volume
      volumetricUnitCode
      mass
      massUnitCode
    }
  }
`;
