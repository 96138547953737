/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const unitCodeCaseInsensitive = {
  l: "L",
  ml: "mL",
  nl: "nL",
  ul: "uL",
  g: "g",
  mg: "mg",
  ng: "ng",
  ug: "ug",
  "g/l": "g/L",
  "g/ml": "g/mL",
  "g/nl": "g/nL",
  "g/ul": "g/uL",
  "kg/l": "kg/L",
  "kg/ml": "kg/mL",
  "kg/nl": "kg/nL",
  "kg/ul": "kg/uL",
  "mg/l": "mg/L",
  "mg/ml": "mg/mL",
  "mg/nl": "mg/nL",
  "mg/ul": "mg/uL",
  "ng/l": "ng/L",
  "ng/ml": "ng/mL",
  "ng/nl": "ng/nL",
  "ng/ul": "ng/uL",
  "ug/l": "ug/L",
  "ug/ml": "ug/mL",
  "ug/nl": "ug/nL",
  "ug/ul": "ug/uL",
  m: "M",
  fm: "fM",
  mm: "mM",
  nm: "nM",
  pm: "pM",
  um: "uM",
  "cells/l": "cells/L",
  "cells/ml": "cells/mL",
  "cells/nl": "cells/nL",
  "cells/ul": "cells/uL"
};

export default unitString => {
  const unitCodeToUse = unitString.replace(/[^a-zA-Z/]/gi, "u").toLowerCase();
  return unitCodeCaseInsensitive[unitCodeToUse];
};
