/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { times, sum } from "lodash";
import tgCreateCachedSelector from "../utils/tgCreateCachedSelector";
import {
  isLayoutList,
  getNumberOfRowsForCard,
  getBinsInCard,
  getElementsInBin,
  getOutputCardIdOfReaction,
  getNumberOfItemsInBin
} from "./designStateSelectors";

/**
 * Figure out the number of combinations if we are in the list view. Just
 * counts the number of non-empty rows.
 */
function getListNumberOfCombinations(state, operationId) {
  const outputCardId = getOutputCardIdOfReaction(state, operationId);
  const numRows = getNumberOfRowsForCard(state, outputCardId);

  // filledRows[i] will be 1 if there is at least one element in row i, 0 otherwise.
  const filledRows = times(numRows, () => 0);

  for (const set of getBinsInCard(state, outputCardId)) {
    for (const el of getElementsInBin(state, set.id)) {
      filledRows[el.index] = 1;
    }
  }

  return sum(filledRows);
}

/**
 * Figure out the number of combinations if we are in the combinatorial view. Will
 * ignore any filtering of the constructs including eugene rules, prebuilt constructs...
 */
function getCombinatorialNumberOfCombinations(state, operationId) {
  const outputCardId = getOutputCardIdOfReaction(state, operationId);
  const numElsPerBin = getBinsInCard(state, outputCardId).map(bin =>
    getNumberOfItemsInBin(state, bin.id)
  );
  return prod(numElsPerBin);
}

/**
 * Given an operation, produce an upper bound on its number of output
 * constructors. For list designs, this wil be equivalent to the number
 * of non-empty rows. For combinatorial designs, this will be equivalent
 * to the number of outputs ignoring eugene rules, available constructs, etc.
 * @param {Object} state The full redux state.
 * @param {string} operationId The id of operation.
 * @returns {}
 */
export default tgCreateCachedSelector(
  state => state,
  (state, operationId) => operationId,
  (state, operationId) => {
    if (isLayoutList(state)) {
      return getListNumberOfCombinations(state, operationId);
    } else {
      return getCombinatorialNumberOfCombinations(state, operationId);
    }
  }
)((state, operationId) => operationId);

function prod(a) {
  return a.reduce((p, x) => p * x, 1);
}
