/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isoContext } from "@teselagen/utils";
import { extractPlateCsvFiles } from "./parsePlateCsvAndSequenceFiles";
import { handlePlateCsv } from "./parsePlateCsvAndSequenceFiles";
import finishPlateOrTubeCreate from "../utils/finishPlateOrTubeCreate";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { keyBy } from "lodash";
import { overwriteBarcodeCheck } from "../utils/plateUtils";

export const requiredHeaders = ["Plate Name", "Plate Type"];

export const allHeaders = ["Plate Name", "Plate Barcode", "Plate Type"];

export default async function handleEmptyPlateImport(values, ctx = isoContext) {
  const { safeQuery } = ctx;
  const filename = values.plateFiles[0].name;

  const { csvFiles } = await extractPlateCsvFiles(values.plateFiles);
  const { csvData } = await handlePlateCsv(
    csvFiles,
    {},
    {
      requiredFields: requiredHeaders,
      nameHeader: "PLATE_NAME",
      barcodeHeader: "PLATE_BARCODE"
    },
    ctx
  );

  const plateTypeNames = [];

  for (const [index, row] of csvData.entries()) {
    const { PLATE_NAME: plateName, PLATE_TYPE: plateType } = row;
    if (!plateType) {
      throw new Error(`Row ${index + 1} did not specify a plate type`);
    }
    if (!plateName) {
      throw new Error(`Row ${index + 1} did not specify a plate name`);
    }
    plateTypeNames.push(plateType);
  }

  const containerArrayTypes = await safeQuery(
    [
      "containerArrayType",
      /* GraphQL */ `
        {
          id
          name
          isPlate
          aliquotContainerTypeCode
          aliquotContainerType {
            code
            name
          }
          containerFormat {
            code
            name
            rowCount
            columnCount
            quadrantSize
            is2DLabeled
          }
        }
      `
    ],
    {
      variables: {
        filter: caseInsensitiveFilter(
          "containerArrayType",
          "name",
          plateTypeNames
        )
      }
    }
  );

  const keyedPlateTypes = keyBy(containerArrayTypes, pt =>
    pt.name.toLowerCase()
  );

  const platesToCreate = [];
  const allPlateTypes = [];

  if (values.generateBarcode) {
    const continueUpload = await overwriteBarcodeCheck(
      csvData,
      "Plate Barcode"
    );
    if (!continueUpload) return;
  }

  for (const [index, row] of csvData.entries()) {
    const {
      PLATE_NAME: plateName,
      PLATE_BARCODE: plateBarcode,
      PLATE_TYPE: _plateType
    } = row;
    const matchingPlateType = keyedPlateTypes[_plateType.toLowerCase()];
    if (!matchingPlateType) {
      throw new Error(
        `Row ${
          index + 1
        } specifies the plate type ${_plateType} which was not found.`
      );
    }
    allPlateTypes.push(matchingPlateType);
    platesToCreate.push({
      name: plateName,
      containerArrayType: matchingPlateType,
      containerArrayTypeId: matchingPlateType.id,
      ...(!values.generateBarcode &&
        plateBarcode && {
          barcode: {
            barcodeString: plateBarcode
          }
        })
    });
  }

  const createdPlates = await finishPlateOrTubeCreate({
    newPlates: platesToCreate,
    filename,
    allPlateTypes,
    ...values
  });

  return createdPlates;
}
