/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { BlueprintError } from "@teselagen/ui";

import { AddButton } from "../../FieldArrayButtons";
import MeasurementUnit from "./MeasurementUnit";

import "./style.css";

const add = fields => () => {
  if (!fields.length) {
    fields.push({
      isBaseUnit: true,
      conversionFactor: 1
    });
  } else {
    fields.push({});
  }
};

function CategoricalItems(props) {
  const {
    fields,
    meta: { submitFailed, error },
    isEditing
  } = props;
  return (
    <div>
      <div style={{ marginBottom: 10 }}>Units</div>
      <div className="edit-measurement-units">
        {fields.map((field, index) => (
          <MeasurementUnit key={index} {...{ ...props, field, index }} />
        ))}
        {!isEditing && (
          <AddButton fields={fields} label="Add Unit" add={add(fields)} />
        )}
        {submitFailed && <BlueprintError error={error} />}
      </div>
    </div>
  );
}

export default CategoricalItems;
