/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { min, max } from "lodash";
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import {
  getCopiedCells,
  getSelectedCellPaths,
  getSelectedCardId
} from "../../../selectors/designViewSelectors";
import {
  getDesign,
  getElementsInBin,
  getItemOfType,
  getBinsInCard
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import removeElements from "./removeElements";
import shortid from "shortid";

const getTopLeftIndexOfSelection = (selectedCells, bins) => {
  return {
    topRowIndex: min(selectedCells.map(c => c.index)),
    leftColumnIndex: min(
      selectedCells.map(c => bins.findIndex(s => s.id === c.binId))
    )
  };
};

const getCopiedCellsSize = copiedCells => ({
  numRows: max(copiedCells.map(c => c.rowIndex)) + 1,
  numColumns: max(copiedCells.map(c => c.columnIndex)) + 1
});

const canEditElements = (fullState, binId) => {
  const bin = getItemOfType(fullState, "bin", binId);
  return !bin.isLocked && !bin.isPlaceholder;
};

export default (state, { payload: { additionalItems } }, fullState) => {
  const changeSetsHelper = new ChangeSetsHelper(state);

  if (additionalItems) changeSetsHelper.updateViaFlatObject(additionalItems);

  const design = getDesign(fullState);
  const selectedCardId = getSelectedCardId(fullState);
  const bins = getBinsInCard(fullState, selectedCardId);
  const selectedCells = getSelectedCellPaths(fullState);
  const copiedCells = getCopiedCells(fullState);

  const selectedElementIds = selectedCells
    .filter(c => c.elementId && canEditElements(fullState, c.binId))
    .map(c => c.elementId);
  removeElements(state, selectedElementIds, changeSetsHelper);

  const { topRowIndex, leftColumnIndex } = getTopLeftIndexOfSelection(
    selectedCells,
    bins
  );

  const { numRows } = getCopiedCellsSize(copiedCells);

  if (topRowIndex + numRows > design.numRows) {
    changeSetsHelper.updatePure("design", {
      id: design.id,
      numRows: topRowIndex + numRows
    });
  }

  const cellsToInsert = copiedCells.filter(
    c => c.columnIndex < bins.length - leftColumnIndex
  );

  // Remove the elements that we are overiding
  const overwrittenElementIds = [];

  // Add the elements we are copying
  if (cellsToInsert.length === 1) {
    // If we are inserting a single cell, then paste it into every highlighted cell.
    const { element } = cellsToInsert[0];

    for (const cell of selectedCells) {
      if (cell.elementId) {
        overwrittenElementIds.push(cell.elementId);
      }

      if (element) {
        changeSetsHelper.createPure("element", {
          ...element,
          id: shortid(),
          binId: cell.binId,
          index: cell.index
        });
      }
    }
  } else {
    // If we insert multiple scells, paste the cells in their same original arangement,
    // but taken offset from the top-left most selected row/column.

    // Remove the elements we are overiding.
    for (const { rowIndex, columnIndex } of cellsToInsert) {
      const binId = bins[leftColumnIndex + columnIndex].id;
      if (!canEditElements(fullState, binId)) continue;
      const elements = getElementsInBin(fullState, binId);
      const element = elements.find(el => el.index === topRowIndex + rowIndex);
      if (element) overwrittenElementIds.push(element.id);
    }

    // Add the elements
    for (const { rowIndex, columnIndex, element } of cellsToInsert) {
      const binId = bins[leftColumnIndex + columnIndex].id;
      if (!element || !canEditElements(fullState, binId)) continue;

      changeSetsHelper.createPure("element", {
        ...element,
        id: shortid(),
        binId,
        index: topRowIndex + rowIndex
      });
    }
  }

  removeElements(state, overwrittenElementIds, changeSetsHelper);

  return changeSetsHelper.execute({
    recomputeElementValidation: true,
    recomputeAssemblyPieceValidation: true,
    removeInaccessibleItems: true,
    removeNonsensicalExtraSequences: true,
    updateNumPlaceholders: true,
    recomputeBinValidation: true,
    recomputeDigestFasValidation: true,
    removeInvalidEugeneRules: true,
    removeInvalidElementCombos: true,
    updateInvalidMaterialAvailabilities: state
  });
};
