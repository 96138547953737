/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import DownstreamAutomationParameters from "./structs/DownstreamAutomationParameters";
import PcrReaction from "./structs/PcrReaction";
import PcrBlock from "./structs/PcrBlock";
import {
  selectBlockZonesAndTemperatureToAdjust,
  doMonteCarloMove,
  identifyBlockAndZoneForNextPcrReaction,
  reorderPcrReactionsMonotonicallyInPlace
} from "./MonteCarlo";

// For help while porting the code from perl.
// TODO: Delete later.
// import defaultPlates from "./mock_data/MockPlates";

// For help while porting the code from perl.
// TODO: Delete later.
import defaultPcrReactions from "./mock_data/MockPcrReactions";

const DistributePcrReactions = (
  downstreamAutomationParameters = new DownstreamAutomationParameters(),
  pcrReactions = defaultPcrReactions
  // plates = defaultPlates,
) => {
  // TODO: Initialize j5 parameters.
  // const j5Parameters = ...;

  // console.warn("TODO: Read in the downstream automation parameters");

  // Sort the PCR reactions by optimum annealing temperature.
  pcrReactions = PcrReaction.sortByOptimumAnnealingTemperature(pcrReactions);

  const bestPcrBlocks = designPcrBlockGradients(
    pcrReactions,
    downstreamAutomationParameters
  );
  // console.log(bestPcrBlocks);
  // bestPcrBlocks.forEach(block => block.print());

  // TODO: (possible) Maybe same PRPR stuff (e.g., PRPR_constraints_ok)

  // console.log(bestPcrBlocks)

  // throw new Error(
  //   "You might have to do the stuff in the function `Fill_Pcr_Plates`. I'm not sure. Look into it."
  // );

  // console.error('TODO: Maybe convert the pcr blocks to plates and return the plates instead.')

  return bestPcrBlocks;
};

/**
 * For a given set of pcr reactions, design the optimal thermocycler block gradients and
 * pcr reaction locations in the blocks
 */
const designPcrBlockGradients = (
  pcrReactions,
  downstreamAutomationParameters
) => {
  const {
    wells_per_thermocycler_block,
    zones_per_thermocycler_block,
    wells_per_thermocycler_zone,
    max_delta_temperature_adjacent_zones,
    max_mc_steps_per_zone,
    mc_temperature_initial,
    mc_temperature_final,
    max_delta_temperature_reaction_optimum_zone_acceptable
  } = downstreamAutomationParameters;

  let maxTempDeviation;

  // Get the minimum and maximum PCR reaction optimum annealing temperatures.
  // This only works because the PCR reactions SHOULD already be sorted in ascending
  // order based on their optimal annealing temperature.
  const minOptTemp = pcrReactions[0].optimal_annealing_temperature;
  const maxOptTemp =
    pcrReactions[pcrReactions.length - 1].optimal_annealing_temperature;

  // Initialize the PCR blocks.
  const pcrBlocks = [];
  let bestPcrBlocks;

  // Initialize as many pcr blocks as necessary (minus one) to hold all of the reactions.
  for (
    let i = 0;
    i < Math.ceil(pcrReactions.length / wells_per_thermocycler_block) - 1;
    i++
  ) {
    // bestPcrBlocks.push(new PcrBlock())
    pcrBlocks.push(
      new PcrBlock({
        n_zones: zones_per_thermocycler_block,
        wells_per_zone: wells_per_thermocycler_zone,
        max_delta_temperature_adjacent_zones
      })
    );
  }

  // Optimize for the current number of blocks (starting with the absolute minimum).
  // The monte-carlo simulated temperature will linearly progress over the steps from the initial to the final temperature
  // allowing for a simulated annealing.
  do {
    // Initialize one additional pcr block.
    // bestPcrBlocks.push(new PcrBlock())
    pcrBlocks.push(
      new PcrBlock({
        n_zones: zones_per_thermocycler_block,
        wells_per_zone: wells_per_thermocycler_zone,
        max_delta_temperature_adjacent_zones
      })
    );

    initializeBlockZoneTemperatures(pcrBlocks, pcrReactions);

    fillInBlocksWithPcrReactions(pcrBlocks, pcrReactions);

    let objFun = calculateObjectiveFunction(pcrBlocks, pcrReactions);

    // Set the best objective function and best pcr blocks to the initial values.
    let bestObjFun = objFun;
    bestPcrBlocks = pcrBlocks.map(block => block.copy());

    const maxMcSteps =
      max_mc_steps_per_zone * pcrBlocks.length * zones_per_thermocycler_block;

    // Perform the monte-carlo steps.
    // The monte-carlo simulated temperature will linearly progress over the steps from the initial to the final temperature
    // allowing for a simulated annealing.
    for (let mcStep = 0; mcStep < maxMcSteps; mcStep++) {
      const {
        trialBlock,
        trialZones,
        trialTempChange
      } = selectBlockZonesAndTemperatureToAdjust(
        pcrBlocks,
        minOptTemp,
        maxOptTemp,
        downstreamAutomationParameters
      );

      // Do the trial move.
      doMonteCarloMove(
        pcrBlocks,
        pcrReactions,
        trialBlock,
        trialZones,
        trialTempChange
      );

      const trialObjFun = calculateObjectiveFunction(pcrBlocks, pcrReactions);

      // Revert if the trial is worse than the established, and the boltzmann expression is not satisfied.
      if (
        trialObjFun > objFun &&
        Math.random() >
          Math.exp(
            -(trialObjFun - objFun) /
              ((mc_temperature_initial * (maxMcSteps - mcStep)) / maxMcSteps) +
              mc_temperature_final * (1 - (maxMcSteps - mcStep) / maxMcSteps)
          )
      ) {
        // Undo the trial move.
        doMonteCarloMove(
          pcrBlocks,
          pcrReactions,
          trialBlock,
          trialZones,
          -trialTempChange
        );
      } else {
        // Otherwise accept the move.
        objFun = trialObjFun;

        // Update the best objective function value and the best set of pcr blocks, if appropriate.
        if (bestObjFun > objFun) {
          bestObjFun = objFun;
          bestPcrBlocks = pcrBlocks.map(block => block.copy());
        }
      }
    }

    // Initialize the maximum deviance between the pcr reaction optimal annealing temperature and the zone temperature.
    maxTempDeviation = 0;

    // Find the maximum deviance between the pcr reaction optimal annealing temperature and the best pcr block zone temperature.
    /* eslint-disable no-loop-func*/
    bestPcrBlocks.forEach(block => {
      block.zone_reaction_list.forEach((zoneReactions, j) => {
        zoneReactions.forEach(({ optimal_annealing_temperature }) => {
          if (
            Math.abs(
              optimal_annealing_temperature - block.zone_temperature[j]
            ) > maxTempDeviation
          ) {
            maxTempDeviation = Math.abs(
              optimal_annealing_temperature - block.zone_temperature[j]
            );
          }
        });
      });
    });
    /* eslint-enable no-loop-func*/

    // Add another block if the constraint is not met.
  } while (
    maxTempDeviation > max_delta_temperature_reaction_optimum_zone_acceptable
  );

  return bestPcrBlocks;
};

/**
 * Initialize the annealing temperatures of the thermocycler block zones.
 */
const initializeBlockZoneTemperatures = (blocks, pcrReactions) => {
  // This only works because the PCR reactions SHOULD already be sorted in ascending
  // order based on their optimal annealing temperature.
  const minOptTemp = pcrReactions[0].optimal_annealing_temperature;
  const maxOptTemp =
    pcrReactions[pcrReactions.length - 1].optimal_annealing_temperature;

  let totalZones = 0;
  blocks.forEach(block => {
    totalZones += block.n_zones;
  });

  // If there is more than one zone to play with.
  if (totalZones > 1) {
    // Compute that target average delta temperature between zones.
    const deltaTemp = (maxOptTemp - minOptTemp) / (totalZones - 1);

    // Check to see if this average delta temperature between zones exceeds the maximum for any
    // of the blocks with more than one zone.
    let useMaxTempSpreadFlag = false;
    blocks.forEach(block => {
      if (block.n_zones > 1 && block.max_delta_temperature_adjacent_zones) {
        useMaxTempSpreadFlag = true;
      }
    });

    let currentTemp = minOptTemp;

    // If we need to maximally spread out the zone temperatures.
    if (useMaxTempSpreadFlag) {
      blocks.forEach(block => {
        for (let j = 0; j < block.n_zones - 1; j++) {
          block.zone_temperature[j] = currentTemp;
          currentTemp += block.max_delta_temperature_adjacent_zones;
        }
        block.zone_temperature[block.n_zones - 1] = currentTemp;

        // The change in temperature between blocks is not constrained, so make up any
        // deficit from the target gradient
        currentTemp +=
          deltaTemp +
          (deltaTemp - block.max_delta_temperature_adjacent_zones) *
            (block.n_zones - 1);
      });
    } else {
      // Otherwise we don't need to maximally spread out the zone temperatures.
      blocks.forEach(block => {
        for (let j = 0; j < block.n_zones - 1; j++) {
          block.zone_temperature[j] = currentTemp;
          currentTemp += deltaTemp;
        }
      });
    }
  } else {
    // Otherwise only one zone total; this can only happen if only one block, which contains only one zone.
    // Set the zone temperature to the minimum temperature.
    blocks[0].zone_temperature[0] = minOptTemp;
  }
};

/**
 * Fill in the blocks with the pcr reactions.
 */
const fillInBlocksWithPcrReactions = (blocks, pcrReactions) => {
  // First empty the blocks
  blocks.forEach(block => block.emptyPcrReactions());

  // Fill in the blocks with the pcr reactions.
  pcrReactions.forEach(pcrReaction => {
    const {
      selectedBlock,
      selectedZone
    } = identifyBlockAndZoneForNextPcrReaction(blocks, pcrReaction);
    selectedBlock.addPcrReactionToZone(pcrReaction, selectedZone);
  });

  reorderPcrReactionsMonotonicallyInPlace(blocks, pcrReactions);
};

/**
 * Calculate the objective function for the distribution of the pcr reactions across the blocks.
 */
const calculateObjectiveFunction = (blocks, pcrReactions) => {
  let val = 0;

  // Check to make sure that there is at least one pcr reaction.
  if (!pcrReactions.length) return val;

  blocks.forEach(block => {
    block.zone_reaction_list.forEach((zoneReactions, j) => {
      zoneReactions.forEach(zoneReaction => {
        const tempScore =
          zoneReaction.optimal_annealing_temperature -
          block.zone_temperature[j];
        val += tempScore * tempScore;
      });
    });
  });

  return val;
};

export default DistributePcrReactions;
