/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { InputField, TextareaField } from "@teselagen/ui";
import { DesignImport } from "../../utils/designImportProcess";
import FormFooter from "../../../src-shared/components/FormFooter";

import "./style.css";
import { Card } from "@blueprintjs/core";

class EmptyDesignTemplate extends React.PureComponent {
  beforeSubmit = async values => {
    try {
      const { history } = this.props;
      const designImport = new DesignImport(history, true);

      return designImport.processImport(values);
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating design template.");
    }
  };

  render() {
    const { handleSubmit, submitting, history } = this.props;

    return (
      <Card className="form-layout">
        <form onSubmit={handleSubmit(this.beforeSubmit)}>
          <h2 className="edit-view-title">Create New Design Template</h2>
          <InputField name="name" label="Name" isRequired />
          <TextareaField name="description" label="Description" />
          <FormFooter submitting={submitting} history={history} />
        </form>
      </Card>
    );
  }
}

export default compose(
  reduxForm({
    form: "emptyDesignTemplateForm"
  })
)(EmptyDesignTemplate);
