/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import { Colors } from "@blueprintjs/core";
import actions from "../../../../src-shared/redux/actions";
import {
  COLUMN_WIDTH,
  BIN_HEIGHT,
  CONSTRUCT_ANNOTATION_HEIGHT
} from "../../../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";
import getAppColor from "../../../../src-shared/utils/getAppColor";
import { isDesignLocked } from "../../../../src-shared/utils/designUtils/isDesignLocked";

function BinHeaderRect(props) {
  const {
    bin,
    setBinIcon,
    hasConstructAnnotations,
    isSelected,
    // iconPath,
    // icon,
    handleBinHeaderClick,
    handleContextMenu
  } = props;
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "SBOL_ICON",
    drop: item => {
      setBinIcon({ binId: bin.id, icon: item.icon });
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = isOver && canDrop;
  let backgroundColor;
  if (isActive) {
    backgroundColor = getAppColor();
  } else if (canDrop) {
    if (localStorage.darkMode === "false") {
      backgroundColor = Colors.GRAY3;
    } else {
      backgroundColor = "rgb(52, 59, 64)";
    }
  }

  return (
    <rect
      ref={drop}
      className={
        "bin-header-outline" +
        (isSelected ? " selected-bin-header-outline" : "")
      }
      {...{
        y: 0 + +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT,
        style: { fill: backgroundColor },
        width: COLUMN_WIDTH - 1,
        height: BIN_HEIGHT - 1,
        shapeRendering: "crispEdges",
        onClick: handleBinHeaderClick,
        onContextMenu: handleContextMenu
      }}
    />
  );
}

export default connect(
  state => ({
    isLocked: !!isDesignLocked(state)
  }),
  {
    setBinIcon: actions.design.setBinIcon
  }
)(BinHeaderRect);
