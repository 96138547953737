/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  FileUploadField,
  DialogFooter,
  ReactSelectField,
  wrapDialog,
  CheckboxField,
  BlueprintError
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { readAsText } from "promise-file-reader";
import locationPartialFragment from "../../../graphql/fragments/locationPartialFragment";
import {
  arrayToIdOrCodeValuedOptions,
  throwFormError
} from "../../../../src-shared/utils/formUtils";
import {
  getExt,
  extractZipFiles
} from "../../../../../tg-iso-shared/src/utils/fileUtils";
import exampleUpload from "./example-upload.json";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { handleEquipmentUpload } from "../../../../../tg-iso-lims/src/utils/equipmentUtils";

class EquipmentUploadDialog extends Component {
  onSubmit = async values => {
    const { refetch, hideModal } = this.props;
    const { equipmentFiles = [], locationId, generateBarcode } = values;

    const parsedEquipmentItems = [];
    try {
      const equipmentJsonFiles = (await extractZipFiles(equipmentFiles)).filter(
        file => getExt(file) === "json"
      );
      if (!equipmentJsonFiles.length) {
        return window.toastr.error("No JSON files found.");
      }
      for (const jsonFile of equipmentJsonFiles) {
        const jsonContent = await readAsText(jsonFile.originFileObj);
        const equipment = JSON.parse(jsonContent);
        parsedEquipmentItems.push(equipment);
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error("Error parsing files");
    }

    try {
      await handleEquipmentUpload({
        equipmentJsons: parsedEquipmentItems,
        locationId,
        files: equipmentFiles,
        promptBarcodeOverwrite: true,
        generateBarcode,
        refetch
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      throwFormError(`Error uploading equipment: ${error.message}`);
    }
  };

  render() {
    const {
      handleSubmit,
      hideModal,
      submitting,
      locations,
      error
    } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <ReactSelectField
            name="locationId"
            label="Location"
            isRequired
            placeholder="Select a location"
            options={arrayToIdOrCodeValuedOptions(locations)}
          />
          <CheckboxField
            name="generateBarcode"
            label="Generate Barcodes"
            defaultValue
            tooltipInfo="Will generate barcodes for equipment and nested containers"
          />
          <FileUploadField
            accept={getDownloadTemplateFileHelpers({
              type: [".json", ".zip"],
              fileName: "example-equipment.json",
              fileContents: JSON.stringify(exampleUpload, null, 2)
            })}
            name="equipmentFiles"
            isRequired
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          onClick={handleSubmit(this.onSubmit)}
          submitting={submitting}
          hideModal={hideModal}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({ title: "Upload Equipment" }),
  withQuery(locationPartialFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  reduxForm({
    form: "UploadEquipment"
  })
)(EquipmentUploadDialog);
