/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// Hope to get this into typescript, but seems unnecessary right now
// due to the extra overhead.

/**
 * Base class that defines how the different gql error formatter objects should look like
 * If ported to typescript this could be simplified with an Interface.
 */
class GqlErrorFormatter {
  /**
   * The error's type code
   */
  static TYPE = "UNKNOWN";

  /**
   * Check function to determine whether the error is of this type.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static check(errorMessage) {
    return true;
  }

  /**
   * Main handler or formatter for the incoming error. Receives the gql error object plus the gql operation that caused it.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static handler({ error, operation }) {
    return error;
  }
}

export default GqlErrorFormatter;
