/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createSelector } from "reselect";

import { keyBy, some, isNil, find, maxBy, size } from "lodash";

import {
  getElementsInBin,
  getRootCard,
  getAllOfType,
  getDesignState,
  getIdOfParentCard,
  isLayoutList,
  getDesign,
  getOutputtingReactionIdOfCard,
  getBinIdsInCard,
  getBinsInCard
} from "./designStateSelectors";
import tgCreateCachedSelector from "../utils/tgCreateCachedSelector";
import {
  DIGEST_ASSEMBLY_METHODS,
  GOLDEN_GATE
} from "../constants/assemblyMethods";

export const goldenGateAssemblyHasVariableLengthConstructs = createSelector(
  state => state,
  isLayoutList,
  state => getAllOfType(state, "card"),
  state => getAllOfType(state, "reaction"),
  state => getAllOfType(state, "element"),
  state => getAllOfType(state, "assemblyMethod"),
  (state, isList, cards, reactions, elements, assemblyMethods) => {
    // Get the number of rows in the design, in order to know if any bin has undefined parts.
    const maxElementIndex = maxBy(Object.values(elements), "index");
    // We only care about golden gate reactions.
    const goldenGateId = find(
      assemblyMethods,
      assemblyMethod => assemblyMethod.name === GOLDEN_GATE
    )?.id;

    if (!goldenGateId || !size(cards) || !size(reactions) || !maxElementIndex)
      return;

    const designRows = maxElementIndex.index + 1;

    const ggReactionIds = Object.values(reactions)
      .filter(reaction => reaction.assemblyMethodId === goldenGateId)
      .map(r => r.id);
    const ggCards = Object.values(cards).filter(
      card => ggReactionIds.includes(card.inputReactionId) && !card.isRoot // we don't care about the root card here
    );

    // Check if any bin in a golden gate card has undefined parts.
    const doesAnyBinHaveUndefinedParts = some(ggCards, card =>
      some(
        getBinsInCard(state, card.id),
        bin => getElementsInBin(state, bin.id).length < designRows
      )
    );

    // Check if any bin in a golden gate card has empty parts.
    const doesAnyBinHaveEmptyParts = some(ggCards, card =>
      some(getBinsInCard(state, card.id), bin =>
        some(getElementsInBin(state, bin.id), element => element.isEmpty)
      )
    );

    return (isList && doesAnyBinHaveUndefinedParts) || doesAnyBinHaveEmptyParts;
  }
);

export const digestAssemblyHasNoEnzyme = createSelector(
  state => getAllOfType(state, "reaction"),
  state => getAllOfType(state, "assemblyMethod"),
  (reactions, assemblyMethods) => {
    return find(
      reactions,
      r =>
        DIGEST_ASSEMBLY_METHODS.includes(
          assemblyMethods[r.assemblyMethodId]?.name
        ) && isNil(r.restrictionEnzymeId)
    );
  }
);
export const isCED = createSelector(
  state => getAllOfType(state, "reaction"),
  state => getAllOfType(state, "assemblyMethod"),
  (reactions, assemblyMethods) => {
    return find(
      reactions,
      r =>
        assemblyMethods[r.assemblyMethodId]?.name ===
        "Contiguous Express Digest"
    );
  }
);

export const doesAnyCardHaveEmptyBins = createSelector(
  state => state,
  isLayoutList,
  (state, isList) =>
    isList
      ? false
      : Object.keys(getAllOfType(state, "bin")).some(
          binId => !getElementsInBin(state, binId).length
        )
);

export const doesDesignNotHaveReaction = createSelector(
  state => state,
  getRootCard,
  (state, rootCard) => !getOutputtingReactionIdOfCard(state, rootCard.id)
);

export const doesDesignContainUnmappedElements = createSelector(
  state => getAllOfType(state, "element"),
  elements =>
    Object.values(elements).some(
      el =>
        !el.bps &&
        !el.isEmpty &&
        !el.partId &&
        !el.partsetId &&
        !el.aminoAcidPartId
    )
);

export const doesDesignContainInvalidElements = createSelector(
  state => getAllOfType(state, "element"),
  elements => Object.values(elements).some(el => el.invalidityMessage)
);
export const doesDesignContainAAParts = createSelector(
  state => getAllOfType(state, "element"),
  elements => Object.values(elements).some(el => el.aminoAcidPartId)
);

export const doesDesignContainInvalidCards = createSelector(
  state => getAllOfType(state, "card"),
  cards => Object.values(cards).some(card => card.invalidityMessage)
);

export const isJ5Running = createSelector(
  getDesignState,
  state => state.j5.designsRunningJ5,
  (designState, designsRunningJ5) =>
    !!designsRunningJ5[Object.keys(designState.design)[0]]
);

export const doesListLayoutDesignHaveEmptyRows = createSelector(
  state => state,
  getDesign,
  state => getAllOfType(state, "element"),
  state => getAllOfType(state, "card"),
  isLayoutList,
  (state, design, elements, cards, isList) => {
    if (!isList) return false;
    const checkedSets = {};
    const rowsWithAnEl = {};
    for (let i = 0; i < design.numRows; i++) {
      rowsWithAnEl[i] = false;
    }
    for (const cardId of Object.keys(cards)) {
      for (const binId of getBinIdsInCard(state, cardId)) {
        if (checkedSets[binId]) continue;
        const elements = getElementsInBin(state, binId);
        const indexToElement = keyBy(elements, "index");
        for (let i = 0; i < design.numRows; i++) {
          if (!indexToElement[i]) {
            //do nothing
          } else {
            rowsWithAnEl[i] = true;
          }
        }
        checkedSets[binId] = true;
      }
    }
    for (let i = 0, ii = design.numRows; i < ii; i++) {
      if (!rowsWithAnEl[i]) {
        return true; //we've found a row that has NO elements in it
      }
    }

    return false;
  }
);

export const doesListLayoutDesignHaveAllDisabledRows = createSelector(
  state => state,
  getDesign,
  state => getAllOfType(state, "disabledDesignRow"),
  isLayoutList,
  (state, design, disabledDesignRow, isList) => {
    if (!isList) return false;
    const keyed = keyBy(disabledDesignRow, "rowIndex");
    for (let i = 0, ii = design.numRows; i < ii; i++) {
      if (!keyed[i]) return false;
    }
    return true;
  }
);

// If check functions return true, then the Submit for Assembly
// button will be disabled.
const submitForAssemblyChecks = [
  {
    // NOTE: we might need to also apply this validation for auto-digest and continuous express digest, although we seem to be deprecating the latter
    fn: goldenGateAssemblyHasVariableLengthConstructs,
    message:
      "Golden Gate assembly cannot have constructs with variable number of parts"
  },
  {
    fn: digestAssemblyHasNoEnzyme,
    message:
      "A Restriction Enzyme needs to be selected for digest based assemblies"
  },
  {
    fn: isCED,
    message:
      "It looks like one of your reactions is using the deprecated Contiguous Express Digest method. Please set a different Assembly Method"
  },
  {
    fn: doesDesignContainAAParts,
    message: "Design contains AA Parts that are not reverse translated"
  },
  { fn: doesAnyCardHaveEmptyBins, message: "Design contains empty bins" },
  { fn: doesDesignNotHaveReaction, message: "Design does not have reaction" },
  {
    fn: doesDesignContainUnmappedElements,
    message: "Design contains unmapped parts"
  },
  {
    fn: doesDesignContainInvalidElements,
    message: "Design contains invalid parts"
  },
  {
    fn: doesDesignContainInvalidCards,
    message: "Design contains invalid cards"
  },
  {
    fn: doesListLayoutDesignHaveEmptyRows,
    message: "List layout design has empty rows"
  },
  {
    fn: doesListLayoutDesignHaveAllDisabledRows,
    message: "All rows are disabled"
  }
];

export const canSubmitForAssembly = createSelector(
  ...submitForAssemblyChecks.map(s => s.fn),
  (...violations) => violations.every(v => !v)
);

export const getSubmitForAssemblyViolations = createSelector(
  ...submitForAssemblyChecks.map(s => (...args) => {
    if (s.fn(...args)) return s.message;
  }),
  (...violations) => violations.filter(v => v).join(". ")
);

export const getBinsThatArePropagatedUpMap = tgCreateCachedSelector(
  (state, cardId) => getBinIdsInCard(state, cardId),
  (state, cardId) =>
    getIdOfParentCard(state, cardId) &&
    getBinIdsInCard(state, getIdOfParentCard(state, cardId)),
  (binIdsInCard, binIdsInParent) => {
    if (!binIdsInParent) return {};
    return binIdsInCard.reduce((acc, binId) => {
      if (binIdsInParent.includes(binId)) acc[binId] = true;
      return acc;
    }, {});
  }
)((state, cardId) => cardId);

export const getNonTerminalErdamSets = tgCreateCachedSelector(
  () => {},
  () => {
    throw new Error("Deprecated");
  }
)((state, cardId) => cardId);

export const getRemoveInterruptedFeatures = createSelector(
  state => state,
  state =>
    state.form.designInspectorEditDesign &&
    state.form.designInspectorEditDesign.values.removeInterruptedFeatures
);
