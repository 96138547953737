/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createActions } from "redux-actions";
import notificationActions from "./notificationActions";

const identity = payload => payload;
const actionCreators = createActions({
  PLATFORM: {
    UI: {
      THEME: {
        TOGGLE: identity
      }
    }
  }
});

const allActions = {
  ...notificationActions,
  ...createActions(actionCreators)
};

export default allActions;
