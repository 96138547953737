/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment workflowRunStatusTypeFragment on workflowRunStatusType {
    code
    name
    description
  }
`;
