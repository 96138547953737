/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { showConfirmationDialog } from "@teselagen/ui";
import { Button, Intent, Callout } from "@blueprintjs/core";
import { times, forEach, range, chunk, sortBy, get } from "lodash";
import shortid from "shortid";
import withQuery from "../../../../../src-shared/withQuery";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import Replication from "../Replication";
import Breakdown from "../Breakdown";
import Combination from "../Combination";
import Consolidation from "../Consolidation";
import { validateTransfers } from "../../../../utils";
import containerArrayWorklistFragment from "../../../../graphql/fragments/containerArrayWorklistFragment";
import containerFormatFragment from "../../../../../../tg-iso-shared/src/fragments/containerFormatFragment";
import containerArrayTypeFragment from "../../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import {
  plateTo2dAliquotContainerArray,
  getBlockOf2dArray,
  blockToAliquotArray,
  createSourceToDestinationPlate2DArrayMap,
  transferGroupsToTransfers
} from "../../utils";
import { findAliquotContainer } from "../../../../utils/plateUtils";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  collectExtendedProperties,
  recordExtendedValuesString
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateEmptyWells } from "../../../../../../tg-iso-lims/src/utils/plateUtils";

const checkForVolumeTransferChange = [
  "transferVolume",
  "transferVolumetricUnitCode",
  "transferVolumes",
  "transferVolumeUnitCodes",
  "universalTransferVolume",
  "universalTransferVolumeUnitCode"
];

class PlateMappings extends Component {
  state = {
    loading: false,
    plateWarning: {}
  };

  generateWorklist = async values => {
    const {
      operationType,
      sourcePlates: minimalSourcePlates,
      sourcePlateFormat,
      destinationPlates,
      combinationDestinationPlates,
      combinationGenerateBarcodes,
      replicationColumnPlates = {},
      consolidationDestinationPlate,
      destinationPlateInfo,
      replicatePlateInfo,
      replicateFormats = {},
      combinationPattern,
      transferVolumetricUnitCode,
      transferVolume,
      breakdownPatterns = {},
      generateBarcodes,
      createEmptyPlates = {},
      universalTransfers,
      universalTransferVolume,
      universalTransferVolumeUnitCode,
      transferVolumes,
      transferVolumeUnitCodes,
      newOrExistingReplicates,
      applyUniversalBreakdownPattern,
      universalDestinationFormat,
      universalDestinationPlateType,
      universalMultichannel,
      universalChannelOrientation,
      universalNewOrExistingDestinationPlates,
      newOrExistingDestinationPlates,
      everyOtherColumn: allEveryOtherColumn = {},
      everyOtherRow: allEveryOtherRow = {},
      universalEveryOtherRow,
      universalEveryOtherColumn,
      universalBreakdownPattern,
      combinationEveryOtherColumn,
      combinationEveryOtherRow,
      multiChannel = {},
      channelOrientation = {},
      newColumnPlateInfo = {},
      combinationMultiChannel,
      combinationChannelOrientation
    } = values;
    const {
      nextStep,
      stepFormProps: { change },
      aliquotContainerTypes = []
    } = this.props;
    const sourcePlateIds = new Set(),
      destinationPlateIds = new Set(),
      sourceAliquotContainerIds = new Set(),
      destinationAliquotContainerIds = new Set(),
      destinationPlateCids = new Set();
    this.setState({
      loading: true
    });

    try {
      const plateReformatWorklist = {
        worklistTransfers: []
      };
      const sourceToDestinationPlateMap = {};
      const toBeCreatedReplicatePlates = [];

      // both combination and replication can use column plates here
      forEach(replicationColumnPlates, columnPlates => {
        columnPlates.forEach(plate => {
          destinationPlateIds.add(plate.id);
        });
      });

      if (operationType === "BREAKDOWN" || operationType === "REPLICATION") {
        forEach(destinationPlates, plates => {
          plates.forEach(plate => {
            destinationPlateIds.add(plate.id);
          });
        });
      } else if (combinationDestinationPlates) {
        combinationDestinationPlates.forEach(plate => {
          destinationPlateIds.add(plate.id);
        });
      }

      const sourcePlates = await safeQuery(containerArrayWorklistFragment, {
        variables: { filter: { id: minimalSourcePlates.map(sp => sp.id) } }
      });

      const plateWarning = {
        emptyPlates: [],
        dryPlates: [],
        isEmpty: false,
        isDry: false
      };
      const drainedAliquotPlates = [];
      sourcePlates.forEach(plate => {
        if (plate.aliquotContainers.every(ac => !ac.aliquot)) {
          plateWarning.isEmpty = true;
          plateWarning.emptyPlates.push(plate.name);
        }
        if (plate.aliquotContainers.some(ac => get(ac, "aliquot.isDry"))) {
          plateWarning.isDry = true;
          plateWarning.dryPlates.push(plate.name);
        }
        if (
          plate.aliquotContainers.some(
            ac => ac.aliquot && !ac.aliquot.isDry && !ac.aliquot.volume
          )
        ) {
          drainedAliquotPlates.push(plate.name);
        }
      });
      // return early to show validation
      if (plateWarning.isEmpty || plateWarning.isDry) {
        return this.setState({
          plateWarning,
          loading: false
        });
      }

      if (drainedAliquotPlates.length) {
        const continueWithTool = await showConfirmationDialog({
          text: `These plates have aliquots with 0 volume: ${drainedAliquotPlates.join(
            ", "
          )}. Would you like to skip those aliquots and continue?`
        });
        if (!continueWithTool) return;
      }
      let allDestinationPlates = [];
      if (destinationPlateIds.size) {
        allDestinationPlates = await safeQuery(containerArrayWorklistFragment, {
          variables: { filter: { id: Array.from(destinationPlateIds) } }
        });
      }
      const consolidationDestinationPlateId = get(
        consolidationDestinationPlate,
        "id"
      );
      let fullConsolidationDestinationPlate;
      if (
        operationType === "CONSOLIDATION" &&
        newOrExistingDestinationPlates === "EXISTING"
      ) {
        destinationPlateIds.add(consolidationDestinationPlateId);
        fullConsolidationDestinationPlate = await safeQuery(
          containerArrayWorklistFragment,
          { variables: { id: consolidationDestinationPlateId } }
        );
      }

      const sourcePlateIdToPlateAliquotContainerCidList = {};
      const usedAliquotContainerCids = [];

      if (operationType === "REPLICATION" || operationType === "BREAKDOWN") {
        sourcePlates.forEach(plate => {
          sourcePlateIdToPlateAliquotContainerCidList[plate.id] = {};
          const plateFormKey = "id" + plate.id;
          const sourcePlateId = plate.id;
          let replicateFormat;
          sourcePlateIds.add(sourcePlateId);
          sourceToDestinationPlateMap[sourcePlateId] = [];
          let newOrExistingValue;
          if (applyUniversalBreakdownPattern) {
            newOrExistingValue = universalNewOrExistingDestinationPlates;
            replicateFormat = universalDestinationFormat;
          } else {
            newOrExistingValue = newOrExistingReplicates[plateFormKey];
            replicateFormat = replicateFormats[plateFormKey];
          }
          if (newOrExistingValue === "EXISTING_COLUMN") {
            replicationColumnPlates[plateFormKey].forEach(plate => {
              sourceToDestinationPlateMap[sourcePlateId].push(
                allDestinationPlates.find(
                  destPlate => destPlate.id === plate.id
                )
              );
            });
          }
          if (newOrExistingValue === "EXISTING") {
            destinationPlates["id" + sourcePlateId].forEach(plate => {
              sourceToDestinationPlateMap[sourcePlateId].push(
                allDestinationPlates.find(
                  destPlate => destPlate.id === plate.id
                )
              );
            });
          } else if (
            newOrExistingValue === "NEW" ||
            newOrExistingValue === "NEW_COLUMN"
          ) {
            let numReplicates;
            let replicatePlateInfoToUse;
            let shouldGenerateBarcode;
            if (operationType === "REPLICATION") {
              if (newOrExistingValue === "NEW_COLUMN") {
                numReplicates = 1;
                replicatePlateInfoToUse = [newColumnPlateInfo[plateFormKey]];
                shouldGenerateBarcode =
                  newColumnPlateInfo[plateFormKey].generateBarcode;
              } else {
                replicatePlateInfoToUse = replicatePlateInfo[plateFormKey];
                shouldGenerateBarcode = generateBarcodes[plateFormKey];
                numReplicates = replicatePlateInfoToUse.length;
              }
            } else {
              replicatePlateInfoToUse = replicatePlateInfo[plateFormKey];
              shouldGenerateBarcode = generateBarcodes[plateFormKey];
              numReplicates =
                get(plate, "containerArrayType.containerFormat.quadrantSize") /
                replicateFormat.quadrantSize;
            }
            let replicatePlateType = universalDestinationPlateType;

            times(numReplicates, i => {
              if (!applyUniversalBreakdownPattern) {
                replicatePlateType = replicatePlateInfoToUse[i].plateType;
              }
              const replicatePlate = {
                cid: shortid(),
                name: replicatePlateInfoToUse[i].name,
                containerArrayTypeId: replicatePlateType.id,
                containerArrayType: replicatePlateType,
                barcode: !shouldGenerateBarcode
                  ? {
                      barcodeString: replicatePlateInfoToUse[i].barcode
                    }
                  : undefined,
                aliquotContainers: generateEmptyWells(
                  replicatePlateType.containerFormat,
                  {
                    aliquotContainerTypeCode:
                      replicatePlateType.aliquotContainerTypeCode
                  }
                )
              };
              sourcePlateIdToPlateAliquotContainerCidList[plate.id][
                replicatePlate.cid
              ] = [];
              destinationPlateCids.add(replicatePlate.cid);
              const replicatePlateWithCids = replicatePlate;
              replicatePlateWithCids.aliquotContainers = replicatePlate.aliquotContainers.map(
                ac => {
                  const cid = shortid();
                  sourcePlateIdToPlateAliquotContainerCidList[plate.id][
                    replicatePlate.cid
                  ].push(cid);
                  return {
                    ...ac,
                    cid
                  };
                }
              );
              toBeCreatedReplicatePlates.push(replicatePlateWithCids);
              sourceToDestinationPlateMap[sourcePlateId].push(
                replicatePlateWithCids
              );
            });
          }
        });
        change("toBeCreatedReplicatePlates", toBeCreatedReplicatePlates);
      }

      const sourceToDestinationPlate2DArrayMap = createSourceToDestinationPlate2DArrayMap(
        sourceToDestinationPlateMap
      );

      if (operationType === "REPLICATION") {
        const destinationPlateIdToLocationMap = {};
        allDestinationPlates.forEach(plate => {
          destinationPlateIdToLocationMap[plate.id] = {};
          plate.aliquotContainers.forEach(ac => {
            destinationPlateIdToLocationMap[plate.id][
              getAliquotContainerLocation(ac)
            ] = ac;
          });
        });
        const replicatePlateIdToLocationMap = {};
        toBeCreatedReplicatePlates.forEach(plate => {
          replicatePlateIdToLocationMap[plate.cid] = {};
          plate.aliquotContainers.forEach(ac => {
            replicatePlateIdToLocationMap[plate.cid][
              getAliquotContainerLocation(ac)
            ] = ac;
          });
        });
        sourcePlates.forEach(plate => {
          sourcePlateIds.add(plate.id);
          plate.aliquotContainers.forEach(ac => {
            if (ac.aliquot?.volume) {
              sourceToDestinationPlateMap[plate.id].forEach(
                ({
                  name: destPlateName,
                  id: destPlateId,
                  cid: destPlateCid,
                  containerArrayType: destinationPlateType
                }) => {
                  let destAc;
                  if (
                    newOrExistingReplicates["id" + plate.id] === "EXISTING" ||
                    newOrExistingReplicates["id" + plate.id] ===
                      "EXISTING_COLUMN"
                  ) {
                    destAc =
                      destinationPlateIdToLocationMap[destPlateId][
                        getAliquotContainerLocation(ac)
                      ];
                  } else {
                    destAc =
                      replicatePlateIdToLocationMap[destPlateCid][
                        getAliquotContainerLocation(ac)
                      ];
                  }
                  sourceAliquotContainerIds.add(ac.id);
                  if (destAc.id) {
                    destinationAliquotContainerIds.add(destAc.id);
                  }
                  plateReformatWorklist.worklistTransfers.push({
                    volume: universalTransfers
                      ? universalTransferVolume
                      : transferVolumes["id" + plate.id],
                    volumetricUnitCode: universalTransfers
                      ? universalTransferVolumeUnitCode
                      : transferVolumeUnitCodes["id" + plate.id],
                    sourceAliquotContainerId: ac.id,
                    sourceAliquotContainer: ac,
                    destinationPlateName: destPlateName,
                    destinationAliquotContainer: {
                      ...destAc,
                      containerArrayType: destinationPlateType
                    },
                    destinationAliquotContainerId: destAc.id || `&${destAc.cid}`
                  });
                }
              );
            }
          });
        });
      }
      if (operationType === "BREAKDOWN") {
        sourcePlates.forEach(plate => {
          const plateFormKey = "id" + plate.id;
          const {
            rowCount: srcRowCount,
            columnCount: srcColCount
          } = plate.containerArrayType.containerFormat;
          let multiChannelForPlate,
            channelOrientationForPlate,
            replicateFormat,
            everyOtherColumn,
            everyOtherRow,
            breakdownPattern;

          if (applyUniversalBreakdownPattern) {
            multiChannelForPlate = universalMultichannel;
            channelOrientationForPlate = universalChannelOrientation;
            replicateFormat = universalDestinationFormat;
            everyOtherColumn = universalEveryOtherColumn;
            everyOtherRow = universalEveryOtherRow;
            breakdownPattern = universalBreakdownPattern;
          } else {
            multiChannelForPlate = multiChannel[plateFormKey];
            channelOrientationForPlate = channelOrientation[plateFormKey];
            replicateFormat = replicateFormats[plateFormKey];
            everyOtherColumn = allEveryOtherColumn[plateFormKey];
            everyOtherRow = allEveryOtherRow[plateFormKey];
            breakdownPattern = breakdownPatterns[plateFormKey];
          }
          sourcePlateIds.add(plate.id);
          const {
            rowCount: repRowCount,
            columnCount: repColCount
          } = replicateFormat;
          if (srcRowCount % repRowCount || srcColCount % repColCount) {
            throw new Error(
              "The number of columns/rows of the source plate is not an integer multiple of the number of columns/rows of the replicate plate."
            );
          }
          const aliquotContainer2dArray = plateTo2dAliquotContainerArray(plate);
          const blockRowCount = srcRowCount / repRowCount;
          const blockColCount = srcColCount / repColCount;
          const transferGroups = [];
          range(repRowCount).forEach(repRowPos => {
            range(repColCount).forEach(repColPos => {
              const block = getBlockOf2dArray(
                aliquotContainer2dArray,
                blockRowCount,
                blockColCount,
                repColPos,
                repRowPos,
                everyOtherColumn,
                everyOtherRow
              );
              blockToAliquotArray(block, breakdownPattern).forEach(
                (aliquotContainer, plateIndex) => {
                  if (!transferGroups[plateIndex])
                    transferGroups[plateIndex] = [];
                  const arrayToPushTo = multiChannelForPlate
                    ? transferGroups[plateIndex]
                    : plateReformatWorklist.worklistTransfers;
                  if (aliquotContainer.aliquot?.volume) {
                    const destinationAliquotContainer = findAliquotContainer(
                      sourceToDestinationPlate2DArrayMap[plate.id][plateIndex],
                      repRowPos,
                      repColPos
                    );
                    sourceAliquotContainerIds.add(aliquotContainer.id);
                    if (destinationAliquotContainer.id) {
                      destinationAliquotContainerIds.add(
                        destinationAliquotContainer.id
                      );
                    }
                    if (destinationAliquotContainer.cid) {
                      usedAliquotContainerCids.push(
                        destinationAliquotContainer.cid
                      );
                    }

                    arrayToPushTo.push({
                      volume: universalTransfers
                        ? universalTransferVolume
                        : transferVolumes[plateFormKey],
                      volumetricUnitCode: universalTransfers
                        ? universalTransferVolumeUnitCode
                        : transferVolumeUnitCodes[plateFormKey],
                      sourceAliquotContainerId: aliquotContainer.id,
                      sourceAliquotContainer: aliquotContainer,
                      destinationPlateName:
                        sourceToDestinationPlateMap[plate.id][plateIndex].name,
                      destinationAliquotContainer: {
                        ...destinationAliquotContainer,
                        containerArrayType:
                          sourceToDestinationPlateMap[plate.id][plateIndex]
                            .containerArrayType
                      },
                      destinationAliquotContainerId:
                        destinationAliquotContainer.id ||
                        `&${destinationAliquotContainer.cid}`
                    });
                  } else if (multiChannelForPlate) {
                    arrayToPushTo.push(undefined);
                  }
                }
              );
            });
          });
          if (transferGroups.length) {
            transferGroupsToTransfers({
              transferGroups,
              channelOrientation: channelOrientationForPlate,
              repRowCount,
              repColCount,
              worklistTransfers: plateReformatWorklist.worklistTransfers
            });
          }
        });

        // now remove destination plates that are not used
        const destPlateCidsToRemove = [];
        sourcePlates.forEach(sourcePlate => {
          const plateFormKey = "id" + sourcePlate.id;
          const shouldCreateEmpty = createEmptyPlates[plateFormKey];
          if (shouldCreateEmpty) return;
          Object.keys(
            sourcePlateIdToPlateAliquotContainerCidList[sourcePlate.id]
          ).forEach(destPlateCid => {
            const aliquotContainerCidList =
              sourcePlateIdToPlateAliquotContainerCidList[sourcePlate.id][
                destPlateCid
              ];
            const wasUsed = aliquotContainerCidList.some(cid =>
              usedAliquotContainerCids.includes(cid)
            );
            if (!wasUsed) destPlateCidsToRemove.push(destPlateCid);
          });
        });
        if (destPlateCidsToRemove.length) {
          const newReplicatePlates = toBeCreatedReplicatePlates.filter(
            plate => !destPlateCidsToRemove.includes(plate.cid)
          );
          change("toBeCreatedReplicatePlates", newReplicatePlates);
        }
      }
      if (operationType === "COMBINATION") {
        let combDestinationPlates;
        if (newOrExistingDestinationPlates === "NEW") {
          const toBeCreatedDestinationPlates = [];
          destinationPlateInfo.forEach(plate => {
            const destinationPlate = {
              cid: shortid(),
              name: plate.name,
              containerArrayTypeId: plate.type.id,
              containerArrayType: plate.type,
              barcode: !combinationGenerateBarcodes
                ? {
                    barcodeString: plate.barcode
                  }
                : undefined,
              aliquotContainers: generateEmptyWells(
                plate.type.containerFormat,
                {
                  aliquotContainerTypeCode: plate.type.aliquotContainerTypeCode
                }
              )
            };
            destinationPlateCids.add(destinationPlate.cid);
            const destinationPlateWithCids = destinationPlate;
            destinationPlateWithCids.aliquotContainers = destinationPlate.aliquotContainers.map(
              ac => {
                return {
                  ...ac,
                  cid: shortid()
                };
              }
            );

            toBeCreatedDestinationPlates.push(destinationPlateWithCids);
          });
          change("toBeCreatedDestinationPlates", toBeCreatedDestinationPlates);
          combDestinationPlates = toBeCreatedDestinationPlates;
        } else if (newOrExistingDestinationPlates === "NEW_COLUMN") {
          const toBeCreatedDestinationPlates = [];
          forEach(newColumnPlateInfo, plateInfo => {
            const destinationPlate = {
              cid: shortid(),
              name: plateInfo.name,
              containerArrayTypeId: plateInfo.plateType.id,
              containerArrayType: plateInfo.plateType,
              barcode: !plateInfo.generateBarcode
                ? {
                    barcodeString: plateInfo.barcode
                  }
                : undefined,
              aliquotContainers: generateEmptyWells(
                plateInfo.plateType.containerFormat,
                {
                  aliquotContainerTypeCode:
                    plateInfo.plateType.aliquotContainerTypeCode
                }
              ).map(ac => {
                return {
                  ...ac,
                  cid: shortid()
                };
              })
            };
            destinationPlateCids.add(destinationPlate.cid);
            toBeCreatedDestinationPlates.push(destinationPlate);
          });
          change("toBeCreatedDestinationPlates", toBeCreatedDestinationPlates);
          combDestinationPlates = toBeCreatedDestinationPlates;
        }
        combDestinationPlates = combDestinationPlates || allDestinationPlates;
        const sourcePlateGroups = chunk(sourcePlates, 4);
        const combinationPlateArray = combDestinationPlates.map(
          (destPlate, i) => ({
            destPlate,
            sourcePlates: sourcePlateGroups[i]
          })
        );
        combinationPlateArray.forEach(({ destPlate, sourcePlates }) => {
          const sortedSourcePlatesAcs = sourcePlates.map(sp =>
            sortBy([...sp.aliquotContainers], ["rowPosition", "columnPosition"])
          );

          const {
            rowCount: srcRowCount,
            columnCount: srcColCount
          } = destPlate.containerArrayType.containerFormat;
          const {
            rowCount: repRowCount,
            columnCount: repColCount
          } = sourcePlateFormat;
          const aliquotContainer2dArray = plateTo2dAliquotContainerArray(
            destPlate
          );
          const blockRowCount = srcRowCount / repRowCount;
          const blockColCount = srcColCount / repColCount;
          const transferGroups = [];
          range(repRowCount).forEach(repRowPos => {
            range(repColCount).forEach(repColPos => {
              const block = getBlockOf2dArray(
                aliquotContainer2dArray,
                blockRowCount,
                blockColCount,
                repColPos,
                repRowPos,
                combinationEveryOtherColumn,
                combinationEveryOtherRow
              );

              const inputAliquotContainers = times(
                4,
                i =>
                  sortedSourcePlatesAcs[i] && sortedSourcePlatesAcs[i].shift()
              );

              blockToAliquotArray(block, combinationPattern).forEach(
                (aliquotContainer, plateIndex) => {
                  if (!transferGroups[plateIndex])
                    transferGroups[plateIndex] = [];
                  const arrayToPushTo = combinationMultiChannel
                    ? transferGroups[plateIndex]
                    : plateReformatWorklist.worklistTransfers;
                  const sourceAc = inputAliquotContainers[plateIndex];
                  if (sourceAc?.aliquot?.volume) {
                    sourceAliquotContainerIds.add(sourceAc.id);
                    if (aliquotContainer.id)
                      destinationAliquotContainerIds.add(aliquotContainer.id);
                    arrayToPushTo.push({
                      volume: transferVolume,
                      volumetricUnitCode: transferVolumetricUnitCode,
                      sourceAliquotContainerId: sourceAc.id,
                      sourceAliquotContainer: sourceAc,
                      destinationPlateName: destPlate.name,
                      destinationAliquotContainer: {
                        ...aliquotContainer,
                        containerArrayType: destPlate.containerArrayType
                      },
                      destinationAliquotContainerId:
                        aliquotContainer.id || `&${aliquotContainer.cid}`
                    });
                  } else if (combinationMultiChannel) {
                    arrayToPushTo.push(undefined);
                  }
                }
              );
            });
          });
          if (transferGroups.length) {
            transferGroupsToTransfers({
              transferGroups,
              channelOrientation: combinationChannelOrientation,
              repRowCount,
              repColCount,
              worklistTransfers: plateReformatWorklist.worklistTransfers
            });
          }
        });
      }
      if (operationType === "CONSOLIDATION") {
        if (newOrExistingDestinationPlates === "NEW") {
          fullConsolidationDestinationPlate = {
            cid: shortid(),
            name: destinationPlateInfo.name,
            containerArrayTypeId: destinationPlateInfo.type.id,
            containerArrayType: destinationPlateInfo.type,
            barcode: !destinationPlateInfo.generateBarcode
              ? {
                  barcodeString: destinationPlateInfo.barcode
                }
              : undefined,
            aliquotContainers: generateEmptyWells(
              destinationPlateInfo.type.containerFormat,
              {
                aliquotContainerTypeCode:
                  destinationPlateInfo.type.aliquotContainerTypeCode
              }
            )
          };
          destinationPlateCids.add(fullConsolidationDestinationPlate.cid);
          const destinationPlateWithCids = fullConsolidationDestinationPlate;
          destinationPlateWithCids.aliquotContainers = fullConsolidationDestinationPlate.aliquotContainers.map(
            ac => {
              return {
                ...ac,
                cid: shortid()
              };
            }
          );
          change("toBeCreatedDestinationPlates", [destinationPlateWithCids]);
        }
        const locationToDestACMap = {};

        fullConsolidationDestinationPlate.aliquotContainers.forEach(ac => {
          locationToDestACMap[getAliquotContainerLocation(ac)] = ac;
        });
        sourcePlates.forEach(plate => {
          plate.aliquotContainers.forEach(ac => {
            if (ac.aliquot?.volume) {
              sourceAliquotContainerIds.add(ac.id);
              const aliquotContainerLocation = getAliquotContainerLocation(ac);
              const destAcId = locationToDestACMap[aliquotContainerLocation].id;
              if (destAcId) {
                destinationAliquotContainerIds.add(destAcId);
              }
              plateReformatWorklist.worklistTransfers.push({
                volume: transferVolume,
                volumetricUnitCode: transferVolumetricUnitCode,
                sourceAliquotContainerId: ac.id,
                sourceAliquotContainer: ac,
                destinationPlateName: fullConsolidationDestinationPlate.name,
                destinationAliquotContainer: {
                  ...locationToDestACMap[aliquotContainerLocation],
                  containerArrayType:
                    fullConsolidationDestinationPlate.containerArrayType
                },
                destinationAliquotContainerId:
                  destAcId ||
                  `&${locationToDestACMap[aliquotContainerLocation].cid}`
              });
            }
          });
        });
      }

      // ** handle extended properties **
      // destinationAliquotContainerIds
      // sourceAliquotContainerIds
      // sourcePlateIds
      // destinationPlateIds
      const sourceAliquotExtendedProperties = {};
      const sourceContainerArrayExtendedProperties = {};
      const destinationAliquotExtendedProperties = {};
      const destinationContainerArrayExtendedProperties = {};

      if (sourcePlateIds.size || destinationPlateIds.size) {
        const containerArrays = await safeQuery(
          ["containerArray", `id ${recordExtendedValuesString}`],
          {
            variables: {
              filter: {
                id: Array.from(sourcePlateIds).concat(
                  Array.from(destinationPlateIds)
                )
              }
            }
          }
        );
        containerArrays.forEach(c => {
          if (sourcePlateIds.has(c.id)) {
            collectExtendedProperties(
              c,
              sourceContainerArrayExtendedProperties
            );
          }
          if (destinationPlateIds.has(c.id)) {
            collectExtendedProperties(
              c,
              destinationContainerArrayExtendedProperties
            );
          }
        });
      }
      if (
        sourceAliquotContainerIds.size ||
        destinationAliquotContainerIds.size
      ) {
        const aliquotContainers = await safeQuery(
          [
            "aliquotContainer",
            `id aliquot { id ${recordExtendedValuesString} }`
          ],
          {
            variables: {
              filter: {
                id: Array.from(sourceAliquotContainerIds).concat(
                  Array.from(destinationAliquotContainerIds)
                )
              }
            }
          }
        );
        aliquotContainers.forEach(ac => {
          if (ac.aliquot) {
            if (sourceAliquotContainerIds.has(ac.id)) {
              collectExtendedProperties(
                ac.aliquot,
                sourceAliquotExtendedProperties
              );
            }
            if (destinationAliquotContainerIds.has(ac.id)) {
              collectExtendedProperties(
                ac.aliquot,
                destinationAliquotExtendedProperties
              );
            }
          }
        });
      }
      change("sourcePlateIds", Array.from(sourcePlateIds));
      change("destinationPlateIds", Array.from(destinationPlateIds));
      change("destinationPlateCids", Array.from(destinationPlateCids));
      change(
        "sourceAliquotExtendedProperties",
        Object.values(sourceAliquotExtendedProperties)
      );
      change(
        "sourceContainerArrayExtendedProperties",
        Object.values(sourceContainerArrayExtendedProperties)
      );
      change(
        "destinationAliquotExtendedProperties",
        Object.values(destinationAliquotExtendedProperties)
      );
      change(
        "destinationContainerArrayExtendedProperties",
        Object.values(destinationContainerArrayExtendedProperties)
      );
      change("sourceToDestinationPlateMap", sourceToDestinationPlateMap);
      change("worklist", plateReformatWorklist);
      const error = validateTransfers(
        plateReformatWorklist,
        aliquotContainerTypes
      );
      const isWarning = error
        ?.trim()
        .split("\n")
        .every(e => e.includes("below dead volume"));
      if (error && !isWarning) {
        this.setState({
          loading: false
        });
        throw new Error(error);
      } else {
        change("warning", isWarning ? error.trim() : null);
        nextStep();
      }
    } catch (error) {
      console.error("error:", error);
      this.setState({
        loading: false
      });
      window.toastr.error("Error generating worklist.");
      throw new SubmissionError({
        _error: error.message || "Error generating worklists."
      });
    }
  };

  render() {
    const { plateWarning = {}, loading } = this.state;
    const {
      stepFormProps,
      operationType,
      containerFormats = [],
      containerArrayTypes = [],
      toolSchema,
      Footer,
      footerProps,
      handleSubmit
    } = this.props;
    const operationCompMap = {
      REPLICATION: Replication,
      BREAKDOWN: Breakdown,
      COMBINATION: Combination,
      CONSOLIDATION: Consolidation
    };
    const OperationComp = operationCompMap[operationType];

    return (
      <React.Fragment>
        <div>
          {operationType && (
            <OperationComp
              {...{
                stepFormProps,
                toolSchema,
                containerFormats,
                containerArrayTypes
              }}
            />
          )}
        </div>
        {(plateWarning.isEmpty || plateWarning.isDry) && (
          <div>
            {plateWarning.isEmpty && (
              <Callout intent={Intent.DANGER} style={{ marginBottom: 10 }}>
                {`The following plates are empty: ${plateWarning.emptyPlates.join(
                  ", "
                )}. Please return to Step
            1 and select plates with at least one aliquot.`}
              </Callout>
            )}
            {plateWarning.isDry && (
              <Callout intent={Intent.DANGER}>
                {`The following plates contain dry aliquots: ${plateWarning.dryPlates.join(
                  ", "
                )}. Please rehydrate before selecting these plates as inputs.`}
              </Callout>
            )}
          </div>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              loading={loading}
              intent={Intent.PRIMARY}
              onClick={handleSubmit(this.generateWorklist)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "operationType",
    "sourcePlates",
    ...checkForVolumeTransferChange
  ),
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  }),
  withQuery(containerArrayTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        filter: {
          containerFormatCode: [
            "96_WELL",
            "384_WELL",
            "48_WELL",
            "24_WELL",
            "12_WELL",
            "6_WELL",
            "1536_WELL"
          ],
          isPlate: true
        }
      }
    }
  }),
  withQuery(containerFormatFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        filter: {
          code: ["6_WELL", "24_WELL", "96_WELL", "384_WELL", "1536_WELL"]
        }
      }
    }
  })
)(PlateMappings);
