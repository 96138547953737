/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { BlueprintError } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { compose } from "recompose";
import { tgFormValues } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { validateTransfers } from "../../../../utils";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import { isEmpty } from "lodash";

const worklistTransferSchema = {
  model: "worklist",
  fields: [
    {
      path: "source",
      displayName: "Source"
    },
    {
      path: "sourceBarcode",
      displayName: "Source Barcode"
    },
    {
      displayName: "Source Position",
      path: "sourcePosition"
    },
    {
      displayName: "Destination",
      path: "destinationPlateName"
    },
    {
      displayName: "Destination Position",
      path: "destinationPosition"
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    }
  ]
};

class ReviewWorklist extends Component {
  render() {
    const {
      toolSchema,
      worklist,
      aliquotContainerTypes = [],
      Footer,
      footerProps
    } = this.props;
    if (!worklist) return null;
    const error = validateTransfers(worklist, aliquotContainerTypes);
    const valid = isEmpty(error);
    return (
      <React.Fragment>
        <ReviewWorklistSection
          toolSchema={toolSchema}
          schema={worklistTransferSchema}
        />
        <BlueprintError error={error} />
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              disabled={!valid}
              loading={footerProps.submitting}
            >
              Generate Worklist
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  }),
  tgFormValues("worklist")
)(ReviewWorklist);
