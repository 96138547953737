/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { types } from "mobx-state-tree";
import assayStore from "./assays";
import { importJobStore } from "./importJobs";
import { modalStore } from "./modal";
import { helperStore } from "./helper";
// import { visualizationStore } from "./visualization";
import { alertStore } from "./alert";
import { withPropsStore } from "./withProps";
import { testHelperContent } from "../../src-test/configs/config.json";
import { statisticalAnalysisStore } from "./dataAnalysis/statisticalAnalysis";

const mainStore = types.model("mainStore", {
  assays: types.optional(assayStore, {}),
  importJobs: types.optional(importJobStore, {}),
  modal: types.optional(modalStore, {}),
  helper: types.optional(helperStore, { categories: testHelperContent }),
  alert: types.optional(alertStore, {}),
  statisticalAnalysis: types.optional(statisticalAnalysisStore, {}),
  common: types.optional(withPropsStore, {})
});

export default mainStore;
