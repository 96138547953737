/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DataTable, CollapsibleCard, withTableParams } from "@teselagen/ui";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";
import { compose } from "recompose";
import withQuery from "../../../../src-shared/withQuery";
import gql from "graphql-tag";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";

function RegisteredSamplesCard({ tableParams }) {
  return (
    <CollapsibleCard title="Samples">
      <DataTable {...tableParams} noPadding onDoubleClick={routeDoubleClick} />
    </CollapsibleCard>
  );
}

const schema = {
  model: "sample",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Sample Type",
      path: "sampleType.name"
    },
    dateModifiedColumn
  ]
};

const fragment = gql`
  fragment materialRecordSamplesFragment on sample {
    id
    name
    sampleType {
      code
      name
    }
    updatedAt
  }
`;

export default compose(
  withTableParams({
    urlConnected: false,
    formName: "materialRecordSamplesCard",
    schema,
    additionalFilter: (props, qb) => {
      qb.whereAll({
        materialId: props.materialId
      });
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(RegisteredSamplesCard);
