/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import SelectReactionMap, {
  updateReactionMapReactionMapFragment,
  updateReactionMapDataTableFragment,
  updateReactionMapReagentFragment
} from "./Steps/SelectReactionMap";
import UpdateReactions from "./Steps/UpdateReactions";
import ReviewReactions from "./Steps/ReviewReactions";

function UpdateReagentReactionMap(props) {
  const {
    initialValues,
    toolSchema,
    toolIntegrationProps,
    isToolIntegrated
  } = props;

  async function onSubmit(values) {
    const { reactionMap, addReagentsForReaction = {} } = values;

    try {
      const newReactionInputs = [];
      reactionMap.reactions.forEach(r => {
        const existingInputReagentIds = [];
        r.reactionInputs.forEach(input => {
          if (input.inputAdditiveMaterialId) {
            existingInputReagentIds.push(input.inputAdditiveMaterialId);
          }
        });
        const newReagentIds = addReagentsForReaction["id" + r.id] || [];
        const filteredNewIds = newReagentIds.filter(
          id => !existingInputReagentIds.includes(id)
        );
        if (filteredNewIds.length) {
          filteredNewIds.forEach(reagentId => {
            newReactionInputs.push({
              reactionId: r.id,
              inputAdditiveMaterialId: reagentId
            });
          });
        }
      });

      await safeUpsert("reactionInput", newReactionInputs);

      return {
        reactionMap
      };
    } catch (error) {
      console.error("err:", error);
      throwFormError("Error creating table.");
    }
  }

  const steps = [
    {
      title: "Select Reaction Map and Reagents",
      Component: SelectReactionMap,
      withCustomFooter: true
    },
    {
      title: "Update Reactions",
      Component: UpdateReactions,
      withCustomFooter: true
    },
    {
      title: "Review Reaction Map",
      Component: ReviewReactions
    }
  ];

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      enableReinitialize={isToolIntegrated}
      initialValues={initialValues}
    />
  );
}

export default compose(
  withWorkflowInputs(updateReactionMapReactionMapFragment, {
    singular: true
  }),
  withWorkflowInputs(updateReactionMapDataTableFragment),
  withWorkflowInputs(updateReactionMapReagentFragment)
)(UpdateReagentReactionMap);
