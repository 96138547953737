/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "recompose";
import { withSelectedEntities } from "@teselagen/ui";
import { connect } from "react-redux";
import InspectAliquotContainerDialog from "../components/Dialogs/InspectAliquotContainerDialog";

const mapStateToProps = (state, { aliquotContainer }) => {
  if (aliquotContainer.aliquot) {
    return {
      initialValues: {
        aliquot_id: aliquotContainer.aliquot.id,
        volume: aliquotContainer.aliquot.volume
      }
    };
  }
  return {
    initialValues: {}
  };
};

export default compose(
  connect(mapStateToProps),
  withSelectedEntities({
    formName: "aliquotsDisplayTable",
    name: "selectedAliquots"
  }),
  withSelectedEntities({
    formName: "tubesDisplayTable",
    name: "selectedTubes"
  })
)(InspectAliquotContainerDialog);
