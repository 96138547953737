/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Button, Tooltip } from "@blueprintjs/core";
import { showDialog } from "../../src-shared/GlobalDialog";
import SelectPlateWellsDialog from "./Dialogs/SelectPlateWellsDialog";

function ChooseAndClearWellsForPlate({
  containerArray,
  selectedWells = [],
  clearWells,
  notOptional,
  chooseWells
}) {
  return (
    <div className="tg-flex">
      {!!selectedWells.length && (
        <Button icon="trash" intent="danger" onClick={clearWells} minimal />
      )}
      <Tooltip
        disabled={!!selectedWells.length || notOptional}
        content="Entire plate will be prepped by default."
      >
        <Button
          style={{ marginLeft: 3 }}
          minimal
          intent="primary"
          text={
            selectedWells.length
              ? `Change Wells (${selectedWells.length} Selected)`
              : "Choose Wells" + (notOptional ? "" : " (optional)")
          }
          onClick={() => {
            showDialog({
              ModalComponent: SelectPlateWellsDialog,
              modalProps: {
                containerArray,
                locations: selectedWells,
                onSelect: chooseWells
              }
            });
          }}
        />
      </Tooltip>
    </div>
  );
}

export default ChooseAndClearWellsForPlate;
