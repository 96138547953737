/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { trim } from "lodash";
import { withProps } from "recompose";
import { Classes } from "@blueprintjs/core";
import { TextareaField, DialogFooter } from "@teselagen/ui";
import PropTypes from "prop-types";
import { wrapDialog } from "@teselagen/ui";

class CustomTableSearch extends Component {
  static propTypes = {
    tableParams: PropTypes.object.isRequired
  };

  onSubmit = ({ searchValue }) => {
    const { tableParams, hideModal } = this.props;
    const searchTerm = searchValue
      .split(/\n|\t/g)
      .map(trim)
      .filter(v => v)
      .join(",");
    tableParams.setSearchTerm(searchTerm);
    hideModal();
  };

  render() {
    const { handleSubmit, hideModal } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <div className={Classes.TEXT_MUTED} style={{ marginBottom: 15 }}>
            Text on different lines will be run as separate queries. For
            example, if you would like to search for two different samples with
            names "Sample 1" and "Sample 2" you would enter:
            <br />
            <br />
            Sample 1<br />
            Sample 2
          </div>
          <TextareaField
            style={{ height: 250 }}
            name="searchValue"
            isRequired
          />
        </div>
        <DialogFooter
          onClick={handleSubmit(this.onSubmit)}
          hideModal={hideModal}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Multi-line Search"
  }),
  withProps(props => {
    if (
      props.tableParams.searchTerm &&
      !props.tableParams.searchTerm.includes("bp_")
    ) {
      return {
        initialValues: {
          searchValue: props.tableParams.searchTerm.replace(/,/g, "\n")
        }
      };
    }
  }),
  reduxForm({
    form: "customTableSearch"
  })
)(CustomTableSearch);
