/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";
import stripFields from "../../src-shared/utils/stripFields";
import gql from "graphql-tag";
import { download } from "../../src-shared/utils/downloadTest";
import { map, isEmpty } from "lodash";

import { workflowToolFragment } from "../components/NewWorkflowRun/newWorkflowRunFragment";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../src-shared/apolloMethods";

import {
  getIoItemFromTaskIoType,
  importWorkflowDefinition
} from "../../../tg-iso-lims/src/utils/workflowUtils";
import { handleZipFiles } from "../../../tg-iso-shared/src/utils/fileUtils";

const duplicateWorkflowDefinitionFragment = gql`
  fragment duplicateWorkflowDefinitionFragment on workflowDefinition {
    id
    name
    description
    workflowToolDefinitions {
      id
      name
      index
      toolCode
      validateBarcodes
      wtdExtendedProperties {
        id
        extendedPropertyId
      }
      workflowToolInputDefinitions {
        id
        itemCode
        label
        isList
        isDisabled
        taskIoTypeCode
        wtInputDefToOutputDefs {
          id
          workflowToolInputDefinitionId
          workflowToolOutputDefinitionId
        }
      }
      workflowToolOutputDefinitions {
        id
        itemCode
        label
        isList
        taskIoTypeCode
      }
      workflowToolSettingDefinitions {
        id
        name
        isPresetValue
        value
        workflowToolSettingDefinitionTypeCode
        integration {
          id
          name
          integrationTypeCode
        }
      }
    }
  }
`;

export function getIsStartTask(_task) {
  const task = _task.workflowToolDefinition || _task;
  return task.toolCode === "workflowStartTask";
}

export async function exportWorkflowDefinition(workflowDefinitionIdOrIds) {
  try {
    const workflowDefinitions = await safeQuery(
      duplicateWorkflowDefinitionFragment,
      {
        variables: {
          filter: {
            id: workflowDefinitionIdOrIds
          }
        }
      }
    );
    const strippedWDs = stripFields(workflowDefinitions, ["__typename"]);
    if (strippedWDs.length === 1) {
      download(
        JSON.stringify(strippedWDs[0], null, 2),
        strippedWDs[0].name + ".json",
        "application/json"
      );
    } else {
      const files = [];
      strippedWDs.forEach(strippedWD => {
        files.push({
          name: `${strippedWD.name}.json`,
          data: JSON.stringify(strippedWD, null, 2)
        });
      });
      download(
        await handleZipFiles(files),
        "workflow-definitions.zip",
        "application/zip"
      );
    }
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error exporting workflow definition");
  }
}

export async function duplicateWorkflowDefinition(
  workflowDefinitionId,
  options = {}
) {
  try {
    const workflowDefinition = await safeQuery(
      duplicateWorkflowDefinitionFragment,
      {
        variables: {
          id: workflowDefinitionId
        }
      }
    );
    const strippedWD = stripFields(workflowDefinition, ["__typename"]);
    return await importWorkflowDefinition(strippedWD, {
      newName: options.newName || workflowDefinition.name + " copy"
    });
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error duplicating workflow definition");
  }
}

export function convertToolSchemaInputToToolDefinition({
  toolSchema,
  taskIoTypes
}) {
  const { input = {}, output = {}, settings = {} } = toolSchema;
  const { taskIoTypesByDataItemTypeCode, taskIoTypesByInventoryItemTypeCode } =
    getTaskIoTypeMaps(taskIoTypes);
  const [workflowToolInputDefinitions, workflowToolOutputDefinitions] = [
    input,
    output
  ].map(({ ioItems = {} }) => {
    return map(
      ioItems,
      (
        { label, isList, dataItemTypeCode, inventoryItemTypeCode },
        itemCode
      ) => {
        const taskIoType = dataItemTypeCode
          ? taskIoTypesByDataItemTypeCode[dataItemTypeCode]
          : taskIoTypesByInventoryItemTypeCode[inventoryItemTypeCode];
        /* eslint-disable no-debugger */
        if (!taskIoType) debugger;
        /* eslint-enable no-debugger */
        return {
          label,
          itemCode,
          isList,
          taskIoTypeCode: taskIoType.code
        };
      }
    );
  });
  const workflowToolSettingDefinitions = map(
    settings,
    (
      { label, canAdjustPresetSettings, workflowToolSettingDefinitionTypeCode },
      name
    ) => {
      let nameToUse = name;
      if (
        workflowToolSettingDefinitionTypeCode === "MARKDOWN" ||
        workflowToolSettingDefinitionTypeCode === "INTEGRATION"
      ) {
        nameToUse = label;
      }
      return {
        name: nameToUse,
        isPresetValue: canAdjustPresetSettings,
        workflowToolSettingDefinitionTypeCode:
          workflowToolSettingDefinitionTypeCode || "GENERIC"
      };
    }
  );
  return {
    workflowToolInputDefinitions,
    workflowToolOutputDefinitions,
    workflowToolSettingDefinitions
  };
}

function getTaskIoTypeMaps(taskIoTypes) {
  const taskIoTypesByDataItemTypeCode = {};
  const taskIoTypesByInventoryItemTypeCode = {};

  taskIoTypes.forEach(taskIoType => {
    const { dataItemType, inventoryItemType } = taskIoType;
    if (dataItemType) {
      taskIoTypesByDataItemTypeCode[dataItemType.code] = taskIoType;
    } else if (inventoryItemType) {
      taskIoTypesByInventoryItemTypeCode[inventoryItemType.code] = taskIoType;
    }
  });
  return {
    taskIoTypesByDataItemTypeCode,
    taskIoTypesByInventoryItemTypeCode
  };
}

export function computeTaskSettingDefDirtyState(
  dirtySettingDefs,
  settingDef,
  dirty
) {
  if (dirty && !dirtySettingDefs[settingDef.id]) {
    dirtySettingDefs[settingDef.id] = true;
  } else if (!dirty && dirtySettingDefs[settingDef.id]) {
    delete dirtySettingDefs[settingDef.id];
  }
  const newOverallDirty = !isEmpty(dirtySettingDefs);
  return newOverallDirty;
}

export async function createMissingIoItemsForTool(workflowTool) {
  const workflowToolInputIoItemsToDelete = [];
  const workflowToolInputIoItemToCreate = [];
  const workflowToolOutputsToUpdate = [];
  const ioItemsToCreate = [];
  workflowTool.workflowToolInputs.forEach(workflowToolInput => {
    const isList = workflowToolInput.workflowToolInputDefinition.isList;
    if (
      !workflowToolInput.workflowToolInputIoItems.length ||
      workflowToolInput.workflowToolInputIoItems.some(wti => !wti.ioItem)
    ) {
      workflowToolInput.workflowToolInputIoItems.forEach(inputIoItem => {
        workflowToolInputIoItemsToDelete.push(inputIoItem.id);
      });
      workflowToolInputIoItemToCreate.push({
        wtiId: workflowToolInput.id,
        isList,
        ...getIoItemFromTaskIoType(
          null,
          workflowToolInput.workflowToolInputDefinition.taskIoTypeCode
        )
      });
    }
  });
  workflowTool.workflowToolOutputs.forEach(workflowToolOutput => {
    const isList = workflowToolOutput.workflowToolOutputDefinition.isList;
    if (!workflowToolOutput.ioItem) {
      const cid = shortid();
      ioItemsToCreate.push({
        cid: cid,
        isList,
        ...getIoItemFromTaskIoType(
          null,
          workflowToolOutput.workflowToolOutputDefinition.taskIoTypeCode
        )
      });
      workflowToolOutputsToUpdate.push({
        id: workflowToolOutput.id,
        ioItemId: `&${cid}`
      });
    }
  });
  if (
    ioItemsToCreate.length ||
    workflowToolInputIoItemToCreate.length ||
    workflowToolInputIoItemsToDelete.length ||
    workflowToolOutputsToUpdate.length
  ) {
    await safeDelete(
      "workflowToolInputIoItem",
      workflowToolInputIoItemsToDelete
    );
    await safeUpsert(
      "workflowToolInputIoItem",
      workflowToolInputIoItemToCreate
    );
    await safeUpsert("ioItem", workflowToolInputIoItemToCreate);
    await safeUpsert("workflowToolOutput", workflowToolOutputsToUpdate);
    const updatedWorkflowTool = await safeQuery(workflowToolFragment, {
      variables: {
        id: workflowTool.id
      }
    });
    return updatedWorkflowTool;
  } else {
    return workflowTool;
  }
}
