/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Dialog } from "@blueprintjs/core";
import { TextareaField } from "@teselagen/ui";
import { reduxForm } from "redux-form";

class BlastFeedbackContainer extends React.Component {
  render() {
    const { hideModal } = this.props;
    return (
      <div>
        {/*eslint-disable-next-line local-eslint-plugin/no-direct-dialog*/}
        <Dialog
          canOutsideClickClose={false}
          isOpen
          onClose={() => hideModal()}
          title="Blast Tool"
          style={{ width: 900 }}
        >
          <div className="bp3-dialog-body" style={{ height: 200, width: 300 }}>
            <TextareaField
              style={{
                display: "block",
                width: "850px",
                height: "200px",
                maxWidth: "500%"
              }}
              name="output"
              defaultValue={this.props.feedback.data}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}
export default reduxForm({
  form: "blastFeedbackContainerForm"
})(BlastFeedbackContainer);
