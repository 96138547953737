/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { MenuItem } from "@blueprintjs/core";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import UploadWorklistsDialog from "./UploadWorklistsDialog";
import WorklistRecordView from "../../Record/WorklistRecordView";

function WorklistsInventory(props) {
  return (
    <AbstractLibrary
      {...props}
      showUploadFunction={() => {
        showDialog({
          ModalComponent: UploadWorklistsDialog,
          modalProps: {
            refetch: props.refetch
          }
        });
      }}
      RecordViewInspector={WorklistRecordView}
      generateExtraContextMenuItems={worklists => {
        const showExportDialog = () => {
          showDialog({
            modalType: "EXPORT_WORKLIST",
            modalProps: {
              worklistIds: worklists.map(w => w.id)
            }
          });
        };
        return (
          <MenuItem
            key="export"
            icon="export"
            text="Export"
            onClick={showExportDialog}
          />
        );
      }}
      noNewItem
      libraryTitle="Worklists"
      model="worklist"
      isLibraryTable
      withExport
    />
  );
}

const schema = {
  model: "worklist",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "worklistStatus.name", type: "string", displayName: "Status" }
  ]
};

const fragment = gql`
  fragment worklistHeaderFragment on worklist {
    id
    name
    worklistStatus {
      code
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(WorklistsInventory);
