/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { isPlainObject, isArray } from "lodash";
import { JSON_DATATYPE_FIELDS } from "../constants/designStateConstants";

const isStrictObject = obj =>
  obj !== null && !isArray(obj) && isPlainObject(obj);

const recurseOnJson = (json, fn, options = {}) => {
  const { callOnObjectsOnly = false } = options;
  const isValueStrictObject = isStrictObject(json);
  if (!callOnObjectsOnly) fn(json);
  else if (isValueStrictObject) fn(json);

  if (isValueStrictObject || isArray(json)) {
    Object.entries(json).forEach(([key, val]) => {
      if (JSON_DATATYPE_FIELDS[key]) {
        return "we shouldn't recurse on these json object fields";
      }
      return recurseOnJson(val, fn, options);
    });
  }
};

export default recurseOnJson;
