/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { drawSigmaAminoAcidHistogram } from "./d3/sigmaAminoAcidHistogram";
import modelSigmaAminoAcidHistogramWidgetStyles from "./ModelSigmaAminoAcidHistogramWidgetStyles.module.css";
import ReactResizeDetector from "react-resize-detector";

const TAG = "[ModelGroupedHistogramWidget]"; // eslint-disable-line

class ModelSigmaAminoAcidFreqHistogramWidget extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    const formattedData = this.formatData(data);
    const maxYValue =
      Math.round(
        Math.max(
          ...Object.values(formattedData).map(sigmaGroup =>
            Math.max(
              ...sigmaGroup.map(tokenGroup =>
                Math.max(
                  ...tokenGroup.values.map(tokenElement => tokenElement.value)
                )
              )
            )
          )
        )
      ) + 1;
    const chartContainerClass =
      "_" +
      Math.random()
        .toString(36)
        .slice(2, 6);
    this.state = {
      chartContainerClass: chartContainerClass,
      formattedData: formattedData,
      defaultSigmaToPlot: formattedData[data.output_data[0].sigma],
      maxYValue
    };
  }

  formatData = unformattedData => {
    const { input_data, output_data } = unformattedData;

    const formattedData = {};
    const densityHistogram = Object.keys(
      output_data[0]["tokens_histogram"][0]
    ).includes("density");

    const inputDataByToken = {};
    input_data[0]["tokens_histogram"].forEach(datapoint => {
      inputDataByToken[datapoint.label] = {
        type: "input",
        value: densityHistogram ? datapoint.density * 100 : datapoint.frequency
      };
    });
    output_data.forEach(outputPoint => {
      formattedData[outputPoint.sigma] = [];
      const outputDataByTokenBySigma = {};
      outputPoint["tokens_histogram"].forEach(datapoint => {
        outputDataByTokenBySigma[datapoint.label] = {
          type: "output",
          value: densityHistogram
            ? datapoint.density * 100
            : datapoint.frequency
        };
      });
      Object.keys(inputDataByToken).forEach(token => {
        formattedData[outputPoint.sigma].push({
          token,
          values: [inputDataByToken[token], outputDataByTokenBySigma[token]]
        });
      });
    });

    return formattedData;
  };

  componentDidMount() {
    const {
      chartContainerClass,
      formattedData,
      defaultSigmaToPlot
    } = this.state;
    const { sigmaToPlot } = this.props;

    let dataToPlot = defaultSigmaToPlot;

    if (sigmaToPlot) {
      dataToPlot = formattedData[sigmaToPlot];
    }

    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;

    drawSigmaAminoAcidHistogram(
      chartContainerClass,
      height,
      width,
      dataToPlot,
      this.state.maxYValue
    );
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      width: this.divElement.clientWidth,
      height: this.divElement.clientHeight
    });
  };

  render() {
    const {
      chartContainerClass,
      formattedData,
      defaultSigmaToPlot
    } = this.state;
    const { sigmaToPlot } = this.props;

    let dataToPlot = defaultSigmaToPlot;

    if (sigmaToPlot) {
      dataToPlot = formattedData[sigmaToPlot];
    }

    if (this.divElement) {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;
      drawSigmaAminoAcidHistogram(
        chartContainerClass,
        height,
        width,
        dataToPlot,
        this.state.maxYValue
      );
    }

    return (
      <div
        className={modelSigmaAminoAcidHistogramWidgetStyles.container}
        ref={divElement => (this.divElement = divElement)}
      >
        <div
          className={`${modelSigmaAminoAcidHistogramWidgetStyles.viz} ${chartContainerClass}`}
        />
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.updateDimensions}
          targetDomEl={this.divElement}
        />
      </div>
    );
  }
}

export default ModelSigmaAminoAcidFreqHistogramWidget;
