/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { saveSvgAsPng } from "save-svg-as-png";

import { Button, Tab, Tabs } from "@blueprintjs/core";
import { COLORS, COLOR_SQUARE_SIZE } from "../constants";
import ColorSquare from "./ColorSquare";
import schematicToMutationInput from "../../../../../tg-iso-design/designImport/schematicToMutationInput";
import performOldStyleMutations from "../../../../../tg-iso-design/designImport/performOldStyleMutations";

import "./style.css";
import IconItem from "./IconItem";
import { IconsArray } from "./IconsItemsArray";

class SchematicInspector extends React.Component {
  convertToDesign = async () => {
    const { schematic, history } = this.props;
    try {
      const mutations = schematicToMutationInput(schematic);
      const { designId } = await performOldStyleMutations({ mutations });

      if (designId) {
        history.push(`/designs/${designId}`);
      } else {
        throw new Error("convert to design failed");
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating design.");
    }
  };

  exportAsPng = () => {
    const { schematic } = this.props;

    saveSvgAsPng(
      document.getElementById("schematic-edit-svg"),
      `${schematic.name}.png`,
      { scale: 2 }
    );
  };

  exportAsJpeg = () => {
    const { schematic } = this.props;

    const svg = document.getElementById("schematic-edit-svg");

    saveSvgAsPng(svg, `${schematic.name}.jpeg`, {
      encoderType: "image/jpeg",
      backgroundColor: "#ffffff",
      scale: 2
    });
  };

  render() {
    const { readOnly } = this.props;
    return (
      <div className="schematic-inspector-container">
        <Tabs id="schematicsTabs">
          {!readOnly && (
            <Tab
              id="icons"
              title="Icons"
              panel={<IconsPanel readOnly={readOnly} />}
            />
          )}
          {!readOnly && (
            <Tab id="colors" title="Colors" panel={<ColorsPanel />} />
          )}
          <Tab
            id="export"
            title="Export"
            panel={
              <ExportPanel
                convertToDesign={this.convertToDesign.bind(this)}
                exportAsPng={this.exportAsPng.bind(this)}
                exportAsJpeg={this.exportAsJpeg.bind(this)}
              />
            }
          />
        </Tabs>
      </div>
    );
  }
}

export default SchematicInspector;

/**
 * Panels renders
 */

//Icons Panel
const IconsPanel = () => {
  return (
    <div>
      <span className="schematic-panel-title">
        Drag & Drop icons into the <strong>backbone</strong>
      </span>
      <div className="schematic-icons-container">
        {IconsArray.map((item, index) => {
          const Shape = item.shape;
          return (
            <IconItem key={`item-icon-${index}`} title={item.shapeName}>
              <Shape
                {...{
                  ...item.shapeProps,
                  key: `item-icon-${item.shapeType}`
                }}
              />
            </IconItem>
          );
        })}
      </div>
    </div>
  );
};

//Colors Panel
const ColorsPanel = () => {
  return (
    <div className="schematic-inspector-colors-container">
      <span className="schematic-panel-title">
        Drag & Drop a color into an <strong>icon</strong> in the backbone
      </span>
      {COLORS.map(fill => (
        <svg
          key={fill}
          height={COLOR_SQUARE_SIZE + 2}
          width={COLOR_SQUARE_SIZE + 2}
          style={{ margin: 1 }}
        >
          <ColorSquare x={1} y={1} fill={fill} />
        </svg>
      ))}
    </div>
  );
};

//Export Panel
const ExportPanel = props => {
  const { convertToDesign, exportAsPng, exportAsJpeg } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Button
        minimal
        intent="primary"
        icon="send-to-graph"
        text="Convert to Design"
        onClick={convertToDesign}
      />
      <Button
        minimal
        intent="primary"
        icon="export"
        text="Export as PNG"
        onClick={exportAsPng}
      />
      <Button
        minimal
        intent="primary"
        icon="export"
        text="Export as JPEG"
        onClick={exportAsJpeg}
      />
    </div>
  );
};
