/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InfoHelper } from "@teselagen/ui";
import styled from "styled-components";

const InfoSignContainer = styled.div`
  display: flex;
  justify-content: left;
  & > div {
    margin-left: 5px;
    line-height: 0;
  }
`;

const MoreInfoLabel = styled.span`
  font-size: 11px;
`;

// TODO: Check if it can be moved to common src-shared components.
const LabelWithTooltip = ({
  label,
  tooltip,
  icon,
  intent,
  onClick,
  tooltipMargin = 5
}) => (
  <InfoSignContainer disabled={!onClick}>
    {label}
    {tooltip && (
      <InfoHelper
        style={{ marginLeft: tooltipMargin }}
        icon={icon}
        intent={intent}
        // ref={} // This can probably be useful for hiding the tooltip with clicked.
        content={
          <div>
            <span>{tooltip}</span>
            {onClick && (
              <>
                <br />
                <br />
                <MoreInfoLabel>Click icon for more information.</MoreInfoLabel>
              </>
            )}
          </div>
        }
        size={12}
        onClick={onClick}
      />
    )}
  </InfoSignContainer>
);

export default LabelWithTooltip;
