/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment qualityControlCheckFragment on qualityControlCheck {
    id
    name
    description
    updatedAt
  }
`;
