/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import NewGenomeDialog from "../../Dialogs/NewGenomeDialog";

const schema = {
  model: "genome",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "primaryReferenceSequence.name",
      type: "string",
      displayName: "Genomic Reference Sequence"
    }
  ]
};

const GenomeLibrary = ({
  genomes,
  genomesCount,
  genomesError,
  genomesLoading,
  history,
  integrationSubtype,
  libraryFragment,
  location,
  loggedIn,
  model,
  refetch,
  refetchGenomes,
  schema,
  selectTableRecords,
  selectedEntities,
  tableParams,
  withDisplayOptions,
  withSelectedEntities
}) => {
  return (
    <AbstractLibrary
      genomes={genomes}
      genomesCount={genomesCount}
      genomesError={genomesError}
      genomesLoading={genomesLoading}
      history={history}
      integrationSubtype={integrationSubtype}
      libraryFragment={libraryFragment}
      location={location}
      loggedIn={loggedIn}
      model={model}
      refetch={refetch}
      refetchGenomes={refetchGenomes}
      schema={schema}
      selectTableRecords={selectTableRecords}
      selectedEntities={selectedEntities}
      tableParams={tableParams}
      withDisplayOptions={withDisplayOptions}
      withSelectedEntities={withSelectedEntities}
      onNewItemClick={() => {
        showDialog({
          ModalComponent: NewGenomeDialog,
          modalProps: {
            refetch: refetchGenomes
          }
        });
      }}
      isLibraryTable
      withExport
    />
  );
};

const fragment = gql`
  fragment genomeLibraryFragment on genome {
    id
    name
    primaryReferenceSequence {
      id
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(GenomeLibrary);
