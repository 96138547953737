/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import { withTableParams, CollapsibleCard, DataTable } from "@teselagen/ui";
import { addTagFilterToQuery } from "../../../src-shared/utils/tagUtils";
import { tagColumnWithRender } from "../../../src-shared/utils/tagColumn";
import routeDoubleClick from "../../../src-shared/utils/routeDoubleClick";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { tagModels } from "../../../../tg-iso-shared/constants";
import { combineGqlFragments } from "../../../../tg-iso-shared/utils/gqlUtils";
import withQueryDynamic from "../../../src-shared/withQueryDynamic";
import aliasModels from "../../../../tg-iso-lims/src/utils/aliasModels";
import { aliasColumn } from "../../../src-shared/utils/libraryColumns";

function AssociatedTableCard({ tableParams, title, onDoubleClick, cardProps }) {
  return (
    <CollapsibleCard title={title} {...cardProps}>
      <DataTable
        {...tableParams}
        noPadding
        onDoubleClick={onDoubleClick || routeDoubleClick}
      />
    </CollapsibleCard>
  );
}

const taggedItemFrag = `
  taggedItems {
    id
    tag {
      id
      name
      color
    }
    tagOption {
      id
      name
      color
    }
  }
`;

const aliasFrag = `
  aliases {
    id
    name
  }
`;

export default compose(
  withProps(props => {
    const model = getModelNameFromFragment(props.fragment);
    let fragment = props.fragment;

    let schema = props.schema || ["name"];
    if (!schema.fields) {
      schema = {
        model,
        fields: schema
      };
    }
    let extraFragment = "";
    const extraColumns = [];

    if (tagModels.includes(model)) {
      extraFragment += taggedItemFrag;
      extraColumns.push(tagColumnWithRender);
    }
    if (aliasModels.includes(model)) {
      extraFragment += aliasFrag;
      extraColumns.push(aliasColumn);
    }

    schema.fields = schema.fields.concat(extraColumns);

    fragment = combineGqlFragments([fragment, extraFragment]);
    return {
      model,
      formName: props.formName || model + "AssociatedTableCard",
      schema,
      runTimeQueryOptions: {
        fragment
      }
    };
  }),
  withTableParams({
    urlConnected: false,
    additionalFilter: (props, qb, currentParams) => {
      if (props.additionalFilter) {
        if (typeof props.additionalFilter === "function") {
          props.additionalFilter(props, qb, currentParams);
        } else {
          qb.whereAll(props.additionalFilter);
        }
      }
      if (tagModels.includes(props.model)) {
        addTagFilterToQuery(currentParams.tags, qb);
      }
    }
  }),
  withQueryDynamic({
    isPlural: true
  })
)(AssociatedTableCard);
