/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { MenuItem } from "@blueprintjs/core";
import React from "react";
import { exportEquipment, getContentsCsv } from "../../../utils";

function getExportEquipmentMenuItems({ recordIds }) {
  const exportEquipmentMenuItem = (
    <MenuItem
      key="exportEquipment"
      text="Export Equipment as JSON"
      onClick={() => exportEquipment(recordIds)}
    />
  );

  const getCsvContentsMenuItem = (
    <MenuItem
      key="getCsvContents"
      text="Export Contents as CSV"
      onClick={() => getContentsCsv(recordIds)}
    />
  );

  return [exportEquipmentMenuItem, getCsvContentsMenuItem];
}

export default getExportEquipmentMenuItems;
