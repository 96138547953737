/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { withTableParams } from "@teselagen/ui";
import { Dialog, Button, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { DataTable } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import schema from "./schema";

class PartsetLibraryDialog extends React.Component {
  static defaultProps = {
    title: "Choose Part Set"
  };

  static propTypes = {
    /**
     * The title of the dialog. Default title will be used if none is provided.
     */
    title: PropTypes.string,

    /**
     * This function will be called with the selected part sets as its only argument
     * when the user clicks the OK button.
     */
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    submitting: true,
    partsets: []
  };

  handleSubmit = async () => {
    const { partsets } = this.state;
    const { hideModal, onSubmit } = this.props;
    this.setState({ submitting: true });
    await onSubmit(partsets);
    hideModal();
  };

  handleSelect = partsets =>
    this.setState({
      partsets: Array.isArray(partsets) ? partsets : [partsets]
    });

  render() {
    const { hideModal, title, tableParams, submitting } = this.props;
    const { partsets } = this.state;

    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={title}
        style={{ width: 950 }}
      >
        <div className="bp3-dialog-body">
          <DataTable
            {...tableParams}
            onDoubleClick={this.handleSubmit}
            onSingleRowSelect={this.handleSelect}
            onMultiRowSelect={this.handleSelect}
          />
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button text="Cancel" onClick={hideModal} />
            <Button
              intent={Intent.PRIMARY}
              text="OK"
              disabled={!partsets.length}
              loading={submitting}
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "partLibraryDialog"
  }),
  withQuery(["partset", "id name numParts createdAt updatedAt"], {
    isPlural: true
  })
)(PartsetLibraryDialog);
