/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Loading } from "@teselagen/ui";
import { map } from "lodash";
import { Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { ErrorBoundary } from "react-error-boundary";
import EnhancedRoute from "../../EnhancedRoute";
import NoResourceFound from "../../NoResourceFound";
import { FallbackRender } from "../../components/fallbackRender";
import { useHotkeys } from "@blueprintjs/core";
import CurrentUserContext from "../../context/CurrentUserContext";
import publicRoutes from "../../publicRoutes";
import { Header } from "../Header";
import { appRoutes } from "../../appRoutes";
import { navJumpHotkey } from "../../PlatformHeader";

const AppBody = ({
  loggedIn,
  loggedInLayout,
  mainStore,
  showLeftSlideOutDrawer
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  useEffect(() => {
    if (window.Cypress?.throwError) {
      const style = `
      #react-refresh-overlay {
          display: none;
      `;
      const styleSheet = document.createElement("style");
      styleSheet.innerText = style;
      document.head.appendChild(styleSheet);
      throw new Error("Error thrown from AppBody");
    }
  }, []);

  return (
    <div
      id="app-body"
      className={classNames({
        // use this instead of loggedIn variables for public routes that can be accessed
        // even while loggedIn. ex. ResetPassword,
        "logged-in": loggedInLayout
      })}
    >
      <Switch>
        {map(appRoutes, (component, path) => (
          <EnhancedRoute
            key={path}
            showLeftSlideOutDrawer={showLeftSlideOutDrawer}
            privateRoute
            exact
            loggedIn={loggedIn}
            mainStore={mainStore}
            currentUser={currentUser}
            path={path}
            component={component}
          />
        ))}
        <EnhancedRoute component={NoResourceFound} path="/not-found" />
        <EnhancedRoute component={NoResourceFound} />
      </Switch>
    </div>
  );
};

const MainContent = ({
  location,
  currentUser,
  loading,
  logout,
  appHotkeySets,
  loggedIn,
  mainStore
}) => {
  const isPublicRoute = !!publicRoutes[location.pathname];
  const loggedInLayout = !!(loggedIn && !isPublicRoute);
  const [showLeftSlideOutDrawer, setshowLeftSlideOutDrawer] = useState(false);
  const hotkeys = useMemo(
    () => [
      {
        combo: navJumpHotkey,
        allowInInput: true,
        global: true,

        label: "Hide/Show Mobile Menu",
        onKeyDown: e => {
          e.preventDefault();
          e.stopPropagation();
          setshowLeftSlideOutDrawer(!showLeftSlideOutDrawer);
        }
      }
    ],
    [showLeftSlideOutDrawer]
  );
  useHotkeys(hotkeys);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <Switch>
          {map(publicRoutes, (component, path) => (
            <EnhancedRoute
              mainStore={mainStore}
              key={path}
              currentUser={currentUser}
              fullWidth
              exact
              showLeftSlideOutDrawer={showLeftSlideOutDrawer}
              loggedIn={loggedIn}
              path={path}
              component={component}
            />
          ))}
          <Route>
            <Header
              showLeftSlideOutDrawer={showLeftSlideOutDrawer}
              setshowLeftSlideOutDrawer={setshowLeftSlideOutDrawer}
              appHotkeySets={appHotkeySets}
              loggedIn={loggedIn}
              currentUser={currentUser}
              logout={logout}
              appBody={
                <ErrorBoundary fallbackRender={FallbackRender}>
                  <AppBody
                    mainStore={mainStore}
                    showLeftSlideOutDrawer={showLeftSlideOutDrawer}
                    loggedInLayout={loggedInLayout}
                    currentUser={currentUser}
                    loggedIn={loggedIn}
                  />
                </ErrorBoundary>
              }
            />
          </Route>
        </Switch>
      </>
    );
  }
};

export { MainContent };
