/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { userRoleCodes } from "./constants.js";

export function isPublisher(context) {
  if (typeof context === "undefined") return;
  if (typeof context.req === "undefined") return;

  // need to check against the string because env vars get stringified
  if (
    process.env.LoadingFixtures === "true" ||
    process.env.LoadingFixtures === true
  ) {
    // if seed data is being populated then allow without an authenticated admin
    // LoadingFixtures should be unset after seed data is populated
    return true;
  }
  const user = context.req.user;
  if (
    (user.appRoleCodes && user.appRoleCodes[userRoleCodes.PUBLISHER_ROLE]) ||
    context.req?.superuser
  ) {
    return true;
  }

  return false;
}
