/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { noop } from "lodash";

const dialogStore = observable({
  openModals: []
});

// tgreen: keep modal props outside of mobx (which wraps all objects in observables).
// this will keep them as pure js
let dialogModalProps = [];
const openModalComponents = [];
let dialogOnClose = [];
let pathnameOnOpen = "";

export function showStackedDialog({ ModalComponent, modalProps: _m, onClose }) {
  const modalProps = {
    hideDialog: hideStackedDialog,
    hideModal: hideStackedDialog,
    ..._m
  };
  dialogModalProps.push(modalProps || {});
  dialogOnClose.push(onClose || noop);
  pathnameOnOpen = window.location.pathname;
  openModalComponents.push(ModalComponent);

  // this needs to happen last
  dialogStore.openModals.push(shortid());
}

export function hideStackedDialog(options = {}) {
  if (options.hideAll) {
    dialogStore.openModals = [];
    dialogModalProps = [];
    dialogOnClose.forEach(func => func());
    dialogOnClose = [];
  } else {
    // this needs to happen first
    dialogStore.openModals.pop();
    dialogModalProps.pop();
    dialogOnClose.pop()();
  }
}

const StackedDialog = props => {
  const { currentUser, history, location, ...rest } = props;

  useEffect(() => {
    if (
      pathnameOnOpen !== window.location.pathname &&
      dialogStore.openModals.length
    ) {
      hideStackedDialog({ hideAll: true });
    }
  }, []);

  if (!dialogStore.openModals.length) {
    return null;
  }

  const hideModal = () => {
    hideStackedDialog();
  };

  return dialogStore.openModals.map((key, i) => {
    const Component = openModalComponents[i];
    return (
      <Component
        key={key}
        hideModal={hideModal}
        currentUser={currentUser}
        history={history}
        {...rest}
        {...dialogModalProps[i]}
      />
    );
  });
};

export default withRouter(observer(StackedDialog));
