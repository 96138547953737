/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { Classes } from "@blueprintjs/core";
import { InputField, CheckboxField, DialogFooter } from "@teselagen/ui";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";
import { wrapDialog } from "@teselagen/ui";

class DuplicateDesignDialog extends React.Component {
  onSubmit = defaultAsyncWrap(async ({ name, duplicatePartsAndSequences }) => {
    duplicatePartsAndSequences = !!duplicatePartsAndSequences;

    const { designs, onSuccess, hideModal, refetch } = this.props;
    const {
      data: { ids }
    } = await window.serverApi.request({
      method: "POST",
      url: "/duplicateDesigns",
      data: {
        designIds: designs.map(d => d.id),
        name,
        duplicatePartsAndSequences
      }
    });

    onSuccess && (await onSuccess(ids));
    refetch && (await refetch());
    hideModal();

    window.toastr.success("Duplicated Design");
  }, "Error duplicating design(s).");

  render() {
    const { hideModal, submitting, handleSubmit, designs } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            {designs.length === 1 && (
              <InputField
                name="name"
                label="Design copy name"
                defaultValue={designs[0].name + " copy"}
                isRequired
              />
            )}
            <CheckboxField
              name="duplicatePartsAndSequences"
              label={`Duplicate sequences and parts that are part of ${
                designs.length > 1 ? "these designs" : "this design"
              }.`}
            />
          </div>
          <DialogFooter text="OK" hideModal={hideModal} loading={submitting} />
        </form>
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog(props => ({
    title: "Duplicate Design" + sIfPlural(props.designs),
    style: { width: 350 }
  })),
  reduxForm({
    form: "duplicateDesignsForm", // a unique name for this form
    enableReinitialize: true
  })
)(DuplicateDesignDialog);
