/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  takeEvery,
  put
  // select
} from "redux-saga/effects";
import actions from "../../actions";
import { socketWrapper } from "../../../../src-shared/socket/socketWrapper";
// import { getDesignState } from "../../../../../tg-iso-design/selectors/designStateSelectors";
// import {
//   diffDesignStates,
//   getOldIdToNewIdMapFromDiff
// } from "../../../../../tg-iso-design/utils/designStateUtils";
// import { safeQuery } from "../../../../src-shared/apolloMethods";
// import queryBuilder from "tg-client-query-builder";
// import { get, size, find } from "lodash";

// async function getModelCids(model, values) {
//   const modelIds = Object.keys(values);
//   let results;
//   if (model === "fas") {
//     const elementQB = new queryBuilder("element");
//     const filter = elementQB.whereAll({
//       "fas.id": elementQB.inList(modelIds)
//     });
//     results = await safeQuery(["element", "id fas {id cid}"], {
//       variables: {
//         filter
//       }
//     });
//     results = results.map(result => ({
//       id: get(result, "fas.0.id"),
//       cid: get(result, "fas.0.cid")
//     }));
//   } else if (model === "designMaterialAvailabilityView") {
//     return;
//   } else {
//     results = await safeQuery([model, "id cid"], {
//       variables: {
//         filter: {
//           id: modelIds
//         }
//       }
//     });
//   }
//   return results;
// }

// async function addModelsCids(models) {
//   const createEntries = Object.entries(models).filter(([model, values]) =>
//     size(values)
//   );

//   for (let index = 0; index < createEntries.length; index++) {
//     const [model, values] = createEntries[index];

//     const results = await getModelCids(model, values);

//     Object.values(values).forEach(value =>
//       Object.assign(value, {
//         cid: get(
//           find(results, result => result.id === value.id),
//           "cid"
//         )
//       })
//     );
//   }
// }

// async function addDiffCids(diff) {
//   const { updates, creates } = diff;

//   await addModelsCids(updates);
//   await addModelsCids(creates);
// }

/**
 * This saga is useful for synchronizing the design state seen in the database
 * by the server with the the design state in the UI.
 * Usually this saga is not needed because all actions in the design editor
 * are being tracked.
 *
 * At the time of implementation the use case for this saga was when triggering
 * the UPDATE hook from the design editor, which updates the design state in the database
 * outside the design editor framework so updates done in by the UPDATE hook
 * wont get natively tracked in the UI design state. For this reason, after the design state
 * is updated in the backend, this saga will sync it with the design state in the UI.
 *
 */
export function* syncDesignState({ payload: designId }) {
  const { designState: designStateFromServer } = yield socketWrapper.syncDesign(
    designId
  );
  // const designState = yield select(getDesignState);

  // We need this 'diff' object containing the mutation differences in the design state,
  // in order to enable undo/redo logic.
  // const diff = diffDesignStates(designState, designStateFromServer);
  // yield addDiffCids(diff);
  // const oldIdToNewIdMap = yield getOldIdToNewIdMapFromDiff(diff);

  yield put(actions.designIo.setLastSavedState(designStateFromServer));
  yield put(actions.designIo.setDesign(designStateFromServer));

  // yield put(
  //   actions.ui.designEditor.undo.addToUndoStack({
  //     ...designState,
  //     // The following is mainly needed to synchronize editLockIds.
  //     design: designStateFromServer.design
  //   })
  // );

  // yield put(
  //   actions.designIo.saveCompleted({ oldIdToNewIdMap, validationDiff: diff })
  // );
}

export default function* watchSyncDesignState() {
  yield takeEvery(
    actions.ui.designEditor.general.syncDesignState,
    syncDesignState
  );
}
