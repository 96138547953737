/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  forEach,
  times,
  range,
  chunk,
  flatten,
  get,
  cloneDeep,
  uniq
} from "lodash";
import shortid from "shortid";
import FullFormulation from "../../../../tg-iso-lims/src/AliquotFormulation/FullFormulation";

export async function executeServerWorklist(worklistId) {
  return await window.serverApi.request({
    method: "POST",
    url: "/execute-worklist",
    data: {
      worklistId
    }
  });
}
// this function takes in a valid worklist and executes its transfers,
// doing all necessary aliquot formulations on the way
export async function executeWorklist(worklist) {
  return await executeServerWorklist(worklist.id);
}

export const preparePlateMapGroupsForSave = plateMapGroups => {
  function cleanPlateMapItem(plateMapItem) {
    const newPlateMapItem = {
      plateMapItemTypeCode: "J5_ITEM",
      name: plateMapItem.name,
      rowPosition: plateMapItem.rowPosition,
      columnPosition: plateMapItem.columnPosition
    };
    if (plateMapItem.j5DirectSynthesis) {
      newPlateMapItem.j5Item = {
        j5ItemTypeCode: "J5_DIRECT_SYNTHESIS",
        j5DirectSynthesisId: plateMapItem.j5DirectSynthesis.id
      };
    } else if (plateMapItem.j5InputSequence) {
      newPlateMapItem.j5Item = {
        j5ItemTypeCode: "J5_INPUT_SEQUENCE",
        j5InputSequenceId: plateMapItem.j5InputSequence.id
      };
    } else if (plateMapItem.__typename === "j5Oligo") {
      newPlateMapItem.j5Item = {
        j5ItemTypeCode: "J5_OLIGO",
        j5OligoId: plateMapItem.originalId
      };
    }
    return newPlateMapItem;
  }

  const plateMapGroupsInput = plateMapGroups.map(plateMapGroup => {
    const newPlateMapGroup = { ...plateMapGroup };

    delete newPlateMapGroup.outputName;
    delete newPlateMapGroup.containerFormat;
    newPlateMapGroup.plateMaps = newPlateMapGroup.plateMaps.map(plateMap => {
      plateMap.metadata = {
        temperatureZones: plateMap.temperatureZones
      };
      delete plateMap.temperatureZones;
      const newPlateMap = { ...plateMap };
      newPlateMap.plateMapItems = newPlateMap.plateMapItems.map(
        plateMapItem => {
          return cleanPlateMapItem(plateMapItem);
        }
      );
      return newPlateMap;
    });
    return newPlateMapGroup;
  });
  return plateMapGroupsInput;
};

export const deconstructPlateMaps = (plateMaps, containerArrayType) => {
  const createBuilder = () => {
    return {
      forwardPrimer: [],
      primaryTemplate: [],
      reversePrimer: []
    };
  };
  let builder = createBuilder();
  const plateMapTemp = [];
  plateMaps.forEach(plateMap => {
    plateMap.plateMapItems.forEach((plateMapItem, i) => {
      forEach(builder, (array, key) => {
        if (plateMapItem[key]) {
          array.push({
            ...plateMapItem[key],
            id: i,
            originalId: plateMapItem[key].id,
            rowPosition: plateMapItem.rowPosition,
            columnPosition: plateMapItem.columnPosition
          });
        }
      });
    });
    plateMapTemp.push(builder);
    builder = createBuilder();
  });

  const forwardPrimerPlateMaps = [];
  const primaryTemplatePlateMaps = [];
  const reversePrimerPlateMaps = [];
  plateMapTemp.forEach((a, i) => {
    forwardPrimerPlateMaps.push({
      name: `forwardPrimerPlateMap ${i + 1}`,
      type: "Forward Primers",
      temperatureZones: plateMaps[i].temperatureZones,
      plateMapTypeCode: "J5_OLIGO",
      plateMapItems: a.forwardPrimer
    });
    primaryTemplatePlateMaps.push({
      name: `primaryTemplatePlateMap ${i + 1}`,
      type: "Primary Templates",
      plateMapTypeCode: "J5_PRIMARY_TEMPLATE",
      temperatureZones: plateMaps[i].temperatureZones,
      plateMapItems: a.primaryTemplate
    });
    reversePrimerPlateMaps.push({
      name: `reversePrimerPlateMap ${i + 1}`,
      type: "Reverse Primers",
      plateMapTypeCode: "J5_OLIGO",
      temperatureZones: plateMaps[i].temperatureZones,
      plateMapItems: a.reversePrimer
    });
  });
  const plateMapGroups = [
    {
      name: "Primary Templates",
      containerFormatCode: containerArrayType.containerFormat.code,
      containerFormat: containerArrayType.containerFormat,
      plateMaps: primaryTemplatePlateMaps,
      cid: shortid(),
      outputName: "primaryTemplates"
    },
    {
      name: "Forward Primers",
      containerFormatCode: containerArrayType.containerFormat.code,
      containerFormat: containerArrayType.containerFormat,
      plateMaps: forwardPrimerPlateMaps,
      cid: shortid(),
      outputName: "forwardPrimers"
    },
    {
      name: "Reverse Primers",
      containerFormatCode: containerArrayType.containerFormat.code,
      containerFormat: containerArrayType.containerFormat,
      plateMaps: reversePrimerPlateMaps,
      cid: shortid(),
      outputName: "reversePrimers"
    }
  ];
  return plateMapGroups;
};

export const plateTo2dAliquotArray = plate => {
  const { rowCount } = plate.containerArrayType.containerFormat;
  const array2d = range(rowCount).map(() => []);
  plate.aliquotContainers.forEach(aliquotContainer => {
    const { rowPosition, columnPosition } = aliquotContainer;
    array2d[rowPosition][columnPosition] = aliquotContainer.aliquot;
  });
  return array2d;
};

export const plateTo2dAliquotContainerArray = plate => {
  const { rowCount } = plate.containerArrayType.containerFormat;
  const array2d = range(rowCount).map(() => []);
  plate.aliquotContainers.forEach(aliquotContainer => {
    const { rowPosition, columnPosition } = aliquotContainer;
    array2d[rowPosition][columnPosition] = aliquotContainer;
  });
  return array2d;
};

export function createSourceToDestinationPlate2DArrayMap(
  sourceToDestinationPlateMap
) {
  const map = {};
  for (const sourceId of Object.keys(sourceToDestinationPlateMap)) {
    const destinationPlates = sourceToDestinationPlateMap[sourceId];
    map[sourceId] = destinationPlates.map(p =>
      plateTo2dAliquotContainerArray(p)
    );
  }
  return map;
}

export const empty2dArray = (rowCount, colCount) =>
  range(rowCount).map(() => {
    const row = [];
    row.length = colCount;
    return row;
  });
// The `array2d` parameter is assumed to be row major.
export const getBlockOf2dArray = (
  array2d,
  blkRowCount,
  blkColCount,
  blkXIndex,
  blkYIndex,
  everyOtherColumn,
  everyOtherRow
) => {
  if (everyOtherRow && everyOtherColumn) {
    return array2d
      .slice(blkYIndex * blkRowCount, (blkYIndex + 1) * blkRowCount)
      .map(row =>
        row.slice(blkXIndex * blkColCount, (blkXIndex + 1) * blkColCount)
      );
  } else if (everyOtherRow) {
    const row1 = array2d[blkYIndex * blkRowCount];
    const row2 = array2d[blkYIndex * blkRowCount + 1];
    return [
      [row1[blkXIndex], row1.slice(row1.length / 2)[blkXIndex]],
      [row2[blkXIndex], row2.slice(row2.length / 2)[blkXIndex]]
    ];
  } else if (everyOtherColumn) {
    const col1 = [];
    const col2 = [];
    array2d.forEach(row => {
      col1.push(row[blkXIndex * blkColCount]);
      col2.push(row[blkXIndex * blkColCount + 1]);
    });
    return [
      [col1[blkYIndex], col2[blkYIndex]],
      [
        col1.slice(col1.length / 2)[blkYIndex],
        col2.slice(col2.length / 2)[blkYIndex]
      ]
    ];
  } else {
    const row1 = array2d[blkYIndex];
    const row2 = array2d[array2d.length / 2 + blkYIndex];
    return [
      [row1[blkXIndex], row1.slice(row1.length / 2)[blkXIndex]],
      [row2[blkXIndex], row2.slice(row2.length / 2)[blkXIndex]]
    ];
  }
};

// The `array2d` parameter is assumed to be row major.
export const setBlockOf2dArray = (array2d, block, blkXIndex, blkYIndex) => {
  const blkRowCount = block.length;
  const blkColCount = block[0].length;
  block.forEach((row, rowIndex) =>
    row.forEach((item, colIndex) => {
      array2d[blkYIndex * blkRowCount + rowIndex][
        blkXIndex * blkColCount + colIndex
      ] = item;
    })
  );
};

export const transpose = array =>
  array[0].map((col, i) => array.map(row => row[i]));

export const is2x2Mapping = (
  smallerContainerFormat = {},
  biggerContainerFormat = {}
) => {
  const { rowCount: bigRowCount, columnCount: bigColCount } =
    biggerContainerFormat;
  const { rowCount: smallRowCount, columnCount: smallColCount } =
    smallerContainerFormat;
  if (bigRowCount < smallRowCount || bigColCount < smallColCount)
    console.error(
      "Bigger container format is smaller than smaller container format."
    );
  const blkRowCount = bigRowCount / smallRowCount;
  const blkColCount = bigColCount / smallColCount;
  return blkRowCount === 2 && blkColCount === 2;
};

export const canPlateFormatTile = (
  smallerContainerFormat = {},
  biggerContainerFormat = {}
) => {
  const { rowCount: bigRowCount, columnCount: bigColCount } =
    biggerContainerFormat;
  const { rowCount: smallRowCount, columnCount: smallColCount } =
    smallerContainerFormat;
  const isSameFormat =
    bigRowCount === smallRowCount && bigColCount === smallColCount;
  return (
    !isSameFormat &&
    !(bigRowCount % smallRowCount) &&
    !(bigColCount % smallColCount)
  );
};

// This function might mutate `aliquotArray`.
export const aliquotArrayToBlock = (
  aliquotArray,
  pattern,
  blkRowCount,
  blkColCount
) => {
  aliquotArray.length = blkRowCount * blkColCount;
  if (pattern === "Z") {
    return chunk(aliquotArray, blkColCount);
  } else if (pattern === "Z-Reverse") {
    return chunk(aliquotArray, blkColCount).map(row => row.reverse());
  } else if (pattern === "N") {
    return transpose(
      chunk(aliquotArray, blkColCount).map(row => row.reverse())
    );
  } else if (pattern === "N-Reverse") {
    return transpose(chunk(aliquotArray, blkColCount));
  } else if (pattern === "Clockwise") {
    return [
      [aliquotArray[0], aliquotArray[1]],
      [aliquotArray[3], aliquotArray[2]]
    ];
  } else if (pattern === "Counter-Clockwise") {
    return [
      [aliquotArray[0], aliquotArray[3]],
      [aliquotArray[1], aliquotArray[2]]
    ];
  } else {
    throw new Error("Unrecognized breakdown pattern: " + pattern);
  }
};

// Assumes `block` is row-major.
// This function might mutate `block`.
export const blockToAliquotArray = (block, pattern) => {
  if (pattern === "Z") {
    return flatten(block);
  } else if (pattern === "Z-Reverse") {
    return flatten(block.map(row => row.reverse()));
  } else if (pattern === "N") {
    return flatten(transpose(block).map(row => row.reverse()));
  } else if (pattern === "N-Reverse") {
    return flatten(transpose(block));
  } else if (pattern === "Clockwise") {
    return [...block[0], ...block[1].reverse()];
  } else if (pattern === "Counter-Clockwise") {
    return [block[0][0], block[1][0], block[1][1], block[0][1]];
  } else {
    throw new Error("Unrecognized breakdown pattern: " + pattern);
  }
};

export function prepareContainerArraysForStampTool(containerArrays) {
  const materials = [];
  containerArrays.forEach(containerArray => {
    containerArray.aliquotContainers.forEach(aliquotContainer => {
      const aliquotCid = shortid();
      const material = get(aliquotContainer, "aliquot.sample.material");
      const sample = get(aliquotContainer, "aliquot.sample");
      const aliquot = get(aliquotContainer, "aliquot");
      if (!aliquot || !sample) return;
      delete sample.material;
      delete aliquot.sample;
      delete aliquotContainer.aliquot;
      aliquotContainer.aliquotId = `&${aliquotCid}`;
      aliquot.cid = aliquotCid;
      sample.aliquots = [aliquot];
      if (material) {
        material.samples = [sample];
        materials.push(material);
      }
    });
  });
  return materials;
}

export const worklistToFinalizedPlates = (worklist, destinationPlates) => {
  const plateIdMap = cloneDeep(destinationPlates).reduce(
    (acc, plate) => ({ ...acc, [plate.id]: plate }),
    {}
  );

  const dstAliquotContainerIdToTransfersMap = worklist.worklistTransfers.reduce(
    (acc, transfer) => {
      const transfers = acc[transfer.destinationAliquotContainer.id] || [];
      return {
        ...acc,
        [transfer.destinationAliquotContainer.id]: [...transfers, transfer]
      };
    },
    {}
  );
  Object.values(dstAliquotContainerIdToTransfersMap).forEach(transfers => {
    const { destinationAliquotContainer } = transfers[0];

    const dstPlate = plateIdMap[destinationAliquotContainer.containerArrayId];
    const dstACOnPlate = dstPlate.aliquotContainers.find(
      ({ id }) => id === destinationAliquotContainer.id
    );
    const sampleName = uniq([
      get(dstACOnPlate, "aliquot.sample.name"),
      ...transfers.map(({ sourceAliquotContainer }) =>
        get(sourceAliquotContainer, "aliquot.sample.name")
      )
    ])
      .filter(name => name)
      .join(" - ");

    const aliquotFormulation = new FullFormulation({
      aliquot: dstACOnPlate.aliquot,
      aliquotContainer: dstACOnPlate,
      sampleName
    });
    transfers.forEach(transfer => {
      if (transfer.sourceAliquotContainer.aliquot) {
        aliquotFormulation.addAliquotTransfer(
          transfer.sourceAliquotContainer.aliquot,
          transfer.volume,
          transfer.volumetricUnitCode
        );
      }
    });

    dstACOnPlate.aliquot = {
      ...(dstACOnPlate.aliquot || {}),
      volume: aliquotFormulation.getVolume(),
      volumetricUnitCode: aliquotFormulation.getVolumetricUnitCode(),
      concentration: aliquotFormulation.getConcentration(),
      concentrationUnitCode: aliquotFormulation.getConcentrationUnitCode(),
      sample: {
        name: sampleName
      },
      isDry: aliquotFormulation.isDry()
    };
  });
  return Object.values(plateIdMap);
};

export function transferGroupsToTransfers({
  channelOrientation,
  repColCount,
  repRowCount,
  worklistTransfers,
  transferGroups
}) {
  if (channelOrientation === "Horizontal") {
    times(repRowCount, i => {
      transferGroups.forEach(group => {
        const transfers = group.slice(i * repColCount, (i + 1) * repColCount);
        transfers.forEach(transfer => {
          if (transfer) {
            worklistTransfers.push(transfer);
          }
        });
      });
    });
  } else {
    times(repColCount, i => {
      transferGroups.forEach(group => {
        const transfers = [];
        times(repRowCount, j => {
          transfers.push(group[j * repColCount + i]);
        });
        transfers.forEach(transfer => {
          if (transfer) {
            worklistTransfers.push(transfer);
          }
        });
      });
    });
  }
}
