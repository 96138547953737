/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Callout } from "@blueprintjs/core";
import { CheckboxField, DialogFooter, InfoHelper } from "@teselagen/ui";

import { reduxForm, Field, change } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import store from "../../../../src-shared/redux/store";

import getBreakpointableIndices from "./BreakpointsFieldComponent/getBreakpointableIndices";
import BreakpointsFieldComponent from "./BreakpointsFieldComponent";

import { wrapDialog } from "@teselagen/ui";
import { isErdam } from "../../../../src-shared/utils/addErdamUtils";
import actions from "../../../../src-shared/redux/actions";
import { isCardCircular } from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { getIconsForRender } from "../../../../src-shared/selectors/addReactionSelectors";
import "./style.css";
import shortid from "shortid";
import { REACTION_COLORS } from "../../../../src-shared/components/HierarchicalDesign/constants";

const validate = (values = {}, { isOutputCircular }) => {
  const errors = {};

  const breakPointIndices = values.breakPointIndices
    ? values.breakPointIndices.indices
    : [];
  if (isOutputCircular && breakPointIndices.length < 2)
    errors.breakPointIndices =
      "Must have at least 2 breakpoints with circular outputs";
  else if (!breakPointIndices.length)
    errors.breakPointIndices = "Must have at least 1 breakpoint";

  return errors;
};

const mapStateToProps = (state, props) => {
  const { assemblyMethod, cardId } = props;
  const isOutputCircular = isCardCircular(state, cardId);
  return {
    breakpointableIndices: getBreakpointableIndices(
      state,
      props.cardId,
      isOutputCircular,
      isErdam(assemblyMethod)
    ),
    state,
    operationColor: "#7109AA",
    isOutputCircular,
    iconsToRender: getIconsForRender(state, cardId)
    // initialValues: {
    //   breakpointIndices: getCurrentSplits()
    // }
  };
};
const mapDispatchToProps = {
  setInspectorActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  addOrChangeReaction: actions.design.addOrChangeReaction,
  openInspector: actions.ui.designEditor.inspector.open,
  selectReaction: actions.ui.designEditor.general.selectReaction
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapDialog(props => {
    return {
      title: "Change Input Grouping",
      style: {
        width: props.breakpointableIndices.length > 4 ? 850 : undefined
      }
    };
  }),
  reduxForm({
    form: "addReactionForm", // a unique name for this form
    enableReinitialize: true,
    validate
  }),
  tgFormValues("breakPointIndices", "makeAllInputsCircular")
)(
  class AddSplitsDialog extends React.Component {
    state = {};

    onSubmit = values => {
      const {
        addOrChangeReaction,
        cardId,
        hideModal,
        addMockReaction,
        isOutputCircular,
        setInspectorActivePanel,
        openInspector,
        selectReaction
      } = this.props;
      const reactionIdToUse = shortid();
      //if addMockReaction=true then we'll need to add a mock reaction
      addOrChangeReaction({
        ...values,
        reactionIdToUse,
        addMockReaction,
        isOutputCircular: isOutputCircular,
        cardId
      });

      selectReaction(reactionIdToUse);
      setInspectorActivePanel({
        panel: "operation"
      });
      openInspector();

      hideModal();
    };

    handleSplitAllSetsClick = () => {
      const { breakpointableIndices, breakPointIndices } = this.props;
      store.dispatch(
        change("addReactionForm", "breakPointIndices", {
          ...breakPointIndices,
          indices: breakpointableIndices
        })
      );
    };

    render() {
      const {
        hideModal,
        selectedCard,
        handleSubmit,
        valid,
        submitting,
        //
        iconsToRender
      } = this.props;
      const isOutputCircular = !!this.props.isOutputCircular;
      const { submitButtonDisabled } = this.state;

      return (
        <div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="bp3-dialog-body add-operation-dialog">
              <Callout intent="primary">
                Choose how you would like the inputs to the reaction to be
                grouped. Click between the bins to add or remove the presence of
                an assembly junction.
              </Callout>
              <CheckboxField
                label={
                  <div>
                    Add backbone to inputs? &nbsp;
                    <InfoHelper
                      content={`This is an advanced option mostly useful for hierarchical designs where the intermediate dna is sourced on it's own backbone. If checked, we'll add a non-propagating backbone bin to each of the input groups.`}
                      isInline
                    ></InfoHelper>
                  </div>
                }
                name="makeAllInputsCircular"
              />
              <Field
                {...{
                  selectedCard,
                  assemblyMethod: this.props.assemblyMethod,
                  isOutputCircular,
                  operationColor: REACTION_COLORS[0],
                  iconsToRender,
                  breakpointableIndices: this.props.breakpointableIndices
                }}
                name="breakPointIndices"
                component={BreakpointsFieldComponent}
              />
            </div>

            <DialogFooter
              hideModal={hideModal}
              submitting={submitting}
              disabled={submitButtonDisabled || !valid}
              onBackClick={this.handleSplitAllSetsClick}
              backText="Split All Bins"
            />
          </form>
        </div>
      );
    }
  }
);
