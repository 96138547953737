/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as d3 from "d3";
import modelSigmaAminoAcidHistogramWidgetStyles from "../ModelSigmaAminoAcidHistogramWidgetStyles.module.css";
import widgetCardStyles from "../../../WidgetCardStyles.module.css";
import d3Tip from "d3-tip";
import { sortBy } from "lodash";

const X_AXIS_LABEL = "Amino Acids";
const Y_AXIS_LABEL = "Percentage [%]";

const TRANSITION_DURATION = 1500;
// const INTERPOLATION_START_COLOR = "rgb(12, 82, 125)";
// const INTERPOLATION_END_COLOR = "rgb(20, 132, 201)";

// const tokenOrder = ["KHR DE NQSTPC ILVMAG FWY"]
const tokenOrder = "KHRDENQSTPCILVMAGFWY".split("");

function svgSelection(htmlComponentClass, width, height) {
  return d3
    .select(`.${htmlComponentClass}`)
    .append("svg")
    .attr("height", height)
    .attr("width", width);
}

function drawSigmaAminoAcidHistogram(
  htmlComponentClass,
  height,
  width,
  dataToPlot,
  maxYValue
) {
  const categories = [
    {
      graph: "token",
      name: "input",
      label: "training sequences",
      cssColorClass: modelSigmaAminoAcidHistogramWidgetStyles.inputrect,
      clicked: false,
      hidden: false
    },
    {
      graph: "token",
      name: "output",
      label: "generated sequences",
      cssColorClass: modelSigmaAminoAcidHistogramWidgetStyles.outputrect,
      clicked: false,
      hidden: false
    }
  ];

  d3.selectAll(`.${htmlComponentClass} *`).remove();

  const margin = 20;
  const data = sortBy(dataToPlot, function(l) {
    return -l.values.filter(val => val.type === "input")[0].value;
  });
  const xAxisLabels = tokenOrder; // data.map(tokenGroup => tokenGroup.token);
  // const maxY = d3.max(
  //   data.map(tokenGroup =>
  //     d3.max(tokenGroup.values.map(tokenElement => tokenElement.value))
  //   )
  // );
  const tip = d3Tip()
    .attr("class", modelSigmaAminoAcidHistogramWidgetStyles.tip)
    .offset([-10, 0])
    .html(d => {
      return `<strong>Percentage:</strong> <span style='color:#3dcc91'>
        ${Math.round(d.value * 100) / 100} %
        </span><br/><br/>
        <strong>Token:</strong> <span style='color:#3dcc91'>
        ${d.token}
        </span>`;
    });
  const svg = svgSelection(htmlComponentClass, width, height);

  const xTokenScale = d3
    .scaleBand()
    .domain(xAxisLabels)
    .align(0.5)
    .padding(0.5)
    .rangeRound([2.5 * margin, width - 2.5 * margin]);

  const xGroupScale = d3
    .scaleBand()
    .domain(data[0].values.map(tokenElement => tokenElement.type))
    .rangeRound([0, xTokenScale.bandwidth()])
    .padding(0.05);

  const yScale = d3
    .scaleLinear()
    .domain([0, maxYValue * 1.05])
    .nice()
    .rangeRound([height - 3 * margin, 2 * margin]);

  const xAxis = g =>
    g
      .attr("transform", `translate(${0 * margin},${height - 2 * margin})`)
      .attr("class", widgetCardStyles.axis)
      .call(d3.axisBottom(xTokenScale).tickValues(xAxisLabels));
  // .call(d3.axisBottom(xTokenScale).tickSizeOuter(0));

  const yAxis = g =>
    g
      .attr("transform", `translate(${2.5 * margin},${margin})`)
      .call(
        d3
          .axisLeft(yScale)
          .tickValues(yScale.ticks().filter(tick => Number.isInteger(tick)))
          .tickFormat(d3.format("d"))
      )
      .attr("class", widgetCardStyles.axis)
      .call(g =>
        g
          .select(".tick:last-of-type text")
          .clone()
          .attr("x", 3)
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
      );

  const legend = svg => {
    const g = svg
      .attr("transform", `translate(${width - 2 * margin},0)`)
      .attr("text-anchor", "end")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("class", modelSigmaAminoAcidHistogramWidgetStyles.legend)
      .selectAll("g")
      .data(categories)
      .join("g")
      .attr("transform", (d, i) => {
        return `translate(0,${margin + i * 20})`;
      });

    g.append("rect")
      .attr("x", -19)
      .attr("width", 19)
      .attr("height", 19)
      .attr("class", d => {
        return `${d.cssColorClass} token`;
      })
      .on("mouseover", function(d) {
        if (!d.hidden) d3.select(this).style("cursor", "pointer");
      })
      .on("mouseout", function() {
        d3.select(this).style("cursor", "default");
      })
      .on("click", d => {
        filterBars(d);
      });

    g.append("text")
      .attr("x", -24)
      .attr("y", 9.5)
      .attr("dy", "0.35em")
      .text(d => {
        return d.name === "input"
          ? "Training sequences"
          : "Generated sequences";
      });
  };

  svg.call(tip);
  svg.append("g").call(legend);

  const barsAreaSelection = svg.append("g");

  const tokensSelection = barsAreaSelection
    .selectAll("g")
    .data(data)
    .join("g")
    .attr("transform", d => `translate(${xTokenScale(d.token)},${margin})`);

  const t = svg.transition().duration(TRANSITION_DURATION);

  tokensSelection
    .selectAll("rect")
    .data(d => {
      const res = categories.map(key => {
        return {
          token: d.token,
          key: key.name,
          value: d.values.filter(value => value.type === key.name)[0].value
        };
      });
      return res;
    })
    .join(enter => {
      return enter
        .append("rect")
        .attr("x", d => {
          return xGroupScale(d.key);
        })
        .attr("y", yScale(0))
        .attr("width", xGroupScale.bandwidth())
        .attr("height", 0)
        .attr("class", d => {
          return `${
            categories.find(category => category.name === d.key).cssColorClass
          } token`;
        })
        .call(enter =>
          enter
            .transition(t)
            .attr("height", d => yScale(0) - yScale(d.value))
            .attr("y", d => yScale(d.value))
        )
        .on("mouseover", tip.show)
        .on("mouseout", tip.hide);
    });

  svg.append("g").call(yAxis);
  svg
    .append("text")
    .attr("class", widgetCardStyles.label)
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height / 2))
    .attr("dy", "3.4em")
    .style("text-anchor", "middle")
    .text(Y_AXIS_LABEL);

  svg.append("g").call(xAxis);
  svg
    .append("text")
    .attr("class", widgetCardStyles.label)
    .attr("x", width / 2)
    .attr("y", height - 0.3 * margin)
    .style("text-anchor", "middle")
    .text(X_AXIS_LABEL);

  function filterBars(category) {
    if (!category.clicked) {
      if (!category.hidden) {
        category.clicked = true;
        categories.filter(
          _category => _category.name !== category.name
        )[0].hidden = true;
        d3.selectAll("rect")
          .filter(
            d =>
              Object.keys(d).includes("graph") &&
              d.graph === "token" &&
              d.cssColorClass !== category.cssColorClass
          )
          .transition(2 * t)
          .style("opacity", 0.1);

        d3.selectAll("rect")
          .filter(d => Object.keys(d).includes("token"))
          .filter(d => d.key !== category.name)
          .transition(2 * t)
          .style("opacity", 0.1);
      }
    } else {
      category.clicked = false;
      categories.filter(
        _category => _category.name !== category.name
      )[0].hidden = false;
      d3.selectAll("rect")
        .filter(
          d =>
            Object.keys(d).includes("graph") &&
            d.graph === "token" &&
            d.cssColorClass !== category.cssColorClass
        )
        .transition(2 * t)
        .style("opacity", 1);
      d3.selectAll("rect")
        .filter(d => Object.keys(d).includes("token"))
        .filter(d => d.key !== category.name)
        .transition(2 * t)
        .style("opacity", 1);
    }
  }
}

export { drawSigmaAminoAcidHistogram };
