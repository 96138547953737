/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { MenuItem, Alert, Button } from "@blueprintjs/core";
import { DataTable } from "@teselagen/ui";
import { startCase } from "lodash";

import { isRegexRule } from "../../utils/designUtils";

import "./style.css";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { safeDelete } from "../../../src-shared/apolloMethods";
import Tag from "../../../src-shared/Tag";
import TagsRenderingWrapper from "../../../src-shared/TagsRenderingWrapper";
import LoadingOrNotFound from "../../../src-shared/components/LoadingOrNotFound";

const schema = {
  modal: "rule",
  fields: [
    { path: "id", type: "string", displayName: "Id", isHidden: true },
    { path: "name", type: "string", displayName: "Name" },
    { path: "requiredTags", type: "string", displayName: "Required Tags" },
    { path: "restrictedTags", type: "string", displayName: "Restricted Tags" },
    {
      path: "atLeastOneOfTags",
      type: "string",
      displayName: "At Least One of Tags"
    },
    { path: "regex", type: "string", displayName: "Regex" },
    { path: "type", type: "string", displayName: "Type" }
  ]
};

const tagRulesToRender = existenceValue => (_, { tagRules }) => (
  <TagsRenderingWrapper>
    {tagRules
      .filter(tr => tr.existence === existenceValue)
      .map(({ id, tagOption, tag }) => (
        <Tag
          key={id}
          style={{ marginRight: 3 }}
          {...(tagOption || tag)}
          name={`${tag.name + (tagOption ? `: ${tagOption.name}` : "")}`}
        />
      ))}
  </TagsRenderingWrapper>
);

const cellRenderer = {
  type: (type, rule) => (isRegexRule(rule) ? startCase(type) : "Tag Rule"),
  restrictedTags: tagRulesToRender("restricted"),
  requiredTags: tagRulesToRender("required"),
  atLeastOneOfTags: tagRulesToRender("at-least-one-of")
};

class RuleSetEdit extends React.Component {
  state = {
    isOpenDelete: false
  };
  handleDeleteClick = () => {
    const { ruleSet, associatedBinRuleSetIds, history } = this.props;
    safeDelete("ruleSet", ruleSet.id)
      .then(() => safeDelete("binRuleSet", associatedBinRuleSetIds))
      .then(() => history.push("/"))
      .catch(err => {
        console.error(err);
        window.toastr.error("Error deleting design rule set.");
      });
  };

  handleEditClick = () => {
    const { ruleSet, refetchRuleSet } = this.props;
    showDialog({
      modalType: "CREATE_RULE_SET",
      modalProps: {
        ruleSet,
        refetchRuleSet
      }
    });
  };

  handleAddRuleClick = () => {
    const { ruleSet, refetchRuleSet } = this.props;
    showDialog({
      modalType: "ADD_DESIGN_RULE",
      modalProps: {
        ruleSet,
        refetchRuleSet
      }
    });
  };

  goToEditRule = rule => {
    const { ruleSet, refetchRuleSet } = this.props;
    showDialog({
      modalType: isRegexRule(rule)
        ? "ADD_REGEX_DESIGN_RULE"
        : "ADD_TAG_DESIGN_RULE",
      modalProps: {
        ruleSet,
        refetchRuleSet,
        rule
      }
    });
  };

  deleteRule = rule => {
    const { refetchRuleSet } = this.props;
    safeDelete("rule", rule.id)
      .then(() => refetchRuleSet())
      .catch(err => {
        console.error(err);
        window.toastr.error("Error deleting design rule.");
      });
  };

  handleDataTableDoubleClick = rule => this.goToEditRule(rule);

  handleDataTableContextMenu = ({ selectedRecords }) => {
    if (!selectedRecords || !selectedRecords.length) return;
    return [
      <MenuItem
        key="edit"
        text="Edit"
        onClick={() => this.goToEditRule(selectedRecords[0])}
      />,
      <MenuItem
        key="delete"
        text="Delete"
        onClick={() => this.deleteRule(selectedRecords[0])}
      />
    ];
  };

  render() {
    const { ruleSet, ruleSetLoading } = this.props;
    if (ruleSetLoading || !ruleSet) {
      return (
        <LoadingOrNotFound
          {...{ objectName: "Design Ruleset", loading: ruleSetLoading }}
        />
      );
    }
    return (
      <div className="rule-set-edit-container">
        <div className="rule-set-edit-header">
          <div>
            <h4>Design Ruleset: {ruleSet.name} </h4>
          </div>
          <div className="bp3-button-group">
            <Button
              minimal
              className="rule-set-edit-header-button bp3-large"
              text="Add Rule"
              onClick={this.handleAddRuleClick}
            />
            <Button
              minimal
              className="rule-set-edit-header-button bp3-large"
              text="Edit"
              onClick={this.handleEditClick}
            />
            <Button
              minimal
              className="rule-set-edit-header-button bp3-large"
              text="Delete"
              onClick={() => this.setState({ isOpenDelete: true })}
            />
          </div>
        </div>
        <Alert
          isOpen={this.state.isOpenDelete}
          confirmButtonText="OK"
          cancelButtonText="Cancel"
          onConfirm={this.handleDeleteClick}
          onCancel={() => this.setState({ isOpenDelete: false })}
        >
          <p>Are you sure you want to remove {ruleSet.name}?</p>
        </Alert>
        <DataTable
          formName="RuleSetRules"
          compact
          isLoading={false}
          urlConnected={false}
          withPaging={false}
          withSearch={false}
          isInfinite
          isSimple
          noHeader
          noFooter
          hideSelectedCount
          isSingleSelect
          schema={schema}
          entities={ruleSet.rules}
          onDoubleClick={this.handleDataTableDoubleClick}
          contextMenu={this.handleDataTableContextMenu}
          cellRenderer={cellRenderer}
        />
      </div>
    );
  }
}

export default RuleSetEdit;
