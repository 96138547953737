/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import DAG from "./DAG";

const CREATES_ORDER = {
  sequenceFragment: 0,
  sequenceFeature: 1,
  part: 1
};

/**
 * Since ids are not necessarily unique across models, this will
 * produce a unique id.
 * @param {string} id
 * @param {string} model
 */
function getKey(id, model) {
  return `${model}:${id}`;
}

/**
 * Given a key, enable the original id and model to be recovered.
 * @param {string} key
 */
function parseKey(key) {
  const [model, id] = key.split(":");
  return { model, id };
}

/**
 *
 * @param {*} creates
 * @param {*} TYPES_TO_CHECK
 * @param {*} SIMPLE_REFERENCES_TO_TYPE
 */
function constructGraph(creates, TYPES_TO_CHECK, SIMPLE_REFERENCES_TO_TYPE) {
  const dag = new DAG();

  for (const model of Object.keys(creates)) {
    if (!TYPES_TO_CHECK[model]) continue;
    for (const [id, item] of Object.entries(creates[model])) {
      const srcKey = getKey(id, model);

      dag.addNode(srcKey, item);

      // Add the relevant edges to the graph. If an item is referenced by
      // another item, the reference item must be created first.
      for (const [field, dstModel] of Object.entries(
        SIMPLE_REFERENCES_TO_TYPE[model] || {}
      )) {
        const dstId = item[field];

        // We don't care about references to items that already exist in the database.
        if (!TYPES_TO_CHECK[dstModel] || !dstId) continue;

        const dstKey = getKey(dstId, dstModel);
        dag.addEdge(dstKey, srcKey);
      }
    }
  }

  return dag;
}

/**
 * Each createMap must also be ordered. Specifically the one that groups
 * part, sequenceFeature and sequenceFragments as destination nodes of the "sequence" model.
 * Its definitely imperative that sequenceFragments get created before parts and features!
 */
function sortCreateMap(createMap) {
  const sortedCreateMap = {};
  Object.keys(createMap)
    .sort((a, b) => CREATES_ORDER[a] - CREATES_ORDER[b])
    .forEach(key => {
      sortedCreateMap[key] = createMap[key];
    });

  return sortedCreateMap;
}

function groupKeysToCreateMap(dag, groupKeys) {
  const createMap = {};

  for (const key of groupKeys) {
    const { model } = parseKey(key);
    if (!createMap[model]) createMap[model] = [];
    const value = dag.getValue(key);
    if (value) createMap[model].push(value);
  }

  return sortCreateMap(createMap);
}

/**
 * Given a some information about what to entities need to be created, construct
 * an order for the creation. If object A is referenced by object B, then object A
 * must be created before object B. If object A needs objects B and C, and objects
 * B and C have no dependencies, then we can create B and C in parallel. The grouped
 * nature of the topological sort implementation provided here enables this.
 *
 * This function returns an array of maps from model to values to upsert.
 * @param {Object} creates
 * @param {Object} TYPES_TO_CHECK
 * @param {Object} SIMPLE_REFERENCES_TO_TYPE
 */
export default function groupedTopoSort(
  creates,
  TYPES_TO_CHECK,
  SIMPLE_REFERENCES_TO_TYPE
) {
  const dag = constructGraph(
    creates,
    TYPES_TO_CHECK,
    SIMPLE_REFERENCES_TO_TYPE
  );

  // We cannot topologically sort a cyclic graph.
  if (dag.isCyclic()) {
    throw new Error("Cannot topologically sort as graph contains a cycle.");
  }

  const groupKeys = [dag.getSourceKeys()];
  let numRemaining = dag.getNumberOfNodes() - groupKeys[0].length;

  while (numRemaining > 0) {
    const lastGroup = groupKeys[groupKeys.length - 1];

    // The keys of vertices that can be the new source vertices after
    // we have deleted the current source vertices from the graph.
    const sourceCandidates = {};
    for (const u of lastGroup) {
      for (const v of dag.getOutVertexKeys(u)) {
        sourceCandidates[v] = true;
        dag.removeEdge(u, v);
      }
    }

    const newSources = Object.keys(sourceCandidates).filter(
      u => dag.getInDegree(u) === 0
    );

    groupKeys.push(newSources);
    numRemaining -= newSources.length;
  }

  return groupKeys.map(keys => groupKeysToCreateMap(dag, keys));
}
