/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import dataTablePartialFragment from "../../../graphql/fragments/dataTablePartialFragment";
import { showDialog } from "../../../../src-shared/GlobalDialog";

const schema = {
  model: "dataTable",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "dataTableType.name",
      type: "string",
      displayName: "Type"
    },
    {
      path: "dataSet.name",
      type: "string",
      displayName: "Data Set"
    }
  ]
};

class DataTablesInventory extends Component {
  onDoubleClick = record => {
    const { history } = this.props;
    history.push(`/data-tables/${record.id}`);
  };

  showUpload = () => {
    const { refetchDataTables } = this.props;
    showDialog({
      modalType: "UPLOAD_DATA_TABLE",
      modalProps: {
        refetch: refetchDataTables
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        showUploadFunction={this.showUpload}
        isLibraryTable
        model="dataTable"
        withExport
        noNewItem
        onDoubleClick={this.onDoubleClick}
      />
    );
  }
}

export default libraryEnhancer({
  schema,
  fragment: dataTablePartialFragment
})(DataTablesInventory);
