/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { get, keyBy } from "lodash";
import { withRouter } from "react-router-dom";
import actions from "../../src-shared/redux/actions";
import RowIndexCell from "../components/HierarchicalDesign/DesignElementCard/RowIndexCell";
import { CELL_HEIGHT } from "../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";

import {
  getNumberOfRowsForCard,
  isCardRoot,
  treeLayout
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";
import {
  LIST_ELEMENT_ROW_HEIGHT,
  ROW_INDEX_WIDTH
} from "../../src-shared/components/HierarchicalDesign/constants";

const mapStateToProps = (state, props) => {
  const { cardId } = props;
  const isClassicLayout = treeLayout(state) === "classic";
  const selectedIndexes = keyBy(
    state.ui.designEditor.general.selectedCellPaths,
    "index"
  );
  const selectedCard = get(
    state.ui.designEditor.general,
    "selectedCardId",
    null
  );
  return {
    numberOfRows: getNumberOfRowsForCard(state, cardId),
    isLocked: isDesignLocked(state),
    isRoot: isCardRoot(state, cardId),
    cellWidth: isClassicLayout ? CELL_HEIGHT : ROW_INDEX_WIDTH,
    cellHeight: isClassicLayout ? CELL_HEIGHT : LIST_ELEMENT_ROW_HEIGHT,
    isClassicLayout,
    selectedIndexes,
    selectedCard
  };
};

const mapDispatchToProps = {
  selectRow: actions.ui.designEditor.general.selectRow,
  selectAllRows: actions.ui.designEditor.general.selectAllRows
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(RowIndexCell);
