/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DataTable, InputField } from "@teselagen/ui";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";

function ReviewReactionMap({ reactions }) {
  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Name and Review Reaction Map"
        helper="Provide a name for the reaction map and review the reactions below."
      />
      <div style={{ maxWidth: 250 }}>
        <InputField
          isRequired
          label="Reaction Map Name"
          name="reactionMapName"
        />
      </div>
      <DataTable
        formName="reactionMapReviewDataTable"
        schema={["name", "inputs", "outputs"]}
        entities={reactions}
        isSimple
        noSelect
      />
    </div>
  );
}

export default stepFormValues("reactions")(ReviewReactionMap);
