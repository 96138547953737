/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { Button, Intent } from "@blueprintjs/core";
import {
  InputField,
  ReactSelectField,
  CheckboxField,
  RadioGroupField
} from "@teselagen/ui";
import {
  barcodeColumn,
  dateModifiedColumn
} from "../../../../src-shared/utils/libraryColumns";
import PlatePreviewDialog from "../../Dialogs/PlatePreviewDialog";
import { get } from "lodash";
import stepFormValues from "../../../../src-shared/stepFormValues";
import NewColumnPlateInfo from "./NewColumnPlateInfo";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";

function ColumnPlateSelection({
  // passed
  index,
  destinationPlateFormat,
  sourcePlate,
  containerArrayTypes,
  toolSchema,

  // from stepFormValues
  operationType,
  newOrExistingDestinationPlates,
  newOrExistingReplicates = {},
  replicationColumnPlates = {},
  replicationCollectionPlates = {},
  newOrExistingCollectionPlates = {},
  newColumnPlateInfo = {},
  newCollectionPlateInfo = {}
}) {
  let sourcePlateKey;
  if (sourcePlate) {
    sourcePlateKey = "id" + sourcePlate.id;
  } else {
    sourcePlateKey = "index" + index;
  }

  let newOrExistingVal;
  if (operationType === "REPLICATION") {
    newOrExistingVal = newOrExistingReplicates[sourcePlateKey];
  } else if (operationType === "COMBINATION") {
    newOrExistingVal = newOrExistingDestinationPlates;
  }

  const isColumn =
    newOrExistingVal === "EXISTING_COLUMN" || newOrExistingVal === "NEW_COLUMN";
  if (!isColumn) return null;

  let columnPlates = [];
  const newColumnPlateInfoForPlate = newColumnPlateInfo[sourcePlateKey] || {};
  if (newOrExistingVal === "EXISTING_COLUMN") {
    columnPlates = replicationColumnPlates[sourcePlateKey] || [];
  } else {
    columnPlates = [newColumnPlateInfoForPlate];
  }
  const destinationFormatCode = sourcePlate
    ? sourcePlate.containerArrayType.containerFormatCode
    : destinationPlateFormat.code;

  const collectionContainerArrayTypes = containerArrayTypes.filter(
    containerArrayType => {
      const properFormat =
        containerArrayType.containerFormatCode === destinationFormatCode;
      return properFormat && !containerArrayType.isColumn;
    }
  );

  const newOrExistingCollectionPlatesForPlate =
    newOrExistingCollectionPlates[sourcePlateKey] || {};
  const newCollectionPlateInfoForPlate =
    newCollectionPlateInfo[sourcePlateKey] || {};
  const replicationCollectionPlatesForPlate =
    replicationCollectionPlates[sourcePlateKey] || {};
  return (
    <React.Fragment>
      {newOrExistingVal === "EXISTING_COLUMN" && (
        <GenericSelect
          {...{
            name: `replicationColumnPlates.${sourcePlateKey}`,
            schema: ["name", barcodeColumn, dateModifiedColumn],
            isMultiSelect: true,
            minSelected: 1,
            getButtonText: v =>
              v?.length ? "Change Column Plates" : "Select Column Plates",
            sourcePlate,
            fragment: [
              "containerArray",
              "id barcode { id barcodeString } name updatedAt"
            ],
            destinationPlateFormat,
            additionalDataFragment: [
              "containerArray",
              /* GraphQL */ `
                {
                  id
                  name
                  containerArrayType {
                    id
                    name
                    isColumn
                    containerFormatCode
                  }
                  aliquotContainers {
                    id
                    aliquot {
                      id
                    }
                  }
                  collectionPlateId
                  containerArray {
                    id
                    name
                  }
                  updatedAt
                }
              `
            ],
            tableParamOptions: {
              additionalFilter: columnReplicationFilter
            }
          }}
        />
      )}
      {newOrExistingReplicates && newOrExistingVal === "NEW_COLUMN" && (
        <NewColumnPlateInfo
          {...{
            sourcePlateKey,
            toolSchema,
            containerArrayTypes,
            destinationFormatCode
          }}
        />
      )}
      {!!columnPlates.length && (
        <hr className="tg-section-break" style={{ width: "30%" }} />
      )}
      {!!columnPlates.length &&
        columnPlates.map((columnPlate = {}, i) => {
          let columnPlateKey;
          if (columnPlate.id) {
            columnPlateKey = "id" + columnPlate.id;
          } else {
            columnPlateKey = "index" + i;
          }
          const newOrExisting =
            newOrExistingCollectionPlatesForPlate[columnPlateKey];
          const newCollectionPlateInfoForColumnPlate =
            newCollectionPlateInfoForPlate[columnPlateKey] || {};
          let collectionPlateName;
          if (newOrExisting === "EXISTING") {
            if (replicationCollectionPlatesForPlate[columnPlateKey]) {
              collectionPlateName =
                replicationCollectionPlatesForPlate[columnPlateKey].name;
            }
          } else {
            collectionPlateName = newCollectionPlateInfoForColumnPlate.name;
          }
          return (
            <div
              key={`${sourcePlateKey}.${columnPlateKey}`}
              className="tg-flex column"
            >
              <div className="tg-flex">
                <h6
                  style={{
                    marginBottom: 0,
                    marginRight: 5,
                    marginTop: 5
                  }}
                >
                  {"Column Plate: " + (columnPlate.name || "")}
                </h6>
                {columnPlate.id && (
                  <Button
                    intent={Intent.PRIMARY}
                    small
                    minimal
                    icon="eye-open"
                    onClick={() => {
                      showDialog({
                        ModalComponent: PlatePreviewDialog,
                        modalProps: {
                          containerArrayId: columnPlate.id,
                          dialogProps: {
                            title: `Preview ${columnPlate.name}`
                          }
                        }
                      });
                    }}
                  />
                )}
              </div>
              <div className="tg-flex" style={{ marginBottom: 15 }}>
                <h6
                  style={{
                    marginBottom: 0,
                    marginRight: 5,
                    marginTop: 5
                  }}
                >
                  {`Collection Plate: ${collectionPlateName || "Not Selected"}`}
                </h6>
                {newOrExisting === "EXISTING" &&
                  replicationCollectionPlatesForPlate[columnPlateKey] && (
                    <Button
                      intent={Intent.PRIMARY}
                      small
                      minimal
                      icon="eye-open"
                      onClick={() => {
                        showDialog({
                          ModalComponent: PlatePreviewDialog,
                          modalProps: {
                            containerArrayId:
                              replicationCollectionPlatesForPlate[
                                columnPlateKey
                              ].id,
                            dialogProps: {
                              title: `Preview ${replicationCollectionPlatesForPlate[columnPlateKey].name}`
                            }
                          }
                        });
                      }}
                    />
                  )}
              </div>
              <RadioGroupField
                name={`newOrExistingCollectionPlates.${sourcePlateKey}.${columnPlateKey}`}
                options={[
                  { label: "Existing Collection Plate", value: "EXISTING" },
                  {
                    label: "New Collection Plate",
                    value: "NEW"
                  }
                ]}
                defaultValue="EXISTING"
              />
              {newOrExisting === "EXISTING" ? (
                <GenericSelect
                  {...{
                    name: `replicationCollectionPlates.${sourcePlateKey}.${columnPlateKey}`,
                    schema: ["name", dateModifiedColumn],
                    minSelected: 1,
                    buttonProps: {
                      small: true,
                      text:
                        replicationCollectionPlatesForPlate[columnPlateKey] &&
                        replicationCollectionPlatesForPlate[columnPlateKey]
                          .aliquotContainers.length > 0
                          ? "Change Collection Plate"
                          : "Select Collection Plate"
                    },
                    sourcePlate,
                    destinationPlateFormat,
                    fragment: ["containerArray", "id name updatedAt"],
                    additionalDataFragment: [
                      "containerArray",
                      /* GraphQL */ `
                        {
                          id
                          name
                          containerArrayType {
                            id
                            name
                            isColumn
                            containerFormatCode
                          }
                          aliquotContainers {
                            id
                            aliquot {
                              id
                            }
                          }
                          collectionPlateId
                          containerArray {
                            id
                            name
                          }
                        }
                      `
                    ],
                    tableParamOptions: {
                      additionalFilter: collectionReplicationFilter
                    }
                  }}
                />
              ) : (
                <div style={{ width: "50%" }}>
                  <InputField
                    name={`newCollectionPlateInfo.${sourcePlateKey}.${columnPlateKey}.name`}
                    isRequired
                    label="Collection Plate Name"
                    placeholder="Enter collection plate name..."
                  />
                  <CheckboxField
                    name={`newCollectionPlateInfo.${sourcePlateKey}.${columnPlateKey}.generateBarcode`}
                    label="Generate Barcode For Collection Plate"
                    defaultValue
                  />
                  {!newCollectionPlateInfoForColumnPlate.generateBarcode && (
                    <InputField
                      name={`newCollectionPlateInfo.${sourcePlateKey}.${columnPlateKey}.barcode`}
                      label="Collection Plate Barcode"
                    />
                  )}
                  <ReactSelectField
                    name={`newCollectionPlateInfo.${sourcePlateKey}.${columnPlateKey}.plateType`}
                    label="Collection Plate Type"
                    isRequired
                    enableReinitialize
                    options={arrayToItemValuedOptions(
                      collectionContainerArrayTypes
                    )}
                  />
                </div>
              )}
              {i !== columnPlates.length - 1 && (
                <hr
                  className="tg-section-break dashed"
                  style={{ width: "30%" }}
                />
              )}
            </div>
          );
        })}
    </React.Fragment>
  );
}

export default stepFormValues(
  "operationType",
  "newOrExistingDestinationPlates",
  "newOrExistingReplicates",
  "replicationColumnPlates",
  "replicationCollectionPlates",
  "newOrExistingCollectionPlates",
  "newCollectionPlateInfo",
  "newColumnPlateInfo"
)(ColumnPlateSelection);

const columnReplicationFilter = (props, qb) => {
  const { sourcePlate, destinationPlateFormat } = props;
  const filter = {
    "containerArrayType.isColumn": true
  };
  // the selected plate ids doesn't really make sense, come back and fix if necessary
  // if (selectedPlateIds.length) {
  //   filter.id = qb.notInList(selectedPlateIds);
  // }
  if (sourcePlate) {
    filter["containerArrayType.containerFormatCode"] = get(
      sourcePlate,
      "containerArrayType.containerFormatCode"
    );
  }
  if (destinationPlateFormat) {
    filter["containerArrayType.containerFormatCode"] =
      destinationPlateFormat.code;
  }
  qb.whereAll(filter);
};

const collectionReplicationFilter = (props, qb) => {
  const { sourcePlate, destinationPlateFormat } = props;
  const filter = {
    "containerArrayType.isColumn": false
  };
  // if (selectedPlateIds.length) {
  //   filter.id = qb.notInList(selectedPlateIds);
  // }
  if (sourcePlate) {
    filter["containerArrayType.containerFormatCode"] = get(
      sourcePlate,
      "containerArrayType.containerFormatCode"
    );
  }
  if (destinationPlateFormat) {
    filter["containerArrayType.containerFormatCode"] =
      destinationPlateFormat.code;
  }
  qb.whereAll(filter);
};
