/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getElementsInBin,
  getItemOfType,
  getSequenceStringsOfElement
} from "../../selectors/designStateSelectors";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

function getRegex(regexStr, validateStart, spansBin) {
  if (spansBin) {
    return new RegExp("^" + regexStr + "$", "i");
  } else if (validateStart) {
    return new RegExp("^" + regexStr, "i");
  } else {
    return new RegExp(regexStr + "$", "i");
  }
}

export function isElementValid(state, binDirection, elementId, regex) {
  const seqStrs = getSequenceStringsOfElement(state, elementId);
  return seqStrs.every(s => {
    if (!binDirection) {
      s = getReverseComplementSequenceString(s);
    }
    return regex.test(s);
  });
}

/**
 * Get a set of the elements in the bin that don't match the given regex.
 * @param {Object} state
 * @param {string} binId Validate all of the elements in this bin.
 * @param {string} regexStr The string version of the regex we want to use for validation.
 * @param {boolean} validateStart Whether to validate the start or end of the sequences in the bin.
 * @param {boolean} spansBin Whether to validate the entire length of sequence in the bin. Ovrrides the `validateStart` option.
 */
export default function validateBinAgainstRegex(
  state,
  binId,
  regexStr,
  validateStart,
  spansBin
) {
  const regex = getRegex(regexStr, validateStart, spansBin);
  const elements = getElementsInBin(state, binId);
  const direction = getItemOfType(state, "bin", binId).direction;

  const invalidElementIds = {};
  for (const el of elements) {
    if (!isElementValid(state, direction, el.id, regex)) {
      invalidElementIds[el.id] = true;
    }
  }

  return invalidElementIds;
}
