/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment taskParameterTypeFragment on taskParameterType {
    code
    name
    description
    isSystemType
  }
`;
