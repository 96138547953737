/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatten, get, times, zip } from "lodash";

/**
 * Get the fields to add to the assembly piece schema to show the information
 * about the overhangs/overlaps.
 */
export const getOverhangFields = (isGoldenGate, isGibson) => {
  if (!isGoldenGate && !isGibson) return [];
  const over = isGoldenGate ? "Overhang" : "Overlap";
  return [
    isGoldenGate && {
      path: "overhangWithPrevious",
      type: "string",
      displayName: `${over} w/ Previous`
    },
    isGoldenGate && {
      path: "overhangWithPrevious",
      type: "number",
      isHidden: true,
      displayName: `${over} w/ Previous Length`,
      render: over => (over ? over.length : "n/a")
    },
    {
      path: "overhangWithNext",
      type: "string",
      displayName: `${over} w/ Next`
    },
    {
      path: "overhangWithNext",
      type: "number",
      width: 100,
      isHidden: true,
      displayName: `${over} w/ Next Length`,
      render: over => (over ? over.length : "n/a")
    },
    {
      path: "relativeOverhang",
      type: "number",
      displayName: `Relative ${over} Position`
    }
  ].filter(x => x);
};

export const getExtraBpsFields = isGibson =>
  isGibson
    ? [
        {
          path: "extra5PrimeCpecBps",
          displayName: "Extra 5' Bps"
        },
        {
          path: "extra3PrimeCpecBps",
          displayName: "Extra 3' Bps"
        }
      ]
    : [];

export const getAssemblyPiecePartsContained = piece => {
  return [...piece.j5AssemblyPieceParts]
    .sort((a, b) => a.index - b.index)
    .map(piecePart => piecePart.j5InputPart.part?.name)
    .join(", ");
};

export const getConstructAssemblyColumns = j5RunConstructs => {
  let maxNumAssemblyPieces = 0;
  j5RunConstructs.forEach(c => {
    const numAssemblyPieces = c.j5ConstructAssemblyPieces.length;
    if (numAssemblyPieces > maxNumAssemblyPieces)
      maxNumAssemblyPieces = numAssemblyPieces;
  });
  const assemblyPieceColumns = times(maxNumAssemblyPieces, i => ({
    path: `j5ConstructAssemblyPieces[${i}].assemblyPiece.id`,
    displayName: `Piece-${i + 1} ID`,
    isHidden: true
  }));
  const assemblyPieceNameColumns = times(maxNumAssemblyPieces, i => ({
    path: `j5ConstructAssemblyPieces[${i}].assemblyPiece.name`,
    displayName: `Piece-${i + 1} Name`,
    width: 100
  }));
  const partsContainedColumns = times(maxNumAssemblyPieces, i => {
    return {
      path: `j5ConstructAssemblyPieces[${i}].assemblyPiece.j5AssemblyPieceParts`,
      displayName: `Piece-${i + 1} Parts`,
      render: v => {
        if (typeof v === "string") {
          return v;
        }
        return v && v.map(p => get(p, "j5InputPart.part.name")).join(", ");
      }
    };
  });
  const extraColumns = flatten(
    zip(assemblyPieceColumns, assemblyPieceNameColumns, partsContainedColumns)
  );
  return extraColumns;
};
