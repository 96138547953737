/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const addReagentsAdditivesFragment = gql`
  fragment addReagentsAdditivesFragment on additive {
    id
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
    lotId
    lot {
      id
      additiveMaterialId
    }
    additiveMaterialId
  }
`;

export const addReagentsContainerArrayFragment = gql`
  fragment addReagentsContainerArrayFragment on containerArray {
    id
    name
    displayFilter
    createdAt
    updatedAt
    containerArrayType {
      id
      name
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      additives {
        ...addReagentsAdditivesFragment
      }
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
        molarity
        molarityUnitCode
        cellCount
        cellConcentration
        cellConcentrationUnitCode
        additives {
          ...addReagentsAdditivesFragment
        }
        sample {
          id
          material {
            id
            polynucleotideMaterialSequence {
              id
              molecularWeight
            }
            functionalProteinUnit {
              id
              molecularWeight
            }
          }
        }
      }
    }
  }
  ${addReagentsAdditivesFragment}
`;
