/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import {
  NumericInputField,
  SelectField,
  RadioGroupField,
  ReactSelectField,
  InputField,
  SwitchField
} from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import Big from "big.js";
import shortid from "shortid";
import { Button, Intent } from "@blueprintjs/core";
import { getAliquotTransferVolumeFromMass } from "../../../../utils/plateUtils";

import DestinationPlateInfo from "../../AliquotRearrayTool/DestinationPlateInfo";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import MicrobialMaterialSelectSection from "../MicrobialMaterialSelectSection";
import {
  standardizeVolume,
  convertVolume
} from "../../../../../src-shared/utils/unitUtils";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { SubmissionError } from "redux-form";
import { get } from "lodash";
import IntermediateContainerTypeFields from "../../../IntermediateContainerTypeFields";
import { arrayToIdOrCodeValuedOptions } from "../../../../../src-shared/utils/formUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateEmptyWells } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";

class TransformationPrep extends Component {
  generateWorklist = (values, plates) => {
    const {
      aliquotContainers: inputTubes = [],
      inputType,
      strain,
      generateBarcodes,
      microbialTransferVolume,
      microbialTransferVolumeUnitCode,
      dnaTransferVolume,
      dnaTransferVolumeUnitCode,
      dnaTransferMass,
      dnaTransferMassUnitCode,
      dnaTransferType,
      microbialMaterial,
      destinationPlateBarcodes = [],
      destinationPlateNames = [],
      destinationPlateTypes = [],
      tubeBaseName,
      tubeType,
      transferToSourcePlates,
      intermediateContainerName,
      intermediateContainerType,
      generateIntermediateBarcode,
      intermediateBarcode
    } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;

    try {
      const microbialTransformationWorklist = {
        worklistTransfers: []
      };

      const microbialMaterialCid = shortid();

      let toBeCreatedDestinationPlates;
      let toBeCreatedDestinationTubes;
      const intermediateContainers = [];

      if (transferToSourcePlates) {
        let aliquotContainersToPrep = [];
        if (inputType === "PLATE") {
          plates.forEach(plate => {
            plate.aliquotContainers.forEach(ac => {
              if (ac.aliquot) {
                aliquotContainersToPrep.push({
                  ...ac,
                  plateName: plate.name
                });
              }
            });
          });
        } else {
          aliquotContainersToPrep = inputTubes;
        }
        const standardizedMaxWellVolume = standardizeVolume(
          intermediateContainerType.aliquotContainerType.maxVolume,
          intermediateContainerType.aliquotContainerType.volumetricUnitCode,
          true
        );
        const deadVolume =
          get(intermediateContainerType, "aliquotContainerType.deadVolume") ||
          0;
        const standardizedDeadVolume = standardizeVolume(
          deadVolume,
          intermediateContainerType.aliquotContainerType
            .deadVolumetricUnitCode || "uL",
          true
        );
        const standardizedMicrobialTransferVolume = standardizeVolume(
          microbialTransferVolume,
          microbialTransferVolumeUnitCode,
          true
        );
        const totalRequiredVolume = standardizedMicrobialTransferVolume.times(
          aliquotContainersToPrep.length
        );

        // this is the total volume needed for all transfers
        let volumeNeededForAllTransfers = totalRequiredVolume;
        // this is the max volume of the well we are transferring from
        let maxWellVolumeAdjusted = standardizedMaxWellVolume.minus(
          standardizedDeadVolume
        );

        // if the max well volume is not evenly divisible by the transfer volume then we need to
        // adjust it so it is (because we don't want to have to split the transfers to come from two wells)
        if (
          !standardizedMaxWellVolume
            .mod(standardizedMicrobialTransferVolume)
            .eq(0)
        ) {
          maxWellVolumeAdjusted = standardizedMaxWellVolume.minus(
            standardizedMaxWellVolume.mod(standardizedMicrobialTransferVolume)
          );
        }

        let plateIndex = 1;
        const getAliquotContainers = () => {
          const plateName = `${intermediateContainerName} ${plateIndex}`;
          const aliquotContainers = generateEmptyWells(
            intermediateContainerType.containerFormat,
            {
              containerArray: {
                name: plateName
              },
              aliquotContainerTypeCode:
                intermediateContainerType.aliquotContainerTypeCode
            }
          );
          plateIndex++;
          intermediateContainers.push({
            name: plateName,
            containerArrayTypeId: intermediateContainerType.id,
            aliquotContainers,
            ...(!generateIntermediateBarcode && {
              barcode: {
                barcodeString: intermediateBarcode
              }
            })
          });
          return aliquotContainers;
        };
        let currentAliquotContainers = getAliquotContainers();

        // this will keep track of how many transfers can come from each source
        // will be an array of objects with { aliquotContainer, numTransfers }
        // where numTransfers is the number of transfers this aliquot container
        // can be used for
        const transferHelper = [];
        const updateAliquotContainerForTransfer = ac => {
          let volume = maxWellVolumeAdjusted.plus(standardizedDeadVolume);
          if (!volumeNeededForAllTransfers.eq(0)) {
            const numTransfers = maxWellVolumeAdjusted
              .div(standardizedMicrobialTransferVolume)
              .round();
            // if this well can fit all the transfers then we just fill this well and move on
            if (
              volumeNeededForAllTransfers.minus(maxWellVolumeAdjusted).lt(0)
            ) {
              volume = volumeNeededForAllTransfers.plus(standardizedDeadVolume);
              volumeNeededForAllTransfers = new Big(0);
            } else {
              // else we subtract the number of transfers we can take from this well
              volumeNeededForAllTransfers = volumeNeededForAllTransfers.minus(
                numTransfers.times(standardizedMicrobialTransferVolume)
              );
            }
            transferHelper.push({
              aliquotContainer: ac,
              numTransfers
            });
            const aliquotContainerCid = shortid();
            ac.cid = aliquotContainerCid;
            ac.aliquot = {
              aliquotType: "sample-aliquot",
              volume: Number(
                convertVolume(
                  volume,
                  "L",
                  microbialTransferVolumeUnitCode
                ).toString()
              ),
              volumetricUnitCode: microbialTransferVolumeUnitCode,
              sample: {
                name: !microbialMaterial ? strain.name : microbialMaterial.name,
                sampleTypeCode: "REGISTERED_SAMPLE",
                materialId: !microbialMaterial
                  ? `&${microbialMaterialCid}`
                  : microbialMaterial.id
              }
            };
          }
        };
        let finishedAllTransfers = false;
        while (!finishedAllTransfers) {
          currentAliquotContainers.forEach(updateAliquotContainerForTransfer);
          if (volumeNeededForAllTransfers.eq(0)) {
            finishedAllTransfers = true;
          } else {
            currentAliquotContainers = getAliquotContainers();
          }
        }

        const getSourceAliquotContainer = () => {
          const aliquotContainer = transferHelper[0].aliquotContainer;
          transferHelper[0].numTransfers--;
          if (transferHelper[0].numTransfers === 0) {
            transferHelper.shift();
          }
          return aliquotContainer;
        };

        aliquotContainersToPrep.forEach(ac => {
          const sourceAliquotContainer = getSourceAliquotContainer();
          microbialTransformationWorklist.worklistTransfers.push({
            volume: microbialTransferVolume,
            volumetricUnitCode: microbialTransferVolumeUnitCode,
            destinationPlateName: ac.plateName,
            destinationAliquotContainer: ac,
            destinationAliquotContainerId: ac.id,
            sourceAliquotContainer,
            sourceAliquotContainerId: `&${sourceAliquotContainer.cid}`
          });
        });
      } else {
        let transferVolume = dnaTransferVolume,
          transferVolumetricUnitCode = dnaTransferVolumeUnitCode;
        if (inputType === "PLATE") {
          toBeCreatedDestinationPlates = plates.map((plate, index) => {
            return {
              name: destinationPlateNames[index],
              barcode: !generateBarcodes
                ? {
                    barcodeString: destinationPlateBarcodes[index]
                  }
                : undefined,
              containerArrayTypeId: destinationPlateTypes[index].id,
              aliquotContainers: []
            };
          });
          plates.forEach((plate, index) => {
            const createdLocationToACMap = {};
            plate.aliquotContainers.forEach(aliquotContainer => {
              if (aliquotContainer.aliquot) {
                const location = getAliquotContainerLocation(aliquotContainer);
                if (!createdLocationToACMap[location]) {
                  const ac = {
                    cid: shortid(),
                    rowPosition: aliquotContainer.rowPosition,
                    columnPosition: aliquotContainer.columnPosition,
                    aliquotContainerTypeCode:
                      destinationPlateTypes[index].aliquotContainerTypeCode,
                    aliquot: {
                      aliquotType: "sample-aliquot",
                      volume: microbialTransferVolume,
                      volumetricUnitCode: microbialTransferVolumeUnitCode,
                      sample: {
                        name: !microbialMaterial
                          ? strain.name
                          : microbialMaterial.name,
                        sampleTypeCode: "REGISTERED_SAMPLE",
                        materialId: !microbialMaterial
                          ? `&${microbialMaterialCid}`
                          : microbialMaterial.id
                      }
                    }
                  };
                  createdLocationToACMap[location] = ac;
                  toBeCreatedDestinationPlates[index].aliquotContainers.push(
                    ac
                  );
                }
                if (dnaTransferType === "mass") {
                  transferVolume = getAliquotTransferVolumeFromMass(
                    aliquotContainer.aliquot,
                    dnaTransferMass,
                    dnaTransferMassUnitCode
                  );

                  transferVolumetricUnitCode =
                    aliquotContainer.aliquot.volumetricUnitCode;
                }

                microbialTransformationWorklist.worklistTransfers.push({
                  volume: transferVolume,
                  volumetricUnitCode: transferVolumetricUnitCode,
                  sourceAliquotContainerId: aliquotContainer.id,
                  sourceAliquotContainer: {
                    ...aliquotContainer,
                    containerArray: plate
                  },
                  destinationPlateName: destinationPlateNames[index],
                  destinationAliquotContainer: {
                    ...createdLocationToACMap[location],
                    containerArrayType: destinationPlateTypes[index]
                  },
                  destinationAliquotContainerId: `&${createdLocationToACMap[location].cid}`
                });
              }
            });
          });
        } else {
          toBeCreatedDestinationTubes = inputTubes.map((tube, i) => {
            const destinationTubeCid = shortid();
            const destinationAliquotContainer = {
              cid: destinationTubeCid,
              name: `${tubeBaseName + " " + (i + 1)}`,
              barcode: undefined,
              aliquotContainerTypeCode: tubeType,
              aliquot: {
                aliquotType: "sample-aliquot",
                volume: microbialTransferVolume,
                volumetricUnitCode: microbialTransferVolumeUnitCode,
                sample: {
                  sampleTypeCode: "REGISTERED_SAMPLE",
                  name: !microbialMaterial
                    ? strain.name
                    : microbialMaterial.name,
                  materialId: !microbialMaterial
                    ? `&${microbialMaterialCid}`
                    : microbialMaterial.id
                }
              }
            };
            if (dnaTransferType === "mass") {
              transferVolume = getAliquotTransferVolumeFromMass(
                tube.aliquot,
                dnaTransferMass,
                dnaTransferMassUnitCode
              );

              transferVolumetricUnitCode = tube.aliquot.volumetricUnitCode;
            }
            microbialTransformationWorklist.worklistTransfers.push({
              volume: transferVolume,
              volumetricUnitCode: transferVolumetricUnitCode,
              sourceAliquotContainerId: tube.id,
              sourceAliquotContainer: {
                ...tube
              },
              destinationAliquotContainer,
              destinationAliquotContainerId: `&${destinationTubeCid}`
            });
            return destinationAliquotContainer;
          });
        }
      }
      change("intermediateContainers", intermediateContainers);
      change("destinationPlateTypes", destinationPlateTypes);
      change("destinationTubes", toBeCreatedDestinationTubes);
      change("destinationPlates", toBeCreatedDestinationPlates);
      change("worklist", microbialTransformationWorklist);
      nextStep();
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error generating worklist."
      });
    }
  };

  render() {
    const {
      microbialMaterial = {},
      Footer,
      footerProps,
      containerArrays = [],
      aliquotContainerTypes = [],
      dnaTransferType,
      transferToSourcePlates,
      toolSchema,
      inputType,
      handleSubmit
    } = this.props;
    const indexedPlates = containerArrays.map((plate, index) => {
      return {
        ...plate,
        index
      };
    });
    return (
      <React.Fragment>
        <MicrobialMaterialSelectSection
          header="Microbial Material Information"
          helper="Select an existing microbial material for transformation."
          microbialMaterial={microbialMaterial}
        />
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Transfers"
            helper={
              transferToSourcePlates
                ? `Enter the volume of microbial
                material to transfer to the selected source ${
                  inputType === "PLATE" ? "plates" : "tubes"
                }. Then
                enter a name and select a type for an intermediate container,
                which will hold the microbial material prior to transferring.
                By default, a barcode will be generated for the intermediate
                container. To specify a custom barcode, uncheck the box and
                and input the custom barcode. Finally, specify the dead volume
                expected for liquid handler and selected container type.`
                : `Enter the amount of microbial material and plasmid
                that should be transferred to the transformation ${
                  inputType === "PLATE" ? "plates" : "tubes"
                }.`
            }
          />
          <div style={{ width: 600 }}>
            <SwitchField
              name="transferToSourcePlates"
              label={`Transfer to Source ${
                inputType === "PLATE" ? "Plates" : "Tubes"
              }`}
            />
            <div className="input-with-unit-select">
              <NumericInputField
                generateDefaultValue={
                  defaultValueConstants.MICROBE_TRANSFER_VOLUME
                }
                {...defaultValueConstants.MICROBE_TRANSFER_VOLUME}
              />
              <SelectField
                name="microbialTransferVolumeUnitCode"
                label="none"
                defaultValue="uL"
                className="tg-unit-select"
                options={unitGlobals.getOptionsForSelect("volumetricUnit")}
              />
            </div>
            {transferToSourcePlates ? (
              <React.Fragment>
                <hr className="tg-section-break" />
                <IntermediateContainerTypeFields toolSchema={toolSchema} />
              </React.Fragment>
            ) : (
              <div>
                <hr className="tg-section-break" />
                <RadioGroupField
                  defaultValue="volume"
                  inline
                  options={[
                    {
                      label: "Volume",
                      value: "volume"
                    },
                    {
                      label: "Mass",
                      value: "mass"
                    }
                  ]}
                  label="DNA Transfer Type"
                  name="dnaTransferType"
                />
                {dnaTransferType === "volume" ? (
                  <div className="input-with-unit-select">
                    <NumericInputField
                      generateDefaultValue={
                        defaultValueConstants.DNA_TRANSFER_VOLUME
                      }
                      isRequired
                      {...defaultValueConstants.DNA_TRANSFER_VOLUME}
                    />
                    <SelectField
                      name="dnaTransferVolumeUnitCode"
                      label="none"
                      defaultValue="uL"
                      className="tg-unit-select"
                      options={unitGlobals.getOptionsForSelect(
                        "volumetricUnit"
                      )}
                    />
                  </div>
                ) : (
                  <div className="input-with-unit-select">
                    <NumericInputField
                      label="Mass"
                      name="dnaTransferMass"
                      isRequired
                      min={0}
                    />
                    <SelectField
                      name="dnaTransferMassUnitCode"
                      label="none"
                      defaultValue="ng"
                      className="tg-unit-select"
                      options={unitGlobals.getOptionsForSelect("massUnit")}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {!transferToSourcePlates && inputType === "PLATE" && (
          <div className="tg-step-form-section column">
            <DestinationPlateInfo
              plates={indexedPlates}
              isMicrobialTransformation={true}
              toolSchema={toolSchema}
            />
          </div>
        )}
        {!transferToSourcePlates && inputType === "TUBE" && (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Destination Tube Information"
              helper={`Enter a tube base name and select a tube type.
              Barcodes will be automatically generated for new tubes.`}
            />
            <div style={{ width: 600 }}>
              <InputField name="tubeBaseName" label="Tube Base Name" />
              <ReactSelectField
                name="tubeType"
                label="Destination Tube Type"
                options={arrayToIdOrCodeValuedOptions(aliquotContainerTypes)}
                defaultValue="TUBE"
              />
            </div>
          </div>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              intent={Intent.PRIMARY}
              onClick={handleSubmit(values =>
                this.generateWorklist(values, indexedPlates)
              )}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    options: { variables: { filter: { isTube: true } } }
  }),
  stepFormValues(
    "microbialMaterial",
    "containerArrays",
    "transferToSourcePlates",
    "strain",
    "inputType",
    "dnaTransferType"
  )
)(TransformationPrep);
