/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";

import { safeQuery } from "../../../src-shared/apolloMethods";
import caseInsensitiveFilter from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { startCase } from "lodash";
import { getIdOrCodeOrIndex } from "@teselagen/ui";

export const validateNameCsvTableUniqueAsync = ({ model, isCodeModel }) => ({
  tableWideAsyncValidation: async ({ entities }) => {
    //tnw: add async name uniqueness check
    const existing = await safeQuery(
      [model, `${isCodeModel ? "code" : "id"} name`],
      {
        ...(isCodeModel && { idAs: "code" }),
        variables: {
          filter: caseInsensitiveFilter(
            model,
            "name",
            entities.map(row => row.name)
          )
        }
      }
    );
    const keyedExisting = keyBy(existing, item => item.name.toLowerCase());
    const toRet = {};
    entities.forEach(row => {
      const { name } = row;
      if (keyedExisting[name.toLowerCase()]) {
        toRet[`${getIdOrCodeOrIndex(row)}:name`] = `${startCase(
          model
        )} ${name} already exists`;
      }
    });

    return toRet;
  }
});
