/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { filter, forEach, get, keyBy, uniqBy, cloneDeep } from "lodash";
import shortid from "shortid";
import QueryBuilder from "tg-client-query-builder";
import { tagModels } from "../../constants";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "../utils/caseInsensitiveFilter";
import createTaggedItems from "./createTaggedItems";

export const taggedItems = `taggedItems {
  id
  tagId
  tagOptionId
}`;

export function getTaggedItemModelKey(typename) {
  return `${typename}Id`;
}

export function tagNameWithOption(tag, tagOption) {
  const tagName = get(tag, "name", tag);
  const tagOptionName = get(tagOption, "name", tagOption);
  return `${tagName}: ${tagOptionName}`;
}

export const getSortedTaggedItems = record =>
  [...(record.taggedItems || [])].sort(({ tag: tagA }, { tag: tagB }) =>
    tagA.name.localeCompare(tagB.name)
  );

export const getTagsString = record =>
  getSortedTaggedItems(record)
    .map(
      ({ tag, tagOption }) =>
        `${tag.name + (tagOption ? `: ${tagOption.name}` : "")}`
    )
    .join(",");

export const exportTagsColumn = (v, record) => getTagsString(record);

/**
 * Receives an array of tags and returns an array of taggedItems: {tagId: string; tagOptionId: string|null}
 */
export function getTaggedItemsForCreate(_tags = {}) {
  const tags = cleanTagsValue(_tags);
  const tagIds = Object.keys(tags);
  return tagIds.map(tagId => {
    const tagOptionId = typeof tags[tagId] === "string" ? tags[tagId] : null;
    return {
      tagId,
      tagOptionId
    };
  });
}

/**
 * Receives a tag or an array of tags, and returns a dictionary of {[tagId]: boolean | tagOptionId}
 */
export function cleanTagsValue(tags = {}) {
  if (Array.isArray(tags)) {
    return tags.reduce((acc, tag) => {
      const _tagId = tag.id.toString(); //  Supports tag.id to be a number.
      if (_tagId.includes(":")) {
        const [tagId, tagOptionId] = _tagId.split(":");
        acc[tagId] = tagOptionId;
      } else {
        acc[_tagId] = true;
      }
      return acc;
    }, {});
  } else {
    return tags;
  }
}

/**
 * Receives a record or an array of records and adds the taggedItems property to each record.
 */
export function addTaggedItemsBeforeCreate(recordsToCreate = [], tags) {
  recordsToCreate = [].concat(recordsToCreate);
  const taggedItems = getTaggedItemsForCreate(tags);
  if (taggedItems.length) {
    recordsToCreate.forEach(r => {
      r.taggedItems = taggedItems;
    });
  }
  return recordsToCreate;
}

export async function copyRecordsTaggedItems(_records, ctx = isoContext) {
  const { safeQuery } = ctx;
  const records = Array.isArray(_records) ? _records : [_records];
  const tagsOnJob = await safeQuery(["taggedItem", `id tagId tagOptionId`], {
    variables: {
      filter: {
        [records[0].__typename + "Id"]: records.map(r => r.id)
      }
    }
  });
  const uniqueCleanedTaggedItems = uniqBy(tagsOnJob, "tagId").map(
    taggedItem => {
      const cleanedTaggedItem = { ...taggedItem };
      delete cleanedTaggedItem.id;
      delete cleanedTaggedItem.__typename;
      return cleanedTaggedItem;
    }
  );
  return uniqueCleanedTaggedItems;
}

const tagFrag = gql`
  fragment externalImportTagFragment on tag {
    id
    name
    tagOptions {
      id
      name
    }
  }
`;

export async function updateTagsOnExternalRecords(
  { records, model },
  ctx = isoContext
) {
  const { safeUpsert, safeQuery, deleteWithQuery } = ctx;
  // on import/update/export from node-red, tags can be specified on a record and we should create the tags if necessary and update the record with the tags
  // grab all tag types (by tag name), and query for existing ones and create ones that don't yet exist

  if (!tagModels.includes(model)) return;

  const removeAnyFilters = [];
  records.forEach(r => {
    const { __oldRecord, __newRecord } = r;
    if (!__oldRecord || !__newRecord) {
      return;
    }

    const newTags = [];
    if (r.tags) {
      r.tags.forEach(t => {
        if (t.__remove) {
          if (t.id) {
            removeAnyFilters.push({
              tagId: t.id,
              [model + "Id"]: r.id
            });
          }
        } else {
          newTags.push(t);
        }
      });
    }

    r.tags = newTags;
  });

  // deleting taggedItems where recordId is id and tagId is tag.id
  if (removeAnyFilters.length) {
    const qb = new QueryBuilder("taggedItem");
    const filter = qb.whereAny(...removeAnyFilters);
    await deleteWithQuery("taggedItem", filter);
  }

  const tagsFromRecordsByLowerName = {};
  records.forEach(r => {
    (r.tags || []).forEach(t => {
      const n = t.name.toLowerCase();
      if (!tagsFromRecordsByLowerName[n]) {
        tagsFromRecordsByLowerName[n] = {
          ...t,
          tagOptions: []
        };
      }
      const ops = tagsFromRecordsByLowerName[n].tagOptions;
      if (t.tagOption && !ops.some(op => op.name === t.tagOption)) {
        ops.push({
          name: t.tagOption,
          color: t.tagOptionColor
        });
      }
    });
  });

  if (Object.keys(tagsFromRecordsByLowerName).length) {
    // we're using a model that supports tags and we have tags to update on those models
    const existingTags =
      Object.keys(tagsFromRecordsByLowerName).length &&
      (await safeQuery(tagFrag, {
        variables: {
          filter: caseInsensitiveFilter(
            "tag",
            "name",
            Object.keys(tagsFromRecordsByLowerName)
          )
        }
      }));
    const existingTagsByLowerName = keyBy(existingTags, t =>
      t.name.toLowerCase()
    );

    const tagsToMake = filter(
      tagsFromRecordsByLowerName,
      (val, key) => !existingTagsByLowerName[key]
    );

    const newTagsByLowerName = keyBy(
      await safeUpsert(
        tagFrag,
        tagsToMake.map(t => ({
          name: t.name,
          color: t.color || "gray",
          tagOptions: t.tagOptions
        }))
      ),
      t => t.name.toLowerCase()
    );

    //we now have all the tags created in the system
    const tagsByLowerName = cloneDeep({
      ...newTagsByLowerName,
      ...existingTagsByLowerName
    });
    const recordIdsToTagIds = {};
    const tagOptionsToCreate = [];

    records.forEach(r => {
      const recordId = r.id || r.duplicate?.id;
      if (!recordId) {
        console.error(`Broken record with no id! 098127700:`, r);
        throw new Error(`Broken record with no id! name: ${r.name} 8912897711`);
      }
      const ent = (recordIdsToTagIds[recordId] = {});
      forEach(r.tags, t => {
        const tag = tagsByLowerName[t.name.toLowerCase()];
        let tagOptionId = true;
        if (t.tagOption) {
          const tagOption = tag.tagOptions.find(op => op.name === t.tagOption);

          if (!tagOption) {
            const cid = shortid();
            const newTagOption = {
              id: `&${cid}`,
              name: t.tagOption
            };
            tagOptionsToCreate.push({
              cid,
              tagId: tag.id,
              name: t.tagOption,
              color: t.tagOptionColor
            });
            tag.tagOptions = tag.tagOptions.concat(newTagOption);
            tagOptionId = newTagOption.id;
          } else {
            tagOptionId = tagOption.id;
          }
        } else if (!t.tagOption && tag.tagOptions.length) {
          // can't tag without an option if the tag has options
          console.warn(`Must provide a tag option for tag ${t.name}.`);
          return;
        }
        ent[tag.id] = tagOptionId;
      });
    });
    await safeUpsert("tagOption", tagOptionsToCreate);
    await createTaggedItems(
      {
        recordIdsToTagIds,
        model
      },
      ctx
    );
  }
}
