/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { CheckboxField } from "@teselagen/ui";

export const hasContentsInfo =
  "This determines if this equipment will show up in the list when placing items (plates, tubes, etc.)";

function HasContentsCheckbox(props) {
  return (
    <CheckboxField
      name="hasContents"
      label="Shown in placement dialogs"
      tooltipInfo={hasContentsInfo}
      {...props}
    />
  );
}

export default HasContentsCheckbox;
