/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import pascalCase from "pascal-case";
import gql from "graphql-tag";
import pluralize from "pluralize";
import generateFragmentWithFields from "./utils/generateFragmentWithFields";

/**
 * withDeleteQuery
 * @param {string | gql fragment} nameOrFragment supply either a name or a top-level fragment
 * @param {options}
 * @typedef {object} options
 * @property {obj | function} extraMutateArgs - obj or function that returns obj to get passed to the actual mutation call
 * @property {boolean} showError - default=true -- whether or not to show a default error message on failure
 * @property {boolean} asFunction {boolean} if true, this gives you back a function you can call directly instead of a HOC
 * @return deleteXXXX function that takes an id or an array of ids of records to delete. It returns a promise resolving to an array of created/updated outputs
 */

const withDeleteQuery = function(nameOrFragment, options = {}) {
  const { client, options: deleteOptions } = options;
  let fragment = typeof nameOrFragment === "string" ? null : nameOrFragment;
  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment);
  }
  const name = fragment
    ? fragment.definitions[0].typeCondition.name.value
    : nameOrFragment;
  const pluralRecordType = pluralize(pascalCase(name));
  const deleteWithName = `delete${pluralRecordType}WithQuery`;
  const deleteByQueryMutation = gql`
    mutation ${deleteWithName}($input: ${deleteWithName}Input!) {
      ${deleteWithName}(input: $input) {
        deletedCount
      }
    }
  `;

  if (!client)
    return console.error(
      "You need to pass the apollo client to withDeleteQuery if using as a function"
    );
  return function deleteEntities(deleteQueries, options = {}) {
    const { update } = options;
    return client.mutate({
      mutation: deleteByQueryMutation,
      ...deleteOptions,
      update,
      variables: {
        input: {
          deleteQueries
        }
      }
    });
  };
};

export default withDeleteQuery;
