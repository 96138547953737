/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose, withProps } from "recompose";
import withQueryDynamic from "../../../src-shared/withQueryDynamic";

function QueryLoader({ children, ...rest }) {
  return children(rest);
}

export default compose(
  withProps(props => {
    return {
      runTimeQueryOptions: props
    };
  }),
  withQueryDynamic()
)(QueryLoader);
