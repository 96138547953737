/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment fullUserFragment on user {
    id
    labRoles {
      id
      labId
      roleCode
      lab {
        id
        name
        color
      }
    }
    projectRoles {
      id
      projectId
      roleCode
      project {
        id
        name
        color
        labId
      }
    }
    userRoles {
      id
      appRoleCode
      userId
    }
    defaultProjectId
    userPreferences {
      id
      key
      value
    }
    userLogins {
      id
      loginName
      apiKeyExpiration
    }
    preferredDesignViewMode
    avatarFile {
      id
      path
    }
    avatarFileId
    firstName
    lastName
    username
    email
    phoneNumber
  }
`;
