/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { withProps } from "recompose";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { SelectMaterials, ReviewReactionMap } from "./Steps";
import {
  plasmidPurificationPlateMapGroupFragment,
  plasmidPurificationPlateFragment,
  plasmidPurificationTubeFragment
} from "./fragments";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";

class PlasmidPurificationPlanning extends React.Component {
  validate = values => {
    const {
      reactionMapName,
      sourcePlates = [],
      sourceTubes = [],
      plateMapGroups = []
    } = values;
    const errors = {};
    if (!reactionMapName) {
      errors.reactionMapName = "Please provide a name for the reaction map.";
    }
    if (!sourcePlates.length && !sourceTubes.length && !plateMapGroups.length) {
      errors.sourcePlates =
        "Must select at least one plate, tube or plate map.";
      errors.sourceTubes = "Must select at least one plate, tube or plate map.";
      errors.plateMapGroups =
        "Must select at least one plate, tube or plate map.";
    }
    return errors;
  };

  onSubmit = async values => {
    const { reactions, reactionMapName } = values;
    try {
      const strippedReactions = stripFields(reactions, [
        "inputMaterial",
        "outputMaterial"
      ]);
      const reactionMapToCreate = {
        name: reactionMapName,
        reactionTypeCode: "PLASMID_PURIFICATION",
        reactions: strippedReactions
      };
      const [createdReactionMap] = await safeUpsert(
        "reactionMap",
        reactionMapToCreate
      );
      window.toastr.success("Successfully created reaction map");
      return {
        reactionMap: createdReactionMap
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating reaction map."
      });
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Materials From Inventory",
        Component: SelectMaterials,
        withCustomFooter: true
      },
      {
        title: "Review Reaction Map",
        Component: ReviewReactionMap,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plasmidPurificationPlateFragment),
  withWorkflowInputs(plasmidPurificationTubeFragment),
  withWorkflowInputs(plasmidPurificationPlateMapGroupFragment),
  withProps(props => {
    const { aliquotContainers, containerArrays, plateMapGroups } = props;
    return {
      initialValues: {
        sourceTubes: aliquotContainers,
        sourcePlates: containerArrays,
        plateMapGroups
      }
    };
  })
)(PlasmidPurificationPlanning);
