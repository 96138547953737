/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Classes, MenuItem } from "@blueprintjs/core";
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { DialogFooter, ReactSelectField, wrapDialog } from "@teselagen/ui";
import { showDialog } from "../GlobalDialog";
import {
  getFeatureToColorMap,
  getFeatureTypes
} from "@teselagen/sequence-utils";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { safeUpsert } from "../apolloMethods";

// todo pull this directly from ove once we can update (after redux fixed)
const renderTypes = ({ readOnly, defaultValue } = {}) => (
  <ReactSelectField
    inlineLabel
    tooltipError
    disabled={readOnly}
    defaultValue={defaultValue || "misc_feature"}
    options={getFeatureTypes().map(type => {
      return {
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 10
            }}
          >
            <div
              style={{
                background: getFeatureToColorMap({ includeHidden: true })[type],
                height: 15,
                width: 15,
                marginRight: 5
              }}
            />
            {type}
          </div>
        ),
        value: type
      };
    })}
    isRequired
    name="type"
    label="Type:"
  />
);

function ChangeFeatureTypeDialog({
  records,
  submitting,
  hideModal,
  handleSubmit
}) {
  async function onSubmit({ type }) {
    const model = records[0].__typename;
    try {
      await safeUpsert(
        [model, "id type"],
        records.map(r => ({ id: r.id, type }))
      );
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(
        `Error updating ${modelNameToReadableName(model, { plural: true })}`
      );
    }
  }
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {renderTypes({ defaultValue: records.length === 1 && records[0].type })}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

const ChangeFeatureTypeDialogWrapped = compose(
  wrapDialog({
    title: "Change Type"
  }),
  reduxForm({
    form: "changeFeatureTypeDialog"
  })
)(ChangeFeatureTypeDialog);

function ChangeFeatureTypeMenuItem({ records }) {
  return (
    <MenuItem
      text="Change Type"
      icon="edit"
      onClick={() => {
        showDialog({
          ModalComponent: ChangeFeatureTypeDialogWrapped,
          modalProps: {
            records
          }
        });
      }}
    />
  );
}

export default ChangeFeatureTypeMenuItem;
