/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { kebabCase } from "lodash";
import pluralize from "pluralize";

const modelLinkMap = {
  customerRequest: "user-request",
  labProtocol: "protocol",
  lot: "reagent-lot",
  additiveMaterial: "reagent",
  plateMapGroup: "plate-map",
  j5Report: "assembly-report",
  guideRnaRun: "guide-rna-score-prediction-run",
  aliquotContainer: "tube",
  containerArray: "plate",
  importFileSet: "Assay Import Job",
  microserviceQueue: "microservice-task",
  partset: "part-set",
  dnaMaterial: "dna-material",
  rnaMaterial: "rna-material",
  microbialMaterial: "microbial-material",
  proteinMaterial: "protein-material",
  genomicMaterial: "genomic-material",
  cellCulture: "cell-culture",
  aminoAcidSequence: "amino-acid-sequence",
  vendorOrder: "order",
  sequencingQualityControlReport: "dna-sequencing-quality-control-report",
  crisprDesign: "crispr-design",
  ruleSet: "design-rulesets"
};

export default function modelNameToLink(
  modelNameOrRecord: string | { id: string; __typename: string },
  maybeId?: string
) {
  let modelName,
    id = maybeId;
  if (typeof modelNameOrRecord === "string") {
    modelName = modelNameOrRecord;
  } else {
    modelName = modelNameOrRecord.__typename;
    id = modelNameOrRecord.id;
  }
  let link =
    modelLinkMap[modelName as keyof typeof modelLinkMap] ||
    kebabCase(modelName);
  link = "/" + pluralize(link);
  return id ? link + `/${id}` : link;
}
