/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import InventoryParameters from "./InventoryParameters";
import Parameters from "./Parameters";
import { ValidationAlgorithms } from "./ValidationAlgorithms";
import { Button, Intent } from "@blueprintjs/core";
import withQuery from "../../../../src-shared/withQuery";

export const MINIMUM_TARGET_PART_SIZE = 100;

const ConfigurationStep = props => {
  const {
    stepFormProps: { change },
    partitionSizeMin,
    partitionSizeMax,
    overlapSizeMin,
    overlapSizeOptimal,
    overlapSizeMax,
    overlapGcContentMin,
    overlapGcContentOptimal,
    overlapGcContentMax,
    validationAlgorithms,
    usingInventoryParts,
    min_match_length,
    assemblyStrategy,
    restrictionEnzymes = [],
    Footer,
    footerProps,
    submitting
  } = props;

  const validationAlgorithmsParamsReducer = action =>
    change("validationAlgorithms", validationAlgorithms => {
      switch (action.type) {
        case "SET-VALIDATION-ALGORITHMS":
          const { selectedAlgorithms } = action;
          const newSelected = selectedAlgorithms.filter(
            alg => !validationAlgorithms.map(el => el.name).includes(alg)
          );
          const newValidations = newSelected.map(algName => {
            const alg = Object.values(ValidationAlgorithms).find(
              el => el.name === algName
            );
            alg["inputs"].map(inputObj => {
              inputObj.value = inputObj.default;
              return inputObj;
            });
            return alg;
          });
          const existingValidations = selectedAlgorithms.filter(el =>
            validationAlgorithms.map(va => va.name).includes(el)
          );
          validationAlgorithms = validationAlgorithms.filter(alg =>
            existingValidations.includes(alg.name)
          );
          validationAlgorithms.push(...newValidations);
          return validationAlgorithms;
        case "CHANGE-VALIDATION-ALGORITHM-PARAMETER":
          const {
            algorithmName,
            validationParameterName,
            validationParameterValue
          } = action;
          const currentValidationAlgorithm = validationAlgorithms.find(
            el => el.name === algorithmName
          );
          if (currentValidationAlgorithm) {
            currentValidationAlgorithm.inputs.find(
              algorithmInput => algorithmInput.name === validationParameterName
            ).value = validationParameterValue;
          }
          return validationAlgorithms;
        default:
          throw new Error("No such action type");
      }
    });

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Partitioning Tool Parameters"
          helper="Please inspect them and modify according to your needs."
        />
        <Parameters
          partitionSizeMin={partitionSizeMin}
          partitionSizeMax={partitionSizeMax}
          overlapSizeMin={overlapSizeMin}
          overlapSizeOptimal={overlapSizeOptimal}
          overlapSizeMax={overlapSizeMax}
          overlapGcContentMin={overlapGcContentMin}
          overlapGcContentOptimal={overlapGcContentOptimal}
          overlapGcContentMax={overlapGcContentMax}
          validationAlgorithms={validationAlgorithms}
          assemblyStrategy={assemblyStrategy}
          restrictionEnzymes={restrictionEnzymes}
          validationAlgorithmsParamsReducer={validationAlgorithmsParamsReducer}
        />
      </div>
      <div className="tg-step-form-section column">
        {window.frontEndConfig.partitionToolWithInventory && (
          <InventoryParameters
            min_match_length={min_match_length}
            usingInventoryParts={usingInventoryParts}
          />
        )}
      </div>
      <Footer
        {...footerProps}
        nextButton={
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            text="Submit"
            loading={submitting}
          />
        }
      />
    </React.Fragment>
  );
};

export default compose(
  withQuery(
    [
      "restrictionEnzyme",
      "id name sequence forwardSnipPosition reverseSnipPosition"
    ],
    {
      isPlural: true,
      showLoading: true,
      variables: {
        filter: {
          isRecognitionSiteDegenerate: false,
          enzymeTypeCode: "TYPE_IIS"
        }
      }
    }
  ),
  stepFormValues(
    "selectedPart",
    "selectedSequence",
    "partitionSizeMin",
    "partitionSizeMax",
    "assemblyStrategy",
    "overlapSizeMin",
    "overlapSizeOptimal",
    "overlapSizeMax",
    "overlapGcContentMin",
    "overlapGcContentOptimal",
    "overlapGcContentMax",
    "usingInventoryParts",
    "min_match_length",
    "validationAlgorithms",
    "selectedRestrictionEnzymes"
  )
)(ConfigurationStep);
