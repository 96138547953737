/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import classNames from "classnames";
import { Tooltip, Position, ContextMenu, Button } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { get, times, isEqual } from "lodash";
import DesignCardContextMenuContainer from "../../../../containers/DesignCardContextMenuContainer";
import store from "../../../../../src-shared/redux/store";
import ListElementCellContainer from "../../../../containers/ListElementCellContainer";
import Unligated from "../ValidatedJunction/Unligated";
import Ligated from "../ValidatedJunction/Ligated";
import { withEditorContext } from "../../DesignEditorContext";
import BinIcon from "./BinIcon";
import {
  SBOL_ICON_HEIGHT,
  SBOL_ICON_WIDTH
} from "../../../../../src-shared/components/HierarchicalDesign/constants";

/**
 * A bin in the tree view of the hierarchical design editor. The bin can either correspond
 * to a regular bin or a "fixed" bin, which contains a single element that validates
 * the end of all the elements in another bin. Ignore the word "Element" in its name.
 *
 * When passing props, be careful as `shouldComponentUpdate` performs a deep equality check.
 */
class DesignElementBlock extends React.Component {
  static propTypes = {
    /**
     * The id of the bin.
     */
    binId: PropTypes.string.isRequired,

    /**
     * The id of the card this bin is present on.
     */
    cardId: PropTypes.string.isRequired,

    /**
     * The total width of the bin in pixels.
     */
    width: PropTypes.number.isRequired,

    /**
     * Whether or not to render the cells below the bin.
     */
    areListElementsExpanded: PropTypes.bool,

    /**
     * The database record corresponding to `binId`.
     */
    bin: PropTypes.object.isRequired,

    /**
     * The database record of the icon of the bin.
     */
    icon: PropTypes.object.isRequired,

    /**
     * An array with information pertaining to the elements this
     * bin contains. This should be injected by the container via
     * the selector `getElementsInBin(state, binId)`.
     */
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * The id of the element.
         */
        id: PropTypes.string.isRequired,
        /**
         * The row index of the element.
         */
        index: PropTypes.number.isRequired
      })
    ).isRequired,

    /**
     * Is this bin injected by an operation?
     */
    isInjected: PropTypes.bool,

    /**
     * The color of the icon. Defaults to black.
     */
    iconColor: PropTypes.string,

    /**
     * How many rows of cells to render if `areListElementsExpanded` is set to `true`.
     */
    numberOfRows: PropTypes.number.isRequired,

    /**
     * Is the bin selected?
     */
    isSelected: PropTypes.bool,

    /**
     * Is the design locked?
     */
    isLocked: PropTypes.bool,

    /**
     * Is the design a visual report.
     *
     * @deprecated We don't have visual reports anymore.
     */
    isVisualReport: PropTypes.bool
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  handleDirectionButtonClick = e => {
    const { bin, isVisualReport, isLocked, flipBin, isInjected } = this.props;
    e.stopPropagation();
    if (isVisualReport || isLocked || isInjected) return;
    flipBin(bin.id);
  };

  renderButton = () => {
    const {
      bin: { direction }
    } = this.props;
    return (
      <Button
        minimal
        icon={direction ? "arrow-right" : "arrow-left"}
        style={{
          position: "absolute",
          top: -5,
          left: 0
        }}
        onClick={this.handleDirectionButtonClick}
      />
    );
  };

  renderDesignRuleSetsValidation = () => {
    const {
      bin: { invalidityMessage }
    } = this.props;
    if (!invalidityMessage) return null;
    const flagSize = 10;
    return (
      <svg
        key="designRuleValidation"
        height={flagSize}
        width={flagSize}
        style={{
          position: "absolute",
          top: SBOL_ICON_HEIGHT - 10,
          right: 5
        }}
      >
        <Tooltip
          disabled={!!window.Cypress}
          wrapperTagName="g"
          targetTagName="g"
          position={Position.LEFT_TOP}
        >
          <circle
            className="design-ruleset-validation-failed-icon"
            fill="red"
            cx={flagSize / 2}
            cy={flagSize / 2}
            r={flagSize / 2}
          />
          <div>
            {invalidityMessage.split("\n").map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>
        </Tooltip>
      </svg>
    );
  };

  renderIcon = () => {
    const { bin, icon, isInjected, iconColor } = this.props;

    return <BinIcon {...{ bin, icon, isInjected, iconColor }} />;
  };

  renderIcons = () => {
    return this.renderIcon();
  };

  handleBlockClick = e => {
    const {
      cardId,
      binId,
      bin,
      selectBin,
      setActivePanel,
      openInspector
    } = this.props;
    selectBin({
      cardId,
      binId,
      shift: e.shiftKey,
      ctrl: e.ctrlKey || e.metaKey
    });
    if (bin) {
      setActivePanel({ panel: "set" });
      openInspector();
    }
  };

  handleContextMenu = e => {
    const {
      cardId,
      binId,
      isSelected,
      selectBin,
      isLocked,
      isVisualReport
    } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (isLocked || isVisualReport) return;
    if (!isSelected) {
      selectBin({
        cardId,
        binId,
        shift: e.shiftKey,
        ctrl: e.ctrlKey || e.metaKey
      });
    }

    ContextMenu.show(this.getContextMenu(), {
      left: e.clientX,
      top: e.clientY
    });
  };

  getContextMenu = () => {
    const { history, editorContext } = this.props;
    return (
      <DesignCardContextMenuContainer
        clickedOn="bin"
        store={store}
        history={history}
        editorContext={editorContext}
      />
    );
  };

  renderValidatedJunctionIndicators() {
    const {
      cardId,
      binId,
      unligatedValidatedJunctionIds,
      ligatedValidatedJunctionIds
    } = this.props;
    return (
      <React.Fragment>
        {unligatedValidatedJunctionIds.map(junctionId => (
          <Unligated
            key={junctionId}
            cardId={cardId}
            binId={binId}
            junctionId={junctionId}
          />
        ))}
        {ligatedValidatedJunctionIds.map(junctionId => (
          <Ligated
            key={junctionId}
            cardId={cardId}
            binId={binId}
            junctionId={junctionId}
          />
        ))}
      </React.Fragment>
    );
  }

  render() {
    const {
      width,
      areListElementsExpanded,
      binId,
      bin: { name, isFixed, isPlaceholder, isLocked },
      elements,
      numberOfRows,
      cardId,
      isSelected,
      isNonTerminal,
      numItems
    } = this.props;
    return (
      <span
        style={{
          position: "relative"
        }}
        onKeyDown={this.handleKeyDown}
      >
        <span
          className={classNames("sbolIcon", { selected: isSelected })}
          style={{
            display: "inline-block",
            width,
            zIndex: 5,
            background: isNonTerminal ? "blue" : null // This is just for debugging purposes.
          }}
          onContextMenu={this.handleContextMenu}
          onClick={this.handleBlockClick}
        >
          <Tooltip disabled={!!window.Cypress} content={name}>
            <div>
              {this.renderIcons()}
              <div
                className="element-icon-label"
                style={{
                  width,
                  transform: `translate(-${(width - SBOL_ICON_WIDTH) /
                    2}px, 0px)`,
                  color: isFixed || isPlaceholder || isLocked ? "#777777" : null
                }}
              >
                {name}
              </div>
            </div>
          </Tooltip>
          {areListElementsExpanded && (
            <div className="list-elements-column">
              {times(numberOfRows, i => (
                <ListElementCellContainer
                  key={i}
                  cardId={cardId}
                  binId={binId}
                  elementId={get(
                    elements.find(el => el.index === i),
                    "id"
                  )}
                  isBinFixed={!!isFixed}
                  isBinPlaceholder={!!isPlaceholder}
                  isBinLocked={!!isLocked}
                  index={i}
                  inLastRow={i === numberOfRows - 1}
                />
              ))}
            </div>
          )}
        </span>
        {!isFixed && this.renderButton()}
        {!isFixed && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 5
            }}
          >
            x{numItems}
          </div>
        )}
        {this.renderDesignRuleSetsValidation()}
        {this.renderValidatedJunctionIndicators()}
      </span>
    );
  }
}

export default withEditorContext(DesignElementBlock);
