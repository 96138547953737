/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { withTableParams, DataTable } from "@teselagen/ui";
import withQuery from "../../../../../../src-shared/withQuery";
import { assemblyPieceViewFragment } from "../../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import stepFormValues from "../../../../../../src-shared/stepFormValues";
import { Button } from "@blueprintjs/core";
import { pull } from "lodash";

const schema = {
  model: "assemblyPieceView",
  fields: [
    {
      width: 40,
      resizeable: false,
      type: "action",
      path: "trash"
    },
    "name"
  ]
};

class SelectedAssemblypieceTable extends Component {
  clearPiece = r => {
    const { change, finalizedAPIds = [] } = this.props;
    change("finalizedAPIds", pull(finalizedAPIds, r.id));
  };

  render() {
    const { tableParams } = this.props;

    return (
      <DataTable
        {...tableParams}
        noSelect
        compact
        cellRenderer={{
          trash: (v, r) => {
            return (
              <Button
                minimal
                small
                intent="danger"
                icon="trash"
                onClick={() => this.clearPiece(r)}
              />
            );
          }
        }}
        className="tg-assembly-pieces-selected"
        maxHeight={400}
        style={{
          height: 400
        }}
        keepDirtyOnReinitialize
      ></DataTable>
    );
  }
}

export default compose(
  stepFormValues("finalizedAPIds"),
  withTableParams({
    formName: "selectedAssemblyPieceTable",
    urlConnected: false,
    schema,
    additionalFilter: ({ finalizedAPIds = [] }, qb) => {
      const filter = {
        id: finalizedAPIds
      };
      qb.whereAll(filter);
    }
  }),
  withQuery(assemblyPieceViewFragment, {
    isPlural: true
  })
)(SelectedAssemblypieceTable);
