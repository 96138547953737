/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { get, keyBy } from "lodash";
import { addAlignment, AlignmentView } from "@teselagen/ove";
import uuid from "uuid";
import { rerenderOnWindowResize, showContextMenu } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import store from "../../../src-shared/redux/store";
import defaultAsyncWrap from "../../../src-shared/utils/defaultAsyncWrap";
import {
  canCreateMassivelyParallelDesign,
  massivelyParallelCreateDesign
} from "../Dialogs/ParallelPartDialog/parallelPartUtils";

const rangesOverlap = (a1, a2, b1, b2) => !(b1 > a2 || a1 > b2);

const mapStateToProps = (state, { match }) => {
  const mppData = state.massivelyParallelPartCreation[match.params.id];
  const diversityRegions = Object.values(
    get(
      state,
      `VectorEditor.__allEditorsOptions.alignments[${match.params.id}].alignmentTracks[0].sequenceData.parts`,
      {}
    )
  ).filter(part => part.name === "Diversity Region");
  const injections = {
    diversityRegions,
    ...mppData
  };
  const veAlignmentRun = get(
    state,
    `VectorEditor.__allEditorsOptions.alignments[${match.params.id}]`
  );

  if (veAlignmentRun) {
    injections.alignmentRun = veAlignmentRun;
  }
  return injections;
};

const defaultAlignmentAnnotationVisibility = {
  features: false,
  parts: true,
  axis: false,
  dnaColors: false,
  translations: false,
  orfs: false,
  orfTranslations: false,
  cdsFeatureTranslations: false,
  cutsites: false,
  primers: false,
  lineageLines: false,
  chromatogram: false,
  sequence: true,
  reverseSequence: false
};
class ParallelPartSequenceViewer extends React.Component {
  state = { submitting: false };
  constructor(props) {
    super(props);
    rerenderOnWindowResize(this);
  }
  updateAlignment = alignmentRun => {
    addAlignment(store, {
      alignmentAnnotationVisibility: defaultAlignmentAnnotationVisibility,
      alignmentType: "Parallel Part Creation",
      ...alignmentRun
    });
  };
  componentDidMount() {
    const { alignmentRun } = this.props;
    this.updateAlignment(alignmentRun);
  }

  addDiversityRegionIfPossible = () => {
    const { diversityRegions, alignmentRun } = this.props;
    const { selectionLayer = {} } = alignmentRun;
    const { start = -1, end = -1 } = selectionLayer;

    if (end < start) return window.toastr.error("Invalid selection.");

    if (diversityRegions.some(p => rangesOverlap(start, end, p.start, p.end)))
      return window.toastr.error(
        "Cannot add diversity region as selection overlaps existing diversity region."
      );
    const [firstTrack, ...otherTracks] = alignmentRun.alignmentTracks;
    const newId = uuid();
    this.updateAlignment({
      ...alignmentRun,
      alignmentAnnotationVisibility: {
        ...alignmentRun.alignmentAnnotationVisibility,
        parts: true
      },
      alignmentTracks: [
        {
          ...firstTrack,
          sequenceData: {
            ...firstTrack.sequenceData,
            parts: {
              [newId]: {
                name: "Diversity Region",
                strand: 1,
                id: newId,
                forward: true,
                start: start,
                end: end
              },
              ...(firstTrack.sequenceData.parts || {})
            }
          }
        },
        ...otherTracks
      ]
    });
  };

  deleteDiversityRegion = annotation => {
    const { alignmentRun } = this.props;
    const [firstTrack, ...otherTracks] = alignmentRun.alignmentTracks;
    this.updateAlignment({
      ...alignmentRun,
      alignmentTracks: [
        {
          ...firstTrack,
          sequenceData: {
            ...firstTrack.sequenceData,
            parts: keyBy(
              Object.values(firstTrack.sequenceData.parts).filter(
                p => p.id !== annotation.id
              ),
              "id"
            )
          }
        },
        ...otherTracks
      ]
    });
  };

  handleSubmit = defaultAsyncWrap(async () => {
    const {
      designName,
      history,
      alignedSequences,
      match,
      nameTemplate,
      incrementStart,
      numDigits,
      libraryId,
      tagIds = []
    } = this.props;

    const state = store.getState();

    this.setState({ submitting: true });

    const errorMessage = canCreateMassivelyParallelDesign({
      state,
      alignedSequences,
      id: match.params.id
    });

    if (errorMessage) {
      this.setState({ submitting: false });
      return window.toastr.error(errorMessage);
    }

    const designId = await massivelyParallelCreateDesign({
      state,
      alignedSequences,
      designName,
      nameTemplate,
      id: match.params.id,
      numDigits,
      incrementStart,
      libraryId,
      tagIds
    });
    this.setState({ submitting: false });

    history.push(`/designs/${designId}`);
  }, "Error creating design");
  render() {
    const { alignmentRun } = this.props;
    const { submitting } = this.state;
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    return (
      <div
        className="parallel-part-container"
        style={{ width: "100%", padding: 15 }}
      >
        <div style={{ paddingBottom: 10 }}>
          <b>
            Create diversity regions spanning regions of sequence variability by
            selecting a region and right-clicking the selection. Every region of
            sequence variability must be included in a diversity region before
            continuing.
          </b>
        </div>
        <AlignmentView
          {...{
            id: alignmentRun.id,
            alignmentName: "DAPPER",
            alignmentTracks: alignmentRun.alignmentTracks,
            dimensions: {
              width: window.innerWidth - 80
            },
            height: viewportHeight * 0.81,
            additionalSelectionLayerRightClickedOptions: () => [
              {
                text: "Create Diversity Region",
                className: "createDiversityRegion",
                onClick: () => this.addDiversityRegionIfPossible()
              }
            ],
            additionalTopEl: (
              <Button
                style={{ marginLeft: "auto" }}
                intent={Intent.SUCCESS}
                text="Create Design"
                loading={submitting}
                onClick={this.handleSubmit}
              />
            ),

            linearViewOptions: () => {
              const toReturn = {
                partRightClicked: ({ annotation, event }) => {
                  showContextMenu(
                    [
                      {
                        text: "Delete Diversity Region",
                        className: "deleteDiversityRegion",
                        onClick: () => this.deleteDiversityRegion(annotation)
                      }
                    ],
                    undefined,
                    event
                  );
                }
              };
              // if (index === alignmentRun.alignmentTracks.length - 1) {
              //   toReturn.linearViewAnnotationVisibilityOverrides.axis = true
              //   toReturn.linearViewAnnotationVisibilityOverrides.axisNumbers = true
              // }

              return toReturn;
            }
          }}
        />
      </div>
    );
  }
}

export default compose(connect(mapStateToProps))(ParallelPartSequenceViewer);
