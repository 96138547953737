/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import shortid from "shortid";
import { getItemIdByCid } from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { flattenTree } from "../../../../../tg-iso-design/utils/designStateUtils";

export default state => {
  const userDefinedIconId = getItemIdByCid(state, "icon", "USER-DEFINED");
  return flattenTree({
    id: shortid(),
    __typename: "card",
    isRoot: true,
    name: "Template Head",
    circular: false,
    binCards: [
      {
        id: shortid(),
        __typename: "binCard",
        index: 0,
        bin: {
          id: shortid(),
          __typename: "bin",
          direction: true,
          name: "placeholder 1",
          iconId: userDefinedIconId,
          isPlaceholder: true
        }
      }
    ]
  });
};
