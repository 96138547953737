/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { takeEvery, call, select, put } from "redux-saga/effects";
import { get } from "lodash";
import actions from "../../actions";
import { getDesignState } from "../../../../../tg-iso-design/selectors/designStateSelectors";
import {
  getCopiedCells,
  getSelectedCellPaths
} from "../../../selectors/designViewSelectors";
import { flattenTree } from "../../../../../tg-iso-design/utils/designStateUtils";
import { isDesignLocked } from "../../../utils/designUtils/isDesignLocked";
import { designPartFragment } from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { safeQuery } from "../../../../src-shared/apolloMethods";

const getAdditionalItems = async partIds => {
  const parts = await safeQuery(designPartFragment, {
    isPlural: true,
    variables: { filter: { id: partIds } }
  });
  return flattenTree(parts);
};

function* handlePasteCells() {
  try {
    // We can't edit locked designs.
    if (yield select(isDesignLocked)) {
      return;
    }

    const copiedCells = yield select(getCopiedCells);
    const selectedCells = yield select(getSelectedCellPaths);
    if (!copiedCells.length || !selectedCells.length) return;

    const { part: loadedParts } = yield select(getDesignState);

    const partIdsToGetMap = {};
    for (const { element } of copiedCells) {
      const partId = get(element, "partId");
      if (partId && !loadedParts[partId]) {
        partIdsToGetMap[partId] = true;
      }
    }

    let additionalItems;
    const partIds = Object.keys(partIdsToGetMap);
    if (partIds.length) {
      additionalItems = yield call(getAdditionalItems, partIds);
    }

    yield put(actions.design.pasteCells({ additionalItems }));
  } catch (e) {
    yield call(console.error, e);
    yield call(window.toastr.error, "Error pasting cells.");
  }
}

export default function* watchPasteCells() {
  yield takeEvery(actions.ui.designEditor.general.pasteCells, handlePasteCells);
}
