/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../src-shared/withQuery";
import ApplyDesignTemplateDialog from "../components/Dialogs/ApplyDesignTemplateDialog";
import { designTemplateFragment } from "../graphql/fragments/designTemplateFragment";
import actions from "../../src-shared/redux/actions";
import { getSelectedCardId } from "../../src-shared/selectors/designViewSelectors";
import { getBinsInCard } from "../../../tg-iso-design/selectors/designStateSelectors";
import { dateModifiedColumn } from "../../src-shared/utils/libraryColumns";

const mapStateToProps = state => {
  const selectedCardId = getSelectedCardId(state);
  const bins = selectedCardId ? getBinsInCard(state, selectedCardId) : [];
  return {
    selectedCardId,
    bins,
    numBins: bins.length
  };
};

const mapDispatchToProps = {
  applyDesignTemplate: actions.design.applyDesignTemplate
};

const schema = {
  model: "design",
  fields: [
    { path: "dbId", type: "number", displayName: "Id", isHidden: true },
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "numPlaceholders",
      type: "number",
      displayName: "Number of Placeholders"
    },
    dateModifiedColumn
  ]
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTableParams({
    urlConnected: false,
    schema,
    formName: "ApplyDesignTemplateLibrary",
    additionalFilter: (props, qb) => {
      qb.whereAll({
        type: "design-template"
      });
    }
  }),
  withQuery(designTemplateFragment, {
    isPlural: true
  })
)(ApplyDesignTemplateDialog);
