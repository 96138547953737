/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { compose } from "redux";
import { DialogFooter, CheckboxField, wrapDialog } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { Classes, Button } from "@blueprintjs/core";
import exportPlateCsv from "./exportPlateCsv";
import { download } from "../../../../../src-shared/utils/downloadTest";
import { handleZipFiles } from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import {
  getPlateRecordEntities,
  getPlateTableSchema,
  hasAliquotExtendedPropsHelper
} from "../utils";
import { withProps } from "recompose";

function ExportAsPlateCsvDialog(props) {
  const { hideModal, submitting, handleSubmit, fields } = props;
  const [checkboxSelected, setCheckboxSelected] = useState(true);

  const onSubmit = async values => {
    try {
      const { containerArrays, hideModal, sortOrder } = props;

      const fieldsToExport = fields.filter(f => values[f.displayName]);

      const files = [];
      containerArrays.forEach(containerArray => {
        const entities = getPlateRecordEntities(containerArray, sortOrder);
        files.push({
          name: containerArray.name + ".csv",
          data: exportPlateCsv(containerArray, entities, fieldsToExport)
        });
      });
      if (files.length === 1) {
        download(files[0].data, `${files[0].name}.csv`, "text/csv");
      } else {
        download(await handleZipFiles(files), "plates.zip", "application/zip");
      }
      hideModal({ completed: true });
    } catch (e) {
      console.error(e);
      window.toastr.error("Error exporting plate.");
    }
  };
  return (
    <div className={Classes.DIALOG_BODY}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tg-flex justify-space-between">
          <h6>Choose Columns to Export</h6>
          <Button
            text={checkboxSelected ? "Deselect All" : "Select All"}
            onClick={() =>
              setCheckboxSelected(checkboxSelected => !checkboxSelected)
            }
          />
        </div>
        {fields.map(field => {
          return (
            <div key={field.displayName} className="tg-no-form-group-margin">
              <CheckboxField
                name={field.displayName}
                label={field.displayName}
                enableReinitialize
                defaultValue={checkboxSelected}
              />
            </div>
          );
        })}
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    </div>
  );
}

export default compose(
  wrapDialog({
    title: "Select Columns to Export"
  }),
  hasAliquotExtendedPropsHelper(),
  withProps(
    ({
      containerArrays,
      hasAliquotExtProps,
      aliquotExtendedPropertyFields
    }) => {
      return {
        fields: getPlateTableSchema({
          containerArrays,
          hasAliquotExtProps,
          aliquotExtendedPropertyFields
        })
      };
    }
  ),
  reduxForm({
    form: "ExportAsPlateCsvDialogForm",
    enableReinitialize: true,
    validate
  })
)(ExportAsPlateCsvDialog);

function validate(values, { fields }) {
  const errors = {};
  if (!fields.some(f => values[f.displayName])) {
    fields.forEach(f => {
      errors[f.displayName] = "At least one column must be selected.";
    });
  }
  return errors;
}
