/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";

import { Classes } from "@blueprintjs/core";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import { compose } from "recompose";

class InsertSequenceDialog extends React.Component {
  state = {
    sequences: [],
    submitting: false
  };

  handleSelect = sequences =>
    this.setState({
      sequences: Array.isArray(sequences) ? sequences : [sequences]
    });

  handleDoubleClick = async sequence => {
    this.setState({ submitting: true });
    await this.props.onSubmit([sequence]);
    this.props.hideModal();
  };

  render() {
    const {
      tableParams,
      hideModal,
      sequencesCount,
      loading,
      onSubmit,
      isSingleSelect,
      withCheckboxes = false
    } = this.props;
    const { sequences, submitting } = this.state;
    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <DataTable
            formName="InsertSequenceDialog"
            {...tableParams}
            isSingleSelect={isSingleSelect}
            entityCount={sequencesCount}
            isLoading={loading}
            withSearch
            withPaging
            canOutsideClickClose={false}
            isInfinite={false}
            onSingleRowSelect={this.handleSelect}
            onMultiRowSelect={this.handleSelect}
            onDoubleClick={this.handleDoubleClick}
            withCheckboxes={withCheckboxes}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          disabled={!sequences.length}
          submitting={submitting}
          onClick={async () => {
            this.setState({ submitting: true });
            await onSubmit(sequences);
            hideModal();
          }}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    style: { width: 950 },
    title: "Choose Sequence"
  }),
  reduxForm({
    form: "insertSequenceDialog",
    enableReinitialize: true
  })
)(InsertSequenceDialog);
