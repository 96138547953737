/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getOveHotkeyDefs } from "@teselagen/ove";
import designEditorHotkeys from "../src-design/components/HierarchicalDesign/hotkeys";

// These are shared hotkeys, not bound at the app level, but instead imported
// in other sections of the app
// export const sharedHotkeys = {
//   undo: "mod+z",
//   redo: "mod+shift+z"
// };

// These are hotkeys bound at the app level
export const globalHotkeys = {
  showKeyboardShortcuts: "mod+/"
};

// Hotkey sets to display on the hotkeys dialog
export const appHotkeySets = {
  General: { /* ...sharedHotkeys, */ ...globalHotkeys },
  "Design Editor": designEditorHotkeys,
  "Vector Editor": getOveHotkeyDefs({
    store: window.teGlobalStore,
    editorName: "SequenceEditor"
  })
  // ... add sets as needed
};
