/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import stripFields from "../../../../src-shared/utils/stripFields";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import StepForm from "../../../../src-shared/StepForm";
import { j5ReworkReportFragment } from "./fragments";
import ReviewSubsetJ5Report from "./Steps/ReviewSubsetJ5Report";
import SelectInputs from "./Steps/SelectInputs";
import shortid from "shortid";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import dataTableFragment from "../../../graphql/fragments/dataTableFragment";

class J5ReworkTool extends Component {
  onSubmit = async values => {
    try {
      const { j5ReportName, pcrReactions, j5RunConstructs, j5Reports } = values;
      const j5ReportToCreate = { name: j5ReportName };
      let j5ConstructsToCreate;
      const j5AssemblyPiecesToCreate = [];
      const [newJ5Report] = await safeUpsert("j5Report", j5ReportToCreate);
      if (j5RunConstructs.length > 0) {
        j5ConstructsToCreate = stripFields(j5RunConstructs, [
          "id",
          "__typename",
          "sequence"
        ]);
        const seqIdToAssemblyPieceId = {};
        const getAssemblyPieceId = assemblyPiece => {
          if (!seqIdToAssemblyPieceId[assemblyPiece.sequenceId]) {
            const newAp = { ...assemblyPiece, j5ReportId: newJ5Report.id };
            newAp.cid = shortid();
            newAp.j5AssemblyPieceParts = stripFields(
              newAp.j5AssemblyPieceParts,
              ["id", "__typename"]
            ).map(j5app => {
              if (j5app.j5InputPart?.j5InputSequence) {
                j5app.j5InputPart.j5InputSequence.j5ReportId = newJ5Report.id;
              }
              return j5app;
            });
            j5AssemblyPiecesToCreate.push(newAp);

            seqIdToAssemblyPieceId[newAp.sequenceId] = `&${newAp.cid}`;
          }
          return seqIdToAssemblyPieceId[assemblyPiece.sequenceId];
        };
        j5ConstructsToCreate.forEach(construct => {
          construct.j5ReportId = newJ5Report.id;
          construct.j5ConstructAssemblyPieces = construct.j5ConstructAssemblyPieces.map(
            j5Ap => {
              const newJ5Ap = { ...j5Ap };
              newJ5Ap.assemblyPieceId = getAssemblyPieceId(j5Ap.assemblyPiece);
              delete newJ5Ap.assemblyPiece;
              return newJ5Ap;
            }
          );
        });
      }

      if (pcrReactions.length > 0) {
        await safeUpsert(
          "j5PcrReaction",
          stripFields(pcrReactions, [
            "id",
            "__typename",
            "primaryTemplate",
            "forwardPrimer",
            "reversePrimer",
            "pcrProductSequence"
          ]).map(pcrReaction => {
            pcrReaction.j5ReportId = newJ5Report.id;
            return pcrReaction;
          })
        );
      }

      await safeUpsert("j5AssemblyPiece", j5AssemblyPiecesToCreate);
      await safeUpsert("j5RunConstruct", j5ConstructsToCreate);
      const j5ReportSubsetJoins = j5Reports.map(report => {
        return {
          parentReportId: report.id,
          subsetReportId: newJ5Report.id
        };
      });
      await safeUpsert("j5ReportSubsetJoin", j5ReportSubsetJoins);
      return {
        j5Report: newJ5Report
      };
    } catch (error) {
      console.error(error);
      window.toastr.error("Error creating assembly report");
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      initialValues,
      isToolIntegrated
    } = this.props;
    const steps = [
      {
        title: "Select Inputs",
        Component: SelectInputs,
        withCustomFooter: true
      },
      {
        title: "Review Assembly Report",
        Component: ReviewSubsetJ5Report,
        withCustomFooter: true
      }
    ];
    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        toolSchema={toolSchema}
        steps={steps}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(j5ReworkReportFragment),
  withWorkflowInputs(dataTableFragment)
)(J5ReworkTool);
