/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { Menu, MenuItem, MenuDivider } from "@blueprintjs/core";

import { isErdam } from "../../../src-shared/utils/addErdamUtils";
import {
  getReferencedValue,
  getItemOfType,
  doesDesignHavePartsets,
  getOutputCardIdOfReaction
} from "../../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../../src-shared/redux/actions";
import { showDialog } from "../../../src-shared/GlobalDialog";
import AddSplitsDialog from "../Dialogs/AddSplitsDialog";

const mapStateToProps = (state, props) => {
  const { reactionId } = props;
  const reaction = getItemOfType(state, "reaction", reactionId);
  if (!reaction) return {};
  const assemblyMethod =
    reaction &&
    getReferencedValue(state, "reaction", reactionId, "assemblyMethodId");
  const outputCardId = getOutputCardIdOfReaction(state, reactionId);
  return {
    state,
    reaction,
    isReactionErdam: isErdam(assemblyMethod),
    hasPartsets: doesDesignHavePartsets(state),
    outputCardId
  };
};

const mapDispatchToProps = {
  removeReaction: actions.design.removeReaction
};

const ReactionCardContextMenu = props => {
  const {
    reactionId,
    outputCardId,
    removeReaction,
    reaction,
    // hasPartsets,
    isReactionErdam
  } = props;

  if (!reaction) return null;

  const menuItems = [];
  menuItems.push(
    <MenuItem
      key="changeSplits"
      text="Change Input Grouping"
      onClick={() =>
        showDialog({
          ModalComponent: AddSplitsDialog,
          modalProps: {
            cardId: outputCardId
          }
        })
      }
    />,
    <MenuItem
      key="removeReaction"
      text="Remove Assembly Reaction"
      onClick={() => removeReaction(reactionId)}
    />
  );
  if (isReactionErdam)
    menuItems.push(
      <MenuDivider key="waruijhsdafhjdsjahfhjds" />,
      <MenuItem
        key="assignOverhangs"
        text="Assign Overhangs"
        onClick={() =>
          showDialog({
            modalType: "ASSIGN_REACTION_OVERHANGS",
            modalProps: {
              reactionId
            }
          })
        }
      />
    );

  return <Menu>{menuItems}</Menu>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactionCardContextMenu);
