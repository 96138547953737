/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// this should really be living in client/src-shared since this is the dep ?
import Bottle from "bottlejs";

let bottle;

if (!bottle) {
  bottle = new Bottle();
}

export { bottle };
