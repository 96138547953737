/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import DesignElementCard from "../components/HierarchicalDesign/DesignElementCard";
import actions from "../../src-shared/redux/actions";
import {
  isDesignUniform,
  getItemOfType,
  getDsfForCard,
  isDesignVisualReport,
  getViewOptionForCard,
  getBinIdsInCard,
  getRowsIndexIsShown,
  getElementGroupWithCardId,
  treeLayout,
  getRowNamesIsShown,
  getRowDisableIsShown,
  isLayoutList
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";
import {
  isCardSelected,
  getTopMarginForCard,
  getCardHeight,
  getSingleBlockWidth,
  getCardPadding,
  getMinCardWidth,
  getCardWidth,
  getConstructAnnotationsOfCard
} from "../../src-shared/selectors/designViewSelectors";
import { doesCardHaveValidatedJunctionsToRender } from "../../../tg-iso-design/selectors/junctionSelectors";
import stopOutOfSyncReduxRender from "../utils/stopOutOfSyncReduxRender";

const mapStateToProps = (state, props) => {
  const { cardId } = props;

  return {
    areListElementsExpanded:
      getViewOptionForCard(state, cardId, "listElements") === "expanded",
    binIds: getBinIdsInCard(state, cardId),
    constructAnnotations: getConstructAnnotationsOfCard(state, cardId),
    isUniformDesign: isDesignUniform(state),
    isVisualReport: isDesignVisualReport(state),
    isLocked: isDesignLocked(state),
    treeLayout: treeLayout(state),
    cardHeight: getCardHeight(state, cardId),
    minCardWidth: getMinCardWidth(state, cardId),
    cardWidth: getCardWidth(state, cardId),
    card: getItemOfType(state, "card", cardId),
    dsf: getDsfForCard(state, cardId),
    isSelected: isCardSelected(state, cardId),
    topMargin: getTopMarginForCard(state, cardId),
    singleBlockWidth: getSingleBlockWidth(state, cardId),
    hasValidatedJunctions: doesCardHaveValidatedJunctionsToRender(
      state,
      cardId
    ),
    paddingLeft: getCardPadding(state, cardId, true),
    paddingRight: getCardPadding(state, cardId, false),
    materialAvailabilityInfo:
      state.ui.designEditor.tree.cardMaterialAvailabilityInformation[cardId] ||
      {},
    showRowsIndex: getRowsIndexIsShown(state),
    showRowNames: getRowNamesIsShown(state),
    showRowDisable: isLayoutList(state) && getRowDisableIsShown(state),
    invalidityMessage: getElementGroupWithCardId(state, cardId).message,
    elementGroup: getElementGroupWithCardId(state, cardId)
  };
};

const mapDispatchToProps = {
  updateCardWidth: actions.ui.designEditor.tree.updateCardWidth,
  selectCard: actions.ui.designEditor.general.selectCard,
  updateSelection: actions.ui.designEditor.general.updateSelection,
  openInspector: actions.ui.designEditor.inspector.open,
  setInspectorActivePanel: actions.ui.designEditor.inspector.setActivePanel
};

const DesignCardContainer = compose(
  stopOutOfSyncReduxRender((state, props) =>
    getItemOfType(state, "card", props.cardId)
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DesignElementCard);

export default DesignCardContainer;
