/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { convertCellConcentrationBig } from "../../utils/unitUtils";
import { isDiluent } from "../../utils/aliquotUtils";
import { sumBigs } from "../../utils/bigIntUtils";
import { getVolumeInLiters } from "./getConcentrationOfMixture";
import getAliquotNumberOfCells from "../../utils/unitUtils/getAliquotNumberOfCells";

/**
 * See whether or not the aliquot has the required fields to be used to compute
 * the concentration of a mixture.
 * @param {object} aliquot
 * @returns {boolean}
 */
function canBeUsed(aliquot) {
  if (aliquot.isDry) return aliquot.cellCount;
  else if (isDiluent(aliquot)) return aliquot.volume !== null;
  return (
    (aliquot.cellConcentration !== null || aliquot.cellCount !== null) &&
    aliquot.volume !== null
  );
}

/**
 * Given an array of aliquots, return the cell concentration of their mixture. If the resulting mixture has no volume,
 * then we return `null. If any of the aliquots has no cell concentration, we return `null`.
 * @param {Array<Object>} aliquots Array of aliquots. Should have all of the fields directly on aliquot in the `aliquotFormulationFragment`.
 * @param {string} cellConcentrationUnitCode The units that the concentration will be returned in.
 * @returns {number}
 */
function getCellConcentrationOfMixture(_aliquots, cellConcentrationUnitCode) {
  if (!_aliquots.length) return null;

  const canAllBeUsed = _aliquots.every(canBeUsed);
  if (!canAllBeUsed) return null;

  const aliquots = _aliquots.map(aliquot => {
    if (aliquot.cellCount && !aliquot.cellConcentration) {
      return {
        ...aliquot,
        cellConcentration: aliquot.cellCount / aliquot.volume,
        cellConcentrationUnitCode: `cells/${aliquot.volumetricUnitCode}`
      };
    } else {
      return aliquot;
    }
  });

  const cellNums = aliquots.map(getAliquotNumberOfCells);
  const volumes = aliquots.map(getVolumeInLiters);

  const totalVolume = sumBigs(volumes);
  // This would divide by zero in the next step, so return null.
  if (!totalVolume || totalVolume.eq(0)) return null;

  // Cell Concentration = num cells / volume
  const cellConcInCellsPerLiter = sumBigs(cellNums).div(totalVolume);
  return Number(
    convertCellConcentrationBig(
      cellConcInCellsPerLiter,
      "cells/L",
      cellConcentrationUnitCode
    )
  );
}

export default getCellConcentrationOfMixture;
