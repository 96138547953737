/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { flatMapDeep, times, uniqBy } from "lodash";
import mustache from "mustache";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getSequenceDataBetweenRange } from "@teselagen/sequence-utils";
import { chunkSequenceToFragments } from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import defaultJ5OutputNamingTemplateMap from "../../../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import shortid from "shortid";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";

// Start and end are both inclusive.
const indexInRange = (start, end, ind) => start <= ind && ind <= end;

const getSelectedDiversityRegions = (state, id) =>
  Object.values(
    state.VectorEditor.__allEditorsOptions.alignments[id].alignmentTracks[0]
      .sequenceData.parts
  ).filter(part => part.name === "Diversity Region");

export const canCreateMassivelyParallelDesign = ({
  state,
  alignedSequences,
  id
}) => {
  const seqLen = alignedSequences[0].sequence.length;

  if (alignedSequences.some(s => s.sequence.length !== seqLen))
    throw new Error("Not all the aligned sequences are the same length.");

  const diverseInds = [];
  for (let i = 0; i < seqLen; i++) {
    const char = alignedSequences[0].sequence[i];
    if (alignedSequences.some(s => s.sequence[i] !== char)) {
      diverseInds.push(i);
    }
  }

  const diversityRegions = getSelectedDiversityRegions(state, id);
  if (!diversityRegions.length)
    return "Cannot create design as at least one diversity region must be created.";

  let diverseIndsCopy = diverseInds;
  for (const region of diversityRegions) {
    diverseIndsCopy = diverseIndsCopy.filter(
      ind => !indexInRange(region.start, region.end, ind)
    );
  }

  if (diverseIndsCopy.length)
    return (
      "Cannot create design as the following diverse indices are not inside a user-defined diversity region: " +
      diverseIndsCopy.join(", ")
    );

  return false;
};

export const massivelyParallelCreateDesign = async ({
  state,
  alignedSequences,
  designName,
  nameTemplate,
  id,
  numDigits,
  incrementStart,
  libraryId,
  tagIds = []
}) => {
  const seqLen = alignedSequences[0].sequence.length;

  const diversityRegions = [...getSelectedDiversityRegions(state, id)].sort(
    (a, b) => a.start - b.start
  );

  // Start and end are both 0-based and inclusive.
  const regions = [];
  if (diversityRegions[0].start !== 0) {
    regions.push({
      start: 0,
      end: diversityRegions[0].start - 1,
      isDiverse: false
    });
  }
  for (const currentRegion of diversityRegions) {
    const lastRegion = regions[regions.length - 1];
    if (lastRegion && lastRegion.end + 1 !== currentRegion.start) {
      regions.push({
        start: lastRegion.end + 1,
        end: currentRegion.start - 1,
        isDiverse: false
      });
    }

    regions.push({
      start: currentRegion.start,
      end: currentRegion.end,
      isDiverse: true
    });
  }
  if (regions[regions.length - 1].end !== seqLen - 1) {
    regions.push({
      start: regions[regions.length - 1].end + 1,
      end: seqLen - 1,
      isDiverse: false
    });
  }

  const [{ id: designId }] = await safeUpsert("design", {
    name: designName,
    description: "",
    type: "grand-design",
    layoutType: "list",
    numRows: alignedSequences.length
  });

  const binSeqs = regions.map(({ start, end }) => {
    const seqToRowNumberAndData = {};
    alignedSequences.forEach((s, i) => {
      const rawSeq = s.sequence.slice(start, end + 1).replace(/-/g, "");
      seqToRowNumberAndData[rawSeq] = seqToRowNumberAndData[rawSeq] || {};
      let rowNums = seqToRowNumberAndData[rawSeq].rowNums;
      if (!rowNums) rowNums = seqToRowNumberAndData[rawSeq].rowNums = [];
      rowNums.push(i);

      const seqData = seqToRowNumberAndData[rawSeq].seqData;
      const newData = getSequenceDataBetweenRange(s, {
        start,
        end
      });
      const partMapper = ({ name, start, end, forward }) => {
        return {
          name,
          start: start,
          end: end,
          strand: forward ? 1 : -1
        };
      };
      const featureMapper = ({ end, name, notes, start, strand, type }) => {
        return {
          end,
          name,
          notes,
          start,
          strand,
          type
        };
      };
      if (!seqData) {
        seqToRowNumberAndData[rawSeq].seqData = {
          parts: newData.parts.map(partMapper),
          features: newData.features.map(featureMapper)
        };
      } else {
        seqData.features = uniqBy(
          seqData.features.concat(newData.features.map(featureMapper)),
          x => `${x.start}_${x.end}_${x.name}`
        );
        seqData.parts = uniqBy(
          seqData.parts.concat(newData.parts.map(partMapper)),
          x => `${x.start}_${x.end}_${x.name}`
        );
      }
    });
    return seqToRowNumberAndData;
  });

  const cellToPartData = {};
  const hasIncrement = true;
  //below slices off {{{incrementing number from the name}}}

  let constructCount = incrementStart;
  const partValuesToUpsert = flatMapDeep(
    binSeqs,
    (seqToRowNumberAndData, columnIndex) => {
      return Object.entries(seqToRowNumberAndData)
        .sort((a, b) => a[1].rowNums[0] - b[1].rowNums[0])
        .map(([seqStr, { rowNums, seqData }]) => {
          const cid = shortid();
          let name = nameTemplate;

          if (hasIncrement) {
            let stringNumWithDigits = constructCount.toString();
            while (
              String(stringNumWithDigits).match(/\d/g).length < numDigits
            ) {
              stringNumWithDigits = 0 + stringNumWithDigits;
            }
            name = mustache.render(nameTemplate, {
              incrementing_number: stringNumWithDigits
            });
            constructCount += 1;
          }
          rowNums.forEach(rowIndex => {
            cellToPartData[`${rowIndex}:${columnIndex}`] = { cid, name };
          });

          return {
            cid,
            name,
            start: 0,
            end: seqStr.length - 1,
            strand: 1,
            taggedItems: tagIds.map(tagId => ({ tagId })),
            sequence: {
              name,
              description: "",
              libraryId,
              size: seqStr.length,
              sequenceTypeCode: "LINEAR_DNA",
              circular: false,
              sequenceFragments: chunkSequenceToFragments(seqStr),
              sequenceFeatures: uniqBy(
                seqData.features || [],
                feat =>
                  feat.name + feat.start + feat.end + feat.strand + feat.type
              ),
              parts: uniqBy(
                seqData.parts || [],
                part => part.name + part.start + part.end + part.strand
              )
            }
          };
        });
    }
  );

  await safeUpsert("part", partValuesToUpsert);

  const binCids = times(regions.length, () => shortid());
  const inputCardCids = times(regions.length, () => shortid());
  const reactionCid = shortid();

  const bins = await safeUpsert(
    "bin",
    times(regions.length, columnIndex => ({
      designId,
      cid: binCids[columnIndex],
      name: `Bin ${columnIndex + 1}`,
      direction: true,
      iconId: "&USER-DEFINED",
      elements: times(alignedSequences.length, rowIndex => {
        const { cid, name } = cellToPartData[`${rowIndex}:${columnIndex}`];
        return {
          designId,
          name,
          index: rowIndex,
          partId: "&" + cid
        };
      })
    }))
  );

  await safeUpsert("card", {
    designId,
    name: "Target Construct",
    isRoot: true,
    circular: false,
    binCards: bins.map((bin, index) => ({
      binId: bin.id,
      index,
      designId
    })),
    outputReaction: {
      cid: reactionCid,
      designId,
      name: "Mock Assembly",
      assemblyMethodId: "&mock",
      customJ5Parameter: {
        ...getDefaultParamsAsCustomJ5ParamName(),
        isLocalToThisDesignId: designId
      },
      reactionJ5OutputNamingTemplates: Object.keys(
        defaultJ5OutputNamingTemplateMap
      ).map(outputTarget => ({
        designId,
        j5OutputNamingTemplate: {
          designId,
          ...defaultJ5OutputNamingTemplateMap[outputTarget]
        }
      })),
      cards: bins.map((bin, inputIndex) => ({
        cid: inputCardCids[inputIndex],
        designId,
        name: `Bin ${inputIndex + 1}`,
        binCards: [
          {
            designId,
            index: 0,
            binId: bin.id
          }
        ],
        dsf: bin.direct_synthesis_firewall,
        inputIndex
      }))
    }
  });

  await safeUpsert(
    "junction",
    binCids.map((binCid, i) => ({
      designId,
      junctionTypeCode: "SCARLESS",
      isPhantom: false,
      reactionId: "&" + reactionCid,
      fivePrimeCardId: "&" + inputCardCids[i],
      fivePrimeCardEndBinId: "&" + binCid,
      fivePrimeCardInteriorBinId: "&" + binCid,
      threePrimeCardId: "&" + inputCardCids[i % inputCardCids.length],
      threePrimeCardStartBinId: "&" + binCids[i % binCids.length],
      threePrimeCardInteriorBinId: "&" + binCids[i % binCids.length]
    }))
  );

  return designId;
};
