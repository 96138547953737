/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const genomeFragment = gql`
  fragment genome on genome {
    id
    name
    updatedAt
  }
`;

// we need to fetch the fullSequence to determine the HAs and the target region, which are features of this sequence
export const genomeGenomicRegionFragment = gql`
  fragment genomeGenomicRegion on genomeGenomicRegion {
    id
    genomeId
    genomicRegion {
      id
      name
      fullSequence
      size
    }
    updatedAt
  }
`;

export const guideRnaFragment = gql`
  fragment guideRna on sequence {
    id
    name
    rnaSequenceGuideRnaScores {
      id
      cutSite
      guideRnaRun {
        id
        genomeId
        genomicRegionId
      }
    }
    updatedAt
  }
`;

export const casEnzymeFragment = gql`
  fragment casEnzyme on enzyme {
    id
    name
    updatedAt
    scaffoldSequence {
      id
      sequence
    }
    casEnzymePamSites {
      id
      pamSite {
        id
        sequence
        strand
      }
    }
  }
`;

export const sequenceFeatureFragment = gql`
  fragment sequenceFeature on sequenceFeature {
    id
    name
    start
    end
    strand
    updatedAt
  }
`;

export const sequenceFeatureWithSequenceFragment = gql`
  fragment sequenceFeatureWithSequence on sequenceFeature {
    id
    name
    start
    end
    strand
    updatedAt
    sequence {
      id
      name
      fullSequence
    }
  }
`;
