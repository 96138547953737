/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { uniq } from "lodash";
import { compose } from "redux";
import { reduxForm, change } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  InputField,
  InfoHelper,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { hideDialog as defaultHideDialog } from "../../../../src-shared/GlobalDialog";

const templateVariables = [
  ["Incrementing Number", "increment_from_0001"],
  ["Design Name", "design_name"],
  ["Design ID", "design_id"],
  ["Assembly Run ID", "assembly_run_id"],
  ["Reaction Prefix", "reaction_prefix"]
];

const EditNamingTemplateDialog = compose(
  wrapDialog({ title: "Edit Template" }),
  reduxForm({
    form: "EditNamingTemplateDialog",
    validate: ({ newTemplate }) => {
      //  "" or null or undefined
      if (!newTemplate) return { newTemplate: "A template is required" };
      let errorMsg;
      const innerVars = [];

      const templateMinusVars = newTemplate.replace(
        /{{{\s*[^{]*\s*}}}/gi,
        match => {
          if (errorMsg) return;
          if (match.replace(/\s*/gi, "").length !== match.length) {
            // example: {{{ design_id }}}
            errorMsg = "No whitespace allowed within template variables";
            return;
          }
          let innerVariable = match.substr(3, match.length - 6);
          if (
            innerVariable.replace(/increment_from_[0-9]+/gi, "") !==
            innerVariable
          ) {
            innerVariable = "increment_from_0001"; //we just need it to match the default for validation purposes (this is not actually setting it to 0001)
          }
          if (
            ![
              ...templateVariables,
              [null, "incrementing_number"] //tnr: we add this to array to allow for the old naming template
            ].find(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ([n, temp]) => temp === innerVariable
            )
          ) {
            // example: {{{zoink}}}
            // example: {{{zoink{{{design_id}}}}}}
            errorMsg = "Invalid template variable";
            return;
          }

          innerVars.push(innerVariable);

          return "";
        }
      );

      if (
        !errorMsg &&
        (templateMinusVars.length !==
          templateMinusVars.replace("{", "").length ||
          templateMinusVars.length !==
            templateMinusVars.replace("}", "").length)
      ) {
        // example: construct_{design_name}
        errorMsg = "Malformed template variable. Remove incorrect brackets";
      }

      if (!errorMsg && uniq(innerVars).length !== innerVars.length) {
        // example: {{{design_name}}}{{{design_name}}}
        errorMsg = "Cannot use the same template variable twice";
      }

      if (errorMsg) {
        return {
          newTemplate: errorMsg
        };
      }
    }
  }),
  tgFormValues("newTemplate")
)(
  ({
    startingVal,
    newTemplate,
    handleSubmit,
    onSubmit,
    submitting,
    hideDialog = defaultHideDialog
  }) => {
    // const [value, setVal] = useState(startingVal);
    const [cursorPos, setCursorPos] = useState(startingVal.length);

    const handleTemplateButtonClick = t => {
      const oldValue = newTemplate || "";
      const newValue =
        oldValue.substr(0, cursorPos) +
        `{{{${t}}}}` +
        oldValue.substr(cursorPos);

      window.teGlobalStore.dispatch(
        change("EditNamingTemplateDialog", "newTemplate", newValue)
      );

      const el = document.querySelector(`.tg-test-new-template input`);
      if (!el) return;
      // // Refocus and move cursor on input box
      const newPos = cursorPos + t.length + 6;

      el.focus();
      setTimeout(() => {
        el.selectionStart = newPos;
        el.selectionEnd = newPos;
        setCursorPos(newPos);
      }, 10);
    };

    const moveCursor = evt => {
      const target = evt.target;
      // Defer this to get the updated (post-event) position
      setTimeout(() => {
        setCursorPos(target.selectionStart);
      }, 0);
    };

    return (
      <div id="naming-template-dialog-id">
        <div className={Classes.DIALOG_BODY}>
          <InputField
            autoFocus
            name="newTemplate"
            onClick={moveCursor}
            onKeyDown={moveCursor}
            defaultValue={startingVal}
          ></InputField>

          <div style={{ marginTop: 10, marginBottom: 5 }}>
            Available Template Variables{" "}
            <InfoHelper
              noMarginTop
              isInline
              content="Clicking a template variable from the list below will add it to the template"
            ></InfoHelper>
            :
          </div>
          <div style={{ marginLeft: 10 }}>
            {templateVariables.map(([name, t]) => {
              return (
                <a
                  key={t}
                  style={{
                    width: "fit-content",
                    display: "block",
                    cursor: "pointer"
                  }}
                  onClick={() => handleTemplateButtonClick(t)}
                >
                  {name}
                </a>
              );
            })}
          </div>
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideDialog}
          onClick={handleSubmit(onSubmit)}
        ></DialogFooter>
      </div>
    );
  }
);

export default EditNamingTemplateDialog;
