/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function downloadURI(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.click();
}

async function downloader(data, type, name) {
  return new Promise((resolve, reject) => {
    try {
      const blob = new Blob([data], { type });
      const url = window.URL.createObjectURL(blob);
      downloadURI(url, name);
      window.URL.revokeObjectURL(url);
      resolve();
    } catch (error) {
      reject();
    }
  });
}

async function delayApiCall(url, ms) {
  const timeoutPromise = timeout(ms);
  const apiPromise = window.serverApi.get(url);
  await timeoutPromise;
  return apiPromise;
}

export { downloadURI, downloader, timeout, delayApiCall };
