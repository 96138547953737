/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { times } from "lodash";
import { unparse } from "papaparse";
import { getStringValueForField } from "../../../../../src-shared/ExportTableAsCsvButton";
import { rowIndexToLetter } from "../../../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import { plateRecordCellRenderer } from "../utils";

/**
 * Get a string corresponding to the csv export for the specific field.
 * @param {Object} containerArray
 * @param {Object} field
 * @returns {string}
 */
function getCsvFromField(containerArray, entities, cellRenderer, field) {
  const {
    containerArrayType: {
      containerFormat: { rowCount, columnCount }
    }
  } = containerArray;

  // initialize the csv with the row numbering
  const data = times(rowCount, j => [rowIndexToLetter(j)]);

  // Add the column numbering.
  data.unshift([""].concat(times(columnCount, i => i + 1)));

  entities.forEach((ac, i) => {
    data[ac.rowPosition + 1][ac.columnPosition + 1] = getStringValueForField({
      field,
      record: ac,
      index: i,
      cellRenderer
    });
  });

  // Add the label for the field.
  data.unshift([field.displayName]);

  return unparse(data);
}

/**
 * Given a plate and field to export, produce a csv that mocks the layout of the plate.
 * For example:
 *    Volume
 *    ,1,2,3
 *    A,50mL,40mL
 *    B,,25mL
 *    C,45mL,
 * If there are multiple fields supplied, the output csv will be concatenation of the csvs
 * for each field
 * @param {Object} containerArray The plate we wish to export.
 * @param {Array<Object>} field The field object (such as from a schema) that we wish to export
 */
export default function exportPlateCsv(containerArray, entities, fields) {
  const csv = fields
    .map(f =>
      getCsvFromField(
        containerArray,
        entities,
        plateRecordCellRenderer(containerArray),
        f
      )
    )
    .join("\n\n");
  return csv;
}
