/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { showProgressToast } from "@teselagen/ui";
import { map } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import SelectSequencingData from "./Steps/SelectSequencingData";
import ConfigAndSubmission from "./Steps/ConfigAndSubmission";
import api from "../../../../src-shared/api";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

const DEFAULT_COVERAGE_SETTINGS = {
  name: "default",
  coverageSettings: {
    min_bp_cov: 10,
    cov_th: 0.995,
    BAQ: 20,
    MAQ: 20
  }
};
const DEFAULT_VARIANT_CALLING_SETTINGS = {
  name: "default",
  variantCallingSettings: {
    bq_vc: 20,
    mq_vc: 20,
    qd_vc: 20
  }
};
class SequencingQualityControlReportTool extends React.Component {
  state = { sent: false };

  /**
   * This function should upload the FASTQ files into S3. Currently the backend expects for these to be stored unzipped.
   * In order to support storing this ZIPPED **@teselagen/evolve-lib** backend packages needs modification.
   * @param {Object[]} files
   */
  uploadFilesToS3 = async files => {
    const formData = new FormData();
    files.forEach(f => formData.append("file[]", f));
    return await api.post("/user_uploads", formData);
  };

  onSubmit = async formValues => {
    const {
      min_bp_cov,
      cov_th,
      BAQ,
      MAQ,
      mq_vc,
      qd_vc,
      bq_vc,
      aliquots,
      fastqFilesUploadData,
      fastqFilenames,
      secQCGroupName,
      customNameFilesWithAliquotId,
      customNameFilesWithPlateWell
    } = formValues;

    const clearFastQProgressToast = showProgressToast(
      `Uploading FASTQ Files...`
    );

    const filesToUpload = fastqFilesUploadData.map(file => file.originFileObj);

    const results = await this.uploadFilesToS3(filesToUpload);
    const allFastqFileUrls = results.data.map(data =>
      data.path.slice(data.path.lastIndexOf("/") + 1)
    );

    const aqcReportParams = {
      min_bp_cov,
      cov_th,
      BAQ,
      MAQ,
      ploidy: 1,
      vcfilter: "default",
      vc_params: {
        MQ: mq_vc,
        QD: qd_vc,
        BQ: bq_vc
      },
      critical_annotations: ["CDS", "gene"],
      jobName: `QC of ${fastqFilenames.join(", ")}`
    };

    let seqQCReportIds = [];
    const seqQCReportsToUpsert = [];

    const prelimAlignmentData = aliquots.map(aliquot => {
      const {
        id,
        barcode,
        position,
        referenceSequenceName,
        sampleId,
        pooledAliquotId
      } = aliquot;

      seqQCReportsToUpsert.push({
        name: secQCGroupName
          ? `Barcode_${barcode}_Well_${position[0]}_Aliquot_${id}_${referenceSequenceName}_${secQCGroupName}`
          : `Barcode_${barcode}_Well_${position[0]}_Aliquot_${id}_${referenceSequenceName}`,
        sequencingQualityControlReportStatusCode: "Not Inspected",
        sampleId
      });

      let fastqFileUrls = [];

      if (pooledAliquotId) {
        fastqFileUrls = allFastqFileUrls.filter(url =>
          url.includes(`Aliquot_${pooledAliquotId}_`)
        );
      } else {
        fastqFileUrls = allFastqFileUrls.filter(
          url =>
            url.includes(`Aliquot_${id}_`) ||
            url.includes(`Barcode_${barcode}_Well_${position[0]}`)
        );
      }

      if (!fastqFileUrls.length) {
        const customFileNames = [];

        customNameFilesWithAliquotId.forEach(f => {
          if (f.ALIQUOT_ID === id) {
            customFileNames.push(
              f.FILE_NAME.slice(0, f.FILE_NAME.indexOf(".fastq"))
            );
          }
        });

        customNameFilesWithPlateWell.forEach(f => {
          if (f.BARCODE === barcode && f.WELL_LOCATION === position[0]) {
            customFileNames.push(
              f.FILE_NAME.slice(0, f.FILE_NAME.indexOf(".fastq"))
            );
          }
        });

        if (customFileNames) {
          customFileNames.forEach(n => {
            const relevantFastqFileUrl = allFastqFileUrls.filter(url =>
              url.includes(n)
            )[0];
            fastqFileUrls.push(relevantFastqFileUrl);
          });
        }
      }

      return {
        aliquot: {
          id,
          barcode,
          position
        },
        fastqFileUrls
      };
    });

    const upsertedSeqQCReports = await safeUpsert(
      ["sequencingQualityControlReport", `id name`],
      seqQCReportsToUpsert
    );
    seqQCReportIds = upsertedSeqQCReports.map(r => {
      return { id: r.id };
    });

    const alignmentData = map(prelimAlignmentData, alignment => {
      const aliquotId = alignment.aliquot.id;
      const seqQCReport = upsertedSeqQCReports.filter(file => {
        const fileName = file.name;
        const aliquotIdPosition =
          fileName.toLowerCase().split("_").indexOf("aliquot") + 1;
        let fileNameAliquotId = fileName.split("_")[aliquotIdPosition];
        if (fileNameAliquotId.endsWith(".fastq")) {
          fileNameAliquotId = fileNameAliquotId.slice(
            0,
            fileNameAliquotId.indexOf(".fastq")
          );
        }
        return aliquotId === fileNameAliquotId;
      });
      alignment.seqQCReportId = seqQCReport[0].id;
      return alignment;
    });

    const assemblyQCReportParams = { aqcReportParams, alignmentData };

    clearFastQProgressToast();

    // return;
    return api
      .request({
        method: "post",
        url: "/evolve-routes/assemblyqc-tool",
        withCredentials: true,
        data: { assemblyQCReportParams }
      })
      .then(async output => {
        if (output.data.error) throw new Error(output.data.error);

        const clearSeqQCReportProgressToast = showProgressToast(
          `Updating Sequencing QC Reports with Task Status...`
        );

        const seqQCReportsToUpdate = output.data.data.map(task => {
          const microserviceQueueId = task.id;
          const seqQCReportId = task.input.seqQCReportId;
          return {
            id: seqQCReportId,
            microserviceQueueId
          };
        });
        await safeUpsert(
          "sequencingQualityControlReport",
          seqQCReportsToUpdate
        );

        clearSeqQCReportProgressToast();

        return {
          sequencingQualityControlReport: seqQCReportIds
        };
      })
      .catch(error => {
        window.toastr.error(`Error generating sequencing QC report`);
        console.error("Error generating sequencing QC report: ", error);
        return {};
      });
  };

  render() {
    const { toolSchema, initialValues, toolIntegrationProps } = this.props;
    const steps = [
      {
        title: "Select Sequencing Data",
        Component: SelectSequencingData,
        withCustomFooter: true
      },
      {
        title: "Parameter Setup",
        Component: ConfigAndSubmission,
        props: {
          currentUser: this.props.currentUser,
          selectedCoverageSettings: DEFAULT_COVERAGE_SETTINGS,
          coverageSettingsName: DEFAULT_COVERAGE_SETTINGS.name,
          selectedVariantCallingSettings: DEFAULT_VARIANT_CALLING_SETTINGS,
          variantCallingSettingsName: DEFAULT_VARIANT_CALLING_SETTINGS.name
        },
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolSchema={toolSchema}
        toolIntegrationProps={toolIntegrationProps}
        initialValues={initialValues}
        steps={steps}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(withRouter)(SequencingQualityControlReportTool);
