/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  DialogFooter,
  InputField,
  ReactSelectField,
  CheckboxField,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import QueryBuilder from "tg-client-query-builder";

import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import UnitFields from "../../UnitFields";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import {
  standardizeVolume,
  convertVolume,
  convertMass,
  standardizeMass,
  withUnitGeneric
} from "../../../../src-shared/utils/unitUtils";
import {
  arrayToItemValuedOptions,
  arrayToIdOrCodeValuedOptions
} from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";

class CreateTubeFromReagentDialog extends Component {
  onSubmit = async values => {
    const {
      additiveMaterial: maybeAdditiveMaterial,
      initialValues: { lot: maybeInitialLot } = {},
      history,
      hideModal
    } = this.props;
    const {
      name,
      aliquotContainerTypeCode,
      generateBarcode,
      barcode,
      lot,
      ...additiveFields
    } = values;
    const additiveMaterial =
      maybeAdditiveMaterial || maybeInitialLot.additiveMaterial;
    try {
      const tubeToCreate = {
        name,
        aliquotContainerTypeCode,
        additives: [
          {
            additiveMaterialId: additiveMaterial.id,
            ...additiveFields
          }
        ]
      };
      if (!generateBarcode && barcode) {
        tubeToCreate.barcode = {
          barcodeString: barcode
        };
      }
      const [createdTube] = await safeUpsert("aliquotContainer", tubeToCreate);
      if (generateBarcode) {
        await addBarcodesToRecords(createdTube);
      }
      if (lot && (additiveFields.volume || additiveFields.mass)) {
        const lotUpdate = {
          id: lot.id
        };
        if (additiveFields.volume) {
          // compute new lot volume
          lotUpdate.volume = standardizeVolume(
            lot.volume,
            lot.volumetricUnitCode,
            true
          ).minus(
            standardizeVolume(
              additiveFields.volume,
              additiveFields.volumetricUnitCode,
              true
            )
          );
          // convert volume back to the same units that the lot originally had
          lotUpdate.volume = convertVolume(
            lotUpdate.volume,
            "L",
            lot.volumetricUnitCode,
            true
          ).toString();
        } else if (additiveFields.mass) {
          // compute new lot mass
          lotUpdate.mass = standardizeMass(
            lot.mass,
            lot.massUnitCode,
            true
          ).minus(
            standardizeMass(
              additiveFields.mass,
              additiveFields.massUnitCode,
              true
            )
          );
          // convert mass back to the same units that the lot originally had
          lotUpdate.mass = convertMass(
            lotUpdate.volume,
            "g",
            lot.massUnitCode,
            true
          ).toString();
        }
        if (lotUpdate.mass || lotUpdate.volume) {
          await safeUpsert("lot", lotUpdate);
        }
      }
      hideModal();
      history.push(modelNameToLink(createdTube.__typename, createdTube.id));
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating tube.");
    }
  };

  render() {
    const {
      hideModal,
      submitting,
      handleSubmit,
      additiveMaterial: { isDry, lots = [] } = {},
      aliquotContainerTypes = [],
      generateBarcode
    } = this.props;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Tube Name"
            secondaryLabel="(optional)"
          />
          <CheckboxField
            name="generateBarcode"
            label="Generate Barcode"
            defaultValue
          />
          {!generateBarcode && <InputField name="barcode" label="Barcode" />}
          <ReactSelectField
            name="aliquotContainerTypeCode"
            isRequired
            label="Tube Type"
            options={arrayToIdOrCodeValuedOptions(aliquotContainerTypes)}
          />
          {!!lots.length && (
            <ReactSelectField
              name="lot"
              label="Reagent Lot"
              secondaryLabel="(optional)"
              options={arrayToItemValuedOptions(lots)}
            />
          )}
          <UnitFields
            concentrationTypes={["concentration"]}
            isDry={isDry}
            {...this.props}
            {...(isDry
              ? {
                  massRequired: true
                }
              : {
                  volumeRequired: true
                })}
          />
        </div>
        <DialogFooter
          {...{
            hideModal,
            submitting,
            onClick: handleSubmit(this.onSubmit)
          }}
        />
      </div>
    );
  }
}

const fragment = `
  id
  name
  isDry
  lots {
    id
    name
    mass
    massUnitCode
    concentration
    concentrationUnitCode
    volume
    volumetricUnitCode
  }
`;

function validate(
  values,
  {
    additiveMaterial: maybeAdditiveMaterial,
    initialValues: { lot: maybeInitialLot } = {},
    aliquotContainerTypes
  }
) {
  const errors = {};
  const {
    volume,
    volumetricUnitCode,
    mass,
    massUnitCode,
    lot,
    aliquotContainerTypeCode
  } = values;
  const additiveMaterial =
    maybeAdditiveMaterial || maybeInitialLot.additiveMaterial;
  if (additiveMaterial) {
    const standardizedVolume =
      volume && standardizeVolume(volume, volumetricUnitCode);
    const standardizedMass = mass && standardizeMass(mass, massUnitCode);
    if (standardizedVolume && aliquotContainerTypeCode) {
      const aliquotContainerType = aliquotContainerTypes.find(
        act => act.code === aliquotContainerTypeCode
      );
      const {
        maxVolume,
        volumetricUnitCode: acTypeVolUnitCode
      } = aliquotContainerType;
      const standardizedMaxVolume = standardizeVolume(
        maxVolume,
        acTypeVolUnitCode
      );
      if (standardizedVolume > standardizedMaxVolume) {
        errors.volume = `Volume exceeds tube type max volume (${withUnitGeneric(
          "maxVolume",
          "volumetricUnitCode"
        )(aliquotContainerType)})`;
      }
    }
    if (lot) {
      const stardardizedLotVolume = standardizeVolume(
        lot.volume || 0,
        lot.volumetricUnitCode || "uL"
      );
      const stardardizedLotMass = standardizeMass(
        lot.mass || 0,
        lot.massUnitCode || "g"
      );
      if (standardizedVolume && standardizedVolume > stardardizedLotVolume) {
        errors.volume = "Lot does not have enough volume.";
      }
      if (standardizedMass && standardizedMass > stardardizedLotMass) {
        errors.mass = "Lot does not have enough mass.";
      }
    }
  }
  return errors;
}

export default compose(
  wrapDialog(),
  withQuery(["additiveMaterial", fragment], {
    showLoading: true,
    inDialog: true,
    skip: props => !props.reagentId,
    options: props => {
      return {
        variables: {
          id: props.reagentId
        }
      };
    }
  }),
  withQuery(
    [
      "lot",
      `id mass massUnitCode volume volumetricUnitCode concentration concentrationUnitCode additiveMaterial {
        id
        isDry
      }`
    ],
    {
      showLoading: true,
      inDialog: true,
      skip: props => !props.lotId,
      options: props => {
        return {
          variables: {
            id: props.lotId
          }
        };
      },
      props: ({ lot }) => {
        if (lot) {
          return {
            initialValues: { lot }
          };
        }
      }
    }
  ),
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: () => {
      const qb = new QueryBuilder("aliquotContainerType");
      const filter = qb
        .whereAll({
          isTube: true
        })
        .toJSON();
      return {
        variables: {
          filter
        }
      };
    }
  }),
  reduxForm({
    form: "createTubeFromReagent",
    validate
  }),
  tgFormValues("generateBarcode"),
  withRouter
)(CreateTubeFromReagentDialog);
