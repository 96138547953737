/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment additiveMaterialInventoryFragment on additiveMaterial {
    id
    name
    additiveTypeCode
    isDry
    additiveType {
      code
      name
    }
  }
`;
