/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { drawGroupedHistogram } from "./d3/groupedHistogram";
import modelGroupedHistogramWidgetStyles from "./ModelGroupedHistogramWidgetStyles.module.css";
import ReactResizeDetector from "react-resize-detector";

const TAG = "[ModelGroupedHistogramWidget]"; // eslint-disable-line

class ModelGroupedHistogramWidget extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    const defaultFeatureToPlot = Object.keys(
      data["suggested_batch_hist_info"]
    )[0];

    const formattedData = this.formatData(data["suggested_batch_hist_info"]);

    const chartContainerClass =
      "_" +
      Math.random()
        .toString(36)
        .slice(2, 6);

    this.state = {
      chartContainerClass: chartContainerClass,
      formattedData: formattedData,
      defaultFeatureToPlot: defaultFeatureToPlot
    };
  }

  componentDidMount() {
    const {
      chartContainerClass,
      formattedData,
      defaultFeatureToPlot
    } = this.state;
    const { featureToPlot } = this.props;

    let dataToPlot = formattedData[defaultFeatureToPlot];

    if (featureToPlot) {
      dataToPlot = formattedData[featureToPlot];
    }

    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;

    drawGroupedHistogram(chartContainerClass, height, width, dataToPlot);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  formatData = unformattedData => {
    const formattedData = {};

    for (const featureKey in unformattedData) {
      const feature = unformattedData[featureKey];
      const xLabels = feature["_x_labels"].slice(0);

      const batchX = feature["batch"].x.slice(0);
      const batchY = feature["batch"].y.slice(0);

      const expX = feature["exp"].x.slice(0);
      const expY = feature["exp"].y.slice(0);

      const dataPoints = xLabels.reduce((accumulator, value) => {
        const indexOfBatchVal = batchX.indexOf(value);
        let batchValue = 0;
        if (indexOfBatchVal > -1) {
          batchX.splice(indexOfBatchVal, 1);
          batchValue = batchY.splice(indexOfBatchVal, 1)[0];
        }

        const indexOfExpVal = expX.indexOf(value);
        let expValue = 0;

        if (indexOfExpVal > -1) {
          expX.splice(indexOfExpVal, 1);
          expValue = expY.splice(indexOfExpVal, 1)[0];
        }

        const dataPoint = { feature: value, batch: batchValue, exp: expValue };

        accumulator.push(dataPoint);

        return accumulator;
      }, []);

      const formattedFeature = {
        featureName: featureKey,
        xLabels: xLabels,
        dataPoints: dataPoints
      };

      formattedData[featureKey] = formattedFeature;
    }

    return formattedData;
  };

  updateDimensions = () => {
    this.setState({
      width: this.divElement.clientWidth,
      height: this.divElement.clientHeight
    });
  };

  render() {
    const {
      chartContainerClass,
      formattedData,
      defaultFeatureToPlot
    } = this.state;
    const { featureToPlot } = this.props;

    let dataToPlot = formattedData[defaultFeatureToPlot];

    if (featureToPlot) {
      dataToPlot = formattedData[featureToPlot];
    }

    if (this.divElement) {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;

      drawGroupedHistogram(chartContainerClass, height, width, dataToPlot);
    }

    return (
      <div
        className={modelGroupedHistogramWidgetStyles.container}
        ref={divElement => (this.divElement = divElement)}
      >
        <div
          className={`${modelGroupedHistogramWidgetStyles.viz} ${chartContainerClass}`}
        />
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.updateDimensions}
          targetDomEl={this.divElement}
        />
      </div>
    );
  }
}

export default ModelGroupedHistogramWidget;
