/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { difference, get, noop } from "lodash";
import { copyRecordsTaggedItems } from "../../../../../tg-iso-shared/src/tag-utils";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import { createNotification } from "../../../../src-shared/utils/createNotification";

import stripFields from "../../../../src-shared/utils/stripFields";

export async function onSubmitEdit(
  _values,
  { initialValues, refetch, hideModal }
) {
  try {
    const {
      id,
      name,
      jobUsers,
      customerRequests,
      initialCustomerRequestJobs,
      initialJobUsers
    } = initialValues;
    let values = {
      ..._values
    };

    const initialCustomerRequestIds = customerRequests.map(c => c.id);
    const newCustomerRequestIds = values.customerRequests.map(c => c.id);

    const initialJobTeamMembersids = jobUsers.map(function (jobUser) {
      return jobUser.id;
    });
    const newJobTeamMemberIds = values.jobUsers.map(ju => ju.id);
    const addedJobUserIds = difference(
      newJobTeamMemberIds,
      initialJobTeamMembersids
    );
    const removedJobUserIds = difference(
      initialJobTeamMembersids,
      newJobTeamMemberIds
    );

    const addedCustomerRequestIds = difference(
      newCustomerRequestIds,
      initialCustomerRequestIds
    );
    const removedCustomerRequestIds = difference(
      initialCustomerRequestIds,
      newCustomerRequestIds
    );

    const promises = [];
    values.priorityTypeCode = get(values, "priorityType.code");

    values = stripFields(values, [
      "__typename",
      "priorityType",
      "customerRequests",
      "jobUsers",
      "initialCustomerRequestJobs",
      "initialJobUsers"
    ]);

    values.jobOwnerId = get(values, "jobOwner.id");
    delete values.jobOwner;

    const createJobUserInput = addedJobUserIds.map(userId => ({
      jobId: id,
      userId
    }));
    const newNotifications = [];
    addedJobUserIds.forEach(jobUserId => {
      newNotifications.push({
        userId: jobUserId,
        message: `You have been added to job '${name}'`,
        notificationIntent: "primary",
        link: `/jobs/${id}`,
        notificationTypeCode: "ADDED_TO_JOB"
      });
    });
    await createNotification(newNotifications);

    if (removedJobUserIds.length) {
      const jobUserIdsToDelete = initialJobUsers.reduce(
        (acc, { id, userId }) => {
          if (removedJobUserIds.indexOf(userId) > -1) acc.push(id);
          return acc;
        },
        []
      );
      promises.push(safeDelete("jobUser", jobUserIdsToDelete));
    }

    promises.push(safeUpsert("jobUser", createJobUserInput));
    const createCustomerRequestJobInput = addedCustomerRequestIds.map(
      customerRequestId => {
        return {
          jobId: id,
          customerRequestId
        };
      }
    );

    if (removedCustomerRequestIds.length) {
      const customerRequestJobsIdsToDelete = initialCustomerRequestJobs.reduce(
        (acc, { id, customerRequestId }) => {
          if (removedCustomerRequestIds.indexOf(customerRequestId) > -1)
            acc.push(id);
          return acc;
        },
        []
      );
      promises.push(
        safeDelete("customerRequestJob", customerRequestJobsIdsToDelete)
      );
    }

    promises.push(
      safeUpsert("customerRequestJob", createCustomerRequestJobInput)
    );

    promises.push(safeUpsert("job", values));

    await Promise.all(promises);
    await refetch();
    hideModal();
  } catch (error) {
    window.toastr.error("Error editing job.");
    console.error("error:", error);
  }
}

export async function onSubmitCreate(
  _values,
  { hideModal = noop, history, refetchCustomerRequest, returnJob }
) {
  try {
    const values = { ..._values };
    const { jobUsers = [] } = values;
    values.jobOwnerId = values.jobOwner && values.jobOwner.id;
    delete values.jobOwner;
    values.priorityTypeCode = get(values, "priorityType.code");
    delete values.priorityType;
    if (values.customerRequests) {
      values.customerRequestJobs = values.customerRequests.map(cr => ({
        customerRequestId: cr.id
      }));
      values.taggedItems = await copyRecordsTaggedItems(
        values.customerRequests
      );
      delete values.customerRequests;
    }
    let hasJobOwnerAsJobUser = false;
    values.jobUsers = jobUsers.map(jobUser => {
      if (jobUser.id === values.jobOwnerId) {
        hasJobOwnerAsJobUser = true;
      }
      return {
        userId: jobUser.id
      };
    });
    if (!hasJobOwnerAsJobUser && values.jobOwnerId) {
      values.jobUsers.push({
        userId: values.jobOwnerId
      });
    }
    const [job] = await safeUpsert("job", values);

    const newNotifications = [];
    values.jobUsers.forEach(jobUser => {
      if (jobUser.userId !== values.jobOwnerId) {
        newNotifications.push({
          userId: jobUser.userId,
          message: `You have been added to job '${values.name}'`,
          notificationIntent: "primary",
          link: `/jobs/${job.id}`,
          notificationTypeCode: "ADDED_TO_JOB"
        });
      }
    });
    await createNotification(newNotifications);

    if (returnJob) {
      return job;
    } else if (refetchCustomerRequest) {
      await refetchCustomerRequest();
    } else {
      const route = "/jobs/" + job.id;
      history.push(route);
    }
    hideModal();
  } catch (error) {
    window.toastr.error("Error creating job.");
    console.error("error:", error);
  }
}
