/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import {
  DataTable,
  withSelectedEntities,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { compose } from "recompose";

import { Tooltip, Icon } from "@blueprintjs/core";
import {
  concentrationColumn,
  massColumn,
  volumeColumn
} from "../../../../src-shared/utils/libraryColumns";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { aliquotVolumeDeadWarningRender } from "../../AliquotVolumeLessThanDeadWarning";

const schema = [
  {
    type: "action",
    width: 35,
    render: (_, record) => {
      if (record.isDry) {
        return (
          <Tooltip content="This aliquot is dry and will need to be rehydrated before running PCR.">
            <Icon
              intent="warning"
              style={{ marginRight: 10 }}
              icon="warning-sign"
            />
          </Tooltip>
        );
      }
    }
  },
  {
    displayName: "Plate",
    path: "aliquotContainer.containerArray.name"
  },
  {
    displayName: "Tube Barcode",
    path: "aliquotContainer.barcode.barcodeString"
  },
  {
    displayName: "Well",
    render: (v, r) => getAliquotContainerLocation(r.aliquotContainer)
  },
  {
    ...volumeColumn,
    render: aliquotVolumeDeadWarningRender
  },
  concentrationColumn,
  massColumn
];

class SelectAliquotsForPCRDialog extends Component {
  handleClick = record => {
    const {
      selectedAliquots = [],
      makeSelection,
      selectKey,
      hideModal
    } = this.props;
    hideModal();
    makeSelection(record || selectedAliquots[0], selectKey);
  };

  render() {
    const { aliquotOptions, selectedAliquots = [], hideModal } = this.props;
    return (
      <div>
        <DataTable
          isSimple
          isSingleSelect
          noPadding={false}
          withSearch
          onDoubleClick={this.handleClick}
          formName="selectAliquotsForPCR"
          schema={schema}
          entities={aliquotOptions}
        />
        <DialogFooter
          hideModal={hideModal}
          disabled={!selectedAliquots.length}
          onClick={() => this.handleClick()}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog({ title: "Select Aliquots", style: { width: 800 } }),
  withSelectedEntities({
    formName: "selectAliquotsForPCR",
    name: "selectedAliquots"
  })
)(SelectAliquotsForPCRDialog);
