/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { map } from "lodash";
import { InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { getToolOutputItems } from "../../../utils";

function NameOutputData({ sourcePlateMapGroup, toolSchema }) {
  const outputItems = getToolOutputItems(toolSchema);

  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Name Output Data"
        helper="Input names for output data."
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap"
        }}
      >
        {map(outputItems, ({ name, label, defaultValue }) => {
          if (sourcePlateMapGroup && name === "plateMapGroup") return;
          return (
            <div
              style={{
                boxSizing: "border-box",
                maxWidth: "45%",
                marginRight: 10,
                minWidth: "45%"
              }}
              key={name}
            >
              <InputField
                label={label}
                name={name + "Name"}
                isRequired
                generateDefaultValue={{
                  ...defaultValueConstants.PCR_PLANNING_OUTPUT_NAME,
                  customParams: {
                    outputType: defaultValue
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NameOutputData;
