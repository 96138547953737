/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import ReactDOM from "react-dom";

const SelectionLayer = ({
  corner1,
  corner2,
  onMouseMove,
  onMouseUp
}: {
  corner1: { x: number | undefined; y: number | undefined };
  corner2: { x: number | undefined; y: number | undefined };
  onMouseMove: (event: React.MouseEvent) => void;
  onMouseUp: (event: React.MouseEvent) => void;
}) => {
  const [parentEl, setParentEl] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    setParentEl(document.getElementById("root"));
  }, []);

  if (
    !parentEl ||
    corner1.x === undefined ||
    corner1.y === undefined ||
    corner2.x === undefined ||
    corner2.y === undefined
  )
    return null;

  return ReactDOM.createPortal(
    <svg
      className="above-dialog"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight
      }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <rect
        {...{
          x: Math.min(corner1.x, corner2.x),
          y: Math.min(corner1.y, corner2.y),
          width: Math.abs(corner1.x - corner2.x),
          height: Math.abs(corner1.y - corner2.y),
          fill: "#AD99FF",
          fillOpacity: 0.25,
          stroke: "#634DBF",
          strokeWidth: 1,
          rx: 5,
          ry: 5,
          style: {
            pointerEvents: "none"
          }
        }}
      />
    </svg>,
    parentEl
  );
};

export default SelectionLayer;
