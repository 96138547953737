/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { CheckboxField, InputField, InfoHelper } from "@teselagen/ui";
import { compose } from "recompose";
import { set } from "lodash";
import GenericSelect from "../../../../src-shared/GenericSelect";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import {
  volumeRender,
  concentrationRender,
  massRender,
  standardizeVolume,
  molarityRender,
  materialConcentrationRender
} from "../../../../src-shared/utils/unitUtils";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import ChangeAdditiveCompositionField from "../../ChangeAdditiveCompositionField";
import UnitFields from "../../UnitFields";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import ChooseAndClearWellsForPlate from "../../ChooseAndClearWellsForPlate";

let EmptyWellOption = ({ containerArrays }) => {
  const allWellsEmpty = useMemo(() => {
    return containerArrays.every(c => {
      return c.aliquotContainers.every(
        ac => !ac.aliquot && !ac.additives.length
      );
    });
  }, [containerArrays]);

  const validateEmptyWells = useCallback(
    val => {
      if (allWellsEmpty && !val) {
        return "All wells on chosen plates are empty. Check the above box to add reagents to empty wells.";
      }
    },
    [allWellsEmpty]
  );
  return (
    <CheckboxField
      validate={validateEmptyWells}
      name="addToEmptyWells"
      label={
        <span>
          Add reagents to empty wells
          <InfoHelper
            isInline
            style={{ marginLeft: 5 }}
            content="Check this option if you would like to add reagents to empty wells"
          />
        </span>
      }
    />
  );
};

EmptyWellOption = stepFormValues("containerArrays")(EmptyWellOption);

function ChooseWellsSection({
  containerArrays,
  specifyWells,
  change,
  selectedWells = {}
}) {
  function clearWells(containerArray) {
    change("selectedWells", {
      ...selectedWells,
      [containerArray.id]: undefined
    });
  }
  function chooseWells(containerArray, wells) {
    change("selectedWells", { ...selectedWells, [containerArray.id]: wells });
  }

  return (
    <div>
      <CheckboxField label="Specify wells to add to" name="specifyWells" />
      {specifyWells &&
        containerArrays.map(containerArray => {
          return (
            <div key={containerArray.id} style={{ marginBottom: 15 }}>
              <div style={{ marginBottom: 5 }}>{containerArray.name}</div>
              <ChooseAndClearWellsForPlate
                notOptional
                containerArray={containerArray}
                selectedWells={selectedWells[containerArray.id]}
                clearWells={() => clearWells(containerArray)}
                chooseWells={wells => chooseWells(containerArray, wells)}
              />
            </div>
          );
        })}
    </div>
  );
}

const ChooseWellsSectionWrapped = stepFormValues(
  "containerArrays",
  "specifyWells",
  "selectedWells"
)(ChooseWellsSection);

function AddReagents(props) {
  const renderLotDisplay = lot => {
    return (
      <RecordInfoDisplay
        recordInfo={[
          ["Name", lot.name],
          ["Volume", volumeRender(lot)],
          ["Concentration", concentrationRender(lot)],
          ["Molarity", molarityRender(lot)],
          ["Material Concentration", materialConcentrationRender(lot)],
          ["Mass", massRender(lot)]
        ]}
      />
    );
  };

  const renderReagentOptionsForm = () => {
    const {
      reagents = [],
      reagentInfo = {},
      noEmptyWellOption,
      forWorklist,
      toolSchema,
      stepFormProps: { change }
    } = props;
    return (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Reagent Options"
          helper="Provide a volume and concentration to be added to each well. You have the option of specifying a reagent lot."
        />
        {!noEmptyWellOption && <EmptyWellOption toolSchema={toolSchema} />}

        {!forWorklist && (
          <ChooseWellsSectionWrapped toolSchema={toolSchema} change={change} />
        )}
        <ChangeAdditiveCompositionField name="changeAdditiveComposition" />

        {reagents.map((reagent, i) => {
          const {
            id,
            name,
            additiveType: { name: typeName },
            isDry,
            lots
          } = reagent;

          const tableParamOptions = {
            additionalFilter: () => {
              return {
                additiveMaterialId: id
              };
            }
          };

          return (
            <div key={id}>
              <h6>
                {name} ({typeName})
              </h6>
              <div className="tg-flex justify-space-between">
                <UnitFields
                  {...{
                    namePrefix: `reagentInfo.id${id}.`,
                    isDry,
                    toolSchema
                  }}
                />
                <div className="tg-flex-separator" />
                <div>
                  {lots.length ? (
                    <GenericSelect
                      {...{
                        name: `reagentInfo.id${id}.lot`, //the field name within the redux form Field
                        schema: ["name", dateModifiedColumn],
                        getButtonText,
                        dialogProps: {
                          noButtonClickPropagate: true // stop the button click from row selection
                        },
                        fragment: ["lot", "id name updatedAt"],
                        tableParamOptions,
                        additionalDataFragment: [
                          "lot",
                          /* GraphQL */ `
                            {
                              id
                              name
                              volume
                              volumetricUnitCode
                              concentration
                              concentrationUnitCode
                              molarity
                              molarityUnitCode
                              mass
                              massUnitCode
                              materialConcentration
                              materialConcentrationUnitCode
                            }
                          `
                        ]
                      }}
                    />
                  ) : (
                    "No Reagent Lots in Inventory"
                  )}
                  {reagentInfo["id" + id] &&
                    reagentInfo["id" + id].lot &&
                    renderLotDisplay(reagentInfo["id" + id].lot)}
                </div>
              </div>
              {i !== reagents.length - 1 && <hr className="tg-section-break" />}
            </div>
          );
        })}
      </div>
    );
  };

  const { reagents = [] } = props;
  return (
    <React.Fragment>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Select Reagents"
          helper="Select one or more reagents
              to add to the destination wells or
              tubes of each transfer. Specify a
              reagent lot to transfer from if one
              exists in inventory."
        />
        <div>
          <GenericSelect
            {...{
              name: "reagents",
              isRequired: true,
              schema: [
                "name",
                { displayName: "Reagent Type", path: "additiveType.name" },
                dateModifiedColumn
              ],
              isMultiSelect: true,
              fragment: [
                "additiveMaterial",
                "id name isDry additiveType {code name} updatedAt"
              ],
              additionalDataFragment: [
                "additiveMaterial",
                /* GraphQL */ `
                  {
                    id
                    name
                    isDry
                    additiveType {
                      code
                      name
                    }
                    lots {
                      id
                    }
                  }
                `
              ]
            }}
          />
        </div>
      </div>

      {!!reagents.length && renderReagentOptionsForm()}
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Name Reagent List"
          helper="Choose a name for the generated list to track transfers."
        />
        <InputField
          name="addReagentListName"
          isRequired
          label="Reagent List Name"
          generateDefaultValue={{
            ...defaultValueConstants.REAGENT_LIST_NAME
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default compose(stepFormValues("reagents", "reagentInfo"))(AddReagents);

const getButtonText = val =>
  val ? "Select Different Reagent Lot" : "Select Reagent Lot";

export function validateReagentVolumes({
  errors,
  reagents = [],
  reagentInfo = {},
  numberOfTransfers
}) {
  if (!reagents.length) return;
  errors.reagentInfo = {};
  reagents.forEach(reagent => {
    const key = "id" + reagent.id;
    const { volume, volumetricUnitCode, lot } = reagentInfo[key] || {};
    if (lot && volume && lot.volume) {
      let transferVolume = standardizeVolume(volume, volumetricUnitCode);
      if (numberOfTransfers)
        transferVolume = transferVolume * numberOfTransfers;
      if (
        transferVolume > standardizeVolume(lot.volume, lot.volumetricUnitCode)
      ) {
        errors.reagentInfo[key] = {
          volume: numberOfTransfers
            ? "Lot does not have enough volume for all transfers"
            : "Volume must be less than chosen lot volume."
        };
      }
    } else if (volume && lot && !lot.volume) {
      errors.reagentInfo[key] = {
        volume: "Chosen lot does not have enough volume."
      };
    }
    if (reagentInfo[key] && !isValidPositiveNumber(reagentInfo[key].volume)) {
      set(errors, `reagentInfo[${key}].volume`, "Please enter a valid volume.");
    }
  });
}
