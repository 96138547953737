/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment iconFragment on icon {
    id
    cid
    name
    path
    isSbol
  }
`;
