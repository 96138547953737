/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { tgFormValues } from "@teselagen/ui";
import { Link } from "react-router-dom";
import { DataTable, CheckboxField, BlueprintError } from "@teselagen/ui";
import {
  getAliquotContainerTableSchema,
  validateMaterialPlates
} from "../../../../utils/plateUtils";
import { getAlphnumericWellLocation } from "../../../../../src-shared/utils/getAlphnumericWellLocation";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import { uniq, flatMap, isEmpty } from "lodash";
import { Button, Tooltip, Icon, Intent } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import toolConstants from "../constants.json";
import PlateMapView from "../../../../components/PlateMapView";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { tuplingToolPlateFragment } from "../fragments";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class SelectSamples extends React.Component {
  state = {
    loading: false
  };

  componentDidUpdate(oldProps) {
    const {
      containerArrays: oldContainerArrays = [],
      stepFormProps: { change }
    } = oldProps;

    const { containerArrays: newContainerArrays = [] } = this.props;
    const oldIds = oldContainerArrays.map(c => c.id);
    const newIds = newContainerArrays.map(c => c.id);
    const equalContainerArrays =
      newIds.every(id => oldIds.includes(id)) &&
      newIds.length === oldIds.length;
    // No easy access to the GenericSelect's postSelectDataTable delete button function. Thus when deleting plates, tuplingData needs to be emptied.
    if (!equalContainerArrays && !newIds.length) {
      change("tuplingData", []);
    }
  }

  // This functions parses the file that a user can upload. This file may be a single csv file or a zip file containing a csv and genbank files.
  // readTuplingUpload = async (files, onChange) => {
  //   const {
  //     stepFormProps: { change }
  //   } = this.props;
  //   // The File uploader returns a list of files, however this tool requires that only one file has to be uploaded.
  //   if (files.length > 1) {
  //     return window.toastr.error(
  //       "Error: Multiple files uploaded, please compress them into a ZIP file."
  //     );
  //   }
  //   const file = files[0];

  //   if (!file) return false;
  //   try {
  //     if (isZipFile(file)) {
  //       const files = await extractZipFiles(file);
  //       const csvFile = files.find(
  //         file => file.name.split(".").pop() === "csv"
  //       );
  //       if (!csvFile) {
  //         onChange([]);
  //         return window.toastr.error("No CSV file found insize zip.");
  //       }
  //       try {
  //         const csv = await parseCsvFile(csvFile);
  //         const newFile = {
  //           ...csvFile,
  //           csv,
  //           sequenceFiles: without(files, csvFile),
  //           loading: false
  //         };
  //         onChange([newFile]);
  //         // parses GenBank files (if found) and tries to match them with the reference name of CSV records.
  //         const parsedGenbankFiles = await parseSequenceFiles(
  //           newFile.sequenceFiles
  //         ).map((file, idx) => {
  //           let featureTags = file.sequenceFeatures.map(feature => ({
  //             name: feature.name,
  //             type: feature.type
  //           }));
  //           featureTags = featureTags.map(feature => {
  //             return Object.keys(feature).map(key => feature[key]);
  //           });
  //           return {
  //             circular: file.circular,
  //             name: newFile.sequenceFiles[idx].name.split("/").slice(-1)[0],
  //             featureTags: uniq(featureTags.flat()),
  //             referenceSequence: file.sequenceFragments
  //               .map(fragment => fragment.fragment)
  //               .join(""),
  //             sequenceAnnotations: file.sequenceFeatures.map(
  //               (sequenceFeature, idx) =>
  //                 Object.assign(sequenceFeature, { id: idx })
  //             )
  //           };
  //         });
  //         let featureTags = flatMap(
  //           flatMap(parsedGenbankFiles).map(parsedGenbankFile => {
  //             return parsedGenbankFile.featureTags;
  //           })
  //         );
  //         let featureTags2 = uniq(
  //           flatMap(
  //             featureTags.map(feature => [feature.name, feature.type])
  //           ).filter(val => val)
  //         );
  //         change("featureTags", featureTags2);
  //         // Updates the stepform value "tuplingData" with the CSV and genbank data.
  //         change(
  //           "tuplingData",
  //           newFile.csv.data.map(record => {
  //             let circular = false;
  //             let ignored = false;
  //             let referenceSequence;
  //             let referenceSequencePreview;
  //             let sequenceFeatureTags = [];
  //             let sequenceAnnotations = [];
  //             let unparsedGenbankFile;
  //             let parsedGenbankFile;
  //             let warning;
  //             let error;

  //             // Set the circular field as false by default if it is not found on the csv file.
  //             if (record["circular (optional)"])
  //               circular =
  //                 record["circular (optional)"].toLowerCase() === "true" ||
  //                 record["circular (optional)"].toLowerCase() === "yes";
  //             // Set the ignored field as false by default if it is not found on the csv file.
  //             if (record["ignore (optional)"])
  //               ignored =
  //                 record["ignore (optional)"].toLowerCase() === "true" ||
  //                 record["ignore (optional)"].toLowerCase() === "yes";
  //             // get the matching unparsed genbank file of the current record. This one will be used to send it to the backend as it may be lighter in size.
  //             unparsedGenbankFile = newFile.sequenceFiles.filter(
  //               unparsedGenbankFile =>
  //                 record.referenceName ===
  //                 unparsedGenbankFile.name
  //                   .split("/")
  //                   .slice(-1)[0]
  //                   .split(".")[0]
  //             )[0];
  //             // get the matching parsed genbank file of the current record. This one is used in this function to get feature tags and detection of DNA circularity.
  //             parsedGenbankFile = parsedGenbankFiles.filter(
  //               parsedGenbankFile =>
  //                 record.referenceName === parsedGenbankFile.name.split(".")[0]
  //             )[0];
  //             // The reference sequence stored in each tupling record will correspond to the one found by the genbank parser, if no genbank file is detected to this record, the one uploaded by the user will be used.
  //             referenceSequence = parsedGenbankFile
  //               ? parsedGenbankFile.referenceSequence
  //               : record.referenceSequence;
  //             // Sequence annoations of the genbank file will be used by the Tupling Tool algorithm.
  //             sequenceAnnotations = parsedGenbankFile
  //               ? parsedGenbankFile.sequenceAnnotations
  //               : [];
  //             // The sequence fetures will be a list of strings representing the name or type of each genbank annotation. If no genbank file is detected, this list will be empty.
  //             sequenceFeatureTags = parsedGenbankFile
  //               ? parsedGenbankFile.featureTags
  //               : [];
  //             // The circular property of each record will correspond to the DNA circularity detection of the genbank parser OR'd with the one uplaoded by the user.
  //             circular = parsedGenbankFile
  //               ? parsedGenbankFile.circular
  //               : circular;
  //             // If the user explicitly set the record's ciruclarity to be FALSE, and the genbank parser detected it to be TRUE, then a warning will be shown to the user on the record.
  //             if (
  //               parsedGenbankFile &&
  //               parsedGenbankFile.circular &&
  //               !circular
  //             ) {
  //               warning =
  //                 "Genbank Parser detected and set the reference sequence as circular ! ";
  //             }
  //             // A referenceSequencePreview property is created to show it on the datatable. As the actual reference sequence may be too long.
  //             if (record.referenceSequence) {
  //               referenceSequencePreview =
  //                 referenceSequence.length >= 30
  //                   ? [
  //                       referenceSequence.slice(0, 10),
  //                       " . . . ",
  //                       referenceSequence.slice(-10, -1)
  //                     ].join("")
  //                   : referenceSequence;
  //             }
  //             // If no reference sequence is found. An error will be shown for this record.
  //             else {
  //               error = `No reference sequence was found for this record. Reference ${record.referenceName} has the referenceSequence CSV field or its Genbank file missing.`;
  //             }
  //             return {
  //               ...record,
  //               warning: warning,
  //               error: error,
  //               unparsedGenbankFile: unparsedGenbankFile,
  //               referenceSequence: referenceSequence,
  //               referenceSequencePreview: referenceSequencePreview,
  //               circular: circular,
  //               ignored: ignored,
  //               sequenceAnnotations: sequenceAnnotations,
  //               sequenceFeatureTags: sequenceFeatureTags
  //             };
  //           })
  //         );
  //         return false;
  //       } catch (error) {
  //         console.error("error:", error);
  //         return window.toastr.error("Error parsing CSV file in zip.");
  //       }
  //     } else {
  //       const csv = parseCsvString(file.parsedString);
  //       const newFile = {
  //         ...file,
  //         csv,
  //         loading: false
  //       };
  //       onChange([newFile]);
  //       // Updates the stepform value "tuplingData" with just the CSV data.
  //       change(
  //         "tuplingData",
  //         newFile.csv.data.map(record => {
  //           // Set the circular field as false by default if it is not found on the csv file.
  //           let circular =
  //             record["circular (optional)"].toLowerCase() === "true" ||
  //             record["circular (optional)"].toLowerCase() === "yes";
  //           // Set the ignored field as false by default if it is not found on the csv file.
  //           let ignored =
  //             record["ignore (optional)"].toLowerCase() === "true" ||
  //             record["ignore (optional)"].toLowerCase() === "yes";
  //           let referenceSequence = record["referenceSequence"];
  //           let referenceSequencePreview;
  //           let warning;
  //           let error;
  //           if (record.referenceSequence) {
  //             referenceSequencePreview =
  //               record.referenceSequence.length >= 30
  //                 ? [
  //                     record.referenceSequence.slice(0, 10),
  //                     " . . . ",
  //                     record.referenceSequence.slice(-10, -1)
  //                   ].join("")
  //                 : record.referenceSequence;
  //           }
  //           // If no reference sequence is found. An error will be shown for this record.
  //           if (record.referenceSequence) {
  //             error = `No reference sequence was found for this record. Reference ${record.referenceName} has the referenceSequence CSV field or its Genbank file missing.`;
  //           }
  //           return {
  //             ...record,
  //             warning: warning,
  //             error: error,
  //             referenceGenbankFile: "N/A",
  //             referenceSequence: referenceSequence,
  //             referenceSequencePreview: referenceSequencePreview,
  //             circular: circular,
  //             ignored: ignored,
  //             sequenceFeatureTags: []
  //           };
  //         })
  //       );
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     change("warning", { check: true, message: "Error parsing ZIP file." });
  //     return;
  //   }
  // };

  onSelectContainerArrays = containerArrays => {
    const {
      stepFormProps: { change }
    } = this.props;
    let newTuplingData = [];
    try {
      newTuplingData = containerArrays.map(containerArray => {
        if (containerArray) {
          const barcode = containerArray.barcode
            ? containerArray.barcode.barcodeString
            : "N/A";
          return containerArray.aliquotContainers
            .filter(aliquotContainer => aliquotContainer.aliquot)
            .map(aliquotContainer => {
              let warning;
              let error;
              let referenceSequence;
              let referenceAnnotations = [];
              let referenceParts = [];
              const referenceSequenceSnippet = "";
              let referenceId;
              let referenceName = "N/A";
              let circular = false;
              let featureTags = [];
              const position =
                aliquotContainer.rowPosition !== undefined ||
                aliquotContainer.columnPosition !== undefined
                  ? getAlphnumericWellLocation([
                      {
                        rowPosition: aliquotContainer.rowPosition,
                        columnPosition: aliquotContainer.columnPosition
                      }
                    ])
                  : "N/A";
              if (aliquotContainer.aliquot.sample.material) {
                if (
                  aliquotContainer.aliquot.sample.material
                    .polynucleotideMaterialSequence
                ) {
                  circular =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.circular;
                  referenceId =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.id;
                  referenceName =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.name;
                  referenceSequence =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.sequenceFragments;
                  referenceAnnotations =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.sequenceFeatures;
                  referenceParts =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.parts;
                  if (!referenceSequence || referenceSequence.length === 0) {
                    error = "No reference sequence was found for this sample.";
                  }
                }
                if (
                  !referenceAnnotations ||
                  referenceAnnotations.length === 0
                ) {
                  if (!referenceParts || referenceParts.length === 0) {
                    warning =
                      "No annotations found on this reference sequence.";
                  }
                }
                featureTags = referenceAnnotations.concat(referenceParts);
              } else {
                error = "No reference sequence was found for this sample.";
              }
              return {
                barcode,
                position,
                aliquotId: aliquotContainer.aliquot.id,
                sampleName: aliquotContainer.aliquot.sample.name,
                referenceId,
                referenceName,
                referenceSequenceSnippet,
                featureTags,
                circular,
                error: error,
                warning: warning,
                ignored: false
              };
            });
        }
        return {};
      });
    } catch (error) {
      console.error(error);
      change("warning", {
        check: true,
        message: "Error getting the plate(s) aliquots."
      });
    }
    const featureTags = flatMap(
      flatMap(newTuplingData).map(newTuplingRecord => {
        return newTuplingRecord.featureTags;
      })
    );
    const featureTags2 = uniq(
      flatMap(featureTags.map(feature => [feature.name, feature.type])).filter(
        val => val
      )
    );
    change("featureTags", featureTags2);
    change("tuplingData", flatMap(newTuplingData));
  };

  // Function that clears the current object "tuplingData" stepform value.
  clearTuplingData = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("tuplingData", []);
    change("aliquotPlates", []); // Not working correctly together with the plate viewer.
    change("tuplingSchemaTable", null);
    change("tuplingUploadData", null);
    change("warning", { check: false, message: "" });
  };

  getTuplingSchema = () => {
    const schema = {
      fields: [
        {
          type: "action",
          width: 35,
          render: (_, record) => {
            if (record.error || record.warning) {
              return (
                <Tooltip content={record.error || record.warning}>
                  <Icon
                    intent={record.error ? "danger" : "warning"}
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
          }
        },
        { path: "barcode", displayName: "Barcode" },
        { path: "position", displayName: "Position" },
        { path: "sampleName", displayName: "Sample Name" },
        // { path: "referenceSequenceSnippet", displayName: "Reference Sequence" },
        {
          path: "referenceSequenceId",
          displayName: "Reference Sequence",
          render: (_, record) => {
            return record.referenceId ? (
              <Link
                key="dnaMaterialLink"
                to={`/dna-sequences/${record.referenceId}`}
                target="_blank"
              >
                {record.referenceName}
              </Link>
            ) : (
              <span>N/A</span>
            );
          }
        },
        { path: "referenceName", displayName: "Material" },
        { path: "circular", displayName: "Circular DNA" },
        { path: "ignore", displayName: "Ignore" }
      ]
    };
    return schema;
  };

  // This updates the formvalue everytime the user checks/unchecks the ignore checkboxes.
  updateIgnoredSamples = ignoredSample => {
    const {
      stepFormProps: { change },
      tuplingData
    } = this.props;
    const newTuplingData = tuplingData.map(sample => {
      if (sample.sampleName === ignoredSample.sampleName) {
        return { ...sample, ignored: !sample.ignored };
      } else {
        return { ...sample };
      }
    });

    change("tuplingData", newTuplingData);
  };

  render() {
    const {
      Footer,
      footerProps,
      tuplingData = [],
      containerArrays = [],
      toolSchema,
      isIntegratedMap = {},
      containerArraysSelectedEntities: selectedPlates = [],
      warning = {},
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;
    const selectedPlate = selectedPlates[0];

    const plateErrors = validateMaterialPlates(containerArrays, {
      materialTypeCode: "DNA",
      enforceSequenceLinks: true,
      asObject: true
    });
    let containerArrayPreview;
    let plateViewer;
    // Builds a containerArrayPreview object whenever a plate is selected from the selected plates data table.
    if (selectedPlate) {
      containerArrayPreview = {
        ...selectedPlate,
        aliquotContainers: selectedPlate.aliquotContainers.map(container => ({
          ...container,
          location: getAliquotContainerLocation(container)
        }))
      };
      plateViewer = (
        <PlateMapView
          containerArrayType={containerArrayPreview.containerArrayType}
          containerArray={containerArrayPreview}
          tableSchema={getAliquotContainerTableSchema(
            containerArrayPreview.aliquotContainers
          )}
          noEntityTransform
          noPadding
        />
      );
    }

    // This table shows the results of the file uploaded on the "Select Data" section of Step 1 of the Tupling Tool.
    const table = (
      <React.Fragment>
        <div>
          <DataTable
            isSimple
            formName="tuplingSchemaDisplayTable"
            maxHeight={300}
            noSelect
            schema={this.getTuplingSchema()}
            cellRenderer={{
              ignore: (idx, record) => {
                return (
                  <div style={{ marginLeft: 10 }}>
                    <CheckboxField
                      name={record.sampleName}
                      onFieldSubmit={() => this.updateIgnoredSamples(record)}
                      defaultValue={record.ignored}
                    >
                      {" "}
                    </CheckboxField>
                  </div>
                );
              },
              circular: (_, record) => {
                if (record.circular) {
                  return "Yes";
                } else {
                  return "No";
                }
              }
            }}
            entities={tuplingData}
          />
        </div>
        {tuplingData.error && (
          <BlueprintError error="Please fix errors in Tupling Tool input data." />
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Plates"
              helper="Select plates containing samples to be multiplexed."
            />
            {tuplingData.length !== 0 &&
              containerArrays.length === 0 &&
              !isIntegratedMap.containerArrays && (
                <div
                  style={{
                    marginBottom: 10,
                    marginRight: 20,
                    alignSelf: "flex-end"
                  }}
                >
                  <Button
                    id={toolConstants.CLEAR_BUTTON_ID}
                    onClick={this.clearTuplingData}
                    intent="danger"
                    text="Clear"
                  />
                </div>
              )}
          </div>
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "containerArrays", //the field name within the redux form Field
                isMultiSelect: true,
                isRequired: true,
                onSelect: this.onSelectContainerArrays,
                onClear: this.clearTuplingData,
                schema: ["name", dateModifiedColumn],
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: tuplingToolPlateFragment,
                postSelectDTProps: {
                  formName: toolSchema.code,
                  plateErrors,
                  schema: [
                    platePreviewColumn({
                      plateErrors
                    }),
                    "name",
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    }
                  ],
                  buttonProps: {
                    loading: isLoadingMap.containerArrays,
                    disabled: isDisabledMap.containerArrays
                  }
                }
              }}
            />
          </div>
          {containerArrays.length !== 0 && plateViewer}
          <div style={{ marginTop: "24px" }}>
            {!(tuplingData.length === 0) && (
              <HeaderWithHelper
                header="Samples to be Multiplexed"
                helper="The following table shows a summary of the aliquots that are going to be submitted for multiplexing."
              />
            )}
          </div>
          {tuplingData.length !== 0 && table}
          {warning.check && (
            <div className="tg-flex justify-flex-end">
              <BlueprintError error={warning.message} />
            </div>
          )}
        </div>
        <Footer
          {...footerProps}
          errorMessage={!isEmpty(plateErrors) && "Review plate errors."}
          nextButton={
            <Button
              id={toolConstants.STEP_1_NEXT_BUTTON_ID}
              type="next"
              intent={Intent.PRIMARY}
              text="Next"
              disabled={
                !tuplingData.length ||
                !isEmpty(plateErrors) ||
                tuplingData
                  .filter(record => !record.ignored)
                  .some(record => record.error)
              }
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  tgFormValues("featureTags", "tuplingData", "containerArrays", "warning")
)(SelectSamples);
