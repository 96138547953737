/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { identity } from "lodash";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import LaunchNewLookupValueDialog from "../components/Dialogs/LaunchNewLookupValueDialog";
import { wrapDialog } from "@teselagen/ui";
import modelNameToReadableName from "../../src-shared/utils/modelNameToReadableName";

import { ID_TYPE_MODELS, modelToFragMap } from "./TypeSettingsContainer";
import { withHandlers, withProps } from "recompose";
import { asyncValidateName } from "../../src-shared/utils/formUtils";

import { safeUpsert } from "../../src-shared/apolloMethods";

const componentWithQueries = ({
  model,
  label,
  initialValues,
  wrapper = identity,
  neededFormValues = [],
  ...rest
}) => {
  const fragment = modelToFragMap[model];
  if (!fragment) {
    console.error("model name not passed to container");
    return null;
  }
  const editing = initialValues.id || initialValues.code;
  const readableModelName = modelNameToReadableName(label || model, {
    upperCase: true
  });
  const title = `${editing ? "Edit" : "New"} ${readableModelName}`;
  const componentProps = {
    ...rest,
    readableModelName,
    initialValues,
    model,
    label,
    fragment
  };

  const idAs = ID_TYPE_MODELS.includes(model) ? "id" : "code";
  const WrappedComponent = compose(
    wrapDialog({ title, style: { width: 660 } }),
    withProps(() => {
      return {
        model,
        isCodeModel: !ID_TYPE_MODELS.includes(model),
        idAs
      };
    }),
    withHandlers({
      upsertLookupValue: () => items => {
        return safeUpsert(model, items, {
          idAs,
          forceCreate: !editing
        });
      }
    }),
    wrapper,
    reduxForm({
      enableReinitialize: true,
      form: "launchNewLookupValueForm",
      validate,
      ...asyncValidateName
    }),
    ...(neededFormValues.length ? [tgFormValues(...neededFormValues)] : [])
  )(LaunchNewLookupValueDialog);
  return <WrappedComponent {...componentProps} />;
};

export default componentWithQueries;

function validate(values, ownProps) {
  const errors = {};
  if (ownProps.validator) {
    ownProps.validator(values, errors, ownProps);
  }
  if (
    ownProps.model === "additiveType" &&
    ["growth media", "culture media"].includes(values.name)
  ) {
    errors.name = `"Growth Media" and "Culture Media" are reserved system types.`;
  }
  return errors;
}
