/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { parse } from "qs";
import pluralIfNeeded from "../../src-shared/utils/pluralIfNeeded";
import { headers } from "@teselagen/auth-utils";
import {
  volumeField,
  volumetricUnitCodeField
} from "../../../tg-iso-shared/src/utils/unitCodeFields";

export { pluralIfNeeded };

export const getUnitCodes = units => units.map(({ code }) => code);

export function displayIf(cond, element, defaultElement) {
  if (cond) return element;
  return defaultElement;
}

export const convertObjectKeysToLowerCase = obj => {
  Object.keys(obj).forEach(key => {
    const temp = obj[key];
    delete obj[key];
    obj[key.toLowerCase()] = temp;
  });
  return obj;
};

export function parseHash(location) {
  return parse(location.hash.replace(/^#/, ""));
}

export function replaceMus(s) {
  return s.replace(/\u00b5|\u03BC/g, "u");
}

export const dataTableHeaderMap = {
  POOLING_LIST: [
    {
      path: "barcode",
      description: "Barcode of the sample",
      example: "BCD123456",
      isRequired: true
    },
    {
      path: "position",
      description:
        "Position of the sample. Required if container is a plate or tube rack",
      example: "A1"
    },
    {
      path: "pooledSampleName",
      description: "Name of the pooled sample",
      example: "Pool_ABC",
      isRequired: true
    },
    {
      path: "proteinPattern",
      description: "Pattern of protein",
      example: "Pattern_XYZ",
      isRequired: false
    },
    volumeField,
    { ...volumetricUnitCodeField, alternatePathMatch: "volumetricUnit" }
  ],

  COLONY_PICKING_FEEDBACK: [
    {
      path: "destinationPlateBarcode",
      alternatePathMatch: ["TargetPlate_Name"],
      description: "Barcode of the destination plate",
      example: "PLT987654",
      isRequired: true
    },
    {
      alternatePathMatch: ["TargetPlate_Location"],
      path: "destinationWell",
      description: "Well identifier of the destination",
      example: "A1",
      isRequired: true
    },
    {
      alternatePathMatch: ["SourcePlate_Name"],
      path: "sourcePlateBarcode",
      description: "Barcode of the source plate",
      example: "PLT123456",
      isRequired: true
    },
    {
      alternatePathMatch: [
        { path: "SourcePlate_Sector", format: v => Number(v) + 1 }
      ],
      path: "sourceLocation",
      description: "Location of the source",
      example: "B3",
      isRequired: true
    },
    {
      path: "xCoordinate",
      alternatePathMatch: ["SourcePlate_X"],
      description: "X-coordinate of the position",
      type: "number",
      example: "10",
      isRequired: false
    },
    {
      path: "yCoordinate",
      alternatePathMatch: ["SourcePlate_Y"],
      description: "Y-coordinate of the position",
      type: "number",
      example: "5",
      isRequired: false
    },
    {
      path: "diameter",
      alternatePathMatch: ["Colony_Diameter_(mm)"],
      description: "Diameter measurement",
      type: "number",
      example: "3.4",
      isRequired: false
    },
    {
      path: "date",
      alternatePathMatch: [
        {
          path: "Place_Time",
          format: v => {
            const date = new Date(v);
            return date.toLocaleDateString();
          }
        }
      ],
      description: "Optional. Date of the record (e.g. mm/dd/yyyy)",
      example: "12/05/2018",
      isRequired: false
    },
    {
      path: "time",
      alternatePathMatch: [
        {
          path: "Place_Time",
          format: v => {
            const date = new Date(v);
            return date.toLocaleTimeString();
          }
        }
      ],
      description: "Optional. Time of the record. (e.g. 8:00)",
      example: "8:00",
      isRequired: false
    }
  ],

  SEQUENCE_ASSOCIATION: [
    {
      path: "barcode",
      description: "Barcode of the sample. Required",
      example: "BCD123456"
    },
    {
      path: "position",
      description:
        "Position of the sample. Required if container is plate or rack",
      example: "A1"
    },
    {
      path: "sequenceType",
      description:
        "Type of the sequence. Required if not provided in sequence file; choose from the following: CIRCULAR_DNA, LINEAR_DNA, OLIGO",
      example: "CIRCULAR_DNA"
    },
    {
      path: "sequenceName",
      description:
        "Name of the sequence. Recommended. If missing, a name will be generated via prefix. Required if uploading sequence file that contains multiple sequences",
      example: "Sequence_ABC"
    },
    {
      path: "prefix",
      displayName: "Sequence Name Prefix",
      description:
        "Prefix for the sequence name. Recommended. If name and prefix are not provided, the sequence will be named 'Untitled Sequence'",
      example: "Prefix_"
    },
    {
      path: "sequence",
      description: "Sequence data. Required if Sequence File not provided",
      example: "ACGT"
    },
    {
      path: "sequenceFile",
      description: "Sequence file upload. Required if Sequence not provided",
      example: "example.fasta"
    }
  ]
};

export const ignoreBatchHeaders = {
  context: {
    headers: {
      [headers.activeBatchHeaderKey]: ""
    }
  }
};
