/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button, Intent, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { compose } from "redux";
import { TextareaField } from "@teselagen/ui";

import scrollIntoView from "dom-scroll-into-view";
import qs from "qs";
import AvatarIcon from "../../AvatarIcon";
import { getLocationHashPathName } from "../utils";
import MentionField from "../MentionField";
import commentFragment from "../commentFragment";
import "./style.css";
import { noop } from "lodash";
import { createNotification } from "../../utils/createNotification";
import { safeUpsert } from "../../apolloMethods";

export class AddComment extends Component {
  componentDidMount() {
    if (this.props.isReplyForm) {
      // scroll so the form is showing fully
      const cancelButton = document.getElementById(
        "reply-to-comment-cancel-button"
      );
      const container = document.getElementById("tg-comment-list");
      if (cancelButton) {
        scrollIntoView(cancelButton, container, {
          onlyScrollIfNeeded: true
        });
      }
    }
  }

  onSubmit = (opts = {}) => async formData => {
    const { messagePrefix = "", commentProps, beforeFinish = noop } = opts;
    const {
      reset,
      record: { id, __typename } = {},
      commentId,
      cancelReply,
      currentUser,
      afterCreate,
      withMentions,
      userIdToReplyTo,
      isReplyForm,
      handleMentionsSubmit
    } = this.props;

    if (!formData.message) return false;
    this.formEl.querySelector("textarea").setAttribute("disabled", true);

    const newStringValue = withMentions
      ? formData.message.stringValue
      : formData.message;

    const data = { message: messagePrefix + newStringValue, ...commentProps };

    data.userId = currentUser.id;

    if (commentId) {
      data.commentReply = {
        commentId
      };
    } else {
      data[__typename + "Id"] = id;
    }

    try {
      const [newComment] = await safeUpsert(commentFragment, data);
      if (isReplyForm) {
        const { hash, pathName } = getLocationHashPathName();

        const hashObj = qs.parse(hash.replace(/^#/, ""));
        hashObj.commentId = newComment.id;
        hashObj.parentCommentId = commentId;

        const urlHash = qs.stringify(hashObj);

        if (currentUser.id !== userIdToReplyTo) {
          await createNotification({
            message: `${currentUser.username} replied to your comment`,
            userId: userIdToReplyTo,
            notificationIntent: "primary",
            link: `${pathName}#${urlHash}`,
            notificationTypeCode: "COMMENT_REPLY"
          });
        }
      }
      if (withMentions) {
        await handleMentionsSubmit(newComment.id, newStringValue);
      }

      await beforeFinish();
      await afterCreate(newComment);
      reset();
      cancelReply && cancelReply();
    } catch (error) {
      console.error(error);
      window.toastr.error("Error creating comment");
    } finally {
      this.formEl.querySelector("textarea").removeAttribute("disabled");
    }
  };

  cancelReply = () => {
    this.props.cancelReply();
  };

  render() {
    const {
      currentUser,
      handleSubmit,
      label,
      buttonOverride,
      placeholder,
      isReplyForm,
      submitting,
      withMentions = true
    } = this.props;

    let textAreaToUse;
    const shared = {
      name: "message",
      disabled: submitting,
      autoFocus: isReplyForm,
      placeholder: placeholder,
      "data-test": `tg-${isReplyForm ? "reply" : "comment"}-message`,
      label: label
    };
    if (withMentions) {
      textAreaToUse = <MentionField {...shared} isRequired />;
    } else {
      textAreaToUse = (
        <TextareaField {...shared} submitting={submitting} isRequired />
      );
    }
    return (
      <form
        ref={n => {
          if (n) this.formEl = n;
        }}
        className={classNames("add-comment-form", { reply: isReplyForm })}
      >
        <div className="tg-flex width100">
          {isReplyForm && (
            <div className="comment-avatar">
              <AvatarIcon
                size={28}
                style={{ border: "2px solid white" }}
                user={currentUser}
              />
            </div>
          )}
          {textAreaToUse}
        </div>
        <div className="tg-flex justify-flex-end">
          {isReplyForm && (
            <Button
              style={{ marginRight: 10 }}
              id="reply-to-comment-cancel-button"
              className={Classes.SMALL + " " + Classes.MINIMAL}
              onClick={this.cancelReply}
              intent={Intent.DANGER}
              text="Cancel"
            />
          )}
          {buttonOverride ? (
            buttonOverride({
              handleSubmit,
              submitting,
              onSubmit: this.onSubmit
            })
          ) : (
            <Button
              onClick={handleSubmit(this.onSubmit())}
              data-test={`tg-submit-${isReplyForm ? "reply" : "comment"}`}
              style={{ marginRight: isReplyForm ? 15 : 0 }}
              className={Classes.SMALL + " " + Classes.MINIMAL}
              intent={Intent.SUCCESS}
              loading={submitting}
              text="Submit"
              type="submit"
            />
          )}
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: "AddCommentForm"
  })
)(AddComment);
