/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DropTarget } from "react-dnd";
import { Icon, Intent } from "@blueprintjs/core";
import { showConfirmationDialog } from "@teselagen/ui";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";
class TrashDropTarget extends React.Component {
  render() {
    const { connectDropTarget, isOver, canDrop } = this.props;
    return connectDropTarget(
      <div
        className={`trash-drop-target ${
          isOver && canDrop ? "trash-drop-target-hover" : ""
        }`}
      >
        <Icon
          className="trash-drop-target-icon"
          icon="trash"
          iconSize={isOver && canDrop ? 40 : 30}
        />
      </div>
    );
  }
}

export default DropTarget(
  "shape",
  {
    drop: (props, monitor) => {
      (async () => {
        try {
          const { schematic, refetchSchematic } = props;
          const { set, isBackbone } = monitor.getItem();

          if (!set || isBackbone) return;

          const confirm = await showConfirmationDialog({
            text: `Are you sure you want to delete this Bin?
                   You cannot undo this action.`,
            intent: Intent.DANGER,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            canEscapeKeyCancel: true
          });

          if (confirm) {
            const valuesToChange = schematic.schematicSets
              .filter(s => s.index > set.index)
              .map(s => ({
                id: s.id,
                index: s.index - 1
              }));
            if (valuesToChange.length)
              await safeUpsert("schematicSet", valuesToChange);

            await safeDelete("schematicSet", set.id);

            await refetchSchematic();
          }
        } catch (e) {
          console.error(e);
          window.toastr.error("Error editing schematic.");
        }
      })();
    },
    canDrop(props, monitor) {
      const { set, isBackbone } = monitor.getItem();
      return !!(set && !isBackbone);
    }
  },
  (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    };
  }
)(TrashDropTarget);
