/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import { compose } from "recompose";
import { noop } from "lodash";
import { reduxForm } from "redux-form";
import {
  InputField,
  TextareaField,
  DialogFooter,
  ReactColorField,
  AsyncValidateFieldSpinner
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { safeUpsert, deleteWithQuery } from "../apolloMethods";
import { asyncValidateName } from "../utils/formUtils";
import { withProps } from "recompose";
import UserSelect from "../UserSelect";
import appGlobals from "../appGlobals";
import CurrentUserContext from "../context/CurrentUserContext";
import { wrapDialog } from "@teselagen/ui";
import { isAdmin } from "../utils/generalUtils";

const AddProjectDialog = ({
  refetch = noop,
  hideModal = noop,
  initialValues = {},
  handleSubmit,
  submitting,
  asyncValidating
}) => {
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);
  const onSubmit = async values => {
    try {
      const valuesToUpsert = {
        id: initialValues.id,
        name: values.name,
        color: values.color,
        description: values.description
      };

      if (initialValues.id) {
        await deleteWithQuery("projectRole", {
          projectId: initialValues.id
        });
      }
      let projectMembers = [];
      if (values.projectMembers) {
        projectMembers = values.projectMembers;
      }
      let projectAdmins = [];
      if (values.projectAdmins) {
        projectAdmins = values.projectAdmins;
      }

      const [{ id: projectId }] = await safeUpsert("project", valuesToUpsert);
      await safeUpsert(
        "projectRole",
        projectMembers
          .map(member => ({
            projectId,
            userId: member.id,
            roleCode: "MEMBER"
          }))
          .concat(
            projectAdmins.map(admin => ({
              projectId,
              userId: admin.id,
              roleCode: "ADMIN"
            }))
          )
      );

      await refetch();
      refetchCurrentUser();
      hideModal();
    } catch (err) {
      console.error("err:", err);
      window.toastr.error(
        `Error ${initialValues.id ? "editing" : "creating"} project.`
      );
    }
  };

  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <div className="tg-flex align-center">
          <div
            style={{
              flex: 1
            }}
          >
            <InputField
              name="name"
              label="Name"
              isRequired
              rightElement={
                <AsyncValidateFieldSpinner validating={asyncValidating} />
              }
            />
          </div>
          <div className="tg-flex-separator" />
          <ReactColorField name="color" label="Color" isRequired />
        </div>
        <TextareaField name="description" label="Description" />
        <UserSelect
          name="projectAdmins"
          label="Project Admins"
          isMultiSelect
          defaultValue={[
            {
              ...currentUser,
              disabled: true
            }
          ]}
        />
        <UserSelect
          name="projectMembers"
          label="Project Members"
          isMultiSelect
        />
      </div>
      <DialogFooter
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
        hideModal={hideModal}
      />
    </div>
  );
};

export default compose(
  wrapDialog(({ initialValues = {} }) => {
    return {
      title: initialValues.id ? "Edit Project" : "Create Project"
    };
  }),
  withProps(() => {
    return {
      model: "project"
    };
  }),
  reduxForm({
    form: "createProject",
    validate: values => {
      const { projectAdmins = [] } = values;
      const errors = {};
      const currentUserIsProjectAdmin = projectAdmins.some(
        admin => admin.id === appGlobals.currentUser.id
      );
      if (!currentUserIsProjectAdmin && !isAdmin()) {
        errors.projectAdmins = "You must be a project admin.";
      }
      return errors;
    },
    ...asyncValidateName
  })
)(AddProjectDialog);
