/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { invert } from "lodash";
import { isObject } from "lodash";
import { keyBy } from "lodash";

const getArrayUnits = units => (isObject(units) ? Object.values(units) : units);

export const sortMolarityUnits = units =>
  [...getArrayUnits(units)].sort((a, b) => b.molesPerLiter - a.molesPerLiter);

export const sortMassUnits = units =>
  [...getArrayUnits(units)].sort((a, b) => b.grams - a.grams);

export const sortVolumetricUnits = units =>
  [...getArrayUnits(units)].sort((a, b) => b.liters - a.liters);

export const sortConcentrationUnits = units =>
  [...getArrayUnits(units)].sort((a, b) => b.gramsPerLiter - a.gramsPerLiter);

export const sortMaterialConcentrationUnits = units =>
  [...getArrayUnits(units)].sort((a, b) => b.unitsPerLiter - a.unitsPerLiter);

export const sortCellConcentrationUnits = units =>
  [...getArrayUnits(units)].sort((a, b) => b.cellsPerLiter - a.cellsPerLiter);

const unitNameToBaseKey = {
  volumetricUnit: "liters",
  massUnit: "grams",
  concentrationUnit: "gramsPerLiter",
  molarityUnit: "molesPerLiter",
  materialConcentrationUnit: "unitsPerLiter",
  cellConcentrationUnit: "cellsPerLiter"
};

const baseKeyToUnitName = invert(unitNameToBaseKey);

const unitGlobals = {
  loaded: false,
  volumetricUnits: undefined,
  massUnits: undefined,
  concentrationUnits: undefined,
  molarityUnits: undefined,
  materialConcentrationUnits: undefined,
  cellConcentrationUnits: undefined,
  unitNameToBaseKey,
  baseKeyToUnitName,
  init: async function(query) {
    if (unitGlobals.loaded) return;
    const queryOptions = {
      options: {
        fetchPolicy: "cache-first"
      }
    };
    unitGlobals.volumetricUnits = keyBy(
      await query(["volumetricUnit", "code name liters"], queryOptions),
      "code"
    );
    unitGlobals.concentrationUnits = keyBy(
      await query(
        ["concentrationUnit", "code name gramsPerLiter"],
        queryOptions
      ),
      "code"
    );
    unitGlobals.cellConcentrationUnits = keyBy(
      await query(
        ["cellConcentrationUnit", "code name cellsPerLiter"],
        queryOptions
      ),
      "code"
    );
    unitGlobals.massUnits = keyBy(
      await query(["massUnit", "code name grams"], queryOptions),
      "code"
    );
    unitGlobals.molarityUnits = keyBy(
      await query(["molarityUnit", "code name molesPerLiter"], queryOptions),
      "code"
    );
    unitGlobals.materialConcentrationUnits = keyBy(
      await query(
        ["materialConcentrationUnit", "code name unitsPerLiter"],
        queryOptions
      ),
      "code"
    );
    unitGlobals.loaded = true;
  },
  getOptionsForSelect: (unitsName, filteredUnits) => {
    const unitNameToSort = {
      volumetricUnit: sortVolumetricUnits,
      massUnit: sortMassUnits,
      concentrationUnit: sortConcentrationUnits,
      molarityUnit: sortMolarityUnits,
      materialConcentrationUnit: sortMaterialConcentrationUnits,
      cellConcentrationUnit: sortCellConcentrationUnits
    };
    const units = filteredUnits || unitGlobals[unitsName + "s"];
    if (!unitNameToSort[unitsName]) {
      // eslint-disable-next-line no-debugger
      debugger;
    }
    const sorted = unitNameToSort[unitsName](units);
    return sorted.map(unit => {
      return {
        value: unit.code,
        label: unit.code
      };
    });
  },
  getUnitsForBaseKey: baseKey => unitGlobals[baseKeyToUnitName[baseKey] + "s"]
};

export default unitGlobals;
