/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { vectorEditorReducer } from "@teselagen/ove";
import actions from "../actions";
import j5 from "./j5";
import ui from "./ui";
import design from "./design";
import combineReducersWithFullState from "./combineReducersWithFullState";
import massivelyParallelPartCreation from "./massivelyParallelPartCreation";
import sharedReducers from "../../redux-shared/reducer";

const getDesignEditAction = action => {
  const match = action.type.match(/^DESIGN_EDIT\/(.*)$/);
  if (!match) return null;
  else return match[1];
};

const mainReducer = combineReducersWithFullState({
  ui,
  j5,
  VectorEditor: vectorEditorReducer(),
  massivelyParallelPartCreation,
  design,
  ...sharedReducers
});

export default (state = {}, action) => {
  const newState = mainReducer(state, action);
  const designEditAction = getDesignEditAction(action);
  if (designEditAction && designEditAction !== "INSERT_SET") {
    return mainReducer(
      newState,
      actions.ui.designEditor.general.updateSelectionAfterDesignEdit(newState)
    );
  } else {
    return newState;
  }
};
