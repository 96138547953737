/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { ReactSelectField } from "@teselagen/ui";
import {
  BiosafetySelect,
  GrowthConditionFields
} from "../CreateNewStrainDialog";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";

function StrainOverwrites({
  targetOrganismClasses,
  biosafetyLevels,
  selectionMethods,
  inductionMethods,
  lengthUnits
}) {
  return (
    <div className="tg-form-border" style={{ marginBottom: 15 }}>
      <ReactSelectField
        label="Target Organism Group"
        name="targetOrganismClassId"
        options={arrayToIdOrCodeValuedOptions(targetOrganismClasses)}
      />
      <BiosafetySelect biosafetyLevels={biosafetyLevels} />
      <ReactSelectField
        label="Selection Method"
        name="selectionMethodIds"
        options={arrayToIdOrCodeValuedOptions(selectionMethods)}
        multi
      />
      <ReactSelectField
        label="Induction Method"
        name="inductionMethodIds"
        options={arrayToIdOrCodeValuedOptions(inductionMethods)}
        multi
      />
      <h6>Growth Conditions</h6>
      <GrowthConditionFields lengthUnits={lengthUnits} />
    </div>
  );
}

export default StrainOverwrites;
