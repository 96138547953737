/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import { Dialog } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, InputField, TextareaField } from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { withRouter } from "react-router-dom";

class CreateSchematicDialog extends React.Component {
  state = { submitting: false };

  onSubmit = async values => {
    this.setState({ submitting: true });
    try {
      const { hideModal, refetchSchematics, history } = this.props;

      const [{ id }] = await safeUpsert("schematic", {
        name: values.name,
        description: values.description,
        backboneSet: {
          name: "Backbone",
          color: "#e0e3e6"
        },
        schematicSets: [
          {
            name: "Prom",
            color: "#ff7fbf",
            type: "right-arrow",
            schematicElements: [{ name: "Weak" }, { name: "Strong" }].map(
              (x, index) => ({ ...x, index })
            )
          },
          {
            name: "Gene",
            color: "#008000",
            type: "right-cds",
            schematicElements: [].map((x, index) => ({ ...x, index }))
          },
          {
            name: "Term",
            color: "#00FFFF",
            type: "right-terminator",
            schematicElements: [].map((x, index) => ({ ...x, index }))
          }
        ].map((x, index) => ({
          ...x,
          index
        }))
      });
      await refetchSchematics();
      hideModal();
      history.push(`/schematics/${id}`);
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating schematic.");
    }
  };

  render() {
    const { hideModal, handleSubmit } = this.props;
    const { submitting } = this.state;
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={() => hideModal()}
        title="Add Schematic"
        style={{ width: 550 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="bp3-dialog-body">
            <InputField name="name" label="Name" />
            <TextareaField name="description" label="Description" />
          </div>
          <DialogFooter hideModal={hideModal} text="OK" loading={submitting} />
        </form>
      </Dialog>
    );
  }
}

const validate = values => {
  const errors = {};
  const requiredProperties = ["name"];

  requiredProperties.forEach(property => {
    if (!values[property]) errors[property] = "Required";
  });

  return errors;
};

export default compose(
  withRouter,
  reduxForm({
    form: "createSchematicDialogForm", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(CreateSchematicDialog);
