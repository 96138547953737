/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isBrowser } from "browser-or-node";
import { bottle } from "../../../tg-iso-lims/src/dependencyContainer";

export async function showAminoAcidStrippedCharacterWarningDialog(aminoAcids) {
  if (isBrowser) {
    // eslint-disable-next-line import/no-restricted-paths
    // const showDuplicateInputSequencesConfirmation = await import(
    //   "../../../client/src-shared/showStrippedAminoAcidsWarningConfirmation"
    // );
    const continueUpload =
      await bottle.container.showStrippedAminoAcidsWarningConfirmation(
        aminoAcids
      );
    return continueUpload;
  } else {
    return true;
  }
}
