/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  pathToIds,
  pathToPrimaryIds,
  pathToSecondaryIds
} from "../pathUtils.js";

// the order matters here
const objectKeysForProcessingToSite = [
  "containerPosition",
  "container",
  "equipmentPosition",
  "equipment",
  "location"
];

/**
 * Given the raw query output from one of the "toSite" fragments, returns a patchy path
 * corresponding to the item. See the inline documentation of `getPatchyPaths` to get a
 * description of what a patchy path is.
 *
 * @param {Object} item The raw query output from one the "toSite" fragments
 * @param {Array<Object>} patchyPath The patchy path that newly found items will be prepended to.
 * @returns {Array<Object>} The patchy path.
 */
export function createPatchyPath(
  item,
  includeSelfInPath = true,
  patchyPath = []
) {
  if (includeSelfInPath) patchyPath = [item, ...patchyPath];
  if (item.site) return patchyPath;
  for (const key of objectKeysForProcessingToSite) {
    if (item[key]) {
      return createPatchyPath(item[key], false, [item[key], ...patchyPath]);
    }
  }
  return patchyPath;
}

/**
 * Given an array of patchy paths, returns a map from type to an array
 * of ids of items of that type that we need to fetch in order to fill
 * out the patchy path.
 *
 * @param {Array<Array<Object>>} patchyPaths
 * @returns {Map<Type, Array<String>>}
 */
export function getIdsToFetchForPath(patchyPaths) {
  const idsToFetchForPath = {
    location: {},
    container: {},
    containerPosition: {}
  };

  for (const patchyPath of patchyPaths) {
    for (const { path, __typename } of patchyPath) {
      for (const id of pathToPrimaryIds(path)) {
        idsToFetchForPath[__typename][id] = true;
      }
      for (const id of pathToSecondaryIds(path)) {
        // Secondary ids will only ever be containerPositions.
        idsToFetchForPath.containerPosition[id] = true;
      }
    }
  }

  // Convert the sets of ids to arrays.
  for (const type of Object.keys(idsToFetchForPath)) {
    idsToFetchForPath[type] = Object.keys(idsToFetchForPath[type]);
  }
  return idsToFetchForPath;
}

/**
 * Given an array of patchy paths and the items needed to fill them, remove
 * the gaps in the patchy paths.
 *
 * @param {Array<Array<Object>>} patchyPaths
 * @param {Map<Type, Array<String>>} fillerItems
 */
export function fillPatchyPaths(patchyPaths, fillerItems) {
  const containerPositions = fillerItems.containerPosition;
  return patchyPaths.map(pp => {
    const path = [];
    for (const item of pp) {
      const itemIds = pathToIds(item.path);
      const itemsMap = fillerItems[item.__typename];

      // `pathToIds` will return empty array for items without a path field, so
      // the next block is skipped for those types of items
      for (const [pId, sId] of itemIds) {
        path.push(itemsMap[pId]);
        if (sId) {
          // Secondary ids will only ever be containerPositions.
          path.push(containerPositions[sId]);
        }
      }

      path.push(item);
    }
    return path;
  });
}

/**
 export * Helper function.
 */
export function itemToName(item, useFullName) {
  if (useFullName) return item.name;
  else return item.label || item.name;
}

/**
 * @param {Array<Array<Object>>} paths
 * @param {Object} options Optional options
 * @param {Boolean} options.useFullName If `true`, this will always use `name`s instead of `labels`s.
 * @param {Boolean} options.objectsAsPath If `true`, this will mean a path will be an array of objects making up the items in the path instead of their names/labels.
 * @param {Boolean} options.includeSelfInPath If `true`, the path will end with the location in the `locationIds` array. Otherwise, it will end at the location immediately above it.
 * @returns {Array<Array<String | Object>>}
 */
export function postprocessPaths(paths, options) {
  const { useFullName, objectsAsPath, includeSelfInPath } = options;
  return paths.map(p => {
    if (p.includes(undefined)) return [];
    if (!includeSelfInPath) {
      p = p.slice(0, -1);
    }
    if (!objectsAsPath) {
      p = p.map(item => itemToName(item, useFullName));
    }
    return p;
  });
}
