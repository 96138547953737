/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import {
  FileUploadField,
  CheckboxField,
  SwitchField,
  SelectField
} from "@teselagen/ui";
import {
  plateLibraryFilter,
  validateNoDryPlatesObject
} from "../../../utils/plateUtils";
import stepFormValues from "../../../../src-shared/stepFormValues";

import { Button } from "@blueprintjs/core";
import gql from "graphql-tag";
import { get, isEmpty } from "lodash";
import { unparse } from "papaparse";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { allowedCsvFileTypes } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { download } from "../../../../src-shared/utils/downloadTest";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { tubeRackBarcodeHeaders } from "../../UploadRackTubeBarcodes";
import { sortAliquotContainers } from "../../../../../tg-iso-lims/src/utils/plateUtils";

export const plateFragment = [
  "containerArray",
  "id name barcode { id barcodeString } containerArrayType { id isPlate } updatedAt"
];

export const tableFragment = ["dataTable", "id name dataTableTypeCode"];

const getCsvHeaders = (updateType, isRack) => {
  let headers;
  if (updateType === "Tube Barcodes") {
    headers = tubeRackBarcodeHeaders;
  } else {
    headers = ["Plate Name", "Plate Barcode"];
    if (isRack) {
      headers.push("Tube Barcode");
    }
    headers.push("Well Location");
    headers.push(updateType);
    let unitPrefix = updateType;
    if (updateType === "Volume") {
      unitPrefix = "Volumetric";
    }
    headers.push(`${unitPrefix} Unit`);
    if (
      updateType === "Concentration" ||
      updateType === "Molarity" ||
      updateType === "Cell Concentration"
    ) {
      headers.push("Volume (optional)", "Volumetric Unit (optional)");
    }
  }

  return headers;
};

const templatePlateFragment = gql`
  fragment updatePlateToolTemplateCSVFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      isPlate
      containerFormat {
        code
        is2DLabeled
        columnCount
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      barcode {
        id
        barcodeString
      }
      aliquot {
        id
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        molarity
        molarityUnitCode
      }
    }
  }
`;

const UploadCsv = ({
  updateType,
  containerArrays = [],
  stepFormProps: { change },
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  selectTable,
  Footer,
  footerProps
}) => {
  const [loadingCurrentPlateValues, setLoadingCurrentPlateValues] = useState(
    false
  );

  const downloadFullCsv = async () => {
    setLoadingCurrentPlateValues(true);
    try {
      const hasRackInSelection = containerArrays.some(
        p => !p.containerArrayType.isPlate
      );
      const csv = [getCsvHeaders(updateType, hasRackInSelection)];
      containerArrays.forEach(plate => {
        sortAliquotContainers(plate.aliquotContainers, "rowFirst").forEach(
          ac => {
            if (updateType === "Tube Barcodes") {
              const row = [];
              row.push(plate.name);
              row.push(get(plate, "barcode.barcodeString"));
              row.push("");
              row.push(
                getAliquotContainerLocation({ ...ac, containerArray: plate })
              );
              row.push(ac.barcode?.barcodeString);
              csv.push(row);
            } else {
              if (ac.aliquot) {
                const row = [];
                row.push(plate.name);
                row.push(get(plate, "barcode.barcodeString"));
                if (hasRackInSelection) {
                  row.push(ac.barcode?.barcodeString);
                }
                row.push(
                  getAliquotContainerLocation({ ...ac, containerArray: plate })
                );
                if (updateType === "Volume") {
                  row.push(ac.aliquot.volume);
                  row.push(ac.aliquot.volumetricUnitCode);
                } else if (updateType === "Concentration") {
                  row.push(ac.aliquot.concentration);
                  row.push(ac.aliquot.concentrationUnitCode);
                  row.push(ac.aliquot.volume);
                  row.push(ac.aliquot.volumetricUnitCode);
                } else if (updateType === "Molarity") {
                  row.push(ac.aliquot.molarity);
                  row.push(ac.aliquot.molarityUnitCode);
                  row.push(ac.aliquot.volume);
                  row.push(ac.aliquot.volumetricUnitCode);
                } else if (updateType === "Mass") {
                  row.push(ac.aliquot.mass);
                  row.push(ac.aliquot.massUnitCode);
                } else if (updateType === "Cell Concentration") {
                  row.push(ac.aliquot.cellConcentration);
                  row.push(ac.aliquot.cellConcentrationUnitCode);
                  row.push(ac.aliquot.volume);
                  row.push(ac.aliquot.volumetricUnitCode);
                }
                csv.push(row);
              }
            }
          }
        );
      });
      const csvString = unparse(csv);
      download(csvString, "updateData.csv", "text/plain");
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading current plates.");
    }

    setLoadingCurrentPlateValues(false);
  };
  const plateErrors = validateNoDryPlatesObject(containerArrays);
  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Plates"
          helper="Select one or more plates to update."
        />
        <div>
          <GenericSelect
            {...{
              name: "containerArrays",
              isRequired: true,
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              isMultiSelect: true,
              schema: [
                {
                  path: "name"
                },
                {
                  displayName: "Barcode",
                  path: "barcode.barcodeString"
                },
                dateModifiedColumn
              ],
              fragment: plateFragment,
              tableParamOptions: {
                additionalFilter: plateLibraryFilter
              },
              additionalDataFragment: templatePlateFragment,
              postSelectDTProps: {
                formName: "updatePlateToolSelectPlates",
                plateErrors,
                schema: [
                  platePreviewColumn({ plateErrors }),
                  "name",
                  {
                    displayName: "Barcode",
                    path: "barcode.barcodeString"
                  },
                  dateModifiedColumn
                ]
              }
            }}
          />
        </div>
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Update Type"
          helper="Select the type of update and upload CSV"
        />
        <div>
          <div style={{ maxWidth: 250 }}>
            <SelectField
              isRequired
              name="updateType"
              label="Update Type"
              generateDefaultValue={
                defaultValueConstants.UPDATE_PLATE_METRIC_TYPE
              }
              onFieldSubmit={val => {
                if (val === "Tube Barcodes") {
                  change("selectTable", false);
                }
              }}
              options={defaultValueConstants.UPDATE_PLATE_METRIC_TYPE.options}
            />
          </div>
          {updateType === "Volume" && (
            <CheckboxField
              label="Recalculate concentration and molarity"
              name="recalculateConcentration"
              defaultValue
            />
          )}
          {updateType && !!containerArrays.length && isEmpty(plateErrors) && (
            <Button
              style={{ marginBottom: 10 }}
              text="Download CSV of Current Plate(s)"
              loading={loadingCurrentPlateValues}
              onClick={downloadFullCsv}
            />
          )}
          {updateType !== "Tube Barcodes" && (
            <SwitchField label="Select Data Table" name="selectTable" />
          )}
          {selectTable ? (
            <GenericSelect
              {...{
                name: "dataTable",
                isRequired: true,
                schema: ["name", dateModifiedColumn],
                buttonProps: {
                  loading: isLoadingMap.dataTable,
                  disabled: isDisabledMap.dataTable
                },
                tableParamOptions: {
                  additionalFilter: {
                    dataTableTypeCode: "UPDATE_PLATES_TOOL"
                  }
                },
                postSelectDTProps: {
                  formName: "updatePlatesToolDataTable",
                  schema: ["name"]
                },
                fragment: tableFragment
              }}
            />
          ) : (
            <FileUploadField
              accept={getDownloadTemplateFileHelpers({
                type: allowedCsvFileTypes,
                fileName: "plateUpdateCsv",
                headers: getCsvHeaders(updateType)
              })}
              fileLimit={1}
              isRequired
              name="updateFile"
            />
          )}
        </div>
      </div>
      <Footer {...footerProps} nextDisabled={!isEmpty(plateErrors)} />
    </div>
  );
};

export default stepFormValues(
  "updateType",
  "containerArrays",
  "selectTable"
)(UploadCsv);
