/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  wrapDialog,
  BlueprintError
} from "@teselagen/ui";

import { groupBy, isNumber } from "lodash";
import {
  calculateMolarityFromConcentration,
  calculateConcentrationFromMolarity,
  molarToNanoMolar,
  defaultMolarityUnitCode,
  defaultConcentrationUnitCode
} from "../../../../tg-iso-lims/src/utils/unitUtils";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";
import { getAliquotMolecularWeight } from "../../../../tg-iso-lims/src/utils/aliquotUtils";
import {
  convertMolarity,
  convertConcentration
} from "../../../src-shared/utils/unitUtils";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";

import {
  allowedCsvFileTypes,
  isZipFile,
  isCsvOrExcelFile,
  extractZipFiles,
  validateCSVRequiredHeaders,
  parseCsvOrExcelFile
} from "../../../../tg-iso-shared/src/utils/fileUtils";

import caseInsensitiveFilter from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getPositionFromAlphanumericLocation } from "../../../../tg-iso-lims/src/utils/plateUtils";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { throwFormError } from "../../../src-shared/utils/formUtils";

const headers = [
  "Plate Name",
  "Plate Barcode",
  "Well Location",
  "Concentration",
  "Concentration Unit",
  "Molarity",
  "Molarity Unit"
];
const requiredHeaders = ["Plate Barcode", "Well Location"];

class UploadPlateConcentration extends Component {
  onSubmit = async values => {
    try {
      const { hideModal } = this.props;
      const { plateConcentrationFile } = values;

      const [
        {
          csv: { data }
        }
      ] = plateConcentrationFile;

      const plateBarcodes = [];
      const plateNames = [];

      for (const [index, row] of data.entries()) {
        let { "Plate Name": plateName, "Plate Barcode": plateBarcode } = row;
        plateName = plateName.trim();
        plateBarcode = plateBarcode.trim();
        if (!plateName && !plateBarcode) {
          throw new Error(
            `Row ${index + 1} did not specify a plate name or barcode.`
          );
        }
        if (plateBarcode) {
          plateBarcodes.push(plateBarcode);
        } else if (plateName) {
          plateNames.push(plateName);
        }
      }

      let barcodePlates = {};
      const aliquotContainerFragment = `
        aliquotContainers {
          id
          rowPosition
          columnPosition
          aliquot {
            id
            isDry
            concentration
            molarityUnitCode
            sample {
              id
              material {
                id
                polynucleotideMaterialSequence {
                  id
                  molecularWeight
                }
                functionalProteinUnit {
                  id
                  molecularWeight
                }
              }
            }
          }
        }
      `;
      if (plateBarcodes.length) {
        barcodePlates = await safeQuery(
          [
            "containerArray",
            `id name barcode { id barcodeString } ${aliquotContainerFragment}`
          ],
          {
            variables: {
              filter: {
                "barcode.barcodeString": plateBarcodes
              }
            }
          }
        );
        barcodePlates = groupBy(barcodePlates, "barcode.barcodeString");
        const duplicatePlateBarcodes = [];
        for (const barcode of Object.keys(barcodePlates)) {
          if (barcodePlates[barcode].length > 1) {
            duplicatePlateBarcodes.push(barcode);
          } else {
            barcodePlates[barcode] = barcodePlates[barcode][0];
          }
        }
        if (duplicatePlateBarcodes.length) {
          throw new Error(
            `Multiple plates were found with these barcodes: ${duplicatePlateBarcodes.join(
              ", "
            )}. Could not determine which to update.`
          );
        }
      }
      let namedPlates = {};
      if (plateNames.length) {
        namedPlates = await safeQuery(
          ["containerArray", `id name ${aliquotContainerFragment}`],
          {
            variables: {
              filter: caseInsensitiveFilter(
                "containerArray",
                "name",
                plateNames
              )
            }
          }
        );
        namedPlates = groupBy(namedPlates, p => p.name.trim().toLowerCase());
        const duplicatePlateNames = [];
        for (const name of Object.keys(namedPlates)) {
          if (namedPlates[name].length > 1) {
            duplicatePlateNames.push(name);
          } else {
            namedPlates[name] = namedPlates[name][0];
          }
        }
        if (duplicatePlateNames.length) {
          throw new Error(
            `Multiple plates were found with these names: ${duplicatePlateNames.join(
              ", "
            )}. Could not determine which to update.`
          );
        }
      }

      const aliquotUpdates = [];
      for (const [index, row] of data.entries()) {
        let {
          "Plate Name": plateName,
          "Plate Barcode": plateBarcode,
          "Well Location": location,
          Concentration: concentration,
          "Concentration Unit": concentrationUnitCode,
          Molarity: molarity,
          "Molarity Unit": molarityUnitCode
        } = row;
        plateName = plateName.trim().toLowerCase();
        plateBarcode = plateBarcode.trim();
        const plateToUse = plateBarcode
          ? barcodePlates[plateBarcode]
          : namedPlates[plateName.trim().toLowerCase()];

        if (plateBarcode && !plateToUse) {
          throw new Error(
            `Row ${index +
              1} specifies the barcode ${plateBarcode} which does not exist in inventory.`
          );
        }
        if (!plateBarcode && !plateToUse) {
          throw new Error(
            `Row ${index +
              1} specifies the name ${plateName} which does not exist in inventory.`
          );
        }
        const {
          rowPosition,
          columnPosition
        } = getPositionFromAlphanumericLocation(location);
        const aliquotContainer = plateToUse.aliquotContainers.find(ac => {
          return (
            ac.rowPosition === rowPosition &&
            ac.columnPosition === columnPosition
          );
        });
        const aliquot = aliquotContainer && aliquotContainer.aliquot;
        if (!aliquot) {
          throw new Error(
            `No aliquot found at the location specified in row ${index + 1}.`
          );
        }
        if (aliquot.concentration) {
          throw new Error(
            `The aliquot found at the location specified in row ${index +
              1} already has a concentration.`
          );
        }
        if (aliquot.isDry) {
          throw new Error(
            `The aliquot found at the location specified in row ${index +
              1} is dry. Please rehydrate.`
          );
        }
        const newConcentration = concentration && parseFloat(concentration, 10);
        const newMolarity = molarity && parseFloat(molarity, 10);
        if (!isNumber(newMolarity) && !isNumber(newConcentration)) {
          throw new Error(
            `Row ${index +
              1} did not specify a valid number for concentration or a molarity.`
          );
        }

        if (
          isNumber(newConcentration) &&
          !unitGlobals.concentrationUnits[concentrationUnitCode]
        ) {
          throw new Error(
            `The concentration unit (${concentrationUnitCode}) provided in row ${index +
              1} is not valid.`
          );
        }
        if (
          isNumber(newMolarity) &&
          !unitGlobals.molarityUnits[molarityUnitCode]
        ) {
          throw new Error(
            `The molarity unit (${molarityUnitCode}) provided in row ${index +
              1} is not valid.`
          );
        }
        let molarityFields = {};
        let concentrationFields = {};
        const molecularWeight = getAliquotMolecularWeight(aliquot);
        if (isNumber(newConcentration)) {
          concentrationFields = {
            concentration: newConcentration,
            concentrationUnitCode
          };
          if (molecularWeight) {
            molarityFields.molarity =
              calculateMolarityFromConcentration(
                newConcentration,
                concentrationUnitCode,
                molecularWeight
              ) * molarToNanoMolar;
            molarityFields.molarityUnitCode =
              molarityUnitCode ||
              aliquot.molarityUnitCode ||
              defaultMolarityUnitCode;
            if (molarityFields.molarityUnitCode !== defaultMolarityUnitCode) {
              molarityFields.molarity = convertMolarity(
                molarityFields.molarity,
                defaultMolarityUnitCode,
                molarityFields.molarityUnitCode
              );
            }
          }
        } else {
          molarityFields = {
            molarity: newMolarity,
            molarityUnitCode
          };
          if (molecularWeight) {
            concentrationFields.concentration = calculateConcentrationFromMolarity(
              molarity,
              molarityUnitCode,
              molecularWeight
            );
            concentrationFields.concentrationUnitCode =
              concentrationUnitCode ||
              aliquot.concentrationUnitCode ||
              defaultConcentrationUnitCode;
            if (concentrationFields.concentrationUnitCode !== "g/L") {
              concentrationFields.concentration = convertConcentration(
                concentrationFields.concentration,
                "g/L",
                concentrationFields.concentrationUnitCode
              );
            }
          }
        }
        aliquotUpdates.push({
          id: aliquot.id,
          ...concentrationFields,
          ...molarityFields
        });
      }
      await safeUpsert(
        ["aliquot", "id concentration concentrationUnitCode"],
        aliquotUpdates
      );
      window.toastr.success("Aliquots Updated!");
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error uploading concentrations");
    }
  };

  readPlateCsv = async (files, onChange) => {
    const file = files[0];
    if (!file) return false;
    if (isZipFile(file)) {
      const unzippedFiles = await extractZipFiles(file);
      const csvFiles = unzippedFiles.filter(isCsvOrExcelFile);

      if (!csvFiles.length) {
        onChange([]);
        return window.toastr.error("No CSV file found inside zip.");
      }
      try {
        const getParsedCsvFile = async csvFile => {
          const parsedCsv = await parseCsvOrExcelFile(csvFile);
          if (parsedCsv.error) {
            return window.toastr.error(parsedCsv.error);
          }
          const newFile = {
            ...parsedCsv.originalFile,
            csv: parsedCsv,
            loading: false
          };
          return newFile;
        };

        const newFile = await getParsedCsvFile(csvFiles[0]);

        onChange([newFile]);
        return false;
      } catch (error) {
        console.error("error:", error);
        return window.toastr.error(
          error.message || "Error parsing CSV file in zip."
        );
      }
    } else {
      try {
        const parsedCsv = await parseCsvOrExcelFile(file);
        if (parsedCsv.error) {
          return window.toastr.error(parsedCsv.error);
        }
        const newFile = {
          ...parsedCsv.originalFile,
          csv: parsedCsv,
          loading: false
        };
        onChange([newFile]);
        return false;
      } catch (error) {
        console.error("error:", error);
        window.toastr.error(error.message || "Error parsing CSV file in zip.");
        return false;
      }
    }
  };
  render() {
    const { hideModal, handleSubmit, submitting, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            fileLimit={1}
            isRequired
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes.concat([".zip"]),
              fileName: "plate_concentration",
              headers,
              requiredHeaders,
              headerMessages: {
                Concentration: "Required if not providing molarity.",
                "Concentration Unit": "Required if concentration is provided.",
                Molarity: "Required if not providing concentration.",
                "Molarity Unit": "Required if molarity is provided."
              }
            })}
            name="plateConcentrationFile"
            beforeUpload={this.readPlateCsv}
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export const validateFile = values => {
  const errors = {};
  if (values.plateConcentrationFile && values.plateConcentrationFile.length) {
    const [{ csv }] = values.plateConcentrationFile;
    if (!csv) errors.plateConcentrationFile = "No CSV file found.";
    else {
      const {
        meta: { fields }
      } = csv;

      errors.plateConcentrationFile = validateCSVRequiredHeaders(
        fields,
        requiredHeaders
      );
    }
  }
  return errors;
};

export default compose(
  wrapDialog({
    title: "Upload Plate Concentrations"
  }),
  reduxForm({
    form: "uploadPlateConcentration",
    validate: validateFile
  })
)(UploadPlateConcentration);
