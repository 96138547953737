/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import importDesignSimpleJson from "../../../../../tg-iso-design/designImport/importDesignSimpleJson";
import StepForm from "../../../../src-shared/StepForm";
import SelectParts from "./SelectParts";
import "./style.css";
import { getSimpleDesign } from "./utils";

const DesignFromDigestPartsTool = props => {
  const {
    toolIntegrationProps,
    toolSchema,
    isToolIntegrated,
    initialValues = {}
  } = props;

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async values => {
    setSubmitting(true);

    const {
      designName,
      designDescription,
      designLayout,
      selectedParts,
      selectedRestrictionEnzyme
    } = values;

    const simpleDesignJson = getSimpleDesign({ parts: selectedParts });

    const { designId } = await importDesignSimpleJson({
      json: {
        name: designName,
        description: designDescription,
        layout_type: designLayout,
        restrictionEnzymeName: selectedRestrictionEnzyme.name,
        ...simpleDesignJson
      }
    });

    setSubmitting(false);
    return { design: { id: designId } };
  };

  const steps = [
    {
      title: "Select Parts",
      Component: SelectParts,
      withCustomFooter: true,
      props: { submitting }
    }
  ];

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default DesignFromDigestPartsTool;
