/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * Helper function to change the design layout type. The changes will be slated
 * using the changeSetsHelper but not actually executed.
 */
export default function changeDesignLayoutType(
  changeSetsHelper,
  designState,
  layoutType
) {
  Object.keys(designState.card).forEach(id =>
    changeSetsHelper.updatePure("card", {
      id,
      allConstructsAvailable: false,
      lastCheckedAvailability: null,
      hasAvailabilityInfo: false
    })
  );
  if (layoutType === "list") {
    changeSetsHelper.deletePure(
      "eugeneRule",
      Object.keys(designState.eugeneRule)
    );
  }
}
