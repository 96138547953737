/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import { compose, withProps } from "recompose";
import { FieldArray, formValues, reduxForm } from "redux-form";
import { Callout, Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog, NumericInputField } from "@teselagen/ui";
import immer from "immer";
import AddButton from "../../../../../../src-shared/FieldArrayButtons/AddButton";
import RemoveButton from "../../../../../../src-shared/FieldArrayButtons/RemoveButton";
import FillDirectionSelect from "../../../../FillDirectionSelect";
import isValidPositiveInteger from "../../../../../../../tg-iso-shared/src/utils/isValidPositiveInteger";

function ChooseDistributeMaterialsByLengthOptions(props) {
  const {
    hideModal,
    handleSubmit,
    submitting,
    ranges,
    change,
    handleDistribute
  } = props;

  function onSubmit(values) {
    handleDistribute(values);
    hideModal();
  }

  useEffect(() => {
    if (ranges?.length > 1) {
      let needsChange = false;
      const newRanges = immer(ranges, newRanges => {
        newRanges.forEach((range, i) => {
          if (i > 0) {
            const prevRange = newRanges[i - 1];
            if (prevRange.max) {
              needsChange = true;
              range.min = Number(prevRange.max) + 1;
            }
          }
        });
      });
      if (needsChange) {
        change("ranges", newRanges);
      }
    }
  }, [ranges, change]);

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary" style={{ marginBottom: 10 }}>
          Please specify ranges for sequence size, each range will correspond to
          a different plate map during distribution.
        </Callout>
        <Callout intent="warning" style={{ marginBottom: 10 }}>
          This will override current plate maps.
        </Callout>
        <hr className="tg-section-break" />
        <FieldArray name="ranges" component={renderRanges} />
        <div style={{ marginTop: 15, maxWidth: 250 }}>
          <FillDirectionSelect />
        </div>
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

function renderRanges({ fields }) {
  return (
    <div>
      {fields.map((field, i) => {
        return (
          <div key={i} style={{ display: "flex", alignItems: "center" }}>
            <NumericInputField
              tooltipInfo="Min can not be changed"
              name={`${field}.min`}
              label="Min"
              defaultValue={0}
              disabled
            />
            <div style={{ width: 7 }} />
            <NumericInputField name={`${field}.max`} label="Max" />
            {i !== 0 && i === fields.length - 1 && (
              <RemoveButton
                fields={fields}
                index={i}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        );
      })}
      <AddButton noMargin fields={fields} text="Add Range" />
    </div>
  );
}

export default compose(
  wrapDialog({
    title: "Distribute Thresholds"
  }),
  withProps(() => {
    const initialValues = {
      ranges: [
        {
          min: 0
        }
      ]
    };
    const savedRanges = window.localStorage.getItem(
      "distributeCreatePlateMapRanges"
    );
    if (savedRanges) {
      try {
        const parsedRanges = JSON.parse(savedRanges);
        if (parsedRanges.length) {
          initialValues.ranges = parsedRanges;
        }
      } catch (error) {
        console.error(`error:`, error);
      }
    }
    return {
      initialValues
    };
  }),
  reduxForm({
    form: "chooseDistributeOptions",
    validate: values => {
      const errors = {
        ranges: []
      };
      if (values.ranges) {
        values.ranges.forEach((range, i) => {
          if (!isValidPositiveInteger(range.max)) {
            errors.ranges[i] = {
              max: "Please enter valid integer"
            };
          } else if (range.max <= range.min) {
            errors.ranges[i] = {
              max: "Max must be greater than min"
            };
          }
        });
      }
      return errors;
    }
  }),
  formValues("ranges")
)(ChooseDistributeMaterialsByLengthOptions);
