/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon, Tooltip } from "@blueprintjs/core";
import {
  aliquotHasVolumeToTransfer,
  aliquotVolIsLessThanDeadTooltip
} from "../utils/plateUtils";
import { volumeRender } from "../../src-shared/utils/unitUtils";

export const aliquotVolumeDeadWarningRender = (volume, record) => {
  let toRet = volumeRender(volume, record);
  if (
    !record.isDry &&
    record.volume > 0 &&
    !aliquotHasVolumeToTransfer(record)
  ) {
    toRet = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <AliquotVolumeLessThanDeadWarning />
        {toRet}
      </div>
    );
  }
  return toRet;
};

export default function AliquotVolumeLessThanDeadWarning() {
  return (
    <Tooltip content={aliquotVolIsLessThanDeadTooltip}>
      <Icon style={{ marginRight: 5 }} icon="warning-sign" intent="warning" />
    </Tooltip>
  );
}
