/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { Intent, Callout } from "@blueprintjs/core";
import { CheckboxField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { validateMaterialPlates } from "../../../utils/plateUtils";

import stepFormValues from "../../../../src-shared/stepFormValues";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import {
  recordExtendedValuesString,
  getExistingExtendedProperties
} from "../../../../src-shared/utils/extendedPropertyUtils";
import { isEmpty } from "lodash";

export const containerArrayFragmentWithExtendedProperties = [
  "containerArray",
  `id
  name
  ${recordExtendedValuesString}
  containerArrayType {
    id
    name
  }
  aliquotContainers {
    id
    rowPosition
    columnPosition
    aliquot {
      id
      isDry
      ${recordExtendedValuesString}
      sample {
        id
        material {
          id
          name
          materialTypeCode
        }
      }
    }
  }`
];

class SelectPlates extends Component {
  getExistingProperties = async () => {
    const {
      containerArrays = [],
      discardSupernatant,
      containerArrayExtendedValues = [],
      aliquotExtendedValues = [],
      pelletProperty,
      aspiratedProperty,
      stepFormProps: { change },
      nextStep
    } = this.props;

    const pelletValue = {
      value: true,
      extendedProperty: pelletProperty,
      __typename: "extendedValue"
    };

    const aspiratedValue = {
      value: true,
      extendedProperty: aspiratedProperty,
      __typename: "extendedValue"
    };

    let newContainerArrayValues = [],
      newAliquotValues = [];

    if (discardSupernatant) {
      if (
        !containerArrayExtendedValues.some(
          v => v.extendedProperty.id === aspiratedValue.extendedProperty.id
        )
      ) {
        newContainerArrayValues = [
          ...containerArrayExtendedValues,
          aspiratedValue
        ];
      }
    }

    if (
      !aliquotExtendedValues.some(
        v => v.extendedProperty.id === pelletValue.extendedProperty.id
      )
    ) {
      newAliquotValues = [...aliquotExtendedValues, pelletValue];
    } else if (pelletProperty) {
      newAliquotValues.push(pelletValue);
    }

    const existingExtendedProperties = getExistingExtendedProperties(
      containerArrays
    );

    change("aliquotExtendedValues", newAliquotValues);
    change("containerArrayExtendedValues", newContainerArrayValues);
    change("existingExtendedProperties", existingExtendedProperties);
    nextStep();
  };

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      pelletProperty,
      aspiratedProperty,
      Footer,
      footerProps,
      handleSubmit,
      containerArrays = []
    } = this.props;

    const plateErrors = validateMaterialPlates(containerArrays, {
      materialTypeCode: "MICROBIAL",
      asObject: true
    });

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates"
            helper="Select one or more plates of microbial
              materials. Check the box below if you would like to
              discard the supernatant. This determines whether
              the 'Aspirated' extended property is applied to the
              plate."
            width="100%"
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              isRequired: true,
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              isMultiSelect: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              fragment: [
                "containerArray",
                "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: containerArrayFragmentWithExtendedProperties,
              postSelectDTProps: {
                formName: "lysisSelectedPlates",
                plateErrors,
                schema: [
                  platePreviewColumn({
                    plateErrors
                  }),
                  "name",
                  {
                    path: "containerArrayType.name",
                    displayName: "Plate Type"
                  }
                ]
              }
            }}
          />
          <CheckboxField
            label="Discard Supernatant?"
            name="discardSupernatant"
            defaultValue
          />
        </div>
        {!aspiratedProperty && (
          <Callout intent={Intent.DANGER} style={{ marginBottom: 10 }}>
            Could not find plate extended property with name "Aspirated" of type
            "flag". Please add one in App Settings.
          </Callout>
        )}
        {!pelletProperty && (
          <Callout intent={Intent.DANGER}>
            Could not find aliquot extended property with name "Pellet" of type
            "flag". Please add one in App Settings.
          </Callout>
        )}
        <Footer
          {...footerProps}
          nextDisabled={
            !aspiratedProperty || !pelletProperty || !isEmpty(plateErrors)
          }
          errorMessage={!isEmpty(plateErrors) && "Please review plate errors"}
          onNextClick={handleSubmit(this.getExistingProperties)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "containerArrays",
    "containerArrayExtendedValues",
    "aliquotExtendedValues",
    "discardSupernatant"
  )
)(SelectPlates);
