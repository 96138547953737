/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Field } from "redux-form";

function InnerComp({ input: { value } }) {
  return value;
}

function DisplayField({ name }) {
  return <Field name={name} component={InnerComp} />;
}

export default DisplayField;
