/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const proteinPurificationPlanningMaterialFragment = gql`
  fragment proteinPurificationPlanningMaterialFragment on material {
    id
    name
    materialTypeCode
    polynucleotideMaterialMaterialFpus {
      id
      functionalProteinUnit {
        id
        proteinMaterial {
          id
          name
        }
      }
    }
    polynucleotideMaterialSequence {
      id
    }
    microbialMaterialMicrobialMaterialPlasmids {
      id
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
        }
        polynucleotideMaterialMaterialFpus {
          id
          functionalProteinUnit {
            id
            proteinMaterial {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const purificationPlanningSampleFragment = gql`
  fragment purificationPlanningSampleFragment on sample {
    id
    name
    sampleTypeCode
    sampleFormulations {
      id
      materialCompositions {
        id
        material {
          ...proteinPurificationPlanningMaterialFragment
        }
      }
    }
    material {
      ...proteinPurificationPlanningMaterialFragment
    }
  }
  ${proteinPurificationPlanningMaterialFragment}
`;

export const purificationPlanningPlateMapGroupFragment = gql`
  fragment purificationPlanningPlateMapGroupFragment on plateMapGroup {
    id
    name
    plateMaps {
      id
      type
      name
      plateMapItems {
        id
        name
        rowPosition
        columnPosition
        volume
        volumetricUnitCode
        inventoryItem {
          id
          material {
            ...proteinPurificationPlanningMaterialFragment
          }
          sample {
            ...purificationPlanningSampleFragment
          }
        }
      }
    }
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
  ${proteinPurificationPlanningMaterialFragment}
  ${purificationPlanningSampleFragment}
`;

const proteinPurificationPlanningAliquotFragment = gql`
  fragment proteinPurificationPlanningAliquotFragment on aliquot {
    id
    isDry
    sample {
      ...purificationPlanningSampleFragment
    }
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
  }
  ${proteinPurificationPlanningMaterialFragment}
  ${purificationPlanningSampleFragment}
`;

export const purificationPlanningTubeFragment = gql`
  fragment purificationPlanningTubeFragment on aliquotContainer {
    id
    name
    columnPosition
    rowPosition
    barcode {
      id
      barcodeString
    }
    aliquotContainerType {
      code
      name
      isTube
    }
    aliquot {
      ...proteinPurificationPlanningAliquotFragment
    }
  }
  ${proteinPurificationPlanningAliquotFragment}
`;

export const purificationPlanningPlateFragment = gql`
  fragment purificationPlanningPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      isPlate
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      name
      columnPosition
      rowPosition
      aliquot {
        ...proteinPurificationPlanningAliquotFragment
      }
    }
  }
  ${proteinPurificationPlanningAliquotFragment}
`;
