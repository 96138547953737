/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment plateMapGroupRunPcrFragment on plateMapGroup {
    id
    name
    createdAt
    updatedAt
    plateMaps {
      id
      name
      metadata
      plateMapItems {
        id
        rowPosition
        columnPosition
        j5Item {
          id
          j5PcrReaction {
            id
            pcrProductSequence {
              id
              name
              polynucleotideMaterial {
                id
                name
              }
            }
            primaryTemplate {
              id
              name
              polynucleotideMaterial {
                id
                name
              }
            }
            reversePrimer {
              id
              name
              sequence {
                id
                name
                polynucleotideMaterial {
                  id
                  name
                }
              }
            }
            forwardPrimer {
              id
              name
              sequence {
                id
                name
                polynucleotideMaterial {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      is2DLabeled
    }
  }
`;
