/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

const aliquotHydrationFragment = gql`
  fragment aliquotHydrationFragment on aliquot {
    id
    aliquotType
    isDry
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    molarity
    molarityUnitCode
    cellCount
    cellConcentration
    cellConcentrationUnitCode
    additives {
      id
      lot {
        id
        additiveMaterial {
          id
          evaporable
        }
      }
      additiveMaterial {
        id
        evaporable
      }
    }
    sample {
      id
      name
      sampleTypeCode
      sampleStatusCode
      material {
        id
        functionalProteinUnit {
          id
          molecularWeight
        }
        polynucleotideMaterialSequence {
          id
          molecularWeight
        }
      }
    }
  }
`;

export default aliquotHydrationFragment;
