/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import restrictionEnzymeFragment from "../../../tg-iso-shared/src/fragments/restrictionEnzymeFragment";

export const digestPartFragment = gql`
  fragment digestPartFragment on part {
    id
    isDigestPart
    isDigestValid
    re5PrimeId
    re5Prime {
      ...restrictionEnzymeFragment
    }
    re5PrimeOverhang
    re5PrimeOverhangStrand
    re5PrimeRecognitionTypeCode
    re3PrimeId
    re3Prime {
      ...restrictionEnzymeFragment
    }
    re3PrimeOverhang
    re3PrimeOverhangStrand
    re3PrimeRecognitionTypeCode
  }
  ${restrictionEnzymeFragment}
`;

export const partFragment = gql`
  fragment partFragment on part {
    id
    name
    start
    end
    strand
    size
    sequenceId
    ...digestPartFragment
  }
  ${digestPartFragment}
`;
