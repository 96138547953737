/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import {
  Icon,
  Colors,
  Classes,
  Tooltip,
  Callout,
  Intent,
  Divider,
  Position
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { inject, observer } from "mobx-react";
import {
  statusConstants,
  EvolveConfig,
  tooltips
} from "../../../../../configs/config.js";
import { parseErrorToJson } from "../../../../../../src-shared/utils/parseErrorToJson";
import { get } from "lodash";
import LabelWithTooltip from "../../../../../../src-shared/LabelWithTooltip";
import modelSummaryWidgetStyles from "./ModelSummaryWidgetStyles.module.css";

const UNKNOWN_ERROR_OBJECT = {
  message:
    "Encountered an unkown error while training the model. Please try and train your model again and if it keeps throwing an error, please contact TeselaGen's team. Sorry for any inconvenience that this could cause."
};

const NUMERIC_PRECISION = 1000;
const MAX_MSG_LENGTH = 35;

class ModelSummaryWidget extends Component {
  renderStatusPane = statusPaneProps => {
    const {
      isLoading,
      statusCode,
      startTime,
      lastCheckIn,
      warnings = [
        // {
        //   message: "R2 score is very low (-0.806 ± 1.421)",
        //   metric_name: "r2",
        //   set_name: "test"
        // },
        // {
        //   message: "R2 score is very low (-0.806 ± 1.421)",
        //   metric_name: "r2",
        //   set_name: "test"
        // },
        // {
        //   message: "R2 score is very low (-0.806 ± 1.421)",
        //   metric_name: "r2",
        //   set_name: "test"
        // },
        // {
        //   message: "R2 score is very low (-0.806 ± 1.421)",
        //   metric_name: "r2",
        //   set_name: "test"
        // }
      ]
    } = statusPaneProps;

    if (isLoading) {
      return (
        <div className={modelSummaryWidgetStyles.statusPane}>
          <Icon
            style={{ marginLeft: "20px" }}
            className={Classes.SKELETON}
            icon={IconNames.BLANK}
            iconSize={40}
          />
          <p style={{ marginLeft: "20px" }} className={Classes.SKELETON}>
            Model is loading. Please wait.
          </p>
        </div>
      );
    } else {
      const status = statusConstants[statusCode];
      const statusIntent = statusConstants[statusCode];
      const isCompleted = statusCode === "completed-successfully";

      let completionTimeText = "";

      if (isCompleted) {
        const elapsedMilliseconds =
          new Date(lastCheckIn).getTime() - new Date(startTime).getTime();

        let completionTime = Math.round(elapsedMilliseconds / 1000);
        let timeUnits = "seconds";

        if (elapsedMilliseconds >= 60000) {
          completionTime = Math.round((elapsedMilliseconds / 60000) * 10) / 10;
          timeUnits = "minutes";
        }

        completionTimeText = (
          <span>
            in {completionTime < 0 ? 0 : completionTime} {timeUnits}
          </span>
        );
      }

      return (
        <div className={modelSummaryWidgetStyles.statusPane}>
          <div>
            <Icon
              icon={status.icon}
              iconSize={30}
              intent={statusIntent.intent}
            />
            <p>
              {status.description} {completionTimeText}
            </p>
          </div>
          {warnings &&
            warnings.map((warning, idx) => (
              <div key={`warning-${idx}`}>
                <Tooltip
                  position={Position.LEFT}
                  // TODO: figure out how to match warnings to tooltips.
                  // For the moment only a single type of warning is being returned by AIWORKER
                  // which is R2 score too low. In the future multiple warning could be returned
                  // and will probably include a code we can use to to the matching with the proper tooltip

                  // Also, in case the warning message exceeds 35 characters (which impoverishes the UI)
                  // it will be sliced and the full message will be preappended into its tooltip.
                  {...(!warning.code && {
                    className: Classes.TOOLTIP_INDICATOR,
                    content:
                      warning.message.length > MAX_MSG_LENGTH ? (
                        <span>
                          <span style={{ fontStyle: "italic" }}>
                            {warning.message}
                          </span>
                          <br />
                          <br />
                          {tooltips.cvR2LowWarning}
                        </span>
                      ) : (
                        tooltips.cvR2LowWarning
                      )
                  })}
                >
                  <Icon icon="warning-sign" iconSize={30} intent="warning" />
                </Tooltip>
                <p>{`${warning.message.slice(0, MAX_MSG_LENGTH)}...`}</p>
              </div>
            ))}
        </div>
      );
    }
  };

  renderPredictionAndEvolutionStatisticsPane = statisticsPaneProps => {
    const {
      mainStore: { helper }
    } = this.props;

    const {
      modelStats: {
        crossvalidation: crossValidation,
        training_results: trainingResults,
        design_space_complexity
      }
    } = statisticsPaneProps;

    const crossValTestR2 = get(crossValidation, "test.r2.mean");
    const crossValTestMae =
      get(crossValidation, "test.mae.mean") || get(crossValidation, "test.mae");

    // The summary card will have a single CV score. The preferred score
    // has been elected to be the R2 Score. However this score does not come in previous versions
    // of the AIWORKER. So to make it retrocompatible, we will show the MAE score in case R2 isnt available.
    // TODO: deprecate this when the new versions of the AIWORKER gets fully deployed.
    const crossValTestScore = crossValTestR2 || crossValTestMae;
    const crossValTestScoreLabel = crossValTestR2 ? (
      <span>
        Cross-validation R<sup>2</sup> score
      </span>
    ) : (
      "Cross-validation MAE"
    );
    const crossValTestScoreToolTip = crossValTestR2
      ? tooltips.cvTestR2
      : tooltips.cvTestMae;

    return (
      <div className={modelSummaryWidgetStyles.summaryMetricsPane}>
        <ul>
          {design_space_complexity && (
            <li>
              <LabelWithTooltip
                label="Design space complexity"
                tooltip={tooltips.designComplexity}
                onClick={() => helper.open("designComplexity")}
              />
              <Tooltip
                className={Classes.TOOLTIP_INDICATOR}
                content={design_space_complexity}
              >
                <span style={{ color: Colors.GRAY3 }}>
                  {Math.round(design_space_complexity * NUMERIC_PRECISION) /
                    NUMERIC_PRECISION}
                </span>
              </Tooltip>
            </li>
          )}
          {crossValTestScore && (
            <li>
              <LabelWithTooltip
                label={crossValTestScoreLabel}
                tooltip={crossValTestScoreToolTip}
                onClick={() => helper.open("crossvalidation")}
              />
              <Tooltip
                className={Classes.TOOLTIP_INDICATOR}
                content={crossValTestScore}
              >
                <span style={{ color: Colors.GRAY3 }}>
                  {Math.round(crossValTestScore * NUMERIC_PRECISION) /
                    NUMERIC_PRECISION}
                </span>
              </Tooltip>
            </li>
          )}
          {trainingResults.r2 && (
            <li>
              <LabelWithTooltip
                label={
                  <span>
                    Training R<sup>2</sup> score
                  </span>
                }
                tooltip={tooltips.trainingR2Score}
                onClick={() => helper.open("statisticalMetrics")}
              />
              <Tooltip
                className={Classes.TOOLTIP_INDICATOR}
                content={trainingResults.r2}
              >
                <span style={{ color: Colors.GRAY3 }}>
                  {Math.round(trainingResults.r2 * NUMERIC_PRECISION) /
                    NUMERIC_PRECISION}
                </span>
              </Tooltip>
            </li>
          )}
        </ul>
      </div>
    );
  };

  renderGenerativeModelStatisticsPane = statisticsPaneProps => {
    const { isLoading, modelStats } = statisticsPaneProps;

    if (isLoading) {
      return (
        <div className={modelSummaryWidgetStyles.summaryMetricsPane}>
          <h5>Statistics</h5>
          <ul>
            <li className={Classes.SKELETON}>
              Number of itereations:{" "}
              <span style={{ color: Colors.GRAY3 }}>...loading</span>
            </li>
            <li className={Classes.SKELETON}>
              Number of sequences generated:{" "}
              <span style={{ color: Colors.GRAY3 }}>...loading</span>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className={modelSummaryWidgetStyles.summaryMetricsPane}>
          <h5>Statistics</h5>
          <ul>
            <li>
              Number of iterations:{" "}
              <span style={{ color: Colors.GRAY3 }}>
                {modelStats.number_of_iterations}
              </span>
            </li>
            <li>
              Number of sequences generated:{" "}
              <span style={{ color: Colors.GRAY3 }}>
                {modelStats.total_number_of_sequences}
              </span>
            </li>
          </ul>
        </div>
      );
    }
  };

  renderErrorPane = error => {
    let errorJson = error;

    if (error.constructor === "string".constructor) {
      try {
        errorJson = parseErrorToJson(error);
      } catch (error) {
        errorJson = UNKNOWN_ERROR_OBJECT;
      }
    }

    return (
      <div className={modelSummaryWidgetStyles.errorPane}>
        <Divider className={modelSummaryWidgetStyles.divider} />
        <Callout intent={Intent.DANGER} icon={null}>
          {errorJson.message}
        </Callout>
      </div>
    );
  };

  render() {
    const {
      evolveModelSummary: { modelStats, statusCode, startTime, lastCheckIn },
      error,
      modelType
    } = this.props;
    const isLoading = !statusCode;

    const doRenderStatistics =
      !error && statusCode === "completed-successfully";

    const renderStatistics =
      modelType === EvolveConfig.constants.GENERATIVE_MODEL
        ? this.renderGenerativeModelStatisticsPane
        : this.renderPredictionAndEvolutionStatisticsPane;

    return (
      <div>
        {this.renderStatusPane({
          isLoading,
          statusCode,
          startTime,
          lastCheckIn,
          warnings: modelStats.crossvalidation_warnings
        })}
        {doRenderStatistics && renderStatistics({ isLoading, modelStats })}
        {error && this.renderErrorPane(error)}
      </div>
    );
  }
}

export default inject("mainStore")(observer(ModelSummaryWidget));
