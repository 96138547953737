/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniq, startsWith } from "lodash";
import {
  getSequenceStringsOfElement,
  getNeighborBinId,
  getElementsInBin,
  getOutputCardIdOfReaction,
  getItemOfType,
  getSequenceStringOfSequence
} from "../../selectors/designStateSelectors";
import { ChangeSetsHelper } from ".";
import {} from "../../selectors/designStateSelectors";
import { getSequenceIdOfElement } from "../../redux/sagas/submitDesignForAssembly/createCardSequenceMap/isElementCombinationContiguousOnTheSameSequence";

/**
 * Given the design state, see if any 'Assembly Ready Fragment' fas parts
 *  are invalid
 *
 * This function does not mutate its arguments.
 *
 * @param {Object} designState
 * @returns {Object}
 */
export default function validateArfFas(designState) {
  try {
    // 'Assembly Ready Fragment' FAS elements/parts are invalid if...
    //   1.  Not in a Gibson/SLIC/CPEC reaction
    //   2.  Input sequence isn't linear
    //   3.  Element is not a 'normal' element that links to a single part
    //   4.  The containing bin and previous bin do not have the appropriate FROs and extraCpecBps
    //   5.  The ends of the input sequence do not exactly match the junction bps (exact match for now)

    //  ...6.  Shouldn't be present in adjacent bins? (kc_todo this is impossible but might not be super clear to user)

    const allAssemblyReadyFragmentFas = {};
    const validElementsToFasMap = {};
    const elementIdToInvalidityMessages = {};
    const state = { design: designState };
    const changeSetsHelper = new ChangeSetsHelper(designState);

    Object.values(designState.fas).forEach(fas => {
      if (!fas.elementId || !designState.element[fas.elementId]) return;
      elementIdToInvalidityMessages[fas.elementId] =
        elementIdToInvalidityMessages[fas.elementId] || [];

      if (fas.name === "Assembly Ready Fragment") {
        let reaction = getItemOfType(
          { design: designState },
          "reaction",
          fas.reactionId
        );
        let assemblyMethod = getItemOfType(
          { design: designState },
          "assemblyMethod",
          reaction.assemblyMethodId
        );
        if (assemblyMethod.name !== "Gibson/SLIC/CPEC") {
          // 1.  invalid if not in a Gibson/SLIC/CPEC reaction
          elementIdToInvalidityMessages[fas.elementId].push(
            `ARFFAS: 'Assembly Ready Fragment' FAS can only be used on parts in a Gibson/SLIC/CPEC reaction`
          );
        } else {
          let partId = designState.element[fas.elementId].partId;
          if (!partId) {
            // 3.  invalid if element is not a 'normal' element that links to a single part
            elementIdToInvalidityMessages[fas.elementId].push(
              `ARFFAS: 'Assembly Ready Fragment' FAS can only be used on elements that link to a single part`
            );
          } else if (
            designState.sequence[designState.part[partId].sequenceId].circular
          ) {
            // 2.  invalid if input sequence isn't linear
            elementIdToInvalidityMessages[fas.elementId].push(
              `ARFFAS: 'Assembly Ready Fragment' FAS can only be used on parts on a linear source sequence`
            );
          } else {
            allAssemblyReadyFragmentFas[fas.id] = fas;
            validElementsToFasMap[fas.elementId] = fas.id;
          }
        }
      }
    });

    // 4.  The containing bin and previous bin do not have the appropriate FROs and extraCpecBps
    Object.entries(validElementsToFasMap).forEach(([elementId, fasId]) => {
      try {
        const fas = getItemOfType(state, "fas", fasId);
        const reaction = getItemOfType(state, "reaction", fas.reactionId);
        const customJ5Parameter = getItemOfType(
          state,
          "customJ5Parameter",
          reaction.customJ5ParameterId
        );
        const junctionLength = customJ5Parameter.gibsonOverlapBps;
        const junctionLengthIsOdd = junctionLength % 2 === 1;

        const element = getItemOfType(state, "element", elementId);
        const part = getItemOfType(state, "part", element.partId);
        const sequenceId = getSequenceIdOfElement(state, element);
        const sequenceStr = getSequenceStringOfSequence(
          state,
          sequenceId
        ).toUpperCase();

        elementIdToInvalidityMessages[fas.elementId] =
          elementIdToInvalidityMessages[fas.elementId] || [];

        // part needs to start at the junction length and end at sequence length - junction length
        if (parseInt(part.start) !== junctionLength) {
          return elementIdToInvalidityMessages[fas.elementId].push(
            `ARFFAS: This part's starting bp (${part.start}) should be the same as the junction length ${junctionLength}`
          );
        } else if (
          parseInt(part.end) !==
          sequenceStr.length - 1 - junctionLength
        ) {
          return elementIdToInvalidityMessages[fas.elementId].push(
            `ARFFAS: This part's ending bp (${
              part.end
            }) should be a junction's length away from the end of its sequence (at ${sequenceStr.length -
              1 -
              junctionLength})`
          );
        }

        const thisBin = getItemOfType(state, "bin", element.binId);

        if (parseInt(thisBin.fro) !== Math.floor(junctionLength / 2)) {
          elementIdToInvalidityMessages[fas.elementId].push(
            `ARFFAS: The Forced Relative Overlap on bin ${
              thisBin.name
            } should be set to half the overlap length (${junctionLength} / 2 and rounded down), currently it is set to ${
              thisBin.fro ? thisBin.fro : "'an empty value'"
            }`
          );
        }

        if (
          parseInt(thisBin.extra3PrimeBps) !== (junctionLengthIsOdd ? -1 : 0)
        ) {
          elementIdToInvalidityMessages[fas.elementId].push(
            `ARFFAS: Extra 3 Prime Bps should be set to ${
              junctionLengthIsOdd ? -1 : 0
            } for bin ${thisBin.name}. It is currently set to ${
              thisBin.extra3PrimeBps !== undefined
                ? thisBin.extra3PrimeBps
                : "'an empty value'"
            }.`
          );
        }

        const previousBinId = getNeighborBinId(
          state,
          getOutputCardIdOfReaction(state, reaction.id),
          thisBin.id,
          false
        );

        if (previousBinId) {
          const previousBin = getItemOfType(state, "bin", previousBinId);

          if (parseInt(previousBin.fro) !== -Math.floor(junctionLength / 2)) {
            elementIdToInvalidityMessages[fas.elementId].push(
              `ARFFAS: The Forced Relative Overlap on bin ${
                previousBin.name
              } should be set to negative half the overlap length rounded down (${-Math.floor(
                junctionLength / 2
              )}), currently it is ${
                previousBin.fro ? previousBin.fro : "'an empty value'"
              }`
            );
          }

          if (
            parseInt(previousBin.extra5PrimeBps) !==
            (junctionLengthIsOdd ? -1 : 0)
          ) {
            elementIdToInvalidityMessages[fas.elementId].push(
              `ARFFAS: Extra 5 Prime Bps should be set to ${
                junctionLengthIsOdd ? -1 : 0
              } for bin ${previousBin.name}. It is currently set to ${
                previousBin.extra5PrimeBps !== undefined
                  ? previousBin.extra5PrimeBps
                  : "'an empty value'"
              }.`
            );
          }

          // validate bps against previous bin
          const elementsInPreviousBin = getElementsInBin(state, previousBinId);

          elementsInPreviousBin.forEach(prevEl => {
            const previousBps = getSequenceStringsOfElement(state, prevEl.id);

            previousBps.forEach(bps => {
              if (
                bps.slice(-junctionLength).toUpperCase() !==
                sequenceStr.slice(0, junctionLength)
              ) {
                elementIdToInvalidityMessages[fas.elementId].push(
                  `ARFFAS: The beginning ${junctionLength} bps (aka junction length) of ${element.name}'s source sequence does not match the last ${junctionLength} bps of part ${prevEl.name} in the previous bin`
                );
              }
            });
          });
        } else {
          // if no previous bin then the part needs to start at the beginning of its sequence kc_todo
        }

        const nextBinId = getNeighborBinId(
          state,
          getOutputCardIdOfReaction(state, reaction.id),
          thisBin.id,
          true
        );

        if (nextBinId) {
          // validate bps against next bin
          const elementsInNextBin = getElementsInBin(state, nextBinId);

          elementsInNextBin.forEach(nextEl => {
            const nextBps = getSequenceStringsOfElement(state, nextEl.id);

            nextBps.forEach(bps => {
              if (
                bps.slice(0, junctionLength).toUpperCase() !==
                sequenceStr.slice(-junctionLength)
              ) {
                elementIdToInvalidityMessages[fas.elementId].push(
                  `ARFFAS: The last ${junctionLength} bps (aka junction length) of ${element.name}'s source sequence does not match the first ${junctionLength} bps of part ${nextEl.name} in the next bin`
                );
              }
            });
          });
        } else {
          // if no next bin then the part needs to end at the end of its sequence kc_todo
        }
      } catch (e) {
        console.error("Error validating ARFFAS condition 4");
        throw e;
      }
    });

    const allElements = designState.element;
    clearObsoleteInvalidityMsgs({
      allElements,
      elementIdToInvalidityMessages,
      changeSetsHelper
    });

    Object.entries(elementIdToInvalidityMessages).forEach(
      ([elementId, invalidityMessages]) => {
        const element = allElements[elementId];

        // get rid of old ARFFAS msgs, replace with new ones
        let existingMsgs = !element.invalidityMessage
          ? []
          : element.invalidityMessage
              .split("\n")
              .filter(msg => !startsWith(msg, "ARFFAS"));

        const invalidityMessage = invalidityMessages.length
          ? existingMsgs.concat(uniq(invalidityMessages)).join("\n")
          : existingMsgs.join("\n");

        // add ARFFAS related msgs to any existing non-ARFFAS msgs
        changeSetsHelper.updatePure("element", {
          id: element.id,
          invalidityMessage
        });
      }
    );

    return changeSetsHelper.execute({
      removeCardInvalidityMessages: false,
      updateNumPlaceholders: false,
      removeInvalidEugeneRules: false,
      removeInvalidElementCombos: false
    });
  } catch (err) {
    console.error("Error with validating digest fas", err);
    return designState;
  }
}

function clearObsoleteInvalidityMsgs({
  allElements,
  elementIdToInvalidityMessages,
  changeSetsHelper,
  designState
}) {
  for (const element of Object.values(allElements)) {
    if (
      element.invalidityMessage &&
      !elementIdToInvalidityMessages[element.id]
    ) {
      // only clear ARFFAS related msgs, not Express Digest msgs
      let msgs = element.invalidityMessage
        .split("\n")
        .filter(msg => !startsWith(msg, "ARFFAS"));

      changeSetsHelper.updatePure("element", {
        id: element.id,
        invalidityMessage: msgs.length === 0 ? null : msgs.join("\n")
      });
    }
  }
  return designState;
}
