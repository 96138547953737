/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get } from "lodash";
import { ContextMenu, Spinner } from "@blueprintjs/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactionCardContextMenu from "./ReactionCardContextMenu";

import actions from "../../../src-shared/redux/actions";
import {
  isDesignVisualReport,
  getItemOfType,
  getReferencedValue,
  treeLayout
} from "../../../../tg-iso-design/selectors/designStateSelectors";
import store from "../../../src-shared/redux/store";
import { DIGEST_ASSEMBLY_METHODS } from "../../../../tg-iso-design/constants/assemblyMethods";
import { isDesignLocked } from "../../../src-shared/utils/designUtils/isDesignLocked";

/**
 * This component is used to render the operation labels on
 * the connector lines in the tree layouts.
 */
class ReactionCard extends React.Component {
  static propTypes = {
    /**
     * The color of the connector line that this card is being rendered on.
     */
    connectorColor: PropTypes.string.isRequired,

    /**
     * The of id of the reaction this card is representing.
     */
    reactionId: PropTypes.string.isRequired,

    /**
     * The level of output card of this reaction. This takes of the form of `1.3.2.1`. The number of numbers
     * equals the depth of the card in the tree. The i-th number represent the index of
     * the card's ancestor at depth i among its siblings.
     */
    level: PropTypes.string.isRequired,

    /**
     * Is the design a visual report?
     *
     * @deprecated We don't have visual reports anymore.
     */
    isVisualReport: PropTypes.bool,

    /**
     * Is the design locked?
     */
    isLocked: PropTypes.bool,

    /**
     * Are we rendering a card in the horizontal or vertical tree layouts?
     */
    treeLayout: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,

    /**
     * Database object representing the reaction.
     */
    reaction: PropTypes.object.isRequired,

    /**
     * Database object representing the assembly method of the reaction.
     */
    // ! isRequired is removed, sometimes assemblyMethod iis being loaded after design
    assemblyMethod: PropTypes.object,

    /**
     * Database object representing the restriction enzyme of the reaction.
     * Will be `undefined` for all non-contiguous express digest reactions.
     */
    restrictionEnzyme: PropTypes.object
  };
  handleContextMenu = e => {
    const { isVisualReport, reactionId, selectReaction, isLocked } = this.props;
    e.preventDefault();
    selectReaction(reactionId);

    if (isVisualReport || isLocked) return;

    ContextMenu.show(
      <ReactionCardContextMenu reactionId={reactionId} store={store} />,
      {
        left: e.clientX,
        top: e.clientY
      }
    );
  };

  openInspectorPanel = () => {
    const { setInspectorActivePanel, openInspector } = this.props;
    setInspectorActivePanel({
      panel: "operation"
    });
    openInspector();
  };

  handleOperationNameClick = () => {
    const { reactionId, selectReaction } = this.props;
    selectReaction(reactionId);
    this.openInspectorPanel();
  };
  render() {
    const {
      assemblyMethod,
      restrictionEnzyme,
      connectorColor,
      treeLayout,
      level
    } = this.props;

    const border = `3px solid ${connectorColor}`;
    const style =
      treeLayout === "vertical"
        ? {
            borderBottom: border,
            borderRight: border,
            borderTop: border
          }
        : {
            borderLeft: border,
            borderRight: border,
            borderTop: border
          };

    return (
      <div style={{ marginTop: 20 }}>
        <div
          className="reaction-label"
          style={style}
          onContextMenu={this.handleContextMenu}
          onClick={this.handleOperationNameClick}
        >
          <span className="reaction-card-label">
            {assemblyMethod ? (
              `${level} ${assemblyMethod.name}` +
              (DIGEST_ASSEMBLY_METHODS.includes(assemblyMethod.name)
                ? ` (${get(restrictionEnzyme, "name")})`
                : "")
            ) : (
              // * This spinner is added to avoid assemblyMethod 'undefined' crash the component
              <Spinner intent="primary" size="16" />
            )}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { reactionId } = props;
  return {
    isVisualReport: isDesignVisualReport(state),
    treeLayout: treeLayout(state),
    reaction: getItemOfType(state, "reaction", reactionId),
    assemblyMethod: getReferencedValue(
      state,
      "reaction",
      reactionId,
      "assemblyMethodId"
    ),
    restrictionEnzyme: getReferencedValue(
      state,
      "reaction",
      reactionId,
      "restrictionEnzymeId"
    ),
    isLocked: isDesignLocked(state)
  };
};

const mapDispatchToProps = {
  selectReaction: actions.ui.designEditor.general.selectReaction,
  openInspector: actions.ui.designEditor.inspector.open,
  setInspectorActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  setDesignLastUpdated: actions.ui.designEditor.general.setDesignLastUpdated,
  setLayoutFrozen: actions.ui.designEditor.general.setLayoutFrozen,
  updateViewOptions: actions.ui.designEditor.general.updateViewOptions,
  addToUndoStack: actions.ui.designEditor.undo.addToUndoStack
};
export default connect(mapStateToProps, mapDispatchToProps)(ReactionCard);
