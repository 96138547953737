/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment commentFragment on comment {
    id
    message
    headerMsg
    icon
    userId
    user {
      id
      firstName
      lastName
      username
      email
      avatarFile {
        id
        path
      }
    }
    createdAt
    updatedAt
  }
`;
