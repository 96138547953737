/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

export default {
  description: "Hit an endpoint to export a plate in a custom format",
  endpoints: {
    EXPORT_CUSTOM_PLATE: {
      name: "Export Custom Plate",
      method: "POST",
      description: "Export a plate in a custom format",
      exampleRequest: {
        __validate__: () =>
          yup.object({
            plates: yup.array(
              yup.object({
                id: yup.string(),
                name: yup.string(),
                barcode: yup.string(),
                format: yup.object({
                  rowCount: yup.number(),
                  columnCount: yup.number()
                }),
                contents: yup.array(
                  yup.object({
                    position: yup.string(),
                    volume: yup.number().nullable(),
                    "volume unit": yup.string().nullable(),
                    mass: yup.number().nullable(),
                    "mass unit": yup.string().nullable(),
                    concentration: yup.number().nullable(),
                    "concentration unit": yup.string().nullable(),
                    molarity: yup.number().nullable(),
                    "molarity unit": yup.string().nullable(),
                    materials: yup.array(
                      yup.object({
                        id: yup.string(),
                        name: yup.string(),
                        polynucleotideMaterialSequence: yup
                          .object({
                            id: yup.string(),
                            size: yup.number()
                          })
                          .nullable()
                      })
                    ),
                    additives: yup.array(yup.string())
                  })
                )
              })
            )
          }),
        plates: [
          {
            id: "2c39d8cc-eb8a-466e-b6a2-ce7589d3854d",
            name: "Plate 1",
            barcode: "Z0000009a5",
            format: { rowCount: 8, columnCount: 12 },
            contents: [
              {
                position: "A1",
                volume: 1,
                "volume unit": "uL",
                materials: [
                  {
                    id: "cd325aa3-d56c-4aea-b213-d51014f51549",
                    name: "PCR_0001",
                    polynucleotideMaterialSequence: {
                      id: "0a0c9975-c4f0-41ee-b205-3e41489b6739",
                      size: 4623
                    }
                  }
                ],
                additives: []
              },
              {
                position: "A11",
                volume: 3,
                "volume unit": "uL",
                materials: [],
                additives: ["reagent 3 (lot 3)"]
              }
            ]
          }
        ]
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            data: yup.array(
              yup.object({
                filename: yup.string(),
                contents: yup.string()
              })
            )
          });
        },
        data: [
          {
            filename: "plate.txt",
            contents: `custom plate format for Plate 1
              A1,1,uL,PCR_0001,4623
              A11,3,uL,,reagent 3 (lot 3)`
          }
        ]
      }
    }
  }
};
