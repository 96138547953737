/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { keyBy } from "lodash";

import actions from "../../src-shared/redux/actions";

import {
  getNumberOfRowsForCard,
  getAllOfType
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { times } from "lodash";

import { withEditorContext } from "../components/HierarchicalDesign/DesignEditorContext";
import { Checkbox, Position, Tooltip } from "@blueprintjs/core";
import { CELL_HEIGHT } from "../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";

const RowDisableCell = withEditorContext(function RowDisableCell({
  numberOfRows,
  toggleRowDisabled,
  disabledDesignRows
}) {
  const style = {
    display: "flex",
    flexDirection: "column",
    top: "-35px"
  };
  const disabledRowsByIndex = keyBy(disabledDesignRows, "rowIndex");
  return (
    <div
      key="row-index-container"
      className="row-index-container"
      style={style}
    >
      {times(numberOfRows, i => {
        return (
          <div
            key={i}
            data-key={i}
            className="design-row-disable"
            style={{
              height: CELL_HEIGHT,
              width: 35
            }}
          >
            <Tooltip
              position={Position.RIGHT}
              content={`${
                isListRowDisabled({ disabledRowsByIndex, rowIndex: i })
                  ? "Enable"
                  : "Disable"
              } This Row`}
            >
              <div>
                <Checkbox
                  onChange={() => {
                    toggleRowDisabled({ rowIndex: i });
                  }}
                  checked={
                    !isListRowDisabled({ disabledRowsByIndex, rowIndex: i })
                  }
                ></Checkbox>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
});

const mapStateToProps = (state, props) => {
  const { cardId } = props;

  return {
    numberOfRows: getNumberOfRowsForCard(state, cardId),
    isLocked: isDesignLocked(state),
    disabledDesignRows: getAllOfType(state, "disabledDesignRow")
  };
};

const mapDispatchToProps = {
  toggleRowDisabled: actions.design.toggleRowDisabled
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RowDisableCell
);

const isListRowDisabled = ({ rowIndex, disabledRowsByIndex }) => {
  const toRet = !!disabledRowsByIndex[rowIndex];
  return toRet;
};
