/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { keyBy } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import SelectSequenceData, {
  SEQUENCE_PREFIX_HELPER
} from "./Steps/SelectSequenceData";
import processSequences from "../../../../../tg-iso-shared/src/sequence-import-utils/processSequences";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { sequenceAssociationTableFragment } from "./fragments";

class SequenceAssociationTool extends Component {
  onSubmit = async values => {
    try {
      const {
        materialsToCreate,
        dnaMaterialsToCreate,
        sequencesToCreate,
        sequenceUpdates,
        sampleUpdates,
        materialCidToPlasmidCid = {},
        keyedPlates = {},
        keyedTubes = {}
      } = values;

      await safeUpsert("material", dnaMaterialsToCreate);
      await safeUpsert("sequence", sequenceUpdates);
      const newSequences = await safeUpsert(
        ["sequence", "id name cid"],
        sequencesToCreate
      );
      await processSequences(newSequences.map(s => s.id));
      const newMaterials = await safeUpsert(
        ["material", "id name cid"],
        materialsToCreate
      );
      await safeUpsert("sample", sampleUpdates);

      const keyedNewSequences = keyBy(newSequences, "cid");
      const sequenceNameUpdates = [];
      const materialUpdates = [];
      newSequences.forEach(sequence => {
        if (sequence.name.includes(SEQUENCE_PREFIX_HELPER)) {
          sequenceNameUpdates.push({
            id: sequence.id,
            name: sequence.name.replace(SEQUENCE_PREFIX_HELPER, sequence.id)
          });
        }
      });
      newMaterials.forEach(material => {
        const newSequence =
          keyedNewSequences[materialCidToPlasmidCid[material.cid]];
        if (material.name.includes(SEQUENCE_PREFIX_HELPER) && newSequence) {
          materialUpdates.push({
            id: material.id,
            name: material.name.replace(SEQUENCE_PREFIX_HELPER, newSequence.id)
          });
        }
      });

      await safeUpsert("sequence", sequenceNameUpdates);
      await safeUpsert("material", materialUpdates);

      return {
        containerArrays: Object.values(keyedPlates),
        aliquotContainers: Object.values(keyedTubes)
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating samples with sequence data.");
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select Sequence Data",
        Component: SelectSequenceData,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default withWorkflowInputs(sequenceAssociationTableFragment, {
  singular: true,
  initialValueName: "sequenceAssociationTable"
})(SequenceAssociationTool);
