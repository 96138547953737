/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { get, sortBy } from "lodash";
import Big from "big.js";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import withQuery from "../../../../../src-shared/withQuery";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { compose } from "recompose";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getAliquotContainersToNormalize,
  getSourceVolumetricUnit
} from "../utils";
import {
  generateEmptyWells,
  sortAliquotContainers
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";

const transferWorklistColumns = {
  model: "worklist",
  fields: [
    {
      displayName: "Source",
      path: "sourcePlateName"
    },
    {
      displayName: "Source Position",
      path: "sourceLocation"
    },
    {
      displayName: "Destination",
      path: "destinationContainerArrayName"
    },
    {
      displayName: "Destination Position",
      path: "destLocation"
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    },
    {
      displayName: "Aliquot",
      path: "aliquotSample"
    }
  ]
};

function getNormalizationWorklistSchema(aliquotContainerTypeCode) {
  return {
    model: "worklist",
    fields: [
      {
        displayName: "Source",
        path: "sourcePlateName"
      },
      {
        displayName: "Source Position",
        path: "sourcePlateWell",
        isHidden: aliquotContainerTypeCode === "RESERVOIR"
      },
      {
        displayName: "Destination",
        path: "destinationContainerArrayName"
      },
      {
        displayName: "Destination Position",
        path: "destinationPlateWell"
      },
      {
        displayName: "Transfer Volume",
        path: "transferVolume",
        render: volumeRender
      },
      {
        displayName: "Aliquot",
        path: "aliquotSample"
      }
    ]
  };
}

class ReviewWorklists extends Component {
  renderPreNormalizationWorklist() {
    const {
      destinationContainerArrayName,
      desiredTransferVolume,
      desiredTransferVolumetricUnitCode,
      containerArrayToNormalize,
      containerArrayType,
      destinationTransferOrder,
      sourceTransferOrder,
      toolSchema
    } = this.props;

    const acsToUse = getAliquotContainersToNormalize(this.props);

    const emptyWells = generateEmptyWells(containerArrayType.containerFormat);
    const sortedSourceAcs = sortAliquotContainers(
      acsToUse,
      sourceTransferOrder
    );
    const sortedWells = sortAliquotContainers(
      emptyWells,
      destinationTransferOrder
    );

    const isDifferentFormat =
      containerArrayType.containerFormat.code !==
      containerArrayToNormalize.containerArrayType.containerFormat.code;

    const containerArrayTransferWorklistTransfers = sortedSourceAcs.reduce(
      (acc, aliquotContainer) => {
        if (!get(aliquotContainer, "aliquot")) return acc;
        const location = getAliquotContainerLocation(aliquotContainer);
        const transfer = {
          sourcePlateName: containerArrayToNormalize.name,
          sourceAliquotContainer: aliquotContainer,
          destinationContainerArrayName,
          sourceLocation: location,
          destLocation: isDifferentFormat
            ? getAliquotContainerLocation({
                ...sortedWells.shift(),
                containerArrayType
              })
            : location,
          aliquotSample: get(aliquotContainer, "aliquot.sample.name"),
          volume: desiredTransferVolume,
          volumetricUnitCode: desiredTransferVolumetricUnitCode
        };
        return acc.concat(transfer);
      },
      []
    );
    return (
      <React.Fragment>
        <ReviewWorklistSection
          toolSchema={toolSchema}
          schema={transferWorklistColumns}
          worklistTransfers={containerArrayTransferWorklistTransfers}
          noSort
          header="Pre-Normalization Transfer Worklist"
          helper="Enter a worklist name and review the transfers below prior to normalization."
          fieldName="containerArrayTransferWorklistName"
          tableFormName="previewSourceRackNormalizationPlateWorklistTable"
        />
      </React.Fragment>
    );
  }
  render() {
    const {
      transferSourcePlateToNormalizedPlate,
      destinationContainerArrayName,
      containerArrayToNormalize,
      intermediateContainerType,
      dilutionWorklistTransfers,
      toolSchema
    } = this.props;

    const {
      volumetricUnit,
      volumetricUnitCode: volumetricUnitCodeForNormalization
    } = getSourceVolumetricUnit(containerArrayToNormalize);

    const normalizationWorklist = dilutionWorklistTransfers
      ? dilutionWorklistTransfers.reduce((acc, worklistTransfer) => {
          const location = getAliquotContainerLocation(worklistTransfer);
          const sortedTransfers = sortBy(worklistTransfer.transfers, [
            "aliquotContainer.columnPosition",
            "aliquotContainer.rowPosition"
          ]);
          const worklistItems = sortedTransfers.map(
            ({ transferVolume: _transferVolume, aliquotContainer }) => {
              const transferVolume = new Big(_transferVolume)
                .div(new Big(volumetricUnit.liters))
                .toString();
              return {
                sourcePlateName: worklistTransfer.name,
                sourcePlateWell: location,
                sourceAliquotContainer: aliquotContainer,
                destinationContainerArrayName: transferSourcePlateToNormalizedPlate
                  ? destinationContainerArrayName
                  : containerArrayToNormalize.name,
                destinationPlateWell: getAliquotContainerLocation(
                  aliquotContainer
                ),
                transferVolume: transferVolume.toString(),
                volumetricUnitCode: volumetricUnitCodeForNormalization,
                aliquotSample: get(aliquotContainer, "aliquot.sample.name")
              };
            }
          );
          return acc.concat(worklistItems);
        }, [])
      : [];
    return (
      <div>
        {transferSourcePlateToNormalizedPlate &&
          this.renderPreNormalizationWorklist()}
        <ReviewWorklistSection
          toolSchema={toolSchema}
          schema={getNormalizationWorklistSchema(
            intermediateContainerType.aliquotContainerTypeCode
          )}
          worklistTransfers={normalizationWorklist}
          noSort
          header="Normalization Worklist"
          helper="Enter a worklist name and review the normalization transfers below."
          fieldName="normalizationWorklistName"
          tableFormName="previewNormalizationWorklistTable"
        />
      </div>
    );
  }
}

export default compose(
  withQuery(aliquotContainerTypeFragment, { isPlural: true })
)(ReviewWorklists);
