/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";

function Footer({
  nextButton,
  previousButton,
  disabled,
  nextDisabled,
  onClick,
  onNextClick,
  previousDisabled,
  defaultPreviousButton,
  defaultNextButton,
  intent,
  additionalButtons,
  submitting,
  error,
  loading,
  nextText,
  text,
  valid,
  errorMessage,
  submitFailed,
  activeStepIndex
}) {
  let errorMessageToUse = submitFailed && error;
  if (!error) errorMessageToUse = errorMessage;
  const submittingOrLoading = submitting || loading;
  const isDisabled = nextDisabled || disabled;

  return (
    <div className="width100 tg-step-form-footer">
      <div className="tg-step-form-buttons">
        {previousButton ||
          defaultPreviousButton(activeStepIndex, previousDisabled)}
        <div className="tg-flex">
          {additionalButtons}
          {nextButton ||
            defaultNextButton({
              intent,
              ableToClick:
                valid &&
                !error &&
                !errorMessage &&
                !submittingOrLoading &&
                !isDisabled,
              activeStepIndex,
              disabled: isDisabled,
              onClick: onNextClick || onClick,
              text: nextText || text,
              submitting: submittingOrLoading
            })}
        </div>
      </div>
      {errorMessageToUse && (
        <div className={classNames(Classes.FORM_GROUP, Classes.INTENT_DANGER)}>
          <div
            className={classNames(
              Classes.FORM_HELPER_TEXT,
              "tg-step-form-error preserve-newline"
            )}
          >
            {errorMessageToUse}
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
