/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
//Utils file for normalizing text with Regex

//Used for loop to check within a text even if it's pasted
const normalizeText = (text, regex) => {
  let normalizedText = "";
  for (const i of text) {
    normalizedText += i.replace(regex, "");
  }
  return normalizedText;
};

export const normalizeAminoAcidSequence = text => {
  return normalizeText(text, /[^xtgalmfwkqespvicyhrnd*]/gi);
};

export const normalizeBasePairs = text => {
  return normalizeText(text, /[^acgtuwsmkrybdhvn]*$/i);
};

export const trimChars = text => {
  let newText = "";
  for (const i of text) {
    newText += i.trim();
  }
  return newText;
};
