/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

const {
  Provider: DesignEditorProvider,
  Consumer: DesignEditorConsumer
} = React.createContext();

const withEditorContext = WrappedComponent => props => (
  <DesignEditorConsumer>
    {editorContext => (
      <WrappedComponent {...{ ...props, editorContext }}>
        {props.children}
      </WrappedComponent>
    )}
  </DesignEditorConsumer>
);

export { DesignEditorProvider, DesignEditorConsumer, withEditorContext };
