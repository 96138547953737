/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import Footer from "../../../../src-shared/MobxModal/Footer";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import DataMapperRecordView from "./dataMapperRecordView";

const ViewMetaData = ({ mainStore: { modal } }) => {
  return (
    <React.Fragment>
      <div className={classNames(Classes.DIALOG_BODY, "tg-flex auto")}>
        <DataMapperRecordView {...modal.props.get().record} />
      </div>
      <Footer
        onClick={() => {
          modal.hideModal();
        }}
        text="Ok"
      />
    </React.Fragment>
  );
};

export default inject("mainStore")(observer(ViewMetaData));
