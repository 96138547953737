/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment lengthUnitFragment on lengthUnit {
    code
    name
    description
    isMetricUnit
    inches
    millimeters
  }
`;
