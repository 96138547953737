/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { connect } from "react-redux";
import { get, noop, forEach } from "lodash";
import { compose } from "recompose";
import { Prompt, withRouter } from "react-router-dom";
import { withHandlers, withProps } from "recompose";
import { reduxForm, destroy, getFormValues } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Button, Intent, Classes } from "@blueprintjs/core";
import Helmet from "react-helmet";
import scrollIntoView from "dom-scroll-into-view";
import Promise from "bluebird";
import {
  WorkflowDefaultParamsContext,
  TableFormTrackerContext
} from "@teselagen/ui";
import Step from "./Step";
import SuccessPage from "./SuccessPage";
import StepFormFooter from "./StepFormFooter";
import {
  dataItemTypeMap,
  inventoryItemTypeMap
} from "../../../tg-iso-shared/src/utils/inventoryUtils";

import "./style.css";

import classNames from "classnames";
import { stopImportCollection } from "../utils/importCollection";
import { safeQuery, safeUpsert } from "../apolloMethods";
import ToolTitle from "../ToolTitle";

const beforeLeaveConfirmationMessage =
  "Are you sure you want to leave? Tool progress will not be saved.";

const AddWorkflowParamsContextHOC = Component => props => {
  const { toolIntegrationProps, toolSchema } = props;
  const {
    task,
    taskNumber,
    workflowDefinitionName,
    workflowDefinitionId,
    workflowRunName,
    workflowRunId
  } = toolIntegrationProps || {};
  const taskTitle = get(task, "workflowToolDefinition.name");
  const toolName = get(toolSchema, "toolSchema.name");
  return (
    <WorkflowDefaultParamsContext.Provider
      value={{
        taskNumber,
        workflowDefinitionName,
        workflowDefinitionId,
        workflowRunName,
        workflowRunId,
        workflowToolTitle: taskTitle || toolName,
        toolName
      }}
    >
      <Component {...props}></Component>
    </WorkflowDefaultParamsContext.Provider>
  );
};

class StepForm extends Component {
  state = { onSubmitCalled: false };

  static defaultProps = {
    toolIntegrationProps: {},
    activeStepIndex: 0,
    toolSchema: {},
    afterReset: noop
  };

  nestedTableFormNames = [];

  addNestedTableFormName = formName => {
    this.nestedTableFormNames.push(formName);
  };

  warnBeforeLeave = e => {
    const { onSubmitCalled } = this.state;
    const { pristine } = this.props;
    // if we are in a cypress test run then we don't want to block navigation
    if (window.Cypress) {
      return;
    }
    if (pristine || onSubmitCalled) return;

    (e || window.event).returnValue = beforeLeaveConfirmationMessage; //Gecko + IE
    return beforeLeaveConfirmationMessage; //Webkit, Safari, Chrome
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.warnBeforeLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.warnBeforeLeave);
    stopImportCollection();
    const {
      toolIntegrationProps: { isToolIntegrated }
    } = this.props;
    if (!isToolIntegrated) {
      this.resetStepForm(false, true);
    }
  }

  onToolReset = () => {
    const { history, afterReset } = this.props;
    this.resetStepForm(true);
    history.replace({ pathname: history.location.pathname, search: "" });
    afterReset();
  };

  resetStepForm = (overrideDebuggingMode, unmounting) => {
    // destroy forms when unmounting otherwise the data will persist when
    // the user opens the form again

    const { debuggingMode, destroy, destroyForms } = this.props;
    if (!unmounting) {
      this.setState({
        onSubmitCalled: false
      });
    }
    if (
      process.env.NODE_ENV !== "production" &&
      !overrideDebuggingMode &&
      (debuggingMode || window.location.href.indexOf("?debug") > -1)
    )
      return; //don't destroy anything so that the form state won't reload every time
    destroy();
    this.nestedTableFormNames && destroyForms(...this.nestedTableFormNames);
  };

  nextStep = () => {
    this.setState({ onSubmitCalled: false });
    this.setActiveStep(this.props.activeStepIndex + 1);
  };

  previousStep = () => {
    this.setState({ onSubmitCalled: false });
    this.setActiveStep(Math.max(this.props.activeStepIndex - 1, 0));
  };

  setActiveStep = i => {
    this.props.change("activeStepIndex", i);
    try {
      const stepFormContainer = document.getElementsByClassName(
        "tg-step-form-container"
      )[0];
      // scroll to top of browser page on page change
      stepFormContainer.scrollTop = 0;
    } catch (error) {
      console.error("error:", error);
    }
  };

  /**
   * render the next step button
   * can pass in a custom button element for each step
   */
  nextStepButton = ({
    disabled,
    onClick,
    ableToClick,
    submitting,
    text,
    intent
  }) => {
    const { steps, activeStepIndex } = this.props;
    const nextButton = steps[activeStepIndex].nextButton;
    const isLastStep = activeStepIndex === steps.length - 1;
    if (nextButton) {
      if (submitting) {
        return React.cloneElement(nextButton, { loading: true });
      } else {
        return nextButton;
      }
    } else {
      return (
        <Button
          disabled={disabled}
          onClick={onClick}
          className={classNames({
            "tg-step-form-submit-ready": ableToClick
          })}
          type="submit"
          loading={submitting}
          intent={intent || (isLastStep ? Intent.SUCCESS : Intent.PRIMARY)}
          text={text || (isLastStep ? "Submit" : "Next")}
        />
      );
    }
  };

  previousStepButton = (stepIndex, disabled) => {
    return stepIndex === 0 ? (
      <div />
    ) : (
      <Button
        disabled={disabled}
        onClick={this.previousStep}
        text="Previous"
        minimal
      />
    );
  };

  renderStepNav() {
    const { steps, activeStepIndex, submitting } = this.props;

    return steps.reduce((acc, step, i) => {
      acc.push(
        <Step
          key={i}
          title={step.title}
          complete={activeStepIndex > i}
          active={activeStepIndex === i}
          disabled={activeStepIndex < i || submitting}
          setActive={this.setActiveStep}
          step={i}
        />
      );
      if (i < steps.length - 1)
        acc.push(
          <div
            key={`step-separator-${i}`}
            className={classNames("step-separator", {
              small: steps.length > 3
            })}
          />
        );
      return acc;
    }, []);
  }

  onSubmit = async data => {
    const dataToUse = { ...data };
    // remove meta values
    delete dataToUse.inProgress;
    delete dataToUse.activeStepIndex;
    const {
      onSubmit,
      toolIntegrationProps: { taskCallback, tableFormContextValue }
    } = this.props;
    const state = window.teGlobalStore.getState();
    const nestedTableForms = tableFormContextValue
      ? tableFormContextValue.formNames
      : this.nestedTableFormNames;
    const formVals = nestedTableForms.reduce((acc, name) => {
      acc[name] = getFormValues(name)(state);
      return acc;
    }, {});
    const returnVal = await onSubmit(dataToUse, formVals);
    if (!returnVal) return;
    else if (taskCallback) {
      await taskCallback(returnVal, dataToUse);
    } else {
      // if the tool outputs have a microservice queue id we need to add it to the ioItem
      // to track its status
      // this is complicated because we need to link the ioItem to this outputs inventory or data
      // item. However, we first have to query to see if the item already has an inventory or data
      // item. Then we can either update the ioItem or create a new one with the microserviceQueueId
      try {
        const ioItemsToCreate = [];
        const ioItemsToUpdate = [];
        const itemTypesToUpdateObj = {};
        const addToMap = output => {
          const { id: itemId, microserviceQueueId, __typename } = output;
          if (__typename && microserviceQueueId) {
            itemTypesToUpdateObj[__typename] =
              itemTypesToUpdateObj[__typename] || {};
            itemTypesToUpdateObj[__typename][itemId] = microserviceQueueId;
          }
        };

        forEach(returnVal, output => {
          if (output) {
            if (Array.isArray(output)) {
              // isList ioItems
              output.forEach(addToMap);
            } else {
              // single ioItem
              addToMap(output);
            }
          }
        });
        const models = Object.keys(itemTypesToUpdateObj);
        await Promise.map(models, async model => {
          let fragment = `id`;
          if (!dataItemTypeMap[model] && !inventoryItemTypeMap[model]) return;
          if (dataItemTypeMap[model]) {
            fragment += ` dataItems { id ioItem { id } }`;
          } else if (inventoryItemTypeMap[model]) {
            fragment += ` inventoryItems { id ioItem { id } }`;
          }
          const itemIds = Object.keys(itemTypesToUpdateObj[model]);
          const itemWithInvOrDataItems = await safeQuery([model, fragment], {
            variables: {
              filter: {
                id: itemIds
              }
            }
          });
          itemWithInvOrDataItems.forEach(item => {
            const microserviceQueueId = itemTypesToUpdateObj[model][item.id];
            if (microserviceQueueId) {
              let ioItemId, invOrDataItemId;
              (item.dataItems || item.inventoryItems).forEach(invOrDataItem => {
                if (invOrDataItem.ioItem) {
                  ioItemId = invOrDataItem.ioItem.id;
                } else {
                  invOrDataItemId = invOrDataItem.id;
                }
              });
              if (ioItemId) {
                ioItemsToUpdate.push({
                  id: ioItemId,
                  microserviceQueueId
                });
              } else {
                const ioItemToCreate = {
                  ioItemTypeCode: dataItemTypeMap[model] ? "DATA" : "INVENTORY",
                  microserviceQueueId,
                  ioItemAvailabilityStatusCode: "AVAILABLE"
                };
                if (invOrDataItemId) {
                  ioItemToCreate[
                    dataItemTypeMap[model] ? "dataItemId" : "inventoryItemId"
                  ] = invOrDataItemId;
                } else {
                  if (dataItemTypeMap[model]) {
                    ioItemToCreate.dataItem = {
                      dataItemTypeCode: dataItemTypeMap[model],
                      [model + "Id"]: item.id
                    };
                  } else {
                    ioItemToCreate.inventoryItem = {
                      inventoryItemTypeCode: inventoryItemTypeMap[model],
                      [model + "Id"]: item.id
                    };
                  }
                }
                ioItemsToCreate.push(ioItemToCreate);
              }
            }
          });
          await safeUpsert("ioItem", ioItemsToCreate);
          await safeUpsert("ioItem", ioItemsToUpdate);
        });
      } catch (error) {
        console.error("error:", error);
      }
      this.submittedData = returnVal;
      this.setState({ onSubmitCalled: true });
    }
    return returnVal;
  };

  render() {
    const {
      handleSubmit,
      error,
      submitFailed,
      steps,
      getSuccessPageProps,
      submitting,
      form,
      onSubmit,
      toolName,
      toolSchema,
      reset,
      submitSucceeded,
      toolIntegrationProps,
      activeStepIndex: _activeStepIndex,
      location,
      successPageInnerContent,
      ...formProps
    } = this.props;
    const { onSubmitCalled } = this.state;

    const { title: toolTitle } = toolSchema;
    const {
      selectionWarning,
      disabledWarning,
      isToolIntegrated,
      stopTool,
      tableFormContextValue
    } = toolIntegrationProps;
    let activeStepIndex = _activeStepIndex;
    if (!onSubmit)
      throw new Error(
        "Please pass an onSubmit prop to <StepForm/> like <StepForm onSubmit={onSubmit}/> "
      );

    const isLastStep = activeStepIndex === steps.length - 1;
    if (
      isLastStep &&
      onSubmitCalled &&
      submitSucceeded
      // && getSuccessPageProps
    ) {
      return (
        <SuccessPage
          title={toolTitle}
          resetTool={this.onToolReset}
          submittedData={this.submittedData}
          toolSchema={toolSchema.toolSchema}
          innerContentOverride={successPageInnerContent}
          // {...getSuccessPageProps(this.props)}
        />
      );
    }
    const defaultPreviousButton = this.previousStepButton;
    const defaultNextButton = this.nextStepButton;
    if (_activeStepIndex >= steps.length) {
      console.error("this should not happen");
      console.error(
        "_activeStepIndex, steps.length:",
        _activeStepIndex,
        steps.length
      );
      activeStepIndex = steps.length - 1;
    }
    const step = steps[activeStepIndex];
    /* eslint-disable */
    if (!step) debugger;
    /* eslint-enable */
    const { Component, props = {}, withCustomFooter } = step;

    const onSubmitFunction = isLastStep ? this.onSubmit : this.nextStep;

    const defaultFooterProps = {
      defaultPreviousButton,
      defaultNextButton,
      submitting,
      error,
      valid: formProps.valid,
      submitFailed,
      activeStepIndex
    };

    return (
      <div className="tg-step-form-container">
        <Prompt
          when={!formProps.pristine}
          message={newLocation => {
            if (location.pathname === newLocation.pathname) {
              return true;
            }
            return beforeLeaveConfirmationMessage;
          }}
        />
        {!isToolIntegrated && <Helmet title={toolTitle} />}
        <div className="tg-step-form-title-and-nav-container">
          <ToolTitle
            title={toolTitle}
            toolSchema={toolSchema}
            embedded={isToolIntegrated}
            stopTool={stopTool}
          />
          <div className="tg-step-form-nav">{this.renderStepNav()}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmitFunction)}>
          {activeStepIndex === 0 && (
            <React.Fragment>
              {selectionWarning}
              {disabledWarning}
            </React.Fragment>
          )}
          <br></br>
          <TableFormTrackerContext.Provider
            value={
              tableFormContextValue || {
                formNames: this.nestedTableFormNames,
                pushFormName: this.addNestedTableFormName,
                isActive: true
              }
            }
          >
            <Component
              {...props}
              toolSchema={toolSchema}
              submitting={submitting}
              toolIntegrationProps={toolIntegrationProps}
              isToolIntegrated={toolIntegrationProps.isToolIntegrated}
              nextStep={this.nextStep}
              handleSubmit={handleSubmit}
              onSubmit={onSubmitFunction}
              previousStep={this.previousStep}
              setActiveStep={this.setActiveStep}
              footerProps={defaultFooterProps}
              Footer={StepFormFooter}
              stepFormProps={formProps}
            />
          </TableFormTrackerContext.Provider>

          {!withCustomFooter && <StepFormFooter {...defaultFooterProps} />}
        </form>
      </div>
    );
  }
}

export default compose(
  connect(
    (state, ownProps) => {
      const toolCode = get(ownProps, "toolSchema.code");
      if (!ownProps.form && toolCode) {
        return {
          form: toolCode
        };
      }
    },
    {
      destroyForms: destroy
    }
  ),
  withHandlers({
    validate:
      ({ steps, validate: _validate }) =>
      (...args) => {
        let finalErrors = {};
        if (steps) {
          steps.forEach(step => {
            if (step.validate) {
              const errors = step.validate(...args);
              finalErrors = { ...finalErrors, ...errors };
            }
          });
        }
        if (_validate) {
          finalErrors = { ...finalErrors, ..._validate(...args) };
        }
        return finalErrors;
      },
    warn:
      ({ steps, warn: _warn }) =>
      (...args) => {
        let finalWarnings = {};
        if (steps) {
          steps.forEach(step => {
            if (step.warn) {
              const warnings = step.warn(...args);
              finalWarnings = { ...finalWarnings, ...warnings };
            }
          });
        }
        if (_warn) {
          finalWarnings = { ...finalWarnings, ..._warn(...args) };
        }
        return finalWarnings;
      }
  }),
  // used to check if a tool is partially complete
  withProps(props => ({
    initialValues: { ...props.initialValues, inProgress: true }
  })),
  // withHandlers({
  //   validate: ({ activeStepIndex, steps, validate: _validate }) => {
  //     return (
  //       (steps && steps[activeStepIndex] && steps[activeStepIndex].validate) ||
  //       _validate ||
  //       (() => {})
  //     ); //use the step specific validation if passed
  //   }
  // }),
  reduxForm({
    onSubmitFail: function (errors, dispatch, submitError) {
      console.error("Error hit in stepForms onSubmit:", errors); //this is a little hack so that we can see the stack trace when onsubmit fails
      console.error("submitError:", submitError);
      setTimeout(() => {
        try {
          const selector = `.tg-step-form-container .${Classes.FORM_GROUP} .${Classes.INTENT_DANGER}`;
          const erroredEl = document.querySelector(selector);
          const globalBottomError = document.querySelector(
            ".tg-step-form-error"
          );
          const stepFormContainer = document.getElementsByClassName(
            "tg-step-form-container"
          )[0];
          const workflowRunContainer =
            document.getElementsByClassName("workflow-run-body")[0];
          const containerEl = workflowRunContainer || stepFormContainer;
          if (containerEl) {
            if (erroredEl) {
              scrollIntoView(erroredEl, containerEl, {
                alignWithTop: true,
                offsetTop: 50 // size of platform header
              });
            } else if (globalBottomError) {
              scrollIntoView(globalBottomError, containerEl, {
                alignWithTop: true,
                offsetTop: 50 // size of platform header
              });
            }
          }
        } catch (error) {
          console.error("error:", error);
        }
      }, 100);
    },
    // shouldError: customShouldError,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    enableReinitialize: true,
    updateUnregisteredFields: true,
    touchOnChange: true,
    touchOnBlur: true
  }),
  tgFormValues("activeStepIndex"),
  withRouter,
  AddWorkflowParamsContextHOC
)(StepForm);
