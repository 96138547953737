/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const Analyses = {
  OneWayAnova: {
    name: "OneWayAnova",
    label: "One Way Anova",
    inputs: [
      {
        id: 1,
        name: "factor_column_name",
        label: "Factor column name",
        type: "selectField"
      },
      {
        id: 2,
        name: "value_column_name",
        label: "Value column name",
        type: "selectField"
      }
    ]
  },
  TwoWayAnova: {
    name: "TwoWayAnova",
    label: "Two Way Anova",
    inputs: [
      {
        id: 1,
        name: "factor_1_column_name",
        label: "First factor column name",
        type: "selectField"
      },
      {
        id: 2,
        name: "factor_2_column_name",
        label: "Second factor column name",
        type: "selectField"
      },
      {
        id: 3,
        name: "value_column_name",
        label: "Value column name",
        type: "selectField"
      }
    ]
  },
  ShapiroWilkNormality: {
    name: "ShapiroWilkNormality",
    label: "Shapiro - Wilk Normality Test",
    inputs: [
      {
        id: 1,
        name: "value_column_name",
        label: "Value column name",
        type: "selectField"
      }
    ]
  },
  OneWayTukeyHSDPairwise: {
    name: "OneWayTukeyHSDPairwise",
    label: "One Way Tukey HSD Pairwise Test",
    inputs: [
      {
        id: 1,
        name: "factor_column_name",
        label: "Factor column name",
        type: "selectField"
      },
      {
        id: 2,
        name: "value_column_name",
        label: "Value column name",
        type: "selectField"
      },
      {
        id: 3,
        name: "significance_level",
        label: "Significance level",
        type: "numeric",
        min: 0,
        max: 1,
        default: 0.05
      }
    ]
  },
  OneWayLeveneVarianceHomegeneity: {
    name: "OneWayLeveneVarianceHomegeneity",
    label: "One Way Levene Variance Homegeneity Test",
    inputs: [
      {
        id: 1,
        name: "factor_column_name",
        label: "Factor column name",
        type: "selectField"
      },
      {
        id: 2,
        name: "value_column_name",
        label: "Value column name",
        type: "selectField"
      },
      {
        id: 3,
        name: "center",
        label: "Center parameter",
        type: "selectOption",
        options: [
          { value: "mean", label: "Mean", name: "mean" },
          { value: "median", label: "Median", name: "median" },
          { value: "trimmed", label: "Trimmed", name: "trimmed" }
        ]
      },
      {
        id: 4,
        name: "proportioncut",
        label: "Proportion Cut",
        type: "numeric",
        min: 0,
        max: 1,
        default: 0.05
      }
    ]
  }
};

export default Object.freeze(Analyses);
