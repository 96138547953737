/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { MenuItem } from "@blueprintjs/core";
import React from "react";
import { hasIntegration } from "../../../../tg-iso-shared/src/utils/integrationTypeSettingsMap/importExportSubtypes";
import ImportFromExternalDbMenuOptions from "../../../src-shared/ExternalIntegrations/ImportFromExternalDbMenuOptions";
import { createPartsFromSeqsAndInsertThemIntoDesign } from "./ContextMenuHandlers/handleInsertSequenceFromExternalDbClick";

export default function InsertSequenceFromExternalDbMenuItem({
  multi,
  selectedInputCards,
  selectedCellPaths,
  createElements
}) {
  const withIntegration = hasIntegration("sequence", "DNA_SEQUENCE");

  const text = multi ? "Sequences" : "Sequence";

  const afterRecordUpsert = async importResults => {
    await createPartsFromSeqsAndInsertThemIntoDesign({
      cardId: selectedInputCards[0].id,
      sequences: importResults.filter(s => !s.__importFailed),
      selectedCellPaths,
      createElements
    });
  };

  return (
    <MenuItem text={`Insert ${text} From External DB`}>
      <ImportFromExternalDbMenuOptions
        subtype={withIntegration.key}
        afterRecordUpsert={afterRecordUpsert}
      />
    </MenuItem>
  );
}
