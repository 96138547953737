/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { get } from "lodash";
import { Button, Intent, Callout } from "@blueprintjs/core";
import QueryBuilder from "tg-client-query-builder";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../src-shared/stepFormValues";

import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import {
  recordExtendedValuesString,
  getExistingExtendedProperties
} from "../../../../src-shared/utils/extendedPropertyUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

export const containerArrayFragmentWithExtendedProperties = [
  "containerArray",
  `id
  name
  ${recordExtendedValuesString}
  containerArrayType {
    id
    name
  }
  aliquotContainers {
    id
    rowPosition
    columnPosition
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      ${recordExtendedValuesString}
      sample {
        id
        material {
          id
          materialTypeCode
          polynucleotideMaterialSequence {
            id
          }
        }
      }
    }
  }`
];

class SelectPlates extends Component {
  state = {
    loading: false
  };

  validateMaterials = async () => {
    const {
      containerArrays = [],
      stepFormProps: { change },
      nextStep
    } = this.props;
    this.setState({
      loading: true
    });

    try {
      const aliquotIds = [];
      containerArrays.forEach(containerArray => {
        containerArray.aliquotContainers.forEach(({ aliquot }) => {
          if (aliquot) {
            aliquotIds.push(aliquot.id);
          }
        });
      });
      const qb = new QueryBuilder("aliquot");
      const filter = qb
        .whereAll({
          id: qb.inList(aliquotIds)
        })
        .toJSON();

      const plateAliquots = await safeQuery(
        [
          "aliquot",
          `id
            isDry
            additives {
              id
              lot {
                additiveMaterial {
                  id
                  taggedItems {
                    id
                    tag {
                      id
                      name
                    }
                  }
                }
              }
              additiveMaterial {
                id
                taggedItems {
                  id
                  tag {
                    id
                    name
                  }
                }
              }
            }
            aliquotContainer {
              id
              columnPosition
              rowPosition
              containerArray {
                id
                name
              }
            }
          `
        ],
        {
          variables: { filter }
        }
      );

      const invalidPlateWellMap = {};
      plateAliquots.forEach(({ aliquotContainer, additives = [] }) => {
        const plateName = get(aliquotContainer, "containerArray.name");
        const hasLysateReagents = additives.some(additive => {
          const additiveMaterial =
            additive.additiveMaterial || additive.lot.additiveMaterial;
          if (additiveMaterial && additiveMaterial.taggedItems.length > 0) {
            return additiveMaterial.taggedItems.some(taggedItem => {
              return taggedItem.tag.name === "Lysate Reagent";
            });
          }
          return false;
        });
        if (!hasLysateReagents) {
          if (!invalidPlateWellMap[plateName]) {
            invalidPlateWellMap[plateName] = [];
          }
          invalidPlateWellMap[plateName].push(
            getAliquotContainerLocation(aliquotContainer)
          );
        }
      });

      const existingExtendedProperties = getExistingExtendedProperties(
        containerArrays
      );
      change("invalidPlateWellMap", invalidPlateWellMap);
      change("lysateMaterials", plateAliquots);
      change("existingExtendedProperties", existingExtendedProperties);
      nextStep();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error validating selected materials.");
      this.setState({
        loading: false
      });
    }
  };

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      containerArrayExtendedValues = [],
      aliquotExtendedValues = [],
      Footer,
      footerProps,
      handleSubmit
    } = this.props;

    const hasContainerArrayExtProp = containerArrayExtendedValues.find(v => {
      return (
        v.extendedProperty.name === "Lysate (Raw)" &&
        v.extendedProperty.extendedTypeCode === "boolean"
      );
    });
    const hasAliquotExtProp = aliquotExtendedValues.find(v => {
      return (
        v.extendedProperty.name === "Lysate (Raw)" &&
        v.extendedProperty.extendedTypeCode === "boolean"
      );
    });
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plates"
            helper="Select one or more plates of microbial
              materials. Clicking next will validate the selected
              aliquots to ensure they contain the appropriate
              reagents for Lysis."
            width="100%"
          />
          <GenericSelect
            {...{
              name: "containerArrays",
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              isMultiSelect: true,
              isRequired: true,
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                dateModifiedColumn
              ],
              fragment: [
                "containerArray",
                "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
              ],
              additionalDataFragment: containerArrayFragmentWithExtendedProperties,
              postSelectDTProps: {
                formName: "lysisSelectedPlates",
                schema: platePreviewSchema
              }
            }}
          />
        </div>
        {!hasContainerArrayExtProp && (
          <Callout intent={Intent.DANGER} style={{ marginBottom: 10 }}>
            Could not find plate extended property with name "Lysate (Raw)" of
            type "flag". Please add one in App Settings.
          </Callout>
        )}
        {!hasAliquotExtProp && (
          <Callout intent={Intent.DANGER}>
            Could not find aliquot extended property with name "Lysate (Raw)" of
            type "flag". Please add one in App Settings.
          </Callout>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              loading={this.state.loading}
              disabled={!hasContainerArrayExtProp || !hasAliquotExtProp}
              intent={Intent.PRIMARY}
              onClick={handleSubmit(this.validateMaterials)}
              text="Validate Materials"
            />
          }
        />
      </React.Fragment>
    );
  }
}

const platePreviewSchema = [
  platePreviewColumn(),
  "name",
  {
    path: "containerArrayType.name",
    displayName: "Plate Type"
  }
];

export default compose(
  stepFormValues(
    "containerArrays",
    "containerArrayExtendedValues",
    "aliquotExtendedValues"
  )
)(SelectPlates);
