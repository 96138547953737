/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect } from "react";
import {
  DataTable,
  withTableParams,
  withSelectedEntities,
  withSelectTableRecords
} from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { compose, withProps } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { constructSelectionConstructFragment } from "../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { assemblyPieceTableName, constructsTableName } from "../constants";
import { addInInventoryFilter, getBuildableConstructIds } from "../utils";
import InventoryCheckButton from "../../../InventoryCheckButton";
import InInventorySelectFilter from "../InInventorySelectFilter";
import { safeQuery } from "../../../../../src-shared/apolloMethods";

function Constructs({
  tableParams,
  selectTableRecords,
  buildableConstructIds = [],
  [constructsTableName + "SelectedEntities"]: selectedConstructs = []
}) {
  useEffect(() => {
    const buildableSelectedConstructs = selectedConstructs.filter(c =>
      buildableConstructIds.includes(c.id)
    );
    if (selectedConstructs.length !== buildableSelectedConstructs.length) {
      selectTableRecords(buildableSelectedConstructs);
    }
  }, [selectedConstructs, buildableConstructIds, selectTableRecords]);

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Buildable Constructs"
          helper="The following constructs are buildable based on the selection of assembly pieces above. Select the constructs you would like to build."
        />
        <DataTable
          {...tableParams}
          className="tg-buildable-constructs-table"
          maxHeight={400}
          keepDirtyOnReinitialize
          withSelectAll
          safeQuery={safeQuery}
          withCheckboxes
        >
          <InInventorySelectFilter
            name="constructInventoryFilter"
            tableParams={tableParams}
            selectTableRecords={selectTableRecords}
          />
        </DataTable>
      </div>
    </div>
  );
}

const schema = {
  model: "j5RunConstructView",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      type: "number",
      displayName: "Size",
      path: "size"
    },
    {
      path: "containerCount",
      width: 130,
      displayName: "Inventory",
      filterDisabled: true,
      noEllipsis: true,
      immovable: true,
      render: (v, r) => {
        if (v <= 0) return "Not in Inventory";
        return (
          <InventoryCheckButton
            record={{
              id: r.id,
              __typename: "j5RunConstruct"
            }}
          />
        );
      }
    }
  ]
};

export default compose(
  stepFormValues("j5Reports", "finalizedAPIds"),
  withSelectedEntities(assemblyPieceTableName),
  withSelectedEntities(constructsTableName),
  withProps(props => {
    const { j5Reports, finalizedAPIds = [] } = props;
    return {
      buildableConstructIds: getBuildableConstructIds({
        assemblyPieceIds: finalizedAPIds,
        j5Reports
      })
    };
  }),
  withTableParams({
    formName: constructsTableName,
    urlConnected: false,
    schema,
    additionalFilter: ({ buildableConstructIds }, qb, currentParams) => {
      const filter = {
        id: buildableConstructIds
      };
      addInInventoryFilter(qb, currentParams);
      qb.whereAll(filter);
    }
  }),
  withQuery(constructSelectionConstructFragment, {
    isPlural: true
  }),
  withSelectTableRecords(constructsTableName)
)(Constructs);
