/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import "./style.css";
import { InputField } from "@teselagen/ui";
import { RemoveButton } from "../../FieldArrayButtons";

class CategoricalItem extends React.Component {
  render() {
    const { field, fields, index, isEditing } = this.props;
    return (
      <div className="categorical-item">
        <InputField
          className="categorical-item-name-field"
          name={`${field}.name`}
          label="Option Name"
        />
        {!isEditing && (
          <div className="categorical-item-remove">
            <RemoveButton fields={fields} index={index} />
          </div>
        )}
      </div>
    );
  }
}

export default CategoricalItem;
