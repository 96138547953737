/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { useDragLayer } from "react-dnd";
import { getPositionFromAlphanumericLocation } from "../../../../tg-iso-lims/src/utils/plateUtils";

import "./style.css";

import Well from "./Well";

type DragLayerProps = {
  fullRadius: number;
  is2DLabeled: boolean;
  getXAndYofWell: (col: number, row: number) => { x: number; y: number };
};

const CustomDragLayer = ({
  is2DLabeled,
  getXAndYofWell,
  fullRadius
}: DragLayerProps) => {
  // TODO: Type on item are very weird, leaving as is for now
  const { item, itemType, currentOffset, isDragging } = useDragLayer(
    monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  const renderWells = () => {
    const { location, selectedItemsProps } = item;
    const { x, y } = currentOffset || { x: 0, y: 0 };
    const { columnPosition: dragCol, rowPosition: dragRow } =
      getPositionFromAlphanumericLocation(location);

    return Object.entries(selectedItemsProps).map(
      ([loc, selectedItemProps], i) => {
        const { columnPosition: col, rowPosition: row } =
          getPositionFromAlphanumericLocation(loc);
        const { x: wellX, y: wellY } = getXAndYofWell(
          col - dragCol,
          row - dragRow
        );

        const offset2d = is2DLabeled ? fullRadius : 0;
        const finalX = x + wellX + offset2d;
        const finalY = y + wellY + offset2d;
        return (
          <Well
            key={i}
            isDragLayer
            sharedProps={{
              // @ts-ignore
              ...selectedItemProps,
              [is2DLabeled ? "cx" : "x"]: finalX,
              [is2DLabeled ? "cy" : "y"]: finalY,
              opacity: 0.5
            }}
            aliquotContainer={{
              // @ts-ignore
              id: selectedItemProps.id,
              columnPosition: col,
              rowPosition: row
            }}
            is2DLabeled={is2DLabeled}
          />
        );
      }
    );
  };

  if (!isDragging || !currentOffset || itemType !== "aliquot") return null;

  return <svg className="plate-edit-view-drag-layer">{renderWells()}</svg>;
};

export default CustomDragLayer;
