/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, identity } from "lodash";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

const SearchTypeFunctions = qb => ({
  bp_eq_: identity,
  bp_in_: qb.contains,
  bp_sw_: qb.startsWith,
  bp_ew_: qb.endsWith
});

export const SEQUENCE_MODEL = "sequence";
export const SEQ_SEARCH_VIEW_MODEL = "seqSearchView";

export const SEARCH_TYPE_KEYS = Object.keys(SearchTypeFunctions({}));

export const NO_REVERSE_PREFIX = "norev_";

export const NO_CIRCULAR_PREFIX = "nocirc_";

export function buildSequenceFilter({ searchSequence, searchTypeKey, qb }) {
  searchSequence = searchSequence.slice(searchTypeKey.length);
  let noRev = false;
  let noCirc = false;

  // NOTE: the NO_REVERSE_PREFIX must always come prefixed to the NO_CIRCULAR_PREFIX
  // addition/removal of these prefixes should follow a FILO (First-In-Last-Out) approach.
  if (searchSequence.startsWith(NO_REVERSE_PREFIX)) {
    noRev = true;
    searchSequence = searchSequence.slice(NO_REVERSE_PREFIX.length);
  }
  if (searchSequence.startsWith(NO_CIRCULAR_PREFIX)) {
    noCirc = true;
    searchSequence = searchSequence.slice(NO_CIRCULAR_PREFIX.length);
  }
  const queries = [];
  const searchFunction = SearchTypeFunctions(qb)[searchTypeKey];

  // Depending on which model the qb is pointing to ("sequence" or "seqSearchView"),
  // the field to query for will vary. The CLI search/sequence endpoint is set to query the
  // "seqSearchView" which allows looking for circular matches.
  // However the "Search By Sequence" tool available from the UI the Sequence Library, does not support
  // looking for circular matches because it uses the "sequence" model. We could in theory use the "sequence" model
  // but running the query on "sequence.seqSearchView.bps" can be under-performant or even sql crashes due to graphql subQuery issues.
  const fieldToQuery = getFieldToQuery(qb);

  queries.push({
    [fieldToQuery]: searchFunction(searchSequence)
  });
  !noRev &&
    queries.push({
      [fieldToQuery]: searchFunction(
        getReverseComplementSequenceString(searchSequence)
      )
    });

  qb.whereAny(...queries);

  // If non-circular (linearOnly) searches are specifically specified,
  // then do not look for circular sequences :P
  noCirc && qb.andWhereAll({ circular: false });

  return qb;
}

// TODO: figure out a way for the Sequence Library to run filter on the
// "seqSearchView" model instead of the "sequence" model.
function getFieldToQuery(qb) {
  const qbModel = get(qb, "query.entity");
  if (qbModel === SEQ_SEARCH_VIEW_MODEL) {
    // "seqSearchView" has the 'bps' field which is equal to the sequence.fullSequence field
    // when the sequence is linear (circular=false) and equal to
    // "sequence.fullSequence + sequence.fullSequence" (fullSequence concatenated)
    // when the sequence is circular (circular=true).
    return "bps";
  } else if (qbModel === "aminoAcidSequence") {
    return "proteinSequence";
  }
  return "fullSequence";
}
