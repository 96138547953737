/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Classes } from "@blueprintjs/core";
import {
  DataTable,
  DialogFooter,
  withTableParams,
  withSelectedEntities
} from "@teselagen/ui";
import { compose } from "recompose";
import withQuery from "../../../withQuery";
import gql from "graphql-tag";
import client from "../../../apolloClient";
import { safeUpsert } from "../../../apolloMethods";
import asPromiseDialog from "../../../utils/asPromiseDialog";

class UpdateVendorOrderDialog extends Component {
  state = { isOpen: true };

  onCancel = () => {
    const { resolve } = this.props;
    resolve();
  };

  onConfirm = async () => {
    const { resolve, updateVendorOrderTableSelectedEntities = [] } = this.props;
    try {
      if (updateVendorOrderTableSelectedEntities.length) {
        await safeUpsert(
          "vendorOrder",
          updateVendorOrderTableSelectedEntities.map(order => ({
            id: order.id,
            orderStatusCode: "COMPLETE_STATUS"
          }))
        );
      }
    } catch (error) {
      window.toastr.error("Error updating orders. Please update manually.");
      console.error("error:", error);
    }
    resolve();
  };

  render() {
    const { tableParams, sequenceIds } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <div style={{ marginBottom: 15 }}>
            Please select an vendor orders you would like to mark as complete.
          </div>
          <DataTable
            {...tableParams}
            noPadding
            sequenceIds={sequenceIds}
            withCheckboxes
          />
        </div>
        <DialogFooter
          secondaryAction={this.onCancel}
          onClick={this.onConfirm}
        />
      </React.Fragment>
    );
  }
}

const fragment = gql`
  fragment updateVendorOrderStatusFragment on vendorOrder {
    id
    name
    orderStatusCode
    lineItems {
      id
      sequenceId
    }
  }
`;

const schema = {
  model: "vendorOrder",
  fields: [
    "name",
    {
      displayName: "Number of Uploaded Sequences",
      filterDisabled: true,
      sortDisabled: true,
      render: (v, record, _, props) => {
        const matching = record.lineItems.filter(li =>
          props.sequenceIds.includes(li.sequenceId)
        );
        return `${matching.length} / ${record.lineItems.length}`;
      }
    }
  ]
};

export default compose(
  asPromiseDialog,
  withTableParams({
    formName: "updateVendorOrderTable",
    urlConnected: false,
    schema: schema,
    additionalFilter: (props, qb) => {
      qb.whereAll({
        orderStatusCode: ["DRAFT_STATUS", "SUBMITTED_STATUS"],
        "lineItems.sequenceId": props.sequenceIds
      });
    }
  }),
  withQuery(fragment, {
    isPlural: true,
    options: {
      client
    }
  }),
  withSelectedEntities("updateVendorOrderTable")
)(UpdateVendorOrderDialog);
