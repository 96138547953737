/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SelectLocation } from "./Steps/SelectLocation";
import { SelectContent } from "./Steps/SelectContent";
import StepForm from "../../../../src-shared/StepForm";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import uploadDnaSequences from "../../../../../tg-iso-shared/src/sequence-import-utils/uploadDnaSequences";
import SuccessPage from "./SuccessPage";
import {
  CasEnzymeFragment,
  GenomeFragment,
  GenomeGenomicRegionFragment,
  GuideRnaFragment,
  SequenceFeatureFragment,
  SequenceFeatureWithSequenceFragment
} from "./Steps/fragments.gql.generated";
import type { RecursiveRequired } from "../../../../src-shared/typescriptHelpers";
import { HomologyArmsMode } from "./Steps/SelectContent";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import DuplicatesDialog from "./DuplicateDialog";
import type { UploadDnaSequencesOutput } from "./DuplicateDialog";

type Props = {
  initialValues: any;
  isToolIntegrated: boolean;
  toolIntegrationProps: any;
  toolSchema: any;
};

type FormData = {
  name: string;
  genome: RecursiveRequired<GenomeFragment>;
  genomeGenomicRegion: RecursiveRequired<GenomeGenomicRegionFragment>;
  casEnzyme: RecursiveRequired<CasEnzymeFragment>;
  guideRna: RecursiveRequired<GuideRnaFragment>;
  hdrDonorContent: RecursiveRequired<SequenceFeatureWithSequenceFragment>;
  createOutputGenomicRegion: boolean;
} & (
  | {
      homologyArmsMode: HomologyArmsMode.FromHA;
      homologyArm5Prime: RecursiveRequired<SequenceFeatureFragment>;
      homologyArm3Prime: RecursiveRequired<SequenceFeatureFragment>;
      targetRegion: never;
      homologyArmsSize: never;
    }
  | {
      homologyArmsMode: HomologyArmsMode.FromTargetRegion;
      homologyArm5Prime: never;
      homologyArm3Prime: never;
      targetRegion: RecursiveRequired<SequenceFeatureFragment>;
      homologyArmsSize: number;
    }
);

const CRISPRKnockinsDesignTool = ({
  initialValues,
  isToolIntegrated,
  toolIntegrationProps,
  toolSchema
}: Props) => {
  const steps = [
    {
      title: "Select where to knock in",
      Component: SelectLocation
    },
    {
      title: "Select content",
      Component: SelectContent,
      props: {
        genomicRegion: "genomicRegion",
        guideRna: "guideRna"
      }
    }
  ];

  const validateValues = ({
    homologyArmsMode,
    homologyArm3Prime,
    homologyArm5Prime,
    hdrDonorContent,
    targetRegion,
    genomeGenomicRegion
  }: Partial<FormData>) => {
    const errors: { [K in keyof FormData]?: string } = {};

    // Validate that target region does not span to the start or end of the genomic region
    if (genomeGenomicRegion && targetRegion) {
      if (targetRegion.start === 0) {
        errors.targetRegion =
          "Target region cannot span to the beginning of the genomic region";
      }
      if (genomeGenomicRegion.genomicRegion.size - targetRegion.end - 1 === 0) {
        errors.targetRegion =
          "Target region cannot span to the end of the genomic region";
      }
    }

    // Validate strands of homology arms and HDR donor content
    if (homologyArmsMode === "fromHA") {
      if (!homologyArm5Prime || !homologyArm3Prime || !hdrDonorContent)
        return errors;

      if (
        homologyArm5Prime.strand !== homologyArm3Prime.strand ||
        homologyArm5Prime.strand !== hdrDonorContent.strand
      ) {
        errors.homologyArm5Prime =
          "Homology arms and HDR donor content must be on the same strand";
        errors.homologyArm3Prime =
          "Homology arms and HDR donor content must be on the same strand";
        errors.hdrDonorContent =
          "Homology arms and HDR donor content must be on the same strand";
      }
    } else if (homologyArmsMode === "fromTargetRegion") {
      if (!targetRegion || !hdrDonorContent) return errors;
      if (targetRegion.strand !== hdrDonorContent.strand) {
        errors.targetRegion =
          "Target region and HDR donor content must be on the same strand";
        errors.hdrDonorContent =
          "Target region and HDR donor content must be on the same strand";
      }
    }

    return errors;
  };

  const onSubmit = async ({
    name,
    genome,
    genomeGenomicRegion,
    casEnzyme,
    guideRna,
    homologyArmsMode, // "fromHa" | "fromTargetRegion"
    homologyArm5Prime, // Feature | undefined
    homologyArm3Prime, // Feature | undefined
    targetRegion, // Feature | undefined
    homologyArmsSize, // number | undefined
    hdrDonorContent, // Feature,
    createOutputGenomicRegion
  }: FormData) => {
    const genomicRegion = genomeGenomicRegion.genomicRegion;
    const hdrDonorContentSequence = hdrDonorContent.sequence.fullSequence.slice(
      hdrDonorContent.start,
      hdrDonorContent.end + 1
    );

    let homologyArm3PrimeStart, homologyArm3PrimeEnd;
    let homologyArm5PrimeStart, homologyArm5PrimeEnd;

    if (homologyArmsMode === "fromHA") {
      homologyArm5PrimeStart = homologyArm5Prime.start;
      homologyArm5PrimeEnd = homologyArm5Prime.end;
      homologyArm3PrimeStart = homologyArm3Prime.start;
      homologyArm3PrimeEnd = homologyArm3Prime.end;
    } else {
      homologyArm5PrimeStart = targetRegion.start - homologyArmsSize;
      homologyArm5PrimeEnd = targetRegion.start - 1;
      homologyArm3PrimeStart = targetRegion.end + 1;
      homologyArm3PrimeEnd = targetRegion.end + homologyArmsSize;
    }
    const homologyArm5PrimeSequence = genomicRegion.fullSequence.slice(
      homologyArm5PrimeStart,
      homologyArm5PrimeEnd + 1
    );
    const homologyArm3PrimeSequence = genomicRegion.fullSequence.slice(
      homologyArm3PrimeStart,
      homologyArm3PrimeEnd + 1
    );

    // create output repair template sequence
    const repairTemplateSequenceRes = (await uploadDnaSequences({
      sequenceTexts: [
        homologyArm5PrimeSequence +
          hdrDonorContentSequence +
          homologyArm3PrimeSequence
      ],
      sequenceNames: [name + " - repair template"]
    })) as UploadDnaSequencesOutput;

    const repairTemplateSequenceId = repairTemplateSequenceRes.allSeqIds[0];

    // annotate the repair template sequence
    await safeUpsert("sequenceFeature", [
      {
        name: `Homology Arm 5' - ${name}`,
        sequenceId: repairTemplateSequenceId,
        start: 0,
        end: homologyArm5PrimeSequence.length - 1,
        strand: hdrDonorContent.strand
      },
      {
        name: `HDR Donor Content - ${name}`,
        sequenceId: repairTemplateSequenceId,
        start: homologyArm5PrimeSequence.length,
        end:
          homologyArm5PrimeSequence.length + hdrDonorContentSequence.length - 1,
        strand: hdrDonorContent.strand
      },
      {
        name: `Homology Arm 3' - ${name}`,
        sequenceId: repairTemplateSequenceId,
        start:
          homologyArm5PrimeSequence.length + hdrDonorContentSequence.length,
        end:
          homologyArm5PrimeSequence.length +
          hdrDonorContentSequence.length +
          homologyArm3PrimeSequence.length -
          1,
        strand: hdrDonorContent.strand
      }
    ]);

    let outputGenomicRegionRes: UploadDnaSequencesOutput | undefined;
    if (createOutputGenomicRegion) {
      outputGenomicRegionRes = (await uploadDnaSequences({
        promptForDuplicates: false, // No prompt, and if duplicated adds an alias
        isGenomicRegionUpload: true,
        sequenceNames: [name + " - edited genomic region"],
        sequenceTexts: [
          genomicRegion.fullSequence.slice(0, homologyArm5PrimeEnd + 1) +
            hdrDonorContentSequence +
            genomicRegion.fullSequence.slice(homologyArm3PrimeStart)
        ]
      })) as UploadDnaSequencesOutput;
      const outputGenomicRegionId = outputGenomicRegionRes.allSeqIds[0];

      // annotate the output genomic region
      await safeUpsert("sequenceFeature", [
        {
          name: `Homology Arm 5' - ${name}`,
          sequenceId: outputGenomicRegionId,
          start: homologyArm5PrimeStart,
          end: homologyArm5PrimeEnd,
          strand: hdrDonorContent.strand
        },
        {
          name: `HDR Donor Content - ${name}`,
          sequenceId: outputGenomicRegionId,
          start: homologyArm5PrimeEnd + 1,
          end: homologyArm5PrimeEnd + hdrDonorContentSequence.length,
          strand: hdrDonorContent.strand
        },
        {
          name: `Homology Arm 3' - ${name}`,
          sequenceId: outputGenomicRegionId,
          start: homologyArm5PrimeEnd + hdrDonorContentSequence.length + 1,
          end:
            homologyArm5PrimeEnd +
            hdrDonorContentSequence.length +
            homologyArm3PrimeSequence.length,
          strand: hdrDonorContent.strand
        }
      ]);
    }

    const [createdCrisprDesign] = await safeUpsert("crisprDesign", {
      name,
      targetGenomeId: genome.id,
      targetGenomicRegionId: genomeGenomicRegion.genomicRegion.id,
      casEnzymeId: casEnzyme.id,
      guideRnaId: guideRna.id,
      hdrDonorContentId: hdrDonorContent.id,
      homologyArm5PrimeStart,
      homologyArm5PrimeEnd,
      homologyArm3PrimeStart,
      homologyArm3PrimeEnd,
      repairTemplateSequenceId,
      outputGenomicRegionId: outputGenomicRegionRes?.allSeqIds[0]
    });

    if (
      repairTemplateSequenceRes.duplicateSequences.length ||
      outputGenomicRegionRes?.duplicateSequences.length
    ) {
      showDialog({
        ModalComponent: DuplicatesDialog,
        modalProps: {
          repairTemplateSequenceDuplicates:
            repairTemplateSequenceRes.duplicateSequences,
          outputGenomicRegionDuplicates:
            outputGenomicRegionRes?.duplicateSequences
        }
      });
    }

    return { crisprDesign: createdCrisprDesign };
  };

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateValues}
      successPageInnerContent={SuccessPage}
    />
  );
};

export { CRISPRKnockinsDesignTool };
