/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "redux";
import { difference, noop, keyBy } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  TextareaField,
  DialogFooter,
  RadioGroupField,
  ReactSelectField,
  wrapDialog
} from "@teselagen/ui";

import withQuery from "../../../../src-shared/withQuery";

import shortid from "shortid";

import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";

import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";

class PlacementStrategyConfigurationDialog extends React.Component {
  onSubmit = async values => {
    const {
      refetch = noop,
      hideModal,
      history,
      placementContainerTypes = [],
      initialValues: {
        containerArrayTypeIds: initialContainerArrayTypeIds,
        aliquotContainerTypeCodes: initialAliquotContainerTypeCodes,
        placementContainerTypes: initialPlacementContainerTypes
      } = {}
    } = this.props;

    const {
      id: updateId,
      name,
      description,
      strategyType,
      containerArrayTypeIds = [],
      aliquotContainerTypeCodes = [],
      destinationContainerType,
      tubePlacementOption,
      destinationContainerArrayTypeId
    } = values;
    const isContainerArrayStrategy = strategyType === "plate";
    const isDestinationContainerArray = destinationContainerType === "box";
    try {
      if (updateId) {
        const updateContainerType = [];
        if (containerArrayTypeIds.length) {
          containerArrayTypeIds.forEach(containerArrayTypeId => {
            updateContainerType.push({
              placementStrategyId: updateId,
              containerArrayTypeId
            });
          });
        } else {
          aliquotContainerTypeCodes.forEach(aliquotContainerTypeCode => {
            updateContainerType.push({
              placementStrategyId: updateId,
              aliquotContainerTypeCode
            });
          });
        }
        await safeDelete(
          "placementContainerType",
          initialPlacementContainerTypes.map(pct => pct.id)
        );
        await safeUpsert(["placementStrategy", "id name description"], {
          id: updateId,
          name,
          description
        });
        await safeUpsert("placementContainerType", updateContainerType);
        await refetch();
        hideModal();
      } else {
        const cid = shortid();
        const [{ id }] = await safeUpsert("placementStrategy", {
          id: updateId,
          name,
          description,
          isContainerArrayStrategy,
          isDestinationContainerArray,
          destinationContainerArrayTypeId,
          generateBoxes: tubePlacementOption === "newBoxes",
          placementSections: [
            {
              cid,
              name: "Default Placement Section",
              index: 1,
              prioritySubSections: [
                {
                  name: "Default Priority Sub-Section",
                  rank: 1
                }
              ]
            }
          ]
        });
        await safeUpsert("placementStrategy", {
          id,
          defaultPlacementSectionId: "&" + cid
        });
        let newContainerArrayTypeIds = containerArrayTypeIds;
        let newAliquotContainerTypeCodes = aliquotContainerTypeCodes;

        if (initialContainerArrayTypeIds) {
          newContainerArrayTypeIds = difference(
            containerArrayTypeIds,
            initialContainerArrayTypeIds
          );
        }
        if (initialAliquotContainerTypeCodes) {
          newAliquotContainerTypeCodes = difference(
            aliquotContainerTypeCodes,
            initialAliquotContainerTypeCodes
          );
        }
        const removedContainerArrayTypeIds = difference(
          initialContainerArrayTypeIds,
          containerArrayTypeIds
        );
        const removedAliquotContainerTypeCodes = difference(
          initialAliquotContainerTypeCodes,
          aliquotContainerTypeCodes
        );

        const placementContainerTypesToDelete = placementContainerTypes.reduce(
          (acc, placementContainerType) => {
            if (
              removedContainerArrayTypeIds.includes(
                placementContainerType.containerArrayType.id
              )
            ) {
              acc.push(placementContainerType.id);
            }
            if (
              removedAliquotContainerTypeCodes.includes(
                placementContainerType.aliquotContainerType.code
              )
            ) {
              acc.push(placementContainerType.id);
            }
            return acc;
          },
          []
        );
        const containerTypes = [];
        if (isContainerArrayStrategy) {
          newContainerArrayTypeIds.forEach(containerArrayTypeId => {
            containerTypes.push({
              placementStrategyId: id,
              containerArrayTypeId
            });
          });
        } else {
          newAliquotContainerTypeCodes.forEach(aliquotContainerTypeCode => {
            containerTypes.push({
              placementStrategyId: id,
              aliquotContainerTypeCode
            });
          });
        }

        await safeDelete(
          "placementContainerType",
          placementContainerTypesToDelete
        );

        await safeUpsert("placementContainerType", containerTypes);
        await refetch();
        hideModal();
        history.push(`/placement-strategies/${id}`);
      }
    } catch (e) {
      console.error(e);
      window.toastr.error(`Error placement strategy.`);
    }
  };
  render() {
    const {
      handleSubmit,
      hideModal,
      submitting,
      aliquotContainerTypes = [],
      containerArrayTypes = [],
      strategyType,
      destinationContainerType,
      initialValues: {
        id: updateId,
        isContainerArrayStrategy,
        containerArrayTypeIds,
        aliquotContainerTypeCodes
      } = {}
    } = this.props;

    const boxAndRackTypes = containerArrayTypes.filter(
      containerArrayType => !containerArrayType.isPlate
    );
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" isRequired />
          <TextareaField name="description" label="Description" />
          {!updateId && (
            <RadioGroupField
              name="strategyType"
              label="Type"
              defaultValue="plate"
              options={[
                {
                  label: "Plate Placement",
                  value: "plate"
                },
                {
                  label: "Tube Placement",
                  value: "tube"
                }
              ]}
            />
          )}
          {!updateId && strategyType === "tube" && (
            <RadioGroupField
              name="destinationContainerType"
              label="Destination Type"
              defaultValue="box"
              options={[
                {
                  label: "Box/Rack Placement",
                  value: "box"
                },
                {
                  label: "Container Placement",
                  value: "container"
                }
              ]}
            />
          )}
          {!updateId &&
            strategyType === "tube" &&
            destinationContainerType === "box" && (
              <RadioGroupField
                name="tubePlacementOption"
                label="Placement Option"
                defaultValue="mixed"
                options={[
                  {
                    label:
                      "Place in existing boxes/racks (this will generate new boxes if more space is needed)",
                    value: "mixed"
                  },
                  {
                    label: "Only place in new boxes/racks",
                    value: "newBoxes"
                  }
                ]}
              />
            )}
          {strategyType === "plate" || isContainerArrayStrategy ? (
            <ReactSelectField
              name="containerArrayTypeIds"
              multi
              label="Plate Types"
              defaultValue={containerArrayTypeIds}
              options={arrayToIdOrCodeValuedOptions(containerArrayTypes)}
            />
          ) : (
            <ReactSelectField
              name="aliquotContainerTypeCodes"
              multi
              label="Tube Types"
              defaultValue={aliquotContainerTypeCodes}
              options={arrayToIdOrCodeValuedOptions(aliquotContainerTypes)}
            />
          )}
          {strategyType === "tube" && destinationContainerType === "box" && (
            <ReactSelectField
              name="destinationContainerArrayTypeId"
              label="Destination Box/Rack Type"
              options={arrayToIdOrCodeValuedOptions(boxAndRackTypes)}
            />
          )}
        </div>
        <DialogFooter {...{ hideModal, submitting }} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Configure Placement Strategy" }),
  withQuery(["aliquotContainerType", "code name isTube"], {
    isPlural: true,
    options: { variables: { filter: { isTube: true } } }
  }),
  withQuery(
    [
      "containerArrayType",
      "id name isPlate nestableTubeTypes { id aliquotContainerTypeCode }"
    ],
    { isPlural: true }
  ),
  reduxForm({
    enableReinitialize: true,
    form: "PlacementStrategyConfigurationDialog",
    validate,
    touchOnChange: true,
    touchOnBlur: true
  }),
  tgFormValues("strategyType", "destinationContainerType")
)(PlacementStrategyConfigurationDialog);

function validate(values, props) {
  const {
    aliquotContainerTypeCodes = [],
    containerArrayTypeIds = [],
    destinationContainerArrayTypeId
  } = values;
  const { containerArrayTypes = [], aliquotContainerTypes = [] } = props;
  const errors = {};
  if (aliquotContainerTypeCodes && aliquotContainerTypeCodes.length < 1)
    errors.aliquotContainerTypeCodes =
      "Please select at least one aliquot container type.";
  if (containerArrayTypeIds && containerArrayTypeIds.length < 1)
    errors.containerArrayTypeIds = "Please select at least one plate type.";

  if (
    !Array.isArray(destinationContainerArrayTypeId) &&
    destinationContainerArrayTypeId &&
    aliquotContainerTypeCodes.length
  ) {
    const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");
    const containerArrayType = containerArrayTypes.find(
      c => c.id === destinationContainerArrayTypeId
    );
    const acceptedTubeTypeCodes = containerArrayType.nestableTubeTypes.map(
      ntt => ntt.aliquotContainerTypeCode
    );
    const unnacceptedTubeTypeCodes = aliquotContainerTypeCodes.filter(
      c => !acceptedTubeTypeCodes.includes(c)
    );
    if (unnacceptedTubeTypeCodes.length) {
      const unnacceptedTypeNames = unnacceptedTubeTypeCodes.map(
        code => keyedAliquotContainerTypes[code].name
      );
      errors.destinationContainerArrayTypeId = `This box/rack type does not accept these tube types: ${unnacceptedTypeNames.join(
        ", "
      )}.`;
    }
  }
  return errors;
}
