/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * An array of codon tables supported by the emboss back tran seq tool
 */
export const embossAmbigCodonTables = [
  { label: "Standard Code", value: 0 },
  { label: "Vertebrate Mitochondrial", value: 2 },
  { label: "Yeast Mitochondrial", value: 3 },
  {
    label:
      "Mold, Protozoan, Coelenterate Mitochondrial and Mycoplasma/Spiroplasma",
    value: 4
  },
  { label: "Invertebrate Mitochondrial", value: 5 },
  { label: "Ciliate Macronuclear and Dasycladacean", value: 6 },
  { label: "Echinoderm Mitochondrial", value: 9 },
  { label: "Euplotid Nuclear", value: 10 },
  { label: "Bacterial", value: 11 },
  { label: "Alternative Yeast Nuclear", value: 12 },
  { label: "Ascidian Mitochondrial", value: 13 },
  { label: "Flatworm Mitochondrial", value: 14 },
  { label: "Blepharisma Macronuclear", value: 15 },
  { label: "Chlorophycean Mitochondrial", value: 16 },
  { label: "Trematode Mitochondrial", value: 21 },
  { label: "Scenedesmus obliquus", value: 22 },
  { label: "Thraustochytrium Mitochondrial", value: 23 }
];

export const embossCodonTables = [
  { label: "EHuman.cut", value: "Ehuman.cut" },
  { label: "Eacica.cut", value: "Eacica.cut" },
  { label: "Eagrtu.cut", value: "Eagrtu.cut" },
  { label: "Eanasp.cut", value: "Eanasp.cut" },
  { label: "Earath.cut", value: "Earath.cut" },
  { label: "Eazovi.cut", value: "Eazovi.cut" },
  { label: "Ebacme.cut", value: "Ebacme.cut" },
  { label: "Ebacst.cut", value: "Ebacst.cut" },
  { label: "Ebacsu.cut", value: "Ebacsu.cut" },
  { label: "Ebacsu_high.cut", value: "Ebacsu_high.cut" },
  { label: "Ebommo.cut", value: "Ebommo.cut" },
  { label: "Ebovin.cut", value: "Ebovin.cut" },
  { label: "Ebpphx.cut", value: "Ebpphx.cut" },
  { label: "Ebraja.cut", value: "Ebraja.cut" },
  { label: "Ebrana.cut", value: "Ebrana.cut" },
  { label: "Ebrare.cut", value: "Ebrare.cut" },
  { label: "Ecaeel.cut", value: "Ecaeel.cut" },
  { label: "Ecanal.cut", value: "Ecanal.cut" },
  { label: "Ecanfa.cut", value: "Ecanfa.cut" },
  { label: "Ecaucr.cut", value: "Ecaucr.cut" },
  { label: "Echick.cut", value: "Echick.cut" },
  { label: "Echlre.cut", value: "Echlre.cut" },
  { label: "Echltr.cut", value: "Echltr.cut" },
  { label: "Ecloab.cut", value: "Ecloab.cut" },
  { label: "Ecrigr.cut", value: "Ecrigr.cut" },
  { label: "Ecyapa.cut", value: "Ecyapa.cut" },
  { label: "Edicdi.cut", value: "Edicdi.cut" },
  { label: "Edicdi_high.cut", value: "Edicdi_high.cut" },
  { label: "Edrome.cut", value: "Edrome.cut" },
  { label: "Edrome_high.cut", value: "Edrome_high.cut" },
  { label: "Eecoli.cut", value: "Eecoli.cut" },
  { label: "Eecoli_high.cut", value: "Eecoli_high.cut" },
  { label: "Eemeni.cut", value: "Eemeni.cut" },
  { label: "Eemeni_mit.cut", value: "Eemeni_mit.cut" },
  { label: "Eemeni_high.cut", value: "Eemeni_high.cut" },
  { label: "Eerwct.cut", value: "Eerwct.cut" },
  { label: "Ehaein.cut", value: "Ehaein.cut" },
  { label: "Ehalma.cut", value: "Ehalma.cut" },
  { label: "Ehalsa.cut", value: "Ehalsa.cut" },
  { label: "Ehorvu.cut", value: "Ehorvu.cut" },
  { label: "Eklepn.cut", value: "Eklepn.cut" },
  { label: "Eklula.cut", value: "Eklula.cut" },
  { label: "Elacdl.cut", value: "Elacdl.cut" },
  { label: "Elyces.cut", value: "Elyces.cut" },
  { label: "Emacfa.cut", value: "Emacfa.cut" },
  { label: "Emaize.cut", value: "Emaize.cut" },
  { label: "Emammal_high.cut", value: "Emammal_high.cut" },
  { label: "Emanse.cut", value: "Emanse.cut" },
  { label: "Emarpo_chl.cut", value: "Emarpo_chl.cut" },
  { label: "Emedsa.cut", value: "Emedsa.cut" },
  { label: "Emetth.cut", value: "Emetth.cut" },
  { label: "Emouse.cut", value: "Emouse.cut" },
  { label: "Emyctu.cut", value: "Emyctu.cut" },
  { label: "Eneigo.cut", value: "Eneigo.cut" },
  { label: "Eneucr.cut", value: "Eneucr.cut" },
  { label: "Eoncmy.cut", value: "Eoncmy.cut" },
  { label: "Eorysa.cut", value: "Eorysa.cut" },
  { label: "Eorysa_chl.cut", value: "Eorysa_chl.cut" },
  { label: "Epea.cut", value: "Epea.cut" },
  { label: "Epethy.cut", value: "Epethy.cut" },
  { label: "Ephavu.cut", value: "Ephavu.cut" },
  { label: "Epig.cut", value: "Epig.cut" },
  { label: "Eplafa.cut", value: "Eplafa.cut" },
  { label: "Eprovu.cut", value: "Eprovu.cut" },
  { label: "Epseae.cut", value: "Epseae.cut" },
  { label: "Epsepu.cut", value: "Epsepu.cut" },
  { label: "Epsesm.cut", value: "Epsesm.cut" },
  { label: "Erabit.cut", value: "Erabit.cut" },
  { label: "Erat.cut", value: "Erat.cut" },
  { label: "Erhile.cut", value: "Erhile.cut" },
  { label: "Erhime.cut", value: "Erhime.cut" },
  { label: "Erhoca.cut", value: "Erhoca.cut" },
  { label: "Erhosh.cut", value: "Erhosh.cut" },
  { label: "Esalsa.cut", value: "Esalsa.cut" },
  { label: "Esalty.cut", value: "Esalty.cut" },
  { label: "Eschma.cut", value: "Eschma.cut" },
  { label: "Eschpo.cut", value: "Eschpo.cut" },
  { label: "Eschpo_cai.cut", value: "Eschpo_cai.cut" },
  { label: "Eschpo_high.cut", value: "Eschpo_high.cut" },
  { label: "Eserma.cut", value: "Eserma.cut" },
  { label: "Esheep.cut", value: "Esheep.cut" },
  { label: "Esoltu.cut", value: "Esoltu.cut" },
  { label: "Esoybn.cut", value: "Esoybn.cut" },
  { label: "Espiol.cut", value: "Espiol.cut" },
  { label: "Estaau.cut", value: "Estaau.cut" },
  { label: "Estrco.cut", value: "Estrco.cut" },
  { label: "Estrmu.cut", value: "Estrmu.cut" },
  { label: "Estrpn.cut", value: "Estrpn.cut" },
  { label: "Estrpu.cut", value: "Estrpu.cut" },
  { label: "Esv40.cut", value: "Esv40.cut" },
  { label: "Esynco.cut", value: "Esynco.cut" },
  { label: "Esyncy.cut", value: "Esyncy.cut" },
  { label: "Etetth.cut", value: "Etetth.cut" },
  { label: "Etheth.cut", value: "Etheth.cut" },
  { label: "Etobac.cut", value: "Etobac.cut" },
  { label: "Etobac_chl.cut", value: "Etobac_chl.cut" },
  { label: "Etrybr.cut", value: "Etrybr.cut" },
  { label: "Etrycr.cut", value: "Etrycr.cut" },
  { label: "Evibch.cut", value: "Evibch.cut" },
  { label: "Ewheat.cut", value: "Ewheat.cut" },
  { label: "Exenla.cut", value: "Exenla.cut" },
  { label: "Eyeast.cut", value: "Eyeast.cut" },
  { label: "Eyeast_cai.cut", value: "Eyeast_cai.cut" },
  { label: "Eyeast_high.cut", value: "Eyeast_high.cut" },
  { label: "Eyeast_mit.cut", value: "Eyeast_mit.cut" },
  { label: "Eyeren.cut", value: "Eyeren.cut" },
  { label: "Eyerpe.cut", value: "Eyerpe.cut" }
];
