/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, tgFormValues } from "@teselagen/ui";
import { connect } from "react-redux";
import { compose } from "redux";
import PromiseFileReader from "promise-file-reader";
import { withRouter } from "react-router-dom";
import download from "downloadjs";
import {
  FileUploadField,
  InputField,
  CheckboxField,
  NumericInputField
} from "@teselagen/ui";
import { anyToJson } from "@teselagen/bio-parsers";
import { groupBy, map } from "lodash";
import shortid from "shortid";
import exampleTSV from "../../../exampleFiles/exampleTSV.js";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import store from "../../../../src-shared/redux/store";
// import ParallelPartSequenceViewer from './ParallelPartSequenceViewer'

import actions from "../../../../src-shared/redux/actions";
import {
  canCreateMassivelyParallelDesign,
  massivelyParallelCreateDesign
} from "./parallelPartUtils";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";

import "./style.css";
import { ReactSelectField } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

const titles = ["Upload a File", "Define Parts"];
const widths = [undefined, 1600];
const defaultNameTemplate = "DNA Parts {{{incrementing_number}}}";

class ParallelPartDialog extends React.Component {
  state = {
    page: 0
  };

  renderPage = () => {
    const { useDefaultNameTemplate, change, tags = [] } = this.props;
    return (
      <React.Fragment>
        <InputField name="designName" label="Design Name" isRequired />
        <InputField
          name="nameTemplate"
          label="Name Template"
          isRequired
          defaultValue={defaultNameTemplate}
          disabled={!!useDefaultNameTemplate}
        />
        <div className="tg-flex justify-space-around">
          <NumericInputField
            name="incrementStart"
            label="Increment Start"
            min={1}
            defaultValue={1}
          />
          <NumericInputField
            name="numDigits"
            label="Number of Digits"
            min={1}
            max={10}
            defaultValue={4}
          />
        </div>
        <CheckboxField
          name="useDefaultNameTemplate"
          label="Use Default Name Template"
          defaultValue
          onFieldSubmit={val => {
            if (val) {
              change("nameTemplate", defaultNameTemplate);
            }
          }}
        />
        <ReactSelectField
          name="tagIds"
          label="Tags to apply to all generated parts"
          options={arrayToIdOrCodeValuedOptions(tags)}
          multi
        />
        Select a multi-sequence .tsv, .txt, or .fasta file, containing sequence
        names and DNA sequences, or upload multple genbank files.
        <FileUploadField
          isRequired
          accept={[
            { type: ".tsv", exampleFile: "alignedSequences.tsv" },
            { type: ".txt" },
            { type: ".fasta", exampleFile: "alignedSequences.fasta" },
            { type: ".gb", exampleFile: "pj5_0001.gb" }
          ]}
          name="inputFiles"
        />
      </React.Fragment>
    );
  };

  handleMultiFileUpload = async () => {
    const {
      inputFiles,
      addData,
      hideModal,
      history,
      designName,
      nameTemplate,
      incrementStart,
      numDigits,
      tagIds
    } = this.props;

    let seqLength = null;
    let sequences = [];
    const sequenceBpsToAnnotationsMap = {};

    const rowMapper = row => {
      const [name, seq] = row.split("\t");
      const sequence = seq.trim().toUpperCase();
      if (seqLength === null) {
        seqLength = sequence.length;
      } else if (seqLength !== sequence.length) {
        const msg = "All sequences must be the same length.";
        window.toastr.error(msg);
        throw new Error(msg);
      }
      return { name: name.trim(), sequence };
    };

    for (let i = 0; i < inputFiles.length; i++) {
      const { originFileObj } = inputFiles[i];

      if (
        originFileObj.name.endsWith(".fasta") ||
        originFileObj.name.endsWith(".gb")
      ) {
        const jsonResult = await anyToJson(originFileObj, {
          fileName: originFileObj.name.slice(
            originFileObj.name.indexOf("/") + 1
          ),
          acceptParts: true,
          primersAsFeatures: true
        });

        sequences = sequences.concat(
          jsonResult.map(entry => {
            const bps = entry.parsedSequence.sequence.trim().toUpperCase();
            sequenceBpsToAnnotationsMap[bps] = {
              parts: entry.parsedSequence.parts || [],
              features: entry.parsedSequence.features || []
            };

            return {
              name: entry.parsedSequence.name,
              sequence: bps
            };
          })
        );
      } else {
        const content = await PromiseFileReader.readAsText(originFileObj);
        sequences = sequences.concat(
          content
            .split(/\r?\n/)
            .map(x => {
              return x.trim();
            })
            .filter(x => x)
            .map(rowMapper)
        );
      }
    }

    let duplicateSeqError = "";
    const possibleGroupedDuplicates = groupBy(sequences, "sequence");
    map(possibleGroupedDuplicates, group => {
      if (group.length > 1) {
        const seqNames = group.map(seq => seq.name).join(", ");
        duplicateSeqError += `${seqNames} are duplicate sequences. `;
      }
    });
    if (duplicateSeqError.length) {
      window.toastr.error(duplicateSeqError);
      throw new Error(duplicateSeqError);
    }

    const options = {
      alignmentType: "MULTIPLE_SEQUENCE_ALIGNMENT",
      alignmentAlgorithm: "mafft",
      processId: shortid(),
      isPairwiseAlignment: false,
      name: sequences[0].name
    };

    const standardAlignmentData = {
      addedSequences: sequences,
      options
    };
    const response = await window.serverApi.post("/handleAlignments", {
      standardAlignmentData
    });

    const alignedSequences = response.data.result.map(seq => {
      return {
        ...seq.alignmentData,
        ...sequenceBpsToAnnotationsMap[seq.sequenceData.sequence]
      };
    });

    if (!response.data.success) {
      const error = response.data.err;
      console.error(error);
      throw new Error(error);
    }

    const alignmentRun = {
      id: shortid(),
      alignmentTracks: sequences.map((seq, i) => {
        const alignmentData = {
          id: shortid(),
          ...alignedSequences[i]
        };
        return {
          sequenceData: {
            id: shortid(),
            ...seq,
            sequence: alignedSequences[i].sequence
          },
          alignmentData
        };
      })
    };

    addData({
      id: alignmentRun.id,
      data: {
        alignmentRun,
        sequences,
        alignedSequences,
        designName,
        nameTemplate,
        incrementStart,
        numDigits,
        tagIds
      }
    });
    hideModal();
    history.push(`/massively-parallel-part-creation/${alignmentRun.id}`);
  };

  handleSingleFileUpload = async () => {
    const {
      inputFiles,
      addData,
      hideModal,
      history,
      designName,
      nameTemplate,
      incrementStart,
      numDigits,
      tagIds
    } = this.props;

    let seqLength = null;
    let sequences = [];

    const [{ originFileObj }] = inputFiles;

    if (originFileObj.name.endsWith(".fasta")) {
      const jsonResult = await anyToJson(originFileObj, {
        fileName: originFileObj.name.slice(originFileObj.name.indexOf("/") + 1),
        acceptParts: true,
        primersAsFeatures: true
      });

      sequences = jsonResult.map(entry => {
        return {
          name: entry.parsedSequence.name,
          sequence: entry.parsedSequence.sequence.trim().toUpperCase()
        };
      });
    } else {
      const content = await PromiseFileReader.readAsText(originFileObj);
      sequences = content
        .split(/\r?\n/)
        .map(x => {
          return x.trim();
        })
        .filter(x => x)
        .map(row => {
          const [name, seq] = row.split("\t");
          const sequence = seq.trim().toUpperCase();
          if (seqLength === null) {
            seqLength = sequence.length;
          } else if (seqLength !== sequence.length) {
            const msg = "All sequences must be the same length.";
            window.toastr.error(msg);
            throw new Error(msg);
          }
          return { name: name.trim(), sequence };
        });
    }

    let duplicateSeqError = "";
    const possibleGroupedDuplicates = groupBy(sequences, "sequence");
    map(possibleGroupedDuplicates, group => {
      if (group.length > 1) {
        const seqNames = group.map(seq => seq.name).join(", ");
        duplicateSeqError += `${seqNames} are duplicate sequences. `;
      }
    });
    if (duplicateSeqError.length) {
      window.toastr.error(duplicateSeqError);
      throw new Error(duplicateSeqError);
    }

    const options = {
      alignmentType: "MULTIPLE_SEQUENCE_ALIGNMENT",
      alignmentAlgorithm: "mafft",
      processId: shortid(),
      isPairwiseAlignment: false,
      name: sequences[0].name
    };

    const standardAlignmentData = {
      addedSequences: sequences,
      options
    };
    const response = await window.serverApi.post("/handleAlignments", {
      standardAlignmentData
    });
    const alignedSequences = response.data.result.map(seq => seq.alignmentData);
    if (!response.data.success) {
      const error = response.data.err;
      console.error(error);
      throw new Error(error);
    }

    const alignmentRun = {
      id: shortid(),
      alignmentTracks: sequences.map((seq, i) => {
        const alignmentData = {
          id: shortid(),
          ...alignedSequences[i]
        };
        return {
          sequenceData: {
            id: shortid(),
            ...seq,
            sequence: alignedSequences[i].sequence
          },
          alignmentData
        };
      })
    };

    addData({
      id: alignmentRun.id,
      data: {
        alignmentRun,
        sequences,
        alignedSequences,
        designName,
        nameTemplate,
        incrementStart,
        numDigits,
        tagIds
      }
    });
    hideModal();
    history.push(`/massively-parallel-part-creation/${alignmentRun.id}`);
  };

  next = [
    defaultAsyncWrap(async () => {
      const { inputFiles } = this.props;
      if (inputFiles.length > 1) {
        return await this.handleMultiFileUpload();
      } else {
        return await this.handleSingleFileUpload();
      }
    }, "Error uploading or parsing file."),
    defaultAsyncWrap(async () => {
      const { designName, history, hideModal, nameTemplate } = this.props;
      const { alignedSequences } = this.state;
      const state = store.getState();

      this.setState({ submitting: true });

      const errorMessage = canCreateMassivelyParallelDesign({
        state,
        alignedSequences
      });
      if (errorMessage) return window.toastr.error(errorMessage);

      const designId = await massivelyParallelCreateDesign({
        state,
        alignedSequences,
        designName,
        nameTemplate
      });

      hideModal();
      this.setState({ submitting: false });

      history.push(`/designs/${designId}`);
    }, "Error creating design")
  ];

  downloadExampleFile = () => {
    download(exampleTSV, "alignedSequences.txt", "text/plain");
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;
    const { page } = this.state;

    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={titles[page]}
        style={{ width: widths[page] }}
      >
        <div className={Classes.DIALOG_BODY + " parallel-part-dialog-body"}>
          {this.renderPage()}
        </div>
        <DialogFooter
          hideModal={hideModal}
          text={page === 1 ? "Create Design" : "Next"}
          onClick={handleSubmit(this.next[page])}
          loading={submitting}
        />
      </Dialog>
    );
  }
}

const validate = values => {
  const errors = {};

  if (values["incrementStart"] < 1) {
    errors["incrementStart"] = "Number must be greater than 0";
  } else if (values["incrementStart"] !== 0 && !values["incrementStart"]) {
    errors["incrementStart"] = "Field Can't Be Blank";
  }
  if (values["numDigits"] < 1) {
    errors["numDigits"] = `Number must be greater than 0`;
  } else if (values["numDigits"] !== 0 && !values["numDigits"]) {
    errors["numDigits"] = "Field Can't Be Blank";
  }
  if (values.nameTemplate) {
    const matches = values.nameTemplate.match(/{{{incrementing_number}}}/g);
    if (matches && matches.length > 1) {
      errors.nameTemplate = `Only 1 {{{incrementing_number}}} allowed`;
    }
  }

  return errors;
};

export default compose(
  connect(null, {
    addData: actions.massivelyParallelPartCreation.addData
  }),
  reduxForm({
    form: "parallelPartDialogForm",
    validate
  }),
  withQuery(["tag", "id name"], { isPlural: true }),
  tgFormValues(
    "inputFiles",
    "selectionStart",
    "selectionEnd",
    "designName",
    "nameTemplate",
    "useDefaultNameTemplate",
    "incrementStart",
    "numDigits",
    "tagIds"
  ),
  withRouter
)(ParallelPartDialog);
