/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const containerArrayPlatePreviewAdditiveFragment = gql`
  fragment containerArrayPlatePreviewAdditiveFragment on additive {
    id
    volume
    volumetricUnitCode
    lot {
      id
      name
      additiveMaterial {
        id
        name
      }
    }
    additiveMaterial {
      id
      name
    }
  }
`;

export const platePreviewAliquotContainerFragment = gql`
  fragment platePreviewAliquotContainerFragment on aliquotContainer {
    id
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainerTypeCode
    columnPosition
    rowPosition
    additives {
      ...containerArrayPlatePreviewAdditiveFragment
    }
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      mass
      massUnitCode
      molarity
      molarityUnitCode
      additives {
        ...containerArrayPlatePreviewAdditiveFragment
      }
      sample {
        id
        name
        material {
          id
          name
        }
        sampleFormulations {
          id
          materialCompositions {
            id
            material {
              id
              name
            }
          }
        }
      }
    }
  }
  ${containerArrayPlatePreviewAdditiveFragment}
`;

export default gql`
  fragment containerArrayPlatePreviewFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      isPlate
      isColumn
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      ...platePreviewAliquotContainerFragment
    }
  }
  ${containerArrayPlatePreviewAdditiveFragment}
  ${platePreviewAliquotContainerFragment}
`;
