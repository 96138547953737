/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState } from "react";
import { Menu, Intent, Classes, FormGroup, Button } from "@blueprintjs/core";
import { DialogFooter, TgSelect } from "@teselagen/ui";

function DataTableSelectFilterMenu(props) {
  const {
    currentParams,
    setNewParams,
    options,
    getOptions,
    multi,
    paramKey,
    label
  } = props;

  const [val, setVal] = useState(currentParams[paramKey]);
  const clearParam = () => {
    const newParams = { ...currentParams };
    delete newParams[paramKey];
    setNewParams(newParams);
  };
  return (
    <Menu className="data-table-header-menu">
      <div style={{ padding: 7 }}>
        <FormGroup label={label}>
          <TgSelect
            className="data-table-select-filter"
            multi={multi}
            closeOnSelect={!multi}
            style={{ marginTop: 10 }}
            onChange={val => {
              setVal(val);
            }}
            value={val}
            name="dataTableSelectFilter"
            options={options || getOptions()}
          />
        </FormGroup>
      </div>
      <DialogFooter
        noCancel
        className={Classes.POPOVER_DISMISS}
        additionalButtons={
          <Button
            minimal
            className={Classes.POPOVER_DISMISS}
            text="Clear"
            onClick={clearParam}
          />
        }
        onClick={() => {
          if (!val) {
            clearParam();
          } else {
            setNewParams({
              ...currentParams,
              [paramKey]: multi
                ? val && val.map(v => v.value)
                : val && val.value
            });
          }
        }}
        intent={Intent.SUCCESS}
        text="OK"
      />
    </Menu>
  );
}

export default ops => ({ currentParams, setNewParams }) => {
  return (
    <DataTableSelectFilterMenu {...{ ...ops, currentParams, setNewParams }} />
  );
};
