/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const proteinPurificationPlateFragment = gql`
  fragment proteinPurificationPlateFragment on containerArray {
    id
    name
    containerArray {
      id
      name
    }
    collectionPlateId
    collectionPlate {
      id
      name
      aliquotContainers {
        id
        rowPosition
        columnPosition
      }
    }
    extendedValues {
      id
      value
      extendedProperty {
        id
        name
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      columnPosition
      rowPosition
      aliquot {
        id
        isDry
        # volume/mass info is needed for the reaction map new formulated sample logic
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
        sample {
          id
          name
          sampleTypeCode
          sampleFormulations {
            id
            materialCompositions {
              id
              materialId
              material {
                id
                name
                materialTypeCode
              }
            }
          }
          materialId
          material {
            id
            name
            materialTypeCode
          }
        }
      }
    }
  }
`;
