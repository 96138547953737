/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { reduxForm, formValues } from "redux-form";
import {
  InputField,
  TextareaField,
  RadioGroupField,
  ReactSelectField,
  DialogFooter
} from "@teselagen/ui";
import {
  getFeatureToColorMap,
  getFeatureTypes
} from "@teselagen/sequence-utils";
import { compose } from "recompose";
import actions from "../../../../src-shared/redux/actions";
import { connect } from "react-redux";
import {
  showStackedDialog,
  hideStackedDialog
} from "../../../../src-shared/StackedDialog";
import withQuery from "../../../../src-shared/withQuery";
import EditNamingTemplateDialog from "../EditNamingTemplateDialog";
import { size, find } from "lodash";

class CreateConstructAnnotationDialog extends React.Component {
  onSubmit = async values => {
    try {
      const { id, cardId, hideModal, upsertConstructAnnotation } = this.props;

      await upsertConstructAnnotation({
        id,
        name: values.name || values.constructAnnotationTemplate,
        notes: values.notes,
        type: values.type,
        annotationType: values.annotationType,
        leftBoundaryBinId: values.leftBoundaryBinId,
        rightBoundaryBinId: values.rightBoundaryBinId,
        annotationTemplate: values.constructAnnotationTemplate,
        cardId
      });

      window.toastr.success("Construct annotation created");
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating construct annotation.");
    }
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      bins = [],
      type,
      // name = "Untitled Construct Annotation",
      notes,
      annotationType,
      leftBoundaryBinId,
      rightBoundaryBinId,
      readOnly,
      change,
      constructAnnotationTemplate,
      constructAnnotations
    } = this.props;

    let defaultAnnotationTemplate = `NLP_0_{{{increment_from_0001}}}`;
    if (size(constructAnnotations)) {
      defaultAnnotationTemplate = `NLP_${size(
        constructAnnotations
      )}_{{{increment_from_0001}}}`;
    }
    const handleClick = e => {
      if (readOnly) return;
      e.stopPropagation();
      showStackedDialog({
        ModalComponent: EditNamingTemplateDialog,
        modalProps: {
          onSubmit: ({ newTemplate }) => {
            change("constructAnnotationTemplate", newTemplate);
            hideStackedDialog();
          },
          startingVal: constructAnnotationTemplate || defaultAnnotationTemplate
        }
      });
    };

    return (
      // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={`Create Construct ${
          annotationType.charAt(0).toUpperCase() + annotationType.slice(1)
        }`}
        style={{ width: 400 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            {/* <InputField
              inlineLabel
              tooltipError
              autoFocus
              defaultValue={name}
              placeholder="Untitled Construct Annotation"
              name="name"
              label="Name (optional):"
            /> */}
            <InputField
              onKeyDown={handleClick}
              onClick={handleClick}
              name="constructAnnotationTemplate"
              label="Annotation Name Template"
              placeholder="Create Template Name"
              aria-label="press enter to edit"
              readOnly={true}
              isRequired
            />
            <RadioGroupField
              inlineLabel
              tooltipError
              options={[
                { label: "Part", value: "part" },
                { label: "Feature", value: "feature" }
              ]}
              name="annotationType"
              label="Part/Feature:"
              defaultValue={annotationType || "part"}
            />
            <ReactSelectField
              inlineLabel
              tooltipError
              defaultValue={leftBoundaryBinId}
              options={bins.map(bin => {
                return {
                  label: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10
                      }}
                    >
                      {bin.name}
                    </div>
                  ),
                  value: bin.id
                };
              })}
              name="leftBoundaryBinId"
              label="Left Boundary Bin:"
              isRequired
            />
            <ReactSelectField
              inlineLabel
              tooltipError
              defaultValue={rightBoundaryBinId}
              options={bins.map(bin => {
                return {
                  label: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10
                      }}
                    >
                      {bin.name}
                    </div>
                  ),
                  value: bin.id
                };
              })}
              name="rightBoundaryBinId"
              label="Right Boundary Bin:"
              isRequired
            />
            <ReactSelectField
              inlineLabel
              tooltipError
              defaultValue={type || "misc_feature"}
              options={getFeatureTypes().map(type => {
                return {
                  label: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 10
                      }}
                    >
                      <div
                        style={{
                          background: getFeatureToColorMap({
                            includeHidden: true
                          })[type],
                          height: 15,
                          width: 15,
                          marginRight: 5
                        }}
                      />
                      {type}
                    </div>
                  ),
                  value: type
                };
              })}
              name="type"
              label="Type:"
            />
            <TextareaField
              inlineLabel
              tooltipError
              name="notes"
              label="Notes:"
              defaultValue={notes || ""}
              format={v => {
                let toReturn = v;
                if (typeof v !== "string" && v) {
                  toReturn = "";
                  Object.keys(v).forEach(key => {
                    let stringVal;
                    try {
                      stringVal = JSON.stringify(v[key]);
                    } catch (e) {
                      stringVal = v[key];
                    }
                    toReturn += `- ${key}: ${stringVal} \n`;
                  });
                }
                return toReturn;
              }}
              placeholder="Enter notes here..."
            />
          </div>
          <DialogFooter hideModal={hideModal} text="OK" />
        </form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {
  upsertConstructAnnotation: actions.design.upsertConstructAnnotation
};

export default compose(
  connect(null, mapDispatchToProps),
  connect((_, props) => {
    return {
      initialValues: {
        constructAnnotationTemplate: props.annotationTemplate
      }
    };
  }),
  withQuery(["card", "id designId"], {
    showLoading: true,
    options: props => {
      return { variables: { id: props.cardId } };
    }
  }),
  withQuery(["constructAnnotation", "id annotationTemplate designId"], {
    showLoading: true,
    isPlural: true,
    options: props => {
      return {
        variables: {
          filter: {
            designId: props.card.designId
          }
        }
      };
    }
  }),
  reduxForm({
    form: "createConstructAnnotationDialogForm",
    enableReinitialize: true,
    validate: ({ constructAnnotationTemplate }, { constructAnnotations }) => {
      const existingTemplate = find(
        constructAnnotations,
        ca => constructAnnotationTemplate === ca.annotationTemplate
      );
      if (existingTemplate) {
        return {
          constructAnnotationTemplate:
            "This template is already used for this design"
        };
      }
    }
  }),
  formValues("constructAnnotationTemplate")
)(CreateConstructAnnotationDialog);
