/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";

import GenericSelect from "../../../../src-shared/GenericSelect";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class UpdateSampleDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch } = this.props;
    const { sampleStatus = {} } = values;
    delete values.sampleStatus;
    const sampleToUpdate = {
      ...values,
      sampleStatusCode: (sampleStatus && sampleStatus.code) || null
    };
    try {
      await safeUpsert(["sample", "id name status"], sampleToUpdate);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating sample.");
    }
  };

  render() {
    const { handleSubmit, submitting, hideModal } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" isRequired />
          <GenericSelect
            {...{
              name: "sampleStatus",
              idAs: "code",
              schema: [{ path: "name" }],
              asReactSelect: true,
              fragment: ["sampleStatus", "code name"],
              label: "Sample Status",
              reactSelectProps: {
                placeholder: "Select a status..."
              }
            }}
          />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({ title: "Update Sample" }),
  reduxForm({
    form: "updateSampleDialog"
  })
)(UpdateSampleDialog);
