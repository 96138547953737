/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";

import { reduxForm } from "redux-form";
import { compose } from "redux";
import GenericSelect from "../../../GenericSelect";
import { wrapDialog } from "@teselagen/ui";

class SelectRestrictionEnzymesDialog extends React.Component {
  onSubmit = ({ restrictionEnzyme }) => {
    const { onSubmit, hideModal } = this.props;
    onSubmit(restrictionEnzyme);
    hideModal();
  };

  onCancel = () => {
    this.props.onCancel();
    this.props.hideModal();
  };

  render() {
    const { hideModal, submitting, handleSubmit, onlyTypeIIS } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <GenericSelect
            name="restrictionEnzyme"
            isRequired
            asReactSelect
            additionalFilter={
              onlyTypeIIS
                ? (props, qb) => {
                    qb.whereAll({
                      enzymeTypeCode: "TYPE_IIS",
                      isRecognitionSiteDegenerate: false
                    });
                  }
                : undefined
            }
            fragment={[
              "restrictionEnzyme",
              `
                id
                name
                recognitionRegex
                forwardSnipPosition
                reverseSnipPosition
                reverseRecognitionRegex
              `
            ]}
          />
        </div>
        <DialogFooter
          secondaryAction={this.onCancel}
          hideModal={hideModal}
          submitting={submitting}
        />
      </form>
    );
  }
}

export default compose(
  wrapDialog(props => ({
    title: props.title || "Choose Restriction Enzyme"
  })),
  reduxForm({
    form: "restrictionEnzymesForm", // a unique name for this form
    enableReinitialize: true
  })
)(SelectRestrictionEnzymesDialog);
