/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { CheckboxField } from "@teselagen/ui";

function AssignToLocationCheckbox({ fieldPrefix = "" }) {
  if (window.frontEndConfig.disabledToolkits.freezerEquipmentManagement)
    return null;
  return (
    <CheckboxField
      name={fieldPrefix + "shouldAssignToLocation"}
      label="Assign to Location"
      defaultValue
    />
  );
}

export default AssignToLocationCheckbox;
