/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { tgFormValues } from "@teselagen/ui";
import { get, isEmpty } from "lodash";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { Callout } from "@blueprintjs/core";
import {
  executeReactionMapToolContainerArrayFragment,
  executeReactionMapToolReactionMapFragment
} from "./fragments";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { getReactionsForAliquotContainer } from "../../../utils/reactionMapUtils";
import { sortToLocationStrings } from "../../../../../tg-iso-lims/src/utils/plateUtils";

function SelectInputs(props) {
  const {
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
    reactionMaps = [],
    containerArrays = [],
    Footer,
    footerProps
  } = props;

  const samplesMissingReactions = {};
  const plateErrors = {};

  let numberOfReactions = 0;
  let numberOfReactionsRun = 0;
  const reactionsRun = {};
  if (containerArrays.length && reactionMaps.length) {
    reactionMaps.forEach(rm => {
      numberOfReactions = numberOfReactions += rm.reactions.length;
    });
    containerArrays.forEach(containerArray => {
      let plateHasReaction = false;
      containerArray.aliquotContainers.forEach(ac => {
        const matchingReactions = getReactionsForAliquotContainer({
          aliquotContainer: ac,
          reactionMaps,
          onMatch: reaction => {
            if (!reactionsRun[reaction.id]) {
              reactionsRun[reaction.id] = true;
              numberOfReactionsRun++;
            }
          }
        });
        plateHasReaction = plateHasReaction || !!matchingReactions.length;

        if (
          !matchingReactions.length &&
          get(ac, "aliquot.sample.sampleFormulations.length")
        ) {
          // if it is a formulated sample and there is no reaction info mark a warning
          if (samplesMissingReactions[containerArray.name]) {
            samplesMissingReactions[containerArray.name].push(ac);
          } else {
            samplesMissingReactions[containerArray.name] = [ac];
          }
        }
      });
      if (!plateHasReaction) {
        plateErrors[containerArray.id] = plateErrors[containerArray.id] || {};
        plateErrors[containerArray.id]._error =
          "No reactions to perform on plate.";
      }
    });
  }
  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Reaction Maps"
          helper={`Choose from existing reaction maps. These will detect
            pooled samples with matching material composition and associate
            these samples with one output material on submission.`}
        />
        <GenericSelect
          {...{
            name: "reactionMaps",
            schema: ["name", "reactionType.name", dateModifiedColumn],
            isMultiSelect: true,
            isRequired: true,
            fragment: [
              "reactionMap",
              "id name reactionTypeCode reactionType { code name } updatedAt"
            ],
            additionalDataFragment: executeReactionMapToolReactionMapFragment,
            postSelectDTProps: {
              formName: "reactionMapTable",
              schema: ["name", "reactionType.name", dateModifiedColumn]
            },
            buttonProps: {
              loading: isLoadingMap.reactionMaps,
              disabled: isDisabledMap.reactionMaps
            }
          }}
        />
        {numberOfReactions > 0 && !!containerArrays.length && (
          <Callout intent="primary">
            {numberOfReactionsRun} of {numberOfReactions} reactions will be run
            on the selected plates (
            {(numberOfReactionsRun / numberOfReactions) * 100}%)
          </Callout>
        )}
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Plates"
          helper={`Selected plates should have pooled samples that
            consist of materials matching the selected reaction maps
            above. Pooled samples with missing reaction data will be
            listed below and ignored on submission.`}
        />
        <GenericSelect
          {...{
            name: "containerArrays",
            schema: [
              "name",
              { displayName: "Barcode", path: "barcode.barcodeString" },
              { displayName: "Plate Type", path: "containerArrayType.name" },
              dateModifiedColumn
            ],
            isMultiSelect: true,
            isRequired: true,
            fragment: [
              "containerArray",
              "id name containerArrayType { id name } updatedAt"
            ],
            additionalDataFragment: executeReactionMapToolContainerArrayFragment,
            postSelectDTProps: {
              formName: "plateTable",
              plateErrors,
              schema: [
                platePreviewColumn({
                  plateErrors
                }),
                "name",
                {
                  displayName: "Plate Type",
                  path: "containerArrayType.name"
                },
                dateModifiedColumn
              ]
            },
            buttonProps: {
              loading: isLoadingMap.containerArrays,
              disabled: isDisabledMap.containerArrays
            }
          }}
        />
        {!isEmpty(samplesMissingReactions) &&
          reactionMaps.length > 0 &&
          containerArrays.length > 0 && (
            <Callout style={{ marginBottom: 10 }} intent="warning">
              {getMissingWarning(samplesMissingReactions)}
            </Callout>
          )}
      </div>
      <Footer
        {...footerProps}
        nextDisabled={!isEmpty(plateErrors)}
        errorMessage={!isEmpty(plateErrors) && "Please review plate errors"}
      />
    </React.Fragment>
  );
}

function getMissingWarning(samplesMissingReactions) {
  let msg = `The samples at these locations are missing their corresponding reaction info and will not be updated:`;
  const keys = Object.keys(samplesMissingReactions);
  msg += keys.map(
    (plateName, i) =>
      ` ${plateName} (${sortToLocationStrings(
        samplesMissingReactions[plateName]
      ).join(", ")})${i < keys.length - 1 ? ", " : ""}`
  );
  return msg;
}

export default compose(tgFormValues("reactionMaps", "containerArrays"))(
  SelectInputs
);
