/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import MicrobialVeCard from "./MicrobialVeCard";
import DnaVeCard from "./DnaVeCard";

export default function VeCard(props) {
  const El = props.isMicrobialMaterial ? MicrobialVeCard : DnaVeCard;
  return <El {...props} />;
}
