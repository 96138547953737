/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  CheckboxField,
  InputField,
  wrapDialog
} from "@teselagen/ui";
import { dataTableHeaderMap } from "../../../utils";
import GenericSelect from "../../../../src-shared/GenericSelect";

import { startImportCollection } from "../../../../src-shared/utils/importCollection";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { allowedCsvFileTypes } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import {
  dataTableTypeCodesWithCustomPaths,
  parseDataTableImportData
} from "../../../../../tg-iso-lims/src/utils/dataTableUtils";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

// Add a checkbox field for grouping data tables inside a data set
// Change logic to parse multiple csv files

class UploadDataTableDialog extends Component {
  onSubmit = async values => {
    try {
      const { refetch, hideModal } = this.props;
      const { dataTableType, dataTableFiles, dataSetName, groupInDataSet } =
        values;

      const { error, dataTables, dataSet } = await parseDataTableImportData({
        csvFiles: dataTableFiles,
        dataTableType,
        dataSetName: groupInDataSet ? dataSetName : undefined
      });
      if (error) {
        return window.toastr.error(error);
      }
      await startImportCollection("Data Table Upload");
      if (dataSet) {
        await safeUpsert("dataSet", dataSet);
      }
      await safeUpsert("dataTable", dataTables);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error uploading data table");
    }
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      submitting,
      groupInDataSet,
      dataTableType = {}
    } = this.props;

    let requiredHeaders;
    if (dataTableType && dataTableType.rowSchema) {
      requiredHeaders = dataTableType.rowSchema.map(r => r.displayName);
    }
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <GenericSelect
            {...{
              name: "dataTableType",
              isRequired: true,
              label: "Type",
              idAs: "code",
              asReactSelect: true,
              tableParamOptions: {
                additionalFilter: (props, qb) => {
                  qb.whereAll({
                    code: qb.notInList(dataTableTypeCodesWithCustomPaths)
                  });
                }
              },
              fragment: ["dataTableType", "code name"],
              additionalDataFragment: ["dataTableType", "code name rowSchema"]
            }}
          />
          <CheckboxField
            name="groupInDataSet"
            label="Group Tables in Data Set"
            defaultValue
          />
          {groupInDataSet && (
            <InputField isRequired name="dataSetName" label="Data Set Name" />
          )}
          <FileUploadField
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes,
              fileName: `${dataTableType.name} - Template`,
              headers: requiredHeaders,
              headerMessages: dataTableHeaderMap[dataTableType.code] || {}
            })}
            name="dataTableFiles"
            isRequired
          />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Upload Table"
  }),
  reduxForm({
    form: "uploadDataTable"
  }),
  tgFormValues("dataTableType", "groupInDataSet")
)(UploadDataTableDialog);
