/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */
import React from "react";
import { ReactSelectField, withSelectedEntities } from "@teselagen/ui";
import pluralize from "pluralize";
import { forEach } from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { stopSubmit } from "redux-form";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { itemTypes, withItemProps } from "../utils";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import fieldConstants from "../fieldConstants";
import SelectReactionMapEntities, {
  getItemTypeAndFilterForReactionMaps
} from "../../../../../src-shared/SelectReactionMapEntities";
import { safeQuery } from "../../../../../src-shared/apolloMethods";

const modelNameToSchema = {
  material: [
    {
      path: "name"
    },
    {
      path: "materialType.name",
      displayName: "Type"
    },
    dateModifiedColumn
  ],
  reactionMap: [
    "name",
    {
      displayName: "Reaction Type",
      path: "reactionType.name"
    },
    dateModifiedColumn
  ]
};

export const modelNameToFragment = {
  material: ["material", "id name materialTypeCode materialType { code name }"],
  reactionMap: ["reactionMap", "id name reactionType {code name}"]
};

export const modelNameToAdditionalFragment = {
  material: [
    "material",
    "id name materialTypeCode materialType { code name } polynucleotideMaterialSequence { id size sequenceTypeCode }"
  ]
};

forEach(modelNameToFragment, fragmentArray => {
  fragmentArray[1] = fragmentArray[1] + ` updatedAt`;
});

const extraFilterForReactionMaps = {
  "polynucleotideMaterialSequence.sequenceTypeCode": "LINEAR_DNA"
};

class SelectMaterialsStep extends React.Component {
  getMaterialReactionMapFilter = (reactionMaps, reactionEntityType) => {
    let additionalFilter;
    const reactionMapIds = reactionMaps.map(r => r.id);
    if (reactionEntityType.includes("Input")) {
      additionalFilter = {
        "reactionInputs.reaction.reactionMapId": reactionMapIds,
        "polynucleotideMaterialSequence.sequenceTypeCode": "LINEAR_DNA"
      };
    } else {
      additionalFilter = {
        "reactionOutputs.reaction.reactionMapId": reactionMapIds,
        "polynucleotideMaterialSequence.sequenceTypeCode": "LINEAR_DNA"
      };
    }
    return additionalFilter;
  };

  onSelectMainEntity = (mainEntities = []) => {
    const {
      stepFormProps: { change },
      reactionMaps = [],
      [fieldConstants.itemType]: itemType
    } = this.props;

    if (itemType === "reactionMap") {
      const changed = reactionMaps !== mainEntities;
      if (changed) {
        change("materials", []);
      }
    }
  };

  renderReactionMapOptions() {
    const {
      toolSchema,
      reactionMaps = [],
      [fieldConstants.itemType]: itemType,
      stepFormProps: { change }
    } = this.props;
    if (!reactionMaps.length || itemType !== "reactionMap") return;

    return (
      <SelectReactionMapEntities
        toolSchema={toolSchema}
        extraFilter={extraFilterForReactionMaps}
        change={change}
        onlyMaterials
        modelNameToSchema={modelNameToSchema}
        fieldConstants={fieldConstants}
        reactionMaps={reactionMaps}
        modelNameToAdditionalFragment={modelNameToAdditionalFragment}
        modelNameToFragment={modelNameToFragment}
      />
    );
  }

  beforeNextStep = async values => {
    try {
      const {
        [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities,
        [fieldConstants.reactionEntityType]: reactionEntityType,
        [fieldConstants.itemType]: itemType,
        reactionMaps = []
      } = values;

      const {
        stepFormProps: { change },
        nextStep
      } = this.props;
      if (itemType === "reactionMap" && selectAllReactionEntities) {
        const {
          additionalFilter,
          itemType
        } = getItemTypeAndFilterForReactionMaps({
          reactionEntityType,
          reactionMaps,
          extraFilter: extraFilterForReactionMaps
        });
        const items = await safeQuery(modelNameToAdditionalFragment.material, {
          variables: {
            filter: additionalFilter
          }
        });
        if (!items.length) {
          return window.toastr.error(
            `Could not find any valid ${reactionEntityType}. All materials must be linked to Linear DNA.`
          );
        }
        change(pluralize(itemType), items);
      }
      nextStep();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error loading reaction entities");
    }
  };

  render() {
    const {
      [fieldConstants.itemType]: itemType,
      Footer,
      footerProps,
      handleSubmit,
      stepFormProps: { change }
    } = this.props;

    const materialFilter = {
      "polynucleotideMaterialSequence.sequenceTypeCode": "LINEAR_DNA"
    };

    const postSelectTableSchema = modelNameToSchema[itemType];

    const readyToSelect = true;

    return (
      <div>
        <div
          style={{
            textAlign: "right",
            fontStyle: "italic",
            fontSize: 12,
            marginBottom: 15
          }}
        >
          Powered by NinthBio's Homology Path
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Choose Item Type"
            helper="Choose which type of item will be used to select materials."
          />
          <div style={{ width: "30%" }}>
            <ReactSelectField
              name="itemType"
              options={itemTypes.map(model => ({
                label: modelNameToReadableName(model, { upperCase: true }),
                value: model
              }))}
              label="Item Type"
              onFieldSubmit={() => {
                change("materials", []);
              }}
            />
          </div>
        </div>
        {!!itemType && readyToSelect && (
          <div>
            <div className="tg-step-form-section column">
              <div className="tg-flex justify-space-between">
                <HeaderWithHelper
                  width="100%"
                  header={`Select ${modelNameToReadableName(itemType, {
                    plural: true,
                    upperCase: true
                  })}`}
                  helper="Choose materials as synthons for which to generate oligos."
                />
              </div>
              <div>
                <GenericSelect
                  key={itemType}
                  {...{
                    name: pluralize(itemType),
                    isMultiSelect: true,
                    schema: modelNameToSchema[itemType],
                    fragment: modelNameToFragment[itemType],
                    additionalDataFragment:
                      modelNameToAdditionalFragment[itemType],
                    tableParamOptions: {
                      additionalFilter:
                        itemType === "material" ? materialFilter : undefined
                    },
                    onSelect: this.onSelectMainEntity,
                    postSelectDTProps: {
                      formName: "entitiesTable",
                      schema: postSelectTableSchema
                    }
                  }}
                />
                {this.renderReactionMapOptions()}
              </div>
            </div>
          </div>
        )}
        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    fieldConstants.itemType,
    "autoSelectMaterials",
    "reactionMaps",
    fieldConstants.reactionEntityType
  ),
  withItemProps,
  connect(null, {
    stopSubmit
  }),
  withSelectedEntities("selectMaterialsAsSynthons")
)(SelectMaterialsStep);
