/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

const FILE_TO_DATATABLE = {
  defaultSubtype: "COLONY_PICKING_FEEDBACK",
  // subtypes: ['DNA_SEQUENCE', "COLONY_PICKING_FEEDBACK"],
  subtypes: [
    "COLONY_PICKING_FEEDBACK",
    "SAMPLE_QC_INPUT_STATUS",
    "SAMPLE_QC_INPUT_QC_CHECK"
  ],
  description:
    "Send a file and get back a data table; Used in conjunction with the 'Convert File to Data Table' Tool",
  endpoints: {
    FILE_TO_DATATABLE: {
      name: "Convert File To Datatable",
      method: "POST",
      description: "Send a file and get back a data table",
      exampleRequest: {
        __validate__: () =>
          yup.object({
            encodedFiles: yup.array(
              yup.object({
                type: yup.string(),
                base64Data: yup.string(),
                name: yup.string()
              })
            )
          }),
        encodedFiles: [
          {
            type: "txt/csv",
            base64Data: "Im base 64 data 29382982uf",
            name: "someFile.txt"
          }
        ],
        plates: [
          {
            id: "2c39d8cc-eb8a-466e-b6a2-ce7589d3854d",
            name: "Plate 1",
            barcode: "Z0000009a5",
            format: { rowCount: 8, columnCount: 12 },
            contents: [
              {
                position: "A1",
                volume: 1,
                "volume unit": "uL",
                materials: [
                  {
                    id: "cd325aa3-d56c-4aea-b213-d51014f51549",
                    name: "PCR_0001",
                    polynucleotideMaterialSequence: {
                      id: "0a0c9975-c4f0-41ee-b205-3e41489b6739",
                      size: 4623
                    }
                  }
                ],
                additives: []
              },
              {
                position: "A11",
                volume: 3,
                "volume unit": "uL",
                materials: [],
                additives: []
              }
            ]
          }
        ]
      },
      exampleResponse: {
        __validate__: ({ subtype }) => {
          if (subtype === "COLONY_PICKING_FEEDBACK") {
            return yup.object({
              name: yup.string().required(),
              rows: yup
                .array(
                  yup.object({
                    destinationPlateBarcode: yup.string().required(),
                    destinationWell: yup.string().required(),
                    sourcePlateBarcode: yup.string().required(),
                    sourceLocation: yup.string().required(),
                    xCoordinate: yup.string(),
                    yCoordinate: yup.string(),
                    diameter: yup.string(),
                    date: yup.string(),
                    time: yup.string()
                  })
                )
                .required()
            });
          } else if (subtype === "SAMPLE_QC_INPUT_STATUS") {
            return yup.object({
              name: yup.string().required(),
              rows: yup
                .array(
                  yup.object({
                    plateName: yup.string().required(),
                    plateBarcode: yup.string().required(),
                    wellLocation: yup.string().required(),
                    sampleStatus: yup.string().required()
                  })
                )
                .required()
            });
          } else if (subtype === "SAMPLE_QC_INPUT_QC_CHECK") {
            return yup.object({
              name: yup.string().required(),
              rows: yup
                .array(
                  yup.object({
                    plateName: yup.string().required(),
                    plateBarcode: yup.string().required(),
                    wellLocation: yup.string().required(),
                    qcCheckName: yup.string().required(),
                    qcCheckStatus: yup.string().required()
                  })
                )
                .required()
            });
          } else {
            throw new Error("Unrecognized subtype!");
          }
        },
        __subtype__: {
          COLONY_PICKING_FEEDBACK: {
            name: "Feedback Data",
            rows: [
              {
                sourcePlateBarcode: "9839450",
                sourceLocation: "G12",
                destinationPlateBarcode: "76350002",
                destinationWell: "A1",
                xCoordinate: "1476",
                yCoordinate: "176",
                diameter: "0.57",
                date: "23-May-18",
                time: "10:50:43"
              }
            ]
          },
          SAMPLE_QC_INPUT_STATUS: {
            name: "QC Input Status",
            rows: [
              {
                plateName: "my plate",
                plateBarcode: "128410248",
                wellLocation: "B1",
                sampleStatus: "VALID"
              }
            ]
          },
          SAMPLE_QC_INPUT_QC_CHECK: {
            name: "QC Input QC Check",
            rows: [
              {
                plateName: "my plate",
                plateBarcode: "128410248",
                wellLocation: "B1",
                qcCheckName: "PCR Gel",
                qcCheckStatus: "VALID"
              }
            ]
          }
        }
      }
    }
  }
};

export default FILE_TO_DATATABLE;
