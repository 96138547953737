/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes, Button } from "@blueprintjs/core";
import { compose } from "recompose";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

const BACK_ARROW_TEXT = "Return";
const BACK_ARROW_RETURN_PATH = "/";

function ToolTitle({ title, history, embedded, stopTool }) {
  return (
    <div>
      <h4>{title}</h4>
      <Button
        className={classNames(Classes.SMALL, Classes.MINIMAL)}
        onClick={async () => {
          if (embedded) {
            stopTool();
          } else {
            history.push(BACK_ARROW_RETURN_PATH);
          }
        }}
        icon="arrow-left"
        text={BACK_ARROW_TEXT}
      />
    </div>
  );
}

export default compose(withRouter)(ToolTitle);
