/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { showConfirmationDialog } from "@teselagen/ui";
import Promise from "bluebird";
import QueryBuilder from "tg-client-query-builder";

import { typeToFragmentMap as xToSiteFragmentMap } from "@teselagen/path-utils";
import { typeToFragmentMap as xToAllAssignedPositionsFragmentMap } from "../graphql/fragments/paths/allAssignedPositionsFromXFragments";
import { assignedPositionFragment } from "../graphql/fragments/assignedPositionFragment.gql";
import ArrayValuedMap from "./ArrayValuedMap";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../src-shared/apolloMethods";

const sortById = array =>
  array.sort((a, b) => {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });

const keysNotAssignableToLocation = [
  "equipmentPositions",
  "containerPositions"
];

const keysAssignableToLocation = [
  "equipmentItems",
  "containers",
  "assignedPositions"
];

// // Every key above is made singular just by appending an "s",
// // so this function works.
// const makeKeySingular = key => key.slice(0, -1);

const recursivelyFindObjectsToReassign = (
  obj,
  objectsToReassign = [],
  objectsToDelete = []
) => {
  for (const key of keysAssignableToLocation) {
    if (obj[key]) objectsToReassign.push(...obj[key]);
  }
  for (const key of keysNotAssignableToLocation) {
    if (obj[key]) {
      objectsToDelete.push(...obj[key]);
      obj[key].forEach(subObj =>
        recursivelyFindObjectsToReassign(
          subObj,
          objectsToReassign,
          objectsToDelete
        )
      );
    }
  }
  return [objectsToReassign, objectsToDelete];
};

const deleteObjects = async objectsToDelete => {
  const typenameToIdsMap = new ArrayValuedMap();
  for (const obj of objectsToDelete) {
    typenameToIdsMap.get(obj.__typename).push(obj.id);
  }
  await Promise.map(
    Object.entries(typenameToIdsMap.toJs()),
    ([typename, ids]) => safeDelete(typename, ids)
  );
};

const reassignToLocation = async locationIdToPositionsMap => {
  locationIdToPositionsMap = locationIdToPositionsMap.toJs();

  const typenameToValuesToUpsertMap = new ArrayValuedMap();
  Object.entries(locationIdToPositionsMap).forEach(
    ([locationId, positions]) => {
      positions.forEach(pos => {
        typenameToValuesToUpsertMap.get(pos.__typename).push({
          id: pos.id,
          locationId
        });
      });
    }
  );

  await Promise.map(
    Object.entries(typenameToValuesToUpsertMap.toJs()),
    ([typename, valuesToUpsert]) => safeUpsert(typename, valuesToUpsert)
  );
};

export const getLocationFromObject = obj => {
  const objectKeysForProcessingToSite = [
    "location",
    "equipment",
    "equipmentPosition",
    "container",
    "containerPosition"
  ];
  if (obj.location) return obj.location;
  for (const key of objectKeysForProcessingToSite) {
    if (obj[key]) return getLocationFromObject(obj[key]);
  }
  return null;
};

/* tg: deprecating in favor of just removing nested containers.
  makes more sense to me that the user would just want the
  nested containers to be removed
*/
export const deletePositionsWithReassignToLocation = async (
  positionType,
  positionIds
) => {
  positionIds.sort();

  const xToSiteFragment = xToSiteFragmentMap[positionType];
  const xToAllAssignedPositionsFragment = xToAllAssignedPositionsFragmentMap[
    positionType
  ](assignedPositionFragment);

  // Figure out the location of each position.
  const toSites = (
    await safeQuery(xToSiteFragment, {
      isPlural: true,
      variables: { filter: { id: positionIds } }
    })
  ).slice(0);
  sortById(toSites);
  const locations = toSites.map(obj => getLocationFromObject(obj));

  // this was breaking deleting containers
  // if (!locations.every(identity))
  //   throw new Error("Not all positions have locations.");

  // Now find what items we have to reassign and delete.
  const toAllAssignedPositions = (
    await safeQuery(xToAllAssignedPositionsFragment, {
      isPlural: true,
      variables: { filter: { id: positionIds } }
    })
  ).slice(0);
  sortById(toAllAssignedPositions);

  const locationIdToPositionsMap = new ArrayValuedMap();
  const positionsToDelete = [];
  toAllAssignedPositions.forEach((obj, i) => {
    const location = locations[i];
    // probably shouldn't even be here but needed to fix bug
    if (!location) return;
    const [objectsToReassign, objectsToDelete] =
      recursivelyFindObjectsToReassign(obj);
    locationIdToPositionsMap.get(location.id).push(...objectsToReassign);
    positionsToDelete.push(...objectsToDelete);
  });

  // Execute the mutations.
  await deleteObjects(positionsToDelete);
  await reassignToLocation(locationIdToPositionsMap);
  await safeDelete(positionType, positionIds);
};

/**
 * This function will find all nested containers inside of an equipment or container
 * and delete them as well as the equipment or container.
 * @param {array} itemIds - array of item ids to delete (equipment or container)
 * @param {boolean} isEquipment - specifies if deleting equipment (if not we are deleting containers)
 */
async function deleteEquipmentOrContainerAndContents(itemIds, isEquipment) {
  const shouldDelete = await showConfirmationDialog({
    text: `Deleting ${
      isEquipment ? "equipment" : "a container"
    } will delete all containers inside. Continue?`,
    intent: "danger",
    icon: "warning-sign"
  });

  // throw error so that functions following this one are not run
  if (!shouldDelete) {
    throw new Error("userCancelled");
  }
  let nestedContainerFilter;

  // if we are deleting equipment we search for all containers that
  // are in the equipment (have its equipmentId), or are in one of
  // the equipment's positions (have one of its equipmentPositionIds)
  if (isEquipment) {
    const equipmentPositions = await safeQuery(["equipmentPosition", "id"], {
      variables: {
        filter: {
          equipmentId: itemIds
        }
      }
    });
    const equipmentPositionIds = equipmentPositions.map(e => e.id);

    const qb = new QueryBuilder("container");
    nestedContainerFilter = qb
      .whereAny({
        equipmentId: itemIds,
        equipmentPositionId: equipmentPositionIds
      })
      .toJSON();
  } else {
    // if we are deleting a container we find all containers
    // which have a path that starts with this root container's path and id
    // meaning they are inside it
    const containersWithInfo = await safeQuery(
      ["container", "id path equipmentPositionId equipmentId"],
      {
        variables: {
          filter: {
            id: itemIds
          }
        }
      }
    );
    const anyFilters = [];
    const qb = new QueryBuilder("container");
    containersWithInfo.forEach(c => {
      anyFilters.push({
        path: qb.startsWith((c.path || "") + `/${c.id}`)
      });
    });
    nestedContainerFilter = qb.whereAny(...anyFilters).toJSON();
  }

  // use this filter to find all nested containers
  const allNestedContainers = await safeQuery(["container", "id name"], {
    variables: {
      filter: nestedContainerFilter
    }
  });

  await safeDelete(
    "container",
    allNestedContainers.map(c => c.id)
  );
  if (isEquipment) {
    await safeDelete("equipmentItem", itemIds);
  } else {
    await safeDelete("container", itemIds);
  }
}

export function deleteEquipmentAndContents(equipmentIds) {
  return deleteEquipmentOrContainerAndContents(equipmentIds, true);
}

export function deleteContainerAndContents(containerIds) {
  return deleteEquipmentOrContainerAndContents(containerIds);
}
