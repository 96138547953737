/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { DialogFooter, tgFormValues } from "@teselagen/ui";

import { Classes } from "@blueprintjs/core";
import { TextareaField, ReactSelectField } from "@teselagen/ui";
import { keyBy, flatMap, get, forEach, filter } from "lodash";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import { safeUpsert, useTgQuery } from "../../../../src-shared/apolloMethods";
import actions from "../../../../src-shared/redux/actions";

import { statusToIconMap } from "../../../../../tg-iso-design/constants/designStateConstants";
import { getActiveLabId } from "@teselagen/auth-utils";
import { connect } from "react-redux";
import { upsertDesignApprovalNotification } from "../../../utils";
import appGlobals from "../../../../src-shared/appGlobals";
import { wrapDialog } from "@teselagen/ui";

function SubmitDesignForReviewDialog(props) {
  const {
    hideModal,
    submitting,
    handleSubmit,
    reviewType,
    updateDesign,
    refetch,
    afterCreate
  } = props;

  const { design, ...rest } = useTgQuery(
    [
      "design",
      /* GraphQL */ `
        {
          name
          id
          userId
          activeSubmission {
            id
            submissionStateCode
          }
          submissions {
            id
            stageId
            submissionStateCode
          }
        }
      `
    ],
    {
      variables: {
        id: props.design.id
      }
    }
  );

  const { stages = [], ...rest2 } = useTgQuery([
    "stage",
    /* GraphQL */ `
      {
        id
        name
        howManyApprovers
        prerequisiteStagesstages {
          id
          prereqStageId
          stageId
        }
        stageApprovers {
          id
          userId
          user {
            id
            username
            labRoles {
              id
              lab {
                id
              }
            }
          }
        }
      }
    `
  ]);

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  if (useTgQuery.checkErrAndLoad(rest2))
    return useTgQuery.handleErrAndLoad(rest2);

  const alreadySubmittedStages = keyBy(
    design &&
      filter(design.submissions, s => s.submissionStateCode !== "CANCELLED"),
    "stageId"
  );

  const onSubmit = defaultAsyncWrap(
    async ({ comment, reviewType, reviewers }) => {
      if (reviewers.length < reviewType.howManyApprovers) {
        const message = `At least ${reviewType.howManyApprovers} reviewers are required`;
        return window.toastr.error(message);
      }
      const [sub] = await safeUpsert(["submission", "id comments { id }"], {
        // userId: localStorage.getItem("userId"),
        designId: design.id,
        stageId: reviewType.id,
        submissionStateCode: "READY",
        submissionStageApprovers: reviewers.map(({ id }) => ({
          stageApproverId: id,
          submissionStageApproverStatusCode: "READY"
        })),
        comments: [
          {
            headerMsg: `${reviewType.name} started`,
            icon: statusToIconMap.READY.icon,
            message: `Added ${reviewers.reduce((acc, reviewer, i) => {
              acc +=
                reviewer.user.username +
                (i === reviewers.length - 1
                  ? ""
                  : i === reviewers.length - 2
                    ? " and "
                    : ", ");
              return acc;
            }, "")} as reviewer${
              reviewers.length > 1 ? "s" : ""
            } -- ${comment}`,
            userId: localStorage.getItem("userId")
          }
        ]
      });

      const designUpdate = {
        id: design.id,
        activeSubmissionId: sub.id,
        isLocked: true,
        lockedMessage: "Locked During Review.",
        lockMsgDescription:
          "Reviewers can request changes to unlock the design.",
        lockTypeCode: "LOCK_ONLY_CORE_DESIGN"
      };
      const isInsideDesign = window.location.href.includes("designs/");
      if (isInsideDesign) {
        //we're in a design and can use updateDesign
        await updateDesign({
          ...designUpdate,
          overrideLock: true,
          doNotAddToUndoStack: true
        });
      } else {
        //otherwise we'll just do an upsert
        await safeUpsert("design", designUpdate);
      }

      for (const reviewer of reviewers) {
        await upsertDesignApprovalNotification({
          userId: reviewer.userId,
          stageName: reviewType.name,
          designName: design.name,
          commentId: sub.comments[0].id,
          extraMessage: comment,
          designId: design.id,
          activeSubmissionId: sub.id
        });
      }
      window.refetchNotifications && window.refetchNotifications();

      refetch && (await refetch());
      afterCreate && (await afterCreate(sub));
      window.toastr.success("Request for Review Submitted Successfully");
      hideModal();
    },
    "Error submitting design for approval."
  );
  let inner;

  if (!stages || !stages.length) {
    inner = (
      <div className={Classes.DIALOG_BODY}>
        <div>
          No Review Stages have been set up yet. An admin will need to set up
          the design approval process before designs can be submitted for
          approval
        </div>
      </div>
    );
  } else if (
    design.activeSubmission &&
    design.activeSubmission.submissionStateCode !== "ACCEPTED" &&
    design.activeSubmission.submissionStateCode !== "CANCELLED"
  ) {
    inner = (
      <div className={Classes.DIALOG_BODY}>
        <div>
          There is still an ongoing review for this design. Please finish that
          review before starting a new one.
        </div>
      </div>
    );
  } else {
    inner = (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <ReactSelectField
            isRequired
            options={stages
              .filter(({ id, prerequisiteStagesstages }) => {
                //check if this stage has all its prereqs fulfilled
                let hasPrereqsMet = true;
                forEach(prerequisiteStagesstages, ({ prereqStageId }) => {
                  if (!alreadySubmittedStages[prereqStageId]) {
                    hasPrereqsMet = false;
                  }
                });
                if (!hasPrereqsMet) {
                  return false;
                }
                //filter out stages that have already been submitted
                return !alreadySubmittedStages[id];
              })
              .map(s => ({ label: s.name, value: s }))}
            name="reviewType"
            label="Choose Review Type"
          />

          <ReactSelectField
            isRequired
            multi
            disabled={!reviewType}
            options={
              (reviewType &&
                reviewType.stageApprovers &&
                flatMap(reviewType.stageApprovers, s => {
                  const labRoles = get(s, "user.labRoles") || [];
                  const activeLabId = getActiveLabId();
                  //filter out the submitter. Submitters can't be reviewers
                  if (s.user.id === appGlobals.currentUser.id) return [];
                  //filter out any reviewers who aren't a part of the lab
                  if (
                    activeLabId &&
                    !labRoles.find(({ lab }) => lab.id === activeLabId)
                  ) {
                    return [];
                  }
                  if (s.user.id === design.userId) {
                    //don't let the creator be a reviewer
                    return [];
                  }
                  if (s.user.id === appGlobals.currentUser.id) {
                    //don't let the person editing the list be a reviewer
                    return [];
                  }
                  return {
                    label: s.user.username,
                    value: s
                  };
                })) ||
              []
            }
            label={
              <div>
                {`Select Reviewer(s) ${
                  reviewType
                    ? ` -- ${reviewType.howManyApprovers} Required`
                    : ""
                }`}
                <div style={{ margin: "5px 0px", fontSize: 10 }}>
                  Note: You can't add yourself or the design creator as a
                  reviewer
                </div>
              </div>
            }
            name="reviewers"
          />
          <TextareaField
            name="comment"
            label="Comment"
            defaultValue="Design is ready for review."
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          loading={submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </React.Fragment>
    );
  }

  return inner;
}

export default compose(
  wrapDialog({
    title: "Submit for Review",
    style: {
      width: 350
    }
  }),
  connect(null, {
    updateDesign: actions.design.updateDesign
  }),
  reduxForm({
    form: "SubmitDesignForReviewDialog", // a unique name for this form
    enableReinitialize: true
    // validate
  }),
  tgFormValues("reviewType")
)(SubmitDesignForReviewDialog);
