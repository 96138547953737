/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  wrapDialog,
  DataTable,
  withSelectTableRecords,
  withSelectedEntities
} from "@teselagen/ui";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { getReactionMapSchema } from "../../../utils/reactionMapUtils";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import gql from "graphql-tag";
import {
  createReactionMapMaterialFragment,
  createReactionMapReagentFragment
} from "../../../../../tg-iso-lims/src/utils/reactionMapUtils";
import { hideDialog } from "../../../../src-shared/GlobalDialog";
import useShowReactionsSampleStatus from "../../useShowReactionsSampleStatus";

function AddReactionMapDialog(props) {
  const {
    hideModal,
    handleSubmit,
    submitting,
    selectTableRecords,
    onSelectReactions,
    reactionsForAddReactionMapSelectedEntities: selectedReactions = []
  } = props;
  const [loading, setLoading] = useState(false);
  const [reactionMap, setReactionMap] = useState();

  const { switchField, sampleStatusInfo } = useShowReactionsSampleStatus({
    reactions: reactionMap?.reactions
  });

  function onSubmit() {
    onSelectReactions(selectedReactions);
    hideDialog();
  }

  const addReactionMapCreateReactionMapFragment = gql`
    fragment addReactionMapCreateReactionMapFragment on reactionMap {
      id
      reactions {
        id
        name
        reactionOutputs {
          id
          outputMaterial {
            ...createReactionMapMaterialFragment
          }
          outputAdditiveMaterial {
            ...createReactionMapReagentFragment
          }
        }
        reactionInputs {
          id
          conserved
          inputMaterial {
            ...createReactionMapMaterialFragment
          }
          inputAdditiveMaterial {
            ...createReactionMapReagentFragment
          }
        }
      }
    }
    ${createReactionMapMaterialFragment}
    ${createReactionMapReagentFragment}
  `;

  async function loadFullReactionMap(reactionMap) {
    setLoading(true);
    try {
      const fullReactionMap = await safeQuery(
        addReactionMapCreateReactionMapFragment,
        {
          variables: {
            id: reactionMap.id
          }
        }
      );
      setReactionMap(fullReactionMap);
      selectTableRecords(fullReactionMap.reactions);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error loading reaction map");
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <div style={{ maxWidth: 250 }}>
          <GenericSelect
            {...{
              name: "reactionMap", //the field name within the redux form Field
              label: "Reaction Map",
              schema: [{ path: "name" }],
              asReactSelect: true,
              isRequired: true,
              fragment: ["reactionMap", "id name"],
              onFieldSubmit: loadFullReactionMap
            }}
          />
          {reactionMap && switchField}
        </div>
        {(loading || reactionMap) && (
          <DataTable
            formName="reactionsForAddReactionMap"
            entities={reactionMap?.reactions || []}
            schema={
              reactionMap ? getReactionMapSchema(reactionMap.reactions) : []
            }
            isSimple
            withCheckboxes
            sampleStatusInfo={sampleStatusInfo}
            isLoading={loading}
          />
        )}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        disabled={!selectedReactions.length}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Add Reaction Map",
    style: {
      width: 1000
    }
  }),
  reduxForm({
    form: "addReactionMapToCreateReactionMapTool"
  }),
  withSelectTableRecords("reactionsForAddReactionMap"),
  withSelectedEntities("reactionsForAddReactionMap")
)(AddReactionMapDialog);
