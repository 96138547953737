/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const pcrMinimalSequenceIsLinkedFragment = gql`
  fragment pcrMinimalSequenceIsLinkedFragment on sequence {
    id
    polynucleotideMaterialId
  }
`;

const minimalSequenceFragmentForPCRPlanningJ5ReportFragment = gql`
  fragment minimalSequenceFragmentForPCRPlanningJ5ReportFragment on sequence {
    id
    name
    size
    hash
    polynucleotideMaterialId
  }
`;

export const pcrPlanningAssemblyPieceFragment = gql`
  fragment pcrPlanningAssemblyPieceFragment on j5AssemblyPiece {
    id
    sequence {
      ...pcrMinimalSequenceIsLinkedFragment
    }
  }
  ${pcrMinimalSequenceIsLinkedFragment}
`;

export const pcrPlanningJ5RunConstruct = gql`
  fragment pcrPlanningJ5RunConstruct on j5RunConstruct {
    id
    name
    sequence {
      ...minimalSequenceFragmentForPCRPlanningJ5ReportFragment
    }
    j5ConstructAssemblyPieces {
      id
      index
      assemblyPieceId
    }
  }
  ${minimalSequenceFragmentForPCRPlanningJ5ReportFragment}
`;

export const pcrPlanningJ5InputSequence = gql`
  fragment pcrPlanningJ5InputSequence on j5InputSequence {
    id
    sequence {
      ...pcrMinimalSequenceIsLinkedFragment
    }
  }
  ${pcrMinimalSequenceIsLinkedFragment}
`;

export const pcrPlanningJ5DirectSynthesis = gql`
  fragment pcrPlanningJ5DirectSynthesis on j5DirectSynthesis {
    id
    sequence {
      ...pcrMinimalSequenceIsLinkedFragment
    }
  }
  ${pcrMinimalSequenceIsLinkedFragment}
`;

export const pcrPlanningJ5AssemblyPiece = gql`
  fragment pcrPlanningJ5AssemblyPiece on j5AssemblyPiece {
    id
    sequence {
      ...pcrMinimalSequenceIsLinkedFragment
    }
  }
  ${pcrMinimalSequenceIsLinkedFragment}
`;

export const pcrPlanningJ5OligoSynthesis = gql`
  fragment pcrPlanningJ5OligoSynthesis on j5OligoSynthesis {
    id
    oligo {
      id
      sequence {
        ...pcrMinimalSequenceIsLinkedFragment
      }
    }
  }
  ${pcrMinimalSequenceIsLinkedFragment}
`;

export const pcrPlanningJ5PcrReaction = gql`
  fragment pcrPlanningJ5PcrReaction on j5PcrReaction {
    id
    name
    note
    oligoDeltaTm
    oligoDeltaTm3Prime
    oligoMeanTm
    oligoMeanTm3Prime
    pcrProductSequence {
      id
      j5AssemblyPiece {
        id
        name
      }
      ...minimalSequenceFragmentForPCRPlanningJ5ReportFragment
    }
    primaryTemplate {
      ...minimalSequenceFragmentForPCRPlanningJ5ReportFragment
    }
    # secondaryTemplate {
    #   ...minimalSequenceFragmentForPCRPlanningJ5ReportFragment
    # }
    forwardPrimer {
      id
      name
      sequenceId
      sequence {
        ...minimalSequenceFragmentForPCRPlanningJ5ReportFragment
      }
    }
    reversePrimer {
      id
      name
      sequenceId
      sequence {
        ...minimalSequenceFragmentForPCRPlanningJ5ReportFragment
      }
    }
  }
  ${minimalSequenceFragmentForPCRPlanningJ5ReportFragment}
`;
