/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import {
  VALIDATOR_HEIGHT,
  VALIDATOR_ICON_WIDTH
} from "../../../../../../src-shared/components/HierarchicalDesign/constants";

class RestrictionSite extends React.PureComponent {
  /**
   * The total displacement of left side of the indicator from the left end
   * of the bin, in pixels.
   */
  getXOffset() {
    const { on5PrimeCard, singleBinWidth } = this.props;

    // When rendering, junctions corresponding to inject sequence will always be on the 5 prime card.
    if (this.isInjected()) {
      return singleBinWidth - VALIDATOR_ICON_WIDTH / 2;
    }
    // Otherwise, the overhangType must be INSERT.
    if (on5PrimeCard) {
      return singleBinWidth - VALIDATOR_ICON_WIDTH;
    } else {
      return 0;
    }
  }

  handleClick = () => {
    const { junctionId, cardId, selectValidatedJunction } = this.props;
    selectValidatedJunction({ junctionId, cardId });
  };

  isInjected() {
    return !!this.props.injectsSequence;
  }

  /**
   * Render some things that are specific to injected sequence junctions
   */
  renderInjectedSpecificItems() {
    const { color } = this.props;
    return (
      <rect
        {...{
          fill: "none",
          stroke: color,
          strokeWidth: 1,
          x: -5,
          y: 25,
          width: 70 - 10,
          height: 60 - 10,
          rx: 3,
          ry: 3
        }}
      />
    );
  }

  render() {
    const { color, isSelected } = this.props;
    return (
      <svg
        height={VALIDATOR_HEIGHT}
        width={VALIDATOR_ICON_WIDTH}
        viewBox="-10 20 70 60"
        onClick={this.handleClick}
        style={{
          transform:
            `translate(${this.getXOffset()}px,0px)` +
            (isSelected ? "scale(1.15)" : ""),
          cursor: "pointer"
        }}
      >
        {this.isInjected() && this.renderInjectedSpecificItems()}
        <path
          fill="none"
          stroke={color}
          strokeWidth={isSelected ? "5" : "3"}
          d="M 13 46 L 37 46 M 13 54 L 37 54"
        />
      </svg>
    );
  }
}

export default compose()(RestrictionSite);
