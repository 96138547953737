/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import TagFilterMenu from "../TagFilterMenu";
import { exportTagsColumn } from "./tagUtils";
import { pick } from "lodash";
import TagTableColumn from "../TagTableColumn";
import { get } from "lodash";

export const tagColumn = {
  path: "tags",
  type: "string",
  displayName: "Tags",
  searchDisabled: true,
  sortDisabled: true,
  FilterMenu: TagFilterMenu,
  getClipboardData: exportTagsColumn,
  filterIsActive: currentParams => currentParams.tags
};

export const tagColumnWithRender = {
  ...tagColumn,
  render: (val, record, row, props) => {
    const paramProps = pick(props, ["currentParams", "setNewParams"]);
    return (
      <TagTableColumn
        {...{
          record,
          paramProps
        }}
      />
    );
  }
};

export const tagColumnWithRenderNested = path => {
  return {
    ...tagColumnWithRender,
    render: (val, record, row, props) =>
      tagColumnWithRender.render(val, get(record, path) || {}, row, props)
  };
};
