/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* eslint react/jsx-no-bind: 0 */
import React from "react";
import { get, isEmpty } from "lodash";
import {
  ReactSelectField,
  FileUploadField,
  BlueprintError,
  getIdOrCodeOrIndex,
  wrapDialog,
  DialogFooter
} from "@teselagen/ui";
import { Tooltip, Button, ButtonGroup, Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import withQuery from "../../../../../src-shared/withQuery";
import {
  parseReactionMapCsvData,
  createReactionMapMaterialFragment,
  createReactionMapReagentFragment
} from "../../../../../../tg-iso-lims/src/utils/reactionMapUtils";

import "../style.css";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import {
  arrayToIdOrCodeValuedOptions,
  throwFormError
} from "../../../../../src-shared/utils/formUtils";
import { reactionMapCsvFields } from "../../../../utils/reactionMapUtils";
import { hideDialog, showDialog } from "../../../../../src-shared/GlobalDialog";
import AddReactionMapDialog from "../AddReactionMapDialog";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { TgEditableTable } from "../../../../../src-shared/components/TgEditableTable";
import { reduxForm } from "redux-form";
import ReactionMapNameField from "../../../ReactMapNameField";

class DefineReactionMap extends React.Component {
  helperProp = {};
  render() {
    const {
      stepFormProps: { change },
      toolSchema,
      reactionTypes = [],
      submitFailed,
      Footer,
      footerProps,
      handleSubmit,
      nextStep,
      error
    } = this.props;

    const onSelectReactions = newReactions => {
      this.helperProp.addEditableTableEntities(
        newReactions.map(r => {
          const additiveInputs = r.reactionInputs.filter(
            i => i.inputAdditiveMaterial
          );
          return {
            name: r.name,
            inputMaterials: r.reactionInputs
              .filter(i => i.inputMaterial)
              .map(i => i.inputMaterial),
            consumedAdditiveMaterials: additiveInputs
              .filter(i => !i.conserved)
              .map(i => i.inputAdditiveMaterial),
            conservedAdditiveMaterials: additiveInputs
              .filter(i => i.conserved)
              .map(i => i.inputAdditiveMaterial),
            outputMaterials: r.reactionOutputs
              .filter(o => o.outputMaterial)
              .map(o => o.outputMaterial),
            outputAdditiveMaterials: r.reactionOutputs
              .filter(o => o.additiveMaterial)
              .map(o => o.outputAdditiveMaterial)
          };
        })
      );
    };
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Reaction Map"
              helper={`Provide a reaction map name and specify a reaction type.
            Then either manually add reactions, specifying input and output
            materials or upload a CSV containing reaction information.`}
            />
            <ReactionMapNameField
              toolSchema={toolSchema}
              style={{ minWidth: 450, marginRight: 20 }}
            />
          </div>
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper header="Choose Reaction Type" />
          <ReactSelectField
            name="reactionTypeCode"
            isRequired
            label="Reaction Type"
            onFieldSubmit={() => {
              setTimeout(() => {
                this.helperProp.updateValidationHelper();
              }, 0);
            }}
            options={arrayToIdOrCodeValuedOptions(reactionTypes)}
            style={{ minWidth: 450 }}
          />
        </div>
        <div className="tg-step-form-section column">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <HeaderWithHelper
              header="Select Reactions"
              helper="Add new reactions manually or start by choosing existing reactions from other reaction maps."
            />
          </div>

          <div>
            <ButtonGroup style={{ marginBottom: 10 }}>
              <Tooltip content="Select a reaction map to populate the table below with existing reactions">
                <Button
                  intent="primary"
                  icon="plus"
                  text="Add Reactions via Reaction Map"
                  onClick={() => {
                    showDialog({
                      ModalComponent: AddReactionMapDialog,
                      modalProps: {
                        onSelectReactions
                      }
                    });
                  }}
                />
              </Tooltip>
              <Button
                intent="primary"
                icon="plus"
                text="Add Reactions via CSV"
                onClick={() => {
                  showDialog({
                    ModalComponent: AddReactionsViaCsvDialog,
                    modalProps: {
                      onSelectReactions:
                        this.helperProp.addEditableTableEntities
                    }
                  });
                }}
              />
            </ButtonGroup>
            <div className="reaction-rows">
              <TgEditableTable
                tableProps={{
                  helperProp: this.helperProp,
                  destroyOnUnmount: false,
                  keepDirtyOnReinitialize: true
                }}
                schema={{
                  tableWideValidation: ({ entities }) => {
                    const toRet = {};
                    entities.forEach(reaction => {
                      // let reactionTypeWarning = false;
                      let reactionTypeMessage = "";
                      let optionalInputTypes = false;
                      const { reactionTypeCode } = this.props;
                      if (
                        reactionTypeCode &&
                        Object.keys(reactionMaterialTypeMap).includes(
                          reactionTypeCode
                        ) &&
                        !isEmpty(reaction)
                      ) {
                        optionalInputTypes =
                          reactionMaterialTypeMap[reactionTypeCode]
                            .optionalInputTypes;
                        if (
                          reaction.inputMaterials &&
                          reaction.inputMaterials.length > 0
                        ) {
                          if (optionalInputTypes) {
                            if (
                              !reaction.inputMaterials.some(mat =>
                                reactionMaterialTypeMap[
                                  reactionTypeCode
                                ].inputTypes.includes(mat.materialTypeCode)
                              )
                            ) {
                              // reactionTypeWarning = true;
                              if (reactionTypeMessage) {
                                reactionTypeMessage += `\n`;
                              }
                              reactionTypeMessage += `You specified a reaction type of ${
                                reactionMaterialTypeMap[reactionTypeCode].name
                              }, which usually has the following input types: ${reactionMaterialTypeMap[
                                reactionTypeCode
                              ].inputTypes
                                .map(type => modelNameToReadableName(type))
                                .join(", ")}.`;
                            }
                          } else {
                            reactionMaterialTypeMap[
                              reactionTypeCode
                            ].inputTypes.forEach(inputType => {
                              if (
                                !reaction.inputMaterials.some(
                                  inputMaterial =>
                                    inputMaterial.materialTypeCode === inputType
                                )
                              ) {
                                // reactionTypeWarning = true;
                                if (reactionTypeMessage) {
                                  reactionTypeMessage += `\n`;
                                }
                                reactionTypeMessage += `You specified a reaction type of ${
                                  reactionMaterialTypeMap[reactionTypeCode].name
                                }, but this reaction is missing a ${modelNameToReadableName(
                                  inputType
                                )} material.`;
                              }
                            });
                          }
                        }

                        const outputMaterialTypes = get(
                          reaction,
                          "outputMaterials",
                          []
                        ).map(m => m.materialTypeCode);
                        if (
                          outputMaterialTypes.length &&
                          outputMaterialTypes.some(
                            t =>
                              t !==
                              reactionMaterialTypeMap[reactionTypeCode]
                                .outputType
                          )
                        ) {
                          if (reactionTypeMessage) {
                            reactionTypeMessage += `\n`;
                          }
                          reactionTypeMessage += `You specified a reaction type of ${
                            reactionMaterialTypeMap[reactionTypeCode].name
                          }, which requires all output materials to be ${modelNameToReadableName(
                            reactionMaterialTypeMap[reactionTypeCode].outputType
                          )}.`;

                          // You specified a reaction type of transformation, which requires output materials to be microbial
                          // reactionTypeWarning = true;
                        }
                        if (
                          reaction.consumedAdditiveMaterials &&
                          reaction.consumedAdditiveMaterials.some(am =>
                            reaction.conservedAdditiveMaterials?.find?.(
                              cam => cam.id === am.id
                            )
                          )
                        ) {
                          if (reactionTypeMessage) {
                            reactionTypeMessage += `\n`;
                          }

                          toRet[
                            `${getIdOrCodeOrIndex(
                              reaction
                            )}:consumedAdditiveMaterials`
                          ] =
                            "Can not have a reagent that is both consumed and conserved.";
                        }
                      }
                      const showReactionValidation =
                        reactionTypeCode &&
                        reaction.inputMaterials &&
                        reaction.inputMaterials.length > 0 &&
                        reaction.outputMaterials &&
                        reaction.outputMaterials.length > 0;

                      if (showReactionValidation && reactionTypeMessage) {
                        toRet[
                          `${getIdOrCodeOrIndex(reaction)}:inputMaterials`
                        ] = reactionTypeMessage;
                      }
                    });
                    return toRet;
                  },
                  requireAtLeastOneOf: [
                    [
                      "inputMaterials",
                      "consumedAdditiveMaterials",
                      "conservedAdditiveMaterials"
                    ],
                    ["outputMaterials", "outputAdditiveMaterials"]
                  ],
                  fields: [
                    {
                      path: `name`,
                      isRequired: true,
                      isUnique: true,
                      defaultValue: i => `reaction ${i + 1}`,
                      displayName: "Reaction Name",
                      description: "Name of the reaction. Required."
                    },
                    {
                      path: `inputMaterials`,
                      type: "genericSelect",
                      isMultiSelect: true,
                      displayName: "Input Materials",
                      description:
                        "Materials used as inputs for the reaction. Conditional: Required if not providing input reagents.",
                      schema: ["name", "materialTypeCode"],
                      fragment: createReactionMapMaterialFragment
                    },
                    {
                      path: `consumedAdditiveMaterials`,
                      type: "genericSelect",
                      isMultiSelect: true,
                      displayName: "Input Reagents",
                      description:
                        "Reagents used as inputs for the reaction. Conditional: Required if not providing input materials.",
                      schema: ["name", "additiveTypeCode"],
                      fragment: createReactionMapReagentFragment
                    },
                    {
                      path: `conservedAdditiveMaterials`,
                      type: "genericSelect",
                      isMultiSelect: true,
                      displayName: "Input Reagents (Conserved)",
                      description:
                        "Reagents that are conserved during the reaction. Optional: These reagents will remain in the destination well after the reaction.",
                      schema: ["name", "additiveTypeCode"],
                      fragment: createReactionMapReagentFragment
                    },
                    {
                      path: `outputMaterials`,
                      type: "genericSelect",
                      isMultiSelect: true,
                      displayName: "Output Materials",
                      description:
                        "Materials produced as outputs from the reaction. Conditional: Required if not providing an output reagent.",
                      schema: ["name", "materialTypeCode"],
                      fragment: createReactionMapMaterialFragment
                    },
                    {
                      path: `outputAdditiveMaterials`,
                      type: "genericSelect",
                      isMultiSelect: true,
                      displayName: "Output Reagents",
                      description:
                        "Reagents produced as outputs from the reaction. Conditional: Required if not providing an output material.",
                      schema: ["name", "additiveTypeCode"],
                      fragment: createReactionMapReagentFragment
                    }
                  ]
                }}
              ></TgEditableTable>

              {submitFailed && <BlueprintError error={error} />}
            </div>
          </div>
        </div>
        <Footer
          {...footerProps}
          onClick={handleSubmit(() => {
            const entities =
              this.helperProp.getEditableTableInfoAndThrowFormError();
            change("reactions", entities);
            nextStep();
          })}
        />
      </React.Fragment>
    );
  }
}

const reactionMaterialTypeMap = {
  CLONAL_TRANSFORMATION: {
    name: "Transformation",
    inputTypes: ["MICROBIAL", "DNA"],
    outputType: "MICROBIAL"
  },
  PROTEIN_PURIFICATION: {
    name: "Protein Purification",
    inputTypes: ["MICROBIAL", "DNA"],
    optionalInputTypes: true,
    outputType: "PROTEIN"
  },
  ASSEMBLY_REACTION: {
    name: "Assembly Reaction",
    inputTypes: ["DNA"],
    outputType: "DNA"
  },
  PCR_REACTION: {
    name: "PCR Reaction",
    inputTypes: ["DNA"],
    outputType: "DNA"
  },
  CONJUGATION: {
    name: "Conjugation",
    inputTypes: ["MICROBIAL"],
    outputType: "MICROBIAL"
  },
  PLASMID_PURIFICATION: {
    name: "Plasmid Purification",
    inputTypes: ["MICROBIAL"],
    outputType: "DNA"
  },
  CRISPR_DESIGN: {
    name: "CRISPR Design",
    inputTypes: ["MICROBIAL", "DNA", "RNA"],
    outputType: "MICROBIAL"
  }
};

const AddReactionsViaCsvDialog = compose(
  wrapDialog({
    title: "Add Reactions via CSV"
  }),
  reduxForm({ form: "addReactionsViaCsv" })
)(({ submitting, error, handleSubmit, onSelectReactions }) => {
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <React.Fragment>
          <FileUploadField
            noBuildCsvOption
            accept={getDownloadTemplateFileHelpers({
              fileName: "create-reaction-map",
              validateAgainstSchema: {
                fields: reactionMapCsvFields
              }
            })}
            fileLimit={1}
            label="Upload Reactions"
            name="createReactionMapCsv"
          />
          {error && <BlueprintError error={error} />}
        </React.Fragment>
      </div>
      <DialogFooter
        loading={submitting}
        onClick={handleSubmit(async ({ createReactionMapCsv }) => {
          const file = createReactionMapCsv[0];
          if (!file) return false;

          let formError;
          let allReactions;
          try {
            const { parsedData } = file;
            const { error, reactionMaps } = await parseReactionMapCsvData([
              {
                data: parsedData,
                name: file.name
              }
            ]);
            if (error) {
              formError = error;
            } else {
              allReactions = reactionMaps[0].reactions;
            }
          } catch (error) {
            console.error(error);
            window.toastr.error("Error parsing file.");
          }
          if (formError) {
            throwFormError(formError);
          } else {
            hideDialog();
            onSelectReactions(allReactions);
          }
        })}
      />
    </>
  );
});

export default compose(
  withQuery(["reactionType", "code name"], { isPlural: true }),
  stepFormValues("reactions", "reactionTypeCode", "showUpload")
)(DefineReactionMap);
