/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isBrowser } from "browser-or-node";
import { keyBy } from "lodash";
import { isoContext } from "@teselagen/utils";
import { getActiveProjectId } from "@teselagen/auth-utils";

export default async function addRecordsToActiveProject(
  { recordIds = [], model },
  ctx = isoContext
) {
  let activeProjectId, projectTagsActive;
  if (isBrowser) {
    activeProjectId = getActiveProjectId();
    projectTagsActive = window.frontEndConfig.projectTags;
  } else {
    activeProjectId = ctx.projectId;
    projectTagsActive = process.env.PROJECT_TAGS;
  }

  if (!projectTagsActive || !recordIds.length || !activeProjectId) {
    return;
  }
  const key = model + "Id";
  const existingProjectItems = await ctx.safeQuery(
    ["projectItem", `id ${key}`],
    {
      variables: {
        filter: {
          [key]: recordIds,
          projectId: activeProjectId
        }
      }
    }
  );
  const keyed = keyBy(existingProjectItems, key);
  const toCreate = [];
  recordIds.forEach(id => {
    if (!keyed[id]) {
      toCreate.push({
        projectId: activeProjectId,
        [key]: id
      });
    }
  });
  await ctx.safeUpsert("projectItem", toCreate, {
    excludeResults: true
  });
}
