/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment tagFragment on tag {
    id
    name
    color
    isSystemType
    description
    tagOptions {
      id
      name
      color
      description
    }
  }
`;
