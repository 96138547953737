/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const arpJ5ConstructAssemblyPieceFragment = gql`
  fragment arpJ5ConstructAssemblyPieceFragment on j5ConstructAssemblyPiece {
    id
    j5RunConstructId
    assemblyPiece {
      id
      type
      j5AssemblyPieceParts {
        id
        j5InputPart {
          id
          j5InputSequence {
            id
            sequence {
              id
              polynucleotideMaterial {
                id
                name
              }
            }
          }
        }
      }
      sequence {
        id
        polynucleotideMaterial {
          id
          name
        }
      }
    }
  }
`;

export const arpDataRowFragment = gql`
  fragment arpDataRowFragment on dataRow {
    id
    dataRowJ5Items {
      id
      j5Item {
        id
        j5RunConstruct {
          id
          name
          sequence {
            id
            polynucleotideMaterial {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const arpContainerArrayFragment = gql`
  fragment arpContainerArrayFragment on containerArray {
    id
    name
    containerArrayType {
      id
      name
      isPlate
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      columnPosition
      rowPosition
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
        sample {
          id
          name
          material {
            id
            name
          }
        }
      }
    }
  }
`;

export const arpPlateMapGroupFragment = gql`
  fragment arpPlateMapGroupFragment on plateMapGroup {
    id
    name
    containerFormatCode
    containerFormat {
      code
      name
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
    }
    plateMaps {
      id
      name
      plateMapItems {
        id
        rowPosition
        columnPosition
        name
        volume
        volumetricUnitCode
        inventoryItem {
          id
          material {
            id
            name
          }
        }
      }
    }
  }
`;
