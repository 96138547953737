/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Button, Classes, Intent, MenuItem } from "@blueprintjs/core";
import { without, get } from "lodash";
import {
  DataTable,
  CollapsibleCard,
  showConfirmationDialog
} from "@teselagen/ui";

import { handleMicrobialPlasmidUpload } from "../../../../utils";
import TgSequenceEditor from "../../../../../src-shared/TgSequenceEditor";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { deleteWithQuery } from "../../../../../src-shared/apolloMethods";
import { sequenceToVeInput } from "../../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { updateSequenceEditor } from "../../../../../src-shared/utils/sequenceUtils";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";
import appGlobals from "../../../../../src-shared/appGlobals";

class MicrobialVeCard extends Component {
  state = {
    removingSequences: []
  };

  showUpload = () => {
    const { isStrain, strain, material, afterMaterialUpdate } = this.props;
    showDialog({
      modalType: "UPLOAD_MATERIAL_SEQUENCE",
      modalProps: {
        isStrain,
        strain,
        isMicrobialMaterial: true,
        material,
        refetch: afterMaterialUpdate
      }
    });
  };

  showLinkExisting = () => {
    const { material, strain, sequences, afterMaterialUpdate } = this.props;
    const linkPlasmidsToMaterial = async dnaMatsOrSeqs => {
      await handleMicrobialPlasmidUpload(
        strain
          ? {
              strainId: strain.id,
              strainTypeCode: strain.strainTypeCode,
              dnaMaterialIdsToAdd: dnaMatsOrSeqs.map(s => s.id)
            }
          : {
              materialId: material.id,
              materialTypeCode: material.materialTypeCode,
              dnaMaterialIdsToAdd: dnaMatsOrSeqs.map(s => s.id)
            }
      );
      await afterMaterialUpdate();
    };

    showDialog({
      modalType: "SELECT_GENERIC_ITEMS",
      modalProps: {
        isMultiSelect: true,
        onSelect: linkPlasmidsToMaterial,
        fragment: ["material", "id name"],
        schema: {
          model: "material",
          fields: ["name", dateModifiedColumn]
        },
        tableParamOptions: {
          additionalFilter: (props, qb) => {
            qb.whereAll({
              materialTypeCode: "DNA",
              id: qb.notInList(sequences.map(s => s.id))
            });
          }
        }
      }
    });
  };

  selectSequenceToView = r => {
    const sequence = r.polynucleotideMaterialSequence;
    if (sequence) {
      const veInputSequence = sequenceToVeInput(sequence);
      updateSequenceEditor(veInputSequence);
    } else {
      updateSequenceEditor({
        name: "No Sequence"
      });
    }
  };

  selectGenomicRegionToView = genomicRegion => {
    const veInputSequence = sequenceToVeInput(genomicRegion);
    updateSequenceEditor(veInputSequence, {
      editorName: "GenomicRegionEditor"
    });
  };

  removeSequenceAndPlasmid = async plasmid => {
    const {
      material = {},
      strain = {},
      afterMaterialUpdate,
      isStrain
    } = this.props;
    const removingSequences = [...this.state.removingSequences, plasmid.id];
    this.setState({
      removingSequences
    });
    try {
      const continueUnlink = await showConfirmationDialog({
        text: "Are you sure you would like to unlink this plasmid?",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      });
      if (continueUnlink) {
        if (!isStrain) {
          if (material.materialTypeCode === "MICROBIAL") {
            await deleteWithQuery("microbialMaterialPlasmid", {
              microbialMaterialId: material.id,
              "polynucleotideMaterial.id": plasmid.id
            });
          } else if (material.materialTypeCode === "CELL_CULTURE") {
            await deleteWithQuery("cellCulturePlasmid", {
              cellCultureId: material.id,
              "polynucleotideMaterial.id": plasmid.id
            });
          }
        } else if (isStrain) {
          // remove the plasmid from both the strain and it's microbial materials
          if (strain.strainTypeCode === "MICROBIAL_STRAIN") {
            await deleteWithQuery("microbialMaterialPlasmid", {
              "microbialMaterial.strainId": strain.id,
              "polynucleotideMaterial.id": plasmid.id
            });
          } else if (strain.strainTypeCode === "CELL_LINE") {
            await deleteWithQuery("cellCulturePlasmid", {
              "cellCulture.strainId": strain.id,
              "polynucleotideMaterial.id": plasmid.id
            });
          }
          await deleteWithQuery("strainPlasmid", {
            strainId: strain.id,
            "polynucleotideMaterial.id": plasmid.id
          });
        }
        await afterMaterialUpdate();
      }
    } catch (error) {
      window.toastr.error("Error removing plasmid");
      console.error("error:", error);
    }
    this.setState({
      removingSequences: without(removingSequences, plasmid.id)
    });
  };
  getSequenceColumns = () => {
    const { readOnly, isStrain, isGenomicRegionsCard } = this.props;
    const { removingSequences } = this.state;

    const repOriginColumn = {
      displayName: "Replication Origin",
      render: (v, r) => {
        const sequence = r.polynucleotideMaterialSequence;
        return (
          sequence?.sequenceFeatures.length > 0 &&
          sequence.sequenceFeatures
            .filter(feature => feature.type === "rep_origin")
            .map(feature => feature.name)
            .join(", ")
        );
      }
    };

    const inductionMethodColumn = {
      displayName: "Induction Methods",
      render: (v, r) => {
        const sequence = r.polynucleotideMaterialSequence;
        sequence?.plasmidInductionMethodPlasmids.length > 0 &&
          sequence.plasmidInductionMethodPlasmids
            .map(pim => get(pim, "inductionMethod.name"))
            .join(", ");
      }
    };
    const columns = [
      {
        displayName: "Name",
        path: "name"
      },
      {
        displayName: "Size",
        path: "polynucleotideMaterialSequence.size"
      },
      ...(isStrain
        ? []
        : [
            {
              displayName: "Copy Number",
              path: "copyNumber"
            }
          ]),
      inductionMethodColumn,
      repOriginColumn
    ];
    const removePlasmidColumn = {
      type: "action",
      width: 60,
      render: (v, record) => {
        return (
          <Button
            icon="trash"
            loading={removingSequences.includes(record.id)}
            intent={Intent.DANGER}
            className={Classes.MINIMAL}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.removeSequenceAndPlasmid(record);
            }}
          />
        );
      }
    };
    if (!readOnly && !isGenomicRegionsCard) {
      columns.push(removePlasmidColumn);
    }
    return columns;
  };

  editCopyNumber = record => {
    const { afterMaterialUpdate } = this.props;
    showDialog({
      modalType: "EDIT_COPY_NUMBER",
      modalProps: {
        initialValues: record,
        refetch: afterMaterialUpdate
      }
    });
  };

  copyNumberContextMenu = ({ selectedRecords }) => {
    const { isStrain } = this.props;
    let editCopyNumber;
    if (selectedRecords.length === 1 && !isStrain) {
      editCopyNumber = (
        <MenuItem
          key="edit-copy-number"
          icon="edit"
          text="Edit Copy Number"
          onClick={() => this.editCopyNumber(selectedRecords[0])}
        />
      );
    }
    return [editCopyNumber];
  };

  componentDidMount() {
    const { hasSequences, sequences, isGenomicRegionsCard } = this.props;
    if (hasSequences) {
      const firstSequence = sequences[0];
      if (firstSequence) {
        (isGenomicRegionsCard
          ? this.selectGenomicRegionToView
          : this.selectSequenceToView)(firstSequence);
      }
    }
  }
  render() {
    const {
      hasSequences,
      sequences,
      readOnly,
      isStrain,
      isGenomicRegionsCard
    } = this.props;
    const sequencesColumns = this.getSequenceColumns();

    return (
      <CollapsibleCard
        title={isGenomicRegionsCard ? "Genomic Regions" : "Plasmids"}
        className="tg-plasmids-card"
        openTitleElements={
          !readOnly &&
          !isGenomicRegionsCard && (
            <React.Fragment>
              <Button
                minimal
                text="Upload Sequences"
                intent={Intent.PRIMARY}
                onClick={this.showUpload}
              />
              <Button
                minimal
                text="Link Existing Plasmids"
                intent={Intent.PRIMARY}
                onClick={this.showLinkExisting}
              />
            </React.Fragment>
          )
        }
      >
        {hasSequences ? (
          <div className="tg-flex">
            <div style={{ width: "60%" }}>
              <DataTable
                schema={sequencesColumns}
                className="tg-plasmids"
                formName={
                  isGenomicRegionsCard
                    ? "materialRecordGenomicRegions"
                    : "materialRecordSequencePlasmids"
                }
                removingSequences={this.state.removingSequences}
                entities={sequences}
                contextMenu={this.copyNumberContextMenu}
                isSimple
                style={{ marginBottom: 15 }}
                maxHeight={450}
                compact
                noPadding
                onDoubleClick={r => {
                  if (r.polynucleotideMaterialSequence) {
                    appGlobals.history.push(
                      modelNameToLink(r.polynucleotideMaterialSequence)
                    );
                  } else if (r.__typename === "material") {
                    appGlobals.history.push(modelNameToLink(r));
                  }
                }}
                onSingleRowSelect={
                  isGenomicRegionsCard
                    ? this.selectGenomicRegionToView
                    : this.selectSequenceToView
                }
              />
            </div>
            <div style={{ width: "40%", minHeight: 500, marginLeft: 20 }}>
              <TgSequenceEditor
                {...this.props}
                editorName={
                  isGenomicRegionsCard ? "GenomicRegionEditor" : undefined
                }
              />
            </div>
          </div>
        ) : (
          <div>
            <i>
              {`There are no ${
                isGenomicRegionsCard ? "genomic regions" : "plasmids"
              } associated with this `}
              {isStrain ? "strain" : "material"}.
            </i>
          </div>
        )}
      </CollapsibleCard>
    );
  }
}

export default MicrobialVeCard;
