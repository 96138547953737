/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { Button } from "@blueprintjs/core";
import withQuery from "../../../../src-shared/withQuery";

import AbstractRecord from "../../../../src-shared/AbstractRecord";
import PlateMapView from "../../../components/PlateMapView";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import plateMapGroupRecordFragment from "../../../graphql/fragments/plateMapGroupRecordFragment";
import plateLayerFragment from "../../../graphql/fragments/plateLayerFragment";
import { getInitialPlateLayerValues } from "../../../utils/plateUtils";

import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import "./style.css";
import { groupBy, camelCase } from "lodash";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import UpdatePlateMapGroupDialog from "./UpdatePlateMapGroupDialog";

class PlateMapGroupRecordView extends Component {
  renderRecordHeaderInfoSection() {
    const { plateMapGroup } = this.props;
    return (
      <div className="record-header-info-section">
        <RecordInfoDisplay
          recordInfo={[
            ["Type", plateMapGroup.plateMaps[0]?.type],
            ["Plate Format", plateMapGroup.containerFormat?.name]
          ]}
          readOnly={this.props.readOnly}
          record={plateMapGroup}
        />
      </div>
    );
  }

  onRowDoubleClick = record => {
    const { history } = this.props;
    if (record.inventoryItem) {
      const inventoryRecord =
        record.inventoryItem[
          camelCase(record.inventoryItem.inventoryItemTypeCode)
        ];
      if (inventoryRecord) {
        history.push(modelNameToLink(inventoryRecord));
      }
    }
  };

  wrapPlateMapPlate = (plateMapPlate, { plateMap }) => {
    const { plateMapGroup, refetchPlateLayers, readOnly } = this.props;
    const { plateLayers = [] } = plateMap;
    let dialogProps;
    if (plateMapGroup.containerFormat.rowCount >= 16) {
      dialogProps = {
        style: {
          width: 1000
        }
      };
    }
    return (
      <div>
        {!readOnly && (
          <Button
            text="Update Plate Layers"
            intent="warning"
            style={{ marginBottom: 7 }}
            onClick={() => {
              showDialog({
                modalType: "CREATE_PLATE_LAYER",
                modalProps: {
                  containerFormat: plateMapGroup.containerFormat,
                  record: {
                    ...plateMap,
                    plateMapItems: generateContainerArray(
                      plateMap.plateMapItems,
                      plateMapGroup.containerFormat
                    )
                  },
                  refetch: refetchPlateLayers,
                  dialogProps,
                  initialValues: {
                    plateLayers: getInitialPlateLayerValues(
                      plateLayers,
                      plateMap.plateMapItems
                    )
                  }
                }
              });
            }}
          />
        )}
        {plateMapPlate}
      </div>
    );
  };

  renderContent() {
    const {
      plateMapGroup: partialPlateMapGroup,
      plateLayers = []
    } = this.props;
    let plateMapGroup = partialPlateMapGroup;
    if (plateLayers.length) {
      const layersById = groupBy(plateLayers, "plateMapId");

      plateMapGroup = {
        ...plateMapGroup,
        plateMaps: plateMapGroup.plateMaps.map(pm => {
          if (layersById[pm.id]) {
            return {
              ...pm,
              plateLayers: layersById[pm.id]
            };
          } else return pm;
        })
      };
    }
    return (
      <PlateMapView
        noPadding
        noTitle
        stacked={plateMapGroup.containerFormat?.rowCount >= 16}
        plateMapGroup={plateMapGroup}
        wrapPlateMapPlate={this.wrapPlateMapPlate}
        aliquotContainerDoubleClick={this.onRowDoubleClick}
        onTableRowDoubleClick={this.onRowDoubleClick}
      />
    );
  }

  render() {
    return (
      <AbstractRecord
        {...this.props}
        recordName="plateMapGroup"
        updateShowFunction={() => {
          showDialog({
            ModalComponent: UpdatePlateMapGroupDialog,
            modalProps: {
              initialValues: this.props.plateMapGroup
            }
          });
        }}
      >
        {this.renderRecordHeaderInfoSection()}
        {this.renderContent()}
      </AbstractRecord>
    );
  }
}

export default compose(
  recordViewEnhancer(plateMapGroupRecordFragment, {}),
  withQuery(plateLayerFragment, {
    isPlural: true,
    skip: props => !getPlateMapIds(props).length,
    options: props => {
      const plateMapIds = getPlateMapIds(props);
      return {
        variables: {
          filter: {
            plateMapId: plateMapIds
          }
        }
      };
    }
  })
)(PlateMapGroupRecordView);

function getPlateMapIds(props) {
  const { plateMapGroup } = props;
  if (!plateMapGroup) return [];
  return plateMapGroup.plateMaps.map(pm => pm.id);
}
