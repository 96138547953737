/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { isEqual } from "lodash";
import { connect } from "react-redux";

import { producesOverhang } from "../../../../../../../tg-iso-shared/utils/enzymeUtils";
import { getReferencedValue } from "../../../../../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../../../../../src-shared/redux/actions";
import {
  VALIDATOR_HEIGHT,
  VALIDATOR_ICON_WIDTH
} from "../../../../../../src-shared/components/HierarchicalDesign/constants";

const mapStateToProps = (state, props) => {
  const { junctionId } = props;

  const restrictionEnzyme = getReferencedValue(
    state,
    "junction",
    junctionId,
    "restrictionEnzymeId"
  );
  return {
    enzymeProducesOverhang: producesOverhang(restrictionEnzyme)
  };
};

const mapDispatchToProps = {
  selectValidatedJunction:
    actions.ui.designEditor.general.selectValidatedJunction
};
class RestrictionSite extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  getIndicator(d, key) {
    const {
      on5PrimeCard,
      color,
      enzymeProducesOverhang,
      isSelected
    } = this.props;

    // We need to do this step in order to get the rendering to work correctly.
    let direction = !on5PrimeCard;
    if (enzymeProducesOverhang) direction = !direction;

    return (
      <svg
        key={key}
        height={VALIDATOR_HEIGHT}
        width={VALIDATOR_ICON_WIDTH}
        viewBox="-10 20 70 60"
        style={{
          transform:
            (!direction ? "rotate(180deg)" : "") +
            (isSelected ? "scale(1.15)" : ""),
          cursor: "pointer"
        }}
        onClick={this.handleClick}
      >
        <path
          fill="none"
          stroke={color}
          strokeWidth={isSelected ? "5" : "3"}
          d={d}
        />
      </svg>
    );
  }

  getRecognitionSiteIndicator() {
    return this.getIndicator(
      "M 15, 40  m -9, 0  a 9,9 0 1,0 18,0  a 9,9 0 1,0 -18,0 M 21.3 46.3 l 20 20 M 27.3 50.3 l 15 15 M 25.3 52.3 l 15 15",
      "rs"
    );
  }

  getCutsiteIndicator() {
    const { enzymeProducesOverhang } = this.props;
    const d = enzymeProducesOverhang
      ? "M 10 46 L 40 46 M 25 54 L 40 54"
      : "M 10 46 L 40 46 M 25 54 L 10 54";
    return this.getIndicator(d, "cs");
  }

  /**
   * Get the x offset of the indicators in pixels. This reflects the relative position of
   * the validator. Thi
   */
  getNonAdapterXOffset() {
    const { overhangType, singleBinWidth, on5PrimeCard } = this.props;
    if (on5PrimeCard) {
      if (overhangType === "FLANKING_SEQUENCE") {
        return singleBinWidth;
      } else if (overhangType === "INSERT") {
        return singleBinWidth - VALIDATOR_ICON_WIDTH;
      }
    } else {
      if (overhangType === "FLANKING_SEQUENCE") {
        return -2 * VALIDATOR_ICON_WIDTH;
      } else if (overhangType === "INSERT") {
        return -VALIDATOR_ICON_WIDTH;
      }
    }
  }

  renderAdapter() {
    const { singleBinWidth, on5PrimeCard } = this.props;
    return (
      <React.Fragment>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            width: singleBinWidth
          }}
        >
          {this.getCutsiteIndicator()}
          <div
            style={{
              position: "absolute",
              [on5PrimeCard ? "right" : "left"]: -VALIDATOR_ICON_WIDTH
            }}
          >
            {this.getRecognitionSiteIndicator()}
          </div>
        </span>
      </React.Fragment>
    );
  }

  renderNonAdapter() {
    const { on5PrimeCard } = this.props;

    const indicators = [
      this.getRecognitionSiteIndicator(),
      this.getCutsiteIndicator()
    ];
    if (on5PrimeCard) indicators.reverse();
    return (
      <span
        style={{
          display: "block",
          transform: `translate(${this.getNonAdapterXOffset()}px,0px)`
        }}
      >
        {indicators}
      </span>
    );
  }

  handleClick = () => {
    const { junctionId, cardId, selectValidatedJunction } = this.props;
    selectValidatedJunction({ junctionId, cardId });
  };

  render() {
    const { overhangType } = this.props;
    if (overhangType === "ADAPTER") {
      return this.renderAdapter();
    } else if (
      overhangType === "FLANKING_SEQUENCE" ||
      overhangType === "INSERT"
    ) {
      return this.renderNonAdapter();
    } else {
      throw new Error(`Invalid overhang type: ${overhangType}`);
    }
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RestrictionSite
);
