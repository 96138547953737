/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";

import { Dialog, Classes } from "@blueprintjs/core";
import { DialogFooter, InputField } from "@teselagen/ui";

import { cloneDeep } from "lodash";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import sIfPlural from "../../../../src-shared/utils/sIfPlural";

import recurseOnJson from "../../../../../tg-iso-design/utils/recurseOnJson";
import schematicFragment from "../../../graphql/fragments/schematicFragment";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";

class DuplicateSchematicDialog extends React.Component {
  onSubmit = defaultAsyncWrap(async ({ name }) => {
    const { hideModal, refetch, selectedRecords } = this.props;

    try {
      const schematics = await safeQuery(schematicFragment, {
        isPlural: true,
        variables: {
          filter: { id: selectedRecords.map(r => r.id) },
          pageSize: selectedRecords.length
        }
      });
      const valuesToUpsert = cloneDeep(schematics);
      recurseOnJson(
        valuesToUpsert,
        value => {
          delete value.id;
          delete value.code;
          delete value.cid;
          delete value.labId;
          delete value.lab;
          delete value.__typename;
        },
        { callOnObjectsOnly: true }
      );

      valuesToUpsert.forEach(seq => {
        seq.name = name;
      });

      await safeUpsert("schematic", valuesToUpsert);
      await refetch();

      hideModal();

      window.toastr.success(
        `Duplicated ${valuesToUpsert.length} schematic${
          valuesToUpsert.length > 1 ? "s" : ""
        }.`
      );
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error duplicating schematic${selectedRecords.length > 1 ? "s" : ""}s.`
      );
    }
  });

  render() {
    const { hideModal, handleSubmit, selectedRecords } = this.props;
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title={"Duplicate Schematic" + sIfPlural(selectedRecords)}
        style={{ width: 350 }}
      >
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className={Classes.DIALOG_BODY}>
            <InputField
              name="name"
              label="Schematic copy name"
              defaultValue={selectedRecords[0].name + " copy"}
            />
          </div>
          <DialogFooter hideModal={hideModal} text="OK" />
        </form>
      </Dialog>
    );
  }
}

const validate = (values, { selectedRecords }) => {
  const errors = {};
  if (selectedRecords.length && !values.name) errors.name = "Required.";
  return errors;
};

export default compose(
  reduxForm({
    form: "duplicateSchematicDialog", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(DuplicateSchematicDialog);
