/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { BlueprintError } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { Button, Intent } from "@blueprintjs/core";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { validateTransfers } from "../../../../utils";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import ReactionMapNameField from "../../../ReactMapNameField";

class ReviewWorklists extends Component {
  render() {
    const {
      toolSchema,
      worklist = {},
      aliquotContainerTypes = [],
      Footer,
      footerProps
    } = this.props;
    const error = validateTransfers(worklist, aliquotContainerTypes);

    const valid = !error;
    return (
      <React.Fragment>
        <ReviewWorklistSection toolSchema={toolSchema} />
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="PCR Reaction Map"
            helper="Enter a name for the output reaction map."
          />
          <ReactionMapNameField toolSchema={toolSchema} />
        </div>
        <BlueprintError error={error} />
        <Footer
          {...footerProps}
          nextButton={
            <Button
              type="submit"
              intent={Intent.SUCCESS}
              disabled={!valid}
              loading={footerProps.submitting}
            >
              Generate Worklist
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("worklist"),
  withQuery(aliquotContainerTypeFragment, { isPlural: true })
)(ReviewWorklists);
