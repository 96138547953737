/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { RadioGroupField } from "@teselagen/ui";

function TemperatureZoneOrientationField() {
  return (
    <RadioGroupField
      name="temperatureZoneOrientation"
      label="Temperature Zone Orientation"
      defaultValue="vertical"
      options={[
        { label: "Vertical", value: "vertical" },
        { label: "Horizontal", value: "horizontal" }
      ]}
    />
  );
}

export default TemperatureZoneOrientationField;
