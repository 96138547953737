/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const commonLabModels = [
  "assignedPosition",
  "barcode",
  "container",
  "containerDefaultPosition",
  "containerPosition",
  "equipmentDefaultPosition",
  "equipmentFile",
  "equipmentItem",
  "equipmentPosition",
  "genus",
  "location",
  "placementLocation",
  "placementSection",
  "placementContainerType",
  "placementStrategy",
  "prioritySubSection",
  "site",
  "specie"
  // "microserviceIoFile",
  // "microserviceQueue"
];
