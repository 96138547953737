/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { get, keyBy } from "lodash";
import { withRouter } from "react-router-dom";
import { render } from "mustache";

import actions from "../../src-shared/redux/actions";
import { CELL_HEIGHT } from "../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";

import {
  getNumberOfRowsForCard,
  isCardRoot,
  treeLayout,
  getDesign
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";
import {
  LIST_ELEMENT_ROW_HEIGHT,
  ROW_INDEX_WIDTH
} from "../../src-shared/components/HierarchicalDesign/constants";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { times } from "lodash";
import { forEach } from "lodash";

import { withEditorContext } from "../components/HierarchicalDesign/DesignEditorContext";
import defaultJ5OutputNamingTemplateMap from "../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import { Position, Tooltip } from "@blueprintjs/core";
// TO DO: CHANGE IMPORT INTO PACKAGE
// right now it does't work because frontend doesn't have fs, so it cannot import the whole library.
// Necessary to divide the library into smaller pieces
import processTemplate from "../../../platform/packages/j5-parser/src/j5Parser/processTemplate";

const RowNameCell = withEditorContext(function RowNameCell({
  numberOfRows,
  j5OutputNamingTemplate,
  cellHeight,
  isClassicLayout,
  selectedIndexes,
  selectedCard,
  cardId,
  selectRow,
  level,
  designName,
  designId
}) {
  let templateToUse =
    defaultJ5OutputNamingTemplateMap.assembledConstructs.template;
  forEach(j5OutputNamingTemplate, ({ outputTarget, template }) => {
    if (outputTarget === "assembledConstructs") {
      templateToUse = template;
    }
  });
  const toProcess = {
    template: templateToUse
  };
  processTemplate(toProcess);
  const { numDigits, incrementStart, template } = toProcess;
  templateToUse = template;
  let style = {};

  if (isClassicLayout) {
    style = { top: "-45px" };
  } else {
    style = { top: "2px" };
  }

  return (
    <div
      key="row-index-container"
      className="row-index-container"
      style={style}
    >
      <div>
        <svg
          width={150}
          height={numberOfRows * cellHeight}
          key="rows-container"
          className="rows-container"
        >
          {times(numberOfRows, i => {
            const name = render(templateToUse, {
              // default_name: construct.name,
              assembly_run_id: `{{{assembly_run_id}}}`,
              reaction_prefix: `{{{reaction_prefix}}}`,
              design_name: designName,
              design_id: designId,
              increment_from: numDigits
                ? (incrementStart + i).toString().padStart(numDigits, 0)
                : incrementStart + i
            });

            const truncatedName = getNameText({ name });
            return (
              <Tooltip
                wrapperTagName="g"
                targetTagName="g"
                disabled={!truncatedName.endsWith("..")}
                position={Position.RIGHT}
                content={name}
              >
                <g
                  key={`card-${level}-row-${i}`}
                  data-test="row-index"
                  onClick={e => {
                    e.stopPropagation();
                    selectRow({
                      cardId,
                      index: i,
                      shift: e.shiftKey,
                      ctrl: e.ctrlKey || e.metaKey
                    });
                  }}
                >
                  <rect
                    className={`row-index-cell-outline ${
                      get(selectedIndexes, i, null) && selectedCard === cardId
                        ? "row-index-cell-outline-active"
                        : ""
                    }`}
                    {...{
                      x: 0,
                      y: cellHeight * i,
                      width: 150,
                      height: cellHeight,
                      shapeRendering: "crispEdges"
                    }}
                  />

                  <text
                    className="row-index-cell-label"
                    {...{
                      x: 150 / 2,
                      y: cellHeight * (i + 1) - cellHeight / 3
                    }}
                  >
                    {truncatedName}
                  </text>
                </g>
              </Tooltip>
            );
          })}
        </svg>
      </div>
    </div>
  );
});

const mapStateToProps = (state, props) => {
  const { cardId } = props;
  const isClassicLayout = treeLayout(state) === "classic";
  const selectedIndexes = keyBy(
    state.ui.designEditor.general.selectedCellPaths,
    "index"
  );
  const selectedCard = get(
    state.ui.designEditor.general,
    "selectedCardId",
    null
  );

  return {
    numberOfRows: getNumberOfRowsForCard(state, cardId),
    isLocked: isDesignLocked(state),
    designName: getDesign(state).name,
    designId: getDesign(state).id,
    isRoot: isCardRoot(state, cardId),
    j5OutputNamingTemplate: state.design.j5OutputNamingTemplate,
    cellWidth: isClassicLayout ? CELL_HEIGHT : ROW_INDEX_WIDTH,
    cellHeight: isClassicLayout ? CELL_HEIGHT : LIST_ELEMENT_ROW_HEIGHT,
    isClassicLayout,
    selectedIndexes,
    selectedCard
  };
};

const mapDispatchToProps = {
  selectRow: actions.ui.designEditor.general.selectRow,
  selectAllRows: actions.ui.designEditor.general.selectAllRows
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(RowNameCell);

const getNameText = ({ name }) => {
  const maxLength = 18;
  return name.length > maxLength ? name.substring(0, maxLength) + ".." : name;
};
