/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getSelectedCardId } from "../../../../src-shared/selectors/designViewSelectors";
import {
  getReferencedValue,
  getBinIdsThatCanFillTemplatePlaceholders,
  getBinsInCard
} from "../../../../../tg-iso-design/selectors/designStateSelectors";

import { difference } from "lodash";
import { getAllIndicesBetweenTwoLocations } from "../../../../src-shared/utils/getAllIndicesBetweenTwoLocations";
import {
  SBOL_ICON_HEIGHT,
  SBOL_ICON_WIDTH
} from "../../../../src-shared/components/HierarchicalDesign/constants";

const getIconsToRender = createSelector(
  state => state,
  state => {
    const cardId = getSelectedCardId(state);
    const bins = getBinsInCard(state, cardId) || [];

    return bins.map(b => {
      return {
        ...getReferencedValue(state, "bin", b.id, "iconId"),
        direction: b.direction
      };
    });
  }
);

const mapStateToProps = state => {
  const cardId = getSelectedCardId(state);
  const bins = getBinsInCard(state, cardId) || [];
  const icons = getIconsToRender(state);
  const selectableBinIds = cardId
    ? getBinIdsThatCanFillTemplatePlaceholders(state, cardId)
    : [];
  const selectableBinIndices = selectableBinIds.map(setId =>
    bins.findIndex(s => s.id === setId)
  );
  return { bins, icons, selectableBinIndices };
};

class SelectBinsForTemplate extends React.Component {
  state = { activeIndex: null };

  handleIconClick = index => e => {
    const {
      setParentState,
      selectedBinIndices,
      selectableBinIndices
    } = this.props;
    const { activeIndex } = this.state;

    const shift = e.shiftKey;
    const ctrl = e.ctrlKey || e.metaKey;

    let newSelectedBinIndices;
    let newActiveIndex = activeIndex === null ? index : activeIndex;
    const onNormalLeftClick = () => {
      newSelectedBinIndices = [index];
      newActiveIndex = index;
    };
    if (shift) {
      if (activeIndex !== null) {
        newSelectedBinIndices = getAllIndicesBetweenTwoLocations(
          activeIndex,
          index
        );
      } else {
        onNormalLeftClick();
      }
    } else if (ctrl) {
      if (selectedBinIndices.length) {
        if (selectedBinIndices.includes(index)) {
          newSelectedBinIndices = difference(selectedBinIndices, [index]);
        } else {
          newSelectedBinIndices = [...selectedBinIndices, index];
        }
      } else {
        onNormalLeftClick();
      }
    } else {
      onNormalLeftClick();
    }

    setParentState({
      selectedBinIndices: [...newSelectedBinIndices]
        .sort((a, b) => a - b)
        .filter(ind => selectableBinIndices.includes(ind))
    });
    this.setState({
      activeIndex: newActiveIndex
    });
  };

  renderIcon = (icon, index) => {
    const { selectedBinIndices, selectableBinIndices } = this.props;

    const unselectable = !selectableBinIndices.includes(index);
    const stroke = unselectable ? "#969696" : icon.color || "black";
    const iconPath = icon.path;
    const isSelected = selectedBinIndices.includes(index);
    return (
      <div
        key={index}
        style={{
          display: "inline-block",
          cursor: !unselectable && "pointer",
          background: isSelected && "#92a4b6"
        }}
        onClick={this.handleIconClick(index)}
      >
        {iconPath.match("uploads") || iconPath.match("images") ? (
          <img
            alt={icon.name}
            src={"/" + iconPath}
            style={{
              width: SBOL_ICON_WIDTH,
              height: SBOL_ICON_HEIGHT,
              transform: !icon.direction ? "rotate(180deg)" : ""
            }}
          />
        ) : (
          <svg
            viewBox="-10 20 70 60"
            height={SBOL_ICON_HEIGHT}
            width={SBOL_ICON_WIDTH}
            style={{
              transform: !icon.direction ? "rotate(180deg)" : ""
            }}
          >
            <path stroke={stroke} strokeWidth="3" fill="none" d={iconPath} />
          </svg>
        )}
      </div>
    );
  };

  render() {
    const { icons, scale } = this.props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          transform: `scale(${scale})`
        }}
      >
        {icons.map((icon, i) => this.renderIcon(icon, i))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SelectBinsForTemplate);
