/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export function formatDate(date, customFormat) {
  const format = customFormat || "ll";
  return dayjs(date).format(format);
}

export function formatDateTime(date, customFormat) {
  const format = customFormat || "lll";
  return dayjs(date).format(format);
}

export function formatDuration(startDate, endDate) {
  return dayjs.duration(endDate.getTime() - startDate.getTime()).humanize();
}

export function displayDateTime(date) {
  const dtm = valueToDayjsOrNull(date);
  if (dtm === null) return "";
  return formatDateTime(dtm);
}

export function displayDuration(startDate, endDate) {
  const start = valueToDayjsOrNull(startDate);
  const end = valueToDayjsOrNull(endDate);
  if (start === null || end === null) return "";
  return formatDuration(start, end);
}
export function displayExactDuration(startDate, endDate) {
  const st = dayjs(endDate).diff(startDate, "s");
  const hours = Math.floor((st / (60 * 60)) % 60);
  const minutes = Math.floor((st / 60) % 60);
  const seconds = st % 60;
  return `${hours}h ${minutes}m ${seconds}s`
    .replace(/\b0+[a-z]+\s*/gi, "")
    .trim();
}

function valueToDayjsOrNull(date) {
  if (typeof date === "undefined") {
    return null;
  }
  if (date === null) {
    return null;
  }
  try {
    const dtm = dayjs(date);
    if (dtm.isValid()) {
      return dtm.toDate();
    } else {
      return null;
    }
  } catch (error) {
    console.error("error:", error);
  }
  return null;
}
