/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import {
  FileUploadField,
  CheckboxField,
  InputField,
  DataTable
} from "@teselagen/ui";
import { some, reduce } from "lodash";
import { downloadTemplateFile, parseCsvAndSave } from "../utils";
import { barcodeInputHelper } from "../../../../utils/plateUtils";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";

const plateMapBarcodeSchema = {
  model: "plateMap",
  fields: [{ path: "name", displayName: "Plate Name" }, "barcode"]
};

class RegisterPlateBarcodes extends React.Component {
  onClick = () => {
    const { plateMaps = [], selectedContainerArrayTypes } = this.props;
    const selectedRacks = reduce(
      selectedContainerArrayTypes,
      (acc, c, pmKey) => {
        if (!c.isPlate) {
          const plateMapId = pmKey.replace("id", "");
          acc[plateMapId] = true;
        }
        return acc;
      },
      {}
    );
    downloadTemplateFile(
      plateMaps.filter(({ id }) => selectedRacks[id]),
      { barcode: "" },
      "tube_barcodes_template_file"
    );
  };
  render() {
    const {
      tubeBarcodes,
      plateMaps = [],
      selectedContainerArrayTypes = {},
      plateMapNames = {},
      generateBarcodes
    } = this.props;
    const isAnyRack = some(
      selectedContainerArrayTypes,
      selectedContainerArrayType => !selectedContainerArrayType.isPlate
    );
    return (
      <div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Plate Barcodes"
              helper={`Checking the box labeled "Generate Barcodes"
                will automatically generate unique barcodes for each
                plate or rack. To input a custom barcode, uncheck the box and
                input a barcode for each plate in the table below.`}
            />
            <CheckboxField
              name="generateBarcodes"
              label="Generate Barcodes"
              defaultValue
            />
          </div>
          {!generateBarcodes && (
            <React.Fragment>
              <DataTable
                extraClasses="plate-registration-barcode-table"
                schema={plateMapBarcodeSchema}
                destroyOnUnmount={false}
                noForm
                entities={plateMaps}
                isSimple
                noSelect
                cellRenderer={{
                  name: (v, record) => plateMapNames[record.id] || v,
                  barcode: (v, record, { index }) => (
                    <InputField
                      name={`plateMapBarcodes[${index}]`}
                      {...barcodeInputHelper}
                    />
                  )
                }}
              />
            </React.Fragment>
          )}
        </div>
        {isAnyRack && (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Tube Barcodes"
              helper="One or more of the selected plate types is a rack.
                  To add barcode information to tubes on those racks, check the box
                  and upload a CSV."
            />
            <div className="tg-flex column">
              <CheckboxField name="tubeBarcodes" label="Upload Tube Barcodes" />
              {tubeBarcodes && (
                <div style={{ width: 200 }}>
                  <FileUploadField
                    fileLimit={1}
                    accept={{
                      type: [".txt", ".csv"],
                      exampleFile: this.onClick
                    }}
                    name="plateBarcodeTubesUpload"
                    readBeforeUpload
                    beforeUpload={parseCsvAndSave}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default stepFormValues(
  "plateMaps",
  "plateMapNames",
  "tubeBarcodes",
  "generateBarcodes",
  "selectedContainerArrayTypes"
)(RegisterPlateBarcodes);
