/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { noop } from "lodash";
import { DialogFooter, BlueprintError, wrapDialog } from "@teselagen/ui";
import { handleMicrobialPlasmidUpload } from "../../../utils";
import "./style.css";
import {
  parseSequenceText,
  filterSequenceUploads
} from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";

import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { checkDuplicateSequencesExtended } from "../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import showDuplicateInputSequencesConfirmation from "../../../../src-shared/showDuplicateInputSequencesConfirmation";
import upsertUniqueAliases from "../../../../../tg-iso-shared/src/sequence-import-utils/upsertUniqueAliases";
import UploadOrPasteSequenceFields from "../../../../src-shared/UploadDNASequenceDialog/UploadOrPasteSequenceFields";
import { isEmpty } from "lodash";

class UploadMaterialSequenceDialog extends Component {
  state = {
    fileUpload: true
  };

  onSubmit = async values => {
    const { sequenceUpload, sequenceText, sequenceName } = values;
    const {
      strain = {},
      isMicrobialMaterial,
      material,
      hideModal,
      beforeUpload = noop,
      unlinkOldSequence = noop,
      refetch,
      history
    } = this.props;
    try {
      await beforeUpload();
      const sequenceAliases = [];
      let sequences;
      if (this.state.fileUpload) {
        sequences = await filterSequenceUploads({
          allSequenceFiles: [...sequenceUpload]
        });
      } else {
        if (sequenceText == null) throw new Error("No genbank sequence found");
        sequences = await parseSequenceText(sequenceText, sequenceName);
      }

      if (sequences.length > 1 && !isMicrobialMaterial) {
        throw new Error(
          `Only one sequence can be uploaded to a DNA material. This upload had ${sequences.length} sequences.`
        );
      }

      const {
        duplicatesOfInputSequences,
        allInputSequencesWithAttachedDuplicates,
        duplicateSequencesFound
      } = await checkDuplicateSequencesExtended(sequences);
      if (sequences.length > 0) {
        const continueUpload = await showDuplicateInputSequencesConfirmation(
          duplicatesOfInputSequences,
          sequenceAliases
        );
        if (!continueUpload) return;
      }
      if (isMicrobialMaterial) {
        const sequencesToCreate = [];
        const existingSequenceIds = [];
        const newSequenceIds = [];
        allInputSequencesWithAttachedDuplicates.forEach(seq => {
          const dup = seq.duplicateFound;
          if (dup && dup.id) {
            if (!existingSequenceIds.includes(dup.id)) {
              existingSequenceIds.push(dup.id);
            }
          } else {
            const newSequence = dup || seq;
            const sequenceId = `&${newSequence.cid}`;
            if (!newSequenceIds.includes(sequenceId)) {
              sequencesToCreate.push(newSequence);
              newSequenceIds.push(sequenceId);
            }
          }
        });
        await handleMicrobialPlasmidUpload({
          strainId: strain && strain.id,
          strainTypeCode: strain && strain.strainTypeCode,
          materialId: material && material.id,
          materialTypeCode: isEmpty(strain) && material.materialTypeCode,
          newSequences: sequencesToCreate,
          existingSequenceIds
        });
      } else {
        if (duplicateSequencesFound.length) {
          const dupSeq = duplicateSequencesFound[0];

          const dupSeqWithMat = await safeQuery(
            ["sequence", "id polynucleotideMaterialId"],
            {
              variables: { id: dupSeq.id }
            }
          );
          if (dupSeqWithMat.polynucleotideMaterialId) {
            return window.toastr.warning(
              "A material is already linked to this sequence",
              {
                action: {
                  icon: "eye-open",
                  onClick: () => {
                    hideModal();
                    history.push(
                      `/dna-materials/${dupSeq.polynucleotideMaterialId}`
                    );
                  }
                }
              }
            );
          } else {
            await unlinkOldSequence();
            await safeUpsert("sequence", {
              id: dupSeq.id,
              polynucleotideMaterialId: material.id
            });
          }
        } else {
          await unlinkOldSequence();
          await safeUpsert("sequence", {
            ...sequences[0],
            polynucleotideMaterialId: material.id
          });
        }
      }
      await upsertUniqueAliases(sequenceAliases);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error uploading sequences");
    }
  };

  render() {
    const {
      hideModal,
      handleSubmit,
      isMicrobialMaterial,
      valid,
      submitting,
      error
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <UploadOrPasteSequenceFields
            isRequired
            isFileUpload={this.state.fileUpload}
            toggleFileUpload={() =>
              this.setState({ fileUpload: !this.state.fileUpload })
            }
            fileLimit={isMicrobialMaterial ? undefined : 1}
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          disabled={!valid}
          hideModal={hideModal}
          submitting={submitting}
        />
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.sequenceUpload)
    errors.sequenceUpload = "At least one sequence file is required";
  return errors;
};

export default compose(
  wrapDialog({
    title: "Upload Sequence",
    style: { width: "580px" }
  }),
  reduxForm({
    enableReinitialize: true,
    form: "uploadMaterialSequenceForm",
    validate
  })
)(UploadMaterialSequenceDialog);
