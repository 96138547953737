/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  SelectField,
  wrapDialog
} from "@teselagen/ui";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class UpdateJ5MetadataDialog extends Component {
  onSubmit = async values => {
    const { hideModal } = this.props;
    try {
      await safeUpsert(
        ["j5Report", "id name assemblyMethod assemblyType"],
        values
      );
      hideModal();
    } catch (error) {
      console.error("error", error);
      window.toastr.error("Error updating J5 Metadata");
    }
  };

  render() {
    const { handleSubmit, submitting, hideModal, initialValues } = this.props;
    const options = [initialValues.assemblyType];
    if (initialValues.assemblyType === "circular") {
      options.push("linear");
    } else {
      options.push("circular");
    }
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Design Name" isRequired />
          <InputField
            name="assemblyMethod"
            label="Assembly Method"
            isRequired
          />
          <SelectField
            options={options}
            label="Assembly Type"
            name="assemblyType"
          />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({ title: "Update J5 Metadata" }),
  reduxForm({
    form: "updateJ5MetadataDialog"
  })
)(UpdateJ5MetadataDialog);
