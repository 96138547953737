/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { CheckboxField, tgFormValues } from "@teselagen/ui";
import { flatMap, get, isEmpty } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import {
  DataTable,
  RadioGroupField,
  showConfirmationDialog
} from "@teselagen/ui";
import { Button } from "@blueprintjs/core";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import {
  aliquotContainerFragment,
  plateMapGroupFragment,
  plateFragment,
  aliquotRearrayTableFragment
} from "../fragments";
import { validateMaterialPlates } from "../../../../utils/plateUtils";
import {
  concentrationRender,
  massRender,
  volumeRender
} from "../../../../../src-shared/utils/unitUtils";
import { throwFormError } from "../../../../../src-shared/utils/formUtils";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import shortid from "shortid";
import { identity } from "lodash";
import { keyBy } from "lodash";
import { omit } from "lodash";
import {
  get2dLocationFromLocationString,
  getPlateLocationMap
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { capitalize } from "lodash";

const tableParamOptions = {
  additionalFilter: () => ({
    dataTableTypeCode: [
      "SAMPLE_INVENTORY_LIST",
      "PCR_INVENTORY_MATERIALS",
      "MICROBIAL_INVENTORY_MATERIALS",
      "VALID_SAMPLE_QC_INVENTORY_LIST",
      "INVALID_SAMPLE_QC_INVENTORY_LIST"
    ]
  })
};

class SelectMaterials extends Component {
  state = {
    loadingAliquotsForTable: false
  };
  fetchInventoryMaterials = async dataTables => {
    const {
      stepFormProps: { change }
    } = this.props;

    this.fetchAliquotKey = shortid();
    const myKey = this.fetchAliquotKey;
    this.setState({
      loadingAliquotsForTable: true
    });
    try {
      const aliquotIds = flatMap(dataTables, record =>
        record.dataRows.map(row => row.rowValues.aliquotId)
      ).filter(identity);

      const microbialInventoryTables = [];
      dataTables.forEach(table => {
        if (table.dataTableTypeCode === "MICROBIAL_INVENTORY_MATERIALS") {
          microbialInventoryTables.push(table);
        }
      });
      const qb = new QueryBuilder("aliquotContainer");
      let inventoryAliquots = [];
      const plateInfo = `id
      name
      barcode {
        id
        barcodeString
      }`;
      if (aliquotIds.length) {
        const filter = qb
          .whereAll({
            aliquotId: qb.inList(aliquotIds)
          })
          .toJSON();
        const inventoryAliquotContainers = await safeQuery(
          [
            "aliquotContainer",
            `${aliquotContainerFragment}
              containerArray {
                ${plateInfo}
              }
            `
          ],
          {
            variables: { filter }
          }
        );
        inventoryAliquots = inventoryAliquots.concat(
          inventoryAliquotContainers.map(({ aliquot, ...rest }) => {
            const aliquotContainerToUse = {
              ...rest
            };
            return {
              ...aliquot,
              aliquotContainer: aliquotContainerToUse
            };
          })
        );
      }

      if (myKey !== this.fetchAliquotKey) return;

      if (microbialInventoryTables) {
        const plateBarcodeToWells = {};
        microbialInventoryTables.forEach(table => {
          table.dataRows.forEach(row => {
            const rowValues = row.rowValues;
            if (rowValues.plateBarcode) {
              if (!plateBarcodeToWells[rowValues.plateBarcode]) {
                plateBarcodeToWells[rowValues.plateBarcode] = [];
              }
              plateBarcodeToWells[rowValues.plateBarcode].push(rowValues.well);
            }
          });
        });
        if (!isEmpty(plateBarcodeToWells)) {
          const plates = await safeQuery(
            [
              "containerArray",
              `{
            ${plateInfo}
            containerArrayType {
              id
              containerFormat {
                code
                is2DLabeled
                rowCount
                columnCount
              }
            }
            aliquotContainers {
              ${aliquotContainerFragment}
            }
          }`
            ],
            {
              variables: {
                filter: {
                  "barcode.barcodeString": Object.keys(plateBarcodeToWells)
                }
              }
            }
          );
          const keyedPlates = keyBy(plates, "barcode.barcodeString");
          const plateLocationMaps = {};
          plates.forEach(plate => {
            plateLocationMaps[plate.barcode.barcodeString] =
              getPlateLocationMap(plate);
          });
          for (const plateBarcode of Object.keys(plateBarcodeToWells)) {
            const plate = keyedPlates[plateBarcode];
            if (!plate) {
              return window.toastr.error(
                `Could not find plate with barcode ${plateBarcode}`
              );
            }
            const simplePlate = omit(plate, ["aliquotContainers"]);
            const wells = plateBarcodeToWells[plateBarcode];
            const locationMap = plateLocationMaps[plateBarcode];
            wells.forEach(well => {
              const cleanLocation = get2dLocationFromLocationString(
                well,
                plate.containerArrayType.containerFormat
              );
              const aliquotContainer = locationMap[cleanLocation];
              if (aliquotContainer && aliquotContainer.aliquot) {
                const { aliquot, ...rest } = aliquotContainer;
                const aliquotContainerToUse = {
                  ...rest,
                  containerArray: simplePlate
                };
                inventoryAliquots.push({
                  ...aliquot,
                  aliquotContainer: aliquotContainerToUse
                });
              } else {
                return window.toastr.error(
                  `No aliquot found for plate ${plateBarcode} at well ${well}`
                );
              }
            });
          }
        }
      }

      if (myKey === this.fetchAliquotKey) {
        change("inventoryAliquots", inventoryAliquots);
      }
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error fetching aliquots from table");
    }
    if (myKey === this.fetchAliquotKey) {
      this.setState({
        loadingAliquotsForTable: false
      });
    }
  };

  saveAliquotsToForm = async values => {
    const { validationPlateMapGroup, transferAll } = values;
    const {
      stepFormProps: { change },
      inventoryAliquots = [],
      sourcePlates = [],
      nextStep
    } = this.props;
    let allAliquotContainers = [];
    const addedIds = {};

    if (inventoryAliquots.length && sourcePlates.length) {
      const plateAliquots = [];
      sourcePlates.forEach(p => {
        p.aliquotContainers.forEach(ac => {
          if (ac.aliquot) {
            plateAliquots.push(ac.aliquot.id);
          }
        });
      });
      const hasOverlappingAliquot = inventoryAliquots.some(aliquot =>
        plateAliquots.includes(aliquot.id)
      );
      if (hasOverlappingAliquot) {
        const continueTool = await showConfirmationDialog({
          text: `There are overlapping aliquots on the plates and tables selected.\
             If you proceed all aliquots from the plate will be used.\
              If you would like to only use the aliquots from the table\
               then deselect the plates. Would you like to proceed as is?`,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        });
        if (!continueTool) return;
      }
    }

    sourcePlates.forEach(plate => {
      plate.aliquotContainers.forEach(ac => {
        let empty;
        if (transferAll === "all") {
          empty = !ac.aliquot && !ac.additives.length;
        } else {
          empty = !ac.aliquot;
        }
        if (!empty) {
          if (!addedIds[ac.id]) {
            allAliquotContainers.push({
              ...ac,
              containerArray: {
                id: plate.id,
                name: plate.name,
                containerArrayType: plate.containerArrayType
              }
            });
            addedIds[ac.id] = true;
          }
        }
      });
    });
    inventoryAliquots.forEach(aliquot => {
      if (aliquot.aliquotContainer && !addedIds[aliquot.aliquotContainer.id]) {
        allAliquotContainers.push({
          ...aliquot.aliquotContainer,
          aliquot
        });
      }
    });
    const plateMapType = get(validationPlateMapGroup, "plateMaps[0].type");
    if (
      !validationPlateMapGroup ||
      !["lot", "additiveMaterial"].includes(plateMapType)
    ) {
      let hasEmptyAliquot = false;
      let dryAliquot = false;

      allAliquotContainers = allAliquotContainers.filter(ac => {
        if (ac.aliquot?.isDry) {
          dryAliquot = true;
          return false;
        } else if (ac.aliquot && !ac.aliquot.volume) {
          hasEmptyAliquot = true;
          return false;
        } else {
          return true;
        }
      });
      if (dryAliquot) {
        throwFormError("Some source aliquots are dry.");
      }
      if (hasEmptyAliquot && allAliquotContainers.length) {
        const continueWithTool = await showConfirmationDialog({
          text: `Some of the selected aliquots have 0 volume. Would you like to skip those aliquots and continue?`
        });
        if (!continueWithTool) return;
      }
    }
    if (!allAliquotContainers.length) {
      return window.toastr.error(
        "No valid aliquots with volume found in selection."
      );
    }
    change("allAliquotContainers", allAliquotContainers);
    nextStep();
  };

  removeMaterialSelection = () => {
    this.fetchAliquotKey = null;
    this.props.stepFormProps.change("inventoryAliquots", []);
  };

  render() {
    const { loadingAliquotsForTable } = this.state;
    const {
      inventoryAliquots = [],
      footerProps,
      Footer,
      stepFormProps: { change },
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      handleSubmit,
      sourcePlates = [],
      transferAll,
      dataTables = [],
      isTubeTransfer
    } = this.props;

    const destinationContainerType = isTubeTransfer ? "rack" : "plate";
    const DestinationContainerType = capitalize(destinationContainerType);
    let plateErrors = {};
    if (sourcePlates.length) {
      plateErrors = validateMaterialPlates(sourcePlates, {
        allowEmptyWithAdditives: transferAll === "all",
        asObject: true
      });
    }
    let error;

    if (!isEmpty(plateErrors)) {
      error = plateErrors._error || "Errors with selected plates.";
    }

    if (!error && dataTables.length && !inventoryAliquots.length) {
      error = "No aliquots found from tables.";
    }

    const inventoryAliquotSchema = {
      model: "aliquot",
      fields: [
        {
          path: "sample.material.name",
          type: "string",
          displayName: "Material"
        },
        {
          path: "aliquotContainer.containerArray.name",
          type: "string",
          displayName: "Plate Name"
        },
        {
          path: "aliquotContainer.containerArray.barcode.barcodeString",
          type: "string",
          displayName: "Plate Barcode"
        },
        {
          displayName: "Well",
          render: (v, r) => getAliquotContainerLocation(r.aliquotContainer)
        },
        {
          displayName: "Tube Barcode",
          path: "aliquotContainer.barcode.barcodeString"
        },
        {
          path: "volume",
          type: "number",
          displayName: "Volume",
          render: volumeRender
        },
        {
          path: "concentration",
          type: "number",
          displayName: "Concentration",
          render: concentrationRender
        },
        {
          path: "mass",
          type: "number",
          displayName: "Mass",
          render: massRender
        }
      ]
    };

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <HeaderWithHelper
              header="Select Plates From Inventory"
              helper="Select one or more plates of aliquots and/or additives that you would like to rearray onto destination plates."
            />
            <RadioGroupField
              label="Transfer"
              name="transferAll"
              tooltipInfo="By default, only wells with aliquots will be transferred. Select 'All wells' to include wells with additives."
              options={[
                { label: "Only wells with aliquots", value: "aliquot" },
                { label: "All Wells", value: "all" }
              ]}
              defaultValue="aliquot"
            />
          </div>
          <div className="width100 column">
            <CheckboxField
              name="isTubeTransfer"
              label="Tube Transfer"
              onFieldSubmit={val => {
                if (val) {
                  change("validationPlateMapGroup", null);
                }
              }}
              tooltipInfo="Creates a worklist that transfers entire tubes between racks (standard worklists transfer aliquots between wells/tubes)."
            />
            <GenericSelect
              {...{
                name: "sourcePlates",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.containerArrays,
                  loading: isLoadingMap.containerArrays
                },
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                nameOverride: DestinationContainerType + "s",
                isTubeTransfer,
                additionalDataFragment: plateFragment,
                additionalFilter: (props, qb) => {
                  if (props.isTubeTransfer) {
                    qb.whereAny(
                      {
                        "containerArrayType.isPlate": false
                      },
                      {
                        "containerArrayType.isPlate": qb.isNull()
                      }
                    );
                  }
                },
                postSelectDTProps: {
                  formName: "aliquotRearraySelectPlates",
                  plateErrors,
                  schema: [
                    platePreviewColumn({ withSampleStatus: true, plateErrors }),
                    "name",
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Materials From Inventory"
            helper="Select one or more tables of inventory materials."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "dataTables",
                schema: ["name", dateModifiedColumn],
                isMultiSelect: true,
                buttonProps: {
                  disabled: isDisabledMap.dataTables,
                  loading: isLoadingMap.dataTables
                },
                onSelect: this.fetchInventoryMaterials,
                fragment: ["dataTable", "id name updatedAt"],
                additionalDataFragment: aliquotRearrayTableFragment,
                onClear: this.removeMaterialSelection,
                tableParamOptions
              }}
            />
            {(inventoryAliquots.length > 0 || loadingAliquotsForTable) && (
              <DataTable
                formName="inventoryAliquots"
                style={{ marginBottom: 15 }}
                isSimple
                schema={inventoryAliquotSchema}
                isLoading={loadingAliquotsForTable}
                entities={inventoryAliquots}
              />
            )}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Plate Map (Optional)"
            helper={`Select a plate map. The selected plate map
            will dictate the destination plate layout. This action will
            also verify that all necessary input entities (materials,
            samples, reagents) have been selected.`}
          />
          <div>
            <GenericSelect
              {...{
                name: "validationPlateMapGroup", //the field name within the redux form Field
                schema: [
                  "name",
                  {
                    displayName: "Plate Format",
                    path: "containerFormat.name"
                  },
                  dateModifiedColumn
                ],
                fragment: [
                  "plateMapGroup",
                  "id name containerFormatCode containerFormat { code name } updatedAt"
                ],
                buttonProps: {
                  loading: isLoadingMap.plateMapGroup,
                  disabled: isDisabledMap.plateMapGroup
                },
                additionalDataFragment: plateMapGroupFragment,
                postSelectDTProps: {
                  formName: "selectedPlateMapGroups",
                  schema: [platePreviewColumn(), "name", "containerFormat.name"]
                }
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          errorMessage={error}
          nextButton={
            <Button
              intent="primary"
              disabled={error}
              onClick={handleSubmit(this.saveAliquotsToForm)}
            >
              Next
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  tgFormValues(
    "dataTables",
    "inventoryAliquots",
    "sourcePlates",
    "transferAll",
    "isTubeTransfer"
  )
)(SelectMaterials);
