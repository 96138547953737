/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* eslint-disable graphql/template-strings */
import gql from "graphql-tag";
import pluralize from "pluralize";
import { get } from "lodash";
import {
  TYPES_LINKED_TO_DESIGN,
  LOCAL_FIELDS,
  SIMPLE_REFERENCES_TO_TYPE,
  STATE_TO_DB_TYPES
} from "../../../constants/designStateConstants";
import iconFragment from "../iconFragment";
import customJ5ParameterFragment from "../customJ5ParameterFragment";
import ruleSetFragment from "../ruleSetFragment";
import {
  designPartFragment,
  designPartsetFragment,
  constructAnnotationFragment
} from "./designAccessoryFragments";
import restrictionEnzymeFragment from "../../../../tg-iso-shared/src/fragments/restrictionEnzymeFragment";

const additionalFieldsOnItems = {
  bin: ["icon {...iconFragment}", iconFragment],
  card: [
    "constructAnnotations { ...constructAnnotationFragment }",
    constructAnnotationFragment
  ],
  designReaction: [
    `assemblyMethod { id name }
     restrictionEnzyme { ...restrictionEnzymeFragment }
     customJ5Parameter { ...customJ5ParameterFragment }
    `,
    restrictionEnzymeFragment,
    customJ5ParameterFragment
  ],
  element: [
    "part { ...designPartFragment } partset {...designPartsetFragment} " +
      "aminoAcidPart { id name start end aminoAcidSequenceId aminoAcidSequence { id name proteinSequence } }",
    designPartFragment,
    designPartsetFragment
  ],
  binRuleSet: ["ruleSet { ...ruleSetFragment }", ruleSetFragment],
  elementCombo: ["availablePart { ...designPartFragment }", designPartFragment]
};

const designTypeToFragmentMap = Object.keys(TYPES_LINKED_TO_DESIGN).reduce(
  (acc, type) => {
    const localFields = LOCAL_FIELDS[type] || [];
    const simpleReferences = SIMPLE_REFERENCES_TO_TYPE[type] || {};
    const dbType = STATE_TO_DB_TYPES[type] || type;
    const additionalFields = get(additionalFieldsOnItems, [dbType, 0], "");
    let frag = `
    fragment piecemealDesignFragment_${pluralize(dbType)} on design {
      id
      ${pluralize(dbType)} {
        ${localFields.join("\n        ")}
        ${Object.keys(simpleReferences).join("\n        ")}
        ${additionalFields}
      }
    }
    `;

    if (additionalFieldsOnItems[dbType]) {
      frag += additionalFieldsOnItems[dbType]
        .slice(1)
        .map(f => f.loc.source.body)
        .join("\n        ");
    }

    acc[dbType] = gql(frag);
    return acc;
  },
  {}
);

designTypeToFragmentMap.design = gql`
fragment localDesignFieldsFragment on design {
  ${LOCAL_FIELDS.design.join("\n        ")}
}
`;

export default designTypeToFragmentMap;
