/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState, useEffect } from "react";
import { Loading } from "@teselagen/ui";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./style.css";
import integrationTypeSettingsMap from "../../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import { wrapDialog } from "@teselagen/ui";

const {
  CUSTOM_INFO: {
    endpoints: {
      CUSTOM_INFO: { method }
    }
  }
} = integrationTypeSettingsMap;

export default wrapDialog()(function GetCustomExternalInfoDialog({
  endpointId,
  record
}) {
  const [isLoaded, setIsLoaded] = useState();
  useEffect(() => {
    const getInfo = async () => {
      try {
        const res = await window.triggerIntegrationRequest({
          endpointId: endpointId,
          data: { record },
          method
        });
        const { markdown } = res.data;
        setIsLoaded(markdown);
      } catch (e) {
        console.error("error 23ior2oalll:", e);
      }
    };
    getInfo();
  }, [endpointId, record]);

  if (!isLoaded) return <Loading bounce />;
  return (
    <div style={{ padding: 20 }} className="tg-md-body">
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{isLoaded}</ReactMarkdown>
    </div>
  );
});
