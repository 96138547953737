/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import actions from "../../src-shared/redux/actions";
import { withRouter } from "react-router-dom";

import DesignElementBlock from "../components/HierarchicalDesign/DesignElementCard/DesignElementBlock/index.js";
import {
  getItemOfType,
  getReferencedValue,
  getElementsInBin,
  getNumberOfRowsForCard,
  isDesignVisualReport,
  getNumberOfItemsInBin
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";
import { isBinSelected } from "../../src-shared/selectors/designViewSelectors";
import {
  getUnligatedValidatedJunctionIdsToRenderOnBin,
  getLigatedValidatedJunctionIdsToRenderOnBin
  // getNonTerminalBins
} from "../../../tg-iso-design/selectors/junctionSelectors";
import stopOutOfSyncReduxRender from "../utils/stopOutOfSyncReduxRender";

const mapStateToProps = (state, props) => {
  const { cardId, binId } = props;
  return {
    bin: getItemOfType(state, "bin", binId),
    icon: getReferencedValue(state, "bin", binId, "iconId"),
    elements: getElementsInBin(state, binId),
    numberOfRows: getNumberOfRowsForCard(state, cardId),
    isSelected: isBinSelected(state, cardId, binId),
    isLocked: isDesignLocked(state),
    isVisualReport: isDesignVisualReport(state),
    unligatedValidatedJunctionIds: getUnligatedValidatedJunctionIdsToRenderOnBin(
      state,
      cardId,
      binId
    ),
    ligatedValidatedJunctionIds: getLigatedValidatedJunctionIdsToRenderOnBin(
      state,
      cardId,
      binId
    ),
    numItems: getNumberOfItemsInBin(state, binId)
    // Just for visualization purposes, now.
    // isNonTerminal: getNonTerminalBins(state, cardId).some(b => b.id === binId)
  };
};

const mapDispatchToProps = {
  selectBin: actions.ui.designEditor.general.selectBin,
  flipBin: actions.design.flipBin,
  setActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  openInspector: actions.ui.designEditor.inspector.open
};

export default compose(
  stopOutOfSyncReduxRender((state, props) =>
    getItemOfType(state, "bin", props.binId)
  ),
  stopOutOfSyncReduxRender((state, props) =>
    getItemOfType(state, "card", props.cardId)
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(DesignElementBlock);
