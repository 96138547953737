/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Dialog, Classes } from "@blueprintjs/core";
import { isEmpty } from "lodash";
import { getSelectedBinIds } from "../../../../src-shared/selectors/designViewSelectors";
import getAllDesignRuleViolationsForSet from "../../../../src-shared/selectors/getAllDesignRuleViolationsForSet";
import { getItemOfType } from "../../../../../tg-iso-design/selectors/designStateSelectors";

const mapStateToProps = state => {
  const setIds = getSelectedBinIds(state);
  const setId = setIds[0];

  const violations = getAllDesignRuleViolationsForSet(state, setId);
  const hasViolations = !isEmpty(violations);

  return { setId, hasViolations, state, violations };
};

class DesignRuleViolationsDialog extends React.Component {
  renderViolations() {
    const { violations, state } = this.props;
    return (
      <ul className={Classes.LIST}>
        {Object.entries(violations).map(([rsId, rsViolations]) => (
          <li key={rsId}>
            Ruleset: {getItemOfType(state, "ruleSet", rsId).name}
            <ul className={Classes.LIST}>
              {Object.entries(rsViolations).map(([ruleId, violations]) => (
                <li key={ruleId}>
                  Rule: {getItemOfType(state, "rule", ruleId).name}
                  <ul className={Classes.LIST}>
                    {violations.map((message, i) => (
                      <li key={i}>{message}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { hideModal, hasViolations } = this.props;
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title="Design Rule Violations"
        style={{ width: 750 }}
      >
        <div className="bp3-dialog-body">
          {hasViolations
            ? this.renderViolations()
            : "Assembly reaction valid for given rulesets."}
        </div>
      </Dialog>
    );
  }
}

export default compose(connect(mapStateToProps))(DesignRuleViolationsDialog);
