/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { DataTable } from "@teselagen/ui";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import dataMapperFragment from "../../../utils/dataMapperUtils/dataMapperFragment";

const schema = {
  model: "mappingPresetActions",
  fields: [
    { path: "name", displayName: "Column" },
    { path: "className", displayName: "Class" },
    { path: "subClassName", displayName: "Sub Class" }
  ]
};

export default function DataMapperRecordView({ id, header }) {
  const [columnMaps, setColumnMaps] = useState([]);

  useEffect(() => {
    const fetchCols = async mappingPresetId => {
      try {
        const fetchedDataMapper = await safeQuery(dataMapperFragment, {
          variables: { filter: { mappingPresetId } }
        });
        const columnMaps = fetchedDataMapper
          .map(row => row.columnMap)
          .map(col => ({
            name: col.columnName,
            className: col.classType.name,
            subClassName: col.unit
              ? `${col[col.classType.code].name} [${col.unit.name}]`
              : col[col.classType.code].name
          }));

        setColumnMaps(columnMaps);
      } catch (error) {
        console.error(error);
        window.toastr.error(error.message);
      }
    };

    if (!header) {
      fetchCols(id);
    }
  }, [id, header]);

  return (
    <DataTable
      formName="mappingPresetCols"
      isSimple
      isInfinite
      schema={schema}
      entities={header || columnMaps}
      defaultRowHeight={30}
      withPagination={false}
    />
  );
}
