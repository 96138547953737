/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { InputField, TextareaField, ReactSelectField } from "@teselagen/ui";
import { DesignImport } from "../../utils/designImportProcess";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import CustomHelpDocWidget from "../../../src-shared/CustomHelpDocsManagementPanel/CustomHelpDocWidget";
import customHelpDocConstants from "../../../src-shared/CustomHelpDocsManagementPanel/customHelpDocConstants";
import FormFooter from "../../../src-shared/components/FormFooter";

import "./style.css";
import { Card } from "@blueprintjs/core";

//Below is the code for create new design dialog
class EmptyDesign extends React.Component {
  beforeSubmit = async values => {
    try {
      const { history } = this.props;
      const designImport = new DesignImport(history, false);
      await designImport.processImport(values);
    } catch (err) {
      console.error(err);
      window.toastr.error(err.message || "Error uploading design.");
    }
  };

  render() {
    const { handleSubmit, submitting, history } = this.props;

    return (
      <Card className="form-layout">
        <form onSubmit={handleSubmit(this.beforeSubmit)}>
          <h2 className="edit-view-title">Create New Design</h2>
          <CustomHelpDocWidget
            {...customHelpDocConstants.NEW_DESIGN}
          ></CustomHelpDocWidget>
          <InputField
            autoFocus
            isRequired
            generateDefaultValue={{
              ...defaultValueConstants.DESIGN_NAME,
              customParams: {
                // lastDesignId: 189128
                //every field will always have userName, userId, timestamp, labId passed to it
              }
            }}
            name="name"
            label="Name"
            secondaryLabel="(required)"
          />
          <TextareaField name="description" label="Description" />
          <ReactSelectField
            name="layoutType"
            label="Design Layout Type"
            defaultValue="combinatorial"
            options={defaultValueConstants.DESIGN_LAYOUT_TYPE.options}
            disallowClear
            generateDefaultValue={defaultValueConstants.DESIGN_LAYOUT_TYPE}
          />
          <FormFooter submitting={submitting} history={history} />
        </form>
      </Card>
    );
  }
}

export default compose(
  reduxForm({
    form: "emptyDesignForm"
  })
)(EmptyDesign);
