/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEmpty } from "lodash";
import { unparse } from "papaparse";
import pluralize from "pluralize";
import { download } from "../../utils/downloadTest";
import GenericSelect from "../../GenericSelect";
import { Classes, AnchorButton, Button, ButtonGroup } from "@blueprintjs/core";
import {
  SelectField,
  wrapDialog,
  useDialog,
  tgFormValues,
  DialogFooter
} from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import {
  modelTypeMap,
  modelTypeToModelMap
} from "../../../../tg-iso-shared/src/utils/modelTypeMap";
import { extPropTypeFilterAdditionalItems } from "../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { camelCase } from "lodash";
import { observer } from "mobx-react";
import { some } from "lodash";
import { Link } from "react-router-dom";
import { safeQuery } from "../../apolloMethods";
import extendedPropertyFragment from "../../fragments/extendedPropertyFragment";
import queryBuilder from "tg-client-query-builder";
import { isFunction } from "lodash";

export const getDownloadTemplateFileHelpers = async ({
  headers: _headers = [],
  validateAgainstSchema,
  requiredHeaders = [],
  headerMessages: _headerMessages = {},
  conditionalHeaderMessages = {},
  extendedPropTypes = [], // types of extended properties that this upload accepts
  fileName,
  helper,
  fileContents,
  type,
  tableWideValidation,
  additionalFileTypes
}) => {
  let headerMessages = _headerMessages;
  let headers = _headers.length ? _headers : Object.keys(headerMessages);
  let helperToUse = helper;
  const fields = [...(validateAgainstSchema?.fields || [])];
  const exampleDownloadFields = [];
  if (extendedPropTypes.length) {
    headerMessages = { ...headerMessages };
    const extHeaders = [];
    if (validateAgainstSchema) {
      const qb = new queryBuilder("extendedProperty");
      const extendedProps = await safeQuery(extendedPropertyFragment, {
        isPlural: true,
        variables: {
          pageSize: 1000,
          filter: classFilter(qb, {
            modelTypeCode: extendedPropTypes.map(
              t => modelTypeMap[camelCase(t)] || ""
            )
          }).toJSON()
        }
      });
      extendedProps.forEach(p => {
        const newField = getFieldFromExtProp(p);
        extHeaders.push(newField.path);
        const paths = fields.map(f => f.path);

        if (!paths.includes(newField.path)) {
          exampleDownloadFields.push(newField);
        }
      });
    } else {
      extendedPropTypes.forEach(pType => {
        const header = `ext-${pType}-`;
        extHeaders.push(header);
        headerMessages[header] =
          `This upload accepts extended properties for the ${pluralize(
            pType
          )}. Add a property name to this header (ex. '${header}Date of Purchase') and values to the rows. To add additional ${type} properties just duplicate the column header.`;
      });
      headers = [...headers, ...extHeaders];
      const helperMessage = validateAgainstSchema
        ? ""
        : `Please download the template file for help with adding extended properties. All extended properties are set in 'Settings > Extended Properties' before this upload.`;
      helperToUse = helper ? helper + "\n" + helperMessage : helperMessage;
    }

    headers = [...headers, ...extHeaders];
  }

  const toRet = {
    ...(type
      ? { type }
      : {
          type: [".csv", ".xlsx"] //by default allow csv and xlsx
        }),
    callout: helperToUse || undefined,
    description: helperToUse || undefined,
    ...(validateAgainstSchema
      ? {
          validateAgainstSchema: {
            exampleDownloadFields,
            ...(extendedPropTypes?.length && {
              coerceUserSchema: ({ userSchema, officialSchema }) => {
                //first remove any extended properties that may have been added to the officialSchema from a previous upload
                officialSchema.fields = officialSchema.fields.filter(
                  ({ path }) => {
                    return !path.startsWith("ext-");
                  }
                );
                //if userSchema comes in with extended properties, we need to add them to the officialSchema
                userSchema.fields.forEach(f => {
                  if (
                    f.path.startsWith("ext-") &&
                    !officialSchema.fields.some(({ path }) => {
                      return path === f.path;
                    })
                  ) {
                    officialSchema.fields.push({
                      path: f.path,
                      displayName: f.path,
                      hasMatch: true,
                      matches: [
                        {
                          item: {
                            displayName: f.path,
                            path: f.path
                          }
                        }
                      ]
                    });
                  }
                });
              },
              HeaderComp: ({
                validateAgainstSchema
                // props from TRC upload component
              }) => {
                const { showDialogPromise, comp } = useDialog({
                  ModalComponent: compose(
                    wrapDialog({ title: "Add Extended Property" }),
                    reduxForm({ form: "addExtendedPropertyCsvWizard" }),
                    tgFormValues("propType"),
                    observer
                  )(({ propType, hideDialog, handleSubmit, change }) => {
                    return (
                      <>
                        <div className={Classes.DIALOG_BODY}>
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to="/settings/extended-properties"
                          >
                            <AnchorButton
                              style={{ marginBottom: 10 }}
                              data-tip="Extended Properties Settings"
                              icon="cog"
                            >
                              Extended Properties Settings
                            </AnchorButton>
                          </Link>

                          <SelectField
                            label="Extended Property Type"
                            defaultValue={extendedPropTypes[0]}
                            name="propType"
                            options={extendedPropTypes}
                            onFieldSubmit={() => {
                              change("extendedProperty", null);
                            }}
                          ></SelectField>
                          <GenericSelect
                            {...{
                              name: "extendedProperty",
                              isRequired: true,
                              asReactSelect: true,
                              label: "Extended Property",
                              fragment: extendedPropertyFragment,
                              additionalFilter: (props, qb) => {
                                const modelToUse =
                                  extPropTypeFilterAdditionalItems[propType] ||
                                  camelCase(propType);
                                classFilter(qb, {
                                  modelTypeCode: modelTypeMap[modelToUse]
                                });
                              }
                            }}
                          ></GenericSelect>
                        </div>
                        <DialogFooter
                          onClick={handleSubmit(({ extendedProperty }) => {
                            const newField =
                              getFieldFromExtProp(extendedProperty);
                            if (
                              some(validateAgainstSchema.fields, {
                                path: newField.path
                              })
                            )
                              return window.toastr.warning(
                                "This extended property column has already been added"
                              );
                            validateAgainstSchema.fields.push(newField);
                            window.toastr.success(
                              "Extended Property Column Added"
                            );
                            hideDialog();
                          })}
                        ></DialogFooter>
                      </>
                    );
                  })
                });
                return (
                  <>
                    {comp}

                    <ButtonGroup style={{ marginBottom: 5 }}>
                      {validateAgainstSchema.additionalHeaderBtn?.({
                        validateAgainstSchema
                      })}
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={async () => {
                          showDialogPromise();
                        }}
                      >
                        Add Extended Property Column
                      </Button>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to="/settings/extended-properties"
                      >
                        <AnchorButton
                          data-tip="Extended Properties Settings"
                          icon="cog"
                        ></AnchorButton>
                      </Link>
                    </ButtonGroup>
                  </>
                );
              }
            }),
            ...tableWideValidation,
            ...validateAgainstSchema,
            ...(fileName && { fileName }),
            fields
          }
        }
      : {
          exampleFile: () => {
            let contents;
            if (fileContents) {
              if (isFunction(fileContents)) {
                contents = fileContents(headers, headerMessages);
              } else {
                contents = fileContents;
              }
            } else {
              const csvData = {
                fields: headers,
                data: []
              };

              if (
                requiredHeaders.length ||
                !isEmpty(conditionalHeaderMessages) ||
                !isEmpty(headerMessages)
              ) {
                const requiredRow = Array(headers.length);
                requiredHeaders.forEach(header => {
                  requiredRow[headers.indexOf(header)] = "required";
                });
                Object.keys(conditionalHeaderMessages).forEach(header => {
                  requiredRow[headers.indexOf(header)] =
                    conditionalHeaderMessages[header];
                });
                Object.keys(headerMessages).forEach(header => {
                  requiredRow[headers.indexOf(header)] = headerMessages[header];
                });
                csvData.data.push(requiredRow);
              }
              const csvString = unparse(csvData);
              contents = csvString;
            }
            download(
              contents,
              fileName.includes(".") ? fileName : fileName + ".csv",
              "text/plain"
            );
          }
        })
  };
  if (additionalFileTypes) {
    return [toRet, ...additionalFileTypes];
  }
  return toRet;
};

export const getFieldFromExtProp = p => {
  const header = `ext-${modelTypeToModelMap[p.modelTypeCode]}-${p.name}`;
  const type =
    p.extendedPropertyClassCode === "CATEGORY"
      ? "dropdown"
      : p.extendedTypeCode;
  return {
    path: header,
    displayName: header,
    type,
    ...(p.extendedPropertyClassCode === "CATEGORY" && {
      values: p.extendedCategoryClass?.extendedCategories.map(c => c.name)
    }),
    description: `${header}  (${type})`,
    example:
      p.extendedPropertyClassCode === "CATEGORY"
        ? p.extendedCategoryClass?.extendedCategories[0].name
        : p.extendedPropertyClassCode === "MEASUREMENT"
          ? "23 stones"
          : p.extendedTypeCode === "boolean"
            ? true
            : p.extendedTypeCode === "number"
              ? 3
              : "example value"
  };
};

const classFilter = (qb, additionalFilter) => {
  return qb.whereAll({
    ...additionalFilter,
    extendedPropertyClassCode: qb.notInList(["LINK", "WORKLIST_COUNTER"])
  });
};
