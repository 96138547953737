/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Classes } from "@blueprintjs/core";
import { compose } from "redux";
import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import { wrapDialog } from "@teselagen/ui";
import GeneratePartsFromDigest from "./GeneratePartsFromDigest";

function GeneratePartsFromDigestDialog({ refetch, hideModal, sequenceIds }) {
  const onSubmit = defaultAsyncWrap(async () => {
    await refetch();
    hideModal();
  }, "Error generating parts from enzyme digest.");

  return (
    <div className={Classes.DIALOG_BODY}>
      <GeneratePartsFromDigest sequenceIds={sequenceIds} onSubmit={onSubmit} />
    </div>
  );
}

export default compose(
  wrapDialog(props => ({
    canEscapeKeyClose: false,
    style: { width: "85%", maxWidth: "70em" },
    title: `Generate Parts from Digest for ${props.sequenceIds.length} sequences`
    // title: `Generate Parts from Digest of ${props.sequenceNames.join(",")}`
  }))
)(GeneratePartsFromDigestDialog);
