/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { get } from "lodash";
import { reduxForm } from "redux-form";
import {
  DataTable,
  DialogFooter,
  withTableParams,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { Classes, Callout } from "@blueprintjs/core";

import { safeUpsert } from "../../../../src-shared/apolloMethods";

class AttachPlateDialog extends Component {
  onSubmit = async () => {
    const {
      containerArray = {},
      hideModal,
      selectedEntities = []
    } = this.props;
    const selectedCollectionPlate = selectedEntities[0];
    try {
      if (selectedCollectionPlate.containerArray) {
        await safeUpsert("containerArray", {
          id: selectedCollectionPlate.containerArray.id,
          collectionPlateId: null
        });
      }
      await safeUpsert(
        [
          "containerArray",
          /* GraphQL */ `
            {
              id
              collectionPlateId
              collectionPlate {
                id
                name
              }
            }
          `
        ],
        {
          id: containerArray.id,
          collectionPlateId: selectedCollectionPlate.id
        }
      );
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error attaching column plate.");
    }
    hideModal();
    window.toastr.success("Successfully attached column plate.");
  };

  render() {
    const {
      tableParams,
      selectedEntities = [],
      submitting,
      handleSubmit,
      hideModal
    } = this.props;
    const selectedCollectionPlate = selectedEntities[0];
    let alreadyAttachedWarning;
    if (selectedCollectionPlate && selectedCollectionPlate.containerArray) {
      alreadyAttachedWarning = true;
    } else {
      alreadyAttachedWarning = false;
    }
    return (
      <div className={Classes.DIALOG_BODY}>
        <div style={{ paddingBottom: 15 }}>
          <DataTable {...tableParams} noPadding />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
          disabled={!get(selectedCollectionPlate, "id")}
        />
        {alreadyAttachedWarning && (
          <Callout
            intent="warning"
            className="tg-flex justify-space-around"
            style={{ marginTop: 15, marginBottom: -10 }}
          >
            The selected collection plate is already attached.
          </Callout>
        )}
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: "attachPlateForm",
    enableReinitialize: true
  }),
  withTableParams({
    formName: "selectContainerArrays",
    urlConnected: false,
    schema: {
      model: "containerArray",
      fields: [
        "name",
        { displayName: "Barcode", path: "barcode.barcodeString" },
        {
          displayName: "Attached",
          render: (v, r) => (r.containerArray ? "Yes" : "No"),
          filterDisabled: true,
          sortDisabled: true
        }
      ]
    },
    additionalFilter: ({ containerArray }, qb) => {
      const formatCode = get(
        containerArray,
        "containerArrayType.containerFormat.code"
      );
      qb.whereAll({
        "containerArrayType.containerFormat.code": formatCode,
        "containerArrayType.isColumn": false
      });
    },
    withSelectedEntities: true
  }),
  withQuery(
    [
      "containerArray",
      "id name barcode { id barcodeString } containerArray { id name } containerArrayType { id name containerFormat { code }}"
    ],
    {
      isPlural: true
    }
  ),
  wrapDialog({ title: "Attach Plate" })
)(AttachPlateDialog);
