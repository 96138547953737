/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const getSubjectNames = ({ selectedDataGrid, headerClasses }) => {
  if (headerClasses) {
    const assaySubjectHeaderName = Object.entries(headerClasses).find(
      headerClass => headerClass[1].name === "assaySubjectClass"
    )?.[0];

    const subjectsColumn = selectedDataGrid.dataCells.find(
      dataCell => dataCell.value === assaySubjectHeaderName
    )?.columnPosition;

    const subjectNames = selectedDataGrid.dataCells
      .filter(
        dataCell =>
          dataCell.columnPosition === subjectsColumn && dataCell.rowPosition > 0
      )
      .map(el => el.value);

    return { subjectNames, assaySubjectHeaderName };
  }
};
