/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

const worklistContainerArrayFragment = gql`
  fragment worklistContainerArrayFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      containerFormat {
        code
        is2DLabeled
        rowCount
        columnCount
      }
    }
  }
`;

const worklistAliquotContainerFragment = gql`
  fragment worklistAliquotContainerFragment on aliquotContainer {
    id
    name
    rowPosition
    columnPosition
    containerArrayId
    containerArray {
      ...worklistContainerArrayFragment
    }
    barcode {
      id
      barcodeString
    }
    aliquot {
      id
      sample {
        id
        name
      }
    }
  }
  ${worklistContainerArrayFragment}
`;

export const worklistRecordTransfersFragment = gql`
  fragment worklistRecordTransfersFragment on worklistTransfer {
    id
    volume
    volumetricUnitCode
    sourceAliquotContainerId
    sourceAliquotContainer {
      ...worklistAliquotContainerFragment
    }
    destinationAliquotContainerId
    destinationAliquotContainer {
      ...worklistAliquotContainerFragment
    }
  }
  ${worklistAliquotContainerFragment}
`;

export default gql`
  fragment worklistMinimalRecordFragment on worklist {
    id
    name
    user {
      id
      username
    }
    createdAt
    updatedAt
    worklistStatus {
      code
      name
    }
    worklistReactionMaps {
      id
      reactionMap {
        id
        name
      }
    }
    worklistTypeCode
    tubeTransfers {
      id
      sourceContainerArrayId
      sourceContainerArray {
        ...worklistContainerArrayFragment
      }
      destinationContainerArrayId
      destinationContainerArray {
        ...worklistContainerArrayFragment
      }
      aliquotContainerId
      aliquotContainer {
        ...worklistAliquotContainerFragment
      }
      sourceRowPosition
      sourceColumnPosition
      destinationRowPosition
      destinationColumnPosition
    }
  }
  ${worklistAliquotContainerFragment}
  ${worklistContainerArrayFragment}
`;
