/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { keyBy, uniqBy } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  FileUploadField,
  DialogFooter,
  BlueprintError,
  DataTable,
  wrapDialog
} from "@teselagen/ui";
import {
  filterSequenceString
  // guessIfSequenceIsDnaAndNotProtein
} from "@teselagen/sequence-utils";
import shortid from "shortid";

import { pluralIfNeeded } from "../../../utils";
import { taggedItems } from "../../../utils/plateUtils";

import TagField from "../../../../src-shared/TagField";

import { createTaggedItems } from "../../../../src-shared/utils/tagUtils";
import { openInNewTab } from "../../../../src-shared/utils/generalUtils";
import { startImportCollection } from "../../../../src-shared/utils/importCollection";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import {
  allowedCsvFileTypes,
  parseCsvOrExcelFile
} from "../../../../../tg-iso-shared/src/utils/fileUtils";

import caseInsensitiveFilter from "../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { extendedPropertyUploadFragment } from "../../../../src-shared/utils/extendedPropertyUtils";
import { getBoundExtendedPropertyUploadHelpers } from "../../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { showAminoAcidStrippedCharacterWarningDialog } from "../../../../../tg-iso-shared/src/sequence-import-utils/showAminoAcidStrippedCharacterWarningDialog";
import {
  aaSequenceJSONtoGraphQLInput,
  computeSequenceHash,
  stripLastStopCodon
} from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { checkDuplicateSequences } from "../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import upsertUniqueAliases from "../../../../../tg-iso-shared/src/sequence-import-utils/upsertUniqueAliases";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

const requiredHeaders = ["FPU_ID", "AA_ID", "AA_SEQUENCE"];
const allFields = [
  "FPU_ID",
  "AA_ID",
  "PROTEIN_SUBUNIT_NAME",
  "PDB_ID",
  "DESIGN_ID",
  "UNIPROT_ID",
  "AA_SEQUENCE",
  "HIS_TAG_LOCATION",
  "NER",
  "GER",
  "AA_PI_(ISOELECTRIC_POINT)",
  "AA_MW",
  "AA_EXT_COEFF_(SS_FORMED)",
  "COFACTOR",
  "EXTERNAL_LINK",
  "RELEVANT_DNA_MATERIAL"
];

class UploadProteinDialog extends Component {
  state = { submittedData: null };
  onSubmit = async values => {
    const { refetch, hideModal } = this.props;
    const {
      proteinFile: [
        {
          name: filename,
          csv: { data, meta }
        }
      ],
      tags
    } = values;
    try {
      const seenFpuMap = {};
      const fpuNames = [];
      const aaNames = [];
      const relevantDnaMaterialNames = [];
      const materialFpusToCreate = [];
      const aaHashes = [];
      const aliasesToCreate = [];
      const aaSequenceUpdates = [];
      const existingAASequences = [];
      const proteinSubUnitsToCreate = [];
      const proteinsToCreate = [];
      const existingProteinsToTag = [];
      const proteinMaterialsToCreate = [];
      const existingProteinMaterialToTag = [];
      const existingAAToTag = [];
      const aaSequencesToCreate = [];
      const inventoryItemsToCreate = [];
      const fpuMWExtMap = {};
      const cleanedRows = [];
      const invalidAARows = [];
      const aaHashToNewAA = {};
      const aaHashToInvItemId = {};
      const addedExtPropsForAAHash = {};
      const alreadyManagedExistingProtein = {};
      const createdAminoAcidAliases = {};
      const { getCsvRowExtProps, createUploadProperties } =
        await getBoundExtendedPropertyUploadHelpers(meta.fields);

      for (const [index, row] of data.entries()) {
        const cleanedDataRow = {};
        for (const key in row) {
          if (row.hasOwnProperty(key)) {
            const valToUse = row[key] && row[key].trim();
            if (valToUse && valToUse !== "-") {
              cleanedDataRow[key] = valToUse;
            }
          }
        }
        const hasRequiredFields = requiredHeaders.every(
          field => cleanedDataRow[field]
        );
        if (!hasRequiredFields) {
          return window.toastr.error(
            `Row ${
              index + 1
            } is missing a required field (${requiredHeaders.join(", ")}).`
          );
        }

        const aaSequence = stripLastStopCodon(cleanedDataRow.AA_SEQUENCE);
        if (aaSequence.indexOf("*") > -1) {
          invalidAARows.push(index + 1);
        }
        const [cleanedAaSequence] = filterSequenceString(aaSequence, {
          name: cleanedDataRow.AA_ID,
          isProtein: true
        });
        cleanedDataRow.cleanedAaSequence = cleanedAaSequence;

        const aaHash = computeSequenceHash(cleanedAaSequence, "AA");
        if (!aaHashes.includes(aaHash)) {
          aaHashes.push(aaHash);
        }
        if (cleanedDataRow.RELEVANT_DNA_MATERIAL) {
          relevantDnaMaterialNames.push(cleanedDataRow.RELEVANT_DNA_MATERIAL);
        }
        fpuNames.push(cleanedDataRow.FPU_ID);
        aaNames.push(cleanedDataRow.AA_ID);
        cleanedRows.push(cleanedDataRow);
      }

      if (invalidAARows.length) {
        const error = {
          validationMsg: `These rows have a "*" in their amino acid sequences: ${invalidAARows.join(
            ", "
          )}`
        };
        throw error;
      }

      const existingFpus = await safeQuery(
        [
          "functionalProteinUnit",
          `id
          name
          materialFpus {
            id
            polynucleotideMaterialId
          }
          proteinMaterial {
            id
            ${taggedItems}
          }
          ${taggedItems}
          ${extendedPropertyUploadFragment}
          `
        ],
        {
          variables: {
            filter: {
              name: fpuNames
            }
          }
        }
      );
      const existingAAs = await checkDuplicateSequences(aaHashes, {
        fragment: `
          id
          name
          molecularWeight
          extinctionCoefficient
          hash
          codingDnaSequences {
            id
            name
            inventoryItems {
              id
            }
            codingDnaSequenceSequenceCodingSequences {
              id
              sequenceId
            }
          }
          ${taggedItems}
          inventoryItems {
            id
          }
          aliases {
            id
            name
          }
          ${extendedPropertyUploadFragment}
        `,
        isProtein: true
      });
      const matFilter = caseInsensitiveFilter(
        "material",
        "name",
        relevantDnaMaterialNames,
        {
          returnQb: true
        }
      );
      matFilter.whereAll({
        materialTypeCode: "DNA"
      });
      const existingMaterialsWithFpus = relevantDnaMaterialNames.length
        ? await safeQuery(
            [
              "material",
              /* GraphQL */ `
                {
                  id
                  name
                  polynucleotideMaterialSequences {
                    id
                  }
                  polynucleotideMaterialMaterialFpus {
                    id
                    functionalProteinUnitId
                  }
                }
              `
            ],
            {
              variables: {
                filter: matFilter
              }
            }
          )
        : [];

      const keyedExistingFpus = keyBy(existingFpus, "name");
      const keyedExistingAAs = keyBy(existingAAs, "name");
      const aaHashToAASequence = keyBy(existingAAs, "hash");
      const keyedExistingMaterialsWithFpus = keyBy(
        existingMaterialsWithFpus,
        p => p.name.toLowerCase()
      );

      // if there are existing fpus we want to allow the user to choose
      // whether they want to duplicate them:
      // if they choose to duplicate then continue as if brand new fpu
      // else just ignore the rows with those fpu

      // we also check for existing aa's but never duplicate them

      const strippedAATracker = [];

      for (const cleanedRow of cleanedRows) {
        const {
          FPU_ID,
          AA_ID,
          PROTEIN_SUBUNIT_NAME,
          PDB_ID: pdbId,
          DESIGN_ID: designId,
          UNIPROT_ID: uniprotId,
          HIS_TAG_LOCATION: hisTagLoc,
          NER: ner,
          GER: ger,
          "AA_PI_(ISOELECTRIC_POINT)": isoPoint,
          COFACTOR: cofactor,
          RELEVANT_DNA_MATERIAL: materialName = "",
          cleanedAaSequence
        } = cleanedRow;
        const aaHash = computeSequenceHash(cleanedAaSequence, "AA");
        const existingAASequence = aaHashToAASequence[aaHash];
        const existingProtein = keyedExistingFpus[FPU_ID];
        const existingMaterial =
          keyedExistingMaterialsWithFpus[materialName.toLowerCase()];
        const otherAaInUpload = aaHashToNewAA[aaHash];
        const aaCid = shortid();

        if (existingAASequence) {
          existingAAToTag.push(existingAASequence);
          existingAASequences.push(existingAASequence);
        }
        // update the tags if FPU already exist
        if (existingProtein) {
          existingProteinsToTag.push(existingProtein);
          existingProteinMaterialToTag.push(existingProtein.proteinMaterial);
        }
        // if (guessIfSequenceIsDnaAndNotProtein(cleanedAaSequence)) {
        //   const error = {
        //     validationMsg: `Invalid amino acid sequence provided for ${AA_ID}.`
        //   };
        //   throw error;
        // }
        if (materialName && !existingMaterial) {
          const error = {
            validationMsg: `The DNA Material ${materialName} does not exist.`
          };
          throw error;
        } else if (materialName && !existingAASequence) {
          const error = {
            validationMsg: `${AA_ID} was not found. When providing a relevant DNA material, its relevant amino acid sequence must exist.`
          };
          throw error;
        }

        let aaInventoryItemId;

        if (aaHashToInvItemId[aaHash]) {
          aaInventoryItemId = aaHashToInvItemId[aaHash];
        } else {
          if (existingAASequence && existingAASequence.inventoryItems.length) {
            aaInventoryItemId = existingAASequence.inventoryItems[0].id;
          } else {
            const cid = shortid();
            inventoryItemsToCreate.push({
              cid,
              inventoryItemTypeCode: "AMINO_ACID_SEQUENCE",
              aminoAcidSequenceId: existingAASequence
                ? existingAASequence.id
                : `&${aaCid}`
            });
            aaInventoryItemId = `&${cid}`;
          }
        }
        aaHashToInvItemId[aaHash] = aaInventoryItemId;

        if (
          existingAASequence &&
          AA_ID !== existingAASequence.name &&
          !createdAminoAcidAliases[existingAASequence.id]
        ) {
          const matchingCds = existingAASequence.codingDnaSequences.find(
            cds => cds.name === existingAASequence.name
          );
          createdAminoAcidAliases[existingAASequence.id] = true;
          const cdsInventoryItemFields = {};
          if (matchingCds) {
            aliasesToCreate.push({
              name: AA_ID,
              sequenceId: matchingCds.id,
              targetInventoryItemId: aaInventoryItemId
            });
            if (matchingCds.inventoryItems.length) {
              const invItemId = matchingCds.inventoryItems[0].id;
              cdsInventoryItemFields.targetInventoryItemId = invItemId;
            } else {
              cdsInventoryItemFields.targetInventoryItem = {
                inventoryItemTypeCode: "DNA_SEQUENCE",
                sequenceId: matchingCds.id
              };
            }
          }
          // aliases  should only be created if there is an existing aa sequence
          // targetInventoryItemId needs  to point to the CDS's inventory item, not the CDS
          // need to check whether CDS has a inventory Item and create one or nest it in the alias upsert
          aliasesToCreate.push({
            name: existingAASequence.name,
            aminoAcidSequenceId: existingAASequence.id,
            ...cdsInventoryItemFields
          });
        }

        const aaSequenceFields = aaSequenceJSONtoGraphQLInput(
          {
            name: AA_ID,
            sequence: cleanedAaSequence
          },
          {
            strippedAATracker
          }
        );

        if (existingAASequence) {
          const aaSequenceUpdate = {
            id: existingAASequence.id,
            commonId: AA_ID,
            name: AA_ID,
            hisTagLoc,
            isoPoint,
            uniprotId
          };
          aaSequenceUpdates.push(aaSequenceUpdate);
        }

        const aminoAcidSequence = {
          ...aaSequenceFields,
          cid: aaCid,
          commonId: AA_ID,
          hisTagLoc,
          isoPoint,
          uniprotId
        };

        const fpuCid = shortid();
        const fpu = {
          cid: fpuCid,
          commonId: FPU_ID,
          name: FPU_ID,
          designId,
          ner,
          ger,
          pdbId,
          cofactor
        };
        // only want to create fpu for first row with this fpu_id
        // index will track protein sub unit
        let createFPU = !existingProtein;
        if (!seenFpuMap[FPU_ID]) {
          seenFpuMap[FPU_ID] = {
            count: 1,
            cid: fpuCid
          };
        } else {
          createFPU = false;
        }
        if (!fpuMWExtMap[FPU_ID]) {
          fpuMWExtMap[FPU_ID] = {
            molecularWeight: 0,
            extinctionCoefficient: 0
          };
        }
        const codingDnaSequencePlasmidIds = [];

        if (existingAASequence) {
          existingAASequence.codingDnaSequences.forEach(cds => {
            if (cds.codingDnaSequenceSequenceCodingSequences.length > 0) {
              cds.codingDnaSequenceSequenceCodingSequences.forEach(
                sequenceCds => {
                  codingDnaSequencePlasmidIds.push(sequenceCds.sequenceId);
                }
              );
            }
          });
        }
        if (existingMaterial) {
          const validPlasmid =
            existingMaterial.polynucleotideMaterialSequences.some(sequence =>
              codingDnaSequencePlasmidIds.includes(sequence.id)
            );
          if (!validPlasmid) {
            const error = {
              validationMsg: `DNA material's (${existingMaterial.name}) sequence does not match coding DNA sequences of corresponding amino acid sequence.`
            };
            throw error;
          }
        }

        const aaToUse = keyedExistingAAs[AA_ID]
          ? keyedExistingAAs[AA_ID]
          : aminoAcidSequence;
        fpuMWExtMap[FPU_ID].molecularWeight += aaToUse.molecularWeight || 0;
        fpuMWExtMap[FPU_ID].extinctionCoefficient +=
          aaToUse.extinctionCoefficient || 0;

        let aminoAcidSequenceId;
        if (existingAASequence) {
          aminoAcidSequenceId = existingAASequence.id;
        } else if (otherAaInUpload) {
          aminoAcidSequenceId = `&${otherAaInUpload.cid}`;
        }

        if (!existingAASequence && !otherAaInUpload) {
          aaSequencesToCreate.push(aminoAcidSequence);
          aminoAcidSequenceId = `&${aminoAcidSequence.cid}`;
          // don't duplicate aa's if more rows have this aa_id
          // need to keep track of cid so next subunits will also
          // get it
          aaHashToNewAA[aaHash] = aminoAcidSequence;
          keyedExistingAAs[AA_ID] = {
            id: `&${aaCid}`
          };
        }

        proteinSubUnitsToCreate.push({
          name: PROTEIN_SUBUNIT_NAME,
          index: seenFpuMap[FPU_ID].count++,
          functionalProteinUnitId: existingProtein
            ? existingProtein.id
            : `&${seenFpuMap[FPU_ID].cid}`,
          aminoAcidSequenceId
        });
        if (createFPU) {
          proteinsToCreate.push(fpu);
          proteinMaterialsToCreate.push({
            name: fpu.name,
            functionalProteinUnitId: `&${fpu.cid}`,
            materialTypeCode: "PROTEIN"
          });
          if (existingMaterial) {
            materialFpusToCreate.push({
              functionalProteinUnitId: `&${fpu.cid}`,
              polynucleotideMaterialId: existingMaterial.id
            });
          }
        } else if (
          existingProtein &&
          existingMaterial &&
          !alreadyManagedExistingProtein[existingProtein.id]
        ) {
          const linkedToMaterial = existingProtein.materialFpus.find(
            materialFpu =>
              materialFpu.polynucleotideMaterialId === existingMaterial.id
          );
          if (!linkedToMaterial) {
            materialFpusToCreate.push({
              functionalProteinUnitId: existingProtein.id,
              polynucleotideMaterialId: existingMaterial.id
            });
          }
        }

        // this is used to make sure we don't repeat logic for existing proteins
        if (existingProtein) {
          alreadyManagedExistingProtein[existingProtein.id] = true;
        }

        // handle extendedProperties
        if (!addedExtPropsForAAHash[aaHash]) {
          addedExtPropsForAAHash[aaHash] = true;
          getCsvRowExtProps({
            row: cleanedRow,
            recordId: aminoAcidSequenceId,
            record: existingAASequence,
            modelTypeCode: "AMINO_ACID_SEQUENCE",
            typeFilter: ["aminoAcidSequence", "amino acid sequence"]
          });
        }
        if (createFPU) {
          const inventoryItemCid = shortid();
          inventoryItemsToCreate.push({
            cid: inventoryItemCid,
            inventoryItemTypeCode: "FUNCTIONAL_PROTEIN_UNIT",
            functionalProteinUnitId: `&${fpuCid}`
          });
          getCsvRowExtProps({
            row: cleanedRow,
            modelTypeCode: "FUNCTIONAL_PROTEIN_UNIT",
            typeFilter: ["protein", "functional protein unit"],
            recordId: `&${fpuCid}`
          });
        }
      }

      proteinsToCreate.forEach(protein => {
        const { molecularWeight, extinctionCoefficient } =
          fpuMWExtMap[protein.commonId] || {};
        protein.molecularWeight = molecularWeight;
        protein.extinctionCoefficient = extinctionCoefficient;
      });

      if (strippedAATracker.length) {
        const continueUpload =
          await showAminoAcidStrippedCharacterWarningDialog(strippedAATracker);
        if (!continueUpload) {
          return;
        }
      }

      if (
        proteinsToCreate.length ||
        aaSequencesToCreate.length ||
        proteinMaterialsToCreate.length
      ) {
        await startImportCollection(filename || "Protein Upload");
      }

      const newProteins = await safeUpsert(
        ["functionalProteinUnit", "id name"],
        addTaggedItemsBeforeCreate(proteinsToCreate, tags)
      );
      await safeUpsert(
        "material",
        addTaggedItemsBeforeCreate(proteinMaterialsToCreate, tags),
        {
          excludeResults: true
        }
      );
      await safeUpsert(
        "aminoAcidSequence",
        addTaggedItemsBeforeCreate(aaSequencesToCreate, tags),
        {
          excludeResults: true
        }
      );
      const existingAminoAcidSequences = await safeUpsert(
        ["aminoAcidSequence", `id ${taggedItems}`],
        aaSequenceUpdates
      );

      await safeUpsert("proteinSubUnit", proteinSubUnitsToCreate, {
        excludeResults: true
      });
      await safeUpsert("materialFpu", materialFpusToCreate, {
        excludeResults: true
      });
      await safeUpsert("inventoryItem", inventoryItemsToCreate, {
        excludeResults: true
      });

      await upsertUniqueAliases(aliasesToCreate);
      await createUploadProperties();

      const aminoAcidSequencesToTag = uniqBy(
        existingAAToTag.concat(existingAminoAcidSequences),
        "id"
      );

      const itemsToTag = [
        existingProteinsToTag,
        aminoAcidSequencesToTag,
        existingProteinMaterialToTag
      ];
      for (const items of itemsToTag) {
        await createTaggedItems({
          selectedTags: tags,
          records: items
        });
      }
      await refetch();
      if (newProteins.length || existingAASequences.length) {
        this.setState({
          submittedData: { newProteins, existingAASequences }
        });
      } else {
        hideModal();
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error uploading proteins.");
      if (error.validationMsg) {
        throwFormError(error.validationMsg);
      }
      throwFormError(error);
    }
  };

  readPlateCsv = async (files, onChange) => {
    try {
      const file = files[0];
      const csv = await parseCsvOrExcelFile(file);
      const newFile = {
        ...file,
        csv,
        loading: false
      };
      onChange([newFile]);
      return false;
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(error.message || "Error parsing csv.");
      onChange([]);
    }
  };

  renderForm() {
    const { submitting, handleSubmit, hideModal, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes,
              fileName: "proteins.csv",
              requiredHeaders,
              extendedPropTypes: ["protein", "amino acid sequence"],
              headers: requiredHeaders.concat([
                "RELEVANT_DNA_MATERIAL",
                "DESIGN_ID",
                "PDB_ID",
                "UNIPROT_ID",
                "HIS_TAG_LOCATION",
                "NER",
                "GER",
                "AA_PI_(ISOELECTRIC_POINT)",
                "COFACTOR",
                "EXTERNAL_LINK"
              ])
            })}
            name="proteinFile"
            isRequired
            beforeUpload={this.readPlateCsv}
          />
          <BlueprintError error={error} />
          <TagField />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }

  renderTable() {
    const {
      submittedData: { newProteins, existingAASequences }
    } = this.state;
    const { hideModal } = this.props;
    return (
      <div>
        <div className={Classes.DIALOG_BODY}>
          {!!existingAASequences.length && (
            <React.Fragment>
              <h6 className="header-margin">
                {`Duplicated Amino Acid ${pluralIfNeeded(
                  "Sequence",
                  existingAASequences
                )} detected and will not be imported`}
              </h6>
              <DataTable
                isSimple
                maxHeight={300}
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="existingAAFpuUpload"
                entities={existingAASequences}
                onDoubleClick={record => {
                  const itemUrl = `/amino-acid-sequences/${record.id}`;
                  openInNewTab(itemUrl);
                }}
              />
            </React.Fragment>
          )}
          <br />
          {!!newProteins.length && (
            <React.Fragment>
              <h6 className="header-margin">
                {`Created ${
                  newProteins.length
                } New Functional Protein ${pluralIfNeeded(
                  "Unit",
                  newProteins
                )}`}
              </h6>
              <DataTable
                isSimple
                schema={[
                  {
                    path: "name"
                  }
                ]}
                formName="fpuUpload"
                entities={newProteins}
                onDoubleClick={record => {
                  const itemUrl = `/functional-protein-units/${record.id}`;
                  openInNewTab(itemUrl);
                }}
              />
            </React.Fragment>
          )}
        </div>
        <DialogFooter noCancel onClick={hideModal} text="OK" />
      </div>
    );
  }

  render() {
    const { submittedData } = this.state;
    return submittedData ? this.renderTable() : this.renderForm();
  }
}

const validate = values => {
  const errors = {};
  if (values.proteinFile && values.proteinFile.length) {
    const [{ csv }] = values.proteinFile;
    if (!csv) errors.proteinFile = "No CSV file found.";
    else {
      const {
        meta: { fields }
      } = csv;
      const hasRequiredFields = requiredHeaders.every(field =>
        fields.includes(field)
      );
      if (!hasRequiredFields) {
        errors.proteinFile = `CSV file is missing required headers. (${requiredHeaders.join(
          ", "
        )})`;
      } else {
        const extraFields = fields.reduce((acc, field) => {
          if (!allFields.includes(field) && !field.startsWith("ext-")) {
            acc.push(field);
          }
          return acc;
        }, []);
        if (extraFields.length) {
          errors.proteinFile = `CSV has unrecognized fields: ${extraFields.join(
            ", "
          )}`;
        }
      }
    }
  }
  return errors;
};

export default compose(
  wrapDialog({
    title: "Upload Proteins"
  }),
  reduxForm({
    form: "uploadProteinDialogForm",
    validate
  })
)(UploadProteinDialog);
