/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment dataGridPartialFragment on dataGrid {
    id
    name
    description
    createdAt
    updatedAt
    dataGridStateCode
    importFileSet {
      id
      name
      description
      importFileSetStepCode
      header
      assay {
        id
        name
      }
    }
    lab {
      id
      name
    }
  }
`;
