/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DataTable, CollapsibleCard, withTableParams } from "@teselagen/ui";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import withQuery from "../../../src-shared/withQuery";
import gql from "graphql-tag";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";

const schema = {
  model: "sampleQualityControlCheck",
  fields: [
    {
      displayName: "Name",
      path: "qualityControlCheck.name"
    },
    {
      displayName: "Status",
      path: "sampleStatus.name"
    },
    {
      path: "aliquotId",
      type: "number",
      displayName: "Aliquot",
      render: (val, r) => {
        if (val) {
          return (
            <Link to={modelNameToLink("aliquot", r.aliquotId)}>
              {"Aliquot " + val}
            </Link>
          );
        }
      }
    },
    dateModifiedColumn
  ]
};

function SampleQCChecksTableCard({ tableParams, title = "QC Checks" }) {
  return (
    <CollapsibleCard title={title} className="sampleQCChecks-table-card">
      <DataTable {...tableParams} noPadding />
    </CollapsibleCard>
  );
}

const fragment = gql`
  fragment SampleQCChecksTableCardFragment on sampleQualityControlCheck {
    id
    sampleId
    qualityControlCheckId
    qualityControlCheck {
      id
      name
    }
    sampleStatus {
      code
      name
    }
    aliquotId
    createdAt
    updatedAt
  }
`;

export default compose(
  withTableParams({
    formName: "sampleQCChecksTable",
    urlConnected: false,
    schema,
    additionalFilter: (props, ...rest) => {
      return props.additionalFilter(props, ...rest);
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(SampleQCChecksTableCard);
