/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { range } from "lodash";
const sequenceStringToFragments = (sequenceStr, fragLength = 4000) => {
  return range(Math.ceil(sequenceStr.length / fragLength)).map((i, index) => ({
    fragment: sequenceStr.substr(fragLength * i, fragLength),
    index
  }));
};

export default sequenceStringToFragments;
