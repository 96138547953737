/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { keyBy } from "lodash";
import GridContextMenu from "../components/HierarchicalDesign/ClassicGrid/GridContextMenu";
import {
  getSelectedBinIds,
  getSelectedCellPaths,
  getSelectedCardId,
  getSelectedBin,
  getSelectedElement,
  getBinOfSelectedElement,
  getSelectedElementFas,
  getSelectedBinOverhangs
} from "../../src-shared/selectors/designViewSelectors";
import actions from "../../src-shared/redux/actions";
import { getInputCardById } from "../../src-shar../../src-shared/selectors/classicViewSelectors";
import {
  getDsfForCard,
  getBinsInCard,
  getAssemblyMethodOfCard,
  getRestrictionEnzymeOfReaction,
  getAllOfType
} from "../../../tg-iso-design/selectors/designStateSelectors";
import {
  isViewClassic,
  getSelectedAssemblyMethod
} from "../../src-shared/../src-shared/selectors/classicViewSelectors";
import {
  getNeighborArfPartId,
  getFirstNeighborPartId
} from "../utils/stateUtils";
import { get } from "lodash";

const hasMultiple = (array, fn) =>
  array.reduce((acc, item) => acc + +!!fn(item), 0) > 1;

const hasExactlyOne = (array, fn) =>
  array.reduce((acc, item) => acc + +!!fn(item), 0) === 1;

const mapStateToProps = state => {
  const selectedCardId = getSelectedCardId(state);
  const selectedBinIds = getSelectedBinIds(state);
  const selectedCellPaths = getSelectedCellPaths(state);
  const selectedCellsWithElements = selectedCellPaths.filter(p => p.elementId);

  const selectedElement = getSelectedElement(state);
  const setOfSelectedElement =
    selectedElement && getBinOfSelectedElement(state);

  const selectedElementFas = getSelectedElementFas(state);

  const selectedBinOverhangs = getSelectedBinOverhangs(state);

  const selectedInputCards = selectedBinIds.map(setId =>
    getInputCardById(state, setId)
  );

  // ARF stuff, so we can query for these parts and their partSeqContextView
  const previousArfPartId = getNeighborArfPartId(
    state,
    selectedBinIds[0],
    selectedCardId,
    true
  );
  const nextArfPartId = getNeighborArfPartId(
    state,
    selectedBinIds[0],
    selectedCardId,
    false
  );

  const firstPreviousPartId = previousArfPartId
    ? null
    : getFirstNeighborPartId(state, selectedBinIds[0], selectedCardId, true);
  const firstNextPartId = nextArfPartId
    ? null
    : getFirstNeighborPartId(state, selectedBinIds[0], selectedCardId, false);
  // end ARF stuff

  const assemblyMethodOfCard = getAssemblyMethodOfCard(state, selectedCardId);

  const reactionRestrictionEnzyme = getRestrictionEnzymeOfReaction(
    state,
    // All selected input cards share the same reaction, so we can just take the first one.
    get(selectedInputCards, "[0].inputReactionId")
  );

  const elements = getAllOfType(state, "element");

  const elementsById = keyBy(elements, "id");
  const selectedElements = selectedCellPaths.map(
    p => elementsById[p.elementId]
  );

  const selectedElementsWithAminoAcidPartIds = selectedElements.filter(
    e => e?.aminoAcidPartId
  );

  return {
    state, // kc_todo passing the whole state is overkill, needs refactoring
    isClassicView: !!isViewClassic(state),
    selectedAssemblyMethod: getSelectedAssemblyMethod(state),
    //
    selectedCardId,
    selectedBinIds,
    selectedCellPaths,
    selectedCellsWithElements,
    selectedElementsWithAminoAcidPartIds,
    //
    previousArfPartId,
    nextArfPartId,
    firstPreviousPartId,
    firstNextPartId,
    //
    selectedSet: getSelectedBin(state),
    selectedElement,
    selectedElementFas,
    selectedBinOverhangs,
    setOfSelectedElement,
    selectedInputCards,
    bins: getBinsInCard(state, selectedCardId),
    //
    selectionHasAtLeastOneElement: selectedCellPaths.some(p => p.elementId),
    selectionHasExactlyOneElement: hasExactlyOne(
      selectedCellPaths,
      p => p.elementId
    ),
    selectionHasMultipleElements: hasMultiple(
      selectedCellPaths,
      p => p.elementId
    ),
    selectionHasMultipleRows:
      Object.keys(keyBy(selectedCellPaths, "index")).length > 1,
    selectionHasMultipleColumns: selectedBinIds.length > 1,
    //
    selectionHasAtLeastOneDsf: selectedInputCards.some(c =>
      getDsfForCard(state, c.id)
    ),
    selectionHasAllDsfs: selectedInputCards.every(c =>
      getDsfForCard(state, c.id)
    ),
    selectionHasMultipleDsfs: hasMultiple(selectedInputCards, c =>
      getDsfForCard(state, c.id)
    ),
    selectionHasMultipleNonDsfs: hasMultiple(
      selectedInputCards,
      c => !getDsfForCard(state, c.id)
    ),
    assemblyMethodOfCard,
    reactionRestrictionEnzyme
  };
};

const mapDispatchToProps = {
  removeElements: actions.design.removeElements,
  createElements: actions.design.createElements,
  removeBins: actions.design.removeBins,
  insertBin: actions.design.insertBin,
  updateBin: actions.design.updateBin,
  setDsf: actions.design.setDsf,
  changeFas: actions.design.changeFas,
  removeEugeneRule: actions.designEdit.removeEugeneRule,
  insertRow: actions.design.insertRow,
  removeRows: actions.design.removeRows,
  alphabetizeParts: actions.design.alphabetizeParts,
  eliminateCombinations: actions.design.eliminateCombinations
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GridContextMenu
);
