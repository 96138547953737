/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Classes, Menu, MenuItem } from "@blueprintjs/core";
import { isEmpty, pick, keyBy } from "lodash";
import React, { useState } from "react";

import { DropdownButton } from "@teselagen/ui";

import {
  areAllAliquotContainersUnusableBecauseOfDeadVolume,
  getAliquotContainerByLocation,
  getUsableVolumeOfAliquotContainer
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { throwFormError } from "../../../../../src-shared/utils/formUtils";
import { standardizeVolume } from "../../../../../src-shared/utils/unitUtils";
import { getWellContents } from "../../../../utils/plateUtils";
import PlateMapPlate from "../../../PlateMapPlate";
import PlateMapView from "../../../PlateMapView";
import UnitInputField from "../../../UnitInputField";
import { compose } from "recompose";

function PrepPlates(props) {
  const {
    inputPlates = [],
    selectedLocationsForPlates = {},
    sourcedReagentPlatesAndTubes = [],
    stepFormProps: { change },
    Footer,
    handleSubmit,
    aliquotContainerTypes,
    footerProps,
    nextStep
  } = props;
  const [currentPage, setCurrentPage] = useState(0);
  // will need to add a clear selection function
  const currentPlate = inputPlates[currentPage];
  const generateWorklist = async values => {
    const {
      transferVolume,
      transferVolumeUnitCode,
      selectedLocationsForPlates,
      preppingReagent,
      plateToAcs = {},
      inputPlates = [],
      sourceReagent
    } = values;
    if (sourceReagent) {
      try {
        const standardTransferVolume = standardizeVolume(
          transferVolume,
          transferVolumeUnitCode,
          true
        );

        const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");
        let allAcs = [];
        Object.keys(plateToAcs).forEach(plateId => {
          if (
            sourcedReagentPlatesAndTubes.find(
              plate =>
                plate.__typename === "containerArray" && plate.id === plateId
            )
          ) {
            allAcs = allAcs.concat(plateToAcs[plateId]);
          }
        });
        const sourcedTubes = sourcedReagentPlatesAndTubes.filter(
          t => t.__typename === "aliquotContainer"
        );
        allAcs = allAcs.concat(sourcedTubes);
        const allAcsCopy = [...allAcs];
        const acToVolumeLeft = {};

        const hasEnoughVolume = ac => {
          const volume =
            acToVolumeLeft[ac.id] ||
            getUsableVolumeOfAliquotContainer(ac, keyedAliquotContainerTypes);
          acToVolumeLeft[ac.id] = volume;
          return volume.gte(standardTransferVolume);
        };

        const getUsableAc = ac => {
          if (ac && hasEnoughVolume(ac)) return ac;
          else {
            const candidate = allAcs.shift();
            if (!candidate) return null;
            // if it has enough volume return it
            if (hasEnoughVolume(candidate)) return candidate;
            else {
              return getUsableAc();
            }
          }
        };

        const removeTransferFromAc = ac => {
          acToVolumeLeft[ac.id] = acToVolumeLeft[ac.id].minus(
            standardTransferVolume
          );
        };

        let currentAc;

        const transfers = [];

        Object.keys(selectedLocationsForPlates).forEach(plateId => {
          const destinationPlate = inputPlates.find(
            plate => plate.id === plateId
          );
          selectedLocationsForPlates[plateId].forEach(location => {
            currentAc = getUsableAc(currentAc);
            if (!currentAc) {
              let error = {
                validationError: "Not enough source volume."
              };
              if (
                areAllAliquotContainersUnusableBecauseOfDeadVolume({
                  allAliquotContainers: allAcsCopy,
                  keyedAliquotContainerTypes
                })
              ) {
                error = {
                  validationError:
                    "The available volume of the source wells is less than the dead volume of the wells."
                };
              }
              throw error;
            } else {
              transfers.push({
                sourceAliquotContainer: currentAc,
                sourceAliquotContainerId: currentAc.id,
                destinationAliquotContainer: {
                  ...getAliquotContainerByLocation(destinationPlate, location),
                  containerArray: destinationPlate
                },
                destinationPlateName: destinationPlate.name,
                volume: transferVolume,
                volumetricUnitCode: transferVolumeUnitCode
              });
              removeTransferFromAc(currentAc);
            }
          });
        });
        change("worklist", { worklistTransfers: transfers });
        nextStep();
      } catch (error) {
        console.error(`error:`, error);
        throwFormError(error.validationError || "Error generating worklist.");
      }
    } else {
      const mockTransfers = [];
      Object.keys(selectedLocationsForPlates).forEach(plateId => {
        const destinationPlate = inputPlates.find(
          plate => plate.id === plateId
        );
        selectedLocationsForPlates[plateId].forEach(location => {
          mockTransfers.push({
            preppingReagent: preppingReagent.name,
            plateName: destinationPlate.name,
            plateBarcode: destinationPlate.barcode?.barcodeString,
            destinationAliquotContainer: {
              ...getAliquotContainerByLocation(destinationPlate, location),
              containerArray: destinationPlate
            },
            volume: transferVolume,
            volumetricUnitCode: transferVolumeUnitCode
          });
        });
      });
      change("mockTransfers", mockTransfers);
      nextStep();
    }
  };

  const updateLocations = (locations = []) => {
    // logic needed here
    const newSelectedLocations = {
      ...selectedLocationsForPlates
    };
    if (locations.length) {
      newSelectedLocations[currentPlate.id] = locations;
    } else {
      delete newSelectedLocations[currentPlate.id];
    }
    change("selectedLocationsForPlates", newSelectedLocations);
  };

  const clearLocations = () => {
    change("selectedLocationsForPlates", {});
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const containerFormat = currentPlate.containerArrayType.containerFormat;

  const selectedLocations = selectedLocationsForPlates[currentPlate.id] || [];

  const selectWellsHelper = nonEmpty => {
    const newSelection = {};
    inputPlates.forEach(plate => {
      newSelection[plate.id] = [];
      plate.aliquotContainers.forEach(ac => {
        if (nonEmpty ? ac.additives.length || ac.aliquot : true) {
          newSelection[plate.id].push(
            getAliquotContainerLocation(ac, { force2D: true })
          );
        }
      });
    });
    change("selectedLocationsForPlates", newSelection);
  };
  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Prep Plates"
          helper={`Below is a preview of the plates
          selected in the previous step. Designate
          the wells you would like to add prepping
          reagents to.`}
          width="100%"
        />
        <div className="tg-flex align-center row">
          {inputPlates.length > 1 && (
            <React.Fragment>
              <Button
                disabled={currentPage === 0}
                onClick={prevPage}
                className={Classes.MINIMAL}
                icon="arrow-left"
              />
              Plate {currentPage + 1} of {inputPlates.length}
              <Button
                disabled={currentPage === inputPlates.length - 1}
                onClick={nextPage}
                className={Classes.MINIMAL}
                icon="arrow-right"
              />
            </React.Fragment>
          )}
        </div>
        <div className="tg-flex">
          <PlateMapPlate
            {...pick(currentPlate, ["aliquotContainers", "containerArrayType"])}
            withDragSelect
            onWellsSelected={updateLocations}
            selectedAliquotContainerLocations={selectedLocations}
          />
          <div style={{ flex: 1, marginLeft: 10 }}>
            <PlateMapView
              noPadding
              tableOnly
              containerArray={{
                aliquotContainers: currentPlate.aliquotContainers,
                containerArrayType: {
                  containerFormat
                }
              }}
              tableSchema={[
                {
                  displayName: "Well",
                  render: (v, r) => {
                    return getAliquotContainerLocation(
                      {
                        rowPosition: r.rowPosition,
                        columnPosition: r.columnPosition
                      },
                      {
                        containerFormat
                      }
                    );
                  }
                },
                {
                  displayName: "Contents",
                  render: (v, r) => getWellContents(r)
                }
              ]}
            />
          </div>
        </div>
        <div className="tg-flex">
          <Button
            style={{ marginRight: 10 }}
            text="Clear Selection"
            onClick={clearLocations}
            intent="danger"
          />
          <DropdownButton
            text="Selection Options"
            menu={
              <Menu>
                <MenuItem
                  onClick={() => {
                    selectWellsHelper();
                  }}
                  text="Select All Wells"
                />
                <MenuItem
                  onClick={() => {
                    selectWellsHelper(true);
                  }}
                  text="Select Non-Empty Wells"
                />
              </Menu>
            }
          />
        </div>
      </div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Transfer Volume"
          helper="Specify the prepping reagent transfer volume to selected wells."
        />
        <UnitInputField
          name="transferVolume"
          isRequired
          label="Transfer Volume"
          unitName="transferVolumeUnitCode"
          unitDefault="uL"
          unitType="volumetricUnit"
        />
      </div>
      <Footer
        {...footerProps}
        nextDisabled={isEmpty(selectedLocationsForPlates)}
        onNextClick={handleSubmit(generateWorklist)}
      />
    </React.Fragment>
  );
}

export default compose(
  stepFormValues(
    "inputPlates",
    "selectedLocationsForPlates",
    "sourcedReagentPlatesAndTubes"
  )
)(PrepPlates);
