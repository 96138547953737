/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import { keyBy } from "lodash";
import shortid from "shortid";
import { standardizeVolume } from "../../../src-shared/utils/unitUtils";
import { aliquotContainerBottoms, bottomField } from "../../utils/plateUtils";
import {
  throwFormError,
  isValidPositiveNumber
} from "../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";
import caseInsensitiveFilter from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { validateNameCsvTableUniqueAsync } from "../../components/AppSettings/validateNameCsvTableUniqueAsync";
import { volumeAndDeadVolumeFields } from "../../../../tg-iso-shared/src/utils/unitCodeFields";
import { validateDeadVolume } from "../../../src-shared/utils/fieldUtils";

class UploadPlateTypes extends Component {
  onSubmit = async values => {
    try {
      const { refetch, hideModal } = this.props;
      const namesToCheck = [];
      const formatsToCheck = [];
      for (const row of values.plateTypesFile[0].parsedData) {
        namesToCheck.push(row.name);
        formatsToCheck.push(row.format);
      }
      if (!namesToCheck.length) return window.toastr.error("No rows in csv.");
      const existingPlateTypes = await safeQuery(
        ["containerArrayType", "id name"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "containerArrayType",
              "name",
              namesToCheck
            )
          }
        }
      );
      const existingFormats = await safeQuery(
        ["containerFormat", "code name"],
        {
          variables: {
            filter: caseInsensitiveFilter(
              "containerFormat",
              "name",
              formatsToCheck
            )
          }
        }
      );
      const newPlateTypes = [];
      const keyedExisting = keyBy(existingPlateTypes, t =>
        t.name.toLowerCase()
      );
      const keyedFormats = keyBy(existingFormats, f => f.name.toLowerCase());
      for (const [
        index,
        row
      ] of values.plateTypesFile[0].parsedData.entries()) {
        const {
          name = "",
          description,
          format = "",
          "catalog number": catalogNumber,
          manufacturer,
          maxVolume,
          volumetricUnitCode,
          deadVolume,
          deadVolumetricUnitCode,
          plateWellTypeName: wellType,
          isColumn = "",
          plateWellTypeDescription: wellTypeDescription,
          bottom = ""
        } = row;
        if (keyedExisting[name.toLowerCase()]) {
          throw new Error(
            `Row ${index + 1} specifies the name ${name} which already exists`
          );
        }
        const containerFormat = keyedFormats[format.toLowerCase()];
        if (!containerFormat) {
          throw new Error(
            `Row ${index + 1} specifies the format ${format} which is not valid`
          );
        }
        if (!unitGlobals.volumetricUnits[volumetricUnitCode]) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the max volume unit ${volumetricUnitCode} which does not exist`
          );
        }
        if (
          deadVolumetricUnitCode &&
          !unitGlobals.volumetricUnits[deadVolumetricUnitCode]
        ) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the dead volume unit ${deadVolumetricUnitCode} which does not exist`
          );
        }
        if (deadVolume && !deadVolumetricUnitCode) {
          throw new Error(
            `Row ${
              index + 1
            } has a dead volume but does not provide a dead volume unit.`
          );
        }
        if (!isValidPositiveNumber(maxVolume)) {
          throw new Error(
            `Row ${index + 1} did not provide a valid max volume.`
          );
        }
        if (deadVolume && !isValidPositiveNumber(deadVolume)) {
          throw new Error(
            `Row ${index + 1} did not provide a valid dead volume.`
          );
        }
        if (deadVolume) {
          const standardizedMaxVolume = standardizeVolume(
            maxVolume,
            volumetricUnitCode
          );
          const standardizedDeadVolume = standardizeVolume(
            deadVolume,
            deadVolumetricUnitCode
          );
          if (standardizedDeadVolume >= standardizedMaxVolume) {
            throw new Error(
              `Row ${index + 1} dead volume is greater than its max volume.`
            );
          }
        }

        const validBottom = aliquotContainerBottoms.find(
          b => b.toLowerCase() === (bottom || "Round").toLowerCase()
        );
        if (!validBottom) {
          throw new Error(
            `Row ${
              index + 1
            } did not provide a valid well bottom (${aliquotContainerBottoms.join(
              ", "
            )})`
          );
        }

        newPlateTypes.push({
          name,
          description,
          containerFormatCode: containerFormat.code,
          isColumn,
          isPlate: true,
          catalogNumber,
          manufacturer,
          aliquotContainerType: {
            code: shortid(),
            name: wellType || "Round",
            description: wellTypeDescription,
            maxVolume,
            volumetricUnitCode,
            bottom: validBottom,
            isTube: false,
            deadVolume: deadVolume || null,
            deadVolumetricUnitCode: deadVolumetricUnitCode || null
          }
        });
      }
      await safeUpsert("containerArrayType", newPlateTypes);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading plate types.`);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            isRequired
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              fileName: "plateTypes",
              validateAgainstSchema: {
                ...validateNameCsvTableUniqueAsync({
                  model: "containerArrayType"
                }),
                ...validateDeadVolume,
                fields: [
                  {
                    path: "name",
                    description: "Required. Name of the plate well",
                    example: "Well_A1",
                    isUnique: true,
                    isRequired: true
                  },
                  {
                    path: "description",
                    description: "Description of the plate type",
                    example:
                      "This plate type is used for microbial growth assays"
                  },
                  {
                    path: "format",
                    description: "Required. Plate format: 6 Well, 24 Well, etc",
                    example: "24 Well",
                    isRequired: true
                  },
                  {
                    path: "catalog number",
                    description: "Catalog number of the rack",
                    example: "12345"
                  },
                  {
                    path: "manufacturer",
                    description: "Manufacturer of the rack",
                    example: "Company XYZ"
                  },
                  {
                    path: "isColumn",
                    type: "boolean"
                    // description: "Leave blank or specify as 'yes' or 'no'",
                    // example: "yes"
                  },
                  {
                    path: "plateWellTypeName",
                    description: "Default is 'Round'",
                    example: "Round"
                  },
                  {
                    path: "plateWellTypeDescription",
                    description: "",
                    example: ""
                  },
                  ...volumeAndDeadVolumeFields({ type: "plate well" }),
                  bottomField
                ]
              }
            })}
            name="plateTypesFile"
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Upload Plate Types"
  }),
  reduxForm({
    form: "uploadPlateTypes"
  })
)(UploadPlateTypes);
