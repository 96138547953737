/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import {
  DataTable,
  withSelectedEntities,
  Loading,
  BlueprintError
} from "@teselagen/ui";
import { connect } from "react-redux";
import { change } from "redux-form";
import { pick, forEach, get, keyBy } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";

import selectLotsForPlatePrepFragment from "../../../../graphql/fragments/selectLotsForPlatePrepFragment";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import TagTableColumn from "../../../../../src-shared/TagTableColumn";
import {
  dateModifiedColumn,
  tagColumn
} from "../../../../../src-shared/utils/libraryColumns";
import {
  volumeRender,
  massRender,
  concentrationRender
} from "../../../../../src-shared/utils/unitUtils";
import { addTagFilterToQuery } from "../../../../../src-shared/utils/tagUtils";
import {
  platePrepLotFragment,
  platePrepInputPlateFragment,
  platePrepAliquotContainerFragment
} from "../fragments";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class SelectPrepMaterials extends Component {
  state = {
    fetchingFullAliquotContainers: false
  };

  fetchAliquotContainersForPlates = async plates => {
    const {
      stepFormProps: { change }
    } = this.props;

    this.removeAliquotContainerSelection();
    this.setState({
      fetchingFullAliquotContainers: true
    });
    const qb = new QueryBuilder("aliquotContainer");
    const keyedPlates = keyBy(plates, "id");
    const filter = qb
      .whereAll({
        containerArrayId: plates.map(r => r.id)
      })
      .toJSON();
    const aliquotContainersFromPlates = await safeQuery(
      platePrepAliquotContainerFragment,
      {
        nameOverride: "aliquots",
        variables: {
          filter
        }
      }
    );
    const fullAliquotContainers = [];
    aliquotContainersFromPlates.forEach(ac => {
      const notDryAliquot = ac.aliquot && !ac.aliquot.isDry;
      if (notDryAliquot || ac.additives.length) {
        const plate = keyedPlates[ac.containerArrayId];
        fullAliquotContainers.push({
          ...ac,
          containerArray: {
            id: ac.containerArrayId,
            name: plate.name,
            containerArrayType: plate.containerArrayType
          }
        });
      }
    });
    change("aliquotContainersFromPlates", fullAliquotContainers);
    this.setState({
      fetchingFullAliquotContainers: false
    });
  };

  removeAliquotContainerSelection = () => {
    const { changeFormValue, stepFormProps } = this.props;

    changeFormValue(
      "selectedAliquotContainersFromPlates",
      "reduxFormSelectedEntityIdMap",
      {}
    );
    stepFormProps.change("aliquotContainersFromPlates", []);
  };

  render() {
    const { fetchingFullAliquotContainers } = this.state;
    const {
      aliquotContainersFromPlates = [],
      Footer,
      footerProps,
      stepFormProps: { change },
      selectedAliquotContainersFromPlatesSelectedEntities: selectedAliquotsFromPlates = [],
      prepMaterialPlates = [],
      platesToPrep = {},
      destinationContainerArrays = [],
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;

    let selectedPlateIds = new Set();
    forEach(platesToPrep, plates => {
      plates.forEach(p => selectedPlateIds.add(p.id));
    });
    destinationContainerArrays.forEach(p => selectedPlateIds.add(p.id));
    selectedPlateIds = Array.from(selectedPlateIds);
    const missingAliquotError =
      !!prepMaterialPlates.length && !selectedAliquotsFromPlates.length;

    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Materials From Plates or Racks"
            helper="Select one or more plates or racks containing materials you would like to transfer."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "prepMaterialPlates",
                schema: [
                  "name",
                  { displayName: "Barcode", path: "barcode.barcodeString" },
                  dateModifiedColumn
                ],
                isMultiSelect: true,
                onSelect: this.fetchAliquotContainersForPlates,
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: platePrepInputPlateFragment,
                selectedPlateIds,
                tableParamOptions: {
                  additionalFilter: platePrepPlateFilter
                },
                buttonProps: {
                  loading: isLoadingMap.prepMaterialPlates,
                  disabled: isDisabledMap.prepMaterialPlates
                },
                onClear: this.removeAliquotContainerSelection
              }}
            />
            {fetchingFullAliquotContainers ? (
              <Loading inDialog />
            ) : (
              aliquotContainersFromPlates.length > 0 && (
                <DataTable
                  formName="selectedAliquotContainersFromPlates"
                  style={{ marginBottom: 15 }}
                  destroyOnUnmount={false}
                  isSimple
                  withCheckboxes
                  schema={prepAliquotContainerSchema}
                  entities={aliquotContainersFromPlates}
                />
              )
            )}
            {missingAliquotError && (
              <BlueprintError error="Please select at least one aliquot to prep a plate with." />
            )}
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Tubes From Inventory"
            helper="Select one or more tubes containing materials you would like to transfer."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "prepTubes",
                schema: prepTubeDialogSchema,
                isMultiSelect: true,
                dialogProps: {
                  style: {
                    width: 800
                  }
                },
                fragment: platePrepAliquotContainerFragment,
                tableParamOptions: {
                  additionalFilter: additionalFilterForTubes
                },
                postSelectDTProps: {
                  formName: "platePrepSelectedTubes",
                  isSingleSelect: true,
                  schema: prepTubeDialogSchema
                },
                buttonProps: {
                  loading: isLoadingMap.prepTubes,
                  disabled: isDisabledMap.prepTubes
                }
              }}
            />
          </div>
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Reagent Lots"
            helper="Select one or more reagent lots containing materials you would like to transfer. Lots must have enough volume for transfers."
          />
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "prepLots",
                schema: prepLotsSchema,
                isMultiSelect: true,
                dialogProps: {
                  style: {
                    width: 800
                  }
                },
                tableParamOptions: {
                  additionalFilter: additionalFilterForLots
                },
                fragment: selectLotsForPlatePrepFragment,
                additionalDataFragment: platePrepLotFragment,
                postSelectDTProps: {
                  formName: "platePrepSelectedLots",
                  schema: lotSchemaFields
                },
                buttonProps: {
                  loading: isLoadingMap.prepLots,
                  disabled: isDisabledMap.prepLots
                }
              }}
            />
          </div>
        </div>
        <Footer
          {...footerProps}
          onNextClick={() => {
            change("prepAliquotContainers", selectedAliquotsFromPlates);
          }}
          disabled={missingAliquotError}
        />
      </div>
    );
  }
}

const renderMaterialsField = aliquotContainer => {
  const sample = get(aliquotContainer, "aliquot.sample");
  if (sample && sample.sampleTypeCode === "FORMULATED_SAMPLE") {
    const allMats = [];
    const matIds = [];
    sample.sampleFormulations.forEach(sf => {
      sf.materialCompositions.forEach(mc => {
        if (mc.material && !matIds.includes(mc.material.id)) {
          matIds.push(mc.material.id);
          allMats.push(mc.material.name);
        }
      });
    });
    return allMats.join(", ");
  } else if (sample && sample.material) {
    return sample.material.name;
  }
};
const prepAliquotContainerSchema = {
  model: "aliquotContainer",
  fields: [
    {
      displayName: "Well",
      path: "location",
      render: (v, r) => getAliquotContainerLocation(r)
    },
    { displayName: "Material", render: (v, r) => renderMaterialsField(r) },
    {
      displayName: "Volume",
      path: "aliquot",
      type: "number",
      render: volumeRender
    },
    { displayName: "Mass", path: "aliquot", type: "number", render: massRender }
  ]
};

const prepTubeDialogSchema = {
  model: "aliquotContainer",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Barcode",
      path: "barcode.barcodeString"
    },
    {
      displayName: "Materials",
      render: (v, r) => renderMaterialsField(r)
    },
    {
      displayName: "Volume",
      path: "aliquot",
      type: "number",
      render: volumeRender
    },
    {
      displayName: "Mass",
      path: "aliquot",
      type: "number",
      render: massRender
    },
    dateModifiedColumn
  ]
};

const lotSchemaFields = [
  {
    displayName: "Name",
    path: "name"
  },
  {
    displayName: "Reagent",
    path: "additiveMaterial.name"
  },
  {
    displayName: "Volume",
    path: "volume",
    type: "number",
    render: volumeRender
  },
  {
    displayName: "Concentration",
    path: "concentration",
    type: "number",
    render: concentrationRender
  },
  {
    displayName: "Molarity",
    path: "molarity"
  },
  {
    displayName: "Mass",
    path: "mass",
    type: "number",
    render: massRender
  },
  dateModifiedColumn
];

const prepLotsSchema = {
  model: "lot",
  fields: lotSchemaFields.concat({
    ...tagColumn,
    displayName: "Reagent Tags",
    render: (val, record, row, props) => {
      const paramProps = pick(props, ["currentParams", "setNewParams"]);
      return (
        <TagTableColumn
          {...{
            record: record.additiveMaterial,
            paramProps
          }}
        />
      );
    }
  })
};

const additionalFilterForLots = (props, qb, currentParams) => {
  addTagFilterToQuery(currentParams.tags, qb, {
    pathToTaggedItems: "additiveMaterial.taggedItems"
  });

  qb.whereAll({
    volume: qb.greaterThan(0)
  });
};

const additionalFilterForTubes = (props, qb) => {
  qb.whereAll({
    containerArrayId: qb.isNull(),
    "aliquotContainerType.isTube": true
  });
};

export default compose(
  connect(null, {
    changeFormValue: change
  }),
  stepFormValues(
    "aliquotContainersFromPlates",
    "prepMaterialPlates",
    "platesToPrep",
    "destinationContainerArrays"
  ),
  withSelectedEntities("selectedAliquotContainersFromPlates")
)(SelectPrepMaterials);

// changing this filter could affect transfer volume validation
function platePrepPlateFilter(props, qb) {
  const { selectedPlateIds } = props;
  qb.whereAll({
    id: qb.notInList(selectedPlateIds),
    displayFilter: null
  });
}
