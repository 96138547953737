/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { drawSigmaHistogram } from "./d3/sigmaHistogram";
import modelSigmaHistogramWidgetStyles from "./ModelSigmaHistogramWidgetStyles.module.css";
import ReactResizeDetector from "react-resize-detector";

const TAG = "[ModelGroupedHistogramWidget]"; // eslint-disable-line

class ModelSigmaHistogramWidget extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    const chartContainerClass =
      "_" +
      Math.random()
        .toString(36)
        .slice(2, 6);

    this.state = {
      chartContainerClass: chartContainerClass,
      defaultSigmaToPlot: data.output_data[0]
    };
  }

  componentDidMount() {
    const { chartContainerClass, defaultSigmaToPlot } = this.state;
    const { sigmaToPlot } = this.props;

    let dataToPlot = defaultSigmaToPlot;

    if (sigmaToPlot) {
      dataToPlot = sigmaToPlot;
    }

    drawSigmaHistogram(
      chartContainerClass,
      this.divElement.clientHeight,
      this.divElement.clientWidth,
      dataToPlot
    );
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    if (this.divElement) {
      if (
        this.state.width === this.divElement.clientWidth &&
        this.state.height === this.divElement.clientHeight
      ) {
        return false;
      } else {
        this.setState(() => {
          return {
            width: this.divElement.clientWidth,
            height: this.divElement.clientHeight
          };
        });
      }
    }
  };

  render() {
    const { chartContainerClass, defaultSigmaToPlot } = this.state;
    const { sigmaToPlot } = this.props;
    let dataToPlot = defaultSigmaToPlot;

    if (sigmaToPlot) {
      dataToPlot = sigmaToPlot;
    }

    if (this.divElement) {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;

      drawSigmaHistogram(chartContainerClass, height, width, dataToPlot);
    }

    return (
      <div
        className={modelSigmaHistogramWidgetStyles.container}
        ref={divElement => (this.divElement = divElement)}
      >
        <div
          className={`${modelSigmaHistogramWidgetStyles.viz} ${chartContainerClass}`}
        />
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.updateDimensions}
          targetDomEl={this.divElement}
        />
      </div>
    );
  }
}

export default ModelSigmaHistogramWidget;
