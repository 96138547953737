/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  // dnaIcon,
  inventoryIcon
  // strainIcon
} from "@teselagen/ui";

export const materialsMenu = {
  name: "Material Registry",
  icon: "layers",
  menuDescription:
    "Materials link theoretical molecules and strains to physical inventory. Materials can be used in reactions. After a material is registered, the molecule/strain linked to it can no longer be edited."
};
export const inventoryMenu = { name: "Inventory", icon: inventoryIcon };
export const dataMenu = { name: "Data Analysis", icon: "database" };
