/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import SBOLIcons from "../../../SBOLIcons/SBOLIcons";
import { isSetIndexBackboneSourced } from "../../../../../src-shared/utils/addOperationUtils";
import Breakpoint from "./Breakpoint";
import {
  SBOL_ICON_HEIGHT,
  SBOL_ICON_WIDTH
} from "../../../../../src-shared/components/HierarchicalDesign/constants";

class BreakpointsFieldComponent extends React.Component {
  renderIcon = (icon, i) => {
    const {
      operationColor,
      input: { value }
    } = this.props;
    const breakPointIndices = value ? value.indices : [];
    const isOutputCircular = Number(this.props.isOutputCircular);

    // This is only relevent if the output is circular.
    const isBackboneSourced = isSetIndexBackboneSourced(breakPointIndices, i);

    let stroke = "black";
    if (isOutputCircular && isBackboneSourced) stroke = operationColor;
    else stroke = icon.color || "black";

    const iconPath = icon.path || SBOLIcons["USER-DEFINED"].path;

    return (
      <div
        style={{
          display: "inline-block"
        }}
      >
        {iconPath.match("uploads") || iconPath.match("images") ? (
          <img
            alt={icon.name}
            src={"/" + iconPath}
            style={{
              width: SBOL_ICON_WIDTH,
              height: SBOL_ICON_HEIGHT,
              transform: !icon.direction ? "rotate(180deg)" : ""
            }}
          />
        ) : (
          <svg
            viewBox="-10 20 70 60"
            height={SBOL_ICON_HEIGHT}
            width={SBOL_ICON_WIDTH}
            style={{
              transform: !icon.direction ? "rotate(180deg)" : ""
            }}
          >
            <path stroke={stroke} strokeWidth="3" fill="none" d={iconPath} />
          </svg>
        )}
      </div>
    );
  };

  render() {
    const { operationColor, iconsToRender } = this.props;

    const isOutputCircular = Number(this.props.isOutputCircular);

    const borderStyle = "3px solid " + operationColor;
    const loopSharedStyle = {
      display: "inline-block",
      height: SBOL_ICON_WIDTH / 2,
      width: 15,
      borderBottom: borderStyle,
      borderTop: borderStyle,
      marginBottom: -7
    };

    const leftLoop = (
      <div
        style={{
          ...loopSharedStyle,
          borderLeft: borderStyle
        }}
      />
    );

    const rightLoop = (
      <div
        style={{
          ...loopSharedStyle,
          borderRight: borderStyle
        }}
      />
    );

    return (
      <div
        style={{
          overflow: "auto"
        }}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            padding: "10px 30px"
          }}
        >
          <div>
            {!!isOutputCircular && leftLoop}
            {iconsToRender.map((icon, i) => {
              const index = i + 1;
              return (
                <span
                  key={i}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    borderBottom: isOutputCircular && borderStyle
                  }}
                >
                  {this.renderIcon(icon, i)}

                  {i === 0 && this.canPutBreakpointAtIndex(0) && (
                    <Breakpoint {...this.props} index={0} />
                  )}
                  {this.canPutBreakpointAtIndex(index) && (
                    <Breakpoint {...this.props} index={index} />
                  )}
                </span>
              );
            })}
            {!!isOutputCircular && rightLoop}
          </div>
        </div>
      </div>
    );
  }

  canPutBreakpointAtIndex = index =>
    this.props.breakpointableIndices.includes(index);
}

export default BreakpointsFieldComponent;
