/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { inventoryIcon, tubeIcon } from "@teselagen/ui";
import { getPlateTubeLabel } from "./plateUtils";

const terminalContainerType = "assignedPosition";

const getDisplayPath = (label, displayPath) => {
  if (displayPath) return displayPath + " / " + label;
  else return label;
};

export function getNodeLabel(record, { countEmptyRackPositions } = {}) {
  let label = record.name;
  if (record.label) {
    label = record.label + " - " + label;
  }
  const barcode = record.barcode?.barcodeString;
  if (barcode) {
    label += ` (${barcode})`;
  }
  if (record.__typename === "assignedPosition") {
    if (record.containerArray) {
      label = getPlateTubeLabel(record.containerArray);
      if (
        countEmptyRackPositions &&
        !record.containerArray.containerArrayType.isPlate
      ) {
        const max =
          record.containerArray.containerArrayType.containerFormat.quadrantSize;
        const current = record.containerArray.aliquotContainers.length;
        label += ` - ${current} / ${max}`;
      }
    } else if (record.aliquotContainer) {
      label = getPlateTubeLabel(record.aliquotContainer);
    }
  }
  return label;
}

export function buildTreeNodes({
  records,
  displayPath = "",
  selectionMap,
  countEmptyRackPositions = false
}) {
  return records.map(record => {
    let recordDisplayPath = getDisplayPath(
      record.label || record.name,
      displayPath
    );

    const nodeId = `${record.__typename}:${record.id}`;
    let icon = "folder-close";
    const label = getNodeLabel(record, { countEmptyRackPositions });
    let numberOfEmptyPositions = 0;
    if (record.__typename === "assignedPosition") {
      if (record.containerArray) {
        if (
          countEmptyRackPositions &&
          !record.containerArray.containerArrayType.isPlate
        ) {
          const max =
            record.containerArray.containerArrayType.containerFormat
              .quadrantSize;
          const current = record.containerArray.aliquotContainers.length;
          numberOfEmptyPositions = max - current;
          recordDisplayPath = getDisplayPath(label, displayPath);
        }
        icon = inventoryIcon;
      } else if (record.aliquotContainer) {
        icon = tubeIcon;
      }
    }

    return {
      id: nodeId,
      record: {
        ...record,
        displayPath: recordDisplayPath
      },
      numberOfEmptyPositions,
      loaded: false,
      label,
      icon,
      hasCaret: record.__typename !== terminalContainerType,
      isSelected: selectionMap[nodeId]
    };
  });
}

export const sortContainers = containers => {
  return [...containers].sort((c1, c2) => {
    return (c1.label || c1.name || "").localeCompare(c2.label || c2.name || "");
  });
};
