/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { handleActions } from "redux-actions";

import { calculateSelectedLocations } from "../../../../../utils/calculateSelectedLocations";
import actions from "../../../../actions";

const initialState = {
  activeAliquotContainerLocation: null,
  selectedAliquotContainerLocations: []
};

export default handleActions(
  {
    [actions.ui.records.containerArray
      .setSelectedAliquotContainerLocation]: function(state, { payload }) {
      if (!payload || !payload.location) {
        return {
          ...state,
          activeAliquotContainerLocation: null,
          selectedAliquotContainerLocations: []
        };
      } else {
        const { location, shift, ctrl } = payload;
        return {
          ...state,
          ...calculateSelectedLocations(state, { location, shift, ctrl })
        };
      }
    },

    [actions.ui.records.containerArray.setSelectedAliquotContainerLocations]: (
      state,
      { payload: { locations } }
    ) => ({
      ...state,
      selectedAliquotContainerLocations: locations,
      activeAliquotContainerLocation: null
    })
  },
  initialState
);
