/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment dataMapperFragment on mappingPresetColumnMaps {
    id
    columnMap {
      id
      columnName
      classType {
        code
        name
      }
      assaySubjectClass {
        id
        name
      }
      descriptorType {
        id
        name
      }
      measurementType {
        id
        name
      }
      referenceDimension {
        name
        id
      }
      unit {
        id
        name
      }
    }
  }
`;
