/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import RecordInfoTable from "../RecordInfoTable";
import {
  collectAllExtendedValues,
  displayPropertyValueFrontEnd
} from "../../utils/extendedPropertyUtils";
import { strcmp } from "../../utils/generalUtils";
import withRecordExtendedProperties from "../../enhancers/withRecordExtendedProperties";
import { Button } from "@blueprintjs/core";
import { showDialog } from "../../GlobalDialog";

const RecordExtendedProperties = props => {
  const {
    extendedLinkValues = [],
    withEdit,
    withSeparator,
    recordId,
    model,
    libraryFragment
  } = props;

  /**
   * Get the information to render the extended property. Return value will
   * be an array of 2-tuples.
   */
  const getExtendedPropertiesInfo = () => {
    const info = [];

    collectAllExtendedValues(props)
      .concat(extendedLinkValues)
      .forEach(v => {
        const valueDisplay = displayPropertyValueFrontEnd(v);
        info.push([v.extendedProperty.name, valueDisplay]);
      });

    // Sort by the extended property name.
    info.sort((a, b) => strcmp(a[0], b[0]));

    return info;
  };

  const info = getExtendedPropertiesInfo();
  if (!withEdit && !info.length) return null;

  let header = <h6 style={{ marginTop: 10 }}>Extended Properties</h6>;
  if (withEdit) {
    header = (
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {header}
        <Button
          style={{ marginLeft: 12 }}
          icon="edit"
          className="tg-edit-extended-properties"
          minimal
          onClick={() => {
            showDialog({
              modalType: "UPDATE_RECORD_EXTENDED_PROPERTIES",
              modalProps: {
                model,
                libraryFragment,
                recordId
              }
            });
          }}
        />
      </div>
    );
  }
  return (
    <>
      {withSeparator && <hr className="tg-section-break" />}
      {header}
      <RecordInfoTable sections={[info]} />
    </>
  );
};

RecordExtendedProperties.propTypes = {
  // The model of the record. Should be an inventory item.
  model: PropTypes.string.isRequired,
  // The id of the record that we want to render the extended properties
  // of.
  recordId: PropTypes.string.isRequired
};

export default compose(withRecordExtendedProperties)(RecordExtendedProperties);
