/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment transferColumnContainerArrayFragment on containerArray {
    id
    name
    createdAt
    updatedAt
    containerArray {
      id
      name
    }
    collectionPlateId
    collectionPlate {
      id
      name
      aliquotContainers {
        id
      }
    }
    extendedValues {
      id
      value
      extendedProperty {
        id
        name
      }
    }
    containerArrayType {
      id
      name
      isColumn
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    barcode {
      id
      barcodeString
    }
    aliquotContainers {
      id
      containerArray {
        id
        name
      }
      aliquotContainerTypeCode
      columnPosition
      rowPosition
      aliquotId
      aliquot {
        id
        isDry
        volume
        volumetricUnitCode
        concentration
        concentrationUnitCode
        mass
        massUnitCode
        sample {
          id
          name
          materialId
          material {
            id
            name
            materialTypeCode
            polynucleotideMaterialSequence {
              id
              name
              sequenceTypeCode
            }
          }
        }
      }
    }
  }
`;
