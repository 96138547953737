/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

export default function PriorityView(props) {
  if (!props.priority) return null;

  const {
    priority: { name, color }
  } = props;

  const colors = color ? color.split(" ") : "black white".split(" ");
  const backColor = colors[0];
  const foreColor = colors[1];

  const style = {
    color: foreColor,
    backgroundColor: backColor,
    paddingTop: "1px",
    paddingBottom: "1px",
    paddingLeft: "8px",
    paddingRight: "8px",
    display: "inline-block"
  };

  return <div style={style}>{name}</div>;
}
