/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
import React, { Component } from "react";
import { Button } from "@blueprintjs/core";
import { times } from "lodash";
import ReactMarkdown from "react-markdown";
import "./style.css";
import { Loading, wrapDialog } from "@teselagen/ui";

class ChangeLogDialog extends Component {
  state = {
    loading: false,
    chunkedData: [],
    chunksToShow: 3
  };

  async componentDidMount() {
    this.setState({
      loading: true
    });

    const response = await fetch("/CHANGELOG.md");
    const data = await response.text();
    const cleanedData = data.replace("# Change Log\n\n", "");
    const chunkedData = cleanedData
      .split("##")
      .filter(c => !!c)
      .map(c => `##${c}`);
    this.setState({
      chunkedData,
      loading: false
    });
  }

  render() {
    const { chunkedData = [], chunksToShow, loading } = this.state;

    return (
      <Loading bounce inDialog loading={loading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 600,
            maxHeight: 600,
            marginTop: 10,
            marginBottom: 10,
            overflow: "auto"
          }}
        >
          <h2 style={{ textAlign: "center" }}>Release Notes</h2>
          {times(chunksToShow, i => {
            return (
              <ReactMarkdown key={i} className="release-notes-markdown">
                {chunkedData[i]}
              </ReactMarkdown>
            );
          })}
          {chunksToShow < chunkedData.length && (
            <Button
              text="Load More"
              minimal
              intent="primary"
              style={{ margin: 15 }}
              onClick={() => this.setState({ chunksToShow: chunksToShow + 5 })}
            />
          )}
        </div>
      </Loading>
    );
  }
}

export default wrapDialog({
  icon: "clipboard",
  title: "Release Notes",
  style: {
    width: 650
  }
})(ChangeLogDialog);
