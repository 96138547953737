/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "./GenericSelect";
import pluralize from "pluralize";
import { CheckboxField, ReactSelectField } from "@teselagen/ui";
import stepFormValues from "./stepFormValues";

function SelectReactionMapEntities({
  fieldConstants,
  reactionMaps = [],
  modelNameToSchema,
  modelNameToFragment,
  modelNameToAdditionalFragment,
  change,
  onlyMaterials,
  getFieldName,
  extraFilter,
  ...rest
}) {
  const {
    [fieldConstants.reactionEntityType]: reactionEntityType,
    [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities
  } = rest;
  let select;
  if (!reactionMaps.length) return null;
  if (reactionEntityType && !selectAllReactionEntities) {
    const { additionalFilter, itemType } = getItemTypeAndFilterForReactionMaps({
      reactionEntityType,
      reactionMaps,
      extraFilter
    });
    select = (
      <GenericSelect
        {...{
          isRequired: true,
          name: getFieldName?.(itemType) || pluralize(itemType),
          isMultiSelect: true,
          schema: modelNameToSchema[itemType],
          fragment: modelNameToFragment[itemType],
          additionalDataFragment: modelNameToAdditionalFragment?.[itemType],
          postSelectDTProps: {
            formName: "reactionMapPlateMapEntitiesTable",
            schema: modelNameToSchema[itemType]
          },
          additionalFilter
        }}
      />
    );
  }
  return (
    <div>
      <div style={{ maxWidth: 250 }}>
        <ReactSelectField
          label="Reaction Entity Type"
          name={fieldConstants.reactionEntityType}
          onFieldSubmit={() => {
            change("materials", []);
            change("additiveMaterials", []);
          }}
          isRequired
          options={getReactionEntityTypeOptions({ onlyMaterials })}
        />
      </div>
      {reactionEntityType && (
        <CheckboxField
          name={fieldConstants.selectAllReactionEntities}
          label={`Select All ${reactionEntityType}`}
        />
      )}
      {select}
    </div>
  );
}

export function getItemTypeAndFilterForReactionMaps({
  reactionMaps,
  reactionEntityType,
  extraFilter
}) {
  let itemType;
  let additionalFilter;
  const reactionMapIds = reactionMaps.map(r => r.id);
  if (reactionEntityType.includes("Material")) {
    itemType = "material";
  } else {
    itemType = "additiveMaterial";
  }
  if (reactionEntityType.includes("Input")) {
    additionalFilter = {
      "reactionInputs.reaction.reactionMapId": reactionMapIds
    };
  } else if (reactionEntityType.includes("Conserved")) {
    additionalFilter = {
      "reactionInputs.reaction.reactionMapId": reactionMapIds,
      "reactionInputs.conserved": true
    };
  } else {
    additionalFilter = {
      "reactionOutputs.reaction.reactionMapId": reactionMapIds
    };
  }
  additionalFilter = {
    ...additionalFilter,
    ...extraFilter
  };
  return {
    additionalFilter,
    itemType
  };
}

export const getReactionEntityTypeOptions = ({ onlyMaterials } = {}) =>
  [
    "Input Materials",
    "Output Materials",
    "Input Reagents",
    "Output Reagents",
    "Conserved Reagents"
  ]
    .filter(op => {
      if (onlyMaterials) {
        return op.includes("Material");
      } else {
        return true;
      }
    })
    .map(type => {
      return {
        label: type,
        value: type
      };
    });

export default stepFormValues(({ fieldConstants }) => {
  return [
    fieldConstants.reactionEntityType,
    fieldConstants.selectAllReactionEntities
  ];
})(SelectReactionMapEntities);
