/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  copySampleFormulationsFromAliquot,
  isDiluent
} from "../../../utils/aliquotUtils";
import linkToAliquotContainer from "./linkToAliquotContainer";

/**
 * Get an aliquot that is not a diluent. If there are multiple non-diluents,
 * return an arbitrary one. If there are no non-diluents, return null.
 * @param {FullFormulation} fullFormulation
 * @returns {Aliquot | null}
 */
function getNonDiluentAliquot(fullFormulation) {
  const aliquots = fullFormulation.getAllAliquots();
  return aliquots.find(a => !isDiluent(a)) || null;
}

/**
 * Given a concentration change aliquot formulation to execute, add the required
 * upserts to a write buffer.
 *
 * This function assumes that the `fullFormulation` is a concentration change event. It does not
 * check this, so be careful.
 *
 * This function only deals with updates to the destination; it does not affect the source
 * aliquots.
 * @param {FullFormulation} fullFormulation Must be a concentration change event.
 * @param {Object} options
 * @param {WriteBuffer} writeBuffer
 * @returns {string} The id of the destination aliquot.
 */
function executeConcentrationChangeEvent(
  fullFormulation,
  options,
  writeBuffer
) {
  const { upsert } = writeBuffer;

  const nonDiluentAliquot = getNonDiluentAliquot(fullFormulation);
  if (!nonDiluentAliquot) {
    throw new Error(
      "TODO: Not currently handling the case where all aliquots in the formulation are diluents."
    );
  }

  let sampleId = nonDiluentAliquot.sample.id;
  if (nonDiluentAliquot.sample.sampleTypeCode === "FERMENTED_BATCH") {
    const [{ id }] = upsert("sample", {
      sampleTypeCode: "FORMULATED_SAMPLE",
      name: nonDiluentAliquot.sample.name + " (titer)",
      sampleFormulations: copySampleFormulationsFromAliquot(nonDiluentAliquot)
    });
    sampleId = id;
  }

  const originalAliquotId = fullFormulation.getAliquotId() || undefined;
  const dstAliquotValues = {
    id: originalAliquotId,
    // Do not (potentially) change the userId of existing aliquots
    ...(!originalAliquotId && { userId: options.userId }),
    sampleId,
    concentration: fullFormulation.getConcentration(),
    concentrationUnitCode: fullFormulation.getConcentrationUnitCode(),
    ...fullFormulation.getCellConcentrationFields(),
    volume: fullFormulation.getVolume(),
    volumetricUnitCode: fullFormulation.getVolumetricUnitCode(),
    molarity: fullFormulation.getMolarity(),
    molarityUnitCode: fullFormulation.getMolarityUnitCode(),
    isDry: fullFormulation.isDry()
  };

  const originalAliquot = fullFormulation.getAliquot();

  // If there is no original aliqout or if it is a diluent,
  // then this is a replication event. Pick an arbitrary non-diluent
  // aliquot to be the parent aliquot.
  if (!originalAliquot || isDiluent(originalAliquot)) {
    dstAliquotValues.aliquotType = "replicate-aliquot";
    dstAliquotValues.replicateParentAliquotId = nonDiluentAliquot.id;
  }

  const [{ id: aliquotId }] = upsert("aliquot", dstAliquotValues);

  // Add the destination aliquot to the aliquot container if one is provided.
  linkToAliquotContainer(fullFormulation, aliquotId, writeBuffer);

  return aliquotId;
}

export default executeConcentrationChangeEvent;
