/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { DataTable, Loading } from "@teselagen/ui";
import {
  Callout,
  Intent,
  Icon,
  Button,
  Collapse,
  MenuItem,
  IconSize,
  Switch
} from "@blueprintjs/core";
import { get, map, without, union, isEmpty } from "lodash";
import Promise from "bluebird";
import ReactionVerificationTable from "../../../ReactionVerificationTable";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import PlateMapPlate from "../../../PlateMapPlate";
import ReactionMap from "../../../../../../tg-iso-lims/src/ReactionMap";
import { worklistTableSchema } from "../../../Record/WorklistRecordView/worklistRecordTableSchema";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateEmptyWells } from "../../../../../../tg-iso-lims/src/utils/plateUtils";

// add widths to make it fit better in small space
const previewWorklistSchema = worklistTableSchema.fields.map(col => {
  if (["Source", "Destination", "Transfer Volume"].includes(col.displayName)) {
    return {
      ...col,
      width: 50
    };
  } else {
    return col;
  }
});

class ValidateWorklist extends Component {
  state = {
    selectedWorklistErrors: null,
    reactionSummaryIsActive: null,
    visiblePanels: {},
    loadingReactionMapInfo: {},
    platePage: {},
    ignoreWarnings: false
  };

  async componentDidMount() {
    this.stillMounted = true;
    const {
      worklists,
      stepFormProps: { change },
      reactionMaps = {},
      worklistErrorInfo
    } = this.props;
    if (worklists) {
      const reactionMapEntities = [];
      const worklistsToLoadReactionInfo = {};

      worklists.forEach(worklist => {
        const reactionMapsForWorklist =
          reactionMaps[`worklistId${worklist.id}`];
        if (
          !worklistErrorInfo[worklist.id].errors &&
          reactionMapsForWorklist?.length
        ) {
          worklistsToLoadReactionInfo[worklist.id] = true;
          reactionMapEntities.push(
            new ReactionMap(worklist, reactionMapsForWorklist)
          );
        }
      });
      this.setState({
        loadingReactionMapInfo: worklistsToLoadReactionInfo
      });
      const results = await Promise.map(
        reactionMapEntities,
        reactionMapEntity => {
          return reactionMapEntity.verifyWorklistReactions();
        }
      );
      change("reactionMapClasses", reactionMapEntities);
      const reactionMapResults = {};
      reactionMapEntities.forEach((entity, i) => {
        reactionMapResults[entity.worklist.id] = results[i];
      });
      if (this.stillMounted) {
        this.setState({
          reactionMapResults,
          loadingReactionMapInfo: {}
        });
      }
    }
  }

  componentWillUnmount() {
    this.stillMounted = false;
  }

  togglePanel = (worklistId, panelName) => {
    const { visiblePanels } = this.state;
    let newVisiblePanels;
    if (visiblePanels[worklistId] === panelName) {
      newVisiblePanels = {
        ...visiblePanels
      };
      delete newVisiblePanels[worklistId];
    } else {
      newVisiblePanels = {
        ...visiblePanels,
        [worklistId]: panelName
      };
    }
    this.setState({
      visiblePanels: newVisiblePanels
    });
  };

  toggleWorklistErrors = worklistId => this.togglePanel(worklistId, "error");
  toggleWorklistReaction = worklistId =>
    this.togglePanel(worklistId, "reaction");
  toggleIgnoreWarnings = () =>
    this.setState({ ignoreWarnings: !this.state.ignoreWarnings });

  markWellAsTransfered = (locations, activePlate, worklist) => {
    const {
      worklistToDestinationPlates,
      stepFormProps: { change },
      markedTransferLocations: oldMarkedTransferLocations
    } = this.props;
    const destPlatesInfo = worklistToDestinationPlates[worklist.id];
    const locationsToTransfer =
      destPlatesInfo.locationsToTransfer[activePlate.id];
    const markedTransferLocations = { ...oldMarkedTransferLocations };
    if (!markedTransferLocations[worklist.id]) {
      markedTransferLocations[worklist.id] = {
        [activePlate.id]: []
      };
    } else if (!markedTransferLocations[worklist.id][activePlate.id]) {
      markedTransferLocations[worklist.id][activePlate.id] = [];
    }
    const current = markedTransferLocations[worklist.id][activePlate.id];
    const filteredLocations = locations.filter(location =>
      locationsToTransfer.includes(location)
    );
    // all selected
    if (filteredLocations.every(location => current.includes(location))) {
      markedTransferLocations[worklist.id][activePlate.id] = without(
        current,
        ...filteredLocations
      );
    } else {
      markedTransferLocations[worklist.id][activePlate.id] = union(
        current,
        filteredLocations
      );
    }
    change("markedTransferLocations", markedTransferLocations);
  };

  render() {
    const {
      reactionMapResults = {},
      visiblePanels,
      loadingReactionMapInfo,
      platePage
    } = this.state;
    const {
      stepFormProps: { change },
      worklists,
      trackManualTransfers,
      worklistToDestinationPlates = {},
      markedTransferLocations = {},
      worklistErrorInfo,
      Footer,
      footerProps
    } = this.props;
    const valid = worklists.every(w => {
      return !worklistErrorInfo[w.id].errors;
    });
    let disableSubmit;
    const worklistValidationInner = map(worklists, worklist => {
      const { reactionVerificationTable, reactionSummaryStats } =
        reactionMapResults[worklist.id] || {};
      let destinationWellsWithRunningReaction;
      let providedReactionsRan;
      if (reactionSummaryStats) {
        destinationWellsWithRunningReaction = Math.round(
          (reactionSummaryStats.numberOfWellsWithRunningReaction /
            reactionSummaryStats.totalDestinationWells) *
            100
        );
        providedReactionsRan = Math.round(
          ((reactionSummaryStats.totalNumberOfReactions -
            reactionSummaryStats.reactionsNotRun.length) /
            reactionSummaryStats.totalNumberOfReactions) *
            100
        );
      }
      const reactionPanel = loadingReactionMapInfo[worklist.id] ? (
        <Loading inDialog />
      ) : (
        <ReactionVerificationTable
          {...{
            reactionVerificationTable,
            reactionSummaryStats,
            destinationWellsWithRunningReaction,
            providedReactionsRan
          }}
        />
      );
      const worklistErrors = worklistErrorInfo[worklist.id].errors;
      const worklistWarnings = worklistErrorInfo[worklist.id].warnings;

      const errorsPanel = worklistErrors && !!worklistErrors.length && (
        <Callout intent="danger" icon="info-sign">
          <h6>Worklist Errors</h6>
          {worklistErrors.map((error, i) => (
            <li key={i}>{error}</li>
          ))}
        </Callout>
      );

      const warningsPannel = worklistWarnings && !!worklistWarnings.length && (
        <Callout intent="warning" icon="info-sign">
          <h6>Worklist Warnings</h6>
          {worklistWarnings.map((warning, i) => (
            <li key={i}>{warning}</li>
          ))}
        </Callout>
      );
      disableSubmit =
        !valid ||
        !isEmpty(loadingReactionMapInfo) ||
        (worklistWarnings?.length && !this.state.ignoreWarnings);
      return (
        <div key={worklist.id} style={{ marginBottom: 20 }}>
          <div className="tg-flex" style={{ marginBottom: 15 }}>
            {worklist.name}
            <Icon
              icon={
                worklistErrors
                  ? "delete"
                  : worklistWarnings
                  ? "warning-sign"
                  : "tick-circle"
              }
              iconSize={IconSize.LARGE}
              style={{
                marginLeft: 20
              }}
              intent={
                worklistErrors
                  ? "danger"
                  : worklistWarnings
                  ? "warning"
                  : "success"
              }
            />
            <div style={{ flex: 1 }} />
            {((reactionVerificationTable && reactionSummaryStats) ||
              loadingReactionMapInfo[worklist.id]) && (
              <Button
                style={{ marginTop: -6, marginLeft: 5 }}
                intent={Intent.PRIMARY}
                loading={loadingReactionMapInfo[worklist.id]}
                onClick={() => this.toggleWorklistReaction(worklist.id)}
              >
                {visiblePanels[worklist.id] === "reaction"
                  ? "Hide Reaction Verification Table"
                  : "View Reaction Verification Table" +
                    (providedReactionsRan ? ` (${providedReactionsRan}%)` : "")}
              </Button>
            )}
            {(worklistErrors || worklistWarnings) && (
              <Button
                style={{ marginTop: -6, marginLeft: 5 }}
                intent={worklistErrors ? "danger" : "warning"}
                onClick={() => this.toggleWorklistErrors(worklist.id)}
              >
                {(visiblePanels[worklist.id] === "error" ? "Hide" : "Show") +
                  (worklistErrors ? " Errors" : " Warnings")}
              </Button>
            )}
          </div>
          <Collapse isOpen={visiblePanels[worklist.id]}>
            {visiblePanels[worklist.id] === "reaction"
              ? reactionPanel
              : visiblePanels[worklist.id] === "error"
              ? errorsPanel || warningsPannel
              : null}
          </Collapse>
          {worklistWarnings?.length && (
            <Switch
              style={{ marginTop: 16 }}
              checked={this.state.ignoreWarnings}
              onChange={this.toggleIgnoreWarnings}
              label="Ignore Warnings"
            />
          )}
        </div>
      );
    });
    let manualTransfers;

    if (trackManualTransfers) {
      let inner;
      if (!valid) {
        inner = <Callout intent="warning">Some worklists have errors.</Callout>;
      } else {
        inner = worklists.map((worklist, i) => {
          const destPlatesInfo = worklistToDestinationPlates[worklist.id];
          let plateSection;
          if (destPlatesInfo.plates.length) {
            const activePage = platePage[worklist.id] || 1;
            const activePlate = destPlatesInfo.plates[activePage - 1];
            const markedTransfers = get(
              markedTransferLocations,
              `${worklist.id}.${activePlate.id}`,
              []
            );
            let pagingSection;
            if (destPlatesInfo.plates.length > 1) {
              pagingSection = (
                <div
                  className="tg-flex align-center"
                  style={{ marginBottom: 10 }}
                >
                  <Button
                    icon="arrow-left"
                    minimal
                    disabled={activePage === 1}
                    onClick={() =>
                      this.setState({
                        platePage: {
                          ...platePage,
                          [worklist.id]: activePage - 1
                        }
                      })
                    }
                  />
                  Plate {platePage[worklist.id] || 1} of{" "}
                  {destPlatesInfo.plates.length}
                  <Button
                    icon="arrow-right"
                    minimal
                    disabled={activePage === destPlatesInfo.plates.length}
                    onClick={() =>
                      this.setState({
                        platePage: {
                          ...platePage,
                          [worklist.id]: activePage + 1
                        }
                      })
                    }
                  />
                </div>
              );
            }
            plateSection = (
              <div style={{ marginLeft: 15 }}>
                {pagingSection}
                <div style={{ marginBottom: 10 }}>{activePlate.name}</div>
                <PlateMapPlate
                  withDragSelect
                  onWellsSelected={locations => {
                    this.markWellAsTransfered(locations, activePlate, worklist);
                  }}
                  aliquotContainers={generateEmptyWells(
                    activePlate.containerArrayType.containerFormat
                  ).map(well => {
                    const location = getAliquotContainerLocation(well);
                    if (markedTransfers.includes(location)) {
                      return {
                        ...well,
                        color: "#0D8050"
                      };
                    } else if (
                      destPlatesInfo.locationsToTransfer[
                        activePlate.id
                      ].includes(location)
                    ) {
                      return {
                        ...well,
                        color: "#D9822B"
                      };
                    } else {
                      return well;
                    }
                  })}
                  containerArrayType={activePlate.containerArrayType}
                />
              </div>
            );
          } else {
            plateSection = <div>No plates found for worklist!</div>;
          }
          return (
            <div key={worklist.id}>
              <h6>{worklist.name}</h6>
              <div className="tg-flex">
                <div style={{ width: "50%" }}>
                  <DataTable
                    schema={previewWorklistSchema}
                    maxHeight={400}
                    formName={"executeWorklistPreview" + worklist.id}
                    entities={worklist.worklistTransfers}
                    isSimple
                    compact
                    withPaging
                  />
                </div>
                {plateSection}
              </div>
              {i !== worklists.length - 1 && (
                <hr className="tg-section-break" />
              )}
            </div>
          );
        });
      }

      manualTransfers = (
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Track Manual Transfers"
            helper="Click on plate wells to mark a transfer as complete as you go through your worklists."
            width="100%"
          />
          {inner}
        </div>
      );
    }
    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Worklist Validation"
            helper="Check below for any errors that may prevent a worklist from being executed successfully."
            width="100%"
            menuItems={
              !isEmpty(worklistToDestinationPlates) && [
                <MenuItem
                  key="trackManualTransfers"
                  icon={trackManualTransfers ? "tick" : ""}
                  shouldDismissPopover={false}
                  text="Track Manual Transfers?"
                  onClick={() => {
                    change("trackManualTransfers", !trackManualTransfers);
                  }}
                />
              ]
            }
          />
          {worklistValidationInner}
        </div>
        {manualTransfers}
        <Footer
          {...footerProps}
          nextText="Execute Worklist"
          disabled={disableSubmit}
        />
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    "worklists",
    "worklistErrorInfo",
    "reactionMaps",
    "trackManualTransfers",
    "worklistToDestinationPlates",
    "markedTransferLocations"
  )
)(ValidateWorklist);
