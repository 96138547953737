/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import FullFormulation from "../../FullFormulation";
import SimpleWriteBuffer from "../../../SimpleWriteBuffer";
import getIdToAliquotMap from "./getIdToAliquotMap";
import executeConcentrationChangeEvent from "./executeConcentrationChangeEvent";
import executeFormulationEvent from "./executeFormulationEvent";
import updateSourceAliquots from "./updateSourceAliquots";
import addDestinationAdditives from "./addDestinationAdditives";
import getIdToAliquotContainerMap from "./getIdToAliquotContainerMap";
import validateFormulations from "./validateFormulations";
import updateAliquot from "./updateAliquot";
import getSourceAliquotContainerIds from "./getSourceAliquotContainerIds";
import incrementWorklistCounterProperties from "./incrementWorklistCounterProperties";
import executeFermentedBatchEvent from "./executeFermentedBatchEvent";

/**
 * The order to upsert/delete models when flushing the write buffer.
 */
const modelOrder = [
  "sample",
  "aliquot",
  "additive",
  "materialComposition",
  "sampleFormulation",
  "aliquotContainer"
];

/**
 * Execute the given aliquot formulations when we are on the server.
 *
 * @param {Array<Object>} aliquotFormulations Serialized json of the aliquot formiula
 * @param {Object} options Any options for the execution.
 * @param {string} options.userId The id of the user performing the execution.
 * @param {Object} app The `app` object on the server.
 * @param {Object} req The`request` object.
 * @returns {Object}
 */
async function executeServerAliquotFormulations(
  aliquotFormulations,
  options,
  app,
  apolloMethods
) {
  const { safeUpsert } = apolloMethods;
  // Set of the ids of rehydrated aliquots.
  const rehydratedSet = {};

  // Map from the aliquot id to all of the information we need for that aliquot.
  const idToAliquot = await getIdToAliquotMap(
    aliquotFormulations,
    apolloMethods
  );
  const idToAliquotContainer = await getIdToAliquotContainerMap(
    aliquotFormulations,
    apolloMethods
  );

  const fullFormulations = aliquotFormulations.map(f =>
    FullFormulation.deserialize(f, idToAliquot, idToAliquotContainer)
  );

  // Make sure that the given formulations are valid.
  if (!validateFormulations(fullFormulations)) {
    throw new Error("The formulations are invalid.");
  }

  // We use a write buffer so that multiple upserts can get batched together.
  // This improves performance.
  const writeBuffer = new SimpleWriteBuffer();

  // Update/create the destination aliquots and aliquot containers.
  for (const f of fullFormulations) {
    // Make note of aliquots that we are rehydrating.
    if (f.isRehydrationEvent()) {
      rehydratedSet[f.getAliquotId()] = true;
    }

    // Update the destinations.
    let aliquotId = null,
      needsUpdate = true;
    const destAliquot = f.getAliquot();
    // only perform the events if there is an aliquot on the source aliquot container
    if (f.getAllSourceAliquots().length) {
      needsUpdate = false;
      if (
        destAliquot &&
        destAliquot.sample?.sampleTypeCode === "FERMENTED_BATCH"
      ) {
        aliquotId = executeFermentedBatchEvent(f, options, writeBuffer);
      } else if (f.isConcentrationChangeEvent()) {
        aliquotId = executeConcentrationChangeEvent(f, options, writeBuffer);
      } else {
        aliquotId = executeFormulationEvent(f, options, writeBuffer);
      }
    } else {
      aliquotId = f.getAliquotId();
    }

    // Add the additives to the destination material.
    addDestinationAdditives(f, aliquotId, options, writeBuffer);

    if (destAliquot && needsUpdate) {
      updateAliquot(f, writeBuffer);
    }
  }
  // Update the volumes of the source aliquots.
  updateSourceAliquots(fullFormulations, options, writeBuffer);

  const batchAliquotContainerUpdates = async aliquotContainerUpdates => {
    const normalUpdates = [];
    const stringOfUpdateValues = [];
    aliquotContainerUpdates.forEach(acUpdate => {
      if (acUpdate.aliquotId.indexOf("&") > -1) {
        stringOfUpdateValues.push(
          `('${acUpdate.id}', '${acUpdate.aliquotId.replace("&", "")}')`
        );
      } else {
        normalUpdates.push(acUpdate);
      }
    });
    if (stringOfUpdateValues.length) {
      if (!app.get("config").gqlServerDisabled) {
        await app.get(
          "gqlCustomEndpointHandler"
        )("batchAliquotContainerUpdates", { stringOfUpdateValues });
      } else {
        const sqlBatchUpdate = `WITH tmpa ("aliquotContainerId",cid) AS (
          VALUES ${stringOfUpdateValues.join(",")}
          )
          update "aliquotContainer" ac
          set "aliquotId" = a."id"
          from
              tmpa inner join aliquot a on a.cid = tmpa.cid
          where ac.id = tmpa."aliquotContainerId";`;
        await app.get("db").raw(sqlBatchUpdate);
      }
    }
    await safeUpsert("aliquotContainer", normalUpdates, {
      excludeResults: true
    });
  };

  const customHandlers = {
    aliquotContainer: {
      // because the aliquot container updates are as simple as setting the aliquot id
      // we can write a more efficient update. It was very slow when the aliquot ids
      // are cids.
      handleUpdate: batchAliquotContainerUpdates
    }
  };

  // now that we have the source aliquot container ids we can increment all worklistCounter extended properties on them (and their nested entities)
  const sourceAliquotContainerIds = await getSourceAliquotContainerIds(
    aliquotFormulations,
    apolloMethods
  );
  if (options.inWorklist) {
    await incrementWorklistCounterProperties(app, sourceAliquotContainerIds);
  }

  // Save the buffered updates to the server.
  await writeBuffer.flush(apolloMethods, modelOrder, customHandlers);
  // with latest oradm-to-gql
  //   upserting data: 24939.412ms
  //   upserting data: 24939.412ms
  // overall time: 28011.150ms

  return {
    success: true,
    rehydratedAliquotIds: Object.keys(rehydratedSet)
  };
}

export default executeServerAliquotFormulations;
