/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect } from "react";
import { Tooltip, Position, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import { map } from "lodash";
import { Rnd } from "react-rnd";
import { withRouter } from "react-router-dom";
import TooltipMessages from "../../../../src-shared/constants/tooltips";
import DesignInspectorPanelContainer from "../../../containers/DesignInspectorPanelContainer";
import ElementInspectorPanelContainer from "../../../containers/ElementInspectorPanelContainer";
import BinInspectorPanelContainer from "../../../containers/BinInspectorPanelContainer";
import ValidatedJunctionPanel from "./ValidatedJunctionPanel";
import CardInspectorPanel from "./CardInspectorPanel";
import CommentInspectorPanelContainer from "./CommentInspectorPanelContainer";
import ReactionPanel from "./ReactionPanel";
import ReportsPanel from "./ReportsPanel";
import ReviewInspectorPanelContainer from "./ReviewInspectorPanelContainer";
import "./style.css";

const resizingOptions = {
  top: false,
  right: false,
  bottom: false,
  left: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false
};

const DesignInspector = ({
  activePanel,
  readOnly,
  icons,
  panelArgs,
  open,
  toggleInspector,
  setActivePanel,
  locked,
  setWidth,
  width,
  history
}) => {
  const renderActivePanel = useCallback(() => {
    const panels = {
      info: DesignInspectorPanelContainer,
      reports: ReportsPanel,
      operation: ReactionPanel,
      set: BinInspectorPanelContainer,
      element: ElementInspectorPanelContainer,
      validation: ValidatedJunctionPanel,
      ...(!window.frontEndConfig.disabledToolkits.advancedMolecularBiology
        ? { card: CardInspectorPanel }
        : {}),
      comments: CommentInspectorPanelContainer,
      ...(!window.frontEndConfig.disabledToolkits.advancedMolecularBiology
        ? { reviews: ReviewInspectorPanelContainer }
        : {})
    };

    const Panel = panels[activePanel] || panels.info;

    return <Panel readOnly={readOnly} icons={icons} panelArgs={panelArgs} />;
  }, [activePanel, icons, panelArgs, readOnly]);

  useEffect(() => {
    if (window.location.href.includes("panel=")) {
      const urlParams = new URLSearchParams(window.location.search);
      const panel = urlParams.get("panel");

      const panelArgs = {};
      for (const entry of urlParams) {
        // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        panelArgs[key] = value;
      }
      history.replace({
        search: ""
      });
      // window.history.replaceState({}, document.title, window.location.pathname);
      setActivePanel({ panel, panelArgs });
      !open && toggleInspector();
    }
  }, [history, open, setActivePanel, toggleInspector]);

  const tabs = {
    info: "info-sign",
    reports: "document",
    operation: "git-merge",
    ...(!window.frontEndConfig.disabledToolkits.advancedMolecularBiology
      ? { card: "heat-grid" }
      : {}),
    set: "th-list",
    element: "th-derived",
    validation: "tick-circle",
    comments: "chat",
    ...(!window.frontEndConfig.disabledToolkits.advancedMolecularBiology
      ? { reviews: "flow-review-branch" }
      : {})
  };

  if (!tabs[activePanel])
    console.error(
      `The inspector tab "${activePanel}" is not valid. Rendering default panel.`
    );

  return (
    <>
      <Rnd
        className={classNames("design-inspector-panel", {
          "inspector-closed": !open
        })}
        default={{ width }}
        minWidth={300}
        disableDragging
        enableResizing={resizingOptions}
        style={{
          display: "flex",
          height: "100%",
          zIndex: 1
        }}
        onResize={(e, direction, ref) => setWidth(ref.offsetWidth)}
      >
        <div className="design-inspector">{open && renderActivePanel()}</div>
      </Rnd>
      <div className="right-viewer-tab-bar">
        {map(tabs, (iconName, tabName) => {
          const activeAndOpen = activePanel === tabName && open;

          return (
            <Tooltip
              key={tabName}
              disabled={!!window.Cypress}
              content={<span>{TooltipMessages[tabName]}</span>}
              position={Position.LEFT}
            >
              <Icon
                icon={iconName}
                data-test={`design-inspector-tab-${tabName}`}
                className={classNames("tab-icon", {
                  open: activeAndOpen
                })}
                iconSize={20}
                onClick={() => {
                  if (locked) {
                    window.toastr.warning(
                      "Design Inspector is locked shut, click the lock icon to unlock it"
                    );
                  }
                  if (activeAndOpen) {
                    toggleInspector();
                  } else {
                    setActivePanel({ panel: tabName });
                    !open && toggleInspector();
                  }
                }}
              ></Icon>
            </Tooltip>
          );
        })}
      </div>
    </>
  );
};

export default withRouter(DesignInspector);
