/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

// import {
//   safeDelete,
//   safeQuery,
//   safeUpsert
// } from "../../../../src-shared/apolloMethods";
import { Button, Divider } from "@blueprintjs/core";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { AdvancedOptions, InfoHelper } from "@teselagen/ui";
import { statusToIconMap } from "../../../../../tg-iso-design/constants/designStateConstants";
import { useTgQuery } from "../../../../src-shared/apolloMethods";
import Tag from "../../../../src-shared/Tag";
import { wrapDialog } from "@teselagen/ui";

dayjs.extend(relativeTime);

// import "./style.css";

function DesignReviewStatusDialog({
  id,
  hideModal,
  history,
  showGoToDesign,
  selectedSubmissionId
}) {
  const { design, ...rest } = useTgQuery(
    [
      "design",
      `
      id
      submissions {
        id
        updatedAt
        user {
          id
          username
        }
        stage {
          id
          name
          description
          howManyApprovers
          color
        }
        submissionStateCode
        submissionStageApprovers {
          id
          updatedAt
          submissionStageApproverStatusCode
          stageApprover {
            id
            user {
              id
              username
            }
            userId
          }
        }
    }`
    ],
    {
      variables: {
        id
      }
    }
  );
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  const { submissions } = design;
  const selectedSubmission = submissions.filter(
    s => s.id === selectedSubmissionId
  );
  const otherSubmissions = submissions.filter(
    s => s.id !== selectedSubmissionId
  );
  const renderSubmission = (s, i) => {
    const statusObj = statusToIconMap[s.submissionStateCode];
    return (
      <div key={i}>
        {i > 0 && (
          <React.Fragment>
            <br></br> <Divider></Divider>
          </React.Fragment>
        )}
        <br></br>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ paddingLeft: 20 }}>
            <InfoHelper
              className="tg-submissionStatusIcon"
              isInline
              {...statusObj}
              content={
                <div>
                  {statusObj.content}
                  <div style={{ color: "grey", fontSize: 11 }}>
                    {dayjs(s.updatedAt).fromNow()}
                  </div>
                </div>
              }
            ></InfoHelper>{" "}
            &nbsp;
            <span
              style={{
                color: statusObj.color
              }}
            >
              {statusObj.content}
            </span>
            <div style={{ marginTop: 8, marginBottom: 5 }}>
              <Tag {...s.stage}></Tag>
            </div>
            <div style={{ fontSize: 10, color: "gray" }}>
              Submitted by {s.user.username}
            </div>
            <div style={{ fontSize: 10, color: "gray" }}>
              {" "}
              {s.stage.howManyApprovers} approval
              {s.stage.howManyApprovers > 1 || s.stage.howManyApprovers === 0
                ? "s"
                : ""}{" "}
              required{" "}
            </div>
          </div>
          <div style={{ paddingRight: 20 }}>
            {" "}
            <div
              style={{
                fontStyle: "italic",
                fontSize: 10,
                textAlign: "center",
                marginBottom: 8
              }}
            >
              Reviewers
            </div>
            {s.submissionStageApprovers.map(
              (
                { stageApprover, submissionStageApproverStatusCode, updatedAt },
                i
              ) => {
                const statusToUse =
                  s.submissionStateCode === "CANCELLED"
                    ? statusObj
                    : statusToIconMap[submissionStageApproverStatusCode];
                return (
                  <div key={i}>
                    <InfoHelper
                      isInline
                      {...statusToUse}
                      content={
                        <div>
                          {statusToUse.content}
                          <div style={{ color: "grey", fontSize: 11 }}>
                            {dayjs(updatedAt).fromNow()}
                          </div>
                        </div>
                      }
                    ></InfoHelper>{" "}
                    &nbsp;&nbsp;{stageApprover.user.username}
                  </div>
                );
              }
            )}
          </div>
        </div>
        <br />
        {showGoToDesign && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Button
              onClick={() => {
                history.push(`/designs/${id}?panel=reviews`);
                hideModal();
              }}
              intent="primary"
              small
              minimal
            >
              View Review Details...{" "}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <form className="user-form">
      <div className="bp3-dialog-body">
        {selectedSubmission.map(renderSubmission)}
        {!!otherSubmissions.length && (
          <AdvancedOptions
            content={otherSubmissions.map(renderSubmission)}
            label="Show other submissions"
          ></AdvancedOptions>
        )}
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button text="Cancel" onClick={hideModal} />
          {/* <Button intent={Intent.PRIMARY} type="submit" text="OK" /> */}
        </div>
      </div>
    </form>
  );
}

// Decorate the form component
export default wrapDialog({
  title: "Design Review Status"
})(DesignReviewStatusDialog);
