/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

const actions = {
  openNotifications: payload => {
    return {
      type: "NOTIFICATIONS_OPEN",
      payload
    };
  },
  updateUnreadCount: payload => {
    return {
      type: "NOTIFICATIONS_UPDATE_UNREAD_COUNT",
      payload
    };
  },
  updateActiveCount: payload => {
    return {
      type: "NOTIFICATIONS_UPDATE_ACTIVE_COUNT",
      payload
    };
  },
  closeNotifications: payload => {
    return {
      type: "NOTIFICATIONS_CLOSE",
      payload
    };
  },
  addNotification: payload => {
    return {
      type: "NOTIFICATIONS_ADD_NOTIFICATION",
      payload
    };
  },
  updateNotification: payload => {
    return {
      type: "NOTIFICATIONS_UPDATE_NOTIFICATION",
      payload
    };
  },
  tickTimers: payload => {
    return {
      type: "NOTIFICATIONS_TICK_TIMERS",
      payload
    };
  },
  setAllTicks: payload => {
    return {
      type: "NOTIFICATIONS_SET_ALL_TICKS",
      payload
    };
  },
  clear: payload => {
    return {
      type: "NOTIFICATIONS_CLEAR",
      payload
    };
  },
  NOTIFICATIONS_TOGGLE: payload => {
    return {
      type: "NOTIFICATIONS_TOGGLE",
      payload
    };
  }
};

export default actions;
