/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { SelectField } from "@teselagen/ui";
import { tgFormValues } from "@teselagen/ui";
import { getPlateAliquotType } from "../../utils/plateUtils";

import UnitInputField from "../UnitInputField";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";

export function HydrateFormCore() {
  const usableVolumetricUnits = Object.values(
    unitGlobals.volumetricUnits
  ).filter(({ code }) => code === "uL" || code === "mL");
  return (
    <React.Fragment>
      <UnitInputField
        isRequired
        label="Target Volume"
        name="volume"
        unitName="volumetricUnitCode"
        unitType="volumetricUnit"
        filteredUnits={usableVolumetricUnits}
        unitDefault="uL"
      />
      <SelectField
        label="Concentration Unit"
        name="concentrationUnitCode"
        defaultValue="ng/uL"
        options={unitGlobals.getOptionsForSelect("concentrationUnit")}
      />
      <SelectField
        label="Molarity Unit"
        name="molarityUnitCode"
        defaultValue="nM"
        options={unitGlobals.getOptionsForSelect("molarityUnit")}
      />
    </React.Fragment>
  );
}

class HydrateForm extends Component {
  renderHydrateForm() {
    const { hydrate } = this.props;
    if (hydrate === "Rehydrate") {
      return <HydrateFormCore />;
    }
  }

  render() {
    const { aliquotContainers } = this.props;
    const options = ["None"];
    let plateType;
    if (aliquotContainers) {
      plateType = getPlateAliquotType(aliquotContainers);
      if (plateType === "mixed" || !plateType) {
        options.push("Rehydrate", "Dehydrate");
      } else if (plateType === "dry") {
        options.push("Rehydrate");
      } else {
        options.push("Dehydrate");
      }
    }

    return (
      <div>
        <SelectField options={options} label="Plate Hydration" name="hydrate" />
        {this.renderHydrateForm()}
      </div>
    );
  }
}

export default tgFormValues("hydrate")(HydrateForm);
