/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, KeyCombo, Tooltip } from "@blueprintjs/core";
import React from "react";

function ButtonWithHotkey({ tooltip, hotkey, ...rest }) {
  return (
    <Tooltip
      content={
        <div>
          <div style={{ marginBottom: 4 }}>{tooltip}</div>
          <KeyCombo combo={hotkey} />
        </div>
      }
      position="bottom-right"
    >
      <Button {...rest} />
    </Tooltip>
  );
}

export default ButtonWithHotkey;
