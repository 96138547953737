/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem, Tooltip } from "@blueprintjs/core";
import { compose } from "recompose";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import {
  beforeRackDelete,
  plateLibraryFilter
} from "../../../utils/plateUtils";

import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import containerArrayLibraryFragment from "../../../graphql/fragments/containerArrayLibraryFragment";
import { withUnitGeneric } from "../../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import UploadEmptyPlates from "../../UploadEmptyPlates";
import MoveToEquipmentMenuItem from "../../MoveToEquipmentMenuItem";
import ClearLocationMenuItem from "../../ClearLocationMenuItem";
import PlateRecordCreate from "../../PlateRecordCreate";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import ContainerArrayRecordView from "../../Record/ContainerArrayRecordView";
import PrintLabelMenuItem from "../../PrintLabelMenuItem";
import ExportPlatesOrTubesDialog from "../../Dialogs/ExportPlatesOrTubesDialog";
import UploadPlateConcentration from "../../UploadPlateConcentration";
import UploadRackTubeBarcodes from "../../UploadRackTubeBarcodes";
import UploadEmptyTubeRack from "../../UploadEmptyTubeRack";

export const MIXED_PLATE_MESSAGE = "Please select items of the same type.";

// (async () => {
//   const { default: ModalComponent } = await import(
//     "../../Dialogs/PrintLabelDialog"
//   );
//   showDialog({
//     ModalComponent,
//     modalProps: {
//       itemIds: [`5bced42f-a38e-481a-a3ed-77eeccce83bf`],
//       isContainerArray: true
//     }
//   });
// })()

const handlePlateExport = plates => {
  showDialog({
    ModalComponent: ExportPlatesOrTubesDialog,
    modalProps: {
      plateIds: plates.map(p => p.id)
    }
  });
};

const onDoubleClick = (row, rowIndex, history) => {
  history.push(`/plates/${row.id}`);
};

const beforeDelete = async records => {
  await beforeRackDelete(records);
};

const ContainerArraysInventory = props => {
  const { selectTableRecords, libraryFragment, refetch } = props;

  const editPlate = record => {
    showDialog({
      modalType: "UPDATE_PLATE",
      modalProps: {
        plateId: record.id,
        refetch: async () => {
          await safeQuery(libraryFragment, {
            variables: {
              filter: {
                id: record.id
              }
            }
          });
          selectTableRecords([record.id]);
        }
      }
    });
  };

  const contextMenu = selectedRecords => {
    let editMenuItem;

    if (selectedRecords.length === 1) {
      editMenuItem = (
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() => editPlate(selectedRecords[0])}
          text="Edit Plate"
        />
      );
    }

    const hasRack = selectedRecords.some(p => !p.containerArrayType.isPlate);
    const containerFormatCode =
      selectedRecords[0].containerArrayType.containerFormatCode;
    const mixedFormats = selectedRecords.some(
      p => p.containerArrayType.containerFormatCode !== containerFormatCode
    );
    const canChangeType = !hasRack && !mixedFormats;

    const changePlateTypeMenuItem = (
      <Tooltip
        key="changePlateType"
        className="full-width-tooltip"
        disabled={canChangeType}
        content={
          hasRack ? "Cannot change plate type of a rack." : MIXED_PLATE_MESSAGE
        }
      >
        <MenuItem
          icon="exchange"
          text="Change Plate Type"
          disabled={!canChangeType}
          onClick={() => {
            showDialog({
              modalType: "CHANGE_PLATE_TYPE",
              modalProps: {
                containerArrays: selectedRecords,
                containerFormatCode
              }
            });
          }}
        />
      </Tooltip>
    );

    // const stampMenuItem = (
    //   <HandleStampMenuItem
    //     key="stampPlate"
    //     {...{ history, record: selectedRecords[0], show }}
    //   />
    // );

    const exportPlatesMenuItem = (
      <MenuItem
        key="export"
        icon="export"
        text="Export"
        onClick={() => handlePlateExport(selectedRecords)}
      />
    );
    const printMenuItem = (
      <PrintLabelMenuItem key="printLabel" records={selectedRecords} />
    );

    const moveMenuItem = (
      <MoveToEquipmentMenuItem
        key="moveToLocation"
        refetch={refetch}
        records={selectedRecords}
      />
    );

    const clearLocationMenuItem = selectedRecords.some(
      r => r.assignedPosition
    ) ? (
      <ClearLocationMenuItem key="clearLocation" records={selectedRecords} />
    ) : undefined;

    return [
      editMenuItem,
      changePlateTypeMenuItem,
      moveMenuItem,
      clearLocationMenuItem,
      // stampMenuItem,
      exportPlatesMenuItem,
      printMenuItem
    ];
  };

  const onNewItemClick = () => {
    showDialog({
      ModalComponent: PlateRecordCreate,
      modalProps: {
        refetch: refetch
      }
    });
  };

  const showUploadPlateDialog =
    (modalTypeOrComp, options = {}) =>
    () => {
      const { isRNAUpload } = options;
      let modalType, ModalComponent;
      if (typeof modalTypeOrComp === "string") {
        modalType = modalTypeOrComp;
      } else {
        ModalComponent = modalTypeOrComp;
      }
      showDialog({
        modalType,
        ModalComponent,
        modalProps: {
          refetch,
          isRNAUpload
        }
      });
    };

  const uploadMenuItems = [
    <MenuItem
      key="generic"
      text="DNA Plate"
      onClick={showUploadPlateDialog("UploadDnaPlateDialog")}
    />,
    <MenuItem
      key="twist"
      text="Twist CSV"
      onClick={showUploadPlateDialog("UPLOAD_TWIST_PLATE")}
    />,
    <MenuItem
      key="idt"
      text="IDT CSV"
      onClick={showUploadPlateDialog("UPLOAD_IDT_PLATE")}
    />,
    <MenuItem
      key="microbial"
      text="Microbial Plate CSV"
      onClick={showUploadPlateDialog("UPLOAD_MICROBIAL_MATERIAL_PLATE")}
    />,
    <MenuItem
      key="rna"
      text="RNA Plate CSV"
      onClick={showUploadPlateDialog("UploadDnaPlateDialog", {
        isRNAUpload: true
      })}
    />,
    <MenuItem
      key="cellCulture"
      text="Cell Culture Plate CSV"
      onClick={showUploadPlateDialog("UploadCellCulturePlateDialog")}
    />,
    <MenuItem
      key="reagent"
      text="Reagent Plate CSV"
      onClick={showUploadPlateDialog("UPLOAD_REAGENT_PLATE")}
    />,
    <MenuItem
      key="protein"
      text="Protein Plate CSV"
      onClick={showUploadPlateDialog("UPLOAD_PROTEIN_PLATE")}
    />,
    <MenuItem
      key="emptyPlates"
      text="Empty Plates"
      onClick={showUploadPlateDialog(UploadEmptyPlates)}
    />,
    <MenuItem
      key="emptyTubeRacks"
      text="Empty Tube Racks"
      onClick={showUploadPlateDialog(UploadEmptyTubeRack)}
    />,
    <MenuItem
      key="concentrationInfo"
      text="Plate Concentrations"
      onClick={() =>
        showDialog({
          ModalComponent: UploadPlateConcentration
        })
      }
    />,
    <MenuItem
      key="tubeBarcodes"
      text="Tube Barcodes"
      onClick={() =>
        showDialog({
          ModalComponent: UploadRackTubeBarcodes,
          modalProps: {
            libraryFragment
          }
        })
      }
    />
  ];

  return (
    <AbstractLibrary
      {...props}
      beforeDelete={beforeDelete}
      uploadMenuItems={uploadMenuItems}
      isLibraryTable
      RecordViewInspector={ContainerArrayRecordView}
      withExport
      extraTableParams={{
        cellRenderer: {
          "containerArrayType.aliquotContainerType.maxVolume": withUnitGeneric(
            "containerArrayType.aliquotContainerType.maxVolume",
            "containerArrayType.aliquotContainerType.volumetricUnitCode"
          )
        }
      }}
      onDoubleClick={onDoubleClick}
      onNewItemClick={onNewItemClick}
      generateExtraContextMenuItems={contextMenu}
      libraryName="plate"
    />
  );
};

const schema = {
  model: "containerArrayPathView",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "containerArrayType.name",
      type: "string",
      displayName: "Type"
    },
    {
      path: "containerArrayType.aliquotContainerType.maxVolume",
      type: "number",
      displayName: "Max Well Volume"
    },
    {
      path: "fullPath",
      width: 300,
      type: "string",
      displayName: "Location"
    }
  ]
};

export default compose(
  libraryEnhancer({
    schema,
    updateableModel: "containerArray",
    fragment: containerArrayLibraryFragment,
    additionalFilter: plateLibraryFilter
  })
)(ContainerArraysInventory);
