/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import {
  DialogFooter,
  DataTable,
  withSelectedEntities,
  wrapDialog
} from "@teselagen/ui";

import {
  massRender,
  concentrationRender,
  withUnitGeneric
} from "../../../src-shared/utils/unitUtils";
import { getAliquotContainerLocation } from "../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { aliquotVolumeDeadWarningRender } from "../AliquotVolumeLessThanDeadWarning";
import modelNameToReadableName from "../../../../tg-iso-shared/src/utils/modelNameToReadableName";

function SelectAliquotOptionForInventoryCheck(props) {
  const {
    hideModal,
    options = [],
    selectAliquotOptionsSelectedEntities = [],
    onSelect,
    targetType
  } = props;

  const additiveBasePath = "aliquotContainer.additives[0]";
  const schema =
    targetType === "material"
      ? [
          {
            displayName: "Sample",
            path: "sample.name"
          },
          {
            displayName: "Plate",
            path: "aliquotContainer.containerArray.name"
          },
          {
            displayName: "Tube Barcode",
            path: "aliquotContainer.barcode.barcodeString"
          },
          {
            width: 100,
            displayName: "Well Location",
            path: "aliquotContainer",
            render: getAliquotContainerLocation
          },
          {
            width: 80,
            displayName: "Volume",
            path: "volume",
            render: aliquotVolumeDeadWarningRender
          },
          {
            width: 100,
            displayName: "Concentration",
            path: "concentration",
            render: concentrationRender
          },
          {
            width: 80,
            displayName: "Mass",
            path: "mass",
            render: massRender
          }
        ]
      : [
          {
            displayName: "Plate",
            path: "aliquotContainer.containerArray.name"
          },
          {
            displayName: "Tube Barcode",
            path: "aliquotContainer.barcode.barcodeString"
          },
          {
            width: 100,
            displayName: "Well Location",
            path: "aliquotContainer",
            render: getAliquotContainerLocation
          },
          {
            width: 80,
            displayName: "Volume",
            path: "aliquotContainer.additives[0].volume",
            render: withUnitGeneric(
              `${additiveBasePath}.volume`,
              `${additiveBasePath}.volumetricUnitCode`
            )
          },
          {
            width: 100,
            displayName: "Concentration",
            path: "aliquotContainer.additives[0].concentration",
            render: withUnitGeneric(
              `${additiveBasePath}.concentration`,
              `${additiveBasePath}.concentrationUnitCode`
            )
          },
          {
            width: 80,
            displayName: "Mass",
            path: "aliquotContainer.additives[0].mass",
            render: withUnitGeneric(
              `${additiveBasePath}.mass`,
              `${additiveBasePath}.massUnitCode`
            )
          }
        ];

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <DataTable
          formName="selectAliquotOptions"
          isSimple
          onDoubleClick={r => {
            hideModal();
            onSelect(r);
          }}
          schema={schema}
          entities={options}
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        disabled={!selectAliquotOptionsSelectedEntities.length}
        onClick={() => {
          hideModal();
          onSelect(selectAliquotOptionsSelectedEntities[0]);
        }}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    getDialogProps: ({ targetType }) => {
      return {
        title: `Select ${modelNameToReadableName(targetType, {
          upperCase: true
        })}`
      };
    },
    style: {
      width: 880
    }
  }),
  withSelectedEntities("selectAliquotOptions")
)(SelectAliquotOptionForInventoryCheck);
