/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import concatWarningStrs from "../../../utils/concatWarningStrs";
import { isoContext } from "@teselagen/utils";

import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, upsertAddIds, validateUnits } from "./utils";

export default async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  const filteredRecords = recordsToImport.filter(r => {
    if (r.microbialMaterial && r.dnaMaterial) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Do not provide both a DNA and microbial material."
      );
    } else if (!r.microbialMaterial && !r.dnaMaterial) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Provide a DNA or microbial material."
      );
    } else if (!r.volume && !r.mass) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Provide a volume or mass."
      );
    }

    if (r.id) {
      if (r.__oldRecord) {
        if (
          get(r.__oldRecord, "dnaMaterial.id") !== get(r, "dnaMaterial.id") ||
          get(r.__oldRecord, "microbialMaterial.id") !==
            get(r, "microbialMaterial.id")
        ) {
          r.__importFailed = concatWarningStrs(
            r.__importFailed,
            "Cannot change material on an existing aliquot."
          );
        }
      }
    }

    if (r.__importFailed) return false;

    const unitError = validateUnits(r);
    if (unitError) {
      r.__importFailed = concatWarningStrs(r.__importFailed, unitError);
    }

    return !r.__importFailed;
  });

  let recordsToContinueUpserting = await handleNestedRecords(
    filteredRecords,
    "dnaMaterial",
    async dnaMaterials => {
      await upsertHandlers.DNA_MATERIAL(
        {
          ...rest,
          model: "material",
          recordsToImport: dnaMaterials,
          upsertHandlers
        },
        ctx
      );
    }
  );
  recordsToContinueUpserting = await handleNestedRecords(
    recordsToContinueUpserting,
    "microbialMaterial",
    async microbialMaterials => {
      await upsertHandlers.MICROBIAL_MATERIAL(
        {
          ...rest,
          model: "material",
          recordsToImport: microbialMaterials,
          upsertHandlers
        },
        ctx
      );
    }
  );

  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      convertUserFacingToDbModel: r => {
        if (!r.id) {
          const material = r.dnaMaterial || r.microbialMaterial;
          r.sample = {
            sampleTypeCode: "REGISTERED_SAMPLE",
            name: material.name,
            materialId: material.id
          };
        }

        r.aliquotType = "registered-aliquot";
        if (r.mass && !r.volume) {
          r.isDry = true;
        }

        delete r.dnaMaterial;
        delete r.microbialMaterial;

        return r;
      },
      model: "aliquot"
    },
    ctx
  );

  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport,
      modelOrFragment: "aliquot"
    },
    ctx
  );
}
