/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as d3 from "d3";
import modelSigmaHistogramWidgetStyles from "../ModelSigmaHistogramWidgetStyles.module.css";
import widgetCardStyles from "../../../WidgetCardStyles.module.css";
import d3Tip from "d3-tip";
import { range } from "lodash";

const X_AXIS_LABEL = "Similarity with training set (Max Normalized BitScore)";
const Y_AXIS_LABEL = "Number of sequences";

const DATAPOINTS_KEY = "normalized_max_bit_score_histogram";
const STEP = 0.1;
const X_AXIS_RANGE = [0, 1];
const TRANSITION_DURATION = 1500;
const INTERPOLATION_START_COLOR = "rgb(12, 82, 125)";
const INTERPOLATION_END_COLOR = "rgb(20, 132, 201)";

function svgSelection(htmlComponentClass, width, height) {
  return d3
    .select(`.${htmlComponentClass}`)
    .append("svg")
    .attr("height", height)
    .attr("width", width);
}

function generateXAxisLabels(start, end, step) {
  return [
    ...range(start * 10, end * 10, step * 10).map(value => value / 10),
    1
  ];
}

function getCenterFromDatapoint(datapoint) {
  return (datapoint.lower_edge + datapoint.upper_edge) / 2;
}

function drawSigmaHistogram(htmlComponentClass, height, width, data) {
  d3.selectAll(`.${htmlComponentClass} *`).remove();

  const margin = 20;
  const datapoints = data[DATAPOINTS_KEY];
  const xAxisLabels = generateXAxisLabels(
    X_AXIS_RANGE[0],
    X_AXIS_RANGE[1],
    STEP
  );
  const maxY = d3.max(datapoints, d => d.frequency);

  const tip = d3Tip()
    .attr("class", modelSigmaHistogramWidgetStyles.tip)
    .offset([-10, 0])
    .html(d => {
      return `<strong>Frequency:</strong> <span style='color:#3dcc91'>
        ${d.frequency}
        </span><br/><br/>
        <strong>Lower Bound:</strong> <span style='color:#3dcc91'>
        ${Math.round(d.lower_edge * 1000) / 1000}
        </span><br/><br/>
        <strong>Upper Bound:</strong> <span style='color:#3dcc91'>
        ${Math.round(d.upper_edge * 1000) / 1000}
        </span>`;
    });
  const svg = svgSelection(htmlComponentClass, width, height);

  const xScale = d3
    .scaleLinear()
    .domain(X_AXIS_RANGE)
    .rangeRound([2.5 * margin, width - 2.5 * margin]);

  const yScale = d3
    .scaleLinear()
    .domain([0, maxY * 1.05])
    .nice()
    .rangeRound([height - 2 * margin, margin]);

  const colorFillScale = d3
    .scaleSequential()
    .domain(X_AXIS_RANGE)
    .interpolator(
      d3.interpolateRgb(INTERPOLATION_START_COLOR, INTERPOLATION_END_COLOR)
    );

  const xAxis = g =>
    g
      .attr("transform", `translate(${0 * margin},${height - 2 * margin})`)
      .attr("class", widgetCardStyles.axis)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(xAxisLabels.length)
          .tickValues(xAxisLabels)
      );

  const yAxis = g =>
    g
      .attr("transform", `translate(${2.5 * margin},${0})`)
      .data(datapoints)
      .call(
        d3
          .axisLeft(yScale)
          .tickValues(yScale.ticks().filter(tick => Number.isInteger(tick)))
          .tickFormat(d3.format("d"))
      )
      .attr("class", widgetCardStyles.axis)
      .call(g =>
        g
          .select(".tick:last-of-type text")
          .clone()
          .attr("x", 3)
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
      );

  const barWidth =
    xScale(datapoints[0].upper_edge) - xScale(datapoints[0].lower_edge);

  svg.append("g").call(xAxis);
  svg
    .append("text")
    .attr("transform", "translate(" + width / 2 + " ," + (height - 3) + ")")
    .attr("class", widgetCardStyles.label)
    .style("text-anchor", "middle")
    .text(X_AXIS_LABEL);
  svg.append("g").call(yAxis);
  svg
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("class", widgetCardStyles.label)
    .attr("y", 5)
    .attr("x", 0 - height / 2)
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .text(Y_AXIS_LABEL);
  svg.call(tip);

  const t = svg.transition().duration(TRANSITION_DURATION);

  svg
    .append("g")
    .selectAll("g")
    .data(datapoints)
    .join(enter =>
      enter
        .append("rect")
        .attr("class", modelSigmaHistogramWidgetStyles.rect)
        .attr("x", d => xScale(getCenterFromDatapoint(d)))
        .attr("y", yScale(0))
        .attr("width", barWidth)
        .attr("height", 0)
        .attr("fill", d => colorFillScale(getCenterFromDatapoint(d)))
        .on("mouseover", tip.show)
        .on("mouseout", tip.hide)
        .call(enter =>
          enter
            .transition(t)
            .attr("height", d => yScale(0) - yScale(d.frequency))
            .attr("y", d => yScale(d.frequency))
        )
    );
}

export { drawSigmaHistogram };
