/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  DialogFooter,
  InputField,
  TextareaField,
  wrapDialog
} from "@teselagen/ui";
import { normalizeBasePairs } from "../../../../src-shared/utils/normalizeValues";
import { compose } from "redux";
import uploadDnaSequences from "../../../../../tg-iso-shared/src/sequence-import-utils/uploadDnaSequences";
import { connect } from "react-redux";
import actions from "../../../../src-shared/redux/actions";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import {
  getSelectedCardId,
  getSelectedCellPaths
} from "../../../../src-shared/selectors/designViewSelectors";
import { designPartFragment } from "../../../../../tg-iso-design/graphql/fragments/designLoadFragment/designAccessoryFragments";
import { showDialog } from "../../../../src-shared/GlobalDialog";

class InsertBasePairLiteralDialog extends React.Component {
  onSubmit = async ({ name, bps }) => {
    try {
      const { hideModal, createElements, cellIndex, cardId, binId } =
        this.props;

      // create a new sequence (deduplicate it first!) and a new part (also deduplicated) with the base pair literal
      const { allSeqIds } = await uploadDnaSequences({
        allowDuplicates: false,
        sequenceJsons: [
          {
            name: name,
            sequence: bps,
            parts: [
              {
                name: name,
                start: 0,
                end: bps.length,
                strand: 1
              }
            ]
          }
        ]
      });
      const [part] = await safeQuery(designPartFragment, {
        variables: {
          filter: {
            sequenceId: allSeqIds[0],
            name: name,
            start: 0,
            end: bps.length - 1,
            strand: 1
          }
        }
      });

      // then insert the new part into the cell
      createElements({
        values: {
          part
        },
        binId: binId,
        cellIndex,
        cardId
      });
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error inserting base-pair literal.");
    }
  };

  parseBasePairs = bpText => {
    let parsedBp = "";
    if (bpText !== undefined) {
      parsedBp = normalizeBasePairs(bpText);
    }
    return parsedBp;
  };

  render() {
    const { hideModal, handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField isRequired autoFocus name="name" label="Name" />
          <TextareaField
            isRequired
            className="tg-allow-dialog-form-enter"
            name="bps"
            label="Base pairs"
            normalize={this.parseBasePairs}
          />
        </div>
        <DialogFooter hideModal={hideModal} text="OK" loading={submitting} />
      </form>
    );
  }
}

export const showInsertBasePairLiteralDialog = () =>
  showDialog({
    ModalComponent: compose(
      wrapDialog({
        title: "Insert Base Pairs",
        style: {
          width: 550
        }
      }),
      reduxForm({
        form: "InsertBasePairLiteralDialogForm"
      }),
      connect(
        state => {
          // grab the selected cell paths, card ID, from the state
          const selectedCellPaths = getSelectedCellPaths(state);
          const cardId = getSelectedCardId(state);
          const binId = selectedCellPaths[0].binId;
          const cellIndex = selectedCellPaths[0].index;
          return {
            binId,
            cellIndex,
            cardId
          };
        },
        {
          createElements: actions.design.createElements
        }
      )
    )(InsertBasePairLiteralDialog)
  });
