/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap } from "lodash";
import concatWarningStrs from "../../../utils/concatWarningStrs";

/**
 * Adds nested old records to the records to import.
 *
 * This function processes an array of records and, for each record, checks if it contains a nested record under the specified key.
 * If the nested record is an array, it maps through each element and attaches the corresponding old and new records if they exist.
 * If the nested record is not an array, it directly attaches the old and new records if they exist and logs an error if there is a mismatch in IDs.
 *
 * @param {Array} recordsToImport - The array of records to import.
 * @param {string} key - The key to check for nested records.
 * @returns {Array} - The processed array with nested old records added.
 */
export default function addNestedOldRecords(recordsToImport, key) {
  return flatMap(recordsToImport, r => {
    if (r[key]) {
      if (Array.isArray(r[key])) {
        const oldArr = r.__oldRecord && r.__oldRecord[key];
        const newArr = r.__newRecord && r.__newRecord[key];
        return r[key].map(s => {
          const oldR = s.id && oldArr && oldArr.find(r => r.id === s.id);
          const newR = s.id && newArr && newArr.find(r => r.id === s.id);
          if (oldR) {
            s.__oldRecord = oldR;
          }
          if (newR) {
            s.__newRecord = newR;
          }
          return s;
        });
      } else {
        if (
          r[key].id &&
          r.__oldRecord &&
          r.__oldRecord[key] &&
          r[key].id !== r.__oldRecord[key].id
        ) {
          console.error(`record:`, r);
          console.error(`key:`, key);
          r.__importFailed = concatWarningStrs(
            r.__importFailed,
            `Cannot update nested ${key} with different ID`
          );
        }
        if (r[key].id) {
          if (r.__oldRecord) {
            r[key].__oldRecord = r.__oldRecord[key];
          }
          if (r.__newRecord) {
            r[key].__newRecord = r.__newRecord[key];
          }
        }

        return r[key];
      }
    }
    return [];
  });
}
