/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getItemIdByCid,
  getRootCardId,
  getBinIdsInCard,
  isCardRoot,
  getChildCardIds,
  getIdOfParentCard,
  getOutputtingReactionIdOfCard
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import { ChangeSetsHelper } from "../../../../../../tg-iso-design/utils/designEditUtils";
import { isViewClassic } from "../../../../selectors/classicViewSelectors";
import removeAndInsertCards from "../removeAndInsertCards";
import {
  getBinIdsContainingInsert,
  getJunctionOnCard,
  getOverhangTypeOfJunction,
  getNonTerminalBins,
  getInteriorBinIdOfJunction,
  getBoundaryBinIdOfJunction
} from "../../../../../../tg-iso-design/selectors/junctionSelectors";
import shortid from "shortid";

/**
 * If a bin is added within the insert, it should get propagated up. If not,
 * then this method will return null. Otherwise it will return an object with
 * the keys `binId` and `injectToLeft`. The `binId` is the bin to track up and
 * down the tree, and `injectToLeft` tells us whether to place the bin to the
 * left or right of the tracking bin.
 */
function getTrackingInfoForPropagatingUp(state, cardId, neighborBinId, onLeft) {
  const defaultReturn = {
    binId: neighborBinId,
    injectToLeft: onLeft
  };

  // Nowhere to propagate for root cards.
  if (isCardRoot(state, cardId)) {
    return null;
  }

  const nonTerminalBinIds = getNonTerminalBins(state, cardId).map(b => b.id);

  // These are the ids of all of the bins that contain at least
  // a little bit of the insert.
  const insertBinIds = getBinIdsContainingInsert(state, cardId);

  const neighborIndex = insertBinIds.indexOf(neighborBinId);
  if (neighborIndex === -1) return null;

  function returnWhenOnBound(junc5P) {
    const junction = getJunctionOnCard(state, cardId, junc5P);
    if (!junction) return defaultReturn;
    const overhangType = getOverhangTypeOfJunction(junction, !junc5P);
    if (!!onLeft !== !!junc5P) {
      return nonTerminalBinIds.includes(neighborBinId)
        ? defaultReturn
        : {
            binId: nonTerminalBinIds[junc5P ? 0 : nonTerminalBinIds.length - 1],
            injectToLeft: junc5P
          };
    } else {
      if (overhangType === "ADAPTER" || overhangType === "INSERT") return null;
      else {
        return nonTerminalBinIds.includes(neighborBinId) ? defaultReturn : null;
      }
    }
  }

  if (insertBinIds.length === 1 ? onLeft : neighborIndex === 0) {
    return returnWhenOnBound(true);
  } else if (neighborIndex === insertBinIds.length - 1) {
    return returnWhenOnBound(false);
  } else {
    return defaultReturn;
  }
}

/**
 * Given the tracking info, see if we need to add the given bin to the
 * card. If so, then slate the bin to be added.
 *
 * Returns true if the bin was injected.
 */
function injectBin(
  changeSetsHelper,
  state,
  cardId,
  { binId, injectToLeft },
  newBinId,
  insertBin = true
) {
  const binIds = getBinIdsInCard(state, cardId);
  if (!binIds.includes(binId)) return false;

  if (insertBin)
    changeSetsHelper.insertNextTo(cardId, binId, injectToLeft, newBinId);

  function updateJunction(fivePrimeEnd) {
    const junction = getJunctionOnCard(state, cardId, fivePrimeEnd);
    if (!junction) return;

    const overhangType = getOverhangTypeOfJunction(junction, !fivePrimeEnd);
    const interiorBinId = getInteriorBinIdOfJunction(junction, !fivePrimeEnd);

    if (overhangType === "INSERT") return;

    if (binId === interiorBinId && !!fivePrimeEnd === !!injectToLeft) {
      const interiorBinKey = fivePrimeEnd
        ? "threePrimeCardInteriorBinId"
        : "fivePrimeCardInteriorBinId";
      const boundaryBinKey = fivePrimeEnd
        ? "threePrimeCardStartBinId"
        : "fivePrimeCardEndBinId";
      changeSetsHelper.updatePure("junction", {
        id: junction.id,
        [interiorBinKey]: newBinId,
        ...(overhangType !== "ADAPTER" ? { [boundaryBinKey]: newBinId } : {})
      });
    }
  }

  updateJunction(true);
  updateJunction(false);

  return true;
}

/**
 * We run into problems when we try to track using adapter bins. If we try to add
 * a bin to the insert side of them, the bin wouldn't get propagated past the ligating
 * reaction.
 *
 * This function will update the tracking information so that the bin can propagate past
 * the ligating reaction.
 */
function updateTrackingInfoWhenPropagatingUp(state, cardId, trackingInfo) {
  const { binId, injectToLeft } = trackingInfo;

  for (const fivePrimeEnd of [true, false]) {
    const junction = getJunctionOnCard(state, cardId, fivePrimeEnd);
    if (
      getOverhangTypeOfJunction(junction, fivePrimeEnd) === "ADAPTER" &&
      getBoundaryBinIdOfJunction(junction, fivePrimeEnd) === binId &&
      !!injectToLeft !== fivePrimeEnd
    ) {
      const binIds = getBinIdsInCard(state, cardId);
      const newBinId = binIds[binIds.indexOf(binId) + (fivePrimeEnd ? 1 : -1)];
      if (newBinId) {
        console.error(
          "No bin interior to the overhang on the adapter. This is an invalid design."
        );
      }
      trackingInfo.binId = newBinId;
      trackingInfo.injectToLeft = fivePrimeEnd;
    }
  }
}

/**
 * When we are injecting bins, we need to propagate the bins up and down the tree.
 * This function will take care of that.
 */
function propagateBins({
  changeSetsHelper,
  cardId,
  neighborBinId,
  onLeft,
  newBinId,
  state
}) {
  let propagateUp = false;
  let trackingInfo = {
    binId: neighborBinId,
    injectToLeft: onLeft
  };
  const propUpInfo = getTrackingInfoForPropagatingUp(
    state,
    cardId,
    neighborBinId,
    onLeft
  );
  if (propUpInfo) {
    trackingInfo = propUpInfo;
    propagateUp = true;
  }

  // If needed, inject the bins up the tree.
  if (propagateUp) {
    const tInfo = { ...trackingInfo };
    injectBin(changeSetsHelper, state, cardId, tInfo, newBinId, false);
    let cId = getIdOfParentCard(state, cardId);
    while (cId) {
      updateTrackingInfoWhenPropagatingUp(state, cardId, tInfo);
      injectBin(changeSetsHelper, state, cId, tInfo, newBinId);
      cId = getIdOfParentCard(state, cId);
    }
  }

  // dont need to inject down subtree? code already injects below
  // Inject down the subtree.
  // const queue = [cardId]
  // while (queue.length) {
  //   const cId = queue.shift()
  //   const wasBinInjected = injectBin(
  //     changeSetsHelper,
  //     state,
  //     cId,
  //     trackingInfo,
  //     newBinId
  //   )
  //   if (wasBinInjected) {
  //     queue.push(...getChildCardIds(state, cId))
  //   }
  // }
}

/**
 * Slate the proper mutations when we are in the classic view.
 */
function handleClassicView(
  changeSetsHelper,
  state,
  cardId,
  neighborBinId,
  onLeft,
  newBinId
) {
  changeSetsHelper.insertNextTo(cardId, neighborBinId, onLeft, {
    id: newBinId,
    direction: true,
    name: "New Bin",
    iconId: getItemIdByCid(state, "icon", "USER-DEFINED")
  });

  const parentCardId = getRootCardId(state);
  const binIds = getBinIdsInCard(state, parentCardId);
  const index = binIds.indexOf(neighborBinId) + +!onLeft;

  const newCardId = shortid();
  changeSetsHelper.createPure("binCard", {
    id: shortid(),
    index: 0,
    cardId: newCardId,
    binId: newBinId
  });

  removeAndInsertCards({
    changeSetsHelper,
    fullState: state,
    cardId: parentCardId,
    inserts: [
      {
        index,
        child: newCardId
      }
    ]
  });

  // Add the junctions.
  const reactionId = getOutputtingReactionIdOfCard(state, cardId);
  const inputCardIds = getChildCardIds(state, cardId);
  const prevCardId =
    inputCardIds[index === 0 ? inputCardIds.length - 1 : index - 1];
  const nextCardId = inputCardIds[index % inputCardIds.length];
  const junc5P = getJunctionOnCard(state, prevCardId, false);
  const junc3P = getJunctionOnCard(state, nextCardId, true);

  changeSetsHelper.deletePure("junction", [junc5P.id, junc3P.id]);
  changeSetsHelper.createPure("junction", [
    {
      isPhantom: index === 0,
      reactionId,
      junctionTypeCode: "SCARLESS",
      fivePrimeCardId: prevCardId,
      fivePrimeCardEndBinId: junc5P.fivePrimeCardEndBinId,
      fivePrimeCardInteriorBinId: junc5P.fivePrimeCardInteriorBinId,
      threePrimeCardId: newCardId,
      threePrimeCardStartBinId: newBinId,
      threePrimeCardInteriorBinId: newBinId
    },
    {
      isPhantom: index === binIds.length - 1,
      reactionId,
      junctionTypeCode: "SCARLESS",
      fivePrimeCardId: newCardId,
      fivePrimeCardEndBinId: newBinId,
      fivePrimeCardInteriorBinId: newBinId,
      threePrimeCardId: junc3P.threePrimeCardId,
      threePrimeCardStartBinId: junc3P.threePrimeCardStartBinId,
      threePrimeCardInteriorBinId: junc3P.threePrimeCardInteriorBinId
    }
  ]);
}

function handleNonClassicView(
  changeSetsHelper,
  state,
  cardId,
  neighborBinId,
  onLeft,
  newBinId,
  noPropagation,
  getOutputtingReactionIdOfCard
) {
  changeSetsHelper.insertNextTo(cardId, neighborBinId, onLeft, {
    id: newBinId,
    direction: true,
    name: "New Bin",
    iconId: getItemIdByCid(state, "icon", "USER-DEFINED")
  });

  const binIds = getBinIdsInCard(state, cardId);
  const index = binIds.indexOf(neighborBinId) + +!onLeft;
  const reactionId = getOutputtingReactionIdOfCard(state, cardId);
  const inputCardIds = getChildCardIds(state, cardId);
  const prevCardId =
    inputCardIds[index === 0 ? inputCardIds.length - 1 : index - 1];
  const nextCardId = inputCardIds[index % inputCardIds.length];

  //checks if the card selected has children/reactions,
  //if it does then create a new card child, else create new bin next to selected bin
  if (state.design.card[cardId].outputReactionId) {
    const newCardId = shortid();
    changeSetsHelper.createPure("binCard", {
      id: shortid(),
      index: 0,
      cardId: newCardId,
      binId: newBinId
    });
    removeAndInsertCards({
      changeSetsHelper,
      fullState: state,
      cardId,
      inserts: [
        {
          index,
          child: newCardId
        }
      ]
    });
    // Add the junctions.
    const junc5P = getJunctionOnCard(state, prevCardId, false);
    const junc3P = getJunctionOnCard(state, nextCardId, true);

    if (junc5P && junc3P) {
      changeSetsHelper.deletePure("junction", [junc5P.id, junc3P.id]);
    }
    changeSetsHelper.createPure("junction", [
      {
        isPhantom: index === 0,
        reactionId,
        junctionTypeCode: "SCARLESS",
        fivePrimeCardId: prevCardId,
        fivePrimeCardEndBinId: junc5P.fivePrimeCardEndBinId,
        fivePrimeCardInteriorBinId: junc5P.fivePrimeCardInteriorBinId,
        threePrimeCardId: newCardId,
        threePrimeCardStartBinId: newBinId,
        threePrimeCardInteriorBinId: newBinId
      },
      {
        isPhantom: index === binIds.length - 1,
        reactionId,
        junctionTypeCode: "SCARLESS",
        fivePrimeCardId: newCardId,
        fivePrimeCardEndBinId: newBinId,
        fivePrimeCardInteriorBinId: newBinId,
        threePrimeCardId: junc3P.threePrimeCardId,
        threePrimeCardStartBinId: junc3P.threePrimeCardStartBinId,
        threePrimeCardInteriorBinId: junc3P.threePrimeCardInteriorBinId
      }
    ]);
  } else {
    changeSetsHelper.createPure("binCard", {
      id: shortid(),
      index: 0,
      cardId: reactionId,
      binId: newBinId
    });
  }
  // if needs to propogate, it enters below code
  if (!noPropagation) {
    propagateBins({
      changeSetsHelper,
      cardId,
      neighborBinId,
      onLeft,
      newBinId,
      state
    });
  }
}

export default function insertBin(
  designState,
  { payload: { cardId, neighborBinId, onLeft, newBinId, noPropagation } },
  state
) {
  const changeSetsHelper = new ChangeSetsHelper(designState);
  if (isViewClassic(state)) {
    handleClassicView(
      changeSetsHelper,
      state,
      cardId,
      neighborBinId,
      onLeft,
      newBinId
    );
  } else {
    handleNonClassicView(
      changeSetsHelper,
      state,
      cardId,
      neighborBinId,
      onLeft,
      newBinId,
      noPropagation,
      getOutputtingReactionIdOfCard
    );
  }

  return changeSetsHelper.execute({
    recomputeBinValidation: true,
    recomputeElementValidation: true,
    recomputeDigestFasValidation: true,
    removeNonsensicalExtraSequences: true,
    updateInvalidMaterialAvailabilities: true
  });
}
