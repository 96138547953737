/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { SelectField } from "@teselagen/ui";
import { pick } from "lodash";

class InternalizationPreferencesSection extends React.Component {
  /**
   * Get the values of the internalization preferences. These are
   * taken directly from props, so some of the values might be out
   * of date.
   *
   * A different object will be returned each time this method is called.
   */
  getValuesFromProps() {
    return pick(this.props, "rs5", "rs3", "o5", "o3");
  }

  handleFieldSubmit = field => newValue => {
    const { junctionId, changeInternalizationPreferences } = this.props;

    const values = this.getValuesFromProps();
    values[field] = newValue;

    // If the values are invalid, then the handler will do nothing.
    changeInternalizationPreferences({ junctionId, values });
  };

  /**
   * Get the overhang options for the select field.
   * @param {boolean} is5Prime Is this the options for the five prime piece?
   */
  getOverhangOptions(is5Prime) {
    const { canHave5PrimeAdapter, canHave3PrimeAdapter } = this.props;
    if (
      (is5Prime && canHave5PrimeAdapter) ||
      (!is5Prime && canHave3PrimeAdapter)
    ) {
      return ["Flanking Sequence", "Adapter", "Insert"];
    } else {
      return ["Flanking Sequence", "Insert"];
    }
  }

  render() {
    const { level5Prime, readOnly, level3Prime } = this.props;
    return (
      <div>
        <h6>Internalization Preferences</h6>
        <SelectField
          disabled={readOnly}
          label={`5' Overhang (Card ${level5Prime})`}
          name="o5"
          options={processOptions(this.getOverhangOptions(true))}
          onFieldSubmit={this.handleFieldSubmit("o5")}
        />
        <SelectField
          disabled={readOnly}
          label={`3' Overhang (Card ${level3Prime})`}
          name="o3"
          options={processOptions(this.getOverhangOptions(false))}
          onFieldSubmit={this.handleFieldSubmit("o3")}
        />
      </div>
    );
  }
}

export default compose()(InternalizationPreferencesSection);

function processOptions(options) {
  return options.map(label => ({
    label,
    value: label.replace(/ /g, "_").toUpperCase()
  }));
}
