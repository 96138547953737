/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { parse } from "qs";
import { withProps } from "recompose";

export default ({ hashIdToInputName = {} }) =>
  withProps(props => {
    const hash = parse(props.location && props.location.hash.replace(/^#/, ""));
    const keys = Object.keys(hashIdToInputName);
    if (keys.some(key => hash[key])) {
      const { toolIntegrationProps = {} } = props;
      const newInputs = {};
      keys.forEach(key => {
        if (hash[key]) {
          newInputs[hashIdToInputName[key]] = hash[key];
        }
      });
      return {
        toolIntegrationProps: {
          ...toolIntegrationProps,
          isIntegratedMap: {
            ...toolIntegrationProps.isIntegratedMap,
            ...newInputs
          },
          inputs: {
            ...toolIntegrationProps.inputs,
            ...newInputs
          }
        }
      };
    }
  });
