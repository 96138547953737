/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { InputField, CheckboxField } from "@teselagen/ui";
import { get } from "lodash";
import { withUnitGeneric } from "../../../src-shared/utils/unitUtils";
import GenericSelect from "../../../src-shared/GenericSelect";
import stepFormValues from "../../../src-shared/stepFormValues";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import containerArrayTypeFragment from "../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";

function IntermediateContainerTypeFields({
  intermediateContainerType,
  generateIntermediateBarcode,
  label = "Intermediate Container",
  noBarcodeInfo,
  error
}) {
  const validate = useCallback(() => {
    return error;
  }, [error]);
  return (
    <React.Fragment>
      <InputField
        name="intermediateContainerName"
        isRequired
        label={`${label} Name`}
        generateDefaultValue={{
          ...defaultValueConstants.INTERMEDIATE_CONTAINER_NAME,
          customParams: {
            label: `${label}`
          }
        }}
      />
      <GenericSelect
        {...{
          label: `${label} Type`,
          name: "intermediateContainerType",
          asReactSelect: true,
          isRequired: true,
          placeholder: "Select container type...",
          schema: [
            "name",
            {
              render: (v, r) => {
                return `${r.name} (${r.aliquotContainerType.maxVolume +
                  " " +
                  r.aliquotContainerType.volumetricUnitCode})`;
              }
            }
          ],
          fragment: [
            "containerArrayType",
            "id name isPlate aliquotContainerType { code maxVolume volumetricUnitCode }"
          ],
          tableParamOptions: {
            additionalFilter: {
              isPlate: true
            }
          },
          additionalDataFragment: containerArrayTypeFragment,
          validate
        }}
      />
      {!!get(intermediateContainerType, "aliquotContainerType.deadVolume") && (
        <div>
          This {label.toLowerCase()} type has a dead volume of{" "}
          {withUnitGeneric(
            "aliquotContainerType.deadVolume",
            "aliquotContainerType.deadVolumetricUnitCode"
          )(intermediateContainerType)}
          .
        </div>
      )}
      {!noBarcodeInfo && (
        <React.Fragment>
          <CheckboxField
            name="generateIntermediateBarcode"
            label="Generate Barcode"
            defaultValue
          />
          {!generateIntermediateBarcode && (
            <InputField name="intermediateBarcode" label="Barcode" />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default stepFormValues(
  "intermediateContainerType",
  "generateIntermediateBarcode"
)(IntermediateContainerTypeFields);
