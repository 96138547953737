/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { CheckboxField } from "@teselagen/ui";
import React from "react";

function SelectAllScoredSequencesCheckbox() {
  return (
    <CheckboxField
      name="selectAllSequences"
      defaultValue
      label="Select All Finished Sequences"
      tooltipInfo="Automatically select all sequences when they finish scoring."
    />
  );
}

export default SelectAllScoredSequencesCheckbox;
