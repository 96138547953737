/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const assignedPositionFragment = gql`
  fragment assignedPositionFragment on assignedPosition {
    id
    positionTypeCode
    containerId
    container {
      id
      name
    }
    equipmentId
    equipment {
      id
      name
    }
    locationId
    location {
      id
      name
    }
    containerPositionId
  }
`;
