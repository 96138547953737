/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import PropTypes from "prop-types";

import {
  isDesignHierarchical,
  getNumberOfBinsInRootCard,
  createCardTree,
  getBinIdsInCard,
  treeLayout
} from "../../../../tg-iso-design/selectors/designStateSelectors";
import DesignCardContainer from "../../containers/DesignCardContainer";

import ReactionCard from "./ReactionCard";
import TreeMinimapCard from "./DesignElementCard/TreeMinimapCard";
import Tree from "./Tree";

import "./style.css";
import { showDialog } from "../../../src-shared/GlobalDialog";
import AddSplitsDialog from "../Dialogs/AddSplitsDialog";
import { Tooltip } from "@blueprintjs/core";
import {
  HORIZONTAL_MINIMAP_THICKNESS,
  REACTION_COLORS,
  VERTICAL_MINIMAP_THICKNESS,
  X_OFFSET_PER_UNIT_DEPTH
} from "../../../src-shared/components/HierarchicalDesign/constants";

const treeLayoutToMinimapThickness = {
  vertical: VERTICAL_MINIMAP_THICKNESS,
  horizontal: HORIZONTAL_MINIMAP_THICKNESS
};

/**
 * The view for the tree versions of hierarchical designs.
 */
class TreeView extends React.Component {
  static propTypes = {
    /**
     * The tree structure of the cards we are rendering. This should be
     * provided by the `createCardTree` selector.
     */
    tree: PropTypes.shape({
      /**
       * The id of the card.
       */
      id: PropTypes.string.isRequired,
      /**
       * The outputting operation of the card. Will be `undefined` for leaves.
       */
      operationId: PropTypes.string,
      /**
       * An array of child cards. They should have the same shape as the
       * `tree` prop. This array is required even for leaves and should
       * be an empty array in those cases.
       */
      children: PropTypes.array.isRequired
    }).isRequired,

    /**
     * Are we rendering a card in the horizontal or vertical tree layouts?
     */
    treeLayout: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,

    /**
     * The zoom value. Larger zoom values correspond to a more zoomed out
     * state.
     */
    zoom: PropTypes.number.isRequired
  };

  handleAddAssemblyClick = node => {
    if (getBinIdsInCard(this.props, node.id).length < 2)
      return window.toastr.warning(
        `Cannot add assembly reaction to card with only one bin.`
      );
    showDialog({
      ModalComponent: AddSplitsDialog,
      modalProps: {
        title: "Choose Input Grouping",
        addMockReaction: true,
        cardId: node.id
      }
    });
  };

  renderNode = ({ node, depth }) => {
    const { treeLayout } = this.props;
    const assemblyButtonColor = REACTION_COLORS[depth % REACTION_COLORS.length];
    if (
      node.children.length === 0 &&
      getBinIdsInCard(this.props, node.id).length > 1
    ) {
      return (
        <div
          className="design-card-container"
          style={{
            textAlign: treeLayout === "horizontal" ? "center" : null,
            marginLeft: treeLayout === "vertical" ? 12 : null
          }}
        >
          <div className="design-card-component">
            <DesignCardContainer
              cardId={node.id}
              level={node.level}
              depth={depth}
            />
          </div>
          <Tooltip content="Add Assembly Reaction">
            <div
              className="slide-out-button"
              onClick={() => this.handleAddAssemblyClick(node)}
              style={{ backgroundColor: assemblyButtonColor }}
            >
              <p className="plus-sign">+</p>
            </div>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft: treeLayout === "vertical" ? 12 : null }}>
          <DesignCardContainer
            cardId={node.id}
            level={node.level}
            depth={depth}
          />
        </div>
      );
    }
  };

  renderConnectorLabel = ({ node, connectorColor }) => (
    <ReactionCard
      connectorColor={connectorColor}
      reactionId={node.reactionId}
      level={node.level}
    />
  );

  renderMinimapNode = ({ node }) => <TreeMinimapCard cardId={node.id} />;

  handleResize = () => this.forceUpdate();

  render() {
    const {
      treeLayout,
      tree,
      zoom,
      isDesignHierarchical,
      numberOfBinsInRootCard,
      showMinimap
    } = this.props;

    const includeMinimap = isDesignHierarchical || numberOfBinsInRootCard > 8;

    const treeProps = {
      layout: treeLayout,
      colorCodes: REACTION_COLORS,
      renderNode: this.renderNode,
      rootNode: tree,
      childrenKey: "children",
      cardIdKey: "id",
      renderConnectorLabel: this.renderConnectorLabel,
      colorByDepth: true,
      zoom,
      marginRight: 40,
      marginBottom: 50,
      // Horizontal specific
      spaceBetweenSiblings: 25,
      yOffsetPerUnitDepth: 50,
      // Vertical specific
      xOffsetPerUnitDepth: X_OFFSET_PER_UNIT_DEPTH,
      horizontalLeaves: true
    };

    if (showMinimap) {
      // Minimap stuff
      Object.assign(treeProps, {
        includeMinimap,
        renderMinimapNode: this.renderMinimapNode,
        minimapViewportSelector: "#tg-tree-container",
        minimapParentSelector: ".edit-design-container",
        minimapThickness: treeLayoutToMinimapThickness[treeLayout]
      });
    }
    return (
      !!tree && (
        <div id="tg-tree-container">
          <Tree {...treeProps} />
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.handleResize}
          />
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    tree: createCardTree(state),
    treeLayout: treeLayout(state),
    zoom: state.ui.designEditor.tree.zoom,
    isDesignHierarchical: isDesignHierarchical(state),
    design: state.design,
    numberOfBinsInRootCard: getNumberOfBinsInRootCard(state),
    showMinimap: state.ui.designEditor.tree.showMinimap
  };
};

export const DumbTreeView = TreeView;

export default connect(mapStateToProps)(TreeView);
