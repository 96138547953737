/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { prepareDryAliquot } from "../../../utils/plateUtils";
import { reduxForm } from "redux-form";
import UnitInputField from "../../UnitInputField";
import { defaultMassUnitCode } from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";
import aliquotHydrationFragment from "../../../graphql/fragments/aliquotHydrationFragment";
import withQuery from "../../../../src-shared/withQuery";
import pluralize from "pluralize";

class DehydrateAliquotDialog extends Component {
  state = { loading: false };

  dehydrateAliquot = async values => {
    const { mass, massUnitCode } = values;
    const { hideModal, aliquots, refetch } = this.props;
    this.setState({
      loading: true
    });
    try {
      const additivesToDelete = [];
      const aliquotUpdates = [];
      aliquots.forEach(aliquot => {
        const options = {
          aliquot
        };
        if (!aliquot.concentration) {
          options.mass = mass;
          options.massUnitCode = massUnitCode;
        }
        if (aliquot.additives.length > 0) {
          aliquot.additives.forEach(additive => {
            if (additive.additiveMaterial.evaporable) {
              additivesToDelete.push(additive);
            }
          });
        }
        const dehydratedAliquot = prepareDryAliquot(options);
        aliquotUpdates.push(dehydratedAliquot);
      });
      await safeDelete(
        "additive",
        additivesToDelete.map(add => add.id)
      );
      await safeUpsert(aliquotHydrationFragment, aliquotUpdates);
      if (refetch) await refetch();
      hideModal();
    } catch (error) {
      this.setState({
        loading: false
      });
      console.error("error:", error);
      window.toastr.error("Error updating aliquots.");
    }
  };

  render() {
    const { aliquots, hideModal, handleSubmit, submitting } = this.props;
    let content;
    const allHaveConcentration = aliquots.every(a => a.concentration);
    if (allHaveConcentration) {
      content = `Are you sure you would like to dehydrate ${pluralize(
        "this",
        aliquots
      )} ${pluralize("aliquot", aliquots)}?`;
    } else {
      content = (
        <UnitInputField
          {...{
            name: "mass",
            label: "Mass",
            isRequired: true,
            unitName: "massUnitCode",
            unitDefault: defaultMassUnitCode,
            unitType: "massUnit"
          }}
        />
      );
    }

    return (
      <div>
        <div className={Classes.DIALOG_BODY}>{content}</div>
        <DialogFooter
          onClick={handleSubmit(this.dehydrateAliquot)}
          hideModal={hideModal}
          loading={submitting}
        />
      </div>
    );
  }
}

export default compose(
  wrapDialog({ title: "Dehydrate Aliquot" }),
  withQuery(aliquotHydrationFragment, {
    showLoading: true,
    inDialog: true,
    isPlural: true,
    options: props => {
      return {
        variables: {
          filter: {
            id: props.aliquotId || props.aliquotIds
          }
        }
      };
    }
  }),
  reduxForm({
    form: "dehydrateAliquotDialog"
  })
)(DehydrateAliquotDialog);
