/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get } from "lodash";
import classnames from "classnames";
import "./style.css";
import { InputField, NumericInputField, CheckboxField } from "@teselagen/ui";

import { RemoveButton } from "../../FieldArrayButtons";

class MeasurementUnit extends React.Component {
  /**
   * This ensure that no more than one unit will be selected to be the base unit.
   */
  handleBaseUnitChange = (__, newValue) => {
    if (!newValue) return;

    const { units, change, index } = this.props;
    const newUnits = units.map((u, i) => ({
      ...u,
      isBaseUnit: i === index,
      conversionFactor: i === index ? 1 : u.conversionFactor
    }));
    change("units", newUnits);
  };

  /**
   * This enables us to make sure there is one base unit with a conversion
   * factor of 1 whenever possible.
   */
  removeField = () => {
    const { units, fields, index, change } = this.props;

    fields.remove(index);

    if (this.isBaseUnit() && units.length > 1) {
      const newUnits = units.filter((u, i) => i !== index);
      newUnits[0] = { ...newUnits[0], conversionFactor: 1, isBaseUnit: true };
      change("units", newUnits);
    }
  };

  isBaseUnit() {
    const { units, index } = this.props;
    return get(units[index], "isBaseUnit");
  }

  render() {
    const { field, fields, index, isEditing } = this.props;
    return (
      <div className="edit-measurement-unit">
        <InputField
          className="edit-measurement-unit-name-field"
          name={`${field}.name`}
          label="Name"
        />
        <InputField
          className="edit-measurement-unit-abbreviation-field"
          name={`${field}.abbreviation`}
          label="Abbreviation"
        />
        <NumericInputField
          className={classnames("edit-measurement-unit-conversion-field", {
            // This enables us to force the conversion factor for base units
            // to be 1.
            "edit-measurement-unit-field-unchangeable": this.isBaseUnit()
          })}
          name={`${field}.conversionFactor`}
          label="Conversion Factor"
        />
        <CheckboxField
          className={classnames("edit-measurement-unit-base-field", {
            // We try to emulate a radio group with this. This means that once
            // a base unit is selected, one unit will always be the base unit.
            "edit-measurement-unit-field-unchangeable": this.isBaseUnit()
          })}
          name={`${field}.isBaseUnit`}
          label="Base Unit"
          onChange={this.handleBaseUnitChange}
        />
        {!isEditing && (
          <div className="edit-measurement-unit-remove">
            <RemoveButton
              fields={fields}
              index={index}
              remove={this.removeField}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MeasurementUnit;
