/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { map, isEmpty } from "lodash";
import { withHotkeys } from "@teselagen/ui";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Menu,
  MenuItem,
  MenuDivider,
  InputGroup,
  Classes,
  Overlay,
  Tooltip,
  Button,
  Popover
} from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import classNames from "classnames";
import { stringify } from "qs";
import TagFilterMenu from "../TagFilterMenu";
import modelNameToLink from "../utils/modelNameToLink";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { tagModels } from "../../../tg-iso-shared/constants";
import { formatDateTime } from "../utils/dateUtils";
import { observable } from "mobx";
import { observer } from "mobx-react";
import "./style.css";
import { renderProjectItems } from "../utils/libraryColumns";
import { useGetFilteredRecords } from "./utils";
import { universalSearchModels } from "../../../tg-iso-shared/constants";

const universalSearchStore = observable({
  isOpen: false
});

export function showUniversalSearch() {
  universalSearchStore.isOpen = true;
}

export function hideUniversalSearch() {
  universalSearchStore.isOpen = false;
}
type Props = {
  hotKey: any;
} & RouteComponentProps;

const UniversalSearch = ({ history, hotKey }: Props) => {
  const {
    searchTerm,
    setSearchTerm,
    modelMap,
    isLoading,
    hasQuery,
    tagFilterParams,
    setTagFilterParams
  } = useGetFilteredRecords();

  const onClose = () => {
    hideUniversalSearch();
    setTagFilterParams({ page: undefined, tags: [] });
    setSearchTerm("");
  };

  const handleToggle = () => {
    universalSearchStore.isOpen ? onClose() : showUniversalSearch();
  };

  const HotkeyEnabler = withHotkeys({
    searchHotkey: {
      allowInInput: true,
      global: true,
      combo: hotKey,
      label: "Show Universal Search",
      onKeyDown: handleToggle
    }
  });

  let rightElement;
  if (tagModels.length) {
    rightElement = (
      <Popover
        content={
          <TagFilterMenu
            currentParams={tagFilterParams}
            setNewParams={setTagFilterParams}
          />
        }
      >
        <Button active={!!tagFilterParams.tags} minimal icon="tag" />
      </Popover>
    );
  }

  const noResults = hasQuery &&
    !isLoading &&
    Object.values(modelMap).every(entities => !isEmpty(entities)) && (
      <Menu>
        <MenuItem disabled text="No Results" />
      </Menu>
    );

  return (
    <>
      <HotkeyEnabler />
      <Overlay
        className="tg-universal-search-overlay"
        isOpen={universalSearchStore.isOpen}
        onClose={onClose}
      >
        <div className="tg-universal-search">
          <InputGroup
            leftIcon="search"
            placeholder="Search..."
            autoFocus
            rightElement={
              <div style={{ display: "flex" }}>
                {isLoading && <Button minimal disabled loading />}
                {rightElement}
              </div>
            }
            onChange={val => setSearchTerm(val.target.value)}
            value={searchTerm}
            className={Classes.LARGE}
          />
          <div style={{ maxHeight: "75vh", overflow: "auto" }}>
            {hasQuery && (
              <>
                {map(
                  modelMap,
                  (
                    { displayName, entities = [], entityCount, route },
                    modelNameKey
                  ) => {
                    let model = modelNameKey;
                    if (modelNameKey.includes("-"))
                      model = modelNameKey.split("-")[0];
                    const noName =
                      universalSearchModels.noNameModels.includes(model);
                    if (!entities.length) return null;
                    return (
                      <div key={modelNameKey}>
                        <Menu>
                          <div
                            className={classNames(
                              "tg-flex",
                              "justify-space-between"
                            )}
                            style={{
                              paddingLeft: 10,
                              paddingRight: 20,
                              marginTop: 10
                            }}
                          >
                            <h6>{displayName}</h6>
                            {entityCount > 5 && (
                              <Tooltip
                                content={`View all ${entityCount} ${displayName} matching this filter in the library.`}
                              >
                                <Button
                                  style={{
                                    height: 15
                                  }}
                                  className={classNames(
                                    Classes.MINIMAL,
                                    Classes.SMALL
                                  )}
                                  onClick={() => {
                                    onClose();
                                    const filter: {
                                      filters?: string;
                                      tags?: string[];
                                    } = {};
                                    if (!noName && searchTerm) {
                                      filter.filters = `name__contains__${searchTerm}`;
                                    }
                                    if (!isEmpty(tagFilterParams.tags)) {
                                      filter.tags = tagFilterParams.tags;
                                    }
                                    history.push(
                                      (route || modelNameToLink(model)) +
                                        "?" +
                                        stringify(filter)
                                    );
                                  }}
                                >
                                  Total: {entityCount}
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                          <MenuDivider />
                          <>
                            {entityCount > 5 && (
                              <MenuItem disabled text="Top 5 Results" />
                            )}
                            {entities.map(
                              ({
                                id,
                                name,
                                barcode,
                                aliases = [],
                                projectItems = [],
                                ...rest
                              }) => {
                                let displayName;
                                if (noName || !name) {
                                  displayName =
                                    modelNameToReadableName(model, {
                                      upperCase: true
                                    }) + ` ${id}`;
                                } else {
                                  displayName = name;
                                }
                                let barcodeEl;
                                if (barcode && barcode.barcodeString) {
                                  barcodeEl = (
                                    <span
                                      style={{
                                        fontSize: 11,
                                        marginLeft: 3
                                      }}
                                      className={Classes.TEXT_MUTED}
                                    >
                                      ({barcode.barcodeString})
                                    </span>
                                  );
                                }
                                let aliasComp;
                                if (aliases.length) {
                                  aliasComp = (
                                    <div className={Classes.TEXT_MUTED}>
                                      {aliases.map(a => a.name).join(", ")}
                                    </div>
                                  );
                                }
                                let projectsComp;
                                if (projectItems) {
                                  projectsComp = (
                                    <div
                                      style={{
                                        transform: "scale(0.8)"
                                      }}
                                    >
                                      {renderProjectItems({ projectItems })}
                                    </div>
                                  );
                                }
                                return (
                                  <MenuItem
                                    key={id}
                                    text={
                                      <div>
                                        <span>
                                          {displayName}
                                          {barcodeEl}
                                        </span>
                                        {aliasComp}
                                        {projectsComp}
                                      </div>
                                    }
                                    label={formatDateTime(rest["updatedAt"])}
                                    onClick={() => {
                                      onClose();
                                      history.push(
                                        route
                                          ? route + `/${id}`
                                          : modelNameToLink(model, id)
                                      );
                                    }}
                                  />
                                );
                              }
                            )}
                          </>
                        </Menu>
                      </div>
                    );
                  }
                )}
                {noResults}
              </>
            )}
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default compose(
  reduxForm({
    form: "universalSearchForm"
  }),
  withRouter,
  observer
)(UniversalSearch);
