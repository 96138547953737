/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { BlueprintError, tgFormValues } from "@teselagen/ui";
import {
  DataTable,
  CheckboxField,
  FileUploadField,
  Loading
} from "@teselagen/ui";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { uniq, flatMap, difference } from "lodash";
import { getAlphnumericWellLocation } from "../../../../../src-shared/utils/getAlphnumericWellLocation";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { getPositionFromAlphanumericLocation } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { keyBy } from "lodash";
import {
  allowedCsvFileTypes,
  extractZipFiles,
  parseCsvFile
} from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";

// #region String constants
const ALIQUOT_SAMPLE_FORMULATIONS = `id sample { id sampleFormulations { id aliquotId } }`;
const ALIQUOT_DATA_FRAGMENT = /* GraphQL */ `
  id
  sample {
    id
    name
    material {
      id
      name
      polynucleotideMaterialSequence {
        id
        name
      }
    }
    sampleIsolation {
      id
      sourceSample {
        id
        sampleTypeCode
        sampleFormulations {
          id
          materialCompositions {
            id
            material {
              id
              name
              materialTypeCode
              polynucleotideMaterialSequence {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  aliquotContainer {
    id
    containerArray {
      id
      barcode {
        id
        barcodeString
      }
    }
    rowPosition
    columnPosition
  }
`;
const CONTAINER_ARRAY_FRAGMENT = /* GraphQL */ `
    id
    name
    barcode {
        id
        barcodeString
      }
    containerArrayType {
      id
      containerFormat {
        code
        columnCount
        rowCount
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquotId
    }
`;
// #endregion

const fields = ["FILE_NAME", "BARCODE", "WELL_LOCATION", "ALIQUOT_ID"];
const requiredFields = ["FILE_NAME"];
const messageMap = {
  BARCODE: "Required if ALIQUOT_ID is not provided.",
  WELL_LOCATION: "Required if ALIQUOT_ID is not provided.",
  ALIQUOT_ID: "Required if BARCODE and WELL_LOCATION are not provided."
};

class SelectSequencingData extends React.Component {
  state = { loading: false };

  updateIgnored = ignore_aliquot => {
    const {
      stepFormProps: { change },
      aliquots
    } = this.props;
    const new_aliquots = aliquots.map(aliquot => {
      if (aliquot.sampleName === ignore_aliquot.sampleName) {
        return { ...aliquot, ignore: !aliquot.ignore };
      } else {
        return { ...aliquot };
      }
    });

    change("aliquots", new_aliquots);
  };

  cellRenderer = {
    ignore: (idx, record) => {
      return (
        <CheckboxField
          id={`${idx}-${record.sampleName}`}
          name={record.sampleName}
          onFieldSubmit={() => this.updateIgnored(record)}
          defaultValue={record.ignore}
        >
          {" "}
        </CheckboxField>
      );
    }
  };

  /**
   * This function should upload the FASTQ files into S3. Currently the backend expects for these to be stored unzipped.
   * In order to support storing this ZIPPED **@teselagen/evolve-lib** backend packages needs modification.
   * @param {Object[]} files
   */

  selected_aliquots_schema = () => {
    const schema = {
      fields: [
        { path: "id", displayName: "Aliquot ID" },
        { path: "sampleName", displayName: "Sample" },
        {
          path: "referenceSequenceName",
          displayName: "Reference Sequence Name"
        },
        { path: "barcode", displayName: "Barcode" },
        { path: "position", displayName: "Position" }
        // { path: "ignore", displayName: "Ignore?" }
      ]
    };
    return schema;
  };

  /**
   * This functions just validates that the logic in function **fetchAliquotsFromFastqFiles()**
   * has retrieved valid Aliquot IDs.
   * @param {string[]} aliquotIds
   */
  validateAliquotIds = aliquotIds => {
    const {
      stepFormProps: { change }
    } = this.props;
    try {
      aliquotIds.forEach(id => {
        if (Number.isNaN(parseFloat(id))) {
          const error = `Aliquot ID ${id} is not valid.`;
          throw error;
        }
      });
    } catch (error) {
      this.clearSequencingData();
      console.error(error.message);
      change("fileError", error);
    }
  };

  /**
   * Based on FASTQ file name, the aliquot ID is determined and queried for.
   * FASTQ file names should be in one of the following formats:
   * "{{ANYTHING}}_Aliquot_{{EXISTING_ALIQUOT_ID}}_R1_{{ANYTHING}}.fastq" or "{{ANYTHING}}_Aliquot_{{EXISTING_ALIQUOT_ID}}_R2_{{ANYTHING}}.fastq"
   * or "{{ANYTHING}}_Barcode_{{BARCODE}}_Well_{{ALPHANUMERIC_COORDINATE}}_R1_{{ANYTHING}}.fastq" or "{{ANYTHING}}_Barcode_{{BARCODE}}_Well_{{ALPHANUMERIC_COORDINATE}}_R2_{{ANYTHING}}.fastq"
   * @param {string} filename
   */
  fetchAliquotsFromFastqFiles = async fileNames => {
    const {
      fileNamesWithAliquotIds,
      fileNamesWithPlateWell,
      customNameFilesWithAliquotId,
      customNameFilesWithPlateWell
    } = fileNames;
    const {
      stepFormProps: { change }
    } = this.props;
    let _error;

    const sequencedAliquotIds = fileNamesWithAliquotIds.map(fileName => {
      const aliquotIdPosition =
        fileName.toLowerCase().split("_").indexOf("aliquot") + 1;
      let aliquotId = fileName.split("_")[aliquotIdPosition];
      if (aliquotId.endsWith(".fastq")) {
        aliquotId = aliquotId.slice(0, aliquotId.indexOf(".fastq"));
      }
      return aliquotId;
    });
    let aliquot_sample_formulations;

    try {
      customNameFilesWithAliquotId.forEach(f => {
        sequencedAliquotIds.push(f.ALIQUOT_ID);
      });

      const plateBarcodes = [];
      const plateBarcodesAndAlphanumericWells = fileNamesWithPlateWell.map(
        fileName => {
          const plateBarcodePosition =
            fileName.toLowerCase().split("_").indexOf("barcode") + 1;
          const plateBarcode = fileName.split("_")[plateBarcodePosition];
          const alphanumericWellPosition =
            fileName.toLowerCase().split("_").indexOf("well") + 1;
          const alphanumericWell =
            fileName.split("_")[alphanumericWellPosition];
          plateBarcodes.push(plateBarcode);
          return { plateBarcode, alphanumericWell };
        }
      );

      customNameFilesWithPlateWell.forEach(f => {
        plateBarcodes.push(f.BARCODE);
        plateBarcodesAndAlphanumericWells.push({
          plateBarcode: f.BARCODE,
          alphanumericWell: f.WELL_LOCATION
        });
      });

      const plateQueryParams = {
        isPlural: true,
        variables: {
          filter: {
            "barcode.barcodeString": uniq(plateBarcodes)
          }
        }
      };
      const plateInfo = await safeQuery(
        ["containerArray", CONTAINER_ARRAY_FRAGMENT],
        plateQueryParams
      );

      const keyedPlateInfo = keyBy(plateInfo, "barcode.barcodeString");

      plateBarcodesAndAlphanumericWells.forEach(a => {
        const { plateBarcode, alphanumericWell } = a;
        if (!keyedPlateInfo[plateBarcode]) {
          const error = `Barcode ${plateBarcode} not found.`;
          this.clearSequencingData();
          change("fileError", error);
          throw error;
        }
        const plate = keyedPlateInfo[plateBarcode];
        const containerFormat = plate.containerArrayType.containerFormat;
        const { rowPosition, columnPosition } =
          getPositionFromAlphanumericLocation(
            alphanumericWell,
            containerFormat
          );
        // plateBarcodesAndAlphanumericWells[i].plate = plate;
        plate.aliquotContainers.forEach(ac => {
          if (
            ac.columnPosition === columnPosition &&
            ac.rowPosition === rowPosition
          ) {
            sequencedAliquotIds.push(ac.aliquotId);
          }
        });
      });

      // Fetching aliquots with IDs retrieved from the FASTQ filenames.

      // validateAliquotIds fn not currently applicable because we switched from numeric ids to long cids
      // comment back in once we switch back to human-readable numeric ids
      // this.validateAliquotIds(sequencedAliquotIds);
      const aliquotIdsToQuery = uniq(sequencedAliquotIds);
      const queryParams1 = {
        isPlural: true,
        variables: { filter: { id: aliquotIdsToQuery } }
      };
      aliquot_sample_formulations = await safeQuery(
        ["aliquot", ALIQUOT_SAMPLE_FORMULATIONS],
        queryParams1
      );
      if (aliquot_sample_formulations.length !== aliquotIdsToQuery.length) {
        const queriedAliquotIds = aliquot_sample_formulations.map(a => a.id);
        const missingAliquotIds = difference(
          aliquotIdsToQuery,
          queriedAliquotIds
        );
        const error = `Aliquot ID ${missingAliquotIds.join(", ")} not found`;
        const {
          stepFormProps: { change }
        } = this.props;
        this.clearSequencingData();
        change("fileError", error);
        throw error;
      }
    } catch (error) {
      console.error(error);
      _error = `Error fetching aliquots with IDs: ${uniq(
        sequencedAliquotIds
      ).join(", ")}`;
      console.error(`[ERROR 1]: ${_error}`);
      this.clearSequencingData();
      change("fileError", error);
    }
    let aliquot_formulation_ids = [];
    const aliquotIdToPooledAliquotIdMap = {};
    try {
      aliquot_formulation_ids = flatMap(aliquot_sample_formulations, record => {
        const aliquotIds = [];
        // If the aliquot found for the uploaded FASTQ files comes from a pooling. Use the aliquotIds of the aliquots pooled.
        // If there are no sampleFormulation records for this aliquot, then use its own aliquot Id.
        // Also, double check that if the aliquot has a non-empty sample formulation array, its material object should be null.
        if (
          record.sample.sampleFormulations &&
          record.sample.sampleFormulations.length > 0
        ) {
          record.sample.sampleFormulations.forEach(formulation => {
            aliquotIds.push(formulation.aliquotId);
            aliquotIdToPooledAliquotIdMap[formulation.aliquotId] = record.id;
          });
        } else {
          aliquotIds.push(record.id);
        }
        return aliquotIds;
      });
    } catch (error) {
      console.error(error);
      change("loading", false);
      change("fastqFilesUploadData", null);
      change("warningMessage", _error);
    }

    // Fetching aliquots with IDs retrieved from the previous aliquots or parent aliquots accordingly.
    // this.validateAliquotIds(aliquot_formulation_ids);
    const queryParams2 = {
      isPlural: true,
      variables: { filter: { id: uniq(aliquot_formulation_ids) } }
    };
    let aliquot_records;
    try {
      aliquot_records = await safeQuery(
        ["aliquot", ALIQUOT_DATA_FRAGMENT],
        queryParams2
      );
      // aliquot_sample_formulations = await safeQuery(["aliquot", ALIQUOT_SAMPLE_FORMULATIONS], queryParams1);
    } catch (error) {
      console.error(error);
      _error = `Error fetching aliquots with IDs: ${uniq(
        aliquot_formulation_ids
      ).join(", ")}`;
      console.error(`[ERROR 2]: ${_error}`);
    }
    let aliquots = [];
    try {
      aliquots = aliquot_records.map(aliquot_record => {
        let error;
        let referenceSequenceName;
        let referenceSequenceId;
        let barcode = "N/A";
        let position = "N/A";
        if (aliquot_record.aliquotContainer) {
          barcode = aliquot_record.aliquotContainer.containerArray.barcode
            ? aliquot_record.aliquotContainer.containerArray.barcode
                .barcodeString
            : "N/A";
          position = getAlphnumericWellLocation([
            {
              rowPosition: aliquot_record.aliquotContainer.rowPosition,
              columnPosition: aliquot_record.aliquotContainer.columnPosition
            }
          ]);
        }
        if (aliquot_record.sample.material) {
          if (aliquot_record.sample.material.polynucleotideMaterialSequence) {
            referenceSequenceName =
              aliquot_record.sample.material.polynucleotideMaterialSequence
                .name;
            referenceSequenceId =
              aliquot_record.sample.material.polynucleotideMaterialSequence.id;
          }
        } else if (aliquot_record.sample.sampleIsolation) {
          if (
            aliquot_record.sample.sampleIsolation.sourceSample
              .sampleFormulations
          ) {
            const dnaMaterial =
              aliquot_record.sample.sampleIsolation.sourceSample.sampleFormulations.filter(
                material =>
                  material.materialCompositions[0].material.materialTypeCode ===
                  "DNA"
              );
            const { id, name } =
              dnaMaterial[0].materialCompositions[0].material
                .polynucleotideMaterialSequence;
            referenceSequenceName = name;
            referenceSequenceId = id;
          }
        }
        if (!referenceSequenceName || !referenceSequenceId) {
          error = "No reference sequence found.";
        }

        const aliquot = {
          id: aliquot_record.id,
          pooledAliquotId: aliquotIdToPooledAliquotIdMap[aliquot_record.id],
          sampleId: aliquot_record.sample.id,
          sampleName: aliquot_record.sample.name,
          referenceSequenceName,
          referenceSequenceId,
          barcode,
          position,
          ignore: !!error,
          error: error
        };
        return aliquot;
      });
    } catch (error) {
      console.error(error);
      change("loading", false);
      change("fastqFilesUploadData", null);
      change("warningMessage", _error);
    }

    // If no aliquots were found, remove the recently uploaded file data.
    // Else, update the "aliquots" form value to render the aliquots data table.
    change("loading", false);
    if (aliquots && !aliquots.length) {
      change("fastqFilesUploadData", null);
      change(
        "warningMessage",
        "No aliquots found from the uploaded FASTQ filenames.\nCheck that the filenames follow the correct format or try again."
      );
    } else {
      change("aliquots", aliquots);
      change("warningMessage", null);
    }

    // Stop displaying the loading component.
  };

  /**
   * This function will take the uploaded ZIP file and retrieve every found FASTQ file inside.
   * It will also filter filename with __MACOSX and .DS_Store strings in them.
   * FASTQ file names should be in one of the following formats:
   * "{{ANYTHING}}_Aliquot_{{EXISTING_ALIQUOT_ID}}_R1_{{ANYTHING}}.fastq" or "{{ANYTHING}}_Aliquot_{{EXISTING_ALIQUOT_ID}}_R2_{{ANYTHING}}.fastq"
   * or "{{ANYTHING}}_Barcode_{{BARCODE}}_Well_{{ALPHANUMERIC_COORDINATE}}_R1_{{ANYTHING}}.fastq" or "{{ANYTHING}}_Barcode_{{BARCODE}}_Well_{{ALPHANUMERIC_COORDINATE}}_R2_{{ANYTHING}}.fastq"
   * @param zipFiles
   */
  readFastqFileNames = async files => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("warningMessage", null);
    change("loading", true);
    change("fileError", "");

    try {
      const zipFiles = files.filter(f => f.type === "application/zip");

      let allFiles = [];
      if (zipFiles.length) {
        allFiles = await extractZipFiles(zipFiles[0]);
      }
      if (allFiles.length) {
        files.push(...allFiles);
      }

      const csvFiles = [];
      const fileNames = files.map(file => {
        if (file.name.toLowerCase().includes(".csv")) {
          csvFiles.push(file);
        }
        return file.name;
      });

      let csvFile;
      if (csvFiles.length > 1) {
        this.clearSequencingData();
        const error = "Only one CSV file allowed.";
        change("fileError", error);
        throw error;
      } else csvFile = csvFiles[0];

      let csv;
      const customNameFilesWithAliquotId = [];
      const customNameFilesWithPlateWell = [];
      if (csvFile) {
        csv = await parseCsvFile(csvFile, {
          delimiter: ","
        });
        const errors = [];
        csv?.data.forEach((r, i) => {
          if (r?.ALIQUOT_ID) {
            customNameFilesWithAliquotId.push(r);
          } else if (r?.BARCODE && r?.WELL_LOCATION) {
            customNameFilesWithPlateWell.push(r);
          } else if (r?.BARCODE && !r?.WELL_LOCATION) {
            errors.push(
              `Row ${i + 1} specifies a barcode but not a well location.`
            );
          } else if (!r?.BARCODE && r?.WELL_LOCATION) {
            errors.push(
              `Row ${i + 1} specifies a well location but not a barcode.`
            );
          } else if (!r?.BARCODE && !r?.WELL_LOCATION) {
            errors.push(
              `Row ${
                i + 1
              } does not specify aliquot ID, barcode, or well location.`
            );
          }
        });
        if (errors.length) {
          this.clearSequencingData();
          change("fileError", errors.join(`\n`));
          throw errors.join(`\n`);
        }
      }

      change("fastqFilenames", fileNames);
      const fileNamesWithAliquotIds = [];
      const fileNamesWithPlateWell = [];
      fileNames.forEach(fileName => {
        if (fileName.toLowerCase().includes("aliquot_")) {
          fileNamesWithAliquotIds.push(fileName);
        } else if (fileName.toLowerCase().includes("barcode_")) {
          fileNamesWithPlateWell.push(fileName);
        }
      });

      this.fetchAliquotsFromFastqFiles({
        fileNamesWithAliquotIds,
        fileNamesWithPlateWell,
        customNameFilesWithAliquotId,
        customNameFilesWithPlateWell
      });
      change("customNameFilesWithAliquotId", customNameFilesWithAliquotId);
      change("customNameFilesWithPlateWell", customNameFilesWithPlateWell);

      this.setState({
        loading: true
      });
      // const filesToUpload = fileNames.map(fileName => {
      //   return
      // })
      // const response = await this.uploadFilesToS3(zipFiles);
      return true;
    } catch (error) {
      this.clearSequencingData();
      change("fileError", error);
      return console.error("error:", error);
    }
    // }
  };

  renderAliquotTable = aliquots => {
    return (
      <div id="">
        <DataTable
          isSimple
          formName="aliquotsTable"
          maxHeight={300}
          noSelect
          schema={this.selected_aliquots_schema()}
          cellRenderer={this.cellRenderer}
          entities={aliquots}
        />
      </div>
    );
  };

  clearSequencingData = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    this.loadedFullData = false;
    change("aliquots", []);
    change("fastqFilesUploadData", null);
    change("warningMessage", null);
  };

  render() {
    const {
      Footer,
      footerProps,
      aliquots = [],
      loading = false,
      warningMessage = null,
      fileError
    } = this.props;
    let table, clearButton, uploadSection;

    const warningMessageComponent = (
      <div>
        <Icon
          style={{ marginRight: "5px" }}
          icon={IconNames.ERROR}
          iconSize={15}
          intent={Intent.DANGER}
        />
        <span style={{ color: "gray" }}>{warningMessage}</span>
      </div>
    );

    if (!aliquots.length || fileError) {
      uploadSection = (
        <div
          className="upload-sequencing-data"
          style={{ display: "inline-block", width: "30%" }}
        >
          <FileUploadField
            isRequired
            fileLimit={10}
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes.concat([".fastq", ".zip"]),
              fileName: "CustomFASTQFileNames",
              headers: fields,
              requiredHeaders: requiredFields,
              headerMessages: messageMap
            })}
            name="fastqFilesUploadData"
            innerText="Click or drag to upload"
            showUploadList={false}
            showFilesCount={false}
            readBeforeUpload
            beforeUpload={this.readFastqFileNames}
          />
          {fileError && <BlueprintError error={fileError} />}
        </div>
      );
    }

    if (!!aliquots.length && !loading) {
      // This button triggers the clearTuplingData function.
      clearButton = (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginRight: 20
          }}
        >
          <Button
            onClick={this.clearSequencingData}
            minimal
            text="Change Data"
          />
        </div>
      );

      // This table shows the results of the file uploaded on the "Select Data" section of Step 1 of the Tupling Tool.
      table = (
        <React.Fragment>
          <div id="">
            <DataTable
              formName="aliquotsTable"
              maxHeight={300}
              noSelect
              schema={this.selected_aliquots_schema()}
              cellRenderer={this.cellRenderer}
              entities={aliquots}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {!aliquots.length || fileError ? (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Upload Sequencing Data"
              helper={`Upload sequencing data files (.fastq). FASTQ file names should follow one of these formats: "Aliquot_{{ALIQUOT_ID}}_R1_..." or "Barcode_{{BARCODE}}_Well_{{ALPHANUMERIC_COORDINATE}}_R1_...". If uploading FASTQ files with names that are NOT in one of these standard formats, include a CSV that maps between the custom file names and aliquot information or barcode/well information.`}
            />
            {uploadSection}
          </div>
        ) : (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Upload Sequencing Data"
              helper="Review the list of aliquots that will be submitted for alignment and quality control"
            />
            <div
              className="sequencing-data-associated-aliquots"
              style={{ display: "inline-block", width: "70%" }}
            >
              {table}
              {clearButton}
              {warningMessage && !loading && warningMessageComponent}
              {loading && <Loading bounce />}
              <Footer {...footerProps} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  tgFormValues(
    "aliquots",
    "loading",
    "warningMessage",
    "customNameFilesWithAliquotId",
    "customNameFilesWithPlateWell",
    "fileError"
  )
)(SelectSequencingData);
