/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { invert, keyBy, forEach, get, isEmpty } from "lodash";
import { FileUploadField, ReactSelectField } from "@teselagen/ui";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import {
  arrayToIdOrCodeValuedOptions,
  throwFormError
} from "../../../../../src-shared/utils/formUtils";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import {
  getAliquotMaterialList,
  plateLibraryFilter,
  validateMaterialPlates
} from "../../../../utils/plateUtils";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  getMaterialPlasmidSequence,
  getTransformationMicrobialMaterial
} from "../../../../utils";
import {
  microbialReactionPlanningMaterialFragment,
  microbialReactionPlanningPlateFragment
} from "../fragments";
import {
  allowedCsvFileTypes,
  validateCSVRow
} from "../../../../../../tg-iso-shared/src/utils/fileUtils";
import caseInsensitiveFilter from "../../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { safeQuery } from "../../../../../src-shared/apolloMethods";

import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getPositionFromAlphanumericLocation } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import getReactionInputsAndOutputs from "../../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";

const fields = [
  {
    path: "Plate Barcode",
    description:
      "Barcode of the input plate that microbial material will be added to",
    example: "PLT123456"
  },
  {
    path: "Well Location",
    description:
      "The well on the input plate that microbial material will be added to",
    example: "A1"
  },
  {
    path: "Material",
    description:
      "Name of the microbial material to be added to input plate well",
    example: "DNA sample"
  }
];

const csvHeaders = fields.map(f => f.path);
class SelectMicrobes extends Component {
  beforeNextStep = async values => {
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    const {
      containerArrays = [],
      microbialPlates = [],
      inputType,
      reactionType,
      microbialMaterialCSV,
      destinationPlateMap = {},
      microbialMaterial
    } = values;
    const outputMaterials = [];
    const inputKeyToOutputMaterialMap = {};
    const reactionsWithInputHash = [];
    let reactionIndex = 1;
    const getTransformationReactionInfo = (
      aliquotContainer,
      microbialMaterial
    ) => {
      const sampleMaterials = getAliquotMaterialList(aliquotContainer.aliquot);
      sampleMaterials.forEach(donorMaterial => {
        let plasmidId;
        if (reactionType === "CLONAL_TRANSFORMATION") {
          plasmidId = donorMaterial.id;
        } else if (reactionType === "CONJUGATION") {
          plasmidId = getMaterialPlasmidSequence(
            donorMaterial.microbialMaterialMicrobialMaterialPlasmids[0]
          ).id;
        }
        const key = `${plasmidId}-${microbialMaterial.id}`;
        if (!inputKeyToOutputMaterialMap[key]) {
          const outputMaterial = getTransformationMicrobialMaterial(
            donorMaterial,
            microbialMaterial
          );
          inputKeyToOutputMaterialMap[key] = outputMaterial;
          outputMaterials.push(outputMaterial);
          const reactionInputMaterials = [microbialMaterial, donorMaterial];
          reactionsWithInputHash.push({
            name: "reaction " + reactionIndex++,
            inputs: reactionInputMaterials.map(m => m.name).join(", "),
            outputs: outputMaterial.name,
            ...getReactionInputsAndOutputs({
              inputMaterials: reactionInputMaterials,
              outputMaterialId: `&${outputMaterial.cid}`
            })
          });
        }
      });
    };
    if (inputType === "MICROBIAL_MATERIAL") {
      // match DNA materials from source plates to 1 selected microbial material
      containerArrays.forEach(containerArray => {
        containerArray.aliquotContainers.forEach(ac => {
          getTransformationReactionInfo(ac, microbialMaterial);
        });
      });
    } else if (inputType === "PLATE") {
      const keyedSourcePlates = keyBy(containerArrays, "id");
      microbialPlates.forEach(destPlate => {
        const sourcePlateId = destinationPlateMap["id" + destPlate.id];
        const sourcePlate = keyedSourcePlates[sourcePlateId];

        const sourceAcs = keyBy(sourcePlate.aliquotContainers, ac =>
          getAliquotContainerLocation(ac, { force2D: true })
        );
        const destAcs = keyBy(destPlate.aliquotContainers, ac =>
          getAliquotContainerLocation(ac, { force2D: true })
        );
        forEach(sourceAcs, (sourceAc, location) => {
          const destAc = destAcs[location];
          const sourceMaterials = getAliquotMaterialList(sourceAc.aliquot);
          const destMaterials = getAliquotMaterialList(
            destAc && destAc.aliquot
          );
          if (sourceMaterials.length && destMaterials.length) {
            // we are validating that there will only ever be a single microbial material here
            const microbialMaterial = destMaterials[0];
            getTransformationReactionInfo(sourceAc, microbialMaterial);
          }
        });
      });
    } else if (inputType === "CSV") {
      // microbial materials CSV
      try {
        const [csv] = microbialMaterialCSV;
        const { parsedData } = csv;

        const keyedSourcePlates = keyBy(
          containerArrays,
          "barcode.barcodeString"
        );

        const keyedSourceWells = {};
        forEach(keyedSourcePlates, (p, key) => {
          keyedSourceWells[key] = p.aliquotContainers.reduce((acc, well) => {
            acc[getAliquotContainerLocation(well, { force2D: true })] = well;
            return acc;
          }, {});
        });

        const materialNames = [];
        const plateBarcodeToWellsMap = {};

        parsedData.forEach((row, i) => {
          const {
            "Plate Barcode": barcode,
            "Well Location": well,
            Material
          } = row;
          let error = validateCSVRow(row, csvHeaders, i);
          if (plateBarcodeToWellsMap[barcode]) {
            if (plateBarcodeToWellsMap[barcode].includes(well)) {
              error = `Duplicate well found on row ${i + 1}.`;
            } else {
              plateBarcodeToWellsMap[barcode].push(well);
            }
          } else {
            plateBarcodeToWellsMap[barcode] = [well];
          }
          if (error) throw new Error(error);
          if (!keyedSourcePlates[barcode]) {
            throw new Error(
              `Row ${
                i + 1
              } specifies the barcode ${barcode} which is not in the selected source plates.`
            );
          }

          materialNames.push(Material);
        });
        const mats = keyBy(
          await safeQuery(microbialReactionPlanningMaterialFragment, {
            variables: {
              filter: caseInsensitiveFilter("material", "name", materialNames, {
                additionalFilter: {
                  materialTypeCode: "MICROBIAL"
                }
              })
            }
          }),
          m => m.name.toLowerCase()
        );
        parsedData.forEach((row, i) => {
          const {
            "Plate Barcode": barcode,
            Material,
            "Well Location": location
          } = row;
          const microbe = mats[Material.toLowerCase()];
          if (!microbe) {
            throw new Error(
              `Could not find microbial material ${Material} in library. Row ${
                i + 1
              }`
            );
          } else if (!microbe.strain) {
            throw new Error(
              `Microbial material ${Material} is not linked to a strain. Row ${
                i + 1
              }`
            );
          }
          const sourcePlate = keyedSourcePlates[barcode];
          const position = getPositionFromAlphanumericLocation(
            location,
            sourcePlate.containerArrayType.containerFormat
          );
          const location2d = getAliquotContainerLocation(position, {
            force2D: true
          });
          const well = keyedSourceWells[barcode][location2d];
          if (!well) {
            throw new Error(
              `Row ${i + 1}: Location ${location} not found on plate`
            );
          }
          if (!well.aliquot) {
            throw new Error(
              `Row ${i + 1}: No aliquot found at location ${location}`
            );
          }
          getTransformationReactionInfo(well, microbe);
        });
      } catch (error) {
        console.error(`error:`, error);
        throwFormError({ microbialMaterialCSV: error.message || error });
      }
    }

    change("reactions", reactionsWithInputHash);
    change("outputMaterials", outputMaterials);
    nextStep();
  };

  render() {
    const {
      Footer,
      footerProps,
      handleSubmit,
      inputType,
      reactionType,
      containerArrays = [],
      microbialPlates = [],
      destinationPlateMap = {},
      stepFormProps: { change }
    } = this.props;

    let content;
    let plateErrors;
    if (inputType === "CSV") {
      content = (
        <div style={{ maxWidth: 400 }}>
          <FileUploadField
            name="microbialMaterialCSV"
            label="Upload Microbial Materials"
            tooltipInfo={`The CSV should contain a
            list of microbial materials,
            the plate barcode and well location (e.g. "A1")
            on the input plate that the
            ${
              reactionType === "CLONAL_TRANSFORMATION"
                ? "microbial material"
                : "recipient cell"
            }
            should be matched with.`}
            isRequired
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes,
              fileName: "materials",
              validateAgainstSchema: {
                fields
              }
            })}
          />
        </div>
      );
    } else if (inputType === "PLATE") {
      if (microbialPlates.length) {
        plateErrors = validateMaterialPlates(microbialPlates, {
          asObject: true,
          allowDry: true,
          validateAliquot: aliquot => {
            const material = get(aliquot, "sample.material");
            if (material?.materialTypeCode !== "MICROBIAL") {
              return "Not linked to a single microbial material";
            } else if (!material?.strain) {
              return "Not linked to a strain";
            }
          }
        });
      }
      content = (
        <div>
          <GenericSelect
            name="microbialPlates"
            label="Plates"
            tooltipInfo={`Select a plate of
                ${
                  reactionType === "CLONAL_TRANSFORMATION"
                    ? "microbial materials"
                    : "recipient cells"
                }, these
                plates must be of the same number and format as the input plates selected in the previous step.
                Materials from input plates selected in the previous step will be overlayed by materials of these
                plates based on well location (i.e. a "plate stamp" operation).`}
            schema={[
              "name",
              { displayName: "Barcode", path: "barcode.barcodeString" },
              dateModifiedColumn
            ]}
            mustSelect={containerArrays.length}
            isRequired
            isMultiSelect
            fragment={[
              "containerArray",
              "id name barcode { id barcodeString } updatedAt"
            ]}
            additionalDataFragment={microbialReactionPlanningPlateFragment}
            tableParamOptions={{
              additionalFilter: (...args) => {
                const qb = args[1];
                plateLibraryFilter(...args);
                qb.whereAll({
                  "containerArrayType.containerFormatCode":
                    containerArrays[0].containerArrayType.containerFormatCode,
                  id: qb.notInList(containerArrays.map(c => c.id))
                });
              }
            }}
            postSelectDTProps={{
              noForm: true,
              noSelect: true,
              isSimple: true,
              destinationPlateMap,
              schema: [
                platePreviewColumn({ plateErrors }),
                "name",
                {
                  displayName: "Barcode",
                  path: "barcode.barcodeString"
                },
                {
                  displayName: "Map to Source Plate",
                  render: (_, p, { index }) => {
                    return (
                      <div style={{ padding: 3 }}>
                        <ReactSelectField
                          isRequired
                          disallowClear
                          name={`destinationPlateMap.id` + p.id}
                          defaultValue={
                            containerArrays[index] && containerArrays[index].id
                          }
                          options={arrayToIdOrCodeValuedOptions(
                            containerArrays
                          )}
                          onFieldSubmit={v => {
                            if (v) {
                              const currentActivePlateId =
                                destinationPlateMap["id" + p.id];
                              const invDestPlateMap =
                                invert(destinationPlateMap);
                              const plateToSwapWith = invDestPlateMap[v];
                              if (currentActivePlateId && plateToSwapWith) {
                                change(
                                  "destinationPlateMap." + plateToSwapWith,
                                  currentActivePlateId
                                );
                              }
                            }
                          }}
                        />
                      </div>
                    );
                  }
                }
              ]
            }}
          />
        </div>
      );
    } else if (inputType === "MICROBIAL_MATERIAL") {
      content = (
        <div style={{ width: "50%" }}>
          <GenericSelect
            name="microbialMaterial"
            label="Microbial Material"
            tooltipInfo="Choose a microbial material, it will be matched with each material on the input plate."
            schema={["name"]}
            isMultiSelect={false}
            asReactSelect
            isRequired
            fragment={[
              "material",
              /* GraphQL */ `
                {
                  id
                  name
                  materialTypeCode
                  strain {
                    id
                    name
                  }
                  microbialMaterialMicrobialMaterialPlasmids {
                    id
                    polynucleotideMaterial {
                      id
                      polynucleotideMaterialSequence {
                        id
                        name
                      }
                    }
                  }
                }
              `
            ]}
            tableParamOptions={{
              additionalFilter: {
                materialTypeCode: "MICROBIAL"
              }
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Input Type"
            helper={`Choose an input type for selecting
            microbial materials. These microbial materials
            will be paired with the materials selected in
            the previous step to produce the specified output
            reaction map.
            `}
          />
          <div style={{ maxWidth: 250 }}>
            <ReactSelectField
              name="inputType"
              isRequired
              label="Input Type"
              defaultValue="MICROBIAL_MATERIAL"
              options={[
                { label: "Plates", value: "PLATE" },
                { label: "Microbial Material", value: "MICROBIAL_MATERIAL" },
                { label: "Microbial Material CSV", value: "CSV" }
              ]}
            />
          </div>
          {content}
        </div>
        <Footer
          {...footerProps}
          nextDisabled={!isEmpty(plateErrors)}
          onNextClick={handleSubmit(this.beforeNextStep)}
        />
      </div>
    );
  }
}

export default compose(
  stepFormValues(
    "inputType",
    "reactionType",
    "containerArrays",
    "microbialPlates",
    "destinationPlateMap"
  )
)(SelectMicrobes);
