/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// Returns error message if you can't change to digest. Returns a new changeSetsHelper otherwise.
export default ({
  changeSetsHelper,
  reactionId,
  restrictionEnzyme,
  assemblyMethod
}) => {
  if (!restrictionEnzyme)
    return "Cannot change to digest as no restriction enzyme was provided.";

  changeSetsHelper.updatePure("reaction", {
    id: reactionId,
    restrictionEnzymeId: restrictionEnzyme.id,
    assemblyMethodId: assemblyMethod.id,
    ...(assemblyMethod.name && { name: assemblyMethod.name })
  });
  // Ensure that these are in the client state.
  changeSetsHelper.updatePure("restrictionEnzyme", restrictionEnzyme);
  changeSetsHelper.updatePure("assemblyMethod", assemblyMethod);

  return changeSetsHelper;
};
