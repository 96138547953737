/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { RadioGroupField } from "@teselagen/ui";
import actions from "../../../../src-shared/redux/actions";

const mapStateToProps = (state, { cardId }) => ({
  globalViewOptionsRaw: state.ui.designEditor.general.viewOptions,
  localViewOptionsRaw: state.ui.designEditor.tree.cardViewOptions[cardId] || {}
});

const mapDispatchToProps = {
  updateCardViewOptions: actions.ui.designEditor.tree.updateCardViewOptions
};

class CardViewOptions extends React.Component {
  handleFieldSubmit = fieldName => value => {
    const { updateCardViewOptions, cardId } = this.props;
    updateCardViewOptions({
      id: cardId,
      changedViewOptions: { [fieldName]: value }
    });
  };

  getDefaultValue = fieldName => {
    const { globalViewOptionsRaw, localViewOptionsRaw } = this.props;
    return localViewOptionsRaw[fieldName] || globalViewOptionsRaw[fieldName];
  };

  render() {
    return (
      <div className="card-view-options">
        <h6 className="header">View Options</h6>
        <form>
          <RadioGroupField
            name="listElements"
            label="Listing parts in bins"
            defaultValue={this.getDefaultValue("listElements")}
            onFieldSubmit={this.handleFieldSubmit("listElements")}
            options={[
              {
                label: "Collapsed",
                value: "collapsed"
              },
              {
                label: "Expanded",
                value: "expanded"
              }
            ]}
          />
        </form>
      </div>
    );
  }
}

export default compose(
  reduxForm({
    form: "cardViewOptionsForm"
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(CardViewOptions);
