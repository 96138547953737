/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { handleActions } from "redux-actions";
import actions from "../../../actions";

const initialState = {
  open: false,
  activePanel: "info",
  width: 300,
  panelArgs: {},
  removeInterruptedFeaturesFromConstructs: false,
  validateCompatibleParts: false
};

export default handleActions(
  {
    [actions.ui.designEditor.inspector.toggle]: state => {
      return {
        ...state,
        open: state.locked ? false : !state.open
      };
    },
    [actions.ui.designEditor.inspector.open]: state => {
      return {
        ...state,
        open: !state.locked
      };
    },
    [actions.ui.designEditor.inspector.setActivePanel]: (
      state,
      { payload: { panel, panelArgs, shouldOpen } }
    ) => {
      return {
        ...state,
        activePanel: panel,
        panelArgs: panelArgs || {},
        open: shouldOpen === "open" ? true : state.locked ? false : state.open
      };
    },
    [actions.ui.designEditor.inspector.setWidth]: (
      state,
      { payload: width }
    ) => ({
      ...state,
      width
    }),
    [actions.ui.designEditor.general.selectCard]: state => ({
      ...state,
      activePanel: "set"
    }),

    [actions.ui.designEditor.general.selectBin]: state => ({
      ...state,
      activePanel: "set"
    }),

    [actions.ui.designEditor.general.selectCell]: state => ({
      ...state,
      activePanel: "element"
    }),

    [actions.ui.designEditor.general.selectValidatedJunction]: state => ({
      ...state,
      activePanel: "validation",
      open: true
    }),
    [actions.ui.designEditor.inspector
      .toggleRemoveInterruptedFeatures]: state => {
      return {
        ...state,
        removeInterruptedFeatures: !state.removeInterruptedFeatures
      };
    },
    [actions.ui.designEditor.inspector
      .toggleCompatiblePartsValidation]: state => {
      return {
        ...state,
        validateCompatibleParts: !state.validateCompatibleParts
      };
    }
  },
  initialState
);
