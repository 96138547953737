/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes, Intent, Button } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  NumericInputField,
  wrapDialog
} from "@teselagen/ui";

const DEFAULT_PRESET_NAME = "Default";

class SequencingQCCoverageSettingsDialog extends Component {
  onSubmit = async values => {
    // If this is "new settings mode", the preset name cannot be the same as one of those already existing.
    if (
      this.props.newSettingsMode &&
      this.props.forbiddenPresetNames.some(
        name => name === values.coverageSettingsName
      )
    ) {
      window.toastr.error(
        `Coverage Settings preset name: '${values.coverageSettingsName}' already exists.`
      );
      return;
    }
    //Only names other than DEFAULT_PRESET_NAME can be used as coverage settings name.
    if (values.coverageSettingsName !== DEFAULT_PRESET_NAME) {
      const { BAQ, MAQ, min_bp_cov, cov_th } = values;
      let newCoverageSettingsRecord;
      // For new setting presets, an userID and name have to be given.
      if (this.props.newSettingsMode) {
        newCoverageSettingsRecord = [
          {
            userId: this.props.newSettingsMode
              ? this.props.currentUser.id
              : undefined,
            coverageSettings: { BAQ, MAQ, min_bp_cov, cov_th },
            name: values.coverageSettingsName
          }
        ];
      } else {
        newCoverageSettingsRecord = [
          {
            id: this.props.coverageSettings.id,
            updatedAt: new Date(),
            coverageSettings: { BAQ, MAQ, min_bp_cov, cov_th }
          }
        ];
      }
      try {
        await this.props.safeUpsert(
          "sequencingQcCoveragePreset",
          newCoverageSettingsRecord
        );
      } catch (error) {
        window.toastr.error(
          this.props.newSettingsMode
            ? `Error creating new coverage settings preset: ${values.coverageSettingsName}.`
            : "Error updating coverage settings preset."
        );
        console.error(error);
      }
      // After every hideModal function, we should re-run the withQuery query of the parent component.
      await this.props.refetch();
      this.props.hideModal();
    } else {
      window.toastr.error(
        "Please select another Coverage Settings Name other than 'Default'"
      );
    }
  };

  async deleteCoveragePreset() {
    const {
      coverageSettings: { id }
    } = this.props;
    try {
      await this.props.safeDelete("sequencingQcCoveragePreset", [id]);
    } catch (error) {
      window.toastr.error(`Error deleting coverage settings preset.`);
      console.error(error);
    }
    this.props.change("coverageSettingsName", "default");
    await this.props.refetch();
    this.props.hideModal();
  }

  render() {
    const {
      hideModal,
      handleSubmit,
      submitting,
      coverageSettings = {},
      newSettingsMode
    } = this.props;
    const {
      name = "",
      coverageSettings: { BAQ = "", MAQ = "", min_bp_cov = "", cov_th = "" }
    } = coverageSettings;
    const nonEditable = name === DEFAULT_PRESET_NAME && !newSettingsMode;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <span style={{ marginBottom: "5px" }}>Preset Name</span>
          <InputField
            id={`${name}_1`}
            name="coverageSettingsName"
            placeholder={newSettingsMode ? "" : name}
            defaultValue={newSettingsMode ? "" : name}
            disabled={!newSettingsMode}
            isRequired={newSettingsMode}
          />
          <hr />
          <br />
          <NumericInputField
            id={`${name}_BAQ_2`}
            name="BAQ"
            label="Base Pair Quality"
            defaultValue={BAQ}
            placeholder={BAQ}
            isRequired
            disabled={nonEditable}
            tooltipInfo="Phred quality score filter for called base pairs"
          />
          <NumericInputField
            id={`${name}_MAQ_2`}
            name="MAQ"
            label="Mapping Quality"
            defaultValue={MAQ}
            placeholder={MAQ}
            isRequired
            disabled={nonEditable}
            tooltipInfo="Phred quality score filter for alignment mappings"
          />
          <NumericInputField
            id={`${name}_cov_th_2`}
            name="cov_th"
            label="Coverage Threshold"
            defaultValue={cov_th}
            placeholder={cov_th}
            isRequired={false}
            disabled={nonEditable}
            tooltipInfo="Coverage threshold for success criteria. If the alignment coverage is below the threshold, the QC report status will be set to 'Auto-Rejected (Coverage)', and the sample status will be set to 'Invalid'. Default coverage threshold is 0.995, which is equivalent to 99.5%."
          />
          <NumericInputField
            id={`${name}_min_bp_cov_2`}
            name="min_bp_cov"
            label="Single Reference Base Pair Allowed Coverage"
            defaultValue={min_bp_cov}
            placeholder={min_bp_cov}
            isRequired={false}
            disabled={nonEditable}
            tooltipInfo="Minimum depth of coverage for the reference base pairs"
          />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
          text={newSettingsMode ? "Save" : "Update"}
          disabled={!(name !== DEFAULT_PRESET_NAME || newSettingsMode)}
          additionalButtons={
            !this.props.newSettingsMode && (
              <Button
                intent={Intent.DANGER}
                onClick={() => {
                  this.deleteCoveragePreset();
                }}
                text="Delete"
                disabled={nonEditable}
              />
            )
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Coverage Settings"
  }),
  reduxForm({
    form: "sequencingQualityControlReportTool",
    destroyOnUnmount: false
  })
)(SequencingQCCoverageSettingsDialog);
