// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/nwb/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */\n/* STATUS PANE */\n.ModelSummaryWidgetStyles-module__statusPane-2Askw {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n  justify-content: left;\n  overflow-y: auto;\n  max-height: 140px;\n}\n\n.ModelSummaryWidgetStyles-module__statusPane-2Askw div {\n  display: flex;\n  padding-top: 5px;\n  padding-bottom: 20px;\n  padding-left: 20px;\n  flex-direction: row;\n  align-items: flex-end;\n}\n\n.ModelSummaryWidgetStyles-module__statusPane-2Askw p {\n  margin-left: 20px;\n}\n\n.ModelSummaryWidgetStyles-module__statusPane-2Askw p span {\n  font-weight: bold;\n}\n\n/* STATISTICS PANE */\n.ModelSummaryWidgetStyles-module__summaryMetricsPane-33kb- {\n  display: flex;\n  flex-flow: column;\n  height: 90%;\n}\n\n.ModelSummaryWidgetStyles-module__summaryMetricsPane-33kb- ul {\n  list-style-type: none;\n  margin-left: 20px;\n  margin-bottom: 10px;\n  /* overflow-y: scroll; */\n}\n\n.ModelSummaryWidgetStyles-module__summaryMetricsPane-33kb- ul li {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px;\n  margin-right: 20px;\n}\n\n.ModelSummaryWidgetStyles-module__infoSignWrapper-1zqWF {\n  display: flex;\n  justify-content: left;\n}\n\n.ModelSummaryWidgetStyles-module__infoSignWrapper-1zqWF div {\n  margin-left: 5px;\n  line-height: 0;\n}\n\n/* ERROR PANE */\n.ModelSummaryWidgetStyles-module__errorPane-TQ2-y .ModelSummaryWidgetStyles-module__divider-OVw7I {\n  width: 20%;\n  margin-left: auto;\n  margin-right: auto;\n  margin-bottom: 30px;\n}\n", ""]);
// Exports
exports.locals = {
	"statusPane": "ModelSummaryWidgetStyles-module__statusPane-2Askw",
	"summaryMetricsPane": "ModelSummaryWidgetStyles-module__summaryMetricsPane-33kb-",
	"infoSignWrapper": "ModelSummaryWidgetStyles-module__infoSignWrapper-1zqWF",
	"errorPane": "ModelSummaryWidgetStyles-module__errorPane-TQ2-y",
	"divider": "ModelSummaryWidgetStyles-module__divider-OVw7I"
};
module.exports = exports;
