/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import locationPathSeparator from "./locationPathSeparator.js";

export const preprocessPath = path => (path || "").match(/^\/?(.*)\/?$/)[1];

export const pathToPrimaryIds = path =>
  preprocessPath(path)
    .split("/")
    .map(subpath => subpath.split(locationPathSeparator)[0])
    .filter(id => id);

export const pathToSecondaryIds = path =>
  preprocessPath(path)
    .split("/")
    .map(subpath => subpath.split(locationPathSeparator)[1])
    .filter(id => id);

export const pathToIds = path =>
  preprocessPath(path)
    .split("/")
    .filter(x => x)
    .map(subpath => subpath.split(locationPathSeparator))
    .filter(a => a.length);
