/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState } from "react";
import { Intent } from "@blueprintjs/core";
import { SwitchField } from "@teselagen/ui";
import GenericSelect from "../GenericSelect";
import LabelWithTooltip from "../LabelWithTooltip";
import {
  IMPORTED_CODES,
  getDataGridImportStatus,
  getInfoSchema
} from "../utils/experimentData/dataGridUtils";
import dataGridPartialFragment from "../fragments/dataGridPartialFragment";

const SelectDataGrid = props => {
  const { handlers, helper } = props;
  const [dataGridLoaded, setDataGridLoaded] = useState(false);

  const onSelect = async result => {
    setDataGridLoaded(true);
    await handlers.onSelect(result);
    setDataGridLoaded(false);
  };

  const customFilter = (props, qb, currentParams) => {
    const { showImportedDataGrids } = currentParams;

    if (!showImportedDataGrids) {
      qb.andWhereAny(
        ...[
          {
            "importFileSet.importFileSetStepCode": qb.notInList(IMPORTED_CODES)
          },
          { importFileSetId: qb.isNull() }
        ]
      );
    }
  };

  const EnhancedGenericSelectChildren = ({ tableParams }) => {
    const { currentParams, setNewParams } = tableParams;
    return (
      <SwitchField
        label={
          <LabelWithTooltip
            icon="info-sign"
            intent={Intent.NONE}
            label="Include imported Data Grid(s)"
            tooltip="display together the imported and the non-imported Data Grids."
          />
        }
        name="onlyShowPending"
        onFieldSubmit={value => {
          setNewParams({
            ...currentParams,
            showImportedDataGrids: value
          });
        }}
      />
    );
  };

  return (
    <div
      className="tg-flex justify-space-between"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {helper}
      <GenericSelect
        {...{
          isRequired: true,
          name: "selectDataGrids",
          buttonProps: {
            text: "Select Data Grid",
            disabled: undefined,
            loading: dataGridLoaded
          },
          dialogProps: {
            title: "Select Data Grid",
            style: { width: 1200 }
          },
          dialogFooterProps: {
            text: "Select Data Grid"
          },
          isMultiSelect: false,
          onSelect,
          additionalTableProps: {
            isEntityDisabled: entity => {
              return IMPORTED_CODES.includes(
                getDataGridImportStatus(entity).code
              );
            },
            withSearch: false,
            enhancedChildren: EnhancedGenericSelectChildren
          },
          schema: getInfoSchema({
            withDates: true,
            withDescription: true,
            withLab: false
          }),
          fragment: dataGridPartialFragment,
          tableParamOptions: {
            additionalFilter: customFilter
          },
          readableName: "Data Grid",
          onClear: handlers.onClear
        }}
      />
    </div>
  );
};

export default SelectDataGrid;
