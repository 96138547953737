/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { getDesign } from "../../../../tg-iso-design/selectors/designStateSelectors";
import React from "react";
import { Tooltip, AnchorButton } from "@blueprintjs/core";
import actions from "../../../src-shared/redux/actions";
import { throttle } from "lodash";
import { getSubmitForAssemblyViolations } from "../../../../tg-iso-design/selectors/submitForAssemblySelectors";
import tooltips from "../../../src-shared/constants/tooltips";
import { popoverOverflowModifiers } from "../../../src-shared/utils/generalUtils";

const mapStateToProps = state => {
  const design = getDesign(state);
  return {
    submitForAssemblyViolations: getSubmitForAssemblyViolations(state),
    design,
    submitting: state.j5.submittingToJ5[design.id]
  };
};

const mapDispatchToProps = {
  submitDesignForAssembly: actions.j5.submitDesignForAssembly
};

class SubmitForAssemblyButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitForAssemblyClick = throttle(
      this.handleSubmitForAssemblyClick,
      5000
    );
  }

  handleSubmitForAssemblyClick = () => this.props.submitDesignForAssembly();

  render() {
    const { design, submitForAssemblyViolations, submitting } = this.props;

    return (
      design.type === "grand-design" && (
        <Tooltip
          disabled={window.Cypress && !submitForAssemblyViolations}
          content={
            <div style={{ maxWidth: 220 }}>
              {submitForAssemblyViolations
                ? "Can't submit yet: " + submitForAssemblyViolations
                : tooltips.SubmitForAssembly}
            </div>
          }
          usePortal
          modifiers={{ popoverOverflowModifiers }}
        >
          <AnchorButton
            loading={submitting}
            className="submit-for-assembly-button"
            text="Submit for Assembly"
            intent="success"
            disabled={!!submitForAssemblyViolations}
            small
            onClick={this.handleSubmitForAssemblyClick}
            style={{
              height: 24,
              marginRight: 10,
              marginTop: 3,
              marginBottom: 3,
              paddingLeft: 40,
              paddingRight: 40,
              whiteSpace: "nowrap",
              fontWeight: "normal"
            }}
          />
        </Tooltip>
      )
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SubmitForAssemblyButton
);
