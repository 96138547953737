/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment dataMapTypeFragment on dataMapType {
    code
    name
    description
    mapSchema
    isSystemType
    updatedAt
  }
`;
