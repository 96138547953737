/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get, isEqual } from "lodash";

import { compose } from "recompose";
import { SubmissionError } from "redux-form";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import plateMapGroupRunPcrFragment from "../../../graphql/fragments/plateMapGroupRunPcrFragment";
import runPcrDataTableFragment from "../../../graphql/fragments/runPcrDataTableFragment";
import SelectPcrMaterials, {
  containerArrayFragment
} from "./Steps/SelectPcrMaterials";
import PcrConfiguration from "./Steps/PcrConfiguration";
import ReviewWorklist from "./Steps/ReviewWorklist";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import stripFields from "../../../../src-shared/utils/stripFields";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import getReactionInputsAndOutputs from "../../../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";

class RunPCRTool extends React.Component {
  onSubmit = async values => {
    const {
      worklist,
      outputPlates,
      worklistName,
      reactionMapName,
      plateMapGroup,
      generateBarcodes
    } = values;
    try {
      const createdPlates = await safeUpsert("containerArray", outputPlates);
      if (generateBarcodes) {
        await addBarcodesToRecords(createdPlates);
      }

      const reactions = [];

      const outputMaterialIdToInputMaterialMap = {};
      plateMapGroup.plateMaps.forEach(plateMap => {
        plateMap.plateMapItems.forEach(plateMapItem => {
          const inputMaterials = [];
          if (!plateMapItem.j5Item) return;
          const outputMaterial = get(
            plateMapItem,
            "j5Item.j5PcrReaction.pcrProductSequence.polynucleotideMaterial"
          );
          inputMaterials.push(
            get(
              plateMapItem,
              "j5Item.j5PcrReaction.primaryTemplate.polynucleotideMaterial"
            )
          );
          inputMaterials.push(
            get(
              plateMapItem,
              "j5Item.j5PcrReaction.forwardPrimer.sequence.polynucleotideMaterial"
            )
          );
          inputMaterials.push(
            get(
              plateMapItem,
              "j5Item.j5PcrReaction.reversePrimer.sequence.polynucleotideMaterial"
            )
          );

          if (
            !outputMaterialIdToInputMaterialMap[outputMaterial.id] ||
            !isEqual(
              outputMaterialIdToInputMaterialMap[outputMaterial.id],
              inputMaterials.sort()
            )
          ) {
            outputMaterialIdToInputMaterialMap[
              outputMaterial.id
            ] = inputMaterials.sort();
            reactions.push({
              name: `reaction ${reactions.length + 1}`,
              ...getReactionInputsAndOutputs({
                inputMaterials,
                outputMaterials: [outputMaterial]
              })
            });
          }
        });
      });
      const reactionMapToCreate = {
        name: reactionMapName,
        reactionTypeCode: "PCR_REACTION",
        reactions
      };
      const [reactionMap] = await safeUpsert(
        "reactionMap",
        reactionMapToCreate
      );
      const worklistToUpsert = {
        name: worklistName,
        worklistReactionMaps: {
          reactionMapId: reactionMap.id
        },
        worklistTransfers: stripFields(worklist.worklistTransfers, [
          "sourceAliquotContainer",
          "destinationPlateName",
          "destinationAliquotContainer"
        ])
      };
      const [createdWorklist] = await safeUpsert("worklist", worklistToUpsert);
      return {
        containerArrays: createdPlates,
        worklist: createdWorklist,
        reactionMap
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: "Error creating worklist."
      });
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select PCR Source Materials",
        Component: SelectPcrMaterials,
        withCustomFooter: true
      },
      {
        title: "PCR Configuration",
        Component: PcrConfiguration,
        withCustomFooter: true
      },
      {
        title: "Review PCR Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(containerArrayFragment),
  withWorkflowInputs(runPcrDataTableFragment),
  withWorkflowInputs(plateMapGroupRunPcrFragment, {
    singular: true
  })
)(RunPCRTool);
