/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog, InputField } from "@teselagen/ui";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { identity, uniq } from "lodash";

function BarcodeHelperDialog(props) {
  const { hideModal, handleSubmit, submitting, history } = props;
  async function onSubmit({ barcode }) {
    try {
      let allBarcodes = [barcode];
      if (barcode.includes(" ") || barcode.includes(",")) {
        allBarcodes = barcode.split(/[\s,]+/).filter(identity);
        allBarcodes = uniq(allBarcodes);
      }
      const barcodeWithItemIds = await safeQuery(
        [
          "barcode",
          /* GraphQL */ `
            {
              id
              equipmentItemId
              containerArrayId
              aliquotContainerId
              containerId
            }
          `
        ],
        {
          variables: {
            filter: {
              barcodeString: allBarcodes
            }
          }
        }
      );
      if (!barcodeWithItemIds.length) {
        return window.toastr.error("No item with barcode found");
      }

      const getModelAndRecord = barcodeWithItemId => {
        for (const key of Object.keys(barcodeWithItemId)) {
          const recordId = barcodeWithItemId[key];
          if (key.includes("Id") && recordId) {
            const model = key.replace("Id", "");
            return {
              id: recordId,
              __typename: model
            };
          }
        }
      };
      const items = barcodeWithItemIds.map(getModelAndRecord).filter(identity);
      if (items.length > 1) {
        const allSameModel = items.every(
          item => item.__typename && item.__typename === items[0].__typename
        );
        if (!allSameModel) {
          return window.toastr.error(
            "Cannot open multiple items of different types."
          );
        } else {
          const ids = items.map(item => item.id);
          history.push(
            modelNameToLink(items[0].__typename) +
              `?filters=id__inList__${ids.join(";")}`
          );
          hideModal();
          return;
        }
      } else if (items[0].id) {
        history.push(modelNameToLink(items[0]));
        hideModal();
        return;
      }
      return window.toastr.error("Error opening item");
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Unable to open item");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <InputField autoFocus isRequired label="Barcode" name="barcode" />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Go to Barcoded Item"
  }),
  reduxForm({
    form: "barcodeHelperDialog"
  })
)(BarcodeHelperDialog);
