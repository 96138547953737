/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { get, sortBy, times, noop, isEmpty } from "lodash";
import Color from "color";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  DataTable,
  DialogFooter,
  Loading,
  withSelectedEntities,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import { Classes, Colors, Button } from "@blueprintjs/core";
import shortid from "shortid";

import GenericSelect from "../../../../src-shared/GenericSelect";
import PlateMapPlate from "../../PlateMapPlate";
import gql from "graphql-tag";
import withQuery from "../../../../src-shared/withQuery";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  getPositionFromAlphanumericLocation
} from "../../../../../tg-iso-lims/src/utils/plateUtils";

const getTubeNameOrBarcode = tube => {
  const name = tube.name;
  const barcode = get(tube, "barcode.barcodeString");
  if (name && barcode) {
    return `${name} (${barcode})`;
  } else {
    return name || barcode;
  }
};

const placeTubesChooseBoxDialogRackPreviewFragment = gql`
  fragment placeTubesChooseBoxDialogRackPreviewFragment on containerArray {
    id
    name
    aliquotContainers {
      id
      aliquotContainerTypeCode
      columnPosition
      rowPosition
    }
    containerArrayType {
      id
      name
      isPlate
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
      nestableTubeTypes {
        id
        aliquotContainerTypeCode
      }
    }
  }
`;

class PlaceTubeChooseBoxDialog extends Component {
  state = {
    activeRackPreview: null,
    tubePlacementMap: {}
  };

  onSubmit = async values => {
    const { tubePlacementMap = {} } = this.state;
    const {
      tubes,
      refetch,
      placementCb: _placementCb = noop,
      placementStrategy
    } = this.props;
    try {
      const { allUnplacedTubes = [], newRackType, skipRemaining } = values;

      const finishTubePlacement = async () => {
        const tubeUpdates = [];
        Object.keys(tubePlacementMap).forEach(tubeId => {
          const { rack, location } = tubePlacementMap[tubeId];
          const { rowPosition, columnPosition } =
            getPositionFromAlphanumericLocation(location);
          tubeUpdates.push({
            id: tubeId,
            rowPosition,
            columnPosition,
            containerArrayId: rack.id
          });
        });
        await safeUpsert("aliquotContainer", tubeUpdates);
        await refetch();
        _placementCb({ success: true });
        let tubeIds = tubes.map(tube => tube.id);
        if (skipRemaining) {
          tubeIds = Object.keys(tubePlacementMap);
        }
        showDialog({
          modalType: "TUBE_PLACEMENT_SUCCESS_PAGE",
          modalProps: {
            tubeIds
          }
        });
        // otherwise the PLACE_ACCORDING_TO_STRATEGY will hide the success page dialog
        return { cancelHide: true };
      };

      if (allUnplacedTubes.length && !skipRemaining) {
        const numNewBoxes = Math.ceil(
          allUnplacedTubes.length / newRackType.containerFormat.quadrantSize
        );
        const plateIds = times(numNewBoxes, () => shortid());
        const placementStrategyId = placementStrategy.id;
        const {
          data: {
            success,
            err,
            availableLocations,
            placementInformation,
            allItemsPlaced
          }
        } = await window.serverApi.request({
          method: "POST",
          url: "/placeItemsUsingStrategy",
          data: {
            containerArrayIds: plateIds,
            newBoxTypeId: newRackType.id,
            placementStrategyId,
            performMutations: false
          }
        });

        if (!success) throw new Error(err.message || err);

        // If we were not able to place all of the items, right now
        // we just abort.
        if (!allItemsPlaced) {
          throw new Error(
            "The placement strategy did not have enough available space. Unable to continue."
          );
        }

        const placementCb = async (...args) => {
          _placementCb(...args);
          const { success } = args[0];
          if (!success) {
            return _placementCb({ success: false });
          } else {
            return await finishTubePlacement();
          }
        };

        showDialog({
          modalType: "PLACE_ACCORDING_TO_STRATEGY",
          onClose: () => placementCb && placementCb({ success: false }),
          modalProps: {
            placementCb,
            placementStrategyId,
            newRackType,
            numberOfRacksToGenerate: plateIds.length,
            type: "aliquotContainer",
            itemIds: allUnplacedTubes.map(t => t.id),
            availableLocations,
            placementInformation,
            refetch
          }
        });
      } else {
        await finishTubePlacement();
      }
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error placing tubes.");
    }
  };

  loadActiveRackPreview = async rack => {
    this.setState({
      loadingActiveRackPreview: true
    });
    try {
      const fullRack = await safeQuery(
        placeTubesChooseBoxDialogRackPreviewFragment,
        {
          variables: {
            id: rack.id
          }
        }
      );
      if (!fullRack) {
        throw new Error("User missing access.");
      }
      this.setState({
        activeRackPreview: fullRack
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading rack view.");
    }
    this.setState({
      loadingActiveRackPreview: false
    });
  };

  render() {
    const {
      activeRackPreview,
      loadingActiveRackPreview,
      tubePlacementMap = {},
      selectedLocationsForRacks = {}
    } = this.state;
    const {
      tubes = [],
      boxList = [],
      placementStrategy,
      submitting,
      handleSubmit,
      hideModal,
      ChooseBoxesForTubePlacementSelectedEntities: selectedBoxes = [],
      newRackType,
      error: formError
    } = this.props;

    let selectedLocationsForActiveRack = [];
    if (activeRackPreview) {
      selectedLocationsForActiveRack =
        selectedLocationsForRacks[activeRackPreview.id] || [];
    }
    const allUnplacedTubes = tubes.filter(tube => !tubePlacementMap[tube.id]);
    let unplacedTubes = allUnplacedTubes;
    const incorrectTubes = [];
    const placedTubes = tubes.filter(tube => tubePlacementMap[tube.id]);

    let error;

    if (allUnplacedTubes.length && newRackType) {
      const acceptedTubeTypes = newRackType.nestableTubeTypes.map(
        t => t.aliquotContainerTypeCode
      );
      const newRackCanAcceptAllUnplacedTubes = allUnplacedTubes.every(t =>
        acceptedTubeTypes.includes(t.aliquotContainerTypeCode)
      );
      if (!newRackCanAcceptAllUnplacedTubes) {
        error =
          "This rack/box type does not does not accept all the remaining tubes.";
      }
    }

    if (activeRackPreview) {
      const placeableTubeTypes =
        activeRackPreview.containerArrayType.nestableTubeTypes.map(
          tt => tt.aliquotContainerTypeCode
        );
      unplacedTubes = unplacedTubes.filter(t => {
        if (!placeableTubeTypes.includes(t.aliquotContainerTypeCode)) {
          incorrectTubes.push(t);
          return false;
        } else {
          return true;
        }
      });
    }

    const onAliquotContainerSelected = (aliquotContainer, location) => {
      if (selectedLocationsForActiveRack.includes(location)) return;
      if (aliquotContainer.id) {
        return window.toastr.warning("There is already a tube here.");
      }
      if (!unplacedTubes.length) return;
      const newTubePlacementMap = {
        ...tubePlacementMap,
        [unplacedTubes[0].id]: {
          rack: activeRackPreview,
          location
        }
      };
      this.setState({
        tubePlacementMap: newTubePlacementMap,
        selectedLocationsForRacks: {
          ...selectedLocationsForRacks,
          [activeRackPreview.id]: [...selectedLocationsForActiveRack, location]
        }
      });
    };

    const autoPlaceTubesIntoRack = (columnFirst = false) => {
      const fullTubeList = generateContainerArray(
        activeRackPreview.aliquotContainers,
        activeRackPreview.containerArrayType.containerFormat
      );
      const sortOrder = columnFirst
        ? ["columnPosition", "rowPosition"]
        : ["rowPosition", "columnPosition"];
      const sortedEmptyPositions = sortBy(fullTubeList, sortOrder).filter(
        ac =>
          !ac.id &&
          !selectedLocationsForActiveRack.includes(
            getAliquotContainerLocation(ac)
          )
      );
      if (!sortedEmptyPositions.length) return;
      const newTubePlacementMap = {
        ...tubePlacementMap
      };
      const newSelectedLocationsForActiveRack = [
        ...selectedLocationsForActiveRack
      ];
      for (const tube of unplacedTubes) {
        const ac = sortedEmptyPositions.shift();
        if (!ac) break;
        const location = getAliquotContainerLocation(ac);
        newTubePlacementMap[tube.id] = {
          rack: activeRackPreview,
          location
        };
        newSelectedLocationsForActiveRack.push(location);
      }
      this.setState({
        tubePlacementMap: newTubePlacementMap,
        selectedLocationsForRacks: {
          ...selectedLocationsForRacks,
          [activeRackPreview.id]: newSelectedLocationsForActiveRack
        }
      });
    };

    const placeRowFirst = () => autoPlaceTubesIntoRack(false);

    const placeColumnFirst = () => autoPlaceTubesIntoRack(true);

    let activeRackPreviewComp;
    if (selectedBoxes.length) {
      if (loadingActiveRackPreview) {
        activeRackPreviewComp = <Loading inDialog />;
      } else if (activeRackPreview) {
        activeRackPreviewComp = (
          <div>
            <h4>{activeRackPreview.name}</h4>
            <h6>Select Desired Tube Locations</h6>
            <PlateMapPlate
              {...{
                containerArrayType: activeRackPreview.containerArrayType,
                aliquotContainers: generateContainerArray(
                  activeRackPreview.aliquotContainers,
                  activeRackPreview.containerArrayType.containerFormat
                ).map(ac => {
                  if (ac.id) {
                    return {
                      ...ac,
                      color: Color(Colors.RED4).fade(0.8)
                    };
                  } else {
                    return ac;
                  }
                }),
                onAliquotContainerSelected,
                selectedAliquotContainerLocations:
                  selectedLocationsForActiveRack
              }}
            />
          </div>
        );
      }
    }

    const content = (
      <div>
        <div
          style={{
            marginBottom: 15,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div>
            <h6>Tubes to place:</h6>
            {unplacedTubes.map((tube, i) => {
              return (
                <div key={tube.id}>
                  {i + 1}. {getTubeNameOrBarcode(tube)}
                </div>
              );
            })}
            {incorrectTubes.length > 0 && (
              <React.Fragment>
                <h6 style={{ marginTop: 15 }}>
                  Tubes which can't be put into this box/rack type:
                </h6>
                {incorrectTubes.map((tube, i) => {
                  return (
                    <div key={tube.id}>
                      {i + 1}. {getTubeNameOrBarcode(tube)}
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          </div>
          {placedTubes.length > 0 && (
            <div>
              <h6>Placed Tubes:</h6>
              {placedTubes.length > 0 && (
                <Button
                  style={{ marginTop: 5, marginBottom: 5 }}
                  minimal
                  intent="danger"
                  text="Clear All"
                  onClick={() => {
                    this.setState({
                      tubePlacementMap: {},
                      selectedLocationsForRacks: {}
                    });
                  }}
                />
              )}
              {placedTubes.map(tube => {
                const label = getTubeNameOrBarcode(tube);
                const rackName = tubePlacementMap[tube.id].rack.name;
                const location = tubePlacementMap[tube.id].location;
                const msg = `${label} - ${rackName} (${location})`;
                return (
                  <div key={tube.id}>
                    <Button
                      icon="trash"
                      intent="danger"
                      minimal
                      style={{ marginRight: 5 }}
                      onClick={() => {
                        const newTubePlacementMap = {
                          ...tubePlacementMap
                        };
                        delete newTubePlacementMap[tube.id];
                        const newSelectedLocationsForActiveRack =
                          selectedLocationsForActiveRack.filter(
                            loc => loc !== location
                          );
                        this.setState({
                          tubePlacementMap: newTubePlacementMap,
                          selectedLocationsForRacks: {
                            ...selectedLocationsForRacks,
                            [activeRackPreview.id]:
                              newSelectedLocationsForActiveRack
                          }
                        });
                      }}
                    />
                    {msg}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {unplacedTubes.length > 0 && activeRackPreview && (
          <div className="tg-flex" style={{ margin: 10 }}>
            <Button text="Place Row First" onClick={placeRowFirst} />
            <Button
              text="Place Column First"
              onClick={placeColumnFirst}
              style={{ marginLeft: 5 }}
            />
          </div>
        )}
        {boxList.length > 0 && (
          <React.Fragment>
            <hr className="tg-section-break" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                overflow: "auto"
              }}
            >
              <div style={{ marginTop: 10, marginBottom: 10, flex: 1 }}>
                <DataTable
                  onSingleRowSelect={this.loadActiveRackPreview}
                  isViewable
                  noPadding
                  isSingleSelect
                  entities={boxList}
                  schema={[
                    "name",
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    },
                    {
                      displayName: "Empty Positions",
                      path: "emptyPositions"
                    }
                  ]}
                  formName="ChooseBoxesForTubePlacement"
                />
              </div>
              {!!activeRackPreviewComp && <div className="tg-flex-separator" />}
              {activeRackPreviewComp}
            </div>
          </React.Fragment>
        )}
        {allUnplacedTubes.length > 0 && (
          <React.Fragment>
            <hr className="tg-section-break" />
            <div style={{ marginBottom: 15 }}>
              {boxList.length > 0
                ? "The remaining tubes will be placed into new boxes/racks using this strategy."
                : "There were no boxes/racks with empty positions in this strategy. Would you like to create new boxes/racks to place these tubes?"}
            </div>
            <GenericSelect
              {...{
                name: "newRackType",
                asReactSelect: true,
                isRequired: true,
                label: "Destination Box/Rack Type",
                defaultValue: placementStrategy.destinationContainerArrayType,
                schema: [
                  {
                    path: "name"
                  }
                ],
                tableParamOptions: {
                  additionalFilter: {
                    isPlate: false
                  }
                },
                fragment: [
                  "containerArrayType",
                  /* GraphQL */ `
                    {
                      id
                      name
                      containerFormat {
                        code
                        rowCount
                        columnCount
                        quadrantSize
                        is2DLabeled
                      }
                      nestableTubeTypes {
                        id
                        aliquotContainerTypeCode
                      }
                    }
                  `
                ]
              }}
            />
          </React.Fragment>
        )}
      </div>
    );

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {content}
          <BlueprintError error={error || formError} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          text={allUnplacedTubes.length ? "Next" : "Submit"}
          disabled={error}
          additionalButtons={
            // This will allow users to continue without placing all of the tubes
            !isEmpty(tubePlacementMap) && allUnplacedTubes.length
              ? [
                  <Button
                    key="placeIntoExisting"
                    text="Skip Remaining"
                    onClick={handleSubmit(vals => {
                      return this.onSubmit({
                        ...vals,
                        skipRemaining: true,
                        allUnplacedTubes
                      });
                    })}
                  />
                ]
              : undefined
          }
          onClick={handleSubmit(vals =>
            this.onSubmit({ ...vals, allUnplacedTubes })
          )}
        />
      </React.Fragment>
    );
  }
}

const placementStrategyForPlacingTubesIntoBoxesFragment = gql`
  fragment placementStrategyForPlacingTubesIntoBoxesFragment on placementStrategy {
    id
    name
    destinationContainerArrayType {
      id
      name
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
      nestableTubeTypes {
        id
        aliquotContainerTypeCode
      }
    }
  }
`;

export default compose(
  wrapDialog({
    title: "Select Positions for Tubes",
    style: {
      width: 850
    }
  }),
  withQuery(placementStrategyForPlacingTubesIntoBoxesFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.placementStrategyId
        }
      };
    }
  }),
  reduxForm({
    form: "PlaceTubeChooseBoxDialogForm",
    enableReinitialize: true
  }),
  tgFormValues("newRackType", "generateBarcodes"),
  withSelectedEntities("ChooseBoxesForTubePlacement")
)(PlaceTubeChooseBoxDialog);
