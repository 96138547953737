/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export default function addSequenceTypeOrDefault(sequence, sequenceTypeCode) {
  if (sequence.sequenceTypeCode) {
    // use it
  } else if (sequenceTypeCode) {
    sequence.sequenceTypeCode = sequenceTypeCode;
  } else {
    //if there's no sequenceTypeCode passed, then we'll rely on the parsed seq's circularity
    sequence.sequenceTypeCode = sequence.circular
      ? "CIRCULAR_DNA"
      : "LINEAR_DNA";
  }
  if (sequence.sequenceTypeCode === "CIRCULAR_DNA") sequence.circular = true;
}
