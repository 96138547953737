/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";

import withQuery from "../withQuery";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { safeQuery } from "../apolloMethods";
import actions from "../redux/actions";
import { SelectField } from "@teselagen/ui";
import { Link } from "react-router-dom";
import { Callout } from "@blueprintjs/core";

const mapStateToProps = state => {
  const aminoAcidElements = [];
  Object.values(state.design.element).forEach(el => {
    if (el.aminoAcidPartId) aminoAcidElements.push(el);
  });
  return {
    aminoAcidElements
  };
};

const mapDispatchToProps = {
  createElements: actions.design.createElements
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "backTranslateAAPartsDialog"
  }),
  withQuery(["codonMap", "id name description"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(({ codonMaps, handleSubmit, Footer, aaSeqsToRevTrans }) => {
  return (
    <>
      <div className="bp3-dialog-body">
        <Callout style={{ marginBottom: 10 }} intent="primary">
          You can add/edit codon maps in{" "}
          <Link to="/settings/codon-maps">settings/codon-maps</Link>
        </Callout>
        <div className="vertical-table-entity">
          <h4> Select Your Custom Codon Table </h4>
          <SelectField
            name="backTranslator"
            defaultValue={codonMaps[0]}
            options={codonMaps.map(codonMap => ({
              label: codonMap.name,
              value: codonMap
            }))}
          />
        </div>
        <div className="vertical-table-entity">
          Choose how you want the following ambiguous characters to resolve.
          <div className="ambiguous-aa-selectors">
            <SelectField
              label="B"
              name="B"
              defaultValue="N"
              options={["N", "D"]}
            />
            <SelectField
              label="J"
              name="J"
              defaultValue="I"
              options={["I", "L"]}
            />
            <SelectField
              label="X"
              name="X"
              defaultValue="A"
              options={"ACDEFGHIKLMNPQRSTVWY".split("")}
            />
            <SelectField
              label="Z"
              name="Z"
              defaultValue="Q"
              options={["Q", "E"]}
            />
          </div>
        </div>
      </div>
      <Footer
        onSubmit={handleSubmit(async values => {
          try {
            const fullCodonMap = await safeQuery(
              [
                "codonMap",
                "id name codonMapEntries { id probability dnaCodonCode aminoAcidResidueCode }"
              ],
              {
                isPlural: true,
                variables: {
                  filter: { id: values.backTranslator.id }
                }
              }
            );
            const aaToCodonMap = {};
            fullCodonMap[0].codonMapEntries.forEach(cme => {
              if (cme.probability === 1) {
                aaToCodonMap[cme.aminoAcidResidueCode] = cme.dnaCodonCode;
              }
            });
            const toRet = aaSeqsToRevTrans.map(
              ({ proteinSequence, ...rest }) => {
                let reverseTranslatedDna = "";
                proteinSequence = proteinSequence.toUpperCase(); //djrtodo find out the casing issue behind this
                for (let i = 0; i < proteinSequence.length; i++) {
                  let codon = "";
                  if (values[proteinSequence[i]]) {
                    codon = aaToCodonMap[values[proteinSequence[i]]];
                  } else if (aaToCodonMap[proteinSequence[i]]) {
                    codon = aaToCodonMap[proteinSequence[i]];
                  } else {
                    throw new Error(
                      `No resolver found for amino acid character ${proteinSequence[i]}`
                    );
                  }

                  reverseTranslatedDna += codon;
                }
                return { ...rest, reverseTranslatedDna, proteinSequence };
              }
            );
            return [toRet, { name: fullCodonMap[0].name, description: "" }];
          } catch (e) {
            window.toastr.error(`Error: ${e.message}`);
            throw e;
          }
        })}
      ></Footer>
    </>
  );
});
