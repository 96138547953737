/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, noop } from "lodash";
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import caseInsensitiveFilter from "./caseInsensitiveFilter";
import { addToUniqArray } from "./generalUtils";
import modelNameToReadableName from "./modelNameToReadableName";

export default async function uploadReagentHelper(
  reagents,
  { getCsvRowExtProps = noop, noErrorOnExisting } = {},
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const additiveMaterialNames = [];
  const additiveTypeNames = [];
  const selectionMethodNames = [];
  const targetOrganismClassNames = [];
  for (const reagent of reagents) {
    addToUniqArray(additiveMaterialNames, reagent.name);
    addToUniqArray(additiveTypeNames, reagent.reagentType);
    addToUniqArray(
      targetOrganismClassNames,
      (reagent.targetOrganismGroup || "").toLowerCase()
    );

    if (reagent.selectionMethods) {
      reagent.selectionMethods.forEach(sm => {
        addToUniqArray(selectionMethodNames, sm.trim().toLowerCase());
      });
    }
  }
  const additiveMaterials = additiveMaterialNames.length
    ? await safeQuery(["additiveMaterial", "id name"], {
        variables: {
          filter: caseInsensitiveFilter(
            "additiveMaterial",
            "name",
            additiveMaterialNames
          )
        }
      })
    : [];

  const additiveTypes = additiveTypeNames.length
    ? await safeQuery(["additiveType", "code name"], {
        variables: {
          filter: caseInsensitiveFilter(
            "additiveType",
            "name",
            additiveTypeNames
          )
        }
      })
    : [];
  const selectionMethods = selectionMethodNames.length
    ? await safeQuery(["selectionMethod", "id name"], {
        variables: {
          filter: caseInsensitiveFilter(
            "selectionMethod",
            "name",
            selectionMethodNames
          )
        }
      })
    : [];
  const targetOrganismClasses = targetOrganismClassNames.length
    ? await safeQuery(["targetOrganismClass", "id name"], {
        variables: {
          filter: caseInsensitiveFilter(
            "targetOrganismClass",
            "name",
            targetOrganismClassNames
          )
        }
      })
    : [];
  const keyedAdditiveMaterials = keyBy(additiveMaterials, am => {
    return am.name.toLowerCase();
  });
  const keyedAdditiveTypes = keyBy(additiveTypes, t => t.name.toLowerCase());
  const keyedSelectionMethods = keyBy(selectionMethods, s =>
    s.name.toLowerCase()
  );
  const keyedTargetOrganismClasses = keyBy(targetOrganismClasses, o =>
    o.name.toLowerCase()
  );
  const newAdditiveMaterials = [];
  for (const reagent of reagents) {
    const {
      name = "",
      description,
      reagentType = "",
      isDry,
      selectionMethods,
      targetOrganismGroup = ""
    } = reagent;
    const existingAdditiveMaterial = keyedAdditiveMaterials[name.toLowerCase()];
    if (existingAdditiveMaterial) {
      if (noErrorOnExisting) {
        reagent.existingId = existingAdditiveMaterial.id;
        continue;
      } else {
        throw new Error(`Reagent name ${name} which already exists.`);
      }
    }
    const additiveType = keyedAdditiveTypes[reagentType.toLowerCase()];
    if (!additiveType) {
      throw new Error(
        `Reagent ${name} specifies the reagent type ${reagentType} which does not exist.`
      );
    }
    if (
      additiveType.code !== "GROWTH_MEDIA" &&
      (selectionMethods?.length || targetOrganismGroup)
    ) {
      throw new Error(
        `Reagent ${name} specifies a ${
          selectionMethods?.length && targetOrganismGroup
            ? "selection method and target organism group"
            : targetOrganismGroup
              ? "target organism group"
              : selectionMethods?.length
                ? "selection method"
                : ""
        } which only applies to Growth Media reagent type.`
      );
    }
    const missingSelectionMethods = [];
    const growthMediaSelectionMethods = selectionMethods.reduce(
      (acc, smName) => {
        const selectionMethod =
          keyedSelectionMethods[smName.trim().toLowerCase()];
        if (selectionMethod) {
          acc.push({
            selectionMethodId: selectionMethod.id
          });
        } else {
          missingSelectionMethods.push(smName);
        }
        return acc;
      },
      []
    );
    if (selectionMethods?.length && missingSelectionMethods.length) {
      throw new Error(
        `Reagent ${name} specifies these selection methods ${missingSelectionMethods.join(
          ", "
        )} which ${
          missingSelectionMethods.length > 1 ? "do" : "does"
        } not exist.`
      );
    }
    const targetOrganismClass =
      keyedTargetOrganismClasses[targetOrganismGroup.toLowerCase()];
    if (targetOrganismGroup && !targetOrganismClass) {
      throw new Error(
        `Reagent ${name} specifies the ${modelNameToReadableName(
          "targetOrganismClass"
        )} ${targetOrganismGroup} which does not exist.`
      );
    }

    const recordCid = reagent.cid || shortid();
    getCsvRowExtProps({
      row: reagent,
      recordId: `&${recordCid}`,
      modelTypeCode: "ADDITIVE_MATERIAL"
    });
    const additiveMaterial = {
      cid: recordCid,
      name: name,
      description: description,
      additiveTypeCode: additiveType.code,
      isDry,
      targetOrganismClassId: targetOrganismClass && targetOrganismClass.id,
      growthMediaSelectionMethods
    };
    newAdditiveMaterials.push(additiveMaterial);
  }

  return newAdditiveMaterials;
}
