/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import classNames from "classnames";
import { compose } from "redux";
import {
  Menu,
  MenuItem,
  Popover,
  Position,
  Intent,
  Button,
  Classes,
  Icon
} from "@blueprintjs/core";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { TextareaField } from "@teselagen/ui";
import { parse } from "qs";
import AvatarIcon from "../../AvatarIcon";
import AddCommentReplyContainer from "../AddCommentReplyContainer";
import {
  transformCommentMessageWithMentions,
  getInitialMentions
} from "../utils";
import MentionField from "../MentionField";
import commentFragment from "../commentFragment";
import "./style.css";
import { safeDelete, safeUpsert } from "../../apolloMethods";
import { formatDateTime } from "../../utils/dateUtils";

export const COMMENT_REPLY_MARGIN_LEFT = 42;

class Comment extends Component {
  constructor(props) {
    super(props);
    const { location, comment = {} } = this.props;
    const { commentId } = parse(location.hash.replace(/^#/, ""));

    if (commentId === comment.id) {
      this.matchesHash = true;
    }
  }

  componentDidMount() {
    const commentList = document.getElementById("tg-comment-list");
    if (commentList && this.commentDiv) {
      this.commentDiv.scrollIntoView({ block: "center" });
      const appbody = document.getElementById("app-body");
      const card = commentList.closest(".tg-card");
      if (card && appbody) {
        card.scrollIntoView();
      }
    }
  }

  toggleEditor = () => {
    const { comment, toggleEdit, editingCommentId } = this.props;
    if (editingCommentId) {
      return window.toastr.warning("Please finish editing other comment");
    }
    toggleEdit(comment);
  };

  cancelEditor = () => {
    this.props.reset();
    this.props.toggleEdit();
  };

  onSubmitEdit = async values => {
    const {
      comment,
      afterEdit,
      withMentions,
      handleMentionsSubmit
    } = this.props;
    const newStringValue = withMentions
      ? values.message.stringValue
      : values.message;
    const data = { message: newStringValue, id: comment.id };
    try {
      if (comment.message === newStringValue) {
        // no edit
        this.cancelEditor();
        return;
      }
      const [editedComment] = await safeUpsert(commentFragment, data);
      if (afterEdit) afterEdit(editedComment);
      if (withMentions) {
        handleMentionsSubmit(editedComment.id, newStringValue);
      }
      this.cancelEditor();
    } catch (error) {
      window.toastr.error("Error updating comment.");
      console.error("err:", error);
    }
  };

  renderEditor() {
    const {
      handleSubmit,
      comment,
      client,
      withMentions = true,
      submitting
    } = this.props;

    let textAreaToUse;
    if (withMentions) {
      textAreaToUse = (
        <MentionField
          client={client}
          disabled={submitting}
          defaultValue={{
            stringValue: comment.message,
            mentions: getInitialMentions(comment.message)
          }}
          name="message"
          data-test="tg-edit-message"
        />
      );
    } else {
      textAreaToUse = (
        <TextareaField
          name="message"
          defaultValue={comment.message}
          data-test="tg-edit-message"
        />
      );
    }

    return (
      <form className="comment-edit-form">
        {textAreaToUse}
        <div className="comment-button-container">
          <Button
            intent={Intent.DANGER}
            text="Cancel"
            className={Classes.SMALL + " " + Classes.MINIMAL}
            onClick={this.cancelEditor}
          />
          <Button
            data-test="tg-save-comment"
            intent={Intent.SUCCESS}
            loading={submitting}
            text="Save"
            className={Classes.SMALL + " " + Classes.MINIMAL}
            onClick={handleSubmit(this.onSubmitEdit)}
          />
        </div>
      </form>
    );
  }

  handleDelete = () => {
    const {
      comment: { id },
      afterDelete
    } = this.props;
    return safeDelete("comment", id).then(() => {
      afterDelete(id);
    });
  };

  render() {
    const {
      editingCommentId,
      customerRequestId,
      currentUser,
      isReplying,
      startReply,
      client,
      disableDelete,
      cancelReply,
      afterReply,
      isReply,
      userIdToReplyTo,
      parentId,
      withMentions = true,
      handleMentionsSubmit,
      comment: { id, message, headerMsg, icon, createdAt, updatedAt, user }
    } = this.props;

    const isEditing = id === editingCommentId;

    const commentMessageWithMentions = transformCommentMessageWithMentions(
      message
    );

    const commentMenu = (
      <Menu className="comment-edit-menu">
        <MenuItem
          data-test={`${isReply ? "reply" : "comment"}-menu-item-edit`}
          text="Edit"
          onClick={this.toggleEditor}
        />
        {!disableDelete && (
          <MenuItem
            data-test={`${isReply ? "reply" : "comment"}-menu-item-delete`}
            text="Delete"
            onClick={this.handleDelete}
          />
        )}
      </Menu>
    );

    const wasCreatedInLast2Seconds = new Date() - new Date(updatedAt) < 2000;

    let matchesHashStyling;
    if (this.matchesHash) {
      matchesHashStyling = {
        boxShadow: "0 0 0 2px rgba(49, 120, 193, .3)",
        border: "1px solid #137CBD"
      };
    }
    return (
      <React.Fragment>
        <div
          className={classNames("comment-bubble-container", {
            "fade-out": wasCreatedInLast2Seconds,
            reply: isReply
          })}
          style={{
            marginLeft: isReply ? COMMENT_REPLY_MARGIN_LEFT : 0,
            ...matchesHashStyling
          }}
          {...(this.matchesHash && {
            ref: ref => (this.commentDiv = ref)
          })}
        >
          <div className="comment-avatar">
            <AvatarIcon
              size={isReply ? 28 : 36}
              style={{ border: "2px solid white" }}
              user={user}
            />
          </div>
          {isEditing ? (
            this.renderEditor()
          ) : (
            <div style={{ width: "90%" }}>
              <div
                data-test={`${isReply ? "reply" : "comment"}-bubble-header`}
                className="comment-bubble-header"
              >
                <div
                  data-test={`${isReply ? "reply" : "comment"}-bubble-username`}
                  className="comment-bubble-username"
                >
                  {user.username}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    data-test={`${isReply ? "reply" : "comment"}-bubble-date`}
                    className="comment-bubble-date"
                  >
                    {formatDateTime(createdAt)}
                    {createdAt !== updatedAt && ` (edited)`}
                  </div>
                  {user.id === currentUser.id && !isEditing && (
                    <div
                      data-test={`${isReply ? "reply" : "comment"}-edit-menu`}
                      className="comment-edit-menu"
                    >
                      <Popover content={commentMenu} position={Position.LEFT}>
                        <Button small className={Classes.MINIMAL} icon="more" />
                      </Popover>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {icon && (
                  <div
                    data-test={`"comment-bubble-icon`}
                    className="comment-bubble-icon"
                  >
                    <Icon icon={icon}></Icon> &nbsp;
                  </div>
                )}
                {headerMsg && (
                  <div
                    data-test={`"comment-bubble-headerMsg`}
                    className="comment-bubble-headerMsg"
                  >
                    {headerMsg}
                  </div>
                )}
              </div>

              <div
                data-test={`${isReply ? "reply" : "comment"}-bubble-message`}
                className="comment-bubble-message"
              >
                {commentMessageWithMentions}
              </div>
              <div
                data-test={`${
                  isReply ? "reply" : "comment"
                }-bubble-reply-button`}
                className="comment-bubble-reply-button"
                onClick={startReply}
              >
                Reply
              </div>
            </div>
          )}
        </div>
        {isReplying && (
          <div
            className="comment-bubble-container reply"
            style={{ marginLeft: COMMENT_REPLY_MARGIN_LEFT }}
          >
            <AddCommentReplyContainer
              {...{
                client,
                withMentions,
                afterCreate: afterReply,
                cancelReply,
                currentUser,
                isReplyForm: true,
                customerRequestId,
                handleMentionsSubmit,
                placeholder: "Enter reply...",
                userIdToReplyTo,
                commentId: parentId || id
              }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: "editCommentForm",
    destroyOnUnmount: true,
    enableReinitialize: true
  })
)(Comment);
