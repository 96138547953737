/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { formatDate } from "./dateUtils";
import { Colors } from "@blueprintjs/core";

export default function renderExpirationDate(date) {
  if (!date) return "N/A";
  if (date && new Date(date) < new Date()) {
    return <span style={{ color: Colors.RED4 }}>{formatDate(date)}</span>;
  } else {
    return formatDate(date);
  }
}
