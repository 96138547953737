/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, pick, keyBy, isNumber } from "lodash";
import gql from "graphql-tag";
import Big from "big.js";
import {
  isDry,
  getMassInGrams,
  getVolumeInLiters
} from "../../../tg-iso-lims/src/utils/unitUtils/getDimensionsOfMixture";
import {
  convertMassBig,
  convertVolumeBig,
  defaultVolumetricUnitCode,
  defaultMassUnitCode,
  getDimensionsOfMixture,
  defaultConcentrationUnitCode,
  convertMolarityBig,
  calculateMolarityFromConcentration,
  standardizeVolume
} from "../../../tg-iso-lims/src/utils/unitUtils";
import {
  getCombinabilityKey,
  processDimensions
} from "../../../tg-iso-lims/src/AliquotFormulation/executeAliquotFormulations/executeServerAliquotFormulation/addDestinationAdditives";
import aliquotAdditiveFragment from "../graphql/fragments/aliquotAdditiveFragment";
import {
  getNewConcentrationForHydratedAliquot,
  getAliquotMolecularWeight,
  getNewCellConcentrationForHydratedAliquot
} from "../../../tg-iso-lims/src/utils/aliquotUtils";
import { isoContext } from "@teselagen/utils";
import SimpleWriteBuffer from "../../../tg-iso-lims/src/SimpleWriteBuffer";
import { containerArrayRecordViewAliquotContainerFragment } from "../graphql/fragments/containerArrayRecordViewFragment.gql";
import { safeUpsert, safeQuery } from "../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { aliquotRecordAdditiveFragment } from "../../src-shared/graphql/fragments/aliquotRecordFragment";

export const additiveMixingFragment = gql`
  fragment additiveMixingFragment on additive {
    id
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    molarity
    molarityUnitCode
    materialConcentration
    materialConcentrationUnitCode
    additiveMaterialId
    lotId
    lot {
      id
      name
      additiveMaterialId
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      molarity
      molarityUnitCode
      materialConcentration
      materialConcentrationUnitCode
      mass
      massUnitCode
    }
  }
`;

/**
 * The input to `updateAliquotsWithReagents` is cumbersome to work with it do to its format.
 * This creates a much nicer array to work with.
 * @param {Array<Object>} arg.reagents Array of additive materials. Only needs the `id` key
 * @param {Map<string, Object>} arg.reagentInfo
 * @returns {Array<Object>}
 */
function getReagentsArray(reagents, reagentInfo) {
  return reagents.map(r => {
    const ri = reagentInfo["id" + r.id];
    return {
      id: r.id,
      ...ri,
      __typename: ri.__typename || "additive",
      // These fields give reagents the same structure as additives on aliquots. This simplifies
      // some code.
      additiveMaterialId: r.id,
      lotId: get(ri, "lot.id"),
      // This enables us distinguish reagents from additives on aliquots.
      isReagent: true
    };
  });
}

/**
 * If the given lot has enough volume to extract all of the reagent we need, then return an object
 * representing the update for the lot. Otherwise return null.
 * @param {Array<Object>} aliquots
 * @param {Object} reagent
 * @returns {Object | null}
 */
function extractFromLot(numberOfItemsToExtractTo, reagent) {
  const { lot } = reagent;
  if (!lot) throw new Error("Reagent does not have a lot.");
  if (isDry(reagent) !== isDry(lot))
    throw new Error("Both reagents and lots must be either dry or wet.");

  if (isDry(reagent)) {
    const existingMass = getMassInGrams(lot);
    const massToExtract = getMassInGrams(reagent).times(
      numberOfItemsToExtractTo
    );
    const newMass = existingMass.minus(massToExtract);
    if (newMass.lt(0)) {
      return null;
    } else {
      return {
        id: lot.id,
        mass: convertMassBig(newMass, "g", lot.massUnitCode).toString()
      };
    }
  } else {
    const existingVolume = getVolumeInLiters(lot);
    const volumeToExtract = getVolumeInLiters(reagent).times(
      numberOfItemsToExtractTo
    );
    const newVolume = existingVolume.minus(volumeToExtract);
    if (newVolume.lt(0)) {
      return null;
    } else {
      return {
        id: lot.id,
        volume: convertVolumeBig(
          newVolume,
          "L",
          lot.volumetricUnitCode
        ).toString()
      };
    }
  }
}

/**
 * Get the max aliquot volume of the aliquot in the given units.
 * @param {Object} aliquot
 * @param {string} units The volumetric unit code to get the results in.
 * @returns {Object | null}
 */
function getMaxAliquotVolume(aliquot, units) {
  const act = aliquot.aliquotContainer.aliquotContainerType;
  return convertVolumeBig(
    act.maxVolume,
    act.volumetricUnitCode,
    units
  ).toString();
}

/**
 * See if all of the reagents can fit into the aliquot's container. If not, return null.
 * Otherwise return an object that can be passed to upsert to update the aliquot's volume.
 * @param {Object} aliquot
 * @param {Array<Object} reagents
 */
function updateAliquot(aliquot, reagents) {
  const volumetricUnitCode =
    aliquot.volumetricUnitCode || defaultVolumetricUnitCode;
  const massUnitCode = aliquot.massUnitCode || defaultMassUnitCode;
  const concentrationUnitCode =
    aliquot.concentrationUnitCode || defaultConcentrationUnitCode;

  // We have to ignore empty aliquots as this might throw off the logic in getDimensions of mixture.
  // This won't affect the rest of the code.
  const itemsToMix =
    (!aliquot.isDry && !aliquot.volume) || (aliquot.isDry && !aliquot.mass)
      ? reagents
      : [aliquot, ...reagents];

  // Figure out the dimensions, including the volume, of the mixture of the
  // aliquot and the reagents.
  const { volume: newVolume } = getDimensionsOfMixture({
    mixture: itemsToMix,
    volumetricUnitCode,
    massUnitCode,
    concentrationUnitCode
  });
  let maxAliquotVolume;
  if (aliquot.aliquotContainer) {
    maxAliquotVolume = getMaxAliquotVolume(aliquot, volumetricUnitCode);
  }
  if (maxAliquotVolume && new Big(newVolume || 0).gt(maxAliquotVolume)) {
    return null;
  } else {
    let concentrationAndMolarityUpdates = {};
    let cellConcentrationUpdates = {};

    if (!new Big(newVolume).eq(aliquot.volume || 0) && aliquot.concentration) {
      const newConcentration = new Big(aliquot.volume)
        .div(newVolume)
        .times(aliquot.concentration);

      const aliquotMolecularWeight = getAliquotMolecularWeight(aliquot);
      let newMolarity;
      const molarityUnitCode = aliquot.molarityUnitCode || "nM";
      if (aliquotMolecularWeight) {
        newMolarity = Number(
          convertMolarityBig(
            calculateMolarityFromConcentration(
              newConcentration,
              aliquot.concentrationUnitCode,
              aliquotMolecularWeight
            ),
            "M",
            molarityUnitCode
          )
        ).toString();
      } else {
        newMolarity = null;
      }
      concentrationAndMolarityUpdates = {
        concentration: newConcentration,
        molarity: newMolarity,
        molarityUnitCode
      };
    } else if (aliquot.isDry && newVolume && Number(newVolume.toString())) {
      concentrationAndMolarityUpdates = getNewConcentrationForHydratedAliquot(
        aliquot,
        newVolume,
        volumetricUnitCode
      );
    }

    if (
      !new Big(newVolume).eq(aliquot.volume || 0) &&
      aliquot.cellConcentration
    ) {
      const newCellConcentration = new Big(aliquot.volume)
        .div(newVolume)
        .times(aliquot.cellConcentration);

      cellConcentrationUpdates = {
        cellConcentration: newCellConcentration
      };
    } else if (aliquot.isDry && newVolume && Number(newVolume.toString())) {
      cellConcentrationUpdates = getNewCellConcentrationForHydratedAliquot(
        aliquot,
        newVolume,
        volumetricUnitCode
      );
    }
    return {
      id: aliquot.id,
      volume: newVolume.toString(),
      volumetricUnitCode,
      isDry: !newVolume,
      ...concentrationAndMolarityUpdates,
      ...cellConcentrationUpdates
    };
  }
}

/**
 * See if all of the reagents can fit into the aliquot container. If not, return null.
 * @param {Object} aliquot
 * @param {Array<Object} reagents
 */
function canAliquotContainerFitReagents(aliquotContainer, reagents) {
  // Figure out the dimensions, including the volume, of the mixture of the
  // aliquot and the reagents.
  let mixture = reagents;
  if (aliquotContainer.additives) {
    mixture = [...aliquotContainer.additives, ...reagents];
  }
  const { volume: newVolume } = getDimensionsOfMixture({
    mixture,
    volumetricUnitCode: defaultVolumetricUnitCode,
    massUnitCode: defaultMassUnitCode,
    concentrationUnitCode: defaultConcentrationUnitCode
  });
  const maxAliquotContainerVolume = getMaxAliquotVolume(
    { aliquotContainer },
    defaultVolumetricUnitCode
  );
  if (new Big(newVolume || 0).gt(maxAliquotContainerVolume)) {
    return false;
  } else {
    return true;
  }
}

/**
 * Return an object with `creates` and `updates` as keys. Reagents are added to
 * the creates array if the aliquot doesn't have an existing additive with the same
 * additive material id and lot id. Otherwise, the exisitng additive is updated
 * via the updates array.
 * @param {Object} aliquot
 * @param {Array<Object>} reagents
 */
function getAdditiveUpserts(aliquot, aliquotContainer, reagents) {
  const item = aliquot || aliquotContainer;
  const fullMixture = [...item.additives, ...reagents];
  const creates = [];
  const updates = [];

  // Get the groups of additives already on the aliquots and added reagents
  // that can be mixed together.
  const mixableGroups = {};
  for (const a of fullMixture) {
    const key = getCombinabilityKey(a);
    if (!mixableGroups[key]) mixableGroups[key] = [];
    mixableGroups[key].push(a);
  }
  for (const group of Object.values(mixableGroups)) {
    const reagent = group.find(a => a.isReagent);
    const additive = group.find(a => !a.isReagent);

    // We won't have the reagent in the case of existing additives that
    // we are not updating.
    if (!reagent) continue;
    const dims = getDimensionsOfMixture({
      mixture: group,
      concentrationUnitCode: reagent.concentrationUnitCode,
      volumetricUnitCode: reagent.volumetricUnitCode,
      massUnitCode: reagent.massUnitCode,
      molarityUnitCode: reagent.molarityUnitCode
    });

    const coreUpsertValue = {
      ...processDimensions(dims),
      additiveMaterialId: reagent.additiveMaterialId,
      lotId: reagent.lotId
    };
    if (aliquot) {
      coreUpsertValue.aliquotId = aliquot.id;
    } else {
      coreUpsertValue.aliquotContainerId = aliquotContainer.id;
    }

    if (additive) {
      updates.push({ id: additive.id, ...coreUpsertValue });
    } else {
      creates.push(coreUpsertValue);
    }
  }
  return {
    creates,
    updates
  };
}

/**
 * Given an some information about the reagents we want to add to some aliquots,
 * see if we can add the reagents to the aliquots. If so, update the volumes of the
 * aliquots, update the volumes of the lots, and add/update the associated additives fields.
 * @param {Object} arg
 * @param {Array<Object>} arg.aliquots The destination aliquots. Has to have a lot of information associated with it.
 * @param {Array<Object>} arg.aliquotContainers The destination aliquotContainers. Has to have a lot of information associated with it.
 * @param {Array<Object>} arg.reagents Array of additive materials. Only needs the `id` key
 * @param {Map<string, Object>} arg.reagentInfo From keys of form `id${id}` to info about dimensions we want to add for each reagent and their lot.
 */
export async function updateAliquotsWithReagents(
  {
    aliquots = [],
    aliquotContainers = [],
    changeAdditiveComposition,
    reagents,
    reagentInfo
  },
  ctx = isoContext
) {
  const { safeQuery, safeUpsert } = ctx;
  const additiveCreates = [];
  const additiveUpdates = [];
  const aliquotUpdates = [];
  const lotUpdates = [];
  // Each reagent should appear at most once in this array.
  const reagentsArray = getReagentsArray(reagents, reagentInfo);
  // changeAdditiveComposition is used to adjust the reagents volume without affecting the total aliquot volume

  // See if the lots have enough volume/mass to fill the destination wells. If so,
  // update the volumes/mass of the lots.
  for (const r of reagentsArray) {
    if (changeAdditiveComposition) continue;
    if (r.lot) {
      const lotUpdate = extractFromLot(
        aliquots.length + aliquotContainers.length,
        r
      );
      if (lotUpdate) {
        lotUpdates.push(lotUpdate);
      } else {
        return "There is not enough of the lot remaining to fill all destination plate wells.";
      }
    }
  }

  const getSpaceError = (aliquotContainer, plural) => {
    const isTube = get(aliquotContainer, "aliquotContainerType.isTube");
    if (aliquotContainer && isNumber(aliquotContainer.rowPosition)) {
      return `Not enough space for additives in destination ${
        isTube ? "tube" : "plate well"
      } at ${getAliquotContainerLocation(aliquotContainer)}`;
    }
    return `Not enough space for additives in destination ${
      isTube ? "tube" : "plate well"
    }${plural ? "s" : ""}.`;
  };

  // Update the aliquots' volumes and create/update the associated additives.
  for (const aliquot of aliquots) {
    // Update the aliquot's volume if we can, otherwise return an error.
    const aliquotUpdate = updateAliquot(aliquot, reagentsArray);
    if (!aliquotUpdate) {
      return getSpaceError(aliquot.aliquotContainer, aliquots.length > 1);
    }
    if (!changeAdditiveComposition) {
      aliquotUpdates.push(aliquotUpdate);
    } else {
      const { volume: newVolumeOfAllReagents } = getDimensionsOfMixture({
        mixture: [...reagentsArray, ...aliquot.additives],
        volumetricUnitCode: defaultVolumetricUnitCode,
        massUnitCode: defaultMassUnitCode,
        concentrationUnitCode: defaultConcentrationUnitCode
      });
      const aliquotVolume = convertVolumeBig(
        aliquot.volume || 0,
        aliquot.volumetricUnitCode || defaultVolumetricUnitCode,
        defaultVolumetricUnitCode
      );
      if (!aliquot.volume || aliquotVolume.lt(newVolumeOfAllReagents)) {
        return "Additives' volume would be greater than aliquot's.";
      }
    }
    // Attach/update additives on the aliquot.
    const { creates, updates } = getAdditiveUpserts(
      aliquot,
      null,
      reagentsArray
    );
    additiveCreates.push(...creates);
    additiveUpdates.push(...updates);
  }
  // Update the aliquot containers' volumes and create/update the associated additives.
  for (const aliquotContainer of aliquotContainers) {
    // Update the aliquot's volume if we can, otherwise return an error.
    if (!canAliquotContainerFitReagents(aliquotContainer, reagentsArray)) {
      return getSpaceError(aliquotContainer, aliquotContainers.length > 1);
    }

    // Attach/update additives on the aliquot.
    const { creates, updates } = getAdditiveUpserts(
      null,
      aliquotContainer,
      reagentsArray
    );
    additiveCreates.push(...creates);
    additiveUpdates.push(...updates);
  }
  await safeUpsert("additive", additiveCreates, { pageSize: 200 });
  await safeUpsert("additive", additiveUpdates, { pageSize: 200 });
  await safeUpsert(aliquotAdditiveFragment, aliquotUpdates, {
    pageSize: 200
  });
  const aliquotIds = aliquotUpdates.map(a => a.id);
  await safeQuery(aliquotRecordAdditiveFragment, {
    variables: {
      filter: {
        id: aliquotIds
      }
    }
  });
  await safeUpsert("lot", lotUpdates);
}

/**
 * When assigning an aliquot to a aliquot container than has reagents
 * we need to move those reagents onto the aliquot. This is a helper function
 * which will prep the data necessary for updateAliquotsWithReagents.
 * It will also handle removing the additives from the aliquot container.
 */
export async function addAliquotToAliquotContainerWithReagents(
  _aliquot,
  aliquotContainer,
  ctx = isoContext
) {
  const { safeDelete } = ctx;
  // add aliquot container for max volume checking
  const aliquot = {
    ..._aliquot,
    aliquotContainer
  };
  const reagents = [];
  const reagentInfo = {};

  aliquotContainer.additives.forEach(additive => {
    const additiveMaterialId = additive.lot
      ? additive.lot.additiveMaterialId
      : additive.additiveMaterialId;
    const lots = [];
    if (additive.lot) {
      lots.push(additive.lot);
    }
    reagents.push({
      id: additiveMaterialId,
      lots
    });
    reagentInfo["id" + additiveMaterialId] = {
      lot: additive.lot,
      ...pick(additive, [
        "volume",
        "volumetricUnitCode",
        "concentration",
        "concentrationUnitCode",
        "mass",
        "massUnitCode",
        "molarity",
        "molarityUnitCode"
      ])
    };
  });
  const result = await updateAliquotsWithReagents(
    {
      aliquots: [aliquot],
      reagents,
      reagentInfo
    },
    ctx
  );
  await safeDelete(
    "additive",
    aliquotContainer.additives.map(a => a.id)
  );
  return result;
}

const addAliquotsToAliquotContainerContainerFragment = gql`
  fragment addAliquotsToAliquotContainerContainerFragment on aliquotContainer {
    id
    rowPosition
    columnPosition
    aliquotContainerType {
      code
      maxVolume
      volumetricUnitCode
    }
    additives {
      ...additiveMixingFragment
    }
  }
  ${additiveMixingFragment}
`;

const addAliquotsToAliquotContainerAliquotFragment = gql`
  fragment addAliquotsToAliquotContainerAliquotFragment on aliquot {
    id
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    mass
    massUnitCode
    cellConcentration
    cellConcentrationUnitCode
    cellCount
    isDry
    additives {
      ...additiveMixingFragment
    }
  }
  ${additiveMixingFragment}
`;

export async function addAliquotsToAliquotContainers(
  sortedAliquotContainers,
  aliquotsToAssign
) {
  const aliquotContainerUpdates = [];

  // use a write buffer so that we can backtrack if some wells do not have enough space for additives
  const writeBuffer = new SimpleWriteBuffer();
  const fullAliquotContainers = await safeQuery(
    addAliquotsToAliquotContainerContainerFragment,
    {
      variables: {
        filter: {
          id: sortedAliquotContainers.map(ac => ac.id)
        }
      }
    }
  );
  const fullAliquots = await safeQuery(
    addAliquotsToAliquotContainerAliquotFragment,
    {
      variables: {
        filter: {
          id: aliquotsToAssign.map(a => a.id)
        }
      }
    }
  );
  const keyedFullAliquotContainers = keyBy(fullAliquotContainers, "id");
  const keyedFullAliquots = keyBy(fullAliquots, "id");
  const assignedAliquots = [];
  const aliquotContainersGettingUpdates = {};

  for (const [index, aliquotContainer] of sortedAliquotContainers.entries()) {
    const aliquotToAssignHere = aliquotsToAssign[index];
    if (!aliquotToAssignHere) {
      break;
    }
    assignedAliquots.push(aliquotToAssignHere);
    aliquotContainerUpdates.push({
      id: aliquotContainer.id,
      aliquotId: aliquotToAssignHere.id
    });
    aliquotContainersGettingUpdates[aliquotContainer.id] = true;

    const fullAliquotContainer =
      keyedFullAliquotContainers[aliquotContainer.id];
    const fullAliquot = keyedFullAliquots[aliquotToAssignHere.id];
    const aliquotContainerType = fullAliquotContainer.aliquotContainerType;
    if (
      !fullAliquot.isDry &&
      standardizeVolume(fullAliquot.volume, fullAliquot.volumetricUnitCode) >
        standardizeVolume(
          aliquotContainerType.maxVolume,
          aliquotContainerType.volumetricUnitCode
        )
    ) {
      return window.toastr.error(`
        Cannot place aliquot into location ${getAliquotContainerLocation(
          fullAliquotContainer
        )} because the
        aliquot's volume of ${fullAliquot.volume} ${
          fullAliquot.volumetricUnitCode
        } \
      is greater than the wells max volume (${aliquotContainerType.maxVolume} ${
        aliquotContainerType.volumetricUnitCode
      }).`);
    }

    if (fullAliquotContainer.additives.length) {
      const error = await addAliquotToAliquotContainerWithReagents(
        fullAliquot,
        fullAliquotContainer,
        {
          ...isoContext,
          ...writeBuffer
        }
      );
      if (error) {
        throw new Error(error);
      }
    }
  }

  // because we could be moving an aliquot from one selected well to another we don't
  // want to accidentally clear that other well
  assignedAliquots.forEach(aliquot => {
    if (
      aliquot.aliquotContainer &&
      !aliquotContainersGettingUpdates[aliquot.aliquotContainer.id]
    ) {
      aliquotContainerUpdates.push({
        id: aliquot.aliquotContainer.id,
        aliquotId: null
      });
      aliquotContainersGettingUpdates[aliquot.aliquotContainer.id] = true;
    }
  });
  await writeBuffer.flush(isoContext, [
    "additive",
    "aliquot",
    "aliquotContainer",
    "lot"
  ]);
  await safeUpsert(
    containerArrayRecordViewAliquotContainerFragment,
    aliquotContainerUpdates
  );
}
