/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { ChangeSetsHelper } from "../../../../../../tg-iso-design/utils/designEditUtils";
import {
  getOutputtingReactionOfCard,
  getInputCardsOfReaction,
  getBinIdsInCard,
  getItemOfType,
  getBinsInCard,
  getItemIdByCid,
  getDesign,
  getRootCardId,
  isCardRoot,
  isCardCircular
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";

import {
  flattenTree,
  createObjectValuedTypeMap,
  transformWithNewIds,
  replaceReferences
} from "../../../../../../tg-iso-design/utils/designStateUtils";
import changeDesignLayoutType from "../changeDesignLayoutType";
import { getJunctionOnCard } from "../../../../../../tg-iso-design/selectors/junctionSelectors";
import removeReaction from "../removeReaction";
import defaultJ5OutputNamingTemplateMap from "../../../../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import shortid from "shortid";

const TYPES_TO_TRANSFORM_IDS = [
  "design",
  "reaction",
  "element",
  "binRuleSet",
  "fas",
  "eugeneRule",
  "reactionJ5OutputNamingTemplate",
  "j5OutputNamingTemplate",
  "eugeneRuleElement",
  "elementCombo",
  "elementElementCombo",
  "elementGroupCombo",
  "elementGroupElementGroupCombo",
  "bin",
  "card",
  "binCard",
  "junction",
  "customJ5Parameter"
];

const createTemplateState = fullTemplate => {
  const flattened = flattenTree(fullTemplate);
  const oldIdToNewIdMap = createObjectValuedTypeMap();

  for (const type of TYPES_TO_TRANSFORM_IDS) {
    for (const id of Object.keys(flattened[type])) {
      oldIdToNewIdMap[type][id] = shortid();
    }
  }

  return transformWithNewIds(flattened, oldIdToNewIdMap);
};

const binIdToCard = (binId, inputReactionId) => ({
  id: shortid(),
  __typename: "card",
  direction: true,
  inputReactionId,
  binCards: [
    {
      id: shortid(),
      __typename: "binCard",
      binId,
      index: 0
    }
  ]
});

const isBinPlaceholder = s => s.isPlaceholder;

export default (state, { payload: { cardId, binIds, fullTemplate } }) => {
  const fakeFullState = { design: state };
  const changeSetsHelper = new ChangeSetsHelper(state);

  const cardBinIds = getBinIdsInCard(fakeFullState, cardId);

  // Sort the bin ids by their position in the selected card.
  binIds.sort((a, b) => cardBinIds.indexOf(a) - cardBinIds.indexOf(b));

  let templateState = createTemplateState(fullTemplate);
  let fullTemplateState = { design: templateState };
  // const originalFullTemplateState = fullTemplateState

  const templateHeadId = getRootCardId(fullTemplateState);
  const placeholderBins = getBinsInCard(
    fullTemplateState,
    templateHeadId
  ).filter(isBinPlaceholder);

  if (placeholderBins.length !== binIds.length) {
    window.toastr.error(
      `Number of selected bins must match number of placeholders in template head (${placeholderBins.length}).`
    );
    return state;
  }

  // Make sure that the design has enough rows to hold the contents
  // of the template.
  const design = getDesign(fakeFullState);
  const templateDesign = getDesign(fullTemplateState);
  changeSetsHelper.updatePure("design", {
    id: design.id,
    numRows: Math.max(design.numRows, templateDesign.numRows)
  });

  // Replace the placeholder bins with the selected bins.
  const placeholderIdToReplacementSetId = placeholderBins.reduce(
    (acc, placeholderSet, i) => {
      acc.bin[placeholderSet.id] = binIds[i];
      return acc;
    },
    { bin: {} }
  );
  templateState = replaceReferences(
    templateState,
    placeholderIdToReplacementSetId
  );
  fullTemplateState = { design: templateState };

  // Add the modified template to the store.
  changeSetsHelper.updateViaFlatObject({
    ...templateState,
    design: {}
  });

  // Remove the designation of the root card as root in the
  // template state.
  changeSetsHelper.updatePure("card", {
    id: templateHeadId,
    isRoot: false
  });

  const outputtingReaction = getOutputtingReactionOfCard(fakeFullState, cardId);

  // See if we are applying the template to the entire card
  // or a subset of its sets.
  if (binIds.length === cardBinIds.length) {
    // Essentially, we replace the card with the template.
    if (outputtingReaction)
      removeReaction(state, changeSetsHelper, outputtingReaction.id);

    if (isCardRoot(fakeFullState, cardId)) {
      changeSetsHelper.updatePure("card", {
        id: cardId,
        isRoot: false
      });
      changeSetsHelper.updatePure("card", {
        id: templateHeadId,
        isRoot: true
      });
    } else {
      const card = getItemOfType(fakeFullState, "card", cardId);
      changeSetsHelper.deletePure("card", cardId);
      changeSetsHelper.updatePure("card", {
        id: templateHeadId,
        isRoot: false,
        inputReactionId: card.inputReactionId,
        inputIndex: card.inputIndex
      });

      for (const fivePrimeEnd of [true, false]) {
        const junction = getJunctionOnCard(fakeFullState, cardId, fivePrimeEnd);
        if (junction) {
          const cardKey = fivePrimeEnd ? "threePrimeCardId" : "fivePrimeCardId";
          changeSetsHelper.updatePure("junction", {
            id: junction.id,
            [cardKey]: templateHeadId
          });
        }
      }
    }
  } else {
    if (outputtingReaction) {
      const childCards = getInputCardsOfReaction(
        fakeFullState,
        outputtingReaction.id
      );

      const childCardsToReplace = childCards.filter(childCard => {
        const binIdsInChildCard = getBinIdsInCard(fakeFullState, childCard.id);
        return (
          binIdsInChildCard.length === 1 &&
          binIds.includes(binIdsInChildCard[0])
        );
      });

      if (childCardsToReplace.length !== binIds.length) {
        window.toastr.error(
          "You cannot apply a design template to selected bins that are present in non-trivial child cards of the selected card."
        );
        return state;
      }

      // We can do this as we know `getInputCardsOfReaction` is sorted by inputIndex.
      const minInputIndex = childCardsToReplace[0].inputIndex;
      const maxInputIndex =
        childCardsToReplace[childCardsToReplace.length - 1].inputIndex;

      childCards.forEach(c => {
        const { inputIndex } = c;
        if (minInputIndex <= inputIndex && inputIndex <= maxInputIndex) {
          changeSetsHelper.deletePure("card", c.id);
        } else if (inputIndex > maxInputIndex) {
          changeSetsHelper.updatePure("card", {
            id: c.id,
            inputIndex: inputIndex - (maxInputIndex - minInputIndex)
          });
        }
      });

      changeSetsHelper.updatePure("card", {
        id: templateHeadId,
        inputIndex: minInputIndex,
        inputReactionId: outputtingReaction.id
      });

      // Do the junctions
      for (let i = 1, ii = childCardsToReplace.length; i < ii; i++) {
        const junction = getJunctionOnCard(
          fakeFullState,
          childCardsToReplace[i],
          true
        );
        if (junction) {
          changeSetsHelper.deletePure("junction", junction.id);
        }
      }

      // TODO: This code will have issues with ADAPTER overhangs as their interior
      // and boundary bins are different.
      const junc5p = getJunctionOnCard(
        fakeFullState,
        childCardsToReplace[0].id,
        true
      );
      if (junc5p) {
        changeSetsHelper.updatePure("junction", {
          id: junc5p.id,
          threePrimeCardId: templateHeadId,
          threePrimeCardInteriorBinId: binIds[0],
          threePrimeCardStartBinId: binIds[0]
        });
      }

      const junc3p = getJunctionOnCard(
        fakeFullState,
        childCardsToReplace[childCardsToReplace.length - 1].id,
        false
      );
      if (junc3p) {
        changeSetsHelper.updatePure("junction", {
          id: junc3p.id,
          fivePrimeCardId: templateHeadId,
          fivePrimeCardInteriorBinId: binIds[binIds.length - 1],
          fivePrimeCardEndBinId: binIds[binIds.length - 1]
        });
      }
    } else {
      const defaultJ5ParameterId = getItemIdByCid(
        fakeFullState,
        "customJ5Parameter",
        "default"
      );
      const mockAssemblyMethodId = getItemIdByCid(
        fakeFullState,
        "assemblyMethod",
        "mock"
      );

      // We can do this because the cardBinIds are sorted.
      const minChildIndex = cardBinIds.indexOf(binIds[0]);
      const maxChildIndex = cardBinIds.indexOf(binIds[binIds.length - 1]);

      const reactionInput = {
        id: shortid(),
        __typename: "reaction",
        name: "Mock Assembly Reaction",
        isOutputCircular: !!isCardCircular(fakeFullState, cardId),
        assemblyMethodId: mockAssemblyMethodId,
        restrictionEnzymeId: null,
        customJ5ParameterId: defaultJ5ParameterId,
        reactionJ5OutputNamingTemplates: Object.values(
          defaultJ5OutputNamingTemplateMap
        ).map(template => ({
          id: shortid(),
          __typename: "reactionJ5OutputNamingTemplate",
          j5OutputNamingTemplate: {
            id: shortid(),
            __typename: "j5OutputNamingTemplate",
            ...template
          }
        }))
      };

      changeSetsHelper.updateViaFlatObject(flattenTree(reactionInput));
      changeSetsHelper.updatePure("card", {
        id: cardId,
        outputReactionId: reactionInput.id
      });
      changeSetsHelper.updatePure("card", {
        id: templateHeadId,
        inputReactionId: reactionInput.id,
        inputIndex: minChildIndex
      });

      const childrenInfo = [];
      childrenInfo[minChildIndex] = { cardId: templateHeadId };

      cardBinIds.forEach((binId, i) => {
        const common = binIdToCard(binId, reactionInput.id);
        const info = {
          cardId: common.id,
          binId
        };
        if (i < minChildIndex) {
          childrenInfo[i] = info;
          changeSetsHelper.updateViaFlatObject(
            flattenTree({
              ...common,
              inputIndex: i
            })
          );
        } else if (i > maxChildIndex) {
          const inputIndex = i - (maxChildIndex - minChildIndex);
          childrenInfo[inputIndex] = info;
          changeSetsHelper.updateViaFlatObject(
            flattenTree({
              ...common,
              inputIndex
            })
          );
        }
      });

      // Add the junctions
      const numJunctions = isCardCircular(fakeFullState, cardId)
        ? childrenInfo.length
        : childrenInfo.length - 1;

      for (let i = 0; i < numJunctions; i++) {
        const nextInd = (i + 1) % childrenInfo.length;

        const info5p = childrenInfo[i];
        const info3p = childrenInfo[nextInd];

        const junction = {
          junctionTypeCode: "SCARLESS",
          isPhantom: nextInd === 0,
          reactionId: reactionInput.id,
          fivePrimeCardId: info5p.cardId,
          threePrimeCardId: info3p.cardId,
          fivePrimeCardEndBinId: info5p.binId,
          fivePrimeCardInteriorBinId: info5p.binId,
          threePrimeCardStartBinId: info3p.binId,
          threePrimeCardInteriorBinId: info3p.binId
        };

        if (!info5p.binId) {
          junction.fivePrimeCardEndBinId = binIds[binIds.length - 1];
          junction.fivePrimeCardInteriorBinId = binIds[binIds.length - 1];
        }
        if (!info3p.binId) {
          junction.threePrimeCardStartBinId = binIds[0];
          junction.threePrimeCardInteriorBinId = binIds[0];
        }

        changeSetsHelper.createPure("junction", junction);
      }
    }
  }

  // List designs cannot have part sets.
  if (Object.keys(templateState.partset).length) {
    changeDesignLayoutType(changeSetsHelper, state, "combinatorial");
  }

  return changeSetsHelper.execute({
    recomputeElementValidation: true,
    simplifyTrivialValidators: true,
    removeInaccessibleItems: true,
    removeNonsensicalExtraSequences: true,
    recomputeBinValidation: true,
    recomputeDigestFasValidation: true,
    updateInvalidMaterialAvailabilities: true
  });
};
