/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";

export function getNumberOfCombinationDestinationPlates({
  sourcePlateFormat,
  sourcePlates,
  destinationPlateFormat
}) {
  return Math.ceil(
    sourcePlates.length /
      (get(destinationPlateFormat, "quadrantSize") /
        get(sourcePlateFormat, "quadrantSize"))
  );
}

export function breakdownPatterns(all2x2, sourceIs2x2) {
  const breakdownPatternOptions = ["Z", "Z-Reverse", "N", "N-Reverse"];
  if (all2x2 || sourceIs2x2) {
    breakdownPatternOptions.push("Clockwise", "Counter-Clockwise");
  }
  return breakdownPatternOptions;
}
