/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment bioshopDataTableFragment on dataTable {
    id
    name
    dataTableTypeCode
    dataRows {
      id
      rowValues
      dataRowJ5Items {
        id
        j5Item {
          id
          j5AssemblyPiece {
            id
            sequenceId
          }
        }
      }
    }
  }
`;
