/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { unparse } from "papaparse";
import { isoContext } from "@teselagen/utils";

export default async function formatReactionMapsForExport(
  reactionMapIds,
  ctx = isoContext
) {
  const { safeQuery } = ctx;
  const exportReactionMapFragment = gql`
    fragment exportReactionMapFragment on reactionMap {
      id
      name
      reactions {
        id
        name
        reactionInputs {
          id
          conserved
          inputMaterial {
            id
            name
          }
          inputAdditiveMaterial {
            id
            name
          }
        }
        reactionOutputs {
          id
          outputMaterial {
            id
            name
          }
          outputAdditiveMaterial {
            id
            name
          }
        }
      }
    }
  `;
  const fullReactionMaps = await safeQuery(exportReactionMapFragment, {
    variables: {
      filter: {
        id: reactionMapIds
      }
    }
  });

  const seenFileNames = {};
  const reactionMaps = [];
  fullReactionMaps.forEach(rm => {
    const reactions = [];
    rm.reactions.forEach((reaction, i) => {
      const inputMaterials = [];
      const inputReagents = [];
      const inputReagentsConserved = [];
      const outputMaterials = [];
      const outputReagents = [];
      reaction.reactionInputs.forEach(r => {
        if (r.inputMaterial) {
          inputMaterials.push(r.inputMaterial.name);
        } else if (r.inputAdditiveMaterial) {
          if (r.conserved) {
            inputReagentsConserved.push(r.inputAdditiveMaterial.name);
          } else {
            inputReagents.push(r.inputAdditiveMaterial.name);
          }
        }
      });
      reaction.reactionOutputs.forEach(r => {
        if (r.outputMaterial) {
          outputMaterials.push(r.outputMaterial.name);
        } else if (r.outputAdditiveMaterial) {
          outputReagents.push(r.outputAdditiveMaterial.name);
        }
      });
      reactions.push({
        REACTION_NAME: reaction.name || `reaction ${i + 1}`,
        INPUT_MATERIALS: inputMaterials.join(", "),
        INPUT_REAGENTS: inputReagents.join(", "),
        INPUT_REAGENTS_CONSERVED: inputReagentsConserved.join(", "),
        OUTPUT_MATERIALS: outputMaterials.join(", "),
        OUTPUT_REAGENTS: outputReagents.join(", ")
      });
    });
    let name = rm.name;
    if (seenFileNames[name]) {
      name = seenFileNames[name]++;
    } else {
      seenFileNames[name] = 1;
    }
    reactionMaps.push({
      name,
      contents: unparse(reactions)
    });
  });

  return reactionMaps;
}
