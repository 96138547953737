/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import unitGlobals from "../../unitGlobals";
import getUnit from "./getUnit";
import {
  standardizeCellConcentration,
  standardizeConcentration,
  standardizeMolarity,
  standardizeVolume
} from "./standardizeUnits";
import { round } from "lodash";

export const molarToNanoMolar = 1000000000;
export const defaultConcentrationUnitCode = "ng/uL";
export const defaultMolarityUnitCode = "nM";
export const defaultCellConcentrationUnitCode = "cells/uL";
export const defaultMaterialConcentrationUnitCode = "U/L";

/**
 * Given a concentration unit code, return how many grams per liter one unit represents.
 * @param {string} concentrationUnitCode
 * @returns {number}
 */
export function concentrationUnitCodeToGramsPerLiter(concentrationUnitCode) {
  const unit =
    unitGlobals.concentrationUnits &&
    getUnit(unitGlobals.concentrationUnits, concentrationUnitCode);
  const gramsPerLiter = unit && unit.gramsPerLiter;
  if (!gramsPerLiter)
    throw new Error(
      `Invalid concentration unit code: ${concentrationUnitCode}.`
    );
  return gramsPerLiter;
}

export function molarityUnitCodeToMolesPerLiter(molarityUnitCode) {
  const unit =
    unitGlobals.molarityUnits &&
    getUnit(unitGlobals.molarityUnits, molarityUnitCode);
  const molesPerLiter = unit && unit.molesPerLiter;
  if (!molesPerLiter)
    throw new Error(`Invalid concentration unit code: ${molarityUnitCode}.`);
  return molesPerLiter;
}

export function cellConcentrationUnitCodeToCellsPerLiter(
  cellConcentrationUnitCode
) {
  const unit =
    unitGlobals.cellConcentrationUnits &&
    getUnit(unitGlobals.cellConcentrationUnits, cellConcentrationUnitCode);
  const cellsPerLiter = unit && unit.cellsPerLiter;
  if (!cellsPerLiter)
    throw new Error(
      `Invalid cell concentration unit code: ${cellConcentrationUnitCode}.`
    );
  return cellsPerLiter;
}

export const allConcentrationTypeFields = [
  "concentration",
  "concentrationUnitCode",
  "materialConcentration",
  "materialConcentrationUnitCode",
  "molarity",
  "molarityUnitCode"
];

/**
 * Convert a `concentration` given in `oldUnits` to `newUnits`.
 * @param {number} concentration The concentration we wish to convert
 * @param {string} oldUnits The concentration unit code that `concentration` is provided as.
 * @param {string} newUnits The concentration unit code that we wish to see `concentration` as.
 * @returns {number}
 */
export function convertConcentrationBig(concentration, oldUnits, newUnits) {
  return new Big(concentration)
    .times(concentrationUnitCodeToGramsPerLiter(oldUnits))
    .div(concentrationUnitCodeToGramsPerLiter(newUnits));
}

export function convertMolarityBig(molarity, oldUnits, newUnits) {
  return new Big(molarity)
    .times(molarityUnitCodeToMolesPerLiter(oldUnits))
    .div(molarityUnitCodeToMolesPerLiter(newUnits));
}

export function convertCellConcentrationBig(
  cellConcentration,
  oldUnits,
  newUnits
) {
  return new Big(cellConcentration)
    .times(cellConcentrationUnitCodeToCellsPerLiter(oldUnits))
    .div(cellConcentrationUnitCodeToCellsPerLiter(newUnits));
}
/**
 * Returns molarity as M
 * @param {*} concentration
 * @param {*} concentrationUnitCode
 * @param {*} concentrationUnits
 * @param {*} molecularWeight
 */
export const calculateMolarityFromConcentration = (
  concentration,
  concentrationUnitCode,
  molecularWeight
) => {
  return Number(
    standardizeConcentration(concentration, concentrationUnitCode, true).div(
      molecularWeight
    )
  );
};

/**
 * Returns concentration as g/L
 * @param {*} molarity
 * @param {*} molarityUnitCode
 * @param {*} molarityUnits
 * @param {*} molecularWeight
 */
export const calculateConcentrationFromMolarity = (
  molarity,
  molarityUnitCode,
  molecularWeight
) => {
  return Number(
    standardizeMolarity(
      molarity,
      molarityUnitCode,
      unitGlobals.molarityUnits,
      true
    ).times(molecularWeight)
  );
};

export function getCellCount({
  cellConcentration,
  cellConcentrationUnitCode,
  volume,
  volumetricUnitCode
}) {
  return round(
    standardizeCellConcentration(cellConcentration, cellConcentrationUnitCode) *
      standardizeVolume(volume, volumetricUnitCode)
  );
}
