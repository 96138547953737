/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";

const ReactGridLayout = WidthProvider(Responsive);

class WidgetCanvas extends Component {
  render() {
    const widgetLayout = this.props.widgetLayout;
    const widgets = this.props.widgets;
    const toRender = widgets.map(widget => {
      return <div key={widget.key}>{widget.component}</div>;
    });

    return widgetLayout ? (
      <ReactGridLayout
        layouts={widgetLayout}
        breakpoints={{ lg: 1400, md: 1200, sm: 960, xs: 400 }}
        cols={{ lg: 12, md: 11, sm: 6, xs: 4 }}
      >
        {toRender}
      </ReactGridLayout>
    ) : (
      <div></div>
    );
  }
}

export default WidgetCanvas;
