/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import sequenceFragment from "../../../../tg-iso-shared/src/fragments/sequenceFragment";

const additivesPlateExportFragment = gql`
  fragment additivesPlateExportFragment on additive {
    id
    additiveMaterial {
      id
      name
    }
    lot {
      id
      name
      additiveMaterial {
        id
        name
      }
    }
    mass
    massUnitCode
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
  }
`;

export const aliquotFragment = gql`
  fragment aliquotPlateExportFragment on aliquot {
    id
    isDry
    mass
    massUnitCode
    volume
    volumetricUnitCode
    concentration
    concentrationUnitCode
    additives {
      ...additivesPlateExportFragment
    }
    sample {
      id
      name
      sampleTypeCode
      material {
        id
        name
        materialTypeCode
        polynucleotideMaterialSequence {
          ...sequenceFragment
        }
        microbialMaterialMicrobialMaterialPlasmids {
          id
          polynucleotideMaterial {
            id
            polynucleotideMaterialSequence {
              ...sequenceFragment
            }
          }
        }
        strain {
          id
          name
        }
      }
      sampleFormulations {
        id
        materialCompositions {
          id
          materialId
          material {
            id
            name
            polynucleotideMaterialSequence {
              ...sequenceFragment
            }
          }
        }
      }
    }
  }
  ${sequenceFragment}
  ${additivesPlateExportFragment}
`;

export const aliquotContainerFragment = gql`
  fragment aliquotContainerPlateExportFragment on aliquotContainer {
    id
    name
    barcode {
      id
      barcodeString
    }
    aliquotContainerPathView {
      id
      fullPath
    }
    additives {
      ...additivesPlateExportFragment
    }
    containerArrayId
    columnPosition
    rowPosition
    aliquotId
  }
  ${additivesPlateExportFragment}
`;

export default gql`
  fragment containerArrayExportFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
  }
`;
