/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { sequenceSampleStatusFragment } from "./sequenceSampleStatusFragment";

export const j5OligoSynthesisFragment = gql`
  fragment j5OligoSynthesisFragment on j5OligoSynthesis {
    id
    cid
    cost
    name
    simpleName
    tm
    tm3Prime
    sequence3Prime
    j5TargetParts {
      id
      j5InputPart {
        id
        part {
          id
          name
        }
      }
    }
    oligo {
      id
      cid
      name
      j5AnnealedOligosTopOligos {
        id
        sequence {
          id
          j5AssemblyPiece {
            id
            j5AssemblyPieceParts {
              id
              index
              j5InputPart {
                id
                part {
                  id
                  name
                }
              }
            }
          }
        }
      }
      j5AnnealedOligosBottomOligos {
        id
        sequence {
          id
          hash
          j5AssemblyPiece {
            id
            j5AssemblyPieceParts {
              id
              index
              j5InputPart {
                id
                part {
                  id
                  name
                }
              }
            }
          }
        }
      }
      sequence {
        id
        cid
        hash
        name
        polynucleotideMaterialId
        size
        isJ5Sequence
        isInLibrary
        sequenceAvailableInventoryView {
          id
          sampleCount
          containerCount
        }
        sequenceSampleStatusView {
          ...sequenceSampleStatusFragment
        }
        sequenceFragments {
          id
          index
          fragment
        }
      }
    }
  }
  ${sequenceSampleStatusFragment}
`;
