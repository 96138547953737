/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  getInputCardIdOfJunction,
  getTerminalBinIdOfJunction,
  getOverhangTypeCodeOfJunction
} from "../../../../../../../tg-iso-design/selectors/junctionSelectors";
import {
  getBinIdsInCard,
  getItemOfType
} from "../../../../../../../tg-iso-design/selectors/designStateSelectors";
import tgCreateCachedSelector from "../../../../../../../tg-iso-design/utils/tgCreateCachedSelector";

/**
 * Get the initial values for the internalization preference options.
 * @param {Object} state
 * @param {string} junctionId
 */
export const getInternalizationPreferencesIntialValues = tgCreateCachedSelector(
  state => state,
  (state, junctionId) => getItemOfType(state, "junction", junctionId),
  (state, junction) => {
    return {
      rs5: "FLANKING_SEQUENCE",
      o5: junction.fivePrimeCardOverhangTypeCode,
      rs3: "FLANKING_SEQUENCE",
      o3: junction.threePrimeCardOverhangTypeCode
    };
  }
)((state, junctionId) => junctionId);

/**
 * Get the id of the bin that can form the overhang adapter for a given
 * validated junction. Will return null if no such bin exists.
 * @param {Object} state
 * @param {string} junctionId
 * @param {boolean} onFivePrimePiece
 */
export const getOverhangAdapterBinId = tgCreateCachedSelector(
  getOverhangTypeCodeOfJunction,
  getTerminalBinIdOfJunction,
  (...args) => getBinIdsInCard(args[0], getInputCardIdOfJunction(...args)),
  (state, junctionId, onFivePrimePiece) => onFivePrimePiece,
  (overhangType, binId, binIds, onFivePrimePiece) => {
    // If this end of the assembly piece is on an adapter return that bin.
    if (overhangType === "ADAPTER") {
      return binId;
    } else {
      const binIndex = binIds.indexOf(binId);
      const adapterIndex = onFivePrimePiece ? binIndex + 1 : binIndex - 1;
      return binIds[adapterIndex] || null;
    }
  }
)(
  (state, junctionId, onFivePrimePiece) => `${junctionId}:${!!onFivePrimePiece}`
);

/**
 * This selector helps us when we want to go from adapter to either insert or flanking
 * sequence.
 * @param {Object} state
 * @param {string} junctionId
 * @param {boolean} onFivePrimePiece
 */
export const getOverhangNonAdapterBin = tgCreateCachedSelector(
  getOverhangTypeCodeOfJunction,
  getTerminalBinIdOfJunction,
  (...args) => getBinIdsInCard(args[0], getInputCardIdOfJunction(...args)),
  (state, junctionId, onFivePrimePiece) => onFivePrimePiece,
  (overhangType, binId, binIds, onFivePrimePiece) => {
    if (overhangType !== "ADAPTER") {
      return binId;
    } else {
      const binIndex = binIds.indexOf(binId);
      const nonAdapterIndex = onFivePrimePiece ? binIndex - 1 : binIndex + 1;
      return binIds[nonAdapterIndex] || null;
    }
  }
)(
  (state, junctionId, onFivePrimePiece) => `${junctionId}:${!!onFivePrimePiece}`
);

/**
 * Does an adapter bin exist to put the overhang on?
 * @param {Object} state
 * @param {string} validatedJunctionId
 * @param {boolean} onFivePrimePiece
 */
export const isAdapterAnOptionForOverhang = (...args) =>
  !!getOverhangAdapterBinId(...args);
