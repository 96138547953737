/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { BlueprintError, tgFormValues } from "@teselagen/ui";
import { difference, get, noop } from "lodash";
import {
  CheckboxField,
  InputField,
  NumericInputField,
  ReactSelectField,
  SelectField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import shortid from "shortid";
import { Button, Classes } from "@blueprintjs/core";

import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";
import GenericSelect from "../../../../src-shared/GenericSelect";
import "./style.css";
import { filterSequenceUploads } from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";

import {
  arrayToIdOrCodeValuedOptions,
  throwFormError
} from "../../../../src-shared/utils/formUtils";

import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";

import caseInsensitiveFilter from "../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { checkDuplicateSequencesExtended } from "../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import SequenceFileUpload from "../../../../src-shared/SequenceFileUpload";
import { getMicrobialPlasmidsToCreate } from "../../../utils";

class CreateNewStrainDialog extends Component {
  state = {
    page: 1
  };

  nextPage = async values => {
    const sequenceUpload = values.plasmidUpload;
    try {
      if (sequenceUpload)
        await filterSequenceUploads({
          allSequenceFiles: [...sequenceUpload]
        });
      this.setState({
        page: this.state.page + 1
      });
    } catch (error) {
      console.error(error);
      throwFormError(error.message || "Error creating Strain");
    }
  };

  prevPage = () => {
    this.setState({
      page: this.state.page - 1
    });
  };

  onSubmit = async values => {
    const {
      hideModal,
      history,
      initialValues: {
        selectionMethodIds: initialSelectionMethodIds,
        inductionMethodIds: initialInductionMethodIds
      } = {},
      strainTypeCode,
      strainSelectionMethods = [],
      strainInductionMethods = [],
      refetch = noop
    } = this.props;
    const {
      id: updateId,
      name,
      genotype,
      description,
      selectionMethodIds = [],
      inductionMethodIds = [],
      biosafetyLevelCode,
      targetOrganismClassId,
      withGrowthCondition,
      growthCondition: _growthCondition,
      specie,
      genome
    } = values;
    const sequenceUpload = values.plasmidUpload;

    delete values.plasmidUpload;
    const maybeGrowthCondition = _growthCondition || {};
    const growthMediaId = get(maybeGrowthCondition, "growthMedia.id");
    const gasCompositionId = get(maybeGrowthCondition, "gasComposition.id");
    let growthCondition = {
      updatedAt: new Date()
    };
    let growthConditionId;
    try {
      let sequences;
      if (sequenceUpload) {
        sequences = await filterSequenceUploads({
          allSequenceFiles: [...sequenceUpload]
        });
      } else {
        sequences = [];
      }
      const { allInputSequencesWithAttachedDuplicates } =
        await checkDuplicateSequencesExtended(sequences);

      if (withGrowthCondition) {
        growthConditionId = maybeGrowthCondition.id;
        growthCondition = {
          ...maybeGrowthCondition,
          growthMediaId,
          gasCompositionId
        };
        if (!growthConditionId) {
          const cid = shortid();
          growthConditionId = `&${cid}`;
          growthCondition.cid = cid;
        }
        delete growthCondition.growthMedia;
        delete growthCondition.gasComposition;
        delete growthCondition.__typename;
        await safeUpsert("growthCondition", growthCondition);
      } else if (updateId && maybeGrowthCondition.id) {
        // if updating and trying to remove old growth condition
        safeDelete("growthCondition", maybeGrowthCondition.id);
      }

      const selectedSequences = values.selectedSequences || [];
      delete values.selectedSequences;
      const { microbialPlasmids, extraCreates } =
        await getMicrobialPlasmidsToCreate({
          selectedSequences,
          allInputSequencesWithAttachedDuplicates
        });

      const newStrain = {
        id: updateId,
        name,
        strainTypeCode,
        genotype,
        description,
        targetOrganismClassId,
        biosafetyLevelCode,
        growthConditionId,
        genomeId: genome && genome.id,
        specieId: specie && specie.id,
        strainPlasmids: microbialPlasmids
      };
      if (!targetOrganismClassId) newStrain.targetOrganismClassId = null;
      await extraCreates();
      const [{ id }] = await safeUpsert("strain", newStrain);
      let newSelectionMethodIds = selectionMethodIds;
      let newInductionMethodIds = inductionMethodIds;
      // SELECTION METHODS
      if (initialSelectionMethodIds) {
        newSelectionMethodIds = difference(
          selectionMethodIds,
          initialSelectionMethodIds
        );
        const removedSelectionMethodIds = difference(
          initialSelectionMethodIds,
          selectionMethodIds
        );
        const strainSelectionMethodsToDelete = strainSelectionMethods.reduce(
          (acc, ssm) => {
            if (removedSelectionMethodIds.includes(ssm.selectionMethod.id)) {
              acc.push(ssm.id);
            }
            return acc;
          },
          []
        );
        await safeDelete(
          "strainSelectionMethod",
          strainSelectionMethodsToDelete
        );
      }
      await safeUpsert(
        "strainSelectionMethod",
        newSelectionMethodIds.map(selectionMethodId => ({
          strainId: id,
          selectionMethodId
        }))
      );

      // INDUCTION METHODS
      if (initialInductionMethodIds) {
        newInductionMethodIds = difference(
          inductionMethodIds,
          initialInductionMethodIds
        );
        const removedInductionMethodIds = difference(
          initialInductionMethodIds,
          inductionMethodIds
        );
        const strainInductionMethodsToDelete = strainInductionMethods.reduce(
          (acc, sim) => {
            if (removedInductionMethodIds.includes(sim.inductionMethod.id)) {
              acc.push(sim.id);
            }
            return acc;
          },
          []
        );
        await safeDelete(
          "inductionMethodStrain",
          strainInductionMethodsToDelete
        );
      }
      await safeUpsert(
        "inductionMethodStrain",
        newInductionMethodIds.map(inductionMethodId => ({
          strainId: id,
          inductionMethodId
        }))
      );
      await refetch();
      hideModal();
      strainTypeCode === "MICROBIAL_STRAIN"
        ? history.push(`/microbial-strains/${id}`)
        : history.push(`/cell-lines/${id}`);
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error creating Strain");
    }
  };

  renderPage1() {
    const { asyncValidating, isUpdate, strainTypeCode, error } = this.props;
    return (
      <React.Fragment>
        <InputField
          label="Name"
          name="name"
          isRequired
          rightElement={
            <AsyncValidateFieldSpinner validating={asyncValidating} />
          }
        />
        <TextareaField label="Genotype" name="genotype" />
        {strainTypeCode === "MICROBIAL_STRAIN" && (
          <GenericSelect
            {...{
              name: "genome",
              asReactSelect: true,
              label: "Genome",
              fragment: ["genome", "id name"]
            }}
          />
        )}
        <TextareaField
          label="Description"
          name="description"
          placeholder="Enter any additional information."
        />
        {!isUpdate && (
          <GenericSelect
            {...{
              name: "selectedSequences",
              asReactSelect: true,
              isMultiSelect: true,
              label: "Select Existing Plasmids",
              schema: [
                {
                  path: "name"
                },
                {
                  path: "sequenceTypeCode"
                }
              ],
              fragment: [
                "sequence",
                "id name sequenceTypeCode hash size circular sequenceFragments { id index fragment}"
              ],
              tableParamOptions: {
                additionalFilter: {
                  sequenceTypeCode: "CIRCULAR_DNA"
                }
              }
            }}
          />
        )}
        <SequenceFileUpload
          label="Upload Additional Plasmids"
          name="plasmidUpload"
        ></SequenceFileUpload>
        {error && <BlueprintError error={error} />}
      </React.Fragment>
    );
  }

  renderPage2() {
    const {
      selectionMethods = [],
      inductionMethods = [],
      targetOrganismClasses = [],
      biosafetyLevels = [],
      lengthUnits = [],
      withGrowthCondition,
      initialValues = {},
      error
    } = this.props;

    return (
      <React.Fragment>
        <CheckboxField
          name="withGrowthCondition"
          label="Specify Growth Conditions?"
          defaultValue={initialValues.growthCondition}
        />
        {withGrowthCondition && (
          <div className="tg-form-border" style={{ marginBottom: 15 }}>
            <h6>Growth Conditions</h6>
            <GrowthConditionFields lengthUnits={lengthUnits} />
          </div>
        )}
        <GenericSelect
          {...{
            name: "specie",
            asReactSelect: true,
            label: "Species",
            schema: [
              {
                path: "abbreviatedName"
              }
            ],
            fragment: ["specie", "id name abbreviatedName"]
          }}
        />
        <ReactSelectField
          label={modelNameToReadableName("targetOrganismClass", {
            upperCase: true
          })}
          name="targetOrganismClassId"
          options={arrayToIdOrCodeValuedOptions(targetOrganismClasses)}
        />
        <BiosafetySelect biosafetyLevels={biosafetyLevels} isRequired />
        <ReactSelectField
          label="Selection Methods"
          name="selectionMethodIds"
          options={arrayToIdOrCodeValuedOptions(selectionMethods)}
          multi
        />
        <ReactSelectField
          label="Induction Methods"
          name="inductionMethodIds"
          options={arrayToIdOrCodeValuedOptions(inductionMethods)}
          multi
          defaultValue={initialValues.inductionMethodIds}
        />
        {error && <BlueprintError error={error} />}
      </React.Fragment>
    );
  }

  render() {
    const { page } = this.state;
    const { hideModal, handleSubmit, submitting } = this.props;

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>{this[`renderPage${page}`]()}</div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          text={page > 1 ? "Submit" : "Next"}
          additionalButtons={
            page > 1 && <Button text="Previous" onClick={this.prevPage} />
          }
          onClick={handleSubmit(page === 1 ? this.nextPage : this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export const asyncValidateStrainName = async (
  { name },
  dispatch,
  { initialValues = {}, strainTypeCode }
) => {
  if (!name) return;
  if (initialValues.name && name === initialValues.name)
    return Promise.resolve();
  const [existingStrain] = await safeQuery(["strain", "id"], {
    variables: {
      pageSize: 1,
      filter: caseInsensitiveFilter("strain", "name", [name], {
        returnQb: true
      })
        .whereAll({
          strainTypeCode
        })
        .toJSON()
    }
  });
  if (existingStrain) {
    const error = {
      name: `A ${
        strainTypeCode === "MICROBIAL_STRAIN" ? "strain" : "cell line"
      } with this name already exists.`
    };
    throw error;
  }
};

export const withStrainUnits = compose(
  withQuery(["selectionMethod", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["inductionMethod", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["growthCondition", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["targetOrganismClass", "id name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["biosafetyLevel", "code name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["lengthUnit", "code"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
);

export default compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title:
          get(props, "strainTypeCode") === "MICROBIAL_STRAIN"
            ? `${props.isUpdate ? "Update" : "Create New"} Strain`
            : `${props.isUpdate ? "Update" : "Create New"} Cell Line`
      };
    }
  }),
  reduxForm({
    form: "createNewStrain",
    asyncBlurFields: ["name"],
    asyncValidate: asyncValidateStrainName
  }),
  withStrainUnits,
  tgFormValues("withGrowthCondition")
)(CreateNewStrainDialog);

export function GrowthConditionFields({ lengthUnits }) {
  return (
    <React.Fragment>
      <InputField label="Name" name="growthCondition.name" />
      <TextareaField label="Description" name="growthCondition.description" />
      <NumericInputField
        label="Shaker Speed (rpm)"
        name="growthCondition.shakerSpeed"
        min={0}
      />
      <div className="input-with-unit-select">
        <NumericInputField
          name="growthCondition.shakerThrow"
          label="Shaker Throw"
          min={0}
        />
        <SelectField
          name="growthCondition.lengthUnitCode"
          label="none"
          className="tg-unit-select"
          defaultValue="mm"
          options={arrayToIdOrCodeValuedOptions(lengthUnits, {
            labelKey: "code"
          })}
        />
      </div>
      <NumericInputField
        name="growthCondition.temperature"
        label="Temperature (°C)"
        min={0}
      />
      <NumericInputField
        name="growthCondition.humidity"
        label="Humidity (%)"
        min={0}
      />
      <GenericSelect
        {...{
          name: "growthCondition.growthMedia",
          asReactSelect: true,
          label: "Growth Medium",
          schema: [
            {
              path: "name"
            }
          ],
          fragment: ["additiveMaterial", "id name"],
          tableParamOptions: {
            additionalFilter: (props, qb) => {
              qb.whereAll({
                additiveTypeCode: "GROWTH_MEDIA"
              });
            }
          }
        }}
      />
      <GenericSelect
        {...{
          name: "growthCondition.gasComposition",
          asReactSelect: true,
          label: "Gas Composition",
          schema: [
            {
              path: "name"
            }
          ],
          fragment: ["gasComposition", "id name"]
        }}
      />
    </React.Fragment>
  );
}

export function BiosafetySelect({ biosafetyLevels, isRequired }) {
  return (
    <ReactSelectField
      label="Biosafety Level"
      name="biosafetyLevelCode"
      isRequired={isRequired}
      options={arrayToIdOrCodeValuedOptions(
        [...biosafetyLevels].sort(
          (a, b) => (Number(a.name) || 0) - (Number(b.name) || 0)
        )
      )}
    />
  );
}
