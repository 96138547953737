/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState, useEffect } from "react";
import { generateField } from "@teselagen/ui";
import modelNameToReadableName from "../utils/modelNameToReadableName";

import { safeQuery } from "../apolloMethods";
import { Button, Intent, Tooltip } from "@blueprintjs/core";
import { noop, isEmpty } from "lodash";
import { branch, withProps, compose, withHandlers } from "recompose";
import { clearFields, reduxForm, autofill, change } from "redux-form";
import { connect } from "react-redux";
import GenericSelectInner from "./InnerComp";
import genericSelectWrapper from "./genericSelectWrapper";
import { PostSelectTable } from "./PostSelectTable";
import usePrevious from "../usePrevious";

// useage example:
// <GenericSelect {...{
//   name: "selectedWorklists", //the field name within the redux form Field
//   isMultiSelect: true,
//   schema: ["name", "lastModified"],
//   fragment: [model, "id name"],
//   additionalDataFragment: somefragment,
// }}/>

//options:
// name - the field name of the redux form Field!
// schema - the schema for the data table
// getButtonText(selectedEntities) - function to override the button text if necessary
// isMultiSelect=false - do you want users to be able to select multiple entities or just one
// noDialog=false - set to true to not have the selector show up in a dialog
// fragment - the fragment powering the lookup/datatable
// dialogProps - any dialog overrides you might want to make
// dialogFooterProps - any dialogFooter overrides you might want to make
// additionalDataFragment - optional fragment for fetching more data based on the initially selected data
// postSelectDTProps - props passed to the DataTable shown after select. If none are passed the DataTable isn't shown
// onSelect - optional callback for doing things with the selected data
// onFieldSubmit - additional callback for doing things when selecting options. Receives all the currently selected options. When GenericSelect is created from SelectGenericItemsDialog its always defined

// ################################   asReactSelect   ################################
// idAs="id" - use this to get the TgSelect to use some other property as the "value" aka idAs="code" for code based selects
// asReactSelect - optionally make the generic select a simple TgSelect component instead of the default datatables
// reactSelectProps - optionally pass additional props to the TgSelect
// ...rest - all additional props will be passed to the TgSelect
// ** preventing unselect if you don't want a certain option to be unselected ever, you can pass initialValues with a property called clearableValue  entity.clearableValue,

// if you want initialValues, simply pass them to the reduxForm wrapped component like:
// {
//   initialValues: {
//     selectedWorklists: [{id: 1, name: "worklist1"}]
//   }
// }

function preventBubble(e) {
  e.stopPropagation();
}

const GenericSelect = compose(
  branch(
    props => props.noForm, // In case the GenericSelect is not used within a form, add an artificial one for all events to work correctly
    reduxForm({
      form: "genericSelect",
      asyncBlurFields: [] //hacky fix for weird redux form asyncValidate error https://github.com/erikras/redux-form/issues/1675
    })
  ),
  withProps(
    ({
      asReactSelect: _asReactSelect,
      CustomInnerComponent,
      dialogProps,
      idAs: _idAs,
      isCodeModel: _isCodeModel,
      isMultiSelect,
      name,
      nameOverride,
      noFill,
      passedName,
      postSelectDTProps,
      schema
    }) => {
      // custom inner component should be treated the same as asReactSelect in terms of props passed down
      const asReactSelect = _asReactSelect || CustomInnerComponent;
      let idAs = _idAs;
      if (!idAs) {
        idAs = _isCodeModel ? "code" : "id";
      }
      let isCodeModel = _isCodeModel;
      if (!isCodeModel) {
        isCodeModel = idAs === "code";
      }

      const readableName =
        nameOverride ||
        modelNameToReadableName(schema.model, {
          plural: isMultiSelect,
          upperCase: true
        });
      const response = {
        dialogProps: {
          title: "Select " + readableName,
          ...dialogProps
        },
        modelName: schema.model,
        idAs,
        isCodeModel,
        passedName: name,
        readableName,
        schema,
        asReactSelect
      };
      if (asReactSelect) {
        response.noDialog = true;
      }
      if (!noFill && postSelectDTProps) {
        response.containerStyle = {
          width: "100%"
        };
      }
      if (postSelectDTProps) {
        response.postSelectFormName = passedName + "PostSelect";
      }
      return response;
    }
  ),
  withHandlers({
    massageDefaultIdValue:
      props =>
      async ({ defaultValueById }) => {
        if (!defaultValueById) return {};
        const res = await safeQuery(
          props.additionalDataFragment || props.fragment,
          {
            idAs: props.idAs,
            variables:
              props.idAs === "code"
                ? { code: defaultValueById }
                : { id: defaultValueById }
          }
        );

        return {
          defaultValue: res,
          preventUserOverrideFromBeingDisabled: !res
        };
      }
  }),
  connect(null, dispatch => {
    return {
      changeFieldValue: (...args) => dispatch(change(...args)),
      autofillForm: (...args) => dispatch(autofill(...args)),
      clearFields: (...args) => dispatch(clearFields(...args))
    };
  }),
  generateField
)(({
  additionalDataFragment,
  additionalFilter,
  additionalTableProps,
  asReactSelect,
  buttonProps = {},
  code,
  containerClassName,
  defaultTemplateString,
  defaultValueByIdOverride,
  destinationPlateFormat,
  dialogInfoMessage,
  dialogProps,
  disabled,
  doNotGenerateField,
  enableReinitialize,
  fragment,
  fieldType,
  firstItemsToShow,
  getButton,
  getButtonText,
  handlersObj,
  handleOpenChange,
  hideModal,
  idAs,
  isMultiSelect,
  isRequired,
  key,
  label,
  minSelected,
  mustSelect,
  nameOverride,
  noDialog,
  noFill,
  noForm,
  noRemoveButton,
  onFieldSubmit = noop,
  onSelect,
  params,
  passedName,
  placeholder,
  postSelectDTProps = {},
  postSelectFormName,
  preserveValue,
  queryOptions,
  reactSelectProps,
  reactSelectQueryString: _reactSelectQueryString,
  reagent,
  readableName,
  schema,
  secondaryLabel,
  style,
  tableParamOptions,
  tooltipInfo,
  validate,
  withSelectAll = true,
  withSelectedTitle,
  CustomInnerComponent,
  autofillForm,
  input,
  onClear = noop,
  meta: { form },
  changeFieldValue,
  defaultValue,
  dataTest,
  autoOpen,
  noMarginBottom,
  inputTracker,
  isLocalCall,
  entities,
  selectedPlateIds // TODO: Remove in favor of a generic prop,
}) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [tempValue, setTempValue] = useState(null);
  const [reactSelectQueryString, setReactSelectQueryString] = useState("");

  const oldValue = usePrevious(input.value);

  // handles value pre-selection from parent
  useEffect(() => {
    if (onSelect) {
      const hasValue = Array.isArray(input.value)
        ? input.value.length
        : input.value;
      if (hasValue) {
        const arrValue = Array.isArray(input.value)
          ? input.value
          : [input.value];
        const arrOldValue = oldValue
          ? Array.isArray(oldValue)
            ? oldValue
            : [oldValue]
          : [];
        const newIds = arrValue.map(r => r.id || r.code);
        const oldIds = arrOldValue.map(r => r.id || r.code);
        if (
          newIds.length !== oldIds.length ||
          newIds.every(id => !oldIds.includes(id))
        ) {
          // instead of doing a deep comparison check just check if ids of selected items changed
          onSelect(input.value, {
            reactSelectQueryString: _reactSelectQueryString
          });
        }
      }
    }
  }, [_reactSelectQueryString, input.value, oldValue, onSelect]);

  const resetPostSelectSelection = () => {
    const postSelectDTFormName =
      postSelectDTProps.formName || postSelectFormName;
    if (postSelectDTFormName) {
      changeFieldValue(
        postSelectDTFormName,
        "reduxFormSelectedEntityIdMap",
        {}
      );
    }
  };

  // for post select table
  const removeEntityFromSelection = record => {
    // TODO: sometimes (kind of randomly) the input.value is not an array
    const newValue = input.value
      ? input.value.filter(r => r[idAs] !== record[idAs])
      : [];
    if (newValue.length) {
      input.onChange(newValue);
      setTempValue(null);
      resetPostSelectSelection();
    } else {
      removeSelection();
    }
  };

  const removeSelection = () => {
    const newVal = isMultiSelect ? [] : undefined;
    autofillForm(form, input.name, newVal);
    onClear();
    input.onBlur();

    setTempValue(null);
    resetPostSelectSelection();
    onFieldSubmit(undefined, true);
  };

  const handleOnChange = newValue => {
    const { onChange = noop, onBlur = noop, value = [] } = input;
    let toSelect = newValue;
    if (isMultiSelect && value.length && preserveValue) {
      const newIds = newValue.map(r => r[idAs]);
      toSelect = value.filter(r => !newIds.includes(r[idAs])).concat(newValue);
    }
    onChange(toSelect);
    onBlur();
    onFieldSubmit(toSelect, true);
  };

  async function handleSelection(records) {
    const toSelect = isMultiSelect ? records : records[0];
    resetPostSelectSelection();
    if (asReactSelect && !records.length) {
      return removeSelection();
    }
    if (!additionalDataFragment) {
      handleOnChange(toSelect || null);
      return;
    }
    setFetchingData(true);
    const queryVariables = {
      filter: {
        [idAs]: isMultiSelect ? records.map(r => r[idAs]) : records[0][idAs]
      }
    };
    if (isEmpty(postSelectDTProps)) {
      try {
        const records = await safeQuery(additionalDataFragment, {
          variables: queryVariables
        });

        const toSelect = isMultiSelect ? records : records[0];
        handleOnChange(toSelect);
      } catch (error) {
        console.error("err:", error);
        window.toastr.error("Error fetching " + readableName);
      }
    } else if (!additionalDataFragment) {
      handleOnChange(toSelect);
    } else {
      // this is necessary because sometimes we are relying on the field to have
      // the full data
      setTempValue(toSelect);
    }
    setFetchingData(false);
  }

  // Used when input methods are needed in the parent
  if (inputTracker) {
    inputTracker.input = input;
  }

  if (handlersObj) {
    handlersObj.removeSelection = removeSelection;
  }
  let postSelectDataTableValue = tempValue || input.value;
  if (postSelectDataTableValue && !Array.isArray(postSelectDataTableValue)) {
    postSelectDataTableValue = [postSelectDataTableValue];
  }
  const propsToPass = {
    additionalDataFragment,
    additionalFilter,
    additionalTableProps,
    asReactSelect,
    buttonProps,
    code,
    currentValue: input.value,
    defaultTemplateString,
    defaultValueByIdOverride,
    destinationPlateFormat,
    dialogInfoMessage,
    dialogProps,
    disabled,
    doNotGenerateField,
    enableReinitialize,
    fragment,
    fieldType,
    firstItemsToShow,
    getButton,
    getButtonText,
    handlersObj,
    handleSelection,
    hideModal,
    idAs,
    isMultiSelect,
    isRequired,
    inputTracker,
    onClear,
    input,
    key,
    label,
    minSelected,
    mustSelect,
    nameOverride,
    noDialog,
    noFill,
    noForm,
    noRemoveButton,
    onFieldSubmit,
    onSelect,
    params,
    passedName,
    placeholder,
    postSelectDTProps,
    postSelectFormName,
    preserveValue,
    queryOptions,
    reactSelectProps,
    reactSelectQueryString,
    setReactSelectQueryString,
    reagent,
    readableName,
    schema,
    secondaryLabel,
    style,
    tableParamOptions,
    tooltipInfo,
    validate,
    withSelectAll,
    withSelectedTitle,
    CustomInnerComponent,
    noMarginBottom,
    selectedPlateIds,
    defaultValue,
    autoOpen,
    dataTest,
    handleOpenChange,
    isLocalCall,
    entities
  };

  let hasValue = !!input.value;
  // need to account for case where value = [] which is empty
  if (Array.isArray(input.value) && !input.value.length) hasValue = false;

  let buttonText = buttonProps.text;
  if (!buttonText) {
    buttonText = getButtonText
      ? getButtonText(input.value)
      : hasValue
        ? "Change " + readableName
        : `Select ${readableName}`;
  }

  const classNameToUse =
    "tg-generic-select-container " + (containerClassName || "");

  return noDialog ? (
    <div className={classNameToUse} onClick={preventBubble}>
      <GenericSelectInner {...propsToPass} />
    </div>
  ) : (
    <div className={classNameToUse}>
      <div
        onClick={preventBubble}
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <div style={{ display: "flex" }}>
          <GenericSelectInner {...propsToPass} buttonText={buttonText}>
            {getButton ? (
              getButton(input.value, propsToPass, {
                fetchingData,
                tempValue,
                reactSelectQueryString
              })
            ) : (
              <Button
                intent={hasValue ? Intent.NONE : Intent.PRIMARY}
                {...buttonProps}
                text={buttonText}
                loading={fetchingData || buttonProps.loading}
              />
            )}
          </GenericSelectInner>
          {hasValue && !noRemoveButton && !noForm && (
            <Tooltip
              disabled={buttonProps.disabled}
              content={"Clear " + readableName}
            >
              <Button
                minimal
                style={{ marginLeft: 4 }}
                intent={Intent.DANGER}
                disabled={buttonProps.disabled}
                onClick={removeSelection}
                className="tg-clear-generic-select"
                icon="trash"
              />
            </Tooltip>
          )}
        </div>
        {!isEmpty(postSelectDTProps) && !!postSelectDataTableValue?.length && (
          <PostSelectTable
            idAs={idAs}
            additionalDataFragment={additionalDataFragment}
            initialEntities={postSelectDataTableValue}
            genericSelectValue={input.value}
            onSelect={onSelect}
            withSelectedTitle={withSelectedTitle}
            readableName={readableName}
            removeSelection={removeSelection}
            removeEntityFromSelection={removeEntityFromSelection}
            postSelectFormName={postSelectFormName}
            postSelectDTProps={postSelectDTProps}
            isMultiSelect={isMultiSelect}
            resetSelection={resetPostSelectSelection}
            clearTempValue={() => {
              setTempValue(null);
            }}
            changeGenericSelectValue={handleOnChange}
            buttonProps={buttonProps}
          />
        )}
      </div>
    </div>
  );
});

export default genericSelectWrapper(GenericSelect);
