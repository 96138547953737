/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import widgetCardStyles from "../../WidgetCardStyles.module.css";
import { Slider } from "@blueprintjs/core";
import modelSigmaHistogramWidgetStyles from "./ModelSigmaHistogramWidgetStyles.module.css";
import classNames from "classnames";
class ModelSigmaHistogramWidgetHeader extends Component {
  constructor(props) {
    super(props);
    const { updateWidgetData, data } = props;

    const sigmas = data.output_data.map(d => d.sigma);

    updateWidgetData({ sigmaToPlot: data.output_data[0] });

    this.state = {
      sigmas: sigmas,
      selectedSigmaIndex: 0,
      data: data,
      updateWidgetData: updateWidgetData
    };
  }

  getChangeHandler = key => {
    return value => this.setState({ [key]: value });
  };

  onSliderChange = value => {
    const { data, updateWidgetData } = this.state;
    const dataToPlot = data.output_data[value];

    updateWidgetData({ sigmaToPlot: dataToPlot });

    this.setState({ selectedSigmaIndex: value });
  };

  sliderLabelRender = sigmas => {
    return value => {
      if (value === 0) return "Low";
      else if (value === sigmas.length - 1) return "High";
      else return "Medium";
    };
  };

  generateSliderFromSigmas = sigmas => {
    return (
      <div className={classNames(modelSigmaHistogramWidgetStyles.headerSlider)}>
        <h5>Choose diversity:</h5>
        <Slider
          initialValue={0}
          min={0}
          max={sigmas.length - 1}
          labelStepSize={1}
          stepSize={1}
          labelRenderer={this.sliderLabelRender(sigmas)}
          onChange={this.onSliderChange}
          value={this.state.selectedSigmaIndex}
          showTrackFill={false}
        />
      </div>
    );
  };

  render() {
    const { sigmas } = this.state;
    const { title } = this.props;

    return (
      <div className={widgetCardStyles.widgetHeader}>
        <h4>{title}</h4>
        {this.generateSliderFromSigmas(sigmas)}
      </div>
    );
  }
}

export default ModelSigmaHistogramWidgetHeader;
