/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import SelectInputs, {
  reactionMapReworkReactionMapFragment,
  reactionMapReworkSampleDataTableFragment
} from "./Steps/SelectInputs";
import ReviewReactionMap from "./Steps/ReviewReactionMap";
import stripFields from "../../../../src-shared/utils/stripFields";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import shortid from "shortid";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";

function ReactionMapReworkTool(props) {
  const {
    initialValues,
    toolSchema,
    toolIntegrationProps,
    isToolIntegrated
  } = props;

  async function onSubmit(values) {
    try {
      const {
        childReactionMapName,
        reactionsThatWillRun,
        reactionTypeCode,
        reactionMaps
      } = values;
      const allSameType = reactionMaps.every(
        rm => rm.reactionType.code === reactionMaps[0].reactionType.code
      );
      const reactionsToUpsert = stripFields(reactionsThatWillRun, [
        "__typename",
        "id",
        "reactionMapId",
        "outputMaterial",
        "inputMaterial",
        "inputAdditiveMaterial",
        "outputAdditiveMaterial"
      ]);

      const childReactionMapCid = shortid();
      const reactionMapSubsetsToUpsert = reactionMaps.map(rm => {
        return {
          parentReactionMapId: rm.id,
          childReactionMapId: `&${childReactionMapCid}`
        };
      });
      const [childReactionMap] = await safeUpsert("reactionMap", {
        cid: childReactionMapCid,
        name: childReactionMapName,
        reactions: reactionsToUpsert,
        reactionTypeCode: allSameType
          ? reactionMaps[0].reactionType.code
          : reactionTypeCode
      });
      await safeUpsert("reactionMapSubsetJoin", reactionMapSubsetsToUpsert);

      return {
        reactionMap: childReactionMap
      };
    } catch (error) {
      console.error(error);
      window.toastr.error("Error creating reaction map");
    }
  }

  const steps = [
    {
      title: "Select Reaction Maps",
      Component: SelectInputs,
      withCustomFooter: true
    },
    {
      title: "Review Reaction Map",
      Component: ReviewReactionMap,
      withCustomFooter: true
    }
  ];

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      enableReinitialize={isToolIntegrated}
      initialValues={initialValues}
    />
  );
}

export default compose(
  withWorkflowInputs(reactionMapReworkReactionMapFragment),
  withWorkflowInputs(reactionMapReworkSampleDataTableFragment)
)(ReactionMapReworkTool);
