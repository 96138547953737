/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import { standardizeVolume, standardizeMass } from "./standardizeUnits";
import getUnit from "./getUnit";
import unitGlobals from "../../unitGlobals";

export default function calculateConcentration({
  volume = 0,
  volumetricUnitCode,
  concentrationUnitCode,
  mass = 0,
  massUnitCode,
  big
}) {
  const standardizedVolume = standardizeVolume(volume, volumetricUnitCode, big);
  const standardizedMass = standardizeMass(mass, massUnitCode, big);

  const standardizedConcentration = big
    ? standardizedMass.div(standardizedVolume)
    : standardizedMass / standardizedVolume; // grams per liter
  const concentrationUnitToUse = getUnit(
    unitGlobals.concentrationUnits,
    concentrationUnitCode
  );
  if (big) {
    return standardizedConcentration.div(
      new Big(concentrationUnitToUse.gramsPerLiter)
    );
  }
  return standardizedConcentration / concentrationUnitToUse.gramsPerLiter;
}
