/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { UNASSIGNED_PROJECT_ID } from "./constants.js";

export const getActiveProjectId = (options = {}) => {
  const activeProjectId = sessionStorage.getItem("activeProjectId");

  const { returnUnassigned } = options;
  if (activeProjectId) {
    if (!returnUnassigned && activeProjectId === UNASSIGNED_PROJECT_ID) {
      return null;
    }
    return activeProjectId;
  } else {
    // tgreen: this needs to be null because it will be applied to filters in graphql
    // if it is an empty string "" it will break the query
    return null;
  }
};
