/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  DialogFooter,
  InputField,
  ReactSelectField,
  RadioGroupField,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { noop } from "lodash";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class AddToPlacementQueueDialog extends React.Component {
  static propTypes = {
    /**
     * Are we placing plates or tubes?
     */
    type: PropTypes.oneOf(["aliquotContainer", "containerArray"]).isRequired,

    /**
     * The ids of the plates or tubes we are placing.
     */
    itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    refetch: PropTypes.func
  };

  onSubmit = async ({ createNewQueue, newQueueName, placementQueueId }) => {
    try {
      const {
        type,
        itemIds,
        refetch,
        placementCb = noop,
        hideModal
      } = this.props;

      if (createNewQueue) {
        const [{ id }] = await safeUpsert("placementQueue", {
          name: newQueueName,
          isEmpty: !itemIds.length
        });
        placementQueueId = id;
      }

      await safeUpsert(
        type,
        itemIds.map(id => ({ id, placementQueueId }))
      );

      if (refetch) await refetch();
      placementCb({ success: true });

      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error adding to manual placement queue.");
    }
  };

  render() {
    const {
      submitting,
      hideModal,
      createNewQueue,
      placementQueues = [],
      handleSubmit
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <RadioGroupField
            name="createNewQueue"
            options={[
              { label: "Create new manual placement queue", value: true },
              { label: "Add to existing manual placement queue", value: false }
            ]}
            label="Use existing or create new manual placement queue?"
          />
          {createNewQueue ? (
            <InputField
              name="newQueueName"
              label="New manual placement queue name"
            />
          ) : (
            <ReactSelectField
              name="placementQueueId"
              label="Select manual placement queue"
              options={arrayToIdOrCodeValuedOptions(placementQueues)}
            />
          )}
        </div>
        <DialogFooter submitting={submitting} hideModal={hideModal} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Add to Manual Placement Queue" }),
  reduxForm({
    form: "AddToPlacementQueueDialogForm",
    enableReinitialize: true,
    validate
  }),
  tgFormValues("createNewQueue"),
  withQuery(["placementQueue", "id name"], { isPlural: true })
)(AddToPlacementQueueDialog);

function validate(values) {
  const errors = {};
  const key = values.createNewQueue ? "newQueueName" : "placementQueueId";
  if (!values[key]) errors[key] = "Required";
  return errors;
}
