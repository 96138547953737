/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
export default gql`
  fragment plateLayerFragment on plateLayer {
    id
    containerArrayId
    plateMapId
    plateLayerDefinition {
      id
      name
    }
    plateZones {
      id
      plateZoneDefinition {
        id
        name
        color
      }
      plateZoneWells {
        id
        aliquotContainerId
        plateMapItemId
      }
    }
  }
`;
