/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { times, get } from "lodash";
import {
  COLUMN_WIDTH,
  BIN_HEIGHT,
  BIN_PART_GAP_HEIGHT,
  CELL_HEIGHT,
  CONSTRUCT_ANNOTATION_HEIGHT
} from "../../../../src-shared/components/HierarchicalDesign/ClassicGrid/constants";
import PropTypes from "prop-types";
import BinHeader from "./BinHeader";
import Cell from "./Cell";

import "./style.css";
import {
  getElementsInBin,
  getDsfForCard,
  getItemOfType,
  getClassicNumberOfRows
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import { getInputCardByIndex } from "../../../../src-shared/selectors/classicViewSelectors";
import { Tooltip } from "@blueprintjs/core";
import tooltips from "../../../../src-shared/constants/tooltips";
import stopOutOfSyncReduxRender from "../../../utils/stopOutOfSyncReduxRender";

const mapStateToProps = (state, { binId, binIndex }) => {
  const elements = getElementsInBin(state, binId);
  const inputCard = getInputCardByIndex(state, binIndex);
  return {
    elements,
    numberOfRows: getClassicNumberOfRows(state),
    dsf: getDsfForCard(state, inputCard.id)
  };
};

/**
 * A bin the classic design view. Represents an entire column, i.e. includes
 * the bin header and the cells.
 */
class Bin extends React.Component {
  static propTypes = {
    /**
     * The id of the bin we are rendering.
     */
    binId: PropTypes.string.isRequired,

    /**
     * The index of bin we are rendering.
     */
    binIndex: PropTypes.number.isRequired,

    /**
     * An array with information pertaining to the elements this
     * bin contains. This should be injected by the container via
     * the selector `getElementsInBin(state, setId)`.
     */
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * The id of the element.
         */
        id: PropTypes.string.isRequired,
        /**
         * The row index of the element.
         */
        index: PropTypes.number.isRequired
      })
    ).isRequired,

    /**
     * The number of rows of cells to render.
     */
    numberOfRows: PropTypes.number.isRequired,

    /**
     * Does the right side of the bin contain a direct synthesis firewall?
     */
    dsf: PropTypes.bool
  };

  calcBinTransform = () =>
    `translate(${this.props.binIndex * COLUMN_WIDTH}, 0)`;

  renderCells = () => {
    const {
      elements,
      numberOfRows,
      binId,
      binIndex,
      state,
      inputCard,
      updateBin,
      changeFas,
      designId
    } = this.props;
    return times(numberOfRows, cellIndex => (
      <Cell
        key={cellIndex}
        elementId={get(
          elements.find(el => el.index === cellIndex),
          "id"
        )}
        binId={binId}
        designId={designId}
        binIndex={binIndex}
        cellIndex={cellIndex}
        state={state}
        inputCard={inputCard}
        updateBin={updateBin}
        changeFas={changeFas}
      />
    ));
  };

  renderDsf = () => {
    const { dsf, numberOfRows, hasConstructAnnotations } = this.props;
    if (!dsf) return null;
    return (
      <g>
        <Tooltip wrapperTagName="g" targetTagName="g" content={tooltips.dsf}>
          <line
            {...{
              x1: COLUMN_WIDTH - 1,
              y1: 0 + +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT,
              x2: COLUMN_WIDTH - 1,
              y2:
                BIN_HEIGHT +
                BIN_PART_GAP_HEIGHT +
                numberOfRows * CELL_HEIGHT +
                +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT,
              stroke: "firebrick",
              strokeWidth: 3,
              shapeRendering: "crispEdges",
              "data-test": "direct-synthesis-firewall",
              strokeDasharray:
                BIN_HEIGHT -
                2 +
                ", " +
                (BIN_PART_GAP_HEIGHT + 1) +
                ", " +
                (numberOfRows * CELL_HEIGHT + 1) +
                ",0"
            }}
          />
        </Tooltip>
      </g>
    );
  };

  render() {
    const { binId, binIndex, hasConstructAnnotations } = this.props;
    return (
      <g
        className="simplified-bin-container"
        transform={this.calcBinTransform()}
      >
        <BinHeader
          binId={binId}
          binIndex={binIndex}
          hasConstructAnnotations={hasConstructAnnotations}
        />
        <g
          className="simplified-cells-container"
          transform={`translate(0, ${BIN_HEIGHT +
            BIN_PART_GAP_HEIGHT +
            +hasConstructAnnotations * CONSTRUCT_ANNOTATION_HEIGHT})`}
        >
          {this.renderCells()}
        </g>
        {this.renderDsf()}
      </g>
    );
  }
}

export const DumbBin = Bin;

export default compose(
  stopOutOfSyncReduxRender((state, props) =>
    getItemOfType(state, "bin", props.binId)
  ),
  stopOutOfSyncReduxRender((state, props) =>
    getInputCardByIndex(state, props.binIndex)
  ),
  connect(mapStateToProps)
)(Bin);
