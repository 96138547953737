/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import duplicateDialogAsPromise from "../components/Dialogs/DuplicatesDialog";
import { safeQuery } from "../apolloMethods";

export default async function(platesOrTubes, isTube) {
  const model = isTube ? "aliquotContainer" : "containerArray";
  const qb = new QueryBuilder(model);
  const filter = qb
    .whereAny({
      name: platesOrTubes.map(p => p.name),
      "barcode.barcodeString": platesOrTubes.reduce((acc, p) => {
        const barcode = get(p, "barcode.barcodeString");
        if (barcode) {
          acc.push(barcode);
        }
        return acc;
      }, [])
    })
    .toJSON();
  const duplicatePlates = await safeQuery(
    [model, "id name barcode { id barcodeString }"],
    {
      variables: {
        filter
      }
    }
  );

  const duplicates = [];
  let platesToCreate = [];
  const readableName = isTube ? "tube" : "plate";
  const nameCounter = {};
  const barcodeCounter = {};
  platesOrTubes.forEach(plate => {
    const barcode = get(plate, "barcode.barcodeString");
    if (plate.name) {
      nameCounter[plate.name] = (nameCounter[plate.name] || 0) + 1;
    }
    if (barcode) {
      barcodeCounter[barcode] = (barcodeCounter[barcode] || 0) + 1;
    }
  });
  platesOrTubes.forEach((plate, i) => {
    const barcode = get(plate, "barcode.barcodeString");
    const duplicateOnName = nameCounter[plate.name] > 1;
    const duplicateOnBarcode = barcodeCounter[barcode] > 1;
    const duplicateOnNameInDb = duplicatePlates.find(
      d => d.name === plate.name
    );
    const duplicateOnBarcodeInDb = duplicatePlates.find(
      d =>
        get(d, "barcode.barcodeString") &&
        get(d, "barcode.barcodeString") === barcode
    );
    let message;
    if (duplicateOnNameInDb && duplicateOnBarcodeInDb) {
      message = `Duplicate ${readableName} with name and barcode in library`;
    } else if (duplicateOnNameInDb) {
      message = `Duplicate ${readableName} with name in library`;
    } else if (duplicateOnBarcodeInDb) {
      message = `Duplicate ${readableName} with barcode in library`;
    } else if (duplicateOnName && duplicateOnBarcode) {
      message = `Duplicate ${readableName} with name and barcode in import`;
    } else if (duplicateOnName) {
      message = `Duplicate ${readableName} with name in import`;
    } else if (duplicateOnBarcode) {
      message = `Duplicate ${readableName} with barcode in import`;
    }
    if (message) {
      duplicates.push({
        id: i,
        name: plate.name,
        barcode,
        record: plate,
        message
      });
    } else {
      platesToCreate.push(plate);
    }
  });
  if (duplicates.length) {
    const duplicatesToImport = await duplicateDialogAsPromise({
      dialogProps: {
        title: `Select Duplicate ${isTube ? "Tubes" : "Plates"} to Import`,
        style: {
          width: 650
        }
      },
      schema: [
        {
          path: "name",
          width: 120
        },
        {
          path: "barcode",
          width: 120
        },
        "message"
      ],
      entities: duplicates
    });
    if (duplicatesToImport.userCancelled) return [];
    platesToCreate = platesToCreate.concat(
      duplicatesToImport.map(d => d.record)
    );
  }
  return platesToCreate;
}
