/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { formValues } from "redux-form";
import { ReactSelectField } from "@teselagen/ui";
import GenericSelect from "../../GenericSelect";
import { arrayToItemValuedOptions } from "../../utils/formUtils";

function LabelSettingsForm({
  endpointData = {},
  params,
  integrationLabelMedia,
  labelMedia
}) {
  const {
    labelFormats: endpointLabelFormats = [],
    labelMedia: endpointLabelMedia = []
  } = endpointData;

  const isContainerArray = params.itemType === "plate";

  let labelMediaSelect;
  let labelFormatSelect;

  let labelMediaId;
  if (endpointLabelMedia.length) {
    const defaultValue = endpointLabelMedia.find(m => m.isDefault);
    labelMediaSelect = (
      <ReactSelectField
        isRequired
        defaultValue={defaultValue}
        name="integrationLabelMedia"
        label="Label Media"
        options={arrayToItemValuedOptions(endpointLabelMedia)}
      />
    );
    if (integrationLabelMedia) {
      labelMediaId = integrationLabelMedia.id;
    }
  } else {
    labelMediaSelect = (
      <GenericSelect
        {...{
          name: "labelMedia",
          asReactSelect: true,
          isRequired: true,
          label: "Label Media",
          fragment: ["labelMedia", "id name"]
        }}
      />
    );
    if (labelMedia) {
      labelMediaId = labelMedia.id;
    }
  }

  if (endpointLabelFormats.length) {
    const defaultValue = endpointLabelFormats.find(m => m.isDefault);
    labelFormatSelect = (
      <ReactSelectField
        isRequired
        defaultValue={defaultValue}
        disabled={!labelMediaId}
        name="integrationLabelFormat"
        label="Label Format"
        options={arrayToItemValuedOptions(endpointLabelFormats)}
      />
    );
  } else {
    labelFormatSelect = (
      <GenericSelect
        {...{
          name: "labelFormat",
          disabled: !labelMediaId,
          asReactSelect: true,
          isRequired: true,
          label: "Label Format",
          fragment: ["labelFormat", "id name"],
          additionalFilter: {
            isContainerArray,
            "labelFormatLabelMedias.labelMediaId": labelMediaId
          }
        }}
      />
    );
  }

  return (
    <div>
      {labelMediaSelect}
      {labelFormatSelect}
    </div>
  );
}

export default formValues(
  "integrationLabelMedia",
  "labelMedia"
)(LabelSettingsForm);
