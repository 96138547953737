/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import QueryBuilder from "tg-client-query-builder";
import {
  DialogFooter,
  ReactSelectField,
  BlueprintError,
  IntentText,
  wrapDialog
} from "@teselagen/ui";
import pluralize from "pluralize";
import withQuery from "../../../../src-shared/withQuery";

import PropTypes from "prop-types";
import { noop, times } from "lodash";
import shortid from "shortid";

import { Button, Intent, Classes } from "@blueprintjs/core";
import { withProps } from "recompose";

import { showDialog } from "../../../../src-shared/GlobalDialog";
import {
  arrayToIdOrCodeValuedOptions,
  throwFormError
} from "../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../src-shared/apolloMethods";

class AssignPlatePlacementDialog extends React.Component {
  static propTypes = {
    /**
     * The ids of the plates we are placing.
     */
    plateIds: PropTypes.arrayOf(PropTypes.string),

    tubeIds: PropTypes.arrayOf(PropTypes.string),

    /**
     * The container array type of the plates given in the `plateIds` prop.
     */
    containerArrayType: PropTypes.shape({ id: PropTypes.string.isRequired }),

    aliquotContainerType: PropTypes.shape({
      code: PropTypes.string.isRequired
    }),

    refetch: PropTypes.func
  };

  addToQueue = () => {
    const { plateIds, tubeIds, placementCb = noop, refetch } = this.props;

    showDialog({
      modalType: "ADD_TO_PLACEMENT_QUEUE",
      onClose: () => placementCb({ success: false }),
      modalProps: {
        type: plateIds ? "containerArray" : "aliquotContainer",
        placementCb,
        itemIds: plateIds || tubeIds,
        refetch
      }
    });
  };

  placeManually = async () => {
    const { plateIds, tubeIds, refetch = noop, placementCb } = this.props;
    try {
      refetch();
      let fragment = "id name assignedPosition { id }";
      if (!plateIds) {
        // this is needed for placing tubes into racks
        fragment += " aliquotContainerTypeCode";
      }
      const recordsToMove = await safeQuery(
        [plateIds ? "containerArray" : "aliquotContainer", fragment],
        {
          variables: {
            filter: {
              id: plateIds || tubeIds
            }
          }
        }
      );
      showDialog({
        modalType: "MoveRecordsToEquipmentDialog",
        onClose: () => placementCb && placementCb({ success: false }),
        modalProps: {
          placementCb,
          recordsToMove,
          refetch
        }
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading.");
    }
  };

  placeUsingStrategy = async ({ placementStrategyId }) => {
    const { hideModal, refetch = noop, placementCb = noop } = this.props;
    let { tubeIds = [], plateIds = [] } = this.props;
    const type = plateIds.length ? "containerArray" : "aliquotContainer";
    const itemIds = plateIds.length ? plateIds : tubeIds;
    try {
      const placementStrategy = await safeQuery(
        [
          "placementStrategy",
          /* GraphQL */ `
            {
              id
              name
              isDestinationContainerArray
              destinationContainerArrayType {
                id
                name
                containerFormat {
                  code
                  rowCount
                  columnCount
                  quadrantSize
                }
              }
              generateBoxes
            }
          `
        ],
        {
          variables: {
            id: placementStrategyId
          }
        }
      );

      let isGeneratingRacks = false;
      if (
        placementStrategy.isDestinationContainerArray &&
        placementStrategy.generateBoxes
      ) {
        const numBoxes = Math.ceil(
          tubeIds.length /
            placementStrategy.destinationContainerArrayType.containerFormat
              .quadrantSize
        );
        isGeneratingRacks = true;
        tubeIds = [];
        plateIds = times(numBoxes, () => shortid());
      }

      let mixedTubePlacement = false;
      if (
        placementStrategy.isDestinationContainerArray &&
        !placementStrategy.generateBoxes
      ) {
        mixedTubePlacement = true;
      }

      if (mixedTubePlacement) {
        const {
          data: { success, err, boxList = [] }
        } = await window.serverApi.request({
          method: "POST",
          url: "/placeItemsUsingStrategy",
          data: {
            aliquotContainerIds: tubeIds,
            placementStrategyId,
            getListOfBoxes: true,
            performMutations: false
          }
        });
        if (!success) throw new Error(err.message || err);
        const tubes = await safeQuery(
          [
            "aliquotContainer",
            /* GraphQL */ `
              {
                id
                name
                barcode {
                  id
                  barcodeString
                }
                aliquotContainerTypeCode
              }
            `
          ],
          {
            variables: {
              filter: {
                id: tubeIds
              }
            }
          }
        );
        hideModal();
        showDialog({
          onClose: () => placementCb && placementCb({ success: false }),
          modalType: "CHOOSE_BOXES_FOR_TUBE_STRATEGY",
          modalProps: {
            boxList,
            tubes,
            refetch,
            placementStrategyId,
            placementCb
          }
        });
      } else {
        const {
          data: {
            success,
            err,
            availableLocations,
            placementInformation,
            allItemsPlaced
            // placedTubes
          }
        } = await window.serverApi.request({
          method: "POST",
          url: "/placeItemsUsingStrategy",
          data: {
            containerArrayIds: plateIds,
            aliquotContainerIds: tubeIds,
            placementStrategyId,
            performMutations: false
          }
        });

        if (!success) throw new Error(err.message || err);

        // If we were not able to place all of the items, right now
        // we just abort.
        if (!allItemsPlaced) {
          throw new Error(
            "The placement strategy did not have enough available space. Unable to continue."
          );
        }

        showDialog({
          modalType: "PLACE_ACCORDING_TO_STRATEGY",
          onClose: () => placementCb && placementCb({ success: false }),
          modalProps: {
            placementCb,
            placementStrategyId,
            numberOfRacksToGenerate: isGeneratingRacks
              ? plateIds.length
              : undefined,
            type,
            itemIds,
            availableLocations,
            placementInformation,
            refetch
          }
        });
      }

      // hideModal();
    } catch (error) {
      console.error(error);
      throwFormError(
        error.message || "Error assigning plate placement strategy."
      );
    }
  };

  hasNoOptions() {
    const { placementStrategiesLoading, placementStrategies = [] } = this.props;
    return !placementStrategiesLoading && !placementStrategies.length;
  }

  render() {
    const {
      submitting,
      handleSubmit,
      hideModal,
      placementStrategies = [],
      containerArrayTypes = [],
      placementCb,
      placementStrategyId,
      error
    } = this.props;

    return (
      <form>
        <div className={Classes.DIALOG_BODY}>
          <h6>Place Manually</h6>
          <i>Choose a specific position in an equipment.</i>
          <br />
          <Button
            style={{ marginTop: 10 }}
            text="Place Manually"
            loading={submitting}
            onClick={handleSubmit(this.placeManually)}
            intent={Intent.PRIMARY}
          />
          {!placementCb && (
            <React.Fragment>
              <hr className="tg-section-break" />
              <h6>Add to Queue</h6>
              <i>Add to a list to place later.</i>
              <br />
              <Button
                style={{ marginTop: 10 }}
                text="Add to Queue"
                loading={submitting}
                onClick={this.addToQueue}
                intent={Intent.PRIMARY}
              />
            </React.Fragment>
          )}
          <hr className="tg-section-break" />
          <h6>Place Using Strategy</h6>
          <i>Place automatically using a placement strategy</i>
          <div style={{ marginTop: 15 }}>
            {this.hasNoOptions() ? (
              <IntentText intent="warning">
                No placement strategies found for selected{" "}
                {containerArrayTypes.length ? "plate" : "tube"} types.
              </IntentText>
            ) : (
              <ReactSelectField
                name="placementStrategyId"
                label="Placement Strategy"
                placeholder="Please select a placement strategy..."
                options={arrayToIdOrCodeValuedOptions(placementStrategies)}
              />
            )}
            <Button
              text="Place Using Strategy"
              loading={submitting}
              disabled={this.hasNoOptions() || !placementStrategyId}
              onClick={handleSubmit(this.placeUsingStrategy)}
              intent={Intent.PRIMARY}
            />
          </div>
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          noCancel
          text="Don't Place Right Now"
          intent="none"
          onClick={hideModal}
        />
      </form>
    );
  }
}

export default compose(
  withProps(props => {
    const newProps = {};
    if (props.containerArrayType || props.aliquotContainerType) {
      if (props.containerArrayType) {
        newProps.containerArrayTypes = [props.containerArrayType];
      } else {
        newProps.aliquotContainerTypes = [props.aliquotContainerType];
      }
    }

    // this will ensure our logic of plateIds || tubeIds will work above
    if (props.plateIds && props.plateIds.length) {
      newProps.tubeIds = undefined;
    } else {
      newProps.plateIds = undefined;
    }
    return newProps;
  }),
  wrapDialog(({ tubeIds = [], plateIds = [] }) => {
    const itemIds = tubeIds.length ? tubeIds : plateIds;
    const name = tubeIds.length ? "tube" : "plate";
    const plural = itemIds.length > 1;
    const maybePluralize = word => (plural ? pluralize(word) : word);
    return {
      title: `Where should ${maybePluralize("this")} ${maybePluralize(
        name
      )} go?`,
      isCloseButtonShown: false,
      style: { width: 550 }
    };
  }),
  reduxForm({
    form: "AssignPlatePlacementDialogForm",
    enableReinitialize: true
  }),
  tgFormValues("placementStrategyId"),
  withQuery(["placementStrategy", "id name"], {
    isPlural: true,
    options: ({ containerArrayTypes = [], aliquotContainerTypes = [] }) => {
      const containerArrayTypeIds =
        containerArrayTypes.length > 0 &&
        containerArrayTypes.map(type => type.id);
      const aliquotContainerTypeCodes =
        aliquotContainerTypes.length > 0 &&
        aliquotContainerTypes.map(type => type.code);
      const qb = new QueryBuilder("placementStrategy");

      qb.whereAll({
        isContainerArrayStrategy: !(aliquotContainerTypes.length > 0)
      });
      if (aliquotContainerTypes.length > 0) {
        aliquotContainerTypeCodes.forEach(code => {
          qb.whereAll({
            "placementContainerTypes.aliquotContainerTypeCode": code
          });
        });
      } else {
        containerArrayTypeIds.forEach(id => {
          qb.whereAll({
            "placementContainerTypes.containerArrayTypeId": id
          });
        });
      }
      return {
        variables: {
          filter: qb.toJSON()
        }
      };
    }
  })
)(AssignPlatePlacementDialog);
