/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const COLUMN_WIDTH = 120;

export const CONSTRUCT_ANNOTATION_HEIGHT = 19;

export const BIN_HEIGHT = 100;
export const BIN_FILL_COLOR = "#fefefe";
export const BIN_OUTLINE_COLOR = "#E0E3E6";
export const BIN_OUTLINE_WIDTH = 1; // Not really sure right now.
export const BIN_HOVER_OUTLINE_COLOR = "#4A4B4C";
export const BIN_SELECTED_OUTLINE_COLOR = "#5BC0DE";
export const BIN_SELECTED_FILL_COLOR = "#E0E3E6";

export const BIN_PART_GAP_HEIGHT = 14;

// export const NEXT_LEVEL_PART_HEIGHT = 12
// export const NEXT_LEVEL_PART_PATH_HEIGHT = 6
// export const NEXT_LEVEL_PART_CONTAINER_HEIGHT = 0
export const SQUIGGLY_END_STRENGTH = 3;

export const SCISSOR_ICON_HEIGHT = 16;
export const SCISSOR_ICON_WIDTH = 16;
export const SCISSOR_ICON_Y_PADDING = 10;
export const SCISSOR_ICON_X_PADDING = 2;
export const SCISSOR_ICON_L_ROTATION = -160;
export const SCISSOR_ICON_R_ROTATION = -20;

export const CELL_HEIGHT = 40;
export const CELL_FILL_COLOR = "#fefefe";
export const CELL_OUTLINE_COLOR = "#E0E3E6";
//    export const PHANTOM_CELL_OUTLINE_COLOR = "#e10000" #KC_REFACTOR for when we want to change the outline of a "phantom" cell
export const PHANTOM_CELL_OUTLINE_COLOR = "#E0E3E6";
export const CELL_UNMAPPED_OUTLINE_COLOR = "#e10000";
export const CELL_MAPPED_OUTLINE_COLOR = "#5BC0DE";
export const CELL_OUTLINE_WIDTH = 1; // Not really sure right now.
export const CELL_HOVER_OUTLINE_COLOR = "#4A4B4C";
export const CELL_SELECTED_OUTLINE_COLOR = "#5BC0DE";
export const CELL_SELECTED_FILL_COLOR = "#E0E3E6";

export const FLIP_ARROW_PATH =
  "m 27.64032, 9.92550 -9.35672,-6.23781 0,3.36062 -6.61209,0 0,5.75438 6.61209,0 0,3.36062 9.35672,-6.23781 z";

export const ADD_BUTTON_HEIGHT = 25;
export const ADD_BUTTON_WIDTH = 25;

export const MAX_CELL_NAME_LENGTH_WITH_ICON = 10;
