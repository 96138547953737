/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty, set } from "lodash";
import { basename } from "path";
import shortid from "shortid";
import { parseCsvOrExcelFile } from "../../../tg-iso-shared/src/utils/fileUtils";

export const dataTableTypeCodesWithCustomPaths = [
  "CONTAINER_ARRAY_PLACEMENT_LIST",
  "ALIQUOT_CONTAINER_PLACEMENT_LIST",
  "J5_CONSTRUCT_SELECTION",
  "SAMPLE_MULTIPLEXING_INPUT",
  "J5_ASSEMBLY_PIECE_LIST"
];

export async function parseDataTableImportData({
  csvFiles,
  dataTableType,
  dataSetName
}) {
  const keyedSchema = {};
  dataTableType.rowSchema.forEach(
    r => (keyedSchema[r.displayName.toLowerCase()] = r)
  );

  let dataSet;
  if (dataSetName) {
    dataSet = {
      cid: shortid(),
      name: dataSetName,
      type: dataTableType.name,
      status: "IN_PROGRESS"
    };
  }

  const dataTables = [];
  for (const dataTableFile of csvFiles) {
    const {
      data: csvData,
      meta: { fields }
    } = await parseCsvOrExcelFile(dataTableFile);

    const badHeaders = fields.filter(
      header => !keyedSchema[header.toLowerCase()]
    );
    if (badHeaders.length) {
      return {
        error: `These headers are not part of the table schema (file ${
          dataTableFile.name
        }): ${badHeaders.join(", ")}`
      };
    }
    const fileName = basename(dataTableFile.name).split(".")[0];
    const dataRows = [];
    // clean columns that are not in schema
    csvData.forEach(_rowValues => {
      const rowValues = {};
      Object.keys(_rowValues).forEach(key => {
        const columnSchema = keyedSchema[key.toLowerCase()];
        if (columnSchema) {
          const rowVal = _rowValues[key];
          set(rowValues, columnSchema.path.replace("rowValues.", ""), rowVal);
        }
      });
      if (!isEmpty(rowValues)) {
        dataRows.push({
          index: dataRows.length,
          rowValues
        });
      }
    });

    if (!dataRows.length) {
      return {
        error: `No proper data rows found in csv ${dataTableFile.name}.`
      };
    }
    dataTables.push({
      name: fileName,
      dataTableTypeCode: dataTableType.code,
      dataRows,
      dataSetId: dataSet ? `&${dataSet.cid}` : null
    });
  }
  return {
    dataTables,
    dataSet
  };
}
