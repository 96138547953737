/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem, MenuDivider } from "@blueprintjs/core";
import { cloneDeep } from "lodash";
import { compose } from "redux";
import schematicFragment from "../graphql/fragments/schematicFragment";
import { safeQuery } from "../../src-shared/apolloMethods";
import { showDialog } from "../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import schematicToMutationInput from "../../../tg-iso-design/designImport/schematicToMutationInput";
import performOldStyleMutations from "../../../tg-iso-design/designImport/performOldStyleMutations";

const schema = {
  model: "schematic",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "description", type: "string", displayName: "Description" }
  ]
};

class SchematicLibrary extends Component {
  onNewItemClick = () => {
    showDialog({
      modalType: "SCHEMATIC",
      modalProps: {
        refetchSchematics: this.props.refetch
      }
    });
  };

  additionalContextMenu = selectedRecords => {
    const menuItems = [];
    const duplicateSchematic = (
      <MenuItem
        key="duplicateSchematic"
        text="Duplicate"
        icon="duplicate"
        onClick={() => {
          showDialog({
            modalType: "DUPLICATE_SCHEMATIC",
            modalProps: {
              selectedRecords,
              refetch: this.props.refetch
            }
          });
        }}
      />
    );

    menuItems.push(duplicateSchematic);

    if (selectedRecords.length === 1) {
      const createDesignMenuItem = (
        <MenuItem
          key="createDesign"
          text="Create Design from this Schematic"
          icon="add"
          onClick={async () => {
            try {
              const _schematic = await safeQuery(schematicFragment, {
                variables: {
                  id: selectedRecords[0].id
                }
              });

              const schematic = cloneDeep(_schematic);

              schematic.schematicSets.sort((a, b) => a.index - b.index);
              schematic.schematicSets.forEach(s =>
                s.schematicElements.sort((a, b) => a.index - b.index)
              );

              const mutations = schematicToMutationInput(schematic);
              const { designId } = await performOldStyleMutations({
                mutations
              });

              if (designId) {
                this.props.history.push(`/designs/${designId}`);
              } else {
                throw new Error("creating design from schematic failed");
              }
            } catch (error) {
              console.error("error:", error);
              window.toastr.error("Error creating design.");
            }
          }}
        />
      );
      menuItems.push(<MenuDivider key="md1" />);
      menuItems.push(createDesignMenuItem);
    }

    return menuItems;
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        isLibraryTable
        onNewItemClick={this.onNewItemClick}
        generateExtraContextMenuItems={this.additionalContextMenu}
        // ViewerComponent={ViewerComponent}
      />
    );
  }
}

const fragment = gql`
  fragment schematicLibraryFragment on schematic {
    id
    name
    description
  }
`;

export default compose(
  libraryEnhancer({
    fragment,
    schema,
    withSelectedEntities: true
  })
)(SchematicLibrary);
