/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const microbialReactionPlanningMaterialFragment = gql`
  fragment microbialReactionPlanningMaterialFragment on material {
    id
    name
    materialTypeCode
    polynucleotideMaterialSequence {
      id
    }
    strain {
      id
      name
    }
    microbialMaterialMicrobialMaterialPlasmids {
      id
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          name
        }
      }
    }
  }
`;

const microbialReactionPlanningAliquotFragment = gql`
  fragment microbialReactionPlanningAliquotFragment on aliquot {
    id
    isDry
    volume
    volumetricUnitCode
    sample {
      id
      sampleTypeCode
      sampleFormulations {
        id
        materialCompositions {
          id
          material {
            id
            name
            materialTypeCode
            polynucleotideMaterialSequence {
              id
            }
            microbialMaterialMicrobialMaterialPlasmids {
              id
              polynucleotideMaterial {
                id
                polynucleotideMaterialSequence {
                  id
                  name
                }
              }
            }
          }
        }
      }
      material {
        ...microbialReactionPlanningMaterialFragment
      }
    }
  }
  ${microbialReactionPlanningMaterialFragment}
`;
export const microbialReactionPlanningPlateFragment = gql`
  fragment microbialReactionPlanningPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormatCode
    }
    aliquotContainers {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        code
        name
        isTube
        maxVolume
        deadVolume
        volumetricUnitCode
        deadVolumetricUnitCode
      }
      rowPosition
      columnPosition
      aliquot {
        ...microbialReactionPlanningAliquotFragment
      }
    }
  }
  ${microbialReactionPlanningAliquotFragment}
`;
