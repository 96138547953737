/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import DesignInspector from "../components/HierarchicalDesign/DesignInspector";
import actions from "../../src-shared/redux/actions";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";

const mapStateToProps = state => ({
  open: state.ui.designEditor.inspector.open,
  locked: state.ui.designEditor.inspector.locked,
  activePanel: state.ui.designEditor.inspector.activePanel,
  panelArgs: state.ui.designEditor.inspector.panelArgs,
  readOnly: isDesignLocked(state),
  width: state.ui.designEditor.inspector.width
});

const mapDispatchToProps = {
  toggleOpen: actions.ui.designEditor.inspector.toggle,
  toggleInspector: actions.ui.designEditor.inspector.toggle,
  setActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  addToUndoStack: actions.ui.designEditor.undo.addToUndoStack,
  setWidth: actions.ui.designEditor.inspector.setWidth
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DesignInspector
);
