/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment customJ5ParameterFragment on customJ5Parameter {
    id
    cid
    name
    isLocalToThisDesignId
    dirSynthCostPerBp
    dirSynthMinCostPerPiece
    ggateOverhangBps
    ggateRecognitionSeq
    ggateTerminiExtraSeq
    ggateTerminiExtraSeqAlt
    gibsonOverlapBps
    gibsonOverlapMaxTm
    gibsonOverlapMinTm
    hmlgyMaxFractionMismatches
    hmlgyMinLengthBps
    includeLineageFeatures
    masterOligoNumOfDigits
    masterPlasmidNumOfDigits
    maxIdsGgateOverhangsCmptbl
    maxOligoLengthBps
    minDnaSynthLengthBps
    maxDnaSynthLengthBps
    minFragmentSizeGibsonBps
    minPcrProductBps
    mispriming3PBpToWarnIfHit
    misprimingMinTm
    misprimingOligoConc
    misprimingSaltConc
    oligoMaxLenNoPagePurBps
    oligoPagePurCostPerPiece
    oligoSynthesisCostPerBp
    outputSeqFormat
    primerDnaConc
    primerGcClamp
    primerMaxDiffTm
    primerMaxSelfAnyTh
    primerMaxSelfEndTh
    primerMaxSize
    primerMaxTm
    primerMinSize
    primerMinTm
    primerPairMaxComplAnyTh
    primerPairMaxComplEndTh
    primerSaltCorrections
    primerTmSantaLucia
    suppressPrimerAnnotations
    suppressPurePrimers
    gatewayAttSites
  }
`;
