/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../GlobalDialog";
import UpdateRecordAliasesDialog from "../UpdateRecordAliasesDialog";

export default function EditAliasesMenuItem({ record, noIcon }) {
  return (
    <MenuItem
      key="updateAliases"
      text="Update Aliases"
      icon={noIcon ? undefined : "edit"}
      onClick={() =>
        showDialog({
          ModalComponent: UpdateRecordAliasesDialog,
          modalProps: {
            record
          }
        })
      }
    />
  );
}
