/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { showConfirmationDialog } from "@teselagen/ui";
import axios from "axios";

export default class EmbossTranslator {
  /**
   * Build a job parameters object
   * @returns Parameter object to start a new job based on this class attributes
   */
  buildJobParams(
    sequence,
    organism // algorithm)
  ) {
    const params = new URLSearchParams();
    params.append("email", "contact@teselagen.com");
    params.append("sequence", sequence);
    params.append("codontable", organism);
    return params;
  }

  /**
   * Return output from emboss back trans seq api
   * @return {String}
   */
  async sendReq(
    sequence,
    organism, // algorithm)
    ambig
  ) {
    const params = this.buildJobParams(sequence, organism);
    let jobId = null;
    const serviceType = ambig ? "emboss_backtranambig" : "emboss_backtranseq";
    await axios
      .post(
        `https://www.ebi.ac.uk/Tools/services/rest/${serviceType}/run`,
        params,
        {
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(response => {
        jobId = response.data;
      });
    const jobStatus = await this.checkJobStatus({ jobId, serviceType });
    if (jobStatus) {
      const response = await axios.get(
        `https://www.ebi.ac.uk/Tools/services/rest/${serviceType}/result/` +
          jobId +
          "/out"
      );
      return response;
    } else {
      return "API Error";
    }
  }

  /**
   * Recusive function to
   * @param {*} jobId job id to check
   * @param {*} acc accumulator for recursion
   * @returns
   */
  async checkJobStatus({ jobId, serviceType }, acc = 0) {
    // console.log({jobId, serviceType});
    const finished = await this.checkJobStatusHelper({ jobId, serviceType });
    if (acc > (window.Cypress ? 15 : 6) && !finished) {
      // console.log("too many reqs");
      const keepGoing = await showConfirmationDialog({
        text: "The EBI API is taking a while to respond. Would you like to continue waiting or stop now?",
        cancelButtonText: "Stop Now",
        confirmButtonText: "Keep trying"
      });
      if (!keepGoing) throw new Error("The EBI API took too long to respond.");
      return this.checkJobStatus({ jobId, serviceType }, 0); //reset the accumulator
    } else if (finished) {
      return true;
    } else {
      await this.sleep(2000);
      return this.checkJobStatus({ jobId, serviceType }, acc + 1);
    }
  }

  /**
   * Check the status of an active job and return a bool for it is complete
   * @param {string} jobId - the job id we are checking the status of
   * @return {boolean} completion status
   */
  async checkJobStatusHelper({ jobId, serviceType }) {
    const params = new URLSearchParams();
    params.append("jobId", jobId);
    try {
      const response = await axios.get(
        `https://www.ebi.ac.uk/Tools/services/rest/${serviceType}/status/` +
          jobId
      );
      return response.data === "FINISHED";
    } catch (e) {
      console.error(`e:`, e);
      return false;
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
