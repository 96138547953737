/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { FieldArray } from "redux-form";
import { Intent, Button, Divider, Classes } from "@blueprintjs/core";
import { get } from "lodash";
import { InputField } from "@teselagen/ui";
import classNames from "classnames";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import PlateCsvWithTabs from "../PlateCsvWithTabs";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import UnitFields from "../../../UnitFields";
import RemoveButton from "../../../../../src-shared/FieldArrayButtons/RemoveButton";

import determineBlackOrWhiteTextColor from "../../../../../src-shared/utils/determineBlackOrWhiteTextColor";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { removeDecimal } from "../../../../../src-shared/utils/formUtils";

export const REACTION_CONDITION_GROUP_CSV_HEADER = "Reaction Condition Group";

class ReactionConditions extends Component {
  saveReagentsToCondition = (fields, items) => {
    items.forEach(item => {
      const newItem = {};
      if (item.__typename === "material") {
        newItem.material = item;
      } else {
        newItem.additiveMaterial = item;
      }
      fields.push(newItem);
    });
  };

  addReagentsToCondition = fields => {
    showDialog({
      modalType: "SELECT_REAGENTS_OR_MATERIALS",
      modalProps: {
        onSelect: items => this.saveReagentsToCondition(fields, items),
        materialFragment: ["material", "id name"],
        reagentFragment: ["additiveMaterial", "id name isDry"]
      }
    });
  };

  loadReactionConditionPreset = fields => {
    const onSelect = reactionConditionGroupPresets => {
      reactionConditionGroupPresets.forEach(group => {
        group.reactionConditionPresets.forEach(preset => {
          fields.push({ ...preset, groupName: group.name });
        });
      });
    };
    showDialog({
      modalType: "SELECT_GENERIC_ITEMS",
      modalProps: {
        isMultiSelect: true,
        fragment: ["reactionConditionGroupPreset", "id name updatedAt"],
        onSelect,
        additionalDataFragment: [
          "reactionConditionGroupPreset",
          `
          id
          name
          reactionConditionPresets {
            id
            volume
            volumetricUnitCode
            concentration
            concentrationUnitCode
            mass
            massUnitCode
            material {
              id
              name
            }
            additiveMaterial {
              id
              name
              isDry
            }
          }
          `
        ]
      }
    });
  };

  saveReactionConditionPreset = (fieldRowInfo, reactionConditionGroupName) => {
    showDialog({
      modalType: "SAVE_REACTION_CONDITION_GROUP_PRESET",
      modalProps: {
        reactionConditionGroupName,
        fieldRowInfo
      }
    });
  };

  render() {
    const {
      assayPlanningCsv,
      reactionConditions,
      userLayerColor,
      stepFormProps: { change },
      Footer,
      footerProps,
      toolSchema,
      reactionConditionRows = {}
    } = this.props;

    const csvData = get(assayPlanningCsv, "[0].parsedCsv.data", []);
    const csvHeaders = get(assayPlanningCsv, "[0].parsedCsv.meta.fields");
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Reaction Conditions"
            helper="Below is a plate map preview containing
              plate zones of different reaction condition groups.
              Change color by clicking on the color selector to
              the left of the zone key. Below, add materials or load
              pre-existing reaction condition presets, add new reaction
              conditions, and save new presets for each zone."
            width="100%"
          />

          <PlateCsvWithTabs
            toolSchema={toolSchema}
            data={csvData}
            change={change}
            headers={csvHeaders}
            layers={[REACTION_CONDITION_GROUP_CSV_HEADER]}
            userLayerColor={userLayerColor}
          />
          {reactionConditions.map(name => {
            const color = get(
              userLayerColor,
              `al${REACTION_CONDITION_GROUP_CSV_HEADER}.li${removeDecimal(
                name
              )}`
            );
            const fieldLabel = (
              <h6
                style={{
                  background: color,
                  color: determineBlackOrWhiteTextColor(color),
                  display: "table",
                  padding: "5px 10px"
                }}
              >
                {name}
              </h6>
            );
            return (
              <div
                key={name}
                className="tg-rounded-border"
                style={{ margin: 10 }}
              >
                <FieldArray
                  name={`reactionConditionRows.${name}`}
                  allValues={reactionConditionRows[name]}
                  component={renderReactionConditionRows}
                  toolSchema={toolSchema}
                  fieldLabel={fieldLabel}
                  addReagentsToCondition={this.addReagentsToCondition}
                  saveReactionConditionPreset={this.saveReactionConditionPreset}
                  loadReactionConditionPreset={this.loadReactionConditionPreset}
                  reactionConditionGroupName={name}
                />
              </div>
            );
          })}
        </div>
        <div className="tg-step-form-section">
          <HeaderWithHelper
            header="Output Data"
            helper="Enter a name for the output plate map and reaction map."
          />
          <div style={{ width: "35%" }}>
            <InputField
              label="Plate Map Name"
              name="plateMapGroupName"
              placeholder="Enter name..."
              isRequired
            />
            <InputField
              label="Reaction Map Name"
              name="reactionMapName"
              placeholder="Enter name..."
              isRequired
            />
          </div>
        </div>
        <Footer {...footerProps} />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "userLayerColor",
    "assayPlanningCsv",
    "reactionConditions",
    "reactionConditionRows"
  )
)(ReactionConditions);

function renderReactionConditionRows({
  fields,
  addReagentsToCondition,
  saveReactionConditionPreset,
  reactionConditionGroupName,
  loadReactionConditionPreset,
  fieldLabel,
  allValues = [],
  toolSchema
}) {
  return (
    <div>
      <div
        className="tg-flex justify-space-between align-flex-start"
        style={{ marginBottom: 15 }}
      >
        {fieldLabel}
        <Button
          text="Clear All"
          disabled={!fields.length}
          intent={Intent.DANGER}
          onClick={() => {
            fields.removeAll();
          }}
        />
      </div>
      {fields.map((row, index) => {
        const rowInfo = allValues[index] || {};
        const name =
          get(rowInfo, "additiveMaterial.name") ||
          get(rowInfo, "material.name");
        const isDry = get(rowInfo, "additiveMaterial.isDry");
        const showAllFields = get(rowInfo, "material");
        return (
          <div key={index}>
            <div
              className="tg-flex align-center justify-space-between"
              style={{ marginBottom: 10 }}
            >
              <div className="tg-flex align-center">
                <RemoveButton fields={fields} index={index} />
                {name}
              </div>
              {rowInfo.groupName && (
                <div
                  className={classNames(Classes.TEXT_MUTED, Classes.TEXT_SMALL)}
                  style={{ fontStyle: "italic" }}
                >
                  Loaded from {rowInfo.groupName}
                </div>
              )}
            </div>
            <UnitFields
              toolSchema={toolSchema}
              namePrefix={`${row}.`}
              concentrationTypes={["concentration"]}
              isDry={isDry}
              showAllFields={showAllFields}
              inline
              rightMargin
            />
            {index < fields.length - 1 && <Divider />}
          </div>
        );
      })}
      <div className="tg-flex justify-space-between" style={{ marginTop: 15 }}>
        <Button
          intent={Intent.PRIMARY}
          text="Add Reagents"
          onClick={() => addReagentsToCondition(fields)}
        />
        <div className="tg-flex">
          <Button
            style={{ marginRight: 10 }}
            text="Load Preset"
            onClick={() => loadReactionConditionPreset(fields)}
          />
          <Button
            intent={Intent.SUCCESS}
            text="Save as Preset"
            disabled={!fields.length}
            onClick={() =>
              saveReactionConditionPreset(allValues, reactionConditionGroupName)
            }
          />
        </div>
      </div>
    </div>
  );
}
