/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DragLayer } from "react-dnd";
import { Icon } from "@blueprintjs/core";
import "./style.css";

class CustomMaterialDragLayer extends React.Component {
  renderMaterialCursor() {
    const {
      item: { selectedSourceMaterials },
      currentOffset: { x, y }
    } = this.props;

    return (
      <div style={{ position: "absolute", top: y, left: x, fontSize: "16px" }}>
        <b>
          <Icon icon="symbol-cross" iconSize={20} />
          <sub style={{ marginLeft: -5 }}>
            ☓{selectedSourceMaterials.length}
          </sub>
        </b>
      </div>
    );
  }

  render() {
    const { isDragging, currentOffset, itemType } = this.props;

    if (!isDragging || !currentOffset || itemType !== "source-material")
      return null;

    return (
      <div className="source-material-drag-layer">
        {this.renderMaterialCursor()}
      </div>
    );
  }
}

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
}))(CustomMaterialDragLayer);
