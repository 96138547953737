/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { showConfirmationDialog } from "@teselagen/ui";
import { isEmpty } from "lodash";
import { createTaggedItems } from "../../../src-shared/utils/tagUtils";
import { getReportSequenceAndMaterialIds } from "../Library/J5Reports/UploadJ5Report";
import { safeQuery } from "../../../src-shared/apolloMethods";

export async function handleLinkAllReportMaterials(j5ReportId) {
  let shouldTag = false;
  const reportTaggedItems = await safeQuery(
    [
      "taggedItem",
      /* GraphQL */ `
        {
          id
          tagId
          tagOptionId
        }
      `
    ],
    {
      variables: {
        filter: {
          j5ReportId
        }
      }
    }
  );

  if (reportTaggedItems.length) {
    shouldTag = await showConfirmationDialog({
      text: "Should the report's tags be applied to the materials?",
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
  }
  const { data } = await window.serverApi({
    method: "POST",
    url: "/linkAllJ5ReportMaterials",
    data: {
      j5ReportId
    }
  });

  let materialIds;
  const selectedTags = reportTaggedItems.reduce((acc, taggedItem) => {
    acc[taggedItem.tagId] = taggedItem.tagOptionId || true;
    return acc;
  }, {});
  if (!data.alreadyLinked || (shouldTag && !isEmpty(selectedTags))) {
    // requery to update ui
    const res = await getReportSequenceAndMaterialIds(j5ReportId);
    materialIds = res.materialIds;
  }

  if (shouldTag && materialIds && materialIds.length) {
    await createTaggedItems({
      selectedTags,
      recordIds: materialIds,
      model: "material"
    });
  }
  if (data.alreadyLinked) {
    window.toastr.warning(
      `All sequences are already linked. Materials were aliased if needed. ${
        shouldTag ? " Tags were applied to existing materials." : ""
      }`
    );
    return { alreadyLinked: true };
  }
  return { success: true };
}
