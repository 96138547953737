/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DataTable, Loading } from "@teselagen/ui";
import { get, map, isEmpty } from "lodash";
import classNames from "classnames";
import HeaderWithHelper from "../../../src-shared/HeaderWithHelper";
import { Icon } from "@blueprintjs/core";

const schema = [
  {
    width: 50,
    type: "action",
    render: (_, r) => {
      if (isEmpty(r.wellsWithReaction)) {
        return <Icon intent="danger" icon="delete" />;
      } else {
        return <Icon intent="success" icon="tick-circle" />;
      }
    }
  },
  { path: "name", displayName: "Reaction" },
  {
    path: "reactionInputs",
    displayName: "Inputs",
    render: (_, r) => {
      return r.reactionInputs
        .map(input => (input.inputMaterial || input.inputAdditiveMaterial).name)
        .join(", ");
    }
  },
  {
    path: "reactionOutputs",
    displayName: "Outputs",
    render: (_, r) => {
      return r.reactionOutputs
        .map(o => (o.outputMaterial || o.outputAdditiveMaterial).name)
        .join(", ");
    }
  },
  {
    displayName: "Locations with Reaction",
    render: (_, r) => {
      return map(r.wellsWithReaction, v => {
        return v;
      });
    }
  }
];

function ReactionVerificationTable({
  reactionVerificationTable,
  reactionSummaryStats,
  destinationWellsWithRunningReaction,
  providedReactionsRan,
  loading,
  inForm
}) {
  if ((!reactionVerificationTable || !reactionSummaryStats) && !loading)
    return null;
  return (
    <div
      className={classNames({
        "tg-step-form-section column": inForm
      })}
    >
      <HeaderWithHelper
        width="100%"
        header="Reaction Verification Information"
        helper="The tables below display information
          on each of the reactions generated by the
          selected worklists including input and output
          materials as well as summarized statistics on
          all reactions run."
      />
      <Loading bounce style={{ minHeight: 120 }} loading={loading}>
        <DataTable
          style={{ flexGrow: 1 }}
          isSimple
          noPadding
          maxHeight={300}
          formName="reactionVerificationTableSchema"
          schema={schema}
          entities={reactionVerificationTable}
          withPaging
          defaults={{
            pageSize: 50
          }}
        />
        <div style={{ marginTop: 10 }}>
          Number of Unique Reactions Ran:{" "}
          {get(reactionSummaryStats, "numberOfReactionsRan")}
        </div>
        <div style={{ marginTop: 10 }}>
          Percentage of Destination Wells With Running Reaction:{" "}
          {destinationWellsWithRunningReaction}%
        </div>
        <div style={{ marginTop: 10 }}>
          Percentage of Provided Reactions Ran: {providedReactionsRan}%
        </div>
      </Loading>
    </div>
  );
}

export default ReactionVerificationTable;
