/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { Intent, Button } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import stepFormValues from "../../../../src-shared/stepFormValues";
import transferColumnContainerArrayFragment from "../../../graphql/fragments/transferColumnContainerArrayFragment";
import { attachedColumnPlateFilter } from "../../../utils/plateUtils";

import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import {
  getExistingExtendedProperties,
  getPropValue
} from "../../../../src-shared/utils/extendedPropertyUtils";
import UnitInputField from "../../UnitInputField";

class SelectPlates extends Component {
  state = {
    loading: false
  };

  collectExtendedProps = async () => {
    const {
      columnContainerArrays = [],
      stepFormProps: { change },
      nextStep
    } = this.props;
    this.setState({
      loading: true
    });
    try {
      const collectionPlateIds = columnContainerArrays.map(
        columnContainerArray => columnContainerArray.collectionPlate.id
      );
      const collectionPlatesWithProps = await safeQuery(
        [
          "containerArray",
          `id
            aliquotContainers {
              id
              aliquot {
                id
                extendedValues {
                  id
                  value
                  extendedProperty {
                    id
                    name
                  }
                }
                extendedCategoryValues {
                  id
                  extendedProperty {
                    id
                    name
                  }
                }
                extendedMeasurementValues {
                  id
                  value
                  extendedProperty {
                    id
                    name
                  }
                }
              }
            }
              extendedValues {
                id
                value
                extendedProperty {
                  id
                  name
                }
              }
              extendedCategoryValues {
                id extendedProperty {
                  id
                  name
                }
              }
              extendedMeasurementValues {
                id
                value
                extendedProperty {
                  id
                  name
                }
              }
              `
        ],
        { variables: { filter: { id: collectionPlateIds } } }
      );
      const existingExtendedProperties = getExistingExtendedProperties(
        collectionPlatesWithProps
      );
      change("existingExtendedProperties", existingExtendedProperties);
      change("collectionPlatesWithProps", collectionPlatesWithProps);
      nextStep();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(
        "Error collecting extended properties from collection plate."
      );
      this.setState({
        loading: false
      });
    }
  };

  render() {
    const {
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      columnContainerArrays = [],
      Footer,
      footerProps,
      handleSubmit
    } = this.props;
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select Column Plates"
            width="100%"
            helper="Choose one or more column plates of protein materials. The selected column plates must be attached to a collection plate."
          />
          <GenericSelect
            {...{
              name: "columnContainerArrays",
              isMultiSelect: true,
              buttonProps: {
                disabled: isDisabledMap.containerArrays,
                loading: isLoadingMap.containerArrays,
                text: `${
                  columnContainerArrays.length ? "Change" : "Select"
                } Column Plates`
              },
              tableParamOptions: {
                additionalFilter: attachedColumnPlateFilter
              },
              schema: [
                "name",
                {
                  displayName: "Plate Type",
                  path: "containerArrayType.name"
                },
                dateModifiedColumn
              ],
              fragment: [
                "containerArray",
                "id name containerArrayType { id name } updatedAt"
              ],
              additionalDataFragment: transferColumnContainerArrayFragment,
              postSelectDTProps: {
                formName: "desaltSelectedPlates",
                schema: platePreviewSchema
              }
            }}
          />
        </div>
        {!!columnContainerArrays.length && (
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Specify Protein Volume (Optional)"
              helper="Specify the amount of protein material that
              will be added to the collection plate. If no value
              is provided, the entire volume of the protein material
              from the filter plate will be transferred to the
              collection plate."
            />
            <UnitInputField
              label="Protein Volume"
              name="proteinVolume"
              min={0}
              style={{ marginTop: 15 }}
              unitName="proteinVolumetricUnitCode"
              unitDefault="uL"
              unitType="volumetricUnit"
            />
          </div>
        )}
        <Footer
          {...footerProps}
          nextButton={
            <Button
              loading={this.state.loading}
              intent={Intent.PRIMARY}
              onClick={handleSubmit(this.collectExtendedProps)}
              text="Next"
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues("columnContainerArrays", "collectionContainerArrays")
)(SelectPlates);

const platePreviewSchema = [
  platePreviewColumn(),
  "name",
  {
    path: "collectionPlate.name",
    displayName: "Collection Plate"
  },
  {
    displayName: "Times Used",
    render: (v, r) => getPropValue(r, "Number of Uses")
  },
  dateModifiedColumn
];
