/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

export default ({ microserviceQueue: { id } }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h4>
        The Guide RNA Score Prediction tool has been submitted. Head to the{" "}
        <Link to={modelNameToLink("microserviceTask", id)}>Task</Link> to view
        the status.
        <br />
      </h4>
      <h5>After successful completion the gRNAs will be generated.</h5>
    </div>
  );
};
