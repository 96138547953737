/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { capitalize } from "lodash";

export const makeMaterialSearchObject = type => {
  const additionalFilter = qb => {
    return qb.whereAll({
      materialTypeCode: type
    });
  };

  let displayName = type === "DNA" ? type : capitalize(type.toLowerCase());
  displayName += " Materials";

  return {
    model: "material",
    displayName,
    additionalFilter,
    route: `/${type.toLowerCase()}-materials`
  };
};
