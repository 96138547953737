/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const designTemplateFragment = gql`
  fragment designTemplateFragment on design {
    id
    type
    name
    numPlaceholders
    description
    lab {
      id
      name
    }
    createdAt
    updatedAt
  }
`;
