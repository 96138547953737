/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { isNumber, times } from "lodash";
import { generalComplement } from "../../../../../tg-iso-shared/utils/enzymeUtils";

const CHAR_WIDTH = 15;

class EnzymeVisual extends React.Component {
  static defaultProps = {
    label: "Visualization"
  };

  getSequenceLength = () =>
    Math.max(
      this.props.recognitionRegex.length,
      this.props.forwardSnipPosition + 1,
      this.props.reverseSnipPosition + 1
    );

  getPaddingNs = () =>
    times(
      Math.max(
        this.props.recognitionRegex.length,
        this.props.forwardSnipPosition + 1,
        this.props.reverseSnipPosition + 1
      ) - this.props.recognitionRegex.length,
      () => "N"
    ).join("");

  renderText = (text = "") => (
    <g>
      {text.split("").map((c, i) => (
        <text
          key={i}
          x={(i + 0.5) * CHAR_WIDTH}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {c}
        </text>
      ))}
    </g>
  );

  renderVisualization = () => {
    const {
      recognitionRegex,
      forwardSnipPosition,
      reverseSnipPosition
    } = this.props;

    const seqLen = this.getSequenceLength();
    const paddingNs = this.getPaddingNs();
    return (
      <svg width={seqLen * CHAR_WIDTH + 10} height={50}>
        <g className="enzyme-visual-sequence" transform="translate(5,15)">
          <g>
            <g fill="#f54242">
              {this.renderText(recognitionRegex.toUpperCase())}
            </g>
            <g
              transform={`translate(${recognitionRegex.length * CHAR_WIDTH},0)`}
            >
              {this.renderText(paddingNs)}
            </g>
          </g>
          <g transform="translate(0,15)">
            <g fill="#f54242">
              {this.renderText(
                generalComplement(recognitionRegex).toUpperCase()
              )}
            </g>
            <g
              transform={`translate(${recognitionRegex.length * CHAR_WIDTH},0)`}
            >
              {this.renderText(paddingNs)}
            </g>
          </g>
          <path
            transform={`translate(${forwardSnipPosition * CHAR_WIDTH},-5)`}
            fill="black"
            d="M 0 0 L 5 -7 L -5 -7 Z"
          />
          <path
            transform={`translate(${reverseSnipPosition * CHAR_WIDTH},15)`}
            fill="black"
            d="M 0 0 L 5 7 L -5 7 Z"
          />
          <path
            fill="none"
            stroke="#4a4b4c"
            strokeWidth="1"
            d={`M ${forwardSnipPosition * CHAR_WIDTH} -2.5
                L ${forwardSnipPosition * CHAR_WIDTH} 6
                L ${reverseSnipPosition * CHAR_WIDTH} 6
                L ${reverseSnipPosition * CHAR_WIDTH} 12.5
                `}
          />
        </g>
      </svg>
    );
  };

  render() {
    const {
      label,
      recognitionRegex,
      forwardSnipPosition: _fsp,
      reverseSnipPosition: _rsp
    } = this.props;
    // sometimes redux form numeric fields are strings
    const forwardSnipPosition = Number(_fsp);
    const reverseSnipPosition = Number(_rsp);
    return (
      <div className="enzyme-visual-container">
        {label}
        {recognitionRegex &&
        isNumber(forwardSnipPosition) &&
        isNumber(reverseSnipPosition) &&
        forwardSnipPosition < 100 &&
        forwardSnipPosition >= 0 &&
        reverseSnipPosition < 100 &&
        reverseSnipPosition >= 0 ? (
          <div className="enzyme-visual-container-body">
            {this.renderVisualization()}
          </div>
        ) : (
          <div style={{ height: 50, fontStyle: "italic" }}>
            Visualization not available.
          </div>
        )}
      </div>
    );
  }
}

export default EnzymeVisual;
