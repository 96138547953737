/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment j5AssemblyPieceFragment on j5AssemblyPiece {
    id
    cid
    name
    type
    overhangWithNext
    overhangWithPrevious
    relativeOverhang
    extra3PrimeCpecBps
    extra5PrimeCpecBps
    cpecTmNext
    assemblyPieceConstructCountView {
      id
      constructCount
    }
    j5AssemblyPieceParts {
      id
      index
      j5InputPart {
        id
        j5InputSequence {
          id
          sequence {
            id
            name
          }
        }
        part {
          id
          name
        }
      }
    }
    sequence {
      id
      cid
      size
      name
      isInLibrary
    }
  }
`;
