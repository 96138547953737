/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import GenericSelect from "../GenericSelect";
import AddTagDialog from "../AddTagDialog";
import { Button, Popover } from "@blueprintjs/core";
import tagFragment from "../fragments/tagFragment";

function TagField({ name = "tags", ...rest }) {
  const [creatingTag, setCreatingTag] = useState(false);
  // add creating tag stuff back in
  const inputTracker = {};
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <GenericSelect
        asReactSelect
        doNotGenerateField
        name={name}
        label="Tags"
        isMultiSelect
        reactSelectProps={{
          isTagSelect: true
        }}
        inputTracker={inputTracker}
        fragment={[
          "tagWithOptionView",
          /* GraphQL */ `
            {
              id
              name
              color
            }
          `
        ]}
        {...rest}
      />
      <div style={{ marginLeft: 10, marginTop: 15 }}>
        <Popover
          isOpen={creatingTag}
          hasBackdrop
          content={
            <div style={{ padding: 15, width: 300 }}>
              <AddTagDialog
                noDialog
                tagFragment={tagFragment}
                hideModal={() => setCreatingTag(false)}
                afterCreate={tag => {
                  const tagToSelect = {
                    ...tag,
                    label: tag.name
                  };
                  if (tag.tagOptions.length) {
                    const option = tag.tagOptions[0];
                    tagToSelect.id = `${tag.id}:${option.id}`;
                    tagToSelect.color = option.color;
                    const label = `${tag.name}: ${option.name}`;
                    tagToSelect.label = label;
                    tagToSelect.name = label;
                  }
                  inputTracker.input.onChange([
                    ...(inputTracker.input.value || []),
                    tagToSelect
                  ]);
                }}
                submitText="Add Tag"
              />
            </div>
          }
        >
          <Button
            icon="add"
            minimal
            style={{ marginTop: 6.5 }}
            intent="success"
            active={creatingTag}
            onClick={() => setCreatingTag(true)}
          />
        </Popover>
      </div>
    </div>
  );
}

export default TagField;
