/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "recompose";
import { branch, withProps } from "recompose";
import withQuery from "../../src-shared/withQuery";

import GenericEditPositionsDialog from "../components/Dialogs/GenericEditPositionsDialog";
import { wrapDialog } from "@teselagen/ui";
import { prepareInitialValuesForPositions } from "../utils";

const containerFragment = [
  "container",
  `
  id
  containerPositions {
    id
    name
    label
    index
  }
`
];

const equipmentFragment = [
  "equipmentItem",
  `
  id
  equipmentPositions {
    id
    name
    label
    index
  }
`
];

const queryOptions = {
  showLoading: true,
  inDialog: true,
  options: props => {
    return {
      variables: {
        id: props.record.id
      }
    };
  }
};

export default compose(
  wrapDialog({ title: "Edit Positions", style: { width: 600 } }),
  branch(
    props => props.record.__typename === "container",
    withQuery(containerFragment, queryOptions),
    withQuery(equipmentFragment, queryOptions)
  ),
  withProps(props => {
    return {
      model: props.container ? "container" : "equipment",
      initialValues: prepareInitialValuesForPositions({
        initialValues: props.container || props.equipmentItem,
        key: props.container ? "containerPosition" : "equipmentPosition"
      })
    };
  })
)(GenericEditPositionsDialog);
