/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getIdOrCodeOrIndex } from "@teselagen/ui";
import React from "react";

import {
  defaultReagentHeaders,
  defaultReagentFields
} from "../../../tg-iso-lims/src/utils/getReagentsFromCsv";
import { snakeCase } from "lodash";
import { Button } from "@blueprintjs/core";
import { filter } from "lodash";
import { find } from "lodash";
import { forEach } from "lodash";
import { reduce } from "lodash";

export const reagentHandlingLogic = {
  csvParserOptions: ({ validateAgainstSchema }) => {
    const defaultReagentHeadersCounts = reduce(
      defaultReagentHeaders,
      (acc, header) => {
        const h = snakeCase(header.trim()).toUpperCase();
        acc[h] = 1;
        return acc;
      },
      {}
    );
    return {
      transformHeader: h => {
        let toRet = h;
        const trimmedH = snakeCase(h.trim()).toUpperCase();
        const exactMatchCount = defaultReagentHeadersCounts[trimmedH];
        const trimmedHNoNums = trimmedH
          .replace(/[0-9]/g, "")
          .replace(/_$/g, "");
        const partialMatchCount = defaultReagentHeadersCounts[trimmedHNoNums];
        if (exactMatchCount || partialMatchCount) {
          const trailingNum =
            partialMatchCount > 1 ? `_${partialMatchCount}` : "";

          toRet = `${trimmedHNoNums}${trailingNum}`;
          if (partialMatchCount > 1) {
            const f = defaultReagentFields.find(
              f => snakeCase(f.path.trim()).toUpperCase() === trimmedHNoNums
            );
            validateAgainstSchema.fields.push({
              ...f,
              requireIf: f.requireIf ? f.requireIf + trailingNum : undefined,
              path: toRet
            });
          }
          defaultReagentHeadersCounts[trimmedHNoNums] =
            defaultReagentHeadersCounts[trimmedHNoNums] + 1;
        }
        return toRet;
      }
    };
  },
  tableWideValidation: ({ entities }) => {
    const toRet = {};
    entities.forEach(ent => {
      const groupedKeysByReagentNum = [];

      forEach(ent, (val, key) => {
        if (key.trim().toLowerCase().startsWith("reagent")) {
          //get number from the end of the key
          // eg reagent_lot_name_3 => 3
          const reagentNum = key.trim().match(/\d+$/)?.[0] || 0;
          groupedKeysByReagentNum[reagentNum] =
            groupedKeysByReagentNum[reagentNum] || {};
          groupedKeysByReagentNum[reagentNum][key] = val;
        }
      });

      // now loop over each group and validate that if any of the keys are present, then the reagent_name is present
      // if not, then throw an error
      groupedKeysByReagentNum.forEach(group => {
        let areAnyValuesFilledIn;
        let isNameFilledIn;
        let nameKey;
        const typesToCheck = {
          volume: {
            isDefined: false,
            hasUnit: false,
            key: ""
          },
          concentration: {
            isDefined: false,
            hasUnit: false,
            key: ""
          },
          mass: {
            isDefined: false,
            hasUnit: false,
            key: ""
          }
        };

        forEach(group, (val, key) => {
          const isNameKey = key.toLowerCase().startsWith("reagent_name");
          if (isNameKey) nameKey = key;
          if (val) {
            areAnyValuesFilledIn = true;
            if (isNameKey) isNameFilledIn = true;
          }
          forEach(typesToCheck, (tVal, tKey) => {
            if (
              key.toLowerCase().includes(tKey) &&
              !key.toLowerCase().includes("unit")
            ) {
              tVal.key = key;
              if (val) tVal.isDefined = true;
            }
            if (
              key.toLowerCase().includes(tKey) &&
              key.toLowerCase().includes("unit")
            ) {
              tVal.unitKey = key;
              if (val) tVal.hasUnit = true;
            }
          });
        });

        let areAnyUnitsFilledIn = false;
        forEach(typesToCheck, (val, key) => {
          if (val.isDefined && !val.hasUnit) {
            toRet[`${getIdOrCodeOrIndex(ent)}:${val.unitKey}`] =
              `${key} is defined but no unit is specified`;
          }
          if (val.isDefined) areAnyUnitsFilledIn = true;
        });
        if (typesToCheck.volume.isDefined && typesToCheck.mass.isDefined) {
          ["mass", "volume"].forEach(t => {
            toRet[`${getIdOrCodeOrIndex(ent)}:${typesToCheck[t].key}`] =
              `Please specify mass or volume, not both`;
          });
        }
        if (
          typesToCheck.concentration.isDefined &&
          typesToCheck.mass.isDefined
        ) {
          ["mass", "concentration"].forEach(t => {
            toRet[`${getIdOrCodeOrIndex(ent)}:${typesToCheck[t].key}`] =
              `Please specify mass or concentration, not both`;
          });
        }

        if (!areAnyUnitsFilledIn && areAnyValuesFilledIn) {
          ["mass", "concentration", "volume"].forEach(t => {
            toRet[`${getIdOrCodeOrIndex(ent)}:${typesToCheck[t].key}`] =
              `At least one of the following fields must be defined: volume, mass, or volume+concentration`;
          });
        }

        if (areAnyValuesFilledIn && !isNameFilledIn) {
          toRet[`${getIdOrCodeOrIndex(ent)}:${nameKey}`] =
            `Reagent name is required if any other reagent fields are present`;
        }

        if (areAnyValuesFilledIn) {
          find(group, (val, key) => {
            const isNameKey = key.toLowerCase().startsWith("reagent_name");

            if (isNameKey) return true;
          });
        }
      });
    });
    return toRet;
  },
  additionalHeaderBtn: ({ validateAgainstSchema }) => {
    return (
      <Button
        intent="primary"
        icon="add"
        onClick={() => {
          const count =
            filter(validateAgainstSchema.fields, f =>
              f.path.includes("Reagent Name")
            ).length + 1;
          defaultReagentFields.forEach(field => {
            validateAgainstSchema.fields.push({
              ...field,
              path: field.path + count,
              requireIf: field.requireIf ? field.requireIf + count : undefined
            });
          });
        }}
      >
        Add Reagent Columns
      </Button>
    );
  }
};
