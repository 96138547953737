/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap, identity } from "lodash";
import concatWarningStrs from "../../../utils/concatWarningStrs";
import { isoContext } from "@teselagen/utils";
import getMutations from "./getMutations";
import handleMutations from "./handleMutations";

export default async function handleUpdateMutations(
  {
    recordsToImport,
    preMutateFn = identity,
    convertUserFacingToDbModel = identity,
    precheckFn,
    precheckWarningsFn,
    model
  },
  ctx = isoContext
) {
  const mutationTracker = {};
  const newRecords = flatMap(recordsToImport, record => {
    if (record.__importFailed) {
      return [];
    }
    if (precheckFn) {
      const error = precheckFn(record);
      if (error) {
        record.__importFailed = concatWarningStrs(record.__importFailed, error);
        return [];
      }
    }
    if (precheckWarningsFn) {
      const warning = precheckWarningsFn(record);
      if (warning) {
        record.__importWarning = concatWarningStrs(
          record.__importWarning,
          warning
        );
      }
    }
    if (record.id && !record.__oldRecord) {
      record.__importFailed = concatWarningStrs(
        record.__importFailed,
        `Missing previous data for ${model} with id ${record.id}`
      );
      return [];
    }
    if (record.__oldRecord) {
      const original = convertUserFacingToDbModel(record.__oldRecord, {
        isOldRecord: true
      });
      if (record.__oldRecord.id !== record.__newRecord.id) {
        record.__importFailed = concatWarningStrs(
          record.__importFailed,
          `Response update id did not match original record id. ${model}`
        );
        return [];
      }
      if (!original)
        throw new Error(
          `1 - You must return a record from your convertUserFacingToDbModel. ${model}`
        );
      original.id = record.__newRecord.id;
      const updated = convertUserFacingToDbModel(record.__newRecord, {
        isUpdateRecord: true
      });
      if (!updated)
        throw new Error(
          `2 - You must return a record from your convertUserFacingToDbModel. ${model}`
        );
      updated.id = record.__newRecord.id;
      getMutations({
        model,
        original,
        updated,
        mutations: mutationTracker
      });
      return []; //return early because we've got an update
    }

    const toRet = convertUserFacingToDbModel(record, {
      isBrandNewRecord: true
    });
    if (!toRet)
      throw new Error(
        `3 - You must return a record from your convertUserFacingToDbModel. ${model}`
      );
    toRet.cid = record.cid;
    return toRet;
  });
  await preMutateFn(mutationTracker);
  await handleMutations(mutationTracker, ctx);
  return newRecords;
}
