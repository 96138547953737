/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SubmissionError } from "redux-form";
import { keyBy, get, sortBy } from "lodash";

import StepForm from "../../../../src-shared/StepForm";
import { updateAliquotsWithReagents } from "../../../utils";

import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import AddReagents, { validateReagentVolumes } from "./AddReagents";
import SelectPlates from "./SelectPlates";
import { addReagentsContainerArrayFragment } from "./fragments";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getPlateLocationMap } from "../../../../../tg-iso-lims/src/utils/plateUtils";

class AddReagentsTool extends React.Component {
  onSubmit = async values => {
    const {
      containerArrays = [],
      reagents = [],
      reagentInfo = {},
      addToEmptyWells,
      addReagentListName,
      changeAdditiveComposition,
      specifyWells,
      selectedWells = {}
    } = values;
    try {
      const aliquotContainerTypes = await safeQuery(
        aliquotContainerTypeFragment
      );
      const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");
      const allAliquots = [];
      const allAliquotContainers = [];
      const updatedPlates = new Set();
      containerArrays.forEach(plate => {
        const { aliquotContainers } = plate;

        aliquotContainers.forEach(aliquotContainer => {
          if (specifyWells && selectedWells[plate.id]?.length) {
            const location = getAliquotContainerLocation(aliquotContainer, {
              force2D: true
            });
            if (!selectedWells[plate.id].includes(location)) {
              return;
            }
          }

          const { aliquot } = aliquotContainer;
          const fullAliquotContainer = {
            ...aliquotContainer,
            plateName: plate.name,
            aliquotContainerType:
              keyedAliquotContainerTypes[
                aliquotContainer.aliquotContainerTypeCode
              ]
          };
          if (aliquot) {
            updatedPlates.add(plate);
            allAliquots.push({
              ...aliquot,
              aliquotContainer: fullAliquotContainer
            });
          } else if (aliquotContainer.additives.length) {
            updatedPlates.add(plate);
            allAliquotContainers.push(fullAliquotContainer);
          } else if (addToEmptyWells) {
            updatedPlates.add(plate);
            allAliquotContainers.push(fullAliquotContainer);
          }
        });
      });
      let dataTable;
      if (reagents.length) {
        const error = await updateAliquotsWithReagents({
          aliquots: allAliquots,
          aliquotContainers: allAliquotContainers,
          changeAdditiveComposition,
          reagents,
          reagentInfo
        });
        // it couldn't add reagents bail early
        if (error) {
          throw new Error(error);
        }
        let dataRows = [];
        reagents.forEach(reagent => {
          const info = reagentInfo["id" + reagent.id];
          const addRow = ac => {
            dataRows.push({
              rowValues: {
                reagent: reagent.name,
                lot: get(info, "lot.name"),
                transferVolume: `${info.volume} ${info.volumetricUnitCode}`,
                destinationPlate: ac.plateName,
                destinationWell: getAliquotContainerLocation(ac)
              }
            });
          };
          allAliquotContainers.forEach(addRow);
          allAliquots.forEach(aliquot => addRow(aliquot.aliquotContainer));
        });
        if (dataRows.length) {
          // this will sort the table rows based upon plate well
          dataRows = sortBy(dataRows, [
            "rowValues.destinationPlate",
            "rowValues.destinationWell"
          ]).map((row, i) => {
            row.index = i;
            return row;
          });
          const [dt] = await safeUpsert("dataTable", {
            name: addReagentListName,
            dataTableTypeCode: "ADD_REAGENT_LIST",
            dataRows
          });
          dataTable = dt;
        }
        // mark plates as updated
        await safeUpsert(
          "containerArray",
          Array.from(updatedPlates).map(c => ({
            id: c.id,
            updatedAt: new Date()
          }))
        );
      } else {
        throw new Error("No reagents chosen.");
      }
      return {
        dataTable,
        containerArrays
      };
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error: error.message || "Error updating plates."
      });
    }
  };

  validate = values => {
    const {
      reagents = [],
      reagentInfo,
      containerArrays = [],
      addToEmptyWells,
      selectedWells = {},
      specifyWells
    } = values;
    const errors = {};
    let numberOfTransfers = 0;
    const isWellEmpty = well => !well.aliquot && !well.additives.length;

    if (containerArrays.length) {
      containerArrays.forEach(plate => {
        if (specifyWells && selectedWells[plate.id]?.length) {
          numberOfTransfers += selectedWells[plate.id].length;
        } else {
          const { aliquotContainers } = plate;
          aliquotContainers.forEach(ac => {
            if (!isWellEmpty(ac) || addToEmptyWells) {
              numberOfTransfers++;
            }
          });
        }
      });
    }
    validateReagentVolumes({
      errors,
      reagents,
      reagentInfo,
      numberOfTransfers
    });
    if (specifyWells && !addToEmptyWells) {
      containerArrays.forEach(plate => {
        if (selectedWells[plate.id]?.length) {
          const plateLocationMap = getPlateLocationMap(plate);
          selectedWells[plate.id].some(well => {
            if (isWellEmpty(plateLocationMap[well])) {
              errors.specifyWells =
                "Some of the chosen wells are empty. Please select 'Add reagents to empty wells' or change selection.";
              return true;
            } else {
              return false;
            }
          });
        }
      });
    }
    if (specifyWells && !errors.specifyWells) {
      const platesWithoutSelection = containerArrays.filter(
        plate => !selectedWells[plate.id]?.length
      );
      if (platesWithoutSelection.length) {
        errors.specifyWells = `Please select wells for ${platesWithoutSelection
          .map(p => p.name)
          .join(", ")}.`;
      }
    }
    return errors;
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        props: {
          toolSchema
        }
      },
      {
        title: "Add Reagents",
        Component: AddReagents,
        props: {
          toolSchema
        }
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(withWorkflowInputs(addReagentsContainerArrayFragment))(
  AddReagentsTool
);
