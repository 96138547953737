/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { DropTarget } from "react-dnd";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

class ShapeDropTarget extends React.Component {
  static defaultProps = {
    x: 0,
    y: 0
  };
  render() {
    const {
      x,
      y,
      width,
      height,
      connectDropTarget,
      isOver,
      canDrop
    } = this.props;
    return connectDropTarget(
      <g>
        <rect x={x} y={y} width={width} height={height} fill="rgba(0,0,0,0)" />
        {isOver && canDrop && (
          <rect
            {...{
              width: 6,
              rx: 3,
              ry: 6,
              fill: "#00b7ff",
              fillOpacity: 0.5,
              height,
              y,
              x: x + width / 2 - 6 / 2
            }}
          />
        )}
      </g>
    );
  }
}

export default DropTarget(
  "shape",
  {
    drop: (props, monitor) => {
      (async () => {
        try {
          const { index, schematic, refetchSchematic } = props;
          const { set, type } = monitor.getItem();

          if (set) {
            if (set.index === index || set.index + 1 === index) return;

            if (set.index < index) {
              await safeUpsert("schematicSet", [
                ...schematic.schematicSets
                  .filter(s => set.index < s.index && s.index < index)
                  .map(s => ({
                    id: s.id,
                    index: s.index - 1
                  })),
                {
                  id: set.id,
                  index: index - 1
                }
              ]);
            } else {
              await safeUpsert("schematicSet", [
                ...schematic.schematicSets
                  .filter(s => index <= s.index && s.index < set.index)
                  .map(s => ({
                    id: s.id,
                    index: s.index + 1
                  })),
                {
                  id: set.id,
                  index
                }
              ]);
            }

            await refetchSchematic();
          } else {
            const valuesToChange = schematic.schematicSets
              .filter(s => s.index >= index)
              .map(s => ({
                id: s.id,
                index: s.index + 1
              }));
            if (valuesToChange.length)
              await safeUpsert("schematicSet", valuesToChange);

            await safeUpsert("schematicSet", {
              schematicId: schematic.id,
              index,
              type,
              color: "#e0e3e6",
              name: type === "divider" ? "" : "Bin"
            });

            await refetchSchematic();
          }
        } catch (e) {
          console.error(e);
          window.toastr.error("Error editing schematic.");
        }
      })();
    },
    canDrop(props, monitor) {
      const { isBackbone } = monitor.getItem();
      return !isBackbone;
    }
  },
  (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    };
  }
)(ShapeDropTarget);
