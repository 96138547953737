/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import actions from "../../../actions";
import basicHandleActionsWithFullState from "../../basicHandleActionsWithFullState";

export const ZOOM_LEVELS = [1, 1.5, 2, 2.5, 3];

const initialState = {
  cardWidths: {},
  cardSetExpansions: {},
  cardViewOptions: {},
  zoom: 1,
  cardMaterialAvailabilityInformation: {},
  showMinimap: true
};

export default basicHandleActionsWithFullState(
  {
    [actions.ui.designEditor.tree.updateCardViewOptions]: (
      state,
      { payload: { id, changedViewOptions } }
    ) => {
      return {
        ...state,
        cardViewOptions: {
          ...state.cardViewOptions,
          [id]: {
            ...(state.cardViewOptions[id] || {}),
            ...changedViewOptions
          }
        }
      };
    },

    // The purpose of this reducer is to enable changes in the global
    // view options to override local view options.
    [actions.ui.designEditor.general.updateViewOptions]: (
      state,
      { payload: changedViewOptions }
    ) => {
      return {
        ...state,
        cardViewOptions: Object.keys(state.cardViewOptions)
          .map(id => {
            const viewOptions = { ...state.cardViewOptions[id] };
            Object.keys(changedViewOptions).forEach(
              optionName => delete viewOptions[optionName]
            );
            return { id, viewOptions };
          })
          .reduce(
            (obj, { id, viewOptions }) => ({ ...obj, [id]: viewOptions }),
            {}
          )
      };
    },
    [actions.ui.designEditor.tree.clearCardSetExpansions]: state => {
      return {
        ...state,
        cardSetExpansions: {}
      };
    },

    [actions.ui.designEditor.tree.updateCardWidth]: (
      state,
      { payload: { id, cardWidth } }
    ) => {
      return {
        ...state,
        cardWidths: {
          ...state.cardWidths,
          [id]: cardWidth
        }
      };
    },
    [actions.designIo.setDesign]: state => {
      return {
        ...state,
        cardWidths: {},
        cardSetExpansions: {},
        cardViewOptions: {},
        cardMaterialAvailabilityInformation: {}
      };
    },
    [actions.ui.designEditor.tree.zoomIn]: state => {
      const { zoom } = state;
      const index = ZOOM_LEVELS.indexOf(zoom);
      return { ...state, zoom: ZOOM_LEVELS[Math.max(0, index - 1)] };
    },
    [actions.ui.designEditor.tree.zoomOut]: state => {
      const { zoom } = state;
      const index = ZOOM_LEVELS.indexOf(zoom);
      return {
        ...state,
        zoom: ZOOM_LEVELS[Math.min(ZOOM_LEVELS.length - 1, index + 1)]
      };
    },
    [actions.ui.designEditor.tree.updateCardMaterialAvailabilityInformation]: (
      state,
      { payload: cardIdToInfo }
    ) => ({
      ...state,
      cardMaterialAvailabilityInformation: {
        ...state.cardMaterialAvailabilityInformation,
        ...cardIdToInfo
      }
    }),
    [actions.ui.designEditor.tree.toggleMinimap]: state => ({
      ...state,
      showMinimap: !state.showMinimap
    })
  },
  initialState
);
