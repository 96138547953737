/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListElementCell from "../components/HierarchicalDesign/DesignElementCard/ListElementCell";
import {
  getItemOfType,
  getFasOfElement,
  getEugeneRulesOfElement,
  isDesignVisualReport,
  getFieldOnItem,
  doesElementHaveExtraSequence,
  getInputCardThatContainsBin,
  getInputReactionIdOfCard,
  getOutputtingReactionIdOfCard
} from "../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../src-shared/utils/designUtils/isDesignLocked";
import { isCellSelected } from "../../src-shared/selectors/designViewSelectors";
import actions from "../../src-shared/redux/actions";
import {
  getNeighborArfPartId,
  getFirstNeighborPartId
} from "../utils/stateUtils";
import { isClassicDesign } from "../../src-shared/selectors/classicViewSelectors";

const mapStateToProps = (state, props) => {
  const { cardId, binId, index, elementId } = props;

  const inputReactionId = getInputReactionIdOfCard(state, cardId);
  const outputReactionId = getOutputtingReactionIdOfCard(state, cardId);

  // ARF stuff, so we can query for these parts and their partSeqContextView
  const isClassic = isClassicDesign(state).success; // ARF only works in Classic-compatible designs for now
  let previousArfPartId, nextArfPartId, firstPreviousPartId, firstNextPartId;
  if (isClassic) {
    previousArfPartId = getNeighborArfPartId(state, binId, cardId, true);
    nextArfPartId = getNeighborArfPartId(state, binId, cardId, false);

    firstPreviousPartId = previousArfPartId
      ? null
      : getFirstNeighborPartId(state, binId, cardId, true);
    firstNextPartId = nextArfPartId
      ? null
      : getFirstNeighborPartId(state, binId, cardId, false);
  }
  // end ARF stuff

  const injection = {
    state,
    inputCard:
      inputReactionId || outputReactionId
        ? getInputCardThatContainsBin(
            state,
            inputReactionId || outputReactionId,
            binId
          )
        : null,
    isDesignVisualReport: isDesignVisualReport(state),
    isDesignLocked: isDesignLocked(state),
    isSelected: isCellSelected(state, cardId, binId, index),
    previousArfPartId,
    nextArfPartId,
    firstPreviousPartId,
    firstNextPartId
  };

  if (elementId) {
    const element = getItemOfType(state, "element", elementId);
    const part = element.partId && getItemOfType(state, "part", element.partId);
    const aminoAcidPart =
      element.aminoAcidPartId &&
      getItemOfType(state, "aminoAcidPart", element.aminoAcidPartId);
    Object.assign(injection, {
      element,
      fas: getFasOfElement(state, cardId, elementId),
      hasEugeneRules: !!getEugeneRulesOfElement(state, cardId, elementId)
        .length,
      hasExtraSequence: doesElementHaveExtraSequence(state, elementId)
    });
    if (part) {
      let partSourceIsAvailable = getFieldOnItem(
        state,
        "sequence",
        part.sequenceId,
        "designMaterialAvailabilityView"
      );
      partSourceIsAvailable =
        partSourceIsAvailable && partSourceIsAvailable.isAvailable;
      Object.assign(injection, {
        partSequenceId: part.sequenceId,
        partSourceIsAvailable,
        partStart: part.start,
        partEnd: part.end
      });
    } else if (aminoAcidPart) {
      Object.assign(injection, {
        aminoAcidSequenceId: aminoAcidPart.aminoAcidSequenceId,
        partStart: aminoAcidPart.start,
        partEnd: aminoAcidPart.end
      });
    }
  }
  return injection;
};

const mapDispatchToProps = {
  selectCell: actions.ui.designEditor.general.selectCell,
  createElements: actions.design.createElements,
  mapElementToPart: actions.design.mapElementToPart,
  updateElement: actions.design.updateElement,
  updateBin: actions.design.updateBin,
  changeFas: actions.design.changeFas,
  setActivePanel: actions.ui.designEditor.inspector.setActivePanel,
  openInspector: actions.ui.designEditor.inspector.open
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ListElementCell);
