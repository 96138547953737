/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Tree, Classes, Button } from "@blueprintjs/core";
import QueryBuilder from "tg-client-query-builder";
import { compose } from "recompose";
import { forEach, noop, isEmpty } from "lodash";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import {
  wrapDialog,
  DataTable,
  DialogFooter,
  CheckboxField,
  NumericInputField,
  BlueprintError
} from "@teselagen/ui";
import { buildTreeNodes } from "../../../utils";

import GenericSelect from "../../../../src-shared/GenericSelect";

import loadChildItemsForTreeNode from "../../Record/EquipmentRecordView/loadChildItemsForTreeNode";
import { notLessThan } from "../../../../src-shared/utils/formUtils";

import { safeQuery } from "../../../../src-shared/apolloMethods";
import { pathToPrimaryIds } from "@teselagen/path-utils";

class SelectEquipmentPositionDialog extends React.Component {
  state = {
    treeNodes: [],
    selectionMap: {},
    numPositions: 0,
    loadingNumPositions: false,
    loading: false
  };

  async componentDidUpdate(prevProps, prevState) {
    const { selectionMap: oldSelectionMap } = prevState;
    const { selectionMap } = this.state;
    const { recordsToMove } = this.props;

    if (recordsToMove) return;

    const oldSelectionKeys = Object.keys(oldSelectionMap);
    const newSelectionKeys = Object.keys(selectionMap);
    const needsToCalcNumPositions =
      oldSelectionKeys.length !== newSelectionKeys.length ||
      newSelectionKeys.some(key => !oldSelectionKeys.includes(key));
    if (needsToCalcNumPositions) {
      try {
        if (!newSelectionKeys.length) {
          return this.setState({
            numPositions: 0
          });
        }
        this.setState({
          loading: true
        });
        // this timeout will make it so the message doesn't flash
        const timeoutId = setTimeout(() => {
          this.setState({
            loadingNumPositions: true
          });
        }, 300);
        const numPositions = await this.getContainerPositionsInSelection({
          countOnly: true
        });
        clearTimeout(timeoutId);
        this.setState({
          numPositions
        });
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error loading number of positions in selection.");
      }
      this.setState({
        loadingNumPositions: false,
        loading: false
      });
    }
  }

  clearPositions = () => {
    const { selectionMap } = this.state;
    Object.keys(selectionMap).forEach(key => {
      const nodeData = selectionMap[key];
      nodeData.isSelected = false;
    });
    this.setState({
      selectionMap: {},
      treeNodes: this.state.treeNodes
    });
  };

  handleNodeExpand = async node => {
    if (node.loaded === true) {
      node.isExpanded = true;
      node.icon = "folder-open";
      return this.setState({
        treeNodes: this.state.treeNodes
      });
    }
    if (node.icon === "refresh") return;
    node.icon = "refresh";
    this.setState({
      treeNodes: this.state.treeNodes
    });

    const assignedPositionFragment = [
      "assignedPosition",
      `
      id
      containerArray {
        id
        name
        barcode {
          id
          barcodeString
        }
      }
      aliquotContainer {
        id
        name
        barcode {
          id
          barcodeString
        }
      }
    `
    ];
    const items = await loadChildItemsForTreeNode(node, {
      assignedPositionFragment
    });

    node.icon = "folder-open";

    node.isExpanded = true;
    node.loaded = true;
    const itemNodes = buildTreeNodes({
      records: items,
      displayPath: node.record.displayPath || "",
      selectionMap: this.state.selectionMap
    });
    node.childNodes = itemNodes;

    this.setState({
      treeNodes: this.state.treeNodes
    });
  };

  handleNodeCollapse = nodeData => {
    nodeData.icon = "folder-close";
    nodeData.isExpanded = false;
    this.setState({
      treeNodes: this.state.treeNodes
    });
  };

  handleNodeClick = nodeData => {
    if (nodeData.record.__typename === "assignedPosition") {
      return;
    }
    const originallySelected = nodeData.isSelected;
    const selectionMap = {
      ...this.state.selectionMap
    };

    nodeData.isSelected = !originallySelected;

    if (originallySelected) {
      delete selectionMap[nodeData.id];
    } else {
      selectionMap[nodeData.id] = nodeData;
    }

    this.setState({
      treeNodes: this.state.treeNodes,
      selectionMap
    });
  };

  onEquipmentChosen = async equipment => {
    const treeNodes = buildTreeNodes({
      records: [equipment],
      selectionMap: this.state.selectionMap
    });
    this.setState(
      {
        treeNodes
      },
      () => {
        this.handleNodeExpand(this.state.treeNodes[0]);
      }
    );
  };

  getContainerPositionsInSelection = async (options = {}) => {
    const { countOnly } = options;
    const { selectionMap } = this.state;
    const selectedLocations = Object.values(selectionMap);

    const selectedLocationsByType = {
      equipmentItem: {},
      container: {},
      containerPosition: {}
    };

    selectedLocations.forEach(({ record }) => {
      if (selectedLocationsByType[record.__typename]) {
        selectedLocationsByType[record.__typename][record.id] = record;
      }
    });

    // the selection should now be keyed by id to the record
    // next filter out duplication (ex if a containers equipment is chosen then we don't need to
    // add spaces in container because the equipment will handle it)
    forEach(selectedLocationsByType.containerPosition, pos => {
      const containerSelected =
        selectedLocationsByType.container[pos.container.id];
      const equipmentSelected =
        selectedLocationsByType.equipmentItem[pos.container.equipmentId];

      const parentContainers = pathToPrimaryIds(pos.container.path);
      const anyParentSelected = parentContainers.some(
        id => selectedLocationsByType.container[id]
      );
      if (anyParentSelected || containerSelected || equipmentSelected) {
        delete selectedLocationsByType.containerPosition[pos.id];
      }
    });
    forEach(selectedLocationsByType.container, pos => {
      const equipmentSelected =
        selectedLocationsByType.equipmentItem[pos.equipmentId];
      const parentContainers = pathToPrimaryIds(pos.path);
      const anyParentSelected = parentContainers.some(
        id => selectedLocationsByType.container[id]
      );
      if (anyParentSelected || equipmentSelected) {
        delete selectedLocationsByType.container[pos.id];
      }
    });

    // now the map should be filtered to contain only the parent locations to add.
    // Find all the container positions inside these locations and those will be the
    // placement locations

    // const selectedLocationsByType = {
    //   equipmentItem: {},
    //   container: {},
    //   containerPosition: {}
    // };
    const qb = new QueryBuilder("containerPosition");
    const filters = [];
    const equipmentIds = Object.keys(selectedLocationsByType.equipmentItem);
    const containerIds = Object.keys(selectedLocationsByType.container);
    if (equipmentIds.length) {
      filters.push({
        "container.equipmentId": equipmentIds
      });
    }
    if (containerIds.length) {
      filters.push({
        containerId: containerIds
      });
      containerIds.forEach(id => {
        const container = selectedLocationsByType.container[id];
        filters.push({
          "container.path": qb.startsWith(
            `${container.path || ""}/${container.id}`
          )
        });
      });
    }
    let containerPositions = Object.values(
      selectedLocationsByType.containerPosition
    );
    let containerPositionCount = containerPositions.length;
    if (filters.length) {
      const filter = qb.whereAny(...filters).toJSON();
      const containerPositionsInsideContainers = await safeQuery(
        ["containerPosition", "id"],
        {
          variables: {
            filter,
            pageSize: countOnly ? 1 : undefined
          }
        }
      );
      containerPositionCount += containerPositionsInsideContainers.totalResults;
      containerPositions = containerPositions.concat(
        containerPositionsInsideContainers
      );
    }
    if (countOnly) return containerPositionCount;
    return containerPositions;
  };

  onSubmit = async values => {
    const { onSelect = noop, hideModal } = this.props;
    try {
      const containerPositions = await this.getContainerPositionsInSelection();
      if (!containerPositions.length) {
        return window.toastr.error(
          "No positions found inside the selected locations."
        );
      }

      await onSelect(containerPositions, values);
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error adding locations.");
    }
  };

  renderPositionLocationSelection() {
    const { selectionMap, numPositions, loadingNumPositions } = this.state;
    const { isInfinite, placementStrategy } = this.props;
    const entities = Object.values(selectionMap).sort((a, b) => {
      return a.record.displayPath.localeCompare(b.record.displayPath);
    });

    let placementCapacityMessage;
    if (placementStrategy) {
      if (placementStrategy.isContainerArrayStrategy) {
        placementCapacityMessage = `Set the capacity for each of ${
          numPositions > 0 ? numPositions : ""
        } positions you have selected. This will specify how many plates can fit into each position in the freezer.`;
      } else if (placementStrategy.isDestinationContainerArray) {
        placementCapacityMessage = `Set the capacity for each of ${
          numPositions > 0 ? numPositions : ""
        } positions you have selected. This will specify how many boxes/racks can fit into each position in the freezer. These boxes/racks will hold the tubes that you are placing.`;
      } else {
        placementCapacityMessage = `Set the capacity for each of ${
          numPositions > 0 ? numPositions : ""
        } positions you have selected. This will specify how many loose tubes can fit into each position in the freezer.`;
      }
    }
    return (
      <React.Fragment>
        <div className="tg-flex-separator" />
        <div className="tg-flex column" style={{ flex: 1 }}>
          <div style={{ fontSize: 15 }}>Locations to Add</div>
          {numPositions > 0 && (
            <div style={{ marginTop: 10 }}>
              There are {loadingNumPositions ? "-" : numPositions} positions
              selected (including nested positions).
            </div>
          )}
          {placementCapacityMessage && (
            <div style={{ marginTop: 10 }}>{placementCapacityMessage}</div>
          )}
          <div
            className="tg-flex justify-space-between"
            style={{ marginTop: 10 }}
          >
            <CheckboxField
              name="isInfinite"
              style={{ marginTop: 20 }}
              label="Set Infinite Capacity"
              defaultValue={false}
            />
            {!isInfinite && (
              <NumericInputField
                name="globalCapacity"
                label="Capacity"
                min={1}
                normalize={notLessThan(1, { integer: true })}
                isRequired
              />
            )}
          </div>
          {!!Object.keys(selectionMap).length && (
            <Button
              style={{ marginLeft: 10 }}
              minimal
              intent="danger"
              text="Clear All"
              onClick={this.clearPositions}
            />
          )}
          <DataTable
            formName="selectPositionsTable"
            noHeader
            style={{ maxHeight: 500 }}
            schema={[
              {
                type: "action",
                width: 50,
                render: (_, r) => (
                  <Button
                    minimal
                    intent="danger"
                    icon="trash"
                    inten="danger"
                    small
                    onClick={() => this.handleNodeClick(r)}
                  />
                )
              },
              {
                sortDisabled: true,
                filterDisabled: true,
                displayName: "Path",
                path: "record.displayPath"
              }
            ]}
            isSimple
            entities={entities}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      treeNodes,
      selectionMap,
      numPositions,
      loadingNumPositions,
      loading
    } = this.state;
    const { handleSubmit, submitting, hideModal } = this.props;
    const noValidPositions =
      !isEmpty(selectionMap) && numPositions === 0 && !loading;
    const equipmentSelect = (
      <div style={{ minWidth: 300 }}>
        <GenericSelect
          {...{
            name: "equipment", //the field name within the redux form Field
            label: "Choose Equipment",
            additionalFilter: {
              hasContents: true
            },
            asReactSelect: true,
            onSelect: this.onEquipmentChosen,
            fragment: ["equipmentItem", "id name"]
          }}
        />
      </div>
    );

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <div className="tg-flex">
            <div
              style={{
                borderRight: "1px solid #a7b6c2",
                paddingRight: 10
              }}
            >
              {equipmentSelect}
              <Tree
                contents={treeNodes}
                onNodeClick={this.handleNodeClick}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
              />
            </div>
            {this.renderPositionLocationSelection()}
          </div>
          {noValidPositions && (
            <BlueprintError error="There are no positions in the selected containers." />
          )}
        </div>
        <DialogFooter
          hideModal={hideModal}
          loading={loadingNumPositions || loading}
          disabled={isEmpty(selectionMap) || noValidPositions}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Select Locations",
    style: {
      width: 800
    }
  }),
  reduxForm({
    form: "selectEquipmentPositions"
  }),
  tgFormValues("isInfinite")
)(SelectEquipmentPositionDialog);
