/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";
import GenericSelect from "../GenericSelect";

class PublishSequencesDialog extends React.Component {
  onSubmit = async values => {
    const { hideModal, sequenceIds, isProtein } = this.props;
    try {
      const valuesToSend = { ...values };

      const {
        data: { success, imported, duplicateSequences, err }
      } = await window.serverApi.request({
        method: "POST",
        url: "/publishSequences",
        data: {
          ...valuesToSend,
          isProtein,
          sequenceIds
        }
      });
      if (!success) {
        console.error(err);
        throw new Error(err);
      } else {
        let dupMsg = "";
        if (duplicateSequences?.length > 0) {
          dupMsg = `\nThere were ${duplicateSequences?.length} duplicated sequences: ${duplicateSequences?.map(x => x.name).join(", ")}.`;
        }
        window.toastr.success(
          `Successfully published ${imported?.length} sequences of ${sequenceIds?.length} submitted. ${dupMsg}`,
          { timeout: 2000 * (duplicateSequences?.length + 1) }
        );
      }

      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error publishing sequence(s).");
      hideModal();
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <GenericSelect
            {...{
              name: "publicationTarget",
              idAs: "id",
              isRequired: true,
              asReactSelect: true,
              label: "Publication Target",
              fragment: ["publicationTarget", "id name authKey baseUrl"]
            }}
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          text="Publish"
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Publish Options",
    style: {
      width: 450
    }
  }),
  reduxForm({
    form: "publishSequencesForm", // a unique name for this form
    enableReinitialize: true
  }),
  tgFormValues("format")
)(PublishSequencesDialog);
