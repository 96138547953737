/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import CUSTOM_WORKLIST from "./integrationDefs/CUSTOM_WORKLIST";
import EXPORT_CUSTOM_WORKLIST from "./integrationDefs/EXPORT_CUSTOM_WORKLIST";
import EXPORT_CUSTOM_PLATE from "./integrationDefs/EXPORT_CUSTOM_PLATE";
import CUSTOM_INFO from "./integrationDefs/CUSTOM_INFO";
import FILE_TO_DATATABLE from "./integrationDefs/FILE_TO_DATATABLE";
import FILE_TO_DATAGRID from "./integrationDefs/FILE_TO_DATAGRID";
import TWIST_ORDERING from "./integrationDefs/TWIST_ORDERING";
import DNA_VENDOR_QUOTE from "./integrationDefs/DNA_VENDOR_QUOTE";
import IDT_ORDERING from "./integrationDefs/IDT_ORDERING";
import IMPORT, { cursorPagingMode } from "./integrationDefs/IMPORT";
import UPDATE from "./integrationDefs/UPDATE";
import EXPORT from "./integrationDefs/EXPORT";
import CUSTOM_LABEL_PRINTING from "./integrationDefs/CUSTOM_LABEL_PRINTING";
import API_INTEGRATION from "./integrationDefs/API_INTEGRATION";
import { importExportSubtypes } from "./importExportSubtypes";

const integrationTypes = {
  CUSTOM_INFO,
  CUSTOM_WORKLIST,
  EXPORT_CUSTOM_WORKLIST,
  EXPORT_CUSTOM_PLATE,
  IDT_ORDERING,
  TWIST_ORDERING,
  FILE_TO_DATAGRID,
  FILE_TO_DATATABLE,
  DNA_VENDOR_QUOTE,
  IMPORT,
  UPDATE,
  EXPORT,
  CUSTOM_LABEL_PRINTING,
  API_INTEGRATION
};

export default integrationTypes;

export { importExportSubtypes, cursorPagingMode };
