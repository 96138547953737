/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import { DragSource, DropTarget } from "react-dnd";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

import "./style.css";

import AssemblyScarClass from "./Glyphs/AssemblyScar";
import ArrowClass from "./Glyphs/Promoter";
import CdsClass from "./Glyphs/CDS";
import DividerClass from "./Glyphs/Divider";
import RectangleClass from "./Glyphs/Rectangle";
import TerminatorClass from "./Glyphs/Terminator";
import OriginClass from "./Glyphs/Origin";
import BluntRestrictionSiteClass from "./Glyphs/BluntRestrictionSite";
import FivePrimeOverhangClass from "./Glyphs/FivePrimeOverhang";
import FivePrimeUtrClass from "./Glyphs/FivePrimeUtr";
import FivePrimeRestrictionSiteClass from "./Glyphs/FivePrimeRestrictionSite";
import InsulatorClass from "./Glyphs/Insulator";
import OperatorClass from "./Glyphs/Operator";
import PrimerBindingSiteClass from "./Glyphs/PrimerBindingSite";
import ProteaseSiteClass from "./Glyphs/ProteaseSite";
import ProteinStabilityElementClass from "./Glyphs/ProteinStabilityElement";
import RestrictionEnzymeRecognitionSiteClass from "./Glyphs/RestrictionEnzymeRecognitionSite";
import RibonucleaseSiteClass from "./Glyphs/RibonucleaseSite";
import RibosomeEntrySiteClass from "./Glyphs/RibosomeEntrySite";
import RnaStabilityElementClass from "./Glyphs/RnaStabilityElement";
import SignatureClass from "./Glyphs/Signature";
import ThreePrimeOverhangClass from "./Glyphs/ThreePrimeOverhang";
import ThreePrimeRestrictionSiteClass from "./Glyphs/ThreePrimeRestrictionSite";

const createDragSource = shapeType => {
  return compose(
    DragSource(
      "shape",
      {
        canDrag(props) {
          return !props.readOnly;
        },
        beginDrag(props) {
          let type = shapeType;
          if (type === "arrow" || type === "terminator" || type === "cds")
            type = props.isRight ? `right-${type}` : `left-${type}`;

          return {
            set: props.set,
            isBackbone: props.isBackbone,
            type,
            text: props.text,
            fill: props.fill,
            textColor: props.textColor
          };
        }
      },
      (connect, monitor) => {
        //console.log(connect, monitor)
        return {
          connectDragSource: connect.dragSource(),
          isDragging: monitor.isDragging()
        };
      }
    ),
    DropTarget(
      "color",
      {
        drop: (props, monitor) => {
          (async () => {
            try {
              const { set } = props;
              const { color } = monitor.getItem();

              await safeUpsert(["schematicSet", "id color"], {
                id: set.id,
                color
              });
            } catch (e) {
              console.error(e);
              window.toastr.error("Error editing schematic.");
            }
          })();
        },
        canDrop(props) {
          return props.set;
        }
      },
      (connect, monitor) => {
        return {
          connectDropTarget: connect.dropTarget(),
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          item: monitor.getItem()
        };
      }
    )
  );
};

export const AssemblyScar = createDragSource("assembly_junction")(
  AssemblyScarClass
);
export const Arrow = createDragSource("arrow")(ArrowClass);
export const BluntRestrictionSite = createDragSource("blunt_restriction_site")(
  BluntRestrictionSiteClass
);
export const Cds = createDragSource("cds")(CdsClass);
export const Divider = createDragSource("divider")(DividerClass);
export const FivePrimeOverhang = createDragSource("five_prime_overhang")(
  FivePrimeOverhangClass
);
export const FivePrimeUtr = createDragSource("five_prime_utr")(
  FivePrimeUtrClass
);
export const FivePrimeRestrictionSite = createDragSource(
  "five_prime_restriction_site"
)(FivePrimeRestrictionSiteClass);
export const Insulator = createDragSource("insulator")(InsulatorClass);
export const Operator = createDragSource("operator_site")(OperatorClass);
export const Origin = createDragSource("origin")(OriginClass);
export const PrimerBindingSite = createDragSource("primer_binding_site")(
  PrimerBindingSiteClass
);
export const ProteaseSite = createDragSource("protease_site")(
  ProteaseSiteClass
);
export const ProteinStabilityElement = createDragSource(
  "protein_stability_element"
)(ProteinStabilityElementClass);
export const Rectangle = createDragSource("rectangle")(RectangleClass);
export const RestrictionEnzymeRecognitionSite = createDragSource(
  "restriction_enzyme_recognition_site"
)(RestrictionEnzymeRecognitionSiteClass);
export const RibonucleaseSite = createDragSource("ribonuclease_site")(
  RibonucleaseSiteClass
);
export const RibosomeEntrySite = createDragSource("ribosome_entry_site")(
  RibosomeEntrySiteClass
);
export const RnaStabilityElement = createDragSource("rna_stability_element")(
  RnaStabilityElementClass
);
export const Signature = createDragSource("signature")(SignatureClass);
export const Terminator = createDragSource("terminator")(TerminatorClass);
export const ThreePrimeOverhang = createDragSource("three_prime_overhang")(
  ThreePrimeOverhangClass
);
export const ThreePrimeRestrictionSite = createDragSource(
  "three_prime_restriction_site"
)(ThreePrimeRestrictionSiteClass);
