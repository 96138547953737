/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { get, isEmpty } from "lodash";
import {
  FileUploadField,
  Loading,
  DialogFooter,
  showProgressToast,
  CheckboxField,
  wrapDialog
} from "@teselagen/ui";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import shortid from "shortid";
import { createTaggedItems } from "../../../../../src-shared/utils/tagUtils";
import { startImportCollection } from "../../../../../src-shared/utils/importCollection";
import processSequences from "../../../../../../tg-iso-shared/src/sequence-import-utils/processSequences";
import TagField from "../../../../../src-shared/TagField";
import { safeUpsert, safeQuery } from "../../../../../src-shared/apolloMethods";

import tooltips from "../../../../tooltips";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import { createAssemblyReportReactionMaps } from "../../../../utils/reactionMapUtils";

export async function getReportSequenceAndMaterialIds(j5ReportId) {
  const itemsWithSequences = [
    "j5OligoSyntheses",
    "j5AssemblyPieces",
    "j5InputSequences",
    "j5RunConstructs",
    "j5DirectSyntheses"
  ];

  const hasOligos = {
    j5OligoSyntheses: true
  };

  let fragment = `id`;
  itemsWithSequences.forEach(item => {
    if (hasOligos[item]) {
      fragment += ` ${item} { id oligo { id sequenceId sequence { id polynucleotideMaterialId isInLibrary} } }`;
    } else {
      fragment += ` ${item} { id sequenceId sequence { id polynucleotideMaterialId isInLibrary} }`;
    }
  });

  const j5ReportWithSequenceAndMaterialIds = await safeQuery(
    ["j5Report", fragment],
    {
      variables: {
        id: j5ReportId
      }
    }
  );
  const sequenceIds = [];
  const materialIds = [];
  itemsWithSequences.forEach(item => {
    j5ReportWithSequenceAndMaterialIds[item].forEach(j5Item => {
      let sequenceId;
      let materialId;
      if (hasOligos[item]) {
        sequenceId = get(j5Item, "oligo.sequenceId");
        materialId = get(j5Item, "oligo.sequence.polynucleotideMaterialId");
      } else {
        sequenceId = j5Item.sequenceId;
        materialId = get(j5Item, "sequence.polynucleotideMaterialId");
      }
      sequenceIds.push(sequenceId);
      if (materialId) {
        materialIds.push(materialId);
      }
    });
  });

  return {
    sequenceIds,
    materialIds
  };
}

class UploadJ5Report extends React.Component {
  state = {
    loading: false
  };

  componentWillUnmount() {
    this.clearToast && this.clearToast();
  }

  startProgressChecker = async key => {
    try {
      const res = await window.serverApi({
        method: "POST",
        url: "/checkJ5UploadStatus",
        withCredentials: true,
        data: { key }
      });
      const progress = res.data.progress || 0;
      this.clearToast = showProgressToast(
        "Creating report data",
        progress,
        key
      );
    } catch (error) {
      console.error("error:", error);
    }
  };

  uploadReport = async (
    reportFile,
    j5ReportIds,
    linkSequences,
    clearToastsOnError
  ) => {
    const data = new FormData();
    data.append("file", reportFile.originFileObj);
    const res = await window.serverApi.post("/user_uploads/", data);
    clearInterval(this.fakeInterval);
    showProgressToast("Uploading report file", 1, "fakeProgress");

    const { path, encoding, originalname, size, mimetype } = res.data[0];

    const { tags } = this.props;
    const newDataFile = {
      path,
      encoding,
      originalname,
      size,
      mimetype,
      dataFileTypeCode: "J5"
    };

    this.setState({ loading: true });

    const [ioItem] = await safeUpsert("ioItem", {
      ioItemTypeCode: "DATA",
      displayName: originalname,
      statusCode: "uploading",
      ioItemAvailabilityStatusCode: "ENTERING",
      dataItem: {
        dataItemTypeCode: "DATA_FILE",
        dataFile: newDataFile
      }
    });

    const ioItemId = ioItem.id;
    //fire off api route to parse
    //on backend
    //update dataSet.status = 'parsing'
    //fire off async parsing
    //return
    //on promise resolve it would trigger a query refresh to get the updated status
    const key = shortid();
    this.progressInterval = setInterval(() => {
      this.startProgressChecker(key);
    }, 2500);
    await startImportCollection(originalname || "Assembly Report Upload");
    await window.serverApi({
      method: "POST",
      url: "/process_datafile",
      withCredentials: true,
      data: { ioItemId, key }
    });
    clearInterval(this.progressInterval);
    // final one for finish
    this.startProgressChecker(key);
    const fullIoItem = await safeQuery(
      ["ioItem", "id dataItem { id dataFile { id j5Report { id } } } "],
      {
        variables: {
          id: ioItemId
        }
      }
    );
    const j5ReportId = get(fullIoItem, "dataItem.dataFile.j5Report.id");
    if (j5ReportId) {
      j5ReportIds.push(j5ReportId);
      if (linkSequences) {
        const clear = showProgressToast("Linking to materials...");
        clearToastsOnError.push(clear);
        await window.serverApi({
          method: "POST",
          url: "/linkAllJ5ReportMaterials",
          data: {
            j5ReportId
          }
        });
        clear();
      }
      const { sequenceIds, materialIds } =
        await getReportSequenceAndMaterialIds(j5ReportId);
      await processSequences(sequenceIds);
      if (!isEmpty(tags)) {
        await createTaggedItems({
          selectedTags: tags,
          recordIds: [j5ReportId],
          model: "j5Report"
        });
        await createTaggedItems({
          selectedTags: tags,
          recordIds: sequenceIds,
          model: "sequence"
        });
        await createTaggedItems({
          selectedTags: tags,
          recordIds: materialIds,
          model: "material"
        });
      }

      await createAssemblyReportReactionMaps({
        j5ReportId
      });
    }
  };

  onSubmit = async values => {
    const { j5ReportUpload = [], linkSequences } = values;
    const { uploadCompleted, hideModal } = this.props;
    if (!j5ReportUpload.length) return;
    const clearToastsOnError = [];
    try {
      const j5ReportIds = [];
      // the reports should be uploaded serially so that sequence
      // duplicate checking will work properly
      for (const reportFile of j5ReportUpload) {
        if (j5ReportUpload.length === 1) {
          this.startFakeProgress();
        }
        await this.uploadReport(
          reportFile,
          j5ReportIds,
          linkSequences,
          clearToastsOnError
        );
      }
      if (uploadCompleted) await uploadCompleted(j5ReportIds);
      hideModal();
    } catch (err) {
      clearToastsOnError.forEach(clear => clear && clear());
      this.setState({ loading: false });
      const msg = get(err, "response.data.msg");
      console.error("err:", err);
      window.toastr.error(msg || "Error saving j5 Report.");
    }
    this.progressInterval && clearInterval(this.progressInterval);
    clearInterval(this.fakeInterval);
    setTimeout(() => {
      this.clearToast && this.clearToast();
    }, 1000);
  };

  startFakeProgress = () => {
    let step = 0.5,
      progress = 0,
      currentProgress = 0;
    const key = "fakeProgress";
    this.fakeInterval = setInterval(() => {
      currentProgress += step;
      if (progress === 51) {
        step = 0.3;
        currentProgress = 1.1;
      }
      if (progress < 50) {
        progress = currentProgress * 3;
      } else {
        progress =
          Math.round(
            (Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000
          ) / 1000;
        if (progress >= 100) {
          clearInterval(this.fakeInterval);
          setTimeout(() => {
            this.cancelProgressToast && this.cancelProgressToast();
          }, 1000);
        } else if (progress >= 70) {
          step = 0.1;
        }
      }
      this.cancelProgressToast = showProgressToast(
        "Uploading report file",
        progress / 100,
        key
      );
    }, 100);
  };

  render() {
    const { hideModal, handleSubmit } = this.props;
    if (this.state.loading) return <Loading inDialog />;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            accept={[".j5", ".zip", ".json"]}
            name="j5ReportUpload"
          />
          <CheckboxField
            label="Link sequences to materials"
            name="linkSequences"
            defaultValue
            tooltipInfo={tooltips.linkJ5MaterialsHelper}
          />
          <TagField tooltipInfo="Add tags to report and its sequences" />
        </div>
        <DialogFooter
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: `Upload ${modelNameToReadableName("j5Report", { upperCase: true })}`
  }),
  reduxForm({
    form: "uploadJ5Reports"
  }),
  tgFormValues("tags")
)(UploadJ5Report);
