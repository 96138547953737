/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "recompose";
import { DataTable, InputField, ReactSelectField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { arrayToIdOrCodeValuedOptions } from "../../../../../src-shared/utils/formUtils";
import withQuery from "../../../../../src-shared/withQuery";
import { getReactionMapSchema } from "../../../../utils/reactionMapUtils";

function ReviewReactionMap(props) {
  const {
    reactionsThatWillRun,
    Footer,
    footerProps,
    reactionMaps = [],
    reactionTypes = []
  } = props;

  const allSameType = reactionMaps.every(
    rm => rm.reactionType.code === reactionMaps[0].reactionType.code
  );
  const schema = useMemo(() => {
    if (reactionsThatWillRun.length) {
      return getReactionMapSchema(reactionsThatWillRun);
    }
  }, [reactionsThatWillRun]);

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Define Reaction Map"
          helper="Provide a name for the subset reaction map."
        />
        <InputField
          style={{ maxWidth: 400 }}
          name="childReactionMapName"
          label="Reaction Map Name"
          isRequired
        />
        {!allSameType && (
          <div style={{ maxWidth: 400 }}>
            <ReactSelectField
              name="reactionTypeCode"
              isRequired
              label="Reaction Type"
              options={arrayToIdOrCodeValuedOptions(reactionTypes)}
            />
          </div>
        )}
        <DataTable
          formName="reactions"
          entities={reactionsThatWillRun}
          reactionsThatWillRun={reactionsThatWillRun}
          schema={schema}
          maxHeight={300}
          destroyOnUnmount={false}
          isSimple
          compact
        />
      </div>
      <Footer {...footerProps} />
    </React.Fragment>
  );
}

export default compose(
  withQuery(["reactionType", "code name"], { isPlural: true }),
  stepFormValues("reactionsThatWillRun", "reactionMaps")
)(ReviewReactionMap);
