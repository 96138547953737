/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { COLOR_SQUARE_SIZE } from "../constants";
import { DragSource } from "react-dnd";

import "./style.css";

class ColorSquare extends React.Component {
  static defaultProps = {
    x: 0,
    y: 0
  };

  render() {
    const { x, y, fill, connectDragSource } = this.props;
    return connectDragSource(
      <rect
        className="schematic-color-square"
        {...{ x, y, fill, height: COLOR_SQUARE_SIZE, width: COLOR_SQUARE_SIZE }}
      />
    );
  }
}

export default DragSource(
  "color",
  {
    beginDrag: props => ({ color: props.fill })
  },
  (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    };
  }
)(ColorSquare);
