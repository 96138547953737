/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getSelectedCellPaths,
  getSelectedCardId
} from "../../../selectors/designViewSelectors";
import { flatMap } from "lodash";
import { getBinsToTakeCombinationsOf } from "../../../utils/generateAllCardCombinations";
import { ChangeSetsHelper } from "../../../../../tg-iso-design/utils/designEditUtils";
import { getElementIdsInBin } from "../../../../../tg-iso-design/selectors/designStateSelectors";
import shortid from "shortid";

export default (state, { payload }, fullState) => {
  const changeSetsHelper = new ChangeSetsHelper(state);
  let cardId, bins, cellsWithElements;
  if (payload && payload.cardIdToUse) {
    cardId = payload.cardIdToUse;
    bins = getBinsToTakeCombinationsOf(fullState, cardId);
  } else {
    cardId = getSelectedCardId(fullState);
    bins = getBinsToTakeCombinationsOf(fullState, cardId);
    cellsWithElements = getSelectedCellPaths(fullState).filter(
      p => p.elementId
    );
  }

  const elementGroupComboId = shortid();
  changeSetsHelper.createPure("elementGroupCombo", {
    id: elementGroupComboId,
    isDeleted: true,
    cardId
  });

  const elementGroupElementGroupCombos = flatMap(bins, (s, index) => {
    const cellsInSet = cellsWithElements.filter(c => c.binId === s.id);
    const elementIds = cellsInSet.length
      ? cellsInSet.map(c => c.elementId)
      : getElementIdsInBin(fullState, s.id);

    return elementIds.map(elementId => ({
      elementGroupComboId,
      index,
      elementId
    }));
  });
  changeSetsHelper.createPure(
    "elementGroupElementGroupCombo",
    elementGroupElementGroupCombos
  );

  return changeSetsHelper.execute({
    removeInaccessibleItems: true
  });
};
