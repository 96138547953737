/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  BlueprintError,
  DialogFooter,
  InputField,
  wrapDialog,
  ReactSelectField
} from "@teselagen/ui";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import uploadDnaSequences from "../../../../../tg-iso-shared/src/sequence-import-utils/uploadDnaSequences";
import SequenceFileUpload from "../../../../src-shared/SequenceFileUpload";
import withQuery from "../../../../src-shared/withQuery";

const NewGenomeDialog = ({
  error,
  genomicReferenceSequences,
  handleSubmit,
  hideModal,
  refetch,
  submitting
}) => {
  const onSubmit = async values => {
    try {
      const {
        genomicRegionFiles = [],
        genomicRegions = [],
        genomicReferenceSequence
      } = values;

      if (genomicReferenceSequence?.genome) {
        const message = `This genomic reference sequence is already associated with genome ${genomicReferenceSequence.genome.name}.`;
        window.toastr.error(message);
        throwFormError(message);
      }

      const { existingSequences = [], createdSequences = [] } =
        genomicRegionFiles.length
          ? await uploadDnaSequences({
              isFileUpload: true,
              sequenceUpload: genomicRegionFiles,
              isGenomicRegionUpload: true,
              tags: values.tags
            })
          : {
              duplicateSequences: [],
              createdSequences: []
            };

      await safeUpsert("genome", {
        name: values.name,
        primaryReferenceSequenceId: genomicReferenceSequence?.id,
        genomeGenomicRegions: existingSequences
          .concat(createdSequences)
          .concat(genomicRegions)
          .map(s => ({
            genomicRegionId: s.id
          }))
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error creating genome");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField label="Name" name="name" />
        <GenericSelect
          name="genomicRegions"
          asReactSelect
          isMultiSelect
          label="Select Existing Genomic Regions"
          fragment={["sequence", "id name"]}
          tableParamOptions={{
            additionalFilter: {
              sequenceTypeCode: "GENOMIC_REGION"
            }
          }}
        />
        <ReactSelectField
          name="genomicReferenceSequence"
          label="Select Genomic Reference Sequence"
          options={genomicReferenceSequences.map(refSeq => ({
            value: refSeq,
            label: refSeq.name
          }))}
        />
        <SequenceFileUpload
          label="Upload Genomic Regions"
          name="genomicRegionFiles"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Create Genome"
  }),
  reduxForm({
    form: "createGenomeDialog"
  }),
  withQuery(["genomicReferenceSequence", "id name genome { id name }"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(NewGenomeDialog);
