/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import gql from "graphql-tag";
import AssociatedTableCard from "../../AssociatedTableCard";
import { getMaterialPlasmidSequence } from "../../../utils";

const schema = {
  model: "material",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Associated Plasmids",
      path: "microbialMaterialMicrobialMaterialPlasmids.polynucleotideMaterial.polynucleotideMaterialSequence.name",
      render: (v, r) => {
        return (
          r.microbialMaterialMicrobialMaterialPlasmids.length > 0 &&
          r.microbialMaterialMicrobialMaterialPlasmids
            .map(mmp => getMaterialPlasmidSequence(mmp)?.name)
            .join(", ")
        );
      }
    }
  ]
};

function MicrobialMaterialCard({
  title = "Microbial Materials",
  strainId,
  isCellCulture
}) {
  return (
    <AssociatedTableCard
      title={title}
      schema={{
        ...schema,
        fields: isCellCulture
          ? schema.fields.filter(f => f.displayName !== "Associated Plasmids")
          : schema.fields
      }}
      formName="strainRecordMicrobialMaterialsCard"
      additionalFilter={{
        strainId: strainId
      }}
      fragment={fragment}
    />
  );
}

const fragment = gql`
  fragment strainRecordMicrobialMaterialFragment on material {
    id
    name
    microbialMaterialMicrobialMaterialPlasmids {
      id
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          name
        }
      }
    }
  }
`;

export default compose(
  withProps(props => {
    return {
      schema: {
        ...schema,
        fields: props.isCellCulture
          ? schema.fields.filter(f => f.displayName !== "Associated Plasmids")
          : schema.fields
      }
    };
  })
)(MicrobialMaterialCard);
