/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const defaultSchema = [
  { path: "name", width: 200 },
  {
    path: "success",
    render: (v, rec) => (rec.__importFailed ? "False" : "True"),
    type: "boolean",
    width: 90
  }
];

export function getImportResultSchema(records) {
  const hasFailure = records.some(r => r.__importFailed);
  const hasWarning = records.some(r => r.__importWarning);
  const schema = [...defaultSchema];
  if (hasFailure) {
    schema.push({
      path: "__importFailed",
      displayName: "Failure Reason",
      width: 300
    });
  }
  if (hasWarning) {
    schema.push({
      path: "__importWarning",
      displayName: "Warning",
      width: 300
    });
  }

  return schema;
}
