/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { uniq } from "lodash";
import _getNameKeyedItems from "../../utils/getNameKeyedItems";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import {
  handleNestedRecords,
  handleNewLinkagesOnUpdateRecords,
  removeJoins,
  upsertAddIds
} from "./utils";
import { getMaterialFields } from "../../sequence-import-utils/getMaterialFields";
import shortid from "shortid";
import getSequenceMaterialsByHash from "../../sequence-import-utils/getSequenceMaterialsByHash";

const MICROBIAL_MATERIAL = async function (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) {
  const { safeUpsert } = ctx;

  const allPlasmidNames = [];
  const strainNames = [];

  const materialIds = [];
  recordsToImport.forEach(r => {
    if (r.id) {
      materialIds.push(r.id);
    }
    if (r.plasmidNames) {
      allPlasmidNames.push(...r.plasmidNames);
    }
    if (r.strainName) {
      strainNames.push(r.strainName);
    }
  });

  await removeJoins(
    {
      recordsToImport,
      model: "material",
      nestedId: "polynucleotideMaterial.polynucleotideMaterialSequence.id",
      pluralNested: "plasmids",
      joinName: "microbialMaterialPlasmid",
      modelId: "microbialMaterialId"
    },
    ctx
  );

  let recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "plasmids",
    async sequences => {
      await upsertHandlers.DNA_SEQUENCE(
        {
          ...rest,
          model: "sequence",
          recordsToImport: sequences,
          upsertHandlers
        },
        ctx
      );
    }
  );

  recordsToContinueUpserting = await handleNestedRecords(
    recordsToContinueUpserting,
    "strain",
    async strains => {
      await upsertHandlers.MICROBIAL_STRAIN(
        {
          ...rest,
          model: "strain",
          recordsToImport: strains,
          upsertHandlers
        },
        ctx
      );
    }
  );
  const toUpdate = handleNewLinkagesOnUpdateRecords(
    recordsToContinueUpserting,
    "plasmids"
  );

  const plasmidIdToDnaMaterialId = {};
  const plasmidIds = toUpdate.map(([, plasmidId]) => plasmidId);
  const plasmidsWithMaterials = await ctx.safeQuery(
    ["sequence", "id name hash polynucleotideMaterialId"],
    { id: plasmidIds }
  );
  const newMaterials = [];
  const seqUpdates = [];
  const allHashes = plasmidsWithMaterials.map(p => p.hash);
  const hashToMaterial = await getSequenceMaterialsByHash(allHashes, ctx);

  plasmidsWithMaterials.forEach(
    ({ id, name, hash, polynucleotideMaterialId }) => {
      if (polynucleotideMaterialId) {
        plasmidIdToDnaMaterialId[id] = polynucleotideMaterialId;
      } else if (hashToMaterial[hash]) {
        plasmidIdToDnaMaterialId[id] = hashToMaterial[hash].id;
      } else {
        const cid = shortid();
        newMaterials.push({
          ...getMaterialFields(),
          cid,
          name
        });
        plasmidIdToDnaMaterialId[id] = `&${cid}`;
        seqUpdates.push({
          id,
          polynucleotideMaterialId: `&${cid}`
        });
      }
    }
  );
  await safeUpsert("material", newMaterials);
  await safeUpsert("sequence", seqUpdates);

  await safeUpsert(
    "microbialMaterialPlasmid",
    toUpdate.map(([microbialMaterialId, plasmidId]) => ({
      microbialMaterialId,
      polynucleotideMaterialId: plasmidIdToDnaMaterialId[plasmidId]
    }))
  );

  const getNameKeyedItems = async props => _getNameKeyedItems(props, ctx);

  const keyedExistingStrains = await getNameKeyedItems({
    names: strainNames,
    model: "strain"
  });
  const keyedExistingPlasmids = await getNameKeyedItems({
    names: allPlasmidNames,
    fragment: ["sequence", "id name polynucleotideMaterialId"],
    model: "sequence"
  });

  // update the materials
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      precheckFn: r => {
        if (r.plasmidNames) {
          const missingNames = r.plasmidNames.filter(
            n => !keyedExistingPlasmids[n.toLowerCase()]
          );
          if (missingNames.length)
            return `These plasmids were not found: ${missingNames.join(", ")}`;
        }
        if (r.strainName && !keyedExistingStrains[r.strainName.toLowerCase()]) {
          return `This strain was not found ${r.strainName}`;
        }
      },
      convertUserFacingToDbModel: (r, { isOldRecord }) => {
        if (r.strain) {
          r.strainId = r.strain.id;
        }
        if (!isOldRecord && r.strainName) {
          r.strainId = keyedExistingStrains[r.strainName.toLowerCase()].id;
        }

        delete r.strain;
        delete r.strainName;

        const plasmids = uniq(
          (r.plasmidNames || [])
            .map(n => keyedExistingPlasmids[n.toLowerCase()])
            .concat(r.plasmids || [])
        );

        if (!r.id) {
          r.microbialMaterialMicrobialMaterialPlasmids = plasmids.map(p => ({
            polynucleotideMaterialId:
              plasmidIdToDnaMaterialId[p.id] || p.polynucleotideMaterialId
          }));
        }

        r.materialTypeCode = "MICROBIAL";
        //strip off the sequence
        delete r.plasmidNames;
        delete r.plasmids;
        delete r.existingRecord;
        return r;
      },
      model: "material"
    },
    ctx
  );
  // create new materials
  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport: recordsToContinueUpserting,
      modelOrFragment: "material"
    },
    ctx
  );
};

export default MICROBIAL_MATERIAL;
