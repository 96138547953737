/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Field } from "redux-form";
import { CheckboxField, InputField } from "@teselagen/ui";
import AsyncValidateFieldSpinner from "../../../src-shared/AsyncValidateFieldSpinner";

function InnerComp({ input, rightElement }) {
  return (
    <React.Fragment>
      <CheckboxField
        name="generateBarcode"
        defaultValue
        label="Generate Barcode"
      />
      {!input.value && (
        <InputField
          name="userAddedBarcode"
          label="Barcode"
          placeholder="Enter Barcode..."
          rightElement={rightElement}
        />
      )}
    </React.Fragment>
  );
}

function CreateOrGenerateBarcodeField({ initialValues = {}, asyncValidating }) {
  const rightElement = (
    <AsyncValidateFieldSpinner validating={asyncValidating} />
  );
  if (initialValues.id) {
    return (
      <InputField
        name="barcode.barcodeString"
        label="Barcode"
        placeholder="Enter Barcode..."
        rightElement={rightElement}
      />
    );
  }
  return (
    <Field
      name="generateBarcode"
      component={InnerComp}
      rightElement={rightElement}
    />
  );
}

export default CreateOrGenerateBarcodeField;
