/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";

import { compose } from "recompose";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  j5ReworkDataTableFragment,
  j5ReworkReportFragment
} from "../fragments";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { isEmpty, isEqual } from "lodash";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import SampleQCDataTableSelect, {
  getDataTableRows
} from "../../SampleQCDataTableSelect";

const j5ReportMinimalFragment = [
  "j5Report",
  /* GraphQL */ `
    {
      id
      name
      assemblyMethod
      assemblyType
      createdAt
      dateRan
      updatedAt
      j5FileId
    }
  `
];

function collectJ5Entities(sequenceIds, reports) {
  const sequenceIdToAssemblyList = {};
  const sequenceIdToConstruct = {};
  const keyedJ5PcrReactions = {};
  reports.forEach(report => {
    report.j5PcrReactions.forEach(reaction => {
      if (!keyedJ5PcrReactions[reaction.pcrProductSequenceId]) {
        keyedJ5PcrReactions[reaction.pcrProductSequenceId] = [];
      }
      keyedJ5PcrReactions[reaction.pcrProductSequenceId].push(reaction);
    });

    report.j5RunConstructs.forEach(c => {
      if (!sequenceIdToConstruct[c.sequenceId]) {
        sequenceIdToConstruct[c.sequenceId] = [];
      }
      const complexConstruct = {
        construct: c,
        pcrReactions: c.j5ConstructAssemblyPieces.reduce(
          (acc, { assemblyPiece }) => {
            const pcrReactions = keyedJ5PcrReactions[assemblyPiece.sequenceId];
            if (pcrReactions) {
              acc = acc.concat(pcrReactions);
            }
            return acc;
          },
          []
        )
      };
      sequenceIdToConstruct[c.sequenceId].push(complexConstruct);
      c.j5ConstructAssemblyPieces.forEach(p => {
        if (!sequenceIdToAssemblyList[p.assemblyPiece.sequenceId]) {
          sequenceIdToAssemblyList[p.assemblyPiece.sequenceId] = [];
        }
        sequenceIdToAssemblyList[p.assemblyPiece.sequenceId].push({
          constructId: c.id,
          construct: complexConstruct,
          assemblyPieceSeqIds: c.j5ConstructAssemblyPieces.map(
            ({ assemblyPiece }) => assemblyPiece.sequenceId
          )
        });
      });
    });
  });
  const finalPcrReactions = {};
  const finalConstructs = {};
  sequenceIds.forEach(sequenceId => {
    const constructs = sequenceIdToConstruct[sequenceId];
    const pcrReactions = keyedJ5PcrReactions[sequenceId];
    const assemblyPieceLists = sequenceIdToAssemblyList[sequenceId];

    if (constructs) {
      constructs.forEach(c => {
        finalConstructs[c.construct.id] = c;
      });
    }
    if (pcrReactions) {
      pcrReactions.forEach(p => {
        finalPcrReactions[p.id] = p;
      });
    }
    if (assemblyPieceLists) {
      assemblyPieceLists.forEach(list => {
        finalConstructs[list.constructId] = list.construct;
      });
    }
  });
  const pcrReactions = Object.values(finalPcrReactions);
  const pcrReactionIds = pcrReactions.map(p => p.id);
  const j5RunConstructs = Object.values(finalConstructs).map(c => {
    c.pcrReactions.forEach(r => {
      if (!pcrReactionIds.includes(r.id)) {
        pcrReactions.push(r);
        pcrReactionIds.push(r.id);
      }
    });
    return c.construct;
  });
  return {
    pcrReactions,
    j5RunConstructs
  };
}

class SelectInputs extends Component {
  state = {
    sampleErrors: {}
  };

  validateSamples = async values => {
    try {
      const { j5Reports = [], dataTables = [] } = values;
      const {
        nextStep,
        stepFormProps: { change }
      } = this.props;
      const dataTableRows = getDataTableRows(dataTables);
      const j5ReportSeqIds = [];

      j5Reports.forEach(j5Report => {
        j5Report.j5RunConstructs.forEach(construct => {
          j5ReportSeqIds.push(construct.sequenceId);
        });
        j5Report.j5PcrReactions.forEach(pcrReaction => {
          j5ReportSeqIds.push(pcrReaction.pcrProductSequenceId);
        });
      });

      // get sequence ids from the samples and make sure they are in the list of sequence ids from the report
      const fullSamples = await safeQuery(
        [
          "sample",
          /* GraphQL */
          `
            {
              id
              material {
                id
                polynucleotideMaterialSequence {
                  id
                }
              }
            }
          `
        ],
        {
          variables: {
            filter: {
              id: dataTableRows.map(s => s.sampleId)
            }
          }
        }
      );

      const sampleIdToSeqId = fullSamples.reduce((acc, s) => {
        const seqId = s.material?.polynucleotideMaterialSequence?.id;
        acc[s.id] = seqId;
        return acc;
      }, {});

      const sampleErrors = {};
      const sequenceIds = [];
      dataTableRows.forEach(s => {
        const seqId = sampleIdToSeqId[s.sampleId];
        // might also need validation for pooled samples (do not allow them)
        if (!seqId) {
          sampleErrors[s.sampleId] = "No DNA sequence linked to sample.";
        } else if (!j5ReportSeqIds.includes(seqId)) {
          sampleErrors[s.sampleId] =
            `${s.materialName} was not found on assembly report.`;
        } else {
          sequenceIds.push(seqId);
        }
      });
      if (sequenceIds.length) {
        const { pcrReactions, j5RunConstructs } = collectJ5Entities(
          sequenceIds,
          j5Reports
        );
        change("pcrReactions", pcrReactions);
        change("j5RunConstructs", j5RunConstructs);
      }
      this.setState({
        sampleErrors
      });
      if (isEmpty(sampleErrors)) {
        nextStep();
      } else {
        this.setState({
          sampleErrors
        });
      }
    } catch (error) {
      console.error(error);
      window.toastr.error("Error validating samples");
    }
  };

  componentDidUpdate(oldProps) {
    const { sampleErrors } = this.state;
    const { dataTables: oldDataTables = [], j5Reports: oldReports = [] } =
      oldProps;
    const { j5Reports = [], dataTables = [] } = this.props;
    const newReportIds = j5Reports.map(r => r.id);
    const oldReportIds = oldReports.map(r => r.id);
    const newDataTableIds = dataTables.map(r => r.id);
    const oldDataTableIds = oldDataTables.map(r => r.id);
    const changedJ5Inputs = !isEqual(
      [...newReportIds].sort(),
      [...oldReportIds].sort()
    );
    const changedDataTableInputs = !isEqual(
      [...newDataTableIds].sort(),
      [...oldDataTableIds].sort()
    );
    const changedInputs = changedJ5Inputs || changedDataTableInputs;
    if (!isEmpty(sampleErrors) && changedInputs) {
      this.setState({
        sampleErrors: {}
      });
    }
  }

  render() {
    const { sampleErrors = {} } = this.state;
    const {
      Footer,
      footerProps,
      j5Reports = [],
      dataTables = [],
      handleSubmit,
      toolSchema,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;
    const dataTableRows = getDataTableRows(dataTables);
    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Select DNA Assembly Reports"
            helper="Please select one or more DNA Assembly reports."
          />
          <GenericSelect
            {...{
              name: "j5Reports",
              nameOverride: "DNA Assembly Reports",
              isMultiSelect: true,
              schema: [
                "name",
                "assemblyType",
                "assemblyMethod",
                dateModifiedColumn
              ],
              isRequired: true,
              fragment: j5ReportMinimalFragment,
              additionalDataFragment: j5ReworkReportFragment,
              dialogProps: {
                style: {
                  width: 650
                }
              },
              postSelectDTProps: {
                formName: "selectedJ5Reports",
                schema: [
                  "name",
                  "assemblyType",
                  "assemblyMethod",
                  dateModifiedColumn
                ]
              },
              onClear: this.clearReports,
              buttonProps: {
                loading: isLoadingMap.j5Reports,
                disabled: isDisabledMap.j5Reports
              }
            }}
          />
        </div>
        <SampleQCDataTableSelect
          toolSchema={toolSchema}
          isLoadingMap={isLoadingMap}
          isDisabledMap={isDisabledMap}
          sampleErrors={sampleErrors}
          helperText="Choose data tables of samples (output by Sample QC Tool). These samples will be used to reconstruct a subset DNA assembly report."
          additionalDataFragment={j5ReworkDataTableFragment}
        />
        <Footer
          {...footerProps}
          nextDisabled={!dataTableRows.length || !j5Reports.length}
          onNextClick={handleSubmit(this.validateSamples)}
        />
      </React.Fragment>
    );
  }
}

export default compose(stepFormValues("dataTables", "j5Reports"))(SelectInputs);
