/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon } from "@blueprintjs/core";

const recordPreview = props => {
  const { onClick } = props;
  const previewRender = (_, record) => {
    const previewIcon = (
      <Icon
        style={{
          // to keep icons aligned if some have errors and some do not
          float: "right",
          marginRight: 8,
          marginLeft: 8
        }}
        icon="eye-open"
        onClick={() => onClick(record)}
      />
    );
    return <span>{previewIcon}</span>;
  };

  return {
    width: 60,
    noEllipsis: true,
    immovable: true,
    type: "action",
    render: previewRender
  };
};

export { recordPreview };
