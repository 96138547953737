/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
//common
import DNASequences from "./Library/Sequences/DNASequences";
import RNASequences from "./Library/Sequences/RNASequences";
import SequenceFeatures from "./Library/SequenceFeatures";
import AminoAcids from "./Library/AminoAcids";
// import RNASequences from "./Library/Sequences/RNASequences";
// import Oligos from "../src-build/components/Library/Oligos";

let modelToLibrary = {
  //tnw: comment this back in at some point..
  // rnaSequence: RNASequences,
  // oligo: Oligos,
  sequence: [DNASequences, RNASequences],
  sequenceFeature: SequenceFeatures,
  aminoAcidSequence: AminoAcids
  // material: ,
};

export function initModelToLibrary(modelToLib) {
  modelToLibrary = {
    ...modelToLibrary,
    ...modelToLib
  };
}

export { modelToLibrary };
