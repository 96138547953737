/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Menu, MenuItem } from "@blueprintjs/core";
import React from "react";
import { DropdownButton } from "@teselagen/ui";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import GenerateReactionMapsDialog from "./GenerateReactionMapsDialog";

function AdvancedActionsButton({ j5Report, readOnly }) {
  let menuItems = [];

  if (!readOnly) {
    menuItems = menuItems.concat([
      <MenuItem
        key="tagMats"
        text="Tag Materials"
        onClick={() => {
          showDialog({
            modalType: "TAG_J5_REPORT_MATERIALS",
            modalProps: {
              j5ReportId: j5Report.id
            }
          });
        }}
      />,
      <MenuItem
        key="genRMs"
        text="Create Reaction Maps"
        onClick={() => {
          showDialog({
            ModalComponent: GenerateReactionMapsDialog,
            modalProps: {
              j5ReportId: j5Report.id
            }
          });
        }}
      />
    ]);
  }

  if (!menuItems.length) return null;

  return (
    <DropdownButton
      key="advanced"
      text="Advanced Options"
      menu={<Menu>{menuItems}</Menu>}
    />
  );
}

export default AdvancedActionsButton;
