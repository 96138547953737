/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";

export const getMaterialInfo = material => {
  const strain = material.strain;
  if (material.materialTypeCode === "MICROBIAL") {
    return [
      ["Microbial Material", material.name],
      ["Strain", get(strain, "name")],
      ["Species", get(strain, "specie.abbreviatedName")],
      material.vendorCatalogNumber && [
        "Vendor Catalog Number",
        material.vendorCatalogNumber
      ],
      ["Description", get(strain, "description")],
      ["Biosafety Level", get(strain, "biosafetyLevel.name")]
    ];
  } else {
    return [
      ["Cell Culture", material.name],
      ["Cell Line", get(strain, "name")],
      ["Species", get(strain, "specie.abbreviatedName")],
      material.vendorCatalogNumber && [
        "Vendor Catalog Number",
        material.vendorCatalogNumber
      ],
      ["Description", get(strain, "description")],
      ["Biosafety Level", get(strain, "biosafetyLevel.name")]
    ];
  }
};

export const validateAliquot = aliquot => {
  const material = get(aliquot, "sample.material");
  if (material) {
    if (material.materialTypeCode !== "DNA") {
      return "Not linked to a DNA material.";
    } else if (
      !window.frontEndConfig.enableNonSequenceDnaMaterials &&
      !material.polynucleotideMaterialSequence
    ) {
      return `Material ${material.name} is not linked to a sequence.`;
    }
  } else if (aliquot.sample.sampleTypeCode === "FORMULATED_SAMPLE") {
    if (!aliquot.sample.sampleFormulations) {
      return "No sample formulations found on aliquot";
    }
    let nonDna, notLinkedToSequence;
    aliquot.sample.sampleFormulations.some(formulation => {
      return formulation.materialCompositions.some(matComp => {
        if (!matComp.material) {
          nonDna = true;
          return true;
        } else if (matComp.material.materialTypeCode !== "DNA") {
          nonDna = true;
          return true;
        } else if (!matComp.material.polynucleotideMaterialSequence) {
          notLinkedToSequence = true;
        }
        return nonDna || notLinkedToSequence;
      });
    });
    if (nonDna) {
      return "Sample formulation not linked to DNA material.";
    } else if (notLinkedToSequence) {
      return "Sample formulation not linked to sequence.";
    }
  } else {
    return "Not linked to a material.";
  }
};
