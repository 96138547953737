/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";

export const sumBigs = arrayOfBigInts => {
  return arrayOfBigInts.reduce((acc, bigInt) => acc.plus(bigInt), new Big(0));
};

export const maxBigs = arrayOfBigInts => {
  if (!arrayOfBigInts.length) return null;
  let max = arrayOfBigInts[0];
  arrayOfBigInts.forEach(bigInt => {
    if (bigInt.gt(max)) max = bigInt;
  });
  return max;
};

export const minBigs = arrayOfBigInts => {
  if (!arrayOfBigInts.length) return null;
  let min = arrayOfBigInts[0];
  arrayOfBigInts.forEach(bigInt => {
    if (bigInt.lt(min)) min = bigInt;
  });
  return min;
};
