/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { get, uniqBy, isEmpty } from "lodash";
import { reduxForm } from "redux-form";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import TagField from "../../../../src-shared/TagField";
import { createTaggedItems } from "../../../../src-shared/utils/tagUtils";
import { safeQuery } from "../../../../src-shared/apolloMethods";

const TagJ5ReportMaterials = ({
  j5ReportId,
  hideModal,
  handleSubmit,
  submitting
}) => {
  const onSubmit = async values => {
    const { tags = {} } = values;
    try {
      const itemsWithSequences = [
        "j5OligoSyntheses",
        "j5AssemblyPieces",
        "j5InputSequences",
        "j5RunConstructs",
        "j5DirectSyntheses"
      ];

      const hasOligos = {
        j5OligoSyntheses: true
      };

      const materialWithTags = `
        polynucleotideMaterial {
          id
          taggedItems {
            id
            tagId
            tagOptionId
          }
        }
      `;
      let fragment = `id`;
      itemsWithSequences.forEach(item => {
        if (hasOligos[item]) {
          fragment += ` ${item} { id oligo { id sequence { id ${materialWithTags} } } }`;
        } else {
          fragment += ` ${item} { id sequence { id ${materialWithTags} } }`;
        }
      });

      const j5ReportWithSequenceMaterials = await safeQuery(
        ["j5Report", fragment],
        {
          variables: {
            id: j5ReportId
          }
        }
      );
      let materials = [];
      itemsWithSequences.forEach(item => {
        j5ReportWithSequenceMaterials[item].forEach(j5Item => {
          let material;
          if (hasOligos[item]) {
            material = get(j5Item, "oligo.sequence.polynucleotideMaterial");
          } else {
            material = get(j5Item, "sequence.polynucleotideMaterial");
          }
          if (material) {
            materials.push(material);
          }
        });
      });
      if (!materials.length) {
        return window.toastr.warning(
          "No materials to tag. Please make sure assembly report is linked to materials."
        );
      }
      materials = uniqBy(materials, "id");
      if (!isEmpty(tags)) {
        await createTaggedItems({
          selectedTags: values.tags,
          records: materials
        });
      }
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error tagging materials");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <TagField noFieldToggle noBorder />
      </div>
      <DialogFooter
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Tag Report Materials"
  }),
  reduxForm({
    form: "tagJ5Materials"
  })
)(TagJ5ReportMaterials);
