/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { pick, get, isEqual } from "lodash";
import shortid from "shortid";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import strainFragment from "../../../../../tg-iso-shared/src/fragments/strainRecordFragment";
import AssociatedFilesSection from "../AssociatedFilesSection";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import auth from "../../../../src-shared/auth";
import VeCard from "../MaterialRecordView/VeCard";
import { getSequencesToUse } from "../../../utils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import DisplayGrowthConditions from "../../DisplayGrowthConditions";
import StrainRecordInfo from "./StrainRecordInfo";
import DerivedStrainCard from "./DerivedStrainCard";
import { SelectionMethodsCard } from "./SelectionMethodsCard";
import MicrobialMaterialCard from "./MicrobialMaterialCard";
import {
  updateSequenceEditor,
  updateSequenceFromProps
} from "../../../../src-shared/utils/sequenceUtils";

export function getStrainRecordIgvOptions() {
  let authToken = undefined;
  if (auth.getAuthToken()) {
    authToken = auth.getAuthToken();
  }
  const options = {
    reference: {
      fastaURL: "/handleGenomes",
      indexURL: "/handleFaiFile",
      withCredentials: true,
      // indexed: false,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    },
    tracks: [
      {
        name: "<font size=1>Annotations</font>",
        type: "annotation",
        format: "bed",
        delimiter: "\t",
        url: "/handleBedFile",
        displayMode: "EXPANDED",
        // displayMode: "SQUISHED",
        autoHeight: true,
        maxHeight: 200,
        indexed: false,
        searchable: true,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    ]
  };
  return options;
}

class StrainRecordView extends Component {
  state = {
    microbialMaterialCardKey: shortid()
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newSequences = getSequencesToUse(nextProps);
    const newGenomicRegions = getSequencesToUse({
      ...nextProps,
      isGenomicRegion: true
    });
    const { oldSequences, oldGenomicRegions } = prevState;
    const newState = {};
    if (newSequences.length > 0 && !isEqual(newSequences, oldSequences)) {
      let seq = newSequences[0];
      if (seq.__typename === "material") {
        seq = seq.polynucleotideMaterialSequence;
      }
      if (seq?.__typename === "sequence") {
        updateSequenceFromProps([seq]);
      } else {
        updateSequenceEditor({
          name: "No sequence"
        });
      }
      newState.oldSequences = newSequences;
    }
    if (!isEqual(newGenomicRegions, oldGenomicRegions)) {
      updateSequenceFromProps(newGenomicRegions, {
        editorName: "GenomicRegionEditor"
      });
      newState.oldGenomicRegions = newGenomicRegions;
    }
    return newState;
  }

  componentDidMount = async () => {
    this.componentDidMountOrUpdate();
  };

  componentDidUpdate() {
    this.componentDidMountOrUpdate();
  }

  componentDidMountOrUpdate() {
    const {
      location,
      history,
      strain: { id, strainTypeCode } = {}
    } = this.props;
    if (
      strainTypeCode === "MICROBIAL_STRAIN" &&
      !location.pathname.includes("microbial")
    ) {
      return history.replace(`/microbial-strains/${id}`);
    } else if (
      strainTypeCode === "CELL_LINE" &&
      !location.pathname.includes("cell")
    ) {
      return history.replace(`/cell-lines/${id}`);
    }
  }

  renderContent(strain) {
    if (this.props.data.loading) return;
    return <StrainRecordInfo readOnly={this.props.readOnly} strain={strain} />;
  }

  showUpdateDialog = () => {
    const { strain = {}, refetchStrain } = this.props;
    showDialog({
      modalType: "CREATE_NEW_STRAIN",
      modalProps: {
        strainTypeCode: strain.strainTypeCode,
        dialogProps: {
          title: "Update Strain"
        },
        isUpdate: true,
        initialValues: {
          ...pick(strain, [
            "id",
            "name",
            "genome",
            "genotype",
            "description",
            "growthCondition",
            "specie"
          ]),
          biosafetyLevelCode: get(strain, "biosafetyLevel.code"),
          targetOrganismClassId: get(strain, "targetOrganismClass.id"),
          selectionMethodIds: strain.strainSelectionMethods.map(
            ssm => ssm.selectionMethod.id
          ),
          inductionMethodIds: strain.inductionMethodStrains.map(
            sim => sim.inductionMethod.id
          )
        },
        refetch: refetchStrain,
        strainSelectionMethods: strain.strainSelectionMethods,
        strainInductionMethods: strain.inductionMethodStrains
      }
    });
  };

  afterLinkingSequences = async () => {
    const { refetchStrain } = this.props;
    await refetchStrain();
    // refresh the microbial material card because its plasmids might have changed
    this.setState({
      microbialMaterialCardKey: shortid()
    });
  };

  render() {
    const {
      strain = {},
      refetchStrain,
      readOnly,
      showLeftSlideOutDrawer
    } = this.props;
    const sequencesToUse = getSequencesToUse(this.props);
    const genomicRegionsToUse = getSequencesToUse({
      ...this.props,
      isGenomicRegion: true
    });
    return (
      <AbstractRecord
        {...this.props}
        recordName="strain"
        updateShowFunction={this.showUpdateDialog}
      >
        <div className="tg-flex">
          <div style={{ width: "50%" }}>{this.renderContent(strain)}</div>
          <div className="tg-flex-separator" />
          <DisplayGrowthConditions strain={strain} />
        </div>
        <hr className="tg-section-break" />
        <AssociatedFilesSection
          files={strain.strainFiles}
          refetch={refetchStrain}
          fileTableName="strainFile"
          record={strain}
        />
        <hr className="tg-section-break" />
        <VeCard
          key="ve"
          {...{
            showLeftSlideOutDrawer,
            isMicrobialMaterial: true,
            isStrain: true,
            hasSequences: sequencesToUse.length,
            sequences: sequencesToUse,
            strain,
            readOnly,
            afterMaterialUpdate: this.afterLinkingSequences
          }}
        />
        <hr className="tg-section-break" />
        <VeCard
          key="genomicRegions"
          {...{
            showLeftSlideOutDrawer,
            isMicrobialMaterial: true,
            isGenomicRegionsCard: true,
            hasSequences: genomicRegionsToUse.length,
            sequences: genomicRegionsToUse,
            strain,
            readOnly,
            afterMaterialUpdate: this.afterMaterialUpdate
          }}
        />
        <hr className="tg-section-break" />
        <DerivedStrainCard strain={strain} />
        <hr className="tg-section-break" />
        <MicrobialMaterialCard
          key={this.state.microbialMaterialCardKey}
          strainId={strain.id}
        />
        <hr className="tg-section-break" />
        <SelectionMethodsCard strain={strain} />
      </AbstractRecord>
    );
  }
}

export default recordViewEnhancer(strainFragment, {})(StrainRecordView);
