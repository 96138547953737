/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import HeaderWithHelper from "../../HeaderWithHelper";
import isMobile from "is-mobile";
import { Button, Card } from "@blueprintjs/core";

export const FallbackRender = ({ error, resetErrorBoundary }) => (
  <div
    role="alert"
    style={{
      paddingRight: "20px",
      paddingTop: "70px",
      paddingLeft: isMobile() ? "10px" : "60px",
      width: "100%"
    }}
  >
    <Card>
      <HeaderWithHelper
        style
        header="Something went wrong:"
        helper={error.message}
      />
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </Card>
  </div>
);
