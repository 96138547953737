/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { RadioGroupField } from "@teselagen/ui";
import { sortBy } from "lodash";
import shortid from "shortid";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";

class SortWorklistField extends Component {
  constructor(props) {
    super(props);
    // because in plate prep we have multiple fields
    this.fieldName = "sort-worklist-destinationTransferOrder" + shortid();

    // default the sort to rowFirst
    this.sortTransfers("rowFirst");
  }

  sortTransfers = val => {
    const {
      worklistFieldName = "worklist",
      worklist = {},
      change
    } = this.props;
    const sortOrder =
      val === "rowFirst"
        ? [
            "destinationPlateName",
            "destinationAliquotContainer.rowPosition",
            "destinationAliquotContainer.columnPosition"
          ]
        : [
            "destinationPlateName",
            "destinationAliquotContainer.columnPosition",
            "destinationAliquotContainer.rowPosition"
          ];
    change(worklistFieldName, {
      ...worklist,
      worklistTransfers: sortBy(worklist.worklistTransfers, sortOrder)
    });
  };

  render() {
    return (
      <RadioGroupField
        inline
        generateDefaultValue={defaultValueConstants.SORT_WORKLIST_ORDER}
        options={defaultValueConstants.SORT_WORKLIST_ORDER.options}
        onFieldSubmit={this.sortTransfers}
        label="Destination Transfer Order"
        name={this.fieldName}
      />
    );
  }
}

export default SortWorklistField;
