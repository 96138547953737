/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { SbolIconDialog } from "../../../SbolIconHeader";
import { renderHeaderIconInner } from "../../../SbolIconHeader/HeaderIcon";

class IconFieldComponent extends React.Component {
  render() {
    const {
      icons,
      forward,
      disabled,
      input: { value: icon }
    } = this.props;
    if (!icon) {
      return <div className="missing-icon">missing</div>;
    }
    return (
      <div
        className="inspector-icon"
        onClick={() => {
          if (disabled) return;
          showDialog({
            modalProps: {
              icons
            },
            ModalComponent: SbolIconDialog
          });
        }}
        style={{
          cursor: disabled ? undefined : "pointer",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative"
        }}
      >
        {renderHeaderIconInner(icon, { forward, width: 60, height: 60 })}
        {icon.name}
      </div>
    );
  }
}

export default IconFieldComponent;
