/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Classes, Button, Callout } from "@blueprintjs/core";
import { union, without, get, set, noop, isEmpty } from "lodash";
import { reduxForm, FieldArray } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import shortid from "shortid";
import {
  DialogFooter,
  InputField,
  ReactColorField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import PlateMapPlate from "../../PlateMapPlate";
import SimplePaging from "../../SimplePaging";
import AddButton from "../../../../src-shared/FieldArrayButtons/AddButton";
import RemoveButton from "../../../../src-shared/FieldArrayButtons/RemoveButton";
import { createPlateLocationMap } from "../../../utils/plateUtils";
import {
  REQUIRED_ERROR,
  throwFormError
} from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { compose } from "recompose";

class CreatePlateLayerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocations: [],
      page: 0
    };
    const { record } = this.props;
    const { aliquotContainers, plateMapItems } = record;
    this.plateLocationMap = createPlateLocationMap(
      aliquotContainers || plateMapItems
    );
  }

  updateLocations = locations => {
    if (this.props.noEmptyWells) {
      locations = locations.filter(lo => {
        const ac = this.plateLocationMap[lo];
        return ac && ac.id;
      });
    }
    this.setState({
      selectedLocations: locations
    });
  };

  addSelectedWellsToZone = (plateLayerName, zoneIndex) => {
    const { page, selectedLocations } = this.state;
    const { plateLayers = [], change } = this.props;
    if (!selectedLocations.length) return;
    const plateLayer = plateLayers[page];
    const newZones = plateLayer.zones.map((zone, i) => {
      const { locations = [] } = zone;
      let newLocations;
      if (i === zoneIndex) {
        newLocations = union(locations, selectedLocations);
      } else {
        newLocations = without(locations, ...selectedLocations);
      }
      if (newLocations.length !== locations.length) {
        return {
          ...zone,
          locations: newLocations
        };
      } else {
        return zone;
      }
    });
    change(`${plateLayerName}.zones`, newZones);
  };

  onSubmit = async values => {
    const { record, hideModal, refetch = noop } = this.props;
    const { plateLayers } = values;

    const plateLayerDefIds = [];
    const errors = {};
    plateLayers.forEach((pl, i) => {
      pl.zones.forEach((zone, j) => {
        const fieldName = `plateLayers[${i}].zones[${j}]`;
        if (!zone.locations?.length) {
          set(errors, `${fieldName}.name`, "No wells assigned to zone.");
        }
      });
    });
    if (!isEmpty(errors)) {
      return throwFormError(errors);
    }
    try {
      plateLayers.forEach(pl => {
        if (pl.id) {
          plateLayerDefIds.push(pl.plateLayerDefinitionId);
        }
      });

      await safeDelete("plateLayerDefinition", plateLayerDefIds);
      const newPlateLayerDefinitions = [];

      const newPlateLayers = plateLayers.map(plateLayer => {
        const plateLayerDefId = shortid();
        newPlateLayerDefinitions.push({
          cid: plateLayerDefId,
          name: plateLayer.name
        });

        return {
          [record.__typename + "Id"]: record.id,
          plateLayerDefinitionId: `&${plateLayerDefId}`,
          plateZones: plateLayer.zones.map(zone => {
            return {
              plateZoneDefinition: {
                plateLayerDefinitionId: `&${plateLayerDefId}`,
                name: zone.name,
                color: zone.color
              },
              plateZoneWells: (zone.locations || []).reduce((acc, location) => {
                const ac = this.plateLocationMap[location];
                if (ac && ac.__typename) {
                  acc.push({
                    [ac.__typename + "Id"]: ac.id
                  });
                }
                return acc;
              }, [])
            };
          })
        };
      });

      await safeUpsert("plateLayerDefinition", newPlateLayerDefinitions);
      await safeUpsert("plateLayer", newPlateLayers);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating plate layers");
    }
  };

  render() {
    const { selectedLocations, page } = this.state;
    const {
      hideModal,
      record,
      plateLayers = [],
      containerFormat,
      handleSubmit,
      submitting,
      invalid,
      submitFailed,
      noEmptyWells
    } = this.props;

    const { aliquotContainers, plateMapItems, containerArrayType } = record;
    const plateLayer = plateLayers[page];
    let aliquotContainersToShow = aliquotContainers || plateMapItems;
    const plateZones = get(plateLayer, "zones", []);
    if (plateZones.length) {
      aliquotContainersToShow = aliquotContainersToShow.map(ac => {
        let acToReturn = ac;
        plateZones.some(zone => {
          const { locations = [], color } = zone;
          if (
            color &&
            locations.length &&
            locations.includes(getAliquotContainerLocation(ac))
          ) {
            acToReturn = {
              ...ac,
              color
            };
            return true;
          }
          return false;
        });
        return acToReturn;
      });
    }
    // console.log("plateLayers:", plateLayers);
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          {noEmptyWells && (
            <Callout style={{ marginBottom: 15 }} intent="primary">
              Plate layers cannot be applied to empty wells.
            </Callout>
          )}
          <div className="tg-flex justify-space-between">
            <div>
              <FieldArray
                page={page}
                setState={this.setState.bind(this)}
                name="plateLayers"
                addSelectedWellsToZone={this.addSelectedWellsToZone}
                component={renderPlateLayers}
              />
            </div>
            <div>
              {plateLayer && (
                <h6 style={{ textAlign: "center", marginBottom: 15 }}>
                  {plateLayer.name}
                </h6>
              )}
              <PlateMapPlate
                noPadding
                {...{
                  containerArrayType,
                  containerFormat,
                  withDragSelect: true,
                  onWellsSelected: this.updateLocations,
                  selectedAliquotContainerLocations: selectedLocations,
                  aliquotContainers: aliquotContainersToShow
                }}
              />
            </div>
          </div>
          {submitFailed && invalid && (
            <div className="tg-flex justify-flex-end" style={{ marginTop: 10 }}>
              <BlueprintError error="A plate layer has errors. Please check all plate layers for errors." />
            </div>
          )}
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({ title: "Create Plate Layers", style: { width: 800 } }),
  reduxForm({
    form: "CreatePlateLayers",
    validate
  }),
  tgFormValues("plateLayers")
)(CreatePlateLayerDialog);

function validate(values) {
  const { plateLayers = [] } = values;
  const errors = {};
  const seenLayerNames = [];
  plateLayers.forEach((plateLayer, i) => {
    if (!plateLayer.name) {
      set(errors, `plateLayers[${i}].name`, REQUIRED_ERROR);
    } else if (seenLayerNames.includes(plateLayer.name)) {
      set(errors, `plateLayers[${i}].name`, "Another layer has the same name.");
    } else {
      seenLayerNames.push(plateLayer.name);
    }
    const zones = plateLayer.zones || [];
    const seenZoneNames = [];
    if (!zones.length) {
      set(
        errors,
        `plateLayers[${i}].zones._error`,
        "Please add at least one zone."
      );
    }
    zones.forEach((zone, j) => {
      const fieldName = `plateLayers[${i}].zones[${j}]`;
      if (!zone.name) {
        set(errors, `${fieldName}.name`, REQUIRED_ERROR);
      } else if (seenZoneNames.includes(zone.name)) {
        set(errors, `${fieldName}.name`, "Another zone has the same name.");
      } else {
        seenZoneNames.push(zone.name);
      }
      if (!zone.color) {
        set(errors, `${fieldName}.color`, REQUIRED_ERROR);
      }
    });
  });
  return errors;
}

function renderPlateLayers({ fields, page, setState, addSelectedWellsToZone }) {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <AddButton
          noMargin
          text="Add Plate Layer"
          add={() => {
            const newLength = fields.length + 1;
            fields.push({});
            setState({
              page: newLength - 1
            });
          }}
        />
        {fields.length > 1 && (
          <SimplePaging
            zeroIndexed
            numPages={fields.length}
            page={page}
            setState={setState}
          />
        )}
      </div>
      {fields.map((plateLayer, i) => {
        if (page !== i) return null;

        return (
          <div key={i}>
            <div className="tg-flex">
              <RemoveButton
                remove={() => {
                  const newLength = fields.length - 1;
                  fields.remove(i);
                  if (page === fields.length - 1) {
                    setState({
                      page: newLength - 1
                    });
                  }
                }}
              />
              <InputField label="Name" name={`${plateLayer}.name`} />
            </div>
            <FieldArray
              plateLayer={plateLayer}
              name={`${plateLayer}.zones`}
              component={renderZones}
              addSelectedWellsToZone={addSelectedWellsToZone}
            />
          </div>
        );
      })}
    </div>
  );
}

function renderZones({
  fields,
  meta: { error },
  plateLayer,
  addSelectedWellsToZone
}) {
  return (
    <div>
      <div className="tg-flex align-center" style={{ marginBottom: 10 }}>
        <h6 style={{ marginBottom: 0 }}>Zones</h6>
        <AddButton noMargin fields={fields} text="Add Zone" />
      </div>
      {error && <BlueprintError error={error} />}
      {fields.map((zone, i) => {
        return (
          <div key={i}>
            <div className="tg-flex align-center">
              <RemoveButton fields={fields} index={i} />
              <InputField label="Name" name={`${zone}.name`} />
              <div className="tg-flex-separator" />
              <ReactColorField label="Color" name={`${zone}.color`} />
            </div>
            <Button
              small
              text="Add Selected Wells to Zone"
              onClick={() => addSelectedWellsToZone(plateLayer, i)}
            />
            {i !== fields.length - 1 && <hr className="tg-section-break" />}
          </div>
        );
      })}
    </div>
  );
}
